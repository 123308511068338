import { WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { openChangePasswordModal } from "../../lib/redux/modals/OpenModalAction";
import { setUserInfoAction } from "../../lib/redux/users/SetUserAction";
import ServiceWire from "../../lib/services/ServiceWire";
import { GlobalState } from "../../lib/state/GlobalState";
import UserInfo from "../../lib/state/UserInfo";
import ChangePasswordDialog from "../ChangePasswordComponent/ChangePasswordDialog";
import SternumImprovedButton from "../SUI/SternumImprovedButton/SternumImprovedButton";
import SternumInputField from "../SUI/SternumInputField/SternumInputField";
import userInformationUpdateComponentStyle from "./UserInformationUpdateComponentStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    firstName: string;
    lastName: string;
    savingUserInformation: boolean;
    errorSavingUserInformation: boolean;
    email: string;
    openChangePasswordDialog: boolean;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof userInformationUpdateComponentStyle> {
    openChangePasswordModal?: (key: string, displayXButton: boolean, displayBackButton: boolean) => void;
    setUserInfoAction?: (user: UserInfo) => void;
    user?: UserInfo;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {
        user: state.user.entity,
    };
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {
        setUserInfoAction: (user) => dispatch(setUserInfoAction(user)),
        openChangePasswordModal: (key, displayXButton, displayBackButton) =>
            dispatch(openChangePasswordModal(key, displayXButton, displayBackButton)),
    };
};

/**
 * Displays the dashboard of sternum.
 */
class UserInformationUpdateComponent extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);
        // Initializing the state to default.
        this.state = {
            firstName: props.user?.firstName || "",
            lastName: props.user?.lastName || "",
            savingUserInformation: false,
            errorSavingUserInformation: false,
            email: this.props.user?.email,
            openChangePasswordDialog: false,
        };
    }

    /**
     * Open change password dialog
     */
    private openChangePasswordDialog = () => {
        this.setState({ openChangePasswordDialog: true });
    };

    /**
     * Close change password dialog
     */
    private closeChangePasswordDialog = () => {
        this.setState({ openChangePasswordDialog: false });
    };

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <div className={classNames(classes.flexContainer, classes.fullWidth, classes.marginBottomXLarge)}>
                    {/* First Name */}
                    <div className={classNames(classes.fullWidth)}>
                        <Typography variant="caption" className={classNames(classes.labelColor)}>
                            First name
                        </Typography>
                        <div className={classes.marginTop}>
                            <SternumInputField
                                label={"First Name"}
                                onFieldChange={this.onFirstNameChanged}
                                inputValue={this.state.firstName}
                                noLabel={true}
                            />
                        </div>
                    </div>

                    {/* Last Name */}
                    <div className={classNames(classes.fullWidth)}>
                        <Typography variant="caption" className={classNames(classes.labelColor)}>
                            Last name
                        </Typography>
                        <div className={classes.marginTop}>
                            <SternumInputField
                                label={"Last Name"}
                                onFieldChange={this.onLastNameChanged}
                                inputValue={this.state.lastName}
                                noLabel={true}
                            />
                        </div>
                    </div>

                    {/* Email */}
                    <div className={classNames(classes.fullWidth)}>
                        <Typography variant="caption" className={classNames(classes.labelColor)}>
                            Email
                        </Typography>
                        <div className={classes.marginTop}>
                            <SternumInputField
                                label={"Email"}
                                onFieldChange={() => {}}
                                inputValue={this.state.email}
                                noLabel={true}
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>
                <div className={classNames(classes.flexRow)}>
                    {/* Save */}
                    <div className={classNames(classes.marginRight)}>
                        <SternumImprovedButton
                            content={"Save"}
                            isLoading={this.state.savingUserInformation}
                            isDisabled={this.state.savingUserInformation}
                            onClick={this.saveUserInformation}
                        />
                    </div>
                    {/* Change password */}
                    <div>
                        <SternumImprovedButton
                            content={"Change password"}
                            isLoading={false}
                            isDisabled={false}
                            onClick={this.openChangePasswordDialog}
                            buttonType={"gray"}
                        />
                    </div>

                    {/* Error message */}
                    {this.state.errorSavingUserInformation && (
                        <Typography color={"error"} variant={"caption"} className={classNames(classes.marginTopXs)}>
                            Error trying to save information.
                        </Typography>
                    )}
                    <ChangePasswordDialog
                        open={this.state.openChangePasswordDialog}
                        onCloseClick={this.closeChangePasswordDialog}
                    />
                </div>
            </div>
        );
    }

    /**
     * Occurs on change of first name.
     */
    private onFirstNameChanged = (event) => {
        this.setState({
            firstName: event.target.value,
        });
    };

    /**
     * Occurs on change of last name.
     */
    private onLastNameChanged = (event) => {
        this.setState({
            lastName: event.target.value,
        });
    };

    /**
     * Occurs on clicking the save of user information.
     */
    private saveUserInformation = async () => {
        try {
            this.setState({
                savingUserInformation: true,
                errorSavingUserInformation: false,
            });

            let updatedUserInfo = await ServiceWire.getSternumService().updateUser(
                ServiceWire.getAuthenticationService().getCachedAuthenticatedUser().entityId,
                this.state.firstName,
                this.state.lastName
            );
            this.props.setUserInfoAction(updatedUserInfo);
        } catch (error) {
            this.setState({
                errorSavingUserInformation: true,
            });
        } finally {
            this.setState({
                savingUserInformation: false,
            });
        }
    };
}

export const UserInformationUpdate = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(userInformationUpdateComponentStyle)(UserInformationUpdateComponent));
