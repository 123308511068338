import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import HashSet from "../../lib/infra/HashSet";
import Utils from "../../lib/infra/Utils";
import { fetchDeviceAction } from "../../lib/redux/devices/FetchDeviceAction";
import CvesFilter from "../../lib/state/CvesFilter";
import DeviceDefinitionLibrariesFilter from "../../lib/state/DeviceDefinitionLibrariesFilter";
import DeviceInfo from "../../lib/state/DeviceInfo";
import { GlobalState } from "../../lib/state/GlobalState";
import TableToolbarDisplayState from "../../lib/state/TableToolbarDisplayState";
import CvesList from "../CvesList/CvesList";
import DeviceDefinitionLibrariesList from "../DeviceDefinitionLibrariesList/DeviceDefinitionLibrariesList";
import DeviceInfoDisplay from "../DeviceInfoDisplay/DeviceInfoDisplay";
import SpinnerLoader from "../SUI/SternumLoaders/SpinnerLoader";
import SternumTab from "../SUI/SternumTabs/SternumTab";
import SternumTabs from "../SUI/SternumTabs/SternumTabs";
import deviceViewStyle from "./DeviceViewStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    selectedTab: number;
    selectedTabType: any;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof deviceViewStyle> {
    deviceId: string;
    fullScreenDisplay: boolean;

    loadingDevice?: boolean;
    errorLoadingDevice?: boolean;
    device?: DeviceInfo;

    fetchDeviceAction?: (deviceId: string) => void;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {
        loadingDevice: state.devices.loadingIds.exists(ownProps.deviceId),
        errorLoadingDevice: state.devices.errorIds.containsKey(ownProps.deviceId),
        device: state.devices.idToEntityMap.get(ownProps.deviceId),
    };
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchDeviceAction: (deviceId: string) => dispatch(fetchDeviceAction(deviceId)),
    };
};

/**
 * Displays a bar of metrics.
 */
class DeviceView extends React.Component<AppProps, AppState> {
    /**
     * Holds all type of tabs.
     */
    private tabTypes = {
        deviceInfo: {
            key: "deviceInfo",
            displayName: "Device Info",
            index: 0,
        },
        deviceDefinitionLibraries: {
            key: "deviceDefinitionLibraries",
            displayName: "3ʳᵈ Party Libraries",
            index: 1,
        },
        cves: {
            key: "cves",
            displayName: "CVEs",
            index: 2,
        },
    };

    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            selectedTab: null,
            selectedTabType: null,
        };
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        if (this.props.loadingDevice || !this.props.device) {
            // Return loading placeholder for device page.
            return (
                <div className={classes.deviceViewUpperContent}>
                    <SpinnerLoader />
                </div>
            );
        } else {
            let tabsToDisplay = Utils.getMapValues(this.tabTypes);

            let selectedTab = this.state.selectedTab;
            if (selectedTab === null) {
                selectedTab = 0;
            }

            let selectedTabType = this.state.selectedTabType;
            if (selectedTabType === null) {
                selectedTabType = tabsToDisplay[0];
            }

            // Otherwise, render the device view.
            return (
                <div className={classes.container}>
                    {/* Tab selection */}
                    <SternumTabs
                        className={classNames(classes.tabsSelector)}
                        value={selectedTab}
                        // @ts-ignore
                        onChange={(event, value) => this.handleSelectedTab(value, tabsToDisplay[value])}
                    >
                        {tabsToDisplay.map((tabType) => (
                            <SternumTab key={tabType.key} label={tabType.displayName} />
                        ))}
                    </SternumTabs>

                    {/* Device info */}
                    {selectedTabType.key === "deviceInfo" && (
                        <div className={classes.deviceInfoTabContent}>
                            <DeviceInfoDisplay device={this.props.device} />
                        </div>
                    )}

                    {/* Device definition libraries list */}
                    {selectedTabType.key === "deviceDefinitionLibraries" && (
                        <div className={classes.tableContainer}>
                            <div className={classes.tableInner}>
                                <DeviceDefinitionLibrariesList
                                    deviceDefinitionId={this.props.device.deviceDefinitionId}
                                    viewedColumnsSet={HashSet.fromValues([
                                        "libraryName",
                                        "libraryVersion",
                                        "deviceDefinitionLibraryVersion",
                                        "actions",
                                    ])}
                                    toolbarState={
                                        new TableToolbarDisplayState(
                                            true,
                                            false,
                                            true,
                                            false,
                                            false,
                                            false,
                                            false,
                                            false,
                                            "Search Library Name"
                                        )
                                    }
                                    deviceDefinition={this.props.device.deviceDefinition}
                                    deviceDefinitionLibrariesFilter={
                                        new DeviceDefinitionLibrariesFilter(
                                            this.props.device.lastSeenVersionId
                                                ? null
                                                : this.props.device.deviceDefinitionId,
                                            null,
                                            null,
                                            this.props.device.lastSeenVersionId
                                        )
                                    }
                                    displayXButtonInUsedLibraryView={true}
                                    displayBackButtonInUsedLibraryView={true}
                                    paperClassNames={classNames(classes.tracesListPaper)}
                                />
                            </div>
                        </div>
                    )}

                    {/* CVEs */}
                    {selectedTabType.key === "cves" && (
                        <div className={classes.tableContainer}>
                            <div className={classes.tableInner}>
                                <CvesList
                                    cvesFilter={new CvesFilter(null, null, null, null, null, [this.props.deviceId])}
                                    paperClassNames={classNames(classes.tracesListPaper)}
                                    displayBackButtonInCveView={true}
                                    displayXButtonInCveView={true}
                                />
                            </div>
                        </div>
                    )}
                </div>
            );
        }
    }

    /**
     * Occurs once a tab has been selected.
     */
    private handleSelectedTab(selectedTab, selectedTabType) {
        this.setState({
            selectedTab: selectedTab,
            selectedTabType: selectedTabType,
        });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(deviceViewStyle)(DeviceView));
