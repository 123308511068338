import { Theme } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../../components/CommonStyle";

const changePasswordDialogStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),
        root: {
            borderRadius: "6px",
            "& .MuiDialogTitle-root": {
                paddingRight: "0px",
            },
            ".MuiPaper-rounded": {
                borderRadius: "6px",
            },
        },
        container: {
            width: "370px",
        },
        padding: {
            paddingRight: theme.spacing(3),
            paddingBottom: theme.spacing(3),
            paddingLeft: theme.spacing(3),
        },
        passwordStatusLocation: {
            alignSelf: "flex-end",
        },
        passwordInformationSize: {
            fontSize: "0.875rem",
        },
    });

export default changePasswordDialogStyle;
