import { makeStyles } from "@material-ui/core";

const darkColor = "#2B2523";
const disabledTextColor = "#BEC5CC";
const blueColor = "#1B6FDE";

export const useDownloadChipsStyle = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        gap: 14,
    },

    hide: {
        visibility: "hidden",
    },

    childItemContainer: {},

    childItemContainerDisabled: {
        "& $chipItem": {
            color: disabledTextColor,
            cursor: "inherit",

            "&:hover": {
                background: "#FFF",
                color: disabledTextColor,

                "& $logoContainer": {
                    color: disabledTextColor,
                },

                "& $content": {
                    color: disabledTextColor,
                },

                "& $tag": {
                    background: "#F0F3F5",
                    color: disabledTextColor,
                },
            },
        },

        "& $content": {
            color: disabledTextColor,

            "&:hover": {
                color: disabledTextColor,
            },
        },

        "& $tag": {
            background: "#F0F3F5",
            color: disabledTextColor,
        },

        "& $logoContainer": {
            color: disabledTextColor,
        },
    },

    progress: {
        height: 2,
        marginTop: 8,
        borderRadius: "0 2px 2px 0",
        background: "#E6EAED",
        overflow: "hidden",
    },

    progressValue: {
        height: "100%",
        width: "100%",
        background: disabledTextColor,
        transformOrigin: "0 50%",
        transition: "transform 0.1s ease-out",
    },

    downloadIsDone: {
        display: "flex",
        gap: 5,
        alignItems: "center",
        color: "#ACB4BD",
        fontSize: 12,
        marginTop: 6,
    },

    chipItem: {
        display: "flex",
        alignItems: "center",
        minWidth: 150,
        height: 68,
        border: "1px solid #E7EEF6",
        borderRadius: 10,
        background: "#FFF",
        color: "#2B2523",
        padding: 3,
        paddingRight: 17,
        boxSizing: "border-box",
        cursor: "pointer",

        "&:hover": {
            background: blueColor,
            color: "#FFF",

            "& $logoContainer": {
                color: "#FFF",
            },

            "& $content": {
                color: "#FFF",
            },

            "& $tag": {
                background: "#4F98F7",
                color: "#FFF",
            },
        },
    },

    logoContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 47,
        color: blueColor,
    },

    content: {
        flex: 1,
        color: darkColor,
    },

    contentText: {
        fontSize: 10,
        lineHeight: "120%",
    },

    tag: {
        background: "#F0F3F5",
        borderRadius: 4,
        padding: "2px 4px",
        color: "#676E75",
        marginLeft: 2,
        fontSize: 9,
    },

    contentTitle: {
        fontWeight: 700,
        fontSize: 14,
        lineHeight: "120%",
        marginTop: 1.5,
    },

    contentSubtitle: {
        fontSize: 10,
    },
}));
