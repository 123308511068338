import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { sternumTheme } from "../App";
import commonStyle from "../CommonStyle";

const keyMetricStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        // Key metric container.
        root: {
            display: "flex",
            flexDirection: "column" as "column",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer" as "pointer",
            margin: theme.spacing(2),
            border: "1px solid #E8E8E8",
            background: "#FCFCFC",
            minWidth: theme.spacing(32),

            "&.mod-size-large": {
                padding: theme.spacing(4, 8),
                borderRadius: 40,

                "& $content": {
                    fontSize: 36,
                },

                "& $captionText": {
                    fontSize: 16,
                },
            },

            "&.mod-size-medium": {
                padding: theme.spacing(3, 6),
                borderRadius: 35,

                "& $content": {
                    fontSize: 26,
                },

                "& $captionText": {
                    fontSize: 14,
                },
            },

            "&.mod-size-small": {
                padding: theme.spacing(2, 3),
                borderRadius: 30,

                "& $content": {
                    fontSize: 18,
                },

                "& $captionText": {
                    fontSize: 14,
                },
            },

            "&.mod-not-clickable": {
                cursor: "default",
            },

            "&.mod-cursor-only": {
                cursor: "pointer",
            },
        },

        // Metric content.
        content: {
            fontWeight: 600,
            color: "#E7004C",
        },

        // If metric is selected.
        selected: {
            backgroundColor: sternumTheme.lightGrey.main,
            border: `1px solid ${sternumTheme.grey.main}`,
        },

        // If metric semantic meaning is positive.
        positive: {
            color: sternumTheme.success.main,
        },

        // If metric semantic meaning is negative.
        negative: {
            color: sternumTheme.danger.main,
        },

        // The container of the caption.
        captionContainer: {
            display: "flex",
            alignItems: "center",
        },

        // Caption text to display for key metric.
        captionText: {
            textAlign: "center",
        },

        // The icon indicating the delta.
        arrowIcon: {
            fontSize: "12px",
            marginLeft: theme.spacing(2),
        },

        // The value of the delta.
        deltaValue: {
            fontSize: "12px",
        },
    });

export default keyMetricStyle;
