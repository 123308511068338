import { Typography } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import HashSet from "../../lib/infra/HashSet";
import Utils from "../../lib/infra/Utils";
import { fetchCveAction } from "../../lib/redux/cves/FetchCveAction";
import CveInfo from "../../lib/state/CveInfo";
import DevicesFilter from "../../lib/state/DevicesFilter";
import { GlobalState } from "../../lib/state/GlobalState";
import CveInfoDisplay from "../CveInfoDisplay/CveInfoDisplay";
import CveReferencesDisplay from "../CveReferencesDisplay/CveReferencesDisplay";
import CveScoreDisplay from "../CveScoreDisplay/CveScoreDisplay";
import DevicesList from "../DevicesList/DevicesList";
import SternumTab from "../SUI/SternumTabs/SternumTab";
import SternumTabs from "../SUI/SternumTabs/SternumTabs";
import UsedLibraryInfoDisplay from "../UsedLibraryInfoDisplay/UsedLibraryInfoDisplay";
import cveViewStyle from "./CveViewStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    selectedTab: number;
    selectedTabType: any;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof cveViewStyle> {
    cveId: string;
    fullScreenDisplay: boolean;

    loadingCve?: boolean;
    errorLoadingCve?: boolean;
    cve?: CveInfo;

    fetchCveAction?: (cveId: string) => void;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {
        loadingCve: state.cves.loadingIds.exists(ownProps.cveId),
        errorLoadingCve: state.cves.errorIds.containsKey(ownProps.cveId),
        cve: state.cves.idToEntityMap.get(ownProps.cveId),
    };
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchCveAction: (cveId: string) => dispatch(fetchCveAction(cveId)),
    };
};

/**
 * Displays a bar of metrics.
 */
class CveView extends React.Component<AppProps, AppState> {
    /**
     * Holds all type of tabs.
     */
    private tabTypes = {
        cveInfo: {
            key: "cveInfo",
            displayName: "Cve Info",
            index: 0,
        },
        usedLibraryInfo: {
            key: "usedLibraryInfo",
            displayName: "Library Info",
            index: 1,
        },
        devices: {
            key: "devices",
            displayName: "Affected Devices",
            index: 2,
        },
        scores: {
            key: "scores",
            displayName: "Scores",
            index: 3,
        },
        references: {
            key: "references",
            displayName: "References",
            index: 4,
        },
    };

    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            selectedTab: null,
            selectedTabType: null,
        };
    }

    /**
     * Occurs once the component is mounted.
     * We fetch the cve if needed here.
     */
    async componentDidMount() {
        // Getting the cve.
        this.props.fetchCveAction(this.props.cveId);
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        if (this.props.loadingCve || !this.props.cve) {
            // Return loading placeholder for cve page.
            return (
                <div className={classes.cveViewUpperContent}>
                    <div className={classNames(classes.loadingPlaceholder, classes.marginBottom, "mod-title")} />
                    <div
                        className={classNames(classes.loadingPlaceholder, classes.marginBottomXLarge, "mod-subtitle")}
                    />
                    <div className={classNames(classes.loadingPlaceholder, classes.marginBottom, "mod-paragraph")} />
                    <div className={classNames(classes.loadingPlaceholder, classes.marginBottom, "mod-paragraph")} />
                    <div className={classNames(classes.loadingPlaceholder, classes.marginBottom, "mod-paragraph")} />
                </div>
            );
        } else {
            let tabsToDisplay = Utils.getMapValues(this.tabTypes);

            let selectedTab = this.state.selectedTab;
            if (selectedTab === null) {
                selectedTab = 0;
            }

            let selectedTabType = this.state.selectedTabType;
            if (selectedTabType === null) {
                selectedTabType = tabsToDisplay[0];
            }

            let now = Utils.now();
            let thirtyDays = 30 * 24 * 60 * 60 * 1000;

            // Otherwise, render the cve view.
            return (
                <div className={classes.root}>
                    {/* Title container */}
                    <div className={classNames(classes.cveViewUpperContent)}>
                        {/* Cve name */}
                        <Typography variant={"h5"} className={classes.title}>
                            {this.props.cve.cveOfficialId}
                        </Typography>
                    </div>

                    {/* Tab selection */}
                    <div className={classes.tabsContainer}>
                        <SternumTabs
                            value={selectedTab}
                            // @ts-ignore
                            onChange={(event, value) => this.handleSelectedTab(value, tabsToDisplay[value])}
                        >
                            {tabsToDisplay.map((tabType) => (
                                <SternumTab key={tabType.key} label={tabType.displayName} />
                            ))}
                        </SternumTabs>
                    </div>

                    <div className={classes.tabContent}>
                        {/* Cve info */}
                        {selectedTabType.key === "cveInfo" && (
                            <div className={classes.infoTabContent}>
                                <CveInfoDisplay cve={this.props.cve} />
                            </div>
                        )}

                        {/* Used library info */}
                        {selectedTabType.key === "usedLibraryInfo" && (
                            <div className={classes.infoTabContent}>
                                <UsedLibraryInfoDisplay usedLibrary={this.props.cve.usedLibrary} />
                            </div>
                        )}

                        {/* Devices */}
                        {selectedTabType.key === "devices" && (
                            <div className={classes.tableContainer}>
                                <div className={classes.tableInner}>
                                    <DevicesList
                                        viewedColumnsSet={HashSet.fromValues([
                                            "entityId",
                                            "name",
                                            "status",
                                            "lastSeen",
                                        ])}
                                        devicesFilter={
                                            new DevicesFilter(
                                                null,
                                                null,
                                                null,
                                                null,
                                                null,
                                                null,
                                                null,
                                                null,
                                                [this.props.cve.usedLibraryId],
                                                null,
                                                null
                                            )
                                        }
                                        paperClassNames={classNames(
                                            classes.devicesListPaper,
                                            this.props.fullScreenDisplay ? "mod-fullscreen" : "mod-regular"
                                        )}
                                        displayBackButtonInDeviceView={true}
                                        displayXButtonInDeviceView={true}
                                        openDeviceViewInCurrentPage={false}
                                    />
                                </div>
                            </div>
                        )}

                        {/* Score */}
                        {selectedTabType.key === "scores" && (
                            <div className={classes.infoTabContent}>
                                <CveScoreDisplay cve={this.props.cve} />
                            </div>
                        )}

                        {/* References - Full state */}
                        {!!(
                            selectedTabType.key === "references" &&
                            this.props.cve.externalEntity["references"] &&
                            this.props.cve.externalEntity["references"].length
                        ) && (
                            <div className={classes.infoTabContent}>
                                <CveReferencesDisplay cve={this.props.cve} />
                            </div>
                        )}

                        {/* References - Empty State */}
                        {selectedTabType.key === "references" &&
                            (!this.props.cve.externalEntity["references"] ||
                                !this.props.cve.externalEntity["references"].length) && (
                                <div className={classes.infoTabContent}>
                                    <Typography variant="body2">
                                        No references found for {this.props.cve.cveOfficialId}.
                                    </Typography>
                                </div>
                            )}
                    </div>
                </div>
            );
        }
    }

    /**
     * Occurs once a tab has been selected.
     */
    private handleSelectedTab(selectedTab, selectedTabType) {
        this.setState({
            selectedTab: selectedTab,
            selectedTabType: selectedTabType,
        });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(cveViewStyle)(CveView));
