import React, { useRef, useState } from "react";
import { Popover } from "@material-ui/core";

import { PlayIcon } from "../../SUI/SternumIcon";
import { anomalyOnboardingEmitter } from "../../AnomalyOnboarding";
import { useOnboardingHowItWorksStyles } from "./OnboardingHowItWorks.styles";

export interface OnboardingHowItWorksProps {
    showResolving?: boolean;
    onGoToAnomalyGraph?: () => unknown;
    onGoToTraceSequence?: () => unknown;
    onGoToResolving?: () => unknown;
}

export function OnboardingHowItWorks({
    showResolving = true,
    onGoToAnomalyGraph,
    onGoToTraceSequence,
    onGoToResolving,
}: OnboardingHowItWorksProps) {
    const textRef = useRef<HTMLDivElement>(null);
    const popoverContentRef = useRef<HTMLDivElement>(null);

    const [isOpen, setIsOpen] = useState(false);
    const classes = useOnboardingHowItWorksStyles();

    return (
        <>
            <Popover
                classes={{ paper: classes.popoverPaper }}
                open={textRef.current && isOpen}
                anchorEl={textRef.current}
                onClose={() => setIsOpen(false)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: -10,
                    horizontal: -20,
                }}
            >
                <div ref={popoverContentRef} className={classes.popoverContainer}>
                    <div className={classes.onboardingPopoverTitle}>Guide for Anomaly Investigation</div>
                    <div
                        className={classes.onboardingItem}
                        onClick={() => {
                            setIsOpen(false);
                            onGoToAnomalyGraph?.();
                            setTimeout(() => {
                                anomalyOnboardingEmitter.emit("resetOnboarding", "onboarding_anomaly_anomaly_graph");
                            });
                        }}
                    >
                        <span>Anomaly Graph</span>
                        <PlayIcon color="#E7004C" />
                    </div>
                    <div
                        className={classes.onboardingItem}
                        onClick={() => {
                            setIsOpen(false);
                            onGoToTraceSequence?.();
                            setTimeout(() => {
                                anomalyOnboardingEmitter.emit("resetOnboarding", "onboarding_anomaly_trace_sequence");
                            });
                        }}
                    >
                        <span>Trace Sequence</span>
                        <PlayIcon color="#E7004C" />
                    </div>
                    {showResolving && (
                        <div
                            className={classes.onboardingItem}
                            onClick={() => {
                                setIsOpen(false);
                                onGoToResolving?.();
                                setTimeout(() => {
                                    anomalyOnboardingEmitter.emit("resetOnboarding", "onboarding_anomaly_resolving");
                                });
                            }}
                        >
                            <span>Resolving</span>
                            <PlayIcon color="#E7004C" />
                        </div>
                    )}
                </div>
            </Popover>
            <div ref={textRef} className={classes.howItWorksContainer} onClick={() => setIsOpen((isOpen) => !isOpen)}>
                How it works?
            </div>
        </>
    );
}
