import { Action } from "redux";
import ActionType from "../ActionType";

/**
 * Sets the currently know detected device count.
 */
export interface SetDetectedDeviceCountAction extends Action<ActionType> {
    type: ActionType.SetDetectedDeviceCount;
    count: number;
}

export function setDetectedDeviceCountAction(count: number): SetDetectedDeviceCountAction {
    return {
        type: ActionType.SetDetectedDeviceCount,
        count,
    };
}
