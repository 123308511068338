import { makeStyles } from "@material-ui/core";

export const useKernelLogsStyles = makeStyles((theme) => ({
    kernelLogsContainer: {
        margin: "0 18px",
    },

    kernelLogsDisplay: {
        borderRadius: 8,
        maxHeight: 600,
        background: "#F7F9FA",
        color: "#555B61",
        fontSize: 16,

        "& .kernel-log-message-line": {
            marginBottom: 16,
        },
    },
}));
