import { Button, Typography } from "@material-ui/core";
import * as React from "react";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { History } from "history";
import usePremiumBannerStyle from "./PremiumBannerStyle";
import SternumImprovedButton from "../SUI/SternumImprovedButton/SternumImprovedButton";

interface PremiumBannerProps {}

type PremiumBannerPropsWithHOC = PremiumBannerProps & { history: History };

function PremiumBannerComponent({ history }: PremiumBannerPropsWithHOC) {
    const classes = usePremiumBannerStyle();

    return (
        <div className={classes.root}>
            <Typography className={classes.message}>
                You are using the Sternum Platform Free license. Upgrade to the Premium version to get additional
                insights and capabilities.
            </Typography>
            <a href="/premium" target="_blank" className={classes.premiumLink}>
                <SternumImprovedButton
                    content="Upgrade"
                    fullWidth={false}
                    className={classNames(classes.button, classes.upgradeButton)}
                />
            </a>
        </div>
    );
}

export const PremiumBanner: React.FC<PremiumBannerProps> = withRouter(PremiumBannerComponent);
