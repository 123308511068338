import { Action } from "redux";

import ActionType from "../ActionType";
import { DashboardOnboardingStepState } from "../../state/DashboardRegularState";

// Get Onboarding state
export interface GetDashboardOnboardingStateAction extends Action<ActionType> {
    type: ActionType.DashboardRegularGetOnboardingState;
}

export function getDashboardOnboardingStateAction(): GetDashboardOnboardingStateAction {
    return {
        type: ActionType.DashboardRegularGetOnboardingState,
    };
}

// Get Onboarding state - in progress
export interface GetDashboardOnboardingStateInProgressAction extends Action<ActionType> {
    type: ActionType.DashboardRegularGetOnboardingStateInProgress;
}

export function getDashboardOnboardingStateInProgressAction(): GetDashboardOnboardingStateInProgressAction {
    return {
        type: ActionType.DashboardRegularGetOnboardingStateInProgress,
    };
}

// Get Onboarding state - successfully
export interface GetDashboardOnboardingStateSuccessfullyActionFields {
    isVisible: boolean;
    stepsState: DashboardOnboardingStepState;
}

export interface GetDashboardOnboardingStateSuccessfullyAction
    extends Action<ActionType>,
        GetDashboardOnboardingStateSuccessfullyActionFields {
    type: ActionType.DashboardRegularGetOnboardingStateSuccessfully;
}

export function getDashboardOnboardingStateSuccessfullyAction(
    action: GetDashboardOnboardingStateSuccessfullyActionFields
): GetDashboardOnboardingStateSuccessfullyAction {
    return {
        type: ActionType.DashboardRegularGetOnboardingStateSuccessfully,
        isVisible: action.isVisible,
        stepsState: action.stepsState,
    };
}

// Get Onboarding state - error
export interface GetDashboardOnboardingStateErrorAction extends Action<ActionType> {
    type: ActionType.DashboardRegularGetOnboardingStateError;
}

export function getDashboardOnboardingStateErrorAction(): GetDashboardOnboardingStateErrorAction {
    return {
        type: ActionType.DashboardRegularGetOnboardingStateError,
    };
}
