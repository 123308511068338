import { call, put, select, takeEvery } from "redux-saga/effects";
import ServiceWire from "../../services/ServiceWire";
import FetchError from "../../state/FetchError";
import TraceInfo from "../../state/TraceInfo";
import ActionType from "../ActionType";
import { fetchedDeviceSuccessfullyAction } from "../devices/FetchedDeviceSuccessfullyAction";
import { errorFetchingTraceAction } from "./ErrorFetchingTraceAction";
import { fetchedTraceSuccessfullyAction } from "./FetchedTraceSuccessfullyAction";
import { FetchTraceAction } from "./FetchTraceAction";
import traceSelector from "./TraceSelector";

/**
 * Fetches a trace from API.
 */
export function* fetchTraceAsync(action: FetchTraceAction) {
    try {
        // Trying to retrieve trace from cache.

        let trace = yield select(traceSelector, action.traceId);

        if (!trace) {
            // Trace not found in cache, calling API for getting the trace.
            let traceInfo: TraceInfo = yield call(
                [ServiceWire.getSternumService(), ServiceWire.getSternumService().getTraceById],
                action.traceId,
                action.issueId
            );

            // Dispatching a successful fetch of device.
            yield put(fetchedDeviceSuccessfullyAction(traceInfo.device));
            // Dispatching a successful fetch of trace.
            yield put(fetchedTraceSuccessfullyAction(traceInfo));
        } else {
            // Dispatching a successful fetch of trace.
            yield put(fetchedTraceSuccessfullyAction(trace));
        }
    } catch (error) {
        // Error occurred, dispatching an erroneous fetch of trace.
        yield put(errorFetchingTraceAction(action.traceId, new FetchError(error.message)));
    }
}

/**
 * Watching the ActionType.FetchTrace action for fetching trace from API.
 */
export function* watchFetchTraceAsync() {
    yield takeEvery((action) => action.type === ActionType.FetchTrace, fetchTraceAsync);
}
