import { makeStyles } from "@material-ui/core";

const useCogSpinnerStyle = makeStyles((theme) => {
    return {
        container: {
            position: "relative",
            top: "155px",
        },
        cog: {
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            overflow: "visible",
            width: "170px",
            height: "170px",
        },
        outer: {
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            overflow: "visible",
            width: "170px",
            height: "170px",
            "& svg": {
                transform: "rotate(0deg)",
                animation: `$spinLeft 1600ms linear`,
                animationIterationCount: "infinite",
            },
        },
        inner: {
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            overflow: "visible",
            width: "170px",
            height: "170px",
            "& svg": {
                transform: "rotate(0deg)",
                animation: `$spinRight 1600ms linear`,
                animationIterationCount: "infinite",
            },
        },
        "@keyframes spinLeft": {
            "0%": {
                transform: "rotate(0deg)",
            },
            "100%": {
                transform: "rotate(360deg)",
            },
        },
        "@keyframes spinRight": {
            "0%": {
                transform: "rotate(0deg)",
            },
            "100%": {
                transform: "rotate(-360deg)",
            },
        },
    };
});

export default useCogSpinnerStyle;
