import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
    root: {},

    title: {
        display: "flex",
        alignItems: "center",
        gap: 8,
    },

    subTitle: {
        maxWidth: 570,
    },

    notificationTitle: {
        fontWeight: 500,
    },

    notificationTooltipBox: {
        display: "inline-flex",
        marginLeft: 10,
        borderLeft: "1px solid #DBD7E8",
    },

    notificationDescription: {
        maxWidth: "650px",
        marginTop: 5,
        color: "#ACB4BD",
    },
}));
