import React from "react";
import classNames from "classnames";

import { useGraphItemStyle } from "./GraphItem.style";
import { DeviceGraphData } from "../types";
import { GraphTooltip } from "../GraphTooltip";
import moment from "moment";

export interface GraphItemProps extends React.ComponentPropsWithRef<"div"> {
    color?: string;
    selected?: boolean;
    inlined?: boolean;
    titleText?: string;
    graphData?: DeviceGraphData;
    onChangeSelection?: () => unknown;
}

export function GraphItem({
    color,
    selected,
    inlined,
    titleText,
    graphData,
    onChangeSelection,
    ...props
}: GraphItemProps) {
    const classes = useGraphItemStyle();

    function displayLastTimeSeenTime(lastSeen: number): string {
        return moment(lastSeen).fromNow();
    }

    const renderTooltipContent = () => (
        <div>
            <div>
                <span className={classNames(classes.tooltipTitle)}>Device ID:</span> {graphData?.deviceId}
            </div>
            <div>
                <span className={classNames(classes.tooltipTitle)}>Device Profile:</span> {graphData?.deviceProfile}
            </div>
            <div>
                <span className={classNames(classes.tooltipTitle)}>Firmware Version:</span>{" "}
                {graphData?.lastSeenFirmwareVersion}
            </div>
            <div>
                <span className={classNames(classes.tooltipTitle)}>Last seen:</span>{" "}
                {displayLastTimeSeenTime(graphData?.lastSeen)}
            </div>
        </div>
    );

    const renderItemWithTooltip = () => (
        <GraphTooltip arrow={true} title={renderTooltipContent()}>
            {renderItem()}
        </GraphTooltip>
    );

    const renderItem = () => (
        <div
            className={classNames(
                classes.graphItem,
                onChangeSelection && classes.graphItemWithClick,
                selected && classes.graphItemSelected
            )}
            style={{ backgroundColor: color }}
            onClick={onChangeSelection}
        />
    );

    const renderItemElement = graphData ? renderItemWithTooltip : renderItem;

    if (!titleText) {
        return renderItemElement();
    }

    return (
        <div className={classNames(classes.graphItemContainer)} {...props}>
            {inlined ? (
                <div className={classNames(classes.graphItemTitleInline)}>{titleText}</div>
            ) : (
                <>
                    <div className={classNames(classes.graphItemInnerConnector)} />
                    <div className={classNames(classes.graphItemTitleTop)}>{titleText}</div>
                    <div className={classNames(classes.graphItemTitleBottom)}>{titleText}</div>
                </>
            )}
            {renderItemElement()}
        </div>
    );
}
