import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";

import HashSet from "../../lib/infra/HashSet";
import Utils from "../../lib/infra/Utils";
import WebUtils from "../../lib/infra/WebUtils";
import { fetchGeneratedEventAction } from "../../lib/redux/generatedEvents/FetchGeneratedEventAction";
import { openDeviceViewModalAction } from "../../lib/redux/modals/OpenModalAction";
import ServiceWire from "../../lib/services/ServiceWire";
import { GlobalState } from "../../lib/state/GlobalState";
import HttpResponse from "../../lib/state/HttpResponse";
import IssueInfo from "../../lib/state/IssueInfo";
import ServerEntityType from "../../lib/state/ServerEntityType";
import SternumDeviceEventInfo from "../../lib/state/SternumDeviceEventInfo";
import SternumGeneratedEventInfo from "../../lib/state/SternumGeneratedEventInfo";
import SternumReportDetails from "../../lib/state/SternumReportDetails";
import SternumReportTraceDetails from "../../lib/state/SternumReportTraceDetails";
import TableToolbarDisplayState from "../../lib/state/TableToolbarDisplayState";
import TimeDivisionType from "../../lib/state/TimeDivisionType";
import TraceInfo from "../../lib/state/TraceInfo";
import TracesFilter from "../../lib/state/TracesFilter";
import DeviceInfoDisplay from "../DeviceInfoDisplay/DeviceInfoDisplay";
import EventsTimeline from "../EventsTimeline/EventsTimeline";
import GeoLocationContent from "../GeoLocationContent/GeoLocationContent";
import { ArchiveBookIcon, DownloadIcon, FolderIcon } from "../SUI/SternumIcon";
import SternumTab from "../SUI/SternumTabs/SternumTab";
import SternumTabs from "../SUI/SternumTabs/SternumTabs";
import TracesList from "../TracesList/TracesList";
import sternumGeneratedEventViewStyle from "./SternumGeneratedEventViewStyle";
import { putIssueStatusAction } from "../../lib/redux/issues/PutIssueStatusAction";
import { IssueInfoStateStatus, IssueInfoStatus } from "../../lib/state/IssueInfoType";
import { TraceViewAlertStatus } from "../TraceViewAlertStatus";
import { setSelectedIssueInfoAction } from "../../lib/redux/issues/SetSelectedIssueInfoAction";
import { SternumEmptyModal } from "../SternumEmptyModal";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    selectedTab: number;

    showDownloadReportLoader: boolean;

    shouldDisplayResolve: boolean;

    resolvedIssue: IssueInfo;

    canEdit: boolean;

    expanded: boolean;

    isResolving: boolean;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof sternumGeneratedEventViewStyle> {
    generatedEventId: string;
    fullScreenDisplay: boolean;

    loadingGeneratedEvent: boolean;
    sternumGeneratedEvent: SternumGeneratedEventInfo;
    openDeviceViewModal?: (key: string, deviceId: string, displayXButton: boolean, displayBackButton: boolean) => void;
    issue?: IssueInfo;
    resolvedIssue?: IssueInfo;

    shouldDisplayLinkToDeviceView?: boolean;
    shouldDisplayLinkToExportReport?: boolean;

    fetchGeneratedEventAction?: (generatedEventId: string) => void;
    resolveIssueAction?: (issueId: string) => void;
    setSelectedIssueInfo?: (issue?: IssueInfo) => unknown;
    closeModal?: () => void;
    loadingResolveIssue?: boolean;
    isFreeUser: boolean;
    resolveFlowOpen?: boolean;
    onRefresh?: () => unknown;

    modalShowsBackButton?: boolean;
    shouldDisplayViewInContextButton: boolean;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    const selectedClient = ServiceWire.getClientsService().getSelectedClient();

    return {
        isFreeUser: selectedClient.isTrialTier(),
        loadingGeneratedEvent: state.generatedEvents.loadingIds.exists(ownProps.generatedEventId),
        errorLoadingTrace: state.generatedEvents.errorIds.containsKey(ownProps.generatedEventId),
        sternumGeneratedEvent: state.generatedEvents.idToEntityMap.get(ownProps.generatedEventId),
        loadingResolveIssue:
            state.issues.issueStateByIssueId.get(ownProps.issue?.issueId)?.issueStatus ===
            IssueInfoStateStatus.Resolving,
    };
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchGeneratedEventAction: (generatedEventId: string) => dispatch(fetchGeneratedEventAction(generatedEventId)),
        openDeviceViewModal: (key: string, deviceId, displayXButton, displayBackButton) =>
            dispatch(openDeviceViewModalAction(key, false, deviceId, null, displayXButton, displayBackButton)),
        resolveIssueAction: (issueId: string) =>
            dispatch(putIssueStatusAction({ issueId, status: IssueInfoStatus.Resolved })),
        setSelectedIssueInfo: (issue?: IssueInfo) => dispatch(setSelectedIssueInfoAction({ issue })),
    };
};

/**
 * Displays a bar of metrics.
 */
class SternumGeneratedEventView extends React.Component<AppProps, AppState> {
    /**
     * Holds all type of tabs.
     */
    private tabTypes = {
        eventInfo: {
            key: "eventInfo",
            displayName: "Timeline",
            index: 0,
        },
        traces: {
            key: "traces",
            displayName: "Related Events",
            index: 1,
        },
        deviceDetails: {
            key: "deviceDetails",
            displayName: "Device Info",
            index: 2,
        },
    };

    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            selectedTab: 0,
            showDownloadReportLoader: false,
            shouldDisplayResolve: this.shouldDisplayResolve(this.props.issue),
            resolvedIssue: this.props.issue?.isResolved() ? this.props.issue : null,
            canEdit: ServiceWire.getAuthorizationService().canEdit(ServerEntityType.ISSUE),
            expanded: false,
            isResolving: props.resolveFlowOpen,
        };

        props.setSelectedIssueInfo(props.issue);
    }

    private static limitCharacters(n: number, s: string) {
        return s.length > n ? s.slice(0, n) + "..." : s;
    }

    /**
     * Occurs once the component is loaded into the UI.
     */
    async componentDidMount() {
        this.props.fetchGeneratedEventAction(this.props.generatedEventId);
    }

    componentWillUnmount() {
        this.props.setSelectedIssueInfo();
    }

    async UNSAFE_componentWillReceiveProps(nextProps: Readonly<AppProps>, nextContext: any): Promise<void> {
        if (!nextProps.loadingResolveIssue && this.props.loadingResolveIssue) {
            const resolvedIssue = await ServiceWire.getSternumService().getIssueById(this.props.issue.issueId);
            this.setState({ shouldDisplayResolve: false, resolvedIssue: resolvedIssue });
        }
    }

    shouldDisplayResolve = (issue: IssueInfo): boolean => {
        if (issue) {
            if (!issue.isResolved()) {
                return true;
            }
        }
        return false;
    };

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        if (this.props.loadingGeneratedEvent) {
            // Return loading placeholder for trace page.
            return (
                <div className={classes.upperContentContainer}>
                    <div className={classNames(classes.loadingPlaceholder, classes.marginBottom, "mod-title")} />
                    <div
                        className={classNames(classes.loadingPlaceholder, classes.marginBottomXLarge, "mod-subtitle")}
                    />
                    <div className={classNames(classes.loadingPlaceholder, classes.marginBottom, "mod-paragraph")} />
                    <div className={classNames(classes.loadingPlaceholder, classes.marginBottom, "mod-paragraph")} />
                    <div className={classNames(classes.loadingPlaceholder, classes.marginBottom, "mod-paragraph")} />
                </div>
            );
        }

        if (!this.props.sternumGeneratedEvent) {
            return (
                <SternumEmptyModal>
                    <SternumEmptyModal.Title>No data to display</SternumEmptyModal.Title>
                    <SternumEmptyModal.Description>
                        There is no data to display. It can be because of some internal error or non-existent trace.
                    </SternumEmptyModal.Description>
                </SternumEmptyModal>
            );
        } else {
            return (
                <div
                    role="presentation"
                    aria-label="sternum generated event view"
                    className={classNames(classes.root, this.state.isResolving && classes.rootIsResolving)}
                >
                    {/* Upper content */}
                    <div className={classes.upperContentContainer}>
                        {/* Title */}
                        <div className={classes.flexSpaceBetween}>
                            {/* Investigate text */}
                            <Typography
                                variant={"h5"}
                                className={classNames(classes.title, {
                                    [classes.titlePushedDown]: this.props.modalShowsBackButton,
                                })}
                            >
                                Investigation
                            </Typography>
                            {this.props.shouldDisplayViewInContextButton && (
                                <Button
                                    variant="contained"
                                    className={classes.viewInContextButton}
                                    startIcon={<ArchiveBookIcon />}
                                    onClick={() => {
                                        Object.assign(document.createElement("a"), {
                                            target: "_blank",
                                            href: `/fleet-view/${this.props.sternumGeneratedEvent.device.lastSeenVersionId}?contextFromTimestamp=${this.props.sternumGeneratedEvent.created}&contextTraceId=${this.props.sternumGeneratedEvent.entityId}`,
                                        }).click();
                                    }}
                                >
                                    View in context
                                </Button>
                            )}
                        </div>
                        {/* Subtitle */}
                        <div
                            role="presentation"
                            aria-label="parameters container"
                            className={classNames(classes.flexVMiddle, classes.parametersContainer)}
                        >
                            {/* Description */}
                            <Typography variant="body2" className={classes.extraBold}>
                                Event:
                            </Typography>
                            <Typography variant="body2" className={classNames(classes.marginLeftXs)}>
                                {this.props.sternumGeneratedEvent.sternumTrigger.displayName}
                            </Typography>

                            {/* Device profile name */}
                            <Typography
                                variant="body2"
                                className={classNames(classes.marginLeftLarge, classes.extraBold)}
                            >
                                Device Profile:
                            </Typography>
                            <Typography variant="body2" className={classNames(classes.marginLeftXs)}>
                                {this.props.sternumGeneratedEvent.device.deviceDefinition.displayName}
                            </Typography>

                            {/* Device id */}
                            <Typography
                                variant="body2"
                                className={classNames(classes.marginLeftLarge, classes.extraBold)}
                            >
                                Device ID:
                            </Typography>
                            <Typography variant="body2" className={classNames(classes.marginLeftXs)}>
                                {this.props.sternumGeneratedEvent.device.receivedDeviceId}
                            </Typography>

                            <Typography variant="body2" className={classes.marginLeftLarge}>
                                {moment(this.props.sternumGeneratedEvent.created).format("MM/DD/YYYY HH:mm")}
                            </Typography>

                            {/* More Info Icon */}
                            {!this.props.isFreeUser && this.props.shouldDisplayLinkToDeviceView && (
                                <div
                                    className={classNames(classes.exportReportContainer, classes.flexVMiddle)}
                                    onClick={() => this.moreInfoClicked()}
                                >
                                    <FolderIcon
                                        color="#8B949E"
                                        className={classNames(classes.cursorPointer, classes.marginLeftLarge)}
                                    />

                                    <Typography
                                        variant="body2"
                                        className={classNames(classes.moreInfoText, classes.marginLeftXs)}
                                    >
                                        Open Device View
                                    </Typography>
                                </div>
                            )}

                            {/* Export Report Container */}
                            {!this.props.isFreeUser && this.props.shouldDisplayLinkToExportReport && (
                                <div
                                    className={classNames(classes.exportReportContainer, classes.flexVMiddle)}
                                    onClick={() => this.generateReport()}
                                >
                                    <DownloadIcon
                                        width={24}
                                        height={24}
                                        color="#8B949E"
                                        className={classNames(classes.cursorPointer, classes.marginLeftLarge)}
                                    />

                                    {/* Export Report link */}
                                    <Typography
                                        variant="body2"
                                        className={classNames(classes.generateReportText, classes.marginLeftXs)}
                                    >
                                        Export Report
                                    </Typography>
                                </div>
                            )}
                            {/* Show loader*/}
                            {this.props.shouldDisplayLinkToExportReport && this.state.showDownloadReportLoader && (
                                <div className={classNames(classes.exportReportContainer, classes.flexVMiddle)}>
                                    {/* Loading circle */}
                                    <CircularProgress size={15} className={classNames(classes.marginRight)} />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={classNames(classes.geoLocationContentContainer)}>
                        <GeoLocationContent generatedEvent={this.props.sternumGeneratedEvent} />
                    </div>

                    <div className={classes.tabsContainer}>
                        <SternumTabs
                            value={this.state.selectedTab}
                            // @ts-ignore
                            onChange={(event, value) => this.handleSelectedTab(event, value)}
                        >
                            {/* Getting the tabs */}
                            {Utils.getMapValues(this.tabTypes).map((tabType) => (
                                <SternumTab
                                    key={tabType.key}
                                    label={tabType.displayName}
                                    disabled={this.props.isFreeUser}
                                />
                            ))}
                        </SternumTabs>
                    </div>

                    <div role="presentation" aria-label="tab content" className={classes.tabContent}>
                        {this.state.selectedTab === this.tabTypes.eventInfo.index && (
                            <>
                                <div className={classNames(classes.eventInfoContainer)}>
                                    {!this.state.isResolving && (
                                        <div className={classNames(classes.flexRow)}>
                                            <div className={classNames(classes.flexEqual)}>
                                                {/* Description */}
                                                <Typography variant="body2" className={classes.triggerDescriptionText}>
                                                    {this.state.expanded
                                                        ? this.props.sternumGeneratedEvent.sternumTrigger.description
                                                        : SternumGeneratedEventView.limitCharacters(
                                                              110,
                                                              this.props.sternumGeneratedEvent.sternumTrigger
                                                                  .description
                                                          )}{" "}
                                                    <span
                                                        className={classes.expandToggle}
                                                        onClick={() =>
                                                            this.setState((s) => ({ ...s, expanded: !s.expanded }))
                                                        }
                                                    >
                                                        {this.props.sternumGeneratedEvent.sternumTrigger.description
                                                            .length > 110 && (
                                                            <span>
                                                                {this.state.expanded ? " Show less" : " Show more"}
                                                            </span>
                                                        )}
                                                    </span>
                                                    {}
                                                </Typography>
                                            </div>
                                        </div>
                                    )}

                                    {/* Timeline */}
                                    <div className={classNames(classes.timelineContainer)}>
                                        <EventsTimeline
                                            hideTimelineIndexContainer={this.state.isResolving}
                                            sternumDeviceEventInfo={
                                                new SternumDeviceEventInfo(
                                                    "SDEV" + this.props.sternumGeneratedEvent.entityId.substring(4),
                                                    this.props.sternumGeneratedEvent.sternumGeneratedEventIdLong,
                                                    this.props.sternumGeneratedEvent.created,
                                                    this.props.sternumGeneratedEvent.device,
                                                    null,
                                                    this.props.sternumGeneratedEvent
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </>
                        )}

                        {this.state.selectedTab === this.tabTypes.traces.index && (
                            <div className={classes.tableContainer}>
                                <div className={classes.tableInner}>
                                    <TracesList
                                        toolbarState={
                                            new TableToolbarDisplayState(true, false, false, false, true, false, false)
                                        }
                                        deviceId={this.props.sternumGeneratedEvent.device.entityId}
                                        viewedColumnsSet={HashSet.fromValues([
                                            "traceEventType",
                                            "created",
                                            "prevented",
                                            "details",
                                        ])}
                                        paperClassNames={classNames(
                                            classes.tracesListPaper,
                                            this.props.fullScreenDisplay ? "mod-fullscreen" : "mod-regular"
                                        )}
                                        tracesFilter={
                                            new TracesFilter(
                                                null,
                                                null,
                                                null,
                                                null,
                                                null,
                                                null,
                                                this.props.sternumGeneratedEvent.entityId,
                                                null
                                            )
                                        }
                                        displayBackButtonInTraceView={true}
                                        timeFrame={10}
                                        timeDivisionType={TimeDivisionType.MINUTE}
                                    />
                                </div>
                            </div>
                        )}

                        {this.state.selectedTab === this.tabTypes.deviceDetails.index && (
                            <div className={classes.traceDetailsDisplayContainer}>
                                <DeviceInfoDisplay device={this.props.sternumGeneratedEvent.device} />
                                {/* <AnomalyChart
                                    hideLegendAndDescription={this.state.isResolving}
                                    issueId={this.props.issue.issueId}
                                    onAnomaliesFetched={() => {}}
                                /> */}
                            </div>
                        )}
                    </div>

                    <TraceViewAlertStatus
                        isResolving={this.state.isResolving}
                        onSetIsResolving={(isResolving) => this.setState({ isResolving })}
                        onRefresh={this.props.onRefresh}
                    />
                </div>
            );
        }
    }

    /**
     * Occurs once the more info button is clicked.
     */
    private moreInfoClicked() {
        window.open(`device/${this.props.sternumGeneratedEvent.device.entityId}`, "_blank");
    }

    /**
     * Occurs once a tab has been selected.
     */
    private handleSelectedTab(event, value) {
        this.setState({
            selectedTab: value,
        });
    }

    /**
     * Generates a report for the alert.
     */
    private async generateReport() {
        if (!this.state.showDownloadReportLoader) {
            // Activate loading icon
            this.setState({ showDownloadReportLoader: true });

            // Fetch related events.
            let relatedEvents: TraceInfo[] = await ServiceWire.getSternumService().getTraces(
                ServiceWire.getClientsService().getSelectedClientId(),
                this.props.sternumGeneratedEvent.device.entityId,
                new TracesFilter(null, null, null, null, null, null, this.props.sternumGeneratedEvent.entityId, null),
                null,
                null,
                null,
                0,
                10
            );

            const country = this.props.sternumGeneratedEvent.geoLocationInfo
                ? this.props.sternumGeneratedEvent.geoLocationInfo.country
                : "N/A";
            const city = this.props.sternumGeneratedEvent.geoLocationInfo
                ? this.props.sternumGeneratedEvent.geoLocationInfo.city
                : "N/A";
            const state = this.props.sternumGeneratedEvent.geoLocationInfo
                ? this.props.sternumGeneratedEvent.geoLocationInfo.state
                : "N/A";
            // Construct report details.
            let sternumReportDetails = new SternumReportDetails(
                this.props.sternumGeneratedEvent.sternumTrigger.displayName,
                moment(this.props.sternumGeneratedEvent.created).format("MM/DD/YYYY HH:mm:ss"),
                this.props.sternumGeneratedEvent.sternumTrigger.displayName,
                "Unknown",
                "Unknown",
                this.props.sternumGeneratedEvent.sternumTrigger.description,
                relatedEvents.map(
                    (relatedEvent) =>
                        new SternumReportTraceDetails(
                            relatedEvent.traceDefinition.displayName,
                            relatedEvent.orderedTraceArguments.map(
                                (traceArgument) =>
                                    `${traceArgument.argumentDefinition.displayName}: ${traceArgument.displayValue}`
                            ),
                            moment(relatedEvent.created).format("YYYY-MM-DD HH:mm")
                        )
                ),
                this.props.sternumGeneratedEvent.device.receivedDeviceId,
                this.props.sternumGeneratedEvent.device.deviceDefinition.displayName,
                this.props.sternumGeneratedEvent.device.deviceDefinition.deviceFirmwareVersion,
                this.props.sternumGeneratedEvent.device.cvesCount,
                this.props.sternumGeneratedEvent.device.outOfDateLibrariesCount,
                this.props.sternumGeneratedEvent.device.deviceDefinition.deviceOSFamily,
                this.props.sternumGeneratedEvent.device.deviceDefinition.cpuBitness.toString(),
                country,
                city,
                state
            );
            try {
                // Generate the report.
                const response: HttpResponse = await ServiceWire.getSternumService().generateDeviceAlertReport(
                    this.props.sternumGeneratedEvent.device.entityId,
                    sternumReportDetails.getJsonObject()
                );

                // Download the report.
                WebUtils.downloadReport(response);

                // Hide loading icon
                this.setState({ showDownloadReportLoader: false });
            } catch (err) {
                this.setState({ showDownloadReportLoader: false });
            }
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(sternumGeneratedEventViewStyle)(SternumGeneratedEventView));
