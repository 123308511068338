import { Action } from "redux";

import ActionType from "../ActionType";
import { CategoryGraphView } from "../../state/DashboardRegularState";

export interface SetCategoryGraphViewAction extends Action<ActionType> {
    type: ActionType.DashboardRegularSetCategoryGraphView;
    categoryGraphView: CategoryGraphView;
}

export function setCategoryGraphViewAction(categoryGraphView: CategoryGraphView): SetCategoryGraphViewAction {
    return {
        type: ActionType.DashboardRegularSetCategoryGraphView,
        categoryGraphView,
    };
}
