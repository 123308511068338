import React from "react";
import { useTitleWithTagStyle } from "./TitleWithTag.styles";
import classNames from "classnames";

export interface TitleWithTagProps extends React.ComponentProps<"div"> {
    tagValue?: React.ReactNode;
}

export function TitleWithTag({ className, children, tagValue, ...props }: TitleWithTagProps) {
    const classes = useTitleWithTagStyle();

    return (
        <div className={classNames(classes.container, className)}>
            <div>{children}</div>
            {tagValue && <div className={classes.tag}>{tagValue}</div>}
        </div>
    );
}
