import { Paper, Tooltip } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import HashSet from "../../lib/infra/HashSet";
import SternumConfiguration from "../../lib/infra/SternumConfiguration";
import Utils from "../../lib/infra/Utils";
import {
    openAlertDescriptionModal,
    openCveViewModalAction,
    openSternumGeneratedEventViewModalAction,
    openTraceViewModalAction,
} from "../../lib/redux/modals/OpenModalAction";
import AnalyticsService from "../../lib/services/AnalyticsService";
import PollingSubscriptionInfo from "../../lib/services/PollingSubscriptionInfo";
import ServiceWire from "../../lib/services/ServiceWire";
import DeviceDefinitionInfo from "../../lib/state/DeviceDefinitionInfo";
import DeviceDefinitionVersionInfo from "../../lib/state/DeviceDefinitionVersionInfo";
import GetTopHittingTriggersResponse from "../../lib/state/GetTopHittingTriggersResponse";
import { GlobalState } from "../../lib/state/GlobalState";
import PollingChangeType from "../../lib/state/PollingChangeType";
import SternumTriggerInfo from "../../lib/state/SternumTriggerInfo";
import SternumTriggerTypeDisplayInfo from "../../lib/state/SternumTriggerTypeDisplayInfo";
import TableColumnHeaderInfo from "../../lib/state/TableColumnHeaderInfo";
import TableToolbarDisplayState from "../../lib/state/TableToolbarDisplayState";
import TriggerHitSummary from "../../lib/state/TriggerHitSummary";
import TriggerDefinition from "../../lib/state/triggers/TriggerDefinition";
import AlertStatusDisplay from "../AlertsStatusDisplay/AlertStatusDisplay";
import StatusDisplay from "../StatusDisplay/StatusDisplay";
import SternumTable from "../SUI/SternumTable/SternumTable";
import triggersHitsListStyle from "./TriggersHitsListStyle";
import { History, Location } from "history";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    // Loading & error state.
    loadingEntities: boolean;
    errorLoadingEntities: boolean;

    // Entities.
    entities: TriggerHitSummary[];
    totalItemCount: number;

    doNotDisplayLoading: boolean;

    // Trigger Modal
    sternumTriggerTypes: SternumTriggerTypeDisplayInfo[];
    alertToDisplayId: string;
    alertToDisplayName: string;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof triggersHitsListStyle> {
    sideBarOpen: boolean;
    location: Location;
    history: History;
    hideUpperBorder?: boolean;
    entityId: string;
    columnWidthsArray?: number[];
    deviceDefinition: DeviceDefinitionInfo;
    toolbarState?: TableToolbarDisplayState;
    includeColumns?: string[];
    paperClassNames?;
    viewedColumnsSet?: HashSet;
    doNotDisplayLoading?: boolean;
    createdFrom: Date;
    createdTo: Date;
    amountOfLoadingPlaceholders?: number;
    showAllColumns?: boolean;
    hideToolbar?: boolean;
    deviceDefinitionVersionId?: string;

    // redux
    shouldRefresh: object;
    openAlertDescriptionModal: (
        key: string,
        alert: string,
        deviceDefinitionVersionId: string,
        createdFrom: number,
        createdTo: number,
        deviceDefinitionInfo: DeviceDefinitionInfo,
        alertDisplayName: string
    ) => void;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {
        shouldRefresh: state.triggersHitList.shouldRefresh,
    };
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {
        openTraceViewModal: (
            key: string,
            traceId,
            displayXButton,
            displayBackButton,
            shouldDisplayLinkToDeviceView,
            shouldDisplayLinkToExportReport
        ) =>
            dispatch(
                openTraceViewModalAction(
                    key,
                    traceId,
                    displayXButton,
                    displayBackButton,
                    shouldDisplayLinkToDeviceView,
                    shouldDisplayLinkToExportReport
                )
            ),
        openSternumGeneratedEventViewModal: (
            key: string,
            sternumGeneratedEventId: string,
            shouldDisplayLinkToDeviceView: boolean,
            shouldDisplayLinkToExportReport: boolean,
            displayXButton,
            displayBackButton
        ) =>
            dispatch(
                openSternumGeneratedEventViewModalAction(
                    key,
                    sternumGeneratedEventId,
                    shouldDisplayLinkToDeviceView,
                    shouldDisplayLinkToExportReport,
                    displayXButton,
                    displayBackButton
                )
            ),

        openCveViewModal: (key, cveId, displayXButton, displayBackButton) =>
            dispatch(openCveViewModalAction(key, cveId, displayXButton, displayBackButton)),

        openAlertDescriptionModal: (
            key,
            alertId,
            deviceDefinitionVersionId,
            createdFrom,
            createdTo,
            deviceDefinitionInfo,
            alertDisplayName
        ) => {
            dispatch(
                openAlertDescriptionModal(
                    key,
                    alertId,
                    deviceDefinitionVersionId,
                    createdFrom,
                    createdTo,
                    deviceDefinitionInfo,
                    alertDisplayName
                )
            );
        },
    };
};

/**
 * Holds the entities list in the app.
 */
class TriggersHitsList extends React.Component<AppProps, AppState> {
    /**
     * Defines the column headers participating in the entities table.
     */
    private readonly columnHeaders: TableColumnHeaderInfo[] = [
        new TableColumnHeaderInfo("displayName", "Name", false, true, false, false, 10),
        new TableColumnHeaderInfo("count", "Count", false, true, false, false),
        new TableColumnHeaderInfo("triggerDisabled", "Status", false, true, false, false),
    ];

    private pollingId = "TriggerHitList";

    /**
     * Gets the display values for row's columns (where they aren't the default).
     */
    private getRowDisplayValues(row) {
        let entity = row as TriggerHitSummary;
        const { classes } = this.props;

        const tooltipText = `${entity.eventInterest.substr(0, 1).toUpperCase()}${entity.eventInterest
            .toLowerCase()
            .substr(1)} Interest`;

        return {
            displayName: (
                <div className={classNames(classes.flexVMiddle)}>
                    {/* Interest indication */}
                    <StatusDisplay
                        low={entity.eventInterest.toLowerCase() === "low"}
                        medium={entity.eventInterest.toLowerCase() === "medium"}
                        high={entity.eventInterest.toLowerCase() === "high"}
                        regular={entity.eventInterest.toLowerCase() === "regular"}
                        smallIndication={true}
                        toolTipText={tooltipText}
                    />
                    {/* Alert name */}
                    <Tooltip
                        title={Utils.capitalizeFirsLetter(entity.displayName)}
                        classes={{ tooltip: classes.toolTip }}
                    >
                        <Typography
                            variant="body2"
                            className={classNames(classes.marginLeftXs, classes.triggerDisplayNameWidth)}
                            noWrap={true}
                        >
                            {Utils.capitalizeFirsLetter(entity.displayName)}
                        </Typography>
                    </Tooltip>
                </div>
            ),
            count: entity.count,
            triggerDisabled: <AlertStatusDisplay triggerDisabled={entity.triggerDisabled} />,
        };
    }

    /**
     * Occurs on the click of a row.
     */
    private async handleRowClicked(sternumDeviceEventInfo: TriggerHitSummary) {
        this.props.openAlertDescriptionModal(
            "alertDescriptionModal",
            sternumDeviceEventInfo.entityId,
            this.props.deviceDefinitionVersionId,
            this.props.createdFrom.getTime(),
            this.props.createdTo.getTime(),
            this.props.deviceDefinition,
            sternumDeviceEventInfo.displayName
        );
    }

    constructor(props: AppProps) {
        super(props);

        if (this.props.includeColumns) {
            if (this.props.includeColumns.length) {
                let includeColumnsSet = HashSet.fromValues(this.props.includeColumns);
                this.columnHeaders = this.columnHeaders.filter((columnHeader) =>
                    includeColumnsSet.exists(columnHeader.id)
                );
            } else {
                this.columnHeaders = [];
            }
        }

        if (this.props.showAllColumns) {
            for (let i = 0; i < this.columnHeaders.length; i++) {
                this.columnHeaders[i].isHidden = false;
            }
        }

        // Initializing the state to default.
        this.state = {
            loadingEntities: false,
            errorLoadingEntities: false,

            entities: [],
            totalItemCount: 0,

            doNotDisplayLoading: this.props.doNotDisplayLoading,

            sternumTriggerTypes: [],
            alertToDisplayId: null,
            alertToDisplayName: null,
        };
    }

    async componentDidMount() {
        await Promise.all([this.loadEntities(), this.loadSternumTriggerData()]);
        ServiceWire.getPollingService().subscribe(
            new PollingSubscriptionInfo(
                this.pollingId,
                PollingChangeType.ALL_TRIGGERS,
                this.handleChangedIssues.bind(this)
            )
        );
    }

    componentWillUnmount(): void {
        ServiceWire.getPollingService().unsubscribe(this.pollingId, PollingChangeType.ALL_TRIGGERS);
    }

    private async handleChangedIssues(fromTimestamp: number, changedData: Object) {
        if (!changedData) return;
        await Promise.all([this.loadEntities(true)]);
    }

    private loadSternumTriggerData = async () => {
        const serverResponse = await ServiceWire.getSternumService().getSternumTriggerTypes();

        if (serverResponse) {
            this.setState({
                sternumTriggerTypes: serverResponse,
            });
        }
    };

    async componentDidUpdate(prevProps: Readonly<AppProps>, nextContext: any) {
        if (
            this.props.createdTo !== prevProps.createdTo ||
            this.props.createdFrom !== prevProps.createdFrom ||
            this.props.entityId !== prevProps.entityId
        ) {
            await this.loadEntities();
        }

        // Handle component signaled to refresh the triggers list
        if (prevProps.shouldRefresh !== this.props.shouldRefresh) {
            await this.loadEntities();
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <Paper
                classes={{
                    root: classNames(this.props.paperClassNames),
                }}
                elevation={0}
            >
                <SternumTable
                    size="small"
                    hideUpperBorder={this.props.hideUpperBorder}
                    columnWidthsArray={this.props.columnWidthsArray || [10, 10, 90]}
                    emptyComponent={
                        <div className={classNames(classes.flexCenter, classes.padding)}>
                            <Typography variant="body2">Alerts and anomalies will appear here.</Typography>
                        </div>
                    }
                    toolbarState={this.props.toolbarState}
                    viewedColumnsSet={this.props.viewedColumnsSet}
                    hideToolbar={this.props.hideToolbar}
                    pageSize={SternumConfiguration.getPageSize()}
                    amountOfLoadingPlaceholders={this.props.amountOfLoadingPlaceholders || 9}
                    totalItemCount={this.state.totalItemCount}
                    loadingItems={this.state.loadingEntities}
                    errorLoadingItems={this.state.errorLoadingEntities}
                    columnHeaders={this.columnHeaders}
                    onRefreshClicked={() => this.onRefreshClicked()}
                    rows={this.state.entities}
                    onPageChanged={(pageNumber) => this.loadEntities()}
                    getRowValues={(row) => this.getRowDisplayValues(row)}
                    onRowClicked={(row) => this.handleRowClicked(row as TriggerHitSummary)}
                    getRowClasses={(row) => this.getRowClasses(row)}
                    maxTableHeightClass={this.props.paperClassNames}
                />
            </Paper>
        );
    }

    /**
     * Occurs once the user refreshes the list.
     */
    private async onRefreshClicked() {
        await this.loadEntities();
    }

    /**
     * Loads entities.
     */
    private async loadEntities(doNotDisplayLoading?: boolean) {
        try {
            let entities = [];
            let totalItemCount = 0;

            // Setting loading to true.
            this.setState({
                loadingEntities: !doNotDisplayLoading && !this.props.doNotDisplayLoading,
                errorLoadingEntities: false,
            });

            // Fetching entities.
            let getTopHittingTriggersResponse: GetTopHittingTriggersResponse = await this.fetchEntities();
            entities = getTopHittingTriggersResponse.triggersHitSummaries || [];
            totalItemCount = entities ? entities.length : 0;

            this.setState({
                loadingEntities: false,
                errorLoadingEntities: false,
                entities: [...entities],
                totalItemCount: totalItemCount,
            });
        } catch (error) {
            console.error(error);
            AnalyticsService.error("TriggersHitList:loadEntities", error.message);

            this.setState({
                loadingEntities: false,
                errorLoadingEntities: true,
            });
        }
    }

    /**
     * Gets the classes defined for row.
     */
    private getRowClasses(row) {
        const { classes } = this.props;

        return {
            hover: classes.rowHover,
        };
    }

    /**
     * Api call for entities.
     */
    private async fetchEntities(): Promise<GetTopHittingTriggersResponse> {
        return await ServiceWire.getSternumService().getTopHittingTriggers(
            this.props.entityId,
            this.props.createdFrom.getTime(),
            this.props.createdTo.getTime(),
            5,
            this.props.deviceDefinitionVersionId
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(triggersHitsListStyle)(TriggersHitsList)));
