class SpecialField {
    constructor(public id: string, public displayName: string) {}

    /**
     * Converts json to info object.
     */
    public static fromJsonObject(jsonObject: Object) {
        return new SpecialField(jsonObject["id"], jsonObject["display_name"]);
    }
}

export default SpecialField;
