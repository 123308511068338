/**
 * Represents a device in sternum.
 */
import SternumConfiguration from "../infra/SternumConfiguration";
import Utils from "../infra/Utils";
import DeviceDefinitionInfo from "./DeviceDefinitionInfo";
import DeviceStatus from "./DeviceStatus";
import EntityBase from "./EntityBase";
import EntityType from "./EntityType";
import TableRowData from "./TableRowData";

export enum DeviceMode {
    Production = "PRODUCTION",
    Development = "DEVELOPMENT",
}

class DeviceInfo extends EntityBase implements TableRowData {
    public status: DeviceStatus;

    /**
     * Constructor.
     */
    constructor(
        public deviceId: string,
        public receivedDeviceId: string,
        public lastSeen: number,
        public ipAddress: string,
        public lastSeenAttack: number,
        public lastSeenAttackTraceId: number,
        public firstSeen: number,
        public created: number,
        public updated: number,
        public deviceDefinitionId: string,
        public deviceDefinition?: DeviceDefinitionInfo,
        public cvesCount?: number,
        public outOfDateLibrariesCount?: number,
        public lastSeenVersionId?: string,
        public lastSeenVersionFirmware?: string,
        public lastSeenVersionName?: string,
        public lastSeenVersionSequence?: number,
        public lastSeenVersionStatus?: string,
        public deviceDefinitionVersionExists?: boolean,
        public countryCode?: string,
        public deviceMode?: DeviceMode
    ) {
        super(deviceId, created, updated, EntityType.Device);

        this.status = DeviceInfo.getEvaluatedDeviceStatus(this.lastSeenAttack, cvesCount, outOfDateLibrariesCount);
    }

    /**
     * Gets DeviceInfo from json.
     */
    public static fromJsonObject(jsonObject: Object): DeviceInfo {
        return new DeviceInfo(
            jsonObject["entity_id"],
            jsonObject["received_device_id"],
            jsonObject["last_seen"],
            jsonObject["last_seen_ip_address"],
            jsonObject["last_seen_security_alert"],
            jsonObject["last_seen_security_alert_trace_id"],
            jsonObject["created"], // TODO: find out where `first_seen` disappeared
            jsonObject["created"],
            jsonObject["updated"],
            jsonObject["device_definition_id"],
            jsonObject["device_definition"]
                ? DeviceDefinitionInfo.fromJsonObject(jsonObject["device_definition"])
                : null,
            jsonObject["cves_count"],
            jsonObject["out_of_date_libraries_count"],
            jsonObject["device_definition_version_id"],
            jsonObject["last_seen_version_firmware"],
            jsonObject["last_seen_version_name"],
            jsonObject["last_seen_version_sequence"],
            jsonObject["last_seen_version_status"],
            jsonObject.hasOwnProperty("is_device_definition_version_exists")
                ? jsonObject["is_device_definition_version_exists"]
                : false,
            jsonObject["country_code"] || null,
            jsonObject["device_mode"]
        );
    }

    /**
     * Returns whether given row is any different in details than current device info.
     * Shallow comparison, in other words.
     * @param other The row to compare with.
     */
    public isDifferentFrom(other: TableRowData): boolean {
        // Row must of of type device info.
        if (!(other instanceof DeviceInfo)) {
            return true;
        }

        let otherDeviceInfo = other as DeviceInfo;

        return (
            otherDeviceInfo.deviceId !== this.deviceId ||
            otherDeviceInfo.lastSeen !== this.lastSeen ||
            otherDeviceInfo.ipAddress !== this.ipAddress ||
            otherDeviceInfo.status !== this.status ||
            otherDeviceInfo.firstSeen !== this.firstSeen ||
            otherDeviceInfo.deviceDefinitionId !== this.deviceDefinitionId
        );
    }

    /**
     * Construct device name with version id
     */
    public getDeviceName(): string {
        let deviceName = this.deviceDefinition ? this.deviceDefinition.displayName : "N/A";
        if (this.lastSeenVersionSequence && this.lastSeenVersionSequence > 0) {
            deviceName = `${deviceName} (v${this.lastSeenVersionSequence})`;
        }
        return deviceName;
    }

    /**
     * Gets the value for the given column id.
     */
    public getColumnValue(columnHeaderId: string): any {
        return this[columnHeaderId];
    }

    public getDeviceState(): string {
        /**
         * TODO: TEMPORARLY COMMENTED OUT, CHECK THE LOGIC HERE
         */
        // return this.lastSeenVersionSequence > 0
        //     ? this.lastSeenVersionStatus
        //     : this.deviceDefinition.deviceDefinitionStatus;
        return this.lastSeenVersionStatus;
    }

    /**
     * Returns whether the status of device is secured.
     */
    public isSecured(): boolean {
        return this.status === DeviceStatus.SECURED;
    }

    /**
     * Gets the possible display names for device statuses.
     */
    public static getPossibleDeviceStatusesDisplayNames(): string[] {
        return [
            DeviceInfo.getStatusDisplay(DeviceStatus.SECURED),
            DeviceInfo.getStatusDisplay(DeviceStatus.SECURITY_ALERT),
        ];
    }

    /**
     * Returns whether the status of device is attach attempt.
     */
    public isAlertingStatus(): boolean {
        return this.status === DeviceStatus.SECURITY_ALERT;
    }

    /**
     * Gets the display name of the device status.
     */
    public getStatusDisplay(): string {
        return DeviceInfo.getStatusDisplay(this.status);
    }

    public getDeviceModeLabel(): string {
        switch (this.deviceMode) {
            case DeviceMode.Development:
                return "Development";
            case DeviceMode.Production:
                return "Production";
            default:
                return this.deviceMode;
        }
    }

    /**
     * Get Firmware version
     */
    public getFirmwareVersion(): string {
        return this.lastSeenVersionFirmware
            ? this.lastSeenVersionFirmware
            : this.deviceDefinition.deviceFirmwareVersion;
    }

    /**
     * Check if device definition version exists
     */
    public isDeviceDefinitionVersion(): boolean {
        return this.deviceDefinitionVersionExists ? true : false;
    }

    /**
     * Gets the display name of given device status.
     */
    public static getStatusDisplay(deviceStatus: DeviceStatus): string {
        switch (deviceStatus) {
            case DeviceStatus.SECURED:
                return "Secured";

            case DeviceStatus.SECURITY_ALERT:
                return "Security Alert";

            case DeviceStatus.CRITICAL_CVE:
                return "Critical CVE";

            case DeviceStatus.OUT_OF_DATE_LIBRARIES:
                return "Out of Date";

            case DeviceStatus.MULTIPLE_ISSUES:
                return "Multiple Issues";

            default:
                return "Unknown";
        }
    }

    /**
     * Gets the evaluated device status according to last attack.
     */
    public static getEvaluatedDeviceStatus(
        lastSeenAttack: number,
        cvesCount: number,
        outOfDateLibrariesCount: number
    ): DeviceStatus {
        let now = Utils.now();

        let isSecurityAlert = now - lastSeenAttack < SternumConfiguration.getSecurityAlertTimeWindowMillis();
        let hasCriticalCVE = cvesCount && cvesCount > 0;
        let hasOutOfDateLibraries = outOfDateLibrariesCount && outOfDateLibrariesCount > 0;

        let statusIndicators = (isSecurityAlert ? 1 : 0) + (hasCriticalCVE ? 1 : 0) + (hasOutOfDateLibraries ? 1 : 0);

        if (isSecurityAlert) {
            return DeviceStatus.SECURITY_ALERT;
        } else {
            if (statusIndicators > 1) {
                return DeviceStatus.MULTIPLE_ISSUES;
            } else if (statusIndicators === 1) {
                if (hasCriticalCVE) {
                    return DeviceStatus.CRITICAL_CVE;
                } else {
                    return DeviceStatus.OUT_OF_DATE_LIBRARIES;
                }
            } else {
                return DeviceStatus.SECURED;
            }
        }
    }
}

export default DeviceInfo;
