import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { uniqueId } from "lodash";
import React, { useMemo } from "react";
import HashMap from "../../lib/infra/HashMap";
import HashSet from "../../lib/infra/HashSet";
import ConfigurationService from "../../lib/services/ConfigurationService";
import DeviceDefinitionInfo from "../../lib/state/DeviceDefinitionInfo";
import QueryQuantifierType from "../../lib/state/QueryQuantifierType";
import SternumDeviceEventsFilter from "../../lib/state/SternumDeviceEventsFilter";
import SternumFilter from "../../lib/state/SternumFilter";
import SternumFilterValue from "../../lib/state/SternumFilterValue";
import SternumQuery from "../../lib/state/SternumQuery";
import TableToolbarDisplayState from "../../lib/state/TableToolbarDisplayState";
import SternumDeviceEventsList from "../SternumDeviceEventsList/SternumDeviceEventsList";
import useAlertDescriptionModalStyles from "./AlertDescriptionModalStyles";

const columnWidthArray = [10, 10, 10, 80];

const columnsToDisplay = HashSet.fromValues(["created", "deviceId", "eventCategory", "displayName"]);

const toolbarState = new TableToolbarDisplayState(
    false,
    false,
    true,
    false,
    true,
    false,
    true,
    false,
    "Search Event Name",
    true
);

interface AppProps {
    alertId: string;
    alertDisplayName: string;
    deviceDefinitionVersionId: string;
    deviceDefinitionInfo: DeviceDefinitionInfo;
    createdFrom: number;
    createdTo: number;
    fullScreenDisplay: boolean;
}

const AlertDescriptionModal = ({
    alertId,
    deviceDefinitionVersionId,
    deviceDefinitionInfo,
    createdFrom,
    createdTo,
    fullScreenDisplay,
    alertDisplayName,
}: AppProps) => {
    const { upperContentContainer, listPaper, tableContainer, tableInner, container } =
        useAlertDescriptionModalStyles();

    const filters = useMemo(() => {
        const query = new SternumQuery(
            uniqueId(),
            QueryQuantifierType.ALL,
            [
                new SternumFilter(
                    1,
                    ConfigurationService.getEventTypeArgumentField().id,
                    "EQUALS",
                    HashMap.fromPair("compareWithValue", new SternumFilterValue(alertId, alertDisplayName)),
                    false,
                    true
                ),
            ],
            []
        );

        return new SternumDeviceEventsFilter(null, false, createdFrom, createdTo, null, null, [], false, query, null);
    }, [alertId, alertDisplayName, createdFrom, createdTo]);

    return (
        <div className={container}>
            {/* Title Section */}
            <div className={upperContentContainer}>
                <Typography variant={"h5"}>{alertDisplayName}</Typography>
                {/**
                 * <AlertDescription alertId={alertId} deviceDefinitionVersionId={deviceDefinitionVersionId} />
                 */}
            </div>

            {/* List of issues */}
            <div className={tableContainer}>
                <div className={tableInner}>
                    <SternumDeviceEventsList
                        toolbarState={toolbarState}
                        doNotDisplayExploitationTypeInDisplayName={true}
                        columnWidthsArray={columnWidthArray}
                        doNotDisplayLoading={false}
                        infiniteScroll={true}
                        entityId={deviceDefinitionVersionId}
                        deviceDefinition={deviceDefinitionInfo}
                        viewedColumnsSet={columnsToDisplay}
                        entitiesFilter={filters}
                        deviceDefinitionVersionId={deviceDefinitionVersionId}
                        shouldDisplayLinkToDeviceView={true}
                        displayBackButtonInTraceView={true}
                        displayXButtonInTraceView={true}
                        emptyTableMessage={"Alerts will appear here."}
                        shouldNoWrapDisplayName={false}
                        paperClassNames={clsx({
                            [listPaper]: true,
                            ["mod-regular"]: !fullScreenDisplay,
                            ["mod-fullscreen"]: fullScreenDisplay,
                        })}
                        hideShowInContext={true}
                    />
                </div>
            </div>
        </div>
    );
};

export default AlertDescriptionModal;
