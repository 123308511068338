import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { last } from "lodash";
import classNames from "classnames";
import { Box, IconButton, Link, Tooltip, Typography } from "@material-ui/core";

import WebUtils from "../../../lib/infra/WebUtils";
import { showNotificationAction } from "../../../lib/redux/notifications/ShowNotificationAction";
import ServiceWire from "../../../lib/services/ServiceWire";
import APIKeyInfo from "../../../lib/state/APIKeyInfo";
import { GlobalState } from "../../../lib/state/GlobalState";
import { ClientInfoTier } from "../../../lib/state/ClientInfo.types";
import { NotificationMessage } from "../../../lib/state/NotificationsState";
import { CopyFilesIcon, DiamondIcon, KeyIcon } from "../../SUI/SternumIcon";
import { useCommonStyle } from "../../CommonStyle";
import useApiKeyGenerationStyle from "./ApiKeyGenerationStyle";
import { Chip } from "../Chip";

export interface ApiKeyGenerationProps {}

const mapStateToProps = (state: GlobalState, ownProps: any) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        showNotification: (message: NotificationMessage) => dispatch(showNotificationAction(message)),
        isFreeUser: ServiceWire.getClientsService().getSelectedClient().tier === ClientInfoTier.Trial,
    };
};

type ApiKeyGenerationPropsWithHOC = ApiKeyGenerationProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

function ApiKeyGenerationComponent(props: ApiKeyGenerationPropsWithHOC) {
    const classes = useApiKeyGenerationStyle();
    const classesCommon = useCommonStyle();
    const [apiKeys, setApiKeys] = useState<APIKeyInfo[]>([]);

    const apiKey = last(apiKeys);

    const clientId = ServiceWire.getClientsService().getSelectedClientId();

    useEffect(() => {
        if (props.isFreeUser) {
            return;
        }

        ServiceWire.getSternumService()
            .getAPIKeys(clientId)
            .then((apiKeys) => {
                setApiKeys(apiKeys);
            });
    }, [clientId]);

    const handleGenerateClick = useCallback(async () => {
        const newApiKey = await ServiceWire.getSternumService().createAPIKey(clientId, "");
        setApiKeys([newApiKey]);

        props.showNotification("API key successfully generated");
    }, [clientId]);

    const handleCopyClick = useCallback(() => {
        if (!apiKey.secret) return;

        WebUtils.copyContentToClipBoard(apiKey.secret);
        props.showNotification("API key is copied to the clipboard");
    }, [apiKey]);

    const renderFreeUserGenerateApiKey = () => (
        <>
            <Typography variant="body2" color="textSecondary" className={classesCommon.marginRight}>
                Generate API Key
            </Typography>

            <Tooltip
                title={<Typography variant="caption">API Key is not available in Free Trial</Typography>}
                placement="right"
            >
                <div
                    className={classNames(
                        classes.apiKeyContainer,
                        classesCommon.flexSpaceBetween,
                        classesCommon.flexVMiddle
                    )}
                >
                    <Typography
                        variant="body2"
                        className={classNames(classesCommon.marginRight, classes.inactiveColor)}
                    >
                        Key will be generated here
                    </Typography>

                    <div className={classNames(classesCommon.flexVMiddle)}>
                        <IconButton className={classes.noPadding} disabled={!apiKey} onClick={handleCopyClick}>
                            <KeyIcon />
                        </IconButton>
                    </div>
                </div>
            </Tooltip>

            <div className={classesCommon.marginTop}>
                <div className={classes.disabledLink}>API Documentation</div>
            </div>
        </>
    );

    const renderNotFreeUserGenerateApiKey = () => (
        <>
            <Typography variant="body2" color="textSecondary" className={classesCommon.marginRight}>
                Generate API Key
            </Typography>

            <div
                className={classNames(
                    classes.apiKeyContainer,
                    classesCommon.flexSpaceBetween,
                    classesCommon.flexVMiddle
                )}
            >
                <Typography
                    variant="body2"
                    className={classNames(classesCommon.marginRight, !apiKey && classes.inactiveColor)}
                >
                    {apiKey?.secret || "Key will be generated here"}
                </Typography>

                <div className={classNames(classesCommon.flexVMiddle)}>
                    {!Boolean(apiKey) && (
                        <Tooltip title="Generate">
                            <IconButton
                                className={classNames(classes.noPadding, classesCommon.marginRight)}
                                onClick={handleGenerateClick}
                            >
                                <KeyIcon color="#8B949E" />
                            </IconButton>
                        </Tooltip>
                    )}

                    <Tooltip title="Copy">
                        <IconButton className={classes.noPadding} disabled={!apiKey} onClick={handleCopyClick}>
                            <CopyFilesIcon color="#8B949E" />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>

            <div className={classesCommon.marginTop}>
                <Link href="/api-docs" target="_blank" className={classes.docsLink}>
                    API Documentation
                </Link>
            </div>
        </>
    );

    return (
        <div>
            <div>
                <Typography
                    variant="h6"
                    className={classNames(classes.titleWithChip, classesCommon.marginRight, classesCommon.extraBold)}
                >
                    Sternum API
                    {props.isFreeUser && (
                        <Chip>
                            <DiamondIcon /> Premium
                        </Chip>
                    )}
                </Typography>
                <Box mt={1}>
                    <Typography variant="body2" color="textSecondary">
                        You can generate a key only once
                    </Typography>
                </Box>
            </div>
            <div className={classNames(classesCommon.marginBottomLarge, classesCommon.marginTopLarge)}>
                {props.isFreeUser ? renderFreeUserGenerateApiKey() : renderNotFreeUserGenerateApiKey()}
            </div>
        </div>
    );
}

export const ApiKeyGeneration: React.FC<ApiKeyGenerationProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(ApiKeyGenerationComponent);
