import { Theme } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

export const useShareExperienceDialogStyle = makeStyles((theme: Theme) => ({
    root: {
        fontFamily: "Inter",
        padding: "0 18px",
    },

    paper: {
        position: "relative",
        width: "367px",
        boxShadow: "0px 0px 50px rgba(0, 0, 0, 0.12)",
        borderRadius: "8px",

        "& .MuiDialogActions-root": {
            justifyContent: "flex-start",
        },
    },

    dialogTitle: {
        paddingBottom: 0,
    },

    dialogContent: {
        padding: "1px 18px",
    },

    closeButton: {
        position: "absolute",
        top: 18,
        right: 18,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 20,
        height: 20,
        cursor: "pointer",
    },

    title: {
        fontSize: 20,
        lineHeight: "20px",
        fontWeight: 600,
    },

    subTitle: {
        fontSize: 14,
        lineHeight: "22px",
        color: "#555B61",
        margin: "8px 65px 0 0",
    },

    selectLabel: {
        margin: "8px 0",
        fontSize: 12,
        lineHeight: "20px",
        color: "#999999",
    },

    selectComponent: {
        cursor: "pointer",

        "& > div:nth-of-type(1) > div:first-child": {
            padding: "18.5px 14px",
        },
    },

    actions: {
        "&&": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: 24,
            paddingTop: 16,
        },
    },

    linkButton: {
        fontSize: 16,
        fontWeight: 500,
        color: "#000",
    },
}));
