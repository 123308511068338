class DeviceDefinitionLibraryPartial {
    /**
     * Constructor.
     */
    constructor(
        public libraryName: string,
        public libraryVersion: string,
        public libraryVendor: string,
        public librarySource?: string
    ) {}

    // Convert object to json
    toJsonObject() {
        let response = {
            library_name: this.libraryName,
            library_version: this.libraryVersion,
            library_vendor: this.libraryVendor,
        };
        if (this.librarySource) {
            response["library_source"] = this.librarySource;
        }
        return response;
    }

    /**
     * Sets the library name.
     */
    public setLibraryName(libraryName: string) {
        this.libraryName = libraryName;
    }

    /**
     * Sets the library version.
     */
    public setLibraryVersion(libraryVersion: string) {
        this.libraryVersion = libraryVersion;
    }

    /**
     * Sets the library vendor.
     */
    public setLibraryVendor(libraryVendor: string) {
        this.libraryVendor = libraryVendor;
    }

    /**
     * Get library name
     */
    public getLibraryName() {
        return this.libraryName;
    }

    /**
     * Get library version
     */
    public getLibraryVersion() {
        return this.libraryVersion;
    }

    /**
     * Get library vendor
     */
    public getLibraryVendor() {
        return this.libraryVendor;
    }

    /**
     * Get library source url
     */
    public getLibrarySource() {
        return this.librarySource;
    }
}

export default DeviceDefinitionLibraryPartial;
