import { Action } from "redux";
import ActionType from "../ActionType";

/**
 * Defines the data for a trace not being viewed anymore action.
 */
export interface GeneratedEventNotViewedAction extends Action<ActionType> {
    type: ActionType.GeneratedEventNotViewed;
}

/**
 * Action creator for trace not being viewed anymore.
 */
export function generatedEventNotViewedAction(): GeneratedEventNotViewedAction {
    return {
        type: ActionType.GeneratedEventNotViewed,
    };
}
