import EntityBase from "./EntityBase";
import EntityType from "./EntityType";
import TableRowData from "./TableRowData";

class PermissionInfo extends EntityBase implements TableRowData {
    /**
     * Constructor.
     */
    private resourceName: string;
    private operation: string;

    constructor(public roleId: string, public created: number, public updated: number, public permissionName: string) {
        super(roleId, created, updated, EntityType.Permission);
        const permissionInfo = permissionName.split("_");
        this.operation = permissionInfo[1];
        this.resourceName = permissionInfo.slice(2).join("_");
    }

    public static fromJsonObject(jsonObject: Object) {
        return new PermissionInfo(
            jsonObject["entity_id"],
            jsonObject["created"],
            jsonObject["updated"],
            jsonObject["permission_name"]
        );
    }

    /**
     * Returns whether given row is any different in details than current issue info.
     * Shallow comparison, in other words.
     * @param other The row to compare with.
     */
    public isDifferentFrom(other: TableRowData): boolean {
        // Row must of of type issue info.
        if (!(other instanceof PermissionInfo)) {
            return true;
        }

        let otherRoleInfo = other as PermissionInfo;

        // Comparing base properties.
        if (
            otherRoleInfo.entityId !== this.entityId ||
            otherRoleInfo.created !== this.created ||
            otherRoleInfo.updated !== this.updated
        ) {
            return true;
        }

        return false;
    }

    /**
     * Gets the value for the given column id.
     */
    public getColumnValue(columnHeaderId: string): any {
        return this[columnHeaderId];
    }

    public getResourceName(): string {
        return this.resourceName;
    }

    public getOperationName(): string {
        return this.operation;
    }
}

export default PermissionInfo;
