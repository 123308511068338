import { Link, Typography } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { openCreateDashboardModalAction } from "../../../lib/redux/modals/OpenModalAction";
import ServiceWire from "../../../lib/services/ServiceWire";
import CustomDashboardInfo from "../../../lib/state/CustomDashboardInfo";
import { GlobalState } from "../../../lib/state/GlobalState";
import ServerEntityType from "../../../lib/state/ServerEntityType";
import DashboardViewer from "../DashboardViewer/DashboardViewer";
import singleDashboardPageStyle from "./SingleDashboardPageStyle";

interface AppState {
    loadingDashboards: boolean;
    errorLoadingDashboards: boolean;
    customDashboard: CustomDashboardInfo;
    isDisabled: boolean;
    customDashboardId: string;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof singleDashboardPageStyle> {
    sidebarOpen: boolean;
    openCreateDashboardModal?: (key: string, onCreateOperation: (customDashboard: CustomDashboardInfo) => void) => void;
    history;
    match;
}

const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        openCreateDashboardModal: (key: string, onCreateOperation: (customDashboard: CustomDashboardInfo) => void) =>
            dispatch(openCreateDashboardModalAction(key, onCreateOperation)),
    };
};

class SingleDashboardPage extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);
        // Initializing the state to default.
        this.state = {
            loadingDashboards: true,
            errorLoadingDashboards: false,
            customDashboard: null,
            isDisabled: !ServiceWire.getAuthorizationService().canEdit(ServerEntityType.CUSTOM_DASHBOARD),
            customDashboardId: this.props.match.params.dashboardId,
        };
    }

    async componentDidMount() {
        if (!this.props.match.params.dashboardId) {
            await this.handleEmptyState();
        }
    }

    async UNSAFE_componentWillReceiveProps(nextProps: Readonly<AppProps>, nextContext: any) {
        if (this.state.customDashboardId !== nextProps.match.params.dashboardId) {
            if (nextProps.match.params.dashboardId) {
                this.setState({ customDashboardId: nextProps.match.params.dashboardId });
            } else {
                await this.handleEmptyState();
            }
        }
    }

    render() {
        const { classes } = this.props;
        if (this.state.customDashboardId) {
            return <DashboardViewer dashboardId={this.state.customDashboardId} />;
        } else {
            if (this.state.loadingDashboards) {
                return <></>;
            } else {
                return this.getNoContentMessage();
            }
        }
    }

    private getNoContentMessage() {
        const { classes } = this.props;
        // No content text

        let noContentElement = (
            <div
                key="noContentKey"
                className={classNames(classes.fullViewportHeight, classes.flexVMiddle, classes.flexCenter)}
            >
                <Typography variant="h6">Oops! You don't have any views yet. Click&nbsp;</Typography>
                <Typography variant="h6" className={classes.redirectLink}>
                    <Link
                        onClick={() =>
                            this.props.openCreateDashboardModal("CreateDashboardModal", this.handleEmptyState)
                        }
                    >
                        here
                    </Link>
                </Typography>
                <Typography variant="h6">&nbsp;to create.</Typography>
            </div>
        );
        if (this.state.isDisabled) {
            noContentElement = (
                <div
                    key="noContentKey"
                    className={classNames(classes.fullViewportHeight, classes.flexVMiddle, classes.flexCenter)}
                >
                    <Typography variant="h6">Oops! You don't have any views yet.</Typography>
                </div>
            );
        }

        return noContentElement;
    }

    private async loadDashboard() {
        try {
            this.setState({
                loadingDashboards: true,
                errorLoadingDashboards: false,
            });
            const dashboardEntities: CustomDashboardInfo[] =
                await ServiceWire.getCustomDashboardsApiService().getDefaultDashboardSummery(
                    ServiceWire.getClientsService().getSelectedClientId()
                );

            const defaultDashboardFromStorage = ServiceWire.getClientsService().getCustomDashboard();
            const defaultDashboard = this.findDefaultCustomDashboard(defaultDashboardFromStorage, dashboardEntities);

            this.setState({
                loadingDashboards: false,
                errorLoadingDashboards: false,
                customDashboard: defaultDashboard ? defaultDashboard : dashboardEntities[0] || null,
            });
        } catch {
            this.setState({
                loadingDashboards: false,
                errorLoadingDashboards: true,
            });
        }
    }

    private findDefaultCustomDashboard(
        dashboardToFind: string,
        listOfDashboards: CustomDashboardInfo[]
    ): null | CustomDashboardInfo {
        if (dashboardToFind) {
            return listOfDashboards.find((dashboardEntity) => dashboardEntity.entityId === dashboardToFind);
        }
        return null;
    }

    private handleEmptyState = async () => {
        await this.loadDashboard();
        // If boards exists redirect to latest board
        if (this.state.customDashboard) {
            this.redirectToDashboardPage(this.state.customDashboard);
        } else {
            this.setState({ customDashboardId: null });
        }
    };

    private redirectToDashboardPage(dashboard: CustomDashboardInfo) {
        this.props.history.push({
            pathname: `/glances/${dashboard.customDashboardId}`,
        });
        this.setState({ customDashboardId: dashboard.customDashboardId });
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(singleDashboardPageStyle)(SingleDashboardPage)));
