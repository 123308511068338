import { WithStyles, withStyles } from "@material-ui/core/styles";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TimeAgo from "react-timeago";
import HashSet from "../../lib/infra/HashSet";
import SternumConfiguration from "../../lib/infra/SternumConfiguration";
import WebUtils from "../../lib/infra/WebUtils";
import { openUsedLibraryViewModalAction } from "../../lib/redux/modals/OpenModalAction";
import AnalyticsService from "../../lib/services/AnalyticsService";
import DebounceService from "../../lib/services/DebounceService";
import ServiceWire from "../../lib/services/ServiceWire";
import EntityType from "../../lib/state/EntityType";
import { GlobalState } from "../../lib/state/GlobalState";
import HttpResponse from "../../lib/state/HttpResponse";
import SternumQuery from "../../lib/state/SternumQuery";
import TableColumnHeaderInfo from "../../lib/state/TableColumnHeaderInfo";
import TableToolbarDisplayState from "../../lib/state/TableToolbarDisplayState";
import UsedLibrariesFilter from "../../lib/state/UsedLibrariesFilter";
import UsedLibraryInfo from "../../lib/state/UsedLibraryInfo";
import UsedLibraryStatus from "../../lib/state/UsedLibraryStatus";
import EntityFilterPopover from "../EntityFilterPopover/EntityFilterPopover";
import StatusDisplay from "../StatusDisplay/StatusDisplay";
import SternumLink from "../SUI/SternumLink/SternumLink";
import SternumTable from "../SUI/SternumTable/SternumTable";
import usedLibrariesListStyle from "./UsedLibrariesListStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    // Loading & error state.
    loadingUsedLibraries: boolean;
    errorLoadingUsedLibraries: boolean;

    // Used libraries.
    usedLibraries: UsedLibraryInfo[];
    totalItemCount: number;

    // Filtering.
    searchText: string;
    orderByField: string;
    order: "asc" | "desc";

    sternumQuery: SternumQuery;

    displayLoadingIcon: boolean;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof usedLibrariesListStyle> {
    includeColumns: string[];

    hideToolbar?: boolean;
    hidePagination?: boolean;
    hideUpperBorder?: boolean;

    displayXButtonInUsedLibraryView?: boolean;
    displayBackButtonInUsedLibraryView?: boolean;
    viewedColumnsSet?: HashSet;

    location;
    history;

    paperClassNames?;

    openUsedLibraryViewModal?: (
        key: string,
        usedLibraryId: string,
        displayXButton: boolean,
        displayBackButton: boolean
    ) => void;

    showAllColumns?: boolean;

    isExpanded?: boolean;
    onExpandToggle?: () => void;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {
        openUsedLibraryViewModal: (key, usedLibraryId, displayXButton, displayBackButton) =>
            dispatch(openUsedLibraryViewModalAction(key, usedLibraryId, displayXButton, displayBackButton)),
    };
};

/**
 * Holds the used libraries list in the app.
 */
class UsedLibrariesList extends React.Component<AppProps, AppState> {
    /**
     * Defines the column headers participating in the used libraries table.
     */
    private readonly columnHeaders: TableColumnHeaderInfo[] = [
        new TableColumnHeaderInfo("libraryId", "Library Id", false, true, true),
        new TableColumnHeaderInfo("libraryName", "Name", false, true, false),
        new TableColumnHeaderInfo("libraryStatus", "Status", false, true, false),
        new TableColumnHeaderInfo("version", "Current Version", false, true, false),
        new TableColumnHeaderInfo("latestKnownVersion", "Latest Version", false, true, false),
        new TableColumnHeaderInfo("created", "Created", false, true, true),
        new TableColumnHeaderInfo("updated", "Information Collected", false, true, false),
    ];

    /**
     * Gets the display values for row's columns (where they aren't the default).
     */
    private getRowDisplayValues(row) {
        let usedLibraryInfo = row as UsedLibraryInfo;
        const { classes } = this.props;

        return {
            entityId: usedLibraryInfo.entityId,
            libraryId: usedLibraryInfo.libraryId,
            libraryName: (
                <SternumLink onClick={() => this.handleRowClicked(usedLibraryInfo)}>
                    {usedLibraryInfo.library.name}
                </SternumLink>
            ),
            libraryStatus: (
                <StatusDisplay
                    label={usedLibraryInfo.getStatusDisplay()}
                    danger={usedLibraryInfo.isAlertingStatus()}
                    medium={
                        usedLibraryInfo.status === UsedLibraryStatus.OUT_OF_DATE_LIBRARIES ||
                        usedLibraryInfo.status === UsedLibraryStatus.NO_INFORMATION
                    }
                    success={
                        !usedLibraryInfo.isAlertingStatus() &&
                        usedLibraryInfo.status !== UsedLibraryStatus.OUT_OF_DATE_LIBRARIES &&
                        usedLibraryInfo.status !== UsedLibraryStatus.NO_INFORMATION
                    }
                    displayColoredBackground={true}
                    centerText={true}
                    padding={true}
                />
            ),
            version: usedLibraryInfo.version,
            created: <TimeAgo date={usedLibraryInfo.created} />,
            updated: <TimeAgo date={usedLibraryInfo.updated} />,
            latestKnownVersion: usedLibraryInfo.library.latestKnownVersion || "",
        };
    }

    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        if (this.props.includeColumns) {
            if (this.props.includeColumns.length) {
                let includeColumnsSet = HashSet.fromValues(this.props.includeColumns);
                this.columnHeaders = this.columnHeaders.filter((columnHeader) =>
                    includeColumnsSet.exists(columnHeader.id)
                );
            } else {
                this.columnHeaders = [];
            }
        }

        if (this.props.showAllColumns) {
            for (let i = 0; i < this.columnHeaders.length; i++) {
                this.columnHeaders[i].isHidden = false;
            }
        }

        // Initializing the state to default.
        this.state = {
            loadingUsedLibraries: false,
            errorLoadingUsedLibraries: false,

            usedLibraries: [],

            searchText: null,
            orderByField: null,
            order: "asc",

            totalItemCount: null,

            sternumQuery: SternumQuery.getEmptyQuery(),

            displayLoadingIcon: false,
        };
    }

    /**
     * Occurs once the component finished its initialization process.
     */
    async componentDidMount() {
        await this.loadUsedLibraries(1);
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        return (
            <SternumTable
                nonClickableRows
                isTableExpanded={this.props.isExpanded}
                onTableExpandToggle={this.props.onExpandToggle}
                maxTableHeightClass={classes.limitHeight}
                hideUpperBorder={this.props.hideUpperBorder}
                hideToolbar={this.props.hideToolbar}
                hidePagination={this.props.hidePagination}
                pageSize={SternumConfiguration.getPageSize()}
                amountOfLoadingPlaceholders={3}
                totalItemCount={this.state.totalItemCount}
                loadingItems={this.state.loadingUsedLibraries}
                errorLoadingItems={this.state.errorLoadingUsedLibraries}
                columnHeaders={this.columnHeaders}
                onRefreshClicked={() => this.onRefreshClicked()}
                viewedColumnsSet={this.props.viewedColumnsSet}
                sternumQuery={this.state.sternumQuery}
                listFilter={new UsedLibrariesFilter()}
                isFilterActive={this.state.sternumQuery && !this.state.sternumQuery.isEmpty()}
                filterComponentProvider={(onFilterChanged) => (
                    <EntityFilterPopover
                        onSternumQueryApplied={(sternumQuery) => this.onSternumQueryApplied(sternumQuery)}
                        entityType={EntityType.UsedLibrary}
                        sternumQuery={this.state.sternumQuery}
                    />
                )}
                orderByField={this.state.orderByField}
                order={this.state.order}
                rows={this.state.usedLibraries}
                onSearchTextChanged={(searchText) => this.handleSearchTextChanged(searchText)}
                onPageChanged={(pageNumber) => this.loadUsedLibraries(pageNumber)}
                onOrderChanged={(orderByField, order) => this.handleOrderChanged(orderByField, order)}
                getRowValues={(row) => this.getRowDisplayValues(row)}
                toolbarState={
                    new TableToolbarDisplayState(
                        true,
                        false,
                        true,
                        false,
                        false,
                        false,
                        false,
                        false,
                        "Search Library Name",
                        true,
                        true
                    )
                }
                onExportToCsvClicked={() => this.onExportToCsvClicked("CSV")}
                onExportToXlsxClicked={() => this.onExportToCsvClicked("XLSX")}
                displayLoadingIcon={this.state.displayLoadingIcon}
            />
        );
    }

    /**
     * Occurs once the user refreshes the list.
     */
    private async onRefreshClicked() {
        await this.loadUsedLibraries(1);
    }

    /**
     * Occurs on a details action click.
     */
    private handleRowClicked(usedLibraryInfo: UsedLibraryInfo) {
        // Opens the used library view modal.
        this.props.openUsedLibraryViewModal(
            usedLibraryInfo.entityId,
            usedLibraryInfo.entityId,
            this.props.displayXButtonInUsedLibraryView,
            this.props.displayBackButtonInUsedLibraryView
        );
    }

    /**
     * Occurs once search text is changed.
     */
    private handleSearchTextChanged(searchText) {
        // Debounce request to search used libraries.
        DebounceService.debounce(
            "getUsedLibraries",
            () => {
                this.setState(
                    {
                        searchText: searchText,
                    },
                    () => this.loadUsedLibraries(1)
                );
            },
            350
        );
    }

    /**
     * Occurs once sort order is changed.
     */
    private handleOrderChanged(orderByField, order) {
        this.setState(
            {
                orderByField: orderByField,
                order: order,
            },
            () => this.loadUsedLibraries(1)
        );
    }

    /**
     * Loads used libraries.
     */
    private async loadUsedLibraries(pageNumber) {
        try {
            // Setting loading to true.
            this.setState({
                loadingUsedLibraries: true,
                errorLoadingUsedLibraries: false,
            });

            // Fetching used libraries.

            let defaultClientId = ServiceWire.getClientsService().getSelectedClientId();
            let usedLibraries = await ServiceWire.getSternumService().getUsedLibraries(
                defaultClientId,
                new UsedLibrariesFilter(),
                this.state.searchText,
                this.state.orderByField,
                this.state.order
            );

            this.setState({
                loadingUsedLibraries: false,
                errorLoadingUsedLibraries: false,
                usedLibraries: usedLibraries,
                totalItemCount: usedLibraries.length,
            });
        } catch (error) {
            AnalyticsService.error("UsedLibrariesList:loadUsedLibraries", error.message);

            this.setState({
                loadingUsedLibraries: false,
                errorLoadingUsedLibraries: true,
            });
        }
    }

    /**
     * Occurs once the sternum query is applied.
     */
    private onSternumQueryApplied(sternumQuery: SternumQuery) {
        this.setState(
            {
                sternumQuery: sternumQuery,
            },
            async () => {
                await this.loadUsedLibraries(1);
            }
        );
    }

    /**
     * Occurs when the export to csv button clicked.
     */
    private async onExportToCsvClicked(format: "CSV" | "XLSX") {
        this.setState({ displayLoadingIcon: true });
        const serverResponse: HttpResponse = await ServiceWire.getSternumService().exportClient3rdPartyLibraries(
            ServiceWire.getClientsService().getSelectedClientId(),
            format
        );

        this.setState({ displayLoadingIcon: false });

        // Download the report.
        WebUtils.downloadReport(serverResponse);
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(usedLibrariesListStyle)(UsedLibrariesList)));
