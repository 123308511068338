import { makeStyles } from "@material-ui/core";

export const useTraceSequenceStyle = makeStyles((theme) => {
    return {
        container: {
            flex: "1 1 1px",
            display: "flex",
            flexDirection: "row",
            gap: 32,
            overflow: "hidden",
        },
        column: {
            flex: 1,
            overflowY: "auto",
        },
    };
});

export const useTraceSequenceSharedStyle = makeStyles((theme) => {
    const secondaryLighterDarker3 = "#e8ecf2";

    return {
        beforeTableHeader: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: 10,
            padding: "10px 14px",
            height: 46,
            borderBottom: `1px solid ${secondaryLighterDarker3}`,
        },
    };
});
