import ReceivedDefinitionsResponse from "../../../lib/services/events/ReceivedDefinitionsResponse";

export default function getTraceDefinitionDisplayNameFromReceivedDefinitions(
    receivedDefinitionsResponse: ReceivedDefinitionsResponse,
    traceDefinitionId: string
): string | undefined {
    const foundDefinition = receivedDefinitionsResponse.receivedDefinitions.find(
        (receivedDefinition) => receivedDefinition.identifier === traceDefinitionId
    );

    if (foundDefinition) {
        return foundDefinition.displayName;
    } else {
        return undefined;
    }
}
