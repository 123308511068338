import { Action } from "redux";
import ActionType from "../ActionType";
import FetchError from "../../state/FetchError";

/**
 * Occurs once there has been an error fetching a device from API.
 */
export interface ErrorFetchingDeviceAction extends Action<ActionType> {
    type: ActionType.ErrorFetchingDevice;
    deviceId: string;
    error: FetchError;
}

/**
 * Occurs once there has been an error fetching a device from API.
 */
export function errorFetchingDeviceAction(deviceId: string, error: FetchError): ErrorFetchingDeviceAction {
    return {
        type: ActionType.ErrorFetchingDevice,
        deviceId: deviceId,
        error: error,
    };
}
