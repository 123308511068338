import AuthenticationCommandResponse from "../state/AuthenticationCommandResponse";
import RoleInfo from "../state/RoleInfo";
import UserInfo from "../state/UserInfo";
import AnalyticsService from "./AnalyticsService";
import ServiceWire from "./ServiceWire";
import SternumService from "./SternumService";

/**
 * Service responsible for all outgoing calls to the sternum REST API.
 */
class AuthenticationService {
    /**
     * Holds the authenticated user for the application.
     */
    private authenticatedUser: UserInfo;

    /**
     * The roles of the authenticated user.
     */
    private authenticatedUserRolesMap: Record<string, RoleInfo[]>;

    private forgotPasswordEmail: string;

    private validationToken: string;

    private reCaptchaKey = process.env.STERNUM_RECAPTCHA;

    private captchaIsReady: boolean;

    /**
     * Constructor.
     */
    constructor(private sternumService: SternumService) {}

    /**
     * Get the state of remember me from local storage.
     */
    public getRememberMe() {
        return localStorage.getItem("remember_me") === "yes" ? true : false;
    }

    /**
     * Set remember me state in local storage.
     */
    public setRememberMe(rememberMe: boolean) {
        localStorage.setItem("remember_me", rememberMe ? "yes" : "no");
    }

    /**
     * Fetches the authenticated user.
     */
    public async fetchAuthenticatedUser() {
        const userId = localStorage.getItem("user_id");
        const authenticationToken = localStorage.getItem("authentication_token");

        if (userId !== null && authenticationToken !== null) {
            try {
                this.authenticatedUser = await this.sternumService.getAuthenticatedUser();
            } catch (error) {
                // We don't need the error here to propagate.
                this.authenticatedUser = null;
            }
        }
    }

    /**
     * Returns whether user is already authenticated.
     * @returns {boolean}
     */
    public isAuthenticated(): boolean {
        return !!this.authenticatedUser;
    }

    /**
     * Gets the cached authenticated user for the application.
     */
    public getCachedAuthenticatedUser(): UserInfo {
        return this.authenticatedUser;
    }

    /**
     * Authenticates by given email and password.
     */
    public async authenticate(
        email: string,
        password: string,
        adminLogin: boolean,
        captchaToken?: string
    ): Promise<AuthenticationCommandResponse | null> {
        const response: AuthenticationCommandResponse = await this.sternumService.authenticate(
            email,
            password,
            adminLogin,
            captchaToken,
            this.getRememberMe()
        );

        const success = await this.handleLogin(response);
        return success ? response : null;
    }

    /**
     * Authenticates given email and password.
     */
    public async resendMfaToken(jwt_token: string): Promise<boolean> {
        try {
            const response: AuthenticationCommandResponse = await this.sternumService.resendMfaToken(jwt_token);
            if (response.authenticationType) {
                window.location.href = response.getRedirectURL();
            }
        } catch (error) {
            // If we received an error, we're not authenticated!
            return false;
        }
    }

    /**
     * Authenticates given email and password.
     */
    public async resendEmailVerificationToken(jwt_token: string): Promise<boolean> {
        try {
            const response = await this.sternumService.resendEmailVerificationToken(jwt_token);
            return true;
        } catch (error) {
            // If we received an error, we're not authenticated!
            return false;
        }
    }

    /**
     * Validate MFA code
     */
    public async validateMFA(mfaToken: string, jwtToken: string): Promise<AuthenticationCommandResponse | null> {
        try {
            let response: AuthenticationCommandResponse = await this.sternumService.authenticateMFA(
                mfaToken,
                jwtToken,
                this.getRememberMe()
            );

            await this.handleLogin(response);

            return response;
        } catch (error) {
            // If we received an error, we're not authenticated!
            return null;
        }
    }

    private async handleLogin(response: AuthenticationCommandResponse) {
        if (!response.authenticationType) {
            // Saving authentication details.
            try {
                localStorage.setItem("user_id", response.entity_id);
                AnalyticsService.setUserInfo(
                    response.entity_id,
                    response.default_client && response.default_client.entity_id
                );
                AnalyticsService.track("login");

                this.overrideAuthenticationToken(response.authentication_token);

                // Fetching the new authenticated user!
                await this.fetchAuthenticatedUser();

                // Load clients for application.
                await ServiceWire.getClientsService().loadClients();

                return true;
            } catch (error) {
                return false;
            }
        }

        return true;
    }

    /**
     * Overrides the saved authentication token.
     */
    public overrideAuthenticationToken(authenticationToken: string) {
        localStorage.setItem("authentication_token", authenticationToken);
    }

    /**
     * Logout existing user.
     */
    public async logout(): Promise<void> {
        // Revoking token.
        await this.sternumService.logout();
    }

    /**
     * Clear local storage from current user active session data
     */
    public clearDataFromStorage(): void {
        // Removing saved tokens.
        localStorage.removeItem("user_id");
        localStorage.removeItem("authentication_token");
    }

    /**
     * Set authenticated user to null (mark as not authenticated)
     */
    public clearAuthenticatedUser(): void {
        this.authenticatedUser = null;
    }

    /**
     * Set email for forgot password operation
     */
    public setForgotPasswordEmail(email: string) {
        this.forgotPasswordEmail = email;
    }

    /**
     * Get email for forgot password operation
     */
    public getForgotPasswordEmail(): string {
        return this.forgotPasswordEmail;
    }

    /**
     * Set validation token for forgot password operation
     */
    public setValidationToken(token: string) {
        this.validationToken = token;
    }

    /**
     * Get forgot password token
     */
    public getValidationToken(): string {
        return this.validationToken;
    }

    /**
     * Get token from url if exists
     */
    public loadTokenFromUrl(): string {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const token = params.get("token");
        return token;
    }

    public updateCaptcha() {
        this.captchaIsReady = true;
    }

    public getCaptchaKey() {
        return this.reCaptchaKey;
    }

    public setUser(user: UserInfo) {
        this.authenticatedUser = user;
    }
}

export default AuthenticationService;
