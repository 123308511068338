import ActivityInfo from "./ActivityInfo";

/**
 * Response of the get activities command.
 */
class GetActivitiesResponse {
    /**
     * Constructor.
     */
    constructor(public activities: ActivityInfo[], public totalItemCount: number) {}
}

export default GetActivitiesResponse;
