import { Paper } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EntityManager from "../../lib/infra/EntityManager";
import HashSet from "../../lib/infra/HashSet";
import SternumConfiguration from "../../lib/infra/SternumConfiguration";
import { openTraceViewModalAction } from "../../lib/redux/modals/OpenModalAction";
import DebounceService from "../../lib/services/DebounceService";
import PollingSubscriptionInfo from "../../lib/services/PollingSubscriptionInfo";
import ServiceWire from "../../lib/services/ServiceWire";
import DeviceInfo from "../../lib/state/DeviceInfo";
import { GlobalState } from "../../lib/state/GlobalState";
import ListFilter from "../../lib/state/ListFilter";
import ModalKey from "../../lib/state/ModalKey";
import ModalType from "../../lib/state/ModalType";
import PollingChangeType from "../../lib/state/PollingChangeType";
import TableColumnHeaderInfo from "../../lib/state/TableColumnHeaderInfo";
import TableToolbarDisplayState from "../../lib/state/TableToolbarDisplayState";
import TimeDivisionType from "../../lib/state/TimeDivisionType";
import TraceInfo from "../../lib/state/TraceInfo";
import TracesFilter from "../../lib/state/TracesFilter";
import ArgumentsListDisplay from "../ArgumentsListDisplay/ArgumentsListDisplay";
import EventStatusListDisplay from "../EventStatusListDisplay/EventStatusListDisplay";
import StatusDisplay from "../StatusDisplay/StatusDisplay";
import SternumLink from "../SUI/SternumLink/SternumLink";
import SternumTable from "../SUI/SternumTable/SternumTable";
import TracesListFilterPopover from "../TracesListFilterPopover/TracesListFilterPopover";
import tracesListStyle from "./TracesListStyle";
import moment from "moment";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    // Loading & error state.
    loadingTraces: boolean;
    errorLoadingTraces: boolean;

    // Traces.
    traces: TraceInfo[];
    totalItemCount: number;
    pageSize: number;

    // Filtering.
    tracesFilter: TracesFilter;
    searchText: string;
    orderByField: string;
    order: "asc" | "desc";

    // Refreshing.
    newEvents: number;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof tracesListStyle> {
    issueId?: string;
    deviceId?: string;
    device?: DeviceInfo;
    tracesFilter?: TracesFilter;
    includeColumns: string[];
    markAttackRows?: boolean;
    markRowIds?: HashSet;
    toolbarState?: TableToolbarDisplayState;
    isSearchInputDisabled?: boolean;

    doNotIncludeFilter?: boolean;

    fetchAdjacentTraces?: boolean;
    adjacentTracesCount?: number;
    adjacentTracesToTrace?: TraceInfo;

    fetchAnomalyTraces?: boolean;
    viewedColumnsSet?: HashSet;

    timeFrame?: number;
    timeDivisionType: TimeDivisionType;

    hideToolbar?: boolean;
    hidePagination?: boolean;
    hideUpperBorder?: boolean;

    displayXButtonInTraceView?: boolean;
    displayBackButtonInTraceView?: boolean;

    location;
    history;

    traceViewModalOpen?: boolean;
    paperClassNames?;

    openTraceViewModal?: (
        key: string,
        traceId: string,
        displayXButton: boolean,
        displayBackButton: boolean,
        shouldDisplayLinkToDeviceView: boolean,
        shouldDisplayLinkToExportReport: boolean
    ) => void;

    showAllColumns?: boolean;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    if (!state.ui.currentlyViewedTraceId) {
        return {
            traceViewModalOpen: false,
        };
    } else {
        let modalKey: ModalKey = new ModalKey(ModalType.TraceViewModal, state.ui.currentlyViewedTraceId);
        return {
            traceViewModalOpen:
                state.ui.modals.modalIdToConfigurationMap[modalKey.getStringKey()] &&
                state.ui.modals.modalIdToConfigurationMap[modalKey.getStringKey()].open,
        };
    }
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {
        openTraceViewModal: (
            key,
            traceId,
            displayXButton,
            displayBackButton,
            shouldDisplayLinkToDeviceView,
            shouldDisplayLinkToExportReport
        ) =>
            dispatch(
                openTraceViewModalAction(
                    key,
                    traceId,
                    displayXButton,
                    displayBackButton,
                    shouldDisplayLinkToDeviceView,
                    shouldDisplayLinkToExportReport
                )
            ),
    };
};

/**
 * Holds the traces list in the app.
 */
class TracesList extends React.Component<AppProps, AppState> {
    /**
     * Defines the column headers participating in the traces table.
     */
    private columnHeaders: TableColumnHeaderInfo[] = [
        new TableColumnHeaderInfo("created", "Received", false, true, false),
        new TableColumnHeaderInfo("traceEventType", "Event Type", false, true, false),
        new TableColumnHeaderInfo("details", "Arguments", false, true, false, false),
    ];

    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        if (this.props.includeColumns) {
            if (this.props.includeColumns.length) {
                let includeColumnsSet = HashSet.fromValues(this.props.includeColumns);
                this.columnHeaders = this.columnHeaders.filter((columnHeader) =>
                    includeColumnsSet.exists(columnHeader.id)
                );
            } else {
                this.columnHeaders = [];
            }
        }

        if (this.props.showAllColumns) {
            for (let i = 0; i < this.columnHeaders.length; i++) {
                this.columnHeaders[i].isHidden = false;
            }
        }

        // Initializing the state to default.
        this.state = {
            loadingTraces: false,
            errorLoadingTraces: false,

            traces: [],
            pageSize: 0,

            tracesFilter: this.props.tracesFilter
                ? this.props.tracesFilter
                : new TracesFilter(null, null, null, null, null, null, null, null),
            searchText: null,
            orderByField: null,
            order: "desc",

            totalItemCount: null,
            newEvents: 0,
        };
    }

    /**
     * Occurs once the component finished its initialization process.
     */
    async componentDidMount() {
        await this.loadTraces(1);
        ServiceWire.getPollingService().subscribe(
            new PollingSubscriptionInfo("TracesList", PollingChangeType.ALL_TRACES, this.handleNewTraces.bind(this))
        );
    }

    /**
     * Occurs once the component is being destroyed.
     */
    componentWillUnmount(): void {
        ServiceWire.getPollingService().unsubscribe("TracesList", PollingChangeType.ALL_TRACES);
    }

    filterTraces(trace: TraceInfo) {
        if (trace.deviceId !== this.props.deviceId) {
            return false;
        }

        if (!this.state.tracesFilter || !this.state.tracesFilter.isActive()) {
            return true;
        }

        const filter = this.state.tracesFilter;

        if (filter.startTime && filter.startTime > trace.created) {
            return false;
        }

        if (filter.endTime && filter.endTime < trace.created) {
            return false;
        }

        if (filter.ipAddress && filter.ipAddress.length && filter.ipAddress !== trace.ipAddress) {
            return false;
        }

        return true;
    }

    handleNewTraces(fromTimestamp: number, changedData: Object): void {
        if (changedData && !!changedData["entities"]) {
            let traces = EntityManager.getSternumEntities(changedData).map((entity) => entity as TraceInfo);
            const countTraces = traces.filter((trace) => this.filterTraces(trace)).length;
            this.setState({
                newEvents: this.state.newEvents + countTraces,
            });
        }
    }

    /**
     * Occurs once the component is about to receive props.
     */
    UNSAFE_componentWillReceiveProps(nextProps: Readonly<AppProps>, nextContext: any): void {
        if (
            (!nextProps.tracesFilter && this.props.tracesFilter) ||
            (nextProps.tracesFilter && !this.props.tracesFilter) ||
            (nextProps.tracesFilter &&
                this.props.tracesFilter &&
                nextProps.tracesFilter.isDifferentFrom(this.props.tracesFilter))
        ) {
            this.handleTracesFilterChanged(nextProps.tracesFilter);
        }
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        let startTime = moment().subtract(30, "days").valueOf();
        let endTime = moment().valueOf();

        if (this.props.tracesFilter) {
            if (this.props.tracesFilter.startTime) {
                startTime = this.props.tracesFilter.startTime;
            }
            if (this.props.tracesFilter.endTime) {
                endTime = this.props.tracesFilter.endTime;
            }
        }

        return (
            <Paper
                classes={{
                    root: classNames(classes.paperClass, this.props.paperClassNames),
                }}
                elevation={0}
            >
                {/* Traces list */}
                <SternumTable
                    isSearchInputDisabled={this.props.isSearchInputDisabled}
                    nonClickableRows
                    columnWidthsArray={[10, 90]}
                    emptyComponent={
                        <div className={classNames(classes.flexCenter, classes.padding)}>
                            <Typography variant="body2">No events found. Try changing your criteria.</Typography>
                        </div>
                    }
                    toolbarState={this.props.toolbarState || new TableToolbarDisplayState(true, true, false, false)}
                    hideUpperBorder={this.props.hideUpperBorder}
                    hideToolbar={this.props.hideToolbar}
                    hidePagination={this.props.hidePagination}
                    pageSize={this.state.pageSize || SternumConfiguration.getPageSize()}
                    amountOfLoadingPlaceholders={3}
                    totalItemCount={this.state.totalItemCount}
                    loadingItems={this.state.loadingTraces}
                    errorLoadingItems={this.state.errorLoadingTraces}
                    columnHeaders={this.columnHeaders}
                    listFilter={this.state.tracesFilter}
                    viewedColumnsSet={this.props.viewedColumnsSet}
                    onRefreshClicked={() => this.onRefreshClicked()}
                    newEvents={this.state.newEvents}
                    isFilterActive={this.state.tracesFilter && this.state.tracesFilter.isActive()}
                    includeActivityInToolbar={false}
                    filterComponentProvider={(onFilterChanged) => (
                        <TracesListFilterPopover
                            tracesFilter={this.state.tracesFilter}
                            onFilterChanged={onFilterChanged}
                        />
                    )}
                    orderByField={this.state.orderByField}
                    order={this.state.order}
                    rows={this.state.traces}
                    onFilterChanged={(listFilter) => this.handleTracesFilterChanged(listFilter)}
                    onSearchTextChanged={(searchText) => this.handleSearchTextChanged(searchText)}
                    onPageChanged={(pageNumber) => this.loadTraces(pageNumber)}
                    onOrderChanged={(orderByField, order) => this.handleOrderChanged(orderByField, order)}
                    getRowValues={(row) => this.getRowDisplayValues(row)}
                    getRowClasses={(row) => this.getRowClasses(row)}
                />
            </Paper>
        );
    }

    /**
     * Gets the display values for row's columns (where they aren't the default).
     */
    private getRowDisplayValues(row) {
        let traceInfo = row as TraceInfo;
        const { classes } = this.props;

        let traceTypeConfigurationObject = SternumConfiguration.getTraceEventTypeConfigurationObject(
            traceInfo.traceDefinition.traceEventName
        );

        let eventTypeDisplayValue = traceInfo.traceDefinition.displayName;
        // if (traceTypeConfigurationObject) {
        //     eventTypeDisplayValue = traceTypeConfigurationObject.familyName
        //         ? traceTypeConfigurationObject.familyName
        //         : traceTypeConfigurationObject.displayName;
        // }

        let createdDisplayElement = (
            <Typography variant="body2">{moment(traceInfo.created).format("MM/DD/YYYY HH:mm:ss")}</Typography>
        );
        if (this.props.adjacentTracesToTrace && this.props.adjacentTracesToTrace.traceId === traceInfo.traceId) {
            createdDisplayElement = (
                <div className={classes.flexVMiddle}>
                    {}
                    <Icon className={classNames("fas fa-arrow-alt-circle-right", classes.viewedTraceIcon)} />

                    {}
                    <Typography variant="body2">{moment(traceInfo.created).format("MM/DD/YYYY HH:mm:ss")}</Typography>
                </div>
            );
        }

        return {
            traceId: traceInfo.entityId,
            traceEventType: (
                <SternumLink onClick={() => this.handleRowClicked(traceInfo)}>{eventTypeDisplayValue}</SternumLink>
            ),
            severity: (
                <StatusDisplay
                    label={traceInfo.getSeverityDisplay()}
                    danger={traceInfo.isSeverityHigh()}
                    medium={traceInfo.isSeverityMedium()}
                    success={traceInfo.isSeverityNormal()}
                />
            ),
            created: createdDisplayElement,
            details: <ArgumentsListDisplay traceInfo={traceInfo} />,
            deviceName: traceInfo.device && traceInfo.device.deviceDefinition.displayName,
            prevented: <EventStatusListDisplay traceInfo={traceInfo} />,
            actions: (
                <div className={classes.flexVMiddle}>
                    {}
                    <Typography className={classes.traceLinkText}>
                        {traceTypeConfigurationObject && traceTypeConfigurationObject.isAttackTrace
                            ? "Investigate"
                            : "Details"}
                    </Typography>

                    {}
                    <Icon className={classNames("fa fa-external-link-alt", classes.traceLinkIcon)} />
                </div>
            ),
        };
    }

    private async onRefreshClicked() {
        await this.loadTraces(1);
    }

    private getRowClasses(row) {
        const { classes } = this.props;
        let traceInfo = row as TraceInfo;
        let traceEventTypeConfigurationObject = SternumConfiguration.getTraceEventTypeConfigurationObject(
            traceInfo.traceDefinition.traceEventName
        );

        if (
            this.props.markAttackRows &&
            traceEventTypeConfigurationObject &&
            traceEventTypeConfigurationObject.isAttackTrace
        ) {
            return { root: classes.attackRow };
        }

        return null;
    }

    private handleRowClicked(trace: TraceInfo) {
        this.props.openTraceViewModal(
            trace.traceId,
            trace.traceId,
            this.props.displayXButtonInTraceView,
            this.props.displayBackButtonInTraceView,
            true,
            false
        );
    }

    private handleTracesFilterChanged(tracesFilter: ListFilter) {
        this.setState(
            {
                tracesFilter: tracesFilter != null ? (tracesFilter as TracesFilter) : null,
            },
            async () => {
                if (tracesFilter && tracesFilter.isActive()) {
                    await this.loadTraces(1);
                }
            }
        );
    }

    private handleSearchTextChanged(searchText) {
        DebounceService.debounce(
            "getTraces",
            () => {
                this.setState(
                    {
                        searchText: searchText,
                    },
                    () => this.loadTraces(1)
                );
            },
            350
        );
    }

    /**
     *
     * @param orderByField
     * @param order
     */
    private handleOrderChanged(orderByField, order) {
        this.setState(
            {
                orderByField: orderByField,
                order: order,
            },
            () => this.loadTraces(1)
        );
    }

    isAttackTrace = (): boolean => {
        return this.props.adjacentTracesToTrace?.isAttackAttempt();
    };

    private async loadTraces(pageNumber) {
        try {
            this.setState({
                loadingTraces: true,
                errorLoadingTraces: false,
                newEvents: 0,
            });

            let traces = [];
            let tracesCount = 0;
            let pageSize: number = 0;
            const processName = this.isAttackTrace() ? this.props.adjacentTracesToTrace.getProcessName() : null;

            if (this.props.fetchAdjacentTraces && this.props.adjacentTracesCount && this.props.adjacentTracesToTrace) {
                traces = await ServiceWire.getSternumService().getAdjacentTraces({
                    clientId: ServiceWire.getClientsService().getSelectedClientId(),
                    trace: this.props.adjacentTracesToTrace,
                    deviceId: this.props.deviceId,
                    searchText: this.state.searchText,
                    limitPreceding: this.props.adjacentTracesCount,
                    limitFollowing: this.props.adjacentTracesCount,
                    processName: processName,
                });
                tracesCount = traces.length;
            } else if (this.props.fetchAnomalyTraces) {
                traces = await ServiceWire.getSternumService().getAnomalyTraces(this.props.issueId);
                tracesCount = traces.length;
                pageSize = traces.length;
            } else {
                tracesCount = await ServiceWire.getSternumService().getTracesCount(
                    ServiceWire.getClientsService().getSelectedClientId(),
                    this.props.deviceId,
                    this.state.tracesFilter,
                    this.state.searchText,
                    processName
                );

                if (tracesCount > 0) {
                    traces = await ServiceWire.getSternumService().getTraces(
                        ServiceWire.getClientsService().getSelectedClientId(),
                        this.props.deviceId,
                        this.state.tracesFilter,
                        this.state.searchText,
                        this.state.orderByField,
                        this.state.order,
                        (pageNumber - 1) * SternumConfiguration.getPageSize(),
                        SternumConfiguration.getPageSize(),
                        processName
                    );
                }
            }

            this.setState({
                loadingTraces: false,
                errorLoadingTraces: false,
                traces: traces,
                pageSize,
                totalItemCount: tracesCount,
            });
        } catch (error) {
            this.setState({
                loadingTraces: false,
                errorLoadingTraces: true,
            });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(tracesListStyle)(TracesList)));
