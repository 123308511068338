import React, { useMemo } from "react";

import SternumDeviceHardwareEventsList from "../../../SternumDeviceHardwareEventsList/SternumDeviceHardwareEventsList";
import TableToolbarDisplayState from "../../../../lib/state/TableToolbarDisplayState";
import HashSet from "../../../../lib/infra/HashSet";
import DeviceDefinitionInfo from "../../../../lib/state/DeviceDefinitionInfo";
import SternumDeviceEventsFilter from "../../../../lib/state/SternumDeviceEventsFilter";
import DeviceInfo from "../../../../lib/state/DeviceInfo";

import { useHardwareEventsTabStyles } from "./HardwareEventsTab.styles";

export interface HardwareEventsTabProps {
    deviceId: string;
    device: DeviceInfo;
    deviceDefinition: DeviceDefinitionInfo;
    startDate: Date;
    endDate: Date;
    refreshEntitiesFilter?: boolean;
}

export function HardwareEventsTab({
    deviceId,
    device,
    deviceDefinition,
    startDate,
    endDate,
    refreshEntitiesFilter,
}: HardwareEventsTabProps) {
    const classes = useHardwareEventsTabStyles();

    const viewedColumns = ["created", "latestTraceTime"];
    const tooltipState = new TableToolbarDisplayState(
        true,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        "Search hardware events"
    );
    const entitiesFilter = useMemo(() => {
        return new SternumDeviceEventsFilter(
            null,
            false,
            startDate?.getTime() || null,
            endDate?.getTime() || null,
            null,
            null,
            [],
            false,
            null,
            null
        );
    }, [startDate?.getTime(), endDate?.getTime()]);

    return (
        <div className={classes.container}>
            <SternumDeviceHardwareEventsList
                doNotDisplayExploitationTypeInDisplayName={true}
                doNotDisplayLoading={false}
                refreshEntitiesFilter={refreshEntitiesFilter}
                toolbarState={tooltipState}
                infiniteScroll
                entityId={deviceId}
                deviceDefinition={deviceDefinition}
                viewedColumnsSet={HashSet.fromValues(viewedColumns)}
                entitiesFilter={entitiesFilter}
                deviceDefinitionVersionId={device.lastSeenVersionId}
                shouldDisplayLinkToDeviceView={false}
                displayBackButtonInTraceView
                displayXButtonInTraceView
                emptyTableMessage={"Hardware events will appear here."}
                shouldNoWrapDisplayName={false}
                displayTypeColumn
                indexEventTime={endDate?.getTime()}
            />
        </div>
    );
}
