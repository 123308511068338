import { Action } from "redux";
import ActionType from "../ActionType";
import ModalKey from "../../state/ModalKey";

/**
 * Defines the data for the close modal action.
 */
export interface CloseModalAction extends Action<ActionType> {
    type: ActionType.CloseModal;
    modalKey: ModalKey;
}

/**
 * Action creator for closing a modal.
 * @param modalKey The key of the modal to close.
 */
export function closeModalAction(modalKey: ModalKey): CloseModalAction {
    return {
        type: ActionType.CloseModal,
        modalKey: modalKey,
    };
}
