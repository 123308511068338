import * as React from "react";

import { WithStyles, withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import cveScoreDisplayStyle from "./CveScoreDisplayStyle";
import { Typography } from "@material-ui/core";
import CveInfo from "../../lib/state/CveInfo";
import classNames from "classnames";
import TimeAgo from "react-timeago";
import SternumConfiguration from "../../lib/infra/SternumConfiguration";
import Link from "@material-ui/core/Link";

/**
 * Holds the inner state for our app.
 */
interface AppState {}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof cveScoreDisplayStyle> {
    cve: CveInfo;
}

/**
 * Cve info display.
 */
class CveScoreDisplay extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {};
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        let v2Exploitability = this.props.cve.externalEntity["cvss_v2_exploitability_score"];
        let v2BaseScore = this.props.cve.externalEntity["cvss_v2_base_score"];
        let v2ImpactScore = this.props.cve.externalEntity["cvss_v2_impact_score"];
        let v2Severity = this.props.cve.externalEntity["cvss_v2_severity"];

        let v3BaseScore = this.props.cve.externalEntity["cvss_v3_base_score"];
        let v3Exploitability = this.props.cve.externalEntity["cvss_v3_exploitability_score"];
        let v3ImpactScore = this.props.cve.externalEntity["cvss_v3_impact_score"];
        let v3Severity = this.props.cve.externalEntity["cvss_v3_severity"];

        return (
            <div className={classes.details}>
                {!!(v2Exploitability || v2BaseScore || v2ImpactScore || v2Severity) && (
                    <div className={classes.infoColumn}>
                        <Typography variant={"body2"} className={classes.columnTitle}>
                            CVSS v2
                        </Typography>

                        {v2BaseScore && (
                            <div className={classes.cveDetailContainer}>
                                {/* Title */}
                                <Typography variant={"body2"} className={classes.cveDetailName}>
                                    Score:
                                </Typography>

                                <Typography variant="body2">{v2BaseScore}</Typography>
                            </div>
                        )}

                        {v2Exploitability && (
                            <div className={classes.cveDetailContainer}>
                                {/* Title */}
                                <Typography variant={"body2"} className={classes.cveDetailName}>
                                    Exploitability:
                                </Typography>

                                <Typography variant="body2">{v2Exploitability}</Typography>
                            </div>
                        )}

                        {v2ImpactScore && (
                            <div className={classes.cveDetailContainer}>
                                {/* Title */}
                                <Typography variant={"body2"} className={classes.cveDetailName}>
                                    Impact:
                                </Typography>

                                <Typography variant="body2">{v2ImpactScore}</Typography>
                            </div>
                        )}

                        {v2Severity && (
                            <div className={classes.cveDetailContainer}>
                                {/* Title */}
                                <Typography variant={"body2"} className={classes.cveDetailName}>
                                    Severity:
                                </Typography>

                                <Typography variant="body2">{v2Severity}</Typography>
                            </div>
                        )}
                    </div>
                )}

                {!!(v3BaseScore || v3Exploitability || v3ImpactScore || v3Severity) && (
                    <div className={classes.infoColumn}>
                        <Typography variant={"body2"} className={classes.columnTitle}>
                            CVSS v3
                        </Typography>

                        {v3BaseScore && (
                            <div className={classes.cveDetailContainer}>
                                {/* Title */}
                                <Typography variant={"body2"} className={classes.cveDetailName}>
                                    Score:
                                </Typography>

                                <Typography variant="body2">{v3BaseScore}</Typography>
                            </div>
                        )}

                        {v3Exploitability && (
                            <div className={classes.cveDetailContainer}>
                                {/* Title */}
                                <Typography variant={"body2"} className={classes.cveDetailName}>
                                    Exploitability:
                                </Typography>

                                <Typography variant="body2">{v3Exploitability}</Typography>
                            </div>
                        )}

                        {v3ImpactScore && (
                            <div className={classes.cveDetailContainer}>
                                {/* Title */}
                                <Typography variant={"body2"} className={classes.cveDetailName}>
                                    Impact:
                                </Typography>

                                <Typography variant="body2">{v3ImpactScore}</Typography>
                            </div>
                        )}

                        {v3Severity && (
                            <div className={classes.cveDetailContainer}>
                                {/* Title */}
                                <Typography variant={"body2"} className={classes.cveDetailName}>
                                    Severity:
                                </Typography>

                                <Typography variant="body2">{v3Severity}</Typography>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default withRouter(withStyles(cveScoreDisplayStyle)(CveScoreDisplay));
