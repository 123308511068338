import * as React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import moment from "moment";
import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";

import HashSet from "../../lib/infra/HashSet";
import SternumConfiguration from "../../lib/infra/SternumConfiguration";
import Utils from "../../lib/infra/Utils";
import WebUtils from "../../lib/infra/WebUtils";
import { openDeviceViewModalAction } from "../../lib/redux/modals/OpenModalAction";
import { fetchTraceAction } from "../../lib/redux/traces/FetchTraceAction";
import ServiceWire from "../../lib/services/ServiceWire";
import DeviceInfo from "../../lib/state/DeviceInfo";
import { GlobalState } from "../../lib/state/GlobalState";
import HttpResponse from "../../lib/state/HttpResponse";
import IssueInfo from "../../lib/state/IssueInfo";
import ServerEntityType from "../../lib/state/ServerEntityType";
import SternumReportDetails from "../../lib/state/SternumReportDetails";
import SternumReportTraceDetails from "../../lib/state/SternumReportTraceDetails";
import TableToolbarDisplayState from "../../lib/state/TableToolbarDisplayState";
import TimeDivisionType from "../../lib/state/TimeDivisionType";
import TraceInfo from "../../lib/state/TraceInfo";
import DeviceInfoDisplay from "../DeviceInfoDisplay/DeviceInfoDisplay";
import GeoLocationContent from "../GeoLocationContent/GeoLocationContent";
import { ArchiveBookIcon, DownloadIcon, FolderIcon, InfoCircleIcon } from "../SUI/SternumIcon";
import SternumTab from "../SUI/SternumTabs/SternumTab";
import SternumTabs from "../SUI/SternumTabs/SternumTabs";
import TraceInfoDisplay from "../TraceInfoDisplay/TraceInfoDisplay";
import TracesList from "../TracesList/TracesList";
import traceViewStyle from "./TraceViewStyle";
import { AnomalyChart, LostConnectionChart } from "../AnomalyChart";
import {
    alertAnomalyStatusEmitter,
    ReasonsDefaultAndCustom,
    TraceViewAlertAnomalyStatus,
    TraceViewAlertStatus,
    TraceViewAlertViewAnomalyStatus,
} from "../TraceViewAlertStatus";
import { setSelectedIssueInfoAction } from "../../lib/redux/issues/SetSelectedIssueInfoAction";
import { putIssueStatusAction } from "../../lib/redux/issues/PutIssueStatusAction";
import { IssueInfoStateStatus, IssueInfoStatus } from "../../lib/state/IssueInfoType";
import { AnomaliesResponse } from "../../lib/state/Anomaly";
import TracesFilter from "../../lib/state/TracesFilter";
import { SternumTooltip } from "../SUI/SternumTooltip";
import { Button as SUIButton } from "../SUI/Button";
import { getMappedValue } from "../../utils";
import { TraceStepper, TraceStepperState } from "./TraceStepper";
import { TraceSequence } from "../TraceSequence/TraceSequence.component";
import { OnboardingHowItWorks } from "./OnboardingHowItWorks";
import { SternumEmptyModal } from "../SternumEmptyModal";
import { useState } from "react";
import { AnomalyChartOldVersion } from "../AnomalyChart/AnomalyChartOldVersion";

enum AnomalyStep {
    AnomalyGraph = "AnomalyGraph",
    TraceSequence = "TraceSequence",
    Resolving = "Resolving",
}

/**
 * Holds the inner state for our app.
 */
interface AppState {
    selectedTab: number;
    showDownloadReportLoader: boolean;
    canEdit: boolean;
    firstTraceTimestamp: number;
    lastTraceTimestamp: number;
    isResolving: boolean;
    selectedStep: AnomalyStep;
    anomaliesResponse?: AnomaliesResponse;
    savedLocalStatus?: IssueInfoStatus;
    savedLocalReasons?: ReasonsDefaultAndCustom;
    isAlertUpdatedLocally?: boolean;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof traceViewStyle> {
    traceId: string;
    fullScreenDisplay: boolean;

    loadingTrace: boolean;
    errorLoadingTrace: boolean;
    selectedIssue?: IssueInfo;
    trace: TraceInfo;
    device: DeviceInfo;
    issue?: IssueInfo;

    shouldDisplayLinkToDeviceView?: boolean;
    shouldDisplayLinkToExportReport?: boolean;
    shouldDisplayViewInContextButton?: boolean;

    issueInfoStateStatus?: IssueInfoStateStatus;

    fetchTraceAction?: (traceId: string, issueId?: string) => void;
    openDeviceViewModal?: (key: string, deviceId: string, displayXButton: boolean, displayBackButton: boolean) => void;
    resolveIssueAction?: (issueId: string) => void;
    setSelectedIssueInfo?: (issue?: IssueInfo) => unknown;
    closeModal?: () => void;
    isFreeUser: boolean;
    resolveFlowOpen?: boolean;
    onRefresh?: () => unknown;
    modalShowsBackButton: boolean;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    const selectedClient = ServiceWire.getClientsService().getSelectedClient();

    const trace = state.traces.idToEntityMap.get(ownProps.traceId);

    return {
        isFreeUser: selectedClient.isTrialTier(),
        loadingTrace: state.traces.loadingIds.exists(ownProps.traceId),
        errorLoadingTrace: state.traces.errorIds.containsKey(ownProps.traceId),
        selectedIssue: state.issues.selectedIssue, // Can be undefined
        trace: trace,
        device: state.traces.idToEntityMap.containsKey(ownProps.traceId)
            ? state.devices.idToEntityMap.get(trace.deviceId) || trace.device
            : null,
        issueInfoStateStatus: state.issues.issueStateByIssueId.get(ownProps.issue?.issueId)?.issueStatus,
    };
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchTraceAction: (traceId: string, issueId?: string) => dispatch(fetchTraceAction(traceId, issueId)),
        openDeviceViewModal: (key: string, deviceId, displayXButton, displayBackButton) =>
            dispatch(openDeviceViewModalAction(key, false, deviceId, null, displayXButton, displayBackButton)),
        resolveIssueAction: (issueId: string) =>
            dispatch(putIssueStatusAction({ issueId, status: IssueInfoStatus.Resolved })),
        setSelectedIssueInfo: (issue?: IssueInfo) => dispatch(setSelectedIssueInfoAction({ issue })),
    };
};

type TraceViewPropsWithHOC = AppProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {};

/**
 * Displays a bar of metrics.
 */
class TraceView extends React.Component<AppProps, AppState> {
    /**
     * Holds all type of tabs.
     */
    private tabTypesRegular = {
        traceDetails: {
            key: "traceDetails",
            displayName: "Timeline",
            index: 0,
            visible: true,
        },
        adjacentEvents: {
            key: "adjacentEvents",
            displayName: "Adjacent Events",
            index: 1,
            visible: true,
        },
        deviceDetails: {
            key: "deviceDetails",
            displayName: "Device Info",
            index: 2,
            visible: true,
        },
    };

    private tabTypesAnomaly = {
        graph: {
            key: "graph",
            displayName: "Investigation",
            index: 0,
            visible: true,
        },
        adjacentEvents: {
            key: "adjacentEvents",
            displayName: "Related Events",
            index: 1,
            visible: true,
        },
        deviceDetails: {
            key: "deviceDetails",
            displayName: "Device Info",
            index: 2,
            visible: true,
        },
    };

    private tabTypesLostCommunication = {
        graph: {
            key: "graph",
            displayName: "Graph",
            index: 0,
            visible: true,
        },
        adjacentEvents: {
            key: "adjacentEvents",
            displayName: "Latest Events",
            index: 1,
            visible: true,
        },
        deviceDetails: {
            key: "deviceDetails",
            displayName: "Device Info",
            index: 2,
            visible: true,
        },
    };

    /**
     * Constructor.
     */
    constructor(props: TraceViewPropsWithHOC) {
        super(props);

        // Initializing the state to default.
        this.state = {
            selectedTab: 0,
            showDownloadReportLoader: false,
            canEdit: ServiceWire.getAuthorizationService().canEdit(ServerEntityType.ISSUE),
            firstTraceTimestamp: 0,
            lastTraceTimestamp: 0,
            isResolving: props.resolveFlowOpen,
            selectedStep: AnomalyStep.AnomalyGraph,
        };

        props.setSelectedIssueInfo(props.issue);
    }

    /**
     * Occurs once the component is loaded into the UI.
     */
    async componentDidMount() {
        this.props.fetchTraceAction(this.props.traceId, this.props.issue?.entityId);

        if (this.props.issue?.issueId) {
            await this.getAnomalyGraphDataWithTraceSequence(null, this.props.issue.issueId);
        }
    }

    componentWillUnmount() {
        this.props.setSelectedIssueInfo();
    }

    async componentDidUpdate(prevProps: Readonly<AppProps>, prevState) {
        if (this.props.issue?.issueId) {
            await this.getAnomalyGraphDataWithTraceSequence(prevProps.issue?.issueId, this.props.issue.issueId);
        }
    }

    async getAnomalyGraphDataWithTraceSequence(prevIssueId: string, issueId) {
        const showAnomalyStepper =
            SternumConfiguration.getAnomalyInvestigationEnabled() &&
            this.props.issue &&
            !this.props.issue.isLostCommunicationIssue();

        if (showAnomalyStepper && prevIssueId !== issueId) {
            const anomaliesResponse = await ServiceWire.getSternumService().getAnomalies(issueId);

            this.setState({ anomaliesResponse });
        }
    }

    render() {
        const { classes } = this.props;
        const showUnknownAnomalyGraph =
            SternumConfiguration.getAnomalyInvestigationEnabled() &&
            this.props.issue &&
            !this.props.issue.isLostCommunicationIssue();
        const showLostCommunication = this.props.issue?.isLostCommunicationIssue();
        const isResolvingAlertAnomaly =
            this.props.selectedIssue?.status === IssueInfoStatus.Open || this.state.isResolving;

        if (this.props.loadingTrace) {
            // Return loading placeholder for trace page.
            return (
                <div className={classes.upperContentContainer}>
                    <div className={classNames(classes.loadingPlaceholder, classes.marginBottom, "mod-title")} />
                    <div
                        className={classNames(classes.loadingPlaceholder, classes.marginBottomXLarge, "mod-subtitle")}
                    />
                    <div className={classNames(classes.loadingPlaceholder, classes.marginBottom, "mod-paragraph")} />
                    <div className={classNames(classes.loadingPlaceholder, classes.marginBottom, "mod-paragraph")} />
                    <div className={classNames(classes.loadingPlaceholder, classes.marginBottom, "mod-paragraph")} />
                </div>
            );
        }

        if (!this.props.trace) {
            return (
                <SternumEmptyModal>
                    <SternumEmptyModal.Title>No data to display</SternumEmptyModal.Title>
                    <SternumEmptyModal.Description>
                        There is no data to display. It can be because of some internal error or non-existent trace.
                    </SternumEmptyModal.Description>
                </SternumEmptyModal>
            );
        } else {
            let traceEventTypeConfiguration = SternumConfiguration.getTraceEventTypeConfigurationObject(
                this.props.trace.traceDefinition.traceEventName
            );

            const isAnomaly =
                SternumConfiguration.getAnomalyInvestigationEnabled() &&
                this.props.issue &&
                this.props.issue.anomalyType !== null;

            const shouldDisableExportReport = this.props.issue && this.props.issue.anomalyType !== null;

            const tabTypes = this.getAvailableTabTypes(this.props.issue?.anomalyType);
            const isGraphSelected = this.state.selectedTab === tabTypes["graph"]?.index;

            return (
                <div
                    role="presentation"
                    aria-label="trace view modal content"
                    className={classNames(classes.root, this.state.isResolving && classes.rootIsResolving)}
                >
                    {showUnknownAnomalyGraph && isGraphSelected && (
                        <OnboardingHowItWorks
                            showResolving={isResolvingAlertAnomaly}
                            onGoToAnomalyGraph={() => this.setState({ selectedStep: AnomalyStep.AnomalyGraph })}
                            onGoToTraceSequence={() => this.setState({ selectedStep: AnomalyStep.TraceSequence })}
                            onGoToResolving={() => this.setState({ selectedStep: AnomalyStep.Resolving })}
                        />
                    )}

                    {/* Upper content */}
                    <div className={classes.upperContentContainer}>
                        {/* Title */}
                        <div className={classes.flexSpaceBetween}>
                            {/* Investigate text */}
                            <Typography
                                variant={"h5"}
                                className={classNames(classes.title, {
                                    [classes.titlePushedDown]: this.props.modalShowsBackButton,
                                })}
                            >
                                {this.getModalTitle()}
                            </Typography>
                            {this.props.shouldDisplayViewInContextButton && (
                                <Button
                                    variant="contained"
                                    className={classes.viewInContextButton}
                                    startIcon={<ArchiveBookIcon />}
                                    onClick={() => {
                                        Object.assign(document.createElement("a"), {
                                            target: "_blank",
                                            href: `/fleet-view/${this.props.device.lastSeenVersionId}?contextFromTimestamp=${this.props.trace.created}&contextTraceId=${this.props.trace.entityId}`,
                                        }).click();
                                    }}
                                >
                                    View in context
                                </Button>
                            )}
                        </div>

                        {/* Subtitle */}
                        <div
                            role="presentation"
                            aria-label="parameters container"
                            className={classNames(classes.parametersContainer, classes.flexVMiddle)}
                        >
                            {/* Family name */}
                            {this.props.issue && this.props.issue.alertName && this.props.issue.alertType ? (
                                <>
                                    <Typography variant="body2" className={classNames(classes.extraBold)}>
                                        Alert Name:
                                    </Typography>
                                    <Typography variant="body2" className={classNames(classes.marginLeftXs)}>
                                        {this.props.issue && this.props.issue.alertName}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        className={classNames(classes.marginLeftLarge, classes.extraBold)}
                                    >
                                        Alert Type:
                                    </Typography>
                                    <Typography variant="body2" className={classNames(classes.marginLeftXs)}>
                                        {this.props.issue && this.props.issue.alertType}
                                    </Typography>
                                </>
                            ) : (
                                <>
                                    <Typography variant="body2" className={classNames(classes.extraBold)}>
                                        Event:
                                    </Typography>
                                    <Typography variant="body2" className={classNames(classes.marginLeftXs)}>
                                        {traceEventTypeConfiguration && traceEventTypeConfiguration.familyName
                                            ? traceEventTypeConfiguration.familyName
                                            : this.props.trace.traceDefinition.displayName}
                                    </Typography>
                                </>
                            )}

                            {/* Device profile name */}
                            <Typography
                                variant="body2"
                                className={classNames(classes.marginLeftLarge, classes.extraBold)}
                            >
                                Device Profile:
                            </Typography>
                            <Typography variant="body2" className={classes.marginLeftXs}>
                                {this.props.device.deviceDefinition?.displayName}
                            </Typography>

                            {/* Device id */}
                            <Typography
                                variant="body2"
                                className={classNames(classes.marginLeftLarge, classes.extraBold)}
                            >
                                Device ID:
                            </Typography>
                            <Typography variant="body2" className={classNames(classes.marginLeftXs)}>
                                {this.props.device.receivedDeviceId}
                            </Typography>

                            {/* Attack time */}
                            <Typography variant="body2" className={classes.marginLeftLarge}>
                                {moment(this.props.trace.created).format("MM/DD/YYYY HH:mm")}
                            </Typography>

                            {/* More Info Icon */}
                            {!this.props.isFreeUser && this.props.shouldDisplayLinkToDeviceView && (
                                <div
                                    className={classNames(classes.exportReportContainer, classes.flexVMiddle)}
                                    onClick={() => this.moreInfoClicked()}
                                >
                                    <FolderIcon
                                        color="#8B949E"
                                        className={classNames(classes.cursorPointer, classes.marginLeftLarge)}
                                    />

                                    <Typography
                                        variant="body2"
                                        className={classNames(classes.moreInfoText, classes.marginLeftXs)}
                                    >
                                        Open Device View
                                    </Typography>
                                </div>
                            )}

                            {/* Export Report Container */}
                            {!shouldDisableExportReport &&
                                !this.props.isFreeUser &&
                                this.props.shouldDisplayLinkToExportReport && (
                                    <div
                                        className={classNames(classes.exportReportContainer, classes.flexVMiddle)}
                                        onClick={() => this.generateReport()}
                                    >
                                        <DownloadIcon
                                            width={24}
                                            height={24}
                                            color="#8B949E"
                                            className={classNames(classes.cursorPointer, classes.marginLeftLarge)}
                                        />

                                        {/* Export Report link */}
                                        <Typography
                                            variant="body2"
                                            className={classNames(classes.generateReportText, classes.marginLeftXs)}
                                        >
                                            Export Report
                                        </Typography>
                                    </div>
                                )}

                            {/* Show loader*/}
                            {this.props.shouldDisplayLinkToExportReport && this.state.showDownloadReportLoader && (
                                <div className={classNames(classes.exportReportContainer, classes.flexVMiddle)}>
                                    {/* Loading circle */}
                                    <CircularProgress size={15} className={classNames(classes.marginRight)} />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={classNames(classes.geoLocationContentContainer)}>
                        <GeoLocationContent trace={this.props.trace} />
                    </div>

                    <div className={classes.tabsContainer}>
                        <SternumTabs
                            value={this.state.selectedTab}
                            // @ts-ignore
                            onChange={(event, value) => this.handleSelectedTab(event, value)}
                        >
                            {Utils.getMapValues(tabTypes)
                                .filter((tabType) => tabType.visible)
                                .map((tabType) => (
                                    <SternumTab
                                        key={tabType.key}
                                        label={tabType.displayName}
                                        disabled={this.props.isFreeUser}
                                    />
                                ))}
                        </SternumTabs>
                    </div>

                    <div role="presentation" aria-label="tab content" className={classes.tabContent}>
                        {/* Events close to the attack list */}
                        {this.state.selectedTab === tabTypes.adjacentEvents.index && (
                            <div className={classes.tableContainer}>
                                <div className={classes.tableInner}>
                                    <TracesList
                                        deviceId={this.props.trace.deviceId}
                                        issueId={this.props.issue?.issueId}
                                        toolbarState={
                                            new TableToolbarDisplayState(true, false, false, false, true, false, false)
                                        }
                                        viewedColumnsSet={HashSet.fromValues([
                                            "traceEventType",
                                            "created",
                                            "prevented",
                                            "details",
                                        ])}
                                        tracesFilter={
                                            isAnomaly
                                                ? new TracesFilter(
                                                      this.state.firstTraceTimestamp,
                                                      this.state.lastTraceTimestamp,
                                                      null,
                                                      null,
                                                      null,
                                                      this.props.traceId,
                                                      null,
                                                      null
                                                  )
                                                : null
                                        }
                                        isSearchInputDisabled={showUnknownAnomalyGraph}
                                        fetchAnomalyTraces={showUnknownAnomalyGraph}
                                        fetchAdjacentTraces={!isAnomaly}
                                        adjacentTracesCount={10}
                                        adjacentTracesToTrace={this.props.trace}
                                        paperClassNames={classNames(
                                            classes.tracesListPaper,
                                            this.props.fullScreenDisplay ? "mod-fullscreen" : "mod-regular"
                                        )}
                                        markRowIds={HashSet.fromValue(this.props.trace.traceId)}
                                        displayBackButtonInTraceView={true}
                                        timeFrame={10}
                                        timeDivisionType={TimeDivisionType.MINUTE}
                                    />
                                </div>
                            </div>
                        )}

                        {/* Trace info */}
                        {this.state.selectedTab === tabTypes["traceDetails"]?.index && (
                            <div className={classes.traceDetailsDisplayContainer}>
                                <TraceInfoDisplay
                                    hideInfoDescription={this.state.isResolving}
                                    hideTimelineIndexContainer={this.state.isResolving}
                                    trace={this.props.trace}
                                    showEventsTimeline={true}
                                    isIssue={Boolean(this.props.trace.isAttackAttempt())}
                                />
                            </div>
                        )}

                        {/* Device info */}
                        {this.state.selectedTab === tabTypes.deviceDetails.index && (
                            <div className={classes.traceDetailsDisplayContainer}>
                                <DeviceInfoDisplay device={this.props.device} />
                                {/* <AnomalyChart
                                    hideLegendAndDescription={this.state.isResolving}
                                    issueId={this.props.issue?.issueId}
                                    onAnomaliesFetched={this.handleAnomaliesResponse}
                                /> */}
                            </div>
                        )}

                        {!SternumConfiguration.getAnomalyInvestigationEnabled() &&
                            this.state.selectedTab === tabTypes["graph"]?.index && (
                                <div className={classNames(classes.fullHeight, classes.flexEqual, classes.flexColumn)}>
                                    {!this.props.issue?.isLostCommunicationIssue() && (
                                        <AnomalyChartOldVersion
                                            hideLegendAndDescription={this.state.isResolving}
                                            issueId={this.props.issue?.issueId}
                                            onAnomaliesFetched={this.handleAnomaliesResponse}
                                        />
                                    )}
                                    {this.props.issue?.isLostCommunicationIssue() && (
                                        <LostConnectionChart issue={this.props.issue} />
                                    )}
                                </div>
                            )}

                        {/* Graph */}
                        {SternumConfiguration.getAnomalyInvestigationEnabled() && isGraphSelected && (
                            <div className={classNames(classes.fullHeight, classes.flexEqual, classes.flexColumn)}>
                                {showUnknownAnomalyGraph && (
                                    <>
                                        <TraceStepper activeStep={this.state.selectedStep}>
                                            <TraceStepper.Step stepKey={AnomalyStep.AnomalyGraph}>
                                                Anomaly Graph
                                            </TraceStepper.Step>
                                            <TraceStepper.Step stepKey={AnomalyStep.TraceSequence}>
                                                Trace Sequence
                                            </TraceStepper.Step>
                                            <TraceStepper.Step
                                                stepKey={AnomalyStep.Resolving}
                                                getStepState={({ stepState }) => {
                                                    if (
                                                        stepState === TraceStepperState.Active &&
                                                        !this.state.isResolving &&
                                                        this.props.selectedIssue?.status !== IssueInfoStatus.Open
                                                    ) {
                                                        return TraceStepperState.Completed;
                                                    }

                                                    return stepState;
                                                }}
                                            >
                                                Resolving
                                            </TraceStepper.Step>
                                        </TraceStepper>
                                        {getMappedValue<AnomalyStep, React.ReactNode>(
                                            {
                                                [AnomalyStep.AnomalyGraph]: (
                                                    <AnomalyChart
                                                        anomaliesResponse={this.state.anomaliesResponse}
                                                        hideLegendAndDescription={this.state.isResolving}
                                                        onAnomaliesFetched={this.handleAnomaliesResponse}
                                                    />
                                                ),
                                                [AnomalyStep.TraceSequence]: (
                                                    <TraceSequence anomaliesResponse={this.state.anomaliesResponse} />
                                                ),
                                                [AnomalyStep.Resolving]: (
                                                    <div className={classes.traceViewAlertAnomalyStatusContainer}>
                                                        <TraceViewAlertAnomalyStatus
                                                            savedLocalReasons={this.state.savedLocalReasons}
                                                            onChangeSavedLocalReasons={(newSavedLocalReasons) =>
                                                                this.setState({
                                                                    savedLocalReasons: newSavedLocalReasons,
                                                                })
                                                            }
                                                            savedLocalStatus={this.state.savedLocalStatus}
                                                            onChangeSavedLocalStatus={(newSavedLocalStatus) =>
                                                                this.setState({
                                                                    savedLocalStatus: newSavedLocalStatus,
                                                                })
                                                            }
                                                            isAlertUpdatedLocally={this.state.isAlertUpdatedLocally}
                                                            onChangeIsAlertUpdatedLocally={(newIsAlertUpdatedLocally) =>
                                                                this.setState({
                                                                    isAlertUpdatedLocally: newIsAlertUpdatedLocally,
                                                                })
                                                            }
                                                            isResolving={isResolvingAlertAnomaly}
                                                            onSetIsResolving={(isResolving) =>
                                                                this.setState({ isResolving })
                                                            }
                                                            onRefresh={this.props.onRefresh}
                                                            onCloseWithoutChange={() => this.props.closeModal?.()}
                                                        />
                                                    </div>
                                                ),
                                            },
                                            this.state.selectedStep
                                        )}
                                    </>
                                )}
                                {showLostCommunication && <LostConnectionChart issue={this.props.issue} />}
                            </div>
                        )}
                    </div>

                    {(() => {
                        if (!isGraphSelected || !SternumConfiguration.getAnomalyInvestigationEnabled()) {
                            return (
                                <TraceViewAlertStatus
                                    isResolving={this.state.isResolving}
                                    onSetIsResolving={(isResolving) => this.setState({ isResolving })}
                                    onRefresh={this.props.onRefresh}
                                />
                            );
                        }

                        if (showLostCommunication) {
                            return (
                                <TraceViewAlertStatus
                                    isResolving={this.state.isResolving}
                                    resolvingSubtitle={
                                        <>
                                            Add reason that will be used when improving our artificial intelligence
                                            <span className={classes.redDecoration}>*</span>
                                        </>
                                    }
                                    onSetIsResolving={(isResolving) => this.setState({ isResolving })}
                                    onRefresh={this.props.onRefresh}
                                />
                            );
                        }

                        if (isGraphSelected) {
                            return (
                                <div className={classes.anomalyNavigationContainer}>
                                    <div>
                                        {this.state.selectedStep !== AnomalyStep.Resolving &&
                                            (() => {
                                                if (this.props.selectedIssue?.status === IssueInfoStatus.Open) {
                                                    return (
                                                        <SUIButton
                                                            className={classes.blueButton}
                                                            variant="primaryPink"
                                                            size="size24"
                                                            onClick={() => this.handleGoToResolvingAlert()}
                                                        >
                                                            Resolve
                                                        </SUIButton>
                                                    );
                                                }

                                                return (
                                                    <TraceViewAlertViewAnomalyStatus
                                                        savedLocalStatus={this.state.savedLocalStatus}
                                                        savedLocalReasons={this.state.savedLocalReasons}
                                                    />
                                                );
                                            })()}
                                    </div>
                                    {this.renderAnomalyStepsNavigationButtons()}
                                </div>
                            );
                        }

                        return null;
                    })()}
                </div>
            );
        }
    }

    private handleGoToResolvingAlert() {
        this.setState({ selectedStep: AnomalyStep.Resolving });
    }

    private renderAnomalyStepsNavigationButtons() {
        const { classes } = this.props;
        const selectedStep = this.state.selectedStep;

        let nextDoneButtonContent = "Next";
        let onClickNextDoneButton = () => this.nextAnomalyStep();
        let prevButtonContent = "Prev";
        let onClickPrevButton = () => this.previousAnomalyStep();

        if (selectedStep === AnomalyStep.Resolving) {
            if (this.props.selectedIssue?.status === IssueInfoStatus.Open) {
                nextDoneButtonContent = "Done";
                onClickNextDoneButton = () => this.anomalyDone();
            } else if (this.state.isResolving) {
                nextDoneButtonContent = "Save";
                onClickNextDoneButton = () => this.anomalySave();
                prevButtonContent = "Cancel";
                onClickPrevButton = () => this.anomalyCancel();
            } else {
                nextDoneButtonContent = "Done";
                onClickNextDoneButton = () => this.anomalyDone();
            }
        }

        return (
            <div className={classes.anomalyStepsNavigationButtonsContainer}>
                {selectedStep !== AnomalyStep.AnomalyGraph && (
                    <Button variant="text" className={classes.anomalyPrevButton} onClick={onClickPrevButton}>
                        {prevButtonContent}
                    </Button>
                )}
                <Button variant="outlined" className={classes.anomalyNextButton} onClick={onClickNextDoneButton}>
                    {nextDoneButtonContent}
                </Button>
            </div>
        );
    }

    private anomalyDone() {
        alertAnomalyStatusEmitter.emit("saveAlertStatus");
    }

    private anomalySave() {
        alertAnomalyStatusEmitter.emit("saveReasonsLocally");
    }

    private anomalyCancel() {
        alertAnomalyStatusEmitter.emit("cancelResolving");
    }

    private nextAnomalyStep() {
        const nextStep = getMappedValue<AnomalyStep, AnomalyStep>(
            {
                [AnomalyStep.AnomalyGraph]: AnomalyStep.TraceSequence,
                [AnomalyStep.TraceSequence]: AnomalyStep.Resolving,
                [AnomalyStep.Resolving]: AnomalyStep.Resolving,
            },
            this.state.selectedStep
        );

        this.setState({ selectedStep: nextStep });
    }

    private previousAnomalyStep() {
        const nextStep = getMappedValue<AnomalyStep, AnomalyStep>(
            {
                [AnomalyStep.AnomalyGraph]: AnomalyStep.AnomalyGraph,
                [AnomalyStep.TraceSequence]: AnomalyStep.AnomalyGraph,
                [AnomalyStep.Resolving]: AnomalyStep.TraceSequence,
            },
            this.state.selectedStep
        );

        this.setState({ selectedStep: nextStep });
    }

    private getModalTitle = () => {
        const isAnomaly = this.props.issue && this.props.issue.anomalyType !== null;

        if (isAnomaly) {
            if (this.props.issue?.isLostCommunicationIssue()) {
                return (
                    <>
                        Lost Communication Investigation
                        <SternumTooltip
                            useWrapper={false}
                            title={
                                <div>
                                    The device has stop sending data. You can check the reasons by investigating the
                                    last events and specify the actual reason.
                                </div>
                            }
                        >
                            <Box display="inline-flex" ml={2}>
                                <InfoCircleIcon width={18} height={18} color="#1B6FDE" />
                            </Box>
                        </SternumTooltip>
                    </>
                );
            }

            return (
                <>
                    AI Investigation
                    <SternumTooltip
                        useWrapper={false}
                        title={
                            <div>
                                The suggested anomaly visualization hints at the direction of a root cause to solve a
                                potential issue. If investigation shows that it's not an issue, you can dismiss it.
                            </div>
                        }
                    >
                        <Box display="inline-flex" ml={2}>
                            <InfoCircleIcon width={18} height={18} color="#1B6FDE" />
                        </Box>
                    </SternumTooltip>
                </>
            );
        }

        let traceEventTypeConfiguration = SternumConfiguration.getTraceEventTypeConfigurationObject(
            this.props.trace.traceDefinition.traceEventName
        );

        return traceEventTypeConfiguration && traceEventTypeConfiguration.isAttackTrace ? "Investigation" : "Details";
    };

    private handleAnomaliesResponse = (response: AnomaliesResponse) => {
        this.setState({
            firstTraceTimestamp: response.firstTraceTimestamp,
            lastTraceTimestamp: response.lastTraceTimestamp,
        });
    };

    private getAvailableTabTypes(anomalyType: IssueInfo["anomalyType"] | null) {
        return anomalyType
            ? anomalyType === "lost_communication"
                ? this.tabTypesLostCommunication
                : this.tabTypesAnomaly
            : this.tabTypesRegular;
    }

    /**
     * Occurs once the more info button is clicked.
     */
    private moreInfoClicked() {
        // this.props.openDeviceViewModal(this.props.trace.device.entityId, this.props.trace.device.entityId, true, false);
        window.open(`device/${this.props.trace.device.entityId}`, "_blank");
    }

    /**
     * Occurs once a tab has been selected.
     */
    private handleSelectedTab(event, value) {
        this.setState({
            selectedTab: value,
        });
    }

    /**
     * Generates a report for the trace.
     */
    private async generateReport() {
        if (!this.state.showDownloadReportLoader) {
            // Activate loading icon
            this.setState({ showDownloadReportLoader: true });

            // Fetch adjacent traces.
            let adjacentTraces: TraceInfo[] = await ServiceWire.getSternumService().getAdjacentTraces({
                clientId: ServiceWire.getClientsService().getSelectedClientId(),
                trace: this.props.trace,
                deviceId: this.props.trace.deviceId,
                searchText: null,
                limitPreceding: 10,
                limitFollowing: 10,
            });

            const country = this.props.trace.geoLocationInfo ? this.props.trace.geoLocationInfo.country : "N/A";
            const city = this.props.trace.geoLocationInfo ? this.props.trace.geoLocationInfo.city : "N/A";
            const state = this.props.trace.geoLocationInfo ? this.props.trace.geoLocationInfo.state : "N/A";

            // Generate report details.
            let sternumReportDetails = new SternumReportDetails(
                this.props.trace.traceArguments["EXPLOITATION_TYPE"].displayValue,
                moment(this.props.trace.created).format("YYYY-MM-DD HH:mm"),
                this.props.trace.traceDefinition.displayName,
                this.props.trace.ipAddress,
                "Unknown",
                SternumConfiguration.getAttackExplanationLines(
                    this.props.trace,
                    this.props.trace.traceArguments["EXPLOITATION_TYPE"].argumentValue,
                    true
                ).join(" "),
                adjacentTraces.map(
                    (adjacentTrace) =>
                        new SternumReportTraceDetails(
                            adjacentTrace.traceDefinition.displayName,
                            adjacentTrace.orderedTraceArguments.map(
                                (traceArgument) =>
                                    `${traceArgument.argumentDefinition.displayName}: ${traceArgument.displayValue}`
                            ),
                            moment(adjacentTrace.created).format("YYYY-MM-DD HH:mm")
                        )
                ),
                this.props.trace.device.receivedDeviceId,
                this.props.trace.device.deviceDefinition.displayName,
                this.props.trace.device.deviceDefinition.deviceFirmwareVersion,
                this.props.trace.device.cvesCount,
                this.props.trace.device.outOfDateLibrariesCount,
                this.props.device.deviceDefinition.deviceOSFamily,
                this.props.device.deviceDefinition.cpuBitness.toString(),
                country,
                city,
                state
            );

            try {
                // Get the report content.
                const response: HttpResponse = await ServiceWire.getSternumService().generateDeviceAttackReport(
                    this.props.device.entityId,
                    sternumReportDetails.getJsonObject()
                );

                // Downloads the report file to the user.
                WebUtils.downloadReport(response);

                // Hide loading icon
                this.setState({ showDownloadReportLoader: false });
            } catch (err) {
                this.setState({ showDownloadReportLoader: false });
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(traceViewStyle)(TraceView));
