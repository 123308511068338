import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: "flex",
        height: "100%",
    },

    title: {
        fontSize: 16,
        fontWeight: 600,
    },

    description: {
        marginTop: 8,
        fontSize: 14,
        color: "#1D1D1F",
    },

    extraDescription: {
        marginTop: 16,
        fontSize: 14,
        fontWeight: 500,
        color: "#1D1D1F",
    },

    inner: {
        display: "flex",
    },

    noPadding: {
        padding: "0 !important",
    },

    downloadSourceSection: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "20px",
        padding: "20px 0",
        borderTop: "1px solid #E6EAED",
        borderBottom: "1px solid #E6EAED",
    },

    downloadSourceButton: {
        padding: "0 0 0 10px",
        flexShrink: 0,
    },
}));
