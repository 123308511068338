import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { NavLink, Redirect, withRouter } from "react-router-dom";
import { Divider, Tooltip } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DevicesIcon from "@material-ui/icons/Devices";

import HashSet from "../../lib/infra/HashSet";
import { fetchPendingIssuesCountAction } from "../../lib/redux/issues/FetchPendingIssuesCountAction";
import {
    openChangeLogModalAction,
    openGetSupportModal,
    openInteractiveCoverageModal,
} from "../../lib/redux/modals/OpenModalAction";
import ServiceWire from "../../lib/services/ServiceWire";
import ClientInfo from "../../lib/state/ClientInfo";
import { GlobalState } from "../../lib/state/GlobalState";
import SideBarSectionType from "../../lib/state/SideBarSectionType";
import UserInfo from "../../lib/state/UserInfo";
import {
    ArrowLeftIcon,
    ArrowRightIcon,
    BoxIcon,
    ChartActivityIcon,
    ChartTwoSeriesIcon,
    DashboardIcon,
    DialpadIcon,
    ExternalLinkIcon,
    FeedbackIcon,
    InteractiveCoverageIcon,
    ListIcon,
    LogoutIcon,
    QuestionMarkIcon,
    SettingsIcon,
    ShieldIcon,
    SternumLogoGradientIcon,
    SternumLongLogoOneColorIcon,
    UserIcon,
    WindowIcon,
} from "../SUI/SternumIcon";
import sideBarStyle from "./SideBarStyle";
import { Chip } from "./Chip";
import { DiamondChip } from "./DiamondChip";
import { SideBarFrame } from "./SideBarItemFrame";
import { SternumPlatformFreeTrial } from "./SternumPlatformFreeTrial";
import { GoProDialog } from "../GoProDialog/GoProDialog";
import { FeedbackDialog } from "../FeedbackDialog";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    redirectToLogin: boolean;
    redirectToDashboard: boolean;
    redirectToBoards: boolean;

    changedTracesFromTimestamp: number;
    changedTraces;

    changedDevicesFromTimestamp: number;
    changedDevices;

    sideBarOpen: boolean;

    logOutCloseBarPosition: number;
    logOutOpenBarPosition: number;

    userPopupMenuAnchorElement;
    boardPopupMenuAnchorElement;

    currentActiveClient: ClientInfo;
    optionalClientsList: ClientInfo[];

    interactiveCoverageOpen: boolean;
    goProDialogOpen: boolean;
    feedbackDialogOpen: boolean;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof sideBarStyle> {
    onSideBarToggled: (sideBarOpen: boolean) => void;
    sectionsToDisplayOnSideBarSet?: HashSet;
    dangerSideBar?: boolean;
    theme?;
    match;
    history?;
    location?;
    user?: UserInfo;

    fetchPendingIssuesCountAction?: () => void;
    openChangeLogModal?: (key: string, displayXButton: boolean, displayBackButton: boolean) => void;
    openSecurityCoverageModal?: (key: string) => void;
    openGetSupportModal?: (key: string) => void;
    loadingPendingIssuesCount?: boolean;
    pendingIssuesCount?: number;
    isFreeUser?: boolean;
    devicesLeft?: number;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    const selectedClient = ServiceWire.getClientsService().getSelectedClient();
    const maxPossibleDevices = selectedClient.limits?.devicesLimit || 0;
    const alreadyUsedDevices = selectedClient.usage?.devices || 0;

    return {
        loadingPendingIssuesCount: state.issues.loadingPendingIssuesCount,
        pendingIssuesCount: state.issues.pendingIssuesCount,
        user: state.user.entity,
        isFreeUser: selectedClient.isTrialTier(),
        devicesLeft: Math.max(0, maxPossibleDevices - alreadyUsedDevices),
    };
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchPendingIssuesCountAction: () => dispatch(fetchPendingIssuesCountAction()),
        openChangeLogModal: (key: string, displayXButton: boolean, displayBackButton: boolean) =>
            dispatch(openChangeLogModalAction(key, displayXButton, displayBackButton)),
        openSecurityCoverageModal: (key: string) => dispatch(openInteractiveCoverageModal(key)),
        openGetSupportModal: (key: string, onSuccess?: () => unknown) => dispatch(openGetSupportModal(key, onSuccess)),
    };
};

/**
 * Holds the content of the side bar of our application.
 */
class SideBar extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            redirectToLogin: false,
            redirectToDashboard: false,
            redirectToBoards: false,

            changedTracesFromTimestamp: 0,
            changedTraces: null,

            changedDevicesFromTimestamp: 0,
            changedDevices: null,

            sideBarOpen: true,

            logOutCloseBarPosition: 105,
            logOutOpenBarPosition: 230,

            userPopupMenuAnchorElement: null,
            boardPopupMenuAnchorElement: null,

            currentActiveClient: ServiceWire.getClientsService().getSelectedClient(),
            optionalClientsList: ServiceWire.getClientsService().getClients(),

            interactiveCoverageOpen: false,
            goProDialogOpen: false,
            feedbackDialogOpen: false,
        };
    }

    /**
     * Occurs once the component finished its initialization process.
     */
    async componentDidMount() {
        this.props.fetchPendingIssuesCountAction();
    }

    /**
     * Renders the component.
     */

    render() {
        const { classes } = this.props;

        if (this.state.redirectToLogin) {
            return <Redirect to={"/login"} />;
        } else if (this.state.redirectToDashboard) {
            window.location.pathname = "/dashboard";
            return null;
        } else if (this.state.redirectToBoards) {
            // Stop current client polling operation before redirecting to boards selection page
            ServiceWire.getPollingService().stopPolling();
            return <Redirect to={"/boards"} />;
        } else if (this.props.isFreeUser) {
            return (
                <>
                    {this.renderFreeAccountUserSideBar()}
                    {this.renderDialogs()}
                </>
            );
        } else {
            return (
                <>
                    <Drawer
                        role="presentation"
                        aria-label="left sternum sidebar"
                        variant="permanent"
                        anchor="left"
                        classes={{
                            paper: classNames(
                                classes.drawerPaper,
                                !this.state.sideBarOpen && classes.drawerPaperClose,
                                this.props.dangerSideBar && "mod-danger"
                            ),
                        }}
                        open={this.state.sideBarOpen}
                    >
                        {/* Company logo */}
                        <div
                            className={classNames(
                                classes.companyLogoContainer,
                                this.state.sideBarOpen && "sidebarOpen"
                            )}
                        >
                            {this.state.sideBarOpen ? (
                                <SternumLongLogoOneColorIcon color="white" />
                            ) : (
                                <SternumLogoGradientIcon color="white" />
                            )}
                        </div>

                        {/* Boards indication */}
                        <div
                            className={classNames(classes.clientIndicationContainer)}
                            style={{
                                visibility: this.state.sideBarOpen ? "visible" : "hidden",
                            }}
                        >
                            {/* Board name */}
                            <Typography
                                variant="body2"
                                classes={{ root: classes.clientTypography }}
                                role="presentation"
                                aria-label="board name - active client"
                            >
                                {this.state.currentActiveClient.name}
                            </Typography>
                        </div>

                        {/* Menu items */}
                        <List className={classes.menuItemsContainer}>
                            {/* Dashboard */}
                            {(!this.props.sectionsToDisplayOnSideBarSet ||
                                this.props.sectionsToDisplayOnSideBarSet.exists(
                                    SideBarSectionType.DASHBOARD.toString()
                                )) && (
                                <NavLink
                                    to={"/dashboard"}
                                    className={classes.menuItemLink}
                                    activeClassName={classes.menuItemLinkActive}
                                >
                                    <ListItem button key="Dashboard" className={classes.sidebarNavLinkListItemPadding}>
                                        {/* Icon */}
                                        <ListItemIcon>
                                            <DashboardIcon color="white" />
                                        </ListItemIcon>

                                        {/* Title */}
                                        {this.state.sideBarOpen && (
                                            <Typography variant="body2" className={classes.menuItemLinkTypography}>
                                                Dashboard
                                            </Typography>
                                        )}
                                    </ListItem>
                                </NavLink>
                            )}

                            {/* Issues */}
                            {(!this.props.sectionsToDisplayOnSideBarSet ||
                                this.props.sectionsToDisplayOnSideBarSet.exists(
                                    SideBarSectionType.ISSUES.toString()
                                )) && (
                                <NavLink
                                    to={"/alerts"}
                                    className={classes.menuItemLink}
                                    activeClassName={classes.menuItemLinkActive}
                                >
                                    <ListItem button key="Alerts" className={classes.sidebarNavLinkListItemPadding}>
                                        {/* Icon */}
                                        <ListItemIcon>
                                            <ShieldIcon color="white" />
                                        </ListItemIcon>

                                        {/* Title */}
                                        {this.state.sideBarOpen && (
                                            <Typography className={classes.menuItemLinkTypography}>
                                                Critical Alerts
                                            </Typography>
                                        )}

                                        {this.state.sideBarOpen &&
                                            !this.props.loadingPendingIssuesCount &&
                                            !!this.props.pendingIssuesCount && (
                                                <Tooltip
                                                    placement="top"
                                                    title={
                                                        this.props.pendingIssuesCount >= 100 &&
                                                        this.props.pendingIssuesCount
                                                    }
                                                >
                                                    <div
                                                        role="presentation"
                                                        aria-label="critical alerts issues count badge"
                                                        className={classes.issuesCountBadge}
                                                    >
                                                        <Typography
                                                            variant="caption"
                                                            className={classes.issuesCountText}
                                                        >
                                                            {this.props.pendingIssuesCount >= 100
                                                                ? "99+"
                                                                : this.props.pendingIssuesCount}
                                                        </Typography>
                                                    </div>
                                                </Tooltip>
                                            )}
                                    </ListItem>
                                </NavLink>
                            )}

                            {/* Fleet View */}
                            {(!this.props.sectionsToDisplayOnSideBarSet ||
                                this.props.sectionsToDisplayOnSideBarSet.exists(
                                    SideBarSectionType.FLEET_VIEW.toString()
                                )) && (
                                <NavLink
                                    to={
                                        this.props.match.params.deviceDefinitionVersionId
                                            ? `/fleet-view/${this.props.match.params.deviceDefinitionVersionId}`
                                            : "/fleet-view"
                                    }
                                    className={classes.menuItemLink}
                                    activeClassName={classes.menuItemLinkActive}
                                >
                                    <ListItem button key="FleetView" className={classes.sidebarNavLinkListItemPadding}>
                                        {/* Icon */}
                                        <ListItemIcon>
                                            <DialpadIcon color="white" />
                                        </ListItemIcon>

                                        {/* Title */}
                                        {this.state.sideBarOpen && (
                                            <Typography variant="body2" className={classes.menuItemLinkTypography}>
                                                Fleet View
                                            </Typography>
                                        )}
                                    </ListItem>
                                </NavLink>
                            )}

                            {/* Dashboards */}
                            {(!this.props.sectionsToDisplayOnSideBarSet ||
                                this.props.sectionsToDisplayOnSideBarSet.exists(
                                    SideBarSectionType.ANALYTICS.toString()
                                )) && (
                                <NavLink
                                    to={
                                        this.props.match.params.dashboardId
                                            ? `/glances/${this.props.match.params.dashboardId}`
                                            : "/glances"
                                    }
                                    className={classNames(
                                        classes.menuItemLink,
                                        this.props.match &&
                                            this.props.match.params &&
                                            this.props.match.params.dashboardId &&
                                            classes.menuItemLinkActive
                                    )}
                                    activeClassName={classes.menuItemLinkActive}
                                >
                                    <ListItem button key="Glances" className={classes.sidebarNavLinkListItemPadding}>
                                        <ListItemIcon>
                                            <ChartTwoSeriesIcon color="white" />
                                        </ListItemIcon>

                                        {this.state.sideBarOpen && (
                                            <Typography variant="body2" className={classes.menuItemLinkTypography}>
                                                Glances
                                            </Typography>
                                        )}
                                    </ListItem>
                                </NavLink>
                            )}

                            {/* Clients (Admin) */}
                            {!!(
                                this.props.sectionsToDisplayOnSideBarSet &&
                                this.props.sectionsToDisplayOnSideBarSet.exists(SideBarSectionType.CLIENTS.toString())
                            ) && (
                                <NavLink
                                    to={"/clients"}
                                    className={classes.menuItemLink}
                                    activeClassName={classes.menuItemLinkActive}
                                >
                                    <ListItem button key="Clients" className={classes.sidebarNavLinkListItemPadding}>
                                        {/* Icon */}
                                        <ListItemIcon>
                                            <DevicesIcon className={classes.menuItemLinkIcon} />
                                        </ListItemIcon>

                                        {/* Title */}
                                        <Typography variant="body2" className={classes.menuItemLinkTypography}>
                                            Clients
                                        </Typography>
                                    </ListItem>
                                </NavLink>
                            )}

                            {/* Users (Admin) */}
                            {!!(
                                this.props.sectionsToDisplayOnSideBarSet &&
                                this.props.sectionsToDisplayOnSideBarSet.exists(SideBarSectionType.USERS.toString())
                            ) && (
                                <NavLink
                                    to={"/users"}
                                    className={classes.menuItemLink}
                                    activeClassName={classes.menuItemLinkActive}
                                >
                                    <ListItem button key="Users" className={classes.sidebarNavLinkListItemPadding}>
                                        {/* Icon */}
                                        <ListItemIcon>
                                            <DevicesIcon className={classes.menuItemLinkIcon} />
                                        </ListItemIcon>

                                        {/* Title */}
                                        <Typography variant="body2" className={classes.menuItemLinkTypography}>
                                            Users
                                        </Typography>
                                    </ListItem>
                                </NavLink>
                            )}

                            {/* Traces (Admin) */}
                            {!!(
                                this.props.sectionsToDisplayOnSideBarSet &&
                                this.props.sectionsToDisplayOnSideBarSet.exists(SideBarSectionType.TRACES.toString())
                            ) && (
                                <NavLink
                                    to={"/traces"}
                                    className={classes.menuItemLink}
                                    activeClassName={classes.menuItemLinkActive}
                                >
                                    <ListItem button key="Traces" className={classes.sidebarNavLinkListItemPadding}>
                                        {/* Icon */}
                                        <ListItemIcon>
                                            <DevicesIcon className={classes.menuItemLinkIcon} />
                                        </ListItemIcon>

                                        {/* Title */}
                                        <Typography className={classes.menuItemLinkTypography}>Traces</Typography>
                                    </ListItem>
                                </NavLink>
                            )}

                            {/* Activities (Admin) */}
                            {!!(
                                this.props.sectionsToDisplayOnSideBarSet &&
                                this.props.sectionsToDisplayOnSideBarSet.exists(
                                    SideBarSectionType.ACTIVITIES.toString()
                                )
                            ) && (
                                <NavLink
                                    to={"/activities"}
                                    className={classes.menuItemLink}
                                    activeClassName={classes.menuItemLinkActive}
                                >
                                    <ListItem button key="Activities" className={classes.sidebarNavLinkListItemPadding}>
                                        {/* Icon */}
                                        <ListItemIcon>
                                            <DevicesIcon className={classes.menuItemLinkIcon} />
                                        </ListItemIcon>

                                        {/* Title */}
                                        <Typography className={classes.menuItemLinkTypography}>Activities</Typography>
                                    </ListItem>
                                </NavLink>
                            )}

                            {/* Cves (Admin) */}
                            {!!(
                                this.props.sectionsToDisplayOnSideBarSet &&
                                this.props.sectionsToDisplayOnSideBarSet.exists(SideBarSectionType.CVES.toString())
                            ) && (
                                <NavLink
                                    to={"/cves"}
                                    className={classes.menuItemLink}
                                    activeClassName={classes.menuItemLinkActive}
                                >
                                    <ListItem button key="Cves" className={classes.sidebarNavLinkListItemPadding}>
                                        {/* Icon */}
                                        <ListItemIcon>
                                            <DevicesIcon className={classes.menuItemLinkIcon} />
                                        </ListItemIcon>

                                        {/* Title */}
                                        <Typography className={classes.menuItemLinkTypography}>CVEs</Typography>
                                    </ListItem>
                                </NavLink>
                            )}

                            {/* Libraries (Admin) */}
                            {!!(
                                this.props.sectionsToDisplayOnSideBarSet &&
                                this.props.sectionsToDisplayOnSideBarSet.exists(SideBarSectionType.LIBRARIES.toString())
                            ) && (
                                <NavLink
                                    to={"/libraries"}
                                    className={classes.menuItemLink}
                                    activeClassName={classes.menuItemLinkActive}
                                >
                                    <ListItem button key="Libraries" className={classes.sidebarNavLinkListItemPadding}>
                                        {/* Icon */}
                                        <ListItemIcon>
                                            <DevicesIcon className={classes.menuItemLinkIcon} />
                                        </ListItemIcon>

                                        {/* Title */}
                                        <Typography className={classes.menuItemLinkTypography}>Libraries</Typography>
                                    </ListItem>
                                </NavLink>
                            )}

                            {/* Used Libraries (Admin) */}
                            {!!(
                                this.props.sectionsToDisplayOnSideBarSet &&
                                this.props.sectionsToDisplayOnSideBarSet.exists(
                                    SideBarSectionType.USED_LIBRARIES.toString()
                                )
                            ) && (
                                <NavLink
                                    to={"/used_libraries"}
                                    className={classes.menuItemLink}
                                    activeClassName={classes.menuItemLinkActive}
                                >
                                    <ListItem
                                        button
                                        key="UsedLibraries"
                                        className={classes.sidebarNavLinkListItemPadding}
                                    >
                                        {/* Icon */}
                                        <ListItemIcon>
                                            <DevicesIcon className={classes.menuItemLinkIcon} />
                                        </ListItemIcon>

                                        {/* Title */}
                                        <Typography className={classes.menuItemLinkTypography}>
                                            Used Libraries
                                        </Typography>
                                    </ListItem>
                                </NavLink>
                            )}

                            {/* Libraries Histories (Admin) */}
                            {!!(
                                this.props.sectionsToDisplayOnSideBarSet &&
                                this.props.sectionsToDisplayOnSideBarSet.exists(
                                    SideBarSectionType.LIBRARIES_HISTORIES.toString()
                                )
                            ) && (
                                <NavLink
                                    to={"/libraries_histories"}
                                    className={classes.menuItemLink}
                                    activeClassName={classes.menuItemLinkActive}
                                >
                                    <ListItem
                                        button
                                        key="LibrariesHistories"
                                        className={classes.sidebarNavLinkListItemPadding}
                                    >
                                        {/* Icon */}
                                        <ListItemIcon>
                                            <DevicesIcon className={classes.menuItemLinkIcon} />
                                        </ListItemIcon>

                                        {/* Title */}
                                        <Typography className={classes.menuItemLinkTypography}>
                                            Library History
                                        </Typography>
                                    </ListItem>
                                </NavLink>
                            )}

                            {/* Used Libraries Histories (Admin) */}
                            {!!(
                                this.props.sectionsToDisplayOnSideBarSet &&
                                this.props.sectionsToDisplayOnSideBarSet.exists(
                                    SideBarSectionType.USED_LIBRARIES_HISTORIES.toString()
                                )
                            ) && (
                                <NavLink
                                    to={"/used_libraries_histories"}
                                    className={classes.menuItemLink}
                                    activeClassName={classes.menuItemLinkActive}
                                >
                                    <ListItem
                                        button
                                        key="UsedLibrariesHistory"
                                        className={classes.sidebarNavLinkListItemPadding}
                                    >
                                        {/* Icon */}
                                        <ListItemIcon>
                                            <DevicesIcon className={classes.menuItemLinkIcon} />
                                        </ListItemIcon>

                                        {/* Title */}
                                        <Typography className={classes.menuItemLinkTypography}>
                                            Used-Lib History
                                        </Typography>
                                    </ListItem>
                                </NavLink>
                            )}

                            {/* Libraries */}
                            {(!this.props.sectionsToDisplayOnSideBarSet ||
                                this.props.sectionsToDisplayOnSideBarSet.exists(
                                    SideBarSectionType.LIBRARIES_DASHBOARD.toString()
                                )) && (
                                <NavLink
                                    to={"/libraries"}
                                    className={classes.menuItemLink}
                                    activeClassName={classes.menuItemLinkActive}
                                >
                                    <ListItem button key="Dashboard" className={classes.sidebarNavLinkListItemPadding}>
                                        {/* Icon */}
                                        <ListItemIcon>
                                            <BoxIcon color="white" />
                                        </ListItemIcon>

                                        {/* Title */}
                                        {this.state.sideBarOpen && (
                                            <Typography variant="body2" className={classes.menuItemLinkTypography}>
                                                3ʳᵈ Party
                                            </Typography>
                                        )}
                                    </ListItem>
                                </NavLink>
                            )}

                            {/* Devices */}
                            {(!this.props.sectionsToDisplayOnSideBarSet ||
                                this.props.sectionsToDisplayOnSideBarSet.exists(
                                    SideBarSectionType.DEVICES.toString()
                                )) && (
                                <NavLink
                                    to={"/devices"}
                                    className={classNames(
                                        classes.menuItemLink,
                                        this.props.match &&
                                            this.props.match.params &&
                                            this.props.match.params.deviceId &&
                                            classes.menuItemLinkActive
                                    )}
                                    activeClassName={classes.menuItemLinkActive}
                                >
                                    <ListItem button key="Devices" className={classes.sidebarNavLinkListItemPadding}>
                                        {/* Icon */}
                                        <ListItemIcon>
                                            <ChartActivityIcon color="white" />
                                        </ListItemIcon>

                                        {/* Title */}
                                        {this.state.sideBarOpen && (
                                            <Typography variant="body2" className={classes.menuItemLinkTypography}>
                                                Device Inventory
                                            </Typography>
                                        )}
                                    </ListItem>
                                </NavLink>
                            )}

                            {/* Device Definitions */}
                            {(!this.props.sectionsToDisplayOnSideBarSet ||
                                this.props.sectionsToDisplayOnSideBarSet.exists(
                                    SideBarSectionType.DEVICE_DEFINITIONS.toString()
                                )) && (
                                <NavLink
                                    to={"/device-profiles"}
                                    className={classNames(
                                        classes.menuItemLink,
                                        this.props.match &&
                                            this.props.match.params &&
                                            this.props.match.params.deviceDefinitionId &&
                                            classes.menuItemLinkActive
                                    )}
                                    activeClassName={classes.menuItemLinkActive}
                                >
                                    <ListItem
                                        button
                                        key="DeviceDefinitions"
                                        className={classes.sidebarNavLinkListItemPadding}
                                    >
                                        {/* Icon */}
                                        <ListItemIcon>
                                            <ListIcon color="white" />
                                        </ListItemIcon>

                                        {/* Title */}
                                        {this.state.sideBarOpen && (
                                            <Typography variant="body2" className={classes.menuItemLinkTypography}>
                                                Device Profiles
                                            </Typography>
                                        )}
                                    </ListItem>
                                </NavLink>
                            )}

                            {/* Settings */}
                            {(!this.props.sectionsToDisplayOnSideBarSet ||
                                this.props.sectionsToDisplayOnSideBarSet.exists(
                                    SideBarSectionType.USER_SETTINGS.toString()
                                )) && (
                                <NavLink
                                    to={"/settings"}
                                    className={classes.menuItemLink}
                                    activeClassName={classes.menuItemLinkActive}
                                >
                                    <ListItem button key="Settings" className={classes.sidebarNavLinkListItemPadding}>
                                        {/* Icon */}
                                        <ListItemIcon>
                                            <SettingsIcon color="white" />
                                        </ListItemIcon>

                                        {/* Title */}
                                        {this.state.sideBarOpen && (
                                            <Typography variant="body2" className={classes.menuItemLinkTypography}>
                                                Settings
                                            </Typography>
                                        )}
                                    </ListItem>
                                </NavLink>
                            )}

                            {/* Placeholder for flex to grow */}
                            <div className={classes.flexGrow}></div>

                            {this.renderSideBarFrame()}

                            <Divider className={classes.bottomDivider} />

                            {/* Collapse */}
                            <ListItem
                                button
                                key={"Collapse"}
                                onClick={() => this.handleDrawerToggled()}
                                className={classes.sidebarNavLinkListItemPadding}
                            >
                                {/* Icon */}
                                <ListItemIcon>
                                    {this.state.sideBarOpen ? (
                                        <ArrowLeftIcon color="#6F96E4" />
                                    ) : (
                                        <ArrowRightIcon color="#6F96E4" />
                                    )}
                                </ListItemIcon>

                                {/* Title */}
                                {this.state.sideBarOpen && (
                                    <Typography variant="body2" className={classes.menuItemLinkTypography}>
                                        Collapse
                                    </Typography>
                                )}
                            </ListItem>

                            {/* Logged in user */}
                            <ListItem
                                aria-label="logged in user list item"
                                button
                                key={"User"}
                                onClick={this.handleUserMenuItemClicked.bind(this)}
                                aria-owns={this.state.userPopupMenuAnchorElement ? "user-popup-menu" : undefined}
                                aria-haspopup="true"
                                className={classes.sidebarNavLinkListItemPadding}
                            >
                                {/* Avatar */}
                                <ListItemIcon>
                                    <UserIcon color="#fff" />
                                </ListItemIcon>

                                {this.state.sideBarOpen && (
                                    <div
                                        className={classNames(
                                            classes.flexRow,
                                            classes.fullWidth,
                                            classes.flexSpaceBetween
                                        )}
                                    >
                                        {/* Name */}
                                        <Typography variant="body2" className={classes.userTypography}>
                                            {this.props.user?.firstName || ""}
                                        </Typography>
                                        {/* Arrow icon */}
                                        <ArrowRightIcon color="#fff" />
                                    </div>
                                )}
                            </ListItem>

                            {/* Version number */}
                            {/* <div
                                className={classNames(
                                    classes.versionNumberContainer,
                                    this.state.sideBarOpen && "sidebarOpen"
                                )}
                            >
                                <Typography
                                    className={classNames(classes.versionNumber)}
                                    onClick={() => this.props.openChangeLogModal("changelog", true, false)}
                                >
                                    v1.3
                                </Typography>
                            </div> */}

                            {/** Check if there are multiple boards, create menu with boards section */}
                            {!this.props.sectionsToDisplayOnSideBarSet && this.state.optionalClientsList.length > 1 && (
                                <Menu
                                    PaperProps={{
                                        className: classes.userOptionsMenu,
                                    }}
                                    id={"user-popup-menu"}
                                    anchorEl={this.state.userPopupMenuAnchorElement}
                                    getContentAnchorEl={null}
                                    anchorOrigin={{
                                        vertical: 0,
                                        horizontal: "right",
                                    }}
                                    open={Boolean(this.state.userPopupMenuAnchorElement)}
                                    onClose={this.handleUserPopupMenuClosed.bind(this)}
                                >
                                    {/** Boards menu item */}
                                    <MenuItem
                                        hidden={
                                            !this.props.sectionsToDisplayOnSideBarSet &&
                                            this.state.optionalClientsList.length > 1
                                        }
                                        onClick={this.handleBoardMenuItemClicked.bind(this)}
                                        className={classNames(
                                            classes.menuItemLogoutTopBottomPadding,
                                            classes.menuItemLogoutLeftRightPadding,
                                            classes.menuItemLogoutBoxSizing
                                        )}
                                    >
                                        <ListItemIcon>
                                            <WindowIcon />
                                        </ListItemIcon>
                                        <div
                                            className={classNames(
                                                classes.flexRow,
                                                classes.fullWidth,
                                                classes.flexSpaceBetween
                                            )}
                                        >
                                            {/* Title */}
                                            <Typography className={classes.userOptionMenuItemText}>Boards</Typography>
                                            {/* Arrow icon */}
                                            <ArrowRightIcon fontSize="small" />
                                        </div>
                                    </MenuItem>

                                    {/** Logout menu item */}
                                    <MenuItem
                                        onClick={this.handleLogoutClicked.bind(this)}
                                        className={classNames(
                                            classes.menuItemLogoutTopBottomPadding,
                                            classes.menuItemLogoutLeftRightPadding,
                                            classes.menuItemLogoutBoxSizing
                                        )}
                                    >
                                        <ListItemIcon>
                                            <LogoutIcon />
                                        </ListItemIcon>
                                        <Typography className={classes.userOptionMenuItemText}>Logout</Typography>
                                    </MenuItem>
                                </Menu>
                            )}
                            {!this.props.sectionsToDisplayOnSideBarSet && this.state.optionalClientsList.length === 1 && (
                                <Menu
                                    PaperProps={{
                                        className: classes.userOptionsMenu,
                                    }}
                                    id={"user-popup-menu"}
                                    anchorEl={this.state.userPopupMenuAnchorElement}
                                    getContentAnchorEl={null}
                                    anchorOrigin={{
                                        vertical: 0,
                                        horizontal: "right",
                                    }}
                                    open={Boolean(this.state.userPopupMenuAnchorElement)}
                                    onClose={this.handleUserPopupMenuClosed.bind(this)}
                                >
                                    {/** Logout menu item */}
                                    <MenuItem
                                        onClick={this.handleLogoutClicked.bind(this)}
                                        className={classNames(
                                            classes.menuItemLogoutTopBottomPadding,
                                            classes.menuItemLogoutLeftRightPadding,
                                            classes.menuItemLogoutBoxSizing
                                        )}
                                    >
                                        <ListItemIcon>
                                            <LogoutIcon />
                                        </ListItemIcon>
                                        <Typography className={classes.userOptionMenuItemText}>Logout</Typography>
                                    </MenuItem>
                                </Menu>
                            )}

                            {/** Boards menu selection */}
                            <Menu
                                PaperProps={{
                                    className: classes.userOptionsMenu,
                                }}
                                id={"board-popup-menu"}
                                anchorEl={this.state.boardPopupMenuAnchorElement}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: -50,
                                    horizontal: "right",
                                }}
                                open={Boolean(this.state.boardPopupMenuAnchorElement)}
                                onClose={this.handleBoardPopupMenuClosed.bind(this)}
                            >
                                {/** All boards selection */}
                                <MenuItem
                                    onClick={() => {
                                        this.boardsLinkClick();
                                    }}
                                    className={classNames(
                                        classes.menuItemLogoutTopBottomPadding,
                                        classes.menuItemLogoutLeftRightPadding,
                                        classes.menuItemLogoutBoxSizing
                                    )}
                                >
                                    <ExternalLinkIcon color="#E1034C" />
                                    <Typography
                                        className={classNames(
                                            classes.userOptionMenuItemText,
                                            classes.userOptionMenuItemAllBoards
                                        )}
                                    >
                                        All Boards
                                    </Typography>
                                </MenuItem>
                                {/** All boards selection */}
                                {this.state.optionalClientsList
                                    .filter((client) => client.name !== this.state.currentActiveClient.name)
                                    .map((client) => (
                                        <MenuItem
                                            key={client.clientId}
                                            className={classNames(
                                                classes.flexColumn,
                                                classes.flexCenter,
                                                classes.menuItemLogoutTopBottomPadding,
                                                classes.menuItemLogoutLeftRightPadding,
                                                classes.menuItemLogoutBoxSizing
                                            )}
                                            onClick={() => {
                                                this.boardSelectChange(client);
                                            }}
                                        >
                                            <Typography className={classes.userOptionMenuItemText}>
                                                {client.name}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                            </Menu>
                        </List>
                    </Drawer>

                    {this.renderDialogs()}
                </>
            );
        }
    }

    renderDialogs() {
        return (
            <>
                {this.state.goProDialogOpen ? (
                    <GoProDialog open={true} onCancelClick={() => this.setState({ goProDialogOpen: false })} />
                ) : null}

                {this.state.feedbackDialogOpen ? (
                    <FeedbackDialog open={true} onCancelClick={() => this.setState({ feedbackDialogOpen: false })} />
                ) : null}
            </>
        );
    }

    renderSideBarFrame() {
        if (!this.state.sideBarOpen) {
            return null;
        }

        const { classes, match } = this.props;
        const getSupportPath = "/get-support";
        const isGetSupportActive = getSupportPath === match?.path;

        const GetSupportItem = (
            <SideBarFrame.ItemButton
                isSelected={isGetSupportActive}
                onClick={!this.props.isFreeUser ? () => this.props.openGetSupportModal("getSupport") : undefined}
                icon={<QuestionMarkIcon width={14} height={14} color={isGetSupportActive ? "#E7004C" : "#fff"} />}
            >
                Get Support
            </SideBarFrame.ItemButton>
        );

        return (
            <SideBarFrame>
                <SideBarFrame.ItemButton
                    icon={<InteractiveCoverageIcon width={14} height={14} color="#fff" />}
                    onClick={this.handleInteractiveCoverageItemClicked.bind(this)}
                >
                    Security Coverage
                </SideBarFrame.ItemButton>
                {this.props.isFreeUser && (
                    <SideBarFrame.Item>
                        <SternumPlatformFreeTrial
                            devicesLeft={this.props.devicesLeft}
                            onClickProtectAll={() => this.setState({ goProDialogOpen: true })}
                        />
                    </SideBarFrame.Item>
                )}
                <SideBarFrame.ItemButton
                    icon={<FeedbackIcon />}
                    onClick={() => this.setState({ feedbackDialogOpen: true })}
                >
                    Feedback
                </SideBarFrame.ItemButton>

                {this.props.isFreeUser ? (
                    <NavLink to={getSupportPath} className={classes.menuFrameItemLink}>
                        {GetSupportItem}
                    </NavLink>
                ) : (
                    GetSupportItem
                )}
            </SideBarFrame>
        );
    }

    private renderFreeAccountUserSideBar() {
        const { classes } = this.props;

        return (
            <>
                <Drawer
                    role="presentation"
                    aria-label="left sternum sidebar"
                    variant="permanent"
                    anchor="left"
                    classes={{
                        paper: classNames(
                            classes.drawerPaper,
                            !this.state.sideBarOpen && classes.drawerPaperClose,
                            this.props.dangerSideBar && "mod-danger"
                        ),
                    }}
                    open={this.state.sideBarOpen}
                >
                    {/* Company logo */}
                    <div className={classNames(classes.companyLogoContainer, this.state.sideBarOpen && "sidebarOpen")}>
                        {this.state.sideBarOpen ? (
                            <>
                                <SternumLongLogoOneColorIcon color="white" />
                                <Chip>Free</Chip>
                            </>
                        ) : (
                            <SternumLogoGradientIcon color="white" />
                        )}
                    </div>

                    {/* Menu items */}
                    <List className={classes.menuItemsContainer}>
                        {/* Dashboard */}
                        {(!this.props.sectionsToDisplayOnSideBarSet ||
                            this.props.sectionsToDisplayOnSideBarSet.exists(
                                SideBarSectionType.DASHBOARD.toString()
                            )) && (
                            <NavLink
                                to={"/dashboard"}
                                className={classes.menuItemLink}
                                activeClassName={classes.menuItemLinkActive}
                            >
                                <ListItem button key="Dashboard" className={classes.sidebarNavLinkListItemPadding}>
                                    <ListItemIcon>
                                        <DashboardIcon color="white" />
                                    </ListItemIcon>

                                    {this.state.sideBarOpen && (
                                        <Typography variant="body2" className={classes.menuItemLinkTypography}>
                                            Dashboard
                                        </Typography>
                                    )}
                                </ListItem>
                            </NavLink>
                        )}

                        {/* Glances */}
                        {(!this.props.sectionsToDisplayOnSideBarSet ||
                            this.props.sectionsToDisplayOnSideBarSet.exists(
                                SideBarSectionType.ANALYTICS.toString()
                            )) && (
                            <NavLink
                                to={
                                    this.props.match.params.dashboardId
                                        ? `/glances/${this.props.match.params.dashboardId}`
                                        : "/glances"
                                }
                                className={classNames(
                                    classes.menuItemLink,
                                    this.props.match?.params?.dashboardId && classes.menuItemLinkActive
                                )}
                                activeClassName={classes.menuItemLinkActive}
                            >
                                <ListItem button key="Glances" className={classes.sidebarNavLinkListItemPadding}>
                                    <ListItemIcon>
                                        <ChartTwoSeriesIcon color="white" />
                                    </ListItemIcon>

                                    {this.state.sideBarOpen && (
                                        <Typography variant="body2" className={classes.menuItemLinkTypography}>
                                            Glances
                                        </Typography>
                                    )}
                                </ListItem>
                            </NavLink>
                        )}

                        {/* Issues */}
                        {(!this.props.sectionsToDisplayOnSideBarSet ||
                            this.props.sectionsToDisplayOnSideBarSet.exists(SideBarSectionType.ISSUES.toString())) && (
                            <div
                                className={classes.menuItemLink}
                                onClick={() => this.setState({ goProDialogOpen: true })}
                            >
                                <ListItem button key="Alerts" className={classes.sidebarNavLinkListItemPadding}>
                                    {/* Icon */}
                                    <ListItemIcon>
                                        <ShieldIcon color="white" />
                                    </ListItemIcon>

                                    {/* Title */}
                                    {this.state.sideBarOpen && (
                                        <>
                                            <Typography className={classes.menuItemLinkTypography}>
                                                Critical Alerts
                                            </Typography>
                                            <DiamondChip />
                                        </>
                                    )}
                                </ListItem>
                            </div>
                        )}

                        {/* Fleet View */}
                        {(!this.props.sectionsToDisplayOnSideBarSet ||
                            this.props.sectionsToDisplayOnSideBarSet.exists(
                                SideBarSectionType.FLEET_VIEW.toString()
                            )) && (
                            <div
                                className={classes.menuItemLink}
                                onClick={() => this.setState({ goProDialogOpen: true })}
                            >
                                <ListItem button key="FleetView" className={classes.sidebarNavLinkListItemPadding}>
                                    {/* Icon */}
                                    <ListItemIcon>
                                        <DialpadIcon color="white" />
                                    </ListItemIcon>

                                    {/* Title */}
                                    {this.state.sideBarOpen && (
                                        <>
                                            <Typography variant="body2" className={classes.menuItemLinkTypography}>
                                                Fleet View
                                            </Typography>
                                            <DiamondChip />
                                        </>
                                    )}
                                </ListItem>
                            </div>
                        )}

                        {/* Clients (Admin) */}
                        {!!(
                            this.props.sectionsToDisplayOnSideBarSet &&
                            this.props.sectionsToDisplayOnSideBarSet.exists(SideBarSectionType.CLIENTS.toString())
                        ) && (
                            <NavLink
                                to={"/clients"}
                                className={classes.menuItemLink}
                                activeClassName={classes.menuItemLinkActive}
                            >
                                <ListItem button key="Clients" className={classes.sidebarNavLinkListItemPadding}>
                                    {/* Icon */}
                                    <ListItemIcon>
                                        <DevicesIcon className={classes.menuItemLinkIcon} />
                                    </ListItemIcon>

                                    {/* Title */}
                                    <Typography variant="body2" className={classes.menuItemLinkTypography}>
                                        Clients
                                    </Typography>
                                </ListItem>
                            </NavLink>
                        )}

                        {/* Users (Admin) */}
                        {!!(
                            this.props.sectionsToDisplayOnSideBarSet &&
                            this.props.sectionsToDisplayOnSideBarSet.exists(SideBarSectionType.USERS.toString())
                        ) && (
                            <NavLink
                                to={"/users"}
                                className={classes.menuItemLink}
                                activeClassName={classes.menuItemLinkActive}
                            >
                                <ListItem button key="Users" className={classes.sidebarNavLinkListItemPadding}>
                                    {/* Icon */}
                                    <ListItemIcon>
                                        <DevicesIcon className={classes.menuItemLinkIcon} />
                                    </ListItemIcon>

                                    {/* Title */}
                                    <Typography variant="body2" className={classes.menuItemLinkTypography}>
                                        Users
                                    </Typography>
                                </ListItem>
                            </NavLink>
                        )}

                        {/* Traces (Admin) */}
                        {!!(
                            this.props.sectionsToDisplayOnSideBarSet &&
                            this.props.sectionsToDisplayOnSideBarSet.exists(SideBarSectionType.TRACES.toString())
                        ) && (
                            <NavLink
                                to={"/traces"}
                                className={classes.menuItemLink}
                                activeClassName={classes.menuItemLinkActive}
                            >
                                <ListItem button key="Traces" className={classes.sidebarNavLinkListItemPadding}>
                                    {/* Icon */}
                                    <ListItemIcon>
                                        <DevicesIcon className={classes.menuItemLinkIcon} />
                                    </ListItemIcon>

                                    {/* Title */}
                                    <Typography className={classes.menuItemLinkTypography}>Traces</Typography>
                                </ListItem>
                            </NavLink>
                        )}

                        {/* Activities (Admin) */}
                        {!!(
                            this.props.sectionsToDisplayOnSideBarSet &&
                            this.props.sectionsToDisplayOnSideBarSet.exists(SideBarSectionType.ACTIVITIES.toString())
                        ) && (
                            <NavLink
                                to={"/activities"}
                                className={classes.menuItemLink}
                                activeClassName={classes.menuItemLinkActive}
                            >
                                <ListItem button key="Activities" className={classes.sidebarNavLinkListItemPadding}>
                                    {/* Icon */}
                                    <ListItemIcon>
                                        <DevicesIcon className={classes.menuItemLinkIcon} />
                                    </ListItemIcon>

                                    {/* Title */}
                                    <Typography className={classes.menuItemLinkTypography}>Activities</Typography>
                                </ListItem>
                            </NavLink>
                        )}

                        {/* Cves (Admin) */}
                        {!!(
                            this.props.sectionsToDisplayOnSideBarSet &&
                            this.props.sectionsToDisplayOnSideBarSet.exists(SideBarSectionType.CVES.toString())
                        ) && (
                            <NavLink
                                to={"/cves"}
                                className={classes.menuItemLink}
                                activeClassName={classes.menuItemLinkActive}
                            >
                                <ListItem button key="Cves" className={classes.sidebarNavLinkListItemPadding}>
                                    {/* Icon */}
                                    <ListItemIcon>
                                        <DevicesIcon className={classes.menuItemLinkIcon} />
                                    </ListItemIcon>

                                    {/* Title */}
                                    <Typography className={classes.menuItemLinkTypography}>CVEs</Typography>
                                </ListItem>
                            </NavLink>
                        )}

                        {/* Libraries (Admin) */}
                        {!!(
                            this.props.sectionsToDisplayOnSideBarSet &&
                            this.props.sectionsToDisplayOnSideBarSet.exists(SideBarSectionType.LIBRARIES.toString())
                        ) && (
                            <NavLink
                                to={"/libraries"}
                                className={classes.menuItemLink}
                                activeClassName={classes.menuItemLinkActive}
                            >
                                <ListItem button key="Libraries" className={classes.sidebarNavLinkListItemPadding}>
                                    {/* Icon */}
                                    <ListItemIcon>
                                        <DevicesIcon className={classes.menuItemLinkIcon} />
                                    </ListItemIcon>

                                    {/* Title */}
                                    <Typography className={classes.menuItemLinkTypography}>Libraries</Typography>
                                </ListItem>
                            </NavLink>
                        )}

                        {/* Used Libraries (Admin) */}
                        {!!(
                            this.props.sectionsToDisplayOnSideBarSet &&
                            this.props.sectionsToDisplayOnSideBarSet.exists(
                                SideBarSectionType.USED_LIBRARIES.toString()
                            )
                        ) && (
                            <NavLink
                                to={"/used_libraries"}
                                className={classes.menuItemLink}
                                activeClassName={classes.menuItemLinkActive}
                            >
                                <ListItem button key="UsedLibraries" className={classes.sidebarNavLinkListItemPadding}>
                                    {/* Icon */}
                                    <ListItemIcon>
                                        <DevicesIcon className={classes.menuItemLinkIcon} />
                                    </ListItemIcon>

                                    {/* Title */}
                                    <Typography className={classes.menuItemLinkTypography}>Used Libraries</Typography>
                                </ListItem>
                            </NavLink>
                        )}

                        {/* Libraries Histories (Admin) */}
                        {!!(
                            this.props.sectionsToDisplayOnSideBarSet &&
                            this.props.sectionsToDisplayOnSideBarSet.exists(
                                SideBarSectionType.LIBRARIES_HISTORIES.toString()
                            )
                        ) && (
                            <NavLink
                                to={"/libraries_histories"}
                                className={classes.menuItemLink}
                                activeClassName={classes.menuItemLinkActive}
                            >
                                <ListItem
                                    button
                                    key="LibrariesHistories"
                                    className={classes.sidebarNavLinkListItemPadding}
                                >
                                    {/* Icon */}
                                    <ListItemIcon>
                                        <DevicesIcon className={classes.menuItemLinkIcon} />
                                    </ListItemIcon>

                                    {/* Title */}
                                    <Typography className={classes.menuItemLinkTypography}>Library History</Typography>
                                </ListItem>
                            </NavLink>
                        )}

                        {/* Used Libraries Histories (Admin) */}
                        {!!(
                            this.props.sectionsToDisplayOnSideBarSet &&
                            this.props.sectionsToDisplayOnSideBarSet.exists(
                                SideBarSectionType.USED_LIBRARIES_HISTORIES.toString()
                            )
                        ) && (
                            <NavLink
                                to={"/used_libraries_histories"}
                                className={classes.menuItemLink}
                                activeClassName={classes.menuItemLinkActive}
                            >
                                <ListItem
                                    button
                                    key="UsedLibrariesHistory"
                                    className={classes.sidebarNavLinkListItemPadding}
                                >
                                    {/* Icon */}
                                    <ListItemIcon>
                                        <DevicesIcon className={classes.menuItemLinkIcon} />
                                    </ListItemIcon>

                                    {/* Title */}
                                    <Typography className={classes.menuItemLinkTypography}>Used-Lib History</Typography>
                                </ListItem>
                            </NavLink>
                        )}

                        {/* Libraries */}
                        {(!this.props.sectionsToDisplayOnSideBarSet ||
                            this.props.sectionsToDisplayOnSideBarSet.exists(
                                SideBarSectionType.LIBRARIES_DASHBOARD.toString()
                            )) && (
                            <div
                                className={classes.menuItemLink}
                                onClick={() => this.setState({ goProDialogOpen: true })}
                            >
                                <ListItem button key="Dashboard" className={classes.sidebarNavLinkListItemPadding}>
                                    {/* Icon */}
                                    <ListItemIcon>
                                        <BoxIcon color="white" />
                                    </ListItemIcon>

                                    {/* Title */}
                                    {this.state.sideBarOpen && (
                                        <>
                                            <Typography variant="body2" className={classes.menuItemLinkTypography}>
                                                3ʳᵈ Party
                                            </Typography>
                                            <DiamondChip />
                                        </>
                                    )}
                                </ListItem>
                            </div>
                        )}

                        {/* Devices */}
                        {(!this.props.sectionsToDisplayOnSideBarSet ||
                            this.props.sectionsToDisplayOnSideBarSet.exists(SideBarSectionType.DEVICES.toString())) && (
                            <div
                                className={classNames(classes.menuItemLink)}
                                onClick={() => this.setState({ goProDialogOpen: true })}
                            >
                                <ListItem button key="Devices" className={classes.sidebarNavLinkListItemPadding}>
                                    {/* Icon */}
                                    <ListItemIcon>
                                        <ChartActivityIcon color="white" />
                                    </ListItemIcon>

                                    {/* Title */}
                                    {this.state.sideBarOpen && (
                                        <>
                                            <Typography variant="body2" className={classes.menuItemLinkTypography}>
                                                Device Inventory
                                            </Typography>
                                            <DiamondChip />
                                        </>
                                    )}
                                </ListItem>
                            </div>
                        )}

                        {/* Device Definitions */}
                        {(!this.props.sectionsToDisplayOnSideBarSet ||
                            this.props.sectionsToDisplayOnSideBarSet.exists(
                                SideBarSectionType.DEVICE_DEFINITIONS.toString()
                            )) && (
                            <div
                                className={classNames(classes.menuItemLink)}
                                onClick={() => this.setState({ goProDialogOpen: true })}
                            >
                                <ListItem
                                    button
                                    key="DeviceDefinitions"
                                    className={classes.sidebarNavLinkListItemPadding}
                                >
                                    {/* Icon */}
                                    <ListItemIcon>
                                        <ListIcon color="white" />
                                    </ListItemIcon>

                                    {/* Title */}
                                    {this.state.sideBarOpen && (
                                        <>
                                            <Typography variant="body2" className={classes.menuItemLinkTypography}>
                                                Device Profiles
                                            </Typography>
                                            <DiamondChip />
                                        </>
                                    )}
                                </ListItem>
                            </div>
                        )}

                        {/* Settings */}
                        {(!this.props.sectionsToDisplayOnSideBarSet ||
                            this.props.sectionsToDisplayOnSideBarSet.exists(
                                SideBarSectionType.USER_SETTINGS.toString()
                            )) && (
                            <NavLink
                                to={"/settings"}
                                className={classes.menuItemLink}
                                activeClassName={classes.menuItemLinkActive}
                            >
                                <ListItem button key="Settings" className={classes.sidebarNavLinkListItemPadding}>
                                    {/* Icon */}
                                    <ListItemIcon>
                                        <SettingsIcon color="white" />
                                    </ListItemIcon>

                                    {/* Title */}
                                    {this.state.sideBarOpen && (
                                        <Typography variant="body2" className={classes.menuItemLinkTypography}>
                                            Settings
                                        </Typography>
                                    )}
                                </ListItem>
                            </NavLink>
                        )}

                        {/* Placeholder for flex to grow */}
                        <div className={classes.flexGrow}></div>

                        {this.renderSideBarFrame()}

                        <Divider className={classes.bottomDivider} />

                        {/* Collapse */}
                        <ListItem
                            button
                            key={"Collapse"}
                            onClick={() => this.handleDrawerToggled()}
                            className={classes.sidebarNavLinkListItemPadding}
                        >
                            {/* Icon */}
                            <ListItemIcon>
                                {this.state.sideBarOpen ? (
                                    <ArrowLeftIcon color="#6F96E4" />
                                ) : (
                                    <ArrowRightIcon color="#6F96E4" />
                                )}
                            </ListItemIcon>

                            {/* Title */}
                            {this.state.sideBarOpen && (
                                <Typography variant="body2" className={classes.menuItemLinkTypography}>
                                    Collapse
                                </Typography>
                            )}
                        </ListItem>

                        {/* Logged in user */}
                        <ListItem
                            aria-label="logged in user list item"
                            button
                            key={"User"}
                            onClick={this.handleUserMenuItemClicked.bind(this)}
                            aria-owns={this.state.userPopupMenuAnchorElement ? "user-popup-menu" : undefined}
                            aria-haspopup="true"
                            className={classes.sidebarNavLinkListItemPadding}
                        >
                            {/* Avatar */}
                            <ListItemIcon>
                                <UserIcon color="#fff" />
                            </ListItemIcon>

                            {this.state.sideBarOpen && (
                                <div
                                    className={classNames(classes.flexRow, classes.fullWidth, classes.flexSpaceBetween)}
                                >
                                    {/* Name */}
                                    <Typography variant="body2" className={classes.userTypography}>
                                        {this.props.user?.firstName || ""}
                                    </Typography>
                                    {/* Arrow icon */}
                                    <ArrowRightIcon color="#fff" />
                                </div>
                            )}
                        </ListItem>

                        {/* Version number */}
                        {/* <div
                            className={classNames(
                                classes.versionNumberContainer,
                                this.state.sideBarOpen && "sidebarOpen"
                            )}
                        >
                            <Typography
                                className={classNames(classes.versionNumber)}
                                onClick={() => this.props.openChangeLogModal("changelog", true, false)}
                            >
                                v1.3
                            </Typography>
                        </div> */}

                        {/** Check if there are multiple boards, create menu with boards section */}
                        {!this.props.sectionsToDisplayOnSideBarSet && this.state.optionalClientsList.length > 1 && (
                            <Menu
                                PaperProps={{
                                    className: classes.userOptionsMenu,
                                }}
                                id={"user-popup-menu"}
                                anchorEl={this.state.userPopupMenuAnchorElement}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: 0,
                                    horizontal: "right",
                                }}
                                open={Boolean(this.state.userPopupMenuAnchorElement)}
                                onClose={this.handleUserPopupMenuClosed.bind(this)}
                            >
                                {/** Boards menu item */}
                                <MenuItem
                                    hidden={
                                        !this.props.sectionsToDisplayOnSideBarSet &&
                                        this.state.optionalClientsList.length > 1
                                    }
                                    onClick={this.handleBoardMenuItemClicked.bind(this)}
                                    className={classNames(
                                        classes.menuItemLogoutTopBottomPadding,
                                        classes.menuItemLogoutLeftRightPadding,
                                        classes.menuItemLogoutBoxSizing
                                    )}
                                >
                                    <ListItemIcon>
                                        <WindowIcon />
                                    </ListItemIcon>
                                    <div
                                        className={classNames(
                                            classes.flexRow,
                                            classes.fullWidth,
                                            classes.flexSpaceBetween
                                        )}
                                    >
                                        {/* Title */}
                                        <Typography className={classes.userOptionMenuItemText}>Boards</Typography>
                                        {/* Arrow icon */}
                                        <ArrowRightIcon fontSize="small" />
                                    </div>
                                </MenuItem>

                                {/** Logout menu item */}
                                <MenuItem
                                    onClick={this.handleLogoutClicked.bind(this)}
                                    className={classNames(
                                        classes.menuItemLogoutTopBottomPadding,
                                        classes.menuItemLogoutLeftRightPadding,
                                        classes.menuItemLogoutBoxSizing
                                    )}
                                >
                                    <ListItemIcon>
                                        <LogoutIcon />
                                    </ListItemIcon>
                                    <Typography className={classes.userOptionMenuItemText}>Logout</Typography>
                                </MenuItem>
                            </Menu>
                        )}
                        {!this.props.sectionsToDisplayOnSideBarSet && this.state.optionalClientsList.length === 1 && (
                            <Menu
                                PaperProps={{
                                    className: classes.userOptionsMenu,
                                }}
                                id={"user-popup-menu"}
                                anchorEl={this.state.userPopupMenuAnchorElement}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: 0,
                                    horizontal: "right",
                                }}
                                open={Boolean(this.state.userPopupMenuAnchorElement)}
                                onClose={this.handleUserPopupMenuClosed.bind(this)}
                            >
                                {/** Logout menu item */}
                                <MenuItem
                                    onClick={this.handleLogoutClicked.bind(this)}
                                    className={classNames(
                                        classes.menuItemLogoutTopBottomPadding,
                                        classes.menuItemLogoutLeftRightPadding,
                                        classes.menuItemLogoutBoxSizing
                                    )}
                                >
                                    <ListItemIcon>
                                        <LogoutIcon />
                                    </ListItemIcon>
                                    <Typography className={classes.userOptionMenuItemText}>Logout</Typography>
                                </MenuItem>
                            </Menu>
                        )}

                        {/** Boards menu selection */}
                        <Menu
                            PaperProps={{
                                className: classes.userOptionsMenu,
                            }}
                            id={"board-popup-menu"}
                            anchorEl={this.state.boardPopupMenuAnchorElement}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: -50,
                                horizontal: "right",
                            }}
                            open={Boolean(this.state.boardPopupMenuAnchorElement)}
                            onClose={this.handleBoardPopupMenuClosed.bind(this)}
                        >
                            {/** All boards selection */}
                            <MenuItem
                                onClick={() => {
                                    this.boardsLinkClick();
                                }}
                                className={classNames(
                                    classes.menuItemLogoutTopBottomPadding,
                                    classes.menuItemLogoutLeftRightPadding,
                                    classes.menuItemLogoutBoxSizing
                                )}
                            >
                                <ExternalLinkIcon color="#E1034C" />
                                <Typography
                                    className={classNames(
                                        classes.userOptionMenuItemText,
                                        classes.userOptionMenuItemAllBoards
                                    )}
                                >
                                    All Boards
                                </Typography>
                            </MenuItem>
                            {/** All boards selection */}
                            {this.state.optionalClientsList
                                .filter((client) => client.name !== this.state.currentActiveClient.name)
                                .map((client) => (
                                    <MenuItem
                                        key={client.clientId}
                                        className={classNames(
                                            classes.flexColumn,
                                            classes.flexCenter,
                                            classes.menuItemLogoutTopBottomPadding,
                                            classes.menuItemLogoutLeftRightPadding,
                                            classes.menuItemLogoutBoxSizing
                                        )}
                                        onClick={() => {
                                            this.boardSelectChange(client);
                                        }}
                                    >
                                        <Typography className={classes.userOptionMenuItemText}>
                                            {client.name}
                                        </Typography>
                                    </MenuItem>
                                ))}
                        </Menu>
                    </List>
                </Drawer>
            </>
        );
    }

    /**
     * Occurs once user click on log out button.
     */
    private async handleLogoutClicked() {
        // First, we clear all subscriptions to polling service, as we don't want to get thrown to the not authorized page.
        ServiceWire.getPollingService().clearAllSubscriptions();
        // Stop polling
        ServiceWire.getPollingService().stopPolling();

        // Clear selected client
        ServiceWire.getClientsService().clearSelectedClient();

        // clear authenticated user
        ServiceWire.getAuthenticationService().clearAuthenticatedUser();

        // Then, we log out.
        await ServiceWire.getAuthenticationService().logout();

        // Clear data from local storage
        ServiceWire.getAuthenticationService().clearDataFromStorage();

        this.setState({
            redirectToLogin: true,
        });
    }

    /**
     * Handles the drawer being toggled from close to open and vice versa.
     */
    private handleDrawerToggled() {
        this.setState(
            {
                sideBarOpen: !this.state.sideBarOpen,
            },
            () => {
                if (this.props.onSideBarToggled) {
                    this.props.onSideBarToggled(this.state.sideBarOpen);
                }
            }
        );
    }

    /**
     * Handles user menu opened.
     */
    private handleUserMenuItemClicked(event) {
        this.setState({
            userPopupMenuAnchorElement: event.currentTarget,
        });
    }

    private handleInteractiveCoverageItemClicked(event) {
        this.props.openSecurityCoverageModal("securityCoverage");
    }

    /**
     * Handles board menu opened.
     */
    private handleBoardMenuItemClicked(event) {
        this.setState({
            boardPopupMenuAnchorElement: event.currentTarget,
        });
    }

    /**
     * Handles user menu closed.
     */
    private handleUserPopupMenuClosed() {
        this.setState({
            userPopupMenuAnchorElement: null,
        });
    }

    /**
     * Handles board menu closed.
     */
    private handleBoardPopupMenuClosed() {
        this.setState({
            boardPopupMenuAnchorElement: null,
        });
    }

    /**
     * Handle board changed
     */
    private async boardSelectChange(client) {
        // Save new client in local storage
        await ServiceWire.getClientsService().selectClient(client);

        // Change state to trigger redirect operation
        this.setState({ redirectToDashboard: true });
    }

    /**
     * Handle board changed
     */
    private boardsLinkClick() {
        // Change state to trigger redirect operation
        this.setState({ redirectToBoards: true });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(sideBarStyle)(SideBar)));
