import { makeStyles } from "@material-ui/core";

export const useLegendDetailsStyles = makeStyles((theme) => {
    const grayHard = "#676e75";
    const whiteHard = "#d5dae0";
    const black = "#2b2523";

    return {
        container: {
            display: "flex",
            alignItems: "stretch",
            gap: 16,
        },

        legendItem: {
            display: "flex",
            alignItems: "center",
        },

        content: {
            color: grayHard,
            fontSize: 12,
        },

        circle: {
            borderRadius: 100,
            width: 8,
            height: 8,
            marginRight: 4,
        },

        count: {
            color: black,
            fontSize: 12,
            fontWeight: 600,
            marginLeft: 8,
        },

        separator: {
            width: 1,
            background: whiteHard,
        },
    };
});
