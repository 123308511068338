import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => {
    return {
        root: {},

        coverageGraphsContainer: {
            display: "flex",
            flexDirection: "column",
            rowGap: "20px",
            marginTop: "30px",
        },

        coverageSection: {
            display: "grid",
            gridTemplateColumns: "110px 1fr 60px",
            alignItems: "center",
            columnGap: "20px",
        },

        categoryTitle: {
            color: "#43484C",
        },

        coverageGraph: {
            display: "flex",
            flex: 1,
            height: "60px",
            overflow: "hidden",
            borderRadius: "6px",
        },

        coverageGraphPart: {
            height: "100%",
        },

        infoTooltip: {
            background: "#1B1B1B",
            fontSize: "12px",
        },

        tooltipContent: {
            display: "flex",
            flexDirection: "column",
            padding: "6px 8px",
        },

        tooltipCve: {
            color: "#ACB4BD",
        },

        totalProtectedContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            padding: "10px 20px",
            marginTop: "40px",
            background: "#F7F9FA",
            borderRadius: "14px",
        },

        totalProtectedTitle: {
            maxWidth: "80%",
        },

        totalProtectedNumber: {
            fontSize: "20px",
            fontWeight: 700,
            color: "#E7004C",
        },

        coveragePercent: {
            fontSize: "10px",
            fontWeight: 600,
        },
    };
});
