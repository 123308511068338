import { Action } from "redux";
import ActionType from "../ActionType";

/**
 * Occurs once fetching a trace has started.
 */
export interface FetchTraceAction extends Action<ActionType> {
    type: ActionType.FetchTrace;
    traceId: string;
    issueId?: string;
}

/**
 * Occurs once fetching a trace has started.
 */
export function fetchTraceAction(traceId: string, issueId?: string): FetchTraceAction {
    return {
        type: ActionType.FetchTrace,
        traceId: traceId,
        issueId,
    };
}
