import { Status } from "./GlobalTypes";

export interface IssueInfoReason {
    type: IssueInfoReasonType;
    text: string;
}

export enum IssueInfoReasonType {
    SecurityIssues = "security_issues",
    LoginFailure = "login_failure",
    DdosAttack = "ddos_attack",
    Other = "other",
}

export interface IssuesResolveItem {
    type: IssueInfoReasonType;
    text: string;
}

export interface IssueInfoPutReason {
    type: IssueInfoReasonType;
    text?: string;
}

export enum IssueInfoStatus {
    Resolved = "RESOLVED",
    Dismissed = "DISMISSED",
    Open = "OPEN",
    Archived = "ARCHIVED",
}

export interface IssueInfoState {
    issueStatus: IssueInfoStateStatus;
}

export enum IssueInfoStateStatus {
    StableState = "StableState",
    Resolving = "Resolving",
    Dismissing = "Dismissing",
    ResolveError = "ResolveError",
    DismissError = "DismissError",
}

export interface IssueResolveReasonsStoreData {
    data: IssuesResolveItem[];
    status: Status;
}
