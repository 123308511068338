/**
 * Represents the different data types for fields.
 */
enum SternumTriggerType {
    EVENT_COUNT_OVER_TIME,
    SPECIFIC_BEHAVIOR,
    WHITE_LIST,
    NEVER_SEEN_ARGUMENT,
    COMPOSITE_TRIGGER,
}

export default SternumTriggerType;
