import { Action } from "redux";
import ActionType from "../ActionType";

export interface SetAlertTableExpandedAction extends Action<ActionType> {
    type: ActionType.DashboardRegulatSetAlertTableExpanded;
    expanded: boolean;
}

export function setAlertTableExpandedAction(expanded: boolean): SetAlertTableExpandedAction {
    return {
        type: ActionType.DashboardRegulatSetAlertTableExpanded,
        expanded,
    };
}
