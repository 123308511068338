import { makeStyles } from "@material-ui/core";

export const useResearchNumberWithContentStyle = makeStyles((theme) => ({
    researchNumberContainer: {
        display: "flex",
        alignItems: "center",
        gap: 4,
        fontSize: 12,
        fontWeight: 600,
    },

    iconContainer: {
        position: "relative",
        display: "flex",
        width: 34,
        height: 34,
        background: "white",
        borderRadius: "100px",
    },

    icon: {
        margin: "auto",
    },

    researchNumber: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        color: "white",
        fontSize: 8,
    },
}));
