import { makeStyles, Theme } from "@material-ui/core";

export const useUsersContainerStyle = makeStyles((theme: Theme) => ({
    usersContainer: {
        marginTop: 40,
    },

    root: {
        height: "100%",
    },

    subtitle: {
        maxWidth: "80%",
        marginTop: theme.spacing(1),
        fontWeight: 400,
        fontSize: 14,
        color: "#595C62",
    },

    inviteButtonContainer: {
        display: "flex",
        flexFlow: "column",
        gap: 8,
        alignItems: "flex-end",
    },

    invitesLeftContainer: {
        fontSize: 13,
        color: "#595C62",
    },

    invitesLeftCount: {
        color: theme.palette.secondary.dark,
    },

    createButton: {
        cursor: "pointer",
        textDecoration: "none",
        color: theme.palette.common.black,

        "&:hover": {
            textDecoration: "underline",
        },
    },

    tableInner: {
        height: "100%",
        padding: theme.spacing(3),
        background: "#fff",
        boxShadow: "0px 0px 23px rgba(231, 232, 235, 0.25)",
        borderRadius: 10,
    },
}));
