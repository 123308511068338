import { withStyles } from "@material-ui/styles";

// after migrating from Material UI V3 to V4 many styles changed, so we need to override them to keep them look as they used to
const GlobalCss = withStyles({
    "@global": {
        body: {
            margin: 0,
        },
        ".MuiChip-root": {
            height: "30px",
        },
        ".MuiTypography-body2": {
            lineHeight: "1.5",
        },
        ".MuiListItemIcon-root": {
            minWidth: "0px",
            marginRight: 16,
        },
        ".MuiTableCell-body": {
            fontSize: "1rem",
            color: "#999999",

            "&.MuiTableCell-sizeSmall": {
                fontSize: "14px",
            },
        },
        ".MuiTableCell-head": {
            color: "#2B2523",
            fontSize: "1rem",
            fontWeight: 600,

            "&.MuiTableCell-sizeSmall": {
                fontSize: "14px",
                fontWeight: 500,
            },
        },
        ".MuiTableCell-root": {
            padding: "12px 56px 12px 24px",

            "&.MuiTableCell-sizeSmall": {
                padding: "6px 24px 6px 16px",
            },
        },
        ".MuiTableCell-stickyHeader": {
            background: "#fff",
        },
        ".MuiTable-root": {
            fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
        },
        ".MuiListItem-root": {
            paddingTop: "11px",
            paddingBottom: "11px",
        },
        ".MuiDialogTitle-root": {
            padding: 18,
            paddingBottom: 10,
        },
        ".MuiDialogActions-root": {
            justifyContent: "center",
            padding: 18,
        },
        "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px #ECEFF3 inset",
        },
        "::-webkit-scrollbar": {
            "-webkit-appearance": "none",
            "-moz-appearance": "none",
            position: "relative",
            left: 40,
        },

        "::-webkit-scrollbar:vertical": {
            width: 6,
        },

        "::-webkit-scrollbar:horizontal": {
            height: 6,
        },

        "::-webkit-scrollbar-thumb": {
            backgroundColor: "#ced0d6",
            borderRadius: 6,
        },

        "::-webkit-scrollbar-track": {
            borderRadius: 6,
        },
    },
})(() => null);

export default GlobalCss;
