import React from "react";
import classNames from "classnames";

import { useDropdownStyle } from "./Dropdown.style";
import { useDropdownContext } from "./Dropdown.context";

export interface DropdownItemProps extends React.ComponentProps<"div"> {
    id: any;
}

export function DropdownItem({ className, id, onClick, children, ...props }: DropdownItemProps) {
    const classes = useDropdownStyle();
    const { onChangeSelectedCase } = useDropdownContext();

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        onChangeSelectedCase(id);

        return onClick?.(e);
    };

    return (
        <div className={classNames(classes.dropdownItem, className)} onClick={handleClick} {...props}>
            {children}
        </div>
    );
}
