import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const useTrialSettingsDescriptionStyle = makeStyles((theme: Theme) => ({
    trailSettingsDescriptionContainer: {
        display: "flex",
        flexFlow: "column",
        gap: 20,
    },

    section: {
        padding: 24,
        borderRadius: 12,
        background: "#F7F9FA",
        color: "#1D1D1D",
    },

    sectionTitle: {
        fontWeight: 600,
        fontSize: 16,
        marginBottom: 8,
    },

    sectionDescription: {
        fontWeight: 400,
        fontSize: 14,
        maxWidth: 636,
    },

    sectionDescription2: {
        fontWeight: 500,
        fontSize: 14,
        maxWidth: 636,
        marginTop: 16,
    },

    sectionLink: {
        color: "#1B6FDE",
    },

    exampleField: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        background: "#F4F5F9",
        border: "1px solid #E0E1EC",
        borderRadius: 8,
        padding: 16,
        marginTop: 16,
        width: 519,
    },

    exampleFieldText: {
        color: "#1D1D1F",
    },
}));
