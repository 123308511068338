import React, { useState } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { Box, Typography } from "@material-ui/core";

import ServiceWire from "../../../lib/services/ServiceWire";
import { GlobalState } from "../../../lib/state/GlobalState";
import ServerEntityType from "../../../lib/state/ServerEntityType";
import UserInfo from "../../../lib/state/UserInfo";
import InviteUserDialog from "../../InviteUserDialog/InviteUserDialog";
import MixedUsersList from "../../MixedUsersList/MixedUsersList";
import SternumImprovedButton from "../../SUI/SternumImprovedButton/SternumImprovedButton";
import { useCommonStyle } from "../../CommonStyle";
import { ShareExperienceDialog } from "../../ShareExperienceDialog";
import { ApiKeyGenerationProps } from "../ApiKeyGeneration";
import { useUsersContainerStyle } from "./UsersContainer.style";

export interface UsersContainerProps {}

const useServiceStore = () => {
    const selectedClient = ServiceWire.getClientsService().getSelectedClient();
    const maxPossibleInvitedUsers = selectedClient.limits?.usersLimit || 0;
    const alreadyInvitedUsers = selectedClient.usage?.users || 0;

    return {
        isFreeUser: selectedClient.isTrialTier(),
        invitesLeftUsersCount: Math.max(0, maxPossibleInvitedUsers - alreadyInvitedUsers),
        maxPossibleInvitedUsers,
    };
};

const mapStateToProps = (state: GlobalState, ownProps: any) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

type UsersContainerPropsWithHOC = UsersContainerProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

function UsersContainerComponent({}: UsersContainerPropsWithHOC) {
    const { isFreeUser, maxPossibleInvitedUsers, invitesLeftUsersCount } = useServiceStore();

    const classes = useUsersContainerStyle();
    const classesCommon = useCommonStyle();
    const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
    const [isShareExperienceDialogOpen, setIsShareExperienceDialogOpen] = useState(false);
    const [newUser, setNewUser] = useState<UserInfo | null>(null);
    const authenticatedUser = ServiceWire.getAuthenticationService().getCachedAuthenticatedUser();
    const authorizationUser = ServiceWire.getAuthorizationService();
    const isAdmin =
        authenticatedUser.isSternumAdmin || authenticatedUser.hasAdminRole() || authorizationUser.hasUserRoleAdmin();

    const handleInvitedUser = (newUser: UserInfo) => {
        setIsInviteDialogOpen(false);
        setNewUser(newUser);
    };

    function renderDialogs() {
        return (
            <>
                {isInviteDialogOpen && (
                    <InviteUserDialog
                        open={isInviteDialogOpen}
                        handleCancel={() => setIsInviteDialogOpen(false)}
                        handleInvite={handleInvitedUser}
                    />
                )}

                {isShareExperienceDialogOpen && (
                    <ShareExperienceDialog
                        open={isShareExperienceDialogOpen}
                        onCancelClick={() => setIsShareExperienceDialogOpen(false)}
                    />
                )}
            </>
        );
    }

    function renderFreeUserView() {
        return (
            <div>
                {isAdmin && (
                    <div className={classNames(classesCommon.flexSpaceBetween, classesCommon.flexVMiddle)}>
                        <Box flex={1}>
                            {/* Title */}
                            <Typography variant={"h6"} className={classNames(classesCommon.marginRight)}>
                                Invite Colleagues, Get More Data
                            </Typography>
                            {/* we display `maxPossibleInvitedUsers - 1` because this value includes the user itself, so we need to exclude it from total number */}
                            <Typography variant={"subtitle2"} className={classes.subtitle}>
                                Know anyone who might be excited to try Sternum Platform? Invite them and get an
                                additional 500 megabytes of data per device! You can invite up to{" "}
                                {maxPossibleInvitedUsers - 1} people.
                            </Typography>
                        </Box>
                        {/* Invite new user button */}
                        {ServiceWire.getAuthorizationService().canAdd(ServerEntityType.USER) && (
                            <div className={classes.inviteButtonContainer}>
                                <div>
                                    <SternumImprovedButton
                                        content="Share the Experience"
                                        isLoading={false}
                                        isDisabled={invitesLeftUsersCount <= 0}
                                        onClick={() => setIsShareExperienceDialogOpen(true)}
                                        buttonType={"blue"}
                                    />
                                </div>
                                <div className={classes.invitesLeftContainer}>
                                    Users left:{" "}
                                    <span className={classes.invitesLeftCount}>{invitesLeftUsersCount}</span>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }

    function renderInviteNewUserWithMixedUsersList() {
        return (
            <>
                <div
                    className={classNames(
                        classesCommon.flexSpaceBetween,
                        classesCommon.flexVMiddle,
                        isFreeUser && classesCommon.paddingTopXl
                    )}
                >
                    {/* Invite new user button */}
                    {!isFreeUser && ServiceWire.getAuthorizationService().canAdd(ServerEntityType.USER) && (
                        <>
                            {/* Title */}
                            <Typography variant={"h6"} className={(classesCommon.marginRight, classesCommon.extraBold)}>
                                Users
                            </Typography>

                            <div>
                                <SternumImprovedButton
                                    content="+ Invite New User"
                                    isLoading={false}
                                    onClick={() => setIsInviteDialogOpen(true)}
                                    buttonType={"blue"}
                                />
                            </div>
                        </>
                    )}
                </div>

                {isAdmin && (
                    <div className={classNames(classes.root, classesCommon.paddingTopXl)}>
                        <div className={classes.tableInner}>
                            <MixedUsersList newUser={newUser} />
                        </div>
                    </div>
                )}
            </>
        );
    }

    return (
        <div className={classes.usersContainer}>
            {isFreeUser && renderFreeUserView()}
            {isAdmin && renderInviteNewUserWithMixedUsersList()}
            {renderDialogs()}
        </div>
    );
}

export const UsersContainer: React.FC<ApiKeyGenerationProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(UsersContainerComponent);
