import React from "react";
import { ChevronRight } from "@material-ui/icons";

import { useTraceStepperStyle } from "./TraceStepper.style";

export interface TraceStepperStepProps {}

export function StepSeparator(props: TraceStepperStepProps) {
    const classes = useTraceStepperStyle();

    return <ChevronRight className={classes.stepSeparator} />;
}
