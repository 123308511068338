import React from "react";

import { useDownloadChipsStyle } from "./DownloadChips.style";
import classNames from "classnames";
import { CheckNoBorderIcon } from "../../../SUI/SternumIcon";

export interface DownloadChipItemProps extends React.ComponentProps<"div"> {
    isDisabled?: boolean;
    iconLogo: React.ReactNode;
    systemTitle: string;
    systemSubtitle?: string;
    version?: string;
    showProgressBar?: boolean;
    progressValue?: number; // from 0 to 100
    isDownloadDone?: boolean;
}

export function DownloadChipItem({
    className,
    isDisabled = false,
    iconLogo,
    systemTitle,
    systemSubtitle,
    version,
    showProgressBar = false,
    progressValue = 0,
    isDownloadDone = false,
    onClick,
    ...props
}: DownloadChipItemProps) {
    const classes = useDownloadChipsStyle();

    return (
        <div
            className={classNames(classes.childItemContainer, isDisabled && classes.childItemContainerDisabled)}
            role="presentation"
            aria-label="download chip item"
        >
            <div className={classes.chipItem} onClick={(e) => !isDisabled && onClick?.(e)} {...props}>
                <div className={classes.logoContainer}>{iconLogo}</div>
                <div className={classes.content}>
                    <div className={classes.contentText}>
                        SDK app{" "}
                        {version && (
                            <span role="status" aria-label="tag with version" className={classes.tag}>
                                {version ? `v. ${version}` : ""}
                            </span>
                        )}
                    </div>
                    <div className={classes.contentTitle}>
                        {systemTitle}
                        {systemSubtitle && <span className={classes.contentSubtitle}>{systemSubtitle}</span>}
                    </div>
                </div>
            </div>
            {showProgressBar && (
                <div className={classNames(classes.progress)}>
                    <div className={classes.progressValue} style={{ transform: `scaleX(${progressValue / 100})` }} />
                </div>
            )}
            {!showProgressBar && isDownloadDone && (
                <div className={classes.downloadIsDone}>
                    <CheckNoBorderIcon />
                    Downloaded
                </div>
            )}
        </div>
    );
}
