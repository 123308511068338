import React from "react";
import { connect } from "react-redux";
import { Box, IconButton, Tooltip, Typography } from "@material-ui/core";
import { useStyles } from "./InstallProtectionStep.style";
import { StepNumber } from "../StepNumber";
import SternumImprovedButton from "../../SUI/SternumImprovedButton/SternumImprovedButton";
import SternumLink from "../../SUI/SternumLink/SternumLink";
import classNames from "classnames";
import { useCommonStyle } from "../../CommonStyle";
import WebUtils from "../../../lib/infra/WebUtils";
import { GlobalState } from "../../../lib/state/GlobalState";
import { NotificationMessage } from "../../../lib/state/NotificationsState";
import { showNotificationAction } from "../../../lib/redux/notifications/ShowNotificationAction";
import { CopyFilesIcon } from "../../SUI/SternumIcon";
import { SignupUserGuideStep } from "../../../lib/state/ClientInfo.types";
import { OpenWRTInstallerCommand } from "../../OpenWRTInstallerCommand";

interface InstallProtectionStepProps {
    active: boolean;
    onNextClick: (step: SignupUserGuideStep) => unknown;
    onBackClick: (step: SignupUserGuideStep) => unknown;
    stepNumber: number;
    completed: boolean;
    className?: string;
}

const mapStateToProps = (state: GlobalState, ownProps: any) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        showNotification: (message: NotificationMessage) => dispatch(showNotificationAction(message)),
    };
};

function InstallProtectionStepComponent({
    active,
    onNextClick,
    onBackClick,
    stepNumber,
    completed,
    className,
    showNotification,
}: InstallProtectionStepProps & ReturnType<typeof mapDispatchToProps>) {
    const classes = useStyles();
    const classesCommon = useCommonStyle();

    const handleNextClick = () => {
        onNextClick(SignupUserGuideStep.InstallProtection);
    };

    const handleBackClick = () => {
        onBackClick(SignupUserGuideStep.InstallProtection);
    };

    return (
        <div className={classNames(classes.container, className)}>
            <div className={classes.inner}>
                <div>
                    <StepNumber orderNumber={stepNumber} completed={completed} />
                </div>

                <Box ml={3}>
                    <Typography className={classes.title}>Install Sternum Runtime Protection</Typography>

                    {active && (
                        <>
                            <Typography className={classes.description}>
                                Sternum provides real-time protection, detection and observability for all RTOS and
                                Linux-operated IIoT, IoMT and IoT devices.
                            </Typography>

                            <Box mt={3}>
                                <OpenWRTInstallerCommand />
                            </Box>

                            <Box display="flex" mt={3}>
                                <SternumImprovedButton
                                    buttonType="regular"
                                    content="Next"
                                    fullWidth={false}
                                    onClick={handleNextClick}
                                />
                                <Box ml={2}>
                                    <SternumImprovedButton
                                        buttonType="text:dark"
                                        content="Back"
                                        fullWidth={false}
                                        onClick={handleBackClick}
                                    />
                                </Box>
                            </Box>
                        </>
                    )}
                </Box>
            </div>
        </div>
    );
}

export const InstallProtectionStep: React.FC<InstallProtectionStepProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(InstallProtectionStepComponent);
