import { makeStyles } from "@material-ui/core";

export const useDeviceMetricsTileStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexFlow: "column",
        justifyContent: "space-between",
        position: "relative",
        background: "#FFFFFF",
        color: "#000",
        boxShadow: "0 0 23px rgba(231, 232, 235, 0.6)",
        borderRadius: 14,
        minHeight: 100,
        padding: 16,
        fontWeight: 400,
    },
    containerWithHover: {
        cursor: "pointer",

        "&:hover": {
            color: "#FFF",
            background: "#D8034A",

            "& $percentageValueContainer": {
                color: "#FFF",
                background: "#D8034A",
            },
        },
    },
    icon: {
        position: "absolute",
        top: 16,
        right: 16,
        color: "#C7C7C7",
    },
    title: {
        fontWeight: 500,
        fontSize: 14,
        lineHeight: "120%",
        marginBottom: 16,
    },
    titleWithIcon: {
        marginRight: 20,
    },
    flex: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        gap: 12,
    },
    value: {
        fontSize: 24,
    },
    percentageValueContainer: {
        fontSize: 16,
    },
    percentageValueContainerRed: {
        color: "#E7004C",
    },
    percentageValueContainerGreen: {
        color: "#15AC5A",
    },
}));
