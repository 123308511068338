/**
 * Represents configuration details for an argument role type.
 */

import AggregateOverProperty from "../state/AggregateOverProperty";

class AggregateOverTimeConvertor {
    /**
     * Returns the aggregate over property name.
     */
    public static getAggregateOverPropertyName(aggregateOverProperty: AggregateOverProperty): string {
        switch (aggregateOverProperty) {
            case AggregateOverProperty.TRACE_CATEGORY_NAME:
                return "trace_category";
        }
    }
}

export default AggregateOverTimeConvertor;
