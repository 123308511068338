import SternumColorType from "./SternumColorType";

export function getColorOrder(): SternumColorType[] {
    return [
        SternumColorType.COLOR_A,
        SternumColorType.COLOR_H,
        SternumColorType.COLOR_I,
        SternumColorType.COLOR_J,
        SternumColorType.COLOR_K,
        SternumColorType.COLOR_L,
        SternumColorType.COLOR_M,
        SternumColorType.COLOR_N,
        SternumColorType.COLOR_O,
        SternumColorType.COLOR_P,
        SternumColorType.COLOR_B,
        SternumColorType.COLOR_C,
        SternumColorType.COLOR_D,
        SternumColorType.COLOR_E,
        SternumColorType.COLOR_F,
        SternumColorType.COLOR_G,
    ];
}
