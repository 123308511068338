import ActionType from "../ActionType";
import { TracesReducerActions } from "./TracesReducerActions";
import { EntitiesState, getDefaultEntitiesState } from "../../state/EntitiesState";
import HashSet from "../../infra/HashSet";
import HashMap from "../../infra/HashMap";
import TraceInfo from "../../state/TraceInfo";

/**
 * Reducer for all trace-related actions.
 */
const tracesReducer = (
    state: EntitiesState<TraceInfo> = getDefaultEntitiesState<TraceInfo>(),
    action: TracesReducerActions
) => {
    switch (action.type) {
        case ActionType.FetchTrace: {
            return {
                idToEntityMap: state.idToEntityMap,
                loadingIds: HashSet.copyAndAdd(state.loadingIds, action.traceId),
                errorIds: state.errorIds,
                detectedDeviceCount: null,
            };
        }

        case ActionType.FetchedTraceSuccessfully: {
            return {
                idToEntityMap: HashMap.copyAndPut(state.idToEntityMap, action.trace.traceId, action.trace),
                loadingIds: state.loadingIds.exists(action.trace.traceId)
                    ? HashSet.copyAndRemove(state.loadingIds, action.trace.traceId)
                    : state.loadingIds,
                errorIds: state.errorIds.containsKey(action.trace.traceId)
                    ? HashMap.copyAndRemove(state.errorIds, action.trace.traceId)
                    : state.errorIds,
                detectedDeviceCount: null,
            };
        }

        case ActionType.ErrorFetchingTrace: {
            return {
                idToEntityMap: state.idToEntityMap,
                loadingIds: HashSet.copyAndRemove(state.loadingIds, action.traceId),
                errorIds: HashMap.copyAndPut(state.errorIds, action.traceId, action.error),
                detectedDeviceCount: null,
            };
        }

        default:
            return state;
    }
};

export default tracesReducer;
