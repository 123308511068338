import React from "react";
import classNames from "classnames";

import { useSternumEmptyModalStyles } from "./SternumEmptyModal.styles";
import { Title } from "./Title.component";
import { Description } from "./Description.component";

export interface SternumEmptyModalProps extends React.ComponentProps<"div"> {}

export function SternumEmptyModal({ className, children, ...props }: SternumEmptyModalProps) {
    const classes = useSternumEmptyModalStyles();

    return (
        <div className={classNames(classes.emptyContainer, className)} {...props}>
            <div className={classes.emptyContent}>{children}</div>
        </div>
    );
}

SternumEmptyModal.Title = Title;
SternumEmptyModal.Description = Description;
