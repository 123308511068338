/**
 * Indicates over which property we'd like to aggregate.
 */
enum AggregateOverProperty {
    /**
     * Aggregate over the category of the event type.
     */
    TRACE_CATEGORY_NAME = "TRACE_CATEGORY_NAME",
}

export default AggregateOverProperty;
