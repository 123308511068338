import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { sternumTheme } from "../App";
import commonStyle from "../CommonStyle";

const issuesListActionsStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        investigateIcon: {
            color: theme.palette.primary.main,
            marginRight: theme.spacing(1),
        },
        investigateText: {},
        traceLinkText: {
            fontSize: 14,
            fontWeight: 400,
            marginRight: theme.spacing(1),
            "&:hover": {
                textDecoration: "underline",
            },
        },
        actionContainer: {
            "&:hover": {
                color: "#15AC5A",
            },
        },
    });

export default issuesListActionsStyle;
