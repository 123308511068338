/**
 * Represents an error in fetching a resource.
 */
class FetchError {
    /**
     * Constructor.
     */
    constructor(public errorMessage?: string) {}
}

export default FetchError;
