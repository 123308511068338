import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
    content: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    },

    authFieldsContainer: {
        marginTop: "20px",
    },

    innerContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        width: "1170px",
        maxWidth: "90%",
        columnGap: "150px",
        padding: "0 40px",
        marginBottom: "50px",
    },

    mainTitle: {
        fontWeight: 700,
        fontSize: "34px",
        color: "#fff",
        lineHeight: "51px",
    },

    leftContent: {},

    rightContent: {
        minWidth: "480px",
        width: "480px",
        height: "fit-content",
        boxSizing: "border-box",
        padding: "40px",
        background: "#fff",
        boxShadow: "-12px 14px 34px rgba(83, 93, 120, 0.15)",
        borderRadius: "12px",
    },

    logo: {
        "& .text": {
            fill: "#253858",
        },
    },

    formHeader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        columnGap: "10px",
    },

    trialDescription: {
        fontSize: "14px",
        color: "#D5DAE0",
        lineHeight: "22px",
        marginTop: "32px",
        maxWidth: 500,
    },

    featuresContainer: {
        display: "flex",
        flexDirection: "column",
        rowGap: "16px",
        marginTop: "32px",
    },

    featureItem: {
        display: "flex",
        alignItems: "center",
    },

    featureText: {
        marginLeft: "16px",
        fontSize: "14px",
        color: "#D5DAE0",
    },

    successContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },

    successTitle: {
        marginTop: 24,
        fontSize: "24px",
        fontWeight: 500,
        color: "#212B36",
        textAlign: "center",
    },

    successBackLink: {
        marginTop: 20,
        color: theme.palette.secondary.dark,
        fontSize: 16,
        fontWeight: 600,
    },
}));
