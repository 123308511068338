import { makeStyles } from "@material-ui/core";
import commonStyle from "../../../components/CommonStyle";

const usePasswordStrengthStyles = makeStyles((theme) => {
    return {
        ...commonStyle(theme),
    };
});

export default usePasswordStrengthStyles;
