import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { sternumTheme } from "../App";
import commonStyle, { SIDEBAR_EXPANDED_WIDTH, SIDEBAR_NORMAL_WIDTH } from "../CommonStyle";

const appContentStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        wholeAppContainer: {
            display: "flex",
            minHeight: "100vh",
        },
        // Main content container.
        content: {
            backgroundColor: "#FBFCFF",

            // Letting the content fit the whole screen.
            flexGrow: 1,
            overflowY: "hidden",
            paddingLeft: SIDEBAR_NORMAL_WIDTH,
            transition: theme.transitions.create("padding-left", {
                easing: theme.transitions.easing.easeInOut,
                duration: theme.transitions.duration.enteringScreen,
            }),

            "&.sidebar-open": {
                paddingLeft: SIDEBAR_EXPANDED_WIDTH,
                width: `calc(100% - ${SIDEBAR_EXPANDED_WIDTH}px)`,
            },
        },
        attackedDevicesSnackbar: {
            backgroundColor: sternumTheme.danger.main,
            flexWrap: "nowrap",
        },
        attackedDevicesNoProductionSnackbar: {
            backgroundColor: sternumTheme.warning.main,
            flexWrap: "nowrap",
        },
        snackbarCloseIcon: {
            fontSize: 20,
        },
        linkToDeviceIcon: {
            fontSize: 20,
            marginRight: theme.spacing(2),
            paddingRight: theme.spacing(5),
            color: theme.palette.common.white,
        },
        attackedDeviceLink: {
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            textDecoration: "none",
        },
        attackedDeviceLinkMultiAlerts: {
            cursor: "pointer",
            textDecoration: "none",
        },
        attackedDeviceText: {
            color: theme.palette.common.white,
        },
        viewAlertsLink: {
            "&:hover": {
                textDecoration: "underline",
            },
        },
    });

export default appContentStyle;
