import React, { createContext, useContext, useEffect, useState } from "react";
import ReceivedDefinitionsResponse from "../../lib/services/events/ReceivedDefinitionsResponse";
import ReceivedDefinitionSummary from "../../lib/services/events/ReceivedDefinitionSummary";
import ServiceWire from "../../lib/services/ServiceWire";
import EventInterestDetailed from "../../lib/state/EventInterestDetailed";
import SternumAgentConfiguration from "../../lib/state/SternumAgentConfiguration";
import TraceCategory from "../../lib/state/TraceCategory";
import UIDataVisualisationConfiguration from "../VisualisationConfigurationComponent/entities/UIDataVisualisationConfiguration";

export interface CategoriesMap {
    [numericIdentifier: number]: TraceCategory;
}

export interface EventInterestMap {
    [id: number]: EventInterestDetailed;
}

export interface EventTypeMap {
    [identifier: string]: ReceivedDefinitionSummary;
}

export const CategoriesContext = createContext<CategoriesMap>({});
export const InterestContext = createContext<EventInterestMap>({});
export const EventTypeContext = createContext<EventTypeMap>({});

interface UniqueCountTableDataProviderProps {
    dataVisualisationConfiguration?: UIDataVisualisationConfiguration;
    children?: React.ReactNode;
}

export interface UniqueCountTableDataResponse {
    categories: CategoriesMap;
    interests: EventInterestMap;
    eventTypes: EventTypeMap;
}

export async function getUniqueCountTableData(
    dataVisualisationConfiguration: UIDataVisualisationConfiguration
): Promise<UniqueCountTableDataResponse> {
    const resolved = await Promise.all<unknown>([
        ServiceWire.getSternumService().getSternumConfigurations(),
        ...dataVisualisationConfiguration.deviceDefinitionVersionIds.map((deviceDefinitionVersionId) =>
            ServiceWire.getEventsApiService().getAllReceivedDefinitions(
                deviceDefinitionVersionId,
                dataVisualisationConfiguration.entitiesFilter.createdFrom,
                dataVisualisationConfiguration.entitiesFilter.createdTo
            )
        ),
    ]);

    const mappedCategories: CategoriesMap = {};
    (resolved[0] as SternumAgentConfiguration).traceCategories.forEach((cat) => {
        mappedCategories[cat.numericIdentifier] = cat;
    });

    const mappedInterests: EventInterestMap = {};
    (resolved[0] as SternumAgentConfiguration).eventInterests.forEach((ei) => {
        mappedInterests[ei.id] = ei;
    });

    const mappedEventTypes: EventTypeMap = {};
    for (let i = 1; i < resolved.length; ++i) {
        (resolved[i] as ReceivedDefinitionsResponse).receivedDefinitions.forEach((def) => {
            mappedEventTypes[def.identifier] = def;
        });
    }

    return {
        categories: mappedCategories,
        interests: mappedInterests,
        eventTypes: mappedEventTypes,
    };
}

export function UniqueCountTableDataProvider({
    children,
    dataVisualisationConfiguration = undefined,
}: UniqueCountTableDataProviderProps) {
    const [categories, setCategories] = useState<CategoriesMap>({});
    const [interests, setInterests] = useState<EventInterestMap>({});
    const [eventTypes, setEventTypes] = useState<EventTypeMap>({});

    useEffect(() => {
        (async () => {
            const result = await ServiceWire.getSternumService().getSternumConfigurations();

            const mappedCategories: CategoriesMap = {};
            result.traceCategories.forEach((cat) => {
                mappedCategories[cat.numericIdentifier] = cat;
            });

            const mappedInterests: EventInterestMap = {};
            result.eventInterests.forEach((ei) => {
                mappedInterests[ei.id] = ei;
            });

            setCategories(mappedCategories);
            setInterests(mappedInterests);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if (
                dataVisualisationConfiguration &&
                dataVisualisationConfiguration.deviceDefinitionVersionIds &&
                dataVisualisationConfiguration.deviceDefinitionVersionIds.length > 0
            ) {
                const results = await Promise.all([
                    ...dataVisualisationConfiguration.deviceDefinitionVersionIds.map((deviceDefinitionVersionId) =>
                        ServiceWire.getEventsApiService().getAllReceivedDefinitions(
                            deviceDefinitionVersionId,
                            dataVisualisationConfiguration.entitiesFilter.createdFrom,
                            dataVisualisationConfiguration.entitiesFilter.createdTo
                        )
                    ),
                ]);

                const mappedEventTypes: EventTypeMap = {};

                results.forEach((result) => {
                    result.receivedDefinitions.forEach((def) => {
                        mappedEventTypes[def.identifier] = def;
                    });
                });

                setEventTypes(mappedEventTypes);
            }
        })();
    }, [
        dataVisualisationConfiguration === undefined,
        dataVisualisationConfiguration !== undefined &&
            dataVisualisationConfiguration.deviceDefinitionVersionIds &&
            dataVisualisationConfiguration.deviceDefinitionVersionIds.join(""),
        dataVisualisationConfiguration !== undefined && dataVisualisationConfiguration.entitiesFilter.createdFrom,
        dataVisualisationConfiguration !== undefined && dataVisualisationConfiguration.entitiesFilter.createdTo,
    ]);

    return (
        <CategoriesContext.Provider value={categories}>
            <InterestContext.Provider value={interests}>
                <EventTypeContext.Provider value={eventTypes}>{children}</EventTypeContext.Provider>
            </InterestContext.Provider>
        </CategoriesContext.Provider>
    );
}

interface UniqueCountTableDataCategoryProps {
    value: string;
}

export function UniqueCountTableDataCategory({ value }: UniqueCountTableDataCategoryProps) {
    const categories = useContext(CategoriesContext);

    return <>{categories[value] ? categories[value].displayName : `Missing label: ${value}`}</>;
}

interface UniqueCountTableDataInterestProps {
    value: string;
}

export function UniqueCountTableDataInterest({ value }: UniqueCountTableDataInterestProps) {
    const interests = useContext(InterestContext);

    return <>{interests[value] ? interests[value].displayName : `Missing label: ${value}`}</>;
}

interface UniqueCountTableDataEventTypeProps {
    value: string;
}

export function UniqueCountTableDataEventType({ value }: UniqueCountTableDataEventTypeProps) {
    const eventTypes = useContext(EventTypeContext);

    return <>{eventTypes[value] ? eventTypes[value].displayName : `Missing label: ${value}`}</>;
}
