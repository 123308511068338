import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

const traceViewStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: {
            display: "flex",
            flexDirection: "column",
            minHeight: "100%",
        },

        rootIsResolving: {},

        upperContentContainer: {
            padding: 18,
            paddingBottom: theme.spacing(5),
        },

        geoLocationContentContainer: {
            paddingRight: 32,
            paddingLeft: 32,
            paddingBottom: theme.spacing(3),
        },

        tabContent: {
            flex: "1 1 1px",
            overflow: "auto",
            background: "#fff",
            margin: "0 18px",
            display: "flex",
            flexDirection: "column",
        },

        timelineContainer: {
            marginTop: theme.spacing(27),
        },

        tabsContainer: {
            padding: "0 18px",
        },

        tableContainer: {
            height: "100%",
            padding: theme.spacing(4),
            width: "auto",
            overflow: "auto",
            maxHeight: "100%",
        },

        tableInner: {
            height: "100%",
            background: "#fff",
        },

        title: {
            fontSize: 20,
            fontWeight: 600,
        },

        titlePushedDown: {
            marginTop: 40,
        },

        parametersContainer: {
            marginTop: 15,
        },

        infoCircleIconSize: {
            fontSize: "0.8125rem",
        },
        moreInfoText: {
            cursor: "pointer",
            marginRight: theme.spacing(1),
            "&:hover": {
                textDecoration: "underline",
            },
        },

        openDeviceInformationIcon: {
            fontSize: "0.8125rem",
            paddingRight: theme.spacing(3),
            paddingLeft: "1px",
        },

        tracesListPaper: {
            "&.mod-regular": {
                height: "100%",
            },
            "&.mod-fullscreen": {
                height: "100%",
            },
        },

        investigateIcon: {
            color: theme.palette.primary.main,
            fontSize: theme.typography.h5.fontSize,
            marginRight: theme.spacing(1),
        },

        familyNameText: {
            marginRight: theme.spacing(1),
        },

        traceDetailsDisplayContainer: {
            padding: theme.spacing(5),
            overflow: "auto",
            height: "100%",
        },

        tabCustomStyle: {
            maxWidth: "none",
        },

        exportReportContainer: {},

        generateReportIconSize: {
            fontSize: "14px",
        },

        generateReportText: {
            cursor: "pointer",
            marginRight: theme.spacing(1),
            "&:hover": {
                textDecoration: "underline",
            },
        },

        resolveButton: {
            bottom: theme.spacing(4),
            right: theme.spacing(5),
            position: "absolute",
        },

        resolvedColor: {
            color: "#555B61",
        },

        blueButton: {
            "&&": {
                fontSize: 14,
                fontWeight: 500,
                color: "#1B6FDE",
                background: "#D8E1FF",
                borderRadius: 14,
                padding: "12px 24px",

                "&:hover": {
                    background: "#2064c2",
                    color: "white",
                },

                "&:focus-visible": {
                    outline: "1px solid #2064c2",
                    boxShadow: "0px 4px 30px rgb(58 134 253 / 40%)",
                },

                "&:disabled": {
                    opacity: 0.3,

                    "&:hover": {
                        background: "#1B6FDE",
                    },
                },
            },
        },

        viewInContextButton: {
            backgroundColor: "#1B6FDE",
            color: "#fff",
            borderRadius: 14,
            fontWeight: 500,
            fontSize: 16,
            textTransform: "none",
            boxShadow: "none",
            lineHeight: "20px",
            marginRight: "80px",
            height: 45,

            "&:hover": {
                backgroundColor: "#1656AB",
                boxShadow: "none",
            },
        },

        anomalyNavigationContainer: {
            display: "flex",
            justifyContent: "space-between",
            padding: 18,
            marginTop: 12,
            background: "white",
            gap: 32,
        },

        anomalyStepsNavigationButtonsContainer: {
            display: "flex",
            gap: 16,
            alignItems: "center",

            "& > *": {
                color: "#E7004C",
                textTransform: "unset",
                fontSize: "16px",
            },
        },

        traceViewAlertAnomalyStatusContainer: {
            flex: "1 1 1px",
            overflow: "auto !important",
        },

        anomalyPrevButton: {
            padding: 0,
            minWidth: "unset",
        },

        anomalyNextButton: {
            height: 40,
            border: "1px solid #E7004C",
            borderRadius: "14px",
        },

        redDecoration: {
            color: "#EE3B66",
        },
    });

export default traceViewStyle;
