import { Action } from "redux";
import ActionType from "../ActionType";

/**
 * Defines the data for the hide notification action.
 */
export interface HideNotificationAction extends Action<ActionType> {
    type: ActionType.HideNotification;
}

export function hideNotificationAction(): HideNotificationAction {
    return {
        type: ActionType.HideNotification,
    };
}
