import {
    Button,
    CircularProgress,
    Divider,
    Icon,
    IconButton,
    Tooltip,
    Typography,
    withStyles,
} from "@material-ui/core";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import { WithStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import HashMap from "../../../lib/infra/HashMap";
import Utils from "../../../lib/infra/Utils";
import WebUtils from "../../../lib/infra/WebUtils";
import ServiceWire from "../../../lib/services/ServiceWire";
import DeviceDefinitionVersionInfo from "../../../lib/state/DeviceDefinitionVersionInfo";
import GetDeleteSummaryResponse from "../../../lib/state/GetDeleteSummaryResponse";
import { GlobalState } from "../../../lib/state/GlobalState";
import ServerEntityType from "../../../lib/state/ServerEntityType";
import TraceCategory from "../../../lib/state/TraceCategory";
import TraceDefinitionInfo from "../../../lib/state/TraceDefinitionInfo";
import TraceDefinitionPartial from "../../../lib/state/TraceDefinitionPartial";
import ConfirmationDialog from "../../../shared_components/ConfirmationDialog/ConfirmationDialog";
import ExplanationComponent from "../../ExplanationComponent/ExplanationComponent";
import TraceDefinitionSimpleDialog from "../TraceDefinitionSimpleDialog/TraceDefinitionSimpleDialog";
import traceDefinitionStepStyle from "./TraceDefinitionStepStyle";

interface AppState {
    selectedTrace: any;
    isTraceDialogOpen: boolean;
    deviceDefinition: any;
    expanded: string;
    // indicate if buttons should be in disable mode
    isDisabledMode: boolean;
    traceDefinitionToDelete: any;
    openConfirmationDialog: boolean;
    onOperationMap: HashMap<boolean>;
    isError: boolean;
    deleteCount: GetDeleteSummaryResponse;
}

export interface AppProps extends WithStyles<typeof traceDefinitionStepStyle> {
    deviceDefinition: any;
    categoriesList: TraceCategory[];
    deviceDefinitionVersion?: DeviceDefinitionVersionInfo;
    forceCRUD?: boolean;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {};
};

class TraceDefinitionStep extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);
        // Initializing the state to default.
        this.state = {
            deviceDefinition: this.props.deviceDefinition,
            isTraceDialogOpen: false,
            selectedTrace: null,
            expanded: null,
            isDisabledMode:
                (!!this.props.forceCRUD && this.props.forceCRUD && this.props.deviceDefinitionVersion.isProduction()) ||
                !ServiceWire.getAuthorizationService().canEdit(ServerEntityType.TRACE_DEFINITION)
                    ? true
                    : false,
            traceDefinitionToDelete: null,
            openConfirmationDialog: false,
            onOperationMap: new HashMap<boolean>(),
            isError: false,
            deleteCount: null,
        };
    }

    /*
     * Handle save dialog
     */
    private onSaveDialog = async (
        traceName: string,
        traceEventName: string,
        traceCategory: string,
        traceInterest: string,
        transmitFrequency: string,
        description: string,
        addAnotherTrace: boolean,
        selectedTrace?: any
    ) => {
        try {
            if (this.isForceCRUD()) {
                this.setState({ isError: false });
                // Send request to server
                await this.UpdateOrCreateTrace(
                    traceName,
                    traceEventName,
                    traceCategory,
                    traceInterest,
                    transmitFrequency,
                    description,
                    selectedTrace
                );
            } else {
                // Update in place
                this.UpdateOrCreateInPlace(
                    traceName,
                    traceEventName,
                    traceCategory,
                    traceInterest,
                    transmitFrequency,
                    description,
                    selectedTrace
                );
            }

            this.setState({
                isTraceDialogOpen: addAnotherTrace ? true : false,
                deviceDefinition: this.state.deviceDefinition,
                selectedTrace: null,
                expanded: "custom",
            });
        } catch (err) {
            this.setState({ isError: true });
        }
    };

    /*
     * Duplication validation
     * If it's new trace, Don't allow to create trace with the same name.
     * On update check full match
     */
    checkIfTraceExists = (
        traceName: string,
        traceEventName: string,
        traceCategory: string,
        transmitFrequency: string,
        traceInterest: string
    ) => {
        if (this.state.selectedTrace && this.state.selectedTrace.displayName === traceName) {
            return false;
        }
        const index = this.state.deviceDefinition.findTraceIndex(
            new TraceDefinitionPartial(traceName, traceEventName, traceCategory, traceInterest, transmitFrequency)
        );
        return index > -1 ? true : false;
    };

    /*
     * Check if operation should be taken inplace (it's setup operation) or send request to server.
     */
    isForceCRUD = (): boolean => {
        return this.props.forceCRUD && this.props.forceCRUD === true;
    };

    /*
     * Handle expand panel
     */
    handleExpandChange = (panel) => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : null,
        });
    };

    /*
     * Open edit dialog
     */
    handleEditClick = (selectedTrace) => (event) => {
        this.setState({
            selectedTrace: selectedTrace,
            deviceDefinition: this.state.deviceDefinition,
            isTraceDialogOpen: true,
        });
    };

    /*
     * Copy to clipboard
     */
    handleCopyToClipBoard = (selectedTrace) => (event) => {
        WebUtils.copyContentToClipBoard(selectedTrace.traceEventName);
    };

    /*
     * Close dialog
     */
    handleCancelOperation = () => {
        this.setState({
            isTraceDialogOpen: false,
            selectedTrace: null,
            isError: false,
        });
    };

    /**
     * Open delete confirmation dialog
     */

    private openDeleteConfirmationDialog = async (traceDefinitionToDelete) => {
        try {
            // Load summary data and show loading bar
            if (this.props.forceCRUD) {
                // Add current trace to activity map to display load icon
                this.setState({
                    onOperationMap: HashMap.copyAndPut(
                        this.state.onOperationMap,
                        traceDefinitionToDelete.entityId,
                        true
                    ),
                });

                const deleteSummary: GetDeleteSummaryResponse = await ServiceWire.getSternumService().getDeleteSummary(
                    traceDefinitionToDelete.entityId,
                    this.props.deviceDefinitionVersion.entityId
                );

                this.setState({
                    deleteCount: deleteSummary,
                });
            }
            this.setState({
                openConfirmationDialog: true,
                traceDefinitionToDelete: traceDefinitionToDelete,
            });
        } catch (err) {
            this.setState({
                onOperationMap: HashMap.copyAndRemove(this.state.onOperationMap, traceDefinitionToDelete.entityId),
            });
        }
    };

    /**
     * Close confirmation dialog
     */

    private closeDeleteConfirmationDialog = () => {
        if (this.props.forceCRUD) {
            // remove current trace from activity map to hide load icon
            this.setState({
                onOperationMap: HashMap.copyAndRemove(
                    this.state.onOperationMap,
                    this.state.traceDefinitionToDelete.entityId
                ),
            });
        }

        this.setState({ openConfirmationDialog: false, traceDefinitionToDelete: null });
    };

    render() {
        const { classes } = this.props;
        return [
            <div className={classNames(classes.flexColumn, classes.flexCenter, classes.flexVMiddle, classes.root)}>
                {/** Title */}
                <div className={classes.flexRow}>
                    <Typography variant={"subtitle2"} className={classNames(classes.marginBottomMedium)}>
                        Trace Definitions
                    </Typography>
                    <ExplanationComponent>
                        <Typography variant="body2">
                            Here you can create your own traces to be able to trace device-specific functionalities and
                            behaviors or to use Sternum’s out-of-the-box tracing capabilities to monitor
                            security-related events, performance, cryptography and more. Traces implemented onto device
                            collects and monitor device behavior. In the "Alert" creation step, traces are used to
                            create advanced rule-based detection of anomalous behavior, alerts and analytics based on
                            the collected data and your security policies.
                        </Typography>
                    </ExplanationComponent>
                </div>

                {/** Expansion panel */}
                <ExpansionPanel
                    role="presentation"
                    aria-label="expansion panel"
                    key={"SystemTracePanel"}
                    className={classes.fullWidth}
                    expanded={this.state.expanded === "system"}
                    onChange={this.handleExpandChange("system")}
                >
                    {/** Expansion for system traces */}
                    <ExpansionPanelSummary
                        role="presentation"
                        aria-label="expansion panel summary"
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography className={classes.heading}>System traces</Typography>
                    </ExpansionPanelSummary>

                    {/* Display system traces */}
                    <ExpansionPanelDetails
                        role="presentation"
                        aria-label="expansion panel details"
                        className={classNames(classes.tracesList)}
                    >
                        <div key="system-Trace-Container" className={classNames(classes.flexColumn, classes.fullWidth)}>
                            {this.props.deviceDefinition.traceDefinitions.map(
                                (traceSystemDefinitionIter: TraceDefinitionInfo, index) => {
                                    if (
                                        traceSystemDefinitionIter.isSystemTrace() &&
                                        traceSystemDefinitionIter.displayInUI
                                    ) {
                                        return (
                                            <div
                                                role="presentation"
                                                aria-label="system trace item"
                                                key={index + "System-Trace"}
                                                className={classNames(classes.flexColumn)}
                                            >
                                                <div className={classNames(classes.traceItem, classes.flexVMiddle)}>
                                                    {/* Trace name*/}
                                                    <div className={classes.systemDisplayName}>
                                                        <Typography noWrap variant="subtitle2">
                                                            {Utils.capitalize(traceSystemDefinitionIter.displayName)}
                                                        </Typography>
                                                    </div>

                                                    {/* Trace event name*/}
                                                    <div className={classes.systemRoleName}>
                                                        <Typography noWrap variant="subtitle2">
                                                            {traceSystemDefinitionIter.traceEventName}
                                                        </Typography>
                                                    </div>

                                                    {/* Separator */}
                                                    <div className={classNames(classes.flexGrow)} />

                                                    <div>
                                                        {/*Copy operation*/}
                                                        <Tooltip
                                                            title="Copy System Trace"
                                                            classes={{ tooltip: classes.toolTip }}
                                                        >
                                                            <IconButton
                                                                aria-label="copy"
                                                                className={classes.smallIconButton}
                                                                onClick={this.handleCopyToClipBoard(
                                                                    traceSystemDefinitionIter
                                                                )}
                                                            >
                                                                <Icon
                                                                    className={classNames("fas fa-paste")}
                                                                    fontSize={"small"}
                                                                    color="primary"
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                                <Divider />
                                            </div>
                                        );
                                    }
                                }
                            )}
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                {/** Custom trace Expansion panel */}
                <ExpansionPanel
                    role="presentation"
                    aria-label="expansion panel"
                    key={"CustomTracePanel"}
                    className={classNames(classes.fullWidth, classes.marginBottomMedium)}
                    expanded={this.state.expanded === "custom"}
                    onChange={this.handleExpandChange("custom")}
                >
                    <ExpansionPanelSummary
                        role="presentation"
                        aria-label="expansion panel summary"
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography className={classes.heading}>Customized traces</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails
                        role="presentation"
                        aria-label="expansion panel details"
                        className={classNames(classes.tracesList)}
                    >
                        <div className={classNames(classes.flexColumn, classes.fullWidth)}>
                            {/* Traces list */}
                            {this.state.deviceDefinition.traceDefinitions.map((traceDefinitionIter, index) => {
                                if (!traceDefinitionIter.isSystemTrace()) {
                                    return (
                                        <div
                                            role="presentation"
                                            aria-label="customized trace item"
                                            key={index + "Custom-Trace"}
                                            className={classNames(classes.flexColumn)}
                                        >
                                            <div
                                                className={classNames(
                                                    classes.flexSpaceBetween,
                                                    classes.traceItem,
                                                    classes.flexVMiddle
                                                )}
                                            >
                                                {/* Trace name*/}
                                                <div className={classNames(classes.customDisplayName)}>
                                                    <Typography variant="subtitle2" noWrap>
                                                        {Utils.capitalize(traceDefinitionIter.displayName)}
                                                    </Typography>
                                                </div>

                                                {/* Trace event name*/}
                                                <div className={classNames(classes.customDisplayRoleName)}>
                                                    <Typography variant="subtitle2" noWrap>
                                                        {traceDefinitionIter.traceEventName}
                                                    </Typography>
                                                </div>

                                                {/* Separator */}
                                                <div className={classNames(classes.flexGrow)} />

                                                {this.props.forceCRUD &&
                                                this.state.onOperationMap.containsKey(traceDefinitionIter.entityId) ? (
                                                    <CircularProgress size={16} className={classes.marginRightXs} />
                                                ) : (
                                                    <div>
                                                        {/* Copy operation*/}
                                                        <Tooltip
                                                            title="Copy Custom Trace"
                                                            classes={{ tooltip: classes.toolTip }}
                                                        >
                                                            <IconButton
                                                                aria-label="copy"
                                                                className={classes.smallIconButton}
                                                                onClick={this.handleCopyToClipBoard(
                                                                    traceDefinitionIter
                                                                )}
                                                            >
                                                                <Icon
                                                                    className={classNames("fas fa-paste")}
                                                                    fontSize={"small"}
                                                                    color="primary"
                                                                />
                                                            </IconButton>
                                                        </Tooltip>

                                                        {/* Edit operation*/}
                                                        <Tooltip title="Edit" classes={{ tooltip: classes.toolTip }}>
                                                            <IconButton
                                                                aria-label="edit"
                                                                className={classes.smallIconButton}
                                                                onClick={this.handleEditClick(traceDefinitionIter)}
                                                                disabled={this.state.isDisabledMode}
                                                            >
                                                                <EditIcon
                                                                    fontSize="small"
                                                                    color={
                                                                        this.state.isDisabledMode
                                                                            ? "disabled"
                                                                            : "primary"
                                                                    }
                                                                />
                                                            </IconButton>
                                                        </Tooltip>

                                                        {/* Delete operation*/}
                                                        <Tooltip title="Delete" classes={{ tooltip: classes.toolTip }}>
                                                            <IconButton
                                                                aria-label="delete"
                                                                className={classes.smallIconButton}
                                                                disabled={this.state.isDisabledMode}
                                                                onClick={() => {
                                                                    this.openDeleteConfirmationDialog(
                                                                        traceDefinitionIter
                                                                    );
                                                                }}
                                                            >
                                                                <DeleteIcon
                                                                    fontSize="small"
                                                                    color={
                                                                        this.state.isDisabledMode ? "disabled" : "error"
                                                                    }
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                )}
                                            </div>
                                            <Divider />
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                {}
                <div className={classNames(classes.fullWidth, classes.flexEnd)}>
                    <Button
                        disabled={this.state.isDisabledMode}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            this.setState({
                                isTraceDialogOpen: true,
                            });
                        }}
                    >
                        Create Trace
                    </Button>
                </div>

                {}
                {(() => {
                    if (this.state.isTraceDialogOpen) {
                        return (
                            <TraceDefinitionSimpleDialog
                                categoriesList={this.props.categoriesList}
                                existingTraceDefinition={this.state.selectedTrace}
                                open={this.state.isTraceDialogOpen}
                                onClose={this.onSaveDialog}
                                onCancel={this.handleCancelOperation}
                                duplicationCheck={this.checkIfTraceExists}
                                isError={this.state.isError}
                            />
                        );
                    }
                })()}
                {/* Delete confirmation dialog */}

                {this.state.traceDefinitionToDelete && (
                    <ConfirmationDialog
                        open={this.state.openConfirmationDialog}
                        title={`Delete ${this.state.traceDefinitionToDelete.displayName}?`}
                        body={"All data associated to this trace definition will be deleted."}
                        handleCancel={this.closeDeleteConfirmationDialog}
                        handleApprove={this.removeTraceFromDevice}
                        overrideActionName={"Delete"}
                    >
                        {this.state.deleteCount && this.state.deleteCount.triggersCount > 0 && (
                            <div className={classNames(classes.flexRow)}>
                                <Typography variant={"subtitle1"} className={classNames(classes.commonBold)}>
                                    {this.state.deleteCount.triggersCount}
                                </Typography>
                                <Typography gutterBottom variant={"subtitle1"}>
                                    &nbsp;
                                </Typography>
                                <Typography gutterBottom variant={"subtitle1"}>
                                    {"associated alerts will be deleted."}
                                </Typography>
                            </div>
                        )}
                    </ConfirmationDialog>
                )}
            </div>,
        ];
    }

    private removeTraceFromDevice = async () => {
        // Close the dialog to prevent double clicking
        this.setState({ openConfirmationDialog: false });

        if (this.isForceCRUD()) {
            // Send delete request
            const deleteResponse = await ServiceWire.getSternumService().deleteTraceDefinition(
                this.state.traceDefinitionToDelete.entityId,
                this.props.deviceDefinitionVersion.entityId
            );
            if (deleteResponse) {
                // Delete in place triggers associated to deleted trace definition
                this.props.deviceDefinitionVersion.deviceDefinition.deleteAssociatedTriggersByTraceDefinition(
                    this.state.traceDefinitionToDelete.entityId
                );
                this.removeTraceInPlace(this.state.traceDefinitionToDelete);
            }
        } else {
            this.removeTraceInPlace(this.state.traceDefinitionToDelete);
        }
        // remove current trace from activity map to hide load icon
        this.setState({
            onOperationMap: HashMap.copyAndRemove(
                this.state.onOperationMap,
                this.state.traceDefinitionToDelete.entityId
            ),
        });
    };

    private removeTraceInPlace(traceToRemove: any) {
        this.state.deviceDefinition.removeTrace(traceToRemove);
        this.setState({
            deviceDefinition: this.state.deviceDefinition,
            traceDefinitionToDelete: null,
        });
    }

    private async UpdateOrCreateTrace(
        traceName: string,
        traceEventName: string,
        traceCategory: string,
        traceInterest: string,
        transmitFrequency: string,
        description: string,
        selectedTrace?: any
    ) {
        let traceDefinitionResponse = undefined;
        if (selectedTrace) {
            this.updateSelectedTrace(
                traceName,
                traceEventName,
                traceCategory,
                traceInterest,
                transmitFrequency,
                description
            );
            traceDefinitionResponse = await ServiceWire.getSternumService().updateTraceDefinition(
                this.state.selectedTrace,
                this.props.deviceDefinitionVersion.entityId
            );
        } else {
            const traceObject = new TraceDefinitionPartial(
                traceName,
                traceEventName,
                traceCategory,
                traceInterest,
                transmitFrequency,
                description
            );
            traceDefinitionResponse = await ServiceWire.getSternumService().createTraceDefinition(
                this.props.deviceDefinitionVersion.entityId,
                traceObject
            );
            if (traceDefinitionResponse && traceDefinitionResponse.length > 0) {
                this.updateCurrentDisplayList(traceDefinitionResponse[0]);
            }
        }
    }

    private updateSelectedTrace = (
        displayName,
        traceEventName,
        traceCategory,
        traceInterest,
        transmitFrequency,
        description
    ) => {
        this.state.selectedTrace.displayName = displayName;
        this.state.selectedTrace.traceEventName = traceEventName;
        this.state.selectedTrace.traceCategory = traceCategory;
        this.state.selectedTrace.traceInterest = traceInterest;
        this.state.selectedTrace.transmitFrequency = transmitFrequency;
        this.state.selectedTrace.description = description;
    };

    private updateCurrentDisplayList = (traceObject: any) => {
        this.state.deviceDefinition.traceDefinitions.push(traceObject);
    };

    private UpdateOrCreateInPlace(
        traceName: string,
        traceEventName: string,
        traceCategory: string,
        traceInterest: string,
        transmitFrequency: string,
        description: string,
        selectedTrace?: any
    ) {
        if (!selectedTrace) {
            const newTrace = new TraceDefinitionPartial(
                traceName,
                traceEventName,
                traceCategory,
                traceInterest,
                transmitFrequency,
                description
            );
            this.updateCurrentDisplayList(newTrace);
        } else {
            this.updateSelectedTrace(
                traceName,
                traceEventName,
                traceCategory,
                traceInterest,
                transmitFrequency,
                description
            );
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(traceDefinitionStepStyle)(TraceDefinitionStep));
