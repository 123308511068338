import React from "react";
import classNames from "classnames";

import { useMainSectionStyle } from "./MainSection.style";

export interface MainSectionInlineProps extends React.ComponentProps<"div"> {}

export function MainSectionInline({ className, children, ...props }: MainSectionInlineProps) {
    const classes = useMainSectionStyle();

    return (
        <div className={classNames(classes.inline, className)} {...props}>
            {children}
        </div>
    );
}
