import { useCallback, useEffect, useRef, useState } from "react";
import { uniqBy } from "lodash";

import ServiceWire from "../../../../lib/services/ServiceWire";
import SternumDeviceEventsFilter from "../../../../lib/state/SternumDeviceEventsFilter";
import SternumConfiguration from "../../../../lib/infra/SternumConfiguration";
import DeviceInfo from "../../../../lib/state/DeviceInfo";
import GetSternumDeviceEventsResponseWithCursor from "../../../../lib/state/GetSternumDeviceEventsResponseWithCursor";
import GetSternumUniqueCountEventsResponse from "../../../../lib/state/GetSternumUniqueCountEventsResponse";

import SternumDeviceEventInfo from "../../../../lib/state/SternumDeviceEventInfo";
import { useAutoUpdatedRef } from "../../../../hooks";

import { CrashesTableArgument, CrashesTableData } from "./CrashesTab.types";

export interface CrashesTableDataArgs {
    device: DeviceInfo;
    searchText: string;
    startDate: Date;
    endDate: Date;
}

export const useCrashesTabData = ({ device, searchText, startDate, endDate }: CrashesTableDataArgs) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [sternumDeviceEvents, setSternumDeviceEvents] = useState<
        GetSternumDeviceEventsResponseWithCursor | GetSternumUniqueCountEventsResponse | null
    >(null);
    const [crashesData, setCrashesData] = useState<CrashesTableData>({
        totalItemsCount: 0,
        crashesData: [],
    });

    const cursorRef = useRef("");
    const isLoadingRef = useAutoUpdatedRef(isLoading);

    const fetchDeviceCrashesData = useCallback(
        async ({ isFirstPage }: { isFirstPage?: boolean } = {}) => {
            if (isLoadingRef.current && !isFirstPage) {
                return;
            }

            setIsLoading(true);

            const entitiesFilter = new SternumDeviceEventsFilter(
                null,
                false,
                startDate?.getTime() || null,
                endDate?.getTime() || null,
                null,
                null,
                [], // this.state.filterByTraceCategories,
                false,
                null, // this.state.sternumQuery,
                null
            );

            try {
                const sternumDeviceEvents = await ServiceWire.getSternumService().getDeviceSternumDeviceEvents(
                    device.entityId,
                    entitiesFilter, // this.state.entitiesFilter,
                    searchText, // this.state.searchText,
                    "desc", // this.state.order,
                    [],
                    isFirstPage ? "" : cursorRef.current, // cursor,
                    SternumConfiguration.getPageSize(),
                    device.lastSeenVersionId, // this.props.deviceDefinitionVersionId,
                    undefined, // this.props.excludeLinuxViewTraces,
                    undefined,
                    undefined, // aggregationFunction,
                    undefined, // this.props.dynamicColumns
                    undefined,
                    ["TRACE_CRASH"]
                );

                cursorRef.current = sternumDeviceEvents.cursor;

                const deviceEvents = sternumDeviceEvents.sternumDeviceEvents as SternumDeviceEventInfo[];
                const newCrashesData: CrashesTableData = {
                    totalItemsCount: sternumDeviceEvents.totalItemCount,
                    crashesData: deviceEvents.map((deviceEvent) => {
                        const allArguments = deviceEvent?.traceInfo?.traceArguments || {};

                        const mainArguments: CrashesTableArgument[] = [];
                        const otherArguments: CrashesTableArgument[] = [];

                        function addMainArgument(argumentId): boolean /* return true if added to mainArguments */ {
                            if ("ARG_ROLE_NAME" === argumentId) {
                                return !!mainArguments.push({
                                    argumentId,
                                    argumentName: "Process name",
                                    argumentValue: allArguments[argumentId].displayValue,
                                });
                            }

                            // FIXME: ARG_ROLE_SINGLE_RECEIVED - check if this role is correct and add here proper value
                            if ("ARG_ROLE_SIGNAL_RECEIVED" === argumentId) {
                                return !!mainArguments.push({
                                    argumentId,
                                    argumentName: allArguments[argumentId].argumentDefinition.displayName,
                                    argumentValue: allArguments[argumentId].displayValue,
                                });
                            }

                            return false;
                        }

                        Object.keys(allArguments).forEach((argumentId) => {
                            if (addMainArgument(argumentId)) {
                                return;
                            }

                            otherArguments.push({
                                argumentId,
                                argumentName: allArguments[argumentId].argumentDefinition.displayName,
                                argumentValue: allArguments[argumentId].displayValue,
                            });
                        });

                        return {
                            crashesItemId: deviceEvent.entityId,
                            receivedTime: new Date(deviceEvent.created), // TODO: Check if this is correct field
                            mainArguments,
                            otherArguments,
                        };
                    }),
                };

                if (isFirstPage) {
                    setCrashesData(newCrashesData);
                } else {
                    setCrashesData((oldCrashesTableData) => {
                        const allCrashesData = [...oldCrashesTableData.crashesData, ...newCrashesData.crashesData];
                        const uniqAllCrashesData = uniqBy(
                            allCrashesData.reverse(),
                            (item) => item.crashesItemId
                        ).reverse();

                        return {
                            totalItemsCount: newCrashesData.totalItemsCount,
                            crashesData: uniqAllCrashesData,
                        };
                    });
                }

                setIsLoading(false);
                setIsError(false);
            } catch (e) {
                setIsError(true);
                setIsLoading(false);
            }
        },
        [device, searchText, startDate, endDate]
    );

    useEffect(() => {
        fetchDeviceCrashesData({ isFirstPage: true }).then();
    }, [fetchDeviceCrashesData]);

    return { isLoading, isError, crashesData, fetchDeviceCrashesData };
};
