/**
 * Represents a filter for an event that happened.
 */
class EventFilter {
    /**
     * Constructor.
     */
    constructor(public eventType: string, public from: Date, public to: Date) {}

    /**
     * Returns whether given other event filter is any different than current.
     * @param other
     */
    public isDifferentFrom(other: EventFilter): boolean {
        if (!(other instanceof EventFilter)) {
            return true;
        }

        let otherEventFilter = other as EventFilter;

        return (
            otherEventFilter.eventType !== this.eventType ||
            otherEventFilter.from !== this.from ||
            otherEventFilter.to !== this.to
        );
    }
}

export default EventFilter;
