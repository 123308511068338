import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useFormik } from "formik";
import classNames from "classnames";
import { Box, Checkbox, FormControlLabel, Typography } from "@material-ui/core";

import { NotificationMessage, NotificationVariant } from "../../../lib/state/NotificationsState";
import { showNotificationAction } from "../../../lib/redux/notifications/ShowNotificationAction";
import SternumImprovedButton from "../../SUI/SternumImprovedButton/SternumImprovedButton";
import { GlobalState } from "../../../lib/state/GlobalState";
import { EmailNotificationType, EmailNotificationSettings } from "../../../lib/state/EmailNotificationSettings";
import ServiceWire from "../../../lib/services/ServiceWire";
import { SternumTooltip } from "../../SUI/SternumTooltip";
import { InfoCircleIcon } from "../../SUI/SternumIcon";
import WebUtils from "../../../lib/infra/WebUtils";
import { useCommonStyle } from "../../CommonStyle";

import { EmailUnsubscribeDialog } from "../EmailUnsubscribeDialog";
import { useStyle } from "./EmailNotifications.style";
import { useEmailNotificationSettings } from "./EmailNotifications.hook";
import { EmailAlreadyUnsubscribedDialog } from "../EmailAlreadyUnsubscribedDialog";

const unsubscribeUrlParam = "unsubscribe";

const mapStateToProps = (state: GlobalState, ownProps: EmailNotificationsComponentProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        showNotification: (message: NotificationMessage, variant?: NotificationVariant) => {
            dispatch(showNotificationAction(message, variant));
        },
    };
};

type EmailNotificationsPropsWithHOC = EmailNotificationsComponentProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export interface EmailNotificationsComponentProps {}

function EmailNotificationsComponent({ showNotification }: EmailNotificationsPropsWithHOC) {
    const classes = useStyle();
    const classesCommon = useCommonStyle();
    const [unsubscribeFromEmails, setUnsubscribeFromEmails] = useState<EmailNotificationType[]>([]);
    const [alreadyUnsubscribedFromEmails, setAlreadyUnsubscribedFromEmails] = useState<EmailNotificationType[]>([]);

    const {
        emailNotificationSettings,
        refetch,
        isLoading: areEmailNotificationSettingsLoading,
        isBeforeFirstLoad: areEmailNotificationSettingsBeforeFirstLoad,
    } = useEmailNotificationSettings();

    const clearUnsubscribePageState = () => {
        WebUtils.removeUrlSearchParam(unsubscribeUrlParam);
        setUnsubscribeFromEmails([]);
        setAlreadyUnsubscribedFromEmails([]);
        refetch();
    };

    const form = useFormik<EmailNotificationSettings[]>({
        initialValues: emailNotificationSettings,
        enableReinitialize: true,
        async onSubmit(values) {
            const clientId = ServiceWire.getClientsService().getSelectedClientId();
            const subscribedNotificationIds = values.filter((email) => email.subscribed).map((email) => email.id);

            try {
                await ServiceWire.getSternumService().subscribeToEmailNotifications(
                    clientId,
                    subscribedNotificationIds
                );

                // for now we have only one type of report, so message is specific to it
                if (values[0].subscribed) {
                    showNotification("You've subscribed successfully", NotificationVariant.Success);
                } else {
                    showNotification("You've unsubscribed successfully", NotificationVariant.Success);
                }

                await refetch();
            } catch {
                showNotification("There was an error, please refresh and try again", NotificationVariant.Error);
            }
        },
    });

    useEffect(() => {
        if (areEmailNotificationSettingsLoading || areEmailNotificationSettingsBeforeFirstLoad) {
            return;
        }

        const unsubscribeFrom = WebUtils.getUrlSearchArrayParam(unsubscribeUrlParam);

        if (unsubscribeFrom && emailNotificationSettings) {
            // list of notifications that user is subscribed to
            const subscribedEmailNotifications = emailNotificationSettings
                .filter((setting) => setting.subscribed)
                .map((setting) => setting.id);

            // list of notifications that user is subscribed to and wants to unsubscribe from
            const subscribedIntersection = subscribedEmailNotifications.filter((subscribed) =>
                unsubscribeFrom.includes(subscribed)
            );

            if (subscribedIntersection.length > 0) {
                setUnsubscribeFromEmails(unsubscribeFrom as EmailNotificationType[]);
            } else {
                clearUnsubscribePageState();
                setAlreadyUnsubscribedFromEmails(unsubscribeFrom as EmailNotificationType[]);
            }
        }
    }, [areEmailNotificationSettingsLoading, areEmailNotificationSettingsBeforeFirstLoad, emailNotificationSettings]);

    const weeklyReportEmailIndex = form.values.findIndex((email) => email.id === EmailNotificationType.WeeklyReport);
    const weeklyReportEmail = form.values[weeklyReportEmailIndex];

    return (
        <div role="presentation" aria-label="email subscription section" className={classes.root}>
            <Typography variant="h6" className={classNames(classes.title, classesCommon.extraBold)}>
                Email subscriptions
            </Typography>

            {weeklyReportEmail && (
                <Box>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={weeklyReportEmail.subscribed}
                                style={{ color: "#1B6FDE", backgroundColor: "#fff" }}
                                onChange={(e) => {
                                    const checked = e.target.checked;
                                    form.setFieldValue(`${weeklyReportEmailIndex}.subscribed`, checked);
                                }}
                            />
                        }
                        style={{ alignItems: "flex-start" }}
                        label={
                            <Box pt="10px">
                                <Box display="flex" alignItems="center">
                                    <Typography variant="body2" className={classes.notificationTitle}>
                                        Receive Weekly Reports
                                    </Typography>

                                    <Box className={classes.notificationTooltipBox}>
                                        <SternumTooltip
                                            placement="top"
                                            useWrapper={false}
                                            title="The report is being generated only if your workspace has at least one Production Device profile. Staging profiles are not included."
                                        >
                                            <Box display="inline-flex" ml={2}>
                                                <InfoCircleIcon width={18} height={18} color="#1B6FDE" />
                                            </Box>
                                        </SternumTooltip>
                                    </Box>
                                </Box>

                                <Typography variant="body2" className={classes.notificationDescription}>
                                    The report will send you data about the most important and impactful events in your
                                    workspace. Like most active Device Profiles, Alerts statistics and security updates
                                    and more.
                                </Typography>
                            </Box>
                        }
                    />
                </Box>
            )}

            <Box mt="24px">
                <SternumImprovedButton
                    content="Save"
                    isLoading={form.isSubmitting}
                    isDisabled={!form.dirty || areEmailNotificationSettingsLoading}
                    buttonType="blue"
                    onClick={() => form.submitForm()}
                    fullWidth={false}
                />
            </Box>

            <EmailUnsubscribeDialog
                open={unsubscribeFromEmails.length > 0}
                unsubscribeFrom={unsubscribeFromEmails}
                onCancelClick={clearUnsubscribePageState}
            />

            <EmailAlreadyUnsubscribedDialog
                open={alreadyUnsubscribedFromEmails.length > 0}
                alreadyUnsibscribedFrom={alreadyUnsubscribedFromEmails}
                onOKClick={clearUnsubscribePageState}
            />
        </div>
    );
}

export const EmailNotifications: React.FC<EmailNotificationsComponentProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(EmailNotificationsComponent);
