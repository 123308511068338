import React from "react";

import { useTilesStyle } from "./Tiles.style";

export interface TileItemProps extends React.ComponentProps<"div"> {
    icon?: React.ReactElement;
    title?: string;
    description?: React.ReactNode;
}

export function TileItem({ className, icon, title, description, ...props }: TileItemProps) {
    const classes = useTilesStyle();

    return (
        <div className={classes.tileItem} {...props}>
            <div className={classes.tileItemIcon}>{icon}</div>
            <div className={classes.tileTitle}>{title}</div>
            <div className={classes.tileDescription}>{description}</div>
        </div>
    );
}
