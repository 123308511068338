import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { Typography } from "@material-ui/core";

import { ReleaseSystem } from "../../../lib/state/DownloadType";
import HttpResponse from "../../../lib/state/HttpResponse";
import ServiceWire from "../../../lib/services/ServiceWire";
import WebUtils from "../../../lib/infra/WebUtils";
import { GlobalState } from "../../../lib/state/GlobalState";
import { NotificationMessage, NotificationVariant } from "../../../lib/state/NotificationsState";
import { showNotificationAction } from "../../../lib/redux/notifications/ShowNotificationAction";
import { useCommonStyle } from "../../CommonStyle";
import { DiamondIcon } from "../../SUI/SternumIcon";
import { useSettingsGlobalStyle } from "../SettingsPageStyle";
import { Chip } from "../Chip";

import { useDownloadAdsSdkStyle } from "./DownloadAdsSdk.style";
import { downloadSystemApps } from "./DownloadAdsSdk.model";
import { DownloadChips } from "./DownloadChips";
import { useBuildVersionByReleaseSystem } from "./DownloadAdsSdk.hook";

export interface DownloadAdsSdkComponentProps {}

const mapStateToProps = (state: GlobalState, ownProps: DownloadAdsSdkComponentProps) => {
    return {
        isFreeUser: ServiceWire.getClientsService().getSelectedClient().isTrialTier(),
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        showNotification: (message: NotificationMessage, variant?: NotificationVariant) => {
            dispatch(showNotificationAction(message, variant));
        },
    };
};

interface DownloadState {
    downloadState: "inProgress" | "done" | "notStarted";
    progress: number;
}

type DownloadAdsSdkPropsWithHOC = DownloadAdsSdkComponentProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

function DownloadAdsSdkComponent({ isFreeUser, showNotification }: DownloadAdsSdkPropsWithHOC) {
    const isMountedRef = useIsMountedRef();
    const downloadRef = useRef<Record<ReleaseSystem, { downloadId: number }>>({
        [ReleaseSystem.Linux]: { downloadId: 1 },
        [ReleaseSystem.MacOsArm]: { downloadId: 1 },
        [ReleaseSystem.MacOsIntel]: { downloadId: 1 },
        [ReleaseSystem.Windows]: { downloadId: 1 },
    });
    const classes = useDownloadAdsSdkStyle();
    const classesSettings = useSettingsGlobalStyle();
    const classesCommon = useCommonStyle();

    const buildVersionByReleaseSystem = useBuildVersionByReleaseSystem();

    const [downloadStateByReleaseSystem, setDownloadStateByReleaseSystem] = useState<
        Record<ReleaseSystem, DownloadState>
    >({
        [ReleaseSystem.Linux]: { downloadState: "notStarted", progress: 0 },
        [ReleaseSystem.MacOsArm]: { downloadState: "notStarted", progress: 0 },
        [ReleaseSystem.MacOsIntel]: { downloadState: "notStarted", progress: 0 },
        [ReleaseSystem.Windows]: { downloadState: "notStarted", progress: 0 },
    });

    const setDownloadStateForReleaseSystem = (releaseSystem: ReleaseSystem, newState: Partial<DownloadState>) => {
        if (!isMountedRef.current) {
            return;
        }

        setDownloadStateByReleaseSystem((prevState) => ({
            ...prevState,
            [releaseSystem]: {
                ...prevState[releaseSystem],
                ...newState,
            },
        }));
    };

    const downloadApp = async (releaseSystem: ReleaseSystem) => {
        setDownloadStateForReleaseSystem(releaseSystem, { downloadState: "inProgress", progress: 0 });
        const currentDownloadId = ++downloadRef.current[releaseSystem].downloadId;

        try {
            let downloadResult: HttpResponse =
                await ServiceWire.getDownloadService().downloadApplicationForReleaseSystem(releaseSystem, {
                    onProgress: (progress) => {
                        setDownloadStateForReleaseSystem(releaseSystem, { progress });
                    },
                });

            if (downloadResult) {
                WebUtils.downloadReport(downloadResult);
            }
        } catch (e) {
            const errorSystemByReleaseSystem: Record<ReleaseSystem, string> = {
                [ReleaseSystem.Linux]: "Linux",
                [ReleaseSystem.MacOsArm]: "Mac",
                [ReleaseSystem.MacOsIntel]: "Mac",
                [ReleaseSystem.Windows]: "Windows",
            };

            showNotification(
                `Error while downloading SDK app for ${errorSystemByReleaseSystem[releaseSystem]}`,
                NotificationVariant.Error
            );
            return setDownloadStateForReleaseSystem(releaseSystem, { downloadState: "notStarted", progress: 0 });
        }

        setDownloadStateForReleaseSystem(releaseSystem, { downloadState: "done", progress: 0 });

        setTimeout(() => {
            if (!isMountedRef.current || currentDownloadId !== downloadRef.current[releaseSystem].downloadId) {
                return;
            }

            setDownloadStateForReleaseSystem(releaseSystem, { downloadState: "notStarted", progress: 0 });
        }, 3000);
    };

    return (
        <div className={classes.root}>
            <Typography variant="h6" className={classNames(classes.title, classesCommon.extraBold)}>
                Download ADS SDK
                {isFreeUser && (
                    <Chip>
                        <DiamondIcon /> Premium
                    </Chip>
                )}
            </Typography>
            <div className={classNames(classesSettings.subTitle, classes.subTitle)}>
                The ADS SDK allows you to define and configure traces in your firmware for any desired function and
                apply custom anomaly detection over this data. Use this application to manage your device profile and
                related trace arguments.
            </div>

            <DownloadChips>
                {downloadSystemApps.map((item) => (
                    <DownloadChips.Item
                        key={item.releaseSystem}
                        isDisabled={isFreeUser}
                        onClick={() => downloadApp(item.releaseSystem)}
                        iconLogo={item.iconLogo}
                        systemTitle={item.systemTitle}
                        systemSubtitle={item.systemSubtitle}
                        version={buildVersionByReleaseSystem[item.releaseSystem]?.buildVersion || ""}
                        showProgressBar={
                            downloadStateByReleaseSystem[item.releaseSystem].downloadState === "inProgress"
                        }
                        progressValue={downloadStateByReleaseSystem[item.releaseSystem].progress}
                        isDownloadDone={downloadStateByReleaseSystem[item.releaseSystem].downloadState === "done"}
                    />
                ))}
            </DownloadChips>
        </div>
    );
}

export const DownloadAdsSdk: React.FC<DownloadAdsSdkComponentProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(DownloadAdsSdkComponent);

const useIsMountedRef = () => {
    const isMountedRef = useRef<boolean>(true);

    useEffect(
        () => () => {
            isMountedRef.current = false;
        },
        []
    );

    return isMountedRef;
};
