import { Theme } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyle = makeStyles((theme: Theme) => ({
    root: {
        fontFamily: "Inter",
        padding: "0 18px",
    },

    paper: {
        width: "480px",
        boxShadow: "0px 0px 50px rgba(0, 0, 0, 0.12)",
        borderRadius: "8px",

        "& .MuiDialogActions-root": {
            justifyContent: "flex-start",
        },
    },

    dialogContent: {
        padding: "1px 18px",
    },

    title: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },

    unsubscribeDescription: {
        paddingLeft: 26,
        color: "#999999",
    },

    actions: {
        marginTop: 20,
        "&&": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: 24,
            paddingTop: 8,
        },
    },

    linkButton: {
        fontSize: 16,
        fontWeight: 500,
        color: "#000",
    },
}));
