import { Typography } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import HashSet from "../../lib/infra/HashSet";
import Utils from "../../lib/infra/Utils";
import { fetchLibraryAction } from "../../lib/redux/libraries/FetchLibraryAction";
import CvesFilter from "../../lib/state/CvesFilter";
import DeviceDefinitionLibrariesFilter from "../../lib/state/DeviceDefinitionLibrariesFilter";
import { GlobalState } from "../../lib/state/GlobalState";
import LibraryHistoriesFilter from "../../lib/state/LibraryHistoriesFilter";
import LibraryInfo from "../../lib/state/LibraryInfo";
import TableToolbarDisplayState from "../../lib/state/TableToolbarDisplayState";
import CvesList from "../CvesList/CvesList";
import DeviceDefinitionLibrariesList from "../DeviceDefinitionLibrariesList/DeviceDefinitionLibrariesList";
import LibraryHistoriesList from "../LibraryHistoriesList/LibraryHistoriesList";
import LibraryInfoDisplay from "../LibraryInfoDisplay/LibraryInfoDisplay";
import libraryViewStyle from "./LibraryViewStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    selectedTab: number;
    selectedTabType: any;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof libraryViewStyle> {
    libraryId: string;
    fullScreenDisplay: boolean;
    backButtonDisplayed: boolean;

    loadingLibrary?: boolean;
    errorLoadingLibrary?: boolean;
    library?: LibraryInfo;

    fetchLibraryAction?: (libraryId: string) => void;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {
        loadingLibrary: state.libraries.loadingIds.exists(ownProps.libraryId),
        errorLoadingLibrary: state.libraries.errorIds.containsKey(ownProps.libraryId),
        library: state.libraries.idToEntityMap.get(ownProps.libraryId),
    };
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchLibraryAction: (libraryId: string) => dispatch(fetchLibraryAction(libraryId)),
    };
};

/**
 * Displays a bar of metrics.
 */
class LibraryView extends React.Component<AppProps, AppState> {
    /**
     * Holds all type of tabs.
     */
    private tabTypes = {
        libraryInfo: {
            key: "libraryInfo",
            displayName: "Library Info",
            index: 0,
        },
        devices: {
            key: "devices",
            displayName: "Related Devices",
            index: 1,
        },
        cves: {
            key: "cves",
            displayName: "Known CVEs",
            index: 2,
        },
        history: {
            key: "history",
            displayName: "Version History",
            index: 2,
        },
    };

    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            selectedTab: null,
            selectedTabType: null,
        };
    }

    /**
     * Occurs once the component is mounted.
     * We fetch the library if needed here.
     */
    async componentDidMount() {
        // Getting the library.
        this.props.fetchLibraryAction(this.props.libraryId);
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        if (this.props.loadingLibrary || !this.props.library) {
            // Return loading placeholder for library page.
            return (
                <div
                    className={classNames(
                        classes.libraryViewUpperContent,
                        this.props.backButtonDisplayed && "mod-with-back-button"
                    )}
                >
                    <div className={classNames(classes.loadingPlaceholder, classes.marginBottom, "mod-title")} />
                    <div
                        className={classNames(classes.loadingPlaceholder, classes.marginBottomXLarge, "mod-subtitle")}
                    />
                    <div className={classNames(classes.loadingPlaceholder, classes.marginBottom, "mod-paragraph")} />
                    <div className={classNames(classes.loadingPlaceholder, classes.marginBottom, "mod-paragraph")} />
                    <div className={classNames(classes.loadingPlaceholder, classes.marginBottom, "mod-paragraph")} />
                </div>
            );
        } else {
            let tabsToDisplay = Utils.getMapValues(this.tabTypes);

            let selectedTab = this.state.selectedTab;
            if (selectedTab === null) {
                selectedTab = 0;
            }

            let selectedTabType = this.state.selectedTabType;
            if (selectedTabType === null) {
                selectedTabType = tabsToDisplay[0];
            }

            let now = Utils.now();
            let thirtyDays = 30 * 24 * 60 * 60 * 1000;

            // Otherwise, render the library view.
            return (
                <div>
                    {/* Upper content */}
                    <div className={classes.libraryViewUpperContent}>
                        {/* Library name */}
                        <Typography variant={"h5"} className={classes.title}>
                            {this.props.library.name}
                        </Typography>

                        {/* Version */}
                        <Typography variant={"caption"} className={classNames(classes.marginRightXs)}>
                            {this.props.library.latestKnownVersion}
                        </Typography>
                    </div>

                    {/* Tab selection */}
                    <Tabs
                        value={selectedTab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={(event, value) => this.handleSelectedTab(value, tabsToDisplay[value])}
                    >
                        {/* Getting the tabs */}
                        {tabsToDisplay.map((tabType) => (
                            <Tab key={tabType.key} label={tabType.displayName} />
                        ))}
                    </Tabs>

                    {/* Library info */}
                    {selectedTabType.key === "libraryInfo" && (
                        <div className={classes.libraryInfoTabContent}>
                            <LibraryInfoDisplay library={this.props.library} />
                        </div>
                    )}

                    {/* Devices */}
                    {selectedTabType.key === "devices" && (
                        <div className={classes.devicesListTabContent}>
                            <DeviceDefinitionLibrariesList
                                libraryId={this.props.libraryId}
                                viewedColumnsSet={HashSet.fromValues([
                                    "deviceDefinitionId",
                                    "deviceDefinitionLibraryVersion",
                                    "actions",
                                ])}
                                includedActionsSet={HashSet.fromValue("history")}
                                deviceDefinitionLibrariesFilter={
                                    new DeviceDefinitionLibrariesFilter(null, this.props.libraryId, null)
                                }
                                library={this.props.library}
                                displayXButtonInUsedLibraryView={true}
                                displayBackButtonInUsedLibraryView={true}
                                paperClassNames={classNames(
                                    classes.devicesListPaper,
                                    this.props.fullScreenDisplay ? "mod-fullscreen" : "mod-regular"
                                )}
                                toolbarState={
                                    new TableToolbarDisplayState(
                                        true,
                                        false,
                                        true,
                                        false,
                                        false,
                                        false,
                                        false,
                                        false,
                                        "Search Library Name"
                                    )
                                }
                            />
                        </div>
                    )}

                    {/* Cves */}
                    {selectedTabType.key === "cves" && (
                        <div className={classes.cvesListTabContent}>
                            <CvesList
                                cvesFilter={new CvesFilter(this.props.library.entityId, null, null, null, null, null)}
                                paperClassNames={classNames(
                                    classes.cvesListPaper,
                                    this.props.fullScreenDisplay ? "mod-fullscreen" : "mod-regular"
                                )}
                                displayBackButtonInCveView={true}
                                displayXButtonInCveView={true}
                            />
                        </div>
                    )}

                    {/* History */}
                    {selectedTabType.key === "history" && (
                        <div className={classes.historiesListTabContent}>
                            {/* History list */}
                            <LibraryHistoriesList
                                viewedColumnsSet={HashSet.fromValues([
                                    "libraryVersion",
                                    "libraryUpdated",
                                    "historyDate",
                                ])}
                                libraryHistoriesFilter={new LibraryHistoriesFilter(this.props.library.entityId)}
                                paperClassNames={classNames(
                                    classes.historiesListPaper,
                                    this.props.fullScreenDisplay ? "mod-fullscreen" : "mod-regular"
                                )}
                            />
                        </div>
                    )}
                </div>
            );
        }
    }

    /**
     * Occurs once a tab has been selected.
     */
    private handleSelectedTab(selectedTab, selectedTabType) {
        this.setState({
            selectedTab: selectedTab,
            selectedTabType: selectedTabType,
        });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(libraryViewStyle)(LibraryView));
