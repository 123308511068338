import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { History } from "history";

import { GlobalState } from "../../../lib/state/GlobalState";
import DeviceInfo from "../../../lib/state/DeviceInfo";
import DeviceDefinitionInfo from "../../../lib/state/DeviceDefinitionInfo";
import DeviceDefinitionVersionInfo from "../../../lib/state/DeviceDefinitionVersionInfo";
import TimeSelectOption from "../../../lib/state/TimeSelectOption";

import { LinuxTabs } from "./LinuxTabs";
import { OverviewTab } from "./OverviewTab";
import { AlertsTab } from "./AlertsTab";
import { AnomaliesTab } from "./AnomaliesTab";
import { CrashesTab } from "./CrashesTab";
import { PerformanceTab } from "./PerformanceTab";
import { HardwareEventsTab } from "./HardwareEventsTab";
import { RemoteViewTab } from "./RemoteViewTab";
import { TracesTab } from "./TracesTab";

import { useDeviceInventoryLinuxViewStyle } from "./DeviceInventoryLinuxView.styles";

export interface DeviceInventoryLinuxViewProps {
    deviceId: string;
    device: DeviceInfo;
    deviceDefinition: DeviceDefinitionInfo;
    deviceDefinitionVersion: DeviceDefinitionVersionInfo;
    timeSelectOption: TimeSelectOption;
    startDate: Date;
    endDate: Date;
    refreshEntitiesFilter?: boolean;
}

const mapStateToProps = (state: GlobalState, ownProps: DeviceInventoryLinuxViewProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

type DeviceInventoryLinuxViewPropsWithHOC = DeviceInventoryLinuxViewProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
        history: History;
    };

function DeviceInventoryLinuxViewComponent({
    history,
    deviceId,
    device,
    deviceDefinition,
    deviceDefinitionVersion,
    timeSelectOption,
    startDate,
    endDate,
    refreshEntitiesFilter,
}: DeviceInventoryLinuxViewPropsWithHOC) {
    const classes = useDeviceInventoryLinuxViewStyle();

    const renderOverview = () => (
        <OverviewTab
            deviceId={deviceId}
            device={device}
            deviceDefinition={deviceDefinition}
            deviceDefinitionVersion={deviceDefinitionVersion}
            timeSelectOption={timeSelectOption}
            startDate={startDate}
            endDate={endDate}
            refreshEntitiesFilter={refreshEntitiesFilter}
        />
    );
    const renderAlerts = () => <AlertsTab deviceId={device.entityId} startDate={startDate} endDate={endDate} />;
    const renderAnomalies = () => <AnomaliesTab deviceId={device.deviceId} startDate={startDate} endDate={endDate} />;
    const renderCrashes = () => (
        <CrashesTab
            device={device}
            startDate={startDate}
            endDate={endDate}
            timeSelectOption={timeSelectOption}
            refreshEntitiesFilter={refreshEntitiesFilter}
        />
    );
    const renderTraces = () => (
        <TracesTab device={device} startDate={startDate} endDate={endDate} timeSelectOption={timeSelectOption} />
    );
    const renderPerformance = () => (
        <PerformanceTab
            deviceId={deviceId}
            device={device}
            deviceDefinition={deviceDefinition}
            startDate={startDate}
            endDate={endDate}
            refreshEntitiesFilter={refreshEntitiesFilter}
        />
    );
    const renderHardwareEvents = () => (
        <HardwareEventsTab
            deviceId={deviceId}
            device={device}
            deviceDefinition={deviceDefinition}
            startDate={startDate}
            endDate={endDate}
            refreshEntitiesFilter={refreshEntitiesFilter}
        />
    );
    const renderRemoteView = () => (
        <RemoteViewTab device={device} deviceDefinition={deviceDefinition} startDate={startDate} endDate={endDate} />
    );

    return (
        <div className={classes.container}>
            <LinuxTabs
                history={history}
                renderOverview={renderOverview}
                renderAlerts={renderAlerts}
                renderAnomalies={renderAnomalies}
                renderCrashes={renderCrashes}
                renderTraces={renderTraces}
                renderPerformance={renderPerformance}
                renderHardwareEvents={renderHardwareEvents}
                renderRemoteView={renderRemoteView}
            />
        </div>
    );
}

export const DeviceInventoryLinuxView: React.FC<DeviceInventoryLinuxViewProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DeviceInventoryLinuxViewComponent));
