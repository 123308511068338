import React from "react";

import { SternumIconProps } from "./SternumIcon.types";

export function DiamondIcon({ color, width = 12, height = 11, ...restProps }: SternumIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 12 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...restProps}
        >
            <path
                d="M5.70801 10.2744L0 3.4248L1.7124 0H9.70361L11.416 3.4248L5.70801 10.2744ZM4.10977 2.9967H7.30625L6.236 0.856201H5.18002L4.10977 2.9967ZM5.27991 8.43358V3.85291H1.46981L5.27991 8.43358ZM6.13611 8.43358L9.9462 3.85291H6.13611V8.43358ZM8.26234 2.9967H10.2031L9.13281 0.856201H7.19209L8.26234 2.9967ZM1.21295 2.9967H3.15367L4.22393 0.856201H2.2832L1.21295 2.9967Z"
                fill={color || "currentColor"}
            />
        </svg>
    );
}
