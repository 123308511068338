import { Checkbox, FormControlLabel, Icon, Popover, Typography, WithStyles, withStyles } from "@material-ui/core";
import classNames from "classnames";
import * as React from "react";
import ConfirmationDialog from "../../../shared_components/ConfirmationDialog/ConfirmationDialog";
import deployToProductionDialogStyle from "./DeployToProductionDialogStyle";

interface AppState {
    cleanHistoricalData: boolean;
    anchorElement: any;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof deployToProductionDialogStyle> {
    open: boolean;
    title: string;
    body: string;
    handleConfirmationDialog: (dialogResult: boolean, checkboxResult: boolean) => void;
}

class DeployToProductionDialog extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            cleanHistoricalData: true,
            anchorElement: null,
        };
    }

    /**
     * Get result from confirmation dialog
     */
    private handleConfirmationDialog = (result: boolean) => {
        const cleanData = this.state.cleanHistoricalData;
        this.setState({ cleanHistoricalData: false }, () => {
            this.props.handleConfirmationDialog(result, cleanData);
        });
    };

    /**
     * Change checkbox state
     */
    private checkBoxClick = (event) => {
        this.setState({ cleanHistoricalData: !this.state.cleanHistoricalData });
    };

    /**
     * Close information popover
     */
    private closePopover = (event) => {
        this.setState({ anchorElement: null });
    };

    /**
     * Open information popover
     */
    private openPopover = (event) => {
        this.setState({ anchorElement: event.currentTarget });
    };
    render() {
        const { classes } = this.props;
        return (
            <ConfirmationDialog
                open={this.props.open}
                title={this.props.title}
                body={this.props.body}
                handleCancel={this.handleConfirmationDialog}
                handleApprove={this.handleConfirmationDialog}
            >
                <div className={classNames(classes.flexVMiddle)}>
                    <FormControlLabel
                        className={classNames(classes.checkboxPadding)}
                        control={
                            <Checkbox
                                checked={this.state.cleanHistoricalData}
                                onChange={this.checkBoxClick}
                                name="cleanHistoricalData"
                                color="primary"
                            />
                        }
                        label="Delete existing data"
                    />
                    <Icon
                        className={classNames("fas fa-info-circle", classes.cursorPointer, classes.marginLeftXs)}
                        fontSize={"small"}
                        classes={{
                            fontSizeSmall: classes.infoCircleIconSize,
                        }}
                        onClick={this.openPopover}
                    />
                </div>
                {/* Explanation popover */}
                <Popover
                    id="explanation-popover"
                    open={!!this.state.anchorElement}
                    anchorEl={this.state.anchorElement}
                    onClose={this.closePopover}
                    elevation={10}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                >
                    <Typography className={classNames(classes.padding, classes.explanationTypography)}>
                        {"All data associated to this device profile will be deleted."}
                    </Typography>
                </Popover>
            </ConfirmationDialog>
        );
    }
}

export default withStyles(deployToProductionDialogStyle)(DeployToProductionDialog);
