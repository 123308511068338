import FieldType from "../state/FieldType";
import InputComponentType from "../state/InputComponentType";

/**
 * Configuration for an input value of a sternum query.
 */
class SternumQueryInputValueConfiguration {
    /**
     * Constructor.
     */
    constructor(
        public inputType: FieldType,
        public inputComponentType: InputComponentType,
        public dropdownOptions?: { value: string; label: string }[]
    ) {}
}
export default SternumQueryInputValueConfiguration;
