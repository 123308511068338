import { red } from "@material-ui/core/colors";
import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle, { SIDEBAR_EXPANDED_WIDTH, SIDEBAR_NORMAL_WIDTH } from "../CommonStyle";

const sideBarStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        toolbar: theme.mixins.toolbar,

        userOptionsMenu: {
            borderRadius: 20,
            boxShadow: "0px 6px 250px 0px #0000001F",

            "& .MuiMenu-list": {
                padding: theme.spacing(3, 0),

                "& .MuiListItem-button:hover": {
                    background: "#FFF1F5",
                },
            },
        },

        // Side bar menu.
        drawerPaper: {
            backgroundColor: "#1D489B",
            boxShadow: "0px 0px 23px rgba(231, 232, 235, 0.25)",

            position: "fixed",
            whiteSpace: "nowrap",
            width: SIDEBAR_EXPANDED_WIDTH,
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.easeInOut,
                duration: theme.transitions.duration.enteringScreen,
            }),

            "&.mod-danger": {
                backgroundImage: `linear-gradient(${red[700]}, ${red[700]})`,
            },

            // hide scrollbar
            "scrollbar-width": "none",
            "-ms-overflow-style": "none",
            "&::-webkit-scrollbar": {
                display: "none",
            },

            zIndex: 1,
        },

        // Side bar closed state.
        drawerPaperClose: {
            overflowX: "hidden" as "hidden",
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: SIDEBAR_NORMAL_WIDTH,
            [theme.breakpoints.up("sm")]: {
                width: 60,
            },
        },

        // Container of the company logo.
        companyLogoContainer: {
            flexGrow: 0,
            flexShrink: 0,
            flexBasis: theme.spacing(13),
            display: "flex",
            margin: "5px auto 0 auto",
            alignItems: "center",
            gap: 10,

            "&.sidebarOpen": {
                marginLeft: 16,
            },
        },

        // Logo content.
        companyLogoTypography: {
            color: theme.palette.common.white,
        },

        versionNumber: {
            color: "#6F96E4",
            cursor: "pointer",
            fontSize: "1rem",

            "&:hover": {
                textDecoration: "underline",
            },
        },
        versionNumberContainer: {
            marginLeft: 8,
        },

        // Client indication container.
        clientIndicationContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },

        // Client indication text.
        clientTypography: {
            color: theme.palette.common.white,
            fontSize: "1rem",
        },

        // Container of menu items.
        menuItemsContainer: {
            flex: 1,
            display: "flex",
            flexDirection: "column" as "column",
        },

        issuesCountBadge: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "auto",
            marginRight: 10,
            width: 26,
            height: 26,
            borderRadius: "50%",
            background: "#E7004C",
        },

        issuesCountText: {
            color: "#fff",
            fontWeight: "bold",
            fontSize: "10px",
        },

        // Single item in the menu.
        menuItemLink: {
            // Clearing any default style given by a html element.
            "& svg": {
                color: "#6F96E4",
            },

            color: "inherit",
            textDecoration: "inherit",
            "&:hover, &:focus": {
                color: "inherit",
                textDecoration: "inherit",
            },
        },

        menuFrameItemLink: {
            textDecoration: "none",
        },

        // Active state of menu item.
        menuItemLinkActive: {
            position: "relative",

            "& p": {
                color: theme.palette.common.white,
            },

            "& svg": {
                color: "#fff",
            },

            "&:after": {
                position: "absolute",
                content: "''",
                top: "50%",
                right: 5,
                transform: "translateY(-50%)",
                width: 3,
                height: 24,
                borderRadius: 24,
                background: theme.palette.common.white,
            },
        },

        // Menu item title.
        menuItemLinkTypography: {
            color: "#6F96E4",
            fontSize: "1rem",
            fontWeight: 500,
        },

        menuItemLinkIcon: {
            color: theme.palette.common.white,
        },

        // User avatar in user indication.
        userAvatar: {
            color: theme.palette.common.white,
        },

        bottomDivider: {
            width: "80%",
            margin: "0 auto",
            background: "#2D58AE",
        },

        // Board icon.
        boardIcon: {
            fontSize: "20px",
        },

        // User name.
        userTypography: {
            color: "#fff",
            fontSize: "1rem",
            fontWeight: 500,
        },

        // Menu logout top bottom padding.
        menuItemLogoutTopBottomPadding: {
            paddingTop: "5px!important",
            paddingBottom: "5px!important",
        },

        // Menu logout left right padding.
        menuItemLogoutLeftRightPadding: {
            paddingRight: 15,
            paddingLeft: 15,
        },

        userOptionMenuItemText: {
            fontSize: "1rem",
        },

        userOptionMenuItemAllBoards: {
            color: "#E1034C",
        },

        menuItemLogoutBoxSizing: {
            boxSizing: "content-box",
            height: "30px",
        },

        boardsPageIcon: {
            fontSize: "0.8125rem",
            paddingRight: theme.spacing(5),
        },
        sidebarNavLinkListItemPadding: {
            paddingTop: "18px !important",
            paddingBottom: "18px !important",
        },
    });

export default sideBarStyle;
