import { makeStyles } from "@material-ui/styles";

const connectorColor = "#E7EEF6";
const maxTitleWidth = "55px";

export const useGraphItemStyle = makeStyles({
    graphItemContainer: {
        position: "relative",
        display: "grid",
        gridTemplateColumns: "auto auto",
        gridTemplateRows: "auto auto auto",
        gridTemplateAreas: "'topTitle .' 'main inlineTitle' 'bottomTitle .'",
        justifyItems: "center",
        alignItems: "center",
        gap: 4,
        color: "#999999",
    },
    graphItem: {
        gridArea: "main",
        width: 24,
        height: 24,
        borderRadius: 24,
        backgroundColor: "#1B6FDE",
        zIndex: 1,
    },
    graphItemWithClick: {
        cursor: "pointer",
    },
    graphItemSelected: {
        border: "2px solid #E7004C",
    },
    graphItemTitleInline: {
        gridArea: "inlineTitle",
        maxWidth: maxTitleWidth,
        overflow: "hidden",
        textOverflow: "ellipsis",
        direction: "rtl",
    },
    graphItemInnerConnector: {
        position: "absolute",
        top: "50%",
        left: 0,
        right: 0,
        transform: "translateY(-50%)",
        height: 2,
        backgroundColor: connectorColor,
    },
    graphItemTitleTop: {
        gridArea: "topTitle",
        maxWidth: maxTitleWidth,
        overflow: "hidden",
        textOverflow: "ellipsis",
        direction: "rtl",
    },
    graphItemTitleBottom: {
        gridArea: "bottomTitle",
        visibility: "hidden",
    },
    tooltipTitle: {
        fontWeight: 600,
    },
});
