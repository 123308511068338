import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

import { commonSignupManager } from "../Signup/SignupManager.style";

export const useStyle = makeStyles((theme: Theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        minHeight: "100vh",
        fontFamily: "Inter",

        [`@media (max-width: ${commonSignupManager.mediaQueryMaxWidth})`]: {
            "&[data-show-background-decoration='true']": {
                background: "#DBDBFC",
            },
        },
    },

    backgroundRectangleContainer: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        height: "100vh",
        overflow: "hidden",
        zIndex: -1,

        [`@media (max-width: ${commonSignupManager.mediaQueryMaxWidth})`]: {
            display: "none",
        },
    },

    backgroundRectangle: {
        position: "absolute",
        bottom: "45%",
        left: "-100%",
        right: "-100%",
        height: "100vmax",
        transformOrigin: "50% 100%",
        transform: "rotate(-12deg)",
        background: "#DBDBFC",
        zIndex: -1,
    },

    header: {
        padding: "20px 40px",
    },

    content: {
        display: "flex",
        flexFlow: "column",
        flex: 1,
    },
}));
