import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const useStyle = makeStyles((theme: Theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
        fontFamily: "Inter",
    },

    backgroundRectangle: {
        position: "absolute",
        top: 0,
        left: "50%",
        width: "200%",
        height: "150%",
        transformOrigin: "center",
        transform: "rotate(-12deg) translate(-50%, -70%)",
        background: "#231434",
        zIndex: -2,
    },

    globeImage: {
        position: "absolute",
        top: "10%",
        left: "5%",
        zIndex: -1,
        width: "40%",

        "& img": {
            maxWidth: "100%",
        },
    },

    header: {
        padding: "20px 40px",
    },

    content: {
        flex: 1,
    },

    logo: {},
}));
