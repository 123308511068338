import React from "react";

import {
    GrandWithCertificateIcon,
    HandWithCheckedShieldIcon,
    LaptopWithCheckedShieldIcon,
    WinnerPodiumIcon,
} from "../../SUI/SternumIcon";

export const sternumByNumbers: {
    id: string;
    IconComponent: React.ElementType;
    title: string;
    description: React.ReactNode;
}[] = [
    {
        id: "startups",
        IconComponent: WinnerPodiumIcon,
        title: "#1",
        description: "Startup in the IoT Solutions World Congress Startups Competition",
    },
    {
        id: "granded",
        IconComponent: GrandWithCertificateIcon,
        title: "3",
        description: "Granted Patents",
    },
    {
        id: "benchmark",
        IconComponent: HandWithCheckedShieldIcon,
        title: "96.5%",
        description: "Prevention Rates Tested in Industry Benchmarks",
    },
    {
        id: "protectedDevices",
        IconComponent: LaptopWithCheckedShieldIcon,
        title: "300K+",
        description: "Number of Sternum-protected Devices",
    },
    {
        id: "vulnerabilities",
        IconComponent: HandWithCheckedShieldIcon,
        title: "300+",
        description: "Vulnerabilities Prevented as Zero-Days",
    },
    {
        id: "CWE",
        IconComponent: LaptopWithCheckedShieldIcon,
        title: "100+ CWE’s covered",
        description: "Deterministic Protection Against Entire Classes of Critical Vulnerabilities",
    },
];
