import React from "react";
import classNames from "classnames";

import { useStyle } from "./PremiumUpgradeContainer.style";
import { SternumLogo } from "../../SUI/SternumIcon";
import globeImg from "../../../images/globe_premium.png";

export interface PremiumUpgradeContainerProps {
    className?: string;
    children?: React.ReactNode;
}

export function PremiumUpgradeContainer({ className, children }: PremiumUpgradeContainerProps) {
    const classes = useStyle();

    return (
        <div className={classNames(classes.container, className)}>
            <div className={classes.backgroundRectangle} />
            <div className={classes.globeImage}>
                <img src={globeImg} />
            </div>

            <div className={classNames(classes.content)}>{children}</div>
        </div>
    );
}
