import { Action } from "redux";
import ActionType from "../ActionType";

export interface FetchedPendingIssuesCountSuccessfullyAction extends Action<ActionType> {
    type: ActionType.FetchedPendingIssuesCountSuccessfully;
    pendingIssuesCount: number;
}

export function fetchedPendingIssuesCountSuccessfullyAction(
    pendingIssuesCount: number
): FetchedPendingIssuesCountSuccessfullyAction {
    return {
        type: ActionType.FetchedPendingIssuesCountSuccessfully,
        pendingIssuesCount: pendingIssuesCount,
    };
}
