import * as React from "react";
import classNames from "classnames";
import { Badge, CircularProgress, Icon } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import Popover from "@material-ui/core/Popover";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import FilterListIcon from "@material-ui/icons/FilterList";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";

import HashSet from "../../../../lib/infra/HashSet";
import ServiceWire from "../../../../lib/services/ServiceWire";
import ListFilter from "../../../../lib/state/ListFilter";
import ServerEntityType from "../../../../lib/state/ServerEntityType";
import TableColumnHeaderInfo from "../../../../lib/state/TableColumnHeaderInfo";
import TableToolbarDisplayState from "../../../../lib/state/TableToolbarDisplayState";
import ViewColumnsPopover from "../../../ViewColumnsPopover/ViewColumnsPopover";
import VisualisationButton from "../../../VisualisationButton/VisualisationButton";
import { Chip } from "../../../SettingsPage/Chip";
import { CheckmarkIcon, CrossIcon, DiamondIcon, DownloadIcon, RefreshIcon, SearchIcon } from "../../SternumIcon";
import SternumTablePagination from "../SternumTablePagination/SternumTablePagination";
import sternumTableToolbarStyle from "./SternumTableToolbarStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    filterListIsActive: boolean;
    filterListPopoverOpen: boolean;
    filterListPopoverAnchorElement;

    viewColumnsIsActive: boolean;
    viewColumnsPopoverOpen: boolean;
    viewColumnsPopoverAnchorElement;

    currentRefreshCycle: number;

    activityBarIsActive: boolean;

    searchIsActive: boolean;
    searchText: string;

    currentPageNumber: number;

    listFilter: ListFilter;
    viewedColumnsSet: HashSet;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof sternumTableToolbarStyle> {
    // Changeable props (will cause a re-render).
    disabled: boolean;
    totalItemCount: number;

    isFilterActive: boolean;

    dynamicPageSize?: number;

    // Non-changeable props (will not cause a re-render).
    columnHeaders: TableColumnHeaderInfo[];
    viewedColumnsSet: HashSet;
    listFilter: ListFilter;
    pageSize: number;
    pageNumber?: number;

    toolbarState?: TableToolbarDisplayState;

    isExpanded?: boolean;
    onExpandToggle?: () => void;

    filterComponentProvider?: (onFilterChanged: (listFilter: ListFilter) => void) => any;
    onFilterChanged?: (listFilter: ListFilter) => void;
    onViewedColumnsChanged: (viewedColumnsSet: HashSet) => void;
    onSearchTextChanged: (searchText: string) => void;
    onPageChanged: (pageNumber: number) => void;
    onRefreshClicked: () => void;

    includeActivityInToolbar?: boolean;
    onActivityBarToggled?: (isDisplayed: boolean) => void;
    newEvents?: number;
    onNewVisualizationClicked?: () => void;
    onExistingVisualisationClicked?: (visualisationId: string) => void;
    onResolveAllIssuesClicked?: () => void;
    onExportToCsvClicked?: () => void;
    onExportToXlsxClicked?: () => void;
    displayLoadingIcon?: boolean;
    hidePagination?: boolean;
    onResetShowInContext?: () => unknown;
}

/**
 * Displays a sternum-styled table.
 */
class SternumTableToolbar extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */

    searchElement;

    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            filterListIsActive: !!this.props.isFilterActive,
            filterListPopoverOpen: false,
            filterListPopoverAnchorElement: null,

            viewColumnsIsActive: false,
            viewColumnsPopoverOpen: false,
            viewColumnsPopoverAnchorElement: null,

            currentRefreshCycle: 1,

            activityBarIsActive: false,

            searchIsActive: false,
            searchText: "",

            listFilter: this.props.listFilter,
            currentPageNumber: 1,

            viewedColumnsSet: this.props.viewedColumnsSet,
        };
        this.searchElement = React.createRef();
    }

    /**
     * Returns whether anything changed in props need to execute a render.
     */
    shouldComponentUpdate(nextProps: Readonly<AppProps>, nextState: Readonly<AppState>, nextContext: any): boolean {
        // Note: the order of comparison is performance-wise. From the most costly comparison to the most cheap one.

        if (
            nextProps.disabled !== this.props.disabled ||
            nextProps.totalItemCount !== this.props.totalItemCount ||
            nextProps.dynamicPageSize !== this.props.dynamicPageSize
        ) {
            return true;
        }

        if (
            nextState.filterListIsActive !== this.state.filterListIsActive ||
            nextState.filterListPopoverOpen !== this.state.filterListPopoverOpen ||
            nextState.filterListPopoverAnchorElement !== this.state.filterListPopoverAnchorElement ||
            nextState.activityBarIsActive !== this.state.activityBarIsActive ||
            nextState.viewColumnsIsActive !== this.state.viewColumnsIsActive ||
            nextState.viewColumnsPopoverOpen !== this.state.viewColumnsPopoverOpen ||
            nextState.viewColumnsPopoverAnchorElement !== this.state.viewColumnsPopoverAnchorElement ||
            nextState.searchIsActive !== this.state.searchIsActive ||
            nextState.searchText !== this.state.searchText ||
            nextState.currentPageNumber !== this.state.currentPageNumber ||
            nextProps.newEvents !== this.props.newEvents ||
            nextProps.displayLoadingIcon !== this.props.displayLoadingIcon ||
            nextProps.isExpanded !== this.props.isExpanded
        ) {
            return true;
        }

        if (
            (!nextState.listFilter && this.state.listFilter) ||
            (nextState.listFilter && !this.state.listFilter) ||
            (nextState.listFilter &&
                this.state.listFilter &&
                nextState.listFilter.isDifferentFrom(this.state.listFilter))
        ) {
            return true;
        }

        if (nextState.viewedColumnsSet.isDifferentFrom(this.state.viewedColumnsSet)) {
            return true;
        }

        return false;
    }

    /**
     * Occurs once the component is about to receive props.
     */
    UNSAFE_componentWillReceiveProps(nextProps: Readonly<AppProps>, nextContext: any): void {
        if (nextProps.listFilter && nextProps.listFilter.isDifferentFrom(this.props.listFilter)) {
            this.setState({
                listFilter: nextProps.listFilter,
                filterListIsActive: nextProps.listFilter.isActive(),
            });
        }
    }

    /**
     * Is invoked immediately after updating occurs. This method is not called for the initial render.
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        // Set focus on search field after component render and search is active.
        if (this.state.searchIsActive) {
            if (this.searchElement) {
                this.searchElement.value = this.state.searchText;
                this.searchElement.focus();
            }
        }
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;
        const newEvents = this.props.newEvents || 0;

        return (
            <div className={classes.root} role="presentation" aria-label="sternum table toolbar">
                {/* Refresh button */}
                {(!this.checkIfToolBarPropExists() || this.props.toolbarState.displayRefresh) && (
                    <div
                        className={classNames(classes.flexVMiddle, classes.toolbarItemContainer)}
                        onClick={() => !this.props.disabled && this.refresh()}
                    >
                        {/* Icon button */}
                        <IconButton
                            aria-label="Refresh"
                            color="primary"
                            disabled={this.props.disabled}
                            className={classNames(classes.toolbarItemIconContainer)}
                        >
                            <RefreshIcon className={classNames(classes.inactiveIcon)} />
                        </IconButton>

                        {/* Refresh title */}
                        <Badge badgeContent={newEvents} color="primary" classes={{ badge: classes.badge }}>
                            <div className={classNames(newEvents > 0 && classes.badgeBuffer)}>
                                <Typography
                                    className={classNames(
                                        !this.props.disabled && classes.cursorPointer,
                                        classes.toolbarItemTitle,
                                        classes.inactiveIcon
                                    )}
                                >
                                    Refresh
                                </Typography>
                            </div>
                        </Badge>
                    </div>
                )}

                {/* Divider */}
                {(!this.checkIfToolBarPropExists() || this.props.toolbarState.displayRefresh) &&
                    (!this.checkIfToolBarPropExists() || this.props.toolbarState.displayFilters) && (
                        <div className={classes.listToolbarDivider} />
                    )}

                {/* Filter button */}
                {(!this.checkIfToolBarPropExists() || this.props.toolbarState.displayFilters) && (
                    <div
                        className={classNames(classes.flexVMiddle, classes.toolbarItemContainer)}
                        onClick={(event) => !this.props.disabled && this.handleFilterListPopoverOpened(event)}
                    >
                        {/* Icon button */}
                        <IconButton
                            aria-owns={this.state.filterListPopoverOpen ? "filter-list-popover" : undefined}
                            aria-haspopup="true"
                            aria-label="Filter"
                            color="primary"
                            disabled={this.props.disabled}
                            className={classNames(classes.toolbarItemIconContainer)}
                        >
                            <FilterListIcon
                                className={classNames(
                                    classes.toolbarItemIcon,
                                    this.state.filterListIsActive ? classes.activeIcon : classes.inactiveIcon
                                )}
                            />
                        </IconButton>

                        {/* Filter title */}
                        <Typography
                            className={classNames(
                                !this.props.disabled && classes.cursorPointer,
                                classes.toolbarItemTitle,
                                this.state.filterListIsActive ? classes.activeIcon : classes.inactiveIcon
                            )}
                        >
                            Filter
                        </Typography>
                    </div>
                )}

                {/* Divider */}
                {(!this.checkIfToolBarPropExists() || this.props.toolbarState.displayColumnOptions) && (
                    <div className={classes.listToolbarDivider} />
                )}

                {/* Show columns button */}
                {(!this.checkIfToolBarPropExists() || this.props.toolbarState.displayColumnOptions) && (
                    <div
                        className={classNames(classes.flexVMiddle, classes.toolbarItemContainer)}
                        onClick={(event) => !this.props.disabled && this.handleViewColumnsPopoverOpened(event)}
                    >
                        {/* Icon button */}
                        <IconButton
                            aria-label="Select Columns"
                            color="primary"
                            aria-owns={this.state.viewColumnsPopoverOpen ? "view-columns-popover" : undefined}
                            aria-haspopup="true"
                            disabled={this.props.disabled}
                            className={classNames(classes.toolbarItemIconContainer)}
                        >
                            <ViewColumnIcon
                                className={classNames(
                                    classes.toolbarItemIcon,
                                    this.state.viewColumnsIsActive ? classes.activeIcon : classes.inactiveIcon
                                )}
                            />
                        </IconButton>

                        {/* Columns title */}
                        <Typography
                            className={classNames(
                                !this.props.disabled && classes.cursorPointer,
                                classes.toolbarItemTitle,
                                this.state.viewColumnsIsActive ? classes.activeIcon : classes.inactiveIcon
                            )}
                        >
                            Columns
                        </Typography>
                    </div>
                )}

                {/* Divider */}
                {(!this.checkIfToolBarPropExists() || this.props.toolbarState.displayAddVisualization) &&
                    (!this.checkIfToolBarPropExists() || this.props.toolbarState.displayColumnOptions) && (
                        <div className={classes.listToolbarDivider} />
                    )}

                {/* Visualization */}
                {(!this.checkIfToolBarPropExists() || this.props.toolbarState.displayAddVisualization) && (
                    <div className={classNames(classes.toolbarItemContainer)}>
                        <VisualisationButton
                            disabled={this.props.disabled}
                            onNewVisualisationClicked={() => {
                                this.props.onNewVisualizationClicked();
                            }}
                            onExistingVisualisationClicked={(visualisationId) => {
                                this.props.onExistingVisualisationClicked(visualisationId);
                            }}
                        />
                    </div>
                )}

                {/* Divider */}
                {(!this.checkIfToolBarPropExists() ||
                    (this.props.toolbarState.displayAddVisualization &&
                        (this.props.toolbarState.exportToCSV || this.props.toolbarState.exportToXLSX))) && (
                    <div className={classes.listToolbarDivider} />
                )}

                {/* Export to CSV */}
                {(!this.checkIfToolBarPropExists() || this.props.toolbarState.exportToCSV) && (
                    <div
                        className={classNames(classes.flexVMiddle, classes.toolbarItemContainer)}
                        onClick={() => this.onExportCsvClicked()}
                    >
                        {/* Icon button */}
                        <IconButton
                            color="primary"
                            disabled={this.props.disabled || this.props.toolbarState.showPremium}
                            className={classNames(classes.toolbarItemIconContainer)}
                        >
                            <DownloadIcon width={20} height={20} color="#8B949E" />
                        </IconButton>

                        {/* Export to CSV title */}
                        <Typography
                            className={classNames(
                                !(this.props.disabled || this.props.toolbarState.showPremium) && classes.cursorPointer,
                                classes.toolbarItemTitle,
                                classes.inactiveIcon
                            )}
                        >
                            Export CSV
                        </Typography>

                        {/* {this.props.displayLoadingIcon && (
                            <div className={classes.paddingLeft}>
                                <CircularProgress size={15} color="inherit" />
                            </div>
                        )} */}
                    </div>
                )}

                {/* Export to XLSX */}
                {(!this.checkIfToolBarPropExists() || this.props.toolbarState.exportToXLSX) && (
                    <div
                        className={classNames(classes.flexVMiddle, classes.toolbarItemContainer)}
                        onClick={() => this.onExportXlsxClicked()}
                    >
                        {/* Icon button */}
                        <IconButton
                            color="primary"
                            disabled={this.props.disabled}
                            className={classNames(classes.toolbarItemIconContainer)}
                        >
                            <DownloadIcon width={20} height={20} color="#8B949E" />
                        </IconButton>

                        {/* Export to XLSX title */}
                        <Typography
                            className={classNames(
                                !this.props.disabled && classes.cursorPointer,
                                classes.toolbarItemTitle,
                                classes.inactiveIcon
                            )}
                        >
                            Export XLSX
                        </Typography>

                        {this.props.displayLoadingIcon && (
                            <div className={classes.paddingLeft}>
                                <CircularProgress size={15} color="inherit" />
                            </div>
                        )}
                    </div>
                )}

                {(!this.checkIfToolBarPropExists() || this.props.onResetShowInContext) && (
                    <div className={classes.resetShowInContext} onClick={this.props.onResetShowInContext}>
                        <CrossIcon /> Reset View in Context
                    </div>
                )}

                {/* Divider */}
                {(!this.checkIfToolBarPropExists() || this.props.toolbarState.displayAddVisualization) &&
                    (!this.checkIfToolBarPropExists() || this.props.toolbarState.displayColumnOptions) && (
                        <div className={classes.listToolbarDivider} />
                    )}

                {/* Resolve all */}
                {(!this.checkIfToolBarPropExists() || this.props.toolbarState.displayResolveAllIssues) &&
                    ServiceWire.getAuthorizationService().canEdit(ServerEntityType.ISSUE) && (
                        <div
                            className={classNames(
                                classes.flexVMiddle,
                                classes.toolbarItemContainer,
                                classes.resolveAllContainer,
                                !this.props.disabled && classes.cursorPointer
                            )}
                            onClick={() => this.onResolveAllIssuesClicked()}
                        >
                            <Typography className={classNames(classes.toolbarItemTitle)}>Resolve All</Typography>

                            <div className={classNames(classes.toolbarItemIconContainer)}>
                                <CheckmarkIcon />
                            </div>
                        </div>
                    )}

                {this.props.toolbarState.showPremium && (
                    <div
                        className={classNames(classes.flexVMiddle, classes.toolbarItemContainer)}
                        onClick={() => this.onExportXlsxClicked()}
                    >
                        <Chip>
                            <DiamondIcon /> Premium
                        </Chip>
                    </div>
                )}

                {/* Divider */}
                {this.props.includeActivityInToolbar && <div className={classes.listToolbarDivider} />}

                {/* Activity button */}
                {this.props.includeActivityInToolbar && (
                    <div
                        className={classNames(classes.flexVMiddle, classes.toolbarItemContainer)}
                        onClick={(event) => !this.props.disabled && this.handleOpenActivityBar(event)}
                    >
                        {/* Icon button */}
                        <IconButton
                            color="primary"
                            disabled={this.props.disabled}
                            className={classNames(classes.toolbarItemIconContainer)}
                        >
                            <Icon
                                classes={{
                                    fontSizeSmall: classes.activityIconSize,
                                }}
                                fontSize={"small"}
                                className={classNames(
                                    "fas fa-chart-line",
                                    this.state.activityBarIsActive ? classes.activeIcon : classes.inactiveIcon
                                )}
                            />
                        </IconButton>

                        {/* Columns title */}
                        <Typography
                            className={classNames(
                                !this.props.disabled && classes.cursorPointer,
                                classes.toolbarItemTitle,
                                this.state.activityBarIsActive ? classes.activeIcon : classes.inactiveIcon
                            )}
                        >
                            Activity
                        </Typography>
                    </div>
                )}

                {/* Divider */}
                {(!this.checkIfToolBarPropExists() ||
                    (this.props.toolbarState.displaySearch &&
                        (this.props.toolbarState.displayFilters ||
                            this.props.toolbarState.displayRefresh ||
                            this.props.toolbarState.displayColumnOptions ||
                            this.props.toolbarState.exportToCSV ||
                            this.props.toolbarState.displayAddVisualization))) && (
                    <div className={classNames(classes.listToolbarDivider, "with-extra-margin")} />
                )}

                {/* Search icon */}
                {(!this.checkIfToolBarPropExists() || this.props.toolbarState.displaySearch) && (
                    <SearchIcon
                        className={classNames(
                            classes.searchIcon,
                            this.state.searchIsActive ? classes.activeIcon : classes.inactiveIcon
                        )}
                    />
                )}

                {/* Search input */}
                {(!this.checkIfToolBarPropExists() || this.props.toolbarState.displaySearch) && (
                    <Input
                        placeholder={
                            this.props.toolbarState && this.props.toolbarState.searchDisplayString
                                ? this.props.toolbarState.searchDisplayString
                                : "Search..."
                        }
                        disableUnderline={true}
                        onChange={this.handleSearchInputChanged.bind(this)}
                        onFocus={this.handleSearchInputFocused.bind(this)}
                        onBlur={this.handleSearchInputBlurred.bind(this)}
                        classes={{ input: classes.searchInput }}
                        disabled={this.props.disabled}
                        //inputRef={this.searchElement}
                        inputProps={{ ref: (input) => (this.searchElement = input) }}
                        //autoFocus={true}
                    />
                )}

                {/* Divider */}
                {(!this.checkIfToolBarPropExists() ||
                    (this.props.toolbarState.displayFilters && !this.props.toolbarState.doNotDisplayClearFilter)) &&
                    this.state.filterListIsActive &&
                    this.state.listFilter &&
                    this.state.listFilter.isActive() && <div className={classes.listToolbarDivider} />}

                {/* Clear filter button */}
                {(!this.checkIfToolBarPropExists() ||
                    (this.props.toolbarState.displayFilters && !this.props.toolbarState.doNotDisplayClearFilter)) &&
                    this.state.filterListIsActive &&
                    this.state.listFilter &&
                    this.state.listFilter.isActive() && (
                        <div
                            className={classNames(classes.flexVMiddle, classes.toolbarItemContainer)}
                            onClick={(event) => !this.props.disabled && this.handleFilterApplied(null)}
                        >
                            {/* Icon button */}
                            <IconButton
                                color="primary"
                                disabled={this.props.disabled}
                                className={classNames(classes.toolbarItemIconContainer)}
                            >
                                <CloseIcon className={classNames(classes.toolbarItemIcon, classes.activeIcon)} />
                            </IconButton>

                            {/* Clear filter title */}
                            <Typography
                                className={classNames(
                                    !this.props.disabled && classes.cursorPointer,
                                    classes.toolbarItemTitle,
                                    classes.activeIcon
                                )}
                            >
                                Clear
                            </Typography>
                        </div>
                    )}

                {/* Grower to get the pagination to be at the end of the line */}
                <div className={classes.flexGrow} />

                {/* Pagination */}

                {!this.props.hidePagination && (
                    <SternumTablePagination
                        key={this.state.currentRefreshCycle}
                        disabled={this.props.disabled}
                        pageNumber={this.props.pageNumber}
                        pageSize={this.props.pageSize}
                        dynamicPageSize={this.props.dynamicPageSize}
                        totalItemCount={this.props.totalItemCount}
                        onPageChanged={this.props.onPageChanged}
                        toolbarState={this.props.toolbarState}
                    />
                )}

                {this.props.onExpandToggle && <div className={classes.listToolbarDivider} />}

                {/* Expand icon */}
                {(!this.checkIfToolBarPropExists() || this.props.onExpandToggle) && (
                    <IconButton onClick={this.props.onExpandToggle}>
                        {this.props.isExpanded ? (
                            <Icon className={classNames("fa fa-compress", classes.expandIcon)} />
                        ) : (
                            <Icon className={classNames("fa fa-expand", classes.expandIcon)} />
                        )}
                    </IconButton>
                )}

                {/* Filter list popover */}
                {!!this.props.filterComponentProvider && (
                    <Popover
                        id="filter-list-popover"
                        open={this.state.filterListPopoverOpen}
                        anchorEl={this.state.filterListPopoverAnchorElement}
                        onClose={this.handleFilterListPopoverClosed.bind(this)}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        transformOrigin={{ vertical: "top", horizontal: "left" }}
                        classes={{ paper: classes.popoverPaper }}
                    >
                        {this.props.filterComponentProvider((listFilter) => this.handleFilterApplied(listFilter))}
                    </Popover>
                )}

                {/* View columns popover */}
                <Popover
                    id="view-columns-popover"
                    open={this.state.viewColumnsPopoverOpen}
                    anchorEl={this.state.viewColumnsPopoverAnchorElement}
                    onClose={this.handleViewColumnsPopoverClosed.bind(this)}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    transformOrigin={{ vertical: "top", horizontal: "left" }}
                    classes={{ paper: classes.popoverPaper }}
                >
                    {/* Popover content */}
                    <ViewColumnsPopover
                        columnHeaders={this.props.columnHeaders}
                        viewedColumnsSet={this.state.viewedColumnsSet}
                        onViewedColumnsChanged={(newViewedColumnsSet) =>
                            this.handleViewedColumnsChanged(newViewedColumnsSet)
                        }
                    />
                </Popover>
            </div>
        );
    }

    /**
     * Occurs once the filter list popover is opened.
     */
    private handleFilterListPopoverOpened(event) {
        this.setState({
            filterListIsActive: true,
            filterListPopoverOpen: true,
            filterListPopoverAnchorElement: event.currentTarget,
        });
    }

    /**
     * Occurs once the user clicks on refresh.
     */
    private refresh() {
        this.setState(
            {
                currentRefreshCycle: this.state.currentRefreshCycle + 1,
            },
            () => {
                if (this.props.onRefreshClicked) {
                    this.props.onRefreshClicked();
                }
            }
        );
    }

    /**
     * Occurs on the click of the visualization button.
     */
    private onVisualizationClicked() {
        if (this.props.onNewVisualizationClicked) {
            this.props.onNewVisualizationClicked();
        }
    }

    /**
     * Occurs on the export csv click.
     */
    private onExportCsvClicked() {
        if (this.props.onExportToCsvClicked) {
            this.props.onExportToCsvClicked();
        }
    }

    /**
     * Occurs on the export xlsx click.
     */
    private onExportXlsxClicked() {
        if (this.props.onExportToXlsxClicked) {
            this.props.onExportToXlsxClicked();
        }
    }

    /**
     * Occurs on the click of the resolve all button.
     */
    private onResolveAllIssuesClicked() {
        if (this.props.onResolveAllIssuesClicked) {
            this.props.onResolveAllIssuesClicked();
        }
    }

    /**
     * Occurs once the show/hide columns popover is opened.
     */
    private handleViewColumnsPopoverOpened(event) {
        this.setState({
            viewColumnsIsActive: true,
            viewColumnsPopoverOpen: true,
            viewColumnsPopoverAnchorElement: event.currentTarget,
        });
    }

    /**
     * Occurs once the activity bar is toggled.
     */
    private handleOpenActivityBar(event) {
        this.setState(
            {
                activityBarIsActive: !this.state.activityBarIsActive,
            },
            () => {
                if (this.props.onActivityBarToggled) {
                    this.props.onActivityBarToggled(this.state.activityBarIsActive);
                }
            }
        );
    }

    /**
     * Occurs once the search input is changed.
     */
    private handleSearchInputChanged(event) {
        let searchText = event.target.value;

        this.setState(
            {
                searchText: searchText,
            },
            () => {
                if (this.props.onSearchTextChanged) {
                    this.props.onSearchTextChanged(searchText);
                }
            }
        );
    }

    /**
     * Occurs once the user focuses on the search input.
     */
    private handleSearchInputFocused() {
        this.setState({
            searchIsActive: true,
        });
    }

    /**
     * Occurs once the user blurred out from the search input.
     */
    private handleSearchInputBlurred() {
        this.setState({
            searchIsActive: !!(this.state.searchText && this.state.searchText.length),
        });
    }

    /**
     * Occurs once the filter list popover is closed.
     */
    private handleFilterListPopoverClosed() {
        this.setState({
            filterListIsActive: this.state.listFilter && this.state.listFilter.isActive(),
            filterListPopoverOpen: false,
            filterListPopoverAnchorElement: null,
        });
    }

    /**
     * Occurs once the view columns popover is closed.
     */
    private handleViewColumnsPopoverClosed() {
        this.setState({
            viewColumnsIsActive: this.state.viewedColumnsSet.length() !== this.props.columnHeaders.length,
            viewColumnsPopoverOpen: false,
            viewColumnsPopoverAnchorElement: null,
        });
    }

    /**
     * Occurs once the viewed columns are changed.
     */
    private handleViewedColumnsChanged(newViewedColumnsSet) {
        this.setState(
            {
                viewedColumnsSet: newViewedColumnsSet,
            },
            () => {
                if (this.props.onViewedColumnsChanged) {
                    this.props.onViewedColumnsChanged(newViewedColumnsSet);
                }
            }
        );
    }

    /**
     * Occurs once filter is applied.
     */
    private handleFilterApplied(listFilter) {
        this.setState(
            {
                filterListIsActive: listFilter && listFilter.isActive(),
                filterListPopoverOpen: false,
                listFilter: listFilter,
            },
            () => {
                if (this.props.onFilterChanged) {
                    this.props.onFilterChanged(listFilter);
                }
            }
        );
    }

    /**
     * Check if toolbar prop exists
     */

    private checkIfToolBarPropExists = () => {
        return !!this.props.toolbarState;
    };
}

export default withStyles(sternumTableToolbarStyle)(SternumTableToolbar);
