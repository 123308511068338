import React from "react";

import { SternumIconProps } from "./SternumIcon.types";

export const WindowsLogoIcon = React.forwardRef<SVGSVGElement, SternumIconProps>(function WindowsLogoIcon(
    { color, height = 28, width = 28, ...props },
    ref
) {
    return (
        <svg
            ref={ref}
            width={width}
            height={height}
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.1604 5.58778L23.9976 4.0061V13.517L13.1604 13.603V5.58778ZM12.1734 5.7334L4 6.84653L4.00747 13.6638L12.1769 13.6173L12.1734 5.7334ZM12.1758 22.4163L12.1695 14.5255L4.0059 14.4726L4.00636 21.2931L12.1758 22.4163ZM23.9975 24.0679L24 14.5998L13.1451 14.5821L13.1603 22.5383L23.9975 24.0679Z"
                fill={color || "currentColor"}
            />
        </svg>
    );
});
