import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../../../CommonStyle";

const sternumTablePaginationStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),
        resultsFromAndToTitle: {
            fontSize: 14,
            fontWeight: 500,
            color: "#999999",
            marginRight: theme.spacing(1),
        },
        arrowIcon: {
            padding: 4,
        },
    });

export default sternumTablePaginationStyle;
