import { Action } from "redux";
import ActionType from "../ActionType";

/**
 * Defines the data for the view cve action.
 */
export interface ViewCveAction extends Action<ActionType> {
    type: ActionType.ViewCve;
    cveId: string;
}

/**
 * Action creator for viewing a cve.
 */
export function viewCveAction(cveId: string): ViewCveAction {
    return {
        type: ActionType.ViewCve,
        cveId: cveId,
    };
}
