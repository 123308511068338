import React, { useRef, useState } from "react";
import classNames from "classnames";
import { useFormik } from "formik";
import { connect } from "react-redux";

import GoogleRecaptchaWrapper from "../../GoogleRecaptcha/GoogleRecaptcha";
import LoginStateEnum from "../../../lib/state/LoginStateEnum";
import { GlobalState } from "../../../lib/state/GlobalState";
import { SternumInputField } from "../../SUI/SternumInputField";
import SternumImprovedButton from "../../SUI/SternumImprovedButton/SternumImprovedButton";
import SternumBanner from "../../SUI/SternumBanner/SternumBanner";
import { AuthenticationContainer } from "../AuthenticationContainer";
import { useCommonStyle } from "../../CommonStyle";
import { useForgotPasswordStyle } from "./ForgotPassword.style";
import { useLoginManagerStyle } from "../LoginManager.style";
import ServiceWire from "../../../lib/services/ServiceWire";
import Utils from "../../../lib/infra/Utils";

export interface ForgotPasswordProps {
    nextStep: (nextStep: LoginStateEnum) => unknown;
}

interface FormValues {
    email: string;
}

const mapStateToProps = (state: GlobalState, ownProps: ForgotPasswordProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

interface ForgotPasswordPropsWithHOC extends ForgotPasswordProps, ReturnType<typeof mapDispatchToProps> {}

function ForgotPasswordComponent({ nextStep }: ForgotPasswordPropsWithHOC) {
    const reCaptchaElementRef = useRef();
    const classes = useForgotPasswordStyle();
    const classesLogin = useLoginManagerStyle();
    const classesCommon = useCommonStyle();

    const [errorMsg, setErrorMsg] = useState("");
    const [captchaToken, setCaptchaToken] = useState("");
    const [isEmailSent, setIsEmailSent] = useState(false);
    const formik = useFormik<FormValues>({
        initialValues: {
            email: "",
        },
        validate: (values) => {
            const errors: Partial<FormValues> = {};

            if (values.email.length < 1) {
                errors.email = "The field is required";
            }

            if (!Utils.isValidEmail(values.email)) {
                errors.email = "Email is not valid";
            }

            return errors;
        },
        onSubmit: async (values) => {
            setErrorMsg("");

            try {
                // Send email request
                await ServiceWire.getSternumService().forgotPasswordEmailStep(formik.values.email, captchaToken);

                setIsEmailSent(true);
            } catch (err) {
                // Handle login error types
                if (!err?.response?.status) {
                    return setErrorMsg("Internal server error");
                }

                let errorMsg = "";

                // Api limit error
                if (String(err.response.status) === "429") {
                    errorMsg = `You have reached the maximum number of requests allowed for forgot password operation.\n
                    Your account has been locked for approximately 20 minutes`;
                } else {
                    // Wrong authentication credentials
                    errorMsg = "The email you have entered is invalid.";
                }

                setErrorMsg(errorMsg);
            }
        },
    });

    const handleRecaptchaSuccess = (reCaptchaToken: string) => {
        // Here you will get the final reCaptchaToken
        setCaptchaToken(reCaptchaToken);
    };

    const handleRecaptchaError = () => {
        setCaptchaToken("");
    };

    const isSendEmailButtonDisabled = () => {
        return formik.isSubmitting || !formik.values.email || Object.keys(formik.errors).length > 0;
    };

    if (isEmailSent) {
        return (
            <AuthenticationContainer onBack={() => nextStep(LoginStateEnum.LOGIN)}>
                <h1 className={classNames(classesLogin.title)}>Forgot Password</h1>

                <div className={classNames(classes.errorMessageContainer)}>
                    We've send an email to <span className={classes.titleDecorated}>{formik.values.email}</span>
                    <br />
                    with instructions to reset your password.
                </div>
            </AuthenticationContainer>
        );
    }

    return (
        <AuthenticationContainer onBack={() => nextStep(LoginStateEnum.LOGIN)}>
            <h1 className={classNames(classesLogin.title)}>Forgot Password</h1>

            <div className={classNames(classesLogin.authFieldsContainer)}>
                {errorMsg && (
                    <div className={classNames(classesCommon.marginBottomXLarge, classesCommon.fullWidth)}>
                        <SternumBanner messageType="error" message={errorMsg} onCloseClick={() => setErrorMsg("")} />
                    </div>
                )}

                {/* Email input */}
                <SternumInputField
                    label={"Email"}
                    inputValue={formik.values.email}
                    onFieldChange={(e) => formik.setFieldValue("email", e.target.value)}
                    addMargin={true}
                    isTouched={!!formik.touched.email}
                    error={!!formik.errors.email}
                    helperText={formik.touched.email ? formik.errors.email : undefined}
                    onTouch={() => formik.setFieldTouched("email", true)}
                    required={true}
                />

                {/** Google reCaptcha */}
                <GoogleRecaptchaWrapper
                    elementRef={reCaptchaElementRef}
                    onRecaptchaSuccess={handleRecaptchaSuccess}
                    onRecaptchaError={handleRecaptchaError}
                />

                <div className={classesLogin.buttonContainer}>
                    <SternumImprovedButton
                        buttonType="regularWithDisabled"
                        className={classesLogin.loginButton}
                        content={"Send"}
                        onClick={formik.handleSubmit}
                        isDisabled={isSendEmailButtonDisabled()}
                        isLoading={formik.isSubmitting}
                    />
                </div>
            </div>
        </AuthenticationContainer>
    );
}

export const ForgotPassword: React.FC<ForgotPasswordProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgotPasswordComponent);
