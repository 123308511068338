import { TabProps, Tab } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import React from "react";

interface SternumTabProps extends TabProps {
    /**
     * defines style for tab that is separated from the tab content visually
     */
    separated?: boolean;
}

const StyledTab = withStyles((theme) => ({
    root: {
        minWidth: 90,
        textTransform: "initial",
        fontSize: "1rem",
        fontWeight: "normal",
        borderRadius: 14,
        borderBottomLeftRadius: (props) => (props.separated ? 14 : 0),
        borderBottomRightRadius: (props) => (props.separated ? 14 : 0),
    },

    selected: {
        background: "#fff",
        color: "#E7004C",
        boxShadow: "0px 0px 23px rgba(231, 232, 235, 0.25)",
    },
}))(Tab);

const SternumTab = (props: SternumTabProps) => {
    return <StyledTab {...props} />;
};

export default SternumTab;
