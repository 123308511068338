import { Typography } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import HashSet from "../../lib/infra/HashSet";
import Utils from "../../lib/infra/Utils";
import { fetchUsedLibraryAction } from "../../lib/redux/usedLibraries/FetchUsedLibraryAction";
import CvesFilter from "../../lib/state/CvesFilter";
import DevicesFilter from "../../lib/state/DevicesFilter";
import { GlobalState } from "../../lib/state/GlobalState";
import TableToolbarDisplayState from "../../lib/state/TableToolbarDisplayState";
import UsedLibraryInfo from "../../lib/state/UsedLibraryInfo";
import UsedLibraryStatus from "../../lib/state/UsedLibraryStatus";
import CvesList from "../CvesList/CvesList";
import DevicesList from "../DevicesList/DevicesList";
import StatusDisplay from "../StatusDisplay/StatusDisplay";
import SternumTab from "../SUI/SternumTabs/SternumTab";
import SternumTabs from "../SUI/SternumTabs/SternumTabs";
import UsedLibraryInfoDisplay from "../UsedLibraryInfoDisplay/UsedLibraryInfoDisplay";
import usedLibraryViewStyle from "./UsedLibraryViewStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    selectedTab: number;
    selectedTabType: any;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof usedLibraryViewStyle> {
    usedLibraryId: string;
    fullScreenDisplay: boolean;
    backButtonDisplayed: boolean;

    loadingUsedLibrary?: boolean;
    errorLoadingUsedLibrary?: boolean;
    usedLibrary?: UsedLibraryInfo;

    fetchUsedLibraryAction?: (usedLibraryId: string) => void;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {
        loadingUsedLibrary: state.usedLibraries.loadingIds.exists(ownProps.usedLibraryId),
        errorLoadingUsedLibrary: state.usedLibraries.errorIds.containsKey(ownProps.usedLibraryId),
        usedLibrary: state.usedLibraries.idToEntityMap.get(ownProps.usedLibraryId),
    };
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchUsedLibraryAction: (usedLibraryId: string) => dispatch(fetchUsedLibraryAction(usedLibraryId)),
    };
};

/**
 * Displays a bar of metrics.
 */
class UsedLibraryView extends React.Component<AppProps, AppState> {
    /**
     * Holds all type of tabs.
     */
    private tabTypes = {
        usedLibraryInfo: {
            key: "usedLibraryInfo",
            displayName: "Library Info",
            index: 0,
        },
        devices: {
            key: "devices",
            displayName: "Related Devices",
            index: 1,
        },
        cves: {
            key: "cves",
            displayName: "Known CVEs",
            index: 2,
        },
    };

    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            selectedTab: null,
            selectedTabType: null,
        };
    }

    /**
     * Occurs once the component is mounted.
     * We fetch the used library if needed here.
     */
    async componentDidMount() {
        // Getting the used library.
        this.props.fetchUsedLibraryAction(this.props.usedLibraryId);
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        if (this.props.loadingUsedLibrary) {
            // Return loading placeholder for used library page.
            return (
                <div
                    className={classNames(
                        classes.usedLibraryViewUpperContent,
                        this.props.backButtonDisplayed && "mod-with-back-button"
                    )}
                >
                    <div className={classNames(classes.loadingPlaceholder, classes.marginBottom, "mod-title")} />
                    <div
                        className={classNames(classes.loadingPlaceholder, classes.marginBottomXLarge, "mod-subtitle")}
                    />
                    <div className={classNames(classes.loadingPlaceholder, classes.marginBottom, "mod-paragraph")} />
                    <div className={classNames(classes.loadingPlaceholder, classes.marginBottom, "mod-paragraph")} />
                    <div className={classNames(classes.loadingPlaceholder, classes.marginBottom, "mod-paragraph")} />
                </div>
            );
        } else {
            if (!this.props.usedLibrary) {
                return (
                    <div className={classes.usedLibraryViewUpperContent}>
                        <Typography variant="body2">Sorry, we couldn't find requested library.</Typography>
                    </div>
                );
            } else {
                const tabsToDisplay = Utils.getMapValues(this.tabTypes);

                let selectedTab = this.state.selectedTab;
                if (selectedTab === null) {
                    selectedTab = 0;
                }

                let selectedTabType = this.state.selectedTabType;
                if (selectedTabType === null) {
                    selectedTabType = tabsToDisplay[0];
                }

                // Otherwise, render the used library view.
                return (
                    <div className={classes.wrapper}>
                        {/* Upper content */}
                        <div className={classNames(classes.usedLibraryViewUpperContent)}>
                            <div className={classNames(classes.flexVMiddle)}>
                                {/* Library name */}
                                <Typography variant={"h6"} className={classNames(classes.title, classes.extraBold)}>
                                    {this.props.usedLibrary.library.name}
                                </Typography>

                                {/* Used library status indication */}
                                {this.props.usedLibrary.status !== UsedLibraryStatus.MULTIPLE_ISSUES && (
                                    <StatusDisplay
                                        label={this.props.usedLibrary.getStatusDisplay()}
                                        danger={this.props.usedLibrary.isAlertingStatus()}
                                        success={
                                            this.props.usedLibrary.status !== UsedLibraryStatus.OUT_OF_DATE_LIBRARIES &&
                                            this.props.usedLibrary.status !== UsedLibraryStatus.NO_INFORMATION &&
                                            !this.props.usedLibrary.isAlertingStatus()
                                        }
                                        medium={
                                            this.props.usedLibrary.status === UsedLibraryStatus.OUT_OF_DATE_LIBRARIES ||
                                            this.props.usedLibrary.status === UsedLibraryStatus.NO_INFORMATION
                                        }
                                        displayColoredBackground={true}
                                        centerText={true}
                                        padding={true}
                                    />
                                )}

                                {this.props.usedLibrary.status === UsedLibraryStatus.MULTIPLE_ISSUES && (
                                    <div className={classNames(classes.marginRightXs)}>
                                        <StatusDisplay
                                            label={UsedLibraryInfo.getStatusDisplay(UsedLibraryStatus.CRITICAL_CVE)}
                                            danger={true}
                                            success={false}
                                            medium={false}
                                            displayColoredBackground={true}
                                            centerText={true}
                                            padding={true}
                                        />
                                    </div>
                                )}

                                {this.props.usedLibrary.status === UsedLibraryStatus.MULTIPLE_ISSUES && (
                                    <div className={classNames(classes.marginRightXs)}>
                                        <StatusDisplay
                                            label={UsedLibraryInfo.getStatusDisplay(
                                                UsedLibraryStatus.OUT_OF_DATE_LIBRARIES
                                            )}
                                            danger={true}
                                            success={false}
                                            medium={false}
                                            displayColoredBackground={true}
                                            centerText={true}
                                            padding={true}
                                        />
                                    </div>
                                )}
                            </div>

                            {/* Version */}
                            <Typography
                                variant={"body2"}
                                className={classNames(classes.marginRightXs, classes.subTitle, classes.marginTopXs)}
                            >
                                {this.props.usedLibrary.version}
                            </Typography>
                        </div>

                        {/* Tab selection */}
                        <SternumTabs
                            value={selectedTab}
                            // @ts-ignore
                            onChange={(e, value) => this.handleSelectedTab(value, tabsToDisplay[value])}
                            className={classes.tabsContainer}
                        >
                            {tabsToDisplay.map((tab) => {
                                return <SternumTab label={tab.displayName} value={tab.index} />;
                            })}
                        </SternumTabs>

                        {/* Used Library info */}
                        {selectedTabType.key === "usedLibraryInfo" && (
                            <div className={classNames(classes.infoTabContent, classes.tabContent)}>
                                <div className={classes.innerTabContent}>
                                    <UsedLibraryInfoDisplay usedLibrary={this.props.usedLibrary} />
                                </div>
                            </div>
                        )}

                        {/* Devices */}
                        {selectedTabType.key === "devices" && (
                            <div className={classNames(classes.devicesListTabContent, classes.tableContainer)}>
                                <div className={classes.tableInner}>
                                    <DevicesList
                                        viewedColumnsSet={HashSet.fromValues([
                                            "entityId",
                                            "name",
                                            "status",
                                            "lastSeen",
                                        ])}
                                        devicesFilter={
                                            new DevicesFilter(
                                                null,
                                                null,
                                                null,
                                                null,
                                                null,
                                                null,
                                                null,
                                                null,
                                                [this.props.usedLibraryId],
                                                null,
                                                null
                                            )
                                        }
                                        paperClassNames={classNames(
                                            classes.devicesListPaper,
                                            this.props.fullScreenDisplay ? "mod-fullscreen" : "mod-regular"
                                        )}
                                        displayBackButtonInDeviceView={true}
                                        displayXButtonInDeviceView={true}
                                        toolBarState={
                                            new TableToolbarDisplayState(
                                                true,
                                                false,
                                                true,
                                                false,
                                                false,
                                                false,
                                                false,
                                                false,
                                                "Search Device"
                                            )
                                        }
                                        openDeviceViewInCurrentPage={false}
                                    />
                                </div>
                            </div>
                        )}

                        {/* Cves */}
                        {selectedTabType.key === "cves" && (
                            <div className={classNames(classes.cvesListTabContent, classes.tableContainer)}>
                                <div className={classes.tableInner}>
                                    <CvesList
                                        cvesFilter={
                                            new CvesFilter(
                                                this.props.usedLibrary.entityId,
                                                null,
                                                null,
                                                null,
                                                null,
                                                null
                                            )
                                        }
                                        paperClassNames={classNames(
                                            classes.cvesListPaper,
                                            this.props.fullScreenDisplay ? "mod-fullscreen" : "mod-regular"
                                        )}
                                        displayBackButtonInCveView={true}
                                        displayXButtonInCveView={true}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                );
            }
        }
    }

    /**
     * Occurs once a tab has been selected.
     */
    private handleSelectedTab(selectedTab, selectedTabType) {
        this.setState({
            selectedTab: selectedTab,
            selectedTabType: selectedTabType,
        });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(usedLibraryViewStyle)(UsedLibraryView));
