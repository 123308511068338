import React from "react";
import { Box, Typography } from "@material-ui/core";

import { useGetSupportStyle } from "../GetSupport.style";
import { GetSupportSteps } from "../GetSupportSteps";

export function GetSupportGuide() {
    const classes = useGetSupportStyle();

    return (
        <Box>
            <Box py={2}>
                <Typography variant="subtitle1" className={classes.instructionTitle}>
                    OpenWrt User Guide
                </Typography>

                <GetSupportSteps />
            </Box>

            <div className={classes.guideDescriptionSection}>
                <Typography variant="subtitle1" className={classes.instructionTitle}>
                    About Sternum Runtime Protection
                </Typography>

                <Typography variant="body2" className={classes.instructionText}>
                    Sternum Embedded Integrity Verification (EIV) is an agentless security solution which is embedded on
                    the device, protecting firmware and 3rd party software from exploitation. Instruments the software,
                    analyzes it, identifies the non-negotiable steps an attacker must take to exploit it, and expresses
                    the steps as a unique fingerprint of exploitation. When an attack is attempted, the unique
                    Exploitation Fingerprint™ triggers Sternum's integrity verification and the attack is stopped.
                    Sternum prevents entire families of attacks and equips the device so that it is not exposed to new
                    vulnerabilities or malware.
                    <br />
                    EIV main capabilities include:
                    <ul>
                        <li>Comprehensive runtime protection from memory exploits</li>
                        <li>Runtime execution flow integrity </li>
                        <li>Supply-chain third-party protection </li>
                        <li>Thorough protection from command injections at runtime</li>
                        <li>Attack detection, real-time alerts and event-based timeline investigation</li>
                    </ul>
                </Typography>
            </div>

            <div className={classes.guideDescriptionSection}>
                <Typography variant="subtitle1" className={classes.instructionTitle}>
                    About Sternum Real-time Analytics and Insights
                </Typography>

                <Typography variant="body2" className={classes.instructionText}>
                    Sternum Platform Analytics & Detection Insights is the policy-enabling cloud platform to which
                    prevented attacks and detected events are reported by EIV. The dashboard allows viewing and analysis
                    of attacks and alerts related to functional behavior. Sternum Platform data taxonomy supports risk
                    and performance use cases, simplifying interpretation, guiding decision-making, and facilitating
                    incident response, troubleshooting, continuous improvement, and quality control.
                    <br />
                    The main capabilities include:
                    <ul>
                        <li>Autonomous anomaly detection</li>
                        <li>Multiple categories of telemetry from device behavior</li>
                        <li>Runtime alerts, visibility and investigation</li>
                        <li>Granular logging for engineering and support teams</li>
                    </ul>
                </Typography>
            </div>
        </Box>
    );
}
