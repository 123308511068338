import { call, put, select, takeEvery } from "redux-saga/effects";
import {
    convertCategoryTimeRangeToNumeric,
    DAY_INTERVAL_IN_MS,
    generateMockedEmptyStatistics,
    MONTH_INTERVAL_IN_MS,
} from "../../infra/Alerts.utils";
import ServiceWire from "../../services/ServiceWire";
import { CategoryTimeRange } from "../../state/DashboardRegularState";
import FetchError from "../../state/FetchError";
import { GlobalState } from "../../state/GlobalState";
import { IssuesStatisticsHeatmap } from "../../state/IssuesStatisticsHeatmap";
import ActionType from "../ActionType";
import { errorFetchingAlertsStatisticsAction } from "./ErrorFetchingAlertsStatisticsAction";
import { FetchAlertsStatisticsAction } from "./FetchAlertsStatisticsAction";
import { fetchedAlertsStatisticsSuccessfullyAction } from "./FetchedAlertsStatisticsSuccessfullyAction";
import { toggleAlertsHeatmapInteractionsAction } from "./ToggleAlertsHeatmapInteractionsAction";

export function* fetchAlertsStatisticsAsync(action: FetchAlertsStatisticsAction) {
    try {
        const response: IssuesStatisticsHeatmap = yield call(
            [ServiceWire.getSternumService(), ServiceWire.getSternumService().getIssuesHeatmapStatistics],
            action.period,
            action.startTime,
            action.endTime,
            action.onlyUnresolved,
            action.onlyAnomalyInsights,
            "PRODUCTION"
        );

        response.category_count.forEach((point) => {
            // TODO: remove it after category_label field is deployed to dev api
            if (!point.category_label) {
                point.category_label = point.category + 1;
            }

            point.periodStart = point.period;
        });

        const selectedTimeRange: CategoryTimeRange = yield select(
            (state: GlobalState) => state.dashboardRegular.categoryTimeRange
        );
        const categoryTimeRangeCustomFrom: Date = yield select(
            (state: GlobalState) => state.dashboardRegular.categoryTimeRangeCustomFrom
        );
        const categoryTimeRangeCustomTo: Date = yield select(
            (state: GlobalState) => state.dashboardRegular.categoryTimeRangeCustomTo
        );
        const timestampRange = convertCategoryTimeRangeToNumeric(selectedTimeRange, {
            from: categoryTimeRangeCustomFrom,
            to: categoryTimeRangeCustomTo,
        });

        if (response.category_count.length === 0) {
            yield put(
                fetchedAlertsStatisticsSuccessfullyAction({
                    category_count: generateMockedEmptyStatistics(
                        timestampRange.from,
                        timestampRange.to,
                        selectedTimeRange === CategoryTimeRange.LastYear ? MONTH_INTERVAL_IN_MS : DAY_INTERVAL_IN_MS
                    ),
                })
            );

            yield put(toggleAlertsHeatmapInteractionsAction(false));
        } else {
            yield put(fetchedAlertsStatisticsSuccessfullyAction(response));
            yield put(toggleAlertsHeatmapInteractionsAction(true));
        }
    } catch (error) {
        yield put(errorFetchingAlertsStatisticsAction(new FetchError(error.message)));
    }
}

export function* watchFetchAlertsStatisticsAsync() {
    yield takeEvery((action) => action.type === ActionType.FetchAlertsStatistics, fetchAlertsStatisticsAsync);
}
