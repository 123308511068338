import { Action } from "redux";
import ActionType from "../ActionType";

/**
 * Defines the data for the refresh trigger action
 */
export interface RefreshTriggersHitLIstAction extends Action<ActionType> {
    type: ActionType.RefreshTriggersHitList;
}

/**
 * Dispatch refresh trigger action
 */
export function refreshTriggersHitLIstAction(): RefreshTriggersHitLIstAction {
    return {
        type: ActionType.RefreshTriggersHitList,
    };
}
