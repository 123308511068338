import { Box, IconButton, Typography } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { useCommonStyle } from "../../CommonStyle";
import { Chip } from "../../SettingsPage/Chip";
import { DiamondIcon } from "../../SUI/SternumIcon";
import { ExpandArrowsIcon, ThreeDotsHorizontalIcon } from "../../SUI/SternumIcon/SternumIcon";
import { useStyles } from "./PremiumVisualisationMockCardStyle";

interface PremiumVisualisationMockCardProps {
    title: React.ReactNode;
    visualisationMock: React.ReactNode;
}

export function PremiumVisualisationMockCard({ title, visualisationMock }: PremiumVisualisationMockCardProps) {
    const classes = useStyles();
    const commonClasses = useCommonStyle();

    return (
        <div className={classNames(classes.visualisationBoxContainer)}>
            <div className={classNames(classes.visualisationBox)}>
                <div className={classNames(classes.visualisationTitleContainer)}>
                    <div>
                        <Typography className={classNames(classes.visualisationTitle)}>{title}</Typography>
                        <Box display="inline-flex" mt={0.5}>
                            <Chip>
                                <DiamondIcon /> Premium
                            </Chip>
                        </Box>
                    </div>

                    <div className={classNames(commonClasses.flexGrow)} />

                    <IconButton disabled>
                        <ExpandArrowsIcon color="#DADADA" />
                    </IconButton>

                    <ThreeDotsHorizontalIcon />
                </div>

                {visualisationMock}
            </div>
        </div>
    );
}
