import { Typography } from "@material-ui/core";
import classNames from "classnames";
import * as React from "react";
import { useEffect } from "react";
import ExplanationComponent from "../../ExplanationComponent/ExplanationComponent";
import useSternumAuthTitleStyles from "./SternumAuthTitleStyles";

interface AppProps {
    content: string;
    infoContent: string;
}

const SternumAuthTitle = ({ content, infoContent }: AppProps) => {
    const classes = useSternumAuthTitleStyles();

    useEffect(() => {}, []);

    return (
        <>
            <Typography variant="h4" className={classNames(classes.headline)}>
                {content}
            </Typography>
            <ExplanationComponent
                size={"large"}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "right",
                }}
                transformOrigin={{ vertical: "center", horizontal: "left" }}
            >
                <Typography variant="body2">{infoContent}</Typography>
            </ExplanationComponent>
        </>
    );
};

export default SternumAuthTitle;
