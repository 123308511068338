import ArgumentDefinitionPartial from "../ArgumentDefinitionPartial";
import TraceDefinitionPartial from "../TraceDefinitionPartial";
import { default as TriggerDefinition } from "./TriggerDefinition";

/**
 * Represents over time trigger in sternum.
 */

class NeverSeenArgumentDefinition extends TriggerDefinition {
    /**
     * Constructor.
     */
    constructor(
        public traceDefinition: any,
        public argumentDefinition: any,
        public ignoreValues: any,
        public traceDefinitionPartial?: TraceDefinitionPartial,
        public argumentDefinitionPartial?: ArgumentDefinitionPartial
    ) {
        super();
    }

    /**
     * Converts json to trigger object.
     */
    public static fromJsonObject(jsonObject: Object) {
        // Constructing the map of arguments.
        return new NeverSeenArgumentDefinition(
            jsonObject["trace_definition_id"],
            jsonObject["argument_definition_id"],
            jsonObject["ignore_values"]
        );
    }

    /**
     * Converts json to object.
     * There are two senarios. First: trace and argument definitions doesn't exists need to save mapping for server
     * Secod: trace and argument definitions are exist, save their entity id
     */
    public getJsonObject() {
        let triggerJsonObject = {
            trace_definition_id: this.traceDefinitionPartial
                ? this.traceDefinitionPartial
                : this.traceDefinition.entityId,
            argument_definition_id: this.argumentDefinitionPartial
                ? this.argumentDefinitionPartial
                : this.argumentDefinition.entityId,
            ignore_values: this.ignoreValues,
        };

        return triggerJsonObject;
    }

    /**
     * Check if given trigger equals to existing one
     */
    public isEqual(other: NeverSeenArgumentDefinition): boolean {
        return true;
    }

    /**
     * Convert class fields into json string.
     */
    public getJsonString() {
        return JSON.stringify(this.getJsonObject());
    }
}

export default NeverSeenArgumentDefinition;
