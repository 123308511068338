import UIDataVisualisationConfiguration from "../entities/UIDataVisualisationConfiguration";
import { isValidDataSourceConfiguration } from "./isValidDataSourceConfiguration";

export function isValidDataSourceConfigurations(
    dataVisualisationConfigurations: UIDataVisualisationConfiguration[]
): boolean {
    return (
        dataVisualisationConfigurations &&
        dataVisualisationConfigurations.every((dataVisualisationConfiguration) =>
            isValidDataSourceConfiguration(dataVisualisationConfiguration)
        )
    );
}
