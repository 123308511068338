import React from "react";
import { connect } from "react-redux";

import { GlobalState } from "../../lib/state/GlobalState";
import { useHomePageStyle } from "./Home.style";
import { NavigationBar } from "./NavigationBar";
import { FirstMainView } from "./FirstMainView";
import { SecondMainView } from "./SecondMainView";
import { CustomersOpinionsView } from "./CustomersOpinionsView";
import { SternumByNumbersView } from "./SternumByNumbersView";

export interface HomePageProps {}

const mapStateToProps = (state: GlobalState, ownProps: HomePageProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

type HomePagePropsWithHOC = HomePageProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function HomePageComponent({}: HomePagePropsWithHOC) {
    const classes = useHomePageStyle();

    return (
        <div className={classes.root}>
            <NavigationBar />
            <FirstMainView />
            <SecondMainView />
            <CustomersOpinionsView />
            <SternumByNumbersView />
        </div>
    );
}

export const HomePage: React.FC<HomePageProps> = connect(mapStateToProps, mapDispatchToProps)(HomePageComponent);
