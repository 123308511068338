import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { History } from "history";

import { GlobalState } from "../../../lib/state/GlobalState";
import { SternumInputField } from "../../SUI/SternumInputField";
import SternumImprovedButton from "../../SUI/SternumImprovedButton/SternumImprovedButton";
import { ReSendIcon } from "../../SUI/SternumIcon";
import SternumBanner from "../../SUI/SternumBanner/SternumBanner";
import ServiceWire from "../../../lib/services/ServiceWire";
import LoginStateEnum from "../../../lib/state/LoginStateEnum";
import { setUserInfoAction } from "../../../lib/redux/users/SetUserAction";
import { useCommonStyle } from "../../CommonStyle";

import { AuthenticationContainer } from "../AuthenticationContainer";
import { useLoginManagerStyle } from "../LoginManager.style";
import { AuthInfoIcon } from "../AuthInfoIcon";
import { useEnterAuthMfaCodeStyle } from "./EnterAuthMfaCode.style";
import Utils from "../../../lib/infra/Utils";
import SternumConfiguration from "../../../lib/infra/SternumConfiguration";

export interface EnterAuthMfaCodeComponentProps {
    nextStep: (nextStep: number) => void;
}

interface FormValues {
    code: string;
}

const mapStateToProps = (state: GlobalState, ownProps: EnterAuthMfaCodeComponentProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return { setUserInfoAction: (user) => dispatch(setUserInfoAction(user)) };
};

const sternumDocsIframeId = "sternumDocsIframe";

function sendToSternumDocsDomain(data: any) {
    const w = (document.getElementById(sternumDocsIframeId) as HTMLIFrameElement).contentWindow;
    w.postMessage(data, SternumConfiguration.getSternumDocsUrl());
}

type EnterAuthMfaCodePropsWithHOC = EnterAuthMfaCodeComponentProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
        history: History;
    };

function EnterAuthMfaCodeComponent({ history, nextStep, setUserInfoAction }: EnterAuthMfaCodePropsWithHOC) {
    const classes = useEnterAuthMfaCodeStyle();
    const classesLogin = useLoginManagerStyle();
    const classesCommon = useCommonStyle();

    const [jwtToken] = useState(() => ServiceWire.getAuthenticationService().loadTokenFromUrl());
    const [email] = useState(() => {
        const token = ServiceWire.getAuthenticationService().loadTokenFromUrl();
        // parse jwt token
        const tokenContent = JSON.parse(atob(token.split(".")[1]));

        return tokenContent?.sub?.email || "";
    });
    const [showResendingMfaMessage, setShowResendingMfaMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const formik = useFormik<FormValues>({
        initialValues: {
            code: "",
        },
        validate: (values) => {
            const errors: Partial<FormValues> = {};

            if (values.code.length < 1) {
                errors.code = "The field is required";
            }

            return errors;
        },
        onSubmit: async (values) => {
            // Save validation token for next step
            ServiceWire.getAuthenticationService().setValidationToken(values.code);

            let response = await ServiceWire.getAuthenticationService().validateMFA(values.code, jwtToken);

            if (response) {
                sendToSternumDocsDomain({ token: response.authentication_token });
                // need to wait until event is delivered and processed
                await Utils.asyncDelay(500);

                setUserInfoAction(ServiceWire.getAuthenticationService().getCachedAuthenticatedUser());

                const routerState = history.location.state as any;

                history.replace({
                    pathname: routerState?.from?.pathname || "/dashboard",
                    search: routerState?.from?.search,
                });
            } else {
                setErrorMessage("The authentication code you have entered is invalid.");
            }
        },
    });

    useEffect(() => {
        (async () => {
            if (jwtToken) {
                await ServiceWire.getSternumService().jwtValidation(jwtToken);
            }
        })().then();
    }, []);

    const handleResendMfaCode = async () => {
        if (showResendingMfaMessage) {
            return;
        }

        setShowResendingMfaMessage(true);
        setErrorMessage("");

        try {
            await ServiceWire.getAuthenticationService().resendMfaToken(jwtToken);
        } catch (_e) {
            setErrorMessage("Failed to send verification email.");
        } finally {
            setShowResendingMfaMessage(false);
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            formik.handleSubmit();
        }
    };

    const isSubmitButtonDisabled = () => {
        return (
            showResendingMfaMessage ||
            formik.isSubmitting ||
            !formik.values.code ||
            Object.keys(formik.errors).length > 0
        );
    };

    return (
        <AuthenticationContainer onBack={() => history.goBack()}>
            <h1 className={classNames(classesLogin.title)}>
                Two-factor{" "}
                <span className={classesLogin.textWithIcon}>
                    authentication
                    <AuthInfoIcon>
                        We just sent you a message via email with your authentication code. Enter the code in the form
                        below to verify your identity.
                    </AuthInfoIcon>
                </span>
            </h1>

            <div className={classNames(classesLogin.authFieldsContainer)}>
                {errorMessage && (
                    <div className={classesCommon.marginBottom}>
                        <SternumBanner
                            messageType={"error"}
                            message={errorMessage}
                            onCloseClick={() => setErrorMessage("")}
                        />
                    </div>
                )}
                {showResendingMfaMessage && (
                    <div className={classesCommon.marginBottom}>
                        <SternumBanner
                            messageType={"system"}
                            message={"Your authentication code has been sent"}
                            onCloseClick={() => setShowResendingMfaMessage(false)}
                        />
                    </div>
                )}

                {/* Authentication code input */}
                <SternumInputField
                    className={classes.codeInput}
                    label={"Authentication code"}
                    inputValue={formik.values.code}
                    onFieldChange={(e) => formik.setFieldValue("code", e.target.value)}
                    onKeyPress={handleKeyPress}
                    addMargin={true}
                    isTouched={!!formik.touched.code}
                    error={!!formik.errors.code}
                    helperText={formik.touched.code ? formik.errors.code : undefined}
                    onTouch={() => formik.setFieldTouched("code", true)}
                    required={true}
                />

                <div className={classes.subtitleContainer}>
                    <div className={classes.subtitle}>The code has been sent to your email -</div>
                    <div className={classes.subtitle}>{email}</div>
                </div>

                <div className={classes.resendMfaContainer} onClick={handleResendMfaCode}>
                    <ReSendIcon width={19} height={19} />
                    <span className={classes.resendMfaText}>Resend the MFA code</span>
                </div>

                <div className={classesLogin.buttonContainer}>
                    <SternumImprovedButton
                        buttonType="regularWithDisabled"
                        className={classesLogin.loginButton}
                        content="Submit"
                        onClick={formik.handleSubmit}
                        isDisabled={isSubmitButtonDisabled()}
                        isLoading={formik.isSubmitting}
                    />
                </div>
            </div>

            <iframe
                style={{ display: "none" }}
                src={SternumConfiguration.getSternumDocsUrl()}
                id={sternumDocsIframeId}
            />
        </AuthenticationContainer>
    );
}

export const EnterAuthMfaCode: React.FC<EnterAuthMfaCodeComponentProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(EnterAuthMfaCodeComponent));
