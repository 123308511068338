import { Action } from "redux";
import ActionType from "../ActionType";

/**
 * Defines the data for the not viewing a device action.
 */
export interface DeviceNotViewedAction extends Action<ActionType> {
    type: ActionType.DeviceNotViewed;
}

/**
 * Action creator for not viewing a device anymore.
 */
export function deviceNotViewedAction(): DeviceNotViewedAction {
    return {
        type: ActionType.DeviceNotViewed,
    };
}
