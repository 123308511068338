import React, { useState } from "react";
import { connect } from "react-redux";
import { Title } from "../Title";
import { useSimulateAttackStyle } from "./SimulateAttack.style";
import { GlobalState } from "../../../../lib/state/GlobalState";
import { DashboardProps } from "../../Dashboard.component";
import SternumImprovedButton from "../../../SUI/SternumImprovedButton/SternumImprovedButton";
import { openSimulateAttackModal } from "../../../../lib/redux/modals/OpenModalAction";
import { Typography } from "@material-ui/core";

export interface SimulateAttackProps {}

type ConnectedSimulateAttackProps = {
    openSimulateAttackModal: (key: string) => unknown;
};

const mapStateToProps = (state: GlobalState, ownProps: DashboardProps) => ({});

const mapDispatchToProps = (dispatch: any) => ({
    openSimulateAttackModal: (key: string) => dispatch(openSimulateAttackModal(key)),
});

function SimulateAttackComponent({ openSimulateAttackModal }: ConnectedSimulateAttackProps) {
    const classes = useSimulateAttackStyle();
    const open = useState(false);

    return (
        <div className={classes.root}>
            <div className={classes.left}>
                <Title>Learn how to use Attack Simulation Kit</Title>
                <div className={classes.descriptionText}>
                    Simulate cybersecurity attacks without endangering your device
                </div>
            </div>
            <div className={classes.right}>
                <SternumImprovedButton
                    onClick={() => openSimulateAttackModal("simulateAttackModal")}
                    content={<Typography variant="body2">Learn More</Typography>}
                    buttonType="regular:outlined"
                />
            </div>
        </div>
    );
}

export const SimulateAttack: React.FC<SimulateAttackProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(SimulateAttackComponent);
