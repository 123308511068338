import { getTimeSeriesGraphLabel } from "../../../components/VisualisationConfigurationComponent/utils/getTimeSeriesGraphLabel";
import EntityBase from "../EntityBase";
import EntityType from "../EntityType";
import SternumQuery from "../SternumQuery";
import VisualisationConfiguration from "./VisualisationConfiguration";
import ConfigurationService from "../../../lib/services/ConfigurationService";

const specialFields = {
    [ConfigurationService.getEventTypeArgumentField().id]: ConfigurationService.getEventTypeArgumentField(),
    [ConfigurationService.getInterestArgumentField().id]: ConfigurationService.getInterestArgumentField(),
    [ConfigurationService.getCategoryArgumentField().id]: ConfigurationService.getCategoryArgumentField(),
    [ConfigurationService.getDeviceIdArgumentField().id]: ConfigurationService.getDeviceIdArgumentField(),
};

class VisualisationInfo extends EntityBase {
    /**
     * Constructor.
     */
    constructor(
        public visualisationId: string,
        public displayName: string,
        public created: number,
        public updated: number,
        public configuration: VisualisationConfiguration,
        public isStacked: boolean,
        public isLogarithmicScale: boolean
    ) {
        super(visualisationId, created, updated, EntityType.Visualisation);
    }

    /**
     * Gets VisualisationInfo from json.
     */
    public static fromJsonObject(jsonObject: Object): VisualisationInfo {
        return new VisualisationInfo(
            jsonObject["entity_id"],
            jsonObject["display_name"],
            jsonObject["created"],
            jsonObject["updated"],
            {
                visualisationType: jsonObject["configuration"]["visualisation_type"],
                groupBy: jsonObject["configuration"]["group_by"],
                dataSources: (jsonObject["configuration"]["data_sources"] || []).map((dataSourceJson) => {
                    return {
                        dataSourceKey: dataSourceJson["data_source_key"],
                        sternumQuery: dataSourceJson["sternum_query"]
                            ? SternumQuery.fromJsonObject(dataSourceJson["sternum_query"])
                            : undefined,
                        aggregationFunction: dataSourceJson["aggregation_function"],
                        traceDefinitionId: dataSourceJson["trace_definition_id"],
                        traceDefinitionDisplayName: dataSourceJson["trace_definition_display_name"],
                        argumentDefinitionId: dataSourceJson["argument_definition_id"],
                        deviceDefinitionVersionIds: dataSourceJson["device_definition_version_id"],
                        argumentDefinitionDisplayName: dataSourceJson["argument_definition_display_name"],
                        percentile: dataSourceJson["percentile"],
                        color: dataSourceJson["color"],
                        // graph label is constructed from the next three parameters and also device definition version if available,
                        // so its done not here, but somewhere closer to the component where we can retrieve device definition version
                        dataSourceLabel: getTimeSeriesGraphLabel(
                            dataSourceJson["aggregation_function"],
                            dataSourceJson["trace_definition_display_name"],
                            dataSourceJson["argument_definition_display_name"]
                        ),
                        uniqueColumns: dataSourceJson["aggregation_fields"]
                            ? dataSourceJson["aggregation_fields"].map((column) => ({
                                  label: column in specialFields ? specialFields[column].displayName : "",
                                  value: column,
                                  isSpecialField: !!(column in specialFields),
                              }))
                            : undefined,
                    };
                }),
            },
            jsonObject["stacked"],
            jsonObject["logarithmic_scale"]
        );
    }
}

export default VisualisationInfo;
