import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const useSettingsPageStyle = makeStyles((theme: Theme) => ({
    root: {
        padding: "16px",
    },

    oneLineContainer: {
        display: "flex",
        flexWrap: "wrap",
        gap: 20,
        marginTop: 40,
    },

    leftPart: {
        flex: 1,
        minWidth: 340,
    },

    rightPart: {
        flex: 1,
        minWidth: 300,
        maxWidth: 460,
    },

    dataPrivacyContainer: {
        marginTop: 32,
    },

    linkButton: {
        color: "#1B6FDE",
        fontSize: 14,
        lineHeight: "21px",
        fontWeight: 400,
    },
}));

export const useSettingsGlobalStyle = makeStyles((theme) => ({
    subTitle: {
        color: "#ACB4BD",
        fontSize: 14,
        lineHeight: "138%",
        margin: "16px 0 30px 0",
    },
}));
