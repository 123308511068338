import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {},

    commandContainer: {
        maxWidth: 480,
        marginTop: "5px",
        padding: theme.spacing(3),
        borderRadius: 14,

        "&.filled": {
            background: "#F7F9FA",
        },

        "&.outlined": {
            background: "#FFF",
            border: "1px solid #E4E4E4",
        },
    },

    command: {
        maxWidth: "100%",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        fontFamily: "monospace",
    },

    noPadding: {
        padding: "0 !important",
    },

    selectArchitecture: {
        display: "flex",
        columnGap: "15px",
        marginTop: "10px",
    },

    architectureOption: {
        flex: 1,
        display: "flex",
        justifyContent: "center",
        padding: "10px 0",
        background: "#FFFFFF",
        border: "1px solid #E7EEF6",
        borderRadius: "10px",
        transition: "all 0.2s ease",
        cursor: "pointer",

        "&.selected": {
            background: "#1B6FDE",
            color: "#FFF",
        },
    },
}));
