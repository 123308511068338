import { Action } from "redux";
import ActionType from "../ActionType";

/**
 * Defines the data for the view used library action.
 */
export interface ViewUsedLibraryAction extends Action<ActionType> {
    type: ActionType.ViewUsedLibrary;
    usedLibraryId: string;
}

/**
 * Action creator for viewing a used library.
 */
export function viewUsedLibraryAction(usedLibraryId: string): ViewUsedLibraryAction {
    return {
        type: ActionType.ViewUsedLibrary,
        usedLibraryId: usedLibraryId,
    };
}
