import React from "react";
import classNames from "classnames";

import { useSternumPlatformFreeTrialStyle } from "./SternumPlatformFreeTrial.style";
import { DiamondIcon } from "../../SUI/SternumIcon";

export interface SternumPlatformFreeTrialProps {
    devicesLeft: number;
    onClickProtectAll?: () => unknown;
}

export function SternumPlatformFreeTrial({ devicesLeft, onClickProtectAll }: SternumPlatformFreeTrialProps) {
    const classes = useSternumPlatformFreeTrialStyle();
    const isZeroDevices = devicesLeft <= 0;

    return (
        <div className={classes.sternumPlatformFreeTrial}>
            <div className={classes.title}>Sternum Platform Free Plan</div>
            <div className={classes.item}>
                <div className={classes.itemTitle}>Devices left:</div>
                <div className={classes.itemValue}>{devicesLeft}</div>
            </div>
            <button
                className={classNames(classes.protectButton, isZeroDevices && classes.protectButtonZero)}
                onClick={onClickProtectAll}
            >
                {!isZeroDevices && <DiamondIcon color="#222831" />}
                <span>Protect all devices</span>
            </button>
        </div>
    );
}
