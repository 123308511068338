import { isEqual, uniqueId } from "lodash";
import Cloneable from "../infra/Cloneable";
import QueryQuantifierType from "./QueryQuantifierType";
import SternumFilter from "./SternumFilter";

/**
 * Custom sternum query.
 */
class SternumQuery implements Cloneable<SternumQuery> {
    /**
     * Constructor.
     */
    constructor(
        public id: string,
        public queryQuantifierType: QueryQuantifierType,
        public filters: SternumFilter[],
        public innerQueries: SternumQuery[]
    ) {}

    /**
     * Returns whether query is empty.
     */
    public isEmpty(): boolean {
        // If we've got no filters and no inner queries.
        if ((!this.filters || !this.filters.length) && (!this.innerQueries || !this.innerQueries.length)) {
            return true;
        }

        return !this.anyNonNullFilters() && !this.anyNonNullInnerQueries();
    }

    /**
     * Returns whether we have any non null filters.
     */
    private anyNonNullFilters(): boolean {
        for (let i = 0; i < this.filters.length; i++) {
            let filter = this.filters[i];

            if (!filter.isEmpty()) {
                return true;
            }
        }

        return false;
    }

    /**
     * Returns whether we have any non null inner queries.
     */
    private anyNonNullInnerQueries(): boolean {
        for (let i = 0; i < this.innerQueries.length; i++) {
            let innerQuery = this.innerQueries[i];

            if (!innerQuery.isEmpty()) {
                return true;
            }
        }

        return false;
    }

    /**
     * Returns an empty query.
     */
    public static getEmptyQuery(withOneEmptyFilter = true): SternumQuery {
        return new SternumQuery(
            uniqueId(),
            QueryQuantifierType.ALL,
            withOneEmptyFilter ? [SternumFilter.getEmptyFilter(+uniqueId())] : [],
            []
        );
    }

    /**
     * Cleans the query of any null filters or queries.
     */
    public getCleanQuery(): SternumQuery {
        let cleanQuery = new SternumQuery(uniqueId(), this.queryQuantifierType, [], []);

        for (let i = 0; i < this.filters.length; i++) {
            let filter = this.filters[i];

            if (!filter.isEmpty()) {
                cleanQuery.filters.push(filter);
            }
        }

        for (let i = 0; i < this.innerQueries.length; i++) {
            let innerQuery = this.innerQueries[i];

            if (!innerQuery.isEmpty()) {
                cleanQuery.innerQueries.push(innerQuery);
            }
        }

        return cleanQuery;
    }

    /**
     * Clones the sternum query to a new cloned object.
     */
    public clone(): SternumQuery {
        return new SternumQuery(
            this.id,
            this.queryQuantifierType,
            this.filters.map((filter) => filter.clone()),
            this.innerQueries.map((query) => query.clone())
        );
    }

    /**
     * Creates a sternum query out of given json object.
     */
    public static fromJsonObject(jsonObject: Object): SternumQuery {
        let queryQuantifierType: QueryQuantifierType = QueryQuantifierType.ALL;

        switch (jsonObject["query_type"]) {
            case "AND":
                queryQuantifierType = QueryQuantifierType.ALL;
                break;

            case "OR":
                queryQuantifierType = QueryQuantifierType.ANY;
                break;
        }

        let filterComponentIdentifier: number = 0;
        let filters: SternumFilter[] = [];

        jsonObject["filters"].forEach((filterJson) => {
            filterComponentIdentifier = filterComponentIdentifier + 1;
            filters.push(SternumFilter.fromJsonObject(filterComponentIdentifier, filterJson));
        });

        // // Keep at least one filter empty if no filters.
        // if (!filters || !filters.length) {
        //     filterComponentIdentifier = filterComponentIdentifier + 1;
        //     filters.push(SternumFilter.getEmptyFilter(filterComponentIdentifier));
        // }

        let innerQueries: SternumQuery[] = jsonObject["queries"].map((innerQueryJson) =>
            SternumQuery.fromJsonObject(innerQueryJson)
        );
        return new SternumQuery(uniqueId(), queryQuantifierType, filters, innerQueries);
    }

    public isDifferentFrom(otherSternumQuery: SternumQuery) {
        if (!otherSternumQuery) {
            return true;
        }

        if (otherSternumQuery.queryQuantifierType !== this.queryQuantifierType) {
            return true;
        }

        if (otherSternumQuery.filters && !this.filters) {
            return true;
        }

        if (!otherSternumQuery.filters && this.filters) {
            return true;
        }

        if (otherSternumQuery.innerQueries && !this.innerQueries) {
            return true;
        }

        if (!otherSternumQuery.innerQueries && this.innerQueries) {
            return true;
        }

        if (otherSternumQuery.filters.length !== this.filters.length) {
            return true;
        }

        if (otherSternumQuery.innerQueries.length !== this.innerQueries.length) {
            return true;
        }

        for (let i = 0; i < otherSternumQuery.filters.length; i++) {
            const currentFilter: SternumFilter = this.filters[i];
            const otherFilter: SternumFilter = otherSternumQuery.filters[i];

            if (currentFilter.isDifferentFrom(otherFilter)) {
                return true;
            }

            const currentValues = currentFilter.valuesMap.values();
            const otherValues = otherFilter.valuesMap.values();

            if (currentValues.length !== otherValues.length) {
                return true;
            }

            for (let j = 0; j < currentValues.length; j++) {
                const currentValue = currentValues[j];
                const otherValue = otherValues[j];

                if (!isEqual(currentValue, otherValue)) {
                    return true;
                }
            }
        }

        for (let i = 0; i < otherSternumQuery.innerQueries.length; i++) {
            let currnetInnerQuery = this.innerQueries[i];
            let otherInnerQuery = otherSternumQuery.innerQueries[i];

            if (currnetInnerQuery.isDifferentFrom(otherInnerQuery)) {
                return true;
            }
        }

        return false;
    }
    /**
     * Returns the json the server needs for the query.
     */
    public getServerQueryJson(): Object {
        return this.getQueryServerQueryJson(this);
    }

    /**
     * Constructs the query json recursively.
     */
    private getQueryServerQueryJson(query: SternumQuery): Object {
        let serverQueryJson: Object = {};

        // Quantifier
        switch (query.queryQuantifierType) {
            case QueryQuantifierType.ALL:
                serverQueryJson["query_type"] = "AND";
                break;

            case QueryQuantifierType.ANY:
                serverQueryJson["query_type"] = "OR";
                break;
        }

        // Filters
        serverQueryJson["filters"] = [];
        if (query.filters && query.filters.length) {
            let convertedFilters = query.filters
                .filter((filter) => !filter.isEmpty())
                .map((filter) => filter.getServerJson());

            if (convertedFilters && convertedFilters.length) {
                serverQueryJson["filters"] = convertedFilters;
            }
        }

        // Inner queries
        serverQueryJson["queries"] = [];
        if (query.innerQueries && query.innerQueries.length) {
            let convertedQueries = query.innerQueries
                .filter((innerQuery) => !innerQuery.isEmpty())
                .map((innerQuery) => this.getQueryServerQueryJson(innerQuery));

            if (convertedQueries && convertedQueries.length) {
                serverQueryJson["queries"] = convertedQueries;
            }
        }

        return serverQueryJson;
    }
}

export default SternumQuery;
