import { makeStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import commonStyle from "../../../components/CommonStyle";

const useSelectComponentStyles = makeStyles((theme: Theme) => {
    return {
        ...commonStyle(theme),
        selectComponent: {
            //minWidth: theme.spacing(57),
            // paddingTop: theme.spacing(2),
            // paddingBottom: theme.spacing(2),
        },
    };
});

export default useSelectComponentStyles;
