import React from "react";
import classNames from "classnames";

import { GraphWithArrowIcon } from "../../SUI/SternumIcon/SternumIcon";
import { GraphItem } from "../GraphItem";
import { DeviceGraphData, GraphData } from "../types";
import { useMultipleDevicesGraphStyle } from "./MultipleDevicesGraph.style";
import { GraphParentChildItem } from "./GraphParentChildItem";

export interface MultipleDevicesGraphProps {
    multipleDevicesGraph: GraphData<DeviceGraphData>;
    isGraphDeviceSelectedById: Record<string, boolean>;
    maxDeep?: number;
    onChangeSelectedGraphDevicesById?: (isGraphDeviceSelectedById: Record<string, boolean>) => unknown;
}

export function MultipleDevicesGraphComponent({
    multipleDevicesGraph,
    isGraphDeviceSelectedById,
    maxDeep = -1,
    onChangeSelectedGraphDevicesById,
}: MultipleDevicesGraphProps) {
    const classes = useMultipleDevicesGraphStyle();

    const renderRoot = () => (
        <div className={classNames(classes.rootContainer)}>
            <div className={classNames(classes.rootIconContainer)}>
                <GraphWithArrowIcon className={classNames(classes.rootIcon)} />
            </div>
            <div className={classNames(classes.rootText)}>ADS</div>
        </div>
    );

    const changeSelectedGraphItem = (itemId: string) => {
        const updatedIsGraphDeviceSelectedById = { ...isGraphDeviceSelectedById };

        if (updatedIsGraphDeviceSelectedById[itemId]) {
            delete updatedIsGraphDeviceSelectedById[itemId];
        } else {
            updatedIsGraphDeviceSelectedById[itemId] = true;
        }

        // At least one item has to be selected
        if (Object.keys(updatedIsGraphDeviceSelectedById).length) {
            onChangeSelectedGraphDevicesById?.(updatedIsGraphDeviceSelectedById);
        }
    };

    const renderGraphNodeWithChildren = (parentId: string, deep: number = -1) => {
        const itemNode = multipleDevicesGraph.graph[parentId];
        const deviceId = itemNode?.data?.deviceId;
        const childIds = itemNode?.childIds;
        const color = itemNode?.data?.color;
        const newDeep = deep > 0 ? deep - 1 : deep;

        if (deep === 0) {
            return null;
        }

        return (
            <GraphParentChildItem
                renderItem={() => (
                    <GraphItem
                        titleText={deviceId}
                        graphData={itemNode?.data}
                        inlined={!childIds?.length || deep === 1}
                        selected={isGraphDeviceSelectedById[parentId]}
                        color={color}
                        onChangeSelection={() => changeSelectedGraphItem(parentId)}
                    />
                )}
                childItems={childIds?.map((childId) => renderGraphNodeWithChildren(childId, newDeep))}
                deep={deep}
            />
        );
    };

    const renderGraphRoot = (deep: number = -1) => {
        const childIds = multipleDevicesGraph.root.childIds;

        if (deep === 0) {
            return null;
        }

        return (
            <GraphParentChildItem
                renderItem={renderRoot}
                childItems={childIds?.map((childId) => renderGraphNodeWithChildren(childId, deep))}
                deep={deep}
            />
        );
    };

    return (
        <div className={classNames(classes.container)}>
            <div className={classNames(classes.graph)}>{renderGraphRoot(maxDeep ?? -1)}</div>
        </div>
    );
}
