import * as React from "react";

import { WithStyles, withStyles } from "@material-ui/core/styles";
import { Link, withRouter } from "react-router-dom";
import libraryInfoDisplayStyle from "./LibraryInfoDisplayStyle";
import { Typography } from "@material-ui/core";
import TimeAgo from "react-timeago";
import LibraryInfo from "../../lib/state/LibraryInfo";
import SternumConfiguration from "../../lib/infra/SternumConfiguration";
import DeviceInfo from "../../lib/state/DeviceInfo";

/**
 * Holds the inner state for our app.
 */
interface AppState {}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof libraryInfoDisplayStyle> {
    library: LibraryInfo;
}

/**
 * Library info display.
 */
class LibraryInfoDisplay extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {};
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <div className={classes.infoColumn}>
                    {/* Library Id */}
                    <div className={classes.libraryDetailContainer}>
                        {/* Title */}
                        <Typography variant={"body2"} className={classes.libraryDetailName}>
                            Library ID:
                        </Typography>

                        <Typography variant="body2">{this.props.library.entityId}</Typography>
                    </div>

                    {/* Name */}
                    <div className={classes.libraryDetailContainer}>
                        {/* Title */}
                        <Typography variant={"body2"} className={classes.libraryDetailName}>
                            Name:
                        </Typography>

                        <Typography variant="body2">{this.props.library.name}</Typography>
                    </div>

                    {/* Version */}
                    <div className={classes.libraryDetailContainer}>
                        {/* Title */}
                        <Typography variant={"body2"} className={classes.libraryDetailName}>
                            Latest Version:
                        </Typography>

                        <Typography variant="body2">
                            {this.props.library ? this.props.library.latestKnownVersion : "Unknown"}
                        </Typography>
                    </div>
                </div>

                <div className={classes.infoColumn}>
                    {/* Information Source */}
                    <div className={classes.libraryDetailContainer}>
                        {/* Title */}
                        <Typography variant={"body2"} className={classes.libraryDetailName}>
                            Information Source:
                        </Typography>

                        <Typography variant="body2">{this.props.library.informationSource}</Typography>
                    </div>

                    {/* Information Collected */}
                    <div className={classes.libraryDetailContainer}>
                        {/* Title */}
                        <Typography variant={"body2"} className={classes.libraryDetailName}>
                            Information Collected:
                        </Typography>

                        <Typography variant="body2">
                            <TimeAgo date={this.props.library.updated} />
                        </Typography>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(withStyles(libraryInfoDisplayStyle)(LibraryInfoDisplay));
