import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import cvesListModalStyle from "./CvesListModalStyle";
import { GlobalState } from "../../lib/state/GlobalState";
import { connect } from "react-redux";
import classNames from "classnames";
import CvesList from "../CvesList/CvesList";
import { Typography } from "@material-ui/core";
import CvesFilter from "../../lib/state/CvesFilter";

/**
 * Holds the inner state for our app.
 */
interface AppState {}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof cvesListModalStyle> {
    fullScreenDisplay: boolean;
    cvesFilter: CvesFilter;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {};
};

/**
 * Displays a bar of metrics.
 */
class CvesListModal extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {};
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.wrapper}>
                {/* Title Section */}
                <div className={classNames(classes.upperContentContainer)}>
                    {/* Main Title */}
                    <Typography className={classes.title}>Critical CVEs</Typography>

                    {/* Subtitle */}
                    <Typography className={classes.subtitle}>
                        Discovered CVEs that are currently affecting devices.
                    </Typography>
                </div>

                {/* List of CVEs */}
                <div className={classes.tableContainer}>
                    <div className={classes.tableInner}>
                        <CvesList
                            cvesFilter={this.props.cvesFilter}
                            displayBackButtonInCveView={true}
                            displayXButtonInCveView={true}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(cvesListModalStyle)(CvesListModal));
