import { Typography } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { History, Location } from "history";
import * as React from "react";
import * as queryString from "querystring";
import { connect } from "react-redux";
import { match } from "react-router";
import { withRouter } from "react-router-dom";
import Utils from "../../lib/infra/Utils";
import ServiceWire from "../../lib/services/ServiceWire";
import { GlobalState } from "../../lib/state/GlobalState";
import NewDeviceView from "../NewDeviceView/NewDeviceView";
import SpinnerLoader from "../SUI/SternumLoaders/SpinnerLoader";
import SternumSelectData from "../SUI/SternumSelect/SternumSelectData";
import fleetViewStyle from "./FleetViewStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    defaultDeviceDefinitionVersionId: SternumSelectData;
    selectDeviceDefinitionVersionIds: SternumSelectData[];
    loadingContent: boolean;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof fleetViewStyle> {
    sidebarOpen: boolean;
    history: History;
    location: Location;
    match: match;
}

const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

/**
 * Displays the dashboard of device definitions.
 */
class FleetView extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            defaultDeviceDefinitionVersionId: null,
            loadingContent: true,
            selectDeviceDefinitionVersionIds: null,
        };
    }

    /**
     * Occurs once the component finished its initialization process.
     */
    async componentDidMount() {
        await this.initState(true);
    }

    /**
     *
     * Load default fleet view to init state
     */
    private initState = async (addHistoryPath: boolean) => {
        this.setState({ loadingContent: true });
        const deviceDefinitionSelectVersions = await ServiceWire.getSternumService().getClientFleetView(
            ServiceWire.getClientsService().getSelectedClientId(),
            0,
            50
        );

        // Get default device definition
        const defaultDeviceDefinitionVersion = this.findDefaultDeviceDefinition(deviceDefinitionSelectVersions);

        // Check if device definition exists
        if (defaultDeviceDefinitionVersion !== undefined) {
            this.setState(
                {
                    loadingContent: false,
                    selectDeviceDefinitionVersionIds: deviceDefinitionSelectVersions,
                    defaultDeviceDefinitionVersionId: defaultDeviceDefinitionVersion,
                },
                () => {
                    // Check if it's deep url or sidebar click
                    if (addHistoryPath && this.props.match.params.deviceDefinitionVersionId === undefined) {
                        this.props.history.push(`fleet-view/${defaultDeviceDefinitionVersion.entityId}`);
                    }
                }
            );
        } else {
            this.setState({
                loadingContent: false,
                selectDeviceDefinitionVersionIds: null,
                defaultDeviceDefinitionVersionId: null,
            });
        }
    };

    /**
     * Find default device definition version if url param exists
     */

    private findDefaultDeviceDefinition = (
        optionalDeviceDefinitionVersions: SternumSelectData[]
    ): SternumSelectData | undefined => {
        if (this.props.match.params.deviceDefinitionVersionId) {
            return optionalDeviceDefinitionVersions.find(
                (optionalVersion) => optionalVersion.entityId === this.props.match.params.deviceDefinitionVersionId
            );
        }
        return optionalDeviceDefinitionVersions.find((optionalVersion) => optionalVersion.isDefault === true);
    };

    /**
     * Occurs once the component is being destroyed.
     */
    componentWillUnmount(): void {}

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;
        const searchParams = Utils.parseQueryStringParamsObject(queryString.parse(this.props.location.search));

        if (this.state.loadingContent) {
            return this.getLoadingPlaceholder();
        } else {
            if (this.state.defaultDeviceDefinitionVersionId === null) {
                return this.getNoContentMessage();
            } else
                return (
                    <NewDeviceView
                        fullScreenDisplay={true}
                        displayBackButton={false}
                        displayViewForDeviceDefinition={true}
                        deviceDefinitionVersionId={this.state.defaultDeviceDefinitionVersionId.entityId}
                        fleetViewSelectList={this.state.selectDeviceDefinitionVersionIds}
                        fleetViewDefaultItem={this.state.defaultDeviceDefinitionVersionId}
                        contextFromTimestamp={parseInt(searchParams.contextFromTimestamp, 10)}
                        contextTraceId={searchParams.contextTraceId}
                    />
                );
        }
    }

    /**
     * Returns the loading place holder for the page.
     */
    private getLoadingPlaceholder() {
        const { classes } = this.props;

        return (
            <div className={classes.deviceViewUpperContent}>
                <SpinnerLoader />
            </div>
        );
    }

    private getNoContentMessage() {
        const { classes } = this.props;
        // No content text
        const noContentElement = (
            <div
                role="presentation"
                aria-label="no content message"
                key="noContentKey"
                className={classNames(classes.fullViewportHeight, classes.flexVMiddle, classes.flexCenter)}
            >
                <Typography variant="h6">Oops! You don't have any device profiles yet. Click&nbsp;</Typography>
                <Typography variant="h6" className={classes.redirectLink}>
                    <Link
                        onClick={() => {
                            this.props.history.push("/device-profiles");
                        }}
                    >
                        here
                    </Link>
                </Typography>
                <Typography variant="h6">&nbsp;to create.</Typography>
            </div>
        );
        return noContentElement;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(fleetViewStyle)(FleetView)));
