import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../../components/CommonStyle";

const customInputStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        cssLabel: {
            fontSize: "0.95rem",
            transform: `translate(${theme.spacing(3)}px, ${theme.spacing(2.5)}px) scale(1)`,
            color: "#696969",
        },

        disabledLabel: {
            color: "#DCDCDC",
            fontSize: "0.95rem",
            transform: `translate(${theme.spacing(3)}px, ${theme.spacing(2.5)}px) scale(1)`,
        },

        cssFocused: {
            fontSize: "0.95rem",
            width: "100%",
        },

        formHelperText: {
            margin: "3px 12px 0",
            fontSize: "0.65rem",
        },

        cssOutlinedInput: {
            fontSize: "1rem",
            height: theme.spacing(8),
        },

        notchedOutline: {
            height: theme.spacing(8),
        },

        input: {
            paddingTop: "0px",
            paddingBottom: "0px",
        },

        inputSelect: {
            height: theme.spacing(6),
            paddingTop: theme.spacing(2),
            paddingBottom: "0px",
        },
        selectHeight: {
            height: theme.spacing(8),
        },

        arrowIcon: {
            top: "calc(50% - 14px)",
        },

        menuPaper: {
            boxShadow: "5px 5px 24px 0px rgba(0, 0, 0, .1)",
        },

        menuList: {
            "& .MuiMenuItem-root": {
                height: "auto",
                padding: "10px 16px",
            },
        },
    });

export default customInputStyle;
