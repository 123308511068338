import { LineSeries } from "@amcharts/amcharts4/charts";
import { Typography } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { flatMap, get, isEmpty, isEqual, maxBy, set, uniq } from "lodash";
import * as React from "react";
import GraphUtils, { GraphResult, GraphTooltipData } from "../../../lib/infra/GraphUtils";
import SternumUtils from "../../../lib/infra/SternumUtils";
import AnalyticsService from "../../../lib/services/AnalyticsService";
import Utils from "../../../lib/infra/Utils";
import ReceivedDefinitionsResponse from "../../../lib/services/events/ReceivedDefinitionsResponse";
import ServiceWire from "../../../lib/services/ServiceWire";
import DeviceDefinitionVersionInfo from "../../../lib/state/DeviceDefinitionVersionInfo";
import VisualisationDataSourceGroupBy, {
    VisualisationDataSourceGroupByCustomFields,
} from "../../../lib/state/Visualisation/VisualisationConfigurationGroupBy";
import VisualisationDataSource from "../../../lib/state/Visualisation/VisualisationDataSource";
import { sternumColorMap } from "../../ColorPicker/sternumColorMap";
import SternumTimeSeriesChart from "../../SUI/SternumAMCharts/SternumTimeSeriesChart";
import { ChartEmptyIcon } from "../../SUI/SternumIcon/SternumIcon";
import UIDataVisualisationConfiguration from "../../VisualisationConfigurationComponent/entities/UIDataVisualisationConfiguration";
import GraphLoadingDisplay from "../../VisualisationConfigurationComponent/GraphLoadingDisplay/GraphLoadingDisplay";
import getVisualisationDataSource from "../../VisualisationConfigurationComponent/utils/getVisualisationDataSource";
import { isChangedDataSourceConfiguration } from "../../VisualisationConfigurationComponent/utils/isChangedDataSourceConfiguration";
import visualisationConfigurationPresentationTimeSeriesStyle from "./VisualisationConfigurationPresentationTimeSeriesStyle";
import moment from "moment";
import AggregationFunctionType from "../../../lib/state/Visualisation/AggregationFunctionType";
import ConfigurationService from "../../../lib/services/ConfigurationService";
import { getUniqueCountTableData, UniqueCountTableDataResponse } from "../../UniqueCountQuery/UniqueCountTableData";

interface AppState {
    loadingGraph: boolean;
    errorLoadingGraph: boolean;

    graphResult: GraphResult;
    timeFormat: string;
    uiTimeFormat: string;
}

export interface AppProps extends WithStyles<typeof visualisationConfigurationPresentationTimeSeriesStyle> {
    entityId: string;

    startTime: number;

    endTime: number;

    dataVisualisationConfigurations: UIDataVisualisationConfiguration[];
    receivedDefinitionsResponse: Record<string, ReceivedDefinitionsResponse>;
    deviceDefinitionVersions: DeviceDefinitionVersionInfo[];
    groupBy: VisualisationDataSourceGroupBy;

    amountOfLoaders: number;

    amountOfGraphPointsToDisplay: number;

    isStacked: boolean;

    isLogarithmicScale?: boolean;

    displayTooltipDataPerDeviceDefinitionVersion?: boolean;
    refreshComponentId?: number;
}

class VisualisationConfigurationPresentationTimeSeries extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);

        this.state = {
            loadingGraph: false,
            errorLoadingGraph: false,

            graphResult: null,

            timeFormat: "HH:mm",
            uiTimeFormat: "HH:mm",
        };
    }

    async componentDidMount() {
        if (this.props.displayTooltipDataPerDeviceDefinitionVersion) {
            await this.loadGraphDetailed(this.props.startTime, this.props.endTime);
        } else {
            await this.loadGraph(this.props.startTime, this.props.endTime);
        }
    }

    async componentDidUpdate(nextProps: Readonly<AppProps>, prevState: Readonly<AppState>) {
        const nextDataVisualisationConfigurations: UIDataVisualisationConfiguration[] =
            nextProps.dataVisualisationConfigurations || [];

        const changedDataSource =
            nextDataVisualisationConfigurations.length !== this.props.dataVisualisationConfigurations.length ||
            nextDataVisualisationConfigurations.some(
                (newConfiguration, index) =>
                    !this.props.dataVisualisationConfigurations[index] ||
                    isChangedDataSourceConfiguration(
                        newConfiguration,
                        this.props.dataVisualisationConfigurations[index]
                    )
            );

        if (
            nextProps.refreshComponentId !== this.props.refreshComponentId ||
            nextProps.startTime !== this.props.startTime ||
            nextProps.endTime !== this.props.endTime ||
            changedDataSource ||
            nextProps.entityId !== this.props.entityId ||
            !isEqual(this.props.groupBy, nextProps.groupBy)
        ) {
            if (this.props.displayTooltipDataPerDeviceDefinitionVersion) {
                await this.loadGraphDetailed(this.props.startTime, this.props.endTime);
            } else {
                await this.loadGraph(this.props.startTime, this.props.endTime);
            }
        }
    }

    render() {
        const { classes } = this.props;

        if (
            this.state.loadingGraph ||
            !this.props.receivedDefinitionsResponse ||
            !this.props.startTime ||
            !this.props.endTime
        ) {
            return <GraphLoadingDisplay amountOfLoaders={this.props.amountOfLoaders} />;
        }

        if (this.state.errorLoadingGraph) {
            return <Typography className={classNames(classes.commonDangerColor)}>Error loading graph...</Typography>;
        }

        if (this.state.graphResult && this.state.graphResult?.["x_axes"]?.length) {
            const xAxesValues =
                this.state.graphResult && this.state.graphResult["x_axes"]
                    ? this.state.graphResult["x_axes"].map((xAxesValue) =>
                          moment(xAxesValue).format(this.state.uiTimeFormat)
                      )
                    : [];

            const groupByEnabled = !!this.props.groupBy?.enabled;

            let datasets = [];

            if (this.state.graphResult?.data_sources) {
                if (
                    this.props.dataVisualisationConfigurations &&
                    this.props.dataVisualisationConfigurations.length > 0 &&
                    this.props.dataVisualisationConfigurations[0].aggregationFunctionType ===
                        AggregationFunctionType.UNIQUE_COUNT
                ) {
                    if (
                        this.props.dataVisualisationConfigurations[0].uniqueColumns &&
                        this.props.dataVisualisationConfigurations[0].uniqueColumns.length > 1
                    ) {
                        return (
                            <Typography className={classNames(classes.commonDangerColor)}>
                                Please select only one Unique Value for Time Series
                            </Typography>
                        );
                    }
                    datasets = this.splitToUniqueCountDatasets(this.props.dataVisualisationConfigurations[0]);
                } else if (groupByEnabled) {
                    datasets = this.splitVisualisationDataSourceIntoDatasetsByGroupByKeys(
                        this.props.dataVisualisationConfigurations
                    );
                } else {
                    datasets = this.splitVisualisationDataSourcesIntoDatasets(
                        this.props.dataVisualisationConfigurations
                    );
                }
            }

            return (
                <div className={classNames(classes.root)}>
                    <SternumTimeSeriesChart
                        data={{
                            labels: xAxesValues,
                            datasets: datasets,
                        }}
                        displayLegend={false}
                        isStacked={this.props.isStacked}
                        height="100%"
                        isLogarithmicScale={this.props.isLogarithmicScale}
                        tooltipCallback={this.handleTooltipGeneration}
                        copyCategoryOnPointClick={
                            this.props.groupBy?.enabled &&
                            this.props.groupBy?.field === VisualisationDataSourceGroupByCustomFields.DEVICE
                        }
                    />
                </div>
            );
        }

        return (
            <div className={classes.emptyDataContainer}>
                <div className={classes.emptyDataInner}>
                    <ChartEmptyIcon />
                    <Typography className={classes.emptyDataText}>You dont have any data yet</Typography>
                </div>
            </div>
        );
    }

    private transformUniqueCountValues(
        values: any[],
        visualisationConfiguration: VisualisationDataSource,
        uniqueCountTableDataResponse: UniqueCountTableDataResponse
    ) {
        const argIdToFieldName = {
            [ConfigurationService.getCategoryArgumentField().id]: "categories",
            [ConfigurationService.getInterestArgumentField().id]: "interests",
            [ConfigurationService.getEventTypeArgumentField().id]: "eventTypes",
        };

        const newValues = values
            .reverse()
            .map((val, index) => {
                const col = visualisationConfiguration.uniqueColumns[index];

                if (!col || !col.isSpecialField) {
                    return val;
                }

                if (col.value in argIdToFieldName) {
                    const newVal = uniqueCountTableDataResponse?.[argIdToFieldName[col.value]][val];
                    return newVal !== undefined ? newVal.displayName : `Missing label: ${val}`;
                }

                return val;
            })
            .filter((_, index) => index > 0);

        return newValues;
    }

    private handleTooltipGeneration = (value: string, target: LineSeries) => {
        const data = target.tooltipDataItem.dataContext;
        if (!data || !data["configuration"]) return "";

        const dataPerDeviceDefinitionVersion = Object.keys(data["deviceDefinitionVersionsData"] || {}).map((key) => {
            return {
                label: key,
                value: data["deviceDefinitionVersionsData"][key] as number,
            };
        });

        return this.createDataSourceTooltip(data["configuration"], dataPerDeviceDefinitionVersion);
    };

    private splitToUniqueCountDatasets(visualisationConfiguration: UIDataVisualisationConfiguration) {
        const dataSources = this.state.graphResult.data_sources;
        const xAxes = this.state.graphResult.x_axes;

        if (!dataSources || Object.keys(dataSources).length === 0) {
            return [];
        }

        const uniqueCountItems = Object.values(dataSources)[0];

        return Object.keys(uniqueCountItems).map((item, index) => {
            const uniqueCountData = uniqueCountItems[item];
            const data = xAxes.map((xTime) => {
                const item = uniqueCountData?.[xTime];

                if (!item) {
                    return null;
                }

                return {
                    deviceDefinitionVersionsData: {
                        // [item]: uniqueCountItems[item][x].count
                    },
                    configuration: visualisationConfiguration,
                    value: item.count,
                };
            });

            return {
                id: `(${item})`,
                label: `(${item})`,
                color: sternumColorMap[visualisationConfiguration.color].main,
                data,
            };
        });
    }

    private splitVisualisationDataSourceIntoDatasetsByGroupByKeys(
        visualisationConfigurations: UIDataVisualisationConfiguration[]
    ) {
        // graphResult = {
        //     data_sources: {
        //         DEFAULT: {
        //             "1633934700000": {
        //                 "76.161.121.75": 290,
        //                 "117.73.95.127": 288,
        //                 "198.253.176.250": 284,
        //             },
        //             "1633944600000": {
        //                 "158.102.36.244": 290,
        //                 "198.253.176.250": 290,
        //                 "148.86.113.195": 3,
        //             },
        //             "1633954500000": {
        //                 "117.73.95.127": 176,
        //                 "158.102.36.244": 176,
        //                 "178.141.204.201": 89,
        //             },
        // 			...
        //         },
        //     }
        // }

        let groupByKeysSet = new Set<string>();

        const dataSourceValues = Object.values(this.state.graphResult?.data_sources || {});

        dataSourceValues.forEach((obj) => {
            const valuesPerDeviceDefinitionVersion = Object.values(obj);
            valuesPerDeviceDefinitionVersion.forEach((deviceDefinitionVersionObj) => {
                Object.values(deviceDefinitionVersionObj).forEach((timestampObj) => {
                    Object.keys(timestampObj).forEach((key) => {
                        groupByKeysSet.add(key);
                    });
                });
            });
        });

        const groupByKeys = [...groupByKeysSet];
        const groupByLabels = GraphUtils.convertGroupByKeysToLabelsArray(groupByKeys, this.props.groupBy.field);

        // @ts-ignore
        const datasets = groupByKeys.flatMap((key, index) => {
            return visualisationConfigurations
                .map((configuration) => {
                    if (!this.state.graphResult.data_sources[configuration.dataSourceKey]) return;

                    const dataset = {
                        id: key,
                        label: configuration.graphLabel,
                        extraLabel: groupByLabels[index],
                        data: [],
                    };

                    dataset.data = this.state.graphResult.x_axes.map((timestamp) => {
                        const deviceDefinitionVersionIds = configuration.deviceDefinitionVersionNames;

                        const isDetailed = !(
                            "total" in this.state.graphResult.data_sources[configuration.dataSourceKey]
                        );

                        let deviceDefinitionVersionsData: Record<string, number>;

                        deviceDefinitionVersionsData = deviceDefinitionVersionIds.reduce(
                            (acc, deviceDefinitionVersionId) => {
                                set(
                                    acc,
                                    [deviceDefinitionVersionId],
                                    isDetailed
                                        ? get(this.state.graphResult, [
                                              "data_sources",
                                              configuration.dataSourceKey,
                                              deviceDefinitionVersionId,
                                              timestamp,
                                              key,
                                          ]) || 0
                                        : null
                                );

                                return acc;
                            },
                            {}
                        );

                        const rawValues = isDetailed
                            ? deviceDefinitionVersionIds.map(
                                  (id) =>
                                      get(this.state.graphResult, [
                                          "data_sources",
                                          configuration.dataSourceKey,
                                          id,
                                          timestamp,
                                          key,
                                      ]) || 0
                              )
                            : [
                                  get(this.state.graphResult, [
                                      "data_sources",
                                      configuration.dataSourceKey,
                                      "total",
                                      timestamp,
                                      key,
                                  ]) || 0,
                              ];

                        return {
                            deviceDefinitionVersionsData,
                            configuration,
                            value: GraphUtils.calculateTotalValue(rawValues, configuration.aggregationFunctionType),
                        };
                    });

                    return dataset;
                })
                .filter(Boolean);
        });

        return datasets;
    }

    private createDataSourceTooltip = (
        configuration: UIDataVisualisationConfiguration,
        deviceDefinitionVersionData?: GraphTooltipData[]
    ): string => {
        const queryEditorFields = SternumUtils.getDeviceDefinitionFieldsToQuery(
            true,
            true,
            true,
            true,
            this.props.receivedDefinitionsResponse[configuration.deviceDefinitionVersionIds[0]]?.receivedDefinitions,
            this.props.receivedDefinitionsResponse[configuration.deviceDefinitionVersionIds[0]]?.receivedArguments,
            false
        );

        const traceDefinitionFields = SternumUtils.getTraceDefinitionFields(
            true,
            true,
            true,
            true,
            this.props.receivedDefinitionsResponse[configuration.deviceDefinitionVersionIds[0]]?.receivedDefinitions
        );

        const tooltip = GraphUtils.constructSeriesTooltip(
            configuration,
            queryEditorFields,
            traceDefinitionFields,
            "default",
            this.props.groupBy,
            deviceDefinitionVersionData
        );

        return tooltip;
    };

    private splitVisualisationDataSourcesIntoDatasets(visualisationConfigurations: UIDataVisualisationConfiguration[]) {
        // graphResult = {
        //     data_sources: {
        //         DEFAULT: {
        //             "1633934700000": 320,
        //             "1633944600000": 742,
        //             "1633954500000": 8431,
        // 				...
        //         },
        //     }
        // }

        const dataSourceKeyToConfiguration: Record<string, UIDataVisualisationConfiguration> = {};

        visualisationConfigurations.forEach((dataVisualisationConfiguration) => {
            dataSourceKeyToConfiguration[dataVisualisationConfiguration.dataSourceKey] = dataVisualisationConfiguration;
        });

        const allDataSourceKeys = Object.keys(this.state.graphResult?.data_sources || {});

        const datasets = allDataSourceKeys
            .map((dataSourceKey) => {
                const dataSourceConfiguration = dataSourceKeyToConfiguration[dataSourceKey];
                if (!dataSourceConfiguration) return;

                const labelPrefix = `(${dataSourceKey === "DEFAULT" ? 1 : +dataSourceKey + 1})`;

                const data = this.state.graphResult.x_axes.map((timestamp) => {
                    const deviceDefinitionVersionIds = dataSourceConfiguration.deviceDefinitionVersionNames;

                    const isDetailed = !("total" in this.state.graphResult.data_sources[dataSourceKey]);

                    let deviceDefinitionVersionsData: Record<string, number>;

                    deviceDefinitionVersionsData = deviceDefinitionVersionIds.reduce(
                        (acc, deviceDefinitionVersionId) => {
                            set(
                                acc,
                                [deviceDefinitionVersionId],
                                isDetailed
                                    ? get(this.state.graphResult, [
                                          "data_sources",
                                          dataSourceKey,
                                          deviceDefinitionVersionId,
                                          timestamp,
                                      ]) || 0
                                    : null
                            );

                            return acc;
                        },
                        {}
                    );

                    const rawValues = isDetailed
                        ? deviceDefinitionVersionIds.map(
                              (id) => get(this.state.graphResult, ["data_sources", dataSourceKey, id, timestamp]) || 0
                          )
                        : [get(this.state.graphResult, ["data_sources", dataSourceKey, "total", timestamp]) || 0];

                    return {
                        deviceDefinitionVersionsData,
                        configuration: dataSourceConfiguration,
                        value: GraphUtils.calculateTotalValue(
                            rawValues,
                            dataSourceConfiguration.aggregationFunctionType
                        ),
                    };
                });

                return {
                    id: labelPrefix,
                    label: labelPrefix,
                    color: sternumColorMap[dataSourceConfiguration.color].main,
                    data,
                };
            })
            .filter(Boolean);

        return datasets;
    }

    /**
     * Loads the graph of the aggregated events.
     */
    private async loadGraph(startTime: number, endTime: number) {
        const dataSources = this.getDataSources();
        if (isEmpty(dataSources)) return;

        try {
            this.setState({
                loadingGraph: true,
                errorLoadingGraph: false,
            });

            let hourDifference = (endTime - startTime) / 1000.0 / 60.0 / 60.0;
            let minuteDifference = (endTime - startTime) / 1000.0 / 60.0;
            let amountOfGraphPointsToDisplay = this.props.amountOfGraphPointsToDisplay;
            let interval = (minuteDifference / amountOfGraphPointsToDisplay) | 0 || 1; // Converting to integer
            let intervalTimeUnit = "MINUTE";
            let timeFormat = "HH:mm";
            let uiTimeFormat = "HH:mm";

            if (hourDifference > 24) {
                timeFormat = "MM/dd HH:mm";
                uiTimeFormat = "MM/DD HH:mm";
            }

            let graphResult: GraphResult = {
                data_sources: {},
                x_axes: [],
            };

            const allDeviceDefinitionVersions = uniq(flatMap(dataSources, "deviceDefinitionVersionIds"));
            const allTraceDefinitionIds = uniq(dataSources.map((dataSource) => dataSource.traceDefinitionId));

            const response = await ServiceWire.getVisualisationApiService().getArgumentValueOverTime(
                this.props.entityId,
                startTime,
                endTime,
                false,
                timeFormat,
                amountOfGraphPointsToDisplay,
                dataSources,
                this.props.groupBy,
                allDeviceDefinitionVersions,
                allTraceDefinitionIds,
                interval,
                intervalTimeUnit
            );

            graphResult.x_axes = response.x_axes;

            /**
             * {
             * 	1: {
             * 		ddve1: {
             * 			timestamp:  ...
             * 		},
             * 	},
             * 	2: {
             * 		ddve1: {
             * 			timestamp: ...
             * 		}
             * 	},
             * }
             */
            dataSources.forEach((dataSource) => {
                set(
                    graphResult,
                    ["data_sources", dataSource.dataSourceKey, "total"],
                    get(response, ["data_sources", dataSource.dataSourceKey])
                );
            });

            // Set state to finish loading.
            this.setState({
                graphResult: graphResult,
                loadingGraph: false,
                errorLoadingGraph: false,
                timeFormat: timeFormat,
                uiTimeFormat: uiTimeFormat,
            });
        } catch (error) {
            AnalyticsService.error("ArgumentValueTimeeSeriesGraph:loadGraph", error.message);
            console.error(error);

            // Set error state.
            this.setState({
                loadingGraph: false,
                errorLoadingGraph: true,
            });
        }
    }

    /**
     * Loads the graph of the aggregated events.
     */
    private async loadGraphDetailed(startTime: number, endTime: number) {
        const dataSources = this.getDataSources();
        if (isEmpty(dataSources)) return;

        try {
            this.setState({
                loadingGraph: true,
                errorLoadingGraph: false,
            });

            let minuteDifference = (endTime - startTime) / 1000.0 / 60.0;
            let hourDifference = minuteDifference / 60.0;
            let amountOfGraphPointsToDisplay = this.props.amountOfGraphPointsToDisplay;
            let interval = (minuteDifference / amountOfGraphPointsToDisplay) | 0 || 1; // Converting to integer
            let intervalTimeUnit = "MINUTE";
            let timeFormat = "HH:mm";
            let uiTimeFormat = "HH:mm";

            if (hourDifference > 24) {
                timeFormat = "MM/dd HH:mm";
                uiTimeFormat = "MM/DD HH:mm";
            }

            let graphResult: GraphResult = {
                data_sources: {},
                x_axes: [],
            };

            const dataSourcesRequests = this.getDataSourcesRequests(dataSources);

            const allDeviceDefinitionVersions = uniq(flatMap(dataSources, "deviceDefinitionVersionIds"));
            const allTraceDefinitionIds = uniq(dataSources.map((dataSource) => dataSource.traceDefinitionId));

            const responses = await Promise.all(
                dataSourcesRequests.map((dataSourceBatch) => {
                    return ServiceWire.getVisualisationApiService().getArgumentValueOverTime(
                        this.props.entityId,
                        startTime,
                        endTime,
                        false,
                        timeFormat,
                        amountOfGraphPointsToDisplay,
                        dataSourceBatch,
                        this.props.groupBy,
                        allDeviceDefinitionVersions,
                        allTraceDefinitionIds,
                        interval,
                        intervalTimeUnit
                    );
                })
            );

            responses.forEach((response) => {
                if (!isEmpty(response.x_axes)) {
                    graphResult.x_axes = response.x_axes;
                }
            });

            const deviceDefinitionVersionIdToVersionMap = this.getDeviceDefinitionVersionIdToVersionMap();

            const hasUniqueValue =
                this.props.dataVisualisationConfigurations.length > 0 &&
                this.props.dataVisualisationConfigurations[0].aggregationFunctionType ===
                    AggregationFunctionType.UNIQUE_COUNT;

            let uniqueDataResponse: UniqueCountTableDataResponse = null;
            if (hasUniqueValue) {
                const uniqueDataResponse = await getUniqueCountTableData(this.props.dataVisualisationConfigurations[0]);
            }

            /**
             * {
             * 	1: {
             * 		ddve1: {
             * 			timestamp:  ...
             * 		},
             * 		ddve2: {
             * 			timestamp: ...
             * 		},
             * 	},
             * 	2: {
             * 		ddve1: {
             * 			timestamp: ...
             * 		}
             * 	},
             * }
             */
            responses.forEach((response, index) => {
                const dataSourceBatch = dataSourcesRequests[index];

                dataSourceBatch.forEach((dataSource) => {
                    const versionId = dataSource.deviceDefinitionVersionIds[0];
                    const version = deviceDefinitionVersionIdToVersionMap[versionId];
                    if (!version) return;

                    if (dataSource.aggregationFunction === AggregationFunctionType.UNIQUE_COUNT) {
                        const timeData = get(response, ["data_sources", dataSource.dataSourceKey]);
                        const resultTimeDatas: any = {};

                        // sample the first result and use this information for the rest

                        Object.keys(timeData).forEach((timestamp) => {
                            const items = timeData[timestamp];
                            // its possible that data will look like this, so we need to filter out zero values
                            /**
                             * {
                             * 	1641828600000: [{values: ["ARDECG"], count: 1}],
                             * 	1641838500000: 0
                             * }
                             */
                            if (!items) return;

                            items.forEach((item) => {
                                const key = this.transformUniqueCountValues(
                                    item.values,
                                    dataSource,
                                    uniqueDataResponse
                                ).join(", ");

                                if (!(key in resultTimeDatas)) {
                                    resultTimeDatas[key] = {};
                                }

                                resultTimeDatas[key][timestamp] = item;
                            });
                        });

                        Object.keys(resultTimeDatas).forEach((timeDataKey) => {
                            set(
                                graphResult,
                                [
                                    "data_sources",
                                    // dataSource.dataSourceKey,
                                    version.getVersionName(),
                                    timeDataKey,
                                ],
                                resultTimeDatas[timeDataKey]
                            );
                        });
                    } else {
                        set(
                            graphResult,
                            ["data_sources", dataSource.dataSourceKey, version.getVersionName()],
                            get(response, ["data_sources", dataSource.dataSourceKey])
                        );
                    }
                });
            });

            // Set state to finish loading.
            this.setState({
                graphResult: graphResult,
                loadingGraph: false,
                errorLoadingGraph: false,
                timeFormat: timeFormat,
                uiTimeFormat: uiTimeFormat,
            });
        } catch (error) {
            AnalyticsService.error("ArgumentValueTimeeSeriesGraph:loadGraph", error.message);
            console.error(error);

            // Set error state.
            this.setState({
                loadingGraph: false,
                errorLoadingGraph: true,
            });
        }
    }

    private getDeviceDefinitionVersionIdToVersionMap = (): Record<string, DeviceDefinitionVersionInfo> => {
        return this.props.deviceDefinitionVersions.reduce((acc, version) => {
            acc[version.entityId] = version;
            return acc;
        }, {});
    };

    private getDataSources() {
        const dataSources = this.props.dataVisualisationConfigurations
            .filter(
                (dataVisualisationConfiguration) =>
                    dataVisualisationConfiguration.aggregatedArgumentDefinitionId ||
                    dataVisualisationConfiguration.aggregationFunctionType === AggregationFunctionType.COUNT ||
                    dataVisualisationConfiguration.aggregationFunctionType === AggregationFunctionType.UNIQUE_COUNT
            )
            .map((dataVisualisationConfiguration) => getVisualisationDataSource(dataVisualisationConfiguration));

        return dataSources;
    }

    private getDataSourcesRequests(dataSources: VisualisationDataSource[]): VisualisationDataSource[][] {
        const deviceDefinitionVersionBuckets: Record<string, string[]> = {};
        dataSources.forEach((dataSource) => {
            deviceDefinitionVersionBuckets[dataSource.dataSourceKey] = [...dataSource.deviceDefinitionVersionIds];
        });

        const dataSourceKeyToDataSourceMap: Record<string, VisualisationDataSource> = {};
        dataSources.forEach((dataSource) => {
            dataSourceKeyToDataSourceMap[dataSource.dataSourceKey] = dataSource;
        });

        const largestBucket = maxBy(Object.values(deviceDefinitionVersionBuckets), "length");

        const requests = [];
        while (!isEmpty(largestBucket)) {
            const dataSourceBatch: VisualisationDataSource[] = [];

            Object.keys(dataSourceKeyToDataSourceMap).forEach((key) => {
                if (!isEmpty(deviceDefinitionVersionBuckets[key])) {
                    dataSourceBatch.push({
                        ...dataSourceKeyToDataSourceMap[key],
                        deviceDefinitionVersionIds: [deviceDefinitionVersionBuckets[key].shift()],
                    });
                }
            });

            requests.push(dataSourceBatch);
        }

        return requests;
    }
}

export default withStyles(visualisationConfigurationPresentationTimeSeriesStyle)(
    VisualisationConfigurationPresentationTimeSeries
);
