import { CurrentlyViewedCveIdReducerActions } from "./CurrentlyViewedCveIdReducerActions";
import ActionType from "../ActionType";

/**
 * Reducer for the currently viewed cve.
 */
const currentlyViewedCveIdReducer = (state: string, action: CurrentlyViewedCveIdReducerActions) => {
    switch (action.type) {
        case ActionType.ViewCve:
            return action.cveId;

        case ActionType.CveNotViewed:
            return null;

        default:
            return state;
    }
};

export default currentlyViewedCveIdReducer;
