import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";

import { ArrowLeftIcon } from "../../../../SUI/SternumIcon";
import { useDropdownStyle } from "./Dropdown.style";
import { DropdownItem } from "./DropdownItem.component";
import { DropdownContextProvider } from "./Dropdown.context";

export interface DropdownProps extends React.ComponentProps<"div"> {
    selectedCase?: any;
    onChangeSelectedCase?: (newSelectedCase: any) => unknown;
    renderSelectedCase?: (selectedCase: any) => React.ReactNode;
}

export function Dropdown({
    className,
    selectedCase,
    onChangeSelectedCase,
    renderSelectedCase = () => selectedCase,
    onClick,
    children,
    ...props
}: DropdownProps) {
    const dropdownRef = useRef<HTMLDivElement>();
    const classes = useDropdownStyle();
    const [isOpened, setIsOpened] = useState(false);

    const renderedSelectedCase = renderSelectedCase?.(selectedCase);

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        setIsOpened((isOpened) => !isOpened);

        return onClick?.(e);
    };

    const handleChangeSelectedCase = (newSelectedCase: any) => {
        onChangeSelectedCase(newSelectedCase);

        setIsOpened(false);
    };

    useEffect(() => {
        if (!isOpened) {
            return;
        }

        const handleClickOutside = (e: PointerEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target as any)) {
                setIsOpened(false);
            }
        };

        document.addEventListener("pointerdown", handleClickOutside);

        return () => {
            document.removeEventListener("pointerdown", handleClickOutside);
        };
    }, [isOpened]);

    return (
        <DropdownContextProvider selectedCase={selectedCase} onChangeSelectedCase={handleChangeSelectedCase}>
            <div
                ref={dropdownRef}
                className={classNames(classes.dropdownRoot, className)}
                aria-expanded={isOpened}
                onClick={handleClick}
                {...props}
            >
                <div>{renderedSelectedCase}</div>
                <ArrowLeftIcon className={classNames(classes.arrowIcon)} />

                <div className={classNames(classes.dropdownView)} onClick={(e) => e.stopPropagation()}>
                    {children}
                </div>
            </div>
        </DropdownContextProvider>
    );
}

Dropdown.Item = DropdownItem;
