import ActivityInfo from "../state/ActivityInfo";
import ActivityType from "../state/ActivityType";
import CveInfo from "../state/CveInfo";
import IssueInfo from "../state/IssueInfo";
import SternumGeneratedEventInfo from "../state/SternumGeneratedEventInfo";
import TraceInfo from "../state/TraceInfo";

class ActivityManager {
    public static getActivityDisplayString(activity: ActivityInfo) {
        switch (activity.activityType) {
            case ActivityType.CVE_DETECTED:
                let cveInfo = activity.reference1 as CveInfo;
                if (cveInfo) {
                    return `New CVE detected (${cveInfo.cveOfficialId})`;
                } else {
                    return "Unknown";
                }

            case ActivityType.ATTACK_TRACE_DETECTED:
                let traceInfo = activity.reference1 as TraceInfo;

                if (traceInfo) {
                    return `${traceInfo.device.deviceDefinition.displayName} - Security Alert`;
                } else {
                    return "Unknown";
                }

            case ActivityType.ISSUE_RESOLVED:
                let issueInfo = activity.reference1 as IssueInfo;

                if (issueInfo) {
                    return `${issueInfo.device.deviceDefinition.displayName} Security Alert - Issue Resolved`;
                } else {
                    return "Unknown";
                }

            case ActivityType.STERNUM_TRIGGER_MET:
                let sternumGeneratedEvent = activity.reference1 as SternumGeneratedEventInfo;

                if (sternumGeneratedEvent) {
                    return `${sternumGeneratedEvent.device.deviceDefinition.displayName} - ${sternumGeneratedEvent.sternumTrigger.displayName}`;
                } else {
                    return "Unknown";
                }
        }
    }
}

export default ActivityManager;
