import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

const visualisationConfigurationPresentationStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        metricContainer: {
            "&.mod-outline": {
                paddingTop: theme.spacing(1),
                paddingRight: theme.spacing(4),
                paddingLeft: theme.spacing(4),
                paddingBottom: theme.spacing(1),
                height: theme.spacing(42),

                border: "1px solid #e9ecf1",
                boxShadow: "1px 2px 6px 0 rgba(0,0,0,0.07)",
            },

            "&.mod-inline": {
                width: "100%",
                height: "100%",
            },

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflowX: "auto",
            overflowY: "hidden",
        },

        graphContainer: {
            "&.mod-outline": {
                paddingTop: theme.spacing(1),
                paddingRight: theme.spacing(4),
                paddingLeft: theme.spacing(4),
                paddingBottom: theme.spacing(1),
                height: theme.spacing(42),
                border: "1px solid #e9ecf1",
                boxShadow: "1px 2px 6px 0 rgba(0,0,0,0.07)",
            },

            "&.full-height": {
                height: "100%",
            },

            "&.mod-inline": {
                width: "100%",
                height: "calc(100% - 27px)",
            },

            "&.mod-no-padding": {
                padding: 0,
            },
        },

        loadingContainer: {
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
    });

export default visualisationConfigurationPresentationStyle;
