import { makeStyles } from "@material-ui/core";

export const useGlancesStyle = makeStyles((theme) => {
    return {
        root: {
            display: "flex",
            flexDirection: "column",
            marginTop: "10px",
        },
        titleContainer: {
            display: "flex",
            flexDirection: "column",
        },
        subtitle: {
            fontWeight: 400,
            fontSize: "13px",
            lineHeight: "20px",
            color: "#6D7894",
        },
        glancesContainer: {
            display: "grid",
            gridTemplateColumns: "minmax(0, 1fr) minmax(0, 1fr) 330px",
            columnGap: "20px",
            marginTop: "20px",
        },
        item: {
            boxShadow: "-12px 14px 34px rgba(83, 93, 120, 0.15)",
            borderRadius: "15px",
        },
        seeMoreContainer: {
            display: "flex",
            border: "1px dashed #1B6FDE",
            borderRadius: "15px",
            boxShadow: "none",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            cursor: "pointer",
        },
        seeMore: {
            color: "#1B6FDE",
            lineHeight: "27px",
        },
    };
});
