import { RefreshTriggersHitLIstAction } from "./RefreshTriggesHitLIstAction";
import ActionType from "../ActionType";
import { getDefaultTriggersHitListState, TriggersHitListState } from "../../state/TriggersHitListState";

type TriggerReducerActions = RefreshTriggersHitLIstAction;

/*
 * Hack: in order to signal that triggers hit list should refresh we just create a new `shouldRefresh` object and
 * let redux detect that a change have been made
 */
const triggerListReducer = (
    state: TriggersHitListState = getDefaultTriggersHitListState(),
    action: TriggerReducerActions
) => {
    switch (action.type) {
        case ActionType.RefreshTriggersHitList:
            return {
                shouldRefresh: {},
            };

        default:
            return state;
    }
};

export default triggerListReducer;
