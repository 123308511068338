import { CancelToken } from "axios";
import SternumSelectData from "../../components/SUI/SternumSelect/SternumSelectData";
import AggregateOverTimeConvertor from "../infra/AggregateOverTimeConvertor";
import EntityManager from "../infra/EntityManager";
import SternumConfiguration from "../infra/SternumConfiguration";
import Utils from "../infra/Utils";
import ActivityInfo from "../state/ActivityInfo";
import AggregateOverProperty from "../state/AggregateOverProperty";
import APIKeyInfo from "../state/APIKeyInfo";
import ArgumentDefinitionInfo from "../state/ArgumentDefinitionInfo";
import ArgumentDefinitionPartial from "../state/ArgumentDefinitionPartial";
import ArgumentRoleType from "../state/ArgumentRoleType";
import AuthenticationCommandResponse from "../state/AuthenticationCommandResponse";
import AutoCompleteLibrary from "../state/AutoCompleteLibrary";
import ClientInfo from "../state/ClientInfo";
import CveInfo from "../state/CveInfo";
import CvesFilter from "../state/CvesFilter";
import DeviceDefinitionInfo from "../state/DeviceDefinitionInfo";
import DeviceDefinitionLibrariesFilter from "../state/DeviceDefinitionLibrariesFilter";
import DeviceDefinitionLibraryInfo from "../state/DeviceDefinitionLibraryInfo";
import DeviceDefinitionPartial from "../state/DeviceDefinitionPartial";
import DeviceDefinitionVersionInfo from "../state/DeviceDefinitionVersionInfo";
import DeviceInfo from "../state/DeviceInfo";
import DeviceProcessInstanceInfo from "../state/DeviceProcessInstanceInfo";
import DevicesFilter from "../state/DevicesFilter";
import EntitiesFilter from "../state/EntitiesFilter";
import EventCountsAggregationInfo from "../state/EventCountsAggregationInfo";
import EventCountsOverTimeAggregationInfo from "../state/EventCountsOverTimeAggregationInfo";
import GetActivitiesResponse from "../state/GetActivitiesResponse";
import GetCvesResponse from "../state/GetCvesResponse";
import GetDeleteSummaryResponse from "../state/GetDeleteSummaryResponse";
import GetDevicesResponse from "../state/GetDevicesResponse";
import GetSternumDeviceEventsResponse from "../state/GetSternumDeviceEventsResponse";
import GetSternumUniqueCountEventsResponse from "../state/GetSternumUniqueCountEventsResponse";
import GetTopHittingTriggersResponse from "../state/GetTopHittingTriggersResponse";
import HttpResponse from "../state/HttpResponse";
import InviteUserInfo from "../state/InviteUserInfo";
import IssueInfo from "../state/IssueInfo";
import IssuesFilter from "../state/IssuesFilter";
import LibrariesFilter from "../state/LibrariesFilter";
import LibraryHistoriesFilter from "../state/LibraryHistoriesFilter";
import LibraryHistoryInfo from "../state/LibraryHistoryInfo";
import LibraryInfo from "../state/LibraryInfo";
import MetricInfo from "../state/MetricInfo";
import OutgoingWebhookInfo from "../state/OutgoingWebhookInfo";
import RoleInfo from "../state/RoleInfo";
import SecurityIssuesData from "../state/SecurityIssuesData";
import SpecialField from "../state/SpecialField";
import SternumAgentConfiguration from "../state/SternumAgentConfiguration";
import SternumDeviceEventInfo from "../state/SternumDeviceEventInfo";
import SternumUniqueCountEventInfo from "../state/SternumUniqueCountEventInfo";
import SternumDeviceEventsFilter from "../state/SternumDeviceEventsFilter";
import SternumDisplayObjectInfo from "../state/SternumDisplayObjectInfo";
import SternumGeneratedEventInfo from "../state/SternumGeneratedEventInfo";
import SternumQuery from "../state/SternumQuery";
import SternumRole from "../state/SternumRole";
import SternumTriggerInfo from "../state/SternumTriggerInfo";
import SternumTriggerTypeDisplayInfo from "../state/SternumTriggerTypeDisplayInfo";
import TimeDivisionType from "../state/TimeDivisionType";
import TraceCategory from "../state/TraceCategory";
import TraceDefinitionInfo from "../state/TraceDefinitionInfo";
import TraceDefinitionPartial from "../state/TraceDefinitionPartial";
import TraceInfo from "../state/TraceInfo";
import TracesFilter from "../state/TracesFilter";
import TriggerParsedDescriptionResponse from "../state/TriggerParsedDescriptionResponse";
import UsedLibrariesFilter from "../state/UsedLibrariesFilter";
import UsedLibraryHistoriesFilter from "../state/UsedLibraryHistoriesFilter";
import UsedLibraryHistoryInfo from "../state/UsedLibraryHistoryInfo";
import UsedLibraryInfo from "../state/UsedLibraryInfo";
import UserInfo from "../state/UserInfo";
import AnalyticsService from "./AnalyticsService";
import buildSternumApiUrl from "./buildSternumApiUrl";
import HttpService from "./HttpService";
import { GetReceivedEventDefinitionCountersResponse } from "../state/GetReceivedEventDefinitionCountersResponse";
import AggregationFunctionType from "../state/Visualisation/AggregationFunctionType";
import EventInterestDetailed from "../state/EventInterestDetailed";
import ConfigurationService from "./ConfigurationService";
import moment from "moment";
import { ClientInfoOnboardingState } from "../state/ClientInfo.types";
import { BuildVersionByReleaseSystem, ReleaseSystem } from "../state/DownloadType";
import { AnomaliesResponse, anomalyFromJSON } from "../state/Anomaly";
import { IssueInfoPutReason, IssueInfoStatus, IssuesResolveItem } from "../state/IssueInfoType";
import { IssuesStatisticsHeatmap } from "../state/IssuesStatisticsHeatmap";
import { DashboardOnboardingStepState } from "../state/DashboardRegularState";
import GetSternumDeviceEventsResponseWithCursor from "../state/GetSternumDeviceEventsResponseWithCursor";
import { getSequencePatternsFromJSON } from "../state/Pattern";
import { EmailNotificationSettings } from "../state/EmailNotificationSettings";
import { LinuxDeviceMetrics, LinuxDeviceMetricsApiResponse } from "../state/LinuxDeviceMetrics";
import { DeviceAlertsStatistics } from "../state/DeviceAlertsStatistics";
import { LinuxAllKernelModules, LinuxAllKernelModulesResponseApi } from "../state/LinuxAllKernelModules";
import ServiceWire from "./ServiceWire";

interface AdjacentTracesArguments {
    clientId: string;
    deviceId: string;
    trace: TraceInfo;
    searchText?: string;
    limitPreceding: number;
    limitFollowing: number;
    order?: "ASC" | "DESC";
    processName?: string;
}

export interface MultipleRelatedDevicesResponse {
    entity_id: string; // "DEVIKY6ln4UEb91",
    entity_type: string; // "DEVICE",
    client_id: string; // "CLEI1",
    received_device_id: string; // "123456789",
    created: number; // 1651160045021.982;
    updated: number; // 1651160525021.982;
    last_seen_ip_address?: string; // "127.0.0.1",
    last_seen: number; // 1651160585021.982;
    last_seen_security_alert?: number; // 0.0;
    last_seen_security_alert_trace_id: string; // "",
    device_definition_id: string; // "DEDEg7J7F3UEb91",
    device_definition_version_id: string; // "DDVEg7J7F3UEb91",
    last_seen_version_firmware?: string | null; // null,
    last_seen_version_status?: string; // "STAGING",
    last_seen_version_sequence?: number; // 0,
    last_seen_version_name?: string | null; // null,
    is_device_definition_version_exists: string | null; // null,
    country_code?: string; // "IL";
    device_definition?: {
        is_reference: boolean;
        entity_id: string; // "DEDEg7J7F3UEb91"
    };
    last_seen_device_definition_version?: {
        is_reference: boolean;
        entity_id: string; // "DDVEg7J7F3UEb91"
    };
}

export interface MultipleDeviceGraphRelatedDevices {
    device: MultipleRelatedDevicesResponse;
    related_devices?: MultipleDeviceGraphRelatedDevices[];
}

export type MultipleDevicesGraphRelatedEntitiesMap = Record<
    string,
    {
        entity_id: string; // "DEVIWojidCgCs51"
        client_id: string; // "CLEI1"
        country_code: string; // "IR"
        created: number; // 1639134116000;
        device_definition?: {
            entity_id: string; // "DEDEGyg2hJQsN31"
            entity_type: string; // "DEVICE_DEFINITION"
        };
        device_definition_id: string; // "DEDEGyg2hJQsN31"
        device_definition_version_id: string; // "DDVECurMCOU0K41"
        entity_type: string; // "DEVICE"
        is_device_definition_version_exists: true;
        last_seen: number; // 1651134975767;
        last_seen_ip_address: string; // "201.167.17.183"
        last_seen_security_alert: number; // 0;
        last_seen_security_alert_trace_id: number; // 969117807886221300;
        last_seen_version_firmware: string; // "1.0.2"
        last_seen_version_name?: string | null; // null;
        last_seen_version_sequence?: number; // 2;
        last_seen_version_status: string; // "STAGING"
        received_device_id: string; // "95210"
        updated: number; // 1646738728000;
        device_display_name?: string;
    }
>;

export interface MultipleDevicesGraphRelationshipResponse {
    graph: MultipleDeviceGraphRelatedDevices;
    related_entities_map?: MultipleDevicesGraphRelatedEntitiesMap;
}

/**
 * Service responsible for all outgoing calls to the sternum REST API.
 */
class SternumService {
    /**
     * Constructor.
     */
    constructor(private httpService: HttpService) {}

    /**
     * Health command.
     * Returns empty response.
     * @deprecated Frontend should not check the health of backend, this endpoint is now hidden
     */
    // @AnalyticsService.reportOnError("API:health")
    // public health(): Promise<Object> {
    //     let endpoint = "/health";
    //     return this.httpService.get(this.buildUrl(endpoint));
    // }

    /**
     * Gets device by id.
     */
    @AnalyticsService.reportOnError("API:getDeviceById")
    public async getDeviceById(deviceId: string): Promise<DeviceInfo> {
        let endpoint = `/${deviceId}`;
        let responseJson = await this.httpService.get(this.buildUrl(endpoint));
        return Promise.resolve(EntityManager.getSternumEntity(responseJson) as DeviceInfo);
    }

    /**
     * Gets trace by id.
     */
    @AnalyticsService.reportOnError("API:getTraceById")
    public async getTraceById(traceId: string, issueId?: string): Promise<TraceInfo> {
        let endpoint = issueId ? `/${issueId}/${traceId}` : `/${traceId}`;
        let responseJson = await this.httpService.get(this.buildUrl(endpoint));
        return Promise.resolve(EntityManager.getSternumEntity(responseJson) as TraceInfo);
    }

    @AnalyticsService.reportOnError("API:getLinuxDeviceMetrics")
    public async getLinuxDeviceMetrics({
        clientId = ServiceWire.getClientsService().getSelectedClientId(),
        deviceId,
        timeRanges,
    }: {
        clientId?: string;
        deviceId: string;
        timeRanges: { from: number; to: number }[];
    }): Promise<LinuxDeviceMetrics[]> {
        const endpoint = `/${clientId}/${deviceId}/events-metrics`;
        const data = {
            time_ranges: timeRanges.map((timeRange) => ({
                created_from: timeRange.from,
                created_to: timeRange.to,
            })),
        };

        let responseJson = (await this.httpService.post(
            this.buildUrl(endpoint),
            data
        )) as LinuxDeviceMetricsApiResponse;

        return responseJson.metrics.map((metric) => ({
            alertsCount: metric.alerts_count,
            anomaliesCount: metric.anomalies_count,
            tracesCounts: metric.traces_counts,
        }));
    }

    /**
     * Get delete summary for trace or argument definition
     */
    @AnalyticsService.reportOnError("API:getDeleteSummary")
    public async getDeleteSummary(
        entityId: string,
        deviceDefinitionVersionId: string
    ): Promise<GetDeleteSummaryResponse> {
        let endpoint = `/${entityId}/delete_summary`;

        let queryParameters = { device_definition_version_id: deviceDefinitionVersionId };

        let responseJson = await this.httpService.get(this.buildUrl(endpoint), queryParameters);

        return Promise.resolve(GetDeleteSummaryResponse.fromJson(responseJson));
    }

    /**
     * Gets generated event by id.
     */
    @AnalyticsService.reportOnError("API:getGeneratedEventById")
    public async getGeneratedEventById(generatedEventId: string): Promise<SternumGeneratedEventInfo> {
        let endpoint = `/${generatedEventId}`;
        let responseJson = await this.httpService.get(this.buildUrl(endpoint));
        return Promise.resolve(EntityManager.getSternumEntity(responseJson) as SternumGeneratedEventInfo);
    }

    /**
     * Gets outgoing webhook by id.
     */
    @AnalyticsService.reportOnError("API:getOutgoingWebhookById")
    public async getOutgoingWebhookById(outgoingWebhookId: string): Promise<OutgoingWebhookInfo> {
        let endpoint = `/${outgoingWebhookId}`;
        let responseJson = await this.httpService.get(this.buildUrl(endpoint));
        return Promise.resolve(EntityManager.getSternumEntity(responseJson) as OutgoingWebhookInfo);
    }

    /**
     * Create a new outgoing webhook.
     */
    @AnalyticsService.reportOnError("API:createOutgoingWebhook")
    public async createOutgoingWebhook(
        clientId: string,
        displayName: string,
        uri: string,
        registeredEvents: string[]
    ): Promise<OutgoingWebhookInfo> {
        let data = {
            display_name: displayName,
            uri: uri,
            registered_events: registeredEvents,
        };

        let endpoint = `/${clientId}/outgoing_webhooks`;
        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);
        return Promise.resolve(EntityManager.getSternumEntity(responseJson) as OutgoingWebhookInfo);
    }

    /**
     * Updates an outgoing webhook.
     */
    @AnalyticsService.reportOnError("API:updateOutgoingWebhook")
    public async updateOutgoingWebhook(
        outgoingWebhookId: string,
        displayName: string,
        uri: string,
        registeredEvents: string[]
    ): Promise<OutgoingWebhookInfo> {
        let data = {
            display_name: displayName,
            uri: uri,
            registered_events: registeredEvents,
        };

        let endpoint = `/${outgoingWebhookId}`;
        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);
        return Promise.resolve(EntityManager.getSternumEntity(responseJson) as OutgoingWebhookInfo);
    }

    /**
     * Get device aggregated events.
     */
    @AnalyticsService.reportOnError("API:getEventCountsOverTimeAggregation")
    public async getEventCountsOverTimeAggregation(
        entityId: string,
        interval: number,
        intervalTimeUnit: string,
        createdFrom: number,
        createdTo: number,
        aggregateOverProperty: AggregateOverProperty,
        filterEmptyBuckets: boolean,
        bucketKeyDateFormat: string,
        amountOfGraphPointsToDisplay: number,
        deviceDefinitionVersionId?: string
    ): Promise<EventCountsOverTimeAggregationInfo> {
        let data = {
            interval: interval,
            interval_time_unit: intervalTimeUnit,
            created_from: createdFrom,
            created_to: createdTo,
            aggregate_over_property: AggregateOverTimeConvertor.getAggregateOverPropertyName(aggregateOverProperty),
            filter_empty_buckets: filterEmptyBuckets || false,
            bucket_key_date_format: bucketKeyDateFormat,
            amount_of_graph_points_to_display: amountOfGraphPointsToDisplay,
        };

        if (deviceDefinitionVersionId) {
            data["device_definition_version_id"] = deviceDefinitionVersionId;
        }

        let responseJson = await this.httpService.post(
            this.buildUrl(`/${entityId}/event_counts_over_time_aggregation`),
            data
        );
        return Promise.resolve(EventCountsOverTimeAggregationInfo.fromApiResponse(responseJson));
    }

    /**
     * Get device aggregated events.
     */
    @AnalyticsService.reportOnError("API:getEventRelatedEntities")
    public async getEventRelatedEntities(sternumGeneratedEventId: string): Promise<any> {
        let data = {};

        let responseJson = await this.httpService.get(
            this.buildUrl(`/${sternumGeneratedEventId}/related_entities`),
            data
        );

        let entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(entities);
    }

    /**
     * Get top hitting triggers.
     */
    @AnalyticsService.reportOnError("API:getTopHittingTriggers")
    public async getTopHittingTriggers(
        entityId: string,
        createdFrom: number,
        createdTo: number,
        amountOfHits: number,
        deviceDefinitionVersionId?: string
    ): Promise<GetTopHittingTriggersResponse> {
        let data = {
            created_from: createdFrom,
            created_to: createdTo,
            amount_of_hits: amountOfHits,
        };

        if (deviceDefinitionVersionId) {
            data["device_definition_version_id"] = deviceDefinitionVersionId;
        }

        let responseJson = await this.httpService.post(this.buildUrl(`/${entityId}/top_triggers`), data);
        return Promise.resolve(GetTopHittingTriggersResponse.fromJson(responseJson));
    }

    /**
     * Get event counts aggregation.
     */
    @AnalyticsService.reportOnError("API:getEventCountsAggregation")
    public async getEventCountsAggregation(
        entityId: string,
        created_from: number,
        created_to: number,
        aggregateOverProperty: AggregateOverProperty,
        filterEmptyBuckets: boolean,
        deviceDefinitionVersionId?: string
    ): Promise<EventCountsAggregationInfo> {
        let data = {
            created_from: created_from,
            created_to: created_to,
            aggregate_over_property: AggregateOverTimeConvertor.getAggregateOverPropertyName(aggregateOverProperty),
            filter_empty_buckets: filterEmptyBuckets || false,
        };

        if (deviceDefinitionVersionId) {
            data["device_definition_version_id"] = deviceDefinitionVersionId;
        }

        let responseJson = await this.httpService.post(this.buildUrl(`/${entityId}/event_counts_aggregation`), data);
        return Promise.resolve(EventCountsAggregationInfo.fromApiResponse(responseJson));
    }

    /**
     * Gets cve by id.
     */
    @AnalyticsService.reportOnError("API:getCveById")
    public async getCveById(cveId: string): Promise<CveInfo> {
        let endpoint = `/${cveId}`;
        let responseJson = await this.httpService.get(this.buildUrl(endpoint));
        return Promise.resolve(EntityManager.getSternumEntity(responseJson) as CveInfo);
    }

    /**
     * Gets library by id.
     */
    @AnalyticsService.reportOnError("API:getLibraryById")
    @AnalyticsService.reportOnError("API:getLibraryById")
    public async getLibraryById(libraryId: string): Promise<LibraryInfo> {
        let endpoint = `/${libraryId}`;
        let responseJson = await this.httpService.get(this.buildUrl(endpoint));
        return Promise.resolve(EntityManager.getSternumEntity(responseJson) as LibraryInfo);
    }

    /**
     * Gets used library by id.
     */
    @AnalyticsService.reportOnError("API:getUsedLibraryById")
    public async getUsedLibraryById(usedLibraryId: string): Promise<UsedLibraryInfo> {
        let endpoint = `/${usedLibraryId}`;
        let responseJson = await this.httpService.get(this.buildUrl(endpoint));
        return Promise.resolve(EntityManager.getSternumEntity(responseJson) as UsedLibraryInfo);
    }

    /**
     * Update user.
     */
    @AnalyticsService.reportOnError("API:updateUser")
    public async updateUser(userId: string, firstName: string, lastName: string): Promise<UserInfo> {
        let data = {
            first_name: firstName,
            last_name: lastName,
        };

        let endpoint = `/${userId}`;
        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);

        let entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(EntityManager.getSternumEntity(responseJson) as UserInfo);
    }

    /**
     * Update a user password.
     */
    @AnalyticsService.reportOnError("API:updateUserPassword")
    public async updateUserPassword(userId: string, oldPassword: string, newPassword: string): Promise<string> {
        let data = {
            old_password: oldPassword,
            new_password: newPassword,
        };

        let endpoint = `/${userId}/password`;
        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);

        return Promise.resolve(responseJson["token"]);
    }

    /**
     * Gets used libraries.
     */
    @AnalyticsService.reportOnError("API:getUsedLibraries")
    public async getUsedLibraries(
        clientId: string,
        filter?: UsedLibrariesFilter,
        searchText?: string,
        sortByField?: string,
        sortOrder?: string
    ): Promise<UsedLibraryInfo[]> {
        let data = { search_text: searchText };

        let endpoint = `/${clientId}/used_libraries`;
        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);

        let entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(entities.map((entity) => entity as UsedLibraryInfo));
    }

    /**
     * Gets device.
     */
    @AnalyticsService.reportOnError("API:getDevice")
    public async getDevice(deviceId: string): Promise<DeviceInfo> {
        let endpoint = `/${deviceId}`;
        let responseJson = await this.httpService.get(this.buildUrl(endpoint));

        let entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(entities.map((entity) => entity as DeviceInfo)[0]);
    }

    /**
     * Gets devices.
     */
    @AnalyticsService.reportOnError("API:getDevices")
    public async getDevices({
        clientId,
        doNotReturnEntities,
        returnTotalCount,
        devicesFilter,
        searchText,
        sortByField,
        sortOrder,
        offset,
        limit,
    }: {
        clientId: string;
        doNotReturnEntities: boolean;
        returnTotalCount: boolean;
        devicesFilter?: DevicesFilter;
        searchText?: string;
        sortByField?: string;
        sortOrder?: string;
        offset?: number;
        limit?: number;
    }): Promise<GetDevicesResponse> {
        let endpoint = `/${clientId}/devices/search`;
        let now = Utils.now();

        let data = {
            offset: offset || 0,
            limit: limit || 10,
            do_not_return_entities: doNotReturnEntities,
            return_total_count: offset ? false : returnTotalCount,
        };

        if (searchText && searchText.length) {
            data["search_text"] = searchText;
        }

        if (devicesFilter) {
            if (!Utils.isNullOrUndefined(devicesFilter.ipAddresses)) {
                data["ip_addresses"] = devicesFilter.ipAddresses;
            }

            if (!Utils.isNullOrUndefined(devicesFilter.deviceNames)) {
                data["device_names"] = devicesFilter.deviceNames;
            }

            if (!Utils.isNullOrUndefined(devicesFilter.lastSeenFrom)) {
                data["last_seen_start_time"] = devicesFilter.lastSeenFrom;
            }

            if (!Utils.isNullOrUndefined(devicesFilter.lastSeenTo)) {
                data["last_seen_end_time"] = devicesFilter.lastSeenTo;
            }

            if (!Utils.isNullOrUndefined(devicesFilter.createdFrom)) {
                data["created_start_time"] = devicesFilter.createdFrom;
            }

            if (!Utils.isNullOrUndefined(devicesFilter.createdTo)) {
                data["created_end_time"] = devicesFilter.createdTo;
            }

            if (!Utils.isNullOrUndefined(devicesFilter.usedLibraryIds)) {
                data["used_library_ids"] = devicesFilter.usedLibraryIds;
            }

            if (!Utils.isNullOrUndefined(devicesFilter.onlyVulnerableDevices)) {
                data["only_vulnerable_devices"] = devicesFilter.onlyVulnerableDevices;
            }

            if (!Utils.isNullOrUndefined(devicesFilter.onlyOutOfDateDevices)) {
                data["only_out_of_date_devices"] = devicesFilter.onlyOutOfDateDevices;
            }

            if (devicesFilter.statuses && devicesFilter.statuses.length) {
                for (let i = 0; i < devicesFilter.statuses.length; i++) {
                    let status = devicesFilter.statuses[i];

                    if (status === "Secured") {
                        data["is_secured"] = true;
                        data["last_seen_security_alert_end_time"] =
                            now - SternumConfiguration.getSecurityAlertTimeWindowMillis();
                    } else if (status === "Security Alert") {
                        data["is_secured"] = false;
                        data["last_seen_security_alert_start_time"] =
                            now - SternumConfiguration.getSecurityAlertTimeWindowMillis();
                        data["last_seen_security_alert_end_time"] = now;
                    }
                }
            }

            if (devicesFilter.eventFilters && devicesFilter.eventFilters.length) {
                data["event_filters"] = [];

                for (let i = 0; i < devicesFilter.eventFilters.length; i++) {
                    let eventFilter = devicesFilter.eventFilters[i];

                    if (eventFilter.eventType) {
                        let convertedEventFilter = {
                            event_type: eventFilter.eventType,
                        };

                        if (!Utils.isNullOrUndefined(eventFilter.from)) {
                            convertedEventFilter["start_time"] = eventFilter.from;
                        }

                        if (!Utils.isNullOrUndefined(eventFilter.to)) {
                            convertedEventFilter["end_time"] = eventFilter.to;
                        }

                        data["event_filters"].push(convertedEventFilter);
                    }
                }
            }
        }

        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);
        let entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(
            new GetDevicesResponse(
                entities.map((entity) => entity as DeviceInfo),
                responseJson["total_count"]
            )
        );
    }

    /**
     * Gets activities.
     */
    @AnalyticsService.reportOnError("API:getActivities")
    public async getActivities(
        clientId: string,
        filter: EntitiesFilter,
        searchText: string,
        sortByField: string,
        sortOrder: string,
        offset: number,
        limit: number
    ): Promise<GetActivitiesResponse> {
        let data = {
            offset: offset || 0,
            limit: limit || 10,
        };

        let endpoint = `/${clientId}/activities`;
        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);

        let entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(
            new GetActivitiesResponse(
                entities.map((entity) => entity as ActivityInfo),
                responseJson["total_count"]
            )
        );
    }

    /**
     * Gets sternum device events of a device.
     */
    @AnalyticsService.reportOnError("API:getDeviceSternumDeviceEvents")
    public async getDeviceSternumDeviceEvents(
        entityId: string,
        filter: SternumDeviceEventsFilter,
        searchText: string,
        sortOrder: string,
        includeSternumEventEntitiesIds: string[],
        cursor: string,
        limit: number,
        deviceDefinitionVersionId?: string,
        excludeLinuxViewTraces = false,
        processName?: string,
        aggregation_function?: string,
        aggregation_fields?: string[],
        // used for unique value cursor pagination,
        // the cursor is the last "value" given
        // by unique value search
        after?: any[],
        traceNames?: string[],
        options: { cancelToken?: CancelToken } = {}
    ): Promise<GetSternumDeviceEventsResponseWithCursor | GetSternumUniqueCountEventsResponse> {
        const data = {
            cursor,
            limit: limit || 10,
        };

        if (filter) {
            if (filter.eventInterest) {
                data["event_interest"] = filter.eventInterest;
            }

            if (filter.createdTo) {
                data["created_to"] = filter.createdTo;
            }

            if (filter.createdFrom) {
                data["created_from"] = filter.createdFrom;
            }

            if (filter.lessThanId) {
                data["less_than_id"] = filter.lessThanId;
            }

            if (filter.greaterThanId) {
                data["greater_than_id"] = filter.greaterThanId;
            }

            if (filter.lessThanOrEqualToId) {
                data["less_than_or_equal_id"] = filter.lessThanOrEqualToId;
            }

            if (filter.greaterThanOrEqualToId) {
                data["greater_than_or_equal_id"] = filter.greaterThanOrEqualToId;
            }

            if (filter.onlyAttackTraces) {
                data["only_attack_traces"] = filter.onlyAttackTraces;
            }

            if (filter.traceCategories) {
                data["trace_categories"] = filter.traceCategories;
            }

            if (filter.filterOnlyTriggers) {
                data["filter_only_triggers"] = filter.filterOnlyTriggers;
            }

            if (filter.sternumQuery && !filter.sternumQuery.isEmpty()) {
                data["sternum_query"] = filter.sternumQuery.getServerQueryJson();
            }

            if (filter.traceDefinitionIds && filter.traceDefinitionIds.length) {
                data["trace_definition_ids"] = filter.traceDefinitionIds;
            }
        }

        if (sortOrder) {
            data["sort_order"] = sortOrder;
        }

        if (deviceDefinitionVersionId) {
            data["device_definition_version_id"] = deviceDefinitionVersionId;
        }

        if (includeSternumEventEntitiesIds) {
            data["include_sternum_event_entities_ids"] = includeSternumEventEntitiesIds;
        }

        if (searchText) {
            data["search_text"] = searchText;
        }

        if (excludeLinuxViewTraces) {
            data["exclude_linux_view_traces"] = true;
        }

        if (processName) {
            data["process_name"] = processName;
        }

        if (aggregation_function) {
            data["aggregation_function"] = aggregation_function;
        }

        if (aggregation_fields) {
            data["aggregation_fields"] = aggregation_fields;
        }

        if (after) {
            data["after"] = after;
        }

        if (aggregation_function === AggregationFunctionType.UNIQUE_COUNT) {
            data["trace_definition_ids"] = [ConfigurationService.getAllEventsFilterField().id];
        }

        if (
            // aggregation_function &&
            // aggregation_function === AggregationFunctionType.COUNT &&
            filter &&
            filter.traceDefinitionIds &&
            filter.traceDefinitionIds.includes(ConfigurationService.getDeviceIdArgumentField().id)
        ) {
            data["aggregation_context"] = "DEVICES";
            data["trace_definition_ids"] = undefined;
        }

        if (traceNames?.length) {
            data["trace_names"] = traceNames;
        }

        let endpoint = `/${entityId}/sternum_device_events/search`;
        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data, {
            cancelToken: options.cancelToken,
        });

        if (
            aggregation_function &&
            aggregation_fields &&
            aggregation_function === AggregationFunctionType.UNIQUE_COUNT
        ) {
            /**
             * Special case for unique values
             */
            const entities: SternumUniqueCountEventInfo[] = responseJson["entries"].map((entity) => {
                const columnMap = {};
                aggregation_fields.forEach((field, index) => {
                    columnMap[field] = entity["values"][index];
                });
                columnMap["count"] = entity.count;
                return new SternumUniqueCountEventInfo(columnMap);
            });

            return Promise.resolve(
                new GetSternumUniqueCountEventsResponse(entities, responseJson["total_count"], responseJson["cursor"])
            );
        }

        let entities = EntityManager.getSternumEntities(responseJson);
        let sternumDeviceEventInfos: SternumDeviceEventInfo[] = entities.map(
            (entity) => entity as SternumDeviceEventInfo
        );
        return Promise.resolve(
            new GetSternumDeviceEventsResponseWithCursor(
                sternumDeviceEventInfos,
                responseJson["total_count"],
                responseJson["cursor"]
            )
        );
    }

    /**
     * Gets sternum device events of multiple devices.
     */
    @AnalyticsService.reportOnError("API:getDeviceSternumMultipleDevicesEvents")
    public async getDeviceSternumMultipleDevicesEvents(
        clientId: string,
        deviceIds: string[],
        filter: SternumDeviceEventsFilter,
        searchText: string,
        sortOrder: string,
        includeSternumEventEntitiesIds: string[],
        cursor: string,
        limit: number,
        deviceDefinitionVersionId?: string,
        excludeLinuxViewTraces = false,
        processName?: string,
        aggregation_function?: string,
        aggregation_fields?: string[],
        // used for unique value cursor pagination,
        // the cursor is the last "value" given
        // by unique value search
        after?: any[],
        options: { cancelToken?: CancelToken } = {}
    ): Promise<GetSternumDeviceEventsResponseWithCursor | GetSternumUniqueCountEventsResponse> {
        const data = {
            cursor,
            limit: limit || 10,
        };

        if (deviceIds) {
            data["device_ids"] = deviceIds;
        }

        if (filter) {
            if (filter.eventInterest) {
                data["event_interest"] = filter.eventInterest;
            }

            if (filter.createdTo) {
                data["created_to"] = filter.createdTo;
            }

            if (filter.createdFrom) {
                data["created_from"] = filter.createdFrom;
            }

            if (filter.lessThanId) {
                data["less_than_id"] = filter.lessThanId;
            }

            if (filter.greaterThanId) {
                data["greater_than_id"] = filter.greaterThanId;
            }

            if (filter.onlyAttackTraces) {
                data["only_attack_traces"] = filter.onlyAttackTraces;
            }

            if (filter.traceCategories) {
                data["trace_categories"] = filter.traceCategories;
            }

            if (filter.filterOnlyTriggers) {
                data["filter_only_triggers"] = filter.filterOnlyTriggers;
            }

            if (filter.sternumQuery && !filter.sternumQuery.isEmpty()) {
                data["sternum_query"] = filter.sternumQuery.getServerQueryJson();
            }

            if (filter.traceDefinitionIds && filter.traceDefinitionIds.length) {
                data["trace_definition_ids"] = filter.traceDefinitionIds;
            }
        }

        if (sortOrder) {
            data["sort_order"] = sortOrder;
        }

        if (deviceDefinitionVersionId) {
            data["device_definition_version_id"] = deviceDefinitionVersionId;
        }

        if (includeSternumEventEntitiesIds) {
            data["include_sternum_event_entities_ids"] = includeSternumEventEntitiesIds;
        }

        if (searchText) {
            data["search_text"] = searchText;
        }

        if (excludeLinuxViewTraces) {
            data["exclude_linux_view_traces"] = true;
        }

        if (processName) {
            data["process_name"] = processName;
        }

        if (aggregation_function) {
            data["aggregation_function"] = aggregation_function;
        }

        if (aggregation_fields) {
            data["aggregation_fields"] = aggregation_fields;
        }

        if (after) {
            data["after"] = after;
        }

        if (aggregation_function === AggregationFunctionType.UNIQUE_COUNT) {
            data["trace_definition_ids"] = [ConfigurationService.getAllEventsFilterField().id];
        }

        if (
            // aggregation_function &&
            // aggregation_function === AggregationFunctionType.COUNT &&
            filter &&
            filter.traceDefinitionIds &&
            filter.traceDefinitionIds.includes(ConfigurationService.getDeviceIdArgumentField().id)
        ) {
            data["aggregation_context"] = "DEVICES";
            data["trace_definition_ids"] = undefined;
        }

        let endpoint = `/${clientId}/sternum_device_events/search`;
        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data, {
            cancelToken: options.cancelToken,
        });

        if (
            aggregation_function &&
            aggregation_fields &&
            aggregation_function === AggregationFunctionType.UNIQUE_COUNT
        ) {
            /**
             * Special case for unique values
             */
            const entities: SternumUniqueCountEventInfo[] = responseJson["entries"].map((entity) => {
                const columnMap = {};
                aggregation_fields.forEach((field, index) => {
                    columnMap[field] = entity["values"][index];
                });
                columnMap["count"] = entity.count;
                return new SternumUniqueCountEventInfo(columnMap);
            });

            return Promise.resolve(
                new GetSternumUniqueCountEventsResponse(entities, responseJson["total_count"], responseJson["cursor"])
            );
        }

        let entities = EntityManager.getSternumEntities(responseJson);
        let sternumDeviceEventInfos: SternumDeviceEventInfo[] = entities.map(
            (entity) => entity as SternumDeviceEventInfo
        );
        return Promise.resolve(
            new GetSternumDeviceEventsResponseWithCursor(
                sternumDeviceEventInfos,
                responseJson["total_count"],
                responseJson["cursor"]
            )
        );
    }

    /**
     * Gets device kernel logs
     */
    @AnalyticsService.reportOnError("API:getDeviceSternumDeviceKernelLogBoots")
    public async getDeviceSternumDeviceKernelLogBoots(
        entityId: string,
        filter?: SternumDeviceEventsFilter,
        firstEntityCreatedAt?: number,
        searchText?: string,
        offset?: number,
        limit?: number
    ): Promise<GetSternumDeviceEventsResponse> {
        const data = {
            offset: offset || 0,
            limit: limit || 10,
        };

        if (filter) {
            if (filter.createdTo) {
                data["created_to"] = filter.createdTo;
            }

            if (filter.createdFrom) {
                data["created_from"] = filter.createdFrom;
            }
        }

        if (firstEntityCreatedAt) {
            data["created_freeze"] = firstEntityCreatedAt;
        }

        if (searchText) {
            data["search_text"] = searchText;
        }

        let endpoint = `/${entityId}/linux-view/kernel-log/boots`;
        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);

        let entities = EntityManager.getSternumEntities(responseJson);
        let sternumDeviceEventInfos: SternumDeviceEventInfo[] = entities.map(
            (entity) => entity as SternumDeviceEventInfo
        );
        return Promise.resolve(
            new GetSternumDeviceEventsResponse(sternumDeviceEventInfos, responseJson["total_count"])
        );
    }

    /**
     * Gets device boot message
     */
    @AnalyticsService.reportOnError("API:getDeviceSternumDeviceKernelLogBootMessages")
    public async getDeviceSternumDeviceKernelLogBootMessages(
        entityId: string,
        filter?: SternumDeviceEventsFilter,
        searchText?: string,
        offset?: number,
        limit?: number
    ): Promise<GetSternumDeviceEventsResponse> {
        const data = {
            offset: offset || 0,
            limit: limit || 10,
        };

        if (filter) {
            if (filter.createdFrom) {
                data["created_from"] = filter.createdFrom;
            }
        }

        if (searchText) {
            data["search_text"] = searchText;
        }

        let endpoint = `/${entityId}/linux-view/kernel-log/messages`;
        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);

        let entities = EntityManager.getSternumEntities(responseJson);
        let sternumDeviceEventInfos: SternumDeviceEventInfo[] = entities.map(
            (entity) => entity as SternumDeviceEventInfo
        );
        return Promise.resolve(
            new GetSternumDeviceEventsResponse(sternumDeviceEventInfos, responseJson["total_count"])
        );
    }

    @AnalyticsService.reportOnError("API:getDeviceSternumDeviceKernelLogAllMessages")
    public async getDeviceSternumDeviceKernelLogAllMessages({
        entityId,
        searchText,
        offset,
        limit,
    }: {
        entityId: string;
        searchText?: string;
        offset?: number;
        limit?: number;
    }): Promise<GetSternumDeviceEventsResponse> {
        const data = {
            offset: offset || 0,
            limit: limit || 10,
        };

        if (searchText) {
            data["search_text"] = searchText;
        }

        let endpoint = `/${entityId}/linux-view/kernel-log/all-messages`;
        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);

        let entities = EntityManager.getSternumEntities(responseJson);
        let sternumDeviceEventInfos: SternumDeviceEventInfo[] = entities.map(
            (entity) => entity as SternumDeviceEventInfo
        );
        return Promise.resolve(
            new GetSternumDeviceEventsResponse(sternumDeviceEventInfos, responseJson["total_count"])
        );
    }

    /**
     * Gets device commands list
     */
    @AnalyticsService.reportOnError("API:getDeviceSternumDeviceCommands")
    public async getDeviceSternumDeviceCommands(
        entityId: string,
        filter?: SternumDeviceEventsFilter,
        searchText?: string,
        offset?: number,
        limit?: number
    ): Promise<GetSternumDeviceEventsResponse> {
        const data = {
            offset: offset || 0,
            limit: limit || 10,
        };

        if (filter) {
            if (filter.createdTo) {
                data["created_to"] = filter.createdTo;
            }

            if (filter.createdFrom) {
                data["created_from"] = filter.createdFrom;
            }
        }

        if (searchText) {
            data["search_text"] = searchText;
        }

        let endpoint = `/${entityId}/linux-view/command-output`;
        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);

        let entities = EntityManager.getSternumEntities(responseJson);
        let sternumDeviceEventInfos: SternumDeviceEventInfo[] = entities.map(
            (entity) => entity as SternumDeviceEventInfo
        );
        return Promise.resolve(
            new GetSternumDeviceEventsResponse(sternumDeviceEventInfos, responseJson["total_count"])
        );
    }

    /**
     * Gets device processes info list
     */
    @AnalyticsService.reportOnError("API:getDeviceSternumDeviceProcessInfo")
    public async getDeviceSternumDeviceProcessInfo(
        entityId: string,
        filter?: SternumDeviceEventsFilter,
        firstEntityCreatedAt?: number,
        searchText?: string,
        offset?: number,
        limit?: number
    ): Promise<GetSternumDeviceEventsResponse> {
        const data = {
            offset: offset || 0,
            limit: limit || 10,
        };

        if (filter) {
            if (filter.createdTo) {
                data["created_to"] = filter.createdTo;
            }

            if (filter.createdFrom) {
                data["created_from"] = filter.createdFrom;
            }
        }

        if (firstEntityCreatedAt) {
            data["created_freeze"] = firstEntityCreatedAt;
        }

        if (searchText) {
            data["search_text"] = searchText;
        }

        let endpoint = `/${entityId}/linux-view/process-info`;
        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);

        let entities = EntityManager.getSternumEntities(responseJson);
        let sternumDeviceEventInfos: SternumDeviceEventInfo[] = entities.map(
            (entity) => entity as SternumDeviceEventInfo
        );
        return Promise.resolve(
            new GetSternumDeviceEventsResponse(sternumDeviceEventInfos, responseJson["total_count"])
        );
    }

    /**
     * Gets device processes list
     */
    @AnalyticsService.reportOnError("API:getDeviceSternumDeviceSampleProcesses")
    public async getDeviceSternumDeviceSampleProcesses(
        entityId: string,
        sampleId: number,
        filter?: SternumDeviceEventsFilter,
        firstEntityCreatedAt?: number,
        searchText?: string,
        offset?: number,
        limit?: number
    ): Promise<GetSternumDeviceEventsResponse> {
        const data = {
            offset: offset || 0,
            limit: limit || 10,
        };

        if (filter) {
            if (filter.createdTo) {
                data["created_to"] = filter.createdTo;
            }

            if (filter.createdFrom) {
                data["created_from"] = filter.createdFrom;
            }
        }

        if (firstEntityCreatedAt) {
            data["created_freeze"] = firstEntityCreatedAt;
        }

        if (searchText) {
            data["search_text"] = searchText;
        }

        let endpoint = `/${entityId}/linux-view/process-info/${sampleId}`;
        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);

        let entities = EntityManager.getSternumEntities(responseJson);
        let sternumDeviceEventInfos: SternumDeviceEventInfo[] = entities.map(
            (entity) => entity as SternumDeviceEventInfo
        );
        return Promise.resolve(
            new GetSternumDeviceEventsResponse(sternumDeviceEventInfos, responseJson["total_count"])
        );
    }

    /**
     * Gets device kmodule boots
     */
    @AnalyticsService.reportOnError("API:getDeviceSternumDeviceKernelModuleBoots")
    public async getDeviceSternumDeviceKernelModuleBoots(
        entityId: string,
        filter?: SternumDeviceEventsFilter,
        firstEntityCreatedAt?: number,
        searchText?: string,
        offset?: number,
        limit?: number
    ): Promise<GetSternumDeviceEventsResponse> {
        const data = {
            offset: offset || 0,
            limit: limit || 10,
        };

        if (filter) {
            if (filter.createdTo) {
                data["created_to"] = filter.createdTo;
            }

            if (filter.createdFrom) {
                data["created_from"] = filter.createdFrom;
            }
        }

        if (firstEntityCreatedAt) {
            data["created_freeze"] = firstEntityCreatedAt;
        }

        if (searchText) {
            data["search_text"] = searchText;
        }

        let endpoint = `/${entityId}/linux-view/kernel-modules/boots`;
        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);

        let entities = EntityManager.getSternumEntities(responseJson);
        let sternumDeviceEventInfos: SternumDeviceEventInfo[] = entities.map(
            (entity) => entity as SternumDeviceEventInfo
        );
        return Promise.resolve(
            new GetSternumDeviceEventsResponse(sternumDeviceEventInfos, responseJson["total_count"])
        );
    }

    /**
     * Gets device kmodule changes
     */
    @AnalyticsService.reportOnError("API:getDeviceSternumDeviceKernelModuleChanges")
    public async getDeviceSternumDeviceKernelModuleChanges(
        entityId: string,
        filter?: SternumDeviceEventsFilter,
        searchText?: string,
        offset?: number,
        limit?: number
    ): Promise<GetSternumDeviceEventsResponse> {
        const data = {
            offset: offset || 0,
            limit: limit || 10,
        };

        if (filter) {
            if (filter.createdTo) {
                data["created_to"] = filter.createdTo;
            }

            if (filter.createdFrom) {
                data["created_from"] = filter.createdFrom;
            }
        }

        if (searchText) {
            data["search_text"] = searchText;
        }

        let endpoint = `/${entityId}/linux-view/kernel-modules/changes`;
        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);

        let entities = EntityManager.getSternumEntities(responseJson);
        let sternumDeviceEventInfos: SternumDeviceEventInfo[] = entities.map(
            (entity) => entity as SternumDeviceEventInfo
        );
        return Promise.resolve(
            new GetSternumDeviceEventsResponse(sternumDeviceEventInfos, responseJson["total_count"])
        );
    }

    @AnalyticsService.reportOnError("API:getDeviceSternumDeviceAllKernelModules")
    public async getDeviceSternumDeviceAllKernelModules({
        entityId,
        filter,
        searchText,
    }: {
        entityId: string;
        filter?: SternumDeviceEventsFilter;
        searchText?: string;
    }): Promise<LinuxAllKernelModules> {
        const data = Utils.removeEmptyRecords({
            created_to: filter?.createdTo,
            created_from: filter?.createdFrom,
            search_text: searchText,
        });

        const endpoint = `/${entityId}/linux-view/all-kernel-modules`;
        const responseJson = (await this.httpService.post(
            this.buildUrl(endpoint),
            data
        )) as LinuxAllKernelModulesResponseApi;

        const result: LinuxAllKernelModules = {
            modules:
                responseJson?.modules?.map((resp) => ({
                    module: resp.module,
                    firstSeen: new Date(resp.first_seen),
                })) || [],
        };

        return result;
    }

    /**
     * Gets device hardware boots
     */
    @AnalyticsService.reportOnError("API:getDeviceSternumDeviceHardwareBoots")
    public async getDeviceSternumDevicHardwareBoots(
        entityId: string,
        filter?: SternumDeviceEventsFilter,
        firstEntityCreatedAt?: number,
        searchText?: string,
        offset?: number,
        limit?: number
    ): Promise<GetSternumDeviceEventsResponse> {
        const data = {
            offset: offset || 0,
            limit: limit || 10,
        };

        if (filter) {
            if (filter.createdTo) {
                data["created_to"] = filter.createdTo;
            }

            if (filter.createdFrom) {
                data["created_from"] = filter.createdFrom;
            }
        }

        if (firstEntityCreatedAt) {
            data["created_freeze"] = firstEntityCreatedAt;
        }

        if (searchText) {
            data["search_text"] = searchText;
        }

        let endpoint = `/${entityId}/linux-view/hardware/boots`;
        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);

        let entities = EntityManager.getSternumEntities(responseJson);
        let sternumDeviceEventInfos: SternumDeviceEventInfo[] = entities.map(
            (entity) => entity as SternumDeviceEventInfo
        );
        return Promise.resolve(
            new GetSternumDeviceEventsResponse(sternumDeviceEventInfos, responseJson["total_count"])
        );
    }

    /**
     * Gets device hardware boot messages
     */
    @AnalyticsService.reportOnError("API:getDeviceSternumDeviceHardwareBootMessages")
    public async getDeviceSternumDeviceHardwareBootMessages(
        entityId: string,
        filter?: SternumDeviceEventsFilter,
        searchText?: string,
        offset?: number,
        limit?: number
    ): Promise<GetSternumDeviceEventsResponse> {
        const data = {
            offset: offset || 0,
            limit: limit || 10,
        };

        if (filter) {
            if (filter.createdTo) {
                data["created_to"] = filter.createdTo;
            }

            if (filter.createdFrom) {
                data["created_from"] = filter.createdFrom;
            }
        }

        if (searchText) {
            data["search_text"] = searchText;
        }

        let endpoint = `/${entityId}/linux-view/hardware/messages`;
        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);

        let entities = EntityManager.getSternumEntities(responseJson);
        let sternumDeviceEventInfos: SternumDeviceEventInfo[] = entities.map(
            (entity) => entity as SternumDeviceEventInfo
        );
        return Promise.resolve(
            new GetSternumDeviceEventsResponse(sternumDeviceEventInfos, responseJson["total_count"])
        );
    }

    /**
     * Gets device processes
     */
    @AnalyticsService.reportOnError("API:getDeviceSternumDeviceProcesses")
    public async getDeviceSternumDevicProcesses(
        entityId: string,
        filter?: SternumDeviceEventsFilter,
        searchText?: string,
        offset?: number,
        limit?: number
    ): Promise<GetSternumDeviceEventsResponse> {
        const data = {
            offset: offset || 0,
            limit: limit || 10,
        };

        if (filter) {
            if (filter.createdTo) {
                data["created_to"] = filter.createdTo;
            }

            if (filter.createdFrom) {
                data["created_from"] = filter.createdFrom;
            }
        }

        if (searchText) {
            data["search_text"] = searchText;
        }

        let endpoint = `/${entityId}/linux-view/process-traces`;
        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);

        let entities = EntityManager.getSternumEntities(responseJson);
        let sternumDeviceEventInfos: SternumDeviceEventInfo[] = entities.map(
            (entity) => entity as SternumDeviceEventInfo
        );
        return Promise.resolve(
            new GetSternumDeviceEventsResponse(sternumDeviceEventInfos, responseJson["total_count"])
        );
    }

    @AnalyticsService.reportOnError("API:getReceivedEventDefinitionCounters")
    public async getReceivedEventDefinitionCounters(
        clientId: string,
        deviceDefinitionVersionIds: string[],
        created_from: number,
        created_to: number
    ): Promise<GetReceivedEventDefinitionCountersResponse> {
        const response = await this.httpService.post(this.buildUrl(`/${clientId}/received_event_definition_counters`), {
            created_from: created_from,
            created_to: created_to,
            only_definitions_of_type: ["EVENT_DEFINITIONS", "ARGUMENT_DEFINITIONS"],
            device_definition_version_ids: deviceDefinitionVersionIds,
        });

        return response as GetReceivedEventDefinitionCountersResponse;
    }

    /**
     * Gets device process traces
     */
    @AnalyticsService.reportOnError("API:getDeviceSternumDeviceProcessTraces")
    public async getDeviceSternumDeviceProcessTraces(
        entityId: string,
        processName: string,
        filter?: SternumDeviceEventsFilter,
        firstEntityCreatedAt?: number,
        searchText?: string,
        offset?: number,
        limit?: number
    ): Promise<GetSternumDeviceEventsResponse> {
        const data = {
            offset: offset || 0,
            limit: limit || 10,
        };

        if (filter) {
            if (filter.createdTo) {
                data["created_to"] = filter.createdTo;
            }

            if (filter.createdFrom) {
                data["created_from"] = filter.createdFrom;
            }
        }

        if (firstEntityCreatedAt) {
            data["created_freeze"] = firstEntityCreatedAt;
        }

        if (searchText) {
            data["search_text"] = searchText;
        }

        let endpoint = `/${entityId}/linux-view/process-traces/${processName}`;
        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);

        let entities = EntityManager.getSternumEntities(responseJson);
        let sternumDeviceEventInfos: SternumDeviceEventInfo[] = entities.map(
            (entity) => entity as SternumDeviceEventInfo
        );
        return Promise.resolve(
            new GetSternumDeviceEventsResponse(sternumDeviceEventInfos, responseJson["total_count"])
        );
    }

    /**
     * Gets sternum device events of a device.
     */
    @AnalyticsService.reportOnError("API:exportSternumEvents")
    public async exportSternumEvents(
        entityId: string,
        filter: SternumDeviceEventsFilter,
        searchText: string,
        sortOrder: string,
        includeSternumEventEntitiesIds: string[],
        offset?: number,
        limit?: number,
        deviceDefinitionVersionId?: string,
        format: "CSV" | "XLSX" = "CSV",
        excludeLinuxViewTraces = false
    ): Promise<HttpResponse> {
        let data = {
            offset: offset || 0,
            limit: limit || 10,
        };

        if (filter) {
            if (filter.eventInterest) {
                data["event_interest"] = filter.eventInterest;
            }

            if (filter.createdTo) {
                data["created_to"] = filter.createdTo;
            }

            if (filter.createdFrom) {
                data["created_from"] = filter.createdFrom;
            }

            if (filter.lessThanId) {
                data["less_than_id"] = filter.lessThanId;
            }

            if (filter.greaterThanId) {
                data["greater_than_id"] = filter.greaterThanId;
            }

            if (filter.onlyAttackTraces) {
                data["only_attack_traces"] = filter.onlyAttackTraces;
            }

            if (filter.traceCategories) {
                data["trace_categories"] = filter.traceCategories;
            }

            if (filter.filterOnlyTriggers) {
                data["filter_only_triggers"] = filter.filterOnlyTriggers;
            }

            if (filter.sternumQuery && !filter.sternumQuery.isEmpty()) {
                data["sternum_query"] = filter.sternumQuery.getServerQueryJson();
            }

            if (filter.traceDefinitionIds && filter.traceDefinitionIds.length) {
                data["trace_definition_ids"] = filter.traceDefinitionIds;
            }
        }

        if (deviceDefinitionVersionId) {
            data["device_definition_version_id"] = deviceDefinitionVersionId;
        }

        if (sortOrder) {
            data["sort_order"] = sortOrder;
        }

        if (includeSternumEventEntitiesIds) {
            data["include_sternum_event_entities_ids"] = includeSternumEventEntitiesIds;
        }

        if (searchText) {
            data["search_text"] = searchText;
        }

        if (format) {
            data["format"] = format;
        }

        if (excludeLinuxViewTraces) {
            data["exclude_linux_view_traces"] = true;
        }

        let endpoint = `/${entityId}/export_events`;
        let responseBlob = await this.httpService.downloadWithData(this.buildUrl(endpoint), data);
        return Promise.resolve(responseBlob as HttpResponse);
    }

    /**
     * Gets client active 3rd party libraries report
     */
    @AnalyticsService.reportOnError("API:exportClient3rdPartyLibs")
    public async exportClient3rdPartyLibraries(
        entityId: string,
        format: "CSV" | "XLSX" = "CSV"
    ): Promise<HttpResponse> {
        let data = {};

        if (format) {
            data["format"] = format;
        }

        let endpoint = `/${entityId}/export_3rd_libraries`;
        let responseBlob = await this.httpService.downloadWithData(this.buildUrl(endpoint), data);
        return Promise.resolve(responseBlob as HttpResponse);
    }

    /**
     * Gets the trace categories in Sternum.
     */
    @AnalyticsService.reportOnError("API:getTraceCategories")
    public async getTraceCategories(): Promise<TraceCategory[]> {
        let endpoint = `/sternum_categories`;
        let responseJson = await this.httpService.get(this.buildUrl(endpoint));

        let entities = responseJson["entities"].map((entity) => TraceCategory.fromJsonObject(entity));
        return Promise.resolve(entities);
    }

    /**
     * Gets the trace categories in Sternum.
     */
    @AnalyticsService.reportOnError("API:getSternumConfigurations")
    public async getSternumConfigurations(): Promise<SternumAgentConfiguration> {
        let endpoint = `/sternum_configurations`;
        let responseJson = await this.httpService.get(this.buildUrl(endpoint));

        let argumentRoleTypes: ArgumentRoleType[] = responseJson["argument_role_types"].map((entity) =>
            ArgumentRoleType.fromJsonObject(entity)
        );
        let traceCategories: TraceCategory[] = responseJson["trace_categories"].map((entity) =>
            TraceCategory.fromJsonObject(entity)
        );
        let speciaFields: SpecialField[] = responseJson["special_fields"].map((entity) =>
            SpecialField.fromJsonObject(entity)
        );
        let eventInterests: EventInterestDetailed[] = responseJson["event_interests"].map((entity) =>
            EventInterestDetailed.fromJsonObject(entity)
        );

        return Promise.resolve(
            new SternumAgentConfiguration(argumentRoleTypes, traceCategories, speciaFields, eventInterests)
        );
    }

    /**
     * Gets Sternum basic roles.
     */
    @AnalyticsService.reportOnError("API:getSternumBasicRoles")
    public async getSternumBasicRoles(): Promise<SternumRole[]> {
        let endpoint = `/sternum_basic_roles`;
        let responseJson = await this.httpService.get(this.buildUrl(endpoint));

        let sternumRoles: SternumRole[] = responseJson["basic_roles"].map((entity) =>
            SternumRole.fromJsonObject(entity)
        );

        return Promise.resolve(sternumRoles);
    }

    /**
     * Gets Sternum trigger types.
     */
    @AnalyticsService.reportOnError("API:getSternumTriggerTypes")
    public async getSternumTriggerTypes(): Promise<SternumTriggerTypeDisplayInfo[]> {
        let endpoint = `/sternum_trigger_types`;
        let responseJson = await this.httpService.get(this.buildUrl(endpoint));

        let entities = responseJson["entities"].map((entity) => SternumTriggerTypeDisplayInfo.fromJsonObject(entity));
        return Promise.resolve(entities);
    }

    /**
     * Gets Sternum system traces.
     */
    @AnalyticsService.reportOnError("API:getSystemTraces")
    public async getSystemTraces(): Promise<SternumDisplayObjectInfo[]> {
        let endpoint = `/sternum_trace_system_definitions`;
        let responseJson = await this.httpService.get(this.buildUrl(endpoint));

        let entities = responseJson["entities"].map((entity) => SternumDisplayObjectInfo.fromJsonObject(entity));
        return Promise.resolve(entities);
    }

    /**
     * Gets Sternum system .
     */
    @AnalyticsService.reportOnError("API:getSystemArguments")
    public async getSystemArguments(): Promise<SternumDisplayObjectInfo[]> {
        let endpoint = `/sternum_argument_system_definitions`;
        let responseJson = await this.httpService.get(this.buildUrl(endpoint));

        let entities = responseJson["entities"].map((entity) => SternumDisplayObjectInfo.fromJsonObject(entity));
        return Promise.resolve(entities);
    }

    /**
     * Gets device definitions.
     */
    @AnalyticsService.reportOnError("API:getDeviceDefinitions")
    public async getDeviceDefinitions(
        clientId: string,
        offset: number,
        limit: number
    ): Promise<DeviceDefinitionInfo[]> {
        let data = {
            offset: offset || 0,
            limit: limit || 10,
        };

        let endpoint = `/${clientId}/device_definitions`;
        let responseJson = await this.httpService.get(this.buildUrl(endpoint), data);
        let entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(entities.map((entity) => entity as DeviceDefinitionInfo));
    }

    /**
     * Gets device definitions.
     */
    @AnalyticsService.reportOnError("API:getDeviceDefinitionVersions")
    public async getDeviceDefinitionVersions(
        clientId: string,
        offset: number,
        limit: number,
        searchTerm?: string
    ): Promise<DeviceDefinitionVersionInfo[]> {
        let queryParams = {
            offset: offset || 0,
            limit: limit || 10,
            search_term: searchTerm,
        };

        let endpoint = `/${clientId}/device_definition_versions`;
        let responseJson = await this.httpService.get(this.buildUrl(endpoint), queryParams);
        let entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(entities.map((entity) => entity as DeviceDefinitionVersionInfo));
    }

    /**
     * Gets device definitions.
     */
    @AnalyticsService.reportOnError("API:getClientFleetView")
    public async getClientFleetView(clientId: string, offset: number, limit: number): Promise<SternumSelectData[]> {
        const queryParams = {
            offset: offset || 0,
            limit: limit || 10,
        };

        const endpoint = `/${clientId}/client_fleet`;
        const responseJson = await this.httpService.get(this.buildUrl(endpoint), queryParams);
        const entities = responseJson ? responseJson["entities"] : [];

        return Promise.resolve(
            entities.map((entity) => {
                return {
                    displayName: entity["display_name"],
                    entityId: entity["entity_id"],
                    isDefault: entity["is_default_fleet_view"],
                } as SternumSelectData;
            })
        );
    }

    /**
     * Gets a map of device definition id to devices count.
     */
    @AnalyticsService.reportOnError("API:getDeviceDefinitionsDeviceCounts")
    public async getDeviceDefinitionsDeviceCounts(clientId: string): Promise<Record<string, number>> {
        let endpoint = `/${clientId}/device_definitions_devices_count`;
        let responseJson = await this.httpService.get(this.buildUrl(endpoint));

        return Promise.resolve(responseJson["device_definition_id_to_counts_map"]);
    }

    /**
     * Gets multiple devices graph
     */
    @AnalyticsService.reportOnError("API:getMultipleDevicesGraph")
    public async getMultipleDevicesGraph(
        clientId: string,
        deviceId?: string,
        startTime?: number,
        endTime?: number
    ): Promise<MultipleDevicesGraphRelationshipResponse> {
        const now = Date.now();
        let endpoint = `/${clientId}/${deviceId}/relationships`;
        let responseJson = await this.httpService
            .post(this.buildUrl(endpoint), {
                created_from: startTime ?? moment(now).subtract(7, "days").toDate().getTime(),
                created_to: endTime ?? now,
                max_depth: 2,
            })
            .catch(() => ({}));

        const mapGraphRelatedDevices = (relatedDevices: Object[]): MultipleDeviceGraphRelatedDevices[] => {
            return relatedDevices?.map?.((relatedDevice) => ({
                device: relatedDevice["device"] || {},
                related_devices: mapGraphRelatedDevices(relatedDevice["related_devices"] || []),
            }));
        };

        const multipleDevicesGraphRelationshipResponse: MultipleDevicesGraphRelationshipResponse = {
            graph: {
                device: responseJson["entities"]?.[0]?.device || ({} as any),
                related_devices: mapGraphRelatedDevices(responseJson["entities"]?.[0]?.related_devices || []),
            },
            related_entities_map: responseJson["related_entities_map"] || {},
        };

        return multipleDevicesGraphRelationshipResponse;
    }

    /**
     * Gets device definition.
     */
    @AnalyticsService.reportOnError("API:getDeviceDefinition")
    public async getDeviceDefinition(deviceDefinitionId: string): Promise<DeviceDefinitionInfo> {
        let endpoint = `/${deviceDefinitionId}`;
        let responseJson = await this.httpService.get(this.buildUrl(endpoint));

        let entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(entities.map((entity) => entity as DeviceDefinitionInfo)[0]);
    }

    /**
     * Gets device definition version.
     */
    @AnalyticsService.reportOnError("API:getDeviceDefinitionVersion")
    public async getDeviceDefinitionVersion(deviceDefinitionVersionId: string): Promise<DeviceDefinitionVersionInfo> {
        let endpoint = `/${deviceDefinitionVersionId}`;
        let responseJson = await this.httpService.get(this.buildUrl(endpoint));

        let entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(entities.map((entity) => entity as DeviceDefinitionVersionInfo)[0]);
    }

    /**
     * Gets full device definition version.
     */
    @AnalyticsService.reportOnError("API:getFullDeviceDefinitionVersion")
    public async getFullDeviceDefinitionVersion(
        deviceDefinitionVersionId: string
    ): Promise<DeviceDefinitionVersionInfo> {
        let endpoint = `/${deviceDefinitionVersionId}/full`;
        let responseJson = await this.httpService.get(this.buildUrl(endpoint));

        let entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(entities.map((entity) => entity as DeviceDefinitionVersionInfo)[0]);
    }

    /**
     * Create device definitions.
     */
    @AnalyticsService.reportOnError("API:createDeviceDefinition")
    public async createDeviceDefinition(
        clientId: string,
        deviceDefinition: DeviceDefinitionPartial
    ): Promise<DeviceDefinitionVersionInfo[]> {
        let data = deviceDefinition.toJsonObject();
        let endpoint = `/${clientId}/device_definitions`;
        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);

        let entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(entities.map((entity) => entity as DeviceDefinitionVersionInfo));
    }

    /**
     * Create new device definition version.
     */
    public async createDeviceDefinitionNewVersion(
        deviceDefinitionVersionId: string
    ): Promise<DeviceDefinitionVersionInfo[]> {
        let endpoint = `/${deviceDefinitionVersionId}/new_version`;
        let responseJson = await this.httpService.post(this.buildUrl(endpoint), {});

        let entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(entities.map((entity) => entity as DeviceDefinitionVersionInfo));
    }

    /**
     * Update device definitions.
     */
    @AnalyticsService.reportOnError("API:updateDeviceDefinition")
    public async updateDeviceDefinition(deviceDefinition: DeviceDefinitionInfo): Promise<DeviceDefinitionInfo[]> {
        let data = deviceDefinition.getUpdateJsonObject();

        let endpoint = `/${deviceDefinition.deviceDefinitionId}`;
        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);

        let entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(entities.map((entity) => entity as DeviceDefinitionInfo));
    }

    /**
     * Update device definitions version.
     */
    @AnalyticsService.reportOnError("API:updateDeviceDefinitionVersion")
    public async updateDeviceDefinitionVersion(
        versionId: string,
        versionFirmware: string,
        description: string
    ): Promise<DeviceDefinitionVersionInfo[]> {
        let data = { version_firmware: versionFirmware, description: description };

        let endpoint = `/${versionId}`;
        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);

        let entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(entities.map((entity) => entity as DeviceDefinitionVersionInfo));
    }

    /**
     * Update device definition status.
     */
    @AnalyticsService.reportOnError("API:updateDeviceDefinitionStatus")
    public async updateDeviceDefinitionStatus(
        deviceDefinition: DeviceDefinitionInfo,
        deleteExistingData: boolean
    ): Promise<DeviceDefinitionInfo[]> {
        let data = deviceDefinition.getUpdateStatusJsonObject();
        data["delete_existing_data"] = deleteExistingData;
        let endpoint = `/${deviceDefinition.deviceDefinitionId}/status`;
        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);
        let entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(entities.map((entity) => entity as DeviceDefinitionInfo));
    }

    /**
     * Update device definition version status.
     */
    @AnalyticsService.reportOnError("API:updateDeviceDefinitionVersionStatus")
    public async updateDeviceDefinitionVersionStatus(
        deviceDefinitionVersion: DeviceDefinitionVersionInfo,
        deleteExistingData: boolean
    ): Promise<DeviceDefinitionVersionInfo[]> {
        let data = deviceDefinitionVersion.getUpdateStatusJsonObject();

        data["delete_existing_data"] = deleteExistingData;

        let endpoint = `/${deviceDefinitionVersion.entityId}/status`;

        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);

        let entities = EntityManager.getSternumEntities(responseJson);

        return Promise.resolve(entities.map((entity) => entity as DeviceDefinitionVersionInfo));
    }

    /**
     * Update device definitions.
     */
    @AnalyticsService.reportOnError("API:deviceDefinitionClearHistory")
    public async deviceDefinitionClearHistory(deviceDefinition: DeviceDefinitionInfo): Promise<DeviceDefinitionInfo[]> {
        let data = deviceDefinition.getUpdateStatusJsonObject();

        let endpoint = `/${deviceDefinition.deviceDefinitionId}/clear_history`;
        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);

        let entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(entities.map((entity) => entity as DeviceDefinitionInfo));
    }

    /**
     * Delete device definition.
     */
    @AnalyticsService.reportOnError("API:deleteDeviceDefinition")
    public async deleteDeviceDefinition(deviceDefinition: DeviceDefinitionInfo): Promise<DeviceDefinitionInfo[]> {
        let endpoint = `/${deviceDefinition.deviceDefinitionId}`;

        let responseJson = await this.httpService.delete(this.buildUrl(endpoint));

        let entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(entities.map((entity) => entity as DeviceDefinitionInfo));
    }

    /**
     * Delete device definition version.
     */
    @AnalyticsService.reportOnError("API:deleteDeviceDefinitionVersion")
    public async deleteDeviceDefinitionVersion(
        deviceDefinitionVersionId: string
    ): Promise<DeviceDefinitionVersionInfo[]> {
        let endpoint = `/${deviceDefinitionVersionId}`;

        let responseJson = await this.httpService.delete(this.buildUrl(endpoint));

        let entities = EntityManager.getSternumEntities(responseJson);

        return Promise.resolve(entities.map((entity) => entity as DeviceDefinitionVersionInfo));
    }

    /**
     * Clear device definition version data.
     */
    @AnalyticsService.reportOnError("API:clearDeviceDefinitionVersionData")
    public async clearDeviceDefinitionVersionData(
        deviceDefinitionVersionId: string
    ): Promise<DeviceDefinitionVersionInfo[]> {
        let endpoint = `/${deviceDefinitionVersionId}/clean`;

        let responseJson = await this.httpService.delete(this.buildUrl(endpoint));

        let entities = EntityManager.getSternumEntities(responseJson);

        return Promise.resolve(entities.map((entity) => entity as DeviceDefinitionVersionInfo));
    }

    /**
     * Download device definitions c header.
     */
    @AnalyticsService.reportOnError("API:downloadDeviceDefinitionCustomHeader")
    public async downloadDeviceDefinitionCustomHeader(deviceDefinitionId: string): Promise<HttpResponse> {
        let endpoint = `/${deviceDefinitionId}/generate_custom_header_file`;
        let responseBlob = await this.httpService.download(this.buildUrl(endpoint));
        return Promise.resolve(responseBlob as HttpResponse);
    }

    /**
     * Download device definitions json header.
     */
    @AnalyticsService.reportOnError("API:downloadDeviceDefinitionJsonHeader")
    public async downloadDeviceDefinitionJsonHeader(deviceDefinitionId: string): Promise<HttpResponse> {
        let endpoint = `/${deviceDefinitionId}/generate_json_header_file`;
        let responseBlob = await this.httpService.download(this.buildUrl(endpoint));
        return Promise.resolve(responseBlob as HttpResponse);
    }

    /**
     * Download java traces file.
     */
    @AnalyticsService.reportOnError("API:downloadDeviceDefinitionJavaTracesFile")
    public async downloadDeviceDefinitionJavaTracesFile(deviceDefinitionId: string): Promise<HttpResponse> {
        let endpoint = `/${deviceDefinitionId}/generate_java_trace_file`;
        let responseBlob = await this.httpService.download(this.buildUrl(endpoint));
        return Promise.resolve(responseBlob as HttpResponse);
    }

    /**
     * Download java arguments file.
     */
    @AnalyticsService.reportOnError("API:downloadDeviceDefinitionJavaArgumentsFile")
    public async downloadDeviceDefinitionJavaArgumentsFile(deviceDefinitionId: string): Promise<HttpResponse> {
        let endpoint = `/${deviceDefinitionId}/generate_java_argument_file`;
        let responseBlob = await this.httpService.download(this.buildUrl(endpoint));
        return Promise.resolve(responseBlob as HttpResponse);
    }

    /**
     * Create Trace definition to existing device definition.
     */
    @AnalyticsService.reportOnError("API:createTraceDefinition")
    public async createTraceDefinition(
        deviceDefinitionVersionId: string,
        traceDefinition: TraceDefinitionPartial
    ): Promise<TraceDefinitionInfo[]> {
        let data = traceDefinition.toJsonObject();

        let endpoint = `/${deviceDefinitionVersionId}/trace_definition`;
        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);

        let entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(entities.map((entity) => entity as TraceDefinitionInfo));
    }

    /**
     * Update Trace definition.
     */
    @AnalyticsService.reportOnError("API:updateTraceDefinition")
    public async updateTraceDefinition(
        traceDefinition: TraceDefinitionInfo,
        versionId: string
    ): Promise<TraceDefinitionInfo[]> {
        let data = traceDefinition.toJsonObject();

        // Set device definition version id
        data["device_definition_version_id"] = versionId;

        let endpoint = `/${traceDefinition.traceDefinitionId}`;
        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);

        let entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(entities.map((entity) => entity as TraceDefinitionInfo));
    }

    /**
     * Delete Trace definition.
     */
    @AnalyticsService.reportOnError("API:deleteTraceDefinition")
    public async deleteTraceDefinition(
        deviceDefinitionTraceId: string,
        deviceDefinitionVersionId: string
    ): Promise<TraceDefinitionInfo[]> {
        let endpoint = `/${deviceDefinitionTraceId}`;

        let queryParams = { device_definition_version_id: deviceDefinitionVersionId };

        let responseJson = await this.httpService.delete(this.buildUrl(endpoint), queryParams);

        let entities = EntityManager.getSternumEntities(responseJson);

        return Promise.resolve(entities.map((entity) => entity as TraceDefinitionInfo));
    }

    /**
     * Create Argument definition to existing device definition.
     */
    @AnalyticsService.reportOnError("API:createArgumentDefinition")
    public async createArgumentDefinition(
        deviceDefinitionVersionId: string,
        argumentDefinition: ArgumentDefinitionPartial
    ): Promise<ArgumentDefinitionInfo[]> {
        let data = argumentDefinition.toJsonObject();

        let endpoint = `/${deviceDefinitionVersionId}/argument_definitions`;
        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);

        let entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(entities.map((entity) => entity as ArgumentDefinitionInfo));
    }

    /**
     * Update Argument definition to existing device definition.
     */
    @AnalyticsService.reportOnError("API:updateArgumentDefinition")
    public async updateArgumentDefinition(
        argumentDefinition: ArgumentDefinitionInfo,
        deviceDefinitionVersionId: string
    ): Promise<ArgumentDefinitionInfo[]> {
        let data = argumentDefinition.toJsonObject();

        // Set version id
        data["device_definition_version_id"] = deviceDefinitionVersionId;

        let endpoint = `/${argumentDefinition.argumentDefinitionId}`;
        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);

        let entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(entities.map((entity) => entity as ArgumentDefinitionInfo));
    }

    /**
     * Delete Argument definition to existing device definition.
     */
    @AnalyticsService.reportOnError("API:deleteArgumentDefinition")
    public async deleteArgumentDefinition(
        deviceDefinitionArgumentId: string,
        deviceDefinitionVersionId: string
    ): Promise<ArgumentDefinitionInfo[]> {
        let endpoint = `/${deviceDefinitionArgumentId}`;

        let queryParams = { device_definition_version_id: deviceDefinitionVersionId };

        let responseJson = await this.httpService.delete(this.buildUrl(endpoint), queryParams);

        let entities = EntityManager.getSternumEntities(responseJson);

        return Promise.resolve(entities.map((entity) => entity as ArgumentDefinitionInfo));
    }

    /**
     * Create Library definition to existing device definition.
     */
    @AnalyticsService.reportOnError("API:createDeviceDefinitionLibrary")
    public async createDeviceDefinitionLibrary(
        deviceDefinitionVersionId: string,
        deviceDefinitionLibrary: any
    ): Promise<DeviceDefinitionLibraryInfo[]> {
        let endpoint = `/${deviceDefinitionVersionId}/device_definition_libraries`;
        let responseJson = await this.httpService.post(this.buildUrl(endpoint), {
            libraries: [deviceDefinitionLibrary.toJsonObject()],
        });

        let entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(entities.map((entity) => entity as DeviceDefinitionLibraryInfo));
    }

    /**
     * Update Library definition to existing device definition.
     */
    @AnalyticsService.reportOnError("API:updateDeviceDefinitionLibrary")
    public async updateDeviceDefinitionLibrary(
        deviceDefinitionVersionId: string,
        deviceDefinitionLibrary: DeviceDefinitionLibraryInfo
    ): Promise<DeviceDefinitionLibraryInfo[]> {
        let endpoint = `/${deviceDefinitionLibrary.entityId}`;

        let data = deviceDefinitionLibrary.toJsonObject();
        data["device_definition_version_id"] = deviceDefinitionVersionId;
        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);

        let entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(entities.map((entity) => entity as DeviceDefinitionLibraryInfo));
    }

    /**
     * Delete device definition library entity
     */
    @AnalyticsService.reportOnError("API:deleteDeviceDefinitionLibrary")
    public async deleteDeviceDefinitionLibrary(
        deviceDefinitionLibraryId: string,
        deviceDefinitionVersionId: string
    ): Promise<DeviceDefinitionLibraryInfo[]> {
        let queryParams = { device_definition_version_id: deviceDefinitionVersionId };

        let endpoint = `/${deviceDefinitionLibraryId}`;

        let responseJson = await this.httpService.delete(this.buildUrl(endpoint), queryParams);

        let entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(entities.map((entity) => entity as DeviceDefinitionLibraryInfo));
    }

    /**
     * Create trigger definition to existing device definition.
     */
    @AnalyticsService.reportOnError("API:createTriggerDefinition")
    public async createTriggerDefinition(
        deviceDefinitionVersionId: string,
        triggerDefinition: SternumTriggerInfo
    ): Promise<SternumTriggerInfo[]> {
        let endpoint = `/${deviceDefinitionVersionId}/sternum_triggers`;

        let responseJson = await this.httpService.post(this.buildUrl(endpoint), triggerDefinition.toJsonObject());

        let entities = EntityManager.getSternumEntities(responseJson);

        return Promise.resolve(entities.map((entity) => entity as SternumTriggerInfo));
    }

    /**
     * Update trigger definition
     */
    @AnalyticsService.reportOnError("API:updateTriggerDefinition")
    public async updateTriggerDefinition(
        deviceDefinitionVersionId: string,
        triggerDefinition: SternumTriggerInfo
    ): Promise<SternumTriggerInfo[]> {
        let endpoint = `/${triggerDefinition.sternumTriggerId}`;

        let data = triggerDefinition.toJsonObject();

        data["device_definition_version_id"] = deviceDefinitionVersionId;

        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);

        let entities = EntityManager.getSternumEntities(responseJson);

        return Promise.resolve(entities.map((entity) => entity as SternumTriggerInfo));
    }

    /**
     * Delete trigger definition
     */
    @AnalyticsService.reportOnError("API:deleteTriggerDefinition")
    public async deleteTriggerDefinition(
        triggerDefinitionId: string,
        deviceDefinitionVersionId: string
    ): Promise<SternumTriggerInfo[]> {
        let endpoint = `/${triggerDefinitionId}`;

        let queryParams = { device_definition_version_id: deviceDefinitionVersionId };

        let responseJson = await this.httpService.delete(this.buildUrl(endpoint), queryParams);

        let entities = EntityManager.getSternumEntities(responseJson);

        return Promise.resolve(entities.map((entity) => entity as SternumTriggerInfo));
    }

    /**
     * Update trigger status definition
     */
    @AnalyticsService.reportOnError("API:updateTriggerDefinitionStatus")
    public async updateTriggerDefinitionStatus(
        triggerDefinitionId: string,
        status: boolean,
        deviceDefinitionVersionId: string
    ): Promise<SternumTriggerInfo[]> {
        const data = {
            disabled: status,
            device_definition_version_id: deviceDefinitionVersionId,
        };
        let endpoint = `/${triggerDefinitionId}/update_status`;
        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);

        let entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(entities.map((entity) => entity as SternumTriggerInfo));
    }

    /**
     * Get API keys.
     */

    @AnalyticsService.reportOnError("API:getAPIKeys")
    public async getAPIKeys(clientId: string): Promise<APIKeyInfo[]> {
        let endpoint = `/${clientId}/api_keys`;
        let responseJson = await this.httpService.get(this.buildUrl(endpoint));

        let entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(entities.map((entity) => entity as APIKeyInfo));
    }

    /**
     * Create an API key
     */
    @AnalyticsService.reportOnError("API:createAPIKey")
    public async createAPIKey(clientId: string, displayName: string): Promise<APIKeyInfo> {
        let data = {
            display_name: displayName,
        };

        let endpoint = `/${clientId}/api_keys`;
        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);
        const entity = EntityManager.getSternumEntity(responseJson) as APIKeyInfo;
        return Promise.resolve(
            new APIKeyInfo(entity.entityId, entity.created, entity.updated, entity.displayName, entity.secret)
        );
    }

    /**
     * Delete api key
     */

    @AnalyticsService.reportOnError("API:deleteApiKey")
    public async deleteApiKey(apiKeyId: string): Promise<any> {
        let endpoint = `/${apiKeyId}`;
        let responseJson = await this.httpService.delete(this.buildUrl(endpoint));
        return Promise.resolve(true);
    }

    /**
     * Delete User
     */
    @AnalyticsService.reportOnError("API:deleteUser")
    public async deleteUser(user: UserInfo): Promise<any> {
        let endpoint;
        let responseJson;
        // Check if it's invited user and change url
        if (user.isInvited) {
            endpoint = `/invited_user/${user.userId}`;
            await this.httpService.delete(this.buildUrl(endpoint));
        } else {
            endpoint = `/${user.userId}`;
            await this.httpService.delete(this.buildUrl(endpoint));
        }

        return Promise.resolve(true);
    }

    /**
     * Gets clients.
     */
    @AnalyticsService.reportOnError("API:getClients")
    public async getClients(
        filter: EntitiesFilter,
        searchText: string,
        sortByField: string,
        sortOrder: string,
        offset: number,
        limit: number
    ): Promise<ClientInfo[]> {
        let queryParameters = {
            offset: offset || 0,
            limit: limit || 10,
        };

        let endpoint = `/clients`;
        let responseJson = await this.httpService.get(this.buildUrl(endpoint), queryParameters);

        let entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(entities.map((entity) => entity as ClientInfo));
    }

    /**
     * Gets client data.
     */
    @AnalyticsService.reportOnError("API:getClient")
    public async getClient(clientId: string): Promise<ClientInfo> {
        let endpoint = `/${clientId}/`;

        let responseJson = await this.httpService.get(this.buildUrl(endpoint));

        let entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(entities.map((entity) => entity as ClientInfo)?.[0]);
    }

    /**
     * Update client data.
     */
    @AnalyticsService.reportOnError("API:updateClientOnboardingState")
    public async updateClientOnboardingState(
        clientId: string,
        onboardingState: ClientInfoOnboardingState
    ): Promise<boolean> {
        let endpoint = `/${clientId}/`;

        const updates = {
            onboarding_state: onboardingState,
        };

        let responseJson = await this.httpService.put(this.buildUrl(endpoint), updates);
        return !!responseJson;
    }

    /**
     * Gets users.
     */
    @AnalyticsService.reportOnError("API:getUsers")
    public async getUsers(
        clientId: string,
        filter: EntitiesFilter,
        searchText: string,
        sortByField: string,
        sortOrder: string,
        offset?: number,
        limit?: number
    ): Promise<UserInfo[]> {
        let queryParameters = {
            offset: offset || 0,
            limit: limit || 50,
            search_email_term: searchText,
        };

        let endpoint = `/${clientId}/users`;
        let responseJson = await this.httpService.get(this.buildUrl(endpoint), queryParameters);

        let entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(entities.map((entity) => entity as UserInfo));
    }

    /**
     * Gets invited users.
     */
    @AnalyticsService.reportOnError("API:getInvitedUsers")
    public async getInvitedUsers(
        clientId: string,
        filter: EntitiesFilter,
        searchText: string,
        sortByField: string,
        sortOrder: string,
        offset?: number,
        limit?: number
    ): Promise<UserInfo[]> {
        let queryParameters = {
            offset: offset || 0,
            limit: limit || 50,
            search_email_term: searchText,
        };

        let endpoint = `/${clientId}/invited_users`;
        let responseJson = await this.httpService.get(this.buildUrl(endpoint), queryParameters);

        let entities = EntityManager.getSternumEntities(responseJson);
        let response = entities.map((entity) => entity as UserInfo);
        return Promise.resolve(response);
    }

    /**
     * Gets libraries metrics
     */
    @AnalyticsService.reportOnError("API:getLibrariesMetrics")
    public async getLibrariesMetrics(clientId: string): Promise<MetricInfo[]> {
        let endpoint = `/${clientId}/libraries_metrics`;

        let responseJson = await this.httpService.get(this.buildUrl(endpoint));
        let allMetrics: MetricInfo[] = [];

        for (let key in responseJson) {
            if (responseJson.hasOwnProperty(key)) {
                allMetrics.push(MetricInfo.fromJsonObject(responseJson[key]));
            }
        }

        return Promise.resolve(allMetrics);
    }

    /**
     * Gets devices metrics
     */
    @AnalyticsService.reportOnError("API:getDevicesMetrics")
    public async getDevicesMetrics(clientId: string): Promise<MetricInfo[]> {
        let endpoint = `/${clientId}/device_metrics`;

        let responseJson = await this.httpService.get(this.buildUrl(endpoint));
        let allMetrics: MetricInfo[] = [];

        for (let key in responseJson) {
            if (responseJson.hasOwnProperty(key)) {
                allMetrics.push(MetricInfo.fromJsonObject(responseJson[key]));
            }
        }

        return Promise.resolve(allMetrics);
    }

    /**
     * Gets the operation systems counts map.
     */
    @AnalyticsService.reportOnError("API:getOperationSystemCounts")
    public async getOperationSystemCounts(clientId: string) {
        let endpoint = `/${clientId}/operation_system_counts`;
        return await this.httpService.get(this.buildUrl(endpoint));
    }

    /**
     * Gets the used libraries counts map.
     */
    @AnalyticsService.reportOnError("API:getUsedLibrariesCounts")
    public async getUsedLibrariesCounts(clientId: string) {
        let endpoint = `/${clientId}/used_libraries_counts`;
        return await this.httpService.get(this.buildUrl(endpoint));
    }

    /**
     * Gets the device names counts map.
     */
    @AnalyticsService.reportOnError("API:getDeviceNamesCount")
    public async getDeviceNamesCount(clientId: string) {
        let endpoint = `/${clientId}/device_name_counts`;
        return await this.httpService.get(this.buildUrl(endpoint));
    }

    /**
     * Gets the vulnerable devices count.
     */
    @AnalyticsService.reportOnError("API:getVulnerableDevicesCount")
    public async getVulnerableDevicesCount(clientId: string): Promise<number> {
        let endpoint = `/${clientId}/vulnerable_devices_count`;
        let response = await this.httpService.get(this.buildUrl(endpoint));

        return response["count"];
    }

    /**
     * Gets the security issues summary data.
     */
    @AnalyticsService.reportOnError("API:getSecurityIssuesSummaryData")
    public async getSecurityIssuesSummaryData(
        clientId: string,
        createdFrom: number,
        createdTo: number,
        updatedFrom: number,
        updatedTo: number
    ): Promise<SecurityIssuesData> {
        let queryParameters = {
            createdFrom: createdFrom || null,
            createdTo: createdTo || null,
            updatedFrom: updatedFrom || null,
            updatedTo: updatedTo || null,
        };

        let endpoint = `/${clientId}/security_issues_summary_data`;
        let response = await this.httpService.get(this.buildUrl(endpoint), queryParameters);
        return Promise.resolve(new SecurityIssuesData(response["total"], response["timed_count"]));
    }

    /**
     * Gets the data for the trace activity graph.
     */
    @AnalyticsService.reportOnError("API:getTraceActivityGraphData")
    public async getTraceActivityGraphData(
        clientId: string,
        country: "world" | "US",
        deviceId?: string,
        timeFrame?: number,
        timeDivisionType?: TimeDivisionType
    ) {
        let endpoint = `/${clientId}/trace_activity_graph`;

        let timeDivisionTypeConverted = null;
        switch (timeDivisionType) {
            case TimeDivisionType.DAY:
                timeDivisionTypeConverted = "DAY";
                break;

            case TimeDivisionType.HOUR:
                timeDivisionTypeConverted = "HOUR";
                break;

            case TimeDivisionType.MINUTE:
                timeDivisionTypeConverted = "MINUTE";
                break;
        }

        let data = {
            device_id: deviceId,
            time_frame: timeFrame,
            time_division_type: timeDivisionTypeConverted,
            location: country,
        };
        return await this.httpService.post(this.buildUrl(endpoint), data);
    }

    /**
     * Get device geo location data.
     */

    @AnalyticsService.reportOnError("API:getDeviceGeolocationData")
    public async getDeviceGeolocationData(deviceId: string, startTime: number, endTime: number) {
        const endpoint = `/${deviceId}/geo_location_history`;

        const data = {
            start_time: startTime,
            end_time: endTime,
        };
        return await this.httpService.post(this.buildUrl(endpoint), data);
    }

    /**
     * Get device definition version geo location data.
     */

    @AnalyticsService.reportOnError("API:getDeviceDefinitionVersionGeolocationData")
    public async getDeviceDefinitionVersionGeolocationData(
        deviceDefinitionVersionId: string,
        startTime: number,
        endTime: number
    ) {
        const endpoint = `/${deviceDefinitionVersionId}/geo_location_history`;

        const data = {
            start_time: startTime,
            end_time: endTime,
        };
        return await this.httpService.post(this.buildUrl(endpoint), data);
    }

    /**
     * Resolves an issue.
     */
    @AnalyticsService.reportOnError("API:resolveIssue")
    public async resolveIssue(issueId: string): Promise<IssueInfo> {
        let endpoint = `/${issueId}/resolve`;
        let responseJson = await this.httpService.post(this.buildUrl(endpoint), {});
        return Promise.resolve(EntityManager.getSternumEntity(responseJson) as IssueInfo);
    }

    /**
     * Resolves all issues by client id.
     */
    @AnalyticsService.reportOnError("API:resolveAllIssues")
    public async resolveAllIssues(clientId: string, deviceId?: string): Promise<IssueInfo> {
        let endpoint = `/${clientId}/resolve_all_issues`;
        const data = {};

        if (deviceId) {
            data["device_id"] = [deviceId];
        }

        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);
        return Promise.resolve(EntityManager.getSternumEntity(responseJson) as IssueInfo);
    }

    @AnalyticsService.reportOnError("API:getAdjacentTraces")
    public async getAdjacentTraces({
        clientId,
        deviceId,
        trace,
        searchText,
        limitPreceding,
        limitFollowing,
        order,
        processName = null,
    }: AdjacentTracesArguments): Promise<TraceInfo[]> {
        let precedingTracesPromise = this.getTraces(
            clientId,
            deviceId,
            new TracesFilter(null, null, null, null, null, trace.traceId, null, null),
            searchText,
            null,
            "DESC",
            0,
            limitPreceding,
            processName
        );
        let followingTracesPromise = this.getTraces(
            clientId,
            deviceId,
            new TracesFilter(null, null, null, null, trace.traceId, null, null, null),
            searchText,
            null,
            "ASC",
            0,
            limitFollowing,
            processName
        );
        let tracesResults = await Promise.all([precedingTracesPromise, followingTracesPromise]);

        let precedingTraces = tracesResults[0].filter((precedingTrace) => precedingTrace.entityId !== trace.traceId);
        let precedingTracesIds = new Set(precedingTraces.map((precedingTrace) => precedingTrace.entityId));
        let followingTraces = tracesResults[1].filter(
            (followingTrace) =>
                !precedingTracesIds.has(followingTrace.entityId) && followingTrace.entityId !== trace.traceId
        );

        // Reversing the fetched following traces because they were sorted by ASC
        const traces = followingTraces.reverse().concat([trace]).concat(precedingTraces);

        if (order === "ASC") {
            return traces.reverse();
        }

        return traces;
    }

    /**
     * Gets traces.
     */
    @AnalyticsService.reportOnError("API:getTraces")
    public async getTraces(
        clientId: string,
        deviceId?: string,
        tracesFilter?: TracesFilter,
        searchText?: string,
        sortByField?: string,
        sortOrder?: string,
        offset?: number,
        limit?: number,
        processName?: string
    ): Promise<TraceInfo[]> {
        let data = {};

        if (processName) {
            data["process_name"] = processName;
        }
        if (deviceId) {
            data["device_id"] = deviceId;
        }

        if (offset) {
            data["offset"] = offset;
        } else {
            data["offset"] = 0;
        }

        if (limit) {
            data["limit"] = limit;
        } else {
            data["limit"] = SternumConfiguration.getPageSize();
        }

        if (tracesFilter) {
            // Start time
            if (tracesFilter.startTime) {
                data["start_time"] = tracesFilter.startTime;
            }

            // End time
            if (tracesFilter.endTime) {
                data["end_time"] = tracesFilter.endTime;
            }

            // Trace event types
            if (tracesFilter.traceEventTypes && tracesFilter.traceEventTypes.length) {
                data["trace_event_types"] = tracesFilter.traceEventTypes;
            }

            // Start ID
            if (tracesFilter.startId) {
                data["start_id"] = tracesFilter.startId;
            }

            // End ID
            if (tracesFilter.endId) {
                data["end_id"] = tracesFilter.endId;
            }

            if (tracesFilter.sternumGeneratedEventId) {
                data["sternum_generated_event_id"] = tracesFilter.sternumGeneratedEventId;
            }

            if (tracesFilter.traceCategories && tracesFilter.traceCategories.length) {
                data["trace_categories"] = tracesFilter.traceCategories.filter(
                    (traceCategory) => traceCategory !== "ALL"
                );
            }

            if (sortOrder) {
                data["sort_order"] = sortOrder;
            }
        }

        let endpoint = `/${clientId}/traces/search`;
        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);

        let entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(entities.map((entity) => entity as TraceInfo));
    }

    /**
     * Gets anomaly traces.
     */
    @AnalyticsService.reportOnError("API:getAnomalyTraces")
    public async getAnomalyTraces(issueId: string): Promise<TraceInfo[]> {
        let endpoint = `/${issueId}/anomaly/related_events`;
        let responseJson = await this.httpService.get(this.buildUrl(endpoint));

        let entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(entities.map((entity) => entity as TraceInfo));
    }

    /**
     * Gets device process instances.
     */
    @AnalyticsService.reportOnError("API:getDeviceProcessInstances")
    public async getDeviceProcessInstances(deviceId?: string, limit?: number): Promise<DeviceProcessInstanceInfo[]> {
        let queryParameters = {
            limit: limit || 10,
        };

        let endpoint = `/${deviceId}/process_instances`;
        let responseJson = await this.httpService.get(this.buildUrl(endpoint), queryParameters);

        let entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(entities.map((entity) => entity as DeviceProcessInstanceInfo));
    }

    /**
     * Gets outgoing webhooks.
     */
    @AnalyticsService.reportOnError("API:getOutgoingWebhooks")
    public async getOutgoingWebhooks(clientId: string): Promise<OutgoingWebhookInfo[]> {
        let queryParameters = {};

        let endpoint = `/${clientId}/outgoing_webhooks`;
        let responseJson = await this.httpService.get(this.buildUrl(endpoint), queryParameters);

        let entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(entities.map((entity) => entity as OutgoingWebhookInfo));
    }

    /**
     * Gets device definition libraries.
     */
    @AnalyticsService.reportOnError("API:getDeviceDefinitionLibraries")
    public async getDeviceDefinitionLibraries(
        clientId: string,
        deviceDefinitionLibrariesFilter?: DeviceDefinitionLibrariesFilter,
        searchText?: string,
        sortByField?: string,
        sortOrder?: string,
        offset?: number,
        limit?: number
    ): Promise<DeviceDefinitionLibraryInfo[]> {
        let data = {};

        if (deviceDefinitionLibrariesFilter) {
            if (deviceDefinitionLibrariesFilter.deviceDefinitionId) {
                data["device_definition_id"] = deviceDefinitionLibrariesFilter.deviceDefinitionId;
            }

            if (deviceDefinitionLibrariesFilter.deviceDefinitionVersionId) {
                data["device_definition_version_id"] = deviceDefinitionLibrariesFilter.deviceDefinitionVersionId;
            }

            if (deviceDefinitionLibrariesFilter.libraryId) {
                data["library_id"] = deviceDefinitionLibrariesFilter.libraryId;
            }

            if (deviceDefinitionLibrariesFilter.usedLibraryId) {
                data["used_library_id"] = deviceDefinitionLibrariesFilter.usedLibraryId;
            }

            if (searchText) {
                data["search_text"] = searchText;
            }
        }

        data["offset"] = offset || 0;
        data["limit"] = limit || 10;

        let endpoint = `/${clientId}/device_definition_libraries`;
        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);

        let entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(entities.map((entity) => entity as DeviceDefinitionLibraryInfo));
    }

    /**
     * Gets autocomplete libraries.
     */
    @AnalyticsService.reportOnError("API:getAutocompleteLibraries")
    public async getAutocompleteLibraries(searchTerm: string): Promise<AutoCompleteLibrary[]> {
        let endpoint = `/autocomplete_libraries?search_term=${searchTerm}`;
        let responseJson = await this.httpService.get(this.buildUrl(endpoint));
        let entities = responseJson["entities"].map((entity) => AutoCompleteLibrary.fromJsonObject(entity));
        return Promise.resolve(entities);
    }

    /**
     * Gets cves.
     */
    @AnalyticsService.reportOnError("API:getCves")
    public async getCves(
        clientId: string,
        filter?: CvesFilter,
        searchText?: string,
        sortByField?: string,
        sortOrder?: string,
        offset?: number,
        limit?: number
    ): Promise<GetCvesResponse> {
        let data = { offset: offset ? offset : 0, limit: limit ? limit : SternumConfiguration.getPageSize() };

        if (filter) {
            data["used_library_id"] = filter.usedLibraryId;

            if (filter.createdFrom) {
                data["created_from"] = filter.createdFrom;
            }

            if (filter.createdTo) {
                data["created_to"] = filter.createdTo;
            }

            if (filter.updatedFrom) {
                data["updated_from"] = filter.updatedFrom;
            }

            if (filter.updatedTo) {
                data["updated_to"] = filter.updatedTo;
            }

            if (filter.deviceIds) {
                data["device_ids"] = filter.deviceIds;
            }

            if (filter.deviceDefinitionVersionIds) {
                data["device_definition_version_ids"] = filter.deviceDefinitionVersionIds;
            }

            if (searchText) {
                data["search_text"] = searchText;
            }
        }

        const endpoint = `/${clientId}/cves`;
        const responseJson = await this.httpService.post(this.buildUrl(endpoint), data);

        const entities = EntityManager.getSternumEntities(responseJson);

        return Promise.resolve(
            new GetCvesResponse(
                entities.map((entity) => entity as CveInfo),
                responseJson["total_count"]
            )
        );
    }

    /**
     * Gets libraries.
     */
    @AnalyticsService.reportOnError("API:getLibraries")
    public async getLibraries(
        clientId: string,
        filter?: LibrariesFilter,
        searchText?: string,
        sortByField?: string,
        sortOrder?: string
    ): Promise<LibraryInfo[]> {
        let data = {};

        const endpoint = `/${clientId}/libraries`;
        const responseJson = await this.httpService.post(this.buildUrl(endpoint), data);

        const entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(entities.map((entity) => entity as LibraryInfo));
    }

    /**
     * Compiles a sternum trace query.
     */
    @AnalyticsService.reportOnError("API:compileSternumTraceQuery")
    public async compileSternumTraceQuery(sternumQuery: SternumQuery): Promise<any> {
        const data = {
            trace_query: sternumQuery ? sternumQuery.getServerQueryJson() : null,
        };

        const endpoint = `/compile_sternum_trace_query`;
        const responseJson = await this.httpService.post(this.buildUrl(endpoint), data);
        return Promise.resolve(responseJson);
    }

    /**
     * Gets library histories.
     */
    @AnalyticsService.reportOnError("API:getLibraryHistories")
    public async getLibraryHistories(
        clientId: string,
        filter?: LibraryHistoriesFilter,
        searchText?: string,
        sortByField?: string,
        sortOrder?: string
    ): Promise<LibraryHistoryInfo[]> {
        let data = {};

        if (filter) {
            if (filter.libraryId) {
                data["library_id"] = filter.libraryId;
            }
        }

        const endpoint = `/${clientId}/library_histories`;
        const responseJson = await this.httpService.post(this.buildUrl(endpoint), data);

        const entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(entities.map((entity) => entity as LibraryHistoryInfo));
    }

    /**
     * Gets used library histories.
     */
    @AnalyticsService.reportOnError("API:getUsedLibraryHistories")
    public async getUsedLibraryHistories(
        clientId: string,
        filter?: UsedLibraryHistoriesFilter,
        searchText?: string,
        sortByField?: string,
        sortOrder?: string
    ): Promise<UsedLibraryHistoryInfo[]> {
        let data = {};

        if (filter) {
            if (filter.usedLibraryId) {
                data["used_library_id"] = filter.usedLibraryId;
            }
        }

        const endpoint = `/${clientId}/used_library_histories`;
        const responseJson = await this.httpService.post(this.buildUrl(endpoint), data);

        const entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(entities.map((entity) => entity as UsedLibraryHistoryInfo));
    }

    /**
     * Gets count of traces.
     */
    @AnalyticsService.reportOnError("API:getTracesCount")
    public async getTracesCount(
        clientId: string,
        deviceId?: string,
        tracesFilter?: TracesFilter,
        searchText?: string,
        processName?: string
    ): Promise<number> {
        const endpoint = `/${clientId}/trace_count`;

        let data = {};

        if (deviceId) {
            data["device_id"] = deviceId;
        }

        if (processName) {
            data["process_name"] = processName;
        }

        if (tracesFilter) {
            // Start time
            if (tracesFilter.startTime) {
                data["start_time"] = tracesFilter.startTime;
            }

            // End time
            if (tracesFilter.endTime) {
                data["end_time"] = tracesFilter.endTime;
            }

            // Trace event types
            if (tracesFilter.traceEventTypes && tracesFilter.traceEventTypes.length) {
                data["trace_event_types"] = tracesFilter.traceEventTypes;
            }

            if (tracesFilter.sternumGeneratedEventId) {
                data["sternum_generated_event_id"] = tracesFilter.sternumGeneratedEventId;
            }

            if (tracesFilter.traceCategories && tracesFilter.traceCategories.length) {
                data["trace_categories"] = tracesFilter.traceCategories.filter(
                    (traceCategory) => traceCategory !== "ALL"
                );
            }
        }

        const responseJson = await this.httpService.post(this.buildUrl(endpoint), data);
        return Promise.resolve(responseJson["count"]);
    }

    @AnalyticsService.reportOnError("API:getIssueById")
    public async getIssueById(issueId: string): Promise<IssueInfo> {
        const endpoint = `/${issueId}`;
        const responseJson = await this.httpService.get(this.buildUrl(endpoint));
        return Promise.resolve(EntityManager.getSternumEntity(responseJson) as IssueInfo);
    }

    @AnalyticsService.reportOnError("API:getIssuesResolveReasons")
    public async getIssuesResolveReasons(): Promise<IssuesResolveItem[]> {
        const endpoint = "/issues/resolve";

        const responseJson = await this.httpService.get(this.buildUrl(endpoint));

        if (!(responseJson?.["reasons"] instanceof Array)) {
            return [];
        }

        return responseJson["reasons"].map((response) => ({
            type: response["type"],
            text: response["text"] || "",
        }));
    }

    /**
     * Gets issues.
     */
    @AnalyticsService.reportOnError("API:getIssues")
    public async getIssues(
        clientId: string,
        deviceId?: string,
        traceId?: string,
        issuesFilter?: IssuesFilter,
        searchText?: string,
        sortByField?: string,
        sortOrder?: string,
        offset?: number,
        limit?: number,
        deviceProfileStatus?: "STAGING" | "PRODUCTION",
        deviceDefinitionVersionId?: string
    ): Promise<IssueInfo[]> {
        let data = {};

        if (deviceId) {
            data["device_id"] = deviceId;
        }

        if (deviceDefinitionVersionId) {
            data["ddve_id"] = deviceDefinitionVersionId;
        }

        if (offset) {
            data["offset"] = offset;
        } else {
            data["offset"] = 0;
        }

        if (limit) {
            data["limit"] = limit;
        } else {
            data["limit"] = SternumConfiguration.getPageSize();
        }

        if (issuesFilter) {
            data = { ...data, ...issuesFilter.getJsonObject() };
        }

        if (searchText) {
            data["search_text"] = searchText;
        }

        if (deviceProfileStatus) {
            data["version_status"] = deviceProfileStatus;
        }

        const endpoint = `/${clientId}/issues`;
        const responseJson = await this.httpService.post(this.buildUrl(endpoint), data);

        // INFO: UNCOMMENT THIS TO FIND AN ISSUE THAT USES A GRAPH
        // console.log((responseJson as any).entities.map((e, i) => ({ ...e, i })).filter((e) => !!e.anomaly_type));

        const entities = EntityManager.getSternumEntities(responseJson);
        return Promise.resolve(entities.map((entity) => entity as IssueInfo));
    }

    /**
     * Gets count of issues.
     */
    @AnalyticsService.reportOnError("API:getIssuesCount")
    public async getIssuesCount(
        clientId: string,
        deviceId?: string,
        traceId?: string,
        issuesFilter?: IssuesFilter,
        searchText?: string,
        deviceProfileStatus?: "STAGING" | "PRODUCTION",
        deviceDefinitionVersionId?: string
    ): Promise<number> {
        const endpoint = `/${clientId}/issue_count`;

        let data = {};

        if (deviceId) {
            data["device_id"] = deviceId;
        }

        if (deviceDefinitionVersionId) {
            data["ddve_id"] = deviceDefinitionVersionId;
        }

        if (issuesFilter) {
            data = { ...data, ...issuesFilter.getJsonObject() };
        }
        if (searchText) {
            data["search_text"] = searchText;
        }

        if (deviceProfileStatus) {
            data["version_status"] = deviceProfileStatus;
        }

        const responseJson = await this.httpService.post(this.buildUrl(endpoint), data);
        return Promise.resolve(responseJson["count"]);
    }

    /**
     * Set issue status
     */
    @AnalyticsService.reportOnError("API:putIssueStatus")
    public async putIssueStatus(
        issueId: string,
        status: IssueInfoStatus,
        reasons?: IssueInfoPutReason[]
    ): Promise<unknown> {
        const endpoint = `/${issueId}/status`;

        const data = {
            status,
            reasons: reasons || [],
        };

        return await this.httpService.put(this.buildUrl(endpoint), data);
    }

    /**
     * Gets changed entities since.
     */
    @AnalyticsService.reportOnError("API:getChangedDataSince")
    public async getChangedDataSince(
        clientId: string,
        timestamp: number,
        pollingChangeTypes: string[]
    ): Promise<Object> {
        const endpoint = `/${clientId}/changed_data_since`;

        const data = {
            timestamp: timestamp,
            change_types: pollingChangeTypes,
        };

        const responseJson = await this.httpService.post(this.buildUrl(endpoint), data);
        return Promise.resolve(responseJson);
    }

    /**
     * Sign up command.
     */
    @AnalyticsService.reportOnError("API:signUp")
    public async signUp(
        email: string,
        fullName: string,
        company: string,
        jobTitle: string,
        termsAgreed: boolean,
        captchaToken: string
    ): Promise<AuthenticationCommandResponse> {
        const endpoint = "/trial/clients";
        const data = {
            email: email,
            full_name: fullName,
            company_name: company,
            job_title: jobTitle,
            accepted_terms: termsAgreed ? "v1" : null,
            token: captchaToken,
        };

        const responseJson = await this.httpService.post(this.buildUrl(endpoint), data);
        return Promise.resolve(new AuthenticationCommandResponse(responseJson));
    }

    /**
     * Sign up command.
     */
    @AnalyticsService.reportOnError("API:logout")
    public async logout(): Promise<void> {
        let endpoint = "/logout";
        let data = {};

        await this.httpService.post(this.buildUrl(endpoint), data);
        return Promise.resolve();
    }

    /**
     * Authentication command.
     */
    @AnalyticsService.reportOnError("API:authenticate")
    public async authenticate(
        email: string,
        password: string,
        adminLogin: boolean,
        captchaToken: string,
        rememberMe: boolean
    ): Promise<AuthenticationCommandResponse> {
        const endpoint = "/login";
        const data = {
            email: email,
            password: password,
            admin_login: adminLogin,
            token: captchaToken,
            remember_me: rememberMe,
        };

        const responseJson = await this.httpService.post(this.buildUrl(endpoint), data);
        return Promise.resolve(new AuthenticationCommandResponse(responseJson));
    }

    /**
     * Authentication command.
     */
    @AnalyticsService.reportOnError("API:authenticateMFA")
    public async authenticateMFA(
        mfaToken: string,
        jwtToken: string,
        rememberMe: boolean
    ): Promise<AuthenticationCommandResponse> {
        const endpoint = "/mfa_validation";
        const data = {
            mfa_token: mfaToken,
            jwt_token: jwtToken,
            remember_me: rememberMe,
        };

        const responseJson = await this.httpService.post(this.buildUrl(endpoint), data);
        return Promise.resolve(new AuthenticationCommandResponse(responseJson));
    }

    /**
     * Resend mfa token.
     */
    @AnalyticsService.reportOnError("API:resendMfaToken")
    public async resendMfaToken(jwtToken: string): Promise<AuthenticationCommandResponse> {
        let endpoint = "/resend_mfa_token";
        let data = {
            jwt_token: jwtToken,
        };

        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);
        return Promise.resolve(new AuthenticationCommandResponse(responseJson));
    }

    /**
     * Resend email verification token.
     */
    @AnalyticsService.reportOnError("API:resendEmailVerificationToken")
    public async resendEmailVerificationToken(jwtToken: string): Promise<Object> {
        let endpoint = "/resend_email_verification_token";
        let data = {
            jwt_token: jwtToken,
        };

        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);
        return Promise.resolve(responseJson);
    }

    /**
     * Authentication command.
     */
    @AnalyticsService.reportOnError("API:jwtValidation")
    public async jwtValidation(jwtToken: string): Promise<AuthenticationCommandResponse> {
        let endpoint = "/jwt_validation";
        let data = {
            jwt_token: jwtToken,
        };

        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);
        return Promise.resolve(new AuthenticationCommandResponse(responseJson));
    }

    /**
     * Forgot password get token by email command.
     */
    @AnalyticsService.reportOnError("API:forgotPasswordEmailStep")
    public async forgotPasswordEmailStep(email: string, recaptchaToken: string): Promise<Object> {
        let endpoint = "/forgot_password/send_email";
        let data = {
            email: email,
            token: recaptchaToken,
        };

        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);
        return Promise.resolve(responseJson);
    }

    /**
     * Forgot password activate token step.
     */
    @AnalyticsService.reportOnError("API:forgotPasswordActivateTokenStep")
    public async forgotPasswordActivateTokenStep(token: string, jwtToken: string): Promise<Object> {
        let endpoint = "/forgot_password/validate_token";
        let data = {
            jwt_token: jwtToken,
            token: token,
        };
        try {
            let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);
            return Promise.resolve(responseJson);
        } catch (err) {
            return Promise.resolve(false);
        }
    }

    /**
     * Forgot password command .
     */
    @AnalyticsService.reportOnError("API:resetPasswordStep")
    public async resetPasswordStep(new_password: string, jwtToken: string): Promise<Object> {
        let endpoint = "/forgot_password/reset_password";
        let data = {
            new_password: new_password,
            jwt_token: jwtToken,
        };

        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);
        return Promise.resolve(responseJson);
    }

    /**
     * Invite new user command
     */
    @AnalyticsService.reportOnError("API:inviteNewUser")
    public async inviteNewUser(clientId: string, inviteData: InviteUserInfo): Promise<UserInfo> {
        const endpoint = `/${clientId}/invite_user`;
        const data = inviteData.getJsonObject();

        const responseJson = await this.httpService.post(this.buildUrl(endpoint), data);

        return Promise.resolve(EntityManager.getSternumEntity(responseJson) as UserInfo);
    }

    /**
     * Resend invitation to new user
     */
    @AnalyticsService.reportOnError("API:reinviteNewUser")
    public async resendUserInvitation(invitedUserId: string): Promise<UserInfo> {
        let endpoint = `/${invitedUserId}/invite_user`;
        let responseJson = await this.httpService.post(this.buildUrl(endpoint), {});
        return Promise.resolve(EntityManager.getSternumEntity(responseJson) as UserInfo);
    }

    /**
     * New user change password command .
     */
    @AnalyticsService.reportOnError("API:newUserChangePassword")
    public async finishRegistration(
        new_password: string,
        jwtToken: string,
        firstName?: string,
        lastName?: string,
        termsAgreed?: boolean
    ): Promise<Object> {
        let endpoint = "/invite/set_new_password";
        let data = {
            new_password: new_password,
            jwt_token: jwtToken,
            accepted_terms: termsAgreed ? "v1" : null,
        };

        if (firstName && lastName) {
            data["first_name"] = firstName;
            data["last_name"] = lastName;
        }

        let responseJson = await this.httpService.post(this.buildUrl(endpoint), data);
        return Promise.resolve(responseJson);
    }

    /**
     * Gets authenticated user entity.
     */
    @AnalyticsService.reportOnError("API:getAuthenticatedUser")
    public async getAuthenticatedUser(): Promise<UserInfo> {
        let endpoint = "/me";
        let responseJson = await this.httpService.get(this.buildUrl(endpoint));
        return Promise.resolve(EntityManager.getSternumEntity(responseJson) as UserInfo);
    }

    /**
     * Gets current user's email notification settings.
     */
    @AnalyticsService.reportOnError("API:getUserEmailNotificationSettings")
    public async getUserEmailNotificationSettings(clientId: string): Promise<EmailNotificationSettings[]> {
        let endpoint = `/${clientId}/mail-notifications`;
        let responseJson = await this.httpService.get(this.buildUrl(endpoint));

        return responseJson["entities"] as EmailNotificationSettings[];
    }

    /**
     * Subscribes current user's to given email notifications.
     */
    @AnalyticsService.reportOnError("API:subscribeToEmailNotifications")
    public async subscribeToEmailNotifications(
        clientId: string,
        emailNotificationIds: EmailNotificationSettings["id"][]
    ): Promise<any> {
        let endpoint = `/${clientId}/mail-notifications`;
        return await this.httpService.put(this.buildUrl(endpoint), { subscriptions: emailNotificationIds });
    }

    /**
     * Unsubscribes current user's from given email notifications.
     */
    @AnalyticsService.reportOnError("API:unsubscribeFromEmailNotifications")
    public async unsubscribeFromEmailNotifications(
        clientId: string,
        emailNotificationIds: EmailNotificationSettings["id"][]
    ): Promise<any> {
        let endpoint = `/${clientId}/mail-notifications`;
        return await this.httpService.delete(this.buildUrl(endpoint), undefined, { unsubscribe: emailNotificationIds });
    }

    /**
     * Gets the roles of a user.
     */
    @AnalyticsService.reportOnError("API:getUserRoles")
    public async getUserRoles(clientId: string): Promise<RoleInfo[]> {
        const responseJson = await this.httpService.get(this.buildUrl(`/${clientId}/roles`));
        return Promise.resolve(EntityManager.getSternumEntities(responseJson).map((entity) => entity as RoleInfo));
    }

    /**
     * Gets the roles of a user.
     */
    @AnalyticsService.reportOnError("API:UpdateUserRoles")
    public async UpdateUserRoles(
        clientId: string,
        userId: string,
        roleName: string,
        is_invited_user: boolean
    ): Promise<boolean> {
        const body = { add_to_user_id: userId, role_name: roleName, is_invited: is_invited_user };
        const responseJson = await this.httpService.post(this.buildUrl(`/${clientId}/roles`), body);
        return Promise.resolve(true);
    }

    /**
     * Request premium upgrade command.
     */
    @AnalyticsService.reportOnError("API:requestPremiumUpgrade")
    public async requestPremiumUpgrade(
        email: string,
        fullName: string,
        company: string,
        jobTitle: string,
        phone?: string,
        additionalDetails?: string
    ): Promise<AuthenticationCommandResponse> {
        const endpoint = "/contact/upgrade";
        const data = {
            email: email,
            full_name: fullName,
            company_name: company,
            job_title: jobTitle,
            phone,
            details: additionalDetails,
        };

        const responseJson = await this.httpService.post(this.buildUrl(endpoint), data);
        return Promise.resolve(new AuthenticationCommandResponse(responseJson));
    }

    /**
     * Gets the exported report file for an attack of a device.
     */
    @AnalyticsService.reportOnError("API:generateDeviceAttackReport")
    public async generateDeviceAttackReport(deviceId: string, content: Object): Promise<HttpResponse> {
        let endpoint = `/${deviceId}/export_attack_report`;
        let responseBlob = await this.httpService.downloadWithData(this.buildUrl(endpoint), { content: content });
        return Promise.resolve(responseBlob as HttpResponse);
    }

    /**
     * Download report.
     */
    @AnalyticsService.reportOnError("API:generateDeviceAlertReport")
    public async generateDeviceAlertReport(deviceId: string, bodyData: Object): Promise<HttpResponse> {
        let endpoint = `/${deviceId}/export_alerts_report`;
        let responseBlob = await this.httpService.downloadWithData(this.buildUrl(endpoint), { content: bodyData });
        return Promise.resolve(responseBlob as HttpResponse);
    }

    /**
     * Download device definition cves report.
     */
    @AnalyticsService.reportOnError("API:exportDeviceDefinitionCVEsReport")
    public async exportDeviceDefinitionCVEsReport(
        deviceDefinitionVersionId: string,
        format: "CSV" | "XLSX" = "CSV"
    ): Promise<HttpResponse> {
        let endpoint = `/${deviceDefinitionVersionId}/export_cves`;
        const data = { format };

        let responseBlob = await this.httpService.downloadWithData(this.buildUrl(endpoint), data);
        return Promise.resolve(responseBlob as HttpResponse);
    }

    /**
     * Download device definition devices report.
     */
    @AnalyticsService.reportOnError("API:exportDeviceDefinitionDevicesReport")
    public async exportDeviceDefinitionDevicesReport(
        deviceDefinitionVersionId: string,
        format: "CSV" | "XLSX" = "CSV"
    ): Promise<HttpResponse> {
        let endpoint = `/${deviceDefinitionVersionId}/export_devices`;
        const data = { format };

        let responseBlob = await this.httpService.downloadWithData(this.buildUrl(endpoint), data);
        return Promise.resolve(responseBlob as HttpResponse);
    }

    /**
     * Download device definition 3rd party libraries report.
     */
    @AnalyticsService.reportOnError("API:exportDeviceDefinition3rdPartyLibraryReport")
    public async exportDeviceDefinition3rdPartyLibrariesReport(
        deviceDefinitionVersionId: string,
        format: "CSV" | "XLSX" = "CSV"
    ): Promise<HttpResponse> {
        let endpoint = `/${deviceDefinitionVersionId}/export_3rd_libraries`;
        const data = { format };

        let responseBlob = await this.httpService.downloadWithData(this.buildUrl(endpoint), data);
        return Promise.resolve(responseBlob as HttpResponse);
    }

    /**
     * Get sternum trigger description
     */
    @AnalyticsService.reportOnError("API:getSternumTriggerParsedDescription")
    public async getSternumTriggerParsedDescription(
        sternumTriggerDefinitionId: string,
        device_definition_version_id: string
    ): Promise<string> {
        const endpoint = `/${sternumTriggerDefinitionId}/${device_definition_version_id}/trigger_description`;
        const triggerDescription = (await this.httpService.get(
            this.buildUrl(endpoint)
        )) as TriggerParsedDescriptionResponse;

        return triggerDescription.trigger_parsed_description;
    }

    /**
     * Send feedback message
     */
    @AnalyticsService.reportOnError("API:sendFeedback")
    public async sendFeedback(fullName: string, email: string, details: string) {
        const endpoint = "/contact/feedback";
        const url = this.buildUrl(endpoint);

        return await this.httpService.post(url, { full_name: fullName, email, details });
    }

    /**
     * Request for support
     */
    @AnalyticsService.reportOnError("API:getSupport")
    public async getSupport(fullName: string, email: string, issue: string) {
        const endpoint = "/contact/support";
        const url = this.buildUrl(endpoint);

        return await this.httpService.post(url, { full_name: fullName, email, issue });
    }

    /**
     * Request for support
     */
    @AnalyticsService.reportOnError("API:sendContactUs")
    public async sendContactUs(token: string, fullName: string, companyName: string, jobTitle: string, email: string) {
        const endpoint = "/contact/contact";
        const url = this.buildUrl(endpoint);

        return await this.httpService.post(url, {
            token,
            full_name: fullName,
            company_name: companyName,
            job_title: jobTitle,
            email,
        });
    }

    /**
     * Get available SDK builds for different releases
     */
    @AnalyticsService.reportOnError("API:getAvailableSdkBuilds")
    public async getAvailableSdkBuilds(): Promise<BuildVersionByReleaseSystem> {
        const endpoint = "/ads_sdk_versions";
        const url = this.buildUrl(endpoint);

        const resp = (await this.httpService.get(url)) as any;

        const response: BuildVersionByReleaseSystem = {
            [ReleaseSystem.Linux]: { buildVersion: resp.linux?.build_version },
            [ReleaseSystem.MacOsArm]: { buildVersion: resp["mac/arm64"]?.build_version },
            [ReleaseSystem.MacOsIntel]: { buildVersion: resp["mac/x64"]?.build_version },
            [ReleaseSystem.Windows]: { buildVersion: resp.windows?.build_version },
        };

        return response;
    }

    /**
     * Download application for Linux or Mac or Windows.
     */
    @AnalyticsService.reportOnError("API:downloadApplicationForReleaseSystem")
    public async downloadApplicationForReleaseSystem(
        releaseSystem: ReleaseSystem,
        options: {
            onProgress?: (percentProgress: number /* from 0 to 100 */) => unknown;
        } = {}
    ): Promise<HttpResponse> {
        const endpoint = `/download/ads-sdk/${releaseSystem}`;
        let responseBlob = await this.httpService.download(this.buildUrl(endpoint), undefined, {
            onProgress: options.onProgress,
        });

        return Promise.resolve(responseBlob as HttpResponse);
    }

    /**
     * Get installer command
     */
    @AnalyticsService.reportOnError("API:getInstallerCommand")
    public async getInstallerCommand(arch: "arm32" | "arm64") {
        const endpoint = "/get_installer_command";
        const url = this.buildUrl(endpoint);
        const params = { arch };

        return (await this.httpService.get(url, params)) as Promise<{ command: string }>;
    }

    /**
     * Get attack simulation kit command
     */
    @AnalyticsService.reportOnError("API:getSimulationKitCommand")
    public async getSimulationKitCommand(arch: "arm32sf" | "arm32hf" | "arm64") {
        const endpoint = "/get_attack_simulation_command";
        const url = this.buildUrl(endpoint);
        const params = { arch };

        return (await this.httpService.get(url, params)) as Promise<{ command: string }>;
    }

    /**
     * Download attack simulation kit source code.
     */
    @AnalyticsService.reportOnError("API:downloadSimulationKitSourceArchive")
    public async downloadSimulationKitSourceArchive(apiKey: string): Promise<HttpResponse> {
        const endpoint = `/plg_liv/latest/attack_simulation_kit_source.zip`;
        let responseBlob = await this.httpService.download(this.buildUrl(endpoint), { api_key: apiKey });

        return Promise.resolve(responseBlob as HttpResponse);
    }

    /**
     * Get dashboard regular onboarding state
     */
    @AnalyticsService.reportOnError("API:getDashboardRegularOnboardingState")
    public async getDashboardRegularOnboardingState(): Promise<DashboardOnboardingStepState> {
        let dashboardRegularState: DashboardOnboardingStepState;

        try {
            dashboardRegularState = JSON.parse(localStorage.getItem("onboarding-dashboard-state") || "{}") || {};

            return {
                isAverageAnomaliesNumbersVisited: !!dashboardRegularState["isAverageAnomaliesNumbersVisited"],
            };
        } catch (e) {}

        return (
            dashboardRegularState || {
                isAverageAnomaliesNumbersVisited: false,
            }
        );

        // TODO: Maybe some endpoint will be good to have
        // const endpoint = `/onboarding-state`;
        // const url = this.buildUrl(endpoint);
        //
        // const response = await this.httpService.get(url);
        //
        // const isAverageAnomaliesNumbersVisited = response["data"]?.["is_average_anomalies_numbers_visited"];
        // const isCategoriesVisited = response["data"]?.["is_categories_visited"];
        //
        // return {
        //     isAverageAnomaliesNumbersVisited:
        //         typeof isAverageAnomaliesNumbersVisited === "boolean" ? isAverageAnomaliesNumbersVisited : false,
        //     isCategoriesVisited: typeof isCategoriesVisited === "boolean" ? isCategoriesVisited : false,
        // };
    }

    /**
     * Set dashboard regular onboarding state
     */
    @AnalyticsService.reportOnError("API:setDashboardRegularOnboardingState")
    public async setDashboardRegularOnboardingState(
        payload: Partial<DashboardOnboardingStepState>
    ): Promise<DashboardOnboardingStepState> {
        try {
            const localStorageKey = "onboarding-dashboard-state";

            const dashboardRegularState = JSON.parse(localStorage.getItem(localStorageKey) || "{}") || {};
            const newDashboardRegularState: DashboardOnboardingStepState = {
                ...dashboardRegularState,
                ...payload,
            };

            localStorage.setItem(localStorageKey, JSON.stringify(newDashboardRegularState));

            return newDashboardRegularState;
        } catch (e) {
            return {
                isAverageAnomaliesNumbersVisited: true,
            };
        }

        // TODO: Maybe some endpoint will be good to have
        // const endpoint = `/onboarding-state`;
        // const url = this.buildUrl(endpoint);
        // const data = {};
        //
        // if (payload.isAverageAnomaliesNumbersVisited !== undefined) {
        //     data["is_average_anomalies_numbers_visited"] = payload.isAverageAnomaliesNumbersVisited;
        // }
        //
        // if (payload.isCategoriesVisited !== undefined) {
        //     data["is_categories_visited"] = payload.isCategoriesVisited;
        // }
        //
        // const response = await this.httpService.put(url, data);
        //
        // const isAverageAnomaliesNumbersVisited = response["data"]?.["is_average_anomalies_numbers_visited"];
        // const isCategoriesVisited = response["data"]?.["is_categories_visited"];
        //
        // return {
        //     isAverageAnomaliesNumbersVisited:
        //         typeof isAverageAnomaliesNumbersVisited === "boolean" ? isAverageAnomaliesNumbersVisited : false,
        //     isCategoriesVisited: typeof isCategoriesVisited === "boolean" ? isCategoriesVisited : false,
        // };
    }

    /**
     * Get anomalies related to the issue.
     */
    @AnalyticsService.reportOnError("API:getAnomalies")
    public async getAnomalies(issueId: string): Promise<AnomaliesResponse> {
        const endpoint = `/${issueId}/anomaly`;
        const url = this.buildUrl(endpoint);
        const params = {};

        // const dataFromNetwork = {};
        const dataFromNetwork = (await this.httpService.get(url, params)) as any;
        // console.log(dataFromNetwork);

        const dummyDataAnomaly = {
            trde_stats_anomalies: [
                {
                    trace_definition: { is_reference: true, entity_id: "TRDEYdpoRWYq021" },
                    min: 1,
                    max: 7,
                    median: 4,
                    shap: 0.8,
                    q1: 3,
                    q3: 5,
                    value: 10,
                },
                {
                    trace_definition: { is_reference: true, entity_id: "TRDEYdpoRWYq022" },
                    min: 1,
                    max: 8,
                    median: 5,
                    shap: 0.2,
                    q1: 2,
                    q3: 6,
                    value: 10,
                },
                {
                    trace_definition: { is_reference: true, entity_id: "TRDEYdpoRWYq023" },
                    min: 1,
                    max: 7,
                    median: 3,
                    shap: 0.9,
                    q1: 2,
                    q3: 5,
                    value: 14,
                },
                {
                    trace_definition: { is_reference: true, entity_id: "TRDEYdpoRWYq024" },
                    min: 1,
                    max: 8,
                    median: 5,
                    shap: 1.4,
                    q1: 2,
                    q3: 6,
                    value: 4,
                },
                {
                    trace_definition: { is_reference: true, entity_id: "TRDEYdpoRWYq025" },
                    min: 1,
                    max: 7,
                    median: 3,
                    shap: 0.6,
                    q1: 2,
                    q3: 5,
                    value: 0,
                },
                {
                    trace_definition: { is_reference: true, entity_id: "TRDEYdpoRWYq026" },
                    min: 1,
                    max: 7,
                    median: 4,
                    shap: 0.8,
                    q1: 3,
                    q3: 5,
                    value: 10,
                },
                {
                    trace_definition: { is_reference: true, entity_id: "TRDEYdpoRWYq027" },
                    min: 1,
                    max: 8,
                    median: 5,
                    shap: 0.2,
                    q1: 2,
                    q3: 6,
                    value: 10,
                },
                {
                    trace_definition: { is_reference: true, entity_id: "TRDEYdpoRWYq028" },
                    min: 1,
                    max: 7,
                    median: 3,
                    shap: 0.9,
                    q1: 2,
                    q3: 5,
                    value: 14,
                },
                {
                    trace_definition: { is_reference: true, entity_id: "TRDEYdpoRWYq029" },
                    min: 1,
                    max: 8,
                    median: 5,
                    shap: 1.4,
                    q1: 2,
                    q3: 6,
                    value: 4,
                },
                {
                    trace_definition: { is_reference: true, entity_id: "TRDEYdpoRWYq030" },
                    min: 1,
                    max: 7,
                    median: 3,
                    shap: 0.6,
                    q1: 2,
                    q3: 5,
                    value: 0,
                },
                {
                    trace_definition: { is_reference: true, entity_id: "TRDEYdpoRWYq031" },
                    min: 1,
                    max: 8,
                    median: 5,
                    shap: 1.4,
                    q1: 2,
                    q3: 6,
                    value: 4,
                },
                {
                    trace_definition: { is_reference: true, entity_id: "TRDEYdpoRWYq032" },
                    min: 1,
                    max: 7,
                    median: 3,
                    shap: 0.6,
                    q1: 2,
                    q3: 5,
                    value: 0,
                },
            ],
            sequences_patterns: [
                {
                    name: "Pattern #1",
                    expected_traces: [
                        {
                            trace_definition: { is_reference: true, entity_id: "TRDEYCj8JNT02F1" },
                        },
                        {
                            trace_definition: { is_reference: true, entity_id: "TRDENCj8JNT02F1" },
                        },
                        {
                            trace_definition: { is_reference: true, entity_id: "TRDEZCj8JNT02F1" },
                        },
                        {
                            trace_definition: { is_reference: true, entity_id: "TRDEWCj8JNT02F1" },
                        },
                        {
                            trace_definition: { is_reference: true, entity_id: "TRDEXCj8JNT02F1" },
                        },
                        {
                            trace_definition: { is_reference: true, entity_id: "TRDETCj8JNT02F1" },
                        },
                        {
                            trace_definition: { is_reference: true, entity_id: "TRDEbKKQJNT02F1" },
                        },
                        {
                            trace_definition: { is_reference: true, entity_id: "TRDEcKKQJNT02F1" },
                        },
                    ],
                    received_traces: [
                        {
                            expected: true,
                            trace: { is_reference: true, entity_id: "TRACBNzoUPT02F1" },
                        },
                        {
                            expected: true,
                            trace: { is_reference: true, entity_id: "TRACEVa6VPT02F1" },
                        },
                        {
                            unexpected: true,
                            trace: { is_reference: true, entity_id: "TRACFVa6VPT02F1" },
                        },
                        {
                            missing: true,
                            trace: { is_reference: true, entity_id: "TRACGVa6VPT02F1" },
                        },
                        {
                            missing: true,
                            trace: { is_reference: true, entity_id: "TRACHVa6VPT02F1" },
                        },
                        {
                            unexpected: true,
                            trace: { is_reference: true, entity_id: "TRACIVa6VPT02F1" },
                        },
                        {
                            expected: true,
                            trace: { is_reference: true, entity_id: "TRACJVa6VPT02F1" },
                        },
                        {
                            expected: true,
                            trace: { is_reference: true, entity_id: "TRACKVa6VPT02F1" },
                        },
                    ],
                },
                {
                    name: "Pattern #2",
                    expected_traces: [
                        {
                            trace_definition: { is_reference: true, entity_id: "TRDEYCj8JNT02F1" },
                        },
                        {
                            trace_definition: { is_reference: true, entity_id: "TRDENCj8JNT02F1" },
                        },
                        {
                            trace_definition: { is_reference: true, entity_id: "TRDEZCj8JNT02F1" },
                        },
                        {
                            trace_definition: { is_reference: true, entity_id: "TRDEXCj8JNT02F1" },
                        },
                        {
                            trace_definition: { is_reference: true, entity_id: "TRDETCj8JNT02F1" },
                        },
                        {
                            trace_definition: { is_reference: true, entity_id: "TRDEcKKQJNT02F1" },
                        },
                    ],
                    received_traces: [
                        {
                            expected: true,
                            trace: { is_reference: true, entity_id: "TRACBNzoUPT02F1" },
                        },
                        {
                            expected: true,
                            trace: { is_reference: true, entity_id: "TRACEVa6VPT02F1" },
                        },
                        {
                            expected: true,
                            trace: { is_reference: true, entity_id: "TRACFVa6VPT02F1" },
                        },
                        {
                            unexpected: true,
                            trace: { is_reference: true, entity_id: "TRACGVa6VPT02F1" },
                        },
                        {
                            expected: true,
                            trace: { is_reference: true, entity_id: "TRACHVa6VPT02F1" },
                        },
                        {
                            expected: true,
                            trace: { is_reference: true, entity_id: "TRACIVa6VPT02F1" },
                        },
                        {
                            unexpected: true,
                            trace: { is_reference: true, entity_id: "TRACJVa6VPT02F1" },
                        },
                        {
                            expected: true,
                            trace: { is_reference: true, entity_id: "TRACKVa6VPT02F1" },
                        },
                    ],
                },
                {
                    name: "Pattern #3",
                    expected_traces: [
                        {
                            trace_definition: { is_reference: true, entity_id: "TRDEYCj8JNT02F1" },
                        },
                        {
                            trace_definition: { is_reference: true, entity_id: "TRDENCj8JNT02F1" },
                        },
                        {
                            trace_definition: { is_reference: true, entity_id: "TRDEZCj8JNT02F1" },
                        },
                        {
                            trace_definition: { is_reference: true, entity_id: "TRDEWCj8JNT02F1" },
                        },
                        {
                            trace_definition: { is_reference: true, entity_id: "TRDEXCj8JNT02F1" },
                        },
                        {
                            trace_definition: { is_reference: true, entity_id: "TRDETCj8JNT02F1" },
                        },
                        {
                            trace_definition: { is_reference: true, entity_id: "TRDEbKKQJNT02F1" },
                        },
                        {
                            trace_definition: { is_reference: true, entity_id: "TRDEXCj8JNT02F1" },
                        },
                        {
                            trace_definition: { is_reference: true, entity_id: "TRDEdCj8JNT02F1" },
                        },
                        {
                            trace_definition: { is_reference: true, entity_id: "TRDEcKKQJNT02F1" },
                        },
                    ],
                    received_traces: [
                        {
                            expected: true,
                            trace: { is_reference: true, entity_id: "TRACBNzoUPT02F1" },
                        },
                        {
                            expected: true,
                            trace: { is_reference: true, entity_id: "TRACEVa6VPT02F1" },
                        },
                        {
                            expected: true,
                            trace: { is_reference: true, entity_id: "TRACFVa6VPT02F1" },
                        },
                        {
                            expected: true,
                            trace: { is_reference: true, entity_id: "TRACGVa6VPT02F1" },
                        },
                        {
                            expected: true,
                            trace: { is_reference: true, entity_id: "TRACHVa6VPT02F1" },
                        },
                        {
                            expected: true,
                            trace: { is_reference: true, entity_id: "TRACIVa6VPT02F1" },
                        },
                        {
                            expected: true,
                            trace: { is_reference: true, entity_id: "TRACJVa6VPT02F1" },
                        },
                        {
                            missing: true,
                            trace: { is_reference: true, entity_id: "TRDEXCj8JNT02F1" },
                        },
                        {
                            missing: true,
                            trace: { is_reference: true, entity_id: "TRDEdCj8JNT02F1" },
                        },
                        {
                            expected: true,
                            trace: { is_reference: true, entity_id: "TRACKVa6VPT02F1" },
                        },
                    ],
                },
            ],
            related_entities_map: {
                TRDEYdpoRWYq021: {
                    entity_id: "TRDEYdpoRWYq021",
                    entity_type: "TRACE_DEFINITION",
                    display_name: "Attack Attempt",
                },
                TRDEYdpoRWYq022: {
                    entity_id: "TRDEYdpoRWYq022",
                    entity_type: "TRACE_DEFINITION",
                    display_name: "Boot",
                },
                TRDEYdpoRWYq023: {
                    entity_id: "TRDEYdpoRWYq023",
                    entity_type: "TRACE_DEFINITION",
                    display_name: "Update Requests",
                },
                TRDEYdpoRWYq024: {
                    entity_id: "TRDEYdpoRWYq024",
                    entity_type: "TRACE_DEFINITION",
                    display_name: "Connect",
                },
                TRDEYdpoRWYq025: {
                    entity_id: "TRDEYdpoRWYq025",
                    entity_type: "TRACE_DEFINITION",
                    display_name: "Memory Usage",
                },
                TRDEYdpoRWYq026: {
                    entity_id: "TRDEYdpoRWYq026",
                    entity_type: "TRACE_DEFINITION",
                    display_name: "Heap Allocations",
                },
                TRDEYdpoRWYq027: {
                    entity_id: "TRDEYdpoRWYq027",
                    entity_type: "TRACE_DEFINITION",
                    display_name: "Drive reads",
                },
                TRDEYdpoRWYq028: {
                    entity_id: "TRDEYdpoRWYq028",
                    entity_type: "TRACE_DEFINITION",
                    display_name: "Messages sent",
                },
                TRDEYdpoRWYq029: {
                    entity_id: "TRDEYdpoRWYq029",
                    entity_type: "TRACE_DEFINITION",
                    display_name: "Validated strings",
                },
                TRDEYdpoRWYq030: {
                    entity_id: "TRDEYdpoRWYq030",
                    entity_type: "TRACE_DEFINITION",
                    display_name: "Mixed bags",
                },
                TRDEYdpoRWYq031: {
                    entity_id: "TRDEYdpoRWYq031",
                    entity_type: "TRACE_DEFINITION",
                    display_name: "Packets sent",
                },
                TRDEYdpoRWYq032: {
                    entity_id: "TRDEYdpoRWYq032",
                    entity_type: "TRACE_DEFINITION",
                    display_name: "Packets received",
                },
                TRDEXCj8JNT02F1: {},
                CLEI1: {},
                DDVEQLmM6NT02F1: {},
                DEDEQLmM6NT02F1: {},
                USERKmNkSLT02F1: {},
                TRDETCj8JNT02F1: {},
                TRDEYCj8JNT02F1: {},
                TRDENCj8JNT02F1: {},
                TRDEZCj8JNT02F1: {},
                TRDEWCj8JNT02F1: {
                    entity_id: "TRDEWCj8JNT02F1",
                    entity_type: "TRACE_DEFINITION",
                    created: "2022-11-17T13:31:07+00:00",
                    updated: "2022-11-17T13:31:07+00:00",
                    display_name: "Stat",
                    trace_event_name: "TRACE_STAT",
                    trace_type: "SYSTEM",
                    trace_category: "FILE_EVENT",
                    event_interest: "REGULAR",
                    transmit_frequency: "BATCH",
                    description: null,
                    client: {
                        is_reference: true,
                        entity_id: "CLEI1",
                    },
                    deleted: null,
                    device_definition_id: "DEDEQLmM6NT02F1",
                    device_definition_version: {
                        entity_id: "DDVEQLmM6NT02F1",
                        entity_type: "DEVICE_DEFINITION_VERSION",
                        client_id: "CLEI1",
                        created: "2022-11-17 13:31:07",
                        updated: "2022-11-17 13:31:07",
                        device_definition_id: "DEDEQLmM6NT02F1",
                        device_definition_version_raw_id: "1042794012645437440",
                        version_firmware: null,
                        version_status: "STAGING",
                        version_sequence: 0,
                        version_name: null,
                        description: null,
                        downloaded_date: ["2022-11-17 13:31:07"],
                        trigger_download_date: null,
                        parent_raw_id: "N/A",
                        creator_user: {
                            is_reference: true,
                            entity_id: "USERKmNkSLT02F1",
                        },
                        device_definition: {
                            is_reference: true,
                            entity_id: "DEDEQLmM6NT02F1",
                        },
                    },
                    display_in_ui: true,
                    data_source: ["MySQL"],
                },
                TRDEbKKQJNT02F1: {},
                TRDEcKKQJNT02F1: {},
                TRACBNzoUPT02F1: {
                    entity_id: "TRACBNzoUPT02F1",
                    entity_id_long: 1042794014839058433,
                    entity_type: "TRACE",
                    client_id: "CLEI1",
                    device_id: "DEVIANzoUPT02F1",
                    ip_address: "127.0.0.1",
                    created: 1668691867000,
                    updated: null,
                    severity: "normal",
                    trace_event_name: "Trace boot",
                    device_definition_version: {
                        is_reference: true,
                        entity_id: "DDVEQLmM6NT02F1",
                    },
                    geo_location: {
                        continent: "AS",
                        country: "IL",
                        city: "Jerusalem",
                        state: "Jerusalem",
                        latitude: 52.2297,
                        longitude: 21.0122,
                        organization: "Internet provider",
                    },
                    device: {
                        is_reference: true,
                        entity_id: "DEVIANzoUPT02F1",
                    },
                    trace_event_type: 113,
                    trace_definition: { is_reference: true, entity_id: "TRDEYCj8JNT02F1" },
                    data_source: ["ElasticSearch"],
                },
                TRACEVa6VPT02F1: {
                    entity_id: "TRACEVa6VPT02F1",
                    entity_id_long: 1042794014839058433,
                    entity_type: "TRACE",
                    client_id: "CLEI1",
                    device_id: "DEVIANzoUPT02F1",
                    ip_address: "127.0.0.1",
                    created: 1668691867000,
                    updated: null,
                    severity: "normal",
                    trace_event_name: "Trace boot 2",
                    device_definition_version: {
                        is_reference: true,
                        entity_id: "DDVEQLmM6NT02F1",
                    },
                    geo_location: {
                        continent: "AS",
                        country: "IL",
                        city: "Jerusalem",
                        state: "Jerusalem",
                        latitude: 52.2297,
                        longitude: 21.0122,
                        organization: "Internet provider",
                    },
                    device: {
                        is_reference: true,
                        entity_id: "DEVIANzoUPT02F1",
                    },
                    trace_event_type: 113,
                    trace_definition: { is_reference: true, entity_id: "TRDEYCj8JNT02F1" },
                    data_source: ["ElasticSearch"],
                },
                TRACFVa6VPT02F1: {
                    entity_id: "TRACFVa6VPT02F1",
                    entity_id_long: 1042794014839058433,
                    entity_type: "TRACE",
                    client_id: "CLEI1",
                    device_id: "DEVIANzoUPT02F1",
                    ip_address: "127.0.0.1",
                    created: 1668691867000,
                    updated: null,
                    severity: "normal",
                    trace_event_name: "Trace boot 2",
                    device_definition_version: {
                        is_reference: true,
                        entity_id: "DDVEQLmM6NT02F1",
                    },
                    geo_location: {
                        continent: "AS",
                        country: "IL",
                        city: "Jerusalem",
                        state: "Jerusalem",
                        latitude: 52.2297,
                        longitude: 21.0122,
                        organization: "Internet provider",
                    },
                    device: {
                        is_reference: true,
                        entity_id: "DEVIANzoUPT02F1",
                    },
                    trace_event_type: 113,
                    trace_definition: { is_reference: true, entity_id: "TRDEYCj8JNT02F1" },
                    data_source: ["ElasticSearch"],
                },
                TRACGVa6VPT02F1: {
                    entity_id: "TRACGVa6VPT02F1",
                    entity_id_long: 1042794014839058433,
                    entity_type: "TRACE",
                    client_id: "CLEI1",
                    device_id: "DEVIANzoUPT02F1",
                    ip_address: "127.0.0.1",
                    created: 1668691867000,
                    updated: null,
                    severity: "normal",
                    trace_event_name: "Trace boot 2",
                    device_definition_version: {
                        is_reference: true,
                        entity_id: "DDVEQLmM6NT02F1",
                    },
                    geo_location: {
                        continent: "AS",
                        country: "IL",
                        city: "Jerusalem",
                        state: "Jerusalem",
                        latitude: 52.2297,
                        longitude: 21.0122,
                        organization: "Internet provider",
                    },
                    device: {
                        is_reference: true,
                        entity_id: "DEVIANzoUPT02F1",
                    },
                    trace_event_type: 113,
                    trace_definition: { is_reference: true, entity_id: "TRDEYCj8JNT02F1" },
                    data_source: ["ElasticSearch"],
                },
                TRACHVa6VPT02F1: {
                    entity_id: "TRACHVa6VPT02F1",
                    entity_id_long: 1042794014839058433,
                    entity_type: "TRACE",
                    client_id: "CLEI1",
                    device_id: "DEVIANzoUPT02F1",
                    ip_address: "127.0.0.1",
                    created: 1668691867000,
                    updated: null,
                    severity: "normal",
                    trace_event_name: "Trace boot 2",
                    device_definition_version: {
                        is_reference: true,
                        entity_id: "DDVEQLmM6NT02F1",
                    },
                    geo_location: {
                        continent: "AS",
                        country: "IL",
                        city: "Jerusalem",
                        state: "Jerusalem",
                        latitude: 52.2297,
                        longitude: 21.0122,
                        organization: "Internet provider",
                    },
                    device: {
                        is_reference: true,
                        entity_id: "DEVIANzoUPT02F1",
                    },
                    trace_event_type: 113,
                    trace_definition: { is_reference: true, entity_id: "TRDEYCj8JNT02F1" },
                    data_source: ["ElasticSearch"],
                },
                TRACIVa6VPT02F1: {},
                TRACJVa6VPT02F1: {},
                TRACKVa6VPT02F1: {},
                DEVIANzoUPT02F1: {},
                TRDEdCj8JNT02F1: {},
            },
        };

        const anomalies =
            dataFromNetwork["trde_stats_anomalies"]?.map((item) =>
                anomalyFromJSON(item, dataFromNetwork["related_entities_map"] || {})
            ) || [];

        const patterns = getSequencePatternsFromJSON(dataFromNetwork);

        return {
            patterns,
            anomalies,
            firstTraceTimestamp: dataFromNetwork?.["first_trace_timestamp"] || 0, // FIXME: is it needed
            lastTraceTimestamp: dataFromNetwork?.["last_trace_timestamp"] || 0, // FIXME: is it needed
        };
    }

    /**
     * Get issues heatmap statistics
     */
    @AnalyticsService.reportOnError("API:getIssuesHeatmapStatistics")
    public async getIssuesHeatmapStatistics(
        period: "month" | "day_of_month",
        startTime?: number,
        endTime?: number,
        onlyUnresolved?: boolean,
        onlyAnomalyInsights?: boolean,
        deviceProfileStatus?: "STAGING" | "PRODUCTION"
    ): Promise<IssuesStatisticsHeatmap> {
        const endpoint = "/issues/statistics";
        const url = this.buildUrl(endpoint);

        const params = { period, from: startTime, to: endTime };
        if (onlyUnresolved) {
            params["status"] = "open";
        }

        if (onlyAnomalyInsights) {
            params["anomalies_only"] = "1";
        }

        if (deviceProfileStatus) {
            params["version_status"] = deviceProfileStatus;
        }

        return (await this.httpService.get(url, params)) as any;
    }

    /**
     * Get device alerts statistics
     */
    @AnalyticsService.reportOnError("API:getDeviceAlertsStatistics")
    public async getDeviceAlertsStatistics(
        clientId: string,
        deviceId: string,
        startTime: number,
        endTime: number
    ): Promise<DeviceAlertsStatistics> {
        const endpoint = `/${clientId}/${deviceId}/alerts-stats`;
        const url = this.buildUrl(endpoint);

        const params = { create_from: startTime, created_to: endTime };

        const response = (await this.httpService.post(url, params)) as any;

        return {
            triggersUsage: response["triggers_usage"].map((entry) => ({
                trigger: entry["trigger"] ? SternumTriggerInfo.fromJsonObject(entry["trigger"]) : undefined,
                traceDefinition: entry["trace_definition"]
                    ? TraceDefinitionInfo.fromJsonObject(entry["trace_definition"])
                    : undefined,
                alertsCount: entry["alerts_count"],
                tracesCount: entry["traces_count"],
            })),
        };
    }

    /**
     * Builds the endpoint url to the sternum service.
     */
    private buildUrl(endpoint): string {
        return buildSternumApiUrl(endpoint);
    }
}

export default SternumService;
