import { Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Icon from "@material-ui/core/Icon";
import Popover from "@material-ui/core/Popover";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ServiceWire from "../../../lib/services/ServiceWire";
import { GlobalState } from "../../../lib/state/GlobalState";
import ConfirmationDialog from "../../../shared_components/ConfirmationDialog/ConfirmationDialog";
import { DeleteIcon, PencilIcon, SettingsIcon } from "../../SUI/SternumIcon/SternumIcon";
import dashboardMenuStyle from "./DashboardMenuStyle";

const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

interface AppState {
    anchorElement: any;
    confirmDialogOpen: boolean;

    deletingDashboard: boolean;
    errorDeletingDashboard: boolean;
}

export interface AppProps extends WithStyles<typeof dashboardMenuStyle> {
    customDashboardId: string;
    onEditClicked: () => void;
    onDashboardDeleted: () => void;
}

class DashboardMenu extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);

        const { classes } = this.props;

        this.state = {
            anchorElement: null,
            confirmDialogOpen: false,

            deletingDashboard: false,
            errorDeletingDashboard: false,
        };
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classNames(classes.flexVMiddle)}>
                <ConfirmationDialog
                    title={`Delete View?`}
                    body={"If you proceed, this view will be removed."}
                    open={this.state.confirmDialogOpen}
                    handleApprove={() => this.onDeleteRequested()}
                    handleCancel={() => this.onDeleteCanceled()}
                    overrideActionName={"Delete"}
                />

                <Popover
                    id="explanation-popover"
                    open={!!this.state.anchorElement}
                    anchorEl={this.state.anchorElement}
                    PaperProps={{
                        className: classes.paperContainer,
                    }}
                    onClose={() => {
                        this.setState({
                            anchorElement: null,
                        });
                    }}
                    elevation={10}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                >
                    <div className={classNames(classes.menuContainer)}>
                        <div className={classNames(classes.menuItemContainer)} onClick={() => this.onEditClicked()}>
                            <PencilIcon width={20} height={20} />
                            <Typography variant="body2" className={classNames(classes.menuItemText)}>
                                Edit
                            </Typography>
                        </div>

                        <div
                            className={classNames(classes.menuItemContainer)}
                            onClick={() => this.onDeleteMenuItemClicked()}
                        >
                            <DeleteIcon width={20} height={20} />
                            <Typography variant="body2" className={classNames(classes.menuItemText)}>
                                Delete
                            </Typography>

                            {(this.state.deletingDashboard || this.state.errorDeletingDashboard) && (
                                <div className={classNames(classes.deletingLoaderContainer)}>
                                    <CircularProgress size={15} color="inherit" />
                                </div>
                            )}
                        </div>
                    </div>
                </Popover>
                <Icon
                    className={classNames("fas fa-ellipsis-h", classes.ellipsisIcon)}
                    onClick={(event) => this.onMenuOpenIconClicked(event as React.MouseEvent<HTMLSpanElement>)}
                />
            </div>
        );
    }

    private onEditClicked() {
        this.props.onEditClicked();
        this.setState({
            anchorElement: null,
        });
    }

    private onDeleteMenuItemClicked() {
        this.setState({
            confirmDialogOpen: true,
        });
    }

    private onMenuOpenIconClicked(event: React.MouseEvent<HTMLSpanElement>) {
        event.stopPropagation();

        this.setState({
            anchorElement: event.currentTarget,
        });
    }

    private onDeleteCanceled() {
        this.setState({
            confirmDialogOpen: false,
        });
    }

    private async onDeleteRequested() {
        try {
            this.setState({
                confirmDialogOpen: false,
                deletingDashboard: true,
                errorDeletingDashboard: false,
            });

            await ServiceWire.getCustomDashboardsApiService().deleteDashboard(this.props.customDashboardId);

            if (this.props.onDashboardDeleted) {
                this.props.onDashboardDeleted();
            }

            this.setState({
                confirmDialogOpen: false,
                deletingDashboard: false,
                errorDeletingDashboard: false,
                anchorElement: null,
            });
        } catch {
            this.setState({
                deletingDashboard: false,
                errorDeletingDashboard: false,
            });
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(dashboardMenuStyle, { withTheme: true })(DashboardMenu)));
