import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

const activitiesListModalStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        upperContentContainer: {
            paddingTop: theme.spacing(10),
            paddingRight: theme.spacing(5),
            paddingBottom: theme.spacing(5),
            paddingLeft: theme.spacing(5),
        },
        listPaper: {
            "&.mod-regular": {
                maxHeight: theme.spacing(66),
                minHeight: theme.spacing(65),
            },
            "&.mod-fullscreen": {
                maxHeight: "100vh",
            },
        },
    });

export default activitiesListModalStyle;
