import Utils from "../infra/Utils";
import AnalyticsService from "./AnalyticsService";
import { unset } from "lodash";
import ConfigurationService from "./ConfigurationService";

interface Notification {
    operation: NotificationOperation;
    data: any;
}

export enum NotificationOperation {
    DEVICES_INSERTED = "DEVICES_INSERTED",
    DEVICE_UPDATED = "DEVICE_UPDATED",
    ALERT_CREATED = "ALERT_CREATED",
}

const CONNECTING_STATE = 0;
const CONNECTED_STATE = 1;
const CLOSED_STATE = 3;
const MAX_RETRY_DEPTH = 8;

const PING_MESSAGE = "PING";
const PONG_MESSAGE = "PONG";

type Callback = (data: any) => void;

class WebsocketService {
    private url: string;
    private ws: WebSocket;

    private retrying = false;

    private retryDepth = 0;

    /**
     * Holds a map between a changed event type to the subscribers who are listening on it.
     */
    private subscribersMap: {
        [operation in NotificationOperation]?: {
            [subscriberId: string]: Callback;
        };
    } = {};

    constructor() {
        this.url = this.buildSternumWebsocketUrl();
    }

    private isActive = () => {
        return this.ws && (this.ws.readyState === CONNECTED_STATE || this.ws.readyState === CONNECTING_STATE);
    };

    public runWebsocket = () => {
        if (!ConfigurationService.isNewArchitectureEnabled()) return;

        if (!this.isActive()) {
            this.ws = new WebSocket(this.url);
            this.listenForChanges();
        }
    };

    public stopWebsocket = () => {
        if (this.isActive()) {
            this.ws.close();
        }
    };

    public subscribe(subscriberId: string, operation: NotificationOperation, callback: Callback): void {
        // Initializing subscribers for type if not already exists.
        if (!this.subscribersMap[operation]) {
            this.subscribersMap[operation] = {};
        }

        // Validating subscriber is not already subscribed.
        let currentSubscribers = this.subscribersMap[operation];
        if (currentSubscribers && currentSubscribers[subscriberId]) {
            return;
        }

        // At last, adding the subscriber.
        currentSubscribers[subscriberId] = callback;
    }

    public unsubscribe(subscriberId: string, operation: NotificationOperation): void {
        unset(this.subscribersMap, [operation, subscriberId]);

        // Delete subscription type if it's empty to prevent server overhead.
        if (this.subscribersMap[operation] && Object.keys(this.subscribersMap[operation]).length === 0) {
            delete this.subscribersMap[operation];
        }
    }

    public clearAllSubscriptions() {
        this.subscribersMap = {};
    }

    private listenForChanges = (): void => {
        this.ws.onmessage = (event) => {
            if (event.data === PING_MESSAGE) {
                this.ws.send(PONG_MESSAGE);
                return;
            }

            let operations = Object.keys(this.subscribersMap);

            if (operations.length) {
                // Only if we have subscribers!

                try {
                    this.handleWebsocketData(JSON.parse(event.data));
                } catch (error) {
                    // Failed to run callback function. Report the error.
                    AnalyticsService.error("WebsocketService:listenForChanges", error.message);
                }
            }
        };

        this.ws.onclose = async (event) => {
            if (!this.retrying) {
                try {
                    await this.retry(event);
                } catch (err) {
                    console.error(`Failed to establish websocket connection: `, event);
                }
            }
        };

        this.ws.onerror = async (event) => {
            this.stopWebsocket();

            if (!this.retrying) {
                try {
                    await this.retry(event);
                } catch (err) {
                    console.error(`Failed to establish websocket connection: `, event);
                }
            }
        };
    };

    private retry = async (err) => {
        if (this.retryDepth >= MAX_RETRY_DEPTH) {
            throw err;
        }

        this.retrying = true;
        await Utils.wait(this.getWaitTime());
        this.retryDepth++;

        this.runWebsocket();

        this.retrying = false;
    };

    private getWaitTime = () => 2 ** this.retryDepth * 100;

    private handleWebsocketData(changedData: Notification): void {
        Object.keys(this.subscribersMap).forEach((operation) => {
            if (changedData.operation === operation) {
                let subscriberMap = this.subscribersMap[operation];

                Object.keys(this.subscribersMap[operation]).forEach((subscriberId) => {
                    const cb = subscriberMap[subscriberId];
                    cb(changedData.data);
                });
            }
        });
    }

    private buildSternumWebsocketUrl(): string {
        return process.env.STERNUM_WEBSOCKET_ENDPOINT;
    }
}

export default WebsocketService;
