import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Box, IconButton, Tooltip, Typography } from "@material-ui/core";
import { useStyles } from "./InstallationStep.style";
import { StepNumber } from "../StepNumber";
import SternumImprovedButton from "../../../../SUI/SternumImprovedButton/SternumImprovedButton";
import classNames from "classnames";
import { useCommonStyle } from "../../../../CommonStyle";
import { GlobalState } from "../../../../../lib/state/GlobalState";
import { NotificationMessage, NotificationVariant } from "../../../../../lib/state/NotificationsState";
import { showNotificationAction } from "../../../../../lib/redux/notifications/ShowNotificationAction";
import { SimulateAttackSetupStep } from "../SimulateAttackDialog.component";
import { AttackSimulationKitCommand } from "../../../../AttackSimulationKitCommand";
import { DownloadIcon, InfoCircleIcon } from "../../../../SUI/SternumIcon";
import APIKeyInfo from "../../../../../lib/state/APIKeyInfo";
import ServiceWire from "../../../../../lib/services/ServiceWire";
import { last } from "lodash";
import WebUtils from "../../../../../lib/infra/WebUtils";

interface InstallationStepProps {
    step: SimulateAttackSetupStep;
    active: boolean;
    onNextClick: (step: SimulateAttackSetupStep) => unknown;
    onSkipClick: (step: SimulateAttackSetupStep) => unknown;
    stepNumber: number;
    completed: boolean;
    className?: string;
}

const mapStateToProps = (state: GlobalState, ownProps: any) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        showNotification: (message: NotificationMessage, variant?: NotificationVariant) =>
            dispatch(showNotificationAction(message)),
        selectedClient: ServiceWire.getClientsService().getSelectedClient(),
    };
};

function InstallationStepComponent({
    step,
    active,
    onNextClick,
    onSkipClick,
    stepNumber,
    completed,
    className,
    showNotification,
    selectedClient,
}: InstallationStepProps & ReturnType<typeof mapDispatchToProps>) {
    const classes = useStyles();
    const classesCommon = useCommonStyle();
    const [apiKey, setApiKey] = useState<APIKeyInfo | null>(null);
    const [isSourceDownloadInProgress, setSourceDownloadInProgress] = useState(false);

    useEffect(() => {
        ServiceWire.getSternumService()
            .getAPIKeys(selectedClient.clientId)
            .then((apiKeys) => {
                setApiKey(last(apiKeys));
            });
    }, [selectedClient.clientId]);

    const handleNextClick = () => {
        onNextClick(step);
    };

    const handleSkipClick = () => {
        onSkipClick(step);
    };

    const handleDownloadSourceClick = async () => {
        try {
            if (apiKey?.secret) {
                setSourceDownloadInProgress(true);

                let downloadResult = await ServiceWire.getSternumService().downloadSimulationKitSourceArchive(
                    apiKey.secret
                );

                if (downloadResult) {
                    WebUtils.downloadReport(downloadResult);
                }

                setSourceDownloadInProgress(false);
            }
        } catch (err) {
            showNotification(
                `Error while downloading source code for attack simulation kit`,
                NotificationVariant.Error
            );
        }
    };

    return (
        <div className={classNames(classes.container, className)}>
            <div className={classes.inner}>
                <div>
                    <StepNumber orderNumber={stepNumber} completed={completed} />
                </div>

                <Box ml={3}>
                    <Typography className={classes.title}>Installation</Typography>

                    {active && (
                        <>
                            <Typography className={classes.description}>
                                Install the Sternum Attack Simulation Kit and execute exploit simulations on your
                                device.
                            </Typography>

                            <Box display="flex" mt={1} gridColumnGap={5}>
                                <Box>
                                    <InfoCircleIcon color="#1B6FDE" />
                                </Box>
                                <Typography variant="caption">
                                    These simulations are not exposing the device to any risks - there is no external
                                    access to them and they are solely for simulation purposes.
                                </Typography>
                            </Box>

                            <Box mt={3}>
                                <AttackSimulationKitCommand />
                            </Box>

                            <div className={classes.downloadSourceSection}>
                                <Typography variant="body2" className={classesCommon.commonBold}>
                                    Download the source code of the Attack Simulation Kit
                                </Typography>
                                <SternumImprovedButton
                                    buttonType="text:blue"
                                    content={
                                        <Typography variant="body2" className={classesCommon.commonBold}>
                                            Download file
                                        </Typography>
                                    }
                                    icon={<DownloadIcon />}
                                    fullWidth={false}
                                    isLoading={isSourceDownloadInProgress}
                                    isDisabled={isSourceDownloadInProgress}
                                    className={classes.downloadSourceButton}
                                    onClick={handleDownloadSourceClick}
                                />
                            </div>

                            <Box display="flex" mt={3}>
                                <SternumImprovedButton
                                    buttonType="regular"
                                    content="Next"
                                    fullWidth={false}
                                    onClick={handleNextClick}
                                />
                                <Box ml={2}>
                                    <SternumImprovedButton
                                        buttonType="text:dark"
                                        content="Skip"
                                        fullWidth={false}
                                        onClick={handleSkipClick}
                                    />
                                </Box>
                            </Box>
                        </>
                    )}
                </Box>
            </div>
        </div>
    );
}

export const InstallationStep: React.FC<InstallationStepProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(InstallationStepComponent);
