import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

import { commonHome } from "../Home.style";

export const useSternumByNumbersViewStyle = makeStyles((theme: Theme) => ({
    mainContainerWithBackground: {
        position: "relative",
        width: "100%",
        background: commonHome.customersOpinionsBackground,
        color: "white",
        textAlign: "center",
        overflow: "hidden",
    },

    darkViewContainer: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        backgroundColor: commonHome.darkBackground,
        borderTopLeftRadius: 42,
        borderTopRightRadius: 42,
        zIndex: 10,

        [`@media (max-width: ${commonHome.mediaQueryMaxWidth})`]: {
            borderRadius: 0,
        },
    },

    mainContainer: {
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        zIndex: 10,
    },

    backgroundLogo: {
        position: "absolute",
        transform: "translate(-50%, -50%)",
        left: "70%",
        top: "63%",
        zIndex: 0,
        color: "#2D1F3E",
    },

    title: {
        fontSize: 32,
        fontWeight: 600,
        margin: "115px 0px 36px 0px",

        [`@media (max-width: ${commonHome.mediaQueryMaxWidth})`]: {
            fontSize: 22,
            lineHeight: "33px",
            margin: "71px 0px 76px 0px",
        },
    },

    tilesContainer: {
        marginBottom: 36,
        width: "100%",
    },

    footerDescription: {
        fontSize: 13,
        lineHeight: "18px",
        margin: "202px 0 25px 0",
        color: "#9081A2",

        [`@media (max-width: ${commonHome.mediaQueryMaxWidth})`]: {
            margin: "12.5px 0 70px 0",
        },
    },
}));
