/**
 * Common utils for dealing with the JS language.
 */
import moment from "moment";

const NUMBER_PREFIXES = {
    "24": "Y",
    "21": "Z",
    "18": "E",
    "15": "P",
    "12": "T",
    "9": "G",
    "6": "M",
    "3": "k",
    "0": "",
    "-3": "m",
    "-6": "µ",
    "-9": "n",
    "-12": "p",
    "-15": "f",
    "-18": "a",
    "-21": "z",
    "-24": "y",
};

class Utils {
    /**
     * Converts a map into pairs.
     */
    public static getMapPairs<V>(map: Object): Object[] {
        let pairs = [];

        for (let key in map) {
            if (map.hasOwnProperty(key)) {
                pairs.push({
                    key: key,
                    value: map[key],
                });
            }
        }

        return pairs;
    }

    /**
     * Returns the current time in epoch time.
     */
    public static now(): number {
        return moment().valueOf();
    }

    /**
     * Parses query params object to remove any question marks inside the values.
     */
    public static parseQueryStringParamsObject(queryStringParamsObject: any): any {
        let evaluatedParsedUrlQuery = {};

        for (let key in queryStringParamsObject) {
            if (queryStringParamsObject.hasOwnProperty(key)) {
                if (key && key.length && key[0] === "?") {
                    evaluatedParsedUrlQuery[key.substr(1)] = queryStringParamsObject[key];
                } else {
                    evaluatedParsedUrlQuery[key] = queryStringParamsObject[key];
                }
            }
        }

        return evaluatedParsedUrlQuery;
    }

    /**
     * Returns whether given argument is null or undefined.
     */
    public static isNullOrUndefined(arg: any): boolean {
        return arg === null || arg === undefined;
    }

    /**
     * Returns all the keys of a given map.
     * @param map The map to get keys from.
     */
    public static getMapKeys<V>(map: { [key: string]: V }): string[] {
        let keys = [];

        for (let key in map) {
            if (map.hasOwnProperty(key)) {
                keys.push(key);
            }
        }

        return keys;
    }

    /**
     * Returns all the values of a given map.
     * @param map The map to get values from.
     */
    public static getMapValues<V>(map: { [key: string]: V }): V[] {
        let values = [];

        for (let key in map) {
            if (map.hasOwnProperty(key)) {
                values.push(map[key]);
            }
        }

        return values;
    }

    /**
     * Divides given collection into groups by given extractor.
     */
    public static groupBy<T>(collection: T[], extractor: (T) => string | number): Record<string | number, T[]> {
        let groupsMap: Record<string | number, T[]> = {};

        for (const element of collection) {
            const groupByValue = extractor(element);

            if (!groupsMap[groupByValue]) {
                groupsMap[groupByValue] = [];
            }

            groupsMap[groupByValue].push(element);
        }

        return groupsMap;
    }

    /**
     * Creates a map from given array with given key selector and value selector functions.
     * @param array The array to convert to a map.
     * @param keySelector The function to extract a key from a single entity.
     * @param valueSelector The function to extract a value from a single entity.
     */
    public static createMapFromArray<V, E>(
        array: E[],
        keySelector: (element: E) => string,
        valueSelector: (element: E) => V
    ) {
        let map: { [mapKey: string]: V } = {};

        for (let i = 0; i < array.length; i++) {
            let element: E = array[i];

            map[keySelector(element)] = valueSelector(element);
        }

        return map;
    }

    /**
     * Returns all the keys of given object.
     */
    public static getObjectKeys(object: Object): string[] {
        let keys: string[] = [];

        for (let key in object) {
            if (object.hasOwnProperty(key)) {
                keys.push(key);
            }
        }

        return keys;
    }

    /**
     * Gets a promise waiting for given delayMs.
     * @param delayMs The amount of time to wait, in milliseconds.
     */
    public static asyncDelay(delayMs: number): Promise<void> {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                resolve();
            }, delayMs);
        });
    }

    /**
     * Returns an array with given amount of elements, ranging from 0 to given amount.
     */
    public static range(amount: number): number[] {
        let numbers: number[] = [];

        for (let i = 0; i < amount; i++) {
            numbers.push(i);
        }

        return numbers;
    }

    /**
     * Returns whether two given arrays are different from one another.
     * @param first The first array to compare with.
     * @param second The second array to compare with.
     */
    public static areArraysDifferent(first: string[], second: string[]) {
        if (!first && !second) {
            return false;
        }

        if ((!first && second) || (first && !second) || first.length !== second.length) {
            return true;
        }

        for (let i = 0; i < first.length; i++) {
            if (first[i] !== second[i]) {
                return true;
            }
        }

        return false;
    }

    /**
     * Returns whether any elements match the given predicate.
     */
    public static anyMatch<T>(collection: T[], predicate: (element: T) => boolean): boolean {
        if (!collection || !collection.length || !predicate) {
            return false;
        }

        for (let i = 0; i < collection.length; i++) {
            let element = collection[i];

            if (predicate(element)) {
                return true;
            }
        }

        return false;
    }

    /**
     * Find the first element in a collection that satisfies given predicate.
     * @param collection Collection to search in.
     * @param predicate The predicate that test if element is found.
     * @param transformReturnedElement Function to execute on the returned found element.
     */
    public static findFirst<T, U>(
        collection: T[],
        predicate: (element: T) => boolean,
        transformReturnedElement: (index: number, element: T) => U
    ): U {
        if (!collection || !collection.length) {
            return null;
        }

        for (let i = 0; i < collection.length; i++) {
            let element = collection[i];

            if (predicate(element)) {
                return transformReturnedElement(i, element);
            }
        }

        return null;
    }

    /**
     * Finds first element by predicate and returns its index.
     */
    public static findFirstIndex<T>(collection: T[], predicate: (element: T) => boolean): number {
        return Utils.findFirst(collection, predicate, (index, element) => index);
    }

    /**
     * Duplicates array until it reaches the given length.
     */
    public static duplicateArray<T>(array: T[], length: number): T[] {
        let duplicatedArray: T[] = [];
        duplicatedArray = duplicatedArray.concat(array);

        while (duplicatedArray.length < length) {
            duplicatedArray = duplicatedArray.concat(array);
        }

        return duplicatedArray.slice(0, length);
    }

    /**
     * Finds first element by predicate and returns element.
     */
    public static findFirstElement<T>(collection: T[], predicate: (element: T) => boolean): T {
        return Utils.findFirst(collection, predicate, (index, element) => element);
    }

    /**
     * Convert string with spaces into capital letter string
     */
    public static capitalize(stringData: string): string {
        const splitBySpace = stringData.split(" ");
        let capitalizeString;
        if (splitBySpace.length > 1) {
            capitalizeString = splitBySpace.reduce(
                (first: string, second: string) =>
                    first.charAt(0).toUpperCase() +
                    first.slice(1) +
                    " " +
                    second.charAt(0).toUpperCase() +
                    second.slice(1)
            );
        } else {
            if (splitBySpace.length == 1) {
                capitalizeString = splitBySpace[0].charAt(0).toUpperCase() + splitBySpace[0].slice(1);
            }
        }

        return capitalizeString;
    }

    /**
     * Convert first letter of the first word in a string
     */
    public static capitalizeFirsLetter(stringData: string): string {
        return typeof stringData !== "string" ? "" : stringData.charAt(0).toUpperCase() + stringData.slice(1);
    }

    /**
     * Convert string with spaces into capital letters with underscores
     */
    public static toUpperCaseWithUnderscore(stringData: string): string {
        const splitBySpace = stringData.split(" ");
        let capitalizeString: string;
        if (splitBySpace.length > 1) {
            capitalizeString = splitBySpace.reduce(
                (first: string, second: string) => first.toUpperCase() + "_" + second.toUpperCase()
            );
        } else {
            if (splitBySpace.length == 1) {
                capitalizeString = splitBySpace[0].toUpperCase();
            }
        }

        return capitalizeString;
    }

    /**
     * Takes a part of the collection by given fromIndex and length.
     */
    public static takePartCollection<T>(collection: T[], fromIndex: number, length: number): T[] {
        if (!collection || !collection.length) {
            return [];
        } else if (fromIndex >= collection.length) {
            return [];
        } else {
            let partCollection: T[] = [];

            for (let i = fromIndex; i < fromIndex + length && i < collection.length; i++) {
                partCollection.push(collection[i]);
            }

            return partCollection;
        }
    }
    /**
     * Sorts given collection. The property used for extraction is extracted using given propertyExtractor function.
     */
    public static sortCollection<T, U>(collection: T[], propertyExtractor: (T) => U): T[] {
        if (!collection || !collection.length) {
            return [];
        } else {
            // Sorting sorts the array in place.
            collection.sort((a, b) => {
                let firstElement: U = propertyExtractor(a);
                let secondElement: U = propertyExtractor(b);

                if (firstElement > secondElement) {
                    return 1;
                } else {
                    return -1;
                }
            });

            // So at last, we return the received collection.
            return collection;
        }
    }

    /**
     * Check if given email has valid struct
     */
    public static isValidEmail(email: string): boolean {
        const regexp =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regexp.test(email);
    }

    /**
     * Convert number to a human-readable format
     * @example
     * numberToHumanString(10000) -> "10k"
     *
     * @example
     * numberToHumanString(1800000) -> "1.8M"
     */
    public static numberToHumanString(sn: number): string {
        const n = parseFloat(sn.toPrecision(3));
        const e = Math.max(Math.min(3 * Math.floor(this.getExponent(n) / 3), 24), -24);

        return parseFloat((n / Math.pow(10, e)).toPrecision(3)).toString() + NUMBER_PREFIXES[e];
    }

    private static getExponent(n: number): number {
        if (n === 0) {
            return 0;
        }
        return Math.floor(Math.log10(Math.abs(n)));
    }

    public static wait(ms: number) {
        return new Promise((res) => setTimeout(res, ms));
    }

    public static getTimezoneOffsetInHours() {
        // getTimezonOffset() returns difference between UTC and local time.
        // So if UTC = 12:00, Local = 17:30, then difference is 12:00 - 17:30 = -5:30,
        // in this case we need to round it
        return Math.round(new Date().getTimezoneOffset() / -60);
    }

    public static removeEmptyRecords<T>(obj: T): { [K in keyof T]: NonNullable<T[K]> } {
        const result = {};

        Object.keys(obj).forEach((key) => {
            if ([null, undefined].includes(obj[key])) {
                return;
            }

            result[key] = obj[key];
        });

        return result as { [K in keyof T]: NonNullable<T[K]> };
    }

    public static limitCharacters(n: number, s: string) {
        return s.length > n ? s.slice(0, n) + "..." : s;
    }
}

export default Utils;
