import { Action } from "redux";
import ActionType from "../ActionType";
import ModalKey from "../../state/ModalKey";

/**
 * Defines the data for the enter fullscreen modal action.
 */
export interface EnterModalFullscreenAction extends Action<ActionType> {
    type: ActionType.EnterModalFullscreen;
    modalKey: ModalKey;
}

/**
 * Action creator for entering fullscreen mode for a modal.
 * @param modalKey The key of the modal to enter fullscreen.
 */
export function enterModalFullscreenAction(modalKey: ModalKey): EnterModalFullscreenAction {
    return {
        type: ActionType.EnterModalFullscreen,
        modalKey: modalKey,
    };
}
