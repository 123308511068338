import { IconButton, Typography } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { connect } from "react-redux";
import React, { useRef } from "react";
import { useInViewport } from "react-in-viewport";
import ServiceWire from "../../../lib/services/ServiceWire";
import DeviceDefinitionVersionInfo from "../../../lib/state/DeviceDefinitionVersionInfo";
import { GlobalState } from "../../../lib/state/GlobalState";
import VisualisationInfo from "../../../lib/state/Visualisation/VisualisationInfo";
import VisualisationType from "../../../lib/state/Visualisation/VisualisationType";
import { ExpandArrowsIcon } from "../../SUI/SternumIcon/SternumIcon";
import GraphLoader from "../../SUI/SternumLoaders/GraphLoader";
import getDefaultDataVisualisationConfiguration from "../../VisualisationConfigurationComponent/utils/getDefaultDataVisualisationConfiguration";
import VisualisationConfigurationPresentation from "../../VisualisationConfigurationPresentation/VisualisationConfigurationPresentation";
import visualisationCardStyle from "./PredefinedLinuxVisualisationCardStyle";

interface PredefinedLinuxVisualisationCardClassNameInner {
    title?: string;
    icon?: string;
}

export interface PredefinedLinuxVisualisationCardProps {
    className?: string;
    classNameInner?: PredefinedLinuxVisualisationCardClassNameInner;
    visualisation: VisualisationInfo;
    dashboardDeviceDefinitionVersions: DeviceDefinitionVersionInfo[];
    startTime: Date;
    endTime: Date;
    openVisualisationModal: (
        id: string,
        type?: VisualisationType,
        entityId?: string,
        visualisation?: VisualisationInfo,
        disableEditing?: boolean
    ) => void;
}

const mapStateToProps = (state: GlobalState, ownProps: PredefinedLinuxVisualisationCardProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

type PredefinedLinuxVisualisationCardPropsWithHOC = PredefinedLinuxVisualisationCardProps &
    WithStyles &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const PredefinedLinuxVisualisationCardComponent = ({
    className,
    classNameInner = {},
    visualisation,
    dashboardDeviceDefinitionVersions,
    startTime,
    endTime,
    classes,
    openVisualisationModal,
}: PredefinedLinuxVisualisationCardPropsWithHOC) => {
    const containerRef = useRef();
    const { enterCount } = useInViewport(containerRef, undefined, { disconnectOnLeave: false }, {});

    const clientId = ServiceWire.getClientsService().getSelectedClientId();

    const isGeomap = visualisation.configuration.visualisationType === VisualisationType.GEOMAP;

    const dataVisualisationConfigurations = visualisation.configuration.dataSources.map((dataSource) =>
        getDefaultDataVisualisationConfiguration(
            dataSource.dataSourceKey,
            undefined,
            dataSource.sternumQuery,
            startTime,
            endTime,
            dataSource,
            dataSource.deviceDefinitionVersionIds,
            dashboardDeviceDefinitionVersions.map((version) => version.getVersionName())
        )
    );

    return (
        <div
            ref={containerRef}
            role="presentation"
            aria-label="glance"
            className={classNames(classes.visualisationBoxContainer, className)}
        >
            <div className={classNames(classes.visualisationBox, isGeomap && "no-padding")}>
                <div className={classNames(classes.visualisationTitleContainer, isGeomap && "absolute")}>
                    <Typography
                        className={classNames(classes.visualisationTitle, classNameInner.title)}
                        aria-label="glance title"
                    >
                        {visualisation.displayName}
                    </Typography>

                    <div className={classNames(classes.flexGrow)} />

                    <IconButton
                        className={classNames(classNameInner.icon)}
                        onClick={() =>
                            openVisualisationModal(
                                visualisation.visualisationId,
                                visualisation.configuration.visualisationType,
                                undefined,
                                visualisation,
                                true
                            )
                        }
                    >
                        <ExpandArrowsIcon />
                    </IconButton>
                </div>

                {visualisation && enterCount > 0 ? (
                    <div className={classNames(classes.visualisationContainer)}>
                        <VisualisationConfigurationPresentation
                            visualisationType={visualisation.configuration.visualisationType}
                            displayingInDashboard={true}
                            updated={visualisation.updated}
                            lookedUpEntityId={clientId}
                            dataVisualisationConfigurations={dataVisualisationConfigurations}
                            groupBy={visualisation.configuration.groupBy}
                            receivedDefinitionsResponse={{}}
                            startTime={startTime}
                            endTime={endTime}
                            outline={false}
                            amountOfLoaders={4}
                            amountOfGraphPointsToDisplay={11}
                            hideTableToolbar
                            denseTable
                            isStacked={visualisation.isStacked}
                            isLogarithmicScale={visualisation.isLogarithmicScale}
                            dashboardDeviceDefinitionVersions={dashboardDeviceDefinitionVersions}
                            displayTooltipDataPerDeviceDefinitionVersion
                        />
                    </div>
                ) : (
                    <div className={classNames(classes.loadingContainer)}>
                        <GraphLoader />
                    </div>
                )}
            </div>
        </div>
    );
};

export const PredefinedLinuxVisualisationCard: React.FC<PredefinedLinuxVisualisationCardProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(visualisationCardStyle)(PredefinedLinuxVisualisationCardComponent));
