import { Typography, IconButton, Tooltip } from "@material-ui/core";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import classNames from "classnames";
import { connect } from "react-redux";
import React, { useCallback } from "react";
import { CopyFilesIcon } from "../../SUI/SternumIcon/SternumIcon";
import useApiDocumentationPageStyle from "../ApiDocumentationPageStyle";
import WebUtils from "../../../lib/infra/WebUtils";
import { GlobalState } from "../../../lib/state/GlobalState";
import { NotificationMessage } from "../../../lib/state/NotificationsState";
import { showNotificationAction } from "../../../lib/redux/notifications/ShowNotificationAction";
import { isEmpty, isNil } from "lodash";

export interface EndpointQueryParam {
    name: string;
    type: string;
    required?: boolean;
    default?: string | number;
}

export interface EndpointDefinition {
    id: string;
    name: string;
    method: "GET" | "POST" | "PUT" | "DELETE";
    description?: string;
    queryParams?: EndpointQueryParam[];
    requestExample: string;
    responseExample: any;
}

interface ApiDocumentationEndpointProps {
    endpoint: EndpointDefinition;
    showNotification: (message: NotificationMessage) => void;
}

const mapStateToProps = (state: GlobalState, ownProps: ApiDocumentationEndpointProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        showNotification: (message: NotificationMessage) => dispatch(showNotificationAction(message)),
    };
};

function ApiDocumentationEndpoint({ endpoint, showNotification }: ApiDocumentationEndpointProps) {
    const classes = useApiDocumentationPageStyle();

    const handleCopyClick = useCallback(() => {
        WebUtils.copyContentToClipBoard(endpoint.requestExample);
        showNotification("Code is copied to the clipboard");
    }, [endpoint]);

    return (
        <div>
            <div className={classes.marginBottomLarge}>
                <div className={classes.flex}>
                    <Typography variant="subtitle1" className={classNames(classes.marginRight, classes.commonBold)}>
                        {endpoint.method}
                    </Typography>
                    <Typography variant="subtitle1" className={classNames(classes.extraBold)}>
                        {endpoint.name}
                    </Typography>
                </div>

                {endpoint.description && <Typography variant="body2">{endpoint.description}</Typography>}

                <div className={classNames(classes.requestExampleContainer, classes.marginTop)}>
                    <SyntaxHighlighter
                        language="shell"
                        style={atomOneDark}
                        customStyle={{ padding: 10, fontSize: 12, borderRadius: 8 }}
                    >
                        {"$ " + endpoint.requestExample}
                    </SyntaxHighlighter>

                    <Tooltip title="Copy">
                        <IconButton className={classes.copyRequestButton} onClick={handleCopyClick}>
                            <CopyFilesIcon color="#8B949E" />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>

            {!isEmpty(endpoint.queryParams) && (
                <div className={classes.marginBottomLarge}>
                    <Typography variant="subtitle1" className={classNames(classes.marginRight, classes.commonBold)}>
                        Query Parameters
                    </Typography>

                    <div className={classNames(classes.flexWrap, classes.marginTop)}>
                        {endpoint.queryParams.map((param) => (
                            <div key={param.name} className={classes.queryParam}>
                                <Typography variant="body2" className={classes.commonBold}>
                                    {param.name}
                                </Typography>
                                <Typography variant="body2">: {param.type}</Typography>
                                {!isNil(param.default) && (
                                    <Typography variant="body2" color="textSecondary" className={classes.marginLeftXs}>
                                        (default: {param.default})
                                    </Typography>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            )}

            <div className={classes.marginBottomLarge}>
                <Typography variant="subtitle1" className={classNames(classes.marginRight, classes.commonBold)}>
                    Response
                </Typography>
            </div>

            <SyntaxHighlighter
                language="json"
                style={atomOneDark}
                customStyle={{ padding: 10, fontSize: 12, borderRadius: 8 }}
            >
                {JSON.stringify(endpoint.responseExample, null, 4)}
            </SyntaxHighlighter>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ApiDocumentationEndpoint);
