import { Switch, SwitchProps } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import React from "react";

interface SternumSwitchProps extends SwitchProps {}

const StyledSwitch = withStyles((theme) => ({
    root: {
        width: 34,
        height: 18,
        padding: 0,
        margin: "0 5px",
        display: "flex",
    },

    switchBase: {
        padding: 3,
        color: theme.palette.grey[500],

        "&$checked": {
            transform: "translateX(16px)",
            color: theme.palette.common.white,

            "& + $track": {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },
        },

        "&.MuiSwitch-colorSecondary$checked": {
            "& + $track": {
                opacity: 1,
                backgroundColor: theme.palette.secondary.main,
                borderColor: theme.palette.secondary.main,
            },
        },

        "&$checked.Mui-disabled.Mui-disabled": {
            "& + $track": {
                opacity: 1,
                backgroundColor: "#EAEAEA",
                borderColor: "#EAEAEA",
            },
        },
    },

    thumb: {
        width: 12,
        height: 12,
        boxShadow: "none",
    },

    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 10,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },

    checked: {},
}))(Switch);

export default function SternumSwitch(props: SternumSwitchProps) {
    return <StyledSwitch {...props} />;
}
