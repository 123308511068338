import React, { useState } from "react";
import classNames from "classnames";

import Utils from "../../../../../lib/infra/Utils";

import { useArgumentTagStyles } from "./ArgumentTag.styles";

export interface ArgumentTagProps {
    name: string;
    value: string;
    maxNumberOfVisibleChars?: number;
}

export function ArgumentTag({ name, value, maxNumberOfVisibleChars = 590 }: ArgumentTagProps) {
    const classes = useArgumentTagStyles();

    const [isExpanded, setIsExpanded] = useState(false);

    const separator = ": ";
    const nameAndSeparatorTextLength = name.length + separator.length;
    const valueTextLength = value.length;
    const isTextTooBig = nameAndSeparatorTextLength + valueTextLength > maxNumberOfVisibleChars;
    const valueLimitText = Utils.limitCharacters(maxNumberOfVisibleChars - nameAndSeparatorTextLength, value);

    const handleButtonClick = () => {
        setIsExpanded((isExpanded) => !isExpanded);
    };

    return (
        <div className={classes.argumentTagContainer}>
            <span className={classNames(classes.text, classes.name)}>{name + separator}</span>
            <span className={classes.text}>{isExpanded ? value : valueLimitText}</span>
            {isTextTooBig && (
                <span className={classes.showMoreButton} onClick={handleButtonClick}>
                    {isExpanded ? "  Show less" : "  Show more"}
                </span>
            )}
        </div>
    );
}
