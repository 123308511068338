import React from "react";

/**
 * Holds the state for the notifications in sternum app.
 */
export interface NotificationsState {
    displayed: boolean;
    message: NotificationMessage;
    variant?: NotificationVariant;
}

export type NotificationMessage = string | React.ReactNode;

export enum NotificationVariant {
    NotSet = "NotSet",
    Information = "Information",
    Success = "Success",
    Error = "Error",
}

/**
 * Gets the default state for notifications.
 */
export function getNotificationsDefaultState(): NotificationsState {
    return {
        displayed: false,
        message: null,
    };
}
