import { CircularProgress } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import Utils from "../../lib/infra/Utils";
import { openCvesListModal } from "../../lib/redux/modals/OpenModalAction";
import PollingSubscriptionInfo from "../../lib/services/PollingSubscriptionInfo";
import ServiceWire from "../../lib/services/ServiceWire";
import CvesFilter from "../../lib/state/CvesFilter";
import { GlobalState } from "../../lib/state/GlobalState";
import PollingChangeType from "../../lib/state/PollingChangeType";
import SecurityIssuesData from "../../lib/state/SecurityIssuesData";
import SpinnerLoader from "../SUI/SternumLoaders/SpinnerLoader";
import securityIssuesDataTileStyle from "./SecurityIssuesDataTileStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    loadingSecurityIssuesData?: boolean;
    errorLoadingSecurityIssuesData?: boolean;
    securityIssuesData: SecurityIssuesData;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof securityIssuesDataTileStyle> {
    openCvesListModal?: (
        key: string,
        cvesFilter: CvesFilter,
        displayXButton: boolean,
        displayBackButton: boolean
    ) => void;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {
        openCvesListModal: (key: string, cvesFilter: CvesFilter, displayXButton: boolean, displayBackButton: boolean) =>
            dispatch(openCvesListModal(key, cvesFilter, displayXButton, displayBackButton)),
    };
};

/**
 * Displays the dashboard of sternum.
 */
class SecurityIssuesDataTile extends React.Component<AppProps, AppState> {
    /**
     * We should only return CVEs whose updated property is after this value.
     */
    private updatedFrom: number;

    /**
     * We should only return CVEs whose updated property is less than this value.
     */
    private updatedTo: number;

    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        let now = Utils.now();
        this.updatedFrom = now - 30 * 24 * 60 * 60 * 1000;
        this.updatedTo = now;

        // Initializing the state to default.
        this.state = {
            securityIssuesData: new SecurityIssuesData(0, 0),
        };
    }

    /**
     * Occurs once the component finished its initialization process.
     */
    async componentDidMount() {
        await this.loadSecurityIssuesSummaryData();
        // Registering to polling for issues.
        ServiceWire.getPollingService().subscribe(
            new PollingSubscriptionInfo(
                "DashboardCriticalCVES",
                PollingChangeType.ALL_CVES,
                this.handleNewCves.bind(this)
            )
        );
    }

    /**
     * Occurs once the component is being destroyed.
     */
    componentWillUnmount(): void {
        ServiceWire.getPollingService().unsubscribe("DashboardCriticalCVES", PollingChangeType.ALL_CVES);
    }
    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;
        if (this.state.loadingSecurityIssuesData || !this.state.securityIssuesData) {
            return (
                <div className={classNames(classes.flexCenter, classes.flexVMiddle, classes.flexGrow)}>
                    <SpinnerLoader />
                </div>
            );
        } else {
            return (
                <div className={classNames(classes.flexCenter, classes.flexVMiddle, classes.flexGrow)}>
                    {/* Content state */}
                    {this.state.securityIssuesData.total > 0 && (
                        <div className={classNames(classes.cursorPointer, classes.securityIssuesNumberContainer)}>
                            <Typography
                                onClick={() =>
                                    this.props.openCvesListModal(
                                        "CvesListModal",
                                        new CvesFilter(null, null, null, null, null, null),
                                        true,
                                        false
                                    )
                                }
                            >
                                {this.state.securityIssuesData.timedCount}
                            </Typography>
                        </div>
                    )}

                    {/* No CVEs state */}
                    {this.state.securityIssuesData.total === 0 && (
                        <div className={classNames(classes.flexColumn, classes.flexVMiddle)}>
                            {/* Shield icon */}
                            <Icon
                                className={classNames("fa fa-shield-alt", classes.shieldIcon, classes.marginBottom)}
                                fontSize={"large"}
                                classes={{
                                    fontSizeLarge: classes.shieldIconSize,
                                }}
                            />

                            {/* Text for no cves devices */}
                            <Typography className={classes.marginRight}>No CVEs found!</Typography>
                        </div>
                    )}
                </div>
            );
        }
    }

    /**
     * Handle polling operation
     */
    private handleNewCves(fromTimestamp: number, changedData: Object) {
        if (changedData && (changedData["entities"] !== null || changedData["entities"] !== undefined)) {
            const entities = changedData["entities"];
            if (this.state.securityIssuesData) {
                const newTotal = this.state.securityIssuesData.total + entities.length;
                this.setState({ securityIssuesData: new SecurityIssuesData(newTotal, 0) });
            } else {
                const newCves = entities.length;
                this.setState({ securityIssuesData: new SecurityIssuesData(newCves, 0) });
            }
        }
    }

    /**
     * Loads the security issues data summary.
     */
    private async loadSecurityIssuesSummaryData() {
        try {
            // Setting loading to true.
            this.setState({
                loadingSecurityIssuesData: true,
                errorLoadingSecurityIssuesData: false,
            });

            // Fetching security issues summary data.
            let securitySummaryData = await ServiceWire.getSternumService().getSecurityIssuesSummaryData(
                ServiceWire.getClientsService().getSelectedClientId(),
                null,
                null,
                null,
                null
            );

            this.setState({
                loadingSecurityIssuesData: false,
                errorLoadingSecurityIssuesData: false,
                securityIssuesData: securitySummaryData,
            });
        } catch (error) {
            this.setState({
                loadingSecurityIssuesData: false,
                errorLoadingSecurityIssuesData: true,
            });
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(securityIssuesDataTileStyle)(SecurityIssuesDataTile));
