import { makeStyles } from "@material-ui/core";

export const useDownloadAdsSdkStyle = makeStyles((theme) => ({
    root: {},

    title: {
        display: "flex",
        alignItems: "center",
        gap: 8,
    },

    subTitle: {
        maxWidth: 570,
    },
}));
