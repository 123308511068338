import React from "react";
import classNames from "classnames";

import { useSideBarFrameStyle } from "./SideBarItemFrame.style";

export interface SideBarFrameItemProps {
    className?: string;
    children?: React.ReactNode;
}

export function SideBarFrameItem({ className, children }: SideBarFrameItemProps) {
    const classes = useSideBarFrameStyle();

    return <div className={classNames(classes.sideBarFrameItem, className)}>{children}</div>;
}
