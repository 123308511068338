import { WithStyles, withStyles } from "@material-ui/core/styles";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SternumConfiguration from "../../lib/infra/SternumConfiguration";
import Utils from "../../lib/infra/Utils";
import { GlobalState } from "../../lib/state/GlobalState";
import TraceInfo from "../../lib/state/TraceInfo";
import StatusDisplay from "../StatusDisplay/StatusDisplay";
import eventStatusListDisplayStyle from "./EventStatusListDisplayStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof eventStatusListDisplayStyle> {
    traceInfo: TraceInfo;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {};
};

/**
 * Displays arguments when a row is in a list.
 */
class EventStatusListDisplay extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {};
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        let statusDisplayComponent = null;

        let traceTypeConfigurationObject = SternumConfiguration.getTraceEventTypeConfigurationObject(
            this.props.traceInfo.traceDefinition.traceEventName
        );
        if (traceTypeConfigurationObject && traceTypeConfigurationObject.isAttackTrace) {
            return (
                <StatusDisplay
                    label={"Prevented"}
                    danger={true}
                    displayColoredBackground={true}
                    centerText={true}
                    padding={true}
                />
            );
        } else if (
            this.props.traceInfo.traceArguments["SYSTEM_ARG_ROLE_ACTION_STATUS"] &&
            !Utils.isNullOrUndefined(this.props.traceInfo.traceArguments["SYSTEM_ARG_ROLE_ACTION_STATUS"].argumentValue)
        ) {
            let actionStatus = parseInt(
                this.props.traceInfo.traceArguments["SYSTEM_ARG_ROLE_ACTION_STATUS"].argumentValue
            );

            if (actionStatus === 0) {
                return (
                    <StatusDisplay
                        label={"Allowed"}
                        danger={false}
                        success={true}
                        displayColoredBackground={true}
                        centerText={true}
                        padding={true}
                    />
                );
            } else if (actionStatus === 1) {
                return (
                    <StatusDisplay
                        label={"Blocked"}
                        danger={true}
                        displayColoredBackground={true}
                        centerText={true}
                        padding={true}
                    />
                );
            } else {
                return <React.Fragment />;
            }
        } else {
            return <React.Fragment />;
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(eventStatusListDisplayStyle)(EventStatusListDisplay)));
