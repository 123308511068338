import DeviceDefinitionInfo from "./DeviceDefinitionInfo";
import EntityBase from "./EntityBase";
import EntityType from "./EntityType";
import UserInfo from "./UserInfo";
import TraceDefinitionInfo from "./TraceDefinitionInfo";
import ArgumentDefinitionInfo from "./ArgumentDefinitionInfo";
import SternumTriggerInfo from "./SternumTriggerInfo";
import DeviceDefinitionLibraryInfo from "./DeviceDefinitionLibraryInfo";

/**
 * Represents a device definition in sternum.
 */
class DeviceDefinitionVersionInfo extends EntityBase {
    /**
     * Constructor.
     */
    constructor(
        public deviceDefinitionVersionId: string,
        public deviceDefinitionVersionRawId: string,
        public created: number,
        public updated: number,
        public versionFirmware: string,
        public versionStatus: string,
        public description: string,
        public versionSequence: number,
        public versionName: string,
        public createdBy?: UserInfo,
        public deviceDefinition?: DeviceDefinitionInfo,
        public lastDownloadOperation?: number,
        public lastTracesDefinitionChange?: number,
        public parentRawId?: string,
        public traceDefinitions?: TraceDefinitionInfo[],
        public argumentDefinitions?: ArgumentDefinitionInfo[],
        public sternumTriggers?: SternumTriggerInfo[],
        public deviceDefinitionLibraries?: DeviceDefinitionLibraryInfo[]
    ) {
        super(deviceDefinitionVersionId, created, updated, EntityType.DeviceDefinitionVersion);
    }

    /**
     * Converts json to device definition info object.
     */
    public static fromJsonObject(jsonObject: Object) {
        let deviceDefinition =
            jsonObject.hasOwnProperty("device_definition") &&
            !jsonObject["device_definition"].hasOwnProperty("is_reference")
                ? DeviceDefinitionInfo.fromJsonObject(jsonObject["device_definition"])
                : null;
        return new DeviceDefinitionVersionInfo(
            jsonObject["entity_id"],
            jsonObject["device_definition_version_raw_id"].toString(),
            jsonObject["created"],
            jsonObject["updated"],
            jsonObject["version_firmware"],
            jsonObject["version_status"],
            jsonObject["description"] ? jsonObject["description"] : "",
            jsonObject["version_sequence"],
            jsonObject["version_name"],
            jsonObject.hasOwnProperty("creator_user") ? UserInfo.fromJsonObject(jsonObject["creator_user"]) : null,
            deviceDefinition,
            jsonObject["downloaded_date"],
            jsonObject["trigger_download_date"],
            jsonObject["parent_raw_id"] ? jsonObject["parent_raw_id"] : undefined,
            jsonObject["trace_definitions"]
                ? jsonObject["trace_definitions"].map((traceDefinitionJson) =>
                      TraceDefinitionInfo.fromJsonObject(traceDefinitionJson)
                  )
                : [],
            jsonObject["argument_definitions"]
                ? jsonObject["argument_definitions"].map((argumentDefinitionJson) =>
                      ArgumentDefinitionInfo.fromJsonObject(argumentDefinitionJson)
                  )
                : [],
            jsonObject["sternum_triggers"]
                ? jsonObject["sternum_triggers"].map((sternumTriggerInfo) =>
                      SternumTriggerInfo.fromJsonObject(sternumTriggerInfo)
                  )
                : [],
            jsonObject["device_definition_libraries"]
                ? jsonObject["device_definition_libraries"].map((deviceDefinitionLibraryJson) =>
                      DeviceDefinitionLibraryInfo.fromJsonObject(deviceDefinitionLibraryJson)
                  )
                : []
        );
    }

    /**
     *
     * Display device definition version name
     */
    public getVersionName(): string {
        if (this.parentRawId === "N/A") {
            return `${this.deviceDefinition.displayName}`;
        }
        return `${this.deviceDefinition.displayName} (v${this.versionSequence})`;
    }

    /**
     *
     * Get json representation of device definition version for update operation
     */

    public getUpdateJsonObject() {
        return {
            entity_id: this.deviceDefinitionVersionId,
            description: this.description,
            version_firmware: this.versionFirmware,
        };
    }

    /**
     *
     * Get json representation of device definition version for update status operation
     */

    public getUpdateStatusJsonObject() {
        return {
            entity_id: this.deviceDefinitionVersionId,
            version_status: this.versionStatus,
        };
    }

    /**
     *  Return device definition content display
     */
    public getContentDisplay(): string[] {
        return [
            `Firmware Version: ${this.getFirmwareVersion()}`,
            `Operating System: ${this.deviceDefinition.deviceOSFamily}`,
            this.parentRawId !== "N/A" ? `Parent Definition ID: ${this.parentRawId}` : "",
            `Device Definition ID: ${this.deviceDefinitionVersionRawId}`,
        ];
    }

    /**
     *  Get the firmware version
     */
    public getFirmwareVersion(): string {
        return this.isRootVersion() ? this.deviceDefinition.deviceFirmwareVersion : this.versionFirmware;
    }

    /**
     *  Return device definition content display
     */
    public isProduction(): boolean {
        return this.versionStatus === "PRODUCTION";
    }

    /**
     * Change device definition status, currently only to production
     */
    public changeStatusToProduction() {
        this.versionStatus = "PRODUCTION";
    }

    /**
     * Determine if user need to download traces definition file
     */
    public isNewTraceDefinitionsExists(): boolean {
        if (this.lastDownloadOperation && this.lastTracesDefinitionChange) {
            return this.lastTracesDefinitionChange > this.lastDownloadOperation;
        }
        return false;
    }

    /**
     * Get device definition version creator name
     */
    public getCreatorFullName(): string {
        return this.createdBy ? this.createdBy.getFullName() : "John Doe";
    }

    /**
     * Is given device definition version is root version
     */
    public isRootVersion(): boolean {
        return this.parentRawId === "N/A" ? true : false;
    }

    /**
     *
     * Clone function to create new device definition instance.
     */
    public static cloneObject(toClone: DeviceDefinitionVersionInfo): DeviceDefinitionVersionInfo {
        return new DeviceDefinitionVersionInfo(
            toClone.entityId,
            toClone.deviceDefinitionVersionRawId,
            toClone.created,
            toClone.updated,
            toClone.versionFirmware,
            toClone.versionStatus,
            toClone.description,
            toClone.versionSequence,
            toClone.versionName,
            toClone.createdBy,
            toClone.deviceDefinition,
            toClone.lastDownloadOperation,
            toClone.lastTracesDefinitionChange,
            toClone.parentRawId
        );
    }
}

export default DeviceDefinitionVersionInfo;
