import React from "react";
import classNames from "classnames";

import { getMappedValue } from "../../../utils";
import { CheckNoBorderIcon } from "../../SUI/SternumIcon";
import { useCommonStyle } from "../../CommonStyle";
import { useTraceStepperStyle } from "./TraceStepper.style";
import { TraceStepperState } from "./TraceStepper.types";

export interface TraceStepperStepProps extends React.ComponentProps<"div"> {
    stepKey: any;
    stepNumber?: number;
    stepState?: TraceStepperState;
    children?: React.ReactNode;
    getStepState?: (args: { stepKey: any; stepState?: TraceStepperState }) => TraceStepperState | undefined | null;
}

export function Step({ stepKey, stepState, getStepState, stepNumber, onClick, children }: TraceStepperStepProps) {
    const classes = useTraceStepperStyle();
    const classesCommon = useCommonStyle();

    const renderedIcon = getMappedValue<TraceStepperState, React.ReactNode>(
        {
            [TraceStepperState.Active]: <ActiveIcon>{stepNumber}</ActiveIcon>,
            [TraceStepperState.Completed]: <CompletedIcon />,
            [TraceStepperState.NotVisited]: <NotVisitedIcon>{stepNumber}</NotVisitedIcon>,
        },
        stepState || getStepState({ stepKey, stepState })
    );

    return (
        <div key={stepKey} className={classNames(classes.step, onClick && classesCommon.cursorPointer)}>
            {renderedIcon}
            <div>{children}</div>
        </div>
    );
}

function ActiveIcon({ children }: React.ComponentProps<"div">) {
    const classes = useTraceStepperStyle();

    return <div className={classNames(classes.stepIcon, classes.stepActiveIcon)}>{children}</div>;
}

function CompletedIcon({}: React.ComponentProps<"div">) {
    const classes = useTraceStepperStyle();

    return (
        <div className={classNames(classes.stepIcon, classes.stepCompletedIcon)}>
            <CheckNoBorderIcon width={10} height={7} />
        </div>
    );
}

function NotVisitedIcon({ children }: React.ComponentProps<"div">) {
    const classes = useTraceStepperStyle();

    return <div className={classNames(classes.stepIcon, classes.stepNotVisitedIcon)}>{children}</div>;
}
