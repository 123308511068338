import { Typography } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { compare, validate } from "compare-versions";
import * as React from "react";
import { withRouter } from "react-router-dom";
import TimeAgo from "react-timeago";
import UsedLibraryInfo from "../../lib/state/UsedLibraryInfo";
import usedLibraryInfoDisplayStyle from "./UsedLibraryInfoDisplayStyle";
/**
 * Holds the inner state for our app.
 */
interface AppState {}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof usedLibraryInfoDisplayStyle> {
    usedLibrary: UsedLibraryInfo;
}

/**
 * Used library info display.
 */
class UsedLibraryInfoDisplay extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {};
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;
        let latestVersion = "Unknown";
        let versionClass = null;
        let isOutdated = false;

        if (this.props.usedLibrary.library?.latestKnownVersion) {
            latestVersion = this.props.usedLibrary.library.latestKnownVersion;
            isOutdated =
                validate(this.props.usedLibrary.library.latestKnownVersion) && validate(this.props.usedLibrary.version)
                    ? compare(latestVersion, this.props.usedLibrary.version, ">")
                    : latestVersion > this.props.usedLibrary.version;
            versionClass = isOutdated ? classes.danger : null;
        }

        return (
            <div className={classes.root}>
                <div className={classes.infoColumn}>
                    {/* Used library Id */}
                    <div className={classes.detailContainer}>
                        {/* Title */}
                        <Typography variant={"body2"} className={classNames(classes.detailName, classes.commonBold)}>
                            Used Library ID: {this.props.usedLibrary.entityId}
                        </Typography>
                    </div>

                    {/* Library Name */}
                    <div className={classes.detailContainer}>
                        {/* Title */}
                        <Typography variant={"body2"} className={classNames(classes.detailName, classes.commonBold)}>
                            Name: {this.props.usedLibrary.library.name}
                        </Typography>
                    </div>

                    {/* Version */}
                    <div className={classes.detailContainer}>
                        {/* Title */}
                        <Typography variant={"body2"} className={classNames(classes.detailName, classes.commonBold)}>
                            Current Version:
                        </Typography>

                        <Typography variant={"body2"} className={classNames(versionClass, classes.commonBold)}>
                            {this.props.usedLibrary.version}
                        </Typography>
                    </div>

                    {/* Latest Known Version */}
                    <div className={classes.detailContainer}>
                        {/* Title */}
                        <Typography variant={"body2"} className={classNames(classes.detailName, classes.commonBold)}>
                            Latest Version: {latestVersion}
                        </Typography>
                    </div>
                </div>

                <div className={classes.infoColumn}>
                    {/* Updated */}
                    <div className={classes.detailContainer}>
                        {/* Title */}
                        <Typography variant={"body2"} className={classNames(classes.detailName, classes.commonBold)}>
                            Information Collected: <TimeAgo date={this.props.usedLibrary.updated} />
                        </Typography>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(withStyles(usedLibraryInfoDisplayStyle)(UsedLibraryInfoDisplay));
