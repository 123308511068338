import React from "react";
import { History } from "history";

import SternumTabs from "../../../SUI/SternumTabs/SternumTabs";
import SternumTab from "../../../SUI/SternumTabs/SternumTab";

import { LinuxTab } from "./LinuxTabs.types";
import { useLinuxTabsStyle } from "./LinuxTabs.styles";
import SternumConfiguration from "../../../../lib/infra/SternumConfiguration";

export interface LinuxTabsProps {
    history: History;
    renderOverview: () => unknown;
    renderAlerts: () => unknown;
    renderAnomalies: () => unknown;
    renderCrashes: () => unknown;
    renderTraces: () => unknown;
    renderPerformance: () => unknown;
    renderHardwareEvents: () => unknown;
    renderRemoteView: () => unknown;
}

export function LinuxTabs({
    history,
    renderOverview,
    renderAlerts,
    renderAnomalies,
    renderCrashes,
    renderTraces,
    renderPerformance,
    renderHardwareEvents,
    renderRemoteView,
}: LinuxTabsProps) {
    const classes = useLinuxTabsStyle();

    const searchKey = "tab";
    const defaultSelectedTab = LinuxTab.Overview;
    const tabs: Partial<Record<LinuxTab, { label: string; renderContent?: () => unknown }>> = {
        [LinuxTab.Overview]: {
            label: "Overview",
            renderContent: renderOverview,
        },
        [LinuxTab.Alerts]: {
            label: "Alerts",
            renderContent: renderAlerts,
        },
        [LinuxTab.Anomalies]: {
            label: "Anomalies",
            renderContent: renderAnomalies,
        },
        [LinuxTab.Traces]: {
            label: "Traces",
            renderContent: renderTraces,
        },
        [LinuxTab.Performance]: {
            label: "Performance",
            renderContent: renderPerformance,
        },
        [LinuxTab.HardwareEvents]: {
            label: "Hardware Events",
            renderContent: renderHardwareEvents,
        },
        [LinuxTab.RemoteView]: {
            label: "Remote View",
            renderContent: renderRemoteView,
        },
    };

    if (SternumConfiguration.getDeploymentEnv() === "development") {
        tabs[LinuxTab.Crashes] = {
            label: "Crashes",
            renderContent: renderCrashes,
        };
    }

    const urlParams = new URLSearchParams(history.location.search);
    const selectedTab = ((urlTab) => {
        return tabs[urlTab] ? urlTab : defaultSelectedTab;
    })(urlParams.get(searchKey));

    const handleSternumTabChange = (e, selectedTab) => {
        urlParams.set(searchKey, selectedTab);
        history.push({ search: urlParams.toString() });
    };

    const selectedTabData = tabs[selectedTab];

    return (
        <>
            <SternumTabs
                aria-label="device inventory linux tabs"
                value={selectedTab}
                // @ts-ignore
                onChange={handleSternumTabChange}
                classes={{ root: classes.tabsRoot }}
            >
                {Object.keys(tabs).map((tabValue) => {
                    const tab = tabs[tabValue];

                    return <SternumTab key={tabValue} label={tab.label} value={tabValue} disableRipple />;
                })}
            </SternumTabs>
            <div
                role="presentation"
                aria-label="device inventory linux tab content"
                aria-details={selectedTabData?.label}
                className={classes.tabContent}
            >
                {selectedTabData?.renderContent?.()}
            </div>
        </>
    );
}
