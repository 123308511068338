import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { Box, Divider, Typography } from "@material-ui/core";

import SternumLink from "../SUI/SternumLink/SternumLink";
import ServiceWire from "../../lib/services/ServiceWire";
import { GlobalState } from "../../lib/state/GlobalState";
import { UserInformationUpdate } from "../UserInformationUpdateComponent";
import { UsersContainer } from "./Users";
import { useCommonStyle } from "../CommonStyle";
import { ApiKeyGeneration } from "./ApiKeyGeneration";
import { useSettingsPageStyle } from "./SettingsPageStyle";
import { DownloadAdsSdk } from "./DownloadAdsSdk";
import { TrialSettingsDescription } from "./TrailSettingsDescription";
import { EmailNotifications } from "./EmailNotifications";

export interface SettingsComponentProps {
    sideBarOpen: boolean;
}

const mapStateToProps = (state: GlobalState, ownProps: SettingsComponentProps) => {
    return {
        isFreeUser: ServiceWire.getClientsService().getSelectedClient().isTrialTier(),
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

type SettingsPropsWithHOC = SettingsComponentProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

function SettingsComponent({ isFreeUser }: SettingsPropsWithHOC) {
    const classes = useSettingsPageStyle();
    const classesCommon = useCommonStyle();

    const sdkSection = (
        <div className={classes.oneLineContainer}>
            <div className={classes.leftPart}>
                <DownloadAdsSdk />
            </div>
            <div className={classes.rightPart}>
                <ApiKeyGeneration />
            </div>
        </div>
    );

    const dataPrivacyButton = (
        <div className={classes.dataPrivacyContainer}>
            <SternumLink to="/data-privacy" className={classes.linkButton}>
                Data privacy
            </SternumLink>
        </div>
    );

    return (
        <div className={classes.root}>
            {isFreeUser && (
                <div className={classesCommon.marginBottomLarge}>
                    <TrialSettingsDescription />
                </div>
            )}

            {/* Profile */}
            <div className={classesCommon.marginBottomLarge}>
                {/* Title */}
                <Typography variant={"h6"} className={(classesCommon.marginRight, classesCommon.extraBold)}>
                    Profile
                </Typography>
            </div>

            {/* Update user information */}
            <div>
                <UserInformationUpdate className={classNames(classesCommon.marginBottomXLarge)} />
            </div>

            {!isFreeUser && sdkSection}

            {!isFreeUser && (
                <>
                    <Divider style={{ marginTop: "40px" }} />

                    <Box mt="40px">
                        <EmailNotifications />
                    </Box>
                </>
            )}

            {/* Users Section */}
            <UsersContainer />

            {isFreeUser && (
                <>
                    {sdkSection}
                    {dataPrivacyButton}
                </>
            )}
        </div>
    );
}

export const SettingsPage: React.FC<SettingsComponentProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingsComponent);
