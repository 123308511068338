import { makeStyles } from "@material-ui/core";
import commonStyle from "../../../CommonStyle";

export const useStyle = makeStyles((theme) => ({
    ...commonStyle(theme),
    container: {
        width: "100%",
        height: "100%",
    },
    filterContainer: {
        minHeight: theme.spacing(17),
    },

    tracesListPaper: {
        height: "100%",
        marginTop: theme.spacing(4),
        paddingTop: theme.spacing(2),
        borderTop: "1px solid #E4E4E4",
    },

    eventsSectionTitleContentContainer: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },

    eventsSectionTitleTypography: {
        fontWeight: 500,
        fontSize: 16,
        color: "#ACB4BD",
        marginLeft: theme.spacing(1),
    },

    queryEditorFooter: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
    },

    filterVerticalDivider: {
        width: 1,
        height: 20,
        background: "#C4C4C4",
        margin: theme.spacing(0, 3),
    },

    filterChip: {
        maxWidth: 200,
    },

    chipsContainer: {
        maxWidth: "calc(100% - 24px - 31px - 53px - 16px - 10px)",
        overflow: "auto",

        "&::-webkit-scrollbar": {
            display: "none",
            // Chrome & Safari
            width: 0,
            height: 0,
        },
        scrollbarWidth: "none", // Firefox
        msOverflowStyle: "none", // IE
    },
}));
