import { Dialog, DialogActions, DialogTitle, IconButton, Typography, withStyles } from "@material-ui/core";
import { WithStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import Utils from "../../lib/infra/Utils";
import ServiceWire from "../../lib/services/ServiceWire";
import InviteUserInfo from "../../lib/state/InviteUserInfo";
import SternumRole from "../../lib/state/SternumRole";
import UserInfo from "../../lib/state/UserInfo";
import SelectComponent from "../DeviceDefinitionComponents/SelectComponent/SelectComponent";
import { CrossIcon } from "../SUI/SternumIcon/SternumIcon";
import SternumImprovedButton from "../SUI/SternumImprovedButton/SternumImprovedButton";
import SternumInputField from "../SUI/SternumInputField/SternumInputField";
import inviteUserDialogStyle from "./InviteUserDialogStyle";

interface AppState {
    email: string;
    serverLoading: boolean;
    serverError: string;
    role: SternumRole;
    sternumBasicRolesList: SternumRole[];
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof inviteUserDialogStyle> {
    open: boolean;
    handleCancel: () => void;
    handleInvite: (newUser: UserInfo) => void;

    theme?;
}

class InviteUserDialog extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */

    constructor(props: AppProps) {
        // Initializing the state to default.
        super(props);
        this.state = {
            email: "",
            serverLoading: false,
            serverError: null,
            role: null,
            sternumBasicRolesList: ServiceWire.getAuthorizationService().getSternumBasicRoles(),
        };
    }

    componentDidMount() {
        this.setState({
            role: this.getDefaultRole(),
        });
    }

    /**
     * Email name change
     */
    private emailChange = (event) => {
        this.setState({ email: event.target.value });
    };

    /**
     * Permission change
     */
    private roleChange = (selectedRole) => {
        const roleName = selectedRole.value;
        const role = this.state.sternumBasicRolesList.filter((role) => role.displayName == roleName)[0];
        this.setState({ role: role });
    };

    /**
     * Disable button
     */
    private disableInviteButton = () => {
        return !Utils.isValidEmail(this.state.email);
    };

    private sendInvitationToUser = async (event) => {
        this.setState({ serverLoading: true });
        const inviteObject: InviteUserInfo = new InviteUserInfo(this.state.email, this.state.role);
        try {
            const clientId = ServiceWire.getClientsService().getSelectedClientId();
            const inviteResponse = await ServiceWire.getSternumService().inviteNewUser(clientId, inviteObject);
            if (inviteResponse) {
                this.resetState();
                this.props.handleInvite(inviteResponse);
            }
        } catch (err) {
            // Error occurred, check if server returned an error
            if (err.response?.data?.error?.message) {
                this.setState({ serverError: err.response.data.error.message });
            } else {
                // Set generic error
                this.setState({ serverError: `Failed to invite ${this.state.email}` });
            }
        } finally {
            this.setState({ serverLoading: false });
        }
    };

    /**
     * Reset dialog state
     */
    private resetState = () => {
        this.setState({ email: "" });
    };

    /**
     * On Cancel button click
     */
    private onCancelClick = () => {
        this.resetState();
        this.props.handleCancel();
    };

    /**
     * Get default role name
     */
    private getDefaultRole = () => {
        if (this.state.sternumBasicRolesList && this.state.sternumBasicRolesList.length > 0) {
            const viewerRole = this.state.sternumBasicRolesList.find((role) => role.displayName === "VIEWER");
            return viewerRole;
        }
        return null;
    };

    /**
     * Render dialog.
     */
    render() {
        const { classes } = this.props;
        return (
            <Dialog
                aria-labelledby="dialog-title"
                open={this.props.open}
                onClose={() => {}}
                disableEnforceFocus
                className={classNames(classes.root)}
            >
                {/* Title */}
                <DialogTitle id="dialog-title">
                    <div className={classNames(classes.flexSpaceBetween, classes.flexVMiddle)}>
                        <Typography variant={"h6"} className={classNames(classes.extraBold)}>
                            Invite New User
                        </Typography>
                        <IconButton aria-label="close" onClick={this.onCancelClick}>
                            <CrossIcon color="#909090" />
                        </IconButton>
                    </div>
                </DialogTitle>

                {/* Body */}
                <div className={classNames(classes.flexColumn, classes.padding, classes.container)}>
                    {/* Email */}
                    <div>
                        <SternumInputField
                            label={"Email"}
                            inputValue={this.state.email}
                            onFieldChange={this.emailChange}
                            error={this.disableInviteButton() && this.state.email.length > 1}
                            helperText={
                                this.disableInviteButton() && this.state.email.length > 1 && "Please enter valid email"
                            }
                            addMargin={true}
                        />
                    </div>

                    {/* Permission */}
                    <div>
                        <Typography variant="caption" className={classNames(classes.labelColor)}>
                            Role
                        </Typography>

                        <SelectComponent
                            name={"selectUserRole"}
                            onFieldChange={(newValue, action) => this.roleChange(newValue)}
                            placeHolder={"Select field..."}
                            clearable={false}
                            searchable={false}
                            isMenuFixed={true}
                            className={classNames(classes.selectComponent)}
                            selectOptions={this.state.sternumBasicRolesList.map((role) => {
                                return {
                                    label: Utils.capitalize(role.displayName.toLowerCase()),
                                    value: role.displayName,
                                };
                            })}
                            selectedValue={
                                this.state.role
                                    ? {
                                          label: Utils.capitalize(this.state.role.displayName.toLocaleLowerCase()),
                                          value: this.state.role,
                                      }
                                    : {}
                            }
                            isDisabled={false}
                            type={"outlined"}
                        />
                    </div>

                    {/** Show error */}
                    {this.state.serverError && (
                        <Typography
                            variant={"subtitle2"}
                            className={classNames(classes.marginBottomMedium)}
                            color="error"
                        >
                            {this.state.serverError}
                        </Typography>
                    )}
                </div>

                <DialogActions className={classNames(classes.paddingBottomXl, classes.flexCenter)}>
                    <div>
                        <SternumImprovedButton
                            onClick={this.onCancelClick}
                            isDisabled={false}
                            isLoading={false}
                            content={"Cancel"}
                            buttonType={"white"}
                        />
                    </div>
                    <div>
                        <SternumImprovedButton
                            onClick={this.sendInvitationToUser}
                            isDisabled={this.disableInviteButton() || this.state.serverLoading}
                            isLoading={this.state.serverLoading}
                            content={"Invite"}
                        />
                    </div>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(inviteUserDialogStyle, { withTheme: true })(InviteUserDialog);
