import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const useSliderItemStyle = makeStyles((theme: Theme) => ({
    sliderItem: {
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        whiteSpace: "initial",
    },
}));
