import { Action } from "redux";

import ActionType from "../ActionType";
import { IssuesResolveItem } from "../../state/IssueInfoType";

// Main action
export interface GetIssueResolveReasonsAction extends Action<ActionType> {
    type: ActionType.GetIssueResolveReasons;
}

export function getIssueResolveReasonsAction(): GetIssueResolveReasonsAction {
    return {
        type: ActionType.GetIssueResolveReasons,
    };
}

// In Progress
export interface GetIssueResolveReasonsInProgressAction extends Action<ActionType> {
    type: ActionType.GetIssueResolveReasonsInProgress;
}

export function getIssueResolveReasonsInProgressAction(): GetIssueResolveReasonsInProgressAction {
    return {
        type: ActionType.GetIssueResolveReasonsInProgress,
    };
}

// Success
export interface GetIssueResolveReasonsSuccessfullyActionFields {
    reasons: IssuesResolveItem[];
}

export interface GetIssueResolveReasonsSuccessfullyAction
    extends Action<ActionType>,
        GetIssueResolveReasonsSuccessfullyActionFields {
    type: ActionType.GetIssueResolveReasonsSuccessfully;
}

export function getIssueResolveReasonsSuccessfullyAction(
    action: GetIssueResolveReasonsSuccessfullyActionFields
): GetIssueResolveReasonsSuccessfullyAction {
    return {
        type: ActionType.GetIssueResolveReasonsSuccessfully,
        reasons: action.reasons,
    };
}

// Error
export interface GetIssueResolveReasonsErrorAction extends Action<ActionType> {
    type: ActionType.GetIssueResolveReasonsError;
}

export function getIssueResolveReasonsErrorAction(): GetIssueResolveReasonsErrorAction {
    return {
        type: ActionType.GetIssueResolveReasonsError,
    };
}
