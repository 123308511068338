import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";

import { ArrowIcon } from "./ArrowIcon";

import { useRemoteViewAccordionStyles } from "./RemoteViewAccordion.styles";

export interface RemoteViewAccordionProps {
    isExpanded?: boolean;
    onChange?: (isExpanded: boolean) => unknown;
    header?: React.ReactNode;
    children?: React.ReactNode;
    ariaLabel?: string;
}

export function RemoteViewAccordion({
    isExpanded,
    onChange,
    header,
    ariaLabel,
    children,
    ...props
}: RemoteViewAccordionProps) {
    const classes = useRemoteViewAccordionStyles();

    return (
        <Accordion
            role="presentation"
            aria-details="accordion"
            aria-label={ariaLabel}
            aria-expanded={isExpanded}
            expanded={isExpanded}
            onChange={(_, isExpanded) => onChange(isExpanded)}
            className={classes.accordion}
            {...props}
        >
            <AccordionSummary expandIcon={<ArrowIcon />}>{header}</AccordionSummary>
            <AccordionDetails>
                <div className={classes.horizontalLineSeparator} />
                {children}
            </AccordionDetails>
        </Accordion>
    );
}
