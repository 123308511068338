import { Divider, Icon } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import * as React from "react";
import visualisationButtonStyle from "./VisualisationButtonStyle";
import Popover from "@material-ui/core/Popover";
import VisualisationSummary from "../../lib/services/visualisation/VisualisationSummary";
import ServiceWire from "../../lib/services/ServiceWire";
import { GlobalState } from "../../lib/state/GlobalState";
import { connect } from "react-redux";
import { ChartTwoSeriesIcon } from "../SUI/SternumIcon/SternumIcon";

interface AppState {
    anchorElement: any;

    loadingVisualisation: boolean;
    errorLoadingVisualisation: boolean;
    visualisations: VisualisationSummary[];
}

export interface AppProps extends WithStyles<typeof visualisationButtonStyle> {
    disabled?: boolean;

    onNewVisualisationClicked: () => void;
    onExistingVisualisationClicked: (visualisationId: string) => void;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {};
};

/**
 * Displays a sternum-styled table.
 */
class VisualisationButton extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);
        this.state = {
            anchorElement: null,

            loadingVisualisation: false,
            errorLoadingVisualisation: false,
            visualisations: [],
        };
    }

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <Popover
                    id="add-filter-or-inner-query-popover"
                    open={!!this.state.anchorElement}
                    anchorEl={this.state.anchorElement}
                    onClose={() => {
                        this.setState({
                            anchorElement: null,
                        });
                    }}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                >
                    <div className={classNames(classes.visualisationsContainer)}>
                        <Typography
                            className={classNames(classes.createNewVisualisation)}
                            onClick={() => {
                                this.createNewVisualisationClicked();
                            }}
                        >
                            Create new visualisation
                        </Typography>

                        {this.state.loadingVisualisation && !this.state.errorLoadingVisualisation && (
                            <React.Fragment>
                                <Divider className={classNames(classes.divider)} />

                                {[1, 2, 3].map((index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={classNames(
                                                classes.visualisationItem,
                                                classes.loadingPlaceholder,
                                                "mod-small-column"
                                            )}
                                        />
                                    );
                                })}
                            </React.Fragment>
                        )}

                        {this.state.errorLoadingVisualisation && (
                            <React.Fragment>
                                <Divider className={classNames(classes.divider)} />

                                <Typography
                                    className={classNames(classes.visualisationItem, classes.commonDangerColor)}
                                >
                                    Error loading visualisations...
                                </Typography>
                            </React.Fragment>
                        )}

                        {!!(
                            this.state.visualisations &&
                            this.state.visualisations.length &&
                            !this.state.loadingVisualisation &&
                            !this.state.errorLoadingVisualisation
                        ) && (
                            <React.Fragment>
                                <Divider className={classNames(classes.divider)} />

                                {this.state.visualisations.map((visualisation) => {
                                    return (
                                        <Typography
                                            key={visualisation.visualisationId}
                                            onClick={() =>
                                                this.existingVisualisationClicked(visualisation.visualisationId)
                                            }
                                            className={classNames(classes.visualisationItem)}
                                        >
                                            {visualisation.displayName}
                                        </Typography>
                                    );
                                })}
                            </React.Fragment>
                        )}
                    </div>
                </Popover>

                <div className={classNames(classes.root)} onClick={() => this.props.onNewVisualisationClicked()}>
                    {/* Icon button */}
                    <IconButton
                        color="primary"
                        disabled={this.props.disabled}
                        className={classNames(classes.toolbarItemIconContainer)}
                    >
                        <ChartTwoSeriesIcon width={20} height={20} color="#8B949E" />
                    </IconButton>

                    {/* Visualization title */}
                    <Typography
                        className={classNames(
                            !this.props.disabled && classes.cursorPointer,
                            classes.toolbarItemTitle,
                            classes.inactiveIcon
                        )}
                    >
                        Visualization
                    </Typography>
                </div>
            </React.Fragment>
        );
    }

    private createNewVisualisationClicked() {
        this.setState({
            anchorElement: null,
        });

        this.props.onNewVisualisationClicked();
    }

    private existingVisualisationClicked(visualisationId: string) {
        this.setState({
            anchorElement: null,
        });

        this.props.onExistingVisualisationClicked(visualisationId);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(visualisationButtonStyle)(VisualisationButton));
