import EntityBase from "./EntityBase";
import EntityType from "./EntityType";
import TableRowData from "./TableRowData";
import {
    ClientInfoLimits,
    ClientInfoOnboardingState,
    ClientInfoTier,
    ClientInfoUsage,
    SignupUserGuideStep,
} from "./ClientInfo.types";

/**
 * Represents a client in sternum.
 */
class ClientInfo extends EntityBase implements TableRowData {
    /**
     * Constructor.
     */
    constructor(
        public clientId: string,
        public created: number,
        public updated: number,
        public name: string,
        public tier: ClientInfoTier | null | undefined,
        public limits: ClientInfoLimits | null | undefined,
        public usage: ClientInfoUsage | null | undefined,
        public onboardingState: ClientInfoOnboardingState | null | undefined
    ) {
        super(clientId, created, updated, EntityType.Client);
    }

    /**
     * Converts json to info object.
     */
    public static fromJsonObject(jsonObject: Object) {
        const limits = jsonObject["limits"];
        const usage = jsonObject["usage"];
        const onboardingState = jsonObject["onboarding_state"];

        return new ClientInfo(
            jsonObject["entity_id"],
            jsonObject["created"],
            jsonObject["updated"],
            jsonObject["name"],
            jsonObject["tier"] || null,
            limits && {
                usersLimit: limits["users_limit"],
                devicesLimit: limits["devices_limit"],
                dataLimitMb: limits["data_limit_mb"],
            },
            usage && {
                users: usage["users"],
                devices: usage["devices"],
                data: usage["data"],
            },
            onboardingState && {
                steps: {
                    [SignupUserGuideStep.PrepareOpenWRT]:
                        !!onboardingState["steps"]?.[SignupUserGuideStep.PrepareOpenWRT],
                    [SignupUserGuideStep.InstallProtection]:
                        !!onboardingState["steps"]?.[SignupUserGuideStep.InstallProtection],
                    [SignupUserGuideStep.MonitorDevice]:
                        !!onboardingState["steps"]?.[SignupUserGuideStep.MonitorDevice],
                },
            }
        );
    }

    public isTrialTier(): boolean {
        return this.tier === ClientInfoTier.Trial;
    }

    public isOnboardingCompleted(): boolean {
        if (!this.onboardingState) return false;

        return Object.values(this.onboardingState.steps).every((stepCompleted) => stepCompleted);
    }

    public getCurrentOnboardingStep(): SignupUserGuideStep | null {
        if (this.isOnboardingCompleted()) return null;

        if (!this.onboardingState) return SignupUserGuideStep.PrepareOpenWRT;

        if (!this.onboardingState.steps.prepareOpenWRT) return SignupUserGuideStep.PrepareOpenWRT;
        if (!this.onboardingState.steps.installProtection) return SignupUserGuideStep.InstallProtection;
        if (!this.onboardingState.steps.monitorDevice) return SignupUserGuideStep.MonitorDevice;

        return null;
    }

    /**
     * Returns whether given row is any different in details than current issue info.
     * Shallow comparison, in other words.
     * @param other The row to compare with.
     */
    public isDifferentFrom(other: TableRowData): boolean {
        // Row must of of type issue info.
        if (!(other instanceof ClientInfo)) {
            return true;
        }

        let otherClientInfo = other as ClientInfo;

        // Comparing base issue properties.
        return (
            otherClientInfo.entityId !== this.entityId ||
            otherClientInfo.created !== this.created ||
            otherClientInfo.updated !== this.updated ||
            otherClientInfo.name !== this.name ||
            otherClientInfo.tier !== this.tier ||
            otherClientInfo.limits !== this.limits
        );
    }

    /**
     * Gets the value for the given column id.
     */
    public getColumnValue(columnHeaderId: string): any {
        return this[columnHeaderId];
    }
}

export default ClientInfo;
