import { Theme } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import { sternumTheme } from "../App";
import commonStyle from "../CommonStyle";

let sternumFilterEditorStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: {
            display: "flex",

            "&.mod-row": {
                flexDirection: "row",
                alignItems: "center",
            },
            "&.mod-column": {
                flexDirection: "column",
            },
        },
        titleTypography: {
            display: "flex",
            alignItems: "center",
            marginBottom: theme.spacing(2),
        },
        resetAndApplyButtons: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(4),
        },
        applyButton: {
            marginRight: theme.spacing(2),
        },
        formControl: {
            minWidth: theme.spacing(40),
            maxWidth: theme.spacing(80),
        },
        ipAddressFilter: {
            marginRight: theme.spacing(4),
        },
        fromTimePicker: {
            marginRight: theme.spacing(4),
        },
        filterRow: {
            marginTop: theme.spacing(4),
        },

        selectComponent: {
            minWidth: "190px",
        },
        addFilterOrQueryLink: {
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: "0.8125rem",
            cursor: "pointer",
            textDecoration: "none",
            color: theme.palette.common.black,
            "&:hover": {
                textDecoration: "underline",
            },
        },
        popoverPaper: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            minWidth: theme.spacing(25),
        },
        optionRow: {
            cursor: "pointer",
            "&:hover": {
                backgroundColor: sternumTheme.lightGrey.main,
            },
            "&:hover .optionText": {
                fontWeight: 500,
            },
        },
        valueInputBox: {
            fontSize: "0.8125rem",
            fontWeight: theme.typography.fontWeightRegular,
        },
    });

export default sternumFilterEditorStyle;
