import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import _ from "lodash";
import React, { useEffect, useLayoutEffect, useRef } from "react";
import { GraphData } from "../../../lib/infra/GraphUtils";

export interface SternumBarChartProps {
    id: string;
    data?: GraphData;
    height?: number | string;
    tooltipText?: string;
    labelText?: string;
    inverted?: boolean;
    isCustomTooltip?: boolean;
    stacked?: boolean;
    isLogarithmicScale?: boolean;
    displayValueLabels?: boolean;
    applyExtraMax?: boolean;
    limitLabelWidth?: boolean;
    tooltipCallback?: TooltipCallback;
    minAxisYValue?: number;
}

type TooltipCallback = (value: string, tooltipContent: any, hoveredCategory?: string) => string;

am4core.useTheme(am4themes_animated);
am4core.options.autoDispose = true;

function createSeries(
    valueName: string,
    categoryName: string,
    tooltipText: string,
    labelText?: string,
    isCustomTooltip = false,
    inverted = false,
    multiColumns = false,
    displayValueLabels = true,
    tooltipCallback?: TooltipCallback
): am4charts.ColumnSeries {
    let series = new am4charts.ColumnSeries();
    series.name = valueName;
    series.sequencedInterpolation = true;

    if (displayValueLabels) {
        if (inverted) {
            const labelBullet = series.bullets.push(new am4charts.LabelBullet());
            labelBullet.label.text = labelText || "[bold]{valueX}[/]";
            labelBullet.label.horizontalCenter = "left";
            labelBullet.valign = "middle";
            labelBullet.label.truncate = false;
            labelBullet.fill = am4core.color("#000");
            labelBullet.strokeWidth = 0;
            labelBullet.dx = 5;
        } else {
            const labelBullet = series.columns.template.createChild(am4core.Label);
            labelBullet.text = multiColumns ? "{name}: [bold]{valueY}[/]" : "[bold]{valueY}[/]";
            labelBullet.align = "center";
            labelBullet.truncate = true;
            labelBullet.fill = am4core.color("#000");
            labelBullet.strokeWidth = 0;
            labelBullet.dy = -15;
        }
    }

    if (multiColumns) {
        series.stacked = true;
    }

    if (inverted) {
        series.dataFields.valueX = valueName;
        series.dataFields.categoryY = categoryName;
    } else {
        series.dataFields.valueY = valueName;
        series.dataFields.categoryX = categoryName;
    }

    series.dataFields.customValue = valueName;
    series.columns.template.propertyFields.fill = "barColor";
    series.columns.template.propertyFields.stroke = "barColor";
    series.columns.template.column.cornerRadiusBottomLeft = 4;
    series.columns.template.column.cornerRadiusBottomRight = 4;
    series.columns.template.column.cornerRadiusTopLeft = 4;
    series.columns.template.column.cornerRadiusTopRight = 4;

    series.columns.template.tooltip = new am4core.Tooltip();
    series.columns.template.tooltipHTML = tooltipText;

    series.columns.template.tooltip.label.interactionsEnabled = true;
    series.columns.template.tooltip.keepTargetHover = true;
    series.columns.template.tooltip.getFillFromObject = false;
    series.columns.template.tooltip.background.fill = am4core.color("#000");
    series.columns.template.tooltip.background.stroke = null;
    series.columns.template.tooltip.background.cornerRadius = 8;
    series.columns.template.tooltip.background.filters.clear();
    series.columns.template.tooltip.label.padding(9, 13, 9, 13);
    series.columns.template.tooltip.zIndex = Infinity;

    if (isCustomTooltip) {
        series.columns.template.adapter.add("tooltipHTML", function (text, target) {
            const data = target.tooltipDataItem.dataContext;
            if (data["tooltipValue"]) {
                let tooltip = `
                    <div style="font-size:14px; font-family: Inter; color:#999999; padding-bottom:3px;">
                    ${data["label"]}
                </div>
                    <div style="width:100%; height: 2px; background-color:#343434; margin-bottom:3px;"></div>`;
                let totalCounter = 0;
                for (let content in data["tooltipValue"]) {
                    const contentValue = data["tooltipValue"][content];
                    tooltip += `<div style="font-size:14px; font-family: Inter; color:#999999; padding-bottom:3px;" >
                                    <strong>${content}: </strong> ${contentValue}
                                    </div>`;
                    totalCounter += contentValue;
                }
                tooltip += `<div style="font-size:14px; font-family: Inter; color:#999999; padding-bottom:3px;" >
                                <strong>Total: </strong> ${totalCounter}
                                </div>`;
                return tooltip;
            } else {
                return tooltipText;
            }
        });
    } else {
        if (tooltipCallback) {
            series.columns.template.adapter.add("tooltipHTML", function (text, target) {
                const data = target.tooltipDataItem.dataContext;

                if (multiColumns) {
                    return tooltipCallback(text, data["tooltipContent"][valueName], data["label"]) || tooltipText;
                }

                return tooltipCallback(text, data["tooltipContent"]);
            });
        }
    }

    return series;
}

function SternumBarChart({
    id,
    tooltipText,
    labelText,
    inverted = false,
    isCustomTooltip = false,
    height = 220,
    isLogarithmicScale = false,
    displayValueLabels = true,
    applyExtraMax = true,
    limitLabelWidth = true,
    stacked = false,
    minAxisYValue,
    tooltipCallback,
    ...props
}: SternumBarChartProps) {
    const myChart = useRef<am4charts.XYChart>(null);

    if (!tooltipText) {
        if (inverted) {
            tooltipText = "{categoryY}: <b>{customValue}</b>";
        } else {
            tooltipText = "{categoryX}: <b>{customValue}</b>";
        }
    }

    useLayoutEffect(() => {
        let chart = am4core.create(id, am4charts.XYChart);

        chart.fontFamily = '"Inter", "Roboto", "Helvetica", "Arial", sans-serif';
        chart.fontSize = 12;
        chart.paddingLeft = 0;
        chart.paddingRight = 25;

        chart.numberFormatter.numberFormat = "#.#a";

        /* Create axes */
        let categoryAxis: am4charts.CategoryAxis;
        if (inverted) {
            categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        } else {
            categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        }

        categoryAxis.dataFields.category = "label";
        categoryAxis.renderer.grid.template.disabled = true;
        if (inverted) {
            categoryAxis.renderer.minGridDistance = 10;
        } else {
            categoryAxis.renderer.minGridDistance = 100;
        }
        categoryAxis.renderer.labels.template.fill = am4core.color("#B8BACF");
        categoryAxis.renderer.labels.template.fontSize = 12;
        // @ts-ignore
        categoryAxis.renderer.labels.template.fontWeight = 500;

        if (limitLabelWidth) {
            categoryAxis.renderer.labels.template.truncate = true;

            categoryAxis.events.on("sizechanged", (event) => {
                let axis = event.target;
                let cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
                axis.renderer.labels.template.maxWidth = cellWidth;
            });
        }

        if (inverted) {
            categoryAxis.renderer.inversed = true;
        }

        /* Create value axis */
        let valueAxis: am4charts.ValueAxis;
        if (inverted) {
            valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        } else {
            valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        }

        valueAxis.renderer.grid.template.stroke = am4core.color("#959595");
        valueAxis.renderer.grid.template.strokeOpacity = 0.1;
        valueAxis.renderer.labels.template.fill = am4core.color("#B8BACF");
        valueAxis.renderer.labels.template.fontSize = 12;
        // @ts-ignore
        valueAxis.renderer.labels.template.fontWeight = 500;

        if (applyExtraMax) {
            valueAxis.extraMax = 0.15;
        }

        if (isLogarithmicScale) {
            valueAxis.logarithmic = true;
            valueAxis.treatZeroAs = 0.1;
        }

        if (minAxisYValue !== undefined) {
            valueAxis.min = minAxisYValue;
        }

        if (stacked) {
            props.data.datasets.forEach((dataset) => {
                chart.series.push(
                    createSeries(
                        dataset.source,
                        "label",
                        tooltipText,
                        labelText,
                        false,
                        inverted,
                        true,
                        false,
                        tooltipCallback
                    )
                );
            });
        } else {
            chart.series.push(
                createSeries(
                    "count",
                    "label",
                    tooltipText,
                    labelText,
                    isCustomTooltip,
                    inverted,
                    false,
                    displayValueLabels,
                    tooltipCallback
                )
            );
        }

        myChart.current = chart;

        return () => {
            chart.dispose();
        };
    }, [isLogarithmicScale, minAxisYValue]);

    useEffect(() => {
        const data = [];

        if (!stacked) {
            props.data.datasets.forEach((dataset, index) => {
                let tooltipValue = dataset.originalData || dataset.data;

                if (isCustomTooltip) {
                    if (props.data.tooltips[props.data.labels[index]]) {
                        tooltipValue = props.data.tooltips[props.data.labels[index]];
                    }
                }

                data.push({
                    label: props.data.labels[index],
                    count: dataset.data.value,
                    tooltipValue: tooltipValue,
                    tooltipContent: dataset.tooltipContent,
                    barColor: dataset.backgroundColor,
                });
            });
        } else {
            props.data.labels.forEach((label, index) => {
                data.push({
                    ...props.data.datasets.reduce((acc, dataset) => {
                        if (dataset.data.values[label]) {
                            acc[dataset.source] = dataset.data.values[label];
                        }

                        return acc;
                    }, {}),
                    tooltipContent: props.data.datasets.reduce((acc, dataset) => {
                        acc[dataset.source] = dataset.tooltipContent;
                        return acc;
                    }, {}),
                    label: props.data.labels[index],
                });
            });
        }

        myChart.current.data = data;
    }, [props.data]);

    return <div id={id} style={{ width: "100%", height }} />;
}

export default React.memo(SternumBarChart, (prevProps, nextProps) => {
    if (!_.isEqual(prevProps.data.labels, nextProps.data.labels)) {
        return false;
    }

    if (!_.isEqual(prevProps.data.datasets, nextProps.data.datasets)) {
        return false;
    }

    if (prevProps.isLogarithmicScale !== nextProps.isLogarithmicScale) {
        return false;
    }

    return true;
});
