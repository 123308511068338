import { Theme } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import { sternumTheme } from "../App";
import commonStyle from "../CommonStyle";

let deviceDefinitionsDashboardStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: {
            padding: theme.spacing(4),
        },

        deviceDefinitionContainer: {
            padding: theme.spacing(4),
            width: theme.spacing(50),
            height: theme.spacing(38),
            boxShadow: "1px 2px 6px 0 rgba(0,0,0,0.07)",
            border: `1px solid ${sternumTheme.grey.main}`,
            borderRadius: theme.spacing(1),
        },

        deviceDefinitionCardsContainer: {
            display: "grid",
            alignItems: "center",
            gridTemplateColumns: "repeat(auto-fit, minmax(365px, 1fr))",
            columnGap: "20px",
            rowGap: "20px",
        },

        deviceCardContainer: {
            width: theme.spacing(50),
            height: theme.spacing(38),
        },

        deviceDefinitionTitle: {
            fontWeight: 500,
        },

        fab: {
            position: "fixed",
            bottom: theme.spacing(2),
            right: theme.spacing(3),
            boxShadow: "0px 15px 60px 0px #6070AB40",

            "& svg": {
                transition:
                    "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",
            },

            "&:hover": {
                background: "#fff",

                "& svg": {
                    color: "#1D489B",
                },
            },
        },

        toolTip: {
            fontSize: "0.85rem",
        },

        loadingBox: {
            width: "18vw",
            marginRight: theme.spacing(4),
            marginBottom: theme.spacing(4),
        },
    });

export default deviceDefinitionsDashboardStyle;
