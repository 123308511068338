import { makeStyles, Theme } from "@material-ui/core";

export const useCategoryActionsStyle = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        gap: 24,
        alignItems: "center",
        fontSize: 14,
    },

    separator: {
        height: 24,
        width: 1,
        background: "#E6E6E6",
    },

    onlyUnresolved: {
        display: "flex",
        alignItems: "center",
        gap: 7,

        "&[aria-disabled='true']": {
            color: "#D2D2D9",
        },
    },

    checkboxSelectedView: {
        display: "flex",
        gap: 4,
        alignItems: "center",
        fontSize: 14,
        minWidth: 103,
    },

    checkboxSelectedWithOverflowView: {
        width: 103,
    },

    calendarIcon: {
        color: "#ACB4BD",
    },

    switchSelectorGraphView: {
        marginLeft: 8,
    },

    dropdownDisplayedLongValue: {
        display: "flex",
        flex: "1 1 1px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
}));
