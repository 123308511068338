import { makeStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";

export const useSideBarFrameStyle = makeStyles((theme: Theme) => ({
    sideBarFrame: {
        display: "flex",
        flexFlow: "column",
        gap: 8,
        margin: "20px 20px 24px 20px",
    },

    sideBarFrameItem: {
        color: "#D5E3FF",
        padding: 16,
        background: "rgba(255, 255, 255, 0.1)",
        borderRadius: 12,
    },

    sideBarFrameItemButton: {
        display: "flex",
        alignItems: "center",
        gap: 8,
        border: "none",
        outline: "none",
        cursor: "pointer",
        width: "100%",
    },

    sideBarFrameItemButtonSelected: {
        background: "white",
        color: "#000",
    },

    sideBarFrameButton: {
        padding: 16,
    },
}));
