import VisualisationDataSource from "../../../lib/state/Visualisation/VisualisationDataSource";
import UIDataVisualisationConfiguration from "../entities/UIDataVisualisationConfiguration";

function getVisualisationDataSource(
    dataVisualisationConfiguration: UIDataVisualisationConfiguration
): VisualisationDataSource {
    return {
        dataSourceKey: dataVisualisationConfiguration.dataSourceKey,
        sternumQuery: dataVisualisationConfiguration.appliedSternumQuery,
        aggregationFunction: dataVisualisationConfiguration.aggregationFunctionType,
        traceDefinitionId: dataVisualisationConfiguration.aggregatedTraceDefinitionId,
        traceDefinitionDisplayName: dataVisualisationConfiguration.aggregatedTraceDefinitionDisplayName,
        graphLabel: dataVisualisationConfiguration.graphLabel,
        dataSourceLabel: dataVisualisationConfiguration.dataSourceLabel,
        argumentDefinitionId: dataVisualisationConfiguration.aggregatedArgumentDefinitionId,
        deviceDefinitionVersionIds: dataVisualisationConfiguration.deviceDefinitionVersionIds,
        argumentDefinitionDisplayName: dataVisualisationConfiguration.aggregatedArgumentDefinitionDisplayName,
        percentile: dataVisualisationConfiguration.percentile,
        color: dataVisualisationConfiguration.color,
        uniqueColumns: dataVisualisationConfiguration.uniqueColumns,
    };
}

export default getVisualisationDataSource;
