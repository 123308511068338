import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useStyles } from "./UsageStep.style";
import { StepNumber } from "../StepNumber";
import SternumImprovedButton from "../../../../SUI/SternumImprovedButton/SternumImprovedButton";
import SternumLink from "../../../../SUI/SternumLink/SternumLink";
import classNames from "classnames";
import { ExternalLinkIcon } from "../../../../SUI/SternumIcon";
import { SimulateAttackSetupStep } from "../SimulateAttackDialog.component";
import { availableSimulationAttacks } from "../SimulateAttack.model";
import { useCommonStyle } from "../../../../CommonStyle";
import { SimulationVulnerabilities } from "../SimulationVulnerabilities";
import { CopyTextComponent } from "../../../../CopyTextComponent";

interface UsageStepProps {
    step: SimulateAttackSetupStep;
    active: boolean;
    onBackClick: (step: SimulateAttackSetupStep) => unknown;
    stepNumber: number;
    completed: boolean;
    className?: string;
}

export function UsageStep({ step, active, onBackClick, stepNumber, completed, className }: UsageStepProps) {
    const classes = useStyles();
    const classesCommon = useCommonStyle();

    const handleBackClick = () => {
        onBackClick(step);
    };

    return (
        <div className={classNames(classes.container, className)}>
            <div className={classes.inner}>
                <div>
                    <StepNumber orderNumber={stepNumber} completed={completed} />
                </div>

                <Box>
                    <Typography className={classes.title}>Usage</Typography>

                    {active && (
                        <>
                            <Typography variant="caption">Choose an attack to simulate</Typography>

                            {availableSimulationAttacks.map(({ id, category, description, vulnerabilities }) => (
                                <SimulationVulnerabilities
                                    key={id}
                                    categoryId={id}
                                    categoryDescription={description}
                                    categoryName={category}
                                    vulnerabilities={vulnerabilities}
                                />
                            ))}

                            <Typography variant="body2" className={classesCommon.marginTopLarge}>
                                After simulating an attack, you can view detected event and attack data with{" "}
                                <SternumLink to="/glances" isExternal>
                                    Glances
                                </SternumLink>
                            </Typography>

                            <div className={classes.uninstallInstructions}>
                                <Typography variant="body2" className={classesCommon.commonBold}>
                                    Uninstall Simulation Kit
                                </Typography>
                                <Box mt={1}>
                                    <Typography variant="caption">
                                        To uninstall please execute the following command in the root directory of the
                                        installer:
                                    </Typography>
                                </Box>

                                <Box mt={3}>
                                    <CopyTextComponent
                                        text="rm attack_simulation_kit"
                                        notificationText="Command is copied to the clipboard"
                                    />
                                </Box>
                            </div>

                            <Box mt={3}>
                                <SternumImprovedButton
                                    buttonType="text:blue"
                                    content="Back"
                                    fullWidth={false}
                                    onClick={handleBackClick}
                                />
                            </Box>
                        </>
                    )}
                </Box>
            </div>
        </div>
    );
}
