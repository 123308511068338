import { Theme, makeStyles } from "@material-ui/core";

const errorColor = "#E7004C";
const focusColor = "#0d47a1";

export const useTextAreaStyle = makeStyles((theme: Theme) => ({
    root: {
        display: "grid",
        fontFamily: "inherit",
        margin: "16px 0 8px 0",

        "&:focus-within": {},
    },

    textAreaContainer: {
        position: "relative",
        display: "grid",
        borderRadius: "14px",

        "&:hover": {
            "& $textAreaFieldSet": {
                borderColor: "#000",
            },

            "& $textAreaFieldSetError": {
                borderColor: errorColor,
            },
        },

        "&:focus-within": {
            "&& $textAreaFieldSet": {
                borderColor: focusColor,
                borderWidth: 2,

                "&:hover": {
                    borderColor: "#000",
                },
            },

            "&& $textAreaFieldSetError": {
                borderColor: errorColor,
                borderWidth: 2,

                "&:hover": {
                    borderColor: errorColor,
                },
            },

            "&& $textAreaLabel": {
                transform: "translate(14px, -50%) scale(0.8)",
                pointerEvents: "initial",
                color: focusColor,
            },

            "&& $textAreaLabelError": {
                color: errorColor,
            },

            "&& $legend": {
                maxWidth: 10000,
            },
        },
    },

    textAreaIsNotEmpty: {
        "& $textAreaLabel": {
            transform: "translate(14px, -50%) scale(0.8)",
            pointerEvents: "initial",
        },

        "& $legend": {
            maxWidth: 10000,
        },
    },

    textArea: {
        flex: 1,
        width: "100%",
        margin: 1,
        padding: "18.5px 14px",
        resize: "none",
        fontSize: "1rem",
        border: "none",
        outline: "none",
        fontFamily: "inherit",

        "&:hover": {
            border: "none",
            outline: "none",
        },

        "&:focus": {
            border: "none",
            outline: "none",
        },
    },

    textAreaFieldSet: {
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        margin: 0,
        outline: "none",
        border: "1px solid #E4E4E4",
        borderRadius: "14px",
        pointerEvents: "none",
        padding: "18.5px 14px",
    },

    legend: {
        visibility: "hidden",
        height: "2.2px",
        width: "auto",
        maxWidth: 0.01,
        padding: 0,
        margin: "0 -0.4em",
        fontSize: "0.8em",
    },

    textAreaLabel: {
        position: "absolute",
        top: 0,
        left: 0,
        fontSize: 14,
        color: "#999999",
        transform: "translate(14px, 18.5px)",
        transformOrigin: "0 50%",
        transition: "transform 0.1s ease-in-out",
        pointerEvents: "none",
    },

    textAreaLabelError: {
        "&&": {
            color: errorColor,
        },
    },

    label: {
        position: "absolute",
        fontSize: 14,
        color: "#999999",
    },

    textAreaFieldSetError: {
        borderColor: errorColor,

        "&:hover": {
            borderColor: errorColor,
        },
    },

    belowTextArea: {
        fontSize: "0.75rem",
        marginTop: 3,
        textAlign: "left",
    },

    belowTextAreaError: {
        color: errorColor,
    },
}));
