import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

const sternumGeneratedEventViewStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: {
            minHeight: "100%",
            display: "flex",
            flexDirection: "column",
        },

        rootIsResolving: {},

        upperContentContainer: {
            padding: 18,
            paddingBottom: theme.spacing(5),
        },

        geoLocationContentContainer: {
            paddingRight: 32,
            paddingLeft: 32,
            paddingBottom: theme.spacing(3),
        },

        triggerDescriptionText: {
            color: "#908C8C",
            fontSize: "1rem",
        },

        tabsContainer: {
            padding: "0 18px",
        },

        title: {
            fontSize: 20,
            fontWeight: 600,
        },

        titlePushedDown: {
            marginTop: 40,
        },

        parametersContainer: {
            marginTop: 15,
        },

        tracesListPaper: {
            "&.mod-regular": {
                height: "100%",
            },
            "&.mod-fullscreen": {
                height: "100%",
            },
        },
        moreInfoContainer: {
            marginLeft: theme.spacing(3),
        },
        openDeviceInformationIcon: {
            paddingRight: theme.spacing(3),
            paddingLeft: "1px",
        },
        infoCircleIconSize: {
            fontSize: "0.8125rem",
        },
        moreInfoText: {
            cursor: "pointer",
            "&:hover": {
                textDecoration: "underline",
            },
        },
        reportIcon: {
            marginLeft: theme.spacing(1),
            marginTop: theme.spacing(1),

            "&:hover": {
                cursor: "pointer",
            },
        },
        investigateIcon: {
            color: theme.palette.primary.main,
            fontSize: theme.typography.h5.fontSize,
            marginRight: theme.spacing(1),
        },
        familyNameText: {
            marginRight: theme.spacing(1),
        },
        traceDetailsDisplayContainer: {
            padding: theme.spacing(5),
            overflow: "auto",
        },
        tableContainer: {
            height: "100%",
            padding: theme.spacing(4),
            width: "auto",
            overflow: "auto",
            maxHeight: "100%",
        },

        tableInner: {
            height: "100%",
            background: "#fff",
        },

        tabContent: {
            flex: "1 1 1px",
            overflow: "auto",
            background: "#fff",
            margin: "0 18px",
        },
        tabCustomStyle: {
            maxWidth: "none",
        },
        timelineContainer: {
            height: "100%",
            display: "flex",
            flexDirection: "column",
            marginTop: theme.spacing(27),
        },
        eventInfoContainer: {
            padding: theme.spacing(5),
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
            height: "100%",
        },
        exportReportContainer: {},
        generateReportIconSize: {
            fontSize: "14px",
        },
        generateReportText: {
            cursor: "pointer",
            marginRight: theme.spacing(1),
            "&:hover": {
                textDecoration: "underline",
            },
        },
        resolveButton: {
            bottom: theme.spacing(4),
            right: theme.spacing(5),
            position: "absolute",
        },
        resolvedColor: {
            color: "#555B61",
        },
        expandToggle: {
            fontWeight: "bold",
            cursor: "pointer",
            color: "black",
        },
        viewInContextButton: {
            backgroundColor: "#1B6FDE",
            color: "#fff",
            borderRadius: 14,
            fontWeight: 500,
            fontSize: 16,
            textTransform: "none",
            boxShadow: "none",
            lineHeight: "20px",
            marginRight: "80px",
            height: 45,

            "&:hover": {
                backgroundColor: "#1656AB",
                boxShadow: "none",
            },
        },
    });

export default sternumGeneratedEventViewStyle;
