/**
 * Represents a autocomplete library in sternum.
 */

class AutoCompleteLibrary {
    /**
     * Constructor.
     */
    constructor(public libraryName: string, public libraryVersion: string, public libraryVendor: string) {}

    /**
     * Converts json to info object.
     */
    public static fromJsonObject(jsonObject: Object) {
        return new AutoCompleteLibrary(
            jsonObject["library_name"],
            jsonObject["latest_known_version"],
            jsonObject["vendor_name"]
        );
    }
}

export default AutoCompleteLibrary;
