import React from "react";
import useLoadingBarStyle from "./LoadingBarStyle";

export function LoadingBar() {
    const classes = useLoadingBarStyle();

    return (
        <div className={classes.container}>
            <div className={classes.bar1} />
            <div className={classes.bar2} />
            <div className={classes.bar3} />
        </div>
    );
}
