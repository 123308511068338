import React, { useMemo } from "react";

import SternumDeviceProcessesList from "../../../../SternumDeviceProcessesList/SternumDeviceProcessesList";
import TableToolbarDisplayState from "../../../../../lib/state/TableToolbarDisplayState";
import HashSet from "../../../../../lib/infra/HashSet";
import DeviceDefinitionInfo from "../../../../../lib/state/DeviceDefinitionInfo";
import SternumDeviceEventsFilter from "../../../../../lib/state/SternumDeviceEventsFilter";
import DeviceInfo from "../../../../../lib/state/DeviceInfo";

import { useProcessExplorerStyles } from "./ProcessExplorer.styles";

export interface ProcessExplorerProps {
    deviceId: string;
    device: DeviceInfo;
    deviceDefinition: DeviceDefinitionInfo;
    startDate: Date;
    endDate: Date;
}

export function ProcessExplorer({ deviceId, device, deviceDefinition, startDate, endDate }: ProcessExplorerProps) {
    const classes = useProcessExplorerStyles();

    const entitiesFilter = useMemo(() => {
        return new SternumDeviceEventsFilter(
            null,
            false,
            startDate?.getTime() || null,
            endDate?.getTime() || null,
            null,
            null,
            [], // this.state.filterByTraceCategories,
            false,
            null, // this.state.sternumQuery,
            null
        );
    }, [startDate?.getTime(), endDate?.getTime()]);

    const viewedColumns = ["created", "processName", "totalTraces"];

    return (
        <div className={classes.container}>
            <SternumDeviceProcessesList
                isTableExpanded={false}
                doNotDisplayExploitationTypeInDisplayName={true}
                doNotDisplayLoading={true}
                refreshEntitiesFilter={undefined}
                toolbarState={
                    new TableToolbarDisplayState(true, false, false, false, false, false, true, false, "Search process")
                }
                infiniteScroll
                entityId={deviceId}
                deviceDefinition={deviceDefinition}
                viewedColumnsSet={HashSet.fromValues(viewedColumns)}
                entitiesFilter={entitiesFilter}
                deviceDefinitionVersionId={device.lastSeenVersionId}
                shouldDisplayLinkToDeviceView={false}
                paperClassNames={classes.sternumDeviceProcessesList}
                displayBackButtonInTraceView
                displayXButtonInTraceView
                emptyTableMessage={"Processes will appear here."}
                shouldNoWrapDisplayName={false}
                displayTypeColumn
                indexEventTime={endDate?.getTime()}
            />
        </div>
    );
}
