import { makeStyles } from "@material-ui/core";

export const useFeedbackResponseStyle = makeStyles((theme) => ({
    dialogContent: {
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        marginTop: -15,
    },

    statusTitle: {
        marginTop: 35,
        fontSize: "20px",
        fontWeight: 600,
        color: "#000",
        textAlign: "center",
    },

    statusSubTitle: {
        marginTop: 8,
        fontSize: 14,
    },

    actions: {
        "&&&": {
            paddingTop: 36,
        },
    },

    linkButton: {
        fontSize: 16,
        fontWeight: 500,
        color: "#000",
    },
}));
