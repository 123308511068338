import React, { ReactNode } from "react";
import classNames from "classnames";

import { useIconContainerStyles } from "./IconContainer.style";

export interface IconContainerProps extends React.ComponentProps<"div"> {
    className?: string;
    isSelected?: boolean;
    children?: ReactNode;
}

export function IconContainer({ children, className, isSelected, ...props }: IconContainerProps) {
    const classes = useIconContainerStyles();

    return (
        <div className={classNames(className, classes.container, isSelected && classes.isSelected)} {...props}>
            {children}
        </div>
    );
}
