import React, { useCallback } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { Snackbar, SnackbarOrigin } from "@material-ui/core";

import { hideNotificationAction } from "../../lib/redux/notifications/HideNotificationAction";
import { GlobalState } from "../../lib/state/GlobalState";
import { useNotificationSnackbarStyle } from "./NotificationSnackbar.style";
import { NotificationVariant } from "../../lib/state/NotificationsState";
import { CheckFilledIcon, CircleInformationIcon, CrossIcon, ErrorSolidIcon } from "../SUI/SternumIcon";

interface NotificationsSnackbarProps {}

const mapStateToProps = (state: GlobalState, ownProps: NotificationsSnackbarProps) => {
    return { notifications: state.ui.notifications };
};

const mapDispatchToProps = (dispatch: any) => {
    return { hideNotification: () => dispatch(hideNotificationAction()) };
};

const snackbarPosition: SnackbarOrigin = {
    vertical: "bottom",
    horizontal: "right",
};

const hideAfterMs = 5000;

type NotificationsSnackbarPropsWithHOC = NotificationsSnackbarProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

function NotificationSnackbarComponent({ notifications, hideNotification }: NotificationsSnackbarPropsWithHOC) {
    const classes = useNotificationSnackbarStyle();

    const handleClose = useCallback(() => {
        hideNotification();
    }, []);

    function getVariantAndMessage(): { className: string; message: React.ReactNode } {
        switch (notifications.variant) {
            case NotificationVariant.Information:
                return {
                    className: classNames(classes.root, classes.rootInformation),
                    message: (
                        <>
                            <div className={classNames(classes.leftBar)} />
                            <div className={classes.iconContainer}>
                                <CircleInformationIcon className={classes.icon} width={24} height={24} />
                            </div>
                            <div className={classes.content}>{notifications.message}</div>
                            <div className={classes.iconCloseContainer}>
                                <CrossIcon className={classes.iconClose} onClick={handleClose} color="#222831" />
                            </div>
                        </>
                    ),
                };
            case NotificationVariant.Success:
                return {
                    className: classes.root,
                    message: (
                        <>
                            <div className={classNames(classes.leftBar)} />
                            <div className={classes.iconContainer}>
                                <CheckFilledIcon className={classes.icon} width={24} height={24} />
                            </div>
                            <div className={classes.content}>{notifications.message}</div>
                            <div className={classes.iconCloseContainer}>
                                <CrossIcon className={classes.iconClose} onClick={handleClose} color="#222831" />
                            </div>
                        </>
                    ),
                };
            case NotificationVariant.Error:
                return {
                    className: classNames(classes.root, classes.rootError),
                    message: (
                        <>
                            <div className={classNames(classes.leftBar)} />
                            <div className={classes.iconContainer}>
                                <ErrorSolidIcon className={classes.icon} color={null} width={24} height={24} />
                            </div>
                            <div className={classes.content}>{notifications.message}</div>
                            <div className={classes.iconCloseContainer}>
                                <CrossIcon className={classes.iconClose} onClick={handleClose} color="#222831" />
                            </div>
                        </>
                    ),
                };
        }

        return { className: classes.default, message: notifications.message };
    }

    const { className, message } = getVariantAndMessage();

    return (
        <Snackbar
            className={className}
            anchorOrigin={snackbarPosition}
            autoHideDuration={hideAfterMs}
            open={notifications.displayed}
            onClose={handleClose}
            message={message}
        />
    );
}

export const NotificationSnackbar: React.FC<NotificationsSnackbarProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationSnackbarComponent);
