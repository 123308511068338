import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useFormik } from "formik";
import { withRouter } from "react-router-dom";
import zxcvbn from "zxcvbn";
import { History } from "history";

import GoogleRecaptchaWrapper from "../../GoogleRecaptcha/GoogleRecaptcha";
import { SternumInputField } from "../../SUI/SternumInputField";
import SternumImprovedButton from "../../SUI/SternumImprovedButton/SternumImprovedButton";
import SternumBanner from "../../SUI/SternumBanner/SternumBanner";
import { useCommonStyle } from "../../CommonStyle";
import PasswordStrength from "../../SUI/PasswordStrength/PasswordStrength";
import ServiceWire from "../../../lib/services/ServiceWire";
import { SignupContainer } from "../../SignupContainer";
import { useCreatePasswordStyle } from "./CreatePassword.style";
import { PASSWORD_MIN_CHARS_COUNT } from "../../Login/LoginManager.model";
import { Box, Checkbox, FormControlLabel } from "@material-ui/core";
import SternumLink from "../../SUI/SternumLink/SternumLink";
import SternumModal from "../../SUI/SternumModal/SternumModal";
import { TermsConditionsModal } from "../../TermsConditionsModal/TermsConditionsModal";

export interface CreatePasswordComponentProps {
    /**
     * if true, user needs to enter also his name besides password
     * @default false
     */
    isInvitationMode?: boolean;
    onSuccess: () => unknown;
}

interface FormValues {
    password: string;
    confirmPassword: string;
    firstName: string;
    lastName: string;
    termsAgreed?: boolean;
}

enum ClientType {
    PLG = "trial",
    Regular = "regular",
}

function getClientTypeFromParams(s: string): ClientType {
    const params = new URLSearchParams(s);
    return params.get("client_tier") as ClientType;
}

function CreatePasswordComponent({
    onSuccess,
    isInvitationMode = false,
    history,
}: CreatePasswordComponentProps & { history: History }) {
    const reCaptchaElementRef = useRef();
    const classes = useCreatePasswordStyle();
    const classesCommon = useCommonStyle();

    const [isTermsModalOpen, setTermsModalOpen] = useState(false);

    const [clientType] = useState(() => getClientTypeFromParams(history.location.search));
    const [jwtToken] = useState(() => ServiceWire.getAuthenticationService().loadTokenFromUrl());
    const [errorMsg, setErrorMsg] = useState("");
    const [captchaToken, setCaptchaToken] = useState("");
    const [passwordStrength, setPasswordStrength] = useState(-1);

    const formik = useFormik<FormValues>({
        initialValues: {
            password: "",
            confirmPassword: "",
            firstName: "",
            lastName: "",
            termsAgreed: false,
        },
        validate: (values) => {
            const errors: Partial<FormValues> = {};

            if (passwordStrength < 2) {
                errors.password = "The password strength must be medium or above";
            }

            if (values.password.length < PASSWORD_MIN_CHARS_COUNT) {
                errors.password = `The password must contain at least ${PASSWORD_MIN_CHARS_COUNT} characters`;
            }

            if (values.password !== values.confirmPassword) {
                errors.confirmPassword = "The password doesn't match";
            }

            if (isInvitationMode) {
                if (!values.firstName.trim()) {
                    errors.firstName = "First name is required";
                }

                if (!values.lastName.trim()) {
                    errors.lastName = "Last name is required";
                }
            }

            return errors;
        },
        onSubmit: async (values) => {
            setErrorMsg("");

            try {
                let serverResponse;

                if (isInvitationMode) {
                    serverResponse = await ServiceWire.getSternumService().finishRegistration(
                        values.password,
                        jwtToken,
                        values.firstName,
                        values.lastName,
                        values.termsAgreed
                    );
                } else {
                    serverResponse = await ServiceWire.getSternumService().finishRegistration(
                        values.password,
                        jwtToken
                    );
                }

                if (!serverResponse) {
                    throw new Error();
                }

                onSuccess();
            } catch (err) {
                setErrorMsg("Something went wrong, please contact us at support@sternumiot.com");
            }
        },
    });

    useEffect(() => {
        (async () => {
            if (jwtToken) {
                await ServiceWire.getSternumService().jwtValidation(jwtToken);
            }
        })().then();
    }, []);

    const handlePasswordFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const password = e.target.value;
        const passwordStrength = zxcvbn(password)?.score as number;

        setPasswordStrength(passwordStrength);
        formik.setFieldValue("password", e.target.value);
    };

    const handleRecaptchaSuccess = (reCaptchaToken: string) => {
        setCaptchaToken(reCaptchaToken);
    };

    const handleRecaptchaError = () => {
        setCaptchaToken("");
    };

    const handleTermsAgreed = () => {
        formik.setFieldValue("termsAgreed", true);
        setTermsModalOpen(false);
    };

    const isTrialClient = clientType === ClientType.PLG;

    const isSubmitButtonDisabled = () => {
        return (
            (isTrialClient && !formik.values.termsAgreed) ||
            formik.isSubmitting ||
            Object.keys(formik.errors).length > 0
        );
    };

    return (
        <SignupContainer>
            <div className={classNames(classes.content)}>
                <div className={classes.innerContainer}>
                    <h2 className={classNames(classes.title)}>
                        {isInvitationMode ? "Welcome Aboard!" : "Setup Password"}
                    </h2>

                    {errorMsg && (
                        <div className={classNames(classesCommon.marginBottomXLarge, classesCommon.fullWidth)}>
                            <SternumBanner
                                messageType="error"
                                message={errorMsg}
                                onCloseClick={() => setErrorMsg("")}
                            />
                        </div>
                    )}

                    {isInvitationMode && (
                        <>
                            {/* First name input */}
                            <SternumInputField
                                label={"First name"}
                                name="firstName"
                                inputValue={formik.values.firstName}
                                onFieldChange={formik.handleChange}
                                onTouch={() => formik.setFieldTouched("firstName", true)}
                                isTouched={!!formik.touched.firstName}
                                error={!!formik.errors.firstName}
                                helperText={formik.touched.firstName ? formik.errors.firstName : undefined}
                                required
                            />

                            {/* First name input */}
                            <SternumInputField
                                label={"Last name"}
                                name="lastName"
                                inputValue={formik.values.lastName}
                                onFieldChange={formik.handleChange}
                                onTouch={() => formik.setFieldTouched("lastName", true)}
                                isTouched={!!formik.touched.lastName}
                                error={!!formik.errors.lastName}
                                helperText={formik.touched.lastName ? formik.errors.lastName : undefined}
                                addMargin
                                required
                            />
                        </>
                    )}

                    {/* Password input */}
                    <SternumInputField
                        label={"Create password"}
                        inputValue={formik.values.password}
                        onFieldChange={handlePasswordFieldChange}
                        onTouch={() => formik.setFieldTouched("password", true)}
                        isTouched={!!formik.touched.password}
                        isPassword={true}
                        error={!!formik.errors.password}
                        helperText={formik.touched.password ? formik.errors.password : undefined}
                    />

                    <div className={classesCommon.marginBottomXLarge}>
                        <PasswordStrength passwordStrength={passwordStrength} />
                    </div>

                    {/* Confirm password input */}
                    <SternumInputField
                        label={"Confirm password"}
                        inputValue={formik.values.confirmPassword}
                        onFieldChange={(e) => formik.setFieldValue("confirmPassword", e.target.value)}
                        addMargin={true}
                        isTouched={!!formik.touched.confirmPassword}
                        isPassword={true}
                        error={!!formik.errors.confirmPassword}
                        helperText={formik.touched.confirmPassword ? formik.errors.confirmPassword : undefined}
                        onTouch={() => formik.setFieldTouched("confirmPassword", true)}
                        required={true}
                    />

                    {/* Terms & Conditions */}
                    {isInvitationMode && isTrialClient && (
                        <FormControlLabel
                            className={classes.termsCheckbox}
                            control={
                                <Checkbox
                                    checked={formik.values.termsAgreed}
                                    name="termsAgreed"
                                    onChange={(e) => {
                                        if (!formik.values.termsAgreed) {
                                            setTermsModalOpen(true);
                                        } else {
                                            formik.handleChange(e);
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                />
                            }
                            label={
                                <div>
                                    I agree to{" "}
                                    <SternumLink
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setTermsModalOpen(true);
                                        }}
                                    >
                                        Terms & Conditions
                                    </SternumLink>
                                </div>
                            }
                        />
                    )}

                    {/** Google reCaptcha */}
                    <GoogleRecaptchaWrapper
                        elementRef={reCaptchaElementRef}
                        onRecaptchaSuccess={handleRecaptchaSuccess}
                        onRecaptchaError={handleRecaptchaError}
                    />

                    <div className={classes.buttonContainer}>
                        <SternumImprovedButton
                            buttonType="regularWithDisabled"
                            className={classes.loginButton}
                            content={"Get Started"}
                            onClick={formik.handleSubmit}
                            isDisabled={isSubmitButtonDisabled()}
                            isLoading={formik.isSubmitting}
                        />
                    </div>
                </div>
            </div>

            <SternumModal
                modalKey="termsConditions"
                open={isTermsModalOpen}
                onClose={() => setTermsModalOpen(false)}
                showXButton={true}
                showFullScreenButton={false}
                showBackButton={false}
                fullscreen={false}
                modalContentClassNames={classes.modalContent}
            >
                <Box px={5} py={8}>
                    <TermsConditionsModal onTermsAgree={handleTermsAgreed} />
                </Box>
            </SternumModal>
        </SignupContainer>
    );
}

export const CreatePassword: React.FC<CreatePasswordComponentProps> = withRouter(CreatePasswordComponent);
