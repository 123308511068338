import { Input, Typography } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { closeModalAction } from "../../../lib/redux/modals/CloseModalAction";
import { GlobalState } from "../../../lib/state/GlobalState";
import TimeSelectOption from "../../../lib/state/TimeSelectOption";
import { PencilIcon } from "../../SUI/SternumIcon/SternumIcon";
import TimeSelectionComponent from "../../TimeSelectionComponent/TimeSelectionComponent";
import visualisationConfigurationHeaderStyle from "./VisualisationConfigurationHeaderStyle";

interface AppState {
    editingName: boolean;
    displayName: string;
}

export interface AppProps extends WithStyles<typeof visualisationConfigurationHeaderStyle> {
    loading: boolean;
    displayName: string;
    timeSelectedOption: TimeSelectOption;
    onDisplayNameChanged: (displayName: string) => void;
    onTimeRangeSelected: (selectedOption: TimeSelectOption) => void;
    onCloseClicked: () => void;
    isHeaderEditable: boolean;
}

const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeModal: (modalKey) => dispatch(closeModalAction(modalKey)),
    };
};

class VisualisationConfigurationHeader extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);

        const { classes } = this.props;

        // Initializing the state to default.
        this.state = {
            editingName: false,
            displayName: this.props.displayName,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: Readonly<AppProps>, nextContext: any): void {
        if (nextProps.displayName !== this.props.displayName) {
            this.setState({
                displayName: nextProps.displayName,
            });
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classNames(classes.root)}>
                {/* Title */}
                {!this.props.loading && (
                    <div className={classNames(classes.flexVMiddle, classes.flexGrow, classes.marginRightLarge)}>
                        {(!this.state.editingName || !this.props.isHeaderEditable) && (
                            <Typography className={classNames(classes.mainTitle)}>{this.state.displayName}</Typography>
                        )}

                        {this.state.editingName && this.props.isHeaderEditable && (
                            <Input
                                aria-label="glance name"
                                className={classNames(classes.valueInputBox, classes.selectComponent)}
                                value={this.state.displayName}
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        this.setState({
                                            editingName: false,
                                        });
                                    }
                                }}
                                onChange={(event) => {
                                    const updatedDisplayName: string = event.target.value;

                                    this.setState({
                                        displayName: updatedDisplayName,
                                    });

                                    this.props.onDisplayNameChanged(updatedDisplayName);
                                }}
                            />
                        )}
                        {this.props.isHeaderEditable && (
                            <PencilIcon
                                role="button"
                                aria-label="edit glance name"
                                color="#8B949E"
                                onClick={() => {
                                    this.setState({
                                        editingName: !this.state.editingName,
                                    });
                                }}
                                className={classes.pencil}
                            />
                        )}
                    </div>
                )}

                {this.props.loading && (
                    <div className={classNames(classes.marginRightLarge, classes.flexGrow)}>
                        <div className={classNames(classes.loadingPlaceholder, "mod-medium-column")} />
                    </div>
                )}

                {/* Time range content */}
                <div className={classNames(classes.flexVMiddle)}>
                    <TimeSelectionComponent
                        onTimeRangeSelected={this.props.onTimeRangeSelected}
                        timeSelectedOption={this.props.timeSelectedOption}
                    />
                    {/* Close button */}
                    <CloseIcon className={classes.closeIcon} onClick={this.props.onCloseClicked} />
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(visualisationConfigurationHeaderStyle, { withTheme: true })(VisualisationConfigurationHeader));
