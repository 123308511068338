import ActionType from "../ActionType";
import { getModalsDefaultState, ModalsState } from "../../state/ModalsState";
import { ModalsReducerActions } from "./ModalsReducerActions";

/**
 * Reducer for all modal-related actions.
 */
const modalsReducer = (state: ModalsState = getModalsDefaultState(), action: ModalsReducerActions) => {
    switch (action.type) {
        case ActionType.OpenModal: {
            if (
                state.modalIdToConfigurationMap[action.modalKey.getStringKey()] &&
                state.modalIdToConfigurationMap[action.modalKey.getStringKey()].open
            ) {
                // If it's already open, we do nothing.
                return state;
            } else {
                // First, we clone our state.
                let clonedState = {
                    ...state,
                    modalIdToConfigurationMap: {
                        ...state.modalIdToConfigurationMap,
                    },
                    openModalsStack: [...state.openModalsStack],
                };

                // If we don't have a configuration for the modal, we create one.
                if (!clonedState.modalIdToConfigurationMap[action.modalKey.getStringKey()]) {
                    clonedState.modalIdToConfigurationMap[action.modalKey.getStringKey()] = {
                        modalKey: action.modalKey,
                        open: false,
                        fullScreen: action.fullScreen,
                        parametersMap: {},
                    };
                }

                // Otherwise, we set open to true and push the modal onto the modals stack.
                clonedState.modalIdToConfigurationMap[action.modalKey.getStringKey()].open = true;
                clonedState.modalIdToConfigurationMap[action.modalKey.getStringKey()].parametersMap =
                    action.parametersMap;
                clonedState.modalIdToConfigurationMap[action.modalKey.getStringKey()].onClose = action.onClose;
                clonedState.openModalsStack.push(action.modalKey);

                return clonedState;
            }
        }

        case ActionType.CloseModal: {
            // If we don't have a configuration for the modal, we do nothing (can't close a non-existing modal).
            if (!state.modalIdToConfigurationMap[action.modalKey.getStringKey()]) {
                return state;
            }

            if (!state.modalIdToConfigurationMap[action.modalKey.getStringKey()].open) {
                // Can't close a non-open modal.
                return state;
            } else {
                // First, we clone our state.
                let clonedState = {
                    ...state,
                    modalIdToConfigurationMap: {
                        ...state.modalIdToConfigurationMap,
                    },
                    openModalsStack: [...state.openModalsStack],
                };

                // Found open modal, closing it.
                clonedState.modalIdToConfigurationMap[action.modalKey.getStringKey()].open = false;

                // Fire close event if is set
                clonedState.modalIdToConfigurationMap[action.modalKey.getStringKey()].onClose?.();

                // Removing modal from open modals stack.
                let indexToSplice: number = null;

                // First finding its index in the stack.
                for (let i = 0; i < clonedState.openModalsStack.length; i++) {
                    let openModalKey = clonedState.openModalsStack[i];

                    if (openModalKey.getStringKey() === action.modalKey.getStringKey()) {
                        indexToSplice = i;
                        break;
                    }
                }
                // Next, removing index.
                if (indexToSplice !== null) {
                    clonedState.openModalsStack.splice(indexToSplice);
                }

                return clonedState;
            }
        }

        case ActionType.EnterModalFullscreen: {
            if (
                !state.modalIdToConfigurationMap[action.modalKey.getStringKey()] ||
                !state.modalIdToConfigurationMap[action.modalKey.getStringKey()].open ||
                state.modalIdToConfigurationMap[action.modalKey.getStringKey()].fullScreen
            ) {
                return state;
            } else {
                // First, we clone our state.
                let clonedState = {
                    ...state,
                    modalIdToConfigurationMap: {
                        ...state.modalIdToConfigurationMap,
                    },
                    openModalsStack: [...state.openModalsStack],
                };

                clonedState.modalIdToConfigurationMap[action.modalKey.getStringKey()].fullScreen = true;
                return clonedState;
            }
        }

        case ActionType.ExitModalFullscreen: {
            if (
                !state.modalIdToConfigurationMap[action.modalKey.getStringKey()] ||
                !state.modalIdToConfigurationMap[action.modalKey.getStringKey()].open ||
                !state.modalIdToConfigurationMap[action.modalKey.getStringKey()].fullScreen
            ) {
                return state;
            } else {
                // First, we clone our state.
                let clonedState = {
                    ...state,
                    modalIdToConfigurationMap: {
                        ...state.modalIdToConfigurationMap,
                    },
                    openModalsStack: [...state.openModalsStack],
                };

                clonedState.modalIdToConfigurationMap[action.modalKey.getStringKey()].fullScreen = false;
                return clonedState;
            }
        }

        default:
            return state;
    }
};

export default modalsReducer;
