import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
    MenuItem,
    Typography,
    withStyles,
} from "@material-ui/core";
import { WithStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import SternumUtils from "../../../lib/infra/SternumUtils";
import Utils from "../../../lib/infra/Utils";
import ServiceWire from "../../../lib/services/ServiceWire";
import { GlobalState } from "../../../lib/state/GlobalState";
import SternumTriggerInfo from "../../../lib/state/SternumTriggerInfo";
import SternumTriggerType from "../../../lib/state/SternumTriggerType";
import SternumTriggerTypeDisplayInfo from "../../../lib/state/SternumTriggerTypeDisplayInfo";
import TriggerDefinition from "../../../lib/state/triggers/TriggerDefinition";
import CustomInput from "../../../shared_components/CustomInput/CustomInput";
import InfoTooltip from "../../../shared_components/InfoTooltip/InfoTooltip";
import CountOverTimeTrigger from "../../SternumTriggers/CountOverTime/CountOverTimeTrigger";
import NeverSeenArgumentTrigger from "../../SternumTriggers/NeverSeenArgument/NeverSeenArgumentTrigger";
import SpecificBehaviorTrigger from "../../SternumTriggers/SpecificBehaviourTrigger/SpecificBehaviourTrigger";
import WhitelistTrigger from "../../SternumTriggers/Whitelist/WhitelistTrigger";
import triggerDefinitionSimpleDialogStyle from "./TriggerDefinitionSimpleDialogStyle";

interface AppState {
    triggerDisplayName: string;
    triggerCategory: string;
    triggerInterest: string;
    triggerDescription: string;
    isDuplicateError: boolean;
    triggerDefinition: TriggerDefinition;
    childComponentFieldsStatus: boolean;
    triggerType: string;
    triggerEntityTargetType: string;
    displayLoadingBarOnSave: boolean;
    displayLoadingBarOnAddAnother: boolean;
}

export interface AppProps extends WithStyles<typeof triggerDefinitionSimpleDialogStyle> {
    open: boolean;
    sternumTriggerTypes: SternumTriggerTypeDisplayInfo[];
    deviceDefinition?: any;
    existingTriggerDefinition?: SternumTriggerInfo;
    onClose: (
        triggerDisplayName: string,
        triggerCategory: string,
        triggerInterest: string,
        triggerDescription: string,
        triggerType: string,
        triggerDefinition: TriggerDefinition,
        triggerTargetEntityType: string,
        keepDialogOpen: boolean,
        existingTrigger?: SternumTriggerInfo
    ) => void;
    onCancel: () => void;
    duplicationCheck: (triggerDisplayName: string, triggerCategory: string) => boolean;
    isError?: boolean;
    viewOnly?: boolean;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {};
};

class TriggerDefinitionSimpleDialog extends React.Component<AppProps, AppState> {
    removeElemRef;

    constructor(props: AppProps) {
        super(props);

        if (this.props.existingTriggerDefinition) {
            this.state = {
                triggerDisplayName: Utils.capitalizeFirsLetter(this.props.existingTriggerDefinition.displayName),
                triggerDescription: this.props.existingTriggerDefinition.description,
                triggerCategory: this.props.existingTriggerDefinition.triggerCategory,
                triggerInterest: this.props.existingTriggerDefinition.eventInterest,
                triggerType: this.props.existingTriggerDefinition.triggerType,
                isDuplicateError: false,
                triggerDefinition: this.props.existingTriggerDefinition.triggerDefinition,
                childComponentFieldsStatus: false,
                triggerEntityTargetType: "DEVICE",
                displayLoadingBarOnSave: false,
                displayLoadingBarOnAddAnother: false,
            };
        } else {
            // Initializing the state to default.
            this.state = {
                triggerDisplayName: "",
                triggerDescription: "",
                triggerCategory: "",
                triggerInterest: "",
                triggerType: "",
                isDuplicateError: false,
                triggerDefinition: null,
                childComponentFieldsStatus: false,
                triggerEntityTargetType: "DEVICE",
                displayLoadingBarOnSave: false,
                displayLoadingBarOnAddAnother: false,
            };
            this.removeElemRef = React.createRef();
        }
    }

    /*
     * Handle display name change
     */

    private onTriggerDisplayNameChanged = (event) => {
        this.setState({
            triggerDisplayName: event.target.value,
        });
    };

    /*
     * Handle description change
     */

    private onTriggerDescriptionChanged = (event) => {
        this.setState({
            triggerDescription: event.target.value,
        });
    };

    /*
     * Handle Interest change
     */

    private onTriggerInterestChanged = (event) => {
        this.setState({
            triggerInterest: event.target.value,
        });
    };

    /*
     * Handle trigger change
     */

    private onTriggerTypeChanged = (event) => {
        this.setState(
            {
                triggerType: event.target.value,
            }
            //() => this.removeElemRef.current.removeChild(this.removeElemRef.current.firstElementChild)
        );
    };

    /*
     * Handle Category change
     */

    private onTriggerCategoryChanged = (event) => {
        this.setState({
            triggerCategory: event.target.value,
        });
    };

    /**
     * Clear fields
     */
    private clearFields = () => {
        this.setState({
            triggerDisplayName: "",
            triggerDescription: "",
            triggerCategory: "",
            triggerInterest: "",
            triggerType: "",
            isDuplicateError: false,
            triggerDefinition: null,
            childComponentFieldsStatus: false,
            triggerEntityTargetType: "DEVICE",
            displayLoadingBarOnSave: false,
            displayLoadingBarOnAddAnother: false,
        });
    };

    /*
     * Handle fields validation. True - disable button. False - enable
     * If trigger exists, validate state fields with prop fields
     */
    private fieldsValidation = (): boolean => {
        if (this.props.existingTriggerDefinition) {
            // trigger exists, check with props
            return (
                this.state.triggerDisplayName ===
                    Utils.capitalizeFirsLetter(this.props.existingTriggerDefinition.displayName) &&
                this.state.triggerDescription === this.props.existingTriggerDefinition.description &&
                this.state.triggerCategory === this.props.existingTriggerDefinition.triggerCategory &&
                this.state.triggerInterest === this.props.existingTriggerDefinition.eventInterest &&
                !this.state.childComponentFieldsStatus
            );
        } else {
            // new trigger, check with state
            return (
                this.state.triggerDisplayName === "" ||
                this.state.triggerDescription === "" ||
                this.state.triggerCategory === "" ||
                this.state.triggerInterest === "" ||
                !this.state.childComponentFieldsStatus
            );
        }
    };

    /*
     * Handle close dialog operation
     */

    private onSaveClick = (keepDialogOpen: boolean) => {
        this.setState({ displayLoadingBarOnSave: !keepDialogOpen, displayLoadingBarOnAddAnother: keepDialogOpen });
        this.props.onClose(
            Utils.capitalizeFirsLetter(this.state.triggerDisplayName),
            this.state.triggerCategory,
            this.state.triggerInterest,
            this.state.triggerDescription,
            this.state.triggerType,
            this.state.triggerDefinition,
            this.state.triggerEntityTargetType,
            keepDialogOpen,
            this.props.existingTriggerDefinition
        );
        // IF add another is clicked, clear the fields
        if (keepDialogOpen) {
            this.clearFields();
        }
    };

    /*
     * Get data from child component, update only when there is a change to prevent dom render.
     */
    private getFieldsStatusFromChild = (fieldsStatus: boolean, triggerDefinition: TriggerDefinition) => {
        if (fieldsStatus) {
            this.setState({
                triggerDefinition: triggerDefinition,
            });
        }
        if (fieldsStatus !== this.state.childComponentFieldsStatus) {
            this.setState({
                childComponentFieldsStatus: fieldsStatus,
            });
        }
    };

    /**
     * Load trigger definition by selected alert type
     */
    private loadTriggerDefinition = () => {
        // Check if there is an existing trigger definition
        const existingTriggerDefinition = this.props.existingTriggerDefinition
            ? this.props.existingTriggerDefinition.triggerDefinition
            : null;

        const whitelistDefinition = (
            <WhitelistTrigger
                existingDeviceDefinition={this.props.deviceDefinition}
                updateFieldsStatus={this.getFieldsStatusFromChild}
                existingWhitelistDefinition={existingTriggerDefinition}
                disabled={this.props.viewOnly}
            />
        );
        const countOverTimeDefinition = (
            <CountOverTimeTrigger
                existingDeviceDefinition={this.props.deviceDefinition}
                updateFieldsStatus={this.getFieldsStatusFromChild}
                existingCountOverTimeDefinition={existingTriggerDefinition}
                disabled={this.props.viewOnly}
            />
        );

        const specificBehaviorDefinition = (
            <SpecificBehaviorTrigger
                existingDeviceDefinition={this.props.deviceDefinition}
                updateFieldsStatus={this.getFieldsStatusFromChild}
                existingSpecificBehaviorDefinition={existingTriggerDefinition}
                disabled={this.props.viewOnly}
            />
        );

        const neverSeenArgumentDefinition = (
            <NeverSeenArgumentTrigger
                existingDeviceDefinition={this.props.deviceDefinition}
                updateFieldsStatus={this.getFieldsStatusFromChild}
                existingNeverSeenArgumentDefinition={existingTriggerDefinition}
                disabled={this.props.viewOnly}
            />
        );

        switch (SternumTriggerType[this.state.triggerType]) {
            // EVENT_COUNT_OVER_TIME
            case SternumTriggerType.EVENT_COUNT_OVER_TIME:
                return countOverTimeDefinition;

            // SPECIFIC_BEHAVIOR
            case SternumTriggerType.SPECIFIC_BEHAVIOR:
                return specificBehaviorDefinition;

            // COMPOSITE_TRIGGER
            case SternumTriggerType.COMPOSITE_TRIGGER:
                return "";

            // NEVER_SEEN_ARGUMENT
            case SternumTriggerType.NEVER_SEEN_ARGUMENT:
                return neverSeenArgumentDefinition;

            // WHITE_LIST
            case SternumTriggerType.WHITE_LIST:
                return whitelistDefinition;
            default:
                return "";
        }
    };

    /**
     * Handle close click
     */
    private handleCloseClick = () => {
        this.props.onCancel();
    };

    private getDialogTitle = () => {
        if (this.props.viewOnly) {
            return "View";
        }

        return this.props.existingTriggerDefinition ? "Edit" : "Add";
    };

    render() {
        const { classes } = this.props;

        const duplicationError = (
            <Typography variant={"subtitle2"} className={classNames(classes.marginBottomXLarge)} color="error">
                Sternum alert already exists!
            </Typography>
        );

        const serverError = (
            <Typography variant={"subtitle2"} className={classNames(classes.marginBottomLarge)} color="error">
                Operation failed
            </Typography>
        );

        return (
            <Dialog
                role="dialog"
                aria-label="add sternum alert"
                disableEnforceFocus
                aria-labelledby="simple-dialog-title"
                open={this.props.open}
                maxWidth={"lg"}
                onClose={() => {
                    this.props.existingTriggerDefinition ? this.handleCloseClick() : "";
                }}
            >
                {/* Title */}
                <DialogTitle id="simple-dialog-title" className={classNames(classes.flexVMiddle)}>
                    {this.getDialogTitle()} Sternum Alert
                </DialogTitle>

                <div className={classNames(classes.flexColumn, classes.flexCenter, classes.flexVMiddle, classes.root)}>
                    {/*Alert Type */}
                    <div className={classNames(classes.marginBottomLarge, classes.flexRow, classes.fullWidth)}>
                        <CustomInput
                            value={this.state.triggerType}
                            inputTitle={"Alert Type"}
                            maxHeightClass={""}
                            inputClasses={""}
                            autoFocus={false}
                            onChange={this.onTriggerTypeChanged}
                            disabled={!!(this.props.viewOnly || this.props.existingTriggerDefinition)}
                            required={true}
                            select={true}
                            id={"alert-type"}
                        >
                            {/* Empty menu item to fix autoselect first element */}
                            <MenuItem style={{ display: "none" }} />
                            {this.props.sternumTriggerTypes.map((triggerTypeIter, index) => (
                                <MenuItem
                                    aria-label="alert type dropdown menu item"
                                    key={triggerTypeIter.displayName}
                                    value={triggerTypeIter.serverName}
                                    className={classNames(classes.selectItem)}
                                >
                                    <div
                                        className={classNames(
                                            classes.flexRow,
                                            classes.flexSpaceBetween,
                                            classes.fullWidth
                                        )}
                                    >
                                        {/* Display trigger type name */}
                                        <Typography variant="body2">{triggerTypeIter.displayName}</Typography>
                                        {/** Display info icon for additional information */}
                                        <InfoTooltip tooltipContent={triggerTypeIter.description} />
                                    </div>
                                </MenuItem>
                            ))}
                        </CustomInput>
                    </div>

                    {/*Alert category */}
                    <div className={classNames(classes.fullWidth, classes.marginBottomLarge)}>
                        <CustomInput
                            value={this.state.triggerCategory}
                            inputTitle={"Alert Category"}
                            maxHeightClass={""}
                            inputClasses={""}
                            autoFocus={false}
                            onChange={this.onTriggerCategoryChanged}
                            required={true}
                            select={true}
                            id={"alert-category"}
                            disabled={this.props.viewOnly}
                        >
                            {/* Empty menu item to fix autoselect first element */}
                            <MenuItem style={{ display: "none" }} />
                            {ServiceWire.getConfigurationService()
                                .getTraceCategories()
                                .map((traceCategory) => (
                                    <MenuItem
                                        aria-label="alert category dropdown menu item"
                                        key={traceCategory.displayName}
                                        value={traceCategory.systemName}
                                        className={classNames(classes.selectItem)}
                                    >
                                        <div
                                            className={classNames(
                                                classes.flexRow,
                                                classes.flexSpaceBetween,
                                                classes.fullWidth
                                            )}
                                        >
                                            {/* Display trigger type name */}
                                            <Typography variant="body2">{traceCategory.displayName}</Typography>
                                            {/* Display info icon for additional information */}
                                            <InfoTooltip tooltipContent={traceCategory.description} />
                                        </div>
                                    </MenuItem>
                                ))}
                        </CustomInput>
                    </div>

                    {/*Alert Interest */}
                    <div className={classNames(classes.fullWidth, classes.marginBottomLarge)}>
                        <CustomInput
                            value={this.state.triggerInterest}
                            inputTitle={"Alert Interest"}
                            maxHeightClass={""}
                            inputClasses={""}
                            autoFocus={false}
                            onChange={this.onTriggerInterestChanged}
                            required={true}
                            select={true}
                            id={"alert-interest"}
                            disabled={this.props.viewOnly}
                        >
                            {/* Empty menu item to fix autoselect first element */}
                            <MenuItem style={{ display: "none" }} />
                            {SternumUtils.getEventInterests(true, false).map((interestTypeIter) => (
                                <MenuItem
                                    aria-label="alert interest dropdown menu item"
                                    key={interestTypeIter.label}
                                    value={interestTypeIter.value}
                                    className={classNames(classes.selectItem)}
                                >
                                    <div
                                        className={classNames(
                                            classes.flexVMiddle,
                                            classes.flexSpaceBetween,
                                            classes.fullWidth
                                        )}
                                    >
                                        <Typography variant="body2">{interestTypeIter.label}</Typography>
                                        {/* Display info icon for additional information */}
                                        {interestTypeIter.explanation && (
                                            <InfoTooltip tooltipContent={interestTypeIter.explanation} />
                                        )}
                                    </div>
                                </MenuItem>
                            ))}
                        </CustomInput>
                    </div>

                    {/*Alert Display name */}

                    <div className={classNames(classes.fullWidth, classes.marginBottomLarge)}>
                        <CustomInput
                            value={this.state.triggerDisplayName}
                            inputTitle={"Display Name"}
                            maxHeightClass={""}
                            inputClasses={""}
                            autoFocus={false}
                            onChange={this.onTriggerDisplayNameChanged}
                            required={true}
                            id={"alert-display-name"}
                            disabled={this.props.viewOnly}
                            maxLength={SternumUtils.getMaxInputFieldSize()}
                        />
                    </div>

                    {/*Alert Description */}
                    <div className={classNames(classes.fullWidth, classes.marginBottomLarge)}>
                        <CustomInput
                            value={this.state.triggerDescription}
                            inputTitle={"Description"}
                            maxHeightClass={""}
                            inputClasses={""}
                            autoFocus={false}
                            onChange={this.onTriggerDescriptionChanged}
                            required={true}
                            id={"alert-description"}
                            disabled={this.props.viewOnly}
                        />
                    </div>

                    {/** Display triggers by selection */}
                    <div role="presentation" aria-label="add sternum alert modal additional fields">
                        {this.loadTriggerDefinition()}
                    </div>
                </div>

                {/* Trigger duplication Error */}
                {this.state.isDuplicateError ? duplicationError : " "}

                {/* Server Error */}
                {this.props.isError ? serverError : " "}

                <DialogActions role="presentation" aria-label="buttons container">
                    <Button
                        variant="contained"
                        color="default"
                        onClick={this.handleCloseClick}
                        disabled={
                            (this.state.displayLoadingBarOnAddAnother || this.state.displayLoadingBarOnSave) &&
                            !this.props.isError
                        }
                    >
                        Close
                    </Button>
                    {!this.props.viewOnly && (
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={
                                (this.props.viewOnly ||
                                    this.fieldsValidation() ||
                                    this.state.displayLoadingBarOnAddAnother ||
                                    this.state.displayLoadingBarOnSave) &&
                                !this.props.isError
                            }
                            onClick={() => this.onSaveClick(false)}
                        >
                            {this.props.existingTriggerDefinition ? "Update" : "Save"}
                            {/* Loading */}
                            {this.state.displayLoadingBarOnSave && !this.props.isError && (
                                <CircularProgress size={20} color="inherit" />
                            )}
                        </Button>
                    )}
                    {!this.props.viewOnly && (
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={
                                (this.props.viewOnly ||
                                    this.fieldsValidation() ||
                                    this.state.displayLoadingBarOnAddAnother ||
                                    this.state.displayLoadingBarOnSave) &&
                                !this.props.isError
                            }
                            onClick={() => this.onSaveClick(true)}
                        >
                            {"Add Another"}
                            {/* Loading */}
                            {this.state.displayLoadingBarOnAddAnother && !this.props.isError && (
                                <CircularProgress size={20} color="inherit" />
                            )}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(triggerDefinitionSimpleDialogStyle)(TriggerDefinitionSimpleDialog));
