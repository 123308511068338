import { CurrentlyViewedTraceIdReducerActions } from "../currentlyViewedTraceId/CurrentlyViewedTraceIdReducerActions";
import ActionType from "../ActionType";

/**
 * Reducer for the currently viewed issue in trace.
 */
const currentlyViewedIssueIdReducer = (state: string, action: CurrentlyViewedTraceIdReducerActions) => {
    switch (action.type) {
        case ActionType.ViewTrace:
            return action.issueId;

        case ActionType.ViewGeneratedEvent:
            return action.issueId;

        case ActionType.TraceNotViewed:
            return null;

        default:
            return state;
    }
};

export default currentlyViewedIssueIdReducer;
