import { Theme } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

let userInformationUpdateComponentStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: {
            marginRight: "70px",
        },
        valueInputBox: {
            fontSize: "0.875rem",
        },
        keyIcon: {
            fontSize: theme.spacing(3),
            marginRight: theme.spacing(1),
        },
        nameColumn: {
            width: "7%",
        },
        chevronRightIcon: {
            fontSize: theme.spacing(3),
        },
        flexContainer: {
            display: "flex",
            flexDirection: "row",
            gap: "30px",
        },
        saveButtonContainer: {
            width: "102px",
            height: "45px",
        },
        labelColor: {
            color: "#999999",
        },
    });

export default userInformationUpdateComponentStyle;
