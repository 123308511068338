import { makeStyles } from "@material-ui/core";

export const useResolvingReasonsStyle = makeStyles((theme) => ({
    resolvingReasonsContainer: {
        display: "flex",
        alignItems: "center",
        gap: 12,
        flexWrap: "wrap",
    },

    resolvingReasonsContainerDisplayView: {
        "&&": {
            margin: "12px 0 0 0",
        },
    },

    resolvingReasonsItem: {
        position: "relative",
        color: "#1B6FDE",
        fontSize: 14,
        padding: "8px 12px",
        border: "1px dashed #1B6FDE",
        borderRadius: 8,
        boxSizing: "border-box",
        whiteSpace: "nowrap",

        "&[aria-selected=true]": {
            background: "#e4eefb",
            border: "1px solid #e4eefb",

            "& $checkIcon": {
                display: "block",
            },
        },

        "&[data-on-click=true]": {
            cursor: "pointer",
        },
    },

    checkIcon: {
        display: "none",
        position: "absolute",
        top: 0,
        right: 0,
        transform: "translate(45%, -50%)",
        width: 16,
        height: 16,
        color: "#1B6FDE",
    },

    deleteIcon: {
        display: "none",
        position: "absolute",
        top: 0,
        right: 0,
        transform: "translate(45%, -50%)",
        width: 18,
        height: 18,
        color: "white",
        background: "#ACB4BD",
        borderRadius: 100,
        cursor: "pointer",
    },

    resolvingReasonsCustomItem: {
        position: "relative",
        color: "#1B6FDE",
        fontSize: 14,
        padding: "8px 12px",
        borderRadius: 8,
        background: "#e4eefb",
        border: "1px solid #e4eefb",
        boxSizing: "border-box",
        whiteSpace: "nowrap",

        "&:hover": {
            "&[aria-selected=false]": {
                "& $deleteIcon": {
                    display: "block",
                },
            },
        },

        "&[aria-selected=true]": {
            background: "#e4eefb",
            border: "1px solid #e4eefb",

            "& $checkIcon": {
                display: "block",
            },
        },

        "&[data-on-click=true]": {
            cursor: "pointer",
        },
    },

    resolvingReasonsAddItem: {
        position: "relative",
        color: "#1B6FDE",
        fontSize: 14,
        padding: "8px 12px",
        border: "1px dashed #1B6FDE",
        borderRadius: 8,
        boxSizing: "border-box",
        whiteSpace: "nowrap",
        cursor: "pointer",
    },

    addInput: {
        display: "block",
        color: "#1B6FDE",
        minWidth: 1,

        "&[contenteditable=true]:empty:focus:before": {
            color: "gray",
        },

        "&[contenteditable=true]:empty:before": {
            content: "attr(placeholder)",
            color: "#1B6FDE",
        },

        /* it prevents the user from being able to make a (visible) newline */
        "&[contenteditable=true]": {
            "& br": {
                display: "none",
            },

            "& div": {
                display: "inline",
            },
        },

        "&:focus": {
            outline: "none",
        },
    },
}));
