import Popover from "@material-ui/core/Popover";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import ColorConfiguration from "./ColorConfiguration";
import colorPickerStyle from "./ColorPickerStyle";
import { getColorOrder } from "./getColorOrder";
import { sternumColorMap } from "./sternumColorMap";
import SternumColorType from "./SternumColorType";

interface AppState {
    anchorElement?;
}

export interface AppProps extends WithStyles<typeof colorPickerStyle> {
    color: SternumColorType;
    onChange: (color: SternumColorType) => void;
    disabled?: boolean;

    theme?;
}

class ColorPicker extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);

        this.state = {};
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        const allSternumColorTypes = getColorOrder();

        return (
            <div>
                <Popover
                    id="explanation-popover"
                    open={!!this.state.anchorElement}
                    anchorEl={this.state.anchorElement}
                    onClose={() => {
                        this.setState({
                            anchorElement: undefined,
                        });
                    }}
                    elevation={10}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                >
                    <div className={classNames(classes.colorContainer)}>
                        {allSternumColorTypes.map((sternumColorType, index) => {
                            const currentConfiguration = sternumColorMap[sternumColorType];
                            return (
                                <div
                                    key={sternumColorType}
                                    style={{
                                        backgroundColor: currentConfiguration.main,
                                    }}
                                    className={classNames(
                                        classes.colorBox,
                                        allSternumColorTypes.length !== index + 1 && "mod-margin",
                                        "mod-hover",
                                        this.props.color === sternumColorType && "mod-selected"
                                    )}
                                    onClick={() => this.onColorSelected(currentConfiguration)}
                                />
                            );
                        })}
                    </div>
                </Popover>

                <div
                    className={classNames(classes.colorBox)}
                    style={{
                        backgroundColor: sternumColorMap[this.props.color].main,
                    }}
                    onClick={
                        this.props.disabled
                            ? undefined
                            : (event) => this.onSelectedColorClicked(event as React.MouseEvent<HTMLDivElement>)
                    }
                />
            </div>
        );
    }

    private onColorSelected(currentConfiguration: ColorConfiguration) {
        this.setState(
            {
                anchorElement: null,
            },
            () => {
                this.props.onChange(currentConfiguration.colorType);
            }
        );
    }

    private onSelectedColorClicked(event: React.MouseEvent<HTMLDivElement>) {
        this.setState({
            anchorElement: event.currentTarget,
        });
    }
}

export default withStyles(colorPickerStyle, { withTheme: true })(ColorPicker);
