import { Theme } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import createStyles from "@material-ui/core/styles/createStyles";
import { sternumTheme } from "../App";
import commonStyle from "../CommonStyle";

let sternumQueryEditorStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        titleTypography: {
            display: "flex",
            alignItems: "center",
            marginBottom: theme.spacing(2),
        },
        removeLinkText: {
            textDecoration: "underline",
            cursor: "pointer",
        },
        resetAndApplyButtons: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(4),
        },
        applyButton: {
            marginRight: theme.spacing(2),
        },
        formControl: {
            minWidth: theme.spacing(40),
            maxWidth: theme.spacing(80),
        },
        ipAddressFilter: {
            marginRight: theme.spacing(4),
        },
        fromTimePicker: {
            marginRight: theme.spacing(4),
        },
        filterRow: {
            marginTop: theme.spacing(4),
        },

        paperContainer: {
            borderRadius: 20,
            boxShadow: "0px 0px 23px rgba(211, 212, 214, 0.25)",

            "& .MuiMenu-list": {},
        },

        menuContainer: {
            padding: "10px 0",
        },

        menuItemContainer: {
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            padding: theme.spacing(0, 7, 0, 5),
            height: 40,

            "&:hover": {
                background: "#FFF1F5",
            },
        },

        selectComponent: {
            minWidth: "190px",
        },
        popoverPaper: {
            minWidth: theme.spacing(25),
        },
        filterContainer: {
            display: "flex",

            "&.mod-row": {
                alignItems: "center",
                flexDirection: "row",
            },
            "&.mod-column": {
                flexDirection: "column",
            },
        },
        optionRow: {
            cursor: "pointer",
            "&:hover": {
                backgroundColor: sternumTheme.lightGrey.main,
            },
            "&:hover .optionText": {
                fontWeight: 500,
            },
        },
        valueInputBox: {
            fontSize: "0.8125rem",
            fontWeight: theme.typography.fontWeightRegular,
        },
        allOrAnySelectorText: {
            fontFamily: theme.typography.fontFamily,
            fontWeight: 500,
            fontSize: "0.8125rem",
            cursor: "pointer",
            textDecoration: "none",
            color: theme.palette.common.black,
            "&:hover": {
                textDecoration: "underline",
            },

            "&.mod-disabled": {
                cursor: "default",

                "&:hover": {
                    textDecoration: "none",
                },
            },
        },
        dropdownChevron: {
            fontSize: theme.spacing(2),
        },
        deleteIcon: {
            cursor: "pointer",
            fontSize: theme.spacing(3),
            color: grey[500],
        },
        removeIcon: {
            cursor: "pointer",
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1),
        },
    });

export default sternumQueryEditorStyle;
