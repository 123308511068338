import ReceivedDefinitionSummary from "./ReceivedDefinitionSummary";

/**
 * Response of the get top hitting triggers command.
 */
class ReceivedDefinitionsResponse {
    /**
     * Constructor.
     */
    constructor(
        public receivedDefinitions: ReceivedDefinitionSummary[],
        public receivedArguments: ReceivedDefinitionSummary[]
    ) {}

    /**
     * Creates the response instance from the given json response.
     */
    public static fromJson(jsonObject: Object): ReceivedDefinitionsResponse {
        return new ReceivedDefinitionsResponse(
            jsonObject["received_definitions"].map((receivedDefinitionJson) =>
                ReceivedDefinitionSummary.fromJsonObject(receivedDefinitionJson)
            ),
            jsonObject["received_argument_definitions"].map((receivedDefinitionJson) =>
                ReceivedDefinitionSummary.fromJsonObject(receivedDefinitionJson)
            )
        );
    }
}

export default ReceivedDefinitionsResponse;
