import React, { MouseEvent } from "react";
import classNames from "classnames";

import { ArrowDown2Icon, ArrowUp2Icon } from "../../../../SUI/SternumIcon";

import { DeviceMetricTileDirection } from "./DeviceMetricTile.types";

import { useDeviceMetricsTileStyles } from "./DeviceMetricTile.styles";

export interface DeviceMetricTileProps {
    title?: React.ReactNode;
    icon?: React.ReactNode;
    value?: React.ReactNode;
    percentageValue?: React.ReactNode;
    arrowDirection?: DeviceMetricTileDirection;
    onClick?: (e: MouseEvent<HTMLDivElement>) => unknown;
}

export function DeviceMetricTile({
    title,
    icon,
    value,
    percentageValue,
    arrowDirection,
    onClick,
}: DeviceMetricTileProps) {
    const classes = useDeviceMetricsTileStyles();

    const percentageClassName = {
        [DeviceMetricTileDirection.Down]: classes.percentageValueContainerRed,
        [DeviceMetricTileDirection.Up]: classes.percentageValueContainerGreen,
    }[arrowDirection];

    return (
        <div
            className={classNames(classes.container, onClick && classes.containerWithHover)}
            role="presentation"
            aria-label="metrics tile card"
            onClick={onClick}
        >
            <div className={classes.icon}>{icon}</div>
            <div className={classNames(classes.title, icon && classes.titleWithIcon)}>{title}</div>
            <div className={classes.flex}>
                <div className={classes.value}>{value}</div>
                <div className={classNames(classes.percentageValueContainer, percentageClassName, classes.flex)}>
                    {arrowDirection === DeviceMetricTileDirection.Down && <ArrowDown2Icon />}
                    {arrowDirection === DeviceMetricTileDirection.Up && <ArrowUp2Icon />}
                    <div>{percentageValue}</div>
                </div>
            </div>
        </div>
    );
}
