import { makeStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";

export const useChipStyle = makeStyles((theme: Theme) => ({
    chip: {
        padding: "2px 8px",
        fontSize: 14,
        fontWeight: 500,
        borderRadius: 10,
    },

    chipTypeBlue: {
        background: "rgba(79, 152, 247, 0.2)",
        color: "#93B4F5",
    },

    chipTypeYellow: {
        background: "rgba(255, 179, 0, 0.2)",
        color: "#FFB300",
    },
}));
