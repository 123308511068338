import { call, put, select, takeEvery } from "redux-saga/effects";
import FetchError from "../../state/FetchError";
import ActionType from "../ActionType";
import ServiceWire from "../../services/ServiceWire";
import IssueInfo from "../../state/IssueInfo";
import {
    PutIssueStatusAction,
    putIssueStatusErrorAction,
    putIssueStatusSuccessfullyAction,
} from "./PutIssueStatusAction";
import {
    GetIssueResolveReasonsAction,
    getIssueResolveReasonsErrorAction,
    getIssueResolveReasonsInProgressAction,
    getIssueResolveReasonsSuccessfullyAction,
} from "./GetIssueResolveReasonsAction";
import { IssuesResolveItem } from "../../state/IssueInfoType";
import { Status } from "../../state/GlobalTypes";
import { GlobalState } from "../../state/GlobalState";

export function* putIssueStatusAsync(action: PutIssueStatusAction) {
    try {
        yield call(
            [ServiceWire.getSternumService(), ServiceWire.getSternumService().putIssueStatus],
            action.issueId,
            action.status,
            action.reasons
        );

        let updatedIssue: IssueInfo | undefined;

        try {
            updatedIssue = yield call(
                [ServiceWire.getSternumService(), ServiceWire.getSternumService().getIssueById],
                action.issueId
            );
        } catch (e) {
            updatedIssue = undefined;
        }

        yield put(putIssueStatusSuccessfullyAction({ updatedFields: action, updatedIssue }));
        action.onDone?.();
    } catch (e) {
        yield put(
            putIssueStatusErrorAction({
                issueId: action.issueId,
                status: action.status,
                error: new FetchError(e?.message),
            })
        );
    }
}

export function* getIssueResolveReasonsAsync(action: GetIssueResolveReasonsAction) {
    const state: GlobalState = yield select();

    if ([Status.Success, Status.InProgress].includes(state.issues.issueResolveReasons.status)) {
        yield put(getIssueResolveReasonsSuccessfullyAction({ reasons: state.issues.issueResolveReasons.data }));
        return;
    }

    yield put(getIssueResolveReasonsInProgressAction());

    try {
        const reasons: IssuesResolveItem[] = yield call([
            ServiceWire.getSternumService(),
            ServiceWire.getSternumService().getIssuesResolveReasons,
        ]);

        yield put(getIssueResolveReasonsSuccessfullyAction({ reasons }));
    } catch (e) {
        yield put(getIssueResolveReasonsErrorAction());
    }
}

/**
 * Watching the ActionType.ResolveIssue action.
 */
export function* watchIssueAsync() {
    yield takeEvery((action) => action.type === ActionType.PutIssueStatus, putIssueStatusAsync);
    yield takeEvery((action) => action.type === ActionType.GetIssueResolveReasons, getIssueResolveReasonsAsync);
}
