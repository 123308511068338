import React from "react";
import classNames from "classnames";

import { DeviceTileItem } from "./DeviceTileItem";
import { DeviceTileAddItem } from "./DeviceTileAddItem";
import { useDeviceTilesStyle } from "./DeviceTiles.style";

export interface DeviceTilesProps extends React.ComponentProps<"div"> {}

export function DeviceTiles({ className, children, ...props }: DeviceTilesProps) {
    const classes = useDeviceTilesStyle();

    return (
        <div className={classNames(classes.deviceTilesContainer, className)} {...props}>
            {children}
        </div>
    );
}

DeviceTiles.Item = DeviceTileItem;
DeviceTiles.AddItem = DeviceTileAddItem;
