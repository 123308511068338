import { Action } from "redux";
import { NotificationMessage, NotificationVariant } from "../../state/NotificationsState";
import ActionType from "../ActionType";

/**
 * Defines the data for the show notification action.
 */
export interface ShowNotificationAction extends Action<ActionType> {
    type: ActionType.ShowNotification;
    message: NotificationMessage;
    variant?: NotificationVariant;
}

export function showNotificationAction(
    message: NotificationMessage,
    variant?: NotificationVariant
): ShowNotificationAction {
    return {
        type: ActionType.ShowNotification,
        message,
        variant,
    };
}
