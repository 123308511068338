import React, { useMemo, useState } from "react";
import classNames from "classnames";
import moment from "moment";
import { Typography } from "@material-ui/core";

import HashSet from "../../../../../lib/infra/HashSet";
import SternumTableToolbar from "../../../../SUI/SternumTable/SternumTableToolbar/SternumTableToolbar";
import SternumConfiguration from "../../../../../lib/infra/SternumConfiguration";

import { useDebounceState } from "../../../../../hooks";
import SternumTable from "../../../../SUI/SternumTable/SternumTable";
import { useCommonStyle } from "../../../../CommonStyle";
import TableRowData from "../../../../../lib/state/TableRowData";
import { LinuxKernelModule } from "../../../../../lib/state/LinuxAllKernelModules";
import TableColumnHeaderInfo from "../../../../../lib/state/TableColumnHeaderInfo";

import { useKernelModulesData } from "./KernelModules.hook";
import { useKernelModulesStyles } from "./KernelModules.styles";

export interface KernelModulesProps {
    deviceId: string;
    startDate: Date;
    endDate: Date;
}

export function KernelModules({ deviceId, startDate, endDate }: KernelModulesProps) {
    const classes = useKernelModulesStyles();
    const classesCommon = useCommonStyle();

    const [searchText, setSearchText] = useDebounceState("");
    const [pageNumber, setPageNumber] = useState(1);

    const { isLoading, isError, allKernelModules } = useKernelModulesData({ deviceId, searchText, startDate, endDate });

    const viewedColumns = ["firstAppeared", "name"];

    const rows: TableRowData[] = useMemo(
        () =>
            allKernelModules?.map(
                (linuxModule): TableRowData => ({
                    getIdentifier(): string {
                        return linuxModule.module;
                    },
                    getColumnValue(columnHeaderId: keyof LinuxKernelModule): any {
                        return linuxModule[columnHeaderId];
                    },
                    isDifferentFrom(other: TableRowData): boolean {
                        return (
                            other.getColumnValue("module") !== linuxModule.module ||
                            other.getColumnValue("firstSeen") !== linuxModule.firstSeen
                        );
                    },
                })
            ) || [],
        [allKernelModules]
    );

    const columnHeaders: TableColumnHeaderInfo[] = [
        new TableColumnHeaderInfo("firstAppeared", "First Appeared", false, true, false, false),
        new TableColumnHeaderInfo("name", "Name", false, true, false, false),
    ];

    const renderRowDisplayValues = (row: TableRowData) => {
        const module: string = row.getColumnValue("module");
        const firstSeen: Date = row.getColumnValue("firstSeen");

        return {
            firstAppeared: <div className={classes.rowCell}>{moment(firstSeen).format("DD/MM/YY HH:mm:ss")}</div>,
            name: <div className={classNames(classes.rowCell, classesCommon.textEllipsis)}>{module}</div>,
        };
    };

    return (
        <div className={classes.kernelModulesContainer}>
            <SternumTableToolbar
                toolbarState={{
                    showPremium: false,
                    displaySearch: true,
                    displayRefresh: false,
                    displayFilters: false,
                    displayColumnOptions: false,
                    searchDisplayString: "Search Kernel Module",
                }}
                hidePagination={true}
                disabled={false}
                listFilter={null}
                viewedColumnsSet={HashSet.fromValues([])}
                totalItemCount={SternumConfiguration.getPageSize()}
                isFilterActive={false}
                columnHeaders={[]}
                pageNumber={pageNumber}
                pageSize={SternumConfiguration.getPageSize()}
                onViewedColumnsChanged={() => null}
                onSearchTextChanged={(searchText) => setSearchText(searchText)}
                onPageChanged={(pageNumber) => setPageNumber(pageNumber)}
                onRefreshClicked={() => null}
            />
            <SternumTable
                classNameInner={{
                    tableWrapper: classes.tableWrapper,
                }}
                emptyComponent={
                    <div className={classNames(classesCommon.flexCenter, classesCommon.padding)}>
                        <Typography variant="body2">Modules will appear here.</Typography>
                    </div>
                }
                viewedColumnsSet={HashSet.fromValues(viewedColumns)}
                pageSize={SternumConfiguration.getPageSize()}
                amountOfLoadingPlaceholders={5}
                totalItemCount={rows.length}
                loadingItems={isLoading}
                errorLoadingItems={isError}
                columnHeaders={columnHeaders}
                rows={rows}
                getRowValues={(row) => renderRowDisplayValues(row)}
                nonClickableRows
                displayLoadingIcon
                hideUpperBorder
                hideToolbar
                wrapColumns
            />
        </div>
    );
}
