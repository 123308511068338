import EntityBase from "./EntityBase";
import EntityType from "./EntityType";
import PermissionInfo from "./PermissionInfo";
import TableRowData from "./TableRowData";

class RoleInfo extends EntityBase implements TableRowData {
    /**
     * Constructor.
     */
    constructor(
        public roleId: string,
        public created: number,
        public updated: number,
        public permissions: PermissionInfo[],
        public roleName: string
    ) {
        super(roleId, created, updated, EntityType.Role);
    }

    public static fromJsonObject(jsonObject: Object) {
        return new RoleInfo(
            jsonObject["entity_id"],
            jsonObject["created"],
            jsonObject["updated"],
            jsonObject["permissions"].map((permission) => PermissionInfo.fromJsonObject(permission)),
            jsonObject["role_name"]
        );
    }

    /**
     * Returns whether given row is any different in details than current issue info.
     * Shallow comparison, in other words.
     * @param other The row to compare with.
     */
    public isDifferentFrom(other: TableRowData): boolean {
        // Row must of of type issue info.
        if (!(other instanceof RoleInfo)) {
            return true;
        }

        let otherRoleInfo = other as RoleInfo;

        // Comparing base properties.
        if (
            otherRoleInfo.entityId !== this.entityId ||
            otherRoleInfo.created !== this.created ||
            otherRoleInfo.updated !== this.updated
        ) {
            return true;
        }

        return false;
    }

    /**
     * Gets the value for the given column id.
     */
    public getColumnValue(columnHeaderId: string): any {
        return this[columnHeaderId];
    }
}

export default RoleInfo;
