import React, { useMemo, useState } from "react";

import { KernelLogsDisplay, KernelLogsMessagesType } from "../../../../KernelLogsDisplay";

import HashSet from "../../../../../lib/infra/HashSet";
import SternumTableToolbar from "../../../../SUI/SternumTable/SternumTableToolbar/SternumTableToolbar";
import SternumDeviceEventsFilter from "../../../../../lib/state/SternumDeviceEventsFilter";
import SternumConfiguration from "../../../../../lib/infra/SternumConfiguration";

import { useKernelLogsData } from "./KernelLogs.hook";
import { useKernelLogsStyles } from "./KernelLogs.styles";
import { useDebounceState } from "../../../../../hooks";

export interface KernelLogsProps {
    deviceId: string;
    startDate: Date;
    endDate: Date;
}

export function KernelLogs({ deviceId, startDate, endDate }: KernelLogsProps) {
    const classes = useKernelLogsStyles();
    const [searchText, setSearchText] = useDebounceState("");
    const [pageNumber, setPageNumber] = useState(1);

    const entitiesFilter = useMemo(() => {
        return new SternumDeviceEventsFilter(
            null,
            false,
            startDate?.getTime() || null,
            endDate?.getTime() || null,
            null,
            null,
            [], // this.state.filterByTraceCategories,
            false,
            null, // this.state.sternumQuery,
            null
        );
    }, [startDate?.getTime(), endDate?.getTime()]);

    const { isLoading, deviceEventInfo } = useKernelLogsData({ deviceId, entitiesFilter });

    if (isLoading) {
        return <div />;
    }

    return (
        <div className={classes.kernelLogsContainer}>
            <SternumTableToolbar
                toolbarState={{
                    showPremium: false,
                    displaySearch: true,
                    displayRefresh: false,
                    displayFilters: false,
                    displayColumnOptions: false,
                }}
                hidePagination={true}
                disabled={false}
                listFilter={null}
                viewedColumnsSet={HashSet.fromValues([])}
                totalItemCount={SternumConfiguration.getPageSize()}
                isFilterActive={false}
                columnHeaders={[]}
                pageNumber={pageNumber}
                pageSize={SternumConfiguration.getPageSize()}
                onViewedColumnsChanged={() => null}
                onSearchTextChanged={(searchText) => setSearchText(searchText)}
                onPageChanged={(pageNumber) => setPageNumber(pageNumber)}
                onRefreshClicked={() => null}
            />
            <KernelLogsDisplay
                className={classes.kernelLogsDisplay}
                bootEvent={deviceEventInfo}
                entitiesFilter={entitiesFilter}
                searchText={searchText}
                kernelLogsMessagesType={KernelLogsMessagesType.All}
                isInfinityScrollOn
            />
        </div>
    );
}
