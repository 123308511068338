import Cloneable from "../infra/Cloneable";
import HashMap from "../infra/HashMap";
import SternumConfiguration from "../infra/SternumConfiguration";
import Utils from "../infra/Utils";
import ConditionType from "./ConditionType";
import SternumFilterValue from "./SternumFilterValue";

/**
 * Represents a filter in Sternum.
 */
class SternumFilter implements Cloneable<SternumFilter> {
    /**
     * Constructor.
     */
    constructor(
        public filterComponentIdentifier: number,
        public fieldApiName: string,
        public conditionApiName: string,
        public valuesMap: HashMap<SternumFilterValue>,
        public doesNotRequireValue?: boolean,
        public isSpecialField?: boolean,
        public displayName?: string
    ) {}

    /**
     * Clones the sternum filter.
     */
    public clone(): SternumFilter {
        let clonedValuesMap: HashMap<SternumFilterValue> = new HashMap<SternumFilterValue>();
        let originalValuesMap = this.valuesMap.getMap();

        for (let key in originalValuesMap) {
            if (originalValuesMap.hasOwnProperty(key)) {
                if (originalValuesMap[key]) {
                    clonedValuesMap.put(key, originalValuesMap[key].clone());
                }
            }
        }

        return new SternumFilter(
            this.filterComponentIdentifier,
            this.fieldApiName,
            this.conditionApiName,
            clonedValuesMap,
            this.doesNotRequireValue,
            this.isSpecialField,
            this.displayName
        );
    }

    public isDifferentFrom(otherFilter: SternumFilter): boolean {
        if (!otherFilter) {
            return true;
        }

        if (this.fieldApiName !== otherFilter.fieldApiName) {
            return true;
        }

        if (this.filterComponentIdentifier !== otherFilter.filterComponentIdentifier) {
            return true;
        }

        if (this.conditionApiName !== otherFilter.conditionApiName) {
            return true;
        }

        if (this.doesNotRequireValue !== otherFilter.doesNotRequireValue) {
            return true;
        }

        if (this.isSpecialField !== otherFilter.isSpecialField) {
            return true;
        }

        return false;
    }

    /**
     * Returns whether filter is empty.
     */
    public isEmpty(): boolean {
        const hasValue = this.doesNotRequireValue || (this.valuesMap && this.anyNonNullValues());
        return !this.fieldApiName || !this.conditionApiName || !hasValue;
    }

    /**
     * Returns whether filter has any non null values.
     */
    private anyNonNullValues(): boolean {
        let values = this.valuesMap.values();

        if (values.length) {
            for (let j = 0; j < values.length; j++) {
                let valueObject = values[j];

                if (!Utils.isNullOrUndefined(valueObject?.value) && valueObject.value !== "") {
                    return true;
                }
            }
        }

        return false;
    }

    /**
     * Returns an empty filter.
     */
    public static getEmptyFilter(filterComponentIdentifier: number): SternumFilter {
        return new SternumFilter(filterComponentIdentifier, null, "EQUALS", new HashMap<SternumFilterValue>(), false);
    }

    /**
     * Creates the filter from given server json object.
     */
    public static fromJsonObject(filterComponentIdentifier: number, jsonObject: Object): SternumFilter {
        let filterCondition: ConditionType = ConditionType.EQUALS;

        switch (jsonObject["filter_condition"]) {
            case "EQUALS":
                filterCondition = ConditionType.EQUALS;
                break;
            case "NOT_EQUALS":
                filterCondition = ConditionType.NOT_EQUALS;
                break;
            case "GREATER_THAN":
                filterCondition = ConditionType.GREATER_THAN;
                break;
            case "GREATER_THAN_OR_EQUALS":
                filterCondition = ConditionType.GREATER_THAN_OR_EQUALS;
                break;
            case "LESS_THAN":
                filterCondition = ConditionType.LESS_THAN;
                break;
            case "LESS_THAN_OR_EQUALS":
                filterCondition = ConditionType.LESS_THAN_OR_EQUALS;
                break;
            case "CONTAINS":
                filterCondition = ConditionType.CONTAINS;
                break;
            case "NOT_CONTAINS":
                filterCondition = ConditionType.NOT_CONTAINS;
                break;
            case "IS_EMPTY":
                filterCondition = ConditionType.IS_EMPTY;
                break;
            case "IS_NOT_EMPTY":
                filterCondition = ConditionType.IS_NOT_EMPTY;
                break;
            case "MATCH":
                filterCondition = ConditionType.MATCH;
                break;
        }

        const conditionConfiguration = SternumConfiguration.getCondition(filterCondition);

        // Validate condition type contains required values
        const valuesMap =
            conditionConfiguration.requiredValues.length > 0
                ? HashMap.fromPair(
                      SternumConfiguration.getCondition(filterCondition).requiredValues[0].apiName,
                      new SternumFilterValue(jsonObject["value"], jsonObject["display_value"])
                  )
                : HashMap.fromPair(filterCondition, null);

        return new SternumFilter(
            filterComponentIdentifier,
            jsonObject["argument_definition_id"],
            jsonObject["filter_condition"],
            valuesMap,
            conditionConfiguration.doesNotRequireValue,
            jsonObject["is_special_field"],
            jsonObject["display_name"]
        );
    }

    /**
     * Gets the API JSON for the filter.
     */
    public getServerJson(): Object {
        let currentFilterJson: Object = {};
        currentFilterJson["argument_definition_id"] = this.fieldApiName;
        currentFilterJson["filter_condition"] = this.conditionApiName;
        currentFilterJson["value"] = undefined;

        if (this.doesNotRequireValue) {
            currentFilterJson["value"] = true;
        } else if (this.valuesMap && this.valuesMap.values() && this.valuesMap.values().length) {
            currentFilterJson["value"] = this.valuesMap.values()[0].value;
        }

        return currentFilterJson;
    }
}

export default SternumFilter;
