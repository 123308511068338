import { makeStyles } from "@material-ui/core";
import commonStyle from "../CommonStyle";

export const useStyles = makeStyles((theme) => {
    return {
        container: {
            height: "100%",
            maxWidth: 1123,
            margin: "0 auto",
        },

        title: {
            fontSize: "24px",
            fontWeight: 500,
            textAlign: "center",
        },

        description: {
            marginTop: "24px",
            color: "#676E75",
            fontSize: "14px",
            lineHeight: "22px",
            textAlign: "justify",
        },

        footer: {
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "30px",
        },
        contents: {
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: "14px",
            "& ol": {
                paddingLeft: 0,
            },
            "& li > p": {
                fontWeight: "normal",
            },
            "& > ol": {
                counterReset: "one",
            },
            "& > ol > ol": {
                counterReset: "two",
                paddingLeft: "30px",
            },
            "& > ol > ol > ol": {
                counterReset: "three",
                paddingLeft: "30px",
            },
            "& ol > li": {
                listStyleType: "none",
                marginBottom: "0.6em",
            },
            "& > ol > li": {
                counterIncrement: "one",
                fontWeight: "bold",
            },
            "& > ol > li::before": {
                display: "inline-block",
                content: 'counters(one, ".") "."',
                marginRight: "5px",
            },
            "& > ol > ol > li": {
                counterIncrement: "two",
            },
            "& > ol > ol > li::before": {
                display: "inline-block",
                content: 'counters(one, ".") "." counters(two, ".") "."',
                marginRight: "5px",
            },
            "& > ol > ol > ol > li": {
                counterIncrement: "three",
            },
            "& > ol > ol > ol > li::before": {
                display: "inline-block",
                content: 'counters(one, ".") "." counters(two, ".") "."  counters(three, ".") "."',
                marginRight: "5px",
            },
        },
    };
});
