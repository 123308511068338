import { WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import HashSet from "../../lib/infra/HashSet";
import SternumConfiguration from "../../lib/infra/SternumConfiguration";
import { GlobalState } from "../../lib/state/GlobalState";
import { IssueInfoStatus } from "../../lib/state/IssueInfoType";
import IssuesFilter from "../../lib/state/IssuesFilter";
import IssuesList from "../IssuesList/IssuesList";
import SternumTab from "../SUI/SternumTabs/SternumTab";
import SternumTabs from "../SUI/SternumTabs/SternumTabs";
import issuesPageStyle from "./IssuesPageStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    selectedTab: SelectedTab;
}

enum SelectedTab {
    OPEN,
    RESOLVED,
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof issuesPageStyle> {
    sidebarOpen: boolean;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {};
};

/**
 * Holds the devices list in the app.
 */
class IssuesPage extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            selectedTab: SelectedTab.OPEN,
        };
    }

    private handleIssueTabChange = (selectedTab: SelectedTab) => {
        this.setState({ selectedTab });
    };

    private getIssuesStatusesToShow = (): IssueInfoStatus[] => {
        return this.state.selectedTab === SelectedTab.RESOLVED
            ? [IssueInfoStatus.Resolved, IssueInfoStatus.Dismissed]
            : [IssueInfoStatus.Open];
    };

    private getViewedColumnsSet = () => {
        let columns = [
            "alertName",
            "traceCategory",
            "traceEventType",
            "created",
            "deviceName",
            "firmwareVersion",
            "state",
            "receivedDeviceId",
            "prevented",
        ];

        if (this.state.selectedTab === SelectedTab.RESOLVED) {
            columns.push("resolver");
        } else {
            columns.push("actions");
        }

        return HashSet.fromValues(columns);
    };

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.container}>
                <div>
                    <div className={classNames(classes.flexVMiddle)}>
                        <Typography
                            variant="h6"
                            className={classNames(classes.issuesTitle, "mod-no-padding", classes.marginRight)}
                        >
                            All Alerts
                        </Typography>
                    </div>
                </div>

                <SternumTabs
                    value={this.state.selectedTab}
                    // @ts-ignore
                    onChange={(e, value) => this.handleIssueTabChange(value as SelectedTab)}
                    className={classes.issueTabsContainer}
                    aria-label="sternum tabs"
                >
                    <SternumTab label="Open" value={SelectedTab.OPEN} />
                    <SternumTab label="Resolved" value={SelectedTab.RESOLVED} />
                </SternumTabs>

                <div className={classes.tableContainer}>
                    <div className={classes.tableInner}>
                        <IssuesList
                            key={this.state.selectedTab}
                            viewedColumnsSet={this.getViewedColumnsSet()}
                            enablePolling={true}
                            issuesFilter={
                                new IssuesFilter(
                                    null,
                                    null,
                                    true,
                                    // this.state.selectedTab !== SelectedTab.RESOLVED,
                                    true,
                                    this.getIssuesStatusesToShow(),
                                    undefined,
                                    undefined,
                                    true
                                )
                            }
                            displayXButtonInTraceView={true}
                            displayBackButton={false}
                            hideUpperBorder={true}
                            forceRefresh={false}
                            pageSize={SternumConfiguration.getPageSize()}
                            deviceProfileStatus="PRODUCTION"
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(issuesPageStyle)(IssuesPage)));
