/**
 * Represents configuration details for an argument role type.
 */
import TraceInfo from "../state/TraceInfo";

class ArgumentRoleTypeUIConfiguration {
    /**
     * Constructor.
     */
    constructor(
        public argumentRoleType: string,
        public order: number,
        public getDisplayName: (traceInfo: TraceInfo) => string,
        public displayInArgumentsSummary: boolean,
        public extractValue?: (value: any) => any,
        public shouldBeDisplayedInTraceView?: (traceInfo: TraceInfo) => boolean
    ) {}
}

export default ArgumentRoleTypeUIConfiguration;
