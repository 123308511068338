import SternumMapDataResponse from "../../../components/SUI/SternumMap/SternumMapDataResponse";
import SternumMapIpResponse from "../../../components/SUI/SternumMap/SternumMapIpResponse";
import AnalyticsService from "../AnalyticsService";
import buildSternumApiUrl from "../buildSternumApiUrl";
import HttpService from "../HttpService";

class GeoIpApiService {
    constructor(private httpService: HttpService) {}

    @AnalyticsService.reportOnError("API:getCountriesAggregation")
    public async getCountriesAggregation(
        entityId: string,
        startDate: number,
        endDate: number
    ): Promise<SternumMapDataResponse[]> {
        const endpoint = `/${entityId}/countries`;

        const queryParams = {
            created_from: startDate,
            created_to: endDate,
        };
        const response = await this.httpService.get(buildSternumApiUrl(endpoint), queryParams);

        return Promise.resolve(response["entities"] as SternumMapDataResponse[]);
    }

    @AnalyticsService.reportOnError("API:getGeoDataByIpAddress")
    public async getGeoDataByIpAddress(ipAddress: string): Promise<SternumMapIpResponse[]> {
        const endpoint = `/get_geo_data`;

        const queryParams = {
            ip_address: ipAddress,
        };
        const response = await this.httpService.get(buildSternumApiUrl(endpoint), queryParams);

        return Promise.resolve(response["entities"] as SternumMapIpResponse[]);
    }
}

export default GeoIpApiService;
