import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
    Typography,
    withStyles,
} from "@material-ui/core";
import { WithStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import SternumUtils from "../../../lib/infra/SternumUtils";
import Utils from "../../../lib/infra/Utils";
import ArgumentDefinitionPartial from "../../../lib/state/ArgumentDefinitionPartial";
import { GlobalState } from "../../../lib/state/GlobalState";
import CustomInput from "../../../shared_components/CustomInput/CustomInput";
import argumentDefinitionSimpleDialogStyle from "./ArgumentDefinitionSimpleDialogStyle";

interface AppState {
    argumentDisplayName: string;
    argumentEventName: string;
    isDuplicateError: boolean;
    displayLoadingBarOnSave: boolean;
    displayLoadingBarOnAddAnother: boolean;
}

export interface AppProps extends WithStyles<typeof argumentDefinitionSimpleDialogStyle> {
    onClose: (
        name: string,
        eventType: string,
        keepDialogOpen: boolean,
        existingArgument?: ArgumentDefinitionPartial
    ) => void;
    onCancel: () => void;
    open: boolean;
    selectedArgument?: ArgumentDefinitionPartial;
    duplicationCheck: (argName: string) => boolean;
    isError?: boolean;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {};
};

///

class ArgumentDefinitionalSimpleDialog extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);
        // Initializing the state to default.
        if (this.props.selectedArgument) {
            this.state = {
                argumentDisplayName: this.props.selectedArgument.displayName,
                argumentEventName: this.props.selectedArgument.argumentEventName,
                isDuplicateError: false,
                displayLoadingBarOnSave: false,
                displayLoadingBarOnAddAnother: false,
            };
        } else {
            this.state = {
                argumentDisplayName: "",
                argumentEventName: "",
                isDuplicateError: false,
                displayLoadingBarOnSave: false,
                displayLoadingBarOnAddAnother: false,
            };
        }
    }

    /*
     * Validate fields are not empty
     */
    fieldsValidation = (): boolean => {
        return this.state.argumentDisplayName === "" || this.state.argumentEventName === "";
    };

    /*
     * Handle save operation and check if it's duplicate operation.
     */
    private onSaveClick = (keepDialogOpen: boolean) => {
        if (!this.checkDuplication()) {
            this.setState({ displayLoadingBarOnSave: !keepDialogOpen, displayLoadingBarOnAddAnother: keepDialogOpen });
            this.props.onClose(
                this.state.argumentDisplayName,
                this.state.argumentEventName,
                keepDialogOpen,
                this.props.selectedArgument
            );
            // Clear field to insert another trace
            if (keepDialogOpen) {
                this.clearFields();
            }
        }
    };

    private clearFields = () => {
        this.setState({
            argumentDisplayName: "",
            argumentEventName: "",
            isDuplicateError: false,
            displayLoadingBarOnSave: false,
            displayLoadingBarOnAddAnother: false,
        });
    };

    /*
     * Update name field and trace type
     */
    private onDisplayNameChanged = (event) => {
        const newValue = event.target.value;
        const argumentEventName: string = "ARG_ROLE_" + Utils.toUpperCaseWithUnderscore(newValue);

        this.setState({
            argumentDisplayName: newValue,
            argumentEventName: argumentEventName,
            isDuplicateError: false,
        });
    };

    /**
     * Handle close click
     */
    private handleCloseClick = () => {
        this.props.onCancel();
    };

    render() {
        const { classes } = this.props;

        const duplicationError = (
            <Typography variant={"subtitle2"} className={classNames(classes.marginBottomLarge)} color="error">
                Argument already exists!
            </Typography>
        );

        const serverError = (
            <Typography variant={"subtitle2"} className={classNames(classes.marginBottomLarge)} color="error">
                "Operation failed
            </Typography>
        );

        return (
            <Dialog
                onClose={() => {
                    this.props.selectedArgument ? this.handleCloseClick() : "";
                }}
                role="dialog"
                aria-label="add argument definition"
                aria-labelledby="simple-dialog-title"
                open={this.props.open}
            >
                <DialogTitle id="simple-dialog-title">
                    {this.props.selectedArgument ? "Edit" : "Add"} Argument Definition
                </DialogTitle>

                <div className={classNames(classes.flexColumn, classes.flexCenter, classes.flexVMiddle, classes.root)}>
                    {/* Display Name */}

                    <div className={classNames(classes.marginBottomLarge, classes.fullWidth)}>
                        <CustomInput
                            value={this.state.argumentDisplayName}
                            inputTitle={"Display Name"}
                            maxHeightClass={""}
                            inputClasses={""}
                            autoFocus={true}
                            onChange={this.onDisplayNameChanged}
                            required={true}
                            id={"display-name"}
                            maxLength={SternumUtils.getMaxInputFieldSize()}
                        />
                    </div>

                    {/* Display role type */}
                    <div className={classNames(classes.fullWidth, classes.marginBottomLarge)}>
                        <CustomInput
                            value={this.state.argumentEventName}
                            inputTitle={"Argument Role Type"}
                            maxHeightClass={""}
                            inputClasses={""}
                            autoFocus={true}
                            onChange={this.onDisplayNameChanged}
                            required={false}
                            id={"argument-role-type"}
                            disabled={true}
                        />
                    </div>

                    {this.state.isDuplicateError ? duplicationError : " "}
                    {this.props.isError ? serverError : ""}
                </div>
                <DialogActions role="presentation" aria-label="buttons container">
                    <Button
                        variant="contained"
                        color="default"
                        onClick={this.handleCloseClick}
                        disabled={
                            (this.state.displayLoadingBarOnAddAnother || this.state.displayLoadingBarOnSave) &&
                            !this.props.isError
                        }
                    >
                        Close
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={
                            (this.fieldsValidation() ||
                                this.state.displayLoadingBarOnAddAnother ||
                                this.state.displayLoadingBarOnSave) &&
                            !this.props.isError
                        }
                        onClick={() => this.onSaveClick(false)}
                    >
                        {this.props.selectedArgument ? "Update" : "Save"}
                        {/* Loading */}
                        {this.state.displayLoadingBarOnSave && !this.props.isError && (
                            <CircularProgress size={20} color="inherit" />
                        )}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={
                            (this.fieldsValidation() ||
                                this.state.displayLoadingBarOnAddAnother ||
                                this.state.displayLoadingBarOnSave) &&
                            !this.props.isError
                        }
                        onClick={() => this.onSaveClick(true)}
                    >
                        {"Add Another"}
                        {/* Loading */}
                        {this.state.displayLoadingBarOnAddAnother && !this.props.isError && (
                            <CircularProgress size={20} color="inherit" />
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    /*
     * Check if user trying to create/update to existing argument
     */
    private checkDuplication(): boolean {
        const isDuplicate = this.props.duplicationCheck(this.state.argumentDisplayName);
        if (isDuplicate === true) {
            if (this.state.isDuplicateError === false) {
                this.setState({
                    isDuplicateError: true,
                });
            }
        } else {
            if (this.state.isDuplicateError === true) {
                this.setState({
                    isDuplicateError: false,
                });
            }
        }
        return isDuplicate;
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(argumentDefinitionSimpleDialogStyle)(ArgumentDefinitionalSimpleDialog));
