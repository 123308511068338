import { Typography } from "@material-ui/core";
import classNames from "classnames";
import * as React from "react";
import SternumGeneratedEventInfo from "../../lib/state/SternumGeneratedEventInfo";
import TraceInfo from "../../lib/state/TraceInfo";
import SternumFlag from "../SternumFlag/SternumFlag";
import useGeoLocationContentStyles from "./GeoLocationContentStyles";

interface AppProps {
    trace?: TraceInfo;
    generatedEvent?: SternumGeneratedEventInfo;
}

const GeoLocationContent = ({ trace = null, generatedEvent = null }: AppProps) => {
    const classes = useGeoLocationContentStyles();

    const geoLocationData = trace ? trace.geoLocationInfo : generatedEvent.geoLocationInfo;

    const ipAddress = trace ? trace.device.ipAddress : generatedEvent.device.ipAddress;

    return (
        <div role="presentation" aria-label="geo location content" className={classNames(classes.flexRow)}>
            <div className={classNames(classes.flexRow)}>
                <Typography variant="body2" className={classNames(classes.extraBold)}>
                    {"IP Address:"}
                </Typography>
                <Typography variant="body2" className={classNames(classes.marginLeftXs)}>
                    {ipAddress}
                </Typography>
            </div>
            <div className={classNames(classes.container)}>
                <div className={classNames(classes.separator)}></div>
            </div>

            <div className={classNames(classes.flexRow)}>
                <Typography variant="body2" className={classNames(classes.extraBold)}>
                    {"Country:"}
                </Typography>
                {geoLocationData && (
                    <div className={classNames(classes.marginLeftXs)}>
                        <SternumFlag countryCode={geoLocationData.country} />
                    </div>
                )}

                <Typography variant="body2" className={classNames(classes.marginLeftXs)}>
                    {geoLocationData ? geoLocationData.country : "N/A"}
                </Typography>
            </div>

            <div className={classNames(classes.container)}>
                <div className={classNames(classes.separator)}></div>
            </div>

            <div className={classNames(classes.flexRow)}>
                <Typography variant="body2" className={classNames(classes.extraBold)}>
                    {"State:"}
                </Typography>
                <Typography variant="body2" className={classNames(classes.marginLeftXs)}>
                    {geoLocationData ? geoLocationData.state : "N/A"}
                </Typography>
            </div>
        </div>
    );
};

export default GeoLocationContent;
