import { Action } from "redux";
import ActionType from "../ActionType";

/**
 * Defines the data for the view trace action.
 */
export interface ViewTraceAction extends Action<ActionType> {
    type: ActionType.ViewTrace;
    traceId: string;
    issueId?: string;
}

/**
 * Action creator for viewing a trace.
 */
export function viewTraceAction(traceId: string, issueId?: string): ViewTraceAction {
    return {
        type: ActionType.ViewTrace,
        traceId: traceId,
        issueId,
    };
}
