import { makeStyles } from "@material-ui/core";

export const usePagerStyles = makeStyles((theme) => {
    return {
        dots: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        },
        dot: {
            width: 5,
            height: 5,
            borderRadius: "50%",
            backgroundColor: "#D8DFEC",
            marginRight: 5,
            cursor: "pointer",
        },
        dotActive: {
            backgroundColor: "#EE3B66",
        },
        selectedPage: {
            marginRight: 20,
            fontSize: 12,
            lineHeight: "18px",
            fontWeight: 600,
        },
    };
});
