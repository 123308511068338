import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {},

    noPadding: {
        padding: "0 !important",
    },

    selectArchitecture: {
        display: "flex",
        columnGap: "15px",
        marginTop: "10px",
    },

    architectureOption: {
        flex: 1,
        display: "flex",
        justifyContent: "center",
        padding: "10px 0",
        background: "#FFFFFF",
        border: "1px solid #E7EEF6",
        borderRadius: "10px",
        transition: "all 0.2s ease",
        cursor: "pointer",

        "&.selected": {
            background: "#1B6FDE",
            color: "#FFF",
        },
    },

    infoTooltip: {
        background: "#1B1B1B",
        fontSize: "12px",
    },
}));
