import { Action } from "redux";
import ActionType from "../ActionType";

/**
 * Defines the data for a used library not being viewed anymore action.
 */
export interface UsedLibraryNotViewedAction extends Action<ActionType> {
    type: ActionType.UsedLibraryNotViewed;
}

/**
 * Action creator for used library not being viewed anymore.
 */
export function usedLibraryNotViewedAction(): UsedLibraryNotViewedAction {
    return {
        type: ActionType.UsedLibraryNotViewed,
    };
}
