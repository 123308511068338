/**
 * Represents a configuration for a category in Sternum.
 */
class TraceCategory {
    /**
     * Constructor.
     */
    constructor(
        public systemName: string,
        public displayName: string,
        public numericIdentifier: number,
        public color: string,
        public description?: string
    ) {}

    /**
     * Converts json to info object.
     */
    public static fromJsonObject(jsonObject: Object) {
        return new TraceCategory(
            jsonObject["name"],
            jsonObject["display_name"],
            jsonObject["numeric_identifier"],
            jsonObject["color"],
            jsonObject["description"]
        );
    }
}

export default TraceCategory;
