import React from "react";

import { SternumIconProps } from "./SternumIcon.types";

export function LockIcon({ className, color, height = 17, width = 16, ...props }: SternumIconProps) {
    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M3.66992 15.1667C3.39214 15.1667 3.15603 15.0695 2.96159 14.8751C2.76714 14.6806 2.66992 14.4445 2.66992 14.1667V6.93341C2.66992 6.65564 2.76714 6.41953 2.96159 6.22508C3.15603 6.03064 3.39214 5.93341 3.66992 5.93341H4.83659V4.33341C4.83659 3.45564 5.14492 2.70841 5.76159 2.09175C6.37825 1.47508 7.12548 1.16675 8.00325 1.16675C8.88103 1.16675 9.62825 1.47508 10.2449 2.09175C10.8616 2.70841 11.1699 3.45564 11.1699 4.33341V5.93341H12.3366C12.6144 5.93341 12.8505 6.03064 13.0449 6.22508C13.2394 6.41953 13.3366 6.65564 13.3366 6.93341V14.1667C13.3366 14.4445 13.2394 14.6806 13.0449 14.8751C12.8505 15.0695 12.6144 15.1667 12.3366 15.1667H3.66992ZM5.83659 5.93341H10.1699V4.33341C10.1699 3.73341 9.95881 3.2223 9.53659 2.80008C9.11437 2.37786 8.60325 2.16675 8.00325 2.16675C7.40325 2.16675 6.89214 2.37786 6.46992 2.80008C6.0477 3.2223 5.83659 3.73341 5.83659 4.33341V5.93341ZM3.66992 14.1667H12.3366V6.93341H3.66992V14.1667ZM8.00325 11.8334C8.35881 11.8334 8.66159 11.7112 8.91159 11.4667C9.16159 11.2223 9.28659 10.9279 9.28659 10.5834C9.28659 10.2501 9.16159 9.9473 8.91159 9.67508C8.66159 9.40286 8.35881 9.26675 8.00325 9.26675C7.6477 9.26675 7.34492 9.40286 7.09492 9.67508C6.84492 9.9473 6.71992 10.2501 6.71992 10.5834C6.71992 10.9279 6.84492 11.2223 7.09492 11.4667C7.34492 11.7112 7.6477 11.8334 8.00325 11.8334Z"
                fill={color || "currentColor"}
            />
        </svg>
    );
}
