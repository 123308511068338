import { Anomaly } from "../../lib/state/Anomaly";

export const demoData: Anomaly[] = [
    {
        traceCategory: "Certification extraction",
        actualValue: 95,
        highValue: 95,
        shap: 1.5,
        median: 65,
        firstQuartile: 55,
        thirdQuartile: 75,
        min: 45,
        max: 85,
        iconDisabled: true,
        labelColor: "#BCBED2",
        zero: 0,
    },
    {
        traceCategory: "Firmware signature validation",
        actualValue: 0,
        normalValue: 0,
        shap: 0.7,
        median: 50,
        firstQuartile: 40,
        thirdQuartile: 60,
        min: 30,
        max: 70,
        iconDisabled: false,
        labelColor: "#FFA337",
        zero: 0,
    },
    {
        traceCategory: "Connect",
        actualValue: 40,
        normalValue: 40,
        shap: 0.6,
        median: 0,
        firstQuartile: 0,
        thirdQuartile: 0,
        min: 0,
        max: 0,
        iconDisabled: true,
        labelColor: "#BCBED2",
        zero: 0,
    },
    {
        traceCategory: "Authentication Success",
        actualValue: 75,
        normalValue: 75,
        shap: 0.5,
        median: 50,
        firstQuartile: 40,
        thirdQuartile: 60,
        min: 30,
        max: 70,
        iconDisabled: true,
        labelColor: "#BCBED2",
        zero: 0,
    },
    {
        traceCategory: "Update Requests",
        actualValue: 30,
        normalValue: 30,
        shap: 0.4,
        median: 35,
        firstQuartile: 25,
        thirdQuartile: 45,
        min: 15,
        max: 55,
        iconDisabled: true,
        labelColor: "#BCBED2",
        zero: 0,
    },
];
