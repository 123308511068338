import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

const deviceDefinitionModalStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: {},

        stepContentHeight: {
            height: theme.spacing(78),
        },

        selectComponent: {
            minWidth: theme.spacing(20),
        },
        whenSentence: {
            fontWeight: 500,
        },
        actionsSentence: {
            fontWeight: 500,
        },
        stepper: {
            background: "none",
            padding: `${theme.spacing(5)}px ${theme.spacing(5)}px 0 ${theme.spacing(5)}px`,
        },
        deleteActionIcon: {
            fontSize: theme.spacing(3),
        },
        actionDisplayName: {
            width: "30%",
        },
        valueInputBox: {
            fontSize: "0.8125rem",
            fontWeight: theme.typography.fontWeightRegular,
        },
        nameColumn: {
            width: "26%",
        },
        eventBox: {
            minWidth: theme.spacing(44),
        },
        saveButton: {
            marginTop: theme.spacing(16),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        backButton: {
            marginRight: theme.spacing(1),
        },
        exportPopperContainer: {
            background: "#fff",
            boxShadow: "1px 2px 6px 0 rgba(0,0,0,0.15)",
            padding: theme.spacing(2),
            paddingLeft: 0,
        },
        exportPopperIconSize: {
            "font-size": theme.spacing(3),
        },
    });

export default deviceDefinitionModalStyle;
