/**
 * Represents a configuration of an update source type.
 */
class UpdateSourceTypeConfiguration {
    /**
     * Constructor.
     */
    constructor(public key: string, public displayName: string) {}
}

export default UpdateSourceTypeConfiguration;
