import React from "react";

import { SternumIconProps } from "./SternumIcon.types";

export function FeedbackIcon({ className, color, height = 14, width = 14, ...props }: SternumIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M3.40361 8.62322H7.61688C7.74958 8.62322 7.86293 8.57622 7.95693 8.48222C8.05093 8.38823 8.09792 8.26935 8.09792 8.12559C8.09792 7.98183 8.05093 7.86295 7.95693 7.76895C7.86293 7.67495 7.74405 7.62796 7.60029 7.62796H3.38702C3.25432 7.62796 3.14097 7.67495 3.04698 7.76895C2.95298 7.86295 2.90598 7.98183 2.90598 8.12559C2.90598 8.26935 2.95298 8.38823 3.04698 8.48222C3.14097 8.57622 3.25985 8.62322 3.40361 8.62322ZM3.40361 6.46682H10.387C10.5197 6.46682 10.6331 6.41982 10.7271 6.32582C10.8211 6.23183 10.8681 6.11295 10.8681 5.96919C10.8681 5.82543 10.8211 5.70655 10.7271 5.61255C10.6331 5.51856 10.5142 5.47156 10.3704 5.47156H3.38702C3.25432 5.47156 3.14097 5.51856 3.04698 5.61255C2.95298 5.70655 2.90598 5.82543 2.90598 5.96919C2.90598 6.11295 2.95298 6.23183 3.04698 6.32582C3.14097 6.41982 3.25985 6.46682 3.40361 6.46682ZM3.40361 4.31042H10.387C10.5197 4.31042 10.6331 4.26342 10.7271 4.16943C10.8211 4.07543 10.8681 3.95655 10.8681 3.81279C10.8681 3.66903 10.8211 3.55015 10.7271 3.45616C10.6331 3.36216 10.5142 3.31516 10.3704 3.31516H3.38702C3.25432 3.31516 3.14097 3.36216 3.04698 3.45616C2.95298 3.55015 2.90598 3.66903 2.90598 3.81279C2.90598 3.95655 2.95298 4.07543 3.04698 4.16943C3.14097 4.26342 3.25985 4.31042 3.40361 4.31042ZM0.251953 12.7204V1.6398C0.251953 1.38546 0.351479 1.156 0.550531 0.951416C0.749583 0.746835 0.981811 0.644544 1.24721 0.644544H12.5268C12.7812 0.644544 13.0106 0.746835 13.2152 0.951416C13.4198 1.156 13.5221 1.38546 13.5221 1.6398V10.2654C13.5221 10.5197 13.4198 10.7492 13.2152 10.9538C13.0106 11.1584 12.7812 11.2607 12.5268 11.2607H2.90598L1.09792 13.0687C0.943106 13.2235 0.763407 13.2595 0.558825 13.1765C0.354244 13.0936 0.251953 12.9415 0.251953 12.7204ZM1.24721 11.5095L2.49129 10.2654H12.5268V1.6398H1.24721V11.5095ZM1.24721 1.6398V10.2654V11.5095V1.6398Z"
                fill={color || "currentColor"}
            />
        </svg>
    );
}
