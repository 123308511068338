import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../../CommonStyle";

const argumentDefinitionStepStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: {
            paddingTop: theme.spacing(5),
            paddingRight: theme.spacing(5),
            paddingBottom: theme.spacing(5),
            paddingLeft: theme.spacing(5),
        },

        heading: {
            fontSize: theme.typography.pxToRem(15),
        },

        systemDisplayName: {
            width: theme.spacing(44),
        },

        systemRoleName: {
            width: theme.spacing(44),
        },

        customDisplayName: {
            width: theme.spacing(44),
        },

        customDisplayRoleName: {
            width: theme.spacing(50),
        },

        displayBlock: {
            paddingRight: "0px",
        },
        argumentItem: {
            "&:hover": {
                background: "#F0F0F0",
            },
        },

        argumentsList: {
            height: theme.spacing(30),
            overflowY: "auto",
        },

        smallIconButton: {
            padding: "4px",
        },
    });

export default argumentDefinitionStepStyle;
