import React, { ReactElement, useMemo, useState } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { isString } from "lodash";
import { Box, Chip, Tooltip, Typography } from "@material-ui/core";

import SternumConfiguration from "../../../../lib/infra/SternumConfiguration";
import HashSet from "../../../../lib/infra/HashSet";
import SternumQueryEditor from "../../../SternumQueryEditor/SternumQueryEditor";
import SternumImprovedButton from "../../../SUI/SternumImprovedButton/SternumImprovedButton";
import SternumDeviceEventsList from "../../../SternumDeviceEventsList/SternumDeviceEventsList";
import ServiceWire from "../../../../lib/services/ServiceWire";
import { FilterIcon, PlusFilledIcon } from "../../../SUI/SternumIcon";
import ConditionType from "../../../../lib/state/ConditionType";
import TableToolbarDisplayState from "../../../../lib/state/TableToolbarDisplayState";
import SternumUtils from "../../../../lib/infra/SternumUtils";
import DeviceInfo from "../../../../lib/state/DeviceInfo";
import SternumDeviceEventsFilter from "../../../../lib/state/SternumDeviceEventsFilter";
import SternumQuery from "../../../../lib/state/SternumQuery";
import QueryQuantifierType from "../../../../lib/state/QueryQuantifierType";
import { GlobalState } from "../../../../lib/state/GlobalState";
import DeviceDefinitionVersionInfo from "../../../../lib/state/DeviceDefinitionVersionInfo";
import ReceivedDefinitionsResponse from "../../../../lib/services/events/ReceivedDefinitionsResponse";
import TimeSelectOption from "../../../../lib/state/TimeSelectOption";
import { openVisualizationCreationModalAction } from "../../../../lib/redux/modals/OpenModalAction";

import { useReceivedDefinitions } from "./TracesTab.hooks";
import { useStyle } from "./TracesTab.styles";
import ConfigurationService from "../../../../lib/services/ConfigurationService";
import ReceivedDefinitionSummary from "../../../../lib/services/events/ReceivedDefinitionSummary";

export interface TracesTabProps {
    device: DeviceInfo;
    startDate: Date;
    endDate: Date;
    timeSelectOption: TimeSelectOption;
}

const mapStateToProps = (state: GlobalState, ownProps: TracesTabProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        openVisualizationCreationModal: (
            key: string,
            visualisationId: string,
            deviceDefinitionVersions: DeviceDefinitionVersionInfo[],
            receivedDefinitionsResponse: Record<string, ReceivedDefinitionsResponse>,
            startTime: Date,
            endTime: Date,
            sternumQuery: SternumQuery,
            displayViewForDeviceDefinition: boolean,
            displayXButton: boolean,
            displayBackButton: boolean,
            lookedUpEntityId: string,
            timeSelectedOption: TimeSelectOption,
            deviceId?: string
        ) =>
            dispatch(
                openVisualizationCreationModalAction(
                    key,
                    visualisationId,
                    deviceDefinitionVersions,
                    receivedDefinitionsResponse,
                    startTime,
                    endTime,
                    sternumQuery,
                    displayViewForDeviceDefinition,
                    displayXButton,
                    displayBackButton,
                    lookedUpEntityId,
                    timeSelectedOption,
                    deviceId
                )
            ),
    };
};

type TracesTabPropsWithHOC = TracesTabProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

function getFinalApiQuery(query: SternumQuery, traceDefinitions?: ReceivedDefinitionSummary[]) {
    const processInformationTrace = traceDefinitions?.find(
        (definition) => definition.displayName === "Process Information"
    );
    const processListInformationTrace = traceDefinitions?.find(
        (definition) => definition.displayName === "Process List Information"
    );
    const storageReportTrace = traceDefinitions?.find((definition) => definition.displayName === "Storage Report");

    const finalQuery = SternumQuery.fromJsonObject({
        query_type: "AND",
        filters: [
            {
                argument_definition_id: ConfigurationService.getEventTypeArgumentField().id,
                filter_condition: ConditionType.EQUALS,
                value: ConfigurationService.getAllTracesFilterField().id,
                display_value: ConfigurationService.getAllTracesFilterField().displayName,
            },
            processInformationTrace && {
                argument_definition_id: ConfigurationService.getEventTypeArgumentField().id,
                filter_condition: ConditionType.NOT_EQUALS,
                value: processInformationTrace.identifier,
                display_value: processInformationTrace.displayName,
            },
            processListInformationTrace && {
                argument_definition_id: ConfigurationService.getEventTypeArgumentField().id,
                filter_condition: ConditionType.NOT_EQUALS,
                value: processListInformationTrace.identifier,
                display_value: processListInformationTrace.displayName,
            },
            storageReportTrace && {
                argument_definition_id: ConfigurationService.getEventTypeArgumentField().id,
                filter_condition: ConditionType.NOT_EQUALS,
                value: storageReportTrace.identifier,
                display_value: storageReportTrace.displayName,
            },
        ].filter(Boolean),
        queries: [],
    });

    finalQuery.innerQueries = [query.clone()];

    return finalQuery;
}

function getDefaultQuery() {
    const query = SternumQuery.getEmptyQuery();
    query.queryQuantifierType = QueryQuantifierType.ANY;
    return query;
}

const defaultQuery = getDefaultQuery();

function TracesTabComponent({
    device,
    startDate,
    endDate,
    timeSelectOption,
    openVisualizationCreationModal,
}: TracesTabPropsWithHOC) {
    const classes = useStyle();
    const [areFiltersExpanded, setFiltersExpanded] = useState(false);
    const [query, setQuery] = useState<SternumQuery>(defaultQuery);
    const [temporaryQuery, setTemporaryQuery] = useState<SternumQuery>(defaultQuery);
    const [selectedQuery, setSelectedQuery] = useState<SternumQuery>(defaultQuery);

    const {
        receivedDefinitions,
        receivedArguments,
        isLoading: areDefinitionsLoading,
    } = useReceivedDefinitions({
        deviceId: device.entityId,
        createdFrom: startDate.getTime(),
        createdTo: endDate.getTime(),
    });

    const handleQuerySelect = (query: SternumQuery) => {
        setSelectedQuery(query);
        setFiltersExpanded(true);
    };

    const handleQueryDelete = (innerQuery: SternumQuery) => {
        const query = temporaryQuery.clone();
        query.innerQueries = query.innerQueries.filter((q) => q.id !== innerQuery.id);

        setTemporaryQuery(query);
        setQuery(query);
        setSelectedQuery(query.innerQueries[0]);
    };

    const handleAddQueryClick = () => {
        const clonedQuery = temporaryQuery.clone();

        const newQuery = SternumQuery.getEmptyQuery();
        clonedQuery.innerQueries.push(newQuery);

        setTemporaryQuery(clonedQuery);
        setSelectedQuery(newQuery);
        setFiltersExpanded(true);
    };

    const onTemporarySternumQueryChanged = (innerQuery: SternumQuery) => {
        const clonedQuery = temporaryQuery.clone();
        clonedQuery.innerQueries = clonedQuery.innerQueries.map((query) =>
            query.id === innerQuery.id ? innerQuery : query
        );

        setTemporaryQuery(clonedQuery);
        setSelectedQuery(innerQuery);
    };

    const onVisualisationClicked = async (visualisationId?: string) => {
        const deviceDefinitionVersion = await ServiceWire.getSternumService().getDeviceDefinitionVersion(
            device.lastSeenVersionId
        );

        openVisualizationCreationModal(
            "VisualizationCreation",
            visualisationId,
            [deviceDefinitionVersion],
            {
                [deviceDefinitionVersion.entityId]: {
                    receivedArguments,
                    receivedDefinitions,
                },
            },
            startDate,
            endDate,
            SternumQuery.getEmptyQuery(),
            false,
            false,
            false,
            device.entityId,
            timeSelectOption,
            device.entityId
        );
    };

    const handleApplyClick = () => {
        setQuery(temporaryQuery);
        setFiltersExpanded(false);
    };

    const queryEditorFields = SternumUtils.getDeviceDefinitionFieldsToQuery(
        false,
        false,
        false,
        false,
        receivedDefinitions?.filter((definition) => definition.displayName !== "Attack Attempt"),
        receivedArguments,
        false
    );

    const eventsFilter = useMemo(() => {
        return new SternumDeviceEventsFilter(
            null,
            false,
            startDate?.getTime(),
            endDate?.getTime(),
            null,
            null,
            [],
            false,
            getFinalApiQuery(query, receivedDefinitions),
            null
        );
    }, [startDate, endDate, query, receivedDefinitions]);

    let columnWidthsArray = [10, 10, 10, 60];

    let viewedColumns = ["created", "displayName", "details", "eventCategory"];

    return (
        <Box className={classes.container}>
            <div role="presentation" aria-label="events list top bar container">
                <div className={classNames(classes.flexVMiddle, classes.fullWidth)}>
                    <div
                        className={classNames(
                            classes.flexVMiddle,
                            classes.flexGrow,
                            classes.eventsSectionTitleContentContainer,
                            classes.fullWidth
                        )}
                    >
                        <FilterIcon color="#ACB4BD" />

                        <Typography variant="body2" className={classNames(classes.eventsSectionTitleTypography)}>
                            Filters
                        </Typography>

                        <div className={classes.filterVerticalDivider} />

                        <div className={classNames(classes.flexVMiddle, classes.chipsContainer)}>
                            {/* first query shouldnt be available for user modifications */}
                            {temporaryQuery.innerQueries.map((query, index) => {
                                const labels = query.filters.map((filter) => {
                                    const conditionApiName = filter.conditionApiName as ConditionType;

                                    let value = filter.valuesMap.values()[0]?.displayValue;
                                    const field = queryEditorFields.find(
                                        (field) => field.apiName === filter.fieldApiName
                                    );

                                    if (
                                        [ConditionType.IS_EMPTY, ConditionType.IS_NOT_EMPTY].includes(conditionApiName)
                                    ) {
                                        const condition = SternumConfiguration.getCondition(conditionApiName);

                                        value = condition?.label;
                                    }

                                    value = isString(value) ? value : (value as ReactElement)?.props?.children || "";

                                    const label = field ? `${field.label}: ${value}` : "";

                                    return label;
                                });

                                return (
                                    <div
                                        key={query.id}
                                        className={classNames(classes.marginRight, classes.flexVMiddle)}
                                    >
                                        <Tooltip title={labels.join("; ")} placement="top">
                                            <Chip
                                                role="button"
                                                aria-label="filter chip"
                                                className={classes.filterChip}
                                                variant={query.id === selectedQuery?.id ? "default" : "outlined"}
                                                color="primary"
                                                label={labels.join("; ") || "Undefined"}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleQuerySelect(query);
                                                }}
                                                onDelete={() => {
                                                    handleQueryDelete(query);
                                                }}
                                            />
                                        </Tooltip>

                                        {index !== temporaryQuery.innerQueries.length - 1 && (
                                            <Typography variant="body1" className={classes.marginLeft}>
                                                or
                                            </Typography>
                                        )}
                                    </div>
                                );
                            })}

                            <Tooltip title="Add Filter" placement="top">
                                <div role="button" aria-label="add filter" style={{ height: 20 }}>
                                    <PlusFilledIcon
                                        width={20}
                                        height={20}
                                        color="#1B6DD9"
                                        className={classNames(classes.cursorPointer)}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            handleAddQueryClick();
                                        }}
                                    />
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                </div>

                {areFiltersExpanded && selectedQuery && (
                    <div
                        role="presentation"
                        aria-label="filter container"
                        className={classNames(classes.filterContainer)}
                    >
                        <div className={classNames(classes.marginLeftLarge, classes.marginTop, classes.flex)}>
                            <SternumQueryEditor
                                withoutInnerQueries
                                loading={areDefinitionsLoading && !receivedDefinitions}
                                error={false}
                                fields={queryEditorFields}
                                sternumQuery={selectedQuery}
                                onSternumQueryChanged={(updatedQuery) => onTemporarySternumQueryChanged(updatedQuery)}
                            />
                        </div>

                        <div className={classNames(classes.queryEditorFooter)}>
                            <SternumImprovedButton
                                fullWidth={false}
                                onClick={handleApplyClick}
                                isDisabled={false}
                                content="Apply"
                            />
                        </div>
                    </div>
                )}
            </div>

            <SternumDeviceEventsList
                clientId={ServiceWire.getClientsService().getSelectedClientId()}
                columnWidthsArray={columnWidthsArray}
                doNotDisplayLoading
                refreshEntitiesFilter={false}
                onNewVisualizationClicked={() => onVisualisationClicked()}
                onExistingVisualisationClicked={(visualisationId) => onVisualisationClicked(visualisationId)}
                toolbarState={
                    new TableToolbarDisplayState(
                        false,
                        false,
                        false,
                        false,
                        true,
                        true,
                        true,
                        false,
                        "Search Event Name",
                        true,
                        true
                    )
                }
                infiniteScroll={true}
                entityId={device.entityId}
                viewedColumnsSet={HashSet.fromValues(viewedColumns)}
                entitiesFilter={eventsFilter}
                selectedTimeSelectionType={timeSelectOption.selectionType}
                deviceDefinitionVersionId={device.lastSeenVersionId}
                shouldDisplayLinkToDeviceView={false}
                paperClassNames={classes.tracesListPaper}
                displayBackButtonInTraceView={true}
                displayXButtonInTraceView={true}
                emptyTableMessage="Traces will appear here."
                shouldNoWrapDisplayName={false}
                excludeLinuxViewTraces
                hideShowInContext
            />
        </Box>
    );
}

export const TracesTab: React.FC<TracesTabProps> = connect(mapStateToProps, mapDispatchToProps)(TracesTabComponent);
