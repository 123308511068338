import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

const createTriggerModalStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: {
            paddingTop: theme.spacing(5),
            paddingRight: theme.spacing(5),
            paddingBottom: theme.spacing(5),
            paddingLeft: theme.spacing(5),
        },
        selectComponent: {
            minWidth: theme.spacing(20),
        },
        whenSentence: {
            fontWeight: 500,
        },
        actionsSentence: {
            fontWeight: 500,
        },
        deleteActionIcon: {
            fontSize: theme.spacing(3),
        },
        actionDisplayName: {
            width: "30%",
        },
    });

export default createTriggerModalStyle;
