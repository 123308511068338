import React from "react";
import { connect } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import moment from "moment";

import { InfoCircleIcon } from "../SUI/SternumIcon";
import { SternumTooltip } from "../SUI/SternumTooltip";
import IssueInfo from "../../lib/state/IssueInfo";
import { IssueInfoReasonType, IssueInfoStateStatus, IssueInfoStatus } from "../../lib/state/IssueInfoType";
import { GlobalState } from "../../lib/state/GlobalState";
import { ResolvingReasons, ResolvingReasonsCustomItem, ResolvingReasonsItem } from "../TraceView/ResolvingReasons";
import { CustomReason, DefaultIssueReason, ReasonsDefaultAndCustom } from "./TraceViewAlertStatus.types";
import { useTraceViewAlertStatusStyle } from "./TraceViewAlertStatus.style";
import { reasonsDataByIssueReasonType } from "./TraceViewAlertStatus.model";
import { useCommonStyle } from "../CommonStyle";

export interface TraceViewAlertViewStatusProps {
    savedLocalStatus?: IssueInfoStatus;
    savedLocalReasons?: ReasonsDefaultAndCustom;
}

const mapStateToProps = (state: GlobalState, ownProps: TraceViewAlertViewStatusProps) => {
    const issueId = state.issues.selectedIssue?.issueId || state.ui.currentlyViewedIssueId;

    return {
        selectedIssue: state.issues.selectedIssue, // Can be undefined
        selectedIssueState: state.issues.issueStateByIssueId.get(issueId), // Can be null or undefined
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

type TraceViewAlertStatusPropsWithHOC = TraceViewAlertViewStatusProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {};

export const TraceViewAlertViewAnomalyStatus: React.FC<TraceViewAlertViewStatusProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(TraceViewAlertViewAnomalyStatusComponent);

function TraceViewAlertViewAnomalyStatusComponent({
    savedLocalStatus,
    savedLocalReasons,
    selectedIssue,
    selectedIssueState,
}: TraceViewAlertStatusPropsWithHOC) {
    const classes = useTraceViewAlertStatusStyle();
    const classesCommon = useCommonStyle();
    const issueStatus = savedLocalStatus || getIssueInfoStatus(selectedIssue);
    const reasons = savedLocalReasons || getReasons(selectedIssue);

    const areAlertReasonsVisible =
        selectedIssueState?.issueStatus !== IssueInfoStateStatus.Resolving &&
        !!(reasons.default.length || reasons.custom.length);

    const renderIssueInfoStatusTags = () => {
        const tags = [];

        if (issueStatus === IssueInfoStatus.Resolved) {
            tags.push(
                <Box key={issueStatus} display="flex" alignItems="center">
                    <div className={classes.greenTag}>Resolved</div>
                    <Typography variant="caption" className={classesCommon.marginLeft}>
                        <>
                            {savedLocalStatus
                                ? `at ${moment().format("HH:mm MM/DD/YYYY")}`
                                : `at ${moment(selectedIssue?.updated).format(
                                      "HH:mm MM/DD/YYYY"
                                  )} by ${selectedIssue.getResolvedBy()}`}
                        </>
                    </Typography>
                </Box>
            );
        }

        if (issueStatus === IssueInfoStatus.Open) {
            tags.push(
                <div key={issueStatus} className={classes.yellowTag}>
                    Unresolved
                </div>
            );
        }

        if (issueStatus === IssueInfoStatus.Dismissed) {
            tags.push(
                <Box key={issueStatus} display="flex" alignItems="center">
                    <div className={classes.grayTag}>Dismissed</div>
                    <Typography variant="caption" className={classesCommon.marginLeft}>
                        <>
                            {savedLocalStatus
                                ? `at ${moment().format("HH:mm MM/DD/YYYY")}`
                                : `at ${moment(selectedIssue?.updated).format(
                                      "HH:mm MM/DD/YYYY"
                                  )} by ${selectedIssue.getResolvedBy()}`}
                        </>
                    </Typography>
                </Box>
            );
        }

        if (!tags.length) {
            return null;
        }

        return <div className={classes.tagsContainer}>{tags}</div>;
    };

    if (!issueStatus) {
        return null;
    }

    if (issueStatus === IssueInfoStatus.Open) {
        return null;
    }

    return (
        <div className={classes.titleSection}>
            <div className={classes.titleContainer}>
                <div className={classes.title}>
                    Alert status{" "}
                    <SternumTooltip
                        title={
                            <span className={classes.tooltipContent}>
                                Please resolve the unresolved issues to inform future issue suggestions. If this was not
                                an issue, please dismiss it rather than keeping it unresolved.
                            </span>
                        }
                    >
                        <InfoCircleIcon className={classes.statusIcon} />
                    </SternumTooltip>
                </div>
                <div className={classes.issueInfoStatusContainer}>
                    {renderIssueInfoStatusTags()}
                    {areAlertReasonsVisible && (
                        <ResolvingReasons isDisplayView>
                            {reasons.default.map((reasonData, index) => (
                                <ResolvingReasonsItem key={"default" + index} isSelected={true}>
                                    {reasonData.title}
                                </ResolvingReasonsItem>
                            ))}
                            {reasons.custom.map((customReason, index) => (
                                <ResolvingReasonsCustomItem key={"custom" + index} isSelected={true}>
                                    {customReason.title}
                                </ResolvingReasonsCustomItem>
                            ))}
                        </ResolvingReasons>
                    )}
                </div>
            </div>
        </div>
    );
}

function getIssueInfoStatus(issue?: IssueInfo): IssueInfoStatus | undefined {
    return issue ? (issue.status as IssueInfoStatus) : undefined;
}

function getReasons(issue?: IssueInfo): { default: DefaultIssueReason[]; custom: CustomReason[] } {
    const defaultReasons: DefaultIssueReason[] = [];
    const customReasons: CustomReason[] = [];

    issue?.reasons?.forEach?.((reason) => {
        if (IssueInfoReasonType.Other === reason.type) {
            customReasons.push({ title: reason.text || "", isSelected: true });
        } else {
            defaultReasons.push({
                type: reason.type,
                title: reason.text || reasonsDataByIssueReasonType[reason.type]?.title || "",
            });
        }
    });

    return {
        default: defaultReasons,
        custom: customReasons,
    };
}
