import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { uniqBy } from "lodash";

import HashSet from "../../../lib/infra/HashSet";
import ServiceWire from "../../../lib/services/ServiceWire";
import { GlobalState } from "../../../lib/state/GlobalState";
import SternumDeviceEventsFilter from "../../../lib/state/SternumDeviceEventsFilter";
import TableToolbarDisplayState from "../../../lib/state/TableToolbarDisplayState";
import AggregationFunctionType from "../../../lib/state/Visualisation/AggregationFunctionType";
import AggregatedTraceDefinition from "../../../lib/state/AggregatedTraceDefinition";
import SternumDeviceEventsList from "../../SternumDeviceEventsList/SternumDeviceEventsList";
import UIDataVisualisationConfiguration from "../entities/UIDataVisualisationConfiguration";
import useVisualisationConfigurationEventsExplorerStyle from "./VisualisationConfigurationEventsExplorerStyle";
import { UniqueCountProvider } from "../../UniqueCountQuery/UniqueCountProvider";
import ConfigurationService from "../../../lib/services/ConfigurationService";
import TimeSelectionType from "../../../lib/state/TimeSelectionType";

const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    const selectedClient = ServiceWire.getClientsService().getSelectedClient();

    return {
        isFreeUser: selectedClient.isTrialTier(),
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

export interface AppProps {
    dataVisualisationConfigurations: UIDataVisualisationConfiguration[];
    loading: boolean;
    error: boolean;
    displayViewForDeviceDefinition: boolean;
    selectedDataSource: string;
    selectedTimeSelectionType: TimeSelectionType;
    lookedUpEntityId: string;
    onEventsExplorerExpandToggle: () => void;
    isEventsExplorerExpanded: boolean;
    isFreeUser: boolean;
    deviceId?: string;
}

const columnWidthsArray = [10, 10, 10, 90];

const VisualisationConfigurationEventsExplorer = (props: AppProps) => {
    const classes = useVisualisationConfigurationEventsExplorerStyle();

    const viewedColumns = [
        "type",
        "created",
        "displayName",
        "deviceProfile",
        "details",
        "eventCategory",
        "firmwareVersion",
    ];

    if (props.displayViewForDeviceDefinition) {
        // Remove firmwareVersion from device definition view
        viewedColumns.pop();
        viewedColumns.push("deviceId");
    }

    /**
     * Gets the current filter object.
     */
    const getCurrentEventsFilter = (currentFilter: SternumDeviceEventsFilter) => {
        return new SternumDeviceEventsFilter(
            currentFilter.eventInterest,
            currentFilter.onlyAttackTraces,
            props.dataVisualisationConfigurations[0].entitiesFilter.createdFrom,
            props.dataVisualisationConfigurations[0].entitiesFilter.createdTo,
            currentFilter.lessThanId,
            currentFilter.greaterThanId,
            currentFilter.traceCategories,
            currentFilter.filterOnlyTriggers,
            currentFilter.sternumQuery,
            currentFilter.traceDefinitionIds
        );
    };

    return (
        <div className={classes.container}>
            <UniqueCountProvider dataVisualisationConfigurations={props.dataVisualisationConfigurations}>
                {props.dataVisualisationConfigurations
                    .filter(
                        (dataVisualisationConfiguration) =>
                            dataVisualisationConfiguration.dataSourceKey === props.selectedDataSource
                    )
                    .map((dataVisualisationConfiguration, index) => {
                        const hasDynamicColumns =
                            dataVisualisationConfiguration.aggregationFunctionType ===
                                AggregationFunctionType.UNIQUE_COUNT && dataVisualisationConfiguration.uniqueColumns;

                        let uniqueColumns: AggregatedTraceDefinition[] | null = null;
                        let hasDuplicates = false;

                        if (hasDynamicColumns) {
                            // unique columns should be not null columns and with no duplicates
                            const notNullUniqueColumns = dataVisualisationConfiguration.uniqueColumns.filter(
                                (col) => col !== null
                            );

                            const notDuplicatedUniqueColumns = uniqBy(notNullUniqueColumns, "value");
                            hasDuplicates = notNullUniqueColumns.length !== notDuplicatedUniqueColumns.length;

                            uniqueColumns = [
                                ...notDuplicatedUniqueColumns,
                                {
                                    value: "count",
                                    displayName: "Count",
                                    label: "Count",
                                    isSpecialField: false,
                                    isSternumTrigger: false,
                                },
                            ];
                        }

                        const hasArguments = hasDynamicColumns
                            ? dataVisualisationConfiguration.uniqueColumns.filter(
                                  (col) => col !== null && !col.isSpecialField
                              ).length > 0
                            : false;

                        return (
                            !props.loading && (
                                <div
                                    className={classes.tableContainer}
                                    key={dataVisualisationConfiguration.dataSourceKey}
                                >
                                    {hasDuplicates && (
                                        <div className={classes.duplicationWarning}>
                                            WARNING: [UNIQUE VALUE COLUMNS]: please don't set duplicated columns
                                        </div>
                                    )}
                                    <div className={classes.tableInner}>
                                        <SternumDeviceEventsList
                                            isTableExpanded={props.isEventsExplorerExpanded}
                                            onTableExpandToggle={props.onEventsExplorerExpandToggle}
                                            doNotDisplayExploitationTypeInDisplayName={true}
                                            selectedTimeSelectionType={props.selectedTimeSelectionType}
                                            columnWidthsArray={
                                                hasDynamicColumns ? uniqueColumns.map((col) => 10) : columnWidthsArray
                                            }
                                            entityId={
                                                props.deviceId || ServiceWire.getClientsService().getSelectedClientId()
                                            }
                                            deviceDefinitionVersionId={props.lookedUpEntityId}
                                            viewedColumnsSet={HashSet.fromValues(
                                                hasDynamicColumns
                                                    ? [
                                                          ...uniqueColumns
                                                              .filter(
                                                                  (column) =>
                                                                      column.value !==
                                                                      ConfigurationService.getAllEventsFilterField().id
                                                              )
                                                              .map((col) => col.value),
                                                          ...(hasArguments ? ["arguments"] : []),
                                                      ]
                                                    : viewedColumns
                                            )}
                                            toolbarState={
                                                new TableToolbarDisplayState(
                                                    false,
                                                    false,
                                                    false,
                                                    false,
                                                    true,
                                                    false,
                                                    true,
                                                    false,
                                                    "Search Event Name",
                                                    true,
                                                    false,
                                                    props.isFreeUser
                                                )
                                            }
                                            infiniteScroll={true}
                                            doNotConsiderChangeInSternumQuery={true}
                                            entitiesFilter={getCurrentEventsFilter(
                                                dataVisualisationConfiguration.entitiesFilter
                                            )}
                                            aggregationFunctionType={
                                                dataVisualisationConfiguration.aggregationFunctionType
                                            }
                                            paperClassNames={classNames(classes.tracesListPaper)}
                                            displayBackButtonInTraceView={true}
                                            displayXButtonInTraceView={true}
                                            shouldDisplayLinkToDeviceView={true}
                                            amountOfLoadingPlaceholders={9}
                                            emptyTableMessage={"Events will appear here."}
                                            displayTypeColumn={true}
                                            // unique value configuration
                                            dynamicColumns={hasDynamicColumns ? uniqueColumns : null}
                                            hideShowInContext={true}
                                        />
                                    </div>
                                </div>
                            )
                        );
                    })}
            </UniqueCountProvider>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(VisualisationConfigurationEventsExplorer);
