import React from "react";

import { SternumIconProps } from "./SternumIcon.types";

export function ExternalLink2Icon({ className, color, height = 11, width = 12, ...props }: SternumIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 12 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M11.25 5.80625V9.875C11.25 10.1083 11.1625 10.3125 10.9875 10.4875C10.8125 10.6625 10.6083 10.75 10.375 10.75H1.625C1.39167 10.75 1.1875 10.6625 1.0125 10.4875C0.8375 10.3125 0.75 10.1083 0.75 9.875V1.125C0.75 0.891667 0.8375 0.6875 1.0125 0.5125C1.1875 0.3375 1.39167 0.25 1.625 0.25H5.69375V1.125H1.625V9.875H10.375V5.80625H11.25ZM4.57083 7.55625L3.95833 6.92917L9.7625 1.125H6.56875V0.25H11.25V4.93125H10.375V1.75208L4.57083 7.55625Z"
                fill={color || "currentcolor"}
            />
        </svg>
    );
}
