import TableRowData from "./TableRowData";

/**
 * Trigger hit summary for the top hits response.
 */
class TriggerHitSummary implements TableRowData {
    /**
     * Constructor.
     */
    constructor(
        public entityId: string,
        public count: number,
        public displayName: string,
        public eventInterest: string,
        public triggerDisabled: boolean
    ) {}

    getColumnValue(columnHeaderId: string): any {
        if (columnHeaderId === "count") {
            return this.count;
        } else {
            return "";
        }
    }

    getIdentifier(): string {
        return this.entityId;
    }

    isDifferentFrom(other: TableRowData): boolean {
        return true;
    }
}

export default TriggerHitSummary;
