import { Paper } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import HashSet from "../../lib/infra/HashSet";
import SternumConfiguration from "../../lib/infra/SternumConfiguration";
import {
    openCveViewModalAction,
    openSternumGeneratedEventViewModalAction,
    openTraceViewModalAction,
} from "../../lib/redux/modals/OpenModalAction";
import ActivityManager from "../../lib/services/ActivityManager";
import DebounceService from "../../lib/services/DebounceService";
import ServiceWire from "../../lib/services/ServiceWire";
import ActivityInfo from "../../lib/state/ActivityInfo";
import ActivityType from "../../lib/state/ActivityType";
import CveInfo from "../../lib/state/CveInfo";
import EntitiesFilter from "../../lib/state/EntitiesFilter";
import GetActivitiesResponse from "../../lib/state/GetActivitiesResponse";
import { GlobalState } from "../../lib/state/GlobalState";
import ListFilter from "../../lib/state/ListFilter";
import SternumGeneratedEventInfo from "../../lib/state/SternumGeneratedEventInfo";
import TableColumnHeaderInfo from "../../lib/state/TableColumnHeaderInfo";
import TableToolbarDisplayState from "../../lib/state/TableToolbarDisplayState";
import TraceInfo from "../../lib/state/TraceInfo";
import EntitiesListFilterPopover from "../EntitiesListFilterPopover/EntitiesListFilterPopover";
import SternumTable from "../SUI/SternumTable/SternumTable";
import activitiesListStyle from "./ActivitiesListStyle";
import moment from "moment";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    // Loading & error state.
    loadingEntities: boolean;
    errorLoadingEntities: boolean;

    // Entities.
    entities: ActivityInfo[];
    totalItemCount: number;

    // Filtering.
    entitiesFilter: EntitiesFilter;
    searchText: string;
    orderByField: string;
    order: "asc" | "desc";
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof activitiesListStyle> {
    sideBarOpen: boolean;
    location;
    history;

    includeColumns?: string[];
    paperClassNames?;
    viewedColumnsSet?: HashSet;

    preloadedEntities: ActivityInfo[];
    entitiesFilter: EntitiesFilter;

    openSternumGeneratedEventViewModal?: (
        key: string,
        generatedEventId: string,
        shouldDisplayLinkToDeviceView: boolean,
        shouldDisplayLinkToExportReport: boolean,
        displayXButton: boolean,
        displayBackButton: boolean
    ) => void;

    openTraceViewModal?: (
        key: string,
        traceId: string,
        displayXButton: boolean,
        displayBackButton: boolean,
        shouldDisplayLinkToDeviceView: boolean,
        shouldDisplayLinkToExportReport: boolean
    ) => void;
    openCveViewModal?: (key: string, cveId: string, displayXButton: boolean, displayBackButton: boolean) => void;

    showAllColumns?: boolean;

    toolbarState?: TableToolbarDisplayState;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {
        openTraceViewModal: (
            key,
            traceId,
            displayXButton,
            displayBackButton,
            shouldDisplayLinkToDeviceView,
            shouldDisplayLinkToExportReport
        ) =>
            dispatch(
                openTraceViewModalAction(
                    key,
                    traceId,
                    displayXButton,
                    displayBackButton,
                    shouldDisplayLinkToDeviceView,
                    shouldDisplayLinkToExportReport
                )
            ),
        openCveViewModal: (key, cveId, displayXButton, displayBackButton) =>
            dispatch(openCveViewModalAction(key, cveId, displayXButton, displayBackButton)),
        openSternumGeneratedEventViewModal: (
            key,
            generatedEventId,
            shouldDisplayLinkToDeviceView,
            shouldDisplayLinkToExportReport,
            displayXButton,
            displayBackButton
        ) =>
            dispatch(
                openSternumGeneratedEventViewModalAction(
                    key,
                    generatedEventId,
                    shouldDisplayLinkToDeviceView,
                    shouldDisplayLinkToExportReport,
                    displayXButton,
                    displayBackButton
                )
            ),
    };
};

/**
 * Holds the entities list in the app.
 */
class ActivitiesList extends React.Component<AppProps, AppState> {
    /**
     * Defines the column headers participating in the entities table.
     */
    private readonly columnHeaders: TableColumnHeaderInfo[] = [
        new TableColumnHeaderInfo("entityId", "Alert ID", false, true, true),
        new TableColumnHeaderInfo("created", "Reported", false, true, false),
        new TableColumnHeaderInfo("summary", "Summary", false, true, false),
        new TableColumnHeaderInfo("actions", "Actions", false, true, false, false),
    ];

    /**
     * Gets the display values for row's columns (where they aren't the default).
     */
    private getRowDisplayValues(row) {
        let entity = row as ActivityInfo;
        const { classes } = this.props;

        return {
            entityId: row.entityId,
            summary: ActivityManager.getActivityDisplayString(entity),
            created: moment(entity.created).format("MM/DD/YYYY HH:mm"),
            actions: (
                <div className={classes.flexVMiddle}>
                    {entity.activityType !== ActivityType.ISSUE_RESOLVED && (
                        <React.Fragment>
                            <Typography variant="body2" className={classes.linkText}>
                                Investigate
                            </Typography>
                            <Icon className={classNames("fa fa-external-link-alt", classes.linkIcon)} />
                        </React.Fragment>
                    )}
                </div>
            ),
        };
    }

    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        if (this.props.includeColumns) {
            if (this.props.includeColumns.length) {
                let includeColumnsSet = HashSet.fromValues(this.props.includeColumns);
                this.columnHeaders = this.columnHeaders.filter((columnHeader) =>
                    includeColumnsSet.exists(columnHeader.id)
                );
            } else {
                this.columnHeaders = [];
            }
        }

        if (this.props.showAllColumns) {
            for (let i = 0; i < this.columnHeaders.length; i++) {
                this.columnHeaders[i].isHidden = false;
            }
        }

        // Initializing the state to default.
        this.state = {
            loadingEntities: false,
            errorLoadingEntities: false,

            entities: [],

            entitiesFilter: this.props.entitiesFilter,
            searchText: null,
            orderByField: null,
            order: "asc",

            totalItemCount: null,
        };
    }

    /**
     * Occurs once the component is about to receive props.
     */
    UNSAFE_componentWillReceiveProps(nextProps: Readonly<AppProps>, nextContext: any): void {
        if (
            (!nextProps.entitiesFilter && this.props.entitiesFilter) ||
            (nextProps.entitiesFilter && !this.props.entitiesFilter) ||
            (nextProps.entitiesFilter &&
                this.props.entitiesFilter &&
                nextProps.entitiesFilter.isDifferentFrom(nextProps.entitiesFilter))
        ) {
            this.handleEntitiesFilterChanged(nextProps.entitiesFilter);
        }
    }

    /**
     * Occurs once the component finished its initialization process.
     */
    async componentDidMount() {
        await this.loadEntities(1);
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        return (
            <Paper
                classes={{
                    root: classNames(classes.paperClass, this.props.paperClassNames),
                }}
                elevation={0}
            >
                <SternumTable
                    columnWidthsArray={[10, 120, 10]}
                    viewedColumnsSet={this.props.viewedColumnsSet}
                    pageSize={SternumConfiguration.getPageSize()}
                    amountOfLoadingPlaceholders={9}
                    totalItemCount={this.state.totalItemCount}
                    loadingItems={this.state.loadingEntities}
                    errorLoadingItems={this.state.errorLoadingEntities}
                    columnHeaders={this.columnHeaders}
                    listFilter={this.state.entitiesFilter}
                    onRefreshClicked={() => this.onRefreshClicked()}
                    filterComponentProvider={(onFilterChanged) => (
                        <EntitiesListFilterPopover
                            entitiesFilter={this.state.entitiesFilter}
                            onFilterChanged={onFilterChanged}
                        />
                    )}
                    orderByField={this.state.orderByField}
                    order={this.state.order}
                    rows={this.state.entities}
                    onFilterChanged={(listFilter) => this.handleEntitiesFilterChanged(listFilter)}
                    onSearchTextChanged={(searchText) => this.handleSearchTextChanged(searchText)}
                    onPageChanged={(pageNumber) => this.loadEntities(pageNumber)}
                    onOrderChanged={(orderByField, order) => this.handleOrderChanged(orderByField, order)}
                    getRowValues={(row) => this.getRowDisplayValues(row)}
                    onRowClicked={(row) => this.handleRowClicked(row as ActivityInfo)}
                    toolbarState={
                        this.props.toolbarState
                            ? this.props.toolbarState
                            : new TableToolbarDisplayState(false, false, false, false, false, false, false)
                    }
                />
            </Paper>
        );
    }

    /**
     * Occurs on a click on a row.
     */
    private handleRowClicked(entity: ActivityInfo) {
        switch (entity.activityType) {
            case ActivityType.CVE_DETECTED:
                let cveInfo = entity.reference1 as CveInfo;
                this.props.openCveViewModal(cveInfo.entityId, cveInfo.entityId, true, true);
                break;

            case ActivityType.ATTACK_TRACE_DETECTED:
                let traceInfo = entity.reference1 as TraceInfo;
                this.props.openTraceViewModal(traceInfo.entityId, traceInfo.entityId, true, true, true, true);
                break;

            case ActivityType.STERNUM_TRIGGER_MET:
                let generatedInfo = entity.reference1 as SternumGeneratedEventInfo;
                this.props.openSternumGeneratedEventViewModal(
                    generatedInfo.entityId,
                    generatedInfo.entityId,
                    true,
                    true,
                    true,
                    true
                );
                break;
        }
    }

    /**
     * Occurs once the filters of the list are changed.
     */
    private handleEntitiesFilterChanged(entitiesFilter: ListFilter) {
        this.setState(
            {
                entitiesFilter: entitiesFilter != null ? (entitiesFilter as EntitiesFilter) : null,
            },
            async () => {
                await this.loadEntities(1);
            }
        );
    }

    /**
     * Occurs once search text is changed.
     */
    private handleSearchTextChanged(searchText) {
        // Debounce request to search entities.
        DebounceService.debounce(
            "getActivities",
            () => {
                this.setState(
                    {
                        searchText: searchText,
                    },
                    () => this.loadEntities(1)
                );
            },
            350
        );
    }

    /**
     * Occurs once sort order is changed.
     */
    private handleOrderChanged(orderByField, order) {
        this.setState(
            {
                orderByField: orderByField,
                order: order,
            },
            () => this.loadEntities(1)
        );
    }

    /**
     * Occurs once the user refreshes the list.
     */
    private async onRefreshClicked() {
        await this.loadEntities(1);
    }

    /**
     * Loads entities.
     */
    private async loadEntities(pageNumber) {
        try {
            // Setting loading to true.
            this.setState({
                loadingEntities: true,
                errorLoadingEntities: false,
            });
            // Fetching entities.
            let entities = [];
            let totalItemCount = 0;
            if (this.props.preloadedEntities) {
                entities = this.props.preloadedEntities;
                totalItemCount = entities.length;
            } else {
                const activityResponse: GetActivitiesResponse = await ServiceWire.getSternumService().getActivities(
                    ServiceWire.getClientsService().getSelectedClientId(),
                    this.state.entitiesFilter,
                    this.state.searchText,
                    this.state.orderByField,
                    this.state.order,
                    (pageNumber - 1) * SternumConfiguration.getPageSize(),
                    SternumConfiguration.getPageSize()
                );
                entities = activityResponse.activities;
                totalItemCount = activityResponse.totalItemCount;
            }

            this.setState({
                loadingEntities: false,
                errorLoadingEntities: false,
                entities: entities,
                totalItemCount: totalItemCount,
            });
        } catch (error) {
            this.setState({
                loadingEntities: false,
                errorLoadingEntities: true,
            });
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(activitiesListStyle)(ActivitiesList)));
