import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import newApiKeyModalStyle from "./NewAPIKeyModalStyle";
import { GlobalState } from "../../lib/state/GlobalState";
import { connect } from "react-redux";
import { Input, TextField, Typography } from "@material-ui/core";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import ServiceWire from "../../lib/services/ServiceWire";
import APIKeyInfo from "../../lib/state/APIKeyInfo";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    displayName: string;
    creatingNewKey: boolean;
    errorCreatingNewKey: boolean;
    newApiKey?: APIKeyInfo;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof newApiKeyModalStyle> {
    fullScreenDisplay: boolean;
    theme?;
    closeModal: () => void;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {};
};

/**
 * Displays a bar of metrics.
 */
class NewAPIKeyModal extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        this.state = {
            displayName: "",
            creatingNewKey: false,
            errorCreatingNewKey: false,
        };
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        if (this.state.newApiKey) {
            return (
                <div className={classes.root}>
                    <Typography variant={"h5"} className={classNames(classes.marginBottom)}>
                        New API Key Created
                    </Typography>
                    <div className={classNames(classes.row)}>
                        <Typography className={classNames(classes.marginRight, classes.nameColumn)}>Name</Typography>
                        <TextField
                            className={classNames(classes.inputColumn)}
                            value={this.state.newApiKey.displayName}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </div>
                    <div className={classNames(classes.row)}>
                        <Typography className={classNames(classes.marginRight, classes.nameColumn)}>API Key</Typography>
                        <TextField
                            className={classNames(classes.inputColumn)}
                            value={this.state.newApiKey.entityId}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </div>
                    <div className={classNames(classes.row)}>
                        <Typography className={classNames(classes.marginRight, classes.nameColumn)}>Secret</Typography>
                        <TextField
                            className={classNames(classes.inputColumn)}
                            value={this.state.newApiKey.secret}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </div>
                    <Button
                        size={"small"}
                        variant={"contained"}
                        color={"primary"}
                        className={classNames(classes.button)}
                        onClick={() => this.props.closeModal()}
                        disabled={!this.state.displayName}
                    >
                        <span>Close</span>

                        {/* Loading */}
                        {this.state.creatingNewKey && (
                            <CircularProgress className={classes.marginLeft} size={10} color="inherit" />
                        )}
                    </Button>
                </div>
            );
        }

        return (
            <div className={classes.root}>
                {/* Title */}
                <Typography variant={"h5"} className={classNames(classes.marginBottomXLarge)}>
                    Create New API Key
                </Typography>

                {/* Display Name */}
                <div className={classNames(classes.flexVMiddle, classes.marginBottomMedium)}>
                    {/* Title */}
                    <Typography className={classNames(classes.marginRight, classes.nameColumn)}>
                        Display Name:
                    </Typography>

                    {/* Input */}
                    <Input
                        className={classNames(classes.sternumInput, classes.marginRight)}
                        value={this.state.displayName}
                        onChange={(event) => this.onDisplayNameChanged(event)}
                    />
                </div>

                {/* Save */}
                <Button
                    size={"small"}
                    variant={"contained"}
                    color={"primary"}
                    className={classNames(classes.button)}
                    onClick={() => this.createNewAPIKey(this.state.displayName)}
                    disabled={!this.state.displayName}
                >
                    <span>Save</span>

                    {/* Loading */}
                    {this.state.creatingNewKey && (
                        <CircularProgress className={classes.marginLeft} size={10} color="inherit" />
                    )}
                </Button>

                {/* Error message */}
                {this.state.errorCreatingNewKey && (
                    <Typography color={"error"} variant={"caption"} className={classNames(classes.marginTopXs)}>
                        There was an error trying to create a new API key.
                    </Typography>
                )}
            </div>
        );
    }

    /**
     * Occurs on change of display name.
     */
    private onDisplayNameChanged(event) {
        this.setState({
            displayName: event.target.value,
        });
    }

    private async createNewAPIKey(displayName: string) {
        const newApiKey = await ServiceWire.getSternumService().createAPIKey(
            ServiceWire.getClientsService().getSelectedClientId(),
            this.state.displayName
        );
        this.setState({
            newApiKey,
        });
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(newApiKeyModalStyle, { withTheme: true })(NewAPIKeyModal));
