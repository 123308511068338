import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@material-ui/core";

import { useCommonStyle } from "../../CommonStyle";
import { CrossIcon, InfoCircleIcon } from "../../SUI/SternumIcon";
import SternumImprovedButton from "../../SUI/SternumImprovedButton/SternumImprovedButton";
import { GlobalState } from "../../../lib/state/GlobalState";

import { useStyle } from "./EmailAlreadyUnsubscribedDialog.style";
import { EmailNotificationType } from "../../../lib/state/EmailNotificationSettings";

const DISPLAY_NAMES = {
    WeeklyReportV2: "routine report",
};

const mapStateToProps = (state: GlobalState, ownProps: EmailAlreadyUnsubscribedDialogProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

type EmailAlreadyUnsubscribedDialogPropsWithHOC = EmailAlreadyUnsubscribedDialogProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export interface EmailAlreadyUnsubscribedDialogProps {
    open: boolean;
    alreadyUnsibscribedFrom: EmailNotificationType[];
    onOKClick?: () => unknown;
}

function EmailAlreadyUnsubscribedDialogBaseComponent({
    open,
    alreadyUnsibscribedFrom,
    onOKClick,
}: EmailAlreadyUnsubscribedDialogPropsWithHOC) {
    const classes = useStyle();
    const classesCommon = useCommonStyle();

    const handleClose = () => {
        onOKClick();
    };

    return (
        <Dialog
            aria-labelledby="dialog-title"
            open={open}
            onClose={handleClose}
            disableEnforceFocus
            classes={{
                root: classes.root,
                paper: classes.paper,
            }}
        >
            <DialogTitle id="dialog-title">
                <div className={classNames(classes.title)}>
                    <Box display="flex" alignItems="center" gridColumnGap={8}>
                        <InfoCircleIcon color="#FFA337" />
                        <Typography variant={"h6"} className={classNames(classesCommon.extraBold)}>
                            You've unsubscribed from{" "}
                            {alreadyUnsibscribedFrom
                                .map((unsubscribed) => DISPLAY_NAMES[unsubscribed] ?? unsubscribed)
                                .join(", ")}
                        </Typography>
                    </Box>

                    <IconButton onClick={handleClose} size="small">
                        <CrossIcon width={16} height={16} color="#909090" />
                    </IconButton>
                </div>
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>
                <Typography className={classes.unsubscribeDescription}>
                    To subscribe, use the menu in settings
                </Typography>
            </DialogContent>

            <DialogActions className={classNames(classes.actions)}>
                <SternumImprovedButton
                    content="OK"
                    onClick={handleClose}
                    isDisabled={false}
                    isLoading={false}
                    buttonType="regularWithDisabled"
                    fullWidth={false}
                />
            </DialogActions>
        </Dialog>
    );
}

const EmailAlreadyUnsubscribedDialogComponent: React.FC<EmailAlreadyUnsubscribedDialogProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(EmailAlreadyUnsubscribedDialogBaseComponent);

export function EmailAlreadyUnsubscribedDialog(props: EmailAlreadyUnsubscribedDialogProps) {
    if (!props.open) {
        return null;
    }

    return <EmailAlreadyUnsubscribedDialogComponent {...props} />;
}
