import { PatternWithTraces } from "./Pattern";

export interface AnomaliesResponse {
    anomalies: Anomaly[];
    firstTraceTimestamp: number;
    lastTraceTimestamp: number;
    patterns: PatternWithTraces[];
}

export interface Anomaly {
    traceCategory: string;
    // expectedValue: number;
    shap: number;

    // always set that
    actualValue: number | null;

    // set one of these
    severeValue?: number;
    highValue?: number;
    normalValue?: number;
    firstQuartile?: number;
    thirdQuartile?: number;
    tooltipQuartileRange?: string;
    min?: number;
    max?: number;
    median?: number;
    labelColor?: string;
    iconDisabled?: boolean;
    zero: number; // always = 0
}

export function anomalyFromJSON(json_object: Object, related_entities_map: Object): Anomaly {
    const shap = json_object["shap"];

    const traceDefinitionId = json_object["trace_definition"]["entity_id"];

    const showWarning = json_object["value"] === 0 && json_object["median"] > 0;

    const flattenRange = json_object["max"] - json_object["min"] < 5;

    // default situation
    const anomaly: Anomaly = {
        shap,
        traceCategory: related_entities_map[traceDefinitionId]
            ? related_entities_map[traceDefinitionId]["display_name"]
            : "?",
        // expectedValue: json_object["expected_value"],
        actualValue: json_object["value"],
        // startExpectedValue: Math.max(json_object["expected_value"] - 0.2, 0),
        // endExpectedValue: json_object["expected_value"] + 0.2,
        // firstQuartile: Math.max(json_object["expected_value"] - EXPECTED_RANGE, 0),
        // thirdQuartile: json_object["expected_value"] + EXPECTED_RANGE,
        // min: Math.max(json_object["expected_value"] - EXPECTED_RANGE - 10, 0),
        // max: json_object["expected_value"] + EXPECTED_RANGE + 10,
        // median: json_object["expected_value"],
        firstQuartile: flattenRange ? json_object["median"] : Math.max(json_object["q1"], 0),
        thirdQuartile: flattenRange ? json_object["median"] : json_object["q3"],
        min: flattenRange ? json_object["median"] : Math.max(json_object["min"], 0),
        max: flattenRange ? json_object["median"] : json_object["max"],
        median: json_object["median"],
        tooltipQuartileRange:
            json_object["q1"] === json_object["q3"] ? json_object["q1"] : `${json_object["q1"]}-${json_object["q3"]}`,
        labelColor: "#BCBED2",
        iconDisabled: !showWarning,
        zero: 0,
    };

    // special cases
    if (shap > 1.2) {
        anomaly.highValue = anomaly.actualValue;

        if (showWarning) {
            anomaly.labelColor = "#FF5C5C";
        }
    } else {
        anomaly.normalValue = anomaly.actualValue;

        if (showWarning) {
            anomaly.labelColor = "#FFA337";
        }
    }

    return anomaly;
}
