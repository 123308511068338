import ConditionType from "../state/ConditionType";
import DeploymentEnvironmentType from "../state/DeploymentEnvironmentType";
import DevicesFilter from "../state/DevicesFilter";
import EntityType from "../state/EntityType";
import EventFilter from "../state/EventFilter";
import FieldType from "../state/FieldType";
import InputComponentType from "../state/InputComponentType";
import TraceInfo from "../state/TraceInfo";
import ArgumentRoleTypeUIConfiguration from "./ArgumentRoleTypeUIConfiguration";
import ExploitationTypeConfiguration from "./ExploitationTypeConfiguration";
import TraceEventTypeConfiguration from "./TraceEventTypeConfiguration";
import UpdateSourceTypeConfiguration from "./UpdateSourceTypeConfiguration";
import Utils from "./Utils";

export type DeploymentEnv = "development" | "stage" | "prod";

/**
 * Holds any configuration for the sternum app.
 */
class SternumConfiguration {
    /**
     * How long are the pages when we fetch data.
     */
    private static pageSize: number = 50;

    /**
     * Holds the deployment environment type.
     */
    private static deploymentEnvironmentType: DeploymentEnvironmentType;

    /**
     * If a security alert has happened, how long do we keep on telling the device is in security alert state.
     */
    private static securityAlertTimeWindowMillis: number = 30 * 24 * 60 * 60 * 1000; // 30 days

    /**
     * Holds the different fields for entities in Sternum.
     */
    private static entityFieldsMap: {} = {
        // User library.
        [EntityType.UsedLibrary]: {
            usedLibraryId: {
                apiName: "usedLibraryId",
                value: "usedLibraryId",
                label: "Used Library Id",
                fieldType: FieldType.STRING,
                inputTypeConfiguration: {
                    inputType: FieldType.STRING,
                    inputComponentType: InputComponentType.TEXT,
                },
            },
            name: {
                apiName: "name",
                value: "name",
                label: "Name",
                fieldType: FieldType.STRING,
                inputTypeConfiguration: {
                    inputType: FieldType.STRING,
                    inputComponentType: InputComponentType.TEXT,
                },
            },
            version: {
                apiName: "version",
                value: "version",
                label: "Version",
                fieldType: FieldType.STRING,
                inputTypeConfiguration: {
                    inputType: FieldType.STRING,
                    inputComponentType: InputComponentType.TEXT,
                },
            },
        },

        // Device.
        [EntityType.Device]: {
            name: {
                apiName: "name",
                value: "name",
                label: "Name",
                fieldType: FieldType.STRING,
                inputTypeConfiguration: {
                    inputType: FieldType.STRING,
                    inputComponentType: InputComponentType.TEXT,
                },
            },
            ipAddress: {
                apiName: "ipAddress",
                value: "ipAddress",
                label: "IP Address",
                fieldType: FieldType.STRING,
                inputTypeConfiguration: {
                    inputType: FieldType.STRING,
                    inputComponentType: InputComponentType.TEXT,
                },
            },
            operatingSystem: {
                apiName: "operatingSystem",
                value: "operatingSystem",
                label: "Operating System",
                fieldType: FieldType.STRING,
                inputTypeConfiguration: {
                    inputType: FieldType.STRING,
                    inputComponentType: InputComponentType.TEXT,
                },
            },
        },
    };

    /**
     * Holds the different conditions.
     */
    private static conditionsMap: {} = {
        [ConditionType.EQUALS]: {
            apiName: "EQUALS",
            value: ConditionType.EQUALS,
            label: "Equals",
            supportedTypesMap: {
                [FieldType.STRING]: true,
            },
            requiredValues: [
                {
                    apiName: "compareWithValue",
                    inputTypeConfiguration: {
                        takeFromFieldConfiguration: true,
                    },
                },
            ],
            optionalValues: [],
        },
        [ConditionType.NOT_EQUALS]: {
            apiName: "NOT_EQUALS",
            value: ConditionType.NOT_EQUALS,
            label: "Not Equals",
            supportedTypesMap: {
                [FieldType.STRING]: true,
            },
            requiredValues: [
                {
                    apiName: "compareWithValue",
                    inputTypeConfiguration: {
                        takeFromFieldConfiguration: true,
                    },
                },
            ],
            optionalValues: [],
        },
        [ConditionType.GREATER_THAN]: {
            apiName: "GREATER_THAN",
            value: ConditionType.GREATER_THAN,
            label: "Greater Than",
            supportedTypesMap: {
                [FieldType.STRING]: true,
            },
            requiredValues: [
                {
                    apiName: "compareWithValue",
                    inputTypeConfiguration: {
                        takeFromFieldConfiguration: true,
                    },
                },
            ],
            optionalValues: [],
        },
        [ConditionType.GREATER_THAN_OR_EQUALS]: {
            apiName: "GREATER_THAN_OR_EQUALS",
            value: ConditionType.GREATER_THAN_OR_EQUALS,
            label: "Greater Than Or Equals",
            supportedTypesMap: {
                [FieldType.STRING]: true,
            },
            requiredValues: [
                {
                    apiName: "compareWithValue",
                    inputTypeConfiguration: {
                        takeFromFieldConfiguration: true,
                    },
                },
            ],
            optionalValues: [],
        },

        [ConditionType.LESS_THAN]: {
            apiName: "LESS_THAN",
            value: ConditionType.LESS_THAN,
            label: "Less Than",
            supportedTypesMap: {
                [FieldType.STRING]: true,
            },
            requiredValues: [
                {
                    apiName: "compareWithValue",
                    inputTypeConfiguration: {
                        takeFromFieldConfiguration: true,
                    },
                },
            ],
            optionalValues: [],
        },
        [ConditionType.LESS_THAN_OR_EQUALS]: {
            apiName: "LESS_THAN_OR_EQUALS",
            value: ConditionType.LESS_THAN_OR_EQUALS,
            label: "Less Than Or Equals",
            supportedTypesMap: {
                [FieldType.STRING]: true,
            },
            requiredValues: [
                {
                    apiName: "compareWithValue",
                    inputTypeConfiguration: {
                        takeFromFieldConfiguration: true,
                    },
                },
            ],
            optionalValues: [],
        },
        [ConditionType.CONTAINS]: {
            apiName: "CONTAINS",
            value: ConditionType.CONTAINS,
            label: "Contains",
            supportedTypesMap: {
                [FieldType.STRING]: true,
            },
            requiredValues: [
                {
                    apiName: "compareWithValue",
                    inputTypeConfiguration: {
                        takeFromFieldConfiguration: true,
                    },
                },
            ],
            optionalValues: [],
        },
        [ConditionType.NOT_CONTAINS]: {
            apiName: "NOT_CONTAINS",
            value: ConditionType.NOT_CONTAINS,
            label: "Not Contains",
            supportedTypesMap: {
                [FieldType.STRING]: true,
            },
            requiredValues: [
                {
                    apiName: "compareWithValue",
                    inputTypeConfiguration: {
                        takeFromFieldConfiguration: true,
                    },
                },
            ],
            optionalValues: [],
        },
        [ConditionType.IS_EMPTY]: {
            apiName: "IS_EMPTY",
            value: ConditionType.IS_EMPTY,
            label: "Is Empty",
            supportedTypesMap: {
                [FieldType.STRING]: true,
            },
            doesNotRequireValue: true,
            requiredValues: [],
            optionalValues: [],
        },
        [ConditionType.IS_NOT_EMPTY]: {
            apiName: "IS_NOT_EMPTY",
            value: ConditionType.IS_NOT_EMPTY,
            label: "Is Not Empty",
            supportedTypesMap: {
                [FieldType.STRING]: true,
            },
            doesNotRequireValue: true,
            requiredValues: [],
            optionalValues: [],
        },
        // [ConditionType.MATCH]: {
        //     apiName: "MATCH",
        //     value: ConditionType.MATCH,
        //     label: "Match",
        //     supportedTypesMap: {
        //         [FieldType.STRING]: true,
        //     },
        //     requiredValues: [
        //         {
        //             apiName: "compareWithValue",
        //             inputTypeConfiguration: {
        //                 takeFromFieldConfiguration: true,
        //             },
        //         },
        //     ],
        //     optionalValues: [],
        // },
    };

    /**
     * Default condition for each field type.
     */
    private static fieldTypeToDefaultConditionTypeMap: {} = {
        [FieldType.STRING]: ConditionType.EQUALS,
    };

    /**
     * Map between api names to configuration.
     */
    private static entityFieldsApiNameToConfigurationMap: {} = {};

    /**
     * Map between api names to configuration.
     */
    private static conditionsApiNameToConfigurationMap: {} = {};

    /**
     * Map between supported type to api names.
     */
    private static supportedTypeToConditionApiNameMap: {} = {};

    /**
     * Defines the types of argument role types.
     */
    private static argumentRoleTypes: { [key: string]: ArgumentRoleTypeUIConfiguration } = {
        SYSTEM_ARG_ROLE_ACTION_STATUS: new ArgumentRoleTypeUIConfiguration(
            "SYSTEM_ARG_ROLE_ACTION_STATUS",
            null,
            (traceInfo) => "Action Status",
            false,
            null,
            (traceInfo) => false
        ),
    };

    /**
     * Defines the types of trace events.
     */
    private static traceEventTypes: {
        [key: string]: TraceEventTypeConfiguration;
    } = {
        exploitationAlert: new TraceEventTypeConfiguration(
            "EXPLOITATION_ALERT",
            "Attack Attempt",
            true,
            "Attack Attempt",
            false,
            {
                NAME: new ArgumentRoleTypeUIConfiguration(
                    "ARG_ROLE_NAME",
                    null,
                    (traceInfo) => "Function Name",
                    false,
                    (value) => value,
                    (traceInfo) => true
                ),
                ORIGINATING_CODE_ADDRESS: new ArgumentRoleTypeUIConfiguration(
                    "SYSTEM_ARG_ROLE_ORIGINATING_CODE_ADDRESS",
                    null,
                    (traceInfo) => "Function Address",
                    false,
                    null,
                    (traceInfo) => {
                        if (
                            traceInfo &&
                            traceInfo.traceArguments &&
                            traceInfo.traceArguments["EXPLOITATION_TYPE"] &&
                            traceInfo.traceArguments["EXPLOITATION_TYPE"].argumentValue
                        ) {
                            return (
                                traceInfo.traceArguments["EXPLOITATION_TYPE"].argumentValue !==
                                "UNAUTHORIZED_SYSTEM_OPERATION_ALERT"
                            );
                        } else {
                            return true;
                        }
                    }
                ),
                FAULT_ADDRESS: new ArgumentRoleTypeUIConfiguration(
                    "SYSTEM_ARG_ROLE_FAULT_ADDRESS",
                    null,
                    (traceInfo) => "Allocation Pointer",
                    false,
                    null,
                    (traceInfo) => {
                        if (
                            traceInfo &&
                            traceInfo.traceArguments &&
                            traceInfo.traceArguments["EXPLOITATION_TYPE"] &&
                            traceInfo.traceArguments["EXPLOITATION_TYPE"].argumentValue
                        ) {
                            return (
                                traceInfo.traceArguments["EXPLOITATION_TYPE"].argumentValue !==
                                "UNAUTHORIZED_SYSTEM_OPERATION_ALERT"
                            );
                        } else {
                            return true;
                        }
                    }
                ),
                EXPLOITATION_TYPE: new ArgumentRoleTypeUIConfiguration(
                    "EXPLOITATION_TYPE",
                    null,
                    (traceInfo) => "Exploitation Type",
                    true,
                    null,
                    (traceInfo) => true
                ),
            }
        ),
        validate: new TraceEventTypeConfiguration(
            "SYSTEM_TRACE_MEMORY_VALIDATION_FAILURE",
            "Illegal Memory Operation",
            false,
            null,
            true,
            {
                ALLOCATION_POINTER: new ArgumentRoleTypeUIConfiguration(
                    "SYSTEM_ARG_ROLE_ALLOCATION_POINTER_",
                    0,
                    (traceInfo) => "Allocation Pointer",
                    true,
                    null
                ),
                ALLOCATION_SIZE: new ArgumentRoleTypeUIConfiguration(
                    "SYSTEM_ARG_ROLE_ALLOCATION_SIZE",
                    1,
                    (traceInfo) => "Allocation Size",
                    true
                ),
            }
        ),
        preValidate: new TraceEventTypeConfiguration(
            "SYSTEM_TRACE_MEMORY_PREVALIDATION_FAILURE",
            "Illegal Memory Operation",
            false,
            null,
            true,
            {
                SYSTEM_ARG_ROLE_DESTINATION_ADDRESS: new ArgumentRoleTypeUIConfiguration(
                    "SYSTEM_ARG_ROLE_DESTINATION_ADDRESS",
                    0,
                    (traceInfo) => "Destination Address",
                    true,
                    null
                ),
                SYSTEM_ARG_ROLE_SOURCE_ADDRESS: new ArgumentRoleTypeUIConfiguration(
                    "SYSTEM_ARG_ROLE_SOURCE_ADDRESS",
                    1,
                    (traceInfo) => "Source Address",
                    false,
                    null
                ),
                SYSTEM_ARG_ROLE_COPY_SIZE: new ArgumentRoleTypeUIConfiguration(
                    "SYSTEM_ARG_ROLE_COPY_SIZE",
                    2,
                    (traceInfo) => "Illegal Copy Size",
                    true
                ),
                SYSTEM_ARG_ROLE_ALLOCATION_POINTER: new ArgumentRoleTypeUIConfiguration(
                    "SYSTEM_ARG_ROLE_ALLOCATION_POINTER",
                    3,
                    (traceInfo) => "Allocation Pointer",
                    true,
                    null
                ),
                SYSTEM_ARG_ROLE_ALLOCATION_SIZE: new ArgumentRoleTypeUIConfiguration(
                    "SYSTEM_ARG_ROLE_ALLOCATION_SIZE",
                    4,
                    (traceInfo) => "Allocation Size",
                    true
                ),
                SYSTEM_ARG_ROLE_ACTION_STATUS: new ArgumentRoleTypeUIConfiguration(
                    "SYSTEM_ARG_ROLE_ACTION_STATUS",
                    5,
                    (traceInfo) => "Action Status",
                    false,
                    null,
                    (traceInfo) => false
                ),
            }
        ),
        boot: new TraceEventTypeConfiguration("TRACE_BOOT", "Process Initialized", false, null, false, {
            NAME: new ArgumentRoleTypeUIConfiguration("ARG_ROLE_NAME", 0, (traceInfo) => "Process Name", false),
        }),
    };

    /**
     * Defines the types of exploitation alerts there are in Sternum.
     */
    private static exploitationTypes: {
        [key: string]: ExploitationTypeConfiguration;
    } = {
        flowIntegrityViolation: new ExploitationTypeConfiguration(
            "FLOW_INTEGRITY_VIOLATION_ALERT",
            "Flow Integrity Violation"
        ),
        stackCorruption: new ExploitationTypeConfiguration("STACK_CORRUPTION_ALERT", "Stack Corruption"),
        heapPreInformationLeak: new ExploitationTypeConfiguration(
            "HEAP_PRE_INFORMATION_LEAK_ALERT",
            "Heap Information Leak"
        ),
        heapCorruption: new ExploitationTypeConfiguration("HEAP_CORRUPTION_ALERT", "Heap Corruption"),
        heapPreCorruption: new ExploitationTypeConfiguration("HEAP_PRE_CORRUPTION_ALERT", "Heap Corruption"),
        heapDoubleFree: new ExploitationTypeConfiguration("HEAP_DOUBLE_FREE_ALERT", "Heap Corruption"),
        unauthorizedSystemOperation: new ExploitationTypeConfiguration(
            "UNAUTHORIZED_SYSTEM_OPERATION_ALERT",
            "Unauthorized System Operation"
        ),
        heapUseAfterFree: new ExploitationTypeConfiguration("HEAP_USE_AFTER_FREE_ALERT", "Heap Use After Free"),
        dataSectionPreCorruption: new ExploitationTypeConfiguration(
            "DATA_SECTION_PRE_CORRUPTION_ALERT",
            "Data Section Corruption"
        ),
        dataSectionPreInformationLeak: new ExploitationTypeConfiguration(
            "DATA_SECTION_PRE_INFORMATION_LEAK_ALERT",
            "Data Section Corruption"
        ),
    };

    /**
     * Defines the types of update sources there are in Sternum.
     */
    private static updateSourceTypes: {
        [key: string]: UpdateSourceTypeConfiguration;
    } = {
        mitre: new UpdateSourceTypeConfiguration("MITRE", "NIST"),
    };

    /**
     * Holds a map between a metric id and its devices list filter object.
     */
    private static keyMetricIdToDevicesListFilterMap: {
        [key: string]: DevicesFilter;
    } = {
        TOTAL_DEVICES: new DevicesFilter(null, null, null, null, null, null, null, null, null, null, true),
        DEVICES: new DevicesFilter(
            null,
            null,
            null,
            Utils.now() - 30 * 24 * 60 * 60 * 1000,
            null,
            null,
            null,
            null,
            null,
            null,
            null
        ),
        SECURITY_ALERTS: new DevicesFilter(
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            [new EventFilter("EXPLOITATION_ALERT", new Date(Utils.now() - 30 * 24 * 60 * 60 * 1000), null)],
            null,
            null,
            null
        ),
        COMPROMISED_DEVICES: new DevicesFilter(
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            [new EventFilter("EXPLOITATION_ALERT", new Date(Utils.now() - 30 * 24 * 60 * 60 * 1000), null)],
            null,
            null,
            null
        ),
        UPDATE_REQUESTED: new DevicesFilter(
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            [new EventFilter("TRACE_UPDATE_START", new Date(Utils.now() - 30 * 24 * 60 * 60 * 1000), null)],
            null,
            null,
            null
        ),
    };

    /**
     * Holds a map between api name of argument role type to its configuration.
     */
    private static argumentRoleTypeApiNameToConfigurationObject: {
        [key: string]: ArgumentRoleTypeUIConfiguration;
    } = {};

    /**
     * Holds a map between api name of event type to its configuration.
     */
    private static traceEventTypeApiNameToConfigurationObject: {
        [key: string]: TraceEventTypeConfiguration;
    } = {};

    /**
     * Holds a map between api name of exploitation type to its display name.
     */
    private static exploitationTypeApiNameToConfigurationObject: {
        [key: string]: ExploitationTypeConfiguration;
    } = {};

    /**
     * Holds a map between api name of update source type to its display name.
     */
    private static updateSourceTypeApiNameToConfigurationObject: {
        [key: string]: UpdateSourceTypeConfiguration;
    } = {};

    /**
     * Holds an array of event types that are attacks.
     */
    private static attackTraceEventTypes = [];

    /**
     * Acts as a static constructor.
     */
    static initialize() {
        // Initialize a map between argument role type api name to its configuration.
        for (let argumentRoleType in this.argumentRoleTypes) {
            if (this.argumentRoleTypes.hasOwnProperty(argumentRoleType)) {
                this.argumentRoleTypeApiNameToConfigurationObject[argumentRoleType] =
                    this.argumentRoleTypes[argumentRoleType];
            }
        }

        // Initialize a map between event type api name to its display name.
        for (let key in this.traceEventTypes) {
            if (this.traceEventTypes.hasOwnProperty(key)) {
                this.traceEventTypeApiNameToConfigurationObject[this.traceEventTypes[key].key] =
                    this.traceEventTypes[key];

                if (this.traceEventTypes[key].isAttackTrace) {
                    this.attackTraceEventTypes.push(this.traceEventTypes[key].key);
                }
            }
        }

        // Initialize a map between exploitation type api name to its display name.
        for (let key in this.exploitationTypes) {
            if (this.exploitationTypes.hasOwnProperty(key)) {
                this.exploitationTypeApiNameToConfigurationObject[this.exploitationTypes[key].key] =
                    this.exploitationTypes[key];
            }
        }

        // Initialize a map between update source type api name to its display name.
        for (let key in this.updateSourceTypes) {
            if (this.updateSourceTypes.hasOwnProperty(key)) {
                this.updateSourceTypeApiNameToConfigurationObject[this.updateSourceTypes[key].key] =
                    this.updateSourceTypes[key];
            }
        }

        // Creating entityFieldsApiNameToConfigurationMap.
        for (let entityType in this.entityFieldsMap) {
            if (this.entityFieldsMap.hasOwnProperty(entityType)) {
                this.entityFieldsApiNameToConfigurationMap[entityType] = {};
                let fieldsMap = this.entityFieldsMap[entityType];

                for (let key in fieldsMap) {
                    if (fieldsMap.hasOwnProperty(key)) {
                        let fieldConfigurationObject = fieldsMap[key];
                        this.entityFieldsApiNameToConfigurationMap[entityType][fieldConfigurationObject.apiName] =
                            fieldConfigurationObject;
                    }
                }
            }
        }

        // Creating supportedTypeToConditionApiNameMap.
        for (let key in this.conditionsMap) {
            if (this.conditionsMap.hasOwnProperty(key)) {
                let conditionConfigurationObject = this.conditionsMap[key];
                this.conditionsApiNameToConfigurationMap[conditionConfigurationObject.apiName] =
                    conditionConfigurationObject;

                for (let supportedType in conditionConfigurationObject.supportedTypesMap) {
                    if (
                        conditionConfigurationObject.supportedTypesMap.hasOwnProperty(supportedType) &&
                        conditionConfigurationObject.supportedTypesMap[supportedType]
                    ) {
                        if (!this.supportedTypeToConditionApiNameMap[supportedType]) {
                            this.supportedTypeToConditionApiNameMap[supportedType] = {};
                        }

                        this.supportedTypeToConditionApiNameMap[supportedType][conditionConfigurationObject.apiName] =
                            true;
                    }
                }
            }
        }
    }

    /**
     * Gets all the conditions relevant for given field type.
     */
    public static getConditions(fieldType: FieldType) {
        let relevantConditions = [];

        for (let key in this.conditionsMap) {
            if (this.conditionsMap.hasOwnProperty(key)) {
                let conditionConfigurationObject = this.conditionsMap[key];

                if (conditionConfigurationObject.supportedTypesMap[fieldType]) {
                    relevantConditions.push(conditionConfigurationObject);
                }
            }
        }

        return relevantConditions;
    }

    /**
     * Gets all the fields of given entity.
     */
    public static getEntityFields(entityType: EntityType) {
        return Utils.getMapValues(this.entityFieldsApiNameToConfigurationMap[entityType]);
    }

    /**
     * Gets entityFieldsApiNameToConfigurationMap.
     */
    public static getEntityFieldsApiNameToConfigurationMap() {
        return this.entityFieldsApiNameToConfigurationMap;
    }

    /**
     * Gets conditionsApiNameToConfigurationMap.
     */
    public static getConditionsApiNameToConfigurationMap() {
        return this.conditionsApiNameToConfigurationMap;
    }

    /**
     * Gets supportedTypeToConditionApiNameMap.
     */
    public static getSupportedTypeToConditionApiNameMap() {
        return this.supportedTypeToConditionApiNameMap;
    }

    /**
     * Gets fieldTypeToDefaultConditionTypeMap.
     */
    public static getFieldTypeToDefaultConditionTypeMap() {
        return this.fieldTypeToDefaultConditionTypeMap;
    }

    /**
     * Gets conditionsMap.
     */
    public static getConditionsMap() {
        return this.conditionsMap;
    }

    /**
     * Gets the configuration of a specific condition.
     */
    public static getCondition(conditionType: ConditionType) {
        return this.conditionsMap[conditionType];
    }

    /**
     * Gets the page size for fetching data.
     */
    public static getPageSize(): number {
        return this.pageSize;
    }

    /**
     * Gets the devices filter for given metric id.
     */
    public static getMetricDevicesFilter(metricId: string): DevicesFilter {
        if (this.keyMetricIdToDevicesListFilterMap[metricId]) {
            return this.keyMetricIdToDevicesListFilterMap[metricId];
        } else {
            return null;
        }
    }

    /**
     * Gets the security alert time window millis.
     */
    public static getSecurityAlertTimeWindowMillis(): number {
        return this.securityAlertTimeWindowMillis;
    }

    /**
     * Gets the trace event types defined in sternum.
     */
    public static getTraceEventTypes() {
        return this.traceEventTypes;
    }

    /**
     * Gets the exploitation types defined in sternum.
     */
    public static getExploitationTypes() {
        return this.exploitationTypes;
    }

    /**
     * Gets the trace event types defined in sternum.
     */
    public static getTraceEventTypesArray(): TraceEventTypeConfiguration[] {
        return Utils.getMapValues(this.traceEventTypes);
    }

    /**
     * Gets the configuration object for an argument role type.
     */
    public static getArgumentRoleTypeConfigurationObject(
        argumentRoleType: string,
        traceEventType?: string
    ): ArgumentRoleTypeUIConfiguration {
        // If we are given a trace event type, we will first try to see if we have the argument role configuration
        // configured for the trace. If we have that, we will return that - meaning, the configurtion of the argument
        // for the specific trace overrides all. Otherwise, we will return the default configuration for the argument
        // role type (which will be true for every trace the argument role type appears in).
        if (
            traceEventType &&
            this.traceEventTypeApiNameToConfigurationObject[traceEventType] &&
            this.traceEventTypeApiNameToConfigurationObject[traceEventType].argumentRoleTypesConfigurations &&
            this.traceEventTypeApiNameToConfigurationObject[traceEventType].argumentRoleTypesConfigurations[
                argumentRoleType
            ]
        ) {
            return this.traceEventTypeApiNameToConfigurationObject[traceEventType].argumentRoleTypesConfigurations[
                argumentRoleType
            ];
        } else {
            if (this.argumentRoleTypeApiNameToConfigurationObject[argumentRoleType]) {
                return this.argumentRoleTypeApiNameToConfigurationObject[argumentRoleType];
            } else {
                return null;
            }
        }
    }

    /**
     * Gets the explanation lines for an exploitation type.
     */
    public static getAttackExplanationLines(
        traceInfo: TraceInfo,
        exploitationType: string,
        isReport?: boolean
    ): string[] {
        const types = SternumConfiguration.getExploitationTypes();
        const functionName: string = traceInfo.getFunctionName();
        const displayFunctionData =
            functionName && isReport
                ? `${traceInfo.getOriginatingCodeAddressDisplay()} (${functionName})`
                : traceInfo.getOriginatingCodeAddressDisplay();

        switch (exploitationType) {
            case types.stackCorruption.displayName:
                return [
                    `The stack of the function ${displayFunctionData} was corrupted; this may indicate an attack attempt.`,
                    "You may investigate the events adjacent to this attack attempt to further understand its nature and origins.",
                    "You may also use this information to trace back the vulnerability.",
                ];
            case types.heapCorruption.displayName:
                return [
                    `The dynamic memory around the address ${traceInfo.getFaultAddressDisplay()} was corrupted by this function: ${displayFunctionData}; this may indicate an attack attempt.`,
                    "You may investigate the events adjacent to this attack attempt to further understand its nature and origins.",
                    `You may also use this information to trace back the vulnerability in the function: ${displayFunctionData} that caused this corruption.`,
                ];
            case types.heapPreCorruption.displayName:
                return [
                    `The dynamic memory around the address ${traceInfo.getFaultAddressDisplay()} was about to be corrupted by this function: ${displayFunctionData}; this may indicate an attack attempt.`,
                    "You may investigate the events adjacent to this attack attempt to further understand its nature and origins.",
                    `You may also use this information to trace back the vulnerability in the function: ${displayFunctionData} that would have caused this corruption.`,
                ];
            case types.heapDoubleFree.displayName:
                return [
                    `The dynamic memory around the address ${traceInfo.getFaultAddressDisplay()} was freed multiple times by this function: ${displayFunctionData}; this may indicate a double free vulnerability.`,
                    "You may investigate the events adjacent to this attack attempt to further understand its nature and origins.",
                    `You may also use this information to trace back the vulnerability in the function: ${displayFunctionData} that caused this corruption.`,
                ];
            case types.flowIntegrityViolation.displayName:
                return [
                    `The integrity of the device's execution was violated; the violation originated in this function: ${displayFunctionData}. This may indicate an attack attempt.`,
                    "You may investigate the events adjacent to this attack attempt to further understand its nature and origins.",
                    `You may also use this information to trace back the vulnerability in the function: ${displayFunctionData} that caused this corruption.`,
                ];
            case types.unauthorizedSystemOperation.displayName:
                return [
                    "An attempt to perform unauthorized operation on the device was made and blocked by EIV.",
                    'For more Information about the blocked operation and the violation made please go to "Adjacent Events" tab.',
                ];
            case types.heapPreInformationLeak.displayName:
                return [
                    `The dynamic memory around the address ${traceInfo.getFaultAddressDisplay()} was about to be leaked by this function: ${displayFunctionData}; this may indicate an attack attempt.`,
                    "You may investigate the events adjacent to this attack attempt to further understand its nature and origins.",
                    `You may also use this information to trace back the vulnerability in the function: ${displayFunctionData} that would have caused this corruption.`,
                ];
            case types.dataSectionPreCorruption.displayName:
                return [
                    `The memory around the address ${traceInfo.getFaultAddressDisplay()} was about to be corrupted by this function: ${displayFunctionData}; this may indicate an attack attempt.`,
                    "You may investigate the events adjacent to this attack attempt to further understand its nature and origins.",
                    `You may also use this information to trace back the vulnerability in the function: ${displayFunctionData} that would have caused this corruption.`,
                ];
            case types.dataSectionPreInformationLeak.displayName:
                return [
                    `The memory around the address ${traceInfo.getFaultAddressDisplay()} was about to be leaked by this function: ${displayFunctionData}; this may indicate an attack attempt.`,
                    "You may investigate the events adjacent to this attack attempt to further understand its nature and origins.",
                    `You may also use this information to trace back the vulnerability in the function: ${displayFunctionData} that would have caused this corruption.`,
                ];
            default:
                return [];
        }
    }

    /**
     * Gets the configuration object for a trace event type.
     */
    public static getTraceEventTypeConfigurationObject(traceEventType: string): TraceEventTypeConfiguration {
        if (this.traceEventTypeApiNameToConfigurationObject[traceEventType]) {
            return this.traceEventTypeApiNameToConfigurationObject[traceEventType];
        } else {
            return null;
        }
    }

    /**
     * Returns the map between exploitation type api name to display name.
     */
    public static getExploitationTypeApiNameToConfigurationObject() {
        return this.exploitationTypeApiNameToConfigurationObject;
    }

    /**
     * Returns the map between update source type api name to display name.
     */
    public static getUpdateSourceTypeApiNameToConfigurationObject() {
        return this.updateSourceTypeApiNameToConfigurationObject;
    }

    /**
     * Gets the types of trace events that indicate attack.
     */
    public static getAttackTraceEventTypes(): string[] {
        return this.attackTraceEventTypes;
    }

    public static getPLGEnabled(): boolean {
        return !!process.env.STERNUM_ENABLE_PLG;
    }

    public static getAnomalyInvestigationEnabled(): boolean {
        return !!process.env.STERNUM_ENABLE_ANOMALY_INVESTIGATION;
    }

    public static getSternumDocsUrl(): string {
        return process.env.STERNUM_DOCS_CENTER_URL;
    }

    public static getDeploymentEnv(): DeploymentEnv {
        return process.env.STERNUM_APP_ENV as DeploymentEnv;
    }
}

// Don't forget to call the static constructor for it to work!
SternumConfiguration.initialize();

export default SternumConfiguration;
