import { Checkbox, ListItemText, MenuItem, TextField, Tooltip, Typography } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { isEmpty, isEqual, noop, uniqueId } from "lodash";
import * as React from "react";
import SternumQueryField from "../../../lib/infra/SternumQueryField";
import SternumUtils from "../../../lib/infra/SternumUtils";
import ReceivedDefinitionsResponse from "../../../lib/services/events/ReceivedDefinitionsResponse";
import ReceivedDefinitionType from "../../../lib/services/events/ReceivedDefinitionType";
import ServiceWire from "../../../lib/services/ServiceWire";
import AggregatedTraceDefinition from "../../../lib/state/AggregatedTraceDefinition";
import DeviceDefinitionVersionInfo from "../../../lib/state/DeviceDefinitionVersionInfo";
import PercentileConfiguration from "../../../lib/state/PercentileConfiguration";
import SpecialField from "../../../lib/state/SpecialField";
import Button from "../../SUI/SternumImprovedButton/SternumImprovedButton";
import SternumDeviceEventsFilter from "../../../lib/state/SternumDeviceEventsFilter";
import SternumFilter from "../../../lib/state/SternumFilter";
import SternumQuery from "../../../lib/state/SternumQuery";
import AggregationFunctionType from "../../../lib/state/Visualisation/AggregationFunctionType";
import VisualisationDataSourceGroupBy, {
    getDefaultVisualisationConfigurationGroupBy,
} from "../../../lib/state/Visualisation/VisualisationConfigurationGroupBy";
import VisualisationType from "../../../lib/state/Visualisation/VisualisationType";
import ColorPicker from "../../ColorPicker/ColorPicker";
import SelectComponent from "../../DeviceDefinitionComponents/SelectComponent/SelectComponent";
import { BigPlusIcon, CategoryIcon, DeleteIcon, PlusFilledIcon } from "../../SUI/SternumIcon/SternumIcon";
import { UniqueCountProvider } from "../../UniqueCountQuery/UniqueCountProvider";
import {
    counterUpdate,
    formatGroupLabel,
    formatOptionLabel,
    UniqueCountDropdown,
} from "../../UniqueCountQuery/UniqueCountDropdown";
import UIDataVisualisationConfiguration from "../entities/UIDataVisualisationConfiguration";
import getAggregationFunctionSelectOptions from "../utils/getAggregationFunctionSelectOptions";
import getAggregationFunctionsMap from "../utils/getAggregationFunctionsMap";
import getArgumentDefinitionDisplayNameFromReceivedDefinitions from "../utils/getArgumentDefinitionDisplayNameFromReceivedDefinitions";
import getSpecialFieldSelectDisplay from "../utils/getSpecialFieldSelectDisplay";
import { getTimeSeriesGraphLabel } from "../utils/getTimeSeriesGraphLabel";
import getTraceDefinitionDisplayNameFromReceivedDefinitions from "../utils/getTraceDefinitionDisplayNameFromReceivedDefinitions";
import { isChangedDataSourceConfiguration } from "../utils/isChangedDataSourceConfiguration";
import visualisationConfigurationSingleDataSourceSingleStyle from "./VisualisationConfigurationSingleDataSourceSingleStyle";
import ConfigurationService from "../../../lib/services/ConfigurationService";
import { addCounters, DropdownCounterProvider } from "../../UniqueCountQuery/DropdownCounterProvider";
import Utils from "../../../lib/infra/Utils";
import Select from "react-select";

interface AppState {
    dataVisualisationConfiguration: UIDataVisualisationConfiguration;
    argumentDefinitionFields: SternumQueryField[];
    loadingArguments: boolean;
}

export interface AppProps extends WithStyles<typeof visualisationConfigurationSingleDataSourceSingleStyle> {
    dataSourceKey: string;
    lookedUpEntityId: string;
    traceDefinitionFields: AggregatedTraceDefinition[];
    argumentDefinitionFields: SternumQueryField[];
    dataVisualisationConfiguration: UIDataVisualisationConfiguration;
    groupBy: VisualisationDataSourceGroupBy;
    displayRemoveButton?: boolean;
    hideColorPicker?: boolean;
    hideGroupBy?: boolean;
    visualizationType: VisualisationType;
    onDataVisualisationConfigurationChanged: (dataVisualisationConfiguration) => void;
    onVisualisationConfigurationGroupByToggle: (groupBy: VisualisationDataSourceGroupBy) => void;
    onVisualisationConfigurationDeleted: () => void;
    onFilterAdd: (query: SternumQuery) => void;
    receivedDefinitionsResponse: ReceivedDefinitionsResponse;
    startTime: number;
    endTime: number;
    theme?;
    deviceDefinitionVersions: DeviceDefinitionVersionInfo[];
    onDeviceDefinitionVersionChange: (value: string[]) => void;
    allowGroupBy: boolean;
    // how many data sources are selected
    dataSourceCount: number;
    disableEditing?: boolean;
}

const fieldsWithoutArgumentSelect: AggregationFunctionType[] = [
    AggregationFunctionType.COUNT,
    AggregationFunctionType.UNIQUE_COUNT,
];

const defaultUniqueValueItem: AggregatedTraceDefinition = {
    label: ConfigurationService.getDeviceIdArgumentField().displayName,
    value: ConfigurationService.getDeviceIdArgumentField().id,
    isSpecialField: true,
    displayName: ConfigurationService.getDeviceIdArgumentField().displayName,
    isSternumTrigger: false,
};

class VisualisationConfigurationSingleDataSourceSingle extends React.Component<AppProps, AppState> {
    deviceOfDropdownRef = React.createRef<Select>();

    constructor(props: AppProps) {
        super(props);

        this.state = {
            dataVisualisationConfiguration: this.props.dataVisualisationConfiguration,
            argumentDefinitionFields: this.props.argumentDefinitionFields,
            loadingArguments: false,
        };
    }

    async componentDidMount(): Promise<void> {
        if (this.shouldLoadArguments()) {
            await this.loadReceivedDefinitions(this.state.dataVisualisationConfiguration.aggregatedTraceDefinitionId);
        }
    }

    async componentDidUpdate(prevProps, prevState, snapshot): Promise<void> {
        if (
            !isEqual(
                this.props.dataVisualisationConfiguration.deviceDefinitionVersionIds,
                prevProps.dataVisualisationConfiguration.deviceDefinitionVersionIds
            ) ||
            this.props.startTime !== prevProps.startTime ||
            this.props.endTime !== prevProps.endTime ||
            (this.props.visualizationType !== prevProps.visualizationType && this.shouldLoadArguments())
        ) {
            {
                await this.loadReceivedDefinitions(
                    this.state.dataVisualisationConfiguration.aggregatedTraceDefinitionId
                );
            }
        }
    }

    async UNSAFE_componentWillReceiveProps(nextProps: Readonly<AppProps>, nextContext: any) {
        if (
            isChangedDataSourceConfiguration(
                nextProps.dataVisualisationConfiguration,
                this.state.dataVisualisationConfiguration
            )
        ) {
            this.setState({
                dataVisualisationConfiguration: nextProps.dataVisualisationConfiguration,
            });
        }
    }

    render() {
        const { classes } = this.props;

        const arrowIcon = (
            <svg height="20" width="20" viewBox="0 0 20 20" className={classes.deviceProfileSelectIcon}>
                <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
            </svg>
        );

        const percentilesOptions: PercentileConfiguration[] = [...Array(100).keys()]
            .filter((key) => key % 5 === 0 && key > 0)
            .map((key) => {
                return {
                    value: key,
                    label: `${key}%`,
                };
            });

        const aggregationFunctionOption = {
            value: this.state.dataVisualisationConfiguration.aggregationFunctionType,
            label: getAggregationFunctionsMap()[this.state.dataVisualisationConfiguration.aggregationFunctionType]
                .label,
        };

        const isSpecialTraceDefinition = ServiceWire.getConfigurationService().isSpecialField(
            this.state.dataVisualisationConfiguration.aggregatedTraceDefinitionId
        );

        const traceDefinitionOption = this.getTraceDefinitionOption(
            aggregationFunctionOption.value,
            isSpecialTraceDefinition,
            false,
            classes
        );

        const isSpecialArgumentDefinition = ServiceWire.getConfigurationService().isSpecialField(
            this.state.dataVisualisationConfiguration.aggregatedArgumentDefinitionId
        );

        const argumentDefinitionOption = {
            value: this.state.dataVisualisationConfiguration.aggregatedArgumentDefinitionId || "",
            label: isSpecialArgumentDefinition
                ? getSpecialFieldSelectDisplay(
                      classes,
                      this.state.dataVisualisationConfiguration.aggregatedArgumentDefinitionDisplayName
                  )
                : this.state.dataVisualisationConfiguration.aggregatedArgumentDefinitionDisplayName || "",
        };

        const percentileOption = this.state.dataVisualisationConfiguration.percentile
            ? {
                  value: this.state.dataVisualisationConfiguration.percentile,
                  label: `${this.state.dataVisualisationConfiguration.percentile}%`,
              }
            : { value: "", label: "" };

        let traceDefinitionDropdown = (this.props.traceDefinitionFields || []).filter((traceDefinitionField) => {
            // It's Count or Unique Count operation, show all options
            if (
                (this.state.dataVisualisationConfiguration.aggregationFunctionType === AggregationFunctionType.COUNT ||
                    this.state.dataVisualisationConfiguration.aggregationFunctionType ===
                        AggregationFunctionType.UNIQUE_COUNT) &&
                !traceDefinitionField.isSternumTrigger
            ) {
                return traceDefinitionField;
            }
            // It's not Count or Unique count operation, hide special fields
            if (
                this.state.dataVisualisationConfiguration.aggregationFunctionType !== AggregationFunctionType.COUNT &&
                this.state.dataVisualisationConfiguration.aggregationFunctionType !==
                    AggregationFunctionType.UNIQUE_COUNT &&
                !traceDefinitionField.isSternumTrigger &&
                !traceDefinitionField.isSpecialField
            ) {
                return traceDefinitionField;
            }
        });

        if (this.props.traceDefinitionFields) {
            const filteredTraceDefinitionDropdown = traceDefinitionDropdown
                .filter((opt) => !!opt && !opt.isSpecialField)
                .map((opt) => ({ ...opt, metricType: "trace" }));
            traceDefinitionDropdown = [
                ...traceDefinitionDropdown.filter((opt) => !!opt && opt.isSpecialField),
                {
                    label: (
                        <span>
                            Traces{" "}
                            <span style={{ color: "#AAAAAA" }} className="ucd-group-counter">
                                &nbsp;({Utils.numberToHumanString(filteredTraceDefinitionDropdown.length)})
                            </span>
                        </span>
                    ),
                    options: filteredTraceDefinitionDropdown,
                },
            ] as any;
        }

        return (
            <div className={classNames(classes.flexVMiddle, classes.flexWrap)}>
                <Typography variant="body2" className={classNames(classes.marginRight, classes.flexNoShrink)}>
                    Device Profiles
                </Typography>

                <div className={classNames(classes.flexVMiddle, classes.marginRightMd)}>
                    <TextField
                        id="demo-mutiple-checkbox"
                        SelectProps={{
                            multiple: true,
                            renderValue: (selected) =>
                                this.state.dataVisualisationConfiguration.deviceDefinitionVersionNames.join(", "),
                            classes: {
                                select: classes.deviceProfileSelect,
                            },
                            className: classes.deviceProfileInput,
                            IconComponent: () => arrowIcon,
                            MenuProps: {
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                                "aria-label": "device profile menu",
                                classes: {
                                    list: classes.deviceProfileMenuList,
                                },
                                PaperProps: {
                                    className: classes.deviceProfileMenuRoot,
                                    style: {
                                        maxHeight: 300,
                                    },
                                },
                            },
                        }}
                        className={classes.deviceProfileSelectRoot}
                        select
                        disabled={this.props.disableEditing}
                        variant="outlined"
                        value={this.state.dataVisualisationConfiguration.deviceDefinitionVersionIds}
                        onChange={(e) => this.props.onDeviceDefinitionVersionChange(e.target.value as any)}
                    >
                        {this.props.deviceDefinitionVersions.map((version) => (
                            <MenuItem key={version.entityId} value={version.entityId}>
                                <Checkbox
                                    checked={this.state.dataVisualisationConfiguration.deviceDefinitionVersionIds.includes(
                                        version.entityId
                                    )}
                                />
                                <ListItemText primary={version.getVersionName()} />
                            </MenuItem>
                        ))}
                    </TextField>
                </div>

                <Typography variant="body2" className={classNames(classes.marginRight, classes.flexNoShrink)}>
                    Display the &nbsp;
                </Typography>

                {/* Aggregation function */}
                <div role="presentation" aria-label="select component" aria-description="aggregation select component">
                    <SelectComponent
                        type="outlined"
                        ariaLabel="aggregation checkbox"
                        name={"aggregationSelect"}
                        onFieldChange={(newValue, action) => this.onAggregatedFunctionSelected(newValue, action)}
                        placeHolder={"Select aggregation..."}
                        clearable={false}
                        searchable={true}
                        isDisabled={this.props.disableEditing}
                        className={classNames(classes.selectComponent, classes.marginRight)}
                        selectOptions={getAggregationFunctionSelectOptions().filter(
                            (aggregation) =>
                                !(
                                    // allow unique values only when data source count is 1
                                    // and group by is not enabled
                                    (
                                        aggregation.value === AggregationFunctionType.UNIQUE_COUNT &&
                                        (this.props.dataSourceCount > 1 || this.props.groupBy?.enabled)
                                    )
                                )
                        )}
                        selectedValue={aggregationFunctionOption}
                    />
                </div>
                {/* Percentile selection */}
                {this.state.dataVisualisationConfiguration.aggregationFunctionType === "PERCENTILE" && (
                    <div
                        role="presentation"
                        aria-label="select component"
                        aria-description="percentile select component"
                    >
                        <SelectComponent
                            type="outlined"
                            name={"percentileSelect"}
                            onFieldChange={(newValue, action) => this.onPercentileOptionSelected(newValue, action)}
                            placeHolder={"Select percentile..."}
                            clearable={false}
                            searchable={false}
                            isDisabled={this.props.disableEditing}
                            className={classNames(classes.selectComponent, classes.marginRight)}
                            selectOptions={percentilesOptions}
                            selectedValue={percentileOption}
                        />
                    </div>
                )}
                <Typography variant="body2" className={classNames(classes.marginRight)}>
                    of
                </Typography>
                {this.state.dataVisualisationConfiguration.aggregationFunctionType ===
                    AggregationFunctionType.UNIQUE_COUNT && (
                    <UniqueCountDropdown
                        selectedValue={this.props.dataVisualisationConfiguration.uniqueColumns?.[0]}
                        className={classNames(classes.selectComponent, classes.marginRight)}
                        onChange={(newValue, action) => {
                            const newDataVis = { ...this.props.dataVisualisationConfiguration };
                            if (!newDataVis.uniqueColumns) {
                                newDataVis.uniqueColumns = [];
                            }
                            // always the first index
                            const index = 0;
                            // refresh graph
                            newDataVis.uniqueColumns = [...newDataVis.uniqueColumns];
                            newDataVis.uniqueColumns[index] = newValue as AggregatedTraceDefinition;

                            // newDataVis.aggregatedTraceDefinitionDisplayName = newDataVis.uniqueColumns[index].label;
                            // newDataVis.aggregatedTraceDefinitionId = newDataVis.uniqueColumns[index].value;

                            this.setState(
                                {
                                    dataVisualisationConfiguration: newDataVis,
                                },
                                () => {
                                    this.props.onDataVisualisationConfigurationChanged(newDataVis);
                                    this.onAggregatedTraceDefinitionSelected(newDataVis.uniqueColumns[index], {
                                        action: "select-option",
                                    });
                                    this.onAggregatedArgumentDefinitionSelected(newDataVis.uniqueColumns[index], {
                                        action: "select-option",
                                    });
                                }
                            );
                        }}
                    />
                )}
                {/* Trace selection */}
                {this.state.dataVisualisationConfiguration.aggregationFunctionType !==
                    AggregationFunctionType.UNIQUE_COUNT && (
                    <DropdownCounterProvider>
                        {(metrics) => (
                            <div
                                role="presentation"
                                aria-label="select component"
                                aria-description="event select component"
                            >
                                <SelectComponent
                                    ref={this.deviceOfDropdownRef}
                                    onInputChange={counterUpdate(this.deviceOfDropdownRef)}
                                    onAfterInitialRender={() => counterUpdate(this.deviceOfDropdownRef)("")}
                                    type="outlined"
                                    name={"eventSelect"}
                                    onFieldChange={(newValue, action) =>
                                        this.onAggregatedTraceDefinitionSelected(newValue, action)
                                    }
                                    placeHolder={"Select event..."}
                                    clearable={false}
                                    searchable={true}
                                    isDisabled={this.props.disableEditing}
                                    className={classNames(classes.selectComponent, classes.marginRight)}
                                    selectOptions={addCounters(traceDefinitionDropdown, metrics)}
                                    selectedValue={traceDefinitionOption}
                                    formatGroupLabel={formatGroupLabel}
                                    formatOptionLabel={formatOptionLabel}
                                />
                            </div>
                        )}
                    </DropdownCounterProvider>
                )}
                {!fieldsWithoutArgumentSelect.includes(
                    this.state.dataVisualisationConfiguration.aggregationFunctionType
                ) && (
                    <Typography variant="body2" className={classNames(classes.marginRight)}>
                        :
                    </Typography>
                )}
                {/* Argument selection */}
                {!fieldsWithoutArgumentSelect.includes(
                    this.state.dataVisualisationConfiguration.aggregationFunctionType
                ) &&
                    (!this.state.loadingArguments ? (
                        <div role="presentation" aria-label="select argument">
                            <SelectComponent
                                type="outlined"
                                name={"argumentSelect"}
                                onFieldChange={(newValue, action) =>
                                    this.onAggregatedArgumentDefinitionSelected(newValue, action)
                                }
                                placeHolder={"Select argument..."}
                                clearable={false}
                                searchable={true}
                                isDisabled={this.props.disableEditing}
                                className={classNames(classes.selectComponent, classes.marginRight)}
                                selectOptions={this.state.argumentDefinitionFields}
                                selectedValue={argumentDefinitionOption}
                            />
                        </div>
                    ) : (
                        <div className={classNames(classes.marginRight, classes.loadingPlaceholder, "mod-input")} />
                    ))}
                {!this.props.hideColorPicker && (
                    <div role="presentation" aria-label="color picker" className={classNames(classes.marginRightXs)}>
                        <ColorPicker
                            color={this.state.dataVisualisationConfiguration.color}
                            disabled={this.props.disableEditing}
                            onChange={(color) => {
                                const newConfiguration = {
                                    ...this.state.dataVisualisationConfiguration,
                                    color: color,
                                };

                                this.setState(
                                    {
                                        dataVisualisationConfiguration: newConfiguration,
                                    },
                                    () => this.props.onDataVisualisationConfigurationChanged(newConfiguration)
                                );
                            }}
                        />
                    </div>
                )}

                {!this.props.hideGroupBy && (
                    <Tooltip
                        title={
                            this.props.disableEditing
                                ? ""
                                : !this.props.allowGroupBy
                                ? "Grouping is not allowed"
                                : this.props.groupBy?.enabled
                                ? "Disable group by"
                                : "Enable group by"
                        }
                        placement="top"
                    >
                        <div
                            className={classNames(
                                classes.groupByIconContainer,
                                this.props.groupBy?.enabled && "enabled",
                                !this.props.allowGroupBy && "not-allowed"
                            )}
                        >
                            <CategoryIcon
                                role="button"
                                aria-label="group by"
                                color={this.props.groupBy?.enabled ? "#fff" : "#221CB6"}
                                width={14}
                                onClick={
                                    !this.props.disableEditing && this.props.allowGroupBy
                                        ? this.handleGroupByClick
                                        : noop
                                }
                            />
                        </div>
                    </Tooltip>
                )}

                {!this.props.disableEditing &&
                    isEmpty(this.props.dataVisualisationConfiguration.appliedSternumQuery.filters) && (
                        <div className={classNames(classes.addFilteringButton)}>
                            <Button
                                buttonType="text:blue"
                                onClick={this.handleAddFilterClick}
                                content="Add Filtering"
                                icon={<BigPlusIcon width={16} />}
                                fullWidth={false}
                            />
                        </div>
                    )}

                {!this.props.disableEditing && this.props.displayRemoveButton && (
                    <DeleteIcon
                        color="#4F4F4F"
                        className={classNames(classes.removeIcon)}
                        onClick={() => this.props.onVisualisationConfigurationDeleted()}
                    />
                )}
            </div>
        );
    }

    private handleAddFilterClick = () => {
        const clonedQuery = this.props.dataVisualisationConfiguration.appliedSternumQuery.clone();
        clonedQuery.filters = [SternumFilter.getEmptyFilter(+uniqueId())];

        this.props.onFilterAdd(clonedQuery);
    };

    private handleGroupByClick = () => {
        // turn off group by if it was previously enabled
        this.props.onVisualisationConfigurationGroupByToggle(
            !this.props.groupBy?.enabled
                ? { ...getDefaultVisualisationConfigurationGroupBy(), ...this.props.groupBy, enabled: true }
                : { ...this.props.groupBy, enabled: false }
        );
    };

    private getDeviceDefinitionSelectList() {
        return this.props.deviceDefinitionVersions.map((deviceDefinitionVersion) => {
            return {
                label: deviceDefinitionVersion.getVersionName(),
                value: deviceDefinitionVersion.entityId,
            };
        });
    }

    /**
     * Check if arguments should be loaded by aggregation type, view type or trace definition type
     */
    private shouldLoadArguments(): boolean {
        return (
            this.state.dataVisualisationConfiguration.aggregationFunctionType !== "COUNT" ||
            !ServiceWire.getConfigurationService().isSpecialField(
                this.state.dataVisualisationConfiguration.aggregatedTraceDefinitionId
            )
        );
    }

    /**
     * Load Received definitions
     */
    private loadReceivedDefinitions = async (traceDefinitionId: string): Promise<void> => {
        // Load arguments only if it's not a special field
        if (!ServiceWire.getConfigurationService().isSpecialField(traceDefinitionId)) {
            this.setState({ loadingArguments: true });
            const filteredArgumentsResponse = await ServiceWire.getEventsApiService().getAllReceivedDefinitions(
                this.props.lookedUpEntityId,
                this.props.startTime,
                this.props.endTime,
                [ReceivedDefinitionType.ARGUMENT_DEFINITIONS],
                traceDefinitionId
            );

            this.setState({
                argumentDefinitionFields: SternumUtils.getQueriedFieldsFromReceivedDefinitions(
                    filteredArgumentsResponse.receivedArguments
                ),
                loadingArguments: false,
            });
        }
    };

    /**
     * Occurs once a trace definition select component option is executed.
     */
    private async onAggregatedTraceDefinitionSelected(selectedField, actionObject) {
        switch (actionObject.action) {
            case "select-option":
                const traceDefinitionId: string = selectedField.value;

                const specialField: SpecialField | undefined =
                    ServiceWire.getConfigurationService().getSpecialField(traceDefinitionId);

                const traceDefinitionDisplayName = specialField
                    ? specialField.displayName
                    : getTraceDefinitionDisplayNameFromReceivedDefinitions(
                          this.props.receivedDefinitionsResponse,
                          traceDefinitionId
                      );

                // Update sate only of selected trace name is changed
                if (
                    traceDefinitionDisplayName !==
                    this.state.dataVisualisationConfiguration.aggregatedTraceDefinitionDisplayName
                ) {
                    const newDataVisualisationConfiguration = {
                        ...this.state.dataVisualisationConfiguration,

                        aggregatedTraceDefinitionId: traceDefinitionId,
                        aggregatedTraceDefinitionDisplayName: traceDefinitionDisplayName,
                        aggregatedArgumentDefinitionId: "",
                        aggregatedArgumentDefinitionDisplayName: "",

                        entitiesFilter: new SternumDeviceEventsFilter(
                            this.state.dataVisualisationConfiguration.entitiesFilter.eventInterest,
                            this.state.dataVisualisationConfiguration.entitiesFilter.onlyAttackTraces,
                            this.state.dataVisualisationConfiguration.entitiesFilter.createdFrom,
                            this.state.dataVisualisationConfiguration.entitiesFilter.createdTo,
                            this.state.dataVisualisationConfiguration.entitiesFilter.lessThanId,
                            this.state.dataVisualisationConfiguration.entitiesFilter.greaterThanId,
                            this.state.dataVisualisationConfiguration.entitiesFilter.traceCategories,
                            this.state.dataVisualisationConfiguration.entitiesFilter.filterOnlyTriggers,
                            this.state.dataVisualisationConfiguration.entitiesFilter.sternumQuery,
                            [traceDefinitionId]
                        ),
                    };
                    newDataVisualisationConfiguration.dataSourceLabel = getTimeSeriesGraphLabel(
                        newDataVisualisationConfiguration.aggregationFunctionType,
                        newDataVisualisationConfiguration.aggregatedTraceDefinitionDisplayName,
                        newDataVisualisationConfiguration.aggregatedArgumentDefinitionDisplayName
                    );
                    newDataVisualisationConfiguration.graphLabel = getTimeSeriesGraphLabel(
                        newDataVisualisationConfiguration.aggregationFunctionType,
                        newDataVisualisationConfiguration.aggregatedTraceDefinitionDisplayName,
                        newDataVisualisationConfiguration.aggregatedArgumentDefinitionDisplayName,
                        newDataVisualisationConfiguration.deviceDefinitionVersionNames
                    );

                    this.setState(
                        {
                            dataVisualisationConfiguration: newDataVisualisationConfiguration,
                            argumentDefinitionFields: [],
                        },
                        () => this.props.onDataVisualisationConfigurationChanged(newDataVisualisationConfiguration)
                    );

                    if (this.state.dataVisualisationConfiguration.aggregationFunctionType !== "COUNT") {
                        await this.loadReceivedDefinitions(traceDefinitionId);
                    }

                    break;
                }
        }
    }

    /**
     * Occurs once an argument definition select component option is executed.
     */
    private onAggregatedArgumentDefinitionSelected(selectedField, actionObject) {
        switch (actionObject.action) {
            case "select-option":
                const argumentDefinitionId: string = selectedField.value;
                const specialField: SpecialField | undefined =
                    ServiceWire.getConfigurationService().getSpecialField(argumentDefinitionId);

                const argumentDefinitionDisplayName = specialField
                    ? specialField.displayName
                    : getArgumentDefinitionDisplayNameFromReceivedDefinitions(
                          this.props.receivedDefinitionsResponse,
                          argumentDefinitionId
                      );

                const newDataVisualisationConfiguration = {
                    ...this.state.dataVisualisationConfiguration,

                    aggregatedArgumentDefinitionId: argumentDefinitionId,
                    aggregatedArgumentDefinitionDisplayName: argumentDefinitionDisplayName,
                };

                newDataVisualisationConfiguration.dataSourceLabel = getTimeSeriesGraphLabel(
                    newDataVisualisationConfiguration.aggregationFunctionType,
                    newDataVisualisationConfiguration.aggregatedTraceDefinitionDisplayName,
                    newDataVisualisationConfiguration.aggregatedArgumentDefinitionDisplayName
                );
                newDataVisualisationConfiguration.graphLabel = getTimeSeriesGraphLabel(
                    newDataVisualisationConfiguration.aggregationFunctionType,
                    newDataVisualisationConfiguration.aggregatedTraceDefinitionDisplayName,
                    newDataVisualisationConfiguration.aggregatedArgumentDefinitionDisplayName,
                    newDataVisualisationConfiguration.deviceDefinitionVersionNames
                );

                this.setState(
                    {
                        dataVisualisationConfiguration: newDataVisualisationConfiguration,
                    },
                    () => this.props.onDataVisualisationConfigurationChanged(newDataVisualisationConfiguration)
                );
                break;
        }
    }

    /**
     * Occurs on selection of percentile option.
     */
    private onPercentileOptionSelected(selectedField, actionObject) {
        switch (actionObject.action) {
            case "select-option":
                const newDataVisualisationConfiguration = {
                    ...this.state.dataVisualisationConfiguration,
                    percentile: selectedField.value,
                };

                newDataVisualisationConfiguration.dataSourceLabel = getTimeSeriesGraphLabel(
                    newDataVisualisationConfiguration.aggregationFunctionType,
                    newDataVisualisationConfiguration.aggregatedTraceDefinitionDisplayName,
                    newDataVisualisationConfiguration.aggregatedArgumentDefinitionDisplayName
                );
                newDataVisualisationConfiguration.graphLabel = getTimeSeriesGraphLabel(
                    newDataVisualisationConfiguration.aggregationFunctionType,
                    newDataVisualisationConfiguration.aggregatedTraceDefinitionDisplayName,
                    newDataVisualisationConfiguration.aggregatedArgumentDefinitionDisplayName,
                    newDataVisualisationConfiguration.deviceDefinitionVersionNames
                );

                this.setState(
                    {
                        dataVisualisationConfiguration: newDataVisualisationConfiguration,
                    },
                    () => this.props.onDataVisualisationConfigurationChanged(newDataVisualisationConfiguration)
                );
                break;
        }
    }

    /**
     * Occurs on th selection of an aggregation function.
     */
    private async onAggregatedFunctionSelected(selectedAggregationFunction, actionObject) {
        switch (actionObject.action) {
            case "select-option":
                const newDataVisualisationConfiguration = {
                    ...this.state.dataVisualisationConfiguration,
                    aggregationFunction: selectedAggregationFunction,
                    aggregationFunctionType: selectedAggregationFunction.value,
                };

                if (selectedAggregationFunction.value === AggregationFunctionType.UNIQUE_COUNT) {
                    newDataVisualisationConfiguration.uniqueColumns = [defaultUniqueValueItem];
                    newDataVisualisationConfiguration.aggregatedTraceDefinitionId =
                        ConfigurationService.getAllEventsFilterField().id; // needed for dashboard (saved graphs)
                    newDataVisualisationConfiguration.aggregatedTraceDefinitionDisplayName =
                        defaultUniqueValueItem.displayName;
                    newDataVisualisationConfiguration.aggregatedArgumentDefinitionId = "";
                    newDataVisualisationConfiguration.aggregatedArgumentDefinitionDisplayName = "";
                } else {
                    newDataVisualisationConfiguration.uniqueColumns = [];
                    newDataVisualisationConfiguration.aggregatedTraceDefinitionId =
                        ConfigurationService.getAllEventsFilterField().id;
                    newDataVisualisationConfiguration.aggregatedTraceDefinitionDisplayName =
                        ConfigurationService.getAllEventsFilterField().displayName;
                    newDataVisualisationConfiguration.aggregatedArgumentDefinitionId = "";
                    newDataVisualisationConfiguration.aggregatedArgumentDefinitionDisplayName = "";
                }

                newDataVisualisationConfiguration.dataSourceLabel = getTimeSeriesGraphLabel(
                    newDataVisualisationConfiguration.aggregationFunctionType,
                    newDataVisualisationConfiguration.aggregatedTraceDefinitionDisplayName,
                    newDataVisualisationConfiguration.aggregatedArgumentDefinitionDisplayName
                );
                newDataVisualisationConfiguration.graphLabel = getTimeSeriesGraphLabel(
                    newDataVisualisationConfiguration.aggregationFunctionType,
                    newDataVisualisationConfiguration.aggregatedTraceDefinitionDisplayName,
                    newDataVisualisationConfiguration.aggregatedArgumentDefinitionDisplayName,
                    newDataVisualisationConfiguration.deviceDefinitionVersionNames
                );
                this.setState(
                    {
                        dataVisualisationConfiguration: newDataVisualisationConfiguration,
                    },
                    () => this.props.onDataVisualisationConfigurationChanged(newDataVisualisationConfiguration)
                );

                if (
                    newDataVisualisationConfiguration.aggregationFunctionType !== "COUNT" &&
                    this.state.dataVisualisationConfiguration.aggregatedTraceDefinitionId
                ) {
                    await this.loadReceivedDefinitions(
                        this.state.dataVisualisationConfiguration.aggregatedTraceDefinitionId
                    );
                }
                break;
        }
    }

    /**
     * Get trace definition option by operation type
     */

    private getTraceDefinitionOption(
        aggregationType: string,
        isSpecialTraceDefinition: boolean,
        isValuesDisplay: boolean,
        classes
    ) {
        let traceDefinitionOption = null;

        if (aggregationType !== "COUNT" || isValuesDisplay) {
            traceDefinitionOption = {
                value: this.state.dataVisualisationConfiguration.aggregatedTraceDefinitionId,
                label: isSpecialTraceDefinition
                    ? ""
                    : this.state.dataVisualisationConfiguration.aggregatedTraceDefinitionDisplayName,
            };
        } else {
            traceDefinitionOption = {
                value: this.state.dataVisualisationConfiguration.aggregatedTraceDefinitionId,
                label: isSpecialTraceDefinition
                    ? getSpecialFieldSelectDisplay(
                          classes,
                          this.state.dataVisualisationConfiguration.aggregatedTraceDefinitionDisplayName
                      )
                    : this.state.dataVisualisationConfiguration.aggregatedTraceDefinitionDisplayName,
                metricType: isSpecialTraceDefinition ? undefined : "trace",
            };
        }

        return traceDefinitionOption;
    }
}

export default withStyles(visualisationConfigurationSingleDataSourceSingleStyle, { withTheme: true })(
    VisualisationConfigurationSingleDataSourceSingle
);
