import Icon from "@material-ui/core/Icon/Icon";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import * as React from "react";
import keyMetricStyle from "./KeyMetricStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof keyMetricStyle> {
    metricId: string;
    metricValue: string;
    deltaValue: number;
    captionText: string;
    isPositive: boolean;
    isNegative: boolean;
    isDeltaPositive: boolean;
    isDeltaNegative: boolean;
    isSelected: boolean;
    onKeyMetricClicked?: (metricId: string) => void;
    notClickable?: boolean;
    isCursorAllowed?: boolean;
    size?: "large" | "medium" | "small";
}

/**
 * Displays a single key metric in sternum.
 */
class KeyMetric extends React.Component<AppProps, AppState> {
    static defaultProps: Partial<AppProps> = {
        size: "large",
    };

    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            isSelected: this.props.isSelected,
        };
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        return (
            <div
                key={this.props.metricId}
                className={classNames(
                    classes.root,
                    this.props.isSelected && classes.selected,
                    this.props.notClickable && "mod-not-clickable",
                    this.props.isCursorAllowed && "mod-cursor-only",
                    `mod-size-${this.props.size}`
                )}
                onClick={() => this.handleKeyMetricClicked()}
            >
                {/* Metric value */}
                <Typography
                    variant={"h4"}
                    className={classNames(
                        classes.content,
                        this.props.isPositive && classes.positive,
                        this.props.isNegative && classes.negative
                    )}
                >
                    {this.props.metricValue}
                </Typography>

                {/* Caption container */}
                <div className={classes.captionContainer}>
                    {/* Text */}
                    <Typography className={classes.captionText} variant={"caption"}>
                        {this.props.captionText}
                    </Typography>

                    {/* Icon for delta increase/decrease */}
                    {!!this.props.deltaValue && (
                        <Icon
                            className={classNames(
                                classes.arrowIcon,
                                this.props.deltaValue > 0 && "fa fa-long-arrow-alt-up",
                                this.props.deltaValue < 0 && "fa fa-long-arrow-alt-down",
                                this.props.isDeltaPositive && classes.positive,
                                this.props.isDeltaNegative && classes.negative
                            )}
                        />
                    )}

                    {/* Delta value */}
                    {!!this.props.deltaValue && (
                        <Typography variant="body2" className={classes.deltaValue}>
                            {this.props.deltaValue}%
                        </Typography>
                    )}
                </div>
            </div>
        );
    }

    /**
     * Occurs once key metric is clicked.
     */
    private handleKeyMetricClicked() {
        if (!this.props.notClickable && this.props.onKeyMetricClicked) {
            this.props.onKeyMetricClicked(this.props.metricId);
        }
    }
}

export default withStyles(keyMetricStyle)(KeyMetric);
