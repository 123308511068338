import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { Link, Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { History } from "history";

import { GlobalState } from "../../lib/state/GlobalState";
import { SignupContainer } from "../SignupContainer";
import { ArrowLeftIcon } from "../SUI/SternumIcon";
import { useDataPrivacyPageStyle } from "./DataPrivacyPage.style";
import { roundedSectionInformation, sternumDoesNot, userSpaceProcessEvents } from "./DataPrivacyPage.model";

export interface DataPrivacyPageProps {}

const mapStateToProps = (state: GlobalState, ownProps: DataPrivacyPageProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

type DataPrivacyPagePropsWithHOC = DataPrivacyPageProps &
    ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps> & { history: History };

function DataPrivacyPageComponent({ history }: DataPrivacyPagePropsWithHOC) {
    const classes = useDataPrivacyPageStyle();

    return (
        <SignupContainer className={classes.mainContainer} showBackgroundDecoration={false}>
            <div className={classes.root}>
                <div className={classes.content}>
                    <Link
                        className={classNames(classes.backButton)}
                        component="a"
                        variant="body2"
                        onClick={() => history.goBack()}
                    >
                        <ArrowLeftIcon className={classes.backButtonIcon} />
                        <span>Back</span>
                    </Link>

                    <Typography variant="h5" className={classes.mainTitle}>
                        Data Privacy and Encryption
                    </Typography>

                    <div className={classes.subTitleDescription}>
                        Sternum collects operational and OS-level information, which includes:
                    </div>

                    <div className={classes.textSection}>
                        <div className={classes.enumerationContainer}>
                            <div className={classes.enumerationTitle}>User Space Process Events</div>
                            <ul className={classes.enumerationItemContainer}>
                                {userSpaceProcessEvents.map((userSpaceProcessEvent) => (
                                    <li key={userSpaceProcessEvent} className={classes.enumerationItem}>
                                        {userSpaceProcessEvent}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className={classes.enumerationContainer}>
                            <div className={classes.enumerationTitle}>System-wide Information</div>
                            <ul className={classes.enumerationItemContainer}>
                                <li className={classes.enumerationItem}>CPU & Memory signals including processlist</li>
                            </ul>
                        </div>
                    </div>
                    <div className={classes.textSection}>
                        <div className={classes.enumerationContainer}>
                            <div className={classes.enumerationBigTitle}>
                                Sternum <b>DOES NOT:</b>
                            </div>
                            <ul className={classes.enumerationItemContainer}>
                                {sternumDoesNot.map((sternumDoesNotItem) => (
                                    <li key={sternumDoesNotItem} className={classes.enumerationItem}>
                                        {sternumDoesNotItem}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    {roundedSectionInformation.map((info) => (
                        <div key={info.title} className={classes.textSection}>
                            <div className={classes.roundedSection}>
                                <div className={classes.roundedSectionTitle}>{info.title}</div>
                                <div className={classes.roundedSectionDescription}>{info.description}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </SignupContainer>
    );
}

export const DataPrivacyPage: React.FC<DataPrivacyPageProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DataPrivacyPageComponent));
