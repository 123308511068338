import React from "react";

import { SternumIconProps } from "./SternumIcon.types";

export const MonitorMobileIcon = React.forwardRef<SVGSVGElement, SternumIconProps>(function MonitorMobileIcon(
    { className, color, height = 18, width = 18, ...props },
    ref
) {
    return (
        <svg
            ref={ref}
            width={width}
            height={height}
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.52869 3.79602C2.23423 4.09047 2.0625 4.65672 2.0625 5.92483V10.4248C2.0625 11.6929 2.23423 12.2592 2.52869 12.5536C2.82314 12.8481 3.38939 13.0198 4.6575 13.0198H7.5C7.81066 13.0198 8.0625 13.2717 8.0625 13.5823C8.0625 13.893 7.81066 14.1448 7.5 14.1448H4.6575C3.39811 14.1448 2.38561 14.0016 1.73319 13.3491C1.08077 12.6967 0.9375 11.6842 0.9375 10.4248V5.92483C0.9375 4.66544 1.08077 3.65295 1.73319 3.00052C2.38561 2.3481 3.39811 2.20483 4.6575 2.20483H12.555C13.8144 2.20483 14.8269 2.3481 15.4793 3.00052C16.1317 3.65295 16.275 4.66544 16.275 5.92483C16.275 6.23549 16.0232 6.48733 15.7125 6.48733C15.4018 6.48733 15.15 6.23549 15.15 5.92483C15.15 4.65672 14.9783 4.09047 14.6838 3.79602C14.3894 3.50157 13.8231 3.32983 12.555 3.32983H4.6575C3.38939 3.32983 2.82314 3.50157 2.52869 3.79602Z"
                fill={color || "currentColor"}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.5 13.0198C7.81066 13.0198 8.0625 13.2717 8.0625 13.5823V16.9723C8.0625 17.283 7.81066 17.5348 7.5 17.5348C7.18934 17.5348 6.9375 17.283 6.9375 16.9723V13.5823C6.9375 13.2717 7.18934 13.0198 7.5 13.0198Z"
                fill={color || "currentColor"}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.9375 10.5823C0.9375 10.2717 1.18934 10.0198 1.5 10.0198H7.5C7.81066 10.0198 8.0625 10.2717 8.0625 10.5823C8.0625 10.893 7.81066 11.1448 7.5 11.1448H1.5C1.18934 11.1448 0.9375 10.893 0.9375 10.5823Z"
                fill={color || "currentColor"}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.4925 16.9723C4.4925 16.6617 4.74434 16.4098 5.055 16.4098H7.5C7.81066 16.4098 8.0625 16.6617 8.0625 16.9723C8.0625 17.283 7.81066 17.5348 7.5 17.5348H5.055C4.74434 17.5348 4.4925 17.283 4.4925 16.9723Z"
                fill={color || "currentColor"}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.2387 9.09102C10.0849 9.2448 9.96 9.57658 9.96 10.4698V14.7523C9.96 15.6456 10.0849 15.9774 10.2387 16.1311C10.3925 16.2849 10.7242 16.4098 11.6175 16.4098H14.28C15.1733 16.4098 15.505 16.2849 15.6588 16.1311C15.8126 15.9774 15.9375 15.6456 15.9375 14.7523V10.4698C15.9375 9.57658 15.8126 9.2448 15.6588 9.09102C15.505 8.93724 15.1733 8.81233 14.28 8.81233H11.6175C10.7242 8.81233 10.3925 8.93724 10.2387 9.09102ZM9.44319 8.29552C9.95503 7.78368 10.7333 7.68733 11.6175 7.68733H14.28C15.1642 7.68733 15.9425 7.78368 16.4543 8.29552C16.9662 8.80737 17.0625 9.58559 17.0625 10.4698V14.7523C17.0625 15.6366 16.9662 16.4148 16.4543 16.9266C15.9425 17.4385 15.1642 17.5348 14.28 17.5348H11.6175C10.7333 17.5348 9.95503 17.4385 9.44319 16.9266C8.93135 16.4148 8.835 15.6366 8.835 14.7523V10.4698C8.835 9.58559 8.93135 8.80737 9.44319 8.29552Z"
                fill={color || "currentColor"}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.1834 14.5573C12.1834 14.1431 12.5192 13.8073 12.9334 13.8073H12.9401C13.3543 13.8073 13.6901 14.1431 13.6901 14.5573C13.6901 14.9715 13.3543 15.3073 12.9401 15.3073H12.9334C12.5192 15.3073 12.1834 14.9715 12.1834 14.5573Z"
                fill={color || "currentColor"}
            />
        </svg>
    );
});
