import { Theme } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

let issuesPageStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        container: {
            marginTop: theme.spacing(3),
        },

        issuesTitle: {
            padding: theme.spacing(0, 4),
            fontWeight: 600,
        },

        metricDataTile: {
            marginTop: theme.spacing(2),
        },

        tableContainer: {
            height: "calc(100vh - 105px)",
            padding: theme.spacing(4),
            paddingTop: 0,
            width: "auto",
            overflow: "auto",
            maxHeight: "100%",
        },

        tableInner: {
            height: "100%",
            padding: theme.spacing(3),
            background: "#fff",
            boxShadow: "0px 0px 23px rgba(231, 232, 235, 0.25)",
            borderRadius: 14,
            borderTopLeftRadius: 0,
        },

        issueTabsContainer: {
            marginTop: theme.spacing(2),
            padding: theme.spacing(0, 4),
        },

        dataTileTitle: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            paddingLeft: theme.spacing(2),
            fontWeight: 500,

            "&.mod-no-padding": {
                padding: 0,
            },
        },
        securityBreachesTitle: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(1),
            paddingLeft: 0,

            fontWeight: 500,

            "&.mod-no-padding": {
                padding: 0,
            },
        },
        pendingIssuesTitleContainer: {
            marginLeft: "16px",
            //marginTop: "16px",

            "&:hover .expand-text-typo": {
                visibility: "visible",
            },
            "&.mod-expanded": {
                paddingTop: "16px",
                position: "fixed",
                background: theme.palette.common.white,
            },
        },
        expandText: {
            cursor: "pointer",
            fontSize: "0.8125rem",
            fontWeight: 400,
            "&:hover": {
                textDecoration: "underline",
            },
            //visibility: "hidden",
        },
        infoCircleIconSize: {
            fontSize: "16px",
        },
        explanationTypography: {
            width: theme.spacing(40),
        },
        mapContainer: {},
        mapTitle: {
            marginBottom: theme.spacing(2),
            fontWeight: 500,
        },
    });

export default issuesPageStyle;
