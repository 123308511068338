import HashMap from "../infra/HashMap";
import HashSet from "../infra/HashSet";
import FetchError from "./FetchError";

/**
 * Represents the state of an entities holder.
 */
export interface EntitiesState<T> {
    /**
     * Map between entity id and entity.
     */
    idToEntityMap: HashMap<T>;

    /**
     * Hash set of all entities being currently loaded.
     */
    loadingIds: HashSet;

    /**
     * Map between entity ids and their error object.
     */
    errorIds: HashMap<FetchError>;

    /**
     * Most recent device count detected for the user. Can be null, as we not
     * always know it up front (we don't always poll for this value). Null value
     * just means we have not polled for this value yet.
     */
    detectedDeviceCount: null | number;
}

/**
 * Gets the default entities state object.
 */
export function getDefaultEntitiesState<T>(): EntitiesState<T> {
    return {
        idToEntityMap: new HashMap<T>(),
        loadingIds: new HashSet(),
        errorIds: new HashMap<FetchError>(),
        detectedDeviceCount: null,
    };
}
