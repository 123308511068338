import ArgumentInfo from "./ArgumentInfo";

/**
 * Represents a metric in sternum application.
 */
class MetricInfo {
    /**
     * Constructor.
     */
    constructor(
        public metricId: string,
        public metricValue: string,
        public deltaValue: number,
        public captionText: string,
        public isPositive: boolean,
        public isNegative: boolean,
        public isDeltaPositive: boolean,
        public isDeltaNegative: boolean,
        public isSelected: boolean = false
    ) {}

    /**
     * Converts json to metric info object.
     */
    public static fromJsonObject(jsonObject: Object) {
        return new MetricInfo(
            jsonObject["metric_id"],
            jsonObject["metric_value"],
            jsonObject["delta_value"],
            jsonObject["caption_text"],
            jsonObject["is_positive"],
            jsonObject["is_negative"],
            jsonObject["is_delta_positive"],
            jsonObject["is_delta_negative"],
            false
        );
    }
}

export default MetricInfo;
