import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { History } from "history";
import classNames from "classnames";

import { DashboardProps } from "../../Dashboard.component";
import { GlobalState } from "../../../../lib/state/GlobalState";
import ServiceWire from "../../../../lib/services/ServiceWire";
import SternumLink from "../../../SUI/SternumLink/SternumLink";
import { DiamondIcon } from "../../../SUI/SternumIcon";
import SpinnerLoader from "../../../SUI/SternumLoaders/SpinnerLoader";
import { DeviceTiles } from "../DeviceTiles";
import { Title } from "../Title";
import { Button } from "./Button";
import { useDevices } from "./ConnectedDevices.hook";
import { useConnectedDevicesStyle } from "./ConnectedDevices.style";
import { openGoProModal } from "../../../../lib/redux/modals/OpenModalAction";
import { sortBy } from "lodash";

export interface ConnectedDevicesProps {}

const mapStateToProps = (state: GlobalState, ownProps: DashboardProps) => {
    const selectedClient = ServiceWire.getClientsService().getSelectedClient();
    const maxDevicesToConnect = selectedClient.limits?.devicesLimit || 0;
    const usedDevicesCount = selectedClient.usage?.devices || 0;

    return {
        maxDevicesToConnect,
        isMaxDevicesReached: usedDevicesCount >= maxDevicesToConnect,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        openGoProModal: (key: string) => dispatch(openGoProModal(key)),
    };
};

type ConnectedDevicesPropsWithHOC = ConnectedDevicesProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
        history: History;
    };

function ConnectedDevicesComponent({
    maxDevicesToConnect,
    isMaxDevicesReached,
    history,
    openGoProModal,
}: ConnectedDevicesPropsWithHOC) {
    const classes = useConnectedDevicesStyle();
    const { devices, areDevicesLoading } = useDevices(maxDevicesToConnect);

    const renderDeviceTiles = () => (
        <DeviceTiles>
            {sortBy(devices, ["created"]).map((device) => (
                <DeviceTiles.Item key={device.entityId} device={device} />
            ))}
            {!isMaxDevicesReached && (
                <DeviceTiles.AddItem onClick={() => history.push("/settings")}>Add device</DeviceTiles.AddItem>
            )}
        </DeviceTiles>
    );

    return (
        <div className={classes.root}>
            <Title>Connected Devices</Title>
            <div className={classNames(classes.descriptionText, classes.marginTop, classes.marginBottom)}>
                You can add for free up to {maxDevicesToConnect} devices
            </div>
            {areDevicesLoading ? <SpinnerLoader className={classes.spinner} /> : renderDeviceTiles()}
            {isMaxDevicesReached && (
                <a href="/premium" target="_blank" className={classes.protectMoreLink}>
                    <Button>
                        <DiamondIcon />
                        <span>Protect more devices</span>
                    </Button>
                </a>
            )}
            <div className={classNames(classes.descriptionText, classes.centered)}>
                You can add for free up to {maxDevicesToConnect} devices
            </div>
            {!isMaxDevicesReached && (
                <div className={classes.linkContainer}>
                    <SternumLink className={classes.link} onClick={() => openGoProModal("goProModal")}>
                        Add non-OpenWrt Linux or RTOS device
                    </SternumLink>
                </div>
            )}
        </div>
    );
}

export const ConnectedDevices: React.FC<ConnectedDevicesProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ConnectedDevicesComponent));
