import React, { useRef, useState } from "react";
import classNames from "classnames";
import { useFormik } from "formik";
import { withRouter } from "react-router-dom";
import { History } from "history";
import { Box, Checkbox, FormControlLabel, Link, Typography } from "@material-ui/core";

import GoogleRecaptchaWrapper from "../../GoogleRecaptcha/GoogleRecaptcha";
import { SternumInputField } from "../../SUI/SternumInputField";
import SternumImprovedButton from "../../SUI/SternumImprovedButton/SternumImprovedButton";
import SternumBanner from "../../SUI/SternumBanner/SternumBanner";
import { useCommonStyle } from "../../CommonStyle";
import { ArrowLeftIcon, CheckFilledIcon } from "../../SUI/SternumIcon";
import SternumLink from "../../SUI/SternumLink/SternumLink";
import SternumModal from "../../SUI/SternumModal/SternumModal";
import { ResearchNumberWithContent } from "../../ResearchNumberWithContent";
import { TermsConditionsModal } from "../../TermsConditionsModal/TermsConditionsModal";
import ServiceWire from "../../../lib/services/ServiceWire";
import { SignupContainer } from "../../SignupContainer";
import { useSignupManagerStyle } from "../SignupManager.style";
import { useSignupStyle } from "./Signup.style";
import { backendInvestors, signupInformation, trustedIndustryLeaders } from "./Signup.model";

export interface SignupComponentProps {
    onFormSubmitSuccess: (values: FormValues) => unknown;
}

interface FormValues {
    name: string;
    email: string;
    jobTitle: string;
    company: string;

    termsAgreed: boolean;
    captchaToken: "";
}

type SignupComponentPropsWithHOC = SignupComponentProps & { history: History };

function SignupComponent({ onFormSubmitSuccess, history }: SignupComponentPropsWithHOC) {
    const reCaptchaElementRef = useRef();
    const classes = useSignupStyle();
    const classesSignup = useSignupManagerStyle();
    const classesCommon = useCommonStyle();

    const [isTermsModalOpen, setTermsModalOpen] = useState(false);

    const [errorMsg, setErrorMsg] = useState("");
    const savedFormState = ServiceWire.getClientsService().getSignupFormState();
    const formik = useFormik<FormValues>({
        initialValues: {
            email: savedFormState?.email || "",
            company: savedFormState?.company || "",
            jobTitle: savedFormState?.jobTitle || "",
            name: savedFormState?.name || "",
            termsAgreed: savedFormState?.termsAgreed || false,
            captchaToken: "",
        },
        validate: (values) => {
            const errors: Partial<FormValues> = {};

            if (!values.name.trim()) {
                errors.name = "Name is required";
            }

            if (!values.email.trim()) {
                errors.email = "Email is required";
            }

            return errors;
        },
        onSubmit: async (values) => {
            try {
                await ServiceWire.getSternumService().signUp(
                    values.email,
                    values.name,
                    values.company,
                    values.jobTitle,
                    values.termsAgreed,
                    values.captchaToken
                );

                onFormSubmitSuccess(values);
                ServiceWire.getClientsService().deleteSignupFormState();
            } catch (err) {
                if (err?.response?.data?.error?.message) {
                    return setErrorMsg(err?.response?.data?.error?.message);
                }

                if (err?.response?.status === 429) {
                    return setErrorMsg("Max number of requests per day has been reached.");
                }

                return setErrorMsg("Internal server error");
            }
        },
    });

    const handleChange: typeof formik.handleChange = (event) => {
        formik.handleChange(event);
    };

    const handleBlur = () => {
        const { captchaToken, ...rest } = formik.values;
        ServiceWire.getClientsService().setSignupFormState(rest);
    };

    const handleRecaptchaSuccess = (reCaptchaToken: string) => {
        // Here you will get the final reCaptchaToken
        formik.setFieldValue("captchaToken", reCaptchaToken);
    };

    const handleRecaptchaError = () => {
        formik.setFieldValue("captchaToken", "");
    };

    const handleTermsAgreed = () => {
        formik.setFieldValue("termsAgreed", true);
        setTermsModalOpen(false);
    };

    const isSubmitButtonDisabled =
        !formik.values.termsAgreed || formik.isSubmitting || Object.keys(formik.errors).length > 0;

    return (
        <SignupContainer>
            <div className={classes.content}>
                <div className={classes.descriptionContent}>
                    <a
                        className={classNames(classes.backButton, classesCommon.cursorPointer)}
                        href="https://www.sternumiot.com"
                    >
                        <ArrowLeftIcon className={classes.backButtonIcon} />
                        <span>Back</span>
                    </a>
                    <h1 className={classes.mainTitle}>Free Runtime Security for Your OpenWrt Devices</h1>

                    <p className={classes.trialDescription}>
                        <div className={classes.trialDescriptionItem}>
                            Sternum integrates with IoT devices to deliver security and observability previously thought
                            impossible.
                        </div>
                        <div className={classes.trialDescriptionItem}>
                            Fill the form to download and deploy Sternum{"\u2019"}s EIV™ software technologies for free
                            on OpenWrt devices. For non-OpenWrt Linux or RTOS devices, please{" "}
                            <SternumLink href="https://www.sternumiot.com/contact" target="_blank">
                                contact us here
                            </SternumLink>
                        </div>
                    </p>

                    <div className={classes.featuresContainer}>
                        {signupInformation.map((info, index) => (
                            <div key={index} className={classes.featureItem}>
                                <CheckFilledIcon className={classes.featureItemIcon} />
                                <div className={classes.featureText}>{info}</div>
                            </div>
                        ))}
                    </div>

                    <Box mt={8}>
                        <Typography variant="body2">
                            “Sternum's approach is significantly different in that it provides immunity to exploits and
                            takes out the necessity to continuously patch vulnerabilities.”
                        </Typography>
                        <ResearchNumberWithContent className={classes.researchNumber} researchNumber={451}>
                            Research
                        </ResearchNumberWithContent>
                        <div className={classes.companyLogosGroup}>
                            <div className={classes.companyLogosItem}>
                                <div className={classes.companyLogosTitle}>Trusted by industry leaders</div>
                                <div className={classes.companyLogos}>
                                    {trustedIndustryLeaders.map((trustedIndustryLeader) => (
                                        <div id={trustedIndustryLeader.id} className={classes.companyLogo}>
                                            <img
                                                className={classes.companyLogoImage}
                                                src={trustedIndustryLeader.logoSrc}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className={classes.companyLogosItem}>
                                <div className={classes.companyLogosTitle}>Backed by world-class investors</div>
                                <div className={classes.companyLogos}>
                                    {backendInvestors.map((backendInvestor) => (
                                        <div id={backendInvestor.id} className={classes.companyLogo}>
                                            <img
                                                className={classes.backendInvestorImage}
                                                src={backendInvestor.logoSrc}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Box>
                </div>

                <div className={classes.signupFormContent}>
                    <h2 className={classNames(classes.signUpTitle)}>Sign up for a free license</h2>

                    <div>
                        {errorMsg && (
                            <div className={classNames(classesCommon.marginBottomXLarge, classesCommon.fullWidth)}>
                                <SternumBanner
                                    messageType="error"
                                    message={errorMsg}
                                    onCloseClick={() => setErrorMsg("")}
                                />
                            </div>
                        )}

                        {/* Full name input */}
                        <SternumInputField
                            label={"Full name"}
                            name="name"
                            inputValue={formik.values.name}
                            onFieldChange={handleChange}
                            isTouched={!!formik.touched.name}
                            error={!!formik.errors.name}
                            helperText={formik.touched.name ? formik.errors.name : undefined}
                            onTouch={() => {
                                formik.setFieldTouched("name", true);
                                handleBlur();
                            }}
                            required
                        />

                        {/* Email input */}
                        <SternumInputField
                            label={"Business email"}
                            name="email"
                            inputValue={formik.values.email}
                            onFieldChange={handleChange}
                            isTouched={!!formik.touched.email}
                            error={!!formik.errors.email}
                            helperText={formik.touched.email ? formik.errors.email : undefined}
                            onTouch={() => {
                                formik.setFieldTouched("email", true);
                                handleBlur();
                            }}
                            required
                        />

                        {/* Company input */}
                        <SternumInputField
                            label={"Company name"}
                            name="company"
                            inputValue={formik.values.company}
                            onFieldChange={handleChange}
                            isTouched={!!formik.touched.company}
                            error={!!formik.errors.company}
                            helperText={formik.touched.company ? formik.errors.company : undefined}
                            onTouch={() => {
                                formik.setFieldTouched("company", true);
                                handleBlur();
                            }}
                            required={false}
                        />

                        {/* Job title input */}
                        <SternumInputField
                            label={"Job title"}
                            name="jobTitle"
                            inputValue={formik.values.jobTitle}
                            onFieldChange={handleChange}
                            isTouched={!!formik.touched.jobTitle}
                            error={!!formik.errors.jobTitle}
                            helperText={formik.touched.jobTitle ? formik.errors.jobTitle : undefined}
                            onTouch={() => {
                                formik.setFieldTouched("jobTitle", true);
                                handleBlur();
                            }}
                            required={false}
                        />

                        {/* Terms & Conditions */}
                        <FormControlLabel
                            className={classes.termsCheckbox}
                            control={
                                <Checkbox
                                    checked={formik.values.termsAgreed}
                                    name="termsAgreed"
                                    onChange={(e) => {
                                        if (!formik.values.termsAgreed) {
                                            setTermsModalOpen(true);
                                        } else {
                                            formik.handleChange(e);
                                        }
                                    }}
                                    onBlur={handleBlur}
                                />
                            }
                            label={
                                <div>
                                    I agree to{" "}
                                    <SternumLink
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setTermsModalOpen(true);
                                        }}
                                    >
                                        Terms & Conditions
                                    </SternumLink>
                                </div>
                            }
                        />

                        {/** Google reCaptcha */}
                        <GoogleRecaptchaWrapper
                            elementRef={reCaptchaElementRef}
                            onRecaptchaSuccess={handleRecaptchaSuccess}
                            onRecaptchaError={handleRecaptchaError}
                        />

                        <div className={classesSignup.buttonContainer}>
                            <SternumImprovedButton
                                buttonType="regularWithDisabled"
                                fullWidth={false}
                                content={"Get Started"}
                                onClick={formik.handleSubmit}
                                isDisabled={isSubmitButtonDisabled}
                                isLoading={formik.isSubmitting}
                            />
                        </div>

                        <Box mt={5}>
                            <Typography color="textSecondary">
                                Already have an account?{" "}
                                <Link
                                    className={classNames(classesSignup.linkButton, classesCommon.cursorPointer)}
                                    component="a"
                                    href="/login"
                                >
                                    Sign in
                                </Link>
                            </Typography>
                        </Box>

                        <Box mt={5} className={classesCommon.flex}>
                            <SternumLink to="/data-privacy" className={classes.linkButton}>
                                Data privacy
                            </SternumLink>
                            <div className={classes.separator} />
                            <SternumLink to="/how-it-works" className={classes.linkButton}>
                                How it works?
                            </SternumLink>
                        </Box>
                    </div>
                </div>
            </div>

            <SternumModal
                modalKey="termsConditions"
                open={isTermsModalOpen}
                onClose={() => setTermsModalOpen(false)}
                showXButton={true}
                showFullScreenButton={false}
                showBackButton={false}
                fullscreen={false}
                modalContentClassNames={classes.modalContent}
            >
                <Box px={5} py={8}>
                    <TermsConditionsModal onTermsAgree={handleTermsAgreed} />
                </Box>
            </SternumModal>
        </SignupContainer>
    );
}

export const Signup: React.FC<SignupComponentProps> = withRouter(SignupComponent);
