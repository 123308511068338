import * as React from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import {
    Button,
    CircularProgress,
    Divider,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    FormControlLabel,
    IconButton,
    Switch,
    Tooltip,
    Typography,
    withStyles,
} from "@material-ui/core";
import { WithStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import HashMap from "../../../lib/infra/HashMap";
import Utils from "../../../lib/infra/Utils";
import { refreshTriggersHitLIstAction } from "../../../lib/redux/Triggers/RefreshTriggesHitLIstAction";
import ServiceWire from "../../../lib/services/ServiceWire";
import ArgumentDefinitionInfo from "../../../lib/state/ArgumentDefinitionInfo";
import DeviceDefinitionVersionInfo from "../../../lib/state/DeviceDefinitionVersionInfo";
import { GlobalState } from "../../../lib/state/GlobalState";
import ServerEntityType from "../../../lib/state/ServerEntityType";
import SternumDisplayObjectInfo from "../../../lib/state/SternumDisplayObjectInfo";
import SternumTriggerInfo from "../../../lib/state/SternumTriggerInfo";
import TraceDefinitionInfo from "../../../lib/state/TraceDefinitionInfo";
import TriggerDefinition from "../../../lib/state/triggers/TriggerDefinition";
import DeviceDefinitionInfo from "../../../lib/state/DeviceDefinitionInfo";
import ConfirmationDialog from "../../../shared_components/ConfirmationDialog/ConfirmationDialog";
import ExplanationComponent from "../../ExplanationComponent/ExplanationComponent";
import TriggerDefinitionSimpleDialog from "../TriggerDefinitionSimpleDialog/TriggerDefinitionSimpleDialog";
import triggerDefinitionStepStyle from "./TriggerDefinitionStepStyle";

interface AppState {
    triggerDialogStatus: boolean;
    serverError: boolean;
    deviceDefinition: DeviceDefinitionInfo;
    selectedTriggerDefinition?: SternumTriggerInfo;
    expanded: string;
    convertedTriggers: HashMap<boolean>;
    changedTrigger: any;
    showLoader: boolean;
    onOperationMap: HashMap<boolean>;
    isError: boolean;
    // Open delete trigger
    openDeleteConfirmationDialog: boolean;
    // Open change trigger state
    openChangeStatusDialog: boolean;
    // indicate if buttons should be in disable mode
    isDisabledMode: boolean;
}

export interface AppProps extends WithStyles<typeof triggerDefinitionStepStyle> {
    deviceDefinition: any;
    sternumTriggerTypes: SternumDisplayObjectInfo[];
    deviceDefinitionVersion?: DeviceDefinitionVersionInfo;
    forceCRUD?: boolean;

    refreshTriggerHitList: () => void;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {
        refreshTriggerHitList: () => dispatch(refreshTriggersHitLIstAction()),
    };
};

class TriggerDefinitionStep extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);
        // Initializing the state to default.

        this.state = {
            triggerDialogStatus: false,
            serverError: false,
            selectedTriggerDefinition: null,
            deviceDefinition: this.props.deviceDefinition,
            expanded: "",
            convertedTriggers: new HashMap<boolean>(),
            openDeleteConfirmationDialog: false,
            openChangeStatusDialog: false,
            changedTrigger: null,
            showLoader: false,
            onOperationMap: new HashMap<boolean>(),
            isError: false,
            isDisabledMode: !ServiceWire.getAuthorizationService().canEdit(ServerEntityType.STERNUM_TRIGGER),
        };
    }

    /**
     * Occurs once the component finished its initialization process.
     */
    async componentDidMount() {
        const traces = this.prepareTraceDefinitions();
        const args = this.prepareArgumentDefinitions();
        const newDeviceDefinition = DeviceDefinitionInfo.cloneObject(this.state.deviceDefinition);

        newDeviceDefinition.traceDefinitions = traces;
        newDeviceDefinition.argumentDefinitions = args;

        this.setState({ deviceDefinition: newDeviceDefinition });
    }

    private prepareTraceDefinitions = () => {
        let traces = [...this.state.deviceDefinition.traceDefinitions]
            .filter((definition) => definition.canBeUsedForAlerts)
            .sort((a, b) => a.displayName.localeCompare(b.displayName));

        return traces;
    };

    private prepareArgumentDefinitions = () => {
        let args = [...this.state.deviceDefinition.argumentDefinitions]
            .filter((definition) => definition.canBeUsedForAlerts)
            .sort((a, b) => a.displayName.localeCompare(b.displayName));

        return args;
    };

    /*
     *
     */
    private handleCloseDialog = async (
        triggerDisplayName: string,
        triggerCategory: string,
        triggerInterest: string,
        triggerDescription: string,
        triggerType: string,
        triggerDefinition: TriggerDefinition,
        triggerTargetEntityType: string,
        keepDialogOpen: boolean,
        selectedTrigger?: SternumTriggerInfo
    ) => {
        let createdTrigger: SternumTriggerInfo;
        if (selectedTrigger) {
            // update selected trigger if exists
            selectedTrigger.displayName = triggerDisplayName;
            selectedTrigger.description = triggerDescription;
            selectedTrigger.triggerCategory = triggerCategory;
            selectedTrigger.eventInterest = triggerInterest;
            selectedTrigger.triggerDefinition = triggerDefinition;
            selectedTrigger.triggerType = triggerType;
        } else {
            createdTrigger = new SternumTriggerInfo(
                null,
                null,
                null,
                triggerDisplayName,
                triggerInterest,
                triggerDescription,
                triggerCategory,
                triggerDefinition,
                triggerType,
                triggerTargetEntityType
            );
        }
        try {
            if (this.isForceCRUD()) {
                this.setState({ isError: false });
                // Send request to server
                const responseObject = await this.UpdateOrCreateTrigger(selectedTrigger, createdTrigger);
                if (selectedTrigger) {
                    selectedTrigger = responseObject;
                } else {
                    createdTrigger = responseObject;
                }
                // Remove trigger from checked list to load data
                this.state.convertedTriggers.remove(responseObject.entityId);
            }

            this.UpdateOrCreateInPlace(selectedTrigger, createdTrigger);

            this.setState({
                triggerDialogStatus: keepDialogOpen,
                deviceDefinition: this.state.deviceDefinition,
                selectedTriggerDefinition: null,
                expanded: "custom",
                convertedTriggers: this.state.convertedTriggers,
            });
        } catch (err) {
            this.setState({ isError: true });
        }
    };

    /*
     * Open edit dialog.
     * If it's edit flow, convert existing definition data into proper object
     *
     */
    handleEditClick = (sternumTrigger: SternumTriggerInfo) => (event) => {
        if (!sternumTrigger.triggerDefinition) {
            // This is probably server (backend) error. This item probably shouldn't be displayed in a list
            return;
        }

        if (this.isForceCRUD()) {
            // It's edit stage, load trace into trigger definition
            if (!this.state.convertedTriggers.containsKey(sternumTrigger.entityId)) {
                const traceDefinitionId: string =
                    sternumTrigger.triggerDefinition.traceDefinition?.traceDefinitionId ||
                    sternumTrigger.triggerDefinition.traceDefinition;
                const traceDefinitionObject: TraceDefinitionInfo =
                    this.state.deviceDefinition.getTraceDefinitionById(traceDefinitionId);

                if (sternumTrigger.triggerDefinition.argumentDefinition) {
                    const argumentDefinitionId: string =
                        sternumTrigger.triggerDefinition.argumentDefinition?.argumentDefinitionId ||
                        sternumTrigger.triggerDefinition.argumentDefinition;
                    const argumentDefinitionObject: ArgumentDefinitionInfo =
                        this.state.deviceDefinition.getArgumentDefinitionById(argumentDefinitionId);
                    sternumTrigger.triggerDefinition.argumentDefinition = argumentDefinitionObject;
                }

                sternumTrigger.triggerDefinition.traceDefinition = traceDefinitionObject;
                this.state.convertedTriggers.put(sternumTrigger.entityId, true);
            }
        }

        this.setState({
            triggerDialogStatus: true,
            selectedTriggerDefinition: sternumTrigger,
            convertedTriggers: this.state.convertedTriggers,
        });
    };

    /*
     * Handle delete request.
     */
    private handleDeleteClick = async () => {
        // Close dialog
        this.setState({ openDeleteConfirmationDialog: false });

        if (this.isForceCRUD()) {
            const deleteTriggerResponse = await ServiceWire.getSternumService().deleteTriggerDefinition(
                this.state.changedTrigger.entityId,
                this.props.deviceDefinitionVersion.entityId
            );
            if (deleteTriggerResponse) {
                this.removeTriggerInPlace(this.state.changedTrigger);
                this.props.refreshTriggerHitList();
            }

            // Remove entity from loading map
            this.state.onOperationMap.remove(this.state.changedTrigger.entityId);
            this.setState({
                onOperationMap: HashMap.copyAndRemove(this.state.onOperationMap, this.state.changedTrigger.entityId),
            });
        } else {
            this.removeTriggerInPlace(this.state.changedTrigger);
        }

        this.setState({
            changedTrigger: null,
        });
    };

    /*
     * Handle change trigger toggle click.
     */
    private changeStatusClick = async () => {
        if (this.isForceCRUD()) {
            try {
                const updateTriggerResponse = await ServiceWire.getSternumService().updateTriggerDefinitionStatus(
                    this.state.changedTrigger.entityId,
                    !this.state.changedTrigger.disabled,
                    this.props.deviceDefinitionVersion.entityId
                );
                this.state.changedTrigger.setTriggerStatus();
                this.UpdateOrCreateInPlace(this.state.changedTrigger, null);
                this.closeChangeStateConfirmationDialog();
            } catch (err) {
                this.closeChangeStateConfirmationDialog();
            }
        } else {
            this.state.changedTrigger.setTriggerStatus();
            this.UpdateOrCreateInPlace(this.state.changedTrigger, null);
            this.closeChangeStateConfirmationDialog();
        }
    };

    /*
     * Remove trigger from device definition and update state
     */
    private removeTriggerInPlace(triggerToRemove: SternumTriggerInfo) {
        this.state.deviceDefinition.removeTrigger(triggerToRemove);
        this.setState({
            deviceDefinition: this.state.deviceDefinition,
        });
    }

    /*
     * Check if operation should be taken inplace (it's setup operation) or send request to server.
     */
    private isForceCRUD = (): boolean => {
        return this.props.forceCRUD && this.props.forceCRUD === true;
    };

    /*
     * Check if operation should be taken inplace (it's setup operation) or send request to server.
     */
    UpdateOrCreateTrigger = async (selectedTrigger?: SternumTriggerInfo, toCreateTrigger?: SternumTriggerInfo) => {
        let responseObject;
        // Create trigger in  server
        if (toCreateTrigger) {
            responseObject = await ServiceWire.getSternumService().createTriggerDefinition(
                this.props.deviceDefinitionVersion.entityId,
                toCreateTrigger
            );
        } else {
            responseObject = await ServiceWire.getSternumService().updateTriggerDefinition(
                this.props.deviceDefinitionVersion.entityId,
                selectedTrigger
            );
        }
        if (responseObject) {
            return responseObject[0];
        }
        return responseObject;
    };

    /*
     * Check if operation should be taken inplace (it's setup operation) or send request to server.
     */
    UpdateOrCreateInPlace = (updateTrigger: SternumTriggerInfo, createTrigger: SternumTriggerInfo) => {
        // Check if exists,  if yes, update
        if (updateTrigger) {
            const triggerIndex = this.state.deviceDefinition.findTriggerIndex(updateTrigger);
            if (triggerIndex > -1) {
                this.state.deviceDefinition.sternumTriggers.splice(triggerIndex, 1, updateTrigger);
            }
        } else {
            this.state.deviceDefinition.addTrigger(createTrigger);
        }
    };

    /**
     * Open delete confirmation dialog
     */

    private openDeleteConfirmationDialog = async (triggerToDelete: SternumTriggerInfo) => {
        try {
            // Load summary data and show loading bar
            if (this.props.forceCRUD) {
                // Add current trigger to activity map to display load icon
                this.setState({
                    onOperationMap: HashMap.copyAndPut(this.state.onOperationMap, triggerToDelete.entityId, true),
                });
            }
            this.setState({
                openDeleteConfirmationDialog: true,
                changedTrigger: triggerToDelete,
            });
        } catch (err) {
            this.state.onOperationMap.remove(triggerToDelete.entityId);
            this.setState({ onOperationMap: this.state.onOperationMap });
        }
    };

    /**
     * Cancel button is clicked
     */
    private onCancelClicked = () => {
        if (this.props.forceCRUD) {
            this.setState({
                onOperationMap: HashMap.copyAndRemove(this.state.onOperationMap, this.state.changedTrigger.entityId),
            });
        }
        // Remove current trigger to activity map to display load icon
        this.setState({ openDeleteConfirmationDialog: false, changedTrigger: null });
    };

    /**
     * Close change status confirmation dialog
     */
    private closeChangeStateConfirmationDialog = () => {
        this.setState({ openChangeStatusDialog: false, changedTrigger: null });
    };

    /*
     * On Cancel click
     */
    private handleCancelOperation = () => {
        this.setState({
            triggerDialogStatus: false,
            selectedTriggerDefinition: null,
            serverError: false,
        });
    };

    /*
     * Handle expand panel
     */
    private handleExpandChange = (panel) => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : null,
        });
    };

    /*
     * Handle trigger toggle click
     */
    private onToggleClick = (triggerObject: SternumTriggerInfo) => {
        // Open confirmation dialog only when activating the trigger
        this.setState({ openChangeStatusDialog: !triggerObject.disabled, changedTrigger: triggerObject }, () => {
            // If it's active operation, update and change toggle state
            if (triggerObject.disabled) {
                this.changeStatusClick();
            }
        });
    };

    render() {
        const { classes } = this.props;

        return (
            <div className={classNames(classes.flexColumn, classes.flexCenter, classes.flexVMiddle, classes.root)}>
                {/** Title */}
                <div className={classes.flexRow}>
                    <Typography variant={"subtitle2"} className={classNames(classes.marginBottomMedium)}>
                        Sternum Alerts
                    </Typography>
                    <ExplanationComponent>
                        <Typography variant="body2" className={classes.explanationTextTypographyLineHeight}>
                            Sternum Advanced Detection Engine will alert you in real time on attack attempts, suspicious
                            device behaviors, and cyber breaches including data theft. Here, you can create your own
                            configurable rule-based alerts to be further automatically alerted on any device-specific
                            behavior, performance, operation or violation of existing security policies. Alerts can be
                            created on any combination of defined traces and arguments to allow flexible capabilities
                        </Typography>
                    </ExplanationComponent>
                </div>

                <ExpansionPanel
                    role="presentation"
                    aria-label="expansion panel"
                    className={classNames(classes.fullWidth, classes.marginBottomMedium)}
                    expanded={this.state.expanded === "custom"}
                    onChange={this.handleExpandChange("custom")}
                >
                    <ExpansionPanelSummary
                        role="presentation"
                        aria-label="expansion panel summary"
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography variant="body2" className={classes.heading}>
                            Customized Alerts
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails
                        role="presentation"
                        aria-label="expansion panel details"
                        className={classNames(classes.triggerList)}
                    >
                        <div className={classNames(classes.flexColumn, classes.fullWidth)}>
                            {/* Traces list */}
                            {this.state.deviceDefinition.sternumTriggers.map(
                                (triggerDefinitionIter: SternumTriggerInfo, index) => {
                                    if (!triggerDefinitionIter.fromTemplate) {
                                        return (
                                            <div
                                                role="presentation"
                                                aria-label="alert argument item"
                                                key={index + "Custom-Trace"}
                                                className={classNames(classes.flexColumn)}
                                            >
                                                <div
                                                    className={classNames(
                                                        classes.flexSpaceBetween,
                                                        classes.triggerItem,
                                                        classes.flexVMiddle
                                                    )}
                                                >
                                                    <Typography
                                                        noWrap
                                                        variant="subtitle2"
                                                        className={classNames(classes.displayName)}
                                                    >
                                                        {Utils.capitalizeFirsLetter(triggerDefinitionIter.displayName)}
                                                    </Typography>

                                                    {/** Loading bar */}
                                                    {this.props.forceCRUD &&
                                                    this.state.onOperationMap.containsKey(
                                                        triggerDefinitionIter.entityId
                                                    ) ? (
                                                        <CircularProgress size={16} className={classes.marginRightXs} />
                                                    ) : (
                                                        <div>
                                                            {/** Trigger switch toggle */}
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        disabled={this.state.isDisabledMode}
                                                                        color="primary"
                                                                        checked={!triggerDefinitionIter.disabled}
                                                                        onChange={() =>
                                                                            this.onToggleClick(triggerDefinitionIter)
                                                                        }
                                                                        value={triggerDefinitionIter.getTriggerStatus()}
                                                                        size="small"
                                                                    />
                                                                }
                                                                labelPlacement="end"
                                                                label={
                                                                    <Typography
                                                                        variant="body2"
                                                                        color={
                                                                            triggerDefinitionIter.disabled
                                                                                ? "textSecondary"
                                                                                : "primary"
                                                                        }
                                                                    >
                                                                        {triggerDefinitionIter.getTriggerStatus()}
                                                                    </Typography>
                                                                }
                                                            />

                                                            {/** Edit button */}
                                                            <Tooltip
                                                                title="Edit Alert"
                                                                classes={{ tooltip: classes.toolTip }}
                                                            >
                                                                <IconButton
                                                                    aria-label="edit"
                                                                    className={classes.smallIconButton}
                                                                    onClick={this.handleEditClick(
                                                                        triggerDefinitionIter
                                                                    )}
                                                                    disabled={this.state.isDisabledMode}
                                                                >
                                                                    <EditIcon
                                                                        fontSize="small"
                                                                        color={
                                                                            this.state.isDisabledMode
                                                                                ? "disabled"
                                                                                : "primary"
                                                                        }
                                                                    />
                                                                </IconButton>
                                                            </Tooltip>

                                                            {/** Delete button */}
                                                            <Tooltip
                                                                title="Delete Alert"
                                                                classes={{ tooltip: classes.toolTip }}
                                                            >
                                                                <IconButton
                                                                    aria-label="delete"
                                                                    className={classes.smallIconButton}
                                                                    onClick={() =>
                                                                        this.openDeleteConfirmationDialog(
                                                                            triggerDefinitionIter
                                                                        )
                                                                    }
                                                                    disabled={this.state.isDisabledMode}
                                                                >
                                                                    <DeleteIcon
                                                                        fontSize="small"
                                                                        color={
                                                                            this.state.isDisabledMode
                                                                                ? "disabled"
                                                                                : "error"
                                                                        }
                                                                    />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                    )}
                                                </div>

                                                <Divider />
                                            </div>
                                        );
                                    }
                                }
                            )}
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <div className={classNames(classes.fullWidth, classes.flexEnd)}>
                    {/* Open add trigger dialog */}
                    <Button
                        disabled={this.state.isDisabledMode}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            this.setState({
                                triggerDialogStatus: true,
                            });
                        }}
                    >
                        Create Alert
                    </Button>
                </div>

                {/* Make sure trigger dialog is created each time the state is changed */}
                {(() => {
                    if (this.state.triggerDialogStatus) {
                        return (
                            <TriggerDefinitionSimpleDialog
                                deviceDefinition={this.state.deviceDefinition}
                                open={this.state.triggerDialogStatus}
                                onClose={this.handleCloseDialog}
                                onCancel={this.handleCancelOperation}
                                duplicationCheck={() => {}}
                                existingTriggerDefinition={this.state.selectedTriggerDefinition}
                                sternumTriggerTypes={this.props.sternumTriggerTypes}
                            />
                        );
                    }
                })()}

                {/* Delete confirmation dialog */}
                {this.state.changedTrigger && this.state.openDeleteConfirmationDialog && (
                    <ConfirmationDialog
                        open={this.state.openDeleteConfirmationDialog}
                        title={`Delete ${this.state.changedTrigger.displayName} ?`}
                        body={"All data associated to this alert will be deleted."}
                        handleCancel={this.onCancelClicked}
                        handleApprove={this.handleDeleteClick}
                        overrideActionName={"Delete"}
                    />
                )}

                {/* Enable disable confirmation dialog */}
                {this.state.changedTrigger && this.state.openChangeStatusDialog && (
                    <ConfirmationDialog
                        open={this.state.openChangeStatusDialog}
                        title={`Turn Off ${this.state.changedTrigger.displayName}?`}
                        body={this.state.changedTrigger.getConfirmationMessage()}
                        handleCancel={this.closeChangeStateConfirmationDialog}
                        handleApprove={this.changeStatusClick}
                        overrideActionName={"Approve"}
                    />
                )}
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(triggerDefinitionStepStyle)(TriggerDefinitionStep));
