import { Action } from "redux";
import ActionType from "../ActionType";
import SternumService from "../../services/SternumService";

/**
 * Occurs once fetching a library has started.
 */
export interface FetchLibraryAction extends Action<ActionType> {
    type: ActionType.FetchLibrary;
    libraryId: string;
}

/**
 * Occurs once fetching a library has started.
 */
export function fetchLibraryAction(libraryId: string): FetchLibraryAction {
    return {
        type: ActionType.FetchLibrary,
        libraryId: libraryId,
    };
}
