import { ClientInfoOnboardingState, ClientInfoTier } from "../state/ClientInfo.types";
import ClientInfo from "../state/ClientInfo";
import SternumService from "./SternumService";

class ClientsService {
    /**
     * Service used for outgoing sternum API calls.
     */
    private sternumService: SternumService;

    /**
     * Holds all the fetched clients for the application.
     */
    private clients: ClientInfo[];

    /**
     * Holds the currently selected client in the application.
     */
    private selectedClient: ClientInfo;

    /**
     * Constructor.
     */
    constructor(sternumService: SternumService) {
        this.sternumService = sternumService;
    }

    /**
     * Loads the clients for the application.
     */
    public async loadClients() {
        // Fetching the clients.
        this.clients = await this.sternumService.getClients(null, null, null, null, 0, 1000);

        // Fetching the saved client from the storage.
        const storageSelectedClientId: string = localStorage.getItem("selected_client_id");

        if (storageSelectedClientId) {
            await this.setSelectedClient(storageSelectedClientId);
        } else if (this.clients.length > 0) {
            await this.selectClient(this.clients[0]);
        }
    }

    private async loadClient(clientId: string) {
        return await this.sternumService.getClient(clientId);
    }

    public async updateClientOnboardingState(onboardingState: ClientInfoOnboardingState) {
        const success = await this.sternumService.updateClientOnboardingState(
            this.getSelectedClientId(),
            onboardingState
        );

        if (success) {
            this.selectedClient.onboardingState = onboardingState;
        }
    }

    /**
     * Returns all the clients in the app.
     */
    public getClients(): ClientInfo[] {
        return this.clients;
    }

    /**
     * Returns the selected client for the application.
     */
    public getSelectedClient(): ClientInfo {
        return this.selectedClient;
    }

    /**
     * Returns the selected client id for the application.
     */
    public getSelectedClientId(): string {
        return this.selectedClient ? this.selectedClient.entityId : null;
    }

    /**
     * Saves the given client as the selected client.
     */
    public async selectClient(client: ClientInfo) {
        // Save selected client in storage.
        localStorage.setItem("selected_client_id", client.entityId);

        // Save selected client on service.
        await this.setSelectedClient(client.entityId);
    }

    /**
     * Clears the selected client.
     */
    public clearSelectedClient(): void {
        localStorage.removeItem("selected_client_id");
        this.selectedClient = null;
    }

    /**
     * Sets the selected client based on given id.
     */
    private async setSelectedClient(storageSelectedClientId: string) {
        this.selectedClient = await this.loadClient(storageSelectedClientId);
    }

    /**
     * Set last custom dashboard that selected
     */

    public setCustomDashboard(dashboardId: string) {
        localStorage.setItem(this.getCustomDashboardKey(), dashboardId.toString());
    }

    /**
     * Get latest custom dashboard
     */

    public getCustomDashboard(): string {
        // Each user can view single client with single default custom dashboard.
        return localStorage.getItem(this.getCustomDashboardKey());
    }

    private getCustomDashboardKey(): string {
        return `client_${this.selectedClient.clientId}_selected_dashboard`;
    }

    private getSignupFormStateKey(): string {
        return "signup_form_state";
    }

    public getSignupFormState(): Record<string, any> | null {
        const item = sessionStorage.getItem(this.getSignupFormStateKey());
        if (!item) {
            return null;
        }
        return JSON.parse(item);
    }

    public setSignupFormState(data: Record<string, any>): void {
        const blob = JSON.stringify(data);
        sessionStorage.setItem(this.getSignupFormStateKey(), blob);
    }

    public deleteSignupFormState(): void {
        sessionStorage.removeItem(this.getSignupFormStateKey());
    }
}

export default ClientsService;
