import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

const cveInfoDisplayStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        details: {
            display: "flex",
            alignItems: "flex-start",
        },
        cveDetailContainer: {
            display: "flex",
            alignItems: "center",
        },
        cveDetailName: {
            marginRight: theme.spacing(1),
        },
        infoColumn: {
            marginRight: theme.spacing(8),
        },
        descriptionHideShowLink: {
            fontSize: "0.8125rem",
            fontWeight: 400,
            "&:hover": {
                textDecoration: "underline",
            },
        },
    });

export default cveInfoDisplayStyle;
