import React from "react";
import classNames from "classnames";

import { useMainSectionStyle } from "./MainSection.style";

export interface MainSectionContentProps extends React.ComponentProps<"div"> {}

export function MainSectionContent({ className, children, ...props }: MainSectionContentProps) {
    const classes = useMainSectionStyle();

    return (
        <div className={classNames(classes.content, className)} {...props}>
            {children}
        </div>
    );
}
