/**
 * Holds information about a column header in a table.
 */
class TableColumnHeaderInfo {
    /**
     * Constructor.
     */
    constructor(
        public id: string,
        public displayValue: string,
        public isPrimaryColumn: boolean,
        public canBeHidden: boolean,
        public isHidden: boolean,
        public isSortable: boolean = false,
        public marginLeft?: number,
        public tooltipValue?: string
    ) {}
}

export default TableColumnHeaderInfo;
