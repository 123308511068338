import { makeStyles } from "@material-ui/core";
import commonStyle from "../../CommonStyle";

export const useTooltipStyles = makeStyles((theme) => {
    return {
        ...commonStyle(theme),
        body: {
            backgroundColor: "#fff",
            borderRadius: 20,
            padding: "32px 24px",
            width: 400,
            minHeight: 222,
            display: "flex",
            flexDirection: "column",
        },
        title: {
            fontSize: 18,
            lineHeight: "28px",
            fontWeight: 700,
            marginBottom: 16,
            color: "#2B2E30",
        },
        content: {
            fontSize: 14,
            lineHeight: "21px",
            fontWeight: 400,
            color: "#999999",
            flex: 1,
        },
        footer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: 16,
        },
        buttons: {
            display: "flex",
            flexDirection: "row",
        },
        button: {
            fontSize: 16,
        },
        skipButton: {
            paddingLeft: 16,
            paddingRight: 16,
        },
        demoDataTag: {
            position: "fixed",
            top: 38,
            right: 240,
            padding: "8px 12px",
            borderRadius: 20,
            fontSize: 16,
            lineHeight: "100%",
            fontWeight: 400,
            background: "#FFF3E2",
            color: "#F79A1D",
            zIndex: 2100,
        },
    };
});
