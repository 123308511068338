import { Action } from "redux";
import ActionType from "../ActionType";

/**
 * Occurs once fetching a used library has started.
 */
export interface FetchUsedLibraryAction extends Action<ActionType> {
    type: ActionType.FetchUsedLibrary;
    usedLibraryId: string;
}

/**
 * Occurs once fetching a used library has started.
 */
export function fetchUsedLibraryAction(usedLibraryId: string): FetchUsedLibraryAction {
    return {
        type: ActionType.FetchUsedLibrary,
        usedLibraryId: usedLibraryId,
    };
}
