import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useFormik } from "formik";
import { connect } from "react-redux";
import zxcvbn from "zxcvbn";

import GoogleRecaptchaWrapper from "../../GoogleRecaptcha/GoogleRecaptcha";
import { GlobalState } from "../../../lib/state/GlobalState";
import { SternumInputField } from "../../SUI/SternumInputField";
import SternumImprovedButton from "../../SUI/SternumImprovedButton/SternumImprovedButton";
import SternumBanner from "../../SUI/SternumBanner/SternumBanner";
import { AuthenticationContainer } from "../AuthenticationContainer";
import { useCommonStyle } from "../../CommonStyle";
import { useCreatePasswordStyle } from "./CreatePassword.style";
import { useLoginManagerStyle } from "../LoginManager.style";
import LoginStateEnum from "../../../lib/state/LoginStateEnum";
import PasswordStrength from "../../SUI/PasswordStrength/PasswordStrength";
import ServiceWire from "../../../lib/services/ServiceWire";
import { PASSWORD_MIN_CHARS_COUNT } from "../LoginManager.model";
import { NotificationMessage, NotificationVariant } from "../../../lib/state/NotificationsState";
import { showNotificationAction } from "../../../lib/redux/notifications/ShowNotificationAction";

export interface CreatePasswordComponentProps {
    nextStep: (nextStep: LoginStateEnum) => unknown;
}

interface FormValues {
    password: string;
    confirmPassword: string;
}

const mapStateToProps = (state: GlobalState, ownProps: CreatePasswordComponentProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        showNotification: (message: NotificationMessage, variant?: NotificationVariant) => {
            dispatch(showNotificationAction(message, variant));
        },
    };
};

interface CreatePasswordPropsWithHOC extends CreatePasswordComponentProps, ReturnType<typeof mapDispatchToProps> {}

function CreatePasswordComponent({ nextStep, showNotification }: CreatePasswordPropsWithHOC) {
    const reCaptchaElementRef = useRef();
    const classes = useCreatePasswordStyle();
    const classesLogin = useLoginManagerStyle();
    const classesCommon = useCommonStyle();

    const [jwtToken] = useState(() => ServiceWire.getAuthenticationService().loadTokenFromUrl());
    const [errorMsg, setErrorMsg] = useState("");
    const [captchaToken, setCaptchaToken] = useState("");
    const [passwordStrength, setPasswordStrength] = useState(-1);

    const formik = useFormik<FormValues>({
        initialValues: {
            password: "",
            confirmPassword: "",
        },
        validate: (values) => {
            const errors: Partial<FormValues> = {};

            if (passwordStrength < 2) {
                errors.password = "The password strength must be medium or above";
            }

            if (values.password.length < PASSWORD_MIN_CHARS_COUNT) {
                errors.password = `The password must contain at least ${PASSWORD_MIN_CHARS_COUNT} characters`;
            }

            if (values.password !== values.confirmPassword) {
                errors.confirmPassword = "The password doesn't match";
            }

            return errors;
        },
        onSubmit: async (values) => {
            setErrorMsg("");

            try {
                const serverResponse = await ServiceWire.getSternumService().resetPasswordStep(
                    formik.values.password,
                    jwtToken
                );

                if (!serverResponse) {
                    throw new Error();
                }

                showNotification(`Password has been updated`, NotificationVariant.Success);
                nextStep(LoginStateEnum.LOGIN);
            } catch (err) {
                setErrorMsg("Something went wrong, please contact us at support@sternumiot.com");
            }
        },
    });

    useEffect(() => {
        (async () => {
            if (jwtToken) {
                await ServiceWire.getSternumService().jwtValidation(jwtToken);
            }
        })().then();
    }, []);

    const handlePasswordFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const password = e.target.value;
        const passwordStrength = zxcvbn(password)?.score as number;

        setPasswordStrength(passwordStrength);
        formik.setFieldValue("password", e.target.value);
    };

    const handleRecaptchaSuccess = (reCaptchaToken: string) => {
        setCaptchaToken(reCaptchaToken);
    };

    const handleRecaptchaError = () => {
        setCaptchaToken("");
    };

    const isSubmitButtonDisabled = () => {
        return formik.isSubmitting || Object.keys(formik.errors).length > 0;
    };

    return (
        <AuthenticationContainer onBack={() => nextStep(LoginStateEnum.LOGIN)}>
            <h1 className={classNames(classesLogin.title, classes.title)}>Create Password</h1>

            <div className={classNames(classesLogin.authFieldsContainer)}>
                {errorMsg && (
                    <div className={classNames(classesCommon.marginBottomXLarge, classesCommon.fullWidth)}>
                        <SternumBanner messageType="error" message={errorMsg} onCloseClick={() => setErrorMsg("")} />
                    </div>
                )}

                {/* Password input */}
                <SternumInputField
                    label={"Password"}
                    inputValue={formik.values.password}
                    onFieldChange={handlePasswordFieldChange}
                    onTouch={() => formik.setFieldTouched("password", true)}
                    isTouched={!!formik.touched.password}
                    isPassword={true}
                    error={!!formik.errors.password}
                    helperText={formik.touched.password ? formik.errors.password : undefined}
                />

                <div className={classesCommon.marginBottomXLarge}>
                    <PasswordStrength passwordStrength={passwordStrength} />
                </div>

                {/* Confirm password input */}
                <SternumInputField
                    label={"Confirm password"}
                    inputValue={formik.values.confirmPassword}
                    onFieldChange={(e) => formik.setFieldValue("confirmPassword", e.target.value)}
                    addMargin={true}
                    isTouched={!!formik.touched.confirmPassword}
                    isPassword={true}
                    error={!!formik.errors.confirmPassword}
                    helperText={formik.touched.confirmPassword ? formik.errors.confirmPassword : undefined}
                    onTouch={() => formik.setFieldTouched("confirmPassword", true)}
                    required={true}
                />

                {/** Google reCaptcha */}
                <GoogleRecaptchaWrapper
                    elementRef={reCaptchaElementRef}
                    onRecaptchaSuccess={handleRecaptchaSuccess}
                    onRecaptchaError={handleRecaptchaError}
                />

                <div className={classesLogin.buttonContainer}>
                    <SternumImprovedButton
                        buttonType="regularWithDisabled"
                        className={classesLogin.loginButton}
                        content={"Send"}
                        onClick={formik.handleSubmit}
                        isDisabled={isSubmitButtonDisabled()}
                        isLoading={formik.isSubmitting}
                    />
                </div>
            </div>
        </AuthenticationContainer>
    );
}

export const CreatePassword: React.FC<CreatePasswordComponentProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreatePasswordComponent);
