import {
    Button,
    CircularProgress,
    Icon,
    IconButton,
    Step,
    StepLabel,
    Stepper,
    Tooltip,
    Typography,
    WithStyles,
    withStyles,
} from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import WebUtils from "../../lib/infra/WebUtils";
import ServiceWire from "../../lib/services/ServiceWire";
import DeviceDefinitionPartial from "../../lib/state/DeviceDefinitionPartial";
import { GlobalState } from "../../lib/state/GlobalState";
import HttpResponse from "../../lib/state/HttpResponse";
import SternumDisplayObjectInfo from "../../lib/state/SternumDisplayObjectInfo";
import SternumTriggerTypeDisplayInfo from "../../lib/state/SternumTriggerTypeDisplayInfo";
import TraceCategory from "../../lib/state/TraceCategory";
import ArgumentDefinitionStep from "./ArgumentDefinitionStep/ArgumentDefinitionStep";
import DeviceDefinitionForm from "./DeviceDefinitionForm/DeviceDefinitionForm";
import deviceDefinitionModalStyle from "./DeviceDefinitionModalStyle";
import DeviceDefinitionLibraryStep from "./LibraryDefinitionStep/DeviceDefinitionLibraryStep";
import TraceDefinitionStep from "./TraceDefinitionStep/TraceDefinitionStep";
import TriggerDefinitionStep from "./TriggerDefinitionStep/TriggerDefinitionStep";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    savingDeviceDefinition: boolean;
    errorSavingDeviceDefinition: boolean;
    activeStep: number;
    deviceDefinition: DeviceDefinitionPartial;
    deviceDefinitionRawId: string;
    deviceDefinitionEntityId: string;
    sternumCategories: TraceCategory[];
    sternumSystemTraces: SternumDisplayObjectInfo[];
    sternumSystemArguments: SternumDisplayObjectInfo[];
    sternumTriggerTypes: SternumTriggerTypeDisplayInfo[];
    loadingData: boolean;
    isDeviceDefinitionIsValid: boolean;
    clickedDownloadButtonEl: HTMLDivElement | null;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof deviceDefinitionModalStyle> {
    fullScreenDisplay: boolean;
    theme?;
    closeModal: () => void;
    newDeviceDefinitionCallbackFunction: () => void;
    existingDeviceDefinition?: DeviceDefinitionPartial;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {};
};

class DeviceDefinitionCreateModal extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */

    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.

        this.state = {
            savingDeviceDefinition: false,
            errorSavingDeviceDefinition: false,
            activeStep: 0,
            deviceDefinition: null,
            deviceDefinitionRawId: null,
            deviceDefinitionEntityId: "",
            sternumCategories: null,
            sternumSystemTraces: null,
            sternumSystemArguments: null,
            sternumTriggerTypes: null,
            loadingData: false,
            isDeviceDefinitionIsValid: false,
            clickedDownloadButtonEl: null,
        };
    }

    /*
     * Occurs once the component finished its initialization process.
     */
    componentDidMount() {
        this.loadCategories();
        this.loadSystemTraces();
        this.loadSystemArguments();
        this.loadSternumTriggerTypes();
    }

    /**
     * List all wizard steps
     */
    private getDeviceDefinitionSteps() {
        return ["Add Device Profile", "Add Traces", "Add Arguments", "Add Alerts", "Add 3ʳᵈ Party"];
    }

    /**
     * Switch to get active wizard step
     */
    private getDeviceDefinitionStepContent(stepIndex: number) {
        switch (stepIndex) {
            case 0:
                return (
                    <DeviceDefinitionForm
                        deviceDefinition={this.state.deviceDefinition}
                        setOrUpdateDeviceDefinition={this.setDeviceDefinition}
                    />
                );
            case 1:
                return (
                    <TraceDefinitionStep
                        deviceDefinition={this.state.deviceDefinition}
                        categoriesList={this.state.sternumCategories}
                    />
                );
            case 2:
                return <ArgumentDefinitionStep deviceDefinition={this.state.deviceDefinition} />;
            case 3:
                return (
                    <TriggerDefinitionStep
                        deviceDefinition={this.state.deviceDefinition}
                        sternumTriggerTypes={this.state.sternumTriggerTypes}
                    />
                );
            case 4:
                return <DeviceDefinitionLibraryStep deviceDefinition={this.state.deviceDefinition} />;
            default:
                return "Unknown stepIndex";
        }
    }

    /*
     *  Load Sternum categories from server.
     */
    private loadCategories = async () => {
        this.setState({ loadingData: true });
        const serverResponse = await ServiceWire.getSternumService().getTraceCategories();
        if (serverResponse) {
            this.setState({
                loadingData: false,
                sternumCategories: serverResponse,
            });
        }
    };

    /*
     *  Load Sternum system traces from server.
     */
    private loadSystemTraces = async () => {
        this.setState({ loadingData: true });
        const serverResponse = await ServiceWire.getSternumService().getSystemTraces();
        if (serverResponse) {
            this.setState({
                loadingData: false,
                sternumSystemTraces: serverResponse,
            });
        }
    };

    /*
     *  Load Sternum system arguments from server.
     */
    private loadSystemArguments = async () => {
        this.setState({ loadingData: true });
        const serverResponse = await ServiceWire.getSternumService().getSystemArguments();
        if (serverResponse) {
            this.setState({
                loadingData: false,
                sternumSystemArguments: serverResponse,
            });
        }
    };

    /*
     *  Load Sternum trigger types from server.
     */
    private loadSternumTriggerTypes = async () => {
        this.setState({ loadingData: true });
        const serverResponse = await ServiceWire.getSternumService().getSternumTriggerTypes();
        if (serverResponse) {
            this.setState({
                loadingData: false,
                sternumTriggerTypes: serverResponse,
            });
        }
    };

    /**
     * Update device definition
     */
    private setDeviceDefinition = (deviceDefinition: DeviceDefinitionPartial) => {
        // Set system definition data
        if (deviceDefinition.isValid()) {
            deviceDefinition.createSystemTraces(this.state.sternumSystemTraces);
            deviceDefinition.createSystemArguments(this.state.sternumSystemArguments);
            //deviceDefinition.createLibrary();
            this.setState({
                deviceDefinition: deviceDefinition,
                isDeviceDefinitionIsValid: true,
            });
        } else {
            this.setState({ isDeviceDefinitionIsValid: false });
        }
    };

    /**
     * Set next step
     */
    private handleNext = () => {
        this.setState({
            activeStep: this.state.activeStep + 1,
        });
    };

    /**
     * Set back step
     */
    private handleBack = () => {
        this.setState({
            activeStep: this.state.activeStep - 1,
        });
    };

    /**
     * Reset steps ( go back to step 0)
     */
    private handleReset = () => {
        this.setState({
            activeStep: 0,
        });
    };

    /*
     * Close modal after user's click
     */
    private handleClose = (event) => {
        this.props.newDeviceDefinitionCallbackFunction();
        this.props.closeModal();
    };

    /*
     * Copy device definition id
     */
    private copyToken = (event) => {
        WebUtils.copyContentToClipBoard(this.state.deviceDefinitionRawId);
    };

    /*
     * Download custom header file for clicked device definition
     */

    private downloadCustomHeaderFile = async (fileType) => {
        this.setState({
            loadingData: true,
        });

        let downloadResult: HttpResponse = null;
        switch (fileType) {
            case "java":
                const [traces_file, arguments_file] = await Promise.all([
                    ServiceWire.getSternumService().downloadDeviceDefinitionJavaTracesFile(
                        this.state.deviceDefinitionEntityId
                    ),
                    ServiceWire.getSternumService().downloadDeviceDefinitionJavaArgumentsFile(
                        this.state.deviceDefinitionEntityId
                    ),
                ]);

                if (traces_file) {
                    WebUtils.downloadReport(traces_file);
                }

                if (arguments_file) {
                    WebUtils.downloadReport(arguments_file);
                }
                break;
            case "json":
                downloadResult = await ServiceWire.getSternumService().downloadDeviceDefinitionJsonHeader(
                    this.state.deviceDefinitionEntityId
                );
                break;
            default:
                downloadResult = await ServiceWire.getSternumService().downloadDeviceDefinitionCustomHeader(
                    this.state.deviceDefinitionEntityId
                );
        }

        if ((fileType === "json" || fileType === "header") && downloadResult) {
            WebUtils.downloadReport(downloadResult);
        }
        this.setState({
            loadingData: false,
        });
    };

    /**
     * Occurs once the download icon clicked.
     */
    private onDownloadButtonClick = (event) => {
        this.setState({ clickedDownloadButtonEl: event.currentTarget });
    };

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        // Loading element
        const loadingDataElement = (
            <div className={classNames(classes.flexVMiddle, classes.flexCenter)}>
                <CircularProgress />
            </div>
        );

        // Show device definition key step
        const deviceDefinitionKeyStep = (
            <div className={classNames(classes.flexVMiddle, classes.flexColumn)}>
                <div className={classNames(classes.marginBottomXLarge)}>
                    <Typography variant="h6" className={classNames(classes.instructions)}>
                        {this.state.deviceDefinitionRawId ? this.state.deviceDefinition.displayName : ""} profile is
                        ready
                    </Typography>
                </div>
                <div className={classNames(classes.marginBottomXLarge)}>
                    <Typography variant="h6" className={classNames(classes.instructions)}>
                        Device Definition ID: {this.state.deviceDefinitionRawId ? this.state.deviceDefinitionRawId : ""}
                    </Typography>
                </div>

                <div>
                    <Tooltip title="Download Traces Definition" classes={{ tooltip: classes.toolTip }}>
                        <IconButton
                            color="primary"
                            aria-label="Download Traces Definition"
                            onClick={this.onDownloadButtonClick}
                        >
                            <CloudDownloadIcon />
                        </IconButton>
                    </Tooltip>

                    <Popover
                        anchorEl={this.state.clickedDownloadButtonEl}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        open={!!this.state.clickedDownloadButtonEl}
                        onClose={() => this.setState({ clickedDownloadButtonEl: null })}
                    >
                        <div className={classNames(classes.exportPopperContainer)}>
                            <div
                                className={classNames(classes.flexVMiddle)}
                                onClick={() => {
                                    this.downloadCustomHeaderFile("header");
                                    this.setState({ clickedDownloadButtonEl: null });
                                }}
                            >
                                {/* Icon button */}
                                <IconButton color="primary">
                                    <Icon className={classNames("fa fa-file", classes.exportPopperIconSize)} />
                                </IconButton>

                                {/* Download header file */}
                                <Typography variant="body2" className={classNames(classes.cursorPointer)}>
                                    Header File
                                </Typography>
                            </div>
                            <div
                                className={classNames(classes.flexVMiddle)}
                                onClick={() => {
                                    this.downloadCustomHeaderFile("json");

                                    this.setState({ clickedDownloadButtonEl: null });
                                }}
                            >
                                {/* Icon button */}
                                <IconButton color="primary">
                                    <Icon className={classNames("fa fa-file", classes.exportPopperIconSize)} />
                                </IconButton>

                                {/* Download json file */}
                                <Typography variant="body2" className={classNames(classes.cursorPointer)}>
                                    JSON File
                                </Typography>
                            </div>
                            <div
                                className={classNames(classes.flexVMiddle)}
                                onClick={() => {
                                    this.downloadCustomHeaderFile("java");

                                    this.setState({ clickedDownloadButtonEl: null });
                                }}
                            >
                                {/* Icon button */}
                                <IconButton color="primary">
                                    <Icon className={classNames("fa fa-file", classes.exportPopperIconSize)} />
                                </IconButton>

                                {/* Download java files */}
                                <Typography variant="body2" className={classNames(classes.cursorPointer)}>
                                    Java Files
                                </Typography>
                            </div>
                        </div>
                    </Popover>

                    <Tooltip title="Copy Device Definition ID" classes={{ tooltip: classes.toolTip }}>
                        <IconButton color="primary" aria-label="Copy key" onClick={this.copyToken}>
                            <Icon className={classNames("fas fa-paste")} color="primary" />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Close" classes={{ tooltip: classes.toolTip }}>
                        <IconButton color="primary" aria-label="Close" onClick={() => this.props.closeModal()}>
                            <CloseOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        );

        // Steps container
        const stepContainer = (
            <div className={classNames(classes.stepContentHeight, classes.flexColumn, classes.flexSpaceBetween)}>
                <div key="wizardContent" role="presentation" aria-label="device definition step content">
                    {this.getDeviceDefinitionStepContent(this.state.activeStep)}
                </div>

                <div key="buttonsContent" role="presentation" aria-label="navigation buttons">
                    <Button
                        disabled={this.state.activeStep === 0}
                        onClick={this.handleBack}
                        className={classes.backButton}
                    >
                        <ArrowBackIcon />
                        Back
                    </Button>
                    <Button
                        disabled={this.state.isDeviceDefinitionIsValid ? false : true}
                        variant="contained"
                        color="primary"
                        onClick={
                            this.state.activeStep === this.getDeviceDefinitionSteps().length - 1
                                ? () => this.createDeviceDefinition()
                                : this.handleNext
                        }
                    >
                        {this.state.activeStep === this.getDeviceDefinitionSteps().length - 1
                            ? "Create Profile"
                            : "Next"}
                        {this.state.activeStep === this.getDeviceDefinitionSteps().length - 1 ? (
                            ""
                        ) : (
                            <ArrowForwardIcon />
                        )}
                    </Button>
                </div>
            </div>
        );
        if (this.state.loadingData) {
            return loadingDataElement;
        }
        return (
            <div
                key="stepper"
                className={classNames(classes.root)}
                role="presentation"
                aria-label="device definition modal"
            >
                {/** Display steps */}
                <Stepper
                    role="presentation"
                    aria-label="device definition stepper"
                    activeStep={this.state.activeStep}
                    alternativeLabel
                    classes={{ root: classNames(classes.stepper) }}
                >
                    {this.getDeviceDefinitionSteps().map((label) => (
                        <Step key={label} role="presentation" aria-label="device definition stepper item">
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>

                {/** Display active step or finish page */}

                {this.state.savingDeviceDefinition
                    ? loadingDataElement
                    : this.state.activeStep === this.getDeviceDefinitionSteps().length - 1 &&
                      this.state.deviceDefinitionRawId
                    ? deviceDefinitionKeyStep
                    : stepContainer}
            </div>
        );
    }

    /**
     * Create Device definition .
     */
    private async createDeviceDefinition() {
        try {
            this.setState({
                savingDeviceDefinition: true,
                errorSavingDeviceDefinition: false,
            });
            const createDefinitionResponse = await ServiceWire.getSternumService().createDeviceDefinition(
                ServiceWire.getClientsService().getSelectedClientId(),
                this.state.deviceDefinition
            );
            if (createDefinitionResponse) {
                this.setState({
                    deviceDefinitionRawId: createDefinitionResponse[0].deviceDefinitionVersionRawId,
                    deviceDefinitionEntityId: createDefinitionResponse[0].entityId,
                });
            }
            this.setState({
                savingDeviceDefinition: false,
            });
        } catch (error) {
            this.setState({
                errorSavingDeviceDefinition: true,
            });
        } finally {
            this.setState({
                errorSavingDeviceDefinition: false,
            });
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(deviceDefinitionModalStyle, { withTheme: true })(DeviceDefinitionCreateModal));
