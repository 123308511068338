import ModalStateConfiguration from "./ModalStateConfiguration";
import ModalKey from "./ModalKey";

/**
 * Holds the state for the modals in sternum app.
 */
export interface ModalsState {
    modalIdToConfigurationMap: { [key: string]: ModalStateConfiguration };
    openModalsStack: ModalKey[];
}

/**
 * Gets the default state for modals.
 */
export function getModalsDefaultState() {
    return {
        modalIdToConfigurationMap: {},
        openModalsStack: [],
    };
}
