import Button from "@material-ui/core/Button";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import { uniqueId } from "lodash";
import * as React from "react";
import HashMap from "../../lib/infra/HashMap";
import SternumUtils from "../../lib/infra/SternumUtils";
import DeviceDefinitionInfo from "../../lib/state/DeviceDefinitionInfo";
import QueryQuantifierType from "../../lib/state/QueryQuantifierType";
import SternumDeviceEventsFilter from "../../lib/state/SternumDeviceEventsFilter";
import SternumFilter from "../../lib/state/SternumFilter";
import SternumFilterValue from "../../lib/state/SternumFilterValue";
import SternumQuery from "../../lib/state/SternumQuery";
import SternumQueryEditor from "../SternumQueryEditor/SternumQueryEditor";
import sternumDeviceEventsListFilterPopoverStyle from "./SternumDeviceEventsListFilterPopoverStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    sternumDeviceEventsFilter: SternumDeviceEventsFilter;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof sternumDeviceEventsListFilterPopoverStyle> {
    sternumDeviceEventsFilter: SternumDeviceEventsFilter;

    onFilterChanged: (sternumDeviceEventsFilter: SternumDeviceEventsFilter) => void;
}

class SternumDeviceEventsListFilterPopover extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        this.state = {
            sternumDeviceEventsFilter: this.props.sternumDeviceEventsFilter
                ? this.props.sternumDeviceEventsFilter
                : new SternumDeviceEventsFilter(
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      new SternumQuery(
                          uniqueId(),
                          QueryQuantifierType.ALL,
                          [new SternumFilter(1, null, null, new HashMap<SternumFilterValue>(), false)],
                          []
                      ),
                      null
                  ),
        };
    }

    /**
     * Occurs once the component is about to receive props.
     */
    UNSAFE_componentWillReceiveProps(nextProps: Readonly<AppProps>, nextContext: any): void {
        if (
            (!nextProps.sternumDeviceEventsFilter && this.props.sternumDeviceEventsFilter) ||
            (nextProps.sternumDeviceEventsFilter && !this.props.sternumDeviceEventsFilter) ||
            (nextProps.sternumDeviceEventsFilter &&
                this.props.sternumDeviceEventsFilter &&
                nextProps.sternumDeviceEventsFilter.isDifferentFrom(nextProps.sternumDeviceEventsFilter))
        ) {
            this.setState({
                sternumDeviceEventsFilter: nextProps.sternumDeviceEventsFilter,
            });
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <div>
                {/* Query editor */}
                <SternumQueryEditor
                    fields={SternumUtils.getDeviceDefinitionFieldsToQuery()}
                    sternumQuery={this.state.sternumDeviceEventsFilter.sternumQuery}
                    onSternumQueryChanged={(updatedQuery) => this.onSternumQueryChanged(updatedQuery)}
                />

                {/* Apply & Reset buttons */}
                <div className={classes.resetAndApplyButtons}>
                    {/* Apply */}
                    <Button
                        size={"small"}
                        variant={"contained"}
                        color={"primary"}
                        className={classes.applyButton}
                        onClick={(event) => this.handleFilterApplied()}
                    >
                        Apply
                    </Button>

                    {/* Reset */}
                    <Button size={"small"} variant={"contained"} onClick={(event) => this.handleFilterReset()}>
                        Reset
                    </Button>
                </div>
            </div>
        );
    }

    /**
     * Occurs on the change of the stenrum query.
     */
    private onSternumQueryChanged(updatedSternumQuery: SternumQuery) {
        this.setState({
            sternumDeviceEventsFilter: new SternumDeviceEventsFilter(
                this.state.sternumDeviceEventsFilter.eventInterest,
                this.state.sternumDeviceEventsFilter.onlyAttackTraces,
                this.state.sternumDeviceEventsFilter.createdFrom,
                this.state.sternumDeviceEventsFilter.createdTo,
                this.state.sternumDeviceEventsFilter.lessThanId,
                this.state.sternumDeviceEventsFilter.greaterThanId,
                this.state.sternumDeviceEventsFilter.traceCategories,
                this.state.sternumDeviceEventsFilter.filterOnlyTriggers,
                updatedSternumQuery,
                null
            ),
        });
    }

    private handleFilterApplied() {
        if (this.props.onFilterChanged) {
            this.props.onFilterChanged(this.state.sternumDeviceEventsFilter);
        }
    }

    /**
     * Occurs on reset of filters requested.
     */
    private handleFilterReset() {
        if (this.props.onFilterChanged) {
            this.props.onFilterChanged(
                new SternumDeviceEventsFilter(
                    this.state.sternumDeviceEventsFilter.eventInterest,
                    this.state.sternumDeviceEventsFilter.onlyAttackTraces,
                    this.state.sternumDeviceEventsFilter.createdFrom,
                    this.state.sternumDeviceEventsFilter.createdTo,
                    this.state.sternumDeviceEventsFilter.lessThanId,
                    this.state.sternumDeviceEventsFilter.greaterThanId,
                    this.state.sternumDeviceEventsFilter.traceCategories,
                    this.state.sternumDeviceEventsFilter.filterOnlyTriggers,
                    null,
                    null
                )
            );
        }
    }
}

export default withStyles(sternumDeviceEventsListFilterPopoverStyle)(SternumDeviceEventsListFilterPopover);
