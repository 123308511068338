import { CircularProgress, Paper } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import HashSet from "../../lib/infra/HashSet";
import SternumConfiguration from "../../lib/infra/SternumConfiguration";
import Utils from "../../lib/infra/Utils";
import DebounceService from "../../lib/services/DebounceService";
import ServiceWire from "../../lib/services/ServiceWire";
import EntitiesFilter from "../../lib/state/EntitiesFilter";
import { GlobalState } from "../../lib/state/GlobalState";
import ListFilter from "../../lib/state/ListFilter";
import ServerEntityType from "../../lib/state/ServerEntityType";
import SternumRole from "../../lib/state/SternumRole";
import TableColumnHeaderInfo from "../../lib/state/TableColumnHeaderInfo";
import TableToolbarDisplayState from "../../lib/state/TableToolbarDisplayState";
import UserInfo from "../../lib/state/UserInfo";
import EntitiesListFilterPopover from "../EntitiesListFilterPopover/EntitiesListFilterPopover";
import StatusDisplay from "../StatusDisplay/StatusDisplay";
import SternumEllipsisToolTip from "../SUI/SternumEllipsisToolTip/SternumEllipsisToolTip";
import SternumTable from "../SUI/SternumTable/SternumTable";
import UsersListActions from "../UsersListActions/UsersListActions";
import mixedUsersListStyle from "./MixedUsersListStyle";

import moment from "moment";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    // Loading & error state.
    loadingEntities: boolean;
    errorLoadingEntities: boolean;

    // Entities.
    entities: UserInfo[];
    totalItemCount: number;

    // Filtering.
    entitiesFilter: EntitiesFilter;
    searchText: string;
    orderByField: string;
    order: "asc" | "desc";
    displayLoader: boolean;

    openEditUserDialog: boolean;
    updateUser: UserInfo;
    roles: SternumRole[];
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof mixedUsersListStyle> {
    sideBarOpen: boolean;
    location;
    history;

    includeColumns?: string[];
    paperClassNames?;
    viewedColumnsSet?: HashSet;

    preloadedEntities: UserInfo[];
    entitiesFilter: EntitiesFilter;

    showAllColumns?: boolean;

    newUser?: UserInfo;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {};
};

/**
 * Holds the entities list in the app.
 */
class MixedUsersList extends React.Component<AppProps, AppState> {
    /**
     * Defines the column headers participating in the entities table.
     */
    private readonly columnHeaders: TableColumnHeaderInfo[] = [
        // new TableColumnHeaderInfo("entityId", "User ID", false, true, false),
        new TableColumnHeaderInfo("email", "Email", false, true, false),
        new TableColumnHeaderInfo("firstName", "First Name", false, true, false),
        new TableColumnHeaderInfo("lastName", "Last Name", false, true, false),

        new TableColumnHeaderInfo("created", "Created", false, true, false),
        //new TableColumnHeaderInfo("updated", "Updated", false, true, false),
        new TableColumnHeaderInfo("status", "Status", false, true, false),
        //new TableColumnHeaderInfo("isSternumAdmin", "Is Sternum Admin", false, true, false),
        new TableColumnHeaderInfo("role", "Role", false, true, false),
        new TableColumnHeaderInfo("lastLogin", "Last Login", false, true, false),
        new TableColumnHeaderInfo(
            "actions",
            "Actions",
            false,
            true,
            !ServiceWire.getAuthorizationService().canEdit(ServerEntityType.USER),
            false
        ),
    ];

    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        if (this.props.includeColumns) {
            if (this.props.includeColumns.length) {
                let includeColumnsSet = HashSet.fromValues(this.props.includeColumns);
                this.columnHeaders = this.columnHeaders.filter((columnHeader) =>
                    includeColumnsSet.exists(columnHeader.id)
                );
            } else {
                this.columnHeaders = [];
            }
        }

        if (this.props.showAllColumns) {
            for (let i = 0; i < this.columnHeaders.length; i++) {
                this.columnHeaders[i].isHidden = false;
            }
        }

        // Initializing the state to default.
        this.state = {
            loadingEntities: false,
            errorLoadingEntities: false,

            entities: [],

            entitiesFilter: this.props.entitiesFilter,
            searchText: null,
            orderByField: null,
            order: "asc",

            totalItemCount: null,
            displayLoader: false,
            openEditUserDialog: false,
            updateUser: null,
            roles: null,
        };
    }

    /**
     * Gets the display values for row's columns (where they aren't the default).
     */
    private getRowDisplayValues(row) {
        let entity = row as UserInfo;

        return {
            entityId: row.entityId,
            created: moment(entity.created).format("MM/DD/YYYY HH:mm"),
            email: (
                <SternumEllipsisToolTip
                    toolTipContent={row.email}
                    ellipsisContent={row.email}
                    className={this.props.classes.emailWidth}
                />
            ),
            firstName: row.firstName ? row.firstName : "-",
            lastName: row.lastName ? row.lastName : "-",
            isSternumAdmin: row.isSternumAdmin.toString(),
            status: this.getStatusComponent(entity.status),
            role: this.getRoleName(entity),
            lastLogin: entity.lastLogin ? moment(entity.lastLogin).format("MM/DD/YYYY HH:mm") : "-",
            actions: this.getActionComponent(entity),
        };
    }

    private getRoleName(user: UserInfo): string {
        let role = "Viewer";
        if (user.isInvited && user.role) {
            role = Utils.capitalize(user.role.displayName.toLowerCase());
        } else {
            if (user.roles && user.roles.length > 0) {
                role = Utils.capitalize(user.roles[0].toLowerCase());
            }
        }
        return role;
    }

    private getActionComponent = (entity) => {
        const { classes } = this.props;
        if (this.state.displayLoader) {
            return (
                <div className={classNames(classes.flexVMiddle)}>
                    {/* Loading circle */}
                    <CircularProgress size={15} className={classNames(classes.marginRight)} />
                </div>
            );
        } else {
            if (ServiceWire.getAuthorizationService().canEdit(ServerEntityType.USER)) {
                return (
                    <UsersListActions
                        user={entity}
                        displayLoader={this.state.displayLoader}
                        removeSelectedUser={this.removeSelectedUser}
                        editSelectedUser={this.onEditClose}
                        updateUsersList={this.updateUsersList}
                    />
                );
            }
            return "";
        }
    };

    /**
     * Occurs once the component is about to receive props.
     */
    UNSAFE_componentWillReceiveProps(nextProps: Readonly<AppProps>, nextContext: any): void {
        if (
            (!nextProps.entitiesFilter && this.props.entitiesFilter) ||
            (nextProps.entitiesFilter && !this.props.entitiesFilter) ||
            (nextProps.entitiesFilter &&
                this.props.entitiesFilter &&
                nextProps.entitiesFilter.isDifferentFrom(nextProps.entitiesFilter))
        ) {
            this.handleEntitiesFilterChanged(nextProps.entitiesFilter);
        }
    }

    /**
     * After update lifecycle
     */
    componentDidUpdate(prevProps) {
        // check if new user is created, if so, update the table.
        if (this.props.newUser) {
            // Check if new user is different from prevues one or it's first time we see a new user
            if (
                (prevProps.newUser && this.props.newUser.entityId !== prevProps.newUser.entityId) ||
                !prevProps.newUser
            ) {
                this.loadEntities();
            }
        }
    }

    /**
     * Occurs once the component finished its initialization process.
     */
    async componentDidMount() {
        const [rolesList, entities] = await Promise.all([
            ServiceWire.getSternumService().getSternumBasicRoles(),
            this.loadEntities(),
        ]);
        this.setState({ roles: rolesList });
    }

    /**
     * Delete selected user.
     */
    private removeSelectedUser = async (user: UserInfo) => {
        // Remove user from list
        try {
            // Find user's index
            let existingUsers = [...this.state.entities];
            const userIndexInArray = Utils.findFirstIndex(
                existingUsers,
                (userIter) => userIter.entityId == user.entityId
            );
            // Remove user from list
            existingUsers.splice(userIndexInArray, 1);
            // Update state with new list
            this.setState({ entities: existingUsers, totalItemCount: existingUsers.length });
        } catch (err) {
            console.log(err);
        } finally {
            this.setState({ displayLoader: false });
        }
    };

    /**
     * Resend invitation to selected user.
     */
    private updateUsersList = async (oldUser: UserInfo, newUser: UserInfo) => {
        try {
            // Find user's index
            let existingUsers = [...this.state.entities];
            const userIndexInArray = Utils.findFirstIndex(
                existingUsers,
                (userIter) => userIter.entityId == oldUser.entityId
            );
            // Replace user in list
            existingUsers.splice(userIndexInArray, 1, newUser);
            // Update state with new list
            this.setState({ entities: existingUsers, totalItemCount: existingUsers.length, displayLoader: false });
        } catch (err) {
        } finally {
            this.setState({ displayLoader: false });
        }
    };

    /**
     * Update selected user.
     */
    private onEditClose = (userRole: string) => {
        if (userRole) {
            this.loadEntities(false);
        }
    };

    /**
     * Close edit dialog
     */
    private closeEditDialog = () => {
        this.setState({ openEditUserDialog: false });
    };

    /**
     * Occurs once the component finished its initialization process.
     */
    private getStatusComponent(status: string) {
        switch (status) {
            case "ACTIVATED": {
                return (
                    <StatusDisplay
                        label={Utils.capitalize(status.toLowerCase())}
                        displayColoredBackground={true}
                        padding={true}
                        success={true}
                        centerText={true}
                    />
                );
            }
            case "INVITED": {
                return (
                    <StatusDisplay
                        label={Utils.capitalize(status.toLowerCase())}
                        displayColoredBackground={true}
                        padding={true}
                        medium={true}
                        centerText={true}
                    />
                );
            }
            case "EXPIRED": {
                return (
                    <StatusDisplay
                        label={`Invitation ${Utils.capitalize(status.toLowerCase())}`}
                        displayColoredBackground={true}
                        padding={true}
                        high={true}
                        centerText={true}
                    />
                );
            }
            default:
                return (
                    <StatusDisplay
                        label={"UNSENT"}
                        displayColoredBackground={true}
                        padding={true}
                        low={true}
                        centerText={true}
                    />
                );
        }
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        return (
            <Paper
                classes={{
                    root: classNames(classes.paperClass, this.props.paperClassNames),
                }}
                elevation={0}
            >
                <SternumTable
                    viewedColumnsSet={this.props.viewedColumnsSet}
                    pageSize={SternumConfiguration.getPageSize()}
                    amountOfLoadingPlaceholders={9}
                    totalItemCount={this.state.totalItemCount}
                    loadingItems={this.state.loadingEntities}
                    onRefreshClicked={() => this.onRefreshClicked()}
                    errorLoadingItems={this.state.errorLoadingEntities}
                    columnHeaders={this.columnHeaders}
                    listFilter={this.state.entitiesFilter}
                    filterComponentProvider={(onFilterChanged) => (
                        <EntitiesListFilterPopover
                            entitiesFilter={this.state.entitiesFilter}
                            onFilterChanged={onFilterChanged}
                        />
                    )}
                    orderByField={this.state.orderByField}
                    order={this.state.order}
                    rows={this.state.entities}
                    onFilterChanged={(listFilter) => this.handleEntitiesFilterChanged(listFilter)}
                    onSearchTextChanged={(searchText) => this.handleSearchTextChanged(searchText)}
                    onPageChanged={(pageNumber) => this.loadEntities()}
                    onOrderChanged={(orderByField, order) => this.handleOrderChanged(orderByField, order)}
                    getRowValues={(row) => this.getRowDisplayValues(row)}
                    toolbarState={new TableToolbarDisplayState(true, false, true, false, true, false, true)}
                />
            </Paper>
        );
    }

    /**
     * Occurs once the user refreshes the list.
     */
    private async onRefreshClicked() {
        await this.loadEntities();
    }

    /**
     * Occurs once the filters of the list are changed.
     */
    private handleEntitiesFilterChanged(entitiesFilter: ListFilter) {
        this.setState(
            {
                entitiesFilter: entitiesFilter != null ? (entitiesFilter as EntitiesFilter) : null,
            },
            async () => {
                await this.loadEntities();
            }
        );
    }

    /**
     * Occurs once search text is changed.
     */
    private handleSearchTextChanged(searchText) {
        // Debounce request to search entities.
        DebounceService.debounce(
            "getUsers",
            () => {
                this.setState(
                    {
                        searchText: searchText,
                    },
                    () => this.loadEntities()
                );
            },
            350
        );
    }

    /**
     * Occurs once sort order is changed.
     */
    private handleOrderChanged(orderByField, order) {
        this.setState(
            {
                orderByField: orderByField,
                order: order,
            },
            () => this.loadEntities()
        );
    }

    /**
     * Loads entities.
     */
    public async loadEntities(displayLoader?: boolean) {
        try {
            this.setState({
                loadingEntities: displayLoader === undefined ? true : displayLoader,
                errorLoadingEntities: false,
            });

            let invitedUsers, existingUsers;
            [invitedUsers, existingUsers] = await Promise.all([this.getInvitedUsers(), this.getExistingUsers()]);

            // Filter active user from users list
            existingUsers = existingUsers.filter(
                (user: UserInfo) =>
                    user.email !== ServiceWire.getAuthenticationService().getCachedAuthenticatedUser().email
            );

            const users = invitedUsers.concat(existingUsers);
            this.setState({
                loadingEntities: false,
                errorLoadingEntities: false,
                entities: [...users],
                totalItemCount: users.length,
            });
        } catch (error) {
            this.setState({
                loadingEntities: false,
                errorLoadingEntities: true,
            });
        }
    }

    /**
     * Load invited users
     */

    private async getInvitedUsers(): Promise<UserInfo[]> {
        return ServiceWire.getSternumService().getInvitedUsers(
            ServiceWire.getClientsService().getSelectedClientId(),
            this.state.entitiesFilter,
            this.state.searchText,
            this.state.orderByField,
            this.state.order
        );
    }

    /**
     * Load existing users
     */

    private async getExistingUsers(): Promise<UserInfo[]> {
        return ServiceWire.getSternumService().getUsers(
            ServiceWire.getClientsService().getSelectedClientId(),
            this.state.entitiesFilter,
            this.state.searchText,
            this.state.orderByField,
            this.state.order
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(mixedUsersListStyle)(MixedUsersList)));
