import DebounceService from "../services/DebounceService";
import HttpResponse from "../state/HttpResponse";

class WebUtils {
    /**
     * Copies given content into the clipboard.
     */
    public static copyContentToClipBoard(content: any) {
        const range = document.createRange();
        const selection = document.getSelection();
        const mark = document.createElement("span");
        mark.textContent = content;

        // prevents scrolling to the end of the page
        mark.style.position = "fixed";

        mark.style.clip = "rect(0, 0, 0, 0)";
        // used to preserve spaces and line breaks
        mark.style.whiteSpace = "pre";
        // do not inherit user-select (it may be `none`)
        mark.style.webkitUserSelect = "text";
        // @ts-ignore
        mark.style.msUserSelect = "text";
        mark.style.userSelect = "text";
        mark.addEventListener("copy", function (e) {
            e.stopPropagation();
        });

        document.body.appendChild(mark);

        // The following line is very important
        if (selection.rangeCount > 0) {
            selection.removeAllRanges();
        }

        range.selectNodeContents(mark);
        selection.addRange(range);
        document.execCommand("copy");
        document.body.removeChild(mark);
    }

    /**
     * Downloads an exported report.
     * @param exportReportHttpResponse The response of the HTTP request to export a report.
     */
    public static downloadReport(exportReportHttpResponse: HttpResponse) {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([exportReportHttpResponse.body]));
        const link = document.createElement("a");
        link.href = url;

        // Get file name from response header
        link.setAttribute("download", exportReportHttpResponse.headers["content-disposition"].split("filename=")[1]);

        // Append to html page
        document.body.appendChild(link);

        // Force download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }

    /**
     * Calls `onScrollReachedGoal` when element is scrolled far enough(specified by `activationRatio`).
     * When element is back to the top, `onScrollBackToTop` callback is fired if given
     */
    public static loadDataOnScroll = (
        event: any,
        requestId: string,
        onScrollReachedGoal: (scrollTop: number) => void,
        onScrollBackToTop?: (scrollTop: number) => void,
        activationRatio = 0.75
    ) => {
        const scrollTop = Math.floor(event.target.scrollTop);
        const scrollHeight = event.target.scrollHeight;
        const elementHeight = event.target.clientHeight;
        const scrollLocationRatio = (scrollTop + elementHeight) / scrollHeight;

        if (scrollLocationRatio >= activationRatio) {
            // If scrollTop is 0, don't call for load more data.
            if (scrollTop !== 0) {
                DebounceService.debounce(requestId, () => onScrollReachedGoal(scrollTop), 75);
            }
        }

        if (onScrollBackToTop) {
            const isTop = scrollTop < elementHeight / 5;
            // Check scroll location, if it's back to top, reload data
            if (isTop) {
                DebounceService.debounce(requestId, () => onScrollBackToTop(scrollTop), 350);
            }
        }
    };

    public static getUrlSearchParam(key: string): string {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(key);
    }

    public static getUrlSearchArrayParam(key: string): string[] | null {
        const urlParams = new URLSearchParams(window.location.search);
        const val = urlParams.get(key);

        if (!val) return null;
        return JSON.parse(val);
    }

    public static setUrlSearchParam(key: string, val: string | string[], shouldReplaceState = true) {
        // @ts-ignore
        const url = new URL(window.location);

        if (Array.isArray(val)) {
            val = encodeURIComponent(JSON.stringify(val));
        }
        url.searchParams.set(key, val);

        if (shouldReplaceState) {
            window.history.replaceState(null, "", url.toString());
        } else {
            window.history.pushState(null, "", url.toString());
        }
    }

    public static removeUrlSearchParam(key: string, shouldReplaceState = true) {
        // @ts-ignore
        const url = new URL(window.location);
        url.searchParams.delete(key);

        if (shouldReplaceState) {
            window.history.replaceState(null, "", url.toString());
        } else {
            window.history.pushState(null, "", url.toString());
        }
    }
}

export default WebUtils;
