import { makeStyles } from "@material-ui/core";

export const useIconStyle = makeStyles((theme) => ({
    "@keyframes moveUp": {
        "0%": {
            transform: "translateY(0)",
        },
        "50%": {
            transform: "translateY(-26px)",
        },
        "100%": {
            transform: "translateY(0)",
        },
    },

    "@keyframes logoAnimation": {
        "0%": {
            fill: "white",
            opacity: 0,
        },
        "40%": {
            fill: "#E7004C",
            opacity: 1,
        },
        "70%": {
            fill: "#E7004C",
            opacity: 0.7,
        },
        "100%": {
            fill: "white",
            opacity: 0,
        },
    },

    "@keyframes opacity80": createOpacityAnimationKeyframes(80),
    "@keyframes opacity70": createOpacityAnimationKeyframes(70),
    "@keyframes opacity60": createOpacityAnimationKeyframes(60),
    "@keyframes opacity50": createOpacityAnimationKeyframes(50),
    "@keyframes opacity40": createOpacityAnimationKeyframes(40),
    "@keyframes opacity30": createOpacityAnimationKeyframes(30),
    "@keyframes opacity20": createOpacityAnimationKeyframes(20),

    svg: {
        "& path": {
            transform: "translateY(26px)",
        },
    },

    chipDots: {
        "& path:nth-child(7n)": createOpacityAnimation(50),
        "& path:nth-child(7n-1)": createOpacityAnimation(70),
        "& path:nth-child(7n-2)": createOpacityAnimation(30),
        "& path:nth-child(7n-3)": createOpacityAnimation(80),
        "& path:nth-child(7n-4)": createOpacityAnimation(40),
        "& path:nth-child(7n-5)": createOpacityAnimation(60),
        "& path:nth-child(7n-6)": createOpacityAnimation(20),
    },

    moveUpAnimation: {
        animationName: "$moveUp",
        animationDuration: "3s",
        animationIterationCount: "infinite",
    },

    logo: {
        "& path": {
            animationName: "$logoAnimation",
            animationDuration: "3s",
            animationIterationCount: "infinite",
        },
    },

    opacityAnimation80: createOpacityAnimation(80),
    opacityAnimation70: createOpacityAnimation(70),
    opacityAnimation60: createOpacityAnimation(60),
    opacityAnimation50: createOpacityAnimation(50),
    opacityAnimation40: createOpacityAnimation(40),
    opacityAnimation30: createOpacityAnimation(30),
    opacityAnimation20: createOpacityAnimation(20),
}));

function getOpacityAnimation<T>(key: T): string {
    return `$opacity${key}`;
}

function createOpacityAnimationKeyframes(key: string | number) {
    return {
        "0%": {
            opacity: 0,
        },
        [`${key}%`]: {
            opacity: 1,
        },
        "100%": {
            opacity: 0,
        },
    };
}

function createOpacityAnimation(key: string | number) {
    return {
        animationName: getOpacityAnimation(key),
        animationDuration: "3s",
        animationIterationCount: "infinite",
    };
}
