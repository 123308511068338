import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../../CommonStyle";

const deployToProductionDialogStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        checkboxPadding: {
            marginRight: "0",
        },

        infoCircleIconSize: {
            fontSize: "16px",
        },
        explanationTypography: {
            width: theme.spacing(40),
        },
    });

export default deployToProductionDialogStyle;
