import React, { useCallback, useEffect, useState } from "react";
import ReactJoyride, { ACTIONS, CallBackProps, LIFECYCLE, STATUS, Step } from "react-joyride";

import { useAutoUpdatedRef } from "../../hooks";
import { AnomalyIsDemoDataTagVisibleContext, StepActionContext } from "./StepAction.context";
import { Tooltip } from "./Tooltip";
import { anomalyOnboardingEmitter } from "./AnomalyOnboarding.emitter";

export interface AnomalyOnboardingProps {
    storageKey: string;
    steps: Step[];
    isDemoDataTagVisible?: boolean;
    disableScrolling?: boolean;
    onStartOnboarding?: () => unknown;
    onFinishOnboarding?: () => unknown;
}

export function AnomalyOnboarding({
    storageKey,
    steps,
    isDemoDataTagVisible = true,
    disableScrolling = false,
    onStartOnboarding,
    onFinishOnboarding,
}: AnomalyOnboardingProps) {
    const [runOnboarding, setRunOnboarding] = useState(false);
    const [step, setStep] = useState(0);
    const resetOnboardingEventRef = useAutoUpdatedRef((onboardingStorageKey: string) => {
        if (onboardingStorageKey === storageKey) {
            setRunOnboarding(true);
            setStep(0);
        }
    });

    useEffect(() => {
        const handleAnomalyResetOnboardingEvent = (onboardingStorageKey: string) => {
            resetOnboardingEventRef.current(onboardingStorageKey);
        };

        anomalyOnboardingEmitter.addListener("resetOnboarding", handleAnomalyResetOnboardingEvent);

        return () => {
            anomalyOnboardingEmitter.removeListener("resetOnboarding", handleAnomalyResetOnboardingEvent);
        };
    }, []);

    useEffect(() => {
        const hadOnboardingAlready = localStorage.getItem(storageKey);

        if (hadOnboardingAlready === null || hadOnboardingAlready === "false") {
            setRunOnboarding(true);
            setStep(0);
        }
    }, [storageKey]);

    useEffect(() => {
        if (runOnboarding) {
            onStartOnboarding?.();
        } else {
            onFinishOnboarding?.();
        }
    }, [runOnboarding]);

    const joyrideCallback = useCallback(
        (data: CallBackProps) => {
            if (data.action === ACTIONS.CLOSE || data.action === ACTIONS.SKIP || data.status === STATUS.FINISHED) {
                localStorage.setItem(storageKey, "true");
                setRunOnboarding(false);
            }

            if (data.action === ACTIONS.NEXT && data.lifecycle === LIFECYCLE.COMPLETE) {
                setStep(data.index + 1);
            }
        },
        [storageKey]
    );

    return (
        <AnomalyIsDemoDataTagVisibleContext.Provider value={isDemoDataTagVisible}>
            <StepActionContext.Provider value={setStep}>
                <ReactJoyride
                    steps={steps}
                    tooltipComponent={Tooltip}
                    continuous
                    run={runOnboarding}
                    stepIndex={step}
                    disableScrolling={disableScrolling}
                    disableScrollParentFix={true}
                    callback={joyrideCallback}
                    locale={{
                        last: "Finish",
                    }}
                    hideCloseButton={true}
                    styles={{
                        options: {
                            zIndex: 2000,
                        },
                    }}
                />
            </StepActionContext.Provider>
        </AnomalyIsDemoDataTagVisibleContext.Provider>
    );
}
