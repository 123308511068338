import ListFilter from "./ListFilter";

/**
 * Represents a filter of device definition libraries.
 */
class DeviceDefinitionLibrariesFilter implements ListFilter {
    /**
     * Constructor.
     */
    constructor(
        public deviceDefinitionId?: string,
        public libraryId?: string,
        public usedLibraryId?: string,
        public deviceDefinitionVersionId?: string
    ) {}

    /**
     * Returns whether filter is defined, or all values are nulls.
     */
    public isActive(): boolean {
        return !!(this.deviceDefinitionId || this.libraryId || this.usedLibraryId || this.deviceDefinitionVersionId);
    }

    /**
     * Returns whether current filter is different than given other filter.
     * @param other The other list filter to check with.
     */
    public isDifferentFrom(other: ListFilter): boolean {
        if (!(other instanceof DeviceDefinitionLibrariesFilter)) {
            return true;
        }

        let otherDeviceDefinitionLibrariesFilter = other as DeviceDefinitionLibrariesFilter;

        return (
            otherDeviceDefinitionLibrariesFilter.deviceDefinitionId !== this.deviceDefinitionId ||
            otherDeviceDefinitionLibrariesFilter.libraryId !== this.libraryId ||
            otherDeviceDefinitionLibrariesFilter.usedLibraryId !== this.usedLibraryId ||
            otherDeviceDefinitionLibrariesFilter.deviceDefinitionVersionId !== this.deviceDefinitionVersionId
        );
    }
}

export default DeviceDefinitionLibrariesFilter;
