import ActionType from "../ActionType";
import { CvesReducerActions } from "./CvesReducerActions";
import { EntitiesState, getDefaultEntitiesState } from "../../state/EntitiesState";
import HashSet from "../../infra/HashSet";
import HashMap from "../../infra/HashMap";
import CveInfo from "../../state/CveInfo";

/**
 * Reducer for all cve-related actions.
 */
const cvesReducer = (
    state: EntitiesState<CveInfo> = getDefaultEntitiesState<CveInfo>(),
    action: CvesReducerActions
) => {
    switch (action.type) {
        case ActionType.FetchCve: {
            return {
                idToEntityMap: state.idToEntityMap,
                loadingIds: HashSet.copyAndAdd(state.loadingIds, action.cveId),
                errorIds: state.errorIds,
                detectedDeviceCount: null,
            };
        }

        case ActionType.FetchedCveSuccessfully: {
            return {
                idToEntityMap: HashMap.copyAndPut(state.idToEntityMap, action.cve.cveId, action.cve),
                loadingIds: state.loadingIds.exists(action.cve.cveId)
                    ? HashSet.copyAndRemove(state.loadingIds, action.cve.cveId)
                    : state.loadingIds,
                errorIds: state.errorIds.containsKey(action.cve.cveId)
                    ? HashMap.copyAndRemove(state.errorIds, action.cve.cveId)
                    : state.errorIds,
                detectedDeviceCount: null,
            };
        }

        case ActionType.ErrorFetchingCve: {
            return {
                idToEntityMap: state.idToEntityMap,
                loadingIds: HashSet.copyAndRemove(state.loadingIds, action.cveId),
                errorIds: HashMap.copyAndPut(state.errorIds, action.cveId, action.error),
                detectedDeviceCount: null,
            };
        }

        default:
            return state;
    }
};

export default cvesReducer;
