import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { sternumTheme } from "../App";
import commonStyle from "../CommonStyle";

const issuesListStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        investigateIcon: {
            color: theme.palette.primary.main,
            fontSize: "0.8125rem",
            marginRight: theme.spacing(1),
        },
        investigateText: {
            fontSize: "0.8125rem",
        },
        traceLinkText: {
            fontSize: "0.8125rem",
            fontWeight: 400,
            marginRight: theme.spacing(1),
            "&:hover": {
                textDecoration: "underline",
            },
        },
        traceLinkIcon: {
            fontSize: "0.8125rem",
            paddingRight: theme.spacing(3),
        },

        attackRow: {
            backgroundColor: `rgba(209, 0, 39, 0.06) !important`,

            "&:hover": {
                backgroundColor: `rgba(209, 0, 39, 0.06) !important`,
                cursor: "pointer" as "pointer",
            },
        },
        viewedTraceIcon: {
            fontSize: "0.8125rem",
            marginRight: theme.spacing(1),
        },
        issueRowHover: {
            "&:hover": {
                backgroundColor: `${sternumTheme.lightGrey.main} !important`,
            },
        },
        eventName: {},

        traceTypeText: {
            width: "8vw",
        },

        deviceIdText: {
            width: "4vw",
        },
    });

export default issuesListStyle;
