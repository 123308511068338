import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../../CommonStyle";

const triggerDefinitionStepStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: {
            paddingTop: theme.spacing(5),
            paddingRight: theme.spacing(5),
            paddingBottom: theme.spacing(5),
            paddingLeft: theme.spacing(5),
        },

        triggerList: {
            height: theme.spacing(37),
            overflowY: "auto",
        },

        triggerItem: {
            "&:hover": {
                background: "#F0F0F0",
            },
        },

        displayName: {
            width: theme.spacing(77),
        },

        heading: {
            fontSize: theme.typography.pxToRem(15),
            flexBasis: "33.33%",
            flexShrink: 0,
        },

        secondaryHeading: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
        },

        smallIconButton: {
            padding: "4px",
        },
        explanationTextTypographyLineHeight: {
            lineHeight: "1.5",
        },
    });

export default triggerDefinitionStepStyle;
