import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: "24px",
        height: "24px",
    },

    numberBox: {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#E7004C",
        borderRadius: "50%",
    },

    number: {
        fontSize: 14,
        fontWeight: 500,
        color: "#fff",
    },
}));
