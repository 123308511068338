import React from "react";

import { SternumIconProps } from "./SternumIcon.types";

export const CheckOutlinedIcon = React.forwardRef<SVGSVGElement, SternumIconProps>(function SternumOutlineInfoIcon(
    { color = "#3FC47C", height = 94, width = 94, ...props },
    ref
) {
    return (
        <svg ref={ref} width={width} height={height} viewBox="0 0 94 94" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.40039 46.8511C5.40039 23.9643 24.1146 5.25012 47.0013 5.25012C69.8881 5.25012 88.6023 23.9643 88.6023 46.8511C88.6023 69.7378 69.8881 88.452 47.0013 88.452C24.1146 88.452 5.40039 69.7378 5.40039 46.8511ZM47.0013 10.6932C27.1208 10.6932 10.8435 26.9705 10.8435 46.8511C10.8435 66.7317 27.1208 83.0089 47.0013 83.0089C66.8819 83.0089 83.1592 66.7317 83.1592 46.8511C83.1592 26.9705 66.8819 10.6932 47.0013 10.6932Z"
                fill={color || "currentColor"}
                fillOpacity="0.6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M65.4512 33.9254C66.5131 34.9892 66.5116 36.7124 65.4478 37.7743L43.4032 59.78C42.3401 60.8413 40.6182 60.8405 39.556 59.7783L28.5532 48.7754C27.4903 47.7126 27.4903 45.9894 28.5532 44.9266C29.616 43.8638 31.3392 43.8638 32.402 44.9266L41.4822 54.0067L61.6024 33.922C62.6661 32.8601 64.3893 32.8616 65.4512 33.9254Z"
                fill={color || "currentColor"}
                fillOpacity="0.6"
            />
        </svg>
    );
});
