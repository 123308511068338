/**
 * Represents a Sternum permission
 */
class SternumPermission {
    /**
     * Constructor.
     */
    constructor(public permissionName: string, public resourceName: string, public operation: string) {}

    /**
     * Converts json to info object.
     */
    public static fromJsonObject(jsonObject: Object) {
        return new SternumPermission(
            jsonObject["permission_name"],
            jsonObject["resource_name"],
            jsonObject["operation"]
        );
    }

    public toJsonObject() {
        return {
            permission_name: this.permissionName,
            resource_name: this.resourceName,
            operation: this.operation,
        };
    }
}

export default SternumPermission;
