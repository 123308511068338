import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import { GlobalState } from "../../../lib/state/GlobalState";
import { useDashboardFreeStyle } from "./DashboardFree.style";
import { ConnectedDevices } from "./ConnectedDevices";
import { SimulateAttack } from "./SimulateAttack";
import { InteractiveCoverage } from "./InteractiveCoverage";
import ServiceWire from "../../../lib/services/ServiceWire";
import { LoadingDevices } from "../../LoadingDevices/LoadingDevices";
import { DeviceMetrics } from "../../../lib/services/DevicesMetricsPollingService";
import { Glances } from "./Glances/Glances.component";
import SpinnerLoader from "../../SUI/SternumLoaders/SpinnerLoader";
import { VulnerabilitiesCoverage } from "./VulnerabilitiesCoverage";
import { setDetectedDeviceCountAction } from "../../../lib/redux/devices/SetDetectedDeviceCount";

export interface DashboardFreeProps {
    sidebarOpen: boolean;
}

export interface DashboardFreeComponentState {
    waitingForDevices: boolean;
    showConfirmation: boolean;
    wasInitiallyLoaded: boolean;
}

const mapStateToProps = (state: GlobalState, ownProps: DashboardFreeProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onDeviceCountKnown: (c: number) => dispatch(setDetectedDeviceCountAction(c)),
    };
};

type DashboardFreePropsWithHOC = DashboardFreeProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

function DashboardFreeComponent({ onDeviceCountKnown }: DashboardFreePropsWithHOC) {
    const classes = useDashboardFreeStyle();

    const [loadingState, setLoadingState] = useState<DashboardFreeComponentState>({
        waitingForDevices: true,
        showConfirmation: false,
        wasInitiallyLoaded: false,
    });

    const handleMetrics = useCallback(({ totalDevices }: DeviceMetrics) => {
        if (totalDevices > 0) {
            onDeviceCountKnown(totalDevices);
            ServiceWire.getDeviceMetricsPollingService().unsubscribe("Dashboard");
            setLoadingState((s) => ({
                ...s,
                showConfirmation: s.wasInitiallyLoaded,
                waitingForDevices: false,
            }));
        }

        setLoadingState((s) => ({
            ...s,
            wasInitiallyLoaded: true,
        }));
    }, []);

    useEffect(() => {
        ServiceWire.getDeviceMetricsPollingService().subscribe("Dashboard", handleMetrics, true);

        return () => {
            ServiceWire.getDeviceMetricsPollingService().unsubscribe("Dashboard");
        };
    }, []);

    if (!loadingState.wasInitiallyLoaded) {
        return (
            <div className={classes.spinnerContainer}>
                <SpinnerLoader />
            </div>
        );
    }

    if (loadingState.waitingForDevices) {
        return <LoadingDevices confirmMode={false} />;
    }

    if (!loadingState.waitingForDevices && loadingState.showConfirmation) {
        return (
            <LoadingDevices
                confirmMode
                confirmButtonText="Go to Dashboard"
                onConfirm={() =>
                    setLoadingState((s) => ({
                        ...s,
                        showConfirmation: false,
                    }))
                }
            />
        );
    }

    return (
        <div className={classes.root}>
            <div className={classes.tilesContainer}>
                <div className={classNames(classes.tileItem, classes.tileConnectedDevices)}>
                    <ConnectedDevices />
                </div>
                <div className={classNames(classes.tileItem, classes.tileVulnerabilities)}>
                    <VulnerabilitiesCoverage />
                </div>
                <div className={classNames(classes.tileItem, classes.tileCoverage)}>
                    <InteractiveCoverage />
                </div>
                <div className={classNames(classes.tileItem, classes.tileSimulateAttack)}>
                    <SimulateAttack />
                </div>
                <div className={classes.tileGlances}>
                    <Glances />
                </div>
            </div>
        </div>
    );
}

export const DashboardFree: React.FC<DashboardFreeProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardFreeComponent);
