import { Action } from "redux";
import ActionType from "../ActionType";

/**
 * Occurs once fetching a trace has started.
 */
export interface FetchGeneratedEventAction extends Action<ActionType> {
    type: ActionType.FetchGeneratedEvent;
    generatedEventId: string;
}

/**
 * Occurs once fetching a trace has started.
 */
export function fetchGeneratedEventAction(generatedEventId: string): FetchGeneratedEventAction {
    return {
        type: ActionType.FetchGeneratedEvent,
        generatedEventId: generatedEventId,
    };
}
