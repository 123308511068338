import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    inner: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        rowGap: "60px",
        columnGap: "80px",
        marginTop: "32px",
    },

    stepContainer: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        width: "300px",
    },

    arrowIcon: {
        position: "absolute",
        top: "50%",
        right: "-50px",
    },

    stepTitle: {
        marginTop: "24px",
        fontWeight: 600,
        fontSize: "18px",
        textTransform: "uppercase",
    },

    iconBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "50px",
        height: "50px",
        background: "#EEF1FA",
        borderRadius: "10px",
    },

    stepText: {
        marginTop: "12px",
        color: "#555B61",
        fontSize: "14px",
    },
}));
