import { Box, Link, Tooltip, Typography } from "@material-ui/core";
import classNames from "classnames";
import React, { useState } from "react";
import { CopyTextComponent } from "../../../../CopyTextComponent";
import { InfoCircleIcon } from "../../../../SUI/SternumIcon";
import { useStyles } from "./SimulationVulnerabilities.style";

export interface SimulationVulnerability {
    id: number;
    name: string;
    description: string;
    cwes: { name: string; link: string }[];
}

export interface SimulationVulnerabilitiesProps {
    categoryId: number;
    categoryName: string;
    categoryDescription: string;
    vulnerabilities: SimulationVulnerability[];
}

export function SimulationVulnerabilities({
    categoryId,
    categoryName,
    categoryDescription,
    vulnerabilities,
}: SimulationVulnerabilitiesProps) {
    const classes = useStyles();

    const [selectedVulnerability, setSelectedVulnerability] = useState<SimulationVulnerability | null>(null);

    return (
        <div className={classes.container}>
            <Box display="flex">
                <Typography className={classes.attackCategoryTitle}>{categoryName}</Typography>
                <Tooltip classes={{ tooltip: classes.infoTooltip }} title={<div>{categoryDescription}</div>}>
                    <Box display="inline-flex" ml={1}>
                        <InfoCircleIcon color="#1B6FDE" />
                    </Box>
                </Tooltip>
            </Box>

            <div className={classes.attackCategoryVulnerabilitiesContainer}>
                {vulnerabilities.map((vulnerability) => (
                    <Tooltip
                        key={vulnerability.id}
                        classes={{ tooltip: classes.infoTooltip }}
                        title={<div>{vulnerability.name}</div>}
                    >
                        <div
                            role="button"
                            className={classNames(
                                classes.vulnerabilityContainer,
                                selectedVulnerability?.id === vulnerability.id && "selected"
                            )}
                            onClick={() => setSelectedVulnerability(vulnerability)}
                        >
                            <Typography variant="caption" className={classes.vulnerabilityTitle}>
                                {vulnerability.name}
                            </Typography>
                        </div>
                    </Tooltip>
                ))}
            </div>

            {selectedVulnerability && (
                <Box mt={2}>
                    <Typography variant="caption" className={classes.vulnerabilityDescription}>
                        {selectedVulnerability.description}
                    </Typography>

                    <div className={classes.cwesContainer}>
                        {selectedVulnerability.cwes.map((cwe) => (
                            <Link key={cwe.name} href={cwe.link} underline="none" target="_blank">
                                <div className={classNames(classes.cweItem)}>
                                    <Typography variant="caption" className={classes.cweTitle}>
                                        {cwe.name}
                                    </Typography>
                                </div>
                            </Link>
                        ))}
                    </div>

                    <Box mt={2}>
                        <CopyTextComponent
                            variant="filled"
                            text={`./attack_simulation_kit ${categoryId} ${selectedVulnerability.id}`}
                            notificationText="Command is copied to the clipboard"
                        />
                    </Box>
                </Box>
            )}
        </div>
    );
}
