import { Action } from "redux";
import ActionType from "../ActionType";

/**
 * Defines the data for a trace not being viewed anymore action.
 */
export interface TraceNotViewedAction extends Action<ActionType> {
    type: ActionType.TraceNotViewed;
}

/**
 * Action creator for trace not being viewed anymore.
 */
export function traceNotViewedAction(): TraceNotViewedAction {
    return {
        type: ActionType.TraceNotViewed,
    };
}
