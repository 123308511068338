import React from "react";
import { Tooltip } from "@material-ui/core";

import { useAuthInfoIconStyle } from "./AuthInfoIcon.style";
import { SternumOutlineInfoIcon } from "../../SUI/SternumIcon";
import classNames from "classnames";

export interface AuthenticationContainerProps {
    className?: string;
    children?: React.ReactNode;
}

export function AuthInfoIcon({ className, children }: AuthenticationContainerProps) {
    const classes = useAuthInfoIconStyle();

    return (
        <Tooltip
            placement="top"
            arrow
            classes={{ tooltip: classes.tooltipBackground }}
            title={<span className={classes.tooltipTitle}>{children}</span>}
        >
            <span className={classNames(classes.icon, className)}>
                <SternumOutlineInfoIcon />
            </span>
        </Tooltip>
    );
}
