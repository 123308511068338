import { WithStyles, withStyles } from "@material-ui/core/styles";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { GlobalState } from "../../lib/state/GlobalState";
import NewDeviceView from "../NewDeviceView/NewDeviceView";
import deviceDefinitionViewPageStyle from "./DeviceDefinitionViewPageStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof deviceDefinitionViewPageStyle> {
    sideBarOpen: boolean;

    location;
    match;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {};
};

/**
 * Holds the devices list in the app.
 */
class DeviceDefinitionViewPage extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {};
    }

    /**
     /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        return (
            <NewDeviceView
                fullScreenDisplay={true}
                displayBackButton={false}
                displayViewForDeviceDefinition={true}
                deviceDefinitionVersionId={this.props.match.params.deviceDefinitionVersionId}
                hideShowInContext={true}
            />
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(deviceDefinitionViewPageStyle)(DeviceDefinitionViewPage)));
