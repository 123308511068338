import ActionType from "../ActionType";
import { CurrentlyViewedGeneratedEventIdReducerActions } from "./CurrentlyViewedGeneratedEventIdReducerActions";

/**
 * Reducer for the currently viewed trace.
 */
const currentlyViewedGeneratedEventIdReducer = (
    state: string,
    action: CurrentlyViewedGeneratedEventIdReducerActions
) => {
    switch (action.type) {
        case ActionType.ViewGeneratedEvent:
            return action.generatedEventId;

        case ActionType.GeneratedEventNotViewed:
            return null;

        default:
            return state;
    }
};

export default currentlyViewedGeneratedEventIdReducer;
