import { Typography } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import Utils from "../../lib/infra/Utils";
import AnalyticsService from "../../lib/services/AnalyticsService";
import ServiceWire from "../../lib/services/ServiceWire";
import AggregatedEventInfo from "../../lib/state/AggregatedEventInfo";
import AggregateOverProperty from "../../lib/state/AggregateOverProperty";
import EventCountsOverTimeAggregationInfo from "../../lib/state/EventCountsOverTimeAggregationInfo";
import { GlobalState } from "../../lib/state/GlobalState";
import SternumBarChart from "../SUI/SternumAMCharts/SternumBarChart";
import { ChartEmptyIcon } from "../SUI/SternumIcon/SternumIcon";
import GraphLoader from "../SUI/SternumLoaders/GraphLoader";
import eventCountOverTimeGraphStyle from "./EventCountOverTimeGraphStyle";
import GraphLabelPoint from "./GraphLabelPoint";

import moment from "moment";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    loadingGraph: boolean;
    errorLoadingGraph: boolean;
    deviceAggregatedEventsInfo?: EventCountsOverTimeAggregationInfo;
    doNotDisplayLoading: boolean;

    timeFormat: string;
    uiTimeFormat: string;

    startTime: Date;
    endTime: Date;

    interval: number;
    intervalTimeUnit: string;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof eventCountOverTimeGraphStyle> {
    /**
     * On which entity (device or device definition) are we displaying the graph.
     */
    entityId: string;

    /**
     * Over which property we should aggregate.
     */
    aggregateOverProperty: AggregateOverProperty;

    /**
     * If set to true, will display the different events stacked. Otherwise, will just display the amount of events per time slot.
     */
    showStackedEvents: boolean;

    /**
     * On which date to start the filtering.
     */
    startTime: Date;

    /**
     * On which date to end the filtering.
     */
    endTime: Date;

    /**
     * Whether we should show loading or not.
     */
    doNotDisplayLoading: boolean;

    /**
     * The version we're filtering on.
     */
    deviceDefinitionVersionId?: string;

    /**
     * The max ticks for y axis
     */
    maxAxisTicksY: number;

    /** Indicate if it's html tooltip */
    isHtmlTooltip?: boolean;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {};
};

/**
 * Displays a bar of metrics.
 */
class EventCountOverTimeGraph extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            loadingGraph: false,
            errorLoadingGraph: false,
            deviceAggregatedEventsInfo: null,
            doNotDisplayLoading: false,

            startTime: this.props.startTime,
            endTime: this.props.endTime,

            timeFormat: "HH:mm",
            uiTimeFormat: "HH:mm",

            interval: null,
            intervalTimeUnit: null,
        };
    }

    /**
     * Occurs once the component is mounted.
     * We fetch the cve if needed here.
     */
    async componentDidMount() {
        await this.loadGraph(this.state.startTime, this.state.endTime, this.state.doNotDisplayLoading);
    }

    /**
     * Occurs once the component is about to receive props.
     */
    async UNSAFE_componentWillReceiveProps(nextProps: Readonly<AppProps>, nextContext: any) {
        if (nextProps.startTime || nextProps.startTime) {
            this.setState(
                {
                    startTime: nextProps.startTime,
                    endTime: nextProps.endTime,
                    doNotDisplayLoading: nextProps.doNotDisplayLoading,
                },
                async () => {
                    await this.loadGraph(this.state.startTime, this.state.endTime, this.state.doNotDisplayLoading);
                }
            );
        }
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        if (this.state.loadingGraph || (!this.state.deviceAggregatedEventsInfo && !this.state.errorLoadingGraph)) {
            return (
                <div className={classes.emptyDataContainer}>
                    <GraphLoader />
                </div>
            );
        }

        if (this.state.errorLoadingGraph) {
            return <Typography className={classNames(classes.commonDangerColor)}>Error loading graph...</Typography>;
        } else if (this.state.deviceAggregatedEventsInfo) {
            // Generating a map between an event identifier to a map between x axes label and the event related to it.
            let eventIdentifierToXAxesIdentifierToEventMap: Record<
                string,
                Record<string, AggregatedEventInfo>
            > = this.createEventIdentifierToXAxesIdentifierToEventMap();

            // Generating a map between a raw time and its label information.
            let rawTimeToGraphLabelPointMap: Record<string, GraphLabelPoint> = this.getRawTimeToGraphLabelPointMap();

            // Generating a map between an x axes label to its graph label point.
            let formattedTimeToGraphLabelPointMap: Record<string, GraphLabelPoint> =
                this.getFormattedTimeToGraphLabelPointMap(rawTimeToGraphLabelPointMap);

            const barChartData = this.getBarChartData(
                eventIdentifierToXAxesIdentifierToEventMap,
                rawTimeToGraphLabelPointMap
            );

            // @ts-ignore
            if (barChartData.datasets.every(({ data }) => data.length === 0)) {
                return (
                    <div className={classes.emptyDataContainer}>
                        <div className={classes.emptyDataInner}>
                            <ChartEmptyIcon />
                            <Typography className={classes.emptyDataText}>You dont have any data yet</Typography>
                        </div>
                    </div>
                );
            }

            const isLogarithmicScale: boolean = (() => {
                let max = barChartData.datasets[0]?.["data"]?.["value"] || 0;
                let min = max;

                barChartData.datasets.forEach((dataset) => {
                    const value = dataset?.["data"]?.["value"] || 0;

                    max = Math.max(value, max);

                    if (value) {
                        min = Math.min(value, min);
                    }
                });

                // Show logarithmic scale when min is at least 5 times lower than max value
                return min * 5 < max;
            })();

            return (
                <div className={classNames(classes.root, classes.zIndex50)}>
                    <SternumBarChart
                        id="count-over-time-bar-chart"
                        height="100%"
                        data={barChartData}
                        displayValueLabels={false}
                        applyExtraMax={false}
                        isCustomTooltip={this.props.isHtmlTooltip}
                        limitLabelWidth={false}
                        isLogarithmicScale={isLogarithmicScale}
                        minAxisYValue={isLogarithmicScale ? 1 : undefined}
                    />
                </div>
            );
        }
    }

    /**
     * Gets a map between a formatted time to its graph label point.
     */
    private getFormattedTimeToGraphLabelPointMap(
        rawTimeToGraphLabelPointMap: Record<string, GraphLabelPoint>
    ): Record<string, GraphLabelPoint> {
        let formattedTimeToGraphLabelPointMap: Record<string, GraphLabelPoint> = {};

        for (const rawTime in rawTimeToGraphLabelPointMap) {
            if (rawTimeToGraphLabelPointMap.hasOwnProperty(rawTime)) {
                const graphLabelPoint: GraphLabelPoint = rawTimeToGraphLabelPointMap[rawTime];
                formattedTimeToGraphLabelPointMap[graphLabelPoint.formattedTime] = graphLabelPoint;
            }
        }

        return formattedTimeToGraphLabelPointMap;
    }

    /**
     * Gets a map between an event identifier as received from the API and its GraphLabelPoint object that holds information over the label itself.
     */
    private getRawTimeToGraphLabelPointMap(): Record<string, GraphLabelPoint> {
        let rawTimeToGraphLabelPointMap: Record<string, GraphLabelPoint> = {};

        for (const rawTime in this.state.deviceAggregatedEventsInfo.dateToEventsMap) {
            if (this.state.deviceAggregatedEventsInfo.dateToEventsMap.hasOwnProperty(rawTime)) {
                rawTimeToGraphLabelPointMap[rawTime] = new GraphLabelPoint(
                    rawTime,
                    moment(parseInt(rawTime)).format(this.state.uiTimeFormat)
                );
            }
        }

        return rawTimeToGraphLabelPointMap;
    }

    /**
     * Loads the graph of the aggregated events.
     */
    private async loadGraph(startTime: Date, endTime: Date, doNotDisplayLoading: boolean) {
        try {
            // Setting state to loading.
            this.setState({
                loadingGraph: !doNotDisplayLoading,
                errorLoadingGraph: false,
            });

            let hourDifference = (endTime.getTime() - startTime.getTime()) / 1000.0 / 60.0 / 60.0;
            let minuteDifference = (endTime.getTime() - startTime.getTime()) / 1000.0 / 60.0;
            let amountOfGraphPointsToDisplay = 61;
            let interval = (minuteDifference / amountOfGraphPointsToDisplay) | 0 || 1; // Converting to integer
            let intervalTimeUnit = "MINUTE";
            let timeFormat = "HH:mm";
            let uiTimeFormat = "HH:mm";

            if (hourDifference > 24) {
                timeFormat = "MM/dd HH:mm";
                uiTimeFormat = "MM/DD HH:mm";
            }

            // Fetch graph info.
            const deviceAggregatedEventsInfo: EventCountsOverTimeAggregationInfo =
                await ServiceWire.getSternumService().getEventCountsOverTimeAggregation(
                    this.props.entityId,
                    interval,
                    intervalTimeUnit,
                    startTime.getTime(),
                    endTime.getTime(),
                    this.props.aggregateOverProperty,
                    false,
                    timeFormat,
                    amountOfGraphPointsToDisplay,
                    this.props.deviceDefinitionVersionId
                );

            // Set state to finish loading.
            this.setState({
                deviceAggregatedEventsInfo: deviceAggregatedEventsInfo,
                loadingGraph: false,
                errorLoadingGraph: false,
                timeFormat: timeFormat,
                uiTimeFormat: uiTimeFormat,
                interval: interval,
                intervalTimeUnit: intervalTimeUnit,
            });
        } catch (error) {
            AnalyticsService.error("EventCountOverTimeGraph:loadGraph", error.message);

            // Set error state.
            this.setState({
                loadingGraph: false,
                errorLoadingGraph: true,
            });
        }
    }

    /**
     * Creates a map between an event identifier and the dataset it has.
     */
    private createEventIdentifierToXAxesIdentifierToEventMap(): Record<string, Record<string, AggregatedEventInfo>> {
        let eventIdentifierToXAxesIdentifierToEventMap: Record<string, Record<string, AggregatedEventInfo>> = {};

        // Going through the API results, and breaking it into a dataset per event.
        for (let dateString in this.state.deviceAggregatedEventsInfo.dateToEventsMap) {
            if (this.state.deviceAggregatedEventsInfo.dateToEventsMap.hasOwnProperty(dateString)) {
                // Current date's aggregated events.
                let aggregatedEventInfoCollection: AggregatedEventInfo[] =
                    this.state.deviceAggregatedEventsInfo.dateToEventsMap[dateString];

                for (const aggregatedEventInfo of aggregatedEventInfoCollection) {
                    // Extracting the event identifier for the data set.
                    const eventIdentifier: string = aggregatedEventInfo.displayName;

                    // Initializing a new data set.
                    if (!eventIdentifierToXAxesIdentifierToEventMap[eventIdentifier]) {
                        eventIdentifierToXAxesIdentifierToEventMap[eventIdentifier] = {};
                    }

                    eventIdentifierToXAxesIdentifierToEventMap[eventIdentifier][dateString] = aggregatedEventInfo;
                }
            }
        }

        return eventIdentifierToXAxesIdentifierToEventMap;
    }

    /**
     * Returns the data object needed for the bar chart.
     */
    private getBarChartData(
        eventIdentifierToXAxesIdentifierToEventMap: Record<string, Record<string, AggregatedEventInfo>>,
        rawTimeToGraphLabelPointMap: Record<string, GraphLabelPoint>
    ) {
        // Sorting the graph x axes label points.
        let sortedGraphLabelPoints: GraphLabelPoint[] = Utils.sortCollection(
            Object.keys(rawTimeToGraphLabelPointMap).map((rawTime) => rawTimeToGraphLabelPointMap[rawTime]),
            (graphLabelPoint) => graphLabelPoint.rawTime
        );

        return {
            labels: sortedGraphLabelPoints.map((graphLabelPoint) => graphLabelPoint.formattedTime),
            datasets: this.getNonStackedEventsDatasetCollection(
                sortedGraphLabelPoints,
                eventIdentifierToXAxesIdentifierToEventMap
            ),
            tooltips: this.getTooltipContent(eventIdentifierToXAxesIdentifierToEventMap, rawTimeToGraphLabelPointMap),
        };
    }

    /**
     * Gets the content of the tooltip for the graph.
     */
    private getTooltipContent(
        eventIdentifierToXAxesIdentifierToEventMap: Record<string, Record<string, AggregatedEventInfo>>,
        formattedTimeToGraphLabelPointMap: Record<string, GraphLabelPoint>
    ): Record<number, Record<string, number>> {
        {
            const aggregatedLabels: Record<number, { formattedTime: string; event: Record<string, number> }> = {};
            const aggregatedFormattedLabels: Record<number, Record<string, number>> = {};
            for (let eventIdentifier in eventIdentifierToXAxesIdentifierToEventMap) {
                let xAxesIdentifierToEventMap = eventIdentifierToXAxesIdentifierToEventMap[eventIdentifier];

                for (let eventInfoDate in xAxesIdentifierToEventMap) {
                    const eventInfo = xAxesIdentifierToEventMap[eventInfoDate];
                    if (!aggregatedLabels[eventInfoDate]) {
                        aggregatedLabels[eventInfoDate] = {};
                    }
                    aggregatedLabels[eventInfoDate][eventIdentifier] = eventInfo.count;
                }
            }

            for (let timeIdentifier in formattedTimeToGraphLabelPointMap) {
                const timeData = formattedTimeToGraphLabelPointMap[timeIdentifier];
                if (aggregatedLabels[timeData.rawTime]) {
                    const eventData = aggregatedLabels[timeData.rawTime];
                    aggregatedFormattedLabels[timeData.formattedTime] = eventData;
                }
            }
            return aggregatedFormattedLabels;
        }
    }

    /**
     * Get non stacked events dataset collection.
     * @param sortedGraphLabelPoints Collection of all the x axes labels.
     * @param eventIdentifierMap A map between an event identifier to a map between x axes label to its aggregated event info.
     */
    private getNonStackedEventsDatasetCollection(
        sortedGraphLabelPoints: GraphLabelPoint[],
        eventIdentifierMap: Record<string, Record<string, AggregatedEventInfo>>
    ): Object[] {
        let dataPoints: number[] = [];
        let backgroundColors: string[] = [];

        // Going through all the x axes labels we have in the graph.
        for (const graphLabelPoint of sortedGraphLabelPoints) {
            // We will count how many events we have for current x axes label.
            let totalEventCount: number = 0;
            let interestLevelToCountMap: Record<string, number> = {};

            for (let eventIdentifier in eventIdentifierMap) {
                if (eventIdentifierMap.hasOwnProperty(eventIdentifier)) {
                    for (const xAxesIdentifier in eventIdentifierMap[eventIdentifier]) {
                        if (eventIdentifierMap[eventIdentifier].hasOwnProperty(xAxesIdentifier)) {
                            if (xAxesIdentifier === graphLabelPoint.rawTime) {
                                // If the current x axes label for the current event identifier is the same as
                                // the current x axes label we're iterating over, we add event count to
                                // the total count.
                                const aggregatedEventInfo: AggregatedEventInfo =
                                    eventIdentifierMap[eventIdentifier][xAxesIdentifier];

                                // Summing total event count.
                                totalEventCount = totalEventCount + aggregatedEventInfo.count;

                                // Saving interest level.
                                if (aggregatedEventInfo.interestLevels) {
                                    for (const interestLevel of aggregatedEventInfo.interestLevels) {
                                        if (!interestLevelToCountMap[interestLevel]) {
                                            interestLevelToCountMap[interestLevel] = 0;
                                        }
                                        interestLevelToCountMap[interestLevel] =
                                            interestLevelToCountMap[interestLevel] + 1;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            // Getting the background color based on interest.
            let backgroundColor = null;
            if (interestLevelToCountMap["HIGH"]) {
                backgroundColor = "rgb(209, 0, 39)";
            } else if (interestLevelToCountMap["MEDIUM"]) {
                backgroundColor = "rgb(231,180,22)";
            } else {
                backgroundColor = "#d6ebf5";
            }

            backgroundColors.push(backgroundColor);
            dataPoints.push(totalEventCount);
        }

        const transformedDataPoints = dataPoints;
        return transformedDataPoints.map((point, index) => {
            return {
                backgroundColor: backgroundColors[index],
                data: { value: point },
            };
        });
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(eventCountOverTimeGraphStyle)(EventCountOverTimeGraph));
