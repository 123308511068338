import { makeStyles } from "@material-ui/core";

export const useConnectedDevicesStyle = makeStyles((theme) => {
    const marginTopUnit = 8;

    return {
        root: {},

        descriptionText: {
            fontSize: 11,
            lineHeight: "20px",
            color: "#6d7894",
        },

        spinner: {
            margin: "0 auto 16px 0",
            width: "100%",
        },

        centered: {
            textAlign: "center",
        },

        linkContainer: {
            textAlign: "center",
            margin: "4px 0 5px 0",
        },

        link: {
            fontSize: 11,
            color: "#1B6FDE",
        },

        marginTop: {
            marginTop: marginTopUnit,
        },

        marginTopLarge: {
            marginTop: 2 * marginTopUnit,
        },

        marginBottom: {
            marginBottom: marginTopUnit,
        },
        protectMoreLink: {
            textDecoration: "none",
        },
    };
});
