import { makeStyles } from "@material-ui/core";
import commonStyle from "../../../components/CommonStyle";

const useSternumImprovedButtonStyles = makeStyles((theme) => {
    return {
        ...commonStyle(theme),

        root: {
            height: "45px",
            borderRadius: "14px",
            fontFamily: "Inter",
            borderColor: "#E4E4E4",
            textTransform: "none",
            boxShadow: "none",
            "&.regular": {
                backgroundColor: "#E7004C",
                color: "#FFFFFF",
                "&:hover": {
                    backgroundColor: "#B80D3B",
                    boxShadow: "none",
                },
                "&.mod-disabled": {
                    backgroundColor: "#F8F8F8",
                    color: "#595C62",
                    pointerEvents: "all !important",
                    cursor: "not-allowed",
                },
            },
            "&.regular-outlined": {
                border: "1px solid #E7004C",
                background: "none",
                color: "#E7004C",
                transition: "all 0.2s ease",
                "&:hover": {
                    backgroundColor: "#E7004C",
                    color: "#FFF",
                    boxShadow: "none",
                },
                // "&.mod-disabled": {
                //     backgroundColor: "#F8F8F8",
                //     color: "#595C62",
                //     pointerEvents: "all !important",
                //     cursor: "not-allowed",
                // },
            },
            "&.regularWithDisabled": {
                backgroundColor: "#E7004C",
                color: "#FFFFFF",
                "&:hover": {
                    backgroundColor: "#B80D3B",
                    boxShadow: "none",
                },
                "&.mod-disabled": {
                    backgroundColor: "#E7004C",
                    color: "#FFFFFF",
                    opacity: 0.4,
                    pointerEvents: "all !important",
                    cursor: "not-allowed",
                },
            },
            "&.gray": {
                backgroundColor: "#E5E8EC",
                color: "#595C62",
                "&:hover": {
                    backgroundColor: "#D9DBDE",
                    boxShadow: "none",
                },
                "&.mod-disabled": {
                    backgroundColor: "#F8F8F8",
                    color: "#595C62",
                    pointerEvents: "all !important",
                    cursor: "not-allowed",
                },
            },
            "&.blue": {
                backgroundColor: "#1B6FDE",
                color: "#FFFFFF",
                "&:hover": {
                    backgroundColor: "#1656AB",
                    boxShadow: "none",
                },
                "&.mod-disabled": {
                    backgroundColor: "#DCE1E9",
                    color: "#595C62",
                    pointerEvents: "all !important",
                    cursor: "not-allowed",
                },
            },
            "&.white": {
                backgroundColor: "#FFFFFF",
                color: "#000000",
                "&:hover": {
                    backgroundColor: "#FFFFFF",
                    boxShadow: "none",
                },
                "&.mod-disabled": {
                    backgroundColor: "#FFFFFF",
                    color: "#000000",
                    pointerEvents: "all !important",
                    cursor: "not-allowed",
                },
            },

            "&.text-dark": {
                background: "none",
                "&:hover": {
                    backgroundColor: "#E5E8EC",
                    boxShadow: "none",
                },
            },

            "&.text-blue": {
                background: "none",
                color: "#1B6FDE",
                "&:hover": {
                    backgroundColor: "#E5E8EC",
                    boxShadow: "none",
                },
            },
        },

        buttonContent: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            fontWeight: 500,
        },
    };
});

export default useSternumImprovedButtonStyles;
