import * as am4maps from "@amcharts/amcharts4/maps";
import { Typography } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { flatMap, get, isEmpty, last, maxBy, set, uniq, uniqueId } from "lodash";
import * as React from "react";
import { renderToString } from "react-dom/server";
import { GraphResult } from "../../../lib/infra/GraphUtils";
import ReceivedDefinitionsResponse from "../../../lib/services/events/ReceivedDefinitionsResponse";
import ServiceWire from "../../../lib/services/ServiceWire";
import Utils from "../../../lib/infra/Utils";
import DeviceDefinitionVersionInfo from "../../../lib/state/DeviceDefinitionVersionInfo";
import AggregationFunctionType from "../../../lib/state/Visualisation/AggregationFunctionType";
import {
    VisualisationDataSourceGroupByCustomFields,
    VisualisationGroupByOrder,
} from "../../../lib/state/Visualisation/VisualisationConfigurationGroupBy";
import VisualisationDataSource from "../../../lib/state/Visualisation/VisualisationDataSource";
import { sternumColorMap } from "../../ColorPicker/sternumColorMap";
import SternumFlag from "../../SternumFlag/SternumFlag";
import SternumGeoMap from "../../SUI/SternumAMCharts/SternumGeoMap";
import { ChartEmptyIcon } from "../../SUI/SternumIcon/SternumIcon";
import UIDataVisualisationConfiguration from "../../VisualisationConfigurationComponent/entities/UIDataVisualisationConfiguration";
import GraphLoadingDisplay from "../../VisualisationConfigurationComponent/GraphLoadingDisplay/GraphLoadingDisplay";
import getVisualisationDataSource from "../../VisualisationConfigurationComponent/utils/getVisualisationDataSource";
import { isChangedDataSourceConfiguration } from "../../VisualisationConfigurationComponent/utils/isChangedDataSourceConfiguration";
import visualisationConfigurationPresentationGeoMapStyle from "./VisualisationConfigurationPresentationGeoMapStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    loadingGraph: boolean;
    errorLoadingGraph: boolean;

    graphResult: any;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof visualisationConfigurationPresentationGeoMapStyle> {
    entityId: string;
    startTime: number;
    endTime: number;
    dataVisualisationConfigurations: UIDataVisualisationConfiguration[];
    deviceDefinitionVersions: DeviceDefinitionVersionInfo[];
    receivedDefinitionsResponse: Record<string, ReceivedDefinitionsResponse>;
    displayTooltipDataPerDeviceDefinitionVersion?: boolean;
    refreshComponentId?: number;
}

const deviceIcon = `
	<svg width="16" height="14" viewBox="0 0 14 12" fill="none">
		<rect x="1.35718" y="0.5" width="11.2857" height="10.4286" rx="2.5" stroke="white"/>
		<circle cx="10.3215" cy="8.60718" r="0.75" fill="white"/>
		<path d="M1.85718 6.57141H13" stroke="white"/>
	</svg>
`;

class VisualisationConfigurationPresentationGeoMap extends React.Component<AppProps, AppState> {
    graphId: string;

    constructor(props: AppProps) {
        super(props);

        this.graphId = uniqueId();

        this.state = {
            loadingGraph: false,
            errorLoadingGraph: false,

            graphResult: null,
        };
    }

    async componentDidMount() {
        if (this.props.displayTooltipDataPerDeviceDefinitionVersion) {
            await this.loadGraphDetailed(this.props.startTime, this.props.endTime);
        } else {
            await this.loadGraph(this.props.startTime, this.props.endTime);
        }
    }

    async componentDidUpdate(nextProps: Readonly<AppProps>, prevState: Readonly<AppState>) {
        const nextDataVisualisationConfigurations: UIDataVisualisationConfiguration[] =
            nextProps.dataVisualisationConfigurations || [];

        const changedDataSource =
            nextDataVisualisationConfigurations.length !== this.props.dataVisualisationConfigurations.length ||
            nextDataVisualisationConfigurations.some(
                (newConfiguration, index) =>
                    !this.props.dataVisualisationConfigurations[index] ||
                    isChangedDataSourceConfiguration(
                        newConfiguration,
                        this.props.dataVisualisationConfigurations[index]
                    )
            );

        if (
            nextProps.refreshComponentId !== this.props.refreshComponentId ||
            (nextProps.startTime && nextProps.startTime !== this.props.startTime) ||
            (nextProps.endTime && nextProps.endTime !== this.props.endTime) ||
            changedDataSource ||
            nextProps.entityId !== this.props.entityId
        ) {
            if (this.props.displayTooltipDataPerDeviceDefinitionVersion) {
                await this.loadGraphDetailed(this.props.startTime, this.props.endTime);
            } else {
                await this.loadGraph(this.props.startTime, this.props.endTime);
            }
        }
    }

    render() {
        const { classes } = this.props;

        for (let i = 0; i < this.props.dataVisualisationConfigurations.length; ++i) {
            if (
                this.props.dataVisualisationConfigurations[i].aggregationFunctionType ===
                AggregationFunctionType.UNIQUE_COUNT
            ) {
                return (
                    <Typography className={classNames(classes.commonDangerColor)}>
                        Can not use Geo Map for Unique Count.
                    </Typography>
                );
            }
        }

        if (this.props.dataVisualisationConfigurations.length === 0) {
            return (
                <div className={classes.emptyDataContainer}>
                    <div className={classes.emptyDataInner}>
                        <ChartEmptyIcon />
                        <Typography className={classes.emptyDataText}>You dont have any data yet</Typography>
                    </div>
                </div>
            );
        }

        if (this.state.errorLoadingGraph) {
            return <Typography className={classNames(classes.commonDangerColor)}>Error loading graph...</Typography>;
        }

        if (this.state.graphResult) {
            const geomapData = this.getDataset();

            if (geomapData.datasets.every(({ data }) => !data)) {
                return (
                    <div className={classes.emptyDataContainer}>
                        <div className={classes.emptyDataInner}>
                            <ChartEmptyIcon />
                            <Typography className={classes.emptyDataText}>You dont have any data yet</Typography>
                        </div>
                    </div>
                );
            }
            return (
                <div className={classNames(classes.root)}>
                    <SternumGeoMap
                        id={`data-source-geomap-${this.graphId}`}
                        data={geomapData}
                        height="100%"
                        tooltipCallback={this.handleTooltipGeneration}
                        useHeatMap={true}
                        heatMapRange={sternumColorMap[last(this.props.dataVisualisationConfigurations).color]}
                    />
                </div>
            );
        }

        return <GraphLoadingDisplay />;
    }

    private handleTooltipGeneration = (value: string, target: am4maps.MapPolygon) => {
        const tooltipData = target.tooltipDataItem.dataContext;
        const countryFlag = <SternumFlag countryCode={tooltipData["id"]} />;

        return `
			<div>
				<div style="display: flex;">
					${renderToString(countryFlag)}	
					<span style="padding-left: 5px; font-size:14px; font-weight:600; font-family: Inter;"><strong>{name}</strong></span>
				</div>

				<div style="max-height: 150px; overflow: auto; padding-right: 5px;">
					${Object.keys(tooltipData["data"])
                        .map((dataSource) => {
                            const configuration: UIDataVisualisationConfiguration = tooltipData["tooltipContent"][
                                "configurations"
                            ].find((config) => config.dataSourceKey === dataSource);

                            const dataSourceTitle = `
								<div>${configuration.dataSourceLabel}</div>
							`;

                            const deviceDefinitionVersionDivs = Object.keys(tooltipData["data"][dataSource])
                                .map((deviceDefinitionVersion) => {
                                    return `
									<div style="display: flex; justify-content: space-between;">
										<div style="display: flex; align-items: center; margin-right: 10px;">
											<div style="margin-right: 5px;">${deviceIcon}</div> 
											<div style="margin-bottom: 4px;">${deviceDefinitionVersion}</div>
										</div>
										<div><b>${tooltipData["data"][dataSource][deviceDefinitionVersion]}</b></div>
									</div>
								`;
                                })
                                .join("");

                            return `
								<div style="margin-top: 7px;">
									${dataSourceTitle}
									${deviceDefinitionVersionDivs}
								</div>	
							`;
                        })
                        .join("")}
				</div>
			</div>
		`;
    };

    /**
     * Gets the dataset to use for the graph.
     */
    private getDataset() {
        const receivedDataPoints =
            (this.state.graphResult["data_sources"] as Record<number, number | { [key: string]: number }>) || {};

        const allDataSources = Object.values(receivedDataPoints);
        const countriesSet = new Set();

        allDataSources.forEach((dataSource) => {
            const valuesPerDeviceDefinitionVersion = Object.values(dataSource);
            valuesPerDeviceDefinitionVersion.forEach((deviceDefinitionVersionObj) => {
                Object.keys(deviceDefinitionVersionObj).forEach((key) => {
                    countriesSet.add(key);
                });
            });
        });

        const countries = [...countriesSet];

        return {
            datasets: countries.map((country) => {
                return {
                    backgroundColor: sternumColorMap[last(this.props.dataVisualisationConfigurations).color].main,
                    tooltipContent: { configurations: this.props.dataVisualisationConfigurations },
                    country,
                    data: this.props.dataVisualisationConfigurations.reduce((acc, configuration) => {
                        const dataPerDeviceDefinitionVersion = receivedDataPoints[configuration.dataSourceKey];

                        if (dataPerDeviceDefinitionVersion) {
                            const data = {};

                            Object.keys(dataPerDeviceDefinitionVersion).forEach((deviceDefinitionVersion) => {
                                data[deviceDefinitionVersion] =
                                    dataPerDeviceDefinitionVersion[deviceDefinitionVersion][country] || 0;
                            });

                            acc[configuration.dataSourceKey] = data;
                        }

                        return acc;
                    }, {}),
                    totalCount: this.props.dataVisualisationConfigurations.reduce((acc, configuration) => {
                        const dataPerDeviceDefinitionVersion = receivedDataPoints[configuration.dataSourceKey];

                        if (dataPerDeviceDefinitionVersion) {
                            let data = 0;
                            Object.keys(dataPerDeviceDefinitionVersion).forEach((deviceDefinitionVersion) => {
                                data += dataPerDeviceDefinitionVersion[deviceDefinitionVersion][country] || 0;
                            });

                            acc = data;
                        }

                        return acc;
                    }, 0),
                };
            }),
        };
    }

    /**
     * Loads the graph of the aggregated events.
     */
    private async loadGraph(startTime: number, endTime: number) {
        const dataSources = this.getDataSources();
        if (isEmpty(dataSources)) return;

        try {
            // Setting state to loading.
            this.setState({
                loadingGraph: true,
                errorLoadingGraph: false,
            });

            let hourDifference = (endTime - startTime) / 1000.0 / 60.0 / 60.0;
            let amountOfGraphPointsToDisplay = 60;
            let timeFormat = "HH:mm";

            if (hourDifference > 24) {
                timeFormat = "MM/dd HH:mm";
            }

            let graphResult: GraphResult = {
                data_sources: {},
            };

            const allDeviceDefinitionVersions = uniq(flatMap(dataSources, "deviceDefinitionVersionIds"));
            const allTraceDefinitionIds = uniq(dataSources.map((dataSource) => dataSource.traceDefinitionId));

            graphResult = await ServiceWire.getVisualisationApiService().getArgumentValueOverTime(
                this.props.entityId,
                startTime,
                endTime,
                false,
                timeFormat,
                amountOfGraphPointsToDisplay,
                this.getDataSources(),
                {
                    enabled: true,
                    field: VisualisationDataSourceGroupByCustomFields.COUNTRY,
                    limit: 200,
                    order_by: VisualisationGroupByOrder.TOP,
                },
                allDeviceDefinitionVersions,
                allTraceDefinitionIds
            );

            this.setState({
                graphResult: graphResult,
                loadingGraph: false,
                errorLoadingGraph: false,
            });
        } catch (error) {
            this.setState({
                loadingGraph: false,
                errorLoadingGraph: true,
            });
        }
    }

    private async loadGraphDetailed(startTime: number, endTime: number) {
        const dataSources = this.getDataSources();
        if (isEmpty(dataSources)) return;

        try {
            this.setState({
                loadingGraph: true,
                errorLoadingGraph: false,
            });

            let hourDifference = (endTime - startTime) / 1000.0 / 60.0 / 60.0;
            let amountOfGraphPointsToDisplay = 60;
            let timeFormat = "HH:mm";

            if (hourDifference > 24) {
                timeFormat = "MM/dd HH:mm";
            }

            let graphResult: GraphResult = {
                data_sources: {},
            };

            const dataSourcesRequests = this.getDataSourcesRequests(dataSources);

            const allDeviceDefinitionVersions = uniq(flatMap(dataSources, "deviceDefinitionVersionIds"));
            const allTraceDefinitionIds = uniq(dataSources.map((dataSource) => dataSource.traceDefinitionId));

            const responses = await Promise.all(
                dataSourcesRequests.map((dataSourceBatch) => {
                    return ServiceWire.getVisualisationApiService().getArgumentValueOverTime(
                        this.props.entityId,
                        startTime,
                        endTime,
                        false,
                        timeFormat,
                        amountOfGraphPointsToDisplay,
                        dataSourceBatch,
                        {
                            enabled: true,
                            field: VisualisationDataSourceGroupByCustomFields.COUNTRY,
                            limit: 200,
                            order_by: VisualisationGroupByOrder.TOP,
                        },
                        allDeviceDefinitionVersions,
                        allTraceDefinitionIds
                    );
                })
            );

            const deviceDefinitionVersionIdToVersionMap = this.getDeviceDefinitionVersionIdToVersionMap();

            /**
             * {
             * 	1: {
             * 		ddve1: ...
             * 		ddve2: ...
             * 	},
             * 	2: {
             * 		ddve1: ...
             * 	},
             * }
             */
            responses.forEach((response, index) => {
                const dataSourceBatch = dataSourcesRequests[index];

                dataSourceBatch.forEach((dataSource) => {
                    const versionId = dataSource.deviceDefinitionVersionIds[0];
                    const version = deviceDefinitionVersionIdToVersionMap[versionId];
                    if (!version) return;

                    set(
                        graphResult,
                        ["data_sources", dataSource.dataSourceKey, version.getVersionName()],
                        get(response, ["data_sources", dataSource.dataSourceKey])
                    );
                });
            });

            this.setState({
                graphResult: graphResult,
                loadingGraph: false,
                errorLoadingGraph: false,
            });
        } catch (error) {
            this.setState({
                loadingGraph: false,
                errorLoadingGraph: true,
            });
        }
    }

    private getDeviceDefinitionVersionIdToVersionMap = (): Record<string, DeviceDefinitionVersionInfo> => {
        return this.props.deviceDefinitionVersions.reduce((acc, version) => {
            acc[version.entityId] = version;
            return acc;
        }, {});
    };

    private getDataSources(): VisualisationDataSource[] {
        // in case geo map created with multiple data sources, take the first one
        return this.props.dataVisualisationConfigurations.length > 0
            ? [getVisualisationDataSource(this.props.dataVisualisationConfigurations[0])]
            : [];
        /*
        return this.props.dataVisualisationConfigurations.map((dataVisualisationConfiguration) =>
            getVisualisationDataSource(dataVisualisationConfiguration)
        );
        */
    }

    private getDataSourcesRequests(dataSources: VisualisationDataSource[]): VisualisationDataSource[][] {
        const deviceDefinitionVersionBuckets: Record<string, string[]> = {};
        dataSources.forEach((dataSource) => {
            deviceDefinitionVersionBuckets[dataSource.dataSourceKey] = [...dataSource.deviceDefinitionVersionIds];
        });

        const dataSourceKeyToDataSourceMap: Record<string, VisualisationDataSource> = {};
        dataSources.forEach((dataSource) => {
            dataSourceKeyToDataSourceMap[dataSource.dataSourceKey] = dataSource;
        });

        const largestBucket = maxBy(Object.values(deviceDefinitionVersionBuckets), "length");

        const requests = [];
        while (!isEmpty(largestBucket)) {
            const dataSourceBatch: VisualisationDataSource[] = [];

            Object.keys(dataSourceKeyToDataSourceMap).forEach((key) => {
                if (!isEmpty(deviceDefinitionVersionBuckets[key])) {
                    dataSourceBatch.push({
                        ...dataSourceKeyToDataSourceMap[key],
                        deviceDefinitionVersionIds: [deviceDefinitionVersionBuckets[key].shift()],
                    });
                }
            });

            requests.push(dataSourceBatch);
        }

        return requests;
    }
}

export default withStyles(visualisationConfigurationPresentationGeoMapStyle)(
    VisualisationConfigurationPresentationGeoMap
);
