enum ActionType {
    // Modals
    OpenModal,
    CloseModal,
    EnterModalFullscreen,
    ExitModalFullscreen,

    // Notifications
    ShowNotification,
    HideNotification,

    // Devices
    FetchDevice,
    FetchedDeviceSuccessfully,
    ErrorFetchingDevice,
    SetDetectedDeviceCount,

    // Trace
    FetchTrace,
    FetchedTraceSuccessfully,
    ErrorFetchingTrace,

    // GeneratedEvent
    FetchGeneratedEvent,
    FetchedGeneratedEventSuccessfully,
    ErrorFetchingGeneratedEvent,

    // Libraries
    FetchLibrary,
    FetchedLibrarySuccessfully,
    ErrorFetchingLibrary,

    // Used libraries
    FetchUsedLibrary,
    FetchedUsedLibrarySuccessfully,
    ErrorFetchingUsedLibrary,

    // Cves
    FetchCve,
    FetchedCveSuccessfully,
    ErrorFetchingCve,

    // View
    ViewDevice,
    DeviceNotViewed,
    ViewTrace,
    TraceNotViewed,
    ViewLibrary,
    LibraryNotViewed,
    ViewUsedLibrary,
    UsedLibraryNotViewed,
    ViewCve,
    CveNotViewed,
    ViewGeneratedEvent,
    GeneratedEventNotViewed,

    // Issue
    ResolveAllIssues,

    // Put Issue Status
    PutIssueStatus,
    PutIssueStatusSuccessfully,
    PutIssueStatusError,

    // Get issue resolve reasons
    GetIssueResolveReasons,
    GetIssueResolveReasonsInProgress,
    GetIssueResolveReasonsSuccessfully,
    GetIssueResolveReasonsError,

    SetSelectedIssueInfo,
    SetIssueStateByIssueId,

    // Pending issues count
    FetchPendingIssuesCount,
    FetchedPendingIssuesCountSuccessfully,
    ErrorFetchingPendingIssuesCount,

    // Triggers
    RefreshTriggersHitList,

    // Users
    UpdateUserInfo,
    SetUserAction,

    // DashboardRegular
    FetchAlertsStatistics,
    FetchedAlertsStatisticsSuccessfully,
    ErrorFetchingAlertsStatistics,
    DashboardRegularSetCategoryView,
    DashboardRegularSetIsOnlyUnresolved,
    DashboardRegularSetIsOnlyAnomalyInsights,
    DashboardRegularSetCategoryGraphView,
    DashboardRegularSetCategoryTimeRange,
    DashboardRegularSetCategoryTimeRangeCustom,
    DashboardRegularResetAlertsFilter,
    DashboardRegularToggleDateRangeInAlertsFilter,
    DashboardRegularSetAlertsCountFilter,
    DashboardRegularToggleAlertCategory,
    DashboardRegularToggleAlertsHeatmapInteractions,
    DashboardRegularGetOnboardingState,
    DashboardRegularGetOnboardingStateInProgress,
    DashboardRegularGetOnboardingStateSuccessfully,
    DashboardRegularGetOnboardingStateError,
    DashboardRegularSetOnboardingState,
    DashboardRegularSetOnboardingStateInProgress,
    DashboardRegularSetOnboardingStateSuccessfully,
    DashboardRegularSetOnboardingStateError,
    DashboardRegulatSetAlertTableExpanded,
}

export default ActionType;
