import React, { useEffect, useState } from "react";
import { UniqueCountDataContext, UniqueCountMetric } from "./UniqueCountContext";
import ServiceWire from "../../lib/services/ServiceWire";
import UIDataVisualisationConfiguration from "../VisualisationConfigurationComponent/entities/UIDataVisualisationConfiguration";

interface UniqueCountProviderProps {
    children: React.ReactNode;
    dataVisualisationConfigurations: UIDataVisualisationConfiguration[];
}

export function UniqueCountProvider({ children, dataVisualisationConfigurations }: UniqueCountProviderProps) {
    const [availableMetrics, setAvailableMetrics] = useState<UniqueCountMetric[]>([]);

    useEffect(() => {
        (async () => {
            if (
                !dataVisualisationConfigurations ||
                dataVisualisationConfigurations.length === 0 ||
                !dataVisualisationConfigurations[0]
            ) {
                return;
            }

            const deviceDefinitionVersionIds: string[] = [];

            dataVisualisationConfigurations.forEach((dataVisualisationConfiguration) => {
                deviceDefinitionVersionIds.push(...dataVisualisationConfiguration.deviceDefinitionVersionIds);
            });

            const data = await ServiceWire.getSternumService().getReceivedEventDefinitionCounters(
                ServiceWire.getClientsService().getSelectedClientId(),
                deviceDefinitionVersionIds,
                dataVisualisationConfigurations[0].entitiesFilter.createdFrom,
                dataVisualisationConfigurations[0].entitiesFilter.createdTo
            );

            const traces: UniqueCountMetric[] = data.trace.map((trace) => ({
                value: trace.identifier,
                displayName: trace.display_name,
                entityType: trace.entity_type,
                counter: trace.counter,
                label: `${trace.display_name}`,
                isSpecialField: trace.is_special_argument,
                metricType: "trace" as "trace",
            }));

            const argumentsData: UniqueCountMetric[] = data.arguments.map((arg) => ({
                value: arg.identifier,
                displayName: arg.display_name,
                entityType: arg.entity_type,
                counter: arg.counter,
                label: `${arg.display_name}`,
                isSpecialField: arg.is_special_argument,
                metricType: "argument" as "argument",
            }));

            setAvailableMetrics([...traces, ...argumentsData].sort((a, b) => b.counter - a.counter));
        })();
    }, [dataVisualisationConfigurations ? JSON.stringify(dataVisualisationConfigurations) : null]);

    return <UniqueCountDataContext.Provider value={availableMetrics}>{children}</UniqueCountDataContext.Provider>;
}
