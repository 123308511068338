import { makeStyles, Theme } from "@material-ui/core";

export const useIssuesVisualisationStyle = makeStyles((theme: Theme) => ({
    root: {
        position: "relative",
        width: "100%",
    },

    blur: {
        filter: "blur(2px)",
    },

    loadingOverlay: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1,
    },
}));
