import React, { useEffect, useMemo, useRef, useState } from "react";
import moment from "moment";
import classNames from "classnames";
import { connect } from "react-redux";
import { Tooltip, Typography } from "@material-ui/core";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import { GlobalState } from "../../../../lib/state/GlobalState";
import {
    breakIntoNumberSegments,
    colorPalette,
    fillTimeValuesGaps,
    getLabelFormatBySelectedTimeRange,
    getMaxOrMinIssuesCount,
    groupIssuesWithinTimeInterval,
    groupIssuesWithinTimeIntervalForCustomTimeRange,
    WEEK_INTERVAL_IN_MS,
} from "../../../../lib/infra/Alerts.utils";
import {
    AlertsCountFilter,
    CategoryTimeRange,
    DashboardAlertsDateRange,
} from "../../../../lib/state/DashboardRegularState";
import {
    setAlertsCountFilterAction,
    toggleDateRangeInAlertsFilterAction,
} from "../../../../lib/redux/dashboardRegular";
import {
    IssuesStatisticsCategoryData,
    IssuesStatisticsGroupedCategoryData,
} from "../../../../lib/state/IssuesStatisticsHeatmap";
import { useAlertsHeatmapStyle } from "./AlertsHeatmap.style";

const defaultLegendSegments = [
    { from: 0, to: 0 },
    { from: 0, to: 20 },
    { from: 21, to: 40 },
    { from: 41, to: 60 },
    { from: 61, to: 80 },
    { from: 81, to: 100 },
];

export interface AlertsHeatmapProps {}

const mapStateToProps = (state: GlobalState, ownProps: AlertsHeatmapProps) => {
    return {
        categoryTimeRange: state.dashboardRegular.categoryTimeRange,
        categoryTimeRangeCustomFrom: state.dashboardRegular.categoryTimeRangeCustomFrom,
        categoryTimeRangeCustomTo: state.dashboardRegular.categoryTimeRangeCustomTo,
        issuesStatistics: state.dashboardRegular.alertsStatistics?.category_count,
        alertsFilter: state.dashboardRegular.alertsFilter,
        alertsCountFilter: state.dashboardRegular.alertsCountFilter,
        interactionsEnabled: state.dashboardRegular.alertsHeatmapInteractionsEnabled,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setAlertsCountFilter: (filter: AlertsCountFilter | null) => dispatch(setAlertsCountFilterAction(filter)),
        toggleDateRangeInAlertsFilter: (category: string, range: DashboardAlertsDateRange) =>
            dispatch(toggleDateRangeInAlertsFilterAction(category, range)),
    };
};

type AlertsHeatmapPropsWithHOC = AlertsHeatmapProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

function AlertsHeatmapComponent({
    issuesStatistics: issuesStatisticsProps,
    alertsFilter,
    alertsCountFilter,
    setAlertsCountFilter,
    toggleDateRangeInAlertsFilter,
    categoryTimeRange,
    categoryTimeRangeCustomFrom,
    categoryTimeRangeCustomTo,
    interactionsEnabled,
}: AlertsHeatmapPropsWithHOC) {
    const classes = useAlertsHeatmapStyle();

    const [legendSegments, setLegendSegments] = useState<AlertsCountFilter[]>(defaultLegendSegments);

    const containerRef = useRef<HTMLDivElement | null>(null);
    const chartRef = useRef<am4charts.XYChart | null>(null);

    const issuesStatistics = useMemo(() => {
        let issuesStatistics: (IssuesStatisticsCategoryData | IssuesStatisticsGroupedCategoryData)[] = [];

        if (issuesStatisticsProps) {
            if (categoryTimeRange === CategoryTimeRange.TimeRange) {
                const timeDistance = moment(categoryTimeRangeCustomTo).diff(
                    moment(categoryTimeRangeCustomFrom),
                    "days"
                );

                issuesStatistics = groupIssuesWithinTimeIntervalForCustomTimeRange(issuesStatisticsProps, timeDistance);
            } else {
                issuesStatistics =
                    categoryTimeRange === CategoryTimeRange.LastMonth
                        ? groupIssuesWithinTimeInterval(issuesStatisticsProps, WEEK_INTERVAL_IN_MS)
                        : fillTimeValuesGaps(issuesStatisticsProps);
            }
        }

        if (alertsCountFilter) {
            issuesStatistics = issuesStatistics.map((point) => {
                if (alertsCountFilter.from === 0 && alertsCountFilter.to === 0 && point.issues_count === 0) {
                    return point;
                }

                if (point.issues_count >= alertsCountFilter.from && point.issues_count <= alertsCountFilter.to) {
                    return point;
                }

                return {
                    ...point,
                    interactionDisabled: true,
                };
            });
        }

        return issuesStatistics;
    }, [alertsCountFilter, categoryTimeRange, issuesStatisticsProps]);

    useEffect(() => {
        const chart = am4core.create(containerRef.current, am4charts.XYChart);
        chartRef.current = chart;

        chart.maskBullets = false;
        chart.paddingLeft = 0;

        chart.events.on("datavalidated", (e) => {
            var chart = e.target;
            var categoryAxis = chart.yAxes.getIndex(0);

            var adjustHeight = categoryAxis.dataItems.length * 16 - categoryAxis.pixelHeight;
            // current chart height
            var targetHeight = chart.pixelHeight + adjustHeight;

            // set it on chart's container
            chart.svgContainer.htmlElement.style.height = targetHeight + "px";
        });

        const xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        const yAxis = chart.yAxes.push(new am4charts.CategoryAxis());

        xAxis.dataFields.category = "category_label";
        yAxis.dataFields.category = "periodStart";
        yAxis.renderer.labels.template.adapter.add("text", (text, target) => {
            return target.dataItem.dataContext?.["formattedPeriod"];
        });

        xAxis.renderer.grid.template.disabled = true;
        xAxis.renderer.minGridDistance = 40;
        xAxis.renderer.opposite = true;
        xAxis.renderer.labels.template.fontSize = 10;
        xAxis.renderer.labels.template.fill = am4core.color("#999");

        yAxis.renderer.grid.template.disabled = true;
        yAxis.renderer.inversed = true;
        yAxis.renderer.minGridDistance = 8;
        yAxis.renderer.labels.template.fontSize = 10;
        yAxis.renderer.labels.template.fill = am4core.color("#999");

        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryX = "category_label";
        series.dataFields.categoryY = "periodStart";
        series.dataFields.value = "issues_count";
        series.sequencedInterpolation = true;
        series.defaultState.transitionDuration = 3000;

        let columnTemplate = series.columns.template;
        columnTemplate.width = am4core.percent(100);
        columnTemplate.height = am4core.percent(100);

        columnTemplate.strokeWidth = 1;
        columnTemplate.strokeOpacity = 1;
        columnTemplate.stroke = am4core.color("#fff");

        columnTemplate.tooltip = new am4core.Tooltip();
        columnTemplate.tooltip.getFillFromObject = false;
        columnTemplate.tooltip.background.fill = am4core.color("#000");
        columnTemplate.tooltip.background.stroke = null;
        columnTemplate.tooltip.background.cornerRadius = 8;
        columnTemplate.tooltip.background.filters.clear();
        columnTemplate.tooltip.label.padding(10, 10, 10, 10);
        columnTemplate.tooltipHTML = "";
    }, []);

    useEffect(() => {
        const chart = chartRef.current;
        const series = chart.series.getIndex(0) as am4charts.ColumnSeries;

        series.columns.template.adapter.add("tooltipHTML", (text, target) => {
            const data = target.tooltipDataItem.dataContext;

            const dateFormat = getLabelFormatBySelectedTimeRange(categoryTimeRange, true);

            const period = data["period"];
            let formattedPeriod = "";
            if (typeof period === "number") {
                formattedPeriod = moment(period).format(dateFormat);
            } else if (Array.isArray(period)) {
                const startDate = moment(period[0]).format(dateFormat);
                const endDate = moment(period[1]).format(dateFormat);
                formattedPeriod = `${startDate} - ${endDate}`;
            }

            const itemStyle = `
				display: grid;
				grid-auto-flow: column;
				grid-template-columns: 60px auto;
				font-size: 12px; 
				padding-bottom: 5px;
			`;

            let tooltip = `
				<div style="${itemStyle}">
					<div style="color:#DADADA;">
						Category
					</div>
					<div style="color:#FFF; font-weight: 500;">
						${data["category_label"]}
					</div>
				</div>
				<div style="${itemStyle}">
					<div style="color:#DADADA;">
						Date
					</div>
					<div style="color:#FFF; font-weight: 500;">
						${formattedPeriod}
					</div>
				</div>
				<div style="${itemStyle}">
					<div style="color:#DADADA;">
						Alerts
					</div>
					<div style="color:#FFF; font-weight: 500;">
						${data["issues_count"]}
					</div>
				</div>
				<div style="${itemStyle}">
					<div style="color:#DADADA;">
						Devices
					</div>
					<div style="color:#FFF; font-weight: 500;">
						${data["devices_count"]}
					</div>
				</div>
			`;

            return tooltip;
        });

        const clickHandler = (e) => {
            const point = e.target.dataItem.dataContext as IssuesStatisticsCategoryData;
            if (!interactionsEnabled || point["interactionDisabled"]) return;

            if (categoryTimeRange === CategoryTimeRange.LastWeek) {
                toggleDateRangeInAlertsFilter(point.category, {
                    from: point.period,
                    to: moment.utc(point.period).endOf("day").toDate().getTime(),
                });
            }

            if (categoryTimeRange === CategoryTimeRange.LastMonth) {
                toggleDateRangeInAlertsFilter(point.category, { from: point.period[0], to: point.period[1] });
            }

            if (categoryTimeRange === CategoryTimeRange.LastYear) {
                toggleDateRangeInAlertsFilter(point.category, {
                    from: point.period,
                    to: moment.utc(point.period).endOf("month").toDate().getTime(),
                });
            }

            if (categoryTimeRange === CategoryTimeRange.TimeRange) {
                const from = (point.period as any) instanceof Array ? point.period[0] : point.period;
                const to = (point.period as any) instanceof Array ? point.period[1] : point.period;

                toggleDateRangeInAlertsFilter(point.category, { from, to });
            }
        };

        series.columns.template.events.on("hit", clickHandler);

        const data = issuesStatistics;

        const axisLabelFormat = getLabelFormatBySelectedTimeRange(categoryTimeRange);

        if (chart) {
            chart.data = data.map((point) => {
                return {
                    ...point,
                    formattedPeriod: point.formattedPeriod || moment(point.period).format(axisLabelFormat),
                };
            });

            const maxIssuesCount = getMaxOrMinIssuesCount(data, "max");
            const minIssuesCount = getMaxOrMinIssuesCount(data, "min");
            let segmentRanges: AlertsCountFilter[] = defaultLegendSegments;

            if (minIssuesCount !== 0 || maxIssuesCount !== 0) {
                const segments = breakIntoNumberSegments(minIssuesCount, maxIssuesCount, colorPalette.length - 1);

                if (segments.length > 0) {
                    segmentRanges = segments
                        .map((segment, i) => ({ from: i === 0 ? segment : segment + 1, to: segments[i + 1] }))
                        .slice(0, -1);
                    segmentRanges.unshift({ from: 0, to: 0 });
                }
            }

            setLegendSegments(segmentRanges);

            const series = chart.series.getIndex(0) as am4charts.ColumnSeries;
            series.columns.template.column.adapter.add("fill", (fill, target) => {
                if (target.dataItem) {
                    // @ts-ignore
                    const value = target.dataItem.value;

                    if (target.dataItem.dataContext["interactionDisabled"]) return am4core.color("#fff");

                    for (let i = 0; i < segmentRanges.length; i++) {
                        if (value >= segmentRanges[i].from && value <= segmentRanges[i].to) {
                            return am4core.color(colorPalette[i]);
                        }
                    }
                }

                return am4core.color("#fff");
            });

            series.columns.template.column.adapter.add("stroke", (stroke, target) => {
                if (target.dataItem.dataContext["interactionDisabled"]) {
                    return am4core.color("#f2f2f2");
                }

                return stroke;
            });
        }

        return () => {
            series.columns.template.events.off("hit", clickHandler);
        };
    }, [issuesStatistics, categoryTimeRange, interactionsEnabled]);

    useEffect(() => {
        const chart = chartRef.current;

        const applyStylesToSelectedCells = () => {
            const series = chart.series.getIndex(0) as am4charts.ColumnSeries;

            series.dataItems.each((dataItem) => {
                const point = dataItem.dataContext as IssuesStatisticsCategoryData;

                let isSelected = false;
                if (categoryTimeRange === CategoryTimeRange.LastWeek) {
                    isSelected = !!alertsFilter.selectedCategories[point.category]?.find(
                        (range) =>
                            moment.utc(range.from).isSame(point.period, "day") &&
                            moment.utc(range.to).isSame(point.period, "day")
                    );
                }

                if (categoryTimeRange === CategoryTimeRange.LastMonth) {
                    isSelected = !!alertsFilter.selectedCategories[point.category]?.find(
                        (range) => range.from === point.period[0] && range.to === point.period[1]
                    );
                }

                if (categoryTimeRange === CategoryTimeRange.LastYear) {
                    isSelected = !!alertsFilter.selectedCategories[point.category]?.find(
                        (range) =>
                            moment(range.from).isSame(point.period, "month") &&
                            moment(range.to).subtract(1, "month").isSame(point.period, "month")
                    );
                }

                if (categoryTimeRange === CategoryTimeRange.TimeRange) {
                    const from = (point.period as any) instanceof Array ? point.period[0] : point.period;
                    const to = (point.period as any) instanceof Array ? point.period[1] : point.period;

                    isSelected = !!alertsFilter.selectedCategories[point.category]?.find(
                        (range) => range.from === from && range.to === to
                    );
                }

                if (isSelected) {
                    dataItem.column.stroke = am4core.color("#480966");
                    dataItem.column.strokeWidth = 3;
                    // TODO: shadow doesnt look like on the design
                    // const shadow = dataItem.column.filters.push(new am4core.DropShadowFilter());
                    // shadow.dx = 0;
                    // shadow.dy = 13;
                    // shadow.blur = 25;
                    // shadow.color = am4core.color("#091A45");

                    dataItem.column.zIndex = 100;
                } else {
                    dataItem.column.stroke = am4core.color("#fff");
                    dataItem.column.strokeWidth = 1;
                    dataItem.column.zIndex = 1;
                }
            });
        };

        applyStylesToSelectedCells();
        chart.events.on("datavalidated", applyStylesToSelectedCells);

        return () => {
            chart.events.off("datavalidated", applyStylesToSelectedCells);
        };
    }, [alertsFilter, categoryTimeRange]);

    useEffect(() => {
        setAlertsCountFilter(null);
    }, [categoryTimeRange]);

    return (
        <div className={classes.root} role="presentation" aria-label="dashboard alert heatmap">
            <div ref={containerRef} className={classes.chart} />

            <div data-onboarding-number-of-anomalies-alerts={true} className={classes.actionContainer}>
                <Typography variant="caption">Average numbers of alerts and anomalies</Typography>
                <div className={classes.legend}>
                    <Typography variant="caption" className={classNames(classes.legendNumber, "left")}>
                        {legendSegments[0]?.from}
                    </Typography>
                    <Typography variant="caption" className={classNames(classes.legendNumber, "right")}>
                        {legendSegments[legendSegments.length - 1]?.to}
                    </Typography>

                    {legendSegments.map((segment, i) => {
                        const isSelected =
                            segment.from === alertsCountFilter?.from && segment.to === alertsCountFilter?.to;

                        return (
                            <Tooltip
                                key={`${segment.from}-${segment.to}`}
                                title={segment.from === segment.to ? segment.to : `${segment.from}-${segment.to}`}
                            >
                                <div
                                    className={classNames(classes.legendSegment, isSelected && "selected")}
                                    style={{ background: colorPalette[i] }}
                                    onClick={() => {
                                        if (isSelected) {
                                            setAlertsCountFilter(null);
                                        } else {
                                            setAlertsCountFilter(segment);
                                        }
                                    }}
                                />
                            </Tooltip>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export const AlertsHeatmap: React.FC<AlertsHeatmapProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(AlertsHeatmapComponent);
