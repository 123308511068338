import CveInfo from "./CveInfo";
import DeviceInfo from "./DeviceInfo";
import { EntitiesState, getDefaultEntitiesState } from "./EntitiesState";
import { getDefaultIssuesState, IssuesState } from "./IssuesState";
import LibraryInfo from "./LibraryInfo";
import SternumGeneratedEventInfo from "./SternumGeneratedEventInfo";
import TraceInfo from "./TraceInfo";
import { getDefaultTriggersHitListState, TriggersHitListState } from "./TriggersHitListState";
import { getDefaultUIState, UIState } from "./UIState";
import UsedLibraryInfo from "./UsedLibraryInfo";
import { getDefaultUserState, UserState } from "./UserState";
import { DashboardRegularState, getDefaultDashboardRegularState } from "./DashboardRegularState";

/**
 * Defines the global state of the whole app.
 */
export interface GlobalState {
    devices: EntitiesState<DeviceInfo>;
    generatedEvents: EntitiesState<SternumGeneratedEventInfo>;
    traces: EntitiesState<TraceInfo>;
    issues: IssuesState;
    libraries: EntitiesState<LibraryInfo>;
    usedLibraries: EntitiesState<UsedLibraryInfo>;
    cves: EntitiesState<CveInfo>;
    ui: UIState;
    triggersHitList: TriggersHitListState;
    user: UserState;
    dashboardRegular: DashboardRegularState;
}

/**
 * Gets the default global state for the app.
 */
export function getDefaultGlobalState(): GlobalState {
    return {
        devices: getDefaultEntitiesState<DeviceInfo>(),
        generatedEvents: getDefaultEntitiesState<SternumGeneratedEventInfo>(),
        traces: getDefaultEntitiesState<TraceInfo>(),
        issues: getDefaultIssuesState(),
        libraries: getDefaultEntitiesState<LibraryInfo>(),
        usedLibraries: getDefaultEntitiesState<UsedLibraryInfo>(),
        cves: getDefaultEntitiesState<CveInfo>(),
        ui: getDefaultUIState(),
        triggersHitList: getDefaultTriggersHitListState(),
        user: getDefaultUserState(),
        dashboardRegular: getDefaultDashboardRegularState(),
    };
}
