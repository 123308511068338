import React from "react";
import { connect } from "react-redux";
import { History } from "history";

import { DashboardProps } from "../../Dashboard.component";
import { GlobalState } from "../../../../lib/state/GlobalState";
import { useStyles } from "./VulnerabilitiesCoverage.style";
import { Box, lighten, Tooltip, Typography } from "@material-ui/core";
import { knownVulnerabilities } from "./VulnerabilitiesCoverage.model";
import { useCommonStyle } from "../../../CommonStyle";
import classNames from "classnames";
import { InfoCircleIcon } from "../../../SUI/SternumIcon";
import { uniqBy } from "lodash";

export interface VulnerabilitiesCoverageProps {}

const mapStateToProps = (state: GlobalState, ownProps: DashboardProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

type VulnerabilitiesCoveragePropsWithHOC = VulnerabilitiesCoverageProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
        history: History;
    };

function VulnerabilitiesCoverageComponent({}: VulnerabilitiesCoveragePropsWithHOC) {
    const classes = useStyles();
    const classesCommon = useCommonStyle();

    // @ts-ignore
    const allCves: typeof knownVulnerabilities[number]["cves"] = knownVulnerabilities.flatMap(({ cves }) => cves);
    const protectedCves = uniqBy(allCves, "name").filter((cve) => cve.protected);
    const allProtectedCvesPercentage = Math.round((protectedCves.length / allCves.length) * 100);

    return (
        <div className={classes.root}>
            <Typography className={classesCommon.commonBold}>All OpenWrt CVEs Over Time</Typography>

            <div className={classes.coverageGraphsContainer}>
                {knownVulnerabilities.map(({ category, color, cves }) => {
                    const protectedCves = cves.filter((cve) => cve.protected);
                    const unprotectedCves = cves.filter((cve) => !cve.protected);

                    const protectedCvesPercentage = Math.round((protectedCves.length / cves.length) * 100);
                    const unprotectedCvesPercentage = Math.round((unprotectedCves.length / cves.length) * 100);

                    return (
                        <div key={category} className={classes.coverageSection}>
                            <Typography variant="body2" className={classes.categoryTitle}>
                                {category}
                            </Typography>

                            <div className={classes.coverageGraph}>
                                <Tooltip
                                    placement="bottom"
                                    classes={{ tooltip: classes.infoTooltip }}
                                    title={
                                        <div className={classes.tooltipContent}>
                                            <div>
                                                Protected:{" "}
                                                <b style={{ color: "#15AC5A" }}>{protectedCvesPercentage}%</b>
                                            </div>
                                            {protectedCves.map((cve) => (
                                                <Typography
                                                    key={cve.name}
                                                    variant="caption"
                                                    className={classes.tooltipCve}
                                                >
                                                    {cve.name}
                                                </Typography>
                                            ))}
                                        </div>
                                    }
                                >
                                    <div
                                        className={classes.coverageGraphPart}
                                        style={{
                                            width: `${protectedCvesPercentage}%`,
                                            background: color,
                                        }}
                                    />
                                </Tooltip>
                                <Tooltip
                                    placement="bottom"
                                    classes={{ tooltip: classes.infoTooltip }}
                                    title={
                                        <div className={classes.tooltipContent}>
                                            <div>
                                                Unprotected:{" "}
                                                <b style={{ color: "#C42727" }}>{unprotectedCvesPercentage}%</b>
                                            </div>
                                            {unprotectedCves.map((cve) => (
                                                <Typography
                                                    key={cve.name}
                                                    variant="caption"
                                                    className={classes.tooltipCve}
                                                >
                                                    {cve.name}
                                                </Typography>
                                            ))}
                                        </div>
                                    }
                                >
                                    <div
                                        className={classes.coverageGraphPart}
                                        style={{
                                            width: `${unprotectedCvesPercentage}%`,
                                            background: lighten(color, 0.6),
                                        }}
                                    />
                                </Tooltip>
                            </div>

                            <Box>
                                <Typography variant="body2">
                                    <b>{protectedCves.length}</b>/{cves.length}
                                </Typography>
                                <Typography variant="caption" className={classes.coveragePercent}>
                                    {protectedCvesPercentage}%
                                </Typography>
                            </Box>
                        </div>
                    );
                })}
            </div>

            <div className={classes.totalProtectedContainer}>
                <Typography
                    variant="body2"
                    className={classNames(classesCommon.commonBold, classes.totalProtectedTitle)}
                >
                    Sternum automatically protects{" "}
                    <span className={classes.totalProtectedNumber}>{allProtectedCvesPercentage}%</span> of all known
                    vulnerabilities over time
                </Typography>

                <Tooltip
                    classes={{ tooltip: classes.infoTooltip }}
                    title={<div>With additional zero-day protection of unknown vulnerabilities in real-time.</div>}
                >
                    <Box display="inline-flex">
                        <InfoCircleIcon width={18} height={18} color="#1B6FDE" />
                    </Box>
                </Tooltip>
            </div>
        </div>
    );
}

export const VulnerabilitiesCoverage: React.FC<VulnerabilitiesCoverageProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(VulnerabilitiesCoverageComponent);
