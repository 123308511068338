import { IconButton, Typography } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { connect } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { useInViewport } from "react-in-viewport";
import ServiceWire from "../../../lib/services/ServiceWire";
import VisualisationSummary from "../../../lib/services/visualisation/VisualisationSummary";
import DeviceDefinitionVersionInfo from "../../../lib/state/DeviceDefinitionVersionInfo";
import { GlobalState } from "../../../lib/state/GlobalState";
import VisualisationInfo from "../../../lib/state/Visualisation/VisualisationInfo";
import VisualisationType from "../../../lib/state/Visualisation/VisualisationType";
import { ExpandArrowsIcon } from "../../SUI/SternumIcon/SternumIcon";
import GraphLoader from "../../SUI/SternumLoaders/GraphLoader";
import { UniqueCountTableDataProvider } from "../../UniqueCountQuery/UniqueCountTableData";
import UIDataVisualisationConfiguration from "../../VisualisationConfigurationComponent/entities/UIDataVisualisationConfiguration";
import getDefaultDataVisualisationConfiguration from "../../VisualisationConfigurationComponent/utils/getDefaultDataVisualisationConfiguration";
import VisualisationConfigurationPresentation from "../../VisualisationConfigurationPresentation/VisualisationConfigurationPresentation";
import DashboardVisualisationMenu from "../DashboardVisualisationMenu/DashboardVisualisationMenu";
import visualisationCardStyle from "./DashboardVisualisationCardStyle";
import { openGoProModal } from "../../../lib/redux/modals/OpenModalAction";

interface DashboardVisualisationCardProps extends WithStyles {
    visualisation: VisualisationSummary;
    dashboardDeviceDefinitionVersions: DeviceDefinitionVersionInfo[];
    index: number;
    isDisabled: boolean;
    startTime: Date;
    endTime: Date;
    openVisualisationModal: (id: string, type?: VisualisationType, entityIds?: string[]) => void;
    onVisualisationDelete: (index: number) => void;
}

const mapStateToProps = (state: GlobalState, ownProps: DashboardVisualisationCardProps) => {
    const selectedClient = ServiceWire.getClientsService().getSelectedClient();

    return {
        isFreeUser: selectedClient.isTrialTier(),
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        openGoProModal: (key: string) => dispatch(openGoProModal(key)),
    };
};

type DashboardVisualisationCardPropsWithHOC = DashboardVisualisationCardProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const DashboardVisualisationCard = ({
    visualisation,
    dashboardDeviceDefinitionVersions,
    index,
    isDisabled,
    startTime,
    endTime,
    classes,
    openVisualisationModal,
    onVisualisationDelete,
    isFreeUser,
    openGoProModal,
}: DashboardVisualisationCardPropsWithHOC) => {
    const [visualisationEntity, setVisualisationEntity] = useState<VisualisationInfo | null>(null);
    const [dataVisualisationConfigurations, setDataVisualisationConfigurations] = useState<
        UIDataVisualisationConfiguration[]
    >([]);
    const [errorFetchingVisualisation, setErrorFetchingVisualisation] = useState("");

    const containerRef = useRef();
    const { enterCount } = useInViewport(containerRef, undefined, { disconnectOnLeave: false }, {});

    useEffect(() => {
        if (!enterCount) return;
        if (dashboardDeviceDefinitionVersions.length === 0) return;

        ServiceWire.getVisualisationApiService()
            .getVisualisation(visualisation.visualisationId)
            .then((fetchedVisualisation) => {
                const dataVisualisationConfigurations = fetchedVisualisation.configuration.dataSources.map(
                    (dataSource) => {
                        const deviceDefinitionVersions = dashboardDeviceDefinitionVersions.filter((version) =>
                            dataSource.deviceDefinitionVersionIds.includes(version.entityId)
                        );

                        return getDefaultDataVisualisationConfiguration(
                            dataSource.dataSourceKey,
                            undefined,
                            dataSource.sternumQuery,
                            startTime,
                            endTime,
                            dataSource,
                            dataSource.deviceDefinitionVersionIds,
                            deviceDefinitionVersions.map((version) => version.getVersionName())
                        );
                    }
                );

                setDataVisualisationConfigurations(dataVisualisationConfigurations);
                setVisualisationEntity(fetchedVisualisation);
                setErrorFetchingVisualisation("");
            })
            .catch((error) => {
                setErrorFetchingVisualisation(error.message);
            });
    }, [dashboardDeviceDefinitionVersions, !!enterCount, visualisation.updated]);

    const clientId = ServiceWire.getClientsService().getSelectedClientId();

    const isGeomap = visualisationEntity?.configuration?.visualisationType === VisualisationType.GEOMAP;

    return (
        <div
            ref={containerRef}
            role="presentation"
            aria-label="glance"
            className={classNames(classes.visualisationBoxContainer)}
        >
            <div className={classNames(classes.visualisationBox, isGeomap && "no-padding")}>
                <div className={classNames(classes.visualisationTitleContainer, isGeomap && "absolute")}>
                    <Typography className={classNames(classes.visualisationTitle)} aria-label="glance title">
                        {visualisation.displayName}
                    </Typography>

                    <div className={classNames(classes.flexGrow)} />

                    <IconButton onClick={() => openVisualisationModal(visualisation.visualisationId, undefined)}>
                        <ExpandArrowsIcon />
                    </IconButton>

                    {!isDisabled && (
                        <DashboardVisualisationMenu
                            visualisationId={visualisation.visualisationId}
                            onEditClicked={() =>
                                isFreeUser
                                    ? openGoProModal("goProModal")
                                    : openVisualisationModal(
                                          visualisation.visualisationId,
                                          undefined,
                                          dataVisualisationConfigurations[0]?.deviceDefinitionVersionIds
                                      )
                            }
                            onVisualisationDeleted={() => onVisualisationDelete(index)}
                        />
                    )}
                </div>

                {!errorFetchingVisualisation && visualisationEntity && enterCount > 0 ? (
                    <div className={classNames(classes.visualisationContainer)}>
                        <VisualisationConfigurationPresentation
                            visualisationType={visualisationEntity.configuration.visualisationType}
                            displayingInDashboard={true}
                            updated={visualisation.updated}
                            lookedUpEntityId={isFreeUser ? visualisationEntity.entityId : clientId}
                            dataVisualisationConfigurations={dataVisualisationConfigurations}
                            groupBy={visualisationEntity.configuration.groupBy}
                            receivedDefinitionsResponse={{}}
                            startTime={startTime}
                            endTime={endTime}
                            outline={false}
                            amountOfLoaders={4}
                            amountOfGraphPointsToDisplay={11}
                            hideTableToolbar
                            denseTable
                            isStacked={visualisationEntity.isStacked}
                            isLogarithmicScale={visualisationEntity.isLogarithmicScale}
                            dashboardDeviceDefinitionVersions={dashboardDeviceDefinitionVersions}
                            displayTooltipDataPerDeviceDefinitionVersion
                        />
                    </div>
                ) : (
                    <div className={classNames(classes.loadingContainer)}>
                        <GraphLoader />
                    </div>
                )}
            </div>
        </div>
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(visualisationCardStyle)(DashboardVisualisationCard));
