import React, { createContext, useContext, useRef } from "react";

const DropdownSelectedCaseContext = createContext(null);
const DropdownOnChangeSelectedCaseContext = createContext((newSelectedCase: any) => {});

export interface DropdownContextProviderProps {
    selectedCase: any;
    onChangeSelectedCase: (newSelectedCase: any) => unknown;
    children: React.ReactNode;
}

export function DropdownContextProvider({
    selectedCase,
    onChangeSelectedCase,
    children,
}: DropdownContextProviderProps) {
    const onChange = useRef((newSelectedCase: any) => {});
    const handleChangeSelectedCase = useRef((newSelectedCase: any) => {
        return onChange.current(newSelectedCase);
    });

    onChange.current = (newSelectedCase: any) => {
        onChangeSelectedCase?.(newSelectedCase);
    };

    return (
        <DropdownSelectedCaseContext.Provider value={selectedCase}>
            <DropdownOnChangeSelectedCaseContext.Provider value={handleChangeSelectedCase.current}>
                {children}
            </DropdownOnChangeSelectedCaseContext.Provider>
        </DropdownSelectedCaseContext.Provider>
    );
}

export const useDropdownContext = () => {
    const selectedCase = useContext(DropdownSelectedCaseContext);
    const onChangeSelectedCase = useContext(DropdownOnChangeSelectedCaseContext);

    return { selectedCase, onChangeSelectedCase };
};
