import TableRowData from "./TableRowData";
import EntityBase from "./EntityBase";
import EntityType from "./EntityType";

class UsedLibraryHistoryInfo extends EntityBase implements TableRowData {
    /**
     * Constructor.
     */
    constructor(
        public historyId: string,
        public created: number,
        public updated: number,
        public usedLibraryId: string,
        public libraryId: string,
        public name: string,
        public version: string,
        public historyDate: number
    ) {
        super(historyId, created, updated, EntityType.UsedLibraryHistory);
    }

    /**
     * Converts json to library info object.
     */
    public static fromJsonObject(jsonObject: Object) {
        return new UsedLibraryHistoryInfo(
            jsonObject["entity_id"],
            jsonObject["created"],
            jsonObject["updated"],
            jsonObject["used_library_id"],
            jsonObject["library_id"],
            jsonObject["name"],
            jsonObject["version"],
            jsonObject["history_date"]
        );
    }

    /**
     * Gets the value for the given column id.
     */
    public getColumnValue(columnHeaderId: string): any {
        return this[columnHeaderId];
    }

    /**
     * Returns whether given row is any different in details than current library info.
     * Shallow comparison, in other words.
     * @param other The row to compare with.
     */
    public isDifferentFrom(other: TableRowData): boolean {
        // Row must be of type library info.
        if (!(other instanceof UsedLibraryHistoryInfo)) {
            return true;
        }

        let otherUsedLibraryHistoryInfo = other as UsedLibraryHistoryInfo;

        // Comparing base library info properties.
        if (
            otherUsedLibraryHistoryInfo.entityId !== this.entityId ||
            otherUsedLibraryHistoryInfo.created !== this.created ||
            otherUsedLibraryHistoryInfo.updated !== this.updated ||
            otherUsedLibraryHistoryInfo.usedLibraryId !== this.usedLibraryId ||
            otherUsedLibraryHistoryInfo.libraryId !== this.libraryId ||
            otherUsedLibraryHistoryInfo.name !== this.name ||
            otherUsedLibraryHistoryInfo.version !== this.version ||
            otherUsedLibraryHistoryInfo.historyDate !== this.historyDate
        ) {
            return true;
        }

        return false;
    }
}

export default UsedLibraryHistoryInfo;
