import { call, takeEvery } from "redux-saga/effects";
import WebUtils from "../../infra/WebUtils";
import ActionType from "../ActionType";
import { SetIsOnlyAnomalyInsightsAction } from "./SetIsOnlyAnomalyInsightsAction";

export function* setIsOnlyAnomalyInsightsAsync(action: SetIsOnlyAnomalyInsightsAction) {
    const urlParamKey = "anomaly_insights";

    if (action.isOnlyAnomalyInsights) {
        yield call(WebUtils.setUrlSearchParam, urlParamKey, "1");
    } else {
        yield call(WebUtils.removeUrlSearchParam, urlParamKey);
    }
}

export function* watchSetIsOnlyAnomalyInsightsAsync() {
    yield takeEvery(
        (action) => action.type === ActionType.DashboardRegularSetIsOnlyAnomalyInsights,
        setIsOnlyAnomalyInsightsAsync
    );
}
