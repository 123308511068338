import React from "react";
import {
    MediumIndicationIcon,
    StrongIndicationIcon,
    VeryStrongIndicationIcon,
    WeakIndicationIcon,
} from "../SternumIcon/SternumIcon";
import usePasswordStrengthStyles from "./PasswordStrengthStyles";

interface Props {
    passwordStrength: number;
    width?: number;
    height?: number;
}

const getPasswordStatus = (passwordStrength: number, width: number, height: number) => {
    switch (passwordStrength) {
        case 0: {
            return <WeakIndicationIcon width={width} height={height} />;
        }
        case 1: {
            return <WeakIndicationIcon width={width} height={height} />;
        }
        case 2: {
            return <MediumIndicationIcon width={width} height={height} />;
        }
        case 3: {
            return <StrongIndicationIcon width={width} height={height} />;
        }
        case 4: {
            return <VeryStrongIndicationIcon width={width} height={height} />;
        }
    }
};

const PasswordStrength = ({ passwordStrength, width = 400, height = 6 }: Props) => {
    const classes = usePasswordStrengthStyles();

    return <div>{getPasswordStatus(passwordStrength, width, height)}</div>;
};

export default PasswordStrength;
