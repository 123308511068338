import { grey } from "@material-ui/core/colors";
import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../../CommonStyle";

const sternumModalStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        modalContentContainer: {
            left: "50%",
            transform: "translate(-50%)",
            position: "absolute",
            boxShadow: "0 2px 20px 0 rgba(0, 0, 0, 0.25)",
            backgroundColor: "#FBFCFF",
            padding: theme.spacing(5),
            outline: "none",
            borderRadius: "14px",
            overflowY: "auto",
            margin: `${theme.spacing(13)}px 0`,

            "&.mod-fullscreen": {
                margin: "0",
                width: "100%",
                height: "100%",
            },

            "&.mod-stacked": {
                boxShadow: "none",
            },
        },
        backdrop: {
            backgroundColor: theme.palette.common.white,

            // @ts-ignore
            opacity: 0.7,

            // @ts-ignore
            "&.mod-stacked": {
                opacity: 0,
            },
        },
        backButtonContainer: {
            display: "flex",
            alignItems: "center",
            position: "absolute",
            top: 0,
            padding: theme.spacing(5),
        },
        backButtonIconAndText: {
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            color: grey[500],
            "&:hover": {
                color: theme.palette.common.black,
            },
        },
        backButtonIcon: {
            marginLeft: theme.spacing(-1),
        },
        backButtonText: {},
        topButtonsContainer: {
            position: "absolute",
            top: 0,
            right: 0,
            padding: theme.spacing(5),
            zIndex: 10000,
        },
        topButtonIcon: {
            cursor: "pointer",
            color: grey[500],
            "&:hover": {
                color: theme.palette.common.black,
            },
        },
        fullscreenIcon: {
            marginRight: theme.spacing(1),
        },
    });

export default sternumModalStyle;
