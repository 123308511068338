import React from "react";

interface SpinnerLoaderProps {
    className?: string;
    width?: number;
    height?: number;
}

export default function SpinnerLoader(props: SpinnerLoaderProps) {
    return (
        <div>
            <svg width="45" height="45" viewBox="0 0 45 45" fill="none" {...props}>
                <path
                    d="M41.2512 24.3805C39.1807 24.3805 37.5023 22.7026 37.5023 20.6328C37.5023 18.563 39.1807 16.8851 41.2512 16.8851C43.3216 16.8851 45 18.563 45 20.6328C45 22.7026 43.3216 24.3805 41.2512 24.3805Z"
                    fill="#DF1B50"
                >
                    <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.2" />
                </path>
                <path
                    d="M34.4356 37.0818C32.9716 35.6183 32.9716 33.2454 34.4356 31.7818C35.8997 30.3182 38.2733 30.3182 39.7373 31.7818C41.2013 33.2454 41.2013 35.6183 39.7373 37.0818C38.2733 38.5454 35.8997 38.5454 34.4356 37.0818Z"
                    fill="#DF1B50"
                >
                    <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.3" />
                </path>
                <path
                    d="M20.5588 41.2378C20.5668 39.168 22.2517 37.4966 24.3221 37.5046C26.3925 37.5126 28.0644 39.197 28.0564 41.2668C28.0484 43.3365 26.3635 45.008 24.2931 45C22.2227 44.992 20.5508 43.3076 20.5588 41.2378Z"
                    fill="#DF1B50"
                >
                    <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.4" />
                </path>
                <path
                    d="M7.87821 34.3748C9.34753 32.9166 11.7211 32.9252 13.1798 34.3941C14.6385 35.863 14.6299 38.2359 13.1606 39.6941C11.6912 41.1524 9.31763 41.1437 7.85894 39.6749C6.40026 38.206 6.40889 35.8331 7.87821 34.3748Z"
                    fill="#DF1B50"
                >
                    <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.5" />
                </path>
                <path
                    d="M3.77778 20.4855C5.84814 20.5014 7.51354 22.1923 7.49756 24.262C7.48157 26.3317 5.79025 27.9966 3.71989 27.9807C1.64953 27.9647 -0.0158702 26.2739 0.000114104 24.2041C0.0160984 22.1344 1.70742 20.4695 3.77778 20.4855Z"
                    fill="#DF1B50"
                >
                    <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.6" />
                </path>
                <path
                    d="M10.6802 7.84957C12.1335 9.32373 12.1163 11.6966 10.6417 13.1495C9.16706 14.6024 6.79349 14.5851 5.34016 13.111C3.88682 11.6368 3.90407 9.26395 5.37868 7.81105C6.85329 6.35816 9.22686 6.3754 10.6802 7.84957Z"
                    fill="#DF1B50"
                >
                    <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.7" />
                </path>
                <path
                    d="M24.6022 3.79111C24.5782 5.86077 22.8805 7.51914 20.8102 7.49517C18.7399 7.4712 17.0811 5.77398 17.105 3.70431C17.129 1.63465 18.8267 -0.0237119 20.897 0.000256573C22.9673 0.024225 24.6262 1.72145 24.6022 3.79111Z"
                    fill="#DF1B50"
                >
                    <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.8" />
                </path>
                <path
                    d="M37.2119 10.7352C35.732 12.1827 33.3585 12.1568 31.9105 10.6774C30.4626 9.19796 30.4884 6.82518 31.9683 5.37765C33.4482 3.93012 35.8217 3.95598 37.2696 5.43542C38.7176 6.91485 38.6917 9.28763 37.2119 10.7352Z"
                    fill="#DF1B50"
                >
                    <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.9" />
                </path>
            </svg>
        </div>
    );
}
