import ReceivedDefinitionsResponse from "../../../lib/services/events/ReceivedDefinitionsResponse";

export default function getArgumentDefinitionDisplayNameFromReceivedDefinitions(
    receivedDefinitionsResponse: ReceivedDefinitionsResponse,
    argumentDefinitionId: string
): string | undefined {
    const foundDefinition = receivedDefinitionsResponse.receivedArguments.find(
        (receivedDefinition) => receivedDefinition.identifier === argumentDefinitionId
    );

    if (foundDefinition) {
        return foundDefinition.displayName;
    } else {
        return undefined;
    }
}
