import { makeStyles } from "@material-ui/core";
import commonStyle from "../CommonStyle";

const useGoogleRecaptchaStyles = makeStyles((theme) => {
    return {
        ...commonStyle(theme),
        scale: {
            transform: "scale(1)",
            "-webkit-transform": "scale(1)",
            transformOrigin: "0 0",
            "-webkit-transform-origin": "0 0",
        },
    };
});

export default useGoogleRecaptchaStyles;
