import { Action } from "redux";
import ActionType from "../ActionType";
import FetchError from "../../state/FetchError";

/**
 * Occurs once there has been an error fetching a library from API.
 */
export interface ErrorFetchingLibraryAction extends Action<ActionType> {
    type: ActionType.ErrorFetchingLibrary;
    libraryId: string;
    error: FetchError;
}

/**
 * Occurs once there has been an error fetching a library from API.
 */
export function errorFetchingLibraryAction(libraryId: string, error: FetchError): ErrorFetchingLibraryAction {
    return {
        type: ActionType.ErrorFetchingLibrary,
        libraryId: libraryId,
        error: error,
    };
}
