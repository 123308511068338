/**
 * Information for a subscription to polling changed entities.
 */
import PollingChangeType from "../state/PollingChangeType";

class PollingSubscriptionInfo {
    /**
     * Constructor.
     * @param subscriberId The id of the subscriber to changes.
     * @param {PollingChangeType} pollingChangeType The type of change we're subscribing to.
     * @param {(changedData: Object[]) => void} callback The callback to call once there are changed entities of subscribed type.
     */
    constructor(
        public subscriberId: string,
        public pollingChangeType: PollingChangeType,
        public callback: (fromTimestamp: number, changedData: Object) => void
    ) {}
}

export default PollingSubscriptionInfo;
