import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

const statusDisplayStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        statusIndication: {
            borderRadius: 20,
            maxWidth: theme.spacing(30),
            fontWeight: 400,
            fontSize: 14,

            "&.mod-small-indication": {
                height: theme.spacing(4),
                width: theme.spacing(1),
            },

            "&.mod-narrow": {
                width: theme.spacing(13),
            },

            "&.mod-padding": {
                padding: `${theme.spacing(0.75)}px ${theme.spacing(2)}px`,
            },

            "&.mod-center-text": {
                textAlign: "center",
            },

            "&.mod-success": {
                color: "#15AC5A",

                "&.with-background": {
                    background: "#E7FFF2",
                },
            },

            "&.mod-dev-mode": {
                color: "#5E30C9",

                "&.with-background": {
                    background: "rgba(94, 48, 201, 0.1)",
                },
            },

            "&.mod-low": {
                color: "rgb(34, 155, 212)",

                "&.with-background": {
                    background: "rgba(34, 155, 212, 0.06)",
                },
                "&.mod-small-indication": {
                    background: "rgba(34, 155, 212, 0.5)",
                },
            },

            "&.mod-regular": {
                color: "rgb(135, 137, 140)",

                "&.with-background": {
                    background: "rgb(135, 137, 140)",
                },
                "&.mod-small-indication": {
                    background: "rgb(135, 137, 140)",
                },
            },

            "&.mod-medium": {
                color: "rgb(231,180,22)",

                "&.with-background": {
                    background: "rgba(231,180,22, 0.06)",
                },
                "&.mod-small-indication": {
                    background: "rgba(231,180,22, 0.5)",
                },
            },

            "&.mod-high": {
                color: "rgb(209, 0, 39)",

                "&.with-background": {
                    background: "rgba(209, 0, 39, 0.06)",
                },
                "&.mod-small-indication": {
                    background: "rgba(209, 0, 39, 0.5)",
                },
            },

            "&.mod-danger": {
                color: "rgb(209, 0, 39)",

                "&.with-background": {
                    background: "rgba(209, 0, 39, 0.06)",
                },
            },

            "&.mod-staging": {
                color: "#221CB6",

                "&.with-background": {
                    background: "#DBE2FE",
                },
            },

            "&.mod-production": {
                color: "#15AC5A",

                "&.with-background": {
                    background: "#E7FFF2",
                },
            },

            "&.mod-very-weak-password": {
                color: "rgb(154, 0, 0)",

                "&.with-background": {
                    background: "rgba(154, 0, 0, 0.06)",
                },
            },
            "&.mod-weak-password": {
                color: "rgb(255, 0, 0)",

                "&.with-background": {
                    background: "rgba(255, 0, 0, 0.06)",
                },
            },
            "&.mod-medium-password": {
                color: "rgb(255, 172, 0)",

                "&.with-background": {
                    background: "rgba(255, 172, 0, 0.06)",
                },
            },
            "&.mod-strong-password": {
                color: "rgb(0, 222, 0)",

                "&.with-background": {
                    background: "rgba(0, 222, 0, 0.06)",
                },
            },
            "&.mod-very-strong-password": {
                color: "rgb(0, 177, 0)",

                "&.with-background": {
                    background: "rgba(0, 177, 0, 0.06)",
                },
            },
            "&.mod-date-selection": {
                color: "rgb(134,134,134)",
                padding: theme.spacing(1, 2),
                borderRadius: 6,

                "&.with-background": {
                    background: "rgba(169,169,169, 0.2)",
                },
            },
            "&.mod-disabled": {
                color: "rgb(108, 122, 137)",

                "&.with-background": {
                    background: "rgb(236, 240, 241)",
                },
            },
        },
    });

export default statusDisplayStyle;
