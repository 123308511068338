import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { History } from "history";

import { GlobalState } from "../../../lib/state/GlobalState";
import { Sternum3LargeLogo } from "../../SUI/SternumIcon";
import { useHomePageStyle } from "../Home.style";
import { Button } from "../../SUI/Button";
import { Tiles } from "../Tiles";
import { useSternumByNumbersViewStyle } from "./SternumByNumbers.style";
import { sternumByNumbers } from "./SternumByNumbers.model";

export interface SternumByNumbersViewProps {}

const mapStateToProps = (state: GlobalState, ownProps: SternumByNumbersViewProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

type SternumByNumbersViewPropsWithHOC = SternumByNumbersViewProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
        history: History;
    };

function SternumByNumbersViewComponent({ history }: SternumByNumbersViewPropsWithHOC) {
    const classes = useSternumByNumbersViewStyle();
    const classesHome = useHomePageStyle();

    return (
        <div className={classes.mainContainerWithBackground}>
            <div className={classes.darkViewContainer}>
                <Sternum3LargeLogo className={classes.backgroundLogo} height={1273} width={1273} />
                <div className={classNames(classesHome.mainContainer, classes.mainContainer)}>
                    <div className={classes.title}>Sternum by Numbers</div>
                    <div className={classes.tilesContainer}>
                        <Tiles>
                            {sternumByNumbers.map((sternumItem) => (
                                <Tiles.Item
                                    key={sternumItem.id}
                                    icon={<sternumItem.IconComponent />}
                                    title={sternumItem.title}
                                    description={sternumItem.description}
                                />
                            ))}
                        </Tiles>
                    </div>
                    <Button onClick={() => history.push("/signup")}>Get Started</Button>
                    <div className={classes.footerDescription}>
                        ⓒ All rights reserved. Sternum, {new Date().getFullYear()}
                    </div>
                </div>
            </div>
        </div>
    );
}

export const SternumByNumbersView: React.FC<SternumByNumbersViewProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SternumByNumbersViewComponent));
