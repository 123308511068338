import { Theme } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import { sternumTheme } from "../App";
import commonStyle from "../CommonStyle";

let securityIssuesDataTileStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        shieldIcon: {
            color: sternumTheme.success.main,
        },
        shieldIconSize: {
            fontSize: theme.spacing(10),
        },

        securityIssuesNumberContainer: {
            minWidth: 100,
            padding: theme.spacing(0, 3),
            height: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#FCFCFC",
            border: "1px solid #E8E8E8",
            borderRadius: 28,
            cursor: "pointer",
            transition: "all 0.2s ease",

            "& .MuiTypography-root": {
                color: "#E7004C",
                fontSize: 36,
                fontWeight: 600,
                transition: "all 0.2s ease",
            },

            "&:hover": {
                background: "#E7004C",

                "& .MuiTypography-root": {
                    color: "#fff",
                },
            },
        },
    });

export default securityIssuesDataTileStyle;
