import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

const argumentsListDisplayStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        paperClass: {
            overflowY: "auto" as "auto",
        },

        argument: {
            color: "#999999",
            backgroundColor: "#F0F3F5",
        },

        argumentDisplayName: {
            fontWeight: 500,
        },

        investigateIcon: {
            color: theme.palette.primary.main,
            fontSize: "0.8125rem",
            marginRight: theme.spacing(1),
        },
        investigateText: {
            fontSize: "0.8125rem",
        },
        traceLinkText: {
            fontSize: "0.8125rem",
            fontWeight: 400,
            marginRight: theme.spacing(1),
            "&:hover": {
                textDecoration: "underline",
            },
        },
        traceLinkIcon: {
            fontSize: "0.8125rem",
            paddingRight: theme.spacing(1),
        },

        attackRow: {
            backgroundColor: `rgba(209, 0, 39, 0.06) !important`,

            "&:hover": {
                backgroundColor: `rgba(209, 0, 39, 0.06) !important`,
                cursor: "pointer" as "pointer",
            },
        },
        viewedTraceIcon: {
            fontSize: "0.8125rem",
            marginRight: theme.spacing(1),
        },
        tracesActivityGraph: {
            marginLeft: theme.spacing(2),
            marginBottom: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
    });

export default argumentsListDisplayStyle;
