import { makeStyles } from "@material-ui/core";

export const useReferenceTraceSequenceStyle = makeStyles((theme) => {
    return {
        dropdownValueContainer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
        },
        iconContainer: {
            marginRight: 8,
            alignItems: "center",
            "& svg": {
                display: "flex",
            },
        },
        dropdownValueItem: {
            fontWeight: 500,
            alignItems: "center",
        },
        tableHead: {},
        tableHeadCell: {
            "th&": {
                borderBottom: "1px solid #E8ECF2",
                fontSize: 12,
                lineHeight: "16px",
                padding: "14px 28px",
            },
        },
        tableBodyCell: {
            borderBottom: "1px solid rgba(149, 149, 149, 0.08)",
        },
        displayNameCell: {
            "td&": {
                color: "#2B2E30",
            },
        },
        orderCell: {
            "td&": {
                color: "#999999",
            },
        },
        invalidSequence: {
            color: "#1B6FDE",
        },
    };
});
