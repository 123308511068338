import React from "react";
import classNames from "classnames";

import { useSternumEmptyModalStyles } from "./SternumEmptyModal.styles";

export interface TitleProps extends React.ComponentProps<"h2"> {}

export function Title({ className, children, ...props }: TitleProps) {
    const classes = useSternumEmptyModalStyles();

    return (
        <h2 className={classNames(classes.title, className)} {...props}>
            {children}
        </h2>
    );
}
