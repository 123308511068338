import React from "react";
import classNames from "classnames";

import { useTextAreaStyle } from "./TextArea.style";

export interface TextAreaProps extends React.ComponentProps<"textarea"> {
    classNameTextArea?: string;
    classNameFieldSet?: string;
    styleTextArea?: React.CSSProperties;
    isError?: boolean;
    belowTextArea?: React.ReactNode;
    label?: React.ReactNode;
}

export function TextArea({
    className,
    style,
    classNameTextArea,
    classNameFieldSet,
    styleTextArea,
    isError,
    belowTextArea,
    label,
    value,
    ...props
}: TextAreaProps) {
    const classes = useTextAreaStyle();

    const isEmpty = !value;

    return (
        <div className={classNames(classes.root, className)} style={style}>
            <div className={classNames(classes.textAreaContainer, !isEmpty && classes.textAreaIsNotEmpty)}>
                <div className={classNames(classes.textAreaLabel, isError && classes.textAreaLabelError)}>{label}</div>

                <>
                    <fieldset
                        className={classNames(
                            classNameFieldSet,
                            classes.textAreaFieldSet,
                            isError && classes.textAreaFieldSetError
                        )}
                    >
                        <legend className={classes.legend}>{label && <span>&nbsp;{label}&nbsp;</span>}</legend>
                    </fieldset>
                </>
                <textarea
                    className={classNames(classNameTextArea, classes.textArea)}
                    style={styleTextArea}
                    value={value}
                    {...props}
                />
            </div>

            {belowTextArea && (
                <div className={classNames(classes.belowTextArea, isError && classes.belowTextAreaError)}>
                    {belowTextArea}
                </div>
            )}
        </div>
    );
}
