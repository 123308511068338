enum ServerEntityType {
    USER,
    DEVICE,
    TRACE,
    TRACE_ARGUMENT,
    ISSUE,
    CVE,
    USED_LIBRARY,
    USED_LIBRARY_HISTORY,
    LIBRARY,
    LIBRARY_HISTORY,
    INTEGRATION,
    ACTIVITY,
    PROJECT,
    CLIENT,
    OUTGOING_WEBHOOK,
    API_KEY,
    DEVICE_PROCESS,
    DEVICE_PROCESS_INSTANCE,
    DEVICE_DEFINITION,
    TRACE_DEFINITION,
    ARGUMENT_DEFINITION,
    DEVICE_DEFINITION_LIBRARY,
    DEVICE_DEFINITION_LIBRARY_HISTORY,
    STERNUM_TRIGGER,
    STERNUM_GENERATED_EVENT,
    DEVICE_STERNUM_TRIGGER,
    STERNUM_GENERATED_EVENT_ENTITY,
    STERNUM_DEVICE_EVENT,
    DEVICE_DEFINITION_ADDRESS_MAPPING,
    CUSTOM_DASHBOARD,
    USER_RESET_PASSWORD_ACTIVITY,
    USER_MFA,
    STERNUM_OPERATION,
    USER_ROLE,
    PERMISSION,
    VISUALIZATION,
}

export default ServerEntityType;
