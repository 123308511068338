import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../../CommonStyle";

const whiteListTriggerStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),
        whitelistNewChip: {
            width: theme.spacing(30),
        },
        chipContainer: {
            height: theme.spacing(10),
            width: theme.spacing(55),
            paddingTop: theme.spacing(2.5),
            overflowY: "auto",
        },
        chipPaper: {
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            padding: theme.spacing(1) / 2,
        },
        chip: {
            margin: theme.spacing(1) / 2,
        },
        button: {
            margin: theme.spacing(1),
            padding: "4px",
        },
        customFilterInput: {
            width: theme.spacing(45),
        },
    });

export default whiteListTriggerStyle;
