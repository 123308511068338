import React from "react";

import { SternumIconProps } from "./SternumIcon.types";

interface SternumIconPropsWithAnimation extends SternumIconProps {
    useAnimation?: boolean;
}

export const SternumLogoGradientIcon = React.forwardRef<SVGSVGElement, SternumIconPropsWithAnimation>(
    function SternumLogoGradientIcon({ className, color, height = 26, width = 26, useAnimation, ...props }, ref) {
        return (
            <svg
                ref={ref}
                className={className}
                width={width}
                height={height}
                viewBox="0 0 73 72"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                aria-label="sternum logo with text - many colors"
                {...props}
            >
                <mask
                    id="mask0_6114_175607"
                    style={{ maskType: "luminance" }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="430"
                    height="72"
                >
                    <path d="M429.231 0H0V72H429.231V0Z" fill="white" />
                </mask>
                <g mask="url(#mask0_6114_175607)">
                    <path
                        d="M36.0424 72C29.9484 71.9945 23.9555 70.4473 18.6223 67.5028C13.2891 64.5582 8.78959 60.3122 5.54367 55.1609C2.29776 50.0095 0.411173 44.121 0.0599694 38.0445C-0.291234 31.968 0.904387 25.9016 3.53526 20.4115C3.7414 19.978 4.11139 19.644 4.56394 19.4829C5.01646 19.3217 5.51456 19.3466 5.94875 19.552C6.38297 19.7574 6.71774 20.1266 6.87957 20.5785C7.04141 21.0303 7.01704 21.5278 6.81184 21.9617C4.68169 26.3339 3.58992 31.1378 3.62146 36C3.62149 42.4254 5.53018 48.7063 9.10592 54.0476C12.6817 59.3889 17.7637 63.5505 23.7086 66.0054C29.6535 68.4604 36.1938 69.0984 42.5022 67.8387C48.8102 66.5787 54.6023 63.478 59.145 58.9284C63.6876 54.3788 66.777 48.5856 68.0214 42.2814C69.2659 35.9776 68.6099 29.4463 66.1362 23.515C63.6627 17.5836 59.4828 12.5186 54.1257 8.96123C48.7686 5.40388 42.4753 3.5141 36.0424 3.53113C29.5469 3.49233 23.1962 5.44641 17.8487 9.12924C17.4485 9.39193 16.9602 9.48506 16.4913 9.38814C16.0223 9.29124 15.6112 9.01224 15.3482 8.6125C15.0852 8.21276 14.9919 7.72507 15.089 7.25669C15.186 6.7883 15.4653 6.37757 15.8655 6.1149C21.2954 2.47645 27.6125 0.379102 34.1427 0.046738C40.6725 -0.285627 47.1705 1.15947 52.9424 4.22776C58.7143 7.29606 63.5439 11.8724 66.9151 17.4682C70.2867 23.064 72.0734 29.469 72.0845 36C72.0595 45.54 68.2543 54.6823 61.5005 61.4282C54.7466 68.174 45.5937 71.9751 36.0424 72Z"
                        fill={color || "url(#paint0_linear_6114_175607)"}
                    >
                        {useAnimation && (
                            <>
                                <animateTransform
                                    id="firstCircleForward"
                                    attributeName="transform"
                                    type="rotate"
                                    dur="1.5s"
                                    begin="0s; firstCircleBack.end+0.5s"
                                    from="0 36 36"
                                    to="200 36 36"
                                />
                                <animateTransform
                                    id="firstCircleBack"
                                    attributeName="transform"
                                    type="rotate"
                                    dur="1.5s"
                                    begin="firstCircleForward.end"
                                    from="200 36 36"
                                    to="0 36 36"
                                />
                            </>
                        )}
                    </path>
                    <path
                        d="M36.0377 64.3354C30.427 64.3354 24.9423 62.6736 20.2772 59.5602C15.6121 56.4467 11.976 52.0212 9.82892 46.8439C7.68179 41.6662 7.12 35.9691 8.21459 30.4727C9.30919 24.9762 12.011 19.9274 15.9784 15.9646C19.9457 12.0019 25.0004 9.30329 30.5034 8.20999C36.0061 7.11667 41.7102 7.6778 46.8936 9.82241C52.0774 11.967 56.5078 15.5988 59.6249 20.2584C62.7422 24.9181 64.406 30.3963 64.406 36.0004C64.4065 36.2383 64.36 36.4739 64.2692 36.6938C64.1783 36.9137 64.0451 37.1133 63.8768 37.2817C63.7087 37.4501 63.509 37.5836 63.2889 37.6747C63.069 37.7658 62.8333 37.8128 62.5952 37.8128C62.357 37.8128 62.1214 37.7658 61.9015 37.6747C61.6816 37.5836 61.4817 37.4501 61.3136 37.2817C61.1452 37.1133 61.012 36.9137 60.9212 36.6938C60.8303 36.4739 60.7841 36.2383 60.7844 36.0004C60.7844 31.1119 59.333 26.3329 56.6139 22.268C53.8948 18.2032 50.0298 15.0351 45.5079 13.1643C40.986 11.2934 36.0103 10.804 31.2098 11.7577C26.4095 12.7114 22 15.0656 18.5391 18.5224C15.0782 21.9792 12.7213 26.3835 11.7665 31.1783C10.8116 35.973 11.3017 40.9429 13.1747 45.4595C15.0478 49.9761 18.2196 53.8365 22.2892 56.5525C26.3588 59.2686 31.1434 60.7183 36.0377 60.7183C40.0979 60.7365 44.0989 59.7465 47.6812 57.8372C51.2632 55.928 54.3141 53.1594 56.5594 49.7804C56.6895 49.5824 56.8576 49.4121 57.0537 49.2791C57.2497 49.1459 57.4704 49.0526 57.7025 49.0047C57.9348 48.9568 58.1741 48.9549 58.407 48.9994C58.6399 49.0437 58.8617 49.1335 59.06 49.2636C59.258 49.3938 59.4286 49.5616 59.562 49.7574C59.6952 49.9532 59.7886 50.1736 59.8365 50.4054C59.8846 50.6374 59.8863 50.8764 59.842 51.109C59.7974 51.3416 59.7074 51.5632 59.5773 51.7612C56.9897 55.6273 53.4883 58.797 49.3826 60.9902C45.277 63.1832 40.6936 64.3321 36.0377 64.3354Z"
                        fill={color || "url(#paint1_linear_6114_175607)"}
                    >
                        {useAnimation && (
                            <>
                                <animateTransform
                                    id="secondCircleForward"
                                    attributeName="transform"
                                    type="rotate"
                                    dur="1.5s"
                                    begin="0s; secondCircleBack.end+0.5s"
                                    from="0 36 36"
                                    to="-200 36 36"
                                />
                                <animateTransform
                                    id="secondCircleBack"
                                    attributeName="transform"
                                    type="rotate"
                                    dur="1.5s"
                                    begin="secondCircleForward.end"
                                    from="-200 36 36"
                                    to="0 36 36"
                                />
                            </>
                        )}
                    </path>
                    <path
                        d="M36.041 56.7559C33.3125 56.7672 30.6094 56.232 28.0917 55.1819C25.5738 54.1315 23.2925 52.5874 21.3826 50.6412C18.4822 47.7351 16.5073 44.0371 15.7062 40.0126C14.9052 35.988 15.3138 31.8168 16.8808 28.0235C18.4478 24.2304 21.103 20.985 24.5123 18.6957C27.9216 16.4064 31.9329 15.1755 36.041 15.1579C38.7695 15.1465 41.4726 15.6818 43.9904 16.7321C46.5082 17.7824 48.7894 19.3264 50.6994 21.2727C50.8633 21.4367 50.9938 21.6314 51.0826 21.8457C51.1715 22.06 51.2172 22.2896 51.2172 22.5215C51.2172 22.7534 51.1715 22.9831 51.0826 23.1974C50.9938 23.4116 50.8633 23.6063 50.6994 23.7703C50.5352 23.9343 50.3402 24.0644 50.1256 24.1532C49.911 24.2419 49.6811 24.2876 49.4491 24.2876C49.2167 24.2876 48.9869 24.2419 48.7723 24.1532C48.5579 24.0644 48.363 23.9343 48.1988 23.7703C46.6051 22.174 44.7095 20.9102 42.6224 20.0526C40.5349 19.195 38.2979 18.7608 36.041 18.7751C31.4898 18.786 27.1246 20.5795 23.8831 23.7703C21.4734 26.1674 19.8313 29.2256 19.1651 32.5567C18.499 35.8878 18.8389 39.3416 20.1416 42.4791C21.4444 45.6169 23.6513 48.2973 26.4822 50.1798C29.3129 52.0626 32.6401 53.0623 36.041 53.0526C38.5762 53.0592 41.0816 52.5051 43.377 51.4296C45.6724 50.3543 47.7009 48.7844 49.3164 46.8329C50.9323 44.8814 52.0954 42.5968 52.722 40.143C53.3487 37.6892 53.4235 35.1274 52.9411 32.6412C52.8954 32.4149 52.8948 32.1817 52.9394 31.9549C52.984 31.7284 53.0729 31.513 53.2011 31.3208C53.329 31.1283 53.4938 30.9633 53.686 30.8348C53.8779 30.7063 54.0934 30.6166 54.3202 30.5714C54.547 30.526 54.7804 30.526 55.0072 30.5709C55.2337 30.6157 55.4494 30.7049 55.6419 30.8331C55.8341 30.9613 55.9991 31.1261 56.1276 31.3183C56.2558 31.5102 56.3453 31.7256 56.3902 31.9522C57.0492 35.2813 56.8748 38.7213 55.8817 41.9668C54.889 45.2124 53.1086 48.1624 50.6994 50.555C48.8058 52.5289 46.5295 54.0969 44.0092 55.163C41.4892 56.2292 38.7778 56.7711 36.041 56.7559Z"
                        fill={color || "url(#paint2_linear_6114_175607)"}
                    >
                        {useAnimation && (
                            <>
                                <animateTransform
                                    id="thirdCircleForward"
                                    attributeName="transform"
                                    type="rotate"
                                    dur="1.2s"
                                    begin="0s; thirdCircleBack.end+0.5s"
                                    from="0 36 36"
                                    to="200 36 36"
                                />
                                <animateTransform
                                    id="thirdCircleBack"
                                    attributeName="transform"
                                    type="rotate"
                                    dur="1.2s"
                                    begin="thirdCircleForward.end"
                                    from="200 36 36"
                                    to="0 36 36"
                                />
                            </>
                        )}
                    </path>
                    <path
                        d="M36.0398 48.2301C33.6276 48.24 31.2671 47.5333 29.2583 46.1997C27.2495 44.866 25.6833 42.9658 24.7589 40.7404C23.8344 38.5153 23.5936 36.0657 24.0669 33.7032C24.5403 31.3408 25.7065 29.1725 27.4172 27.474C27.7488 27.1428 28.1985 26.9567 28.6673 26.9567C29.1364 26.9567 29.5862 27.1428 29.9176 27.474C30.2494 27.8051 30.4355 28.2545 30.4355 28.7228C30.4355 29.1911 30.2494 29.6405 29.9176 29.9717C28.4829 31.4341 27.6204 33.3618 27.4868 35.4052C27.3531 37.4486 27.957 39.4721 29.1893 41.1087C30.4214 42.7453 32.1998 43.8865 34.2022 44.3255C36.2046 44.7644 38.2982 44.472 40.1034 43.5011C41.9084 42.5304 43.3052 40.9456 44.0404 39.034C44.7759 37.1227 44.8009 35.0114 44.1113 33.0832C43.4215 31.1547 42.0626 29.5372 40.2812 28.5239C38.4998 27.5107 36.4137 27.1687 34.4016 27.5601C33.9444 27.6505 33.4703 27.5562 33.0829 27.2977C32.6954 27.0392 32.4266 26.6378 32.3352 26.1815C32.2438 25.7252 32.3374 25.2514 32.5955 24.8639C32.8536 24.4765 33.2551 24.2072 33.7118 24.1151C35.3816 23.7849 37.1024 23.8077 38.7631 24.1822C40.4238 24.5567 41.9873 25.2746 43.3534 26.2895C44.7192 27.3045 45.8571 28.594 46.6936 30.075C47.5302 31.556 48.0467 33.1956 48.2103 34.8882C48.3737 36.5808 48.1802 38.2888 47.6424 39.9022C47.1046 41.5155 46.2345 42.9984 45.0878 44.2554C43.9413 45.5124 42.5437 46.5154 40.9851 47.1999C39.4269 47.8845 37.7421 48.2353 36.0398 48.2301Z"
                        fill={color || "url(#paint3_linear_6114_175607)"}
                    >
                        {useAnimation && (
                            <>
                                <animateTransform
                                    id="fourthCircleForward"
                                    attributeName="transform"
                                    type="rotate"
                                    dur="1s"
                                    begin="0s; fourthCircleBack.end+0.5s"
                                    from="0 36 36"
                                    to="-200 36 36"
                                />
                                <animateTransform
                                    id="fourthCircleBack"
                                    attributeName="transform"
                                    type="rotate"
                                    dur="1s"
                                    begin="fourthCircleForward.end"
                                    from="-200 36 36"
                                    to="0 36 36"
                                />
                            </>
                        )}
                    </path>
                </g>
                <defs>
                    <linearGradient
                        id="paint0_linear_6114_175607"
                        x1="36.0424"
                        y1="5.80447"
                        x2="36.0424"
                        y2="64.0279"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#EF145D" />
                        <stop offset="0.19651" stopColor="#EC155E" />
                        <stop offset="0.352" stopColor="#E21660" />
                        <stop offset="0.49349" stopColor="#D11A65" />
                        <stop offset="0.62665" stopColor="#BA1E6B" />
                        <stop offset="0.75406" stopColor="#9B2473" />
                        <stop offset="0.87695" stopColor="#762A7D" />
                        <stop offset="0.99436" stopColor="#4A3388" />
                        <stop offset="1" stopColor="#483389" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_6114_175607"
                        x1="36.0377"
                        y1="5.80489"
                        x2="36.0377"
                        y2="64.0283"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#EF145D" />
                        <stop offset="0.19651" stopColor="#EC155E" />
                        <stop offset="0.352" stopColor="#E21660" />
                        <stop offset="0.49349" stopColor="#D11A65" />
                        <stop offset="0.62665" stopColor="#BA1E6B" />
                        <stop offset="0.75406" stopColor="#9B2473" />
                        <stop offset="0.87695" stopColor="#762A7D" />
                        <stop offset="0.99436" stopColor="#4A3388" />
                        <stop offset="1" stopColor="#483389" />
                    </linearGradient>
                    <linearGradient
                        id="paint2_linear_6114_175607"
                        x1="36.0352"
                        y1="5.80443"
                        x2="36.0352"
                        y2="64.0279"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#EF145D" />
                        <stop offset="0.19651" stopColor="#EC155E" />
                        <stop offset="0.352" stopColor="#E21660" />
                        <stop offset="0.49349" stopColor="#D11A65" />
                        <stop offset="0.62665" stopColor="#BA1E6B" />
                        <stop offset="0.75406" stopColor="#9B2473" />
                        <stop offset="0.87695" stopColor="#762A7D" />
                        <stop offset="0.99436" stopColor="#4A3388" />
                        <stop offset="1" stopColor="#483389" />
                    </linearGradient>
                    <linearGradient
                        id="paint3_linear_6114_175607"
                        x1="36.0398"
                        y1="5.80473"
                        x2="36.0398"
                        y2="64.0283"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#EF145D" />
                        <stop offset="0.19651" stopColor="#EC155E" />
                        <stop offset="0.352" stopColor="#E21660" />
                        <stop offset="0.49349" stopColor="#D11A65" />
                        <stop offset="0.62665" stopColor="#BA1E6B" />
                        <stop offset="0.75406" stopColor="#9B2473" />
                        <stop offset="0.87695" stopColor="#762A7D" />
                        <stop offset="0.99436" stopColor="#4A3388" />
                        <stop offset="1" stopColor="#483389" />
                    </linearGradient>
                </defs>
            </svg>
        );
    }
);
