import React from "react";

import { SternumIconProps } from "./SternumIcon.types";

export const CircleInformationIcon = React.forwardRef<SVGSVGElement, SternumIconProps>(function CircleInformationIcon(
    { color, height = 24, width = 24, ...props },
    ref
) {
    return (
        <svg
            ref={ref}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 5.99997C11.8022 5.99997 11.6089 6.05861 11.4444 6.16849C11.28 6.27838 11.1518 6.43456 11.0761 6.61728C11.0004 6.80001 10.9806 7.00107 11.0192 7.19506C11.0578 7.38904 11.153 7.56722 11.2929 7.70707C11.4327 7.84693 11.6109 7.94217 11.8049 7.98075C11.9989 8.01934 12.2 7.99953 12.3827 7.92385C12.5654 7.84816 12.7216 7.71999 12.8315 7.55554C12.9414 7.39109 13 7.19775 13 6.99997C13 6.73475 12.8946 6.4804 12.7071 6.29286C12.5196 6.10532 12.2652 5.99997 12 5.99997ZM11 9.99997C11 9.44768 11.4477 8.99997 12 8.99997C12.5523 8.99997 13 9.44768 13 9.99997V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V9.99997Z"
                fill={color || "currentColor"}
            />
        </svg>
    );
});
