import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
    MenuItem,
    Typography,
    withStyles,
} from "@material-ui/core";
import { WithStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import SternumUtils from "../../../lib/infra/SternumUtils";
import Utils from "../../../lib/infra/Utils";
import ServiceWire from "../../../lib/services/ServiceWire";
import EventInterest from "../../../lib/state/EventInterest";
import { GlobalState } from "../../../lib/state/GlobalState";
import TraceCategory from "../../../lib/state/TraceCategory";
import TransmitFrequency from "../../../lib/state/TransmitFrequency";
import CustomInput from "../../../shared_components/CustomInput/CustomInput";
import InfoTooltip from "../../../shared_components/InfoTooltip/InfoTooltip";
import traceDefinitionSimpleDialogStyle from "./TraceDefinitionSimpleDialogStyle";

interface AppState {
    traceDisplayName: string;
    traceEventName: string;
    traceCategory: string;
    traceInterest: string;
    transmitFrequency: string;
    isDuplicateError: boolean;
    displayLoadingBarOnSave: boolean;
    displayLoadingBarOnAddAnother: boolean;
    traceDescription: string;
}

export interface AppProps extends WithStyles<typeof traceDefinitionSimpleDialogStyle> {
    categoriesList: TraceCategory[];
    open: boolean;
    existingTraceDefinition?: any;
    duplicationCheck: (
        traceName: string,
        traceEvent: string,
        traceCategoryType: string,
        transmitFrequency: string,
        traceInterest: string
    ) => boolean;
    onClose: (
        traceName: string,
        traceEventName: string,
        traceCategory: string,
        traceInterest: string,
        transmitFrequency: string,
        description: string,
        keepDialogOpen: boolean,
        selectedTrace?: any
    ) => void;
    onCancel: () => void;
    isError?: boolean;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {};
};

///

class TraceDefinitionSimpleDialog extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);
        // Initializing the state to default.
        if (this.props.existingTraceDefinition) {
            this.state = {
                traceDisplayName: this.props.existingTraceDefinition.displayName,
                traceEventName: this.props.existingTraceDefinition.traceEventName,
                traceCategory: this.props.existingTraceDefinition.traceCategory,
                transmitFrequency: this.props.existingTraceDefinition.transmitFrequency,
                traceInterest: this.props.existingTraceDefinition.traceInterest,
                isDuplicateError: false,
                displayLoadingBarOnSave: false,
                displayLoadingBarOnAddAnother: false,
                traceDescription: this.props.existingTraceDefinition.description,
            };
        } else {
            this.state = {
                traceDisplayName: "",
                traceEventName: "",
                traceCategory: "",
                transmitFrequency: TransmitFrequency[TransmitFrequency.BATCH],
                isDuplicateError: false,
                traceInterest: EventInterest[EventInterest.REGULAR],
                displayLoadingBarOnSave: false,
                displayLoadingBarOnAddAnother: false,
                traceDescription: "",
            };
        }
    }

    /*
     * Handle save operation and check if it's duplicate operation.
     */
    private onSaveClick = (keepDialogOpen: boolean) => {
        if (!this.checkDuplication()) {
            this.setState({ displayLoadingBarOnSave: !keepDialogOpen, displayLoadingBarOnAddAnother: keepDialogOpen });
            this.props.onClose(
                this.state.traceDisplayName,
                this.state.traceEventName,
                this.state.traceCategory,
                this.state.traceInterest,
                this.state.transmitFrequency,
                this.state.traceDescription,
                keepDialogOpen,
                this.props.existingTraceDefinition
            );
            // Clear field to insert another trace
            if (keepDialogOpen) {
                this.clearFields();
            }
        }
    };

    /**
     * Handle close click
     */
    private handleCloseClick = () => {
        this.props.onCancel();
    };

    /*
     * Validate fields are not empty
     */

    private fieldsValidation = (): boolean => {
        return this.state.traceDisplayName === "" || this.state.traceCategory === "" || this.state.traceInterest === "";
    };

    /*
     * Update category field
     */
    private onTraceCategoryChanged = (event) => {
        this.setState({
            traceCategory: event.target.value,
            isDuplicateError: false,
        });
    };

    /*
     * Update interest field
     */
    private onTraceInterestChanged = (event) => {
        this.setState({
            traceInterest: event.target.value,
            isDuplicateError: false,
        });
    };

    /*
     * Update is critical field
     */

    private onTransmitChange = (event) => {
        const transmit =
            this.state.transmitFrequency === TransmitFrequency[TransmitFrequency.BATCH]
                ? TransmitFrequency[TransmitFrequency.IMMEDIATELY]
                : TransmitFrequency[TransmitFrequency.BATCH];
        this.setState({
            transmitFrequency: transmit,
            isDuplicateError: false,
        });
    };

    /**
     * Clear fields for another instance.
     */
    private clearFields = () => {
        this.setState({
            traceDisplayName: "",
            traceEventName: "",
            traceCategory: "",
            traceInterest: EventInterest[EventInterest.REGULAR],
            transmitFrequency: TransmitFrequency[TransmitFrequency.BATCH],
            isDuplicateError: false,
            displayLoadingBarOnSave: false,
            displayLoadingBarOnAddAnother: false,
            traceDescription: "",
        });
    };

    /*
     * Update name field and trace type
     */

    private onDisplayNameChanged = (event) => {
        const newValue = event.target.value;
        const traceEventName = "TRACE_" + Utils.toUpperCaseWithUnderscore(newValue);

        this.setState({
            traceDisplayName: newValue,
            traceEventName: traceEventName,
            isDuplicateError: false,
        });
    };

    /*
     * Update description field
     */

    private onDescriptionChanged = (event) => {
        const description = event.target.value;

        this.setState({
            traceDescription: description,
        });
    };

    render() {
        const { classes } = this.props;

        const duplicationError = (
            <Typography variant={"subtitle2"} className={classNames(classes.marginBottomMedium)} color="error">
                Trace already exists!
            </Typography>
        );

        const serverError = (
            <Typography variant={"subtitle2"} className={classNames(classes.marginBottomMedium)} color="error">
                Operation failed
            </Typography>
        );

        return (
            <Dialog
                disableEnforceFocus
                onClose={() => {
                    this.props.existingTraceDefinition ? this.handleCloseClick() : "";
                }}
                role="dialog"
                aria-label="add trace definition"
                aria-labelledby="simple-dialog-title"
                open={this.props.open}
            >
                <DialogTitle id="simple-dialog-title">
                    {this.props.existingTraceDefinition ? "Edit" : "Add"} Trace Definition
                </DialogTitle>

                <div className={classNames(classes.flexColumn, classes.flexCenter, classes.flexVMiddle, classes.root)}>
                    {/* Display Name */}

                    <div className={classNames(classes.marginBottomLarge)}>
                        <CustomInput
                            value={this.state.traceDisplayName}
                            inputTitle={"Display Name"}
                            maxHeightClass={""}
                            inputClasses={""}
                            autoFocus={true}
                            onChange={this.onDisplayNameChanged}
                            required={true}
                            id={"trace-display-name"}
                            maxLength={SternumUtils.getMaxInputFieldSize()}
                        />
                    </div>

                    {/* Display Trace type */}
                    <div className={classNames(classes.marginBottomLarge)}>
                        <CustomInput
                            value={this.state.traceEventName}
                            inputTitle={"Trace Type"}
                            maxHeightClass={""}
                            inputClasses={""}
                            autoFocus={false}
                            onChange={() => {}}
                            disabled={true}
                            required={false}
                            id={"trace-type-name"}
                        />
                    </div>

                    {/* Display Trace category type */}
                    <div className={classNames(classes.marginBottomLarge)}>
                        <CustomInput
                            value={this.state.traceCategory}
                            inputTitle={"Trace Category"}
                            maxHeightClass={""}
                            inputClasses={""}
                            autoFocus={false}
                            onChange={this.onTraceCategoryChanged}
                            required={true}
                            id={"trace-category"}
                            select={true}
                        >
                            {/* Empty menu item to fix autoSelect first element */}
                            <MenuItem style={{ display: "none" }} />
                            {ServiceWire.getConfigurationService()
                                .getTraceCategories()
                                .map((traceCategory) => (
                                    <MenuItem
                                        aria-label="trace category menu item"
                                        key={traceCategory.displayName}
                                        value={traceCategory.systemName}
                                        className={classNames(classes.selectItem)}
                                    >
                                        <div
                                            className={classNames(
                                                classes.flexRow,
                                                classes.flexSpaceBetween,
                                                classes.fullWidth
                                            )}
                                        >
                                            {/* Display trigger type name */}
                                            <Typography variant="body2">{traceCategory.displayName}</Typography>
                                            {/* Display info icon for additional information */}
                                            <InfoTooltip tooltipContent={traceCategory.description} />
                                        </div>
                                    </MenuItem>
                                ))}
                        </CustomInput>
                    </div>

                    {/* Display Trace interest type */}
                    <div className={classNames(classes.marginBottomLarge)}>
                        <CustomInput
                            value={this.state.traceInterest}
                            inputTitle={"Trace Interest"}
                            maxHeightClass={""}
                            inputClasses={""}
                            autoFocus={false}
                            onChange={this.onTraceInterestChanged}
                            required={true}
                            id={"trace-interest"}
                            select={true}
                        >
                            {/* Empty menu item to fix autoSelect first element */}
                            <MenuItem style={{ display: "none" }} />
                            {SternumUtils.getEventInterests(false, true).map((option) => (
                                <MenuItem
                                    aria-label="trace interest menu item"
                                    key={option.value}
                                    value={option.value}
                                    className={classNames(classes.selectItem)}
                                >
                                    <div
                                        className={classNames(
                                            classes.flexVMiddle,
                                            classes.flexSpaceBetween,
                                            classes.fullWidth
                                        )}
                                    >
                                        <Typography variant="body2">{option.label}</Typography>
                                        {/* Display info icon for additional information */}
                                        {option.explanation && <InfoTooltip tooltipContent={option.explanation} />}
                                    </div>
                                </MenuItem>
                            ))}
                        </CustomInput>
                    </div>

                    {/*Trace Description */}
                    <div className={classNames(classes.marginBottomLarge)}>
                        <CustomInput
                            value={this.state.traceDescription}
                            inputTitle={"Description"}
                            maxHeightClass={""}
                            inputClasses={""}
                            autoFocus={false}
                            onChange={this.onDescriptionChanged}
                            required={false}
                            id={"trace-description"}
                        />
                    </div>

                    {/* Trace is critical checkbox */}
                    <div
                        className={classNames(
                            classes.fullWidth,
                            classes.marginBottomLarge,
                            classes.flexRow,
                            classes.flexVMiddle
                        )}
                    >
                        <Checkbox
                            role="checkbox"
                            aria-label="report immediately"
                            className={classes.checkBoxPadding}
                            checked={this.state.transmitFrequency == TransmitFrequency[TransmitFrequency.IMMEDIATELY]}
                            onChange={this.onTransmitChange}
                            value={TransmitFrequency[TransmitFrequency.IMMEDIATELY]}
                            color="primary"
                        />

                        <Typography variant={"body2"}>{"Report Immediately"}</Typography>
                    </div>

                    {/* Trace duplication Error */}
                    {this.state.isDuplicateError ? duplicationError : " "}
                    {this.props.isError ? serverError : " "}
                </div>
                <DialogActions role="presentation" aria-label="buttons container">
                    <Button
                        variant="contained"
                        color="default"
                        onClick={this.handleCloseClick}
                        disabled={
                            (this.state.displayLoadingBarOnAddAnother || this.state.displayLoadingBarOnSave) &&
                            !this.props.isError
                        }
                    >
                        Close
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={
                            (this.fieldsValidation() ||
                                this.state.displayLoadingBarOnAddAnother ||
                                this.state.displayLoadingBarOnSave) &&
                            !this.props.isError
                        }
                        onClick={() => this.onSaveClick(false)}
                    >
                        {this.props.existingTraceDefinition ? "Update" : "Save"}
                        {/* Loading */}
                        {this.state.displayLoadingBarOnSave && !this.props.isError && (
                            <CircularProgress size={20} color="inherit" />
                        )}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={
                            (this.fieldsValidation() ||
                                this.state.displayLoadingBarOnAddAnother ||
                                this.state.displayLoadingBarOnSave) &&
                            !this.props.isError
                        }
                        onClick={() => this.onSaveClick(true)}
                    >
                        {"Add Another"}
                        {/* Loading */}
                        {this.state.displayLoadingBarOnAddAnother && !this.props.isError && (
                            <CircularProgress size={20} color="inherit" />
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    /*
     * Check if user trying to create/update to existing trace
     */

    private checkDuplication(): boolean {
        const isDuplicate = this.props.duplicationCheck(
            this.state.traceDisplayName,
            this.state.traceEventName,
            this.state.traceCategory,
            this.state.transmitFrequency,
            this.state.traceInterest
        );
        if (isDuplicate === true) {
            if (this.state.isDuplicateError === false) {
                this.setState({
                    isDuplicateError: true,
                });
            }
        } else {
            if (this.state.isDuplicateError === true) {
                this.setState({
                    isDuplicateError: false,
                });
            }
        }
        return isDuplicate;
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(traceDefinitionSimpleDialogStyle)(TraceDefinitionSimpleDialog));
