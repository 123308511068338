import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popover from "@material-ui/core/Popover";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { InfoCircleIcon } from "../SUI/SternumIcon/SternumIcon";
import explanationComponentStyle from "./ExplanationComponentStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    anchorElement;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof explanationComponentStyle> {
    anchorOrigin?: any;
    transformOrigin?: any;
    openOnHover?: boolean;
    size?: "medium" | "large";
}

/**
 * Displays the dashboard of sternum.
 */
class ExplanationComponent extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            anchorElement: null,
        };
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        return (
            <div>
                <ClickAwayListener onClickAway={this.handleExplanationPopoverClosed}>
                    <div>
                        {/* Explanation popover */}
                        <Popover
                            id="explanation-popover"
                            className={classes.popover}
                            disableRestoreFocus={this.props.openOnHover}
                            open={!!this.state.anchorElement}
                            anchorEl={this.state.anchorElement}
                            onClose={this.handleExplanationPopoverClosed}
                            elevation={10}
                            anchorOrigin={
                                this.props.anchorOrigin
                                    ? this.props.anchorOrigin
                                    : {
                                          vertical: "bottom",
                                          horizontal: "center",
                                      }
                            }
                            transformOrigin={
                                this.props.transformOrigin
                                    ? this.props.transformOrigin
                                    : {
                                          vertical: "top",
                                          horizontal: "center",
                                      }
                            }
                        >
                            <div
                                role="presentation"
                                aria-label="explanation popover content"
                                className={classNames(classes.padding, classes.popoverContentContainer)}
                            >
                                {this.props.children}
                            </div>
                        </Popover>

                        {/* Explanation Icon */}
                        <div
                            role="presentation"
                            aria-label="explanation container with popover"
                            className={classNames(classes.cursorPointer, classes.flexVMiddle, classes.marginLeftXs)}
                            onMouseEnter={(event) => this.explanationMouseEnter(event)}
                            onMouseLeave={() => this.explanationMouseLeave()}
                            onClick={(event) => this.explanationIconClicked(event)}
                        >
                            <InfoCircleIcon
                                width={this.props.size === "large" ? 30 : 18}
                                height={this.props.size === "large" ? 30 : 18}
                                color="#1B6FDE"
                            />
                        </div>
                    </div>
                </ClickAwayListener>
            </div>
        );
    }

    /**
     * Occurs on mouse enter.
     */
    private explanationMouseEnter(event) {
        if (this.props.openOnHover) {
            this.setState({
                anchorElement: event.currentTarget,
            });
        }
    }

    /**
     * Occurs on mouse leave.
     */
    private explanationMouseLeave() {
        if (this.props.openOnHover) {
            this.setState({
                anchorElement: null,
            });
        }
    }

    /**
     * Occurs once the explanation popover is closed.
     */
    private handleExplanationPopoverClosed = () => {
        this.setState({
            anchorElement: null,
        });
    };

    /**
     * Occurs once the explanation of a metric is opened.
     */
    private explanationIconClicked(event) {
        this.setState({
            anchorElement: this.state.anchorElement ? null : event.currentTarget,
        });
    }
}

export default withStyles(explanationComponentStyle)(ExplanationComponent);
