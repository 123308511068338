import ArgumentRoleType from "../state/ArgumentRoleType";
import SpecialField from "../state/SpecialField";
import SternumAgentConfiguration from "../state/SternumAgentConfiguration";
import TraceCategory from "../state/TraceCategory";
import SternumService from "./SternumService";

/**
 * Service to handle the different configurations in Sternum that are loaded from the backend (and not defined in the app itself).
 */
class ConfigurationService {
    /**
     * Service used for outgoing sternum API calls.
     */
    private sternumService: SternumService;

    private isDataLoaded: boolean;

    /**
     * The different argument role types in Sternum.
     */
    private argumentRoleTypes: ArgumentRoleType[] = [];
    /**
     * The different trace categories in Sternum.
     */
    private traceCategories: TraceCategory[] = [];

    private specialFields: SpecialField[] = [];

    private interests = [
        { label: "Regular", value: "0" },
        {
            label: "Low",
            value: "1",
        },
        {
            label: "Medium",
            value: "2",
        },
        {
            label: "High",
            value: "3",
        },
    ];

    /**
     * The different trace categories in Sternum as map <category name, category object>
     */
    private traceCategoriesMap: Map<string, TraceCategory> = new Map<string, TraceCategory>();

    /**
     * Constructor.
     */
    constructor(sternumService: SternumService) {
        this.sternumService = sternumService;
        this.isDataLoaded = false;
    }

    /**
     * Loads the different configuration from the backend.
     */
    public async loadConfigurations(): Promise<void> {
        let sternumAgentConfiguration: SternumAgentConfiguration = await this.sternumService.getSternumConfigurations();

        // Argument role types.
        this.argumentRoleTypes = sternumAgentConfiguration.argumentRoleTypes;

        // Trace categories.
        this.traceCategories = sternumAgentConfiguration.traceCategories;

        // Special fields
        this.specialFields = sternumAgentConfiguration.specialFields;

        // Populate traceCategories map
        this.traceCategories.forEach((traceCategory) =>
            this.traceCategoriesMap.set(traceCategory.systemName, traceCategory)
        );

        // Update data is loaded from server.
        this.isDataLoaded = true;
    }

    /**
     * Gets whether given identifier is a special field.
     */
    public isSpecialField(identifier: string): boolean {
        return this.specialFields.some((specialField) => specialField.id === identifier);
    }

    /**
     * Gets whether given identifier is a special field comparing the labels.
     */
    public isSpecialFieldByLabel(label: string): boolean {
        return this.specialFields.some((specialField) => specialField.displayName === label);
    }

    /**
     * Gets a special field by identifier.
     */
    public getSpecialField(identifier: string): SpecialField | undefined {
        return this.specialFields.find((specialField) => specialField.id === identifier);
    }

    /**
     * Gets a special field by label.
     */
    public getSpecialFieldByLabel(label: string): SpecialField | undefined {
        return this.specialFields.find((specialField) => specialField.displayName === label);
    }

    /**
     * Returns the loaded argument role types in Sternum.
     */
    public getArgumentRoleTypes(): ArgumentRoleType[] {
        return this.argumentRoleTypes;
    }

    /**
     * Returns the loaded trace categories in Sternum.
     */
    public getTraceCategories(): TraceCategory[] {
        return this.traceCategories;
    }

    public getInterests() {
        return this.interests;
    }

    /**
     * Returns the loaded trace categories map in Sternum.
     */
    public getTraceCategoriesMap(): Map<string, TraceCategory> {
        return this.traceCategoriesMap;
    }

    public getIsDataLoadedFlag(): boolean {
        if (this.traceCategories.length === 0 || this.argumentRoleTypes.length === 0) {
            this.isDataLoaded = false;
        }
        return this.isDataLoaded;
    }

    public static isNewArchitectureEnabled(): boolean {
        return process.env.STERNUM_ENABLE_FEATURES_FROM_NEW_ARCHITECTURE === "true";
    }

    /**
     * Returns the identifier for ALL_TRACES filter.
     */
    public static getAllEventsFilterField(): SpecialField {
        return new SpecialField("TRDE9G", "All Events");
    }

    public static getAllTracesFilterField(): SpecialField {
        return new SpecialField("TRDEBG", "All Traces");
    }

    /**
     * Returns the identifier for ALL_ALERTS filter.
     */
    public static getAllAlertsFilterField(): SpecialField {
        return new SpecialField("TRDEAG", "All Alerts");
    }

    /**
     * Returns the argument identifier for Event Type.
     */
    public static getEventTypeArgumentField(): SpecialField {
        return new SpecialField("ARDE9G", "Event Type");
    }

    /**
     * Returns the argument identifier for Interest.
     */
    public static getInterestArgumentField(): SpecialField {
        return new SpecialField("ARDEAG", "Interest");
    }

    /**
     * Returns the argument identifier for Category.
     */
    public static getCategoryArgumentField(): SpecialField {
        return new SpecialField("ARDEBG", "Category");
    }

    /**
     * Returns the argument identifier for device id
     */
    public static getDeviceIdArgumentField(): SpecialField {
        return new SpecialField("ARDECG", "Device");
    }
}

export default ConfigurationService;
