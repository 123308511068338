import TimeSelectOption from "./TimeSelectOption";

class TimeSelectOptionElementHolder {
    /**
     * Constructor.
     */

    constructor(public label: React.ReactNode, public value: TimeSelectOption) {}
}

export default TimeSelectOptionElementHolder;
