import React from "react";
import classNames from "classnames";

import { useLegendDetailsStyles } from "./LegendDetails.styles";

export interface LegendItemProps extends React.ComponentProps<"div"> {
    circleColor?: string;
    count?: number;
}

export function LegendItem({ className, children, circleColor, count, ...props }: LegendItemProps) {
    const classes = useLegendDetailsStyles();

    return (
        <div className={classNames(className, classes.legendItem)} {...props}>
            {circleColor && <div className={classes.circle} style={{ background: circleColor }} />}
            <div className={classes.content}>{children}</div>
            {count !== undefined && <div className={classes.count}>{count}</div>}
        </div>
    );
}
