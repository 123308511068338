import { Typography, withStyles, WithStyles } from "@material-ui/core";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { setUserInfoAction } from "../../../lib/redux/users/SetUserAction";
import ServiceWire from "../../../lib/services/ServiceWire";
import { GlobalState } from "../../../lib/state/GlobalState";
import LoginStateEnum from "../../../lib/state/LoginStateEnum";
import UserInfo from "../../../lib/state/UserInfo";
import SternumAuthTitle from "../../SUI/SternumAuthTitle/SternumAuthTitle";
import SternumBanner from "../../SUI/SternumBanner/SternumBanner";
import { ReSendIcon } from "../../SUI/SternumIcon/SternumIcon";
import SternumImprovedButton from "../../SUI/SternumImprovedButton/SternumImprovedButton";
import SternumInputField from "../../SUI/SternumInputField/SternumInputField";
import enterCodeStyle from "./EnterCodeStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    token: string;
    buttonLoader: boolean;
    serverError: boolean;
    disableButton: boolean;
    displaySentMsg: boolean;
    jwtToken: string;
    errorMsg: string;
    email: string;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof enterCodeStyle> {
    nextStep: (nextStep: number) => void;
    verificationCodeState: string;
    setUserInfoAction?: (user: UserInfo) => void;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return { setUserInfoAction: (user) => dispatch(setUserInfoAction(user)) };
};

/**
 * Login page of the app.
 */
class EnterCode extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        const token = ServiceWire.getAuthenticationService().loadTokenFromUrl();

        // parse jwt token
        const tokenContent = JSON.parse(atob(token.split(".")[1]));

        // Initializing the state to default.
        this.state = {
            token: "",
            buttonLoader: false,
            serverError: false,
            disableButton: true,
            displaySentMsg: false,
            jwtToken: token,
            errorMsg: null,
            email: tokenContent?.sub?.email,
        };
    }

    async componentDidMount() {
        // Validate given url token is valid
        if (this.state.jwtToken) {
            await ServiceWire.getSternumService().jwtValidation(this.state.jwtToken);
        }
    }

    /**
     * Handle token changed.
     */
    private TokenChange = (event) => {
        const token = event.target.value;
        const tokenSize = token.length;
        this.setState({ token: token, disableButton: tokenSize > 0 ? false : true });
    };

    /**
     * Handle submit button.
     */
    private handleSubmitClick = async (event) => {
        this.setState({ buttonLoader: true, disableButton: true });
        // Save validation token for next step
        ServiceWire.getAuthenticationService().setValidationToken(this.state.token);
        // Validate code on server side
        try {
            if (this.props.verificationCodeState === LoginStateEnum.MFA.toString()) {
                let response = await ServiceWire.getAuthenticationService().validateMFA(
                    this.state.token,
                    this.state.jwtToken
                );
                if (response) {
                    this.props.setUserInfoAction(ServiceWire.getAuthenticationService().getCachedAuthenticatedUser());
                    this.setState({ buttonLoader: false, disableButton: false });
                    // Activate next step
                    this.props.nextStep(LoginStateEnum.LOGIN);
                } else {
                    // Display error
                    this.setState({
                        buttonLoader: false,
                        serverError: true,
                        disableButton: false,
                        errorMsg: "The authentication code you have entered is invalid.",
                    });
                    // Failed to validate mfa
                }
            } else {
                let serverResponse = await ServiceWire.getSternumService().forgotPasswordActivateTokenStep(
                    this.state.token,
                    this.state.jwtToken
                );
                if (serverResponse) {
                    this.setState({ buttonLoader: false });
                    // Activate next step
                    this.props.nextStep(LoginStateEnum.RESET_PASSWORD);
                } else {
                    // Display error
                    this.setState({
                        buttonLoader: false,
                        serverError: true,
                        disableButton: false,
                        errorMsg: "The verification code you have entered is invalid.",
                    });
                }
            }
        } catch (err) {
            // Display error
            this.setState({
                buttonLoader: false,
                serverError: true,
                disableButton: false,
            });
        }
    };

    /**
     * Resend authentication token via email
     */
    private onResendClick = async () => {
        let response = true;
        // Display loader until redirect operation occur
        this.setState({ displaySentMsg: true });
        try {
            if (this.props.verificationCodeState === LoginStateEnum.MFA.toString()) {
                // If token is valid user will be redirected to new page, else, to error page
                await ServiceWire.getAuthenticationService().resendMfaToken(this.state.jwtToken);
            } else {
                response = await ServiceWire.getAuthenticationService().resendEmailVerificationToken(
                    this.state.jwtToken
                );
            }
            if (!response) {
                this.setState({
                    displaySentMsg: false,
                    errorMsg: "Failed to send verification email.",
                    serverError: true,
                });
            }
        } catch (err) {
            this.setState({ displaySentMsg: false, errorMsg: "Failed to send verification email." });
        }
    };

    /**
     * Catch enter click
     */
    private onKeyPress = (event) => {
        if (event.key === "Enter") {
            this.handleSubmitClick(event);
        }
    };

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <div className={classes.container}>
                    {/* Title */}
                    <div
                        className={classNames(classes.marginBottomXLarge, classes.flexVMiddle, classes.flexSpaceAround)}
                    >
                        <SternumAuthTitle
                            content={
                                this.props.verificationCodeState === LoginStateEnum.MFA.toString()
                                    ? "Two-factor authentication"
                                    : "Email Verification"
                            }
                            infoContent={
                                this.props.verificationCodeState === LoginStateEnum.MFA.toString()
                                    ? "We just sent you a message via email with your authentication code. Enter the code in the form below to verify your identity."
                                    : "Email verification code was sent to your email."
                            }
                        />
                    </div>
                    {this.state.serverError && (
                        <div className={classes.marginBottom}>
                            <SternumBanner
                                messageType={"error"}
                                message={this.state.errorMsg}
                                onCloseClick={() => {
                                    this.setState({ serverError: false });
                                }}
                            />
                        </div>
                    )}
                    {this.state.displaySentMsg && (
                        <div className={classes.marginBottom}>
                            <SternumBanner
                                messageType={"system"}
                                message={"Your authentication code has been sent"}
                                onCloseClick={() => {
                                    this.setState({ displaySentMsg: false });
                                }}
                            />
                        </div>
                    )}

                    <div className={classNames(classes.userAndPassInputs, classes.fullWidth, classes.marginBottomXs)}>
                        {/* Code input */}
                        <SternumInputField
                            label={
                                this.props.verificationCodeState === LoginStateEnum.MFA.toString()
                                    ? "Authentication Code"
                                    : "Verification Code"
                            }
                            inputValue={this.state.token}
                            onFieldChange={this.TokenChange}
                            onKeyPress={this.onKeyPress}
                        />
                    </div>

                    {/* For MFA display message with user email */}
                    {this.props.verificationCodeState === LoginStateEnum.MFA.toString() && (
                        <div className={classNames(classes.marginBottomXLarge)}>
                            <Typography variant="body1" className={classNames(classes.blueColor, classes.textSize)}>
                                The code has been sent to your email - {this.state.email}
                            </Typography>
                        </div>
                    )}

                    {/* For MFA display message with user email */}
                    {this.props.verificationCodeState === LoginStateEnum.MFA.toString() && (
                        <div
                            className={classNames(classes.marginBottomXLarge, classes.flexVMiddle, classes.flexCenter)}
                        >
                            <div
                                className={classNames(
                                    !this.state.displaySentMsg && classes.cursorPointer,
                                    classes.flexCenter
                                )}
                                onClick={() => {
                                    if (!this.state.displaySentMsg) {
                                        this.onResendClick();
                                    }
                                }}
                            >
                                <div className={classNames(classes.flexVMiddle, classes.marginRightXs)}>
                                    <ReSendIcon width={24} height={24} color={"#000000"} />
                                </div>

                                <Typography
                                    variant="body1"
                                    className={classNames(
                                        classes.textSize,
                                        !this.state.displaySentMsg && classes.underLine
                                    )}
                                >
                                    Resend the MFA code
                                </Typography>
                            </div>
                        </div>
                    )}

                    {/* Submit email */}
                    <div className={classes.flexCenter}>
                        {/* Submit button */}
                        <SternumImprovedButton
                            content={"Submit"}
                            onClick={this.handleSubmitClick}
                            isDisabled={this.state.disableButton}
                            isLoading={this.state.buttonLoader}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(enterCodeStyle)(EnterCode));
