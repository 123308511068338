import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import React, { useEffect, useRef } from "react";
import { sortBy } from "lodash";
import { useStyles } from "./InteractiveCoverageStyle";

export interface InteractiveCoveragePlotProps {
    data: Array<{ category: string; cwe: string; description: string }>;
    onCategoryClick: (category: string) => unknown;
}

const CATEGORY_PALETTE = ["#014362", "#8769C3", "#1B6FDE", "#15AC5A", "#6372D6", "#44B8D9"];
const CATEGORY_PALETTE_HOVER = ["#003C58", "#7451BA", "#0F64D3", "#129E52", "#4B5BCA", "#3BA9C9"];
const VERY_MAGICAL_MAX_WIDTH_CONSTANT = 595;

type RawCWEData = Array<{ category: string; cwe: string; description: string }>;
type CategoryPlotData = Array<{
    category: string;
    index: number;
    value: number;
    fill: string;
    hoverFill: string;
    maxWidth: number;
}>;

function transformData(data: RawCWEData): {
    category: CategoryPlotData;
} {
    const histogram: { [k: string]: number } = {};
    for (const { category } of data) {
        histogram[category] = histogram[category] === undefined ? 1 : histogram[category] + 1;
    }
    const categoriesData = sortBy(
        Object.entries(histogram).map(([category, value]) => ({ category, value })),
        ["category"]
    ).map(({ category, value }, index) => ({
        category,
        value,
        index,
        fill: CATEGORY_PALETTE[index % CATEGORY_PALETTE.length],
        hoverFill: CATEGORY_PALETTE_HOVER[index % CATEGORY_PALETTE_HOVER.length],
        maxWidth: (value / data.length) * VERY_MAGICAL_MAX_WIDTH_CONSTANT,
    }));
    return { category: categoriesData };
}

function onCategoryColorChange(target, eventType: "over" | "out") {
    target.fill = am4core.color(target.dataItem.dataContext[eventType === "over" ? "hoverFill" : "fill"]);
}

export function InteractiveCoveragePlot(props: InteractiveCoveragePlotProps) {
    const classes = useStyles();
    const divContainer = useRef<HTMLDivElement>();

    useEffect(() => {
        remountChart();
    }, []);

    const remountChart = () => {
        const visibleData = props.data;
        const { category } = transformData(visibleData);
        // Set theme

        // Create chart instance
        const chart = am4core.create(divContainer.current, am4charts.PieChart);

        chart.innerRadius = 60;
        chart.radius = 120;
        chart.width = am4core.percent(100);
        chart.responsive.enabled = true;

        // Add and configure Series
        const pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.legendSettings.itemValueText = "{value}";
        pieSeries.dataFields.value = "value";
        pieSeries.dataFields.category = "category";
        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.slices.template.strokeWidth = 2;
        pieSeries.slices.template.cornerRadius = 4;
        pieSeries.slices.template.strokeOpacity = 1;
        pieSeries.slices.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
        pieSeries.labels.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;

        pieSeries.alignLabels = false;
        pieSeries.labels.template.relativeRotation = 90;
        pieSeries.labels.template.truncate = true;
        pieSeries.labels.template.propertyFields.maxWidth = "maxWidth";
        pieSeries.slices.template.propertyFields.fill = "fill";
        pieSeries.slices.template.events.on("hit", (e) => {
            props.onCategoryClick(e.target.dataItem.properties["category"]);
        });
        pieSeries.labels.template.events.on("hit", (e) => {
            props.onCategoryClick(e.target.dataItem.properties["category"]);
        });
        pieSeries.labels.template.events.on("over", (e) => {
            pieSeries.tooltip.show();
        });
        pieSeries.labels.template.bent = true;
        pieSeries.labels.template.radius = -30;
        pieSeries.labels.template.padding(0, 0, 0, 0);
        pieSeries.labels.template.fill = am4core.color("#fff");
        pieSeries.labels.template.fontSize = "12px";
        pieSeries.labels.template.text = "{category}";

        pieSeries.innerRadius = am4core.percent(20);

        pieSeries.data = category;

        // Disabling ticks on inner circle
        pieSeries.ticks.template.disabled = true;

        // Disable sliding out of slices
        pieSeries.slices.template.states.getKey("hover").properties.scale = 1;
        pieSeries.slices.template.states.getKey("active").properties.shiftRadius = 0;
        pieSeries.slices.template.propertyFields.tooltipText = "category";
        pieSeries.tooltip.getFillFromObject = false;
        pieSeries.tooltip.fontSize = "12px";
        pieSeries.tooltip.background.cornerRadius = 4;
        pieSeries.tooltip.background.stroke = null;
        pieSeries.tooltip.background.filters.clear();
        pieSeries.tooltip.background.fill = am4core.color("#1B1B1B");
        pieSeries.tooltip.disabled = false;
        pieSeries.slices.template.propertyFields.tooltipText = "category";
        pieSeries.tooltip.getFillFromObject = false;
        pieSeries.tooltip.background.fill = am4core.color("#000");
        pieSeries.tooltip.filters.clear();

        pieSeries.slices.template.events.on("over", ({ target }) => {
            onCategoryColorChange(target, "over");
        });
        pieSeries.labels.template.events.on("over", ({ target }) => {
            const slice = pieSeries.slices.getIndex(target.dataItem.dataContext["index"]);
            onCategoryColorChange(slice, "over");
        });

        pieSeries.slices.template.events.on("out", ({ target }) => {
            onCategoryColorChange(target, "out");
        });
        pieSeries.labels.template.events.on("out", ({ target }) => {
            const slice = pieSeries.slices.getIndex(target.dataItem.dataContext["index"]);
            onCategoryColorChange(slice, "out");
        });
    };

    return <div ref={divContainer} className={classes.chart} />;
}
