import { Action } from "redux";
import ActionType from "../ActionType";
import CveInfo from "../../state/CveInfo";

/**
 * Occurs once a cve has been successfully fetched from API.
 */
export interface FetchedCveSuccessfullyAction extends Action<ActionType> {
    type: ActionType.FetchedCveSuccessfully;
    cve: CveInfo;
}

/**
 * Occurs once a cve has been successfully fetched from API.
 */
export function fetchedCveSuccessfullyAction(cve: CveInfo): FetchedCveSuccessfullyAction {
    return {
        type: ActionType.FetchedCveSuccessfully,
        cve: cve,
    };
}
