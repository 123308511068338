import { useCallback, useEffect, useRef, useState } from "react";

import ServiceWire from "../../../../lib/services/ServiceWire";
import { DeviceAlertsStatistics } from "../../../../lib/state/DeviceAlertsStatistics";

export interface UseDeviceAlertsStatisticsOptions {
    deviceId: string;
    createdFrom: number;
    createdTo: number;
}

export const useDeviceAlertsStatistics = ({ deviceId, createdFrom, createdTo }: UseDeviceAlertsStatisticsOptions) => {
    const [alertsStatistics, setAlertsStatistics] = useState<DeviceAlertsStatistics | null>(null);
    const isMountedRef = useIsMounted();
    const [isLoading, setLoading] = useState(false);

    const fetch = useCallback(async () => {
        setLoading(true);
        const clientId = ServiceWire.getClientsService().getSelectedClientId();

        const response = await ServiceWire.getSternumService().getDeviceAlertsStatistics(
            clientId,
            deviceId,
            createdFrom,
            createdTo
        );

        if (isMountedRef.current) {
            setAlertsStatistics(response);
            setLoading(false);
        }
    }, [deviceId, createdFrom, createdTo]);

    useEffect(() => {
        fetch().catch(() => {
            if (isMountedRef.current) {
                setLoading(false);
            }
        });
    }, [fetch]);

    return {
        alertsStatistics,
        isLoading,
        refetch: fetch,
    } as const;
};

const useIsMounted = () => {
    const isMountedRef = useRef(true);

    useEffect(
        () => () => {
            isMountedRef.current = false;
        },
        []
    );

    return isMountedRef;
};
