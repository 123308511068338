import Utils from "../infra/Utils";
import ArgumentDefinitionPartial from "./ArgumentDefinitionPartial";
import DeviceDefinitionLibraryPartial from "./DeviceDefinitionLibraryPartial";
import SternumDisplayObjectInfo from "./SternumDisplayObjectInfo";
import SternumTriggerInfo from "./SternumTriggerInfo";
import TraceDefinitionPartial from "./TraceDefinitionPartial";

/**
 * Represents a device definition in sternum.
 */
class DeviceDefinitionPartial {
    /**
     * Constructor.
     */
    private isSystemTracesSet: boolean = false;
    private isSystemArgumentsSet: boolean = false;

    constructor(
        public displayName: string,
        public cpuBitness: number,
        public deviceFirmwareVersion: string,
        public deviceOSFamily: string,
        public traceDefinitions?: TraceDefinitionPartial[],
        public deviceDefinitionLibraries?: DeviceDefinitionLibraryPartial[],
        public argumentDefinitions?: ArgumentDefinitionPartial[],
        public sternumTriggers?: SternumTriggerInfo[],
        public description?: string
    ) {
        if (!traceDefinitions) {
            this.traceDefinitions = [];
        }
        if (!deviceDefinitionLibraries) {
            this.deviceDefinitionLibraries = [];
        }
        if (!argumentDefinitions) {
            this.argumentDefinitions = [];
        }
        if (!sternumTriggers) {
            this.sternumTriggers = [];
        }

        // Add new 3rd party component on creation of device definition
        //this.createLibrary();
    }

    /**
     * Create json object for post operation
     */

    public toJsonObject(): object {
        return {
            device_display_name: this.displayName,
            cpu_bitness: this.cpuBitness,
            device_firmware_version: this.deviceFirmwareVersion,
            device_os_family: this.deviceOSFamily,
            trace_definitions: this.buildTracesJsonList(),
            argument_definitions: this.buildArgumentsJsonList(),
            libraries: this.buildLibrariesJsonList(),
            sternum_triggers: this.buildTriggersJsonList(),
            description: this.description,
        };
    }

    /*
     * Add trace to list
     */
    public addTrace(trace: TraceDefinitionPartial) {
        this.traceDefinitions.push(trace);
    }

    /*
     * Remove trace from list
     */

    public removeTrace(trace: TraceDefinitionPartial) {
        const traceIndex = this.findTraceIndex(trace);
        if (traceIndex > -1) {
            return this.traceDefinitions.splice(traceIndex, 1);
        }
    }

    /*
     * Add library to list
     */
    public addLibrary(library: DeviceDefinitionLibraryPartial) {
        this.deviceDefinitionLibraries.push(library);
    }

    /*
     * Add trigger to list
     */
    public addTrigger(trigger: SternumTriggerInfo) {
        this.sternumTriggers.push(trigger);
    }

    /*
     * Remove library from list
     */

    public removeDeviceDefinitionLibrary(libraryToRemove: DeviceDefinitionLibraryPartial) {
        const libraryIndex = this.findDeviceDefinitionLibraryIndex(libraryToRemove);
        if (libraryIndex > -1) {
            this.deviceDefinitionLibraries.splice(libraryIndex, 1);
        }
    }

    /*
     * Add argument to list
     */
    public addArgument(argument: ArgumentDefinitionPartial) {
        this.argumentDefinitions.push(argument);
    }

    /*
     * Remove argument from list
     */
    public removeArgument(argumentToRemove: ArgumentDefinitionPartial) {
        const libraryIndex = this.findArgumentIndex(argumentToRemove);
        if (libraryIndex > -1) {
            this.argumentDefinitions.splice(libraryIndex, 1);
        }
    }

    /**
     *
     * Remove trigger definition from definition list
     */
    public removeTrigger(trigger: SternumTriggerInfo) {
        const triggerIndex = this.findTriggerIndex(trigger);
        if (triggerIndex > -1) {
            this.sternumTriggers.splice(triggerIndex, 1);
        }
    }

    /*
     * Find trace index in traces list
     */
    public findTraceIndex(traceToFind: TraceDefinitionPartial): number {
        if (traceToFind) {
            const argumentIndex = Utils.findFirstIndex(
                this.traceDefinitions,
                (trace: TraceDefinitionPartial) =>
                    trace.displayName.toLowerCase() === traceToFind.displayName.toLowerCase()
            );
            return argumentIndex === null ? -1 : argumentIndex;
        }
        return -1;
    }

    /**
     *
     * Help function to find given trigger
     */
    public findTriggerIndex(triggerToFind: SternumTriggerInfo): number {
        if (SternumTriggerInfo) {
            const triggerIndex = Utils.findFirstIndex(
                this.sternumTriggers,
                (trigger: SternumTriggerInfo) =>
                    trigger.displayName === triggerToFind.displayName &&
                    trigger.targetEntityType === triggerToFind.targetEntityType &&
                    trigger.triggerType === triggerToFind.triggerType
            );
            return triggerIndex === null ? -1 : triggerIndex;
        }
        return -1;
    }

    /**
     * Find library index in libraries list
     */
    public findDeviceDefinitionLibraryIndex(libraryToFind: any): number {
        if (libraryToFind) {
            const argumentIndex = Utils.findFirstIndex(
                this.deviceDefinitionLibraries,
                (library: any) =>
                    library.getLibraryName() === libraryToFind.getLibraryName() &&
                    library.getLibraryVersion() === libraryToFind.getLibraryVersion()
            );
            return argumentIndex === null ? -1 : argumentIndex;
        }
        return -1;
    }

    /*
     * Find argument index in an arguments list
     */
    public findArgumentIndex(argumentToFind: ArgumentDefinitionPartial): number {
        if (argumentToFind) {
            const argumentIndex = Utils.findFirstIndex(
                this.argumentDefinitions,
                (argument: ArgumentDefinitionPartial) =>
                    argument.displayName.toLowerCase() === argumentToFind.displayName.toLowerCase()
            );
            return argumentIndex === null ? -1 : argumentIndex;
        }
        return -1;
    }

    /**
     * Create system traces
     */
    public createSystemTraces(systemTraceList: SternumDisplayObjectInfo[]) {
        if (!this.isSystemTracesSet) {
            this.traceDefinitions = TraceDefinitionPartial.createSystemTraces(systemTraceList);
            this.isSystemTracesSet = true;
        }
    }

    /**
     * Create system arguments
     */
    public createSystemArguments(systemArgumentsList: SternumDisplayObjectInfo[]) {
        if (!this.isSystemArgumentsSet) {
            this.argumentDefinitions = ArgumentDefinitionPartial.createSystemArguments(systemArgumentsList);
            this.isSystemArgumentsSet = true;
        }
    }

    /**
     * Create list of new traces to create in server
     */
    private buildTracesJsonList() {
        let customTraces = [];
        for (let trace of this.traceDefinitions) {
            if (!trace.isSystemTrace()) {
                customTraces.push(trace.toJsonObject());
            }
        }
        return customTraces;
    }

    /**
     * Create list of new arguments to create in server
     */
    private buildArgumentsJsonList() {
        let customArguments = [];
        for (let argument of this.argumentDefinitions) {
            if (!argument.isSystemArgument()) {
                customArguments.push(argument.toJsonObject());
            }
        }
        return customArguments;
    }

    /**
     * Create list of new libraries to create in server
     */
    private buildLibrariesJsonList() {
        return this.deviceDefinitionLibraries.map((library) => library.toJsonObject());
    }

    /**
     * Create list of new triggers to create in server
     */
    private buildTriggersJsonList() {
        return this.sternumTriggers.map((trigger) => trigger.toJsonObject());
    }

    /**
     *  Return device definition deploy status
     */
    public isProduction(): boolean {
        // Partial device definition can't be in production mode, return false
        return false;
    }

    /**
     *
     * Clone function to create new device definition instance.
     */
    public static cloneObject(toClone: DeviceDefinitionPartial): DeviceDefinitionPartial {
        return new DeviceDefinitionPartial(
            toClone.displayName,
            toClone.cpuBitness,
            toClone.deviceFirmwareVersion,
            toClone.deviceOSFamily,
            toClone.traceDefinitions,
            toClone.deviceDefinitionLibraries,
            toClone.argumentDefinitions,
            toClone.sternumTriggers
        );
    }

    public isValid(): boolean {
        return (
            this.displayName !== "" &&
            this.cpuBitness !== null &&
            this.deviceFirmwareVersion !== "" &&
            this.deviceOSFamily !== ""
        );
    }
}

export default DeviceDefinitionPartial;
