import ArgumentRoleTypeUIConfiguration from "./ArgumentRoleTypeUIConfiguration";

/**
 * Represents a configuration of a trace event type.
 */
class TraceEventTypeConfiguration {
    /**
     * Constructor.
     */
    constructor(
        public key: string,
        public displayName: string,
        public isAttackTrace: boolean,
        public familyName: string,
        public shouldRegardOrder: boolean,
        public argumentRoleTypesConfigurations: {
            [roleType: string]: ArgumentRoleTypeUIConfiguration;
        }
    ) {}
}

export default TraceEventTypeConfiguration;
