import { useEffect, useState } from "react";

import ServiceWire from "../../../../../lib/services/ServiceWire";
import SternumConfiguration from "../../../../../lib/infra/SternumConfiguration";
import SternumDeviceEventsFilter from "../../../../../lib/state/SternumDeviceEventsFilter";
import SternumDeviceEventInfo from "../../../../../lib/state/SternumDeviceEventInfo";

export interface KernelLogsDataArgs {
    deviceId: string;
    entitiesFilter: SternumDeviceEventsFilter;
}

export const useKernelLogsData = ({ deviceId, entitiesFilter }: KernelLogsDataArgs) => {
    const [isLoading, setIsLoading] = useState(true);
    const [deviceEventInfo, setDeviceEventInfo] = useState<SternumDeviceEventInfo>();

    useEffect(() => {
        setIsLoading(true);

        new Promise(async () => {
            const deviceSternumDeviceKernelLogBoots =
                await ServiceWire.getSternumService().getDeviceSternumDeviceKernelLogBoots(
                    deviceId,
                    entitiesFilter,
                    undefined, // firstEntityCreatedAt,
                    undefined, // searchText,
                    0, // (pageNumber - 1) * SternumConfiguration.getPageSize(),
                    SternumConfiguration.getPageSize()
                );

            setDeviceEventInfo(deviceSternumDeviceKernelLogBoots.sternumDeviceEvents[0]);
            setIsLoading(false);
        }).then();
    }, [deviceId]);

    return { isLoading, deviceEventInfo, entitiesFilter };
};
