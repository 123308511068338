import { Theme } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

let deviceDefinitionLibrariesListFilterPopoverStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        titleTypography: {
            display: "flex",
            alignItems: "center",
            marginBottom: theme.spacing(2),
        },
        resetAndApplyButtons: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(4),
        },
        applyButton: {
            marginRight: theme.spacing(2),
        },
        formControl: {
            minWidth: theme.spacing(40),
            maxWidth: theme.spacing(80),
        },
        ipAddressFilter: {
            marginRight: theme.spacing(4),
        },
        fromTimePicker: {
            marginRight: theme.spacing(4),
        },
        filterRow: {
            marginTop: theme.spacing(4),
        },
    });

export default deviceDefinitionLibrariesListFilterPopoverStyle;
