import { WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import * as React from "react";
import { Redirect, withRouter } from "react-router-dom";
import Utils from "../../lib/infra/Utils";
import ServiceWire from "../../lib/services/ServiceWire";
import ClientInfo from "../../lib/state/ClientInfo";
import UserInfo from "../../lib/state/UserInfo";
import boardsPageStyle from "./BoardsPageStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    redirectToHomePage: boolean;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof boardsPageStyle> {
    history;
    location;
}

/**
 * Displays the dashboard of sternum.
 */
class BoardsPage extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            redirectToHomePage: false,
        };
    }

    getUserFullName = () => {
        const user: UserInfo = ServiceWire.getAuthenticationService().getCachedAuthenticatedUser();
        return user.getFullName();
    };
    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        if (this.state.redirectToHomePage === true) {
            return <Redirect to="/dashboard" />;
        }
        return (
            <div className={classes.root}>
                <div className={classes.upperContent}>
                    <Typography variant={"h3"} className={classes.marginBottomMedium}>
                        Boards
                    </Typography>

                    <Typography variant="body2">
                        Listed below are all the Boards you have access to. Select a Board to view your dashboard.
                    </Typography>
                </div>

                <div className={classes.boardsContainer}>
                    {ServiceWire.getClientsService()
                        .getClients()
                        .map((client, index) => {
                            return (
                                <div
                                    key={index}
                                    role="listitem"
                                    aria-label="Board item"
                                    className={classNames(
                                        classes.boardHolder,
                                        classes.marginRightLarge,
                                        classes.marginBottomLarge
                                    )}
                                    onClick={() => this.selectClient(client)}
                                >
                                    <div className={classNames(classes.flexVMiddle, classes.marginBottomXLarge)}>
                                        <Typography variant={"h5"} className={"boardNameTypography"}>
                                            {client.name}
                                        </Typography>
                                    </div>

                                    <Typography variant={"caption"}>
                                        {Utils.capitalize(this.getUserFullName())}
                                    </Typography>
                                </div>
                            );
                        })}
                </div>
            </div>
        );
    }

    /**
     * Selects given client.
     */
    private async selectClient(client: ClientInfo) {
        // Selecting the client to be saved in storage.
        await ServiceWire.getClientsService().selectClient(client);
        this.setState({ redirectToHomePage: true });
    }
}

export default withRouter(withStyles(boardsPageStyle)(BoardsPage));
