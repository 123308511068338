import React from "react";

import { SternumIconProps } from "./SternumIcon.types";

export const CalendarIcon = React.forwardRef<SVGSVGElement, SternumIconProps>(function CalendarIcon(
    { color, width = 24, height = 25, ...props },
    ref
) {
    return (
        <svg
            ref={ref}
            width={width}
            height={height}
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.34518 3C8.77411 3 9.12183 3.33321 9.12183 3.74424V6.37097C9.12183 6.782 8.77411 7.11521 8.34518 7.11521C7.91625 7.11521 7.56853 6.782 7.56853 6.37097V3.74424C7.56853 3.33321 7.91625 3 8.34518 3Z"
                fill={color || "currentColor"}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.6548 3C16.0838 3 16.4315 3.33321 16.4315 3.74424V6.37097C16.4315 6.782 16.0838 7.11521 15.6548 7.11521C15.2259 7.11521 14.8782 6.782 14.8782 6.37097V3.74424C14.8782 3.33321 15.2259 3 15.6548 3Z"
                fill={color || "currentColor"}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.45685 9.95207C3.45685 9.54104 3.80457 9.20783 4.2335 9.20783H19.7665C20.1954 9.20783 20.5431 9.54104 20.5431 9.95207C20.5431 10.3631 20.1954 10.6963 19.7665 10.6963H4.2335C3.80457 10.6963 3.45685 10.3631 3.45685 9.95207Z"
                fill={color || "currentColor"}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.4308 6.76794C4.86629 7.3505 4.5533 8.23621 4.5533 9.43548V16.8779C4.5533 18.0772 4.86629 18.9629 5.4308 19.5454C5.98563 20.118 6.89422 20.5115 8.34518 20.5115H15.6548C17.1058 20.5115 18.0144 20.118 18.5692 19.5454C19.1337 18.9629 19.4467 18.0772 19.4467 16.8779V9.43548C19.4467 8.23621 19.1337 7.3505 18.5692 6.76794C18.0144 6.19536 17.1058 5.80184 15.6548 5.80184H8.34518C6.89422 5.80184 5.98563 6.19536 5.4308 6.76794ZM4.29255 5.7551C5.22249 4.79542 6.59816 4.31336 8.34518 4.31336H15.6548C17.4018 4.31336 18.7775 4.79542 19.7074 5.7551C20.6277 6.70479 21 8.00803 21 9.43548V16.8779C21 18.3053 20.6277 19.6086 19.7074 20.5583C18.7775 21.5179 17.4018 22 15.6548 22H8.34518C6.59816 22 5.22249 21.5179 4.29255 20.5583C3.37229 19.6086 3 18.3053 3 16.8779V9.43548C3 8.00803 3.37229 6.70479 4.29255 5.7551Z"
                fill={color || "currentColor"}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.4622 13.9885C14.4622 13.5049 14.8712 13.1129 15.3759 13.1129H15.3841C15.8887 13.1129 16.2978 13.5049 16.2978 13.9885C16.2978 14.4721 15.8887 14.8641 15.3841 14.8641H15.3759C14.8712 14.8641 14.4622 14.4721 14.4622 13.9885Z"
                fill={color || "currentColor"}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.4622 16.6152C14.4622 16.1317 14.8712 15.7396 15.3759 15.7396H15.3841C15.8887 15.7396 16.2978 16.1317 16.2978 16.6152C16.2978 17.0988 15.8887 17.4908 15.3841 17.4908H15.3759C14.8712 17.4908 14.4622 17.0988 14.4622 16.6152Z"
                fill={color || "currentColor"}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.0822 13.9885C11.0822 13.5049 11.4912 13.1129 11.9959 13.1129H12.0041C12.5087 13.1129 12.9178 13.5049 12.9178 13.9885C12.9178 14.4721 12.5087 14.8641 12.0041 14.8641H11.9959C11.4912 14.8641 11.0822 14.4721 11.0822 13.9885Z"
                fill={color || "currentColor"}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.0822 16.6152C11.0822 16.1317 11.4912 15.7396 11.9959 15.7396H12.0041C12.5087 15.7396 12.9178 16.1317 12.9178 16.6152C12.9178 17.0988 12.5087 17.4908 12.0041 17.4908H11.9959C11.4912 17.4908 11.0822 17.0988 11.0822 16.6152Z"
                fill={color || "currentColor"}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.70039 13.9885C7.70039 13.5049 8.10947 13.1129 8.61409 13.1129H8.6223C9.12692 13.1129 9.536 13.5049 9.536 13.9885C9.536 14.4721 9.12692 14.8641 8.6223 14.8641H8.61409C8.10947 14.8641 7.70039 14.4721 7.70039 13.9885Z"
                fill={color || "currentColor"}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.70039 16.6152C7.70039 16.1317 8.10947 15.7396 8.61409 15.7396H8.6223C9.12692 15.7396 9.536 16.1317 9.536 16.6152C9.536 17.0988 9.12692 17.4908 8.6223 17.4908H8.61409C8.10947 17.4908 7.70039 17.0988 7.70039 16.6152Z"
                fill={color || "currentColor"}
            />
        </svg>
    );
});
