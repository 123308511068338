import { Typography } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TimeAgo from "react-timeago";
import HashSet from "../../lib/infra/HashSet";
import SternumConfiguration from "../../lib/infra/SternumConfiguration";
import { openCveViewModalAction } from "../../lib/redux/modals/OpenModalAction";
import DebounceService from "../../lib/services/DebounceService";
import ServiceWire from "../../lib/services/ServiceWire";
import CveInfo from "../../lib/state/CveInfo";
import CvesFilter from "../../lib/state/CvesFilter";
import GetCvesResponse from "../../lib/state/GetCvesResponse";
import { GlobalState } from "../../lib/state/GlobalState";
import ListFilter from "../../lib/state/ListFilter";
import TableColumnHeaderInfo from "../../lib/state/TableColumnHeaderInfo";
import TableToolbarDisplayState from "../../lib/state/TableToolbarDisplayState";
import CvesListFilterPopover from "../CvesListFilterPopover/CvesListFilterPopover";
import ExplanationComponent from "../ExplanationComponent/ExplanationComponent";
import SternumLink from "../SUI/SternumLink/SternumLink";
import SternumTable from "../SUI/SternumTable/SternumTable";
import cvesListStyle from "./CvesListStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    // Loading & error state.
    loadingCves: boolean;
    errorLoadingCves: boolean;

    // Cves.
    cves: CveInfo[];
    totalItemCount: number;

    // Filtering.
    cvesFilter: CvesFilter;
    searchText: string;
    orderByField: string;
    order: "asc" | "desc";
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof cvesListStyle> {
    cvesFilter?: CvesFilter;
    includeColumns: string[];

    hideToolbar?: boolean;
    hidePagination?: boolean;
    hideUpperBorder?: boolean;

    displayXButtonInCveView?: boolean;
    displayBackButtonInCveView?: boolean;

    location;
    history;

    viewedColumnsSet?: HashSet;

    paperClassNames?;

    openCveViewModal?: (key: string, cveId: string, displayXButton: boolean, displayBackButton: boolean) => void;

    showAllColumns?: boolean;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {
        openCveViewModal: (key, cveId, displayXButton, displayBackButton) =>
            dispatch(openCveViewModalAction(key, cveId, displayXButton, displayBackButton)),
    };
};

/**
 * Holds the cves list in the app.
 */
class CvesList extends React.Component<AppProps, AppState> {
    /**
     * Defines the column headers participating in the cves table.
     */
    private columnHeaders: TableColumnHeaderInfo[] = [
        new TableColumnHeaderInfo("cveId", "Id", false, true, true),
        new TableColumnHeaderInfo("cveOfficialId", "Official CVE ID", false, true, false),
        new TableColumnHeaderInfo("libraryNameAndVersion", "Library", false, true, false),
        new TableColumnHeaderInfo("libraryName", "Library Name", false, true, true),
        new TableColumnHeaderInfo("libraryVersion", "Library Version", false, true, true),
        new TableColumnHeaderInfo("updateTime", "Update Time", false, true, false),
        new TableColumnHeaderInfo("updateSource", "Update Source", false, true, false),
        new TableColumnHeaderInfo("description", "Description", false, true, true),
    ];

    /**
     * Gets the display values for row's columns (where they aren't the default).
     */
    private getRowDisplayValues(row) {
        let cveInfo = row as CveInfo;

        return {
            cveId: cveInfo.cveId,
            cveOfficialId: (
                <div className={classNames(this.props.classes.flexRow)}>
                    <SternumLink
                        className={this.props.classes.paddingLeft}
                        onClick={() => this.handleRowClicked(cveInfo)}
                    >
                        {cveInfo.cveOfficialId}
                    </SternumLink>
                    {cveInfo.isAnyVersion() && (
                        <ExplanationComponent openOnHover={true}>
                            <Typography>
                                Detected potential CVE affecting this library. It may not be vulnerable by the version
                            </Typography>
                        </ExplanationComponent>
                    )}
                </div>
            ),
            description: cveInfo.description,
            libraryNameAndVersion: (
                <div>
                    {cveInfo.usedLibrary.library.name} ({cveInfo.usedLibrary.version})
                </div>
            ),
            libraryName: cveInfo.usedLibrary.library.name,
            libraryVersion: cveInfo.usedLibrary.version,
            updateTime: <TimeAgo date={cveInfo.updated} />,
            updateSource:
                SternumConfiguration.getUpdateSourceTypeApiNameToConfigurationObject()[cveInfo.updateSource]
                    .displayName,
        };
    }

    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        if (this.props.includeColumns) {
            if (this.props.includeColumns.length) {
                let includeColumnsSet = HashSet.fromValues(this.props.includeColumns);
                this.columnHeaders = this.columnHeaders.filter((columnHeader) =>
                    includeColumnsSet.exists(columnHeader.id)
                );
            } else {
                this.columnHeaders = [];
            }
        }

        if (this.props.showAllColumns) {
            for (let i = 0; i < this.columnHeaders.length; i++) {
                this.columnHeaders[i].isHidden = false;
            }
        }

        // Initializing the state to default.
        this.state = {
            loadingCves: false,
            errorLoadingCves: false,

            cves: [],

            cvesFilter: this.props.cvesFilter
                ? this.props.cvesFilter
                : new CvesFilter(null, null, null, null, null, null),
            searchText: null,
            orderByField: null,
            order: "asc",

            totalItemCount: null,
        };
    }

    /**
     * Occurs once the component finished its initialization process.
     */
    async componentDidMount() {
        await this.loadCves(1);
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        return (
            <SternumTable
                nonClickableRows
                hideUpperBorder={this.props.hideUpperBorder}
                hideToolbar={this.props.hideToolbar}
                viewedColumnsSet={this.props.viewedColumnsSet}
                hidePagination={this.props.hidePagination}
                pageSize={SternumConfiguration.getPageSize()}
                amountOfLoadingPlaceholders={3}
                onRefreshClicked={() => this.onRefreshClicked()}
                totalItemCount={this.state.totalItemCount}
                loadingItems={this.state.loadingCves}
                errorLoadingItems={this.state.errorLoadingCves}
                columnHeaders={this.columnHeaders}
                listFilter={this.state.cvesFilter}
                isFilterActive={this.state.cvesFilter && this.state.cvesFilter.isActive()}
                filterComponentProvider={(onFilterChanged) => (
                    <CvesListFilterPopover cvesFilter={this.state.cvesFilter} onFilterChanged={onFilterChanged} />
                )}
                orderByField={this.state.orderByField}
                order={this.state.order}
                rows={this.state.cves}
                onFilterChanged={(listFilter) => this.handleCvesFilterChanged(listFilter)}
                onSearchTextChanged={(searchText) => this.handleSearchTextChanged(searchText)}
                onPageChanged={(pageNumber) => this.loadCves(pageNumber)}
                onOrderChanged={(orderByField, order) => this.handleOrderChanged(orderByField, order)}
                getRowValues={(row) => this.getRowDisplayValues(row)}
                toolbarState={
                    new TableToolbarDisplayState(true, false, true, false, true, false, false, false, "Search CVE ID")
                }
            />
        );
    }

    /**
     * Occurs on a click on a row.
     */
    private handleRowClicked(cveInfo: CveInfo) {
        // Opens the cve view modal.
        this.props.openCveViewModal(
            cveInfo.cveId,
            cveInfo.cveId,
            this.props.displayXButtonInCveView,
            this.props.displayBackButtonInCveView
        );
    }

    /**
     * Occurs once the user refreshes the list.
     */
    private async onRefreshClicked() {
        await this.loadCves(1);
    }

    /**
     * Occurs once the filters of the list are changed.
     */
    private handleCvesFilterChanged(cvesFilter: ListFilter) {
        this.setState(
            {
                cvesFilter: cvesFilter != null ? (cvesFilter as CvesFilter) : null,
            },
            async () => {
                if (cvesFilter && cvesFilter.isActive()) {
                    await this.loadCves(1);
                }
            }
        );
    }

    /**
     * Occurs once search text is changed.
     */
    private handleSearchTextChanged(searchText) {
        // Debounce request to search cves.
        DebounceService.debounce(
            "getCves",
            () => {
                this.setState(
                    {
                        searchText: searchText,
                    },
                    () => this.loadCves(1)
                );
            },
            350
        );
    }

    /**
     * Occurs once sort order is changed.
     */
    private handleOrderChanged(orderByField, order) {
        this.setState(
            {
                orderByField: orderByField,
                order: order,
            },
            () => this.loadCves(1)
        );
    }

    /**
     * Loads cves.
     */
    private async loadCves(pageNumber) {
        try {
            // Setting loading to true.
            this.setState({
                loadingCves: true,
                errorLoadingCves: false,
            });

            const offset = (pageNumber - 1) * SternumConfiguration.getPageSize();

            // Fetching cve.
            let cvesResponse: GetCvesResponse = await ServiceWire.getSternumService().getCves(
                ServiceWire.getClientsService().getSelectedClientId(),
                this.state.cvesFilter,
                this.state.searchText,
                this.state.orderByField,
                this.state.order,
                offset
            );

            this.setState({
                loadingCves: false,
                errorLoadingCves: false,
                cves: cvesResponse.cves,
                totalItemCount: cvesResponse.totalItemCount,
            });
        } catch (error) {
            this.setState({
                loadingCves: false,
                errorLoadingCves: true,
            });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(cvesListStyle)(CvesList)));
