import * as React from "react";

import classNames from "classnames";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import createTriggerModalStyle from "./CreateTriggerModalStyle";
import { GlobalState } from "../../lib/state/GlobalState";
import { connect } from "react-redux";
import { Typography } from "@material-ui/core";
import EntityType from "../../lib/state/EntityType";
import SternumQuery from "../../lib/state/SternumQuery";
import Select from "react-select";
import Divider from "@material-ui/core/Divider";
import Icon from "@material-ui/core/Icon";
import customSelectStyle from "../CustomSelectStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof createTriggerModalStyle> {
    fullScreenDisplay: boolean;
    theme?;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {};
};

/**
 * Displays a bar of metrics.
 */
class CreateTriggerModal extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {};
    }

    /**
     * Occurs once the component is mounted.
     * We fetch the library if needed here.
     */
    async componentDidMount() {}

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        let entityOptions = [
            {
                value: EntityType.Device,
                label: "Device",
            },
            {
                value: EntityType.Trace,
                label: "Trace",
            },
        ];

        return (
            <div className={classes.root}>
                {/* Title */}
                <Typography variant={"h5"} className={classNames(classes.marginBottomXLarge)}>
                    Create Trigger
                </Typography>

                <div className={classNames(classes.flexVMiddle, classes.marginBottomXs)}>
                    <Typography className={classNames(classes.marginRight, classes.whenSentence)}>When</Typography>

                    <Select
                        className={classNames(classes.selectComponent, classes.marginRight)}
                        options={entityOptions}
                        name={"entitySelection"}
                        value={entityOptions[0]}
                        styles={customSelectStyle(this.props.theme)}
                        isSearchable={true}
                        placeholder={"Select entity..."}
                        onChange={(newValue, action) => this.onEntitySelected(newValue, action)}
                    />

                    <Typography className={classes.whenSentence}>satisfies these conditions</Typography>
                </div>

                <Divider className={classNames(classes.marginBottomLarge)} />

                {/*<SternumQueryEditor*/}
                {/*    entityType={EntityType.Device}*/}
                {/*    sternumQuery={SternumQuery.getEmptyQuery()}*/}
                {/*    onSternumQueryChanged={sternumQuery => this.onSternumQueryChanged(sternumQuery)}*/}
                {/*/>*/}

                <Typography
                    className={classNames(classes.actionsSentence, classes.marginTopXXl, classes.marginBottomXs)}
                >
                    Execute actions
                </Typography>

                <Divider className={classNames(classes.marginBottomLarge)} />

                <div className={classNames(classes.flexVMiddle, classes.marginBottom)}>
                    <Typography className={classNames(classes.actionDisplayName)}>Add to U.S. East Board</Typography>

                    {/* Edit */}
                    <Icon className={classNames("fas fa-pencil-alt", classes.deleteActionIcon, classes.marginRight)} />

                    {/* Delete */}
                    <Icon className={classNames("fas fa-trash-alt", classes.deleteActionIcon)} />
                </div>

                <div className={classNames(classes.flexVMiddle, classes.marginBottom)}>
                    <Typography className={classNames(classes.actionDisplayName)}>Email Lian and Natali</Typography>

                    {/* Edit */}
                    <Icon className={classNames("fas fa-pencil-alt", classes.deleteActionIcon, classes.marginRight)} />

                    {/* Delete */}
                    <Icon className={classNames("fas fa-trash-alt", classes.deleteActionIcon)} />
                </div>
            </div>
        );
    }

    private onSternumQueryChanged(sternumQuery: SternumQuery) {}

    /**
     * Occurs once a field select component option is executed.
     */
    private onEntitySelected(selectedField, actionObject) {
        switch (actionObject.action) {
            // New value selected.
            case "select-option":
                break;

            // Value erased.
            case "clear":
                break;
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(createTriggerModalStyle, { withTheme: true })(CreateTriggerModal));
