import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import moment from "moment";
import React, { useEffect, useLayoutEffect, useRef } from "react";
import { renderToString } from "react-dom/server";
import Utils from "../../lib/infra/Utils";
import SternumFlag from "../SternumFlag/SternumFlag";

am4core.useTheme(am4themes_animated);

am4core.useTheme((target) => {
    if (target instanceof am4core.InterfaceColorSet) {
        target.setFor("primaryButtonActive", am4core.color("#0d47a1"));
    }
});

export interface GeoLocationData {
    city: string;
    continent: string;
    country: string;
    latitude: number;
    longitude: number;
    organization: string;
    state: string;
}

export interface GeoLocationContainer {
    created: number;
    ip_address: string;
    geo_location: GeoLocationData;
}

export interface DeviceDefinitionGeoData {
    traces_count: number;
    devices_count: number;
}
export interface DeviceDefinitionDeviceLocationMap {
    country: string;
    count: number;
}

export interface DeviceDefinitionGeoContainer {
    [key: string]: [value: DeviceDefinitionGeoData];
}

function onReady(chart: am4maps.MapChart, heatRule: { minColor: string; maxColor: string }) {
    let countryColor = am4core.color("#fff");
    let countryStrokeColor = am4core.color("#D9D9D9");

    let currentPolygon;

    let countryDataTimeout;

    // make a map of country indexes for later use
    let countryIndexMap = {};

    //////////////////////////////////////////////////////////////////////////////
    // LAYOUT & CHARTS
    //////////////////////////////////////////////////////////////////////////////

    // MAP CHART
    // https://www.amcharts.com/docs/v4/chart-types/map/
    let mapChart = chart;
    //mapChart.height = am4core.percent(100);
    //mapChart.width = am4core.percent(100);
    mapChart.homeGeoPoint = { longitude: 10, latitude: 30 };

    // clicking on a "sea" will also result a full zoom-out
    mapChart.seriesContainer.background.events.on("hit", showWorld);
    mapChart.seriesContainer.background.events.on("over", resetHover);
    mapChart.seriesContainer.background.fillOpacity = 0;

    // https://www.amcharts.com/docs/v4/chart-types/map/#Map_data
    // you can use more accurate world map or map of any other country - a wide selection of maps available at: https://github.com/amcharts/amcharts4-geodata
    mapChart.geodata = am4geodata_worldLow;

    // Set projection
    // https://www.amcharts.com/docs/v4/chart-types/map/#Setting_projection
    // instead of Miller, you can use Mercator or many other projections available: https://www.amcharts.com/demos/map-using-d3-projections/
    mapChart.projection = new am4maps.projections.Miller();
    mapChart.panBehavior = "move";

    // when map is globe, beackground is made visible
    mapChart.backgroundSeries.mapPolygons.template.polygon.fillOpacity = 1;
    mapChart.backgroundSeries.mapPolygons.template.polygon.fill = am4core.color("#DEE7EF");
    mapChart.backgroundSeries.hidden = false;

    // Map polygon series (defines how country areas look and behave)
    let polygonSeries = mapChart.series.push(new am4maps.MapPolygonSeries());
    polygonSeries.interpolationDuration = 0;

    polygonSeries.exclude = ["AQ"]; // Antarctica is excluded in non-globe projection
    polygonSeries.useGeodata = true;
    // this helps to place bubbles in the visual middle of the area
    polygonSeries.calculateVisualCenter = true;

    let polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.fill = countryColor;
    polygonTemplate.fillOpacity = 1;
    polygonTemplate.stroke = countryStrokeColor;
    polygonTemplate.strokeOpacity = 0.2;
    polygonTemplate.strokeWidth = 1;
    polygonTemplate.setStateOnChildren = true;
    polygonTemplate.tooltipPosition = "fixed";

    polygonTemplate.tooltip = new am4core.Tooltip();
    polygonTemplate.tooltip.background.filters.clear();
    polygonTemplate.tooltip.background.cornerRadius = 8;
    polygonTemplate.tooltip.getFillFromObject = false;
    polygonTemplate.tooltip.background.fill = am4core.color("#1B1B1B");
    polygonTemplate.tooltip.background.fillOpacity = 1;
    polygonTemplate.tooltip.background.stroke = null;
    polygonTemplate.applyOnClones = true;
    polygonTemplate.hoverOnFocus = true;
    polygonTemplate.nonScalingStroke = true;

    polygonTemplate.events.on("hit", handleCountryHit);
    polygonTemplate.events.on("over", handleCountryOver);
    polygonTemplate.events.on("out", handleCountryOut);

    polygonSeries.heatRules.push({
        target: polygonTemplate,
        property: "fill",
        min: am4core.color(heatRule.minColor),
        max: am4core.color(heatRule.maxColor),
        dataField: "value",
    });

    const polygonHoverState = polygonTemplate.states.create("hover");
    polygonHoverState.properties.stroke = am4core.color("#000");
    polygonHoverState.properties.strokeOpacity = 1;
    polygonHoverState.properties.strokeWidth = 2;

    const polygonActiveState = polygonTemplate.states.create("active");
    polygonActiveState.properties.stroke = am4core.color("#000");
    polygonActiveState.properties.strokeOpacity = 1;
    polygonActiveState.properties.strokeWidth = 2;
    polygonActiveState.properties.zIndex = Number.MAX_VALUE;

    polygonTemplate.adapter.add("tooltipHTML", function (text, target) {
        const data = target.tooltipDataItem.dataContext;
        if (data["tooltip"]) {
            return data["tooltip"];
        }
        const countryFlag = <SternumFlag countryCode={data["id"]} />;
        const toolTip = `
                        <div style="display: flex; align-items: center">
                        ${renderToString(countryFlag)}
                            <span style=" padding-left: 5px; font-size:14px; font-weight:600; font-family: Inter;"><strong>{name} </strong></span>
                        </div>
                        `;
        return toolTip;
    });

    polygonSeries.mapPolygons.template.tooltipHTML = "{tooltip}";

    // this is to prevent polygon from displaying broken outline, polygon should be placed in front of all other polygons by Z axis
    polygonSeries.mapPolygons.template.events.on("over", (event) => {
        event.target.zIndex = Number.MAX_VALUE;
        event.target.toFront();
    });

    // you can have pacific - centered map if you set this to -154.8
    mapChart.deltaLongitude = -10;

    // END OF MAP

    // select a country
    function selectCountry(mapPolygon) {
        resetHover();
        polygonSeries.hideTooltip();

        // if the same country is clicked show world
        if (currentPolygon == mapPolygon) {
            currentPolygon.isActive = false;
            currentPolygon = undefined;
            showWorld();
            return;
        }
        // save current polygon
        currentPolygon = mapPolygon;
        let countryIndex = countryIndexMap[mapPolygon.dataItem.id];

        // make others inactive
        polygonSeries.mapPolygons.each(function (polygon) {
            polygon.isActive = false;
        });

        // clear timeout if there is one
        if (countryDataTimeout) {
            clearTimeout(countryDataTimeout);
        }

        mapPolygon.isActive = true;
        mapChart.zoomToMapObject(mapPolygon, getZoomLevel(mapPolygon));
    }

    // change line chart data to the selected countries

    // what happens when a country is rolled-over
    function rollOverCountry(mapPolygon) {
        resetHover();
        if (mapPolygon) {
            mapPolygon.isHover = true;
        }
    }
    // what happens when a country is rolled-out
    function rollOutCountry(mapPolygon) {
        resetHover();
    }

    // calculate zoom level (default is too close)
    function getZoomLevel(mapPolygon) {
        let w = mapPolygon.polygon.bbox.width;
        let h = mapPolygon.polygon.bbox.width;
        // change 2 to smaller walue for a more close zoom
        return Math.min(mapChart.seriesWidth / (w * 2), mapChart.seriesHeight / (h * 2));
    }

    // show world data
    function showWorld() {
        currentPolygon = undefined;
        resetHover();

        if (countryDataTimeout) {
            clearTimeout(countryDataTimeout);
        }

        // make all inactive
        polygonSeries.mapPolygons.each(function (polygon) {
            polygon.isActive = false;
        });

        //updateCountryName();

        mapChart.goHome();
    }

    function handleCountryHit(event) {
        selectCountry(event.target);
    }

    function handleCountryOver(event) {
        rollOverCountry(event.target);
    }

    function handleCountryOut(event) {
        rollOutCountry(event.target);
    }

    function resetHover() {
        polygonSeries.mapPolygons.each(function (polygon) {
            polygon.isHover = false;
        });
    }

    function addDeviceDefinitionData(data: DeviceDefinitionGeoContainer) {
        const locationList = [];
        for (const [key, value] of Object.entries(data)) {
            locationList.push({ country: key, count: value });
        }
        const sortedData = Utils.sortCollection(locationList, (geoData: DeviceDefinitionDeviceLocationMap) => {
            geoData.count;
        });
        const displayData = sortedData.map((dataItem: DeviceDefinitionDeviceLocationMap) => {
            const countryFlag = <SternumFlag countryCode={dataItem.country} />;
            const toolTip = `
                            <div style="display: flex; align-items: center;">
                            ${renderToString(countryFlag)}
                                <span style=" padding-left: 5px; font-size:14px; font-weight:600; font-family: Inter;"><strong>{name}: {value.formatNumber('#,###')}</strong></span>
                            </div>
                            <span style="font-size:12px; font-family: Inter; color:#606060;"> Active Devices</span>
                            `;
            return {
                id: dataItem.country,
                value: dataItem.count,
                tooltip: toolTip,
            };
        });

        // Add some data
        polygonSeries.data = displayData;
        polygonTemplate.propertyFields.fill = "fill";
    }

    function updateData(newData: GeoLocationContainer[]) {
        // @ts-ignore
        const sortedData = Utils.sortCollection(newData, (geoData: GeoLocationContainer) => {
            geoData.created;
        });

        const displayData = sortedData.map((dataItem) => {
            const geoData: GeoLocationData = dataItem["geo_location"];

            const flagComponent = <SternumFlag countryCode={geoData["country"]} />;

            const htmlToolTip = `
            <div style="font-size:14px; font-family: Inter; color:#999999; padding-bottom:3px;">
                ${moment(dataItem["created"]).format("MM/DD/YYYY HH:mm")}
            </div>

            <div style="width:100%; height: 2px; background-color:#343434; margin-bottom:3px;"></div>
            
            <div style="display: flex; justify-content:space-between; align-items: center;">
                <div style="font-size:16px; font-family: Inter; color:#FFFFFF; font-weight: 600; padding-bottom:3px;" >
                {name}
                
                </div>
                ${renderToString(flagComponent)}
            </div>

            <div style="width:100%; height: 2px; background-color:#343434; margin-bottom:3px;"></div>

            <div style="font-size:14px; font-family: Inter; color:#999999; padding-bottom:3px;" >
            <strong>IP Address: </strong> ${dataItem["ip_address"]}
            </div>

            <div style="font-size:14px; font-family: Inter; color:#999999; padding-bottom:3px;" >
            <strong>Latitude: </strong> ${geoData["latitude"]}
            </div>
            <div style="font-size:14px; font-family: Inter; color:#999999; padding-bottom:3px;" >
            <strong>Longitude: </strong> ${geoData["longitude"]}
            </div>
            <div style="font-size:14px; font-family: Inter; color:#999999; padding-bottom:3px;" >
            <strong>City: </strong> ${geoData["city"]}
            </div>
            <div style="font-size:14px; font-family: Inter; color:#999999; padding-bottom:3px;" >
            <strong>State: </strong> ${geoData["state"]}
            </div>
            <div style="font-size:14px; font-family: Inter; color:#999999; padding-bottom:3px;" >
            <strong>Organization: </strong> ${geoData["organization"]}
            </div>
            <div style="font-size:14px; font-family: Inter; color:#999999; padding-bottom:6px;" >
            <strong>Last Seen: </strong> ${moment(dataItem["created"]).fromNow()}
            </div>
            `;
            return {
                id: geoData["country"],
                value: dataItem["created"],
                tooltip: htmlToolTip,
            };
        });

        // Add some data
        polygonSeries.data = displayData;
        polygonTemplate.propertyFields.fill = "fill";
    }

    return { updateData, addDeviceDefinitionData };
}

export interface DeviceInteractiveMapProps {
    id: string;
    minColor: string;
    maxColor: string;
    deviceMapData?: GeoLocationContainer[];
    deviceDefinitionMapView?: DeviceDefinitionGeoContainer;
}

export default function DeviceInteractiveMap(props: DeviceInteractiveMapProps) {
    const chartId = `device_interactive_map_${props.id}`;
    const chart = useRef<am4maps.MapChart>(null);
    const chartController = useRef(null);

    useLayoutEffect(() => {
        let newChart = am4core.create(chartId, am4maps.MapChart);
        chart.current = newChart;

        chartController.current = onReady(newChart, { minColor: props.minColor, maxColor: props.maxColor });

        return () => {
            newChart.dispose();
        };
    }, []);

    useEffect(() => {
        if (props.deviceMapData) {
            chartController.current?.updateData(props.deviceMapData);
        } else {
            chartController.current?.addDeviceDefinitionData(props.deviceDefinitionMapView);
        }
    }, []);

    return <div id={chartId} style={{ width: "100%", height: "70vh" }} />;
}
