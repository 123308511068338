import React from "react";

import { SternumIconProps } from "./SternumIcon.types";

export const ResearchNumberIcon = React.forwardRef<SVGSVGElement, SternumIconProps>(function ResearchNumberIcon(
    { color, height = 27, width = 28, ...props },
    ref
) {
    return (
        <svg
            ref={ref}
            width={width}
            height={height}
            viewBox="0 0 28 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.946 10.4817C25.2895 5.92576 21.8526 2.04678 17.0626 1.03446C12.2804 0.0229392 7.56375 2.16759 5.09753 6.0515C1.4717 8.89767 -0.17943 13.789 1.33051 18.4145C2.84498 23.0478 7.08294 26.0427 11.7066 26.2159C15.9894 27.8937 21.0503 26.8654 24.3118 23.2624C27.5717 19.6598 28.0714 14.5445 25.946 10.4817Z"
                fill="#679CC5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.9461 10.4815C25.3357 9.31637 24.5103 8.23646 23.4709 7.30398C21.4118 5.46073 18.8608 4.50574 16.2933 4.41114C13.9259 3.48235 11.232 3.33574 8.623 4.17933C7.30736 4.60402 6.12346 5.24613 5.09762 6.05127C4.39987 7.15124 3.88202 8.38965 3.59386 9.73572C3.02332 12.4058 3.45225 15.055 4.62834 17.3C4.99059 19.8308 6.21156 22.2552 8.27022 24.0984C9.31088 25.0305 10.4771 25.7332 11.7067 26.2157C13.0055 26.2644 14.3343 26.0904 15.6417 25.6677C18.2664 24.8188 20.3679 23.1094 21.7341 20.9545C23.7454 19.3771 25.2356 17.1194 25.8103 14.4333C26.0964 13.0958 26.131 11.762 25.9461 10.4815"
                fill="#2874AD"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.932 11.4314C21.842 8.09335 19.3371 5.60552 16.2925 4.41096C13.0467 4.29259 9.77361 5.55106 7.42965 8.14044C5.08654 10.7298 4.16977 14.1006 4.62794 17.2998C6.14036 20.1869 8.88935 22.4049 12.3407 23.1346C15.7747 23.8607 19.1757 22.9586 21.7341 20.9543C23.4709 18.213 24.0172 14.7538 22.932 11.4314"
                fill="#025B9E"
            />
        </svg>
    );
});
