import axios, { AxiosRequestConfig } from "axios";
import AuthenticationHttpRequestInterceptor from "../interceptors/AuthenticationHttpRequestInterceptor";
import AuthenticationHttpResponseInterceptor from "../interceptors/AuthenticationHttpResponseInterceptor";
import HttpRequestInterceptor from "../interceptors/HttpRequestInterceptor";
import HttpResponseInterceptor from "../interceptors/HttpResponseInterceptor";
import HttpResponse from "../state/HttpResponse";

/**
 * Http service for all http calls in the application.
 */
class HttpService {
    /**
     * Constructor.
     */
    constructor(onUnauthorizedCallback: () => void) {
        let requestInterceptors: HttpRequestInterceptor[] = [new AuthenticationHttpRequestInterceptor()];
        let responseInterceptors: HttpResponseInterceptor[] = [
            new AuthenticationHttpResponseInterceptor(onUnauthorizedCallback),
        ];

        for (let i = 0; i < requestInterceptors.length; i++) {
            let interceptor = requestInterceptors[i];

            axios.interceptors.request.use(
                (config) => interceptor.intercept(config),
                (err) => Promise.reject(err)
            );
        }

        for (let i = 0; i < responseInterceptors.length; i++) {
            let interceptor = responseInterceptors[i];

            axios.interceptors.response.use(
                (response) => interceptor.intercept(response),
                (error) => interceptor.error(error)
            );
        }
    }

    /**
     * Executes an HTTP get.
     */
    public get(endpoint: string, queryParams?: Object): Promise<Object> {
        return this.wrapWithPromise(() =>
            axios.get(endpoint, {
                params: queryParams ? queryParams : {},
            })
        );
    }

    /**
     * Executes an HTTP download .
     */
    public download(endpoint: string, queryParams?: Object, options: DownloadOptions = {}): Promise<Object> {
        return this.wrapWToPromiseWithHeaders(() =>
            axios.get(endpoint, {
                params: queryParams ? queryParams : {},
                responseType: "arraybuffer",
                onDownloadProgress: options.onProgress
                    ? (progressEvent) => {
                          const percentProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);

                          options.onProgress?.(percentProgress);
                      }
                    : undefined,
            })
        );
    }

    /**
     * Executes an HTTP download with body .
     */
    public downloadWithData(endpoint: string, data?: Object): Promise<Object> {
        return this.wrapWToPromiseWithHeaders(() => axios.post(endpoint, data, { responseType: "arraybuffer" }));
    }

    /**
     * Executes an HTTP post.
     */
    public post(endpoint: string, data: Object, config?: AxiosRequestConfig): Promise<Object> {
        return this.wrapWithPromise(() => axios.post(endpoint, data, config));
    }

    /**
     * Executes an HTTP post.
     */
    public put(endpoint: string, data: Object): Promise<Object> {
        return this.wrapWithPromise(() => axios.put(endpoint, data));
    }

    /**
     * Executes an HTTP delete.
     */
    public delete(endpoint: string, queryParams?: Object, data?: Object): Promise<Object> {
        return this.wrapWithPromise(() =>
            axios.delete(endpoint, {
                params: queryParams ? queryParams : {},
                data: data,
            })
        );
    }

    /**
     * Wraps axios service calls to return a TypeScript Promise<Void> object.
     * @param axiosCallback
     * @returns {Promise<void>}
     */
    private wrapWithPromise(axiosCallback): Promise<Object> {
        return new Promise<Object>((resolve, reject) => {
            axiosCallback()
                .then((response) => {
                    if (response.data && response.data.redirect) {
                        window.location = response.data.redirect;
                    } else {
                        resolve(response.data);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    /**
     * Wraps axios service calls to return a TypeScript Promise<Void> object.
     * @param axiosCallback
     * @returns {Promise<void>}
     */
    private wrapWToPromiseWithHeaders(axiosCallback): Promise<Object> {
        return new Promise<Object>((resolve, reject) => {
            axiosCallback()
                .then((response) => resolve(new HttpResponse(response.data, response.headers)))
                .catch((error) => {
                    reject(error);
                });
        });
    }
}

export default HttpService;

export interface DownloadOptions {
    onProgress?: (percentProgress: number /* from 0 to 100 */) => unknown;
}
