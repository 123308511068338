import { Typography } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { flatMap, get, isEmpty, isEqual, maxBy, set, uniq, uniqueId } from "lodash";
import * as React from "react";
import GraphUtils, { GraphResult, GraphTooltipData } from "../../../lib/infra/GraphUtils";
import SternumUtils from "../../../lib/infra/SternumUtils";
import ConfigurationService from "../../../lib/services/ConfigurationService";
import ReceivedDefinitionsResponse from "../../../lib/services/events/ReceivedDefinitionsResponse";
import ServiceWire from "../../../lib/services/ServiceWire";
import Utils from "../../../lib/infra/Utils";
import DeviceDefinitionVersionInfo from "../../../lib/state/DeviceDefinitionVersionInfo";
import AggregationFunctionType from "../../../lib/state/Visualisation/AggregationFunctionType";
import VisualisationDataSourceGroupBy from "../../../lib/state/Visualisation/VisualisationConfigurationGroupBy";
import VisualisationDataSource from "../../../lib/state/Visualisation/VisualisationDataSource";
import { sternumColorMap } from "../../ColorPicker/sternumColorMap";
import SternumBarChart from "../../SUI/SternumAMCharts/SternumBarChart";
import { ChartEmptyIcon } from "../../SUI/SternumIcon/SternumIcon";
import { getUniqueCountTableData, UniqueCountTableDataResponse } from "../../UniqueCountQuery/UniqueCountTableData";
import UIDataVisualisationConfiguration from "../../VisualisationConfigurationComponent/entities/UIDataVisualisationConfiguration";
import GraphLoadingDisplay from "../../VisualisationConfigurationComponent/GraphLoadingDisplay/GraphLoadingDisplay";
import getVisualisationDataSource from "../../VisualisationConfigurationComponent/utils/getVisualisationDataSource";
import { isChangedDataSourceConfiguration } from "../../VisualisationConfigurationComponent/utils/isChangedDataSourceConfiguration";
import visualisationConfigurationPresentationValuesGraphStyle from "./VisualisationConfigurationPresentationValuesGraphStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    loadingGraph: boolean;
    errorLoadingGraph: boolean;

    graphResult: GraphResult;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof visualisationConfigurationPresentationValuesGraphStyle> {
    entityId: string;
    startTime: number;
    endTime: number;
    dataVisualisationConfigurations: UIDataVisualisationConfiguration[];
    groupBy: VisualisationDataSourceGroupBy;
    receivedDefinitionsResponse: Record<string, ReceivedDefinitionsResponse>;
    deviceDefinitionVersions: DeviceDefinitionVersionInfo[];
    isLogarithmicScale?: boolean;
    displayTooltipDataPerDeviceDefinitionVersion?: boolean;
    refreshComponentId?: number;
}

/**
 * Displays a bar of metrics.
 */
class VisualisationConfigurationPresentationValuesGraph extends React.Component<AppProps, AppState> {
    graphId: string;

    constructor(props: AppProps) {
        super(props);

        this.graphId = uniqueId();

        this.state = {
            loadingGraph: false,
            errorLoadingGraph: false,

            graphResult: null,
        };
    }

    async componentDidMount() {
        if (this.props.displayTooltipDataPerDeviceDefinitionVersion) {
            await this.loadGraphDetailed(this.props.startTime, this.props.endTime);
        } else {
            await this.loadGraph(this.props.startTime, this.props.endTime);
        }
    }

    async componentDidUpdate(nextProps: Readonly<AppProps>, prevState: Readonly<AppState>) {
        const nextDataVisualisationConfigurations: UIDataVisualisationConfiguration[] =
            nextProps.dataVisualisationConfigurations || [];

        const changedDataSource =
            nextDataVisualisationConfigurations.length !== this.props.dataVisualisationConfigurations.length ||
            nextDataVisualisationConfigurations.some(
                (newConfiguration, index) =>
                    !this.props.dataVisualisationConfigurations[index] ||
                    isChangedDataSourceConfiguration(
                        newConfiguration,
                        this.props.dataVisualisationConfigurations[index]
                    )
            );

        if (
            nextProps.refreshComponentId !== this.props.refreshComponentId ||
            (nextProps.startTime && nextProps.startTime !== this.props.startTime) ||
            (nextProps.endTime && nextProps.endTime !== this.props.endTime) ||
            changedDataSource ||
            nextProps.entityId !== this.props.entityId ||
            !isEqual(this.props.groupBy, nextProps.groupBy)
        ) {
            if (this.props.displayTooltipDataPerDeviceDefinitionVersion) {
                await this.loadGraphDetailed(this.props.startTime, this.props.endTime);
            } else {
                await this.loadGraph(this.props.startTime, this.props.endTime);
            }
        }
    }

    render() {
        const { classes } = this.props;

        if (this.state.loadingGraph || !this.props.startTime || !this.props.endTime) {
            return <GraphLoadingDisplay />;
        }

        if (this.state.errorLoadingGraph) {
            return <Typography className={classNames(classes.commonDangerColor)}>Error loading graph...</Typography>;
        }

        if (this.state.graphResult) {
            let barChartData;
            if (
                this.props.dataVisualisationConfigurations &&
                this.props.dataVisualisationConfigurations.length > 0 &&
                this.props.dataVisualisationConfigurations[0].aggregationFunctionType ===
                    AggregationFunctionType.UNIQUE_COUNT
            ) {
                barChartData = this.splitToUniqueCountDatasets(this.props.dataVisualisationConfigurations[0]);
            } else if (this.props.groupBy?.enabled) {
                barChartData = this.splitVisualisationDataSourcesIntoDatasetsByGroupByKeys(
                    this.props.dataVisualisationConfigurations
                );
            } else {
                barChartData = this.splitVisualisationDataSourcesIntoDatasets(
                    this.props.dataVisualisationConfigurations
                );
            }

            // @ts-ignore
            if (barChartData.datasets.every(({ data }) => !data)) {
                return (
                    <div className={classes.emptyDataContainer}>
                        <div className={classes.emptyDataInner}>
                            <ChartEmptyIcon />
                            <Typography className={classes.emptyDataText}>You dont have any data yet</Typography>
                        </div>
                    </div>
                );
            }

            return (
                <div className={classNames(classes.root)}>
                    <div className={classNames(classes.root)}>
                        <SternumBarChart
                            id={`data-source-bar-chart-${this.graphId}`}
                            data={barChartData}
                            isLogarithmicScale={this.props.isLogarithmicScale}
                            stacked={this.props.groupBy?.enabled}
                            tooltipCallback={this.handleTooltipGeneration}
                            height="100%"
                        />
                    </div>
                </div>
            );
        }

        return (
            <div className={classes.emptyDataContainer}>
                <div className={classes.emptyDataInner}>
                    <ChartEmptyIcon />
                    <Typography className={classes.emptyDataText}>You dont have any data yet</Typography>
                </div>
            </div>
        );
    }

    private transformUniqueCountValues(
        values: any[],
        visualisationConfiguration: VisualisationDataSource,
        uniqueCountTableDataResponse: UniqueCountTableDataResponse
    ) {
        const argIdToFieldName = {
            [ConfigurationService.getCategoryArgumentField().id]: "categories",
            [ConfigurationService.getInterestArgumentField().id]: "interests",
            [ConfigurationService.getEventTypeArgumentField().id]: "eventTypes",
        };

        const newValues = values
            .reverse()
            .map((val, index) => {
                const col = visualisationConfiguration.uniqueColumns[index];

                if (!col || !col.isSpecialField) {
                    return val;
                }

                if (col.value in argIdToFieldName) {
                    const newVal = uniqueCountTableDataResponse?.[argIdToFieldName[col.value]][val];
                    return newVal !== undefined ? newVal.displayName : `Missing label: ${val}`;
                }

                return val;
            })
            .filter((_, index) => index > 0);

        return newValues;
    }

    private handleTooltipGeneration = (value: string, tooltipContent: any, hoveredCategory?: string) => {
        // let hasUniqueValue = false;
        // this.props.dataVisualisationConfigurations.forEach((configuration) => {
        //     if (configuration.aggregationFunctionType === AggregationFunctionType.UNIQUE_COUNT) {
        //         hasUniqueValue = true;
        //     }
        // });

        const deviceDefinitionVersionsData = hoveredCategory
            ? tooltipContent.deviceDefinitionVersionsData?.[hoveredCategory]
            : tooltipContent.deviceDefinitionVersionsData;

        const dataPerDeviceDefinitionVersion = Object.keys(deviceDefinitionVersionsData || {}).map((key) => {
            return {
                label: key,
                value: deviceDefinitionVersionsData[key] as number,
            };
        });

        return this.createDataSourceTooltip(tooltipContent.configuration, dataPerDeviceDefinitionVersion);
    };

    private splitToUniqueCountDatasets(visualisationConfiguration: UIDataVisualisationConfiguration) {
        const dataSource = this.state.graphResult.data_sources[visualisationConfiguration.dataSourceKey];

        if (!dataSource) {
            return {
                labels: [],
                datasets: [],
            };
        }

        return {
            labels: Object.keys(dataSource),
            datasets: Object.keys(dataSource).map((item, index) => {
                const data = {
                    backgroundColor: sternumColorMap[visualisationConfiguration.color].main,
                    tooltipContent: {
                        deviceDefinitionVersionsData: {
                            [item]: dataSource[item],
                        },
                        configuration: visualisationConfiguration,
                    },
                    // value: GraphUtils.calculateTotalValue(rawValues, configuration.aggregationFunctionType),
                    data: {
                        value: dataSource[item],
                    },
                };

                return data;
            }),
        };
    }

    private splitVisualisationDataSourcesIntoDatasets(visualisationConfigurations: UIDataVisualisationConfiguration[]) {
        // graphResult = {
        //     data_sources: {
        //			DEFAULT: 324,
        //			1: 4582,
        //			2: 587,
        //			...
        //     }
        // }

        return {
            labels: visualisationConfigurations.map((configuration) => configuration.graphLabel),
            datasets: visualisationConfigurations
                .map((configuration) => {
                    const dataSource = this.state.graphResult.data_sources[configuration.dataSourceKey];
                    if (!dataSource) return;

                    const deviceDefinitionVersionIds = configuration.deviceDefinitionVersionNames;

                    const isDetailed = !("total" in this.state.graphResult.data_sources[configuration.dataSourceKey]);

                    let deviceDefinitionVersionsData: Record<string, number>;

                    if (configuration.aggregationFunctionType === AggregationFunctionType.UNIQUE_COUNT) {
                        deviceDefinitionVersionsData = {};
                        Object.keys(this.state.graphResult.data_sources[configuration.dataSourceKey]).forEach(
                            (item) => {
                                deviceDefinitionVersionsData[item] =
                                    this.state.graphResult.data_sources[configuration.dataSourceKey][item] || 0;
                            }
                        );
                    } else {
                        deviceDefinitionVersionsData = deviceDefinitionVersionIds.reduce(
                            (acc, deviceDefinitionVersionId) => {
                                set(
                                    acc,
                                    [deviceDefinitionVersionId],
                                    isDetailed
                                        ? get(this.state.graphResult, [
                                              "data_sources",
                                              configuration.dataSourceKey,
                                              deviceDefinitionVersionId,
                                          ]) || 0
                                        : null
                                );

                                return acc;
                            },
                            {}
                        );
                    }

                    let rawValues: any[];

                    if (configuration.aggregationFunctionType === AggregationFunctionType.UNIQUE_COUNT) {
                        const valueMap = this.state.graphResult.data_sources[configuration.dataSourceKey];
                        rawValues = Object.values(valueMap).map((v) => v || 0);
                    } else {
                        rawValues = isDetailed
                            ? deviceDefinitionVersionIds.map(
                                  (id) =>
                                      get(this.state.graphResult, ["data_sources", configuration.dataSourceKey, id]) ||
                                      0
                              )
                            : [
                                  get(this.state.graphResult, ["data_sources", configuration.dataSourceKey, "total"]) ||
                                      0,
                              ];
                    }

                    const data = {
                        value: GraphUtils.calculateTotalValue(rawValues, configuration.aggregationFunctionType),
                    };

                    return {
                        backgroundColor: sternumColorMap[configuration.color].main,
                        tooltipContent: {
                            deviceDefinitionVersionsData,
                            configuration,
                        },
                        data,
                    };
                })
                .filter(Boolean),
        };
    }

    private splitVisualisationDataSourcesIntoDatasetsByGroupByKeys(
        visualisationConfigurations: UIDataVisualisationConfiguration[]
    ) {
        // graphResult = {
        //     data_sources: {
        //			DEFAULT: {
        //				"121.52.188.23": 582,
        //				"45.79.125.224": 23,
        //			},
        //			1: {
        //				"121.52.188.23": 582,
        //				"45.79.125.224": 23,
        //			},
        //			...
        //     }
        // }

        let groupByKeysSet = new Set<string>();

        const dataSourceValues = Object.values(this.state.graphResult?.data_sources || {});

        dataSourceValues.forEach((obj) => {
            const valuesPerDeviceDefinitionVersion = Object.values(obj);
            valuesPerDeviceDefinitionVersion.forEach((deviceDefinitionVersionObj) => {
                Object.keys(deviceDefinitionVersionObj).forEach((key) => {
                    groupByKeysSet.add(key);
                });
            });
        });

        const groupByKeys = [...groupByKeysSet];

        return {
            labels: GraphUtils.convertGroupByKeysToLabelsArray(groupByKeys, this.props.groupBy.field),
            datasets: visualisationConfigurations
                .map((configuration) => {
                    const dataSource = this.state.graphResult.data_sources[configuration.dataSourceKey];
                    if (!dataSource) return;

                    const deviceDefinitionVersionIds = configuration.deviceDefinitionVersionNames;

                    const isDetailed = !("total" in this.state.graphResult.data_sources[configuration.dataSourceKey]);

                    let deviceDefinitionVersionsData: Record<string, number>;

                    deviceDefinitionVersionsData = deviceDefinitionVersionIds.reduce(
                        (acc, deviceDefinitionVersionId) => {
                            groupByKeys.forEach((key) => {
                                set(
                                    acc,
                                    [key, deviceDefinitionVersionId],
                                    isDetailed
                                        ? get(this.state.graphResult, [
                                              "data_sources",
                                              configuration.dataSourceKey,
                                              deviceDefinitionVersionId,
                                              key,
                                          ]) || 0
                                        : null
                                );
                            });

                            return acc;
                        },
                        {}
                    );

                    deviceDefinitionVersionsData = GraphUtils.convertGroupByKeysToLabelsObject(
                        deviceDefinitionVersionsData,
                        this.props.groupBy.field
                    );

                    const data = {
                        values: {},
                    };

                    groupByKeys.forEach((key) => {
                        const rawValues = isDetailed
                            ? deviceDefinitionVersionIds.map(
                                  (id) =>
                                      get(this.state.graphResult, [
                                          "data_sources",
                                          configuration.dataSourceKey,
                                          id,
                                          key,
                                      ]) || 0
                              )
                            : [
                                  get(this.state.graphResult, [
                                      "data_sources",
                                      configuration.dataSourceKey,
                                      "total",
                                      key,
                                  ]) || 0,
                              ];

                        const value = GraphUtils.calculateTotalValue(rawValues, configuration.aggregationFunctionType);

                        set(data.values, [key], value);
                    });

                    data.values = GraphUtils.convertGroupByKeysToLabelsObject(data.values, this.props.groupBy.field);

                    return {
                        data,
                        tooltipContent: { deviceDefinitionVersionsData, configuration },
                        source: configuration.dataSourceKey,
                    };
                })
                .filter(Boolean),
        };
    }

    private createDataSourceTooltip = (
        configuration: UIDataVisualisationConfiguration,
        deviceDefinitionVersionData?: GraphTooltipData[]
    ): string => {
        const queryEditorFields = SternumUtils.getDeviceDefinitionFieldsToQuery(
            true,
            true,
            true,
            true,
            this.props.receivedDefinitionsResponse[configuration.deviceDefinitionVersionIds[0]]?.receivedDefinitions,
            this.props.receivedDefinitionsResponse[configuration.deviceDefinitionVersionIds[0]]?.receivedArguments,
            false
        );

        const traceDefinitionFields = SternumUtils.getTraceDefinitionFields(
            true,
            true,
            true,
            true,
            this.props.receivedDefinitionsResponse[configuration.deviceDefinitionVersionIds[0]]?.receivedDefinitions
        );

        const tooltip = GraphUtils.constructSeriesTooltip(
            configuration,
            queryEditorFields,
            traceDefinitionFields,
            "bar",
            this.props.groupBy,
            deviceDefinitionVersionData
        );

        return tooltip;
    };

    /**
     * Loads the graph of the aggregated events.
     */
    private async loadGraph(startTime: number, endTime: number) {
        const dataSources = this.getDataSources();
        if (isEmpty(dataSources)) return;

        try {
            this.setState({
                loadingGraph: true,
                errorLoadingGraph: false,
            });

            let hourDifference = (endTime - startTime) / 1000.0 / 60.0 / 60.0;
            let amountOfGraphPointsToDisplay = 60;
            let timeFormat = "HH:mm";

            if (hourDifference > 24) {
                timeFormat = "MM/dd HH:mm";
            }

            let graphResult: GraphResult = {
                data_sources: {},
            };

            const allDeviceDefinitionVersions = uniq(flatMap(dataSources, "deviceDefinitionVersionIds"));
            const allTraceDefinitionIds = uniq(dataSources.map((dataSource) => dataSource.traceDefinitionId));

            const response = await ServiceWire.getVisualisationApiService().getArgumentValueOverTime(
                this.props.entityId,
                startTime,
                endTime,
                false,
                timeFormat,
                amountOfGraphPointsToDisplay,
                dataSources,
                this.props.groupBy,
                allDeviceDefinitionVersions,
                allTraceDefinitionIds
            );

            /**
             * {
             * 	1: {
             * 		ddve1: ...
             * 	},
             * 	2: {
             * 		ddve1: ...
             * 	},
             * }
             */
            dataSources.forEach((dataSource) => {
                set(
                    graphResult,
                    ["data_sources", dataSource.dataSourceKey, "total"],
                    get(response, ["data_sources", dataSource.dataSourceKey])
                );
            });

            this.setState({
                graphResult: graphResult,
                loadingGraph: false,
                errorLoadingGraph: false,
            });
        } catch (error) {
            console.log(error);
            this.setState({
                loadingGraph: false,
                errorLoadingGraph: true,
            });
        }
    }

    private async loadGraphDetailed(startTime: number, endTime: number) {
        const dataSources = this.getDataSources();
        if (isEmpty(dataSources)) return;

        try {
            this.setState({
                loadingGraph: true,
                errorLoadingGraph: false,
            });

            let hourDifference = (endTime - startTime) / 1000.0 / 60.0 / 60.0;
            let amountOfGraphPointsToDisplay = 60;
            let timeFormat = "HH:mm";

            if (hourDifference > 24) {
                timeFormat = "MM/dd HH:mm";
            }

            let graphResult: GraphResult = {
                data_sources: {},
            };

            const dataSourcesRequests = this.getDataSourcesRequests(dataSources);

            const allDeviceDefinitionVersions = uniq(flatMap(dataSources, "deviceDefinitionVersionIds"));
            const allTraceDefinitionIds = uniq(dataSources.map((dataSource) => dataSource.traceDefinitionId));

            const responses = await Promise.all(
                dataSourcesRequests.map((dataSourceBatch) => {
                    return ServiceWire.getVisualisationApiService().getArgumentValueOverTime(
                        this.props.entityId,
                        startTime,
                        endTime,
                        false,
                        timeFormat,
                        amountOfGraphPointsToDisplay,
                        dataSourceBatch,
                        this.props.groupBy,
                        allDeviceDefinitionVersions,
                        allTraceDefinitionIds
                    );
                })
            );

            const deviceDefinitionVersionIdToVersionMap = this.getDeviceDefinitionVersionIdToVersionMap();

            const hasUniqueValue =
                this.props.dataVisualisationConfigurations.length > 0 &&
                this.props.dataVisualisationConfigurations[0].aggregationFunctionType ===
                    AggregationFunctionType.UNIQUE_COUNT;

            let uniqueDataResponse: UniqueCountTableDataResponse = null;
            if (hasUniqueValue) {
                uniqueDataResponse = await getUniqueCountTableData(this.props.dataVisualisationConfigurations[0]);
            }

            /**
             * {
             * 	1: {
             * 		ddve1: ...
             * 		ddve2: ...
             * 	},
             * 	2: {
             * 		ddve1: ...
             * 	},
             * }
             */
            responses.forEach((response, index) => {
                const dataSourceBatch = dataSourcesRequests[index];

                dataSourceBatch.forEach((dataSource) => {
                    const versionId = dataSource.deviceDefinitionVersionIds[0];
                    const version = deviceDefinitionVersionIdToVersionMap[versionId];
                    if (!version) return;

                    if (dataSource.aggregationFunction === AggregationFunctionType.UNIQUE_COUNT) {
                        get(response, ["data_sources", dataSource.dataSourceKey]).forEach((item) => {
                            set(
                                graphResult,
                                [
                                    "data_sources",
                                    dataSource.dataSourceKey,
                                    version.getVersionName() +
                                        " " +
                                        this.transformUniqueCountValues(
                                            item.values,
                                            dataSource,
                                            uniqueDataResponse
                                        ).join(", "),
                                ],
                                item.count
                            );
                        });
                    } else {
                        set(
                            graphResult,
                            ["data_sources", dataSource.dataSourceKey, version.getVersionName()],
                            get(response, ["data_sources", dataSource.dataSourceKey])
                        );
                    }
                });
            });

            this.setState({
                graphResult: graphResult,
                loadingGraph: false,
                errorLoadingGraph: false,
            });
        } catch (error) {
            console.log(error);
            this.setState({
                loadingGraph: false,
                errorLoadingGraph: true,
            });
        }
    }

    private getDeviceDefinitionVersionIdToVersionMap = (): Record<string, DeviceDefinitionVersionInfo> => {
        return this.props.deviceDefinitionVersions.reduce((acc, version) => {
            acc[version.entityId] = version;
            return acc;
        }, {});
    };

    private getDataSources(): VisualisationDataSource[] {
        return this.props.dataVisualisationConfigurations
            .filter((dataVisualisationConfiguration) => {
                return (
                    dataVisualisationConfiguration.aggregatedArgumentDefinitionId ||
                    dataVisualisationConfiguration.aggregationFunctionType === AggregationFunctionType.COUNT ||
                    dataVisualisationConfiguration.aggregationFunctionType === AggregationFunctionType.UNIQUE_COUNT
                );
            })
            .map((dataVisualisationConfiguration) => getVisualisationDataSource(dataVisualisationConfiguration));
    }

    private getDataSourcesRequests(dataSources: VisualisationDataSource[]): VisualisationDataSource[][] {
        const deviceDefinitionVersionBuckets: Record<string, string[]> = {};
        dataSources.forEach((dataSource) => {
            deviceDefinitionVersionBuckets[dataSource.dataSourceKey] = [...dataSource.deviceDefinitionVersionIds];
        });

        const dataSourceKeyToDataSourceMap: Record<string, VisualisationDataSource> = {};
        dataSources.forEach((dataSource) => {
            dataSourceKeyToDataSourceMap[dataSource.dataSourceKey] = dataSource;
        });

        const largestBucket = maxBy(Object.values(deviceDefinitionVersionBuckets), "length");

        const requests = [];
        while (!isEmpty(largestBucket)) {
            const dataSourceBatch: VisualisationDataSource[] = [];

            Object.keys(dataSourceKeyToDataSourceMap).forEach((key) => {
                if (!isEmpty(deviceDefinitionVersionBuckets[key])) {
                    dataSourceBatch.push({
                        ...dataSourceKeyToDataSourceMap[key],
                        deviceDefinitionVersionIds: [deviceDefinitionVersionBuckets[key].shift()],
                    });
                }
            });

            requests.push(dataSourceBatch);
        }

        return requests;
    }
}

export default withStyles(visualisationConfigurationPresentationValuesGraphStyle)(
    VisualisationConfigurationPresentationValuesGraph
);
