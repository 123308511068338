import { Tabs, TabsProps } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import React from "react";

interface SternumTabsProps extends TabsProps {}

const StyledTabs = withStyles((theme) => ({
    root: {
        overflow: "visible",
    },

    fixed: {
        overflow: "visible !important",
    },

    indicator: { display: "none" },
}))(Tabs);

const SternumTabs = (props: SternumTabsProps) => {
    return <StyledTabs {...props} />;
};

export default SternumTabs;
