import { call, put, select, takeEvery } from "redux-saga/effects";
import ServiceWire from "../../services/ServiceWire";
import ActionType from "../ActionType";
import { GlobalState } from "../../state/GlobalState";
import { Status } from "../../state/GlobalTypes";
import {
    getDashboardOnboardingStateErrorAction,
    getDashboardOnboardingStateInProgressAction,
    getDashboardOnboardingStateSuccessfullyAction,
} from "./GetDashboardOnboardingStateAction";
import { DashboardOnboardingStepState } from "../../state/DashboardRegularState";
import {
    SetDashboardOnboardingStateAction,
    setDashboardOnboardingStateErrorAction,
    setDashboardOnboardingStateInProgressAction,
    setDashboardOnboardingStateSuccessfullyAction,
} from "./SetDashboardOnboardingStateAction";

export function* getDashboardOnboardingStateAsync() {
    const state: GlobalState = yield select();

    if ([Status.InProgress, Status.Success].includes(state.dashboardRegular.onboardingState.statusGet)) {
        return;
    }

    yield put(getDashboardOnboardingStateInProgressAction());

    try {
        const response: DashboardOnboardingStepState = yield call([
            ServiceWire.getSternumService(),
            ServiceWire.getSternumService().getDashboardRegularOnboardingState,
        ]);

        const isVisible = Object.keys(response).some((key) => !response[key]);

        yield put(getDashboardOnboardingStateSuccessfullyAction({ isVisible, stepsState: response }));
    } catch (error) {
        yield put(getDashboardOnboardingStateErrorAction());
    }
}

export function* setDashboardOnboardingStateAsync(action: SetDashboardOnboardingStateAction) {
    yield put(setDashboardOnboardingStateInProgressAction());

    try {
        const state: GlobalState = yield select();

        const stepsState = { ...state.dashboardRegular.onboardingState.stepsState, ...action.stepsState };
        const isVisible = action.isVisible ?? Object.keys(stepsState).some((key) => !stepsState[key]);

        // First save correct changed state and then update backend endpoint
        yield put(setDashboardOnboardingStateSuccessfullyAction({ isVisible, stepsState: action.stepsState }));

        const response: DashboardOnboardingStepState = yield call(
            [ServiceWire.getSternumService(), ServiceWire.getSternumService().setDashboardRegularOnboardingState],
            action.stepsState
        );
    } catch (error) {
        yield put(setDashboardOnboardingStateErrorAction());
    }
}

export function* watchDashboardOnboardingStateAsync() {
    yield takeEvery(
        (action) => action.type === ActionType.DashboardRegularGetOnboardingState,
        getDashboardOnboardingStateAsync
    );
    yield takeEvery(
        (action) => action.type === ActionType.DashboardRegularSetOnboardingState,
        setDashboardOnboardingStateAsync
    );
}
