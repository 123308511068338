import EntityBase from "./EntityBase";
import EntityType from "./EntityType";
import SternumTriggerType from "./SternumTriggerType";
import TableRowData from "./TableRowData";
import { default as CountOverTimeDefinition } from "./triggers/CountOverTimeDefinition";
import NeverSeenArgumentDefinition from "./triggers/NeverSeenArgumentDefinition";
import SpecificBehaviorDefinition from "./triggers/SpecificBehaviorDefinition";
import TriggerDefinition from "./triggers/TriggerDefinition";
import WhitelistDefinition from "./triggers/WhitelistDefinition";
import DeviceDefinitionVersionInfo from "./DeviceDefinitionVersionInfo";

/**
 * Represents a sternum trigger in sternum.
 */
class SternumTriggerInfo extends EntityBase implements TableRowData {
    /**
     * Constructor.
     */
    constructor(
        public sternumTriggerId: string,
        public created: number,
        public updated: number,
        public displayName: string,
        public eventInterest: string,
        public description: string,
        public triggerCategory: string,
        public triggerDefinition: TriggerDefinition,
        public triggerType: string,
        public targetEntityType: string,
        public fromTemplate?: boolean,
        public disabled?: boolean
    ) {
        super(sternumTriggerId, created, updated, EntityType.SternumTrigger);
    }

    /**
     * Converts json to entity object.
     */
    public static fromJsonObject(jsonObject: Object) {
        // Load trigger definition by trigger type
        const triggerDefinition: TriggerDefinition = jsonObject["definition"]
            ? SternumTriggerInfo.triggerDefinitionFactory(jsonObject["definition"], jsonObject["sternum_trigger_type"])
            : null;

        const fromTemplate: boolean = jsonObject.hasOwnProperty("from_template") ? jsonObject["from_template"] : false;
        const disabled: boolean = jsonObject.hasOwnProperty("disabled") ? jsonObject["disabled"] : false;
        return new SternumTriggerInfo(
            jsonObject["entity_id"],
            jsonObject["created"],
            jsonObject["updated"],
            jsonObject["display_name"],
            jsonObject["event_interest"],
            jsonObject["description"],
            jsonObject["trigger_category"],
            triggerDefinition,
            jsonObject["sternum_trigger_type"],
            jsonObject["target_entity_type"],
            fromTemplate,
            disabled
        );
    }

    /**
     * Trigger definition factory, return definition object by trigger type and json data
     */

    public static triggerDefinitionFactory(
        triggerDefinitionJsonObject: Object,
        triggerType: string
    ): TriggerDefinition {
        switch (SternumTriggerType[triggerType]) {
            // EVENT_COUNT_OVER_TIME
            case SternumTriggerType.EVENT_COUNT_OVER_TIME:
            case "EVENT_COUNT_OVER_TIME":
                return CountOverTimeDefinition.fromJsonObject(triggerDefinitionJsonObject);

            // SPECIFIC_BEHAVIOR
            case SternumTriggerType.SPECIFIC_BEHAVIOR:
            case "SPECIFIC_BEHAVIOR":
                return SpecificBehaviorDefinition.fromJsonObject(triggerDefinitionJsonObject);

            // COMPOSITE_TRIGGER
            case SternumTriggerType.COMPOSITE_TRIGGER:
            case "COMPOSITE_TRIGGER":
                return;
            // NEVER_SEEN_ARGUMENT
            case SternumTriggerType.NEVER_SEEN_ARGUMENT:
            case "NEVER_SEEN_ARGUMENT":
                return NeverSeenArgumentDefinition.fromJsonObject(triggerDefinitionJsonObject);
            // WHITE_LIST
            case SternumTriggerType.WHITE_LIST:
            case "WHITE_LIST":
                return WhitelistDefinition.fromJsonObject(triggerDefinitionJsonObject);
            default:
                return;
        }
    }

    /**
     *
     * Get json representation of trigger definition
     */
    public toJsonObject() {
        return {
            display_name: this.displayName,
            event_interest: this.eventInterest,
            description: this.description,
            trigger_category: this.triggerCategory,
            definition: this.triggerDefinition.getJsonObject(),
            sternum_trigger_type: this.triggerType,
            target_entity_type: this.targetEntityType,
            disabled: this.disabled,
        };
    }

    /**
     * Returns whether given row is any different in details than current entity.
     * Shallow comparison, in other words.
     * @param other The row to compare with.
     */
    public isDifferentFrom(other: TableRowData): boolean {
        // Row must be of type.
        if (!(other instanceof SternumTriggerInfo)) {
            return true;
        }

        let otherEntityInfo = other as SternumTriggerInfo;

        // Comparing base entity properties.
        if (
            otherEntityInfo.sternumTriggerId !== this.sternumTriggerId ||
            otherEntityInfo.created !== this.created ||
            otherEntityInfo.updated !== this.updated
        ) {
            return true;
        }

        return false;
    }

    /**
     * Gets the value for the given column id.
     */
    public getColumnValue(columnHeaderId: string): any {
        return this[columnHeaderId];
    }

    /**
     * Set trigger definition
     */
    public setTriggerDefinition(triggerDefinition: TriggerDefinition) {
        this.triggerDefinition = triggerDefinition;
    }

    /**
     * Get trigger status
     */
    public getTriggerStatus(): string {
        return this.disabled ? "Off" : "On";
    }

    public setTriggerStatus() {
        this.disabled = !this.disabled;
    }

    /**
     *
     */
    public getConfirmationMessage() {
        if (!this.disabled) {
            // Return disable message
            return "The alert will not be triggered even if the anomaly occurs.";
        }
    }
}

export default SternumTriggerInfo;
