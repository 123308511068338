import React from "react";

import { SternumIconProps } from "./SternumIcon.types";

export const SternumBgLightLogo = ({
    className,
    color = "#EEF1FA",
    height = 141,
    width = 807,
    ...props
}: SternumIconProps) => {
    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox="0 0 807 141"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g opacity="0.25">
                <path
                    d="M-82.5615 2.80762H31.2404V22.149H-61.0483V78.3015H29.3697V97.643H-61.0483V157.539H31.2404V176.88H-82.5615V2.80762Z"
                    fill={color}
                />
                <path
                    d="M138.149 107.609H103.541V176.863H82.0278V2.79053H151.868C183.67 2.79053 206.431 23.0678 206.431 55.1996C206.431 86.3954 184.917 103.865 161.222 105.737L208.301 176.551H182.735L138.149 107.609ZM149.062 21.82H103.541V88.2671H149.062C169.64 88.2671 183.67 74.541 183.67 54.8876C183.67 35.2342 169.64 21.82 149.062 21.82Z"
                    fill={color}
                />
                <path
                    d="M274.728 36.192V176.573H253.215V2.8125H275.351L375.435 140.386V2.8125H396.948V176.885H376.058L274.728 36.192Z"
                    fill={color}
                />
                <path
                    d="M447.185 2.8125H468.698V108.878C468.698 140.698 485.846 160.663 517.96 160.663C550.074 160.663 567.222 140.698 567.222 108.878V2.8125H588.736V108.878C588.736 152.241 565.04 179.693 517.96 179.693C470.88 179.693 447.185 151.929 447.185 108.878V2.8125Z"
                    fill={color}
                />
                <path
                    d="M785.175 31.8027L725.936 176.551H717.206L657.967 31.8027V176.551H636.453V2.79053H667.632L721.571 135.373L775.821 2.79053H807V176.863H785.487V31.8027H785.175Z"
                    fill={color}
                />
            </g>
        </svg>
    );
};
