import SternumService from "./SternumService";

export class ContactService {
    /**
     * Service used for outgoing sternum API calls.
     */
    private sternumService: SternumService;

    /**
     * Constructor.
     */
    constructor(sternumService: SternumService) {
        this.sternumService = sternumService;
    }

    public async sendFeedback({
        fullName,
        email,
        details,
    }: {
        fullName: string;
        email: string;
        details: string;
    }): Promise<boolean> {
        const resp = await this.sternumService.sendFeedback(fullName, email, details);

        return !!resp;
    }

    public async getSupport({
        fullName,
        email,
        issue,
    }: {
        fullName: string;
        email: string;
        issue: string;
    }): Promise<boolean> {
        const resp = await this.sternumService.getSupport(fullName, email, issue);

        return !!resp;
    }

    public async sendContactUs({
        token,
        fullName,
        companyName,
        jobTitle,
        email,
    }: {
        token: string;
        fullName: string;
        companyName: string;
        jobTitle: string;
        email: string;
    }) {
        const resp = await this.sternumService.sendContactUs(token, fullName, companyName, jobTitle, email);

        return !!resp;
    }
}
