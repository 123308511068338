import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core";

export const useDataPrivacyPageStyle = makeStyles((theme: Theme) => ({
    mainContainer: {
        background: "#FBFCFF",
    },

    root: {
        display: "flex",
        justifyContent: "center",
        maxHeight: "calc(100vh - 100px)",
        padding: "0 16px",
        overflow: "auto",
    },

    backButton: {
        display: "inline-flex",
        alignItems: "center",
        gap: 4,
        color: "#1B6FDE",
        fontWeight: 600,
        cursor: "pointer",

        "&:hover": {
            color: "#1B6FDE",
        },
    },

    backButtonIcon: {
        color: "#1B6FDE",
    },

    content: {
        maxWidth: "80%",
        padding: "32px 0",
    },

    mainTitle: {
        fontSize: "34px",
        fontWeight: 600,
        color: "#253858",
        margin: "35px 0 32px 0",
    },

    textSection: {
        marginBottom: 32,
    },

    subTitleDescription: {
        fontSize: 16,
        lineHeight: "24px",
        fontWeight: 400,
        color: "#43484C",
        marginBottom: 20,
    },

    enumerationContainer: {
        marginBottom: 16,
    },

    enumerationTitle: {
        fontSize: 14,
        lineHeight: "24px",
        fontWeight: 600,
        color: "#2B2E30",
        marginBottom: 12,
    },

    enumerationItemContainer: {
        margin: 0,
        marginBlockStart: 0,
        marginBlockEnd: 0,
        paddingInlineStart: 20,
    },

    enumerationItem: {
        fontSize: 14,
        lineHeight: "22.4px",
        fontWeight: 400,
        color: "#555B61",
        marginBottom: 8,
    },

    enumerationBigTitle: {
        fontSize: 16,
        lineHeight: "24px",
        fontWeight: 400,
        color: "#43484C",
        marginBottom: 12,

        "& b": {
            fontWeight: 600,
        },
    },

    roundedSection: {
        background: "white",
        boxShadow: "0px 0px 23px rgba(231, 232, 235, 0.25)",
        borderRadius: 14,
        padding: 24,
    },

    roundedSectionTitle: {
        fontSize: 16,
        lineHeight: "24px",
        fontWeight: 600,
        color: "#091E42",
        marginBottom: 16,
    },

    roundedSectionDescription: {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: "160%",
        color: "#555B61",
    },
}));
