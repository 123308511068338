import { Typography } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import * as React from "react";
import TimeAgo from "react-timeago";
import DeviceInfo from "../../lib/state/DeviceInfo";
import deviceInfoDisplayStyle from "./DeviceInfoDisplayStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof deviceInfoDisplayStyle> {
    device: DeviceInfo;
}

/**
 * Device info display.
 */
class DeviceInfoDisplay extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {};
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <div className={classes.infoColumn}>
                    {/* Device Id */}
                    <div
                        role="presentation"
                        aria-label="device info detail item"
                        className={classes.deviceDetailContainer}
                    >
                        {/* Title */}
                        <Typography variant={"body2"} className={classes.deviceDetailName}>
                            Device ID:
                        </Typography>

                        <Typography variant="body2" className={classes.deviceDetailValue}>
                            {this.props.device.receivedDeviceId}
                        </Typography>
                    </div>

                    {/* Last seen */}
                    <div
                        role="presentation"
                        aria-label="device info detail item"
                        className={classes.deviceDetailContainer}
                    >
                        {/* Title */}
                        <Typography variant={"body2"} className={classes.deviceDetailName}>
                            Last Seen:
                        </Typography>

                        <Typography variant="body2" className={classes.deviceDetailValue}>
                            <TimeAgo date={this.props.device.lastSeen} />
                        </Typography>
                    </div>

                    {/* First seen */}
                    <div
                        role="presentation"
                        aria-label="device info detail item"
                        className={classes.deviceDetailContainer}
                    >
                        {/* Title  */}
                        <Typography variant={"body2"} className={classes.deviceDetailName}>
                            First Seen:
                        </Typography>

                        <Typography variant="body2" className={classes.deviceDetailValue}>
                            <TimeAgo date={this.props.device.firstSeen} />
                        </Typography>
                    </div>

                    {/* IP */}
                    <div
                        role="presentation"
                        aria-label="device info detail item"
                        className={classes.deviceDetailContainer}
                    >
                        {/* Title */}
                        <Typography variant={"body2"} className={classes.deviceDetailName}>
                            Last Seen IP:
                        </Typography>

                        <Typography variant="body2" className={classes.deviceDetailValue}>
                            {this.props.device.ipAddress}
                        </Typography>
                    </div>
                </div>

                <div className={classes.infoColumn}>
                    {/* Firmware version */}
                    <div
                        role="presentation"
                        aria-label="device info detail item"
                        className={classes.deviceDetailContainer}
                    >
                        {/* Title */}
                        <Typography variant={"body2"} className={classes.deviceDetailName}>
                            Firmware Version:
                        </Typography>

                        <Typography variant="body2" className={classes.deviceDetailValue}>
                            {this.props.device.deviceDefinition.deviceFirmwareVersion}
                        </Typography>
                    </div>
                </div>

                <div className={classes.infoColumn}>
                    {/* OS */}
                    <div
                        role="presentation"
                        aria-label="device info detail item"
                        className={classes.deviceDetailContainer}
                    >
                        {/* Title */}
                        <Typography variant={"body2"} className={classes.deviceDetailName}>
                            Operating System:
                        </Typography>

                        <Typography variant="body2" className={classes.deviceDetailValue}>
                            {this.props.device.deviceDefinition.deviceOSFamily}
                        </Typography>
                    </div>

                    {/* CPU Bitness */}
                    <div
                        role="presentation"
                        aria-label="device info detail item"
                        className={classes.deviceDetailContainer}
                    >
                        {/* Title */}
                        <Typography variant={"body2"} className={classes.deviceDetailName}>
                            CPU Bitness:
                        </Typography>

                        <Typography variant="body2" className={classes.deviceDetailValue}>
                            {this.props.device.deviceDefinition.cpuBitness}
                        </Typography>
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(deviceInfoDisplayStyle)(DeviceInfoDisplay);
