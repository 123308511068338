import React, { useState } from "react";
import { History } from "history";
import classNames from "classnames";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Link, Typography } from "@material-ui/core";

import { useCommonStyle } from "../CommonStyle";
import { useSignupStyle } from "./SignupUserGuide.style";
import { SignupContainer } from "../Signup";
import { GlobalState } from "../../lib/state/GlobalState";
import { ProtectOpenWRTStep } from "./ProtectOpenWRTStep";
import { InstallProtectionStep } from "./InstallProtectionStep";
import { MonitorDeviceStep } from "./MonitorDeviceStep";
import ServiceWire from "../../lib/services/ServiceWire";
import { SignupUserGuideStep } from "../../lib/state/ClientInfo.types";
import { ExternalLinkIcon } from "../SUI/SternumIcon";

export interface SignupUserGuideProps {}

function getGuideProgressPercent(step: SignupUserGuideStep): number {
    const totalSteps = Object.keys(SignupUserGuideStep).length;
    const progressPerStep = 100 / totalSteps;

    if (step === SignupUserGuideStep.PrepareOpenWRT) {
        return progressPerStep;
    }

    if (step === SignupUserGuideStep.InstallProtection) {
        return progressPerStep * 2;
    }
    if (step === SignupUserGuideStep.MonitorDevice) {
        return progressPerStep * 3;
    }
}

const mapStateToProps = (state: GlobalState, ownProps: SignupUserGuideProps) => {
    const selectedClient = ServiceWire.getClientsService().getSelectedClient();

    return {
        initialOnboardingState: selectedClient.onboardingState,
        initialStep: selectedClient.getCurrentOnboardingStep(),
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

type SignupUserGuidePropsWithHOC = SignupUserGuideProps &
    ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps> & { history: History };

function SignupUserGuideComponent({ initialOnboardingState, initialStep, history }: SignupUserGuidePropsWithHOC) {
    const classes = useSignupStyle();
    const classesCommon = useCommonStyle();

    const [currentStep, setCurrentStep] = useState<SignupUserGuideStep>(initialStep);
    const [onboardingState, setOnboardingState] = useState(initialOnboardingState);

    const handleStepNextClick = (step: SignupUserGuideStep) => {
        const newOnboardingState = { steps: { ...onboardingState?.steps, [step]: true } };
        setOnboardingState(newOnboardingState);

        ServiceWire.getClientsService().updateClientOnboardingState(newOnboardingState);

        if (step === SignupUserGuideStep.PrepareOpenWRT) {
            setCurrentStep(SignupUserGuideStep.InstallProtection);
        }

        if (step === SignupUserGuideStep.InstallProtection) {
            setCurrentStep(SignupUserGuideStep.MonitorDevice);
        }

        if (step === SignupUserGuideStep.MonitorDevice) {
            history.push("/dashboard");
        }
    };

    const handleStepBackClick = (step: SignupUserGuideStep) => {
        if (step === SignupUserGuideStep.InstallProtection) {
            setCurrentStep(SignupUserGuideStep.PrepareOpenWRT);
        }

        if (step === SignupUserGuideStep.MonitorDevice) {
            setCurrentStep(SignupUserGuideStep.InstallProtection);
        }
    };

    const progressPercent = getGuideProgressPercent(currentStep);

    return (
        <SignupContainer>
            <div className={classes.content}>
                <div className={classes.innerContainer}>
                    <div>
                        <Typography className={classes.mainTitle}>Let's Get Started!</Typography>
                        <Typography className={classes.description}>
                            The installation guide below will help you get the most out of the Sternum Platform.
                            <br />
                            <br />
                            To access installation instructions and learn more about the Sternum Platform, go to{" "}
                            <Link
                                className={classNames(classes.linkButton, classesCommon.cursorPointer)}
                                component="a"
                                variant="body2"
                                href="/how-it-works"
                            >
                                Get Support <ExternalLinkIcon width={16} height={16} />
                            </Link>{" "}
                            page.
                        </Typography>
                    </div>

                    <div className={classes.progressContainer}>
                        <Typography className={classes.progressTitle}>Quick Installation Guide</Typography>

                        <div className={classes.progressBar}>
                            <div className={classes.progressTrack} style={{ width: `${progressPercent}%` }} />
                        </div>
                    </div>

                    <div className={classes.stepsContainer}>
                        <ProtectOpenWRTStep
                            stepNumber={1}
                            onNextClick={handleStepNextClick}
                            active={currentStep === SignupUserGuideStep.PrepareOpenWRT}
                            className={
                                currentStep === SignupUserGuideStep.PrepareOpenWRT
                                    ? classes.activeStep
                                    : classes.inactiveStep
                            }
                            completed={onboardingState?.steps?.[SignupUserGuideStep.PrepareOpenWRT]}
                        />
                        <InstallProtectionStep
                            stepNumber={2}
                            onNextClick={handleStepNextClick}
                            onBackClick={handleStepBackClick}
                            active={currentStep === SignupUserGuideStep.InstallProtection}
                            className={
                                currentStep === SignupUserGuideStep.InstallProtection
                                    ? classes.activeStep
                                    : classes.inactiveStep
                            }
                            completed={onboardingState?.steps?.[SignupUserGuideStep.InstallProtection]}
                        />
                        <MonitorDeviceStep
                            stepNumber={3}
                            onNextClick={handleStepNextClick}
                            onBackClick={handleStepBackClick}
                            active={currentStep === SignupUserGuideStep.MonitorDevice}
                            className={
                                currentStep === SignupUserGuideStep.MonitorDevice
                                    ? classes.activeStep
                                    : classes.inactiveStep
                            }
                            completed={onboardingState?.steps?.[SignupUserGuideStep.MonitorDevice]}
                        />
                    </div>
                </div>
            </div>
        </SignupContainer>
    );
}

export const SignupUserGuide: React.FC<SignupUserGuideProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SignupUserGuideComponent));
