import { Typography } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import HashMap from "../../lib/infra/HashMap";
import HashSet from "../../lib/infra/HashSet";
import SternumConfiguration from "../../lib/infra/SternumConfiguration";
import Utils from "../../lib/infra/Utils";
import AnalyticsService from "../../lib/services/AnalyticsService";
import ServiceWire from "../../lib/services/ServiceWire";
import ArgumentInfo from "../../lib/state/ArgumentInfo";
import EntityType from "../../lib/state/EntityType";
import { GlobalState } from "../../lib/state/GlobalState";
import SternumDeviceEventInfo from "../../lib/state/SternumDeviceEventInfo";
import SternumDeviceEventsFilter from "../../lib/state/SternumDeviceEventsFilter";
import SternumGeneratedEventInfo from "../../lib/state/SternumGeneratedEventInfo";
import TraceInfo from "../../lib/state/TraceInfo";
import { ArrowBackIcon } from "../SUI/SternumIcon/SternumIcon";
import eventsTimelineStyle from "./EventsTimelineStyle";
import TimelineBulletInfo from "./TimelineBulletInfo";

import moment from "moment";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    loadingEntities: boolean;
    errorLoadingEntities: boolean;
    pageNumberToPrecedingEntities: HashMap<SternumDeviceEventInfo[]>;
    pageNumberToLeadingEntities: HashMap<SternumDeviceEventInfo[]>;
    timelineBullets: TimelineBulletInfo[];
    hasMorePrecedingEntities: boolean;
    hasMoreLeadingEntities: boolean;
    popoverTimelineBullet: TimelineBulletInfo;
    popoverTimelineBulletIndex: number;
    popoverAnchorElement: any;
    /**
     * used to move forward
     */
    preceedingCursor: string;
    /**
     * used to move backwards
     */
    leadingCursor: string;

    loadingRelatedEntities: boolean;
    relatedTraces: TraceInfo[];
    relatedGeneratedEvents: SternumGeneratedEventInfo[];
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof eventsTimelineStyle> {
    sternumDeviceEventInfo: SternumDeviceEventInfo;
    isIssue: boolean;
    hideTimelineIndexContainer?: boolean;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {};
};

class EventsTimeline extends React.Component<AppProps, AppState> {
    /**
     * Holds the page number we're currently viewing.
     */
    private pageNumber: number = 1;

    /**
     * Indicates whether we should fetch preceding or leading traces.
     */
    private fetchPrecedingTraces: boolean = false;

    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            loadingEntities: false,
            errorLoadingEntities: false,
            pageNumberToPrecedingEntities: new HashMap<SternumDeviceEventInfo[]>(),
            pageNumberToLeadingEntities: new HashMap<SternumDeviceEventInfo[]>(),
            timelineBullets: [],
            popoverTimelineBullet: null,
            popoverTimelineBulletIndex: null,
            popoverAnchorElement: null,
            hasMorePrecedingEntities: false,
            hasMoreLeadingEntities: false,
            preceedingCursor: "",
            leadingCursor: "",
            loadingRelatedEntities: false,
            relatedTraces: null,
            relatedGeneratedEvents: null,
        };
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        if (this.state.loadingEntities) {
            return this.getLoadingPlaceholder();
        } else if (this.state.errorLoadingEntities) {
            return (
                <div>
                    <Typography variant="body2">There was an error loading timeline.</Typography>
                </div>
            );
        } else {
            return this.getTimelineComponent();
        }
    }

    /**
     * Gets the content of the popover for a timeline bullet.
     */
    private getPopoverContent(timelineBullet: TimelineBulletInfo) {
        const { classes } = this.props;

        if (!timelineBullet) {
            return;
        }

        if (timelineBullet.sternumDeviceEvent.traceInfo) {
            return this.renderTracePopover(timelineBullet, classes);
        } else if (timelineBullet.sternumDeviceEvent.sternumGeneratedEventInfo) {
            if (!timelineBullet.highlightedBullet) {
                if (this.state.loadingRelatedEntities) {
                    return (
                        <div>
                            {this.getGeneratedEventPopoverTitle(timelineBullet, classes)}

                            {/* Leading title */}
                            <Typography className={classNames(classes.timelineBulletLabelContent, classes.marginTop)}>
                                Loading related events...
                            </Typography>
                        </div>
                    );
                } else {
                    return this.renderGeneratedEventPopover(timelineBullet, classes);
                }
            } else {
                return <div>{this.getGeneratedEventPopoverTitle(timelineBullet, classes)}</div>;
            }
        }
    }

    /**
     * Renders the popover content for a generated event.
     */
    private renderGeneratedEventPopover(timelineBullet: TimelineBulletInfo, classes) {
        return (
            <div>
                {this.getGeneratedEventPopoverTitle(timelineBullet, classes)}

                {/* Leading title */}
                <Typography className={classNames(classes.timelineBulletLabelContent, classes.marginTop)}>
                    Related events:
                </Typography>

                {/* Traces */}
                {this.state.relatedTraces &&
                    this.state.relatedTraces
                        .filter((ignored, index) => {
                            return index < 5;
                        })
                        .map((trace) => {
                            return (
                                <Typography className={classNames(classes.timelineBulletLabelContent)}>
                                    {trace.traceDefinition.displayName} ({moment(trace.created).format("HH:mm:ss")})
                                </Typography>
                            );
                        })}

                {/* Sternum generated events*/}
                {this.state.relatedGeneratedEvents &&
                    this.state.relatedGeneratedEvents
                        .filter((ignored, index) => {
                            return index < 5;
                        })
                        .map((sternumGeneratedEvent) => {
                            return (
                                <Typography className={classNames(classes.timelineBulletLabelContent)}>
                                    {sternumGeneratedEvent.sternumTrigger.displayName}
                                </Typography>
                            );
                        })}
            </div>
        );
    }

    /**
     * Gets the title for the popover of generated event.
     */
    private getGeneratedEventPopoverTitle(timelineBullet: TimelineBulletInfo, classes) {
        return (
            <Typography key={timelineBullet.identifier} className={classNames(classes.timelineBulletLabelContent)}>
                {timelineBullet.sternumDeviceEvent.sternumGeneratedEventInfo.sternumTrigger.description}
            </Typography>
        );
    }

    /**
     * Renders the popover content for displaying a trace.
     */
    private renderTracePopover(timelineBullet: TimelineBulletInfo, classes) {
        const filteredTraceArguments = this.filterTraceArguments(
            timelineBullet.sternumDeviceEvent.traceInfo.orderedTraceArguments
        );

        return (
            <React.Fragment key={timelineBullet.identifier}>
                {/* Event Name */}
                <div className={classes.flexVMiddle}>
                    {/* Title */}
                    <Typography
                        variant={"body2"}
                        className={classNames(
                            classes.marginRightXs,
                            classes.timelineBulletLabelContent,
                            "mod-selected"
                        )}
                    >
                        Event:
                    </Typography>

                    <Typography className={classNames(classes.timelineBulletLabelContent)}>
                        {timelineBullet.sternumDeviceEvent.traceInfo.traceDefinition.displayName}
                    </Typography>
                </div>

                {filteredTraceArguments
                    .filter((argumentInfo) => {
                        let argumentRoleTypeConfiguration = SternumConfiguration.getArgumentRoleTypeConfigurationObject(
                            argumentInfo.argumentDefinition.argumentEventName,
                            timelineBullet.sternumDeviceEvent.traceInfo.traceDefinition.traceEventName
                        );

                        return (
                            !argumentRoleTypeConfiguration ||
                            !argumentRoleTypeConfiguration.shouldBeDisplayedInTraceView ||
                            argumentRoleTypeConfiguration.shouldBeDisplayedInTraceView(
                                timelineBullet.sternumDeviceEvent.traceInfo
                            )
                        );
                    })
                    .map((argumentInfo, index) => {
                        let argumentRoleTypeConfiguration = SternumConfiguration.getArgumentRoleTypeConfigurationObject(
                            argumentInfo.argumentDefinition.argumentEventName,
                            timelineBullet.sternumDeviceEvent.traceInfo.traceDefinition.traceEventName
                        );

                        return (
                            <div key={index} className={classes.flexVMiddle}>
                                {/* Title */}
                                <Typography
                                    variant={"body2"}
                                    className={classNames(
                                        classes.marginRightXs,
                                        classes.timelineBulletLabelContent,
                                        "mod-selected"
                                    )}
                                >
                                    {argumentRoleTypeConfiguration
                                        ? argumentRoleTypeConfiguration.getDisplayName(
                                              timelineBullet.sternumDeviceEvent.traceInfo
                                          )
                                        : argumentInfo.argumentDefinition.displayName}
                                    :
                                </Typography>

                                <Typography className={classNames(classes.timelineBulletLabelContent)}>
                                    {argumentRoleTypeConfiguration && argumentRoleTypeConfiguration.extractValue
                                        ? argumentRoleTypeConfiguration.extractValue(argumentInfo.argumentValue)
                                        : argumentInfo.displayValue}
                                </Typography>
                            </div>
                        );
                    })}
            </React.Fragment>
        );
    }

    /**
     * Filters the given trace arguments to only include what's relevant.
     */
    private filterTraceArguments(traceArguments: ArgumentInfo[]) {
        return traceArguments.filter(
            (traceArgument) => traceArgument.argumentDefinition.argumentEventName !== "SYSTEM_ARG_ROLE_ACTION_STATUS"
        );
    }

    /**
     * Returns the timeline display.
     */
    private getTimelineComponent() {
        const { classes } = this.props;

        return (
            <>
                {this.state.timelineBullets.filter((bullet) => !bullet.isEmptyBullet).length === 0 && (
                    <Typography variant="body2">No more events found</Typography>
                )}

                <div className={classNames(classes.timelineContainer)}>
                    <Popover
                        id="explanation-popover"
                        className={classNames(
                            classes.popoverElement,
                            this.state.popoverTimelineBulletIndex % 2 === 0 ? "mod-top" : "mod-bottom"
                        )}
                        open={!!this.state.popoverAnchorElement}
                        anchorEl={this.state.popoverAnchorElement}
                        onClose={() => this.handlePopoverClosed()}
                        elevation={6}
                        anchorOrigin={{
                            vertical: this.state.popoverTimelineBulletIndex % 2 === 0 ? "top" : "bottom",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: this.state.popoverTimelineBulletIndex % 2 === 0 ? "bottom" : "top",
                            horizontal: "center",
                        }}
                    >
                        <div
                            className={classNames(
                                classes.popoverContentContainer,
                                this.state.popoverTimelineBullet &&
                                    this.state.popoverTimelineBullet.sternumDeviceEvent.traceInfo &&
                                    "mod-bigger"
                            )}
                        >
                            {this.getPopoverContent(this.state.popoverTimelineBullet)}
                        </div>
                    </Popover>

                    {/* Previous Page Icon */}
                    <ArrowBackIcon
                        className={classNames(
                            classes.previousPageLink,
                            this.state.hasMorePrecedingEntities && classes.visibilityVisible,
                            !this.state.hasMorePrecedingEntities && classes.visibilityHidden
                        )}
                        onClick={() => !this.state.loadingEntities && this.goBackInTimeline()}
                    />

                    {/* Creating a preceding separator and a bullet for all timeline bullets we have */}
                    {this.state.timelineBullets.map((timelineBullet, index) => {
                        let eventInterest =
                            !timelineBullet.isEmptyBullet && timelineBullet.sternumDeviceEvent.getEventInterest();

                        return (
                            <React.Fragment key={index}>
                                {/* Separator */}
                                <div
                                    className={classNames(
                                        classes.separator,
                                        (timelineBullet.selected ||
                                            timelineBullet.highlightedBullet ||
                                            (index - 1 < this.state.timelineBullets.length &&
                                                index - 1 >= 0 &&
                                                this.state.timelineBullets[index - 1].selected)) &&
                                            "mod-selected"
                                    )}
                                />

                                {/* Timeline bullet */}
                                <div
                                    className={classNames(classes.timelineBulletContainer)}
                                    onClick={(event) => this.handleTimelineBulletClicked(event, index, timelineBullet)}
                                >
                                    {/* Bullet */}
                                    <div
                                        className={classNames(
                                            classes.timelineBullet,
                                            timelineBullet.isEmptyBullet && "mod-empty",
                                            (timelineBullet.selected || timelineBullet.highlightedBullet) &&
                                                "mod-selected",
                                            classes.cursorPointer,
                                            "mod-hover-enabled",
                                            eventInterest && eventInterest.toLowerCase() === "high" && "mod-high",
                                            eventInterest && eventInterest.toLowerCase() === "medium" && "mod-medium",
                                            eventInterest && eventInterest.toLowerCase() === "low" && "mod-low",
                                            eventInterest &&
                                                eventInterest.toLowerCase() !== "high" &&
                                                eventInterest.toLowerCase() !== "medium" &&
                                                eventInterest.toLowerCase() !== "low" &&
                                                "mod-regular"
                                        )}
                                    />

                                    {/* Line leading to the label */}
                                    {!timelineBullet.isEmptyBullet && (
                                        <div
                                            className={classNames(
                                                classes.timelineBulletTooltipLine,
                                                classes.cursorPointer,
                                                eventInterest.toLowerCase() !== "regular" && "mod-hover-enabled",
                                                index % 2 === 0 ? "mod-bottom" : "mod-top",
                                                (timelineBullet.selected || timelineBullet.highlightedBullet) &&
                                                    "mod-selected"
                                            )}
                                        />
                                    )}

                                    {/* Label */}
                                    {!timelineBullet.isEmptyBullet && (
                                        <div
                                            className={classNames(
                                                classes.timelineBulletLabelContainer,
                                                classes.cursorPointer,
                                                eventInterest.toLowerCase() !== "regular" && "mod-hover-enabled",
                                                index % 2 === 0 ? "mod-bottom" : "mod-top"
                                            )}
                                        >
                                            {this.getTimelineBulletLabelComponent(timelineBullet)}
                                        </div>
                                    )}

                                    {/* Time label*/}
                                    {!timelineBullet.isEmptyBullet && timelineBullet.timeLabel && (
                                        <div
                                            className={classNames(
                                                classes.cursorPointer,
                                                eventInterest.toLowerCase() !== "regular" && "mod-hover-enabled",
                                                classes.timelineBulletTimestampContainer,
                                                index % 2 === 0 ? "mod-top" : "mod-bottom"
                                            )}
                                        >
                                            <Typography
                                                className={classNames(
                                                    classes.timelineBulletLabelContent,
                                                    (timelineBullet.selected || timelineBullet.highlightedBullet) &&
                                                        "mod-selected"
                                                )}
                                            >
                                                {timelineBullet.timeLabel}
                                            </Typography>
                                        </div>
                                    )}
                                </div>
                            </React.Fragment>
                        );
                    })}

                    {/* Separator at the end of the timeline */}
                    <div
                        className={classNames(
                            classes.separator,
                            this.state.timelineBullets[this.state.timelineBullets.length - 1]?.selected &&
                                "mod-selected"
                        )}
                    />

                    {/* Next Page */}
                    <ArrowBackIcon
                        className={classNames(
                            classes.nextPageLink,
                            this.state.hasMoreLeadingEntities && classes.visibilityVisible,
                            !this.state.hasMoreLeadingEntities && classes.visibilityHidden
                        )}
                        onClick={() => !this.state.loadingEntities && this.goFurtherInTimeline()}
                    />
                </div>

                {/* Timeline Index Container */}
                {!this.props.hideTimelineIndexContainer && (
                    <div
                        className={classNames(
                            classes.timelineIndexContainer,
                            this.props.sternumDeviceEventInfo.getTraceType() == "Trace" && "mod-attack",
                            this.props.sternumDeviceEventInfo.getTraceType() == "Event" && "mod-generated-event",
                            classes.flexVMiddle
                        )}
                    >
                        {/* High */}
                        <div
                            className={classNames(
                                classes.flexVMiddle,
                                classes.marginBottomXs,
                                classes.marginRightLarge
                            )}
                        >
                            {/* Bullet */}
                            <div className={classNames(classes.timelineLegendBullet, "mod-high")} />

                            {/* Text */}
                            <Typography className={classNames(classes.timelineBulletLabelContent, classes.marginLeft)}>
                                High
                            </Typography>
                        </div>

                        {/* Medium */}
                        <div
                            className={classNames(
                                classes.flexVMiddle,
                                classes.marginBottomXs,
                                classes.marginRightLarge
                            )}
                        >
                            {/* Bullet */}
                            <div className={classNames(classes.timelineLegendBullet, "mod-medium")} />

                            {/* Text */}
                            <Typography className={classNames(classes.timelineBulletLabelContent, classes.marginLeft)}>
                                Medium
                            </Typography>
                        </div>

                        {/* Low */}
                        <div
                            className={classNames(
                                classes.flexVMiddle,
                                classes.marginBottomXs,
                                classes.marginRightLarge
                            )}
                        >
                            {/* Bullet */}
                            <div className={classNames(classes.timelineLegendBullet, "mod-low")} />

                            {/* Text */}
                            <Typography className={classNames(classes.timelineBulletLabelContent, classes.marginLeft)}>
                                Low
                            </Typography>
                        </div>

                        {/* Regular */}
                        <div
                            className={classNames(
                                classes.flexVMiddle,
                                classes.marginBottomXs,
                                classes.marginRightLarge
                            )}
                        >
                            {/* Bullet */}
                            <div className={classNames(classes.timelineLegendBullet, "mod-regular")} />

                            {/* Text */}
                            <Typography className={classNames(classes.timelineBulletLabelContent, classes.marginLeft)}>
                                Regular
                            </Typography>
                        </div>
                    </div>
                )}
            </>
        );
    }

    /**
     * Goes back a page in the timeline.
     */
    private async goBackInTimeline() {
        if (this.pageNumber === 1) {
            // If we're on the first page, if we go back in timeline, that means we go into fetch preceding
            // traces mode.
            this.pageNumber = this.pageNumber + 1;
            this.fetchPrecedingTraces = true;
        } else {
            // Otherwise, we're either in an advanced page of preceding traces or an advanced page of leading traces.

            if (this.fetchPrecedingTraces) {
                // If we are in an advanced page of preceding traces, we increment the page size.
                this.pageNumber = this.pageNumber + 1;
            } else {
                // Otherwise, we're in an advanced page of leading traces, we decrease the page size, as we go back.
                this.pageNumber = this.pageNumber - 1;
            }
        }

        // If after all evaluation, we ended up at the first page, we set the fetchPrecedingTraces to false as
        // this is the default state.
        if (this.pageNumber === 1) {
            this.fetchPrecedingTraces = false;
        }

        await this.loadEntities(this.pageNumber, this.fetchPrecedingTraces);
    }

    /**
     * Goes further a page in the timeline.
     */
    private async goFurtherInTimeline() {
        if (this.pageNumber === 1) {
            // If we're on the first page, if we go further in timeline, that means we're not fetching preceding
            // traces, but rather fetching leading traces - meaning fetchPrecedingTraces should be false.
            this.pageNumber = this.pageNumber + 1;
            this.fetchPrecedingTraces = false;
        } else {
            // Otherwise, we're either in an advanced page of preceding traces or an advanced page of leading traces.

            if (this.fetchPrecedingTraces) {
                // If we are in an advanced page of preceding traces, we decrease the page size.
                this.pageNumber = this.pageNumber - 1;
            } else {
                // Otherwise, we're in an advanced page of leading traces, we increate the page size, as we go even
                // more further.
                this.pageNumber = this.pageNumber + 1;
            }
        }

        // If after all evaluation, we ended up at the first page, we set the fetchPrecedingTraces to false as
        // this is the default state.
        if (this.pageNumber === 1) {
            this.fetchPrecedingTraces = false;
        }

        await this.loadEntities(this.pageNumber, this.fetchPrecedingTraces);
    }

    /**
     * Truncates the given label to a size that fits the square of the label.
     */
    private truncateTimelineLabel(label: string): string {
        if (!label) {
            return label;
        } else {
            let labelString = label.toString();

            let maxLength = 30;
            if (labelString.length > maxLength) {
                return labelString.substring(0, maxLength - 3) + "...";
            } else {
                return labelString;
            }
        }
    }

    /**
     * Gets the display name of the timeline bullet.
     */
    private getTimelineBulletLabelComponent(timelineBullet: TimelineBulletInfo) {
        const { classes } = this.props;

        if (timelineBullet.sternumDeviceEvent.traceInfo) {
            let traceEventName = timelineBullet.sternumDeviceEvent.traceInfo.traceDefinition.traceEventName;

            if (traceEventName === "EXPLOITATION_ALERT") {
                const exploitationTypeArgument: ArgumentInfo =
                    timelineBullet.sternumDeviceEvent.traceInfo.traceArguments["EXPLOITATION_TYPE"];
                const functionNameArgument: ArgumentInfo =
                    timelineBullet.sternumDeviceEvent.traceInfo.traceArguments["ARG_ROLE_NAME"];

                if (exploitationTypeArgument) {
                    if (functionNameArgument) {
                        return (
                            <div className={classNames(classes.multiLineTimelineBulletLabelContainer)}>
                                <Typography
                                    className={classNames(
                                        classes.timelineBulletLabelContent,
                                        (timelineBullet.selected || timelineBullet.highlightedBullet) && "mod-selected"
                                    )}
                                >
                                    {this.truncateTimelineLabel(
                                        `${exploitationTypeArgument.displayValue} at ${functionNameArgument.displayValue}`
                                    )}
                                </Typography>
                            </div>
                        );
                    } else {
                        return (
                            <div className={classNames(classes.multiLineTimelineBulletLabelContainer)}>
                                <Typography
                                    className={classNames(
                                        classes.timelineBulletLabelContent,
                                        (timelineBullet.selected || timelineBullet.highlightedBullet) && "mod-selected"
                                    )}
                                >
                                    {this.truncateTimelineLabel(exploitationTypeArgument.displayValue)}
                                </Typography>
                            </div>
                        );
                    }
                }
            } else if (traceEventName === "TRACE_BOOT") {
                const processNameArgument: ArgumentInfo =
                    timelineBullet.sternumDeviceEvent.traceInfo.traceArguments["ARG_ROLE_NAME"];

                if (processNameArgument) {
                    return (
                        <Typography
                            className={classNames(
                                classes.timelineBulletLabelContent,
                                (timelineBullet.selected || timelineBullet.highlightedBullet) && "mod-selected"
                            )}
                        >
                            {processNameArgument.displayValue} Boot
                        </Typography>
                    );
                }
            } else {
                const argumentsDisplayList = [];

                let filteredTraceArguments = this.filterTraceArguments(
                    timelineBullet.sternumDeviceEvent.traceInfo.orderedTraceArguments
                );

                for (let i = 0; i < 2; i++) {
                    const traceArgument = filteredTraceArguments[i];

                    if (traceArgument) {
                        argumentsDisplayList.push(
                            <Typography
                                key={i}
                                className={classNames(
                                    classes.timelineBulletLabelContent,
                                    classes.timelineEllipsis,
                                    classes.bulletArgumentInfo,
                                    (timelineBullet.selected || timelineBullet.highlightedBullet) && "mod-selected"
                                )}
                            >
                                {`${traceArgument.argumentDefinition.displayName}: ${traceArgument.displayValue}`}
                            </Typography>
                        );
                    }
                }

                if (filteredTraceArguments.length > 2) {
                    argumentsDisplayList.push(
                        <Typography
                            className={classNames(
                                classes.bulletArgumentInfo,
                                classes.flexNoShrink,
                                (timelineBullet.selected || timelineBullet.highlightedBullet) && "mod-selected"
                            )}
                        >
                            +{filteredTraceArguments.length - 2} more
                        </Typography>
                    );
                }

                if (argumentsDisplayList.length) {
                    return (
                        <div className={classNames(classes.multiLineTimelineBulletLabelContainer)}>
                            <Typography
                                className={classNames(
                                    classes.timelineBulletLabelContent,
                                    classes.timelineEllipsis,
                                    (timelineBullet.selected || timelineBullet.highlightedBullet) && "mod-selected"
                                )}
                            >
                                {timelineBullet.sternumDeviceEvent.getDisplayName()}
                            </Typography>
                            {argumentsDisplayList}
                        </div>
                    );
                }
            }
        }

        return (
            <Typography
                className={classNames(
                    classes.timelineBulletLabelContent,
                    (timelineBullet.selected || timelineBullet.highlightedBullet) && "mod-selected"
                )}
            >
                {timelineBullet.sternumDeviceEvent.getDisplayName()}
            </Typography>
        );
    }

    private getLoadingPlaceholder() {
        const { classes } = this.props;

        return (
            <div className={classNames(classes.timelineContainer)}>
                {/* Previous Page */}
                <ArrowBackIcon className={classNames(classes.previousPageLink)} />

                {[
                    { isEmptyBullet: false },
                    { isEmptyBullet: true },
                    { isEmptyBullet: true },
                    { isEmptyBullet: false },
                    { isEmptyBullet: true },
                    { isEmptyBullet: true },
                    { isEmptyBullet: false },
                    { isEmptyBullet: true },
                    { isEmptyBullet: true },
                    { isEmptyBullet: false },
                ].map((timelineBullet, index) => {
                    return (
                        <React.Fragment key={index}>
                            {/* Separator */}
                            <div className={classNames(classes.separator)} />

                            {/* Timeline bullet */}
                            <div className={classNames(classes.timelineBulletContainer)}>
                                {/* Bullet */}
                                <div
                                    className={classNames(
                                        classes.timelineBullet,
                                        "mod-regular",
                                        timelineBullet.isEmptyBullet && "mod-empty"
                                    )}
                                />

                                {/* Line leading to the label */}
                                {!timelineBullet.isEmptyBullet && (
                                    <div
                                        className={classNames(
                                            classes.timelineBulletTooltipLine,
                                            index % 2 === 0 ? "mod-bottom" : "mod-top"
                                        )}
                                    />
                                )}

                                {/* Label */}
                                {!timelineBullet.isEmptyBullet && (
                                    <div
                                        className={classNames(
                                            classes.timelineBulletLabelContainer,
                                            index % 2 === 0 ? "mod-bottom" : "mod-top"
                                        )}
                                    >
                                        <div className={classNames(classes.loadingPlaceholder, "mod-small-column")} />
                                    </div>
                                )}
                            </div>
                        </React.Fragment>
                    );
                })}

                {/* Separator */}
                <div className={classNames(classes.separator)} />

                {/* Next Page */}
                <ArrowBackIcon className={classNames(classes.nextPageLink)} />
            </div>
        );
    }

    /**
     * Occurs once the component is loaded.
     */
    async componentDidMount() {
        await this.loadEntities(this.pageNumber, this.fetchPrecedingTraces);
    }

    /**
     * Handles the click of a timeline bullet.
     */
    private async handleTimelineBulletClicked(event, index: number, timelineBullet: TimelineBulletInfo) {
        this.setState({
            popoverTimelineBullet: timelineBullet,
            popoverTimelineBulletIndex: index,
            popoverAnchorElement: event.currentTarget,
            loadingRelatedEntities: !!timelineBullet.sternumDeviceEvent.sternumGeneratedEventInfo,
        });

        // If it's not the currently highlighted bullet and it's a generated event, we fetch its related entities.
        if (!timelineBullet.highlightedBullet && timelineBullet.sternumDeviceEvent.sternumGeneratedEventInfo) {
            const entities = await ServiceWire.getSternumService().getEventRelatedEntities(
                timelineBullet.sternumDeviceEvent.sternumGeneratedEventInfo.sternumGeneratedEventId
            );

            const relatedTraces: TraceInfo[] = [];
            const relatedGeneratedEvents: SternumGeneratedEventInfo[] = [];

            entities.forEach((entity) => {
                if (entity.entityType === EntityType.Trace) {
                    relatedTraces.push(entity);
                } else if (entity.entityType === EntityType.SternumGeneratedEvent) {
                    relatedGeneratedEvents.push(entity);
                }
            });

            this.setState({
                loadingRelatedEntities: false,
                relatedTraces: relatedTraces,
                relatedGeneratedEvents: relatedGeneratedEvents,
            });
        }
    }

    /**
     * Loads entities for the timeline.
     */
    private async loadEntities(pageNumber: number, fetchPrecedingTraces: boolean) {
        try {
            let shouldFetchPrecedingEntities = fetchPrecedingTraces || pageNumber === 1;
            let shouldFetchLeadingEntities = !fetchPrecedingTraces || pageNumber === 1;
            let shouldLoadPreceding = this.isFetchingPrecedingEntitiesNeeded(pageNumber, shouldFetchPrecedingEntities);
            let shouldLoadLeading = this.isFetchingLeadingEntitiesNeeded(pageNumber, shouldFetchLeadingEntities);

            this.setState({
                loadingEntities: shouldLoadPreceding || shouldLoadLeading,
                errorLoadingEntities: false,
            });

            const pageSize: number = 10;

            let copiedPageNumberToPrecedingEntitiesMap = this.state.pageNumberToPrecedingEntities;
            let copiedPageNumberToLeadingEntitiesMap = this.state.pageNumberToLeadingEntities;

            let [[precedingEntities, preceedingCursor], [leadingEntities, leadingCursor]] = await Promise.all([
                // Preceding entities.
                // We should only fetch preceding entities if we're requested to, or the current page number is 1 -
                // meaning we're displaying the currently selected event.
                this.fetchPrecedingEntitiesIfNeeded(pageNumber, pageSize, shouldFetchPrecedingEntities),
                // Leading entities.
                // We should only fetch leading entities if we're requested to, or the current page number is 1 -
                // meaning we're displaying the currently selected event.
                this.fetchLeadingEntitiesIfNeeded(pageNumber, pageSize, shouldFetchLeadingEntities),
            ]);

            // Creating a copy of the altered map in state.
            if (precedingEntities) {
                copiedPageNumberToPrecedingEntitiesMap = HashMap.copyAndPut(
                    copiedPageNumberToPrecedingEntitiesMap,
                    pageNumber.toString(),
                    precedingEntities
                );
            }

            // Creating a copy of the altered map in state.
            if (leadingEntities) {
                copiedPageNumberToLeadingEntitiesMap = HashMap.copyAndPut(
                    copiedPageNumberToLeadingEntitiesMap,
                    pageNumber.toString(),
                    leadingEntities
                );
            }

            if (pageNumber === 1) {
                let amountOfUsedPreceding: number = 0;
                let amountOfUsedLeading: number = 0;

                // Constructing the displayed events, keeping the currently viewed event at the end of the list.
                let displayedEntities: SternumDeviceEventInfo[] = [];

                // Preceding
                if (precedingEntities && precedingEntities.length) {
                    for (
                        let i = Math.max(precedingEntities.length - pageSize + 1, 0);
                        i >= 0 && i < precedingEntities.length;
                        i++
                    ) {
                        displayedEntities.push(precedingEntities[i]);
                        amountOfUsedPreceding++;
                    }
                }

                // Highlighted event
                displayedEntities.push(this.props.sternumDeviceEventInfo);

                // Leading
                if (leadingEntities && leadingEntities.length) {
                    let currentDisplayedEntitiesLength = displayedEntities.length;
                    for (
                        let i = 0;
                        i < leadingEntities.length && i < leadingEntities.length - currentDisplayedEntitiesLength - 1;
                        i++
                    ) {
                        displayedEntities.push(leadingEntities[i]);
                        amountOfUsedLeading++;
                    }
                }

                // Finally, setting the state with the displayed bullets and additional needed information.
                this.setState({
                    loadingEntities: false,
                    errorLoadingEntities: false,
                    pageNumberToPrecedingEntities: copiedPageNumberToPrecedingEntitiesMap,
                    pageNumberToLeadingEntities: copiedPageNumberToLeadingEntitiesMap,
                    timelineBullets: this.createTimelineBullets(displayedEntities),
                    hasMorePrecedingEntities: precedingEntities.length - amountOfUsedPreceding > 0,
                    hasMoreLeadingEntities: leadingEntities.length - amountOfUsedLeading > 0,
                    preceedingCursor: preceedingCursor || this.state.preceedingCursor,
                    leadingCursor: leadingCursor || this.state.leadingCursor,
                });
            } else {
                if (fetchPrecedingTraces) {
                    // If we're requested to fetch preceding traces, that means we're looking at another page of
                    // preceding traces.

                    let currentPageOfPrecedingEntities = copiedPageNumberToPrecedingEntitiesMap.get(
                        pageNumber.toString()
                    );

                    let displayedEntities = [];
                    if (currentPageOfPrecedingEntities.length > pageSize) {
                        displayedEntities = Utils.takePartCollection(currentPageOfPrecedingEntities, 1, pageSize);
                    } else {
                        displayedEntities = currentPageOfPrecedingEntities;
                    }

                    this.setState({
                        loadingEntities: false,
                        errorLoadingEntities: false,
                        pageNumberToPrecedingEntities: copiedPageNumberToPrecedingEntitiesMap,
                        pageNumberToLeadingEntities: copiedPageNumberToLeadingEntitiesMap,
                        timelineBullets: this.createTimelineBullets(displayedEntities, true, true, pageSize),
                        hasMorePrecedingEntities: currentPageOfPrecedingEntities.length > pageSize,
                        hasMoreLeadingEntities: pageNumber > 1,
                        preceedingCursor: preceedingCursor || this.state.preceedingCursor,
                    });
                } else {
                    // Otherwise, we're looking at another page of leading traces.
                    // Note that unlike preceding traces, here we take one page back when displaying leading traces,
                    // since in the first page no leading traces were included.
                    let currentPageLeadingEntities = copiedPageNumberToLeadingEntitiesMap.get(pageNumber.toString());

                    let displayedEntities = Utils.takePartCollection(currentPageLeadingEntities, 0, pageSize);

                    this.setState({
                        loadingEntities: false,
                        errorLoadingEntities: false,
                        pageNumberToPrecedingEntities: copiedPageNumberToPrecedingEntitiesMap,
                        pageNumberToLeadingEntities: copiedPageNumberToLeadingEntitiesMap,
                        timelineBullets: this.createTimelineBullets(displayedEntities, true, false, pageSize),
                        hasMorePrecedingEntities: pageNumber > 1,
                        hasMoreLeadingEntities: currentPageLeadingEntities.length > pageSize,
                        leadingCursor: leadingCursor || this.state.leadingCursor,
                    });
                }
            }
        } catch (error) {
            AnalyticsService.error("EventsTimeline:loadEntities", error.message);

            this.setState({
                loadingEntities: false,
                errorLoadingEntities: true,
            });
        }
    }

    /**
     * Returns whether the fetching of preceding entities is needed.
     */
    private isFetchingPrecedingEntitiesNeeded(pageNumber: number, shouldFetch: boolean): boolean {
        return shouldFetch && !this.state.pageNumberToPrecedingEntities.containsKey(pageNumber.toString());
    }

    /**
     * Returns whether the fetching of leading entities is needed.
     */
    private isFetchingLeadingEntitiesNeeded(pageNumber: number, shouldFetch: boolean): boolean {
        return (
            shouldFetch &&
            !this.state.pageNumberToLeadingEntities.containsKey(
                (pageNumber === 1 ? pageNumber : pageNumber - 1).toString()
            )
        );
    }

    /**
     * Fetches the preceding entities if they aren't already fetched.
     */
    private async fetchPrecedingEntitiesIfNeeded(
        pageNumber: number,
        pageSize: number,
        shouldFetch: boolean
    ): Promise<[SternumDeviceEventInfo[] | null, string]> {
        const processName: string | null = this.props.isIssue
            ? this.props.sternumDeviceEventInfo.getProcessName()
            : null;

        if (shouldFetch) {
            if (this.state.pageNumberToPrecedingEntities.containsKey(pageNumber.toString())) {
                return [this.state.pageNumberToPrecedingEntities.get(pageNumber.toString()), ""];
            } else {
                let lessThanId =
                    this.props.sternumDeviceEventInfo.traceInfo?.entityId ||
                    this.props.sternumDeviceEventInfo.sternumGeneratedEventInfo?.entityId;

                const response = await ServiceWire.getSternumService().getDeviceSternumDeviceEvents(
                    this.props.sternumDeviceEventInfo.device.entityId,
                    new SternumDeviceEventsFilter(null, false, null, null, lessThanId, null, null, false, null, null),
                    null,
                    "desc",
                    [],
                    this.state.preceedingCursor,
                    pageSize + 1,
                    null,
                    false,
                    processName
                );

                // We are fetching preceding entities in a descending order, so we must sort them back
                // into ascending order once they arrive.
                let sortedEntities = Utils.sortCollection(
                    response.sternumDeviceEvents as SternumDeviceEventInfo[],
                    (sternumDeviceEvent) => sternumDeviceEvent.entityIdLong
                );

                return [sortedEntities, response.cursor];
            }
        } else {
            return [null, ""];
        }
    }

    /**
     * Fetches the leading entities if they aren't already fetched.
     */
    private async fetchLeadingEntitiesIfNeeded(
        pageNumber: number,
        pageSize: number,
        shouldFetch: boolean
    ): Promise<[SternumDeviceEventInfo[] | null, string]> {
        const processName: string | null = this.props.isIssue
            ? this.props.sternumDeviceEventInfo.getProcessName()
            : null;

        if (shouldFetch) {
            if (this.state.pageNumberToLeadingEntities.containsKey(pageNumber.toString())) {
                return [this.state.pageNumberToLeadingEntities.get(pageNumber.toString()), ""];
            } else {
                let greaterThanId =
                    this.props.sternumDeviceEventInfo.traceInfo?.entityId ||
                    this.props.sternumDeviceEventInfo.sternumGeneratedEventInfo?.entityId;

                const response = await ServiceWire.getSternumService().getDeviceSternumDeviceEvents(
                    this.props.sternumDeviceEventInfo.device.entityId,
                    new SternumDeviceEventsFilter(
                        null,
                        false,
                        null,
                        null,
                        null,
                        greaterThanId,
                        null,
                        false,
                        null,
                        null
                    ),
                    null,
                    "asc",
                    [],
                    this.state.leadingCursor,
                    pageSize + 1,
                    null,
                    false,
                    processName
                );

                return [response.sternumDeviceEvents as SternumDeviceEventInfo[], response.cursor];
            }
        } else {
            return [null, ""];
        }
    }

    /**
     * Creates the timeline bullets for the given entities.
     */
    private createTimelineBullets(
        entities: SternumDeviceEventInfo[],
        shouldPad?: boolean,
        padLeft?: boolean,
        pageSize?: number
    ): TimelineBulletInfo[] {
        let timeLabelSet: HashSet = new HashSet();
        let timelineBullets: TimelineBulletInfo[] = [];
        for (let i = 0; i < entities.length; i++) {
            let entity = entities[i];

            let timeLabel = moment(entity.created).format("HH:mm");
            let displayedTimeLabel = null;
            if (!timeLabelSet.exists(timeLabel)) {
                displayedTimeLabel = timeLabel;
            }
            timeLabelSet.add(timeLabel);

            timelineBullets.push(
                new TimelineBulletInfo(
                    entity.entityId,
                    entity.entityId === this.props.sternumDeviceEventInfo.entityId,
                    entity,
                    displayedTimeLabel,
                    false,
                    entity.entityId === this.props.sternumDeviceEventInfo.entityId
                )
            );
        }

        if (shouldPad) {
            for (let i = 0; i < pageSize - entities.length; i++) {
                if (padLeft) {
                    timelineBullets.unshift(new TimelineBulletInfo(`Empty ${i}`, false, null, null, true, false));
                } else {
                    timelineBullets.push(new TimelineBulletInfo(`Empty ${i}`, false, null, null, true, false));
                }
            }
        }

        return timelineBullets;
    }

    /**
     * Occurs once the explanation popover is closed.
     */
    private handlePopoverClosed() {
        this.setState({
            popoverAnchorElement: null,

            loadingRelatedEntities: false,
            relatedTraces: [],
            relatedGeneratedEvents: [],
        });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(eventsTimelineStyle)(EventsTimeline));
