import { Dialog, DialogActions, DialogClassKey, DialogTitle, Typography, withStyles } from "@material-ui/core";
import { WithStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import SternumImprovedButton, {
    SternumImprovedButtonType,
} from "../../components/SUI/SternumImprovedButton/SternumImprovedButton";
import confirmationDialogStyle from "./ConfirmationDialogStyle";
import { ClassNameMap } from "@material-ui/styles";

interface AppState {
    disableApproveButton: boolean;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof confirmationDialogStyle> {
    open: boolean;
    title: string;
    body: string;
    actionName?: string;
    handleCancel: (operationResult: boolean) => void;
    handleApprove: (operationResult: boolean) => void;
    overrideActionName?: string;
    cancelButtonName?: string;
    cancelButtonType?: SternumImprovedButtonType;
    hideCancelButton?: boolean;
    dialogClasses?: Partial<ClassNameMap<DialogClassKey>>;
    extraButtons?: React.ReactNode;
}

class ConfirmationDialog extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);
        this.state = {
            disableApproveButton: false,
        };

        // Initializing the state to default.
    }

    /**
     * Handle approve click.
     */
    private onApproveClick = () => {
        this.props.handleApprove(true);
    };

    /**
     * Handle cancel click.
     */
    private onCancelClick = () => {
        this.props.handleCancel(false);
    };

    render() {
        const {
            classes,
            dialogClasses,
            cancelButtonName = "Cancel",
            cancelButtonType = "gray",
            hideCancelButton,
        } = this.props;

        return (
            <Dialog
                role="dialog"
                aria-label="confirmation"
                aria-labelledby="simple-dialog-title"
                open={this.props.open}
                onClose={() => {
                    this.props.handleCancel(false);
                }}
                classes={dialogClasses}
            >
                {/* Title */}
                <DialogTitle id="simple-dialog-title" className={classNames(classes.flexVMiddle)}>
                    {this.props.title}
                </DialogTitle>

                {/* Body */}
                <div className={classNames(classes.flexColumn, classes.padding)}>
                    <Typography variant={"subtitle1"} className={classes.body}>
                        {this.props.body}
                    </Typography>
                    {/* Child content */}
                    <div className={classNames(classes.paddingBottomXs, classes.paddingTop)}>{this.props.children}</div>
                </div>

                <DialogActions className={classNames(classes.paddingBottomXs)}>
                    {this.props.extraButtons}

                    {!hideCancelButton && (
                        <SternumImprovedButton
                            fullWidth={false}
                            buttonType={cancelButtonType}
                            content={cancelButtonName}
                            onClick={this.onCancelClick}
                        />
                    )}

                    <SternumImprovedButton
                        fullWidth={false}
                        content={this.props.overrideActionName ? this.props.overrideActionName : "Confirm"}
                        onClick={this.onApproveClick}
                        isDisabled={this.state.disableApproveButton}
                        isLoading={this.state.disableApproveButton}
                    />
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(confirmationDialogStyle)(ConfirmationDialog);
