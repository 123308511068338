import { Button, CircularProgress, MenuItem, withStyles } from "@material-ui/core";
import { WithStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import SternumUtils from "../../../lib/infra/SternumUtils";
import ServiceWire from "../../../lib/services/ServiceWire";
import DeviceDefinitionInfo from "../../../lib/state/DeviceDefinitionInfo";
import DeviceDefinitionPartial from "../../../lib/state/DeviceDefinitionPartial";
import DeviceDefinitionVersionInfo from "../../../lib/state/DeviceDefinitionVersionInfo";
import { GlobalState } from "../../../lib/state/GlobalState";
import ServerEntityType from "../../../lib/state/ServerEntityType";
import Autocomplete from "../../../shared_components/Autocomplete/Autocomplete";
import CustomInput from "../../../shared_components/CustomInput/CustomInput";
import deviceDefinitionFormStyle from "./DeviceDefinitionFormStyle";

interface AppState {
    displayName: string;
    cpuBitness: string;
    deviceFirmwareVersion: string;
    deviceOSFamily: string;
    deviceDefinition: any;
    // indicate if buttons should be in disable mode
    isDisableMode: boolean;
    description: string; // device definition version property
    displayLoader: boolean;
}

export interface AppProps extends WithStyles<typeof deviceDefinitionFormStyle> {
    deviceDefinition: any;
    deviceDefinitionVersion?: DeviceDefinitionVersionInfo;
    forceCRUD?: boolean;
    setOrUpdateDeviceDefinition: (deviceDefinition: any) => void;
    isDirtyState?: (isDirty: boolean) => void;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {};
};

const OS_LIST = [
    { label: "Linux", value: "Linux" },
    { label: "RTOS", value: "RTOS" },
    { label: "Other", value: "Other" },
];

const CPU_TYPES = ["32", "64"];

class DeviceDefinitionForm extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);
        // Initializing the state to default
        this.initState();
    }

    /**
     * Init state options:
     * 1. it's a new device device definition
     * 2. it's update of existing device definition
     * 3. on device definition creation, user enter again into form
     */
    private initState() {
        if (this.props.deviceDefinitionVersion) {
            this.state = {
                displayName: this.props.deviceDefinitionVersion.getVersionName(),
                cpuBitness: this.props.deviceDefinitionVersion.deviceDefinition.cpuBitness.toString(),
                deviceFirmwareVersion: this.props.deviceDefinitionVersion.getFirmwareVersion(),
                deviceOSFamily: this.props.deviceDefinitionVersion.deviceDefinition.deviceOSFamily,
                deviceDefinition: this.props.forceCRUD
                    ? DeviceDefinitionInfo.cloneObject(this.props.deviceDefinitionVersion.deviceDefinition)
                    : DeviceDefinitionPartial.cloneObject(this.props.deviceDefinition),
                isDisableMode:
                    this.props.deviceDefinitionVersion.isProduction() ||
                    !ServiceWire.getAuthorizationService().canEdit(ServerEntityType.DEVICE_DEFINITION),
                description: this.props.deviceDefinitionVersion.description,
                displayLoader: false,
            };
        } else {
            this.state = {
                displayName: "",
                cpuBitness: "",
                deviceFirmwareVersion: "",
                deviceOSFamily: "",
                deviceDefinition: new DeviceDefinitionPartial("", 0, "", "", [], [], [], [], ""),
                isDisableMode: false,
                description: "",
                displayLoader: false,
            };
        }
    }

    /**
     * Update parent component with new data
     */
    private updateParent = (): void => {
        // Update only when it's create step, on edit, do it on click operation.
        if (!this.props.forceCRUD) {
            this.props.setOrUpdateDeviceDefinition(this.state.deviceDefinition);
        }
    };

    /**
     * Validate all fields are set
     */
    private isValid = (): boolean => {
        return (
            this.state.displayName !== "" &&
            this.state.cpuBitness !== "" &&
            this.state.deviceFirmwareVersion !== "" &&
            this.state.deviceOSFamily !== ""
        );
    };

    /**
     * Validate One of the fields is changed to allow update click
     */
    private isNotChanged = (): boolean => {
        if (this.props.forceCRUD) {
            if (this.props.deviceDefinitionVersion) {
                if (!this.props.deviceDefinitionVersion.isRootVersion()) {
                    return (
                        this.state.description === this.props.deviceDefinitionVersion.description &&
                        this.state.deviceFirmwareVersion === this.props.deviceDefinitionVersion.versionFirmware
                    );
                }
                return (
                    this.state.displayName === this.props.deviceDefinitionVersion.deviceDefinition.displayName &&
                    this.state.cpuBitness ===
                        this.props.deviceDefinitionVersion.deviceDefinition.cpuBitness.toString() &&
                    this.state.deviceFirmwareVersion ===
                        this.props.deviceDefinitionVersion.deviceDefinition.deviceFirmwareVersion &&
                    this.state.deviceOSFamily === this.props.deviceDefinitionVersion.deviceDefinition.deviceOSFamily &&
                    this.state.description === this.props.deviceDefinitionVersion.description
                );
            }
            return (
                this.state.displayName === this.props.deviceDefinition.displayName &&
                this.state.cpuBitness === this.props.deviceDefinition.cpuBitness.toString() &&
                this.state.deviceFirmwareVersion == this.props.deviceDefinition.deviceFirmwareVersion &&
                this.state.deviceOSFamily === this.props.deviceDefinition.deviceOSFamily
            );
        }
        return true;
    };

    /**
     * Occurs on change of display name.
     */
    private onDisplayNameChanged = (event): void => {
        const displayNameValue = event.target.value;
        this.state.deviceDefinition.displayName = displayNameValue;

        this.setState(
            {
                displayName: displayNameValue,
            },
            () => {
                this.updateParent();
                this.updateDirtyState(!this.isNotChanged());
            }
        );
    };

    /**
     * Occurs on change of cpu.
     */
    private onCpuBitnessChanged = (event): void => {
        const cpuBitness = event.target.value.toString();
        this.state.deviceDefinition.cpuBitness = cpuBitness;

        this.setState(
            {
                cpuBitness: cpuBitness,
            },
            () => {
                this.updateParent();
                this.updateDirtyState(!this.isNotChanged());
            }
        );
    };

    /**
     * Occurs on change of firmware version.
     */
    private onFirmwareVersionChanged = (event): void => {
        const firmwareVersion = event.target.value;
        this.state.deviceDefinition.deviceFirmwareVersion = firmwareVersion;

        this.setState(
            {
                deviceFirmwareVersion: firmwareVersion,
            },
            () => {
                this.updateParent();
                this.updateDirtyState(!this.isNotChanged());
            }
        );
    };

    private onOsTypeChanged = (event): void => {
        const osType = event.target.value;
        this.state.deviceDefinition.deviceOSFamily = osType;

        this.setState(
            {
                deviceOSFamily: osType,
            },
            () => {
                this.updateParent();
                this.updateDirtyState(!this.isNotChanged());
            }
        );
    };

    /**
     * Occurs on change of new version description.
     */
    private onDescriptionChanged = (event): void => {
        const description = event.target.value;
        this.state.deviceDefinition.description = description;
        this.setState(
            {
                description: description,
            },
            () => {
                this.updateParent();
                this.updateDirtyState(!this.isNotChanged());
            }
        );
    };

    /**
     * Is root version.
     */
    private isDisabled(): boolean {
        return (
            this.state.isDisableMode ||
            (this.props.deviceDefinitionVersion && !this.props.deviceDefinitionVersion.isRootVersion())
        );
    }

    /**
     *
     * Set dirty flag in parent component
     */
    private updateDirtyState = (isDirty: boolean) => {
        if (this.props.forceCRUD) {
            this.props.isDirtyState(isDirty);
        }
    };
    /**
     *
     * Update device definition or version
     */
    private async updateDeviceDefinitionVersion(deviceDefinition: any) {
        // set loading bar while waiting for server response !
        this.setState({
            displayLoader: true,
        });

        let serverResponse = undefined;
        if (this.props.deviceDefinitionVersion.isRootVersion()) {
            // It's root, update device definition
            serverResponse = await ServiceWire.getSternumService().updateDeviceDefinition(deviceDefinition);
            this.props.deviceDefinitionVersion.deviceDefinition.deviceFirmwareVersion =
                this.state.deviceFirmwareVersion;
            // If it's a firmware version change, send update device definition request.
            if (
                this.state.deviceFirmwareVersion !== this.props.deviceDefinitionVersion.getFirmwareVersion() ||
                this.state.description !== this.props.deviceDefinitionVersion.description
            ) {
                serverResponse = await ServiceWire.getSternumService().updateDeviceDefinitionVersion(
                    this.props.deviceDefinitionVersion.entityId,
                    this.state.deviceFirmwareVersion,
                    this.state.description
                );
            }
        } else {
            // It's a version, update version
            serverResponse = await ServiceWire.getSternumService().updateDeviceDefinitionVersion(
                this.props.deviceDefinitionVersion.entityId,
                this.state.deviceFirmwareVersion,
                this.state.description
            );
        }

        if (serverResponse) {
            // Update props to handle step changes
            this.props.deviceDefinitionVersion.description = this.state.description;
            this.props.deviceDefinitionVersion.versionFirmware = this.state.deviceFirmwareVersion;
            this.props.deviceDefinitionVersion.deviceDefinition = this.state.deviceDefinition;
            this.updateDirtyState(false);
            this.setState({
                displayLoader: false,
            });
        }
    }

    /**
     * Check if it's device definition version
     */

    private isVersion = (): boolean => {
        // It's creation of new device definition
        if (!this.props.forceCRUD) {
            return false;
        }
        // Validate it's not root version
        return !(this.props.deviceDefinitionVersion && this.props.deviceDefinitionVersion.isRootVersion());
    };

    render() {
        const { classes } = this.props;

        if (this.state.displayLoader) {
            return (
                <div className={classNames(classes.flexVMiddle, classes.flexCenter)}>
                    <CircularProgress />
                </div>
            );
        }
        return (
            <div
                key="noContent"
                className={classNames(classes.flexColumn, classes.flexCenter, classes.flexVMiddle, classes.root)}
            >
                <div className={classNames(classes.marginBottomMedium)}>
                    <CustomInput
                        value={this.state.displayName}
                        inputTitle={"Display Name"}
                        maxHeightClass={""}
                        inputClasses={""}
                        autoFocus={true}
                        onChange={this.onDisplayNameChanged}
                        disabled={this.isDisabled()}
                        required={true}
                        id={"display-name"}
                        maxLength={SternumUtils.getMaxInputFieldSize()}
                    />
                </div>

                <div className={classNames(classes.marginBottomMedium)}>
                    <CustomInput
                        value={this.state.cpuBitness}
                        inputTitle={"Cpu Bitness"}
                        maxHeightClass={""}
                        inputClasses={""}
                        autoFocus={false}
                        onChange={this.onCpuBitnessChanged}
                        disabled={this.isDisabled()}
                        required={true}
                        select={true}
                        id={"display-cpu"}
                    >
                        {CPU_TYPES.map((option) => (
                            <MenuItem
                                aria-label="cpu bitness dropdown menu item"
                                value={option}
                                key={option}
                                className={classNames(classes.selectItem)}
                            >
                                {option}
                            </MenuItem>
                        ))}
                    </CustomInput>
                </div>

                <div className={classNames(classes.marginBottomMedium)}>
                    <CustomInput
                        value={this.state.deviceOSFamily}
                        inputTitle={"OS Type"}
                        maxHeightClass={""}
                        inputClasses={""}
                        autoFocus={false}
                        onChange={this.onOsTypeChanged}
                        disabled={this.isDisabled()}
                        required={true}
                        select={true}
                        id={"display-os-type"}
                    >
                        {OS_LIST.map(({ label, value }) => (
                            <MenuItem
                                aria-label="os type dropdown menu item"
                                value={value}
                                key={value}
                                className={classNames(classes.selectItem)}
                            >
                                {label}
                            </MenuItem>
                        ))}
                    </CustomInput>
                </div>

                <div className={classNames(classes.marginBottomMedium)}>
                    <CustomInput
                        value={this.state.deviceFirmwareVersion}
                        inputTitle={"Firmware Version"}
                        maxHeightClass={""}
                        inputClasses={""}
                        autoFocus={false}
                        onChange={this.onFirmwareVersionChanged}
                        disabled={this.state.isDisableMode}
                        required={true}
                        id={"display-firmware"}
                        maxLength={SternumUtils.getMaxInputFieldSize()}
                    />
                </div>

                <div className={classNames(classes.marginBottomMedium)}>
                    <CustomInput
                        value={this.state.description}
                        inputTitle={this.isVersion() ? "Version Description" : "Description"}
                        maxHeightClass={""}
                        inputClasses={""}
                        autoFocus={false}
                        onChange={this.onDescriptionChanged}
                        disabled={this.state.isDisableMode}
                        required={false}
                        id={"display-description"}
                    />
                </div>

                {this.props.forceCRUD ? (
                    <div className={classNames(classes.fullWidth, classes.flexEnd)}>
                        <Button
                            disabled={this.isNotChanged() || this.state.isDisableMode || !this.isValid()}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                this.updateDeviceDefinitionVersion(this.state.deviceDefinition);
                            }}
                        >
                            Update Profile
                        </Button>
                    </div>
                ) : (
                    " "
                )}
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(deviceDefinitionFormStyle)(DeviceDefinitionForm));
