/**
 * Represents a user in sternum.
 */
import ClientInfo from "./ClientInfo";
import EntityBase from "./EntityBase";
import EntityType from "./EntityType";
import SternumRole from "./SternumRole";
import TableRowData from "./TableRowData";

class UserInfo extends EntityBase implements TableRowData {
    /**
     * Constructor.
     */
    constructor(
        public userId: string,
        public email: string,
        public firstName: string,
        public lastName: string,
        public isSternumAdmin: boolean,
        public created: number,
        public updated: number,
        public defaultClient: ClientInfo,
        public status: string,
        public isInvited: boolean,
        public lastLogin: number | null,
        public roles?: string[],
        public role?: SternumRole
    ) {
        super(userId, created, updated, EntityType.User);
    }

    /**
     * Converts json to info object.
     */
    public static fromJsonObject(jsonObject: Object) {
        let roles = null;
        let role = null;
        if (jsonObject.hasOwnProperty("roles")) {
            roles = jsonObject["roles"];
        }

        if (jsonObject.hasOwnProperty("role")) {
            role = SternumRole.fromJsonObject(jsonObject["role"]);
        }

        return new UserInfo(
            jsonObject["entity_id"],
            jsonObject["email"],
            jsonObject["first_name"],
            jsonObject["last_name"],
            jsonObject["is_sternum_admin"],
            jsonObject["created"],
            jsonObject["updated"],
            ClientInfo.fromJsonObject(jsonObject["default_client"]),
            jsonObject["status"],
            jsonObject["is_invited"],
            jsonObject["last_login_time"],
            roles,
            role
        );
    }

    public hasAdminRole(): boolean {
        const roleAdminName = "admin";

        if (this.isInvited && this.role) {
            return this.role.displayName.toLocaleLowerCase() === roleAdminName;
        } else {
            if (this.roles && this.roles.length > 0) {
                return this.roles.some((role) => role.toLocaleLowerCase() === roleAdminName);
            }
        }

        return false;
    }

    /**
     * Gets the full name for the user.
     */
    public getFullName(): string {
        if (this.firstName === null || this.lastName === null) {
            return this.email;
        }
        return `${this.firstName} ${this.lastName}`;
    }

    /**
     * Gets the first name for the user.
     */
    public getFirstName(): string {
        return this.firstName;
    }

    /**
     * Returns whether given row is any different in details than current issue info.
     * Shallow comparison, in other words.
     * @param other The row to compare with.
     */
    public isDifferentFrom(other: TableRowData): boolean {
        // Row must of of type issue info.
        if (!(other instanceof UserInfo)) {
            return true;
        }

        let otherUserInfo = other as UserInfo;

        // Comparing base issue properties.
        if (
            otherUserInfo.entityId !== this.entityId ||
            otherUserInfo.created !== this.created ||
            otherUserInfo.updated !== this.updated ||
            otherUserInfo.email !== this.email ||
            otherUserInfo.firstName !== this.firstName ||
            otherUserInfo.lastName !== this.lastName ||
            otherUserInfo.isSternumAdmin !== this.isSternumAdmin ||
            otherUserInfo.lastLogin !== this.lastLogin ||
            otherUserInfo.role !== this.role ||
            otherUserInfo.roles !== this.roles
        ) {
            return true;
        }

        return false;
    }

    /**
     * Gets the value for the given column id.
     */
    public getColumnValue(columnHeaderId: string): any {
        return this[columnHeaderId];
    }

    public updateRole(roleName: string) {
        if (this.isInvited) {
            this.role.displayName = roleName;
        } else {
            this.roles[0] = roleName;
        }
    }

    public getUserStatus(): string {
        return this.status;
    }
}

export default UserInfo;
