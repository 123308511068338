import { makeStyles } from "@material-ui/core";

export const useOnboardingHowItWorksStyles = makeStyles((theme) => {
    const grayCold = "#7D86A1";

    return {
        howItWorksContainer: {
            position: "absolute",
            right: 86,
            top: 26,
            fontSize: 14,
            lineHeight: "17px",
            fontWeight: 600,
            color: "#1B6FDE",
            cursor: "pointer",
        },
        popoverPaper: {
            borderRadius: 20,
        },
        popoverContainer: {
            width: 355,
            background: "white",
            padding: 24,
        },
        onboardingPopoverTitle: {
            color: grayCold,
            fontSize: 12,
            fontWeight: 600,
            marginBottom: 12,
        },
        onboardingItem: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "2px 0 10px 0",
            marginTop: 8,
            borderBottom: "1px solid #ECECEC",
            cursor: "pointer",
        },
    };
});
