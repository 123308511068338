import { IssueInfoReasonType } from "../../lib/state/IssueInfoType";

export interface DefaultIssueReason {
    type: IssueInfoReasonType;
    title: string;
}

export type IsSelectedByDefaultIssueReasonType = {
    [Type in IssueInfoReasonType]?: boolean;
};

export interface CustomReason {
    title: string;
    isSelected: boolean;
}

export interface AlertAnomalyStatusEmitter {
    cancelResolving: () => unknown;
    saveReasonsLocally: () => unknown;
    saveAlertStatus: () => unknown;
}

export enum AlertAnomalyAction {
    Resolve = "Resolve",
    Dismiss = "Dismiss",
}

export interface ReasonsDefaultAndCustom {
    default: DefaultIssueReason[];
    custom: CustomReason[];
}
