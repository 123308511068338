import { grey } from "@material-ui/core/colors";
import { Theme } from "@material-ui/core/styles";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import createStyles from "@material-ui/core/styles/createStyles";
import { sternumTheme } from "../../App";
import commonStyle from "../../CommonStyle";

const dashboardViewerStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: {
            height: "100%",
            position: "relative",
        },

        visualisationsContainer: {
            display: "grid",
            gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
            columnGap: "25px",
            rowGap: "20px",
            alignItems: "center",
            padding: "16px",
        },

        visualisationBoxContainer: {
            height: "315px",
            boxSizing: "border-box",
        },

        viewMoreContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "315px",
            border: "1px dashed #1B6FDE",
            borderRadius: "14px",
            cursor: "pointer",
        },

        viewMoreLabel: {
            fontSize: "18px",
            color: "#1B6FDE",
            fontWeight: 500,
        },

        savingCircleContainer: {
            marginLeft: "15px",
        },

        dashboardTitle: {
            fontWeight: 600,
            fontSize: "20px",
        },

        displayNameContainer: {
            display: "flex",
            alignItems: "center",
            flex: 1,
            gap: "16px",
        },

        valueInputBox: {
            fontSize: "24px",
            fontWeight: 500,
        },

        selectComponent: {
            width: "-webkit-fill-available",
            maxWidth: 500,
        },

        pencil: {
            fontSize: theme.spacing(3),
            cursor: "pointer",
        },

        visualisationBox: {
            width: "100%",
            height: "100%",
            position: "relative",

            boxShadow: "0px 1px 4px rgba(0,0,0,0.1)",

            "&.mod-content": {
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
                border: "1px solid #e0dee2",
            },

            "&.mod-add": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "2px dashed #E0DEE1",
                backgroundColor: "#f6f5f6",
                cursor: "pointer",
                borderRadius: 14,

                "&:hover": {
                    backgroundColor: lighten("#f6f5f6", 0.7),
                },

                "&.mod-visualisation-selection": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    backgroundColor: lighten("#f6f5f6", 0.7),
                    cursor: "default",

                    "&:hover": {
                        backgroundColor: lighten("#f6f5f6", 0.7),
                    },
                },
            },
        },

        closeIconContainer: {
            position: "absolute",
            padding: "10px 10px 0 0",
            top: "0",
            right: "0",
        },

        closeIcon: {
            cursor: "pointer",
            color: grey[500],
            "&:hover": {
                color: theme.palette.common.black,
            },
        },

        metricIconText: {
            fontWeight: 500,
        },

        visualisationContainer: {
            width: "100%",
            height: "100%",
            paddingTop: "10px",
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingBottom: "5px",
        },

        addVisualisationText: {
            fontWeight: 500,
            fontSize: "0.8125rem",
            marginTop: "-1px",
            marginLeft: "5px",
        },

        visualisationAddIcon: {
            color: theme.palette.primary.main,
            fontSize: "16px",
        },

        pencilIcon: {
            fontSize: "0.8125rem",
            marginRight: "5px",
            color: grey[500],
            cursor: "pointer",
        },
        cogIcon: {
            fontSize: "0.8125rem",
            color: grey[500],
            cursor: "pointer",

            "&:hover": {
                color: theme.palette.common.black,
            },
        },

        visualisationTitleContainer: {
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid #e0dee2",
            padding: "0 5px 0 5px",
        },

        visualisationTitle: {
            fontSize: "16px",
            fontWeight: 400,
        },

        metricTypeIcon: {
            fontSize: theme.spacing(4) + 2,
        },

        visualisationTypeSelectionText: {
            fontSize: "0.8125rem",
        },

        titleContainer: {
            display: "flex",
            alignItems: "center",
            padding: "25px 25px 0",
            justifyContent: "space-between",
            minHeight: "55px",
        },

        addGlanceButton: {
            height: 45,
            background: "#1B6FDE",
            borderRadius: 14,

            "& .plus": {
                marginRight: 8,
                color: "#fff",
                fontSize: 16,
                fontWeight: 600,
            },

            "&:hover": {
                background: "#1656AB",
            },
        },

        expandIcon: {
            marginLeft: "5px",
            fontSize: "12px",
            marginTop: "3px",
            cursor: "pointer",
        },

        browseTitleContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "60px",
            background: "#fff",
            boxShadow: "0px 0px 23px rgba(231, 232, 235, 0.25)",
        },

        browseTitle: {
            fontSize: "1rem",
            color: "#C4C6CD",
            fontWeight: 500,
            cursor: "pointer",
        },

        dashboardsBrowserContainer: {
            minHeight: "60px",
            maxHeight: 0,

            "&.mod-expanded": {
                maxHeight: "1500px",
            },
        },
        errorText: {
            color: sternumTheme.danger.main,
            padding: "10px",
        },

        metricTypeContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "25%",
            height: "65px",
            padding: theme.spacing(2),
            cursor: "pointer",
            border: "2px dashed #E0DEE1",
            backgroundColor: theme.palette.common.white,

            "&:hover": {
                borderColor: "#b8bcc1",
                boxShadow: "1px 2px 6px 0 rgba(0,0,0,0.07)",
            },
        },

        browseIcon: {
            padding: "6px",
        },

        spinnerContainer: {
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },

        predefinedDashboardChip: {
            background: "#EEF1FF",
            color: "#1555AB",
            fontWeight: 400,
        },
    });

export default dashboardViewerStyle;
