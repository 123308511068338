import { Action } from "redux";

import ActionType from "../ActionType";
import { CategoryTimeRange } from "../../state/DashboardRegularState";

export interface SetCategoryTimeRangeAction extends Action<ActionType> {
    type: ActionType.DashboardRegularSetCategoryTimeRange;
    categoryTimeRange: CategoryTimeRange;
}

export function setCategoryTimeRangeAction(categoryTimeRange: CategoryTimeRange): SetCategoryTimeRangeAction {
    return {
        type: ActionType.DashboardRegularSetCategoryTimeRange,
        categoryTimeRange,
    };
}
