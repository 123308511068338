import { Theme } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import commonStyle from "../../../CommonStyle";
import gradient from "../../../../images/image_gradient.png";

const useSimulateAttackDialogStyle = makeStyles((theme: Theme) => ({
    ...commonStyle(theme),
    root: {
        padding: "8px 18px",
    },
    paper: {
        width: "650px",
        maxWidth: "650px",
        boxShadow: "0px 0px 50px rgba(0, 0, 0, 0.12)",
        borderRadius: "8px",
        "& .MuiDialogActions-root": {
            justifyContent: "flex-start",
        },
    },
    title: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    actions: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
    },
    paragraph: {
        marginLeft: "-4px",
        marginRight: "-4px",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "150%",
        color: "#737373",
        "& a": {
            color: "#1B6FDE",
            textDecoration: "none",
        },
    },
    logo: {
        width: "100%",
        height: "160px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: `url(${gradient})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
    },

    stepsContainer: {
        display: "flex",
        flexDirection: "column",
        rowGap: "24px",
        width: "100%",
        borderRadius: "12px",
    },

    activeStep: {
        padding: "24px 18px",
        background: "#F7F9FA",
        borderRadius: "12px",
    },

    inactiveStep: {
        padding: "0 18px",
    },
}));

export default useSimulateAttackDialogStyle;
