import { makeStyles } from "@material-ui/core";

export const useTraceStepperStyle = makeStyles((theme) => {
    const colorLightBlue = "#4F98F7";
    const colorLightGreen = "#3FC47C";
    const colorWhite = "white";
    const colorWhiteLight = "#F0F3F5";

    return {
        stepperContainer: {
            display: "flex",
            gap: 24,
            justifyContent: "center",
            padding: "16px 0",
            borderBottom: `1px solid ${colorWhiteLight}`,
        },

        stepSeparator: {
            color: "#ACB4BD",
        },

        step: {
            display: "flex",
            alignItems: "center",
            gap: 8,
            fontWeight: 500,
        },

        stepIconContainer: {},

        stepIcon: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxSizing: "border-box",
            borderRadius: 24,
            width: 24,
            height: 24,
            fontSize: "12px",
            fontWeight: 600,
        },
        stepCompletedIcon: {
            background: colorLightGreen,
            color: colorWhite,
        },
        stepActiveIcon: {
            background: colorLightBlue,
            color: colorWhite,
        },
        stepNotVisitedIcon: {
            color: colorLightBlue,
            border: `1px solid ${colorLightBlue}`,
        },
    };
});
