import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

const colorPickerStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        colorContainer: {
            display: "flex",
            alignItems: "center",
            padding: "12px",
        },

        colorBox: {
            width: "20px",
            height: "20px",
            cursor: "pointer",
            borderRadius: "50%",
            transition: "all 0.2s ease-in-out",
            padding: 0,

            "&:hover": {
                "&.mod-hover": {
                    transform: "scale(1.2)",
                },
            },

            "&.mod-margin": {
                marginRight: "8px",
            },

            "&.mod-selected": {
                transform: "scale(1.2)",
            },
        },
    });

export default colorPickerStyle;
