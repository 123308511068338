import { useEffect, useRef, useState } from "react";

import DeviceInfo from "../../../../lib/state/DeviceInfo";
import ServiceWire from "../../../../lib/services/ServiceWire";

export const useDevices = (maxDevicesToConnect: number) => {
    const isMountedRef = useIsMounted();
    const [devices, setDevices] = useState<DeviceInfo[]>([]);
    const [areDevicesLoading, setAreDevicesLoading] = useState(true);

    useEffect(() => {
        (async () => {
            let devicesResponse = await ServiceWire.getSternumService().getDevices({
                clientId: ServiceWire.getClientsService().getSelectedClientId(),
                doNotReturnEntities: false,
                returnTotalCount: false,
                limit: maxDevicesToConnect,
            });

            if (!isMountedRef.current) {
                return;
            }

            setDevices(devicesResponse.devices);
            setAreDevicesLoading(false);
        })();
    }, []);

    return { devices, areDevicesLoading };
};

const useIsMounted = () => {
    const isMountedRef = useRef(true);

    useEffect(
        () => () => {
            isMountedRef.current = false;
        },
        []
    );

    return isMountedRef;
};
