import ActionType from "../ActionType";
import { UsedLibrariesReducerActions } from "./UsedLibrariesReducerActions";
import { EntitiesState, getDefaultEntitiesState } from "../../state/EntitiesState";
import HashSet from "../../infra/HashSet";
import HashMap from "../../infra/HashMap";
import UsedLibraryInfo from "../../state/UsedLibraryInfo";

/**
 * Reducer for all used library-related actions.
 */
const usedLibrariesReducer = (
    state: EntitiesState<UsedLibraryInfo> = getDefaultEntitiesState<UsedLibraryInfo>(),
    action: UsedLibrariesReducerActions
) => {
    switch (action.type) {
        case ActionType.FetchUsedLibrary: {
            return {
                idToEntityMap: state.idToEntityMap,
                loadingIds: HashSet.copyAndAdd(state.loadingIds, action.usedLibraryId),
                errorIds: state.errorIds,
                detectedDeviceCount: null,
            };
        }

        case ActionType.FetchedUsedLibrarySuccessfully: {
            return {
                idToEntityMap: HashMap.copyAndPut(state.idToEntityMap, action.usedLibrary.entityId, action.usedLibrary),
                loadingIds: state.loadingIds.exists(action.usedLibrary.entityId)
                    ? HashSet.copyAndRemove(state.loadingIds, action.usedLibrary.entityId)
                    : state.loadingIds,
                errorIds: state.errorIds.containsKey(action.usedLibrary.entityId)
                    ? HashMap.copyAndRemove(state.errorIds, action.usedLibrary.entityId)
                    : state.errorIds,
                detectedDeviceCount: null,
            };
        }

        case ActionType.ErrorFetchingUsedLibrary: {
            return {
                idToEntityMap: state.idToEntityMap,
                loadingIds: HashSet.copyAndRemove(state.loadingIds, action.usedLibraryId),
                errorIds: HashMap.copyAndPut(state.errorIds, action.usedLibraryId, action.error),
                detectedDeviceCount: null,
            };
        }

        default:
            return state;
    }
};

export default usedLibrariesReducer;
