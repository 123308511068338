import React from "react";

import { useTilesStyle } from "./Tiles.style";
import { TileItem } from "./TileItem";
import classNames from "classnames";

export interface TilesProps extends React.ComponentProps<"div"> {}

export function Tiles({ className, children, ...props }: TilesProps) {
    const classes = useTilesStyle();

    return (
        <div className={classNames(className, classes.tilesContainer)} {...props}>
            {children}
        </div>
    );
}

Tiles.Item = TileItem;
