import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Typography } from "@material-ui/core";
import { BigPlusIcon, DeleteIcon } from "../SUI/SternumIcon/SternumIcon";
import Button from "../SUI/SternumImprovedButton/SternumImprovedButton";
import UIDataVisualisationConfiguration from "../VisualisationConfigurationComponent/entities/UIDataVisualisationConfiguration";
import AggregatedTraceDefinition from "../../lib/state/AggregatedTraceDefinition";
import { UniqueCountProvider } from "./UniqueCountProvider";
import { UniqueCountDropdown } from "./UniqueCountDropdown";

interface UniqueCountQueryProps {
    traceDefinitionFields: AggregatedTraceDefinition[];
    dataVisualisationConfiguration: UIDataVisualisationConfiguration;
    onDataVisualisationConfigurationChange: (dataVisualisationConfiguration: UIDataVisualisationConfiguration) => void;
}

const styles = {
    headerBlock: {
        marginBottom: 30,
    },
    header: {
        fontWeight: 600,
        fontSize: 18,
    },
    description: {
        color: "#B8BACF",
    },
    uniqueValueBlock: {
        display: "inline-block",
        width: 300,
    },
    uniqueValueLabel: {
        fontWeight: 600,
    },
    uniqueValueContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 10,
    },
    flex1: {
        flex: 1,
    },
    deleteIcon: {
        marginLeft: 10,
        marginRight: 20,
        cursor: "pointer",
    },
    buttonContainer: {
        marginTop: 20,
    },
};

const MAX_ALLOWED_UNIQUE_VALUES = 2;

export const UniqueCountQuery = ({
    dataVisualisationConfiguration,
    onDataVisualisationConfigurationChange,
    traceDefinitionFields,
}: UniqueCountQueryProps) => {
    const addUniqueValue = useCallback(() => {
        if (dataVisualisationConfiguration.uniqueColumns && dataVisualisationConfiguration.uniqueColumns.length >= 2) {
            // limit the column length to 2
            return;
        }
        const newDataVis = { ...dataVisualisationConfiguration };
        if (newDataVis.uniqueColumns) {
            newDataVis.uniqueColumns = [...newDataVis.uniqueColumns, null];
        } else {
            newDataVis.uniqueColumns = [null];
        }
        onDataVisualisationConfigurationChange(newDataVis);
    }, [dataVisualisationConfiguration]);

    const removeUniqueValue = useCallback(
        (index: number) => {
            const newDataVis = { ...dataVisualisationConfiguration };
            newDataVis.uniqueColumns = newDataVis.uniqueColumns.filter((val, idx) => index !== idx);
            onDataVisualisationConfigurationChange(newDataVis);
        },
        [dataVisualisationConfiguration]
    );

    return (
        <div>
            <div style={styles.headerBlock}>
                <Typography variant="body2" style={styles.header}>
                    Unique Value
                </Typography>
                <Typography variant="body2" style={styles.description}>
                    Displays only unique values of the selected category
                </Typography>
            </div>
            {!!dataVisualisationConfiguration.uniqueColumns &&
                dataVisualisationConfiguration.uniqueColumns
                    .filter((_, index) => index > 0) // do not show the first item
                    .map((uniqueValue, index) => (
                        <div key={index} style={styles.uniqueValueBlock}>
                            <Typography variant="body2" style={styles.uniqueValueLabel}>
                                Unique Value ({index + 1})
                            </Typography>
                            <div style={styles.uniqueValueContent}>
                                <div style={styles.flex1}>
                                    <UniqueCountDropdown
                                        selectedValue={uniqueValue}
                                        onChange={(newValue, action) => {
                                            const newDataVis = { ...dataVisualisationConfiguration };
                                            // to properly reset graph on value change
                                            newDataVis.uniqueColumns = [...newDataVis.uniqueColumns];
                                            newDataVis.uniqueColumns[index + 1] = newValue as AggregatedTraceDefinition;
                                            onDataVisualisationConfigurationChange(newDataVis);
                                        }}
                                    />
                                </div>

                                <div style={styles.deleteIcon}>
                                    {/* {index > 0 && ( */}
                                    <DeleteIcon
                                        color="#4F4F4F"
                                        // className={classNames(classes.removeIcon)}
                                        onClick={(event) => {
                                            removeUniqueValue(index + 1);
                                        }}
                                    />
                                    {/* )} */}
                                </div>
                            </div>
                        </div>
                    ))}

            {dataVisualisationConfiguration.uniqueColumns &&
                dataVisualisationConfiguration.uniqueColumns.length < MAX_ALLOWED_UNIQUE_VALUES && (
                    <div style={styles.buttonContainer}>
                        <Button
                            buttonType="text:blue"
                            onClick={addUniqueValue}
                            content="Add Unique Value"
                            icon={<BigPlusIcon width={16} />}
                            fullWidth={false}
                        />
                    </div>
                )}
        </div>
    );
};
