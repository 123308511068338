import { CircularProgress, IconButton } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { withRouter } from "react-router-dom";
import ServiceWire from "../../lib/services/ServiceWire";
import ServerEntityType from "../../lib/state/ServerEntityType";
import UserInfo from "../../lib/state/UserInfo";
import UserStatus from "../../lib/state/UserStatus";
import ConfirmationDialog from "../../shared_components/ConfirmationDialog/ConfirmationDialog";
import EditUserPermissionsDialog from "../EditUserPermissionsDialog/EditUserPermissionsDialog";
import { DeleteIcon, PencilIcon, ReSendIcon } from "../SUI/SternumIcon/SternumIcon";
import usersListActionsStyle from "./UsersListActionsStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    deleteConfirmationDialog: boolean;
    resendConfirmationDialog: boolean;
    openEditDialog: boolean;
    displayLoader: boolean;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof usersListActionsStyle> {
    user: UserInfo;
    displayLoader: boolean;

    removeSelectedUser: (user: UserInfo) => void;
    updateUsersList: (oldUser: UserInfo, newUser: UserInfo) => void;
    editSelectedUser: (userRole: string) => void;
}

/**
 * Holds the user actions.
 */
class UsersListActions extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            deleteConfirmationDialog: false,
            resendConfirmationDialog: false,
            openEditDialog: false,
            displayLoader: false,
        };
    }

    /**
     * Delete selected user.
     */
    private deleteSelectedUser = async () => {
        this.closeDeleteConfirmationDialog();

        // Set loader flag to true
        this.setState({ displayLoader: true });
        // Send request to delete user
        try {
            const serverResponse = await ServiceWire.getSternumService().deleteUser(this.props.user);
            if (serverResponse) {
                // Update state with new list
                this.setState({ displayLoader: false });
                this.props.removeSelectedUser(this.props.user);
            }
        } catch (err) {
        } finally {
            this.setState({ displayLoader: false });
        }
    };

    /**
     * Resend invitation to selected user.
     */
    private resendInvitationToSelectedUser = async () => {
        this.closeResendConfirmationDialog();
        // Set loader flag to true
        this.setState({ displayLoader: true });
        // Send request to delete user
        try {
            const updatedUser: UserInfo = await ServiceWire.getSternumService().resendUserInvitation(
                this.props.user.entityId
            );
            if (updatedUser) {
                this.setState({ displayLoader: false });
                this.props.updateUsersList(this.props.user, updatedUser);
            }
        } catch (err) {
        } finally {
            this.setState({ displayLoader: false });
        }
    };

    /**
     * Open delete confirmation dialog
     */
    private openDeleteConfirmationDialog = () => {
        this.setState({ deleteConfirmationDialog: true });
    };

    /**
     * Open resend user invitation dialog
     */
    private openResendConfirmationDialog = () => {
        this.setState({ resendConfirmationDialog: true });
    };

    /**
     * Open edit dialog
     */
    private openEditDialog = () => {
        this.setState({ openEditDialog: true });
    };

    /**
     * Close delete confirmation dialog
     */
    private closeDeleteConfirmationDialog = () => {
        this.setState({ deleteConfirmationDialog: false });
    };

    /**
     * Close resend invitation confirmation dialog
     */
    private closeResendConfirmationDialog = () => {
        this.setState({ resendConfirmationDialog: false });
    };

    /**
     * Close edit dialog
     */
    private closeEditDialog = (userRole: string) => {
        this.setState({ openEditDialog: false }, () => {
            this.props.editSelectedUser(userRole);
        });
    };

    private canEdit = (): boolean => {
        return ServiceWire.getAuthorizationService().canEdit(ServerEntityType.USER);
    };

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;
        if (this.state.displayLoader) {
            return (
                <div className={classNames(classes.flexVMiddle)}>
                    {/* Loading circle */}
                    <CircularProgress size={15} className={classNames(classes.marginRight)} />
                </div>
            );
        }
        return (
            <div className={classNames(classes.flexVMiddle, classes.fullWidth)}>
                {/* Resend invitation, display only for expired invitations*/}

                {this.props.user.isInvited && this.props.user.getUserStatus() === UserStatus[UserStatus.EXPIRED] && (
                    <IconButton
                        disabled={!this.canEdit()}
                        className={classes.smallButton}
                        aria-label="resend-expiration"
                        onClick={() => {
                            this.openResendConfirmationDialog();
                        }}
                    >
                        <ReSendIcon width={20} height={20} className={classes.svgEditIcon} color="#8B949E" />
                    </IconButton>
                )}

                {/* Delete */}
                <IconButton
                    disabled={!this.canEdit()}
                    className={classes.smallButton}
                    aria-label="delete"
                    onClick={() => {
                        this.openDeleteConfirmationDialog();
                    }}
                >
                    <DeleteIcon className={classes.svgDeleteIcon} color="#8B949E" />
                </IconButton>
                {/* Edit Role */}
                <IconButton
                    disabled={!this.canEdit()}
                    className={classes.smallButton}
                    aria-label="edit"
                    onClick={() => {
                        this.openEditDialog();
                    }}
                >
                    <PencilIcon width={20} height={20} className={classes.svgEditIcon} color="#8B949E" />
                </IconButton>

                {/* Delete user confirmation dialog*/}
                <ConfirmationDialog
                    title={`Delete ${this.props.user.getFullName()} ?`}
                    open={this.state.deleteConfirmationDialog}
                    body={"The user will be deleted from the system."}
                    handleApprove={this.deleteSelectedUser}
                    handleCancel={this.closeDeleteConfirmationDialog}
                    overrideActionName={"Delete"}
                />
                {/* Resend user invitation confirmation dialog*/}
                <ConfirmationDialog
                    title={`Resend invitation to ${this.props.user.getFullName()} ?`}
                    open={this.state.resendConfirmationDialog}
                    body={`${this.props.user.getFullName()} will receive invitation from you.`}
                    handleApprove={this.resendInvitationToSelectedUser}
                    handleCancel={this.closeResendConfirmationDialog}
                />
                {(() => {
                    if (this.state.openEditDialog) {
                        return (
                            <EditUserPermissionsDialog
                                user={this.props.user}
                                open={this.state.openEditDialog}
                                handleCancel={this.closeEditDialog}
                                handleUpdate={this.closeEditDialog}
                            />
                        );
                    }
                })()}
            </div>
        );
    }
}

export default withRouter(withStyles(usersListActionsStyle)(UsersListActions));
