import ColorConfiguration from "./ColorConfiguration";
import SternumColorType from "./SternumColorType";

export const sternumColorMap: Record<SternumColorType, ColorConfiguration> = {
    [SternumColorType.COLOR_A]: {
        colorType: SternumColorType.COLOR_A,
        main: "rgba(203, 0, 35, 1)",
        light: "rgba(203, 0, 35, 0.4)",
    },
    [SternumColorType.COLOR_B]: {
        colorType: SternumColorType.COLOR_B,
        main: "rgba(31, 116, 170, 1)",
        light: "rgba(31, 116, 170, 0.4)",
    },
    [SternumColorType.COLOR_C]: {
        colorType: SternumColorType.COLOR_C,
        main: "rgba(199, 0, 203, 1)",
        light: "rgba(199, 0, 203, 0.4)",
    },
    [SternumColorType.COLOR_D]: {
        colorType: SternumColorType.COLOR_D,
        main: "rgba(16, 0, 203, 1)",
        light: "rgba(16, 0, 203, 0.4)",
    },
    [SternumColorType.COLOR_E]: {
        colorType: SternumColorType.COLOR_E,
        main: "rgba(0, 203, 142, 1)",
        light: "rgba(0, 203, 142, 0.4)",
    },
    [SternumColorType.COLOR_F]: {
        colorType: SternumColorType.COLOR_F,
        main: "rgba(102, 203, 0, 1)",
        light: "rgba(102, 203, 0, 0.4)",
    },
    [SternumColorType.COLOR_G]: {
        colorType: SternumColorType.COLOR_G,
        main: "rgba(199, 203, 0, 1)",
        light: "rgba(199, 203, 0, 0.4)",
    },
    [SternumColorType.COLOR_H]: {
        colorType: SternumColorType.COLOR_H,
        main: "rgba(203, 97, 0, 1)",
        light: "rgba(203, 97, 0, 0.4)",
    },
    [SternumColorType.COLOR_I]: {
        colorType: SternumColorType.COLOR_I,
        main: "rgba(250, 140, 173, 1)",
        light: "rgba(250, 140, 173, 0.4)",
    },
    [SternumColorType.COLOR_J]: {
        colorType: SternumColorType.COLOR_J,
        main: "rgba(0, 166, 203, 1)",
        light: "rgba(0, 166, 203, 0.4)",
    },
    [SternumColorType.COLOR_K]: {
        colorType: SternumColorType.COLOR_K,
        main: "rgba(150, 0, 203, 1)",
        light: "rgba(150, 0, 203, 0.4)",
    },
    [SternumColorType.COLOR_L]: {
        colorType: SternumColorType.COLOR_L,
        main: "rgba(92, 107, 242, 1)",
        light: "rgba(92, 107, 242, 0.4)",
    },
    [SternumColorType.COLOR_M]: {
        colorType: SternumColorType.COLOR_M,
        main: "rgba(179, 231, 181, 1)",
        light: "rgba(179, 231, 181, 0.4)",
    },
    [SternumColorType.COLOR_N]: {
        colorType: SternumColorType.COLOR_N,
        main: "rgba(169, 192, 132, 1)",
        light: "rgba(169, 192, 132, 0.4)",
    },
    [SternumColorType.COLOR_O]: {
        colorType: SternumColorType.COLOR_O,
        main: "rgba(236, 224, 163, 1)",
        light: "rgba(236, 224, 163, 0.4)",
    },
    [SternumColorType.COLOR_P]: {
        colorType: SternumColorType.COLOR_P,
        main: "rgba(238, 153, 106, 1)",
        light: "rgba(238, 153, 106, 0.4)",
    },
};
