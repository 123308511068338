export interface PatternItem {
    order: number;
    name: string;
}

export enum PatternReceivedTraceStatus {
    Received = "Received",
    NotReceived = "NotReceived",
    Unexpected = "Unexpected",
}

export interface PatternReceivedTrace {
    receivedTraceId: string;
    orderIndex: number;
    eventType: string;
    receivedTime: number | string;
    traceStatus: PatternReceivedTraceStatus;
}

export interface PatternWithTraces {
    name: string;
    receivedTraces: PatternReceivedTrace[];
    items: PatternItem[];
}

export interface SequencesPatternsApi {
    name: string;
    expected_traces: {
        trace_definition: { is_reference: boolean; entity_id: string /* "TRDE***" */ };
    }[];
    received_traces: {
        expected?: boolean;
        unexpected?: boolean;
        missing?: boolean;
        trace?: {
            is_reference: boolean;
            entity_id: string; // "TRAC***"
        };
        trace_definition?: {
            is_reference: boolean;
            entity_id: string; // "TRDE***"
        };
    }[];
    related_entities_map: Object;
}

// tslint:disable-next-line:class-name
export interface TraceTRAC_API {
    // "TRACBNzoUPT02F1": {}
    entity_id: string; // "TRACBNzoUPT02F1";
    entity_id_long: number; // 1042794014839058433;
    entity_type: string; // "TRACE";
    client_id: string; // "CLEI1";
    device_id: string; // "DEVIANzoUPT02F1";
    ip_address: string; // "127.0.0.1";
    created: string; // 1668691867000;
    updated: string | number | null; // null;
    severity: string; // "normal";
    display_name?: string;
    trace_event_name: string; // "TRACE_BOOT";
    device_definition_version: {
        is_reference: boolean;
        entity_id: string; // "DDVEQLmM6NT02F1";
    };
    // geo_location: {
    //     continent: "AS";
    //     country: "IL";
    //     city: "Jerusalem";
    //     state: "Jerusalem";
    //     latitude: 52.2297;
    //     longitude: 21.0122;
    //     organization: "Internet provider";
    // };
    device: {
        is_reference: boolean;
        entity_id: string; // "DEVIANzoUPT02F1";
    };
    trace_event_type: number; // 113;
    trace_definition: { is_reference: boolean; entity_id: string /* "TRDEYCj8JNT02F1" */ };
    // data_source: ["ElasticSearch"];
}

// tslint:disable-next-line:class-name
export interface TraceDefinitionTRDE_API {
    // "TRDEWCj8JNT02F1": {}
    entity_id: "TRDEWCj8JNT02F1";
    entity_type: "TRACE_DEFINITION";
    created: "2022-11-17T13:31:07+00:00";
    updated: "2022-11-17T13:31:07+00:00";
    display_name: "Stat";
    trace_event_name: "TRACE_STAT";
    trace_type: "SYSTEM";
    trace_category: "FILE_EVENT";
    event_interest: "REGULAR";
    transmit_frequency: "BATCH";
    description: null;
    client: {
        is_reference: true;
        entity_id: "CLEI1";
    };
    deleted: null;
    device_definition_id: "DEDEQLmM6NT02F1";
    device_definition_version: {
        entity_id: "DDVEQLmM6NT02F1";
        entity_type: "DEVICE_DEFINITION_VERSION";
        client_id: "CLEI1";
        created: "2022-11-17 13:31:07";
        updated: "2022-11-17 13:31:07";
        device_definition_id: "DEDEQLmM6NT02F1";
        device_definition_version_raw_id: "1042794012645437440";
        version_firmware: null;
        version_status: "STAGING";
        version_sequence: 0;
        version_name: null;
        description: null;
        downloaded_date: ["2022-11-17 13:31:07"];
        trigger_download_date: null;
        parent_raw_id: "N/A";
        creator_user: {
            is_reference: true;
            entity_id: "USERKmNkSLT02F1";
        };
        device_definition: {
            is_reference: true;
            entity_id: "DEDEQLmM6NT02F1";
        };
    };
    display_in_ui: true;
    data_source: ["MySQL"];
}

export function getSequencePatternsFromJSON(response: Object): PatternWithTraces[] {
    const sequencePatternsApi: SequencesPatternsApi[] = response?.["sequences_patterns"];
    const related_entities = response?.["related_entities_map"];

    return sequencePatternsApi.map((sequencePatternApi) => {
        return {
            name: sequencePatternApi.name,
            receivedTraces:
                sequencePatternApi.received_traces?.map?.((receivedTrace) => {
                    const traceId = receivedTrace.trace?.entity_id || "";
                    const traceDefinitionId = receivedTrace.trace_definition?.entity_id || "";
                    const trace: TraceTRAC_API = related_entities?.[traceId || traceDefinitionId] || {};
                    const traceDefinition: TraceDefinitionTRDE_API =
                        related_entities?.[trace.trace_definition?.entity_id || ""] || {};

                    const receivedTraceId = (receivedTrace.trace || receivedTrace.trace_definition)?.entity_id || "";

                    return {
                        receivedTraceId,
                        orderIndex: 0,
                        receivedTime: trace.created,
                        eventType: trace.display_name || traceDefinition.display_name || "Unknown",
                        traceStatus: ((): PatternReceivedTraceStatus => {
                            if (receivedTrace.expected) return PatternReceivedTraceStatus.Received;
                            if (receivedTrace.unexpected) return PatternReceivedTraceStatus.Unexpected;
                            if (receivedTrace.missing) return PatternReceivedTraceStatus.NotReceived;

                            return PatternReceivedTraceStatus.Unexpected;
                        })(),
                    };
                }) || [],
            items:
                sequencePatternApi.expected_traces?.map((expectedTrace, index) => {
                    const traceDefinitionId: string = expectedTrace.trace_definition?.entity_id || "";
                    const traceDefinitionApi: TraceDefinitionTRDE_API = related_entities?.[traceDefinitionId] || {};

                    return {
                        name: traceDefinitionApi.display_name || "Unknown",
                        order: index + 1, // FIXME: Is that correct value?
                    };
                }) || [],
        };
    });
}
