import { Action } from "redux";
import ReceivedDefinitionsResponse from "../../services/events/ReceivedDefinitionsResponse";
import ActivityInfo from "../../state/ActivityInfo";
import CustomDashboardInfo from "../../state/CustomDashboardInfo";
import CvesFilter from "../../state/CvesFilter";
import DeviceDefinitionInfo from "../../state/DeviceDefinitionInfo";
import DeviceDefinitionVersionInfo from "../../state/DeviceDefinitionVersionInfo";
import IssueInfo from "../../state/IssueInfo";
import LibraryInfo from "../../state/LibraryInfo";
import ModalKey from "../../state/ModalKey";
import ModalType from "../../state/ModalType";
import OutgoingWebhookInfo from "../../state/OutgoingWebhookInfo";
import SternumQuery from "../../state/SternumQuery";
import TimeSelectOption from "../../state/TimeSelectOption";
import VisualisationType from "../../state/Visualisation/VisualisationType";
import ActionType from "../ActionType";
import VisualisationInfo from "../../state/Visualisation/VisualisationInfo";

/**
 * Defines the data for the open modal action.
 */
export interface OpenModalAction extends Action<ActionType> {
    type: ActionType.OpenModal;
    modalKey: ModalKey;
    fullScreen: boolean;
    parametersMap: { [parameterKey: string]: any };
    onClose?: () => unknown;
}

/**
 * Opens the device view modal.
 */
export function openDeviceViewModalAction(
    key: string,
    displayViewForDeviceDefinition: boolean,
    deviceId?: string,
    deviceDefinitionId?: string,
    displayXButton?: boolean,
    displayBackButton?: boolean
): OpenModalAction {
    return {
        type: ActionType.OpenModal,
        modalKey: new ModalKey(ModalType.DeviceViewModal, key),
        fullScreen: true,
        parametersMap: {
            deviceId: deviceId,
            deviceDefinitionId: deviceDefinitionId,
            displayViewForDeviceDefinition: displayViewForDeviceDefinition,
            displayXButton: displayXButton,
            displayBackButton: displayBackButton,
        },
    };
}

/**
 * Opens the trace view modal.
 */
export function openTraceViewModalAction(
    key: string,
    traceId: string,
    displayXButton?: boolean,
    displayBackButton?: boolean,
    shouldDisplayLinkToDeviceView?: boolean,
    shouldDisplayLinkToExportReport?: boolean,
    issue?: IssueInfo,
    shouldDisplayViewInContextButton: boolean | undefined = false,
    onRefresh: (() => unknown) | undefined = undefined,
    resolveFlowOpen: boolean | undefined = undefined
): OpenModalAction {
    return {
        type: ActionType.OpenModal,
        modalKey: new ModalKey(ModalType.TraceViewModal, key),
        fullScreen: false,
        parametersMap: {
            traceId: traceId,
            displayXButton,
            displayBackButton,
            shouldDisplayLinkToDeviceView: shouldDisplayLinkToDeviceView,
            shouldDisplayLinkToExportReport: shouldDisplayLinkToExportReport,
            issueId: issue ? issue.issueId : null,
            issue: issue,
            shouldDisplayViewInContextButton: shouldDisplayViewInContextButton,
            resolveFlowOpen,
            onRefresh,
        },
    };
}

/**
 * Opens the create dashboard modal.
 */
export function openCreateDashboardModalAction(
    key: string,
    onCreateOperation?: (customDashboard: CustomDashboardInfo) => void
): OpenModalAction {
    return {
        type: ActionType.OpenModal,
        modalKey: new ModalKey(ModalType.CreateDashboardModal, key),
        fullScreen: false,
        parametersMap: {
            onCreateOperation: onCreateOperation,
        },
    };
}

/**
 * Opens the edit dashboard modal.
 */
export function openEditDashboardModalAction(
    key: string,
    dashboard: CustomDashboardInfo,
    onEditOperation?: (customDashboard: CustomDashboardInfo) => void
): OpenModalAction {
    return {
        type: ActionType.OpenModal,
        modalKey: new ModalKey(ModalType.EditDashboardModal, key),
        fullScreen: false,
        parametersMap: {
            dashboard,
            onEditOperation: onEditOperation,
        },
    };
}

/**
 * Opens the visualization creation modal.
 */
export function openVisualizationCreationModalAction(
    key: string,
    visualisationId: string,
    deviceDefinitionVersions: DeviceDefinitionVersionInfo[],
    receivedDefinitionsResponse: Record<string, ReceivedDefinitionsResponse>,
    startTime: Date,
    endTime: Date,
    sternumQuery: SternumQuery,
    displayViewForDeviceDefinition: boolean,
    displayXButton: boolean,
    displayBackButton: boolean,
    lookedUpEntityId: string,
    timeSelectedOption: TimeSelectOption,
    deviceId?: string,
    visualisationType?: VisualisationType,
    dashboardId?: string,
    onVisualisationCreated?: (visualisationId: string, displayName: string) => void,
    onVisualisationUpdated?: (visualisationId: string, displayName: string) => void,
    onVisualisationDeleted?: (visualisationId: string) => void,
    visualisation?: VisualisationInfo,
    disableEditing?: boolean
): OpenModalAction {
    return {
        type: ActionType.OpenModal,
        modalKey: new ModalKey(ModalType.VisualizationCreation, key),
        fullScreen: false,
        parametersMap: {
            deviceId,
            visualisationId,
            visualisation,
            deviceDefinitionVersions,
            receivedDefinitionsResponse,
            startTime,
            endTime,
            sternumQuery,
            displayViewForDeviceDefinition,
            displayXButton,
            displayBackButton,
            lookedUpEntityId,
            timeSelectedOption,
            visualisationType,
            dashboardId,
            onVisualisationCreated,
            onVisualisationUpdated,
            onVisualisationDeleted,
            disableEditing,
        },
    };
}

/**
 * Opens the device information modal.
 */
export function openDeviceInfoModalAction(
    key: string,
    deviceId: string,
    displayXButton?: boolean,
    displayBackButton?: boolean
): OpenModalAction {
    return {
        type: ActionType.OpenModal,
        modalKey: new ModalKey(ModalType.DeviceInfoModal, key),
        fullScreen: false,
        parametersMap: {
            deviceId: deviceId,
            displayXButton: displayXButton,
            displayBackButton: displayBackButton,
        },
    };
}

/**
 * Opens the device definition information modal.
 */
export function openDeviceDefinitionInfoModalAction(
    key: string,
    entityId: string,
    displayXButton?: boolean,
    displayBackButton?: boolean
): OpenModalAction {
    return {
        type: ActionType.OpenModal,
        modalKey: new ModalKey(ModalType.DeviceDefinitionInfoModal, key),
        fullScreen: false,
        parametersMap: {
            entityId: entityId,
            displayXButton: displayXButton,
            displayBackButton: displayBackButton,
        },
    };
}

/**
 * Opens the map view modal.
 */
export function openMapModalAction(
    key: string,
    entityId: string,
    createdFrom: number,
    createdTo: number,
    title: string,
    displayXButton: boolean
): OpenModalAction {
    return {
        type: ActionType.OpenModal,
        modalKey: new ModalKey(ModalType.DeviceDefinitionLocationModal, key),
        fullScreen: false,
        parametersMap: {
            entityId: entityId,
            createdFrom: createdFrom,
            createdTo: createdTo,
            title: title,
            displayXButton: displayXButton,
        },
    };
}

/**
 * Opens the device geo location history modal.
 */
export function openGeoLocationHistoryModalAction(
    key: string,
    entityId: string,
    createdFrom: number,
    createdTo: number,
    title: string,
    displayXButton: boolean,
    deviceDefinition: DeviceDefinitionInfo,
    deviceDefinitionVersionId: string
): OpenModalAction {
    return {
        type: ActionType.OpenModal,
        modalKey: new ModalKey(ModalType.DeviceLocationModal, key),
        fullScreen: false,
        parametersMap: {
            entityId: entityId,
            createdFrom: createdFrom,
            createdTo: createdTo,
            title: title,
            displayXButton: displayXButton,
            deviceDefinition: deviceDefinition,
            deviceDefinitionVersionId: deviceDefinitionVersionId,
        },
    };
}

/**
 * Opens the sternum generated event view modal.
 */
export function openSternumGeneratedEventViewModalAction(
    key: string,
    generatedEventId: string,
    shouldDisplayLinkToDeviceView: boolean,
    shouldDisplayLinkToExportReport: boolean,
    displayXButton?: boolean,
    displayBackButton?: boolean,
    issue?: IssueInfo,
    issueId?: string,
    onRefresh?: () => unknown,
    resolveFlowOpen?: boolean,
    shouldDisplayViewInContextButton: boolean | undefined = false
): OpenModalAction {
    return {
        type: ActionType.OpenModal,
        modalKey: new ModalKey(ModalType.SternumGeneratedEventViewModal, key),
        fullScreen: false,
        parametersMap: {
            generatedEventId: generatedEventId,
            shouldDisplayLinkToDeviceView: shouldDisplayLinkToDeviceView,
            shouldDisplayLinkToExportReport: shouldDisplayLinkToExportReport,
            displayXButton: displayXButton,
            displayBackButton: displayBackButton,
            shouldDisplayViewInContextButton,
            issue: issue,
            issueId: issue?.issueId || issueId,
            resolveFlowOpen,
            onRefresh,
        },
    };
}

/**
 * Opens the library view modal.
 */
export function openLibraryViewModalAction(
    key: string,
    libraryId: string,
    displayXButton?: boolean,
    displayBackButton?: boolean
): OpenModalAction {
    return {
        type: ActionType.OpenModal,
        modalKey: new ModalKey(ModalType.LibraryViewModal, key),
        fullScreen: false,
        parametersMap: {
            libraryId: libraryId,
            displayXButton: displayXButton,
            displayBackButton: displayBackButton,
        },
    };
}

/**
 * Opens the used library view modal.
 */
export function openUsedLibraryViewModalAction(
    key: string,
    usedLibraryId: string,
    displayXButton?: boolean,
    displayBackButton?: boolean
): OpenModalAction {
    return {
        type: ActionType.OpenModal,
        modalKey: new ModalKey(ModalType.UsedLibraryViewModal, key),
        fullScreen: false,
        parametersMap: {
            usedLibraryId: usedLibraryId,
            displayXButton: displayXButton,
            displayBackButton: displayBackButton,
        },
    };
}

/**
 * Opens the change log modal.
 */
export function openChangeLogModalAction(
    key: string,
    displayXButton?: boolean,
    displayBackButton?: boolean
): OpenModalAction {
    return {
        type: ActionType.OpenModal,
        modalKey: new ModalKey(ModalType.ChangeLogModal, key),
        fullScreen: false,
        parametersMap: {
            displayXButton: displayXButton,
            displayBackButton: displayBackButton,
        },
    };
}

/**
 * Opens the cve view modal.
 */
export function openCveViewModalAction(
    key: string,
    cveId: string,
    displayXButton?: boolean,
    displayBackButton?: boolean
): OpenModalAction {
    return {
        type: ActionType.OpenModal,
        modalKey: new ModalKey(ModalType.CveViewModal, key),
        fullScreen: false,
        parametersMap: {
            cveId: cveId,
            displayXButton: displayXButton,
            displayBackButton: displayBackButton,
        },
    };
}

/**
 * Opens the library histories modal.
 */
export function openLibraryHistoriesModal(
    key: string,
    library: LibraryInfo,
    displayXButton?: boolean,
    displayBackButton?: boolean
): OpenModalAction {
    return {
        type: ActionType.OpenModal,
        modalKey: new ModalKey(ModalType.LibraryHistoriesModal, key),
        fullScreen: false,
        parametersMap: {
            library: library,
            displayXButton: displayXButton,
            displayBackButton: displayBackButton,
        },
    };
}

/**
 * Opens the activities list modal.
 */
export function openActivitiesListModal(
    key: string,
    preloadedEntities: ActivityInfo[],
    displayXButton?: boolean,
    displayBackButton?: boolean
): OpenModalAction {
    return {
        type: ActionType.OpenModal,
        modalKey: new ModalKey(ModalType.ActivitiesListModal, key),
        fullScreen: false,
        parametersMap: {
            preloadedEntities: preloadedEntities,
            displayXButton: displayXButton,
            displayBackButton: displayBackButton,
        },
    };
}

/**
 * Opens the cves list modal.
 */
export function openCvesListModal(
    key: string,
    cvesFilter: CvesFilter,
    displayXButton?: boolean,
    displayBackButton?: boolean
): OpenModalAction {
    return {
        type: ActionType.OpenModal,
        modalKey: new ModalKey(ModalType.CvesListModal, key),
        fullScreen: false,
        parametersMap: {
            cvesFilter: cvesFilter,
            displayXButton: displayXButton,
            displayBackButton: displayBackButton,
        },
    };
}

/**
 * Opens the devices list modal.
 */
export function openDevicesListModal(
    key: string,
    displayXButton?: boolean,
    displayBackButton?: boolean
): OpenModalAction {
    return {
        type: ActionType.OpenModal,
        modalKey: new ModalKey(ModalType.DevicesListModal, key),
        fullScreen: false,
        parametersMap: {
            displayXButton: displayXButton,
            displayBackButton: displayBackButton,
        },
    };
}

/**
 * Opens the create trigger modal.
 */
export function openCreateTriggerModal(
    key: string,
    displayXButton?: boolean,
    displayBackButton?: boolean
): OpenModalAction {
    return {
        type: ActionType.OpenModal,
        modalKey: new ModalKey(ModalType.CreateTriggerModal, key),
        fullScreen: false,
        parametersMap: {
            displayXButton: displayXButton,
            displayBackButton: displayBackButton,
        },
    };
}

/**
 * Opens the outgoing webhook configuration modal.
 */
export function openOutgoingWebhookConfigurationModal(
    key: string,
    existingOutgoingWebhook?: OutgoingWebhookInfo,
    displayXButton?: boolean,
    displayBackButton?: boolean
): OpenModalAction {
    return {
        type: ActionType.OpenModal,
        modalKey: new ModalKey(ModalType.OutgoingWebhookConfigurationModal, key),
        fullScreen: false,
        parametersMap: {
            displayXButton: displayXButton,
            displayBackButton: displayBackButton,
            existingOutgoingWebhook: existingOutgoingWebhook,
        },
    };
}

/**
 * Opens create new device definition configuration modal.
 */
export function openDeviceDefinitionModal(
    key: string,
    deviceDefinition?: DeviceDefinitionInfo,
    displayXButton?: boolean,
    displayBackButton?: boolean,
    callbackFunction?: () => void
): OpenModalAction {
    return {
        type: ActionType.OpenModal,
        modalKey: new ModalKey(ModalType.CreateDeviceDefinitionModal, key),
        fullScreen: false,
        parametersMap: {
            displayXButton: displayXButton,
            deviceDefinition: deviceDefinition,
            displayBackButton: displayBackButton,
            callbackFunction: callbackFunction,
        },
    };
}

/**
 * Opens edit existing device definition configuration modal.
 */

export function editDeviceDefinitionModal(
    key: string,
    deviceDefinitionVersionId?: string,
    displayXButton?: boolean,
    displayBackButton?: boolean,
    callbackFunction?: () => void
): OpenModalAction {
    return {
        type: ActionType.OpenModal,
        modalKey: new ModalKey(ModalType.EditDeviceDefinitionModal, key),
        fullScreen: false,
        parametersMap: {
            displayXButton: displayXButton,
            deviceDefinitionVersionId: deviceDefinitionVersionId,
            displayBackButton: displayBackButton,
            callbackFunction: callbackFunction,
        },
    };
}

/**
 * Opens the new API key modal.
 */
export function openNewAPIKeyModal(key: string, displayXButton?: boolean): OpenModalAction {
    return {
        type: ActionType.OpenModal,
        modalKey: new ModalKey(ModalType.NewAPIKeyModal, key),
        fullScreen: false,
        parametersMap: {
            displayXButton: displayXButton,
            displayBackButton: false,
        },
    };
}

/**
 * Opens the change password modal.
 */
export function openChangePasswordModal(
    key: string,
    displayXButton?: boolean,
    displayBackButton?: boolean
): OpenModalAction {
    return {
        type: ActionType.OpenModal,
        modalKey: new ModalKey(ModalType.ChangePasswordModal, key),
        fullScreen: false,
        parametersMap: {
            displayXButton: displayXButton,
            displayBackButton: displayBackButton,
        },
    };
}

/**
 * Opens the alert description modal
 */
export function openAlertDescriptionModal(
    key: string,
    alertId: string,
    deviceDefinitionVersionId: string,
    createdFrom: number,
    createdTo: number,
    deviceDefinitionInfo: DeviceDefinitionInfo,
    alertDisplayName: string
): OpenModalAction {
    return {
        type: ActionType.OpenModal,
        modalKey: new ModalKey(ModalType.AlertDescriptionModal, key),
        fullScreen: false,
        parametersMap: {
            alertId: alertId,
            deviceDefinitionVersionId: deviceDefinitionVersionId,
            createdFrom: createdFrom,
            deviceDefinitionInfo: deviceDefinitionInfo,
            alertDisplayName: alertDisplayName,
        },
    };
}

/**
 * Opens the alert description modal
 */
export function openGoProModal(key: string): OpenModalAction {
    return {
        type: ActionType.OpenModal,
        modalKey: new ModalKey(ModalType.GoProModal, key),
        fullScreen: false,
        parametersMap: {},
    };
}

/**
 * Opens the alert description modal
 */
export function openSimulateAttackModal(key: string): OpenModalAction {
    return {
        type: ActionType.OpenModal,
        modalKey: new ModalKey(ModalType.SimulateAttackModal, key),
        fullScreen: false,
        parametersMap: {},
    };
}

/**
 * Opens the get support modal
 */
export function openGetSupportModal(key: string, onSuccess?: () => unknown): OpenModalAction {
    return {
        type: ActionType.OpenModal,
        modalKey: new ModalKey(ModalType.GetSupportModal, key),
        fullScreen: false,
        parametersMap: {
            onSuccess,
        },
    };
}

/**
 * Opens the get support modal
 */
export function openInteractiveCoverageModal(
    key: string,
    plot?: "prevention" | "detection",
    category?: string
): OpenModalAction {
    return {
        type: ActionType.OpenModal,
        modalKey: new ModalKey(ModalType.InteractiveCoverageModal, key),
        fullScreen: false,
        parametersMap: {
            plot,
            category,
        },
    };
}
