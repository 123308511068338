import { WithStyles, withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import ServiceWire from "../../../lib/services/ServiceWire";
import Utils from "../../../lib/infra/Utils";
import { CalculateAggregationResponse } from "../../../lib/services/visualisation/CalculateAggregationResponse";
import { GlobalState } from "../../../lib/state/GlobalState";
import AggregationFunctionType from "../../../lib/state/Visualisation/AggregationFunctionType";
import KeyMetric from "../../KeyMetric/KeyMetric";
import UIDataVisualisationConfiguration from "../../VisualisationConfigurationComponent/entities/UIDataVisualisationConfiguration";
import GraphLoadingDisplay from "../../VisualisationConfigurationComponent/GraphLoadingDisplay/GraphLoadingDisplay";
import getVisualisationDataSource from "../../VisualisationConfigurationComponent/utils/getVisualisationDataSource";
import { isChangedDataSourceConfiguration } from "../../VisualisationConfigurationComponent/utils/isChangedDataSourceConfiguration";
import visualisationConfigurationPresentationMetricStyle from "./VisualisationConfigurationPresentationMetricStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    loadingMetric: boolean;
    errorLoadingMetric: boolean;

    metricValue: CalculateAggregationResponse;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof visualisationConfigurationPresentationMetricStyle> {
    /**
     * On which entity (device or device definition) are we displaying the graph.
     */
    entityId: string;

    time: {
        /**
         * On which date to start the filtering.
         */
        startTime: number;

        /**
         * On which date to end the filtering.
         */
        endTime: number;
    };

    dataVisualisationConfigurations: UIDataVisualisationConfiguration[];

    isCursorAllowed?: boolean;
    refreshComponentId?: number;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {};
};

/**
 * Displays a bar of metrics.
 */
class VisualisationConfigurationPresentationMetric extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            loadingMetric: false,
            errorLoadingMetric: false,

            metricValue: {
                DEFAULT: {
                    result: 0.0,
                },
            },
        };
    }

    /**
     * Occurs once the component is mounted.
     */
    async componentDidMount() {
        await this.loadMetric(this.props.time.startTime, this.props.time.endTime);
    }

    /**
     * Occurs once the component is about to receive props.
     */
    async componentDidUpdate(nextProps: Readonly<AppProps>, prevState: Readonly<AppState>) {
        const nextDataVisualisationConfigurations: UIDataVisualisationConfiguration[] =
            nextProps.dataVisualisationConfigurations || [];

        const changedDataSource =
            nextDataVisualisationConfigurations.length !== this.props.dataVisualisationConfigurations.length ||
            nextDataVisualisationConfigurations.some(
                (newConfiguration, index) =>
                    !this.props.dataVisualisationConfigurations[index] ||
                    isChangedDataSourceConfiguration(
                        newConfiguration,
                        this.props.dataVisualisationConfigurations[index]
                    )
            );

        if (
            this.props.refreshComponentId !== nextProps.refreshComponentId ||
            nextProps.time.startTime !== this.props.time.startTime ||
            nextProps.time.endTime !== this.props.time.endTime ||
            changedDataSource ||
            nextProps.entityId !== this.props.entityId
        ) {
            await this.loadMetric(this.props.time.startTime, this.props.time.endTime);
        }
    }

    private getMetricCardSize = (metricKeys: string[]) => {
        if (metricKeys.length === 1) {
            return "large";
        }

        if (metricKeys.length === 2) {
            return "medium";
        }

        return "small";
    };

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        for (let i = 0; i < this.props.dataVisualisationConfigurations.length; ++i) {
            if (
                this.props.dataVisualisationConfigurations[i].aggregationFunctionType ===
                AggregationFunctionType.UNIQUE_COUNT
            ) {
                return (
                    <Typography className={classNames(classes.commonDangerColor)}>
                        Can not show metrics for Unique Count.
                    </Typography>
                );
            }
        }

        if (this.state.loadingMetric || this.state.errorLoadingMetric) {
            return <GraphLoadingDisplay />;
        }

        const dataSourceKeyToConfiguration: Record<string, UIDataVisualisationConfiguration> = {};
        const allDataSourceKeys: string[] = [];
        // Show if it's count or operation have trace and argument id's
        this.props.dataVisualisationConfigurations.forEach((dataVisualisationConfiguration) => {
            if (
                dataVisualisationConfiguration.aggregationFunctionType === "COUNT" ||
                (dataVisualisationConfiguration.aggregatedTraceDefinitionId &&
                    dataVisualisationConfiguration.aggregatedArgumentDefinitionId)
            ) {
                dataSourceKeyToConfiguration[dataVisualisationConfiguration.dataSourceKey] =
                    dataVisualisationConfiguration;
                allDataSourceKeys.push(dataVisualisationConfiguration.dataSourceKey);
            }
        });

        return (
            <div className={classNames(classes.root)}>
                {allDataSourceKeys.map((dataSourceKey) => {
                    const metricValue = this.state.metricValue?.[dataSourceKey] || 0.0;

                    return (
                        <KeyMetric
                            key={dataSourceKey}
                            size={this.getMetricCardSize(allDataSourceKeys)}
                            metricId={dataSourceKey}
                            metricValue={metricValue.toString()}
                            deltaValue={undefined}
                            captionText={dataSourceKeyToConfiguration[dataSourceKey].graphLabel}
                            isPositive={false}
                            isNegative={false}
                            isDeltaPositive={false}
                            isDeltaNegative={false}
                            isSelected={false}
                            notClickable={true}
                            isCursorAllowed={this.props.isCursorAllowed}
                        />
                    );
                })}
            </div>
        );
    }

    /**
     * Loads the graph of the aggregated events.
     */
    private async loadMetric(startTime: number, endTime: number) {
        try {
            // Setting state to loading.
            this.setState({
                loadingMetric: true,
                errorLoadingMetric: false,
            });

            let hourDifference = (endTime - startTime) / 1000.0 / 60.0 / 60.0;
            let amountOfGraphPointsToDisplay = 60;
            let timeFormat = "HH:mm";

            if (hourDifference > 24) {
                timeFormat = "MM/dd HH:mm";
            }

            const graphResult = await ServiceWire.getVisualisationApiService().getArgumentValueOverTime(
                this.props.entityId,
                startTime,
                endTime,
                false,
                timeFormat,
                amountOfGraphPointsToDisplay,
                (this.props.dataVisualisationConfigurations || []).map((dataVisualisationConfiguration) =>
                    getVisualisationDataSource(dataVisualisationConfiguration)
                )
            );

            // Set state to finish loading.
            this.setState({
                metricValue: graphResult.data_sources,
                loadingMetric: false,
                errorLoadingMetric: false,
            });
        } catch (error) {
            // Set error state.
            this.setState({
                loadingMetric: false,
                errorLoadingMetric: true,
            });
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(visualisationConfigurationPresentationMetricStyle)(VisualisationConfigurationPresentationMetric));
