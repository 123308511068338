import { makeStyles, Theme } from "@material-ui/core";

export const useLoadingContainerStyle = makeStyles((theme: Theme) => ({
    loadingContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
    },
}));
