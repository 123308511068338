import { makeStyles } from "@material-ui/styles";

export const useGraphHeaderItemStyle = makeStyles({
    headerContainer: {
        display: "flex",
        alignItems: "center",
        gap: 12,
    },
    text: {
        flex: 1,
        fontSize: 14,
        fontWeight: 400,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
});
