class ReceivedDefinitionSummary {
    /**
     * Constructor.
     */
    constructor(public identifier: string, public displayName: string, public entityType: string) {}

    /**
     * Converts json to entity object.
     */
    public static fromJsonObject(jsonObject: Object) {
        return new ReceivedDefinitionSummary(
            jsonObject["identifier"],
            jsonObject["display_name"],
            jsonObject["entity_type"]
        );
    }
}

export default ReceivedDefinitionSummary;
