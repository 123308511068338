import { withStyles, WithStyles } from "@material-ui/core";
import { createBrowserHistory } from "history";
import * as React from "react";
import * as am4core from "@amcharts/amcharts4/core";
import { connect } from "react-redux";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { setUserInfoAction } from "../lib/redux/users/SetUserAction";
import AnalyticsService from "../lib/services/AnalyticsService";
import ServiceWire from "../lib/services/ServiceWire";
import { GlobalState } from "../lib/state/GlobalState";
import LoginStateEnum from "../lib/state/LoginStateEnum";
import UserInfo from "../lib/state/UserInfo";
import ApiDocumentationPage from "./ApiDocumentationPage/ApiDocumentationPage";
import AppContent from "./AppContent/AppContent";
import appStyle from "./AppStyle";
import BoardsPage from "./BoardsPage/BoardsPage";
import { Dashboard } from "./Dashboard";
import SingleDashboardPage from "./DashboardsPage/SingleDashboardPage/SingleDashboardPage";
import DeviceDefinitionsDashboard from "./DeviceDefinitionsDashboard/DeviceDefinitionsDashboard";
import DeviceDefinitionViewPage from "./DeviceDefinitionViewPage/DeviceDefinitionViewPage";
import DevicesPage from "./DevicesPage/DevicesPage";
import DeviceViewPage from "./DeviceViewPage/DeviceViewPage";
import FleetView from "./FleetView/FleetView";
import IssuesPage from "./IssuesPage/IssuesPage";
import LibrariesDashboard from "./LibrariesDashboard/LibrariesDashboard";
import { LoginManager } from "./Login";
import NoPageFound from "./NoPageFound/NoPageFound";
import PrivateRoute from "./PrivateRoute";
import ServerDown from "./ServerDown/ServerDown";
import { SettingsPage } from "./SettingsPage";
import { SignupManager, SignupStage } from "./Signup";
import TokenExpired from "./TokenExpirationPage/TokenExpired";
import { PremiumUpgrade } from "./PremiumUpgrade";
import { GetSupport } from "./GetSupport/GetSupportPage";
import { HomePage } from "./HomePage";
import { SignupUserGuide } from "./SignupUserGuide";
import { HowItWorksPage } from "./HowItWorksPage";
import { NotificationSnackbar } from "./NotificationSnackbar/";
import { DataPrivacyPage } from "./DataPrivacyPage";
import SternumConfiguration from "../lib/infra/SternumConfiguration";
import { LoadingContainer } from "./LoadingContainer";
import { SternumLogoGradientIcon } from "./SUI/SternumIcon";

const history = createBrowserHistory();

history.listen((location) => {
    AnalyticsService.trackPageView(location.pathname);
});

am4core.addLicense("CH358457796");
am4core.addLicense("MP358457796");

/**
 * The custom theme for sternum.
 * The reason to have it outside of createMuiTheme() at the moment is the inability
 * to make typescript digest the addition of variables to the theme elegantly.
 * This will be revisited in the future, as theme is the right way to put these
 * custom properties in.
 */
export const sternumTheme = {
    lightGrey: {
        main: "#f9f9f7",
        light: "#f9f9f7",
        dark: "#f9f9f7",
        contrastText: "#f9f9f7",
    },
    grey: {
        main: "#e9ecf1",
        light: "#e9ecf1",
        dark: "#e9ecf1",
        contrastText: "#e9ecf1",
    },
    success: {
        main: "#23930d",
        light: "#23930d",
        dark: "#23930d",
        contrastText: "#23930d",
    },
    warning: {
        main: "#e7b416",
        light: "#e7b416",
        dark: "#e7b416",
        contrastText: "#e7b416",
    },
    danger: {
        main: "#d10027",
        light: "#d10027",
        dark: "#d10027",
        contrastText: "#d10027",
    },
    enabled: {
        main: "#00b16a",
        light: "#00b16a",
        dark: "#00b16a",
        contrastText: "#00b16a",
    },
    disabled: {
        main: "rgb(149, 165, 166)",
        light: "rgb(149, 165, 166)",
        dark: "rgb(149, 165, 166)",
        contrastText: "rgb(149, 165, 166)",
    },
    brand: {
        main: "#ed4276",
        light: "#ed4276",
        dark: "#ed4276",
        contrastText: "#ed4276",
    },
};

/**
 * Holds the inner state for our app.
 */
interface AppState {
    initialLoading: boolean;
    serverDown: boolean;
    unauthorised: boolean;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof appStyle> {
    setUserInfoAction?: (user: UserInfo) => void;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return { setUserInfoAction: (user) => dispatch(setUserInfoAction(user)) };
};

/**
 * The App component - initial component for our react application.
 */
class App extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the service wire with the unauthorised callback.
        ServiceWire.initialize(() => this.handleUnauthorised());

        // Initializing the state to default.
        this.state = {
            initialLoading: true,
            serverDown: false,
            unauthorised: false,
        };
    }

    /**
     * Occurs once the component finished its initialization process.
     */
    async componentDidMount() {
        try {
            // Making sure we are authenticated!
            await ServiceWire.getAuthenticationService().fetchAuthenticatedUser();

            const fetchedUser = ServiceWire.getAuthenticationService().getCachedAuthenticatedUser();
            if (fetchedUser) {
                this.props.setUserInfoAction(fetchedUser);
            }

            // Start only if we authenticated
            if (ServiceWire.getAuthenticationService().isAuthenticated()) {
                // Load clients for application.
                await ServiceWire.getClientsService().loadClients();

                if (ServiceWire.getClientsService().getSelectedClient()?.entityId) {
                    // Fetch the roles of a user in a client.
                    await ServiceWire.getAuthorizationService().fetchUserRoles();
                }

                // Starting the polling service, fetching changes.
                ServiceWire.getPollingService().startPolling();

                ServiceWire.getDeviceMetricsPollingService().startPolling();

                // Starting the websocket service, listening for changes.
                ServiceWire.getWebsocketService().runWebsocket();

                // Loading configurations.
                await ServiceWire.getConfigurationService().loadConfigurations();

                // Set user ID for analytics
                AnalyticsService.setUserInfo(fetchedUser.userId, fetchedUser.defaultClient.clientId);
            }

            this.setState({
                initialLoading: false,
            });
        } catch (error) {
            this.setState({
                serverDown: true,
            });
        }
    }

    /**
     * Occurs once the component is about to be unmounted (destroyed).
     */
    componentWillUnmount() {
        // Stop checking for changes in API.
        ServiceWire.getPollingService().stopPolling();
        ServiceWire.getDeviceMetricsPollingService().stopPolling();
    }

    /**
     *
     */

    /**
     * Renders the component.
     */
    render() {
        let appContent = {};
        const selectedClient = ServiceWire.getClientsService().getSelectedClient();

        const plgEnabled = SternumConfiguration.getPLGEnabled();

        if (this.state.initialLoading) {
            appContent = (
                <LoadingContainer>
                    <SternumLogoGradientIcon width={72} height={72} useAnimation={true} />
                </LoadingContainer>
            );
        } else if (this.state.serverDown) {
            // Health check failed, server is down.
            // Displaying server down page.
            appContent = <ServerDown />;
        } else if (this.state.unauthorised && !ServiceWire.getAuthenticationService().isAuthenticated()) {
            window.location.reload();
        } else {
            // Otherwise, we load our app.
            appContent = (
                <Router history={history}>
                    <Switch>
                        {/* Default route */}
                        <Route exact path={"/"}>
                            {this.getDefaultRoute()}
                        </Route>

                        {/* Home route */}
                        {plgEnabled && <Route exact path={"/home"} render={(props) => <HomePage {...props} />} />}

                        {/* Signup */}
                        {plgEnabled && <Route exact path="/signup" render={(props) => <SignupManager {...props} />} />}

                        {/* Premium Upgrade */}
                        {plgEnabled && <PrivateRoute exact path="/premium" component={PremiumUpgrade} />}

                        {/* User guide */}
                        <PrivateRoute exact path="/user-guide" component={SignupUserGuide} />

                        {/* Create password */}
                        <Route
                            exact
                            path="/signup/setup"
                            render={(props) => <SignupManager {...props} signupStage={SignupStage.CreatePassword} />}
                        />

                        {/* Login */}
                        <Route exact path="/login" render={(props) => <LoginManager {...props} />} />

                        {/* Invite */}
                        <Route
                            exact
                            path="/invite"
                            render={(props) => <SignupManager {...props} signupStage={SignupStage.InvitationSetup} />}
                        />

                        {/* Reset Password */}
                        <Route
                            exact
                            path="/reset-password"
                            render={(props) => <LoginManager {...props} loginState={LoginStateEnum.RESET_PASSWORD} />}
                        />

                        {/* MFA */}
                        <Route
                            exact
                            path="/mfa"
                            render={(props) => <LoginManager {...props} loginState={LoginStateEnum.MFA} />}
                        />

                        {/* Token expired */}
                        <Route exact path="/token_expired" component={TokenExpired} />

                        {/* Boards */}
                        <PrivateRoute exact path="/boards" component={BoardsPage} />

                        {/* Dashboard */}
                        <PrivateRoute
                            exact
                            path="/dashboard"
                            renderContentComponent={(sideBarOpen) => <Dashboard sidebarOpen={sideBarOpen} />}
                            component={AppContent}
                        />

                        {/* Fleet View */}
                        <PrivateRoute
                            exact
                            path="/fleet-view/:deviceDefinitionVersionId"
                            premiumAccessRoute
                            renderContentComponent={(sideBarOpen) => <FleetView sidebarOpen={sideBarOpen} />}
                            component={AppContent}
                        />

                        {/* Fleet View */}
                        <PrivateRoute
                            exact
                            path="/fleet-view/"
                            premiumAccessRoute
                            renderContentComponent={(sideBarOpen) => <FleetView sidebarOpen={sideBarOpen} />}
                            component={AppContent}
                        />

                        {/* Dashboard View
                         */}

                        <PrivateRoute
                            path="/glances/:dashboardId"
                            renderContentComponent={(sideBarOpen) => <SingleDashboardPage sideBarOpen={sideBarOpen} />}
                            component={AppContent}
                        />

                        {/* Dashboards */}
                        <PrivateRoute
                            path="/glances"
                            renderContentComponent={(sideBarOpen) => <SingleDashboardPage sideBarOpen={sideBarOpen} />}
                            component={AppContent}
                        />

                        {/* Devices */}
                        <PrivateRoute
                            path="/devices"
                            premiumAccessRoute
                            renderContentComponent={(sideBarOpen) => <DevicesPage sideBarOpen={sideBarOpen} />}
                            component={AppContent}
                        />

                        {/* Device View */}
                        <PrivateRoute
                            path="/device/:deviceId"
                            premiumAccessRoute
                            renderContentComponent={(sideBarOpen) => <DeviceViewPage sideBarOpen={sideBarOpen} />}
                            component={AppContent}
                        />

                        {/* Issues */}
                        <PrivateRoute
                            path="/alerts"
                            premiumAccessRoute
                            renderContentComponent={(sideBarOpen) => <IssuesPage sideBarOpen={sideBarOpen} />}
                            component={AppContent}
                        />

                        {/* Device Definition View */}
                        <PrivateRoute
                            path="/device-profile/:deviceDefinitionVersionId"
                            premiumAccessRoute
                            renderContentComponent={(sideBarOpen) => (
                                <DeviceDefinitionViewPage sideBarOpen={sideBarOpen} />
                            )}
                            component={AppContent}
                        />

                        {/* Device Definitions */}
                        <PrivateRoute
                            exact
                            path="/device-profiles"
                            premiumAccessRoute
                            renderContentComponent={(sideBarOpen) => (
                                <DeviceDefinitionsDashboard sidebarOpen={sideBarOpen} />
                            )}
                            component={AppContent}
                        />

                        {/* Libraries */}
                        <PrivateRoute
                            exact
                            path="/libraries"
                            premiumAccessRoute
                            renderContentComponent={(sideBarOpen) => <LibrariesDashboard sidebarOpen={sideBarOpen} />}
                            component={AppContent}
                        />

                        {/* Settings */}
                        <PrivateRoute
                            exact
                            path="/settings"
                            renderContentComponent={(sideBarOpen) => <SettingsPage sideBarOpen={sideBarOpen} />}
                            component={AppContent}
                        />

                        {/* Get Support */}
                        <PrivateRoute
                            exact
                            path="/get-support"
                            renderContentComponent={(sideBarOpen) => <GetSupport sideBarOpen={sideBarOpen} />}
                            component={AppContent}
                        />

                        {/* How it works */}
                        <Route exact path="/how-it-works" component={HowItWorksPage} />

                        {/* Data privacy */}
                        <Route exact path="/data-privacy" component={DataPrivacyPage} />

                        {/* Settings */}
                        <PrivateRoute
                            exact
                            path="/api-docs"
                            premiumAccessRoute
                            renderContentComponent={(sideBarOpen) => <ApiDocumentationPage sidebarOpen={sideBarOpen} />}
                            component={AppContent}
                        />

                        {/* Default - no page found */}
                        <Route component={NoPageFound} />
                    </Switch>
                </Router>
            );
        }

        return (
            <>
                {appContent}
                <NotificationSnackbar />
            </>
        );
    }

    /**
     * Occurs when a request to the server returns with 401 unauthorised.
     * @returns {Promise<void>}
     */
    async handleUnauthorised() {
        // Try to fetch current user, if it's null (session is over or the given token is not valid) redirect to login page
        await ServiceWire.getAuthenticationService().fetchAuthenticatedUser();
        if (!ServiceWire.getAuthenticationService().isAuthenticated()) {
            // Clear user's data from local storage
            ServiceWire.getAuthenticationService().clearDataFromStorage();
            ServiceWire.getAuthenticationService().clearAuthenticatedUser();
            ServiceWire.getClientsService().clearSelectedClient();
            ServiceWire.getPollingService().stopPolling();
            this.setState({
                unauthorised: true,
            });
        }
    }

    /**
     * Gets the route for default page.
     */
    private getDefaultRoute() {
        if (ServiceWire.getAuthenticationService().isAuthenticated()) {
            let selectedClient = ServiceWire.getClientsService().getSelectedClient();

            if (selectedClient) {
                return <Redirect to={"/dashboard"} />;
            } else {
                return <Redirect to={"/boards"} />;
            }
        } else {
            return <Redirect to={"/login"} />;
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(appStyle)(App));
