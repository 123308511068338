import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Step } from "react-joyride";
import classNames from "classnames";
import { EventEmitter } from "eventemitter3";
import { Box, Typography } from "@material-ui/core";

import { InfoCircleIcon, PencilIcon } from "../SUI/SternumIcon";
import IssueInfo from "../../lib/state/IssueInfo";
import {
    IssueInfoPutReason,
    IssueInfoReasonType,
    IssueInfoStateStatus,
    IssueInfoStatus,
} from "../../lib/state/IssueInfoType";
import { getIssueResolveReasonsAction } from "../../lib/redux/issues/GetIssueResolveReasonsAction";
import { GlobalState } from "../../lib/state/GlobalState";
import { putIssueStatusAction } from "../../lib/redux/issues/PutIssueStatusAction";
import {
    ResolvingReasons,
    ResolvingReasonsAddItem,
    ResolvingReasonsCustomItem,
    ResolvingReasonsItem,
} from "../TraceView/ResolvingReasons";
import { useCommonStyle } from "../CommonStyle";
import { SternumTooltip } from "../SUI/SternumTooltip";
import { SwitchSelector } from "../SUI/SwitchSelector";
import { getMappedValue } from "../../utils";
import { useAutoUpdatedRef } from "../../hooks";
import ConfirmationDialog from "../../shared_components/ConfirmationDialog/ConfirmationDialog";
import SternumImprovedButton from "../SUI/SternumImprovedButton/SternumImprovedButton";
import { NotificationMessage, NotificationVariant } from "../../lib/state/NotificationsState";
import { showNotificationAction } from "../../lib/redux/notifications/ShowNotificationAction";
import { TraceViewAlertAnomalyStatusEmitter } from "./TraceViewAlertAnomalyStatus.emitter";
import { AnomalyOnboarding } from "../AnomalyOnboarding";
import {
    AlertAnomalyAction,
    AlertAnomalyStatusEmitter,
    CustomReason,
    DefaultIssueReason,
    IsSelectedByDefaultIssueReasonType,
    ReasonsDefaultAndCustom,
} from "./TraceViewAlertStatus.types";
import { useTraceViewAlertStatusStyle } from "./TraceViewAlertStatus.style";
import { reasonsDataByIssueReasonType } from "./TraceViewAlertStatus.model";

export interface TraceViewAlertAnomalyStatusProps {
    className?: string;
    isResolving?: boolean;
    initialOpen?: boolean;
    isAlertUpdatedLocally?: boolean;
    onChangeIsAlertUpdatedLocally?: (newIsAlertUpdatedLocally: boolean) => unknown;
    savedLocalStatus?: IssueInfoStatus;
    onChangeSavedLocalStatus?: (newLocalStatus?: IssueInfoStatus) => unknown;
    savedLocalReasons?: ReasonsDefaultAndCustom;
    onChangeSavedLocalReasons?: (newLocalReasons?: ReasonsDefaultAndCustom) => unknown;
    onRefresh?: () => unknown;
    onSetIsResolving?: (isResolving: boolean) => unknown;
    onCloseWithoutChange?: () => unknown;
}

export const alertAnomalyStatusEmitter = new EventEmitter<AlertAnomalyStatusEmitter>();

const mapStateToProps = (state: GlobalState, ownProps: TraceViewAlertAnomalyStatusProps) => {
    const issueId = state.issues.selectedIssue?.issueId || state.ui.currentlyViewedIssueId;

    return {
        selectedIssue: state.issues.selectedIssue, // Can be undefined
        selectedIssueState: state.issues.issueStateByIssueId.get(issueId), // Can be null or undefined
        issueResolveReasons: state.issues.issueResolveReasons.data,
        issueResolveReasonsStatus: state.issues.issueResolveReasons.status,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        putIssueInfoStatus: (
            issueId: string,
            status: IssueInfoStatus,
            reasons?: IssueInfoPutReason[],
            onDone?: () => unknown
        ) => dispatch(putIssueStatusAction({ issueId, status, reasons, onDone })),
        getIssueResolveReasons: () => dispatch(getIssueResolveReasonsAction()),
        showNotification: (message: NotificationMessage, variant?: NotificationVariant) => {
            dispatch(showNotificationAction(message, variant));
        },
    };
};

type TraceViewAlertStatusPropsWithHOC = TraceViewAlertAnomalyStatusProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {};

export const TraceViewAlertAnomalyStatus: React.FC<TraceViewAlertAnomalyStatusProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(TraceViewAlertAnomalyStatusComponent);

function TraceViewAlertAnomalyStatusComponent({
    className,
    selectedIssue,
    selectedIssueState,
    issueResolveReasons,
    putIssueInfoStatus,
    getIssueResolveReasons,
    isAlertUpdatedLocally = false,
    onChangeIsAlertUpdatedLocally,
    savedLocalStatus,
    onChangeSavedLocalStatus,
    savedLocalReasons,
    onChangeSavedLocalReasons,
    onRefresh,
    isResolving: isResolvingFromProps,
    onSetIsResolving,
    onCloseWithoutChange,
    initialOpen = false,
    showNotification,
}: TraceViewAlertStatusPropsWithHOC) {
    const classes = useTraceViewAlertStatusStyle();
    const classesCommon = useCommonStyle();
    const issueStatus = savedLocalStatus || getIssueInfoStatus(selectedIssue);
    const savedReasons = savedLocalReasons || getReasons(selectedIssue);

    const [isResolvingState, setIsResolvingState] = useState(initialOpen);
    const [customReasons, setCustomReasons] = useState<CustomReason[]>([]);
    const [isResolveIssueDialogVisible, setIsResolveIssueDialogVisible] = useState(false);
    const [isResolveWithNoReasonDialogVisible, setIsResolveWithNoReasonDialogVisible] = useState(false);
    // const [isSaveChangesDialogVisible, setIsSaveChangesDialogVisible] = useState(false);
    const [selectedAlertAnomalyAction, setSelectedAlertAnomalyAction] = useState<AlertAnomalyAction>(
        AlertAnomalyAction.Resolve
    );
    const [isSelectedByDefaultIssueReasonType, setIsSelectedByDefaultIssueReasonType] =
        useState<IsSelectedByDefaultIssueReasonType>(getSelectedByDefaultIssueReasonType());

    function getSelectedByDefaultIssueReasonType(): IsSelectedByDefaultIssueReasonType {
        return savedReasons.default.reduce((acc, defaultReasonData) => {
            acc[defaultReasonData.type] = true;
            return acc;
        }, {});
    }

    const isResolving = isResolvingFromProps ?? isResolvingState;
    const setIsResolving = (isResolving: boolean) => {
        if (isResolving) {
            const newIsSelectedByDefaultIssueReasonType = getSelectedByDefaultIssueReasonType();

            setCustomReasons([...savedReasons.custom]);
            setIsSelectedByDefaultIssueReasonType(newIsSelectedByDefaultIssueReasonType);
        }

        if (onSetIsResolving) {
            return onSetIsResolving(isResolving);
        }

        setIsResolvingState(isResolving);
    };

    useEffect(
        () => () => {
            onSetIsResolving(false);
        },
        []
    );

    // we want this to be called synchronously,
    // because right after render we may call this
    useLayoutEffect(() => {
        const onChange = (action: AlertAnomalyAction) => {
            setSelectedAlertAnomalyAction(action);
        };
        TraceViewAlertAnomalyStatusEmitter.addListener("setSelectedAlertAnomalyAction", onChange);

        return () => {
            TraceViewAlertAnomalyStatusEmitter.removeListener("setSelectedAlertAnomalyAction", onChange);
        };
    }, []);

    const { defaultIssueResolveReasons, canAddCustomReasons } = useMemo(() => {
        const canAddCustomReasons = issueResolveReasons.some(
            (issueResolve) => issueResolve.type === IssueInfoReasonType.Other
        );

        const defaultIssueResolveReasons = issueResolveReasons
            .filter((issuesResolve) => issuesResolve.type !== IssueInfoReasonType.Other)
            .map(
                (issueResolve): DefaultIssueReason => ({
                    type: issueResolve.type,
                    title: issueResolve.text || reasonsDataByIssueReasonType[issueResolve.type]?.title || "",
                })
            )
            .filter((issuesResolve) => issuesResolve.title);

        return { canAddCustomReasons, defaultIssueResolveReasons };
    }, [issueResolveReasons]);

    const clearDefaultReasons = () => {
        setIsSelectedByDefaultIssueReasonType({});
    };

    const clearCustomReasons = () => {
        setCustomReasons([]);
    };

    const closeResolvingView = () => {
        setIsResolving(false);
        // setIsSaveChangesDialogVisible(false);
        clearDefaultReasons();
        clearCustomReasons();
    };

    const setIsSelectedDefaultIssue = (issueReasonType: IssueInfoReasonType, isSelected: boolean) => {
        if (isSelected) {
            setIsSelectedByDefaultIssueReasonType({ ...isSelectedByDefaultIssueReasonType, [issueReasonType]: true });
            return;
        }

        const newState = { ...isSelectedByDefaultIssueReasonType };
        delete newState[issueReasonType];

        setIsSelectedByDefaultIssueReasonType(newState);
    };

    useEffect(() => {
        getIssueResolveReasons();
    }, []);

    const addCustomReason = (title: string) => {
        if (title) {
            setCustomReasons((reasons) => [...reasons, { title, isSelected: true }]);
        }
    };

    const deleteCustomReason = (customReasonIndex: number) => {
        const newCustomReasons = [...customReasons];

        newCustomReasons.splice(customReasonIndex, 1);
        setCustomReasons(newCustomReasons);
    };

    const setCustomReasonSelection = (customReasonIndex: number, isSelected: boolean) => {
        const newCustomReasons = [...customReasons];

        newCustomReasons[customReasonIndex] = { ...newCustomReasons[customReasonIndex], isSelected };
        setCustomReasons(newCustomReasons);
    };

    const getSavedReasons = (): ReasonsDefaultAndCustom => {
        const newSavedReasons: ReasonsDefaultAndCustom = { custom: [], default: [] };

        defaultIssueResolveReasons.forEach((defaultReason) => {
            if (isSelectedByDefaultIssueReasonType[defaultReason.type]) {
                newSavedReasons.default.push({
                    type: defaultReason.type,
                    title: defaultReason.title,
                });
            }
        });

        customReasons.forEach((customReason) => {
            if (customReason.isSelected) {
                newSavedReasons.custom.push({
                    title: customReason.title,
                    isSelected: customReason.isSelected,
                });
            }
        });

        return newSavedReasons;
    };

    const getAllSelectedDefaultAndCustomReasons = (): IssueInfoPutReason[] => {
        const reasons: IssueInfoPutReason[] = [];

        defaultIssueResolveReasons.forEach((defaultReason) => {
            if (isSelectedByDefaultIssueReasonType[defaultReason.type]) {
                reasons.push({
                    type: defaultReason.type,
                    text: defaultReason.title,
                });
            }
        });

        customReasons.forEach((customReason) => {
            if (customReason.isSelected) {
                reasons.push({
                    type: IssueInfoReasonType.Other,
                    text: customReason.title,
                });
            }
        });

        return reasons;
    };

    const getAllSavedDefaultAndCustomReasons = (): IssueInfoPutReason[] => {
        const reasons: IssueInfoPutReason[] = [];

        savedReasons.default.forEach((defaultReason) => {
            reasons.push({
                type: defaultReason.type,
                text: defaultReason.title,
            });
        });

        savedReasons.custom.forEach((customReason) => {
            if (customReason.isSelected) {
                reasons.push({
                    type: IssueInfoReasonType.Other,
                    text: customReason.title,
                });
            }
        });

        return reasons;
    };

    const resolveDismissAction = async (reasons: IssueInfoPutReason[]) => {
        try {
            const status: IssueInfoStatus = isResolving
                ? getMappedValue<AlertAnomalyAction, IssueInfoStatus>(
                      {
                          [AlertAnomalyAction.Resolve]: IssueInfoStatus.Resolved,
                          [AlertAnomalyAction.Dismiss]: IssueInfoStatus.Dismissed,
                      },
                      selectedAlertAnomalyAction
                  )
                : issueStatus;

            const handler = getMappedValue<IssueInfoStatus, () => Promise<unknown>>(
                {
                    [IssueInfoStatus.Resolved]: async () =>
                        await putIssueInfoStatus(selectedIssue.issueId, IssueInfoStatus.Resolved, reasons, () =>
                            onRefresh?.()
                        ),
                    [IssueInfoStatus.Dismissed]: async () =>
                        await putIssueInfoStatus(selectedIssue.issueId, IssueInfoStatus.Dismissed, reasons, () =>
                            onRefresh?.()
                        ),
                    [IssueInfoStatus.Archived]: async () => {},
                    [IssueInfoStatus.Open]: async () => {},
                },
                status
            );

            await handler?.();
            showNotification("The reason was successfully changed", NotificationVariant.Success);
        } catch (e) {
            console.error("Error while putting new issue status");
        }
    };

    const cancelResolvingHandlerRef = useAutoUpdatedRef(async () => {
        return closeResolvingView();
    });

    const saveReasonsHandlerRef = useAutoUpdatedRef(async () => {
        const newSavedReasons = getSavedReasons();

        onChangeSavedLocalReasons?.(newSavedReasons);
        onChangeSavedLocalStatus?.(
            getMappedValue<AlertAnomalyAction, IssueInfoStatus>(
                {
                    [AlertAnomalyAction.Resolve]: IssueInfoStatus.Resolved,
                    [AlertAnomalyAction.Dismiss]: IssueInfoStatus.Dismissed,
                },
                selectedAlertAnomalyAction
            )
        );
        onChangeIsAlertUpdatedLocally?.(true);

        return closeResolvingView();
    });

    const saveAnomalyStatusHandlerRef = useAutoUpdatedRef(async () => {
        if (issueStatus === IssueInfoStatus.Open) {
            const reasons = getAllSelectedDefaultAndCustomReasons();

            if (!reasons.length) {
                return setIsResolveIssueDialogVisible(true);
            }

            await resolveDismissAction(reasons);
        }

        if (isAlertUpdatedLocally) {
            const reasons = getAllSavedDefaultAndCustomReasons();

            if (!reasons.length) {
                return setIsResolveWithNoReasonDialogVisible(true);
            }

            await resolveDismissAction(reasons);
        }

        return onCloseWithoutChange?.();
    });

    useEffect(() => {
        const cancelResolvingHandler = async () => {
            await cancelResolvingHandlerRef.current();
        };

        const saveReasonsHandler = async () => {
            await saveReasonsHandlerRef.current();
        };

        const saveAnomalyStatusHandler = async () => {
            await saveAnomalyStatusHandlerRef.current();
        };

        alertAnomalyStatusEmitter.addListener("cancelResolving", cancelResolvingHandler);
        alertAnomalyStatusEmitter.addListener("saveReasonsLocally", saveReasonsHandler);
        alertAnomalyStatusEmitter.addListener("saveAlertStatus", saveAnomalyStatusHandler);

        return () => {
            alertAnomalyStatusEmitter.removeListener("cancelResolving", cancelResolvingHandler);
            alertAnomalyStatusEmitter.removeListener("saveReasonsLocally", saveReasonsHandler);
            alertAnomalyStatusEmitter.removeListener("saveAlertStatus", saveAnomalyStatusHandler);
        };
    }, []);

    const areAlertReasonsVisible =
        // issueStatus === IssueInfoStatus.Resolved && // TODO: Should it be displayed when status is not resolved but there are reasons
        !isResolving &&
        selectedIssueState?.issueStatus !== IssueInfoStateStatus.Resolving &&
        !!(savedReasons.default.length || savedReasons.custom.length);

    const renderIssueInfoStatusTags = () => {
        const tags = [];

        if (issueStatus === IssueInfoStatus.Resolved) {
            tags.push(
                <Box key={issueStatus} display="flex" alignItems="center">
                    <div className={classes.greenTag}>Resolved</div>
                </Box>
            );
        }

        if (issueStatus === IssueInfoStatus.Open) {
            tags.push(
                <div key={issueStatus} className={classes.yellowTag}>
                    Unresolved
                </div>
            );
        }

        if (issueStatus === IssueInfoStatus.Dismissed) {
            tags.push(
                <Box key={issueStatus} display="flex" alignItems="center">
                    <div className={classes.grayTag}>Dismissed</div>
                </Box>
            );
        }

        if (!tags.length) {
            return null;
        }

        return <div className={classes.tagsContainer}>{tags}</div>;
    };

    const onboardingSteps: Step[] = [
        {
            target: `[data-onboarding="anomaly-resolving-resolve-option"]`,
            title: "Resolve",
            content: (
                <Typography variant="body2">Resolve the issue by sharing what problem you've identify.</Typography>
            ),
            showSkipButton: true,
            disableBeacon: true,
            placement: "left",
        },
        {
            target: `[data-onboarding="anomaly-resolving-dismiss-option"]`,
            title: "Dismiss",
            content: (
                <Typography variant="body2">
                    Dismiss the issue if something was missing or it was normal behavior.
                </Typography>
            ),
            disableBeacon: true,
            placement: "left",
        },
    ];

    if (!issueStatus) {
        return null;
    }

    return (
        <div className={classNames(className, classes.traceViewAlertAnomalyStatusContainer)}>
            <div className={classes.titleSection}>
                <div className={classes.titleContainer}>
                    <div className={classes.title}>
                        Alert status{" "}
                        <SternumTooltip
                            title={
                                <span className={classes.tooltipContent}>
                                    Please resolve the unresolved issues to inform future issue suggestions. If this was
                                    not an issue, please dismiss it rather than keeping it unresolved.
                                </span>
                            }
                        >
                            <InfoCircleIcon className={classes.statusIcon} />
                        </SternumTooltip>
                    </div>
                    <div className={classes.issueInfoStatusContainer}>{renderIssueInfoStatusTags()}</div>
                </div>
                {isResolving && (
                    <div className={classes.actionsContainer}>
                        <SwitchSelector
                            selectedCase={selectedAlertAnomalyAction}
                            onChangeSelectedCase={(newSelectedAlertAnomalyAction: AlertAnomalyAction) => {
                                setSelectedAlertAnomalyAction(newSelectedAlertAnomalyAction);
                            }}
                        >
                            <SwitchSelector.Item
                                data-onboarding="anomaly-resolving-resolve-option"
                                className={classes.switchAlertAnomalyItem}
                                id={AlertAnomalyAction.Resolve}
                            >
                                Resolve
                            </SwitchSelector.Item>
                            <SwitchSelector.Item
                                data-onboarding="anomaly-resolving-dismiss-option"
                                className={classes.switchAlertAnomalyItem}
                                id={AlertAnomalyAction.Dismiss}
                            >
                                Dismiss
                            </SwitchSelector.Item>
                        </SwitchSelector>
                    </div>
                )}
            </div>
            {!isResolving && (
                <div className={classes.resolvingSection}>
                    <div className={classes.resolvingTitleContainer}>
                        <div className={classes.resolvingTitle}>
                            Reasons
                            <PencilIcon
                                className={classesCommon.cursorPointer}
                                width={18}
                                height={18}
                                color="#8B949E"
                                onClick={() => setIsResolving(true)}
                            />
                        </div>
                    </div>

                    <div className={classes.resolvingSubtitle}>All the reasons displays here why the anomaly was</div>
                    {areAlertReasonsVisible && (
                        <div className={classes.resolvingReasonsWithActionButtons}>
                            <ResolvingReasons isDisplayView>
                                {savedReasons.default.map((reasonData, index) => (
                                    <ResolvingReasonsItem key={"default" + index} isSelected={true}>
                                        {reasonData.title}
                                    </ResolvingReasonsItem>
                                ))}
                                {savedReasons.custom.map((customReason, index) => (
                                    <ResolvingReasonsCustomItem key={"custom" + index} isSelected={true}>
                                        {customReason.title}
                                    </ResolvingReasonsCustomItem>
                                ))}
                            </ResolvingReasons>
                        </div>
                    )}
                </div>
            )}
            {isResolving && (
                <div className={classes.resolvingSection}>
                    <div className={classes.resolvingTitleContainer}>
                        <div className={classes.resolvingTitle}>Resolving the anomaly</div>
                    </div>

                    {canAddCustomReasons && defaultIssueResolveReasons.length && (
                        <div className={classes.resolvingSubtitle}>
                            Add reason that will be used when improving our artificial intelligence
                            <span className={classes.redDecoration}>*</span>
                        </div>
                    )}
                    <div className={classes.resolvingReasonsWithActionButtons}>
                        <ResolvingReasons>
                            {defaultIssueResolveReasons.map((reasonData, index) => {
                                const isSelected = isSelectedByDefaultIssueReasonType[reasonData.type];

                                return (
                                    <ResolvingReasonsItem
                                        key={"default" + reasonData.type + index}
                                        isSelected={isSelectedByDefaultIssueReasonType[reasonData.type]}
                                        onClick={() => setIsSelectedDefaultIssue(reasonData.type, !isSelected)}
                                    >
                                        {reasonData.title}
                                    </ResolvingReasonsItem>
                                );
                            })}
                            {customReasons.map((customReason, index) => (
                                <ResolvingReasonsCustomItem
                                    key={"custom" + index}
                                    isSelected={customReason.isSelected}
                                    onClickDelete={() => deleteCustomReason(index)}
                                    onClick={() => setCustomReasonSelection(index, !customReason.isSelected)}
                                >
                                    {customReason.title}
                                </ResolvingReasonsCustomItem>
                            ))}
                            {canAddCustomReasons && (
                                <ResolvingReasonsAddItem
                                    initialValue=""
                                    placeholder="Manual reason"
                                    buttonPlaceholder="Other"
                                    onAddNewReason={(newReason) => addCustomReason(newReason)}
                                />
                            )}
                        </ResolvingReasons>
                    </div>
                </div>
            )}

            <AnomalyOnboarding
                storageKey="onboarding_anomaly_resolving"
                steps={onboardingSteps}
                isDemoDataTagVisible={false}
                disableScrolling={true}
            />

            <ConfirmationDialog
                title="You didn't resolve the issue"
                body="Reason resolve helps improve your security. Please, select at least one reason to resolve the issue."
                open={isResolveIssueDialogVisible}
                handleApprove={() => setIsResolveIssueDialogVisible(false)}
                handleCancel={() => setIsResolveIssueDialogVisible(false)}
                hideCancelButton={true}
                extraButtons={
                    <SternumImprovedButton
                        fullWidth={false}
                        buttonType="text:dark"
                        content="Leave unresolved"
                        onClick={() => onCloseWithoutChange?.()}
                    />
                }
                overrideActionName="Back"
                dialogClasses={{
                    paper: classes.resolveIssueDialogRoot,
                }}
            />

            <ConfirmationDialog
                title="Issue has no reason"
                body="Reason resolve helps improve your security. Please, select at least one reason to update the issue status"
                open={isResolveWithNoReasonDialogVisible}
                handleApprove={() => setIsResolveWithNoReasonDialogVisible(false)}
                handleCancel={() => setIsResolveWithNoReasonDialogVisible(false)}
                hideCancelButton={true}
                extraButtons={
                    <SternumImprovedButton
                        fullWidth={false}
                        buttonType="text:dark"
                        content="Leave without change"
                        onClick={() => onCloseWithoutChange?.()}
                    />
                }
                overrideActionName="Back"
                dialogClasses={{
                    paper: classes.resolveIssueDialogRoot,
                }}
            />

            {/*<ConfirmationDialog*/}
            {/*    title="Save changes?"*/}
            {/*    body="Reason resolve helps improve your security. Please, select at least one reason to resolve the issue."*/}
            {/*    open={isSaveChangesDialogVisible}*/}
            {/*    handleApprove={() => {*/}
            {/*        resolveDismissHandlerRef.current();*/}
            {/*    }}*/}
            {/*    handleCancel={closeResolvingView}*/}
            {/*    hideCancelButton={true}*/}
            {/*    extraButtons={*/}
            {/*        <SternumImprovedButton*/}
            {/*            fullWidth={false}*/}
            {/*            buttonType="text:dark"*/}
            {/*            content="Remove changes"*/}
            {/*            onClick={closeResolvingView}*/}
            {/*        />*/}
            {/*    }*/}
            {/*    overrideActionName="Save"*/}
            {/*    dialogClasses={{*/}
            {/*        paper: classes.resolveIssueDialogRoot,*/}
            {/*    }}*/}
            {/*/>*/}
        </div>
    );
}

function getIssueInfoStatus(issue?: IssueInfo): IssueInfoStatus | undefined {
    return issue ? (issue.status as IssueInfoStatus) : undefined;
}

function getReasons(issue?: IssueInfo): ReasonsDefaultAndCustom {
    const defaultReasons: DefaultIssueReason[] = [];
    const customReasons: CustomReason[] = [];

    issue?.reasons?.forEach?.((reason) => {
        if (IssueInfoReasonType.Other === reason.type) {
            customReasons.push({ title: reason.text || "", isSelected: true });
        } else {
            defaultReasons.push({
                type: reason.type,
                title: reason.text || reasonsDataByIssueReasonType[reason.type]?.title || "",
            });
        }
    });

    return {
        default: defaultReasons,
        custom: customReasons,
    };
}
