import { Theme } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";
import { sternumTheme } from "../App";

let librariesUsageDataTileStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: {
            position: "relative",
            width: "100%",
        },
    });

export default librariesUsageDataTileStyle;
