import { makeStyles } from "@material-ui/core";

export const useLinuxTopologyGraphStyle = makeStyles((theme) => {
    const whiteHard = "#D5DAE0";

    return {
        container: {},
        item: {
            display: "flex",
            alignItems: "center",
            gap: 8,
            cursor: "pointer",
            textDecoration: "none",
        },
        itemContent: {
            fontSize: 16,
            fontWeight: 400,
            color: "#333333",
        },
        isSelected: {
            color: "#1B6FDE",
        },
        childrenContainer: {
            marginLeft: 18.5,
        },
        childItem: {
            position: "relative",
            display: "flex",
        },
        childDistanceTop: {
            height: 19,
            borderLeft: `1px solid ${whiteHard}`,
        },
        childDistanceBetween: {
            height: 14,
            borderLeft: `1px solid ${whiteHard}`,
        },
        horizontalLine: {
            width: 24,
            height: 1,
            background: whiteHard,
            transform: "translateY(-50%)",
            marginTop: 15,
        },
        leftTopLine: {
            position: "absolute",
            top: 0,
            left: 0,
            width: 1,
            height: 15,
            background: whiteHard,
        },
        leftBottomLine: {
            position: "absolute",
            top: 15,
            bottom: 0,
            left: 0,
            width: 1,
            background: whiteHard,
        },
    };
});
