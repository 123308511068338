import ListFilter from "./ListFilter";

/**
 * Represents a filter of library histories.
 */
class LibraryHistoriesFilter implements ListFilter {
    /**
     * Constructor.
     */
    constructor(public libraryId: string) {}

    /**
     * Returns whether filter is defined, or all values are nulls.
     */
    public isActive(): boolean {
        return !!this.libraryId;
    }

    /**
     * Returns whether current filter is different than given other filter.
     * @param other The other list filter to check with.
     */
    public isDifferentFrom(other: ListFilter): boolean {
        if (!(other instanceof LibraryHistoriesFilter)) {
            return true;
        }

        let otherLibraryHistoriesFilter = other as LibraryHistoriesFilter;

        return otherLibraryHistoriesFilter.libraryId !== this.libraryId;
    }
}

export default LibraryHistoriesFilter;
