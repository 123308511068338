import React from "react";

import Utils from "../../lib/infra/Utils";
import ServiceWire from "../../lib/services/ServiceWire";
import LoginStateEnum from "../../lib/state/LoginStateEnum";
import EnterCode from "../LoginFlow/EnterCode/EnterCode";

import { Login } from "./Login";
import { EnterAuthMfaCode } from "./EnterAuthMfaCode";
import { ForgotPassword } from "./ForgotPassword";
import { CreatePassword } from "./CreatePassword";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    stateToLoad: number;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps {
    adminLogin?: boolean;
    loginState: number;
}

/**
 * Login page of the app.
 */
class LoginManager extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            stateToLoad: null,
        };

        //
        if (this.props.loginState === LoginStateEnum.RESET_PASSWORD) {
            const search = window.location.search;
            const params = new URLSearchParams(search);
            const token = params.get("token");
            ServiceWire.getAuthenticationService().setValidationToken(token);
        }
    }

    /**
     * Renders the component.
     */
    render() {
        // Load next flow
        if (!Utils.isNullOrUndefined(this.state.stateToLoad) || !Utils.isNullOrUndefined(this.props.loginState)) {
            const nextState = !Utils.isNullOrUndefined(this.state.stateToLoad)
                ? this.state.stateToLoad
                : this.props.loginState;
            return this.loadComponent(nextState);
        }

        // Go to login page
        else {
            return (
                <>
                    <Login onForgotPasswordClick={this.handleForgotPasswordClick} />
                </>
            );
        }
    }

    /**
     * Load forgot password state
     */
    private loadComponent = (newState: number) => {
        switch (newState) {
            case LoginStateEnum.ENTER_EMAIL:
                return <ForgotPassword nextStep={this.handleNextStep} />;
            case LoginStateEnum.CODE_VALIDATION:
                return (
                    <EnterCode
                        nextStep={this.handleNextStep}
                        verificationCodeState={LoginStateEnum.RESET_PASSWORD.toString()}
                    />
                );
            case LoginStateEnum.RESET_PASSWORD:
                return <CreatePassword nextStep={this.handleNextStep} />;
            case LoginStateEnum.LOGIN:
                return <Login onForgotPasswordClick={this.handleForgotPasswordClick} />;
            case LoginStateEnum.MFA:
                return <EnterAuthMfaCode nextStep={this.handleNextStep} />;
        }
    };

    /**
     * Load forgot password state
     */
    private handleForgotPasswordClick = () => {
        this.setState({
            stateToLoad: LoginStateEnum.ENTER_EMAIL,
        });
    };

    /**
     * Load next state
     */
    private handleNextStep = (nextStep: number) => {
        this.setState({
            stateToLoad: nextStep,
        });
    };
}

export { LoginManager };
