import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../../components/CommonStyle";

const editUserPermissionsDialogStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),
        padding: {
            paddingRight: theme.spacing(5),
            paddingBottom: theme.spacing(2),
            paddingLeft: theme.spacing(5),
        },
        selectItem: {
            height: theme.spacing(1.5),
            boxSizing: "content-box",
        },
        labelColor: {
            color: "#999999",
        },
        selectComponent: {
            minWidth: theme.spacing(50),
            paddingTop: theme.spacing(2),
        },
        container: {
            width: "370px",
        },
    });

export default editUserPermissionsDialogStyle;
