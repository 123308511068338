/**
 * Holds all modal types in sternum.
 */
enum ModalType {
    DeviceViewModal,
    TraceViewModal,
    SternumGeneratedEventViewModal,
    LibraryViewModal,
    UsedLibraryViewModal,
    CveViewModal,
    LibraryHistoriesModal,
    ActivitiesListModal,
    CvesListModal,
    DevicesListModal,
    CreateTriggerModal,
    ChangePasswordModal,
    OutgoingWebhookConfigurationModal,
    NewAPIKeyModal,
    CreateDeviceDefinitionModal,
    EditDeviceDefinitionModal,
    DeviceInfoModal,
    DeviceDefinitionInfoModal,
    VisualizationCreation,
    ChangeLogModal,
    CreateDashboardModal,
    EditDashboardModal,
    AlertDescriptionModal,
    DeviceLocationModal,
    DeviceDefinitionLocationModal,
    GoProModal,
    GetSupportModal,
    SimulateAttackModal,
    InteractiveCoverageModal,
}

export default ModalType;
