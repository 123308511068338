import React from "react";
import classNames from "classnames";

import { useCommonStyle } from "../../../CommonStyle";
import { usePaginationStyle } from "./Pagination.style";

export interface PaginationProps {
    selectedItemIndex?: number;
    numberOfItems?: number;
    onChangeSelectedItem?: (index: number) => unknown;
}

export function Pagination({ numberOfItems = 0, selectedItemIndex = 0, onChangeSelectedItem }: PaginationProps) {
    const classes = usePaginationStyle();
    const classesCommon = useCommonStyle();

    if (!numberOfItems) {
        return null;
    }

    const points = new Array(numberOfItems)
        .fill(0)
        .map((_, index) => (
            <div
                key={index}
                className={classNames(classes.point, onChangeSelectedItem && classesCommon.cursorPointer)}
                onClick={() => index !== selectedItemIndex && onChangeSelectedItem?.(index)}
            />
        ));

    const transformation = `translateX(${selectedItemIndex * 100}%)`;

    return (
        <div className={classes.paginationContainer}>
            <div className={classes.selectedPointerContainer} style={{ transform: transformation }}>
                <div className={classNames(classes.point, classes.selected)} />
            </div>
            {points}
        </div>
    );
}
