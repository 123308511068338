import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../../CommonStyle";

const visualisationCardStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        visualisationBoxContainer: {
            height: "315px",
            boxSizing: "border-box",
            position: "relative",

            "&:hover": {
                zIndex: 100,
            },
        },

        visualisationBox: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",

            boxShadow: "0px 0px 23px rgba(231, 232, 235, 0.25)",
            borderRadius: 14,
            background: "#fff",
            padding: theme.spacing(2.5, 3.5, 0, 3.5),

            "&.no-padding": {
                padding: 0,
            },
        },

        visualisationContainer: {
            flex: 1,
        },

        visualisationTitleContainer: {
            display: "flex",
            alignItems: "center",

            "&.absolute": {
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                padding: theme.spacing(2.5, 3.5, 0, 3.5),
                zIndex: 1,
            },
        },

        visualisationTitle: {
            fontSize: "18px",
            fontWeight: 600,
        },

        visualisationDeviceDefinitionVersion: {
            marginLeft: theme.spacing(3),
            fontSize: 14,
            fontWeight: 600,
            color: "#B8BACF",
        },

        loadingContainer: {
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
    });

export default visualisationCardStyle;
