export enum ClientInfoTier {
    Regular = "regular",
    Trial = "trial",
    Poc = "poc",
}

export interface ClientInfoLimits {
    devicesLimit: number;
    usersLimit: number;
    dataLimitMb: number;
}

export interface ClientInfoUsage {
    users: number;
    devices: number;
    data: number;
}

export enum SignupUserGuideStep {
    PrepareOpenWRT = "prepareOpenWRT",
    InstallProtection = "installProtection",
    MonitorDevice = "monitorDevice",
}

export type ClientInfoOnboardingStateStep = Record<SignupUserGuideStep, boolean>;

export interface ClientInfoOnboardingState {
    steps: ClientInfoOnboardingStateStep;
}
