import { getDefaultGlobalState, GlobalState } from "../state/GlobalState";
import currentlyViewedCveIdReducer from "./currentlyViewedCveId/CurrentlyViewedCveIdReducer";
import currentlyViewedDeviceIdReducer from "./currentlyViewedDeviceId/CurrentlyViewedDeviceIdReducer";
import currentlyViewedGeneratedEventIdReducer from "./currentlyViewedGeneratedEventId/CurrentlyViewedGeneratedEventIdReducer";
import currentlyViewedLibraryIdReducer from "./currentlyViewedLibraryId/CurrentlyViewedLibraryIdReducer";
import currentlyViewedTraceIdReducer from "./currentlyViewedTraceId/CurrentlyViewedTraceIdReducer";
import currentlyViewedUsedLibraryIdReducer from "./currentlyViewedUsedLibraryId/CurrentlyViewedUsedLibraryIdReducer";
import cvesReducer from "./cves/CvesReducer";
import devicesReducer from "./devices/DevicesReducer";
import generatedEventsReducer from "./generatedEvents/GeneratedEventsReducer";
import issuesReducer from "./issues/IssuesReducer";
import librariesReducer from "./libraries/LibrariesReducer";
import modalsReducer from "./modals/ModalsReducer";
import notificationsReducer from "./notifications/NotificationsReducer";
import tracesReducer from "./traces/TracesReducer";
import triggerListReducer from "./Triggers/TriggerReducer";
import usedLibrariesReducer from "./usedLibraries/UsedLibrariesReducer";
import userReducer from "./users/SetUserReducer";
import { dashboardRegularReducer } from "./dashboardRegular/DashboarRegularReducer";
import currentlyViewedIssueIdReducer from "./currentlyViewedIssueId/CurrentlyViewedIssueIdReducer";

/**
 * Defines the root reducer for our app that glues everything together.
 */
const rootReducer = (state: GlobalState = getDefaultGlobalState(), action) => {
    return {
        user: userReducer(state.user, action),
        devices: devicesReducer(state.devices, action),
        traces: tracesReducer(state.traces, action),
        issues: issuesReducer(state.issues, action),
        libraries: librariesReducer(state.libraries, action),
        cves: cvesReducer(state.cves, action),
        usedLibraries: usedLibrariesReducer(state.usedLibraries, action),
        generatedEvents: generatedEventsReducer(state.generatedEvents, action),
        ui: {
            modals: modalsReducer(state.ui.modals, action),
            notifications: notificationsReducer(state.ui.notifications, action),
            currentlyViewedDeviceId: currentlyViewedDeviceIdReducer(state.ui.currentlyViewedDeviceId, action),
            currentlyViewedTraceId: currentlyViewedTraceIdReducer(state.ui.currentlyViewedTraceId, action),
            currentlyViewedIssueId: currentlyViewedIssueIdReducer(state.ui.currentlyViewedIssueId, action),
            currentlyViewedLibraryId: currentlyViewedLibraryIdReducer(state.ui.currentlyViewedLibraryId, action),
            currentlyViewedCveId: currentlyViewedCveIdReducer(state.ui.currentlyViewedCveId, action),
            currentlyViewedUsedLibraryId: currentlyViewedUsedLibraryIdReducer(
                state.ui.currentlyViewedUsedLibraryId,
                action
            ),
            currentlyViewedGeneratedEventId: currentlyViewedGeneratedEventIdReducer(
                state.ui.currentlyViewedGeneratedEventId,
                action
            ),
        },
        triggersHitList: triggerListReducer(state.triggersHitList, action),
        dashboardRegular: dashboardRegularReducer(state.dashboardRegular, action),
    };
};

export default rootReducer;
