import { CurrentlyViewedDeviceIdReducerActions } from "./CurrentlyViewedDeviceIdReducerActions";
import ActionType from "../ActionType";

/**
 * Reducer for the currently viewed device.
 */
const currentlyViewedDeviceIdReducer = (state: string, action: CurrentlyViewedDeviceIdReducerActions) => {
    switch (action.type) {
        case ActionType.ViewDevice:
            return action.deviceId;

        case ActionType.DeviceNotViewed:
            return null;

        default:
            return state;
    }
};

export default currentlyViewedDeviceIdReducer;
