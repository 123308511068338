import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../../CommonStyle";
import { sternumTheme } from "../../App";

const visualisationConfigurationFooterStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        footer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: theme.spacing(4, 3),
            flexShrink: 0,
        },

        errorSavingVisualisation: {
            color: sternumTheme.danger.main,
            marginRight: "8px",
        },

        savingVisualisationLoading: {
            marginRight: "8px",
        },

        cancelButtonContainer: {
            marginRight: "8px",
        },
    });

export default visualisationConfigurationFooterStyle;
