import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../../CommonStyle";

const sternumTableStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        endOfTablePagination: {
            display: "flex",
            justifyContent: "flex-end",
            margin: `${theme.spacing(1)}px 0`,
            marginRight: "12px",
        },
        scroll: {
            height: "100%",
            overflow: "auto",
        },
        tableLayout: {
            tableLayout: "auto",
        },
        tableWrapper: {
            maxHeight: "calc(100% - 40px)",
            overflow: "auto",
        },
        narrow: {
            tableLayout: "fixed",
        },
    });

export default sternumTableStyle;
