/**
 * Represents a user in sternum.
 */
import EntityBase from "./EntityBase";
import EntityType from "./EntityType";
import TableRowData from "./TableRowData";
import ClientInfo from "./ClientInfo";
import UserInfo from "./UserInfo";

class OutgoingWebhookInfo extends EntityBase implements TableRowData {
    /**
     * Constructor.
     */
    constructor(
        public outgoingWebhookId: string,
        public clientId: string,
        public created: number,
        public updated: number,
        public failCount: number,
        public lastFailMessage: string,
        public registeredEvents: string[],
        public displayName: string,
        public disabled: boolean,
        public uri: string,
        public client: ClientInfo,
        public creator: UserInfo,
        public updater: UserInfo
    ) {
        super(outgoingWebhookId, created, updated, EntityType.OutgoingWebhook);
    }

    /**
     * Converts json to info object.
     */
    public static fromJsonObject(jsonObject: Object) {
        return new OutgoingWebhookInfo(
            jsonObject["entity_id"],
            jsonObject["client_id"],
            jsonObject["created"],
            jsonObject["updated"],
            jsonObject["fail_count"],
            jsonObject["last_fail_message"],
            jsonObject["registered_events"],
            jsonObject["display_name"],
            jsonObject["disabled"],
            jsonObject["uri"],
            jsonObject["client"] ? ClientInfo.fromJsonObject(jsonObject["client"]) : null,
            jsonObject["creator"] ? UserInfo.fromJsonObject(jsonObject["creator"]) : null,
            jsonObject["updater"] ? UserInfo.fromJsonObject(jsonObject["updater"]) : null
        );
    }

    /**
     * Returns whether given row is any different in details than current issue info.
     * Shallow comparison, in other words.
     * @param other The row to compare with.
     */
    public isDifferentFrom(other: TableRowData): boolean {
        // Row must of of type issue info.
        if (!(other instanceof OutgoingWebhookInfo)) {
            return true;
        }

        let otherInfo = other as OutgoingWebhookInfo;

        // Comparing base issue properties.
        if (
            otherInfo.entityId !== this.entityId ||
            otherInfo.created !== this.created ||
            otherInfo.updated !== this.updated
        ) {
            return true;
        }

        return false;
    }

    /**
     * Gets the value for the given column id.
     */
    public getColumnValue(columnHeaderId: string): any {
        return this[columnHeaderId];
    }
}

export default OutgoingWebhookInfo;
