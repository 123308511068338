import EntityBase from "./EntityBase";
import EntityType from "./EntityType";
import TableRowData from "./TableRowData";
import DeviceDefinitionVersionInfo from "./DeviceDefinitionVersionInfo";

function toTitleCase(str) {
    return str.replace("_", " ").replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

/**
 * Represents a trace definition in sternum.
 */

class ProcessTracesInfo extends EntityBase implements TableRowData {
    /**
     * Constructor.
     */
    constructor(public processId: string, public processName: string, public totalTraces: string) {
        super(processId, null, null, EntityType.ProcessTrace);
    }

    /**
     * Converts json to issue info object.
     */
    public static fromJsonObject(jsonObject: Object) {
        return new ProcessTracesInfo(jsonObject["entity_id"], jsonObject["process"], jsonObject["traces_count"]);
    }

    /**
     * Converts Trace definition object into json object.
     */
    public toJsonObject() {
        return {
            entity_id: this.processId,
            process: this.processName,
        };
    }

    public getIdentifier(): string {
        return this.processId;
    }

    /**
     * Gets the value for the given column id.
     */
    public getColumnValue(columnHeaderId: string): any {
        return this[columnHeaderId];
    }

    /**
     * Returns whether given row is any different in details than current trace info.
     * Shallow comparison, in other words.
     * @param other The row to compare with.
     */
    public isDifferentFrom(other: TableRowData): boolean {
        // Row must of of type trace info.
        if (!(other instanceof ProcessTracesInfo)) {
            return true;
        }

        let otherTraceInfo = other as ProcessTracesInfo;

        // Comparing base trace properties.
        if (otherTraceInfo.processId !== this.processId || otherTraceInfo.processName !== this.processName) {
            return true;
        }

        return false;
    }
}

export default ProcessTracesInfo;
