import EntityBase from "./EntityBase";
import EntityType from "./EntityType";
import TableRowData from "./TableRowData";
import UsedLibraryInfo from "./UsedLibraryInfo";

const ANY_VERSION = "*";

class CveInfo extends EntityBase implements TableRowData {
    /**
     * Constructor.
     */
    constructor(
        public cveId: string,
        public created: number,
        public updated: number,
        public usedLibraryId: string,
        public description: string,
        public updateSource: string,
        public cveOfficialId: string,
        public externalEntity: Object,
        public usedLibrary: UsedLibraryInfo,
        public startVersion: string,
        public endVersion: string
    ) {
        super(cveId, created, updated, EntityType.Cve);
    }

    /**
     * Converts json to cve info object.
     */
    public static fromJsonObject(jsonObject: Object) {
        return new CveInfo(
            jsonObject["entity_id"],
            jsonObject["created"],
            jsonObject["updated"],
            jsonObject["used_library_id"],
            jsonObject["description"],
            jsonObject["update_source"],
            jsonObject["cve_official_id"],
            jsonObject["external_entity"],
            UsedLibraryInfo.fromJsonObject(jsonObject["used_library"]),
            jsonObject["start_effected_version"],
            jsonObject["end_effected_version"]
        );
    }

    /**
     * Gets the value for the given column id.
     */
    public getColumnValue(columnHeaderId: string): any {
        return this[columnHeaderId];
    }

    /**
     * Returns whether given row is any different in details than current cve info.
     * Shallow comparison, in other words.
     * @param other The row to compare with.
     */
    public isDifferentFrom(other: TableRowData): boolean {
        // Row must be of type cve info.
        if (!(other instanceof CveInfo)) {
            return true;
        }

        let otherCveInfo = other as CveInfo;

        // Comparing base cve info properties.
        if (
            otherCveInfo.cveId !== this.cveId ||
            otherCveInfo.created !== this.created ||
            otherCveInfo.updated !== this.updated ||
            otherCveInfo.usedLibraryId !== this.usedLibraryId ||
            otherCveInfo.description !== this.description ||
            otherCveInfo.updateSource !== this.updateSource ||
            otherCveInfo.cveOfficialId !== this.cveOfficialId
        ) {
            return true;
        }

        return false;
    }

    public isAnyVersion(): boolean {
        return this.startVersion === ANY_VERSION && this.endVersion === ANY_VERSION;
    }
}

export default CveInfo;
