import SelectComponent from "../DeviceDefinitionComponents/SelectComponent/SelectComponent";
import ConfigurationService from "../../lib/services/ConfigurationService";
import React, { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { BigMinusIcon, BigPlusIcon } from "../SUI/SternumIcon/SternumIcon";
import { ActionMeta, OptionTypeBase } from "react-select";
import { UniqueCountDataContext } from "./UniqueCountContext";
import Utils from "../../lib/infra/Utils";
import Select from "react-select";

const onGroupClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const group = e.currentTarget.parentNode.nextSibling as HTMLDivElement;
    if (group && "style" in group) {
        if (group.style.display !== "none") {
            group.style.display = "none";
            (e.currentTarget.querySelector(".argument-plus") as HTMLDivElement).style.display = "block";
            (e.currentTarget.querySelector(".argument-minus") as HTMLDivElement).style.display = "none";
        } else {
            group.style.display = "block";
            (e.currentTarget.querySelector(".argument-plus") as HTMLDivElement).style.display = "none";
            (e.currentTarget.querySelector(".argument-minus") as HTMLDivElement).style.display = "block";
        }
    }
};

const groupsOpen = (container: HTMLDivElement) => {
    const parent = container.parentNode;
    const plus = parent.querySelector(".argument-plus") as HTMLDivElement;
    if (plus && plus.style.display === "block") {
        plus.click();
    }
};

const groupsClose = (container: HTMLDivElement) => {
    const parent = container.parentNode;
    const minus = parent.querySelector(".argument-minus") as HTMLDivElement;
    if (minus && minus.style.display === "block") {
        minus.click();
    }
};

const groupsUpdateCounters = (container: HTMLDivElement) => {
    const parent = container.nextSibling as HTMLElement;

    if (!parent) {
        return;
    }

    const groupCounter = parent.querySelector(".ucd-group-counter");
    const groupHeader = parent.querySelector(".ucd-group-header");

    if (!groupCounter || !groupHeader) {
        return;
    }

    groupCounter.innerHTML = `(${groupHeader.parentNode.nextSibling.childNodes.length})`;
};

const decorateInputValue = (container: HTMLDivElement) => {
    const prefix = container.querySelector<HTMLDivElement>(".ucd-prefix");
    const counter = container.querySelector<HTMLDivElement>(".ucd-counter");
    const label = container.querySelector<HTMLDivElement>(".ucd-label");

    if (prefix) {
        prefix.style.display = "inline";
    }

    if (counter) {
        counter.style.display = "none";
    }

    if (label) {
        label.style.paddingLeft = "0px";
    }
};

const onGroupLoad = (item: HTMLDivElement) => {
    const group = item?.parentNode?.nextSibling as HTMLDivElement;

    if (group) {
        group.style.display = "none";
        (item.querySelector(".argument-plus") as HTMLDivElement).style.display = "block";
        (item.querySelector(".argument-minus") as HTMLDivElement).style.display = "none";
    }
};

export const formatGroupLabel = (data: any) => {
    // this is NOT a component, we need to have a hack to simulate state
    // normally react select does not allow to show/hide groups,
    // but for that we will use another hack combined with the
    // state hack mentioned above

    return (
        <div
            ref={onGroupLoad}
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                // backgroundColor: "#F7F9FA",
                boxSizing: "content-box",
                // padding: 14,
                // marginLeft: -12,
                // marginRight: -12,
            }}
            className="ucd-group-header"
            onClick={onGroupClick}
        >
            <div style={{ fontSize: 14, color: "#2B2523", textTransform: "none" }}>{data.label}</div>
            <div className="argument-plus" style={{ fontSize: 12 }}>
                <BigPlusIcon width={16} />
            </div>
            <div className="argument-minus" style={{ fontSize: 12, display: "none" }}>
                <BigMinusIcon width={16} />
            </div>
        </div>
    );
};

export const formatOptionLabel = (data: any) => {
    return (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <div
                className="ucd-label"
                style={{ paddingLeft: data.metricType === "trace" || data.metricType === "argument" ? 16 : 0 }}
            >
                <span className="ucd-prefix" style={{ display: "none", fontWeight: "bold" }}>
                    {data.metricType === "trace" && "Trace: "}
                    {data.metricType === "argument" && "Arg: "}
                </span>
                {data.label}
            </div>
            {"counter" in data && (
                <div className="ucd-counter" style={{ color: "#AAAAAA" }}>
                    &nbsp;({Utils.numberToHumanString(data.counter)})
                </div>
            )}
        </div>
    );
};

export const counterUpdate = (selectRef) => (value: string | undefined) => {
    if (!selectRef || !selectRef.current) return;
    const controlEl = selectRef.current.select.controlRef as unknown as HTMLDivElement;
    setTimeout(() => {
        if (controlEl) {
            decorateInputValue(controlEl);
            groupsUpdateCounters(controlEl);

            if (value === undefined) {
                return;
            }

            if (value !== "") {
                groupsOpen(controlEl);
            } else {
                groupsClose(controlEl);
            }
        }
    });
};

interface UniqueCountDropdownProps {
    selectedValue: any;
    onChange: (newValue: OptionTypeBase, action: ActionMeta<any>) => void;
    className?: string;
}

export function UniqueCountDropdown({ selectedValue, onChange, className }: UniqueCountDropdownProps) {
    const availableMetrics = useContext(UniqueCountDataContext);
    const availableArguments = useMemo(
        () => availableMetrics.filter((metric) => metric.metricType === "argument"),
        [availableMetrics]
    );

    const selectRef = useRef<Select>(null);

    const options = useMemo(() => {
        const specialFields = [
            ConfigurationService.getEventTypeArgumentField(),
            ConfigurationService.getInterestArgumentField(),
            ConfigurationService.getCategoryArgumentField(),
            ConfigurationService.getDeviceIdArgumentField(),
            // ConfigurationService.getAllEventsFilterField(),
        ];

        return [
            ...specialFields.map((field) => ({
                label: field.displayName,
                value: field.id,
                isSpecialField: true,
            })),
            {
                label: (
                    <span>
                        Arguments{" "}
                        <span style={{ color: "#AAAAAA" }} className="ucd-group-counter">
                            ({Utils.numberToHumanString(availableArguments.length)})
                        </span>
                    </span>
                ),
                options: availableArguments,
            },
        ];
    }, [availableArguments]);

    const onSelectChange = useCallback(
        (newValue: OptionTypeBase, action: ActionMeta<any>) => {
            onChange(newValue, action);
            setTimeout(() => {
                if (!selectRef.current) return;
                decorateInputValue(selectRef.current.select.controlRef as unknown as HTMLDivElement);
            });
        },
        [onChange]
    );

    useEffect(() => {
        // we don't have normally argument labels from the server
        // with saved glances, so refresh the data if there is
        // a label available
        availableArguments.forEach((metric) => {
            if (selectedValue && metric.value === selectedValue.value) {
                onSelectChange(metric, null);
            }
        });
    }, [availableArguments]);

    return (
        <SelectComponent
            ref={selectRef}
            onInputChange={(value) => {
                if (!selectRef.current) return;
                setTimeout(() => {
                    const controlEl = selectRef.current.select.controlRef as unknown as HTMLDivElement;

                    decorateInputValue(controlEl);
                    groupsUpdateCounters(controlEl);

                    if (value !== "") {
                        groupsOpen(controlEl);
                    } else {
                        groupsClose(controlEl);
                    }
                });
            }}
            type="outlined"
            name="Metric"
            onFieldChange={onSelectChange}
            placeHolder={"Metric"}
            clearable={false}
            searchable={true}
            // className={classNames(classes.selectComponent, classes.marginRight)}
            className={className}
            selectOptions={options}
            selectedValue={selectedValue}
            formatGroupLabel={formatGroupLabel}
            formatOptionLabel={formatOptionLabel}
        />
    );
}
