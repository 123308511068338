import { Theme } from "@material-ui/core/styles";
import { Link, withRouter } from "react-router-dom";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

const deviceDefinitionViewPageStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),
    });

export default deviceDefinitionViewPageStyle;
