import EntityBase from "./EntityBase";
import EntityType from "./EntityType";
import VisualisationSummary from "../services/visualisation/VisualisationSummary";
import DeviceDefinitionVersionInfo from "./DeviceDefinitionVersionInfo";

export enum DashboardType {
    LinuxPerformance = "LinuxPerformance",
    LinuxSecurity = "LinuxSecurity",
    NotSpecified = "",
}

class CustomDashboardInfo extends EntityBase {
    constructor(
        public customDashboardId: string,
        public displayName: string,
        public created: number,
        public updated: number,
        public deviceId: string,
        public deviceDefinitionVersions: DeviceDefinitionVersionInfo[],
        public lookedUpEntityId: string,
        public deviceDefinitionId: string,
        public visualisationSummaries: VisualisationSummary[],
        public type: DashboardType
    ) {
        super(customDashboardId, created, updated, EntityType.CustomDashboard);
    }

    public isPredefinedLinuxDashboard() {
        return this.type === DashboardType.LinuxPerformance || this.type === DashboardType.LinuxSecurity;
    }

    public static fromJsonObject(jsonObject: Object): CustomDashboardInfo {
        return new CustomDashboardInfo(
            jsonObject["entity_id"],
            jsonObject["display_name"],
            jsonObject["created"],
            jsonObject["updated"],
            jsonObject["device_id"],
            jsonObject["device_definition_versions"].map((deviceDefinitionVersion) => {
                return DeviceDefinitionVersionInfo.fromJsonObject(deviceDefinitionVersion);
            }),
            jsonObject["looked_up_entity_id"],
            jsonObject["device_definition_id"],
            (jsonObject["visualisations_summaries"] || []).map((visualisationSummaryJson) => {
                return {
                    visualisationId: visualisationSummaryJson["visualisation_id"],
                    displayName: visualisationSummaryJson["display_name"],
                    updated: visualisationSummaryJson["updated"],
                };
            }),
            jsonObject["dashboard_type"] || DashboardType.NotSpecified
        );
    }
}

export default CustomDashboardInfo;
