import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

const entitiesListFilterPopoverStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        titleTypography: {
            display: "flex",
            alignItems: "center",
            width: theme.spacing(18),
        },
        applyButton: {
            marginRight: theme.spacing(2),
        },
        resetAndApplyButtons: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(4),
        },
    });

export default entitiesListFilterPopoverStyle;
