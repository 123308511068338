import React, { useMemo } from "react";
import classNames from "classnames";

import { ButtonSize, ButtonVariant } from "./Button.types";
import { useButtonStyle } from "./Button.style";

export interface ButtonProps extends React.ComponentPropsWithRef<"button"> {
    className?: string;
    variant?: ButtonVariant;
    size?: ButtonSize;
    iconBefore?: React.ReactNode;
    iconAfter?: React.ReactNode;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(function Button(
    { className, variant = "primaryPink", size = "size32", iconBefore, iconAfter, children, ...props },
    ref
) {
    const classes = useButtonStyle();

    const variantClassName = useMemo(() => {
        const classNameByVariant: Record<ButtonVariant, string> = {
            primaryPink: classes.primaryPink,
            whiteLightTextWithBorder: classes.whiteLightTextWithBorder,
            pinkTextWithoutBorder: classes.pinkTextWithoutBorder,
            whiteTextWithoutBorder: classes.whiteTextWithoutBorder,
            text: classes.text,
        };

        return classNameByVariant[variant] || "";
    }, [variant]);

    const sizeClasName = useMemo(() => {
        const classNameBySize: Record<ButtonSize, string> = {
            size32: classes.size32,
            size24: classes.size24,
            size16: classes.size16,
            size14: classes.size14,
        };

        return classNameBySize[size] || "";
    }, [size]);

    return (
        <button ref={ref} className={classNames(className, classes.button, variantClassName, sizeClasName)} {...props}>
            {iconBefore && <div className={classes.iconContainer}>{iconBefore}</div>}
            {children}
            {iconAfter && <div className={classes.iconContainer}>{iconAfter}</div>}
        </button>
    );
});
