import { makeStyles } from "@material-ui/core";

export const useDropdownStyle = makeStyles((theme) => ({
    dropdownRoot: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        gap: 4,
        cursor: "pointer",

        "&[aria-expanded='true']": {
            "& $dropdownView": {
                display: "block",
            },

            "& $arrowIcon": {
                transform: "rotate(90deg)",
            },
        },
    },

    dropdownView: {
        display: "none",
        position: "absolute",
        top: "100%",
        left: "50%",
        transform: "translate(-50%, 10px)",
        background: "#FFF",
        borderRadius: 20,
        boxShadow: "0 0 5px 0 #999",
        padding: "18px 0 10px 0",
        zIndex: 1000,
    },

    arrowIcon: {
        transition: "transform 0.2s ease",
        transform: "rotate(-90deg)",
    },

    dropdownItem: {
        cursor: "pointer",
        padding: "10px 24px",
        whiteSpace: "nowrap",
    },
}));
