import React, { MutableRefObject, useState } from "react";
import classNames from "classnames";
import { Button, Popover } from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import CloseIcon from "@material-ui/icons/Close";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { useCommonStyle } from "../../../CommonStyle";
import { useSelectTimeRangePopoverStyle } from "./SelectTimeRangePopover.style";

export interface SelectTimeRangePopover {
    isOpen?: boolean;
    anchorElRef?: MutableRefObject<Element>;
    timeFrom?: Date;
    timeTo?: Date;

    onCloseTimeRangeSelection?: () => unknown;
    onSetTimeRange?: (args: { from: Date; to: Date }) => unknown;
}

export function SelectTimeRangePopover({
    isOpen,
    anchorElRef,
    timeFrom,
    timeTo,
    onCloseTimeRangeSelection,
    onSetTimeRange,
}: SelectTimeRangePopover) {
    const commonClasses = useCommonStyle();
    const classes = useSelectTimeRangePopoverStyle();

    const [customTimeStart, setCustomTimeStart] = useState<Date>();
    const [customTimeStop, setCustomTimeStop] = useState<Date>();

    const displayTimeStart = customTimeStart || timeFrom || new Date();
    const displayTimeStop = customTimeStop || timeTo || new Date();

    const handleCloseTimeRangeSelection = () => {
        setCustomTimeStop(undefined);
        setCustomTimeStart(undefined);

        onCloseTimeRangeSelection?.();
    };

    const handleSetCustomRange = () => {
        setCustomTimeStop(undefined);
        setCustomTimeStart(undefined);

        onSetTimeRange?.({ from: displayTimeStart, to: displayTimeStop });
    };

    return (
        <Popover
            id="explanation-popover-select-time-range"
            open={isOpen}
            anchorEl={anchorElRef.current}
            elevation={10}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
        >
            <div className={classNames(commonClasses.flexEnd, commonClasses.marginTop, commonClasses.marginRight)}>
                <CloseIcon className={classes.closeButtonIcon} onClick={handleCloseTimeRangeSelection} />
            </div>
            {/** Display time selection */}
            <div className={classNames(classes.customDateSelect, commonClasses.flexSpaceAround)}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DateTimePicker
                        variant="inline"
                        margin="normal"
                        id="from-date-picker-inline"
                        label="From"
                        value={displayTimeStart}
                        onChange={(value) => {
                            setCustomTimeStart(value.toDate());
                        }}
                    />
                    <DateTimePicker
                        variant="inline"
                        margin="normal"
                        id="date-picker-inline"
                        label="To"
                        value={displayTimeStop}
                        onChange={(value) => {
                            setCustomTimeStop(value.toDate());
                        }}
                    />
                </MuiPickersUtilsProvider>
            </div>
            <div className={classNames(commonClasses.flexEnd, classes.setButton)}>
                <Button
                    className={commonClasses.marginRight}
                    variant="contained"
                    color="default"
                    onClick={handleCloseTimeRangeSelection}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSetCustomRange}
                    disabled={displayTimeStop <= displayTimeStart}
                >
                    Set
                </Button>
            </div>
        </Popover>
    );
}
