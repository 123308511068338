import React from "react";
import classNames from "classnames";

import { ResearchNumberIcon } from "../SUI/SternumIcon";
import { useResearchNumberWithContentStyle } from "./ResearchNumberWithContent.style";

export interface ResearchNumberWithContentProps extends React.ComponentProps<"div"> {
    researchNumber?: number;
}

export function ResearchNumberWithContent({
    className,
    researchNumber,
    children,
    ...props
}: ResearchNumberWithContentProps) {
    const classes = useResearchNumberWithContentStyle();

    return (
        <div className={classNames(classes.researchNumberContainer, className)} {...props}>
            <div className={classes.iconContainer}>
                <ResearchNumberIcon className={classes.icon} />
                <span className={classes.researchNumber}>{researchNumber}</span>
            </div>
            {children}
        </div>
    );
}
