import ListFilter from "./ListFilter";

/**
 * Represents a filter of cves.
 */
class CvesFilter implements ListFilter {
    /**
     * Constructor.
     */
    constructor(
        public usedLibraryId: string,
        public createdFrom: number,
        public createdTo: number,
        public updatedFrom: number,
        public updatedTo: number,
        public deviceIds: string[],
        public deviceDefinitionVersionIds?: string[]
    ) {}

    /**
     * Returns whether filter is defined, or all values are nulls.
     */
    public isActive(): boolean {
        return (
            !!this.usedLibraryId ||
            !!this.createdFrom ||
            !!this.createdTo ||
            !!this.updatedFrom ||
            !!this.updatedTo ||
            !!(this.deviceIds && this.deviceIds.length) ||
            !!(this.deviceDefinitionVersionIds && this.deviceDefinitionVersionIds.length)
        );
    }

    /**
     * Returns whether current filter is different than given other filter.
     * @param other The other list filter to check with.
     */
    public isDifferentFrom(other: ListFilter): boolean {
        if (!(other instanceof CvesFilter)) {
            return true;
        }

        let otherCvesFilter = other as CvesFilter;

        return (
            otherCvesFilter.usedLibraryId !== this.usedLibraryId ||
            otherCvesFilter.createdFrom !== this.createdFrom ||
            otherCvesFilter.createdTo !== this.createdTo ||
            otherCvesFilter.updatedFrom !== this.updatedFrom ||
            otherCvesFilter.updatedTo !== this.updatedTo ||
            otherCvesFilter.deviceIds !== this.deviceIds ||
            otherCvesFilter.deviceDefinitionVersionIds !== this.deviceDefinitionVersionIds
        );
    }
}

export default CvesFilter;
