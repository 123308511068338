import { CurrentlyViewedUsedLibraryIdReducerActions } from "./CurrentlyViewedUsedLibraryIdReducerActions";
import ActionType from "../ActionType";

/**
 * Reducer for the currently viewed used library.
 */
const currentlyViewedUsedLibraryIdReducer = (state: string, action: CurrentlyViewedUsedLibraryIdReducerActions) => {
    switch (action.type) {
        case ActionType.ViewUsedLibrary:
            return action.usedLibraryId;

        case ActionType.UsedLibraryNotViewed:
            return null;

        default:
            return state;
    }
};

export default currentlyViewedUsedLibraryIdReducer;
