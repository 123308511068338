import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: "100%",
        padding: "12px",
        marginTop: "10px",
        background: "#fff",
        borderRadius: "10px",
    },

    attackCategoryTitle: {
        fontSize: "14px",
        fontWeight: 600,
    },

    attackCategoryVulnerabilitiesContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))",
        columnGap: "4px",
        marginTop: "12px",
    },

    vulnerabilityContainer: {
        padding: "4px 8px",
        border: "1px solid #E6EAED",
        borderRadius: "8px",
        transition: "all 0.2s ease",
        cursor: "pointer",

        "&.selected": {
            background: "#1B6FDE",
            color: "#fff",
            border: "1px solid #1B6FDE",
        },
    },

    vulnerabilityTitle: {
        display: "block",
        maxWidth: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },

    vulnerabilityDescription: {
        color: "#555B61",
    },

    cwesContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(140px, 1fr))",
        columnGap: "4px",
        marginTop: "12px",
    },

    cweItem: {
        padding: "4px 8px",
        background: "#F7F9FA",
        borderRadius: "8px",
        color: "#1B6FDE",
    },

    cweTitle: {
        display: "block",
        maxWidth: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontSize: "10px",
    },

    infoTooltip: {
        background: "#1B1B1B",
        fontSize: "12px",
    },
}));
