import AggregatedEventInfo from "./AggregatedEventInfo";
import EntityManager from "../infra/EntityManager";

class EventCountsAggregationInfo {
    /**
     * Constructor.
     */
    constructor(public eventIdentifierToAggregatedEventInfoMap: Record<string, AggregatedEventInfo>) {}

    /**
     * Converts API response to the event counts aggregation information.
     */
    public static fromApiResponse(apiResponseJson: Object) {
        let eventIdentifierToCountMap: Record<string, AggregatedEventInfo> = {};

        for (let property in apiResponseJson["aggregated_events"]) {
            if (apiResponseJson["aggregated_events"].hasOwnProperty(property)) {
                // Constructing the aggregated event info with the event and the count.
                const aggregatedEventInfoJson = apiResponseJson["aggregated_events"][property];

                // At last, we add current object into the map.
                eventIdentifierToCountMap[property] = new AggregatedEventInfo(
                    aggregatedEventInfoJson["count"],
                    aggregatedEventInfoJson["event"],
                    aggregatedEventInfoJson["display_name"],
                    aggregatedEventInfoJson["color"],
                    aggregatedEventInfoJson["trace_definition_name_to_count_map"],
                    aggregatedEventInfoJson["sternum_trigger_name_to_count_map"],
                    aggregatedEventInfoJson["interest_levels"]
                );
            }
        }

        return new EventCountsAggregationInfo(eventIdentifierToCountMap);
    }
}

export default EventCountsAggregationInfo;
