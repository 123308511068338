import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: "flex",
        height: "100%",
    },

    title: {
        fontSize: 16,
        fontWeight: 600,
    },

    description: {
        marginTop: 8,
        fontSize: 14,
        color: "#1D1D1F",
    },

    extraDescription: {
        marginTop: 16,
        fontSize: 14,
        fontWeight: 500,
        color: "#1D1D1F",
    },

    inner: {
        display: "grid",
        width: "100%",
        gridTemplateColumns: "24px 1fr",
        columnGap: "16px",
    },

    uninstallInstructions: {
        paddingTop: "24px",
        marginTop: "24px",
        borderTop: "1px solid #E6EAED",
    },
}));
