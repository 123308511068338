import ActivityInfo from "../state/ActivityInfo";
import APIKeyInfo from "../state/APIKeyInfo";
import ArgumentDefinitionInfo from "../state/ArgumentDefinitionInfo";
import ArgumentInfo from "../state/ArgumentInfo";
import ClientInfo from "../state/ClientInfo";
import CustomDashboardInfo from "../state/CustomDashboardInfo";
import CveInfo from "../state/CveInfo";
import DeviceDefinitionInfo from "../state/DeviceDefinitionInfo";
import DeviceDefinitionLibraryInfo from "../state/DeviceDefinitionLibraryInfo";
import DeviceDefinitionVersionInfo from "../state/DeviceDefinitionVersionInfo";
import DeviceInfo from "../state/DeviceInfo";
import DeviceProcessInfo from "../state/DeviceProcessInfo";
import DeviceProcessInstanceInfo from "../state/DeviceProcessInstanceInfo";
import EntityBase from "../state/EntityBase";
import IssueInfo from "../state/IssueInfo";
import LibraryInfo from "../state/LibraryInfo";
import OutgoingWebhookInfo from "../state/OutgoingWebhookInfo";
import PermissionInfo from "../state/PermissionInfo";
import ProcessTracesInfo from "../state/ProcessTracesInfo";
import RoleInfo from "../state/RoleInfo";
import SternumDeviceEventInfo from "../state/SternumDeviceEventInfo";
import SternumGeneratedEventInfo from "../state/SternumGeneratedEventInfo";
import SternumTriggerInfo from "../state/SternumTriggerInfo";
import TraceDefinitionInfo from "../state/TraceDefinitionInfo";
import TraceInfo from "../state/TraceInfo";
import UsedLibraryHistoryInfo from "../state/UsedLibraryHistoryInfo";
import UsedLibraryInfo from "../state/UsedLibraryInfo";
import UserInfo from "../state/UserInfo";
import VisualisationInfo from "../state/Visualisation/VisualisationInfo";
import Utils from "./Utils";

/**
 * Utility for handling sternum entities.
 */
class EntityManager {
    /**
     * Converts given entity to its class type.
     */
    public static convertSternumEntity(entityJson: Object): EntityBase {
        switch (entityJson["entity_type"]) {
            case "LIBRARY":
                return LibraryInfo.fromJsonObject(entityJson);
            case "USED_LIBRARY":
                return UsedLibraryInfo.fromJsonObject(entityJson);
            case "DEVICE":
                return DeviceInfo.fromJsonObject(entityJson);
            case "TRACE":
                return TraceInfo.fromJsonObject(entityJson);
            case "TRACE_ARGUMENT":
                return ArgumentInfo.fromJsonObject(entityJson);
            case "ISSUE":
                return IssueInfo.fromJsonObject(entityJson);
            case "USED_LIBRARY_HISTORY":
                return UsedLibraryHistoryInfo.fromJsonObject(entityJson);
            case "CVE":
                return CveInfo.fromJsonObject(entityJson);
            case "ACTIVITY":
                return ActivityInfo.fromJsonObject(entityJson);
            case "USER":
                return UserInfo.fromJsonObject(entityJson);
            case "INVITED_USER":
                return UserInfo.fromJsonObject(entityJson);
            case "CLIENT":
                return ClientInfo.fromJsonObject(entityJson);
            case "OUTGOING_WEBHOOK":
                return OutgoingWebhookInfo.fromJsonObject(entityJson);
            case "API_KEY":
                return APIKeyInfo.fromJsonObject(entityJson);
            case "DEVICE_PROCESS":
                return DeviceProcessInfo.fromJsonObject(entityJson);
            case "DEVICE_PROCESS_INSTANCE":
                return DeviceProcessInstanceInfo.fromJsonObject(entityJson);
            case "DEVICE_DEFINITION":
                return DeviceDefinitionInfo.fromJsonObject(entityJson);
            case "TRACE_DEFINITION":
                return TraceDefinitionInfo.fromJsonObject(entityJson);
            case "ARGUMENT_DEFINITION":
                return ArgumentDefinitionInfo.fromJsonObject(entityJson);
            case "DEVICE_DEFINITION_LIBRARY":
                return DeviceDefinitionLibraryInfo.fromJsonObject(entityJson);
            case "STERNUM_TRIGGER":
                return SternumTriggerInfo.fromJsonObject(entityJson);
            case "STERNUM_GENERATED_EVENT":
                return SternumGeneratedEventInfo.fromJsonObject(entityJson);
            case "STERNUM_DEVICE_EVENT":
                return SternumDeviceEventInfo.fromJsonObject(entityJson);
            case "USER_ROLE":
                return RoleInfo.fromJsonObject(entityJson);
            case "PERMISSION":
                return PermissionInfo.fromJsonObject(entityJson);
            case "DEVICE_DEFINITION_VERSION":
                return DeviceDefinitionVersionInfo.fromJsonObject(entityJson);
            case "VISUALIZATION":
                return VisualisationInfo.fromJsonObject(entityJson);
            case "CUSTOM_DASHBOARD":
                return CustomDashboardInfo.fromJsonObject(entityJson);
            case "EXECUTING_PROCESS_NAME":
                return ProcessTracesInfo.fromJsonObject(entityJson);
            default:
                return null;
        }
    }

    /**
     * Gets a single sternum entity out of the response.
     */
    public static getSternumEntity(responseJson: Object): EntityBase {
        let entities = EntityManager.getSternumEntities(responseJson);

        if (entities && entities.length) {
            return entities[0];
        } else {
            return null;
        }
    }

    /**
     * Fills all the entities in the given response.
     */
    public static getSternumEntities(responseJson: Object): EntityBase[] {
        // Extracting the entities array from response.
        let entities: [Object] = responseJson["entities"] ? responseJson["entities"] : [responseJson];

        // Extracting the related entities map from response.
        let relatedEntitiesMap: { [key: string]: Object } = responseJson["related_entities_map"]
            ? responseJson["related_entities_map"]
            : {};

        return this.fillEntities(entities, relatedEntitiesMap);
    }

    /**
     * Fills the given entities using the given related entities map.
     */
    public static fillEntities(entities: [Object], relatedEntitiesMap: { [key: string]: Object }) {
        // If we have nothing in hand, we return nothing.
        if (!entities.length || entities.every((element) => !element)) {
            return [];
        }

        // Filling entities.
        let filledEntities = [];
        for (let i = 0; i < entities.length; i++) {
            let entity = entities[i];

            // Adding entities to the related entities map as well.
            if (!relatedEntitiesMap[entity["entity_id"]]) {
                relatedEntitiesMap[entity["entity_id"]] = entity;
            }

            // Filling entity and adding to filled entities.
            EntityManager.fillEntity(entity, relatedEntitiesMap);
            filledEntities.push(entity);
        }

        // Converting entities to objects.
        let convertedEntities = [];
        for (let i = 0; i < filledEntities.length; i++) {
            let filledEntity = filledEntities[i];

            if (filledEntity["entity_id"]) {
                const convertedEntity = EntityManager.convertSternumEntity(filledEntity);
                if (convertedEntities) {
                    convertedEntities.push(convertedEntity);
                }
            }
        }

        return convertedEntities;
    }

    /**
     * Fills the given entity with entities from the related entities map recursively.
     */
    private static fillEntity(entityJson: Object, relatedEntitiesMap: { [key: string]: Object }) {
        let keys = Utils.getObjectKeys(entityJson);

        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            let value = entityJson[key];

            if (
                key === "is_reference" &&
                value &&
                entityJson["entity_id"] &&
                relatedEntitiesMap[entityJson["entity_id"]]
            ) {
                Object.assign(entityJson, relatedEntitiesMap[entityJson["entity_id"]]);
                delete entityJson["is_reference"];
                this.fillEntity(entityJson, relatedEntitiesMap);
            } else if (typeof value === "object" && value !== null) {
                this.fillEntity(value, relatedEntitiesMap);
            }
        }
    }

    /**
     * Gets a filled related entity out of given parent entity, related entity name, and related entities map.
     * @param parentEntityJson The entity holding the related entity. (For example, a Sternum Trigger holding a Device Definition reference).
     * @param relatedEntityName The name of the related entity.
     * @param relatedEntitiesMap A map of related entities (map between entity id to entity).
     */
    public static getFilledRelatedEntity<T extends EntityBase>(
        parentEntityJson: Object,
        relatedEntityName: string,
        relatedEntitiesMap: { [key: string]: Object }
    ): T {
        if (
            parentEntityJson[relatedEntityName] &&
            parentEntityJson[relatedEntityName]["entity_id"] &&
            parentEntityJson[relatedEntityName]["is_reference"]
        ) {
            let filledEntity = EntityManager.fillEntities([parentEntityJson[relatedEntityName]], relatedEntitiesMap);

            if (filledEntity && filledEntity.length) {
                return filledEntity[0];
            }
        }
    }
}

export default EntityManager;
