import { Chip } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SternumConfiguration from "../../lib/infra/SternumConfiguration";
import { GlobalState } from "../../lib/state/GlobalState";
import TraceInfo from "../../lib/state/TraceInfo";
import argumentsListDisplayStyle from "./ArgumentsListDisplayStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof argumentsListDisplayStyle> {
    traceInfo: TraceInfo;
    exclude?: string[];
    maximumAmountOfLetters?: number;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {};
};

/**
 * Displays arguments when a row is in a list.
 */
class ArgumentsListDisplay extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {};
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        // Constructing the display of arguments.
        let argumentsDisplayElements = [];

        for (let i = 0; i < this.props.traceInfo.orderedTraceArguments.length; i++) {
            let argumentInfo = this.props.traceInfo.orderedTraceArguments[i];

            if (this.props.exclude?.includes(argumentInfo.argumentDefinition.argumentEventName)) {
                continue;
            }

            let argumentRoleTypeConfiguration = SternumConfiguration.getArgumentRoleTypeConfigurationObject(
                argumentInfo.argumentDefinition.argumentEventName,
                this.props.traceInfo.traceDefinition.traceEventName
            );

            if (!argumentRoleTypeConfiguration || argumentRoleTypeConfiguration.displayInArgumentsSummary) {
                let argumentValue = argumentInfo.displayValue;
                if (argumentRoleTypeConfiguration && argumentRoleTypeConfiguration.extractValue) {
                    argumentValue = argumentRoleTypeConfiguration.extractValue(argumentInfo.argumentValue);
                }

                const argumentDisplayName = argumentRoleTypeConfiguration
                    ? argumentRoleTypeConfiguration.getDisplayName(this.props.traceInfo)
                    : argumentInfo.argumentDefinition.displayName;

                argumentsDisplayElements.push(
                    <div
                        role="presentation"
                        aria-label="argument display element chip"
                        key={i}
                        className={classNames(
                            classes.flexVMiddle,
                            i < this.props.traceInfo.orderedTraceArguments.length - 1 && classes.marginRight
                        )}
                    >
                        <Chip
                            className={classes.argument}
                            label={
                                <span>
                                    <span className={classNames(classes.argumentDisplayName)}>
                                        {argumentDisplayName}
                                    </span>
                                    :{" "}
                                    {this.props.maximumAmountOfLetters &&
                                    argumentValue.length >= this.props.maximumAmountOfLetters
                                        ? argumentValue.substr(0, this.props.maximumAmountOfLetters - 3) + "..."
                                        : argumentValue}
                                </span>
                            }
                        />
                    </div>
                );
            }
        }

        return <div className={classNames(classes.flexVMiddle)}>{argumentsDisplayElements}</div>;
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(argumentsListDisplayStyle)(ArgumentsListDisplay)));
