import React from "react";

import { ArrowLeftIcon } from "../../../../SUI/SternumIcon";

import { useRemoteViewAccordionStyles } from "./RemoteViewAccordion.styles";

export interface ArrowIcon extends React.ComponentPropsWithRef<"div"> {}

export const ArrowIcon = React.forwardRef<HTMLDivElement, ArrowIcon>(function ArrowIcon(props, ref) {
    const classes = useRemoteViewAccordionStyles();

    return (
        <div ref={ref} className={classes.arrowIconContainer} {...props}>
            <ArrowLeftIcon width={20} height={20} />
        </div>
    );
});
