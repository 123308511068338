import React from "react";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";

import { useLoadingDevicesStyle } from "./LoadingDevicesStyle";
import { LoadingBar } from "./LoadingBar";
import { CogSpinner } from "./CogSpinner";
import { Check } from "./Check";
import { Button } from "../SUI/Button";

interface LoadingDevicesProps {
    confirmMode: boolean;
    confirmButtonText?: string;
    onConfirm?: () => unknown;
}

export function LoadingDevices(props: LoadingDevicesProps) {
    const classes = useLoadingDevicesStyle();

    if (!props.confirmMode) {
        return (
            <div className={classes.loadingContainer}>
                <div className={classes.loadingPaper}>
                    <p className={classes.loadingTitle}>Waiting for device connection...</p>
                    <LoadingBar />
                    <CogSpinner />
                    <div className={classes.loadingExplanation}>
                        <p>
                            To protect and monitor additional devices please view the{" "}
                            <Link to="/settings">Settings page</Link>
                        </p>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className={classes.loadingContainer}>
                <div className={classes.loadingPaper}>
                    <Check />
                    <div className={classes.loadedExplanation}>
                        <Typography className={classes.loadedExplanationTitle}>You are now protected!</Typography>
                        <p>
                            To protect and monitor additional devices please view the{" "}
                            <Link to="/settings">Settings page</Link>
                        </p>
                        <Button className={classes.loadedExplanationButton} onClick={props.onConfirm}>
                            {props.confirmButtonText}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
