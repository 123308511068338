/**
 * Represents an activity in sternum.
 */
class TableToolbarDisplayState {
    /**
     * Constructor.
     */
    constructor(
        public displaySearch: boolean,
        public displayFilters: boolean,
        public displayRefresh: boolean,
        public displayColumnOptions: boolean,
        public doNotDisplayClearFilter?: boolean,
        public displayAddVisualization?: boolean,
        public hidePagination?: boolean,
        // Handle resolve all in issues list
        public displayResolveAllIssues?: boolean,

        public searchDisplayString?: string,

        public exportToCSV?: boolean,
        public exportToXLSX?: boolean,
        public showPremium?: boolean
    ) {}
}

export default TableToolbarDisplayState;
