import { Tooltip, Typography } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import useSternumEllipsisToolTipStyles from "./SternumEllipsisToolTipStyles";

interface AppProps {
    toolTipContent: string;
    ellipsisContent: React.ReactNode;
    [key: string]: any;
    isToolTip?: boolean;
}

const SternumEllipsisToolTip = ({ toolTipContent, ellipsisContent, isToolTip = true, ...restProps }: AppProps) => {
    const classes = useSternumEllipsisToolTipStyles();

    if (isToolTip) {
        return (
            <Tooltip title={toolTipContent} classes={{ tooltip: classes.toolTip }}>
                <Typography variant="body2" className={classNames(classes.marginLeftXs)} {...restProps} noWrap={true}>
                    {ellipsisContent}
                </Typography>
            </Tooltip>
        );
    }
    return (
        <Typography variant="body2" className={classNames(classes.marginLeftXs)} {...restProps} noWrap={true}>
            {ellipsisContent}
        </Typography>
    );
};

export default SternumEllipsisToolTip;
