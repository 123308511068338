import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";

import { RoundedLeftArrowIcon, RoundedRightArrowIcon } from "../../SUI/SternumIcon";

import { useSliderStyle } from "./Slider.style";
import { SliderItem } from "./SliderItem/SliderItem";
import { Pagination } from "./Pagination";
import { SliderVariant } from "./Slider.types";

export interface SliderProps extends React.ComponentProps<"div"> {
    selectedItemIndex?: number;
    items?: React.ReactElement[];
    displayPagination?: boolean;
    variant?: SliderVariant;
    onChangeSelected?: (newSelectedIndex: number) => unknown;
    onSelectSliderPoint?: (newSelectedIndex: number) => unknown;
    calculateVariantFromRef?: (ref: React.MutableRefObject<HTMLDivElement>) => SliderVariant | undefined | void;
    arrowRight?: React.ReactElement;
    arrowLeft?: React.ReactElement;
}

export function Slider({
    selectedItemIndex = 0,
    items = [],
    displayPagination = true,
    variant,
    onChangeSelected,
    onSelectSliderPoint,
    calculateVariantFromRef,
    arrowLeft = <RoundedLeftArrowIcon />,
    arrowRight = <RoundedRightArrowIcon />,
    ...props
}: SliderProps) {
    selectedItemIndex = Math.max(0, Math.min(items.length - 1, selectedItemIndex));
    const defaultVariant: SliderVariant = "default";

    const sliderRef = useRef<HTMLDivElement>();
    const prevSelectedItemIndexRef = useRef<number>(selectedItemIndex);
    const isAnimationDisabledRef = useRef<boolean>(false);
    const classes = useSliderStyle();

    const [innerVariant, setInnerVariant] = useState<SliderVariant | undefined>(variant);

    const forceUpdate = useForceUpdate();

    const finalVariant = variant || innerVariant || defaultVariant;

    const isMin = selectedItemIndex === 0;
    const isMax = selectedItemIndex === items.length - 1;

    useEffect(() => {
        if (!sliderRef.current || !calculateVariantFromRef) {
            return;
        }

        setInnerVariant(calculateVariantFromRef(sliderRef) || undefined);
    });

    useEffect(() => {
        const resizeCallback = () => {
            if (!sliderRef.current || !calculateVariantFromRef) {
                return;
            }

            setInnerVariant(calculateVariantFromRef(sliderRef) || undefined);
        };

        window.addEventListener("resize", resizeCallback);

        return () => {
            window.removeEventListener("resize", resizeCallback);
        };
    }, []);

    useEffect(() => {
        prevSelectedItemIndexRef.current = selectedItemIndex;

        if (isAnimationDisabledRef.current) {
            isAnimationDisabledRef.current = false;
            forceUpdate();
        }
    });

    const leftArrow = React.Children.map(arrowLeft, (child) =>
        React.cloneElement(child, {
            className: classNames(child.props.className, classes.arrow),
            onClick: (e) => {
                onChangeSelected?.(!isMin ? selectedItemIndex - 1 : items.length - 1);

                return child.props.onClick?.(e);
            },
        })
    );
    const rightArrow = React.Children.map(arrowRight, (child) =>
        React.cloneElement(child, {
            className: classNames(child.props.className, classes.arrow),
            onClick: (e) => {
                onChangeSelected?.(!isMax ? selectedItemIndex + 1 : 0);

                return child.props.onClick?.(e);
            },
        })
    );

    const variantClassName = useMemo(() => {
        const classNameByVariant: Record<SliderVariant, string> = {
            default: classes.variantDefault,
            maxContent: classes.variantMaxContent,
        };

        return classNameByVariant[finalVariant] || "";
    }, [finalVariant]);

    const transformation = (() => {
        if (prevSelectedItemIndexRef.current === 0 && selectedItemIndex === items.length - 1) {
            isAnimationDisabledRef.current = true;
            return `translateX(-${(items.length + 1) * 100}%)`;
        }

        if (prevSelectedItemIndexRef.current === items.length - 1 && selectedItemIndex === 0) {
            isAnimationDisabledRef.current = true;
            return "translateX(0%)";
        }

        return `translateX(-${(selectedItemIndex + 1) * 100}%)`;
    })();

    return (
        <div ref={sliderRef} className={classNames(classes.slider, variantClassName)} {...props}>
            <div className={classes.leftArrowContainer}>{leftArrow}</div>
            <div className={classes.rightArrowContainer}>{rightArrow}</div>
            <div className={classes.slidersContainer}>
                <div
                    className={classes.sliderHandler}
                    style={{
                        transform: transformation,
                        transition: isAnimationDisabledRef.current ? "none" : undefined,
                    }}
                >
                    {items[items.length - 1]}
                    {items}
                    {items[0]}
                </div>
            </div>
            {displayPagination && (
                <div className={classes.paginationContainer}>
                    <Pagination
                        selectedItemIndex={selectedItemIndex}
                        numberOfItems={items?.length}
                        onChangeSelectedItem={onSelectSliderPoint}
                    />
                </div>
            )}
        </div>
    );
}

const useForceUpdate = () => {
    const [_, setV] = useState<Symbol>(Symbol("force update"));

    return useCallback(() => {
        setV(Symbol("force update"));
    }, []);
};

Slider.Item = SliderItem;
Slider.ArrowRight = RoundedRightArrowIcon;
Slider.ArrowLeft = RoundedLeftArrowIcon;
