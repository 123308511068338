import React from "react";
import classNames from "classnames";

import { useGraphHeaderItemStyle } from "./GraphHeaderItem.style";
import { GraphItem } from "../GraphItem";

export interface GraphHeaderItemProps {
    color?: string;
    selected?: boolean;
    titleText?: string;
    maxTextWidth?: number;
}

export function GraphHeaderItem({ color, selected, titleText, maxTextWidth }: GraphHeaderItemProps) {
    const classes = useGraphHeaderItemStyle();

    return (
        <div className={classNames(classes.headerContainer)}>
            <GraphItem selected={selected} color={color} />
            {titleText && (
                <div className={classNames(classes.text)} style={{ maxWidth: maxTextWidth }}>
                    {titleText}
                </div>
            )}
        </div>
    );
}
