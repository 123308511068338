import HashMap from "../../infra/HashMap";
import HashSet from "../../infra/HashSet";
import { EntitiesState, getDefaultEntitiesState } from "../../state/EntitiesState";
import SternumGeneratedEventInfo from "../../state/SternumGeneratedEventInfo";
import ActionType from "../ActionType";
import { GeneratedEventsReducerActions } from "./GeneratedEventsReducerActions";

/**
 * Reducer for all trace-related actions.
 */
const generatedEventsReducer = (
    state: EntitiesState<SternumGeneratedEventInfo> = getDefaultEntitiesState<SternumGeneratedEventInfo>(),
    action: GeneratedEventsReducerActions
) => {
    switch (action.type) {
        case ActionType.FetchGeneratedEvent: {
            return {
                idToEntityMap: state.idToEntityMap,
                loadingIds: HashSet.copyAndAdd(state.loadingIds, action.generatedEventId),
                errorIds: state.errorIds,
                detectedDeviceCount: null,
            };
        }

        case ActionType.FetchedGeneratedEventSuccessfully: {
            return {
                idToEntityMap: HashMap.copyAndPut(
                    state.idToEntityMap,
                    action.generatedEvent.entityId,
                    action.generatedEvent
                ),
                loadingIds: state.loadingIds.exists(action.generatedEvent.entityId)
                    ? HashSet.copyAndRemove(state.loadingIds, action.generatedEvent.entityId)
                    : state.loadingIds,
                errorIds: state.errorIds.containsKey(action.generatedEvent.entityId)
                    ? HashMap.copyAndRemove(state.errorIds, action.generatedEvent.entityId)
                    : state.errorIds,
                detectedDeviceCount: null,
            };
        }

        case ActionType.ErrorFetchingGeneratedEvent: {
            return {
                idToEntityMap: state.idToEntityMap,
                loadingIds: HashSet.copyAndRemove(state.loadingIds, action.generatedEventId),
                errorIds: HashMap.copyAndPut(state.errorIds, action.generatedEventId, action.error),
                detectedDeviceCount: null,
            };
        }

        default:
            return state;
    }
};

export default generatedEventsReducer;
