import AuthenticationType from "./AuthenticationType";

/**
 * Represents response from the authentication command.
 */
class AuthenticationCommandResponse {
    /**
     * The id of the user.
     */
    entity_id?: string;

    /**
     * Default client
     */
    default_client?: {
        entity_id: string;
    };

    /**
     * Authentication token.
     */
    authentication_token?: string;

    authenticationType?: string;

    authenticationToken?: string;

    /**
     * Constructor.
     */
    constructor(jsonObject: Object) {
        if (jsonObject["authentication_type"] !== undefined) {
            this.authenticationType = jsonObject["authentication_type"];
            this.authenticationToken = jsonObject["verification_token"];
        } else {
            this.entity_id = jsonObject["entity_id"];
            this.authentication_token = jsonObject["authentication_token"];
            this.default_client = jsonObject["default_client"];
        }
    }

    public getRedirectURL(): null | string {
        if (this.authenticationType && this.authenticationType == AuthenticationType.MFA.toString()) {
            return `/mfa?token=${this.authenticationToken}`;
        }
        return null;
    }
}

export default AuthenticationCommandResponse;
