import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

const devicesListStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        paperClass: {
            overflowY: "auto" as "auto",
        },
        menuItem: {
            height: theme.spacing(2.5),
        },
        iconExtraSmallSize: {
            fontSize: theme.spacing(3),
        },
        menuItemTopBottomPadding: {
            paddingTop: 5,
            paddingBottom: 5,
        },

        menuItemLeftRightPadding: {
            paddingRight: 10,
            paddingLeft: 10,
        },

        limitHeight: {
            height: "100%",
        },
    });

export default devicesListStyle;
