import { makeStyles } from "@material-ui/core";

export const useDeviceTileItemStyle = makeStyles((theme) => ({
    deviceTileItemContainer: {
        display: "flex",
        flexFlow: "column",
        borderRadius: 20,
        background: "#F0F3F5",
        padding: "13px",
        width: 193,
        minHeight: 160,
        boxSizing: "border-box",
    },

    title: {
        color: "#555B61",
        fontWeight: 500,
        fontSize: 13,
        marginBottom: 8,
    },

    keyValueContainer: {
        display: "flex",
        flexFlow: "column",
        gap: 2,
    },

    keyValue: {
        display: "flex",
        flexWrap: "wrap",
        gap: "3pt",
        fontSize: 12,
    },

    flexNoWrap: {
        flexWrap: "nowrap",
    },

    key: {
        color: "#8B949E",
    },

    value: {
        color: "#555b61",
    },

    country: {
        display: "flex",
        flexWrap: "nowrap",
        gap: 4,
        alignItems: "center",
        overflow: "hidden",
    },

    withDots: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },

    tag: {
        display: "flex",
        color: "#3FC47C",
        alignItems: "center",
        gap: 4,
        marginTop: 8,
    },

    spacer: {
        flex: 1,
    },
}));
