import React, { useEffect, useLayoutEffect, useRef } from "react";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import ServiceWire from "../../lib/services/ServiceWire";
import AggregateOverProperty from "../../lib/state/AggregateOverProperty";
import IssueInfo from "../../lib/state/IssueInfo";
import moment from "moment";

export interface LostConnectionChartProps {
    issue: IssueInfo;
}

am4core.useTheme(am4themes_animated);
am4core.options.autoDispose = true;

const CORNER_RADIUS = 4;

interface LostConnectionItem {
    /**
     * Timestamp when lost connection started
     */
    from: number | Date;

    /**
     * Timestamp when lost connection stopped
     * (if connection still lost, can be set to Date.now())
     */
    to: number | Date;

    /**
     * Needed for lost connection bar chart height.
     * Should be always set to 0
     */
    lostConnectionOpen: number;

    /**
     * Needed for lost connection bar chart height.
     * Should be set to max value in data
     */
    lostConnectionValue: number;

    date?: Date;
}

interface ExpectedValueItem {
    date: Date;
    from: Date;
    to: Date;
    value: number;
    aggregatedInterval: string;
}

export function LostConnectionChart({ issue }: LostConnectionChartProps) {
    const rootRef = useRef<HTMLDivElement>(null);
    const chartRef = useRef<am4charts.XYChart | null>(null);

    useLayoutEffect(() => {
        const chart = am4core.create(rootRef.current, am4charts.XYChart);
        chart.paddingLeft = 0;
        chart.paddingRight = 0;
        chartRef.current = chart;

        const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.dataFields.date = "date";
        dateAxis.renderer.labels.template.fill = am4core.color("#BCBED2");
        dateAxis.renderer.grid.template.stroke = am4core.color("#F4F4F4");
        dateAxis.renderer.grid.template.strokeOpacity = 1;
        dateAxis.renderer.labels.template.fontSize = 12;
        dateAxis.cursorTooltipEnabled = false;
        // dateAxis.renderer.cellStartLocation = 0;
        // dateAxis.renderer.cellEndLocation = 0.5;
        // dateAxis.baseInterval = {
        //     timeUnit: "hour",
        //     count: 10,
        // };

        dateAxis.groupData = true;
        dateAxis.groupCount = 100;

        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = "";
        valueAxis.renderer.labels.template.fontSize = 12;
        valueAxis.renderer.grid.template.stroke = am4core.color("#F4F4F4");
        valueAxis.renderer.grid.template.strokeOpacity = 1;
        valueAxis.maxPrecision = 0;
        valueAxis.cursorTooltipEnabled = false;

        const series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "value";
        series.dataFields.dateX = "date";
        series.fill = am4core.color("#DDE9F9");
        series.strokeWidth = 0;
        // series.columns.template.minWidth = 10;
        // series.columns.template.width = am4core.percent(100);
        // series.columns.template.width = 10;
        series.columns.template.column.cornerRadius(CORNER_RADIUS, CORNER_RADIUS, CORNER_RADIUS, CORNER_RADIUS);
        series.columns.template.tooltip = new am4core.Tooltip();
        series.columns.template.tooltipHTML = "{aggregatedInterval}: <strong>{valueY}</strong>";
        series.columns.template.tooltip.background.cornerRadius = 6;
        series.columns.template.tooltip.background.strokeOpacity = 0;
        series.columns.template.tooltip.background.fillOpacity = 1;
        series.columns.template.tooltip.background.filters.clear();
        series.columns.template.tooltip.getFillFromObject = false;
        series.columns.template.tooltip.background.fill = am4core.color("#000");
        series.columns.template.tooltip.background.stroke = null;
        series.groupFields.valueY = "sum";

        series.adapter.add("groupDataItem", (value) => {
            // for aggregated group item
            let minDate = Number.MAX_SAFE_INTEGER;
            let maxDate = Number.MIN_SAFE_INTEGER;

            value.dataItem.groupDataItems.forEach((item) => {
                const typedItem = item.dataContext as ExpectedValueItem;

                if (minDate > typedItem.date.getTime()) {
                    minDate = typedItem.date.getTime();
                }

                if (maxDate < typedItem.date.getTime()) {
                    maxDate = typedItem.date.getTime();
                }
            });

            let aggregatedInterval = "";
            const minFormatted = moment(minDate).format("DD-MM-YYYY");
            const maxFormatted = moment(maxDate).format("DD-MM-YYYY");

            if (minDate === maxDate) {
                aggregatedInterval = minFormatted;
            } else {
                aggregatedInterval = `${minFormatted} - ${maxFormatted}`;
            }

            (value.dataItem.dataContext as ExpectedValueItem).aggregatedInterval = aggregatedInterval;

            return { ...value };
        });

        const lostConnectionSeries = chart.series.push(new am4charts.ColumnSeries());
        lostConnectionSeries.dataFields.openDateX = "from";
        lostConnectionSeries.dataFields.dateX = "to";
        lostConnectionSeries.dataFields.openValueY = "lostConnectionOpen";
        lostConnectionSeries.dataFields.valueY = "lostConnectionValue";
        lostConnectionSeries.legendSettings.labelText = "Anomaly";
        lostConnectionSeries.fill = am4core.color("rgba(231, 0, 76, 0.1)");
        lostConnectionSeries.columns.template.strokeWidth = 0;
        lostConnectionSeries.columns.template.width = am4core.percent(100);

        /**
         * Assuming this series adapter will run after the
         * previous series adapter
         */
        // lostConnectionSeries.adapter.add("groupDataItem", (data) => {
        //     console.log(series.max(valueAxis));
        //     if (data.dataField === "valueY" && data.value > 0) {
        //         data.value = series.max(valueAxis);
        //     }

        //     return { ...data };
        // });

        series.events.on("extremeschanged", () => {
            const data: (ExpectedValueItem | LostConnectionItem)[] = chart.data;
            if (data.length > 0) {
                const seriesMax = series.max(valueAxis);

                if ((data[data.length - 1] as LostConnectionItem).lostConnectionValue !== seriesMax) {
                    (data[data.length - 1] as LostConnectionItem).lostConnectionValue = seriesMax;
                    chart.data = data;
                }
            }
        });

        // lostConnectionSeries.adapter.add("groupValue", (value) => {
        //     if (value.dataField === "valueY" && value.value === undefined) {
        //         return {
        //             ...value,
        //             value: series.max(valueAxis),
        //         }
        //     }

        //     return value;
        // });

        chart.events.on("appeared", () => {
            const column = lostConnectionSeries.columns.getIndex(lostConnectionSeries.columns.length - 1);
            if (!column) return;

            const label = column.createChild(am4core.Label);
            label.text = "Device stopped sending pulse";
            label.verticalCenter = "bottom";
            label.fill = am4core.color("#FF2263");
            label.opacity = 0.5;
            label.dx = 20;
            label.y = am4core.percent(95);

            const verticalLine = column.createChild(am4core.Line);
            verticalLine.stroke = am4core.color("#FF2263");
            verticalLine.strokeWidth = 1;
            verticalLine.strokeDasharray = "3,3";
            verticalLine.y = 0;
            verticalLine.height = am4core.percent(100);

            const horizontalLine = column.createChild(am4core.Line);
            horizontalLine.stroke = am4core.color("#FF2263");
            horizontalLine.strokeWidth = 2;
            horizontalLine.verticalCenter = "bottom";
            horizontalLine.width = am4core.percent(100);
            horizontalLine.y = am4core.percent(100);

            valueAxis.zoom({
                start: 0,
                end: 1,
            });
        });

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.lineX.disabled = true;
        chart.cursor.lineY.disabled = true;
    }, []);

    useEffect(() => {
        (async () => {
            const endAt = issue.endAt || Date.now();

            const data = await ServiceWire.getSternumService().getEventCountsOverTimeAggregation(
                issue.deviceId,
                1,
                "DAY",
                moment(endAt).subtract(1, "year").valueOf(),
                endAt,
                AggregateOverProperty.TRACE_CATEGORY_NAME,
                true,
                "MM/dd HH:mm",
                10
            );

            const chartData: (ExpectedValueItem | LostConnectionItem)[] = [];

            let maxValue = 1;

            Object.entries(data.dateToEventsMap)
                .sort((a, b) => +a[0] - +b[0])
                .forEach(([timestamp, dataPerTimestamp]) => {
                    if (isNumeric(timestamp) && dataPerTimestamp.length > 0) {
                        let count = 0;
                        dataPerTimestamp.forEach((item) => {
                            count += item.count;
                        });

                        if (count > maxValue) {
                            maxValue = count;
                        }

                        chartData.push({
                            date: new Date(+timestamp),
                            from: new Date(+timestamp),
                            to: new Date(+timestamp),
                            value: count,
                            aggregatedInterval: moment(+timestamp).format("DD-MM-YYYY"),
                        });
                    }
                });

            chartData.push({
                // date: new Date(issue.created),
                date: new Date(issue.created),
                from: new Date(issue.created),
                to: new Date(endAt),
                lostConnectionOpen: 0,
                lostConnectionValue: maxValue,
            });

            chartRef.current.data = chartData;
        })();
    }, [issue.deviceId, issue.created, issue.endAt]);

    return <div ref={rootRef} style={{ minHeight: 400, flex: 1, padding: "44px 30px" }}></div>;
}

function isNumeric(str: string) {
    if (typeof str != "string") return false; // we only process strings!
    return (
        !isNaN(str as unknown as number) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
}
