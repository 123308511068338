import { makeStyles, Theme } from "@material-ui/core";

export const useDashboardStyle = makeStyles((theme: Theme) => ({
    grid: {
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: "15px",
        width: "auto",
        margin: 0,
    },

    gridWhenAlertsTableExpanded: {
        height: "100vh",
    },

    dataTile: {
        border: "1px solid rgba(0, 0, 0, .0625)",

        "&.mod-no-border": {
            border: "none",
        },
    },

    metricDataTile: {},

    issuesListContainer: {
        marginTop: 10,
        height: "calc(100% - 10px)", // 10px is marginTop
        overflow: "auto",
    },

    issuesListContainerFullScreen: {
        overflow: "auto",
        height: "calc(100vh - 60px - 30px - 28px)", // 60px - sum of height of inline sections above, second 30px - sum of both paddings, 28px - sum of all margins
    },
}));
