import { makeStyles } from "@material-ui/core";

export const useDashboardFreeStyle = makeStyles((theme) => ({
    root: {
        padding: 16,
        width: "100%",
        boxSizing: "border-box",
    },

    tilesContainer: {
        display: "grid",
        gridTemplateAreas: `"connectedDevices vulnerabilities" "coverage vulnerabilities" "coverage simulateAttack" "glances glances"`,
        gap: 16,
        gridTemplateRows: "auto auto auto",
        gridTemplateColumns: "1.5fr 1fr",
    },

    tileConnectedDevices: {
        gridArea: "connectedDevices",
    },

    tileVulnerabilities: {
        gridArea: "vulnerabilities",
    },

    tileCoverage: {
        gridArea: "coverage",
    },

    tileSimulateAttack: {
        gridArea: "simulateAttack",
    },

    tileGlances: {
        gridArea: "glances",
    },

    tileItem: {
        boxShadow: "-12px 14px 34px rgba(83, 93, 120, 0.15)",
        borderRadius: 14,
        padding: 16,
        background: "white",
    },

    spinnerContainer: {
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));
