import AggregationFunctionType from "../../../lib/state/Visualisation/AggregationFunctionType";
import AggregationFunctionConfiguration from "../../../lib/state/AggregationFunctionConfiguration";

function getAggregationFunctionsMap(): Record<AggregationFunctionType, AggregationFunctionConfiguration> {
    return {
        [AggregationFunctionType.COUNT]: {
            apiName: AggregationFunctionType.COUNT,
            value: AggregationFunctionType.COUNT,
            label: "Count",
            order: 0,
        },
        [AggregationFunctionType.UNIQUE_COUNT]: {
            apiName: AggregationFunctionType.UNIQUE_COUNT,
            value: AggregationFunctionType.UNIQUE_COUNT,
            label: "Unique count",
            order: 1,
        },
        [AggregationFunctionType.AVG]: {
            apiName: AggregationFunctionType.AVG,
            value: AggregationFunctionType.AVG,
            label: "Average",
            order: 2,
        },
        [AggregationFunctionType.MIN]: {
            apiName: AggregationFunctionType.MIN,
            value: AggregationFunctionType.MIN,
            label: "Minimum",
            order: 3,
        },
        [AggregationFunctionType.MAX]: {
            apiName: AggregationFunctionType.MAX,
            value: AggregationFunctionType.MAX,
            label: "Maximum",
            order: 4,
        },
        [AggregationFunctionType.SUM]: {
            apiName: AggregationFunctionType.SUM,
            value: AggregationFunctionType.SUM,
            label: "Sum",
            order: 5,
        },
        [AggregationFunctionType.PERCENTILE]: {
            apiName: AggregationFunctionType.PERCENTILE,
            value: AggregationFunctionType.PERCENTILE,
            label: "Percentile",
            order: 6,
        },
    };
}

export default getAggregationFunctionsMap;
