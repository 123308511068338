import { makeStyles } from "@material-ui/core";

export const useDashboardInteractiveMapStyles = makeStyles((theme) => {
    return {
        mapWrapper: {
            position: "relative",
        },

        mapTitle: {
            fontSize: 18,
            fontWeight: 600,
        },

        mapContainer: {
            position: "relative",
        },

        locationSwitchContainer: {
            display: "flex",
        },

        locationSwitchOption: {
            padding: theme.spacing(1, 3),
            cursor: "pointer",
            border: "1px solid #1B6FDE",
            color: "#1B6FDE",

            "&:first-child": {
                borderTopLeftRadius: 14,
                borderBottomLeftRadius: 14,
            },

            "&:last-child": {
                borderTopRightRadius: 14,
                borderBottomRightRadius: 14,
            },

            "&.selected": {
                background: "#1B6FDE",
                color: "#fff",
            },
        },

        mapBlur: {
            filter: "blur(2px)",
        },

        mapContainerOverlay: {
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: 1,
        },

        mapLoader: {
            position: "absolute",
            top: "40%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        },
    };
});
