import MomentUtils from "@date-io/moment";
import Button from "@material-ui/core/Button";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import classNames from "classnames";
import * as React from "react";
import IssuesFilter from "../../lib/state/IssuesFilter";
import ListFilter from "../../lib/state/ListFilter";
import issuesListFilterPopoverStyle from "./IssuesListFilterPopoverStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    startTime: number;
    endTime: number;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof issuesListFilterPopoverStyle> {
    issuesFilter: IssuesFilter;
    onFilterChanged: (listFilter: ListFilter) => void;
}

/**
 * Popover content for view columns.
 */
class IssuesListFilterPopover extends React.Component<AppProps, AppState> {
    /**
     * Defining default state so we can later reset safely.
     */
    private readonly defaultState: AppState = {
        startTime: null,
        endTime: null,
    };

    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            startTime:
                this.props.issuesFilter && this.props.issuesFilter.startTime ? this.props.issuesFilter.startTime : null,
            endTime:
                this.props.issuesFilter && this.props.issuesFilter.endTime ? this.props.issuesFilter.endTime : null,
        };
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        return (
            <div>
                {/* Filter popover title */}
                <Typography variant={"caption"} className={classes.titleTypography}>
                    Filter
                </Typography>

                {/* From */}
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DateTimePicker
                        value={this.state.startTime}
                        className={classNames(classes.formControl, classes.fromTimePicker)}
                        label={"From"}
                        clearable
                        onChange={(event) => this.handleStartTimeChanged(event)}
                    />
                </MuiPickersUtilsProvider>

                {/* To */}
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DateTimePicker
                        value={this.state.endTime}
                        className={classNames(classes.formControl)}
                        label={"To"}
                        clearable
                        onChange={(event) => this.handleEndTimeChanged(event)}
                    />
                </MuiPickersUtilsProvider>

                {/* Apply & Reset buttons */}
                <div className={classes.resetAndApplyButtons}>
                    {/* Apply */}
                    <Button
                        size={"small"}
                        variant={"contained"}
                        color={"primary"}
                        className={classes.applyButton}
                        onClick={(event) => this.handleFilterApplied()}
                    >
                        Apply
                    </Button>

                    {/* Reset */}
                    <Button size={"small"} variant={"contained"} onClick={(event) => this.handleFilterReset()}>
                        Reset
                    </Button>
                </div>
            </div>
        );
    }

    /**
     * Occurs once filter is applied.
     */
    private handleFilterApplied() {
        if (this.props.onFilterChanged) {
            this.props.onFilterChanged(new IssuesFilter(this.state.startTime, this.state.endTime, false, true));
        }
    }

    /**
     * Occurs on reset of filters requested.
     */
    private handleFilterReset() {
        this.setState({
            ...this.defaultState,
        });
    }

    /**
     * Occurs once the value of last seen from is changed.
     */
    private handleStartTimeChanged(newDate) {
        this.setState({
            startTime: new Date(newDate).getTime(),
        });
    }

    /**
     * Occurs once the value of last seen to is changed.
     */
    private handleEndTimeChanged(newDate) {
        this.setState({
            endTime: new Date(newDate).getTime(),
        });
    }
}

export default withStyles(issuesListFilterPopoverStyle)(IssuesListFilterPopover);
