import { call, put, select, takeEvery } from "redux-saga/effects";
import FetchError from "../../state/FetchError";
import ActionType from "../ActionType";
import ServiceWire from "../../services/ServiceWire";
import { FetchUsedLibraryAction } from "./FetchUsedLibraryAction";
import usedLibrarySelector from "./UsedLibrarySelector";
import { fetchedUsedLibrarySuccessfullyAction } from "./FetchedUsedLibrarySuccessfullyAction";
import { errorFetchingUsedLibraryAction } from "./ErrorFetchingUsedLibraryAction";

/**
 * Fetches a used library from API.
 */
export function* fetchUsedLibraryAsync(action: FetchUsedLibraryAction) {
    try {
        // Trying to retrieve used library from cache.
        // let usedLibrary = yield select(usedLibrarySelector, action.usedLibraryId);

        const usedLibrary = yield call(
            [ServiceWire.getSternumService(), ServiceWire.getSternumService().getUsedLibraryById],
            action.usedLibraryId
        );

        // Dispatching a successful fetch of used library.
        yield put(fetchedUsedLibrarySuccessfullyAction(usedLibrary));
    } catch (error) {
        // Error occurred, dispatching an erroneous fetch of used library.
        yield put(errorFetchingUsedLibraryAction(action.usedLibraryId, new FetchError(error.message)));
    }
}

/**
 * Watching the ActionType.FetchUsedLibrary action for fetching used library from API.
 */
export function* watchFetchUsedLibraryAsync() {
    yield takeEvery((action) => action.type === ActionType.FetchUsedLibrary, fetchUsedLibraryAsync);
}
