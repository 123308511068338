/**
 * Defines the different types of links on the sidebar.
 */
enum SideBarSectionType {
    DASHBOARD = "DASHBOARD",
    DEVICES = "DEVICES",
    TRACES = "TRACES",
    LIBRARIES_DASHBOARD = "LIBRARIES_DASHBOARD",
    DEVICE_DEFINITIONS = "DEVICE_DEFINITIONS",
    USER_SETTINGS = "USER_SETTINGS",
    ACTIVITIES = "ACTIVITIES",
    LIBRARIES = "LIBRARIES",
    LIBRARIES_HISTORIES = "LIBRARIES_HISTORIES",
    USED_LIBRARIES = "USED_LIBRARIES",
    USED_LIBRARIES_HISTORIES = "USED_LIBRARIES_HISTORIES",
    CVES = "CVES",
    ISSUES = "ISSUES",
    CLIENTS = "CLIENTS",
    USERS = "USERS",
    FLEET_VIEW = "FLEET_VIEW",
    ANALYTICS = "ANALYTICS",
}

export default SideBarSectionType;
