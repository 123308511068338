import * as React from "react";
import ReactCountryFlag from "react-country-flag";
import useSternumFlagStyles from "./SternumFlagStyles";

interface AppProps {
    countryCode: string;
}

const SternumFlag = ({ countryCode }: AppProps) => {
    const classes = useSternumFlagStyles();

    return (
        <ReactCountryFlag
            countryCode={countryCode}
            svg
            style={{
                fontSize: "1.2em",
                lineHeight: "1.2em",
            }}
        />
    );
};

export default SternumFlag;
