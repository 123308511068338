import {
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import classNames from "classnames";
import React, { useState } from "react";
import useSternumInputFieldStyles from "./SternumInputFieldStyles";

interface AppProps {
    label?: string;
    onFieldChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    onKeyPress?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
    onTouch?: () => unknown;
    inputValue: string | number;
    name?: string;
    disabled?: boolean;
    noLabel?: boolean;
    isPassword?: boolean;
    isSelect?: boolean;
    isTouched?: boolean;
    required?: boolean;
    helperText?: string;
    error?: boolean;
    addMargin?: boolean;
    className?: string;
    passwordLabelSize?: number;
    children?: any;
}

const SternumInputField = ({
    label,
    onFieldChange,
    inputValue,
    name,
    onKeyPress,
    disabled = false,
    noLabel = false,
    isPassword = false,
    required = true,
    helperText = "",
    error = false,
    addMargin = false,
    passwordLabelSize = 70,
    isSelect = false,
    isTouched = true,
    onTouch,
    className,
    children,
}: AppProps) => {
    error = isTouched ? error : false;

    const classes = useSternumInputFieldStyles();

    const [isPasswordMode, setPasswordMode] = useState(true);

    // Password input field
    if (isPassword) {
        return (
            <FormControl
                role="presentation"
                aria-label="sternum input field"
                variant="outlined"
                fullWidth
                className={classNames(classes.root, addMargin && classes.marginBottomXLarge)}
                onBlur={() => onTouch?.()}
            >
                <InputLabel
                    htmlFor="outlined-adornment-password"
                    className={classNames(classes.placeHolderFontSize)}
                    required={required}
                    error={error}
                >
                    {label}
                </InputLabel>
                <OutlinedInput
                    className={classNames(classes.borderRadius, classes.displayInput, classes.root)}
                    id="outlined-adornment-password"
                    type={isPasswordMode ? "password" : "text"}
                    value={inputValue}
                    name={name}
                    onChange={onFieldChange}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => {
                                    setPasswordMode(!isPasswordMode);
                                }}
                                onMouseDown={() => {}}
                                edge="end"
                            >
                                {isPasswordMode ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    labelWidth={passwordLabelSize}
                    disabled={disabled}
                    error={error}
                />
                <FormHelperText error id="password-error" className={classes.zeroMargin}>
                    {helperText}
                </FormHelperText>
            </FormControl>
        );
    }
    // Input field without a label
    if (noLabel) {
        return (
            <FormControl
                role="presentation"
                aria-label="sternum input field"
                variant="outlined"
                className={classNames(classes.root, addMargin && classes.marginBottomXLarge)}
                fullWidth
                onBlur={() => onTouch?.()}
            >
                <OutlinedInput
                    className={classNames(
                        classes.borderRadius,
                        classes.displayInput,
                        disabled && classes.disabledBackgroundColor,
                        className
                    )}
                    id={label}
                    value={inputValue}
                    name={name}
                    onChange={onFieldChange}
                    aria-describedby=""
                    inputProps={{}}
                    labelWidth={0}
                    disabled={disabled}
                    error={error}
                />
            </FormControl>
        );
    }
    // Regular input field
    return (
        <TextField
            role="presentation"
            aria-label="sternum input field"
            className={classNames(
                addMargin && classes.marginBottomXLarge,
                disabled && classes.disableColor,
                classes.root,
                className
            )}
            id={label}
            label={label}
            onChange={onFieldChange}
            required={required}
            value={inputValue}
            name={name}
            margin="normal"
            variant="outlined"
            fullWidth
            InputProps={{
                classes: {
                    root: classes.borderRadius,
                    input: classes.displayInput,
                },
            }}
            InputLabelProps={{ className: classes.placeHolderFontSize }}
            onKeyPress={onKeyPress}
            disabled={disabled}
            helperText={helperText}
            error={error}
            select={isSelect}
            onBlur={() => onTouch?.()}
        >
            {children}
        </TextField>
    );
};

export default SternumInputField;
export { SternumInputField };
