import SternumDeviceEventInfo from "./SternumDeviceEventInfo";

/**
 * Response of the get sternum device events command.
 */
class GetSternumDeviceEventsResponse {
    /**
     * Constructor.
     */
    constructor(public sternumDeviceEvents: SternumDeviceEventInfo[], public totalItemCount: number) {}
}

export default GetSternumDeviceEventsResponse;
