import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import ServiceWire from "../../../../lib/services/ServiceWire";
import CustomDashboardInfo from "../../../../lib/state/CustomDashboardInfo";
import DashboardVisualisationCard from "../../../DashboardsPage/DashboardVisualisationCard/DashboardVisualisationCard";
import TimeSelectOption from "../../../../lib/state/TimeSelectOption";
import getDefaultTimeSelection from "../../../NewDeviceView/getDefaultTimeSelection";
import { useGlancesStyle } from "./Glances.style";
import { SeeMoreIcon } from "../../../SUI/SternumIcon";
import VisualisationType from "../../../../lib/state/Visualisation/VisualisationType";
import { GlobalState } from "../../../../lib/state/GlobalState";
import { openVisualizationCreationModalAction } from "../../../../lib/redux/modals/OpenModalAction";
import DeviceDefinitionVersionInfo from "../../../../lib/state/DeviceDefinitionVersionInfo";
import ReceivedDefinitionsResponse from "../../../../lib/services/events/ReceivedDefinitionsResponse";
import SternumQuery from "../../../../lib/state/SternumQuery";
import { Title } from "../Title";

const WHITELIST_GLANCES = ["Activity Graph", "Prevented Attack Attempts & Alerts Over Time"];

type GlancesProps = {};

type GlancesState = {
    loading: boolean;
    error: boolean;
    customDashboardInfo: CustomDashboardInfo | null;
    timeSelectOption: TimeSelectOption;
};

type ConnectedGlancesProps = GlancesProps & ReturnType<typeof mapDispatchToProps>;

const mapStateToProps = (state: GlobalState, ownProps: any) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        openVisualizationCreationModal: (
            key: string,
            visualisationId: string,
            deviceDefinitionVersions: DeviceDefinitionVersionInfo[],
            receivedDefinitionsResponse: Record<string, ReceivedDefinitionsResponse>,
            startTime: Date,
            endTime: Date,
            sternumQuery: SternumQuery,
            displayViewForDeviceDefinition: boolean,
            displayXButton: boolean,
            displayBackButton: boolean,
            lookedUpEntityId: string,
            timeSelectedOption: TimeSelectOption,
            visualisationType?: VisualisationType,
            dashboardId?: string,
            onVisualisationCreated?: (visualisationId: string, displayName: string) => void,
            onVisualisationUpdated?: (visualisationId: string, displayName: string) => void,
            onVisualisationDeleted?: (visualisationId: string) => void
        ) =>
            dispatch(
                openVisualizationCreationModalAction(
                    key,
                    visualisationId,
                    deviceDefinitionVersions,
                    receivedDefinitionsResponse,
                    startTime,
                    endTime,
                    sternumQuery,
                    displayViewForDeviceDefinition,
                    displayXButton,
                    displayBackButton,
                    lookedUpEntityId,
                    timeSelectedOption,
                    undefined,
                    visualisationType,
                    dashboardId,
                    onVisualisationCreated,
                    onVisualisationUpdated,
                    onVisualisationDeleted
                )
            ),
    };
};

export function GlancesComponent(props: ConnectedGlancesProps) {
    const history = useHistory();
    const classes = useGlancesStyle();

    const [state, setState] = useState<GlancesState>({
        loading: true,
        error: false,
        customDashboardInfo: null,
        timeSelectOption: getDefaultTimeSelection(),
    });

    useEffect(() => {
        (async () => {
            try {
                const dashboardInfos = await ServiceWire.getCustomDashboardsApiService().getDefaultDashboardSummery(
                    ServiceWire.getClientsService().getSelectedClientId()
                );
                // technically there should be only one dashboard so it's ok to take first one
                const dashboardInfo = dashboardInfos[0];
                setState((s) => ({
                    ...s,
                    loading: false,
                    customDashboardInfo: dashboardInfo,
                }));
            } catch (e) {
                setState((s) => ({
                    ...s,
                    error: true,
                }));
            }
        })();
    }, []);

    const openVisualizationModal = useCallback(
        (visualisationId?: string, visualisationType?: VisualisationType, lookedUpEntityId?: string) => {
            props.openVisualizationCreationModal(
                "VisualizationCreation",
                visualisationId,
                state.customDashboardInfo.deviceDefinitionVersions,
                {},
                state.timeSelectOption.timeRange.start,
                state.timeSelectOption.timeRange.end,
                undefined,
                true,
                false,
                false,
                lookedUpEntityId,
                state.timeSelectOption,
                visualisationType,
                state.customDashboardInfo.customDashboardId,
                (visualisationId, displayName) => console.warn("This should never happen for free user"),
                (visualisationId, displayName) => console.warn("This should never happen for free user"),
                undefined
            );
        },
        [state]
    );

    if (state.loading || state.error) {
        return null;
    }

    return (
        <div className={classes.root}>
            <div className={classes.titleContainer}>
                <Title>Monitor your devices</Title>
                <div className={classes.subtitle}>
                    Get information and see trends on attack attempts, device behavior and more.
                </div>
            </div>
            <div className={classes.glancesContainer}>
                {state.customDashboardInfo.visualisationSummaries
                    .filter((v) => WHITELIST_GLANCES.includes(v.displayName))
                    .map((visualisation, index) => (
                        <div className={classNames(classes.item)} key={`${visualisation.visualisationId}`}>
                            <DashboardVisualisationCard
                                visualisation={visualisation}
                                index={index}
                                dashboardDeviceDefinitionVersions={state.customDashboardInfo.deviceDefinitionVersions}
                                startTime={state.timeSelectOption.timeRange.start}
                                endTime={state.timeSelectOption.timeRange.end}
                                isDisabled={false}
                                openVisualisationModal={openVisualizationModal}
                                onVisualisationDelete={() => {
                                    console.warn("This should never happen in free users dashboard...");
                                }}
                            />
                        </div>
                    ))}
                <div
                    className={classNames(classes.item, classes.seeMoreContainer)}
                    onClick={() => history.push("/glances")}
                >
                    <SeeMoreIcon />
                    <div className={classes.seeMore}>See more</div>
                </div>
            </div>
        </div>
    );
}

export const Glances: React.FC<GlancesProps> = connect<GlancesProps>(
    mapStateToProps,
    mapDispatchToProps
)(GlancesComponent);
