import { Theme } from "@material-ui/core/styles";
import commonStyle from "../../../CommonStyle";
import createStyles from "@material-ui/core/styles/createStyles";

const sternumTableHeaderStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),
        tableHeaderRow: {
            height: theme.spacing(6),
        },
        tableHeaderCell: {
            // "&.mod-upper-border": {
            //     borderTop: "1px solid rgba(224, 224, 224, 1)",
            // },
        },
        primaryHeader: {
            width: "45%",
        },
        narrow: {
            padding: "0px 10px 0px 0px !important",
            fontSize: "14px",
            overflowX: "hidden",
            textOverflow: "ellipsis",
            height: "54px",

            "& *": {
                overflowX: "hidden",
                textOverflow: "ellipsis",
                fontSize: "14px",
                maxWidth: "100%",
            },
        },
    });

export default sternumTableHeaderStyle;
