import TableRowData from "./TableRowData";
import EntityBase from "./EntityBase";
import EntityType from "./EntityType";

class LibraryInfo extends EntityBase implements TableRowData {
    /**
     * Constructor.
     */
    constructor(
        public entityId: string,
        public created: number,
        public updated: number,
        public name: string,
        public latestKnownVersion: string,
        public informationSource: string,
        public librarySource: string,
        public organization: string,
        public vendor: string,
        public originalName: string
    ) {
        super(entityId, created, updated, EntityType.Library);
    }

    /**
     * Converts json to library info object.
     */
    public static fromJsonObject(jsonObject: Object) {
        return new LibraryInfo(
            jsonObject["entity_id"],
            jsonObject["created"],
            jsonObject["updated"],
            jsonObject["name"],
            jsonObject["latest_known_version"],
            jsonObject["information_source"],
            jsonObject["library_source"],
            jsonObject["organization"],
            jsonObject["vendor"],
            jsonObject["original_name"]
        );
    }

    /**
     * Gets the value for the given column id.
     */
    public getColumnValue(columnHeaderId: string): any {
        switch (columnHeaderId) {
            case "entityId":
                return this.entityId;
            case "name":
                return this.name;
            case "created":
                return this.created;
            case "updated":
                return this.updated;
            case "latestKnownVersion":
                return this.latestKnownVersion;
            case "informationSource":
                return this.informationSource;
        }
    }

    /**
     * Returns whether given row is any different in details than current library info.
     * Shallow comparison, in other words.
     * @param other The row to compare with.
     */
    public isDifferentFrom(other: TableRowData): boolean {
        // Row must be of type library info.
        if (!(other instanceof LibraryInfo)) {
            return true;
        }

        let otherLibraryInfo = other as LibraryInfo;

        // Comparing base library info properties.
        if (
            otherLibraryInfo.entityId !== this.entityId ||
            otherLibraryInfo.created !== this.created ||
            otherLibraryInfo.updated !== this.updated ||
            otherLibraryInfo.name !== this.name ||
            otherLibraryInfo.vendor !== this.vendor ||
            otherLibraryInfo.latestKnownVersion !== this.latestKnownVersion ||
            otherLibraryInfo.informationSource !== this.informationSource
        ) {
            return true;
        }

        return false;
    }
}

export default LibraryInfo;
