import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import sternumFilterEditorStyle from "./SternumFilterEditorStyle";
import { Input } from "@material-ui/core";
import classNames from "classnames";

/**
 * Holds the inner state for our app.
 */
interface AppState {}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof sternumFilterEditorStyle> {}

/**
 * Single filter editor.
 */
class SternumFilterEditorLoadingComponent extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {};
    }

    /**
     * Renders the component.
     */
    render() {
        let { classes } = this.props;

        return (
            <div className={classNames(classes.root, "mod-row")}>
                <div className={classNames(classes.marginRight, classes.loadingPlaceholder, "mod-input-medium")} />

                <div className={classNames(classes.marginRight, classes.loadingPlaceholder, "mod-input-medium")} />

                <Input
                    className={classNames(classes.valueInputBox, classes.selectComponent, classes.marginRight)}
                    disabled={true}
                />
            </div>
        );
    }
}

export default withStyles(sternumFilterEditorStyle, { withTheme: true })(SternumFilterEditorLoadingComponent);
