/**
 * Holds a value for a filter.
 */
import Cloneable from "../infra/Cloneable";

class SternumFilterValue implements Cloneable<SternumFilterValue> {
    /**
     * Constructor.
     */
    constructor(public value: string, public displayValue?: string) {}

    /**
     * Clones the current object.
     */
    public clone(): SternumFilterValue {
        return new SternumFilterValue(this.value, this.displayValue);
    }
}

export default SternumFilterValue;
