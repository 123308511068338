import { makeStyles } from "@material-ui/core";

export const useButtonStyle = makeStyles((theme) => ({
    button: {
        display: "flex",
        gap: 4,
        alignItems: "center",
        margin: "0 auto 8px auto",
        padding: "4px 8px",
        background: "linear-gradient(90deg, #FC3262 -12.5%, #FF6E30 108.5%)",
        borderRadius: "7px",
        color: "white",
        outline: "none",
        border: "none",
        cursor: "pointer",
        fontWeight: 500,
        fontSize: 13,

        "&:focus": {
            outline: "none",
        },
    },
}));
