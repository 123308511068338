import React, { useMemo } from "react";
import { connect } from "react-redux";

import { PredefinedLinuxVisualisationCard } from "../../../DashboardsPage/PredefinedLinuxVisualisationCard";
import DeviceInfo from "../../../../lib/state/DeviceInfo";
import DeviceDefinitionInfo from "../../../../lib/state/DeviceDefinitionInfo";
import DeviceDefinitionVersionInfo from "../../../../lib/state/DeviceDefinitionVersionInfo";
import { GlobalState } from "../../../../lib/state/GlobalState";
import ReceivedDefinitionsResponse from "../../../../lib/services/events/ReceivedDefinitionsResponse";
import SternumQuery from "../../../../lib/state/SternumQuery";
import TimeSelectOption from "../../../../lib/state/TimeSelectOption";
import VisualisationType from "../../../../lib/state/Visualisation/VisualisationType";
import VisualisationInfo from "../../../../lib/state/Visualisation/VisualisationInfo";
import { openVisualizationCreationModalAction } from "../../../../lib/redux/modals/OpenModalAction";

import { useGlancesGridData } from "./GlancesGrid.hook";

import { useGlancesGridStyles } from "./GlancesGrid.styles";

export interface GlancesGridProps {
    deviceId: string;
    device: DeviceInfo;
    deviceDefinition: DeviceDefinitionInfo;
    deviceDefinitionVersion: DeviceDefinitionVersionInfo;
    timeSelectOption: TimeSelectOption;
    startDate: Date;
    endDate: Date;
    refreshEntitiesFilter?: boolean;
}

const mapStateToProps = (state: GlobalState, ownProps: GlancesGridProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        openVisualizationCreationModal: (
            key: string,
            visualisationId: string,
            deviceDefinitionVersions: DeviceDefinitionVersionInfo[],
            receivedDefinitionsResponse: Record<string, ReceivedDefinitionsResponse>,
            startTime: Date,
            endTime: Date,
            sternumQuery: SternumQuery,
            displayViewForDeviceDefinition: boolean,
            displayXButton: boolean,
            displayBackButton: boolean,
            lookedUpEntityId: string,
            timeSelectedOption: TimeSelectOption,
            visualisationType?: VisualisationType,
            dashboardId?: string,
            onVisualisationCreated?: (visualisationId: string, displayName: string) => void,
            onVisualisationUpdated?: (visualisationId: string, displayName: string) => void,
            onVisualisationDeleted?: (visualisationId: string) => void,
            visualisation?: VisualisationInfo,
            disableEditing?: boolean
        ) =>
            dispatch(
                openVisualizationCreationModalAction(
                    key,
                    visualisationId,
                    deviceDefinitionVersions,
                    receivedDefinitionsResponse,
                    startTime,
                    endTime,
                    sternumQuery,
                    displayViewForDeviceDefinition,
                    displayXButton,
                    displayBackButton,
                    lookedUpEntityId,
                    timeSelectedOption,
                    undefined,
                    visualisationType,
                    dashboardId,
                    onVisualisationCreated,
                    onVisualisationUpdated,
                    onVisualisationDeleted,
                    visualisation,
                    disableEditing
                )
            ),
    };
};

type GlancesGridPropsWithHOC = GlancesGridProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export function GlancesGridComponent({
    deviceId,
    device,
    deviceDefinition,
    timeSelectOption,
    startDate: startDateFromProps,
    endDate: endDateFromProps,
    refreshEntitiesFilter,

    openVisualizationCreationModal,
}: GlancesGridPropsWithHOC) {
    const classes = useGlancesGridStyles();

    const { startDate, endDate } = useMemo(() => {
        return { startDate: startDateFromProps, endDate: endDateFromProps };
    }, [refreshEntitiesFilter]);

    const { deviceDefinitionVersion, securityGlances, performanceGlances, operationalGlances } = useGlancesGridData({
        device,
        startDate,
        endDate,
    });

    const handleOpenVisualisationModal = (
        visualisationId?: string,
        visualisationType?: VisualisationType,
        lookedUpEntityId?: string,
        visualisation?: VisualisationInfo,
        disableEditing?: boolean
    ) => {
        openVisualizationCreationModal(
            "VisualizationCreation",
            visualisationId,
            [deviceDefinitionVersion],
            {},
            startDate,
            endDate,
            undefined,
            true,
            false,
            false,
            lookedUpEntityId,
            timeSelectOption,
            visualisationType,
            deviceId,
            undefined, // (visualisationId, displayName) => this.onVisualisationCreated(visualisationId, displayName),
            undefined, // (visualisationId, displayName) => this.onVisualisationUpdated(visualisationId, displayName),
            undefined,
            visualisation,
            disableEditing
        );
    };

    const isPredefinedCardVisible = () => !!deviceDefinitionVersion;

    return (
        <div className={classes.container}>
            <div className={classes.glanceSection} role="presentation" aria-label="overview glance section">
                <h3 className={classes.glanceSectionTitle}>Security</h3>
                <div className={classes.glanceSectionContent}>
                    {securityGlances.map((visualisation) => (
                        <div className={classes.glanceItem} key={visualisation.entityId}>
                            {isPredefinedCardVisible() && (
                                <PredefinedLinuxVisualisationCard
                                    key={visualisation.entityId}
                                    className={classes.predefinedLinuxVisualisationCard}
                                    classNameInner={{
                                        title: classes.predefinedLinuxCardTitle,
                                        icon: classes.predefinedLinuxCardIcon,
                                    }}
                                    startTime={startDate}
                                    endTime={endDate}
                                    dashboardDeviceDefinitionVersions={[deviceDefinitionVersion]}
                                    openVisualisationModal={handleOpenVisualisationModal}
                                    visualisation={visualisation}
                                />
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <div className={classes.glanceSection} role="presentation" aria-label="overview glance section">
                <h3 className={classes.glanceSectionTitle}>Performance</h3>
                <div className={classes.glanceSectionContent}>
                    {performanceGlances.map((visualisation) => (
                        <div className={classes.glanceItem} key={visualisation.entityId}>
                            {isPredefinedCardVisible() && (
                                <PredefinedLinuxVisualisationCard
                                    key={visualisation.entityId}
                                    className={classes.predefinedLinuxVisualisationCard}
                                    classNameInner={{
                                        title: classes.predefinedLinuxCardTitle,
                                        icon: classes.predefinedLinuxCardIcon,
                                    }}
                                    startTime={startDate}
                                    endTime={endDate}
                                    dashboardDeviceDefinitionVersions={[deviceDefinitionVersion]}
                                    openVisualisationModal={handleOpenVisualisationModal}
                                    visualisation={visualisation}
                                />
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <div className={classes.glanceSection} role="presentation" aria-label="overview glance section">
                <h3 className={classes.glanceSectionTitle}>Operational</h3>
                <div className={classes.glanceSectionContent}>
                    {operationalGlances.map((visualisation) => (
                        <div className={classes.glanceItem} key={visualisation.entityId}>
                            {isPredefinedCardVisible() && (
                                <PredefinedLinuxVisualisationCard
                                    key={visualisation.entityId}
                                    className={classes.predefinedLinuxVisualisationCard}
                                    classNameInner={{
                                        title: classes.predefinedLinuxCardTitle,
                                        icon: classes.predefinedLinuxCardIcon,
                                    }}
                                    startTime={startDate}
                                    endTime={endDate}
                                    dashboardDeviceDefinitionVersions={[deviceDefinitionVersion]}
                                    openVisualisationModal={handleOpenVisualisationModal}
                                    visualisation={visualisation}
                                />
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export const GlancesGrid: React.FC<GlancesGridProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(GlancesGridComponent);
