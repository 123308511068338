import { Icon, Typography } from "@material-ui/core";
import _ from "lodash";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { withRouter } from "react-router-dom";

import SternumConfiguration from "../../lib/infra/SternumConfiguration";
import AnalyticsService from "../../lib/services/AnalyticsService";
import ServiceWire from "../../lib/services/ServiceWire";
import GetSternumDeviceEventsResponse from "../../lib/state/GetSternumDeviceEventsResponse";
import SternumDeviceEventInfo from "../../lib/state/SternumDeviceEventInfo";
import PerformanceProcessesDisplayStyle from "./PerformanceProcessesDisplayStyle";
import SternumDeviceEventsFilter from "../../lib/state/SternumDeviceEventsFilter";
import SternumTable from "../SUI/SternumTable/SternumTable";
import TableColumnHeaderInfo from "../../lib/state/TableColumnHeaderInfo";
import HashSet from "../../lib/infra/HashSet";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    entities: SternumDeviceEventInfo[];
    scrollPosition: number;
    pageNumber: number;
    totalItemCount: number;
    loadingEntities: boolean;
    errorLoadingEntities: boolean;
    showInfinityLoader: boolean;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof PerformanceProcessesDisplayStyle> {
    sample: SternumDeviceEventInfo;
    entitiesFilter: SternumDeviceEventsFilter;
    searchText: string;
}

/**
 * Displays arguments when a row is in a list.
 */
class PerformanceProcessesDisplay extends React.Component<AppProps, AppState> {
    /**
     * Defines the column headers participating in the entities table.
     */
    private readonly columnHeaders: TableColumnHeaderInfo[] = [
        new TableColumnHeaderInfo("pid", "PID", false, true, false, false),
        new TableColumnHeaderInfo("ppid", "Parent PID", false, true, false, false),
        new TableColumnHeaderInfo("command", "Process Name", false, true, false, false),
        new TableColumnHeaderInfo("cpuUsage", "CPU Usage", false, true, false, false),
        new TableColumnHeaderInfo("memoryUsage", "Memory Usage", false, true, false, false),
    ];

    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            entities: [],
            scrollPosition: 0,
            pageNumber: 1,
            totalItemCount: 0,
            loadingEntities: false,
            errorLoadingEntities: false,
            showInfinityLoader: false,
        };
    }

    componentDidMount() {
        this.loadProcesses(1);
    }

    /**
     * Gets the display values for row's columns (where they aren't the default).
     */
    private getRowDisplayValues(entity: SternumDeviceEventInfo) {
        const { classes } = this.props;

        const { traceArguments } = entity.traceInfo;

        const pid = traceArguments["SYSTEM_ARG_ROLE_SRC_PID"]?.displayValue;
        const ppid = traceArguments["SYSTEM_ARG_ROLE_SRC_PPID"]?.displayValue;
        const command = traceArguments["ARG_ROLE_COMMAND"]?.displayValue;
        const memoryUsage = parseFloat(traceArguments["SYSTEM_ARG_ROLE_MEMORY_USAGE"]?.displayValue);
        const cpuUsage = parseFloat(traceArguments["SYSTEM_ARG_ROLE_CPU_USAGE"]?.displayValue);

        return {
            pid: <Typography variant="body2">{pid}</Typography>,
            ppid: <Typography variant="body2">{ppid}</Typography>,
            command: <Typography variant="body2">{command}</Typography>,

            memoryUsage: (
                <div className={classNames(classes.flexVMiddle)}>
                    <Typography variant="body2">{`${memoryUsage.toFixed(2)}%`}</Typography>
                </div>
            ),

            cpuUsage: (
                <div className={classNames(classes.flexVMiddle)}>
                    <Typography variant="body2">{`${cpuUsage.toFixed(2)}%`}</Typography>
                </div>
            ),
        };
    }

    private loadProcesses = async (pageNumber: number) => {
        try {
            this.setState({
                loadingEntities: true,
            });

            const response = await this.fetchProcesses(this.props.sample, pageNumber);

            const entities = _.sortBy(
                response.sternumDeviceEvents,
                "traceInfo.traceArguments.SYSTEM_ARG_ROLE_CPU_USAGE.displayValue"
            ).reverse();

            this.setState({
                errorLoadingEntities: false,
                loadingEntities: false,
                showInfinityLoader: false,
                entities,
                totalItemCount: response.totalItemCount,
            });
        } catch (error) {
            AnalyticsService.error("PerformanceProcessesDisplay:loadProcesses", error.message);

            this.setState({ loadingEntities: false, errorLoadingEntities: true, showInfinityLoader: false });
        }
    };

    /**
     * Api call for sample processes.
     */
    private async fetchProcesses(
        entity: SternumDeviceEventInfo,
        pageNumber: number
    ): Promise<GetSternumDeviceEventsResponse> {
        const sampleId = entity.traceInfo.traceArguments["ARG_ROLE_ID"].displayValue;

        return ServiceWire.getSternumService().getDeviceSternumDeviceSampleProcesses(
            entity.device.deviceId,
            parseInt(sampleId, 10),
            undefined,
            entity.created,
            this.props.searchText,
            (pageNumber - 1) * SternumConfiguration.getPageSize(),
            SternumConfiguration.getPageSize()
        );
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        const columnWidthsArray = [20, 20, 20, 20, 20];

        const viewedColumns = ["pid", "ppid", "command", "cpuUsage", "memoryUsage"];

        return (
            <div className={classNames(classes.container)}>
                <SternumTable
                    columnWidthsArray={columnWidthsArray}
                    emptyComponent={
                        <div className={classNames(classes.flexCenter, classes.padding)}>
                            <Typography variant="body2">Processes will appear here.</Typography>
                        </div>
                    }
                    viewedColumnsSet={HashSet.fromValues(viewedColumns)}
                    pageSize={SternumConfiguration.getPageSize()}
                    amountOfLoadingPlaceholders={5}
                    totalItemCount={this.state.totalItemCount}
                    loadingItems={this.state.loadingEntities}
                    errorLoadingItems={this.state.errorLoadingEntities}
                    columnHeaders={this.columnHeaders}
                    rows={this.state.entities}
                    getRowValues={(row) => this.getRowDisplayValues(row as SternumDeviceEventInfo)}
                    nonClickableRows
                    displayLoadingIcon
                    hideUpperBorder
                    hideToolbar
                />
            </div>
        );
    }
}

export default withRouter(withStyles(PerformanceProcessesDisplayStyle)(PerformanceProcessesDisplay));
