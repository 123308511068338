import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { sternumTheme } from "../App";
import commonStyle from "../CommonStyle";

const deviceDefinitionLibrariesListStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        paperClass: {
            overflowY: "auto" as "auto",
        },
        deviceHashOutOfDate: {
            color: sternumTheme.danger.main,
        },
        deviceHashIsAccurate: {
            color: sternumTheme.success.main,
        },
        versionText: {
            fontSize: "1rem",
        },
        actionLinkText: {
            fontWeight: 400,
            marginRight: theme.spacing(1),
            "&:hover": {
                textDecoration: "underline",
            },
        },
        rowHover: {
            "&:hover": {
                backgroundColor: `${sternumTheme.lightGrey.main} !important`,
            },
        },
        actionBlock: {
            cursor: "pointer",
        },
    });

export default deviceDefinitionLibrariesListStyle;
