import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import moment from "moment";
import { Typography } from "@material-ui/core";

import SternumConfiguration from "../../../../lib/infra/SternumConfiguration";
import HashSet from "../../../../lib/infra/HashSet";
import DeviceInfo from "../../../../lib/state/DeviceInfo";
import { GlobalState } from "../../../../lib/state/GlobalState";
import TimeSelectOption from "../../../../lib/state/TimeSelectOption";
import { useCommonStyle } from "../../../CommonStyle";
import { useDebounceState } from "../../../../hooks";
import TableRowData from "../../../../lib/state/TableRowData";
import TableColumnHeaderInfo from "../../../../lib/state/TableColumnHeaderInfo";
import SternumTableToolbar from "../../../SUI/SternumTable/SternumTableToolbar/SternumTableToolbar";
import SternumTable from "../../../SUI/SternumTable/SternumTable";

import { ArgumentTag } from "./ArgumentTag";
import { useCrashesTabData } from "./CrashesTab.hooks";
import { CrashesTableData, CrashesTableItem } from "./CrashesTab.types";
import { useCrashesTableStyles } from "./CrashesTab.styles";

export interface CrashesTabProps {
    device: DeviceInfo;
    startDate: Date;
    endDate: Date;
    timeSelectOption: TimeSelectOption;
    refreshEntitiesFilter?: boolean;
}

const mapStateToProps = (state: GlobalState, ownProps: CrashesTabProps) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

type CrashesTabPropsWithHOC = CrashesTabProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

function CrashesTabComponent({
    device,
    startDate: startDateFromProps,
    endDate: endDateFromProps,
    timeSelectOption,
    refreshEntitiesFilter,
}: CrashesTabPropsWithHOC) {
    const classes = useCrashesTableStyles();
    const classesCommon = useCommonStyle();

    const [searchText, setSearchText] = useDebounceState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [expandedRowsHashSet, setExpandedRowHashSet] = useState<HashSet>(new HashSet());

    const { startDate, endDate } = useMemo(() => {
        return { startDate: startDateFromProps, endDate: endDateFromProps };
    }, [refreshEntitiesFilter]);

    const { isLoading, isError, crashesData, fetchDeviceCrashesData } = useCrashesTabData({
        device,
        searchText,
        startDate,
        endDate,
    });

    const rows: TableRowData[] = useMemo(
        () =>
            crashesData.crashesData.map(
                (crashesDataItem): TableRowData => ({
                    getIdentifier(): string {
                        return crashesDataItem.crashesItemId;
                    },
                    getColumnValue(columnHeaderId): CrashesTableItem | CrashesTableData {
                        if (columnHeaderId === "all") {
                            return crashesDataItem;
                        }

                        return crashesDataItem[columnHeaderId];
                    },
                    isDifferentFrom(other: TableRowData): boolean {
                        crashesDataItem.receivedTime;
                        crashesDataItem.mainArguments;
                        return other.getColumnValue("crashesItemId") !== crashesDataItem.crashesItemId;
                    },
                })
            ) || [],
        [crashesData]
    );

    const columnWidthsArray = [10, 0];
    const viewedColumns = ["received", "arguments"];
    const columnHeaders: TableColumnHeaderInfo[] = [
        new TableColumnHeaderInfo("received", "Received", false, true, false, false),
        new TableColumnHeaderInfo("arguments", "Arguments", false, true, false, false),
    ];

    const renderRowDisplayValues = (row: TableRowData) => {
        const crashesDataItem = row.getColumnValue("all") as CrashesTableItem;

        return {
            received: (
                <div className={classes.rowCell}>{moment(crashesDataItem.receivedTime).format("DD/MM/YY HH:mm")}</div>
            ),
            arguments: (
                <div className={classNames(classes.rowCell, classes.rowCellArguments)}>
                    {crashesDataItem.mainArguments.map((mainArgument) => (
                        <ArgumentTag name={mainArgument.argumentName} value={mainArgument.argumentValue} />
                    ))}
                </div>
            ),
            expandedContent: (
                <div className={classes.expandedContent} onScroll={(e) => e.stopPropagation()}>
                    {crashesDataItem.otherArguments.map((otherArgument) => (
                        <ArgumentTag name={otherArgument.argumentName} value={otherArgument.argumentValue} />
                    ))}
                </div>
            ),
        };
    };

    return (
        <div className={classes.crashesTableContainer}>
            <SternumTableToolbar
                toolbarState={{
                    showPremium: false,
                    displaySearch: true,
                    displayRefresh: false,
                    displayFilters: false,
                    displayColumnOptions: false,
                    searchDisplayString: "Search crashes",
                }}
                hidePagination={true}
                disabled={false}
                listFilter={null}
                viewedColumnsSet={HashSet.fromValues([])}
                totalItemCount={SternumConfiguration.getPageSize()}
                isFilterActive={false}
                columnHeaders={[]}
                pageNumber={pageNumber}
                pageSize={SternumConfiguration.getPageSize()}
                onViewedColumnsChanged={() => null}
                onSearchTextChanged={(searchText) => setSearchText(searchText)}
                onPageChanged={(pageNumber) => setPageNumber(pageNumber)}
                onRefreshClicked={() => null}
            />
            <SternumTable
                classNameInner={{
                    tableWrapper: classes.tableWrapper,
                }}
                emptyComponent={
                    <div className={classNames(classesCommon.flexCenter, classesCommon.padding)}>
                        <Typography variant="body2">Crashes will appear here.</Typography>
                    </div>
                }
                viewedColumnsSet={HashSet.fromValues(viewedColumns)}
                pageSize={SternumConfiguration.getPageSize()}
                amountOfLoadingPlaceholders={5}
                totalItemCount={crashesData.totalItemsCount}
                loadingItems={!rows.length && isLoading}
                errorLoadingItems={isError}
                columnHeaders={columnHeaders}
                columnWidthsArray={columnWidthsArray}
                rows={rows}
                getRowValues={(row) => renderRowDisplayValues(row)}
                nonClickableRows
                displayLoadingIcon
                hideUpperBorder
                expandableRows
                expandedRowsSet={expandedRowsHashSet}
                onRowExpand={(row, isExpanded) => {
                    if (isExpanded) {
                        setExpandedRowHashSet(HashSet.copyAndAdd(expandedRowsHashSet, row.getIdentifier()));
                    } else {
                        setExpandedRowHashSet(HashSet.copyAndRemove(expandedRowsHashSet, row.getIdentifier()));
                    }
                }}
                onScrollChanged={(pageNumber) => {
                    if (pageNumber === 1) {
                        fetchDeviceCrashesData({ isFirstPage: true }).then();
                    } else {
                        fetchDeviceCrashesData({ isFirstPage: false }).then();
                    }
                }}
                hideToolbar
                wrapColumns
                isInfinityScrollOn
            />
        </div>
    );
}

export const CrashesTab: React.FC<CrashesTabProps> = connect(mapStateToProps, mapDispatchToProps)(CrashesTabComponent);
