import { useRef, useState } from "react";

export function useDebounceState<T>(initialValue: T, defaultDebounceMs = 350): [T, (newValue: T) => unknown] {
    const timeoutIdRef = useRef(null);
    const [v, setV] = useState<T>(initialValue);

    const setValue = (newValue: T, debounceMs?: number) => {
        clearTimeout(timeoutIdRef.current);

        timeoutIdRef.current = setTimeout(() => {
            setV(newValue);
        }, defaultDebounceMs ?? debounceMs);
    };

    return [v, setValue];
}
