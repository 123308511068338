import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

const deviceInfoDisplayStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: {
            display: "flex",
            alignItems: "flex-start",
        },
        deviceDetailContainer: {
            display: "flex",
            alignItems: "center",
            marginBottom: 10,
        },
        deviceDetailName: {
            fontWeight: 500,
            fontSize: 14,
            marginRight: theme.spacing(1),
        },
        deviceDetailValue: {
            fontSize: 14,
        },
        infoColumn: {
            minHeight: 150,
            minWidth: 250,
            padding: theme.spacing(3.5),
            marginRight: theme.spacing(6),
        },
    });

export default deviceInfoDisplayStyle;
