import { WithStyles, withStyles } from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import classNames from "classnames";
import * as React from "react";
import HashSet from "../../../../lib/infra/HashSet";
import Utils from "../../../../lib/infra/Utils";
import TableColumnHeaderInfo from "../../../../lib/state/TableColumnHeaderInfo";
import TableRowData from "../../../../lib/state/TableRowData";
import SternumTableRow from "../SternumTableRow/SternumTableRow";
import sternumTableBodyStyle from "./SternumTableBodyStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    infinityLoaderStatus: boolean;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof sternumTableBodyStyle> {
    // Changeable props (will cause a re-render).
    loading: boolean;
    rows: TableRowData[];
    viewedColumnsSet: HashSet;
    nonClickableRows?: boolean;

    // Indicates if need to add to existing list a loader row
    addInfiniteLoader?: boolean;

    // Non-changeable props (will not cause a re-render).
    columnHeaders: TableColumnHeaderInfo[];
    amountOfLoadingPlaceholders: number;
    getRowValues: (row: TableRowData) => { [key: string]: any };
    getRowClasses?: (row: TableRowData) => any;
    onRowClicked?: (row: TableRowData, event) => void;
    onRowMouseDown?: (row: TableRowData, event) => void;
    onRowMouseUp?: (row: TableRowData, event) => void;
    onCellRightClick?: (cellName: string, row: TableRowData, event) => void;
    onRowExpand?: (row: TableRowData, isExpanded: boolean) => void;

    // If rows should be expandable (arrows at the beginning of the row)
    expandableRows?: boolean;
    // Set of currently expanded rows
    expandedRowsSet?: HashSet;
    wrapColumns?: boolean;
    narrow?: boolean;
}

/**
 * Displays a sternum-styled table header.
 */
class SternumTableBody extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            infinityLoaderStatus: false,
        };
    }

    /**
     * Returns whether anything changed in props need to execute a render.
     */
    shouldComponentUpdate(nextProps: Readonly<AppProps>, nextState: Readonly<AppState>, nextContext: any): boolean {
        // Note: the order of comparison is performance-wise. From the most costly comparison to the most cheap one.

        // Are we starting or finished loading?
        if (nextProps.loading !== this.props.loading) {
            return true;
        }

        // Are we starting or finished infinite scroll?
        if (nextProps.addInfiniteLoader !== this.props.addInfiniteLoader) {
            return true;
        }

        // Is the amount of rows changed?
        if (nextProps.rows.length !== this.props.rows.length) {
            return true;
        }

        // Any viewed columns are changed?
        if (nextProps.viewedColumnsSet.isDifferentFrom(this.props.viewedColumnsSet)) {
            return true;
        }

        // Any rows changed their expanded state?
        if (this.props.expandedRowsSet && nextProps.expandedRowsSet.isDifferentFrom(this.props.expandedRowsSet)) {
            return true;
        }

        // Are any of the rows different?
        for (let i = 0; i < nextProps.rows.length; i++) {
            if (nextProps.rows[i].isDifferentFrom(this.props.rows[i])) {
                return true;
            }
        }

        return false;
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        let displayedRows = [];

        const loadingRow = (
            <TableRow
                key={-1}
                hover={false}
                selected={false}
                classes={{ hover: classes.tableRowHover, root: classes.rootRow }}
            >
                {this.props.columnHeaders
                    .filter((columnHeader) => this.props.viewedColumnsSet.exists(columnHeader.id))
                    .map((columnHeader) => {
                        return (
                            <TableCell key={columnHeader.id}>
                                <div
                                    role="presentation"
                                    aria-label="sternum table loading placeholder"
                                    className={classNames(classes.loadingPlaceholder, "mod-loading-column")}
                                />
                            </TableCell>
                        );
                    })}
            </TableRow>
        );

        if (!this.props.loading) {
            displayedRows = this.props.rows.map((row) => {
                if (this.props.getRowValues && !this.props.getRowValues(row)) {
                    return null;
                }

                const extraKey = this.props.getRowValues ? this.props.getRowValues(row).key || "" : "";
                return (
                    <SternumTableRow
                        key={row.getIdentifier() + extraKey}
                        row={row}
                        getRowValues={this.props.getRowValues}
                        nonClickableRows={this.props.nonClickableRows}
                        expandable={this.props.expandableRows}
                        getRowClasses={this.props.getRowClasses}
                        onRowClicked={this.props.onRowClicked}
                        onCellRightClick={this.props.onCellRightClick}
                        onRowMouseDown={this.props.onRowMouseDown}
                        onRowMouseUp={this.props.onRowMouseUp}
                        onRowExpand={this.props.onRowExpand}
                        columnHeaders={this.props.columnHeaders}
                        viewedColumnsSet={this.props.viewedColumnsSet}
                        expandedRowsSet={this.props.expandedRowsSet}
                        wrapColumns={this.props.wrapColumns}
                        narrow={this.props.narrow}
                    />
                );
            });

            displayedRows = displayedRows.filter((row) => row);

            // Add infinite loader icon at the end of the array
            if (!!this.props.addInfiniteLoader && this.props.addInfiniteLoader) {
                displayedRows.push(loadingRow);
            }
        } else {
            displayedRows = Utils.range(this.props.amountOfLoadingPlaceholders).map((index) => {
                return (
                    <TableRow
                        key={index}
                        hover={false}
                        selected={false}
                        classes={{ hover: classes.tableRowHover, root: classes.rootRow }}
                    >
                        {this.props.columnHeaders
                            .filter((columnHeader) => this.props.viewedColumnsSet.exists(columnHeader.id))
                            .map((columnHeader) => {
                                return (
                                    <TableCell key={columnHeader.id}>
                                        <div
                                            className={classNames(
                                                classes.loadingPlaceholder,
                                                columnHeader.isPrimaryColumn ? "mod-big-column" : "mod-small-column"
                                            )}
                                        />
                                    </TableCell>
                                );
                            })}
                    </TableRow>
                );
            });
        }
        return (
            <TableBody aria-details={`table body${this.props.loading ? " is loading" : ""}`}>{displayedRows}</TableBody>
        );
    }
}

export default withStyles(sternumTableBodyStyle)(SternumTableBody);
