import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = {
    loadingContainer: {
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    loadingPaper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        padding: "36px",
        position: "absolute",
        width: "515px",
        height: "444px",
        background: "#FFFFFF",
        boxShadow: "-10px 11px 28px rgba(83, 93, 120, 0.15)",
        borderRadius: "10px",
    },
    loadedPaper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        padding: "36px",
        position: "absolute",
        width: "515px",
        height: "474px",
        background: "#FFFFFF",
        boxShadow: "-10px 11px 28px rgba(83, 93, 120, 0.15)",
        borderRadius: "10px",
    },
    loadingTitle: {
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "30px",
        textAlign: "center",
        color: "#1D1D1F",
        alignSelf: "flex-start",
        margin: 0,
    },
    loadingExplanation: {
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "30px",
        textAlign: "center",
        color: "#555B61",
        display: "flex",
        flex: 1,
        alignContent: "flex-end",
        justifyContent: "space-between",
        alignItems: "flex-end",
        "& a": {
            textDecoration: "none",
            color: "#1B6FDE",
        },
        "& p": {
            margin: 0,
        },
    },
    loadedExplanation: {
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "30px",
        textAlign: "center",
        color: "#555B61",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "flex-end",
        "& a": {
            textDecoration: "none",
            color: "#1B6FDE",
        },
        "& p": {
            margin: 0,
        },
    },

    loadedExplanationTitle: {
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "28px",
        color: "#212B36",
        paddingBottom: "10px",
        paddingTop: "30px",
    },

    loadedExplanationButton: {
        "&&": {
            padding: 12,
            marginTop: "20px",
        },
    },
};

export const useLoadingDevicesStyle = makeStyles(styles as any);
