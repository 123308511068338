import { makeStyles, Theme } from "@material-ui/core";
import commonStyle from "../../CommonStyle";

const useSternumSelectStyle = makeStyles((theme: Theme) => {
    return {
        ...commonStyle(theme),
        bottomBorder: {
            borderBottom: "1px solid black",
        },
        paper: {
            width: theme.spacing(55),
            maxHeight: theme.spacing(60),
            marginTop: 10,
            borderRadius: 20,
            boxShadow: "0px 6px 250px 0px rgba(0, 0, 0, 0.12)",

            "& .MuiListItem-root": {
                "&.Mui-selected": {
                    background: "#FFF1F5",
                },

                "&:hover": {
                    background: "#FFF1F5",
                },
            },
        },
        label: {
            fontSize: 20,
            fontWeight: 600,
        },
    };
});

export default useSternumSelectStyle;
