import { makeStyles } from "@material-ui/core";

export const useReceivedTracesStyles = makeStyles((theme) => {
    const blackHard = "#2b2e30";
    const orangeMedium = "#F79A1D";
    const blue = "#4F98F7";

    return {
        tableHead: {
            "& $tableHeadCell": {
                fontSize: 12,
                lineHeight: "16px",
                fontWeight: 600,
                padding: "14px 28px 14px 18px",
                borderBottom: `1px solid #f7f7f7`,
            },
        },
        tableHeadCell: {},
        tableRow: {
            "& $tableRowCell": {
                fontSize: 16,
                borderBottom: `1px solid #f7f7f7`,
                padding: "12px 28px 12px 18px",
            },
        },
        tableRowCell: {},
        tableRowNotReceived: {
            "& $tableRowCell": {
                background: "#FFF9F1",
            },

            "& $tableRowCellReceived": {
                color: orangeMedium,
            },

            "& $tableRowCellNumber": {
                color: orangeMedium,
            },

            "& $dot": {
                background: orangeMedium,
            },
        },
        tableRowUnexpected: {
            "& $tableRowCell": {
                background: "#F5F7FF",
            },

            "& $tableRowCellNumber": {
                color: blue,
            },

            "& $dot": {
                background: blue,
            },
        },

        tableEventTypeCell: {
            color: blackHard,
        },

        tableRowCellNumber: {
            textAlign: "center",
        },
        tableRowCellReceived: {},
        tableRowCellEventType: {},
        dot: {
            display: "inline-flex",
            margin: "auto",
            borderRadius: 8,
            width: 8,
            height: 8,
        },
    };
});
