import React from "react";

import { SternumIconProps } from "./SternumIcon.types";

export const XIcon = React.forwardRef<SVGSVGElement, SternumIconProps>(function XIcon(
    { color, height = 13, width = 12, ...props },
    ref
) {
    return (
        <svg
            ref={ref}
            width={width}
            height={height}
            viewBox="0 0 12 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.42218 2.42218C2.59155 2.25281 2.86615 2.25281 3.03552 2.42218L6 5.38666L8.96448 2.42218C9.13385 2.25281 9.40845 2.25281 9.57782 2.42218C9.74719 2.59155 9.74719 2.86615 9.57782 3.03552L6.61334 6L9.57782 8.96448C9.74719 9.13385 9.74719 9.40845 9.57782 9.57782C9.40845 9.74719 9.13385 9.74719 8.96448 9.57782L6 6.61334L3.03552 9.57782C2.86615 9.74719 2.59155 9.74719 2.42218 9.57782C2.25281 9.40845 2.25281 9.13385 2.42218 8.96448L5.38666 6L2.42218 3.03552C2.25281 2.86615 2.25281 2.59155 2.42218 2.42218Z"
                fill={color || "currentColor"}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.21348 3.24423C1.92884 2.95959 1.92884 2.49811 2.21348 2.21348C2.49811 1.92884 2.95959 1.92884 3.24423 2.21348L6 4.96925L8.75577 2.21348C9.04041 1.92884 9.50189 1.92884 9.78652 2.21348C10.0712 2.49811 10.0712 2.95959 9.78652 3.24423L7.03075 6L9.78652 8.75577C10.0712 9.04041 10.0712 9.50189 9.78652 9.78652C9.50189 10.0712 9.04041 10.0712 8.75577 9.78652L6 7.03075L3.24423 9.78652C2.95959 10.0712 2.49811 10.0712 2.21348 9.78652C1.92884 9.50189 1.92884 9.04041 2.21348 8.75577L4.96925 6L2.21348 3.24423ZM2.63089 2.63089C2.57678 2.68499 2.57678 2.77271 2.63089 2.82682L5.59537 5.79129C5.71063 5.90656 5.71063 6.09344 5.59537 6.20871L2.63089 9.17318C2.57678 9.22729 2.57678 9.31501 2.63089 9.36911C2.68499 9.42322 2.77271 9.42322 2.82682 9.36911L5.79129 6.40463C5.90656 6.28937 6.09344 6.28937 6.20871 6.40463L9.17318 9.36911C9.22729 9.42322 9.31501 9.42322 9.36911 9.36911C9.42322 9.31501 9.42322 9.22729 9.36911 9.17318L6.40463 6.20871C6.28937 6.09344 6.28937 5.90656 6.40463 5.79129L9.36911 2.82682C9.42322 2.77271 9.42322 2.68499 9.36911 2.63089C9.31501 2.57678 9.22729 2.57678 9.17318 2.63089L6.20871 5.59537C6.09344 5.71063 5.90656 5.71063 5.79129 5.59537L2.82682 2.63089C2.77271 2.57678 2.68499 2.57678 2.63089 2.63089Z"
                fill={color || "currentColor"}
            />
        </svg>
    );
});
