import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { History } from "history";

import { GlobalState } from "../../../lib/state/GlobalState";
import { useFirstMainViewStyle } from "./FirstMainView.style";
import { useHomePageStyle } from "../Home.style";
import { SternumDeviceSecurityPlatformIcon } from "../../SUI/SternumIcon";
import { Button } from "../../SUI/Button";
import { Link } from "@material-ui/core";

export interface FirstMainViewProps {}

const mapStateToProps = (state: GlobalState, ownProps: FirstMainViewProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

type FirstMainViewPropsWithHOC = FirstMainViewProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
        history: History;
    };

function FirstMainViewComponent({ history }: FirstMainViewPropsWithHOC) {
    const classes = useFirstMainViewStyle();
    const classesHome = useHomePageStyle();

    return (
        <div className={classes.mainContainerWithBackground}>
            <div className={classes.darkViewContainer}>
                <div className={classesHome.mainContainer}>
                    <div className={classes.mainTitleContainer}>
                        <div className={classes.titleWithSubtitleContainer}>
                            <h3 className={classes.title}>Welcome to Device Security and Insights Platform</h3>
                            <div className={classes.subtitle}>
                                Our patented technology provides real-time security and observability for all RTOS and
                                Linux-operated IIoT, IoMT and IoT devices.
                            </div>
                            <div className={classes.actionButtonsContainer}>
                                <div className={classes.action}>
                                    <Button onClick={() => history.push("/signup")}>Get Started</Button>
                                    <div className={classes.underActionButton}>Deploy for free on OpenWrt</div>
                                </div>
                                <div className={classes.action}>
                                    <Link href="https://www.sternumiot.com/contact" target="_blank" underline="none">
                                        <Button variant="whiteLightTextWithBorder">Contact Us</Button>
                                    </Link>
                                    <div className={classes.underActionButton}>For additional deployment options</div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.drawingContainer}>
                            <SternumDeviceSecurityPlatformIcon width="auto" height="100%" />
                        </div>
                    </div>
                    <div className={classes.smallActionButtonsContainer}>
                        <div className={classes.action}>
                            <Button onClick={() => history.push("/signup")}>Get Started</Button>
                            <div className={classes.underActionButton}>Deploy for free on OpenWrt</div>
                        </div>
                        <div className={classes.action}>
                            <Link href="https://www.sternumiot.com/contact" target="_blank" underline="none">
                                <Button variant="whiteLightTextWithBorder">Contact Us</Button>
                            </Link>
                            <div className={classes.underActionButton}>For additional deployment options</div>
                        </div>
                    </div>
                    <div className={classes.bottomPlaceholder} />
                </div>
            </div>
        </div>
    );
}

export const FirstMainView: React.FC<FirstMainViewProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(FirstMainViewComponent));
