import EntityBase from "./EntityBase";
import EntityType from "./EntityType";

const SYSTEM_ARGUMENT = "SYSTEM";

/**
 * Represents an argument definition in sternum.
 */
class ArgumentDefinitionInfo extends EntityBase {
    /**
     * Constructor.
     */
    constructor(
        public argumentDefinitionId: string,
        public created: number,
        public updated: number,
        public displayName: string,
        public argumentEventType: string,
        public argumentEventName: string,
        public argumentType: string,
        public displayInUI: boolean,
        public canBeUsedForAlerts: boolean
    ) {
        super(argumentDefinitionId, created, updated, EntityType.ArgumentDefinition);
    }

    /**
     * Converts json to argument info object.
     */
    public static fromJsonObject(jsonObject: Object) {
        return new ArgumentDefinitionInfo(
            jsonObject["entity_id"],
            jsonObject["created"],
            jsonObject["updated"],
            jsonObject["display_name"],
            jsonObject["argument_role_type"],
            jsonObject["argument_role_name"],
            jsonObject["argument_type"],
            jsonObject["display_in_ui"],
            jsonObject["can_be_used_for_alerts"]
        );
    }

    /**
     * Converts Argument definition object into json object.
     */
    public toJsonObject() {
        return {
            display_name: this.displayName,
            argument_event_name: this.argumentEventName,
        };
    }

    /**
     * Help function to determine if given trace is system trace
     */
    public isSystemArgument(): boolean {
        return this.argumentType === SYSTEM_ARGUMENT;
    }
}

export default ArgumentDefinitionInfo;
