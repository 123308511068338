export const userSpaceProcessEvents: string[] = [
    "Macro data about incoming/outgoing Internet connection - no packet inspection!",
    "Process kill events",
    "Disk mount events",
    "Execution of processes and executed string formats",
    "Attack prevention reporting",
];

export const sternumDoesNot: string[] = [
    "Collect data about specific user activity (e.g., website visits)",
    "Perform any type of packet inspection",
    "Attempt any logical understanding of your code",
    "Collect any functional or logical or private data",
    "Collect personal information of any kind",
];

export const roundedSectionInformation: { title: string; description: string }[] = [
    {
        title: "Data Encryption",
        description:
            "Data encryption (a.k.a. “encryption at rest”) is enabled by default for all storage used by Sternum’s platform. Keys are managed by AWS (Amazon Web Services) and are stored in the AWS key management service.",
    },
    {
        title: "Enterprise Readiness",
        description:
            "Sternum is already used by leading enterprises to secure medical devices, PLCs, gateways and other IoT, IIoT and IoMT fleets. The company complies with most strict and up-to-date data and cybersecurity standards, and our platform is subject to regular pen tests.",
    },
];
