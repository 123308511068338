import { Action } from "redux";
import ActionType from "../ActionType";
import FetchError from "../../state/FetchError";

/**
 * Occurs once there has been an error fetching a cve from API.
 */
export interface ErrorFetchingCveAction extends Action<ActionType> {
    type: ActionType.ErrorFetchingCve;
    cveId: string;
    error: FetchError;
}

/**
 * Occurs once there has been an error fetching a cve from API.
 */
export function errorFetchingCveAction(cveId: string, error: FetchError): ErrorFetchingCveAction {
    return {
        type: ActionType.ErrorFetchingCve,
        cveId: cveId,
        error: error,
    };
}
