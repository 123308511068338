import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const pinkColor = "#E7004C";
const whiteColor = "#FFF";
const whiteLightColor = "#F0F3F5";
const whiteSuperLightColor = "#F7F9FA";
const graySuperLightColor = "#E6EAED";

export const useButtonStyle = makeStyles((theme: Theme) => ({
    button: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 8,
        borderRadius: 12,
        fontSize: 18,
        fontWeight: 500,
        width: "auto",
        margin: 0,
        outline: "none",
        border: "none",
        cursor: "pointer",

        "&:focus": {
            outline: 0,
        },

        "&:disabled": {
            cursor: "inherit",
        },
    },

    iconContainer: {
        display: "flex",
    },

    primaryPink: {
        color: whiteColor,
        background: pinkColor,

        "&:hover": {
            background: "linear-gradient(90deg, #FC3262 -12.5%, #FF6E30 108.5%)",
        },

        "&:focus-visible": {
            outline: `1px solid ${pinkColor}`,
            boxShadow: "0px 4px 30px rgba(253, 58, 92, 0.4)",
        },

        "&:disabled": {
            opacity: 0.3,

            "&:hover": {
                background: pinkColor,
            },
        },
    },

    whiteLightTextWithBorder: {
        color: whiteSuperLightColor,
        background: "transparent",
        boxShadow: `inset 0 0 0 1px ${whiteSuperLightColor}`,

        "&:hover": {
            color: pinkColor,
            background: whiteLightColor,
            boxShadow: `inset 0 0 0 1px ${whiteLightColor}`,
        },

        "&:focus-visible": {},

        "&:disabled": {
            opacity: 0.3,
        },
    },

    pinkTextWithoutBorder: {
        color: pinkColor,
        background: "transparent",

        "&:hover": {
            background: graySuperLightColor,
        },

        "&:focus-visible": {},
    },

    whiteTextWithoutBorder: {
        color: whiteColor,
        background: "transparent",

        "&:hover": {
            background: "rgba(255, 255, 255, 0.15)",
        },

        "&:focus-visible": {},
    },

    text: {
        color: "currentColor",
        background: "transparent",

        "&&": {
            padding: 0,
        },

        "&:hover": {},

        "&:focus-visible": {
            outline: `1px solid ${pinkColor}`,
        },

        "& $iconContainer": {
            margin: "0",
        },
    },

    size32: {
        padding: "11px 32px",

        "& $iconContainer": {
            margin: "-11px 0",
        },
    },

    size24: {
        padding: "11px 24px",

        "& $iconContainer": {
            margin: "-11px 0",
        },
    },

    size16: {
        padding: "11px 16px",

        "& $iconContainer": {
            margin: "-11px 0",
        },
    },

    size14: {
        padding: "11px 14px",

        "& $iconContainer": {
            margin: "-11px 0",
        },
    },
}));
