import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { History } from "history";

import { GlobalState } from "../../../lib/state/GlobalState";
import { CheckFilledIcon } from "../../SUI/SternumIcon";
import { useHomePageStyle } from "../Home.style";
import { Button } from "../../SUI/Button";
import { useSecondMainViewStyle } from "./SecondMainView.style";
import { capabilities } from "./SecondMainView.modal";

const promoVideoUrlWebm = "https://sternum-ads-dev.s3.us-east-2.amazonaws.com/Sternum+latest.webm";
const promoVideoUrlMp4 = "https://sternum-ads-dev.s3.us-east-2.amazonaws.com/openWrt_dsi.mp4";
const promoVideoThumbnail = "https://sternum-ads-dev.s3.us-east-2.amazonaws.com/thumbnail.png";

export interface SecondMainViewProps {}

const mapStateToProps = (state: GlobalState, ownProps: SecondMainViewProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

type SecondMainViewPropsWithHOC = SecondMainViewProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
        history: History;
    };

function SecondMainViewComponent({ history }: SecondMainViewPropsWithHOC) {
    const classes = useSecondMainViewStyle();
    const classesHome = useHomePageStyle();

    const videoRef = useRef<HTMLVideoElement>(null);
    const [isVideoPlaying, setVideoPlaying] = useState(false);

    const handlePlayButtonClick = () => {
        videoRef.current.play();
        setVideoPlaying(true);
    };

    return (
        <div className={classes.secondViewContainer}>
            <div className={classNames(classesHome.mainContainer, classes.mainContent)}>
                <div className={classes.screenShotPlaceholder}>
                    <div className={classes.screenShotContainer}>
                        <video
                            ref={videoRef}
                            onEnded={() => setVideoPlaying(false)}
                            className={classes.promoVideo}
                            controls
                            controlsList="nodownload"
                            poster={promoVideoThumbnail}
                        >
                            <source src={promoVideoUrlWebm} type="video/webm" />
                            <source src={promoVideoUrlMp4} type="video/mp4" />
                        </video>

                        {!isVideoPlaying && (
                            <div className={classes.videoPlayButton} onClick={handlePlayButtonClick}>
                                <svg width="512" height="512" viewBox="0 0 512 512">
                                    <path d="M152.443 136.417l207.114 119.573-207.114 119.593z" fill="#fff" />
                                </svg>
                            </div>
                        )}
                    </div>
                </div>
                <div className={classes.sternumCapabilitiesContainer}>
                    <div className={classes.title}>With Sternum you can:</div>
                    <div className={classes.description}>
                        {capabilities.map((capability, index) => (
                            <div className={classes.descriptionItem} key={index}>
                                <CheckFilledIcon className={classes.descriptionIcon} width={16} height={16} />
                                <div className={classes.descriptionText}>{capability}</div>
                            </div>
                        ))}
                    </div>
                    <Button onClick={() => history.push("/signup")}>Get Started</Button>
                </div>
            </div>
        </div>
    );
}

export const SecondMainView: React.FC<SecondMainViewProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SecondMainViewComponent));
