import { Theme } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../../components/CommonStyle";

const infoTooltipStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: {
            paddingLeft: theme.spacing(1),
        },

        tooltipBackground: {
            display: "block",
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            boxShadow: "1px 2px 6px 0 rgba(0,0,0,0.07)",
            opacity: 1,
        },
        infoCircleIconSize: {
            fontSize: "16px",
        },
    });

export default infoTooltipStyle;
