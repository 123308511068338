import { applyMiddleware, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { getDefaultGlobalState } from "../state/GlobalState";
import rootReducer from "./RootReducer";
import rootSaga from "./RootSaga";

let sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, getDefaultGlobalState(), applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga);

export default store;
