import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

import { commonHome } from "../Home.style";

import stairsSvgPath from "../../../images/stars.svg";

export const useFirstMainViewStyle = makeStyles((theme: Theme) => ({
    mainContainerWithBackground: {
        width: "100%",
        background: commonHome.secondViewBackground,
    },

    darkViewContainer: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        backgroundColor: commonHome.darkBackground,
        borderBottomLeftRadius: 38,
        borderBottomRightRadius: 38,
        backgroundImage: `url("${stairsSvgPath}")`,
    },

    mainTitleContainer: {
        display: "grid",
        alignItems: "center",
        gridTemplateAreas: `"title drawing" "title drawing"`,
        gridAutoColumns: "50% 50%",
        gridTemplateRows: "auto auto",
        color: "white",
        marginTop: 222,

        "@media (max-width: 850px)": {
            gridTemplateAreas: `"title title" "drawing drawing"`,
            gap: "24px 0",
        },

        [`@media (max-width: ${commonHome.mediaQueryMaxWidth})`]: {
            marginTop: 122,
        },
    },

    titleWithSubtitleContainer: {
        gridArea: "title",
        marginRight: 20,
    },

    drawingContainer: {
        gridArea: "drawing",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        minHeight: "320px",
    },

    title: {
        fontSize: 40,
        fontWeight: 700,
        lineHeight: "60px",
        margin: "0 0 24px 0",

        [`@media (max-width: ${commonHome.mediaQueryMaxWidth})`]: {
            margin: "0 0 32px 0",
            textAlign: "center",
        },
    },

    subtitle: {
        fontSize: 16,
        lineHeight: "25px",
        marginBottom: 60,
        color: "#E3E0F5",

        [`@media (max-width: ${commonHome.mediaQueryMaxWidth})`]: {
            fontSize: 14,
            lineHeight: "22px",
            textAlign: "center",
        },
    },

    actionButtonsContainer: {
        display: "flex",
        gap: 8,

        [`@media (max-width: ${commonHome.mediaQueryMaxWidth})`]: {
            display: "none",
        },
    },

    smallActionButtonsContainer: {
        display: "none",
        flexFlow: "column",
        alignItems: "center",
        gridGap: 40,
        marginTop: 70,

        "& $action": {
            alignItems: "center",
            width: "100%",
        },

        "& $underActionButton": {
            textAlign: "center",
        },

        [`@media (max-width: ${commonHome.mediaQueryMaxWidth})`]: {
            display: "grid",
        },
    },

    action: {
        display: "flex",
        flexFlow: "column",
        alignItems: "flex-start",
        width: "50%",
    },

    underActionButton: {
        marginTop: "8px",
        color: "#E3E0F5",
        fontSize: 13,
        lineHeight: "28px",
    },

    bottomPlaceholder: {
        marginBottom: "min(49vw, 550px)",
    },
}));
