interface VisualisationDataSourceGroupBy {
    field: string;
    limit: number;
    order_by: VisualisationGroupByOrder;
    enabled: boolean;
}

export enum VisualisationGroupByOrder {
    TOP = "TOP",
    BOTTOM = "BOTTOM",
}

export enum VisualisationDataSourceGroupByCustomFields {
    DEVICE = "device_id",
    TRACE_CATEGORY = "trace_category",
    COUNTRY = "geo_location.country",
    STATE = "geo_location.state",
}

export function getDefaultVisualisationConfigurationGroupBy(): VisualisationDataSourceGroupBy {
    return {
        field: VisualisationDataSourceGroupByCustomFields.DEVICE,
        limit: 10,
        order_by: VisualisationGroupByOrder.TOP,
        enabled: true,
    };
}

export default VisualisationDataSourceGroupBy;
