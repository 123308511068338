import TableRowData from "./TableRowData";
import EntityBase from "./EntityBase";
import EntityType from "./EntityType";
import ActivityType from "./ActivityType";
import UserInfo from "./UserInfo";
import Entity from "./Entity";
import EntityManager from "../infra/EntityManager";

/**
 * Represents an activity in sternum.
 */
class ActivityInfo extends EntityBase implements TableRowData {
    /**
     * Constructor.
     */
    constructor(
        public activityId: string,
        public created: number,
        public updated: number,
        public activityType: ActivityType,
        public actorUserId: number,
        public reference1Id: number,
        public reference1Type: string,
        public reference2Id: number,
        public reference2Type: string,
        public reference3Id: number,
        public reference3Type: string,
        public metadata: Object,
        public actorUser: UserInfo,
        public reference1: EntityBase,
        reference2: EntityBase,
        reference3: EntityBase
    ) {
        super(activityId, created, updated, EntityType.Activity);
    }

    /**
     * Converts json to issue info object.
     */
    public static fromJsonObject(jsonObject: Object) {
        let activityType = (<any>ActivityType)[jsonObject["activity_type"]];

        return new ActivityInfo(
            jsonObject["entity_id"],
            jsonObject["created"],
            jsonObject["updated"],
            activityType,
            jsonObject["actor_user_id"],
            jsonObject["reference1_id"],
            jsonObject["reference1_type"],
            jsonObject["reference2_id"],
            jsonObject["reference2_type"],
            jsonObject["reference3_id"],
            jsonObject["reference3_type"],
            jsonObject["metadata"],
            jsonObject["actor_user"] ? UserInfo.fromJsonObject(jsonObject["actor_user"]) : null,
            jsonObject["reference1"] ? EntityManager.convertSternumEntity(jsonObject["reference1"]) : null,
            jsonObject["reference2"] ? EntityManager.convertSternumEntity(jsonObject["reference2"]) : null,
            jsonObject["reference3"] ? EntityManager.convertSternumEntity(jsonObject["reference3"]) : null
        );
    }

    /**
     * Returns whether given row is any different in details than current issue info.
     * Shallow comparison, in other words.
     * @param other The row to compare with.
     */
    public isDifferentFrom(other: TableRowData): boolean {
        // Row must of of type issue info.
        if (!(other instanceof ActivityInfo)) {
            return true;
        }

        let otherActivityInfo = other as ActivityInfo;

        // Comparing base issue properties.
        if (
            otherActivityInfo.entityId !== this.entityId ||
            otherActivityInfo.created !== this.created ||
            otherActivityInfo.updated !== this.updated
        ) {
            return true;
        }

        return false;
    }

    /**
     * Gets the value for the given column id.
     */
    public getColumnValue(columnHeaderId: string): any {
        return this[columnHeaderId];
    }
}

export default ActivityInfo;
