import MedtronicLogo from "../../../images/logos/medtronic-logo.webp";
import NxpLogo from "../../../images/logos/nxp-logo.webp";
import TelitLogo from "../../../images/logos/telit-logo.webp";

import MedtronicLogoSmall from "../../../images/logos/medtronic-logo-small.webp";
import NxpLogoSmall from "../../../images/logos/nxp-logo-small.webp";
import TelitLogoSmall from "../../../images/logos/telit-logo-small.webp";

export const signupInformation: string[] = [
    "Connect up to 3 OpenWrt devices for free",
    "Runtime protection with our patented Exploitation Fingerprint™ technology",
    "Incident response & investigations",
    "Real-time interactive dashboard",
    "Access to attack simulation kit",
    "Less than 3% overhead",
];

export const trustedIndustryLeaders: { id: string; logoSrc: string }[] = [
    {
        id: "medtronic",
        logoSrc: MedtronicLogo,
    },
    {
        id: "nxp",
        logoSrc: NxpLogo,
    },
    {
        id: "telis",
        logoSrc: TelitLogo,
    },
];

export const backendInvestors: { id: string; logoSrc: string }[] = [
    {
        id: "1",
        logoSrc: NxpLogoSmall,
    },
    {
        id: "2",
        logoSrc: TelitLogoSmall,
    },
    {
        id: "3",
        logoSrc: MedtronicLogoSmall,
    },
];
