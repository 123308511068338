import React, { useState } from "react";
import { connect } from "react-redux";
import { useFormik } from "formik";
import { Box, Link, Typography } from "@material-ui/core";

import { useStyles } from "./GetSupportModal.style";
import SternumImprovedButton from "../SUI/SternumImprovedButton/SternumImprovedButton";
import ServiceWire from "../../lib/services/ServiceWire";
import { SternumInputField } from "../SUI/SternumInputField";
import { TextArea } from "../SUI/TextArea";
import { GlobalState } from "../../lib/state/GlobalState";
import { NotificationMessage } from "../../lib/state/NotificationsState";
import { showNotificationAction } from "../../lib/redux/notifications/ShowNotificationAction";
import { CheckOutlinedIcon, CrossOutlinedIcon } from "../SUI/SternumIcon";
import { closeModalAction } from "../../lib/redux/modals/CloseModalAction";
import ModalKey from "../../lib/state/ModalKey";
import ModalType from "../../lib/state/ModalType";
import SternumLink from "../SUI/SternumLink/SternumLink";
import Utils from "../../lib/infra/Utils";

export interface GetSupportModalProps {
    onSuccess?: () => unknown;
}

interface FormValues {
    fullName: string;
    email: string;
    issue: string;
}

const mapStateToProps = (state: GlobalState, ownProps: GetSupportModalProps) => {
    return {
        user: state.user.entity,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        showNotification: (message: NotificationMessage) => dispatch(showNotificationAction(message)),
        closeModal: (key: string) => dispatch(closeModalAction(new ModalKey(ModalType.GetSupportModal, key))),
    };
};

type GetSupportModalPropsWithHOC = GetSupportModalProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

function GetSupportModalComponent({ user, onSuccess, closeModal }: GetSupportModalPropsWithHOC) {
    const classes = useStyles();
    const [requestState, setRequestState] = useState<"success" | "error" | null>(null);

    const formik = useFormik<FormValues>({
        initialValues: {
            fullName: user.getFullName(),
            email: user.email,
            issue: "",
        },
        initialErrors: {
            issue: "This field is required",
        },
        validate: (values) => {
            const errors: Partial<FormValues> = {};

            if (!values.fullName.trim()) {
                errors.fullName = "Full name is required";
            }

            if (!values.email.trim()) {
                errors.email = "Email is required";
            } else if (!Utils.isValidEmail(values.email)) {
                errors.email = "Email is invalid";
            }

            if (!values.issue.trim()) {
                errors.issue = "Please, describe the issue";
            }

            return errors;
        },
        onSubmit: async (values) => {
            try {
                await ServiceWire.getContactService().getSupport({
                    fullName: values.fullName,
                    email: values.email,
                    issue: values.issue,
                });

                onSuccess?.();
                setRequestState("success");
            } catch (err) {
                setRequestState("error");
            }
        },
    });

    if (requestState === "success") {
        return (
            <div className={classes.container}>
                <div className={classes.statusContainer}>
                    <CheckOutlinedIcon className={classes.statusIcon} />
                    <Typography className={classes.statusTitle}>Thank you!</Typography>
                    <Typography className={classes.statusSubTitle}>Your message has been sent</Typography>
                    <SternumLink className={classes.linkButton} onClick={() => closeModal("getSupport")}>
                        Close
                    </SternumLink>
                </div>
            </div>
        );
    }

    if (requestState === "error") {
        return (
            <div className={classes.container}>
                <div className={classes.statusContainer}>
                    <CrossOutlinedIcon className={classes.statusIcon} />
                    <Typography className={classes.statusTitle}>
                        Oops!
                        <br />
                        Something went wrong
                    </Typography>
                    <Typography className={classes.statusSubTitle}>Please, try again later.</Typography>
                    <SternumLink className={classes.linkButton} onClick={() => setRequestState(null)}>
                        Try again
                    </SternumLink>
                </div>
            </div>
        );
    }

    const isSendButtonDisabled = formik.isSubmitting || Object.keys(formik.errors).length > 0;

    return (
        <div className={classes.container}>
            <Typography variant="h4" className={classes.title}>
                Support
            </Typography>

            <SternumInputField
                label="Full name"
                name="fullName"
                inputValue={formik.values.fullName}
                onFieldChange={formik.handleChange}
                isTouched={!!formik.touched.fullName}
                error={!!formik.errors.fullName}
                helperText={formik.touched.fullName ? formik.errors.fullName : undefined}
                onTouch={() => formik.setFieldTouched("fullName", true)}
                required
            />

            <SternumInputField
                label="Email"
                name="email"
                inputValue={formik.values.email}
                onFieldChange={formik.handleChange}
                isTouched={!!formik.touched.email}
                error={!!formik.errors.email}
                helperText={formik.touched.email ? formik.errors.email : undefined}
                onTouch={() => formik.setFieldTouched("email", true)}
                required
            />

            <TextArea
                classNameTextArea={classes.detailsTextArea}
                onChange={(e) => formik.setFieldValue("issue", e.target.value)}
                onBlur={() => formik.setFieldTouched("issue", true)}
                value={formik.values.issue}
                label="What’s the issue? *"
                isError={formik.touched.issue && !!formik.errors.issue}
                belowTextArea={formik.touched.issue && formik.errors.issue}
                required
            />

            <div className={classes.footer}>
                <SternumLink className={classes.linkButton} onClick={() => closeModal("getSupport")}>
                    Close
                </SternumLink>
                <SternumImprovedButton
                    fullWidth={false}
                    buttonType="regularWithDisabled"
                    content="Send"
                    onClick={formik.submitForm}
                    isLoading={formik.isSubmitting}
                    isDisabled={isSendButtonDisabled}
                />
            </div>

            <Box textAlign="center" mt={4}>
                <Typography variant="caption">
                    Contact our Customer Care:{" "}
                    <Link underline="none" href="mailto:ads@sternumiot.com">
                        ads@sternumiot.com
                    </Link>
                </Typography>
            </Box>
        </div>
    );
}

export const GetSupportModal: React.FC<GetSupportModalProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(GetSupportModalComponent);
