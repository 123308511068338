import { getDefaultIssuesState, IssuesState } from "../../state/IssuesState";
import ActionType from "../ActionType";
import { IssuesReducerActions } from "./IssuesReducerActions";
import HashMap from "../../infra/HashMap";
import { IssueInfoStateStatus, IssueInfoStatus } from "../../state/IssueInfoType";
import { Status } from "../../state/GlobalTypes";
import IssueInfo from "../../state/IssueInfo";

/**
 * Reducer for all issue-related actions.
 */
const issuesReducer = (state: IssuesState = getDefaultIssuesState(), action: IssuesReducerActions) => {
    switch (action.type) {
        case ActionType.PutIssueStatus: {
            const newStatus: IssueInfoStateStatus = (() => {
                switch (action.status) {
                    case IssueInfoStatus.Resolved:
                        return IssueInfoStateStatus.Resolving;
                    case IssueInfoStatus.Dismissed:
                        return IssueInfoStateStatus.Dismissing;
                    default:
                        return IssueInfoStateStatus.Resolving;
                }
            })();

            return {
                ...state,
                issueStateByIssueId: HashMap.copyAndPut(state.issueStateByIssueId, action.issueId, {
                    ...state.issueStateByIssueId.get(action.issueId),
                    issueStatus: newStatus,
                }),
            };
        }

        case ActionType.PutIssueStatusSuccessfully: {
            const updatedIssue: IssueInfo = action.updatedIssue;

            if (updatedIssue) {
                return {
                    ...state,
                    selectedIssue: updatedIssue,
                    issueStateByIssueId: HashMap.copyAndPut(state.issueStateByIssueId, action.updatedFields.issueId, {
                        ...state.issueStateByIssueId.get(action.updatedFields.issueId),
                        issueStatus: IssueInfoStateStatus.StableState,
                    }),
                };
            }

            const updatedSelectedIssue = state.selectedIssue && state.selectedIssue.copy();

            updatedSelectedIssue.reasons = action.updatedFields.reasons.map((reason) => ({
                type: reason.type,
                text: reason.text || "",
            }));
            updatedSelectedIssue.status = action.updatedFields.status;

            return {
                ...state,
                selectedIssue: updatedSelectedIssue,
                issueStateByIssueId: HashMap.copyAndPut(state.issueStateByIssueId, action.updatedFields.issueId, {
                    ...state.issueStateByIssueId.get(action.updatedFields.issueId),
                    issueStatus: IssueInfoStateStatus.StableState,
                }),
            };
        }

        case ActionType.PutIssueStatusError: {
            const newStatus: IssueInfoStateStatus = (() => {
                switch (action.status) {
                    case IssueInfoStatus.Resolved:
                        return IssueInfoStateStatus.ResolveError;
                    case IssueInfoStatus.Dismissed:
                        return IssueInfoStateStatus.DismissError;
                    default:
                        return IssueInfoStateStatus.ResolveError;
                }
            })();

            return {
                ...state,
                issueStateByIssueId: HashMap.copyAndPut(state.issueStateByIssueId, action.issueId, {
                    ...state.issueStateByIssueId.get(action.issueId),
                    issueStatus: newStatus,
                }),
            };
        }

        case ActionType.GetIssueResolveReasonsInProgress: {
            return {
                ...state,
                issueResolveReasons: {
                    ...state.issueResolveReasons,
                    status: Status.InProgress,
                },
            };
        }

        case ActionType.GetIssueResolveReasonsSuccessfully: {
            return {
                ...state,
                issueResolveReasons: {
                    ...state.issueResolveReasons,
                    data: action.reasons,
                    status: Status.Success,
                },
            };
        }

        case ActionType.GetIssueResolveReasonsError: {
            return {
                ...state,
                issueResolveReasons: {
                    ...state.issueResolveReasons,
                    // data: [], // TODO: Should it be cleared?
                    status: Status.Error,
                },
            };
        }

        case ActionType.SetSelectedIssueInfo: {
            return {
                ...state,
                selectedIssue: action.issue,
            };
        }

        case ActionType.SetIssueStateByIssueId: {
            return {
                ...state,
                issueStateByIssueId: HashMap.copyAndPut(state.issueStateByIssueId, action.issueId, {
                    ...state.issueStateByIssueId.get(action.issueId),
                    ...action.partialIssueState,
                }),
            };
        }

        case ActionType.ResolveAllIssues: {
            return {
                ...state,
                entities: state.entities,
                loadingPendingIssuesCount: state.loadingPendingIssuesCount,
                errorLoadingPendingIssuesCount: state.errorLoadingPendingIssuesCount,
                pendingIssuesCount: 0,
            };
        }

        case ActionType.FetchPendingIssuesCount: {
            return {
                ...state,
                entities: state.entities,

                loadingPendingIssuesCount: true,
                errorLoadingPendingIssuesCount: false,
                pendingIssuesCount: state.pendingIssuesCount,
            };
        }

        case ActionType.FetchedPendingIssuesCountSuccessfully: {
            return {
                ...state,
                entities: state.entities,

                loadingPendingIssuesCount: false,
                errorLoadingPendingIssuesCount: false,
                pendingIssuesCount: action.pendingIssuesCount,
            };
        }

        case ActionType.ErrorFetchingPendingIssuesCount: {
            return {
                ...state,
                entities: state.entities,

                loadingPendingIssuesCount: false,
                errorLoadingPendingIssuesCount: true,
                pendingIssuesCount: state.pendingIssuesCount,
            };
        }

        default:
            return state;
    }
};

export default issuesReducer;
