import { Action } from "redux";

import ActionType from "../ActionType";

export interface SetIsOnlyAnomalyInsightsAction extends Action<ActionType> {
    type: ActionType.DashboardRegularSetIsOnlyAnomalyInsights;
    isOnlyAnomalyInsights: boolean;
}

export function setIsOnlyAnomalyInsightsAction(isOnlyAnomalyInsights: boolean): SetIsOnlyAnomalyInsightsAction {
    return {
        type: ActionType.DashboardRegularSetIsOnlyAnomalyInsights,
        isOnlyAnomalyInsights,
    };
}
