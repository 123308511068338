import React, { useState } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@material-ui/core";

import { useCommonStyle } from "../../CommonStyle";
import { CrossIcon, InfoCircleIcon } from "../../SUI/SternumIcon";
import ServiceWire from "../../../lib/services/ServiceWire";
import SternumLink from "../../SUI/SternumLink/SternumLink";
import SternumImprovedButton from "../../SUI/SternumImprovedButton/SternumImprovedButton";
import { showNotificationAction } from "../../../lib/redux/notifications/ShowNotificationAction";
import { NotificationMessage, NotificationVariant } from "../../../lib/state/NotificationsState";
import { GlobalState } from "../../../lib/state/GlobalState";

import { useStyle } from "./EmailUnsubscribeDialog.style";
import { EmailNotificationType } from "../../../lib/state/EmailNotificationSettings";

const mapStateToProps = (state: GlobalState, ownProps: EmailUnsubscribeDialogProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        showNotification: (message: NotificationMessage, variant?: NotificationVariant) => {
            dispatch(showNotificationAction(message, variant));
        },
    };
};

type EmailUnsubscribeDialogPropsWithHOC = EmailUnsubscribeDialogProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export interface EmailUnsubscribeDialogProps {
    open: boolean;
    unsubscribeFrom: EmailNotificationType[];
    onCancelClick?: () => unknown;
}

function EmailUnsubscribeDialogBaseComponent({
    open,
    unsubscribeFrom,
    onCancelClick,
    showNotification,
}: EmailUnsubscribeDialogPropsWithHOC) {
    const classes = useStyle();
    const classesCommon = useCommonStyle();
    const [isLoading, setLoading] = useState(false);

    const handleClose = () => {
        onCancelClick();
    };

    const handleUnsubscribeClick = async () => {
        setLoading(true);

        try {
            const clientId = ServiceWire.getClientsService().getSelectedClientId();
            await ServiceWire.getSternumService().unsubscribeFromEmailNotifications(clientId, unsubscribeFrom);

            showNotification("You've unsubscribed successfully", NotificationVariant.Success);
            handleClose();
        } catch (err) {
            showNotification("Failed to unsubscribe. Please, try again", NotificationVariant.Error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog
            aria-labelledby="dialog-title"
            open={open}
            onClose={handleClose}
            disableEnforceFocus
            classes={{
                root: classes.root,
                paper: classes.paper,
            }}
        >
            <DialogTitle id="dialog-title">
                <div className={classNames(classes.title)}>
                    <Box display="flex" alignItems="center" gridColumnGap={8}>
                        <InfoCircleIcon color="#FFA337" />
                        <Typography variant={"h6"} className={classNames(classesCommon.extraBold)}>
                            Do you want to unsubscribe?
                        </Typography>
                    </Box>

                    <IconButton onClick={onCancelClick} size="small">
                        <CrossIcon width={16} height={16} color="#909090" />
                    </IconButton>
                </div>
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>
                <Typography className={classes.unsubscribeDescription}>
                    Your unsaved changes will be lost if you navigate to another page.
                </Typography>
            </DialogContent>

            <DialogActions className={classNames(classes.actions)}>
                <SternumLink className={classes.linkButton} onClick={handleClose}>
                    Cancel
                </SternumLink>
                <SternumImprovedButton
                    content="Unsubscribe"
                    onClick={handleUnsubscribeClick}
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    buttonType="regularWithDisabled"
                    fullWidth={false}
                />
            </DialogActions>
        </Dialog>
    );
}

const EmailUnsubscribeDialogComponent: React.FC<EmailUnsubscribeDialogProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(EmailUnsubscribeDialogBaseComponent);

export function EmailUnsubscribeDialog(props: EmailUnsubscribeDialogProps) {
    if (!props.open) {
        return null;
    }

    return <EmailUnsubscribeDialogComponent {...props} />;
}
