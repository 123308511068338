import React, { useMemo } from "react";
import { Tooltip } from "@material-ui/core";
import moment from "moment";
import classNames from "classnames";

import { LockIcon } from "../../../../SUI/SternumIcon";
import DeviceInfo from "../../../../../lib/state/DeviceInfo";
import SternumUtils from "../../../../../lib/infra/SternumUtils";
import SternumFlag from "../../../../SternumFlag/SternumFlag";
import { useCommonStyle } from "../../../../CommonStyle";

import { useDeviceTileItemStyle } from "./DeviceTileItem.style";

export interface DeviceTileItemProps {
    device: DeviceInfo;
}

export function DeviceTileItem({ device }: DeviceTileItemProps) {
    const classes = useDeviceTileItemStyle();
    const classesCommon = useCommonStyle();

    const lastSeen = useMemo(() => moment(device.lastSeen).format("MMMM DD,YYYY"), [device.lastSeen]);
    const renderedCountry = useMemo(() => {
        const countryCode = device.countryCode;
        const countryName = countryCode ? SternumUtils.getCountryNameByCode(countryCode) : "";

        return (
            <div className={classes.country}>
                <SternumFlag countryCode={countryCode} />
                <Tooltip title={countryName}>
                    <span className={classes.withDots}>{countryName}</span>
                </Tooltip>
            </div>
        );
    }, [device.countryCode]);

    return (
        <div className={classes.deviceTileItemContainer}>
            <div className={classes.title}>{device.deviceDefinition.displayName}</div>
            <div className={classes.keyValueContainer}>
                <div className={classes.keyValue}>
                    <div className={classes.key}>ID:</div>
                    <div className={classes.value}>{device.receivedDeviceId}</div>
                </div>
                <div className={classes.keyValue}>
                    <div className={classes.key}>Last seen:</div>
                    <div className={classes.value}>{lastSeen}</div>
                </div>
                <div className={classNames(classes.keyValue, classes.flexNoWrap)}>
                    <div className={classes.key}>Location:</div>
                    <div className={classNames(classes.value, classesCommon.overflowHidden)}>{renderedCountry}</div>
                </div>
                <div className={classes.keyValue}>
                    <div className={classes.key}>IP Address:</div>
                    <div className={classes.value}>{device.ipAddress}</div>
                </div>
            </div>
            <div className={classes.spacer} />
            <div className={classes.tag}>
                Protected
                <LockIcon />
            </div>
        </div>
    );
}
