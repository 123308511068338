import React, { Fragment } from "react";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { History } from "history";

import { MonitorMobileIcon } from "../../../SUI/SternumIcon";
import { IconContainer } from "../IconContainer";
import { LinuxTopologyGraphData } from "../LinuxTopologyDropdown.types";

import { useLinuxTopologyGraphStyle } from "./LinuxTopologyGraph.styles";

export interface LinuxTopologyGraphProps {
    linuxTopologyGraphData: LinuxTopologyGraphData;
}

type LinuxTopologyGraphPropsWithHOC = LinuxTopologyGraphProps & {
    history: History;
};

export function LinuxTopologyGraphComponent({ linuxTopologyGraphData, history }: LinuxTopologyGraphPropsWithHOC) {
    const classes = useLinuxTopologyGraphStyle();

    const createItemHref = () => {
        return history.createHref({
            ...history.location,
            pathname: `/device/${linuxTopologyGraphData.item.deviceEntityId}`,
        });
    };

    const renderChildren = () => {
        const children = linuxTopologyGraphData.children;

        if (!children.length) {
            return null;
        }

        return (
            <div className={classes.childrenContainer}>
                <div className={classes.childDistanceTop} />
                {children.map((child, index) => {
                    return (
                        <Fragment key={child.item.deviceId}>
                            <div className={classes.childItem}>
                                <div className={classes.leftTopLine} />
                                {index < children.length - 1 && <div className={classes.leftBottomLine} />}

                                <div className={classes.horizontalLine} />
                                <LinuxTopologyGraph linuxTopologyGraphData={child} />
                            </div>

                            {index < children.length - 1 && <div className={classes.childDistanceBetween} />}
                        </Fragment>
                    );
                })}
            </div>
        );
    };

    return (
        <div className={classes.container}>
            <a href={createItemHref()} target="_blank" className={classes.item}>
                <IconContainer isSelected={linuxTopologyGraphData.item.isSelected}>
                    <MonitorMobileIcon />
                </IconContainer>
                <div
                    className={classNames(
                        classes.itemContent,
                        linuxTopologyGraphData.item.isSelected && classes.isSelected
                    )}
                >
                    {linuxTopologyGraphData.item.deviceId}
                </div>
            </a>
            {renderChildren()}
        </div>
    );
}

export const LinuxTopologyGraph: React.FC<LinuxTopologyGraphProps> = withRouter(LinuxTopologyGraphComponent);
