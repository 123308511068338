import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../../CommonStyle";

const visualisationConfigurationPresentationTitleStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        sectionTitleContainer: {
            display: "flex",
            alignItems: "center",
        },
        sectionTitle: {
            fontSize: 18,
            fontWeight: 600,
        },

        metricDisplayOptionTitle: {
            cursor: "pointer",

            "&.mod-selected": {
                fontWeight: 500,
                textDecoration: "underline",
            },
        },
    });

export default visualisationConfigurationPresentationTitleStyle;
