import MomentUtils from "@date-io/moment";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Link from "@material-ui/core/Link";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import classNames from "classnames";
import * as React from "react";
import SternumConfiguration from "../../lib/infra/SternumConfiguration";
import Utils from "../../lib/infra/Utils";
import DeviceInfo from "../../lib/state/DeviceInfo";
import DevicesFilter from "../../lib/state/DevicesFilter";
import EventFilter from "../../lib/state/EventFilter";
import ListFilter from "../../lib/state/ListFilter";
import deviceListFilterPopoverStyle from "./DevicesListFilterPopoverStyle";
/**
 * Holds the inner state for our app.
 */
interface AppState {
    deviceNames: string[];
    usedLibraryIds: string[];
    statuses: string[];
    ipAddresses: string[];
    lastSeenFrom: Date;
    lastSeenTo: Date;
    createdFrom: Date;
    createdTo: Date;
    eventFilters: EventFilter[];

    displayAdvanced: boolean;
    displayEditLastSeen: boolean;
    displayEditCreated: boolean;
    displayEditStatus: boolean;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof deviceListFilterPopoverStyle> {
    devicesFilter: DevicesFilter;
    onFilterChanged: (listFilter: ListFilter) => void;
}

/**
 * Popover content for view columns.
 */
class DevicesListFilterPopover extends React.Component<AppProps, AppState> {
    /**
     * Defining default state so we can later reset safely.
     */
    private readonly defaultState: AppState = {
        deviceNames: [],
        usedLibraryIds: [],
        statuses: [],
        ipAddresses: [],
        lastSeenFrom: null,
        lastSeenTo: null,
        createdFrom: null,
        createdTo: null,
        eventFilters: [],
        displayAdvanced: false,
        displayEditLastSeen: false,
        displayEditCreated: false,
        displayEditStatus: false,
    };

    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        let anyAdvancedProperties =
            this.props.devicesFilter &&
            (this.props.devicesFilter.lastSeenFrom ||
                this.props.devicesFilter.lastSeenTo ||
                this.props.devicesFilter.createdFrom ||
                this.props.devicesFilter.createdTo);
        let anyNonEmptyEventFilters =
            this.props.devicesFilter &&
            this.props.devicesFilter.eventFilters &&
            this.props.devicesFilter.eventFilters.length &&
            Utils.anyMatch(this.props.devicesFilter.eventFilters, (eventFilter) => !!eventFilter.eventType);
        let moreThanOneDeviceName =
            this.props.devicesFilter &&
            this.props.devicesFilter.deviceNames &&
            this.props.devicesFilter.deviceNames.length &&
            this.props.devicesFilter.deviceNames.filter((deviceName) => !!deviceName).length > 1;
        let moreThanOneUsedLibraryId =
            this.props.devicesFilter &&
            this.props.devicesFilter.usedLibraryIds &&
            this.props.devicesFilter.usedLibraryIds.length &&
            this.props.devicesFilter.usedLibraryIds.filter((usedLibraryId) => !!usedLibraryId).length > 1;
        let moreThanOneIpAddress =
            this.props.devicesFilter &&
            this.props.devicesFilter.ipAddresses &&
            this.props.devicesFilter.ipAddresses.length &&
            this.props.devicesFilter.ipAddresses.filter((ipAddress) => !!ipAddress).length > 1;

        this.state = {
            deviceNames:
                this.props.devicesFilter && this.props.devicesFilter.deviceNames
                    ? this.props.devicesFilter.deviceNames
                    : [],
            usedLibraryIds:
                this.props.devicesFilter && this.props.devicesFilter.usedLibraryIds
                    ? this.props.devicesFilter.usedLibraryIds
                    : [],
            statuses:
                this.props.devicesFilter && this.props.devicesFilter.statuses ? this.props.devicesFilter.statuses : [],
            ipAddresses:
                this.props.devicesFilter && this.props.devicesFilter.ipAddresses
                    ? this.props.devicesFilter.ipAddresses
                    : [],
            lastSeenFrom:
                this.props.devicesFilter && this.props.devicesFilter.lastSeenFrom
                    ? new Date(this.props.devicesFilter.lastSeenFrom)
                    : null,
            lastSeenTo:
                this.props.devicesFilter && this.props.devicesFilter.lastSeenTo
                    ? new Date(this.props.devicesFilter.lastSeenTo)
                    : null,
            createdFrom:
                this.props.devicesFilter && this.props.devicesFilter.createdFrom
                    ? new Date(this.props.devicesFilter.createdFrom)
                    : null,
            createdTo:
                this.props.devicesFilter && this.props.devicesFilter.createdTo
                    ? new Date(this.props.devicesFilter.createdTo)
                    : null,
            eventFilters:
                this.props.devicesFilter && this.props.devicesFilter.eventFilters
                    ? this.props.devicesFilter.eventFilters
                    : [],
            displayAdvanced: !!(
                anyAdvancedProperties ||
                anyNonEmptyEventFilters ||
                moreThanOneDeviceName ||
                moreThanOneUsedLibraryId ||
                moreThanOneIpAddress
            ),
            displayEditLastSeen: !!(
                this.props.devicesFilter &&
                (this.props.devicesFilter.lastSeenFrom || this.props.devicesFilter.lastSeenTo)
            ),
            displayEditCreated: !!(
                this.props.devicesFilter &&
                (this.props.devicesFilter.createdFrom || this.props.devicesFilter.createdTo)
            ),
            displayEditStatus: !!(
                this.props.devicesFilter &&
                this.props.devicesFilter.statuses &&
                this.props.devicesFilter.statuses.length
            ),
        };
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        let statusOptions = DeviceInfo.getPossibleDeviceStatusesDisplayNames();

        return (
            <div className={classes.root}>
                {/* Filter popover title */}
                <div className={classNames(classes.flexVMiddle, classes.marginBottom)}>
                    {/* Title */}
                    <Typography
                        variant={"caption"}
                        className={classNames(classes.titleTypography, classes.marginRight)}
                    >
                        {this.state.displayAdvanced ? "Advanced Filter" : "Quick Filter"}
                    </Typography>

                    {/* Link */}
                    <Typography variant={"caption"}>
                        <Link className={classes.advancedLink} onClick={() => this.handleAdvancedClicked()}>
                            {this.state.displayAdvanced ? "Quick Filter" : "Advanced"}
                        </Link>
                    </Typography>
                </div>

                {!this.state.displayAdvanced && (
                    <div>
                        {/* Device Name */}
                        <TextField
                            id="device-name"
                            label="Device Name"
                            className={classNames(classes.formControl, classes.marginRightLarge)}
                            value={this.state.deviceNames && this.state.deviceNames[0] ? this.state.deviceNames[0] : ""}
                            onChange={(event) => this.handleFilterByDeviceNameChanged(event)}
                        />

                        {/* Status */}
                        <FormControl className={classNames(classes.formControl, classes.marginRightLarge)}>
                            {/* Label */}
                            <InputLabel htmlFor="select-multiple-checkbox">Status</InputLabel>

                            {/* Select */}
                            <Select
                                multiple
                                value={this.state.statuses}
                                onChange={(event) => this.handleFilterByStatusSelected(event)}
                                input={<Input id="select-multiple-checkbox" />}
                                renderValue={(selected) => this.renderSelectedStatuses(selected)}
                            >
                                {statusOptions.map((statusOption) => (
                                    <MenuItem
                                        key={statusOption}
                                        value={statusOption}
                                        classes={{
                                            root: classes.filterByStatusesCheckboxRoot,
                                            selected: classes.filterByStatusesCheckboxSelected,
                                        }}
                                    >
                                        <Checkbox
                                            checked={this.state.statuses.indexOf(statusOption) > -1}
                                            color={"primary"}
                                        />
                                        <ListItemText primary={statusOption} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {/* IP Address */}
                        <TextField
                            id="ip-address"
                            label="IP Address"
                            className={classNames(classes.formControl, classes.marginRightLarge)}
                            value={this.state.ipAddresses && this.state.ipAddresses[0] ? this.state.ipAddresses[0] : ""}
                            onChange={(event) => this.handleFilterByIpChanged(event)}
                        />
                    </div>
                )}

                {/* Advanced section*/}
                {this.state.displayAdvanced && (
                    <div>
                        {/* Last seen title */}
                        <div className={classNames(classes.flexVMiddle, classes.eventsSectionTitleContainer)}>
                            {/* Title */}
                            <Typography
                                variant="body2"
                                className={classNames(classes.eventsSectionTitle, classes.marginRight)}
                            >
                                Last seen
                            </Typography>

                            {/* Edit */}
                            <Typography variant="body2">
                                <Link className={classes.addEventFilterLink} onClick={() => this.editLastSeenClicked()}>
                                    Edit
                                </Link>
                            </Typography>
                        </div>

                        {/* Last seen */}
                        {this.state.displayEditLastSeen && (
                            <div className={classNames(classes.marginTop)}>
                                {/* Last seen from */}
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <DateTimePicker
                                        value={this.state.lastSeenFrom}
                                        className={classNames(classes.formControl, classes.marginRightLarge)}
                                        label={"From"}
                                        clearable
                                        onChange={(newDate) => this.handleLastSeenFromChanged(newDate)}
                                    />
                                </MuiPickersUtilsProvider>

                                {/* Last seen to */}
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <DateTimePicker
                                        value={this.state.lastSeenTo}
                                        className={classNames(classes.formControl)}
                                        label={"To"}
                                        clearable
                                        onChange={(newDate) => this.handleLastSeenToChanged(newDate)}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        )}

                        {/* Created title */}
                        <div className={classNames(classes.flexVMiddle, classes.eventsSectionTitleContainer)}>
                            {/* Title */}
                            <Typography
                                variant="body2"
                                className={classNames(classes.eventsSectionTitle, classes.marginRight)}
                            >
                                Created
                            </Typography>

                            {/* Edit */}
                            <Typography variant="body2">
                                <Link className={classes.addEventFilterLink} onClick={() => this.editCreatedClicked()}>
                                    Edit
                                </Link>
                            </Typography>
                        </div>

                        {/* Created */}
                        {this.state.displayEditCreated && (
                            <div className={classNames(classes.marginTop)}>
                                {/* Created from */}
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <DateTimePicker
                                        value={this.state.createdFrom}
                                        className={classNames(classes.formControl, classes.marginRightLarge)}
                                        label={"From"}
                                        clearable
                                        onChange={(newDate) => this.handleCreatedFromChanged(newDate)}
                                    />
                                </MuiPickersUtilsProvider>

                                {/* Created to */}
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <DateTimePicker
                                        value={this.state.createdTo}
                                        className={classNames(classes.formControl)}
                                        label={"To"}
                                        clearable
                                        onChange={(newDate) => this.handleCreatedToChanged(newDate)}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        )}

                        {/* Events title */}
                        <div className={classNames(classes.flexVMiddle, classes.eventsSectionTitleContainer)}>
                            {/* Title */}
                            <Typography
                                variant="body2"
                                className={classNames(classes.eventsSectionTitle, classes.marginRight)}
                            >
                                Events
                            </Typography>

                            {/* + Add */}
                            {!this.state.eventFilters.length && (
                                <Typography variant="body2">
                                    <Link className={classes.addEventFilterLink} onClick={() => this.addEventFilter()}>
                                        + Add
                                    </Link>
                                </Typography>
                            )}
                        </div>

                        {/* Event filters  */}
                        {this.state.eventFilters.map((eventFilter, index) => {
                            return (
                                <div
                                    key={index}
                                    className={classNames(classes.eventFilterContainer, classes.marginTop)}
                                >
                                    {/* Event */}
                                    <FormControl className={classNames(classes.formControl, classes.marginRightLarge)}>
                                        <InputLabel htmlFor="event-type-simple">Event</InputLabel>
                                        <Select
                                            value={eventFilter.eventType || ""}
                                            onChange={(event) => this.handleEventTypeInEventFilterChanged(event, index)}
                                            inputProps={{
                                                name: "event",
                                                id: "event-type-simple",
                                            }}
                                        >
                                            {SternumConfiguration.getTraceEventTypesArray().map(
                                                (traceEventTypeConfiguration, index) => {
                                                    return (
                                                        <MenuItem
                                                            key={index}
                                                            value={traceEventTypeConfiguration.key || ""}
                                                        >
                                                            {traceEventTypeConfiguration.displayName}
                                                        </MenuItem>
                                                    );
                                                }
                                            )}
                                        </Select>
                                    </FormControl>

                                    {/* From */}
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DateTimePicker
                                            value={eventFilter.from}
                                            className={classNames(classes.formControl, classes.marginRightLarge)}
                                            label={"From"}
                                            clearable
                                            onChange={(newDate) => this.handleEventFilterFromChanged(newDate, index)}
                                        />
                                    </MuiPickersUtilsProvider>

                                    {/* To */}
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DateTimePicker
                                            value={eventFilter.to}
                                            className={classNames(classes.formControl)}
                                            label={"To"}
                                            clearable
                                            onChange={(newDate) => this.handleEventFilterToChanged(newDate, index)}
                                        />
                                    </MuiPickersUtilsProvider>

                                    {/* Delete event */}
                                    <CloseIcon
                                        className={classNames(classes.deleteEventIcon, classes.marginLeftLarge)}
                                        onClick={() => this.handleEventFilterDeleted(index)}
                                    />
                                </div>
                            );
                        })}

                        {/* Adding a new event filter */}
                        {this.state.eventFilters.length > 0 && (
                            <Typography variant="body2" className={classes.addEventFilterLinkContainer}>
                                <Link className={classes.addEventFilterLink} onClick={() => this.addEventFilter()}>
                                    + Add
                                </Link>
                            </Typography>
                        )}

                        {/* Statuses title */}
                        <div className={classNames(classes.flexVMiddle, classes.eventsSectionTitleContainer)}>
                            {/* Title */}
                            <Typography
                                variant="body2"
                                className={classNames(classes.eventsSectionTitle, classes.marginRight)}
                            >
                                Statuses
                            </Typography>

                            {/* Edit */}
                            <Typography variant="body2">
                                <Link className={classes.addEventFilterLink} onClick={() => this.editStatusesClicked()}>
                                    Edit
                                </Link>
                            </Typography>
                        </div>

                        {/* Status */}
                        {this.state.displayEditStatus && (
                            <FormControl className={classNames(classes.formControl, classes.marginRightLarge)}>
                                {/* Select */}
                                <Select
                                    multiple
                                    value={this.state.statuses || []}
                                    onChange={(event) => this.handleFilterByStatusSelected(event)}
                                    input={<Input id="select-multiple-checkbox" />}
                                    className={classes.marginTop}
                                    renderValue={(selected) => this.renderSelectedStatuses(selected)}
                                >
                                    {statusOptions.map((statusOption) => (
                                        <MenuItem
                                            key={statusOption}
                                            value={statusOption || ""}
                                            classes={{
                                                root: classes.filterByStatusesCheckboxRoot,
                                                selected: classes.filterByStatusesCheckboxSelected,
                                            }}
                                        >
                                            <Checkbox
                                                checked={this.state.statuses.indexOf(statusOption) > -1}
                                                color={"primary"}
                                            />
                                            <ListItemText primary={statusOption} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}

                        {/* Device names title */}
                        <div className={classNames(classes.flexVMiddle, classes.eventsSectionTitleContainer)}>
                            {/* Title */}
                            <Typography
                                variant="body2"
                                className={classNames(classes.eventsSectionTitle, classes.marginRight)}
                            >
                                Device Names
                            </Typography>

                            {/* + Add */}
                            {!this.state.deviceNames.length && (
                                <Typography variant="body2">
                                    <Link className={classes.addEventFilterLink} onClick={() => this.addDeviceName()}>
                                        + Add
                                    </Link>
                                </Typography>
                            )}
                        </div>

                        {/* Device names  */}
                        {this.state.deviceNames.map((deviceName, index) => {
                            return (
                                <div key={index} className={classNames(classes.deviceNameSelectionContainer)}>
                                    {/* Input */}
                                    <TextField
                                        id="device-name"
                                        placeholder={"Enter device name..."}
                                        className={classNames(
                                            classes.formControl,
                                            classes.marginTop,
                                            classes.marginRight
                                        )}
                                        value={deviceName || ""}
                                        onChange={(event) => this.handleFilterByDeviceNamesListChanged(event, index)}
                                    />

                                    {/* X Button */}
                                    <CloseIcon
                                        className={classNames(classes.deleteEventIcon, classes.marginLeftLarge)}
                                        onClick={() => this.handleDeviceNameDeleted(index)}
                                    />
                                </div>
                            );
                        })}

                        {/* Adding a new device name */}
                        {this.state.deviceNames.length > 0 && (
                            <Typography variant="body2" className={classes.addEventFilterLinkContainer}>
                                <Link className={classes.addEventFilterLink} onClick={() => this.addDeviceName()}>
                                    + Add
                                </Link>
                            </Typography>
                        )}

                        {/* Used libraries title */}
                        <div className={classNames(classes.flexVMiddle, classes.eventsSectionTitleContainer)}>
                            {/* Title */}
                            <Typography
                                variant="body2"
                                className={classNames(classes.eventsSectionTitle, classes.marginRight)}
                            >
                                Used libraries
                            </Typography>

                            {/* + Add */}
                            {!this.state.usedLibraryIds.length && (
                                <Typography variant="body2">
                                    <Link className={classes.addEventFilterLink} onClick={() => this.addUsedLibrary()}>
                                        + Add
                                    </Link>
                                </Typography>
                            )}
                        </div>

                        {/* Used libraries names  */}
                        {this.state.usedLibraryIds.map((usedLibraryId, index) => {
                            return (
                                <div key={index} className={classNames(classes.usedLibrarySelectionContainer)}>
                                    {/* Input */}
                                    <TextField
                                        id="usedLibrary"
                                        placeholder={"Enter used library id..."}
                                        className={classNames(
                                            classes.formControl,
                                            classes.marginTop,
                                            classes.marginRight
                                        )}
                                        value={usedLibraryId || ""}
                                        onChange={(event) => this.handleFilterByUsedLibrariesListChanged(event, index)}
                                    />

                                    {/* X Button */}
                                    <CloseIcon
                                        className={classNames(classes.deleteEventIcon, classes.marginLeftLarge)}
                                        onClick={() => this.handleUsedLibraryDeleted(index)}
                                    />
                                </div>
                            );
                        })}

                        {/* Adding a new used library */}
                        {this.state.usedLibraryIds.length > 0 && (
                            <Typography variant="body2" className={classes.addEventFilterLinkContainer}>
                                <Link className={classes.addEventFilterLink} onClick={() => this.addUsedLibrary()}>
                                    + Add
                                </Link>
                            </Typography>
                        )}

                        {/* Ip addresses title */}
                        <div className={classNames(classes.flexVMiddle, classes.eventsSectionTitleContainer)}>
                            {/* Title */}
                            <Typography
                                variant="body2"
                                className={classNames(classes.eventsSectionTitle, classes.marginRight)}
                            >
                                Ip Addresses
                            </Typography>

                            {/* + Add */}
                            {!this.state.ipAddresses.length && (
                                <Typography variant="body2">
                                    <Link className={classes.addEventFilterLink} onClick={() => this.addIpAddress()}>
                                        + Add
                                    </Link>
                                </Typography>
                            )}
                        </div>

                        {/* Ip Addresses */}
                        {this.state.ipAddresses.map((ipAddress, index) => {
                            return (
                                <div key={index} className={classNames(classes.deviceNameSelectionContainer)}>
                                    {/* Input */}
                                    <TextField
                                        id="ip-address"
                                        placeholder={"Enter ip address..."}
                                        className={classNames(
                                            classes.formControl,
                                            classes.marginTop,
                                            classes.marginRight
                                        )}
                                        value={ipAddress || ""}
                                        onChange={(event) => this.handleFilterByIpAddressesListChanged(event, index)}
                                    />

                                    {/* X Button */}
                                    <CloseIcon
                                        className={classNames(classes.deleteEventIcon, classes.marginLeftLarge)}
                                        onClick={() => this.handleIpAddressDeleted(index)}
                                    />
                                </div>
                            );
                        })}

                        {/* Adding a new ip address */}
                        {this.state.ipAddresses.length > 0 && (
                            <Typography variant="body2" className={classes.addEventFilterLinkContainer}>
                                <Link className={classes.addEventFilterLink} onClick={() => this.addIpAddress()}>
                                    + Add
                                </Link>
                            </Typography>
                        )}
                    </div>
                )}

                {/* Apply & Reset buttons */}
                <div className={classes.resetAndApplyButtons}>
                    {/* Apply */}
                    <Button
                        size={"small"}
                        variant={"contained"}
                        color={"primary"}
                        className={classes.marginRight}
                        onClick={(event) => this.handleFilterApplied()}
                    >
                        Apply
                    </Button>

                    {/* Reset */}
                    <Button size={"small"} variant={"contained"} onClick={(event) => this.handleFilterReset()}>
                        Reset
                    </Button>
                </div>
            </div>
        );
    }

    /**
     * Renders the display value for selected statuses to filter by.
     */
    private renderSelectedStatuses(selectedStatusesArray) {
        let shortestString = selectedStatusesArray[0];
        let shortestStringLength = selectedStatusesArray[0].length;

        for (let i = 0; i < selectedStatusesArray.length; i++) {
            let currentString = selectedStatusesArray[i];

            if (currentString.length < shortestStringLength) {
                shortestString = currentString;
                shortestStringLength = currentString.length;
            }
        }

        return `${shortestString} ${
            selectedStatusesArray.length - 1 > 0 ? `(+${selectedStatusesArray.length - 1})` : ""
        }`;
    }

    /**
     * Occurs once a filter by status value is changed.
     */
    private handleFilterByStatusSelected(event) {
        this.setState({
            statuses: event.target.value,
        });
    }

    /**
     * Occurs once the value of ip address is changed.
     */
    private handleFilterByIpChanged(event) {
        let ipAddress = event.target.value;
        let clonedArray = [...this.state.ipAddresses];
        clonedArray[0] = ipAddress;

        this.setState({
            ipAddresses: clonedArray,
        });
    }

    /**
     * Occurs once the value of device name is changed.
     */
    private handleFilterByDeviceNameChanged(event) {
        let deviceName = event.target.value;
        let clonedArray = [...this.state.deviceNames];
        clonedArray[0] = deviceName;

        this.setState({
            deviceNames: clonedArray,
        });
    }

    /**
     * Used library filter changed.
     */
    private handleFilterByUsedLibrariesListChanged(event, index) {
        let usedLibraryId = event.target.value;

        let clonedUsedLibraryIds = [...this.state.usedLibraryIds];
        clonedUsedLibraryIds.splice(index, 1, usedLibraryId);

        this.setState({
            usedLibraryIds: clonedUsedLibraryIds,
        });
    }

    /**
     * Occurs once the value of last seen from is changed.
     */
    private handleLastSeenFromChanged(newDate) {
        this.setState({
            lastSeenFrom: newDate,
        });
    }

    /**
     * Occurs once the value of last seen to is changed.
     */
    private handleLastSeenToChanged(newDate) {
        this.setState({
            lastSeenTo: newDate,
        });
    }

    /**
     * Occurs once the value of created from is changed.
     */
    private handleCreatedFromChanged(newDate) {
        this.setState({
            createdFrom: newDate,
        });
    }

    /**
     * Occurs once the value of created to is changed.
     */
    private handleCreatedToChanged(newDate) {
        this.setState({
            createdTo: newDate,
        });
    }

    /**
     * Occurs once filter is applied.
     */
    private handleFilterApplied() {
        if (this.props.onFilterChanged) {
            let eventFilters = this.state.eventFilters;
            eventFilters = eventFilters.filter((eventFilter) => eventFilter.eventType);

            this.props.onFilterChanged(
                new DevicesFilter(
                    this.state.deviceNames,
                    this.state.statuses,
                    this.state.ipAddresses,
                    this.state.lastSeenFrom ? this.state.lastSeenFrom.valueOf() : null,
                    this.state.lastSeenTo ? this.state.lastSeenTo.valueOf() : null,
                    this.state.createdFrom ? this.state.createdFrom.valueOf() : null,
                    this.state.createdTo ? this.state.createdTo.valueOf() : null,
                    eventFilters,
                    this.state.usedLibraryIds,
                    null,
                    null
                )
            );
        }
    }

    /**
     * Occurs on reset of filters requested.
     */
    private handleFilterReset() {
        this.setState({
            ...this.defaultState,
        });
    }

    /**
     * Occurs on advanced section toggle.
     */
    private handleAdvancedClicked() {
        this.setState({
            displayAdvanced: !this.state.displayAdvanced,
        });
    }

    /**
     * Adds a new empty event filter to the list.
     */
    private addEventFilter() {
        let clonedEventFilters = [...this.state.eventFilters];
        clonedEventFilters.push(new EventFilter("", null, null));

        this.setState({
            eventFilters: clonedEventFilters,
        });
    }

    /**
     * Occurs once the event type selection is changed.
     */
    private handleEventTypeInEventFilterChanged(event, index: number) {
        let clonedChangedEventFilter: EventFilter = new EventFilter(
            this.state.eventFilters[index].eventType,
            this.state.eventFilters[index].from,
            this.state.eventFilters[index].to
        );
        clonedChangedEventFilter.eventType = event.target.value;

        let clonedEventFilters = [...this.state.eventFilters];
        clonedEventFilters.splice(index, 1, clonedChangedEventFilter);

        this.setState({
            eventFilters: clonedEventFilters,
        });
    }

    /**
     * Handles changing the from of event filter.
     */
    private handleEventFilterFromChanged(newDate, index: number) {
        let clonedChangedEventFilter: EventFilter = new EventFilter(
            this.state.eventFilters[index].eventType,
            this.state.eventFilters[index].from,
            this.state.eventFilters[index].to
        );
        clonedChangedEventFilter.from = newDate;

        let clonedEventFilters = [...this.state.eventFilters];
        clonedEventFilters.splice(index, 1, clonedChangedEventFilter);

        this.setState({
            eventFilters: clonedEventFilters,
        });
    }

    /**
     * Handles changing the to of event filter.
     */
    private handleEventFilterToChanged(newDate, index: number) {
        let clonedChangedEventFilter: EventFilter = new EventFilter(
            this.state.eventFilters[index].eventType,
            this.state.eventFilters[index].from,
            this.state.eventFilters[index].to
        );
        clonedChangedEventFilter.to = newDate;

        let clonedEventFilters = [...this.state.eventFilters];
        clonedEventFilters.splice(index, 1, clonedChangedEventFilter);

        this.setState({
            eventFilters: clonedEventFilters,
        });
    }

    /**
     * Handles delete of event filter.
     */
    private handleEventFilterDeleted(index: number) {
        let clonedEventFilters = [...this.state.eventFilters];
        clonedEventFilters.splice(index, 1);

        this.setState({
            eventFilters: clonedEventFilters,
        });
    }

    /**
     * New device name filter added.
     */
    private addDeviceName() {
        let clonedDeviceNames = [...this.state.deviceNames];
        clonedDeviceNames.push("");

        this.setState({
            deviceNames: clonedDeviceNames,
        });
    }

    /**
     * New used library filter added.
     */
    private addUsedLibrary() {
        let clonedUsedLibraryIds = [...this.state.usedLibraryIds];
        clonedUsedLibraryIds.push("");

        this.setState({
            usedLibraryIds: clonedUsedLibraryIds,
        });
    }

    /**
     * Device name filter changed.
     */
    private handleFilterByDeviceNamesListChanged(event, index) {
        let deviceName = event.target.value;

        let clonedDeviceNames = [...this.state.deviceNames];
        clonedDeviceNames.splice(index, 1, deviceName);

        this.setState({
            deviceNames: clonedDeviceNames,
        });
    }

    /**
     * Device name deleted from list.
     */
    private handleDeviceNameDeleted(index) {
        let clonedDeviceNames = [...this.state.deviceNames];
        clonedDeviceNames.splice(index, 1);

        this.setState({
            deviceNames: clonedDeviceNames,
        });
    }

    /**
     * Used library deleted from list.
     */
    private handleUsedLibraryDeleted(index) {
        let clonedUsedLibraryIds = [...this.state.usedLibraryIds];
        clonedUsedLibraryIds.splice(index, 1);

        this.setState({
            usedLibraryIds: clonedUsedLibraryIds,
        });
    }

    /**
     * New ip address filter added.
     */
    private addIpAddress() {
        let clonedIpAddresses = [...this.state.ipAddresses];
        clonedIpAddresses.push("");

        this.setState({
            ipAddresses: clonedIpAddresses,
        });
    }

    /**
     * Ip address filter changed.
     */
    private handleFilterByIpAddressesListChanged(event, index) {
        let ipAddress = event.target.value;

        let clonedIpAddresses = [...this.state.ipAddresses];
        clonedIpAddresses.splice(index, 1, ipAddress);

        this.setState({
            ipAddresses: clonedIpAddresses,
        });
    }

    /**
     * Ip address deleted from list.
     */
    private handleIpAddressDeleted(index) {
        let clonedIpAddresses = [...this.state.ipAddresses];
        clonedIpAddresses.splice(index, 1);

        this.setState({
            ipAddresses: clonedIpAddresses,
        });
    }

    /**
     * Editing the last seen.
     */
    private editLastSeenClicked() {
        this.setState({
            displayEditLastSeen: !this.state.displayEditLastSeen,
        });
    }

    /**
     * Editing the created.
     */
    private editCreatedClicked() {
        this.setState({
            displayEditCreated: !this.state.displayEditCreated,
        });
    }

    /**
     * Editing the statuses.
     */
    private editStatusesClicked() {
        this.setState({
            displayEditStatus: !this.state.displayEditStatus,
        });
    }
}

export default withStyles(deviceListFilterPopoverStyle)(DevicesListFilterPopover);
