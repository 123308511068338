import { isEmpty, isNil, isString, max, mean, min, sum } from "lodash";
import UIDataVisualisationConfiguration from "../../components/VisualisationConfigurationComponent/entities/UIDataVisualisationConfiguration";
import { showNotificationAction } from "../redux/notifications/ShowNotificationAction";
import store from "../redux/store";
import ConfigurationService from "../services/ConfigurationService";
import ServiceWire from "../services/ServiceWire";
import AggregatedTraceDefinition from "../state/AggregatedTraceDefinition";
import ConditionType from "../state/ConditionType";
import QueryQuantifierType from "../state/QueryQuantifierType";
import SternumFilter from "../state/SternumFilter";
import SternumQuery from "../state/SternumQuery";
import AggregationFunctionType from "../state/Visualisation/AggregationFunctionType";
import VisualisationDataSourceGroupBy, {
    VisualisationDataSourceGroupByCustomFields,
} from "../state/Visualisation/VisualisationConfigurationGroupBy";
import SternumConfiguration from "./SternumConfiguration";
import SternumQueryField from "./SternumQueryField";
import SternumUtils from "./SternumUtils";

interface SternumFilterWithQuantifier {
    filter: SternumFilter;
    quantifier: QueryQuantifierType;
}

export interface GraphResult {
    data_sources: Record<string, any>;
    x_axes?: number[];
}

export interface GraphTooltipData {
    value: string | number;
    label: string;
}

const line = '<div style="height: 1px; margin: 5px 0; background: #fff; opacity: 0.5; width: 100%;"></div>';
const filterIcon = `
	<svg width="14" height="10" viewBox="0 0 11 7" fill="none">
		<rect width="11" height="1" rx="0.5" fill="white" />
		<rect x="1" y="3" width="9" height="1" rx="0.5" fill="white" />
		<rect x="3" y="6" width="5" height="1" rx="0.5" fill="white" />
	</svg>
`;

const deviceIcon = `
	<svg width="16" height="14" viewBox="0 0 14 12" fill="none">
		<rect x="1.35718" y="0.5" width="11.2857" height="10.4286" rx="2.5" stroke="white"/>
		<circle cx="10.3215" cy="8.60718" r="0.75" fill="white"/>
		<path d="M1.85718 6.57141H13" stroke="white"/>
	</svg>
`;

// @ts-ignore
window.copyTextToClipboard = (text: string) => {
    navigator.clipboard
        .writeText(text)
        .then(() => {
            store.dispatch(showNotificationAction("Device ID copied!"));
        })
        .catch((err) => {
            console.error("Could not copy text: ", err);
        });
};

/**
 * Common utils for dealing with graphs display.
 */
class GraphUtils {
    static readonly MAX_TOOLTIP_LINE_LENGTH = 50;

    /**
     * Applies a minimum over given data points array.
     * If any point is below the requested minimum, it will be set to the minimum.
     * The minimum will be set to a given percentage out of the maximum.
     * This function is typically used to have bars with a small-enough value still show.
     *
     * @param originalDataPoints The original data points we're transforming.
     * @param minimumPercentage The percentage of the maximum we'd like to set as the minimum.
     */
    public static applyMinimumOnDataPoints(
        originalDataPoints: number[],
        minimumPercentage: number,
        integer = false
    ): number[] {
        // Figuring out the max in the data points.
        const maxPointValue = Math.max(...originalDataPoints);
        // Minimum data point value would be 5% of the maximum value.
        let minimumDataPointDisplay = (minimumPercentage * maxPointValue) / 100;
        if (integer) {
            minimumDataPointDisplay = Math.round(minimumDataPointDisplay);
        }

        // Now that we have the minimum value for a point in the graph,
        // we reconstruct the data points using the minimum value if needed.
        return originalDataPoints.map((originalDataPoint) => {
            // Taking the real value needed.
            if (originalDataPoint && originalDataPoint > 0) {
                // Only if realDataValue exists it means this X point should even be in the graph.
                // In this case, we returning the maximum between the real data value and the minimum
                // value.
                return Math.max(minimumDataPointDisplay, originalDataPoint);
            } else {
                // Otherwise, we just return the real data value.
                return originalDataPoint;
            }
        });
    }

    public static applyMinimumOnDataPointsObject(
        originalDataPoints: { [key: string]: number },
        minimumPercentage: number,
        integer = false
    ): { [key: string]: number } {
        // Figuring out the max in the data points.
        const maxPointValue = Math.max(...Object.values(originalDataPoints));
        // Minimum data point value would be 5% of the maximum value.
        let minimumDataPointDisplay = (minimumPercentage * maxPointValue) / 100;
        if (integer) {
            minimumDataPointDisplay = Math.round(minimumDataPointDisplay);
        }

        const result = {};
        // Now that we have the minimum value for a point in the graph,
        // we reconstruct the data points using the minimum value if needed.
        Object.keys(originalDataPoints).forEach((key) => {
            // Taking the real value needed.
            if (originalDataPoints[key] && originalDataPoints[key] > 0) {
                // Only if realDataValue exists it means this X point should even be in the graph.
                // In this case, we returning the maximum between the real data value and the minimum
                // value.
                result[key] = Math.max(minimumDataPointDisplay, originalDataPoints[key]);
            } else {
                // Otherwise, we just return the real data value.
                result[key] = originalDataPoints[key];
            }
        });

        return result;
    }

    /**
     * Draws a tooltip on a charts js graph.
     * @param thisReference The reference to this from the calling function so we would have access to the _chart object.
     * @param tooltipModel The model of the tooltip from the callback.
     * @param tooltipContent The content lines of the tooltip.
     * @param stickTooltip Indicates whether tooltip should stick, for debugging uses.
     * @param overrideTitle Provide this parameter if you'd like the to override the title value, and not take it from the tooltip model itself.
     */
    public static drawGraphTooltip(
        thisReference: any,
        tooltipModel: any,
        tooltipContent: string[],
        stickTooltip?: boolean,
        overrideTitle?: string[]
    ) {
        // Do we need to stick tooltip? If so, we never un-draw it.
        if (stickTooltip && (!tooltipContent || !tooltipContent.length)) {
            return;
        }
        let tooltipElement = document.getElementById("chartjs-tooltip");

        // Create element on first render
        if (!tooltipElement) {
            tooltipElement = document.createElement("div");
            tooltipElement.id = "chartjs-tooltip";
            tooltipElement.innerHTML = "<table></table>";
            tooltipElement.style.backgroundColor = "rgba(0, 0, 0, 0.8)";
            tooltipElement.style.color = "#fff";
            tooltipElement.style.borderRadius = "4px";

            let caretElement = document.createElement("div");
            caretElement.style.width = "0";
            caretElement.style.height = "0";
            caretElement.style.borderLeft = "5px solid transparent";
            caretElement.style.borderRight = "5px solid transparent";
            caretElement.style.borderBottom = "5px solid black";
            caretElement.style.top = "-5px";
            caretElement.style.left = "47%";
            caretElement.style.position = "absolute";

            tooltipElement.appendChild(caretElement);
            document.body.appendChild(tooltipElement);
        }

        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
            tooltipElement.style.opacity = "0";
            return;
        }

        // Set caret Position
        tooltipElement.classList.remove("above", "below", "no-transform");
        if (tooltipModel.yAlign) {
            tooltipElement.classList.add(tooltipModel.yAlign);
        } else {
            tooltipElement.classList.add("no-transform");
        }

        // Set Text
        if (tooltipModel.body) {
            let titleLines = overrideTitle || tooltipModel.title || [];

            let innerHtml = "<thead>";
            titleLines.forEach((title) => {
                innerHtml += `<tr><th>${title}</th></tr>`;
            });

            innerHtml += "</thead><tbody>";

            tooltipContent.forEach((bodyLine) => {
                innerHtml += `<tr><td>${bodyLine}</td></tr>`;
            });
            innerHtml += "</tbody>";

            let tableRoot = tooltipElement.querySelector("table");
            tableRoot.style.display = "flex";
            tableRoot.style.flexDirection = "column";
            tableRoot.innerHTML = innerHtml;
        }

        // `this` will be the overall tooltip
        let position = thisReference._chart.canvas.getBoundingClientRect();

        // Display, position, and set styles for font
        tooltipElement.style.opacity = "1";
        tooltipElement.style.position = "absolute";
        tooltipElement.style.left = position.left + window.pageXOffset + tooltipModel.caretX + "px";
        tooltipElement.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 6 + "px";
        tooltipElement.style.fontFamily = tooltipModel._bodyFontFamily;
        tooltipElement.style.fontSize = tooltipModel.bodyFontSize + "px";
        tooltipElement.style.fontStyle = tooltipModel._bodyFontStyle;
        tooltipElement.style.padding = tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px";
        tooltipElement.style.pointerEvents = "none";

        let tooltipRect = tooltipElement.getBoundingClientRect();
        tooltipElement.style.left =
            position.left + window.pageXOffset + tooltipModel.caretX - tooltipRect.width / 2 + "px";
    }

    /**
     * Number formatter, convert 10000 to 10k and etc.
     */
    public static formatNumber(value) {
        const parsedNumber = parseFloat(value);
        if (parsedNumber < 1000) {
            return parsedNumber;
        }
        if (parsedNumber < 1000000) {
            return `${+(parsedNumber / 1000).toFixed(1)}K`;
        }
        if (parsedNumber < 1000000000) {
            return `${+(parsedNumber / 1000000).toFixed(1)}M`;
        }
        if (parsedNumber < 1000000000) {
            return `${+(parsedNumber / 1000000000).toFixed(1)}B`;
        }
        return `${+(parsedNumber / 1000000000).toFixed(1)}B+`;
    }

    private static retrieveFiltersFromSternumQuery(query: SternumQuery): SternumFilterWithQuantifier[] {
        return query.filters.reduce((acc, filter) => {
            acc.push({ filter, quantifier: query.queryQuantifierType });

            if (query.innerQueries.length > 0) {
                // @ts-ignore
                return acc.concat(query.innerQueries.flatMap(retrieveFiltersFromSternumQuery));
            }

            return acc;
        }, []);
    }

    private static generateCopyButton(itemPlaceholder: string) {
        const styles = [
            "margin-top: 10px",
            "padding: 5px 10px",
            "border-radius: 4px",
            "border: none",
            "background: rgba(255, 255, 255, 0.2)",
            "color: rgba(255, 255, 255, 0.8)",
            "cursor: pointer",
        ];
        return `
			<button style="${styles.join(";")}" onclick="window.copyTextToClipboard('{${itemPlaceholder}}')">
				Copy Device ID
			</button>
		`;
    }

    public static constructSeriesTooltip(
        configuration: UIDataVisualisationConfiguration,
        queryFields: SternumQueryField[],
        traceDefinitionFields: AggregatedTraceDefinition[],
        chartType: "default" | "bar" | "pie" = "default",
        groupBy?: VisualisationDataSourceGroupBy,
        deviceDefinitionVersionData: GraphTooltipData[] = []
    ): string {
        const { appliedSternumQuery } = configuration;

        const title = configuration.dataSourceLabel;
        const filters = GraphUtils.retrieveFiltersFromSternumQuery(appliedSternumQuery);

        const filtersElements = [];
        filters.forEach(({ filter, quantifier }) => {
            const field = queryFields.find((field) => field.apiName === filter.fieldApiName);

            let value = filter.valuesMap.values()[0]?.displayValue || filter.valuesMap.values()[0]?.value;

            value = isString(value) ? value : (value as React.ReactElement)?.props?.children || "";

            const condition = SternumConfiguration.getCondition(filter.conditionApiName as ConditionType);

            let label: string;
            let valueLabel: string;

            if (!field) {
                if (filter.displayName) {
                    label = filter.displayName;
                } else {
                    return;
                }

                valueLabel = value;
            } else {
                valueLabel = traceDefinitionFields.find((field) => field.value === value)?.label;
                label = field.label;
            }

            if (field && !valueLabel) {
                const configurationService = ServiceWire.getConfigurationService();

                if (field.apiName === ConfigurationService.getCategoryArgumentField().id) {
                    valueLabel = configurationService
                        .getTraceCategories()
                        .find((category) => category.numericIdentifier.toString() === value)?.displayName;
                }

                if (field.apiName === ConfigurationService.getInterestArgumentField().id) {
                    valueLabel = configurationService
                        .getInterests()
                        .find((interest) => interest.value === value)?.label;
                }

                if (field.apiName === ConfigurationService.getEventTypeArgumentField().id) {
                    valueLabel = configurationService
                        .getInterests()
                        .find((interest) => interest.value === value)?.label;
                }
            }

            filtersElements.push(
                `
					<div style="display: flex; align-items: center;">
						<div style="margin-right: 5px;">${filterIcon}</div> 
						<div>${label} ${condition.label.toLowerCase()} ${valueLabel || value}</div>
					</div>
				`
            );

            // const quantifierLabel = quantifier === "ALL" ? "AND" : "OR";

            // if (index !== filters.length - 1) {
            //     filtersText += ` ${quantifierLabel} `;
            // }
        });

        let deviceDefintionVersionsDataBlock = "";
        if (!isEmpty(deviceDefinitionVersionData)) {
            deviceDefintionVersionsDataBlock += line;

            deviceDefinitionVersionData.forEach((dataPoint) => {
                deviceDefintionVersionsDataBlock += `
					<div style="display: flex; justify-content: space-between;">
						<div style="display: flex; align-items: center; margin-right: 5px;">
							<div style="margin-right: 5px;">${deviceIcon}</div> 
							<div style="margin-bottom: 4px;">${dataPoint.label}</div>
						</div>
						${!isNil(dataPoint.value) ? `<div><b>${dataPoint.value}</b></div>` : ""}
					</div>
				`;
            });
        }

        let filtersBlock = "";
        if (!isEmpty(filtersElements)) {
            filtersBlock += line;

            filtersBlock += filtersElements.join("");
        }

        let copyButton = "";

        switch (chartType) {
            case "pie":
                if (groupBy?.enabled) {
                    if (groupBy.field === VisualisationDataSourceGroupByCustomFields.DEVICE) {
                        copyButton = `<button onclick="window.copyTextToClipboard({category})">Copy Device ID</button>`;
                        copyButton = this.generateCopyButton("category");
                    }

                    return `
						<div style="font-size: 13px;">
							<div style="display: flex; justify-content: space-between;">
								<div style="margin-right: 10px;">${title} [{category}]</div>
								<div><b>{value}</b></div>
							</div>

							${filtersBlock}

							${deviceDefintionVersionsDataBlock}

							${copyButton}
						</div>
					`;
                }

                return `
					<div style="font-size: 13px;">
						<div style="display: flex; justify-content: space-between;">
							<div style="margin-right: 10px;">${title}</div>
							<div><b>{value}</b></div>
						</div>

						${filtersBlock}

						${deviceDefintionVersionsDataBlock}
					</div>
				`;

            case "bar":
                if (groupBy?.enabled) {
                    if (groupBy.field === VisualisationDataSourceGroupByCustomFields.DEVICE) {
                        copyButton = this.generateCopyButton("categoryX");
                    }

                    return `
						<div style="font-size: 13px;">
							<div style="display: flex; justify-content: space-between;">
								<div style="margin-right: 10px;">${title} [{categoryX}]</div>
								<div><b>{valueY}</b></div>
							</div>

							${filtersBlock}

							${deviceDefintionVersionsDataBlock}

							${copyButton}
						</div>
					`;
                }

                return `
					<div style="font-size: 13px;">
						<div style="display: flex; justify-content: space-between;">
							<div style="margin-right: 10px;">${title}</div>
							<div><b>{valueY}</b></div>
						</div>

						${filtersBlock}

						${deviceDefintionVersionsDataBlock}
					</div>
				`;

            default:
                if (groupBy?.enabled) {
                    if (groupBy.field === VisualisationDataSourceGroupByCustomFields.DEVICE) {
                        copyButton = "<div><b>Tip</b>: Click on the point to copy the device ID</div>";
                    }

                    return `
						<div style="font-size: 13px;">
							<div style="display: flex; justify-content: space-between;">
								<div style="margin-right: 10px;">${title} [{categoryY}]</div>
								<div><b>{valueY}</b></div>
							</div>

							${filtersBlock}

							${deviceDefintionVersionsDataBlock}

							${copyButton}
						</div>
					`;
                }

                return `
					<div style="font-size: 13px;">
						<div style="display: flex; justify-content: space-between;">
							<div style="margin-right: 10px;">${title}</div>
							<div><b>{valueY}</b></div>
						</div>

						${filtersBlock}

						${deviceDefintionVersionsDataBlock}
					</div>
				`;
        }
    }

    public static convertGroupByKeysToLabelsArray(data: string[], groupByField: string) {
        if (groupByField === VisualisationDataSourceGroupByCustomFields.TRACE_CATEGORY) {
            return data.map((key) => {
                return SternumUtils.getTraceCategoryById(+key)?.displayName || key;
            });
        }

        if (groupByField === VisualisationDataSourceGroupByCustomFields.COUNTRY) {
            return data.map((key) => {
                return SternumUtils.getCountryNameByCode(key) || key;
            });
        }

        return data;
    }

    public static convertGroupByKeysToLabelsObject(data: { [key: string]: number }, groupByField: string) {
        if (groupByField === VisualisationDataSourceGroupByCustomFields.TRACE_CATEGORY) {
            return Object.keys(data).reduce((acc, key) => {
                acc[SternumUtils.getTraceCategoryById(+key)?.displayName || key] = data[key];
                return acc;
            }, {});
        }

        if (groupByField === VisualisationDataSourceGroupByCustomFields.COUNTRY) {
            return Object.keys(data).reduce((acc, key) => {
                acc[SternumUtils.getCountryNameByCode(key) || key] = data[key];
                return acc;
            }, {});
        }

        return data;
    }

    public static calculateTotalValue = (
        values: number[],
        aggregationFunctionType: AggregationFunctionType
    ): number => {
        switch (aggregationFunctionType) {
            case AggregationFunctionType.COUNT:
            case AggregationFunctionType.UNIQUE_COUNT:
            case AggregationFunctionType.PERCENTILE:
            case AggregationFunctionType.SUM:
                return sum(values);

            case AggregationFunctionType.AVG:
                return mean(values);

            case AggregationFunctionType.MAX:
                return max(values);

            case AggregationFunctionType.MIN:
                return min(values);

            default:
                throw new Error(`Unsupported aggregation function - ${aggregationFunctionType}`);
        }
    };
}

export default GraphUtils;

export interface GraphData {
    labels: string[];
    datasets: any[];
    tooltips?: Record<string, Record<string, number>>;
}
