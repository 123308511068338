import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        maxHeight: "calc(100vh - 100px)",
        padding: "0 16px",
        overflow: "auto",
    },

    content: {
        maxWidth: "80%",
        padding: "32px 0",
    },

    mainTitle: {
        fontSize: "34px",
        fontWeight: 700,
        margin: "32px 0 12px 0",
    },

    subTitle: {
        fontSize: "24px",
    },

    instructionTitle: {
        fontSize: "16px",
        fontWeight: 600,
    },

    instructionText: {
        margin: "12px 0",
        color: "#555B61",
        fontSize: "14px",
    },

    backButton: {
        display: "inline-flex",
        alignItems: "center",
        gap: 4,
        color: "#1B6FDE",
        fontWeight: 600,
        cursor: "pointer",

        "&:hover": {
            color: "#1B6FDE",
        },
    },
    backButtonIcon: {
        color: "#1B6FDE",
    },
}));
