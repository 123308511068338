import Utils from "../infra/Utils";
import ServiceWire from "../services/ServiceWire";
import DeviceInfo from "./DeviceInfo";
import EntityBase from "./EntityBase";
import EntityType from "./EntityType";
import SternumGeneratedEventInfo from "./SternumGeneratedEventInfo";
import TableRowData from "./TableRowData";
import TraceInfo from "./TraceInfo";

/**
 * Represents a sternum event.
 */
class SternumDeviceEventInfo extends EntityBase implements TableRowData {
    /**
     * Constructor.
     */
    constructor(
        public entityId: string,
        public entityIdLong: number,
        public created: number,
        public device: DeviceInfo,
        public traceInfo?: TraceInfo,
        public sternumGeneratedEventInfo?: SternumGeneratedEventInfo,
        public linuxView?: { latest_seen?: number; modules_added?: string[] }
    ) {
        super(entityId, created, created, EntityType.SternumEvent);
    }

    /**
     * Converts json to issue info object.
     */
    public static fromJsonObject(jsonObject: Object) {
        let sternumDeviceEventInfo: SternumDeviceEventInfo = new SternumDeviceEventInfo(
            jsonObject["entity_id"],
            jsonObject["entity_id_long"],
            jsonObject["created"],
            DeviceInfo.fromJsonObject(jsonObject["device"]),
            null,
            null,
            jsonObject["linux_view"]
        );

        if ("trace" in jsonObject) {
            sternumDeviceEventInfo.traceInfo = TraceInfo.fromJsonObject(jsonObject["trace"]);
        }

        if ("sternum_generated_event" in jsonObject) {
            sternumDeviceEventInfo.sternumGeneratedEventInfo = SternumGeneratedEventInfo.fromJsonObject(
                jsonObject["sternum_generated_event"]
            );
        }

        return sternumDeviceEventInfo;
    }

    /**
     * Returns whether given row is any different in details than current issue info.
     * Shallow comparison, in other words.
     * @param other The row to compare with.
     */
    public isDifferentFrom(other: TableRowData): boolean {
        // Row must of of type issue info.
        if (!(other instanceof SternumDeviceEventInfo)) {
            return true;
        }

        let otherEntityInfo = other as SternumDeviceEventInfo;

        // Comparing base issue properties.
        if (
            otherEntityInfo.entityId !== this.entityId ||
            otherEntityInfo.created !== this.created ||
            otherEntityInfo.updated !== this.updated
        ) {
            return true;
        }

        return false;
    }

    /**
     * Gets the value for the given column id.
     */
    public getColumnValue(columnHeaderId: string): any {
        return this[columnHeaderId];
    }

    /**
     * Returns the display name of the sternum device event.
     *
     */

    public getDisplayName(): string {
        let name: string;
        if (this.traceInfo) {
            name = this.traceInfo.traceDefinition.displayName;
        } else if (this.sternumGeneratedEventInfo) {
            name = this.sternumGeneratedEventInfo.sternumTrigger.displayName;
        } else {
            name = "Unknown";
        }
        return Utils.capitalizeFirsLetter(name);
    }

    getTraceType = (): string => {
        return this.traceInfo ? "Trace" : "Event";
    };

    /**
     * Gets the type of the event.
     */
    public getEventType(): string {
        if (this.traceInfo) {
            // Trace might be alert (attack attempt)
            if (this.traceInfo.isAttackAttempt()) {
                return "Alert";
            } else {
                return "Trace";
            }
        } else if (this.sternumGeneratedEventInfo) {
            return "Alert";
        } else {
            return "Unknown";
        }
    }

    /**
     * Gets the event interest of the event.
     */
    public getEventInterest(): string {
        if (this.traceInfo) {
            return this.traceInfo.traceDefinition.traceInterest;
        } else if (this.sternumGeneratedEventInfo) {
            return this.sternumGeneratedEventInfo.sternumTrigger.eventInterest;
        } else {
            return "Unknown";
        }
    }

    /**
     * Gets the version firmware.
     */
    public getVersionFirmware(): string {
        return (
            this.traceInfo?.deviceDefinitionVersion?.versionFirmware ||
            this.sternumGeneratedEventInfo?.deviceDefinitionVersion?.versionFirmware ||
            "Unknown"
        );
    }

    /**
     * Get the category display name by event type
     */
    public getCategoryDisplay(): string {
        if (this.traceInfo) {
            return this.convertCategoryToDisplayName(this.traceInfo.traceDefinition.traceCategory);
        } else if (this.sternumGeneratedEventInfo) {
            return this.convertCategoryToDisplayName(this.sternumGeneratedEventInfo.sternumTrigger.triggerCategory);
        } else {
            return " ";
        }
    }

    /**
     * Help function to convert system category name into ui display name
     */
    private convertCategoryToDisplayName(category: string): string {
        return ServiceWire.getConfigurationService().getTraceCategoriesMap().get(category).displayName;
    }

    /**
     * Return process name if it's a trace and contains a process name
     */
    public getProcessName = (): string => {
        return this.traceInfo?.getProcessName();
    };
}

export default SternumDeviceEventInfo;
