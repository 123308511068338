import { grey } from "@material-ui/core/colors";
import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../../CommonStyle";

const visualisationConfigurationHeaderStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: {
            display: "flex",
            alignItems: "center",
            padding: `0 ${theme.spacing(5)}px ${theme.spacing(1)}px ${theme.spacing(5)}px`,
            height: "60px",
        },

        valueInputBox: {
            fontSize: "1.2rem",
            fontWeight: 500,
        },

        selectComponent: {
            minWidth: "190px",
        },

        closeIcon: {
            cursor: "pointer",
            color: grey[500],
            marginLeft: theme.spacing(2),
            "&:hover": {
                color: theme.palette.common.black,
            },
        },

        pencil: {
            fontSize: theme.spacing(3),
            cursor: "pointer",
            marginLeft: theme.spacing(3),
        },

        mainTitle: {
            fontSize: 20,
            fontWeight: 600,
        },
    });

export default visualisationConfigurationHeaderStyle;
