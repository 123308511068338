import TimeRange from "./TimeRange";
import TimeSelectionType from "./TimeSelectionType";

class TimeSelectOption {
    /**
     * Constructor.
     */

    constructor(
        public label: string,
        public status: string,
        public unit: string,
        public timeRange: TimeRange,
        public selectionType: TimeSelectionType,
        public defaultUnitValue?: number
    ) {}
}

export default TimeSelectOption;
