import React, { createContext, useContext, useRef } from "react";

const SwitchSelectorSelectedCaseContext = createContext(null);
const SwitchSelectorOnChangeSelectedCaseContext = createContext((newSelectedCase: any) => {});

export interface SwitchSelectorContextProviderProps {
    selectedCase: any;
    onChangeSelectedCase: (newSelectedCase: any) => unknown;
    children: React.ReactNode;
}

export function SwitchSelectorContextProvider({
    selectedCase,
    onChangeSelectedCase,
    children,
}: SwitchSelectorContextProviderProps) {
    const onChange = useRef((newSelectedCase: any) => {});
    const handleChangeSelectedCase = useRef((newSelectedCase: any) => {
        return onChange.current(newSelectedCase);
    });

    onChange.current = (newSelectedCase: any) => {
        onChangeSelectedCase?.(newSelectedCase);
    };

    return (
        <SwitchSelectorSelectedCaseContext.Provider value={selectedCase}>
            <SwitchSelectorOnChangeSelectedCaseContext.Provider value={handleChangeSelectedCase.current}>
                {children}
            </SwitchSelectorOnChangeSelectedCaseContext.Provider>
        </SwitchSelectorSelectedCaseContext.Provider>
    );
}

export const useSwitchSelectorContext = () => {
    const selectedCase = useContext(SwitchSelectorSelectedCaseContext);
    const onChangeSelectedCase = useContext(SwitchSelectorOnChangeSelectedCaseContext);

    return { selectedCase, onChangeSelectedCase };
};
