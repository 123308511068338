import { Typography } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import HashSet from "../../lib/infra/HashSet";
import DevicesFilter from "../../lib/state/DevicesFilter";
import { GlobalState } from "../../lib/state/GlobalState";
import TableToolbarDisplayState from "../../lib/state/TableToolbarDisplayState";
import DevicesList from "../DevicesList/DevicesList";
import devicesListModalStyle from "./DevicesListModalStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof devicesListModalStyle> {
    fullScreenDisplay: boolean;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {};
};

/**
 * Displays a bar of metrics.
 */
class DevicesListModal extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {};
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.wrapper}>
                {/* Title Section */}
                <div className={classNames(classes.upperContentContainer)}>
                    {/* Main Title */}
                    <Typography className={classes.title}>Vulnerable Devices</Typography>

                    {/* Subtitle */}
                    <Typography className={classes.subtitle}>
                        Devices which currently use a vulnerable library.
                    </Typography>
                </div>

                {/* List of devices */}
                <div className={classes.tableContainer}>
                    <div className={classes.tableInner}>
                        <DevicesList
                            viewedColumnsSet={HashSet.fromValues([
                                "entityId",
                                "name",
                                "status",
                                "lastSeen",
                                "ipAddress",
                            ])}
                            devicesFilter={
                                new DevicesFilter(null, null, null, null, null, null, null, null, null, true, null)
                            }
                            displayBackButtonInDeviceView={true}
                            displayXButtonInDeviceView={true}
                            toolBarState={
                                new TableToolbarDisplayState(
                                    true,
                                    false,
                                    true,
                                    false,
                                    false,
                                    false,
                                    false,
                                    false,
                                    "Search Device"
                                )
                            }
                            openDeviceViewInCurrentPage={false}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(devicesListModalStyle)(DevicesListModal));
