import React, { useContext, useMemo } from "react";
import { isEqual } from "lodash";
import TableRowData from "./TableRowData";
import ConfigurationService from "../../lib/services/ConfigurationService";
import { Chip } from "@material-ui/core";
import { UniqueCountDataContext } from "../../components/UniqueCountQuery/UniqueCountContext";
import { makeStyles } from "@material-ui/styles";

interface ColumnMap {
    [column: string]: string | number;
}

interface ArgumentProps {
    label: string;
    value: any;
}

const styles = {
    argumentContainer: {
        display: "inline-block",
        paddingRight: 10,
    },
};

const useStyles = makeStyles({
    argument: {
        color: "#999999",
        backgroundColor: "#F0F3F5",
    },
    argumentKey: {
        fontWeight: "bold",
    },
});

const Argument = ({ label, value }: ArgumentProps) => {
    const availableMetrics = useContext(UniqueCountDataContext);
    const classes = useStyles();

    const properLabel = useMemo(() => {
        for (let i = 0; i < availableMetrics.length; ++i) {
            if (availableMetrics[i].value === label) {
                return availableMetrics[i].label;
            }
        }

        return label;
    }, [availableMetrics]);

    return (
        <Chip
            className={classes.argument}
            label={
                <span>
                    <span className={classes.argumentKey}>{properLabel}:</span> {value}
                </span>
            }
        />
    );
};

/**
 * Represents a sternum event.
 */
class SternumUniqueCountEventInfo implements TableRowData {
    specialFieldIds = [
        ConfigurationService.getEventTypeArgumentField().id,
        ConfigurationService.getInterestArgumentField().id,
        ConfigurationService.getCategoryArgumentField().id,
        ConfigurationService.getDeviceIdArgumentField().id,
    ];
    /**
     * Constructor.
     */
    constructor(public columns: ColumnMap) {}
    getIdentifier(): string {
        return Object.values(this.columns).join("+");
    }

    /**
     * Returns whether given row is any different in details than current issue info.
     * Shallow comparison, in other words.
     * @param other The row to compare with.
     */
    public isDifferentFrom(other: TableRowData): boolean {
        // Row must of of type issue info.
        if (!(other instanceof SternumUniqueCountEventInfo)) {
            return true;
        }

        let otherEntityInfo = other as SternumUniqueCountEventInfo;

        // Comparing base issue properties.
        if (!isEqual(this.columns, other.columns)) {
            return true;
        }

        return false;
    }

    /**
     * Gets the value for the given column id.
     */
    public getColumnValue(columnHeaderId: string): any {
        if (columnHeaderId === "arguments") {
            return (
                <>
                    {Object.keys(this.columns)
                        .filter((col) => !this.specialFieldIds.includes(col) && col !== "count")
                        .map((col) => (
                            <span key={`${col}-${this.columns[col]}`} style={styles.argumentContainer}>
                                <Argument label={col} value={this.columns[col]} />
                            </span>
                        ))}
                </>
            );
        }

        return this.columns[columnHeaderId];
    }
}

export default SternumUniqueCountEventInfo;
