import * as React from "react";

import { WithStyles, withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import cveReferencesDisplayStyle from "./CveReferencesDisplayStyle";
import { Typography } from "@material-ui/core";
import CveInfo from "../../lib/state/CveInfo";
import Utils from "../../lib/infra/Utils";

/**
 * Holds the inner state for our app.
 */
interface AppState {}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof cveReferencesDisplayStyle> {
    cve: CveInfo;
}

/**
 * Cve info display.
 */
class CveReferencesDisplay extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {};
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        let tagToReferencesMap = {};
        let referencesWithoutTags = [];

        for (let i = 0; i < this.props.cve.externalEntity["references"].length; i++) {
            let reference = this.props.cve.externalEntity["references"][i];

            if (reference.tags && reference.tags.length) {
                let tagsString = reference.tags.join(", ");

                if (!tagToReferencesMap[tagsString]) {
                    tagToReferencesMap[tagsString] = [];
                }

                tagToReferencesMap[tagsString].push(reference);
            } else {
                referencesWithoutTags.push(reference);
            }
        }

        let tagReferencesPairs = Utils.getMapPairs(tagToReferencesMap);

        if (referencesWithoutTags && referencesWithoutTags.length) {
            tagReferencesPairs.push({
                key: "Other",
                value: referencesWithoutTags,
            });
        }

        return (
            <div>
                {tagReferencesPairs.map((pair, index) => {
                    return (
                        <div key={index} className={classes.marginBottom}>
                            {/* Tag title */}
                            <Typography variant={"body2"} className={classes.tagTitle}>
                                {pair["key"]}
                            </Typography>

                            {/* References */}
                            {pair["value"].map((referenceObject, innerIndex) => {
                                return (
                                    <Typography key={innerIndex}>
                                        <a
                                            href={referenceObject["url"]}
                                            target={"_blank"}
                                            className={classes.referenceLink}
                                        >
                                            {referenceObject["url"]}
                                        </a>
                                    </Typography>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default withRouter(withStyles(cveReferencesDisplayStyle)(CveReferencesDisplay));
