import ListFilter from "./ListFilter";
import SternumQuery from "./SternumQuery";

/**
 * Represents a filter of sternum device events.
 */
class SternumDeviceEventsFilter implements ListFilter {
    /**
     * Constructor.
     */
    constructor(
        public eventInterest: string,
        public onlyAttackTraces: boolean,
        public createdFrom: number,
        public createdTo: number,
        public lessThanId: string,
        public greaterThanId: string,
        public traceCategories: string[],
        public filterOnlyTriggers: boolean,
        public sternumQuery: SternumQuery,
        public traceDefinitionIds: string[],
        public lessThanOrEqualToId?: string,
        public greaterThanOrEqualToId?: string
    ) {}

    /**
     * Returns whether filter is defined, or all values are nulls.
     */
    public isActive(): boolean {
        return !!(this.sternumQuery && !this.sternumQuery.isEmpty());
    }

    /**
     * Returns whether current filter is different than given other filter.
     * @param other The other list filter to check with.
     */
    public isDifferentFrom(other: ListFilter): boolean {
        if (!(other instanceof SternumDeviceEventsFilter)) {
            return true;
        }

        let otherEntitiesFilter = other as SternumDeviceEventsFilter;

        return (
            otherEntitiesFilter.eventInterest !== this.eventInterest ||
            otherEntitiesFilter.createdFrom !== this.createdFrom ||
            otherEntitiesFilter.createdTo !== this.createdTo ||
            otherEntitiesFilter.lessThanId !== this.lessThanId ||
            otherEntitiesFilter.greaterThanId !== this.greaterThanId ||
            otherEntitiesFilter.lessThanOrEqualToId !== this.lessThanOrEqualToId ||
            otherEntitiesFilter.greaterThanOrEqualToId !== this.greaterThanOrEqualToId ||
            otherEntitiesFilter.onlyAttackTraces !== this.onlyAttackTraces ||
            otherEntitiesFilter.traceCategories !== this.traceCategories ||
            otherEntitiesFilter.filterOnlyTriggers !== this.filterOnlyTriggers ||
            otherEntitiesFilter.traceDefinitionIds !== this.traceDefinitionIds ||
            !!(otherEntitiesFilter.sternumQuery && !this.sternumQuery) ||
            !!(!otherEntitiesFilter.sternumQuery && this.sternumQuery) ||
            (otherEntitiesFilter.sternumQuery &&
                this.sternumQuery &&
                otherEntitiesFilter.sternumQuery.isDifferentFrom(this.sternumQuery))
        );
    }

    public isDifferentTime(other: ListFilter): boolean {
        let otherEntitiesFilter = other as SternumDeviceEventsFilter;
        return otherEntitiesFilter.createdFrom !== this.createdFrom || otherEntitiesFilter.createdTo !== this.createdTo;
    }

    /**
     * Clones the sternum query to a new cloned object.
     */
    public clone() {
        return new SternumDeviceEventsFilter(
            this.eventInterest,
            this.onlyAttackTraces,
            this.createdFrom,
            this.createdTo,
            this.lessThanId,
            this.greaterThanId,
            this.traceCategories,
            this.filterOnlyTriggers,
            this.sternumQuery?.clone(),
            this.traceDefinitionIds
        );
    }
}

export default SternumDeviceEventsFilter;
