import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { first, last } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import SternumQueryField from "../../lib/infra/SternumQueryField";
import SternumUtils from "../../lib/infra/SternumUtils";
import Utils from "../../lib/infra/Utils";
import { closeModalAction } from "../../lib/redux/modals/CloseModalAction";
import ReceivedDefinitionsResponse from "../../lib/services/events/ReceivedDefinitionsResponse";
import ServiceWire from "../../lib/services/ServiceWire";
import AggregatedTraceDefinition from "../../lib/state/AggregatedTraceDefinition";
import DeviceDefinitionVersionInfo from "../../lib/state/DeviceDefinitionVersionInfo";
import { GlobalState } from "../../lib/state/GlobalState";
import ModalKey from "../../lib/state/ModalKey";
import ModalType from "../../lib/state/ModalType";
import SternumDeviceEventsFilter from "../../lib/state/SternumDeviceEventsFilter";
import SternumQuery from "../../lib/state/SternumQuery";
import TimeSelectOption from "../../lib/state/TimeSelectOption";
import AggregationFunctionType from "../../lib/state/Visualisation/AggregationFunctionType";
import VisualisationDataSourceGroupBy from "../../lib/state/Visualisation/VisualisationConfigurationGroupBy";
import VisualisationInfo from "../../lib/state/Visualisation/VisualisationInfo";
import VisualisationType from "../../lib/state/Visualisation/VisualisationType";
import { UniqueCountTableDataProvider } from "../UniqueCountQuery/UniqueCountTableData";
import VisualisationConfigurationPresentation from "../VisualisationConfigurationPresentation/VisualisationConfigurationPresentation";
import UIDataVisualisationConfiguration from "./entities/UIDataVisualisationConfiguration";
import getDefaultDataVisualisationConfiguration from "./utils/getDefaultDataVisualisationConfiguration";
import getSpecialFieldSelectDisplay from "./utils/getSpecialFieldSelectDisplay";
import { getTimeSeriesGraphLabel } from "./utils/getTimeSeriesGraphLabel";
import visualisationConfigurationComponentStyle from "./VisualisationConfigurationComponentStyle";
import VisualisationConfigurationDataSources from "./VisualisationConfigurationDataSources/VisualisationConfigurationDataSources";
import VisualisationConfigurationEventsExplorer from "./VisualisationConfigurationEventsExplorer/VisualisationConfigurationEventsExplorer";
import VisualisationConfigurationFooter from "./VisualisationConfigurationFooter/VisualisationConfigurationFooter";
import VisualisationConfigurationHeader from "./VisualisationConfigurationHeader/VisualisationConfigurationHeader";
import VisualisationConfigurationPresentationTitle from "./VisualisationConfigurationPresentationTitle/VisualisationConfigurationPresentationTitle";
import VisualisationConfigurationTypeSelection from "./VisualisationConfigurationTypeSelection/VisualisationConfigurationTypeSelection";

const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    const selectedClient = ServiceWire.getClientsService().getSelectedClient();

    return {
        isFreeUser: selectedClient.isTrialTier(),
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeModal: (modalKey) => dispatch(closeModalAction(modalKey)),
    };
};

interface AppState {
    startTime?: Date;
    endTime?: Date;

    displayName: string;
    visualizationType: VisualisationType;

    filterSectionExpanded: boolean;

    selectedDataSource: string;

    errorLoadingReceivedDefinitions: boolean;

    traceDefinitionFields: Record<string, AggregatedTraceDefinition[]>;
    argumentDefinitionFields: Record<string, SternumQueryField[]>;

    dataVisualisationConfigurations: UIDataVisualisationConfiguration[];
    groupBy: VisualisationDataSourceGroupBy;

    receivedDefinitionsResponse: Record<string, ReceivedDefinitionsResponse>;

    loadingVisualisation?: boolean;
    errorLoadingVisualisation?: boolean;
    visualisation?: VisualisationInfo;

    savingVisualisation?: boolean;
    errorSavingVisualisation?: boolean;

    editingName?: boolean;

    deletingVisualisation?: boolean;
    errorDeletingVisualisation?: boolean;

    isStacked: boolean;
    isLogarithmicScale: boolean;
    isEventsExplorerExpanded: boolean;

    refreshComponentsId: number;
}

export interface AppProps extends WithStyles<typeof visualisationConfigurationComponentStyle> {
    deviceId?: string;
    visualisationId?: string;
    visualisation?: VisualisationInfo;
    deviceDefinitionVersions: DeviceDefinitionVersionInfo[];
    receivedDefinitionsResponse: Record<string, ReceivedDefinitionsResponse>;

    closeModal?: (modalKey: ModalKey) => void;
    startTime?: Date;
    endTime?: Date;
    sternumQuery?: SternumQuery;
    displayViewForDeviceDefinition?: boolean;

    timeSelectedOption: TimeSelectOption;

    lookedUpEntityId?: string;

    visualisationType?: VisualisationType;
    dashboardId?: string;

    onVisualisationCreated?: (visualisationId: string) => void;
    onVisualisationUpdated?: (visualisationId: string) => void;
    onVisualisationDeleted?: (visualisationId: string) => void;

    theme?;
    isFreeUser: boolean;
    disableEditing?: boolean;
}

class VisualisationConfigurationComponent extends React.Component<AppProps, AppState> {
    private appliedSternumQueryChangeIdentifierCounter: number = 0;

    constructor(props: AppProps) {
        super(props);

        const sternumQuery = this.props.sternumQuery || SternumQuery.getEmptyQuery(false);

        this.state = {
            startTime: this.props.startTime,
            endTime: this.props.endTime,

            displayName: this.props.dashboardId ? "Glance" : "Visualization",
            visualizationType: this.props.visualisationType || VisualisationType.TIME_SERIES,

            filterSectionExpanded: true,

            errorLoadingReceivedDefinitions: false,
            receivedDefinitionsResponse: this.props.receivedDefinitionsResponse,
            ...this.getArgumentAndTraceDefinitionsFromReceivedDefinitions(this.props.receivedDefinitionsResponse),

            selectedDataSource: "DEFAULT",

            dataVisualisationConfigurations: this.props.visualisationId
                ? []
                : [
                      getDefaultDataVisualisationConfiguration(
                          "DEFAULT",
                          undefined,
                          sternumQuery,
                          this.props.startTime,
                          this.props.endTime,
                          undefined,
                          [this.props.deviceDefinitionVersions[0].entityId],
                          [this.props.deviceDefinitionVersions[0].getVersionName()]
                      ),
                  ],
            groupBy: null,

            isStacked: false,
            isLogarithmicScale: false,
            isEventsExplorerExpanded: false,
            refreshComponentsId: 1,
        };
    }

    async componentDidMount() {
        const { visualisation, visualisationId } = this.props;

        if (visualisation) {
            this.setState({
                loadingVisualisation: false,
                errorLoadingVisualisation: false,
                visualisation: visualisation,

                displayName: visualisation.displayName,
                isStacked: visualisation.isStacked,
                isLogarithmicScale: visualisation.isLogarithmicScale,
                visualizationType: visualisation.configuration.visualisationType,
                dataVisualisationConfigurations: visualisation.configuration.dataSources.map((dataSource) => {
                    const deviceDefinitionVersions = this.props.deviceDefinitionVersions.filter((version) =>
                        dataSource.deviceDefinitionVersionIds.includes(version.entityId)
                    );

                    return getDefaultDataVisualisationConfiguration(
                        dataSource.dataSourceKey,
                        undefined,
                        dataSource.sternumQuery,
                        this.props.startTime,
                        this.props.endTime,
                        dataSource,
                        dataSource.deviceDefinitionVersionIds,
                        deviceDefinitionVersions.map((version) => version.getVersionName())
                    );
                }),
                groupBy: visualisation.configuration.groupBy,
            });
        } else if (visualisationId) {
            await this.loadVisualisation(visualisationId);
        }

        await this.loadTraceAndArgumentsDefinitions();
    }

    componentDidUpdate(prevProps: AppProps, prevState: AppState) {
        if (this.state.dataVisualisationConfigurations.length !== prevState.dataVisualisationConfigurations.length) {
            if (
                !this.state.dataVisualisationConfigurations.find(
                    (dvc) => dvc.dataSourceKey === this.state.selectedDataSource
                )
            ) {
                // if no data source keys presented in the props just use DEFAULT
                this.setState({
                    selectedDataSource: this.state.dataVisualisationConfigurations[0]?.dataSourceKey ?? "DEFAULT",
                });
            }
        }

        if (this.props.startTime !== prevProps.startTime || this.props.endTime !== prevProps.endTime) {
            this.setState({ receivedDefinitionsResponse: this.props.receivedDefinitionsResponse });
        }
    }

    private setSelectedDataSource = (key: string) => {
        this.setState({ selectedDataSource: key });
    };

    private handleEventsExplorerExpandToggle = () => {
        this.setState({ isEventsExplorerExpanded: !this.state.isEventsExplorerExpanded });
    };

    render() {
        const { classes } = this.props;

        const loadingVisualisation: boolean = this.props.visualisationId && !this.state.visualisation;

        const uniqueCountVisualisation =
            this.state.dataVisualisationConfigurations.length > 0 &&
            this.state.dataVisualisationConfigurations[0].aggregationFunctionType ===
                AggregationFunctionType.UNIQUE_COUNT
                ? this.state.dataVisualisationConfigurations[0]
                : undefined;

        return (
            <UniqueCountTableDataProvider dataVisualisationConfiguration={uniqueCountVisualisation}>
                <div
                    role="presentation"
                    aria-label="visualisation configuration modal content"
                    className={classNames(classes.root, classes.removeFocus)}
                    tabIndex={-1}
                >
                    <VisualisationConfigurationHeader
                        loading={loadingVisualisation}
                        displayName={this.state.displayName}
                        timeSelectedOption={this.props.timeSelectedOption}
                        onDisplayNameChanged={(displayName) => this.onDisplayNameChanged(displayName)}
                        onTimeRangeSelected={(selectedOption) => this.onTimeRangeSelected(selectedOption)}
                        onCloseClicked={() => this.onCloseClicked()}
                        isHeaderEditable={!this.props.disableEditing && !!this.props.dashboardId}
                    />
                    <div className={classNames(classes.content)}>
                        {!this.props.disableEditing && (
                            <div
                                className={
                                    this.state.isEventsExplorerExpanded
                                        ? classes.displayNone
                                        : classNames(classes.flexVMiddle, classes.flexSpaceBetween)
                                }
                            >
                                <VisualisationConfigurationTypeSelection
                                    multiDataSources={this.state.dataVisualisationConfigurations.length > 1}
                                    visualisationType={this.state.visualizationType}
                                    onVisualisationTypeSelected={(visualisationType) => {
                                        this.selectVisualizationType(visualisationType);
                                    }}
                                    dataVisualisationConfigurations={this.state.dataVisualisationConfigurations}
                                />
                            </div>
                        )}

                        <div
                            aria-label="graph wrapper"
                            className={
                                this.state.isEventsExplorerExpanded
                                    ? classes.displayNone
                                    : classNames(
                                          classes.graphWrapper,
                                          this.state.visualizationType === VisualisationType.GEOMAP && "no-padding"
                                      )
                            }
                        >
                            <VisualisationConfigurationPresentationTitle
                                visualisationType={this.state.visualizationType}
                                onDisplayTypeSelected={(visualisationType) => {
                                    this.selectVisualizationType(visualisationType);
                                }}
                                onStackToggleChange={(status: boolean) => {
                                    this.setState({ isStacked: status });
                                }}
                                onLogarithmicScaleToggleChange={(status: boolean) => {
                                    this.setState({ isLogarithmicScale: status });
                                }}
                                multiDataSources={this.state.dataVisualisationConfigurations.length > 1}
                                isStacked={this.state.isStacked}
                                isLogarithmicScale={this.state.isLogarithmicScale}
                            />

                            <VisualisationConfigurationPresentation
                                refreshComponentId={this.state.refreshComponentsId}
                                visualisationType={this.state.visualizationType}
                                lookedUpEntityId={
                                    this.props.isFreeUser
                                        ? this.props.visualisationId
                                        : this.props.deviceId || this.getClientId()
                                }
                                startTime={this.state.startTime}
                                endTime={this.state.endTime}
                                dataVisualisationConfigurations={this.state.dataVisualisationConfigurations}
                                dashboardDeviceDefinitionVersions={this.props.deviceDefinitionVersions}
                                receivedDefinitionsResponse={this.state.receivedDefinitionsResponse}
                                groupBy={this.state.groupBy}
                                amountOfLoaders={25}
                                amountOfGraphPointsToDisplay={61}
                                isStacked={this.state.isStacked}
                                isLogarithmicScale={this.state.isLogarithmicScale}
                                displayTooltipDataPerDeviceDefinitionVersion
                            />
                        </div>

                        <div className={classes.eventsTableAndSourcesContainer}>
                            <VisualisationConfigurationDataSources
                                // TODO: change to client id instead
                                lookedUpEntityId={this.props.deviceId || this.getDeviceDefinitionVersionIds()[0]}
                                dataVisualisationConfigurations={this.state.dataVisualisationConfigurations}
                                groupBy={this.state.groupBy}
                                loading={loadingVisualisation}
                                error={this.state.errorLoadingReceivedDefinitions}
                                visualisationType={this.state.visualizationType}
                                traceDefinitionFields={this.state.traceDefinitionFields}
                                argumentDefinitionFields={this.state.argumentDefinitionFields}
                                onVisualisationConfigurationDeleted={(dataSourceKey: string) =>
                                    this.onDataSourceDeleted(dataSourceKey)
                                }
                                receivedDefinitionsResponse={this.state.receivedDefinitionsResponse}
                                onDataVisualisationConfigurationChanged={(key, dataVisualisationConfiguration) =>
                                    this.onDataSourceChanged(key, dataVisualisationConfiguration)
                                }
                                onGroupByChanged={this.onGroupByChanged}
                                onDataSourceAdded={() => this.onDataSourceAdded()}
                                onFilterApplied={(sternumQuery, dataVisualisationConfiguration) => {
                                    this.handleFilterApplied(sternumQuery, dataVisualisationConfiguration);
                                }}
                                startTime={this.state.startTime ? this.state.startTime.getTime() : null}
                                endTime={this.state.endTime ? this.state.endTime.getTime() : null}
                                displayViewForDeviceDefinition={this.props.displayViewForDeviceDefinition}
                                selectedDataSource={this.state.selectedDataSource}
                                onDataSourceSelect={this.setSelectedDataSource}
                                deviceDefinitionVersions={this.props.deviceDefinitionVersions}
                                onDeviceDefinitionVersionChange={this.handleDeviceDefinitionVersionChange}
                                disableEditing={this.props.disableEditing}
                            />

                            <VisualisationConfigurationEventsExplorer
                                key={this.state.refreshComponentsId}
                                deviceId={this.props.deviceId}
                                dataVisualisationConfigurations={this.state.dataVisualisationConfigurations}
                                selectedDataSource={this.state.selectedDataSource}
                                selectedTimeSelectionType={this.props.timeSelectedOption.selectionType}
                                loading={loadingVisualisation}
                                error={this.state.errorLoadingReceivedDefinitions}
                                displayViewForDeviceDefinition={this.props.displayViewForDeviceDefinition}
                                lookedUpEntityId={this.getDeviceDefinitionVersionIds()}
                                isEventsExplorerExpanded={this.state.isEventsExplorerExpanded}
                                onEventsExplorerExpandToggle={this.handleEventsExplorerExpandToggle}
                            />
                        </div>
                    </div>
                    {!this.props.disableEditing && !this.props.isFreeUser && (
                        <VisualisationConfigurationFooter
                            visualisationType={this.state.visualizationType}
                            dataVisualisationConfigurations={this.state.dataVisualisationConfigurations}
                            groupBy={this.state.groupBy}
                            visualisationId={this.props.visualisationId}
                            loadingVisualisation={loadingVisualisation}
                            displayName={this.state.displayName}
                            isStacked={this.state.isStacked}
                            isLogarithmicScale={this.state.isLogarithmicScale}
                            dashboardId={this.props.dashboardId}
                            deviceDefinitionId={this.props.deviceDefinitionVersions[0]?.deviceDefinition?.entityId}
                            onVisualisationCreated={this.props.onVisualisationCreated}
                            onVisualisationUpdated={this.props.onVisualisationUpdated}
                        />
                    )}
                </div>
            </UniqueCountTableDataProvider>
        );
    }

    private getArgumentAndTraceDefinitionsFromReceivedDefinitions = (
        deviceDefinitionVersionToReceivedDefinitionsMap: Record<string, ReceivedDefinitionsResponse>
    ) => {
        const traceDefinitionFields: Record<string, AggregatedTraceDefinition[]> = {};
        const argumentDefinitionFields: Record<string, SternumQueryField[]> = {};

        Object.keys(deviceDefinitionVersionToReceivedDefinitionsMap).forEach((version) => {
            traceDefinitionFields[version] = SternumUtils.getTraceDefinitionFields(
                true,
                true,
                true,
                true,
                deviceDefinitionVersionToReceivedDefinitionsMap[version].receivedDefinitions,
                false
            ).map((traceDefinitionField) => {
                return {
                    ...traceDefinitionField,
                    label: traceDefinitionField.isSpecialField
                        ? getSpecialFieldSelectDisplay(this.props.classes, traceDefinitionField.label)
                        : traceDefinitionField.label,
                };
            });

            argumentDefinitionFields[version] = SternumUtils.getQueriedFieldsFromReceivedDefinitions(
                deviceDefinitionVersionToReceivedDefinitionsMap[version].receivedArguments
            );
        });

        return { traceDefinitionFields, argumentDefinitionFields };
    };

    private loadTraceAndArgumentsDefinitions = async () => {
        try {
            const deviceDefinitionVersionToArgumentDefinitionsMap: Record<string, ReceivedDefinitionsResponse> = {};

            await Promise.all(
                this.props.deviceDefinitionVersions.map(async (version) => {
                    const receivedDefinitionsResponse =
                        await ServiceWire.getEventsApiService().getAllReceivedDefinitions(
                            version.entityId,
                            this.state.startTime.getTime(),
                            this.state.endTime.getTime()
                        );

                    deviceDefinitionVersionToArgumentDefinitionsMap[version.entityId] = receivedDefinitionsResponse;
                })
            );

            this.setState({
                receivedDefinitionsResponse: deviceDefinitionVersionToArgumentDefinitionsMap,
                ...this.getArgumentAndTraceDefinitionsFromReceivedDefinitions(
                    deviceDefinitionVersionToArgumentDefinitionsMap
                ),
            });
        } catch (err) {
            this.setState({ errorLoadingReceivedDefinitions: true });
        }
    };

    private handleDeviceDefinitionVersionChange = (deviceDefinitionVersionIds: string[]) => {
        if (deviceDefinitionVersionIds.length === 0) return;

        this.setState({
            dataVisualisationConfigurations: this.state.dataVisualisationConfigurations.map((config) => {
                if (config.dataSourceKey === this.state.selectedDataSource) {
                    const deviceDefinitionVersionNames = deviceDefinitionVersionIds.map((versionId) => {
                        const deviceDefinitionVersion = this.props.deviceDefinitionVersions.find(
                            (version) => version.entityId === versionId
                        );

                        return deviceDefinitionVersion.getVersionName();
                    });

                    return {
                        ...config,
                        deviceDefinitionVersionIds: deviceDefinitionVersionIds,
                        deviceDefinitionVersionNames,
                        graphLabel: getTimeSeriesGraphLabel(
                            config.aggregationFunctionType,
                            config.aggregatedTraceDefinitionDisplayName,
                            config.aggregatedArgumentDefinitionDisplayName,
                            deviceDefinitionVersionNames
                        ),
                    };
                }

                return config;
            }),
        });
    };

    private onCloseClicked() {
        this.props.closeModal(new ModalKey(ModalType.VisualizationCreation, "VisualizationCreation"));
    }

    private onDisplayNameChanged(displayName: string) {
        this.setState({
            displayName: displayName,
        });
    }

    private onDataSourceAdded() {
        if (this.state.dataVisualisationConfigurations?.length === 5) {
            return;
        }

        let sourceIndex = 1;
        if (
            this.state.dataVisualisationConfigurations.length > 1 ||
            first(this.state.dataVisualisationConfigurations)?.dataSourceKey !== "DEFAULT"
        ) {
            const lastDataSourceKey =
                parseInt(last(this.state.dataVisualisationConfigurations)?.dataSourceKey, 10) || 0;
            sourceIndex = lastDataSourceKey + 1;
        }

        this.setState({
            selectedDataSource: sourceIndex.toString(),
            dataVisualisationConfigurations: [
                ...this.state.dataVisualisationConfigurations,
                getDefaultDataVisualisationConfiguration(
                    sourceIndex.toString(),
                    this.state.dataVisualisationConfigurations && this.state.dataVisualisationConfigurations.length
                        ? this.state.dataVisualisationConfigurations[
                              this.state.dataVisualisationConfigurations.length - 1
                          ].color
                        : undefined,
                    SternumQuery.getEmptyQuery(false),
                    this.state.startTime,
                    this.state.endTime,
                    undefined,
                    [this.props.deviceDefinitionVersions[0].entityId],
                    [this.props.deviceDefinitionVersions[0].getVersionName()]
                ),
            ],
        });
    }

    private onDataSourceChanged(key: string, dataVisualisationConfiguration: UIDataVisualisationConfiguration) {
        const previousDataVisualisationConfiguration = this.state.dataVisualisationConfigurations.find(
            (configuration) => configuration.dataSourceKey === dataVisualisationConfiguration.dataSourceKey
        );

        const hasColorChanged = previousDataVisualisationConfiguration.color !== dataVisualisationConfiguration.color;
        const isGeomapVisualisation = this.state.visualizationType === VisualisationType.GEOMAP;

        this.setState((prevState) => ({
            dataVisualisationConfigurations: prevState.dataVisualisationConfigurations.map((configuration) => {
                if (configuration.dataSourceKey === key) {
                    return dataVisualisationConfiguration;
                }

                if (hasColorChanged && isGeomapVisualisation) {
                    return { ...configuration, color: dataVisualisationConfiguration.color };
                }

                return configuration;
            }),
        }));
    }

    private onGroupByChanged = (newGroupBy: VisualisationDataSourceGroupBy) => {
        this.setState({ groupBy: newGroupBy });
    };

    private onDataSourceDeleted(dataSourceKey: string) {
        const clonedConfigurations = this.state.dataVisualisationConfigurations.filter(
            (config) => config.dataSourceKey !== dataSourceKey
        );

        const selectedDataSourceStillExists: boolean = clonedConfigurations.some(
            (currentConfiguration) => currentConfiguration.dataSourceKey === this.state.selectedDataSource
        );

        if (!selectedDataSourceStillExists) {
            this.setState({
                dataVisualisationConfigurations: clonedConfigurations,
                selectedDataSource: clonedConfigurations[0]?.dataSourceKey,
            });
        } else {
            this.setState({
                dataVisualisationConfigurations: clonedConfigurations,
            });
        }
    }

    private onTimeRangeSelected(selectedTimeOption: TimeSelectOption) {
        const clonedDataVisualisationConfigurations: UIDataVisualisationConfiguration[] =
            this.state.dataVisualisationConfigurations.map((vizConfiguration: UIDataVisualisationConfiguration) => {
                return {
                    dataSourceKey: vizConfiguration.dataSourceKey,
                    graphLabel: vizConfiguration.graphLabel,
                    dataSourceLabel: vizConfiguration.dataSourceLabel,
                    percentile: vizConfiguration.percentile,
                    color: vizConfiguration.color,
                    sternumQuery: vizConfiguration.sternumQuery.clone(),
                    sternumQueryChanged: vizConfiguration.sternumQueryChanged,
                    appliedSternumQuery: vizConfiguration.appliedSternumQuery.clone(),
                    deviceDefinitionVersionIds: vizConfiguration.deviceDefinitionVersionIds,
                    deviceDefinitionVersionNames: vizConfiguration.deviceDefinitionVersionNames,
                    appliedSternumQueryChangeIdentifier: vizConfiguration.appliedSternumQueryChangeIdentifier,
                    entitiesFilter: vizConfiguration.entitiesFilter.clone(),
                    aggregationFunctionType: vizConfiguration.aggregationFunctionType,
                    aggregatedTraceDefinitionId: vizConfiguration.aggregatedTraceDefinitionId,
                    aggregatedTraceDefinitionDisplayName: vizConfiguration.aggregatedTraceDefinitionDisplayName,
                    aggregatedArgumentDefinitionId: vizConfiguration.aggregatedArgumentDefinitionId,
                    aggregatedArgumentDefinitionDisplayName: vizConfiguration.aggregatedArgumentDefinitionDisplayName,
                    uniqueColumns: vizConfiguration.uniqueColumns,
                };
            });

        const updatedDataVisualisationConfigurations = clonedDataVisualisationConfigurations.map((visualization) => {
            visualization.entitiesFilter.createdFrom = selectedTimeOption.timeRange
                ? selectedTimeOption.timeRange.start.getTime()
                : this.state.startTime.getTime();
            visualization.entitiesFilter.createdTo = selectedTimeOption.timeRange
                ? selectedTimeOption.timeRange.end.getTime()
                : this.state.endTime.getTime();
            return visualization;
        });

        this.setState(
            {
                dataVisualisationConfigurations: updatedDataVisualisationConfigurations,
                startTime: selectedTimeOption.timeRange ? selectedTimeOption.timeRange.start : this.state.startTime,
                endTime: selectedTimeOption.timeRange ? selectedTimeOption.timeRange.end : this.state.endTime,
                refreshComponentsId: this.state.refreshComponentsId + 1,
            },
            () => this.loadTraceAndArgumentsDefinitions()
        );
    }

    private getClientId = () => {
        const clientId = ServiceWire.getClientsService().getSelectedClientId();
        return clientId;
    };

    private getDeviceDefinitionVersionIds = () => {
        const selectedDataSource = this.state.dataVisualisationConfigurations.find(
            (dataSource) => dataSource.dataSourceKey === this.state.selectedDataSource
        );

        return (
            selectedDataSource?.deviceDefinitionVersionIds || [this.props.deviceDefinitionVersions[0]?.entityId] || [
                this.props.lookedUpEntityId,
            ]
        );
    };

    private handleFilterApplied(
        sternumQuery: SternumQuery,
        dataVisualisationConfiguration: UIDataVisualisationConfiguration
    ) {
        const clonedDataVisualisationConfigurations: UIDataVisualisationConfiguration[] = [
            ...this.state.dataVisualisationConfigurations,
        ];

        const index: number = Utils.findFirstIndex(
            clonedDataVisualisationConfigurations,
            (currentConfiguration) =>
                currentConfiguration.dataSourceKey === dataVisualisationConfiguration.dataSourceKey
        );

        this.appliedSternumQueryChangeIdentifierCounter = this.appliedSternumQueryChangeIdentifierCounter + 1;

        clonedDataVisualisationConfigurations[index] = {
            ...clonedDataVisualisationConfigurations[index],
            appliedSternumQuery: sternumQuery,
            appliedSternumQueryChangeIdentifier: this.appliedSternumQueryChangeIdentifierCounter,
            entitiesFilter: new SternumDeviceEventsFilter(
                null,
                null,
                this.state.startTime ? this.state.startTime.getTime() : null,
                this.state.endTime ? this.state.endTime.getTime() : null,
                null,
                null,
                null,
                null,
                sternumQuery,
                dataVisualisationConfiguration.aggregatedTraceDefinitionId
                    ? [dataVisualisationConfiguration.aggregatedTraceDefinitionId]
                    : undefined
            ),
        };

        this.setState({
            dataVisualisationConfigurations: clonedDataVisualisationConfigurations,
        });
    }

    private selectVisualizationType(visualizationType: VisualisationType) {
        if (this.state.visualizationType !== visualizationType) {
            this.setState({
                visualizationType: visualizationType,
            });
        }
    }

    private async loadVisualisation(visualisationId: string) {
        try {
            this.setState({
                loadingVisualisation: true,
                errorLoadingVisualisation: false,
                visualisation: undefined,
            });

            const visualisation: VisualisationInfo = await ServiceWire.getVisualisationApiService().getVisualisation(
                visualisationId
            );
            this.setState({
                loadingVisualisation: false,
                errorLoadingVisualisation: false,
                visualisation: visualisation,

                displayName: visualisation.displayName,
                isStacked: visualisation.isStacked,
                isLogarithmicScale: visualisation.isLogarithmicScale,
                visualizationType: visualisation.configuration.visualisationType,
                dataVisualisationConfigurations: visualisation.configuration.dataSources.map((dataSource) => {
                    const deviceDefinitionVersions = this.props.deviceDefinitionVersions.filter((version) =>
                        dataSource.deviceDefinitionVersionIds.includes(version.entityId)
                    );

                    return getDefaultDataVisualisationConfiguration(
                        dataSource.dataSourceKey,
                        undefined,
                        dataSource.sternumQuery,
                        this.props.startTime,
                        this.props.endTime,
                        dataSource,
                        dataSource.deviceDefinitionVersionIds,
                        deviceDefinitionVersions.map((version) => version.getVersionName())
                    );
                }),
                groupBy: visualisation.configuration.groupBy,
            });
        } catch {
            this.setState({
                loadingVisualisation: false,
                errorLoadingVisualisation: true,
            });
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(visualisationConfigurationComponentStyle, { withTheme: true })(VisualisationConfigurationComponent));
