import { UserState } from "../../state/UserState";
import ActionType from "../ActionType";
import { SetUserAction } from "./SetUserAction";

type userActions = SetUserAction;

/*
 * Hack: in order to signal that triggers hit list should refresh we just create a new `shouldRefresh` object and
 * let redux detect that a change have been made
 */
const userReducer = (state: UserState, action: userActions) => {
    switch (action.type) {
        case ActionType.SetUserAction:
            return {
                entity: action.user,
            };

        default:
            return state;
    }
};

export default userReducer;
