import { Button, CircularProgress, Divider, IconButton, Tooltip, Typography, withStyles } from "@material-ui/core";
import { WithStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import HashMap from "../../../lib/infra/HashMap";
import ServiceWire from "../../../lib/services/ServiceWire";
import DeviceDefinitionInfo from "../../../lib/state/DeviceDefinitionInfo";
import DeviceDefinitionLibraryPartial from "../../../lib/state/DeviceDefinitionLibraryPartial";
import DeviceDefinitionVersionInfo from "../../../lib/state/DeviceDefinitionVersionInfo";
import { GlobalState } from "../../../lib/state/GlobalState";
import ServerEntityType from "../../../lib/state/ServerEntityType";
import ConfirmationDialog from "../../../shared_components/ConfirmationDialog/ConfirmationDialog";
import ExplanationComponent from "../../ExplanationComponent/ExplanationComponent";
import LibrarySimpleDialog from "../LibrarySimapleDialog/LibrariesSimpleDialog";
import deviceDefinitionLibraryStepStyle from "./DeviceDefinitionLibraryStepStyle";

interface AppState {
    libraryDialogStatus: boolean;
    deviceDefinition: DeviceDefinitionInfo;
    selectedDeviceDefinitionLibrary: any;
    libraryName: string;
    libraryVersion: string;
    serverError: boolean;
    isDisabledMode: boolean;
    openConfirmationDialog: boolean;
    libraryToDelete: any;
    isError: boolean;
    onOperationMap: HashMap<boolean>;
}

export interface AppProps extends WithStyles<typeof deviceDefinitionLibraryStepStyle> {
    deviceDefinition: DeviceDefinitionInfo;
    deviceDefinitionVersion?: DeviceDefinitionVersionInfo;
    forceCRUD?: boolean;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {};
};

class DeviceDefinitionLibraryStep extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);
        // Initializing the state to default.

        this.state = {
            deviceDefinition: this.props.deviceDefinition,
            libraryDialogStatus: false,
            selectedDeviceDefinitionLibrary: null,
            libraryName: null,
            libraryVersion: null,
            serverError: false,
            isDisabledMode:
                (!!this.props.forceCRUD && this.props.forceCRUD && this.props.deviceDefinitionVersion.isProduction()) ||
                !ServiceWire.getAuthorizationService().canEdit(ServerEntityType.DEVICE_DEFINITION_LIBRARY)
                    ? true
                    : false,
            openConfirmationDialog: false,
            libraryToDelete: null,
            isError: false,
            onOperationMap: new HashMap<boolean>(),
        };
    }

    /*
     *  Check for duplicate libraries
     */
    checkIfDeviceDefinitionLibraryExists = (
        libraryName: string,
        libraryVersion: string,
        libraryVendor: string,
        librarySource: string
    ) => {
        const index = this.state.deviceDefinition.findDeviceDefinitionLibraryIndex(
            new DeviceDefinitionLibraryPartial(libraryName, libraryVersion, libraryVendor, librarySource)
        );
        return index > -1 ? true : false;
    };

    /*
     *  Check if operation should be taken inplace (it's setup operation) or send request to server.
     */
    isForceCRUD = (): boolean => {
        return this.props.forceCRUD && this.props.forceCRUD === true;
    };

    /*
     *  Handle close library simple dialog
     */
    private librarySaveDialog = async (
        libraryName: string,
        libraryVersion: string,
        libraryVendor: string,
        librarySource: string,
        keepDialogOpen: boolean,
        selectedDeviceDefinitionLibrary?: any
    ) => {
        // Send request to server
        if (this.isForceCRUD()) {
            try {
                this.setState({ isError: false });
                await this.UpdateOrCreateLibrary(
                    libraryName,
                    libraryVersion,
                    libraryVendor,
                    this.props.deviceDefinitionVersion.entityId,
                    librarySource,
                    selectedDeviceDefinitionLibrary
                );
                this.closeDialog(keepDialogOpen);
            } catch (err) {
                this.setState({ isError: true });
            }
        } else {
            this.UpdateOrCreateInPlace(
                libraryName,
                libraryVersion,
                libraryVendor,
                librarySource,
                selectedDeviceDefinitionLibrary
            );
            this.closeDialog(keepDialogOpen);
        }
    };

    /*
     *  Handle cancel button
     */
    private closeDialog = (keepDialogOpen: boolean) => {
        this.setState({
            libraryDialogStatus: keepDialogOpen ? true : false,
            selectedDeviceDefinitionLibrary: null,
            deviceDefinition: this.state.deviceDefinition,
            serverError: false,
        });
    };

    /*
     *  Handle delete device definition library from device
     */
    private removeDeviceDefinitionLibrary = async (event) => {
        if (this.isForceCRUD()) {
            const deleteResponse = await ServiceWire.getSternumService().deleteDeviceDefinitionLibrary(
                this.state.libraryToDelete.entityId,
                this.props.deviceDefinitionVersion.entityId
            );
            if (deleteResponse) {
                // Delete from list only if managed to delete from server.
                this.removeDeviceDefinitionLibraryInPlace(this.state.libraryToDelete);
            }
        } else {
            this.removeDeviceDefinitionLibraryInPlace(this.state.libraryToDelete);
        }
        this.closeDeleteConfirmationDialog();
    };

    /*
     *  Open edit dialog
     */
    private handleEditClick = (selectedLib) => (event) => {
        this.setState({
            selectedDeviceDefinitionLibrary: selectedLib,
            libraryDialogStatus: true,
        });
    };

    /*
     *  Handle delete click
     */
    private removeDeviceDefinitionLibraryInPlace = (selectedLib) => {
        this.state.deviceDefinition.removeDeviceDefinitionLibrary(selectedLib);
        this.setState({
            deviceDefinition: this.state.deviceDefinition,
        });
    };

    /**
     * Open delete confirmation dialog
     */

    private openDeleteConfirmationDialog = (libraryToDelete) => {
        if (this.props.forceCRUD) {
            // If it's delete on existing library, show loading bar
            this.setState({
                onOperationMap: HashMap.copyAndPut(this.state.onOperationMap, libraryToDelete.entityId, true),
            });
        }
        this.setState({ openConfirmationDialog: true, libraryToDelete: libraryToDelete });
    };

    /**
     * Close confirmation dialog
     */

    private closeDeleteConfirmationDialog = () => {
        if (this.props.forceCRUD) {
            // If delete operation is canceled, remove the loading icon
            this.setState({
                onOperationMap: HashMap.copyAndRemove(this.state.onOperationMap, this.state.libraryToDelete.entityId),
            });
        }

        this.setState({ openConfirmationDialog: false, libraryToDelete: null });
    };

    /*
     *  Create or update Library definition in the server.
     */
    private async UpdateOrCreateLibrary(
        libraryName: string,
        libraryVersion: string,
        libraryVendor: string,
        deviceDefinitionVersionId: string,
        librarySource?: string,
        selectedLibrary?: any
    ) {
        let deviceDefinitionLibraryResponse = null;
        if (selectedLibrary) {
            // Update existing DeviceDefinitionLibrary
            selectedLibrary.setLibraryName(libraryName);
            selectedLibrary.setLibraryVersion(libraryVersion);
            selectedLibrary.setLibraryVendor(libraryVendor);
            selectedLibrary.libraryVersion = libraryVersion;
            if (librarySource) {
                selectedLibrary.librarySource = librarySource;
            }
            // Send update request
            deviceDefinitionLibraryResponse = await ServiceWire.getSternumService().updateDeviceDefinitionLibrary(
                deviceDefinitionVersionId,
                selectedLibrary
            );
            if (deviceDefinitionLibraryResponse && deviceDefinitionLibraryResponse.length > 0) {
                this.updateCurrentDisplayList(deviceDefinitionLibraryResponse[0], selectedLibrary.entityId);
            }
        } else {
            // Create new DeviceDefinitionLibrary
            const deviceDefinitionLibraryObject = new DeviceDefinitionLibraryPartial(
                libraryName,
                libraryVersion,
                libraryVendor,
                librarySource
            );
            // Send create request
            deviceDefinitionLibraryResponse = await ServiceWire.getSternumService().createDeviceDefinitionLibrary(
                deviceDefinitionVersionId,
                deviceDefinitionLibraryObject
            );
            if (deviceDefinitionLibraryResponse && deviceDefinitionLibraryResponse.length > 0) {
                this.updateCurrentDisplayList(deviceDefinitionLibraryResponse[0]);
            }
        }
    }

    /*
     *  Help function for updating current traces list.
     */
    private updateCurrentDisplayList = (deviceDefinitionLibrary: any, currentEntityID?: string) => {
        const libraryIndex = this.state.deviceDefinition.findDeviceDefinitionLibraryIndex(
            deviceDefinitionLibrary,
            currentEntityID
        );
        if (libraryIndex < 0) {
            // add new Library
            this.state.deviceDefinition.deviceDefinitionLibraries.push(deviceDefinitionLibrary);
        } else {
            // update existing one
            this.state.deviceDefinition.deviceDefinitionLibraries.splice(libraryIndex, 1, deviceDefinitionLibrary);
        }
    };

    /*
     *  Create or update Trace definition in place.
     */
    private UpdateOrCreateInPlace(
        libraryName: string,
        libraryVersion: string,
        libraryVendor: string,
        librarySource?: string,
        selectedDeviceDefinitionLibrary?: any
    ) {
        if (selectedDeviceDefinitionLibrary) {
            // Update existing Library definition
            selectedDeviceDefinitionLibrary.setLibraryName(libraryName);
            selectedDeviceDefinitionLibrary.setLibraryVersion(libraryVersion);
            selectedDeviceDefinitionLibrary.librarySource = librarySource;
            this.updateCurrentDisplayList(selectedDeviceDefinitionLibrary);
        } else {
            const newDeviceDefinitionLibrary = new DeviceDefinitionLibraryPartial(
                libraryName,
                libraryVersion,
                libraryVendor,
                librarySource
            );
            this.updateCurrentDisplayList(newDeviceDefinitionLibrary);
        }
    }

    render() {
        const { classes } = this.props;

        const definitionsExists = (
            <div className={classNames(classes.marginBottomXLarge, classes.flexRow)}>
                <Typography variant={"subtitle2"}>3ʳᵈ Party Components</Typography>
                <ExplanationComponent>
                    <Typography variant="body2">
                        List here all the device's 3ʳᵈ Party components to receive continuous alerts on security issues,
                        CVE’s, required patches and new available versions. Common 3ʳᵈ Party components may include
                        operating systems, open-source libraries, closed-source libraries etc. Use the "Add 3ʳᵈ Party"
                        button to add a new component to monitor.
                    </Typography>
                </ExplanationComponent>
            </div>
        );

        return (
            <div className={classNames(classes.flexColumn, classes.flexCenter, classes.flexVMiddle, classes.root)}>
                {definitionsExists}

                <div
                    role="presentation"
                    aria-label="added 3rd party list"
                    className={classNames(
                        classes.flexColumn,
                        classes.fullWidth,
                        classes.librariesList,
                        classes.marginBottomMedium
                    )}
                >
                    {/* libraries list */}
                    {this.state.deviceDefinition.deviceDefinitionLibraries.map((deviceDefinitionLibraryIter) => {
                        return (
                            <div
                                role="presentation"
                                aria-label="added 3rd party list item"
                                className={classNames(classes.flexColumn)}
                            >
                                <div
                                    className={classNames(
                                        classes.flexSpaceBetween,
                                        classes.flexVMiddle,
                                        classes.libraryItem
                                    )}
                                >
                                    <Typography noWrap variant="subtitle2" className={classNames(classes.displayName)}>
                                        {deviceDefinitionLibraryIter.getLibraryName()}:{" "}
                                        {deviceDefinitionLibraryIter.getLibraryVersion()}
                                    </Typography>
                                    {this.state.onOperationMap.containsKey(deviceDefinitionLibraryIter.entityId) ? (
                                        <div className={classes.marginRight}>
                                            <CircularProgress size={16} className={classes.marginRightXs} />
                                        </div>
                                    ) : (
                                        <div role="presentation" aria-label="actions container">
                                            {/* Edit Action*/}
                                            <Tooltip title="Edit 3rd Party" classes={{ tooltip: classes.toolTip }}>
                                                <IconButton
                                                    disabled={this.state.isDisabledMode}
                                                    aria-label="edit"
                                                    className={classes.smallIconButton}
                                                    onClick={this.handleEditClick(deviceDefinitionLibraryIter)}
                                                >
                                                    <EditIcon
                                                        fontSize="default"
                                                        color={this.state.isDisabledMode ? "disabled" : "primary"}
                                                    />
                                                </IconButton>
                                            </Tooltip>

                                            {/** Delete action */}
                                            <Tooltip title="Delete 3rd Party" classes={{ tooltip: classes.toolTip }}>
                                                <IconButton
                                                    disabled={this.state.isDisabledMode}
                                                    aria-label="delete"
                                                    className={classes.smallIconButton}
                                                    onClick={() =>
                                                        this.openDeleteConfirmationDialog(deviceDefinitionLibraryIter)
                                                    }
                                                >
                                                    <DeleteIcon
                                                        fontSize="small"
                                                        color={this.state.isDisabledMode ? "disabled" : "error"}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    )}
                                </div>
                                <Divider />
                            </div>
                        );
                    })}
                </div>

                <div className={classNames(classes.fullWidth, classes.flexEnd)}>
                    <Button
                        disabled={this.state.isDisabledMode}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            this.setState({
                                libraryDialogStatus: true,
                            });
                        }}
                    >
                        Add 3ʳᵈ Party
                    </Button>
                </div>

                {/* Make sure library dialog is created each time the state is changed */}
                {(() => {
                    if (this.state.libraryDialogStatus) {
                        return (
                            <LibrarySimpleDialog
                                open={this.state.libraryDialogStatus}
                                existingDeviceDefinitionLibrary={this.state.selectedDeviceDefinitionLibrary}
                                onClose={this.librarySaveDialog}
                                duplicationCheck={this.checkIfDeviceDefinitionLibraryExists}
                                onCancel={this.closeDialog}
                            />
                        );
                    }
                })()}
                {this.state.libraryToDelete && (
                    <ConfirmationDialog
                        open={this.state.openConfirmationDialog}
                        title={`Delete ${this.state.libraryToDelete.getLibraryName()}: ${this.state.libraryToDelete.getLibraryVersion()} ?`}
                        body={"The 3rd party component will be deleted from the device profile"}
                        handleCancel={this.closeDeleteConfirmationDialog}
                        handleApprove={this.removeDeviceDefinitionLibrary}
                        overrideActionName={"Delete"}
                    />
                )}
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(deviceDefinitionLibraryStepStyle)(DeviceDefinitionLibraryStep));
