import { Typography, WithStyles, withStyles } from "@material-ui/core";
import classNames from "classnames";
import * as React from "react";
import CustomInput from "../CustomInput/CustomInput";
import autocompleteStyle from "./AutocompleteStyle";

interface AppState {
    showOptions: boolean;
    activeOption: number;
    userInput: string;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof autocompleteStyle> {
    value: string;
    optionList: { label: string; value: any; key: string | number }[];
    inputTitle: string;
    inputClasses: any;
    maxHeightClass: any;
    autoFocus: boolean;
    disabled?: boolean;
    onChange: (userInput: string) => void;
    onOptionSelect: (value: any) => void;
    dense?: boolean;
    id: string;
    loadingIcon?: boolean;
    maxLength?: number;
}

class Autocomplete extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    ref;
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            activeOption: 0,
            showOptions: false,
            userInput: this.props.value,
        };
        this.ref = React.createRef();
    }

    componentDidUpdate() {
        if (this.ref.current) {
            this.ref.current.scrollIntoView({ block: "end", behavior: "smooth" });
        }
    }

    onChange = (e) => {
        const { optionList } = this.props;
        const userInput = e.currentTarget.value;
        //const filteredOptions = optionList.filter(option => option.toLowerCase().indexOf(userInput.toLowerCase()) > -1);
        this.setState({
            activeOption: 0,
            showOptions: true,
            userInput: userInput,
        });
        this.props.onChange(userInput);
    };

    onClick = (value: any) => {
        this.props.onOptionSelect(value);
        this.setState({
            activeOption: 0,
            showOptions: false,
        });
    };

    onKeyDown = (e) => {
        const { activeOption } = this.state;
        if (e.keyCode === 13) {
            const userInput = this.props.optionList[activeOption];
            this.setState({
                activeOption: 0,
                showOptions: false,
                userInput: userInput.label,
            });
            this.props.onChange(userInput.label);
        } else if (e.keyCode === 38) {
            if (activeOption === 0) {
                return;
            }
            this.setState({ activeOption: activeOption - 1 });
        } else if (e.keyCode === 40) {
            if (activeOption + 1 === this.props.optionList.length) {
                return;
            }

            this.setState({ activeOption: activeOption + 1 });
        }
    };

    hideOptions = () => {
        this.setState({ showOptions: false });
    };

    render() {
        const { classes } = this.props;

        {
            /* Build options list */
        }
        let optionList;
        if (this.state.showOptions && this.state.userInput) {
            if (this.props.optionList.length) {
                optionList = (
                    <div
                        className={classNames(
                            classes.dropdown,
                            classes.flexColumn,
                            classes.fixedInputTextAreaWith,
                            this.props.maxHeightClass
                        )}
                    >
                        {this.props.optionList.map((option, index) => {
                            return (
                                <div
                                    className={classNames(
                                        classes.dropdownCell,
                                        classes.fullWidth,
                                        classes.flexColumn,
                                        index === this.state.activeOption ? classes.selectedCell : null
                                    )}
                                    key={index + "dropDownSelect"}
                                    ref={index === this.state.activeOption ? this.ref : null}
                                >
                                    <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                        key={option.key}
                                        onMouseDown={() => this.onClick(option.value)}
                                    >
                                        {option.label}
                                    </Typography>
                                </div>
                            );
                        })}
                    </div>
                );
            }
        }
        return (
            <>
                <CustomInput
                    value={this.props.value}
                    inputTitle={this.props.inputTitle}
                    maxHeightClass={""}
                    inputClasses={""}
                    autoFocus={this.props.autoFocus}
                    onChange={this.onChange}
                    onBlur={this.hideOptions}
                    onKeyDown={this.onKeyDown}
                    disabled={!!this.props.disabled && this.props.disabled}
                    required={true}
                    id={this.props.id}
                    loadingIcon={this.props.loadingIcon}
                    maxLength={this.props.maxLength}
                />

                {/* Display autocomplete options*/}
                {optionList}
            </>
        );
    }
}

export default withStyles(autocompleteStyle)(Autocomplete);
