import { grey } from "@material-ui/core/colors";
import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../../CommonStyle";

const dashboardVisualisationMenuStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        cogIcon: {
            fontSize: "0.9rem",
            color: grey[500],
            cursor: "pointer",

            "&:hover": {
                color: theme.palette.common.black,
            },
        },

        paperContainer: {
            borderRadius: 20,
            boxShadow: "0px 6px 250px 0px #0000001F",

            "& .MuiMenu-list": {},
        },

        pencilIcon: {
            fontSize: "12px",
            marginRight: "5px",
        },

        deleteIcon: {
            fontSize: "12px",
            marginRight: "5px",
        },

        menuContainer: {
            padding: "10px 0",
        },

        menuItemContainer: {
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            padding: theme.spacing(0, 7, 0, 5),
            height: 40,

            "&:hover": {
                background: "#FFF1F5",
            },
        },

        menuItemText: {
            fontSize: "1rem",
            marginLeft: 10,
        },

        deletingVisualisationLoaderContainer: {
            marginLeft: "5px",
        },
    });

export default dashboardVisualisationMenuStyle;
