/**
 * Represents a autocomplete library in sternum.
 */

class SternumTriggerTypeDisplayInfo {
    /**
     * Constructor.
     */
    constructor(public serverName: string, public description: string, public displayName: string) {}

    /**
     * Converts json to info object.
     */
    public static fromJsonObject(jsonObject: Object) {
        return new SternumTriggerTypeDisplayInfo(
            jsonObject["server_display_name"],
            jsonObject["description"],
            jsonObject["display_name"]
        );
    }
}

export default SternumTriggerTypeDisplayInfo;
