import { useCallback, useEffect, useRef, useState } from "react";

import ServiceWire from "../../../lib/services/ServiceWire";
import { EmailNotificationSettings } from "../../../lib/state/EmailNotificationSettings";

export const useEmailNotificationSettings = () => {
    const [settings, setSettings] = useState<EmailNotificationSettings[]>([]);
    const isMountedRef = useIsMounted();
    const [isLoading, setLoading] = useState(false);
    const [isBeforeFirstLoad, setBeforeFirstLoad] = useState(true);

    const fetch = useCallback(async () => {
        setLoading(true);
        const clientId = ServiceWire.getClientsService().getSelectedClientId();
        const response = await ServiceWire.getSternumService().getUserEmailNotificationSettings(clientId);

        if (isMountedRef.current) {
            setSettings(response);
            setLoading(false);
            setBeforeFirstLoad(false);
        }
    }, []);

    useEffect(() => {
        fetch().catch(() => {
            if (isMountedRef.current) {
                setLoading(false);
            }
        });
    }, [fetch]);

    return { emailNotificationSettings: settings, isLoading, isBeforeFirstLoad, refetch: fetch } as const;
};

const useIsMounted = () => {
    const isMountedRef = useRef(true);

    useEffect(
        () => () => {
            isMountedRef.current = false;
        },
        []
    );

    return isMountedRef;
};
