import { makeStyles } from "@material-ui/core";

export const useSternumEmptyModalStyles = makeStyles((theme) => ({
    emptyContainer: {
        margin: "auto",
        display: "flex",
        height: "100%",
        width: "100%",
        borderRadius: 14,
    },

    emptyContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "auto",
    },

    title: {
        color: "#2B2523",
    },

    description: {},
}));
