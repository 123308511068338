import { makeStyles } from "@material-ui/core";

export const useDeviceTileAddItemStyle = makeStyles((theme) => ({
    deviceTileAddItemContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 20,
        color: "#1B6FDE",
        border: "1px dashed #1B6FDE",
        width: 192,
        minHeight: 160,
        cursor: "pointer",
    },
}));
