import React from "react";
import classNames from "classnames";

import { useLoadingContainerStyle } from "./LoadingContainer.style";

export interface LoadingContainerProps extends React.ComponentProps<"div"> {}

export function LoadingContainer({ className, children, ...props }: LoadingContainerProps) {
    const classes = useLoadingContainerStyle();

    return (
        <div className={classNames(classes.loadingContainer, className)} {...props}>
            {children}
        </div>
    );
}
