import { connect } from "react-redux";
import { Typography } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { withRouter } from "react-router-dom";
import SternumConfiguration from "../../lib/infra/SternumConfiguration";
import SternumDeviceEventInfo from "../../lib/state/SternumDeviceEventInfo";
import TraceInfo from "../../lib/state/TraceInfo";
import EventsTimeline from "../EventsTimeline/EventsTimeline";
import traceInfoDisplayStyle from "./TraceInfoDisplayStyle";
import SternumLink from "../SUI/SternumLink/SternumLink";
import ArgumentInfo from "../../lib/state/ArgumentInfo";
import { TraceTooltip } from "./TraceTooltip";
import { CopyIcon, XIcon } from "../SUI/SternumIcon";
import { NotificationMessage, NotificationVariant } from "../../lib/state/NotificationsState";
import { showNotificationAction } from "../../lib/redux/notifications/ShowNotificationAction";
import { TraceViewAlertStatusProps } from "../TraceViewAlertStatus";
import { GlobalState } from "../../lib/state/GlobalState";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    expanded: boolean;
    areLogsTooltipVisible?: boolean;
}

/**
 * Holds any props the App component wants to use.
 */
export interface TraceInfoDisplayProps extends WithStyles<typeof traceInfoDisplayStyle> {
    trace: TraceInfo;
    showEventsTimeline: boolean;

    // indicate if it's attack or alert
    isIssue: boolean;
    hideInfoDescription?: boolean;
    hideTimelineIndexContainer?: boolean;
}

const mapStateToProps = (state: GlobalState, ownProps: TraceViewAlertStatusProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        showNotification: (message: NotificationMessage, variant?: NotificationVariant) => {
            dispatch(showNotificationAction(message, variant));
        },
    };
};

type TraceInfoDisplayPropsWithHOC = TraceInfoDisplayProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {};

/**
 * Trace info display.
 */
class TraceInfoDisplayClass extends React.Component<TraceInfoDisplayPropsWithHOC, AppState> {
    /**
     * Holds the explanation about the attack.
     */
    private readonly exploitationExplanationLines: string[];

    /**
     * Constructor.
     */
    constructor(props: TraceInfoDisplayPropsWithHOC) {
        super(props);

        // Initializing the state to default.
        this.state = {
            expanded: false,
        };
        if (
            this.props.trace.traceArguments &&
            this.props.trace.traceArguments["EXPLOITATION_TYPE"] &&
            this.props.trace.traceArguments["EXPLOITATION_TYPE"].displayValue
        ) {
            this.exploitationExplanationLines = SternumConfiguration.getAttackExplanationLines(
                this.props.trace,
                this.props.trace.traceArguments["EXPLOITATION_TYPE"].displayValue
            );
        }
    }

    private static limitCharacters(n: number, s: string) {
        return s.length > n ? s.slice(0, n) + "..." : s;
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;
        let traceEventTypeConfigurationObject = SternumConfiguration.getTraceEventTypeConfigurationObject(
            this.props.trace.traceDefinition.traceEventName
        );

        const getIsDebugLog = (argumentInfo: ArgumentInfo) => {
            return argumentInfo.argumentDefinition.argumentEventName === "ARG_ROLE_DEBUG_LOG";
        };

        return (
            <div className={classes.root}>
                {!this.props.hideInfoDescription && (
                    <div className={classNames(classes.flexRow)}>
                        <div className={classNames(classes.flexEqual)}>
                            {/* Trace info */}
                            <div className={classNames(classes.traceInfoContainer)}>
                                {/* Trace details */}
                                <div className={classes.infoColumn}>
                                    {/* Event type */}
                                    <div className={classes.detailContainer}>
                                        {/* Title */}
                                        <Typography variant={"body2"} className={classes.detailName}>
                                            Event:
                                        </Typography>

                                        <Typography variant="body2">
                                            {this.props.trace.traceDefinition.displayName}
                                        </Typography>
                                    </div>

                                    {/* IP Address */}
                                    <div className={classes.detailContainer}>
                                        {/* Title */}
                                        <Typography variant={"body2"} className={classes.detailName}>
                                            IP:
                                        </Typography>

                                        <Typography variant="body2">{this.props.trace.ipAddress}</Typography>
                                    </div>
                                </div>

                                {/* Arguments display */}
                                <div className={classes.infoColumn}>
                                    {this.props.trace.orderedTraceArguments
                                        .filter((argumentInfo) => {
                                            let argumentRoleTypeConfiguration =
                                                SternumConfiguration.getArgumentRoleTypeConfigurationObject(
                                                    argumentInfo.argumentDefinition.argumentEventName,
                                                    this.props.trace.traceDefinition.traceEventName
                                                );

                                            if (!argumentRoleTypeConfiguration) {
                                                return true;
                                            } else {
                                                return (
                                                    !argumentRoleTypeConfiguration.shouldBeDisplayedInTraceView ||
                                                    argumentRoleTypeConfiguration.shouldBeDisplayedInTraceView(
                                                        this.props.trace
                                                    )
                                                );
                                            }
                                        })
                                        .map((argumentInfo, index) => {
                                            let argumentRoleTypeConfiguration =
                                                SternumConfiguration.getArgumentRoleTypeConfigurationObject(
                                                    argumentInfo.argumentDefinition.argumentEventName,
                                                    this.props.trace.traceDefinition.traceEventName
                                                );

                                            const isDebugLog = getIsDebugLog(argumentInfo);

                                            const argumentValueToDisplay =
                                                argumentRoleTypeConfiguration &&
                                                argumentRoleTypeConfiguration.extractValue
                                                    ? argumentRoleTypeConfiguration.extractValue(
                                                          argumentInfo.argumentValue
                                                      )
                                                    : argumentInfo.displayValue;

                                            return (
                                                <div key={index} className={classes.detailContainer}>
                                                    {/* Title */}
                                                    <Typography variant={"body2"} className={classes.detailName}>
                                                        {argumentRoleTypeConfiguration
                                                            ? argumentRoleTypeConfiguration.getDisplayName(
                                                                  this.props.trace
                                                              )
                                                            : argumentInfo.argumentDefinition.displayName}
                                                        :
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        {isDebugLog ? (
                                                            <TraceTooltip
                                                                isOpen={this.state.areLogsTooltipVisible}
                                                                onClose={() =>
                                                                    this.setState({ areLogsTooltipVisible: false })
                                                                }
                                                                tooltipContent={this.renderTraceLog(
                                                                    argumentValueToDisplay
                                                                )}
                                                            >
                                                                {(ref) => {
                                                                    return (
                                                                        <span ref={ref}>
                                                                            <SternumLink
                                                                                onClick={() =>
                                                                                    this.setState({
                                                                                        areLogsTooltipVisible: true,
                                                                                    })
                                                                                }
                                                                            >
                                                                                Show log
                                                                            </SternumLink>
                                                                        </span>
                                                                    );
                                                                }}
                                                            </TraceTooltip>
                                                        ) : (
                                                            <div>{argumentValueToDisplay}</div>
                                                        )}
                                                    </Typography>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>

                            {/* Trace Description */}
                            {!this.exploitationExplanationLines && this.props.trace.traceDefinition.description && (
                                <div className={classNames(classes.marginTopLarge, classes.displayLineBreak)}>
                                    <Typography variant="body2">
                                        {this.props.trace.traceDefinition.description}
                                    </Typography>
                                </div>
                            )}

                            {/* Explanations */}
                            {this.props.trace.description && (
                                <div className={classNames(classes.marginTopLarge, classes.displayLineBreak)}>
                                    <Typography variant="body2">
                                        {this.state.expanded
                                            ? this.props.trace.description
                                            : TraceInfoDisplayClass.limitCharacters(
                                                  110,
                                                  this.props.trace.description
                                              )}{" "}
                                        <span
                                            className={classes.expandToggle}
                                            onClick={() => this.setState((s) => ({ ...s, expanded: !s.expanded }))}
                                        >
                                            {this.props.trace.description.length > 110 && (
                                                <span>{this.state.expanded ? " Show less" : " Show more"}</span>
                                            )}
                                        </span>
                                    </Typography>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {this.props.showEventsTimeline && (
                    <div
                        id="eventsTimeline"
                        className={classNames(
                            classes.timelineContainer,
                            this.props.hideInfoDescription && classes.timelineContainerWithoutDescription
                        )}
                    >
                        <EventsTimeline
                            hideTimelineIndexContainer={this.props.hideTimelineIndexContainer}
                            isIssue={this.props.isIssue}
                            sternumDeviceEventInfo={
                                new SternumDeviceEventInfo(
                                    "SDEV" + this.props.trace.entityId.substring(4),
                                    this.props.trace.traceIdLong,
                                    this.props.trace.created,
                                    this.props.trace.device,
                                    this.props.trace,
                                    null
                                )
                            }
                        />
                    </div>
                )}
            </div>
        );
    }

    private renderTraceLog(logDescription: string) {
        const { classes } = this.props;

        return (
            <div className={classes.tooltipLogContainer}>
                <div className={classes.tooltipLogCloseIcon}>
                    <XIcon
                        className={classes.cursorPointer}
                        onClick={() => this.setState({ areLogsTooltipVisible: false })}
                        color="#2B2E30"
                    />
                </div>
                <div className={classes.tooltipLogTitle}>
                    Debug log
                    <CopyIcon
                        className={classes.cursorPointer}
                        onClick={() => this.copyToClipboardWithSnackbar(logDescription)}
                        color="#E7004C"
                    />
                </div>
                <div className={classes.tooltipLogDescription}>
                    {logDescription.split("\n").map((item, index) => {
                        if (index === 0) {
                            return item;
                        }

                        return (
                            <>
                                <br />
                                {item}
                            </>
                        );
                    })}
                </div>
            </div>
        );
    }

    private async copyToClipboardWithSnackbar(textToCopy: string) {
        try {
            await navigator.clipboard.writeText(textToCopy);
            this.props.showNotification("The Debug Log was copied", NotificationVariant.Information);
        } catch {
            // Do nothing
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(traceInfoDisplayStyle)(TraceInfoDisplayClass)));

// export default withRouter(withStyles(traceInfoDisplayStyle)(TraceInfoDisplay));
