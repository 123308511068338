import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { WithStyles, withStyles } from "@material-ui/core/styles";

import DevicesFilter from "../../lib/state/DevicesFilter";
import SternumConfiguration from "../../lib/infra/SternumConfiguration";
import { GlobalState } from "../../lib/state/GlobalState";
import DevicesKeyMetricsBar from "../DevicesKeyMetricsBar/DevicesKeyMetricsBar";
import DevicesList from "../DevicesList/DevicesList";
import devicesPageStyle from "./DevicesPageStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    selectedMetricId: string;
    devicesFilter: DevicesFilter;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof devicesPageStyle> {
    sideBarOpen: boolean;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {};
};

/**
 * Holds the devices list in the app.
 */
class DevicesPage extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            selectedMetricId: null,
            devicesFilter: null,
        };
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        return (
            <div>
                {/* Key metrics bar */}
                <DevicesKeyMetricsBar
                    selectedMetricId={this.state.selectedMetricId}
                    onKeyMetricClicked={(metricId) => this.onKeyMetricClicked(metricId)}
                />

                {/* Paper holding the rest of the content of the page */}
                <div
                    className={classNames(
                        classes.tableContainer,
                        this.props.sideBarOpen ? "mod-sidebar-open" : "mod-sidebar-collapsed"
                    )}
                >
                    <div className={classes.tableInner}>
                        <DevicesList
                            devicesFilter={this.state.devicesFilter}
                            applyFiltersOnUrl={true}
                            openDeviceViewInCurrentPage={true}
                        />
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Occurs once a key metric clicked.
     */
    private async onKeyMetricClicked(metricId: string) {
        if (this.state.selectedMetricId === metricId) {
            // If given metric id is the metric id that is currently selected, we remove the filter by setting selectedMetricId in the state to null.
            this.setState({
                selectedMetricId: null,
            });

            // We reload the devices with a null filter.
            this.setState({
                devicesFilter: null,
            });
        } else {
            // Otherwise, it's a new metric that's being chosen, we filter the list by the new filter.
            this.setState({
                selectedMetricId: metricId,
            });

            // Getting the filter from configuration.
            let devicesFilter = SternumConfiguration.getMetricDevicesFilter(metricId);

            if (devicesFilter) {
                // Setting the retrieved filter over the list.
                this.setState({
                    devicesFilter: devicesFilter,
                });
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(devicesPageStyle)(DevicesPage)));
