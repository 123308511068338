import { Typography } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import Popover from "@material-ui/core/Popover";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { uniqueId } from "lodash";
import * as React from "react";
import SternumQueryField from "../../lib/infra/SternumQueryField";
import QueryQuantifierType from "../../lib/state/QueryQuantifierType";
import SternumFilter from "../../lib/state/SternumFilter";
import SternumQuery from "../../lib/state/SternumQuery";
import SternumFilterEditor from "../SternumFilterEditor/SternumFilterEditor";
import { DeleteIcon, PlusFilledIcon } from "../SUI/SternumIcon/SternumIcon";
import sternumQueryEditorStyle from "./SternumQueryEditorStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    sternumQuery: SternumQuery;
    allOrAnySelectorClickedPopoverOpen: boolean;
    allOrAnySelectorClickedPopoverElement: any;

    addFilterOrInnerQueryPopoverOpen: boolean;
    addFilterOrInnerQueryPopoverAnchorElement: any;

    fields: SternumQueryField[];

    loading?: boolean;
    error?: boolean;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof sternumQueryEditorStyle> {
    fields: SternumQueryField[];
    sternumQuery: SternumQuery;
    onSternumQueryChanged: (sternumQuery: SternumQuery) => void;

    columnView?: boolean;

    loading?: boolean;
    error?: boolean;

    showClearButton?: boolean;
    onQueryCleared?: () => void;

    theme?;

    disabled?: boolean;

    withoutInnerQueries?: boolean;
    showFilterLabels?: boolean;
}

/**
 * Popover content for view columns.
 */
class SternumQueryEditor extends React.Component<AppProps, AppState> {
    /**
     * The current identifier of a filter component.
     */
    private filterComponentIdentifier: number = 1;

    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        if (this.props.sternumQuery && this.props.sternumQuery.filters && this.props.sternumQuery.filters.length) {
            this.props.sternumQuery.filters.forEach((currentFilter) => {
                if (currentFilter.filterComponentIdentifier > this.filterComponentIdentifier) {
                    this.filterComponentIdentifier = currentFilter.filterComponentIdentifier;
                }
            });
        }

        // Initializing the state to default.
        this.state = {
            sternumQuery: this.props.sternumQuery,
            allOrAnySelectorClickedPopoverOpen: false,
            allOrAnySelectorClickedPopoverElement: null,
            addFilterOrInnerQueryPopoverOpen: false,
            addFilterOrInnerQueryPopoverAnchorElement: null,
            fields: this.props.fields,

            loading: this.props.loading,
            error: this.props.error,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: Readonly<AppProps>, nextContext: any) {
        if (nextProps.fields || nextProps.loading || nextProps.error) {
            this.setState({
                loading: nextProps.loading,
                error: nextProps.error,
                fields: nextProps.fields,
            });
        }

        if (nextProps.sternumQuery.isDifferentFrom(this.props.sternumQuery)) {
            this.setState({ sternumQuery: nextProps.sternumQuery });
        }
    }

    /**
     * Renders the component.
     */
    render() {
        let { classes } = this.props;

        return (
            <div>
                {this.state.sternumQuery?.filters?.length > 0 && (
                    <>
                        <div className={classNames(classes.flexVMiddle, classes.marginBottom)}>
                            {/* Quantifier selection */}
                            <Typography
                                variant="body2"
                                className={classNames(classes.allOrAnySelectorText, classes.marginRightXs)}
                                onClick={
                                    this.props.disabled
                                        ? undefined
                                        : (event) => this.handleAllOrAnySelectorClicked(event)
                                }
                            >
                                {this.state.sternumQuery.queryQuantifierType === QueryQuantifierType.ANY ? "Or" : "And"}
                            </Typography>

                            {/* Chevron */}
                            <Icon
                                className={classNames(
                                    "fas fa-chevron-down",
                                    classes.dropdownChevron,
                                    classes.marginRightXs
                                )}
                            />

                            {/* Delete icon */}
                            {!this.props.disabled && this.props.showClearButton && (
                                <div className={classNames(classes.flexVMiddle)}>
                                    <Typography variant="body2">(</Typography>
                                    <Typography
                                        variant="body2"
                                        className={classNames(classes.removeLinkText)}
                                        onClick={this.props.disabled ? undefined : () => this.clearQueryClicked()}
                                    >
                                        Remove Inner Query
                                    </Typography>
                                    <Typography variant="body2">)</Typography>
                                </div>
                            )}
                        </div>

                        {/* All or any popover */}
                        <Popover
                            id="all-or-any-popover"
                            open={this.state.allOrAnySelectorClickedPopoverOpen}
                            anchorEl={this.state.allOrAnySelectorClickedPopoverElement}
                            onClose={() => this.handleAllOrAnySelectorClosed()}
                            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                            transformOrigin={{ vertical: "top", horizontal: "left" }}
                            PaperProps={{
                                className: classes.paperContainer,
                            }}
                        >
                            <div className={classes.menuContainer}>
                                {/* All */}
                                <div
                                    className={classNames(classes.menuItemContainer)}
                                    onClick={() => this.allQueryTypeSelected()}
                                >
                                    <Typography variant="body2" className={"optionText"}>
                                        And
                                    </Typography>
                                </div>

                                {/* Any */}
                                <div
                                    className={classNames(classes.menuItemContainer)}
                                    onClick={() => this.anyQueryTypeSelected()}
                                >
                                    <Typography variant="body2" className={"optionText"}>
                                        Or
                                    </Typography>
                                </div>
                            </div>
                        </Popover>
                    </>
                )}

                {/* Filters */}
                {this.state.sternumQuery?.filters?.map((filter, index) => {
                    return (
                        <div
                            role="presentation"
                            aria-label="filter container"
                            key={filter.filterComponentIdentifier}
                            className={classNames(
                                classes.filterContainer,
                                !this.props.columnView && "mod-row",
                                this.props.columnView && "mod-column",
                                classes.marginBottom
                            )}
                        >
                            {this.props.showFilterLabels && (
                                <Typography
                                    variant="body2"
                                    className={classNames(classes.marginRight, classes.flexNoShrink)}
                                >
                                    Filter ({index + 1})
                                </Typography>
                            )}
                            <div className={classNames(this.props.columnView && classes.marginBottomXs)}>
                                <SternumFilterEditor
                                    loading={this.props.loading}
                                    error={this.props.error}
                                    fields={this.state.fields}
                                    filter={filter}
                                    columnView={this.props.columnView}
                                    onSternumFilterChanged={(sternumFilter) =>
                                        this.onSternumFilterChanged(sternumFilter, index)
                                    }
                                    disabled={this.props.disabled}
                                />
                            </div>
                            {/* Remove filter */}
                            {!this.props.disabled && (
                                <div className={classNames(classes.flexVMiddle)}>
                                    <DeleteIcon
                                        role="button"
                                        aria-label="delete filter icon"
                                        color="#4F4F4F"
                                        className={classNames(classes.removeIcon)}
                                        onClick={(event) => this.removeFilter(index)}
                                    />

                                    <PlusFilledIcon
                                        role="button"
                                        aria-label="add filter icon"
                                        width={20}
                                        height={20}
                                        color="#1B6DD9"
                                        className={classNames(classes.cursorPointer)}
                                        onClick={(event) => this.handleAddFilterOrInnerQueryClicked(event)}
                                    />

                                    {/* Adding more filters or inner queries */}
                                    <Icon />
                                </div>
                            )}
                            {/* Add inner query/filter popover */}
                            <Popover
                                id="add-filter-or-inner-query-popover"
                                open={this.state.addFilterOrInnerQueryPopoverOpen}
                                anchorEl={this.state.addFilterOrInnerQueryPopoverAnchorElement}
                                onClose={() => this.handleAddFilterOrInnerQueryPopoverClosed()}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                PaperProps={{
                                    className: classes.paperContainer,
                                }}
                            >
                                <div className={classes.menuContainer}>
                                    {/* Filter */}
                                    <div
                                        className={classNames(classes.menuItemContainer)}
                                        onClick={() => this.onAddFilterClicked()}
                                    >
                                        <Typography variant="body2" className={"optionText"}>
                                            Filter
                                        </Typography>
                                    </div>

                                    {/* Inner query */}
                                    <div
                                        className={classNames(classes.menuItemContainer)}
                                        onClick={() => this.onAddInnerQueryClicked(index)}
                                    >
                                        <Typography variant="body2" className={"optionText"}>
                                            Inner Query
                                        </Typography>
                                    </div>
                                </div>
                            </Popover>
                        </div>
                    );
                })}

                {/* Inner queries */}
                {this.state.sternumQuery?.innerQueries?.map((innerQuery, index) => {
                    return (
                        <div className={classNames(classes.marginLeftLarge)} key={`${index}`}>
                            <SternumQueryEditor
                                fields={this.state.fields}
                                sternumQuery={innerQuery}
                                columnView={this.props.columnView}
                                showClearButton={true}
                                onQueryCleared={() => this.onInnerQueryCleared(innerQuery, index)}
                                onSternumQueryChanged={(changedInnerQuery) =>
                                    this.onInnerQueryChanged(changedInnerQuery, index)
                                }
                                classes={classes}
                                disabled={this.props.disabled}
                            />
                        </div>
                    );
                })}
            </div>
        );
    }

    /**
     * Occurs on the clear of an inner query. It will remove it from the inner queries.
     */
    private onInnerQueryCleared(innerQuery, index) {
        // Cloning query so we won't work on original copy.
        let clonedQuery: SternumQuery = this.state.sternumQuery.clone();

        // Removing the inner query.
        clonedQuery.innerQueries.splice(index, 1);

        this.setState(
            {
                sternumQuery: clonedQuery,
            },
            () => {
                if (this.props.onSternumQueryChanged) {
                    this.props.onSternumQueryChanged(this.state.sternumQuery);
                }
            }
        );
    }

    /**
     * Occurs once add filter is clicked.
     */
    private onAddFilterClicked() {
        // Cloning query so we won't work on original copy.
        let clonedQuery: SternumQuery = this.state.sternumQuery.clone();

        // Adding an empty filter to the query.
        clonedQuery.filters.push(SternumFilter.getEmptyFilter(+uniqueId()));

        this.setState(
            {
                addFilterOrInnerQueryPopoverOpen: false,
                addFilterOrInnerQueryPopoverAnchorElement: null,
                sternumQuery: clonedQuery,
            },
            () => {
                if (this.props.onSternumQueryChanged) {
                    this.props.onSternumQueryChanged(this.state.sternumQuery);
                }
            }
        );
    }

    /**
     * Occurs on a click of a clear query.
     */
    private clearQueryClicked() {
        if (this.props.onQueryCleared) {
            this.props.onQueryCleared();
        }
    }

    /**
     * Occurs once add inner query is clicked.
     */
    private onAddInnerQueryClicked(index) {
        // Cloning query so we won't work on original copy.
        let clonedQuery: SternumQuery = this.state.sternumQuery.clone();

        // Adding inner query.
        clonedQuery.innerQueries.splice(index + 1, 0, SternumQuery.getEmptyQuery());

        this.setState(
            {
                addFilterOrInnerQueryPopoverOpen: false,
                addFilterOrInnerQueryPopoverAnchorElement: null,
                sternumQuery: clonedQuery,
            },
            () => {
                if (this.props.onSternumQueryChanged) {
                    this.props.onSternumQueryChanged(this.state.sternumQuery);
                }
            }
        );
    }

    /**
     * Occurs once a filter is changed.
     */
    private onSternumFilterChanged(sternumFilter: SternumFilter, index: number) {
        // Cloning query so we won't work on original copy.
        let clonedQuery: SternumQuery = this.state.sternumQuery.clone();

        // Updating filter.
        clonedQuery.filters[index].filterComponentIdentifier = sternumFilter.filterComponentIdentifier;
        clonedQuery.filters[index].fieldApiName = sternumFilter.fieldApiName;
        clonedQuery.filters[index].conditionApiName = sternumFilter.conditionApiName;
        clonedQuery.filters[index].valuesMap = sternumFilter.valuesMap;
        clonedQuery.filters[index].doesNotRequireValue = sternumFilter.doesNotRequireValue;
        clonedQuery.filters[index].isSpecialField = sternumFilter.isSpecialField;

        this.setState(
            {
                sternumQuery: clonedQuery,
            },
            () => {
                if (this.props.onSternumQueryChanged) {
                    this.props.onSternumQueryChanged(this.state.sternumQuery);
                }
            }
        );
    }

    private handleAllOrAnySelectorClicked(event) {
        this.setState({
            allOrAnySelectorClickedPopoverOpen: true,
            allOrAnySelectorClickedPopoverElement: event.currentTarget,
        });
    }

    private handleAllOrAnySelectorClosed() {
        this.setState({
            allOrAnySelectorClickedPopoverOpen: false,
            allOrAnySelectorClickedPopoverElement: null,
        });
    }

    private allQueryTypeSelected() {
        let clonedQuery: SternumQuery = this.state.sternumQuery.clone();

        clonedQuery.queryQuantifierType = QueryQuantifierType.ALL;

        this.setState(
            {
                allOrAnySelectorClickedPopoverOpen: false,
                allOrAnySelectorClickedPopoverElement: null,
                sternumQuery: clonedQuery,
            },
            () => {
                if (this.props.onSternumQueryChanged) {
                    this.props.onSternumQueryChanged(this.state.sternumQuery);
                }
            }
        );
    }

    private anyQueryTypeSelected() {
        // Cloning query so we won't work on original copy.
        let clonedQuery: SternumQuery = this.state.sternumQuery.clone();

        // Updating quantifier type.
        clonedQuery.queryQuantifierType = QueryQuantifierType.ANY;

        this.setState(
            {
                allOrAnySelectorClickedPopoverOpen: false,
                allOrAnySelectorClickedPopoverElement: null,
                sternumQuery: clonedQuery,
            },
            () => {
                if (this.props.onSternumQueryChanged) {
                    this.props.onSternumQueryChanged(this.state.sternumQuery);
                }
            }
        );
    }

    /**
     * Add filter on inner query popover opened.
     */
    private handleAddFilterOrInnerQueryClicked(event) {
        if (this.props.withoutInnerQueries) {
            this.onAddFilterClicked();
        } else {
            this.setState({
                addFilterOrInnerQueryPopoverOpen: true,
                addFilterOrInnerQueryPopoverAnchorElement: event.currentTarget,
            });
        }
    }

    /**
     * Add filter on inner query popover closed.
     */
    private handleAddFilterOrInnerQueryPopoverClosed() {
        this.setState({
            addFilterOrInnerQueryPopoverOpen: false,
            addFilterOrInnerQueryPopoverAnchorElement: null,
        });
    }

    /**
     * Occurs on change of inner query.
     */
    private onInnerQueryChanged(changedInnerQuery: SternumQuery, index: number) {
        // Cloning query so we won't work on original copy.
        let clonedQuery: SternumQuery = this.state.sternumQuery.clone();

        // Updating inner query.
        clonedQuery.innerQueries[index] = changedInnerQuery;

        this.setState(
            {
                sternumQuery: clonedQuery,
            },
            () => {
                if (this.props.onSternumQueryChanged) {
                    this.props.onSternumQueryChanged(this.state.sternumQuery);
                }
            }
        );
    }

    /**
     * Removes a filter.
     */
    private removeFilter(index: number) {
        // Cloning query so we won't work on original copy.
        let clonedQuery: SternumQuery = this.state.sternumQuery.clone();

        // Removing filter.
        clonedQuery.filters.splice(index, 1);

        // // Adding empty filter if we have no filters.
        // if (!clonedQuery.filters.length) {
        //     this.filterComponentIdentifier = this.filterComponentIdentifier + 1;
        //     clonedQuery.filters.push(SternumFilter.getEmptyFilter(this.filterComponentIdentifier));
        // }

        this.setState(
            {
                sternumQuery: clonedQuery,
            },
            () => {
                if (this.props.onSternumQueryChanged) {
                    this.props.onSternumQueryChanged(this.state.sternumQuery);
                }
            }
        );
    }
}

export default withStyles(sternumQueryEditorStyle, { withTheme: true })(SternumQueryEditor);
