import AuthenticationService from "./AuthenticationService";
import AuthorizationService from "./AuthorizationService";
import ClientsService from "./ClientsService";
import ConfigurationService from "./ConfigurationService";
import CustomDashboardsApiService from "./customDashboards/CustomDashboardsApiService";
import EventsApiService from "./events/EventsApiService";
import GeoIpApiService from "./geoipEvents/geoipApiService";
import HttpService from "./HttpService";
import PollingService from "./PollingService";
import SternumService from "./SternumService";
import VisualisationApiService from "./visualisation/VisualisationApiService";
import WebsocketService from "./WebsocketService";
import { ContactService } from "./ContactService";
import DevicesMetricsPollingService from "./DevicesMetricsPollingService";
import { DownloadService } from "./DownloadService";

/**
 * This class is a singleton class used to wire all our services together.
 * If you need a service at any point of the app, you use ServiceWire to get it.
 */
class ServiceWire {
    /**
     * Holds the singleton instance of ServiceWire.
     */
    private static instance: ServiceWire;

    private httpService: HttpService;
    private sternumService: SternumService;
    private visualisationApiService: VisualisationApiService;
    private eventsApiService: EventsApiService;
    private customDashboardsApiService: CustomDashboardsApiService;
    private authenticationService: AuthenticationService;
    private pollingService: PollingService;
    private deviceMetricsPollingService: DevicesMetricsPollingService;
    private websocketService: WebsocketService;
    private configurationService: ConfigurationService;
    private contactService: ContactService;
    private clientsService: ClientsService;
    private authorizationService: AuthorizationService;
    private downloadService: DownloadService;
    private geoIpService: GeoIpApiService;

    /**
     * Declaring a private constructor so class cannot be instantiated.
     */
    private constructor() {}

    /**
     * Initializes the instance of ServiceWire.
     * @param onUnauthorizedCallback The callback to be called once we find user unauthorised.
     */
    public static initialize(onUnauthorizedCallback: () => void) {
        if (!ServiceWire.instance) {
            ServiceWire.instance = new ServiceWire();

            ServiceWire.instance.httpService = new HttpService(onUnauthorizedCallback);
            ServiceWire.instance.sternumService = new SternumService(ServiceWire.instance.httpService);
            ServiceWire.instance.visualisationApiService = new VisualisationApiService(
                ServiceWire.instance.httpService
            );
            ServiceWire.instance.eventsApiService = new EventsApiService(ServiceWire.instance.httpService);
            ServiceWire.instance.customDashboardsApiService = new CustomDashboardsApiService(
                ServiceWire.instance.httpService
            );
            ServiceWire.instance.authenticationService = new AuthenticationService(ServiceWire.instance.sternumService);
            ServiceWire.instance.pollingService = new PollingService(ServiceWire.instance.sternumService);
            ServiceWire.instance.deviceMetricsPollingService = new DevicesMetricsPollingService(
                ServiceWire.instance.sternumService
            );
            ServiceWire.instance.websocketService = new WebsocketService();
            ServiceWire.instance.configurationService = new ConfigurationService(ServiceWire.instance.sternumService);
            ServiceWire.instance.contactService = new ContactService(ServiceWire.instance.sternumService);
            ServiceWire.instance.clientsService = new ClientsService(ServiceWire.instance.sternumService);
            ServiceWire.instance.authorizationService = new AuthorizationService(
                ServiceWire.instance.authenticationService
            );
            ServiceWire.instance.downloadService = new DownloadService(ServiceWire.instance.sternumService);
            ServiceWire.instance.geoIpService = new GeoIpApiService(ServiceWire.instance.httpService);
        } else {
            // If we call initialize twice, it is a problem, we warn the user.
            console.warn("Service wire is already instantiated.");
        }
    }

    /**
     * Gets the instance of the ServiceWire.
     */
    public static getInstance(): ServiceWire {
        if (!ServiceWire.instance) {
            // If we don't have an instance, that means we didn't go through
            // initialize before we did get instance, and it's a problem,
            // we throw exception.
            throw "Service wire is not instantiated.";
        } else {
            return ServiceWire.instance;
        }
    }

    /**
     * Gets the http service.
     */
    public static getHttpService(): HttpService {
        return ServiceWire.getInstance().httpService;
    }

    /**
     * Gets the sternum service.
     */
    public static getSternumService(): SternumService {
        return ServiceWire.getInstance().sternumService;
    }

    /**
     * Gets the visualisation api service.
     */
    public static getVisualisationApiService(): VisualisationApiService {
        return ServiceWire.getInstance().visualisationApiService;
    }

    /**
     * Gets the events api service.
     */
    public static getEventsApiService(): EventsApiService {
        return ServiceWire.getInstance().eventsApiService;
    }

    /**
     * Gets the custom dashboards api service.
     */
    public static getCustomDashboardsApiService(): CustomDashboardsApiService {
        return ServiceWire.getInstance().customDashboardsApiService;
    }

    /**
     * Gets the authorizationService service.
     */
    public static getAuthorizationService(): AuthorizationService {
        return ServiceWire.getInstance().authorizationService;
    }

    /**
     * Gets the authentication service.
     */
    public static getAuthenticationService(): AuthenticationService {
        return ServiceWire.getInstance().authenticationService;
    }

    /**
     * Gets the polling service.
     */
    public static getPollingService(): PollingService {
        return ServiceWire.getInstance().pollingService;
    }

    public static getDeviceMetricsPollingService(): DevicesMetricsPollingService {
        return ServiceWire.getInstance().deviceMetricsPollingService;
    }

    /**
     * Gets the websocket service.
     */
    public static getWebsocketService(): WebsocketService {
        return ServiceWire.getInstance().websocketService;
    }

    /**
     * Gets the configuration service.
     */
    public static getConfigurationService(): ConfigurationService {
        return ServiceWire.getInstance().configurationService;
    }

    /**
     * Gets the configuration service.
     */
    public static getContactService(): ContactService {
        return ServiceWire.getInstance().contactService;
    }

    /**
     * Gets the download service.
     */
    public static getDownloadService(): DownloadService {
        return ServiceWire.getInstance().downloadService;
    }

    /**
     * Gets the clients service.
     */
    public static getClientsService(): ClientsService {
        return ServiceWire.getInstance().clientsService;
    }

    /**
     * Gets the clients service.
     */
    public static getGeoIpService(): GeoIpApiService {
        return ServiceWire.getInstance().geoIpService;
    }
}

export default ServiceWire;
