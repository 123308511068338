/**
 * Base class for all entities in Sternum.
 */
import EntityType from "./EntityType";
import Entity from "./Entity";

abstract class EntityBase implements Entity {
    /**
     * The id of entity.
     */
    public entityId: string;

    /**
     * The time the entity was created.
     */
    public created: number;

    /**
     * The time the entity was updated.
     */
    public updated: number;

    /**
     * The type of the entity.
     */
    public entityType: EntityType;

    /**
     * Constructor.
     */
    protected constructor(entityId: string, created: number, updated: number, entityType: EntityType) {
        this.entityId = entityId;
        this.created = created;
        this.updated = updated;
        this.entityType = entityType;
    }

    public getIdentifier(): string {
        return this.entityId;
    }

    public getEntityCreated(): number {
        return this.created;
    }

    public getEntityUpdated(): number {
        return this.updated;
    }

    public getEntityType(): number {
        return this.entityType;
    }
}

export default EntityBase;
