/**
 * Represents a autocomplete library in sternum.
 */

class SternumDisplayObjectInfo {
    /**
     * Constructor.
     */
    constructor(
        public systemName: string,
        public description: string,
        public displayName: string,
        public displayInUI = true,
        public canBeUsedForAlerts = true
    ) {}

    /**
     * Converts json to info object.
     */
    public static fromJsonObject(jsonObject: Object) {
        return new SternumDisplayObjectInfo(
            jsonObject["name"],
            jsonObject["description"],
            jsonObject["display_name"],
            jsonObject["display_in_ui"],
            jsonObject["can_be_used_for_alerts"]
        );
    }
}

export default SternumDisplayObjectInfo;
