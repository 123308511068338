import ListFilter from "./ListFilter";

/**
 * Represents a filter of devices.
 */
class TracesFilter implements ListFilter {
    /**
     * Constructor.
     */
    constructor(
        public startTime: number,
        public endTime: number,
        public ipAddress: string,
        public traceEventTypes: string[],
        public startId: string,
        public endId: string,
        public sternumGeneratedEventId: string,
        public traceCategories: string[]
    ) {}

    /**
     * Returns whether filter is defined, or all values are nulls.
     */
    public isActive(): boolean {
        return !!(
            this.startTime ||
            this.endTime ||
            (this.ipAddress && this.ipAddress.length) ||
            this.startId ||
            this.endId ||
            this.sternumGeneratedEventId ||
            (this.traceCategories && this.traceCategories.length)
        );
    }

    /**
     * Returns whether current filter is different than given other filter.
     * @param other The other list filter to check with.
     */
    public isDifferentFrom(other: ListFilter): boolean {
        if (!(other instanceof TracesFilter)) {
            return true;
        }

        let otherTracesFilter = other as TracesFilter;

        return (
            otherTracesFilter.startTime !== this.startTime ||
            otherTracesFilter.endTime !== this.endTime ||
            otherTracesFilter.ipAddress !== this.ipAddress ||
            otherTracesFilter.startId !== this.startId ||
            otherTracesFilter.endId !== this.endId ||
            otherTracesFilter.sternumGeneratedEventId !== this.sternumGeneratedEventId ||
            otherTracesFilter.traceCategories !== this.traceCategories
        );
    }
}

export default TracesFilter;
