import { Paper, Typography } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { flatMap, get, isEmpty, isEqual, maxBy, set, sortBy, uniq } from "lodash";
import * as React from "react";
import { GraphResult } from "../../../lib/infra/GraphUtils";
import HashSet from "../../../lib/infra/HashSet";
import SternumConfiguration from "../../../lib/infra/SternumConfiguration";
import SternumUtils from "../../../lib/infra/SternumUtils";
import Utils from "../../../lib/infra/Utils";
import AnalyticsService from "../../../lib/services/AnalyticsService";
import ConfigurationService from "../../../lib/services/ConfigurationService";
import ServiceWire from "../../../lib/services/ServiceWire";
import DeviceDefinitionVersionInfo from "../../../lib/state/DeviceDefinitionVersionInfo";
import TableColumnHeaderInfo from "../../../lib/state/TableColumnHeaderInfo";
import TableToolbarDisplayState from "../../../lib/state/TableToolbarDisplayState";
import AggregationFunctionType from "../../../lib/state/Visualisation/AggregationFunctionType";
import VisualisationDataSourceGroupBy, {
    VisualisationDataSourceGroupByCustomFields,
    VisualisationGroupByOrder,
} from "../../../lib/state/Visualisation/VisualisationConfigurationGroupBy";
import VisualisationDataSource from "../../../lib/state/Visualisation/VisualisationDataSource";
import { ChartEmptyIcon } from "../../SUI/SternumIcon/SternumIcon";
import SternumTable from "../../SUI/SternumTable/SternumTable";
import { getUniqueCountTableData, UniqueCountTableDataResponse } from "../../UniqueCountQuery/UniqueCountTableData";
import UIDataVisualisationConfiguration from "../../VisualisationConfigurationComponent/entities/UIDataVisualisationConfiguration";
import getVisualisationDataSource from "../../VisualisationConfigurationComponent/utils/getVisualisationDataSource";
import { isChangedDataSourceConfiguration } from "../../VisualisationConfigurationComponent/utils/isChangedDataSourceConfiguration";
import PresentationValuesTableRowData from "./PresentationValuesTableRowData";
import visualisationConfigurationPresentationValuesTableStyle from "./VisualisationConfigurationPresentationValuesTableStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    loadingGraph: boolean;
    errorLoadingGraph: boolean;

    graphResult: GraphResult;
}

export interface AppProps extends WithStyles<typeof visualisationConfigurationPresentationValuesTableStyle> {
    entityId: string;

    startTime: number;

    endTime: number;

    dataVisualisationConfigurations: UIDataVisualisationConfiguration[];
    deviceDefinitionVersions: DeviceDefinitionVersionInfo[];
    groupBy: VisualisationDataSourceGroupBy;

    hideToolbar?: boolean;
    denseTable?: boolean;
    // displayTooltipDataPerDeviceDefinitionVersion?: boolean;
    refreshComponentId?: number;
}

class VisualisationConfigurationPresentationValuesTable extends React.Component<AppProps, AppState> {
    stale = false;

    constructor(props: AppProps) {
        super(props);

        this.state = {
            loadingGraph: false,
            errorLoadingGraph: false,

            graphResult: null,
        };
    }

    async componentDidMount() {
        await this.loadGraphDetailed(this.props.startTime, this.props.endTime);
    }

    UNSAFE_componentWillUpdate(nextProps: AppProps) {
        if (
            (nextProps.startTime && nextProps.startTime !== this.props.startTime) ||
            (nextProps.startTime && nextProps.startTime !== this.props.startTime) ||
            nextProps.entityId !== this.props.entityId ||
            !isEqual(this.props.groupBy, nextProps.groupBy)
        ) {
            this.stale = true;
        }
    }

    async componentDidUpdate(nextProps: Readonly<AppProps>, prevState: Readonly<AppState>) {
        const nextDataVisualisationConfigurations: UIDataVisualisationConfiguration[] =
            nextProps.dataVisualisationConfigurations || [];

        const changedDataSource =
            nextDataVisualisationConfigurations.length !== this.props.dataVisualisationConfigurations.length ||
            nextDataVisualisationConfigurations.some(
                (newConfiguration, index) =>
                    !this.props.dataVisualisationConfigurations[index] ||
                    isChangedDataSourceConfiguration(
                        newConfiguration,
                        this.props.dataVisualisationConfigurations[index]
                    )
            );

        if (
            nextProps.refreshComponentId !== this.props.refreshComponentId ||
            (nextProps.startTime && nextProps.startTime !== this.props.startTime) ||
            (nextProps.endTime && nextProps.endTime !== this.props.endTime) ||
            changedDataSource ||
            nextProps.entityId !== this.props.entityId ||
            !isEqual(this.props.groupBy, nextProps.groupBy)
        ) {
            this.stale = false;
            await this.loadGraphDetailed(this.props.startTime, this.props.endTime);
        }
    }

    render() {
        const { classes } = this.props;

        if (isEmpty(this.state.graphResult?.data_sources) && !this.state.loadingGraph) {
            return (
                <div className={classes.emptyDataContainer}>
                    <div className={classes.emptyDataInner}>
                        <ChartEmptyIcon />
                        <Typography className={classes.emptyDataText}>You dont have any data yet</Typography>
                    </div>
                </div>
            );
        }

        const groupByEnabled = !!this.props.groupBy?.enabled;

        let rows = [];

        if (this.state.graphResult?.data_sources && !this.stale) {
            if (groupByEnabled) {
                rows = this.splitVisualisationDataSourcesIntoRowsByGroupByKeys(
                    this.props.dataVisualisationConfigurations
                );
            } else {
                rows = this.splitVisualisationDataSourcesIntoRows(this.props.dataVisualisationConfigurations);
            }
        }

        if (this.props.groupBy?.order_by === VisualisationGroupByOrder.BOTTOM) {
            rows = sortBy(rows, "count");
        } else {
            rows = sortBy(rows, "count").reverse();
        }

        let hasUniqueValue = false;

        this.props.dataVisualisationConfigurations.forEach((configuration) => {
            if (configuration.aggregationFunctionType === AggregationFunctionType.UNIQUE_COUNT) {
                hasUniqueValue = true;
            }
        });

        return (
            <Paper
                classes={{
                    root: classNames(classes.paperClass),
                }}
                elevation={0}
            >
                {groupByEnabled && this.props.dataVisualisationConfigurations.length === 1 && (
                    <Typography variant="body2">
                        {this.props.dataVisualisationConfigurations[0].dataSourceLabel}
                    </Typography>
                )}
                <SternumTable
                    size={this.props.denseTable ? "small" : undefined}
                    pageSize={SternumConfiguration.getPageSize()}
                    hidePagination={true}
                    hideToolbar={this.props.hideToolbar}
                    amountOfLoadingPlaceholders={3}
                    totalItemCount={rows.length}
                    loadingItems={this.state.loadingGraph || !this.props.startTime || !this.props.endTime}
                    errorLoadingItems={this.state.errorLoadingGraph}
                    hideUpperBorder={false}
                    nonClickableRows={true}
                    viewedColumnsSet={
                        hasUniqueValue
                            ? HashSet.fromValues(["deviceProfile", "count"])
                            : HashSet.fromValues(["value", "deviceProfile", "count"])
                    }
                    columnHeaders={[
                        new TableColumnHeaderInfo("value", "Value", true, false, false, true),
                        new TableColumnHeaderInfo(
                            "deviceProfile",
                            hasUniqueValue ? "Unique Value" : "Device Profile",
                            false,
                            false,
                            false,
                            true
                        ),
                        new TableColumnHeaderInfo("count", "Count", false, false, false, true),
                    ]}
                    rows={rows}
                    toolbarState={new TableToolbarDisplayState(false, false, false, false, true, false, true)}
                />
            </Paper>
        );
    }

    private transformUniqueCountValues(
        values: any[],
        visualisationConfiguration: VisualisationDataSource,
        uniqueCountTableDataResponse: UniqueCountTableDataResponse
    ) {
        const argIdToFieldName = {
            [ConfigurationService.getCategoryArgumentField().id]: "categories",
            [ConfigurationService.getInterestArgumentField().id]: "interests",
            [ConfigurationService.getEventTypeArgumentField().id]: "eventTypes",
        };

        const newValues = values
            .reverse()
            .map((val, index) => {
                const col = visualisationConfiguration.uniqueColumns[index];

                if (!col || !col.isSpecialField) {
                    return val;
                }

                if (col.value in argIdToFieldName) {
                    const newVal = uniqueCountTableDataResponse?.[argIdToFieldName[col.value]][val];
                    return newVal !== undefined ? newVal.displayName : `Missing label: ${val}`;
                }

                return val;
            })
            .filter((_, index) => index > 0);

        return newValues;
    }

    private splitVisualisationDataSourcesIntoRows(visualisationConfigurations: UIDataVisualisationConfiguration[]) {
        // graphResult = {
        //     data_sources: {
        //			DEFAULT: 324,
        //			1: 4582,
        //			2: 587,
        //			...
        //     }
        // }

        const dataSourcesMap = this.state.graphResult.data_sources;

        const dataSourceKeys = Object.keys(dataSourcesMap);

        // @ts-ignore
        const rows = dataSourceKeys.flatMap((dataSourceKey) => {
            const deviceDefinitionVersionNames = Object.keys(dataSourcesMap[dataSourceKey]);

            return deviceDefinitionVersionNames
                .map((deviceDefinitionVersionName) => {
                    const visualisationConfiguration = visualisationConfigurations.find(
                        (config) => config.dataSourceKey === dataSourceKey
                    );

                    if (!visualisationConfiguration) return;

                    return new PresentationValuesTableRowData(
                        visualisationConfiguration?.dataSourceLabel || dataSourceKey,
                        dataSourcesMap[dataSourceKey][deviceDefinitionVersionName],
                        deviceDefinitionVersionName
                    );
                })
                .filter(Boolean);
        });

        return rows;
    }

    private splitVisualisationDataSourcesIntoRowsByGroupByKeys(
        visualisationConfigurations: UIDataVisualisationConfiguration[]
    ) {
        // graphResult = {
        //     data_sources: {
        //			DEFAULT: {
        //				"121.52.188.23": 582,
        //				"45.79.125.224": 23,
        //			},
        //			1: {
        //				"121.52.188.23": 582,
        //				"45.79.125.224": 23,
        //			},
        //			...
        //     }
        // }

        const dataSourceValues = Object.entries(this.state.graphResult["data_sources"]);

        // @ts-ignore
        const rows = dataSourceValues.flatMap(([dataSourceKey, dataSourceValues]) => {
            const deviceDefinitionVersionNames = Object.keys(dataSourceValues);

            // @ts-ignore
            return deviceDefinitionVersionNames.flatMap((deviceDefinitionVersionName) => {
                return Object.keys(dataSourceValues[deviceDefinitionVersionName])
                    .map((key) => {
                        let label = key;

                        const visualisationConfiguration = visualisationConfigurations.find(
                            (config) => config.dataSourceKey === dataSourceKey
                        );

                        if (!visualisationConfiguration) return;

                        if (this.props.groupBy?.field === VisualisationDataSourceGroupByCustomFields.TRACE_CATEGORY) {
                            label = SternumUtils.getTraceCategoryById(+key)?.displayName || key;
                        }

                        if (this.props.groupBy?.field === VisualisationDataSourceGroupByCustomFields.COUNTRY) {
                            label = SternumUtils.getCountryNameByCode(key) || key;
                        }

                        return new PresentationValuesTableRowData(
                            label,
                            dataSourceValues[deviceDefinitionVersionName][key],
                            deviceDefinitionVersionName,
                            visualisationConfiguration.dataSourceLabel
                        );
                    })
                    .filter(Boolean);
            });
        });

        return rows;
    }

    /**
     * Loads the graph of the aggregated events.
     */
    private async loadGraph(startTime: number, endTime: number) {
        const dataSources = this.getDataSources();
        if (isEmpty(dataSources)) return;

        try {
            this.setState({
                loadingGraph: true,
                errorLoadingGraph: false,
            });

            let hourDifference = (endTime - startTime) / 1000.0 / 60.0 / 60.0;
            let amountOfGraphPointsToDisplay = 60;
            let timeFormat = "HH:mm";

            if (hourDifference > 24) {
                timeFormat = "MM/dd HH:mm";
            }

            let graphResult: GraphResult = {
                data_sources: {},
            };

            const allDeviceDefinitionVersions = uniq(flatMap(dataSources, "deviceDefinitionVersionIds"));
            const allTraceDefinitionIds = uniq(dataSources.map((dataSource) => dataSource.traceDefinitionId));

            const response = await ServiceWire.getVisualisationApiService().getArgumentValueOverTime(
                this.props.entityId,
                startTime,
                endTime,
                false,
                timeFormat,
                amountOfGraphPointsToDisplay,
                dataSources,
                this.props.groupBy,
                allDeviceDefinitionVersions,
                allTraceDefinitionIds
            );

            const deviceDefinitionVersionIdToVersionMap = this.getDeviceDefinitionVersionIdToVersionMap();

            /**
             * {
             * 	1: {
             * 		ddve1: ...
             * 	},
             * 	2: {
             * 		ddve1: ...
             * 	},
             * }
             */
            dataSources.forEach((dataSource) => {
                // we assign data only for one ddve, because we want to display single value that represents
                // an aggregation of values for individual ddve
                const versionId = dataSource.deviceDefinitionVersionIds[0];

                set(
                    graphResult,
                    [
                        "data_sources",
                        dataSource.dataSourceKey,
                        deviceDefinitionVersionIdToVersionMap[versionId].getVersionName(),
                    ],
                    get(response, ["data_sources", dataSource.dataSourceKey])
                );
            });

            this.setState({
                graphResult: graphResult,
                loadingGraph: false,
                errorLoadingGraph: false,
            });
        } catch (error) {
            AnalyticsService.error("VisualisationConfigurationPresentationValuesTable:loadGraph", error.message);

            this.setState({
                loadingGraph: false,
                errorLoadingGraph: true,
            });
        }
    }

    private async loadGraphDetailed(startTime: number, endTime: number) {
        const dataSources = this.getDataSources();
        if (isEmpty(dataSources)) return;

        try {
            this.setState({
                loadingGraph: true,
                errorLoadingGraph: false,
            });

            let hourDifference = (endTime - startTime) / 1000.0 / 60.0 / 60.0;
            let amountOfGraphPointsToDisplay = 60;
            let timeFormat = "HH:mm";

            if (hourDifference > 24) {
                timeFormat = "MM/dd HH:mm";
            }

            let graphResult: GraphResult = {
                data_sources: {},
            };

            const dataSourcesRequests = this.getDataSourcesRequests(dataSources);

            const allDeviceDefinitionVersions = uniq(flatMap(dataSources, "deviceDefinitionVersionIds"));
            const allTraceDefinitionIds = uniq(dataSources.map((dataSource) => dataSource.traceDefinitionId));

            const responses = await Promise.all(
                dataSourcesRequests.map((dataSourceBatch) => {
                    return ServiceWire.getVisualisationApiService().getArgumentValueOverTime(
                        this.props.entityId,
                        startTime,
                        endTime,
                        false,
                        timeFormat,
                        amountOfGraphPointsToDisplay,
                        dataSourceBatch,
                        this.props.groupBy,
                        allDeviceDefinitionVersions,
                        allTraceDefinitionIds
                    );
                })
            );

            const deviceDefinitionVersionIdToVersionMap = this.getDeviceDefinitionVersionIdToVersionMap();

            const hasUniqueValue =
                this.props.dataVisualisationConfigurations.length > 0 &&
                this.props.dataVisualisationConfigurations[0].aggregationFunctionType ===
                    AggregationFunctionType.UNIQUE_COUNT;

            let uniqueDataResponse: UniqueCountTableDataResponse = null;
            if (hasUniqueValue) {
                uniqueDataResponse = await getUniqueCountTableData(this.props.dataVisualisationConfigurations[0]);
            }

            /**
             * {
             * 	1: {
             * 		ddve1: ...
             * 		ddve2: ...
             * 	},
             * 	2: {
             * 		ddve1: ...
             * 	},
             * }
             */
            responses.forEach((response, index) => {
                const dataSourceBatch = dataSourcesRequests[index];

                dataSourceBatch.forEach((dataSource) => {
                    const versionId = dataSource.deviceDefinitionVersionIds[0];

                    if (dataSource.aggregationFunction === AggregationFunctionType.UNIQUE_COUNT) {
                        get(response, ["data_sources", dataSource.dataSourceKey]).forEach((item) => {
                            set(
                                graphResult,
                                [
                                    "data_sources",
                                    dataSource.dataSourceKey,
                                    deviceDefinitionVersionIdToVersionMap[versionId].getVersionName() +
                                        " " +
                                        this.transformUniqueCountValues(
                                            item.values,
                                            dataSource,
                                            uniqueDataResponse
                                        ).join(", "),
                                ],
                                item.count
                            );
                        });
                    } else {
                        const data = get(response, ["data_sources", dataSource.dataSourceKey]);

                        // "data" will be object in case group by is enabled and number in base case
                        if (typeof data === "number" || !isEmpty(data)) {
                            set(
                                graphResult,
                                [
                                    "data_sources",
                                    dataSource.dataSourceKey,
                                    deviceDefinitionVersionIdToVersionMap[versionId].getVersionName(),
                                ],
                                data
                            );
                        }
                    }
                });
            });

            this.setState({
                graphResult: graphResult,
                loadingGraph: false,
                errorLoadingGraph: false,
            });
        } catch (error) {
            this.setState({
                loadingGraph: false,
                errorLoadingGraph: true,
            });
        }
    }

    private getDeviceDefinitionVersionIdToVersionMap = (): Record<string, DeviceDefinitionVersionInfo> => {
        return this.props.deviceDefinitionVersions.reduce((acc, version) => {
            acc[version.entityId] = version;
            return acc;
        }, {});
    };

    private getDataSources(): VisualisationDataSource[] {
        return (this.props.dataVisualisationConfigurations || []).map((dataVisualisationConfiguration) =>
            getVisualisationDataSource(dataVisualisationConfiguration)
        );
    }

    private getDataSourcesRequests(dataSources: VisualisationDataSource[]): VisualisationDataSource[][] {
        const deviceDefinitionVersionBuckets: Record<string, string[]> = {};
        dataSources.forEach((dataSource) => {
            deviceDefinitionVersionBuckets[dataSource.dataSourceKey] = [...dataSource.deviceDefinitionVersionIds];
        });

        const dataSourceKeyToDataSourceMap: Record<string, VisualisationDataSource> = {};
        dataSources.forEach((dataSource) => {
            dataSourceKeyToDataSourceMap[dataSource.dataSourceKey] = dataSource;
        });

        const largestBucket = maxBy(Object.values(deviceDefinitionVersionBuckets), "length");

        const requests = [];
        while (!isEmpty(largestBucket)) {
            const dataSourceBatch: VisualisationDataSource[] = [];

            Object.keys(dataSourceKeyToDataSourceMap).forEach((key) => {
                if (!isEmpty(deviceDefinitionVersionBuckets[key])) {
                    dataSourceBatch.push({
                        ...dataSourceKeyToDataSourceMap[key],
                        deviceDefinitionVersionIds: [deviceDefinitionVersionBuckets[key].shift()],
                    });
                }
            });

            requests.push(dataSourceBatch);
        }

        return requests;
    }
}

export default withStyles(visualisationConfigurationPresentationValuesTableStyle)(
    VisualisationConfigurationPresentationValuesTable
);
