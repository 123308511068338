import React from "react";
import classNames from "classnames";

import { useTitleStyle } from "./Title.style";

export interface TitleProps extends React.ComponentProps<"div"> {}

export function Title({ className, children, ...props }: TitleProps) {
    const classes = useTitleStyle();

    return (
        <div className={classNames(className, classes.title)} {...props}>
            {children}
        </div>
    );
}
