import React from "react";

import { SternumIconProps } from "./SternumIcon.types";

export const BlueCheckmarkIcon = React.forwardRef<SVGSVGElement, SternumIconProps>(function BlueCheckmarkIcon(
    { width = 18, height = 18, color, ...props },
    ref
) {
    return (
        <svg
            ref={ref}
            width={width}
            height={height}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect x="0.75" y="0.75" width="16.5" height="16.5" rx="8.25" fill="#D6E7FF" />
            <path
                d="M13 6L7.5 12L5 9.27273"
                stroke="#4F98F7"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <rect x="0.75" y="0.75" width="16.5" height="16.5" rx="8.25" stroke="#4F98F7" strokeWidth="1.5" />
        </svg>
    );
});
