import { Typography } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import Utils from "../../lib/infra/Utils";
import ActivityInfo from "../../lib/state/ActivityInfo";
import ActivityType from "../../lib/state/ActivityType";
import { GlobalState } from "../../lib/state/GlobalState";
import TableToolbarDisplayState from "../../lib/state/TableToolbarDisplayState";
import ActivitiesList from "../ActivitiesList/ActivitiesList";
import activitiesListModalStyle from "./ActivitiesListModalStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    selectedTabIndex: number;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof activitiesListModalStyle> {
    fullScreenDisplay: boolean;

    preloadedEntities: ActivityInfo[];
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {};
};

/**
 * Displays a bar of metrics.
 */
class ActivitiesListModal extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            selectedTabIndex: 0,
        };
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        // Creating a map between activity type and its activities.
        let activityTypeToEntitiesMap = {};
        for (let i = 0; i < this.props.preloadedEntities.length; i++) {
            let entity = this.props.preloadedEntities[i];

            if (!activityTypeToEntitiesMap[entity.activityType]) {
                activityTypeToEntitiesMap[entity.activityType] = [];
            }

            activityTypeToEntitiesMap[entity.activityType].push(entity);
        }

        // Creating the tabs for activity types.
        let activityTypeToTabInformation = {
            [ActivityType.ATTACK_TRACE_DETECTED]: {
                key: ActivityType.ATTACK_TRACE_DETECTED,
                order: 0,
                displayName: "Attack Attempts",
            },
            [ActivityType.STERNUM_TRIGGER_MET]: {
                key: ActivityType.STERNUM_TRIGGER_MET,
                order: 1,
                displayName: "Alerts",
            },
            [ActivityType.CVE_DETECTED]: {
                key: ActivityType.CVE_DETECTED,
                order: 2,
                displayName: "CVEs",
            },
        };

        let tabsToDisplay = Utils.getObjectKeys(activityTypeToEntitiesMap)
            .map((activityType) => activityTypeToTabInformation[activityType])
            .sort((tabA, tabB) => tabA.order - tabB.order);

        let allActivitiesTab = {
            key: "ALL",
            order: 2,
            displayName: "All",
        };
        tabsToDisplay.unshift(allActivitiesTab);

        return (
            <div>
                {/* Title */}
                <div className={classNames(classes.upperContentContainer, classes.marginBottom)}>
                    <Typography variant={"h5"}>Alerts</Typography>
                </div>

                {/* Tab selection */}
                <Tabs
                    value={this.state.selectedTabIndex}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={(event, value) => this.handleSelectedTab(value)}
                >
                    {/* Getting the tabs */}
                    {tabsToDisplay.map((tab) => (
                        <Tab key={tab.key} label={tab.displayName} />
                    ))}
                </Tabs>

                {/* Activity types */}
                {tabsToDisplay
                    .filter((tab, index) => this.state.selectedTabIndex === index)
                    .map((tab) => {
                        return (
                            <ActivitiesList
                                key={tab.key}
                                preloadedEntities={
                                    activityTypeToEntitiesMap[tab.key]
                                        ? activityTypeToEntitiesMap[tab.key]
                                        : this.props.preloadedEntities
                                }
                                paperClassNames={classNames(
                                    classes.listPaper,
                                    this.props.fullScreenDisplay ? "mod-fullscreen" : "mod-regular"
                                )}
                                toolbarState={
                                    new TableToolbarDisplayState(false, false, false, false, false, false, true)
                                }
                            />
                        );
                    })}
            </div>
        );
    }

    /**
     * Occurs once a tab has been selected.
     *
     */
    private handleSelectedTab(selectedTabIndex) {
        this.setState({
            selectedTabIndex: selectedTabIndex,
        });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(activitiesListModalStyle)(ActivitiesListModal));
