import { makeStyles } from "@material-ui/core";

export const useSimulateAttackStyle = makeStyles((theme) => {
    return {
        root: {
            display: "flex",
            alignItems: "flex-start",
            padding: "24px",
        },
        left: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingRight: "20px",
        },
        right: {
            display: "flex",
            flexShrink: 0,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-end",
        },
        descriptionText: {
            marginTop: "5px",
            fontSize: 14,
            lineHeight: "20px",
            color: "#6d7894",
        },
    };
});
