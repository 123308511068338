import moment from "moment";
import { CategoryTimeRange, DashboardAlertsDateRange } from "../../../../lib/state/DashboardRegularState";

export const MAX_VISIBLE_CATEGORIES = 3;

export function formatDateRanges(
    dateRanges: DashboardAlertsDateRange[],
    selectedOverallRange: CategoryTimeRange
): string {
    if (selectedOverallRange === CategoryTimeRange.LastWeek) {
        return dateRanges
            .map((range) => {
                if (moment.utc(range.from).isSame(range.to, "day")) {
                    return moment.utc(range.from).format("ddd DD");
                }

                const from = moment.utc(range.from).format("ddd DD");
                const to = moment.utc(range.to).format("ddd DD");

                return `${from} - ${to}`;
            })
            .join(", ");
    }

    if (selectedOverallRange === CategoryTimeRange.LastMonth) {
        return dateRanges
            .map((range) => {
                if (moment.utc(range.from).isSame(range.to, "day")) {
                    return moment.utc(range.from).format("DD MMM");
                }

                const from = moment.utc(range.from).format("DD MMM");
                const to = moment.utc(range.to).format("DD MMM");

                return `${from} - ${to}`;
            })
            .join(", ");
    }

    if (selectedOverallRange === CategoryTimeRange.LastYear) {
        return dateRanges
            .map((range) => {
                if (moment.utc(range.from).isSame(range.to, "month")) {
                    return moment.utc(range.from).format("MMMM");
                }

                const from = moment.utc(range.from).format("MMMM");
                const to = moment.utc(range.to).format("MMMM");

                return `${from} - ${to}`;
            })
            .join(", ");
    }
}

export function getUniqueDateRanges(ranges: DashboardAlertsDateRange[]): DashboardAlertsDateRange[] {
    const occurancesMap: Record<string, boolean> = {};
    const result: DashboardAlertsDateRange[] = [];

    ranges.forEach((range) => {
        const key = `${range.from}-${range.to}`;
        if (!occurancesMap[key]) {
            result.push(range);
        }

        occurancesMap[key] = true;
    });

    return result;
}

export function joinOverlappingDateRanges(ranges: DashboardAlertsDateRange[]): DashboardAlertsDateRange[] {
    ranges.sort((a, b) => a.from - b.from);
    const stack: DashboardAlertsDateRange[] = [];

    ranges.forEach((range) => {
        const lastRange = { ...stack.pop() };
        if (Object.keys(lastRange).length === 0) {
            stack.push({ ...range });
            return;
        }

        /**
         * lastRange:   |-----------|
         * range:         |------|
         *
         * when current range is inside the last range union, we just skip this range
         */
        if (range.from >= lastRange.from && range.to <= lastRange.to) {
            stack.push(lastRange);
            return;
        }

        /**
         * lastRange:       |-----------|
         * range:       |----------|
         */
        let hasOverlap = false;
        if (range.to >= lastRange.from && range.to <= lastRange.to) {
            hasOverlap = true;
            lastRange.from = range.from;
        }

        /**
         * lastRange:   |----------|
         * range:            |-----------|
         *
         * "+1" is needed because in case lastRange.to is the end of the day
         * and range.from is the start of the next day, we still want to count it as overlap,
         * so "+1" adds one millisecond to connect two ends
         */
        if (range.from <= lastRange.to + 1 && range.from >= lastRange.from) {
            hasOverlap = true;
            lastRange.to = range.to;
        }

        stack.push(lastRange);
        if (!hasOverlap) {
            stack.push(range);
        }
    });

    return stack;
}
