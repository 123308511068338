import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
    Typography,
    withStyles,
    WithStyles,
} from "@material-ui/core";
import classNames from "classnames";
import { throttle } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import HashSet from "../../../lib/infra/HashSet";
import SternumUtils from "../../../lib/infra/SternumUtils";
import ServiceWire from "../../../lib/services/ServiceWire";
import AutoCompleteLibrary from "../../../lib/state/AutoCompleteLibrary";
import DeviceDefinitionInfo from "../../../lib/state/DeviceDefinitionInfo";
import DeviceDefinitionLibraryPartial from "../../../lib/state/DeviceDefinitionLibraryPartial";
import DeviceDefinitionPartial from "../../../lib/state/DeviceDefinitionPartial";
import { GlobalState } from "../../../lib/state/GlobalState";
import Autocomplete from "../../../shared_components/Autocomplete/Autocomplete";
import CustomInput from "../../../shared_components/CustomInput/CustomInput";
import librarySimpleDialogStyle from "./LibrariesSimpleDialogStyle";

const VERSION_REGEX = /^(\d+\.)+\d+.*/;

interface AppState {
    libraryName: string;
    libraryVersion: string;
    libraryVendor: string;
    librarySource: string;
    deviceDefinition?: DeviceDefinitionPartial;
    // indicate if buttons should be in disable mode
    isDuplicateError: boolean;
    loadingAutocomplete: boolean;
    autocompleteLibraries: { label: string; value: { library: string; vendor: string }; key: string }[];
    displayLoadingBarOnSave: boolean;
    displayLoadingBarOnAddAnother: boolean;
}

export interface AppProps extends WithStyles<typeof librarySimpleDialogStyle> {
    open: boolean;
    deviceDefinition?: DeviceDefinitionInfo;
    existingDeviceDefinitionLibrary?: any;
    onClose: (
        libraryName: string,
        libraryVersion: string,
        libraryVendor: string,
        librarySource: string,
        keepDialogOpen: boolean,
        selectedLibrary?: DeviceDefinitionLibraryPartial
    ) => void;
    onCancel: () => void;
    duplicationCheck: (
        libraryName: string,
        libraryVersion: string,
        libraryVendor: string,
        librarySource: string
    ) => boolean;
    isError?: boolean;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {};
};

class LibrarySimpleDialog extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);
        // Initializing the state to default.
        if (this.props.existingDeviceDefinitionLibrary) {
            this.state = {
                loadingAutocomplete: false,
                autocompleteLibraries: [],
                libraryName: this.props.existingDeviceDefinitionLibrary.getLibraryName(),
                libraryVersion: this.props.existingDeviceDefinitionLibrary.getLibraryVersion(),
                libraryVendor: this.props.existingDeviceDefinitionLibrary.getLibraryVendor(),
                librarySource: this.props.existingDeviceDefinitionLibrary.getLibrarySource(),
                isDuplicateError: false,
                displayLoadingBarOnSave: false,
                displayLoadingBarOnAddAnother: false,
            };
        } else {
            this.state = {
                loadingAutocomplete: false,
                autocompleteLibraries: [],
                libraryName: "",
                libraryVersion: "",
                libraryVendor: "",
                librarySource: "",
                isDuplicateError: false,
                displayLoadingBarOnSave: false,
                displayLoadingBarOnAddAnother: false,
            };
        }
    }

    /**
     *  Validate fields are not empty
     */
    private fieldsValidation(): boolean {
        // It's update operation, return true only if state fields match to existing item fields
        if (this.props.existingDeviceDefinitionLibrary) {
            return (
                this.state.libraryName === this.props.existingDeviceDefinitionLibrary.getLibraryName() &&
                this.state.librarySource === this.props.existingDeviceDefinitionLibrary.getLibrarySource() &&
                this.state.libraryVersion === this.props.existingDeviceDefinitionLibrary.getLibraryVersion() &&
                this.state.libraryVendor === this.props.existingDeviceDefinitionLibrary.getLibraryVendor()
            );
        }
        // It's new library, make sure needed fields are not empty
        return this.state.libraryName.trim() === "" || this.state.libraryVersion.trim() === "";
    }

    private loadAutocompleteLibraries = async (searchTerm: string) => {
        this.setState({ loadingAutocomplete: true, autocompleteLibraries: [] });
        const serverResponse: AutoCompleteLibrary[] = await ServiceWire.getSternumService().getAutocompleteLibraries(
            searchTerm
        );
        if (serverResponse) {
            const autocompleteLibraries = [];
            serverResponse.map((libraryIter) => {
                autocompleteLibraries.push({ library: libraryIter.libraryName, vendor: libraryIter.libraryVendor });
            });
            const autoList = autocompleteLibraries.map((item) => ({
                label: this.addVendorName(item.library, item.vendor),
                value: item,
                key: this.addVendorName(item.library, item.vendor),
            }));
            this.setState({
                autocompleteLibraries: autoList,
            });
        }
        this.setState({
            loadingAutocomplete: false,
        });
    };

    private throttled = throttle(this.loadAutocompleteLibraries, 1000, { trailing: true });

    /**
     *  Update definition field
     */
    private onLibraryNameChanged = (userInput: string) => {
        if (userInput && userInput.length > 1) {
            this.throttled(userInput);
        }

        this.setState({
            libraryName: userInput,
        });
    };

    /**
     *  Update version field
     */
    private onLibraryVersionChanged = (event) => {
        this.setState({
            libraryVersion: event.target.value,
        });
    };

    /**
     *  Update vendor field
     */
    private onLibraryVendorChanged = (event) => {
        this.setState({
            libraryVendor: event.target.value,
        });
    };

    private onLibrarySelected = (item: { library: string; vendor: string }) => {
        this.setState({
            libraryVendor: item.vendor,
            libraryName: item.library,
        });
    };

    /**
     *  Update source field
     */
    private onLibrarySourceChanged = (event) => {
        this.setState({
            librarySource: event.target.value,
        });
    };

    private addVendorName(libraryName: string, vendorName: string) {
        return `(${vendorName}) ${libraryName}`;
    }

    /**
     * Handle save click
     */
    private onSaveClick = (keepDialogOpen: boolean) => {
        if (!this.checkDuplication()) {
            this.setState({ displayLoadingBarOnSave: !keepDialogOpen, displayLoadingBarOnAddAnother: keepDialogOpen });
            this.props.onClose(
                this.state.libraryName,
                this.state.libraryVersion,
                this.state.libraryVendor,
                this.state.librarySource,
                keepDialogOpen,
                this.props.existingDeviceDefinitionLibrary
            );
            if (keepDialogOpen) {
                this.clearFields();
            }
        }
    };

    /**
     * Check if user trying to create/update to existing library
     */
    private checkDuplication = (): boolean => {
        const isDuplicate: boolean = this.props.duplicationCheck(
            this.state.libraryName,
            this.state.libraryVersion,
            this.state.libraryVendor,
            this.state.librarySource
        );
        if (isDuplicate === true) {
            if (this.state.isDuplicateError === false) {
                this.setState({
                    isDuplicateError: true,
                });
            }
        } else {
            if (this.state.isDuplicateError === true) {
                this.setState({
                    isDuplicateError: false,
                });
            }
        }
        return isDuplicate;
    };

    /**
     * Clear fields for add another operation
     */
    private clearFields = () => {
        this.setState({
            libraryName: "",
            libraryVersion: "",
            libraryVendor: "",
            librarySource: "",
            isDuplicateError: false,
            displayLoadingBarOnSave: false,
            displayLoadingBarOnAddAnother: false,
        });
    };

    /**
     * Handle close click
     */
    private handleCloseClick = () => {
        this.props.onCancel();
    };

    private getAutocomplete = () => this.state.autocompleteLibraries;

    render() {
        const { classes } = this.props;

        const duplicationError = (
            <Typography variant={"subtitle2"} className={classNames(classes.marginBottomXLarge)} color="error">
                3ʳᵈ Party already exists!
            </Typography>
        );

        const serverError = (
            <Typography variant={"subtitle2"} className={classNames(classes.marginBottomLarge)} color="error">
                Operation failed
            </Typography>
        );

        return (
            <Dialog
                role="dialog"
                aria-label="add 3rd party"
                aria-labelledby="simple-dialog-title"
                open={this.props.open}
                onClose={() => {
                    this.props.existingDeviceDefinitionLibrary ? this.handleCloseClick() : "";
                }}
            >
                {/* Title */}
                <DialogTitle id="simple-dialog-title" className={classNames(classes.flexVMiddle)}>
                    {this.props.existingDeviceDefinitionLibrary ? "Edit" : "Add"} 3ʳᵈ Party
                </DialogTitle>

                <div className={classNames(classes.flexColumn, classes.flexCenter, classes.flexVMiddle, classes.root)}>
                    {/* Display Name */}

                    <div className={classNames(classes.marginBottomLarge, classes.fullWidth)}>
                        <Autocomplete
                            value={this.state.libraryName}
                            optionList={this.state.autocompleteLibraries}
                            inputTitle="3ʳᵈ Party Name"
                            inputClasses=""
                            maxHeightClass={classes.autocompleteMaxHeight}
                            autoFocus={true}
                            onChange={this.onLibraryNameChanged}
                            onOptionSelect={this.onLibrarySelected}
                            id={"library-auto-complete"}
                            loadingIcon={this.state.loadingAutocomplete}
                            maxLength={SternumUtils.getMaxInputFieldSize()}
                        />
                    </div>

                    {/* Display library bendor */}
                    <div className={classNames(classes.fullWidth, classes.marginBottomLarge)}>
                        <CustomInput
                            value={this.state.libraryVendor}
                            inputTitle={"3ʳᵈ Party Vendor"}
                            maxHeightClass={""}
                            inputClasses={""}
                            onChange={this.onLibraryVendorChanged}
                            required={false}
                            autoFocus={false}
                            id={"3rd-party-vendor"}
                            maxLength={SternumUtils.getMaxInputFieldSize()}
                        />
                    </div>

                    {/* Display library version */}
                    <div className={classNames(classes.fullWidth, classes.marginBottomLarge)}>
                        <CustomInput
                            value={this.state.libraryVersion}
                            inputTitle={"3ʳᵈ Party Version"}
                            maxHeightClass={""}
                            inputClasses={""}
                            onChange={this.onLibraryVersionChanged}
                            required={true}
                            autoFocus={false}
                            id={"3rd-party-version"}
                            maxLength={SternumUtils.getMaxInputFieldSize()}
                        />
                    </div>

                    {/* Display library URL */}
                    <div className={classNames(classes.fullWidth, classes.marginBottomLarge)}>
                        <CustomInput
                            value={this.state.librarySource}
                            inputTitle={"3ʳᵈ Party URL"}
                            maxHeightClass={""}
                            inputClasses={""}
                            onChange={this.onLibrarySourceChanged}
                            required={false}
                            autoFocus={false}
                            id={"3rd-party-url"}
                            urlPrefix={false}
                            placeHolder={"https://repo-example.com"}
                        />
                    </div>

                    {/* Library duplication Error */}
                    {this.state.isDuplicateError ? duplicationError : " "}

                    {/* Server Error */}
                    {this.props.isError ? serverError : " "}
                </div>
                <DialogActions role="presentation" aria-label="buttons container">
                    {/** Cancel button */}
                    <Button
                        variant="contained"
                        color="default"
                        onClick={this.handleCloseClick}
                        disabled={
                            (this.state.displayLoadingBarOnAddAnother || this.state.displayLoadingBarOnSave) &&
                            !this.props.isError
                        }
                    >
                        Close
                    </Button>
                    {/** Update or save button */}
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={
                            (this.fieldsValidation() ||
                                this.state.displayLoadingBarOnAddAnother ||
                                this.state.displayLoadingBarOnSave) &&
                            !this.props.isError
                        }
                        onClick={() => this.onSaveClick(false)}
                    >
                        {this.props.existingDeviceDefinitionLibrary ? "Update" : "Save"}
                        {/* Loading */}
                        {this.state.displayLoadingBarOnSave && !this.props.isError && (
                            <CircularProgress size={20} color="inherit" />
                        )}
                    </Button>
                    {/** Add another one filed */}
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={
                            (this.fieldsValidation() ||
                                this.state.displayLoadingBarOnAddAnother ||
                                this.state.displayLoadingBarOnSave) &&
                            !this.props.isError
                        }
                        onClick={() => this.onSaveClick(true)}
                    >
                        {"Add Another"}
                        {/* Loading */}
                        {this.state.displayLoadingBarOnAddAnother && !this.props.isError && (
                            <CircularProgress size={20} color="inherit" />
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(librarySimpleDialogStyle)(LibrarySimpleDialog));
