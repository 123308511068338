import { Action } from "redux";
import FetchError from "../../state/FetchError";
import ActionType from "../ActionType";

/**
 * Occurs once there has been an error fetching a trace from API.
 */
export interface ErrorFetchingGeneratedEventAction extends Action<ActionType> {
    type: ActionType.ErrorFetchingGeneratedEvent;
    generatedEventId: string;
    error: FetchError;
}

/**
 * Occurs once there has been an error fetching a trace from API.
 */
export function errorFetchingGeneratedEventAction(
    generatedEventId: string,
    error: FetchError
): ErrorFetchingGeneratedEventAction {
    return {
        type: ActionType.ErrorFetchingGeneratedEvent,
        generatedEventId: generatedEventId,
        error: error,
    };
}
