import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    CircularProgress,
    Divider,
    Icon,
    IconButton,
    Tooltip,
    Typography,
    withStyles,
} from "@material-ui/core";
import { WithStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import HashMap from "../../../lib/infra/HashMap";
import Utils from "../../../lib/infra/Utils";
import WebUtils from "../../../lib/infra/WebUtils";
import ServiceWire from "../../../lib/services/ServiceWire";
import ArgumentDefinitionPartial from "../../../lib/state/ArgumentDefinitionPartial";
import DeviceDefinitionVersionInfo from "../../../lib/state/DeviceDefinitionVersionInfo";
import GetDeleteSummaryResponse from "../../../lib/state/GetDeleteSummaryResponse";
import { GlobalState } from "../../../lib/state/GlobalState";
import ServerEntityType from "../../../lib/state/ServerEntityType";
import ConfirmationDialog from "../../../shared_components/ConfirmationDialog/ConfirmationDialog";
import ExplanationComponent from "../../ExplanationComponent/ExplanationComponent";
import ArgumentDefinitionSimpleDialog from "../ArgumentDefinitionSimpleDialog/ArgumentDefinitionSimpleDialog";
import argumentDefinitionStepStyle from "./ArgumentDefinitionStepStyle";

interface AppState {
    selectedArgument: ArgumentDefinitionPartial;
    argumentDialogStatus: boolean;
    deviceDefinition: any;
    expanded: string;
    // indicate if buttons should be in disable mode
    isDisabledMode: boolean;
    openConfirmationDialog: boolean;
    argumentDefinitionToDelete: any;
    onOperationMap: HashMap<boolean>;
    isError: boolean;
    deleteCount: GetDeleteSummaryResponse;
}

export interface AppProps extends WithStyles<typeof argumentDefinitionStepStyle> {
    deviceDefinition: any;
    deviceDefinitionVersion?: DeviceDefinitionVersionInfo;
    forceCRUD?: boolean;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {};
};

class ArgumentDefinitionStep extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);
        // Initializing the state to default.

        this.state = {
            deviceDefinition: this.props.deviceDefinition,
            argumentDialogStatus: false,
            selectedArgument: null,
            expanded: null,
            isDisabledMode:
                (!!this.props.forceCRUD && this.props.forceCRUD && this.props.deviceDefinitionVersion.isProduction()) ||
                !ServiceWire.getAuthorizationService().canEdit(ServerEntityType.ARGUMENT_DEFINITION)
                    ? true
                    : false,
            openConfirmationDialog: false,
            argumentDefinitionToDelete: null,
            onOperationMap: new HashMap<boolean>(),
            isError: false,
            deleteCount: null,
        };
    }

    /*
     * Handle expand panel click
     */
    handleChange = (panel) => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : null,
        });
    };

    /*
     * Handle delete click
     */
    handleDeleteClick = async () => {
        // Close the dialog to prevent double delete click
        this.setState({ openConfirmationDialog: false });

        if (this.isForceCRUD()) {
            const deleteResponse = await ServiceWire.getSternumService().deleteArgumentDefinition(
                this.state.argumentDefinitionToDelete.entityId,
                this.props.deviceDefinitionVersion.entityId
            );
            if (deleteResponse) {
                // remove current trace from activity map to hide load icon
                this.setState({
                    onOperationMap: HashMap.copyAndRemove(
                        this.state.onOperationMap,
                        this.state.argumentDefinitionToDelete.entityId
                    ),
                });
            }

            // Delete in place triggers associated to deleted argument definition
            this.props.deviceDefinitionVersion.deviceDefinition.deleteAssociatedTriggersByArgumentDefinition(
                this.state.argumentDefinitionToDelete.entityId
            );
        }

        this.state.deviceDefinition.removeArgument(this.state.argumentDefinitionToDelete);

        this.setState({
            deviceDefinition: this.state.deviceDefinition,
            argumentDefinitionToDelete: null,
        });
    };

    /*
     * Handle edit click
     */
    handleEditClick = (selectedItem) => (event) => {
        this.setState({
            selectedArgument: selectedItem,
            argumentDialogStatus: true,
        });
    };

    /*
     * Handle add click
     */
    handleAddClick = (event) => {
        this.setState({
            argumentDialogStatus: true,
        });
    };

    /*
     * Check if argument exists
     */
    checkIfArgumentExists = (argumentName: string, argumentEvent: string) => {
        const index = this.state.deviceDefinition.findArgumentIndex(
            new ArgumentDefinitionPartial(argumentName, argumentEvent)
        );
        return index > -1 ? true : false;
    };

    /*
     * Handle close dialog operation
     */
    handleSaveDialog = async (
        argumentName: string,
        argumentEventName: string,
        keepDialogOpen: boolean,
        selectedArgument?: ArgumentDefinitionPartial
    ) => {
        if (argumentName !== "" && !this.checkIfArgumentExists(argumentName, argumentEventName)) {
            {
                try {
                    this.setState({ isError: false });
                    if (this.isForceCRUD()) {
                        // send request to server
                        await this.UpdateOrCreateArgument(
                            argumentName,
                            argumentEventName,
                            this.props.deviceDefinitionVersion.entityId,
                            selectedArgument
                        );
                    } else {
                        // update in place
                        this.UpdateOrCreateInPlace(argumentName, argumentEventName, selectedArgument);
                    }
                    this.setState({
                        argumentDialogStatus: keepDialogOpen ? true : false,
                        deviceDefinition: this.state.deviceDefinition,
                        selectedArgument: null,
                        expanded: "custom",
                    });
                } catch (err) {
                    this.setState({ isError: true });
                }
            }
        }
    };

    private closeDialog = () => {
        this.setState({
            argumentDialogStatus: false,
            selectedArgument: null,
            isError: false,
        });
    };

    /*
     * Check if operation should be taken inplace (it's setup operation) or send request to server.
     */
    isForceCRUD = (): boolean => {
        return this.props.forceCRUD && this.props.forceCRUD === true;
    };

    /*
     * Update or crate argument in server
     */
    private async UpdateOrCreateArgument(
        argumentName: string,
        argumentEventName: string,
        deviceDefinitionVersionId: string,
        selectedArgument?: any
    ) {
        let argumentDefinitionResponse = undefined;
        // Set server error to false

        if (selectedArgument) {
            //Update existing argument definition
            selectedArgument.displayName = argumentName;
            selectedArgument.argumentEventName = argumentEventName;
            argumentDefinitionResponse = await ServiceWire.getSternumService().updateArgumentDefinition(
                selectedArgument,
                deviceDefinitionVersionId
            );
        } else {
            //Create new argument definition
            const argumentObject = new ArgumentDefinitionPartial(argumentName, argumentEventName);
            argumentDefinitionResponse = await ServiceWire.getSternumService().createArgumentDefinition(
                deviceDefinitionVersionId,
                argumentObject
            );
        }

        if (argumentDefinitionResponse && argumentDefinitionResponse.length > 0) {
            this.updateCurrentDisplayList(argumentDefinitionResponse[0]);
        }
    }

    /**
     * Open delete confirmation dialog
     */

    private openDeleteConfirmationDialog = async (argumentDefinitionToDelete) => {
        if (this.props.forceCRUD) {
            // Load delete summary
            this.setState({
                onOperationMap: HashMap.copyAndPut(
                    this.state.onOperationMap,
                    argumentDefinitionToDelete.entityId,
                    true
                ),
            });
            try {
                const deleteSummary: GetDeleteSummaryResponse = await ServiceWire.getSternumService().getDeleteSummary(
                    argumentDefinitionToDelete.entityId,
                    this.props.deviceDefinitionVersion.entityId
                );

                this.setState({
                    deleteCount: deleteSummary,
                });
            } catch (err) {
                this.setState({
                    onOperationMap: HashMap.copyAndRemove(
                        this.state.onOperationMap,
                        argumentDefinitionToDelete.entityId
                    ),
                });
            }
        }
        // Open confirmation dialog
        this.setState({
            openConfirmationDialog: true,
            argumentDefinitionToDelete: argumentDefinitionToDelete,
        });
    };

    /**
     * Close confirmation dialog on cancel click
     */

    private closeDeleteConfirmationDialog = () => {
        if (this.props.forceCRUD) {
            this.state.onOperationMap.remove(this.state.argumentDefinitionToDelete.entityId);
        }
        this.setState({ openConfirmationDialog: false });
    };

    /*
     * Copy to clipboard
     */
    handleCopyToClipBoard = (selectedArgument) => (event) => {
        WebUtils.copyContentToClipBoard(selectedArgument.argumentEventName);
    };

    /*
     * Update or crate argument locally
     */

    private UpdateOrCreateInPlace(argumentName: string, argumentEventName: string, selectedArgument?: any) {
        if (selectedArgument) {
            selectedArgument.displayName = argumentName;
            selectedArgument.argumentEventName = argumentEventName;
            this.updateCurrentDisplayList(selectedArgument);
        } else {
            const newArgument = new ArgumentDefinitionPartial(argumentName, argumentEventName);
            this.updateCurrentDisplayList(newArgument);
        }
    }

    /*
     * Help function for updating current argument list.
     */

    private updateCurrentDisplayList = (argumentObject: any) => {
        const argumentIndex = this.state.deviceDefinition.findArgumentIndex(argumentObject);
        if (argumentIndex < 0) {
            // add new argument
            this.state.deviceDefinition.argumentDefinitions.push(argumentObject);
        } else {
            // update existing one
            this.state.deviceDefinition.argumentDefinitions.splice(argumentIndex, 1, argumentObject);
        }
    };

    render() {
        const { classes } = this.props;
        return (
            <div className={classNames(classes.flexColumn, classes.flexCenter, classes.flexVMiddle, classes.root)}>
                {/** Title */}
                <div className={classes.flexRow}>
                    <Typography variant={"subtitle2"} className={classNames(classes.marginBottomMedium)}>
                        Argument Definitions
                    </Typography>

                    <ExplanationComponent>
                        <Typography variant="body2">
                            Arguments are much like function parameters. They are used to deliver in real-time the
                            actual value of parameters within a trace. For instance, “connect” is a trace with a
                            possible argument definition of “IP address”. Arguments are later used to create advanced
                            rule-based detection of anomalous behavior, alerts and analytics based on the collected data
                            in real-time and can help enforce specific security policies.
                        </Typography>
                    </ExplanationComponent>
                </div>

                {/** System arguments expansion panel */}
                <Accordion
                    role="presentation"
                    aria-label="expansion panel"
                    key={"System Arguments"}
                    expanded={this.state.expanded === "system"}
                    onChange={this.handleChange("system")}
                    className={classNames(classes.fullWidth)}
                >
                    {/** System arguments */}
                    <AccordionSummary
                        role="presentation"
                        aria-label="expansion panel summary"
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography variant="body2" className={classes.heading}>
                            System arguments
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                        role="presentation"
                        aria-label="expansion panel details"
                        className={classNames(classes.argumentsList)}
                    >
                        <div id="systemContainer" className={classNames(classes.flexColumn, classes.fullWidth)}>
                            {/* display system arguments from server */}
                            {this.props.deviceDefinition.argumentDefinitions.map((argumentDefinitionIter, index) => {
                                if (argumentDefinitionIter.isSystemArgument() && argumentDefinitionIter.displayInUI) {
                                    return (
                                        <div
                                            role="presentation"
                                            aria-label="system argument item"
                                            key={index + "System-Argument"}
                                            className={classNames(classes.flexColumn)}
                                        >
                                            <div
                                                className={classNames(
                                                    classes.flexSpaceBetween,
                                                    classes.argumentItem,
                                                    classes.flexVMiddle
                                                )}
                                            >
                                                {/** Argument name */}
                                                <Typography
                                                    noWrap
                                                    variant="subtitle2"
                                                    className={classNames(classes.systemDisplayName)}
                                                >
                                                    {Utils.capitalize(argumentDefinitionIter.displayName)}
                                                </Typography>

                                                {/** Argument event name */}
                                                <Typography
                                                    variant="subtitle2"
                                                    noWrap
                                                    className={classNames(classes.systemRoleName)}
                                                >
                                                    {argumentDefinitionIter.argumentEventName}
                                                </Typography>

                                                {/* Separator */}
                                                <div className={classNames(classes.flexGrow)} />

                                                {/** Copy argument event name button */}
                                                <div>
                                                    <Tooltip
                                                        title="Copy System Argument"
                                                        classes={{ tooltip: classes.toolTip }}
                                                    >
                                                        <IconButton
                                                            aria-label="copy"
                                                            className={classes.smallIconButton}
                                                            onClick={this.handleCopyToClipBoard(argumentDefinitionIter)}
                                                        >
                                                            <Icon
                                                                className={classNames("fas fa-paste")}
                                                                fontSize={"small"}
                                                                color="primary"
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                            <Divider />
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    </AccordionDetails>
                </Accordion>

                {/** Custom arguments expansion panel */}
                <Accordion
                    role="presentation"
                    aria-label="expansion panel"
                    key={"Custom Arguments"}
                    expanded={this.state.expanded === "custom"}
                    onChange={this.handleChange("custom")}
                    className={classNames(classes.fullWidth, classes.marginBottomMedium)}
                >
                    {/** Custom arguments */}
                    <AccordionSummary
                        role="presentation"
                        aria-label="expansion panel summary"
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography variant="body2" className={classes.heading}>
                            Customized arguments
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                        role="presentation"
                        aria-label="expansion panel details"
                        className={classNames(classes.argumentsList)}
                    >
                        <div className={classNames(classes.flexColumn, classes.fullWidth)}>
                            {/* Argument list */}
                            {this.state.deviceDefinition.argumentDefinitions.map((argumentDefinitionIter) => {
                                if (!argumentDefinitionIter.isSystemArgument()) {
                                    return (
                                        <div
                                            role="presentation"
                                            aria-label="customized argument item"
                                            className={classNames(classes.flexColumn)}
                                        >
                                            <div
                                                className={classNames(
                                                    classes.flexSpaceBetween,
                                                    classes.flexVMiddle,
                                                    classes.argumentItem
                                                )}
                                            >
                                                {/** Argument name */}
                                                <div className={classNames(classes.customDisplayName)}>
                                                    <Typography noWrap variant="subtitle2">
                                                        {Utils.capitalize(argumentDefinitionIter.displayName)}
                                                    </Typography>
                                                </div>

                                                {/** Argument event name */}
                                                <div className={classNames(classes.customDisplayRoleName)}>
                                                    <Typography noWrap variant="subtitle2">
                                                        {argumentDefinitionIter.argumentEventName}
                                                    </Typography>
                                                </div>

                                                {/* Separator */}
                                                <div className={classNames(classes.flexGrow)} />

                                                {this.props.forceCRUD &&
                                                this.state.onOperationMap.containsKey(
                                                    argumentDefinitionIter.entityId
                                                ) ? (
                                                    <CircularProgress size={16} className={classes.marginRightXs} />
                                                ) : (
                                                    <div>
                                                        {/** Argument copy */}
                                                        <Tooltip
                                                            title="Copy Custom Argument"
                                                            classes={{ tooltip: classes.toolTip }}
                                                        >
                                                            <IconButton
                                                                aria-label="copy"
                                                                className={classes.smallIconButton}
                                                                onClick={this.handleCopyToClipBoard(
                                                                    argumentDefinitionIter
                                                                )}
                                                            >
                                                                <Icon
                                                                    className={classNames("fas fa-paste")}
                                                                    fontSize={"small"}
                                                                    color="primary"
                                                                />
                                                            </IconButton>
                                                        </Tooltip>

                                                        {/** Argument edit button*/}
                                                        <Tooltip title="Edit" classes={{ tooltip: classes.toolTip }}>
                                                            <IconButton
                                                                aria-label="edit"
                                                                className={classes.smallIconButton}
                                                                onClick={this.handleEditClick(argumentDefinitionIter)}
                                                                disabled={this.state.isDisabledMode}
                                                            >
                                                                <EditIcon
                                                                    fontSize="small"
                                                                    color={
                                                                        this.state.isDisabledMode
                                                                            ? "disabled"
                                                                            : "primary"
                                                                    }
                                                                />
                                                            </IconButton>
                                                        </Tooltip>

                                                        {/** Argument delete button */}
                                                        <Tooltip title="Delete" classes={{ tooltip: classes.toolTip }}>
                                                            <IconButton
                                                                aria-label="delete"
                                                                className={classes.smallIconButton}
                                                                disabled={this.state.isDisabledMode}
                                                                onClick={() =>
                                                                    this.openDeleteConfirmationDialog(
                                                                        argumentDefinitionIter
                                                                    )
                                                                }
                                                            >
                                                                <DeleteIcon
                                                                    fontSize="small"
                                                                    color={
                                                                        this.state.isDisabledMode ? "disabled" : "error"
                                                                    }
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                )}
                                            </div>
                                            <Divider />
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    </AccordionDetails>
                </Accordion>

                <div className={classNames(classes.fullWidth, classes.flexEnd)}>
                    <Button
                        disabled={this.state.isDisabledMode}
                        variant="contained"
                        color="primary"
                        onClick={this.handleAddClick}
                    >
                        Create Argument
                    </Button>
                </div>

                {/* Delete confirmation dialog */}
                {this.state.argumentDefinitionToDelete && (
                    <ConfirmationDialog
                        open={this.state.openConfirmationDialog}
                        title={`Delete ${this.state.argumentDefinitionToDelete.displayName} ?`}
                        body={"All data associated to this argument definition will be deleted."}
                        handleCancel={this.closeDeleteConfirmationDialog}
                        handleApprove={this.handleDeleteClick}
                        overrideActionName={"Delete"}
                    >
                        {this.state.deleteCount && this.state.deleteCount.triggersCount > 0 && (
                            <div className={classNames(classes.flexRow)}>
                                <Typography variant={"subtitle1"} className={classNames(classes.commonBold)}>
                                    {this.state.deleteCount.triggersCount}
                                </Typography>
                                <Typography gutterBottom variant={"subtitle1"}>
                                    &nbsp;
                                </Typography>
                                <Typography gutterBottom variant={"subtitle1"}>
                                    {"associated alerts will be deleted."}
                                </Typography>
                            </div>
                        )}
                    </ConfirmationDialog>
                )}
                {/* Make sure argument dialog is created each time the state is changed */}
                {(() => {
                    if (this.state.argumentDialogStatus) {
                        return (
                            <ArgumentDefinitionSimpleDialog
                                selectedArgument={this.state.selectedArgument}
                                open={this.state.argumentDialogStatus}
                                onClose={this.handleSaveDialog}
                                duplicationCheck={this.checkIfArgumentExists}
                                onCancel={this.closeDialog}
                                isError={this.state.isError}
                            />
                        );
                    }
                })()}
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(argumentDefinitionStepStyle)(ArgumentDefinitionStep));
