import { call, put, select, takeEvery } from "redux-saga/effects";
import { fetchedLibrarySuccessfullyAction } from "./FetchedLibrarySuccessfullyAction";
import { errorFetchingLibraryAction } from "./ErrorFetchingLibraryAction";
import FetchError from "../../state/FetchError";
import ActionType from "../ActionType";
import ServiceWire from "../../services/ServiceWire";
import { FetchLibraryAction } from "./FetchLibraryAction";
import librarySelector from "./LibrarySelector";

/**
 * Fetches a library from API.
 */
export function* fetchLibraryAsync(action: FetchLibraryAction) {
    try {
        // Trying to retrieve library from cache.
        let library = yield select(librarySelector, action.libraryId);

        if (!library) {
            // Library not found in cache, calling API for getting the library.
            library = yield call(
                [ServiceWire.getSternumService(), ServiceWire.getSternumService().getLibraryById],
                action.libraryId
            );
        }

        // Dispatching a successful fetch of library.
        yield put(fetchedLibrarySuccessfullyAction(library));
    } catch (error) {
        // Error occurred, dispatching an erroneous fetch of library.
        yield put(errorFetchingLibraryAction(action.libraryId, new FetchError(error.message)));
    }
}

/**
 * Watching the ActionType.FetchLibrary action for fetching library from API.
 */
export function* watchFetchLibraryAsync() {
    yield takeEvery((action) => action.type === ActionType.FetchLibrary, fetchLibraryAsync);
}
