import { Action } from "redux";
import { DashboardAlertsDateRange } from "../../state/DashboardRegularState";

import ActionType from "../ActionType";

export interface ToggleDateRangeInAlertsFilterAction extends Action<ActionType> {
    type: ActionType.DashboardRegularToggleDateRangeInAlertsFilter;
    category: string;
    dateRange: DashboardAlertsDateRange;
}

export function toggleDateRangeInAlertsFilterAction(
    category: string,
    dateRange: DashboardAlertsDateRange
): ToggleDateRangeInAlertsFilterAction {
    return {
        type: ActionType.DashboardRegularToggleDateRangeInAlertsFilter,
        category,
        dateRange,
    };
}
