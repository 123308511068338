import HashSet from "./HashSet";

/**
 * Defines a simple hash map.
 */
class HashMap<T> {
    /**
     * Holds the actual underlying javascript object.
     */
    private map: { [key: string]: T } = {};

    /**
     * Creates a map from given JS-style dictionary.
     * @param dict The dictionary to create map from.
     */
    public static fromDict<T>(dict: { [key: string]: T }): HashMap<T> {
        let hashMap = new HashMap<T>();
        hashMap.putMap(dict);
        return hashMap;
    }

    /**
     * Creates a map from a single given pair.
     * @param key Key to insert.
     * @param value Value to insert.
     */
    public static fromPair<T>(key: string, value: T) {
        let hashMap = new HashMap<T>();
        hashMap.put(key, value);
        return hashMap;
    }

    /**
     * Returns a copy of the given source map.
     * Note that the copy is at the map level - meaning, any objects that are values are not internally copied.
     * @param source Source map to copy.
     */
    public static copy<T>(source: HashMap<T>): HashMap<T> {
        return HashMap.fromDict(source.getMap());
    }

    /**
     * Copies a map and then removes a key from the copy.
     * @param source Source to copy.
     * @param keyToRemove Key to remove.
     */
    public static copyAndRemove<T>(source: HashMap<T>, keyToRemove: string): HashMap<T> {
        let copiedHashMap = HashMap.copy(source);
        copiedHashMap.remove(keyToRemove);
        return copiedHashMap;
    }

    /**
     * Copies a map and then adds a key and a value to the copy.
     * @param source Source to copy.
     * @param keyToPut Key to put.
     * @param valueToPut Value to put.
     */
    public static copyAndPut<T>(source: HashMap<T>, keyToPut: string, valueToPut: T): HashMap<T> {
        let copiedHashMap = HashMap.copy(source);
        copiedHashMap.put(keyToPut, valueToPut);
        return copiedHashMap;
    }

    /**
     * Returns the underlying map.
     */
    public getMap(): { [key: string]: T } {
        return this.map;
    }

    /**
     * Returns whether given key exists in map.
     * @param key The key to check whether exists.
     */
    public containsKey(key: string): boolean {
        return key && key in this.map;
    }

    /**
     * Removes given key from the map.
     * @param key The key to remove from the map.
     */
    public remove(key: string): void {
        if (key) {
            delete this.map[key];
        }
    }

    /**
     * Puts entire given map into the underlying map.
     * @param map The given map.
     */
    public putMap(map: { [key: string]: T }) {
        for (let key in map) {
            if (map.hasOwnProperty(key)) {
                this.put(key, map[key]);
            }
        }
    }

    /**
     * Puts a key and a value into the map.
     * @param key Key to insert.
     * @param value Value to insert.
     */
    public put(key: string, value: T) {
        this.map[key] = value;
    }

    /**
     * Gets a key from the map.
     * @param key Key to retrieve.
     */
    public get(key: string): T {
        return this.map[key];
    }

    /**
     * Gets a key from the map or returns the default value given if such key does not exist.
     * @param key Key to retrieve.
     * @param defaultValue The value to return if given key does not exist in the map.
     */
    public getOrDefault(key: string, defaultValue: T): T {
        if (this.map[key]) {
            return this.map[key];
        } else {
            return defaultValue;
        }
    }

    /**
     * Gets the collection of map keys as an array.
     */
    public keysArray(): string[] {
        let keys: string[] = [];

        for (let key in this.map) {
            if (this.map.hasOwnProperty(key)) {
                keys.push(key);
            }
        }

        return keys;
    }

    /**
     * Gets the collection of map keys as a set.
     */
    public keys(): HashSet {
        return HashSet.fromValues(this.keysArray());
    }

    /**
     * Returns the values in the map as an array.
     */
    public values(): T[] {
        let values: T[] = [];

        for (let key in this.map) {
            if (this.map.hasOwnProperty(key)) {
                values.push(this.map[key]);
            }
        }

        return values;
    }
}

export default HashMap;
