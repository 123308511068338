import React from "react";

import { DeviceMetrics } from "../DeviceMetrics";
import { GlancesGrid } from "../GlancesGrid";

import { useOverviewTabStyles } from "./OverviewTab.styles";
import DeviceInfo from "../../../../lib/state/DeviceInfo";
import DeviceDefinitionInfo from "../../../../lib/state/DeviceDefinitionInfo";
import DeviceDefinitionVersionInfo from "../../../../lib/state/DeviceDefinitionVersionInfo";
import TimeSelectOption from "../../../../lib/state/TimeSelectOption";

export interface OverviewTabProps {
    deviceId: string;
    device: DeviceInfo;
    deviceDefinition: DeviceDefinitionInfo;
    deviceDefinitionVersion: DeviceDefinitionVersionInfo;
    timeSelectOption: TimeSelectOption;
    startDate: Date;
    endDate: Date;
    refreshEntitiesFilter?: boolean;
}

export function OverviewTab({
    deviceId,
    device,
    deviceDefinition,
    deviceDefinitionVersion,
    timeSelectOption,
    startDate,
    endDate,
    refreshEntitiesFilter,
}: OverviewTabProps) {
    const classes = useOverviewTabStyles();

    return (
        <div className={classes.container}>
            <DeviceMetrics
                deviceId={deviceId}
                startDate={startDate}
                endDate={endDate}
                refreshEntitiesFilter={refreshEntitiesFilter}
            />
            <GlancesGrid
                deviceId={deviceId}
                device={device}
                deviceDefinition={deviceDefinition}
                deviceDefinitionVersion={deviceDefinitionVersion}
                timeSelectOption={timeSelectOption}
                startDate={startDate}
                endDate={endDate}
                refreshEntitiesFilter={refreshEntitiesFilter}
            />
        </div>
    );
}
