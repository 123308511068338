import { Typography } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import Utils from "../../lib/infra/Utils";
import AnalyticsService from "../../lib/services/AnalyticsService";
import ServiceWire from "../../lib/services/ServiceWire";
import AggregatedEventInfo from "../../lib/state/AggregatedEventInfo";
import AggregateOverProperty from "../../lib/state/AggregateOverProperty";
import EventCountsAggregationInfo from "../../lib/state/EventCountsAggregationInfo";
import { GlobalState } from "../../lib/state/GlobalState";
import SternumBarChart from "../SUI/SternumAMCharts/SternumBarChart";
import { ChartEmptyIcon } from "../SUI/SternumIcon/SternumIcon";
import GraphLoader from "../SUI/SternumLoaders/GraphLoader";
import eventCountGraphStyle from "./EventCountGraphStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    loadingGraph: boolean;
    errorLoadingGraph: boolean;
    eventCountsAggregationInfo?: EventCountsAggregationInfo;
    doNotDisplayLoading: boolean;

    startTime: number;
    endTime: number;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof eventCountGraphStyle> {
    /**
     * On which device or device definition are we displaying the graph.
     */
    entityId: string;

    /**
     * Over which property we should aggregate.
     */
    aggregateOverProperty: AggregateOverProperty;

    /**
     * On which date to start the filtering.
     */
    startTime: number;

    /**
     * On which date to end the filtering.
     */
    endTime: number;

    /**
     * Whether we should show loading or not.
     */
    doNotDisplayLoading: boolean;

    /**
     * Indicates whether to display or not to display the categories labels on the x axes.
     */
    doNotDisplayCategoriesLabels: boolean;

    /**
     * The version we're filtering on.
     */
    deviceDefinitionVersionId?: string;

    isCustomTooltip?: boolean;

    limitLabelWidth?: boolean;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {};
};

/**
 * Displays a bar of metrics.
 */
class EventCountGraph extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            loadingGraph: false,
            errorLoadingGraph: false,
            eventCountsAggregationInfo: null,
            doNotDisplayLoading: false,

            startTime: this.props.startTime,
            endTime: this.props.endTime,
        };
    }

    /**
     * Occurs once the component is mounted.
     * We fetch the cve if needed here.
     */
    async componentDidMount() {
        await this.loadGraph(this.state.startTime, this.state.endTime, this.state.doNotDisplayLoading);
    }

    /**
     * Occurs once the component is about to receive props.
     */
    async UNSAFE_componentWillReceiveProps(nextProps: Readonly<AppProps>, nextContext: any) {
        if (nextProps.startTime || nextProps.startTime) {
            this.setState(
                {
                    startTime: nextProps.startTime,
                    endTime: nextProps.endTime,
                    doNotDisplayLoading: nextProps.doNotDisplayLoading,
                },
                async () => {
                    await this.loadGraph(this.state.startTime, this.state.endTime, this.state.doNotDisplayLoading);
                }
            );
        }
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        if (this.state.loadingGraph || (!this.state.eventCountsAggregationInfo && !this.state.errorLoadingGraph)) {
            return (
                <div className={classes.emptyDataContainer}>
                    <GraphLoader />
                </div>
            );
        }

        if (this.state.errorLoadingGraph) {
            return (
                <Typography variant="body2" className={classNames(classes.commonDangerColor)}>
                    Error loading graph...
                </Typography>
            );
        } else if (this.state.eventCountsAggregationInfo) {
            let eventIdentifierKeys: string[] = Object.keys(
                this.state.eventCountsAggregationInfo.eventIdentifierToAggregatedEventInfoMap
            );

            // Sorting all the graph points based on their count.
            let sortedEventIdentifierKeys: string[] = Utils.sortCollection(
                eventIdentifierKeys,
                (eventIdentifier) =>
                    this.state.eventCountsAggregationInfo.eventIdentifierToAggregatedEventInfoMap[eventIdentifier].count
            );

            // First, calculate the data points we need for the graph.
            let dataPointsArray = sortedEventIdentifierKeys.map((eventIdentifier) => {
                return this.state.eventCountsAggregationInfo.eventIdentifierToAggregatedEventInfoMap[eventIdentifier]
                    .count;
            });

            // const transformedDataPoints = GraphUtils.applyMinimumOnDataPoints(dataPointsArray, 5, true);
            const transformedDataPoints = dataPointsArray;

            const labels = sortedEventIdentifierKeys.map(
                (eventIdentifier) =>
                    this.state.eventCountsAggregationInfo.eventIdentifierToAggregatedEventInfoMap[eventIdentifier]
                        .displayName
            );

            let chartData = {
                labels: labels,
                datasets: transformedDataPoints.map((point = 0, index) => {
                    return {
                        data: { value: point },
                        backgroundColor:
                            this.state.eventCountsAggregationInfo.eventIdentifierToAggregatedEventInfoMap[
                                sortedEventIdentifierKeys[index]
                            ].color,
                        originalData: dataPointsArray[index],
                    };
                }),
                tooltips: this.getTooltipContent(
                    this.state.eventCountsAggregationInfo.eventIdentifierToAggregatedEventInfoMap
                ),
            };

            if (chartData.datasets.every((point) => point.data.value === 0)) {
                return (
                    <div className={classes.emptyDataContainer}>
                        <div className={classes.emptyDataInner}>
                            <ChartEmptyIcon />
                            <Typography className={classes.emptyDataText}>You dont have any data yet</Typography>
                        </div>
                    </div>
                );
            }

            return (
                <div className={classNames(classes.root, classes.zIndex50)}>
                    <SternumBarChart
                        id="count-category-bar-chart"
                        height="100%"
                        data={chartData}
                        inverted
                        isCustomTooltip={this.props.isCustomTooltip}
                        limitLabelWidth={this.props.limitLabelWidth}
                        // options={this.getChartOptions(
                        //     this.state.eventCountsAggregationInfo.eventIdentifierToAggregatedEventInfoMap
                        // )}
                    />
                </div>
            );
        }
    }

    /**
     * Gets the content of the tooltip for the graph.
     */
    private getTooltipContent(
        eventIdentifierToAggregatedEventInfoMap: Record<string, AggregatedEventInfo>
    ): Record<string, Record<string, number>> {
        const tooltipsMap: Record<string, Record<string, number>> = {};

        for (let eventIdentifier in eventIdentifierToAggregatedEventInfoMap) {
            const aggregatedEventInfo: AggregatedEventInfo = eventIdentifierToAggregatedEventInfoMap[eventIdentifier];

            let definitionNameToCountMap: Record<string, number> = {};

            if (aggregatedEventInfo.traceDefinitionNameToCountMap) {
                for (let traceDefinitionName in aggregatedEventInfo.traceDefinitionNameToCountMap) {
                    if (aggregatedEventInfo.traceDefinitionNameToCountMap.hasOwnProperty(traceDefinitionName)) {
                        definitionNameToCountMap[traceDefinitionName] =
                            aggregatedEventInfo.traceDefinitionNameToCountMap[traceDefinitionName];
                    }
                }
            }

            if (aggregatedEventInfo.sternumTriggerNameToCountMap) {
                for (let sternumTriggerName in aggregatedEventInfo.sternumTriggerNameToCountMap) {
                    if (aggregatedEventInfo.sternumTriggerNameToCountMap.hasOwnProperty(sternumTriggerName)) {
                        definitionNameToCountMap[sternumTriggerName] =
                            aggregatedEventInfo.sternumTriggerNameToCountMap[sternumTriggerName];
                    }
                }
            }
            tooltipsMap[aggregatedEventInfo.displayName] = definitionNameToCountMap;
        }
        return tooltipsMap;
        /**
                     *   
            if (eventIdentifierToAggregatedEventInfoMap[eventIdentifier]) {


                    // We only want to show a fixed amount of categories - other categories that go beyond
                    // that number will be aggregated under the Other category.
                  const maximumAmountOfLabels: number = 10;
                    let totalAmount: number = 0;
                    let traceDefinitionCountsLabels: string[] = [];
                    let othersAggregator: number = 0;
                    for (let definitionName in definitionNameToCountMap) {
                        if (definitionNameToCountMap.hasOwnProperty(definitionName)) {
                            if (traceDefinitionCountsLabels.length + 1 <= maximumAmountOfLabels) {
                                traceDefinitionCountsLabels.push(
                                    `${definitionName}: ${definitionNameToCountMap[definitionName]}`
                                );

                                totalAmount += definitionNameToCountMap[definitionName];
                            } else {
                                othersAggregator = othersAggregator + definitionNameToCountMap[definitionName];
                            }
                        }
                    }

                    // Adding other category.
                    if (othersAggregator) {
                        traceDefinitionCountsLabels.push(`Other: ${othersAggregator}`);
                    }

                    totalAmount += othersAggregator;

                    if (traceDefinitionCountsLabels.length > 1) {
                        traceDefinitionCountsLabels.unshift(`Total: ${totalAmount}`);
                    }

                    return traceDefinitionCountsLabels;
                    
                    return definitionNameToCountMap

                }
                 */
    }

    /**
     * Loads the graph of the aggregated events.
     */
    private async loadGraph(startTime: number, endTime: number, doNotDisplayLoading: boolean) {
        try {
            // Setting state to loading.
            this.setState({
                loadingGraph: !doNotDisplayLoading,
                errorLoadingGraph: false,
            });

            // Fetch graph info.
            const eventCountsAggregationInfo: EventCountsAggregationInfo =
                await ServiceWire.getSternumService().getEventCountsAggregation(
                    this.props.entityId,
                    startTime,
                    endTime,
                    this.props.aggregateOverProperty,
                    true,
                    this.props.deviceDefinitionVersionId
                );

            // Set state to finish loading.
            this.setState({
                eventCountsAggregationInfo: eventCountsAggregationInfo,
                loadingGraph: false,
                errorLoadingGraph: false,
            });
        } catch (error) {
            AnalyticsService.error("EventCountGraph:loadGraph", error.message);

            // Set error state.
            this.setState({
                loadingGraph: false,
                errorLoadingGraph: true,
            });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(eventCountGraphStyle)(EventCountGraph));
