import { grey } from "@material-ui/core/colors";
import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { sternumTheme } from "../App";
import commonStyle from "../CommonStyle";

const visualisationConfigurationComponentStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            flexGrow: 1,
            flexWrap: "nowrap",
        },

        header: {
            display: "flex",
            alignItems: "center",
            padding: `0 ${theme.spacing(5)}px ${theme.spacing(1)}px ${theme.spacing(5)}px`,
        },

        content: {
            padding: theme.spacing(3, 5),
            flexGrow: 1,
            overflow: "auto",
            minHeight: "2em",
        },

        footer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: `14px ${theme.spacing(5)}px 14px ${theme.spacing(5)}px`,
            flexShrink: 0,
        },

        cancelButtonContainer: {
            marginRight: "8px",
        },

        pencil: {
            fontSize: theme.spacing(3),
            cursor: "pointer",
            marginLeft: theme.spacing(3),
        },

        graphContainer: {
            paddingTop: theme.spacing(1),
            paddingRight: theme.spacing(4),
            paddingLeft: theme.spacing(4),
            paddingBottom: theme.spacing(1),
            height: theme.spacing(42),

            border: "1px solid #e9ecf1",
            boxShadow: "1px 2px 6px 0 rgba(0,0,0,0.07)",

            "&.mod-no-padding": {
                padding: 0,
            },
        },

        percentileSelectionComponent: {
            width: theme.spacing(20),
        },

        valueInputBox: {
            fontSize: "0.8125rem",
            fontWeight: theme.typography.fontWeightRegular,
            width: theme.spacing(4),
        },

        timeRangeSelectComponent: {
            width: theme.spacing(50),
        },

        explanationComponent: {
            marginBottom: "-2px",
        },

        metricIconText: {
            fontWeight: 500,
        },

        closeIcon: {
            cursor: "pointer",
            color: grey[500],
            marginLeft: theme.spacing(2),
            "&:hover": {
                color: theme.palette.common.black,
            },
        },

        metricContainer: {
            paddingTop: theme.spacing(1),
            paddingRight: theme.spacing(4),
            paddingLeft: theme.spacing(4),
            paddingBottom: theme.spacing(1),
            height: theme.spacing(42),
            border: "1px solid #e9ecf1",
            boxShadow: "1px 2px 6px 0 rgba(0,0,0,0.07)",

            display: "flex",
            justifyContent: "center",
            alignItems: "center",

            overflowX: "auto",
            overflowY: "hidden",
        },

        tracesListPaper: {
            maxHeight: theme.spacing(64),
        },

        allTypesContainer: {
            display: "flex",
            alignItems: "center",
            marginBottom: theme.spacing(4),
        },
        selectComponent: {
            minWidth: theme.spacing(36),
        },

        metricTypeContainer: {
            padding: theme.spacing(2),
            border: "1px solid #e9ecf1",
            display: "flex",
            position: "relative",
            boxShadow: "1px 2px 6px 0 rgba(0,0,0,0.07)",
            alignItems: "center",
            marginRight: theme.spacing(3),
            borderRadius: "4px",
            justifyContent: "center",
            cursor: "pointer",

            "&.mod-selected": {
                borderColor: "#b8bcc1",
            },
            "&:hover": {
                borderColor: "#b8bcc1",

                "&.mod-disabled": {
                    border: "1px solid #e9ecf1",
                },
            },
            "&.mod-disabled": {
                cursor: "not-allowed",
            },
        },

        sectionTitleContainer: {
            marginTop: theme.spacing(8),
            marginBottom: theme.spacing(1),
            display: "flex",
            alignItems: "center",
        },
        sectionTitle: {
            fontSize: "0.875rem",
            fontWeight: 500,
        },

        mainTitle: {
            fontSize: "1.2rem",
            fontWeight: 500,
        },

        metricDisplayOptionTitle: {
            cursor: "pointer",

            "&.mod-selected": {
                fontWeight: 500,
                textDecoration: "underline",
            },
        },

        queryContainer: {
            marginBottom: theme.spacing(4),
        },

        expandIcon: {
            fontSize: "0.875rem",
        },

        sectionDivider: {
            marginBottom: theme.spacing(3),
        },

        metricTypeIcon: {
            fontSize: theme.spacing(4) + 2,
        },

        savingVisualisationLoading: {
            marginRight: "8px",
        },

        errorSavingVisualisation: {
            color: sternumTheme.danger.main,
            marginRight: "8px",
        },

        deviceDefinitionVersionSelectContainer: {},

        graphWrapper: {
            height: 280,
            padding: theme.spacing(3.5),
            marginTop: theme.spacing(5),
            borderRadius: 14,
            background: "#fff",
            boxShadow: "0px 0px 23px rgba(231, 232, 235, 0.25)",
            overflow: "hidden",

            "&.no-padding": {
                padding: 0,
            },
        },

        eventsTableAndSourcesContainer: {
            height: "calc(100% - 15px)",
            background: "#fff",
            padding: theme.spacing(4),
            marginTop: theme.spacing(3),
            borderRadius: 14,
            boxShadow: "0px 0px 23px rgba(231, 232, 235, 0.25)",
        },
    });

export default visualisationConfigurationComponentStyle;
