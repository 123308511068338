import { Action } from "redux";
import ActionType from "../ActionType";
import IssuesFilter from "../../state/IssuesFilter";

export interface FetchPendingIssuesCountAction extends Action<ActionType> {
    type: ActionType.FetchPendingIssuesCount;
    issuesFilter?: IssuesFilter;
}

export function fetchPendingIssuesCountAction({
    issuesFilter,
}: { issuesFilter?: IssuesFilter } = {}): FetchPendingIssuesCountAction {
    return {
        type: ActionType.FetchPendingIssuesCount,
        issuesFilter: issuesFilter,
    };
}
