import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

const triggersHitsListStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        paperClass: {
            overflowY: "auto" as "auto",
        },
        linkText: {
            fontSize: "0.8125rem",
            fontWeight: 400,
            marginRight: theme.spacing(1),
            "&:hover": {
                textDecoration: "underline",
            },
        },
        linkIcon: {
            fontSize: "0.8125rem",
            paddingRight: theme.spacing(3),
        },
        traceLinkText: {
            fontSize: "0.8125rem",
            fontWeight: 400,
            marginRight: theme.spacing(1),
            "&:hover": {
                textDecoration: "underline",
            },
        },
        traceLinkIcon: {
            fontSize: "0.8125rem",
            paddingRight: theme.spacing(3),
        },
        rowHover: {
            "&:hover": {
                cursor: "default",
            },
        },
        triggerDisplayNameWidth: {
            width: "11vw",
        },
    });

export default triggersHitsListStyle;
