export const knownVulnerabilities = [
    {
        category: "Supply Chain",
        color: "#44D9D9",
        cves: [
            { name: "CVE-2019-19945", protected: true },
            { name: "CVE-2020-8597", protected: true },
        ],
    },
    {
        category: "Command Injection",
        color: "#777CFF",
        cves: [
            { name: "CVE-2017-17867", protected: true },
            { name: "CVE-2019-12272", protected: true },
            { name: "CVE-2020-11963", protected: true },
            { name: "CVE-2021-28961", protected: true },
        ],
    },
    {
        category: "Memory Vulnerabilities",
        color: "#8A5FE5",
        cves: [
            { name: "CVE-2019-19945", protected: true },
            { name: "CVE-2020-7248", protected: true },
            { name: "CVE-2020-11750", protected: true },
            { name: "CVE-2020-11752", protected: true },
            { name: "CVE-2020-25681", protected: true },
            { name: "CVE-2020-25682", protected: true },
            { name: "CVE-2020-25683", protected: true },
            { name: "CVE-2020-25687", protected: true },
            { name: "CVE-2020-28951", protected: false },
            { name: "CVE-2020-36177", protected: true },
        ],
    },
    {
        category: "Authentication / Passwords",
        color: "#FE8059",
        cves: [
            { name: "CVE-2017-9385", protected: false },
            { name: "CVE-2018-11116", protected: true },
            { name: "CVE-2019-5101", protected: true },
            { name: "CVE-2019-5102", protected: false },
            { name: "CVE-2020-7982", protected: true },
            { name: "CVE-2020-11964", protected: true },
            { name: "CVE-2020-11965", protected: true },
            { name: "CVE-2020-11966", protected: true },
            { name: "CVE-2020-11967", protected: true },
            { name: "CVE-2020-11968", protected: false },
            { name: "CVE-2020-13859", protected: false },
        ],
    },
    {
        category: "Others",
        color: "#FF97D5",
        cves: [
            { name: "CVE-2018-19630", protected: false },
            { name: "CVE-2019-15513", protected: true },
            { name: "CVE-2019-17367", protected: false },
            { name: "CVE-2019-18992", protected: false },
            { name: "CVE-2019-18993", protected: false },
            { name: "CVE-2019-25015", protected: false },
            { name: "CVE-2020-10871", protected: false },
            { name: "CVE-2020-25684", protected: false },
            { name: "CVE-2020-25685", protected: false },
            { name: "CVE-2020-25686", protected: false },
            { name: "CVE-2020-25705", protected: false },
            { name: "CVE-2021-27821", protected: false },
            { name: "CVE-2021-45904", protected: false },
            { name: "CVE-2021-45905", protected: false },
            { name: "CVE-2021-45906", protected: false },
            { name: "CVE-2021-22161", protected: false },
            { name: "CVE-2021-32019", protected: false },
            { name: "CVE-2021-33425", protected: false },
        ],
    },
];
