import React, { useRef, useState } from "react";
import classNames from "classnames";

import { useResolvingReasonsStyle } from "./ResolvingReasons.style";

export interface ResolvingReasonsAddItemProps extends React.ComponentProps<"div"> {
    initialValue?: string;
    placeholder?: string;
    buttonPlaceholder?: string;
    onAddNewReason?: (newReason?: string) => unknown;
}

export function ResolvingReasonsAddItem({
    className,
    initialValue = "",
    placeholder,
    buttonPlaceholder,
    onAddNewReason,
    children,
    ...props
}: ResolvingReasonsAddItemProps) {
    const classes = useResolvingReasonsStyle();
    const contentEditableRef = useRef<HTMLSpanElement>();
    const [isEdited, setIsEdited] = useState(false);

    return (
        <>
            <div className={classNames(className, classes.resolvingReasonsAddItem)} {...props}>
                <span
                    ref={contentEditableRef}
                    contentEditable={true}
                    className={classes.addInput}
                    placeholder={isEdited ? placeholder : buttonPlaceholder}
                    onFocus={(e) => {
                        setIsEdited(true);
                        e.target.innerHTML = initialValue;
                    }}
                    onBlur={(e) => {
                        setIsEdited(false);

                        const innerText = e.target.innerText;

                        e.target.innerHTML = initialValue;
                        onAddNewReason?.(innerText);
                    }}
                    onKeyDown={async (e) => {
                        e.persist();

                        if (e.key === "Enter") {
                            e.target["blur"]?.();
                            // It is needed for firefox where focus needs a time after blur
                            await new Promise((res) => setTimeout(res, 0));
                            e.target["focus"]?.();
                        }
                    }}
                />
            </div>
            {isEdited && (
                <div
                    className={classNames(className, classes.resolvingReasonsAddItem)}
                    {...props}
                    onPointerDown={() => {
                        setTimeout(() => {
                            contentEditableRef.current?.focus?.();
                        }, 0);
                    }}
                >
                    <span className={classes.addInput}>{buttonPlaceholder}</span>
                </div>
            )}
        </>
    );
}
