import * as React from "react";
import Typography from "@material-ui/core/Typography";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import cvesListFilterPopoverStyle from "./CvesListFilterPopoverStyle";
import CvesFilter from "../../lib/state/CvesFilter";
import ListFilter from "../../lib/state/ListFilter";

/**
 * Holds the inner state for our app.
 */
interface AppState {}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof cvesListFilterPopoverStyle> {
    cvesFilter: CvesFilter;
    onFilterChanged: (listFilter: ListFilter) => void;
}

/**
 * Popover content for view columns.
 */
class CvesListFilterPopover extends React.Component<AppProps, AppState> {
    /**
     * Defining default state so we can later reset safely.
     */
    private readonly defaultState: AppState = {};

    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {};
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        return (
            <div>
                {/* Filter popover title */}
                <Typography variant={"caption"} className={classes.titleTypography}>
                    Filter
                </Typography>

                <Typography variant="body2">Nothing here yet...</Typography>

                {/* Apply & Reset buttons */}
                <div className={classes.resetAndApplyButtons}>
                    {/* Apply */}
                    <Button
                        size={"small"}
                        variant={"contained"}
                        color={"primary"}
                        className={classes.applyButton}
                        onClick={(event) => this.handleFilterApplied()}
                    >
                        Apply
                    </Button>

                    {/* Reset */}
                    <Button size={"small"} variant={"contained"} onClick={(event) => this.handleFilterReset()}>
                        Reset
                    </Button>
                </div>
            </div>
        );
    }

    private handleFilterApplied() {
        if (this.props.onFilterChanged) {
            this.props.onFilterChanged(new CvesFilter(null, null, null, null, null, null));
        }
    }

    /**
     * Occurs on reset of filters requested.
     */
    private handleFilterReset() {
        this.setState({
            ...this.defaultState,
        });
    }
}

export default withStyles(cvesListFilterPopoverStyle)(CvesListFilterPopover);
