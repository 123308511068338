import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

import { commonLoginManager } from "../LoginManager.style";

export const useAuthenticationStyle = makeStyles((theme: Theme) => ({
    authenticationContainer: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        fontFamily: "Inter",
        height: "100vh",
        width: "100vw",
    },

    headerLogo: {
        position: "absolute",
        top: 24,
        left: 32,
    },

    backButton: {
        marginLeft: 90,
        marginBottom: 20,
        display: "flex",
        alignItems: "center",
        gap: 4,
        color: "#222831",
        fontWeight: 600,

        "&:hover": {
            color: theme.palette.secondary.dark,
        },

        [`@media (max-width: ${commonLoginManager.mediaQueryMaxWidth})`]: {
            display: "none",
        },
    },

    mobilePadding: {
        display: "none",
        marginBottom: 130,

        [`@media (max-width: ${commonLoginManager.mediaQueryMaxWidth})`]: {
            display: "block",
        },
    },

    backButtonIcon: {
        color: theme.palette.secondary.dark,
    },

    backgroundVideo: {
        position: "absolute",
        objectFit: "cover",
        width: "100%",
        height: "100%",
        zIndex: -1,

        [`@media (max-width: ${commonLoginManager.mediaQueryMaxWidth})`]: {
            display: "none",
        },
    },

    bgLightLogo: {
        position: "absolute",
        bottom: 0,
        left: 0,
        maxWidth: "100%",
        zIndex: -1,

        [`@media (max-width: ${commonLoginManager.mediaQueryMaxWidth})`]: {
            display: "none",
        },
    },

    authenticationContainerContent: {
        marginLeft: 90,
        boxSizing: "border-box",

        [`@media (max-width: ${commonLoginManager.mediaQueryMaxWidth})`]: {
            display: "flex",
            flexFlow: "column",
            alignItems: "center",
            height: "100%",
            width: "100%",
            padding: 16,
            margin: 0,
        },
    },
}));
