import { get } from "lodash";
import EntityManager from "../../infra/EntityManager";
import AggregationFunctionType from "../../state/Visualisation/AggregationFunctionType";
import VisualisationConfiguration from "../../state/Visualisation/VisualisationConfiguration";
import VisualisationDataSourceGroupBy from "../../state/Visualisation/VisualisationConfigurationGroupBy";
import VisualisationDataSource from "../../state/Visualisation/VisualisationDataSource";
import VisualisationInfo from "../../state/Visualisation/VisualisationInfo";
import AnalyticsService from "../AnalyticsService";
import buildSternumApiUrl from "../buildSternumApiUrl";
import ConfigurationService from "../ConfigurationService";
import HttpService from "../HttpService";
import { CalculateAggregationResponse } from "./CalculateAggregationResponse";
import VisualisationSummary from "./VisualisationSummary";

class VisualisationApiService {
    /**
     * Constructor.
     */
    constructor(private httpService: HttpService) {}

    /**
     * Creates a new visualisation.
     */
    @AnalyticsService.reportOnError("API:createVisualisation")
    public async createVisualisation(
        entityId: string,
        displayName: string,
        configuration: VisualisationConfiguration,
        isStacked: boolean,
        isLogarithmicScale: boolean
    ): Promise<string> {
        let data = {
            display_name: displayName,
            configuration: this.getServerVisualisationConfiguration(configuration),
            stacked: isStacked,
            logarithmic_scale: isLogarithmicScale,
        };

        const endpoint = `/${entityId}/visualisations`;
        const responseJson = await this.httpService.post(buildSternumApiUrl(endpoint), data);

        return Promise.resolve(responseJson["visualisation_id"]);
    }

    /**
     * Updates visualisation by id.
     */
    @AnalyticsService.reportOnError("API:updateVisualisation")
    public async updateVisualisation(
        visualisationId: string,
        displayName: string,
        configuration: VisualisationConfiguration,
        isStacked: boolean,
        isLogarithmicScale: boolean
    ): Promise<void> {
        let data = {
            display_name: displayName,
            configuration: this.getServerVisualisationConfiguration(configuration),
            stacked: isStacked,
            logarithmic_scale: isLogarithmicScale,
        };

        const endpoint = `/${visualisationId}`;
        await this.httpService.post(buildSternumApiUrl(endpoint), data);
        return Promise.resolve();
    }

    /**
     * Gets visualisation for entity.
     */
    @AnalyticsService.reportOnError("API:getVisualisations")
    public async getVisualisations(entityId: string): Promise<VisualisationSummary[]> {
        let data = {};

        const endpoint = `/${entityId}/visualisations`;
        const responseJson = await this.httpService.get(buildSternumApiUrl(endpoint), data);

        return Promise.resolve(
            responseJson["entities"].map((entityJson) => {
                return {
                    visualisationId: entityJson["visualisation_id"],
                    displayName: entityJson["display_name"],
                    updated: entityJson["updated"],
                };
            })
        );
    }

    /**
     * Gets visualisation by id.
     */
    @AnalyticsService.reportOnError("API:getVisualisation")
    public async getVisualisation(visualisationId: string): Promise<VisualisationInfo> {
        let data = {};

        const endpoint = `/${visualisationId}`;
        const responseJson = await this.httpService.get(buildSternumApiUrl(endpoint), data);

        return Promise.resolve(EntityManager.getSternumEntity(responseJson) as VisualisationInfo);
    }

    /**
     * Deletes visualisation by id.
     */
    @AnalyticsService.reportOnError("API:deleteVisualisation")
    public async deleteVisualisation(visualisationId: string): Promise<void> {
        let data = {};

        const endpoint = `/${visualisationId}`;
        await this.httpService.delete(buildSternumApiUrl(endpoint), data);

        return Promise.resolve();
    }

    /**
     * Calculates an aggregation value.
     */
    @AnalyticsService.reportOnError("API:calculateAggregation")
    public async calculateAggregation(
        entityId: string,
        visualisationDataSources: VisualisationDataSource[],
        createdFrom: number,
        createdTo: number
    ): Promise<CalculateAggregationResponse> {
        let data = {
            metric_data_sources: visualisationDataSources.map((visualisationDataSource) =>
                this.convertVisualisationDataSource(visualisationDataSource)
            ),
            created_from: createdFrom,
            created_to: createdTo,
        };

        let endpoint = `/${entityId}/calculate_aggregation`;
        let responseJson = await this.httpService.post(buildSternumApiUrl(endpoint), data);

        return Promise.resolve(responseJson["results"]);
    }

    /**
     * Get aggregated events.
     */
    @AnalyticsService.reportOnError("API:getArgumentValueOverTime")
    public async getArgumentValueOverTime(
        entityId: string,
        createdFrom: number,
        createdTo: number,
        filterEmptyBuckets: boolean,
        bucketKeyDateFormat: string,
        amountOfGraphPointsToDisplay: number,
        visualisationDataSources: VisualisationDataSource[],
        groupBy?: VisualisationDataSourceGroupBy,
        groupByDeviceDefinitionVersions?: string[],
        groupByTraceDefinitionIds?: string[],
        interval?: number,
        intervalTimeUnit?: string
    ): Promise<any> {
        // let hasCountOfDeviceSpecialCase = false;

        // visualisationDataSources.forEach((dataSource) => {
        //     if (
        //         dataSource.traceDefinitionId === ConfigurationService.getDeviceIdArgumentField().id &&
        //         dataSource.aggregationFunction === AggregationFunctionType.COUNT
        //     ) {
        //         hasCountOfDeviceSpecialCase = true;
        //     }
        // });

        const hasUniqueCount =
            visualisationDataSources &&
            visualisationDataSources.length > 0 &&
            visualisationDataSources[0] &&
            visualisationDataSources[0].aggregationFunction === AggregationFunctionType.UNIQUE_COUNT;

        let data = {
            interval: interval,
            interval_time_unit: intervalTimeUnit,
            created_from: createdFrom,
            created_to: createdTo,
            filter_empty_buckets: filterEmptyBuckets || false,
            bucket_key_date_format: bucketKeyDateFormat,
            amount_of_graph_points_to_display: amountOfGraphPointsToDisplay,

            group_by:
                groupBy?.enabled && !hasUniqueCount
                    ? {
                          ...groupBy,
                          aggregation_function: "COUNT",
                          device_definition_version_id: groupByDeviceDefinitionVersions,
                          trace_definition_ids: groupByTraceDefinitionIds,
                      }
                    : undefined,
            time_series_data_sources: visualisationDataSources.map((visualisationDataSource) =>
                this.convertVisualisationDataSource(visualisationDataSource)
            ),
        };

        // if (hasCountOfDeviceSpecialCase) {
        //     (data as any).group_by = {
        //         ...(data.group_by || {}),
        //         aggregation_context: "DEVICES",
        //     };
        //     (data as any).group_by.trace_definition_ids = undefined;
        // }

        const result = await this.httpService.post(
            buildSternumApiUrl(`/${entityId}/argument_value_graph_over_time`),
            data
        );

        if (
            visualisationDataSources.length > 0 &&
            visualisationDataSources[0].aggregationFunction === AggregationFunctionType.UNIQUE_COUNT
        ) {
            // limit unique count results to prevent crashing
            const UNIQUE_COUNT_VISUALISATION_LIMIT = 100;
            const data_sources = {};

            Object.keys((result as any).data_sources).forEach((key) => {
                const data = (result as any).data_sources[key];
                if (Array.isArray(data)) {
                    // pie chart, bar chart etc.
                    data_sources[key] = data.slice(0, UNIQUE_COUNT_VISUALISATION_LIMIT);
                } else {
                    // time series
                    const timeSeriesMap = {};
                    Object.keys(data).forEach((timestamp) => {
                        if (Array.isArray(data[timestamp])) {
                            timeSeriesMap[timestamp] = data[timestamp].slice(0, UNIQUE_COUNT_VISUALISATION_LIMIT);
                        } else {
                            // in case we have a "0" from API
                            timeSeriesMap[timestamp] = data[timestamp];
                        }
                    });
                    data_sources[key] = timeSeriesMap;
                }
            });

            return this.calculateArgumentValuesOverTimeWithGivenSternumId(
                {
                    ...result,
                    data_sources,
                },
                "received_device_id"
            );
        }

        return this.calculateArgumentValuesOverTimeWithGivenSternumId(result, "received_device_id");
    }

    /**
     * Calculate argument values over time with given sternum ID
     *
     * Replace object key which is sternumId with `relatedEntitiesKeyForGivenSternumId` value from `relatedEntitiesMap`
     *
     * @param argumentValuesOverTime
     * @param relatedEntitiesKeyForGivenSternumId
     * @private
     */
    private calculateArgumentValuesOverTimeWithGivenSternumId(
        argumentValuesOverTime: Object,
        relatedEntitiesKeyForGivenSternumId: string = "received_device_id"
    ) {
        const dataSourcesKey = "data_sources";
        const relatedEntitiesMap = get(argumentValuesOverTime, ["related_entities_map"]);

        if (!relatedEntitiesMap) {
            return argumentValuesOverTime;
        }

        const argumentOverTime = { ...argumentValuesOverTime };

        if (argumentOverTime[dataSourcesKey]) {
            argumentOverTime[dataSourcesKey] = this.calculateObjectKeysWithGivenSternumId(
                argumentOverTime[dataSourcesKey],
                relatedEntitiesMap,
                relatedEntitiesKeyForGivenSternumId
            );
        }

        return argumentOverTime;
    }

    /**
     * Replace given object keys with values of `relatedEntitiesMap` (if keys are the same in both object)
     *
     * @param argumentValuesOverTime
     * @param relatedEntitiesMap
     * @param relatedEntitiesKeyForGivenSternumId
     * @private
     */
    private calculateObjectKeysWithGivenSternumId(
        argumentValuesOverTime: Object,
        relatedEntitiesMap: Record<string, Object>,
        relatedEntitiesKeyForGivenSternumId: string = "received_device_id"
    ) {
        if (argumentValuesOverTime instanceof Array) {
            return argumentValuesOverTime.map((argument) =>
                this.calculateObjectKeysWithGivenSternumId(
                    argument,
                    relatedEntitiesMap,
                    relatedEntitiesKeyForGivenSternumId
                )
            );
        }

        if (!(argumentValuesOverTime instanceof Object)) {
            return argumentValuesOverTime;
        }

        return Object.keys(argumentValuesOverTime).reduce((argumentsOverTime, key) => {
            const keyToReplace = relatedEntitiesMap?.[key]?.[relatedEntitiesKeyForGivenSternumId];
            const argumentOverTimeValue = this.calculateObjectKeysWithGivenSternumId(
                argumentValuesOverTime[key],
                relatedEntitiesMap,
                relatedEntitiesKeyForGivenSternumId
            );

            if (keyToReplace) {
                argumentsOverTime[keyToReplace] = argumentOverTimeValue;
            } else {
                argumentsOverTime[key] = argumentOverTimeValue;
            }

            return argumentsOverTime;
        }, {});
    }

    /**
     * Gets the server configuration created from given visualisation configuration.
     */
    private getServerVisualisationConfiguration(configuration: VisualisationConfiguration) {
        return {
            visualisation_type: configuration.visualisationType,
            data_sources: (configuration.dataSources || []).map((dataSource) =>
                this.convertVisualisationDataSource(dataSource)
            ),
            group_by: configuration.groupBy ? { ...configuration.groupBy, aggregation_function: "COUNT" } : undefined,
        };
    }

    /**
     * Converts the given visualisation data source to server data source.
     */
    private convertVisualisationDataSource(visualisationDataSource: VisualisationDataSource) {
        const converted: Record<string, any> = {
            data_source_key: visualisationDataSource.dataSourceKey,
            sternum_query: visualisationDataSource.sternumQuery
                ? visualisationDataSource.sternumQuery.getServerQueryJson()
                : null,
            aggregation_function: visualisationDataSource.aggregationFunction,
            trace_definition_id: visualisationDataSource.traceDefinitionId,
            trace_definition_display_name: visualisationDataSource.traceDefinitionDisplayName,
            graph_label: visualisationDataSource.graphLabel,
            argument_definition_id: visualisationDataSource.argumentDefinitionId,
            device_definition_version_id: visualisationDataSource.deviceDefinitionVersionIds,
            argument_definition_display_name: visualisationDataSource.argumentDefinitionDisplayName,
            percentile: visualisationDataSource.percentile,
            color: visualisationDataSource.color,
            unique_columns: visualisationDataSource.uniqueColumns
                ? visualisationDataSource.uniqueColumns.slice().reverse()
                : undefined,
        };

        // UNIQUE COUNT preparation
        if (visualisationDataSource.aggregationFunction === AggregationFunctionType.UNIQUE_COUNT) {
            // when its unique count query trace_definition_id is not needed, because backend relies only on `aggregation_fields`
            converted.trace_definition_id = ConfigurationService.getAllEventsFilterField().id;

            converted.aggregation_fields = visualisationDataSource.uniqueColumns
                ? visualisationDataSource.uniqueColumns
                      .slice()
                      .reverse()
                      .filter((col) => col !== null)
                      .map((col) => col.value)
                : [];
        }

        // special case for COUNT of DEVICES
        if (
            visualisationDataSource.aggregationFunction === AggregationFunctionType.COUNT &&
            visualisationDataSource.traceDefinitionId === ConfigurationService.getDeviceIdArgumentField().id
        ) {
            converted.aggregation_context = "DEVICES";
            converted.trace_definition_id = undefined;
        }

        return converted;
    }
}

export default VisualisationApiService;
