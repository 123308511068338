import React from "react";
import classNames from "classnames";

import { useDiamondChipStyle } from "./DiamondChip.style";
import { DiamondIcon } from "../../SUI/SternumIcon";

export interface FreeChipProps {
    className?: string;
}

export function DiamondChip({ className, ...props }: FreeChipProps) {
    const classes = useDiamondChipStyle();

    return (
        <div className={classNames(classes.diamondChip, className)} {...props}>
            <DiamondIcon color="#FFB300" />
        </div>
    );
}
