import { Dispatch, SetStateAction, useEffect, useState } from "react";

export const useAutoSwitchSlider = ({
    numberOfElements,
    autoSwitchTime = 5000,
    isMouseOverSlider,
}: {
    numberOfElements: number;
    autoSwitchTime?: number;
    isMouseOverSlider?: boolean;
}): [number, Dispatch<SetStateAction<number>>] => {
    const [selectedSlide, setSelectedSlide] = useState(0);

    useEffect(() => {
        if (numberOfElements <= 1 || isMouseOverSlider) {
            return;
        }

        const id = setInterval(() => {
            setSelectedSlide(selectedSlide >= numberOfElements - 1 ? 0 : selectedSlide + 1);
        }, autoSwitchTime);

        return () => {
            clearInterval(id);
        };
    }, [selectedSlide, autoSwitchTime, isMouseOverSlider]);

    return [selectedSlide, setSelectedSlide];
};
