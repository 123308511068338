import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../../CommonStyle";

const graphLoadingDisplayStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        blocksLoadingRoot: {
            display: "flex",
            height: "100%",
            flexGrow: 1,
            alignItems: "flex-end",
            justifyContent: "space-between",
        },

        circularLoadingRoot: {
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
    });

export default graphLoadingDisplayStyle;
