import React from "react";

import { SternumIconProps } from "./SternumIcon.types";

export const AnomaliesIcon = React.forwardRef<SVGSVGElement, SternumIconProps>(function AnomaliesIcon(
    { width = 18, height = 18, color, ...props },
    ref
) {
    return (
        <svg
            ref={ref}
            width={width}
            height={width}
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle cx="9.5813" cy="8.75" r="1.25" fill={color || "currentColor"} />
            <path
                d="M12.2407 6.09131C12.5424 5.78959 13.0375 5.78669 13.2879 6.13215C13.7939 6.83017 14.109 7.65375 14.1944 8.52084C14.3052 9.64574 14.0226 10.7742 13.3946 11.7141C12.7666 12.6539 11.8322 13.3469 10.7505 13.6751C9.66883 14.0032 8.50686 13.9461 7.46257 13.5135C6.41827 13.081 5.55627 12.2997 5.02343 11.3028C4.4906 10.306 4.31989 9.15519 4.54041 8.04657C4.76093 6.93796 5.35902 5.9401 6.23278 5.22302C6.90629 4.67028 7.71147 4.31074 8.56285 4.17497C8.98421 4.10777 9.33225 4.45991 9.33225 4.8866V4.8866C9.33225 5.31329 8.98183 5.65032 8.56651 5.74813C8.07344 5.86426 7.60982 6.09182 7.21303 6.41746C6.61561 6.90775 6.20667 7.59002 6.05589 8.34802C5.90512 9.10602 6.02183 9.89285 6.38615 10.5744C6.75047 11.256 7.33986 11.7902 8.05388 12.086C8.7679 12.3817 9.56238 12.4208 10.302 12.1964C11.0415 11.9721 11.6804 11.4982 12.1098 10.8556C12.5392 10.213 12.7325 9.44143 12.6567 8.6723C12.6064 8.16147 12.4395 7.67273 12.1729 7.24196C11.9484 6.87911 11.939 6.39302 12.2407 6.09131V6.09131Z"
                fill={color || "currentColor"}
            />
            <path
                d="M15.0901 3.24319C15.4254 2.90787 15.9727 2.9055 16.2745 3.27124C17.4371 4.67978 18.1481 6.41487 18.3018 8.24829C18.4795 10.3681 17.9006 12.4822 16.6678 14.2158C15.435 15.9494 13.628 17.1902 11.5674 17.7183C9.50678 18.2463 7.32572 18.0274 5.41114 17.1004C3.49655 16.1734 1.9722 14.5982 1.1085 12.6542C0.2448 10.7103 0.0975783 8.52321 0.692953 6.48102C1.28833 4.43884 2.58781 2.67355 4.36088 1.4983C5.89444 0.481805 7.69654 -0.0364521 9.52246 0.00197382C9.99657 0.0119513 10.3341 0.442763 10.279 0.913768V0.913768C10.224 1.38477 9.79674 1.71659 9.32253 1.71728C7.90128 1.71936 6.50471 2.13758 5.30966 2.92969C3.87491 3.88069 2.82338 5.30915 2.34161 6.96167C1.85984 8.61419 1.97897 10.3839 2.67786 11.957C3.37676 13.53 4.61025 14.8047 6.15952 15.5548C7.70878 16.3049 9.47367 16.4821 11.1411 16.0548C12.8085 15.6275 14.2707 14.6234 15.2683 13.2206C16.2658 11.8178 16.7343 10.107 16.5905 8.39173C16.4708 6.963 15.9327 5.60807 15.0529 4.49186C14.7594 4.11943 14.7547 3.57851 15.0901 3.24319V3.24319Z"
                fill={color || "currentColor"}
            />
            <rect x="8.83521" y="0.500244" width="1.5" height="8" rx="0.75" fill={color || "currentColor"} />
        </svg>
    );
});
