import React from "react";

export interface UniqueCountMetric {
    value: string;
    displayName: string;
    counter: number;
    entityType: string;
    label: string;
    isSpecialField: boolean;
    metricType: "trace" | "argument";
}

export const UniqueCountDataContext = React.createContext<UniqueCountMetric[]>([]);
