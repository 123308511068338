import { Theme } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

let fleetViewStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),
        deviceViewUpperContent: {
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        redirectLink: {
            cursor: "pointer",
        },
    });

export default fleetViewStyle;
