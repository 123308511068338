import { useCallback, useEffect, useState } from "react";

import ServiceWire from "../../../../../lib/services/ServiceWire";
import SternumDeviceEventsFilter from "../../../../../lib/state/SternumDeviceEventsFilter";
import { LinuxKernelModule } from "../../../../../lib/state/LinuxAllKernelModules";

export interface KernelModulesDataArgs {
    deviceId: string;
    searchText: string;
    startDate: Date;
    endDate: Date;
}

export const useKernelModulesData = ({ deviceId, searchText, startDate, endDate }: KernelModulesDataArgs) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [allKernelModules, setAllKernelModules] = useState<LinuxKernelModule[]>();

    const fetchAllKernelModules = useCallback(async () => {
        setIsLoading(true);

        const entitiesFilter = new SternumDeviceEventsFilter(
            null,
            false,
            startDate?.getTime() || null,
            endDate?.getTime() || null,
            null,
            null,
            [], // this.state.filterByTraceCategories,
            false,
            null, // this.state.sternumQuery,
            null
        );

        try {
            const linuxAllKernelModules = await ServiceWire.getSternumService().getDeviceSternumDeviceAllKernelModules({
                entityId: deviceId,
                filter: entitiesFilter,
                searchText,
            });

            setAllKernelModules(linuxAllKernelModules.modules);
            setIsLoading(false);
            setIsError(false);
        } catch {
            setIsError(true);
            setIsLoading(false);
            setAllKernelModules([]);
        }
    }, [deviceId, searchText, startDate, endDate]);

    useEffect(() => {
        fetchAllKernelModules().then();
    }, [fetchAllKernelModules]);

    return { isLoading, isError, allKernelModules, refetchAllKernelModules: fetchAllKernelModules };
};
