import moment from "moment";
import { Chip, CircularProgress, IconButton, Popover, Tooltip, Typography } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { History } from "history";
import { isString, some } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import HashSet from "../../lib/infra/HashSet";
import SternumUtils from "../../lib/infra/SternumUtils";
import WebUtils from "../../lib/infra/WebUtils";
import { fetchDeviceAction } from "../../lib/redux/devices/FetchDeviceAction";
import { closeModalAction } from "../../lib/redux/modals/CloseModalAction";
import {
    editDeviceDefinitionModal,
    openDeviceDefinitionInfoModalAction,
    openDeviceInfoModalAction,
    openGeoLocationHistoryModalAction,
    openMapModalAction,
    openVisualizationCreationModalAction,
} from "../../lib/redux/modals/OpenModalAction";
import ConfigurationService from "../../lib/services/ConfigurationService";
import ReceivedDefinitionsResponse from "../../lib/services/events/ReceivedDefinitionsResponse";
import ReceivedDefinitionSummary from "../../lib/services/events/ReceivedDefinitionSummary";
import PollingSubscriptionInfo from "../../lib/services/PollingSubscriptionInfo";
import ServiceWire from "../../lib/services/ServiceWire";
import AggregateOverProperty from "../../lib/state/AggregateOverProperty";
import DeviceDefinitionInfo from "../../lib/state/DeviceDefinitionInfo";
import DeviceDefinitionVersionInfo from "../../lib/state/DeviceDefinitionVersionInfo";
import DeviceInfo, { DeviceMode } from "../../lib/state/DeviceInfo";
import DeviceStatus from "../../lib/state/DeviceStatus";
import { GlobalState } from "../../lib/state/GlobalState";
import HttpResponse from "../../lib/state/HttpResponse";
import ModalKey from "../../lib/state/ModalKey";
import ModalType from "../../lib/state/ModalType";
import PollingChangeType from "../../lib/state/PollingChangeType";
import QueryQuantifierType from "../../lib/state/QueryQuantifierType";
import SternumDeviceEventsFilter from "../../lib/state/SternumDeviceEventsFilter";
import SternumQuery from "../../lib/state/SternumQuery";
import TableRowData from "../../lib/state/TableRowData";
import TableToolbarDisplayState from "../../lib/state/TableToolbarDisplayState";
import TimeSelectionType from "../../lib/state/TimeSelectionType";
import TimeSelectOption from "../../lib/state/TimeSelectOption";
import customSelectStyle from "../CustomSelectStyle";
import EventCountGraph from "../EventCountGraph/EventCountGraph";
import EventCountOverTimeGraph from "../EventCountOverTimeGraph/EventCountOverTimeGraph";
import ExplanationComponent from "../ExplanationComponent/ExplanationComponent";
import StatusDisplay from "../StatusDisplay/StatusDisplay";
import SternumDeviceEventsList from "../SternumDeviceEventsList/SternumDeviceEventsList";
import SternumQueryEditor from "../SternumQueryEditor/SternumQueryEditor";
import {
    ArrowLeftIcon,
    BoxIcon,
    ClockIcon,
    DownloadIcon,
    FilterIcon,
    FolderIcon,
    MapIcon,
    PlusFilledIcon,
    SettingsIcon,
    SternumLogoGradientIcon,
} from "../SUI/SternumIcon";
import SternumImprovedButton from "../SUI/SternumImprovedButton/SternumImprovedButton";
import GraphLoader from "../SUI/SternumLoaders/GraphLoader";
import SternumCustomSelect from "../SUI/SternumSelect/SternumCustomSelect";
import SternumSelectData from "../SUI/SternumSelect/SternumSelectData";
import TimeSelectionComponent from "../TimeSelectionComponent/TimeSelectionComponent";
import TriggersHitsList from "../TriggersHitsList/TriggersHitsList";
import getDefaultTimeSelection from "./getDefaultTimeSelection";
import newDeviceViewStyle from "./NewDeviceViewStyle";
import { MultipleDevicesSummary } from "./MultipleDevicesSummary";
import { MultipleDevicesGraphComponent } from "./MultipleDevicesGraph";

import {
    MultipleDeviceGraphRelatedDevices,
    MultipleDevicesGraphRelationshipResponse,
} from "../../lib/services/SternumService";
import { DeviceGraphData, GraphData } from "./types";
import { GraphHeaderItem } from "./GraphHeaderItem";
import { ItemCounter } from "./ItemCounter";
import { GraphTooltip } from "./GraphTooltip";
import IssuesList from "../IssuesList/IssuesList";
import { IssueInfoStatus } from "../../lib/state/IssueInfoType";
import IssuesFilter from "../../lib/state/IssuesFilter";
import SternumConfiguration from "../../lib/infra/SternumConfiguration";
import TableColumnHeaderInfo from "../../lib/state/TableColumnHeaderInfo";
import ConditionType from "../../lib/state/ConditionType";
import { LinuxTopologyDropdown } from "./LinuxTopologyDropdown";
import { DeviceInventoryLinuxView } from "./DeviceInventoryLinuxView";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    addToBoardPopoverOpen: boolean;
    addToBoardPopoverElement: any;

    startTime: Date;
    endTime: Date;

    timeSelectOption: TimeSelectOption;

    doNotDisplayLoading: boolean;

    deviceDefinitionVersion: DeviceDefinitionVersionInfo;

    selectedItem: SternumSelectData;

    deviceDefinition: DeviceDefinitionInfo;
    activeDevicesCount?: number;
    loadingDeviceDefinition?: boolean;
    errorLoadingDeviceDefinition?: boolean;

    loadingMultipleDevicesGraph: boolean;
    totalRelatedDevices: number;
    uniqueDeviceProfiles: number;
    multipleDevicesGraph: GraphData<DeviceGraphData>;
    colorByDeviceProfileName: Record<string, string>;
    isGraphDeviceSelectedById: Record<string, boolean>;

    sternumQuery: SternumQuery;
    refreshEntitiesFilter?: boolean;
    sternumQueryChanged: boolean;
    temporarySternumQuery: SternumQuery;
    compilationResponse: any;

    selectedTimeRangeInMiliSec: number;

    filtersExpanded: boolean;

    isQueryInterfaceExpanded: boolean;

    receivedDefinitions: ReceivedDefinitionSummary[];
    receivedArguments: ReceivedDefinitionSummary[];
    loadingReceivedDefinitions: boolean;
    errorLoadingReceivedDefinitions: boolean;

    // Export operation indicators
    onExportCveClicked: boolean;
    onExportDevicesClicked: boolean;
    onExportLibrariesClicked: boolean;

    isApplyFilterDisabled: boolean;

    // Track for new data time (indication for new events are indexed)
    indexEventTime: number;

    clickedExportButtonEl: { element: HTMLDivElement | null; type: "cve" | "devices" | "libraries" };

    selectedQuery: SternumQuery | null;

    // Right click
    anchorEl: Element;
    openRowMenu: boolean;
    clickEvent: React.MouseEvent<HTMLDivElement>;
    rightClickedRow: TableRowData;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof newDeviceViewStyle> {
    deviceId: string;
    fullScreenDisplay: boolean;
    displayBackButton: boolean;
    useLinuxView?: boolean;

    displayMultipleDevicesGraph?: boolean;

    /**
     * Indicates whether this view is for a device definition (rather than for a device).
     */
    displayViewForDeviceDefinition?: boolean;

    /**
     * Indicates the device definition id to use for when it's a display of device definition.
     */
    deviceDefinitionVersionId?: string;

    loadingDevice?: boolean;
    errorLoadingDevice?: boolean;
    device?: DeviceInfo;

    theme?;
    history?: History;

    fleetViewSelectList?: SternumSelectData[];
    fleetViewDefaultItem?: SternumSelectData;
    contextFromTimestamp?: number;
    contextTraceId?: string;

    fetchDeviceAction?: (deviceId: string) => void;
    closeModal?: (modalKey: ModalKey) => void;
    openDeviceInfoModal?: (key: string, deviceId: string, displayXButton: boolean, displayBackButton: boolean) => void;
    openDeviceDefinitionInfoModal?: (
        key: string,
        entityId: string,
        displayXButton: boolean,
        displayBackButton: boolean
    ) => void;

    openVisualizationCreationModal?: (
        key: string,
        visualisationId: string,
        deviceDefinitionVersions: DeviceDefinitionVersionInfo[],
        receivedDefinitionsResponse: Record<string, ReceivedDefinitionsResponse>,
        startTime: Date,
        endTime: Date,
        sternumQuery: SternumQuery,
        displayViewForDeviceDefinition: boolean,
        displayXButton: boolean,
        displayBackButton: boolean,
        lookedUpEntityId: string,
        timeSelectedOption: TimeSelectOption,
        deviceId?: string
    ) => void;
    editDeviceDefinitionModal?: (
        key: string,
        deviceDefinitionVersionId: string,
        displayXButton: boolean,
        displayBackButton: boolean,
        callbackFunction: () => void
    ) => void;
    openMapModal?: (
        key: string,
        entityId: string,
        createdFrom: number,
        createdTo: number,
        title: string,
        displayXButton: boolean
    ) => void;
    openDeviceGeoLocationHistoryModal?: (
        key: string,
        entityId: string,
        createdFrom: number,
        createdTo: number,
        title: string,
        displayXButton: boolean,
        deviceDefinitionInfo: DeviceDefinitionInfo,
        deviceDefinitionVersionId: string
    ) => void;
    hideShowInContext?: boolean;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {
        loadingDevice: state.devices.loadingIds.exists(ownProps.deviceId),
        errorLoadingDevice: state.devices.errorIds.containsKey(ownProps.deviceId),
        device: state.devices.idToEntityMap.get(ownProps.deviceId),
    };
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchDeviceAction: (deviceId: string) => dispatch(fetchDeviceAction(deviceId)),
        closeModal: (modalKey: ModalKey) => dispatch(closeModalAction(modalKey)),
        openDeviceInfoModal: (key: string, deviceId, displayXButton, displayBackButton) =>
            dispatch(openDeviceInfoModalAction(key, deviceId, displayXButton, displayBackButton)),
        openDeviceDefinitionInfoModal: (key: string, entityId: string, displayXButton, displayBackButton) =>
            dispatch(openDeviceDefinitionInfoModalAction(key, entityId, displayXButton, displayBackButton)),

        openMapModal: (
            key: string,
            entityId: string,
            createdFrom: number,
            createdTo: number,
            title: string,
            displayXButton: boolean
        ) => dispatch(openMapModalAction(key, entityId, createdFrom, createdTo, title, displayXButton)),

        openDeviceGeoLocationHistoryModal: (
            key: string,
            entityId: string,
            createdFrom: number,
            createdTo: number,
            title: string,
            displayXButton: boolean,
            deviceDefinition: DeviceDefinitionInfo,
            deviceDefinitionVersionId: string
        ) =>
            dispatch(
                openGeoLocationHistoryModalAction(
                    key,
                    entityId,
                    createdFrom,
                    createdTo,
                    title,
                    displayXButton,
                    deviceDefinition,
                    deviceDefinitionVersionId
                )
            ),

        openVisualizationCreationModal: (
            key: string,
            visualisationId: string,
            deviceDefinitionVersions: DeviceDefinitionVersionInfo[],
            receivedDefinitionsResponse: Record<string, ReceivedDefinitionsResponse>,
            startTime: Date,
            endTime: Date,
            sternumQuery: SternumQuery,
            displayViewForDeviceDefinition: boolean,
            displayXButton: boolean,
            displayBackButton: boolean,
            lookedUpEntityId: string,
            timeSelectedOption: TimeSelectOption,
            deviceId?: string
        ) =>
            dispatch(
                openVisualizationCreationModalAction(
                    key,
                    visualisationId,
                    deviceDefinitionVersions,
                    receivedDefinitionsResponse,
                    startTime,
                    endTime,
                    sternumQuery,
                    displayViewForDeviceDefinition,
                    displayXButton,
                    displayBackButton,
                    lookedUpEntityId,
                    timeSelectedOption,
                    deviceId
                )
            ),
        editDeviceDefinitionModal: (
            key: string,
            deviceDefinitionVersionId,
            displayXButton,
            displayBackButton,
            callbackFunction
        ) =>
            dispatch(
                editDeviceDefinitionModal(
                    key,
                    deviceDefinitionVersionId,
                    displayXButton,
                    displayBackButton,
                    callbackFunction
                )
            ),
    };
};

const emptyArray = [];

/**
 * Displays a bar of metrics.
 */
class NewDeviceView extends React.Component<AppProps, AppState> {
    initialAlertsTableColumns = [
        new TableColumnHeaderInfo("created", "Received", false, true, false, false, undefined, "Received"),
        new TableColumnHeaderInfo("alertName", "Alert Name", false, true, false, false, undefined, "Alert Name"),
        new TableColumnHeaderInfo("traceCategory", "Category", false, true, false, false, undefined, "Category"),
        new TableColumnHeaderInfo("receivedDeviceId", "Device ID", false, true, false, false, undefined, "Device ID"),
    ];

    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        const defaultTimeSelection: TimeSelectOption = getDefaultTimeSelection();

        const startTime = defaultTimeSelection.timeRange.start;
        const endTime = defaultTimeSelection.timeRange.end;

        const tempQuery = SternumQuery.getEmptyQuery();
        tempQuery.queryQuantifierType = QueryQuantifierType.ANY;

        // Initializing the state to default.
        this.state = {
            addToBoardPopoverOpen: false,
            addToBoardPopoverElement: null,

            startTime,
            endTime,
            selectedTimeRangeInMiliSec: endTime.getTime() - startTime.getTime(),
            timeSelectOption: defaultTimeSelection,

            doNotDisplayLoading: false,

            selectedItem: this.props.fleetViewDefaultItem,

            deviceDefinition: null,

            loadingMultipleDevicesGraph: true,
            totalRelatedDevices: 0,
            uniqueDeviceProfiles: 0,
            multipleDevicesGraph: { root: { childIds: [] }, graph: {} },
            colorByDeviceProfileName: {},
            isGraphDeviceSelectedById: this.props.device?.receivedDeviceId
                ? { [this.props.device.receivedDeviceId]: true }
                : {},

            activeDevicesCount: 0,
            loadingDeviceDefinition: false,
            errorLoadingDeviceDefinition: false,

            sternumQuery: null,
            sternumQueryChanged: false,
            temporarySternumQuery: tempQuery,
            compilationResponse: null,

            filtersExpanded: !!this.props.contextFromTimestamp,

            isQueryInterfaceExpanded: false,

            deviceDefinitionVersion: null,

            receivedDefinitions: [],
            receivedArguments: [],
            loadingReceivedDefinitions: false,
            errorLoadingReceivedDefinitions: false,

            onExportCveClicked: false,
            onExportDevicesClicked: false,
            onExportLibrariesClicked: false,

            isApplyFilterDisabled: false,

            indexEventTime: 0,

            clickedExportButtonEl: { element: null, type: "cve" },

            selectedQuery: null,

            anchorEl: null,
            openRowMenu: false,
            clickEvent: null,
            rightClickedRow: null,
        };
    }

    async componentDidMount() {
        // Setting the state for the start time and end time.
        // don't await here so we could parallelize it with other promises in the component
        // its safe to await same promise multiple times

        if (this.props.displayMultipleDevicesGraph) {
            this.getMultipleDevicesGraph()
                .then(() => null)
                .catch(() => null);
        }

        if (this.props.displayViewForDeviceDefinition) {
            await this.updateDeviceDefinitionView(this.props.deviceDefinitionVersionId, this.state.timeSelectOption);
        } else {
            this.props.fetchDeviceAction(this.props.deviceId);
        }

        // Loading initial received definitions.
        await this.loadReceivedDefinitions(
            this.getLookedUpEntityId(),
            this.state.startTime.getTime(),
            this.state.endTime.getTime()
        );

        this.startPolling();
    }

    componentWillUnmount() {
        this.stopPolling();
    }

    render() {
        const { classes, useLinuxView, device } = this.props;
        const { isQueryInterfaceExpanded } = this.state;

        // Getting the base style for the select component.
        let selectStyle = customSelectStyle(this.props.theme);

        // Getting rid of the border when opened.
        selectStyle["control"] = (base, state) => ({
            ...base,
            border: state.isFocused ? 0 : 0,
            // This line disable the blue border
            boxShadow: state.isFocused ? 0 : 0,
            "&:hover": {
                border: state.isFocused ? 0 : 0,
            },
            cursor: "pointer",
        });

        const isMainEntityLoaded = !this.getIsMainEntityLoading();

        const deviceOSFamily = device?.deviceDefinition?.deviceOSFamily || "";
        const isLinuxView = useLinuxView && deviceOSFamily.toLowerCase().includes("linux");

        const isLoading = !this.props.displayViewForDeviceDefinition && !device?.deviceDefinition;

        if (isLoading) {
            return (
                <div className={classes.loadingContainer}>
                    <SternumLogoGradientIcon width={72} height={72} useAnimation={true} />
                </div>
            );
        }

        return (
            <div
                role="presentation"
                aria-label="new device view container"
                className={classNames(classes.flexColumn, classes.fullHeight, classes.removeFocus)}
                tabIndex={-1}
            >
                {/* Title container */}
                <div
                    role="presentation"
                    aria-label="title container"
                    className={classNames(classes.deviceViewUpperContent, classes.titleContainer)}
                >
                    <div>
                        {/* Main Title */}
                        <div className={classes.flexVMiddle}>
                            {/* Back arrow */}
                            {this.props.displayBackButton && (
                                <Icon
                                    className={classNames("fas fa-chevron-left", classes.backButtonIcon)}
                                    fontSize={"small"}
                                    onClick={() => this.closeDeviceView()}
                                />
                            )}

                            {/** Display title content */}
                            {this.getHeaderContent(isMainEntityLoaded)}

                            {/* Status indication */}
                            {isMainEntityLoaded && this.getStatusIndicationComponent()}
                        </div>
                    </div>

                    <div className={classNames(classes.flexVMiddle)}>
                        {isLinuxView && this.renderLinuxTopologyDropdown()}

                        {/* Time selection component */}
                        <TimeSelectionComponent
                            onTimeRangeSelected={this.setSelectedTimeRange}
                            timeSelectedOption={this.state.timeSelectOption}
                            includeLiveTailOption={true}
                        />
                    </div>
                </div>

                {this.props.displayViewForDeviceDefinition && (
                    <div
                        role="presentation"
                        aria-label="details summary line - events list container"
                        className={classNames(
                            !isQueryInterfaceExpanded ? classes.detailsSummaryLine : classes.displayNone
                        )}
                    >
                        {/* Firmware Version */}
                        <Typography
                            variant="body2"
                            className={classNames(
                                classes.flexNoShrink,
                                classes.deviceDefinitionInformationTitle,
                                classes.marginRightXs
                            )}
                        >
                            Firmware Version:
                        </Typography>
                        <Typography
                            variant="body2"
                            className={classNames(classes.deviceDefinitionInformationContent, classes.marginRight)}
                        >
                            {isMainEntityLoaded && this.state.deviceDefinitionVersion.getFirmwareVersion()}
                        </Typography>

                        {/* Active Devices Count */}
                        <Typography
                            variant="body2"
                            className={classNames(
                                classes.flexNoShrink,
                                classes.deviceDefinitionInformationTitle,
                                classes.marginRightXs
                            )}
                        >
                            Total Device Count:
                        </Typography>
                        <Typography
                            variant="body2"
                            className={classNames(classes.deviceDefinitionInformationContent, classes.marginRight)}
                        >
                            {this.state.activeDevicesCount || 0}
                        </Typography>

                        {/* Device Definition ID */}
                        <Typography
                            variant="body2"
                            className={classNames(
                                classes.flexNoShrink,
                                classes.deviceDefinitionInformationTitle,
                                classes.marginRightXs
                            )}
                        >
                            Device Definition ID:
                        </Typography>
                        <Typography variant="body2" className={classNames(classes.deviceDefinitionInformationContent)}>
                            {isMainEntityLoaded && this.state.deviceDefinitionVersion.deviceDefinitionVersionRawId}
                        </Typography>

                        <div
                            onClick={() => this.openDeviceDefinitionClicked()}
                            className={classNames(classes.flexVMiddle, classes.marginLeftLarge)}
                        >
                            <SettingsIcon
                                width={18}
                                height={18}
                                color="#8B949E"
                                className={classNames(classes.cursorPointer)}
                            />
                            <Typography
                                variant="body2"
                                className={classNames(classes.moreInfoText, classes.marginLeftXs, classes.flexNoShrink)}
                            >
                                Settings
                            </Typography>
                        </div>

                        <div
                            onClick={() => this.openDeviceDefinitionInformationModal()}
                            className={classNames(classes.flexVMiddle, classes.marginLeftLarge)}
                        >
                            <BoxIcon
                                width={18}
                                height={18}
                                color="#8B949E"
                                className={classNames(classes.cursorPointer)}
                            />
                            <Typography
                                variant="body2"
                                className={classNames(classes.flexNoShrink, classes.moreInfoText, classes.marginLeftXs)}
                            >
                                Open 3ʳᵈ Party Libraries & CVEs
                            </Typography>
                        </div>

                        <div
                            onClick={() => this.openMapViewClicked()}
                            className={classNames(classes.flexVMiddle, classes.marginLeftLarge)}
                        >
                            <MapIcon
                                width={18}
                                height={18}
                                color="#8B949E"
                                className={classNames(classes.cursorPointer)}
                            />
                            <Typography
                                variant="body2"
                                className={classNames(classes.moreInfoText, classes.marginLeftXs, classes.flexNoShrink)}
                            >
                                Map View
                            </Typography>
                        </div>

                        <div
                            onClick={this.handleAnyExportButtonClick}
                            data-type="cve"
                            className={classNames(classes.flexVMiddle, classes.marginLeftLarge)}
                        >
                            <DownloadIcon
                                width={18}
                                height={18}
                                color="#8B949E"
                                className={classNames(classes.cursorPointer)}
                            />
                            <Typography
                                variant="body2"
                                className={classNames(classes.flexNoShrink, classes.moreInfoText, classes.marginLeftXs)}
                            >
                                Export CVEs
                            </Typography>
                            {this.state.onExportCveClicked && (
                                <div className={classes.paddingLeft}>
                                    <CircularProgress size={15} color="inherit" />
                                </div>
                            )}
                        </div>

                        <div
                            onClick={this.handleAnyExportButtonClick}
                            data-type="devices"
                            className={classNames(classes.flexVMiddle, classes.marginLeftLarge)}
                        >
                            <DownloadIcon
                                width={18}
                                height={18}
                                color="#8B949E"
                                className={classNames(classes.cursorPointer)}
                            />
                            <Typography
                                variant="body2"
                                className={classNames(classes.flexNoShrink, classes.moreInfoText, classes.marginLeftXs)}
                            >
                                Export Devices
                            </Typography>
                            {this.state.onExportDevicesClicked && (
                                <div className={classes.paddingLeft}>
                                    <CircularProgress size={15} color="inherit" />
                                </div>
                            )}
                        </div>

                        <div
                            onClick={this.handleAnyExportButtonClick}
                            data-type="libraries"
                            className={classNames(classes.flexVMiddle, classes.marginLeftLarge)}
                        >
                            <DownloadIcon
                                width={18}
                                height={18}
                                color="#8B949E"
                                className={classNames(classes.cursorPointer)}
                            />
                            <Typography
                                variant="body2"
                                className={classNames(classes.flexNoShrink, classes.moreInfoText, classes.marginLeftXs)}
                            >
                                Export 3ʳᵈ Party Libraries
                            </Typography>
                            {this.state.onExportLibrariesClicked && (
                                <div className={classes.paddingLeft}>
                                    <CircularProgress size={15} color="inherit" />
                                </div>
                            )}
                        </div>

                        <Popover
                            anchorEl={this.state.clickedExportButtonEl.element}
                            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                            open={!!this.state.clickedExportButtonEl.element}
                            onClose={() => this.setState({ clickedExportButtonEl: { element: null, type: "cve" } })}
                            className={classes.popover}
                            elevation={6}
                        >
                            <div
                                className={classNames(
                                    classes.flexVMiddle,
                                    classes.exportHover,
                                    classes.exportItemPadding
                                )}
                                onClick={() => {
                                    const clickedExportButtonType = this.state.clickedExportButtonEl.type;

                                    this.exportOperation(clickedExportButtonType, "CSV");

                                    this.setState({ clickedExportButtonEl: { element: null, type: "cve" } });
                                }}
                            >
                                {/* Icon button */}
                                <IconButton color="primary" className={classNames()}>
                                    <Icon className={classNames("fas fa-file-csv", classes.exportPopperIconSize)} />
                                </IconButton>

                                {/* Export to CSV title */}
                                <Typography
                                    variant="body2"
                                    className={classNames(
                                        !this.state.onExportCveClicked && classes.cursorPointer,
                                        classes.paddingRightXs
                                    )}
                                >
                                    Export CSV
                                </Typography>
                            </div>
                            <div
                                className={classNames(
                                    classes.flexVMiddle,
                                    classes.exportHover,
                                    classes.exportItemPadding
                                )}
                                onClick={() => {
                                    const clickedExportButtonType = this.state.clickedExportButtonEl.type;

                                    this.exportOperation(clickedExportButtonType, "XLSX");

                                    this.setState({ clickedExportButtonEl: { element: null, type: "cve" } });
                                }}
                            >
                                {/* Icon button */}
                                <IconButton color="primary" className={classNames()}>
                                    <Icon className={classNames("fas fa-file-excel", classes.exportPopperIconSize)} />
                                </IconButton>

                                {/* Export to CSV title */}
                                <Typography
                                    variant="body2"
                                    className={classNames(
                                        !this.state.onExportDevicesClicked && classes.cursorPointer,
                                        classes.paddingRightXs
                                    )}
                                >
                                    Export XLSX
                                </Typography>
                            </div>
                        </Popover>
                    </div>
                )}

                {/* Subtitle */}
                {!this.props.displayViewForDeviceDefinition && (
                    <div
                        role="presentation"
                        aria-label="details summary line"
                        className={classNames(
                            !isQueryInterfaceExpanded ? classNames(classes.detailsSummaryLine) : classes.displayNone
                        )}
                    >
                        <Typography variant="body2" className={classNames(classes.deviceSubTitle)}>
                            Last seen on
                        </Typography>
                        {"\u00A0"}
                        <Typography variant="body2" className={classNames(classes.deviceSubTitle, classes.commonBold)}>
                            {isMainEntityLoaded && moment(this.props.device.lastSeen).format("MM/DD/YYYY HH:mm")}
                        </Typography>
                        {"\u00A0"}
                        <Typography variant="body2" className={classNames(classes.deviceSubTitle)}>
                            from
                        </Typography>
                        {"\u00A0"}
                        <Typography variant="body2" className={classNames(classes.deviceSubTitle, classes.commonBold)}>
                            {isMainEntityLoaded && this.props.device.ipAddress}
                        </Typography>

                        {/* Device id indication */}
                        <Typography
                            variant="body2"
                            className={classNames(classes.deviceSubTitle, classes.commonBold, classes.marginLeftLarge)}
                        >
                            Device ID:
                        </Typography>
                        <Typography
                            variant="body2"
                            className={classNames(classes.deviceSubTitle, classes.marginLeftXs)}
                        >
                            {isMainEntityLoaded && this.props.device.receivedDeviceId}
                        </Typography>

                        {/* More Info Link container */}
                        <div
                            className={classNames(classes.flexVMiddle, classes.marginLeftLarge)}
                            onClick={() => this.moreInfoClicked()}
                        >
                            <FolderIcon
                                width={18}
                                height={18}
                                color="#8B949E"
                                className={classNames(classes.cursorPointer)}
                            />
                            <Typography
                                variant="body2"
                                className={classNames(classes.moreInfoText, classes.marginLeftXs)}
                            >
                                Open Device Information
                            </Typography>
                        </div>

                        {/* More Info Link container */}
                        {!!this.props.device && this.props.device.isDeviceDefinitionVersion() && (
                            <div className={classNames(classes.flexRow)}>
                                <div
                                    className={classNames(classes.marginLeftLarge, classes.flexVMiddle)}
                                    onClick={() => this.openDeviceDefinitionClicked()}
                                >
                                    <FolderIcon
                                        width={18}
                                        height={18}
                                        color="#8B949E"
                                        className={classNames(classes.cursorPointer)}
                                    />
                                    <Typography
                                        variant="body2"
                                        className={classNames(classes.moreInfoText, classes.marginLeftXs)}
                                    >
                                        Open Device Definition
                                    </Typography>
                                </div>
                                <div
                                    onClick={() => this.openDeviceGeoLocationHistoryClicked()}
                                    className={classNames(classes.flexVMiddle, classes.marginLeftLarge)}
                                >
                                    <ClockIcon
                                        width={18}
                                        height={18}
                                        color="#8B949E"
                                        className={classNames(classes.cursorPointer)}
                                    />
                                    <Typography
                                        variant="body2"
                                        className={classNames(
                                            classes.moreInfoText,
                                            classes.marginLeftXs,
                                            classes.flexNoShrink
                                        )}
                                    >
                                        Location History
                                    </Typography>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {isLinuxView ? this.renderLinuxView() : this.renderNotLinuxView()}
            </div>
        );
    }

    private renderLinuxView() {
        return (
            <DeviceInventoryLinuxView
                deviceId={this.props.deviceId}
                device={this.props.device}
                deviceDefinition={this.state.deviceDefinition}
                deviceDefinitionVersion={this.state.deviceDefinitionVersion}
                timeSelectOption={this.state.timeSelectOption}
                startDate={this.state.startTime}
                endDate={this.state.endTime}
                refreshEntitiesFilter={this.state.refreshEntitiesFilter}
            />
        );
    }

    private renderNotLinuxView() {
        const { classes } = this.props;
        const { isQueryInterfaceExpanded } = this.state;

        const isMainEntityLoaded = !this.getIsMainEntityLoading();

        // Otherwise, render the device view.
        let lookedUpEntityId = this.getLookedUpEntityId();
        const deviceDefinitionInfo = this.state.deviceDefinition || this.props.device?.deviceDefinition;

        // Multiple Device Graph
        const isMultipleDeviceGraphVisible =
            this.props.displayMultipleDevicesGraph && this.state.totalRelatedDevices > 1;

        const selectedGraphDeviceEntitiesIds = Object.keys(this.state.isGraphDeviceSelectedById)
            .map((id) => this.state.multipleDevicesGraph.graph[id]?.data?.deviceEntityId)
            .filter((entityId) => !!entityId);

        const maxDeviceProfilesNamesVisibleInHeaderGraph = 4;
        const uniqueDeviceProfileNames = Object.keys(this.state.colorByDeviceProfileName);
        const displayedUniqueDeviceProfiles = uniqueDeviceProfileNames.slice(
            0,
            maxDeviceProfilesNamesVisibleInHeaderGraph
        );
        const hiddenUniqueDeviceProfiles = uniqueDeviceProfileNames.slice(displayedUniqueDeviceProfiles.length);

        const queryEditorFields = SternumUtils.getDeviceDefinitionFieldsToQuery(
            true,
            true,
            true,
            true,
            this.state.receivedDefinitions,
            this.state.receivedArguments,
            this.props.displayViewForDeviceDefinition
        );

        // columns for non-linux devices or events tab in linux devices
        let viewedColumns = isMultipleDeviceGraphVisible
            ? ["type", "deviceId", "deviceProfile", "created", "displayName", "details", "eventCategory"]
            : [
                  "type",
                  "deviceId",
                  "deviceProfile",
                  "created",
                  "displayName",
                  "details",
                  "eventCategory",
                  "firmwareVersion",
              ];
        let columnWidthsArray = [10, 10, 10, 10, 10, 10, 60];

        if (this.props.displayViewForDeviceDefinition) {
            // Remove firmwareVersion from device definition view
            viewedColumns.pop();
            viewedColumns.push("deviceId");
        }

        const currentEventsFilter = this.getCurrentEventsFilter();

        let alertsViewColumnWidthsArray = [10];
        if (this.props.displayViewForDeviceDefinition) {
            alertsViewColumnWidthsArray.push(90);
        }

        let alertsViewViewedColumns = ["created", "alertName", "traceCategory"];
        if (this.props.displayViewForDeviceDefinition) {
            alertsViewViewedColumns.push("receivedDeviceId");
        }

        return (
            <>
                {/* Event counts over time */}
                <div
                    role="presentation"
                    aria-label="rounded section container - event counts over time"
                    className={classNames(
                        classes.eventCountOverTimeContainer,
                        isQueryInterfaceExpanded && classes.displayNone
                    )}
                >
                    <div className={classNames(classes.eventCountOverTimeTitleContainer, classes.flexVMiddle)}>
                        <Typography variant="body2" className={classNames(classes.graphTitle)}>
                            Event Counts Over Time
                        </Typography>

                        <ExplanationComponent>
                            <Typography variant="body2">Events group by time frame between selected dates.</Typography>
                        </ExplanationComponent>
                    </div>

                    {/* Event Counts Over Time graph */}
                    <div className={classNames(classes.eventCountOverTimeGraphContainer)}>
                        {isMainEntityLoaded ? (
                            <EventCountOverTimeGraph
                                doNotDisplayLoading={this.state.doNotDisplayLoading}
                                entityId={lookedUpEntityId}
                                // even though deviceDefinitionVersionId is available for device,
                                // if this is used device view, provide undefined
                                // we want history of events for device, disregarding device
                                // definition versions (we want history from all versions)
                                deviceDefinitionVersionId={
                                    this.props.displayViewForDeviceDefinition
                                        ? this.getDeviceDefinitionVersionId()
                                        : undefined
                                }
                                aggregateOverProperty={AggregateOverProperty.TRACE_CATEGORY_NAME}
                                showStackedEvents={false}
                                startTime={this.state.startTime}
                                endTime={this.state.endTime}
                                maxAxisTicksY={5}
                                isHtmlTooltip={true}
                            />
                        ) : (
                            <div className={classes.loaderContainer}>
                                <GraphLoader />
                            </div>
                        )}
                    </div>
                </div>

                {isMultipleDeviceGraphVisible && (
                    <div className={classNames(classes.multipleDevicesGraphContainer)}>
                        <div className={classNames(classes.multipleDevicesGraphTitleContainer)}>
                            <div className={classNames(classes.multipleDevicesGraphLeft)}>
                                <Typography variant="body2" className={classNames(classes.graphTitle)}>
                                    Devices Topology
                                </Typography>

                                <ExplanationComponent>
                                    <Typography variant="body2">
                                        The graph shows the relation between the devices and the device types.
                                    </Typography>
                                </ExplanationComponent>
                            </div>
                            <div className={classNames(classes.multipleDevicesGraphRight)}>
                                {!this.state.loadingMultipleDevicesGraph && (
                                    <>
                                        <GraphHeaderItem selected={true} titleText="Selected" color="white" />
                                        {displayedUniqueDeviceProfiles.map((deviceProfileName) => {
                                            const color = this.state.colorByDeviceProfileName[deviceProfileName];

                                            return (
                                                <GraphHeaderItem
                                                    key={deviceProfileName}
                                                    titleText={deviceProfileName}
                                                    color={color}
                                                />
                                            );
                                        })}
                                        {hiddenUniqueDeviceProfiles.length > 0 && (
                                            <GraphTooltip
                                                arrow={true}
                                                placement="bottom-end"
                                                title={
                                                    <div className={classNames(classes.hiddenDeviceProfilesContainer)}>
                                                        {hiddenUniqueDeviceProfiles.map((deviceProfileName) => {
                                                            const color =
                                                                this.state.colorByDeviceProfileName[deviceProfileName];

                                                            return (
                                                                <GraphHeaderItem
                                                                    key={deviceProfileName}
                                                                    titleText={deviceProfileName}
                                                                    color={color}
                                                                />
                                                            );
                                                        })}
                                                    </div>
                                                }
                                            >
                                                <span>
                                                    <ItemCounter count={hiddenUniqueDeviceProfiles.length} />
                                                </span>
                                            </GraphTooltip>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>

                        <div className={classNames(classes.multipleDevicesGraphContent)}>
                            {this.state.loadingMultipleDevicesGraph ? (
                                <div className={classes.multipleDevicesGraphLoaderContainer}>
                                    <GraphLoader />
                                </div>
                            ) : (
                                <>
                                    <MultipleDevicesSummary
                                        totalRelatedDevices={this.state.totalRelatedDevices}
                                        uniqueDevicesProfiles={this.state.uniqueDeviceProfiles}
                                    />
                                    <MultipleDevicesGraphComponent
                                        multipleDevicesGraph={this.state.multipleDevicesGraph}
                                        isGraphDeviceSelectedById={this.state.isGraphDeviceSelectedById}
                                        maxDeep={8}
                                        onChangeSelectedGraphDevicesById={(isGraphDeviceSelectedById) => {
                                            this.setState({ isGraphDeviceSelectedById });
                                        }}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                )}

                <div
                    role="presentation"
                    aria-label="rounded section container - events list container"
                    className={classNames(
                        classes.eventsListContainer,
                        isQueryInterfaceExpanded && classes.eventsListContainerFlexHeight
                    )}
                >
                    <div className={classNames(classes.tracesListContainer)}>
                        <div className={classNames(this.state.filtersExpanded && classes.marginBottomLarge)}>
                            <div
                                role="presentation"
                                aria-label="events list top bar container"
                                className={classNames(classes.eventsSectionTitleContainer)}
                            >
                                {/* Title content */}
                                <div className={classNames(classes.flexVMiddle, classes.fullWidth)}>
                                    <div
                                        className={classNames(
                                            classes.flexVMiddle,
                                            classes.flexGrow,
                                            classes.eventsSectionTitleContentContainer,
                                            classes.cursorPointer,
                                            classes.fullWidth
                                        )}
                                        onClick={() => this.toggleFilterExpanded()}
                                    >
                                        <>
                                            <FilterIcon color="#ACB4BD" />

                                            <Typography
                                                variant="body2"
                                                className={classNames(classes.eventsSectionTitleTypography)}
                                            >
                                                Filters
                                            </Typography>

                                            <div className={classes.filterVerticalDivider} />

                                            <div className={classNames(classes.flexVMiddle, classes.chipsContainer)}>
                                                {this.state.temporarySternumQuery?.innerQueries.map((query, index) => {
                                                    const labels = query.filters.map((filter) => {
                                                        const conditionApiName =
                                                            filter.conditionApiName as ConditionType;

                                                        let value = filter.valuesMap.values()[0]?.displayValue;
                                                        const field = queryEditorFields.find(
                                                            (field) => field.apiName === filter.fieldApiName
                                                        );

                                                        if (
                                                            [
                                                                ConditionType.IS_EMPTY,
                                                                ConditionType.IS_NOT_EMPTY,
                                                            ].includes(conditionApiName)
                                                        ) {
                                                            const condition =
                                                                SternumConfiguration.getCondition(conditionApiName);

                                                            value = condition?.label;
                                                        }

                                                        value = isString(value)
                                                            ? value
                                                            : (value as React.ReactElement)?.props?.children || "";

                                                        const label = field ? `${field.label}: ${value}` : "";

                                                        return label;
                                                    });

                                                    return (
                                                        <div
                                                            key={query.id}
                                                            className={classNames(
                                                                classes.marginRight,
                                                                classes.flexVMiddle
                                                            )}
                                                        >
                                                            <Tooltip title={labels.join("; ")} placement="top">
                                                                <Chip
                                                                    role="button"
                                                                    aria-label="filter chip"
                                                                    className={classes.filterChip}
                                                                    variant={
                                                                        query.id === this.state.selectedQuery?.id
                                                                            ? "default"
                                                                            : "outlined"
                                                                    }
                                                                    color="primary"
                                                                    label={labels.join("; ") || "Undefined"}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        this.handleQuerySelect(query);
                                                                    }}
                                                                    onDelete={() => {
                                                                        this.handleQueryDelete(query);
                                                                    }}
                                                                />
                                                            </Tooltip>

                                                            {index !==
                                                                this.state.temporarySternumQuery.innerQueries.length -
                                                                    1 && (
                                                                <Typography
                                                                    variant="body1"
                                                                    className={classes.marginLeft}
                                                                >
                                                                    or
                                                                </Typography>
                                                            )}
                                                        </div>
                                                    );
                                                })}

                                                <Tooltip title="Add Filter" placement="top">
                                                    <div role="button" aria-label="add filter" style={{ height: 20 }}>
                                                        <PlusFilledIcon
                                                            width={20}
                                                            height={20}
                                                            color="#1B6DD9"
                                                            className={classNames(classes.cursorPointer)}
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                this.handleAddQueryClick();
                                                            }}
                                                        />
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </>

                                        <div
                                            role="button"
                                            aria-label="show and hide data in table"
                                            className={classNames(
                                                classes.showHideTableDataBadge,
                                                this.state.filtersExpanded && "open"
                                            )}
                                        >
                                            <Typography
                                                className={classNames(
                                                    classes.showHideTableDataText,
                                                    this.state.filtersExpanded && "open"
                                                )}
                                            >
                                                {this.state.filtersExpanded ? "Hide Data" : "Show Data"}
                                            </Typography>
                                            <div
                                                className={classNames(
                                                    classes.dropdownIconContainer,
                                                    this.state.filtersExpanded && "open"
                                                )}
                                            >
                                                <ArrowLeftIcon
                                                    color={this.state.filtersExpanded ? "#1B6FDE" : "#fff"}
                                                    className={classNames(
                                                        classes.dropdownIcon,
                                                        this.state.filtersExpanded && classes.dropdownIconExpanded
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {this.state.filtersExpanded && this.state.selectedQuery && (
                                    <div
                                        role="presentation"
                                        aria-label="filter container"
                                        className={classNames(classes.filterContainer)}
                                    >
                                        {/* Filtering the events below */}
                                        {this.state.filtersExpanded && (
                                            <div
                                                className={classNames(
                                                    classes.marginLeftLarge,
                                                    classes.marginTop,
                                                    classes.flex
                                                )}
                                            >
                                                <SternumQueryEditor
                                                    withoutInnerQueries
                                                    loading={this.state.loadingReceivedDefinitions}
                                                    error={this.state.errorLoadingDeviceDefinition}
                                                    fields={queryEditorFields}
                                                    sternumQuery={this.state.selectedQuery}
                                                    onSternumQueryChanged={(updatedQuery) =>
                                                        this.onTemporarySternumQueryChanged(updatedQuery)
                                                    }
                                                />
                                            </div>
                                        )}

                                        {/* Apply */}
                                        {this.state.filtersExpanded && this.state.sternumQueryChanged && (
                                            <div className={classNames(classes.queryEditorFooter)}>
                                                <SternumImprovedButton
                                                    fullWidth={false}
                                                    onClick={() => this.applyTemporaryQuery()}
                                                    isDisabled={this.state.isApplyFilterDisabled}
                                                    content="Apply"
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>

                        {isMainEntityLoaded && (
                            <SternumDeviceEventsList
                                clientId={ServiceWire.getClientsService().getSelectedClientId()}
                                deviceIds={selectedGraphDeviceEntitiesIds}
                                isTableExpanded={this.state.isQueryInterfaceExpanded}
                                onTableExpandToggle={this.toggleQueryInterfaceExpanded}
                                doNotDisplayExploitationTypeInDisplayName={true}
                                columnWidthsArray={columnWidthsArray}
                                doNotDisplayLoading={this.state.doNotDisplayLoading}
                                refreshEntitiesFilter={this.state.refreshEntitiesFilter}
                                onNewVisualizationClicked={() => this.onVisualisationClicked()}
                                onExistingVisualisationClicked={(visualisationId) =>
                                    this.onVisualisationClicked(visualisationId)
                                }
                                toolbarState={
                                    new TableToolbarDisplayState(
                                        false,
                                        false,
                                        false,
                                        false,
                                        true,
                                        true,
                                        true,
                                        false,
                                        "Search Event Name",
                                        true,
                                        true
                                    )
                                }
                                infiniteScroll={true}
                                entityId={lookedUpEntityId}
                                deviceDefinition={deviceDefinitionInfo}
                                viewedColumnsSet={HashSet.fromValues(viewedColumns)}
                                entitiesFilter={currentEventsFilter}
                                selectedTimeSelectionType={this.state.timeSelectOption.selectionType}
                                deviceDefinitionVersionId={
                                    this.props.displayViewForDeviceDefinition
                                        ? this.getDeviceDefinitionVersionId()
                                        : undefined
                                }
                                shouldDisplayLinkToDeviceView={this.props.displayViewForDeviceDefinition}
                                onFiltersChanged={(updatedSternumDeviceEventsFilter) =>
                                    this.onSternumDeviceEventsFilterChanged(updatedSternumDeviceEventsFilter)
                                }
                                paperClassNames={classNames(
                                    !isQueryInterfaceExpanded
                                        ? classes.tracesListPaper
                                        : classNames(classes.tracesListPaperFlexHeight, classes.marginTopLarge),
                                    "borderTop",
                                    !this.state.filtersExpanded && classes.displayNone
                                )}
                                displayBackButtonInTraceView={true}
                                displayXButtonInTraceView={true}
                                emptyTableMessage={"Events will appear here."}
                                shouldNoWrapDisplayName={false}
                                displayTypeColumn={true}
                                indexEventTime={this.state.indexEventTime}
                                contextFromTimestamp={this.props.contextFromTimestamp}
                                contextTraceId={this.props.contextTraceId}
                                hideShowInContext={this.props.hideShowInContext}
                                excludeLinuxViewTraces={this.props.displayViewForDeviceDefinition}
                            />
                        )}
                    </div>
                </div>

                <div
                    className={classNames(
                        !isQueryInterfaceExpanded ? classes.metricVisualisationsContainer : classes.displayNone
                    )}
                >
                    {/* Event Count */}
                    <div
                        role="presentation"
                        aria-label="rounded section container - events by category"
                        className={classNames(
                            classes.eventCountContainer,
                            classes.flexNoShrink,
                            this.props.displayViewForDeviceDefinition && "mod-half-size"
                        )}
                    >
                        {/* Title */}
                        <div className={classNames(classes.eventCountTitleContainer, classes.flexVMiddle)}>
                            <Typography variant="body2" className={classNames(classes.graphTitle)}>
                                Events By Category
                            </Typography>

                            {/* Explanation */}
                            <ExplanationComponent>
                                <Typography variant="body2">
                                    Events group by category between selected dates.
                                </Typography>
                            </ExplanationComponent>
                        </div>

                        {/* Graph */}
                        <div className={classNames(classes.eventCountGraphContainer)}>
                            {isMainEntityLoaded ? (
                                <EventCountGraph
                                    doNotDisplayLoading={this.state.doNotDisplayLoading}
                                    doNotDisplayCategoriesLabels={this.props.displayViewForDeviceDefinition}
                                    entityId={lookedUpEntityId}
                                    deviceDefinitionVersionId={this.getDeviceDefinitionVersionId()}
                                    aggregateOverProperty={AggregateOverProperty.TRACE_CATEGORY_NAME}
                                    startTime={this.state.startTime}
                                    endTime={this.state.endTime}
                                    isCustomTooltip={true}
                                    limitLabelWidth={false}
                                />
                            ) : (
                                <div className={classes.loaderContainer}>
                                    <GraphLoader />
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Alerts Counts */}
                    {this.props.displayViewForDeviceDefinition && (
                        <div
                            role="presentation"
                            aria-label="rounded section container - custom alerts"
                            className={classNames(
                                classes.sternumDeviceEventsListContainer,
                                classes.marginRight,
                                classes.flexNoShrink
                            )}
                        >
                            {/* Title */}
                            <div className={classNames(classes.sternumDeviceEventsListTitleContainer)}>
                                <Typography variant="body2" className={classNames(classes.graphTitle)}>
                                    Custom Alerts
                                </Typography>
                            </div>

                            {/* Triggers List */}
                            <TriggersHitsList
                                columnWidthsArray={[10]}
                                doNotDisplayLoading={this.state.doNotDisplayLoading}
                                deviceDefinitionVersionId={isMainEntityLoaded && this.getDeviceDefinitionVersionId()}
                                entityId={lookedUpEntityId}
                                hideUpperBorder={true}
                                deviceDefinition={deviceDefinitionInfo}
                                viewedColumnsSet={HashSet.fromValues(["displayName", "count", "triggerDisabled"])}
                                amountOfLoadingPlaceholders={2}
                                createdFrom={this.state.startTime}
                                createdTo={this.state.endTime}
                                hideToolbar={true}
                                toolbarState={
                                    new TableToolbarDisplayState(false, false, false, false, false, false, true, false)
                                }
                                paperClassNames={classNames(classes.sternumEventsPaper)}
                            />
                        </div>
                    )}

                    {/* Notifications List */}
                    <div
                        role="presentation"
                        aria-label="rounded section container - alerts and ai insights"
                        className={classNames(
                            classes.sternumDeviceEventsListContainer,
                            classes.flexNoShrink,
                            classes.marginRightLarge
                        )}
                    >
                        {/* Title */}
                        <div className={classNames(classes.sternumDeviceEventsListTitleContainer, classes.flexVMiddle)}>
                            <Typography variant="body2" className={classNames(classes.graphTitle)}>
                                Alerts and AI Insights
                            </Typography>

                            <ExplanationComponent>
                                <Typography variant="body2">
                                    This section is for <b>unresolved</b> items. The resolved alerts can be found in the
                                    dashboard.
                                </Typography>
                            </ExplanationComponent>
                        </div>

                        {/* Alerts Triggered List */}
                        <div className={classes.issuesTableWrapper}>
                            <IssuesList
                                enablePolling={true}
                                initialColumnHeaders={this.initialAlertsTableColumns}
                                issuesFilter={
                                    new IssuesFilter(
                                        this.state.startTime.getTime(),
                                        this.state.endTime.getTime(),
                                        false,
                                        false,
                                        [IssueInfoStatus.Open],
                                        {},
                                        undefined,
                                        false,
                                        true
                                    )
                                }
                                viewedColumnsSet={HashSet.fromValues(alertsViewViewedColumns)}
                                deviceId={this.props.displayViewForDeviceDefinition ? undefined : this.props.deviceId}
                                deviceDefinitionVersionId={
                                    this.props.displayViewForDeviceDefinition ? this.getLookedUpEntityId() : undefined
                                }
                                displayXButtonInTraceView={true}
                                displayBackButton={false}
                                hideUpperBorder={true}
                                // TODO: huge workaround - there is a condition inside of this component
                                // `forceRefresh !== prevProps.forceRefresh => trigger reload`,
                                // and here date reference change should trigger issues list reload
                                forceRefresh={this.state.startTime}
                                pageSize={SternumConfiguration.getPageSize()}
                                narrow
                                hideResolveAll
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }

    private renderLinuxTopologyDropdown() {
        const { deviceId } = this.props;
        const { multipleDevicesGraph } = this.state;

        if (this.state.totalRelatedDevices <= 1) {
            return null;
        }

        return <LinuxTopologyDropdown deviceId={deviceId} multipleDevicesGraph={multipleDevicesGraph} />;
    }

    /**
     * Handles the fact that new events were indexed.
     */
    private handleEventsIndexed(fromTimestamp: number, changedData: Object): void {
        if (changedData && changedData !== -1) {
            this.updateTimeRange(false, fromTimestamp);
        }
    }

    private updateTimeRange(displayLoadingIcon: boolean, timeUpdate?: number) {
        let newStartTime = new Date(moment().toDate().getTime() - this.state.selectedTimeRangeInMiliSec);

        let newEndTime = moment().toDate();

        // Time is selected, check selection type and update dates.
        if (this.state.timeSelectOption) {
            const selectedTime = this.state.timeSelectOption;

            // Live tail is activated, new start time will be the activation time
            if (selectedTime.selectionType === TimeSelectionType.LIVE) {
                newStartTime = this.state.startTime;
            }

            // Custom, use existing time range.
            else if (selectedTime.selectionType === TimeSelectionType.CUSTOM) {
                newStartTime = selectedTime.timeRange.start;
                newEndTime = selectedTime.timeRange.end;
            }
        }

        this.setState({
            startTime: newStartTime,
            endTime: newEndTime,
            doNotDisplayLoading: !displayLoadingIcon,
            indexEventTime: timeUpdate ? timeUpdate : this.state.indexEventTime,
        });
    }

    /**
     * Handles device definition update.
     */
    private async handleDeviceDefinitionUpdates(fromTimestamp: number, changedData: Object) {
        if (changedData && changedData !== -1) {
            // Load device definition version.
            let deviceDefinitionVersion: DeviceDefinitionVersionInfo =
                await ServiceWire.getSternumService().getDeviceDefinitionVersion(this.getDeviceDefinitionVersionId());
            this.setState({
                deviceDefinition: deviceDefinitionVersion.deviceDefinition,
                deviceDefinitionVersion: deviceDefinitionVersion,
            });
        }
    }

    /**
     * Update time from time select component
     */

    private setSelectedTimeRange = (timeSelectOption: TimeSelectOption) => {
        this.setState(
            {
                startTime: timeSelectOption.timeRange.start,
                endTime: timeSelectOption.timeRange.end,
                selectedTimeRangeInMiliSec:
                    timeSelectOption.timeRange.end.getTime() - timeSelectOption.timeRange.start.getTime(),
                doNotDisplayLoading: false,
                timeSelectOption: timeSelectOption,
                refreshEntitiesFilter: true,
            },
            async () => {
                this.setState({
                    refreshEntitiesFilter: false,
                });

                if (this.props.displayMultipleDevicesGraph) {
                    this.getMultipleDevicesGraph()
                        .then(() => null)
                        .catch(() => null);
                }

                // We changed time range - we need to reload received definitions.
                await this.loadReceivedDefinitions(
                    this.getLookedUpEntityId(),
                    timeSelectOption.timeRange.start.getTime(),
                    timeSelectOption.timeRange.end.getTime()
                );
            }
        );
    };

    /**
     * Stop polling for new data if it's custom state
     */
    private stopPolling = () => {
        ServiceWire.getPollingService().unsubscribe("NewDeviceView", PollingChangeType.EVENTS_INDEXED);
        ServiceWire.getPollingService().unsubscribe("DeviceDefinitionView", PollingChangeType.ALL_DEVICE_DEFINITIONS);
    };

    /**
     * Start polling for new data if it's custom state
     */
    private startPolling = () => {
        // Registering to polling for new events.
        ServiceWire.getPollingService().subscribe(
            new PollingSubscriptionInfo(
                "NewDeviceView",
                PollingChangeType.EVENTS_INDEXED,
                this.handleEventsIndexed.bind(this)
            )
        );
        // If it's device definition view, register for updates
        if (this.props.displayViewForDeviceDefinition) {
            ServiceWire.getPollingService().subscribe(
                new PollingSubscriptionInfo(
                    "DeviceDefinitionView",
                    PollingChangeType.ALL_DEVICE_DEFINITIONS,
                    this.handleDeviceDefinitionUpdates.bind(this)
                )
            );
        }
    };

    private toggleQueryInterfaceExpanded = () => {
        this.setState({ isQueryInterfaceExpanded: !this.state.isQueryInterfaceExpanded });
    };

    private getIsMainEntityLoading = () => {
        if (
            this.props.displayViewForDeviceDefinition &&
            this.state.deviceDefinitionVersion === null &&
            (this.state.loadingDeviceDefinition || !this.state.deviceDefinition)
        ) {
            return true;
        }

        if (!this.props.displayViewForDeviceDefinition && (this.props.loadingDevice || !this.props.device)) {
            return true;
        }
        if (!this.state.startTime || !this.state.endTime) {
            return true;
        }

        return false;
    };

    /**
     * Occurs once add filter is clicked.
     */
    private handleAddQueryClick() {
        // Cloning query so we won't work on original copy.
        let clonedQuery: SternumQuery = this.state.temporarySternumQuery.clone();

        const newQuery = SternumQuery.getEmptyQuery();
        clonedQuery.innerQueries.push(newQuery);

        this.setState({
            temporarySternumQuery: clonedQuery,
            selectedQuery: newQuery,
            filtersExpanded: true,
        });
    }

    private handleQuerySelect(query: SternumQuery) {
        this.setState({ selectedQuery: query });
    }

    private handleQueryDelete(innerQuery: SternumQuery) {
        const query = this.state.temporarySternumQuery.clone();
        query.innerQueries = query.innerQueries.filter((q) => q.id !== innerQuery.id);

        this.setState({ temporarySternumQuery: query, selectedQuery: query.innerQueries[0] }, () => {
            this.applyTemporaryQuery();
        });
    }

    private getHeaderContent(isMainEntityLoaded: boolean) {
        const { classes } = this.props;

        const headerPrefix = (
            <Typography variant={"h5"} className={classes.title}>
                {this.props.displayViewForDeviceDefinition ? `Device Profile: ` : `Device: `}
            </Typography>
        );
        const definitionName = isMainEntityLoaded && (
            <Typography variant={"h5"} className={classes.title}>
                {this.state.deviceDefinitionVersion
                    ? this.state.deviceDefinitionVersion.getVersionName()
                    : this.props.device.deviceDefinition.displayName}
            </Typography>
        );
        return this.props.fleetViewDefaultItem ? (
            <>
                {headerPrefix}
                <div className={classNames(classes.flexRow, classes.marginLeft)}>
                    <SternumCustomSelect
                        selectList={this.props.fleetViewSelectList}
                        onSelected={async (timeSelection: SternumSelectData) => {
                            await this.onDropDownChange(timeSelection);
                        }}
                        selectedItem={this.state.selectedItem}
                    />
                </div>
            </>
        ) : (
            <>
                {headerPrefix}
                {definitionName}
            </>
        );
    }

    /**
     * Gets the current filter object.
     */
    private getCurrentEventsFilter() {
        return new SternumDeviceEventsFilter(
            null,
            false,
            this.props.contextFromTimestamp
                ? this.props.contextFromTimestamp
                : this.state.startTime
                ? this.state.startTime.getTime()
                : null,
            this.state.endTime ? this.state.endTime.getTime() : null,
            null,
            this.props.contextFromTimestamp ? this.props.contextTraceId : null,
            emptyArray,
            false,
            this.state.sternumQuery,
            null
        );
    }

    /**
     * Gets the relevant entity id we're looking it - a device or device definition version id.
     */
    private getLookedUpEntityId(): string {
        if (this.props.displayViewForDeviceDefinition) {
            const id = this.state.deviceDefinitionVersion
                ? this.state.deviceDefinitionVersion.entityId
                : this.props.deviceDefinitionVersionId;

            if (id) {
                return id;
            }

            if (this.props.fleetViewDefaultItem) {
                return this.props.fleetViewDefaultItem.entityId;
            }
        }

        return this.props.deviceId;
    }

    /**
     * Occurs when the visualization button clicked.
     */
    private async onVisualisationClicked(visualisationId?: string) {
        let deviceDefinitionVersion: DeviceDefinitionVersionInfo;

        if (!this.props.displayViewForDeviceDefinition) {
            deviceDefinitionVersion = await ServiceWire.getSternumService().getDeviceDefinitionVersion(
                this.props.device.lastSeenVersionId
            );
        } else {
            deviceDefinitionVersion = this.state.deviceDefinitionVersion;
        }

        this.props.openVisualizationCreationModal(
            "VisualizationCreation",
            visualisationId,
            [deviceDefinitionVersion],
            {
                [deviceDefinitionVersion.entityId]: {
                    receivedArguments: this.state.receivedArguments,
                    receivedDefinitions: this.state.receivedDefinitions,
                },
            },
            this.state.startTime,
            this.state.endTime,
            SternumQuery.getEmptyQuery(),
            this.props.displayViewForDeviceDefinition,
            false,
            false,
            this.getLookedUpEntityId(),
            this.state.timeSelectOption,
            this.props.displayViewForDeviceDefinition ? undefined : this.props.deviceId
        );
    }

    /**
     * Occurs on the change of events filter.
     */
    private onSternumDeviceEventsFilterChanged(updatedSternumDeviceEventsFilter: SternumDeviceEventsFilter) {
        if (updatedSternumDeviceEventsFilter) {
            this.setState({
                isApplyFilterDisabled: false,
                sternumQuery: updatedSternumDeviceEventsFilter.sternumQuery,
            });
        }
    }

    /**
     * Occurs once the more info button is clicked.
     */
    private moreInfoClicked() {
        this.props.openDeviceInfoModal(this.props.deviceId, this.props.deviceId, true, false);
    }

    /**
     * Occurs once the open device definition information is clicked.
     */
    private openDeviceDefinitionInformationModal() {
        const entityId: string = this.state.deviceDefinitionVersion
            ? this.state.deviceDefinitionVersion.entityId
            : this.props.device.deviceDefinition.entityId;

        this.props.openDeviceDefinitionInfoModal(entityId, entityId, true, false);
    }

    /**
     * Occurs once the export cves is clicked.
     */
    private handleAnyExportButtonClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (this.props.displayViewForDeviceDefinition) {
            const buttonType = e.currentTarget.dataset.type as "cve" | "devices" | "libraries";

            this.setState({ clickedExportButtonEl: { element: e.currentTarget, type: buttonType } });
        }
    };

    /**
     * Occurs once the export devices is clicked.
     */
    private exportDeviceDefinitionDevices = async (format: "CSV" | "XLSX") => {
        if (!this.state.onExportDevicesClicked) {
            this.setState({ onExportDevicesClicked: true });

            try {
                // Download report.
                const response: HttpResponse =
                    await ServiceWire.getSternumService().exportDeviceDefinitionDevicesReport(
                        this.state.deviceDefinitionVersion.entityId,
                        format
                    );

                // Download the report.
                WebUtils.downloadReport(response);
                this.setState({ onExportDevicesClicked: false });
            } catch (err) {
                this.setState({ onExportDevicesClicked: false });
            }
        }
    };

    /**
     * Occurs once the export cves is clicked.
     */
    private exportDeviceDefinitionCVEs = async (format: "CSV" | "XLSX") => {
        if (!this.state.onExportCveClicked) {
            this.setState({ onExportCveClicked: true });

            try {
                // Download report.
                const response: HttpResponse = await ServiceWire.getSternumService().exportDeviceDefinitionCVEsReport(
                    this.state.deviceDefinitionVersion.entityId,
                    format
                );

                // Download the report.
                WebUtils.downloadReport(response);

                this.setState({ onExportCveClicked: false });
            } catch (err) {
                this.setState({ onExportCveClicked: false });
            }
        }
    };

    /**
     * Occurs once the export 3rd party libraries is clicked.
     */
    private export3rdPartyLibraries = async (format: "CSV" | "XLSX") => {
        if (!this.state.onExportLibrariesClicked) {
            this.setState({ onExportLibrariesClicked: true });

            try {
                // Download report.
                const response: HttpResponse =
                    await ServiceWire.getSternumService().exportDeviceDefinition3rdPartyLibrariesReport(
                        this.state.deviceDefinitionVersion.entityId,
                        format
                    );

                // Download the report.
                WebUtils.downloadReport(response);

                this.setState({ onExportLibrariesClicked: false });
            } catch (err) {
                this.setState({ onExportLibrariesClicked: false });
            }
        }
    };

    private exportOperation = (clickedExportButtonType: string, extension: string) => {
        switch (clickedExportButtonType) {
            case "cve":
                if (extension === "XLSX") {
                    this.exportDeviceDefinitionCVEs("XLSX");
                } else {
                    this.exportDeviceDefinitionCVEs("CSV");
                }

                break;
            case "devices":
                if (extension === "XLSX") {
                    this.exportDeviceDefinitionDevices("XLSX");
                } else {
                    this.exportDeviceDefinitionDevices("CSV");
                }
                break;
            case "libraries":
                if (extension === "XLSX") {
                    this.export3rdPartyLibraries("XLSX");
                } else {
                    this.export3rdPartyLibraries("CSV");
                }
                break;
        }
    };

    /**
     * Occurs once the open device definition button is clicked.
     */
    private openDeviceDefinitionClicked() {
        const deviceDefinitionVersionId = this.getDeviceDefinitionVersionId();

        if (this.props.displayViewForDeviceDefinition) {
            this.props.editDeviceDefinitionModal(
                deviceDefinitionVersionId,
                deviceDefinitionVersionId,
                true,
                false,
                () => {}
            );
        } else {
            window.open(`device-profile/${deviceDefinitionVersionId}`, "_blank");
        }
    }

    /**
     * Occurs once the open map view button is clicked.
     */
    private openMapViewClicked() {
        const entityId = this.getLookedUpEntityId();
        this.props.openMapModal(
            entityId,
            entityId,
            this.state.timeSelectOption.timeRange.start.getTime(),
            this.state.timeSelectOption.timeRange.end.getTime(),
            "Map View",
            true
        );
    }

    /**
     * Occurs once the open map view button is clicked.
     */
    private openDeviceGeoLocationHistoryClicked() {
        const entityId = this.getLookedUpEntityId();
        this.props.openDeviceGeoLocationHistoryModal(
            entityId,
            entityId,
            this.state.timeSelectOption.timeRange.start.getTime(),
            this.state.timeSelectOption.timeRange.end.getTime(),
            "Location History",
            true,
            this.props.device.deviceDefinition,
            this.getDeviceDefinitionVersionId()
        );
    }

    /**
     * Closes the device view.
     */
    private closeDeviceView() {
        this.props.closeModal(new ModalKey(ModalType.DeviceViewModal, this.props.deviceId));
    }

    /**
     * Returns the component to display status indication.
     */
    private getStatusIndicationComponent() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                {!this.props.displayViewForDeviceDefinition && this.props.device.deviceMode === DeviceMode.Development && (
                    <div className={classNames(classes.marginRightXs)}>
                        <StatusDisplay
                            label={this.props.device.getDeviceModeLabel()}
                            devMode
                            displayColoredBackground
                            centerText
                            padding
                        />
                    </div>
                )}

                {/* Device status indication */}
                {!this.props.displayViewForDeviceDefinition &&
                    this.props.device.status !== DeviceStatus.MULTIPLE_ISSUES &&
                    this.props.device.status !== DeviceStatus.CRITICAL_CVE &&
                    this.props.device.status !== DeviceStatus.OUT_OF_DATE_LIBRARIES && (
                        <div className={classNames(classes.marginRightXs)}>
                            <StatusDisplay
                                label={this.props.device.getStatusDisplay()}
                                danger={this.props.device.isAlertingStatus()}
                                success={this.props.device.isSecured()}
                                displayColoredBackground={true}
                                centerText={true}
                                padding={true}
                            />
                        </div>
                    )}

                {!this.props.displayViewForDeviceDefinition &&
                    this.props.device.cvesCount &&
                    this.props.device.cvesCount > 0 && (
                        <div className={classNames(classes.marginRightXs)}>
                            <StatusDisplay
                                label={DeviceInfo.getStatusDisplay(DeviceStatus.CRITICAL_CVE)}
                                danger={false}
                                success={false}
                                medium={true}
                                displayColoredBackground={true}
                                centerText={true}
                                padding={true}
                            />
                        </div>
                    )}

                {!this.props.displayViewForDeviceDefinition &&
                    this.props.device.outOfDateLibrariesCount &&
                    this.props.device.outOfDateLibrariesCount > 0 && (
                        <StatusDisplay
                            label={DeviceInfo.getStatusDisplay(DeviceStatus.OUT_OF_DATE_LIBRARIES)}
                            danger={false}
                            success={false}
                            medium={true}
                            displayColoredBackground={true}
                            centerText={true}
                            padding={true}
                        />
                    )}
            </React.Fragment>
        );
    }

    private async onTemporarySternumQueryChanged(innerQuery: SternumQuery) {
        this.setState((prevState) => {
            const clonedQuery = prevState.temporarySternumQuery.clone();
            clonedQuery.innerQueries = clonedQuery.innerQueries.map((query) =>
                query.id === innerQuery.id ? innerQuery : query
            );

            return { temporarySternumQuery: clonedQuery, selectedQuery: innerQuery, sternumQueryChanged: true };
        });
    }

    private applyTemporaryQuery() {
        this.setState(
            {
                isApplyFilterDisabled: true,
                sternumQuery: this.state.temporarySternumQuery,
                selectedQuery: null,
                refreshEntitiesFilter: true,
            },
            () => {
                this.setState({
                    refreshEntitiesFilter: false,
                });
            }
        );
    }

    /**
     * Loads the received definitions to be displayed in filtering.
     */
    private async loadReceivedDefinitions(lookedUpEntityId: string, createdFrom: number, createdTo: number) {
        this.setState({
            loadingReceivedDefinitions: true,
            errorLoadingReceivedDefinitions: false,
        });

        try {
            const receivedDefinitionsResponse = await ServiceWire.getEventsApiService().getAllReceivedDefinitions(
                lookedUpEntityId,
                createdFrom,
                createdTo
            );

            this.setState({
                receivedDefinitions: receivedDefinitionsResponse.receivedDefinitions,
                receivedArguments: receivedDefinitionsResponse.receivedArguments,
                loadingReceivedDefinitions: false,
                errorLoadingReceivedDefinitions: false,
            });
        } catch (error) {
            this.setState({
                loadingReceivedDefinitions: false,
                errorLoadingReceivedDefinitions: true,
            });
        }
    }

    /**
     * Toggles the expand of filter section.
     */
    private async toggleFilterExpanded() {
        this.setState({
            filtersExpanded: !this.state.filtersExpanded,
            isQueryInterfaceExpanded: false,
        });
    }

    /**
     * Handle fleet view drop down select option.
     */
    private async onDropDownChange(selectedItem: SternumSelectData) {
        this.setState({
            selectedItem: { ...selectedItem },
            deviceDefinitionVersion: null,
            loadingDeviceDefinition: true,
        });

        await this.updateDeviceDefinitionView(selectedItem.entityId, this.state.timeSelectOption);
        this.props.history.replace(`/fleet-view/${selectedItem.entityId}`);
        this.updateTimeRange(true);
        // Loading initial received definitions.
        await this.loadReceivedDefinitions(
            this.getLookedUpEntityId(),
            this.state.startTime.getTime(),
            this.state.endTime.getTime()
        );
    }

    /**
     *
     * Gets the device definition version by the component state (device view or definition view)
     */

    private getDeviceDefinitionVersionId(): string | undefined {
        if (this.props.displayViewForDeviceDefinition) {
            return this.state.deviceDefinitionVersion.entityId;
        }
        return this.props.device.lastSeenVersionId;
    }

    private generateColorPaletteByDeviceProfileName(deviceProfilesNames: Set<string>): Record<string, string> {
        const maxRGB = [126, 181, 255];
        const minRGB = [0, 67, 98];

        const steps = maxRGB.map((max, i) => (max - minRGB[i]) / deviceProfilesNames.size);
        const colorByDeviceProfileName: Record<string, string> = {};

        let resultColor = maxRGB;

        deviceProfilesNames.forEach((deviceProfileName) => {
            colorByDeviceProfileName[deviceProfileName] = `rgb(${resultColor.join(",")})`;

            resultColor = resultColor.map((color, i) => color - steps[i]);
        });

        return colorByDeviceProfileName;
    }

    private calculateMultipleDevicesGraphWithColorPalette(graphData: MultipleDevicesGraphRelationshipResponse): {
        multipleDevicesGraph: GraphData<DeviceGraphData>;
        colorByDeviceProfileName: Record<string, string>;
        totalRelatedDevices: number;
        uniqueDeviceProfiles: number;
    } {
        const deviceProfileNames = new Set<string>();
        const graphDataById: GraphData<DeviceGraphData> = {
            root: { childIds: [getId(graphData.graph)] },
            graph: {},
        };

        loopGraphStructure([graphData.graph], "root", (relatedDevice) => {
            deviceProfileNames.add(getDeviceProfileName(relatedDevice));
        });

        const colorByDeviceProfileName = this.generateColorPaletteByDeviceProfileName(deviceProfileNames);

        loopGraphStructure([graphData.graph], "root", (relatedDevice, parentId) => {
            const deviceProfileName = getDeviceProfileName(relatedDevice);

            if (graphDataById.graph[getId(relatedDevice)]) {
                return;
            }

            graphDataById.graph[getId(relatedDevice)] = {
                data: {
                    id: getId(relatedDevice),
                    color: colorByDeviceProfileName[deviceProfileName],
                    deviceId: relatedDevice.device.received_device_id,
                    lastSeen: relatedDevice.device.last_seen,
                    deviceProfile: deviceProfileName,
                    lastSeenFirmwareVersion: relatedDevice.device.last_seen_version_firmware,
                    clientId: relatedDevice.device.client_id,
                    deviceEntityId: relatedDevice.device.entity_id,
                },
                parentId,
                childIds: relatedDevice.related_devices?.map?.((relatedDevice) => getId(relatedDevice)) || [],
            };
        });

        return {
            multipleDevicesGraph: graphDataById,
            colorByDeviceProfileName,
            totalRelatedDevices: Object.keys(graphDataById.graph).length,
            uniqueDeviceProfiles: deviceProfileNames.size,
        };

        function getId(device: MultipleDeviceGraphRelatedDevices): string {
            return device.device.entity_id;
        }

        function getDeviceProfileName(device: MultipleDeviceGraphRelatedDevices) {
            const relatedEntity = graphData.related_entities_map[device.device.device_definition_id];

            return relatedEntity?.device_display_name || "";
        }

        function loopGraphStructure(
            relatedDevices: MultipleDeviceGraphRelatedDevices[],
            parentId: string,
            callback: (relatedDevice: MultipleDeviceGraphRelatedDevices, parentId: string) => unknown
        ) {
            relatedDevices.forEach((device) => {
                callback(device, parentId);
                loopGraphStructure(device.related_devices || [], getId(device), callback);
            });
        }
    }

    private async getMultipleDevicesGraph() {
        this.setState({
            loadingMultipleDevicesGraph: true,
        });

        const graphData = await ServiceWire.getSternumService().getMultipleDevicesGraph(
            ServiceWire.getClientsService().getSelectedClientId(),
            this.props.deviceId,
            this.state.startTime?.getTime(),
            this.state.endTime?.getTime()
        );

        const { multipleDevicesGraph, colorByDeviceProfileName, totalRelatedDevices, uniqueDeviceProfiles } =
            this.calculateMultipleDevicesGraphWithColorPalette(graphData);

        this.setState({
            loadingMultipleDevicesGraph: false,
            totalRelatedDevices,
            uniqueDeviceProfiles,
            multipleDevicesGraph,
            colorByDeviceProfileName,
            isGraphDeviceSelectedById: this.props.deviceId ? { [this.props.deviceId]: true } : {},
        });
    }

    /**
     * Handle device definition version loading data
     */
    private async updateDeviceDefinitionView(deviceDefinitionVersionId: string, timeSelection: TimeSelectOption) {
        const startTime = timeSelection.timeRange.start;
        const endTime = timeSelection.timeRange.end;

        try {
            // Load device definition.
            const deviceDefinitionVersionPromise: Promise<DeviceDefinitionVersionInfo> =
                ServiceWire.getSternumService().getFullDeviceDefinitionVersion(deviceDefinitionVersionId);

            const deviceDefinitionIdToDevicesCountMapPromise: Promise<Record<string, number>> =
                ServiceWire.getSternumService().getDeviceDefinitionsDeviceCounts(
                    ServiceWire.getClientsService().getSelectedClientId()
                );

            // Setting loading device definition to true so we show a placeholder.
            this.setState({
                loadingDeviceDefinition: true,
            });

            const [deviceDefinitionVersion, deviceDefinitionIdToDevicesCountMap] = await Promise.all([
                deviceDefinitionVersionPromise,
                deviceDefinitionIdToDevicesCountMapPromise,
            ]);

            // NOTE: These are all tricks so we wouldn't have to change the way the definition modals work today.
            deviceDefinitionVersion.deviceDefinition.traceDefinitions = deviceDefinitionVersion.traceDefinitions;
            deviceDefinitionVersion.deviceDefinition.argumentDefinitions = deviceDefinitionVersion.argumentDefinitions;
            deviceDefinitionVersion.deviceDefinition.sternumTriggers = deviceDefinitionVersion.sternumTriggers;
            deviceDefinitionVersion.deviceDefinition.deviceDefinitionLibraries =
                deviceDefinitionVersion.deviceDefinitionLibraries;

            // Set loading state to done.
            this.setState({
                loadingDeviceDefinition: false,
                deviceDefinition: deviceDefinitionVersion.deviceDefinition,
                startTime: startTime,
                endTime: endTime,
                activeDevicesCount: deviceDefinitionIdToDevicesCountMap[deviceDefinitionVersion.entityId],
                selectedTimeRangeInMiliSec: endTime.getTime() - startTime.getTime(),
                deviceDefinitionVersion: deviceDefinitionVersion,
                timeSelectOption: timeSelection,
            });
        } catch {
            // Set error state.
            this.setState({
                loadingDeviceDefinition: false,
                loadingMultipleDevicesGraph: false,
                errorLoadingDeviceDefinition: true,
            });
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(newDeviceViewStyle, { withTheme: true })(NewDeviceView)));
