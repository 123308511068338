import { Action } from "redux";
import ActionType from "../ActionType";

/**
 * Defines the data for the view device action.
 */
export interface ViewDeviceAction extends Action<ActionType> {
    type: ActionType.ViewDevice;
    deviceId: string;
}

/**
 * Action creator for viewing a device.
 */
export function viewDeviceAction(deviceId: string): ViewDeviceAction {
    return {
        type: ActionType.ViewDevice,
        deviceId: deviceId,
    };
}
