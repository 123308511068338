import CveInfo from "./CveInfo";

/**
 * Response of the get devices command.
 */
class GetCvesResponse {
    /**
     * Constructor.
     */
    constructor(public cves: CveInfo[], public totalItemCount: number) {}
}

export default GetCvesResponse;
