import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

const eventCountOverTimeGraphStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: {
            position: "relative",
            height: "100%",
        },

        emptyDataContainer: {
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },

        emptyDataInner: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },

        emptyDataText: {
            marginTop: 10,
            color: "#E6EAED",
            fontSize: 18,
            fontWeight: "bold",
        },
    });

export default eventCountOverTimeGraphStyle;
