import { Dialog, DialogActions, DialogTitle, IconButton, Typography, withStyles } from "@material-ui/core";
import { WithStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import Utils from "../../lib/infra/Utils";
import ServiceWire from "../../lib/services/ServiceWire";
import SternumRole from "../../lib/state/SternumRole";
import UserInfo from "../../lib/state/UserInfo";
import SelectComponent from "../DeviceDefinitionComponents/SelectComponent/SelectComponent";
import { CrossIcon } from "../SUI/SternumIcon/SternumIcon";
import SternumImprovedButton from "../SUI/SternumImprovedButton/SternumImprovedButton";
import SternumInputField from "../SUI/SternumInputField/SternumInputField";
import editUserPermissionsDialogStyle from "./EditUserPermissionsDialogStyle";

interface AppState {
    serverLoading: boolean;
    serverError: string;
    role: SternumRole;
    sternumBasicRolesList: SternumRole[];
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof editUserPermissionsDialogStyle> {
    open: boolean;
    user: UserInfo;
    handleCancel: (userRole: string) => void;
    handleUpdate: (userRole: string) => void;
    theme?;
}

class EditUserPermissionsDialog extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */

    constructor(props: AppProps) {
        // Initializing the state to default.
        super(props);
        this.state = {
            serverLoading: false,
            serverError: null,
            sternumBasicRolesList: ServiceWire.getAuthorizationService().getSternumBasicRoles(),
            role: this.getCurrentRole(),
        };
    }

    /**
     * Permission change
     */
    private roleChange = (selectedRole) => {
        const roleName = selectedRole.value;
        const role = this.state.sternumBasicRolesList.filter((role) => role.displayName == roleName)[0];
        this.setState({ role: role });
    };

    /**
     * Disable button
     */
    private disableEditButton = (): boolean => {
        const user_role = this.props.user.isInvited ? this.props.user.role : this.props.user.roles[0];

        return this.state.role.displayName === user_role;
    };

    /**
     * Set current role
     */

    getCurrentRole = () => {
        const userRole = this.getUserRole();
        const role: SternumRole[] = ServiceWire.getAuthorizationService()
            .getSternumBasicRoles()
            .filter((role) => role.displayName === userRole);

        return role && role.length > 0 ? role[0] : ServiceWire.getAuthorizationService().getSternumBasicRoles()[0];
    };

    private updateUserRole = async (event) => {
        this.setState({ serverLoading: true });
        try {
            const clientId = ServiceWire.getClientsService().getSelectedClientId();
            const userId = this.props.user.entityId;

            await ServiceWire.getSternumService().UpdateUserRoles(
                clientId,
                userId,
                this.state.role.displayName,
                this.props.user.isInvited
            );
            this.props.handleUpdate(this.state.role.displayName);
        } catch (err) {
            // Error occurred, check if server returned an error
            if (err && err.response && err.response.data && err.response.data.error) {
                this.setState({ serverError: err.response.data.error });
            } else {
                // Set generic error
                this.setState({ serverError: "Failed to update role" });
            }
        } finally {
            this.setState({ serverLoading: false });
        }
    };

    /**
     * On Cancel button click
     */
    private onCancelClick = () => {
        this.props.handleCancel("");
    };

    /**
     * Get user's role
     */
    private getUserRole = (): string => {
        // Check if it's invited user or existing one
        if (this.props.user.isInvited) {
            return this.props.user.role.displayName;
        }
        return this.props.user.roles[0];
    };

    /**
     * Render dialog.
     */
    render() {
        const { classes } = this.props;
        return (
            <Dialog
                disableEnforceFocus
                maxWidth={"sm"}
                aria-labelledby="simple-dialog-title"
                open={this.props.open}
                onClose={() => {}}
            >
                {/* Title */}
                <DialogTitle id="simple-dialog-title">
                    <div className={classNames(classes.flexSpaceBetween, classes.flexVMiddle)}>
                        <Typography variant={"h6"} className={classNames(classes.extraBold)}>
                            Update User Role
                        </Typography>
                        <IconButton aria-label="close" onClick={this.onCancelClick}>
                            <CrossIcon color="#909090" />
                        </IconButton>
                    </div>
                </DialogTitle>

                {/* Body */}
                <div className={classNames(classes.flexColumn, classes.padding, classes.container)}>
                    {/* Email */}
                    <div>
                        <SternumInputField
                            label={"Email"}
                            inputValue={this.props.user.email}
                            onFieldChange={() => {}}
                            addMargin={true}
                            disabled={true}
                        />
                    </div>

                    {/* Permission */}
                    <div>
                        <Typography variant="caption" className={classNames(classes.labelColor)}>
                            Role
                        </Typography>

                        <SelectComponent
                            name={"selectUserRole"}
                            onFieldChange={(newValue, action) => this.roleChange(newValue)}
                            placeHolder={"Select field..."}
                            clearable={false}
                            searchable={false}
                            isMenuFixed={true}
                            className={classNames(classes.selectComponent)}
                            selectOptions={this.state.sternumBasicRolesList.map((role) => {
                                return {
                                    label: Utils.capitalize(role.displayName.toLowerCase()),
                                    value: role.displayName,
                                };
                            })}
                            selectedValue={
                                this.state.role
                                    ? {
                                          label: Utils.capitalize(this.state.role.displayName.toLocaleLowerCase()),
                                          value: this.state.role,
                                      }
                                    : {}
                            }
                            isDisabled={false}
                            type={"outlined"}
                        />
                    </div>

                    {/** Show error */}
                    {this.state.serverError && (
                        <Typography
                            variant={"subtitle2"}
                            className={classNames(classes.marginBottomMedium)}
                            color="error"
                        >
                            {this.state.serverError}
                        </Typography>
                    )}
                </div>

                <DialogActions className={classNames(classes.paddingBottomXl, classes.flexCenter)}>
                    <div>
                        <SternumImprovedButton
                            onClick={this.onCancelClick}
                            isDisabled={false}
                            isLoading={false}
                            content={"Cancel"}
                            buttonType={"white"}
                        />
                    </div>
                    <div>
                        <SternumImprovedButton
                            onClick={this.updateUserRole}
                            isDisabled={this.disableEditButton() || this.state.serverLoading}
                            isLoading={this.state.serverLoading}
                            content={"Update"}
                        />
                    </div>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(editUserPermissionsDialogStyle, { withTheme: true })(EditUserPermissionsDialog);
