import { Action } from "redux";
import ActionType from "../ActionType";
import DeviceInfo from "../../state/DeviceInfo";

/**
 * Occurs once a device has been successfully fetched from API.
 */
export interface FetchedDeviceSuccessfullyAction extends Action<ActionType> {
    type: ActionType.FetchedDeviceSuccessfully;
    device: DeviceInfo;
}

/**
 * Occurs once a device has been successfully fetched from API.
 */
export function fetchedDeviceSuccessfullyAction(device: DeviceInfo): FetchedDeviceSuccessfullyAction {
    return {
        type: ActionType.FetchedDeviceSuccessfully,
        device: device,
    };
}
