import React, { useState } from "react";
import { connect } from "react-redux";

import { GlobalState } from "../../../lib/state/GlobalState";
import { useHomePageStyle } from "../Home.style";
import { Slider, SliderTypes, useAutoSwitchSlider } from "../Slider";
import { customersOpinions } from "./CustomersOpinionsView.model";
import { useCustomersOpinionsViewStyle } from "./CustomersOpinionsView.style";

export interface CustomersOpinionsViewProps {}

const mapStateToProps = (state: GlobalState, ownProps: CustomersOpinionsViewProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

type CustomerOpinionsViewPropsWithHOC = CustomersOpinionsViewProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

function CustomersOpinionsViewComponent({}: CustomerOpinionsViewPropsWithHOC) {
    const classes = useCustomersOpinionsViewStyle();
    const classesHome = useHomePageStyle();
    const [sliderVariant, setSliderVariant] = useState<SliderTypes.SliderVariant>("default");
    const [isMouseOverSlider, setIsMouseOverSlider] = useState(false);

    const [selectedSlide, setSelectedSlide] = useAutoSwitchSlider({
        numberOfElements: customersOpinions.length,
        isMouseOverSlider: isMouseOverSlider,
    });

    return (
        <div className={classes.customerOpinionsContainer}>
            <div className={classesHome.mainContainer}>
                <div className={classes.title}>What Our Customers Say</div>
                <Slider
                    variant={sliderVariant}
                    selectedItemIndex={selectedSlide}
                    displayPagination={customersOpinions.length > 1}
                    onChangeSelected={setSelectedSlide}
                    onSelectSliderPoint={setSelectedSlide}
                    calculateVariantFromRef={(ref) => {
                        if (ref.current.clientWidth > 500) {
                            setSliderVariant("default");
                        } else {
                            setSliderVariant("maxContent");
                        }
                    }}
                    items={customersOpinions.map((customerOpinion) => (
                        <Slider.Item key={customerOpinion.id}>
                            <div className={classes.customerItem}>
                                <img className={classes.image} src={customerOpinion.image} />
                                <div className={classes.descriptions}>{customerOpinion.description}</div>
                                <div className={classes.nameAndSurname}>{customerOpinion.nameAndSurname}</div>
                                <div className={classes.workplace}>{customerOpinion.workplace}</div>
                            </div>
                        </Slider.Item>
                    ))}
                    arrowLeft={<Slider.ArrowLeft className={sliderVariant === "maxContent" && classes.sliderArrow} />}
                    arrowRight={<Slider.ArrowRight className={sliderVariant === "maxContent" && classes.sliderArrow} />}
                    onMouseEnter={() => setIsMouseOverSlider(true)}
                    onMouseLeave={() => setIsMouseOverSlider(false)}
                />
            </div>
        </div>
    );
}

export const CustomersOpinionsView: React.FC<CustomersOpinionsViewProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomersOpinionsViewComponent);
