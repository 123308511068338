import { Action } from "redux";
import ActionType from "../ActionType";
import LibraryInfo from "../../state/LibraryInfo";

/**
 * Occurs once a library has been successfully fetched from API.
 */
export interface FetchedLibrarySuccessfullyAction extends Action<ActionType> {
    type: ActionType.FetchedLibrarySuccessfully;
    library: LibraryInfo;
}

/**
 * Occurs once a library has been successfully fetched from API.
 */
export function fetchedLibrarySuccessfullyAction(library: LibraryInfo): FetchedLibrarySuccessfullyAction {
    return {
        type: ActionType.FetchedLibrarySuccessfully,
        library: library,
    };
}
