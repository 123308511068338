import { Theme } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../../CommonStyle";

const enterCodeStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            flexDirection: "column",
        },

        container: {
            width: "415px",
        },

        subheaderContent: {
            marginLeft: theme.spacing(2),
        },

        userAndPassInputs: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        },

        wrongAuthenticationIndication: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "0.95rem",
        },

        resendEmailSize: {
            fontSize: "0.8125rem",
        },

        resendEmailLink: {
            color: "#0d47a1",
            "&:hover": {
                cursor: "pointer",
            },
        },
        blueColor: {
            color: "#1B6FDE",
        },

        textSize: {
            fontSize: "0.875rem",
        },

        underLine: {
            textDecoration: "underline",
        },
    });

export default enterCodeStyle;
