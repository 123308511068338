export enum LinuxTab {
    Overview = "overview",
    Alerts = "alerts",
    Anomalies = "anomalies",
    Crashes = "crashes",
    Traces = "traces",
    Performance = "performance",
    HardwareEvents = "hardwareEvents",
    RemoteView = "remoteView",
}
