import React from "react";
import classNames from "classnames";

import { useChipStyle } from "./Chip.style";
import { ChipType } from "./Chip.types";

export interface ChipProps {
    className?: string;
    children?: React.ReactNode;
    type?: ChipType;
}

export function Chip({ className, type = ChipType.Yellow, children, ...props }: ChipProps) {
    const classes = useChipStyle();

    const classNameByType: { [Type in ChipType]: string } = {
        [ChipType.Yellow]: classes.chipTypeYellow,
    };

    return (
        <div className={classNames(classes.chip, classNameByType[type], className)} {...props}>
            {children}
        </div>
    );
}
