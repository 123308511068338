import { makeStyles, Theme } from "@material-ui/core";
import commonStyle from "../../CommonStyle";

const useVisualisationConfigurationGroupByPanelStyle = makeStyles((theme: Theme) => {
    return {
        ...commonStyle(theme),

        container: {
            display: "flex",
            alignItems: "center",
            padding: theme.spacing(3, 4),
            background: "#F4F9FF",
            borderRadius: "14px",
        },

        select: {
            width: 200,
        },

        buttonsGroup: {
            display: "flex",
            marginLeft: "auto",
        },

        textInput: {
            "& input": {
                padding: 14,
            },
        },

        groupByDescription: {
            fontWeight: 600,
            color: "#1B6FDE",
        },
    };
});

export default useVisualisationConfigurationGroupByPanelStyle;
