import { Input, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import Utils from "../../lib/infra/Utils";
import ServiceWire from "../../lib/services/ServiceWire";
import { GlobalState } from "../../lib/state/GlobalState";
import OutgoingWebhookInfo from "../../lib/state/OutgoingWebhookInfo";
import customSelectStyle from "../CustomSelectStyle";
import outgoingWebhookConfigurationModalStyle from "./OutgoingWebhookConfigurationModalStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    displayName: string;
    uri: string;
    savingOutgoingWebhook: boolean;
    errorSavingOutgoingWebhook: boolean;
    registeredEvents: any[];
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof outgoingWebhookConfigurationModalStyle> {
    fullScreenDisplay: boolean;
    theme?;
    closeModal: () => void;

    existingOutgoingWebhook?: OutgoingWebhookInfo;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {};
};

/**
 * Displays a bar of metrics.
 */
class OutgoingWebhookConfigurationModal extends React.Component<AppProps, AppState> {
    private registeredEventTypes: { [key: string]: {} } = {
        traceInserted: {
            value: "TRACE_INSERTED",
            label: "Any New Event",
        },
        deviceRegistered: {
            value: "DEVICE_REGISTERED",
            label: "Device Registered",
        },
        securityAlertCreated: {
            value: "SECURITY_ALERT_CREATED",
            label: "Security Alert",
        },
        cveDetected: {
            value: "CVE_DETECTED",
            label: "CVE Detected",
        },
    };

    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.

        if (this.props.existingOutgoingWebhook) {
            let registeredEventValueToObjectMap = {};
            for (let key in this.registeredEventTypes) {
                if (this.registeredEventTypes.hasOwnProperty(key)) {
                    let registeredEventTypeObject = this.registeredEventTypes[key];

                    registeredEventValueToObjectMap[registeredEventTypeObject["value"]] = registeredEventTypeObject;
                }
            }

            this.state = {
                displayName: this.props.existingOutgoingWebhook.displayName,
                uri: this.props.existingOutgoingWebhook.uri,
                savingOutgoingWebhook: false,
                errorSavingOutgoingWebhook: false,
                registeredEvents: this.props.existingOutgoingWebhook.registeredEvents.map(
                    (registeredEvent) => registeredEventValueToObjectMap[registeredEvent]
                ),
            };
        } else {
            this.state = {
                displayName: "",
                uri: "",
                savingOutgoingWebhook: false,
                errorSavingOutgoingWebhook: false,
                registeredEvents: [],
            };
        }
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                {/* Title */}
                <Typography variant={"h5"} className={classNames(classes.marginBottomXLarge)}>
                    {this.props.existingOutgoingWebhook ? "Edit" : "Create"} Outgoing Webhook
                </Typography>

                {/* Display Name */}
                <div className={classNames(classes.flexVMiddle, classes.marginBottomMedium)}>
                    {/* Title */}
                    <Typography variant="body2" className={classNames(classes.marginRight, classes.nameColumn)}>
                        Display Name:
                    </Typography>

                    {/* Input */}
                    <Input
                        className={classNames(classes.sternumInput, classes.marginRight)}
                        value={this.state.displayName}
                        onChange={(event) => this.onDisplayNameChanged(event)}
                    />
                </div>

                {/* Uri */}
                <div className={classNames(classes.flexVMiddle, classes.marginBottomMedium)}>
                    {/* Title */}
                    <Typography variant="body2" className={classNames(classes.marginRight, classes.nameColumn)}>
                        Uri:
                    </Typography>

                    {/* Input */}
                    <Input
                        className={classNames(classes.sternumInput, classes.marginRight)}
                        value={this.state.uri}
                        onChange={(event) => this.onUriChanged(event)}
                    />
                </div>

                {/* Events */}
                <div className={classNames(classes.flexVMiddle, classes.marginBottomMedium)}>
                    {/* Title */}
                    <Typography variant="body2" className={classNames(classes.marginRight, classes.nameColumn)}>
                        Events:
                    </Typography>

                    {/* Input */}
                    <Select
                        className={classNames(classes.selectComponent, classes.marginRight, classes.eventBox)}
                        isMulti
                        options={Utils.getMapValues(this.registeredEventTypes)}
                        name={"conditionSelection"}
                        value={this.state.registeredEvents}
                        styles={customSelectStyle(this.props.theme)}
                        isSearchable={true}
                        placeholder={"Select events to register to..."}
                        onChange={(newValue, action) => this.onRegisteredEventSelected(newValue, action)}
                    />
                </div>

                {/* Save */}
                <Button
                    size={"small"}
                    variant={"contained"}
                    color={"primary"}
                    className={classNames(classes.saveButton)}
                    onClick={() => this.saveOrCreateOutgoingWebhook()}
                    disabled={
                        !this.state.displayName ||
                        !this.state.uri ||
                        !this.state.registeredEvents ||
                        !this.state.registeredEvents.length
                    }
                >
                    <span>Save</span>

                    {/* Loading */}
                    {this.state.savingOutgoingWebhook && (
                        <CircularProgress className={classes.marginLeft} size={10} color="inherit" />
                    )}
                </Button>

                {/* Error message */}
                {this.state.errorSavingOutgoingWebhook && (
                    <Typography color={"error"} variant={"caption"} className={classNames(classes.marginTopXs)}>
                        There was an error trying to create outgoing webhook.
                    </Typography>
                )}
            </div>
        );
    }

    /**
     * Occurs on change of display name.
     */
    private onDisplayNameChanged(event) {
        this.setState({
            displayName: event.target.value,
        });
    }

    /**
     * Occurs on change of uri.
     */
    private onUriChanged(event) {
        this.setState({
            uri: event.target.value,
        });
    }

    /**
     * Occurs once a registered event is selected.
     */
    private onRegisteredEventSelected(selectedEvents, actionObject) {
        if (actionObject.action === "select-option" || actionObject.action === "remove-value") {
            this.setState({
                registeredEvents: selectedEvents,
            });
        }
    }

    /**
     * Saves current outgoing webhook or creates a new one.
     */
    private async saveOrCreateOutgoingWebhook() {
        try {
            this.setState({
                savingOutgoingWebhook: true,
                errorSavingOutgoingWebhook: false,
            });

            // Saving.
            if (this.props.existingOutgoingWebhook) {
                await ServiceWire.getSternumService().updateOutgoingWebhook(
                    this.props.existingOutgoingWebhook.entityId,
                    this.state.displayName,
                    this.state.uri,
                    this.state.registeredEvents.map((registeredEvent) => registeredEvent.value)
                );
            } else {
                await ServiceWire.getSternumService().createOutgoingWebhook(
                    ServiceWire.getClientsService().getSelectedClientId(),
                    this.state.displayName,
                    this.state.uri,
                    this.state.registeredEvents.map((registeredEvent) => registeredEvent.value)
                );
            }

            // Closing modal.
            this.props.closeModal();
        } catch (error) {
            this.setState({
                errorSavingOutgoingWebhook: true,
            });
        } finally {
            this.setState({
                savingOutgoingWebhook: false,
            });
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(outgoingWebhookConfigurationModalStyle, { withTheme: true })(OutgoingWebhookConfigurationModal));
