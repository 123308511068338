/**
 * Represents a used library in sternum.
 */
import { compare, validate } from "compare-versions";
import Utils from "../infra/Utils";
import EntityBase from "./EntityBase";
import EntityType from "./EntityType";
import LibraryInfo from "./LibraryInfo";
import TableRowData from "./TableRowData";
import UsedLibraryStatus from "./UsedLibraryStatus";

class UsedLibraryInfo extends EntityBase implements TableRowData {
    /**
     * Indicates whether the used library is out of date or not.
     */
    public outOfDate: boolean = false;

    /**
     * Holds the status of the used library.
     */
    public status: UsedLibraryStatus = UsedLibraryStatus.UP_TO_DATE;

    /**
     * Constructor.
     */
    constructor(
        public usedLibraryId: string,
        public libraryId: string,
        public version: string,
        public created: number,
        public updated: number,
        public library: LibraryInfo,
        public cvesCount?: number
    ) {
        super(usedLibraryId, created, updated, EntityType.UsedLibrary);

        this.outOfDate =
            this.library &&
            (validate(this.library.latestKnownVersion) && validate(this.version)
                ? compare(this.library.latestKnownVersion, this.version, ">")
                : this.library.latestKnownVersion > this.version);
        const isLatestVersion = !!library.latestKnownVersion;

        this.status = UsedLibraryInfo.getEvaluatedUsedLibraryStatus(this.cvesCount, this.outOfDate, isLatestVersion);
    }

    /**
     * Converts json to info object.
     */
    public static fromJsonObject(jsonObject: Object) {
        return new UsedLibraryInfo(
            jsonObject["entity_id"],
            jsonObject["library_id"],
            jsonObject["version"],
            jsonObject["created"],
            jsonObject["updated"],
            LibraryInfo.fromJsonObject(jsonObject["library"]),
            jsonObject["cves_count"]
        );
    }

    /**
     * Gets the value for the given column id.
     */
    public getColumnValue(columnHeaderId: string): any {
        return this[columnHeaderId];
    }

    /**
     * Returns whether given row is any different in details than current row.
     * Shallow comparison, in other words.
     * @param other The row to compare with.
     */
    public isDifferentFrom(other: TableRowData): boolean {
        // Row must be of type library info.
        if (!(other instanceof UsedLibraryInfo)) {
            return true;
        }

        let otherLibraryInfo = other as UsedLibraryInfo;

        // Comparing base library info properties.
        if (
            otherLibraryInfo.usedLibraryId !== this.usedLibraryId ||
            otherLibraryInfo.libraryId !== this.libraryId ||
            otherLibraryInfo.version !== this.version ||
            otherLibraryInfo.created !== this.created ||
            otherLibraryInfo.updated !== this.updated
        ) {
            return true;
        }

        return false;
    }

    /**
     * Returns whether status is an alerting status.
     */
    public isAlertingStatus(): boolean {
        return this.status === UsedLibraryStatus.CRITICAL_CVE || this.status === UsedLibraryStatus.MULTIPLE_ISSUES;
    }

    /**
     * Gets the evaluated used library status.
     */
    public static getEvaluatedUsedLibraryStatus(
        cvesCount: number,
        outOfDate: boolean,
        isLatestVersion: boolean
    ): UsedLibraryStatus {
        let now = Utils.now();

        let statusIndicators = (cvesCount && cvesCount > 0 ? 1 : 0) + (outOfDate ? 1 : 0);

        if (statusIndicators > 1) {
            return UsedLibraryStatus.MULTIPLE_ISSUES;
        } else if (statusIndicators === 1) {
            if (cvesCount && cvesCount > 0) {
                return UsedLibraryStatus.CRITICAL_CVE;
            } else {
                return UsedLibraryStatus.OUT_OF_DATE_LIBRARIES;
            }
        } else {
            if (isLatestVersion && !outOfDate) {
                return UsedLibraryStatus.UP_TO_DATE;
            }
            return UsedLibraryStatus.NO_INFORMATION;
        }
    }

    /**
     * Gets the display name of the used library status.
     */
    public getStatusDisplay(): string {
        return UsedLibraryInfo.getStatusDisplay(this.status);
    }

    /**
     * Gets the display name of given used library status.
     */
    public static getStatusDisplay(usedLibraryStatus: UsedLibraryStatus): string {
        switch (usedLibraryStatus) {
            case UsedLibraryStatus.UP_TO_DATE:
                return "Up to Date";

            case UsedLibraryStatus.CRITICAL_CVE:
                return "Critical CVE";

            case UsedLibraryStatus.OUT_OF_DATE_LIBRARIES:
                return "Out of Date";

            case UsedLibraryStatus.MULTIPLE_ISSUES:
                return "Multiple Issues";

            default:
                return "No Information";
        }
    }
}

export default UsedLibraryInfo;
