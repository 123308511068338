import { Action } from "redux";

import ActionType from "../ActionType";

export interface SetCategoryTimeRangeCustomAction extends Action<ActionType> {
    type: ActionType.DashboardRegularSetCategoryTimeRangeCustom;
    categoryTimeRangeCustomFrom?: Date;
    categoryTimeRangeCustomTo?: Date;
}

export function setCategoryTimeRangeCustomAction({
    from,
    to,
}: {
    from?: Date;
    to?: Date;
}): SetCategoryTimeRangeCustomAction {
    return {
        type: ActionType.DashboardRegularSetCategoryTimeRangeCustom,
        categoryTimeRangeCustomFrom: from,
        categoryTimeRangeCustomTo: to,
    };
}
