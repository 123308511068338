import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const usePaginationStyle = makeStyles((theme: Theme) => ({
    paginationContainer: {
        display: "flex",
        position: "relative",
    },

    point: {
        width: 12,
        height: 12,
        borderRadius: "100%",
        background: "white",
        margin: "0px 4px",
    },

    selected: {
        background: "#E7004C",
    },

    selectedPointerContainer: {
        position: "absolute",
        left: 0,
        transition: "transform 0.5s ease-out",
    },
}));
