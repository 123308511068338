import { isEqual } from "lodash";
import UIDataVisualisationConfiguration from "../entities/UIDataVisualisationConfiguration";

export function isChangedDataSourceConfiguration(
    newConfiguration: UIDataVisualisationConfiguration,
    currentConfiguration: UIDataVisualisationConfiguration
): boolean {
    return (
        newConfiguration.aggregatedTraceDefinitionId !== currentConfiguration.aggregatedTraceDefinitionId ||
        newConfiguration.aggregatedArgumentDefinitionId !== currentConfiguration.aggregatedArgumentDefinitionId ||
        newConfiguration.aggregationFunctionType !== currentConfiguration.aggregationFunctionType ||
        newConfiguration.percentile !== currentConfiguration.percentile ||
        newConfiguration.color !== currentConfiguration.color ||
        newConfiguration.appliedSternumQueryChangeIdentifier !==
            currentConfiguration.appliedSternumQueryChangeIdentifier ||
        newConfiguration.appliedSternumQuery.isDifferentFrom(currentConfiguration.appliedSternumQuery) ||
        newConfiguration.sternumQuery.isDifferentFrom(currentConfiguration.sternumQuery) ||
        !isEqual(newConfiguration.deviceDefinitionVersionIds, currentConfiguration.deviceDefinitionVersionIds) ||
        !isEqual(newConfiguration.uniqueColumns, currentConfiguration.uniqueColumns)
    );
}
