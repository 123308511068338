import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { sternumTheme } from "../App";
import commonStyle from "../CommonStyle";

const usedLibraryInfoDisplayStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: {
            display: "flex",
            alignItems: "flex-start",
        },
        detailContainer: {
            display: "flex",
            alignItems: "center",
            marginBottom: theme.spacing(2),
        },
        detailName: {
            marginRight: theme.spacing(1),
        },
        infoColumn: {
            marginRight: theme.spacing(8),
        },
        danger: {
            color: sternumTheme.danger.main,
        },
    });

export default usedLibraryInfoDisplayStyle;
