import { makeStyles } from "@material-ui/core";

export const useCrashesTableStyles = makeStyles((theme) => ({
    crashesTableContainer: {
        display: "grid",
        gridTemplateRows: "max-content 1fr",
        margin: "0 18px",
        maxHeight: "100%",
        overflow: "hidden",
        paddingTop: 5,

        "& table tbody tr": {
            "& td:nth-child(1)": {
                padding: "12px 4px 12px 0",
            },
        },

        "& table thead tr": {
            "& th": {
                fontSize: 14,
                fontWeight: 600,
                color: "#555B61",
            },

            "& th:nth-child(1)": {
                padding: "12px 4px 12px 0",
            },
        },
    },

    tableWrapper: {
        maxHeight: "100%",
        padding: "0 5px",

        "& .MuiBox-root": {
            maxHeight: 500,
            height: "unset",
            overflow: "auto",
        },
    },

    rowCell: {
        fontSize: 16,
        fontWeight: 400,
        color: "#999999",
        maxWidth: 300,
        whiteSpace: "nowrap",
    },

    rowCellArguments: {
        display: "flex",
        gap: 8,
    },

    expandedContent: {
        display: "flex",
        flexFlow: "column",
        alignItems: "flex-start",
        gap: 8,
        paddingLeft: 50,
        paddingTop: 8,
        overflow: "auto",
        height: "100%",
    },
}));
