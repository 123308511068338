import AnalyticsService from "../AnalyticsService";
import buildSternumApiUrl from "../buildSternumApiUrl";
import HttpService from "../HttpService";
import ReceivedDefinitionsResponse from "./ReceivedDefinitionsResponse";
import ReceivedDefinitionType from "./ReceivedDefinitionType";

class EventsApiService {
    /**
     * Constructor.
     */
    constructor(private httpService: HttpService) {}

    /**
     * Gets definitions received in given time frame for given entity.
     * It will fetch only traces that were used by devices meaning we have data for these traces
     */
    @AnalyticsService.reportOnError("API:getReceivedDefinitions")
    public async getReceivedDefinitions(
        entityId: string,
        createdFrom: number,
        createdTo: number,
        onlyDefinitionsOfType?: ReceivedDefinitionType[],
        traceDefinitionId?: string
    ): Promise<ReceivedDefinitionsResponse> {
        let endpoint = `/${entityId}/received_event_definitions`;

        const data = {
            created_from: createdFrom,
            created_to: createdTo,
            trace_definition_id: traceDefinitionId || undefined,
            only_definitions_of_type: onlyDefinitionsOfType || [
                ReceivedDefinitionType.EVENT_DEFINITIONS,
                ReceivedDefinitionType.ARGUMENT_DEFINITIONS,
            ],
        };

        const responseJson = await this.httpService.post(buildSternumApiUrl(endpoint), data);

        return Promise.resolve(ReceivedDefinitionsResponse.fromJson(responseJson));
    }

    /**
     * Gets definitions received in given time frame for given entity.
     */
    @AnalyticsService.reportOnError("API:getAllReceivedDefinitions")
    public async getAllReceivedDefinitions(
        entityId: string,
        createdFrom: number,
        createdTo: number,
        onlyDefinitionsOfType?: ReceivedDefinitionType[],
        traceDefinitionId?: string
    ): Promise<ReceivedDefinitionsResponse> {
        let endpoint = `/${entityId}/received_all_event_definitions`;

        const data = {
            created_from: createdFrom,
            created_to: createdTo,
            trace_definition_id: traceDefinitionId || undefined,
            only_definitions_of_type: onlyDefinitionsOfType || [
                ReceivedDefinitionType.EVENT_DEFINITIONS,
                ReceivedDefinitionType.ARGUMENT_DEFINITIONS,
            ],
        };

        const responseJson = await this.httpService.post(buildSternumApiUrl(endpoint), data);

        return Promise.resolve(ReceivedDefinitionsResponse.fromJson(responseJson));
    }
}

export default EventsApiService;
