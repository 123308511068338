import React from "react";
import classNames from "classnames";

import { useSliderItemStyle } from "./SliderItem.style";

export interface SliderItemProps extends React.ComponentProps<"div"> {}

export const SliderItem: React.FC<SliderItemProps> = function SliderItem({ className, children, ...props }) {
    const classes = useSliderItemStyle();

    return (
        <div className={classNames(className, classes.sliderItem)} {...props}>
            {children}
        </div>
    );
};
