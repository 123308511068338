import { Action } from "redux";

import ActionType from "../ActionType";
import { AlertsCountFilter } from "../../state/DashboardRegularState";

export interface SetAlertsCountFilterAction extends Action<ActionType> {
    type: ActionType.DashboardRegularSetAlertsCountFilter;
    filter: AlertsCountFilter | null;
}

export function setAlertsCountFilterAction(filter: AlertsCountFilter | null): SetAlertsCountFilterAction {
    return {
        type: ActionType.DashboardRegularSetAlertsCountFilter,
        filter,
    };
}
