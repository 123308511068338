import React from "react";
import classNames from "classnames";

import { LegendItem } from "./LegendItem.component";
import { LegendSeparator } from "./LegendSeparator.component";

import { useLegendDetailsStyles } from "./LegendDetails.styles";

export function LegendDetails({ className, children, ...props }: React.ComponentProps<"div">) {
    const classes = useLegendDetailsStyles();

    return (
        <div className={classNames(className, classes.container)} {...props}>
            {children}
        </div>
    );
}

LegendDetails.Item = LegendItem;
LegendDetails.Separator = LegendSeparator;
