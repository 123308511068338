import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

const traceInfoDisplayStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: { height: "100%", display: "flex", flexDirection: "column" },
        traceInfoContainer: {
            display: "flex",
            alignItems: "flex-start",
        },
        detailContainer: {
            display: "flex",
            alignItems: "center",
        },
        detailName: {
            marginRight: theme.spacing(1),
        },
        infoColumn: {
            marginRight: theme.spacing(8),
        },
        timelineContainer: {
            flex: 1,
            display: "flex",
            flexDirection: "column",
            marginTop: theme.spacing(42),
        },
        timelineContainerWithoutDescription: {
            "&&": {
                marginTop: theme.spacing(20),
            },
        },
        displayLineBreak: {
            whiteSpace: "pre-line",
        },
        expandToggle: {
            fontWeight: "bold",
            cursor: "pointer",
            color: "black",
        },

        tooltipLogContainer: {
            position: "relative",
            display: "flex",
            flexFlow: "column",
            gap: 12,
            padding: "26px 0 24px 0",
            maxWidth: 365,
            maxHeight: 400,
        },
        tooltipLogCloseIcon: {
            position: "absolute",
            top: 10,
            right: 10,
        },
        tooltipLogTitle: {
            display: "flex",
            alignItems: "center",
            fontSize: "16px",
            fontWeight: 600,
            gap: 8,
            padding: "0 24px",
        },
        tooltipLogDescription: {
            flex: "1",
            overflow: "auto",
            padding: "0 24px",
        },
    });

export default traceInfoDisplayStyle;
