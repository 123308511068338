import { Input, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import _ from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { closeModalAction } from "../../../lib/redux/modals/CloseModalAction";
import ServiceWire from "../../../lib/services/ServiceWire";
import CustomDashboardInfo from "../../../lib/state/CustomDashboardInfo";
import DeviceDefinitionVersionInfo from "../../../lib/state/DeviceDefinitionVersionInfo";
import EntityBase from "../../../lib/state/EntityBase";
import EntityType from "../../../lib/state/EntityType";
import { GlobalState } from "../../../lib/state/GlobalState";
import ModalKey from "../../../lib/state/ModalKey";
import ModalType from "../../../lib/state/ModalType";
import ConfirmationDialog from "../../../shared_components/ConfirmationDialog/ConfirmationDialog";
import SternumButton from "../../SUI/SternumButton/SternumButton";
import SternumEntitySelect from "../../SUI/SternumEntitySelect/SternumEntitySelect";
import SternumImprovedButton from "../../SUI/SternumImprovedButton/SternumImprovedButton";
import createDashboardModalStyle from "./EditDashboardModalStyle";

const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeModal: (modalKey) => dispatch(closeModalAction(modalKey)),
    };
};

interface AppState {
    dashboard: CustomDashboardInfo;

    updatingDashboard: boolean;
    errorUpdatingDashboard: boolean;
    confirmDialogOpen: boolean;
}

export interface AppProps extends WithStyles<typeof createDashboardModalStyle> {
    dashboard: CustomDashboardInfo;
    closeModal?: (modalKey: ModalKey) => void;
    onEditOperation?: (customDashboard: CustomDashboardInfo) => void;
    theme;
    history;
    location;
}

class EditDashboardModal extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);

        const { classes } = this.props;

        // Initializing the state to default.
        this.state = {
            dashboard: _.cloneDeep(props.dashboard),
            updatingDashboard: false,
            errorUpdatingDashboard: false,
            confirmDialogOpen: false,
        };
    }

    render() {
        const { classes } = this.props;

        let createDisabled =
            !this.state.dashboard.displayName || this.state.dashboard.deviceDefinitionVersions.length == 0;

        return (
            <>
                <ConfirmationDialog
                    title="Do you want to remove some of the device profiles?"
                    body={"If you proceed, corresponding glances will be removed from this dashboard."}
                    open={this.state.confirmDialogOpen}
                    handleApprove={() => this.updateDashboard()}
                    handleCancel={() => this.setState({ confirmDialogOpen: false })}
                    overrideActionName="Confirm"
                />

                <div className={classNames(classes.root)}>
                    <Typography className={classNames(classes.title)}>Modify View</Typography>

                    <Typography className={classNames(classes.sectionTitle)}>Name</Typography>

                    <Input
                        value={this.state.dashboard.displayName}
                        onChange={(event) => this.updateName(event)}
                        autoFocus={true}
                    />

                    {/*<Typography className={classNames(classes.sectionTitle, "mod-secondary")}>Description</Typography>*/}

                    {/*<Input value={this.state.description} onChange={event => this.updateDescription(event)} />*/}

                    <Typography className={classNames(classes.sectionTitle, "mod-secondary", classes.profileSelection)}>
                        Device Profiles
                    </Typography>

                    <SternumEntitySelect
                        multiple
                        selectedValue={this.state.dashboard.deviceDefinitionVersions}
                        entityType={EntityType.DeviceDefinitionVersion}
                        onEntitySelected={(sternumEntity) =>
                            this.updateDeviceDefinitionVersion(sternumEntity as EntityBase[])
                        }
                    />

                    <div className={classNames(classes.flexGrow)} />

                    <div className={classNames(classes.footer)}>
                        {this.state.errorUpdatingDashboard && (
                            <Typography className={classNames(classes.errorText)}>
                                Error updating dashboard...
                            </Typography>
                        )}

                        {this.state.updatingDashboard && (
                            <div className={classNames(classes.loadingContainer)}>
                                <CircularProgress size={15} color="inherit" />
                            </div>
                        )}

                        <div className={classNames(classes.cancelButtonContainer)}>
                            <SternumImprovedButton
                                buttonType="gray"
                                fullWidth={false}
                                onClick={() => this.cancel()}
                                content="Cancel"
                            />
                        </div>

                        <SternumImprovedButton
                            fullWidth={false}
                            onClick={() => this.handleUpdateDashboardClick()}
                            isDisabled={createDisabled}
                            content="Update"
                        />
                    </div>
                </div>
            </>
        );
    }

    private checkIfDeviceProfilesWillBeRemoved = () => {
        const profilesToRemove = this.props.dashboard.deviceDefinitionVersions.filter(
            (version) =>
                !_.find(this.state.dashboard.deviceDefinitionVersions, {
                    deviceDefinitionVersionId: version.deviceDefinitionVersionId,
                })
        );

        return profilesToRemove.length !== 0;
    };

    private handleUpdateDashboardClick = () => {
        if (this.checkIfDeviceProfilesWillBeRemoved()) {
            this.setState({ confirmDialogOpen: true });
        } else {
            this.updateDashboard();
        }
    };

    private cancel() {
        this.props.closeModal(new ModalKey(ModalType.EditDashboardModal, "EditDashboardModal"));
    }

    private updateName(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        const { dashboard } = this.state;
        dashboard.displayName = event.target.value;

        this.setState({ dashboard });
    }

    private updateDeviceDefinitionVersion(sternumEntity: EntityBase[]) {
        const { dashboard } = this.state;
        dashboard.deviceDefinitionVersions = sternumEntity as DeviceDefinitionVersionInfo[];

        this.setState({ dashboard });
    }

    private async updateDashboard() {
        try {
            this.setState({
                updatingDashboard: true,
                errorUpdatingDashboard: false,
            });

            const customDashboard = await ServiceWire.getCustomDashboardsApiService().updateCustomDashboard(
                this.state.dashboard.customDashboardId,
                this.state.dashboard.displayName,
                this.state.dashboard.deviceDefinitionVersions.map((version) => version.entityId)
            );

            if (this.props.onEditOperation) {
                this.props.onEditOperation(this.state.dashboard);
            }

            this.props.closeModal(new ModalKey(ModalType.EditDashboardModal, "EditDashboardModal"));
        } catch (error) {
            this.setState({
                updatingDashboard: false,
                errorUpdatingDashboard: true,
            });
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(createDashboardModalStyle, { withTheme: true })(EditDashboardModal)));
