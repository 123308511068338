import { Typography } from "@material-ui/core";
import classNames from "classnames";
import React, { useCallback, useMemo, useRef, useState } from "react";
import Select, { OptionTypeBase } from "react-select";
import SternumQueryField from "../../../lib/infra/SternumQueryField";
import Utils from "../../../lib/infra/Utils";
import VisualisationDataSourceGroupBy, {
    VisualisationDataSourceGroupByCustomFields,
    VisualisationGroupByOrder,
} from "../../../lib/state/Visualisation/VisualisationConfigurationGroupBy";
import SelectComponent from "../../DeviceDefinitionComponents/SelectComponent/SelectComponent";
import SternumImprovedButton from "../../SUI/SternumImprovedButton/SternumImprovedButton";
import SternumInputField from "../../SUI/SternumInputField/SternumInputField";
import { addCounters, DropdownCounterProvider } from "../../UniqueCountQuery/DropdownCounterProvider";
import { counterUpdate, formatGroupLabel, formatOptionLabel } from "../../UniqueCountQuery/UniqueCountDropdown";
import getSpecialFieldSelectDisplay from "../../VisualisationConfigurationComponent/utils/getSpecialFieldSelectDisplay";
import useVisualisationConfigurationGroupByPanelStyle from "./VisualisationConfigurationGroupByPanelStyle";

interface VisualisationConfigurationGroupByPanel {
    groupBy: VisualisationDataSourceGroupBy;
    argumentDefinitionFields: SternumQueryField[];
    onGroupByApply: (groupBy: VisualisationDataSourceGroupBy) => void;
    onGroupByCancel?: () => void;
    onGroupByDisable?: () => void;
    defaultEditingEnabled?: boolean;
    isFreeUser?: boolean;
    openGoProModal?: () => unknown;
    disableEditing?: boolean;
}

const orderOptions = [
    { value: VisualisationGroupByOrder.TOP, label: "Top" },
    { value: VisualisationGroupByOrder.BOTTOM, label: "Bottom" },
];

const specialFields = [
    { value: VisualisationDataSourceGroupByCustomFields.DEVICE, label: "Device" },
    { value: VisualisationDataSourceGroupByCustomFields.TRACE_CATEGORY, label: "Category" },
    { value: VisualisationDataSourceGroupByCustomFields.COUNTRY, label: "Country" },
    { value: VisualisationDataSourceGroupByCustomFields.STATE, label: "State" },
];

export default function VisualisationConfigurationGroupByPanel(props: VisualisationConfigurationGroupByPanel) {
    const classes = useVisualisationConfigurationGroupByPanelStyle();
    const isFreeUser: boolean = props.isFreeUser || false;

    const [isEditing, setEditing] = useState(!!props.defaultEditingEnabled);
    const [groupBy, setGroupBy] = useState({
        ...props.groupBy,
        // field: VisualisationDataSourceGroupByCustomFields.DEVICE,
        field: props.groupBy.field || (VisualisationDataSourceGroupByCustomFields.DEVICE as string),
    });

    const selectedOrderValue = useMemo(() => {
        return orderOptions.find((field) => field.value === groupBy.order_by);
    }, [groupBy]);

    const handleFieldChange = useCallback((option: OptionTypeBase) => {
        setGroupBy((prevState) => ({ ...prevState, field: option.value }));
    }, []);

    const handleOrderChange = useCallback((option: OptionTypeBase) => {
        setGroupBy((prevState) => ({ ...prevState, order_by: option.value }));
    }, []);

    const handleLimitChange = useCallback((event) => {
        const value = event.target.value;
        const limit = value === "" ? value : parseInt(value, 10) || 1;
        setGroupBy((prevState) => ({ ...prevState, limit }));
    }, []);

    const handleCancel = useCallback(() => {
        setGroupBy(props.groupBy);
        setEditing(false);
        props.onGroupByCancel?.();
    }, [props.groupBy, props.onGroupByCancel]);

    const handleApply = useCallback(() => {
        setEditing(false);
        props.onGroupByApply(groupBy);
    }, [groupBy, props.onGroupByApply]);

    const handleEdit = useCallback(() => {
        if (isFreeUser) {
            if (props.openGoProModal) {
                props.openGoProModal();
            }
            return;
        }
        setEditing(true);
    }, [isFreeUser, props.openGoProModal]);

    const handleDisable = useCallback(() => {
        if (isFreeUser) {
            if (props.openGoProModal) {
                props.openGoProModal();
            }
            return;
        }
        setEditing(false);
        props.onGroupByDisable?.();
    }, [groupBy, props.onGroupByDisable, isFreeUser, props.openGoProModal]);

    const fieldDropdownOptions = useMemo(() => {
        const specialFieldsStyled = specialFields.map((field) => ({
            ...field,
            label: getSpecialFieldSelectDisplay(classes, field.label),
            isSpecialField: true,
        }));

        return specialFieldsStyled.concat(props.argumentDefinitionFields as any[]);
    }, [props.argumentDefinitionFields]);

    const selectedFieldValue = useMemo(() => {
        const argumentDefinitionFields = props.argumentDefinitionFields.map((field) => ({
            ...field,
            metricType: "argument",
        }));

        return specialFields.concat(argumentDefinitionFields as any[]).find((field) => field.value === groupBy.field);
    }, [props.argumentDefinitionFields, groupBy]);

    const specialOpts = fieldDropdownOptions.filter((opt) => !!opt && opt["isSpecialField"]);
    const notSpecialOpts = fieldDropdownOptions
        .filter((opt) => !!opt && !opt["isSpecialField"])
        .map((opt) => ({ ...opt, metricType: "argument" }));

    const groupedFieldDropdownOptions = fieldDropdownOptions
        ? [
              ...specialOpts,
              {
                  label: (
                      <span>
                          Arguments{" "}
                          <span style={{ color: "#AAAAAA" }} className="ucd-group-counter">
                              ({Utils.numberToHumanString(notSpecialOpts.length)})
                          </span>
                      </span>
                  ),
                  options: notSpecialOpts,
              },
          ]
        : fieldDropdownOptions;

    const selectRef = useRef<Select>(null);

    return (
        <div className={classes.container}>
            {isEditing ? (
                <div className={classes.flex}>
                    <div className={classNames(classes.marginRightLarge)}>
                        <Typography variant="body2" className={classNames(classes.marginBottomXs, classes.marginLeft)}>
                            Group by
                        </Typography>
                        <DropdownCounterProvider>
                            {(metrics) => (
                                <SelectComponent
                                    ref={selectRef}
                                    onInputChange={counterUpdate(selectRef)}
                                    onAfterInitialRender={() => counterUpdate(selectRef)("")}
                                    searchable
                                    name="field"
                                    type="outlined"
                                    placeHolder="Select field"
                                    className={classes.select}
                                    selectedValue={selectedFieldValue || null}
                                    selectOptions={addCounters(groupedFieldDropdownOptions as any[], metrics)}
                                    onFieldChange={handleFieldChange}
                                    formatGroupLabel={formatGroupLabel}
                                    formatOptionLabel={formatOptionLabel}
                                />
                            )}
                        </DropdownCounterProvider>
                    </div>

                    <div className={classNames(classes.marginRightLarge)}>
                        <Typography variant="body2" className={classNames(classes.marginBottomXs, classes.marginLeft)}>
                            Sort from
                        </Typography>
                        <SelectComponent
                            name="order"
                            type="outlined"
                            placeHolder="Select order"
                            className={classes.select}
                            selectedValue={selectedOrderValue}
                            selectOptions={orderOptions}
                            onFieldChange={handleOrderChange}
                        />
                    </div>

                    <div className={classNames(classes.marginRightLarge)}>
                        <Typography variant="body2" className={classNames(classes.marginBottomXs, classes.marginLeft)}>
                            Limit
                        </Typography>
                        <SternumInputField
                            noLabel
                            name="limit"
                            inputValue={groupBy.limit}
                            onFieldChange={handleLimitChange}
                            className={classes.textInput}
                        />
                    </div>
                </div>
            ) : (
                <div>
                    <Typography variant="body2" className={classes.groupByDescription}>
                        Group by {selectedFieldValue?.label || "N/A"} / Limit to {groupBy.limit} / Sort from{" "}
                        {selectedOrderValue?.label}
                    </Typography>
                </div>
            )}

            {!props.disableEditing && (
                <div className={classes.buttonsGroup}>
                    {isEditing ? (
                        <>
                            <SternumImprovedButton content="Cancel" buttonType="text:dark" onClick={handleCancel} />
                            <SternumImprovedButton content="Apply" buttonType="text:blue" onClick={handleApply} />
                        </>
                    ) : (
                        <>
                            <SternumImprovedButton content="Disable" buttonType="text:dark" onClick={handleDisable} />
                            <SternumImprovedButton content="Edit" buttonType="text:blue" onClick={handleEdit} />
                        </>
                    )}
                </div>
            )}
        </div>
    );
}
