import UIDataVisualisationConfiguration from "../entities/UIDataVisualisationConfiguration";
import AggregationFunctionType from "../../../lib/state/Visualisation/AggregationFunctionType";

export function isValidDataSourceConfiguration(
    dataVisualisationConfiguration: UIDataVisualisationConfiguration
): boolean {
    return !!(
        dataVisualisationConfiguration &&
        (dataVisualisationConfiguration.aggregationFunctionType === AggregationFunctionType.UNIQUE_COUNT ||
            (dataVisualisationConfiguration.aggregationFunctionType === AggregationFunctionType.COUNT &&
                dataVisualisationConfiguration.aggregatedTraceDefinitionId) ||
            (dataVisualisationConfiguration.aggregationFunctionType !== AggregationFunctionType.COUNT &&
                dataVisualisationConfiguration.aggregatedTraceDefinitionId &&
                dataVisualisationConfiguration.aggregatedArgumentDefinitionId))
    );
}
