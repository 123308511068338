/**
 * Interceptor that intercepts authentication token to all outoing requests from the application.
 */
import HttpRequestInterceptor from "./HttpRequestInterceptor";

class AuthenticationHttpRequestInterceptor implements HttpRequestInterceptor {
    /**
     * Intercepts and adds an Authorization header to all calls.
     */
    intercept(request) {
        const authenticationToken = localStorage.getItem("authentication_token");
        const selectedClientId = localStorage.getItem("selected_client_id");

        if (authenticationToken != null) {
            request.headers.Authorization = `Bearer ${authenticationToken}`;
            request.headers["x-sternum-client"] = selectedClientId;
        }

        return request;
    }
}

export default AuthenticationHttpRequestInterceptor;
