import { Chip, IconButton, Paper, Typography, withStyles } from "@material-ui/core";
import { WithStyles } from "@material-ui/core/styles";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { GlobalState } from "../../../lib/state/GlobalState";
import TriggerDefinition from "../../../lib/state/triggers/TriggerDefinition";
import WhitelistDefinition from "../../../lib/state/triggers/WhitelistDefinition";
import CustomInput from "../../../shared_components/CustomInput/CustomInput";
import SelectComponent from "../../DeviceDefinitionComponents/SelectComponent/SelectComponent";
import whitelistTriggerStyle from "./WhitelistTriggerStyle";

interface AppState {
    selectedTrace: any;
    selectedArgument: any;
    newWhitelistValue: string;
    whitelist: string[];
}

export interface AppProps extends WithStyles<typeof whitelistTriggerStyle> {
    existingDeviceDefinition: any;
    existingWhitelistDefinition?: WhitelistDefinition;
    updateFieldsStatus: (isAllFieldsSet: boolean, triggerDefinition: TriggerDefinition) => void;
    disabled?: boolean;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {};
};

class WhitelistTrigger extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);
        // Initializing the state to default.
        if (this.props.existingWhitelistDefinition) {
            this.state = {
                selectedTrace: this.props.existingWhitelistDefinition.traceDefinition,
                selectedArgument: this.props.existingWhitelistDefinition.argumentDefinition,
                whitelist: this.props.existingWhitelistDefinition.whitelist,
                newWhitelistValue: "",
            };
        } else {
            this.state = {
                selectedTrace: "",
                selectedArgument: "",
                newWhitelistValue: "",
                whitelist: [],
            };
        }
    }

    /**
     * Send data to parent component
     */
    private sendFieldsStatusToParent = () => {
        if (this.validateFields()) {
            let partialTrace;
            let partialArgument;
            if (!this.state.selectedTrace.entityId) {
                partialTrace = {
                    trace_type: this.state.selectedTrace.traceType,
                    trace_event_name: this.state.selectedTrace.traceEventName,
                };
            }
            if (!this.state.selectedArgument.entityId) {
                partialArgument = {
                    argument_type: this.state.selectedArgument.argumentType,
                    argument_event_name: this.state.selectedArgument.argumentEventName,
                };
            }
            const triggerDefinition = new WhitelistDefinition(
                this.state.selectedTrace,
                this.state.selectedArgument,
                this.state.whitelist,
                partialTrace,
                partialArgument
            );
            this.props.updateFieldsStatus(true, triggerDefinition);
        } else {
            this.props.updateFieldsStatus(false, null);
        }
    };

    /**
     * Check if all trigger fields are set
     */
    private validateFields = (): boolean => {
        return this.state.selectedTrace !== "" && this.state.selectedArgument !== "" && this.state.whitelist.length > 0;
    };

    /*
     * Handle trace change operation
     */
    private onSelectedTraceChanged = (selectedOption: any) => {
        this.setState(
            {
                selectedTrace: selectedOption.value,
            },
            () => this.sendFieldsStatusToParent()
        );
    };

    /*
     * Handle argument change operation
     */
    private onSelectedArgumentChanged = (selectedOption: any) => {
        this.setState(
            {
                selectedArgument: selectedOption.value,
            },
            () => this.sendFieldsStatusToParent()
        );
    };

    private onNewChipChanged = (event) => {
        this.setState({
            newWhitelistValue: event.target.value,
        });
    };

    /**
     * Handle delete chip
     */
    handleDelete = (selectedChip: string) => () => {
        this.setState({
            whitelist: [...this.state.whitelist.filter((item) => item !== selectedChip)],
        });
    };

    /**
     * Handle add new chip
     */
    handleAddNewChip = (event) => {
        const chipValue = this.state.newWhitelistValue;
        if (chipValue !== "") {
            this.setState(
                {
                    whitelist: [...this.state.whitelist, chipValue],
                    newWhitelistValue: "",
                },
                () => this.sendFieldsStatusToParent()
            );
        }
    };

    /*
     * Build trace list for dropdown display
     */
    private buildTraceSelectList = () => {
        let traceSelectOption = [];
        this.props.existingDeviceDefinition.traceDefinitions.map((traceDefinitionIter, index) => {
            if (traceDefinitionIter.displayInUI || traceDefinitionIter.canBeUsedForAlerts) {
                traceSelectOption.push({
                    label: traceDefinitionIter.displayName,
                    value: traceDefinitionIter,
                });
            }
        });
        return traceSelectOption;
    };

    /*
     * Build argument list for dropdown display
     */
    private buildArgumentSelectList = () => {
        const argumentSelectOption = [];
        this.props.existingDeviceDefinition.argumentDefinitions.map((argumentDefinitionIter, index) => {
            if (argumentDefinitionIter.displayInUI || argumentDefinitionIter.canBeUsedForAlerts) {
                argumentSelectOption.push({
                    label: argumentDefinitionIter.displayName,
                    value: argumentDefinitionIter,
                });
            }
        });
        return argumentSelectOption;
    };

    /*
     * Build selected item
     */

    private buildSelectedItem = (selectedItem) => {
        if (selectedItem) {
            return { label: selectedItem.displayName, value: selectedItem };
        }
        return "";
    };

    render() {
        const { classes } = this.props;
        return (
            <div className={classNames(classes.flexColumn)}>
                {/** Title */}
                <Typography variant="subtitle2">Whitelist Violation</Typography>

                {/* Trace definition selection*/}

                <div role="presentation" aria-label="select trace">
                    <SelectComponent
                        name={"traceSelect"}
                        placeHolder={"Select Trace"}
                        onFieldChange={this.onSelectedTraceChanged}
                        selectedValue={this.buildSelectedItem(this.state.selectedTrace)}
                        selectOptions={this.buildTraceSelectList()}
                        isDisabled={this.props.disabled}
                    />
                </div>

                {/* Argument definition selection*/}
                <div role="presentation" aria-label="select argument">
                    <SelectComponent
                        name={"argumentSelect"}
                        placeHolder={"Select Argument"}
                        onFieldChange={this.onSelectedArgumentChanged}
                        selectedValue={this.buildSelectedItem(this.state.selectedArgument)}
                        selectOptions={this.buildArgumentSelectList()}
                        isDisabled={this.props.disabled}
                    />
                </div>

                {/* Add new chip to list */}
                <div className={classNames(classes.flexRow, classes.flexSpaceBetween)}>
                    <CustomInput
                        value={this.state.newWhitelistValue}
                        inputTitle={"Enter Item"}
                        maxHeightClass={""}
                        inputClasses={""}
                        autoFocus={false}
                        onChange={this.onNewChipChanged}
                        required={true}
                        id={"filter name"}
                        customWidth={classes.customFilterInput}
                        disabled={this.props.disabled}
                    />

                    {/* Add Icon */}
                    <IconButton
                        className={classes.button}
                        aria-label="Add"
                        onClick={this.handleAddNewChip}
                        color="primary"
                        disabled={this.props.disabled}
                    >
                        <AddCircleOutlinedIcon />
                    </IconButton>
                </div>
                {/** Chip list */}
                <div className={classes.chipContainer}>
                    {this.state.whitelist.length !== 0 ? (
                        <Paper className={classes.chipPaper}>
                            {this.state.whitelist.map((data) => {
                                let icon = null;
                                return (
                                    <Chip
                                        key={data}
                                        icon={icon}
                                        label={data}
                                        onDelete={this.props.disabled ? undefined : this.handleDelete(data)}
                                        className={classes.chip}
                                    />
                                );
                            })}
                        </Paper>
                    ) : (
                        <Paper className={classes.chipPaper}>
                            <Typography variant="subtitle2">Whitelist is empty</Typography>
                        </Paper>
                    )}
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(whitelistTriggerStyle)(WhitelistTrigger));
