import React from "react";

import { SternumIconProps } from "./SternumIcon.types";

export const SternumOutlineInfoIcon = React.forwardRef<SVGSVGElement, SternumIconProps>(function SternumOutlineInfoIcon(
    { className, color = "#E7004C", height = 22, width = 22, ...props },
    ref
) {
    return (
        <svg
            ref={ref}
            className={className}
            width={width}
            height={height}
            viewBox="0 0 23 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.4999 1.86826C6.45678 1.86826 2.36851 5.95654 2.36851 10.9997C2.36851 16.0428 6.45678 20.1311 11.4999 20.1311C16.5431 20.1311 20.6313 16.0428 20.6313 10.9997C20.6313 5.95654 16.5431 1.86826 11.4999 1.86826ZM0.708252 10.9997C0.708252 5.0396 5.53984 0.208008 11.4999 0.208008C17.46 0.208008 22.2916 5.0396 22.2916 10.9997C22.2916 16.9597 17.46 21.7913 11.4999 21.7913C5.53984 21.7913 0.708252 16.9597 0.708252 10.9997ZM10.6698 6.57232C10.6698 6.11386 11.0415 5.7422 11.4999 5.7422H11.511C11.9695 5.7422 12.3411 6.11386 12.3411 6.57232C12.3411 7.03079 11.9695 7.40245 11.511 7.40245H11.4999C11.0415 7.40245 10.6698 7.03079 10.6698 6.57232ZM9.56295 10.9997C9.56295 10.5412 9.93461 10.1695 10.3931 10.1695H11.4999C11.9584 10.1695 12.33 10.5412 12.33 10.9997V14.5969H12.6068C13.0652 14.5969 13.4369 14.9686 13.4369 15.427C13.4369 15.8855 13.0652 16.2572 12.6068 16.2572H11.4999C11.0415 16.2572 10.6698 15.8855 10.6698 15.427V11.8298H10.3931C9.93461 11.8298 9.56295 11.4581 9.56295 10.9997Z"
                fill={color}
            />
        </svg>
    );
});
