import { call, put, select, takeEvery } from "redux-saga/effects";
import ServiceWire from "../../services/ServiceWire";
import FetchError from "../../state/FetchError";
import SternumGeneratedEventInfo from "../../state/SternumGeneratedEventInfo";
import ActionType from "../ActionType";
import { errorFetchingGeneratedEventAction } from "./ErrorFetchingGeneratedEventAction";
import { fetchedGeneratedEventSuccessfullyAction } from "./FetchedGeneratedEventSuccessfullyAction";
import { FetchGeneratedEventAction } from "./FetchGeneratedEventAction";
import generatedEventSelector from "./GeneratedEventSelector";

/**
 * Fetches a generated event from API.
 */
export function* fetchGeneratedEventAsync(action: FetchGeneratedEventAction) {
    try {
        // Trying to retrieve generated event from cache.

        let generatedEvent = yield select(generatedEventSelector, action.generatedEventId);
        if (!generatedEvent) {
            // Generated event not found in cache, calling API for getting the generated event.
            let generatedEvent: SternumGeneratedEventInfo = yield call(
                [ServiceWire.getSternumService(), ServiceWire.getSternumService().getGeneratedEventById],
                action.generatedEventId
            );
            // Dispatching a successful fetch of generated event.
            yield put(fetchedGeneratedEventSuccessfullyAction(generatedEvent));
        } else {
            // Dispatching a successful fetch of generated event.
            yield put(fetchedGeneratedEventSuccessfullyAction(generatedEvent));
        }
    } catch (error) {
        // Error occurred, dispatching an erroneous fetch of generated event.
        yield put(errorFetchingGeneratedEventAction(action.generatedEventId, new FetchError(error.message)));
    }
}

/**
 * Watching the ActionType.FetchTrace action for fetching trace from API.
 */
export function* watchFetchGeneratedEventAsync() {
    yield takeEvery((action) => action.type === ActionType.FetchGeneratedEvent, fetchGeneratedEventAsync);
}
