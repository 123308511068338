import React, { useCallback } from "react";
import clsx from "clsx";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";

import { PatternWithTraces } from "../../../lib/state/Pattern";
import { DocumentText } from "../../SUI/SternumIcon/DocumentText.icon";
import { AnomalyOnboarding } from "../../AnomalyOnboarding";
import { Dropdown } from "../Dropdown";
import { TitleWithTag } from "../TitleWithTag";

import { useTraceSequenceSharedStyle } from "../TraceSequence.style";
import { useReferenceTraceSequenceStyle } from "./ReferenceTraceSequence.style";

export interface ReferenceTraceSequenceProps {
    patternsAndTraces: PatternWithTraces[];
    selectedPatternIndex?: number;
    onChangeSelectedPatternIndex?: (newSelectedPatternIndex: number) => unknown;
}

export function ReferenceTraceSequence({
    patternsAndTraces = [],
    selectedPatternIndex = 0,
    onChangeSelectedPatternIndex,
}: ReferenceTraceSequenceProps) {
    const patterns = patternsAndTraces;

    const stylesTrace = useTraceSequenceSharedStyle();
    const styles = useReferenceTraceSequenceStyle();

    const renderSequenceNameCallback = useCallback(
        (sequenceId) => {
            return (
                <div className={styles.dropdownValueContainer}>
                    <div className={styles.iconContainer}>
                        <DocumentText color="#ACB4BD" />
                    </div>
                    <div className={styles.dropdownValueItem}>{patterns.length > 0 && patterns[sequenceId].name}</div>
                </div>
            );
        },
        [patterns]
    );

    return (
        <div>
            <div className={stylesTrace.beforeTableHeader}>
                <TitleWithTag tagValue={patterns.length > 0 ? patterns[selectedPatternIndex].items.length : "-"}>
                    Reference Trace Sequence
                </TitleWithTag>
                <div data-onboarding="choose-reference">
                    <Dropdown
                        selectedCase={selectedPatternIndex}
                        renderSelectedCase={renderSequenceNameCallback}
                        onChangeSelectedCase={(index) => onChangeSelectedPatternIndex(index)}
                    >
                        {patterns.map((pattern, index) => (
                            <Dropdown.Item key={index} id={index}>
                                {pattern.name}
                            </Dropdown.Item>
                        ))}
                    </Dropdown>
                </div>
            </div>
            <TableContainer>
                <Table>
                    <TableHead className={styles.tableHead}>
                        <TableRow>
                            <TableCell className={styles.tableHeadCell} width="auto">
                                #
                            </TableCell>
                            <TableCell className={styles.tableHeadCell} width="100%">
                                Event Type
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {patterns.length > 0 &&
                            patterns[selectedPatternIndex].items.map((item) => (
                                <TableRow key={item.order}>
                                    <TableCell className={clsx(styles.tableBodyCell, styles.orderCell)}>
                                        {item.order}
                                    </TableCell>
                                    <TableCell className={clsx(styles.tableBodyCell, styles.displayNameCell)}>
                                        {item.name}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
