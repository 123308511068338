import { call, put, takeEvery } from "redux-saga/effects";
import ServiceWire from "../../services/ServiceWire";
import { IssueInfoStatus } from "../../state/IssueInfoType";
import IssuesFilter from "../../state/IssuesFilter";
import ActionType from "../ActionType";
import { errorFetchingPendingIssuesCountAction } from "./ErrorFetchingPendingIssuesCountAction";
import { fetchedPendingIssuesCountSuccessfullyAction } from "./FetchedPendingIssuesCountSuccessfullyAction";
import { FetchPendingIssuesCountAction } from "./FetchPendingIssuesCountAction";

export function* fetchPendingIssuesCountAsync(action: FetchPendingIssuesCountAction) {
    try {
        let pendingIssuesCount = yield call(
            [ServiceWire.getSternumService(), ServiceWire.getSternumService().getIssuesCount],
            ServiceWire.getClientsService().getSelectedClientId(),
            null,
            null,
            action.issuesFilter
                ? action.issuesFilter
                : new IssuesFilter(null, null, true, true, [IssueInfoStatus.Open], undefined, undefined, true),
            null,
            "PRODUCTION"
        );

        // Dispatching a successful resolve of issue.
        yield put(fetchedPendingIssuesCountSuccessfullyAction(pendingIssuesCount));
    } catch (error) {
        // Error occurred, dispatching an erroneous resolve of issue.
        yield put(errorFetchingPendingIssuesCountAction());
    }
}

export function* watchFetchPendingIssuesCountAsync() {
    yield takeEvery((action) => action.type === ActionType.FetchPendingIssuesCount, fetchPendingIssuesCountAsync);
}
