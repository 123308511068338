import classNames from "classnames";
import * as React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import ServiceWire from "../../lib/services/ServiceWire";
import useGoogleRecaptchaStyles from "./GoogleRecaptchaStyles";

interface AppProps {
    elementRef;
    onRecaptchaSuccess;
    onRecaptchaError;
}

const GoogleRecaptchaWrapper = ({ elementRef, onRecaptchaSuccess, onRecaptchaError }: AppProps) => {
    const classes = useGoogleRecaptchaStyles();

    const clientKey = ServiceWire.getAuthenticationService().getCaptchaKey();
    if (!clientKey) return null;

    return (
        <div className={classNames(classes.flex, classes.fullWidth)}>
            <ReCAPTCHA
                ref={elementRef}
                sitekey={clientKey}
                onChange={onRecaptchaSuccess}
                onExpired={onRecaptchaError}
                onErrored={onRecaptchaError}
                className={classes.scale}
            />
        </div>
    );
};

export default GoogleRecaptchaWrapper;
