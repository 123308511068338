import React from "react";
import classNames from "classnames";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";

import ServiceWire from "../../lib/services/ServiceWire";
import SternumImprovedButton from "../SUI/SternumImprovedButton/SternumImprovedButton";
import { CrossIcon } from "../SUI/SternumIcon";
import SternumInputField from "../SUI/SternumInputField/SternumInputField";
import Utils from "../../lib/infra/Utils";
import SternumLink from "../SUI/SternumLink/SternumLink";
import { GlobalState } from "../../lib/state/GlobalState";
import { NotificationMessage, NotificationVariant } from "../../lib/state/NotificationsState";
import { showNotificationAction } from "../../lib/redux/notifications/ShowNotificationAction";
import SelectComponent from "../DeviceDefinitionComponents/SelectComponent/SelectComponent";

import { useShareExperienceDialogStyle } from "./ShareExperienceDialog.style";
import SternumRole from "../../lib/state/SternumRole";
import InviteUserInfo from "../../lib/state/InviteUserInfo";

export interface ShareExperienceDialogProps {
    open: boolean;
    onCancelClick?: () => unknown;
}

export function ShareExperienceDialog(props: ShareExperienceDialogProps) {
    if (!props.open) {
        return null;
    }

    return <ShareExperienceDialogComponent {...props} />;
}

interface FormValues {
    email: string;
    jobTitle: string;
    role: SternumRole | null;
}

const mapStateToProps = (state: GlobalState, ownProps: ShareExperienceDialogProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        showNotification: (message: NotificationMessage, variant?: NotificationVariant) => {
            dispatch(showNotificationAction(message, variant));
        },
    };
};

type FeedbackDialogPropsWithHOC = ShareExperienceDialogProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const ShareExperienceDialogComponent: React.FC<ShareExperienceDialogProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(ShareExperienceDialogBaseComponent);

function ShareExperienceDialogBaseComponent({ open, onCancelClick, showNotification }: FeedbackDialogPropsWithHOC) {
    const fieldIsRequired = "The field is required";

    const classes = useShareExperienceDialogStyle();
    const sternumBasicRolesList: SternumRole[] = ServiceWire.getAuthorizationService().getSternumBasicRoles() || [];

    const formik = useFormik<FormValues>({
        initialValues: {
            email: "",
            jobTitle: "",
            role: null,
        },
        initialErrors: {
            email: fieldIsRequired,
        },
        validate: (values) => {
            const errors: Partial<Record<keyof FormValues, string>> = {};

            const checkFieldIsRequired = (field: keyof FormValues) => {
                const fieldValue = values[field];

                if (typeof fieldValue === "string") {
                    if (fieldValue.trim().length < 1) {
                        errors[field] = fieldIsRequired;
                        return true;
                    }
                } else {
                    if (fieldValue === null) {
                        errors[field] = fieldIsRequired;
                        return true;
                    }
                }
            };

            if (!checkFieldIsRequired("email") && !Utils.isValidEmail(values.email)) {
                errors.email = "Email is invalid";
            }

            checkFieldIsRequired("role");

            return errors;
        },
        onSubmit: async (values) => {
            try {
                const clientId = ServiceWire.getClientsService().getSelectedClientId();
                const email = values.email;
                const inviteObject: InviteUserInfo = new InviteUserInfo(values.email, values.role, values.jobTitle);

                await ServiceWire.getSternumService().inviteNewUser(clientId, inviteObject);
                await ServiceWire.getClientsService().loadClients();

                showNotification(`${email} has been invited`, NotificationVariant.Success);
                onCancelClick?.();
            } catch (err) {
                // TODO: Add endpoint error implementation
                console.error("There is error while inviting new user");
                onCancelClick?.();
            }
        },
    });

    const handleRoleChange = (selectedRole) => {
        const roleName: string = selectedRole.value;
        const role = sternumBasicRolesList.filter((role) => role.displayName === roleName)[0];

        formik.setFieldValue("role", role);
    };

    const isSendButtonDisabled = () => {
        return formik.isSubmitting || Object.keys(formik.errors).length > 0;
    };

    return (
        <Dialog
            aria-labelledby="dialog-title"
            open={open}
            onClose={onCancelClick}
            disableEnforceFocus
            classes={{
                root: classes.root,
                paper: classes.paper,
            }}
        >
            <div className={classes.closeButton} onClick={onCancelClick}>
                <CrossIcon color="#909090" />
            </div>

            {/* Title */}
            <DialogTitle className={classes.dialogTitle} id="dialog-title">
                <Typography variant={"h6"} className={classNames(classes.title)}>
                    Share the Experience
                </Typography>
                <div className={classes.subTitle}>Invite others to use Sternum on their devices as well</div>
            </DialogTitle>

            {/* Body */}
            <DialogContent className={classes.dialogContent}>
                <SternumInputField
                    label="Email"
                    required
                    onFieldChange={(e) => formik.setFieldValue("email", e.target.value)}
                    onTouch={() => formik.setFieldTouched("email", true)}
                    inputValue={formik.values.email}
                    error={formik.touched.email && !!formik.errors.email}
                    helperText={formik.touched.email && formik.errors.email}
                />
                <SternumInputField
                    label="Job title"
                    required={false}
                    onFieldChange={(e) => formik.setFieldValue("jobTitle", e.target.value)}
                    onTouch={() => formik.setFieldTouched("jobTitle", true)}
                    inputValue={formik.values.jobTitle}
                    error={formik.touched.jobTitle && !!formik.errors.jobTitle}
                    helperText={formik.touched.jobTitle && formik.errors.jobTitle}
                />
                <div className={classes.selectLabel}>Role</div>
                <SelectComponent
                    className={classes.selectComponent}
                    name="selectUserRole"
                    onFieldChange={(newValue, action) => handleRoleChange(newValue)}
                    placeHolder={"Select field..."}
                    clearable={false}
                    searchable={false}
                    isMenuFixed={true}
                    selectOptions={sternumBasicRolesList.map((role) => {
                        return {
                            label: Utils.capitalize(role.displayName.toLowerCase()),
                            value: role.displayName,
                        };
                    })}
                    selectedValue={
                        formik.values.role
                            ? {
                                  label: Utils.capitalize(formik.values.role.displayName.toLocaleLowerCase()),
                                  value: formik.values.role,
                              }
                            : {}
                    }
                    isDisabled={false}
                    type={"outlined"}
                />
            </DialogContent>

            <DialogActions className={classNames(classes.actions)}>
                <SternumLink className={classes.linkButton} onClick={onCancelClick}>
                    Cancel
                </SternumLink>
                <SternumImprovedButton
                    onClick={formik.handleSubmit}
                    isDisabled={isSendButtonDisabled()}
                    isLoading={formik.isSubmitting}
                    content={"Invite"}
                    buttonType={"regularWithDisabled"}
                    fullWidth={false}
                />
            </DialogActions>
        </Dialog>
    );
}
