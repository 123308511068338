import { makeStyles } from "@material-ui/core";
import commonStyle from "../../../components/CommonStyle";

const useSternumBannerStyles = makeStyles((theme) => {
    return {
        ...commonStyle(theme),
        container: {
            width: "100%",

            borderRadius: "10px",
            boxShadow: "0px 0px 23px rgba(231, 232, 235, 0.25)",
            minHeight: theme.spacing(6),
            position: "relative",
        },
        bannerPadding: {
            padding: theme.spacing(3, 0, 3, 0),
        },
        backgroundErrorState: {
            backgroundColor: "#FFF1F5",
        },
        backgroundSystemState: {
            backgroundColor: "#E3EFFF",
        },
        rectangle: {
            borderRadius: "14px",
            width: "5px",
            height: "100%",
            position: "absolute",
        },
        systemRecColor: {
            backgroundColor: "#1B6FDE",
        },
        errorRecColor: {
            backgroundColor: "#E7004C",
        },
    };
});

export default useSternumBannerStyles;
