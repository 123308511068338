/**
 * Defines the possible statuses of a trace.
 */
enum TraceSeverity {
    NORMAL,
    MEDIUM,
    HIGH,
}

export default TraceSeverity;
