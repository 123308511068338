import * as React from "react";
import Typography from "@material-ui/core/Typography";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import TableColumnHeaderInfo from "../../lib/state/TableColumnHeaderInfo";
import { FormLabel } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import viewColumnsPopoverStyle from "./ViewColumnsPopoverStyle";
import HashSet from "../../lib/infra/HashSet";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    viewedColumnsSet: HashSet;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof viewColumnsPopoverStyle> {
    columnHeaders: TableColumnHeaderInfo[];
    viewedColumnsSet: HashSet;
    onViewedColumnsChanged: (viewedColumnsSet: HashSet) => void;
    classes;
}

/**
 * Popover content for view columns.
 */
class ViewColumnsPopover extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            viewedColumnsSet: this.props.viewedColumnsSet,
        };
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        return (
            // All check boxes are held in a form control container.
            <FormControl>
                {/* The label of the form */}
                <FormLabel>
                    <Typography variant={"caption"}>Display columns</Typography>
                </FormLabel>

                {/* Generating a checkbox for each colum header that canBeHidden = true, meaning it can be toggled */}
                {this.props.columnHeaders
                    .filter((columnHeader) => columnHeader.canBeHidden)
                    .map((columnHeader) => {
                        return (
                            <FormControlLabel
                                key={columnHeader.id}
                                control={
                                    <Checkbox
                                        checked={this.state.viewedColumnsSet.exists(columnHeader.id)}
                                        onChange={(event) => this.handleViewedColumnChecked(event, columnHeader)}
                                        value={columnHeader.id}
                                        color={"primary"}
                                    />
                                }
                                label={columnHeader.displayValue}
                            />
                        );
                    })}
            </FormControl>
        );
    }

    /**
     * Occurs on a toggle of a checkbox.
     */
    private handleViewedColumnChecked(event, columnHeader) {
        // We create the new viewed columns map.
        let newViewedColumnsSet = HashSet.copy(this.state.viewedColumnsSet);
        if (event.target.checked) {
            newViewedColumnsSet.add(columnHeader.id);
        } else {
            newViewedColumnsSet.remove(columnHeader.id);
        }

        // We fire the given callback for when columns are changed.
        if (this.props.onViewedColumnsChanged) {
            this.props.onViewedColumnsChanged(newViewedColumnsSet);
        }

        // At last, we set the state to have the checkbox updated.
        this.setState({
            viewedColumnsSet: newViewedColumnsSet,
        });
    }
}

export default withStyles(viewColumnsPopoverStyle)(ViewColumnsPopover);
