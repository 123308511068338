import { Icon, Typography } from "@material-ui/core";
import _ from "lodash";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import moment from "moment";
import * as React from "react";
import { withRouter } from "react-router-dom";

import SternumConfiguration from "../../lib/infra/SternumConfiguration";
import SternumUtils from "../../lib/infra/SternumUtils";
import WebUtils from "../../lib/infra/WebUtils";
import AnalyticsService from "../../lib/services/AnalyticsService";
import ServiceWire from "../../lib/services/ServiceWire";
import GetSternumDeviceEventsResponse from "../../lib/state/GetSternumDeviceEventsResponse";
import SternumDeviceEventInfo from "../../lib/state/SternumDeviceEventInfo";
import HardwareEventsDisplayStyle from "./HardwareEventsDisplayStyle";
import SternumDeviceEventsFilter from "../../lib/state/SternumDeviceEventsFilter";
import SternumTable from "../SUI/SternumTable/SternumTable";
import HashSet from "../../lib/infra/HashSet";
import TableColumnHeaderInfo from "../../lib/state/TableColumnHeaderInfo";
import EntityType from "../../lib/state/EntityType";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    entities: SternumDeviceEventInfo[];
    scrollPosition: number;
    pageNumber: number;
    loadingEntities: boolean;
    totalItemCount: number;
    errorLoadingEntities: boolean;
    showInfinityLoader: boolean;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof HardwareEventsDisplayStyle> {
    bootEvent: SternumDeviceEventInfo;
    isInfinityScrollOn?: boolean;
    entitiesFilter: SternumDeviceEventsFilter;
    searchText: string;
}

/**
 * Displays arguments when a row is in a list.
 */
class HardwareEventsDisplay extends React.Component<AppProps, AppState> {
    /**
     * Defines the column headers participating in the entities table.
     */
    private readonly columnHeaders: TableColumnHeaderInfo[] = [
        new TableColumnHeaderInfo("created", "Received", false, true, false, false),
        new TableColumnHeaderInfo("message", "Message", false, true, false, false),
    ];

    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            entities: [],
            scrollPosition: 0,
            pageNumber: 1,
            loadingEntities: false,
            totalItemCount: 0,
            errorLoadingEntities: false,
            showInfinityLoader: false,
        };
    }

    componentDidMount() {
        this.loadEntitiesForScroll(1);
    }

    /**
     * Gets the display values for row's columns (where they aren't the default).
     */
    private getRowDisplayValues(entity: SternumDeviceEventInfo) {
        const { classes } = this.props;

        const { traceArguments } = entity.traceInfo;

        const message = traceArguments["ARG_ROLE_MESSAGE"]?.displayValue;

        return {
            created: (
                <div className={classNames(classes.flexVMiddle)}>
                    <Typography variant="body2" className={classNames(classes.marginLeftXs)}>
                        {moment(entity.created).format("MM/DD HH:mm:ss")}
                    </Typography>
                </div>
            ),

            message: <Typography variant="body2">{message}</Typography>,
        };
    }

    /**
     * Api call for boot messages.
     */
    private async fetchKernelModules(
        entity: SternumDeviceEventInfo,
        pageNumber: number
    ): Promise<GetSternumDeviceEventsResponse> {
        // `created_from` value is used in the api to find the boot for which we need to get boot kernel modules,
        // so this value should be always equal to device boot creation time, not custom time that user defined
        const entitiesFilter = this.props.entitiesFilter?.clone();
        if (entitiesFilter) {
            entitiesFilter.createdFrom = entity.created;
        }

        return ServiceWire.getSternumService().getDeviceSternumDeviceHardwareBootMessages(
            entity.device.deviceId,
            entitiesFilter,
            this.props.searchText,
            (pageNumber - 1) * SternumConfiguration.getPageSize(),
            SternumConfiguration.getPageSize()
        );
    }

    /**
     * Loads entities with concat operation to existing entities.
     */
    private loadEntitiesForScroll = async (pageNumber: number) => {
        try {
            let entities = [];
            let totalItemCount = 0;

            this.setState({
                errorLoadingEntities: false,
                showInfinityLoader: true,
            });

            let getSternumDeviceEventsResponse: GetSternumDeviceEventsResponse = await this.fetchKernelModules(
                this.props.bootEvent,
                pageNumber
            );

            // Fetching entities.
            if (pageNumber === 1) {
                entities = getSternumDeviceEventsResponse.sternumDeviceEvents;
            } else {
                entities = this.state.entities.concat(getSternumDeviceEventsResponse.sternumDeviceEvents);
            }

            totalItemCount = getSternumDeviceEventsResponse.totalItemCount;

            this.setState({
                errorLoadingEntities: false,
                showInfinityLoader: false,
                totalItemCount,
                entities: [...entities],
                pageNumber,
            });
        } catch (error) {
            AnalyticsService.error("HardwareEventsDisplay:loadEntitiesForScroll", error.message);

            this.setState({
                showInfinityLoader: false,
                errorLoadingEntities: true,
            });
        }
    };

    private handleScroll = (event: any) => {
        if (!this.props.isInfinityScrollOn) {
            return;
        }

        WebUtils.loadDataOnScroll(event, "loadHardwareEvents", (scrollTop) => {
            if (!this.isLastPage()) {
                this.setState({ scrollPosition: scrollTop }, () => {
                    this.loadEntitiesForScroll(this.state.pageNumber + 1);
                });
            }
        });
    };

    /**
     * Check if it's the last pagination page
     */
    private isLastPage = () => {
        return this.state.totalItemCount < this.state.pageNumber * SternumConfiguration.getPageSize();
    };

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        const viewedColumns = ["created", "message"];

        return (
            <div className={classNames(classes.container)} onScroll={this.handleScroll}>
                <SternumTable
                    emptyComponent={
                        <div className={classNames(classes.flexCenter, classes.padding)}>
                            <Typography variant="body2">Hardware events will appear here.</Typography>
                        </div>
                    }
                    viewedColumnsSet={HashSet.fromValues(viewedColumns)}
                    pageSize={SternumConfiguration.getPageSize()}
                    amountOfLoadingPlaceholders={5}
                    totalItemCount={this.state.totalItemCount}
                    loadingItems={this.state.loadingEntities}
                    errorLoadingItems={this.state.errorLoadingEntities}
                    columnHeaders={this.columnHeaders}
                    rows={this.state.entities}
                    getRowValues={(row) => this.getRowDisplayValues(row as SternumDeviceEventInfo)}
                    nonClickableRows
                    displayLoadingIcon
                    hideUpperBorder
                    hideToolbar
                    wrapColumns
                />
            </div>
        );
    }
}

export default withRouter(withStyles(HardwareEventsDisplayStyle)(HardwareEventsDisplay));
