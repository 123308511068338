import * as React from "react";

import { WithStyles, withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import cveInfoDisplayStyle from "./CveInfoDisplayStyle";
import { Typography } from "@material-ui/core";
import CveInfo from "../../lib/state/CveInfo";
import classNames from "classnames";
import TimeAgo from "react-timeago";
import SternumConfiguration from "../../lib/infra/SternumConfiguration";
import Link from "@material-ui/core/Link";
import moment from "moment";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    showFullDescription: boolean;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof cveInfoDisplayStyle> {
    cve: CveInfo;
}

/**
 * Cve info display.
 */
class CveInfoDisplay extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            showFullDescription: false,
        };
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        return (
            <div>
                {/* Details */}
                <div className={classes.details}>
                    <div className={classes.infoColumn}>
                        {/* Cve Id */}
                        <div className={classes.cveDetailContainer}>
                            {/* Title */}
                            <Typography variant={"body2"} className={classes.cveDetailName}>
                                CVE ID:
                            </Typography>

                            <Typography variant="body2">{this.props.cve.cveId}</Typography>
                        </div>

                        {/* Library name */}
                        {this.props.cve.usedLibrary && (
                            <div className={classes.cveDetailContainer}>
                                {/* Title */}
                                <Typography variant={"body2"} className={classes.cveDetailName}>
                                    Library Name:
                                </Typography>

                                <Typography variant="body2">{this.props.cve.usedLibrary.library.name}</Typography>
                            </div>
                        )}

                        {/* Library ID */}
                        {!this.props.cve.usedLibrary && (
                            <div className={classes.cveDetailContainer}>
                                {/* Title */}
                                <Typography variant={"body2"} className={classes.cveDetailName}>
                                    Library ID:
                                </Typography>

                                <Typography variant="body2">{this.props.cve.usedLibraryId}</Typography>
                            </div>
                        )}

                        {/* Library version */}
                        <div className={classes.cveDetailContainer}>
                            {/* Title */}
                            <Typography variant={"body2"} className={classes.cveDetailName}>
                                Library Version:
                            </Typography>

                            <Typography variant="body2">{this.props.cve.usedLibrary.version}</Typography>
                        </div>

                        {/* Publish date */}
                        <div className={classes.cveDetailContainer}>
                            {/* Title */}
                            <Typography variant={"body2"} className={classes.cveDetailName}>
                                Publish Date:
                            </Typography>

                            <Typography variant="body2">
                                {this.props.cve.externalEntity &&
                                    moment(this.props.cve.externalEntity["published_date"]).format("MM/DD/YYYY HH:mm")}
                            </Typography>
                        </div>

                        {/* Last Update At */}
                        <div className={classes.cveDetailContainer}>
                            {/* Title */}
                            <Typography variant={"body2"} className={classes.cveDetailName}>
                                Updated At:
                            </Typography>

                            <Typography variant="body2">
                                {this.props.cve.externalEntity &&
                                    moment(this.props.cve.externalEntity["last_modified_date"]).format(
                                        "MM/DD/YYYY HH:mm"
                                    )}
                            </Typography>
                        </div>
                    </div>

                    <div className={classes.infoColumn}>
                        {/* Update Time */}
                        <div className={classes.cveDetailContainer}>
                            {/* Title */}
                            <Typography variant={"body2"} className={classes.cveDetailName}>
                                Information Collected:
                            </Typography>

                            <Typography variant="body2">
                                <TimeAgo date={this.props.cve.updated} />
                            </Typography>
                        </div>

                        {/* Update Source */}
                        <div className={classes.cveDetailContainer}>
                            {/* Title */}
                            <Typography variant={"body2"} className={classes.cveDetailName}>
                                Update Source:
                            </Typography>

                            <Typography variant="body2">
                                {
                                    SternumConfiguration.getUpdateSourceTypeApiNameToConfigurationObject()[
                                        this.props.cve.updateSource
                                    ].displayName
                                }
                            </Typography>
                        </div>
                    </div>
                </div>

                {/* Description */}
                <div className={classNames(classes.marginTopLarge)}>
                    <Typography variant="body2">
                        {this.state.showFullDescription
                            ? this.props.cve.description
                            : this.props.cve.description.substr(0, 500) +
                              (this.props.cve.description.length > 500 ? "..." : "")}
                    </Typography>

                    {/* Show more/less */}
                    {this.props.cve.description.length > 500 && (
                        <Typography className={classNames(classes.marginTop)} onClick={() => this.seeAllDescription()}>
                            <span className={classNames(classes.cursorPointer, classes.descriptionHideShowLink)}>
                                {this.state.showFullDescription ? "Show less" : "Show more"}
                            </span>
                        </Typography>
                    )}
                </div>
            </div>
        );
    }

    /**
     * Displays the whole description.
     */
    private seeAllDescription() {
        this.setState({
            showFullDescription: !this.state.showFullDescription,
        });
    }
}

export default withRouter(withStyles(cveInfoDisplayStyle)(CveInfoDisplay));
