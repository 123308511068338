import { getNotificationsDefaultState, NotificationsState } from "../../state/NotificationsState";
import ActionType from "../ActionType";
import { NotificationsReducerActions } from "./NotificationsReducerActions";

/**
 * Reducer for all notification-related actions.
 */
const notificationsReducer = (
    state: NotificationsState = getNotificationsDefaultState(),
    action: NotificationsReducerActions
): NotificationsState => {
    switch (action.type) {
        case ActionType.ShowNotification: {
            return {
                ...state,
                displayed: true,
                message: action.message,
                variant: action.variant,
            };
        }

        case ActionType.HideNotification: {
            return {
                ...state,
                displayed: false,
                message: null,
                variant: undefined,
            };
        }

        default:
            return state;
    }
};

export default notificationsReducer;
