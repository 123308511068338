import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { withRouter } from "react-router-dom";
import PollingSubscriptionInfo from "../../lib/services/PollingSubscriptionInfo";
import ServiceWire from "../../lib/services/ServiceWire";
import MetricInfo from "../../lib/state/MetricInfo";
import PollingChangeType from "../../lib/state/PollingChangeType";
import DeviceKeyMetric from "../SUI/DeviceKeyMetric/DeviceKeyMetric";
import SpinnerLoader from "../SUI/SternumLoaders/SpinnerLoader";
import devicesKeyMetricsBarStyle from "./DevicesKeyMetricsBarStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    loadingMetrics: boolean;
    errorLoadingMetrics: boolean;
    metrics: MetricInfo[];
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof devicesKeyMetricsBarStyle> {
    selectedMetricId: string;
    onKeyMetricClicked: (metricId: string) => void;
}

/**
 * Displays a bar of metrics.
 */
class DevicesKeyMetricsBar extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            loadingMetrics: false,
            errorLoadingMetrics: false,
            metrics: [],
        };
    }

    /**
     * Help map to determine what metric should be displayed in component
     */
    private metricToDisplayInComponent: { [key: string]: boolean } = {
        DEVICES: true,
        TOTAL_DEVICES: true,
        COMPROMISED_DEVICES: true,
        UPDATE_REQUESTED: true,
    };

    /**
     * Occurs once the component finished its initialization process.
     */
    async componentDidMount() {
        await this.loadDevicesMetrics();

        ServiceWire.getPollingService().subscribe(
            new PollingSubscriptionInfo(
                "DevicesKeyMetricsBar",
                PollingChangeType.METRICS,
                this.handleChangedMetrics.bind(this)
            )
        );
    }

    /**
     * Occurs once the component is being destroyed.
     */
    componentWillUnmount(): void {
        ServiceWire.getPollingService().unsubscribe("DevicesKeyMetricsBar", PollingChangeType.METRICS);
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                {/* Key metrics */}
                {!this.state.loadingMetrics &&
                    this.state.metrics.map((metricInfo, index) => {
                        if (this.metricToDisplayInComponent[metricInfo.metricId]) {
                            return (
                                <DeviceKeyMetric
                                    key={index}
                                    {...metricInfo}
                                    // isSelected={metricInfo.metricId === this.props.selectedMetricId}
                                    isSelected={false}
                                    notClickable={true}
                                    onKeyMetricClicked={(metricId) => this.handleMetricClicked(metricId)}
                                />
                            );
                        }
                    })}

                {this.state.loadingMetrics && (
                    <div>
                        <SpinnerLoader />
                    </div>
                )}
            </div>
        );
    }

    /**
     * Occurs once a key metric is clicked.
     */
    private handleMetricClicked(metricId: string) {
        if (this.props.onKeyMetricClicked) {
            this.props.onKeyMetricClicked(metricId);
        }
    }

    /**
     * Fetches the metrics for devices.
     */
    private async loadDevicesMetrics() {
        try {
            this.setState({
                loadingMetrics: true,
                errorLoadingMetrics: false,
            });

            let metrics = await ServiceWire.getSternumService().getDevicesMetrics(
                ServiceWire.getClientsService().getSelectedClientId()
            );

            this.setState({
                loadingMetrics: false,
                errorLoadingMetrics: false,
                metrics: metrics,
            });
        } catch (error) {
            this.setState({
                loadingMetrics: false,
                errorLoadingMetrics: true,
            });
        }
    }

    /**
     * Handles the polling data.
     */
    private handleChangedMetrics(fromTimestamp: number, changedData: Object): void {
        let allMetrics: MetricInfo[] = [];
        for (let key in changedData) {
            if (changedData.hasOwnProperty(key)) {
                allMetrics.push(MetricInfo.fromJsonObject(changedData[key]));
            }
        }

        this.setState({
            metrics: allMetrics,
        });
    }
}

export default withRouter(withStyles(devicesKeyMetricsBarStyle)(DevicesKeyMetricsBar));
