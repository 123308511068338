import { WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import Utils from "../../lib/infra/Utils";
import { closeModalAction } from "../../lib/redux/modals/CloseModalAction";
import { enterModalFullscreenAction } from "../../lib/redux/modals/EnterModalFullscreenAction";
import { exitModalFullscreenAction } from "../../lib/redux/modals/ExitModalFullscreenAction";
import { GlobalState } from "../../lib/state/GlobalState";
import ModalKey from "../../lib/state/ModalKey";
import ModalStateConfiguration from "../../lib/state/ModalStateConfiguration";
import ModalType from "../../lib/state/ModalType";
import ActivitiesListModal from "../ActivitiesListModal/ActivitiesListModal";
import AlertDescriptionModal from "../AlertDescription/AlertDescriptionModal";
import ChangeLogModal from "../ChangeLogModal/ChangeLogModal";
import ChangePasswordModal from "../ChangePasswordModal/ChangePasswordModal";
import CreateTriggerModal from "../CreateTriggerModal/CreateTriggerModal";
import CvesListModal from "../CvesListModal/CvesListModal";
import CveView from "../CveView/CveView";
import CreateDashboardModal from "../DashboardsPage/CreateDashboardModal/CreateDashboardModal";
import EditDashboardModal from "../DashboardsPage/EditDashboardModal/EditDashboardModal";
import DeviceDefinitionCreateModal from "../DeviceDefinitionComponents/DeviceDefinitionModal";
import DeviceDefinitionEditModal from "../DeviceDefinitionEditModal/DeviceDefinitionEditModal";
import DeviceDefinitionView from "../DeviceDefinitionView/DeviceDefinitionView";
import DevicesListModal from "../DevicesListModal/DevicesListModal";
import DeviceView from "../DeviceView/DeviceView";
import DeviceLocationModal from "../GeoLocationHistoryModal/GeoLocationHistoryModal";
import LibraryHistoriesModal from "../LibraryHistoriesModal/LibraryHistoriesModal";
import LibraryView from "../LibraryView/LibraryView";
import DeviceDefinitionLocationModal from "../MapModal/MapModal";
import NewAPIKeyModal from "../NewAPIKeyModal/NewAPIKeyModal";
import NewDeviceView from "../NewDeviceView/NewDeviceView";
import OutgoingWebhookConfigurationModal from "../OutgoingWebhookConfigurationModal/OutgoingWebhookConfigurationModal";
import SternumGeneratedEventView from "../SternumGeneratedEventView/SternumGeneratedEventView";
import SternumModal from "../SUI/SternumModal/SternumModal";
import TraceView from "../TraceView/TraceView";
import UsedLibraryView from "../UsedLibraryView/UsedLibraryView";
import VisualisationConfigurationComponent from "../VisualisationConfigurationComponent/VisualisationConfigurationComponent";
import { GoProDialog } from "../GoProDialog/GoProDialog";
import modalsStyle from "./ModalsStyle";
import { GetSupportModal } from "../GetSupportModal/GetSupportModal";
import { SimulateAttackDialog } from "../Dashboard/DashboardFree/SimulateAttack/SimulateAttackDialog.component";
import InteractiveCoverageDialog from "../InteractiveCoverageDialog/InteractiveCoverageDialog";

/**
 * Holds the inner state for our app.
 */
interface AppState {}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof modalsStyle> {
    modalIdToConfigurationMap?: { [key: string]: ModalStateConfiguration };
    openModalsStack?: ModalKey[];
    closeModal?: (modalKey: ModalKey) => void;
    enterFullscreenModal?: (modalKey: ModalKey) => void;
    exitFullscreenModal?: (modalKey: ModalKey) => void;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {
        modalIdToConfigurationMap: state.ui.modals.modalIdToConfigurationMap,
        openModalsStack: state.ui.modals.openModalsStack,
    };
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {
        closeModal: (modalKey) => dispatch(closeModalAction(modalKey)),
        enterFullscreenModal: (modalKey) => dispatch(enterModalFullscreenAction(modalKey)),
        exitFullscreenModal: (modalKey) => dispatch(exitModalFullscreenAction(modalKey)),
    };
};

/**
 * Holds the content of the side bar of our application.
 */
class Modals extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {};
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;
        let modalElements = [];

        for (let key in this.props.modalIdToConfigurationMap) {
            if (this.props.modalIdToConfigurationMap.hasOwnProperty(key)) {
                let modalConfiguration = this.props.modalIdToConfigurationMap[key];

                switch (modalConfiguration.modalKey.modalType) {
                    case ModalType.DeviceViewModal:
                        modalElements.push(
                            <SternumModal
                                key={modalConfiguration.modalKey.getStringKey()}
                                modalKey={modalConfiguration.modalKey.getStringKey()}
                                aria-labelledby="Device Details"
                                aria-describedby="Displays summary of details about a certain device"
                                open={modalConfiguration.open}
                                onClose={() => this.props.closeModal(modalConfiguration.modalKey)}
                                showXButton={
                                    Utils.isNullOrUndefined(modalConfiguration.parametersMap["displayXButton"])
                                        ? false
                                        : modalConfiguration.parametersMap["displayXButton"]
                                }
                                showFullScreenButton={false}
                                fullscreen={modalConfiguration.fullScreen}
                                showBackButton={
                                    Utils.isNullOrUndefined(modalConfiguration.parametersMap["displayBackButton"])
                                        ? false
                                        : modalConfiguration.parametersMap["displayBackButton"]
                                }
                                stacked={this.calculateIsStacked(modalConfiguration.modalKey)}
                                modalContentClassNames={classes.deviceViewModalContent}
                                onFullscreenDisplayChanged={(fullscreenDisplay) =>
                                    fullscreenDisplay
                                        ? this.props.enterFullscreenModal(modalConfiguration.modalKey)
                                        : this.props.exitFullscreenModal(modalConfiguration.modalKey)
                                }
                            >
                                <NewDeviceView
                                    fullScreenDisplay={modalConfiguration.fullScreen}
                                    displayBackButton={true}
                                    displayViewForDeviceDefinition={
                                        modalConfiguration.parametersMap["displayViewForDeviceDefinition"]
                                    }
                                    deviceId={modalConfiguration.parametersMap["deviceId"]}
                                    deviceDefinitionId={modalConfiguration.parametersMap["deviceDefinitionId"]}
                                    hideShowInContext={true}
                                />
                            </SternumModal>
                        );
                        break;

                    case ModalType.DeviceInfoModal:
                        modalElements.push(
                            <SternumModal
                                key={modalConfiguration.modalKey.getStringKey()}
                                modalKey={modalConfiguration.modalKey.getStringKey()}
                                aria-labelledby="Device Information"
                                aria-describedby="Displays summary of details about a certain device"
                                open={modalConfiguration.open}
                                onClose={() => this.props.closeModal(modalConfiguration.modalKey)}
                                showXButton={modalConfiguration.parametersMap["displayXButton"]}
                                showFullScreenButton={false}
                                showBackButton={modalConfiguration.parametersMap["displayBackButton"]}
                                fullscreen={false}
                                stacked={this.calculateIsStacked(modalConfiguration.modalKey)}
                                modalContentClassNames={classes.deviceInfoModalContent}
                                onFullscreenDisplayChanged={(fullscreenDisplay) =>
                                    fullscreenDisplay
                                        ? this.props.enterFullscreenModal(modalConfiguration.modalKey)
                                        : this.props.exitFullscreenModal(modalConfiguration.modalKey)
                                }
                            >
                                <DeviceView
                                    deviceId={modalConfiguration.parametersMap["deviceId"]}
                                    fullScreenDisplay={modalConfiguration.fullScreen}
                                />
                            </SternumModal>
                        );
                        break;

                    case ModalType.DeviceDefinitionInfoModal:
                        modalElements.push(
                            <SternumModal
                                key={modalConfiguration.modalKey.getStringKey()}
                                modalKey={modalConfiguration.modalKey.getStringKey()}
                                aria-labelledby="Device Information"
                                aria-describedby="Displays summary of details about a certain device"
                                open={modalConfiguration.open}
                                onClose={() => this.props.closeModal(modalConfiguration.modalKey)}
                                showXButton={modalConfiguration.parametersMap["displayXButton"]}
                                showFullScreenButton={false}
                                showBackButton={modalConfiguration.parametersMap["displayBackButton"]}
                                fullscreen={false}
                                stacked={this.calculateIsStacked(modalConfiguration.modalKey)}
                                modalContentClassNames={classes.deviceInfoModalContent}
                                onFullscreenDisplayChanged={(fullscreenDisplay) =>
                                    fullscreenDisplay
                                        ? this.props.enterFullscreenModal(modalConfiguration.modalKey)
                                        : this.props.exitFullscreenModal(modalConfiguration.modalKey)
                                }
                            >
                                <DeviceDefinitionView
                                    entityId={modalConfiguration.parametersMap["entityId"]}
                                    fullScreenDisplay={modalConfiguration.fullScreen}
                                />
                            </SternumModal>
                        );
                        break;

                    case ModalType.TraceViewModal:
                        modalElements.push(
                            <SternumModal
                                key={modalConfiguration.modalKey.getStringKey()}
                                modalKey={modalConfiguration.modalKey.getStringKey()}
                                aria-labelledby="Trace Details"
                                aria-describedby="Displays summary of details about a certain trace"
                                open={modalConfiguration.open}
                                onClose={() => this.props.closeModal(modalConfiguration.modalKey)}
                                showXButton={modalConfiguration.parametersMap["displayXButton"]}
                                showFullScreenButton={true}
                                showBackButton={modalConfiguration.parametersMap["displayBackButton"]}
                                fullscreen={false}
                                stacked={this.calculateIsStacked(modalConfiguration.modalKey)}
                                modalContentClassNames={classes.traceViewModalContent}
                                onFullscreenDisplayChanged={(fullscreenDisplay) =>
                                    fullscreenDisplay
                                        ? this.props.enterFullscreenModal(modalConfiguration.modalKey)
                                        : this.props.exitFullscreenModal(modalConfiguration.modalKey)
                                }
                            >
                                <TraceView
                                    traceId={modalConfiguration.parametersMap["traceId"]}
                                    shouldDisplayLinkToDeviceView={
                                        modalConfiguration.parametersMap["shouldDisplayLinkToDeviceView"]
                                    }
                                    shouldDisplayLinkToExportReport={
                                        modalConfiguration.parametersMap["shouldDisplayLinkToExportReport"]
                                    }
                                    shouldDisplayViewInContextButton={
                                        modalConfiguration.parametersMap["shouldDisplayViewInContextButton"]
                                    }
                                    fullScreenDisplay={modalConfiguration.fullScreen}
                                    issue={modalConfiguration.parametersMap["issue"]}
                                    resolveFlowOpen={modalConfiguration.parametersMap["resolveFlowOpen"]}
                                    onRefresh={modalConfiguration.parametersMap["onRefresh"]}
                                    closeModal={() => this.props.closeModal(modalConfiguration.modalKey)}
                                    modalShowsBackButton={!!modalConfiguration.parametersMap["displayBackButton"]}
                                />
                            </SternumModal>
                        );
                        break;

                    case ModalType.VisualizationCreation:
                        modalElements.push(
                            <SternumModal
                                key={modalConfiguration.modalKey.getStringKey()}
                                modalKey={modalConfiguration.modalKey.getStringKey()}
                                aria-labelledby="Trace Details"
                                aria-describedby="Displays summary of details about a certain trace"
                                open={modalConfiguration.open}
                                onClose={() => this.props.closeModal(modalConfiguration.modalKey)}
                                showXButton={modalConfiguration.parametersMap["displayXButton"]}
                                showFullScreenButton={false}
                                showBackButton={modalConfiguration.parametersMap["displayBackButton"]}
                                fullscreen={false}
                                stacked={this.calculateIsStacked(modalConfiguration.modalKey)}
                                modalContentClassNames={classes.visualizationModal}
                                onFullscreenDisplayChanged={(fullscreenDisplay) =>
                                    fullscreenDisplay
                                        ? this.props.enterFullscreenModal(modalConfiguration.modalKey)
                                        : this.props.exitFullscreenModal(modalConfiguration.modalKey)
                                }
                            >
                                <VisualisationConfigurationComponent
                                    deviceId={modalConfiguration.parametersMap["deviceId"]}
                                    visualisationId={modalConfiguration.parametersMap["visualisationId"]}
                                    visualisation={modalConfiguration.parametersMap["visualisation"]}
                                    disableEditing={modalConfiguration.parametersMap["disableEditing"]}
                                    deviceDefinitionVersions={
                                        modalConfiguration.parametersMap["deviceDefinitionVersions"]
                                    }
                                    receivedDefinitionsResponse={
                                        modalConfiguration.parametersMap["receivedDefinitionsResponse"]
                                    }
                                    startTime={modalConfiguration.parametersMap["startTime"]}
                                    endTime={modalConfiguration.parametersMap["endTime"]}
                                    sternumQuery={modalConfiguration.parametersMap["sternumQuery"]}
                                    displayViewForDeviceDefinition={
                                        modalConfiguration.parametersMap["displayViewForDeviceDefinition"]
                                    }
                                    lookedUpEntityId={modalConfiguration.parametersMap["lookedUpEntityId"]}
                                    timeSelectedOption={modalConfiguration.parametersMap["timeSelectedOption"]}
                                    visualisationType={modalConfiguration.parametersMap["visualisationType"]}
                                    dashboardId={modalConfiguration.parametersMap["dashboardId"]}
                                    onVisualisationCreated={modalConfiguration.parametersMap["onVisualisationCreated"]}
                                    onVisualisationUpdated={modalConfiguration.parametersMap["onVisualisationUpdated"]}
                                    onVisualisationDeleted={modalConfiguration.parametersMap["onVisualisationDeleted"]}
                                />
                            </SternumModal>
                        );
                        break;

                    case ModalType.CreateDashboardModal:
                        modalElements.push(
                            <SternumModal
                                key={modalConfiguration.modalKey.getStringKey()}
                                modalKey={modalConfiguration.modalKey.getStringKey()}
                                aria-labelledby="Trace Details"
                                aria-describedby="Displays summary of details about a certain trace"
                                open={modalConfiguration.open}
                                onClose={() => this.props.closeModal(modalConfiguration.modalKey)}
                                showXButton={modalConfiguration.parametersMap["displayXButton"]}
                                showFullScreenButton={false}
                                showBackButton={modalConfiguration.parametersMap["displayBackButton"]}
                                fullscreen={false}
                                stacked={this.calculateIsStacked(modalConfiguration.modalKey)}
                                modalContentClassNames={classes.createDashboardModal}
                                onFullscreenDisplayChanged={(fullscreenDisplay) =>
                                    fullscreenDisplay
                                        ? this.props.enterFullscreenModal(modalConfiguration.modalKey)
                                        : this.props.exitFullscreenModal(modalConfiguration.modalKey)
                                }
                            >
                                <CreateDashboardModal
                                    onCreateOperation={modalConfiguration.parametersMap["onCreateOperation"]}
                                />
                            </SternumModal>
                        );
                        break;

                    case ModalType.EditDashboardModal:
                        modalElements.push(
                            <SternumModal
                                key={modalConfiguration.modalKey.getStringKey()}
                                modalKey={modalConfiguration.modalKey.getStringKey()}
                                aria-labelledby="Edit Dashboard"
                                aria-describedby="Modal for modifying dashboard information"
                                open={modalConfiguration.open}
                                onClose={() => this.props.closeModal(modalConfiguration.modalKey)}
                                showXButton={modalConfiguration.parametersMap["displayXButton"]}
                                showFullScreenButton={false}
                                showBackButton={modalConfiguration.parametersMap["displayBackButton"]}
                                fullscreen={false}
                                stacked={this.calculateIsStacked(modalConfiguration.modalKey)}
                                modalContentClassNames={classes.createDashboardModal}
                                onFullscreenDisplayChanged={(fullscreenDisplay) =>
                                    fullscreenDisplay
                                        ? this.props.enterFullscreenModal(modalConfiguration.modalKey)
                                        : this.props.exitFullscreenModal(modalConfiguration.modalKey)
                                }
                            >
                                <EditDashboardModal
                                    onEditOperation={modalConfiguration.parametersMap["onEditOperation"]}
                                    dashboard={modalConfiguration.parametersMap["dashboard"]}
                                />
                            </SternumModal>
                        );
                        break;

                    case ModalType.SternumGeneratedEventViewModal:
                        modalElements.push(
                            <SternumModal
                                key={modalConfiguration.modalKey.getStringKey()}
                                modalKey={modalConfiguration.modalKey.getStringKey()}
                                aria-labelledby="Rule Details"
                                aria-describedby="Displays summary of details about a certain rule"
                                open={modalConfiguration.open}
                                onClose={() => this.props.closeModal(modalConfiguration.modalKey)}
                                showXButton={modalConfiguration.parametersMap["displayXButton"]}
                                showFullScreenButton={true}
                                showBackButton={modalConfiguration.parametersMap["displayBackButton"]}
                                fullscreen={false}
                                stacked={this.calculateIsStacked(modalConfiguration.modalKey)}
                                modalContentClassNames={classes.sternumGeneratedEventViewModalContent}
                                onFullscreenDisplayChanged={(fullscreenDisplay) =>
                                    fullscreenDisplay
                                        ? this.props.enterFullscreenModal(modalConfiguration.modalKey)
                                        : this.props.exitFullscreenModal(modalConfiguration.modalKey)
                                }
                            >
                                <SternumGeneratedEventView
                                    generatedEventId={modalConfiguration.parametersMap["generatedEventId"]}
                                    fullScreenDisplay={modalConfiguration.fullScreen}
                                    shouldDisplayLinkToExportReport={true}
                                    shouldDisplayLinkToDeviceView={
                                        modalConfiguration.parametersMap["shouldDisplayLinkToDeviceView"]
                                    }
                                    shouldDisplayViewInContextButton={
                                        modalConfiguration.parametersMap["shouldDisplayViewInContextButton"]
                                    }
                                    issue={modalConfiguration.parametersMap["issue"]}
                                    resolveFlowOpen={modalConfiguration.parametersMap["resolveFlowOpen"]}
                                    onRefresh={modalConfiguration.parametersMap["onRefresh"]}
                                    closeModal={() => this.props.closeModal(modalConfiguration.modalKey)}
                                    modalShowsBackButton={!!modalConfiguration.parametersMap["displayBackButton"]}
                                />
                            </SternumModal>
                        );
                        break;

                    case ModalType.LibraryViewModal:
                        modalElements.push(
                            <SternumModal
                                key={modalConfiguration.modalKey.getStringKey()}
                                modalKey={modalConfiguration.modalKey.getStringKey()}
                                aria-labelledby="Library Details"
                                aria-describedby="Displays summary of details about a certain library"
                                open={modalConfiguration.open}
                                onClose={() => this.props.closeModal(modalConfiguration.modalKey)}
                                showXButton={modalConfiguration.parametersMap["displayXButton"]}
                                showFullScreenButton={true}
                                showBackButton={modalConfiguration.parametersMap["displayBackButton"]}
                                fullscreen={false}
                                stacked={this.calculateIsStacked(modalConfiguration.modalKey)}
                                modalContentClassNames={classes.libraryViewModalContent}
                                onFullscreenDisplayChanged={(fullscreenDisplay) =>
                                    fullscreenDisplay
                                        ? this.props.enterFullscreenModal(modalConfiguration.modalKey)
                                        : this.props.exitFullscreenModal(modalConfiguration.modalKey)
                                }
                            >
                                <LibraryView
                                    libraryId={modalConfiguration.parametersMap["libraryId"]}
                                    fullScreenDisplay={modalConfiguration.fullScreen}
                                    backButtonDisplayed={modalConfiguration.parametersMap["displayBackButton"]}
                                />
                            </SternumModal>
                        );
                        break;

                    case ModalType.UsedLibraryViewModal:
                        modalElements.push(
                            <SternumModal
                                key={modalConfiguration.modalKey.getStringKey()}
                                modalKey={modalConfiguration.modalKey.getStringKey()}
                                aria-labelledby="Library Details"
                                aria-describedby="Displays summary of details about a certain library"
                                open={modalConfiguration.open}
                                onClose={() => this.props.closeModal(modalConfiguration.modalKey)}
                                showXButton={modalConfiguration.parametersMap["displayXButton"]}
                                showFullScreenButton={true}
                                showBackButton={modalConfiguration.parametersMap["displayBackButton"]}
                                fullscreen={false}
                                stacked={this.calculateIsStacked(modalConfiguration.modalKey)}
                                modalContentClassNames={classes.usedLibraryViewModalContent}
                                onFullscreenDisplayChanged={(fullscreenDisplay) =>
                                    fullscreenDisplay
                                        ? this.props.enterFullscreenModal(modalConfiguration.modalKey)
                                        : this.props.exitFullscreenModal(modalConfiguration.modalKey)
                                }
                            >
                                <UsedLibraryView
                                    usedLibraryId={modalConfiguration.parametersMap["usedLibraryId"]}
                                    fullScreenDisplay={modalConfiguration.fullScreen}
                                    backButtonDisplayed={modalConfiguration.parametersMap["displayBackButton"]}
                                />
                            </SternumModal>
                        );
                        break;

                    case ModalType.ChangeLogModal:
                        modalElements.push(
                            <SternumModal
                                key={modalConfiguration.modalKey.getStringKey()}
                                modalKey={modalConfiguration.modalKey.getStringKey()}
                                aria-labelledby="ChangeLog"
                                aria-describedby="Displays the changelog of Sternum"
                                open={modalConfiguration.open}
                                onClose={() => this.props.closeModal(modalConfiguration.modalKey)}
                                showXButton={modalConfiguration.parametersMap["displayXButton"]}
                                showFullScreenButton={true}
                                showBackButton={modalConfiguration.parametersMap["displayBackButton"]}
                                fullscreen={false}
                                stacked={this.calculateIsStacked(modalConfiguration.modalKey)}
                                modalContentClassNames={classes.changeLogModalContent}
                                onFullscreenDisplayChanged={(fullscreenDisplay) =>
                                    fullscreenDisplay
                                        ? this.props.enterFullscreenModal(modalConfiguration.modalKey)
                                        : this.props.exitFullscreenModal(modalConfiguration.modalKey)
                                }
                            >
                                <ChangeLogModal />
                            </SternumModal>
                        );
                        break;

                    case ModalType.CveViewModal:
                        modalElements.push(
                            <SternumModal
                                key={modalConfiguration.modalKey.getStringKey()}
                                modalKey={modalConfiguration.modalKey.getStringKey()}
                                aria-labelledby="Cve Details"
                                aria-describedby="Displays summary of details about a certain cve"
                                open={modalConfiguration.open}
                                onClose={() => this.props.closeModal(modalConfiguration.modalKey)}
                                showXButton={modalConfiguration.parametersMap["displayXButton"]}
                                showFullScreenButton={true}
                                showBackButton={modalConfiguration.parametersMap["displayBackButton"]}
                                fullscreen={false}
                                stacked={this.calculateIsStacked(modalConfiguration.modalKey)}
                                modalContentClassNames={classes.cveViewModalContent}
                                onFullscreenDisplayChanged={(fullscreenDisplay) =>
                                    fullscreenDisplay
                                        ? this.props.enterFullscreenModal(modalConfiguration.modalKey)
                                        : this.props.exitFullscreenModal(modalConfiguration.modalKey)
                                }
                            >
                                <CveView
                                    cveId={modalConfiguration.parametersMap["cveId"]}
                                    fullScreenDisplay={modalConfiguration.fullScreen}
                                />
                            </SternumModal>
                        );
                        break;

                    case ModalType.LibraryHistoriesModal:
                        modalElements.push(
                            <SternumModal
                                key={modalConfiguration.modalKey.getStringKey()}
                                modalKey={modalConfiguration.modalKey.getStringKey()}
                                aria-labelledby="Library Histories Modal"
                                aria-describedby="Displays list of history over libraries"
                                open={modalConfiguration.open}
                                onClose={() => this.props.closeModal(modalConfiguration.modalKey)}
                                showXButton={modalConfiguration.parametersMap["displayXButton"]}
                                showFullScreenButton={true}
                                showBackButton={modalConfiguration.parametersMap["displayBackButton"]}
                                fullscreen={false}
                                stacked={this.calculateIsStacked(modalConfiguration.modalKey)}
                                modalContentClassNames={classes.libraryHistoriesModalContent}
                                onFullscreenDisplayChanged={(fullscreenDisplay) =>
                                    fullscreenDisplay
                                        ? this.props.enterFullscreenModal(modalConfiguration.modalKey)
                                        : this.props.exitFullscreenModal(modalConfiguration.modalKey)
                                }
                            >
                                <LibraryHistoriesModal
                                    library={modalConfiguration.parametersMap["library"]}
                                    fullScreenDisplay={modalConfiguration.fullScreen}
                                />
                            </SternumModal>
                        );
                        break;

                    case ModalType.ActivitiesListModal:
                        modalElements.push(
                            <SternumModal
                                key={modalConfiguration.modalKey.getStringKey()}
                                modalKey={modalConfiguration.modalKey.getStringKey()}
                                aria-labelledby="Library Histories Modal"
                                aria-describedby="Displays list of history over libraries"
                                open={modalConfiguration.open}
                                onClose={() => this.props.closeModal(modalConfiguration.modalKey)}
                                showXButton={modalConfiguration.parametersMap["displayXButton"]}
                                showFullScreenButton={true}
                                showBackButton={modalConfiguration.parametersMap["displayBackButton"]}
                                fullscreen={false}
                                stacked={this.calculateIsStacked(modalConfiguration.modalKey)}
                                modalContentClassNames={classes.libraryHistoriesModalContent}
                                onFullscreenDisplayChanged={(fullscreenDisplay) =>
                                    fullscreenDisplay
                                        ? this.props.enterFullscreenModal(modalConfiguration.modalKey)
                                        : this.props.exitFullscreenModal(modalConfiguration.modalKey)
                                }
                            >
                                <ActivitiesListModal
                                    preloadedEntities={modalConfiguration.parametersMap["preloadedEntities"]}
                                    fullScreenDisplay={modalConfiguration.fullScreen}
                                />
                            </SternumModal>
                        );
                        break;

                    case ModalType.CvesListModal:
                        modalElements.push(
                            <SternumModal
                                key={modalConfiguration.modalKey.getStringKey()}
                                modalKey={modalConfiguration.modalKey.getStringKey()}
                                open={modalConfiguration.open}
                                onClose={() => this.props.closeModal(modalConfiguration.modalKey)}
                                showXButton={modalConfiguration.parametersMap["displayXButton"]}
                                showFullScreenButton={true}
                                showBackButton={modalConfiguration.parametersMap["displayBackButton"]}
                                fullscreen={false}
                                stacked={this.calculateIsStacked(modalConfiguration.modalKey)}
                                modalContentClassNames={classes.libraryHistoriesModalContent}
                                onFullscreenDisplayChanged={(fullscreenDisplay) =>
                                    fullscreenDisplay
                                        ? this.props.enterFullscreenModal(modalConfiguration.modalKey)
                                        : this.props.exitFullscreenModal(modalConfiguration.modalKey)
                                }
                            >
                                <CvesListModal
                                    fullScreenDisplay={modalConfiguration.fullScreen}
                                    cvesFilter={modalConfiguration.parametersMap["cvesFilter"]}
                                />
                            </SternumModal>
                        );
                        break;

                    case ModalType.DevicesListModal:
                        modalElements.push(
                            <SternumModal
                                key={modalConfiguration.modalKey.getStringKey()}
                                modalKey={modalConfiguration.modalKey.getStringKey()}
                                open={modalConfiguration.open}
                                onClose={() => this.props.closeModal(modalConfiguration.modalKey)}
                                showXButton={modalConfiguration.parametersMap["displayXButton"]}
                                showFullScreenButton={true}
                                showBackButton={modalConfiguration.parametersMap["displayBackButton"]}
                                fullscreen={false}
                                stacked={this.calculateIsStacked(modalConfiguration.modalKey)}
                                modalContentClassNames={classes.libraryHistoriesModalContent}
                                onFullscreenDisplayChanged={(fullscreenDisplay) =>
                                    fullscreenDisplay
                                        ? this.props.enterFullscreenModal(modalConfiguration.modalKey)
                                        : this.props.exitFullscreenModal(modalConfiguration.modalKey)
                                }
                            >
                                <DevicesListModal fullScreenDisplay={modalConfiguration.fullScreen} />
                            </SternumModal>
                        );
                        break;

                    case ModalType.CreateTriggerModal:
                        modalElements.push(
                            <SternumModal
                                key={modalConfiguration.modalKey.getStringKey()}
                                modalKey={modalConfiguration.modalKey.getStringKey()}
                                open={modalConfiguration.open}
                                onClose={() => this.props.closeModal(modalConfiguration.modalKey)}
                                showXButton={modalConfiguration.parametersMap["displayXButton"]}
                                showFullScreenButton={true}
                                showBackButton={modalConfiguration.parametersMap["displayBackButton"]}
                                fullscreen={false}
                                stacked={this.calculateIsStacked(modalConfiguration.modalKey)}
                                modalContentClassNames={classes.newAPIKeyModalContent}
                                onFullscreenDisplayChanged={(fullscreenDisplay) =>
                                    fullscreenDisplay
                                        ? this.props.enterFullscreenModal(modalConfiguration.modalKey)
                                        : this.props.exitFullscreenModal(modalConfiguration.modalKey)
                                }
                            >
                                <CreateTriggerModal fullScreenDisplay={modalConfiguration.fullScreen} />
                            </SternumModal>
                        );
                        break;

                    case ModalType.OutgoingWebhookConfigurationModal:
                        modalElements.push(
                            <SternumModal
                                key={modalConfiguration.modalKey.getStringKey()}
                                modalKey={modalConfiguration.modalKey.getStringKey()}
                                open={modalConfiguration.open}
                                onClose={() => this.props.closeModal(modalConfiguration.modalKey)}
                                showXButton={modalConfiguration.parametersMap["displayXButton"]}
                                showFullScreenButton={false}
                                showBackButton={modalConfiguration.parametersMap["displayBackButton"]}
                                fullscreen={false}
                                stacked={this.calculateIsStacked(modalConfiguration.modalKey)}
                                modalContentClassNames={classes.outgoingWebhookConfigurationModalContent}
                                onFullscreenDisplayChanged={(fullscreenDisplay) =>
                                    fullscreenDisplay
                                        ? this.props.enterFullscreenModal(modalConfiguration.modalKey)
                                        : this.props.exitFullscreenModal(modalConfiguration.modalKey)
                                }
                            >
                                <OutgoingWebhookConfigurationModal
                                    fullScreenDisplay={modalConfiguration.fullScreen}
                                    existingOutgoingWebhook={
                                        modalConfiguration.parametersMap["existingOutgoingWebhook"]
                                    }
                                    closeModal={() => this.props.closeModal(modalConfiguration.modalKey)}
                                />
                            </SternumModal>
                        );
                        break;

                    case ModalType.NewAPIKeyModal:
                        modalElements.push(
                            <SternumModal
                                key={modalConfiguration.modalKey.getStringKey()}
                                modalKey={modalConfiguration.modalKey.getStringKey()}
                                open={modalConfiguration.open}
                                onClose={() => this.props.closeModal(modalConfiguration.modalKey)}
                                showXButton={modalConfiguration.parametersMap["displayXButton"]}
                                showFullScreenButton={false}
                                showBackButton={modalConfiguration.parametersMap["displayBackButton"]}
                                fullscreen={false}
                                stacked={this.calculateIsStacked(modalConfiguration.modalKey)}
                                modalContentClassNames={classes.newAPIKeyModalContent}
                                onFullscreenDisplayChanged={(fullscreenDisplay) =>
                                    fullscreenDisplay
                                        ? this.props.enterFullscreenModal(modalConfiguration.modalKey)
                                        : this.props.exitFullscreenModal(modalConfiguration.modalKey)
                                }
                            >
                                <NewAPIKeyModal
                                    fullScreenDisplay={modalConfiguration.fullScreen}
                                    closeModal={() => this.props.closeModal(modalConfiguration.modalKey)}
                                />
                            </SternumModal>
                        );
                        break;

                    case ModalType.CreateDeviceDefinitionModal:
                        modalElements.push(
                            <SternumModal
                                key={modalConfiguration.modalKey.getStringKey()}
                                modalKey={modalConfiguration.modalKey.getStringKey()}
                                open={modalConfiguration.open}
                                onClose={() => this.props.closeModal(modalConfiguration.modalKey)}
                                showXButton={modalConfiguration.parametersMap["displayXButton"]}
                                showFullScreenButton={false}
                                showBackButton={modalConfiguration.parametersMap["displayBackButton"]}
                                fullscreen={false}
                                stacked={this.calculateIsStacked(modalConfiguration.modalKey)}
                                modalContentClassNames={classes.newDeviceDefinitionContent}
                                ignoreClickOnFadeArea={true}
                                onFullscreenDisplayChanged={(fullscreenDisplay) =>
                                    fullscreenDisplay
                                        ? this.props.enterFullscreenModal(modalConfiguration.modalKey)
                                        : this.props.exitFullscreenModal(modalConfiguration.modalKey)
                                }
                            >
                                <DeviceDefinitionCreateModal
                                    fullScreenDisplay={modalConfiguration.fullScreen}
                                    closeModal={() => this.props.closeModal(modalConfiguration.modalKey)}
                                    newDeviceDefinitionCallbackFunction={
                                        modalConfiguration.parametersMap["callbackFunction"]
                                    }
                                />
                            </SternumModal>
                        );
                        break;

                    case ModalType.EditDeviceDefinitionModal:
                        modalElements.push(
                            <SternumModal
                                key={modalConfiguration.modalKey.getStringKey()}
                                modalKey={modalConfiguration.modalKey.getStringKey()}
                                open={modalConfiguration.open}
                                onClose={() => this.props.closeModal(modalConfiguration.modalKey)}
                                showXButton={modalConfiguration.parametersMap["displayXButton"]}
                                showFullScreenButton={false}
                                showBackButton={modalConfiguration.parametersMap["displayBackButton"]}
                                fullscreen={false}
                                stacked={this.calculateIsStacked(modalConfiguration.modalKey)}
                                modalContentClassNames={classes.EditDeviceDefinitionContent}
                                onFullscreenDisplayChanged={(fullscreenDisplay) =>
                                    fullscreenDisplay
                                        ? this.props.enterFullscreenModal(modalConfiguration.modalKey)
                                        : this.props.exitFullscreenModal(modalConfiguration.modalKey)
                                }
                            >
                                <DeviceDefinitionEditModal
                                    fullScreenDisplay={modalConfiguration.fullScreen}
                                    deviceDefinitionVersionId={
                                        modalConfiguration.parametersMap["deviceDefinitionVersionId"]
                                    }
                                    closeModal={() => this.props.closeModal(modalConfiguration.modalKey)}
                                    updateDeviceDefinitionCallbackFunction={
                                        modalConfiguration.parametersMap["callbackFunction"]
                                    }
                                />
                            </SternumModal>
                        );
                        break;

                    case ModalType.ChangePasswordModal:
                        modalElements.push(
                            <SternumModal
                                key={modalConfiguration.modalKey.getStringKey()}
                                modalKey={modalConfiguration.modalKey.getStringKey()}
                                open={modalConfiguration.open}
                                onClose={() => this.props.closeModal(modalConfiguration.modalKey)}
                                showXButton={modalConfiguration.parametersMap["displayXButton"]}
                                showFullScreenButton={false}
                                showBackButton={modalConfiguration.parametersMap["displayBackButton"]}
                                fullscreen={false}
                                stacked={this.calculateIsStacked(modalConfiguration.modalKey)}
                                modalContentClassNames={classes.changePasswordModalContent}
                                onFullscreenDisplayChanged={(fullscreenDisplay) =>
                                    fullscreenDisplay
                                        ? this.props.enterFullscreenModal(modalConfiguration.modalKey)
                                        : this.props.exitFullscreenModal(modalConfiguration.modalKey)
                                }
                            >
                                <ChangePasswordModal
                                    fullScreenDisplay={modalConfiguration.fullScreen}
                                    closeModal={() => this.props.closeModal(modalConfiguration.modalKey)}
                                />
                            </SternumModal>
                        );
                        break;
                    case ModalType.AlertDescriptionModal:
                        modalElements.push(
                            <SternumModal
                                key={modalConfiguration.modalKey.getStringKey()}
                                modalKey={modalConfiguration.modalKey.getStringKey()}
                                open={modalConfiguration.open}
                                onClose={() => this.props.closeModal(modalConfiguration.modalKey)}
                                showXButton={true}
                                showFullScreenButton={false}
                                showBackButton={false}
                                fullscreen={false}
                                stacked={this.calculateIsStacked(modalConfiguration.modalKey)}
                                modalContentClassNames={classes.alertDisplayContent}
                                onFullscreenDisplayChanged={(fullscreenDisplay) =>
                                    fullscreenDisplay
                                        ? this.props.enterFullscreenModal(modalConfiguration.modalKey)
                                        : this.props.exitFullscreenModal(modalConfiguration.modalKey)
                                }
                            >
                                <AlertDescriptionModal
                                    alertId={modalConfiguration.parametersMap["alertId"]}
                                    deviceDefinitionVersionId={
                                        modalConfiguration.parametersMap["deviceDefinitionVersionId"]
                                    }
                                    createdFrom={modalConfiguration.parametersMap["createdFrom"]}
                                    createdTo={modalConfiguration.parametersMap["createdTo"]}
                                    deviceDefinitionInfo={modalConfiguration.parametersMap["deviceDefinitionInfo"]}
                                    alertDisplayName={modalConfiguration.parametersMap["alertDisplayName"]}
                                    fullScreenDisplay={false}
                                />
                            </SternumModal>
                        );
                        break;
                    case ModalType.DeviceDefinitionLocationModal:
                        modalElements.push(
                            <SternumModal
                                key={modalConfiguration.modalKey.getStringKey()}
                                modalKey={modalConfiguration.modalKey.getStringKey()}
                                open={modalConfiguration.open}
                                onClose={() => this.props.closeModal(modalConfiguration.modalKey)}
                                showXButton={true}
                                showFullScreenButton={false}
                                showBackButton={false}
                                fullscreen={false}
                                stacked={this.calculateIsStacked(modalConfiguration.modalKey)}
                                modalContentClassNames={classes.mapContent}
                                onFullscreenDisplayChanged={(fullscreenDisplay) =>
                                    fullscreenDisplay
                                        ? this.props.enterFullscreenModal(modalConfiguration.modalKey)
                                        : this.props.exitFullscreenModal(modalConfiguration.modalKey)
                                }
                            >
                                <DeviceDefinitionLocationModal
                                    entityId={modalConfiguration.parametersMap["entityId"]}
                                    createdFrom={modalConfiguration.parametersMap["createdFrom"]}
                                    createdTo={modalConfiguration.parametersMap["createdTo"]}
                                    title={modalConfiguration.parametersMap["title"]}
                                    fullScreenDisplay={false}
                                />
                            </SternumModal>
                        );
                        break;

                    case ModalType.DeviceLocationModal:
                        modalElements.push(
                            <SternumModal
                                key={modalConfiguration.modalKey.getStringKey()}
                                modalKey={modalConfiguration.modalKey.getStringKey()}
                                open={modalConfiguration.open}
                                onClose={() => this.props.closeModal(modalConfiguration.modalKey)}
                                showXButton={true}
                                showFullScreenButton={false}
                                showBackButton={false}
                                fullscreen={false}
                                stacked={this.calculateIsStacked(modalConfiguration.modalKey)}
                                modalContentClassNames={classes.mapContent}
                                onFullscreenDisplayChanged={(fullscreenDisplay) =>
                                    fullscreenDisplay
                                        ? this.props.enterFullscreenModal(modalConfiguration.modalKey)
                                        : this.props.exitFullscreenModal(modalConfiguration.modalKey)
                                }
                            >
                                <DeviceLocationModal
                                    entityId={modalConfiguration.parametersMap["entityId"]}
                                    createdFrom={modalConfiguration.parametersMap["createdFrom"]}
                                    createdTo={modalConfiguration.parametersMap["createdTo"]}
                                    title={modalConfiguration.parametersMap["title"]}
                                    fullScreenDisplay={false}
                                    deviceDefinition={modalConfiguration.parametersMap["deviceDefinition"]}
                                    deviceDefinitionVersionId={
                                        modalConfiguration.parametersMap["deviceDefinitionVersionId"]
                                    }
                                />
                            </SternumModal>
                        );
                        break;

                    case ModalType.GoProModal:
                        modalElements.push(
                            <GoProDialog
                                key={modalConfiguration.modalKey.getStringKey()}
                                open={modalConfiguration.open}
                                onCancelClick={() => this.props.closeModal(modalConfiguration.modalKey)}
                            />
                        );
                        break;

                    case ModalType.SimulateAttackModal:
                        modalElements.push(
                            <SimulateAttackDialog
                                key={modalConfiguration.modalKey.getStringKey()}
                                open={modalConfiguration.open}
                                onCancelClick={() => this.props.closeModal(modalConfiguration.modalKey)}
                            />
                        );
                        break;

                    case ModalType.GetSupportModal:
                        modalElements.push(
                            <SternumModal
                                key={modalConfiguration.modalKey.getStringKey()}
                                modalKey={modalConfiguration.modalKey.getStringKey()}
                                open={modalConfiguration.open}
                                onClose={() => this.props.closeModal(modalConfiguration.modalKey)}
                                showXButton={true}
                                showFullScreenButton={false}
                                showBackButton={false}
                                fullscreen={false}
                                stacked={this.calculateIsStacked(modalConfiguration.modalKey)}
                                onFullscreenDisplayChanged={(fullscreenDisplay) =>
                                    fullscreenDisplay
                                        ? this.props.enterFullscreenModal(modalConfiguration.modalKey)
                                        : this.props.exitFullscreenModal(modalConfiguration.modalKey)
                                }
                            >
                                <GetSupportModal onSuccess={modalConfiguration.parametersMap["onSuccess"]} />
                            </SternumModal>
                        );
                        break;

                    case ModalType.InteractiveCoverageModal:
                        modalElements.push(
                            <InteractiveCoverageDialog
                                key={modalConfiguration.modalKey.getStringKey()}
                                open={modalConfiguration.open}
                                onClose={() => this.props.closeModal(modalConfiguration.modalKey)}
                                initial={modalConfiguration.parametersMap}
                            />
                        );
                        break;
                }
            }
        }

        return <React.Fragment>{modalElements}</React.Fragment>;
    }

    /**
     * Calculates the is stacked property for a given modal.
     */
    private calculateIsStacked(modalKey: ModalKey): boolean {
        let stacked = false;

        if (this.props.modalIdToConfigurationMap[modalKey.getStringKey()]) {
            // Getting the index of current modal from open stack.
            let modalIndex = Utils.findFirstIndex(
                this.props.openModalsStack,
                (key) => key.getStringKey() === modalKey.getStringKey()
            );

            if (modalIndex - 1 >= 0) {
                // If underneath index is legal.
                let underneathModalKey: ModalKey = this.props.openModalsStack[modalIndex - 1];
                if (!this.props.modalIdToConfigurationMap[underneathModalKey.getStringKey()].fullScreen) {
                    // If underneath modal is not a fullscreen modal, we're stacked.
                    stacked = true;
                }
            }
        }
        return stacked;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(modalsStyle)(Modals));
