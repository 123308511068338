import React, { useContext } from "react";
import { TooltipRenderProps } from "react-joyride";
import clsx from "clsx";
import { Portal, Typography } from "@material-ui/core";

import { Button } from "../../SUI/Button";
import { Pager } from "../Pager";
import { AnomalyIsDemoDataTagVisibleContext, StepActionContext } from "../StepAction.context";
import { useTooltipStyles } from "./Tooltip.style";

export function Tooltip({
    continuous,
    index,
    step,
    // backProps,
    // closeProps,
    primaryProps,
    tooltipProps,
    size,
    isLastStep,
    skipProps,
}: TooltipRenderProps) {
    const style = useTooltipStyles();
    const setStep = useContext(StepActionContext);
    const isDemoDataTagVisible = useContext(AnomalyIsDemoDataTagVisibleContext);

    return (
        <div className={style.body} {...tooltipProps}>
            {isDemoDataTagVisible && (
                <Portal>
                    <div className={style.demoDataTag}>Demo data</div>
                </Portal>
            )}
            {step.title && (
                <Typography variant="h5" className={style.title}>
                    {step.title}
                </Typography>
            )}
            <div className={style.content}>{step.content}</div>
            <div className={style.footer}>
                <div>
                    <Pager total={size} page={index} onPageChange={(page) => setStep(page)} />
                </div>
                {continuous && (
                    <div className={style.buttons}>
                        {!isLastStep && (
                            <Button
                                {...skipProps}
                                variant="pinkTextWithoutBorder"
                                className={clsx(style.button, style.skipButton)}
                            >
                                Skip guide
                            </Button>
                        )}
                        <Button {...primaryProps} className={style.button}>
                            {!isLastStep ? "Next" : "Finish"}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}
