export enum EmailNotificationType {
    WeeklyReport = "WeeklyReportV2",
}

export interface EmailNotificationSettings {
    id: EmailNotificationType;
    subscribed: boolean;

    // not used
    // title: string;
    // description: string;
    // warnings: string[];
}
