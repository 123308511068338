import React from "react";
import classNames from "classnames";

import { SternumLongLogoMultiColorIcon } from "../SUI/SternumIcon";
import { useStyle } from "./SignupContainer.style";

export interface SignupContainerProps {
    className?: string;
    showHeader?: boolean;
    showBackgroundDecoration?: boolean;
    children?: React.ReactNode;
}

export function SignupContainer({
    className,
    showHeader = true,
    showBackgroundDecoration = true,
    children,
}: SignupContainerProps) {
    const classes = useStyle();

    return (
        <div
            className={classNames(classes.container, className)}
            data-show-background-decoration={showBackgroundDecoration}
        >
            {showBackgroundDecoration && (
                <div className={classes.backgroundRectangleContainer}>
                    <div className={classes.backgroundRectangle} />
                </div>
            )}

            {showHeader && (
                <div className={classes.header}>
                    <SternumLongLogoMultiColorIcon width={143} height={24} />
                </div>
            )}

            <div className={classNames(classes.content)}>{children}</div>
        </div>
    );
}
