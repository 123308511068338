import { Theme } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import { sternumTheme } from "../App";
import commonStyle from "../CommonStyle";

let explanationComponentStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        popover: {
            pointerEvents: "none",
        },
        positiveKeyMetric: {
            color: sternumTheme.success.main,
        },
        negativeKeyMetric: {
            color: sternumTheme.danger.main,
        },
        metricDeltaArrowIcon: {
            fontSize: "12px",
            marginRight: theme.spacing(-1),
        },
        metricDeltaValue: {
            marginLeft: theme.spacing(1),
        },
        metricValue: {
            display: "flex",
            justifyContent: "flex-end",
            width: "10%",
        },
        explanationTypography: {
            width: theme.spacing(40),
        },
        infoCircleIconSize: {
            fontSize: "16px",
        },
        popoverContentContainer: {
            width: theme.spacing(60),
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
    });

export default explanationComponentStyle;
