import HttpResponseInterceptor from "./HttpResponseInterceptor";

/**
 * Interceptor that intercepts responses and if they are with status 401 unauthorized, redirect to login page.
 */
class AuthenticationHttpResponseInterceptor implements HttpResponseInterceptor {
    /**
     * Constructor.
     */
    constructor(private onUnauthorizedCallback: () => void) {}

    /**
     * Intercepts response messages to check if they are of 401 unauthorised and redirect to login page.
     */
    intercept(response) {
        return response;
    }

    /**
     * Intercepts response messages that received error codes as response.
     */
    error(error) {
        // If we received 401, that means we're unauthorised, we call the onUnauthorizedCallback callback.
        if (this.onUnauthorizedCallback && error && error.response && error.response.status === 401) {
            this.onUnauthorizedCallback();
        }

        return Promise.reject(error);
    }
}

export default AuthenticationHttpResponseInterceptor;
