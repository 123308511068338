import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../../CommonStyle";

const traceDefinitionSimplseDialogStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: {
            paddingTop: theme.spacing(5),
            paddingRight: theme.spacing(5),
            paddingBottom: theme.spacing(5),
            paddingLeft: theme.spacing(5),
        },
    });

export default traceDefinitionSimplseDialogStyle;
