import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import ReceivedDefinitionsResponse from "../../lib/services/events/ReceivedDefinitionsResponse";
import ServiceWire from "../../lib/services/ServiceWire";
import DeviceDefinitionVersionInfo from "../../lib/state/DeviceDefinitionVersionInfo";
import { GlobalState } from "../../lib/state/GlobalState";
import ServerEntityType from "../../lib/state/ServerEntityType";
import VisualisationDataSourceGroupBy from "../../lib/state/Visualisation/VisualisationConfigurationGroupBy";
import VisualisationInfo from "../../lib/state/Visualisation/VisualisationInfo";
import VisualisationType from "../../lib/state/Visualisation/VisualisationType";
import GraphLoader from "../SUI/SternumLoaders/GraphLoader";
import UIDataVisualisationConfiguration from "../VisualisationConfigurationComponent/entities/UIDataVisualisationConfiguration";
import { isChangedDataSourceConfiguration } from "../VisualisationConfigurationComponent/utils/isChangedDataSourceConfiguration";
import VisualisationConfigurationPresentationGeoMap from "./VisualisationConfigurationPresentationGeoMap/VisualisationConfigurationPresentationGeoMap";
import VisualisationConfigurationPresentationMetric from "./VisualisationConfigurationPresentationMetric/VisualisationConfigurationPresentationMetric";
import VisualisationConfigurationPresentationPieChart from "./VisualisationConfigurationPresentationPieChart/VisualisationConfigurationPresentationPieChart";
import visualisationConfigurationPresentationStyle from "./VisualisationConfigurationPresentationStyle";
import VisualisationConfigurationPresentationTimeSeries from "./VisualisationConfigurationPresentationTimeSeries/VisualisationConfigurationPresentationTimeSeries";
import VisualisationConfigurationPresentationValuesGraph from "./VisualisationConfigurationPresentationValuesGraph/VisualisationConfigurationPresentationValuesGraph";
import VisualisationConfigurationPresentationValuesTable from "./VisualisationConfigurationPresentationValuesTable/VisualisationConfigurationPresentationValuesTable";

const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

interface AppState {
    loadingVisualisation: boolean;
    errorLoadingVisualisation: boolean;
    visualisation: VisualisationInfo;

    isDisabled: boolean;

    visualisationType?: VisualisationType;
    dataVisualisationConfigurations?: UIDataVisualisationConfiguration[];

    isStacked: boolean;
}

export interface AppProps extends WithStyles<typeof visualisationConfigurationPresentationStyle> {
    lookedUpEntityId: string;
    displayingInDashboard?: boolean;
    updated?: number;
    startTime?: Date;
    endTime?: Date;
    visualisationType?: VisualisationType;
    dataVisualisationConfigurations?: UIDataVisualisationConfiguration[];
    receivedDefinitionsResponse: Record<string, ReceivedDefinitionsResponse>;
    groupBy: VisualisationDataSourceGroupBy;
    outline: boolean;

    amountOfLoaders: number;
    amountOfGraphPointsToDisplay: number;

    onVisualizationClicked: () => void;

    isStacked: boolean;
    isLogarithmicScale?: boolean;

    hideTableToolbar?: boolean;
    denseTable?: boolean;
    dashboardDeviceDefinitionVersions: DeviceDefinitionVersionInfo[];
    displayTooltipDataPerDeviceDefinitionVersion?: boolean;
    refreshComponentId?: number;
}

class VisualisationConfigurationPresentation extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            loadingVisualisation: false,
            errorLoadingVisualisation: false,
            visualisation: undefined,

            visualisationType: this.props.visualisationType,
            dataVisualisationConfigurations: this.props.dataVisualisationConfigurations,

            isDisabled: !ServiceWire.getAuthorizationService().canEdit(ServerEntityType.VISUALIZATION),
            isStacked: false,
        };
    }

    async UNSAFE_componentWillReceiveProps(nextProps: Readonly<AppProps>, nextContext: any) {
        if (this.props.isStacked != nextProps.isStacked) {
            this.setState({ isStacked: nextProps.isStacked });
        }

        const nextDataVisualisationConfigurations: UIDataVisualisationConfiguration[] =
            nextProps.dataVisualisationConfigurations || [];

        const changedDataSource =
            (nextDataVisualisationConfigurations &&
                this.props.dataVisualisationConfigurations &&
                nextDataVisualisationConfigurations.length !== this.props.dataVisualisationConfigurations.length) ||
            nextDataVisualisationConfigurations.some(
                (newConfiguration, index) =>
                    !this.props.dataVisualisationConfigurations[index] ||
                    isChangedDataSourceConfiguration(
                        newConfiguration,
                        this.props.dataVisualisationConfigurations[index]
                    )
            );

        if (
            changedDataSource ||
            nextProps.visualisationType !== this.props.visualisationType ||
            !isEqual(this.props.groupBy, nextProps.groupBy)
        ) {
            this.setState({
                dataVisualisationConfigurations: nextProps.dataVisualisationConfigurations,
                visualisationType: nextProps.visualisationType,
            });
        }
    }

    render() {
        const { classes } = this.props;

        if (this.state.loadingVisualisation || this.state.errorLoadingVisualisation) {
            return (
                <div className={classNames(classes.loadingContainer)}>
                    <GraphLoader />
                </div>
            );
        } else {
            return (
                <>
                    {this.state.visualisationType === VisualisationType.TIME_SERIES && (
                        <div
                            className={classNames(
                                !this.state.isDisabled && this.props.displayingInDashboard,
                                classes.graphContainer,
                                this.props.outline && "mod-outline",
                                !this.props.outline && "mod-inline"
                            )}
                        >
                            <VisualisationConfigurationPresentationTimeSeries
                                refreshComponentId={this.props.refreshComponentId}
                                entityId={this.props.lookedUpEntityId}
                                receivedDefinitionsResponse={this.props.receivedDefinitionsResponse}
                                startTime={this.props.startTime ? this.props.startTime.getTime() : null}
                                endTime={this.props.endTime ? this.props.endTime.getTime() : null}
                                dataVisualisationConfigurations={this.state.dataVisualisationConfigurations}
                                deviceDefinitionVersions={this.props.dashboardDeviceDefinitionVersions}
                                groupBy={this.props.groupBy}
                                amountOfLoaders={this.props.amountOfLoaders}
                                amountOfGraphPointsToDisplay={this.props.amountOfGraphPointsToDisplay}
                                isStacked={this.props.isStacked}
                                isLogarithmicScale={this.props.isLogarithmicScale}
                                displayTooltipDataPerDeviceDefinitionVersion={
                                    this.props.displayTooltipDataPerDeviceDefinitionVersion
                                }
                            />
                        </div>
                    )}

                    {this.state.visualisationType === VisualisationType.METRIC && (
                        <div
                            className={classNames(
                                !this.state.isDisabled && this.props.displayingInDashboard,
                                classes.metricContainer,
                                this.props.outline && "mod-outline",
                                !this.props.outline && "mod-inline"
                            )}
                        >
                            <VisualisationConfigurationPresentationMetric
                                refreshComponentId={this.props.refreshComponentId}
                                entityId={this.props.lookedUpEntityId}
                                time={{
                                    startTime: this.props.startTime ? this.props.startTime.getTime() : null,
                                    endTime: this.props.endTime ? this.props.endTime.getTime() : null,
                                }}
                                dataVisualisationConfigurations={this.state.dataVisualisationConfigurations}
                                isCursorAllowed={this.props.displayingInDashboard && !this.state.isDisabled}
                            />
                        </div>
                    )}

                    {this.state.visualisationType === VisualisationType.VALUES_GRAPH && (
                        <div
                            className={classNames(
                                !this.state.isDisabled && this.props.displayingInDashboard,
                                classes.graphContainer,
                                this.props.outline && "mod-outline",
                                !this.props.outline && "mod-inline"
                            )}
                        >
                            <VisualisationConfigurationPresentationValuesGraph
                                refreshComponentId={this.props.refreshComponentId}
                                entityId={this.props.lookedUpEntityId}
                                startTime={this.props.startTime ? this.props.startTime.getTime() : null}
                                endTime={this.props.endTime ? this.props.endTime.getTime() : null}
                                dataVisualisationConfigurations={this.state.dataVisualisationConfigurations}
                                deviceDefinitionVersions={this.props.dashboardDeviceDefinitionVersions}
                                groupBy={this.props.groupBy}
                                receivedDefinitionsResponse={this.props.receivedDefinitionsResponse}
                                isLogarithmicScale={this.props.isLogarithmicScale}
                                displayTooltipDataPerDeviceDefinitionVersion={
                                    this.props.displayTooltipDataPerDeviceDefinitionVersion
                                }
                            />
                        </div>
                    )}

                    {this.state.visualisationType === VisualisationType.PIE_CHART && (
                        <div
                            className={classNames(
                                !this.state.isDisabled && this.props.displayingInDashboard,
                                classes.graphContainer,
                                this.props.outline && "mod-outline",
                                !this.props.outline && "mod-inline"
                            )}
                        >
                            <VisualisationConfigurationPresentationPieChart
                                refreshComponentId={this.props.refreshComponentId}
                                entityId={this.props.lookedUpEntityId}
                                startTime={this.props.startTime ? this.props.startTime.getTime() : null}
                                endTime={this.props.endTime ? this.props.endTime.getTime() : null}
                                dataVisualisationConfigurations={this.state.dataVisualisationConfigurations}
                                deviceDefinitionVersions={this.props.dashboardDeviceDefinitionVersions}
                                groupBy={this.props.groupBy}
                                receivedDefinitionsResponse={this.props.receivedDefinitionsResponse}
                                displayTooltipDataPerDeviceDefinitionVersion={
                                    this.props.displayTooltipDataPerDeviceDefinitionVersion
                                }
                            />
                        </div>
                    )}

                    {this.state.visualisationType === VisualisationType.VALUES_TABLE && (
                        <div
                            className={classNames(
                                !this.state.isDisabled && this.props.displayingInDashboard,
                                classes.graphContainer,
                                "mod-no-padding",
                                this.props.outline && "mod-outline",
                                !this.props.outline && "mod-inline"
                            )}
                        >
                            <VisualisationConfigurationPresentationValuesTable
                                refreshComponentId={this.props.refreshComponentId}
                                entityId={this.props.lookedUpEntityId}
                                startTime={this.props.startTime ? this.props.startTime.getTime() : null}
                                endTime={this.props.endTime ? this.props.endTime.getTime() : null}
                                dataVisualisationConfigurations={this.state.dataVisualisationConfigurations}
                                deviceDefinitionVersions={this.props.dashboardDeviceDefinitionVersions}
                                groupBy={this.props.groupBy}
                                hideToolbar={this.props.hideTableToolbar}
                                denseTable={this.props.denseTable}
                            />
                        </div>
                    )}

                    {this.state.visualisationType === VisualisationType.GEOMAP && (
                        <div
                            className={classNames(
                                !this.state.isDisabled && this.props.displayingInDashboard,
                                classes.graphContainer,
                                "full-height"
                            )}
                        >
                            <VisualisationConfigurationPresentationGeoMap
                                refreshComponentId={this.props.refreshComponentId}
                                entityId={this.props.lookedUpEntityId}
                                startTime={this.props.startTime ? this.props.startTime.getTime() : null}
                                endTime={this.props.endTime ? this.props.endTime.getTime() : null}
                                dataVisualisationConfigurations={this.state.dataVisualisationConfigurations}
                                receivedDefinitionsResponse={this.props.receivedDefinitionsResponse}
                                deviceDefinitionVersions={this.props.dashboardDeviceDefinitionVersions}
                                displayTooltipDataPerDeviceDefinitionVersion={
                                    this.props.displayTooltipDataPerDeviceDefinitionVersion
                                }
                            />
                        </div>
                    )}
                </>
            );
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(visualisationConfigurationPresentationStyle, { withTheme: true })(VisualisationConfigurationPresentation));
