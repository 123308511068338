import { makeStyles } from "@material-ui/core";

export const useSternumTooltipStyle = makeStyles((theme) => ({
    tooltip: {
        background: "#1B1B1B",
        fontSize: 12,
        padding: 10,
        color: "#FBFDFF",
        borderRadius: 8,
    },

    arrow: {
        color: "#1B1B1B",
    },
}));
