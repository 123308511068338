import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@material-ui/core";
import classNames from "classnames";
import * as React from "react";
import { CrossIcon } from "../../../SUI/SternumIcon";
import { InstallationStep } from "./InstallationStep";
import useSimulateAttackDialogStyle from "./SimulateAttackDialog.style";
import { UsageStep } from "./UsageStep";

export interface GoProDialogProps {
    open: boolean;
    onCancelClick: () => void;
}

export enum SimulateAttackSetupStep {
    Installation,
    Usage,
}

export function SimulateAttackDialog(props: GoProDialogProps) {
    const classes = useSimulateAttackDialogStyle();

    const [currentSetupStep, setCurrentSetupStep] = React.useState(SimulateAttackSetupStep.Installation);

    const handleStepNextClick = (step: SimulateAttackSetupStep) => {
        setCurrentSetupStep(step + 1);
    };

    const handleStepBackClick = (step: SimulateAttackSetupStep) => {
        setCurrentSetupStep(step - 1);
    };

    if (!open) {
        return null;
    }

    return (
        <Dialog
            aria-labelledby="dialog-title"
            open={props.open}
            onClose={props.onCancelClick}
            disableEnforceFocus
            classes={{
                root: classes.root,
                paper: classes.paper,
            }}
        >
            {/* Title */}
            <DialogTitle id="dialog-title">
                <div className={classNames(classes.title)}>
                    <Typography variant={"h6"} className={classNames(classes.extraBold)}>
                        Learn how to use Attack Simulation Kit
                    </Typography>
                    <IconButton onClick={props.onCancelClick}>
                        <CrossIcon color="#909090" />
                    </IconButton>
                </div>
            </DialogTitle>

            {/* Body */}
            <DialogContent>
                <div className={classes.stepsContainer}>
                    <InstallationStep
                        step={SimulateAttackSetupStep.Installation}
                        stepNumber={1}
                        onNextClick={handleStepNextClick}
                        onSkipClick={handleStepNextClick}
                        active={currentSetupStep === SimulateAttackSetupStep.Installation}
                        className={
                            currentSetupStep === SimulateAttackSetupStep.Installation
                                ? classes.activeStep
                                : classes.inactiveStep
                        }
                        completed={currentSetupStep > SimulateAttackSetupStep.Installation}
                    />
                    <UsageStep
                        step={SimulateAttackSetupStep.Usage}
                        stepNumber={2}
                        onBackClick={handleStepBackClick}
                        active={currentSetupStep === SimulateAttackSetupStep.Usage}
                        className={
                            currentSetupStep === SimulateAttackSetupStep.Usage
                                ? classes.activeStep
                                : classes.inactiveStep
                        }
                        completed={currentSetupStep > SimulateAttackSetupStep.Usage}
                    />
                </div>
            </DialogContent>

            <DialogActions className={classNames(classes.actions)} />
        </Dialog>
    );
}
