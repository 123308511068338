import { MenuItem, Typography, withStyles } from "@material-ui/core";
import { WithStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import ConditionType from "../../../lib/state/ConditionType";
import { GlobalState } from "../../../lib/state/GlobalState";
import SpecificBehaviorDefinition from "../../../lib/state/triggers/SpecificBehaviorDefinition";
import CustomInput from "../../../shared_components/CustomInput/CustomInput";
import SelectComponent from "../../DeviceDefinitionComponents/SelectComponent/SelectComponent";
import specificBehaviorTriggerStyle from "./SpecificBehaviorTriggerStyle";
interface AppState {
    selectedTrace: any;
    selectedArgument: any;
    condition: string;
    value: string;
}

export interface AppProps extends WithStyles<typeof specificBehaviorTriggerStyle> {
    existingDeviceDefinition: any;
    existingSpecificBehaviorDefinition?: SpecificBehaviorDefinition;
    updateFieldsStatus: (isAllFieldsSet: boolean, triggerDefinition: SpecificBehaviorDefinition) => void;
    disabled?: boolean;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {};
};

const conditions: { value: ConditionType; displayName: string }[] = [
    { value: ConditionType.EQUALS, displayName: "Equals" },
    { value: ConditionType.NOT_EQUALS, displayName: "Not Equals" },
    { value: ConditionType.GREATER_THAN, displayName: "Greater Than" },
    { value: ConditionType.GREATER_THAN_OR_EQUALS, displayName: "Greater Than Or Equals" },
    { value: ConditionType.LESS_THAN, displayName: "Less Than" },
    { value: ConditionType.LESS_THAN_OR_EQUALS, displayName: "Less Than Or Equals" },
    { value: ConditionType.DATE_EXPIRED, displayName: "Date Expired" },
];

class SpecificBehaviorTrigger extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);
        // Initializing the state to default.
        if (this.props.existingSpecificBehaviorDefinition) {
            this.state = {
                selectedTrace: this.props.existingSpecificBehaviorDefinition.traceDefinition,
                selectedArgument: this.props.existingSpecificBehaviorDefinition.argumentDefinition,
                condition: this.props.existingSpecificBehaviorDefinition.condition,
                value: this.props.existingSpecificBehaviorDefinition.value,
            };
        } else {
            this.state = {
                selectedTrace: "",
                selectedArgument: "",
                condition: "",
                value: "",
            };
        }
    }

    /**
     * Send data to parent component
     */
    private sendFieldsStatusToParent = () => {
        if (this.validateFields()) {
            let partialTrace;
            if (!this.state.selectedTrace.entityId) {
                partialTrace = {
                    trace_type: this.state.selectedTrace.traceType,
                    trace_event_name: this.state.selectedTrace.traceEventName,
                };
            }

            let partialArgument;
            if (!this.state.selectedArgument.entityId) {
                partialArgument = {
                    trace_type: this.state.selectedArgument.argumentType,
                    argument_event_name: this.state.selectedArgument.argumentEventName,
                };
            }

            const triggerDefinition = new SpecificBehaviorDefinition(
                this.state.selectedTrace,
                this.state.selectedArgument,
                this.state.condition,
                this.state.condition !== ConditionType.DATE_EXPIRED ? this.state.value : "NOW",
                partialTrace,
                partialArgument
            );
            this.props.updateFieldsStatus(true, triggerDefinition);
        } else {
            this.props.updateFieldsStatus(false, null);
        }
    };

    /**
     * Check if all trigger fields are set
     */
    private validateFields = (): boolean => {
        return (
            this.state.selectedTrace !== "" &&
            this.state.selectedArgument !== "" &&
            this.state.condition !== "" &&
            (this.state.condition === "DATE_EXPIRED" || this.state.value !== "")
        );
    };

    /*
     * Handle trace change operation
     */

    private onSelectedTraceChanged = (selectedOption: any) => {
        this.setState(
            {
                selectedTrace: selectedOption.value,
            },
            () => this.sendFieldsStatusToParent()
        );
    };

    private onSelectedArgumentChanged = (selectedOption: any) => {
        this.setState(
            {
                selectedArgument: selectedOption.value,
            },
            () => this.sendFieldsStatusToParent()
        );
    };

    private onSelectedConditionChanged = (event) => {
        this.setState(
            {
                condition: event.target.value,
            },
            () => this.sendFieldsStatusToParent()
        );
    };

    private onValueChanged = (event) => {
        this.setState(
            {
                value: event.target.value,
            },
            () => this.sendFieldsStatusToParent()
        );
    };

    /*
     * Build trace list for dropdown display
     */
    private buildTraceSelectList = () => {
        let traceSelectOption = [];
        this.props.existingDeviceDefinition.traceDefinitions.map((traceDefinitionIter, index) => {
            if (traceDefinitionIter.displayInUI || traceDefinitionIter.canBeUsedForAlerts) {
                traceSelectOption.push({
                    label: traceDefinitionIter.displayName,
                    value: traceDefinitionIter,
                });
            }
        });
        return traceSelectOption;
    };

    /*
     * Build argument list for dropdown display
     */
    private buildArgumentSelectList = () => {
        let argumentSelectOption = [];
        this.props.existingDeviceDefinition.argumentDefinitions.map((argumentDefinitionIter, index) => {
            if (argumentDefinitionIter.displayInUI || argumentDefinitionIter.canBeUsedForAlerts) {
                argumentSelectOption.push({
                    label: argumentDefinitionIter.displayName,
                    value: argumentDefinitionIter,
                });
            }
        });
        return argumentSelectOption;
    };

    /*
     * Build selected item
     */

    private buildSelectedItem = (selectedItem) => {
        if (selectedItem) {
            return { label: selectedItem.displayName, value: selectedItem };
        }
        return "";
    };

    render() {
        const { classes } = this.props;

        return (
            <div>
                <Typography variant="subtitle2">Specific Behavior Alert</Typography>

                <div className={classNames(classes.flexColumn)}>
                    {/* Trace definition selection*/}
                    <div role="presentation" aria-label="select trace">
                        <SelectComponent
                            name={"traceSelect"}
                            placeHolder={"Select Trace"}
                            onFieldChange={this.onSelectedTraceChanged}
                            selectedValue={this.buildSelectedItem(this.state.selectedTrace)}
                            selectOptions={this.buildTraceSelectList()}
                            isDisabled={this.props.disabled}
                        />
                    </div>

                    {/* Argument definition selection*/}
                    <div role="presentation" aria-label="select argument">
                        <SelectComponent
                            name={"argumentSelect"}
                            placeHolder={"Select Argument"}
                            onFieldChange={this.onSelectedArgumentChanged}
                            selectedValue={this.buildSelectedItem(this.state.selectedArgument)}
                            selectOptions={this.buildArgumentSelectList()}
                            isDisabled={this.props.disabled}
                        />
                    </div>

                    {/* Condition Selection */}
                    <div className={classNames(classes.marginBottomMedium, classes.marginTopMd)}>
                        <CustomInput
                            value={this.state.condition}
                            inputTitle={"Select Condition"}
                            maxHeightClass={""}
                            inputClasses={""}
                            autoFocus={false}
                            onChange={this.onSelectedConditionChanged}
                            required={true}
                            id={"alert-condition"}
                            select={true}
                            disabled={this.props.disabled}
                        >
                            {/* Empty menu item to fix autoselect first element */}
                            <MenuItem style={{ display: "none" }} />

                            {conditions.map((conditionIter, index) => {
                                return (
                                    <MenuItem
                                        aria-label="condition value dropdown menu item"
                                        key={index}
                                        value={conditionIter.value}
                                        className={classNames(classes.selectItem)}
                                    >
                                        {conditionIter.displayName}
                                    </MenuItem>
                                );
                            })}
                        </CustomInput>
                    </div>

                    {/* Value */}
                    {this.state.condition !== ConditionType.DATE_EXPIRED && (
                        <CustomInput
                            value={this.state.value}
                            inputTitle={"Enter Value"}
                            maxHeightClass={""}
                            inputClasses={""}
                            autoFocus={false}
                            onChange={this.onValueChanged}
                            required={true}
                            id={"alert-value"}
                            disabled={this.props.disabled}
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(specificBehaviorTriggerStyle)(SpecificBehaviorTrigger));
