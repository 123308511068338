import moment from "moment";
import TimeRange from "./TimeRange";
import TimeSelectionType from "./TimeSelectionType";
import TimeSelectOption from "./TimeSelectOption";

class TimeSelectOptions {
    /**
     * Constructor.
     */

    static liveTailSelectionOption = new TimeSelectOption("Live Tail", "Live", "", null, TimeSelectionType.LIVE);

    static timeSelectionOptions = [
        new TimeSelectOption("Past Hour", "1h", "h", null, TimeSelectionType.CONST, 1),
        new TimeSelectOption("Past Day", "1d", "d", null, TimeSelectionType.CONST, 1),
        new TimeSelectOption("Past Week", "1w", "w", null, TimeSelectionType.CONST, 1),
        new TimeSelectOption("Past Month", "1m", "M", null, TimeSelectionType.CONST, 1),
        new TimeSelectOption("Past Year", "1y", "y", null, TimeSelectionType.CONST, 1),
        new TimeSelectOption("Select Range", "-", "c", null, TimeSelectionType.CUSTOM),
    ];

    /**
     * Returns whether filter is defined, or all values are nulls.
     */
    public static getTimeOptions() {
        return TimeSelectOptions.timeSelectionOptions;
    }

    public static getTimeRange(unit: string, value: number, options: { startTime?: Date; endTime?: Date } = {}) {
        let range = null;
        switch (unit) {
            case "m": {
                range = moment().subtract(value, unit);
                break;
            }
            case "h": {
                range = moment().subtract(value, unit);
                break;
            }
            case "d": {
                range = moment().subtract(value, unit);
                break;
            }
            case "w": {
                range = moment().subtract(value, unit);
                break;
            }
            case "M": {
                range = moment().subtract(value, unit);
                break;
            }
            case "y": {
                range = moment().subtract(value, unit);
                break;
            }
            case "c": {
                return new TimeRange(moment(options.startTime).toDate(), moment(options.endTime).toDate());
            }
        }

        return new TimeRange(range.toDate(), moment().toDate());
    }

    /**
     *
     * Get TimeSelection Entity by unit type
     */
    public static getTimeSelectionByUnit(unit: string): TimeSelectOption {
        return TimeSelectOptions.getTimeOptions().find((timeSelection) => timeSelection.unit === unit);
    }
}

export default TimeSelectOptions;
