import { makeStyles } from "@material-ui/core";

export const useRemoteViewTabStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexFlow: "column",
        gap: 18,
    },
    header: {
        display: "flex",
        alignItems: "center",
        gap: 8,
    },
    infoIcon: {
        color: "#1B6FDE",
    },
    infoTooltip: {
        background: "#1B1B1B",
        fontSize: "12px",
    },
}));
