import React, { useState } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { Box, Link as MuiLink, Typography } from "@material-ui/core";

import ServiceWire from "../../lib/services/ServiceWire";
import Utils from "../../lib/infra/Utils";
import { Chip } from "../SettingsPage/Chip";
import { SternumInputField } from "../SUI/SternumInputField";
import SternumImprovedButton from "../SUI/SternumImprovedButton/SternumImprovedButton";
import { CheckFilledIcon, CheckOutlinedIcon, DiamondIcon, SternumLongLogoMultiColorIcon } from "../SUI/SternumIcon";
import SternumBanner from "../SUI/SternumBanner/SternumBanner";
import { useCommonStyle } from "../CommonStyle";
import { useStyles } from "./PremiumUpgrade.style";
import { PremiumUpgradeContainer } from "./PremiumUpgradeContainer";

export interface PremiumUpgradePageProps {}

interface FormValues {
    name: string;
    email: string;
    jobTitle: string;
    company: string;
    phone?: string;
    additionalDetails?: string;
}

function PremiumUpgradePage({}: PremiumUpgradePageProps) {
    const classes = useStyles();
    const classesCommon = useCommonStyle();
    const [showSuccessBanner, setShowSuccessBaner] = useState(false);

    const currentUser = ServiceWire.getAuthenticationService().getCachedAuthenticatedUser();
    const currentClient = ServiceWire.getClientsService().getSelectedClient();

    const [errorMsg, setErrorMsg] = useState("");
    const formik = useFormik<FormValues>({
        initialValues: {
            email: currentUser.email,
            company: currentClient.name,
            jobTitle: "",
            name: currentUser.getFullName(),
        },
        initialErrors: {
            jobTitle: "Job title is required",
        },
        validate: (values) => {
            const errors: Partial<FormValues> = {};

            if (!values.name.trim()) {
                errors.name = "Name is required";
            }

            if (!values.email.trim()) {
                errors.email = "Email is required";
            } else if (!Utils.isValidEmail(values.email)) {
                errors.email = "Email is invalid";
            }

            if (!values.company.trim()) {
                errors.company = "Company is required";
            }

            if (!values.jobTitle.trim()) {
                errors.jobTitle = "Job title is required";
            }

            return errors;
        },
        onSubmit: async (values) => {
            try {
                await ServiceWire.getSternumService().requestPremiumUpgrade(
                    values.email,
                    values.name,
                    values.company,
                    values.jobTitle,
                    values.phone,
                    values.additionalDetails
                );

                setShowSuccessBaner(true);
            } catch (err) {
                if (!err?.response?.status) {
                    return setErrorMsg("Internal server error");
                }
            }
        },
    });

    const isSubmitButtonDisabled = formik.isSubmitting || Object.keys(formik.errors).length > 0;

    return (
        <PremiumUpgradeContainer>
            <div className={classes.content}>
                <div className={classes.innerContainer}>
                    <div className={classes.leftContent}>
                        <h1 className={classes.mainTitle}>
                            Upgrade your Sternum Device Security and Insights <br /> Platform
                        </h1>

                        <p className={classes.trialDescription}>
                            Get a fully-featured license to access advanced features and capabilities, including:
                        </p>

                        <div className={classes.featuresContainer}>
                            <div className={classes.featureItem}>
                                <CheckFilledIcon />
                                <div className={classes.featureText}>Protect unlimited devices</div>
                            </div>
                            <div className={classes.featureItem}>
                                <CheckFilledIcon />
                                <div className={classes.featureText}>
                                    Protect RTOS, bare metal and other Linux devices
                                </div>
                            </div>
                            <div className={classes.featureItem}>
                                <CheckFilledIcon />
                                <div className={classes.featureText}>Never miss a critical alert</div>
                            </div>
                            <div className={classes.featureItem}>
                                <CheckFilledIcon />
                                <div className={classes.featureText}>Deep & enhanced real-time analytics</div>
                            </div>
                            <div className={classes.featureItem}>
                                <CheckFilledIcon />
                                <div className={classes.featureText}>Autonomous anomaly detection</div>
                            </div>
                            <div className={classes.featureItem}>
                                <CheckFilledIcon />
                                <div className={classes.featureText}>Advanced and custom insights</div>
                            </div>
                            <div className={classes.featureItem}>
                                <CheckFilledIcon />
                                <div className={classes.featureText}>Third-party management</div>
                            </div>
                            <div className={classes.featureItem}>
                                <CheckFilledIcon />
                                <div className={classes.featureText}>Device fleet management</div>
                            </div>
                            <div className={classes.featureItem}>
                                <CheckFilledIcon />
                                <div className={classes.featureText}>Flexibility to fit any workflow</div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.rightContent}>
                        {showSuccessBanner ? (
                            <div className={classes.successContainer}>
                                <CheckOutlinedIcon />
                                <Typography className={classes.successTitle}>
                                    A member of our team will get back to you shortly!
                                </Typography>
                                <MuiLink
                                    className={classNames(classes.successBackLink, classesCommon.cursorPointer)}
                                    component={Link}
                                    variant="body2"
                                    to="/dashboard"
                                >
                                    Back to Dashboard
                                </MuiLink>
                            </div>
                        ) : (
                            <div>
                                <div className={classes.formHeader}>
                                    <SternumLongLogoMultiColorIcon />
                                    <Chip>
                                        <DiamondIcon /> Premium
                                    </Chip>
                                </div>

                                <div className={classNames(classes.authFieldsContainer)}>
                                    {errorMsg && (
                                        <div
                                            className={classNames(
                                                classesCommon.marginBottomXLarge,
                                                classesCommon.fullWidth
                                            )}
                                        >
                                            <SternumBanner
                                                messageType="error"
                                                message={errorMsg}
                                                onCloseClick={() => setErrorMsg("")}
                                            />
                                        </div>
                                    )}

                                    {/* Full name input */}
                                    <SternumInputField
                                        label={"Full name"}
                                        name="name"
                                        inputValue={formik.values.name}
                                        onFieldChange={formik.handleChange}
                                        isTouched={!!formik.touched.name}
                                        error={!!formik.errors.name}
                                        helperText={formik.touched.name ? formik.errors.name : undefined}
                                        onTouch={() => formik.setFieldTouched("name", true)}
                                        required
                                    />

                                    {/* Email input */}
                                    <SternumInputField
                                        label={"Business email"}
                                        name="email"
                                        inputValue={formik.values.email}
                                        onFieldChange={formik.handleChange}
                                        isTouched={!!formik.touched.email}
                                        error={!!formik.errors.email}
                                        helperText={formik.touched.email ? formik.errors.email : undefined}
                                        onTouch={() => formik.setFieldTouched("email", true)}
                                        required
                                    />

                                    {/* Phone input */}
                                    <SternumInputField
                                        label={"Phone number"}
                                        name="phone"
                                        inputValue={formik.values.phone}
                                        onFieldChange={formik.handleChange}
                                        isTouched={!!formik.touched.phone}
                                        error={!!formik.errors.phone}
                                        helperText={formik.touched.phone ? formik.errors.phone : undefined}
                                        onTouch={() => formik.setFieldTouched("phone", true)}
                                        required={false}
                                    />

                                    {/* Company input */}
                                    <SternumInputField
                                        label={"Company name"}
                                        name="company"
                                        inputValue={formik.values.company}
                                        onFieldChange={formik.handleChange}
                                        isTouched={!!formik.touched.company}
                                        error={!!formik.errors.company}
                                        helperText={formik.touched.company ? formik.errors.company : undefined}
                                        onTouch={() => formik.setFieldTouched("company", true)}
                                        required
                                    />

                                    {/* Job title input */}
                                    <SternumInputField
                                        label={"Job title"}
                                        name="jobTitle"
                                        inputValue={formik.values.jobTitle}
                                        onFieldChange={formik.handleChange}
                                        isTouched={!!formik.touched.jobTitle}
                                        error={!!formik.errors.jobTitle}
                                        helperText={formik.touched.jobTitle ? formik.errors.jobTitle : undefined}
                                        onTouch={() => formik.setFieldTouched("jobTitle", true)}
                                        required
                                    />

                                    {/* Additional details input */}
                                    <SternumInputField
                                        label={"Additional details"}
                                        name="additionalDetails"
                                        inputValue={formik.values.additionalDetails}
                                        onFieldChange={formik.handleChange}
                                        isTouched={!!formik.touched.additionalDetails}
                                        error={!!formik.errors.additionalDetails}
                                        helperText={
                                            formik.touched.additionalDetails
                                                ? formik.errors.additionalDetails
                                                : undefined
                                        }
                                        onTouch={() => formik.setFieldTouched("additionalDetails", true)}
                                        required={false}
                                    />

                                    <Box mt={3}>
                                        <SternumImprovedButton
                                            buttonType="regularWithDisabled"
                                            content="Send"
                                            fullWidth={false}
                                            onClick={formik.handleSubmit}
                                            isDisabled={isSubmitButtonDisabled}
                                            isLoading={formik.isSubmitting}
                                        />
                                    </Box>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </PremiumUpgradeContainer>
    );
}

export const PremiumUpgrade: React.FC<PremiumUpgradePageProps> = PremiumUpgradePage;
