import { Theme } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import { sternumTheme } from "../App";
import commonStyle from "../CommonStyle";

let librariesMetricsDataTileStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        positiveKeyMetric: {
            color: sternumTheme.success.main,
        },
        negativeKeyMetric: {
            color: sternumTheme.danger.main,
        },
        metricDeltaArrowIcon: {
            fontSize: "12px",
            marginRight: -1 * theme.spacing(1),
        },
        metricDeltaValue: {
            marginLeft: theme.spacing(1),
        },
        metricValue: {
            textAlign: "right",
            fontSize: 14,
        },
        infoCircleIconSize: {
            fontSize: "16px",
        },
        explanationTypography: {
            width: theme.spacing(40),
        },

        metricContainer: {
            padding: theme.spacing(3, 2),
        },

        divider: {
            background: "rgba(149, 149, 149, 0.1)",
        },

        metricTitle: {
            fontSize: 14,
        },
    });

export default librariesMetricsDataTileStyle;
