import SternumRole from "./SternumRole";

/**
 * Represents invite user data
 */
class InviteUserInfo {
    /**
     * Constructor.
     */
    constructor(public email: string, public role: SternumRole, public jobTitle?: string) {}

    /**
     * Return json representation of the class
     */
    public getJsonObject() {
        return {
            email: this.email,
            role: this.role.toJsonObject(),
            jobTitle: this.jobTitle,
        };
    }
}

export default InviteUserInfo;
