import SternumDeviceEventInfo from "./SternumDeviceEventInfo";

/**
 * Response of the get sternum device events command.
 */
class GetSternumDeviceEventsResponseWithCursor {
    /**
     * Constructor.
     */
    constructor(
        public sternumDeviceEvents: SternumDeviceEventInfo[],
        public totalItemCount: number,
        public cursor: string
    ) {}
}

export default GetSternumDeviceEventsResponseWithCursor;
