/**
 * Holds the different types of aggregations for sternum queries.
 */
enum AggregationFunctionType {
    AVG = "AVG",
    MIN = "MIN",
    MAX = "MAX",
    SUM = "SUM",
    PERCENTILE = "PERCENTILE",
    COUNT = "COUNT",
    UNIQUE_COUNT = "UNIQUE_COUNT",
}

export default AggregationFunctionType;
