import { withStyles, WithStyles } from "@material-ui/core";
import { isEqual } from "lodash";
import * as React from "react";
import AsyncSelect from "react-select/async";
import ServiceWire from "../../../lib/services/ServiceWire";
import DeviceDefinitionVersionInfo from "../../../lib/state/DeviceDefinitionVersionInfo";
import EntityBase from "../../../lib/state/EntityBase";
import EntityType from "../../../lib/state/EntityType";
import customSelectStyle from "../../CustomSelectStyle";
import sternumEntitySelectStyle from "./SternumEntitySelectStyle";
import SternumEntitySelectType from "./SternumEntitySelectType";

interface AppState {
    selectedValue: { value: EntityBase; label: string } | { value: EntityBase; label: string }[];
    defaultOptions: SternumEntitySelectType[];
}

export interface AppProps extends WithStyles<typeof sternumEntitySelectStyle> {
    entityType: EntityType;
    onEntitySelected: (selectedEntity: EntityBase | EntityBase[]) => void;
    selectedValue?: EntityBase | EntityBase[];
    availableOptions?: string[];

    menuMaxHeight?;
    menuLocation?; // Optional top, bottom or auto
    theme?;

    /**
     * whether to allow or not to select multiple entities
     */
    multiple?: boolean;
    className?: string;
}

class SternumEntitySelect extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);

        this.state = {
            selectedValue: this.constructDefaultValue(),
            defaultOptions: [],
        };
    }

    async componentDidMount() {
        switch (this.props.entityType) {
            case EntityType.DeviceDefinitionVersion: {
                const results = await this.loadDeviceDefinitionVersions();
                this.setState({
                    defaultOptions: results,
                });
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(this.props.selectedValue, prevProps.selectedValue)) {
            this.setState({ selectedValue: this.constructDefaultValue() });
        }
    }

    render() {
        return (
            <AsyncSelect
                value={this.state.selectedValue}
                loadOptions={this.loadOptions}
                name={`SternumSelectorComponent ${this.props.entityType.toString()}`}
                className={this.props.className}
                onChange={(newValue, action) => this.updateSelectedField(newValue, action)}
                styles={{
                    ...customSelectStyle(this.props.theme),
                    option: (provided, state) => {
                        return {
                            ...provided,
                            backgroundColor: state.isFocused ? "#FFF1F5" : "#fff",
                            "&:active": {
                                backgroundColor: "#FFF1F5",
                            },
                            color: "black",
                        };
                    },
                }}
                isSearchable
                closeMenuOnSelect={false}
                isMulti={this.props.multiple}
                placeholder={`Select ${this.getEntityPlaceHolder()}`}
                defaultOptions={this.state.defaultOptions}
                maxMenuHeight={150}
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary: "primary25",
                    },
                })}
            />
        );
    }

    private updateSelectedField = (selectedItem, action) => {
        const value = Array.isArray(selectedItem) ? selectedItem.map((item) => item.value) : selectedItem?.value;
        this.setState({
            selectedValue: selectedItem,
        });

        this.props.onEntitySelected(value || (this.props.multiple ? [] : value));
    };

    private constructDefaultValue = () => {
        if (!this.props.selectedValue) return null;

        switch (this.props.entityType) {
            case EntityType.DeviceDefinitionVersion:
                return Array.isArray(this.props.selectedValue)
                    ? (this.props.selectedValue as DeviceDefinitionVersionInfo[]).map((value) => ({
                          value,
                          label: value.getVersionName(),
                      }))
                    : {
                          value: this.props.selectedValue,
                          label: (this.props.selectedValue as DeviceDefinitionVersionInfo).getVersionName(),
                      };
            default:
                return null;
        }
    };

    private loadOptions = async (inputValue): Promise<SternumEntitySelectType[]> => {
        switch (this.props.entityType) {
            case EntityType.DeviceDefinitionVersion:
                return this.loadDeviceDefinitionVersions(inputValue);

            default: {
                return Promise.resolve([]);
            }
        }
    };

    private getEntityPlaceHolder = () => {
        switch (this.props.entityType) {
            case EntityType.DeviceDefinitionVersion:
                return "Device Profiles";

            default:
                return EntityType[this.props.entityType];
        }
    };

    private async loadDeviceDefinitionVersions(searchTerm?: string): Promise<SternumEntitySelectType[]> {
        let results: DeviceDefinitionVersionInfo[] = await ServiceWire.getSternumService().getDeviceDefinitionVersions(
            ServiceWire.getClientsService().getSelectedClientId(),
            0,
            50,
            searchTerm
        );

        if (this.props.availableOptions) {
            results = results.filter((deviceDefinitionVersion) =>
                this.props.availableOptions.includes(deviceDefinitionVersion.entityId)
            );
        }

        return results.map((deviceDefinitionVersion: DeviceDefinitionVersionInfo) => {
            return {
                label: deviceDefinitionVersion.getVersionName(),
                value: deviceDefinitionVersion,
            };
        });
    }
}

export default withStyles(sternumEntitySelectStyle, { withTheme: true })(SternumEntitySelect);
