import React from "react";

import { useStyle } from "./AlertsTab.styles";
import { Box } from "@material-ui/core";
import IssuesList from "../../../IssuesList/IssuesList";
import IssuesFilter from "../../../../lib/state/IssuesFilter";
import { IssueInfoStatus } from "../../../../lib/state/IssueInfoType";
import SternumConfiguration from "../../../../lib/infra/SternumConfiguration";
import HashSet from "../../../../lib/infra/HashSet";
import TableColumnHeaderInfo from "../../../../lib/state/TableColumnHeaderInfo";
import { AlertsSummary } from "../AlertsSummary";

export interface AlertsTabProps {
    deviceId: string;
    startDate: Date;
    endDate: Date;
}

const columnHeaders = [
    new TableColumnHeaderInfo("created", "Received", false, true, false),
    new TableColumnHeaderInfo("alertName", "Alert Name", false, true, false),
    new TableColumnHeaderInfo("traceEventType", "Alert Type", false, true, false),
    new TableColumnHeaderInfo("traceCategory", "Category", false, true, false),
    new TableColumnHeaderInfo("prevented", "Status", false, true, false),
    new TableColumnHeaderInfo("actions", "Actions", false, true, false, false),
];

export function AlertsTab({ deviceId, startDate, endDate }: AlertsTabProps) {
    const classes = useStyle();

    const getViewedColumnsSet = () => {
        let columns = columnHeaders.map((header) => header.id);

        return HashSet.fromValues(columns);
    };

    const issuesFilter = new IssuesFilter(
        startDate.getTime(),
        endDate.getTime(),
        false,
        false,
        [IssueInfoStatus.Dismissed, IssueInfoStatus.Open],
        undefined,
        undefined,
        true
    );

    return (
        <Box className={classes.container}>
            <IssuesList
                deviceId={deviceId}
                viewedColumnsSet={getViewedColumnsSet()}
                initialColumnHeaders={columnHeaders}
                enablePolling={true}
                issuesFilter={issuesFilter}
                displayXButtonInTraceView={true}
                displayBackButton={false}
                hideUpperBorder={true}
                forceRefresh={false}
                pageSize={SternumConfiguration.getPageSize()}
            />

            <AlertsSummary deviceId={deviceId} startDate={startDate} endDate={endDate} />
        </Box>
    );
}
