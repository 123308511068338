import { makeStyles } from "@material-ui/core";
import commonStyle from "../CommonStyle";

const useMapModalStyles = makeStyles((theme) => {
    return {
        ...commonStyle(theme),
        container: {
            height: "100%",
        },
        descriptionText: {
            marginTop: theme.spacing(2),
        },
        listPaper: {
            "&.mod-fullscreen": {
                maxHeight: "100vh",
            },
        },

        tableContainer: {
            height: "calc(100% - 47px)",
            backgroundColor: "#DEE7EF",
        },

        tableInner: {
            height: "100%",
            padding: theme.spacing(3),
            background: "#fff",
            boxShadow: "0px 0px 23px rgba(231, 232, 235, 0.25)",
            borderRadius: 14,
            borderTopLeftRadius: 0,
        },

        mapLoader: {
            width: "100%",
            height: "90%",
        },
    };
});

export default useMapModalStyles;
