/**
 * Represents a label point in the graph.
 */
class GraphLabelPoint {
    /**
     * Constructor.
     * @param rawTime The raw value of the time (epoch milliseconds).
     * @param formattedTime The formatted value of the time.
     */
    constructor(public rawTime: string, public formattedTime: string) {}
}

export default GraphLabelPoint;
