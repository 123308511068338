import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { sternumTheme } from "../../App";
import commonStyle from "../../CommonStyle";

const deviceKeyMetricStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        // Key metric container.
        root: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer" as "pointer",
            margin: theme.spacing(2),
            borderRadius: theme.shape.borderRadius,
            "&:hover": {
                backgroundColor: sternumTheme.lightGrey.main,
            },
            minWidth: theme.spacing(32),
            height: theme.spacing(10),

            "&.mod-not-clickable": {
                cursor: "default",
                "&:hover": {
                    backgroundColor: "transparent",
                },
            },
            "&.mod-cursor-only": {
                cursor: "pointer",
            },
        },

        // Metric content.
        content: {
            fontWeight: 600,
            fontSize: "1.125erm",
        },

        // If metric is selected.
        selected: {
            backgroundColor: sternumTheme.lightGrey.main,
            border: `1px solid ${sternumTheme.grey.main}`,
        },

        // If metric semantic meaning is positive.
        positive: {
            color: "#15AC5A",
        },

        // If metric semantic meaning is negative.
        negative: {
            color: "#E7004C",
        },

        // Caption text to display for key metric.
        captionText: {
            fontSize: "1.125rem",
            color: "#000000",
        },

        // The icon indicating the delta.
        arrowIcon: {
            fontSize: "14px",
        },

        // The value of the delta.
        deltaValue: {
            fontSize: "14px",
        },

        metricNumberContainer: {
            minWidth: 50,
            padding: theme.spacing(0, 3),
            minHeight: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#FCFCFC",
            border: "1px solid #E8E8E8",
            borderRadius: 14,
            transition: "all 0.2s ease",

            "& .MuiTypography-root": {
                color: "#E7004C",
                fontSize: "1.125rem",
                fontWeight: 600,
                transition: "all 0.2s ease",
            },
            "& .mod-danger": {
                background: "#E7004C",
                color: "#FFFFFF",
            },
        },
    });

export default deviceKeyMetricStyle;
