import React from "react";

import { SternumIconProps } from "./SternumIcon.types";

export function RoundedRightArrowIcon({ className, color, height = 44, width = 44, ...props }: SternumIconProps) {
    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.8163 25.9837C19.6613 25.8286 19.6613 25.5772 19.8163 25.4222L23.2415 21.9971L19.8163 18.5719C19.6613 18.4169 19.6613 18.1655 19.8163 18.0104C19.9714 17.8553 20.2228 17.8553 20.3779 18.0104L24.0838 21.7163C24.2388 21.8714 24.2388 22.1228 24.0838 22.2778L20.3779 25.9837C20.2228 26.1388 19.9714 26.1388 19.8163 25.9837Z"
                fill={color || "currentColor"}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.5335 26.2665C19.2222 25.9553 19.2222 25.4506 19.5335 25.1393L22.6758 21.9971L19.5335 18.8548C19.2222 18.5435 19.2222 18.0388 19.5335 17.7276C19.8448 17.4163 20.3494 17.4163 20.6607 17.7276L24.3666 21.4334C24.6779 21.7447 24.6779 22.2494 24.3666 22.5607L20.6607 26.2665C20.3494 26.5778 19.8448 26.5778 19.5335 26.2665ZM23.5687 22.2271L23.7988 21.9971L23.5687 21.767C23.6657 21.9045 23.6657 22.0896 23.5687 22.2271Z"
                fill={color || "currentColor"}
            />
            <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" stroke={color || "currentColor"} />
        </svg>
    );
}
