import moment from "moment";

import { Tooltip } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { isEqual, omit, uniqBy } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import HashSet from "../../lib/infra/HashSet";
import SternumConfiguration from "../../lib/infra/SternumConfiguration";
import TraceEventTypeConfiguration from "../../lib/infra/TraceEventTypeConfiguration";
import WebUtils from "../../lib/infra/WebUtils";
import {
    openCveViewModalAction,
    openSternumGeneratedEventViewModalAction,
    openTraceViewModalAction,
} from "../../lib/redux/modals/OpenModalAction";
import AnalyticsService from "../../lib/services/AnalyticsService";
import DebounceService from "../../lib/services/DebounceService";
import ServiceWire from "../../lib/services/ServiceWire";
import { CopyMechanismInfo } from "../../lib/state/CopyMechanismInfo";
import GetSternumDeviceEventsResponse from "../../lib/state/GetSternumDeviceEventsResponse";
import GetSternumUniqueCountEventsResponse from "../../lib/state/GetSternumUniqueCountEventsResponse";
import { GlobalState } from "../../lib/state/GlobalState";
import HttpResponse from "../../lib/state/HttpResponse";
import ListFilter from "../../lib/state/ListFilter";
import PollingChangeType from "../../lib/state/PollingChangeType";
import SternumDeviceEventInfo from "../../lib/state/SternumDeviceEventInfo";
import SternumDeviceEventsFilter from "../../lib/state/SternumDeviceEventsFilter";
import TableColumnHeaderInfo from "../../lib/state/TableColumnHeaderInfo";
import TableRowData from "../../lib/state/TableRowData";
import TableToolbarDisplayState from "../../lib/state/TableToolbarDisplayState";
import ArgumentsListDisplay from "../ArgumentsListDisplay/ArgumentsListDisplay";
import EventStatusListDisplay from "../EventStatusListDisplay/EventStatusListDisplay";
import StatusDisplay from "../StatusDisplay/StatusDisplay";
import SternumDeviceEventsListFilterPopover from "../SternumDeviceEventsListFilterPopover/SternumDeviceEventsListFilterPopover";
import SternumCopyMenu from "../SUI/SternumCopyMenu/SternumCopyMenu";
import SternumEventTypeDisplay from "../SUI/SternumEventTypeDisplay/SternumEventTypeDisplay";
import SternumLink from "../SUI/SternumLink/SternumLink";
import SternumTable from "../SUI/SternumTable/SternumTable";
import sternumDeviceEventsListStyle from "./SternumDeviceEventsListStyle";
import AggregatedTraceDefinition from "../../lib/state/AggregatedTraceDefinition";
import SternumUniqueCountEventInfo from "../../lib/state/SternumUniqueCountEventInfo";
import AggregationFunctionType from "../../lib/state/Visualisation/AggregationFunctionType";
import ConfigurationService from "../../lib/services/ConfigurationService";
import {
    UniqueCountTableDataCategory,
    UniqueCountTableDataEventType,
    UniqueCountTableDataInterest,
} from "../UniqueCountQuery/UniqueCountTableData";
import IssueInfo from "../../lib/state/IssueInfo";
import GetSternumDeviceEventsResponseWithCursor from "../../lib/state/GetSternumDeviceEventsResponseWithCursor";
import TimeSelectionType from "../../lib/state/TimeSelectionType";
import axios, { CancelToken } from "axios";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    // Loading & error state.
    loadingEntities: boolean;
    errorLoadingEntities: boolean;

    // Entities.
    entities: SternumDeviceEventInfo[];
    totalItemCount: number;
    currentPage: number;
    currentCursor: string;

    doNotDisplayLoading: boolean;

    // Filtering.
    entitiesFilter: SternumDeviceEventsFilter;
    searchText: string;
    orderByField: string;
    order: "asc" | "desc";
    showInfinityLoader: boolean;
    displayLoadingIcon: boolean;

    // Right click
    anchorEl: Element;
    openRowMenu: boolean;
    clickEvent: React.MouseEvent<HTMLDivElement>;
    rightClickedInfo: CopyMechanismInfo;
    rightClickedRow: TableRowData;

    dynamicColumnEntities: SternumUniqueCountEventInfo[];

    // if cursor pagination teached its end
    dynamicColumnEntitiesReachedEnd: boolean;
    scrolled: boolean;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof sternumDeviceEventsListStyle> {
    sideBarOpen: boolean;
    location;
    history;
    hideUpperBorder?: boolean;
    shouldNoWrapDisplayName: boolean;

    deviceDefinitionVersionId?: string;

    clientId?: string;
    deviceIds?: string[];
    entityId: string;
    columnWidthsArray?: number[];

    toolbarState?: TableToolbarDisplayState;

    includeColumns?: string[];
    paperClassNames?;
    viewedColumnsSet?: HashSet;

    doNotDisplayLoading?: boolean;
    infiniteScroll?: boolean;

    doNotDisplayExploitationTypeInDisplayName?: boolean;

    amountOfLoadingPlaceholders?: number;

    preloadedEntities: SternumDeviceEventInfo[];
    entitiesFilter: SternumDeviceEventsFilter;
    selectedTimeSelectionType: TimeSelectionType;
    refreshEntitiesFilter?: boolean;
    displayTypeColumn: boolean;

    onNewVisualizationClicked?: () => void;
    onExistingVisualisationClicked?: (visualisationId: string) => void;

    openTraceViewModal?: (
        key: string,
        traceId: string,
        displayXButton: boolean,
        displayBackButton: boolean,
        shouldDisplayLinkToDeviceView: boolean,
        shouldDisplayLinkToExportReport: boolean,
        issue: IssueInfo | undefined,
        shouldDisplayViewInContextButton: boolean
    ) => void;
    openSternumGeneratedEventViewModal?: (
        key: string,
        generatedEventId: string,
        shouldDisplayLinkToDeviceView: boolean,
        shouldDisplayLinkToExportReport: boolean,
        displayXButton: boolean,
        displayBackButton: boolean,
        shouldDisplayViewInContextButton: boolean
    ) => void;
    openCveViewModal?: (key: string, cveId: string, displayXButton: boolean, displayBackButton: boolean) => void;

    showAllColumns?: boolean;

    displayXButtonInTraceView: boolean;
    displayBackButtonInTraceView: boolean;
    shouldDisplayLinkToDeviceView?: boolean;
    hideToolbar?: boolean;
    onFiltersChanged?: (sternumDeviceEventsFilter: SternumDeviceEventsFilter) => void;

    emptyTableMessage: string;

    isDeviceView?: boolean;

    indexEventTime?: number;

    excludeLinuxViewTraces?: boolean;

    denseTable?: boolean;

    isTableExpanded?: boolean;
    onTableExpandToggle?: () => void;

    /**
     * Shows columns directly from data,
     * setting this to not null ignores
     * "includeColumns" and "showAllColumns"
     *
     * default: null
     */
    dynamicColumns?: AggregatedTraceDefinition[];

    aggregationFunctionType: AggregationFunctionType;

    contextFromTimestamp?: number;
    contextTraceId?: string;
    // force hide show in context button even if
    // there is sufficient data to display it
    hideShowInContext?: boolean;
}

interface DynamicColumnResponseItem {
    values: number[];
    count: number;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {
        openTraceViewModal: (
            key: string,
            traceId,
            displayXButton,
            displayBackButton,
            shouldDisplayLinkToDeviceView,
            shouldDisplayLinkToExportReport,
            issue: IssueInfo | undefined,
            shouldDisplayViewInContextButton: boolean
        ) =>
            dispatch(
                openTraceViewModalAction(
                    key,
                    traceId,
                    displayXButton,
                    displayBackButton,
                    shouldDisplayLinkToDeviceView,
                    shouldDisplayLinkToExportReport,
                    issue,
                    shouldDisplayViewInContextButton
                )
            ),
        openSternumGeneratedEventViewModal: (
            key: string,
            sternumGeneratedEventId: string,
            shouldDisplayLinkToDeviceView: boolean,
            shouldDisplayLinkToExportReport: boolean,
            displayXButton,
            displayBackButton,
            shouldDisplayViewInContextButton: boolean
        ) =>
            dispatch(
                openSternumGeneratedEventViewModalAction(
                    key,
                    sternumGeneratedEventId,
                    shouldDisplayLinkToDeviceView,
                    shouldDisplayLinkToExportReport,
                    displayXButton,
                    displayBackButton,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    shouldDisplayViewInContextButton
                )
            ),

        openCveViewModal: (key, cveId, displayXButton, displayBackButton) =>
            dispatch(openCveViewModalAction(key, cveId, displayXButton, displayBackButton)),
    };
};

const categoryArg = ConfigurationService.getCategoryArgumentField();
const interestArg = ConfigurationService.getInterestArgumentField();
const eventTypeArg = ConfigurationService.getEventTypeArgumentField();

/**
 * Holds the entities list in the app.
 */
class SternumDeviceEventsList extends React.Component<AppProps, AppState> {
    private seenExtraPageSize: number = 0;

    previousRequest = axios.CancelToken.source();

    /**
     * Defines the column headers participating in the entities table.
     */
    private readonly columnHeaders: TableColumnHeaderInfo[] = [
        new TableColumnHeaderInfo("type", "Type", false, true, false, false),
        new TableColumnHeaderInfo("created", "Received", false, true, false, false, 10),
        new TableColumnHeaderInfo("deviceId", "Device Id", false, true, false, false),
        new TableColumnHeaderInfo("deviceProfile", "Device Profile", false, true, false, false),
        new TableColumnHeaderInfo("firmwareVersion", "Firmware Version", false, true, false, false),
        new TableColumnHeaderInfo("eventCategory", "Category", false, true, false, false),
        new TableColumnHeaderInfo("displayName", "Name", false, true, false, false),
        new TableColumnHeaderInfo("details", "Arguments", false, true, false),
    ];

    private getDynamicRowDisplayValues(row: SternumUniqueCountEventInfo) {
        const obj = {};

        if (!row || !row.columns) {
            return obj;
        }

        this.props.dynamicColumns.forEach((col) => {
            if (col.isSpecialField && row.columns[col.value] !== undefined && col.value === categoryArg.id) {
                obj[col.value] = <UniqueCountTableDataCategory value={row.columns[col.value] + ""} />;
            } else if (col.isSpecialField && row.columns[col.value] !== undefined && col.value === interestArg.id) {
                obj[col.value] = <UniqueCountTableDataInterest value={row.columns[col.value] + ""} />;
            } else if (col.isSpecialField && row.columns[col.value] !== undefined && col.value === eventTypeArg.id) {
                obj[col.value] = <UniqueCountTableDataEventType value={row.columns[col.value] + ""} />;
            } else {
                obj[col.value] = row.columns[col.value];
            }
        });

        return obj;
    }

    private canShowContextButton(traceId?: string) {
        const showContextButton =
            (!!this.state.entitiesFilter.sternumQuery &&
                !!this.state.entitiesFilter.sternumQuery.innerQueries &&
                this.state.entitiesFilter.sternumQuery.innerQueries.length > 0) ||
            traceId === this.props.contextTraceId;

        return showContextButton;
    }

    /**
     * Gets the display values for row's columns (where they aren't the default).
     */
    private getRowDisplayValues(row) {
        let entity = row as SternumDeviceEventInfo;
        const { classes } = this.props;

        if (!entity.traceInfo && !entity.sternumGeneratedEventInfo) {
            return null;
        }

        let eventInterest = entity.traceInfo
            ? entity.traceInfo.traceDefinition.traceInterest
            : entity.sternumGeneratedEventInfo.sternumTrigger.eventInterest;

        const tooltipText = `${eventInterest.substr(0, 1).toUpperCase()}${eventInterest
            .toLowerCase()
            .substr(1)} Interest`;

        let displayName = entity.getDisplayName();
        if (
            !this.props.doNotDisplayExploitationTypeInDisplayName &&
            entity &&
            entity.traceInfo &&
            entity.traceInfo.traceDefinition &&
            entity.traceInfo.traceDefinition.traceEventName === "EXPLOITATION_ALERT" &&
            entity.traceInfo.traceArguments["EXPLOITATION_TYPE"]
        ) {
            displayName += ` (${entity.traceInfo.traceArguments["EXPLOITATION_TYPE"].displayValue})`;
        }

        const underlyingEventId = entity.sternumGeneratedEventInfo?.entityId || entity.traceInfo?.entityId;

        const isPinned =
            !!this.props.contextFromTimestamp &&
            // two == is not a mistake
            entity.created == this.props.contextFromTimestamp &&
            !!this.props.contextTraceId &&
            this.props.contextTraceId === underlyingEventId;

        const showContextButton = this.canShowContextButton(underlyingEventId);

        return {
            key: `${entity.entityId}-${showContextButton}`,
            type: (
                <SternumEventTypeDisplay
                    traceId={underlyingEventId}
                    showIndicator={true}
                    label={entity.getEventType()}
                    eventInterest={eventInterest}
                    size={"medium"}
                    toolTip={tooltipText}
                    onContextClick={
                        showContextButton
                            ? (e) => {
                                  e.preventDefault();
                                  e.stopPropagation();

                                  if (isPinned) {
                                      window.location.href = `/fleet-view/${entity.device.lastSeenVersionId}`;
                                      return;
                                  }

                                  Object.assign(document.createElement("a"), {
                                      target: "_blank",
                                      href: `/fleet-view/${entity.device.lastSeenVersionId}?contextFromTimestamp=${entity.created}&contextTraceId=${underlyingEventId}`,
                                  }).click();
                              }
                            : undefined
                    }
                    pinContext={isPinned}
                    hideShowInContext={this.props.hideShowInContext}
                />
            ),

            created: (
                <div className={classNames(classes.flexVMiddle)}>
                    {!this.props.displayTypeColumn && (
                        <StatusDisplay
                            low={eventInterest.toLowerCase() === "low"}
                            medium={eventInterest.toLowerCase() === "medium"}
                            high={eventInterest.toLowerCase() === "high"}
                            regular={eventInterest.toLowerCase() === "regular"}
                            smallIndication={true}
                            toolTipText={tooltipText}
                        />
                    )}
                    {/* Timestamp  content */}
                    <Typography variant="body2" className={classNames(classes.marginLeftXs)}>
                        {moment(entity.created).format("MM/DD/YYYY HH:mm")}
                    </Typography>
                </div>
            ),
            deviceId: entity.device.receivedDeviceId,
            deviceProfile: cutDisplayedString(entity.device?.deviceDefinition?.displayName || "", 16, 15),
            firmwareVersion: entity.getVersionFirmware(),
            eventCategory: entity.getCategoryDisplay(),
            displayName: (
                <SternumLink onClick={() => this.handleRowClicked(entity)}>
                    {this.props.shouldNoWrapDisplayName ? (
                        <Tooltip title={displayName} classes={{ tooltip: classes.toolTip }}>
                            <Typography
                                noWrap
                                variant={"body2"}
                                className={
                                    this.props.isDeviceView ? classes.deviceAlertName : classes.definitionAlertName
                                }
                            >
                                {displayName}
                            </Typography>
                        </Tooltip>
                    ) : (
                        displayName
                    )}
                </SternumLink>
            ),
            prevented: entity.traceInfo ? <EventStatusListDisplay traceInfo={entity.traceInfo} /> : null,
            details: entity.traceInfo ? <ArgumentsListDisplay traceInfo={entity.traceInfo} /> : null,
            actions: (
                <div className={classes.flexVMiddle}>
                    {}
                    <Typography variant="body2" className={classes.traceLinkText}>
                        {entity.sternumGeneratedEventInfo || this.isAttackAttempt(row) ? "Investigate" : "Details"}
                    </Typography>

                    {}
                    <Icon className={classNames("fa fa-external-link-alt", classes.traceLinkIcon)} />
                </div>
            ),
        };
    }

    /**
     * Occurs on the click of a row.
     */
    private handleRowClicked(sternumDeviceEventInfo: SternumDeviceEventInfo) {
        const shouldDisplayViewInContextButton = !this.props.hideShowInContext && this.state.entitiesFilter.isActive();
        if (sternumDeviceEventInfo.traceInfo) {
            this.props.openTraceViewModal(
                sternumDeviceEventInfo.traceInfo.traceId,
                sternumDeviceEventInfo.traceInfo.traceId,
                this.props.displayXButtonInTraceView,
                this.props.displayBackButtonInTraceView,
                this.props.shouldDisplayLinkToDeviceView,
                this.isAttackAttempt(sternumDeviceEventInfo),
                undefined,
                shouldDisplayViewInContextButton
            );
        } else if (sternumDeviceEventInfo.sternumGeneratedEventInfo) {
            this.props.openSternumGeneratedEventViewModal(
                sternumDeviceEventInfo.sternumGeneratedEventInfo.entityId,
                sternumDeviceEventInfo.sternumGeneratedEventInfo.entityId,
                this.props.shouldDisplayLinkToDeviceView,
                this.isAttackAttempt(sternumDeviceEventInfo),
                this.props.displayXButtonInTraceView,
                this.props.displayBackButtonInTraceView,
                shouldDisplayViewInContextButton
            );
        }
    }

    /**
     * Check if current event is attack event
     */

    private isAttackAttempt = (sternumDeviceEventInfo: SternumDeviceEventInfo) => {
        let traceEventTypeConfiguration: TraceEventTypeConfiguration =
            sternumDeviceEventInfo.traceInfo &&
            SternumConfiguration.getTraceEventTypeConfigurationObject(
                sternumDeviceEventInfo.traceInfo.traceDefinition.traceEventName
            );
        return !!(traceEventTypeConfiguration && traceEventTypeConfiguration.isAttackTrace);
    };

    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        if (this.props.includeColumns && !this.props.dynamicColumns) {
            if (this.props.includeColumns.length) {
                let includeColumnsSet = HashSet.fromValues(this.props.includeColumns);
                this.columnHeaders = this.columnHeaders.filter((columnHeader) =>
                    includeColumnsSet.exists(columnHeader.id)
                );
            } else {
                this.columnHeaders = [];
            }
        }

        if (this.props.showAllColumns && !this.props.dynamicColumns) {
            for (let i = 0; i < this.columnHeaders.length; i++) {
                this.columnHeaders[i].isHidden = false;
            }
        }

        // Initializing the state to default.
        this.state = {
            loadingEntities: false,
            errorLoadingEntities: false,

            entities: [],
            currentPage: 1,
            currentCursor: "",

            doNotDisplayLoading: this.props.doNotDisplayLoading,

            entitiesFilter: this.props.entitiesFilter,
            searchText: null,
            orderByField: null,
            order: this.props.contextFromTimestamp ? "asc" : "desc",

            totalItemCount: null,
            showInfinityLoader: false,
            displayLoadingIcon: false,

            anchorEl: null,
            openRowMenu: false,
            clickEvent: null,
            rightClickedInfo: { value: "", label: "" },

            rightClickedRow: null,

            dynamicColumnEntities: [],
            dynamicColumnEntitiesReachedEnd: false,
            scrolled: false,
        };
    }

    /**
     * Occurs once the component is about to receive props.
     */
    UNSAFE_componentWillReceiveProps(nextProps: Readonly<AppProps>, nextContext: any): void {
        let hasChangeInFilters =
            (!nextProps.entitiesFilter && this.props.entitiesFilter) ||
            (nextProps.entitiesFilter && !this.props.entitiesFilter) ||
            (nextProps.entitiesFilter &&
                this.props.entitiesFilter &&
                nextProps.entitiesFilter.isDifferentFrom(this.props.entitiesFilter)) ||
            !isEqual(this.props.dynamicColumns, nextProps.dynamicColumns);

        if (
            hasChangeInFilters ||
            nextProps.refreshEntitiesFilter ||
            nextProps.entityId !== this.props.entityId ||
            !isEqual(this.props.deviceDefinitionVersionId, nextProps.deviceDefinitionVersionId) ||
            nextProps.clientId !== this.props.clientId ||
            nextProps.deviceIds?.length !== this.props.deviceIds?.length
        ) {
            this.handleEntitiesFilterChanged(
                nextProps.entitiesFilter,
                nextProps.doNotDisplayLoading,
                nextProps.indexEventTime
            );
        }

        // if (nextProps.dynamicColumns && isEqual(this.props.dynamicColumns, nextProps.dynamicColumns)) {
        //     this.setState(
        //         {
        //             dynamicColumnEntities: [],
        //         },
        //         () => {
        //             this.loadEntities(1);
        //         }
        //     );
        // }
    }

    /**
     * Occurs once the component finished its initialization process.
     */
    async componentDidMount() {
        await this.loadEntities();
    }

    /**
     * Occurs once the component is being destroyed.
     */
    componentWillUnmount(): void {
        ServiceWire.getPollingService().unsubscribe("SternumDeviceEventsList", PollingChangeType.EVENTS_INDEXED);
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        return (
            <>
                <SternumTable
                    size={this.props.denseTable ? "small" : undefined}
                    isTableExpanded={this.props.isTableExpanded}
                    onTableExpandToggle={this.props.onTableExpandToggle}
                    hideUpperBorder={this.props.hideUpperBorder}
                    columnWidthsArray={this.props.columnWidthsArray || [10, 10, 90]}
                    emptyComponent={
                        <div className={classNames(classes.flexCenter, classes.padding)}>
                            <Typography variant="body2">{this.props.emptyTableMessage}</Typography>
                        </div>
                    }
                    toolbarState={this.props.toolbarState}
                    viewedColumnsSet={this.props.viewedColumnsSet}
                    onNewVisualizationClicked={this.props.onNewVisualizationClicked}
                    onExistingVisualisationClicked={this.props.onExistingVisualisationClicked}
                    onExportToCsvClicked={() => this.onExportToCsvClicked("CSV")}
                    onExportToXlsxClicked={() => this.onExportToCsvClicked("XLSX")}
                    hideToolbar={this.props.hideToolbar}
                    hidePagination={!!this.props.dynamicColumns}
                    pageSize={SternumConfiguration.getPageSize()}
                    amountOfLoadingPlaceholders={this.props.amountOfLoadingPlaceholders || 9}
                    totalItemCount={this.state.totalItemCount}
                    loadingItems={this.state.loadingEntities}
                    errorLoadingItems={this.state.errorLoadingEntities}
                    columnHeaders={
                        this.props.dynamicColumns
                            ? [
                                  ...this.props.dynamicColumns
                                      .filter((col) => col !== null && col.isSpecialField)
                                      .map(
                                          (col) =>
                                              new TableColumnHeaderInfo(
                                                  col.value,
                                                  col.label,
                                                  false,
                                                  false,
                                                  false,
                                                  true,
                                                  0
                                              )
                                      ),
                                  new TableColumnHeaderInfo("arguments", "Arguments", false, false, false, true, 0),
                                  new TableColumnHeaderInfo("count", "Count", false, false, false, true, 0),
                              ]
                            : this.columnHeaders
                    }
                    listFilter={this.state.entitiesFilter}
                    onRefreshClicked={() => this.onRefreshClicked()}
                    filterComponentProvider={(onFilterChanged) => (
                        <SternumDeviceEventsListFilterPopover
                            sternumDeviceEventsFilter={this.props.entitiesFilter}
                            onFilterChanged={onFilterChanged}
                        />
                    )}
                    orderByField={this.state.orderByField}
                    order={this.state.order}
                    rows={this.props.dynamicColumns ? this.state.dynamicColumnEntities : this.state.entities}
                    onFilterChanged={(listFilter) =>
                        this.handleEntitiesFilterChanged(listFilter, this.state.doNotDisplayLoading)
                    }
                    onSearchTextChanged={(searchText) => this.handleSearchTextChanged(searchText)}
                    onOrderChanged={(orderByField, order) => this.handleOrderChanged(orderByField, order)}
                    getRowValues={(row) => {
                        if (this.props.dynamicColumns) {
                            return this.getDynamicRowDisplayValues(row as SternumUniqueCountEventInfo);
                        }

                        return this.getRowDisplayValues(row);
                    }}
                    onRowClicked={(row) => this.handleRowClicked(row as SternumDeviceEventInfo)}
                    maxTableHeightClass={this.props.paperClassNames}
                    isInfinityScrollOn={this.props.infiniteScroll}
                    onScrollChanged={async (pageNumber) => {
                        if (pageNumber === 1) {
                            const shouldUpdateFilterCreatedTo =
                                this.props.selectedTimeSelectionType !== TimeSelectionType.CUSTOM;
                            const entitiesFilter = this.state.entitiesFilter?.clone?.();

                            if (shouldUpdateFilterCreatedTo) {
                                entitiesFilter.createdTo = moment().toDate().getTime();
                            }

                            this.setState({ currentCursor: "", entitiesFilter }, async () => {
                                this.setState({ currentPage: pageNumber });
                                await this.loadEntitiesForScroll();
                            });
                        } else {
                            this.setState({ currentPage: pageNumber });
                            await this.loadEntitiesForScroll();
                        }
                    }}
                    showInfinityLoader={this.state.showInfinityLoader}
                    pageNumber={this.state.currentPage}
                    displayLoadingIcon={this.state.displayLoadingIcon}
                    onCellRightClick={(cellName: string, clickedRow: TableRowData, event) => {
                        this.onCellCopyClick(cellName, clickedRow, event);
                    }}
                    onResetViewInContext={
                        this.props.contextFromTimestamp
                            ? () => {
                                  this.props.history.replace({
                                      pathname: window.location.pathname,
                                      search: "",
                                  });
                              }
                            : undefined
                    }
                />
                <SternumCopyMenu
                    openMenu={this.state.openRowMenu}
                    fieldsInfo={[this.state.rightClickedInfo]}
                    anchorEl={this.state.anchorEl}
                    copyId={this.onCopyEvent}
                    closeMenu={() => this.setState({ openRowMenu: false })}
                />
            </>
        );
    }

    /**
     * Occurs once the filters of the list are changed.
     */
    private handleEntitiesFilterChanged(
        entitiesFilter: ListFilter,
        doNotDisplayLoading: boolean,
        eventIndexTime?: number
    ) {
        const sternumDeviceEventsFilter: SternumDeviceEventsFilter =
            entitiesFilter != null ? (entitiesFilter as SternumDeviceEventsFilter) : null;

        // Don't update if it's new data event and we are not in the first page
        if (
            !!this.props.infiniteScroll &&
            this.props.infiniteScroll &&
            this.state.currentPage > 1 &&
            this.props.indexEventTime &&
            eventIndexTime &&
            eventIndexTime > this.props.indexEventTime
        ) {
            return;
        }

        this.setState(
            {
                doNotDisplayLoading: doNotDisplayLoading,
                entitiesFilter: sternumDeviceEventsFilter,
                dynamicColumnEntities: [],
                dynamicColumnEntitiesReachedEnd: false,
                currentCursor: "",
                currentPage: 1,
            },
            async () => {
                const success = await this.loadEntities();

                if (success && this.props.onFiltersChanged) {
                    this.props.onFiltersChanged(sternumDeviceEventsFilter);
                }
            }
        );
    }

    /**
     * Occurs once search text is changed.
     */
    private handleSearchTextChanged(searchText) {
        // Debounce request to search entities.
        DebounceService.debounce(
            "getDeviceSternumDeviceEvents",
            () => {
                this.setState(
                    {
                        searchText: searchText,
                        currentCursor: "",
                        currentPage: 1,
                    },
                    () => {
                        this.loadEntities();
                    }
                );
            },
            350
        );
    }

    /**
     * Occurs once sort order is changed.
     */
    private handleOrderChanged(orderByField, order) {
        this.setState(
            {
                orderByField: orderByField,
                order: order,
                currentCursor: "",
                currentPage: 1,
            },
            () => {
                this.loadEntities();
            }
        );
    }

    /**
     * Occurs once the user refreshes the list.
     */
    private async onRefreshClicked() {
        this.setState({ currentCursor: "", currentPage: 1 }, async () => {
            await this.loadEntities();
        });
    }

    /**
     * Loads entities.
     */
    private async loadEntities(doNotDisplayLoading?: boolean) {
        if (this.previousRequest) {
            this.previousRequest.cancel();
        }
        this.previousRequest = axios.CancelToken.source();

        try {
            let entities = [];
            let totalItemCount = 0;
            let newCursor = "";
            let dynamicColumnEntities = [];
            let dynamicColumnEntitiesReachedEnd = false;

            // Setting loading to true.
            this.setState({
                loadingEntities: !doNotDisplayLoading && !this.props.doNotDisplayLoading,
                errorLoadingEntities: false,
                showInfinityLoader: false,
                totalItemCount:
                    !doNotDisplayLoading && !this.props.doNotDisplayLoading ? null : this.state.totalItemCount,
            });

            // Fetching entities.
            if (this.props.preloadedEntities) {
                entities = this.props.preloadedEntities;
            } else {
                let getSternumDeviceEventsResponse = await this.fetchEntities(
                    this.state.currentCursor,
                    this.previousRequest.token
                );
                newCursor = getSternumDeviceEventsResponse.cursor;

                if (this.props.contextFromTimestamp) {
                    let extraResponse = await this.fetchExtraEntitiesForContext(this.state.currentCursor);
                    this.seenExtraPageSize = extraResponse.sternumDeviceEvents.length;
                    getSternumDeviceEventsResponse.totalItemCount += extraResponse.sternumDeviceEvents.length;
                    getSternumDeviceEventsResponse.sternumDeviceEvents = (
                        extraResponse.sternumDeviceEvents.reverse() as SternumDeviceEventInfo[]
                    ).concat(getSternumDeviceEventsResponse.sternumDeviceEvents as SternumDeviceEventInfo[]);
                }

                if (this.props.dynamicColumns) {
                    dynamicColumnEntities = (getSternumDeviceEventsResponse as GetSternumUniqueCountEventsResponse)
                        .sternumDeviceEvents;
                    dynamicColumnEntitiesReachedEnd = dynamicColumnEntities.length < SternumConfiguration.getPageSize();
                } else {
                    entities = (getSternumDeviceEventsResponse as GetSternumDeviceEventsResponse).sternumDeviceEvents;
                }

                totalItemCount = Math.max(getSternumDeviceEventsResponse.totalItemCount, entities?.length || 0);
            }

            const uniqueEntities = uniqBy(entities, (entity) => {
                return entity.sternumGeneratedEventInfo?.entityId || entity.traceInfo?.entityId;
            });

            this.setState(
                {
                    loadingEntities: false,
                    errorLoadingEntities: false,
                    entities: [...uniqueEntities],
                    totalItemCount: totalItemCount,
                    currentCursor: newCursor,
                    dynamicColumnEntities,
                    dynamicColumnEntitiesReachedEnd,
                },
                () => {
                    // i know it's a bit dirty, but it's 10 times easier to implement
                    // and understand than passing a ref deep down from the table...
                    const elem = document.querySelector(
                        `[data-sternum-event-type-display-trace-id="${this.props.contextTraceId || ""}"]`
                    );

                    if (elem && !this.state.scrolled) {
                        elem.scrollIntoView({ block: "center", inline: "nearest", behavior: "smooth" });
                        this.setState((s) => ({ ...s, scrolled: true }));
                    }
                }
            );

            return true;
        } catch (error) {
            if (axios.isCancel(error)) {
                console.warn("Previous request was canceled");
                this.setState({
                    loadingEntities: false,
                });

                return false;
            }

            AnalyticsService.error("SternumDeviceEventsList:loadEntities", error.message);

            this.setState({
                loadingEntities: false,
                errorLoadingEntities: true,
            });
        }
    }

    /**
     * Loads entities with concat operation to existing entities.
     */
    private async loadEntitiesForScroll() {
        if (this.state.dynamicColumnEntitiesReachedEnd) {
            return;
        }

        if (this.previousRequest) {
            this.previousRequest.cancel();
        }
        this.previousRequest = axios.CancelToken.source();

        try {
            let entities = [];
            let dynamicColumnEntities = [];
            let totalItemCount = 0;
            let getSternumDeviceEventsResponse = null;
            let dynamicColumnEntitiesReachedEnd = false;
            let newCursor = "";
            // Setting loading to true.
            this.setState({
                errorLoadingEntities: false,
            });

            // Fetching entities.
            if (this.state.currentCursor === "") {
                getSternumDeviceEventsResponse = await this.fetchEntities(
                    this.state.currentCursor,
                    this.previousRequest.token
                );
                newCursor = getSternumDeviceEventsResponse.cursor;

                if (this.props.contextFromTimestamp) {
                    let extraResponse = await this.fetchExtraEntitiesForContext(this.state.currentCursor);
                    getSternumDeviceEventsResponse.totalItemCount += extraResponse.sternumDeviceEvents.length;
                    getSternumDeviceEventsResponse.sternumDeviceEvents = (
                        extraResponse.sternumDeviceEvents.reverse() as SternumDeviceEventInfo[]
                    ).concat(getSternumDeviceEventsResponse.sternumDeviceEvents as SternumDeviceEventInfo[]);
                }

                if (this.props.dynamicColumns) {
                    dynamicColumnEntities = (getSternumDeviceEventsResponse as GetSternumUniqueCountEventsResponse)
                        .sternumDeviceEvents;
                    dynamicColumnEntitiesReachedEnd = dynamicColumnEntities.length < SternumConfiguration.getPageSize();
                } else {
                    entities = (getSternumDeviceEventsResponse as GetSternumDeviceEventsResponse).sternumDeviceEvents;
                }
            } else {
                // Load given page
                this.setState({ showInfinityLoader: true });
                getSternumDeviceEventsResponse = await this.fetchEntities(
                    this.state.currentCursor,
                    this.previousRequest.token
                );
                newCursor = getSternumDeviceEventsResponse.cursor;

                if (this.props.dynamicColumns) {
                    dynamicColumnEntities = this.state.dynamicColumnEntities.concat(
                        getSternumDeviceEventsResponse.sternumDeviceEvents
                    );
                    dynamicColumnEntitiesReachedEnd = dynamicColumnEntities.length < SternumConfiguration.getPageSize();
                } else {
                    entities = this.state.entities.concat(getSternumDeviceEventsResponse.sternumDeviceEvents);
                }

                // if we are in "view in context" mode, increase total count by amount of preloaded rows
                if (this.props.contextFromTimestamp) {
                    getSternumDeviceEventsResponse.totalItemCount += this.seenExtraPageSize;
                }
            }

            totalItemCount = Math.max(getSternumDeviceEventsResponse.totalItemCount, entities?.length || 0);

            this.setState({
                errorLoadingEntities: false,
                entities: [...entities],
                totalItemCount: totalItemCount,
                showInfinityLoader: false,
                currentCursor: newCursor,
                dynamicColumnEntities,
                dynamicColumnEntitiesReachedEnd,
            });
        } catch (error) {
            if (axios.isCancel(error)) {
                console.warn("Previous request was canceled while scrolling");
                this.setState({
                    loadingEntities: false,
                });
                return;
            }

            AnalyticsService.error("SternumDeviceEventsList:loadEntitiesForScroll", error.message);

            this.setState({
                showInfinityLoader: false,
                errorLoadingEntities: true,
            });
        }
    }

    /**
     * Api call for entities.
     */
    private async fetchEntities(
        cursor: string,
        cancelToken?: CancelToken
    ): Promise<GetSternumDeviceEventsResponseWithCursor | GetSternumUniqueCountEventsResponse> {
        let aggregationFunction = undefined;

        if (this.props.dynamicColumns) {
            aggregationFunction = AggregationFunctionType.UNIQUE_COUNT;
        } else if (
            this.props.aggregationFunctionType === AggregationFunctionType.COUNT &&
            this.state.entitiesFilter &&
            this.state.entitiesFilter.traceDefinitionIds &&
            this.state.entitiesFilter.traceDefinitionIds.includes(ConfigurationService.getDeviceIdArgumentField().id)
        ) {
            aggregationFunction = AggregationFunctionType.COUNT;
        }

        if (this.props.clientId && this.props.deviceIds?.length > 0) {
            // TODO: Replace endpoint here
            return await ServiceWire.getSternumService().getDeviceSternumMultipleDevicesEvents(
                this.props.clientId,
                this.props.deviceIds,
                this.state.entitiesFilter,
                this.state.searchText,
                this.state.order,
                [],
                cursor,
                SternumConfiguration.getPageSize(),
                this.props.deviceDefinitionVersionId,
                this.props.excludeLinuxViewTraces,
                undefined,
                aggregationFunction,
                this.props.dynamicColumns
                    ? this.props.dynamicColumns
                          .slice()
                          .reverse()
                          .map((col) => col.value)
                          .filter((col) => col !== "count")
                    : undefined,
                this.props.dynamicColumns &&
                    this.state.dynamicColumnEntities &&
                    this.state.dynamicColumnEntities.length > 0
                    ? Object.values(
                          omit(this.state.dynamicColumnEntities[this.state.dynamicColumnEntities.length - 1].columns, [
                              "count",
                          ])
                      )
                    : undefined,
                { cancelToken }
            );
        } else {
            // TODO: Replace endpoint here
            return await ServiceWire.getSternumService().getDeviceSternumDeviceEvents(
                this.props.entityId,
                this.state.entitiesFilter,
                this.state.searchText,
                this.state.order,
                [],
                cursor,
                SternumConfiguration.getPageSize(),
                this.props.deviceDefinitionVersionId,
                this.props.excludeLinuxViewTraces,
                undefined,
                aggregationFunction,
                this.props.dynamicColumns
                    ? this.props.dynamicColumns
                          .slice()
                          .reverse()
                          .map((col) => col.value)
                          .filter((col) => col !== "count")
                    : undefined,
                this.props.dynamicColumns &&
                    this.state.dynamicColumnEntities &&
                    this.state.dynamicColumnEntities.length > 0
                    ? Object.values(
                          omit(this.state.dynamicColumnEntities[this.state.dynamicColumnEntities.length - 1].columns, [
                              "count",
                          ])
                      )
                    : undefined,
                [],
                { cancelToken }
            );
        }
    }

    /**
     * Api call for extra entities (history) when context is in use.
     */
    private async fetchExtraEntitiesForContext(
        cursor: string
    ): Promise<GetSternumDeviceEventsResponse | GetSternumUniqueCountEventsResponse> {
        let aggregationFunction = undefined;

        if (this.props.dynamicColumns) {
            aggregationFunction = AggregationFunctionType.UNIQUE_COUNT;
        } else if (
            this.props.aggregationFunctionType === AggregationFunctionType.COUNT &&
            this.state.entitiesFilter &&
            this.state.entitiesFilter.traceDefinitionIds &&
            this.state.entitiesFilter.traceDefinitionIds.includes(ConfigurationService.getDeviceIdArgumentField().id)
        ) {
            aggregationFunction = AggregationFunctionType.COUNT;
        }

        if (this.props.clientId && this.props.deviceIds?.length > 0) {
            // TODO: Replace endpoint here
            return await ServiceWire.getSternumService().getDeviceSternumMultipleDevicesEvents(
                this.props.clientId,
                this.props.deviceIds,
                {
                    ...this.state.entitiesFilter,
                    createdFrom: null,
                    createdTo: this.props.contextFromTimestamp,
                    lessThanId: this.props.contextTraceId,
                    greaterThanId: null,
                } as SternumDeviceEventsFilter,
                this.state.searchText,
                "desc",
                [],
                cursor,
                SternumConfiguration.getPageSize(),
                this.props.deviceDefinitionVersionId,
                this.props.excludeLinuxViewTraces,
                undefined,
                aggregationFunction,
                this.props.dynamicColumns
                    ? this.props.dynamicColumns
                          .slice()
                          .reverse()
                          .map((col) => col.value)
                          .filter((col) => col !== "count")
                    : undefined,
                this.props.dynamicColumns &&
                    this.state.dynamicColumnEntities &&
                    this.state.dynamicColumnEntities.length > 0
                    ? Object.values(
                          omit(this.state.dynamicColumnEntities[this.state.dynamicColumnEntities.length - 1].columns, [
                              "count",
                          ])
                      )
                    : undefined
            );
        } else {
            // TODO: Replace endpoint here
            return await ServiceWire.getSternumService().getDeviceSternumDeviceEvents(
                this.props.entityId,
                {
                    ...this.state.entitiesFilter,
                    createdFrom: null,
                    createdTo: this.props.contextFromTimestamp,
                    lessThanOrEqualToId: this.props.contextTraceId,
                    greaterThanId: null,
                } as SternumDeviceEventsFilter,
                this.state.searchText,
                "desc",
                [],
                cursor,
                SternumConfiguration.getPageSize(),
                this.props.deviceDefinitionVersionId,
                this.props.excludeLinuxViewTraces,
                undefined,
                aggregationFunction,
                this.props.dynamicColumns
                    ? this.props.dynamicColumns
                          .slice()
                          .reverse()
                          .map((col) => col.value)
                          .filter((col) => col !== "count")
                    : undefined,
                this.props.dynamicColumns &&
                    this.state.dynamicColumnEntities &&
                    this.state.dynamicColumnEntities.length > 0
                    ? Object.values(
                          omit(this.state.dynamicColumnEntities[this.state.dynamicColumnEntities.length - 1].columns, [
                              "count",
                          ])
                      )
                    : undefined
            );
        }
    }

    /**
     * Occurs when the export to csv button clicked.
     */
    private async onExportToCsvClicked(format: "CSV" | "XLSX") {
        this.setState({ displayLoadingIcon: true });
        const serverResponse: HttpResponse = await ServiceWire.getSternumService().exportSternumEvents(
            this.props.entityId,
            this.state.entitiesFilter,
            this.state.searchText,
            this.state.order,
            [],
            null,
            null,
            this.props.deviceDefinitionVersionId,
            format,
            this.props.excludeLinuxViewTraces
        );

        this.setState({ displayLoadingIcon: false });

        // Download the report.
        WebUtils.downloadReport(serverResponse);
    }

    /**
     *
     * Callback to handle copy event. Get the header to add into copy operation
     */
    onCopyEvent = (headerName: string) => {
        const eventEntity = this.state.rightClickedRow as SternumDeviceEventInfo;
        let value;
        switch (headerName) {
            case "Device Id":
                value = eventEntity.device.receivedDeviceId;
                break;
            case "Name":
                value = eventEntity.getDisplayName();
                break;
            case "Category":
                value = eventEntity.getCategoryDisplay();
                break;
            case "Received":
                value = moment(eventEntity.created).format("MM/DD/YYYY HH:mm");
                break;
            case "Type":
                value = eventEntity.getEventType();
                break;
            case "Arguments":
                value = eventEntity.traceInfo?.getArguments();
                break;
        }
        WebUtils.copyContentToClipBoard(value);
        this.setState({ openRowMenu: false });
    };

    onCellCopyClick = (cellName: string, clickedRow: TableRowData, event) => {
        this.setState({
            openRowMenu: true,
            anchorEl: event.target,
            rightClickedRow: clickedRow,
            rightClickedInfo: { label: `Copy ${cellName}`, value: cellName },
        });
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(sternumDeviceEventsListStyle)(SternumDeviceEventsList)));

function cutDisplayedString(
    text: string,
    maxDisplayedTextWithoutDots: number,
    displayedTextCharsWithDots: number = maxDisplayedTextWithoutDots - 2
) {
    if (text.length <= maxDisplayedTextWithoutDots) {
        return text;
    }

    return text.slice(0, displayedTextCharsWithDots) + "...";
}
