import { Action } from "redux";

import ActionType from "../ActionType";

export interface ToggleAlertsHeatmapInteractionsAction extends Action<ActionType> {
    type: ActionType.DashboardRegularToggleAlertsHeatmapInteractions;
    enabled: boolean;
}

export function toggleAlertsHeatmapInteractionsAction(enabled: boolean): ToggleAlertsHeatmapInteractionsAction {
    return {
        type: ActionType.DashboardRegularToggleAlertsHeatmapInteractions,
        enabled,
    };
}
