import { makeStyles } from "@material-ui/core";

export const useTitleWithTagStyle = makeStyles((theme) => {
    const whiteMedium = "#e6eaed";
    const blackHard = "#2b2e30";

    return {
        container: {
            display: "flex",
            alignItems: "center",
            gap: 8,
            color: blackHard,
            fontSize: 14,
            fontWeight: 600,
        },

        tag: {
            background: whiteMedium,
            borderRadius: 4,
            padding: "2px 4px",
            fontSize: 10,
            fontWeight: 400,
        },
    };
});
