import { Chip, Typography } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    openCreateDashboardModalAction,
    openEditDashboardModalAction,
} from "../../../lib/redux/modals/OpenModalAction";
import PollingSubscriptionInfo from "../../../lib/services/PollingSubscriptionInfo";
import ServiceWire from "../../../lib/services/ServiceWire";
import CustomDashboardInfo from "../../../lib/state/CustomDashboardInfo";
import { GlobalState } from "../../../lib/state/GlobalState";
import PollingChangeType from "../../../lib/state/PollingChangeType";
import ServerEntityType from "../../../lib/state/ServerEntityType";
import { DesktopIcon } from "../../SUI/SternumIcon/SternumIcon";
import DashboardMenu from "../DashboardMenu/DashboardMenu";
import dashboardsBrowserStyle from "./DashboardsBrowserStyle";

const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        openCreateDashboardModal: (key: string, onCreateOperation: (customDashboard: CustomDashboardInfo) => void) =>
            dispatch(openCreateDashboardModalAction(key, onCreateOperation)),
        openEditDashboardModal: (
            key: string,
            dashboard: CustomDashboardInfo,
            onEditOperation: (customDashboard: CustomDashboardInfo) => void
        ) => dispatch(openEditDashboardModalAction(key, dashboard, onEditOperation)),
    };
};

interface AppState {
    loadingDashboards: boolean;
    errorLoadingDashboards: boolean;
    customDashboards: CustomDashboardInfo[];
    isDisabled: boolean;
    selectedDashboardId: string;
}

export interface AppProps extends WithStyles<typeof dashboardsBrowserStyle> {
    doNotDisplayDashboardMenu?: boolean;
    doNotDisplayAddDashboard?: boolean;
    doNotRedirectToDashboardPage?: boolean;

    selectedDashboardId?: string;

    minified?: boolean;
    onDashboardClicked?: (dashboardId: string) => void;
    onDashboardUpdated?: (dashboardId: string) => void;

    openCreateDashboardModal?: (key: string, onCreateOperation: (customDashboard: CustomDashboardInfo) => void) => void;

    openEditDashboardModal?: (
        key: string,
        dashboard: CustomDashboardInfo,
        onEditOperation?: (customDashboard: CustomDashboardInfo) => void
    ) => void;
    history;
    closeBrowser?: () => void;

    // Update parent with deleted id ( if we deleted the viewed dashboard, take action)
    onDeletedDashboard?: (dashboardId: string) => void;

    forceReloadDashboards?: boolean;
}

class DashboardsBrowser extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            loadingDashboards: false,
            errorLoadingDashboards: false,
            customDashboards: [],
            isDisabled: !ServiceWire.getAuthorizationService().canEdit(ServerEntityType.CUSTOM_DASHBOARD),
            selectedDashboardId: this.props.selectedDashboardId,
        };
    }

    async componentDidMount() {
        await this.loadDashboards(true);
        ServiceWire.getPollingService().subscribe(
            new PollingSubscriptionInfo(
                "CustomDashboardUpdates",
                PollingChangeType.ALL_CUSTOM_DASHBOARDS,
                this.handleChangedCustomDashboards.bind(this)
            )
        );
    }

    componentDidUpdate(prevProps: AppProps) {
        if (this.props.forceReloadDashboards && this.props.forceReloadDashboards !== prevProps.forceReloadDashboards) {
            this.loadDashboards(false);
        }
    }

    componentWillUnmount(): void {
        ServiceWire.getPollingService().unsubscribe("CustomDashboardUpdates", PollingChangeType.ALL_CUSTOM_DASHBOARDS);
    }

    async UNSAFE_componentWillReceiveProps(nextProps: Readonly<AppProps>, nextContext: any) {
        if (nextProps.selectedDashboardId !== this.props.selectedDashboardId) {
            this.setState({
                selectedDashboardId: nextProps.selectedDashboardId,
            });
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <div role="presentation" aria-label="dashboard browser container" className={classNames(classes.root)}>
                {this.state.errorLoadingDashboards && (
                    <Typography variant="body2" className={classNames(classes.errorText)}>
                        Error...
                    </Typography>
                )}

                <div className={classNames(classes.dashboardsContainer)}>
                    {this.state.loadingDashboards &&
                        [1, 2, 3].map((numberedItem) => {
                            return (
                                <div
                                    key={numberedItem}
                                    className={classNames(
                                        classes.dashboardContainer,
                                        this.props.minified && "mod-minified"
                                    )}
                                >
                                    <div className={classNames(classes.loadingPlaceholder, "mod-medium-column")} />
                                </div>
                            );
                        })}

                    {this.state.customDashboards &&
                        this.state.customDashboards.map((dashboard, dashboardIndex) => {
                            return (
                                <div
                                    role="presentation"
                                    aria-label="glance custom tile"
                                    className={classNames(
                                        classes.dashboardContainer,
                                        this.props.minified && "mod-minified",
                                        this.state.selectedDashboardId === dashboard.customDashboardId && "mod-selected"
                                    )}
                                    key={dashboard.customDashboardId}
                                    onClick={() => this.redirectToDashboardPage(dashboard)}
                                >
                                    <div>
                                        <div className={classNames(classes.dashboardTitleContainer)}>
                                            <DesktopIcon className={classNames(classes.dashboardTitleIcon)} />

                                            <Typography variant="body2" className={classNames(classes.dashboardTitle)}>
                                                {dashboard.displayName}
                                            </Typography>
                                        </div>
                                    </div>

                                    <div className={classNames(classes.dashboardBoxFooter)}>
                                        {dashboard.isPredefinedLinuxDashboard() && (
                                            <Chip
                                                className={classes.predefinedDashboardChip}
                                                color="primary"
                                                label="Predefined"
                                            />
                                        )}

                                        <div className={classNames(classes.flexGrow)} />

                                        {!this.state.isDisabled && (
                                            <span onClick={(event) => event.stopPropagation()}>
                                                <DashboardMenu
                                                    customDashboardId={dashboard.customDashboardId}
                                                    onEditClicked={() => this.onEditDashboardClicked(dashboard)}
                                                    onDashboardDeleted={() => this.onDashboardDeleted(dashboardIndex)}
                                                />
                                            </span>
                                        )}
                                    </div>
                                </div>
                            );
                        })}

                    {!this.state.loadingDashboards && !this.state.isDisabled && (
                        <div
                            role="presentation"
                            aria-label="glance tile add view"
                            className={classNames(
                                classes.dashboardContainer,
                                "mod-add-new",
                                this.props.minified && "mod-minified"
                            )}
                            onClick={this.onAddDashboardClicked}
                        >
                            <div role="button" className={classNames(classes.flexVMiddle)}>
                                <AddIcon color={"primary"} className={classes.glaceIcon} />
                                <Typography variant="body2" className={classNames(classes.addDashboardText)}>
                                    Add View
                                </Typography>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    private onDashboardDeleted(dashboardIndex: number) {
        const dashboardId: string = this.state.customDashboards[dashboardIndex].customDashboardId;
        const clonedCustomDashboards = [...this.state.customDashboards];

        clonedCustomDashboards.splice(dashboardIndex, 1);

        this.setState({
            customDashboards: clonedCustomDashboards,
        });

        // Update parent on delete operation
        if (this.props.onDeletedDashboard) {
            this.props.onDeletedDashboard(dashboardId);
        }
    }

    private async handleChangedCustomDashboards(fromTimestamp: number, changedData: Object) {
        if (!changedData) return;
        await this.loadDashboards(false);
    }

    private onCreateOperation = (customDashboard: CustomDashboardInfo) => {
        const entitiesList = [...this.state.customDashboards];
        entitiesList.push(customDashboard);
        this.setState({
            customDashboards: entitiesList,
        });
        this.redirectToDashboardPage(customDashboard);
    };

    private onEditOperation = (customDashboard: CustomDashboardInfo) => {
        const entitiesList = this.state.customDashboards.map((dashboard) =>
            dashboard.customDashboardId === customDashboard.customDashboardId ? customDashboard : dashboard
        );
        this.setState({
            customDashboards: entitiesList,
        });

        this.props.onDashboardUpdated?.(customDashboard.customDashboardId);
    };

    private onAddDashboardClicked = () => {
        this.props.openCreateDashboardModal("CreateDashboardModal", this.onCreateOperation);
    };

    private onEditDashboardClicked = (dashboard: CustomDashboardInfo) => {
        this.props.openEditDashboardModal("EditDashboardModal", dashboard, this.onEditOperation);
    };

    private redirectToDashboardPage(dashboard: CustomDashboardInfo) {
        this.setState({
            selectedDashboardId: dashboard.customDashboardId,
        });

        // Set local storage for default custom dashboard
        ServiceWire.getClientsService().setCustomDashboard(dashboard.customDashboardId);

        if (!this.props.doNotRedirectToDashboardPage) {
            this.props.history.push({
                pathname: `/glances/${dashboard.customDashboardId}`,
            });
        } else {
            if (this.props.onDashboardClicked) {
                this.props.onDashboardClicked(dashboard.customDashboardId);
            }
        }
    }

    private async loadDashboards(displayLoader: boolean) {
        try {
            this.setState({
                loadingDashboards: displayLoader,
                errorLoadingDashboards: false,
            });
            const dashboardEntities: CustomDashboardInfo[] =
                await ServiceWire.getCustomDashboardsApiService().getCustomDashboardsSummaries(
                    ServiceWire.getClientsService().getSelectedClientId()
                );

            this.setState({
                loadingDashboards: false,
                errorLoadingDashboards: false,
                customDashboards: dashboardEntities,
            });
        } catch {
            this.setState({
                loadingDashboards: false,
                errorLoadingDashboards: true,
            });
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(dashboardsBrowserStyle)(DashboardsBrowser)));
