import { Box, IconButton, Link, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { CrossIcon } from "../../SUI/SternumIcon";
import { useStyles } from "./SimulateAttackInstructionBannerStyle";

interface SimulateAttackInstructionBannerProps {
    open?: boolean;
    openAttackSimulationModal: () => void;
}

const CLOSE_AFTER = 10000;

export function SimulateAttackInstructionBanner({
    open = true,
    openAttackSimulationModal,
}: SimulateAttackInstructionBannerProps) {
    const [isOpen, setOpen] = useState(open);
    const classes = useStyles();

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setOpen(false);
        }, CLOSE_AFTER);
        return () => clearTimeout(timeoutId);
    });

    useEffect(() => {
        if (!open) {
            setOpen(false);
        }
    }, [open]);

    if (!isOpen) return null;

    return (
        <div className={classes.container}>
            <Typography className={classes.title}>
                To use Sternum Attack Simulation Kit for sandboxed pen-testing follow the instructions
                <Link className={classes.linkButton} variant="body2" onClick={openAttackSimulationModal}>
                    here
                </Link>
            </Typography>
            <IconButton onClick={() => setOpen(false)}>
                <CrossIcon />
            </IconButton>
        </div>
    );
}
