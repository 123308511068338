import React from "react";
import classNames from "classnames";

import { MainSectionTitle } from "./MainSectionTitle.component";
import { MainSectionRightActions } from "./MainSectionRightActions.component";
import { MainSectionContent } from "./MainSectionContent.component";
import { MainSectionInline } from "./MainSectionInline.component";
import { useMainSectionStyle } from "./MainSection.style";

export interface MainSectionProps extends React.ComponentProps<"div"> {}

export function MainSection({ className, children, ...props }: MainSectionProps) {
    const classes = useMainSectionStyle();

    return (
        <div className={classNames(classes.mainSection, className)} {...props}>
            {children}
        </div>
    );
}

MainSection.Inline = MainSectionInline;
MainSection.Title = MainSectionTitle;
MainSection.RightActions = MainSectionRightActions;
MainSection.Content = MainSectionContent;
