import FieldType from "../state/FieldType";
import SternumQueryInputValueConfiguration from "./SternumQueryInputValueConfiguration";
import ConditionType from "../state/ConditionType";

/**
 * Represents a field in a query in Sternum.
 */
class SternumQueryField {
    /**
     * Constructor.
     */
    constructor(
        public apiName: string,
        public value: string,
        public label: any,
        public fieldType: FieldType,
        public inputTypeConfiguration: SternumQueryInputValueConfiguration,
        public isSpecialField?: boolean,
        public onlyAllowedConditions?: ConditionType[],
        public isSternumTrigger?: boolean
    ) {}
}

export default SternumQueryField;
