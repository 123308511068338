export function getFrontendVersion() {
    return process.env.APP_VERSION;
}

export async function getBackendVersion() {
    return "NOT IMPLEMENTED";
}

export async function displayVersion() {
    const frontendVersion = getFrontendVersion();
    let backendVersion = "LOADING...";

    try {
        backendVersion = await getBackendVersion();
    } catch (e) {
        backendVersion = `network error: ${e}`;
    } finally {
        console.table({
            frontend: { version: frontendVersion },
            backend: { version: backendVersion },
        });
    }
}
