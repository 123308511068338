import { CircularProgress, InputAdornment, TextField, WithStyles, withStyles } from "@material-ui/core";
import classNames from "classnames";
import * as React from "react";
import customInputStyle from "./CustomInputStyle";

interface AppState {
    isFocus: boolean;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof customInputStyle> {
    value: string;
    inputTitle: string;
    inputClasses: any;
    maxHeightClass: any;
    autoFocus: boolean;
    disabled?: boolean;
    id: string;
    onChange: (event: any) => void;
    onKeyDown?: (event: any) => void;
    onBlur?: () => void;
    dense?: boolean;
    required?: boolean;
    select?: boolean;
    urlPrefix?: boolean;
    helperText?: string;
    customWidth?: any;
    loadingIcon?: boolean;
    isPassword?: boolean;
    isError?: boolean;
    displayHelpText?: boolean;
    maxLength?: number;
    placeHolder?: string;
}

class CustomInput extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            isFocus: false,
        };
    }

    componentDidUpdate() {}

    private onChange = (event) => {
        this.props.onChange(event);
    };

    private onKeyDown = (event) => {
        this.props.onKeyDown(event);
    };

    /**
     * Build new label if component is selected and max length is given
     */
    private getLabel = () => {
        const { classes } = this.props;
        return this.state.isFocus && this.props.maxLength ? (
            <>
                <span className={classes.marginRight}>{this.props.inputTitle}</span>
                <span>
                    {this.props.value.length}/{this.props.maxLength}
                </span>
            </>
        ) : (
            this.props.inputTitle
        );
    };

    private getHelperText = (): string => {
        if (this.props.helperText && this.props.displayHelpText) {
            return this.props.helperText;
        }
        return "";
    };

    render() {
        const { classes } = this.props;
        return (
            <TextField
                role="presentation"
                aria-label="sternum custom input"
                placeholder={this.props.placeHolder ? this.props.placeHolder : null}
                onFocus={() => {
                    this.setState({ isFocus: true });
                }}
                onBlur={() => {
                    this.setState({ isFocus: false });
                    this.props.onBlur?.();
                }}
                error={this.props.isError}
                className={classNames(this.props.customWidth ? this.props.customWidth : classes.fixedInputTextAreaWith)}
                required={!!this.props.required && this.props.required}
                autoFocus={this.props.autoFocus}
                autoComplete="off"
                id={this.props.id}
                label={this.getLabel()}
                variant="outlined"
                value={this.props.value}
                disabled={!!this.props.disabled && this.props.disabled}
                InputLabelProps={{
                    classes: {
                        root: this.props.disabled ? classes.disabledLabel : classes.cssLabel,
                        focused: classes.cssFocused,
                    },
                }}
                inputProps={
                    this.props.maxLength && {
                        maxLength: this.props.maxLength,
                    }
                }
                InputProps={{
                    classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                        input: this.props.select ? classes.inputSelect : classes.input,
                    },
                    startAdornment: this.props.urlPrefix ? (
                        <InputAdornment position="start">https://</InputAdornment>
                    ) : (
                        ""
                    ),
                    endAdornment: this.props.loadingIcon ? (
                        <InputAdornment position="end">
                            <CircularProgress size={20} />
                        </InputAdornment>
                    ) : (
                        ""
                    ),
                }}
                SelectProps={{
                    classes: {
                        icon: classes.arrowIcon,
                    },
                    MenuProps: {
                        PopoverClasses: {
                            paper: classes.menuPaper,
                        },
                        classes: {
                            list: classes.menuList,
                        },
                    },
                }}
                FormHelperTextProps={{
                    classes: {
                        contained: classes.formHelperText,
                    },
                }}
                onChange={this.onChange}
                onKeyDown={!!this.props.onKeyDown ? this.onKeyDown : () => {}}
                select={!!this.props.select && this.props.select}
                helperText={this.getHelperText()}
                type={this.props.isPassword ? "password" : ""}
            >
                {this.props.children}
            </TextField>
        );
    }
}

export default withStyles(customInputStyle)(CustomInput);
