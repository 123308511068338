import { PatternReceivedTraceStatus, PatternWithTraces } from "../../lib/state/Pattern";

export const anomaliesResponsePatternsDemoData: PatternWithTraces[] = [
    {
        name: "Reference Pattern 1",
        items: [
            {
                name: "Boot",
                order: 1,
            },
            {
                name: "Task Started",
                order: 2,
            },
            {
                name: "Current Memory Usage",
                order: 3,
            },
        ],
        receivedTraces: [
            {
                orderIndex: 0,
                receivedTraceId: "boot-id-1",
                receivedTime: 1667942105114,
                traceStatus: PatternReceivedTraceStatus.Received,
                eventType: "Boot",
            },
            {
                orderIndex: 0,
                receivedTraceId: "boot-id-2",
                receivedTime: 1667942105114,
                traceStatus: PatternReceivedTraceStatus.Received,
                eventType: "Task Started",
            },
            {
                orderIndex: 0,
                receivedTraceId: "boot-id-unreceived-1",
                receivedTime: 1667942105114,
                traceStatus: PatternReceivedTraceStatus.Unexpected,
                eventType: "Remote SPP Port Closed",
            },
            {
                orderIndex: 0,
                receivedTraceId: "boot-id-not-received-1",
                receivedTime: 1667942105114,
                traceStatus: PatternReceivedTraceStatus.NotReceived,
                eventType: "Current Memory Usage",
            },
        ],
    },
];
