import React, { useMemo } from "react";
import classNames from "classnames";
import { Box } from "@material-ui/core";
import { connect } from "react-redux";

import IssuesFilter from "../../../lib/state/IssuesFilter";
import SternumConfiguration from "../../../lib/infra/SternumConfiguration";
import TableColumnHeaderInfo from "../../../lib/state/TableColumnHeaderInfo";
import { GlobalState } from "../../../lib/state/GlobalState";
import { IssueInfoStatus } from "../../../lib/state/IssueInfoType";
import { convertCategoryTimeRangeToNumeric } from "../../../lib/infra/Alerts.utils";
import { setAlertTableExpandedAction } from "../../../lib/redux/dashboardRegular/SetAlertTableExpandedAction";
import DashboardMetricsDataTile from "../../DashboardMetricsDataTile/DashboardMetricsDataTile";
import IssuesList from "../../IssuesList/IssuesList";
import { useCommonStyle } from "../../CommonStyle";
import { InfoCircleIcon } from "../../SUI/SternumIcon";
import { SternumTooltip } from "../../SUI/SternumTooltip";
import { MainSection } from "../MainSection";
import { useDashboardStyle } from "./DashboardRegular.style";
import { CategoryActions } from "./CategoryActions";
import { IssuesVisualisation } from "./IssuesVisualisation";
import { AlertsFilter } from "./AlertsFilter";
import { CategoryGraphView } from "../../../lib/state/DashboardRegularState";

export interface DashboardRegularProps {
    sidebarOpen: boolean;
}

const mapStateToProps = (state: GlobalState, ownProps: DashboardRegularProps) => {
    return {
        categoryTimeRange: state.dashboardRegular.categoryTimeRange,
        categoryTimeRangeCustomFrom: state.dashboardRegular.categoryTimeRangeCustomFrom,
        categoryTimeRangeCustomTo: state.dashboardRegular.categoryTimeRangeCustomTo,
        isOnlyUnresolved: state.dashboardRegular.isOnlyUnresolved,
        isOnlyAnomalyInsights: state.dashboardRegular.isOnlyAnomalyInsights,
        selectedCategories: state.dashboardRegular.alertsFilter.selectedCategories,
        isAlertTableExpanded: state.dashboardRegular.isAlertTableExpanded,
        selectedGraphView: state.dashboardRegular.selectedGraphView,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setAlertTableExpanded: (isExpanded: boolean) => dispatch(setAlertTableExpandedAction(isExpanded)),
    };
};

type DashboardRegularPropsWithHOC = DashboardRegularProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export function DashboardRegularComponent({
    categoryTimeRange,
    categoryTimeRangeCustomFrom,
    categoryTimeRangeCustomTo,
    isOnlyUnresolved,
    isOnlyAnomalyInsights,
    selectedCategories,
    isAlertTableExpanded,
    setAlertTableExpanded,
    selectedGraphView,
}: DashboardRegularPropsWithHOC) {
    const classes = useDashboardStyle();
    const classesCommon = useCommonStyle();

    const { from, to } = useMemo(() => {
        return convertCategoryTimeRangeToNumeric(categoryTimeRange, {
            from: categoryTimeRangeCustomFrom,
            to: categoryTimeRangeCustomTo,
        });
    }, [categoryTimeRange, categoryTimeRangeCustomFrom, categoryTimeRangeCustomTo]);

    const getInitialColumnHeaders = useMemo(
        () => (): TableColumnHeaderInfo[] =>
            [
                new TableColumnHeaderInfo("created", "Received", false, true, false, false, undefined, "Received"),
                new TableColumnHeaderInfo(
                    "alertName",
                    "Alert Name",
                    false,
                    true,
                    false,
                    false,
                    undefined,
                    "Alert Name"
                ),
                new TableColumnHeaderInfo(
                    "traceCategory",
                    "Category",
                    false,
                    true,
                    false,
                    false,
                    undefined,
                    "Category"
                ),
                new TableColumnHeaderInfo(
                    "traceEventType",
                    "Alert Type",
                    false,
                    true,
                    false,
                    false,
                    undefined,
                    "Alert Type"
                ),
                new TableColumnHeaderInfo(
                    "receivedDeviceId",
                    "Device ID",
                    false,
                    true,
                    false,
                    false,
                    undefined,
                    "Device ID"
                ),
                new TableColumnHeaderInfo(
                    "deviceName",
                    "Profile Name",
                    false,
                    true,
                    false,
                    false,
                    undefined,
                    "Profile Name"
                ),
                new TableColumnHeaderInfo(
                    "firmwareVersion",
                    "Firmware Version",
                    false,
                    true,
                    false,
                    false,
                    undefined,
                    "Firmware Version"
                ),
                new TableColumnHeaderInfo(
                    "state",
                    "Profile State",
                    false,
                    true,
                    false,
                    false,
                    undefined,
                    "Profile State"
                ),
                new TableColumnHeaderInfo("prevented", "Status", false, true, false, false, undefined, "Status"),
                new TableColumnHeaderInfo("isResolved", "Actions", false, true, false, false, undefined, "Actions"),
            ],
        []
    );

    return (
        <div
            className={classNames(classes.grid, classesCommon.fullHeight, {
                [classes.gridWhenAlertsTableExpanded]: isAlertTableExpanded,
            })}
        >
            {!isAlertTableExpanded && (
                <div
                    className={classNames(
                        classes.dataTile,
                        classes.metricDataTile,
                        "with-min-height",
                        classesCommon.flexColumn,
                        "mod-no-border"
                    )}
                >
                    <DashboardMetricsDataTile />
                </div>
            )}

            <MainSection style={{ maxHeight: isAlertTableExpanded ? "100vh" : "90vh" }}>
                <MainSection.Inline>
                    <Box display="flex" alignItems="center">
                        <MainSection.Title>{isAlertTableExpanded ? "Alerts" : "Alert Handling"}</MainSection.Title>
                        <SternumTooltip
                            placement="right"
                            useWrapper={false}
                            title={
                                <div>
                                    Navigate through categories of alerts and anomalies within specific time range.
                                    Filter the categories of interest. Dive deeper into each issue to investigate it
                                    further.
                                    <br />
                                    <br />
                                    Over Time: Observe changes over time.
                                </div>
                            }
                        >
                            <Box display="inline-flex" ml={2}>
                                <InfoCircleIcon width={18} height={18} color="#1B6FDE" />
                            </Box>
                        </SternumTooltip>
                    </Box>

                    <MainSection.RightActions>
                        <CategoryActions />
                    </MainSection.RightActions>
                </MainSection.Inline>

                {isAlertTableExpanded && (
                    <MainSection.Inline>
                        <MainSection.Title>
                            <AlertsFilter />
                        </MainSection.Title>
                    </MainSection.Inline>
                )}

                {!isAlertTableExpanded && (
                    <MainSection.Inline>
                        <IssuesVisualisation startTime={from} endTime={to} />
                    </MainSection.Inline>
                )}

                {selectedGraphView !== CategoryGraphView.Map && (
                    <MainSection.Content>
                        <div
                            className={classNames({
                                [classes.issuesListContainer]: !isAlertTableExpanded,
                                [classes.issuesListContainerFullScreen]: isAlertTableExpanded,
                            })}
                        >
                            <IssuesList
                                initialColumnHeaders={getInitialColumnHeaders()}
                                enablePolling={true}
                                issuesFilter={
                                    new IssuesFilter(
                                        from,
                                        to,
                                        false,
                                        false,
                                        isOnlyUnresolved ? [IssueInfoStatus.Open] : [],
                                        selectedCategories,
                                        isOnlyAnomalyInsights,
                                        false,
                                        true
                                    )
                                }
                                displayXButtonInTraceView={true}
                                displayBackButton={false}
                                hideUpperBorder={true}
                                forceRefresh={false}
                                pageSize={SternumConfiguration.getPageSize()}
                                narrow
                                hideResolveAll
                                isExpanded={isAlertTableExpanded}
                                onExpandedToggle={() => setAlertTableExpanded(!isAlertTableExpanded)}
                                deviceProfileStatus="PRODUCTION"
                            />
                        </div>
                    </MainSection.Content>
                )}
            </MainSection>
            {/* for now we dont display it, because we dont have enough content for it :( */}
            {/* {metricsLoaded && <DashboardRegularOnboardingSteps />} */}
        </div>
    );
}

export const DashboardRegular: React.FC<DashboardRegularProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardRegularComponent);
