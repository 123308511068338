import { Theme } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../../components/CommonStyle";

const interactiveCoverageDialogStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),
        dialog: {
            background: "white",
            zIndex: 1000,
            position: "fixed",
            minWidth: "550px",
            boxShadow: "0px 0px 50px rgba(0, 0, 0, 0.12)",
            borderRadius: "20px",
            top: "50%",
            left: "50%",
        },
        title: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            " .MuiIconButton-root:hover": {
                background: "none",
                borderRadius: 0,
            },
        },
        description: {
            maxWidth: "500px",
            fontSize: "14px",
            color: "#555B61",
        },
        infoTooltip: {
            background: "#1B1B1B",
            fontSize: "12px",
        },
        checkboxContainer: {
            display: "flex",
            flexDirection: "row",
            margin: "10px 20px 30px 20px",
        },
        icon: {
            marginLeft: "5.5px",
        },
        chartWrapper: {
            position: "relative",
        },
        dialogContent: {
            display: "flex",
            flexDirection: "row",
        },
        dialogActions: {
            padding: 0,
        },
        chart: {
            minHeight: "450px",
            minWidth: "500px",
        },
        chartTitle: {
            justifyContent: "center",
        },
        dragContainer: {
            display: "flex",
            alignItems: "center",
            position: "absolute",
            right: "-5px",
            top: "50%",
            padding: "5px",
            zIndex: 1,
            transform: "translateY(-50%)",
            cursor: "pointer",
        },
        dialogWrapper: {
            width: "100%",
        },
        legend: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            rowGap: "15px",
            marginBottom: "30px",
        },
        legendItemContainer: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            flexBasis: "34%",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
        },
        legendItemText: {
            fontFamily: "Gilroy, sans-serif",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "20px",
            marginRight: "5px",
            flexWrap: "nowrap",
            whiteSpace: "nowrap",
        },
        legendItemCount: {
            padding: "2px 8px",
            borderRadius: "16px",
            fontFamily: "Gilroy, sans-serif",
            fontweight: 600,
            fontSize: "12px",
            lineHeight: "18px",
        },
    });

export default interactiveCoverageDialogStyle;
