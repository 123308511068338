import React from "react";
import { connect } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import classNames from "classnames";

import { GlobalState } from "../../../lib/state/GlobalState";
import { useCommonStyle } from "../../CommonStyle";
import SternumLink from "../../SUI/SternumLink/SternumLink";
import { ExternalLink2Icon } from "../../SUI/SternumIcon";
import { OpenWRTInstallerCommand } from "../../OpenWRTInstallerCommand";
import { useTrialSettingsDescriptionStyle } from "./TrialSettingsDescription.style";

export interface TrialSettingsDescriptionProps {}

const mapStateToProps = (state: GlobalState, ownProps: TrialSettingsDescriptionProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

type TrialSettingsDescriptionPropsWithHOC = TrialSettingsDescriptionProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

function TrialSettingsDescriptionComponent({}: TrialSettingsDescriptionPropsWithHOC) {
    const classes = useTrialSettingsDescriptionStyle();
    const classesCommon = useCommonStyle();

    const openWrtLink = (
        <SternumLink className={classes.sectionLink} href="https://firmware-selector.openwrt.org" target="_blank">
            link <ExternalLink2Icon />
        </SternumLink>
    );

    return (
        <div className={classes.trailSettingsDescriptionContainer}>
            <Typography variant={"h6"} className={classNames(classesCommon.marginRight)}>
                Free License Settings
            </Typography>
            <div className={classes.section}>
                <div className={classes.sectionTitle}>Prepare your OpenWrt</div>
                <div className={classes.sectionDescription}>
                    Sternum Platform's Free license allows you to protect the following major OpenWrt versions:
                    <ul>
                        <li>OpenWrt 21</li>
                        <li>OpenWrt 19</li>
                    </ul>
                </div>
                <div className={classes.sectionDescription}>
                    Don't have OpenWRT yet? Visit this {openWrtLink} to download a supported version.
                </div>
            </div>
            <div className={classes.section}>
                <div className={classes.sectionTitle}>Install Sternum Runtime Protection</div>
                <div className={classes.sectionDescription}>
                    Sternum provides real-time protection, detection and observability for all RTOS and Linux-operated
                    IIoT, IoMT and IoT devices.
                </div>

                <Box mt={3}>
                    <OpenWRTInstallerCommand showUninstallCommand />
                </Box>
            </div>
        </div>
    );
}

export const TrialSettingsDescription: React.FC<TrialSettingsDescriptionProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(TrialSettingsDescriptionComponent);
