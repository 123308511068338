import { makeStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";

export const useDiamondChipStyle = makeStyles((theme: Theme) => ({
    diamondChip: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 20,
        height: 20,
        background: "rgba(255, 179, 0, 0.15)",
        borderRadius: 6,
        marginLeft: 8,
    },
}));
