import { Action } from "redux";
import ActionType from "../ActionType";
import ModalKey from "../../state/ModalKey";

/**
 * Defines the data for the enter fullscreen modal action.
 */
export interface ExitModalFullscreenAction extends Action<ActionType> {
    type: ActionType.ExitModalFullscreen;
    modalKey: ModalKey;
}

/**
 * Action creator for exiting fullscreen mode for a modal.
 * @param modalKey The key of the modal to enter fullscreen.
 */
export function exitModalFullscreenAction(modalKey: ModalKey): ExitModalFullscreenAction {
    return {
        type: ActionType.ExitModalFullscreen,
        modalKey: modalKey,
    };
}
