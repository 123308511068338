import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import _, { maxBy, sum } from "lodash";
import React, { useEffect, useLayoutEffect, useRef } from "react";
import { renderToString } from "react-dom/server";
import { GraphData } from "../../../lib/infra/GraphUtils";
import ColorConfiguration from "../../ColorPicker/ColorConfiguration";
import SternumFlag from "../../SternumFlag/SternumFlag";

export interface SternumGeoMapProps {
    id: string;
    data?: Omit<GraphData, "labels">;
    height?: number | string;
    tooltipCallback?: TooltipCallback;
    useHeatMap?: boolean;
    heatMapRange?: ColorConfiguration;
}

type TooltipCallback = (value: string, target: am4maps.MapPolygon) => string;

am4core.useTheme(am4themes_animated);
am4core.options.autoDispose = true;

function onReady(
    chart: am4core.Container,
    tooltipCallback?: TooltipCallback,
    useHeatMap?: boolean,
    heatMapRange?: ColorConfiguration
) {
    let countryColor = am4core.color("#fff");
    let countryStrokeColor = am4core.color("#D9D9D9");

    let currentPolygon;

    let countryDataTimeout;

    const backgroundRectangle = chart.createChild(am4core.RoundedRectangle);
    backgroundRectangle.width = am4core.percent(100);
    backgroundRectangle.height = am4core.percent(100);
    backgroundRectangle.fill = am4core.color("#E0E9F3");
    backgroundRectangle.cornerRadius(14, 14, 14, 14);

    let mapChart = chart.createChild(am4maps.MapChart);
    mapChart.height = am4core.percent(100);
    mapChart.width = am4core.percent(100);
    mapChart.homeGeoPoint = { longitude: 10, latitude: 30 };
    mapChart.homeZoomLevel = 3;

    // clicking on a "sea" will also result a full zoom-out
    mapChart.seriesContainer.background.events.on("hit", showWorld);
    mapChart.seriesContainer.background.events.on("over", resetHover);

    // https://www.amcharts.com/docs/v4/chart-types/map/#Map_data
    // you can use more accurate world map or map of any other country - a wide selection of maps available at: https://github.com/amcharts/amcharts4-geodata
    mapChart.geodata = am4geodata_worldLow;

    // Set projection
    // https://www.amcharts.com/docs/v4/chart-types/map/#Setting_projection
    // instead of Miller, you can use Mercator or many other projections available: https://www.amcharts.com/demos/map-using-d3-projections/
    mapChart.projection = new am4maps.projections.Miller();
    mapChart.panBehavior = "move";

    mapChart.backgroundSeries.mapPolygons.template.polygon.fillOpacity = 0;
    mapChart.backgroundSeries.mapPolygons.template.polygon.fill = null;
    mapChart.backgroundSeries.hidden = false;
    mapChart.seriesContainer.background = null;
    mapChart.svgContainer.hideOverflow = false;

    // Map polygon series (defines how country areas look and behave)
    let polygonSeries = mapChart.series.push(new am4maps.MapPolygonSeries());
    polygonSeries.interpolationDuration = 0;

    polygonSeries.exclude = ["AQ"]; // Antarctica is excluded in non-globe projection
    polygonSeries.useGeodata = true;
    // this helps to place bubbles in the visual middle of the area
    polygonSeries.calculateVisualCenter = true;

    let polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.fill = countryColor;
    polygonTemplate.fillOpacity = 1;
    polygonTemplate.stroke = countryStrokeColor;
    polygonTemplate.strokeOpacity = 0.2;
    polygonTemplate.strokeWidth = 1;
    polygonTemplate.setStateOnChildren = true;
    polygonTemplate.tooltipPosition = "fixed";

    polygonTemplate.tooltip = new am4core.Tooltip();
    polygonTemplate.tooltip.background.filters.clear();
    polygonTemplate.tooltip.background.cornerRadius = 8;
    polygonTemplate.tooltip.getFillFromObject = false;
    polygonTemplate.tooltip.background.fill = am4core.color("#1B1B1B");
    polygonTemplate.tooltip.background.fillOpacity = 1;
    polygonTemplate.tooltip.background.stroke = null;
    polygonTemplate.tooltip.label.interactionsEnabled = true;
    polygonTemplate.tooltip.keepTargetHover = true;
    polygonTemplate.applyOnClones = true;
    polygonTemplate.hoverOnFocus = true;
    polygonTemplate.nonScalingStroke = true;

    polygonTemplate.events.on("hit", handleCountryHit);
    polygonTemplate.events.on("over", handleCountryOver);
    polygonTemplate.events.on("out", handleCountryOut);

    const polygonHoverState = polygonTemplate.states.create("hover");
    polygonHoverState.properties.stroke = am4core.color("#000");
    polygonHoverState.properties.strokeOpacity = 1;
    polygonHoverState.properties.strokeWidth = 2;

    const polygonActiveState = polygonTemplate.states.create("active");
    polygonActiveState.properties.stroke = am4core.color("#000");
    polygonActiveState.properties.strokeOpacity = 1;
    polygonActiveState.properties.strokeWidth = 2;
    polygonActiveState.properties.zIndex = Number.MAX_VALUE;

    polygonTemplate.adapter.add("tooltipHTML", function (text, target) {
        const data = target.tooltipDataItem.dataContext;

        if (tooltipCallback && data["value"]) {
            return tooltipCallback(text, target);
        }

        const countryFlag = <SternumFlag countryCode={data["id"]} />;

        return `
			<div style="display: flex; align-items: center">
				${renderToString(countryFlag)}
				<span style=" padding-left: 5px; font-size:14px; font-weight:600; font-family: Inter;"><strong>{name}</strong></span>
			</div>
		`;
    });

    polygonSeries.mapPolygons.template.tooltipHTML = "{tooltipContent}";

    // this is to prevent polygon from displaying broken outline, polygon should be placed in front of all other polygons by Z axis
    polygonSeries.mapPolygons.template.events.on("over", (event) => {
        event.target.zIndex = Number.MAX_VALUE;
        event.target.toFront();
    });

    // you can have pacific - centered map if you set this to -154.8
    mapChart.deltaLongitude = -10;

    if (useHeatMap && heatMapRange) {
        polygonSeries.heatRules.push({
            property: "fill",
            target: polygonTemplate,
            min: am4core.color(heatMapRange.light),
            max: am4core.color(heatMapRange.main),
            dataField: "value",
            logarithmic: true,
        });
    } else {
        polygonTemplate.propertyFields.fill = "backgroundColor";
    }

    // select a country
    function selectCountry(mapPolygon) {
        resetHover();
        polygonSeries.hideTooltip();

        // if the same country is clicked show world
        if (currentPolygon == mapPolygon) {
            currentPolygon.isActive = false;
            currentPolygon = undefined;
            showWorld();
            return;
        }
        // save current polygon
        currentPolygon = mapPolygon;

        // make others inactive
        polygonSeries.mapPolygons.each(function (polygon) {
            polygon.isActive = false;
        });

        // clear timeout if there is one
        if (countryDataTimeout) {
            clearTimeout(countryDataTimeout);
        }

        mapPolygon.isActive = true;
        mapChart.zoomToMapObject(mapPolygon, getZoomLevel(mapPolygon));
    }

    // change line chart data to the selected countries

    // what happens when a country is rolled-over
    function rollOverCountry(mapPolygon) {
        resetHover();
        if (mapPolygon) {
            mapPolygon.isHover = true;
        }
    }
    // what happens when a country is rolled-out
    function rollOutCountry(mapPolygon) {
        resetHover();
    }

    // calculate zoom level (default is too close)
    function getZoomLevel(mapPolygon) {
        let w = mapPolygon.polygon.bbox.width;
        let h = mapPolygon.polygon.bbox.width;
        // change 2 to smaller walue for a more close zoom
        return Math.min(mapChart.seriesWidth / (w * 2), mapChart.seriesHeight / (h * 2));
    }

    // show world data
    function showWorld() {
        currentPolygon = undefined;
        resetHover();

        if (countryDataTimeout) {
            clearTimeout(countryDataTimeout);
        }

        // make all inactive
        polygonSeries.mapPolygons.each(function (polygon) {
            polygon.isActive = false;
        });

        mapChart.goHome();
    }

    function handleCountryHit(event) {
        selectCountry(event.target);
    }

    function handleCountryOver(event) {
        rollOverCountry(event.target);
    }

    function handleCountryOut(event) {
        rollOutCountry(event.target);
    }

    function resetHover() {
        polygonSeries.mapPolygons.each(function (polygon) {
            polygon.isHover = false;
        });
    }

    function setMapData(newData: { datasets: any[] }, heatMapRange: ColorConfiguration) {
        const data = [];

        newData.datasets.forEach((dataset) => {
            data.push({
                id: dataset.country,
                value: dataset.totalCount,
                backgroundColor: dataset.backgroundColor,
                tooltipContent: dataset.tooltipContent,
                minBackgroundColor: dataset.minBackgroundColor,
                data: dataset.data,
            });
        });

        if (heatMapRange) {
            polygonSeries.heatRules.clear();
            polygonSeries.heatRules.push({
                property: "fill",
                target: polygonTemplate,
                min: am4core.color(heatMapRange.light),
                max: am4core.color(heatMapRange.main),
                dataField: "value",
                logarithmic: true,
            });
        }
        polygonSeries.data = data;
        polygonSeries.heatRules;

        // zoom map to country with most data for better UX

        setTimeout(() => {
            const countryWithMostData = maxBy(newData.datasets, (dataset) =>
                sum(
                    // @ts-ignore
                    Object.values(dataset.data).flatMap((dataPerDeviceDefinitionVersion) =>
                        Object.values(dataPerDeviceDefinitionVersion)
                    )
                )
            ).country;
            const polygon = polygonSeries.getPolygonById(countryWithMostData);

            mapChart.zoomToMapObject(polygon, getZoomLevel(polygon), true);
        }, 1000);
    }

    return { setMapData };
}

function SternumGeoMap({ id, height = 220, useHeatMap = false, heatMapRange = null, ...props }: SternumGeoMapProps) {
    const myChart = useRef<am4core.Container>(null);
    const chartController = useRef(null);

    useLayoutEffect(() => {
        let newChart = am4core.create(id, am4core.Container);
        newChart.width = am4core.percent(100);
        newChart.height = am4core.percent(100);
        myChart.current = newChart;

        chartController.current = onReady(newChart, props.tooltipCallback, useHeatMap, heatMapRange);

        return () => {
            newChart.dispose();
        };
    }, []);

    useEffect(() => {
        chartController.current?.setMapData(props.data, heatMapRange);
    }, [props.data, heatMapRange]);

    return <div id={id} style={{ width: "100%", height }} />;
}

export default React.memo(SternumGeoMap, (prevProps, nextProps) => {
    if (!_.isEqual(prevProps.data.datasets, nextProps.data.datasets)) {
        return false;
    }
    return true;
});
