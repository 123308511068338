import React from "react";

import { AppleLogoIcon, LinuxLogoIcon, WindowsLogoIcon } from "../../SUI/SternumIcon";
import { ReleaseSystem } from "../../../lib/state/DownloadType";

export const downloadSystemApps: {
    releaseSystem: ReleaseSystem;
    iconLogo: React.ReactNode;
    systemTitle: string;
    systemSubtitle?: string;
}[] = [
    {
        releaseSystem: ReleaseSystem.MacOsArm,
        iconLogo: <AppleLogoIcon />,
        systemTitle: "Mac",
        systemSubtitle: " - ARM64",
    },
    {
        releaseSystem: ReleaseSystem.MacOsIntel,
        iconLogo: <AppleLogoIcon />,
        systemTitle: "Mac",
        systemSubtitle: " - x64",
    },
    {
        releaseSystem: ReleaseSystem.Windows,
        iconLogo: <WindowsLogoIcon />,
        systemTitle: "Windows",
    },
    {
        releaseSystem: ReleaseSystem.Linux,
        iconLogo: <LinuxLogoIcon />,
        systemTitle: "Linux",
    },
];
