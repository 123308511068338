import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../../components/CommonStyle";

const timeSelectionSelectComponentStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        dateLabel: {
            fontSize: "1rem",
        },
    });

export default timeSelectionSelectComponentStyle;
