import React, { useEffect, useRef } from "react";
import { sortBy } from "lodash";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import ServiceWire from "../../lib/services/ServiceWire";
import { AnomaliesResponse } from "../../lib/state/Anomaly";

export interface AnomalyChartProps {
    issueId: string;
    onAnomaliesFetched?: (response: AnomaliesResponse) => unknown;
    hideLegendAndDescription?: boolean;
}

am4core.useTheme(am4themes_animated);
am4core.addLicense(process.env.STERNUM_AM_CHARTS);
am4core.options.autoDispose = true;

const CORNER_RADIUS = 2;
const COLUMN_WIDTH = am4core.percent(20);

const CHART_MIN_HEIGHT = 350;

export function AnomalyChartOldVersion({ issueId, onAnomaliesFetched, hideLegendAndDescription }: AnomalyChartProps) {
    const rootRef = useRef<HTMLDivElement>(null);
    const chartRef = useRef<am4charts.XYChart | null>(null);

    useEffect(() => {
        const chart = am4core.create(rootRef.current, am4charts.XYChart);
        chart.scrollbarX = new am4core.Scrollbar();
        const slider = chart.scrollbarX;
        slider.width = am4core.percent(40);
        slider.valign = "middle";
        slider.background.fill = am4core.color("#F1F4F8");
        slider.background.fillOpacity = 1;
        slider.minHeight = 5;
        // slider.start = (_startTime.getTime() - rangeMin) / (rangeMax - rangeMin);
        // slider.end = (_endTime.getTime() - rangeMin) / (rangeMax - rangeMin);
        slider.thumb.background.fill = am4core.color("#1B6FDE");
        slider.thumb.background.states.getKey("hover").properties.fill = am4core.color("#1B6FDE");
        slider.thumb.background.states.getKey("down").properties.fill = am4core.color("#1B6FDE");
        slider.thumb.tooltipY = -5;
        slider.showSystemTooltip = false;
        slider.thumb.showSystemTooltip = false;
        slider.thumb.tooltip.background.filters.clear();
        slider.thumb.tooltip.fontSize = 12;
        slider.thumb.tooltip.background.cornerRadius = 5;

        slider.startGrip.background.fill = am4core.color("#1B6FDE");
        slider.startGrip.background.cornerRadius(10, 10, 10, 10);
        slider.startGrip.background.strokeWidth = 0;
        slider.startGrip.width = 16;
        slider.startGrip.height = 16;
        slider.startGrip.icon.disabled = true;
        slider.startGrip.background.states.getKey("hover").properties.fill = am4core.color("#1B6FDE");
        slider.startGrip.background.states.getKey("down").properties.fill = am4core.color("#1B6FDE");
        slider.startGrip.tooltip.background.filters.clear();
        slider.startGrip.tooltip.dy = -10;
        slider.startGrip.tooltip.fontSize = 12;
        slider.startGrip.tooltip.background.cornerRadius = 10;

        slider.endGrip.background.fill = am4core.color("#1B6FDE");
        slider.endGrip.background.cornerRadius(10, 10, 10, 10);
        slider.endGrip.background.strokeWidth = 0;
        slider.endGrip.width = 16;
        slider.endGrip.height = 16;
        slider.endGrip.icon.disabled = true;
        slider.endGrip.background.states.getKey("hover").properties.fill = am4core.color("#1B6FDE");
        slider.endGrip.background.states.getKey("down").properties.fill = am4core.color("#1B6FDE");
        slider.endGrip.tooltip.background.filters.clear();
        slider.endGrip.tooltip.dy = -10;
        slider.endGrip.tooltip.fontSize = 12;
        slider.endGrip.tooltip.background.cornerRadius = 10;

        const startLeftGripRectangle = slider.startGrip.createChild(am4core.RoundedRectangle);
        startLeftGripRectangle.width = 1;
        startLeftGripRectangle.height = 6;
        startLeftGripRectangle.cornerRadius(40, 40, 40, 40);
        startLeftGripRectangle.fill = am4core.color("#fff");
        startLeftGripRectangle.dx = -2;
        startLeftGripRectangle.dy = -3;

        const startRightGripRectangle = slider.startGrip.createChild(am4core.RoundedRectangle);
        startRightGripRectangle.width = 1;
        startRightGripRectangle.height = 6;
        startRightGripRectangle.cornerRadius(40, 40, 40, 40);
        startRightGripRectangle.fill = am4core.color("#fff");
        startRightGripRectangle.dx = 1;
        startRightGripRectangle.dy = -3;

        const endLeftGripRectangle = slider.endGrip.createChild(am4core.RoundedRectangle);
        endLeftGripRectangle.width = 1;
        endLeftGripRectangle.height = 6;
        endLeftGripRectangle.cornerRadius(40, 40, 40, 40);
        endLeftGripRectangle.fill = am4core.color("#fff");
        endLeftGripRectangle.dx = -2;
        endLeftGripRectangle.dy = -3;

        const endRightGripRectangle = slider.endGrip.createChild(am4core.RoundedRectangle);
        endRightGripRectangle.width = 1;
        endRightGripRectangle.height = 6;
        endRightGripRectangle.cornerRadius(40, 40, 40, 40);
        endRightGripRectangle.fill = am4core.color("#fff");
        endRightGripRectangle.dx = 1;
        endRightGripRectangle.dy = -3;

        chartRef.current = chart;

        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "traceCategory";
        categoryAxis.title.text = "";
        // categoryAxis.renderer.cellStartLocation = 0.4;
        // categoryAxis.renderer.cellEndLocation = 0.6;
        categoryAxis.renderer.labels.template.fill = am4core.color("#BCBED2");
        categoryAxis.renderer.labels.template.fontSize = 10;
        categoryAxis.renderer.labels.template.maxWidth = 100;
        categoryAxis.renderer.labels.template.wrap = true;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = "";
        valueAxis.renderer.labels.template.disabled = true;
        valueAxis.renderer.grid.template.disabled = true;

        categoryAxis.renderer.grid.template.stroke = am4core.color("#F4F4F4");
        categoryAxis.renderer.grid.template.strokeOpacity = 1;
        valueAxis.renderer.grid.template.stroke = am4core.color("#F4F4F4");
        valueAxis.renderer.grid.template.strokeOpacity = 1;

        const rowStyle = "display: flex; font-size: 12px; line-height: 18px;";
        const descriptionCellStyle = "width: 66px; text-align: left; font-weight: 400;";
        const cellStyle = "flex: 1; text-align: left; font-weight: 500;";

        let severeSeries = chart.series.push(new am4charts.ColumnSeries());
        severeSeries.clustered = false;
        severeSeries.dataFields.valueY = "severeValue";
        severeSeries.dataFields.categoryX = "traceCategory";
        severeSeries.fill = am4core.color("#FF5C5C");
        severeSeries.stroke = am4core.color("rgba(0, 0, 0, 0)");
        severeSeries.columns.template.width = COLUMN_WIDTH;
        severeSeries.columns.template.column.cornerRadius(CORNER_RADIUS, CORNER_RADIUS, CORNER_RADIUS, CORNER_RADIUS);
        severeSeries.columns.template.tooltipHTML = "{traceCategory}<br/>Expected: <strong>{valueY}</strong>";
        severeSeries.legendSettings.labelText = "Severe";
        severeSeries.columns.template.tooltipHTML = `
            <div>
                <div style="${rowStyle}">
                    <div style="${descriptionCellStyle}">Category:</div>
                    <div style="${cellStyle}">{traceCategory}</div>
                </div>
                <div style="${rowStyle}">
                    <div style="${descriptionCellStyle}">Severity:</div>
                    <div style="${cellStyle}">Severe</div>
                </div>
            </div>
        `;
        severeSeries.tooltip.background.cornerRadius = 6;
        severeSeries.tooltip.background.strokeOpacity = 0;
        severeSeries.tooltip.background.fillOpacity = 1;
        severeSeries.tooltip.background.filters.clear();
        severeSeries.tooltip.getFillFromObject = false;
        severeSeries.tooltip.background.fill = am4core.color("#1B1B1B");

        let highSeries = chart.series.push(new am4charts.ColumnSeries());
        highSeries.clustered = false;
        highSeries.dataFields.valueY = "highValue";
        highSeries.dataFields.categoryX = "traceCategory";
        highSeries.fill = am4core.color("#FFA337");
        highSeries.stroke = am4core.color("rgba(0, 0, 0, 0)");
        highSeries.columns.template.width = COLUMN_WIDTH;
        highSeries.columns.template.column.cornerRadius(CORNER_RADIUS, CORNER_RADIUS, CORNER_RADIUS, CORNER_RADIUS);
        highSeries.columns.template.tooltipHTML = `
            <div>
                <div style="${rowStyle}">
                    <div style="${descriptionCellStyle}">Category:</div>
                    <div style="${cellStyle}">{traceCategory}</div>
                </div>
                <div style="${rowStyle}">
                    <div style="${descriptionCellStyle}">Severity:</div>
                    <div style="${cellStyle}">High</div>
                </div>
            </div>
      `;
        highSeries.legendSettings.labelText = "High";
        highSeries.tooltip.background.cornerRadius = 6;
        highSeries.tooltip.background.strokeOpacity = 0;
        highSeries.tooltip.background.fillOpacity = 1;
        highSeries.tooltip.background.filters.clear();
        highSeries.tooltip.getFillFromObject = false;
        highSeries.tooltip.background.fill = am4core.color("#1B1B1B");

        let normalSeries = chart.series.push(new am4charts.ColumnSeries());
        normalSeries.clustered = false;
        normalSeries.dataFields.valueY = "normalValue";
        normalSeries.dataFields.categoryX = "traceCategory";
        normalSeries.fill = am4core.color("#1EC985");
        normalSeries.stroke = am4core.color("rgba(0, 0, 0, 0)");
        normalSeries.columns.template.width = COLUMN_WIDTH;
        normalSeries.columns.template.column.cornerRadius(CORNER_RADIUS, CORNER_RADIUS, CORNER_RADIUS, CORNER_RADIUS);
        normalSeries.columns.template.tooltipHTML = "{traceCategory}<br/>Actual: <strong>{valueY}</strong>";
        normalSeries.legendSettings.labelText = "Normal";
        normalSeries.columns.template.tooltipHTML = `
            <div>
                <div style="${rowStyle}">
                    <div style="${descriptionCellStyle}">Category:</div>
                    <div style="${cellStyle}">{traceCategory}</div>
                </div>
                <div style="${rowStyle}">
                    <div style="${descriptionCellStyle}">Severity:</div>
                    <div style="${cellStyle}">Normal</div>
                </div>
            </div>
        `;
        normalSeries.tooltip.background.cornerRadius = 6;
        normalSeries.tooltip.background.strokeOpacity = 0;
        normalSeries.tooltip.background.fillOpacity = 1;
        normalSeries.tooltip.background.filters.clear();
        normalSeries.tooltip.getFillFromObject = false;
        normalSeries.tooltip.background.fill = am4core.color("#1B1B1B");

        const legend = new am4charts.Legend();
        legend.position = "bottom";
        legend.contentAlign = "left";

        const marker = legend.markers.template.children.getIndex(0);
        const disabledState = marker.states.getKey("active");
        // disabledState.propertyFields.stroke = 'fill';
        disabledState.properties.strokeWidth = 2;
        disabledState.properties.strokeOpacity = 1;

        legend.markers.template.events.on("childadded", function (event) {
            // this is a workaround for not working fill override
            // in markers disabled states
            // https://github.com/amcharts/amcharts4/issues/3180
            var child = event.newValue;
            // active is disabled
            var activeState = child.states.create("active");
            activeState.propertyFields.stroke = "fill";
            activeState.properties.fill = am4core.color("rgba(0, 0, 0, 0)");
            activeState.properties.strokeWidth = 2;
            activeState.properties.strokeOpacity = 1;
            (activeState.sprite.dom.firstChild as SVGRectElement).setAttribute("rx", "4");
            (activeState.sprite.dom.firstChild as SVGRectElement).setAttribute("ry", "4");
            const sprite = activeState.sprite as am4core.RoundedRectangle;
            sprite.properties.fill = am4core.color("rgba(0, 0, 0, 0)");
            sprite.cornerRadiusBottomLeft = 4;
            sprite.cornerRadiusTopLeft = 4;
            sprite.cornerRadiusTopRight = 4;
            sprite.cornerRadiusBottomRight = 4;
        });

        const disabledLabel = legend.labels.template.states.getKey("active");
        disabledLabel.properties.fill = am4core.color("#000000");
        const disabledValueLabel = legend.valueLabels.template.states.getKey("active");
        disabledValueLabel.properties.fill = am4core.color("#000000");

        chart.legend = legend;
    }, []);

    useEffect(() => {
        if (!chartRef.current) {
            return;
        }

        const xyChart = chartRef.current as am4charts.XYChart;

        xyChart.legend.disabled = hideLegendAndDescription;
    }, [hideLegendAndDescription]);

    useEffect(() => {
        (async () => {
            const response = await ServiceWire.getSternumService().getAnomalies(issueId);

            let maxValue = 0;

            response.anomalies.forEach((anomaly) => {
                if (anomaly.actualValue > maxValue) {
                    maxValue = anomaly.actualValue;
                }

                if (anomaly.shap > 1.2) {
                    anomaly.severeValue = anomaly.actualValue;
                } else if (anomaly.shap >= 0.8 && anomaly.shap <= 1.2) {
                    anomaly.highValue = anomaly.actualValue;
                } else {
                    anomaly.normalValue = anomaly.actualValue;
                }
            });

            const valueAxis = chartRef.current.yAxes.getIndex(0) as am4charts.ValueAxis<am4charts.AxisRenderer>;
            valueAxis.axisRanges.clear();

            function createGrid(value: number) {
                let range = valueAxis.axisRanges.create();
                range.value = value;
                range.label.text = "";
            }

            if (typeof maxValue === "number") {
                // check if number just in case
                const step = 0.4;
                let currentValue = step;
                const minNeededGrid = 1.2;

                while (currentValue <= Math.max(minNeededGrid, maxValue)) {
                    createGrid(currentValue);
                    currentValue += step;
                }
            }

            chartRef.current.data = sortBy(response.anomalies, ["traceCategory"]);

            onAnomaliesFetched?.(response);
        })();
    }, [issueId]);

    return (
        <div
            style={{
                position: "relative",
                flex: 1,
                display: "flex",
                flexDirection: "column",
                minHeight: CHART_MIN_HEIGHT,
            }}
        >
            <div ref={rootRef} style={{ flex: 1, padding: "28px 40px" }} />
            {!hideLegendAndDescription && (
                <div
                    style={{
                        color: "#BCBED2",
                        fontSize: 12,
                        lineHeight: "18px",
                        maxWidth: 550,
                        position: "absolute",
                        bottom: 20,
                        right: 64,
                    }}
                >
                    The values in this graph show <b>the impact</b> of each trace type on the origin of the anomaly.
                    <br />
                    Note: <i>the absence of some traces can also have a high impact value.</i>
                </div>
            )}
        </div>
    );
}
