import commonStyle from "../../CommonStyle";
import { makeStyles } from "@material-ui/core";

const useVisualisationConfigurationEventsExplorerStyle = makeStyles((theme) => {
    return {
        ...commonStyle(theme),

        container: {},

        tracesListPaper: {
            height: "100%",
        },

        sectionTitleContainer: {
            marginTop: theme.spacing(8),
            marginBottom: theme.spacing(1),
            display: "flex",
            alignItems: "center",
        },
        sectionTitle: {
            fontSize: "0.875rem",
            fontWeight: 500,
        },

        expandIcon: {
            fontSize: "0.875rem",
        },

        sectionDivider: {
            marginBottom: theme.spacing(3),
        },

        tableContainer: {
            height: "660px",
            maxHeight: "100%",
        },

        tableInner: {
            height: "100%",
            maxHeight: "100%",
            padding: theme.spacing(3, 0),
            background: "#fff",
        },

        duplicationWarning: {
            color: "#d10027",
            paddingTop: 10,
            padingBottom: 10,
            marginLeft: 20,
            marginRight: 20,
        },
    };
});

export default useVisualisationConfigurationEventsExplorerStyle;
