import { MenuItem, TextField, Typography, withStyles } from "@material-ui/core";
import { WithStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import SternumQueryField from "../../../lib/infra/SternumQueryField";
import SternumQueryInputValueConfiguration from "../../../lib/infra/SternumQueryInputValueConfiguration";
import Utils from "../../../lib/infra/Utils";
import ArgumentDefinitionInfo from "../../../lib/state/ArgumentDefinitionInfo";
import FieldType from "../../../lib/state/FieldType";
import { GlobalState } from "../../../lib/state/GlobalState";
import InputComponentType from "../../../lib/state/InputComponentType";
import SternumQuery from "../../../lib/state/SternumQuery";
import TimeDivisionType from "../../../lib/state/TimeDivisionType";
import CountOverTimeDefinition from "../../../lib/state/triggers/CountOverTimeDefinition";
import SelectComponent from "../../DeviceDefinitionComponents/SelectComponent/SelectComponent";
import SternumQueryEditor from "../../SternumQueryEditor/SternumQueryEditor";
import countOverTimeTriggerStyle from "./CountOverTimeTriggerStyle";

interface AppState {
    selectedTrace: any;
    timeWindowCount: number;
    timeWindowUnit: string;
    threshold: number;
    sternumQuery: SternumQuery;
    showAdvanced: boolean;
}

export interface AppProps extends WithStyles<typeof countOverTimeTriggerStyle> {
    existingDeviceDefinition: any;
    existingCountOverTimeDefinition?: CountOverTimeDefinition;
    updateFieldsStatus: (isAllFieldsSet: boolean, triggerDefinition: CountOverTimeDefinition) => void;
    disabled?: boolean;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {};
};

class CountOverTimeTrigger extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);
        // Initializing the state to default.
        if (this.props.existingCountOverTimeDefinition) {
            this.state = {
                selectedTrace: this.props.existingCountOverTimeDefinition.traceDefinition,
                timeWindowCount: this.props.existingCountOverTimeDefinition.timeWindowCount,
                timeWindowUnit: this.props.existingCountOverTimeDefinition.timeWindowUnit,
                threshold: this.props.existingCountOverTimeDefinition.threshold,
                sternumQuery: this.props.existingCountOverTimeDefinition.sternumQuery?.filters?.length
                    ? this.props.existingCountOverTimeDefinition.sternumQuery
                    : SternumQuery.getEmptyQuery(),
                showAdvanced:
                    this.props.existingCountOverTimeDefinition.sternumQuery &&
                    !this.props.existingCountOverTimeDefinition.sternumQuery.isEmpty(),
            };
        } else {
            this.state = {
                selectedTrace: "",
                timeWindowCount: 0,
                timeWindowUnit: "",
                threshold: 0,
                sternumQuery: SternumQuery.getEmptyQuery(),
                showAdvanced: false,
            };
        }
    }

    /**
     * Send data to parent component
     */
    private sendFieldsStatusToParent = () => {
        if (this.validateFields()) {
            let partialTrace;
            if (typeof this.state.selectedTrace === "object" && !this.state.selectedTrace.entityId) {
                partialTrace = {
                    trace_type: this.state.selectedTrace.traceType,
                    trace_event_name: this.state.selectedTrace.traceEventName,
                };
            }

            const triggerDefinition = new CountOverTimeDefinition(
                this.state.selectedTrace,
                this.state.timeWindowCount,
                this.state.timeWindowUnit,
                this.state.threshold,
                partialTrace,
                this.state.sternumQuery
            );
            this.props.updateFieldsStatus(true, triggerDefinition);
        } else {
            this.props.updateFieldsStatus(false, null);
        }
    };

    /**
     * Check if all trigger fields are set
     */
    private validateFields = (): boolean => {
        return (
            this.state.selectedTrace !== "" &&
            this.state.timeWindowUnit !== "" &&
            this.state.timeWindowCount !== 0 &&
            this.state.threshold !== 0
        );
    };

    /*
     * Handle trace change operation
     */

    private onSelectedTraceChanged = (selectedOption: any) => {
        this.setState(
            {
                selectedTrace: selectedOption.value,
            },
            () => this.sendFieldsStatusToParent()
        );
    };

    /*
     *
     */

    private onTimeWindowCountChanged = (event) => {
        this.setState(
            {
                timeWindowCount: Number(event.target.value),
            },
            () => this.sendFieldsStatusToParent()
        );
    };

    /*
     *
     */

    private onTimeWindowUnitChanged = (event) => {
        this.setState(
            {
                timeWindowUnit: event.target.value,
            },
            () => this.sendFieldsStatusToParent()
        );
    };

    /*
     *
     */

    private onThresholdChanged = (event) => {
        this.setState(
            {
                threshold: Number(event.target.value),
            },
            () => this.sendFieldsStatusToParent()
        );
    };

    /*
     *
     */
    private buildTriggerUnitsList = () => {
        let timeUnits = [];
        for (let timeUnit in TimeDivisionType) {
            if (isNaN(Number(timeUnit))) {
                const timeDisplayUnit = timeUnit.toLowerCase();
                timeUnits.push({
                    label: Utils.capitalize(timeDisplayUnit) + "s",
                    value: timeUnit,
                });
            }
            Utils.capitalize;
        }
        return timeUnits;
    };

    /*
     * Build trace list for dropdown display
     */
    private buildTraceSelectList = () => {
        let traceSelectOption = [];
        this.props.existingDeviceDefinition.traceDefinitions.map((traceDefinitionIter) => {
            if (traceDefinitionIter.displayInUI || traceDefinitionIter.canBeUsedForAlerts) {
                traceSelectOption.push({
                    label: traceDefinitionIter.displayName,
                    value: traceDefinitionIter,
                });
            }
        });
        return traceSelectOption;
    };

    /*
     * Build selected item
     */

    private buildSelectedItem = (selectedItem) => {
        if (selectedItem) {
            return { label: selectedItem.displayName, value: selectedItem };
        }
        return "";
    };

    render() {
        const { classes } = this.props;

        // Creating fields for arguments.
        let argumentQueryFields: SternumQueryField[] = (
            this.props.existingDeviceDefinition.argumentDefinitions as ArgumentDefinitionInfo[]
        )
            .filter((argumentDefinition) => argumentDefinition.displayInUI || argumentDefinition.canBeUsedForAlerts)
            .map((argumentDefinition) => {
                let apiName = argumentDefinition.argumentDefinitionId
                    ? argumentDefinition.argumentDefinitionId
                    : argumentDefinition.argumentEventName;

                return new SternumQueryField(
                    apiName,
                    apiName,
                    argumentDefinition.displayName,
                    FieldType.STRING,
                    new SternumQueryInputValueConfiguration(FieldType.STRING, InputComponentType.TEXT)
                );
            });

        return (
            <div>
                <Typography variant="subtitle2">Event Count Over Time Alert</Typography>

                {/* Trace definition selection */}
                <div role="presentation" aria-label="select trace">
                    <SelectComponent
                        name={"traceSelect"}
                        placeHolder={"Select Trace"}
                        onFieldChange={this.onSelectedTraceChanged}
                        selectedValue={this.buildSelectedItem(this.state.selectedTrace)}
                        selectOptions={this.buildTraceSelectList()}
                        isDisabled={this.props.disabled}
                    />
                </div>

                <div className={classNames(classes.flexVMiddle)}>
                    <TextField
                        role="presentation"
                        aria-label="text field occurrences"
                        className={classNames(classes.numberTextField)}
                        margin="dense"
                        value={this.state.threshold}
                        autoComplete="off"
                        id="filled-number"
                        label="Occurrences"
                        type="number"
                        onChange={this.onThresholdChanged}
                        InputProps={{ inputProps: { min: 0 } }}
                        disabled={this.props.disabled}
                    />
                </div>

                <div className={classNames(classes.flexVMiddle)}>
                    {/* Time window selection*/}
                    <TextField
                        role="presentation"
                        aria-label="text field within"
                        className={classNames(classes.numberTextField)}
                        margin="dense"
                        value={this.state.timeWindowCount}
                        autoComplete="off"
                        id="filled-basic"
                        label="Within"
                        type="number"
                        onChange={this.onTimeWindowCountChanged}
                        InputProps={{ inputProps: { min: 0 } }}
                        disabled={this.props.disabled}
                    />
                    {/* Time type selection (sec / min / hour / day) */}

                    <TextField
                        role="presentation"
                        aria-label="text field units"
                        className={classNames(classes.unitsTextField)}
                        margin="dense"
                        select
                        value={this.state.timeWindowUnit}
                        autoComplete="off"
                        id="outlined-basic"
                        label="Units"
                        onChange={this.onTimeWindowUnitChanged}
                        disabled={this.props.disabled}
                    >
                        {/* Empty menu item to fix autoselect first element */}
                        <MenuItem style={{ display: "none" }} />
                        {this.buildTriggerUnitsList().map((timeUnitIter) => (
                            <MenuItem
                                aria-label="unit dropdown menu item"
                                key={timeUnitIter.value}
                                value={timeUnitIter.value}
                            >
                                {timeUnitIter.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>

                {/* Advanced Link */}
                <Typography
                    variant="body2"
                    className={classNames(classes.advancedLinkText)}
                    onClick={() => this.onAdvancedClicked()}
                >
                    Advanced
                </Typography>

                {/* Filter title */}
                {this.state.showAdvanced && (
                    <Typography variant="body2" className={classNames(classes.filterTitle)}>
                        Filter over arguments
                    </Typography>
                )}

                {/* Filter explanation */}
                {this.state.showAdvanced && (
                    <div className={classNames(classes.explanationContainer, classes.marginBottomLarge)}>
                        <Typography variant="body2">
                            Once a trace of type {this.state.selectedTrace?.displayName} arrives in the system, it will
                            only be counted in this alert if it meets the conditions below.
                        </Typography>
                        <Typography variant="body2">
                            For example, only consider Connect traces whose IP Address argument equals to 127.0.0.1.
                        </Typography>
                    </div>
                )}

                {/* Filter */}
                {this.state.showAdvanced && (
                    <SternumQueryEditor
                        fields={argumentQueryFields}
                        sternumQuery={this.state.sternumQuery}
                        columnView={true}
                        onSternumQueryChanged={(sternumQuery) => this.onSternumQueryChanged(sternumQuery)}
                        disabled={this.props.disabled}
                    />
                )}
            </div>
        );
    }

    /**
     * Occurs on a click of advanced.
     */
    private onAdvancedClicked() {
        this.setState({
            showAdvanced: !this.state.showAdvanced,
        });
    }

    /**
     * Occurs once the query is changed.
     */
    private onSternumQueryChanged(sternumQuery: SternumQuery) {
        this.setState(
            {
                sternumQuery: sternumQuery,
            },
            () => this.sendFieldsStatusToParent()
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(countOverTimeTriggerStyle)(CountOverTimeTrigger));
