import { Box } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import Popover from "@material-ui/core/Popover";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Utils from "../../lib/infra/Utils";
import { fetchedPendingIssuesCountSuccessfullyAction } from "../../lib/redux/issues/FetchedPendingIssuesCountSuccessfullyAction";
import { fetchPendingIssuesCountAction } from "../../lib/redux/issues/FetchPendingIssuesCountAction";
import { openCvesListModal } from "../../lib/redux/modals/OpenModalAction";
import PollingSubscriptionInfo from "../../lib/services/PollingSubscriptionInfo";
import ServiceWire from "../../lib/services/ServiceWire";
import CvesFilter from "../../lib/state/CvesFilter";
import { GlobalState } from "../../lib/state/GlobalState";
import MetricInfo from "../../lib/state/MetricInfo";
import PollingChangeType from "../../lib/state/PollingChangeType";
import SecurityIssuesData from "../../lib/state/SecurityIssuesData";
import SpinnerLoader from "../SUI/SternumLoaders/SpinnerLoader";
import dashboardMetricsDataTileStyle from "./DashboardMetricsDataTileStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    loadingMetrics: boolean;
    errorLoadingMetrics: boolean;
    metrics: MetricInfo[];
    anchorElement;
    displayExplanationForMetricId: string;
    securityIssuesData: SecurityIssuesData | null;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof dashboardMetricsDataTileStyle> {
    history: any;
    loadingPendingIssuesCount?: boolean;
    errorLoadingPendingIssuesCount?: boolean;
    pendingIssuesCount?: number;

    fetchPendingIssuesCountAction?: () => void;
    fetchedPendingIssuesCountSuccessfullyAction?: (pendingIssuesCount: number) => void;
    openCvesListModal?: (
        key: string,
        cvesFilter: CvesFilter,
        displayXButton: boolean,
        displayBackButton: boolean
    ) => void;
    onMetricsLoaded?: (metrics: MetricInfo[]) => unknown;
}

const ArrowUpIcon = (props: any) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
        <path d="M7 14L11.8649 10L17 14" stroke="currentColor" strokeWidth="3" strokeLinecap="round" />
    </svg>
);

const ArrowDownIcon = (props: any) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
        <path d="M7 10L11.8649 14L17 10" stroke="currentColor" strokeWidth="3" strokeLinecap="round" />
    </svg>
);

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {
        loadingPendingIssuesCount: state.issues.loadingPendingIssuesCount,
        errorLoadingPendingIssuesCount: state.issues.errorLoadingPendingIssuesCount,
        pendingIssuesCount: state.issues.pendingIssuesCount,
    };
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchPendingIssuesCountAction: () => dispatch(fetchPendingIssuesCountAction()),
        fetchedPendingIssuesCountSuccessfullyAction: (pendingIssuesCount: number) =>
            dispatch(fetchedPendingIssuesCountSuccessfullyAction(pendingIssuesCount)),
        openCvesListModal: (key: string, cvesFilter: CvesFilter, displayXButton: boolean, displayBackButton: boolean) =>
            dispatch(openCvesListModal(key, cvesFilter, displayXButton, displayBackButton)),
    };
};

/**
 * Displays the dashboard of sternum.
 */
class DashboardMetricsDataTile extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            loadingMetrics: false,
            errorLoadingMetrics: false,
            metrics: [],
            displayExplanationForMetricId: null,
            anchorElement: null,
            securityIssuesData: null,
        };
    }

    /**
     * Occurs once the component finished its initialization process.
     */
    async componentDidMount() {
        // Subscribe to new attacked devices.
        ServiceWire.getPollingService().subscribe(
            new PollingSubscriptionInfo(
                "DashboardMetricsDataTile",
                PollingChangeType.METRICS,
                this.handleChangedMetrics.bind(this)
            )
        );

        // Registering to polling for issues.
        ServiceWire.getPollingService().subscribe(
            new PollingSubscriptionInfo(
                "DashboardPendingIssuesDataTile",
                PollingChangeType.ALL_ISSUES,
                this.handleChangedIssues.bind(this)
            )
        );

        // Registering to polling for cves.
        ServiceWire.getPollingService().subscribe(
            new PollingSubscriptionInfo(
                "DashboardCriticalCVES",
                PollingChangeType.ALL_CVES,
                this.handleNewCves.bind(this)
            )
        );

        try {
            this.setState({ loadingMetrics: true });

            await Promise.all([
                this.loadMetrics(),
                this.loadSecurityIssuesSummaryData(),
                this.props.fetchPendingIssuesCountAction(),
            ]);

            this.setState({ loadingMetrics: false, errorLoadingMetrics: false }, () => {
                if (this.props.onMetricsLoaded) {
                    this.props.onMetricsLoaded(this.state.metrics);
                }
            });
        } catch (err) {
            console.error(err);
            this.setState({ errorLoadingMetrics: true, loadingMetrics: false });
        }
    }

    /**
     * Occurs once the component is being destroyed.
     */
    componentWillUnmount(): void {
        ServiceWire.getPollingService().unsubscribe("DashboardMetricsDataTile", PollingChangeType.METRICS);
        ServiceWire.getPollingService().unsubscribe("DashboardPendingIssuesDataTile", PollingChangeType.ALL_ISSUES);
        ServiceWire.getPollingService().unsubscribe("DashboardCriticalCVES", PollingChangeType.ALL_CVES);
    }

    /**
     * Handle polling operation
     */
    private handleNewCves(fromTimestamp: number, changedData: Object) {
        if (changedData && (changedData["entities"] !== null || changedData["entities"] !== undefined)) {
            const entities = changedData["entities"];
            if (this.state.securityIssuesData) {
                const newTotal = this.state.securityIssuesData.total + entities.length;
                this.setState({ securityIssuesData: new SecurityIssuesData(newTotal, 0) });
            } else {
                const newCves = entities.length;
                this.setState({ securityIssuesData: new SecurityIssuesData(newCves, 0) });
            }
        }
    }

    /**
     * Occurs once new issues are fetched from server using polling.
     */
    private handleChangedIssues(fromTimestamp: number, changedData: Object): void {
        if (
            changedData &&
            (changedData["pendingIssuesCount"] !== null || changedData["pendingIssuesCount"] !== undefined)
        ) {
            let pendingIssuesCount = changedData["pendingIssuesCount"];

            // Letting everyone know we have pending issues!
            this.props.fetchedPendingIssuesCountSuccessfullyAction(pendingIssuesCount);
        }
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes, loadingPendingIssuesCount, pendingIssuesCount } = this.props;
        const { metrics, securityIssuesData, loadingMetrics, anchorElement, displayExplanationForMetricId } =
            this.state;

        if (loadingMetrics || loadingPendingIssuesCount || metrics.length === 0) {
            return (
                <div className={classNames(classes.flexCenter, classes.flexVMiddle, classes.flexGrow)}>
                    <SpinnerLoader width={36} />
                </div>
            );
        }

        const attacksMetric = metrics.find((m) => m.metricId === "SECURITY_ALERTS");
        const updateRequestsMetric = metrics.find((m) => m.metricId === "UPDATE_REQUESTED");
        const totalDevicesMetric = metrics.find((m) => m.metricId === "TOTAL_DEVICES");
        const activeDevicesMetric = metrics.find((m) => m.metricId === "DEVICES");

        return (
            <div role="presentation" aria-label="dashboard metrics data tiles">
                {/* Explanation popover */}
                <Popover
                    id="explanation-popover"
                    open={!!anchorElement}
                    anchorEl={anchorElement}
                    onClose={this.handleExplanationPopoverClosed}
                    className={classes.popover}
                    elevation={10}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    disableRestoreFocus
                >
                    <div className={classNames(classes.popoverContentContainer)}>
                        {this.getExplanationComponent(displayExplanationForMetricId)}
                    </div>
                </Popover>

                <div>
                    <div className={classes.tilesGrid}>
                        <div
                            role="presentation"
                            aria-label="metrics tile card"
                            className={classNames(classes.metricCard)}
                        >
                            <div>
                                <div className={classNames(classes.flex)}>
                                    <Typography className={classes.metricCardTitle}>Recent Attack Attempts</Typography>
                                    <Box
                                        sx={{ display: "inline-flex" }}
                                        aria-owns={!!anchorElement ? "explanation-popover" : undefined}
                                        aria-haspopup="true"
                                        onMouseEnter={(event) =>
                                            this.metricExplanationOpened(attacksMetric.metricId, event)
                                        }
                                        onMouseLeave={this.handleExplanationPopoverClosed}
                                    >
                                        <Icon className={classNames("fas fa-info-circle", classes.infoCircleIcon)} />
                                    </Box>
                                </div>
                                <div className={classNames(classes.flexVMiddle, classes.marginTopMd)}>
                                    <div className={classes.metricCardValue}>
                                        {Utils.numberToHumanString(parseInt(attacksMetric.metricValue, 10))}
                                    </div>
                                    {!!attacksMetric.deltaValue && (
                                        <div className={classNames(classes.flex, classes.devicesMetricContainer)}>
                                            {attacksMetric.deltaValue > 0 ? (
                                                <ArrowUpIcon className={classes.negativeKeyMetric} />
                                            ) : attacksMetric.deltaValue < 0 ? (
                                                <ArrowDownIcon className={classes.positiveKeyMetric} />
                                            ) : null}

                                            {attacksMetric.deltaValue !== 0 && (
                                                <div
                                                    className={classNames(
                                                        attacksMetric.deltaValue < 0 && classes.positiveKeyMetric,
                                                        attacksMetric.deltaValue > 0 && classes.negativeKeyMetric
                                                    )}
                                                >
                                                    {attacksMetric.deltaValue}%
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div
                            role="presentation"
                            aria-label="metrics tile card"
                            className={classNames(classes.metricCard)}
                        >
                            <div>
                                <div className={classNames(classes.flex)}>
                                    <Typography className={classes.metricCardTitle}>Update Requests</Typography>
                                    <Box
                                        sx={{ display: "inline-flex" }}
                                        onMouseEnter={(event) =>
                                            this.metricExplanationOpened(updateRequestsMetric.metricId, event)
                                        }
                                        onMouseLeave={this.handleExplanationPopoverClosed}
                                    >
                                        <Icon className={classNames("fas fa-info-circle", classes.infoCircleIcon)} />
                                    </Box>
                                </div>
                                <div className={classNames(classes.flexVMiddle, classes.marginTopMd)}>
                                    <div className={classes.metricCardValue}>
                                        {Utils.numberToHumanString(parseInt(updateRequestsMetric.metricValue, 10))}
                                    </div>
                                    {!!updateRequestsMetric.deltaValue && (
                                        <div className={classNames(classes.flex, classes.devicesMetricContainer)}>
                                            {updateRequestsMetric.deltaValue > 0 ? (
                                                <ArrowUpIcon className={classes.negativeKeyMetric} />
                                            ) : updateRequestsMetric.deltaValue < 0 ? (
                                                <ArrowDownIcon className={classes.positiveKeyMetric} />
                                            ) : null}

                                            {updateRequestsMetric.deltaValue !== 0 && (
                                                <div
                                                    className={classNames(
                                                        updateRequestsMetric.deltaValue < 0 &&
                                                            classes.positiveKeyMetric,
                                                        updateRequestsMetric.deltaValue > 0 && classes.negativeKeyMetric
                                                    )}
                                                >
                                                    {updateRequestsMetric.deltaValue}%
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div
                            role="presentation"
                            aria-label="metrics tile card"
                            className={classNames(classes.metricCard, "mod-link")}
                            onClick={this.onDevicesMetricClick}
                        >
                            <div>
                                <div className={classNames(classes.flex)}>
                                    <Typography className={classes.metricCardTitle}>Devices</Typography>
                                    <Box
                                        sx={{ display: "inline-flex" }}
                                        onMouseEnter={(event) =>
                                            this.metricExplanationOpened(activeDevicesMetric.metricId, event)
                                        }
                                        onMouseLeave={this.handleExplanationPopoverClosed}
                                    >
                                        <Icon className={classNames("fas fa-info-circle", classes.infoCircleIcon)} />
                                    </Box>
                                </div>
                                <div className={classNames(classes.flexVMiddle, classes.marginTopMd)}>
                                    <div className={classNames(classes.metricCardValue, "devices")}>
                                        {Utils.numberToHumanString(parseInt(activeDevicesMetric.metricValue, 10))}
                                        <div className={classes.devicesMetricSeparator}>/</div>
                                        <span className="activeDevicesNumber">
                                            {Utils.numberToHumanString(parseInt(totalDevicesMetric.metricValue, 10))}
                                        </span>
                                    </div>
                                    <div className={classes.devicesDeltaContainer}>
                                        <div className={classNames(classes.flex, classes.devicesMetricContainer)}>
                                            {activeDevicesMetric.deltaValue > 0 ? (
                                                <ArrowUpIcon className={classes.positiveKeyMetric} />
                                            ) : activeDevicesMetric.deltaValue < 0 ? (
                                                <ArrowDownIcon className={classes.negativeKeyMetric} />
                                            ) : null}

                                            {activeDevicesMetric.deltaValue !== 0 && (
                                                <div
                                                    className={classNames(
                                                        activeDevicesMetric.deltaValue > 0 && classes.positiveKeyMetric,
                                                        activeDevicesMetric.deltaValue < 0 && classes.negativeKeyMetric
                                                    )}
                                                >
                                                    {activeDevicesMetric.deltaValue}%
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            role="presentation"
                            aria-label="metrics tile card"
                            className={classNames(classes.metricCard, "mod-link")}
                            onClick={this.onAlertsMetricClick}
                        >
                            <div>
                                <div className={classNames(classes.flex)}>
                                    <Typography className={classes.metricCardTitle}>Critical Alerts</Typography>
                                    <Box
                                        sx={{ display: "inline-flex" }}
                                        onMouseEnter={(event) =>
                                            this.metricExplanationOpened("UNRESOLVED_ISSUES", event)
                                        }
                                        onMouseLeave={this.handleExplanationPopoverClosed}
                                    >
                                        <Icon className={classNames("fas fa-info-circle", classes.infoCircleIcon)} />
                                    </Box>
                                </div>
                                <div className={classNames(classes.flexVMiddle, classes.marginTopMd)}>
                                    <div className={classes.metricCardValue}>
                                        {Utils.numberToHumanString(pendingIssuesCount)}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            role="presentation"
                            aria-label="metrics tile card"
                            className={classNames(classes.metricCard, "mod-link")}
                            onClick={() =>
                                this.props.openCvesListModal(
                                    "CvesListModal",
                                    new CvesFilter(null, null, null, null, null, null),
                                    true,
                                    false
                                )
                            }
                        >
                            <div>
                                <div className={classNames(classes.flex)}>
                                    <Typography className={classes.metricCardTitle}>Critical CVEs</Typography>
                                    <Box
                                        sx={{ display: "inline-flex" }}
                                        onMouseEnter={(event) => this.metricExplanationOpened("CVES", event)}
                                        onMouseLeave={this.handleExplanationPopoverClosed}
                                    >
                                        <Icon className={classNames("fas fa-info-circle", classes.infoCircleIcon)} />
                                    </Box>
                                </div>
                                <div className={classNames(classes.flexVMiddle, classes.marginTopMd)}>
                                    <div className={classes.metricCardValue}>
                                        {Utils.numberToHumanString(securityIssuesData.timedCount)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private onDevicesMetricClick = () => {
        this.props.history.push("/devices");
    };

    private onAlertsMetricClick = () => {
        this.props.history.push("/alerts");
    };

    /**
     * Gets the metrics for the dashboard.
     */
    private async loadMetrics() {
        // Fetching metrics.
        let metrics = await ServiceWire.getSternumService().getDevicesMetrics(
            ServiceWire.getClientsService().getSelectedClientId()
        );

        // Setting fetched metrics.
        this.setState({
            metrics: metrics,
        });
    }

    /**
     * Loads the security issues data summary.
     */
    private async loadSecurityIssuesSummaryData() {
        // Fetching security issues summary data.
        let securitySummaryData = await ServiceWire.getSternumService().getSecurityIssuesSummaryData(
            ServiceWire.getClientsService().getSelectedClientId(),
            null,
            null,
            null,
            null
        );

        this.setState({
            securityIssuesData: securitySummaryData,
        });
    }

    /**
     * Handles the polling data.
     */
    private handleChangedMetrics(fromTimestamp: number, changedData: Object): void {
        let allMetrics: MetricInfo[] = [];

        for (let key in changedData) {
            if (changedData.hasOwnProperty(key)) {
                allMetrics.push(MetricInfo.fromJsonObject(changedData[key]));
            }
        }

        this.setState({
            metrics: allMetrics,
        });
    }

    /**
     * Gets the component we need to show for explanation of the metric.
     */
    private getExplanationComponent(metricId: string) {
        const { classes } = this.props;

        switch (metricId) {
            case "DEVICES":
                return (
                    <Typography variant="body2" className={classNames(classes.padding, classes.explanationTypography)}>
                        Devices communicated in past 30 days and total number of devices registered in ADS.
                    </Typography>
                );

            case "SECURITY_ALERTS":
                return (
                    <Typography variant="body2" className={classNames(classes.padding, classes.explanationTypography)}>
                        Security Alerts in last 30 days.
                    </Typography>
                );

            case "UPDATE_REQUESTED":
                return (
                    <Typography variant="body2" className={classNames(classes.padding, classes.explanationTypography)}>
                        Update requests in the last 30 days.
                    </Typography>
                );

            case "UNRESOLVED_ISSUES":
                return (
                    <Typography variant="body2" className={classNames(classes.padding, classes.explanationTypography)}>
                        Total number of unresolved critical alerts.
                    </Typography>
                );

            case "CVES":
                return (
                    <Typography variant="body2" className={classNames(classes.padding, classes.explanationTypography)}>
                        Discovered CVEs currently affecting your devices.
                    </Typography>
                );
        }
    }

    /**
     * Occurs once the explanation popover is closed.
     */
    private handleExplanationPopoverClosed = () => {
        this.setState({
            anchorElement: null,
        });
    };

    /**
     * Occurs once the explanation of a metric is opened.
     */
    private metricExplanationOpened(metricId: string, event) {
        this.setState({
            anchorElement: event.currentTarget,
            displayExplanationForMetricId: metricId,
        });
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(dashboardMetricsDataTileStyle)(withRouter(DashboardMetricsDataTile)));
