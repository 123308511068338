import React from "react";
import classNames from "classnames";

import { useMainSectionStyle } from "./MainSection.style";

export interface MainSectionTitleProps extends React.ComponentProps<"h1"> {}

export function MainSectionTitle({ className, children, ...props }: MainSectionTitleProps) {
    const classes = useMainSectionStyle();

    return (
        <h1 className={classNames(classes.title, className)} {...props}>
            {children}
        </h1>
    );
}
