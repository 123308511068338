import React from "react";
import useCheckStyle from "./CheckStyle";

export function Check() {
    const classes = useCheckStyle();

    return (
        <div className={classes.container}>
            <svg width="163" height="163" viewBox="0 0 163 163" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.802734 81.5004C0.802734 37.1056 37.1038 0.804565 81.4986 0.804565C125.893 0.804565 162.194 37.1056 162.194 81.5004C162.194 125.895 125.893 162.196 81.4986 162.196C37.1038 162.196 0.802734 125.895 0.802734 81.5004ZM81.4986 11.3629C42.935 11.3629 11.3611 42.9368 11.3611 81.5004C11.3611 120.064 42.935 151.638 81.4986 151.638C120.062 151.638 151.636 120.064 151.636 81.5004C151.636 42.9368 120.062 11.3629 81.4986 11.3629Z"
                    fill="#3FC47C"
                    fillOpacity="0.6"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M117.287 56.4277C119.347 58.4912 119.344 61.8338 117.28 63.8936L74.519 106.579C72.4569 108.638 69.1168 108.636 67.0565 106.576L45.7136 85.2332C43.6519 83.1716 43.6519 79.829 45.7136 77.7673C47.7752 75.7057 51.1178 75.7057 53.1794 77.7673L70.7927 95.3806L109.821 56.4211C111.884 54.3613 115.227 54.3643 117.287 56.4277Z"
                    fill="#3FC47C"
                    fillOpacity="0.6"
                />
            </svg>
        </div>
    );
}
