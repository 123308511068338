import { Grid } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import * as React from "react";
import LibrariesMetricsDataTile from "../LibrariesMetricsDataTile/LibrariesMetricsDataTile";
import LibrariesUsageDataTile from "../LibrariesUsageDataTile/LibrariesUsageDataTile";
import SecurityIssuesDataTile from "../SecurityIssuesDataTile/SecurityIssuesDataTile";
import UsedLibrariesList from "../UsedLibrariesList/UsedLibrariesList";
import VulnerableDevicesDataTile from "../VulnerableDevicesDataTile/VulnerableDevicesDataTile";
import librariesDashboardStyle from "./LibrariesDashboardStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    librariesListExpanded: boolean;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof librariesDashboardStyle> {
    sidebarOpen: boolean;
}

/**
 * Displays the dashboard of sternum.
 */
class LibrariesDashboard extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            librariesListExpanded: false,
        };
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        return (
            // Grid must have a containing div.
            <div>
                <Grid
                    container
                    spacing={6}
                    className={classNames(classes.grid, this.state.librariesListExpanded && classes.visibilityHidden)}
                >
                    {/* Metrics */}
                    <Grid item className={classes.dataTileWideContainer} xs={12}>
                        <div className={classNames(classes.dataTile, classes.metricDataTile, classes.flexColumn)}>
                            {/* Title */}
                            <Typography variant="body2" className={classes.dataTileTitle}>
                                Stats
                            </Typography>

                            <LibrariesMetricsDataTile />
                        </div>
                    </Grid>

                    {/* Security issues */}
                    <Grid item className={classes.dataTileNarrowContainer} xs={12}>
                        <div className={classNames(classes.dataTile, classes.metricDataTile, classes.flexColumn)}>
                            {/* Title */}
                            <Typography
                                variant="body2"
                                className={classNames(classes.dataTileTitle, "mod-with-subtitle")}
                            >
                                Critical CVEs
                            </Typography>

                            {/* Subtitle */}
                            <Typography variant={"caption"} className={classes.dataTitleSubtitle}>
                                Discovered CVEs that are currently affecting devices.
                            </Typography>

                            <SecurityIssuesDataTile />
                        </div>
                    </Grid>

                    {/* Vulnerable devices count */}
                    <Grid item className={classes.dataTileNarrowContainer} xs={12}>
                        <div className={classNames(classes.dataTile, classes.metricDataTile, classes.flexColumn)}>
                            {/* Title */}
                            <Typography
                                variant="body2"
                                className={classNames(classes.dataTileTitle, "mod-with-subtitle")}
                            >
                                Total Vulnerable Devices
                            </Typography>

                            {/* Subtitle */}
                            <Typography variant={"caption"} className={classes.dataTitleSubtitle}>
                                Devices that currently contain a vulnerable library.
                            </Typography>

                            <VulnerableDevicesDataTile />
                        </div>
                    </Grid>

                    {/* Distribution of components */}
                    <Grid item className={classes.dataTileWideContainer} xs={12}>
                        <div className={classNames(classes.dataTile, classes.metricDataTile, classes.flexColumn)}>
                            {/* Title */}
                            <Typography variant="body2" className={classes.dataTileTitle}>
                                Distribution of 3ʳᵈ Party Components
                            </Typography>

                            <LibrariesUsageDataTile />
                        </div>
                    </Grid>
                </Grid>

                {/* Libraries */}
                <div
                    className={classNames(
                        classes.librariesTitleContainer,
                        this.state.librariesListExpanded && "mod-expanded"
                    )}
                >
                    <div className={classNames(classes.librariesListTitleContainer, classes.flexVMiddle)}>
                        {/* Title */}
                        <Typography
                            className={classNames(classes.dataTileTitle, "mod-no-padding", classes.marginRight)}
                        >
                            Active Libraries
                        </Typography>
                    </div>
                </div>

                {/* Libraries list */}
                <div
                    className={classNames(
                        classes.tableContainer,
                        this.state.librariesListExpanded && "mod-expanded",
                        this.props.sidebarOpen ? "mod-sidebar-open" : "mod-sidebar-collapsed"
                    )}
                >
                    <div className={classes.tableInner}>
                        <UsedLibrariesList
                            hideToolbar={false}
                            hidePagination={false}
                            displayBackButtonInUsedLibraryView={false}
                            displayXButtonInUsedLibraryView={true}
                            hideUpperBorder={true}
                            isExpanded={this.state.librariesListExpanded}
                            onExpandToggle={this.expandLibrariesTable}
                        />
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Occurs on expanding libraries list.
     */
    private expandLibrariesTable = () => {
        this.setState({
            librariesListExpanded: !this.state.librariesListExpanded,
        });
    };
}

export default withStyles(librariesDashboardStyle)(LibrariesDashboard);
