/**
 * Represents a response of an HTTP request.
 */
class HttpResponse {
    /**
     * Constructor.
     */
    constructor(public body, public headers) {}
}

export default HttpResponse;
