import classNames from "classnames";
import React from "react";
import useSternumLoadersStyle from "./SternumLoadersStyle";

interface GraphLoaderProps {
    width?: number;
    height?: number;
}

export default function GraphLoader(props: GraphLoaderProps) {
    const classes = useSternumLoadersStyle();

    return (
        <div className={classNames()}>
            <svg width="60" height="60" viewBox="0 0 60 60" fill="none" {...props}>
                <rect x="15" y="40" width="4" height="1" fill="#E7004C">
                    <animate
                        attributeName="y"
                        dur="2s"
                        values="40;40;37;37;40"
                        repeatCount="indefinite"
                        keySplines="0.2 0.8 0.2 1;0.1 0.8 0.2 1;0.1 0.8 0.2 1;0.1 0.8 0.2 1;"
                        keyTimes="0;0.53;0.83;0.9;1"
                        calcMode="spline"
                        begin="0s"
                    />
                    <animate
                        attributeName="height"
                        dur="2s"
                        values="1;1;4;4;1"
                        repeatCount="indefinite"
                        keySplines="0.2 0.8 0.2 1;0.1 0.8 0.2 1;0.1 0.8 0.2 1;0.1 0.8 0.2 1;"
                        keyTimes="0;0.53;0.83;0.9;1"
                        calcMode="spline"
                        begin="0s"
                    />
                </rect>
                <rect x="20" y="38" width="4" height="3" fill="#E7004C">
                    <animate
                        attributeName="y"
                        dur="2s"
                        values="38;38;34;34;38"
                        repeatCount="indefinite"
                        keySplines="0.2 0.8 0.2 1;0.1 0.8 0.2 1;0.1 0.8 0.2 1;0.1 0.8 0.2 1;"
                        keyTimes="0;0.5;0.8;0.9;1"
                        calcMode="spline"
                        begin="0s"
                    />
                    <animate
                        attributeName="height"
                        dur="2s"
                        values="3;3;7;7;3"
                        repeatCount="indefinite"
                        keySplines="0.2 0.8 0.2 1;0.1 0.8 0.2 1;0.1 0.8 0.2 1;0.1 0.8 0.2 1;"
                        keyTimes="0;0.5;0.8;0.9;1"
                        calcMode="spline"
                        begin="0s"
                    />
                </rect>
                <rect x="25" y="36" width="4" height="5" fill="#E7004C">
                    <animate
                        attributeName="y"
                        dur="2s"
                        values="36;36;31;31;36"
                        repeatCount="indefinite"
                        keySplines="0.2 0.8 0.2 1;0.1 0.8 0.2 1;0.1 0.8 0.2 1;0.1 0.8 0.2 1;"
                        keyTimes="0;0.45;0.75;0.9;1"
                        calcMode="spline"
                        begin="0s"
                    />
                    <animate
                        attributeName="height"
                        dur="2s"
                        values="5;5;10;10;5"
                        repeatCount="indefinite"
                        keySplines="0.2 0.8 0.2 1;0.1 0.8 0.2 1;0.1 0.8 0.2 1;0.1 0.8 0.2 1;"
                        keyTimes="0;0.45;0.75;0.9;1"
                        calcMode="spline"
                        begin="0s"
                    />
                </rect>
                <rect x="30" y="35" width="4" height="6" fill="#E7004C">
                    <animate
                        attributeName="y"
                        dur="2s"
                        values="35;35;28;28;35"
                        repeatCount="indefinite"
                        keySplines="0.2 0.8 0.2 1;0.1 0.8 0.2 1;0.1 0.8 0.2 1;0.1 0.8 0.2 1;"
                        keyTimes="0;0.4;0.7;0.85;1"
                        calcMode="spline"
                        begin="0s"
                    />
                    <animate
                        attributeName="height"
                        dur="2s"
                        values="6;6;13;13;6"
                        repeatCount="indefinite"
                        keySplines="0.2 0.8 0.2 1;0.1 0.8 0.2 1;0.1 0.8 0.2 1;0.1 0.8 0.2 1;"
                        keyTimes="0;0.4;0.7;0.85;1"
                        calcMode="spline"
                        begin="0s"
                    />
                </rect>
                <rect x="35" y="33" width="4" height="8" fill="#E7004C">
                    <animate
                        attributeName="y"
                        dur="2s"
                        values="33;33;24;24;33"
                        repeatCount="indefinite"
                        keySplines="0.2 0.8 0.2 1;0.1 0.8 0.2 1;0.1 0.8 0.2 1;0.1 0.8 0.2 1;"
                        keyTimes="0;0.35;0.65;0.85;1"
                        calcMode="spline"
                        begin="0s"
                    />
                    <animate
                        attributeName="height"
                        dur="2s"
                        values="8;8;17;17;8"
                        repeatCount="indefinite"
                        keySplines="0.2 0.8 0.2 1;0.1 0.8 0.2 1;0.1 0.8 0.2 1;0.1 0.8 0.2 1;"
                        keyTimes="0;0.35;0.65;0.85;1"
                        calcMode="spline"
                        begin="0s"
                    />
                </rect>
                <rect x="40" y="31" width="4" height="10" fill="#E7004C">
                    <animate
                        attributeName="y"
                        dur="2s"
                        values="31;31;20;20;31"
                        repeatCount="indefinite"
                        keySplines="0.2 0.8 0.2 1;0.1 0.8 0.2 1;0.1 0.8 0.2 1;0.1 0.8 0.2 1;"
                        keyTimes="0;0.3;0.6;0.85;1"
                        calcMode="spline"
                        begin="0s"
                    />
                    <animate
                        attributeName="height"
                        dur="2s"
                        values="10;10;21;21;10"
                        repeatCount="indefinite"
                        keySplines="0.2 0.8 0.2 1;0.1 0.8 0.2 1;0.1 0.8 0.2 1;0.1 0.8 0.2 1;"
                        keyTimes="0;0.3;0.6;0.85;1"
                        calcMode="spline"
                        begin="0s"
                    />
                </rect>
            </svg>
        </div>
    );
}
