import { makeStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";

export const useSternumPlatformFreeTrialStyle = makeStyles((theme: Theme) => ({
    sternumPlatformFreeTrial: {},

    title: {
        fontWeight: 600,
        fontSize: 14,
        color: "white",
    },

    item: {
        display: "flex",
        gap: 6,
        marginTop: 8,
        fontWeight: 400,
        fontSize: 12,
    },

    itemTitle: {
        color: "#DBDBFC",
    },

    itemValue: {
        color: "white",
    },

    protectButton: {
        display: "flex",
        gap: 4,
        alignItems: "center",
        margin: "10px 0 0 0",
        padding: "4px 8px",
        background: "#FFB300",
        borderRadius: "7px",
        color: "#222831",
        outline: "none",
        border: "none",
        cursor: "pointer",
        fontWeight: 500,

        "&:focus": {
            outline: "none",
        },
    },

    protectButtonZero: {
        background: "#E7004C",
        color: "white",
    },
}));
