import { CurrentlyViewedLibraryIdReducerActions } from "./CurrentlyViewedLibraryIdReducerActions";
import ActionType from "../ActionType";

/**
 * Reducer for the currently viewed library.
 */
const currentlyViewedLibraryIdReducer = (state: string, action: CurrentlyViewedLibraryIdReducerActions) => {
    switch (action.type) {
        case ActionType.ViewLibrary:
            return action.libraryId;

        case ActionType.LibraryNotViewed:
            return null;

        default:
            return state;
    }
};

export default currentlyViewedLibraryIdReducer;
