class SternumReportTraceDetails {
    public constructor(public traceType: string, public traceArguments: string[], public receivedTime: string) {}

    /**
     * Json object representation
     */
    public getJsonObject() {
        return {
            trace_type: this.traceType,
            arguments: this.traceArguments,
            time: this.receivedTime,
        };
    }
}

export default SternumReportTraceDetails;
