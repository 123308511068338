/**
 * Response of the get delete summary command.
 */
class GetDeleteSummaryResponse {
    /**
     * Constructor.
     */
    constructor(public triggersCount: number) {}

    static fromJson(json: object) {
        return new GetDeleteSummaryResponse(json["triggers_count"]);
    }
}

export default GetDeleteSummaryResponse;
