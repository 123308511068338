import { Tooltip, Typography } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import SternumConfiguration from "../../lib/infra/SternumConfiguration";
import Utils from "../../lib/infra/Utils";
import WebUtils from "../../lib/infra/WebUtils";
import { editDeviceDefinitionModal, openDeviceDefinitionModal } from "../../lib/redux/modals/OpenModalAction";
import { showNotificationAction } from "../../lib/redux/notifications/ShowNotificationAction";
import PollingSubscriptionInfo from "../../lib/services/PollingSubscriptionInfo";
import ServiceWire from "../../lib/services/ServiceWire";
import DeviceDefinitionInfo from "../../lib/state/DeviceDefinitionInfo";
import DeviceDefinitionVersionInfo from "../../lib/state/DeviceDefinitionVersionInfo";
import { GlobalState } from "../../lib/state/GlobalState";
import HttpResponse from "../../lib/state/HttpResponse";
import { NotificationMessage } from "../../lib/state/NotificationsState";
import PollingChangeType from "../../lib/state/PollingChangeType";
import ServerEntityType from "../../lib/state/ServerEntityType";
import DeviceDefinitionCard from "../../shared_components/GenericCards/DeviceDefinitionCard";
import { BigPlusIcon } from "../SUI/SternumIcon/SternumIcon";
import SpinnerLoader from "../SUI/SternumLoaders/SpinnerLoader";
import deviceDefinitionsDashboardStyle from "./DeviceDefinitionsDashboardStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    // Loading & error state.
    loadingEntities: boolean;
    errorLoadingEntities: boolean;

    // Entities.
    entities: DeviceDefinitionVersionInfo[];
    totalItemCount: number;
    selectedDeviceDefinition: DeviceDefinitionInfo;

    deviceDefinitionEditDialogIsOpen: boolean;

    deviceDefinitionIdToDevicesCountMap: Record<string, number>;

    // Contains indication if device definition is running clear history operation
    deviceDefinitionClearHistoryMap: Map<string, boolean>;

    // Hold device definition version relationships (parent child)
    deviceDefinitionVersionRelationshipsMap: Map<string, string>;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof deviceDefinitionsDashboardStyle> {
    sidebarOpen: boolean;
    openDeviceDefinitionModal: (key: string, displayXButton: boolean, callbackFunction: () => void) => void;
    editDeviceDefinitionModal: (
        key: string,
        deviceDefinitionVersionId: string,
        displayXButton: boolean,
        callbackFunction: () => void
    ) => void;
    showNotification: (message: NotificationMessage) => void;
}

const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        openDeviceDefinitionModal: (key: string, displayXButton: boolean, callbackFunction: () => void) =>
            dispatch(openDeviceDefinitionModal(key, null, displayXButton, null, callbackFunction)),
        editDeviceDefinitionModal: (
            key: string,
            deviceDefinitionVersionId: string,
            displayXButton: boolean,
            callbackFunction: () => void
        ) =>
            dispatch(editDeviceDefinitionModal(key, deviceDefinitionVersionId, displayXButton, null, callbackFunction)),
        showNotification: (message: NotificationMessage) => dispatch(showNotificationAction(message)),
    };
};

/**
 * Displays the dashboard of device definitions.
 */
class DeviceDefinitionsDashboard extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            loadingEntities: false,
            errorLoadingEntities: false,
            entities: [],
            totalItemCount: 0,
            deviceDefinitionEditDialogIsOpen: false,
            selectedDeviceDefinition: null,
            deviceDefinitionIdToDevicesCountMap: {},
            deviceDefinitionClearHistoryMap: new Map(),
            deviceDefinitionVersionRelationshipsMap: new Map(),
        };
    }

    /**
     * Occurs once the component finished its initialization process.
     */
    async componentDidMount() {
        await this.loadEntities(1);

        ServiceWire.getPollingService().subscribe(
            new PollingSubscriptionInfo(
                "DashboardDeviceDefinitions",
                PollingChangeType.ALL_DEVICE_DEFINITIONS,
                this.handleDeviceDefinitionUpdates.bind(this)
            )
        );
        ServiceWire.getPollingService().subscribe(
            new PollingSubscriptionInfo(
                "DashboardDeviceDefinitionVersions",
                PollingChangeType.ALL_DEVICE_DEFINITION_VERSIONS,
                this.handleDeviceDefinitionUpdates.bind(this)
            )
        );
    }

    /**
     * Pop up new device profile modal
     */
    private openCreateNewDeviceProfileModal() {
        this.props.openDeviceDefinitionModal("NewDeviceDefinitionModal", true, this.reloadDeviceDefinitionList);
    }

    /**
     * Pop up edit device profile modal
     */
    private openDeviceDefinitionEditModal(deviceDefinitionVersionId: string) {
        this.props.editDeviceDefinitionModal(
            "EditDeviceDefinitionModal",
            deviceDefinitionVersionId,
            true,
            this.reloadDeviceDefinitionList
        );
    }

    /**
     * Handle new version click
     */
    private async onNewVersionClick(deviceDefinitionVersionID: string) {
        const newDeviceDefinitionVersion = await ServiceWire.getSternumService().createDeviceDefinitionNewVersion(
            deviceDefinitionVersionID
        );

        this.props.editDeviceDefinitionModal(
            "EditDeviceDefinitionModal",
            newDeviceDefinitionVersion[0].entityId,
            true,
            this.reloadDeviceDefinitionList
        );
    }

    /**
     * Occurs once the component is being destroyed.
     */
    componentWillUnmount(): void {
        ServiceWire.getPollingService().unsubscribe(
            "DashboardDeviceDefinitions",
            PollingChangeType.ALL_DEVICE_DEFINITIONS
        );
        ServiceWire.getPollingService().unsubscribe(
            "DashboardDeviceDefinitionVersions",
            PollingChangeType.ALL_DEVICE_DEFINITION_VERSIONS
        );
    }

    /**
     * Handle polling operation
     */
    private async handleDeviceDefinitionUpdates(fromTimestamp: number, changedData: Object) {
        if (changedData && (changedData["entities"] !== null || changedData["entities"] !== undefined)) {
            await this.loadEntities(1);
        }
    }

    /**
     * Trigger load entities operation after finishing adding new device definition flow.
     */

    private reloadDeviceDefinitionList = () => {
        this.loadEntities(0);
    };

    /**
     * Check if it's possible to create new version for given device definition version
     */
    private canCreateNewVersion = (deviceDefinitionVersionId: string): boolean => {
        // if given device definition version id contains in map, it has a child. return false
        const nextVersion = this.state.deviceDefinitionVersionRelationshipsMap.get(deviceDefinitionVersionId);
        return nextVersion === undefined || nextVersion === "N/A" ? true : false;
    };

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        // No content text
        const noContentElement = (
            <div
                key="noContentKey"
                className={classNames(classes.fullViewportHeight, classes.flexVMiddle, classes.flexCenter)}
            >
                <Typography variant="h6">
                    {" "}
                    {ServiceWire.getAuthorizationService().canAdd(ServerEntityType.DEVICE_DEFINITION)
                        ? "You don't have any device profiles, Please use the '+' button to create."
                        : "You don't have any device profiles."}
                </Typography>
            </div>
        );

        // Add new definition button (fab)

        const addNewDeviceElement = ServiceWire.getAuthorizationService().canAdd(ServerEntityType.DEVICE_DEFINITION) ? (
            <div key="addDeviceProfile">
                <Tooltip title="Add New Device Definition" classes={{ tooltip: classes.toolTip }}>
                    <Fab
                        color={"primary"}
                        aria-label={"add"}
                        className={classes.fab}
                        onClick={(e) => this.openCreateNewDeviceProfileModal()}
                    >
                        <BigPlusIcon color="#fff" />
                    </Fab>
                </Tooltip>
            </div>
        ) : null;

        // Display device definitions or create new text
        if (this.state.entities.length > 0) {
            return [
                <div className={classNames(classes.root)} key="rootDiv">
                    {/* Iterating device definitions */}
                    <div
                        role="listbox"
                        aria-label="device profiles list"
                        className={classes.deviceDefinitionCardsContainer}
                    >
                        {this.state.entities.map((deviceDefinitionVersionIter: DeviceDefinitionVersionInfo, index) => {
                            return (
                                <DeviceDefinitionCard
                                    key={deviceDefinitionVersionIter.entityId + index}
                                    cardThemeClass={classes.deviceCardContainer}
                                    header={deviceDefinitionVersionIter.getVersionName()}
                                    deviceDefinitionVersion={deviceDefinitionVersionIter}
                                    content={`Total Device Count: ${
                                        this.state.deviceDefinitionIdToDevicesCountMap[
                                            deviceDefinitionVersionIter.entityId
                                        ] || 0
                                    }`}
                                    handleDelete={() => {
                                        this.deleteDeviceDefinition(deviceDefinitionVersionIter);
                                    }}
                                    uniqueIdentifier={deviceDefinitionVersionIter.deviceDefinitionVersionRawId}
                                    handleEdit={() => {
                                        this.openDeviceDefinitionEditModal(deviceDefinitionVersionIter.entityId);
                                    }}
                                    handleDownloadC={() => {
                                        this.downloadCustomHeaderFile(deviceDefinitionVersionIter.entityId);
                                    }}
                                    handleDownloadJson={() => {
                                        this.downloadJsonHeaderFile(deviceDefinitionVersionIter.entityId);
                                    }}
                                    downloadJavaFiles={() => {
                                        this.downloadJavaFiles(deviceDefinitionVersionIter.entityId);
                                    }}
                                    handleReleaseToProduction={(deleteExistingData: boolean) => {
                                        this.updateDeviceDefinitionStatus(
                                            deviceDefinitionVersionIter,
                                            deleteExistingData
                                        );
                                    }}
                                    deletingHistoryState={this.state.deviceDefinitionClearHistoryMap.get(
                                        deviceDefinitionVersionIter.entityId
                                    )}
                                    handleCreateNewVersion={() => {
                                        this.onNewVersionClick(deviceDefinitionVersionIter.entityId);
                                    }}
                                    canCreateNewVersion={this.canCreateNewVersion(
                                        deviceDefinitionVersionIter.deviceDefinitionVersionRawId
                                    )}
                                    handleClearHistory={() => {
                                        this.clearHistoricalData(deviceDefinitionVersionIter);
                                    }}
                                />
                            );
                        })}
                    </div>
                </div>,
                addNewDeviceElement,
            ];
        } else {
            if (this.state.loadingEntities) {
                return (
                    <div className={classNames(classes.flexVMiddle, classes.flexCenter, classes.fullHeight)}>
                        <SpinnerLoader />
                    </div>
                );
            } else {
                return [addNewDeviceElement, noContentElement];
            }
        }
    }

    /**
     * Loads entities.
     */
    private async loadEntities(pageNumber) {
        try {
            // Setting loading to true.
            this.setState({
                loadingEntities: true,
                errorLoadingEntities: false,
            });

            // Fetching entities.
            let entities = await ServiceWire.getSternumService().getDeviceDefinitionVersions(
                ServiceWire.getClientsService().getSelectedClientId(),
                (pageNumber - 1) * SternumConfiguration.getPageSize(),
                SternumConfiguration.getPageSize()
            );

            // Build relations map
            entities.map((version: DeviceDefinitionVersionInfo) => {
                this.state.deviceDefinitionVersionRelationshipsMap.set(
                    version.parentRawId,
                    version.deviceDefinitionVersionRawId
                );
            });

            // Fetching devices counts.
            let deviceDefinitionIdToDevicesCountMap =
                await ServiceWire.getSternumService().getDeviceDefinitionsDeviceCounts(
                    ServiceWire.getClientsService().getSelectedClientId()
                );

            this.setState({
                loadingEntities: false,
                errorLoadingEntities: false,
                entities: [...entities],
                totalItemCount: entities.length,
                deviceDefinitionIdToDevicesCountMap: deviceDefinitionIdToDevicesCountMap,
                deviceDefinitionVersionRelationshipsMap: this.state.deviceDefinitionVersionRelationshipsMap,
            });
        } catch (error) {
            console.error(error);
            this.setState({
                loadingEntities: false,
                errorLoadingEntities: true,
            });
        }
    }

    /*
     * Delete device definition version
     */
    private async deleteDeviceDefinition(deviceDefinitionVersion: DeviceDefinitionVersionInfo): Promise<void> {
        let deleteResult = await ServiceWire.getSternumService().deleteDeviceDefinitionVersion(
            deviceDefinitionVersion.entityId
        );
        if (deleteResult) {
            const removeIndex = Utils.findFirstIndex(
                this.state.entities,
                (definition: DeviceDefinitionVersionInfo) => definition.entityId === deviceDefinitionVersion.entityId
            );
            let newEntitiesList = Utils.duplicateArray(this.state.entities, this.state.entities.length);
            newEntitiesList.splice(removeIndex, 1);
            this.setState({ entities: newEntitiesList });

            this.loadEntities(1);
        }
    }

    /*
     * Delete device definition version
     */
    private async clearHistoricalData(deviceDefinitionVersion: DeviceDefinitionVersionInfo): Promise<void> {
        // Set clear history flag to true
        this.state.deviceDefinitionClearHistoryMap.set(deviceDefinitionVersion.entityId, true);

        this.setState({ deviceDefinitionClearHistoryMap: this.state.deviceDefinitionClearHistoryMap });
        // Show clear notification

        let deleteResult = await ServiceWire.getSternumService().clearDeviceDefinitionVersionData(
            deviceDefinitionVersion.entityId
        );
        this.props.showNotification(
            `Historical data deleted from ${deviceDefinitionVersion.deviceDefinition?.displayName}`
        );

        // Set clear history flag to false
        this.state.deviceDefinitionClearHistoryMap.set(deviceDefinitionVersion.entityId, false);
        this.setState({ deviceDefinitionClearHistoryMap: this.state.deviceDefinitionClearHistoryMap });

        // Update devices count to 0 after deleting all data
        this.state.deviceDefinitionIdToDevicesCountMap[deviceDefinitionVersion.entityId] = 0;

        this.setState({ deviceDefinitionIdToDevicesCountMap: this.state.deviceDefinitionIdToDevicesCountMap });
    }

    /*
     * Download c header file for clicked device definition
     */

    private async downloadCustomHeaderFile(deviceDefinitionVersionId: string) {
        let downloadResult: HttpResponse = await ServiceWire.getSternumService().downloadDeviceDefinitionCustomHeader(
            deviceDefinitionVersionId
        );

        if (downloadResult) {
            WebUtils.downloadReport(downloadResult);
        }

        this.removeBadgeFromItem(deviceDefinitionVersionId);
    }

    /*
     * Download json header file for clicked device definition
     */

    private async downloadJsonHeaderFile(deviceDefinitionVersionId: string) {
        let downloadResult: HttpResponse = await ServiceWire.getSternumService().downloadDeviceDefinitionJsonHeader(
            deviceDefinitionVersionId
        );

        if (downloadResult) {
            WebUtils.downloadReport(downloadResult);
        }
        this.removeBadgeFromItem(deviceDefinitionVersionId);
    }

    /*
     * Download java files for clicked device definition
     */

    private async downloadJavaFiles(deviceDefinitionVersionId: string) {
        const [traces_file, arguments_file] = await Promise.all([
            ServiceWire.getSternumService().downloadDeviceDefinitionJavaTracesFile(deviceDefinitionVersionId),
            ServiceWire.getSternumService().downloadDeviceDefinitionJavaArgumentsFile(deviceDefinitionVersionId),
        ]);

        if (traces_file) {
            WebUtils.downloadReport(traces_file);
        }

        if (arguments_file) {
            WebUtils.downloadReport(arguments_file);
        }

        this.removeBadgeFromItem(deviceDefinitionVersionId);
    }

    /**
     *
     * Remove badge after download operation
     */
    private removeBadgeFromItem = async (deviceDefinitionVersionId: string) => {
        // After download operation is done, get the downloaded device definition to remove badge and hoover
        const updatedDeviceDefinition = await ServiceWire.getSternumService().getDeviceDefinitionVersion(
            deviceDefinitionVersionId
        );
        const deviceDefinitionIndex = Utils.findFirstIndex(
            this.state.entities,
            (deviceDefinition) => deviceDefinition.entityId === updatedDeviceDefinition.entityId
        );
        this.state.entities.splice(deviceDefinitionIndex, 1, updatedDeviceDefinition);
        this.setState({
            entities: [...this.state.entities],
        });
    };

    /*
     * Update device definition version status to production
     */

    private updateDeviceDefinitionStatus = async (
        deviceDefinitionVersion: DeviceDefinitionVersionInfo,
        deleteExistingData: boolean
    ) => {
        // Update device definition version status to production
        deviceDefinitionVersion.changeStatusToProduction();
        // Set clear history flag to true
        if (deleteExistingData) {
            this.state.deviceDefinitionClearHistoryMap.set(deviceDefinitionVersion.entityId, true);
            this.setState({ deviceDefinitionClearHistoryMap: this.state.deviceDefinitionClearHistoryMap });
        }
        const index = Utils.findFirstIndex(
            this.state.entities,
            (deviceDefinitionVersionIter) => deviceDefinitionVersionIter.entityId === deviceDefinitionVersion.entityId
        );
        let updateResult: DeviceDefinitionVersionInfo[] =
            await ServiceWire.getSternumService().updateDeviceDefinitionVersionStatus(
                deviceDefinitionVersion,
                deleteExistingData
            );
        // If response, update the existing device definition
        if (updateResult && updateResult.length > 0) {
            if (deleteExistingData) {
                // Set clear history flag to false
                this.state.deviceDefinitionClearHistoryMap.set(deviceDefinitionVersion.entityId, false);
                // Update devices count to 0 after deleting all data
                this.state.deviceDefinitionIdToDevicesCountMap[deviceDefinitionVersion.entityId] = 0;
            }
            this.state.entities.splice(index, 1, updateResult[0]);
            this.setState({
                entities: [...this.state.entities],
            });
        }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(deviceDefinitionsDashboardStyle)(DeviceDefinitionsDashboard));
