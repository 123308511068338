import { Action } from "redux";

import ActionType from "../ActionType";
import IssueInfo from "../../state/IssueInfo";

export interface SetSelectedIssueInfoAction extends Action<ActionType> {
    type: ActionType.SetSelectedIssueInfo;
    issue?: IssueInfo | null;
}

export function setSelectedIssueInfoAction(action: { issue?: IssueInfo | null }): SetSelectedIssueInfoAction {
    return {
        type: ActionType.SetSelectedIssueInfo,
        issue: action.issue,
    };
}
