import AggregationFunctionType from "../../../lib/state/Visualisation/AggregationFunctionType";
import getAggregationFunctionsMap from "./getAggregationFunctionsMap";

export function getTimeSeriesGraphLabel(
    aggregationFunctionType: AggregationFunctionType,
    aggregatedTraceDefinitionDisplayName: string | undefined,
    aggregatedArgumentDefinitionDisplayName: string | undefined,
    deviceDefinitionVersionNames?: string[]
) {
    let result = "";
    const aggregationFunctionsMap = getAggregationFunctionsMap();
    const aggregationFunctionDisplayName: string = aggregationFunctionsMap[aggregationFunctionType].label;

    if (
        (aggregationFunctionType === AggregationFunctionType.COUNT ||
            aggregationFunctionType === AggregationFunctionType.UNIQUE_COUNT) &&
        aggregatedTraceDefinitionDisplayName
    ) {
        result = `${aggregationFunctionDisplayName} of ${aggregatedTraceDefinitionDisplayName}`;
    } else if (aggregatedTraceDefinitionDisplayName && aggregatedArgumentDefinitionDisplayName) {
        result = `${aggregationFunctionDisplayName} of ${aggregatedTraceDefinitionDisplayName} (${aggregatedArgumentDefinitionDisplayName})`;
    } else if (aggregatedArgumentDefinitionDisplayName) {
        result = `${aggregationFunctionDisplayName} of ${aggregatedArgumentDefinitionDisplayName}`;
    }

    if (deviceDefinitionVersionNames) {
        result += ` (${deviceDefinitionVersionNames.join(", ")})`;
    }

    return result;
}
