import * as React from "react";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import commonStyle from "../CommonStyle";
import { FC } from "react";
import StatusDisplay from "../StatusDisplay/StatusDisplay";

const AlertStatusDisplayStyles = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),
    });

interface AppProps extends WithStyles<typeof AlertStatusDisplayStyles> {
    triggerDisabled: boolean;
}

const AlertStatusDisplay: FC<AppProps> = ({ triggerDisabled }) => {
    return (
        <StatusDisplay
            label={triggerDisabled ? "Off" : "On"}
            success={!triggerDisabled}
            disabled={triggerDisabled}
            displayColoredBackground
            centerText
            padding
            narrow
        />
    );
};

export default withStyles(AlertStatusDisplayStyles)(AlertStatusDisplay);
