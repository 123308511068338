import EntityManager from "../../infra/EntityManager";
import CustomDashboardInfo from "../../state/CustomDashboardInfo";
import AnalyticsService from "../AnalyticsService";
import buildSternumApiUrl from "../buildSternumApiUrl";
import HttpService from "../HttpService";

class CustomDashboardsApiService {
    constructor(private httpService: HttpService) {}

    @AnalyticsService.reportOnError("API:getCustomDashboardsSummaries")
    public async getCustomDashboardsSummaries(clientId: string): Promise<CustomDashboardInfo[]> {
        const endpoint = `/${clientId}/custom_dashboards`;
        const responseJson = await this.httpService.get(buildSternumApiUrl(endpoint));

        return Promise.resolve(EntityManager.getSternumEntities(responseJson) as CustomDashboardInfo[]);
    }

    @AnalyticsService.reportOnError("API:getCustomDashboardsSummaries")
    public async getDefaultDashboardSummery(clientId: string): Promise<CustomDashboardInfo[]> {
        const endpoint = `/${clientId}/default_dashboard`;
        const responseJson = await this.httpService.get(buildSternumApiUrl(endpoint));

        return Promise.resolve(EntityManager.getSternumEntities(responseJson) as CustomDashboardInfo[]);
    }

    @AnalyticsService.reportOnError("API:createCustomDashboard")
    public async createCustomDashboard(
        clientId: string,
        displayName: string,
        description?: string,
        deviceId?: string,
        deviceDefinitionVersionIds: string[] = []
    ): Promise<CustomDashboardInfo> {
        const data = {
            display_name: displayName,
            description: description || undefined,
            device_id: deviceId || undefined,
            device_definition_version_ids: deviceDefinitionVersionIds,
        };
        const endpoint = `/${clientId}/custom_dashboards`;
        const responseJson = await this.httpService.post(buildSternumApiUrl(endpoint), data);

        return EntityManager.getSternumEntity(responseJson) as CustomDashboardInfo;
    }

    @AnalyticsService.reportOnError("API:updateCustomDashboard")
    public async updateCustomDashboard(
        dashboardId: string,
        displayName?: string,
        deviceDefinitionVersionIds?: string[]
    ): Promise<any> {
        const data = {
            display_name: displayName,
            device_definition_version_ids: deviceDefinitionVersionIds,
        };
        const endpoint = `/${dashboardId}`;
        const responseJson = await this.httpService.put(buildSternumApiUrl(endpoint), data);

        return responseJson;
    }

    @AnalyticsService.reportOnError("API:getCustomDashboard")
    public async getCustomDashboard(customDashboardId: string): Promise<CustomDashboardInfo> {
        const endpoint = `/${customDashboardId}`;
        const responseJson = await this.httpService.get(buildSternumApiUrl(endpoint));

        return Promise.resolve(EntityManager.getSternumEntity(responseJson) as CustomDashboardInfo);
    }

    @AnalyticsService.reportOnError("API:deleteDashboard")
    public async deleteDashboard(customDashboardId: string): Promise<void> {
        let data = {};

        const endpoint = `/${customDashboardId}`;
        await this.httpService.delete(buildSternumApiUrl(endpoint), data);

        return Promise.resolve();
    }
}

export default CustomDashboardsApiService;
