import React from "react";

import { SternumIconProps } from "./SternumIcon.types";

export const PlayIcon = React.forwardRef<SVGSVGElement, SternumIconProps>(function PlayIcon(
    { className, color, height = 24, width = 24, ...props },
    ref
) {
    return (
        <svg
            ref={ref}
            className={className}
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect width="24" height="24" transform="translate(0 0.211914)" fill="transparent" />
            <circle cx="12" cy="12.2119" r="7.5" stroke={color || "currentColor"} />
            <path
                d="M15.25 11.7789C15.5833 11.9714 15.5833 12.4525 15.25 12.6449L10.75 15.243C10.4167 15.4355 10 15.1949 10 14.81L10 9.61384C10 9.22894 10.4167 8.98838 10.75 9.18082L15.25 11.7789Z"
                stroke={color || "currentColor"}
            />
        </svg>
    );
});
