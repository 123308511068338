/**
 * Represents the different levels of interest.
 */
enum EventInterest {
    REGULAR,
    LOW,
    MEDIUM,
    HIGH,
}

export default EventInterest;
