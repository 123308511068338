import { uniqueId } from "lodash";
import React from "react";
import TableRowData from "../../../lib/state/TableRowData";

/**
 * Represents an argument value to be used in a table.
 */
class PresentationValuesTableRowData implements TableRowData {
    constructor(public value: string, public count: number, public deviceProfile: string, public dataSource?: string) {}

    getColumnValue(columnHeaderId: string): any {
        switch (columnHeaderId) {
            case "value":
                if (!this.dataSource) return this.value;

                return (
                    <>
                        {this.value}
                        <br />({this.dataSource})
                    </>
                );
            case "deviceProfile":
                return this.deviceProfile;
            case "count":
                return this.count;
        }
    }

    getIdentifier(): string {
        return this.value + this.count + this.dataSource + this.deviceProfile + uniqueId();
    }

    isDifferentFrom(other: TableRowData): boolean {
        if (!(other instanceof PresentationValuesTableRowData)) {
            return true;
        }

        let otherArgumentValueTableRowData = other as PresentationValuesTableRowData;

        if (
            otherArgumentValueTableRowData.value !== this.value ||
            otherArgumentValueTableRowData.dataSource !== this.dataSource ||
            otherArgumentValueTableRowData.deviceProfile !== this.deviceProfile ||
            otherArgumentValueTableRowData.count !== this.count
        ) {
            return true;
        }

        return false;
    }
}

export default PresentationValuesTableRowData;
