import ArgumentDivisionType from "./ArgumentDivisionType";
import SternumDisplayObjectInfo from "./SternumDisplayObjectInfo";

/**
 * Represents an argument definition in sternum.
 */
class ArgumentDefinitionPartial {
    /**
     * Constructor.
     */
    constructor(
        public displayName: string,
        public argumentEventName: string,
        public displayInUI?: boolean,
        public argumentType?: string,
        public canBeUsedForAlerts?: boolean
    ) {
        if (!displayInUI) {
            this.displayInUI = true;
        }
    }

    /*
     * Return json representation of argument object
     */
    toJsonObject() {
        return {
            display_name: this.displayName,
            can_be_used_for_alerts: this.canBeUsedForAlerts,
            argument_event_name: this.argumentEventName,
        };
    }

    /**
     * Partial argument can be only custom
     */
    public isSystemArgument(): boolean {
        return this.argumentType === ArgumentDivisionType[ArgumentDivisionType.SYSTEM];
    }

    /**
     * Create system arguments
     */
    public static createSystemArguments(listOfSystemArguments): ArgumentDefinitionPartial[] {
        return listOfSystemArguments.map(
            (displayTraceData: SternumDisplayObjectInfo) =>
                new ArgumentDefinitionPartial(
                    displayTraceData.displayName,
                    displayTraceData.systemName,
                    displayTraceData.displayInUI,
                    ArgumentDivisionType[ArgumentDivisionType.SYSTEM],
                    displayTraceData.canBeUsedForAlerts
                )
        );
    }
}

export default ArgumentDefinitionPartial;
