import { useMemo } from "react";

import { getMappedValue } from "../../../utils";
import { PatternReceivedTraceStatus, PatternWithTraces } from "../../../lib/state/Pattern";
import { ReceivedTrace, ReceivedTraceData, ReceivedTraceStatus } from "./ReceivedTraces.types";

export function useReceivedTraces(
    patternsWithTraces: PatternWithTraces[],
    selectedPatternIndex: number
): ReceivedTraceData {
    return useMemo(() => {
        const patternWithTraces = patternsWithTraces[selectedPatternIndex];
        let receivedUnexpectedCount = 0;
        let notReceivedCount = 0;
        let onlyReceivedCount = 0;
        let orderIndex = 0;

        const receivedTraces: ReceivedTrace[] =
            patternWithTraces?.receivedTraces?.map((receivedTrace) => {
                const receivedTime = receivedTrace.receivedTime ? new Date(receivedTrace.receivedTime) : undefined;
                const traceStatus = getMappedValue<PatternReceivedTraceStatus, () => ReceivedTraceStatus>(
                    {
                        [PatternReceivedTraceStatus.NotReceived]: () => {
                            notReceivedCount++;
                            return ReceivedTraceStatus.NotReceived;
                        },
                        [PatternReceivedTraceStatus.Received]: () => {
                            onlyReceivedCount++;
                            return ReceivedTraceStatus.Received;
                        },
                        [PatternReceivedTraceStatus.Unexpected]: () => {
                            receivedUnexpectedCount++;
                            return ReceivedTraceStatus.Unexpected;
                        },
                    },
                    receivedTrace.traceStatus
                )?.();

                return {
                    receivedTraceId: receivedTrace.receivedTraceId,
                    eventType: receivedTrace.eventType,
                    receivedTime,
                    orderIndex: traceStatus !== ReceivedTraceStatus.Unexpected ? ++orderIndex : 0,
                    traceStatus,
                };
            }) || [];

        return {
            receivedTraces,
            receivedTracesCount: onlyReceivedCount + receivedUnexpectedCount,
            receivedUnexpectedCount,
            notReceivedCount,
            totalCount: patternWithTraces?.receivedTraces?.length || 0,
        };
    }, [patternsWithTraces, selectedPatternIndex]);
}
