import { makeStyles, Theme } from "@material-ui/core";
import commonStyle from "../CommonStyle";

const useApiDocumentationPageStyle = makeStyles((theme) => ({
    ...commonStyle(theme),

    root: {
        padding: "16px",
    },

    startedText: {
        maxWidth: 500,
        marginTop: 15,
    },

    endpointContainer: {
        marginTop: 30,
        paddingBottom: 30,
        borderBottom: "1px solid #E6EAED",

        "&:last-child": {
            borderBottom: "none",
        },
    },

    requestExampleContainer: {
        position: "relative",
    },

    copyRequestButton: {
        position: "absolute",
        top: 8,
        right: 12,
        padding: 0,
    },

    queryParam: {
        display: "flex",
        padding: theme.spacing(1, 2),
        marginRight: 10,
        marginBottom: 10,
        background: "#E6EAED",
        borderRadius: 4,
    },
}));

export default useApiDocumentationPageStyle;
