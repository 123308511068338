enum VisualisationType {
    TIME_SERIES = "TIME_SERIES",
    METRIC = "METRIC",
    VALUES_GRAPH = "VALUES_GRAPH",
    PIE_CHART = "PIE_CHART",
    VALUES_TABLE = "VALUES_TABLE",
    GEOMAP = "GEOMAP",
}

export default VisualisationType;
