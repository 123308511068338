import { Theme } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

let librariesDashboardStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        tableContainer: {
            height: "calc(100vh - 315px - 45px)",
            padding: theme.spacing(4),
            paddingTop: 0,

            "&.mod-sidebar-open": {
                left: theme.spacing(36),
            },
            "&.mod-sidebar-collapsed": {
                left: theme.spacing(11),
            },

            overflow: "auto",
            width: "100%",
            maxHeight: "100%",

            "&.mod-expanded": {
                position: "fixed",
                height: "calc(100vh - 45px)",
                top: theme.spacing(9),
                right: 0,
                bottom: 0,

                "&.mod-sidebar-open": {
                    left: 250,
                },
                "&.mod-sidebar-collapsed": {
                    left: 60,
                },

                width: "auto",
                overflow: "auto",
                maxHeight: "100%",
            },
        },

        tableInner: {
            height: "100%",
            padding: theme.spacing(3),
            background: "#fff",
            boxShadow: "0px 0px 23px rgba(231, 232, 235, 0.25)",
            borderRadius: 10,
        },

        root: {
            flexGrow: 1,
        },
        grid: {
            width: "100%",
            margin: 0,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: "center",
            color: theme.palette.text.secondary,
        },
        dataTileWideContainer: {
            flexBasis: "30%",
            maxWidth: "30%",
        },
        dataTileNarrowContainer: {
            flexBasis: "20%",
            maxWidth: "20%",
        },
        dataTile: {
            height: theme.spacing(57),
            boxShadow: "0px 0px 23px rgba(231, 232, 235, 0.25)",
            borderRadius: 14,
            background: "#fff",
            padding: theme.spacing(3),

            "&.mod-no-border": {
                border: "none",
            },
        },
        metricDataTile: {
            minHeight: theme.spacing(35),
        },
        dataTileTitle: {
            fontSize: 18,
            fontWeight: 600,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingBottom: theme.spacing(2),

            "&.mod-no-padding": {
                padding: 0,
            },
            "&.mod-with-subtitle": {
                paddingBottom: theme.spacing(1),
            },
        },
        dataTitleSubtitle: {
            fontSize: 14,
            color: "#999",
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingBottom: theme.spacing(1),

            "&.mod-no-padding": {
                padding: 0,
            },
        },
        librariesTitleContainer: {
            marginLeft: 16,
            marginTop: 10,

            "&:hover .expand-text-typo": {
                visibility: "visible",
            },
            "&.mod-expanded": {
                position: "fixed",
                top: 0,
            },
        },
        expandText: {
            cursor: "pointer",
            fontSize: 14,
            "&:hover": {
                textDecoration: "underline",
            },
        },
        librariesListTitleContainer: {
            marginBottom: "8px",
        },
    });

export default librariesDashboardStyle;
