/**
 * Holds the styles needed for loading placeholders.
 */
import { sternumTheme } from "./App";

let customSelectStyle = (theme) => {
    return {
        control: (provided, state) => {
            if (state.selectProps.type === "outlined") {
                return {
                    ...provided,
                    minHeight: 44,
                    borderRadius: 14,
                    border: "1px solid #E4E4E4",
                    background: "transparent",
                };
            }

            return {
                ...provided,
                minHeight: 44,
                backgroundColor: "#fff",
                border: "none",
                boxShadow: "0px 0px 23px rgba(211, 212, 214, 0.25)",
                borderRadius: 14,
            };
        },
        indicatorSeparator: (provided, state) => {
            return {
                display: "none",
            };
        },
        indicatorsContainer: (provided, state) => {
            return {
                ...provided,
                "> div": {
                    paddingLeft: 0,
                    color: "#171717",
                },
            };
        },
        indicatorContainer: (provided, state) => {
            return {
                ...provided,
                transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "",
            };
        },
        input: (provided, state) => {
            return {
                ...provided,
                fontFamily: theme.typography.fontFamily,
                fontSize: "0.8125rem",
                fontWeight: 400,
            };
        },
        noOptionsMessage: (provided, state) => {
            return {
                ...provided,
                fontFamily: theme.typography.fontFamily,
                fontSize: "0.8125rem",
                fontWeight: 400,
            };
        },
        option: (provided, state) => {
            let includeBottomSeparator: boolean = false;
            if (state.options) {
                let relevantOption = state.options.filter((option) => state.value === option.value)[0];
                includeBottomSeparator = !!relevantOption["includeBottomSeparator"];
            }

            return {
                ...provided,
                fontFamily: theme.typography.fontFamily,
                fontSize: "0.8125rem",
                fontWeight: 400,
                borderBottom: includeBottomSeparator ? `1px solid ${sternumTheme.grey.main}` : "none",
            };
        },
        singleValue: (provided, state) => ({
            ...provided,
            fontFamily: theme.typography.fontFamily,
            fontSize: "0.8125rem",
            fontWeight: 400,
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible",
        }),
        // Make sure the drop down is in heighest order of elements.
        menu: (provided, state) => ({
            ...provided,
            border: "none",
            borderRadius: 20,
            boxShadow: "0px 6px 250px 0px rgba(0, 0, 0, 0.12)",
            zIndex: 100,
        }),
        menuList: (provided, state) => {
            return {
                ...provided,
                maxHeight: 400,
                paddingTop: 12,
                paddingBottom: 12,
            };
        },
        placeholder: (provided, state) => ({
            ...provided,
            fontFamily: theme.typography.fontFamily,
            fontSize: "0.8125rem",
            fontWeight: 400,
        }),

        multiValue: (provided, state) => {
            return {
                ...provided,
                maxWidth: "150px",
                padding: "2px 5px",
                background: "#DBE2FE",
                borderRadius: "14px",
            };
        },
        multiValueLabel: (provided, state) => {
            return {
                ...provided,
                color: "#221CB6",
            };
        },
        multiValueRemove: (provided, state) => {
            return {
                ...provided,
                color: "#221CB6",
                cursor: "pointer",
                ":hover": {
                    background: "none",
                },
            };
        },
    };
};

export default customSelectStyle;
