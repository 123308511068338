import { Menu, Typography } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import classNames from "classnames";
import * as React from "react";
import { ArrowUpIcon, ArrowDownIcon } from "../SternumIcon/SternumIcon";
import SternumSelectData from "./SternumSelectData";
import useSternumSelectStyle from "./SternumSelectStyle";

export interface AppProps {
    selectedItem: SternumSelectData;
    selectList: SternumSelectData[];
    onSelected: (selectedItem: SternumSelectData) => void;
}

export default function SternumCustomSelect(props: AppProps) {
    const classes = useSternumSelectStyle();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const onClose = () => {
        setAnchorEl(null);
    };

    const onItemSelection = (selectedOption: SternumSelectData) => {
        props.onSelected(selectedOption);
        onClose();
    };

    return (
        <>
            <div
                className={classNames(classes.flexRow, classes.flexVMiddle, classes.cursorPointer)}
                onClick={(event) => onClick(event as React.MouseEvent<HTMLDivElement>)}
            >
                <Typography variant="h5" className={classes.label}>
                    {props.selectedItem.displayName}
                </Typography>

                {Boolean(anchorEl) ? (
                    <ArrowUpIcon color="#2B2523" className={classes.marginLeftXs} />
                ) : (
                    <ArrowDownIcon color="#2B2523" className={classes.marginLeftXs} />
                )}
            </div>
            <Menu
                id="custom-select-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={onClose}
                PaperProps={{
                    className: classes.paper,
                }}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                {props.selectList.map((selectOption) => (
                    <MenuItem
                        key={selectOption.entityId}
                        selected={selectOption.entityId === props.selectedItem.entityId}
                        value={selectOption.entityId}
                        onClick={() => {
                            onItemSelection(selectOption);
                        }}
                    >
                        {selectOption.displayName}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}
