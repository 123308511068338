import React from "react";
import { connect } from "react-redux";
import { Typography } from "@material-ui/core";
import SternumImprovedButton from "../SUI/SternumImprovedButton/SternumImprovedButton";
import { useStyles } from "./GetSupportBannerStyle";
import { GlobalState } from "../../lib/state/GlobalState";
import { openGetSupportModal } from "../../lib/redux/modals/OpenModalAction";
import ModalType from "../../lib/state/ModalType";
import ModalKey from "../../lib/state/ModalKey";
import { closeModalAction } from "../../lib/redux/modals/CloseModalAction";

export interface GetSupportBannerProps {
    title?: string;
}

const mapStateToProps = (state: GlobalState, ownProps: GetSupportBannerProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        openGetSupportModal: (key: string, onSuccess?: () => unknown) => dispatch(openGetSupportModal(key, onSuccess)),
        closeModal: (key: string) => dispatch(closeModalAction(new ModalKey(ModalType.GetSupportModal, key))),
    };
};

type GetSupportBannerPropsWithHOC = GetSupportBannerProps &
    ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps>;

function GetSupportBannerComponent({ title = "Looking for help?", openGetSupportModal }: GetSupportBannerPropsWithHOC) {
    const classes = useStyles();

    const handleGetSupportClick = () => {
        openGetSupportModal("getSupport");
    };

    return (
        <div className={classes.root}>
            <div className={classes.inner}>
                <Typography variant="h6" className={classes.title}>
                    {title}
                </Typography>
                <SternumImprovedButton
                    buttonType="regular:outlined"
                    content="Get Support"
                    fullWidth={false}
                    onClick={handleGetSupportClick}
                />
            </div>
        </div>
    );
}

export const GetSupportBanner: React.FC<GetSupportBannerProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(GetSupportBannerComponent);
