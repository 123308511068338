import React from "react";

import { SternumLongLogoMultiColorIcon } from "../../SUI/SternumIcon";
import { SignupContainer } from "../../SignupContainer";
import { useEmailNotificationStyle } from "./EmailNotification.style";

interface EmailNotificationProps {
    verificationEmail: string;
}

function EmailNotification({ verificationEmail }: EmailNotificationProps) {
    const classes = useEmailNotificationStyle();

    return (
        <SignupContainer>
            <div className={classes.content}>
                <div className={classes.innerContainer}>
                    <SternumLongLogoMultiColorIcon />
                    <div className={classes.description}>A link was sent to your email address</div>
                    <div className={classes.email}>{verificationEmail}</div>
                </div>
            </div>
        </SignupContainer>
    );
}

export { EmailNotification };
