import React, { useMemo } from "react";
import { connect } from "react-redux";

import { GlobalState } from "../../../../lib/state/GlobalState";
import { useCommonStyle } from "../../../CommonStyle";
import { useAlertsFilterStyle } from "./AlertsFilter.style";
import { Box, Tooltip, Typography } from "@material-ui/core";
import SternumImprovedButton from "../../../SUI/SternumImprovedButton/SternumImprovedButton";
import { CrossIcon } from "../../../SUI/SternumIcon";
import { resetAlertsFilterAction } from "../../../../lib/redux/dashboardRegular";
import {
    formatDateRanges,
    getUniqueDateRanges,
    joinOverlappingDateRanges,
    MAX_VISIBLE_CATEGORIES,
} from "./AlertsFilter.utils";

export interface AlertsFilterProps {}

const mapStateToProps = (state: GlobalState, ownProps: AlertsFilterProps) => {
    function calculateTotalByAppliedFilter(param: "issues_count" | "devices_count"): number {
        let total = 0;
        const statistics = state.dashboardRegular.alertsStatistics?.category_count || [];

        Object.entries(state.dashboardRegular.alertsFilter.selectedCategories).map(([category, dateRanges]) => {
            for (const range of dateRanges) {
                statistics.forEach((point) => {
                    if (point.category === category && point.period >= range.from && point.period < range.to) {
                        total += point[param];
                    }
                });
            }
        });

        return total;
    }

    // @ts-ignore
    const allDateRanges = Object.values(state.dashboardRegular.alertsFilter.selectedCategories).flat();
    const uniqueDateRanges = getUniqueDateRanges(allDateRanges);
    const joinedDateRanges = joinOverlappingDateRanges(uniqueDateRanges);

    return {
        categoryTimeRange: state.dashboardRegular.categoryTimeRange,
        selectedCategories: state.dashboardRegular.alertsFilter.selectedCategories,
        selectedDateRanges: joinedDateRanges,
        alertsStatistics: state.dashboardRegular.alertsStatistics,
        totalAlertsCount: calculateTotalByAppliedFilter("issues_count"),
        totalDevicesCount: calculateTotalByAppliedFilter("devices_count"),
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        resetAlertsFilter: () => dispatch(resetAlertsFilterAction()),
    };
};

type AlertsFilterPropsWithHOC = AlertsFilterProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

function AlertsFilterComponent({
    selectedCategories,
    selectedDateRanges,
    categoryTimeRange,
    resetAlertsFilter,
    totalAlertsCount,
    totalDevicesCount,
    alertsStatistics,
}: AlertsFilterPropsWithHOC) {
    const classes = useAlertsFilterStyle();
    const classesCommon = useCommonStyle();

    const handleFilterReset = () => {
        resetAlertsFilter();
    };

    const categoryNames = useMemo(() => {
        const categories = Object.keys(selectedCategories);

        return categories
            .map(
                (category) =>
                    alertsStatistics.category_count.find((point) => point.category === category)?.category_label
            )
            .filter(Boolean);
    }, [selectedCategories, alertsStatistics]);

    const visibleCategoryNames = categoryNames.slice(0, MAX_VISIBLE_CATEGORIES);
    const hiddenCategories = categoryNames.slice(MAX_VISIBLE_CATEGORIES);

    const isFilterApplied = Object.keys(selectedCategories).length > 0;

    return (
        <div className={classes.root} role="presentation" aria-label="dashboard alerts filter">
            <div className={classes.left}>
                <Typography variant="caption">Category</Typography>

                {visibleCategoryNames.length > 0 ? (
                    <Tooltip classes={{ tooltip: classes.infoTooltip }} title={categoryNames.join(", ")}>
                        <Box>
                            <Typography variant="caption" className={classes.parameterValue}>
                                {visibleCategoryNames.join(", ")}
                            </Typography>
                            {hiddenCategories.length > 0 && (
                                <div className={classes.extraCategoriesCount}>+{hiddenCategories.length}</div>
                            )}
                        </Box>
                    </Tooltip>
                ) : (
                    <Typography variant="caption" className={classes.parameterValue}>
                        Not chosen
                    </Typography>
                )}

                {isFilterApplied && (
                    <SternumImprovedButton
                        fullWidth={false}
                        buttonType="text:blue"
                        content={
                            <Typography variant="caption" style={{ lineHeight: 2 }}>
                                Reset to default
                            </Typography>
                        }
                        icon={<CrossIcon width={14} height={14} />}
                        className={classes.resetFilterButton}
                        onClick={handleFilterReset}
                    />
                )}
            </div>

            <div className={classes.right}>
                <div className={classes.rightBlock}>
                    <Typography variant="caption">Date</Typography>
                    {isFilterApplied ? (
                        <Typography variant="caption" className={classes.parameterValue}>
                            {formatDateRanges(selectedDateRanges, categoryTimeRange)}
                        </Typography>
                    ) : (
                        <Typography variant="caption" className={classes.parameterValue}>
                            Not chosen
                        </Typography>
                    )}
                </div>

                <div className={classes.rightBlock}>
                    <Typography variant="caption">Alerts</Typography>
                    <Typography variant="caption" className={classes.parameterValue}>
                        {totalAlertsCount}
                    </Typography>
                </div>

                <div className={classes.rightBlock}>
                    <Typography variant="caption">Devices</Typography>
                    <Typography variant="caption" className={classes.parameterValue}>
                        {totalDevicesCount}
                    </Typography>
                </div>
            </div>
        </div>
    );
}

export const AlertsFilter: React.FC<AlertsFilterProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(AlertsFilterComponent);
