import { makeStyles } from "@material-ui/core";

export const useLinuxTabsStyle = makeStyles((theme) => ({
    tabsRoot: {
        width: "100%",
        marginRight: theme.spacing(2),
        minHeight: 36,
    },
    tabContent: {
        height: "calc(100% - 36px)",
        padding: 18,
        background: "#FFFFFF",
        boxShadow: "0 0 23px rgba(231, 232, 235, 0.25)",
        borderRadius: 14,
        overflow: "auto",
    },
}));
