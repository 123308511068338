import React from "react";
import { connect } from "react-redux";
import { GlobalState } from "../../lib/state/GlobalState";
import ServiceWire from "../../lib/services/ServiceWire";
import { DashboardRegular } from "./DashboardRegular";
import { DashboardFree } from "./DashboardFree";

export interface DashboardProps {
    sidebarOpen: boolean;
}

const mapStateToProps = (state: GlobalState, ownProps: DashboardProps) => {
    const selectedClient = ServiceWire.getClientsService().getSelectedClient();

    return {
        isFreeUser: selectedClient.isTrialTier(),
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

type DashboardPropsWithHOC = DashboardProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export function DashboardComponent({ isFreeUser, sidebarOpen }: DashboardPropsWithHOC) {
    if (isFreeUser) {
        return <DashboardFree sidebarOpen={sidebarOpen} />;
    }

    return <DashboardRegular sidebarOpen={sidebarOpen} />;
}

export const Dashboard: React.FC<DashboardProps> = connect(mapStateToProps, mapDispatchToProps)(DashboardComponent);
