import {
    CssBaseline,
    Icon,
    IconButton,
    Snackbar,
    SnackbarContent,
    Typography,
    WithStyles,
    withStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import * as queryString from "querystring";
import * as React from "react";
import { connect } from "react-redux";
import EntityManager from "../../lib/infra/EntityManager";
import HashSet from "../../lib/infra/HashSet";
import Utils from "../../lib/infra/Utils";
import { fetchPendingIssuesCountAction } from "../../lib/redux/issues/FetchPendingIssuesCountAction";
import { showNotificationAction } from "../../lib/redux/notifications/ShowNotificationAction";
import {
    openActivitiesListModal,
    openCveViewModalAction,
    openDeviceViewModalAction,
    openLibraryViewModalAction,
    openSternumGeneratedEventViewModalAction,
    openTraceViewModalAction,
    openUsedLibraryViewModalAction,
} from "../../lib/redux/modals/OpenModalAction";
import ActivityManager from "../../lib/services/ActivityManager";
import PollingSubscriptionInfo from "../../lib/services/PollingSubscriptionInfo";
import ServiceWire from "../../lib/services/ServiceWire";
import { NotificationOperation } from "../../lib/services/WebsocketService";
import ActivityInfo from "../../lib/state/ActivityInfo";
import ActivityType from "../../lib/state/ActivityType";
import CveInfo from "../../lib/state/CveInfo";
import { GlobalState } from "../../lib/state/GlobalState";
import PollingChangeType from "../../lib/state/PollingChangeType";
import SternumGeneratedEventInfo from "../../lib/state/SternumGeneratedEventInfo";
import TraceInfo from "../../lib/state/TraceInfo";
import Modals from "../Modals/Modals";
import SideBar from "../SideBar/SideBar";
import appContentStyle from "./AppContentStyle";
import { NotificationMessage } from "../../lib/state/NotificationsState";
import IssueInfo from "../../lib/state/IssueInfo";
import { LoadingContainer } from "../LoadingContainer";
import { SternumLogoGradientIcon } from "../SUI/SternumIcon";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    sideBarOpen: boolean;
    snackbarDisplayed: boolean;
    activities: ActivityInfo[];
    total_activities_count: number;
    isAppReady: boolean;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof appContentStyle> {
    renderContentComponent: (sideBarOpen: boolean) => any;
    location;
    history;

    currentlyViewedDeviceId?: string;
    currentlyViewedTraceId?: string;
    currentlyViewedIssueId?: string;
    currentlyViewedLibraryId?: string;
    currentlyViewedCveId?: string;
    currentlyViewedUsedLibraryId?: string;
    currentlyViewedGeneratedEventId?: string;

    openSternumGeneratedEventViewModal?: (
        key: string,
        generatedEventId: string,
        shouldDisplayLinkToDeviceView: boolean,
        shouldDisplayLinkToExportReport: boolean,
        displayXButton: boolean,
        displayBackButton: boolean,
        issue?: IssueInfo,
        issueId?: string
    ) => void;
    openTraceViewModal?: (
        key: string,
        traceId: string,
        displayXButton: boolean,
        displayBackButton: boolean,
        shouldDisplayLinkToDeviceView: boolean,
        shouldDisplayLinkToExportReport: boolean,
        issue?: IssueInfo
    ) => void;
    openDeviceViewModal?: (key: string, deviceId: string) => void;
    openLibraryViewModal?: (
        key: string,
        libraryId: string,
        displayXButton: boolean,
        displayBackButton: boolean
    ) => void;
    openCveViewModal?: (key: string, cveId: string, displayXButton: boolean, displayBackButton: boolean) => void;
    openUsedLibraryViewModal?: (
        key: string,
        usedLibraryId: string,
        displayXButton: boolean,
        displayBackButton: boolean
    ) => void;
    openActivitiesListModal?: (
        key: string,
        preloadedEntities: ActivityInfo[],
        displayXButton: boolean,
        displayBackButton: boolean
    ) => void;

    fetchPendingIssuesCountAction?: () => void;
    showNotification: (message: NotificationMessage) => void;

    sectionsToDisplayOnSideBarSet?: HashSet;
    dangerSideBar?: boolean;

    disableAlerts?: boolean;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {
        currentlyViewedDeviceId: state.ui.currentlyViewedDeviceId,
        currentlyViewedTraceId: state.ui.currentlyViewedTraceId,
        currentlyViewedIssueId: state.ui.currentlyViewedIssueId,
        currentlyViewedLibraryId: state.ui.currentlyViewedLibraryId,
        currentlyViewedCveId: state.ui.currentlyViewedCveId,
        currentlyViewedUsedLibraryId: state.ui.currentlyViewedUsedLibraryId,
        currentlyViewedGeneratedEventId: state.ui.currentlyViewedGeneratedEventId,
    };
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {
        openSternumGeneratedEventViewModal: (
            key: string,
            sternumGeneratedEventId: string,
            shouldDisplayLinkToDeviceView: boolean,
            shouldDisplayLinkToExportReport: boolean,
            displayXButton: boolean,
            displayBackButton: boolean,
            issue: IssueInfo,
            issueId: string
        ) =>
            dispatch(
                openSternumGeneratedEventViewModalAction(
                    key,
                    sternumGeneratedEventId,
                    shouldDisplayLinkToDeviceView,
                    shouldDisplayLinkToExportReport,
                    displayXButton,
                    displayBackButton,
                    issue,
                    issueId
                )
            ),
        openTraceViewModal: (
            key: string,
            traceId,
            displayXButton,
            displayBackButton,
            shouldDisplayLinkToDeviceView,
            shouldDisplayLinkToExportReport,
            issue
        ) =>
            dispatch(
                openTraceViewModalAction(
                    key,
                    traceId,
                    displayXButton,
                    displayBackButton,
                    shouldDisplayLinkToDeviceView,
                    shouldDisplayLinkToExportReport,
                    issue
                )
            ),
        openDeviceViewModal: (key: string, deviceId: string) =>
            dispatch(openDeviceViewModalAction(key, false, deviceId)),
        openLibraryViewModal: (key: string, libraryId: string, displayXButton: boolean, displayBackButton: boolean) =>
            dispatch(openLibraryViewModalAction(key, libraryId, displayXButton, displayBackButton)),
        openCveViewModal: (key: string, cveId: string, displayXButton: boolean, displayBackButton: boolean) =>
            dispatch(openCveViewModalAction(key, cveId, displayXButton, displayBackButton)),
        openUsedLibraryViewModal: (
            key: string,
            usedLibraryId: string,
            displayXButton: boolean,
            displayBackButton: boolean
        ) => dispatch(openUsedLibraryViewModalAction(key, usedLibraryId, displayXButton, displayBackButton)),
        openActivitiesListModal: (
            key: string,
            preloadedEntities: ActivityInfo[],
            displayXButton: boolean,
            displayBackButton: boolean
        ) => dispatch(openActivitiesListModal(key, preloadedEntities, displayXButton, displayBackButton)),
        fetchPendingIssuesCountAction: () => dispatch(fetchPendingIssuesCountAction()),
        showNotification: (message: NotificationMessage) => dispatch(showNotificationAction(message)),
    };
};

class AppContent extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            sideBarOpen: true,
            snackbarDisplayed: false,
            activities: null,
            total_activities_count: null,
            isAppReady: false,
        };
    }

    /**
     * Occurs once the component finished its initialization process.
     */
    async componentDidMount() {
        let isAppReady = false,
            isConfigurationReady = false,
            isUserRolesReady = false,
            isSternumBasicRolesReady = false;

        // Start poller again if user press logout and login again (app life cycle won't go through app component)
        ServiceWire.getPollingService().startPolling();
        ServiceWire.getWebsocketService().runWebsocket();
        if (!ServiceWire.getConfigurationService().getIsDataLoadedFlag()) {
            await ServiceWire.getConfigurationService().loadConfigurations();
            isConfigurationReady = true;
        } else isConfigurationReady = true;

        // Fetch the roles of a user in a client.
        if (ServiceWire.getAuthorizationService().getCachedUserRoles() === null) {
            await ServiceWire.getAuthorizationService().fetchUserRoles();
            isUserRolesReady = true;
        } else isUserRolesReady = true;

        if (ServiceWire.getAuthorizationService().getSternumBasicRoles() === null) {
            await ServiceWire.getAuthorizationService().fetchSternumBasicRoles();
            isSternumBasicRolesReady = true;
        } else isSternumBasicRolesReady = true;

        // TODO: If new endpoint will be needed then add it here to get userPlan field

        isAppReady = isConfigurationReady && isUserRolesReady && isSternumBasicRolesReady;

        this.setState({ isAppReady });

        if (!this.props.disableAlerts) {
            ServiceWire.getPollingService().subscribe(
                new PollingSubscriptionInfo(
                    "AppContent",
                    PollingChangeType.ACTIVITIES,
                    this.handleNewActivities.bind(this)
                )
            );
        }

        ServiceWire.getWebsocketService().subscribe("AppContent", NotificationOperation.ALERT_CREATED, (message) => {
            this.props.showNotification(`Received alert: ${message.display_name}`);
        });

        let evaluatedParsedUrlQuery = Utils.parseQueryStringParamsObject(queryString.parse(this.props.location.search));

        let deviceId: string = evaluatedParsedUrlQuery["deviceId"];
        let traceId: string = evaluatedParsedUrlQuery["traceId"];
        let issueId: string = evaluatedParsedUrlQuery["issueId"];
        let libraryId: string = evaluatedParsedUrlQuery["libraryId"];
        let cveId: string = evaluatedParsedUrlQuery["cveId"];
        let usedLibraryId: string = evaluatedParsedUrlQuery["usedLibraryId"];
        let generatedEventId: string = evaluatedParsedUrlQuery["generatedEventId"];

        if (deviceId) {
            this.props.openDeviceViewModal(deviceId, deviceId);
        }

        if (generatedEventId && issueId) {
            ServiceWire.getSternumService()
                .getIssueById(issueId)
                .then((issue) => {
                    this.props.openSternumGeneratedEventViewModal(
                        generatedEventId,
                        generatedEventId,
                        true,
                        true,
                        true,
                        false,
                        issue,
                        issueId
                    );
                });
        }

        if (traceId && issueId) {
            ServiceWire.getSternumService()
                .getIssueById(issueId)
                .then((issue) => {
                    this.props.openTraceViewModal(traceId, traceId, true, true, true, true, issue);
                });
        } else if (traceId) {
            this.props.openTraceViewModal(traceId, traceId, true, true, true, true);
        }

        if (libraryId) {
            this.props.openLibraryViewModal(libraryId, libraryId, true, true);
        }
        if (cveId) {
            this.props.openCveViewModal(cveId, cveId, true, true);
        }
        if (usedLibraryId) {
            this.props.openUsedLibraryViewModal(usedLibraryId, usedLibraryId, true, true);
        }
    }

    /**
     * Occurs once the component is being destroyed.
     */
    UNSAFE_componentWillUnmount(): void {
        if (!this.props.disableAlerts) {
            ServiceWire.getPollingService().unsubscribe("AppContent", PollingChangeType.ACTIVITIES);
        }
    }

    /**
     * Occurs once the component is about to receive props.
     */
    UNSAFE_componentWillReceiveProps(nextProps: Readonly<AppProps>, nextContext: any): void {
        if (
            nextProps.currentlyViewedDeviceId !== this.props.currentlyViewedDeviceId ||
            nextProps.currentlyViewedTraceId !== this.props.currentlyViewedTraceId ||
            nextProps.currentlyViewedLibraryId !== this.props.currentlyViewedLibraryId ||
            nextProps.currentlyViewedCveId !== this.props.currentlyViewedCveId ||
            nextProps.currentlyViewedUsedLibraryId !== this.props.currentlyViewedUsedLibraryId ||
            nextProps.currentlyViewedGeneratedEventId !== this.props.currentlyViewedGeneratedEventId
        ) {
            let queryParamsObject = queryString.parse(window.location.search.replace("?", ""));
            delete queryParamsObject["deviceId"];
            delete queryParamsObject["traceId"];
            delete queryParamsObject["issueId"];
            delete queryParamsObject["libraryId"];
            delete queryParamsObject["cveId"];
            delete queryParamsObject["usedLibraryId"];
            delete queryParamsObject["generatedEventId"];
            if (nextProps.currentlyViewedDeviceId) {
                queryParamsObject["deviceId"] = nextProps.currentlyViewedDeviceId;
            }
            if (nextProps.currentlyViewedTraceId) {
                queryParamsObject["traceId"] = nextProps.currentlyViewedTraceId;
            }
            if (nextProps.currentlyViewedIssueId) {
                queryParamsObject["issueId"] = nextProps.currentlyViewedIssueId;
            }
            if (nextProps.currentlyViewedLibraryId) {
                queryParamsObject["libraryId"] = nextProps.currentlyViewedLibraryId;
            }
            if (nextProps.currentlyViewedCveId) {
                queryParamsObject["cveId"] = nextProps.currentlyViewedCveId;
            }
            if (nextProps.currentlyViewedUsedLibraryId) {
                queryParamsObject["usedLibraryId"] = nextProps.currentlyViewedUsedLibraryId;
            }
            if (nextProps.currentlyViewedGeneratedEventId) {
                queryParamsObject["generatedEventId"] = nextProps.currentlyViewedGeneratedEventId;
            }

            let currentPathName = this.props.location.pathname;

            this.props.history.push({
                pathname: currentPathName,
                search: `?${queryString.stringify(queryParamsObject)}`,
            });
        }
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        if (!this.state.isAppReady) {
            return (
                <LoadingContainer>
                    <SternumLogoGradientIcon width={72} height={72} useAnimation={true} />
                </LoadingContainer>
            );
        }

        let snackbarDisplayComponent = null;

        let isAnyProductionAlert = this.state.activities?.some?.((activity) =>
            this.isTraceInfoWithProductionStatus(activity.reference1 as TraceInfo)
        );

        if (this.state.activities) {
            if (this.state.activities.length === 1) {
                snackbarDisplayComponent = (
                    <>
                        <a
                            target={"_blank"}
                            onClick={() => this.activityClicked(this.state.activities[0], true)}
                            className={classes.attackedDeviceLink}
                        >
                            {/* Open external link icon */}
                            <Icon className={classNames("fa fa-external-link-alt", classes.linkToDeviceIcon)} />

                            {/* Text */}
                            <Typography variant="body2" className={classes.attackedDeviceText}>
                                {this.getProductionOrStagingMark(this.state.activities[0].reference1 as TraceInfo)}{" "}
                                {ActivityManager.getActivityDisplayString(this.state.activities[0])}
                            </Typography>
                        </a>
                    </>
                );
            } else {
                const productionAlertsCount = this.state.activities.filter((activity) =>
                    this.isTraceInfoWithProductionStatus(activity.reference1 as TraceInfo)
                ).length;
                const stagingAlertsCount = this.state.activities.length - productionAlertsCount;

                snackbarDisplayComponent = (
                    <>
                        <div
                            onClick={() => this.openActivitiesListModal()}
                            className={classes.attackedDeviceLinkMultiAlerts}
                        >
                            {productionAlertsCount > 0 && (
                                <Typography variant="body2" className={classes.attackedDeviceText}>
                                    {productionAlertsCount} new [PRODUCTION] alerts require your attention
                                </Typography>
                            )}
                            {stagingAlertsCount > 0 && (
                                <Typography variant="body2" className={classes.attackedDeviceText}>
                                    {stagingAlertsCount} new [STAGING] alerts require your attention
                                </Typography>
                            )}
                        </div>
                    </>
                );
            }
        }

        return (
            <div className={classes.wholeAppContainer}>
                <CssBaseline />

                {/* Modals container */}
                <Modals />

                {/* Menu sidebar */}
                <SideBar
                    onSideBarToggled={(sideBarOpen) => this.handleSideBarToggled(sideBarOpen)}
                    sectionsToDisplayOnSideBarSet={this.props.sectionsToDisplayOnSideBarSet}
                    dangerSideBar={this.props.dangerSideBar}
                />

                {/* Content */}
                <main className={classNames(classes.content, this.state.sideBarOpen && "sidebar-open")}>
                    {this.props.renderContentComponent(this.state.sideBarOpen)}
                </main>

                {/* Snackbar for newly attacked devices */}
                {this.state.snackbarDisplayed && this.state.activities && this.state.activities.length && (
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        open={this.state.snackbarDisplayed}
                        aria-describedby="attacked-device-snackbar"
                    >
                        <SnackbarContent
                            className={
                                isAnyProductionAlert
                                    ? classes.attackedDevicesSnackbar
                                    : classes.attackedDevicesNoProductionSnackbar
                            }
                            message={snackbarDisplayComponent}
                            action={[
                                // Close button
                                <IconButton
                                    key="close"
                                    aria-label="Close"
                                    color="inherit"
                                    onClick={() => this.handleSnackbarClosed()}
                                >
                                    <CloseIcon className={classes.snackbarCloseIcon} />
                                </IconButton>,
                            ]}
                        />
                    </Snackbar>
                )}
            </div>
        );
    }

    private getProductionOrStagingMark(referenceTraceInfo: TraceInfo) {
        return this.isTraceInfoWithProductionStatus(referenceTraceInfo) ? "[PRODUCTION]" : "[STAGING]";
    }

    private isTraceInfoWithProductionStatus(referenceTraceInfo: TraceInfo) {
        return referenceTraceInfo?.device?.lastSeenVersionStatus === "PRODUCTION";
    }

    /**
     * Occurs once side bar is toggled.
     */
    private handleSideBarToggled(sideBarOpen: boolean) {
        this.setState({
            sideBarOpen: sideBarOpen,
        });
    }

    /**
     * Handles new activities.
     */
    private handleNewActivities(fromTimestamp: number, changedData: Object) {
        if (changedData && changedData["entities"] && changedData["entities"].length) {
            let entities = EntityManager.getSternumEntities(changedData).map((entity) => entity as ActivityInfo);
            const total_count = changedData["total_count"] ? changedData["total_count"] : entities.length;
            if (this.state.activities) {
                entities = this.state.activities.concat(entities);
            }

            this.props.fetchPendingIssuesCountAction();

            this.setState({
                snackbarDisplayed: true,
                activities: entities,
                total_activities_count: this.state.total_activities_count + total_count,
            });
        }
    }

    /**
     * Occurs once the activity is clicked.
     */
    private activityClicked(activity: ActivityInfo, shouldCloseSnackbar: boolean) {
        if (shouldCloseSnackbar) {
            this.setState(
                {
                    snackbarDisplayed: false,
                    total_activities_count: 0,
                    activities: null,
                },
                () => {
                    this.openActivityEntityViewModal(activity);
                }
            );
        } else {
            this.openActivityEntityViewModal(activity);
        }
    }

    /**
     * Opens the entity view for activity.
     */
    private openActivityEntityViewModal(activity: ActivityInfo) {
        switch (activity.activityType) {
            case ActivityType.CVE_DETECTED:
                let cveInfo = activity.reference1 as CveInfo;
                this.props.openCveViewModal(cveInfo.entityId, cveInfo.entityId, true, false);
                break;

            case ActivityType.ATTACK_TRACE_DETECTED:
                let traceInfo = activity.reference1 as TraceInfo;
                this.props.openTraceViewModal(traceInfo.entityId, traceInfo.entityId, true, false, true, true);
                break;

            case ActivityType.STERNUM_TRIGGER_MET:
                let sternumGeneratedEvent = activity.reference1 as SternumGeneratedEventInfo;
                this.props.openSternumGeneratedEventViewModal(
                    sternumGeneratedEvent.entityId,
                    sternumGeneratedEvent.entityId,
                    true,
                    true,
                    true,
                    false,
                    null,
                    null
                );
                break;
        }
    }

    /**
     * Handles the snackbar being closed.
     */
    private handleSnackbarClosed() {
        this.setState({
            snackbarDisplayed: false,
            total_activities_count: 0,
            activities: null,
        });
    }

    private openActivitiesListModal() {
        this.props.openActivitiesListModal("ActivitiesList", this.state.activities, true, true);
        this.setState({ snackbarDisplayed: false, total_activities_count: 0, activities: null });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(appContentStyle)(AppContent));
