import { makeStyles } from "@material-ui/core";

export const useDeviceTilesStyle = makeStyles((theme) => ({
    deviceTilesContainer: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: 16,
        marginBottom: 16,
    },
}));
