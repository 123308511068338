import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import moment from "moment";

import { CancelIcon, InfoCircleIcon } from "../SUI/SternumIcon";

import { Button } from "../SUI/Button";
import IssueInfo from "../../lib/state/IssueInfo";
import {
    IssueInfoPutReason,
    IssueInfoReasonType,
    IssueInfoStateStatus,
    IssueInfoStatus,
} from "../../lib/state/IssueInfoType";
import { GlobalState } from "../../lib/state/GlobalState";
import { putIssueStatusAction } from "../../lib/redux/issues/PutIssueStatusAction";
import {
    ResolvingReasons,
    ResolvingReasonsAddItem,
    ResolvingReasonsCustomItem,
    ResolvingReasonsItem,
} from "../TraceView/ResolvingReasons";
import { CustomReason, DefaultIssueReason, IsSelectedByDefaultIssueReasonType } from "./TraceViewAlertStatus.types";
import { useTraceViewAlertStatusStyle } from "./TraceViewAlertStatus.style";
import { reasonsDataByIssueReasonType } from "./TraceViewAlertStatus.model";
import { getIssueResolveReasonsAction } from "../../lib/redux/issues/GetIssueResolveReasonsAction";
import { useCommonStyle } from "../CommonStyle";
import { SternumTooltip } from "../SUI/SternumTooltip";

export interface TraceViewAlertStatusProps {
    isResolving?: boolean;
    initialOpen?: boolean;
    resolvingSubtitle?: ReactNode;
    onRefresh?: () => unknown;
    onSetIsResolving?: (isResolving: boolean) => unknown;
}

const mapStateToProps = (state: GlobalState, ownProps: TraceViewAlertStatusProps) => {
    const issueId = state.issues.selectedIssue?.issueId || state.ui.currentlyViewedIssueId;

    return {
        selectedIssue: state.issues.selectedIssue, // Can be undefined
        selectedIssueState: state.issues.issueStateByIssueId.get(issueId), // Can be null or undefined
        issueResolveReasons: state.issues.issueResolveReasons.data,
        issueResolveReasonsStatus: state.issues.issueResolveReasons.status,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        putIssueInfoStatus: (
            issueId: string,
            status: IssueInfoStatus,
            reasons?: IssueInfoPutReason[],
            onDone?: () => unknown
        ) => dispatch(putIssueStatusAction({ issueId, status, reasons, onDone })),
        getIssueResolveReasons: () => dispatch(getIssueResolveReasonsAction()),
    };
};

type TraceViewAlertStatusPropsWithHOC = TraceViewAlertStatusProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {};

export const TraceViewAlertStatus: React.FC<TraceViewAlertStatusProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(TraceViewAlertStatusComponent);

function TraceViewAlertStatusComponent({
    selectedIssue,
    selectedIssueState,
    issueResolveReasons,
    putIssueInfoStatus,
    getIssueResolveReasons,
    onRefresh,
    isResolving: isResolvingFromProps,
    onSetIsResolving,
    initialOpen = false,
    resolvingSubtitle = "Please choose one or several reasons for Alert. Adding reasons before resolving the Alert allows us to improve the AI models and helps you to track Alerts better.",
}: TraceViewAlertStatusPropsWithHOC) {
    const classes = useTraceViewAlertStatusStyle();
    const classesCommon = useCommonStyle();
    const issueStatus = getIssueInfoStatus(selectedIssue);
    const reasons = getReasons(selectedIssue);

    const [isResolvingState, setIsResolvingState] = useState(initialOpen);
    const [customReasons, setCustomReasons] = useState<CustomReason[]>([]);
    const [isSelectedByDefaultIssueReasonType, setIsSelectedByDefaultIssueReasonType] =
        useState<IsSelectedByDefaultIssueReasonType>({});

    const isIssueInfoStatusResolving = selectedIssueState?.issueStatus === IssueInfoStateStatus.Resolving;
    const isIssueInfoStatusDismissing = selectedIssueState?.issueStatus === IssueInfoStateStatus.Dismissing;

    const isResolving = isResolvingFromProps ?? isResolvingState;
    const setIsResolving = (isResolving: boolean) => {
        if (isResolving) {
            const newIsSelectedByDefaultIssueReasonType: IsSelectedByDefaultIssueReasonType = {};

            reasons.default.map((defaultReasonData) => {
                newIsSelectedByDefaultIssueReasonType[defaultReasonData.type] = true;
            });

            setCustomReasons([...reasons.custom]);
            setIsSelectedByDefaultIssueReasonType(newIsSelectedByDefaultIssueReasonType);
        }

        if (onSetIsResolving) {
            return onSetIsResolving(isResolving);
        }

        setIsResolvingState(isResolving);
    };

    const { defaultIssueResolveReasons, canAddCustomReasons } = useMemo(() => {
        const canAddCustomReasons = issueResolveReasons.some(
            (issueResolve) => issueResolve.type === IssueInfoReasonType.Other
        );

        const defaultIssueResolveReasons = issueResolveReasons
            .filter((issuesResolve) => issuesResolve.type !== IssueInfoReasonType.Other)
            .map(
                (issueResolve): DefaultIssueReason => ({
                    type: issueResolve.type,
                    title: issueResolve.text || reasonsDataByIssueReasonType[issueResolve.type]?.title || "",
                })
            )
            .filter((issuesResolve) => issuesResolve.title);

        return { canAddCustomReasons, defaultIssueResolveReasons };
    }, [issueResolveReasons]);

    const isAnyEditedIssueSelected = useMemo(() => {
        if (
            defaultIssueResolveReasons.some((defaultReason) => isSelectedByDefaultIssueReasonType[defaultReason.type])
        ) {
            return true;
        }

        return customReasons.some((customReason) => customReason.isSelected);
    }, [isSelectedByDefaultIssueReasonType, customReasons, reasons.default]);

    const clearDefaultReasons = () => {
        setIsSelectedByDefaultIssueReasonType({});
    };

    const clearCustomReasons = () => {
        setCustomReasons([]);
    };

    const closeResolvingView = () => {
        setIsResolving(false);
        clearDefaultReasons();
        clearCustomReasons();
    };

    const setIsSelectedDefaultIssue = (issueReasonType: IssueInfoReasonType, isSelected: boolean) => {
        if (isSelected) {
            setIsSelectedByDefaultIssueReasonType({ ...isSelectedByDefaultIssueReasonType, [issueReasonType]: true });
            return;
        }

        const newState = { ...isSelectedByDefaultIssueReasonType };
        delete newState[issueReasonType];

        setIsSelectedByDefaultIssueReasonType(newState);
    };

    useEffect(() => {
        getIssueResolveReasons();
    }, []);

    const addCustomReason = (title: string) => {
        if (title) {
            setCustomReasons((reasons) => [...reasons, { title, isSelected: true }]);
        }
    };

    const deleteCustomReason = (customReasonIndex: number) => {
        const newCustomReasons = [...customReasons];

        newCustomReasons.splice(customReasonIndex, 1);
        setCustomReasons(newCustomReasons);
    };

    const setCustomReasonSelection = (customReasonIndex: number, isSelected: boolean) => {
        const newCustomReasons = [...customReasons];

        newCustomReasons[customReasonIndex] = { ...newCustomReasons[customReasonIndex], isSelected };
        setCustomReasons(newCustomReasons);
    };

    const getAllSelectedDefaultAndCustomReasons = (): { type: IssueInfoReasonType; text?: string }[] => {
        const reasons: { type: IssueInfoReasonType; text?: string }[] = [];

        defaultIssueResolveReasons.forEach((defaultReason) => {
            if (isSelectedByDefaultIssueReasonType[defaultReason.type]) {
                reasons.push({
                    type: defaultReason.type,
                    text: defaultReason.title,
                });
            }
        });

        customReasons.forEach((customReason) => {
            if (customReason.isSelected) {
                reasons.push({
                    type: IssueInfoReasonType.Other,
                    text: customReason.title,
                });
            }
        });

        return reasons;
    };

    const handleDismissAlert = async () => {
        if (!selectedIssue) {
            return;
        }

        try {
            const reasons = getAllSelectedDefaultAndCustomReasons();
            await putIssueInfoStatus(selectedIssue.issueId, IssueInfoStatus.Dismissed, reasons, () => onRefresh?.());
        } catch (e) {
            console.error("Error while putting issue status to DISMISSED");
        }

        closeResolvingView();
    };

    const handleResolveAlert = async () => {
        if (!selectedIssue) {
            return;
        }

        try {
            const reasons = getAllSelectedDefaultAndCustomReasons();
            await putIssueInfoStatus(selectedIssue.issueId, IssueInfoStatus.Resolved, reasons, () => onRefresh?.());
        } catch (e) {
            console.error("Error while putting issue status to RESOLVED");
        }

        closeResolvingView();
    };

    const areAlertReasonsVisible =
        // issueStatus === IssueInfoStatus.Resolved && // TODO: Should it be displayed when status is not resolved but there are reasons
        !isResolving &&
        selectedIssueState?.issueStatus !== IssueInfoStateStatus.Resolving &&
        !!(reasons.default.length || reasons.custom.length);

    const renderIssueInfoStatusTags = () => {
        const tags = [];

        if (issueStatus === IssueInfoStatus.Resolved) {
            tags.push(
                <Box key={issueStatus} display="flex" alignItems="center">
                    <div role="presentation" aria-label="tag" className={classes.greenTag}>
                        Resolved
                    </div>
                    <Typography variant="caption" className={classesCommon.marginLeft}>
                        at {moment(selectedIssue?.updated).format(" HH:mm MM/DD/YYYY")} by{" "}
                        {selectedIssue.getResolvedBy()}
                    </Typography>
                </Box>
            );
        }

        if (issueStatus === IssueInfoStatus.Open) {
            tags.push(
                <div role="presentation" aria-label="tag" key={issueStatus} className={classes.yellowTag}>
                    Unresolved
                </div>
            );
        }

        if (issueStatus === IssueInfoStatus.Dismissed) {
            tags.push(
                <Box key={issueStatus} display="flex" alignItems="center">
                    <div role="presentation" aria-label="tag" className={classes.grayTag}>
                        Dismissed
                    </div>
                    <Typography variant="caption" className={classesCommon.marginLeft}>
                        at {moment(selectedIssue?.updated).format(" HH:mm MM/DD/YYYY")} by{" "}
                        {selectedIssue.getResolvedBy()}
                    </Typography>
                </Box>
            );
        }

        if (!tags.length) {
            return null;
        }

        return <div className={classes.tagsContainer}>{tags}</div>;
    };

    if (!issueStatus) {
        return null;
    }

    return (
        <div
            role="presentation"
            aria-label="trace view alert status container"
            className={classes.traceViewAlertStatusContainer}
        >
            <div className={classes.titleSection}>
                <div className={classes.titleContainer}>
                    <div className={classes.title}>
                        Alert status{" "}
                        <SternumTooltip
                            title={
                                <span className={classes.tooltipContent}>
                                    Please resolve the unresolved issues to inform future issue suggestions. If this was
                                    not an issue, please dismiss it rather than keeping it unresolved.
                                </span>
                            }
                        >
                            <InfoCircleIcon className={classes.statusIcon} />
                        </SternumTooltip>
                    </div>
                    <div className={classes.issueInfoStatusContainer}>
                        {renderIssueInfoStatusTags()}
                        {areAlertReasonsVisible && (
                            <ResolvingReasons isDisplayView>
                                {reasons.default.map((reasonData, index) => (
                                    <ResolvingReasonsItem key={"default" + index} isSelected={true}>
                                        {reasonData.title}
                                    </ResolvingReasonsItem>
                                ))}
                                {reasons.custom.map((customReason, index) => (
                                    <ResolvingReasonsCustomItem key={"custom" + index} isSelected={true}>
                                        {customReason.title}
                                    </ResolvingReasonsCustomItem>
                                ))}
                            </ResolvingReasons>
                        )}
                    </div>
                </div>
                {!isResolving && (
                    <div className={classes.actionsContainer}>
                        {issueStatus === IssueInfoStatus.Open && (
                            <Button
                                className={classes.grayButton}
                                variant="primaryPink"
                                size="size24"
                                onClick={() => setIsResolving(true)}
                                disabled={isIssueInfoStatusResolving || isIssueInfoStatusDismissing}
                                iconAfter={
                                    isIssueInfoStatusDismissing && <CircularProgress size={20} color="inherit" />
                                }
                            >
                                Dismiss
                            </Button>
                        )}
                        {issueStatus === IssueInfoStatus.Open && (
                            <Button
                                className={classes.blueButton}
                                variant="primaryPink"
                                size="size24"
                                onClick={() => setIsResolving(true)}
                                disabled={isIssueInfoStatusResolving || isIssueInfoStatusDismissing}
                                iconAfter={isIssueInfoStatusResolving && <CircularProgress size={20} color="inherit" />}
                            >
                                Resolve
                            </Button>
                        )}
                        {[IssueInfoStatus.Resolved, IssueInfoStatus.Dismissed].includes(issueStatus) && (
                            <Button
                                className={classes.grayButton}
                                variant="primaryPink"
                                size="size24"
                                onClick={() => setIsResolving(true)}
                                disabled={isIssueInfoStatusResolving || isIssueInfoStatusDismissing}
                                iconAfter={
                                    (isIssueInfoStatusResolving || isIssueInfoStatusDismissing) && (
                                        <CircularProgress size={20} color="inherit" />
                                    )
                                }
                            >
                                Edit
                            </Button>
                        )}
                    </div>
                )}
            </div>
            {isResolving && (
                <div className={classes.resolvingSection}>
                    <div className={classes.resolvingTitleContainer}>
                        <div className={classes.resolvingTitle}>
                            Resolving the {selectedIssue?.isLostCommunicationIssue() ? "anomaly" : "alert"}
                        </div>
                        <CancelIcon className={classes.cancelResolvingIcon} onClick={() => closeResolvingView()} />
                    </div>

                    {canAddCustomReasons && defaultIssueResolveReasons.length && (
                        <div className={classes.resolvingSubtitle}>{resolvingSubtitle}</div>
                    )}
                    <div className={classes.resolvingReasonsWithActionButtons}>
                        <ResolvingReasons>
                            {defaultIssueResolveReasons.map((reasonData, index) => {
                                const isSelected = isSelectedByDefaultIssueReasonType[reasonData.type];

                                return (
                                    <ResolvingReasonsItem
                                        key={"default" + reasonData.type + index}
                                        isSelected={isSelectedByDefaultIssueReasonType[reasonData.type]}
                                        onClick={() => setIsSelectedDefaultIssue(reasonData.type, !isSelected)}
                                    >
                                        {reasonData.title}
                                    </ResolvingReasonsItem>
                                );
                            })}
                            {customReasons.map((customReason, index) => (
                                <ResolvingReasonsCustomItem
                                    key={"custom" + index}
                                    isSelected={customReason.isSelected}
                                    onClickDelete={() => deleteCustomReason(index)}
                                    onClick={() => setCustomReasonSelection(index, !customReason.isSelected)}
                                >
                                    {customReason.title}
                                </ResolvingReasonsCustomItem>
                            ))}
                            {canAddCustomReasons && (
                                <ResolvingReasonsAddItem
                                    initialValue=""
                                    placeholder="Manual reason"
                                    buttonPlaceholder="Other"
                                    onAddNewReason={(newReason) => addCustomReason(newReason)}
                                />
                            )}
                        </ResolvingReasons>
                        <div className={classes.actionsContainer}>
                            <Button
                                className={classes.grayButton}
                                variant="primaryPink"
                                size="size24"
                                disabled={
                                    isIssueInfoStatusResolving ||
                                    isIssueInfoStatusDismissing ||
                                    !isAnyEditedIssueSelected
                                }
                                onClick={handleDismissAlert}
                                iconAfter={
                                    isIssueInfoStatusDismissing && <CircularProgress size={20} color="inherit" />
                                }
                            >
                                Dismiss
                            </Button>
                            <Button
                                className={classes.blueButton}
                                variant="primaryPink"
                                size="size24"
                                disabled={
                                    isIssueInfoStatusResolving ||
                                    isIssueInfoStatusDismissing ||
                                    !isAnyEditedIssueSelected
                                }
                                iconAfter={isIssueInfoStatusResolving && <CircularProgress size={20} color="inherit" />}
                                onClick={handleResolveAlert}
                            >
                                Resolve
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

function getIssueInfoStatus(issue?: IssueInfo): IssueInfoStatus | undefined {
    return issue ? (issue.status as IssueInfoStatus) : undefined;
}

function getReasons(issue?: IssueInfo): { default: DefaultIssueReason[]; custom: CustomReason[] } {
    const defaultReasons: DefaultIssueReason[] = [];
    const customReasons: CustomReason[] = [];

    issue?.reasons?.forEach?.((reason) => {
        if (IssueInfoReasonType.Other === reason.type) {
            customReasons.push({ title: reason.text || "", isSelected: true });
        } else {
            defaultReasons.push({
                type: reason.type,
                title: reason.text || reasonsDataByIssueReasonType[reason.type]?.title || "",
            });
        }
    });

    return {
        default: defaultReasons,
        custom: customReasons,
    };
}
