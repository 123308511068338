enum LoginStateEnum {
    LOGIN,
    MFA,
    RESET_PASSWORD,
    INVITE_USER,
    CODE_VALIDATION,
    SET_PASSWORD,
    ENTER_EMAIL,
}

export default LoginStateEnum;
