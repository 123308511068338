import React from "react";
import classNames from "classnames";

import { useDeviceTileAddItemStyle } from "./DeviceTileAddItem.style";

export interface DeviceTileItemProps extends React.ComponentProps<"div"> {}

export function DeviceTileAddItem({ className, children, ...props }: DeviceTileItemProps) {
    const classes = useDeviceTileAddItemStyle();

    return (
        <div className={classNames(classes.deviceTileAddItemContainer, className)} {...props}>
            {children}
        </div>
    );
}
