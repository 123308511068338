import { Action } from "redux";
import ActionType from "../ActionType";

/**
 * Defines the data for a library not being viewed anymore action.
 */
export interface LibraryNotViewedAction extends Action<ActionType> {
    type: ActionType.LibraryNotViewed;
}

/**
 * Action creator for library not being viewed anymore.
 */
export function libraryNotViewedAction(): LibraryNotViewedAction {
    return {
        type: ActionType.LibraryNotViewed,
    };
}
