import { makeStyles } from "@material-ui/styles";

const connectorColor = "#E7EEF6";

export const useMultipleDevicesGraphStyle = makeStyles({
    container: {
        flex: 1,
        display: "flex",
    },

    graph: {
        margin: "auto",
    },
    graphHeader: {},
    graphHeaderIcon: {},
    graphConnector: {
        height: 2,
        width: 33,
        backgroundColor: connectorColor,
    },
    graphChildren: {},
    graphChildItem: {
        display: "flex",
        alignItems: "center",
        position: "relative",
    },
    graphChildHConnector: {
        height: 2,
        width: 33,
        backgroundColor: connectorColor,
    },
    graphChildVTopConnector: {
        position: "absolute",
        left: 0,
        top: 0,
        bottom: "50%",
        width: 2,
        backgroundColor: connectorColor,
    },
    graphChildVBottomConnector: {
        position: "absolute",
        left: 0,
        top: "50%",
        bottom: 0,
        width: 2,
        backgroundColor: connectorColor,
    },

    /* Graph Items Graph */
    graphItemsContainer: {
        display: "flex",
        alignItems: "center",
    },

    /* Root */
    rootContainer: {
        display: "flex",
        alignItems: "center",
        gap: 5,
        padding: "2px 7px 2px 2px",
        borderRadius: 100,
        backgroundColor: "#E7EEF6",
    },
    rootIconContainer: {
        display: "flex",
        width: 23,
        height: 23,
        borderRadius: 23,
        backgroundColor: "#C1DAFC",
    },
    rootIcon: {
        margin: "auto",
    },
    rootText: {
        color: "black",
        fontSize: 14,
        fontWeight: 400,
    },
});
