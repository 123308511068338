import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => {
    return {
        container: {
            width: "370px",
        },

        title: {
            marginBottom: "15px",
            fontSize: "20px",
            fontWeight: 500,
        },

        footer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 16,
            gap: 24,
        },

        detailsTextArea: {
            height: 133,
            background: "none",
        },

        statusContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },

        statusIcon: {
            marginTop: 45,
        },

        statusTitle: {
            marginTop: 28,
            fontSize: 20,
            fontWeight: 600,
            color: "#000",
            textAlign: "center",
        },

        statusSubTitle: {
            margin: "8px 0 44px 0",
            fontSize: 14,
            color: "#000",
            textAlign: "center",
        },

        linkButton: {
            fontSize: 16,
            color: "#000",
            fontWeight: 500,
        },
    };
});
