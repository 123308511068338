import ArgumentDefinitionPartial from "../ArgumentDefinitionPartial";
import TraceDefinitionPartial from "../TraceDefinitionPartial";
import { default as TriggerDefinition } from "./TriggerDefinition";

/**
 * Represents over time trigger in sternum.
 */

class SpecificBehaviorDefinition extends TriggerDefinition {
    /**
     * Constructor.
     */
    constructor(
        public traceDefinition: any,
        public argumentDefinition: any,
        public condition: string,
        public value: string,
        public traceDefinitionPartial?: TraceDefinitionPartial,
        public argumentDefinitionPartial?: ArgumentDefinitionPartial
    ) {
        super();
    }

    /**
     * Converts json to trigger object.
     */
    public static fromJsonObject(jsonObject: Object) {
        // Constructing the map of arguments.
        return new SpecificBehaviorDefinition(
            jsonObject["trace_definition_id"],
            jsonObject["argument_definition_id"],
            jsonObject["filter_condition"],
            jsonObject["value"],
            null,
            null
        );
    }

    /**
     * Converts json to object.
     * There are two scenarios. First: trace and argument definitions doesn't exists need to save mapping for server
     * Second: trace and argument definitions are exist, save their entity id
     */
    public getJsonObject() {
        let triggerJsonObject = {
            trace_definition_id: this.traceDefinitionPartial
                ? this.traceDefinitionPartial
                : this.traceDefinition.entityId,
            argument_definition_id: this.argumentDefinitionPartial
                ? this.argumentDefinitionPartial
                : this.argumentDefinition.entityId,
            filter_condition: this.condition,
            value: this.value,
        };

        return triggerJsonObject;
    }

    /**
     * Check if given trigger equals to existing one
     */
    public isEqual(other: SpecificBehaviorDefinition): boolean {
        return (
            this.traceDefinition === other.traceDefinition &&
            this.argumentDefinition === other.argumentDefinition &&
            this.condition === other.condition &&
            this.value === other.value
        );
    }

    /**
     * Convert class fields into json string.
     */
    public getJsonString() {
        return JSON.stringify(this.getJsonObject());
    }
}

export default SpecificBehaviorDefinition;
