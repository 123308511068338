import { isEqual } from "lodash";
import moment from "moment";

import { IssueInfoStatus } from "./IssueInfoType";
import ListFilter from "./ListFilter";

export interface IssuesFilterDateRange {
    from: number;
    to: number;
}

/**
 * Represents a filter of issues.
 */
class IssuesFilter implements ListFilter {
    /**
     * Constructor.
     */
    constructor(
        public startTime: number,
        public endTime: number,
        public excludeResolved: boolean,
        public showOnlyHighInterest: boolean,
        public statuses?: IssueInfoStatus[],
        public categoriesDateRanges?: Record<string, IssuesFilterDateRange[]>,
        public isOnlyAnomalyInsights?: boolean,
        public hideAnomalies?: boolean,
        public severeAnomaliesOnly?: boolean
    ) {}

    /**
     * Returns whether filter is defined, or all values are nulls.
     */
    public isActive(): boolean {
        return !!(this.startTime || this.endTime || this.excludeResolved);
    }

    /**
     * Returns whether current filter is different than given other filter.
     * @param other The other list filter to check with.
     */
    public isDifferentFrom(other: ListFilter): boolean {
        if (!(other instanceof IssuesFilter)) {
            return true;
        }

        let otherIssuesFilter = other as IssuesFilter;

        return (
            otherIssuesFilter.startTime !== this.startTime ||
            otherIssuesFilter.endTime !== this.endTime ||
            otherIssuesFilter.excludeResolved !== this.excludeResolved ||
            otherIssuesFilter.showOnlyHighInterest !== this.showOnlyHighInterest ||
            otherIssuesFilter.isOnlyAnomalyInsights !== this.isOnlyAnomalyInsights ||
            otherIssuesFilter.hideAnomalies !== this.hideAnomalies ||
            otherIssuesFilter.severeAnomaliesOnly !== this.severeAnomaliesOnly ||
            !isEqual(otherIssuesFilter.statuses, this.statuses) ||
            !isEqual(otherIssuesFilter.categoriesDateRanges, this.categoriesDateRanges)
        );
    }

    public getJsonObject() {
        return {
            start_time: this.startTime,
            end_time: this.endTime,
            show_only_high_interest: this.showOnlyHighInterest,
            statuses: this.statuses?.length > 0 ? this.statuses : undefined,
            anomalies_only: this.isOnlyAnomalyInsights ? 1 : undefined,
            hide_anomalies: this.hideAnomalies || false,
            severe_anomalies_only: this.severeAnomaliesOnly || false,
            // @ts-ignore
            categories_date_ranges: Object.fromEntries(
                Object.entries(this.categoriesDateRanges || {}).map(([category, ranges]) => [
                    category,
                    ranges.map((range) => {
                        if (moment(range.from).isSame(range.to, "day")) {
                            return {
                                start_time: moment(range.from).toDate().getTime(),
                                end_time: moment(range.to).add(1, "day").toDate().getTime(),
                            };
                        }

                        return {
                            start_time: moment(range.from).toDate().getTime(),
                            end_time: moment(range.to).toDate().getTime(),
                        };
                    }),
                ])
            ),
        };
    }
}

export default IssuesFilter;
