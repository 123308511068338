import { AriaRole } from "react";
import { Table } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import _ from "lodash";
import * as React from "react";
import HashSet from "../../../lib/infra/HashSet";
import SternumConfiguration from "../../../lib/infra/SternumConfiguration";
import WebUtils from "../../../lib/infra/WebUtils";
import ListFilter from "../../../lib/state/ListFilter";
import SternumQuery from "../../../lib/state/SternumQuery";
import TableColumnHeaderInfo from "../../../lib/state/TableColumnHeaderInfo";
import TableRowData from "../../../lib/state/TableRowData";
import TableToolbarDisplayState from "../../../lib/state/TableToolbarDisplayState";
import SternumTableBody from "./SternumTableBody/SternumTableBody";
import SternumTableHeader from "./SternumTableHeader/SternumTableHeader";
import sternumTableStyle from "./SternumTableStyle";
import SternumTableToolbar from "./SternumTableToolbar/SternumTableToolbar";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    viewedColumnsSet: HashSet;

    activityBarIsDisplayed: boolean;

    scrollPosition: number;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof sternumTableStyle> {
    classNameInner?: { tableWrapper?: string };
    size?: "small";
    hideToolbar?: boolean;
    hidePagination?: boolean;

    pageSize: number;
    totalItemCount: number;
    isSearchInputDisabled?: boolean;

    emptyComponent?: any;
    isFilterActive?: boolean;
    nonClickableRows?: boolean;
    stickyHeader?: boolean;

    viewedColumnsSet?: HashSet;

    // Toolbar action options
    toolbarState?: TableToolbarDisplayState;

    loadingItems: boolean;
    errorLoadingItems: boolean;
    amountOfLoadingPlaceholders: number;

    columnHeaders: TableColumnHeaderInfo[];
    listFilter?: ListFilter;
    sternumQuery?: SternumQuery;
    filterComponentProvider?: (onFilterChanged: (listFilter: ListFilter) => void) => any;
    activityComponentProvider?: () => any;
    orderByField?: string;
    order?: "asc" | "desc";

    rows: TableRowData[];
    hideUpperBorder?: boolean;
    includeActivityInToolbar?: boolean;

    onFilterChanged?: (listFilter: ListFilter) => void;
    onSearchTextChanged?: (searchText: string) => void;
    onPageChanged?: (pageNumber: number) => void;
    onOrderChanged?: (orderByField: string, order: "asc" | "desc") => void;
    getRowValues?: (row: TableRowData) => { [key: string]: any };
    getRowClasses?: (row: TableRowData) => any;
    onRowClicked?: (row: TableRowData, event) => void;
    onRowMouseDown?: (row: TableRowData, event) => void;
    onRowMouseUp?: (row: TableRowData, event) => void;
    onCellRightClick?: (cellName: string, row: TableRowData, event) => void;
    onRowExpand?: (row: TableRowData, isExpanded: boolean) => void;
    onRefreshClicked?: () => void;
    onNewVisualizationClicked?: () => void;
    onExistingVisualisationClicked?: (visualisationId: string) => void;
    onResolveAllIssuesClicked?: () => void;
    onShowResolvedIssuesClicked?: () => void;
    onExportToCsvClicked?: () => void;
    onExportToXlsxClicked?: () => void;
    newEvents?: number;

    columnWidthsArray?: number[];

    // To activate infinity scroll the following arguments are needed
    maxTableHeightClass?;
    isInfinityScrollOn?;
    onScrollChanged?: (pageNumber: number) => void;
    showInfinityLoader?: boolean;
    pageNumber?: number;
    updateOnTopScroll?: boolean;

    // Export operation clicked, activate loader
    displayLoadingIcon?: boolean;

    // If rows should be expandable (arrows at the beginning of the row)
    expandableRows?: boolean;
    // Set of currently expanded rows
    expandedRowsSet?: HashSet;
    wrapColumns?: boolean;

    isTableExpanded?: boolean;
    onTableExpandToggle?: () => void;

    onResetViewInContext?: () => void;
    narrow?: boolean;

    role?: AriaRole;
    ariaLabel?: string;
}

/**
 * Displays a sternum-styled table header.
 */
class SternumTable extends React.Component<AppProps, AppState> {
    static defaultProps = {
        stickyHeader: true,
    };

    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            viewedColumnsSet: this.props.viewedColumnsSet
                ? this.props.viewedColumnsSet
                : HashSet.fromValues(
                      this.props.columnHeaders
                          .filter((columnHeaderInfo) => !columnHeaderInfo.isHidden)
                          .map((columnHeaderInfo) => columnHeaderInfo.id)
                  ),
            activityBarIsDisplayed: false,
            scrollPosition: 0,
        };
    }

    componentDidUpdate(prevProps: AppProps) {
        if (!_.isEqual(this.props.viewedColumnsSet, prevProps.viewedColumnsSet)) {
            this.setState({ viewedColumnsSet: this.props.viewedColumnsSet });
        }
    }

    /**
     * Handle scroll events on Sternum table component
     */
    private handleScroll = (event) => {
        if (!this.props.isInfinityScrollOn) {
            return;
        }

        WebUtils.loadDataOnScroll(
            event,
            "loadScrollData",
            (scrollTop) => {
                if (!this.props.showInfinityLoader && !this.isLastPage()) {
                    this.setState({ scrollPosition: scrollTop }, () => {
                        this.props.onScrollChanged(this.props.pageNumber + 1);
                    });
                }
            },
            (scrollTop) => {
                if (scrollTop <= this.state.scrollPosition) {
                    this.setState({ scrollPosition: scrollTop }, () => {
                        this.props.onScrollChanged(1);
                    });
                }
            }
        );
    };

    /**
     * Check if it's the last pagination page
     */
    private isLastPage = () => {
        return this.props.totalItemCount < this.props.pageNumber * SternumConfiguration.getPageSize();
    };

    /**
     * Renders the component.
     */
    render() {
        const { classes, classNameInner } = this.props;

        return (
            <div
                className={classNames(classes.scroll, this.props.maxTableHeightClass)}
                role={this.props.role || "presentation"}
                aria-label={this.props.ariaLabel || "sternum table"}
            >
                {/* Toolbar */}
                {!this.props.hideToolbar && (
                    <SternumTableToolbar
                        toolbarState={this.props.toolbarState}
                        pageNumber={this.props.pageNumber}
                        pageSize={this.props.pageSize}
                        onNewVisualizationClicked={this.props.onNewVisualizationClicked}
                        onExistingVisualisationClicked={this.props.onExistingVisualisationClicked}
                        onResolveAllIssuesClicked={this.props.onResolveAllIssuesClicked}
                        onExportToCsvClicked={this.props.onExportToCsvClicked}
                        onExportToXlsxClicked={this.props.onExportToXlsxClicked}
                        dynamicPageSize={this.props.rows.length}
                        totalItemCount={this.props.totalItemCount}
                        onPageChanged={this.props.onPageChanged}
                        disabled={this.props.isSearchInputDisabled || this.props.loadingItems}
                        viewedColumnsSet={this.state.viewedColumnsSet}
                        columnHeaders={this.props.columnHeaders}
                        onRefreshClicked={this.props.onRefreshClicked}
                        newEvents={this.props.newEvents}
                        includeActivityInToolbar={this.props.includeActivityInToolbar}
                        isFilterActive={this.props.isFilterActive}
                        listFilter={this.props.listFilter}
                        filterComponentProvider={this.props.filterComponentProvider}
                        onFilterChanged={this.props.onFilterChanged}
                        onViewedColumnsChanged={(viewedColumnsSet) => this.handleViewedColumnsChanged(viewedColumnsSet)}
                        onSearchTextChanged={this.props.onSearchTextChanged}
                        onActivityBarToggled={(isDisplayed) => this.onActivityBarToggled(isDisplayed)}
                        displayLoadingIcon={this.props.displayLoadingIcon}
                        isExpanded={this.props.isTableExpanded}
                        onExpandToggle={this.props.onTableExpandToggle}
                        hidePagination={this.props.hidePagination}
                        onResetShowInContext={this.props.onResetViewInContext}
                    />
                )}

                {/* Activity bar */}
                {this.state.activityBarIsDisplayed &&
                    this.props.activityComponentProvider &&
                    this.props.activityComponentProvider()}

                {/* Table content */}
                <div
                    className={classNames(classes.tableWrapper, classNameInner?.tableWrapper)}
                    onScroll={this.handleScroll}
                >
                    <Table
                        size={this.props.size}
                        className={classNames(!this.shouldUseCustomColumnWidth() && classes.tableLayout, {
                            [classes.narrow]: this.props.narrow,
                        })}
                        stickyHeader={this.props.stickyHeader}
                    >
                        {/* This is a trick to get the table columns be the right width. */}
                        {this.shouldUseCustomColumnWidth() && (
                            <colgroup>
                                {this.props.columnWidthsArray.map((columnWidth, index) => (
                                    <col key={index} width={columnWidth} />
                                ))}
                            </colgroup>
                        )}

                        {/* Header */}
                        <SternumTableHeader
                            loadingItems={this.props.loadingItems}
                            columnHeaders={this.props.columnHeaders}
                            viewedColumnsSet={this.state.viewedColumnsSet}
                            orderByField={this.props.orderByField}
                            order={this.props.order}
                            onOrderChanged={this.props.onOrderChanged}
                            hideUpperBorder={this.props.hideUpperBorder}
                            expandableRows={this.props.expandableRows}
                            narrow={this.props.narrow}
                        />

                        {/* Body */}
                        {(!!(this.props.rows && this.props.rows.length) || this.props.loadingItems) && (
                            <SternumTableBody
                                loading={this.props.loadingItems}
                                rows={this.props.rows}
                                nonClickableRows={this.props.nonClickableRows}
                                expandableRows={this.props.expandableRows}
                                expandedRowsSet={this.props.expandedRowsSet}
                                amountOfLoadingPlaceholders={this.props.amountOfLoadingPlaceholders}
                                viewedColumnsSet={this.state.viewedColumnsSet}
                                columnHeaders={this.props.columnHeaders}
                                onRowClicked={this.props.onRowClicked}
                                onRowMouseDown={this.props.onRowMouseDown}
                                onRowMouseUp={this.props.onRowMouseUp}
                                onCellRightClick={this.props.onCellRightClick}
                                onRowExpand={this.props.onRowExpand}
                                getRowValues={this.props.getRowValues}
                                getRowClasses={this.props.getRowClasses}
                                addInfiniteLoader={this.props.showInfinityLoader}
                                wrapColumns={this.props.wrapColumns}
                                narrow={this.props.narrow}
                            />
                        )}
                    </Table>
                </div>

                {/* Empty state */}
                {!!(
                    (!this.props.rows || !this.props.rows.length) &&
                    !this.props.loadingItems &&
                    !this.props.errorLoadingItems
                ) && this.props.emptyComponent}

                {/* Error state */}
                {this.props.errorLoadingItems && (
                    <div className={classNames(classes.flexCenter, classes.padding)}>
                        <Typography variant="body2">
                            There was an error trying to load items... Please try again.
                        </Typography>
                    </div>
                )}
            </div>
        );
    }

    private shouldUseCustomColumnWidth() {
        return this.props.columnWidthsArray && this.props.columnWidthsArray.length;
    }

    /**
     * Handles change in viewed columns.
     */
    private handleViewedColumnsChanged(viewedColumnsSet) {
        this.setState({
            viewedColumnsSet: viewedColumnsSet,
        });
    }

    /**
     * Occurs on activity bar toggled.
     */
    private onActivityBarToggled(isDisplayed: boolean) {
        this.setState({
            activityBarIsDisplayed: isDisplayed,
        });
    }
}

export default withStyles(sternumTableStyle)(SternumTable);
