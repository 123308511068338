import { useTheme } from "@material-ui/core";
import classNames from "classnames";
import * as React from "react";
import Select, { ActionMeta, GroupedOptionsType, InputActionMeta, MenuPlacement, OptionTypeBase } from "react-select";
import customSelectStyle from "../../CustomSelectStyle";
import useSelectComponentStyles from "./SelectComponentStyle";

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps {
    name: string;
    placeHolder: string;
    onFieldChange: (value: OptionTypeBase, actionObject?: ActionMeta<any>) => void;
    onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
    selectedValue;
    selectOptions;
    menuMaxHeight?: number;
    menuLocation?: MenuPlacement; // Optional top, bottom or auto
    isDisabled?: boolean;
    className?: string;
    clearable?: boolean;
    searchable?: boolean;
    isMenuFixed?: boolean;
    type?: "filled" | "outlined";
    formatGroupLabel?: (data: any) => JSX.Element;
    formatOptionLabel?: (data: any) => JSX.Element;
    onAfterInitialRender?: () => void;
    ariaLabel?: string;
}

const SelectComponent = React.forwardRef<Select, AppProps>(
    (
        {
            name,
            placeHolder,
            onFieldChange,
            onInputChange,
            selectedValue,
            selectOptions,
            menuMaxHeight,
            menuLocation = "bottom",
            isDisabled = false,
            className,
            clearable = false,
            searchable = false,
            isMenuFixed = false,
            type = "filled",
            formatGroupLabel,
            formatOptionLabel,
            onAfterInitialRender,
            ariaLabel,
        }: AppProps,
        ref
    ) => {
        const classes = useSelectComponentStyles();

        const theme = useTheme();

        const updateSelectedField = (selectedItem: OptionTypeBase, action?: ActionMeta<any>) => {
            onFieldChange(selectedItem, action);
        };

        React.useEffect(() => {
            onAfterInitialRender && onAfterInitialRender();
        }, []);

        return (
            <Select
                aria-label={ariaLabel}
                ref={ref}
                onInputChange={onInputChange}
                type={type}
                className={classNames(classes.selectComponent, className)}
                options={selectOptions}
                name={name}
                value={selectedValue}
                styles={{
                    ...customSelectStyle(theme),
                    option: (provided, state) => {
                        return {
                            ...provided,
                            backgroundColor: state.isFocused ? "#FFF1F5" : "#fff",
                            "&:active": {
                                backgroundColor: "#FFF1F5",
                            },
                            color: "black",
                        };
                    },
                }}
                menuPosition={isMenuFixed ? "fixed" : "absolute"}
                isClearable={clearable}
                isSearchable={searchable}
                placeholder={placeHolder}
                onChange={(newValue: OptionTypeBase, action: ActionMeta<any>) => updateSelectedField(newValue, action)}
                menuPlacement={menuLocation}
                maxMenuHeight={menuMaxHeight ? menuMaxHeight : 250}
                isDisabled={isDisabled}
                formatGroupLabel={formatGroupLabel}
                formatOptionLabel={formatOptionLabel}
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary: "primary25",
                    },
                })}
            />
        );
    }
);
export default SelectComponent;
