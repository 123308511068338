import React from "react";

import { DownloadChipItem } from "./DownloadChipItem.component";
import { useDownloadChipsStyle } from "./DownloadChips.style";

export interface DownloadChipsProps extends React.ComponentProps<"div"> {}

export function DownloadChips({ className, children, ...props }: DownloadChipsProps) {
    const classes = useDownloadChipsStyle();

    return (
        <div className={classes.root} {...props}>
            {children}
        </div>
    );
}

DownloadChips.Item = DownloadChipItem;
