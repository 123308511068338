import React from "react";
import classNames from "classnames";

import { useSideBarFrameStyle } from "./SideBarItemFrame.style";

export interface SideBarFrameButtonProps extends Omit<React.ComponentProps<"button">, "disabled"> {
    className?: string;
    isSelected?: boolean;
    icon?: React.ReactNode;
}

export function SideBarFrameItemButton({ className, isSelected, icon, children, ...props }: SideBarFrameButtonProps) {
    const classes = useSideBarFrameStyle();

    return (
        <button
            className={classNames(
                classes.sideBarFrameItem,
                classes.sideBarFrameItemButton,
                isSelected && classes.sideBarFrameItemButtonSelected,
                className
            )}
            {...props}
        >
            {icon}
            <span>{children}</span>
        </button>
    );
}
