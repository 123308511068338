import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../../../CommonStyle";

const sternumTableToolbarStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: {
            position: "sticky",
            top: 0,
            background: "#fff",
            display: "flex",
            alignItems: "center",
            height: theme.spacing(7),
        },
        resolveAllContainer: {
            color: theme.palette.grey["500"],

            "&:hover": {
                color: "#15AC5A",
            },
        },
        toolbarItemContainer: {
            padding: "2px",
            marginRight: "12px",
        },
        toolbarItemIcon: {
            height: theme.spacing(3),
            width: theme.spacing(3),
        },
        toolbarItemIconContainer: {
            padding: "2px",
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1),
        },
        toolbarItemTitle: {
            fontSize: "14px",
        },
        activeIcon: {
            color: theme.palette.primary.main,
        },
        inactiveIcon: {
            color: theme.palette.grey["500"],
        },
        resultsFromAndToTitle: {
            color: theme.palette.grey["500"],
            marginRight: theme.spacing(1),
        },
        listToolbarDivider: {
            height: "25px",
            width: "1px",
            background: "#E9C9D4",
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1),
            "&.with-extra-margin": {
                marginRight: "12px",
            },
        },
        searchIcon: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        searchInput: {
            fontSize: "14px",
            width: theme.spacing(40),
        },
        popoverPaper: {
            paddingTop: theme.spacing(4),
            paddingRight: theme.spacing(4),
            paddingLeft: theme.spacing(4),
            minWidth: "350px",
        },
        activityIconSize: {
            fontSize: theme.spacing(3),
        },
        badgeBuffer: {
            marginRight: theme.spacing(1),
        },
        badge: {
            height: "12px",
            minWidth: "12px",
            fontSize: "0.6rem",
        },

        expandIcon: {
            fontSize: 15,
            color: "#8B949E",
        },

        iconSize: {
            "font-size": theme.spacing(3),
        },

        resetShowInContext: {
            cursor: "pointer",
            fontSize: "14px",
            lineHeight: "100%",
            color: "#1B6FDE",
            marginTop: "-3px",
            "& svg": {
                height: "14px",
                transform: "translateY(2px)",
            },
        },
    });

export default sternumTableToolbarStyle;
