import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../../CommonStyle";

const countOverTimeTriggerStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: {
            paddingTop: theme.spacing(5),
            paddingRight: theme.spacing(5),
            paddingBottom: theme.spacing(5),
            paddingLeft: theme.spacing(5),
        },
        numberTextField: {
            width: "85px",
        },
        unitsTextField: {
            width: "95px",
        },

        filterTitle: {
            textDecoration: "underline",
        },

        advancedLinkText: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1),

            cursor: "pointer",
            fontSize: "0.8125rem",
            fontWeight: 400,
            marginRight: theme.spacing(1),
            "&:hover": {
                textDecoration: "underline",
            },
        },

        explanationContainer: {
            width: theme.spacing(57),
        },
    });

export default countOverTimeTriggerStyle;
