import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../../components/CommonStyle";

const autocompleteStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        dropdown: {
            position: "absolute",
            zIndex: 100,
            backgroundColor: "white",
            overflowY: "auto",
            border: "2px solid #d4d4d4",
            borderBottom: "2px solid #d4d4d4",
        },

        dropdownCell: {
            paddingLeft: theme.spacing(1),
            paddingTop: theme.spacing(2),

            "&:hover": {
                background: "#F0F0F0",
                cursor: "pointer",
            },
        },

        selectedCell: {
            background: "#F0F0F0",
        },

        selectedField: {
            position: "relative",
        },
    });

export default autocompleteStyle;
