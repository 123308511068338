import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

const changePasswordModalStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: {
            paddingTop: theme.spacing(5),
            paddingRight: theme.spacing(5),
            paddingBottom: theme.spacing(5),
            paddingLeft: theme.spacing(5),
        },
        valueInputBox: {
            fontSize: "0.875rem",
        },
        nameColumn: {
            width: "30%",
        },
    });

export default changePasswordModalStyle;
