import {
    Box,
    Checkbox,
    DialogActions,
    DialogTitle,
    FormControlLabel,
    IconButton,
    Tooltip,
    Typography,
    withStyles,
    WithStyles,
} from "@material-ui/core";
import classNames from "classnames";
import * as React from "react";
import { CrossIcon, DragHandleIcon, InfoCircleIcon } from "../SUI/SternumIcon/SternumIcon";
import interactiveCoverageDialogStyle from "./InteractiveCoverageDialogStyle";
import monitoringData from "./monitoring.json";
import realtimeData from "./realtime.json";
import InteractiveCoveragePlot from "./InteractiveCoveragePlot";
import { getBoundingClientObj } from "react-select/src/utils";

export interface InteractiveCoverageDialogProps extends WithStyles<typeof interactiveCoverageDialogStyle> {
    open: boolean;
    onClose: () => void;
    initial?: {
        plot?: "prevention" | "detection";
        category?: string;
    };
}

export interface InteractiveCoverageDialogState {
    dragReference: {
        x: number;
        y: number;
    } | null;
    dragPosition: {
        x: number;
        y: number;
    };
    showMonitoring: boolean;
    showRealtime: boolean;
}

class InteractiveCoverageDialog extends React.Component<
    InteractiveCoverageDialogProps,
    InteractiveCoverageDialogState
> {
    private draggableElementRef = React.createRef<HTMLDivElement>();

    constructor(props) {
        super(props);
        this.state = {
            dragReference: null,
            dragPosition: { x: 0, y: 0 },
            showMonitoring: this.props?.initial?.plot === "prevention" || this.props?.initial?.plot === undefined,
            showRealtime: this.props?.initial?.plot === "detection",
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.initial !== this.props.initial) {
            this.setState({
                showMonitoring: this.props?.initial?.plot === "prevention" || this.props?.initial?.plot === undefined,
                showRealtime: this.props?.initial?.plot === "detection",
            });
        }

        if (!this.state.dragReference) {
            this.updateDialogPosition();
        }
    }

    startMouseMoveTrack = (event: MouseEvent) => {
        const { pageX, pageY } = event;
        this.setState((state) => ({
            ...state,
            dragReference: {
                x: pageX - this.state.dragPosition.x,
                y: pageY - this.state.dragPosition.y,
            },
        }));
        window.document.addEventListener("mousemove", this.onMouseMove);
    };

    stopMouseMoveTrack = () => {
        window.document.removeEventListener("mousemove", this.onMouseMove);
        this.setState((state) => ({
            ...state,
            dragReference: null,
        }));
    };

    updateDialogPosition = () => {
        const boundingRect = this.draggableElementRef.current?.getBoundingClientRect?.();
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        if (!boundingRect) {
            return;
        }

        const offsetRightX = boundingRect.right - windowWidth;
        const offsetLeftX = -boundingRect.left;
        const offsetTopY = -boundingRect.top;
        const offsetBottomY = boundingRect.bottom - windowHeight;

        let finalXOffset = 0;
        let finalYOffset = 0;

        if (offsetRightX > 0) {
            finalXOffset = -offsetRightX;
        }

        if (offsetLeftX > 0) {
            finalXOffset = offsetLeftX;
        }

        if (offsetTopY > 0) {
            finalYOffset = offsetTopY;
        }

        if (offsetBottomY > 0) {
            finalYOffset = -offsetBottomY;
        }

        if (finalXOffset || finalYOffset) {
            this.setState((state) => ({
                dragPosition: {
                    ...state.dragPosition,
                    x: state.dragPosition.x + finalXOffset,
                    y: state.dragPosition.y + finalYOffset,
                },
            }));
        }
    };

    onMouseMove = (event: MouseEvent) => {
        event.preventDefault();
        const { pageX, pageY } = event;
        this.setState((state) => ({
            ...state,
            dragPosition: {
                x: pageX - state.dragReference.x,
                y: pageY - state.dragReference.y,
            },
        }));
    };

    render() {
        const { classes, open } = this.props;

        if (!open) return null;

        return (
            <div
                className={classes.dialog}
                ref="dialogRef"
                style={{
                    transform: `translate(-50%, -50%) translate(${this.state.dragPosition.x}px, ${this.state.dragPosition.y}px)`,
                }}
            >
                <DialogTitle className={classNames(classes.marginBottom)}>
                    <div className={classNames(classes.flexVStart, classes.flexSpaceBetween)}>
                        <div>
                            <Typography variant={"h6"} className={classNames(classes.extraBold, classes.title)}>
                                Sternum Security Coverage
                                <Tooltip
                                    classes={{ tooltip: classes.infoTooltip }}
                                    title={
                                        <div>
                                            Prevention CWE's are entire major categories of vulnerability types
                                            (consisting of many sub-categories) that Sternum blocks in real-time,
                                            zero-days and 1-days.
                                            <br />
                                            <br />
                                            Detection CWE's are those that are alerted on in real-time. Click an area on
                                            the wheel to filter by CWE type or hover over a CWE to see more information.
                                            <br />
                                            <br />
                                            Please note: The wheel does not include many sub-categories that are also
                                            supported by Sternum.
                                        </div>
                                    }
                                >
                                    <Box display="inline-flex" ml={1}>
                                        <InfoCircleIcon width={18} height={18} color="#1B6FDE" />
                                    </Box>
                                </Tooltip>
                            </Typography>
                            <Typography variant="body2" className={classes.description}>
                                Aligned with MITRE Common Weakness Enumeration, Sternum fingerprints a wide range of top
                                threats.
                            </Typography>
                        </div>

                        <IconButton
                            disableRipple
                            aria-label="close"
                            onClick={this.props.onClose}
                            style={{ backgroundColor: "#fff" }}
                        >
                            <CrossIcon color="#909090" />
                        </IconButton>
                    </div>
                </DialogTitle>
                <div className={classes.checkboxContainer}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.showMonitoring}
                                style={{ color: "#1B6FDE", backgroundColor: "#fff" }}
                                onChange={(e) => {
                                    const checked = e.target.checked;
                                    this.setState((state) => ({
                                        ...state,
                                        showMonitoring: this.state.showRealtime ? checked : true,
                                    }));
                                }}
                            />
                        }
                        label="Prevention"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.showRealtime}
                                style={{ color: "#1B6FDE", backgroundColor: "#fff" }}
                                onChange={(e) => {
                                    const checked = e.target.checked;
                                    this.setState((state) => ({
                                        ...state,
                                        showRealtime: this.state.showMonitoring ? checked : true,
                                    }));
                                }}
                            />
                        }
                        label="Detection"
                    />
                </div>
                <div className={classes.dialogContent}>
                    {this.state.showMonitoring && (
                        <div className={classes.dialogWrapper}>
                            <Typography
                                variant={"h6"}
                                className={classNames(classes.extraBold, classes.title, classes.chartTitle)}
                            >
                                Prevention
                            </Typography>
                            <InteractiveCoveragePlot
                                data={monitoringData}
                                initialCategory={
                                    this.props?.initial?.plot === "prevention"
                                        ? this.props?.initial?.category
                                        : undefined
                                }
                            />
                        </div>
                    )}
                    {this.state.showRealtime && (
                        <div className={classes.dialogWrapper}>
                            <Typography
                                variant={"h6"}
                                className={classNames(classes.extraBold, classes.title, classes.chartTitle)}
                            >
                                Detection
                            </Typography>
                            <InteractiveCoveragePlot
                                data={realtimeData}
                                initialCategory={
                                    this.props?.initial?.plot === "detection"
                                        ? this.props?.initial?.category
                                        : undefined
                                }
                            />
                        </div>
                    )}
                </div>
                <DialogActions className={classes.dialogActions}>
                    {/*this is here just for styling/margin purposes*/}
                </DialogActions>
                <div
                    ref={this.draggableElementRef}
                    className={classes.dragContainer}
                    onMouseDown={this.startMouseMoveTrack.bind(this)}
                    onMouseUp={this.stopMouseMoveTrack.bind(this)}
                >
                    <DragHandleIcon />
                </div>
            </div>
        );
    }
}

export default withStyles(interactiveCoverageDialogStyle)(InteractiveCoverageDialog);
