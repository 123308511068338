/**
 * Represents a configuration for a category in Sternum.
 */
import ArgumentRoleType from "./ArgumentRoleType";
import SpecialField from "./SpecialField";
import TraceCategory from "./TraceCategory";
import EventInterestDetailed from "./EventInterestDetailed";

class SternumAgentConfiguration {
    /**
     * Constructor.
     */
    constructor(
        public argumentRoleTypes: ArgumentRoleType[],
        public traceCategories: TraceCategory[],
        public specialFields: SpecialField[],
        public eventInterests: EventInterestDetailed[]
    ) {}
}

export default SternumAgentConfiguration;
