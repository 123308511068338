import React, { useState } from "react";
import classNames from "classnames";
import { useFormik } from "formik";
import { connect } from "react-redux";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Link,
    Typography,
} from "@material-ui/core";

import SternumImprovedButton from "../SUI/SternumImprovedButton/SternumImprovedButton";
import { useCommonStyle } from "../CommonStyle";
import { CrossIcon } from "../SUI/SternumIcon";
import SternumInputField from "../SUI/SternumInputField/SternumInputField";
import Utils from "../../lib/infra/Utils";
import ServiceWire from "../../lib/services/ServiceWire";
import SternumLink from "../SUI/SternumLink/SternumLink";
import { GlobalState } from "../../lib/state/GlobalState";
import { TextArea } from "../SUI/TextArea";

import { useFeedbackDialogStyle } from "./FeedbackDialog.style";
import { FeedbackResponseStatus } from "./types";
import { FeedbackResponse } from "./FeedbackResponse";

export interface FeedbackDialogProps {
    open: boolean;
    onCancelClick?: () => unknown;
}

export function FeedbackDialog(props: FeedbackDialogProps) {
    if (!props.open) {
        return null;
    }

    return <FeedbackDialogComponent {...props} />;
}

interface FormValues {
    fullName: string;
    email: string;
    details: string;
}

const mapStateToProps = (state: GlobalState, ownProps: FeedbackDialogProps) => {
    return {
        user: state.user.entity,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

type FeedbackDialogPropsWithHOC = FeedbackDialogProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const FeedbackDialogComponent: React.FC<FeedbackDialogProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(FeedbackDialogBaseComponent);

function FeedbackDialogBaseComponent({ open, onCancelClick, user }: FeedbackDialogPropsWithHOC) {
    const classes = useFeedbackDialogStyle();
    const classesCommon = useCommonStyle();

    const [responseStatus, setResponseStatus] = useState<FeedbackResponseStatus>(FeedbackResponseStatus.NotSet);

    const formik = useFormik<FormValues>({
        initialValues: {
            fullName: `${user.firstName} ${user.lastName}`,
            email: user.email || "",
            details: "",
        },
        initialErrors: {
            details: "This field is required",
        },
        validate: (values) => {
            const errors: Partial<FormValues> = {};
            const fieldIsRequired = "The field is required";

            if (values.fullName.trim().length < 1) {
                errors.fullName = fieldIsRequired;
            }

            if (values.email.length < 1) {
                errors.email = fieldIsRequired;
            } else if (!Utils.isValidEmail(values.email)) {
                errors.email = "Email is invalid";
            }

            if (values.details.length < 1) {
                errors.details = fieldIsRequired;
            }

            return errors;
        },
        onSubmit: async (values) => {
            try {
                await ServiceWire.getContactService().sendFeedback({
                    fullName: values.fullName,
                    email: values.email,
                    details: values.details,
                });

                setResponseStatus(FeedbackResponseStatus.Success);
            } catch (err) {
                setResponseStatus(FeedbackResponseStatus.Error);
            }
        },
    });

    const isSendButtonDisabled = () => {
        return formik.isSubmitting || Object.keys(formik.errors).length > 0;
    };

    if (responseStatus !== FeedbackResponseStatus.NotSet) {
        return (
            <Dialog
                aria-labelledby="dialog-title"
                open={open}
                onClose={onCancelClick}
                disableEnforceFocus
                classes={{
                    root: classes.root,
                    paper: classes.paper,
                }}
            >
                <FeedbackResponse
                    responseStatus={responseStatus}
                    onClose={onCancelClick}
                    onTryAgain={() => setResponseStatus(FeedbackResponseStatus.NotSet)}
                />
            </Dialog>
        );
    }

    return (
        <Dialog
            aria-labelledby="dialog-title"
            open={open}
            onClose={onCancelClick}
            disableEnforceFocus
            classes={{
                root: classes.root,
                paper: classes.paper,
            }}
        >
            {/* Title */}
            <DialogTitle id="dialog-title">
                <div className={classNames(classes.title)}>
                    <Typography variant={"h6"} className={classNames(classesCommon.extraBold)}>
                        Feedback
                    </Typography>
                    <IconButton onClick={onCancelClick}>
                        <CrossIcon color="#909090" />
                    </IconButton>
                </div>
            </DialogTitle>

            {/* Body */}
            <DialogContent className={classes.dialogContent}>
                <SternumInputField
                    label="Full Name"
                    required
                    onFieldChange={(e) => formik.setFieldValue("fullName", e.target.value)}
                    onTouch={() => formik.setFieldTouched("fullName", true)}
                    inputValue={formik.values.fullName}
                    error={formik.touched.fullName && !!formik.errors.fullName}
                    helperText={formik.touched.fullName && formik.errors.fullName}
                />
                <SternumInputField
                    label="Email"
                    required
                    onFieldChange={(e) => formik.setFieldValue("email", e.target.value)}
                    onTouch={() => formik.setFieldTouched("email", true)}
                    inputValue={formik.values.email}
                    error={formik.touched.email && !!formik.errors.email}
                    helperText={formik.touched.email && formik.errors.email}
                />
                <TextArea
                    label="Your Feedback *"
                    classNameTextArea={classes.detailsTextArea}
                    onChange={(e) => formik.setFieldValue("details", e.target.value)}
                    onBlur={() => formik.setFieldTouched("details", true)}
                    value={formik.values.details}
                    isError={formik.touched.details && !!formik.errors.details}
                    belowTextArea={formik.touched.details && formik.errors.details}
                />
            </DialogContent>

            <DialogActions className={classNames(classes.actions)}>
                <SternumLink className={classes.linkButton} onClick={onCancelClick}>
                    Close
                </SternumLink>
                <SternumImprovedButton
                    onClick={formik.handleSubmit}
                    isDisabled={isSendButtonDisabled()}
                    isLoading={formik.isSubmitting}
                    content={"Send"}
                    buttonType={"regularWithDisabled"}
                    fullWidth={false}
                />
            </DialogActions>

            <Box textAlign="center" mb={4}>
                <Typography variant="caption">
                    Contact our Customer Care:{" "}
                    <Link underline="none" href="mailto:ads@sternumiot.com">
                        ads@sternumiot.com
                    </Link>
                </Typography>
            </Box>
        </Dialog>
    );
}
