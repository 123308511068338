import { grey } from "@material-ui/core/colors";
import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../../CommonStyle";

const visualisationConfigurationDataSourcesStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: {
            background: "#fff",
        },

        selectComponent: {
            minWidth: theme.spacing(36),
        },
        percentileSelectionComponent: {
            width: theme.spacing(20),
        },

        removeIcon: {
            cursor: "pointer",
            fontSize: theme.spacing(3),
            color: grey[500],
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1),
        },

        queryInterfaceTopContainer: {
            maxWidth: "100%",
        },

        queryInterfaceTopTitle: {
            fontWeight: 600,
            fontSize: 18,
        },

        dataSourcesList: {
            overflow: "auto",
            maxWidth: "calc(100% - 24px - 32px - 137px - 20px)",
            marginLeft: 10,
            marginRight: 10,

            "&::-webkit-scrollbar": {
                display: "none",
                // Chrome & Safari
                width: 0,
                height: 0,
            },
            scrollbarWidth: "none", // Firefox
            msOverflowStyle: "none", // IE
        },

        dropdownIconContainer: {
            width: 24,
            height: 24,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#1B6FDE",
            borderRadius: "50%",
            marginLeft: "auto",
        },

        dropdownIcon: {
            transition: "all 0.2s ease",
        },

        dropdownIconExpanded: {
            transform: "rotate(-90deg)",
        },

        queryInterfaceFooter: {
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 15,
        },

        dataSourceFiltersContainer: {
            marginLeft: theme.spacing(4),
            marginTop: theme.spacing(2),
        },
    });

export default visualisationConfigurationDataSourcesStyle;
