import Utils from "../infra/Utils";
import EventFilter from "./EventFilter";
import ListFilter from "./ListFilter";

/**
 * Represents a filter of devices.
 */
class DevicesFilter implements ListFilter {
    /**
     * Constructor.
     */
    constructor(
        public deviceNames: string[],
        public statuses: string[],
        public ipAddresses: string[],
        public lastSeenFrom: number,
        public lastSeenTo: number,
        public createdFrom: number,
        public createdTo: number,
        public eventFilters: EventFilter[],
        public usedLibraryIds: string[],
        public onlyVulnerableDevices: boolean,
        public onlyOutOfDateDevices: boolean
    ) {}

    /**
     * Returns whether filter is defined, or all values are nulls.
     */
    public isActive(): boolean {
        return !!(
            (this.statuses && this.statuses.length) ||
            (this.ipAddresses &&
                this.ipAddresses.length &&
                Utils.anyMatch(this.ipAddresses, (ipAddress) => !!ipAddress)) ||
            (this.deviceNames &&
                this.deviceNames.length &&
                Utils.anyMatch(this.deviceNames, (deviceName) => !!deviceName)) ||
            (this.usedLibraryIds &&
                this.usedLibraryIds.length &&
                Utils.anyMatch(this.usedLibraryIds, (libraryId) => !!libraryId)) ||
            this.lastSeenFrom ||
            this.lastSeenTo ||
            this.createdFrom ||
            this.createdTo ||
            (this.eventFilters &&
                this.eventFilters.length &&
                Utils.anyMatch(
                    this.eventFilters,
                    (eventFilter) => !!(eventFilter.eventType || eventFilter.from || eventFilter.to)
                ))
        );
    }

    /**
     * Returns whether current filter is different than given other filter.
     * @param other The other list filter to check with.
     */
    public isDifferentFrom(other: ListFilter): boolean {
        if (!(other instanceof DevicesFilter)) {
            return true;
        }

        let otherDevicesFilter = other as DevicesFilter;

        if (otherDevicesFilter.eventFilters && !this.eventFilters) {
            return true;
        }

        if (!otherDevicesFilter.eventFilters && this.eventFilters) {
            return true;
        }

        // Any different event filters?
        if (otherDevicesFilter.eventFilters && this.eventFilters) {
            for (let i = 0; i < otherDevicesFilter.eventFilters.length; i++) {
                let otherEventFilter = otherDevicesFilter.eventFilters[i];
                let thisEventFilter = this.eventFilters[i];

                if (otherEventFilter && !thisEventFilter) {
                    return true;
                }

                if (!otherEventFilter && thisEventFilter) {
                    return true;
                }

                if (otherEventFilter && thisEventFilter) {
                    if (otherEventFilter.isDifferentFrom(thisEventFilter)) {
                        return true;
                    }
                }
            }

            for (let i = 0; i < this.eventFilters.length; i++) {
                let otherEventFilter = otherDevicesFilter.eventFilters[i];
                let thisEventFilter = this.eventFilters[i];

                if (otherEventFilter && !thisEventFilter) {
                    return true;
                }

                if (!otherEventFilter && thisEventFilter) {
                    return true;
                }

                if (otherEventFilter && thisEventFilter) {
                    if (otherEventFilter.isDifferentFrom(thisEventFilter)) {
                        return true;
                    }
                }
            }
        }

        return (
            Utils.areArraysDifferent(otherDevicesFilter.ipAddresses, this.ipAddresses) ||
            Utils.areArraysDifferent(otherDevicesFilter.deviceNames, this.deviceNames) ||
            Utils.areArraysDifferent(otherDevicesFilter.usedLibraryIds, this.usedLibraryIds) ||
            otherDevicesFilter.lastSeenFrom !== this.lastSeenFrom ||
            otherDevicesFilter.lastSeenTo !== this.lastSeenTo ||
            otherDevicesFilter.createdFrom !== this.createdFrom ||
            otherDevicesFilter.createdTo !== this.createdTo ||
            Utils.areArraysDifferent(otherDevicesFilter.statuses, this.statuses)
        );
    }
}

export default DevicesFilter;
