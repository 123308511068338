import React from "react";
import classNames from "classnames";

import { CancelIcon, CheckFilledIcon, CrossOutlinedIcon } from "../../SUI/SternumIcon";
import { useResolvingReasonsStyle } from "./ResolvingReasons.style";

export interface ResolvingReasonsCustomItemProps extends React.ComponentProps<"div"> {
    isSelected?: boolean;
    onClickDelete?: () => unknown;
}

export function ResolvingReasonsCustomItem({
    className,
    isSelected,
    onClick,
    onClickDelete,
    children,
    ...props
}: ResolvingReasonsCustomItemProps) {
    const classes = useResolvingReasonsStyle();

    const handleDeleteIconClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        onClickDelete?.();
    };

    return (
        <div
            className={classNames(className, classes.resolvingReasonsCustomItem)}
            aria-selected={!!isSelected}
            data-on-click={!!onClick}
            onClick={onClick}
            {...props}
        >
            {children}
            <CheckFilledIcon color={null} className={classes.checkIcon} />
            <CancelIcon color={null} className={classes.deleteIcon} onClick={handleDeleteIconClick} />
        </div>
    );
}
