import { WithStyles, withStyles } from "@material-ui/core/styles";
import * as React from "react";
import { connect } from "react-redux";
import { GlobalState } from "../../../lib/state/GlobalState";
import graphLoadingDisplayStyle from "./GraphLoadingDisplayStyle";
import classNames from "classnames";
import Utils from "../../../lib/infra/Utils";
import { CircularProgress } from "@material-ui/core";
import GraphLoader from "../../SUI/SternumLoaders/GraphLoader";

const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

interface AppState {}

export interface AppProps extends WithStyles<typeof graphLoadingDisplayStyle> {
    amountOfLoaders?: number;
    displayLoadingBlocks?: boolean;
}

class GraphLoadingDisplay extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);

        this.state = {};
    }

    render() {
        const { classes } = this.props;

        if (this.props.displayLoadingBlocks) {
            return (
                <div className={classNames(classes.blocksLoadingRoot)}>
                    <div className={classNames(classes.loadingPlaceholder, "mod-tall-small")} />
                    <div className={classNames(classes.loadingPlaceholder, "mod-tall-medium")} />
                    {Utils.range(this.props.amountOfLoaders || 4).map((currentNumber) => {
                        return (
                            <div
                                key={currentNumber}
                                className={classNames(classes.loadingPlaceholder, "mod-tall-large")}
                            />
                        );
                    })}
                    <div className={classNames(classes.loadingPlaceholder, "mod-tall-medium")} />
                    <div className={classNames(classes.loadingPlaceholder, "mod-tall-small")} />
                </div>
            );
        } else {
            return (
                <div className={classNames(classes.circularLoadingRoot)}>
                    <GraphLoader />
                </div>
            );
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(graphLoadingDisplayStyle)(GraphLoadingDisplay));
