import { makeStyles, Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../../CommonStyle";

const useSternumLoadersStyle = makeStyles((theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        container: {
            padding: 10,
            borderRadius: 6,
            background: "#fff",
            boxShadow: "0px 6px 30px 0px rgba(0, 0, 0, 0.1)",
        },
    })
);

export default useSternumLoadersStyle;
