import { Action } from "redux";
import ActionType from "../ActionType";

/**
 * Occurs once fetching a device has started.
 */
export interface FetchDeviceAction extends Action<ActionType> {
    type: ActionType.FetchDevice;
    deviceId: string;
}

/**
 * Occurs once fetching a device has started.
 */
export function fetchDeviceAction(deviceId: string): FetchDeviceAction {
    return {
        type: ActionType.FetchDevice,
        deviceId: deviceId,
    };
}
