import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import statusDisplayStyle from "./StatusDisplayStyle";
import { Tooltip } from "@material-ui/core";

/**
 * Holds the inner state for our app.
 */
interface AppState {}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof statusDisplayStyle> {
    label?: string;
    danger?: boolean;
    success?: boolean;
    medium?: boolean;
    regular?: boolean;
    low?: boolean;
    high?: boolean;
    staging?: boolean;
    production?: boolean;
    veryWeakPassword?: boolean;
    weakPassword?: boolean;
    mediumPassword?: boolean;
    strongPassword?: boolean;
    veryStrongPassword?: boolean;
    displayColoredBackground?: boolean;
    padding?: boolean;
    centerText?: boolean;
    dateSelection?: boolean;
    smallIndication?: boolean;
    emptyIndication?: boolean;
    toolTipText?: string;
    disabled?: boolean;
    narrow?: boolean;
    devMode?: boolean;
}

/**
 * Displays a status indication of a device.
 */
class StatusDisplay extends React.PureComponent<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {};
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        if (this.props.emptyIndication) {
            return (
                <div
                    role="presentation"
                    aria-label="status tag"
                    className={classNames(classes.statusIndication, "mod-small-indication")}
                />
            );
        } else if (this.props.smallIndication) {
            let smallIndication = (
                <div
                    role="presentation"
                    aria-label="status tag"
                    className={classNames(
                        classes.statusIndication,
                        this.props.success && "mod-success",
                        this.props.danger && "mod-danger",
                        this.props.low && "mod-low",
                        this.props.medium && "mod-medium",
                        this.props.regular && "mod-regular",
                        this.props.high && "mod-high",
                        this.props.staging && "mod-staging",
                        this.props.production && "mod-production",
                        "mod-small-indication"
                    )}
                />
            );

            if (this.props.toolTipText) {
                return (
                    <Tooltip title={this.props.toolTipText} classes={{ tooltip: classes.toolTip }}>
                        {smallIndication}
                    </Tooltip>
                );
            } else {
                return smallIndication;
            }
        } else {
            return (
                <div
                    role="presentation"
                    aria-label="status tag"
                    className={classNames(
                        classes.statusIndication,
                        this.props.success && "mod-success",
                        this.props.danger && "mod-danger",
                        this.props.low && "mod-low",
                        this.props.medium && "mod-medium",
                        this.props.high && "mod-high",
                        this.props.staging && "mod-staging",
                        this.props.production && "mod-production",
                        this.props.veryWeakPassword && "mod-very-weak-password",
                        this.props.weakPassword && "mod-weak-password",
                        this.props.mediumPassword && "mod-medium-password",
                        this.props.strongPassword && "mod-strong-password",
                        this.props.veryStrongPassword && "mod-very-strong-password",
                        this.props.displayColoredBackground && "with-background",
                        this.props.padding && "mod-padding",
                        this.props.centerText && "mod-center-text",
                        this.props.dateSelection && "mod-date-selection",
                        this.props.disabled && "mod-disabled",
                        this.props.narrow && "mod-narrow",
                        this.props.devMode && "mod-dev-mode"
                    )}
                >
                    {this.props.label}
                </div>
            );
        }
    }
}

export default withStyles(statusDisplayStyle)(StatusDisplay);
