import TableRowData from "./TableRowData";
import DeviceInfo from "./DeviceInfo";
import EntityBase from "./EntityBase";
import EntityType from "./EntityType";

/**
 * Represents a device process in sternum.
 */
class DeviceProcessInfo extends EntityBase implements TableRowData {
    /**
     * Constructor.
     */
    constructor(
        public deviceProcessId: string,
        public deviceId: string,
        public clientId: string,
        public created: number,
        public updated: number,
        public name: string,
        public device?: DeviceInfo
    ) {
        super(deviceProcessId, created, updated, EntityType.DeviceProcess);
    }

    /**
     * Converts json to device process info object.
     */
    public static fromJsonObject(jsonObject: Object) {
        return new DeviceProcessInfo(
            jsonObject["entity_id"],
            jsonObject["device_id"],
            jsonObject["client_id"],
            jsonObject["created"],
            jsonObject["updated"],
            jsonObject["name"],
            jsonObject["device"] && DeviceInfo.fromJsonObject(jsonObject["device"])
        );
    }

    /**
     * Returns whether given row is any different in details than current info.
     * Shallow comparison, in other words.
     * @param other The row to compare with.
     */
    public isDifferentFrom(other: TableRowData): boolean {
        // Row must be of type device process info.
        if (!(other instanceof DeviceProcessInfo)) {
            return true;
        }

        return true;
    }

    /**
     * Gets the value for the given column id.
     */
    public getColumnValue(columnHeaderId: string): any {
        return this[columnHeaderId];
    }
}

export default DeviceProcessInfo;
