import ActionType from "../ActionType";
import { LibrariesReducerActions } from "./LibrariesReducerActions";
import { EntitiesState, getDefaultEntitiesState } from "../../state/EntitiesState";
import HashSet from "../../infra/HashSet";
import HashMap from "../../infra/HashMap";
import LibraryInfo from "../../state/LibraryInfo";

/**
 * Reducer for all library-related actions.
 */
const librariesReducer = (
    state: EntitiesState<LibraryInfo> = getDefaultEntitiesState<LibraryInfo>(),
    action: LibrariesReducerActions
) => {
    switch (action.type) {
        case ActionType.FetchLibrary: {
            return {
                idToEntityMap: state.idToEntityMap,
                loadingIds: HashSet.copyAndAdd(state.loadingIds, action.libraryId),
                errorIds: state.errorIds,
                detectedDeviceCount: null,
            };
        }

        case ActionType.FetchedLibrarySuccessfully: {
            return {
                idToEntityMap: HashMap.copyAndPut(state.idToEntityMap, action.library.entityId, action.library),
                loadingIds: state.loadingIds.exists(action.library.entityId)
                    ? HashSet.copyAndRemove(state.loadingIds, action.library.entityId)
                    : state.loadingIds,
                errorIds: state.errorIds.containsKey(action.library.entityId)
                    ? HashMap.copyAndRemove(state.errorIds, action.library.entityId)
                    : state.errorIds,
                detectedDeviceCount: null,
            };
        }

        case ActionType.ErrorFetchingLibrary: {
            return {
                idToEntityMap: state.idToEntityMap,
                loadingIds: HashSet.copyAndRemove(state.loadingIds, action.libraryId),
                errorIds: HashMap.copyAndPut(state.errorIds, action.libraryId, action.error),
                detectedDeviceCount: null,
            };
        }

        default:
            return state;
    }
};

export default librariesReducer;
