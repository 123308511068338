import { makeStyles } from "@material-ui/core";

export const useRemoteViewAccordionStyles = makeStyles((theme) => ({
    accordion: {
        "&&": {
            background: "#FFFFFF",
            boxShadow: "0 0 23px rgba(231, 232, 235, 0.6)",
            borderRadius: 14,
            margin: 0,

            "&:before": {
                display: "none",
            },

            "&.Mui-expanded": {
                boxShadow: "none",
            },

            "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
                transform: "rotate(-90deg)",
            },

            "& .MuiAccordionSummary-root": {
                height: 56,
                maxHeight: 56,
                minHeight: 56,
                padding: "0 18px",

                "& .Mui-expanded": {
                    height: 56,
                    maxHeight: 56,
                    minHeight: 56,
                },
            },

            "& .MuiAccordionSummary-content": {
                display: "flex",
                alignItems: "center",
                height: 56,
                maxHeight: 56,
                minHeight: 56,
                margin: 0,
                fontWeight: 600,
                fontSize: 18,
                color: "#2B2523",

                "& .Mui-expanded": {
                    height: 56,
                    maxHeight: 56,
                    minHeight: 56,
                    margin: "0 18px",
                },
            },

            "& .MuiAccordionDetails-root": {
                display: "flex",
                flexFlow: "column",
                padding: "0",
            },
        },
    },

    arrowIconContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 24,
        height: 24,
        background: "#1B6FDE",
        borderRadius: 24,
        color: "white",
    },

    horizontalLineSeparator: {
        height: 1,
        width: "100%",
        margin: "4px 0",
        background: "#D5DAE0",
    },
}));
