import { call, put, takeEvery } from "redux-saga/effects";
import ServiceWire from "../../services/ServiceWire";
import FetchError from "../../state/FetchError";
import ActionType from "../ActionType";
import { errorFetchingDeviceAction } from "./ErrorFetchingDeviceAction";
import { FetchDeviceAction } from "./FetchDeviceAction";
import { fetchedDeviceSuccessfullyAction } from "./FetchedDeviceSuccessfullyAction";
import { showNotificationAction } from "../notifications/ShowNotificationAction";
import { NotificationVariant } from "../../state/NotificationsState";

/**
 * Fetches a device from API.
 */
export function* fetchDeviceAsync(action: FetchDeviceAction) {
    try {
        // Trying to retrieve device from cache.
        let device = null;

        if (!device) {
            // Device not found in cache, calling API for getting the device.
            device = yield call(
                [ServiceWire.getSternumService(), ServiceWire.getSternumService().getDeviceById],
                action.deviceId
            );
        }

        // Dispatching a successful fetch of device.
        yield put(fetchedDeviceSuccessfullyAction(device));
    } catch (error) {
        // Error occurred, dispatching an erroneous fetch of device.
        yield put(errorFetchingDeviceAction(action.deviceId, new FetchError(error.message)));
        yield put(
            showNotificationAction(
                "We were not able to fetch selected device. It might not exist in current board.",
                NotificationVariant.Error
            )
        );
    }
}

/**
 * Watching the ActionType.FetchDevice action for fetching device from API.
 */
export function* watchFetchDeviceAsync() {
    yield takeEvery((action) => action.type === ActionType.FetchDevice, fetchDeviceAsync);
}
