import SternumDisplayObjectInfo from "./SternumDisplayObjectInfo";
import TraceDivisionType from "./TraceDivisionType";

/**
 * Represents a trace definition in sternum.
 */
class TraceDefinitionPartial {
    /**
     * Constructor.
     */
    constructor(
        public displayName: string,
        public traceEventName: string,
        public traceCategory: string,
        public traceInterest: string,
        public transmitFrequency: string,
        public description?: string,
        public displayInUI?: boolean,
        public traceType?: string,
        public canBeUsedForAlerts?: boolean
    ) {
        if (!displayInUI) this.displayInUI = true;
    }

    /**
     * Convert to json object
     */
    toJsonObject() {
        return {
            display_name: this.displayName,
            can_be_used_for_alerts: this.canBeUsedForAlerts,
            trace_event_name: this.traceEventName,
            trace_category: this.traceCategory,
            transmit_frequency: this.transmitFrequency,
            event_interest: this.traceInterest,
            description: this.description,
        };
    }

    /**
     * Check if it's system trace
     */
    public isSystemTrace() {
        return this.traceType == TraceDivisionType[TraceDivisionType.SYSTEM];
    }

    /**
     * Create system traces
     */
    public static createSystemTraces(listOfSystemTraces): TraceDefinitionPartial[] {
        return listOfSystemTraces.map(
            (displayTraceData: SternumDisplayObjectInfo) =>
                new TraceDefinitionPartial(
                    displayTraceData.displayName,
                    displayTraceData.systemName,
                    null,
                    null,
                    null,
                    "",
                    displayTraceData.displayInUI,
                    TraceDivisionType[TraceDivisionType.SYSTEM],
                    displayTraceData.canBeUsedForAlerts
                )
        );
    }
}

export default TraceDefinitionPartial;
