import { Link, LinkProps } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { NavLink } from "react-router-dom";
import useSternumLinkStyles from "./SternumLinkStyles";

interface SternumLinkProps extends LinkProps {
    onlyActiveOnHover?: boolean;
    to?: string;
    children?: React.ReactNode;
    isExternal?: boolean;
}

const SternumLink = ({
    onlyActiveOnHover = false,
    to,
    children,
    className,
    isExternal,
    ...restProps
}: SternumLinkProps) => {
    const classes = useSternumLinkStyles();
    // Use react router dom Link
    if (to) {
        return (
            <NavLink
                to={to}
                target={isExternal ? "_blank" : ""}
                className={classNames(
                    !onlyActiveOnHover && classes.link,
                    onlyActiveOnHover && classes.inactive,
                    className
                )}
            >
                {children}
            </NavLink>
        );
        // Use material Link
    } else {
        return (
            <Link {...restProps} className={classNames(classes.link, className, onlyActiveOnHover && classes.inactive)}>
                {children}
            </Link>
        );
    }
};

export default SternumLink;
