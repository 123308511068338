/**
 * Defines the possible statuses of a device.
 */
enum DeviceStatus {
    SECURED,
    SECURITY_ALERT,
    CRITICAL_CVE,
    OUT_OF_DATE_LIBRARIES,
    MULTIPLE_ISSUES,
}

export default DeviceStatus;
