import React from "react";
import useCogSpinnerStyle from "./CogSpinnerStyle";

export function Cog() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="170" height="170" viewBox="0 0 170 170" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M77.2514 50.9232C80.2408 50.2086 83.2733 49.9296 86.2643 50.0367L90.4407 42.586L103.719 46.7446L103.091 55.3047C105.633 56.9192 107.991 58.8855 110.081 61.1857L118.176 58.9372L124.693 71.4834L118.332 77.0867C119.031 80.1423 119.304 83.2418 119.199 86.2991L126.489 90.5679L122.42 104.14L114.045 103.499C112.466 106.096 110.542 108.507 108.28 110.635L110.48 118.91L98.217 125.578L92.735 119.076C89.7455 119.791 86.7131 120.07 83.7221 119.963L79.5457 127.414L66.2676 123.255L66.8949 114.695C64.3538 113.08 61.9954 111.114 59.9058 108.814L51.8099 111.062L45.2863 98.5286L51.6474 92.9253C50.9482 89.8697 50.6753 86.7701 50.7801 83.7129L43.4907 79.4441L47.5592 65.8721L55.934 66.5133C57.5136 63.916 59.4373 61.5054 61.6877 59.3696L59.4878 51.0946L71.7504 44.4266L77.2514 50.9232ZM78.5568 71.2452C71.2834 75.1938 68.5294 84.4197 72.3875 91.8347C76.2506 99.2691 85.2768 102.084 92.5312 98.1405C99.8046 94.1919 102.559 84.966 98.7005 77.551C94.8494 70.1238 85.8303 67.2966 78.5568 71.2452Z"
                fill="#DBDBFC"
            />
        </svg>
    );
}

export function CircleInner() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="170" height="170" viewBox="0 0 170 170" fill="none">
            <path
                d="M63.5051 3.03196C108.755 -9.36099 155.406 17.3751 167.229 62.4771C170.468 74.8312 170.849 87.5474 168.468 100.18C167.998 102.41 165.894 104.037 163.473 103.649C161.249 103.208 159.648 101.124 160.066 98.6975C162.363 87.3495 161.897 75.9177 158.966 64.7402C148.274 23.9523 106.338 -0.0817731 65.416 11.1258C24.4945 22.3333 -0.0666648 64.5796 10.6258 105.368C21.3183 146.155 63.4514 170.136 104.373 158.928C119.325 154.833 132.556 146.586 142.543 134.813C144.006 133.151 146.666 132.843 148.557 134.427C150.2 135.868 150.475 138.525 148.868 140.437C137.657 153.175 123.007 162.442 106.481 166.968C61.2309 179.361 14.5797 152.625 2.75629 107.523C-9.06713 62.4208 18.2553 15.4249 63.5051 3.03196Z"
                fill="#DBDBFC"
            />
        </svg>
    );
}

export function CircleOuter() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="170" height="170" viewBox="0 0 170 170" fill="none">
            <path
                d="M38.6274 132.933C12.5017 106.229 11.8562 63.2189 37.5056 37.0018C63.155 10.7848 105.234 11.4447 131.36 38.1484C157.486 64.8522 158.131 107.863 132.482 134.08C130.931 135.664 128.223 135.777 126.491 134.006C124.903 132.384 124.728 129.612 126.419 127.883C148.686 105.123 148.247 67.509 125.441 44.1985C102.635 20.888 65.835 20.4385 43.5679 43.1983C21.3008 65.9581 21.7406 103.572 44.5464 126.883C58.4031 141.046 77.8732 147.407 96.7977 143.702C98.9321 143.291 101.231 144.776 101.829 147.116C102.283 149.308 100.884 151.623 98.6082 152.179C76.6935 156.284 54.3605 149.014 38.6274 132.933Z"
                fill="#DBDBFC"
            />
        </svg>
    );
}

export function CogSpinner() {
    const classes = useCogSpinnerStyle();
    return (
        <div className={classes.container}>
            <div className={classes.cog}>
                <Cog />
            </div>
            <div className={classes.inner}>
                <CircleInner />
            </div>
            <div className={classes.outer}>
                <CircleOuter />
            </div>
        </div>
    );
}
