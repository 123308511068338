/**
 * Holds the information needed for a bullet item.
 */
import SternumDeviceEventInfo from "../../lib/state/SternumDeviceEventInfo";

class TimelineBulletInfo {
    /**
     * Constructor.
     */
    constructor(
        public identifier: string,
        public selected: boolean,
        public sternumDeviceEvent: SternumDeviceEventInfo,
        public timeLabel: string,
        public isEmptyBullet: boolean,
        public highlightedBullet: boolean
    ) {}
}

export default TimelineBulletInfo;
