import { Theme } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import { sternumTheme } from "../App";
import commonStyle from "../CommonStyle";

let boardsPageStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: {
            padding: `${theme.spacing(7)}px ${theme.spacing(8)}px`,
        },
        boardsContainer: {
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            paddingLeft: theme.spacing(2),
        },
        boardHolder: {
            cursor: "pointer",
            border: `1px solid ${sternumTheme.grey.main}`,
            boxShadow: "1px 2px 6px 0 rgba(0,0,0,0.07)",
            borderRadius: theme.spacing(1),
            borderTop: `10px solid ${theme.palette.primary.main}`,
            width: "30%",
            padding: `${theme.spacing(3)}px ${theme.spacing(7)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
            "&:hover .boardNameTypography": {
                color: "#636c75",
            },
        },
        upperContent: {
            marginBottom: theme.spacing(10),
        },
    });

export default boardsPageStyle;
