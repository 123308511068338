interface SternumTriggerFields {
    traceDefinition: any;
    argumentDefinition: any;
}

/**
 * Represents abstract class for trigger definition
 */

abstract class TriggerDefinition implements SternumTriggerFields {
    traceDefinition;
    argumentDefinition;
    /**
     * Converts json to object.
     */
    public abstract getJsonObject();

    /**
     * Convert class fields into json string.
     */
    public abstract getJsonString(): string;

    /**
     * Check if equal.
     */
    public abstract isEqual(other: TriggerDefinition): boolean;
}

export default TriggerDefinition;
