import React from "react";
import classNames from "classnames";

import { ResolvingReasonsItem } from "./ResolvingReasonsItem.component";
import { ResolvingReasonsCustomItem } from "./ResolvingReasonsCustomItem.component";
import { ResolvingReasonsAddItem } from "./ResolvingReasonsAddItem.component";

import { useResolvingReasonsStyle } from "./ResolvingReasons.style";

export interface ResolvingReasonsProps extends React.ComponentProps<"div"> {
    isDisplayView?: boolean;
}

export function ResolvingReasons({ className, isDisplayView, children, ...props }: ResolvingReasonsProps) {
    const classes = useResolvingReasonsStyle();

    return (
        <div
            className={classNames(
                className,
                classes.resolvingReasonsContainer,
                isDisplayView && classes.resolvingReasonsContainerDisplayView
            )}
            {...props}
        >
            {children}
        </div>
    );
}

ResolvingReasons.CustomItem = ResolvingReasonsCustomItem;
ResolvingReasons.Item = ResolvingReasonsItem;
ResolvingReasons.AddItem = ResolvingReasonsAddItem;
