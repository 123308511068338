import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const commonHome = {
    darkBackground: "#231434",
    secondViewBackground: "#f8f8ff",
    customersOpinionsBackground: "#F4F4FC",
    mediaQueryMaxWidth: "650px",
    mediaQueriesNavigationMaxWidth: "500px",
};

export const useHomePageStyle = makeStyles((theme: Theme) => ({
    root: {
        fontFamily: "Inter",
        overflowX: "hidden",
    },

    mainContainer: {
        maxWidth: 1130,
        width: "100%",
        padding: "0px 20px",
        boxSizing: "border-box",

        [`@media (max-width: ${commonHome.mediaQueryMaxWidth})`]: {
            padding: "0px 16px",
        },
    },
}));
