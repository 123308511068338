import { Box, Typography } from "@material-ui/core";
import React from "react";
import { useStyles } from "./GetSupportStepsStyle";
import { ArrowIcon, Step1Icon, Step2Icon, Step3Icon, Step4Icon, Step5Icon } from "./icons";

export function GetSupportSteps() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.inner}>
                <div className={classes.stepContainer}>
                    <ArrowIcon />
                    <div className={classes.iconBox}>
                        <Step1Icon />
                    </div>
                    <Typography variant="subtitle1" className={classes.stepTitle}>
                        PREPARE YOUR DEVICE
                    </Typography>
                    <Typography variant="body2" className={classes.stepText}>
                        Check the OpenWrt supported version or download one
                    </Typography>
                </div>

                <div className={classes.stepContainer}>
                    <ArrowIcon />
                    <div className={classes.iconBox}>
                        <Step2Icon />
                    </div>
                    <Typography variant="subtitle1" className={classes.stepTitle}>
                        INSTALL RUNTIME PROTECTION
                    </Typography>
                    <Typography variant="body2" className={classes.stepText}>
                        Install Sternum runtime protection in 3 simple steps
                    </Typography>
                </div>

                <div className={classes.stepContainer}>
                    <ArrowIcon />
                    <div className={classes.iconBox}>
                        <Step3Icon />
                    </div>
                    <Typography variant="subtitle1" className={classes.stepTitle}>
                        MONITOR DEVICE
                    </Typography>
                    <Typography variant="body2" className={classes.stepText}>
                        Get alerts on attacks and suspect behavior and track usage stats
                    </Typography>
                </div>

                <div className={classes.stepContainer}>
                    <ArrowIcon />
                    <div className={classes.iconBox}>
                        <Step4Icon />
                    </div>
                    <Typography variant="subtitle1" className={classes.stepTitle}>
                        TEST YOUR PROTECTION
                    </Typography>
                    <Typography variant="body2" className={classes.stepText}>
                        Run attack simulations to see Sternum in action, without endangering your device
                    </Typography>
                </div>

                <div className={classes.stepContainer}>
                    <div className={classes.iconBox}>
                        <Step5Icon />
                    </div>
                    <Typography variant="subtitle1" className={classes.stepTitle}>
                        ADD ADDITIONAL DEVICES
                    </Typography>
                    <Typography variant="body2" className={classes.stepText}>
                        Contact us to see more of Sternum solutions and services
                    </Typography>
                </div>
            </div>
        </div>
    );
}
