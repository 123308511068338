import { Typography, withStyles, WithStyles } from "@material-ui/core";
import * as React from "react";
import serverDownStyle from "./ServerDownStyle";

interface AppState {}

export interface AppProps extends WithStyles<typeof serverDownStyle> {}

class ServerDown extends React.PureComponent<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {};
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Typography variant="h4">
                    Sorry, we're having issues with our servers. Please try again later.
                </Typography>
            </div>
        );
    }
}

export default withStyles(serverDownStyle)(ServerDown);
