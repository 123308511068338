import { makeStyles } from "@material-ui/core";

const useLoadingBarStyle = makeStyles((theme) => {
    return {
        container: {
            position: "relative",
            width: "100%",
            height: "6px",

            background: "#F4F4F4",
            borderRadius: "3px",
            overflow: "hidden",
        },
        bar1: {
            position: "absolute",
            width: "62px",
            height: "6px",
            left: "-62px",
            top: "0px",

            background: "#E7004C",
            borderRadius: "3px",
            animation: `$barMove 800ms ${theme.transitions.easing.easeInOut}`,
            animationIterationCount: "infinite",
        },
        bar2: {
            position: "absolute",
            width: "62px",
            height: "6px",
            left: "138px",
            top: "0px",

            background: "#E7004C",
            borderRadius: "3px",
            animation: `$barMove 800ms ${theme.transitions.easing.easeInOut}`,
            animationIterationCount: "infinite",
        },
        bar3: {
            position: "absolute",
            width: "62px",
            height: "6px",
            left: "338px",
            top: "0px",

            background: "#E7004C",
            borderRadius: "3px",
            animation: `$barMove 800ms ${theme.transitions.easing.easeInOut}`,
            animationIterationCount: "infinite",
        },
        "@keyframes barMove": {
            "0%": {
                transform: "translateX(0)",
            },
            "100%": {
                transform: "translateX(200px)",
            },
        },
    };
});

export default useLoadingBarStyle;
