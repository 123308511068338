import SternumDeviceEventInfo from "./SternumDeviceEventInfo";
import TriggerHitSummary from "./TriggerHitSummary";

/**
 * Response of the get top hitting triggers command.
 */
class GetTopHittingTriggersResponse {
    /**
     * Constructor.
     */
    constructor(public triggersHitSummaries: TriggerHitSummary[]) {}

    /**
     * Creates the response instance from the given json response.
     */
    public static fromJson(jsonObject: Object): GetTopHittingTriggersResponse {
        let triggersSummaries: TriggerHitSummary[] = jsonObject["triggers"].map((triggerSummaryJson) => {
            return new TriggerHitSummary(
                triggerSummaryJson["entity_id"],
                triggerSummaryJson["count"],
                triggerSummaryJson["display_name"],
                triggerSummaryJson["event_interest"],
                triggerSummaryJson["trigger_disabled"]
            );
        });

        return new GetTopHittingTriggersResponse(triggersSummaries);
    }
}

export default GetTopHittingTriggersResponse;
