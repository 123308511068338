import * as ReactGA from "react-ga";

ReactGA.initialize(process.env.STERNUM_GOOGLE_TRACKING_ID, {
    gaOptions: {
        cookieFlags: "domain:none;SameSite=none;Secure",
    },
});

class AnalyticsService {
    /* Methods */
    public static async error(action: string, description: string) {
        ReactGA.event({
            category: "error",
            action,
            label: description,
            nonInteraction: true,
        });
    }

    public static setUserInfo(userId: string, sternumClientId: string) {
        ReactGA.set({
            userId,
            sternumClientId,
        });
    }

    public static track(action: string) {
        ReactGA.event({
            category: "interaction",
            action,
        });
    }

    public static trackPageView(path: string) {
        ReactGA.pageview(path);
    }

    /* Decorators */
    public static reportOnError = (action: string) => (target, key, descriptor) => {
        const originalMethod = descriptor.value;

        descriptor.value = async function (...args) {
            try {
                return await originalMethod.apply(this, args);
            } catch (error) {
                console.error(error);
                AnalyticsService.error(action, error.message);
                throw error;
            }
        };

        return descriptor;
    };
}

export default AnalyticsService;
