import { all } from "redux-saga/effects";
import { watchFetchCveAsync } from "./cves/FetchCveSaga";
import { watchFetchAlertsStatisticsAsync } from "./dashboardRegular/FetchAlertsStatisticsSaga";
import { watchFetchDeviceAsync } from "./devices/FetchDeviceSaga";
import { watchFetchGeneratedEventAsync } from "./generatedEvents/FetchGeneratedEventSaga";
import { watchFetchPendingIssuesCountAsync } from "./issues/FetchPendingIssuesCountSaga";
import { watchIssueAsync } from "./issues/IssueSaga";
import { watchFetchLibraryAsync } from "./libraries/FetchLibrarySaga";
import { watchCloseModalAsync } from "./modals/CloseModalSaga";
import { watchOpenModalAsync } from "./modals/OpenModalSaga";
import { watchFetchTraceAsync } from "./traces/FetchTraceSaga";
import { watchFetchUsedLibraryAsync } from "./usedLibraries/FetchUsedLibrarySaga";
import { watchDashboardOnboardingStateAsync } from "./dashboardRegular/DashboardOnboardingStateSaga";
import { watchSetIsOnlyAnomalyInsightsAsync } from "./dashboardRegular/SetIsOnlyAnomalyInsightsSaga";

/**
 * Defines the root saga of our application, containing all our sagas.
 */
export default function* rootSaga() {
    yield all([
        watchIssueAsync(),
        watchFetchPendingIssuesCountAsync(),
        watchFetchDeviceAsync(),
        watchOpenModalAsync(),
        watchCloseModalAsync(),
        watchFetchTraceAsync(),
        watchFetchLibraryAsync(),
        watchFetchCveAsync(),
        watchFetchUsedLibraryAsync(),
        watchFetchGeneratedEventAsync(),
        watchFetchAlertsStatisticsAsync(),
        watchSetIsOnlyAnomalyInsightsAsync(),
        watchDashboardOnboardingStateAsync(),
    ]);
}
