import { Action } from "redux";
import ActionType from "../ActionType";

/**
 * Defines the data for the view library action.
 */
export interface ViewLibraryAction extends Action<ActionType> {
    type: ActionType.ViewLibrary;
    libraryId: string;
}

/**
 * Action creator for viewing a library.
 */
export function viewLibraryAction(libraryId: string): ViewLibraryAction {
    return {
        type: ActionType.ViewLibrary,
        libraryId: libraryId,
    };
}
