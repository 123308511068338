enum SternumColorType {
    COLOR_A = "COLOR_A",
    COLOR_B = "COLOR_B",
    COLOR_C = "COLOR_C",
    COLOR_D = "COLOR_D",
    COLOR_E = "COLOR_E",
    COLOR_F = "COLOR_F",
    COLOR_G = "COLOR_G",
    COLOR_H = "COLOR_H",
    COLOR_I = "COLOR_I",
    COLOR_J = "COLOR_J",
    COLOR_K = "COLOR_K",
    COLOR_L = "COLOR_L",
    COLOR_M = "COLOR_M",
    COLOR_N = "COLOR_N",
    COLOR_O = "COLOR_O",
    COLOR_P = "COLOR_P",
}

export default SternumColorType;
