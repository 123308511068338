import { makeStyles } from "@material-ui/core";

const blueColor = "#1B6FDE";
const lightBlueColor = "#F6FAFF";
const disabledColor = "#D2D2D9";

export const useSwitchSelectorStyle = makeStyles((theme) => ({
    switchSelector: {
        display: "grid",
        gridAutoFlow: "column",
        borderRadius: 14,
        border: `1px solid ${blueColor}`,
        boxSizing: "border-box",
        whiteSpace: "nowrap",
    },

    switchSelectorItem: {
        padding: "8px 12px",
        fontSize: 16,
        fontWeight: 500,
        color: blueColor,
        background: lightBlueColor,
        cursor: "pointer",
        transition: "color 0.3s ease, background 0.3s ease",

        "&:first-child": {
            borderTopLeftRadius: "13px",
            borderBottomLeftRadius: "13px",
        },

        "&:last-child": {
            borderTopRightRadius: "13px",
            borderBottomRightRadius: "13px",
        },

        "&[aria-selected='true']": {
            color: lightBlueColor,
            background: blueColor,
        },

        "&[aria-disabled='true']": {
            color: disabledColor,
            background: lightBlueColor,

            "&[aria-selected='true']": {
                color: disabledColor,
                background: lightBlueColor,
            },
        },
    },
}));
