import React from "react";

import { SternumIconProps } from "./SternumIcon.types";

export const ErrorSolidIcon = React.forwardRef<SVGSVGElement, SternumIconProps>(function ErrorSolidIcon(
    { color = "#F93535", height = 21, width = 19, ...props },
    ref
) {
    return (
        <svg
            ref={ref}
            width={width}
            height={height}
            viewBox="0 0 19 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M17.3478 4.0425L11.1365 0.441C10.1222 -0.147 8.86736 -0.147 7.8426 0.441L1.64172 4.0425C0.627407 4.6305 0 5.7225 0 6.909V14.091C0 15.267 0.627407 16.359 1.64172 16.9575L7.85305 20.559C8.86736 21.147 10.1222 21.147 11.1469 20.559L17.3583 16.9575C18.3726 16.3695 19 15.2775 19 14.091V6.909C18.9895 5.7225 18.3621 4.641 17.3478 4.0425ZM8.71051 6.0375C8.71051 5.607 9.06604 5.25 9.49477 5.25C9.9235 5.25 10.279 5.607 10.279 6.0375V11.55C10.279 11.9805 9.9235 12.3375 9.49477 12.3375C9.06604 12.3375 8.71051 11.9805 8.71051 11.55V6.0375ZM10.4568 15.3615C10.4045 15.4875 10.3313 15.603 10.2372 15.708C10.0385 15.9075 9.77711 16.0125 9.49477 16.0125C9.35883 16.0125 9.22289 15.981 9.09741 15.9285C8.96147 15.876 8.85691 15.8025 8.75234 15.708C8.65823 15.603 8.58503 15.4875 8.52229 15.3615C8.47001 15.2355 8.44909 15.099 8.44909 14.9625C8.44909 14.6895 8.55366 14.4165 8.75234 14.217C8.85691 14.1225 8.96147 14.049 9.09741 13.9965C9.48431 13.8285 9.94441 13.923 10.2372 14.217C10.3313 14.322 10.4045 14.427 10.4568 14.5635C10.5091 14.6895 10.5405 14.826 10.5405 14.9625C10.5405 15.099 10.5091 15.2355 10.4568 15.3615Z"
                fill={color || "currentColor"}
            />
        </svg>
    );
});
