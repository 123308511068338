import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

const deviceDefinitionEditModalStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: {},
        stepper: {
            background: "none",
            padding: `${theme.spacing(5)}px ${theme.spacing(5)}px 0 ${theme.spacing(5)}px`,
        },

        selectedStep: {
            color: "#1976d2 !important",
        },
    });

export default deviceDefinitionEditModalStyle;
