import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { History } from "history";
import classNames from "classnames";

import { DashboardProps } from "../../Dashboard.component";
import { GlobalState } from "../../../../lib/state/GlobalState";
import ServiceWire from "../../../../lib/services/ServiceWire";
import { ExpandArrowsIcon, InfoCircleIcon } from "../../../SUI/SternumIcon";
import { Title } from "../Title";
import { useStyles } from "./InteractiveCoverageStyle";
import { useCommonStyle } from "../../../CommonStyle";
import { InteractiveCoveragePlot } from "./InteractiveCoveragePlot";
import monitoringData from "../../../InteractiveCoverageDialog/monitoring.json";
import realtimeData from "../../../InteractiveCoverageDialog/realtime.json";
import { Box, IconButton, Tooltip, Typography } from "@material-ui/core";
import { openInteractiveCoverageModal } from "../../../../lib/redux/modals/OpenModalAction";
import { closeModalAction } from "../../../../lib/redux/modals/CloseModalAction";
import ModalKey from "../../../../lib/state/ModalKey";
import ModalType from "../../../../lib/state/ModalType";

export interface InteractiveCoverageProps {}

const mapStateToProps = (state: GlobalState, ownProps: DashboardProps) => {
    const selectedClient = ServiceWire.getClientsService().getSelectedClient();

    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        openInteractiveCoverageModal: (key: string, plot?, category?) =>
            dispatch(openInteractiveCoverageModal(key, plot, category)),
        closeModal: (key: string) => dispatch(closeModalAction(new ModalKey(ModalType.InteractiveCoverageModal, key))),
    };
};

type InteractiveCoveragePropsWithHOC = InteractiveCoverageProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
        history: History;
    };

function InteractiveCoverageComponent({ openInteractiveCoverageModal, closeModal }: InteractiveCoveragePropsWithHOC) {
    const classes = useStyles();
    const commonClasses = useCommonStyle();

    const handleExpandClick = () => {
        openInteractiveCoverageModal("securityCoverage");
    };

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div>
                    <Box display="flex" alignItems="center" gridColumnGap={5}>
                        <Title>Sternum Exploitation Fingerprint Coverage</Title>
                        <Tooltip
                            classes={{ tooltip: classes.infoTooltip }}
                            title={
                                <div>
                                    Prevention CWE's are entire major categories of vulnerability types (consisting of
                                    many sub-categories) that Sternum blocks in real-time, zero-days and 1-days.
                                    <br />
                                    <br />
                                    Detection CWE's are those that are alerted on in real-time. Click an area on the
                                    wheel to filter by CWE type or hover over a CWE to see more information.
                                    <br />
                                    <br />
                                    Please note: The wheel does not include many sub-categories that are also supported
                                    by Sternum.
                                </div>
                            }
                        >
                            <Box display="inline-flex">
                                <InfoCircleIcon width={18} height={18} color="#1B6FDE" />
                            </Box>
                        </Tooltip>
                    </Box>
                    <div
                        className={classNames(
                            classes.descriptionText,
                            commonClasses.marginTop,
                            commonClasses.marginBottom
                        )}
                    >
                        Aligned with MITRE Common Weakness Enumeration, Sternum fingerprints a wide range of top
                        threats.
                    </div>
                </div>

                <IconButton onClick={handleExpandClick}>
                    <ExpandArrowsIcon />
                </IconButton>
            </div>

            <div className={classes.graphsContainer}>
                <div className={classes.graph}>
                    <InteractiveCoveragePlot
                        data={monitoringData}
                        onCategoryClick={(category) => {
                            openInteractiveCoverageModal("securityCoverage", "prevention", null);
                        }}
                    />
                    <Typography className={classes.graphTitle}>Prevention</Typography>
                </div>
                <div className={classes.graph}>
                    <InteractiveCoveragePlot
                        data={realtimeData}
                        onCategoryClick={(category) => {
                            openInteractiveCoverageModal("securityCoverage", "detection", null);
                        }}
                    />
                    <Typography className={classes.graphTitle}>Detection</Typography>
                </div>
            </div>
        </div>
    );
}

export const InteractiveCoverage: React.FC<InteractiveCoverageProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(InteractiveCoverageComponent));
