import { Action } from "redux";

import ActionType from "../ActionType";
import { DashboardOnboardingStepState } from "../../state/DashboardRegularState";

// Set Onboarding state
export interface SetDashboardOnboardingStateActionFields {
    isVisible?: boolean;
    stepsState: Partial<DashboardOnboardingStepState>;
}

export interface SetDashboardOnboardingStateAction extends Action<ActionType>, SetDashboardOnboardingStateActionFields {
    type: ActionType.DashboardRegularSetOnboardingState;
}

export function setDashboardOnboardingStateAction(
    action: SetDashboardOnboardingStateActionFields
): SetDashboardOnboardingStateAction {
    return {
        type: ActionType.DashboardRegularSetOnboardingState,
        stepsState: action.stepsState,
        isVisible: action.isVisible,
    };
}

// Set Onboarding state - in progress
export interface SetDashboardOnboardingStateInProgressAction extends Action<ActionType> {
    type: ActionType.DashboardRegularSetOnboardingStateInProgress;
}

export function setDashboardOnboardingStateInProgressAction(): SetDashboardOnboardingStateInProgressAction {
    return {
        type: ActionType.DashboardRegularSetOnboardingStateInProgress,
    };
}

// Set Onboarding state - successfully
export interface SetDashboardOnboardingStateSuccessfullyActionFields {
    isVisible: boolean;
    stepsState: Partial<DashboardOnboardingStepState>;
}

export interface SetDashboardOnboardingStateSuccessfullyAction
    extends Action<ActionType>,
        SetDashboardOnboardingStateSuccessfullyActionFields {
    type: ActionType.DashboardRegularSetOnboardingStateSuccessfully;
}

export function setDashboardOnboardingStateSuccessfullyAction(
    action: SetDashboardOnboardingStateSuccessfullyActionFields
): SetDashboardOnboardingStateSuccessfullyAction {
    return {
        type: ActionType.DashboardRegularSetOnboardingStateSuccessfully,
        isVisible: action.isVisible,
        stepsState: action.stepsState,
    };
}

// Set Onboarding state - error
export interface SetDashboardOnboardingStateErrorAction extends Action<ActionType> {
    type: ActionType.DashboardRegularSetOnboardingStateError;
}

export function setDashboardOnboardingStateErrorAction(): SetDashboardOnboardingStateErrorAction {
    return {
        type: ActionType.DashboardRegularSetOnboardingStateError,
    };
}
