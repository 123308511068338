import * as React from "react";
import Typography from "@material-ui/core/Typography";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import entityFilterPopoverStyle from "./EntityFilterPopoverStyle";
import EntityType from "../../lib/state/EntityType";
import SternumQuery from "../../lib/state/SternumQuery";
import SternumQueryEditor from "../SternumQueryEditor/SternumQueryEditor";
import QueryQuantifierType from "../../lib/state/QueryQuantifierType";
import SternumQueryField from "../../lib/infra/SternumQueryField";
import FieldType from "../../lib/state/FieldType";
import SternumQueryInputValueConfiguration from "../../lib/infra/SternumQueryInputValueConfiguration";
import InputComponentType from "../../lib/state/InputComponentType";
import { uniqueId } from "lodash";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    sternumQuery: SternumQuery;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof entityFilterPopoverStyle> {
    theme?;

    entityType: EntityType;
    sternumQuery: SternumQuery;
    onSternumQueryApplied: (sternumQuery: SternumQuery) => void;
}

/**
 * Popover content for view columns.
 */
class EntityFilterPopover extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            sternumQuery: this.props.sternumQuery
                ? this.props.sternumQuery
                : new SternumQuery(uniqueId(), QueryQuantifierType.ALL, this.props.sternumQuery.filters, []),
        };
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        return (
            <div>
                {/* Filter popover title */}
                <Typography variant={"caption"} className={classes.titleTypography}>
                    Filter
                </Typography>

                {/* Query editor */}
                <SternumQueryEditor
                    fields={[
                        new SternumQueryField(
                            "usedLibraryId",
                            "usedLibraryId",
                            "Used Library Id",
                            FieldType.STRING,
                            new SternumQueryInputValueConfiguration(FieldType.STRING, InputComponentType.TEXT)
                        ),
                        new SternumQueryField(
                            "name",
                            "name",
                            "Name",
                            FieldType.STRING,
                            new SternumQueryInputValueConfiguration(FieldType.STRING, InputComponentType.TEXT)
                        ),
                    ]}
                    sternumQuery={this.props.sternumQuery}
                    onSternumQueryChanged={(sternumQuery) => this.onSternumQueryChanged(sternumQuery)}
                />

                {/* Apply & Reset buttons */}
                <div className={classes.resetAndApplyButtons}>
                    {/* Apply */}
                    <Button
                        size={"small"}
                        variant={"contained"}
                        color={"primary"}
                        className={classes.applyButton}
                        onClick={(event) => this.handleFilterApplied()}
                    >
                        Apply
                    </Button>

                    {/* Reset */}
                    <Button size={"small"} variant={"contained"} onClick={(event) => this.handleFilterReset()}>
                        Reset
                    </Button>
                </div>
            </div>
        );
    }

    /**
     * Occurs once the query is changed.
     */
    private onSternumQueryChanged(sternumQuery: SternumQuery) {
        this.setState({
            sternumQuery: sternumQuery,
        });
    }

    /**
     * Occurs once the user wants to apply the filter.
     */
    private handleFilterApplied() {
        if (this.props.onSternumQueryApplied) {
            this.props.onSternumQueryApplied(this.state.sternumQuery.getCleanQuery());
        }
    }

    /**
     * Occurs on reset of filters requested.
     */
    private handleFilterReset() {
        this.setState({
            sternumQuery: SternumQuery.getEmptyQuery(),
        });
    }
}

export default withStyles(entityFilterPopoverStyle, { withTheme: true })(EntityFilterPopover);
