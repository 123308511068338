import * as React from "react";

import classNames from "classnames";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import libraryHistoriesModalStyle from "./LibraryHistoriesModalStyle";
import { GlobalState } from "../../lib/state/GlobalState";
import { connect } from "react-redux";
import { Typography } from "@material-ui/core";
import LibraryInfo from "../../lib/state/LibraryInfo";
import LibraryHistoriesList from "../LibraryHistoriesList/LibraryHistoriesList";
import LibraryHistoriesFilter from "../../lib/state/LibraryHistoriesFilter";
import HashSet from "../../lib/infra/HashSet";

/**
 * Holds the inner state for our app.
 */
interface AppState {}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof libraryHistoriesModalStyle> {
    library: LibraryInfo;
    fullScreenDisplay: boolean;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {};
};

/**
 * Displays a bar of metrics.
 */
class LibraryHistoriesModal extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {};
    }

    /**
     * Occurs once the component is mounted.
     * We fetch the library if needed here.
     */
    async componentDidMount() {}

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        return (
            <div>
                {/* Title */}
                <div className={classNames(classes.upperContentContainer, classes.marginBottom)}>
                    <Typography variant={"h5"}>Update history for {this.props.library.name}</Typography>
                </div>

                {/* History list */}
                <LibraryHistoriesList
                    viewedColumnsSet={HashSet.fromValues(["libraryVersion", "libraryUpdated", "historyDate"])}
                    libraryHistoriesFilter={new LibraryHistoriesFilter(this.props.library.entityId)}
                    paperClassNames={classNames(
                        classes.listPaper,
                        this.props.fullScreenDisplay ? "mod-fullscreen" : "mod-regular"
                    )}
                />
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(libraryHistoriesModalStyle)(LibraryHistoriesModal));
