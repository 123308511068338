import ServiceWire from "./ServiceWire";
import SternumService from "./SternumService";
import AnalyticsService from "./AnalyticsService";
import MetricInfo from "../state/MetricInfo";

export interface DeviceMetrics {
    totalDevices: number;
}

class DevicesMetricsPollingService {
    private sternumService: SternumService;
    private pollingTimeout;
    private pollingActive: boolean;
    private lastPollingTime: number;
    private subscriberMap: { [k: string]: (data: DeviceMetrics) => unknown } = {};
    private static POLLING_INTERVAL_MILLIS: number = 5000;
    private availableMetrics: DeviceMetrics | null = null;
    private requestPending = false;

    constructor(sternumService: SternumService) {
        this.sternumService = sternumService;
    }

    public startPolling(): void {
        if (!this.pollingActive) {
            this.pollingActive = true;
            this.poll();
            this.startPollingTimeout();
        }
    }

    public stopPolling(): void {
        if (this.pollingActive) {
            this.pollingActive = false;

            if (this.pollingTimeout) {
                clearTimeout(this.pollingTimeout);
            }
        }
    }

    public subscribe(subscriptionId: string, cb: (data: DeviceMetrics) => unknown, refreshPolling = false): void {
        if (this.subscriberMap[subscriptionId]) {
            return;
        }

        this.subscriberMap[subscriptionId] = cb;
        if (this.availableMetrics) {
            cb(this.availableMetrics);
        }

        if (refreshPolling) {
            this.stopPolling();
            this.startPolling();
        }
    }

    public unsubscribe(subscriptionId: string): void {
        delete this.subscriberMap[subscriptionId];
    }

    public clearAllSubscriptions() {
        this.subscriberMap = {};
    }

    private parseMetricsData(metricsData: MetricInfo[]): DeviceMetrics {
        const totalDevicesMetric = metricsData.filter((m) => m["metricId"] === "TOTAL_DEVICES")[0];
        const totalDevices = +totalDevicesMetric?.["metricValue"];

        return { totalDevices };
    }

    private startPollingTimeout(): void {
        this.pollingTimeout = setTimeout(async () => {
            this.poll();

            this.startPollingTimeout();
        }, DevicesMetricsPollingService.POLLING_INTERVAL_MILLIS);
    }

    private async poll() {
        if (this.requestPending) return;

        this.lastPollingTime = new Date().getTime();
        this.requestPending = true;

        if (Object.keys(this.subscriberMap).length > 0) {
            let metricsData = null;
            try {
                metricsData = await this.sternumService.getDevicesMetrics(
                    ServiceWire.getClientsService().getSelectedClientId()
                );
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    // Stop polling if we get 401
                    this.stopPolling();
                    // Force refresh, redirects the user to login page
                    window.location.reload();
                }
                // Else, we swallow the exception to allow polling to re-iterate.
            }

            // First, we handle the data received from the polling.
            if (metricsData) {
                try {
                    this.availableMetrics = this.parseMetricsData(metricsData);
                    this.handlePollingData(this.availableMetrics);
                } catch (error) {
                    // Failed to run callback function. Report the error.
                    AnalyticsService.error("DevicesMetricsPollingService:startPollingTimeout", error.message);
                }
            }
        }

        this.requestPending = false;
    }

    /**
     * Responsible for handling the actual polling data.
     */
    private handlePollingData(metricsData: DeviceMetrics): void {
        for (const [_, subscription] of Object.entries(this.subscriberMap)) {
            subscription(metricsData);
        }
    }
}

export default DevicesMetricsPollingService;
