import React, { useRef, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import classNames from "classnames";
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";

import { GlobalState } from "../../../../lib/state/GlobalState";
import { CategoryGraphView, CategoryTimeRange } from "../../../../lib/state/DashboardRegularState";
import {
    resetAlertsFilterAction,
    setCategoryGraphViewAction,
    setCategoryTimeRangeAction,
    setCategoryTimeRangeCustomAction,
    setIsOnlyAnomalyInsightsAction,
    setIsOnlyUnresolvedAction,
} from "../../../../lib/redux/dashboardRegular";
import SternumSwitch from "../../../SUI/SternumSwitch/SternumSwitch";
import { SwitchSelector } from "../../../SUI/SwitchSelector";
import { useCategoryActionsStyle } from "./CategoryActions.style";
import { CalendarIcon } from "../../../SUI/SternumIcon";
import { SternumTooltip } from "../../../SUI/SternumTooltip";
import { SelectTimeRangePopover } from "../SelectTimeRangePopover";
import { Dropdown } from "./Dropdown";

export interface CategoryActionsProps {}

const mapStateToProps = (state: GlobalState, ownProps: CategoryActionsProps) => {
    return {
        isOnlyUnresolved: state.dashboardRegular.isOnlyUnresolved,
        isOnlyAnomalyInsights: state.dashboardRegular.isOnlyAnomalyInsights,
        selectedGraphView: state.dashboardRegular.selectedGraphView,
        categoryTimeRange: state.dashboardRegular.categoryTimeRange,
        categoryTimeRangeCustomFrom: state.dashboardRegular.categoryTimeRangeCustomFrom,
        categoryTimeRangeCustomTo: state.dashboardRegular.categoryTimeRangeCustomTo,
        isAlertTableExpanded: state.dashboardRegular.isAlertTableExpanded,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setIsOnlyUnresolved: (isOnlyUnresolved: boolean) => dispatch(setIsOnlyUnresolvedAction(isOnlyUnresolved)),
        setIsOnlyAnomalyInsights: (value: boolean) => dispatch(setIsOnlyAnomalyInsightsAction(value)),
        setCategoryGraphView: (categoryGraphView: CategoryGraphView) =>
            dispatch(setCategoryGraphViewAction(categoryGraphView)),
        setCategoryTimeRange: (categoryTimeRange: CategoryTimeRange) =>
            dispatch(setCategoryTimeRangeAction(categoryTimeRange)),
        setCategoryTimeRangeCustom: ({ from, to }: { from?: Date; to?: Date }) =>
            dispatch(setCategoryTimeRangeCustomAction({ from, to })),
        resetAlertsFilter: () => dispatch(resetAlertsFilterAction()),
    };
};

type CategoryActionsPropsWithHOC = CategoryActionsProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export function CategoryActionsComponent({
    isOnlyUnresolved,
    setIsOnlyUnresolved,
    isOnlyAnomalyInsights,
    setIsOnlyAnomalyInsights,
    selectedGraphView,
    setCategoryGraphView,
    categoryTimeRange,
    categoryTimeRangeCustomFrom,
    categoryTimeRangeCustomTo,
    setCategoryTimeRange,
    setCategoryTimeRangeCustom,
    resetAlertsFilter,
    isAlertTableExpanded,
}: CategoryActionsPropsWithHOC) {
    const dropdownRef = useRef();

    const classes = useCategoryActionsStyle();

    const [backupTimeRange, setBackupTimeRange] = useState<
        | {
              categoryTimeRange: CategoryTimeRange;
              from?: Date;
              to?: Date;
          }
        | undefined
    >();
    const [isCustomTimeRangeOpen, setIsCustomTimeRangeOpen] = useState(false);

    const textByCategoryTimeRange: Record<CategoryTimeRange, string> = {
        [CategoryTimeRange.LastWeek]: "Last week",
        [CategoryTimeRange.LastMonth]: "Last month",
        [CategoryTimeRange.LastYear]: "Last year",
        [CategoryTimeRange.TimeRange]: "Custom",
    };

    const isOnlyUnresolvedDisabled = false; // TODO: Add condition

    const handleChangeSelectedGraphView = (newSelectedGraphView: CategoryGraphView) => {
        setCategoryGraphView(newSelectedGraphView);
    };

    const handleChangeSelectedCategoryTime = (newCategoryTimeRange: CategoryTimeRange) => {
        if (newCategoryTimeRange === CategoryTimeRange.TimeRange) {
            setIsCustomTimeRangeOpen(true);
            setBackupTimeRange({
                categoryTimeRange: categoryTimeRange,
                from: categoryTimeRangeCustomFrom,
                to: categoryTimeRangeCustomTo,
            });

            return;
        }

        if (newCategoryTimeRange === categoryTimeRange) {
            return;
        }

        setCategoryTimeRange(newCategoryTimeRange);
        resetAlertsFilter();
    };

    const handleCloseTimeRangeSelection = () => {
        setCategoryTimeRange(backupTimeRange.categoryTimeRange);
        setCategoryTimeRangeCustom({ from: backupTimeRange.from, to: backupTimeRange.to });

        setBackupTimeRange(undefined);
        setIsCustomTimeRangeOpen(false);
    };

    const handleSetCustomRange = ({ from, to }: { from: Date; to: Date }) => {
        setCategoryTimeRange(CategoryTimeRange.TimeRange);
        setCategoryTimeRangeCustom({ from, to });
        resetAlertsFilter();

        setBackupTimeRange(undefined);
        setIsCustomTimeRangeOpen(false);
    };

    const renderSelectedTimeRange = (categoryTimeRange: CategoryTimeRange) => {
        if (!isCustomTimeRangeOpen && categoryTimeRange === CategoryTimeRange.TimeRange) {
            const from = moment(categoryTimeRangeCustomFrom).format("MMMM Do, h:mm:ss a");
            const to = moment(categoryTimeRangeCustomTo).format("MMMM Do, h:mm:ss a");
            const displayedValue = `${from} - ${to}`;

            return (
                <SternumTooltip title={displayedValue}>
                    <div className={classNames(classes.checkboxSelectedView, classes.checkboxSelectedWithOverflowView)}>
                        <CalendarIcon className={classes.calendarIcon} />
                        {textByCategoryTimeRange[categoryTimeRange]}
                    </div>
                </SternumTooltip>
            );
        }

        return (
            <div className={classes.checkboxSelectedView}>
                <CalendarIcon className={classes.calendarIcon} />
                {textByCategoryTimeRange[categoryTimeRange]}
            </div>
        );
    };

    return (
        <div className={classes.root} role="presentation" aria-label="dashboard category actions">
            {selectedGraphView !== CategoryGraphView.Map && (
                <>
                    <div className={classes.separator} />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isOnlyAnomalyInsights}
                                style={{ color: "#1B6FDE", backgroundColor: "#fff" }}
                                onChange={(e) => {
                                    const checked = e.target.checked;
                                    setIsOnlyAnomalyInsights(checked);
                                }}
                            />
                        }
                        label={<Typography variant="body2">Only AI Insights</Typography>}
                    />
                    <div className={classes.separator} />
                    <div className={classes.onlyUnresolved} aria-disabled={isOnlyUnresolvedDisabled}>
                        Only unresolved
                        <SternumSwitch
                            disabled={isOnlyUnresolvedDisabled}
                            color="secondary"
                            checked={isOnlyUnresolved}
                            onChange={() => setIsOnlyUnresolved(!isOnlyUnresolved)}
                        />
                    </div>
                    <div className={classes.separator} />
                    <div ref={dropdownRef}>
                        <Dropdown
                            role="presentation"
                            aria-label="dropdown - choose category time range"
                            selectedCase={isCustomTimeRangeOpen ? CategoryTimeRange.TimeRange : categoryTimeRange}
                            onChangeSelectedCase={handleChangeSelectedCategoryTime}
                            renderSelectedCase={renderSelectedTimeRange}
                        >
                            <Dropdown.Item id={CategoryTimeRange.LastWeek}>Last week</Dropdown.Item>
                            <Dropdown.Item id={CategoryTimeRange.LastMonth}>Last month</Dropdown.Item>
                            <Dropdown.Item id={CategoryTimeRange.LastYear}>Last year</Dropdown.Item>
                            <Dropdown.Item id={CategoryTimeRange.TimeRange}>Select range</Dropdown.Item>
                        </Dropdown>
                    </div>

                    <SelectTimeRangePopover
                        anchorElRef={dropdownRef}
                        isOpen={isCustomTimeRangeOpen}
                        timeFrom={categoryTimeRangeCustomFrom}
                        timeTo={categoryTimeRangeCustomTo}
                        onCloseTimeRangeSelection={handleCloseTimeRangeSelection}
                        onSetTimeRange={handleSetCustomRange}
                    />
                </>
            )}
            {!isAlertTableExpanded && (
                <>
                    <SwitchSelector
                        role="presentation"
                        aria-label="switch selector - category graph view"
                        className={classes.switchSelectorGraphView}
                        selectedCase={selectedGraphView}
                        onChangeSelectedCase={handleChangeSelectedGraphView}
                    >
                        <SwitchSelector.Item id={CategoryGraphView.Categories}>By Categories</SwitchSelector.Item>
                        <SwitchSelector.Item id={CategoryGraphView.OverTime}>Over time</SwitchSelector.Item>
                    </SwitchSelector>
                    <SwitchSelector
                        role="presentation"
                        aria-label="switch selector - category graph map view"
                        className={classes.switchSelectorGraphView}
                        selectedCase={selectedGraphView}
                        onChangeSelectedCase={handleChangeSelectedGraphView}
                    >
                        <SwitchSelector.Item id={CategoryGraphView.Map}>Map</SwitchSelector.Item>
                    </SwitchSelector>
                </>
            )}
        </div>
    );
}

export const CategoryActions: React.FC<CategoryActionsProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(CategoryActionsComponent);
