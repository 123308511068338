import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../../CommonStyle";

const singleDashboardPageStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: {
            position: "relative",
        },
        redirectLink: {
            cursor: "pointer",
        },
    });

export default singleDashboardPageStyle;
