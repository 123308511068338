import { makeStyles } from "@material-ui/core";
import commonStyle from "../../CommonStyle";

const useSternumCopyMenuStyles = makeStyles((theme) => {
    return {
        ...commonStyle(theme),
        menuItem: {
            height: theme.spacing(2.5),
        },
        iconExtraSmallSize: {
            fontSize: theme.spacing(3),
        },
        menuItemTopBottomPadding: {
            paddingTop: 5,
            paddingBottom: 5,
        },

        menuItemLeftRightPadding: {
            paddingRight: 10,
            paddingLeft: 10,
        },
    };
});

export default useSternumCopyMenuStyles;
