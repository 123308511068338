import React from "react";
import classNames from "classnames";
import { DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@material-ui/core";

import { CheckOutlinedIcon, CrossIcon, CrossOutlinedIcon } from "../../SUI/SternumIcon";
import { FeedbackResponseStatus } from "../types";
import { useFeedbackResponseStyle } from "./FeedbackResponse.style";
import { useFeedbackDialogStyle } from "../FeedbackDialog.style";
import SternumLink from "../../SUI/SternumLink/SternumLink";

export interface FeedbackResponseProps {
    responseStatus: FeedbackResponseStatus;
    onClose?: () => unknown;
    onTryAgain?: () => unknown;
    className?: string;
}

export function FeedbackResponse({ responseStatus, onClose, onTryAgain, className }: FeedbackResponseProps) {
    const classes = useFeedbackResponseStyle();
    const classesDialog = useFeedbackDialogStyle();

    if (responseStatus === FeedbackResponseStatus.Success) {
        return (
            <div className={className}>
                <DialogTitle id="dialog-title">
                    <div className={classNames(classesDialog.title)}>
                        <div />
                        <IconButton onClick={onClose}>
                            <CrossIcon color="#909090" />
                        </IconButton>
                    </div>
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    <CheckOutlinedIcon width={60} height={60} />
                    <Typography className={classes.statusTitle}>Thank you!</Typography>
                    <Typography className={classes.statusSubTitle}>Your message has been sent</Typography>
                </DialogContent>

                <DialogActions className={classNames(classesDialog.actions, classes.actions)}>
                    <SternumLink className={classes.linkButton} onClick={onClose}>
                        Close
                    </SternumLink>
                </DialogActions>
            </div>
        );
    }

    return (
        <div className={className}>
            <DialogTitle id="dialog-title">
                <div className={classNames(classesDialog.title)}>
                    <div />
                    <IconButton onClick={onClose}>
                        <CrossIcon color="#909090" />
                    </IconButton>
                </div>
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>
                <CrossOutlinedIcon width={60} height={60} />
                <Typography className={classes.statusTitle}>
                    Oooops!
                    <br />
                    Something went wrong
                </Typography>
                <Typography className={classes.statusSubTitle}>Please, try again later</Typography>
            </DialogContent>

            <DialogActions className={classNames(classesDialog.actions, classes.actions)}>
                <SternumLink className={classes.linkButton} onClick={onTryAgain}>
                    Try again
                </SternumLink>
            </DialogActions>
        </div>
    );
}
