import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4SliceGrouperPlugin from "@amcharts/amcharts4/plugins/sliceGrouper";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import _ from "lodash";
import React, { useEffect, useLayoutEffect, useRef } from "react";
import { GraphData } from "../../../lib/infra/GraphUtils";

export interface SternumBarChartProps {
    id: string;
    data?: GraphData;
    height?: number | string;
    tooltipText?: string;
    tooltipCallback?: TooltipCallback;
}

type TooltipCallback = (value: string, target: am4core.Slice) => string;

am4core.useTheme(am4themes_animated);
am4core.options.autoDispose = true;

function SternumPieChart({ id, tooltipText, height = 220, tooltipCallback, ...props }: SternumBarChartProps) {
    const myChart = useRef<am4charts.PieChart>(null);

    if (!tooltipText) {
        tooltipText = "{category}: <b>{value}</b>";
    }

    useLayoutEffect(() => {
        let chart = am4core.create(id, am4charts.PieChart);

        chart.fontFamily = '"Inter", "Roboto", "Helvetica", "Arial", sans-serif';
        chart.fontSize = 12;
        chart.paddingLeft = 0;
        chart.paddingRight = 0;

        chart.innerRadius = am4core.percent(40);

        let series = chart.series.push(new am4charts.PieSeries());
        series.name = "piechart";
        // series.alignLabels = false;
        series.labels.template.maxWidth = 130;
        series.labels.template.wrap = true;

        series.dataFields.value = "count";
        series.dataFields.category = "label";
        series.slices.template.propertyFields.fill = "color";

        series.tooltip.tooltipHTML = tooltipText;
        series.tooltip.label.interactionsEnabled = true;
        series.tooltip.keepTargetHover = true;
        series.tooltip.getFillFromObject = false;
        series.tooltip.background.fill = am4core.color("#000");
        series.tooltip.background.stroke = null;
        series.tooltip.background.cornerRadius = 8;
        series.tooltip.background.filters.clear();
        series.tooltip.label.padding(9, 13, 9, 13);
        series.tooltip.zIndex = 10;

        const grouper = series.plugins.push(new am4SliceGrouperPlugin.SliceGrouper());
        grouper.clickBehavior = "zoom";
        grouper.threshold = 5;

        if (tooltipCallback) {
            series.slices.template.adapter.add("tooltipHTML", tooltipCallback);
        }

        myChart.current = chart;

        return () => {
            chart.dispose();
        };
    }, []);

    useEffect(() => {
        const data = [];

        props.data.datasets.forEach((dataset, index) => {
            if (dataset.multiColumns) {
                Object.keys(dataset.data.values).forEach((label) => {
                    data.push({
                        count: dataset.data.values[label],
                        label,
                        ...dataset.data,
                    });
                });
            } else {
                data.push({
                    label: props.data.labels[index],
                    count: dataset.data.value,
                    color: dataset.backgroundColor,
                    ...dataset.data,
                });
            }
        });

        myChart.current.data = data;
    }, [props.data]);

    return <div id={id} style={{ width: "100%", height }} />;
}

export default React.memo(SternumPieChart, (prevProps, nextProps) => {
    if (!_.isEqual(prevProps.data.labels, nextProps.data.labels)) {
        return false;
    }

    if (!_.isEqual(prevProps.data.datasets, nextProps.data.datasets)) {
        return false;
    }

    return true;
});
