import { makeStyles } from "@material-ui/core";

let useApiKeyGenerationStyle = makeStyles((theme) => ({
    titleWithChip: {
        display: "flex",
        alignItems: "center",
        gap: 8,
    },

    apiKeyContainer: {
        maxWidth: 450,
        marginTop: 5,
        padding: theme.spacing(4, 3),
        border: "1px solid #E4E4E4",
        borderRadius: 14,
    },

    disabledLink: {
        color: "#F980A9",
    },

    inactiveColor: {
        color: "#ACB4BD !important",
    },

    docsLink: {
        color: theme.palette.secondary.dark,
    },

    noPadding: {
        padding: "0 !important",
    },
}));

export default useApiKeyGenerationStyle;
