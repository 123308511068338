import { makeStyles } from "@material-ui/core";

export const useProcessExplorerStyles = makeStyles((theme) => ({
    container: {
        margin: "4px 18px 0 18px",
    },
    sternumDeviceProcessesList: {
        "& table thead th": {
            color: "#555B61",
            fontSize: 14,
            fontWeight: 600,

            "&:nth-child(2)": {
                width: "100%",
            },
        },

        "& table tbody td, & table tbody td > p": {
            fontSize: 16,
            fontWeight: 400,
        },

        "& table th, & table td": {
            padding: "15px 18px",
            color: "#8B949E",
        },

        "& table th:nth-child(1), & table td:nth-child(1)": {
            padding: "15px 4px",
            color: "#999999",
        },

        "& table th:nth-child(3), & table td:nth-child(3)": {
            padding: "15px 4px",
            color: "#999999",
            textAlign: "right",
        },
    },
}));
