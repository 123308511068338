import { Action } from "redux";
import ActionType from "../ActionType";

/**
 * Defines the data for a cve not being viewed anymore action.
 */
export interface CveNotViewedAction extends Action<ActionType> {
    type: ActionType.CveNotViewed;
}

/**
 * Action creator for cve not being viewed anymore.
 */
export function cveNotViewedAction(): CveNotViewedAction {
    return {
        type: ActionType.CveNotViewed,
    };
}
