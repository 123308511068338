import { createTheme, MuiThemeProvider } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import * as React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import App from "./components/App";
import GlobalCss from "./globalCss/GlobalCss";
import store from "./lib/redux/store";
import * as am4core from "@amcharts/amcharts4/core";
import { displayVersion } from "./utils/version";

// make this function global so that QAs can use it from DevTools
Object.defineProperty(window, "version", { value: displayVersion });

am4core.options.autoSetClassName = true;

const rootEl = document.getElementById("root");

const theme = createTheme({
    palette: {
        primary: {
            light: blue[500],
            main: blue[900],
            dark: blue[900],
            contrastText: "#fff",
        },
        secondary: {
            main: "#E7004C", // red main
            dark: "#EE3B66", // primary/darkest_pink
        },
    },
    typography: {
        fontFamily: `"Inter", "Roboto", "Helvetica", "Arial", sans-serif`,
    },
    spacing: 5,
});

render(
    <Provider store={store}>
        <MuiThemeProvider theme={theme}>
            <GlobalCss />
            <App />
        </MuiThemeProvider>
    </Provider>,
    rootEl
);
