import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../../../components/CommonStyle";

const sternumEntitySelectStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        selectComponent: {
            height: theme.spacing(10),
        },
    });

export default sternumEntitySelectStyle;
