import { Theme } from "@material-ui/core/styles";
import commonStyle from "../../../CommonStyle";
import { sternumTheme } from "../../../App";
import createStyles from "@material-ui/core/styles/createStyles";

const sternumTableBodyStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        tableRowHover: {
            "&:hover": {
                backgroundColor: `${sternumTheme.lightGrey.main} !important`,
                cursor: "pointer" as "pointer",
            },
        },
        loadingPlaceholderCell: {
            borderBottom: "0",
        },

        rootRow: {
            height: theme.spacing(6),
        },
    });

export default sternumTableBodyStyle;
