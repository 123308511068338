import { Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { usePagerStyles } from "./Pager.style";

export interface PagerProps {
    total: number;
    page: number;
    onPageChange: (page: number) => unknown;
}

export function Pager({ page, total, onPageChange }: PagerProps) {
    const style = usePagerStyles();

    return (
        <div className={style.dots}>
            <Typography className={style.selectedPage}>
                {page + 1}/{total}
            </Typography>
            {Array(total)
                .fill(null)
                .map((_, i) => (
                    <div
                        key={i}
                        className={clsx(style.dot, { [style.dotActive]: i === page })}
                        onClick={() => onPageChange(i)}
                    />
                ))}
        </div>
    );
}
