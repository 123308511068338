import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { sternumTheme } from "../../App";
import commonStyle from "../../CommonStyle";

const dashboardsBrowserStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: {
            padding: 20,
        },

        titleContainer: {
            marginBottom: "30px",
        },

        title: {
            fontSize: "34px",
            fontWeight: 500,
        },

        dashboardTitleContainer: {
            display: "flex",
            alignItems: "center",
        },

        dashboardTitleIcon: {
            marginRight: "8px",
            color: theme.palette.primary.main,
        },

        dashboardsContainer: {
            display: "grid",
            alignItems: "center",
            gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
            columnGap: "20px",
            rowGap: "20px",
        },

        deviceProfileNameText: {
            color: "#9ca6b2",
            fontSize: "0.8125rem",
        },

        dashboardContainer: {
            borderRadius: "14px",
            height: "130px",
            padding: "20px",
            cursor: "pointer",
            background: "#fff",
            boxShadow: "0px 0px 23px rgba(231, 232, 235, 0.25)",

            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",

            "&:hover": {
                border: "1px solid #cdd2d8",
            },

            "&.mod-minified": {
                height: "100px",
            },

            "&.mod-add-new": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                marginRight: 0,
            },
            "&.mod-selected": {
                border: "1px solid #E7004C",
            },
        },

        addDashboardText: {
            fontSize: "18px",
            fontWeight: "bold",
            marginLeft: "5px",
        },

        glaceIcon: {
            fontSize: "22px",
            fontWeight: "bold",
            color: "#E3014B",
        },

        dashboardBoxFooter: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "16px",
        },

        dashboardCreatorName: {
            fontSize: "0.8125rem",
        },

        dashboardTitle: {
            fontWeight: 500,
            fontSize: "16px",

            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },

        errorText: {
            color: sternumTheme.danger.main,
        },

        predefinedDashboardChip: {
            background: "#EEF1FF",
            color: "#1555AB",
            fontWeight: 400,
        },
    });

export default dashboardsBrowserStyle;
