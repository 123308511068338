import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

const mixedUsersListStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        paperClass: {
            overflowY: "auto" as "auto",
        },
        linkText: {
            fontSize: "0.8125rem",
            fontWeight: 400,
            marginRight: theme.spacing(1),
            "&:hover": {
                textDecoration: "underline",
            },
        },
        linkIcon: {
            fontSize: "0.8125rem",
            paddingRight: theme.spacing(3),
        },
        emailWidth: {
            width: "16vw",
        },
    });

export default mixedUsersListStyle;
