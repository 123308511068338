import { Action } from "redux";
import ActionType from "../ActionType";
import FetchError from "../../state/FetchError";

/**
 * Occurs once there has been an error fetching a used library from API.
 */
export interface ErrorFetchingUsedLibraryAction extends Action<ActionType> {
    type: ActionType.ErrorFetchingUsedLibrary;
    usedLibraryId: string;
    error: FetchError;
}

/**
 * Occurs once there has been an error fetching a used library from API.
 */
export function errorFetchingUsedLibraryAction(
    usedLibraryId: string,
    error: FetchError
): ErrorFetchingUsedLibraryAction {
    return {
        type: ActionType.ErrorFetchingUsedLibrary,
        usedLibraryId: usedLibraryId,
        error: error,
    };
}
