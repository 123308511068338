import TableRowData from "./TableRowData";
import DeviceInfo from "./DeviceInfo";
import EntityBase from "./EntityBase";
import EntityType from "./EntityType";
import DeviceProcessInfo from "./DeviceProcessInfo";

/**
 * Represents a device process instance in sternum.
 */
class DeviceProcessInstanceInfo extends EntityBase implements TableRowData {
    /**
     * Constructor.
     */
    constructor(
        public deviceProcessInstanceId: string,
        public deviceProcessId: string,
        public deviceId: string,
        public clientId: string,
        public created: number,
        public updated: number,
        public processId: string,
        public device?: DeviceInfo,
        public deviceProcess?: DeviceProcessInfo
    ) {
        super(deviceProcessInstanceId, created, updated, EntityType.DeviceProcessInstance);
    }

    /**
     * Converts json to device process info object.
     */
    public static fromJsonObject(jsonObject: Object) {
        return new DeviceProcessInstanceInfo(
            jsonObject["entity_id"],
            jsonObject["device_process_id"],
            jsonObject["device_id"],
            jsonObject["client_id"],
            jsonObject["created"],
            jsonObject["updated"],
            jsonObject["process_id"],
            jsonObject["device"] && DeviceInfo.fromJsonObject(jsonObject["device"]),
            jsonObject["device_process"] && DeviceProcessInfo.fromJsonObject(jsonObject["device_process"])
        );
    }

    /**
     * Returns whether given row is any different in details than current info.
     * Shallow comparison, in other words.
     * @param other The row to compare with.
     */
    public isDifferentFrom(other: TableRowData): boolean {
        // Row must be of type device process instance info.
        if (!(other instanceof DeviceProcessInstanceInfo)) {
            return true;
        }

        return true;
    }

    /**
     * Gets the value for the given column id.
     */
    public getColumnValue(columnHeaderId: string): any {
        return this[columnHeaderId];
    }
}

export default DeviceProcessInstanceInfo;
