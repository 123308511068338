import React, { useState } from "react";
import classNames from "classnames";
import { Typography } from "@material-ui/core";

import SpinnerLoader from "../SUI/SternumLoaders/SpinnerLoader";
import InteractiveMap from "./InteractiveMap";
import { useCommonStyle } from "../CommonStyle";
import { useDashboardInteractiveMapStyles } from "./DashboardInteractiveMap.styles";

export interface DashboardInteractiveMap {}

type MapLocation = "world" | "US";

export function DashboardInteractiveMap({}: DashboardInteractiveMap) {
    const classesCommon = useCommonStyle();
    const classes = useDashboardInteractiveMapStyles();

    const [mapLocation, setMapLocation] = useState<MapLocation>("world");
    const [areMapDataLoaded, setAreMapDataLoaded] = useState(false);

    const handleChangeMapLocation = (newLocation: MapLocation) => {
        setMapLocation(newLocation);
        setAreMapDataLoaded(false);
    };

    return (
        <div className={classes.mapWrapper} role="presentation" aria-label="dashboard interactive map">
            <div
                className={classNames(
                    classesCommon.flexVMiddle,
                    classesCommon.flexSpaceBetween,
                    classesCommon.marginBottom,
                    classesCommon.marginTop
                )}
            >
                <Typography variant="body2" className={classNames(classes.mapTitle)} role="heading">
                    Devices Distribution
                </Typography>

                <div
                    className={classes.locationSwitchContainer}
                    role="presentation"
                    aria-label="switch selector for map location"
                >
                    <div
                        tabIndex={0}
                        role="button"
                        aria-selected={mapLocation === "world"}
                        onClick={() => handleChangeMapLocation("world")}
                        className={classNames(classes.locationSwitchOption, mapLocation === "world" && "selected")}
                    >
                        <Typography variant="body2" role="heading">
                            World
                        </Typography>
                    </div>
                    <div
                        tabIndex={0}
                        role="button"
                        aria-selected={mapLocation === "US"}
                        onClick={() => handleChangeMapLocation("US")}
                        className={classNames(classes.locationSwitchOption, mapLocation === "US" && "selected")}
                    >
                        <Typography variant="body2" role="heading">
                            United States
                        </Typography>
                    </div>
                </div>
            </div>

            <div className={classNames(classes.mapContainer, !areMapDataLoaded && classes.mapBlur)}>
                {!areMapDataLoaded && <div className={classes.mapContainerOverlay} />}
                <InteractiveMap
                    id="DashboardInteractiveMap"
                    onDataLoaded={() => setAreMapDataLoaded(true)}
                    location={mapLocation}
                />
            </div>

            {!areMapDataLoaded && (
                <div className={classes.mapLoader}>
                    <SpinnerLoader />
                </div>
            )}
        </div>
    );
}
