import { Theme } from "@material-ui/core/styles";
import { Link, withRouter } from "react-router-dom";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

const cveReferencesDisplayStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        tagTitle: {
            textDecoration: "underline",
        },
        referenceLink: {
            fontSize: "0.8125rem",
            fontWeight: 400,
            cursor: "pointer",
            textDecoration: "none",
            color: theme.palette.common.black,
            "&:hover": {
                textDecoration: "underline",
            },
        },
    });

export default cveReferencesDisplayStyle;
