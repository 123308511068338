import { makeStyles } from "@material-ui/core";
import commonStyle from "../../../components/CommonStyle";

const useSternumAuthTitleStyles = makeStyles((theme) => {
    return {
        ...commonStyle(theme),

        headline: {
            color: "#1B6FDE",
            fontSize: "30px",
        },
    };
});

export default useSternumAuthTitleStyles;
