import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";
import { sternumTheme } from "../App";

const argumentsListDisplayStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        container: {
            width: "100%",
            height: "100%",
            overflowY: "auto",
            padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
            marginTop: theme.spacing(3),
        },
    });

export default argumentsListDisplayStyle;
