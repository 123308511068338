import { makeStyles } from "@material-ui/core";
import commonStyle from "../CommonStyle";

const useGeoLocationContentStyles = makeStyles((theme) => {
    return {
        ...commonStyle(theme),

        container: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },

        separator: {
            borderLeft: "1px solid #ECECEC",
            position: "absolute",
            height: theme.spacing(5),
        },
    };
});

export default useGeoLocationContentStyles;
