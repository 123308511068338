import { Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Popover from "@material-ui/core/Popover";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ServiceWire from "../../../lib/services/ServiceWire";
import { GlobalState } from "../../../lib/state/GlobalState";
import ConfirmationDialog from "../../../shared_components/ConfirmationDialog/ConfirmationDialog";
import { DeleteIcon, SettingsIcon, ThreeDotsHorizontalIcon } from "../../SUI/SternumIcon/SternumIcon";
import dashboardVisualisationMenuStyle from "./DashboardVisualisationMenuStyle";

const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    const selectedClient = ServiceWire.getClientsService().getSelectedClient();

    return {
        isFreeUser: selectedClient.isTrialTier(),
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

interface AppState {
    anchorElement: any;
    confirmDialogOpen: boolean;

    deletingVisualisation: boolean;
    errorDeletingVisualisation: boolean;
}

export interface AppProps extends WithStyles<typeof dashboardVisualisationMenuStyle> {
    visualisationId: string;
    onEditClicked: () => void;
    onVisualisationDeleted: () => void;
    isFreeUser: boolean;
}

class DashboardVisualisationMenu extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            anchorElement: null,
            confirmDialogOpen: false,

            deletingVisualisation: false,
            errorDeletingVisualisation: false,
        };
    }

    render() {
        const { classes } = this.props;

        return (
            <div>
                <ConfirmationDialog
                    title={`Delete Glance?`}
                    body={"If you proceed, this Glance will be removed from the view."}
                    open={this.state.confirmDialogOpen}
                    handleApprove={() => this.onDeleteRequested()}
                    handleCancel={() => this.onDeleteCanceled()}
                    overrideActionName={"Delete"}
                />

                <Popover
                    id="explanation-popover"
                    className={classes.paperContainer}
                    open={!!this.state.anchorElement}
                    anchorEl={this.state.anchorElement}
                    PaperProps={{
                        className: classes.paperContainer,
                    }}
                    onClose={() => {
                        this.setState({
                            anchorElement: null,
                        });
                    }}
                    elevation={10}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                >
                    <div className={classNames(classes.menuContainer)}>
                        <div className={classNames(classes.menuItemContainer)} onClick={() => this.onEditClicked()}>
                            <SettingsIcon width={20} height={20} />
                            <Typography variant="body2" className={classNames(classes.menuItemText)}>
                                Edit
                            </Typography>
                        </div>

                        {!this.props.isFreeUser && (
                            <div
                                className={classNames(classes.menuItemContainer)}
                                onClick={() => this.onDeleteMenuItemClicked()}
                            >
                                <DeleteIcon width={20} height={20} />
                                <Typography variant="body2" className={classNames(classes.menuItemText)}>
                                    Delete
                                </Typography>

                                {(this.state.deletingVisualisation || this.state.errorDeletingVisualisation) && (
                                    <div className={classNames(classes.deletingVisualisationLoaderContainer)}>
                                        <CircularProgress size={15} color="inherit" />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </Popover>

                <ThreeDotsHorizontalIcon
                    className={classes.cogIcon}
                    onClick={(event) => this.onMenuOpenIconClicked(event)}
                />
            </div>
        );
    }

    private onEditClicked() {
        this.props.onEditClicked();
        this.setState({
            anchorElement: null,
        });
    }

    private onDeleteMenuItemClicked() {
        this.setState({
            confirmDialogOpen: true,
        });
    }

    private onMenuOpenIconClicked(event: React.MouseEvent<SVGSVGElement>) {
        this.setState({
            anchorElement: event.currentTarget,
        });
    }

    private onDeleteCanceled() {
        this.setState({
            confirmDialogOpen: false,
        });
    }

    private async onDeleteRequested() {
        try {
            this.setState({
                confirmDialogOpen: false,
                deletingVisualisation: true,
                errorDeletingVisualisation: false,
            });

            await ServiceWire.getVisualisationApiService().deleteVisualisation(this.props.visualisationId);

            if (this.props.onVisualisationDeleted) {
                this.props.onVisualisationDeleted();
            }

            this.setState({
                confirmDialogOpen: false,
                deletingVisualisation: false,
                errorDeletingVisualisation: false,
                anchorElement: null,
            });
        } catch {
            this.setState({
                deletingVisualisation: false,
                errorDeletingVisualisation: false,
            });
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(dashboardVisualisationMenuStyle)(DashboardVisualisationMenu)));
