import { Icon, Tooltip, Typography } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import infoTooltipStyle from "./InfoTooltipStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof infoTooltipStyle> {
    tooltipContent: string;
}

/**
 * Popover content for view columns.
 */
class InfoTooltip extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Tooltip
                    arrow
                    classes={{ tooltip: classes.tooltipBackground }}
                    title={<Typography variant="body2">{this.props.tooltipContent}</Typography>}
                >
                    <Icon
                        className={classNames("fas fa-info-circle", classes.marginLeftXs, classes.cursorPointer)}
                        fontSize={"small"}
                        classes={{
                            fontSizeSmall: classes.infoCircleIconSize,
                        }}
                    />
                </Tooltip>
            </div>
        );
    }
}

export default withStyles(infoTooltipStyle)(InfoTooltip);
