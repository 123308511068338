import { Typography } from "@material-ui/core";
import classNames from "classnames";
import * as React from "react";
import { useEffect, useState } from "react";
import ServiceWire from "../../lib/services/ServiceWire";
import DeviceDefinitionInfo from "../../lib/state/DeviceDefinitionInfo";
import DeviceInteractiveMap, { GeoLocationContainer } from "../DeviceInteractiveMap/DeviceInteractiveMap";
import GraphLoader from "../SUI/SternumLoaders/GraphLoader";
import useGeoLocationHistoryModalStyles from "./GeoLocationHistoryModalStyles";

interface AppProps {
    entityId: string;
    createdFrom: number;
    createdTo: number;
    title: string;
    fullScreenDisplay: boolean;
    deviceDefinition: DeviceDefinitionInfo;
    deviceDefinitionVersionId: string;
}

const DeviceLocationModal = ({
    entityId,
    createdFrom,
    createdTo,
    title,
    fullScreenDisplay,
    deviceDefinition,
    deviceDefinitionVersionId,
}: AppProps) => {
    const {
        loadingPlaceholder,
        container,
        descriptionText,
        marginBottomXLarge,
        listPaper,
        marginBottom,
        marginBottomMedium,
        tableContainer,
        tableInner,
        mapLoader,
        flexVMiddle,
        flexCenter,
    } = useGeoLocationHistoryModalStyles();

    const [isLoading, setIsLoading] = useState(true);
    const [mapData, setMapData] = useState<GeoLocationContainer[]>(null);

    useEffect(() => {
        async function fetchMapData() {
            await ServiceWire.getSternumService()
                .getDeviceGeolocationData(entityId, createdFrom, createdTo)
                .then((data) => {
                    setMapData(data["geo_locations"]);
                    setIsLoading(false);
                });
        }
        fetchMapData();
    }, []);

    return (
        <div className={container}>
            <div className={marginBottomMedium}>
                {/* Title Section */}
                <Typography variant={"h6"}>{title}</Typography>
            </div>
            {isLoading && (
                <div className={classNames(mapLoader, flexVMiddle, flexCenter)}>
                    <GraphLoader />
                </div>
            )}
            {!isLoading && (
                <div className={tableContainer}>
                    <DeviceInteractiveMap id={entityId} maxColor="#15AC5A" minColor="#87BB9F" deviceMapData={mapData} />
                </div>
            )}
        </div>
    );
};

export default DeviceLocationModal;
