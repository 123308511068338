import * as React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { Tooltip, Typography } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";

import ServiceWire from "../../../lib/services/ServiceWire";
import { GlobalState } from "../../../lib/state/GlobalState";
import AggregationFunctionType from "../../../lib/state/Visualisation/AggregationFunctionType";
import VisualisationType from "../../../lib/state/Visualisation/VisualisationType";
import { ChartBarsIcon, ClockIcon, GridIcon, MapIcon, PiechartIcon } from "../../SUI/SternumIcon";
import UIDataVisualisationConfiguration from "../entities/UIDataVisualisationConfiguration";
import visualisationConfigurationTypeSelectionStyle from "./VisualisationConfigurationTypeSelectionStyle";

const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    const selectedClient = ServiceWire.getClientsService().getSelectedClient();

    return {
        isFreeUser: selectedClient.isTrialTier(),
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

interface AppState {}

export interface AppProps extends WithStyles<typeof visualisationConfigurationTypeSelectionStyle> {
    visualisationType: VisualisationType;
    multiDataSources?: boolean;
    onVisualisationTypeSelected: (visualisationType: VisualisationType) => void;
    dataVisualisationConfigurations: UIDataVisualisationConfiguration[];
    isFreeUser: boolean;
}

class VisualisationConfigurationTypeSelection extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);

        const { classes } = this.props;

        // Initializing the state to default.
        this.state = {};
    }

    render() {
        const { classes } = this.props;

        let hasUniqueValue = false;

        for (let i = 0; i < this.props.dataVisualisationConfigurations.length; ++i) {
            if (
                this.props.dataVisualisationConfigurations[i].aggregationFunctionType ===
                AggregationFunctionType.UNIQUE_COUNT
            ) {
                hasUniqueValue = true;
                break;
            }
        }

        return (
            <div role="tablist" className={classNames(classes.allTypesContainer)}>
                {/* Time series */}
                <div
                    role="tab"
                    aria-label="Time Series"
                    aria-selected={this.props.visualisationType === VisualisationType.TIME_SERIES}
                    onClick={() =>
                        !this.props.isFreeUser && this.props.onVisualisationTypeSelected(VisualisationType.TIME_SERIES)
                    }
                    className={classNames(
                        classes.metricTypeContainer,
                        this.props.visualisationType === VisualisationType.TIME_SERIES && "mod-selected",
                        this.props.visualisationType !== VisualisationType.TIME_SERIES &&
                            this.props.isFreeUser &&
                            "mod-disabled"
                    )}
                >
                    <ClockIcon width={18} height={18} color="#1B6FDE" className={classNames(classes.marginRightXs)} />

                    <Typography variant="body2" className={classNames(classes.metricLabel)}>
                        Time Series
                    </Typography>
                </div>

                {/* Metric */}
                <div
                    role="tab"
                    aria-label="1.71 Metric"
                    aria-selected={this.props.visualisationType === VisualisationType.METRIC}
                    onClick={() =>
                        !hasUniqueValue &&
                        !this.props.isFreeUser &&
                        this.props.onVisualisationTypeSelected(VisualisationType.METRIC)
                    }
                    className={classNames(
                        classes.metricTypeContainer,
                        this.props.visualisationType === VisualisationType.METRIC && "mod-selected",
                        hasUniqueValue && "mod-disabled",
                        this.props.visualisationType !== VisualisationType.METRIC &&
                            this.props.isFreeUser &&
                            "mod-disabled"
                    )}
                >
                    <Typography variant="body2" className={classNames(classes.metricIconText, classes.marginRightXs)}>
                        1.71
                    </Typography>
                    <Typography variant="body2" className={classNames(classes.metricLabel)}>
                        Metric
                    </Typography>
                </div>

                <Tooltip title={""}>
                    <div
                        role="tab"
                        aria-label="Bar"
                        aria-selected={this.props.visualisationType === VisualisationType.VALUES_GRAPH}
                        onClick={() =>
                            // !this.props.multiDataSources &&
                            !this.props.isFreeUser &&
                            this.props.onVisualisationTypeSelected(VisualisationType.VALUES_GRAPH)
                        }
                        className={classNames(
                            classes.metricTypeContainer,
                            this.props.visualisationType === VisualisationType.VALUES_GRAPH && "mod-selected",
                            // this.props.multiDataSources && "mod-disabled"
                            this.props.visualisationType !== VisualisationType.VALUES_GRAPH &&
                                this.props.isFreeUser &&
                                "mod-disabled"
                        )}
                    >
                        <ChartBarsIcon
                            width={18}
                            height={18}
                            color="#1B6FDE"
                            className={classNames(classes.marginRightXs)}
                        />

                        <Typography variant="body2" className={classNames(classes.metricLabel)}>
                            Bar
                        </Typography>
                    </div>
                </Tooltip>

                <Tooltip title={""}>
                    <div
                        role="tab"
                        aria-label="Pie Chart"
                        aria-selected={this.props.visualisationType === VisualisationType.PIE_CHART}
                        onClick={() =>
                            // !this.props.multiDataSources &&
                            !this.props.isFreeUser &&
                            this.props.onVisualisationTypeSelected(VisualisationType.PIE_CHART)
                        }
                        className={classNames(
                            classes.metricTypeContainer,
                            this.props.visualisationType === VisualisationType.PIE_CHART && "mod-selected",
                            // this.props.multiDataSources && "mod-disabled"
                            this.props.visualisationType !== VisualisationType.PIE_CHART &&
                                this.props.isFreeUser &&
                                "mod-disabled"
                        )}
                    >
                        <PiechartIcon
                            width={18}
                            height={18}
                            color="#1B6FDE"
                            className={classNames(classes.marginRightXs)}
                        />

                        <Typography variant="body2" className={classNames(classes.metricLabel)}>
                            Pie Chart
                        </Typography>
                    </div>
                </Tooltip>

                {/* Values */}
                <Tooltip title={""}>
                    <div
                        role="tab"
                        aria-label="Table"
                        aria-selected={this.props.visualisationType === VisualisationType.VALUES_TABLE}
                        onClick={() =>
                            // !this.props.multiDataSources &&
                            !this.props.isFreeUser &&
                            this.props.onVisualisationTypeSelected(VisualisationType.VALUES_TABLE)
                        }
                        className={classNames(
                            classes.metricTypeContainer,
                            this.props.visualisationType === VisualisationType.VALUES_TABLE && "mod-selected",
                            // this.props.multiDataSources && "mod-disabled"
                            this.props.visualisationType !== VisualisationType.VALUES_TABLE &&
                                this.props.isFreeUser &&
                                "mod-disabled"
                        )}
                    >
                        <GridIcon
                            width={18}
                            height={18}
                            color="#1B6FDE"
                            className={classNames(classes.marginRightXs)}
                        />

                        <Typography variant="body2" className={classNames(classes.metricLabel)}>
                            Table
                        </Typography>
                    </div>
                </Tooltip>

                <Tooltip title={""}>
                    <div
                        role="tab"
                        aria-label="Geomap"
                        aria-selected={this.props.visualisationType === VisualisationType.GEOMAP}
                        onClick={() =>
                            !this.props.multiDataSources &&
                            !hasUniqueValue &&
                            !this.props.isFreeUser &&
                            this.props.onVisualisationTypeSelected(VisualisationType.GEOMAP)
                        }
                        className={classNames(
                            classes.metricTypeContainer,
                            this.props.visualisationType === VisualisationType.GEOMAP && "mod-selected",
                            this.props.multiDataSources && "mod-disabled",
                            hasUniqueValue && "mod-disabled",
                            this.props.visualisationType !== VisualisationType.GEOMAP &&
                                this.props.isFreeUser &&
                                "mod-disabled"
                        )}
                    >
                        <MapIcon width={18} height={18} color="#1B6FDE" className={classNames(classes.marginRightXs)} />

                        <Typography variant="body2" className={classNames(classes.metricLabel)}>
                            Geomap
                        </Typography>
                    </div>
                </Tooltip>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withStyles(visualisationConfigurationTypeSelectionStyle, { withTheme: true })(
        VisualisationConfigurationTypeSelection
    )
);
