import { Action } from "redux";
import UserInfo from "../../state/UserInfo";
import ActionType from "../ActionType";

/**
 * Defines the data for the refresh trigger action
 */
export interface SetUserAction extends Action<ActionType> {
    type: ActionType.SetUserAction;
    user: UserInfo;
}

/**
 * Dispatch refresh trigger action
 */
export function setUserInfoAction(user: UserInfo): SetUserAction {
    return {
        type: ActionType.SetUserAction,
        user: user,
    };
}
