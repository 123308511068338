import Utils from "../infra/Utils";
import PollingChangeType from "../state/PollingChangeType";
import PollingSubscriptionInfo from "./PollingSubscriptionInfo";
import ServiceWire from "./ServiceWire";
import SternumService from "./SternumService";
import AnalyticsService from "./AnalyticsService";

/**
 * Handles polling in the sternum application.
 */
class PollingService {
    /**
     * Service used for outgoing sternum API calls.
     */
    private sternumService: SternumService;

    /**
     * Holds the timeout we initiated for the polling.
     */
    private pollingTimeout;

    /**
     * Indicates whether polling is currently active.
     */
    private pollingActive: boolean;

    /**
     * Holds the last time we executed polling request.
     */
    private lastPollingTime: number;

    /**
     * Holds a map between a changed event type to the subscribers who are listening on it.
     */
    private pollingChangeTypeToSubscriberMap: {
        [pollingChangeType: string]: {
            [subscriberId: string]: PollingSubscriptionInfo;
        };
    } = {};

    /**
     * How long to wait between polling intervals.
     */
    private static POLLING_INTERVAL_MILLIS: number = 5000;

    /**
     * Constructor.
     */
    constructor(sternumService: SternumService) {
        this.sternumService = sternumService;
    }

    /**
     * Starts the polling activity.
     */
    public startPolling(): void {
        if (!this.pollingActive) {
            this.pollingActive = true;
            this.startPollingTimeout();
        }
    }

    /**
     * Stops the polling activity by clearing polling interval.
     */
    public stopPolling(): void {
        if (this.pollingActive) {
            this.pollingActive = false;

            if (this.pollingTimeout) {
                clearTimeout(this.pollingTimeout);
            }
        }
    }

    /**
     * Subscribes a new subscriber to changed entities from polling.
     */
    public subscribe(pollingSubscriptionInfo: PollingSubscriptionInfo): void {
        let pollingChangeTypeStr = PollingChangeType[pollingSubscriptionInfo.pollingChangeType];

        // Initializing subscribers for type if not already exists.
        if (!this.pollingChangeTypeToSubscriberMap[pollingChangeTypeStr]) {
            this.pollingChangeTypeToSubscriberMap[pollingChangeTypeStr] = {};
        }

        // Validating subscriber is not already subscribed.
        let currentSubscribers = this.pollingChangeTypeToSubscriberMap[pollingChangeTypeStr];
        if (currentSubscribers && currentSubscribers[pollingSubscriptionInfo.subscriberId]) {
            return;
        }

        // At last, adding the subscriber.
        currentSubscribers[pollingSubscriptionInfo.subscriberId] = pollingSubscriptionInfo;
    }

    /**
     * Removing the subscriber from the polling subscribers map.
     */
    public unsubscribe(subscriberId: string, pollingChangeType: PollingChangeType): void {
        let pollingChangeTypeStr = PollingChangeType[pollingChangeType];

        // Validating changed event type exists.
        if (
            !this.pollingChangeTypeToSubscriberMap[pollingChangeTypeStr] ||
            !this.pollingChangeTypeToSubscriberMap[pollingChangeTypeStr][subscriberId]
        ) {
            return;
        }

        // Removing the subscriber.
        delete this.pollingChangeTypeToSubscriberMap[pollingChangeTypeStr][subscriberId];

        // Delete subscription type if it's empty to prevent server overhead.
        if (Object.keys(this.pollingChangeTypeToSubscriberMap[pollingChangeTypeStr]).length == 0) {
            delete this.pollingChangeTypeToSubscriberMap[pollingChangeTypeStr];
        }
    }

    /**
     * Clears all subscriptions of the polling service.
     */
    public clearAllSubscriptions() {
        this.pollingChangeTypeToSubscriberMap = {};
    }

    /**
     * Recursively re-initiative a timeout for polling.
     * The reason to use setTimeout() and not setInterval() is because setTimeout() will make sure
     * we don't overflow ourselves with requests that we aren't yet finished handling.
     */
    private startPollingTimeout(): void {
        // Updating last polling time to now.
        this.lastPollingTime = new Date().getTime();

        // Setting a timeout for the polling.
        this.pollingTimeout = setTimeout(async () => {
            let currentPollingTimestamp = this.lastPollingTime;

            // Get all the change types we need to poll.
            let pollingChangeTypes = Utils.getMapKeys(this.pollingChangeTypeToSubscriberMap);

            if (pollingChangeTypes && pollingChangeTypes.length) {
                // Only if we have subscribers!

                let changedData = null;
                try {
                    changedData = await this.sternumService.getChangedDataSince(
                        ServiceWire.getClientsService().getSelectedClientId(),
                        currentPollingTimestamp,
                        pollingChangeTypes
                    );
                } catch (error) {
                    if (error.response && error.response.status === 401) {
                        // Stop polling if we get 401
                        this.stopPolling();
                        // Force refresh, redirects the user to login page
                        window.location.reload();
                    }
                    // Else, we swallow the exception to allow polling to re-iterate.
                }

                // First, we handle the data received from the polling.
                if (changedData) {
                    try {
                        this.handlePollingData(currentPollingTimestamp, changedData);
                    } catch (error) {
                        // Failed to run callback function. Report the error.
                        AnalyticsService.error("PollingService:startPollingTimeout", error.message);
                    }
                }
            }

            // Next, we initiate another poll.
            this.startPollingTimeout();
        }, PollingService.POLLING_INTERVAL_MILLIS);
    }

    /**
     * Responsible for handling the actual polling data.
     */
    private handlePollingData(fromTimestamp: number, changedData: Object): void {
        for (let pollingChangeType in this.pollingChangeTypeToSubscriberMap) {
            if (this.pollingChangeTypeToSubscriberMap.hasOwnProperty(pollingChangeType)) {
                if (changedData[pollingChangeType]) {
                    let subscriberMap = this.pollingChangeTypeToSubscriberMap[pollingChangeType];

                    for (let subscriberId in subscriberMap) {
                        if (subscriberMap.hasOwnProperty(subscriberId)) {
                            let subscription = subscriberMap[subscriberId];

                            subscription.callback(fromTimestamp, changedData[pollingChangeType]);
                        }
                    }
                }
            }
        }
    }
}

export default PollingService;
