import { makeStyles } from "@material-ui/styles";

export const useMultipleDevicesSummaryStyle = makeStyles({
    container: {},
    title: {
        color: "#2B2523",
        fontWeight: 600,
        fontSize: "14px",
        marginBottom: 2,
    },
    summaryItem: {
        display: "flex",
        color: "#999999",
        fontSize: "12px",
        marginTop: 10,
    },
    summaryItemTitle: {
        fontWeight: 600,
    },
    summaryItemValue: {
        fontWeight: 400,
    },
});
