import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const commonLoginManager = {
    mediaQueryMaxWidth: "600px",
};

export const useLoginManagerStyle = makeStyles((theme: Theme) => ({
    title: {
        position: "relative",
        fontWeight: 700,
        fontSize: 40,
        margin: "0 0 26px 0",

        [`@media (max-width: ${commonLoginManager.mediaQueryMaxWidth})`]: {
            fontSize: 22,
            lineHeight: "33px",
            margin: "0 0 12px 0",
        },
    },

    textWithIcon: {
        display: "inline-flex",
        flexFlow: "nowrap",
    },

    authFieldsContainer: {
        maxWidth: 400,

        [`@media (max-width: ${commonLoginManager.mediaQueryMaxWidth})`]: {
            maxWidth: "unset",
            width: "100%",
        },
    },

    buttonContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 28,

        [`@media (max-width: ${commonLoginManager.mediaQueryMaxWidth})`]: {
            flexFlow: "column",
            marginTop: 25,
            gap: 10,
        },
    },

    loginButton: {
        maxWidth: 143,
        backgroundColor: theme.palette.secondary.main,
    },

    linkButton: {
        color: theme.palette.secondary.dark,
        fontWeight: 600,
    },
    signupLinkContainer: {
        width: "270px",
        marginLeft: 0,
        marginRight: "auto",
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "160%",
        marginTop: "30px",
        color: "#43484C",
        "& a": {
            textDecoration: "none",
            color: "#E7004C",
            fontWeight: 600,
        },
        [`@media (max-width: ${commonLoginManager.mediaQueryMaxWidth})`]: {
            marginLeft: "auto",
        },
    },
    homeButtonContainer: {
        width: "160px",
        marginLeft: 0,
        marginRight: "auto",
        marginTop: "30px",
        color: "#231434",
        bottom: "50px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "10px 14px",
        background: "#F4F4FC",
        borderRadius: "12px",
        textDecoration: "none",
        transition: "all 0.2s ease",
        "&:hover": {
            background: "#231434",
            color: "#F4F4FC",
        },
        "& svg": {
            marginRight: "8px",
        },
        [`@media (max-width: ${commonLoginManager.mediaQueryMaxWidth})`]: {
            marginLeft: "auto",
        },
    },
    captcha: {
        width: "0px",
        marginLeft: 0,
        marginRight: "auto",
        [`@media (max-width: ${commonLoginManager.mediaQueryMaxWidth})`]: {
            marginLeft: "auto",
            "& > div > div": {
                transform: "translateX(-50%)",
            },
        },
    },
}));
