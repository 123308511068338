import React from "react";
import classNames from "classnames";

import { useMultipleDevicesGraphStyle } from "./MultipleDevicesGraph.style";

export interface GraphParentChildItemProps {
    renderItem: () => React.ReactNode;
    deep: number;
    childItems?: React.ReactNode[];
}

export function GraphParentChildItem({ renderItem, deep, childItems }: GraphParentChildItemProps) {
    const classes = useMultipleDevicesGraphStyle();

    const renderChildren = () => {
        if (!childItems || deep === 1) {
            return null;
        } else if (childItems.length === 1) {
            return (
                <>
                    <div className={classNames(classes.graphConnector)} />
                    {childItems[0]}
                </>
            );
        } else if (childItems.length > 1) {
            return (
                <>
                    <div className={classNames(classes.graphConnector)} />
                    <div className={classNames(classes.graphChildren)}>
                        {childItems.map((child, index, { length }) => (
                            <div key={index} className={classNames(classes.graphChildItem)}>
                                <div className={classNames(classes.graphChildHConnector)} />
                                {index > 0 && <div className={classNames(classes.graphChildVTopConnector)} />}
                                {index < length - 1 && (
                                    <div className={classNames(classes.graphChildVBottomConnector)} />
                                )}
                                {child}
                            </div>
                        ))}
                    </div>
                </>
            );
        }
    };

    return (
        <div className={classNames(classes.graphItemsContainer)}>
            {renderItem()}
            {renderChildren()}
        </div>
    );
}
