import Typography from "@material-ui/core/Typography";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ServiceWire from "../../lib/services/ServiceWire";
import { GlobalState } from "../../lib/state/GlobalState";
import useApiDocumentationPageStyle from "./ApiDocumentationPageStyle";
import ApiDocumentationEndpoint, { EndpointDefinition } from "./ApiDocumentationEndpoint/ApiDocumentationEndpoint";

export interface AppProps {
    sideBarOpen: boolean;
}

const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

const alertsEndpoint: EndpointDefinition = {
    id: "alerts",
    name: "/alerts",
    method: "GET",
    requestExample: "curl https://api.sternum.cloud/public/v1/alerts",
    queryParams: [
        {
            name: "limit",
            type: "number",
            default: 100,
        },
        {
            name: "offset",
            type: "number",
            default: 0,
        },
        {
            name: "start_time",
            type: "number",
        },
        {
            name: "end_time",
            type: "number",
        },
        {
            name: "device_definition_id",
            type: "string",
        },
        {
            name: "device_id",
            type: "number",
        },
        // {
        //     name: "interest_type",
        //     type: "string",
        // },
    ],
    responseExample: {
        alerts: [
            {
                alert_id: "SGEVEpiVGdd0961",
                alert_type: "Multiple login failures",
                exploitation_type: null,
                device_profile: "IP camera",
                device_definition_id: "DDVEyS5zFVV0K41",
                device_id: 95410,
                firmware_version: 1640012316000.0,
                arguments: null,
                ip_address: "51.227.88.64",
                geo_data: null,
                alert_description:
                    "Multiple failed login attempts detected over a short period of time. This may indicate a brute force attack.",
                category: "security_event",
                severity: "high",
                created_at: 1640008965000.0,
            },
            {
                alert_id: "SGEVacWlMwU0961",
                alert_type: "Multiple disconnections detected",
                exploitation_type: null,
                device_profile: "IP camera",
                device_definition_id: "DDVEyS5zFVV0K41",
                device_id: 95410,
                firmware_version: 1640012316000.0,
                arguments: null,
                ip_address: "51.227.88.64",
                geo_data: null,
                alert_description: "Multiple disconnections detected",
                category: "communication_event",
                severity: "high",
                created_at: 1640008846000.0,
            },
        ],
    },
};

const eventsEndpoint: EndpointDefinition = {
    id: "events",
    name: "/events",
    method: "GET",
    description: "Get events that occured after or before a specific event (alert or trace)",
    requestExample: "curl https://api.sternum.cloud/public/v1/events",
    queryParams: [
        {
            name: "limit",
            type: "number",
            default: 100,
        },
        {
            name: "offset",
            type: "number",
            default: 0,
        },
        {
            name: "start_time",
            type: "number",
        },
        {
            name: "end_time",
            type: "number",
        },
        {
            name: "device_definition_id",
            type: "string",
        },
        {
            name: "device_id",
            type: "number",
        },
        {
            name: "alert_id",
            type: "string",
        },
    ],
    responseExample: {
        events: [
            {
                trace_name: "Task Closed",
                trace_id: "TRACoxJRDeTC761",
                severity: "normal",
                category: "OS_EVENT",
                description: null,
                arguments: {
                    Name: "LinkedConnector",
                },
                device_definition_id: 885397982891143168,
                device_profile: "patient monitor",
            },
            {
                trace_name: "Critical Error",
                trace_id: "TRACOkdfPZTC761",
                severity: "normal",
                category: "OS_EVENT",
                description: null,
                arguments: {
                    "Error Code": "189",
                    "File Name": "execproc",
                    Line: "204",
                    Message:
                        "0x72:0xffff T=39471 execproc:492[rpcif_enable_rpm:17]0:0xdead T=22352 execproc:187[rpcif_disable_rpm:18]",
                },
                device_definition_id: 885397982891143168,
                device_profile: "patient monitor",
            },
        ],
    },
};

const apiKeyHeader = {
    api_key: "<YOUR_STERNUM_API_KEY>",
};

const endpoints: EndpointDefinition[] = [alertsEndpoint, eventsEndpoint];

function ApiDocumentationPage() {
    const classes = useApiDocumentationPageStyle();

    return (
        <div className={classes.root}>
            <div className={classes.marginBottomXLarge}>
                <Typography variant={"h4"} className={(classes.marginRight, classes.extraBold)}>
                    API Documentation
                </Typography>
            </div>

            <div className={classes.marginBottomXLarge}>
                <Typography variant={"h5"} className={(classes.marginRight, classes.extraBold)}>
                    Getting Started
                </Typography>

                <Typography variant="body2" className={classes.startedText}>
                    Sternum REST API endpoints can be invoked in any of the standard ways to invoke a RESTful API. This
                    section describes how to use the Sternum REST API using cURL as an example.
                </Typography>

                <Typography variant="body2" className={classes.startedText}>
                    Any request described below expects to have <b>api_key</b> header. Example:
                </Typography>

                <SyntaxHighlighter
                    language="json"
                    style={atomOneDark}
                    customStyle={{ padding: 10, fontSize: 12, borderRadius: 8 }}
                >
                    {JSON.stringify(apiKeyHeader, null, 4)}
                </SyntaxHighlighter>
            </div>

            <div>
                <Typography variant={"h5"} className={classes.extraBold}>
                    Endpoints
                </Typography>
            </div>

            {endpoints.map((endpoint) => (
                <div className={classes.endpointContainer}>
                    <ApiDocumentationEndpoint key={endpoint.id} endpoint={endpoint} />
                </div>
            ))}
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ApiDocumentationPage));
