import { makeStyles } from "@material-ui/core";

const informationColor = "#1B6FDE";
const successColor = "#36BE75";
const errorColor = "#F93535";

export const useNotificationSnackbarStyle = makeStyles((theme) => ({
    default: {},

    root: {
        "& .MuiSnackbarContent-root": {
            position: "relative",
            padding: 0,
            borderRadius: 8,
            background: "white",
            width: 394,
            color: "#484F68",
            fontSize: 14,
            lineHeight: "20px",
        },

        "& .MuiSnackbarContent-message": {
            display: "flex",
            padding: 0,
            width: "100%",
            minHeight: 56,
        },
    },

    rootInformation: {
        "& $leftBar": {
            background: informationColor,
        },

        "& $icon": {
            color: informationColor,
        },
    },

    rootError: {
        "& $leftBar": {
            background: errorColor,
        },

        "& $icon": {
            color: errorColor,
        },
    },

    leftBar: {
        width: 6,
        borderRadius: "8px 0 0 8px",
        background: successColor,
    },

    iconContainer: {
        display: "flex",
        width: 58,
    },

    icon: {
        margin: "auto",
    },

    content: {
        flex: 1,
        display: "flex",
        alignItems: "center",
        padding: "10px 0",
    },

    iconCloseContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: 16,
    },

    iconClose: {
        cursor: "pointer",
    },
}));
