import React, { useEffect } from "react";
import classNames from "classnames";
import { Button, CircularProgress, Typography } from "@material-ui/core";

import useSternumImprovedButtonStyles from "./SternumImprovedButtonStyles";

export type SternumImprovedButtonType =
    | "regular"
    | "regular:outlined"
    | "regularWithDisabled"
    | "gray"
    | "blue"
    | "white"
    | "text:dark"
    | "text:blue";

interface AppProps {
    className?: string;
    onClick?: (event) => unknown;
    isDisabled?: boolean;
    isLoading?: boolean;
    content: React.ReactNode;
    buttonType?: SternumImprovedButtonType;
    fullWidth?: boolean;
    icon?: React.ReactNode;
}

const getBackgroundColor = (type: AppProps["buttonType"]) => {
    const colorsMap: Record<typeof type, string> = {
        regular: "regular",
        regularWithDisabled: "regularWithDisabled",
        gray: "gray",
        blue: "blue",
        white: "white",
        "text:dark": "text-dark",
        "text:blue": "text-blue",
        "regular:outlined": "regular-outlined",
    };
    return colorsMap[type];
};

const SternumImprovedButton = ({
    className,
    isDisabled = false,
    isLoading = false,
    onClick,
    content,
    buttonType = "regular",
    fullWidth = true,
    icon,
}: AppProps) => {
    const classes = useSternumImprovedButtonStyles();

    useEffect(() => {}, []);

    return (
        <Button
            variant="contained"
            onClick={onClick}
            disabled={isDisabled}
            fullWidth={fullWidth}
            className={classNames(
                classes.root,
                getBackgroundColor(buttonType),
                isDisabled && "mod-disabled",
                className
            )}
        >
            {/* Any icon work like DeleteIcon component etc. */}
            {icon}
            <Typography className={classes.buttonContent}>{content}</Typography>
            {/* Loading */}
            {isLoading && <CircularProgress size={20} color="inherit" />}
        </Button>
    );
};

export default SternumImprovedButton;
