import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../../components/CommonStyle";

const inviteUserDialogStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),
        root: {
            "& .MuiDialogTitle-root": {
                paddingRight: "0px",
            },
            ".MuiPaper-rounded": {
                borderRadius: "6px",
            },
        },
        padding: {
            paddingRight: theme.spacing(3),
            paddingLeft: theme.spacing(3),
        },
        container: {
            width: "370px",
        },
        borderRadius: {
            borderRadius: "14px",
        },
        paddingZero: {
            padding: "0px",
        },
        rootMenuItem: {
            "&$selected": {
                backgroundColor: "#FFF1F5",
                "&:hover": {
                    backgroundColor: "#FFF1F5",
                },
            },
            "&:hover": {
                backgroundColor: "#FFF1F5",
            },
        },
        selected: {},
        labelColor: {
            color: "#999999",
        },
        selectComponent: {
            minWidth: theme.spacing(50),
            paddingTop: theme.spacing(2),
        },
    });

export default inviteUserDialogStyle;
