import { Action } from "redux";

import ActionType from "../ActionType";

export interface ResetAlertsFilterAction extends Action<ActionType> {
    type: ActionType.DashboardRegularResetAlertsFilter;
}

export function resetAlertsFilterAction(): ResetAlertsFilterAction {
    return {
        type: ActionType.DashboardRegularResetAlertsFilter,
    };
}
