import React from "react";

interface TracesTableDef<TD extends Object> {
    id: string;
    width: string;
    headCellName: string;
    cellRowClassName: string;
    renderBodyCell: (tableData: TD, rowIndex: number) => React.ReactNode;
}

export function useTracesTableDef<TableData extends Object>({
    tableDefs,
}: {
    tableDefs: TracesTableDef<TableData>[];
}): {
    tableDefs: TracesTableDef<TableData>[];
} {
    return { tableDefs };
}
