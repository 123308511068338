import npxPartnerProgram from "../../../images/customers/CustomerNpxPartnerProgram.webp";
import medtronic from "../../../images/customers/CustomerMedtronic.webp";
import telit from "../../../images/customers/CustomerTelit.webp";

export const customersOpinions: {
    id: string;
    image: string;
    description: string;
    nameAndSurname: string;
    workplace: string;
}[] = [
    {
        id: "npx",
        image: npxPartnerProgram,
        description: `“All attacks NXP crafted were blocked by the EIV technology... The CPU overhead was less than 3%... Sternum successfully addresses the rise of remote runtime attacks against connected devices and can be seen as a valuable addition to many of NXP products.”`,
        nameAndSurname: "MARC VAUCLAIR",
        workplace: "Senior Security System Architect",
    },
    {
        id: "medtronic",
        image: medtronic,
        description: `“Sternum’s solution saves us time, manpower and money. Being able to lean on Sternum as an active mitigation, is a game changer and the data insights help us build better products and make better decisions.”`,
        nameAndSurname: "KYLE ERICKSON",
        workplace: "Product Security Director at Medtronic",
    },
    {
        id: "telit",
        image: telit,
        description: `It's seamless, baked-in security with none of the added investment or complexity. Sternum alerts users to any attempt to compromise one of their deployed devices, providing early security intelligence.`,
        nameAndSurname: "DR. MIHAI VOICU",
        workplace: "Ciso at Telit",
    },
];
