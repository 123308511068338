import ModalType from "./ModalType";

/**
 * Represents a key for a modal in sternum.
 */
class ModalKey {
    /**
     * The string representation of the modal key.
     */
    private readonly stringKey: string;

    /**
     * Constructor.
     */
    constructor(public modalType: ModalType, public key: string) {
        // Calculating the string representation only once.
        this.stringKey = `${this.modalType}--${this.key}`;
    }

    /**
     * Gets the string representation proeprty.
     */
    public getStringKey(): string {
        return this.stringKey;
    }
}

export default ModalKey;
