import MomentUtils from "@date-io/moment";
import { Button, Icon, Popover, Tooltip, WithStyles, withStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import classNames from "classnames";
import * as React from "react";
import TimeRange from "../../lib/state/TimeRange";
import TimeSelectionType from "../../lib/state/TimeSelectionType";
import TimeSelectOption from "../../lib/state/TimeSelectOption";
import TimeSelectOptionElementHolder from "../../lib/state/TimeSelectOptionElementHolder";
import getDefaultTimeSelection from "../NewDeviceView/getDefaultTimeSelection";
import TimeSelectOptions from "../../lib/state/TimeSelectOptions";
import SelectComponent from "../DeviceDefinitionComponents/SelectComponent/SelectComponent";
import timeSelectionComponentStyle from "./TimeSelectionComponentStyle";
import TimeSelectionSelectComponent from "./TimeSelectionSelectComponent";

import moment, { DurationInputArg1, DurationInputArg2 } from "moment";

interface AppState {
    anchorElement: any;
    selectOptions: TimeSelectOptionElementHolder[];
    selectedOption: TimeSelectOption;
    // backupSelectOption is used to store whatever was selected before
    // custom range selection. Then if user cancels his selection in
    // popover, we revert to backupSelectionOption
    backupSelectedOption: TimeSelectOption | null;
    customRange: TimeRange;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof timeSelectionComponentStyle> {
    onTimeRangeSelected: (selectedOption: TimeSelectOption) => void;

    timeSelectedOption?: TimeSelectOption;

    includeLiveTailOption?: boolean;

    theme?;
}

class TimeSelectionComponent extends React.Component<AppProps, AppState> {
    hiddenSelect: any;
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);
        // Initializing the state to default.
        this.state = {
            anchorElement: null,
            selectOptions: this.getTimeSelectionList(),
            selectedOption: this.props.timeSelectedOption ? this.props.timeSelectedOption : this.getDefaultTimeOption(),
            backupSelectedOption: null,
            customRange: new TimeRange(moment().toDate(), moment().toDate()),
        };

        this.hiddenSelect = React.createRef();
    }

    componentDidMount() {}

    /**
     * Get default value for init phase
     */
    private getDefaultTimeOption(): TimeSelectOption {
        return getDefaultTimeSelection();
    }

    /**
     * Update parent component with selected date range
     */
    private updateSelectedField(selectedValue: TimeSelectOptionElementHolder, action) {
        const selectTime = selectedValue.value;
        switch (selectTime.selectionType) {
            case TimeSelectionType.CUSTOM: {
                this.setState((s) => ({
                    ...s,
                    selectedOption: selectedValue.value,
                    anchorElement: this.hiddenSelect.current,
                    backupSelectedOption: s.selectedOption,
                }));
                break;
            }
            case TimeSelectionType.LIVE: {
                selectTime.timeRange = new TimeRange(moment().subtract(1, "m").toDate(), moment().toDate());
                this.setState({ selectedOption: selectTime });
                this.props.onTimeRangeSelected(selectTime);

                break;
            }
            default: {
                const subtractAmountByTimeRange: Record<
                    string,
                    { amount: DurationInputArg1; unit: DurationInputArg2 }
                > = {
                    w: { amount: 7, unit: "days" },
                    M: { amount: 1, unit: "months" },
                    y: { amount: 1, unit: "years" },
                };

                const selectedTimeRange: TimeRange = TimeSelectOptions.getTimeRange(
                    selectTime.unit,
                    selectTime.defaultUnitValue
                );

                if (new Set(Object.keys(subtractAmountByTimeRange)).has(selectTime.unit)) {
                    const subtractAmount = subtractAmountByTimeRange[selectTime.unit];
                    const to = moment.utc().endOf("day").toDate();
                    const from = moment(to).subtract(subtractAmount.amount, subtractAmount.unit).toDate();
                    selectedTimeRange.start = from;
                    selectedTimeRange.end = to;
                }

                selectTime.timeRange = selectedTimeRange;
                this.setState({
                    selectedOption: selectTime,
                });
                this.props.onTimeRangeSelected(selectTime);
                break;
            }
        }
    }

    /**
     * Return default date filters
     */
    private getTimeSelectionList = (): TimeSelectOptionElementHolder[] => {
        let selectOptions: TimeSelectOptionElementHolder[] = [];
        const possibleTimeSelections = [...TimeSelectOptions.getTimeOptions()];

        if (this.props.includeLiveTailOption) {
            possibleTimeSelections.unshift(TimeSelectOptions.liveTailSelectionOption);
        }

        possibleTimeSelections.forEach((timeOption: TimeSelectOption) => {
            selectOptions.push({
                label: <TimeSelectionSelectComponent statusText={timeOption.status} labelText={timeOption.label} />,
                value: timeOption,
            });
        });
        return selectOptions;
    };

    /**
     * Close select range popover
     */
    private setCustomRange = (event) => {
        const customTimeSelect = { ...this.state.selectedOption };
        customTimeSelect.timeRange = this.state.customRange;
        this.setState({
            anchorElement: null,
            selectedOption: customTimeSelect,
            backupSelectedOption: null,
        });
        this.props.onTimeRangeSelected(customTimeSelect);
    };

    /**
     * Set custom start date
     */
    private setCustomStartDate = (date) => {
        let customRange = { ...this.state.customRange };
        customRange.start = date.toDate();
        this.setState({
            customRange: customRange,
        });
    };

    /**
     * Set custom end date
     */
    private setCustomEndDate = (date) => {
        let customRange = { ...this.state.customRange };
        customRange.end = date.toDate();
        this.setState({
            customRange: customRange,
        });
    };

    /**
     * Handle refresh click
     */
    private handleRefreshClick = (event) => {
        const selectedType: TimeSelectionType = this.state.selectedOption.selectionType;

        switch (selectedType) {
            case TimeSelectionType.LIVE:
                let newLiveSelectionTime = { ...this.state.selectedOption };
                newLiveSelectionTime.timeRange = new TimeRange(moment().subtract(1, "m").toDate(), moment().toDate());
                this.props.onTimeRangeSelected(newLiveSelectionTime);
                break;
            case TimeSelectionType.CONST:
                const subtractAmountByTimeRange: Record<
                    string,
                    { amount: DurationInputArg1; unit: DurationInputArg2 }
                > = {
                    w: { amount: 7, unit: "days" },
                    M: { amount: 1, unit: "months" },
                    y: { amount: 1, unit: "years" },
                };

                let newSelectionTime = { ...this.state.selectedOption };
                newSelectionTime.timeRange = new TimeRange(newSelectionTime.timeRange.start, moment().toDate());

                if (new Set(Object.keys(subtractAmountByTimeRange)).has(newSelectionTime.unit)) {
                    const subtractAmount = subtractAmountByTimeRange[newSelectionTime.unit];
                    const to = moment.utc().endOf("day").toDate();
                    const from = moment(to).subtract(subtractAmount.amount, subtractAmount.unit).toDate();
                    newSelectionTime.timeRange.start = from;
                    newSelectionTime.timeRange.end = to;
                }

                this.props.onTimeRangeSelected(newSelectionTime);
                break;
            default:
                // Custom Selection
                this.props.onTimeRangeSelected({ ...this.state.selectedOption });
                break;
        }
    };

    /**
     * Get button tooltip text
     */
    private getButtonTitle = () => {
        return this.state.selectedOption.hasOwnProperty("selectionType") &&
            this.state.selectedOption.selectionType === TimeSelectionType.LIVE
            ? "Clear"
            : "Refresh";
    };

    /***
     * Build select item
     */

    private buildSelectItem = () => {
        const selectedTimeRange = this.state.selectedOption;
        if (selectedTimeRange.selectionType === TimeSelectionType.CUSTOM) {
            let labelText = selectedTimeRange.label;
            if (selectedTimeRange.timeRange) {
                const customStartDate = moment(selectedTimeRange.timeRange.start).format("MMMM Do, h:mm:ss a");
                const customEndDate = moment(selectedTimeRange.timeRange.end).format("MMMM Do, h:mm:ss a");
                labelText = `${customStartDate} - ${customEndDate}`;
            }

            return new TimeSelectOptionElementHolder(
                <TimeSelectionSelectComponent statusText={"-"} labelText={labelText} />,
                this.state.selectedOption
            );
        } else {
            return new TimeSelectOptionElementHolder(
                (
                    <TimeSelectionSelectComponent
                        statusText={selectedTimeRange.status}
                        labelText={selectedTimeRange.label}
                    />
                ),
                this.state.selectedOption
            );
        }
    };

    /**
     * Time range validation. To date is bigger than from date
     */
    private validateCustomRangeSelection(): boolean {
        return this.state.customRange.end >= this.state.customRange.start;
    }

    /**
     * Close custom selection popover
     */
    private onCloseTimeRangeSelection() {
        this.setState((s) => ({
            ...s,
            anchorElement: null,
            selectedOption: s.backupSelectedOption,
            backupSelectedOption: null,
        }));
    }

    render() {
        const { classes } = this.props;
        // Dates dropdown select component
        return (
            <div ref={this.hiddenSelect} role="presentation" aria-label="time selection container">
                <div className={classNames(classes.flexRow, classes.flexVMiddle)}>
                    {/** Select component */}

                    <div role="presentation" aria-label="select time component">
                        <SelectComponent
                            aria-label="time selection component"
                            type={"filled"}
                            name={"dateSelection"}
                            onFieldChange={(newValue, action) =>
                                this.updateSelectedField(newValue as TimeSelectOptionElementHolder, action)
                            }
                            placeHolder={"Select field..."}
                            clearable={false}
                            searchable={false}
                            className={classNames(classes.selectComponent)}
                            selectOptions={this.state.selectOptions}
                            selectedValue={this.buildSelectItem()}
                            isDisabled={false}
                        />
                    </div>
                    {/** Refresh icon */}
                    <div
                        role="button"
                        aria-label="refresh icon"
                        className={classNames(classes.refreshIconContainer, classes.marginLeft, classes.cursorPointer)}
                        onClick={this.handleRefreshClick}
                    >
                        <Tooltip title={this.getButtonTitle()} classes={{ tooltip: classes.toolTip }}>
                            <Icon
                                className={classNames("fas fa-sync-alt")}
                                fontSize={"small"}
                                classes={{
                                    fontSizeSmall: classes.syncIcon,
                                }}
                            />
                        </Tooltip>
                    </div>

                    {/** Custom range selection option  */}
                </div>

                <Popover
                    id="explanation-popover"
                    open={!!this.state.anchorElement}
                    anchorEl={this.state.anchorElement}
                    elevation={10}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                >
                    <div className={classNames(classes.flexEnd, classes.marginTop, classes.marginRight)}>
                        <CloseIcon
                            className={classes.closeButtonIcon}
                            onClick={(event) => {
                                this.onCloseTimeRangeSelection();
                            }}
                        />
                    </div>
                    {/** Display time selection */}
                    <div className={classNames(classes.customDateSelect, classes.flexSpaceAround)}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DateTimePicker
                                variant="inline"
                                margin="normal"
                                id="from-date-picker-inline"
                                label="From"
                                value={this.state.customRange.start}
                                onChange={this.setCustomStartDate}
                            />
                            <DateTimePicker
                                variant="inline"
                                margin="normal"
                                id="date-picker-inline"
                                label="To"
                                value={this.state.customRange.end}
                                onChange={this.setCustomEndDate}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div className={classNames(classes.flexEnd, classes.setButton)}>
                        <Button
                            className={classes.marginRight}
                            variant="contained"
                            color="default"
                            onClick={() => {
                                this.onCloseTimeRangeSelection();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.setCustomRange}
                            disabled={!this.validateCustomRangeSelection()}
                        >
                            Set
                        </Button>
                    </div>
                </Popover>
            </div>
        );
    }
}

export default withStyles(timeSelectionComponentStyle, { withTheme: true })(TimeSelectionComponent);
