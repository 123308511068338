import { CircularProgress } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Icon from "@material-ui/core/Icon";
import Popover from "@material-ui/core/Popover";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import * as React from "react";
import ServiceWire from "../../lib/services/ServiceWire";
import MetricInfo from "../../lib/state/MetricInfo";
import { InfoCircleIcon } from "../SUI/SternumIcon/SternumIcon";
import SpinnerLoader from "../SUI/SternumLoaders/SpinnerLoader";
import librariesMetricsDataTileStyle from "./LibrariesMetricsDataTileStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    loadingMetrics: boolean;
    errorLoadingMetrics: boolean;
    metrics: MetricInfo[];

    anchorElement;
    displayExplanationForMetricId: string;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof librariesMetricsDataTileStyle> {}

/**
 * Displays the dashboard of sternum.
 */
class LibrariesMetricsDataTile extends React.Component<AppProps, AppState> {
    private metricIdToDisplayExplanationIconMap: { [key: string]: boolean } = {
        OUT_OF_DATE_DEVICES: true,
        TOTAL_LIBRARIES_IN_USE: true,
        UPDATED_IN_LAST_MONTH_LIBRARIES: true,
    };

    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            loadingMetrics: false,
            errorLoadingMetrics: false,
            metrics: [],
            displayExplanationForMetricId: null,
            anchorElement: null,
        };
    }

    /**
     * Occurs once the component finished its initialization process.
     */
    async componentDidMount() {
        // Fetch metrics.
        await this.getMetrics();
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;
        if (this.state.loadingMetrics) {
            return (
                <div className={classNames(classes.flexCenter, classes.flexVMiddle, classes.flexGrow)}>
                    <SpinnerLoader />
                </div>
            );
        } else {
            return (
                <div>
                    {/* Explanation popover */}
                    <Popover
                        id="explanation-popover"
                        open={!!this.state.anchorElement}
                        anchorEl={this.state.anchorElement}
                        onClose={() => this.handleExplanationPopoverClosed()}
                        elevation={5}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                    >
                        {this.getExplanationComponent(this.state.displayExplanationForMetricId)}
                    </Popover>

                    {/* All metrics display */}
                    {this.state.metrics.map((metricInfo, index) => {
                        let metricValueColorClass: string;
                        const intValue = parseInt(metricInfo.metricValue);
                        if (metricInfo.isNegative) {
                            metricValueColorClass =
                                intValue > 0 ? classes.negativeKeyMetric : classes.positiveKeyMetric;
                        }
                        return (
                            <div key={metricInfo.metricId}>
                                <div className={classNames(classes.flexVMiddle, classes.metricContainer)}>
                                    {/* Display name */}
                                    <Typography variant="body2" className={classes.metricTitle}>
                                        {metricInfo.captionText}
                                    </Typography>

                                    {/* Info icon */}
                                    {!!this.metricIdToDisplayExplanationIconMap[metricInfo.metricId] && (
                                        <InfoCircleIcon
                                            color="#1B6FDE"
                                            className={classNames(classes.marginLeftXs, classes.cursorPointer)}
                                            onClick={(event) => this.metricExplanationOpened(metricInfo, event)}
                                        />
                                    )}

                                    {/* Grow separator */}
                                    <div className={classes.flexGrow} />

                                    {/* Delta display */}
                                    {metricInfo.deltaValue > 0 && (
                                        <div className={classes.flexVMiddle}>
                                            {/* Icon for delta increase/decrease */}
                                            <Icon
                                                className={classNames(
                                                    classes.metricDeltaArrowIcon,
                                                    metricInfo.deltaValue > 0 && "fa fa-long-arrow-alt-up",
                                                    metricInfo.deltaValue < 0 && "fa fa-long-arrow-alt-down",
                                                    metricInfo.isDeltaPositive && classes.positiveKeyMetric,
                                                    metricInfo.isDeltaNegative && classes.negativeKeyMetric
                                                )}
                                            />

                                            {/* Delta value */}
                                            <Typography variant="body2" className={classes.metricDeltaValue}>
                                                {metricInfo.deltaValue}%
                                            </Typography>
                                        </div>
                                    )}

                                    {/* Metric value */}
                                    <Typography
                                        variant="body2"
                                        className={classNames(classes.metricValue, metricValueColorClass)}
                                    >
                                        {metricInfo.metricValue}
                                    </Typography>
                                </div>

                                {/* Separator */}
                                {index !== this.state.metrics.length - 1 && <Divider className={classes.divider} />}
                            </div>
                        );
                    })}
                </div>
            );
        }
    }

    /**
     * Gets the metrics for the dashboard.
     */
    private async getMetrics() {
        try {
            // Setting loading state.
            this.setState({
                loadingMetrics: true,
                errorLoadingMetrics: false,
            });

            // Fetching metrics.
            let metrics = await ServiceWire.getSternumService().getLibrariesMetrics(
                ServiceWire.getClientsService().getSelectedClientId()
            );

            // Setting fetched metrics.
            this.setState({
                loadingMetrics: false,
                errorLoadingMetrics: false,
                metrics: metrics,
            });
        } catch (error) {
            // Setting error state.
            this.setState({
                loadingMetrics: false,
                errorLoadingMetrics: true,
            });
        }
    }

    /**
     * Occurs once the explanation of a metric is opened.
     */
    private metricExplanationOpened(metricInfo: MetricInfo, event) {
        this.setState({
            anchorElement: event.currentTarget,
            displayExplanationForMetricId: metricInfo.metricId,
        });
    }

    /**
     * Gets the component we need to show for explanation of the metric.
     */
    private getExplanationComponent(metricId: string) {
        const { classes } = this.props;

        switch (metricId) {
            case "OUT_OF_DATE_DEVICES":
                return (
                    <Typography variant="body2" className={classNames(classes.padding, classes.explanationTypography)}>
                        The number of devices that are currently using out-of-date libraries. An update is recommended.
                    </Typography>
                );

            case "TOTAL_LIBRARIES_IN_USE":
                return (
                    <Typography variant="body2" className={classNames(classes.padding, classes.explanationTypography)}>
                        The total number of 3ʳᵈ party components currently in use.
                    </Typography>
                );

            case "UPDATED_IN_LAST_MONTH_LIBRARIES":
                return (
                    <Typography variant="body2" className={classNames(classes.padding, classes.explanationTypography)}>
                        Number of available updates released over the past month.
                    </Typography>
                );
        }
    }

    /**
     * Occurs once the explanation popover is closed.
     */
    private handleExplanationPopoverClosed() {
        this.setState({
            anchorElement: null,
        });
    }
}

export default withStyles(librariesMetricsDataTileStyle)(LibrariesMetricsDataTile);
