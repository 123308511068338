import { Input, Typography } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import Select from "react-select";
import HashMap from "../../lib/infra/HashMap";
import SternumConfiguration from "../../lib/infra/SternumConfiguration";
import SternumQueryField from "../../lib/infra/SternumQueryField";
import Utils from "../../lib/infra/Utils";
import InputComponentType from "../../lib/state/InputComponentType";
import SternumFilter from "../../lib/state/SternumFilter";
import SternumFilterValue from "../../lib/state/SternumFilterValue";
import SelectComponent from "../DeviceDefinitionComponents/SelectComponent/SelectComponent";
import { addCounters, DropdownCounterProvider } from "../UniqueCountQuery/DropdownCounterProvider";
import { counterUpdate, formatGroupLabel, formatOptionLabel } from "../UniqueCountQuery/UniqueCountDropdown";
import getSpecialFieldSelectDisplay from "../VisualisationConfigurationComponent/utils/getSpecialFieldSelectDisplay";
import SternumFilterEditorLoadingComponent from "./SternumFilterEditorLoadingComponent";
import sternumFilterEditorStyle from "./SternumFilterEditorStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    filter: SternumFilter;

    fieldOptions?;
    selectedField?;
    conditionConfiguration?;
    relevantConditions?;

    loading?: boolean;
    error?: boolean;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof sternumFilterEditorStyle> {
    fields: SternumQueryField[];
    filter: SternumFilter;

    columnView?: boolean;
    onSternumFilterChanged: (sternumFilter: SternumFilter) => void;

    loading?: boolean;
    error?: boolean;

    theme?;

    disabled?: boolean;
}

/**
 * Single filter editor.
 */
class SternumFilterEditor extends React.Component<AppProps, AppState> {
    selectRef = React.createRef<Select>();

    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            // filter: this.props.filter ? this.props.filter : SternumFilter.getEmptyFilter(1),
            filter: this.props.filter,

            fieldOptions: null,
            selectedField: null,
            conditionConfiguration: null,
            relevantConditions: null,

            loading: this.props.loading,
            error: this.props.error,
        };
    }

    componentDidMount() {
        this.initializeFieldOptions(this.props.fields || []);
    }

    async UNSAFE_componentWillReceiveProps(nextProps: Readonly<AppProps>, nextContext: any) {
        if (nextProps.loading || nextProps.error) {
            this.setState({
                loading: nextProps.loading,
                error: nextProps.error,
            });
        }

        if (nextProps.fields) {
            this.initializeFieldOptions(nextProps.fields);
        }

        if (nextProps.filter.isDifferentFrom(this.props.filter)) {
            this.setState({ filter: nextProps.filter });
        }
    }

    /**
     * Stop propagation for enter click on select component
     */

    private stopPropagation = (e) => {
        if (e.keyCode === 13) {
            e.stopPropagation();
        }
    };

    /**
     * Renders the component.
     */
    render() {
        let { classes } = this.props;

        if (this.props.loading) {
            return <SternumFilterEditorLoadingComponent />;
        } else if (this.props.error) {
            return (
                <Typography className={classNames(classes.commonDangerColor)}>
                    Error loading visualization filter data.
                </Typography>
            );
        } else {
            const groupedSpecialFields = (this.state.fieldOptions || []).filter(
                (opt) => !!opt && opt["isSpecialField"]
            );
            const groupedNotSpecialFields = (this.state.fieldOptions || [])
                .filter((opt) => !!opt && !opt["isSpecialField"])
                .map((opt) => ({ ...opt, metricType: "argument" }));
            const groupedFields = this.state.fieldOptions
                ? [
                      ...groupedSpecialFields,
                      {
                          label: (
                              <span>
                                  Arguments{" "}
                                  <span style={{ color: "#AAAAAA" }} className="ucd-group-counter">
                                      ({Utils.numberToHumanString(groupedNotSpecialFields.length)})
                                  </span>
                              </span>
                          ),
                          options: groupedNotSpecialFields,
                      },
                  ]
                : this.state.fieldOptions;

            const displayedSelectedField = this.state.selectedField
                ? {
                      ...this.state.selectedField,
                      metricType: this.state.selectedField.isSpecialField ? undefined : "argument",
                  }
                : this.state.selectedField;

            return (
                <div
                    className={classNames(
                        classes.root,
                        !this.props.columnView && "mod-row",
                        this.props.columnView && "mod-column"
                    )}
                >
                    {/* Field */}
                    <DropdownCounterProvider>
                        {(metrics) => (
                            <div role="presentation" aria-label="filter select field">
                                <SelectComponent
                                    ref={this.selectRef}
                                    onInputChange={counterUpdate(this.selectRef)}
                                    onAfterInitialRender={() => counterUpdate(this.selectRef)("")}
                                    type="outlined"
                                    name={"fieldSelect"}
                                    onFieldChange={(newValue, action) => this.onFieldSelected(newValue, action)}
                                    placeHolder={"Select field..."}
                                    clearable={true}
                                    searchable={true}
                                    className={classNames(
                                        classes.selectComponent,
                                        classes.marginRight,
                                        this.props.columnView && classes.marginBottomXs
                                    )}
                                    selectOptions={addCounters(groupedFields, metrics)}
                                    selectedValue={displayedSelectedField}
                                    isDisabled={this.props.disabled}
                                    formatGroupLabel={formatGroupLabel}
                                    formatOptionLabel={formatOptionLabel}
                                />
                            </div>
                        )}
                    </DropdownCounterProvider>
                    {/* Condition */}

                    <div role="presentation" aria-label="filter select condition">
                        <SelectComponent
                            ariaLabel="michal select component"
                            type="outlined"
                            name={"conditionSelect"}
                            onFieldChange={(newValue, action) => this.onConditionSelected(newValue, action)}
                            placeHolder={"Select condition..."}
                            clearable={true}
                            searchable={true}
                            className={classNames(
                                classes.selectComponent,
                                classes.marginRight,
                                this.props.columnView && classes.marginBottomXs
                            )}
                            selectOptions={this.state.relevantConditions}
                            selectedValue={this.state.conditionConfiguration}
                            isDisabled={this.props.disabled || !this.state.selectedField}
                        />
                    </div>

                    {/* Value */}
                    {this.state.selectedField &&
                        this.state.conditionConfiguration &&
                        this.state.conditionConfiguration.requiredValues.map((requiredValueConfiguration, key) => {
                            if (
                                this.state.selectedField &&
                                this.state.selectedField.inputTypeConfiguration &&
                                this.state.selectedField.inputTypeConfiguration.inputComponentType ===
                                    InputComponentType.TEXT
                            ) {
                                return this.getRegularValueInputComponent(key, classes, requiredValueConfiguration);
                            } else {
                                return this.getDropdownFilterValueComponent(
                                    this.state.selectedField,
                                    requiredValueConfiguration
                                );
                            }
                        })}
                    {/* Disabled value place holder */}
                    {(!this.state.selectedField || !this.state.conditionConfiguration) && (
                        <div role="presentation" aria-label="regular value input">
                            <Input
                                className={classNames(
                                    classes.valueInputBox,
                                    classes.selectComponent,
                                    classes.marginRight
                                )}
                                disabled={true}
                            />
                        </div>
                    )}
                </div>
            );
        }
    }

    private initializeFieldOptions(fields: SternumQueryField[]) {
        let { classes } = this.props;

        let fieldOptions = fields.map((sternumQueryField) => {
            return new SternumQueryField(
                sternumQueryField.apiName,
                sternumQueryField.value,
                sternumQueryField.isSpecialField
                    ? getSpecialFieldSelectDisplay(classes, sternumQueryField.label)
                    : sternumQueryField.label,
                sternumQueryField.fieldType,
                sternumQueryField.inputTypeConfiguration,
                sternumQueryField.isSpecialField,
                sternumQueryField.onlyAllowedConditions
            );
        });

        let selectedField: SternumQueryField = Utils.findFirst(
            fieldOptions,
            (field) => this.state.filter.fieldApiName && field.apiName === this.state.filter.fieldApiName.toString(),
            (index, element) => element
        );

        // Getting relevant configuration for condition.
        let conditionConfiguration = null;
        if (this.state.filter.conditionApiName) {
            conditionConfiguration =
                SternumConfiguration.getConditionsApiNameToConfigurationMap()[this.state.filter.conditionApiName];
        }

        // Get all the condition options.
        let relevantConditions = [];
        if (selectedField) {
            relevantConditions = SternumConfiguration.getConditions(selectedField.fieldType);

            if (selectedField.onlyAllowedConditions) {
                const onlyAllowedConditionsSet = {};
                selectedField.onlyAllowedConditions.forEach(
                    (conditionType) => (onlyAllowedConditionsSet[conditionType] = true)
                );

                relevantConditions = relevantConditions.filter(
                    (relevantCondition) => onlyAllowedConditionsSet[relevantCondition.value]
                );
            }
        }

        this.setState({
            fieldOptions,
            selectedField,
            conditionConfiguration,
            relevantConditions,
        });
    }

    /**
     * Gets the component for regular input in the filter editor.
     */
    private getRegularValueInputComponent(key, classes, requiredValueConfiguration) {
        return (
            <div role="presentation" aria-label="regular value input">
                <Input
                    key={key}
                    className={classNames(
                        classes.valueInputBox,
                        classes.selectComponent,
                        classes.marginRight,
                        this.props.columnView && classes.marginBottomXs
                    )}
                    value={
                        this.state.filter.valuesMap.containsKey(requiredValueConfiguration.apiName)
                            ? this.state.filter.valuesMap.get(requiredValueConfiguration.apiName).value
                            : ""
                    }
                    onChange={(event) => this.onFilterValueChanged(requiredValueConfiguration, event)}
                    disabled={this.props.disabled}
                />
            </div>
        );
    }

    /**
     * Gets the component for dropdown selection in filter.
     */
    private getDropdownFilterValueComponent(selectedField: SternumQueryField, requiredValueConfiguration) {
        let { classes } = this.props;

        // Handling special field design.
        let dropdownOptions = selectedField.inputTypeConfiguration.dropdownOptions.map((dropdownOption) => {
            return {
                ...dropdownOption,
                label: dropdownOption["isSpecialField"]
                    ? getSpecialFieldSelectDisplay(classes, dropdownOption.label)
                    : dropdownOption.label,
            };
        });

        let defaultValue = null;
        if (
            this.state.filter &&
            this.state.filter.valuesMap &&
            this.state.filter.valuesMap.containsKey(requiredValueConfiguration.apiName)
        ) {
            const value = this.state.filter.valuesMap.get(requiredValueConfiguration.apiName).value;
            const relevantDropdownOptions = dropdownOptions.filter((dropdownOption) => dropdownOption.value === value);

            if (relevantDropdownOptions && relevantDropdownOptions.length) {
                defaultValue = relevantDropdownOptions[0];
            }
        } else if (dropdownOptions && dropdownOptions.length) {
            defaultValue = dropdownOptions[0];
        }

        return (
            <div role="presentation" aria-label="condition select dropdown">
                <SelectComponent
                    key={selectedField.apiName}
                    type="outlined"
                    name={"conditionSelection"}
                    onFieldChange={(newValue, action) =>
                        this.onFilterValueDropdownChanged(newValue, action, requiredValueConfiguration)
                    }
                    placeHolder={"Select value..."}
                    clearable={true}
                    searchable={true}
                    className={classNames(
                        classes.selectComponent,
                        classes.marginRight,
                        this.props.columnView && classes.marginBottomXs
                    )}
                    selectOptions={dropdownOptions}
                    selectedValue={defaultValue}
                    isDisabled={this.props.disabled}
                />
            </div>
        );
    }

    private onFilterValueDropdownChanged(newValue, actionObject, requiredValueConfiguration) {
        switch (actionObject.action) {
            // New value selected.
            case "select-option": {
                let clonedFilter = this.state.filter.clone();

                clonedFilter.valuesMap.put(
                    requiredValueConfiguration.apiName,
                    new SternumFilterValue(newValue.value, newValue.label)
                );

                this.setState(
                    {
                        filter: clonedFilter,
                    },
                    () => {
                        if (this.props.onSternumFilterChanged) {
                            this.props.onSternumFilterChanged(this.state.filter);
                        }
                    }
                );
                break;
            }

            // Value erased.
            case "clear": {
                let clonedFilter = this.state.filter.clone();

                clonedFilter.valuesMap.put(requiredValueConfiguration.apiName, new SternumFilterValue(null, null));

                this.setState(
                    {
                        filter: clonedFilter,
                    },
                    () => {
                        if (this.props.onSternumFilterChanged) {
                            this.props.onSternumFilterChanged(this.state.filter);
                        }
                    }
                );
                break;
            }
        }
    }

    /**
     * Occurs once a field select component option is executed.
     */
    private onFieldSelected(selectedField, actionObject) {
        switch (actionObject.action) {
            // New value selected.
            case "select-option": {
                let clonedFilter = this.state.filter.clone();

                // Setting the field.
                clonedFilter.fieldApiName = selectedField.apiName;
                if (clonedFilter.isSpecialField) {
                    clonedFilter.valuesMap = new HashMap<SternumFilterValue>();
                }

                // What are the conditions relevant for the field?
                let relevantConditionsMap =
                    SternumConfiguration.getSupportedTypeToConditionApiNameMap()[selectedField.fieldType];

                // Does the current condition satisfy the relevant conditions?
                if (!relevantConditionsMap[clonedFilter.conditionApiName]) {
                    // If it does not, we reset the condition to the default one for the type.
                    let defaultConditionType =
                        SternumConfiguration.getFieldTypeToDefaultConditionTypeMap()[selectedField.fieldType];
                    clonedFilter.conditionApiName =
                        SternumConfiguration.getConditionsMap()[defaultConditionType].apiName;
                    clonedFilter.valuesMap = new HashMap<SternumFilterValue>();
                }

                // Selecting the default value for dropdown options when possible.
                if (
                    selectedField.inputTypeConfiguration.inputComponentType === InputComponentType.DROPDOWN &&
                    selectedField.inputTypeConfiguration.dropdownOptions &&
                    selectedField.inputTypeConfiguration.dropdownOptions.length
                ) {
                    const conditionConfiguration =
                        SternumConfiguration.getConditionsApiNameToConfigurationMap()[clonedFilter.conditionApiName];

                    const selectedDropdownOption = selectedField.inputTypeConfiguration.dropdownOptions[0];

                    if (selectedDropdownOption) {
                        conditionConfiguration.requiredValues.forEach((requiredValueConfiguration) => {
                            clonedFilter.valuesMap.put(
                                requiredValueConfiguration.apiName,
                                new SternumFilterValue(selectedDropdownOption.value, selectedDropdownOption.label)
                            );
                        });
                    }
                }

                clonedFilter.isSpecialField = selectedField.isSpecialField;

                this.setState(
                    {
                        filter: clonedFilter,
                    },
                    () => {
                        if (this.props.onSternumFilterChanged) {
                            this.props.onSternumFilterChanged(this.state.filter);
                        }
                    }
                );
                break;
            }

            // Value erased.
            case "clear": {
                let clonedFilter = this.state.filter.clone();

                clonedFilter.fieldApiName = null;
                clonedFilter.conditionApiName = null;
                clonedFilter.valuesMap = new HashMap<SternumFilterValue>();

                this.setState(
                    {
                        filter: clonedFilter,
                    },
                    () => {
                        if (this.props.onSternumFilterChanged) {
                            this.props.onSternumFilterChanged(this.state.filter);
                        }
                    }
                );
                break;
            }
        }
    }

    /**
     * Occurs once a condition is selected.
     */
    private onConditionSelected(selectedCondition, actionObject) {
        if (actionObject.action === "select-option") {
            let clonedFilter = this.state.filter.clone();

            clonedFilter.conditionApiName = selectedCondition.apiName;
            clonedFilter.doesNotRequireValue = selectedCondition.doesNotRequireValue;

            this.setState(
                {
                    filter: clonedFilter,
                },
                () => {
                    if (this.props.onSternumFilterChanged) {
                        this.props.onSternumFilterChanged(this.state.filter);
                    }
                }
            );
        }
    }

    /**
     * Occurs once the value is changed.
     */
    private onFilterValueChanged(requiredValueConfiguration, event) {
        let clonedFilter = this.state.filter.clone();

        clonedFilter.valuesMap.put(
            requiredValueConfiguration.apiName,
            new SternumFilterValue(event.target.value, event.target.value)
        );

        this.setState(
            {
                filter: clonedFilter,
            },
            () => {
                if (this.props.onSternumFilterChanged) {
                    this.props.onSternumFilterChanged(this.state.filter);
                }
            }
        );
    }
}

export default withStyles(sternumFilterEditorStyle, { withTheme: true })(SternumFilterEditor);
