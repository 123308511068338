import { Action } from "redux";

import ActionType from "../ActionType";
import { IssueInfoPutReason, IssueInfoStatus } from "../../state/IssueInfoType";
import FetchError from "../../state/FetchError";
import IssueInfo from "../../state/IssueInfo";

export interface PutIssueStatusActionFields {
    issueId: string;
    status: IssueInfoStatus;
    reasons?: IssueInfoPutReason[];
    onDone?: () => unknown;
}

export interface PutIssueStatusAction extends Action<ActionType>, PutIssueStatusActionFields {
    type: ActionType.PutIssueStatus;
}

export function putIssueStatusAction(action: PutIssueStatusActionFields): PutIssueStatusAction {
    return {
        type: ActionType.PutIssueStatus,
        issueId: action.issueId,
        status: action.status,
        reasons: action.reasons,
        onDone: action.onDone,
    };
}

export interface PutIssueStatusSuccessfullyAction extends Action<ActionType> {
    type: ActionType.PutIssueStatusSuccessfully;
    updatedFields: PutIssueStatusActionFields;
    updatedIssue?: IssueInfo;
}

export function putIssueStatusSuccessfullyAction(action: {
    updatedFields: PutIssueStatusActionFields;
    updatedIssue?: IssueInfo;
}): PutIssueStatusSuccessfullyAction {
    return {
        type: ActionType.PutIssueStatusSuccessfully,
        updatedFields: action.updatedFields,
        updatedIssue: action.updatedIssue,
    };
}

export interface PutIssueStatusErrorAction extends Action<ActionType> {
    type: ActionType.PutIssueStatusError;
    issueId: string;
    status: IssueInfoStatus;
    error: FetchError;
}

export function putIssueStatusErrorAction(action: {
    issueId: string;
    status: IssueInfoStatus;
    error: FetchError;
}): PutIssueStatusErrorAction {
    return {
        type: ActionType.PutIssueStatusError,
        issueId: action.issueId,
        status: action.status,
        error: action.error,
    };
}
