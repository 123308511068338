import SternumPermission from "./SternumPermission";

/**
 * Represents a Sternum role
 */
class SternumRole {
    /**
     * Constructor.
     */
    constructor(public displayName: string, public permissions: SternumPermission[]) {}

    /**
     * Converts json to info object.
     */
    public static fromJsonObject(jsonObject: Object) {
        return new SternumRole(
            jsonObject["role_name"],
            jsonObject["permissions"].map((permission) => SternumPermission.fromJsonObject(permission))
        );
    }

    public toJsonObject() {
        return {
            role_name: this.displayName,
            permissions: this.permissions.map((permission) => permission.toJsonObject()),
        };
    }
}

export default SternumRole;
