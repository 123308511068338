import React, { MutableRefObject, useEffect, useRef } from "react";
import { Popover } from "@material-ui/core";
import { useTraceTooltipStyle } from "./TraceTooltip.style";

export interface TraceTooltipProps {
    isOpen?: boolean;
    onClose?: () => unknown;
    tooltipContent?: React.ReactNode;
    children?: (ref: MutableRefObject<any>) => React.ReactNode;
}

export function TraceTooltip({ isOpen, onClose, tooltipContent, children }: TraceTooltipProps) {
    const anchorElementRef = useRef(null);
    const classes = useTraceTooltipStyle();

    useEffect(
        () => () => {
            onClose?.();
        },
        []
    );

    return (
        <>
            {children?.(anchorElementRef)}

            <Popover
                id="trace-tooltip-explanation-popover"
                disableRestoreFocus={false}
                open={isOpen && anchorElementRef.current}
                anchorEl={anchorElementRef.current}
                onClose={onClose}
                classes={{
                    paper: classes.tooltipPopover,
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                {tooltipContent}
            </Popover>
        </>
    );
}
