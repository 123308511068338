import React from "react";
import { Typography } from "@material-ui/core";

import { useStyles } from "./TermsConditionsModal.style";
import SternumImprovedButton from "../SUI/SternumImprovedButton/SternumImprovedButton";

export interface TermsConditionsModalProps {
    onTermsAgree: () => void;
}

export function TermsConditionsModal({ onTermsAgree }: TermsConditionsModalProps) {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Typography variant="h4" className={classes.title}>
                Sternum Solution Terms of Service
            </Typography>

            <div className={classes.contents}>
                <p>
                    These Terms, as amended from time to time, together with any other terms, agreements and policies
                    referenced herein (the “<strong>Terms</strong>”) constitute a legally binding agreement between
                    Sternum Inc. (“<strong>Sternum</strong>”, “<strong>we</strong>”, “<strong>us</strong>”) and you, a
                    user of Sternum’s Solution as defined below (“<strong>You</strong>”, “<strong>you</strong>”, “
                    <strong>User</strong>”, “<strong>Customer</strong>”), effective as of the earlier of your acceptance
                    of these Terms or your access to Sternum’s website at https://www.sternumiot.com/ (the “
                    <strong>Site</strong>”) or the Solution as defined below(“<strong>Effective Date</strong>”).
                </p>
                <p>
                    The Terms govern the manner in which You may access and use Sternum's Site or Solution (as defined
                    below), in a free-tier subscription model, which provides limited, reduced functionality for the
                    protection of OpenWrt’s Linux-based firmware as made available by OpenWrt (“
                    <strong>OpenWrt Component</strong>”).
                </p>
                <p>
                    By entering to, connecting to, accessing or using the Site or Solution, you acknowledge that you
                    have read and understood these Terms, including our{" "}
                    <a href="https://www.salto.io/privacy-policy">Privacy Policy</a> available on our website (the “
                    <strong>Privacy Policy</strong>”), and you agree to be bound by the Terms and to comply with all
                    laws and regulations that apply to your use of the Site or Solution and you agree that these Terms
                    constitute a binding and enforceable legal contract between Sternum and you.
                </p>
                <p>
                    ATTENTION - PLEASE READ THESE TERMS CAREFULLY BEFORE ACCESSING OR USING THE SITE, SOLUTION OR ANY
                    RELATED-SERVICES PROVIDED BY STERNUM. IF YOU DO NOT AGREE TO THESE TERMS, PLEASE DO NOT ENTER,
                    CONNECT TO, ACCESS OR USE THE SITE, SOLUTION OR RELATED-SERVICES IN ANY MANNER.
                </p>
                <ol className="ol-level-0">
                    <li className="li-level-0">The Solution</li>
                    <ol>
                        <li>
                            <u>Sternum Solution</u>. The Solution is comprised of (i) embedded software which may be
                            embedded on the Customer’s products, known as "EIV" (the “<strong>Embedded Software</strong>
                            ”), (ii) real-time monitoring software that is provided online as Software-as-a-Service
                            (known as "ADS" the “<strong>Monitoring Software</strong>”) and other related software
                            components and related services (collectively - the “<strong>Solution</strong>”). The
                            Solution includes reduced functionality of the Embedded Software, the Monitoring Software
                            and the related components and services, which is intended to assist Customers in the
                            protection of their devices when used with the OpenWrt Component (the “
                            <strong>Device</strong>”). In order to use the Solution it is required to connect the
                            Solution to the Device (if and to the extent supported by Sternum), by providing Sternum
                            with the relevant access credentials and thereby allowing Sternum to collect and analyze
                            data concerning Customer’s configuration of its Device.
                        </li>
                        <li>
                            <u>Scope and Applicability</u>. These Terms apply only to your access and use of the Site or
                            Solution. These Terms do not apply to any access or use of the Device or any other
                            third-party components and products, which are separately governed by any such third-party
                            licenses and terms.
                        </li>
                        <li>
                            <u>Use rights</u>. Subject to your compliance with these Terms, and unless otherwise agreed
                            in writing between you and Sternum, Sternum will make the Site and Solution available to
                            you, solely to allow you to: (a) access and use the Site; (b) use the Solution for the
                            protection of a single instance of the Device; and (c) use the Solution in a hosted
                            software-as-a-service model to monitor the Device; all as permitted by and subject to these
                            Terms.
                        </li>
                        <li>
                            <u>License</u>. Subject to your compliance with these Terms, and unless otherwise agreed in
                            writing between you and Sternum, Sternum grants solely to you a limited, non-transferable,
                            non-exclusive, non-assignable, non-sub-licensable and immediately revocable at Sternum’s
                            discretion, license to access and use the Site and Solution at no cost, all solely for your
                            domestic internal purposes, in accordance with these Terms. Except as stated above, no other
                            rights in the Solution are granted to you.
                        </li>
                        <li>
                            <u>Commercial Edition:</u> The Solution provided under these Terms includes a limited,
                            reduced functionality solely for the protection of the Device, and is provided free of
                            charge to you. Should you be interested to benefit from Sternum’s fully functional,
                            commercial edition of the Solution, please do so by contacting Sternum at
                            support@sternumiot.com.
                        </li>
                        <li>
                            <u>Use Restrictions</u>
                        </li>
                        <ol type="1">
                            <li>
                                Customer’s use of the Site or Solution will comply with these Terms and all applicable
                                laws.
                            </li>
                            <li>
                                Customer may not: (i) reverse engineer, decompile, disassemble or otherwise attempt to
                                determine source code or protocols from the Site, Solution or parts thereof; (ii) bypass
                                any measures Sternum may use to prevent or restrict access to the Site or Solution,
                                and/or take any action intended to circumvent or disable the operation of any security
                                feature or measure of the Site or Solution; (iii) access the Site, Solution or Sternum’s
                                systems via any means other than through the interface provided by the Sternum, or via
                                automated means, including by crawling, scraping, caching or otherwise; (iv) use the
                                Site or Solution in any manner that is illegal or not authorized by these Terms; or (v)
                                interfere or attempt to interfere with the integrity or proper working of the Site or
                                Solution;
                            </li>
                            <li>
                                Customer agrees not to sell, resell, rent, outsource, timeshare, lease or sublicense the
                                Site or Solution or otherwise provide to any third-party access to the Site or Solution
                                or parts thereof.
                            </li>
                            <li>
                                Customer will not remove, deface, obscure, or alter Sternum's or any third party's
                                identification, attribution or copyright notices, trademarks, or other proprietary
                                rights affixed to or provided as part of the Site or Solution;
                            </li>
                            <li>
                                Customer is responsible for the performance of its Devices or any applications it
                                develops or uses with the Solution.
                            </li>
                            <li>
                                <u>Modification or Discontinuation of the Solution</u>. Sternum may continuously update
                                the Site or Solution with new capabilities or offerings or replace and/or discontinue
                                the Site or Solution or some of the capabilities without notice or liability. Sternum
                                may change the Site or Solution at any time, including the availability of any feature,
                                content or database, and may impose limitations or restrictions on certain features and
                                services or on the access to the Site or Solution (or any part thereof), without notice
                                or liability. You acknowledge and agree that some of the features and capabilities may
                                be experimental and/or offered in limited versions or limited locations. Sternum may
                                offer alternative or additional features to certain Customers, that may not be offered
                                to others.
                            </li>
                        </ol>
                    </ol>

                    <li className="li-level-0">Registration to the Solution</li>
                    <ol>
                        <li>
                            <u>Account Registration</u>. In order to use the Solution, each User shall register and
                            create an account (the “<strong>Account</strong>”). Such User will automatically be
                            considered the Account administrator (the “<strong>Administrator</strong>”).
                        </li>
                        <li>
                            <u>Account Information</u>. As part of the use of the Site or Account registration process
                            Users are required to provide Sternum with certain personal information (such as first and
                            last name, phone number, e-mail address, IP address, etc.) and to select a password (“
                            <strong>User Data</strong>”). Customer: (i) agrees to provide Sternum with accurate,
                            complete, and updated User Data; (ii) acknowledges that Customer is solely responsible for
                            the activity that occurs on its Accounts; and (iii) undertakes to notify the Sternum
                            immediately of any breach of security or unauthorized use of Customer’s Account. Customer
                            will be solely responsible for any losses incurred by the use of the Site or Solution, due
                            to any unauthorized usage of the Account caused by Customer or by use of the Account by a
                            third-party using its login credentials.
                        </li>
                        <li>
                            <u>Account Security</u>. You are responsible for maintaining the confidentiality of the
                            login credentials (e-mail and password or any other access method implemented by Sternum )
                            of your Account and for all activities that occur under your Account. You agree not to
                            disclose your login credentials to any third party, and you are responsible for any use or
                            misuse performed through your Account. We reserve the right to temporarily suspend or
                            permanently terminate your Account if we determine that you or anyone on your behalf is
                            using your Account in a manner which violates these Terms.
                        </li>
                        <li>
                            <u>User Updates</u>. If you wish to modify your Account information, or if you wish to
                            terminate your Sternum Account, you may do so by contacting Sternum support available at
                            support@sternumiot.com. Your Sternum Account will be terminated within a reasonable time
                            following your request in accordance with the Privacy Policy, and from that date you will no
                            longer be able to access your Sternum Account and the permissions, rights and licenses
                            granted to you under these Terms shall terminate.
                            <p>
                                NOTE THAT TERMINATING YOUR ACCOUNT MAY CAUSE THE LOSS AND/OR UNAVAILABILITY OF CONTENT,
                                FEATURES, OR CAPACITY WITH REGARD TO YOUR ACCOUNT. STERNUM SHALL NOT BE LIABLE IN ANY
                                WAY FOR SUCH UNAVAILABILITY AND/OR LOSS.
                            </p>
                        </li>
                    </ol>

                    <li className="li-level-0">Customer Data</li>
                    <ol>
                        <li>
                            While using the Site or Solution, certain data may be uploaded or transferred to the Site or
                            Solution to be processed on the Customer's behalf, including User Data and data and
                            information transmitted or otherwise made available by the Devices, including without
                            limitation, Device ID, IP address, location, OpenWrt Component version, Device processing
                            information and all summaries, reports, analysis, modifications and derivatives thereof (“
                            <strong>Device Data</strong>”; the Device Data and User Data will be referred to
                            collectively as the “<strong>Customer Data</strong>”). As between the Customer and Sternum,
                            all rights in the Customer Data shall remain with Customer and shall be deemed to be
                            Customer’s Confidential Information. Customer hereby grants Sternum and its Sub-processors a
                            non-exclusive, non-transferable, worldwide, royalty-free, fully paid license to access, use,
                            process, copy, download, distribute and display the Customer Data (including for the
                            collection, storage, processing, analysis, display, transfer and creation of derivatives,
                            thereof), solely for the purpose of providing the Solution to Customer and as required to
                            resolve technical and security problems for Customer or as otherwise permitted by these
                            Terms or in writing by Customer. Sternum will store and maintain Customer Data for such
                            period of time necessary for it to provide the Solution to Customer.
                        </li>
                        <li>
                            <u>Anonymous Cumulative Data</u>. Without derogating from the foregoing, you hereby grant
                            Sternum a perpetual, irrevocable, non-exclusive, worldwide, royalty-free right and license
                            to use Customer Data that has been anonymized and that cannot be used to identify or
                            otherwise understood to be related to you, solely for the purpose of internal research or
                            otherwise improving or enhancing the Solution (or any part thereof).
                        </li>
                        <li>
                            Customer represents and warrants that (i) Customer owns or has all the necessary licenses,
                            rights, consents, approvals and permissions to grant Sternum the aforementioned right and
                            license, without infringing or violating any copyrights, privacy rights, publicity rights,
                            trademarks or any other contractual, intellectual property or proprietary of any third
                            party; (ii) Customer Data and any use thereof permitted by these Terms do not and shall not
                            violate any applicable laws, including those related to data privacy or data transfer and
                            export or any policies and terms governing such Customer Data; and (iii) no personal
                            sensitive data that is protected under a special legislation and requires unique treatment
                            (such as protected health information or financial information such as credit, debit or
                            other payment card data) will be transferred to the Solution unless otherwise agreed upon by
                            the parties.
                        </li>
                        <li>
                            Customer shall not create a browser or border environment around the Site or link, including
                            in-line linking, to elements on the Site or Solution such as images, posters and videos,
                            and/or frame or mirror any part of the Site, unless as expressly permitted hereunder.
                        </li>
                        <li>
                            Customer shall not transmit, distribute, display or otherwise make available through or in
                            connection with the Site or Solution any content, including any Customer Data, which may
                            infringe third party rights, including intellectual property rights and privacy rights, or
                            which may contain any unlawful content;
                        </li>
                        <li>
                            Customer shall not transmit or otherwise make available in connection with the Site, or use
                            the Solution to distribute and/or otherwise transmit any malware or any other computer code,
                            file, or program that may or is intended to damage or hijack the operation of the Site,
                            Solution or parts thereof, or any other actually or potentially harmful, disruptive, or
                            invasive code or component;
                        </li>
                        <li>
                            Customer is solely responsible for the legality, accuracy, completeness and integrity of the
                            Customer Data, and hereby waives any and all claims it may have against Sternum in relation
                            thereof. Customer hereby agrees to indemnify Sternum against any and all costs, damages,
                            losses and penalties that Sternum may suffer in respect of any claim that Sternum’s use and
                            hosting of the Customer Data as contemplated by these Terms infringes the rights of any
                            third party.
                        </li>
                    </ol>

                    <li className="li-level-0">Intellectual Property and Right to Use</li>
                    <ol>
                        <li>
                            <u>Sternum Intellectual Property</u>. All right, title and interest in the Site and
                            Solution, including without limitation, any content, materials, software, know-how, data
                            files, documentation, code, SDK, API, design, text, media, methodologies, artwork, names,
                            logos, trademarks and services marks (excluding Customer Data), any and all related or
                            underlying technology and any updates, new versions, modifications, improvements,
                            developments or derivatives thereof, belongs to the Sternum and its licensors and these
                            Terms do not convey to the Customer or the Users any interest in or to the Solution, except
                            for a limited right of use as set forth herein, terminable in accordance with these Terms .
                        </li>
                        <li>
                            <u>Feedback</u>. Customer shall notify the Sternum of any and all design or functional
                            errors, anomalies, and problems associated with the Site or Solution discovered it, and may
                            provide the Sternum suggestions, comments or any other feedback regarding the Solution (the
                            “<strong>Feedback</strong>”). Any such Feedback shall become Sternum’s sole property without
                            any restrictions. Sternum may use any Feedback at its sole discretion, free from any right
                            of the Customer or any third party and without any obligation towards Customer. Customer
                            hereby assigns to Sternum all right, title, and interest worldwide in the Feedback and any
                            intellectual property rights related thereto, and explicitly and irrevocably waives any and
                            all rights associated therewith. Sternum may not attribute the Feedback and shall not use
                            the Feedback in any manner in which it would be attributed, directly or indirectly to
                            Customer. For clarity, the Feedback will be deemed Sternum Confidential Information and
                            Customer will not share Feedback with any third party.
                        </li>
                    </ol>

                    <li className="li-level-0">
                        <strong>
                            <u>Third Party Software and Services</u>
                        </strong>
                    </li>
                    <ol>
                        <li>
                            <u>Sub-processors</u>. Customer acknowledges that the Site, Solution or parts thereof are
                            hosted and made available by certain sub-processors of Sternum (the “
                            <strong>Sub-processors</strong>”). Sternum may remove, add or replace its Sub-processors
                            from time to time, at its sole discretion.
                        </li>
                        <li>
                            <u>The Device</u>. Sternum does not provide the Device. You are solely responsible for the
                            access, use and performance of the Device including without limitation OpenWrt’s license,
                            policies and other terms and conditions as may be provided by OpenWrt from time to time.
                        </li>
                        <li>
                            <u>Open Source Software</u>. The Site or Solution may include third party "open source" or
                            "Free Software" components that are subject to third party terms and conditions (“
                            <strong>Third-Party Terms</strong>”). If there is a conflict between any Third-Party Terms
                            and the terms of these Terms, then the Third-Party Terms shall prevail but solely in
                            connection with the related third party component. Sternum represents and warrants that it
                            is in compliance with the notice and attribution aspects of the Third-Party Terms.
                        </li>
                        <li>
                            <u>Other Products and Services</u>. The Site or Solution may contain links to other
                            third-party websites which propose services or provide information. Such links shall be
                            clearly marked as external links and Sternum shall not be in any way responsible or liable
                            with respect to any such third-party content or services.
                        </li>
                    </ol>

                    <li className="li-level-0">Term and Termination</li>
                    <ol>
                        <li>
                            These Terms shall become effective on the Effective Date and shall continue in effect for 12
                            months from the Effective Date unless terminated by Strenum immediately or by Customer upon
                            a 14-day prior written notice to Sternum. These Terms may be extended by the parties in
                            writing.
                        </li>
                        <li>
                            <u>Effect of Termination</u>. Upon termination or expiration of these Terms, Customer’s
                            license and rights granted hereunder shall terminate, and Customer shall promptly (i) cease
                            to access the Solution and shall remove any components of the Solution from the Device; (ii)
                            destroy all copies of the Solution or any parts thereof from any of its systems, computers,
                            cloud environments, and all instances of the Device; and (iii) certify to Company in writing
                            of the completion of (i) and (ii) above. Customer is solely responsible to export all
                            available Customer Data within thirty (30) days after such termination or expiration (“
                            <strong>Export Period</strong>”), and following the Export Period, except as otherwise
                            explicitly permitted herein, Sternum shall delete the Customer Data without retaining any
                            copy thereof and shall ensure that any device or system which stored or contained Customer’s
                            Confidential Information (except for backups or archived tapes) is wiped, overwritten, or
                            removed and destroyed, at a minimum, in accordance with all applicable laws. In addition,
                            Customer shall return or destroy, at Sternum’s choice, Sternum’s Confidential Information
                            then in Customer’s possession.
                        </li>
                        <li>
                            <u>Survival</u>. All the provisions of these Terms, which by their nature should survive
                            termination (including, without limitation, confidentiality, ownership and intellectual
                            property, warranty disclaimers and limitations of liability) shall remain in full force and
                            effect following termination thereof, for any reason whatsoever. Termination of these Terms
                            shall not relieve either party from any obligation arising or accruing prior to such
                            termination or limit any liability which a party otherwise may have to the other party.
                        </li>
                    </ol>

                    <li className="li-level-0">Confidentiality</li>
                    <ol>
                        <li>
                            <u>Confidential Information</u>. For purposes of these Terms , the term “
                            <strong>Confidential Information</strong>” shall mean any and all non-public business,
                            product, technology and marketing data and information, whether written, oral or in any
                            other medium disclosed or otherwise provided by either party (the “
                            <strong>Disclosing Party</strong>”) to the other party (the “
                            <strong>Receiving Party</strong>”), that is either identified as such or should reasonably
                            be understood to be confidential given the nature of the information and the circumstances
                            of disclosure. Confidential Information shall not include any information which the
                            Receiving Party can prove: (a) is publicly available at the time of disclosure or
                            subsequently becomes publicly available through no act or omission of the Receiving Party in
                            breach of these Terms ; (b) is already known to the Receiving Party at the time of
                            disclosure without obligations of confidentiality; (c) is disclosed to the Receiving Party
                            free from confidentiality obligations by a third party who is not, to the knowledge of the
                            Receiving Party, in breach of an obligation of confidentiality; or (d) was or is
                            independently developed by the Receiving Party without use of or reliance upon the
                            Confidential Information. If the Receiving Party is compelled to disclose the Disclosing
                            Party’s Confidential Information pursuant to a court order, then the Receiving Party shall,
                            to the extent permitted by law, provide the Disclosing Party prompt notice thereof, and, at
                            the request and expense of the Disclosing Party, uses reasonable efforts to limit such
                            disclosure to the extent requested.
                        </li>
                        <li>
                            <u>Confidentiality Obligations</u>. Receiving Party undertakes and warrants that: (i) it
                            shall hold the Confidential Information of Disclosing Party in confidence and shall take all
                            reasonable steps to safeguard and protect the Confidential Information including, without
                            limitation, those steps that it takes to protect its own Confidential Information of a
                            similar nature; (ii) it shall not disclose or otherwise provide any Confidential Information
                            to any third party without the prior written consent of the Disclosing Party, except to
                            those of its employees who have a need to know such Confidential Information for the purpose
                            of fulfilling these Terms and provided that such employees are bound by written
                            confidentiality obligations which are at least as restrictive as those contained herein; and
                            (iii) it shall not copy or use the Confidential Information for any purpose except to the
                            extent required to perform its obligations, or exercise its rights, hereunder, whilst
                            maintaining the Disclosing Party's interests. Receiving party’s obligations with respect to
                            Confidential Information shall expire seven (7) years from the date of termination or
                            expiration of the last Subscription Term, unless under applicable law a longer period of
                            protection applies. Sternum shall maintain and enforce safety and physical security
                            procedures with respect to its collection, possession and maintenance of Customer’s
                            Confidential Information that are at least equal to reasonable industry standards, and which
                            provide reasonably appropriate technical and organizational safeguards against accidental or
                            unlawful destruction, loss, alteration or unauthorized disclosure, removal or access of
                            Confidential Information.
                        </li>
                    </ol>

                    <li className="li-level-0">Warranties Disclaimer</li>
                    <ol>
                        <li>
                            EXCEPT AS PROVIDED HEREIN, STERNUM DOES NOT WARRANT, UNDERTAKE OR GUARANTEE THAT ANY OR ALL
                            SECURITY ATTACKS WILL BE DISCOVERED, REPORTED OR REMEDIED, OR THAT THERE WILL NOT BE ANY
                            SECURITY BREACHES OR VULNERABILITIES IN CUSTOMER’S DEVICES, PRODUCTS, SYSTEMS AND
                            TECHNOLOGY. EXCEPT AS EXPLICITLY SET FORTH HEREIN, (A) THE SITE, SOLUTION AND ANY RELATED
                            SERVICES ARE SUPPLIED ON AN “AS IS” AND “AS AVAILABLE” BASIS AND WITHOUT WARRANTIES,
                            GUARANTEES OR REPRESENTATIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, COMMON LAW
                            OR OTHERWISE; (B) STERNUM DOES NOT WARRANT THAT THE USE OF THE SITE OR SOLUTION WILL BE
                            UNINTERRUPTED, ERROR-FREE OR WILL MEET CUSTOMER’S SPECIFIC REQUIREMENTS OR EXPECTATIONS, OR
                            THAT ANY INFORMATION OR ADVICE OBTAINED BY CUSTOMER AS A RESULT OF CUSTOMER USE OF THE SITE
                            OR SOLUTION WILL BE ACCURATE OR RELIABLE. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN,
                            OBTAINED BY CUSTOMER THROUGH OR FROM THE SITE OR SOLUTION SHALL CREATE ANY WARRANTY OR
                            IMPOSE ANY LIABILITY NOT EXPRESSLY STATED IN THESE TERMS.
                        </li>
                        <li>
                            EXCEPT AS PROVIDED HEREIN, STERNUM MAKES NO WARRANTY OR REPRESENTATION, EITHER EXPRESS OR
                            IMPLIED, REGARDING THE SITE OR SOLUTION AND CUSTOMER’S USE THEREOF, INCLUDING, BUT NOT
                            LIMITED TO, ANY IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE,
                            ACCURACY, AVAILABILITY, SECURITY, COMPATIBILITY OR NON-INFRINGEMENT. CUSTOMER IS RESPONSIBLE
                            TO VERIFY THAT THE SOLUTION WILL NOT INTERFERE WITH THE OPERATION OF THE DEVICES, SUCH THAT
                            THE DEVICES SHALL PROPERLY FUNCTION EVEN IF THE SITE, SOLUTION, OR ANY PART THEREOF, IS
                            DISABLED; PROVIDED THAT STERNUM PROVIDES ALL INFORMATION AND WRITTEN NOTICE OF ANY CHANGES
                            TO CUSTOMER AND DOES NOT PUSH ANY UPDATES TO THE EMBEDDED SOFTWARE, WITHOUT THE CUSTOMER'S
                            PRIOR WRITTEN CONSENT.
                        </li>
                    </ol>

                    <li className="li-level-0">Indemnification</li>
                    <ol>
                        <li>
                            YOU RELEASE, AND AGREE, AT YOUR OWN EXPENSE, TO INDEMNIFY, DEFEND AND HOLD HARMLESS STERNUM,
                            OUR OFFICERS, DIRECTORS, EMPLOYEES, AGENTS AND AFFILIATES, FROM ALL LIABILITIES, CLAIMS,
                            ALLEGED CLAIMS, LOSS AND DAMAGES (OF EVERY KIND, WHETHER KNOWN OR UNKNOWN AND SUSPECTED OR
                            UNSUSPECTED), AND INCLUDING REASONABLE ATTORNEY’S FEES RELATED IN ANY WAY TO: (I) YOUR
                            BREACH OF ANY TERM OR CONDITION OF THESE TERMS, (II) YOUR USE OF, RELIANCE ON OR ACCESS TO
                            THE SOLUTION; (III) ANY RIGHTS OF A THIRD PARTY WITH REGARD TO YOUR CUSTOMER DATA, INCLUDING
                            PRIVACY OR INTELLECTUAL PROPERTY RIGHTS; (IV) YOUR USE OF, RELIANCE ON OR ACCESS TO ANY
                            THIRD-PARTY SOFTWARE, APPLICATIONS OR DATA RESULTING FROM YOUR USE OF SOLUTION. WE WILL
                            PROVIDE YOU WITH WRITTEN NOTICE OF SUCH CLAIM, SUIT OR ACTION AND WE WILL ALLOW YOU TO
                            ASSUME THE EXCLUSIVE DEFENSE AND CONTROL OF ANY MATTER SUBJECT TO INDEMNIFICATION BY YOU
                            HEREUNDER AS LONG AS YOU CONDUCT SUCH DEFENSE DILIGENTLY. WE RESERVE THE RIGHT, AT OUR OWN
                            EXPENSE, TO ASSUME THE EXCLUSIVE DEFENSE AND CONTROL OF ANY MATTER SUBJECT TO
                            INDEMNIFICATION BY YOU.
                        </li>
                    </ol>

                    <li className="li-level-0">
                        Limitation of Liability
                        <p>
                            NOTWITHSTANDING ANYTHING IN THESE TERMS OR ELSEWHERE TO THE CONTRARY AND TO THE FULLEST
                            EXTENT PERMITTED BY APPLICABLE LAW, STERNUM SHALL NOT BE LIABLE UNDER ANY CONTRACT,
                            NEGLIGENCE, STRICT LIABILITY, OR OTHER LEGAL OR EQUITABLE THEORY, FOR ANY TYPE OF CLAIMS,
                            DAMAGES AND LOSSES, DIRECT OR INDIRECT, ARISING OUT OF OR OTHERWISE RELATED TO THESE TERMS.
                        </p>
                    </li>

                    <li className="li-level-0">
                        <u>Miscellaneous</u>
                    </li>
                    <ol>
                        <li>
                            These Terms, as updated from time to time, comprise the entire agreement between you and us,
                            states our entire liability and your exclusive remedy with respect to the Site and Solution,
                            and supersede all prior agreements pertaining to this Agreement’s and Terms’ subject matter.
                            If any provisions of this Agreement are held to be contrary to law, then such provisions
                            shall be construed, as nearly as possible, to reflect the original provision and the other
                            provisions remain in full force and effect. Any failure to exercise or enforce any right or
                            provision of this Agreement shall not constitute a waiver of such right or provision. The
                            section titles in this Agreement are solely used for the convenience and have no legal or
                            contractual significance. These Terms do not create a partnership, franchise, joint venture,
                            agency, fiduciary or employment relationship between the parties. There are no third-party
                            beneficiaries to these Terms. This Agreement is personal to you and you may not assign any
                            of your rights or obligations hereunder. This Agreement may be assigned by Sternum without
                            restriction.
                        </li>
                        <li>
                            <u>Governing Law; Jurisdiction</u>. these Terms and its performance shall be governed by the
                            laws of the State of Israel without regard to conflict of laws’ provisions that would result
                            in the application of the laws of any other jurisdiction. The parties hereto submit the
                            exclusive jurisdiction to the competent courts in Tel-Aviv, Israel.
                        </li>
                        <li>
                            <u>Class Action Waiver</u>. WHERE PERMITTED UNDER APPLICABLE LAWS, CUSTOMER AND STERNUM
                            AGREE THAT EACH PARTY MAY BRING CLAIMS AGAINST THE OTHER PARTY ONLY IN ITS INDIVIDUAL
                            CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
                            ACTION. Unless both Customer and the Sternum agree, no arbitrator or judge may consolidate
                            more than one person’s claims or otherwise preside over any form of a representative or
                            class proceeding.
                        </li>
                    </ol>
                </ol>
            </div>

            <div className={classes.footer}>
                <SternumImprovedButton
                    fullWidth={false}
                    buttonType="regularWithDisabled"
                    content="I agree"
                    onClick={() => onTermsAgree()}
                />
            </div>
        </div>
    );
}
