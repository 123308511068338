import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
    container: {
        width: "100%",
        height: "100%",
        display: "grid",
        gap: "18px",
        gridTemplateRows: "1fr auto",
        gridTemplateColumns: "100%",
    },
}));
