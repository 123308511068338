import { makeStyles } from "@material-ui/core";

export const useDeviceMetricsStyles = makeStyles((theme) => ({
    container: {
        display: "grid",
        gridAutoColumns: "1fr",
        gridAutoFlow: "column",
        gap: 24,
        minHeight: 100,
    },
    loadingContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        overflow: "hidden",
    },
}));
