import { makeStyles } from "@material-ui/core";

export const useGlancesGridStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexFlow: "column",
        gap: 32,
        marginTop: 16,
    },

    glanceSection: {},

    glanceSectionTitle: {
        fontSize: 16,
        fontWeight: 600,
        color: "#2B2E30",
        margin: "0 0 16px 0",
    },

    glanceSectionContent: {
        display: "flex",
        gap: 24,
    },

    glanceItem: {
        height: 240,
        minWidth: 100,
        width: "100%",
    },

    predefinedLinuxVisualisationCard: {
        height: 240,
    },

    predefinedLinuxCardTitle: {
        fontSize: 16,
        fontWeight: 500,
        color: "#2B2E30",
    },

    predefinedLinuxCardIcon: {
        color: "#676E75",
    },
}));
