import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../../components/CommonStyle";

const confirmationDialogStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),
        padding: {
            padding: 18,
            paddingTop: 0,
        },
        body: {
            color: "#999999",
            fontSize: "1rem",
        },
    });

export default confirmationDialogStyle;
