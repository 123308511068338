import DeviceDefinitionVersionInfo from "./DeviceDefinitionVersionInfo";
import DeviceInfo from "./DeviceInfo";
import EntityBase from "./EntityBase";
import EntityType from "./EntityType";
import GeoLocationInfo from "./GeoLocationInfo";
import SternumTriggerInfo from "./SternumTriggerInfo";
import TableRowData from "./TableRowData";
import TraceInfo from "./TraceInfo";

/**
 * Represents a sternum generated event.
 */
class SternumGeneratedEventInfo extends EntityBase implements TableRowData {
    /**
     * Constructor.
     */
    constructor(
        public sternumGeneratedEventId: string,
        public sternumGeneratedEventIdLong: number,
        public created: number,
        public sternumTrigger: SternumTriggerInfo,
        public device: DeviceInfo,
        public relatedEntityIds: string[],
        public traces: TraceInfo[],
        public sternumGeneratedEvents: SternumGeneratedEventInfo[],
        public geoLocationInfo?: GeoLocationInfo,
        public deviceDefinitionVersion?: DeviceDefinitionVersionInfo
    ) {
        super(sternumGeneratedEventId, created, created, EntityType.SternumGeneratedEvent);
    }

    /**
     * Converts json to entity info object.
     */
    public static fromJsonObject(jsonObject: Object) {
        return new SternumGeneratedEventInfo(
            jsonObject["entity_id"],
            jsonObject["entity_id_long"],
            jsonObject["created"],
            // Check if generated event has trigger prop
            SternumTriggerInfo.fromJsonObject(jsonObject["sternum_trigger"]),
            DeviceInfo.fromJsonObject(jsonObject["device"]),
            "related_entity_ids" in jsonObject ? jsonObject["related_entity_ids"] : [],
            jsonObject["traces"] ? jsonObject["traces"].map((traceJson) => TraceInfo.fromJsonObject(traceJson)) : [],
            jsonObject["sternum_generated_events"]
                ? jsonObject["sternum_generated_events"].map((sternumGeneratedEventJson) =>
                      SternumGeneratedEventInfo.fromJsonObject(sternumGeneratedEventJson)
                  )
                : [],
            jsonObject["geo_location"] ? GeoLocationInfo.fromJsonObject(jsonObject["geo_location"]) : undefined,
            jsonObject.hasOwnProperty("device_definition_version")
                ? DeviceDefinitionVersionInfo.fromJsonObject(jsonObject["device_definition_version"])
                : undefined
        );
    }

    /**
     * Returns whether given row is any different in details than current entity info.
     * Shallow comparison, in other words.
     * @param other The row to compare with.
     */
    public isDifferentFrom(other: TableRowData): boolean {
        // Row must of of type entity info.
        if (!(other instanceof SternumGeneratedEventInfo)) {
            return true;
        }

        let otherEntityInfo = other as SternumGeneratedEventInfo;

        // Comparing base entity properties.
        if (
            otherEntityInfo.sternumGeneratedEventId !== this.sternumGeneratedEventId ||
            otherEntityInfo.created !== this.created
        ) {
            return true;
        }

        return false;
    }

    /**
     * Gets the value for the given column id.
     */
    public getColumnValue(columnHeaderId: string): any {
        return this[columnHeaderId];
    }
}

export default SternumGeneratedEventInfo;
