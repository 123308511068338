import * as React from "react";
import { useMemo, useRef, useState } from "react";

import { Popover } from "@material-ui/core";

import { ArrowDownIcon, MonitorMobileIcon } from "../../SUI/SternumIcon";

import { useLinuxTopologyDropdownStyles } from "./LinuxTopologyDropdown.styles";
import { IconContainer } from "./IconContainer";
import { DeviceGraphData, GraphData } from "../types";
import { LinuxTopologyGraphData } from "./LinuxTopologyDropdown.types";
import { LinuxTopologyGraph } from "./LinuxTopologyGraph";

export interface LinuxTopologyDropdownProps {
    deviceId: string;
    multipleDevicesGraph: GraphData<DeviceGraphData>;
    maxNumberOfLayers?: number;
}

export function LinuxTopologyDropdown({
    deviceId,
    multipleDevicesGraph,
    maxNumberOfLayers = 3,
}: LinuxTopologyDropdownProps) {
    const classes = useLinuxTopologyDropdownStyles();

    const dropdownContainerRef = useRef(null);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const dropdownGraph = useMemo(() => {
        return getDropdownGraph(deviceId, multipleDevicesGraph, maxNumberOfLayers);
    }, [deviceId, multipleDevicesGraph, maxNumberOfLayers]);

    const selectedDropdownLabel = multipleDevicesGraph.graph[deviceId]?.data.deviceId || deviceId;

    return (
        <div
            role="presentation"
            aria-label="linux topology dropdown"
            ref={dropdownContainerRef}
            className={classes.container}
            onClick={() => setIsDropdownOpen(true)}
        >
            <div className={classes.dropdown}>
                <div className={classes.dropdownValueContainer}>
                    <IconContainer>
                        <MonitorMobileIcon />
                    </IconContainer>
                    <div className={classes.dropdownValueContent}>{selectedDropdownLabel}</div>
                </div>
                <div className={classes.dropdownIconContainer}>
                    <ArrowDownIcon />
                </div>
            </div>

            <Popover
                id="linux-topology-popover"
                open={isDropdownOpen}
                anchorEl={dropdownContainerRef.current}
                onClose={(e) => {
                    e["stopPropagation"]?.();
                    setIsDropdownOpen(false);
                }}
                elevation={10}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: -8,
                    horizontal: "center",
                }}
                classes={{
                    paper: classes.popoverPaper,
                }}
            >
                <LinuxTopologyGraph linuxTopologyGraphData={dropdownGraph} />
            </Popover>
        </div>
    );
}

function getDropdownGraph(
    deviceId: string,
    multipleDevicesGraph: GraphData<DeviceGraphData>,
    maxNumberOfLayers: number
): LinuxTopologyGraphData {
    let startDeviceEntityId = getStartDeviceEntityId(deviceId, multipleDevicesGraph, maxNumberOfLayers);

    return {
        item: {
            deviceEntityId: startDeviceEntityId,
            deviceId: multipleDevicesGraph.graph[startDeviceEntityId]?.data.deviceId || "",
            isSelected: startDeviceEntityId === deviceId,
        },
        children: getLinuxTopologyGraphChildren(
            deviceId,
            startDeviceEntityId,
            multipleDevicesGraph,
            maxNumberOfLayers - 1
        ),
    };
}

function getStartDeviceEntityId(
    deviceId: string,
    multipleDevicesGraph: GraphData<DeviceGraphData>,
    maxNumberOfLayers: number
) {
    if (!multipleDevicesGraph?.root?.childIds?.length) {
        return deviceId;
    }

    let startDevice = multipleDevicesGraph.graph[deviceId];

    if (multipleDevicesGraph.graph[deviceId]?.childIds.length) {
        maxNumberOfLayers--;
    }

    while (
        startDevice?.parentId &&
        startDevice.parentId !== "root" &&
        multipleDevicesGraph.graph[startDevice.parentId] &&
        maxNumberOfLayers > 1
    ) {
        startDevice = multipleDevicesGraph.graph[startDevice.parentId];
        maxNumberOfLayers--;
    }

    return startDevice.data.deviceEntityId;
}

function getLinuxTopologyGraphChildren(
    deviceId: string,
    parentEntityId: string,
    multipleDevicesGraph: GraphData<DeviceGraphData>,
    maxNumberOfLayers: number
): LinuxTopologyGraphData[] {
    if (maxNumberOfLayers <= 0) {
        return [];
    }

    return (
        multipleDevicesGraph.graph[parentEntityId]?.childIds.map((childEntityId) => {
            return {
                item: {
                    deviceEntityId: childEntityId,
                    deviceId: multipleDevicesGraph.graph[childEntityId].data.deviceId,
                    isSelected: childEntityId === deviceId,
                },
                children: getLinuxTopologyGraphChildren(
                    deviceId,
                    childEntityId,
                    multipleDevicesGraph,
                    maxNumberOfLayers - 1
                ),
            };
        }) || []
    );
}
