import { withStyles } from "@material-ui/styles";
import { Tooltip } from "@material-ui/core";

export const TooltipStyled = withStyles({
    tooltip: {
        fontSize: 12,
        borderRadius: 10,
        padding: 14,
        backgroundColor: "white",
        color: "#999999",
        boxShadow: "0px 4px 23px rgba(26, 24, 125, 0.13)",
    },
    arrow: {
        color: "white",
    },
})(Tooltip);
