import UserInfo from "./UserInfo";

export interface UserState {
    entity: UserInfo;
}

export function getDefaultUserState(): UserState {
    return {
        entity: null,
    };
}
