import { Box, Chip, Tooltip, Typography } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { OptionTypeBase } from "react-select";
import SternumQueryField from "../../../lib/infra/SternumQueryField";
import ReceivedDefinitionsResponse from "../../../lib/services/events/ReceivedDefinitionsResponse";
import ServiceWire from "../../../lib/services/ServiceWire";
import AggregatedTraceDefinition from "../../../lib/state/AggregatedTraceDefinition";
import { ClientInfoTier } from "../../../lib/state/ClientInfo.types";
import DeviceDefinitionVersionInfo from "../../../lib/state/DeviceDefinitionVersionInfo";
import { GlobalState } from "../../../lib/state/GlobalState";
import SternumQuery from "../../../lib/state/SternumQuery";
import AggregationFunctionType from "../../../lib/state/Visualisation/AggregationFunctionType";
import VisualisationDataSourceGroupBy from "../../../lib/state/Visualisation/VisualisationConfigurationGroupBy";
import VisualisationType from "../../../lib/state/Visualisation/VisualisationType";
import EventsQuery from "../../EventsQuery/EventsQuery";
import { Chip as PremiumChip } from "../../SettingsPage/Chip";
import { DiamondIcon } from "../../SUI/SternumIcon";
import { ArrowLeftIcon, SearchIcon } from "../../SUI/SternumIcon/SternumIcon";
import { UniqueCountQuery } from "../../UniqueCountQuery/UniqueCount";
import { UniqueCountProvider } from "../../UniqueCountQuery/UniqueCountProvider";
import UIDataVisualisationConfiguration from "../entities/UIDataVisualisationConfiguration";
import VisualisationConfigurationGroupByPanel from "../VisualisationConfigurationGroupByPanel/VisualisationConfigurationGroupByPanel";
import VisualisationConfigurationSingleDataSourceSingle from "../VisualisationConfigurationSingleDataSourcSingle/VisualisationConfigurationSingleDataSourceSingle";
import visualisationConfigurationDataSourcesStyle from "./VisualisationConfigurationDataSourcesStyle";
import { openGoProModal } from "../../../lib/redux/modals/OpenModalAction";

interface AppState {
    queryInterfaceExpanded: boolean;
    groupBy?: VisualisationDataSourceGroupBy;
    groupByDefaultEditOpen: boolean;
}

export interface AppProps extends WithStyles<typeof visualisationConfigurationDataSourcesStyle> {
    lookedUpEntityId: string;
    dataVisualisationConfigurations: UIDataVisualisationConfiguration[];
    groupBy: VisualisationDataSourceGroupBy;
    loading: boolean;
    error: boolean;
    visualisationType: VisualisationType;
    traceDefinitionFields: Record<string, AggregatedTraceDefinition[]>;
    argumentDefinitionFields: Record<string, SternumQueryField[]>;
    onVisualisationConfigurationDeleted: (dataSourceKey: string) => void;
    onDataVisualisationConfigurationChanged: (
        key: string,
        dataVisualisationConfiguration: UIDataVisualisationConfiguration
    ) => void;
    onGroupByChanged: (groupBy: VisualisationDataSourceGroupBy) => void;
    onDataSourceAdded: () => void;
    onFilterApplied: (
        sternumQuery: SternumQuery,
        dataVisualisationConfiguration: UIDataVisualisationConfiguration
    ) => void;
    receivedDefinitionsResponse: Record<string, ReceivedDefinitionsResponse>;
    startTime: number;
    endTime: number;
    displayViewForDeviceDefinition: boolean;
    selectedDataSource: string;
    onDataSourceSelect: (key: string) => void;
    deviceDefinitionVersions: DeviceDefinitionVersionInfo[];
    onDeviceDefinitionVersionChange: (value: OptionTypeBase) => void;
    isFreeUser: boolean;
    openGoProModal: () => unknown;
    disableEditing?: boolean;
}

const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    const selectedClient = ServiceWire.getClientsService().getSelectedClient();
    const isFreeUser = selectedClient.isTrialTier();

    return {
        disableEditing: ownProps.disableEditing || isFreeUser,
        isFreeUser,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        openGoProModal: () => dispatch(openGoProModal("visualisation-configuration")),
    };
};

class VisualisationConfigurationDataSources extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);

        this.state = {
            queryInterfaceExpanded: false,
            groupBy: this.props.groupBy,
            groupByDefaultEditOpen: false,
        };
    }

    componentDidUpdate(prevProps: AppProps) {
        if (!isEqual(prevProps.groupBy, this.props.groupBy)) {
            this.setState({ groupBy: this.props.groupBy });
        }
    }

    private toggleQueryInterfaceExpanded = () => {
        if (this.props.isFreeUser) return;

        this.setState({ queryInterfaceExpanded: !this.state.queryInterfaceExpanded });
    };

    private handleGroupByChange = (newGroupBy: VisualisationDataSourceGroupBy) => {
        this.props.onGroupByChanged({ ...newGroupBy });

        this.setState({
            groupBy: { ...(this.props.groupBy || newGroupBy), enabled: newGroupBy.enabled },
            groupByDefaultEditOpen: newGroupBy.enabled,
        });
    };

    private getSelectedDataSource = () => {
        return this.props.dataVisualisationConfigurations.find(
            (config) => config.dataSourceKey === this.props.selectedDataSource
        );
    };

    private renderGroupByPanel = (isFreeUser: boolean) => (
        <div
            role="presentation"
            aria-label="group by panel"
            className={classNames(this.props.classes.marginBottomLarge, {
                [this.props.classes.marginTopLarge]: isFreeUser,
            })}
        >
            <VisualisationConfigurationGroupByPanel
                isFreeUser={this.props.isFreeUser}
                openGoProModal={this.props.openGoProModal}
                groupBy={this.state.groupBy}
                onGroupByApply={(groupBy) => {
                    this.props.onGroupByChanged(groupBy);
                }}
                disableEditing={this.props.disableEditing}
                onGroupByCancel={() => {
                    if (!this.props.groupBy?.enabled) {
                        this.setState((prevState) => ({
                            ...prevState,
                            groupBy: { ...prevState.groupBy, enabled: false },
                        }));
                    }
                }}
                onGroupByDisable={() => {
                    this.props.onGroupByChanged({
                        ...this.props.groupBy,
                        enabled: false,
                    });
                }}
                argumentDefinitionFields={
                    this.props.argumentDefinitionFields[this.getSelectedDataSource().deviceDefinitionVersionIds[0]] ||
                    []
                }
            />
        </div>
    );

    render() {
        const { classes } = this.props;

        const addDataSourcesBlocked =
            this.props.groupBy?.enabled && this.props.dataVisualisationConfigurations?.length >= 1;
        const selectedDataVisualisationConfiguration = this.getSelectedDataSource();

        const showGroupByPanel =
            this.state.groupBy?.enabled &&
            this.props.visualisationType !== VisualisationType.METRIC &&
            this.props.visualisationType !== VisualisationType.GEOMAP;

        let hasUniqueValue = false;

        this.props.dataVisualisationConfigurations.forEach((configuration) => {
            if (configuration.aggregationFunctionType === AggregationFunctionType.UNIQUE_COUNT) {
                hasUniqueValue = true;
            }
        });

        return (
            <div role="presentation" aria-label="visualisation configuration data sources" className={classes.root}>
                <UniqueCountProvider dataVisualisationConfigurations={[selectedDataVisualisationConfiguration]}>
                    <div className={classNames(classes.flexVMiddle)}>
                        <div
                            className={classNames(
                                classes.flexVMiddle,
                                classes.flexGrow,
                                classes.queryInterfaceTopContainer,
                                !this.props.isFreeUser && classes.cursorPointer
                            )}
                            onClick={this.toggleQueryInterfaceExpanded}
                        >
                            <SearchIcon className={classes.marginRight} />

                            {/* Events title content */}
                            <Typography variant="body2" className={classNames(classes.queryInterfaceTopTitle)}>
                                Query Interface
                            </Typography>

                            <div className={classNames(classes.flexVMiddle, classes.dataSourcesList)}>
                                {this.props.dataVisualisationConfigurations.map(
                                    (dataVisualisationConfiguration, index) => {
                                        return (
                                            <div
                                                key={dataVisualisationConfiguration.dataSourceKey}
                                                className={classNames(classes.marginRight)}
                                            >
                                                <Chip
                                                    aria-label="data source"
                                                    variant={
                                                        dataVisualisationConfiguration.dataSourceKey ===
                                                        this.props.selectedDataSource
                                                            ? "default"
                                                            : "outlined"
                                                    }
                                                    color="primary"
                                                    label={`(${index + 1}) ${
                                                        dataVisualisationConfiguration.graphLabel
                                                    }`}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        this.setState({ queryInterfaceExpanded: true });
                                                        this.props.onDataSourceSelect(
                                                            dataVisualisationConfiguration.dataSourceKey
                                                        );
                                                    }}
                                                    onDelete={
                                                        hasUniqueValue || this.props.disableEditing
                                                            ? null
                                                            : () =>
                                                                  this.props.onVisualisationConfigurationDeleted(
                                                                      dataVisualisationConfiguration.dataSourceKey
                                                                  )
                                                    }
                                                />
                                            </div>
                                        );
                                    }
                                )}

                                {!this.props.disableEditing && !hasUniqueValue && (
                                    <Tooltip
                                        title={addDataSourcesBlocked ? "You have reached the maximum amount." : ""}
                                        placement="top"
                                    >
                                        <Chip
                                            aria-label="chip"
                                            style={{ cursor: addDataSourcesBlocked ? "not-allowed" : "pointer" }}
                                            clickable={!addDataSourcesBlocked}
                                            color="primary"
                                            label="+ Add Data Source"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (!addDataSourcesBlocked) {
                                                    this.props.onDataSourceAdded();
                                                }
                                            }}
                                        />
                                    </Tooltip>
                                )}

                                {this.props.isFreeUser && (
                                    <Box sx={{ ml: 2 }}>
                                        <PremiumChip>
                                            <DiamondIcon /> Premium
                                        </PremiumChip>
                                    </Box>
                                )}
                            </div>

                            {!this.props.isFreeUser && (
                                <div className={classes.dropdownIconContainer}>
                                    <ArrowLeftIcon
                                        color="#fff"
                                        className={classNames(
                                            classes.dropdownIcon,
                                            this.state.queryInterfaceExpanded && classes.dropdownIconExpanded
                                        )}
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    {this.props.isFreeUser && !this.props.loading && showGroupByPanel && this.renderGroupByPanel(true)}

                    {!this.props.isFreeUser &&
                        this.state.queryInterfaceExpanded &&
                        selectedDataVisualisationConfiguration && (
                            <div className={classNames(classes.marginTopXl)}>
                                {/* Loading placeholder */}
                                {this.props.loading && (
                                    <div className={classNames(classes.flexVMiddle)}>
                                        <Typography className={classNames(classes.marginRight)}>Display the</Typography>
                                        <div
                                            className={classNames(
                                                classes.marginRight,
                                                classes.loadingPlaceholder,
                                                "mod-input"
                                            )}
                                        />
                                        <Typography className={classNames(classes.marginRight)}>of event</Typography>
                                        <div
                                            className={classNames(
                                                classes.marginRight,
                                                classes.loadingPlaceholder,
                                                "mod-input"
                                            )}
                                        />
                                        <Typography className={classNames(classes.marginRight)}>of argument</Typography>
                                        <div className={classNames(classes.loadingPlaceholder, "mod-input")} />
                                    </div>
                                )}
                                {/* Error placeholder */}
                                {this.props.error && (
                                    <Typography className={classNames(classes.commonDangerColor)}>
                                        Error loading visualization filter data.
                                    </Typography>
                                )}

                                {!this.props.loading && showGroupByPanel && this.renderGroupByPanel(false)}

                                {!this.props.loading && (
                                    <VisualisationConfigurationSingleDataSourceSingle
                                        disableEditing={this.props.disableEditing}
                                        dataSourceKey={selectedDataVisualisationConfiguration.dataSourceKey}
                                        lookedUpEntityId={this.props.lookedUpEntityId}
                                        hideColorPicker={
                                            this.props.visualisationType === VisualisationType.METRIC ||
                                            this.props.visualisationType === VisualisationType.VALUES_TABLE ||
                                            this.props.groupBy?.enabled
                                        }
                                        hideGroupBy={
                                            this.props.visualisationType === VisualisationType.METRIC ||
                                            this.props.visualisationType === VisualisationType.GEOMAP ||
                                            selectedDataVisualisationConfiguration.aggregationFunctionType ===
                                                AggregationFunctionType.UNIQUE_COUNT
                                        }
                                        allowGroupBy={
                                            this.props.dataVisualisationConfigurations.length === 1 &&
                                            ((this.props.visualisationType !== VisualisationType.PIE_CHART &&
                                                selectedDataVisualisationConfiguration.aggregationFunctionType !==
                                                    AggregationFunctionType.UNIQUE_COUNT) ||
                                                this.props.visualisationType === VisualisationType.PIE_CHART)
                                        }
                                        visualizationType={this.props.visualisationType}
                                        startTime={this.props.startTime}
                                        endTime={this.props.endTime}
                                        traceDefinitionFields={
                                            this.props.traceDefinitionFields[
                                                selectedDataVisualisationConfiguration.deviceDefinitionVersionIds[0]
                                            ] || []
                                        }
                                        argumentDefinitionFields={
                                            this.props.argumentDefinitionFields[
                                                selectedDataVisualisationConfiguration.deviceDefinitionVersionIds[0]
                                            ] || []
                                        }
                                        dataVisualisationConfiguration={selectedDataVisualisationConfiguration}
                                        groupBy={this.props.groupBy}
                                        displayRemoveButton={this.props.dataVisualisationConfigurations.length > 1}
                                        receivedDefinitionsResponse={
                                            this.props.receivedDefinitionsResponse[
                                                selectedDataVisualisationConfiguration.deviceDefinitionVersionIds[0]
                                            ]
                                        }
                                        onDataVisualisationConfigurationChanged={(dataVisualisationConfiguration) =>
                                            this.props.onDataVisualisationConfigurationChanged(
                                                dataVisualisationConfiguration.dataSourceKey,
                                                dataVisualisationConfiguration
                                            )
                                        }
                                        onVisualisationConfigurationGroupByToggle={this.handleGroupByChange}
                                        onVisualisationConfigurationDeleted={() =>
                                            this.props.onVisualisationConfigurationDeleted(
                                                selectedDataVisualisationConfiguration.dataSourceKey
                                            )
                                        }
                                        onFilterAdd={(sternumQuery) => {
                                            this.props.onFilterApplied(
                                                sternumQuery,
                                                selectedDataVisualisationConfiguration
                                            );
                                        }}
                                        deviceDefinitionVersions={this.props.deviceDefinitionVersions}
                                        onDeviceDefinitionVersionChange={this.props.onDeviceDefinitionVersionChange}
                                        dataSourceCount={this.props.dataVisualisationConfigurations.length}
                                    />
                                )}

                                {!this.props.loading && (
                                    <div className={classes.dataSourceFiltersContainer}>
                                        <EventsQuery
                                            disableEditing={this.props.disableEditing}
                                            loading={this.props.loading}
                                            error={this.props.error}
                                            receivedDefinitionsResponse={
                                                this.props.receivedDefinitionsResponse[
                                                    selectedDataVisualisationConfiguration.deviceDefinitionVersionIds[0]
                                                ]
                                            }
                                            sternumQuery={selectedDataVisualisationConfiguration.appliedSternumQuery}
                                            onFilterApplied={(sternumQuery) => {
                                                this.props.onFilterApplied(
                                                    sternumQuery,
                                                    selectedDataVisualisationConfiguration
                                                );
                                            }}
                                            allowDeviceIdFilter={this.props.displayViewForDeviceDefinition}
                                            isEventsLoaded
                                        />
                                        {selectedDataVisualisationConfiguration.aggregationFunctionType ===
                                            AggregationFunctionType.UNIQUE_COUNT && (
                                            <UniqueCountQuery
                                                traceDefinitionFields={
                                                    this.props.traceDefinitionFields[
                                                        selectedDataVisualisationConfiguration
                                                            .deviceDefinitionVersionIds[0]
                                                    ] || []
                                                }
                                                dataVisualisationConfiguration={selectedDataVisualisationConfiguration}
                                                onDataVisualisationConfigurationChange={(newVis) => {
                                                    this.props.onDataVisualisationConfigurationChanged(
                                                        newVis.dataSourceKey,
                                                        newVis
                                                    );
                                                }}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                </UniqueCountProvider>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(visualisationConfigurationDataSourcesStyle, { withTheme: true })(VisualisationConfigurationDataSources));
