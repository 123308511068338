import { Action } from "redux";
import ActionType from "../ActionType";
import FetchError from "../../state/FetchError";

/**
 * Occurs once there has been an error fetching a trace from API.
 */
export interface ErrorFetchingTraceAction extends Action<ActionType> {
    type: ActionType.ErrorFetchingTrace;
    traceId: string;
    error: FetchError;
}

/**
 * Occurs once there has been an error fetching a trace from API.
 */
export function errorFetchingTraceAction(traceId: string, error: FetchError): ErrorFetchingTraceAction {
    return {
        type: ActionType.ErrorFetchingTrace,
        traceId: traceId,
        error: error,
    };
}
