import PermissionInfo from "../state/PermissionInfo";
import RoleInfo from "../state/RoleInfo";
import ServerEntityType from "../state/ServerEntityType";
import SternumRole from "../state/SternumRole";
import AuthenticationService from "./AuthenticationService";
import ServiceWire from "./ServiceWire";

/**
 * Service responsible for authorization issues.
 */
class AuthorizationService {
    /**
     * The permissions of the authenticated user.
     */

    EDIT = "EDIT";
    ADD = "CREATE";
    ADMIN = "ADMIN";

    private authenticatedUserRolesMap: Record<string, Record<string, boolean>>;
    private authenticationService: AuthenticationService;
    private sternumBasicRoles: SternumRole[];
    private userRoles: RoleInfo[];

    /**
     * Constructor.
     */
    constructor(authenticationService: AuthenticationService) {
        this.authenticationService = authenticationService;
        this.authenticatedUserRolesMap = null;
    }

    public hasUserRoleAdmin(): boolean {
        if (!this.userRoles.length) {
            return false;
        }

        return this.userRoles.some((userRole) => userRole.roleName === this.ADMIN);
    }

    /**
     * Gets the roles of the authenticated user.
     */
    public async fetchUserRoles(): Promise<void> {
        if (!this.authenticatedUserRolesMap) {
            this.authenticatedUserRolesMap = {};
        }

        const userRoles: RoleInfo[] = await ServiceWire.getSternumService().getUserRoles(
            ServiceWire.getClientsService().getSelectedClient().entityId
        );

        // Caching the fetched roles.
        this.userRoles = userRoles;

        // Create permissions matrix
        this.authenticatedUserRolesMap = this.createPermissionMatrix(userRoles);
    }

    /**
     *
     * Check if user can add new type of the given resource
     */
    public canAdd(resourceEnum: ServerEntityType): boolean {
        if (this.authenticationService.getCachedAuthenticatedUser().isSternumAdmin) {
            return true;
        }

        const resourceName = ServerEntityType[resourceEnum];
        if (
            this.authenticatedUserRolesMap.hasOwnProperty(resourceName) &&
            this.authenticatedUserRolesMap[resourceName].hasOwnProperty(this.ADD)
        ) {
            return true;
        }
        return false;
    }

    /**
     *
     * Check if user can edit the given resource
     */
    public canEdit(resourceEnum: ServerEntityType): boolean {
        if (this.authenticationService.getCachedAuthenticatedUser().isSternumAdmin) {
            return true;
        }
        const resourceName = ServerEntityType[resourceEnum];
        if (
            this.authenticatedUserRolesMap.hasOwnProperty(resourceName) &&
            (this.authenticatedUserRolesMap[resourceName].hasOwnProperty(this.EDIT) ||
                this.authenticatedUserRolesMap[resourceName].hasOwnProperty(this.ADD))
        ) {
            return true;
        }
        return false;
    }

    public getCachedUserRoles() {
        return this.authenticatedUserRolesMap;
    }

    /**
     * Load Sternum basic roles.
     */
    public async fetchSternumBasicRoles() {
        const rolesList: SternumRole[] = await ServiceWire.getSternumService().getSternumBasicRoles();
        this.sternumBasicRoles = rolesList;
    }

    /**
     * Get Sternum basic roles.
     */
    public getSternumBasicRoles() {
        return this.sternumBasicRoles ? this.sternumBasicRoles : null;
    }

    /**
     *
     * Create permission matrix from roles list
     */
    private createPermissionMatrix(roles: RoleInfo[]): Record<string, Record<string, boolean>> {
        const permissionMatrix = {};

        roles.forEach((element: RoleInfo) => {
            element.permissions.forEach((element: PermissionInfo) => {
                if (!permissionMatrix.hasOwnProperty(element.getResourceName())) {
                    permissionMatrix[element.getResourceName()] = {};
                }
                permissionMatrix[element.getResourceName()][element.getOperationName()] = true;
            });
        });

        return permissionMatrix;
    }
}

export default AuthorizationService;
