import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../../CommonStyle";

const visualisationConfigurationSingleDataSourceSingleStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: {},

        selectComponent: {
            minWidth: theme.spacing(44),
        },
        percentileSelectionComponent: {
            width: theme.spacing(20),
        },

        addFilteringButton: {
            marginLeft: 10,
        },

        removeIcon: {
            cursor: "pointer",
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1),
        },

        deviceProfileSelect: {
            minHeight: 16,
            padding: 14,

            "&:focus": {
                background: "none",
            },
        },

        deviceProfileSelectRoot: {
            width: 250,
        },

        deviceProfileInput: {
            fontSize: "0.8125rem",
            borderRadius: 14,

            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#E4E4E4",
            },

            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#E4E4E4",
                borderWidth: 1,
            },
        },

        deviceProfileSelectIcon: {
            position: "absolute",
            top: "calc(50% - 10px)",
            right: 10,
            userSelect: "none",
            cursor: "pointer",
            pointerEvents: "none",
        },

        deviceProfileMenuRoot: {
            borderRadius: 14,
            boxShadow: "0px 6px 250px 0px rgba(0, 0, 0, 0.12)",
        },

        deviceProfileMenuList: {
            opacity: 1,

            "& .MuiListItem-root": {
                padding: "0 8px",

                "&.Mui-selected": {
                    backgroundColor: "#fff",
                },

                "& .MuiListItemText-root": {
                    "& .MuiTypography-root": {
                        fontSize: "0.8125rem",
                    },
                },
            },
        },

        groupByIconContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 24,
            height: 24,
            borderRadius: "50%",
            border: "1px solid #221CB6",
            cursor: "pointer",
            marginLeft: 10,

            "&.enabled": {
                background: "#221CB6",
            },

            "&.not-allowed": {
                cursor: "not-allowed",
            },
        },
    });

export default visualisationConfigurationSingleDataSourceSingleStyle;
