import React, { useState } from "react";
import { Step } from "react-joyride";
import { Typography } from "@material-ui/core";

import { ReferenceTraceSequence } from "./ReferenceTraceSequence";
import { ReceivedTraces } from "./ReceivedTraces";

import { useTraceSequenceStyle } from "./TraceSequence.style";
import { AnomalyOnboarding } from "../AnomalyOnboarding";
import { AnomaliesResponse } from "../../lib/state/Anomaly";

import { anomaliesResponsePatternsDemoData } from "./TraceSequence.model";
import { PatternWithTraces } from "../../lib/state/Pattern";

export interface TraceSequenceProps {
    anomaliesResponse?: AnomaliesResponse;
}

export function TraceSequence({ anomaliesResponse }: TraceSequenceProps) {
    const styles = useTraceSequenceStyle();

    const [selectedPatternIndex, setSelectedPatternIndex] = useState(0);
    const [showDemoData, setShowDemoData] = useState(false);

    const patternsWithTraces: PatternWithTraces[] =
        (showDemoData ? anomaliesResponsePatternsDemoData : anomaliesResponse?.patterns) || [];
    const selectedPatternIndexValue = showDemoData ? 0 : selectedPatternIndex;

    const onboardingSteps: Step[] = [
        {
            target: `[data-onboarding="anomaly-trace-sequence-traces-table"]`,
            title: "Compare received and commonly occurring sequences",
            content: (
                <Typography variant="body2">
                    Sternum suggest most common trace pattern to compare it to the received sequences.
                </Typography>
            ),
            showSkipButton: true,
            disableBeacon: true,
            placement: "left",
        },
        {
            target: `[data-onboarding="choose-reference"]`,
            title: "Choose other reference patterns",
            content: (
                <Typography variant="body2">
                    Choose other the reference sets to compare it to actual sequence of traces received.
                </Typography>
            ),
            disableBeacon: true,
            placement: "right",
        },
    ];
    const handleChangeSelectedPatternIndex = (newSelectedPatternIndex: number) => {
        setSelectedPatternIndex(newSelectedPatternIndex);
    };

    return (
        <div className={styles.container}>
            <div className={styles.column}>
                <ReferenceTraceSequence
                    patternsAndTraces={patternsWithTraces}
                    selectedPatternIndex={selectedPatternIndexValue}
                    onChangeSelectedPatternIndex={handleChangeSelectedPatternIndex}
                />
            </div>
            <div className={styles.column}>
                <ReceivedTraces
                    patternsWithTraces={patternsWithTraces}
                    selectedPatternIndex={selectedPatternIndexValue}
                />
            </div>
            <AnomalyOnboarding
                storageKey="onboarding_anomaly_trace_sequence"
                steps={onboardingSteps}
                isDemoDataTagVisible={true}
                disableScrolling={true}
                onStartOnboarding={() => setShowDemoData(true)}
                onFinishOnboarding={() => setShowDemoData(false)}
            />
        </div>
    );
}
