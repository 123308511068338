import SternumReportTraceDetails from "./SternumReportTraceDetails";
import moment from "moment";

class SternumReportDetails {
    public constructor(
        public eventType: string,
        public dateOfIncident: string,
        public alertType: string,
        public ipAddress: string,
        public investigationStatus: string,
        public alertSummaryText: string,
        public relatedEvents: SternumReportTraceDetails[],
        public receivedDeviceId: string,
        public deviceDisplayName: string,
        public firmwareVersion: string,
        public cvesCount: number,
        public outOfDateLibrariesCount: number,
        public deviceOSFamily: string,
        public deviceCPU: string,
        public country?: string,
        public city?: string,
        public state?: string
    ) {}

    /**
     * Return json object
     */
    public getJsonObject() {
        const userLocalTime = moment(new Date()).format("YYYY-MM-DD HH:mm");
        return {
            event_type: this.alertType,
            event_type1: this.alertType,
            date_received: moment(this.dateOfIncident).format("YYYY-MM-DD HH:mm"),
            exploitation_type: this.eventType,
            ip: this.ipAddress,
            event_status: this.investigationStatus,
            device_id: this.receivedDeviceId,
            device_profile: this.deviceDisplayName,
            os: this.deviceOSFamily,
            alert_summary: this.alertSummaryText,
            cpu: this.deviceCPU,
            firmware_version: this.firmwareVersion,
            cve_number: this.cvesCount ? this.cvesCount : 0,
            number_of_outdated_libraries: this.outOfDateLibrariesCount ? this.outOfDateLibrariesCount : 0,
            events: this.relatedEvents.map((event) => event.getJsonObject()),
            time_downloaded: userLocalTime,
            country: this.country,
            city: this.city,
            state: this.state,
        };
    }
}

export default SternumReportDetails;
