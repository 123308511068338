import React from "react";
import classNames from "classnames";

import { useSwitchSelectorContext } from "./SwitchSelector.context";
import { useSwitchSelectorStyle } from "./SwitchSelector.style";

export interface SwitchSelectorItemProps extends React.ComponentProps<"div"> {
    id: any;
    isSelected?: boolean;
    isDisabled?: boolean;
}

export function SwitchSelectorItem({
    className,
    onClick,
    id,
    isSelected,
    isDisabled,
    children,
    ...props
}: SwitchSelectorItemProps) {
    const classes = useSwitchSelectorStyle();

    const { selectedCase, onChangeSelectedCase } = useSwitchSelectorContext();

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (selectedCase !== id) {
            onChangeSelectedCase(id);
        }

        return onClick?.(e);
    };

    return (
        <div
            className={classNames(classes.switchSelectorItem, className)}
            aria-selected={isSelected ?? selectedCase === id}
            aria-disabled={isDisabled}
            onClick={handleClick}
            {...props}
        >
            {children}
        </div>
    );
}
