import { Action } from "redux";
import { DashboardAlertsDateRange } from "../../state/DashboardRegularState";

import ActionType from "../ActionType";

export interface ToggleAlertCategoryAction extends Action<ActionType> {
    type: ActionType.DashboardRegularToggleAlertCategory;
    category: string;
}

export function toggleAlertCategoryAction(category: string): ToggleAlertCategoryAction {
    return {
        type: ActionType.DashboardRegularToggleAlertCategory,
        category,
    };
}
