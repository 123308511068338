import { makeStyles } from "@material-ui/core";
import commonStyle from "../CommonStyle";

const useAlertDescriptionModalStyles = makeStyles((theme) => {
    return {
        ...commonStyle(theme),
        upperContentContainer: {
            paddingTop: theme.spacing(10),
            marginRight: theme.spacing(5),
            marginBottom: theme.spacing(5),
            marginLeft: theme.spacing(5),
            maxWidth: theme.spacing(250),
        },
        listPaper: {
            "&.mod-fullscreen": {
                maxHeight: "100vh",
            },
        },

        container: {
            height: "100%",
        },

        tableContainer: {
            height: "calc(100% - 107px)",
            padding: theme.spacing(4),
            paddingTop: 0,
            overflow: "auto",
            maxHeight: "100%",
        },

        tableInner: {
            height: "100%",
            padding: theme.spacing(3),
            background: "#fff",
            boxShadow: "0px 0px 23px rgba(231, 232, 235, 0.25)",
            borderRadius: 14,
        },
    };
});

export default useAlertDescriptionModalStyles;
