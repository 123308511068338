import { makeStyles, Theme } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../../../CommonStyle";

export const useStyles = makeStyles((theme: Theme) => ({
    root: {},

    descriptionText: {
        maxWidth: "80%",
        fontSize: "14px",
        color: "#6d7894",
    },

    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
    },

    infoTooltip: {
        background: "#1B1B1B",
        fontSize: "12px",
    },

    graphsContainer: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        columnGap: "15px",
        padding: "0 40px 0 40px",

        "@media (max-width: 1300px)": {
            padding: "0",
        },
    },

    graph: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },

    graphTitle: {
        fontSize: "14px",
        fontWeight: 500,
        color: "#676E75",
    },

    chart: {
        maxWidth: "100%",
        minHeight: "270px",
    },
}));
