import moment, { DurationInputArg1, DurationInputArg2 } from "moment";

import { IssuesStatisticsCategoryData, IssuesStatisticsGroupedCategoryData } from "../state/IssuesStatisticsHeatmap";
import { CategoryTimeRange, DashboardAlertsDateRange } from "../state/DashboardRegularState";

export const DAY_INTERVAL_IN_MS = 24 * 60 * 60 * 1000; // 1 day
export const WEEK_INTERVAL_IN_MS = 7 * DAY_INTERVAL_IN_MS; // 7 days
export const MONTH_INTERVAL_IN_MS = 30 * DAY_INTERVAL_IN_MS; // 30 days
export const YEAR_INTERVAL_IN_MS = 365 * DAY_INTERVAL_IN_MS; // 365 days
export const colorPalette = ["#F1F4F8", "#FFF3F7", "#FFE3ED", "#FF9FB6", "#FF7898", "#EF4F88"];
const DEFAULT_CATEGORIES = [
    "Security",
    "Filesystem",
    "Communication",
    "Crypto",
    "Operating System",
    "Updates",
    "Critical",
    "Authentication",
    "Diagnostics",
    "Regulation",
];

export function getLabelFormatBySelectedTimeRange(range: CategoryTimeRange, extended = false): string {
    switch (range) {
        case CategoryTimeRange.LastYear:
            return extended ? "MMMM" : "MMM";
        case CategoryTimeRange.LastMonth:
            return extended ? "DD MMM" : "DD";
        case CategoryTimeRange.LastWeek:
            return extended ? "dddd DD, MMM" : "ddd DD";
        case CategoryTimeRange.TimeRange:
            return extended ? "dddd DD, MMM" : "ddd DD";
    }
}

export function getMaxOrMinIssuesCount(
    data: Pick<IssuesStatisticsCategoryData, "issues_count">[],
    extreme: "max" | "min"
): number {
    if (data.length === 0) return 0;

    let value = extreme === "max" ? Number.MIN_SAFE_INTEGER : Number.MAX_SAFE_INTEGER;

    let found = false;
    data.forEach((point) => {
        const condition = extreme === "max" ? point.issues_count > value : point.issues_count < value;

        // select only non-zero values
        if (condition && point.issues_count !== 0) {
            found = true;
            value = point.issues_count;
        }
    });

    if (!found) return 0;

    return value;
}

/**
 * @example
 * breakIntoNumberSegments(8, 112, 5) // -> [8, 29, 50, 71, 92, 112]
 */
export function breakIntoNumberSegments(from: number, to: number, totalSegments: number): number[] {
    if (from === to) {
        from = 0;
    }

    const step = Math.ceil((to - from) / totalSegments);

    const segments: number[] = [];
    for (let i = from; i < to; i += step) {
        segments.push(i);
    }
    segments.push(to);

    return segments;
}

export function breakStatisticsIntoCategoryBuckets(
    issuesStatistics: IssuesStatisticsCategoryData[]
): Record<string, IssuesStatisticsCategoryData[]> {
    const categoryStatistics: Record<string, IssuesStatisticsCategoryData[]> = {};

    issuesStatistics.forEach((issue) => {
        if (!categoryStatistics[issue.category]) {
            categoryStatistics[issue.category] = [];
        }

        categoryStatistics[issue.category].push(issue);
    });

    return categoryStatistics;
}

export function fillTimeValuesGaps(issuesStatistics: IssuesStatisticsCategoryData[]): IssuesStatisticsCategoryData[] {
    const categoryStatistics = breakStatisticsIntoCategoryBuckets(issuesStatistics);
    const allDates = new Set<number>();

    issuesStatistics.forEach((issue) => {
        allDates.add(issue.period);
    });

    const result: IssuesStatisticsCategoryData[] = [];

    Object.entries(categoryStatistics).forEach(([category, categoryIssuesStatistics]) => {
        allDates.forEach((period) => {
            const existingPoint = categoryIssuesStatistics.find((point) => point.period === period);

            if (!existingPoint) {
                const label = issuesStatistics.find((point) => point.category === category).category_label;

                const missingStatistics: IssuesStatisticsCategoryData = {
                    category,
                    category_label: label,
                    period,
                    periodStart: period,
                    devices_count: 0,
                    issues_count: 0,
                };

                result.push(missingStatistics);
            } else {
                result.push(existingPoint);
            }
        });
    });

    return result;
}

export function groupIssuesWithinTimeIntervalForCustomTimeRange(
    issuesStatistics: IssuesStatisticsCategoryData[],
    timeIntervalInDays: number
): (IssuesStatisticsCategoryData | IssuesStatisticsGroupedCategoryData)[] {
    if (timeIntervalInDays < 12) {
        return fillTimeValuesGaps(issuesStatistics);
    } else if (timeIntervalInDays < 84 /* 12 * 7 */) {
        return groupIssuesWithinTimeInterval(issuesStatistics, WEEK_INTERVAL_IN_MS, {
            startDateFormat: "DD MMM",
            endDateFormat: "DD MMM",
        });
    } else if (timeIntervalInDays < 365 /* 12 * 1 month */) {
        return groupIssuesWithinTimeInterval(issuesStatistics, MONTH_INTERVAL_IN_MS, {
            startDateFormat: "DD YYYY, MMM",
            endDateFormat: "DD YYYY, MMM",
        });
    } else if (timeIntervalInDays < 2190 /* 12 * 365 / 2 */) {
        return groupIssuesWithinTimeInterval(issuesStatistics, YEAR_INTERVAL_IN_MS / 2, {
            startDateFormat: "DD YYYY, MMM",
            endDateFormat: "DD YYYY, MMM",
        });
    } else {
        return groupIssuesWithinTimeInterval(issuesStatistics, YEAR_INTERVAL_IN_MS, {
            startDateFormat: "DD YYYY, MMM",
            endDateFormat: "DD YYYY, MMM",
        });
    }
}

export function groupIssuesWithinTimeInterval(
    issuesStatistics: IssuesStatisticsCategoryData[],
    intervalMs: number,
    {
        startDateFormat = "DD",
        endDateFormat = "DD",
    }: {
        startDateFormat?: string;
        endDateFormat?: string;
    } = {}
): IssuesStatisticsGroupedCategoryData[] {
    const categoryStatistics = breakStatisticsIntoCategoryBuckets(issuesStatistics);
    let earliestAvailableDate = Number.MAX_SAFE_INTEGER;
    let latestAvailableDate = Number.MIN_SAFE_INTEGER;

    issuesStatistics.forEach((issue) => {
        if (issue.period < earliestAvailableDate) {
            earliestAvailableDate = issue.period;
        }

        if (issue.period > latestAvailableDate) {
            latestAvailableDate = issue.period;
        }
    });

    latestAvailableDate = moment.utc(latestAvailableDate).endOf("day").toDate().getTime();

    const result: IssuesStatisticsGroupedCategoryData[] = [];

    Object.entries(categoryStatistics).forEach(([category, categoryIssuesStatistics]) => {
        const label = issuesStatistics.find((point) => point.category === category).category_label;

        for (let i = earliestAvailableDate; i < latestAvailableDate; i += intervalMs) {
            const startDate = i;
            const endDate = Math.min(i + intervalMs, latestAvailableDate);

            const formattedStartDate = moment(startDate).format(startDateFormat);
            const formattedEndDate = moment(endDate).format(endDateFormat);

            const groupedStatistics: IssuesStatisticsGroupedCategoryData = {
                category,
                category_label: label,
                period: [startDate, endDate],
                periodStart: startDate,
                formattedPeriod: `${formattedStartDate}-${formattedEndDate}`,
                devices_count: 0,
                issues_count: 0,
            };

            categoryIssuesStatistics.forEach((point) => {
                if (point.period >= groupedStatistics.period[0] && point.period < groupedStatistics.period[1]) {
                    groupedStatistics.issues_count += point.issues_count;
                    groupedStatistics.devices_count += point.devices_count;
                }
            });

            result.push(groupedStatistics);
        }
    });

    return result;
}

export function convertCategoryTimeRangeToNumeric(
    range: CategoryTimeRange,
    options?: { from?: Date; to?: Date }
): DashboardAlertsDateRange {
    if (range === CategoryTimeRange.TimeRange && options) {
        return {
            from: options.from?.getTime?.(),
            to: options.to?.getTime?.(),
        };
    }

    const subtractAmountByCategoryTimeRange: Record<
        CategoryTimeRange,
        { amount: DurationInputArg1; unit: DurationInputArg2 }
    > = {
        [CategoryTimeRange.LastWeek]: { amount: 7, unit: "days" },
        [CategoryTimeRange.LastMonth]: { amount: 1, unit: "months" },
        [CategoryTimeRange.LastYear]: { amount: 1, unit: "years" },
        [CategoryTimeRange.TimeRange]: { amount: 0, unit: "days" }, // Doesn't matter
    };

    const subtractAmount = subtractAmountByCategoryTimeRange[range];
    const to = moment.utc().endOf("day").toDate().getTime();
    const from = moment(to).subtract(subtractAmount.amount, subtractAmount.unit).toDate().getTime();

    return { from, to };
}

export function generateMockedEmptyStatistics(
    from: number,
    to: number,
    intervalMs: number
): IssuesStatisticsCategoryData[] {
    const result: IssuesStatisticsCategoryData[] = [];

    DEFAULT_CATEGORIES.forEach((category) => {
        for (let date = from; date <= to; date += intervalMs) {
            result.push({
                category,
                category_label: category,
                period: date,
                periodStart: date,
                issues_count: 0,
                devices_count: 0,
            });
        }
    });

    return result;
}
