import { WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import * as React from "react";
import Utils from "../../../lib/infra/Utils";
import keyMetricStyle from "./DeviceKeyMetricStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof keyMetricStyle> {
    metricId: string;
    metricValue: string;
    deltaValue: number;
    captionText: string;
    isPositive: boolean;
    isNegative: boolean;
    isDeltaPositive: boolean;
    isDeltaNegative: boolean;
    isSelected: boolean;
    onKeyMetricClicked: (metricId: string) => void;
    notClickable?: boolean;
    isCursorAllowed?: boolean;
    color?: "danger" | "regular";
}

/**
 * Displays a single key metric in sternum.
 */
class DeviceKeyMetric extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            isSelected: this.props.isSelected,
        };
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        return (
            <div
                key={this.props.metricId}
                className={classNames(
                    classes.root,
                    this.props.isSelected && classes.selected,
                    this.props.notClickable && "mod-not-clickable",
                    this.props.isCursorAllowed && "mod-cursor-only"
                )}
                onClick={() => this.handleKeyMetricClicked()}
            >
                <div className={classNames(classes.metricNumberContainer, classes.marginRightLarge)}>
                    {/* Metric value */}
                    <Typography
                        variant={"h5"}
                        className={classNames(
                            classes.content,
                            this.props.isPositive && classes.positive,
                            this.props.isNegative && classes.negative
                        )}
                    >
                        {Utils.numberToHumanString(parseInt(this.props.metricValue))}
                    </Typography>
                </div>

                <div className={classNames(classes.flexColumn, classes.fullHeight)}>
                    <div>
                        {/* Text */}
                        <Typography className={classes.captionText} variant={"body1"}>
                            {this.props.captionText}
                        </Typography>
                    </div>

                    {/* Icon for delta increase/decrease */}

                    <div>
                        {!!this.props.deltaValue && (
                            <Typography
                                className={classNames(
                                    classes.deltaValue,
                                    this.props.isDeltaPositive && classes.positive,
                                    this.props.isDeltaNegative && classes.negative
                                )}
                            >
                                {this.props.deltaValue > 0 ? "+" : "-"} {Math.abs(this.props.deltaValue)}%
                            </Typography>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Occurs once key metric is clicked.
     */
    private handleKeyMetricClicked() {
        if (!this.props.notClickable && this.props.onKeyMetricClicked) {
            this.props.onKeyMetricClicked(this.props.metricId);
        }
    }
}

export default withStyles(keyMetricStyle)(DeviceKeyMetric);
