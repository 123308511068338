import { makeStyles, Theme } from "@material-ui/core";
import commonStyle from "../../CommonStyle";

const useSternumEventTypeDisplayStyle = makeStyles((theme: Theme) => {
    return {
        ...commonStyle(theme),
        flexRow: {
            ...commonStyle(theme).flexRow,
            "&:hover": {
                "& $contextIcon": {
                    opacity: 1,
                    width: 20,
                },
            },
        },
        statusIndication: {
            borderRadius: "4px",
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: "14px",
            height: theme.spacing(4),
            textAlign: "center",
            padding: "2px",
            "&.mod-large": {
                width: theme.spacing(20),
            },
            "&.mod-medium": {
                width: theme.spacing(10),
            },
            "&.mod-small": {
                width: theme.spacing(1),
            },
        },
        lowInterestLabel: {
            color: "#C8E0FF",
        },

        regularInterestLabel: {
            color: "#004362",
        },

        mediumInterestLabel: {
            color: "#FFCF24",
        },

        highInterestLabel: {
            color: "#E7004C",
        },

        lowInterestIndicator: {
            background: "#C8E0FF",
        },

        regularInterestIndicator: {
            background: "#004362",
        },

        mediumInterestIndicator: {
            background: "#FFCF24",
        },

        highInterestIndicator: {
            background: "#E7004C",
        },

        contextIcon: {
            marginRight: 12,
            transition: "opacity 0.3s ease-in-out, width 0.3s ease-in-out",
            opacity: 0,
            width: 0,
        },

        contextIconHidden: {
            display: "none",
        },

        contextIconPinned: {
            opacity: 1,
            width: 20,
        },
    };
});

export default useSternumEventTypeDisplayStyle;
