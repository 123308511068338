import { makeStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";

export const useChipStyle = makeStyles((theme: Theme) => ({
    chip: {
        display: "flex",
        alignItems: "center",
        gap: 2,
        padding: "6px 8px",
        fontSize: 13,
        fontWeight: 400,
        borderRadius: 12,
    },

    chipTypeYellow: {
        background: "rgba(255, 179, 0, 0.1)",
        color: "#FFB300",
    },
}));
