import { Action } from "redux";
import ActionType from "../ActionType";
import { IssuesStatisticsHeatmap } from "../../state/IssuesStatisticsHeatmap";

export interface FetchedAlertsStatisticsSuccessfullyAction extends Action<ActionType> {
    type: ActionType.FetchedAlertsStatisticsSuccessfully;
    statistics: IssuesStatisticsHeatmap;
}

export function fetchedAlertsStatisticsSuccessfullyAction(
    data: IssuesStatisticsHeatmap
): FetchedAlertsStatisticsSuccessfullyAction {
    return {
        type: ActionType.FetchedAlertsStatisticsSuccessfully,
        statistics: data,
    };
}
