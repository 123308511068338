import React from "react";

import { SternumIconProps } from "./SternumIcon.types";

export function RoundedLeftArrowIcon({ className, color, height = 44, width = 44, ...props }: SternumIconProps) {
    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.1837 25.9837C24.3387 25.8286 24.3387 25.5772 24.1837 25.4222L20.7585 21.9971L24.1837 18.5719C24.3387 18.4169 24.3387 18.1655 24.1837 18.0104C24.0286 17.8553 23.7772 17.8553 23.6221 18.0104L19.9162 21.7163C19.7612 21.8714 19.7612 22.1228 19.9162 22.2778L23.6221 25.9837C23.7772 26.1388 24.0286 26.1388 24.1837 25.9837Z"
                fill={color || "currentColor"}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.4665 26.2665C24.7778 25.9553 24.7778 25.4506 24.4665 25.1393L21.3242 21.9971L24.4665 18.8548C24.7778 18.5435 24.7778 18.0388 24.4665 17.7276C24.1552 17.4163 23.6506 17.4163 23.3393 17.7276L19.6334 21.4334C19.3221 21.7447 19.3221 22.2494 19.6334 22.5607L23.3393 26.2665C23.6506 26.5778 24.1552 26.5778 24.4665 26.2665ZM20.4313 22.2271L20.2012 21.9971L20.4313 21.767C20.3343 21.9045 20.3343 22.0896 20.4313 22.2271Z"
                fill={color || "currentColor"}
            />
            <rect
                x="-0.5"
                y="0.5"
                width="43"
                height="43"
                rx="21.5"
                transform="matrix(-1 0 0 1 43 0)"
                stroke={color || "currentColor"}
            />
        </svg>
    );
}
