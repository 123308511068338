import { useEffect, useState } from "react";
import moment from "moment";

import ServiceWire from "../../../../lib/services/ServiceWire";

import { DeviceMetricTileDirection } from "./DeviceMetricTile";
import { DeviceMetricsData, DeviceMetricsDataItem } from "./DeviceMetrics.types";

export const useDeviceMetrics = ({
    deviceId,
    startDate,
    endDate,
}: {
    deviceId: string;
    startDate: Date;
    endDate: Date;
}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [deviceMetricsData, setDeviceMetricsData] = useState<DeviceMetricsData>();

    useEffect(() => {
        setIsLoading(true);

        new Promise(async () => {
            const diffMs = moment(endDate).diff(startDate);
            const startDateMs = startDate.getTime();
            const endDateMs = endDate.getTime();

            try {
                const deviceMetrics = await ServiceWire.getSternumService().getLinuxDeviceMetrics({
                    deviceId,
                    timeRanges: [
                        {
                            from: startDateMs,
                            to: endDateMs,
                        },
                        {
                            from: startDateMs - diffMs,
                            to: startDateMs,
                        },
                    ],
                });

                function getDeviceMetricsDataItem(
                    countBefore: number = 0,
                    countAfter: number = 0
                ): DeviceMetricsDataItem {
                    let percentageValue = Math.round(((countAfter - countBefore) * 100) / (countBefore || 1));
                    percentageValue = isNaN(percentageValue) ? 0 : percentageValue;

                    return {
                        count: countAfter,
                        percentageValue: Math.abs(percentageValue),
                        arrowDirection:
                            percentageValue < 0 ? DeviceMetricTileDirection.Down : DeviceMetricTileDirection.Up,
                    };
                }

                const metricsBefore = deviceMetrics[1];
                const metricsAfter = deviceMetrics[0];

                setDeviceMetricsData({
                    alerts: getDeviceMetricsDataItem(metricsBefore?.alertsCount, metricsAfter?.alertsCount),
                    anomalies: getDeviceMetricsDataItem(metricsBefore?.anomaliesCount, metricsAfter?.anomaliesCount),
                    crashes: getDeviceMetricsDataItem(
                        metricsBefore?.tracesCounts.TRACE_CRASH,
                        metricsAfter?.tracesCounts.TRACE_CRASH
                    ),
                });
            } catch (e) {
                const emptyMetrics = { count: 0, percentageValue: 0, arrowDirection: DeviceMetricTileDirection.Up };

                setDeviceMetricsData({ alerts: emptyMetrics, anomalies: emptyMetrics, crashes: emptyMetrics });
            }

            setIsLoading(false);
        }).then();
    }, [deviceId, startDate, endDate]);

    return { isLoading, deviceMetricsData };
};
