import TraceDefinitionPartial from "../TraceDefinitionPartial";
import { default as TriggerDefinition } from "./TriggerDefinition";
import SternumQuery from "../SternumQuery";

/**
 * Represents over time trigger in sternum.
 */

class CountOverTimeDefinition extends TriggerDefinition {
    /**
     * Constructor.
     */
    constructor(
        public traceDefinition: any,
        public timeWindowCount: number,
        public timeWindowUnit: string,
        public threshold: number,
        public traceDefinitionPartial?: TraceDefinitionPartial,
        public sternumQuery?: SternumQuery
    ) {
        super();
    }

    /**
     * Converts json to trigger object.
     */
    public static fromJsonObject(jsonObject: Object) {
        // Constructing the map of arguments.
        return new CountOverTimeDefinition(
            jsonObject["trace_definition_id"],
            jsonObject["time_ago"],
            jsonObject["time_unit"],
            jsonObject["threshold"],
            null,
            jsonObject["trace_query"] ? SternumQuery.fromJsonObject(jsonObject["trace_query"]) : null
        );
    }

    /**
     * Converts json to object.
     * There are two senarios. First: trace and argument definitions doesn't exists need to save mapping for server
     * Secod: trace and argument definitions are exist, save their entity id
     */
    public getJsonObject() {
        let triggerJsonObject = {
            trace_definition_id: this.traceDefinitionPartial
                ? this.traceDefinitionPartial
                : this.traceDefinition.entityId,
            time_window: this.timeWindowCount,
            time_unit: this.timeWindowUnit,
            threshold: this.threshold,
            trace_query: this.sternumQuery ? this.sternumQuery.getServerQueryJson() : null,
        };

        return triggerJsonObject;
    }

    /**
     * Check if given trigger equals to existing one
     */
    public isEqual(other: CountOverTimeDefinition): boolean {
        return (
            this.traceDefinition == other.traceDefinition &&
            this.timeWindowCount == other.timeWindowCount &&
            this.timeWindowUnit == other.timeWindowUnit &&
            this.threshold == other.threshold
        );
    }

    /**
     * Convert class fields into json string.
     */
    public getJsonString() {
        return JSON.stringify(this.getJsonObject());
    }
}

export default CountOverTimeDefinition;
