import { BuildVersionByReleaseSystem, ReleaseSystem } from "../state/DownloadType";
import SternumService from "./SternumService";
import HttpResponse from "../state/HttpResponse";

export class DownloadService {
    public async;
    /**
     * Service used for outgoing sternum API calls.
     */
    private sternumService: SternumService;

    /**
     * Constructor.
     */
    constructor(sternumService: SternumService) {
        this.sternumService = sternumService;
    }

    public async fetchAvailableSdkBuilds(): Promise<BuildVersionByReleaseSystem> {
        const resp = await this.sternumService.getAvailableSdkBuilds();

        return resp;
    }

    public async downloadApplicationForReleaseSystem(
        releaseSystem: ReleaseSystem,
        options: {
            onProgress?: (percentProgress: number /* from 0 to 100 */) => unknown;
        } = {}
    ): Promise<HttpResponse> {
        const response = await this.sternumService.downloadApplicationForReleaseSystem(releaseSystem, {
            onProgress: options.onProgress,
        });

        return response;
    }
}
