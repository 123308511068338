import React, { Children, ReactElement, useMemo } from "react";

import { useTraceStepperStyle } from "./TraceStepper.style";
import { TraceStepperContext, TraceStepperContextType } from "./TraceStepper.context";
import { Step, TraceStepperStepProps } from "./Step.component";
import { StepSeparator } from "./StepSeparator.component";
import { TraceStepperState } from "./TraceStepper.types";

export interface TraceStepperProps {
    activeStep?: any;
    children?: ReactElement | ReactElement[];
}

export function TraceStepper({ activeStep, children }: TraceStepperProps) {
    const classes = useTraceStepperStyle();

    const contextValue = useMemo(
        (): TraceStepperContextType => ({
            activeStep: activeStep,
        }),
        [activeStep]
    );

    const numberOfSteps = Children.count(children);
    let activeStepIndex = -1;
    let state: (TraceStepperState | null)[] = [];

    Children.forEach(children, (child, index) => {
        if (React.isValidElement(child) && (child.props as TraceStepperStepProps).stepKey === activeStep) {
            activeStepIndex = index;
            return state.push(TraceStepperState.Active);
        }

        state.push(null);
    });

    state = state.map((traceStep, index) => {
        if (index < activeStepIndex) {
            return TraceStepperState.Completed;
        }

        if (index > activeStepIndex) {
            return TraceStepperState.NotVisited;
        }

        return traceStep;
    });

    return (
        <TraceStepperContext.Provider value={contextValue}>
            <div className={classes.stepperContainer}>
                {Children.map(children, (child, index) => {
                    if (React.isValidElement(child)) {
                        const props = child.props as TraceStepperStepProps;

                        return (
                            <>
                                {React.cloneElement(child, {
                                    stepKey: props.stepKey,
                                    stepState:
                                        props.stepState ||
                                        props.getStepState?.({ stepKey: props.stepKey, stepState: state[index] }) ||
                                        state[index],
                                    stepNumber: props.stepNumber || index + 1,
                                } as TraceStepperStepProps)}
                                {index + 1 !== numberOfSteps && <StepSeparator />}
                            </>
                        );
                    }

                    return null;
                })}
            </div>
        </TraceStepperContext.Provider>
    );
}

TraceStepper.Step = Step;
TraceStepper.StepSeparator = StepSeparator;
