import { Action } from "redux";
import ActionType from "../ActionType";
import FetchError from "../../state/FetchError";

export interface ErrorFetchingAlertsStatisticsAction extends Action<ActionType> {
    type: ActionType.ErrorFetchingAlertsStatistics;
    error: FetchError;
}

export function errorFetchingAlertsStatisticsAction(error: FetchError): ErrorFetchingAlertsStatisticsAction {
    return {
        type: ActionType.ErrorFetchingAlertsStatistics,
        error: error,
    };
}
