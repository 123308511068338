import DeviceDefinitionInfo from "./DeviceDefinitionInfo";
import EntityBase from "./EntityBase";
import EntityType from "./EntityType";
import TableRowData from "./TableRowData";
import UsedLibraryInfo from "./UsedLibraryInfo";

/**
 * Represents a device definition library info in sternum.
 */
class DeviceDefinitionLibraryInfo extends EntityBase implements TableRowData {
    /**
     * Constructor.
     */
    constructor(
        public entityId: string,
        public created: number,
        public updated: number,
        public deviceDefinitionId: string,
        public usedLibraryId: string,
        public libraryId: string,
        public usedLibrary: UsedLibraryInfo,
        public deviceDefinition?: DeviceDefinitionInfo,
        public librarySource?: string
    ) {
        super(entityId, created, updated, EntityType.DeviceDefinitionLibrary);
    }

    /**
     * Converts json to device definition library info object.
     */
    public static fromJsonObject(jsonObject: Object) {
        return new DeviceDefinitionLibraryInfo(
            jsonObject["entity_id"],
            jsonObject["created"],
            jsonObject["updated"],
            jsonObject["device_definition_id"],
            jsonObject["used_library_id"],
            jsonObject["library_id"],
            UsedLibraryInfo.fromJsonObject(jsonObject["used_library"])
        );
    }

    /**
     * Returns whether given row is any different in details than current device definition library info.
     * Shallow comparison, in other words.
     * @param other The row to compare with.
     */
    public isDifferentFrom(other: TableRowData): boolean {
        // Row must be of type device definition library info.
        if (!(other instanceof DeviceDefinitionLibraryInfo)) {
            return true;
        }

        let otherDeviceDefinitionLibraryInfo = other as DeviceDefinitionLibraryInfo;

        // Comparing base device definition library info properties.
        if (
            otherDeviceDefinitionLibraryInfo.entityId !== this.entityId ||
            otherDeviceDefinitionLibraryInfo.created !== this.created ||
            otherDeviceDefinitionLibraryInfo.updated !== this.updated ||
            otherDeviceDefinitionLibraryInfo.deviceDefinitionId !== this.deviceDefinitionId ||
            otherDeviceDefinitionLibraryInfo.usedLibraryId !== this.usedLibraryId ||
            otherDeviceDefinitionLibraryInfo.libraryId !== this.libraryId
        ) {
            return true;
        }

        return false;
    }

    /**
     * Gets the value for the given column id.
     */
    public getColumnValue(columnHeaderId: string): any {
        switch (columnHeaderId) {
            case "entityId":
                return this.entityId;
            case "created":
                return this.created;
            case "updated":
                return this.updated;
            case "deviceDefinitionId":
                return this.deviceDefinitionId;
            case "usedLibraryId":
                return this.usedLibraryId;
            case "libraryId":
                return this.libraryId;
        }
    }

    /**
     * Sets the device definition for the device definition library info.
     */
    public setDeviceDefinition(deviceDefinition: DeviceDefinitionInfo): void {
        this.deviceDefinition = deviceDefinition;
    }

    /**
     * Sets the library for the device definition library info.
     */
    public setUsedLibrary(usedLibrary: UsedLibraryInfo): void {
        this.usedLibrary = usedLibrary;
    }

    /**
     * Sets the library name.
     */
    public setLibraryName(libraryName: string) {
        this.usedLibrary.library.name = libraryName;
    }

    /**
     * Sets the library version.
     */
    public setLibraryVersion(libraryVersion: string) {
        this.usedLibrary.version = libraryVersion;
    }

    /**
     * Sets the library version.
     */
    public setLibraryVendor(libraryVendor: string) {
        this.usedLibrary.library.vendor = libraryVendor;
    }

    /**
     * Get json representation of the object
     */
    public toJsonObject() {
        const deviceDefinitionLibraryJson = {
            library_name: this.usedLibrary.library.name,
            library_version: this.usedLibrary.version,
            device_definition_id: this.deviceDefinitionId,
        };

        if (this.librarySource) {
            deviceDefinitionLibraryJson["library_source"] = this.librarySource;
        }

        if (this.usedLibrary.library.vendor) {
            deviceDefinitionLibraryJson["library_vendor"] = this.usedLibrary.library.vendor;
        }

        return deviceDefinitionLibraryJson;
    }

    /**
     * Get library name
     */
    public getLibraryName() {
        return this.usedLibrary.library.name;
    }

    /**
     * Get library version
     */
    public getLibraryVersion() {
        return this.usedLibrary.version;
    }

    /**
     * Get library vendor
     */
    public getLibraryVendor() {
        return this.usedLibrary.library.vendor;
    }

    /**
     * Get library source url
     */
    public getLibrarySource() {
        return this.usedLibrary.library.librarySource;
    }
}

export default DeviceDefinitionLibraryInfo;
