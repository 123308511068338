import { makeStyles, Theme } from "@material-ui/core";

export const useAlertsHeatmapStyle = makeStyles((theme: Theme) => ({
    root: {
        width: "100%",
    },

    chart: {
        width: "100%",
        height: "225px",
    },

    legend: {
        position: "relative",
        display: "flex",
        width: "fit-content",
        marginTop: "5px",
        paddingBottom: "10px",
    },

    legendSegment: {
        position: "relative",
        width: "44px",
        height: "12px",
        cursor: "pointer",

        "&:hover": {
            outline: "3px solid #fff",
            boxShadow: "0px 0px 10px rgba(9, 26, 69, 0.3)",
            zIndex: 2,
        },

        "&.selected": {
            outline: "2px solid #480966",
            zIndex: 1,
        },
    },

    legendNumber: {
        position: "absolute",
        top: "10px",
        color: "#999999",

        "&.left": {
            left: 0,
        },

        "&.right": {
            right: 0,
        },
    },

    actionContainer: {
        display: "inline-block",
    },
}));
