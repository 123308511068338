import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

const devicesListModalStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        wrapper: {
            height: "100%",
        },

        upperContentContainer: {
            paddingTop: theme.spacing(4),
            paddingRight: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            paddingLeft: theme.spacing(4),
        },
        listPaper: {
            "&.mod-regular": {
                height: theme.spacing(80),
            },
            "&.mod-fullscreen": {
                maxHeight: "100vh",
            },
        },

        title: {
            fontSize: 20,
            fontWeight: 600,
        },

        subtitle: {
            fontSize: 16,
            color: "#908C8C",
        },

        tableContainer: {
            height: "calc(100% - 95px)",
            padding: theme.spacing(4),
            paddingTop: 0,
            width: "auto",
            overflow: "auto",
            maxHeight: "100%",
        },

        tableInner: {
            height: "100%",
            padding: theme.spacing(3),
            background: "#fff",
            boxShadow: "0px 0px 23px rgba(231, 232, 235, 0.25)",
            borderRadius: 14,
        },
    });

export default devicesListModalStyle;
