import { Link, Typography, withStyles, WithStyles } from "@material-ui/core";
import classNames from "classnames";
import * as React from "react";
import { withRouter } from "react-router-dom";
import tokenExpiredStyles from "./TokenExpiredStyle";

interface AppState {}

export interface AppProps extends WithStyles<typeof tokenExpiredStyles> {
    history?: any;
}

class TokenExpired extends React.PureComponent<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {};
    }

    /**
     * Change route state
     */

    private routeChange = () => {
        let path = `/dashboard`;
        this.props.history.push(path);
    };
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Typography variant="h4">Sorry, your token has expired.</Typography>
                <Typography variant="h5">:(</Typography>
                <Link
                    className={classNames(classes.cursorPointer, classes.marginTopXXl)}
                    component="a"
                    variant="h5"
                    onClick={this.routeChange}
                >
                    Go Back Home
                </Link>
            </div>
        );
    }
}

export default withRouter(withStyles(tokenExpiredStyles)(TokenExpired));
