import { call, put, select, takeEvery } from "redux-saga/effects";
import { fetchedCveSuccessfullyAction } from "./FetchedCveSuccessfullyAction";
import { errorFetchingCveAction } from "./ErrorFetchingCveAction";
import FetchError from "../../state/FetchError";
import ActionType from "../ActionType";
import ServiceWire from "../../services/ServiceWire";
import { FetchCveAction } from "./FetchCveAction";
import cveSelector from "./CveSelector";
import CveInfo from "../../state/CveInfo";
import { fetchedLibrarySuccessfullyAction } from "../libraries/FetchedLibrarySuccessfullyAction";
import LibraryInfo from "../../state/LibraryInfo";
import { fetchedUsedLibrarySuccessfullyAction } from "../usedLibraries/FetchedUsedLibrarySuccessfullyAction";

/**
 * Fetches a cve from API.
 */
export function* fetchCveAsync(action: FetchCveAction) {
    try {
        // Trying to retrieve cve from cache.
        let cve = yield select(cveSelector, action.cveId);

        if (!cve) {
            // Cve not found in cache, calling API for getting the cve.
            let cveInfo: CveInfo = yield call(
                [ServiceWire.getSternumService(), ServiceWire.getSternumService().getCveById],
                action.cveId
            );

            // Dispatching a successful fetch of cve.
            yield put(fetchedCveSuccessfullyAction(cveInfo));
            // Dispatching a successful fetch of library.
            yield put(fetchedUsedLibrarySuccessfullyAction(cveInfo.usedLibrary));
        } else {
            // Dispatching a successful fetch of cve.
            yield put(fetchedCveSuccessfullyAction(cve));
        }
    } catch (error) {
        // Error occurred, dispatching an erroneous fetch of cve.
        yield put(errorFetchingCveAction(action.cveId, new FetchError(error.message)));
    }
}

/**
 * Watching the ActionType.FetchCve action for fetching cve from API.
 */
export function* watchFetchCveAsync() {
    yield takeEvery((action) => action.type === ActionType.FetchCve, fetchCveAsync);
}
