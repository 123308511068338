import { makeStyles } from "@material-ui/core";

export const useArgumentTagStyles = makeStyles((theme) => ({
    argumentTagContainer: {
        padding: "8px 16px",
        borderRadius: 20,
        background: "#F0F3F5",
    },

    text: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "140%",
    },

    name: {
        fontWeight: 600,
    },

    showMoreButton: {
        cursor: "pointer",
        fontSize: 14,
        fontWeight: 600,
        lineHeight: "140%",
        color: "#43484C",
    },
}));
