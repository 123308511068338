import { makeStyles } from "@material-ui/core";

export const useMainSectionStyle = makeStyles((theme) => ({
    mainSection: {
        display: "grid",
        gridTemplateRows: "auto auto minmax(0, 1fr)",
        height: "100%",
        flex: 1,
        padding: "14px 18px",
        borderRadius: 14,
        boxShadow: "0px 4px 23px rgba(231, 232, 235, 0.25)",
        background: "#FFF",
    },

    inline: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },

    content: {},

    title: {
        justifySelf: "start",
        margin: 0,
        padding: 0,
        fontSize: 18,
        lineHeight: "22px",
        color: "#2B2523",
    },

    rightActions: {},
}));
