import { useRef } from "react";

export function useAutoUpdatedRef<T>(autoUpdatedValue: T) {
    const ref = useRef<T>(autoUpdatedValue);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ref.current = autoUpdatedValue;

    return ref;
}
