import Utils from "../infra/Utils";
import ArgumentDefinitionInfo from "./ArgumentDefinitionInfo";
import DeviceDefinitionLibraryInfo from "./DeviceDefinitionLibraryInfo";
import EntityBase from "./EntityBase";
import EntityType from "./EntityType";
import SternumTriggerInfo from "./SternumTriggerInfo";
import TraceDefinitionInfo from "./TraceDefinitionInfo";
import UserInfo from "./UserInfo";

/**
 * Represents a device definition in sternum.
 */
class DeviceDefinitionInfo extends EntityBase {
    /**
     * Constructor.
     */
    constructor(
        public deviceDefinitionId: string,
        public deviceDefinitionRawId: string,
        public created: number,
        public updated: number,
        public displayName: string,
        public cpuBitness: number,
        public deviceFirmwareVersion: string,
        public deviceOSFamily: string,
        public deviceDefinitionStatus: string,
        public createdBy?: UserInfo,
        public traceDefinitions?: TraceDefinitionInfo[],
        public argumentDefinitions?: ArgumentDefinitionInfo[],
        public deviceDefinitionLibraries?: DeviceDefinitionLibraryInfo[],
        public sternumTriggers?: SternumTriggerInfo[],
        public lastDownloadOperation?: number,
        public lastTracesDefinitionChange?: number,
        public parentRawId?: string,
        public description?: string
    ) {
        super(deviceDefinitionId, created, updated, EntityType.DeviceDefinition);
        if (!traceDefinitions) {
            this.traceDefinitions = [];
        }
        if (!argumentDefinitions) {
            this.argumentDefinitions = [];
        }
        if (!deviceDefinitionLibraries) {
            this.deviceDefinitionLibraries = [];
        }
        if (!sternumTriggers) {
            this.sternumTriggers = [];
        }
    }

    /**
     * Converts json to device definition info object.
     */
    public static fromJsonObject(jsonObject: Object) {
        // Check if exists and it's not empty list
        if (
            !jsonObject.hasOwnProperty("trace_definitions") ||
            (jsonObject.hasOwnProperty["trace_definitions"] && jsonObject["trace_definitions"].length == 0)
        ) {
            jsonObject["trace_definitions"] = [];
        }

        if (
            !jsonObject.hasOwnProperty("argument_definitions") ||
            (jsonObject.hasOwnProperty["argument_definitions"] && jsonObject["argument_definitions"].length == 0)
        ) {
            jsonObject["argument_definitions"] = [];
        }

        if (
            !jsonObject.hasOwnProperty("device_definition_libraries") ||
            (jsonObject.hasOwnProperty["device_definition_libraries"] &&
                jsonObject["device_definition_libraries"].length == 0)
        ) {
            jsonObject["device_definition_libraries"] = [];
        }

        if (
            !jsonObject.hasOwnProperty("sternum_triggers") ||
            (jsonObject.hasOwnProperty["sternum_triggers"] && jsonObject["sternum_triggers"].length == 0)
        ) {
            jsonObject["sternum_triggers"] = [];
        }

        return new DeviceDefinitionInfo(
            jsonObject["entity_id"],
            jsonObject["device_definition_raw_id"]?.toString?.(),
            jsonObject["created"],
            jsonObject["updated"],
            jsonObject["device_display_name"],
            jsonObject["cpu_bitness"],
            jsonObject["device_firmware_version"],
            jsonObject["device_os_family"],
            jsonObject["device_definition_status"],
            jsonObject.hasOwnProperty("creator_user") ? UserInfo.fromJsonObject(jsonObject["creator_user"]) : null,
            jsonObject["trace_definitions"]?.map((traceDefinitionJson) =>
                TraceDefinitionInfo.fromJsonObject(traceDefinitionJson)
            ),
            jsonObject["argument_definitions"]?.map((argumentDefinitionJson) =>
                ArgumentDefinitionInfo.fromJsonObject(argumentDefinitionJson)
            ),
            jsonObject["device_definition_libraries"]?.map((deviceDefinitionLibraryJson) =>
                DeviceDefinitionLibraryInfo.fromJsonObject(deviceDefinitionLibraryJson)
            ),
            jsonObject["sternum_triggers"]?.map((triggerJson) => SternumTriggerInfo.fromJsonObject(triggerJson)),
            jsonObject["downloaded_at"],
            jsonObject["trace_definitions_updated_at"],
            jsonObject["parent_raw_id"] ? jsonObject["parent_raw_id"] : "N/A"
        );
    }

    /**
     *
     * Get json representation of device definition for update operation
     */

    public getUpdateJsonObject() {
        return {
            entity_id: this.deviceDefinitionId,
            device_display_name: this.displayName,
            cpu_bitness: this.cpuBitness,
            device_firmware_version: this.deviceFirmwareVersion,
            device_os_family: this.deviceOSFamily,
        };
    }

    /**
     *
     * Get json representation of device definition for update status operation
     */

    public getUpdateStatusJsonObject() {
        return {
            entity_id: this.deviceDefinitionId,
            device_definition_status: this.deviceDefinitionStatus,
        };
    }

    /**
     *
     * Add new trace into traces list
     */
    public addTrace(trace: TraceDefinitionInfo) {
        this.traceDefinitions.push(trace);
    }

    /**
     *
     * Remove trace from traces list
     */

    public removeTrace(trace: TraceDefinitionInfo) {
        const traceIndex = this.findTraceIndex(trace);
        if (traceIndex > -1) {
            return this.traceDefinitions.splice(traceIndex, 1);
        }
    }

    /**
     *
     * Add new deviceDefinitionLibrary into libraries list
     */
    public addDeviceDefinitionLibrary(deviceDefinitionLibrary: DeviceDefinitionLibraryInfo) {
        this.deviceDefinitionLibraries.push(deviceDefinitionLibrary);
    }

    /*
     * Add trigger to list
     */
    public addTrigger(trigger: SternumTriggerInfo) {
        this.sternumTriggers.push(trigger);
    }

    /**
     *
     * Remove library from libraries list
     */
    public removeDeviceDefinitionLibrary(library: any) {
        const libraryIndex = this.findDeviceDefinitionLibraryIndex(library);
        if (libraryIndex > -1) {
            this.deviceDefinitionLibraries.splice(libraryIndex, 1);
        }
    }

    /**
     *
     * Remove argument from arguments list
     */
    public removeArgument(argument: ArgumentDefinitionInfo) {
        const argumentIndex = this.findArgumentIndex(argument);
        if (argumentIndex > -1) {
            this.argumentDefinitions.splice(argumentIndex, 1);
        }
    }

    /**
     *
     * Remove trigger definition from definition list
     */
    public removeTrigger(trigger: SternumTriggerInfo) {
        const triggerIndex = this.findTriggerIndex(trigger);
        if (triggerIndex > -1) {
            this.sternumTriggers.splice(triggerIndex, 1);
        }
    }

    /**
     *
     * Help function to find given trace
     */
    public findTraceIndex(traceToFind: any): number {
        if (traceToFind) {
            const traceIndex = Utils.findFirstIndex(
                this.traceDefinitions,
                (trace: TraceDefinitionInfo) =>
                    trace.displayName.toLowerCase() === traceToFind.displayName.toLowerCase()
                /*
                    &&
                    trace.transmitFrequency === traceToFind.transmitFrequency &&
                    trace.traceCategory === traceToFind.traceCategory &&
                    trace.traceInterest === traceToFind.traceInterest
                    */
            );
            return traceIndex === null ? -1 : traceIndex;
        }
        return -1;
    }

    /**
     *
     * Get trace definition by id
     */
    public getTraceDefinitionById(traceId: string): TraceDefinitionInfo {
        const trace: TraceDefinitionInfo = Utils.findFirstElement(
            this.traceDefinitions,
            (trace: TraceDefinitionInfo) => trace.entityId === traceId
        );
        return trace;
    }

    /**
     *
     * Get argument definition by id
     */
    public getArgumentDefinitionById(argumentId: string): ArgumentDefinitionInfo {
        const argument: ArgumentDefinitionInfo = Utils.findFirstElement(
            this.argumentDefinitions,
            (argument: ArgumentDefinitionInfo) => argument.entityId === argumentId
        );
        return argument;
    }

    /**
     *
     * Help function to find given trigger
     */
    public findTriggerIndex(triggerToFind: SternumTriggerInfo): number {
        if (SternumTriggerInfo) {
            const triggerIndex = Utils.findFirstIndex(
                this.sternumTriggers,
                (trigger: SternumTriggerInfo) =>
                    trigger.entityId === triggerToFind.entityId &&
                    trigger.displayName === triggerToFind.displayName &&
                    trigger.targetEntityType === triggerToFind.targetEntityType &&
                    trigger.triggerType === triggerToFind.triggerType
            );
            return triggerIndex === null ? -1 : triggerIndex;
        }
        return -1;
    }

    /**
     *
     * Help function to find given argument
     */
    public findArgumentIndex(argumentToFind: any): number {
        if (argumentToFind) {
            const argumentIndex = Utils.findFirstIndex(
                this.argumentDefinitions,
                (argument: ArgumentDefinitionInfo) =>
                    argument.displayName.toLowerCase() === argumentToFind.displayName.toLowerCase()
            );
            return argumentIndex === null ? -1 : argumentIndex;
        }
        return -1;
    }

    /**
     *
     * Help function to find given library
     */
    public findDeviceDefinitionLibraryIndex(libraryToFind: any, currentEntityID?: string): number {
        if (currentEntityID) {
            const argumentIndex = Utils.findFirstIndex(
                this.deviceDefinitionLibraries,
                (library: DeviceDefinitionLibraryInfo) => library.getIdentifier() === currentEntityID
            );
            return argumentIndex === null ? -1 : argumentIndex;
        }
        if (libraryToFind) {
            const argumentIndex = Utils.findFirstIndex(
                this.deviceDefinitionLibraries,
                (library: DeviceDefinitionLibraryInfo) =>
                    library.getLibraryName() === libraryToFind.getLibraryName() &&
                    library.getLibraryVersion() === libraryToFind.getLibraryVersion() &&
                    library.getLibraryVendor() === libraryToFind.getLibraryVendor() &&
                    library.getLibrarySource() === libraryToFind.getLibrarySource()
            );
            return argumentIndex === null ? -1 : argumentIndex;
        }
        return -1;
    }

    /**
     *  Return device definition content display
     */
    public getContentDisplay(): string[] {
        return [
            `Firmware Version: ${this.deviceFirmwareVersion}`,
            `Operating System: ${this.deviceOSFamily}`,
            `Device Definition ID: ${this.deviceDefinitionRawId}`,
            this.parentRawId !== "N/A" ? `Parent Definition ID: ${this.parentRawId}` : "",
        ];
    }

    /**
     *  Return device definition content display
     */
    public isProduction(): boolean {
        return this.deviceDefinitionStatus === "PRODUCTION";
    }

    /**
     * Change device definition status, currently only to production
     */
    public changeStatusToProduction() {
        this.deviceDefinitionStatus = "PRODUCTION";
    }

    /**
     * Determine if user need to download traces definition file
     */
    public isNewTraceDefinitionsExists(): boolean {
        if (this.lastDownloadOperation && this.lastTracesDefinitionChange) {
            return this.lastTracesDefinitionChange > this.lastDownloadOperation;
        }
        return false;
    }

    /**
     * Get users full name
     */
    public getCreatorFullName(): string {
        return this.createdBy ? this.createdBy.getFullName() : "John Doe";
    }

    /**
     * Remove triggers by deleted trace definition
     */
    public deleteAssociatedTriggersByTraceDefinition(traceDefinitionId): void {
        const triggersList = this.sternumTriggers.filter((triggerIter: SternumTriggerInfo) => {
            return triggerIter?.triggerDefinition?.traceDefinition === traceDefinitionId;
        });

        triggersList.forEach((element) => {
            this.removeTrigger(element);
        });
    }

    /**
     * Remove triggers by deleted argument definition
     */
    public deleteAssociatedTriggersByArgumentDefinition(argumentDefinitionId): void {
        const triggersList = this.sternumTriggers.filter((triggerIter: SternumTriggerInfo) => {
            return triggerIter?.triggerDefinition?.argumentDefinition === argumentDefinitionId;
        });

        triggersList.forEach((element) => {
            this.removeTrigger(element);
        });
    }

    /**
     *
     * Clone function to create new device definition instance.
     */
    public static cloneObject(toClone: DeviceDefinitionInfo): DeviceDefinitionInfo {
        return new DeviceDefinitionInfo(
            toClone.entityId,
            toClone.deviceDefinitionRawId,
            toClone.created,
            toClone.updated,
            toClone.displayName,
            toClone.cpuBitness,
            toClone.deviceFirmwareVersion,
            toClone.deviceOSFamily,
            toClone.deviceDefinitionStatus,
            toClone.createdBy,
            toClone.traceDefinitions,
            toClone.argumentDefinitions,
            toClone.deviceDefinitionLibraries,
            toClone.sternumTriggers
        );
    }
}

export default DeviceDefinitionInfo;
