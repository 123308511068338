import { Action } from "redux";
import ActionType from "../ActionType";

export interface ErrorFetchingPendingIssuesCountAction extends Action<ActionType> {
    type: ActionType.ErrorFetchingPendingIssuesCount;
}

export function errorFetchingPendingIssuesCountAction(): ErrorFetchingPendingIssuesCountAction {
    return {
        type: ActionType.ErrorFetchingPendingIssuesCount,
    };
}
