import { put, takeEvery } from "redux-saga/effects";
import ModalType from "../../state/ModalType";
import ActionType from "../ActionType";
import { cveNotViewedAction } from "../currentlyViewedCveId/CveNotViewedAction";
import { deviceNotViewedAction } from "../currentlyViewedDeviceId/DeviceNotViewedAction";
import { generatedEventNotViewedAction } from "../currentlyViewedGeneratedEventId/GeneratedEventNotViewedAction";
import { libraryNotViewedAction } from "../currentlyViewedLibraryId/LibraryNotViewedAction";
import { traceNotViewedAction } from "../currentlyViewedTraceId/TraceNotViewedAction";
import { usedLibraryNotViewedAction } from "../currentlyViewedUsedLibraryId/UsedLibraryNotViewedAction";
import { CloseModalAction } from "./CloseModalAction";

export function* handleCloseModal(action: CloseModalAction) {
    switch (action.modalKey.modalType) {
        case ModalType.DeviceViewModal:
            yield put(deviceNotViewedAction());
            break;

        case ModalType.TraceViewModal:
            yield put(traceNotViewedAction());
            break;

        case ModalType.LibraryViewModal:
            yield put(libraryNotViewedAction());
            break;

        case ModalType.CveViewModal:
            yield put(cveNotViewedAction());
            break;

        case ModalType.UsedLibraryViewModal:
            yield put(usedLibraryNotViewedAction());
            break;

        case ModalType.SternumGeneratedEventViewModal:
            yield put(generatedEventNotViewedAction());
            break;
    }
}

export function* watchCloseModalAsync() {
    yield takeEvery((action) => action.type === ActionType.CloseModal, handleCloseModal);
}
