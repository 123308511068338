import { Dialog, DialogActions, DialogTitle, IconButton, Typography, withStyles, WithStyles } from "@material-ui/core";
import classNames from "classnames";
import * as React from "react";
import StatusDisplay from "../../components/StatusDisplay/StatusDisplay";
import ServiceWire from "../../lib/services/ServiceWire";
import PasswordStrength from "../SUI/PasswordStrength/PasswordStrength";
import { CrossIcon } from "../SUI/SternumIcon/SternumIcon";
import SternumImprovedButton from "../SUI/SternumImprovedButton/SternumImprovedButton";
import SternumInputField from "../SUI/SternumInputField/SternumInputField";
import changePasswordDialogStyle from "./ChangePasswordDialogStyle";
import zxcvbn from "zxcvbn";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    currentPassword: string;
    newPassword: string;
    repeatPassword: string;
    passwordStrength: number;
    isRepeatPasswordMatch: boolean;
    displayLoadingBar: boolean;
    displayErrorMessage: boolean;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof changePasswordDialogStyle> {
    onKeyPress?: () => void;
    open: boolean;
    onCloseClick: () => void;
}

/**
 * Change password dialog
 */
class ChangePasswordDialog extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    minimumPasswordLength: number = 12;
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            currentPassword: "",
            newPassword: "",
            repeatPassword: "",
            passwordStrength: -1,
            isRepeatPasswordMatch: false,
            displayLoadingBar: false,
            displayErrorMessage: false,
        };
    }

    async componentDidMount() {}

    /**
     * On current password changed.
     */
    private onCurrentPasswordChange = (event) => {
        this.setState({ currentPassword: event.target.value });
    };

    /**
     * On password changed.
     */
    private onNewPasswordChange = (event) => {
        this.setState({ newPassword: event.target.value, passwordStrength: zxcvbn(event.target.value).score });
    };

    /**
     * ON repeat password changed.
     */
    private OnRepeatPasswordChange = (event) => {
        const repeatPassword = event.target.value;
        const compareResult = this.state.newPassword === repeatPassword;
        const isStrong = this.state.passwordStrength > 1;
        const isLength = repeatPassword.length >= this.minimumPasswordLength;
        this.setState({
            repeatPassword: event.target.value,
            isRepeatPasswordMatch: compareResult,
        });
    };

    /***
     * Get password strength indicator
     */
    private getPasswordStatus = () => {
        switch (this.state.passwordStrength) {
            case 0: {
                return <StatusDisplay veryWeakPassword={true} label="Very Weak" displayColoredBackground={true} />;
            }
            case 1: {
                return <StatusDisplay weakPassword={true} label="Weak" displayColoredBackground={true} />;
            }
            case 2: {
                return <StatusDisplay mediumPassword={true} label="Medium" displayColoredBackground={true} />;
            }
            case 3: {
                return <StatusDisplay strongPassword={true} label="Strong" displayColoredBackground={true} />;
            }
            case 4: {
                return <StatusDisplay veryStrongPassword={true} label="Very Strong" displayColoredBackground={true} />;
            }
        }
    };

    /**
     * On cancel click
     */
    private onCancelClick = () => {
        this.setState(
            {
                currentPassword: "",
                newPassword: "",
                repeatPassword: "",
                displayErrorMessage: false,
                displayLoadingBar: false,
            },
            () => this.props.onCloseClick()
        );
    };

    /***
     * Change password click
     */
    private OnChangeClick = async () => {
        try {
            this.setState({
                displayLoadingBar: true,
                displayErrorMessage: false,
            });
            let token = await ServiceWire.getSternumService().updateUserPassword(
                ServiceWire.getAuthenticationService().getCachedAuthenticatedUser().entityId,
                this.state.currentPassword,
                this.state.newPassword
            );
            ServiceWire.getAuthenticationService().overrideAuthenticationToken(token);
            this.props.onCloseClick();
        } catch (error) {
            this.setState({
                displayErrorMessage: true,
                displayLoadingBar: false,
            });
        } finally {
            this.setState({
                displayLoadingBar: false,
            });
        }
    };

    /**
     * check if repeat password error text should be displayed
     */
    private isRepeatErrorDisplay = (): boolean => {
        return (
            !this.state.isRepeatPasswordMatch &&
            this.state.repeatPassword.length > this.minimumPasswordLength &&
            this.state.repeatPassword.length > this.state.newPassword.length
        );
    };

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;
        return (
            <Dialog aria-labelledby="dialog-title" open={this.props.open} onClose={() => {}} className={classes.root}>
                <div className={classes.container}>
                    {/* Title */}

                    <DialogTitle id="dialog-title" className={classNames(classes.marginBottom)}>
                        <div className={classNames(classes.flexVMiddle, classes.flexSpaceBetween)}>
                            <Typography variant={"h6"} className={classNames(classes.extraBold)}>
                                Change Password
                            </Typography>
                            <IconButton aria-label="close" onClick={this.onCancelClick}>
                                <CrossIcon color="#909090" />
                            </IconButton>
                        </div>
                    </DialogTitle>
                    {/* form tag is important to prevent autofill */}
                    <form role="form">
                        {/* Body */}
                        <div className={classNames(classes.flexColumn, classes.padding)}>
                            {/* Current password */}

                            <div role="presentation" aria-label="password input container">
                                <SternumInputField
                                    isPassword={true}
                                    onFieldChange={this.onCurrentPasswordChange}
                                    label={"Current Password"}
                                    inputValue={this.state.currentPassword}
                                    addMargin={true}
                                    passwordLabelSize={124}
                                />
                            </div>

                            <div>
                                <div role="presentation" aria-label="password input container">
                                    <SternumInputField
                                        isPassword={true}
                                        onFieldChange={this.onNewPasswordChange}
                                        label={"New Password"}
                                        inputValue={this.state.newPassword}
                                        passwordLabelSize={105}
                                    />
                                </div>
                                <div
                                    className={classNames(classes.paddingTopXs, classes.flexColumn, classes.fullWidth)}
                                >
                                    {/* Password strength */}
                                    <div>
                                        <PasswordStrength
                                            passwordStrength={this.state.passwordStrength}
                                            width={340}
                                            height={6}
                                        />
                                    </div>
                                    <div className={classes.marginBottomXLarge}>
                                        {/** Password length constrains*/}
                                        {this.state.newPassword.length < 12 && (
                                            <Typography variant="body2" className={classes.passwordInformationSize}>
                                                Must be at least 12 characters
                                            </Typography>
                                        )}

                                        {/** Password strength constrains*/}
                                        {(this.state.passwordStrength < 2 || this.state.newPassword.length < 12) && (
                                            <Typography variant="body2" className={classes.passwordInformationSize}>
                                                Strength must be medium or above
                                            </Typography>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div role="presentation" aria-label="password input container">
                                <SternumInputField
                                    isPassword={true}
                                    onFieldChange={this.OnRepeatPasswordChange}
                                    label={"Repeat New Password"}
                                    inputValue={this.state.repeatPassword}
                                    disabled={this.state.newPassword.length < this.minimumPasswordLength}
                                    error={this.isRepeatErrorDisplay()}
                                    helperText={this.isRepeatErrorDisplay() && "Password mismatch"}
                                    addMargin={true}
                                    passwordLabelSize={155}
                                />
                            </div>
                            <div>
                                {/* Error message */}
                                {this.state.displayErrorMessage && (
                                    <Typography
                                        color={"error"}
                                        variant={"caption"}
                                        className={classNames(classes.marginTopXs)}
                                    >
                                        Failed to update password.
                                    </Typography>
                                )}
                            </div>
                        </div>
                    </form>
                </div>

                <DialogActions className={classNames(classes.paddingBottomXl, classes.flexCenter)}>
                    <div>
                        <SternumImprovedButton
                            onClick={this.onCancelClick}
                            isDisabled={false}
                            isLoading={false}
                            content={"Cancel"}
                            buttonType={"white"}
                        />
                    </div>
                    <div>
                        <SternumImprovedButton
                            onClick={this.OnChangeClick}
                            isDisabled={!this.state.isRepeatPasswordMatch || this.state.displayLoadingBar}
                            isLoading={this.state.displayLoadingBar}
                            content={"Change"}
                            buttonType={"gray"}
                        />
                    </div>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(changePasswordDialogStyle)(ChangePasswordDialog);
