import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core";

export const useGetSupportStyle = makeStyles((theme: Theme) => ({
    root: {
        padding: "24px",
        background: "#FBFCFF",
    },

    content: {
        padding: "32px 0",
    },

    mainTitle: {
        fontSize: "34px",
        fontWeight: 700,
    },

    subTitle: {
        fontSize: "24px",
    },

    instructionTitle: {
        fontSize: "16px",
        fontWeight: 600,
    },

    instructionText: {
        margin: "12px 0",
        color: "#555B61",
        fontSize: "14px",
        lineHeight: "22px",
    },

    guideDescriptionSection: {
        padding: "24px",
        marginTop: "32px",
        background: "#FFF",
        boxShadow: "0px 0px 23px rgba(231, 232, 235, 0.25)",
        borderRadius: "14px",
    },
}));
