import { Theme } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

const usePremiumBannerStyle = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        height: "60px",
        background: "rgba(219, 219, 252, 0.7)",
        borderRadius: "14px",
        margin: "20px",
        padding: "20px",
    },
    message: {
        flexGrow: 1,
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "120%",
    },
    button: {
        borderRadius: "12px",
        padding: "12px 32px",
        marginLeft: "8px",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "106%",
        textTransform: "none",
    },
    upgradeButton: {
        background: "#E7004C",
        color: "#FFFFFF",
        "&:hover": {
            background: "#E7004C",
        },
    },
    readMoreButton: {
        color: "#E7004C",
        background: "none",
        border: "1px solid #E7004C",
        "&:hover": {
            background: "none",
        },
    },
    premiumLink: {
        textDecoration: "none",
    },
}));

export default usePremiumBannerStyle;
