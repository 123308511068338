export const availableSimulationAttacks = [
    {
        id: 1,
        category: "Heap Memory Attacks",
        description:
            "Prevention CWE's are entire major categories of vulnerability types (consisting of many sub-categories) that Sternum blocks in real-time, zero-days and 1-days",
        vulnerabilities: [
            {
                id: 1,
                name: "String copy causing memory corruption",
                description:
                    "String operation without length check that corrupts a function pointer field in a struct that is allocated on the heap",
                cwes: [
                    {
                        name: "CWE-120: Buffer Copy without Checking Size of Input ('Classic Buffer Overflow') (4.8)",
                        link: "https://cwe.mitre.org/data/definitions/120.html",
                    },
                    {
                        name: "CWE-787: Out-of-bounds Write (4.8)",
                        link: "https://cwe.mitre.org/data/definitions/787.html",
                    },
                    {
                        name: "CWE-676: Use of Potentially Dangerous Function (4.8)",
                        link: "https://cwe.mitre.org/data/definitions/676.html",
                    },
                ],
            },
            {
                id: 2,
                name: "Memory copy without length check",
                description: "Memory operation without length check resulting in a malicious code execution",
                cwes: [
                    {
                        name: "CWE-805: Buffer Access with Incorrect Length Value (4.8)",
                        link: "https://cwe.mitre.org/data/definitions/805.html",
                    },
                    {
                        name: "CWE-787: Out-of-bounds Write (4.8)",
                        link: "https://cwe.mitre.org/data/definitions/787.html",
                    },
                ],
            },
            {
                id: 3,
                name: "Memory corruption across multiple functions",
                description:
                    "Memory operation without length check corrupts allocation referenced from multiple functions resulting in a malicious code execution",
                cwes: [
                    {
                        name: "CWE-120: Buffer Copy without Checking Size of Input ('Classic Buffer Overflow') (4.8)",
                        link: "https://cwe.mitre.org/data/definitions/120.html",
                    },
                    {
                        name: "CWE-805: Buffer Access with Incorrect Length Value (4.8)",
                        link: "https://cwe.mitre.org/data/definitions/805.html",
                    },
                    {
                        name: "CWE-787: Out-of-bounds Write (4.8)",
                        link: "https://cwe.mitre.org/data/definitions/787.html",
                    },
                ],
            },
            {
                id: 4,
                name: "Gaining absolute write via multiple corruptions",
                description:
                    "Memory corruption involving multiple structs on the heap resulting in gaining ability writing to an absolute address",
                cwes: [
                    {
                        name: "CWE-120: Buffer Copy without Checking Size of Input ('Classic Buffer Overflow')",
                        link: "https://cwe.mitre.org/data/definitions/120.html",
                    },
                    {
                        name: "CWE-123: Write-what-where Condition (4.8)",
                        link: "https://cwe.mitre.org/data/definitions/123.html",
                    },
                    {
                        name: "CWE-787: Out-of-bounds Write (4.8)",
                        link: "https://cwe.mitre.org/data/definitions/787.html",
                    },
                ],
            },
        ],
    },
    {
        id: 2,
        category: "Heap Information Leak",
        description:
            "Prevention CWE's are entire major categories of vulnerability types (consisting of many sub-categories) that Sternum blocks in real-time, zero-days and 1-days",
        vulnerabilities: [
            {
                id: 1,
                name: "Memory leak without length check",
                description:
                    "Memory operation without length check leaking another information located in different allocation",
                cwes: [
                    {
                        name: "CWE-805: Buffer Access with Incorrect Length Value (4.8)",
                        link: "https://cwe.mitre.org/data/definitions/805.html",
                    },
                    {
                        name: "CWE-126: Buffer Over-read (4.8)",
                        link: "https://cwe.mitre.org/data/definitions/126.html",
                    },
                ],
            },
            {
                id: 2,
                name: "Off by one corruption allows information leak",
                description:
                    "Off-by-one memory operation allows an attacker to change a field inside struct, this allows an attacker to change the amount of bytes read from the struct to and reading past the end of the struct",
                cwes: [
                    {
                        name: "CWE-787: Out-of-bounds Write (4.8)",
                        link: "https://cwe.mitre.org/data/definitions/787.html",
                    },
                    {
                        name: "CWE-193: Off-by-one Error (4.8)",
                        link: "https://cwe.mitre.org/data/definitions/193.html",
                    },
                    {
                        name: "CWE-126: Buffer Over-read (4.8)",
                        link: "https://cwe.mitre.org/data/definitions/126.html",
                    },
                ],
            },
        ],
    },
    {
        id: 3,
        category: "Command Injection",
        description:
            "Prevention CWE's are entire major categories of vulnerability types (consisting of many sub-categories) that Sternum blocks in real-time, zero-days and 1-days",
        vulnerabilities: [
            {
                id: 1,
                name: "Simple without sanitation",
                description: "Command injection using snprintf format without sanitation",
                cwes: [
                    {
                        name: "CWE-78: Improper Neutralization of Special Elements used in an OS Command ('OS Command Injection')",
                        link: "https://cwe.mitre.org/data/definitions/78.html",
                    },
                ],
            },
            {
                id: 2,
                name: "No sanitation across multiple functions",
                description: "Command injection using snprintf format without sanitation with multiple function calls",
                cwes: [
                    {
                        name: "CWE-78: Improper Neutralization of Special Elements used in an OS Command ('OS Command Injection')",
                        link: "https://cwe.mitre.org/data/definitions/78.html",
                    },
                ],
            },
            {
                id: 3,
                name: "Mix of multiple parameter types",
                description: "Command injection using snprintf format with different parameter types",
                cwes: [
                    {
                        name: "CWE-78: Improper Neutralization of Special Elements used in an OS Command ('OS Command Injection')",
                        link: "https://cwe.mitre.org/data/definitions/78.html",
                    },
                ],
            },
            {
                id: 4,
                name: "Improper sanitation bypass",
                description: "Command injection using snprintf with improper sanitation bypass",
                cwes: [
                    {
                        name: "CWE-78: Improper Neutralization of Special Elements used in an OS Command ('OS Command Injection')",
                        link: "https://cwe.mitre.org/data/definitions/78.html",
                    },
                ],
            },
        ],
    },
];
