import { WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TimeAgo from "react-timeago";
import moment from "moment";

import HashSet from "../../lib/infra/HashSet";
import SternumConfiguration from "../../lib/infra/SternumConfiguration";
import Utils from "../../lib/infra/Utils";
import { resolveAllIssuesAction } from "../../lib/redux/issues/ResolveAllIssuesAction";
import {
    openSternumGeneratedEventViewModalAction,
    openTraceViewModalAction,
} from "../../lib/redux/modals/OpenModalAction";
import AnalyticsService from "../../lib/services/AnalyticsService";
import DebounceService from "../../lib/services/DebounceService";
import PollingSubscriptionInfo from "../../lib/services/PollingSubscriptionInfo";
import ServiceWire from "../../lib/services/ServiceWire";
import { GlobalState } from "../../lib/state/GlobalState";
import IssueInfo from "../../lib/state/IssueInfo";
import IssuesFilter from "../../lib/state/IssuesFilter";
import ListFilter from "../../lib/state/ListFilter";
import PollingChangeType from "../../lib/state/PollingChangeType";
import ServerEntityType from "../../lib/state/ServerEntityType";
import TableColumnHeaderInfo from "../../lib/state/TableColumnHeaderInfo";
import TableToolbarDisplayState from "../../lib/state/TableToolbarDisplayState";
import ConfirmationDialog from "../../shared_components/ConfirmationDialog/ConfirmationDialog";
import IssuesListActions from "../IssuesListActions/IssuesListActions";
import IssuesListFilterPopover from "../IssuesListFilterPopover/IssuesListFilterPopover";
import StatusDisplay from "../StatusDisplay/StatusDisplay";
import SternumLink from "../SUI/SternumLink/SternumLink";
import SternumTable from "../SUI/SternumTable/SternumTable";
import issuesListStyle from "./IssuesListStyle";
import { IssuesIsResolved } from "./IssuesIsResolved";
import { putIssueStatusAction } from "../../lib/redux/issues/PutIssueStatusAction";
import { IssueInfoStatus } from "../../lib/state/IssueInfoType";
import { SternumTooltip } from "../SUI/SternumTooltip";
import { fetchPendingIssuesCountAction } from "../../lib/redux/issues/FetchPendingIssuesCountAction";
import { IssueListEmitter } from "./IssuesList.emitter";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    // Loading & error state.
    loadingIssues: boolean;
    errorLoadingIssues: boolean;

    // Issues.
    issues: IssueInfo[];
    totalItemCount: number;

    // Filtering.
    issuesFilter: IssuesFilter;
    searchText: string;
    orderByField: string;
    order: "asc" | "desc";

    pageNumber: number;
    openConfirmationDialog: boolean;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof issuesListStyle> {
    deviceId?: string;
    deviceDefinitionVersionId?: string;

    issuesFilter?: IssuesFilter;
    includeColumns: string[];
    initialColumnHeaders?: TableColumnHeaderInfo[];
    viewedColumnsSet?: HashSet;

    pageSize?: number;
    hideToolbar?: boolean;
    hidePagination?: boolean;
    hideUpperBorder?: boolean;
    displayXButtonInTraceView?: boolean;
    displayBackButtonInTraceView?: boolean;

    enablePolling?: boolean;

    location;
    history;

    paperClassNames?;

    showAllColumns?: boolean;
    forceRefresh: boolean;
    resolveAllIssuesAction: () => void;
    fetchPendingIssuesCount: () => unknown;
    narrow?: boolean;
    hideResolveAll?: boolean;
    openTraceViewModal?: (
        key: string,
        traceId: string,
        displayXButton: boolean,
        displayBackButton: boolean,
        shouldDisplayLinkToDeviceView: boolean,
        shouldDisplayLinkToExportReport: boolean,
        issue: IssueInfo,
        onRefresh?: () => unknown,
        resolveFlowOpen?: boolean
    ) => void;
    openSternumGeneratedEventViewModal?: (
        key: string,
        sternumGeneratedEventId: string,
        shouldDisplayLinkToDeviceView: boolean,
        shouldDisplayLinkToExportReport: boolean,
        displayXButton: boolean,
        displayBackButton: boolean,
        issue: IssueInfo,
        onRefresh?: () => unknown,
        resolveFlowOpen?: boolean
    ) => void;
    resolveIssue: (issueId: string) => unknown;
    isExpanded?: boolean;
    onExpandedToggle?: () => unknown;
    deviceProfileStatus?: "PRODUCTION" | "STAGING";

    searchPlaceholder?: string;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {
        resolveAllIssuesAction: () => dispatch(resolveAllIssuesAction()),
        fetchPendingIssuesCount: () => dispatch(fetchPendingIssuesCountAction()),
        openTraceViewModal: (
            key,
            traceId,
            displayXButton,
            displayBackButton,
            shouldDisplayLinkToDeviceView,
            shouldDisplayLinkToExportReport,
            issue: IssueInfo,
            onRefresh?: () => unknown,
            resolveFlowOpen?: boolean
        ) =>
            dispatch(
                openTraceViewModalAction(
                    key,
                    traceId,
                    displayXButton,
                    displayBackButton,
                    shouldDisplayLinkToDeviceView,
                    shouldDisplayLinkToExportReport,
                    issue,
                    false,
                    onRefresh,
                    resolveFlowOpen
                )
            ),
        openSternumGeneratedEventViewModal: (
            key: string,
            sternumGeneratedEventId: string,
            shouldDisplayLinkToDeviceView: boolean,
            shouldDisplayLinkToExportReport: boolean,
            displayXButton,
            displayBackButton,
            issue: IssueInfo,
            onRefresh?: () => unknown,
            resolveFlowOpen?: boolean
        ) =>
            dispatch(
                openSternumGeneratedEventViewModalAction(
                    key,
                    sternumGeneratedEventId,
                    shouldDisplayLinkToDeviceView,
                    shouldDisplayLinkToExportReport,
                    displayXButton,
                    displayBackButton,
                    issue,
                    issue.issueId,
                    onRefresh,
                    resolveFlowOpen
                )
            ),
        resolveIssue: (issueId: string) =>
            dispatch(putIssueStatusAction({ issueId, status: IssueInfoStatus.Resolved })),
    };
};

/**
 * Holds the issues list in the app.
 */
class IssuesList extends React.Component<AppProps, AppState> {
    static defaultProps = {
        pageSize: SternumConfiguration.getPageSize(),
        searchPlaceholder: "Search alert",
    };

    /**
     * Defines the column headers participating in the issues table.
     */
    private columnHeaders: TableColumnHeaderInfo[] = [
        new TableColumnHeaderInfo("alertName", "Alert Name", false, true, false),
        new TableColumnHeaderInfo("traceCategory", "Category", false, true, false),
        new TableColumnHeaderInfo("traceEventType", "Alert Type", false, true, false),
        new TableColumnHeaderInfo("created", "Received", false, true, false),
        new TableColumnHeaderInfo("receivedDeviceId", "Device Id", false, true, false),
        new TableColumnHeaderInfo("deviceName", "Profile Name", false, true, false),
        new TableColumnHeaderInfo("firmwareVersion", "Firmware Version", false, true, false),
        new TableColumnHeaderInfo("state", "Profile State", false, true, false),
        new TableColumnHeaderInfo("prevented", "Status", false, true, false),
        new TableColumnHeaderInfo("resolver", "Resolver", false, true, false),
        new TableColumnHeaderInfo("actions", "Actions", false, true, false, false),
    ];

    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        if (props.initialColumnHeaders) {
            this.columnHeaders = props.initialColumnHeaders;
        }

        if (this.props.includeColumns) {
            if (this.props.includeColumns.length) {
                let includeColumnsSet = HashSet.fromValues(this.props.includeColumns);
                this.columnHeaders = this.columnHeaders.filter((columnHeader) =>
                    includeColumnsSet.exists(columnHeader.id)
                );
            } else {
                this.columnHeaders = [];
            }
        }

        if (this.props.showAllColumns) {
            for (let i = 0; i < this.columnHeaders.length; i++) {
                this.columnHeaders[i].isHidden = false;
            }
        }

        // Remove the actions column if it's a viewer
        const issuePermission = ServiceWire.getAuthorizationService().canEdit(ServerEntityType.ISSUE);
        if (!issuePermission) {
            this.columnHeaders = this.columnHeaders.filter((header) => header.id != "actions");
        }

        // Initializing the state to default.
        this.state = {
            loadingIssues: false,
            errorLoadingIssues: false,

            issues: [],

            issuesFilter: this.props.issuesFilter
                ? this.props.issuesFilter
                : new IssuesFilter(null, null, false, true, [IssueInfoStatus.Open]),
            searchText: null,
            orderByField: null,
            order: "asc",

            totalItemCount: null,

            pageNumber: 1,
            openConfirmationDialog: false,
        };
    }

    /**
     * Occurs once the component finished its initialization process.
     */
    async componentDidMount() {
        await this.loadIssues(this.state.pageNumber, true);

        if (this.props.enablePolling) {
            // Subscribe to new issues.
            ServiceWire.getPollingService().subscribe(
                new PollingSubscriptionInfo(
                    "IssuesList",
                    PollingChangeType.ALL_ISSUES,
                    this.handleChangedIssues.bind(this)
                )
            );
        }
    }

    /**
     *
     * Refresh issues pagination if expand is clicked (handle new issues from polling)
     */
    async componentDidUpdate(prevProps: AppProps) {
        if (this.props.forceRefresh !== prevProps.forceRefresh) {
            this.setState({ searchText: null }, async () => {
                await this.loadIssues(this.state.pageNumber, true);
            });
        }

        if (
            this.props.issuesFilter.isDifferentFrom(prevProps.issuesFilter) ||
            this.props.deviceDefinitionVersionId !== prevProps.deviceDefinitionVersionId
        ) {
            this.setState({ issuesFilter: this.props.issuesFilter }, async () => {
                await this.loadIssues(1, false);
            });
        }
    }

    /**
     * Occurs once the component is being destroyed.
     */
    componentWillUnmount(): void {
        if (this.props.enablePolling) {
            ServiceWire.getPollingService().unsubscribe("IssuesList", PollingChangeType.ALL_ISSUES);
        }
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        return (
            <>
                <SternumTable
                    role="presentation"
                    ariaLabel="issues list table"
                    nonClickableRows
                    hideUpperBorder={this.props.hideUpperBorder}
                    hideToolbar={this.props.hideToolbar}
                    toolbarState={
                        new TableToolbarDisplayState(
                            !this.props.narrow,
                            false,
                            false,
                            false,
                            false,
                            false,
                            false,
                            !this.props.hideResolveAll &&
                                this.state.issuesFilter.statuses?.includes(IssueInfoStatus.Open) &&
                                this.state.issues.some((issue) => issue.status === IssueInfoStatus.Open),
                            this.props.searchPlaceholder,
                            false,
                            false
                        )
                    }
                    hidePagination={this.props.hidePagination}
                    pageSize={this.props.pageSize}
                    pageNumber={this.state.pageNumber}
                    amountOfLoadingPlaceholders={3}
                    totalItemCount={this.state.totalItemCount}
                    loadingItems={this.state.loadingIssues}
                    onRefreshClicked={() => this.onRefreshClicked()}
                    errorLoadingItems={this.state.errorLoadingIssues}
                    columnHeaders={this.columnHeaders}
                    viewedColumnsSet={this.props.viewedColumnsSet}
                    listFilter={this.state.issuesFilter}
                    isFilterActive={this.state.issuesFilter && this.state.issuesFilter.isActive()}
                    filterComponentProvider={(onFilterChanged) => (
                        <IssuesListFilterPopover
                            issuesFilter={this.state.issuesFilter}
                            onFilterChanged={onFilterChanged}
                        />
                    )}
                    orderByField={this.state.orderByField}
                    order={this.state.order}
                    rows={this.state.issues}
                    emptyComponent={
                        <div className={classNames(classes.flexCenter, classes.padding)}>
                            <Typography variant="body2">No issues found</Typography>
                        </div>
                    }
                    onFilterChanged={(listFilter) => this.handleIssuesFilterChanged(listFilter)}
                    onSearchTextChanged={(searchText) => this.handleSearchTextChanged(searchText)}
                    onPageChanged={(pageNumber) => this.loadIssues(pageNumber, true)}
                    onOrderChanged={(orderByField, order) => this.handleOrderChanged(orderByField, order)}
                    getRowValues={(row) => this.getRowDisplayValues(row)}
                    getRowClasses={(row) => this.getRowClasses(row)}
                    onResolveAllIssuesClicked={this.openConfirmationDialog}
                    narrow
                    onTableExpandToggle={this.props.onExpandedToggle}
                    isTableExpanded={this.props.isExpanded || false}
                />

                <ConfirmationDialog
                    open={this.state.openConfirmationDialog}
                    title={`Resolve All Issues?`}
                    body={`Are you sure you want to resolve all of the issues?`}
                    handleCancel={this.closeConfirmationDialog}
                    handleApprove={this.resolveAll}
                    overrideActionName={"Resolve"}
                />
            </>
        );
    }

    /**
     * Close confirmation dialog
     */
    private closeConfirmationDialog = () => {
        this.setState({ openConfirmationDialog: false });
    };

    /**
     * Close confirmation dialog
     */
    private openConfirmationDialog = () => {
        this.setState({ openConfirmationDialog: true });
    };

    /**
     * Occurs once the user refreshes the list.
     */
    private async onRefreshClicked() {
        IssueListEmitter.emit("refresh");
        await this.loadIssues(this.state.pageNumber, true);
    }

    /**
     *
     * Get device name
     */
    private getDeviceName = (issue: IssueInfo): string => {
        let deviceName = "N/A";
        if (issue.device) {
            deviceName = issue.device.deviceDefinition.displayName;
        }
        return deviceName;
    };

    /**
     * Gets the display values for row's columns (where they aren't the default).
     */
    private getRowDisplayValues(row) {
        let issueInfo = row as IssueInfo;

        let eventTypeDisplayValue = issueInfo.alertType;

        const deviceId = !Utils.isNullOrUndefined(issueInfo.device?.receivedDeviceId)
            ? issueInfo.device.receivedDeviceId.toString()
            : "Unknown";

        const traceCategory = issueInfo.category;

        return {
            alertName: (
                <SternumLink onClick={() => this.handleIssueClicked(issueInfo)}>
                    <SternumTooltip title={issueInfo.alertName}>
                        <>{issueInfo.alertName}</>
                    </SternumTooltip>
                </SternumLink>
            ),
            resolver: <Typography>{issueInfo.updaterUser?.getFullName()}</Typography>,
            firmwareVersion: (
                <SternumTooltip title={issueInfo.device?.getFirmwareVersion()}>
                    <>{issueInfo.device?.getFirmwareVersion()}</>
                </SternumTooltip>
            ),
            state: ((state: string) => (
                <SternumTooltip title={state}>
                    <>{state}</>
                </SternumTooltip>
            ))(issueInfo.device ? Utils.capitalize(issueInfo.device.getDeviceState().toLowerCase()) : ""),
            traceEventType: (
                <SternumTooltip title={eventTypeDisplayValue}>
                    <>{eventTypeDisplayValue}</>
                </SternumTooltip>
            ),
            traceCategory: (
                <SternumTooltip title={traceCategory}>
                    <>{traceCategory}</>
                </SternumTooltip>
            ),
            created: (
                <SternumTooltip title={moment(issueInfo.created).format("MM/DD/YYYY HH:mm")}>
                    <TimeAgo title="" date={issueInfo.created} />
                </SternumTooltip>
            ),
            receivedDeviceId: (
                <SternumTooltip title={deviceId}>
                    <>{deviceId}</>
                </SternumTooltip>
            ),
            deviceName: (
                <SternumTooltip title={this.getDeviceName(issueInfo)}>
                    <>{this.getDeviceName(issueInfo)}</>
                </SternumTooltip>
            ),
            prevented: issueInfo.traceId ? (
                <SternumTooltip title={issueInfo.anomalyType === null ? "Prevented" : "Detected"}>
                    <StatusDisplay
                        label={issueInfo.anomalyType === null ? "Prevented" : "Detected"}
                        danger={true}
                        displayColoredBackground={true}
                        centerText={true}
                        padding={true}
                    />
                </SternumTooltip>
            ) : (
                <SternumTooltip title="Alert">
                    <StatusDisplay
                        label={"Alert"}
                        danger={true}
                        displayColoredBackground={true}
                        centerText={true}
                        padding={true}
                    />
                </SternumTooltip>
            ),
            isResolved: (
                <IssuesIsResolved issue={issueInfo} onClickResolve={() => this.handleIssueClicked(issueInfo, true)} />
            ),
            actions: (
                <IssuesListActions
                    issue={issueInfo}
                    canResolve={this.state.issuesFilter.statuses?.includes(IssueInfoStatus.Open)}
                    onResolveFinished={(issueId) => this.onResolveFinished(issueId)}
                />
            ),
        };
    }

    private handleIssueClicked(issue: IssueInfo, resolveFlowOpen = false) {
        if (issue.traceId) {
            this.props.openTraceViewModal(
                issue.traceId,
                issue.traceId,
                true,
                false,
                true,
                true,
                issue,
                () => this.onRefreshClicked(),
                resolveFlowOpen
            );
        } else if (issue.sternumGeneratedEventId) {
            this.props.openSternumGeneratedEventViewModal(
                issue.sternumGeneratedEventId,
                issue.sternumGeneratedEventId,
                true,
                true,
                true,
                false,
                issue,
                () => this.onRefreshClicked(),
                resolveFlowOpen
            );
        }
    }

    /**
     * Occurs once the filters of the list are changed.
     */
    private handleIssuesFilterChanged(issuesFilter: ListFilter) {
        this.setState(
            {
                issuesFilter: issuesFilter != null ? (issuesFilter as IssuesFilter) : null,
            },
            async () => {
                if (issuesFilter && issuesFilter.isActive()) {
                    await this.loadIssues(1, true);
                }
            }
        );
    }

    /**
     * Occurs once search text is changed.
     */
    private handleSearchTextChanged(searchText) {
        // Debounce request to search issues.
        DebounceService.debounce(
            "getIssues",
            () => {
                this.setState(
                    {
                        searchText: searchText,
                    },
                    () => this.loadIssues(1, true)
                );
            },
            350
        );
    }

    /**
     * Occurs once sort order is changed.
     */
    private handleOrderChanged(orderByField, order) {
        this.setState(
            {
                orderByField: orderByField,
                order: order,
            },
            () => this.loadIssues(1, true)
        );
    }

    /**
     * Loads issues.
     */
    private async loadIssues(pageNumber: number, displayLoader: boolean) {
        try {
            // Setting loading to true.
            this.setState({
                loadingIssues: displayLoader,
                errorLoadingIssues: false,
                pageNumber,
            });

            // Fetching issues.
            let issuesCount = await ServiceWire.getSternumService().getIssuesCount(
                ServiceWire.getClientsService().getSelectedClientId(),
                this.props.deviceId,
                null,
                this.state.issuesFilter,
                this.state.searchText,
                this.props.deviceProfileStatus,
                this.props.deviceDefinitionVersionId
            );
            let issues: IssueInfo[] = [];
            if (issuesCount > 0) {
                issues = await ServiceWire.getSternumService().getIssues(
                    ServiceWire.getClientsService().getSelectedClientId(),
                    this.props.deviceId,
                    null,
                    this.state.issuesFilter,
                    this.state.searchText,
                    this.state.orderByField,
                    this.state.order,
                    (pageNumber - 1) * this.props.pageSize,
                    this.props.pageSize,
                    this.props.deviceProfileStatus,
                    this.props.deviceDefinitionVersionId
                );
            }

            this.setState({
                loadingIssues: false,
                errorLoadingIssues: false,
                issues: issues,
                totalItemCount: issuesCount,
            });
        } catch (error) {
            AnalyticsService.error("IssuesList:loadIssues", error.message);

            this.setState({
                loadingIssues: false,
                errorLoadingIssues: true,
            });
        }
    }

    /**
     * Occurs once new issues are fetched from server using polling.
     */
    private handleChangedIssues(fromTimestamp: number, changedData: Object): void {
        if (changedData && changedData["entities"]) {
            // Load first 50 issues from server
            this.loadIssues(1, false);
        }
    }

    /**
     * Occurs on issue being resolved.
     */
    private onResolveFinished(issueId: string) {
        if (this.state.issuesFilter && this.state.issuesFilter.excludeResolved) {
            // Copying the array to keep the immutability rule.
            let clonedIssues = [...this.state.issues];
            let issueIndex = Utils.findFirstIndex(clonedIssues, (issue) => issue.issueId === issueId);
            clonedIssues.splice(issueIndex, 1);

            // All issues on current page are resolved, check if total number is bigger then 1 and activate page refresh.
            if (clonedIssues.length == 0 && this.state.totalItemCount > 1) {
                this.onRefreshClicked();
            }
            this.setState({
                issues: clonedIssues,
                // after resolve, update total count
                totalItemCount: this.state.totalItemCount - 1,
            });
        } else {
            this.onRefreshClicked();
        }
    }

    /**
     * Handle resolve all clicked from table toolbar
     */
    private resolveAll = async () => {
        if (this.state.issues.length > 0) {
            await ServiceWire.getSternumService().resolveAllIssues(
                ServiceWire.getClientsService().getSelectedClientId(),
                this.props.deviceId
            );
            // Reset issues list
            this.setState({ issues: [] });
            // Call reducer to set issues counter to 0
            this.props.resolveAllIssuesAction();
            this.props.fetchPendingIssuesCount();
            this.closeConfirmationDialog();
        } else {
            this.closeConfirmationDialog();
        }
    };

    /**
     * Gets the classes defined for row.
     */
    private getRowClasses(row) {
        const { classes } = this.props;

        return {
            hover: classes.issueRowHover,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(issuesListStyle)(IssuesList)));
