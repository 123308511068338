import { Action } from "redux";
import ActionType from "../ActionType";

/**
 * Defines the data for the view trace action.
 */
export interface ViewGeneratedEventAction extends Action<ActionType> {
    type: ActionType.ViewGeneratedEvent;
    generatedEventId: string;
    issueId: string;
}

/**
 * Action creator for viewing a trace.
 */
export function viewGeneratedEventAction(generatedEventId: string, issueId: string): ViewGeneratedEventAction {
    return {
        type: ActionType.ViewGeneratedEvent,
        generatedEventId: generatedEventId,
        issueId: issueId,
    };
}
