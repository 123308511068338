import { Theme } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import { sternumTheme } from "./App";
import { makeStyles } from "@material-ui/styles";

export const SIDEBAR_EXPANDED_WIDTH = 250;
export const SIDEBAR_NORMAL_WIDTH = 60;

let commonStyle = (theme: Theme) =>
    createStyles({
        displayNone: {
            display: "none",
        },
        relative: {
            position: "relative",
        },
        fullHeightRelative: {
            position: "relative",
            height: "100%",
        },
        flex: {
            display: "flex",
        },
        flexWrap: {
            display: "flex",
            flexWrap: "wrap",
        },
        flexNoWrap: {
            display: "flex",
            flexWrap: "wrap",
        },
        flexVMiddle: {
            display: "flex",
            alignItems: "center",
        },
        flexCenter: {
            display: "flex",
            justifyContent: "center",
        },
        flexEqual: {
            flex: 1,
        },
        visibilityVisible: {
            visibility: "visible",
        },
        visibilityHidden: {
            visibility: "hidden",
        },
        commonDangerColor: {
            color: sternumTheme.danger.main,
        },
        specialFieldDisplay: {
            fontWeight: 500,
            fontSize: "0.8125rem",
        },
        commonEnabledColor: {
            color: sternumTheme.enabled.main,
        },
        commonDisabledColor: {
            color: sternumTheme.disabled.main,
        },
        cursorPointer: {
            cursor: "pointer",
        },
        whiteSpaceNoWrap: {
            whiteSpace: "nowrap",
        },
        overflowHidden: {
            overflow: "hidden",
        },
        overflowAuto: {
            overflow: "auto",
        },
        textEllipsis: {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        },
        fullWidth: {
            width: "100%",
        },
        fullHeight: {
            height: "100%",
        },
        flexGrow: {
            display: "flex",
            flexGrow: 1,
        },
        flexNoShrink: {
            flexShrink: 0,
        },
        flexSpaceBetween: {
            display: "flex",
            justifyContent: "space-between",
        },
        flexSpaceAround: {
            display: "flex",
            justifyContent: "space-around",
        },
        flexVStart: {
            display: "flex",
            alignItems: "flex-start",
        },
        flexEnd: {
            display: "flex",
            justifyContent: "flex-end",
        },
        flexColumn: {
            display: "flex",
            flexDirection: "column",
        },
        flexRow: {
            display: "flex",
            flexDirection: "row",
        },
        fullViewportHeight: {
            height: "100vh",
        },
        padding: {
            padding: theme.spacing(2),
        },
        paddingXs: {
            padding: theme.spacing(1),
        },
        paddingRightXs: {
            paddingRight: theme.spacing(1),
        },
        paddingRight: {
            paddingRight: theme.spacing(2),
        },
        paddingRightXl: {
            paddingRight: theme.spacing(4),
        },
        paddingLeftXs: {
            paddingLeft: theme.spacing(2),
        },
        paddingLeft: {
            paddingLeft: theme.spacing(2),
        },
        paddingLeftXl: {
            paddingLeft: theme.spacing(2),
        },
        commonBold: {
            fontWeight: 500,
        },
        extraBold: {
            fontWeight: 600,
        },
        paddingBottomXs: {
            paddingBottom: theme.spacing(1),
        },
        paddingBottom: {
            paddingBottom: theme.spacing(2),
        },
        paddingBottomXl: {
            paddingBottom: theme.spacing(4),
        },
        paddingTopXs: {
            paddingTop: theme.spacing(1),
        },
        paddingTop: {
            paddingTop: theme.spacing(2),
        },
        paddingTopXl: {
            paddingTop: theme.spacing(4),
        },
        marginLeftXs: {
            marginLeft: theme.spacing(1),
        },
        marginLeft: {
            marginLeft: theme.spacing(2),
        },
        marginLeftLarge: {
            marginLeft: theme.spacing(4),
        },
        marginTopXs: {
            marginTop: theme.spacing(1),
        },
        marginTop: {
            marginTop: theme.spacing(2),
        },
        marginTopMd: {
            marginTop: theme.spacing(3),
        },
        marginTopLarge: {
            marginTop: theme.spacing(4),
        },
        marginTopXl: {
            marginTop: theme.spacing(6),
        },
        marginTopXXl: {
            marginTop: theme.spacing(8),
        },
        marginRightXs: {
            marginRight: theme.spacing(1),
        },
        marginRight: {
            marginRight: theme.spacing(2),
        },
        marginRightMd: {
            marginRight: theme.spacing(3),
        },
        marginRightLarge: {
            marginRight: theme.spacing(4),
        },
        marginRightXLarge: {
            marginRight: theme.spacing(6),
        },
        marginRightXXLarge: {
            marginRight: theme.spacing(8),
        },
        marginRightXXXLarge: {
            marginRight: theme.spacing(10),
        },
        marginBottomXs: {
            marginBottom: theme.spacing(1),
        },
        sternumInput: {
            fontSize: "0.8125rem",
            fontWeight: theme.typography.fontWeightRegular,
            minWidth: theme.spacing(38),
        },
        marginBottom: {
            marginBottom: theme.spacing(2),
        },
        marginBottomMedium: {
            marginBottom: theme.spacing(3),
        },
        marginBottomLarge: {
            marginBottom: theme.spacing(4),
        },
        marginBottomXLarge: {
            marginBottom: theme.spacing(6),
        },
        marginBottomXXLarge: {
            marginBottom: theme.spacing(8),
        },
        marginBottomXXXLarge: {
            marginBottom: theme.spacing(12),
        },

        fixedInputTextAreaWith: {
            width: theme.spacing(58),
        },
        loadingPlaceholder: {
            animationDuration: "1.6s",
            animationFillMode: "forwards",
            animationIterationCount: "10",
            animationName: "placeHolderShimmer",
            animationTimingFunction: "linear",
            background: "#EFEFED linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%)",
            backgroundSize: "800px 104px",
            borderRadius: "4px",
            content: " ",

            "&.mod-title": {
                height: theme.spacing(3),
                width: theme.spacing(96),
            },

            "&.mod-subtitle": {
                height: theme.spacing(3),
                width: theme.spacing(48),
            },

            "&.mod-paragraph": {
                height: theme.spacing(3),
                width: theme.spacing(35),
            },

            "&.mod-big-column": {
                height: theme.spacing(3),
                width: theme.spacing(82),
            },

            "&.mod-small-column": {
                height: theme.spacing(3),
                width: theme.spacing(20),
            },

            "&.mod-loading-column": {
                height: theme.spacing(3),
                width: theme.spacing(10),
            },

            "&.mod-medium-column": {
                height: theme.spacing(3),
                width: theme.spacing(40),
            },

            "&.mod-input": {
                height: theme.spacing(6) + 2,
                width: theme.spacing(36),
            },

            "&.mod-input-medium": {
                height: theme.spacing(6) + 2,
                width: theme.spacing(38),
            },

            "&.mod-key-metric": {
                width: theme.spacing(32),
                height: theme.spacing(14),
            },

            "&.mod-tall-small": {
                width: theme.spacing(5),
                height: theme.spacing(10),
            },

            "&.mod-tall-medium": {
                width: theme.spacing(5),
                height: theme.spacing(20),
            },

            "&.mod-tall-large": {
                width: theme.spacing(5),
                height: theme.spacing(30),
            },
        },
        toolTip: {
            fontSize: "0.85rem",
        },

        // Don't allow select on option
        disableSelect: {
            userSelect: "none",
            msUserSelect: "none",
            MozUserSelect: "none",
            WebkitUserSelect: "none",
        },

        // Activated on elements that listen to on key press events
        removeFocus: {
            outline: "none",
            boxShadow: "none",
        },

        zIndex50: {
            zIndex: 50,
        },
    });

export default commonStyle;

export const useCommonStyle = makeStyles((theme: Theme) => commonStyle(theme));
