import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const blackSuperLightColor = "#555B61";

export const useEnterAuthMfaCodeStyle = makeStyles((theme: Theme) => ({
    codeInput: {
        marginBottom: 8,
    },

    subtitleContainer: {},

    subtitle: {
        color: "#555B61",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "20px",
    },

    resendMfaContainer: {
        display: "inline-flex",
        alignItems: "center",
        gap: 8,
        marginTop: 28,
        fontSize: "14px",
        lineHeight: "20px",
        color: blackSuperLightColor,
        cursor: "pointer",
    },

    resendMfaText: {
        textDecoration: "underline",
    },
}));
