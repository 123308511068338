import * as React from "react";

import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import TableColumnHeaderInfo from "../../../../lib/state/TableColumnHeaderInfo";
import { TableHead, Typography } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableRow from "@material-ui/core/TableRow";
import sternumTableHeaderStyle from "./SternumTableHeaderStyle";
import HashSet from "../../../../lib/infra/HashSet";
import { SternumTooltip } from "../../SternumTooltip";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    orderByField: string;
    order: "asc" | "desc";
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof sternumTableHeaderStyle> {
    // Changeable props (will cause a re-render).
    loadingItems: boolean;
    viewedColumnsSet: HashSet;

    // Non-changeable props (will not cause a re-render).
    columnHeaders: TableColumnHeaderInfo[];
    orderByField: string;
    order: "asc" | "desc";
    hideUpperBorder?: boolean;

    onOrderChanged: (orderByField: string, order: "asc" | "desc") => void;

    expandableRows?: boolean;
    narrow?: boolean;
}

/**
 * Displays a sternum-styled table header.
 */
class SternumTableHeader extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            orderByField: this.props.orderByField,
            order: this.props.order,
        };
    }

    /**
     * Returns whether anything changed in props need to execute a render.
     */
    shouldComponentUpdate(nextProps: Readonly<AppProps>, nextState: Readonly<AppState>, nextContext: any): boolean {
        // Note: the order of comparison is performance-wise. From the most costly comparison to the most cheap one.

        if (nextProps.loadingItems !== this.props.loadingItems) {
            return true;
        }

        if (nextState.orderByField !== this.state.orderByField) {
            return true;
        }

        if (nextState.order !== this.state.order) {
            return true;
        }

        if (nextProps.viewedColumnsSet.isDifferentFrom(this.props.viewedColumnsSet)) {
            return true;
        }

        if (nextProps.columnHeaders.length !== this.props.columnHeaders.length) {
            return true;
        }

        return false;
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        return (
            <TableHead>
                <TableRow classes={{ root: classes.tableHeaderRow }}>
                    {this.props.expandableRows && (
                        <TableCell
                            align={"left"}
                            className={classNames(
                                classes.whiteSpaceNoWrap,
                                classes.tableHeaderCell,
                                !this.props.hideUpperBorder && "mod-upper-border"
                            )}
                        />
                    )}
                    {this.props.columnHeaders
                        .filter((columnHeader) => this.props.viewedColumnsSet.exists(columnHeader.id))
                        .map((columnHeader) => {
                            return (
                                <TableCell
                                    key={columnHeader.id}
                                    align={"left"}
                                    className={classNames(
                                        { [classes.narrow]: this.props.narrow },
                                        classes.whiteSpaceNoWrap,
                                        classes.tableHeaderCell,
                                        !this.props.hideUpperBorder && "mod-upper-border",
                                        columnHeader.isPrimaryColumn && classes.primaryHeader
                                    )}
                                >
                                    {columnHeader.isSortable ? (
                                        <TableSortLabel
                                            title={columnHeader.tooltipValue}
                                            active={this.state.orderByField === columnHeader.id}
                                            direction={this.state.order}
                                            style={{
                                                marginLeft: columnHeader.marginLeft
                                                    ? `${columnHeader.marginLeft}px`
                                                    : 0,
                                            }}
                                            onClick={(event) =>
                                                !this.props.loadingItems && this.handleSortSelected(columnHeader.id)
                                            }
                                        >
                                            {columnHeader.displayValue}
                                        </TableSortLabel>
                                    ) : (
                                        <SternumTooltip title={columnHeader.tooltipValue}>
                                            <div
                                                style={{
                                                    marginLeft: columnHeader.marginLeft
                                                        ? `${columnHeader.marginLeft}px`
                                                        : 0,
                                                }}
                                            >
                                                {columnHeader.displayValue}
                                            </div>
                                        </SternumTooltip>
                                    )}
                                </TableCell>
                            );
                        })}
                </TableRow>
            </TableHead>
        );
    }

    /**
     * Handles sorting clicked.
     */
    private handleSortSelected(columnHeaderId) {
        let order: "asc" | "desc" = "desc" as "desc";

        if (this.state.orderByField === columnHeaderId && this.state.order === "desc") {
            order = "asc" as "asc";
        }

        this.setState(
            {
                orderByField: columnHeaderId,
                order: order,
            },
            () => {
                if (this.props.onOrderChanged) {
                    this.props.onOrderChanged(this.state.orderByField, this.state.order);
                }
            }
        );
    }
}

export default withStyles(sternumTableHeaderStyle)(SternumTableHeader);
