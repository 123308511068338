enum EntityType {
    Library,
    UsedLibrary,
    DeviceDefinitionLibrary,
    LibraryHistory,
    Device,
    Trace,
    TraceArgument,
    Cve,
    Issue,
    UsedLibraryHistory,
    User,
    Activity,
    Client,
    OutgoingWebhook,
    APIKey,
    DeviceProcess,
    DeviceProcessInstance,
    DeviceDefinition,
    TraceDefinition,
    ArgumentDefinition,
    SternumTrigger,
    SternumGeneratedEvent,
    SternumEvent,
    Role,
    Permission,
    DeviceDefinitionVersion,
    Visualisation,
    CustomDashboard,
    ProcessTrace,
}

export default EntityType;
