import { withStyles, WithStyles } from "@material-ui/core";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as React from "react";
import { sortBy, findIndex } from "lodash";
import interactiveCoverageDialogStyle from "./InteractiveCoverageDialogStyle";
import { MutableRefObject } from "react";

export interface InteractiveCoveragePlotProps extends WithStyles<typeof interactiveCoverageDialogStyle> {
    data: Array<{ category: string; cwe: string; description: string }>;
    initialCategory?: string;
}

export interface InteractiveCoveragePlotState {
    selectedCategory: string | null;
    overrideColorIndex: number | null;
}

const CATEGORY_PALETTE = ["#014362", "#8769C3", "#1B6FDE", "#15AC5A", "#6372D6", "#44B8D9"];
const CATEGORY_PALETTE_HOVER = ["#003C58", "#7451BA", "#0F64D3", "#129E52", "#4B5BCA", "#3BA9C9"];
const CWE_PALETTE = ["#ACD3E5", "#D3C2F4", "#B0D2FF", "#C3EDD6", "#C2CAFF", "#BAEAF8"];
const CWE_PALETTE_HOVER = ["#9ccae0", "#c5aff0", "#9ac5fe", "#b2e8ca", "#abb6ff", "#a6e4f6"];
const VERY_MAGICAL_MAX_WIDTH_CONSTANT = 595;

type RawCWEData = Array<{ category: string; cwe: string; description: string }>;
type CategoryPlotData = Array<{
    category: string;
    index: number;
    value: number;
    fill: string;
    hoverFill: string;
    maxWidth: number;
}>;
type CWEPlotData = Array<{
    cwe: string;
    category: string;
    categoryIndex: number;
    value: 1;
    fill: string;
    hoverFill: string;
    textFill: string;
}>;

function transformData(data: RawCWEData): {
    category: CategoryPlotData;
    cwe: CWEPlotData;
} {
    const histogram: { [k: string]: number } = {};
    for (const { category } of data) {
        histogram[category] = histogram[category] === undefined ? 1 : histogram[category] + 1;
    }
    const categoriesData = sortBy(
        Object.entries(histogram).map(([category, value]) => ({ category, value })),
        ["category"]
    ).map(({ category, value }, index) => ({
        category,
        value,
        index,
        fill: CATEGORY_PALETTE[index % CATEGORY_PALETTE.length],
        hoverFill: CATEGORY_PALETTE_HOVER[index % CATEGORY_PALETTE_HOVER.length],
        maxWidth: (value / data.length) * VERY_MAGICAL_MAX_WIDTH_CONSTANT,
    }));
    const cwesData = sortBy(data, "category")
        .map(({ cwe, category, description }, index) => ({
            cwe,
            description,
            category,
            value: 1 as const,
            categoryIndex: findIndex(categoriesData, (d) => d.category === category),
        }))
        .map(({ cwe, value, description, categoryIndex, category }) => ({
            cwe,
            description,
            value,
            category,
            categoryIndex,
            fill: CWE_PALETTE[categoryIndex % CATEGORY_PALETTE.length],
            hoverFill: CWE_PALETTE_HOVER[categoryIndex % CWE_PALETTE_HOVER.length],
            textFill: CATEGORY_PALETTE[categoryIndex % CATEGORY_PALETTE.length],
        }));
    return { category: categoriesData, cwe: cwesData };
}

function onCategoryColorChange(target, cweSeries, eventType: "over" | "out", overrideColorIndex: number | null) {
    if (overrideColorIndex === null) {
        target.fill = am4core.color(target.dataItem.dataContext[eventType === "over" ? "hoverFill" : "fill"]);
        cweSeries.slices.each((slice) => {
            if (slice.dataItem.dataContext["categoryIndex"] === target.dataItem.dataContext["index"]) {
                slice.fill = am4core.color(slice.dataItem.dataContext[eventType === "over" ? "hoverFill" : "fill"]);
            }
        });
    } else {
        const categoryPallette = eventType === "over" ? CATEGORY_PALETTE_HOVER : CATEGORY_PALETTE;
        const cwePallette = eventType === "over" ? CWE_PALETTE_HOVER : CWE_PALETTE;
        target.fill = am4core.color(categoryPallette[overrideColorIndex % categoryPallette.length]);
        cweSeries.slices.each((slice) => {
            if (slice.dataItem.dataContext["categoryIndex"] === target.dataItem.dataContext["index"]) {
                slice.fill = am4core.color(cwePallette[overrideColorIndex % cwePallette.length]);
            }
        });
    }
}

function onCWEColorChange(
    target,
    categorySeries,
    cweSeries,
    eventType: "over" | "out",
    overrideColorIndex: number | null
) {
    if (overrideColorIndex === null) {
        cweSeries.slices.each((slice) => {
            if (slice.dataItem.dataContext["categoryIndex"] === target.dataItem.dataContext["categoryIndex"]) {
                slice.fill = am4core.color(slice.dataItem.dataContext[eventType === "over" ? "hoverFill" : "fill"]);
            }
        });
        categorySeries.slices.each((slice) => {
            if (slice.dataItem.dataContext["index"] === target.dataItem.dataContext["categoryIndex"]) {
                slice.fill = am4core.color(slice.dataItem.dataContext[eventType === "over" ? "hoverFill" : "fill"]);
            }
        });
    } else {
        const categoryPallette = eventType === "over" ? CATEGORY_PALETTE_HOVER : CATEGORY_PALETTE;
        const cwePallette = eventType === "over" ? CWE_PALETTE_HOVER : CWE_PALETTE;
        cweSeries.slices.each((slice) => {
            if (slice.dataItem.dataContext["categoryIndex"] === target.dataItem.dataContext["categoryIndex"]) {
                slice.fill = am4core.color(cwePallette[overrideColorIndex % cwePallette.length]);
            }
        });
        categorySeries.slices.each((slice) => {
            if (slice.dataItem.dataContext["index"] === target.dataItem.dataContext["categoryIndex"]) {
                slice.fill = am4core.color(categoryPallette[overrideColorIndex % categoryPallette.length]);
            }
        });
    }
}

class InteractiveCoveragePlot extends React.Component<InteractiveCoveragePlotProps, InteractiveCoveragePlotState> {
    private divContainer: MutableRefObject<HTMLDivElement>;
    private pieSeries;
    private pieSeries2;

    constructor(props) {
        super(props);
        const histogram: { [k: string]: number } = {};
        for (const { category } of props.data) {
            histogram[category] = histogram[category] === undefined ? 1 : histogram[category] + 1;
        }
        const categoryList = sortBy(
            Object.entries(histogram).map(([category, value]) => ({ category, value })),
            ["category"]
        );
        const overrideColorIndex = categoryList.findIndex(({ category }) => category === this.props.initialCategory);
        this.state = {
            selectedCategory: this.props.initialCategory || null,
            overrideColorIndex: overrideColorIndex === -1 ? null : overrideColorIndex,
        };
        this.divContainer = React.createRef<HTMLDivElement>();
    }

    componentDidMount() {
        const [pieSeries, pieSeries2] = this.remountChart();
        this.pieSeries = pieSeries;
        this.pieSeries2 = pieSeries2;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.initialCategory !== this.props.initialCategory) {
            this.setState({ selectedCategory: this.props.initialCategory });
        }
        if (prevState.selectedCategory === this.state.selectedCategory) {
            return;
        }
        const [pieSeries, pieSeries2] = this.remountChart();
        this.pieSeries = pieSeries;
        this.pieSeries2 = pieSeries2;
    }

    render() {
        const { classes, data } = this.props;

        const histogram: { [k: string]: number } = {};
        for (const { category } of data) {
            if (this.state.selectedCategory !== null && this.state.selectedCategory !== category) {
                continue;
            }
            histogram[category] = histogram[category] === undefined ? 1 : histogram[category] + 1;
        }
        const categoriesData = sortBy(
            Object.entries(histogram).map(([category, value]) => ({ category, value })),
            ["category"]
        );

        return (
            <div className={classes.chartWrapper}>
                <div ref={this.divContainer} className={classes.chart} />
                <div className={classes.legend}>
                    {categoriesData.map(({ category, value }, index) => (
                        <div
                            className={classes.legendItemContainer}
                            key={index}
                            onClick={() => {
                                if (this.state.overrideColorIndex !== null) {
                                    this.setState({ overrideColorIndex: null, selectedCategory: null });
                                } else {
                                    this.setState({
                                        selectedCategory: category,
                                        overrideColorIndex: index,
                                    });
                                }
                            }}
                            onMouseEnter={() => {
                                this.handleLegendMouseEnter(category, index);
                            }}
                            onMouseLeave={() => {
                                this.handleLegendMouseOut(category, index);
                            }}
                        >
                            <div
                                className={classes.legendItemText}
                                style={{
                                    color: CATEGORY_PALETTE[
                                        this.state.overrideColorIndex || index % CATEGORY_PALETTE.length
                                    ],
                                }}
                            >
                                {category}
                            </div>
                            <div
                                className={classes.legendItemCount}
                                style={{
                                    color: CATEGORY_PALETTE[
                                        this.state.overrideColorIndex || index % CATEGORY_PALETTE.length
                                    ],
                                    backgroundColor:
                                        CWE_PALETTE[this.state.overrideColorIndex || index % CWE_PALETTE.length],
                                }}
                            >
                                {value}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    private setCategoryFilter = (category: string, categoryIndex: number) => {
        this.setState((state) => ({
            ...state,
            selectedCategory: state.selectedCategory === null ? category : null,
            overrideColorIndex: state.selectedCategory === null ? categoryIndex : null,
        }));
    };

    private remountChart = () => {
        const visibleData =
            this.state.selectedCategory !== null
                ? this.props.data.filter((d) => d.category === this.state.selectedCategory)
                : this.props.data;
        const { category, cwe } = transformData(visibleData);
        // Set theme
        am4core.useTheme(am4themes_animated);

        // Create chart instance
        const chart = am4core.create(this.divContainer.current, am4charts.PieChart);

        chart.innerRadius = am4core.percent(20);
        chart.radius = 200;

        // Add and configure Series
        const onSliceClick = this.setCategoryFilter.bind(this);
        const pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "value";
        pieSeries.dataFields.category = "category";
        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.slices.template.strokeWidth = 2;
        pieSeries.slices.template.cornerRadius = 6;
        pieSeries.slices.template.strokeOpacity = 1;
        pieSeries.slices.template.events.on("hit", (x) => {
            onSliceClick(x.target.dataItem.properties.category, x.target.dataItem.dataContext["index"]);
        });
        pieSeries.labels.template.events.on("hit", (x) => {
            onSliceClick(x.target.dataItem.properties.category, x.target.dataItem.dataContext["index"]);
        });
        pieSeries.slices.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
        pieSeries.labels.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;

        pieSeries.alignLabels = false;
        pieSeries.labels.template.relativeRotation = 90;
        pieSeries.labels.template.truncate = true;
        pieSeries.labels.template.propertyFields.maxWidth = "maxWidth";
        if (this.state.overrideColorIndex === null) {
            pieSeries.slices.template.propertyFields.fill = "fill";
        } else {
            pieSeries.slices.template.fill = am4core.color(
                CATEGORY_PALETTE[this.state.overrideColorIndex % CATEGORY_PALETTE.length]
            );
        }
        pieSeries.labels.template.events.on("over", (e) => {
            if (e.target.dataItem.label && e.target.dataItem.label?.isOversized) {
                pieSeries.tooltip.show();
            }
        });
        pieSeries.labels.template.bent = true;
        pieSeries.labels.template.radius = -30;
        pieSeries.labels.template.padding(0, 0, 0, 0);
        pieSeries.labels.template.fill = am4core.color("#fff");
        pieSeries.labels.template.fontSize = "12px";
        pieSeries.labels.template.text = "{category}";

        pieSeries.innerRadius = am4core.percent(20);

        pieSeries.data = category;

        // Disabling ticks on inner circle
        pieSeries.ticks.template.disabled = true;

        // Disable sliding out of slices
        pieSeries.slices.template.states.getKey("hover").properties.scale = 1;
        pieSeries.slices.template.states.getKey("active").properties.shiftRadius = 0;
        pieSeries.slices.template.propertyFields.tooltipText = "category";
        pieSeries.tooltip.getFillFromObject = false;
        pieSeries.tooltip.fontSize = "12px";
        pieSeries.tooltip.background.cornerRadius = 6;
        pieSeries.tooltip.background.stroke = null;
        pieSeries.tooltip.background.filters.clear();
        pieSeries.tooltip.background.fill = am4core.color("#1B1B1B");
        pieSeries.tooltip.disabled = false;
        pieSeries.slices.template.propertyFields.tooltipText = "category";
        pieSeries.slices.template.adapter.add("tooltipText", (text, target) => {
            const matchingLabel = pieSeries.labels.getIndex(target.dataItem.index);
            return matchingLabel.isOversized ? text : null;
        });
        pieSeries.tooltip.getFillFromObject = false;
        pieSeries.tooltip.background.fill = am4core.color("#000");
        pieSeries.tooltip.filters.clear();
        if (this.state.overrideColorIndex !== null) {
            pieSeries.rotation = 180;
        }

        pieSeries.slices.template.events.on("over", ({ target }) => {
            onCategoryColorChange(target, pieSeries2, "over", this.state.overrideColorIndex);
        });
        pieSeries.labels.template.events.on("over", ({ target }) => {
            const slice = pieSeries.slices.getIndex(target.dataItem.dataContext["index"]);
            onCategoryColorChange(slice, pieSeries2, "over", this.state.overrideColorIndex);
        });

        pieSeries.slices.template.events.on("out", ({ target }) => {
            onCategoryColorChange(target, pieSeries2, "out", this.state.overrideColorIndex);
        });
        pieSeries.labels.template.events.on("out", ({ target }) => {
            const slice = pieSeries.slices.getIndex(target.dataItem.dataContext["index"]);
            onCategoryColorChange(slice, pieSeries2, "out", this.state.overrideColorIndex);
        });

        // Add second series
        const pieSeries2 = chart.series.push(new am4charts.PieSeries());
        pieSeries2.dataFields.value = "value";
        pieSeries2.dataFields.category = "category";
        if (this.state.overrideColorIndex === null) {
            pieSeries2.slices.template.propertyFields.fill = "fill";
        } else {
            pieSeries2.slices.template.fill = am4core.color(
                CWE_PALETTE[this.state.overrideColorIndex % CWE_PALETTE.length]
            );
        }
        pieSeries2.labels.template.propertyFields.tooltipText = "description";
        pieSeries2.slices.template.propertyFields.tooltipText = "description";
        pieSeries2.slices.template.stroke = am4core.color("#fff");
        pieSeries2.slices.template.strokeWidth = 2;
        pieSeries2.slices.template.cornerRadius = 6;
        pieSeries2.slices.template.strokeOpacity = 1;
        pieSeries2.slices.template.states.getKey("hover").properties.scale = 1;
        pieSeries2.slices.template.states.getKey("active").properties.shiftRadius = 0;

        pieSeries2.alignLabels = false;
        pieSeries2.labels.template.radius = -62;
        pieSeries2.labels.template.padding(0, 0, 0, 0);
        if (this.state.overrideColorIndex === null) {
            pieSeries2.labels.template.propertyFields.fill = "textFill";
        } else {
            pieSeries2.labels.template.fill = am4core.color(
                CATEGORY_PALETTE[this.state.overrideColorIndex % CATEGORY_PALETTE.length]
            );
        }
        pieSeries2.labels.template.events.on("over", () => {
            pieSeries2.tooltip.show();
        });
        pieSeries2.labels.template.fontSize = "12px";
        pieSeries2.labels.template.text = "{cwe}";
        pieSeries2.labels.template.relativeRotation = 90;
        pieSeries2.ticks.template.disabled = true;
        pieSeries2.tooltip.getFillFromObject = false;
        pieSeries2.tooltip.fontSize = "12px";
        pieSeries2.tooltip.background.cornerRadius = 6;
        pieSeries2.tooltip.background.stroke = null;
        pieSeries2.tooltip.background.filters.clear();
        pieSeries2.tooltip.background.fill = am4core.color("#1B1B1B");

        pieSeries2.slices.template.events.on("over", ({ target }) => {
            onCWEColorChange(target, pieSeries, pieSeries2, "over", this.state.overrideColorIndex);
        });
        pieSeries2.labels.template.events.on("over", ({ target }) => {
            onCWEColorChange(target, pieSeries, pieSeries2, "over", this.state.overrideColorIndex);
        });

        pieSeries2.slices.template.events.on("out", ({ target }) => {
            onCWEColorChange(target, pieSeries, pieSeries2, "out", this.state.overrideColorIndex);
        });
        pieSeries2.labels.template.events.on("out", ({ target }) => {
            onCWEColorChange(target, pieSeries, pieSeries2, "out", this.state.overrideColorIndex);
        });

        pieSeries2.data = cwe;

        return [pieSeries, pieSeries2];
    };

    private handleLegendMouseEnter(category: string, index: number) {
        const i = this.state.overrideColorIndex === null ? index : this.state.overrideColorIndex;
        this.pieSeries.slices.getIndex(index).fill = CATEGORY_PALETTE_HOVER[i];
        this.pieSeries2.slices.each((slice) => {
            if (slice.dataItem.properties.category === category) {
                slice.fill = CWE_PALETTE_HOVER[i];
            }
        });
    }

    private handleLegendMouseOut(category: string, index: number) {
        const i = this.state.overrideColorIndex === null ? index : this.state.overrideColorIndex;
        this.pieSeries.slices.getIndex(index).fill = CATEGORY_PALETTE[i];
        this.pieSeries2.slices.each((slice) => {
            if (slice.dataItem.properties.category === category) {
                slice.fill = CWE_PALETTE[i];
            }
        });
    }
}

export default withStyles(interactiveCoverageDialogStyle)(InteractiveCoveragePlot);
