import { makeStyles } from "@material-ui/core";

export const useIssuesIsResolvedStyle = makeStyles((theme) => ({
    issuesIsResolvedContainer: {
        display: "flex",
        alignItems: "center",
        gap: 6,
        color: "#909090",
        cursor: "pointer",

        "&:hover": {
            color: "#15AC5A",
        },
    },

    issuesIsResolved: {
        color: "#15AC5A",
        cursor: "pointer",
    },

    issuesIsDismissed: {
        color: "#999",
        cursor: "pointer",
    },
}));
