import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { withRouter, Link, Redirect } from "react-router-dom";
import { History } from "history";

import GoogleRecaptchaWrapper from "../../GoogleRecaptcha/GoogleRecaptcha";
import ServiceWire from "../../../lib/services/ServiceWire";
import { GlobalState } from "../../../lib/state/GlobalState";
import { setUserInfoAction } from "../../../lib/redux/users/SetUserAction";
import { SternumInputField } from "../../SUI/SternumInputField";
import SternumImprovedButton from "../../SUI/SternumImprovedButton/SternumImprovedButton";
import SternumBanner from "../../SUI/SternumBanner/SternumBanner";
import { AuthenticationContainer } from "../AuthenticationContainer";
import { useCommonStyle } from "../../CommonStyle";
import { useLoginStyle } from "./Login.style";
import { useLoginManagerStyle } from "../LoginManager.style";
import { HomeIcon } from "../../SUI/SternumIcon";
import SternumConfiguration from "../../../lib/infra/SternumConfiguration";

export interface LoginComponentProps {
    adminLogin?: boolean;
    onForgotPasswordClick: () => unknown;
}

interface FormValues {
    email: string;
    password: string;
}

const mapStateToProps = (state: GlobalState, ownProps: LoginComponentProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return { setUserInfoAction: (user) => dispatch(setUserInfoAction(user)) };
};

type LoginPropsWithHOC = LoginComponentProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
        history: History;
    };

function LoginComponent({ adminLogin, history, onForgotPasswordClick, setUserInfoAction }: LoginPropsWithHOC) {
    const reCaptchaElementRef = useRef<any>();
    const classes = useLoginStyle();
    const classesLogin = useLoginManagerStyle();
    const classesCommon = useCommonStyle();

    const [wrongAuthentication, setWrongAuthentication] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [shouldRedirectToDashboard, setShouldRedirectToDashboard] = useState(false);
    const [captchaToken, setCaptchaToken] = useState("");
    const formik = useFormik<FormValues>({
        initialValues: {
            email: "",
            password: "",
        },
        validate: (values) => {
            const errors: Partial<FormValues> = {};

            if (values.email.length < 1) {
                errors.email = "The field is required";
            }

            if (values.password.length < 1) {
                errors.password = `The password is required`;
            }

            return errors;
        },
        onSubmit: async (values) => {
            try {
                const response = await ServiceWire.getAuthenticationService().authenticate(
                    values.email,
                    values.password,
                    !!adminLogin,
                    captchaToken
                );

                if (response) {
                    setUserInfoAction(ServiceWire.getAuthenticationService().getCachedAuthenticatedUser());

                    if (response.authenticationType) {
                        history.push(response.getRedirectURL(), history.location.state);
                    }
                }
            } catch (err) {
                if (!err?.response?.status) {
                    return setErrorMsg("Internal server error");
                }

                let errorMsg = "";

                // Api limit error
                if (String(err.response.status) === "429") {
                    errorMsg = `You have reached the maximum number of requests allowed for login operation. \n
                    Your account has been locked for approximately 20 minutes`;
                } else {
                    // Wrong authentication credentials
                    errorMsg = "Your email or password is not correct";
                }

                setWrongAuthentication(true);
                setErrorMsg(errorMsg);

                setCaptchaToken("");
                reCaptchaElementRef.current?.reset();
            }
        },
    });

    useEffect(() => {
        const isAuthenticated = ServiceWire.getAuthenticationService().isAuthenticated();

        if (isAuthenticated) {
            setShouldRedirectToDashboard(true);
        }
    }, []);

    const handleRecaptchaSuccess = (reCaptchaToken: string) => {
        // Here you will get the final reCaptchaToken
        setCaptchaToken(reCaptchaToken);
    };

    const handleRecaptchaError = () => {
        setCaptchaToken("");
    };

    const isLoginButtonDisabled = () => {
        return formik.isSubmitting || Object.keys(formik.errors).length > 0;
    };

    if (shouldRedirectToDashboard) {
        return <Redirect to="/dashboard" />;
    }

    return (
        <AuthenticationContainer>
            <h1 className={classNames(classesLogin.title)}>
                <span>
                    <span>Sign in to</span> <span className={classNames(classes.titleDecorated)}>Sternum Platform</span>
                </span>
            </h1>

            <div className={classNames(classesLogin.authFieldsContainer)}>
                {wrongAuthentication && (
                    <div className={classNames(classesCommon.marginBottomXLarge, classesCommon.fullWidth)}>
                        <SternumBanner
                            messageType="error"
                            message={errorMsg}
                            onCloseClick={() => setWrongAuthentication(false)}
                        />
                    </div>
                )}

                {/* Email input */}
                <SternumInputField
                    label={"Email"}
                    inputValue={formik.values.email}
                    onFieldChange={(e) => formik.setFieldValue("email", e.target.value)}
                    addMargin={true}
                    isTouched={!!formik.touched.email}
                    error={!!formik.errors.email}
                    helperText={formik.touched.email ? formik.errors.email : undefined}
                    onTouch={() => formik.setFieldTouched("email", true)}
                    required={true}
                />

                {/* Password input */}
                <SternumInputField
                    label={"Password"}
                    inputValue={formik.values.password}
                    onFieldChange={(e) => formik.setFieldValue("password", e.target.value)}
                    onTouch={() => formik.setFieldTouched("password", true)}
                    isTouched={!!formik.touched.password}
                    isPassword={true}
                    error={!!formik.errors.password}
                    helperText={formik.touched.password ? formik.errors.password : undefined}
                    addMargin={true}
                />

                {/** Google reCaptcha */}
                <div className={classesLogin.captcha}>
                    <GoogleRecaptchaWrapper
                        elementRef={reCaptchaElementRef}
                        onRecaptchaSuccess={handleRecaptchaSuccess}
                        onRecaptchaError={handleRecaptchaError}
                    />
                </div>

                <div className={classesLogin.buttonContainer}>
                    <SternumImprovedButton
                        buttonType="regularWithDisabled"
                        className={classesLogin.loginButton}
                        content={"Sign in"}
                        onClick={formik.handleSubmit}
                        isDisabled={isLoginButtonDisabled()}
                        isLoading={formik.isSubmitting}
                    />
                    <Link
                        className={classNames(classesCommon.cursorPointer, classes.forgotPasswordLink)}
                        component="div"
                        variant="body2"
                        onClick={() => onForgotPasswordClick()}
                    >
                        Forgot Password?
                    </Link>
                </div>

                {SternumConfiguration.getPLGEnabled() && (
                    <div className={classesLogin.signupLinkContainer}>
                        Don't have an account? <Link to="/signup">Sign up</Link>
                    </div>
                )}

                {SternumConfiguration.getPLGEnabled() && (
                    <a
                        className={classNames(classesLogin.homeButtonContainer, classesCommon.cursorPointer)}
                        href="https://www.sternumiot.com/"
                    >
                        <HomeIcon /> Go to Homepage
                    </a>
                )}
            </div>
        </AuthenticationContainer>
    );
}

export const Login: React.FC<LoginComponentProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(LoginComponent));
