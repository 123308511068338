import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";
import { sternumTheme } from "../App";

const argumentsListDisplayStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        container: {
            background: sternumTheme.lightGrey.main,
            width: "100%",
            height: "100%",
            overflowY: "auto",
            padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
            marginTop: theme.spacing(3),
        },

        kernelLogMessage: {
            display: "flex",
            alignItems: "flex-start",
            marginBottom: theme.spacing(2),
        },

        kernelLogMessageText: {
            fontFamily: '"Courier Prime", monospace',
            marginLeft: theme.spacing(1),
        },

        kernelLogTime: {
            display: "inline-block",
            marginLeft: theme.spacing(1),
            color: "rgba(0, 0, 0, .5)",
        },

        kernelLogPriority: {
            color: "rgba(0, 0, 0, .5)",
        },

        kernelLogChevron: {
            display: "inline-block",
            marginLeft: theme.spacing(1),
            marginTop: 4,
            fontSize: "0.6rem",
        },
    });

export default argumentsListDisplayStyle;
