import { Modal } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import Paper from "@material-ui/core/Paper";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import classNames from "classnames";
import * as React from "react";
import { ArrowBackIcon, CrossIcon, FullScreenIcon } from "../SternumIcon/SternumIcon";
import sternumModalStyle from "./SternumModalStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    fullscreenMode: boolean;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof sternumModalStyle> {
    modalKey: string;
    open: boolean;
    onClose: (event: Object) => void;
    showXButton?: boolean;
    showBackButton?: boolean;
    showFullScreenButton?: boolean;
    fullscreen?: boolean;
    stacked?: boolean;
    modalContentClassNames?;
    ignoreClickOnFadeArea?: boolean;
    onFullscreenDisplayChanged?: (fullscreenDisplay: boolean) => void;
}

const SternumModal: React.FC<AppProps> = ({
    ignoreClickOnFadeArea,
    fullscreen,
    onClose,
    classes,
    open,
    stacked,
    modalContentClassNames,
    showXButton,
    showFullScreenButton,
    onFullscreenDisplayChanged,
    children,
    showBackButton,
}) => {
    const [fullscreenMode, setFullscreenMode] = React.useState<boolean>(!!fullscreen);

    const handleClickOnFadeArea = (event) => {
        if (ignoreClickOnFadeArea) {
            return;
        }
        onClose(event);
    };

    const enterFullscreenMode = () => {
        setFullscreenMode(true);
        invokeFullscreenDisplayChanged();
    };

    const invokeFullscreenDisplayChanged = () => {
        if (onFullscreenDisplayChanged) {
            onFullscreenDisplayChanged(fullscreenMode);
        }
    };

    const exitFullscreenMode = () => {
        setFullscreenMode(false);
        invokeFullscreenDisplayChanged();
    };

    return (
        <Modal
            role="dialog"
            aria-label="sternum modal"
            disableEnforceFocus
            open={open}
            onClose={handleClickOnFadeArea}
            BackdropProps={{
                classes: {
                    root: classNames(classes.backdrop, stacked && "mod-stacked"),
                },
            }}
        >
            <Paper
                classes={{
                    root: classNames(
                        classes.modalContentContainer,
                        modalContentClassNames,
                        fullscreenMode && "mod-fullscreen",
                        stacked && "mod-stacked"
                    ),
                }}
            >
                {/* Top bar buttons container */}
                {(showXButton || showFullScreenButton) && (
                    <div className={classes.topButtonsContainer} aria-label={"Close"}>
                        {/* Fullscreen Button */}
                        {showFullScreenButton && !fullscreenMode && (
                            <FullScreenIcon
                                color="#909090"
                                className={classNames(classes.topButtonIcon, classes.fullscreenIcon)}
                                onClick={() => enterFullscreenMode()}
                            />
                        )}

                        {/* Exit fullscreen Button */}
                        {showFullScreenButton && fullscreenMode && (
                            <FullscreenExitIcon
                                className={classNames(classes.topButtonIcon, classes.fullscreenIcon)}
                                onClick={() => exitFullscreenMode()}
                            />
                        )}

                        {/* X Button */}
                        {showXButton && (
                            <CrossIcon
                                role="button"
                                aria-label="close modal"
                                color="#909090"
                                className={classes.topButtonIcon}
                                onClick={(event) => onClose(event)}
                            />
                        )}
                    </div>
                )}

                {/* Back button */}
                {showBackButton && (
                    <div className={classes.backButtonContainer} aria-label={"Back"}>
                        <Typography className={classes.backButtonIconAndText} onClick={(event) => onClose(event)}>
                            {/* Back arrow */}
                            <ArrowBackIcon color="#909090" />

                            {/* Back text */}
                            <span>Back</span>
                        </Typography>
                    </div>
                )}

                {children}
            </Paper>
        </Modal>
    );
};

export default withStyles(sternumModalStyle)(SternumModal);
