import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../../CommonStyle";

const visualisationConfigurationPresentationMetricStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: {
            position: "relative",
            height: theme.spacing(40),
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
    });

export default visualisationConfigurationPresentationMetricStyle;
