import React from "react";

import { SternumIconProps } from "./SternumIcon.types";

export const AppleLogoIcon = React.forwardRef<SVGSVGElement, SternumIconProps>(function AppleLogoIcon(
    { color, height = 28, width = 28, ...props },
    ref
) {
    return (
        <svg
            ref={ref}
            width={width}
            height={height}
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.6696 2C17.8686 3.397 17.3186 4.76561 16.5932 5.73391C15.817 6.77253 14.4792 7.57584 13.183 7.53392C12.9464 6.19642 13.5523 4.81835 14.2894 3.89197C15.0981 2.86958 16.484 2.0852 17.6696 2ZM21.5634 21.6635C22.2316 20.6046 22.4813 20.0704 23 18.8749C19.2271 17.3914 18.6226 11.8453 22.3565 9.71665C21.2176 8.23986 19.6172 7.3838 18.1065 7.3838C17.018 7.3838 16.2721 7.67752 15.5941 7.94454C15.0291 8.16706 14.5111 8.37104 13.8812 8.37104C13.2004 8.37104 12.5976 8.14754 11.9664 7.91352C11.2727 7.65637 10.5448 7.38651 9.6416 7.38651C7.94629 7.38651 6.14177 8.45759 4.99769 10.2887C3.38948 12.8677 3.6638 17.7159 6.27178 21.8461C7.20394 23.3242 8.44942 24.985 10.0784 24.9998C10.7544 25.0068 11.2042 24.7982 11.6909 24.5724C12.248 24.3141 12.8536 24.0332 13.902 24.0275C14.9566 24.0209 15.5526 24.3052 16.1023 24.5674C16.5766 24.7935 17.0164 25.0033 17.6865 24.9958C19.3169 24.9823 20.6312 23.1417 21.5634 21.6635Z"
                fill={color || "currentColor"}
            />
        </svg>
    );
});
