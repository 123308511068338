import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

const libraryViewStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),
        libraryViewUpperContent: {
            paddingTop: theme.spacing(10),
            paddingRight: theme.spacing(5),
            paddingBottom: theme.spacing(5),
            paddingLeft: theme.spacing(5),

            "&.mod-with-back-button": {
                paddingTop: theme.spacing(10),
            },
        },
        title: {
            marginRight: theme.spacing(2),
        },
        securityAlertsTabContent: {
            paddingTop: theme.spacing(2),
        },
        libraryInfoTabContent: {
            padding: theme.spacing(4),
        },
        devicesListTabContent: {
            paddingTop: theme.spacing(2),
        },
        cvesListTabContent: {
            paddingTop: theme.spacing(2),
        },
        historiesListTabContent: {
            paddingTop: theme.spacing(2),
        },
        devicesListPaper: {
            "&.mod-regular": {
                maxHeight: theme.spacing(68),
                minHeight: theme.spacing(67) - 1,
            },
            "&.mod-fullscreen": {
                maxHeight: "100vh",
            },
        },
        cvesListPaper: {
            "&.mod-regular": {
                maxHeight: theme.spacing(68),
            },
            "&.mod-fullscreen": {
                maxHeight: "100vh",
            },
        },
        historiesListPaper: {
            "&.mod-regular": {
                maxHeight: theme.spacing(68),
                minHeight: theme.spacing(67) - 1,
            },
            "&.mod-fullscreen": {
                maxHeight: "100vh",
            },
        },
    });

export default libraryViewStyle;
