import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

const sternumDevicePerformanceListStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        paperClass: {
            overflowY: "auto" as "auto",
        },
        linkText: {
            fontSize: "0.8125rem",
            fontWeight: 400,
            marginRight: theme.spacing(1),
            "&:hover": {
                textDecoration: "underline",
            },
        },
        linkIcon: {
            fontSize: "0.8125rem",
            paddingRight: theme.spacing(3),
        },
        traceLinkText: {
            fontSize: "0.8125rem",
            fontWeight: 400,
            marginRight: theme.spacing(1),
            "&:hover": {
                textDecoration: "underline",
            },
        },
        traceLinkIcon: {
            fontSize: "0.8125rem",
            paddingRight: theme.spacing(3),
        },
        deviceAlertName: {
            width: "18vw",
        },
        definitionAlertName: {
            width: "9vw",
        },

        usageDiffUp: {
            marginRight: theme.spacing(1),
            color: "red",
            fontSize: "0.7rem",
        },
        usageDiffDown: {
            marginRight: theme.spacing(1),
            color: "green",
            fontSize: "0.7rem",
        },
        usageIcon: {
            height: "auto",
            fontSize: "0.6rem",
        },
    });

export default sternumDevicePerformanceListStyle;
