import React from "react";
import classNames from "classnames";
import { Link, useMediaQuery } from "@material-ui/core";

import { ArrowLeftIcon, SternumBgLightLogo, SternumLongLogoMultiColorIcon } from "../../SUI/SternumIcon";
import { useCommonStyle } from "../../CommonStyle";
import { useAuthenticationStyle } from "./AuthenticationContainer.style";

import welcomeVideo from "../../../video/welcome.mp4";
import { commonLoginManager } from "../LoginManager.style";

export interface AuthenticationContainerProps {
    className?: string;
    onBack?: (e: React.MouseEvent<HTMLAnchorElement>) => unknown;
    children?: React.ReactNode;
}

export function AuthenticationContainer({ className, onBack, children }: AuthenticationContainerProps) {
    const classes = useAuthenticationStyle();
    const classesCommon = useCommonStyle();
    const isMobile = useMediaQuery(`(max-width: ${commonLoginManager.mediaQueryMaxWidth})`, { noSsr: true });

    return (
        <div className={classNames(classes.authenticationContainer, className)}>
            <div className={classes.headerLogo}>
                <SternumLongLogoMultiColorIcon width={143} height={24} />
            </div>

            {onBack && (
                <Link
                    className={classNames(classes.backButton, classesCommon.cursorPointer)}
                    component="a"
                    variant="body2"
                    onClick={onBack}
                >
                    <ArrowLeftIcon className={classes.backButtonIcon} />
                    <span>Back</span>
                </Link>
            )}

            <div className={classes.mobilePadding} />

            {!isMobile && (
                <video className={classes.backgroundVideo} autoPlay loop>
                    <source src={welcomeVideo} type="video/mp4" />
                </video>
            )}

            <SternumBgLightLogo className={classes.bgLightLogo} />

            <div className={classNames(classes.authenticationContainerContent)}>{children}</div>
        </div>
    );
}
