import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

const usersListActionsStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        smallButton: {
            padding: "4px",
        },
        svgDeleteIcon: {
            "&:hover": {
                filter: "invert(13%) sepia(93%) saturate(7403%) hue-rotate(334deg) brightness(88%) contrast(107%)",
            },
        },
        svgEditIcon: {
            "&:hover": {
                filter: "invert(32%) sepia(99%) saturate(2814%) hue-rotate(205deg) brightness(93%) contrast(86%)",
            },
        },
    });

export default usersListActionsStyle;
