import { Theme } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

let dashboardMetricsDataTileStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        popover: {
            pointerEvents: "none",
        },

        tilesGrid: {
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(100px, 1fr))",
            columnGap: "12px",
        },

        positiveKeyMetric: {
            color: "#15AC5A",
            transition: "all 0.2s ease",
        },
        negativeKeyMetric: {
            color: "#E7004C",
            transition: "all 0.2s ease",
        },
        metricDeltaArrowIcon: {
            fontSize: "16px",
            marginRight: theme.spacing(-1),
        },
        metricDeltaValue: {
            marginLeft: theme.spacing(1),
        },
        metricValue: {
            display: "flex",
            justifyContent: "flex-end",
        },
        explanationTypography: {
            width: theme.spacing(40),
        },
        infoCircleIcon: {
            marginLeft: "6px",

            fontSize: "16px",
            color: "#1B6FDE",
            transition: "all 0.2s ease",
        },

        metricCard: {
            position: "relative",
            overflow: "hidden",
            padding: theme.spacing(3.5, 3, 3, 3),
            background: "#fff",
            boxShadow: "0px 0px 23px rgba(231, 232, 235, 0.5)",
            borderRadius: 14,

            "&.mod-link": {
                transition: "all 0.2s ease",
                cursor: "pointer",

                "&:hover": {
                    background: "#D8034A",
                    color: "#fff",

                    "& .MuiIcon-root": {
                        color: "#fff",
                    },

                    "& $positiveKeyMetric": {
                        color: "#fff",
                    },

                    "& $negativeKeyMetric": {
                        color: "#fff",
                    },
                },
            },
        },

        metricCardIcon: {
            position: "absolute",
            top: 18,
            right: 12,

            "& svg": {
                fontSize: "1.3rem",
            },
        },

        metricCardTitle: {
            fontSize: 14,
            fontWeight: 500,
            maxWidth: "90%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",

            [`@media (max-width: 1300px)`]: {
                fontSize: 12,
            },
        },

        metricCardValue: {
            fontSize: "24px",

            "&.devices": {
                display: "flex",
                alignItems: "flex-end",

                "& .activeDevicesNumber": {
                    fontSize: "18px",
                    marginBottom: "2px",
                },
            },
        },

        devicesMetricContainer: {
            display: "flex",
            alignItems: "center",
            marginLeft: theme.spacing(1),
        },

        devicesMetricSeparator: {
            fontSize: "18px",
            margin: "0 4px",
            marginBottom: "4px",
        },

        devicesDeltaSeparator: {
            fontSize: "1rem",
            margin: "0 4px",
        },

        devicesDeltaContainer: {
            display: "flex",
            alignItems: "center",
        },

        cveMetricShowButton: {
            marginLeft: theme.spacing(3),
            cursor: "pointer",
            "& .MuiLink-root": {
                color: "#F980A9",
            },
        },
        popoverContentContainer: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            fontSize: "0.875rem",
        },
    });

export default dashboardMetricsDataTileStyle;
