import React, { ReactNode } from "react";
import classNames from "classnames";
import moment from "moment";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

import { PatternWithTraces } from "../../../lib/state/Pattern";
import { TitleWithTag } from "../TitleWithTag";
import { LegendDetails } from "../LegendDetails";
import { useReceivedTraces } from "./useReceivedTraces";
import { useTracesTableDef } from "./useTracesTableDef";
import { ReceivedTrace, ReceivedTraceStatus } from "./ReceivedTraces.types";

import { useTraceSequenceSharedStyle } from "../TraceSequence.style";
import { useReceivedTracesStyles } from "./ReceivedTraces.styles";
import { getMappedValue } from "../../../utils";

export interface ReceivedTracesProps {
    patternsWithTraces: PatternWithTraces[];
    selectedPatternIndex?: number;
}

export function ReceivedTraces({ patternsWithTraces, selectedPatternIndex = 0 }: ReceivedTracesProps) {
    const classesTrace = useTraceSequenceSharedStyle();
    const classes = useReceivedTracesStyles();

    const { receivedTraces, receivedTracesCount, receivedUnexpectedCount, notReceivedCount, totalCount } =
        useReceivedTraces(patternsWithTraces, selectedPatternIndex);

    const { tableDefs } = useTracesTableDef<ReceivedTrace>({
        tableDefs: [
            {
                id: "number",
                width: "1%",
                cellRowClassName: classes.tableRowCellNumber,
                headCellName: "#",
                renderBodyCell: (data, rowIndex) =>
                    getMappedValue<ReceivedTraceStatus, ReactNode>(
                        {
                            [ReceivedTraceStatus.Received]: data.orderIndex,
                            [ReceivedTraceStatus.NotReceived]: data.orderIndex,
                            [ReceivedTraceStatus.Unexpected]: <div className={classes.dot} />,
                        },
                        data.traceStatus,
                        rowIndex + 1
                    ),
            },
            {
                id: "received",
                width: "37%",
                cellRowClassName: classes.tableRowCellReceived,
                headCellName: "Received",
                renderBodyCell: ({ receivedTime, traceStatus }) =>
                    getMappedValue<ReceivedTraceStatus, ReactNode>(
                        {
                            [ReceivedTraceStatus.Received]: moment(receivedTime).format("MM/DD/YYYY HH:mm:ss"),
                            [ReceivedTraceStatus.NotReceived]: "Not received",
                            [ReceivedTraceStatus.Unexpected]: moment(receivedTime).format("MM/DD/YYYY HH:mm:ss"),
                        },
                        traceStatus,
                        receivedTime ? moment(receivedTime).format("MM/DD/YYYY HH:mm:ss") : "Not received"
                    ),
            },
            {
                id: "eventType",
                width: "80%",
                cellRowClassName: classes.tableRowCellEventType,
                headCellName: "Event Type",
                renderBodyCell: ({ eventType }) => <span className={classes.tableEventTypeCell}>{eventType}</span>,
            },
        ],
    });

    return (
        <div>
            <div className={classesTrace.beforeTableHeader}>
                <TitleWithTag tagValue={receivedTracesCount}>Received Traces</TitleWithTag>
                <LegendDetails>
                    <LegendDetails.Item circleColor="#f79a1d" count={notReceivedCount}>
                        Not Received
                    </LegendDetails.Item>
                    <LegendDetails.Separator />
                    <LegendDetails.Item circleColor="#d8e1ff" count={receivedUnexpectedCount}>
                        Received unexpected
                    </LegendDetails.Item>
                </LegendDetails>
            </div>
            <Table>
                <TableHead className={classes.tableHead}>
                    <TableRow>
                        {tableDefs.map((def) => (
                            <TableCell key={def.id} width={def.width} className={classes.tableHeadCell}>
                                {def.headCellName}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody data-onboarding="anomaly-trace-sequence-traces-table">
                    {receivedTraces.map((receivedTrace, rowIndex) => {
                        return (
                            <TableRow
                                key={rowIndex}
                                className={classNames(
                                    classes.tableRow,
                                    receivedTrace.traceStatus === ReceivedTraceStatus.NotReceived &&
                                        classes.tableRowNotReceived,
                                    receivedTrace.traceStatus === ReceivedTraceStatus.Unexpected &&
                                        classes.tableRowUnexpected
                                )}
                            >
                                {tableDefs.map((def) => (
                                    <TableCell
                                        key={def.id}
                                        className={classNames(classes.tableRowCell, def.cellRowClassName)}
                                    >
                                        {def.renderBodyCell(receivedTrace, rowIndex)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </div>
    );
}
