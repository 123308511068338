import { Theme } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useGoProDialogStyle = makeStyles((theme: Theme) => ({
    root: {
        padding: "8px 18px",
    },
    paper: {
        width: "367px",
        boxShadow: "0px 0px 50px rgba(0, 0, 0, 0.12)",
        borderRadius: "8px",
        "& .MuiDialogActions-root": {
            justifyContent: "flex-start",
        },
    },
    titleContainer: {
        paddingBottom: 0,
    },
    title: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    actions: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
    },
    paragraph: {
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "150%",
        color: "#737373",
        "& a": {
            color: "#1B6FDE",
            textDecoration: "none",
        },
    },
}));

export default useGoProDialogStyle;
