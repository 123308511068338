import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        height: "90px",
        padding: "0 34px",
        background: "#fff",
        boxShadow: "0px 4px 10px #F2F4FA",
        borderRadius: "14px",
    },

    title: {
        fontSize: "16px",
        fontWeight: 500,
    },

    linkButton: {
        fontSize: "16px",
        color: theme.palette.secondary.dark,
        fontWeight: 500,
        marginLeft: "5px",
        cursor: "pointer",
    },
}));
