import { ClickAwayListener, Icon, ListItemIcon, Menu, MenuItem, Typography } from "@material-ui/core";
import classNames from "classnames";
import * as React from "react";
import { useEffect, useState } from "react";
import { CopyMechanismInfo } from "../../../lib/state/CopyMechanismInfo";
import useSternumCopyMenuStyles from "./SternumCopyMenuStyles";

interface AppProps {
    fieldsInfo: CopyMechanismInfo[];
    anchorEl: Element;
    openMenu: boolean;
    copyId: (fieldName: string) => void;
    closeMenu: () => void;
}

const SternumCopyMenu = ({ fieldsInfo, anchorEl, openMenu = false, copyId, closeMenu }: AppProps) => {
    const classes = useSternumCopyMenuStyles();

    const [openRowMenu, setMenuState] = useState(false);

    useEffect(() => {
        setMenuState(openMenu);
    }, [openMenu]);

    return (
        <ClickAwayListener
            onClickAway={() => {
                setMenuState(false);
            }}
        >
            <Menu
                id="copy-sternum-menu"
                open={openRowMenu}
                onClose={() => {
                    setMenuState(false);
                    closeMenu();
                }}
                getContentAnchorEl={null}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "center", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                keepMounted
                onContextMenu={(event) => {
                    event.preventDefault();
                    setMenuState(false);
                    closeMenu();
                }}
                MenuListProps={{ disablePadding: true }}
            >
                {/* Open device definition version view */}
                {fieldsInfo.map((field: CopyMechanismInfo) => {
                    return (
                        <MenuItem
                            onClick={() => {
                                copyId(field.value);
                                closeMenu();
                            }}
                            className={classNames(classes.menuItemLeftRightPadding, classes.menuItemTopBottomPadding)}
                            id={field.label}
                            key={field.label}
                        >
                            <ListItemIcon>
                                <Icon
                                    className={classNames("fas fa-paste", classes.iconExtraSmallSize)}
                                    fontSize={"small"}
                                    color="primary"
                                />
                            </ListItemIcon>
                            <Typography variant="subtitle2">{field.label}</Typography>
                        </MenuItem>
                    );
                })}
            </Menu>
        </ClickAwayListener>
    );
};

export default SternumCopyMenu;
