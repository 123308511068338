import { EntitiesState, getDefaultEntitiesState } from "./EntitiesState";
import IssueInfo from "./IssueInfo";
import HashMap from "../infra/HashMap";
import { IssueInfoState, IssueResolveReasonsStoreData } from "./IssueInfoType";
import { Status } from "./GlobalTypes";

export interface IssuesState {
    entities: EntitiesState<IssueInfo>;

    selectedIssue?: IssueInfo | null;
    issueStateByIssueId: HashMap<IssueInfoState>;
    issueResolveReasons: IssueResolveReasonsStoreData;

    loadingPendingIssuesCount: boolean;
    errorLoadingPendingIssuesCount: boolean;
    pendingIssuesCount: number;
}

export function getDefaultIssuesState(): IssuesState {
    return {
        entities: getDefaultEntitiesState<IssueInfo>(),

        selectedIssue: null,
        issueStateByIssueId: new HashMap<IssueInfoState>(),
        issueResolveReasons: {
            data: [],
            status: Status.NotInitialized,
        },

        loadingPendingIssuesCount: false,
        errorLoadingPendingIssuesCount: false,
        pendingIssuesCount: 0,
    };
}
