import { grey } from "@material-ui/core/colors";
import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../../components/CommonStyle";
const timeSelectionComponentStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        selectComponent: {
            minWidth: theme.spacing(50),
        },
        customDateSelect: {
            width: theme.spacing(110),
            height: theme.spacing(20),
        },

        setButton: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },

        syncIcon: {
            fontSize: "15px",
        },

        closeButtonIcon: {
            cursor: "pointer",
            color: grey[500],
            "&:hover": {
                color: theme.palette.common.black,
            },
        },

        refreshIconContainer: {
            width: 45,
            height: 45,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#fff",
            boxShadow: "0px 0px 23px rgba(231, 232, 235, 0.25)",
            borderRadius: 14,
        },
    });

export default timeSelectionComponentStyle;
