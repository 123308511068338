import { useEffect, useState } from "react";

import ServiceWire from "../../../lib/services/ServiceWire";
import { BuildVersionByReleaseSystem, ReleaseSystem } from "../../../lib/state/DownloadType";

export const useBuildVersionByReleaseSystem = () => {
    const [buildVersionByReleaseSystem, setBuildVersionByReleaseSystem] = useState<BuildVersionByReleaseSystem>({
        [ReleaseSystem.Linux]: { buildVersion: "" },
        [ReleaseSystem.MacOsArm]: { buildVersion: "" },
        [ReleaseSystem.MacOsIntel]: { buildVersion: "" },
        [ReleaseSystem.Windows]: { buildVersion: "" },
    });

    useEffect(() => {
        (async function () {
            const response = await ServiceWire.getDownloadService().fetchAvailableSdkBuilds();

            setBuildVersionByReleaseSystem(response);
        })().then();
    }, []);

    return buildVersionByReleaseSystem;
};
