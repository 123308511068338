import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
    container: {
        padding: "18px",
        borderRadius: 14,
        background: "#F8FAFC",
        maxHeight: 400,
        display: "grid",
        gap: "12px",

        "&.expanded": {
            gridTemplateRows: "30px 1fr auto",
        },
    },

    header: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        cursor: "pointer",
    },

    title: {
        fontSize: "20px",
        fontWeight: 600,
    },

    arrowIcon: {
        "&.expanded": {
            transform: "rotate(180deg)",
        },
    },

    categoriesGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(160px, 1fr))",
        columnGap: "52px",
        rowGap: "26px",
        overflow: "auto",
    },

    categoryContainer: {},

    categoryTitle: {
        fontSize: "18px",
        fontWeight: 500,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },

    triggerStatsRow: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "8px",
    },

    triggerStatsRowLeft: {
        display: "flex",
        alignItems: "center",
        gap: "8px",
        overflow: "hidden",
    },

    triggerName: {
        fontSize: "14px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },

    triggerAlertsCount: {
        fontSize: "14px",
        fontWeight: 600,
        textAlign: "right",
    },

    categoryTriggers: {
        display: "grid",
        gap: "12px",
        marginTop: "16px",
        gridTemplateColumns: "100%",
    },

    footer: {
        display: "flex",
        justifyContent: "flex-end",
    },

    showMore: {
        color: "#E7004C",
        cursor: "pointer",
    },
}));
