import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../../CommonStyle";

const deviceDefinitionFormStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: {
            marginTop: theme.spacing(5),
        },

        autocompleteMaxHeight: {
            maxHeight: "20%",
        },

        selectItem: {
            height: theme.spacing(1.5),
            boxSizing: "content-box",
        },
    });

export default deviceDefinitionFormStyle;
