import {
    DashboardRegularState,
    getDefaultDashboardRegularAlertsFilterState,
    getDefaultDashboardRegularState,
} from "../../state/DashboardRegularState";
import ActionType from "../ActionType";
import { SetCategoryViewAction } from "./SetCategoryViewAction";
import { SetIsOnlyUnresolvedAction } from "./SetIsOnlyUnresolvedAction";
import { SetCategoryGraphViewAction } from "./SetCategoryGraphViewAction";
import { SetCategoryTimeRangeAction } from "./SetCategoryTimeRangeAction";
import { ResetAlertsFilterAction } from "./ResetAlertsFilterAction";
import { FetchAlertsStatisticsAction } from "./FetchAlertsStatisticsAction";
import { ErrorFetchingAlertsStatisticsAction } from "./ErrorFetchingAlertsStatisticsAction";
import { FetchedAlertsStatisticsSuccessfullyAction } from "./FetchedAlertsStatisticsSuccessfullyAction";
import { SetAlertsCountFilterAction } from "./SetAlertsCountFilterAction";
import { ToggleDateRangeInAlertsFilterAction } from "./ToggleDateRangeInAlertsFilterAction";
import { ToggleAlertCategoryAction } from "./ToggleAlertCategoryAction";
import { SetAlertTableExpandedAction } from "./SetAlertTableExpandedAction";
import { Status } from "../../state/GlobalTypes";
import {
    GetDashboardOnboardingStateAction,
    GetDashboardOnboardingStateErrorAction,
    GetDashboardOnboardingStateInProgressAction,
    GetDashboardOnboardingStateSuccessfullyAction,
} from "./GetDashboardOnboardingStateAction";
import {
    SetDashboardOnboardingStateAction,
    SetDashboardOnboardingStateErrorAction,
    SetDashboardOnboardingStateInProgressAction,
    SetDashboardOnboardingStateSuccessfullyAction,
} from "./SetDashboardOnboardingStateAction";
import { ToggleAlertsHeatmapInteractionsAction } from "./ToggleAlertsHeatmapInteractionsAction";
import { SetIsOnlyAnomalyInsightsAction } from "./SetIsOnlyAnomalyInsightsAction";
import { SetCategoryTimeRangeCustomAction } from "./SetCategoryTimeRangeCustomAction";

export type DashboardRegularReducerActions =
    | SetCategoryGraphViewAction
    | SetCategoryTimeRangeAction
    | SetCategoryTimeRangeCustomAction
    | SetCategoryViewAction
    | SetIsOnlyUnresolvedAction
    | SetIsOnlyAnomalyInsightsAction
    | ResetAlertsFilterAction
    | SetAlertsCountFilterAction
    | ToggleDateRangeInAlertsFilterAction
    | FetchAlertsStatisticsAction
    | ErrorFetchingAlertsStatisticsAction
    | FetchedAlertsStatisticsSuccessfullyAction
    | ToggleAlertCategoryAction
    | GetDashboardOnboardingStateAction
    | GetDashboardOnboardingStateInProgressAction
    | GetDashboardOnboardingStateSuccessfullyAction
    | GetDashboardOnboardingStateErrorAction
    | SetDashboardOnboardingStateAction
    | SetDashboardOnboardingStateInProgressAction
    | SetDashboardOnboardingStateSuccessfullyAction
    | SetDashboardOnboardingStateErrorAction
    | ToggleAlertsHeatmapInteractionsAction
    | SetAlertTableExpandedAction;

export const dashboardRegularReducer = (
    state: DashboardRegularState = getDefaultDashboardRegularState(),
    action: DashboardRegularReducerActions
): DashboardRegularState => {
    switch (action.type) {
        case ActionType.DashboardRegularSetCategoryGraphView:
            return { ...state, selectedGraphView: action.categoryGraphView };
        case ActionType.DashboardRegularSetCategoryTimeRange:
            return { ...state, categoryTimeRange: action.categoryTimeRange };
        case ActionType.DashboardRegularSetCategoryTimeRangeCustom:
            return {
                ...state,
                categoryTimeRangeCustomFrom: action.categoryTimeRangeCustomFrom,
                categoryTimeRangeCustomTo: action.categoryTimeRangeCustomTo,
            };
        case ActionType.DashboardRegularSetCategoryView:
            return { ...state, categoryView: action.categoryView };
        case ActionType.DashboardRegularSetIsOnlyUnresolved:
            return { ...state, isOnlyUnresolved: action.isOnlyUnresolved };
        case ActionType.DashboardRegularSetIsOnlyAnomalyInsights:
            return { ...state, isOnlyAnomalyInsights: action.isOnlyAnomalyInsights };
        case ActionType.DashboardRegularResetAlertsFilter:
            return { ...state, alertsFilter: getDefaultDashboardRegularAlertsFilterState() };
        case ActionType.DashboardRegularSetAlertsCountFilter:
            return { ...state, alertsCountFilter: action.filter };
        case ActionType.DashboardRegularToggleDateRangeInAlertsFilter:
            const rangeExists = state.alertsFilter.selectedCategories[action.category]?.find(
                (point) => point.from === action.dateRange.from && point.to === action.dateRange.to
            );

            const newState = {
                ...state,
                alertsFilter: {
                    ...state.alertsFilter,
                    selectedCategories: {
                        ...state.alertsFilter.selectedCategories,
                        [action.category]: rangeExists
                            ? state.alertsFilter.selectedCategories[action.category].filter((range) => {
                                  return !(range.from === action.dateRange.from && range.to === action.dateRange.to);
                              })
                            : (state.alertsFilter.selectedCategories[action.category] || []).concat(action.dateRange),
                    },
                },
            };

            if (newState.alertsFilter.selectedCategories[action.category].length === 0) {
                delete newState.alertsFilter.selectedCategories[action.category];
            }

            return newState;

        case ActionType.DashboardRegularToggleAlertsHeatmapInteractions:
            return { ...state, alertsHeatmapInteractionsEnabled: action.enabled };

        case ActionType.FetchAlertsStatistics:
            return { ...state, alertsStatisticsLoading: true };
        case ActionType.FetchedAlertsStatisticsSuccessfully:
            return {
                ...state,
                alertsStatistics: action.statistics,
                alertsStatisticsLoading: false,
                alertsStatisticsRequestError: null,
            };
        case ActionType.ErrorFetchingAlertsStatistics:
            return {
                ...state,
                alertsStatistics: null,
                alertsStatisticsLoading: false,
                alertsStatisticsRequestError: action.error,
            };
        case ActionType.DashboardRegularToggleAlertCategory: {
            let selectedAlertCategories = [];
            const idx = state.selectedAlertCategories.indexOf(action.category);

            if (idx > -1) {
                selectedAlertCategories = [...state.selectedAlertCategories];
                selectedAlertCategories.splice(idx, 1);
            } else {
                selectedAlertCategories = [...state.selectedAlertCategories, action.category];
            }

            return {
                ...state,
                selectedAlertCategories,
            };
        }
        case ActionType.DashboardRegularGetOnboardingState:
            return state;
        case ActionType.DashboardRegularGetOnboardingStateInProgress:
            return {
                ...state,
                onboardingState: {
                    ...state.onboardingState,
                    statusGet: Status.InProgress,
                },
            };
        case ActionType.DashboardRegularGetOnboardingStateSuccessfully:
            return {
                ...state,
                onboardingState: {
                    ...state.onboardingState,
                    statusGet: Status.Success,
                    isVisible: action.isVisible,
                    stepsState: action.stepsState,
                },
            };
        case ActionType.DashboardRegularGetOnboardingStateError:
            return {
                ...state,
                onboardingState: {
                    ...state.onboardingState,
                    statusGet: Status.Error,
                },
            };
        case ActionType.DashboardRegularSetOnboardingState:
            return state;
        case ActionType.DashboardRegularSetOnboardingStateInProgress:
            return {
                ...state,
                onboardingState: {
                    ...state.onboardingState,
                    statusGet: Status.InProgress,
                },
            };
        case ActionType.DashboardRegularSetOnboardingStateSuccessfully:
            return {
                ...state,
                onboardingState: {
                    ...state.onboardingState,
                    statusSet: Status.Success,
                    isVisible: action.isVisible,
                    stepsState: { ...state.onboardingState.stepsState, ...action.stepsState },
                },
            };
        case ActionType.DashboardRegularSetOnboardingStateError:
            return {
                ...state,
                onboardingState: {
                    ...state.onboardingState,
                    statusSet: Status.Error,
                },
            };
        case ActionType.DashboardRegulatSetAlertTableExpanded:
            return {
                ...state,
                isAlertTableExpanded: action.expanded,
            };
    }

    return state;
};
