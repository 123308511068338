import EntityBase from "./EntityBase";
import EntityType from "./EntityType";

/**
 * Represents an API key in sternum.
 */
class APIKeyInfo extends EntityBase {
    public displayName: string;
    public secret: string;

    /**
     * Constructor.
     */
    constructor(
        public apiKeyId: string,
        public created: number,
        public updated: number,
        displayName: string,
        secret?: string
    ) {
        super(apiKeyId, created, updated, EntityType.APIKey);
        this.displayName = displayName;
        this.secret = secret;
    }

    /**
     * Converts json to issue info object.
     */
    public static fromJsonObject(jsonObject: Object) {
        return new APIKeyInfo(
            jsonObject["entity_id"],
            jsonObject["created"],
            jsonObject["updated"],
            jsonObject["display_name"],
            jsonObject["api_key"]
        );
    }
}

export default APIKeyInfo;
