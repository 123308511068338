import { makeStyles } from "@material-ui/core";

export const useKernelModulesStyles = makeStyles((theme) => ({
    kernelModulesContainer: {
        display: "grid",
        gridTemplateRows: "max-content 1fr",
        margin: "0 18px",
        maxHeight: 600,
        overflow: "hidden",
        paddingTop: 5,

        "& table tbody tr": {
            "& td:nth-child(1)": {
                padding: "12px 24px 12px 0",
            },
        },

        "& table thead tr": {
            "& th": {
                fontSize: 14,
                fontWeight: 600,
                color: "#555B61",
            },

            "& th:nth-child(1)": {
                width: 200,
                padding: "12px 24px 12px 0",
            },
        },
    },

    tableWrapper: {
        maxHeight: "100%",
        padding: "0 5px",
    },

    rowCell: {
        fontSize: 16,
        fontWeight: 400,
        color: "#999999",
        maxWidth: 300,
        whiteSpace: "nowrap",
    },
}));
