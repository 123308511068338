import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

const cveViewStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),
        root: {
            height: "100%",
            display: "flex",
            flexDirection: "column",
        },

        cveViewUpperContent: {
            paddingTop: theme.spacing(10),
            paddingRight: theme.spacing(5),
            paddingBottom: theme.spacing(5),
            paddingLeft: theme.spacing(5),
        },
        title: {
            fontSize: 20,
            fontWeight: 600,
            marginRight: theme.spacing(2),
        },

        tabsContainer: {
            padding: theme.spacing(0, 5),
        },

        tabContent: {
            height: "calc(100% - 150px)",
            background: "#fff",
        },

        tableContainer: {
            height: "100%",
            padding: theme.spacing(4),
            width: "auto",
            overflow: "auto",
            maxHeight: "100%",
        },

        tableInner: {
            height: "100%",
            background: "#fff",
        },

        securityAlertsTabContent: {
            paddingTop: theme.spacing(2),
        },
        infoTabContent: {
            padding: theme.spacing(4),
        },
        devicesListPaper: {
            "&.mod-regular": {
                maxHeight: theme.spacing(68),
            },
            "&.mod-fullscreen": {
                maxHeight: "100vh",
            },
        },
    });

export default cveViewStyle;
