import { Typography } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import TableToolbarDisplayState from "../../../../lib/state/TableToolbarDisplayState";
import {
    ArrowLeftIcon,
    ArrowRightIcon,
    DoubleArrowsLeftIcon,
    DoubleArrowsRightIcon,
} from "../../SternumIcon/SternumIcon";
import sternumTablePaginationStyle from "./SternumTablePaginationStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    currentPageNumber: number;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof sternumTablePaginationStyle> {
    // Changeable props (will cause a re-render).
    disabled: boolean;
    totalItemCount: number;
    dynamicPageSize: number;
    pageNumber?: number;

    // Non-changeable props (will not cause a re-render).
    pageSize: number;
    onPageChanged: (pageNumber: number) => void;
    toolbarState: TableToolbarDisplayState;
}

/**
 * Displays a sternum-styled table header.
 */
class SternumTablePagination extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            currentPageNumber: 1,
        };
    }

    /**
     * Returns whether anything changed in props need to execute a render.
     */
    shouldComponentUpdate(nextProps: Readonly<AppProps>, nextState: Readonly<AppState>, nextContext: any): boolean {
        // Note: the order of comparison is performance-wise. From the most costly comparison to the most cheap one.

        if (
            nextProps.disabled !== this.props.disabled ||
            nextProps.totalItemCount !== this.props.totalItemCount ||
            nextProps.dynamicPageSize !== this.props.dynamicPageSize
        ) {
            return true;
        }

        const currentPageNumber = this.props.pageNumber ?? this.state.currentPageNumber;
        const nextPageSize = nextProps.pageNumber ?? nextState.currentPageNumber;

        if (nextPageSize !== currentPageNumber) {
            return true;
        }

        return false;
    }

    /**
     * Calculate paginator counters if table is dynamic
     */
    private getPaginatorStatus = () => {
        const currentPageNumber = this.props.pageNumber ?? this.state.currentPageNumber;

        // calculate first number ( x - 15 out of 500)
        const startPage = Math.min(this.props.totalItemCount, (currentPageNumber - 1) * this.props.pageSize + 1);
        // calculate second number (60 - x out of 500)
        let endPage;
        if (!this.props.toolbarState.hidePagination) {
            if (this.props.pageSize * currentPageNumber < this.props.totalItemCount) {
                if (this.props.dynamicPageSize < this.props.pageSize) {
                    endPage = startPage + this.props.dynamicPageSize;
                } else {
                    endPage = Math.min(currentPageNumber * this.props.pageSize, this.props.totalItemCount);
                }
            } else {
                endPage = this.props.totalItemCount;
            }
        } // Show how many items in table
        else {
            endPage = this.props.dynamicPageSize;
        }

        // const total items
        const total = this.props.totalItemCount;
        return `${startPage} - ${endPage} out of ${total}`;
    };

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;
        const currentPageNumber = this.props.pageNumber ?? this.state.currentPageNumber;

        let isRoundPaginationDivision =
            Math.floor(this.props.totalItemCount / this.props.pageSize) ===
            this.props.totalItemCount / this.props.pageSize;

        let isCurrentPageLastPage = isRoundPaginationDivision
            ? currentPageNumber === this.props.totalItemCount / this.props.pageSize
            : currentPageNumber === Math.floor(this.props.totalItemCount / this.props.pageSize) + 1;

        let lastPage = isRoundPaginationDivision
            ? this.props.totalItemCount / this.props.pageSize
            : Math.floor(this.props.totalItemCount / this.props.pageSize) + 1;

        return (
            <div className={classNames(classes.flexVMiddle, classes.flexNoShrink)}>
                {/* Pagination summary */}

                {!this.props.toolbarState.hidePagination && (
                    <>
                        {/* Go to first page button */}
                        <IconButton
                            disabled={this.props.disabled || currentPageNumber === 1}
                            onClick={(event) => this.handleChangePage(1)}
                            className={classes.arrowIcon}
                        >
                            <DoubleArrowsLeftIcon />
                        </IconButton>

                        {/* Previous page button */}
                        <IconButton
                            disabled={this.props.disabled || currentPageNumber === 1}
                            onClick={(event) => this.handleChangePage(currentPageNumber - 1)}
                            className={classes.arrowIcon}
                        >
                            <ArrowLeftIcon />
                        </IconButton>
                    </>
                )}

                <Typography className={classNames(classes.resultsFromAndToTitle)}>
                    {this.props.totalItemCount === null ? (
                        <span className={classNames(classes.loadingPlaceholder, "mod-loading-column")} />
                    ) : (
                        this.getPaginatorStatus()
                    )}
                </Typography>

                {!this.props.toolbarState.hidePagination && (
                    <>
                        {/* Next page button */}
                        <IconButton
                            disabled={
                                this.props.disabled ||
                                currentPageNumber * this.props.pageSize >= this.props.totalItemCount
                            }
                            onClick={(event) => this.handleChangePage(currentPageNumber + 1)}
                            className={classes.arrowIcon}
                        >
                            <ArrowRightIcon />
                        </IconButton>

                        {/* Go to last page button */}
                        <IconButton
                            disabled={this.props.disabled || isCurrentPageLastPage || this.props.totalItemCount == 0}
                            onClick={(event) => this.handleChangePage(lastPage)}
                            className={classes.arrowIcon}
                        >
                            <DoubleArrowsRightIcon />
                        </IconButton>
                    </>
                )}
            </div>
        );
    }

    /**
     * Occurs once the user goes to another page.
     */
    private handleChangePage(pageNumber) {
        if (this.props.pageNumber === undefined) {
            this.setState({ currentPageNumber: pageNumber });
        }

        this.props.onPageChanged?.(pageNumber);
    }
}

export default withStyles(sternumTablePaginationStyle)(SternumTablePagination);
