import React from "react";
import classNames from "classnames";

import { useSideBarFrameStyle } from "./SideBarItemFrame.style";
import { SideBarFrameItem } from "./SideBarFrameItem";
import { SideBarFrameItemButton } from "./SideBarFrameItemButton";

export interface SideBarFrameProps {
    className?: string;
    children?: React.ReactNode;
}

export function SideBarFrame({ className, children, ...props }: SideBarFrameProps) {
    const classes = useSideBarFrameStyle();

    return (
        <div className={classNames(classes.sideBarFrame, className)} {...props}>
            {children}
        </div>
    );
}

SideBarFrame.Item = SideBarFrameItem;
SideBarFrame.ItemButton = SideBarFrameItemButton;
