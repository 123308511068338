import React from "react";
import classNames from "classnames";

import { useResolvingReasonsStyle } from "./ResolvingReasons.style";
import { CheckFilledIcon } from "../../SUI/SternumIcon";

export interface ResolvingReasonsItemProps extends React.ComponentProps<"div"> {
    isSelected?: boolean;
}

export function ResolvingReasonsItem({
    className,
    isSelected,
    onClick,
    children,
    ...props
}: ResolvingReasonsItemProps) {
    const classes = useResolvingReasonsStyle();

    return (
        <div
            role="presentation"
            aria-label="resolving reason item"
            className={classNames(className, classes.resolvingReasonsItem)}
            aria-selected={isSelected}
            data-on-click={!!onClick}
            onClick={onClick}
            {...props}
        >
            {children}
            <CheckFilledIcon color={null} className={classes.checkIcon} />
        </div>
    );
}
