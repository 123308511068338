import { Paper } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { compare, validate } from "compare-versions";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import HashSet from "../../lib/infra/HashSet";
import SternumConfiguration from "../../lib/infra/SternumConfiguration";
import { openUsedLibraryViewModalAction } from "../../lib/redux/modals/OpenModalAction";
import DebounceService from "../../lib/services/DebounceService";
import ServiceWire from "../../lib/services/ServiceWire";
import DeviceDefinitionInfo from "../../lib/state/DeviceDefinitionInfo";
import DeviceDefinitionLibrariesFilter from "../../lib/state/DeviceDefinitionLibrariesFilter";
import DeviceDefinitionLibraryInfo from "../../lib/state/DeviceDefinitionLibraryInfo";
import { GlobalState } from "../../lib/state/GlobalState";
import ListFilter from "../../lib/state/ListFilter";
import TableColumnHeaderInfo from "../../lib/state/TableColumnHeaderInfo";
import TableToolbarDisplayState from "../../lib/state/TableToolbarDisplayState";
import UsedLibraryInfo from "../../lib/state/UsedLibraryInfo";
import DeviceDefinitionLibrariesListFilterPopover from "../DeviceDefinitionLibrariesListPopover/DeviceDefinitionLibrariesListFilterPopover";
import { ExternalLinkIcon } from "../SUI/SternumIcon/SternumIcon";
import SternumTable from "../SUI/SternumTable/SternumTable";
import deviceDefinitionLibrariesListStyle from "./DeviceDefinitionLibrariesListStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    // Loading & error state.
    loadingDeviceDefinitionLibraries: boolean;
    errorLoadingDeviceDefinitionLibraries: boolean;

    // Device definition libraries.
    deviceDefinitionLibraries: DeviceDefinitionLibraryInfo[];
    totalItemCount: number;

    // Filtering.
    deviceDefinitionLibrariesFilter: DeviceDefinitionLibrariesFilter;
    searchText: string;
    orderByField: string;
    order: "asc" | "desc";
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof deviceDefinitionLibrariesListStyle> {
    deviceDefinitionId?: string;
    deviceDefinition?: DeviceDefinitionInfo;
    usedLibraryId?: string;
    usedLibrary?: UsedLibraryInfo;

    deviceDefinitionLibrariesFilter?: DeviceDefinitionLibrariesFilter;
    includeColumns: string[];

    hideToolbar?: boolean;
    hidePagination?: boolean;
    hideUpperBorder?: boolean;

    doNotIncludeFilter?: boolean;
    toolbarState: TableToolbarDisplayState;

    viewedColumnsSet?: HashSet;
    displayXButtonInUsedLibraryView?: boolean;
    displayBackButtonInUsedLibraryView?: boolean;

    includedActionsSet?: HashSet;

    location;

    paperClassNames?;

    isUsedLibraryOutOfDate: boolean;

    openUsedLibraryViewModal?: (
        key: string,
        usedLibraryId: string,
        displayXButton: boolean,
        displayBackButton: boolean
    ) => void;

    showAllColumns?: boolean;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {
        openUsedLibraryViewModal: (key, usedLibraryId, displayXButton, displayBackButton) =>
            dispatch(openUsedLibraryViewModalAction(key, usedLibraryId, displayXButton, displayBackButton)),
    };
};

/**
 * Holds the device definition libraries list in the app.
 */
class DeviceDefinitionLibrariesList extends React.Component<AppProps, AppState> {
    /**
     * Defines the column headers participating in the device definition libraries table.
     */
    private columnHeaders: TableColumnHeaderInfo[] = [
        new TableColumnHeaderInfo("deviceDefinitionId", "Device Definition ID", false, true, false),
        new TableColumnHeaderInfo("libraryName", "Name", false, true, false),
        new TableColumnHeaderInfo("deviceDefinitionLibraryVersion", "Current Version", false, true, false),
        new TableColumnHeaderInfo("libraryVersion", "Latest Version", false, true, false),
        new TableColumnHeaderInfo("actions", "Actions", false, true, false),
    ];

    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        if (this.props.includeColumns) {
            if (this.props.includeColumns.length) {
                let includeColumnsSet = HashSet.fromValues(this.props.includeColumns);
                this.columnHeaders = this.columnHeaders.filter((columnHeader) =>
                    includeColumnsSet.exists(columnHeader.id)
                );
            } else {
                this.columnHeaders = [];
            }
        }

        if (this.props.showAllColumns) {
            for (let i = 0; i < this.columnHeaders.length; i++) {
                this.columnHeaders[i].isHidden = false;
            }
        }

        // Initializing the state to default.
        this.state = {
            loadingDeviceDefinitionLibraries: false,
            errorLoadingDeviceDefinitionLibraries: false,

            deviceDefinitionLibraries: [],

            deviceDefinitionLibrariesFilter: this.props.deviceDefinitionLibrariesFilter
                ? this.props.deviceDefinitionLibrariesFilter
                : new DeviceDefinitionLibrariesFilter(this.props.deviceDefinitionId, null),
            searchText: null,
            orderByField: null,
            order: "asc",

            totalItemCount: null,
        };
    }

    /**
     * Occurs once the component finished its initialization process.
     */
    async componentDidMount() {
        await this.loadDeviceDefinitionLibraries(1);
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        return (
            <SternumTable
                hideUpperBorder={this.props.hideUpperBorder}
                hideToolbar={this.props.hideToolbar}
                toolbarState={this.props.toolbarState}
                hidePagination={this.props.hidePagination}
                pageSize={SternumConfiguration.getPageSize()}
                amountOfLoadingPlaceholders={3}
                totalItemCount={this.state.totalItemCount}
                onRefreshClicked={() => this.onRefreshClicked()}
                loadingItems={this.state.loadingDeviceDefinitionLibraries}
                errorLoadingItems={this.state.errorLoadingDeviceDefinitionLibraries}
                columnHeaders={this.columnHeaders}
                viewedColumnsSet={this.props.viewedColumnsSet}
                listFilter={this.state.deviceDefinitionLibrariesFilter}
                isFilterActive={
                    this.state.deviceDefinitionLibrariesFilter && this.state.deviceDefinitionLibrariesFilter.isActive()
                }
                filterComponentProvider={(onFilterChanged) => (
                    <DeviceDefinitionLibrariesListFilterPopover
                        deviceDefinitionLibrariesFilter={this.state.deviceDefinitionLibrariesFilter}
                        onFilterChanged={onFilterChanged}
                    />
                )}
                orderByField={this.state.orderByField}
                order={this.state.order}
                rows={this.state.deviceDefinitionLibraries}
                onFilterChanged={(listFilter) => this.handleDeviceDefinitionLibrariesFilterChanged(listFilter)}
                onSearchTextChanged={(searchText) => this.handleSearchTextChanged(searchText)}
                onPageChanged={(pageNumber) => this.loadDeviceDefinitionLibraries(pageNumber)}
                onOrderChanged={(orderByField, order) => this.handleOrderChanged(orderByField, order)}
                getRowValues={(row) => this.getRowDisplayValues(row)}
                getRowClasses={(row) => this.getRowClasses(row)}
            />
        );
    }

    /**
     * Occurs once the user refreshes the list.
     */
    private async onRefreshClicked() {
        await this.loadDeviceDefinitionLibraries(1);
    }

    /**
     * Gets the display values for row's columns (where they aren't the default).
     */
    private getRowDisplayValues(row) {
        let deviceDefinitionLibraryInfo = row as DeviceDefinitionLibraryInfo;

        const { classes } = this.props;
        let isUsedLibraryOutOfDate = false;
        if (deviceDefinitionLibraryInfo.usedLibrary.library.latestKnownVersion) {
            isUsedLibraryOutOfDate =
                validate(deviceDefinitionLibraryInfo.usedLibrary.library.latestKnownVersion) &&
                validate(deviceDefinitionLibraryInfo.usedLibrary.version)
                    ? compare(
                          deviceDefinitionLibraryInfo.usedLibrary.library.latestKnownVersion,
                          deviceDefinitionLibraryInfo.usedLibrary.version,
                          ">"
                      )
                    : deviceDefinitionLibraryInfo.usedLibrary.library.latestKnownVersion >
                      deviceDefinitionLibraryInfo.usedLibrary.version;
        }

        return {
            deviceDefinitionId: deviceDefinitionLibraryInfo.deviceDefinitionId,
            libraryName: deviceDefinitionLibraryInfo.usedLibrary.library.name,
            libraryVersion: (
                <Typography variant="body2" className={classes.versionText}>
                    {deviceDefinitionLibraryInfo.usedLibrary.library.latestKnownVersion || ""}
                </Typography>
            ),
            deviceDefinitionLibraryVersion: (
                <Typography
                    variant="body2"
                    className={classNames(
                        classes.versionText,
                        isUsedLibraryOutOfDate ? classes.deviceHashOutOfDate : classes.deviceHashIsAccurate
                    )}
                >
                    {deviceDefinitionLibraryInfo.usedLibrary.version}
                </Typography>
            ),
            actions: (
                <div className={classes.flexVMiddle}>
                    {/* Details */}
                    {(!this.props.includedActionsSet || this.props.includedActionsSet.exists("details")) && (
                        <div
                            className={classNames(classes.flexVMiddle, classes.actionBlock, classes.marginRightMd)}
                            onClick={() => this.onDetailsActionClicked(deviceDefinitionLibraryInfo)}
                        >
                            <Typography className={classes.actionLinkText}>Library Info</Typography>

                            <ExternalLinkIcon width={20} height={20} />
                        </div>
                    )}
                </div>
            ),
        };
    }

    /**
     * Occurs on a click on a row.
     */
    private onDetailsActionClicked(deviceDefinitionLibraryInfo: DeviceDefinitionLibraryInfo) {
        // Opens the used library view modal.
        this.props.openUsedLibraryViewModal(
            deviceDefinitionLibraryInfo.usedLibraryId,
            deviceDefinitionLibraryInfo.usedLibraryId,
            this.props.displayXButtonInUsedLibraryView,
            this.props.displayBackButtonInUsedLibraryView
        );
    }

    /**
     * Occurs once the filters of the list are changed.
     */
    private handleDeviceDefinitionLibrariesFilterChanged(deviceDefinitionLibrariesFilter: ListFilter) {
        this.setState(
            {
                deviceDefinitionLibrariesFilter:
                    deviceDefinitionLibrariesFilter != null
                        ? (deviceDefinitionLibrariesFilter as DeviceDefinitionLibrariesFilter)
                        : null,
            },
            async () => {
                if (deviceDefinitionLibrariesFilter && deviceDefinitionLibrariesFilter.isActive()) {
                    await this.loadDeviceDefinitionLibraries(1);
                }
            }
        );
    }

    /**
     * Occurs once search text is changed.
     */
    private handleSearchTextChanged(searchText) {
        // Debounce request to search device definition libraries.
        DebounceService.debounce(
            "getDeviceDefinitionLibraries",
            () => {
                this.setState(
                    {
                        searchText: searchText,
                    },
                    () => this.loadDeviceDefinitionLibraries(1)
                );
            },
            350
        );
    }

    /**
     * Occurs once sort order is changed.
     */
    private handleOrderChanged(orderByField, order) {
        this.setState(
            {
                orderByField: orderByField,
                order: order,
            },
            () => this.loadDeviceDefinitionLibraries(1)
        );
    }

    /**
     * Loads device definition libraries.
     */
    private async loadDeviceDefinitionLibraries(pageNumber) {
        try {
            // Setting loading to true.
            this.setState({
                loadingDeviceDefinitionLibraries: true,
                errorLoadingDeviceDefinitionLibraries: false,
            });
            // Fetching device definition libraries.
            let deviceDefinitionLibraries = await ServiceWire.getSternumService().getDeviceDefinitionLibraries(
                ServiceWire.getClientsService().getSelectedClientId(),
                this.state.deviceDefinitionLibrariesFilter,
                this.state.searchText,
                this.state.orderByField,
                this.state.order,
                (pageNumber - 1) * SternumConfiguration.getPageSize(),
                SternumConfiguration.getPageSize()
            );

            this.setState({
                loadingDeviceDefinitionLibraries: false,
                errorLoadingDeviceDefinitionLibraries: false,
                deviceDefinitionLibraries: deviceDefinitionLibraries,
                totalItemCount: deviceDefinitionLibraries.length,
            });
        } catch (error) {
            this.setState({
                loadingDeviceDefinitionLibraries: false,
                errorLoadingDeviceDefinitionLibraries: true,
            });
        }
    }

    /**
     * Gets the classes defined for row.
     */
    private getRowClasses(row) {
        const { classes } = this.props;

        return {
            hover: classes.rowHover,
        };
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(deviceDefinitionLibrariesListStyle)(DeviceDefinitionLibrariesList)));
