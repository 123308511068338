import React, { useState } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { History } from "history";

import { GlobalState } from "../../../lib/state/GlobalState";
import { useCommonStyle } from "../../CommonStyle";
import { CrossIcon, MenuIcon, SternumLongLogoOneColorIcon } from "../../SUI/SternumIcon";
import { Button } from "../../SUI/Button";
import { useHomePageStyle } from "../Home.style";
import { useNavigationBarStyle } from "./NavigationBar.style";

export interface NavigationBarProps {}

const mapStateToProps = (state: GlobalState, ownProps: NavigationBarProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

type NavigationBarPropsWithHOC = NavigationBarProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
        history: History;
    };

function NavigationBarComponent({ history }: NavigationBarPropsWithHOC) {
    const classes = useNavigationBarStyle();
    const classesHome = useHomePageStyle();
    const classesCommon = useCommonStyle();

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const renderActionButtons = (additionalClassName?: string) => (
        <div className={classNames(classes.buttonContainer, additionalClassName)}>
            <Button
                variant="whiteTextWithoutBorder"
                size="size16"
                className={classes.linkButton}
                onClick={() => history.push("/login")}
            >
                Log In
            </Button>
            <Button onClick={() => history.push("/signup")}>Get Started</Button>
        </div>
    );

    return (
        <div className={classes.mainTitleContainer}>
            <div
                className={classNames(
                    classes.navigationContainer,
                    classesHome.mainContainer,
                    classesCommon.flexSpaceBetween,
                    classesCommon.flexVMiddle
                )}
            >
                <SternumLongLogoOneColorIcon width={143} height={24} color="white" />
                {renderActionButtons(classes.bigNavigationButtonContainer)}
                <div className={classes.menuIconContainer}>
                    {!isMenuOpen && (
                        <MenuIcon
                            className={classes.iconButton}
                            width={24}
                            height={24}
                            onClick={() => setIsMenuOpen(true)}
                        />
                    )}
                    {isMenuOpen && (
                        <CrossIcon
                            className={classes.iconButton}
                            width={24}
                            height={24}
                            onClick={() => setIsMenuOpen(false)}
                        />
                    )}
                </div>
            </div>
            <div className={classNames(classes.smallMenuContainer, isMenuOpen && classes.smallMenuContainerOpened)}>
                {renderActionButtons()}
            </div>
        </div>
    );
}

export const NavigationBar: React.FC<NavigationBarProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(NavigationBarComponent));
