import React, { useState } from "react";

import { RemoteViewAccordion } from "./RemoteViewAccordion";

import { InfoCircleIcon } from "../../../SUI/SternumIcon";

import DeviceInfo from "../../../../lib/state/DeviceInfo";
import DeviceDefinitionInfo from "../../../../lib/state/DeviceDefinitionInfo";

import { KernelLogs } from "./KernelLogs";
import { KernelModules } from "./KernelModules";
import { ProcessExplorer } from "./ProcessExplorer";

import { useRemoteViewTabStyles } from "./RemoteViewTab.styles";
import { Box, Tooltip } from "@material-ui/core";

export interface RemoteViewTabProps {
    device: DeviceInfo;
    deviceDefinition: DeviceDefinitionInfo;
    startDate: Date;
    endDate: Date;
}

export function RemoteViewTab({ device, deviceDefinition, startDate, endDate }: RemoteViewTabProps) {
    const classes = useRemoteViewTabStyles();

    const [isKernelLogsExpanded, setIsKernelLogsExpanded] = useState(false);
    const [isKernelModulesExpanded, setIsKernelModulesExpanded] = useState(false);
    const [isProcessExplorerExpanded, setIsProcessExplorerExpanded] = useState(false);

    return (
        <div className={classes.container}>
            <RemoteViewAccordion
                ariaLabel="kernel logs accordion"
                isExpanded={isKernelLogsExpanded}
                onChange={setIsKernelLogsExpanded}
                header={
                    <div className={classes.header}>
                        Kernel Logs
                        <Tooltip
                            classes={{ tooltip: classes.infoTooltip }}
                            title={
                                <div>
                                    Records generated by the operating system's kernel to provide insights into the
                                    system's behavior, mainly boot-up messages, driver information and hardware events
                                    and status.
                                </div>
                            }
                        >
                            <Box display="inline-flex">
                                <InfoCircleIcon className={classes.infoIcon} />
                            </Box>
                        </Tooltip>
                    </div>
                }
            >
                <KernelLogs deviceId={device.entityId} startDate={startDate} endDate={endDate} />
            </RemoteViewAccordion>
            <RemoteViewAccordion
                ariaLabel="kernel modules accordion"
                isExpanded={isKernelModulesExpanded}
                onChange={setIsKernelModulesExpanded}
                header={
                    <div className={classes.header}>
                        Kernel Modules
                        <Tooltip
                            classes={{ tooltip: classes.infoTooltip }}
                            title={
                                <div>
                                    Object files that contains code that can extend the kernel functionality at runtime
                                    by being dynamically loaded.
                                </div>
                            }
                        >
                            <Box display="inline-flex">
                                <InfoCircleIcon className={classes.infoIcon} />
                            </Box>
                        </Tooltip>
                    </div>
                }
            >
                <KernelModules deviceId={device.entityId} startDate={startDate} endDate={endDate} />
            </RemoteViewAccordion>
            <RemoteViewAccordion
                ariaLabel="process explorer accordion"
                isExpanded={isProcessExplorerExpanded}
                onChange={setIsProcessExplorerExpanded}
                header={
                    <div className={classes.header}>
                        Process Explorer
                        <Tooltip
                            classes={{ tooltip: classes.infoTooltip }}
                            title={
                                <div>Explore the processes by investigating the traces under each of the process</div>
                            }
                        >
                            <Box display="inline-flex">
                                <InfoCircleIcon className={classes.infoIcon} />
                            </Box>
                        </Tooltip>
                    </div>
                }
            >
                <ProcessExplorer
                    deviceId={device.entityId}
                    device={device}
                    deviceDefinition={deviceDefinition}
                    startDate={startDate}
                    endDate={endDate}
                />
            </RemoteViewAccordion>
        </div>
    );
}
