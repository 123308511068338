import { Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { closeModalAction } from "../../../lib/redux/modals/CloseModalAction";
import ServiceWire from "../../../lib/services/ServiceWire";
import { GlobalState } from "../../../lib/state/GlobalState";
import ModalKey from "../../../lib/state/ModalKey";
import ModalType from "../../../lib/state/ModalType";
import VisualisationConfiguration from "../../../lib/state/Visualisation/VisualisationConfiguration";
import VisualisationDataSourceGroupBy from "../../../lib/state/Visualisation/VisualisationConfigurationGroupBy";
import VisualisationType from "../../../lib/state/Visualisation/VisualisationType";
import SternumImprovedButton from "../../SUI/SternumImprovedButton/SternumImprovedButton";
import UIDataVisualisationConfiguration from "../entities/UIDataVisualisationConfiguration";
import getVisualisationDataSource from "../utils/getVisualisationDataSource";
import { isValidDataSourceConfigurations } from "../utils/isValidDataSourceConfigurations";
import visualisationConfigurationFooterStyle from "./VisualisationConfigurationFooterStyle";

const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeModal: (modalKey) => dispatch(closeModalAction(modalKey)),
    };
};

interface AppState {
    savingVisualisation: boolean;
    errorSavingVisualisation: boolean;
    deletingVisualisation: boolean;
    errorDeletingVisualisation: boolean;
}

export interface AppProps extends WithStyles<typeof visualisationConfigurationFooterStyle> {
    visualisationType: VisualisationType;
    dataVisualisationConfigurations: UIDataVisualisationConfiguration[];
    groupBy: VisualisationDataSourceGroupBy;
    visualisationId?: string;
    loadingVisualisation: boolean;
    deviceDefinitionId?: string;
    displayName: string;
    isStacked: boolean;
    isLogarithmicScale: boolean;
    dashboardId?: string;
    closeModal?: (modalKey: ModalKey) => void;
    onVisualisationCreated?: (visualisationId: string, displayName: string) => void;
    onVisualisationUpdated?: (visualisationId: string, displayName: string) => void;
}

class VisualisationConfigurationFooter extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            savingVisualisation: false,
            errorSavingVisualisation: false,
            deletingVisualisation: false,
            errorDeletingVisualisation: false,
        };
    }

    render() {
        const { classes } = this.props;

        const validConfigurations: boolean = isValidDataSourceConfigurations(
            this.props.dataVisualisationConfigurations
        );

        const disabledSavedButton =
            this.props.loadingVisualisation || !validConfigurations || this.state.savingVisualisation;

        return (
            <div className={classNames(classes.footer)}>
                {(this.state.errorSavingVisualisation || this.state.errorDeletingVisualisation) && (
                    <Typography className={classNames(classes.errorSavingVisualisation)}>
                        Error saving visualisation...
                    </Typography>
                )}

                {((this.state.savingVisualisation && !this.state.errorSavingVisualisation) ||
                    (this.state.deletingVisualisation && !this.state.errorDeletingVisualisation)) && (
                    <div className={classNames(classes.savingVisualisationLoading)}>
                        <CircularProgress size={20} />
                    </div>
                )}

                <div className={classNames(classes.cancelButtonContainer)}>
                    <SternumImprovedButton
                        buttonType="gray"
                        fullWidth={false}
                        onClick={() =>
                            this.props.closeModal(
                                new ModalKey(ModalType.VisualizationCreation, "VisualizationCreation")
                            )
                        }
                        content={this.props.dashboardId ? "Cancel" : "Close"}
                    />
                </div>

                {this.props.dashboardId && (
                    <SternumImprovedButton
                        fullWidth={false}
                        content="Save"
                        isDisabled={disabledSavedButton}
                        onClick={() => this.saveVisualisation()}
                    />
                )}
            </div>
        );
    }

    /**
     * Saves current visualisation.
     */
    private async saveVisualisation() {
        try {
            this.setState({
                savingVisualisation: true,
                errorSavingVisualisation: false,
            });

            if (this.props.visualisationId) {
                // Updating existing visualisation.
                await ServiceWire.getVisualisationApiService().updateVisualisation(
                    this.props.visualisationId,
                    this.props.displayName,
                    this.getVisualisationConfiguration(),
                    this.props.isStacked,
                    this.props.isLogarithmicScale
                );

                if (this.props.onVisualisationUpdated) {
                    this.props.onVisualisationUpdated(this.props.visualisationId, this.props.displayName);
                }
            } else {
                // Creating new visualisation.

                let createdVisualisationId: string;
                if (this.props.dashboardId) {
                    createdVisualisationId = await ServiceWire.getVisualisationApiService().createVisualisation(
                        this.props.dashboardId,
                        this.props.displayName,
                        this.getVisualisationConfiguration(),
                        this.props.isStacked,
                        this.props.isLogarithmicScale
                    );
                } else {
                    createdVisualisationId = await ServiceWire.getVisualisationApiService().createVisualisation(
                        this.props.deviceDefinitionId,
                        this.props.displayName,
                        this.getVisualisationConfiguration(),
                        this.props.isStacked,
                        this.props.isLogarithmicScale
                    );
                }

                if (this.props.onVisualisationCreated) {
                    this.props.onVisualisationCreated(createdVisualisationId, this.props.displayName);
                }
            }

            this.setState({
                savingVisualisation: false,
                errorSavingVisualisation: false,
            });

            this.props.closeModal(new ModalKey(ModalType.VisualizationCreation, "VisualizationCreation"));
        } catch {
            this.setState({
                savingVisualisation: false,
                errorSavingVisualisation: true,
            });
        }
    }

    /**
     * Deletes current visualisation.
     */
    private async deleteVisualisation() {
        try {
            this.setState({
                deletingVisualisation: true,
                errorDeletingVisualisation: false,
            });

            await ServiceWire.getVisualisationApiService().deleteVisualisation(this.props.visualisationId);

            this.setState({
                deletingVisualisation: false,
                errorDeletingVisualisation: false,
            });

            this.props.closeModal(new ModalKey(ModalType.VisualizationCreation, "VisualizationCreation"));
        } catch {
            this.setState({
                deletingVisualisation: false,
                errorDeletingVisualisation: true,
            });
        }
    }

    private getVisualisationConfiguration(): VisualisationConfiguration {
        return {
            visualisationType: this.props.visualisationType,
            dataSources: this.props.dataVisualisationConfigurations.map((dataVisualisationConfiguration) => {
                const newDataVisConfig: UIDataVisualisationConfiguration = {
                    ...dataVisualisationConfiguration,
                    uniqueColumns: dataVisualisationConfiguration.uniqueColumns
                        ? dataVisualisationConfiguration.uniqueColumns.slice().reverse()
                        : dataVisualisationConfiguration.uniqueColumns,
                };
                return getVisualisationDataSource(newDataVisConfig);
            }),
            groupBy: this.props.groupBy,
        };
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(visualisationConfigurationFooterStyle, { withTheme: true })(VisualisationConfigurationFooter));
