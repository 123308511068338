import { Action } from "redux";
import ActionType from "../ActionType";

/**
 * Occurs once resolving an issue has started.
 */
export interface ResolveAllIssuesAction extends Action<ActionType> {
    type: ActionType.ResolveAllIssues;
}

/**
 * Occurs once resolving an issue has started.
 */
export function resolveAllIssuesAction(): ResolveAllIssuesAction {
    return {
        type: ActionType.ResolveAllIssues,
    };
}
