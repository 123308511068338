import Utils from "../infra/Utils";
import DeviceInfo from "./DeviceInfo";
import EntityBase from "./EntityBase";
import EntityType from "./EntityType";
import SternumGeneratedEventInfo from "./SternumGeneratedEventInfo";
import TableRowData from "./TableRowData";
import TraceInfo from "./TraceInfo";
import UserInfo from "./UserInfo";
import { IssueInfoReason, IssueInfoStatus } from "./IssueInfoType";

/**
 * Represents an issue in sternum.
 */
class IssueInfo extends EntityBase implements TableRowData {
    /**
     * Constructor.
     */
    constructor(
        public issueId: string,
        public deviceId: string,
        public status: IssueInfoStatus,
        public anomalyType: null | "unknown" | "lost_communication" | "random_forest",
        public created: number,
        public updated: number,
        public endAt: number | null,
        public updaterUserId: string,
        public device: DeviceInfo,
        public alertName?: string,
        public alertType?: string,
        public category?: string,
        public trace?: TraceInfo,
        public sternumGeneratedEvent?: SternumGeneratedEventInfo,
        public updaterUser?: UserInfo,
        public reasons?: IssueInfoReason[] | null,
        public traceId?: string,
        public sternumGeneratedEventId?: string
    ) {
        super(issueId, created, updated, EntityType.Issue);
    }

    /**
     * Converts json to issue info object.
     */
    public static fromJsonObject(jsonObject: Object) {
        return new IssueInfo(
            jsonObject["entity_id"],
            jsonObject["device_id"],
            jsonObject["status"],
            jsonObject["anomaly_type"],
            jsonObject["created"],
            jsonObject["updated"],
            jsonObject["end_at"],
            jsonObject["updater_user_id"],
            jsonObject["device"]
                ? // if the object has `is_reference` prop, it means it was not filled properly with EntityManager.fillEntity method,
                  // so we nullify it here
                  !jsonObject["device"]["is_reference"]
                    ? DeviceInfo.fromJsonObject(jsonObject["device"])
                    : null
                : null,
            jsonObject["alert_name"],
            jsonObject["alert_type"],
            jsonObject["category"],
            jsonObject["trace"]
                ? // if the object has `is_reference` prop, it means it was not filled properly with EntityManager.fillEntity method,
                  // so we nullify it here
                  !jsonObject["trace"]["is_reference"]
                    ? TraceInfo.fromJsonObject(jsonObject["trace"])
                    : null
                : null,
            jsonObject["sternum_generated_event"]
                ? // if the object has `is_reference` prop, it means it was not filled properly with EntityManager.fillEntity method,
                  // so we nullify it here
                  !jsonObject["sternum_generated_event"]["is_reference"]
                    ? SternumGeneratedEventInfo.fromJsonObject(jsonObject["sternum_generated_event"])
                    : null
                : null,
            jsonObject["updater_user"]
                ? // if the object has `is_reference` prop, it means it was not filled properly with EntityManager.fillEntity method,
                  // so we nullify it here
                  !jsonObject["updater_user"]["is_reference"]
                    ? UserInfo.fromJsonObject(jsonObject["updater_user"])
                    : null
                : null,
            this.getReasonFromJsonObject(jsonObject["reasons"]),
            jsonObject["trace_id"],
            jsonObject["sternum_generated_event_id"]
        );
    }

    private static getReasonFromJsonObject(reasons: Object[] | null): IssueInfoReason[] {
        if (!reasons) {
            return null;
        }

        return reasons.map((reason) => ({
            type: reason["type"], // type of IssueInfoReasonType
            text: reason["text"] || "",
        }));
    }

    public copy(): IssueInfo {
        return new IssueInfo(
            this.issueId,
            this.deviceId,
            this.status,
            this.anomalyType,
            this.created,
            this.updated,
            this.endAt,
            this.updaterUserId,
            this.device,
            this.alertName,
            this.alertType,
            this.category,
            this.trace,
            this.sternumGeneratedEvent,
            this.updaterUser,
            this.reasons,
            this.traceId,
            this.sternumGeneratedEventId
        );
    }

    /**
     * Returns whether given row is any different in details than current issue info.
     * Shallow comparison, in other words.
     * @param other The row to compare with.
     */
    public isDifferentFrom(other: TableRowData): boolean {
        // Row must of of type issue info.
        if (!(other instanceof IssueInfo)) {
            return true;
        }

        let otherIssueInfo = other as IssueInfo;

        // Comparing base issue properties.
        if (
            otherIssueInfo.issueId !== this.issueId ||
            otherIssueInfo.deviceId !== this.deviceId ||
            otherIssueInfo.status !== this.status ||
            otherIssueInfo.anomalyType !== this.anomalyType ||
            otherIssueInfo.created !== this.created ||
            otherIssueInfo.updated !== this.updated ||
            otherIssueInfo.endAt !== this.endAt ||
            otherIssueInfo.updaterUserId !== this.updaterUserId ||
            otherIssueInfo.updaterUserId !== this.updaterUserId ||
            otherIssueInfo.reasons !== this.reasons ||
            otherIssueInfo.traceId !== this.traceId ||
            otherIssueInfo.sternumGeneratedEventId !== this.sternumGeneratedEventId
        ) {
            return true;
        }

        return false;
    }

    /**
     * Gets the value for the given column id.
     */
    public getColumnValue(columnHeaderId: string): any {
        return this[columnHeaderId];
    }

    /**
     * Gets the display name for the issue.
     */
    public getDisplayName() {
        let name: string;
        if (this.trace) {
            name = this.trace.traceDefinition.displayName;
        } else if (this.sternumGeneratedEvent) {
            name = this.sternumGeneratedEvent.sternumTrigger.displayName;
        } else {
            name = "Unknown";
        }
        return Utils.capitalizeFirsLetter(name);
    }

    public getDisplayStatus() {
        return this.status.charAt(0).toUpperCase() + this.status.substr(1).toLowerCase();
    }

    public isResolved() {
        return this.status === "RESOLVED";
    }

    public isLostCommunicationIssue() {
        return this.anomalyType === "lost_communication";
    }

    public isDismissed() {
        return this.status === "DISMISSED";
    }

    public getResolvedBy(): string {
        return this.updaterUser ? this.updaterUser.getFullName() : "John Doe";
    }
}

export default IssueInfo;
