import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const commonSignupManager = {
    mediaQueryMaxWidth: "880px",
};

export const useSignupManagerStyle = makeStyles((theme: Theme) => ({
    authFieldsContainer: {
        maxWidth: 400,
    },

    buttonContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 28,

        "@media (max-width: 450px)": {
            flexFlow: "column",
            gap: 8,
        },
    },

    loginButton: {
        maxWidth: 143,
        backgroundColor: theme.palette.secondary.main,
    },

    linkButton: {
        color: theme.palette.secondary.dark,
        fontWeight: 600,
    },
}));
