import React from "react";
import classNames from "classnames";

import { useSternumEmptyModalStyles } from "./SternumEmptyModal.styles";

export interface DescriptionProps extends React.ComponentProps<"h2"> {}

export function Description({ className, children, ...props }: DescriptionProps) {
    const classes = useSternumEmptyModalStyles();

    return (
        <div className={classNames(classes.description, className)} {...props}>
            {children}
        </div>
    );
}
