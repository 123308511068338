import { Theme } from "@material-ui/core/styles";
import commonStyle from "../../../CommonStyle";
import createStyles from "@material-ui/core/styles/createStyles";
import { sternumTheme } from "../../../App";

const sternumTableRowStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),
        tableRowHover: {
            "&:hover": {
                backgroundColor: `${sternumTheme.lightGrey.main} !important`,
            },
        },

        tableRowHoverWithPointer: {
            "&:hover": {
                backgroundColor: `${sternumTheme.lightGrey.main} !important`,
                cursor: "pointer" as "pointer",
            },
        },

        rootRow: {
            height: theme.spacing(6),
        },

        expandedContentCell: {
            border: "none",
            paddingBottom: "0 !important",
            paddingTop: "0 !important",
        },

        narrow: {
            padding: "0px 10px 0px 0px !important",
            overflowX: "hidden",
            overflowY: "clip",
            textOverflow: "ellipsis",
            fontSize: "14px",
            height: "54px",

            "& *": {
                overflowX: "hidden",
                overflowY: "clip",
                textOverflow: "ellipsis",
                fontSize: "14px",
                maxWidth: "100%",
            },
        },
    });

export default sternumTableRowStyle;
