import { Box, Collapse, IconButton } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import classNames from "classnames";
import * as React from "react";
import HashSet from "../../../../lib/infra/HashSet";
import TableColumnHeaderInfo from "../../../../lib/state/TableColumnHeaderInfo";
import TableRowData from "../../../../lib/state/TableRowData";
import sternumTableRowStyle from "./SternumTableRowStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof sternumTableRowStyle> {
    columnHeaders: TableColumnHeaderInfo[];

    row: TableRowData;
    viewedColumnsSet: HashSet;

    nonClickableRows?: boolean;

    getRowValues: (row: TableRowData) => { [key: string]: any };
    getRowClasses?: (row: TableRowData) => any;
    onRowClicked?: (row: TableRowData, event) => void;
    onRowMouseDown?: (row: TableRowData, event) => void;
    onRowMouseUp?: (row: TableRowData, event) => void;
    onCellRightClick?: (cellName: string, row: TableRowData, event) => void;
    onRowExpand?: (row: TableRowData, isExpanded: boolean) => void;

    // If row should be expandable (arrow at the beginning of the row)
    expandable?: boolean;
    // Set of currently expanded rows
    expandedRowsSet?: HashSet;
    wrapColumns?: boolean;
    narrow?: boolean;
}

/**
 * Displays a sternum-styled table header.
 */
class SternumTableRow extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {};
    }

    /**
     * Returns whether anything changed in props need to execute a render.
     */
    shouldComponentUpdate(nextProps: Readonly<AppProps>, nextState: Readonly<AppState>, nextContext: any): boolean {
        // Difference in row?
        if (nextProps.row.isDifferentFrom(this.props.row)) {
            return true;
        }

        // Difference in viewed columns?
        if (nextProps.viewedColumnsSet.isDifferentFrom(this.props.viewedColumnsSet)) {
            return true;
        }

        // Difference in expanded columns?
        if (this.props.expandedRowsSet && nextProps.expandedRowsSet.isDifferentFrom(this.props.expandedRowsSet)) {
            return true;
        }

        return false;
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;
        let rowValuesMap = this.props.getRowValues ? this.props.getRowValues(this.props.row) : {};

        // Figuring out the classes for the row, because they can be overridden by the user.
        let rowClasses;

        if (this.props.getRowClasses && this.props.getRowClasses(this.props.row)) {
            // If user overridden the classes, we take its classes.
            rowClasses = this.props.getRowClasses(this.props.row);
        } else {
            // Otherwise, we take the default.
            rowClasses = {
                hover: this.props.nonClickableRows ? classes.tableRowHover : classes.tableRowHoverWithPointer,
            };
        }

        rowClasses.root = classes.rootRow;

        const isExpanded = this.props.expandable && this.props.expandedRowsSet.exists(this.props.row.getIdentifier());

        const columns = this.props.columnHeaders.filter((columnHeader) =>
            this.props.viewedColumnsSet.exists(columnHeader.id)
        );

        return (
            <>
                <TableRow
                    hover={!this.props.nonClickableRows}
                    selected={false}
                    classes={rowClasses}
                    onClick={(event) => this.handleRowClicked(this.props.row, event)}
                    onMouseDown={(event) => this.handleRowMouseDown(this.props.row, event)}
                    onMouseUp={(event) => this.handleRowMouseUp(this.props.row, event)}
                    //onContextMenu={(event) => this.handleRowRightClick(this.props.row, event)}
                >
                    {this.props.expandable && (
                        <TableCell>
                            <IconButton
                                size="small"
                                style={{ padding: 0 }}
                                onClick={(event) => this.handleRowExpand(this.props.row, event)}
                            >
                                {isExpanded ? (
                                    <KeyboardArrowUpIcon style={{ padding: 0, height: 19 }} />
                                ) : (
                                    <KeyboardArrowDownIcon style={{ padding: 0, height: 19 }} />
                                )}
                            </IconButton>
                        </TableCell>
                    )}
                    {columns.map((columnHeader) => {
                        return (
                            <TableCell
                                key={columnHeader.id}
                                className={classNames(!this.props.wrapColumns && classes.whiteSpaceNoWrap, {
                                    [classes.narrow]: this.props.narrow,
                                })}
                                onContextMenu={(event) => {
                                    this.handleCellRightClick(this.props.row, columnHeader.displayValue, event);
                                }}
                            >
                                {rowValuesMap[columnHeader.id]
                                    ? rowValuesMap[columnHeader.id]
                                    : this.props.row.getColumnValue(columnHeader.id)}
                            </TableCell>
                        );
                    })}
                </TableRow>
                {this.props.expandable && (
                    <TableRow>
                        {/* `+1` because we need to include column for arrow as well */}
                        <TableCell classes={{ root: classes.expandedContentCell }} colSpan={columns.length + 1}>
                            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                                <Box margin={2} height={400}>
                                    {typeof rowValuesMap.expandedContent === "function"
                                        ? rowValuesMap.expandedContent(this.props.row)
                                        : rowValuesMap.expandedContent}
                                </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                )}
            </>
        );
    }

    /**
     * Occurs on a click on a row.
     */
    private handleRowClicked(row: TableRowData, event) {
        if (this.props.onRowClicked) {
            this.props.onRowClicked(row, event);
        }
    }

    /**
     * Occurs on a click on a row.
     */
    private handleRowExpand(row: TableRowData, event: any) {
        if (this.props.onRowExpand) {
            event.stopPropagation();
            // expand row if its closed / close if its expanded
            this.props.onRowExpand(row, !this.props.expandedRowsSet.exists(row.getIdentifier()));
        }
    }

    /**
     * Occurs on a right click on a row.
     */
    private handleCellRightClick(row: TableRowData, cellName: string, event) {
        if (this.props.onCellRightClick) {
            event.preventDefault();
            this.props.onCellRightClick(cellName, row, event);
        }
    }

    /**
     * Occurs on a mouse down on a row.
     */
    private handleRowMouseDown(row: TableRowData, event) {
        if (this.props.onRowMouseDown) {
            this.props.onRowMouseDown(row, event);
        }
    }

    /**
     * Occurs on a mouse up on a row.
     */
    private handleRowMouseUp(row: TableRowData, event) {
        if (this.props.onRowMouseUp) {
            this.props.onRowMouseUp(row, event);
        }
    }
}

export default withStyles(sternumTableRowStyle)(SternumTableRow);
