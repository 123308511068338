import { Box, Tooltip, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { GlobalState } from "../../lib/state/GlobalState";
import { useCommonStyle } from "../CommonStyle";
import { useStyles } from "./AttackSimulationKitCommandStyle";
import ServiceWire from "../../lib/services/ServiceWire";
import { CopyTextComponent } from "../CopyTextComponent";
import { InfoCircleIcon } from "../SUI/SternumIcon";

export interface AttackSimulationKitCommandProps {}

const mapStateToProps = (state: GlobalState, ownProps: any) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

type AttackSimulationKitCommandPropsWithHOC = AttackSimulationKitCommandProps & ReturnType<typeof mapDispatchToProps>;

enum SimulationKitArchitecture {
    ARM32SF = "arm32sf",
    ARM32HF = "arm32hf",
    ARM64 = "arm64",
}

function getArchitectureLabel(arch: SimulationKitArchitecture): string {
    switch (arch) {
        case SimulationKitArchitecture.ARM32SF:
            return "ARM 32 SF";
        case SimulationKitArchitecture.ARM32HF:
            return "ARM 32 HF";
        case SimulationKitArchitecture.ARM64:
            return "ARM 64";
    }
}

const availableArchitectures: SimulationKitArchitecture[] = Object.values(SimulationKitArchitecture);

function AttackSimulationKitCommandComponent({}: AttackSimulationKitCommandPropsWithHOC) {
    const classes = useStyles();
    const classesCommon = useCommonStyle();
    const [installerCommand, setInstallerCommand] = useState("");
    const [selectedArch, setSelectedArch] = useState<SimulationKitArchitecture>(availableArchitectures[0]);

    useEffect(() => {
        async function fetchInstallerCommand() {
            const response = await ServiceWire.getSternumService().getSimulationKitCommand(selectedArch);
            setInstallerCommand(response.command);
        }

        fetchInstallerCommand();
    }, [selectedArch]);

    return (
        <div>
            <Box display="flex">
                <Typography variant="body2" className={classesCommon.commonBold}>
                    Select architecture type:
                </Typography>
                <Tooltip
                    classes={{ tooltip: classes.infoTooltip }}
                    title={
                        <div>
                            To determine whether your ARM32 device uses hardware floating-point(HF) or software
                            floating-point(SF), you can run this command: <br />
                            <b>cat /proc/cpuinfo</b>
                            <br />
                            <br />
                            If you see
                            <br />
                            <b>Features : ... vfp ...</b>
                            <br />
                            this means hardware floating-point is used, and you should select ARM 32 HF. In all other
                            cases, select ARM 32 SF since your device uses software floating-point.
                        </div>
                    }
                >
                    <Box display="inline-flex" ml={2}>
                        <InfoCircleIcon width={18} height={18} color="#1B6FDE" />
                    </Box>
                </Tooltip>
            </Box>

            <div className={classes.selectArchitecture}>
                {availableArchitectures.map((arch) => (
                    <div
                        key={arch}
                        role="button"
                        className={classNames(classes.architectureOption, selectedArch === arch && "selected")}
                        onClick={() => setSelectedArch(arch)}
                    >
                        <Typography variant="body2">{getArchitectureLabel(arch)}</Typography>
                    </div>
                ))}
            </div>

            <Box mt={2}>
                <Typography variant="caption">
                    Execute the following command in the device's Linux shell prompt:
                </Typography>
            </Box>

            <CopyTextComponent text={installerCommand} notificationText="Command is copied to the clipboard" />
        </div>
    );
}

export const AttackSimulationKitCommand: React.FC<AttackSimulationKitCommandProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(AttackSimulationKitCommandComponent);
