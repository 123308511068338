import { put, takeEvery } from "redux-saga/effects";
import ModalType from "../../state/ModalType";
import ActionType from "../ActionType";
import { viewCveAction } from "../currentlyViewedCveId/ViewCveAction";
import { viewDeviceAction } from "../currentlyViewedDeviceId/ViewDeviceAction";
import { viewGeneratedEventAction } from "../currentlyViewedGeneratedEventId/ViewGeneratedEventAction";
import { viewLibraryAction } from "../currentlyViewedLibraryId/ViewLibraryAction";
import { viewTraceAction } from "../currentlyViewedTraceId/ViewTraceAction";
import { viewUsedLibraryAction } from "../currentlyViewedUsedLibraryId/ViewUsedLibraryAction";
import { OpenModalAction } from "./OpenModalAction";

export function* handleOpenModal(action: OpenModalAction) {
    switch (action.modalKey.modalType) {
        case ModalType.DeviceViewModal:
            yield put(viewDeviceAction(action.parametersMap["deviceId"]));
            break;

        case ModalType.TraceViewModal:
            yield put(viewTraceAction(action.parametersMap["traceId"], action.parametersMap["issueId"]));
            break;

        case ModalType.LibraryViewModal:
            yield put(viewLibraryAction(action.parametersMap["libraryId"]));
            break;

        case ModalType.CveViewModal:
            yield put(viewCveAction(action.parametersMap["cveId"]));
            break;

        case ModalType.UsedLibraryViewModal:
            yield put(viewUsedLibraryAction(action.parametersMap["usedLibraryId"]));
            break;

        case ModalType.SternumGeneratedEventViewModal:
            yield put(
                viewGeneratedEventAction(action.parametersMap["generatedEventId"], action.parametersMap["issueId"])
            );
            break;
    }
}

export function* watchOpenModalAsync() {
    yield takeEvery((action) => action.type === ActionType.OpenModal, handleOpenModal);
}
