import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../../CommonStyle";

const specificBehaviorTriggerStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: {
            paddingTop: theme.spacing(5),
            paddingRight: theme.spacing(5),
            paddingBottom: theme.spacing(5),
            paddingLeft: theme.spacing(5),
        },
        numberTextField: {
            width: "85px",
        },
        unitsTextField: {
            width: "95px",
        },

        filterTitle: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1),
            textDecoration: "underline",
        },
        selectItem: {
            height: theme.spacing(1.5),
            boxSizing: "content-box",
        },
    });

export default specificBehaviorTriggerStyle;
