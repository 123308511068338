import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

const deviceViewStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),
        container: {
            height: "100%",
            padding: theme.spacing(3.5),
        },

        tableContainer: {
            height: "calc(100% - 63px)",
            maxHeight: "100%",
        },

        tableInner: {
            height: "100%",
            padding: theme.spacing(3),
            background: "#fff",
            boxShadow: "0px 0px 23px rgba(231, 232, 235, 0.25)",
            borderRadius: 14,
        },

        deviceViewUpperContent: {
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        titleContainer: {},
        title: {
            marginRight: theme.spacing(2),
        },
        boardTagText: {
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: "0.8125rem",
        },
        boardTagsHolder: {
            "&:hover .addToBoard": {
                visibility: "visible",
            },
        },
        addToBoard: {
            visibility: "hidden",
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: "0.8125rem",
            cursor: "pointer",
            textDecoration: "none",
            color: theme.palette.common.black,
            "&:hover": {
                textDecoration: "underline",
            },
        },
        securityAlertsTabContent: {
            paddingTop: theme.spacing(2),
        },
        deviceInfoTabContent: {
            padding: theme.spacing(4),
            background: "#fff",
            borderRadius: 14,
            borderTopLeftRadius: 0,
        },
        tracesListTabContent: {
            paddingTop: theme.spacing(2),
        },
        deviceDefinitionLibrariesListTabContent: {
            paddingTop: theme.spacing(2),
        },
        cvesListTabContent: {
            paddingTop: theme.spacing(2),
        },
        tracesListPaper: {
            maxHeight: theme.spacing(68),
        },
        tabsSelector: {
            marginTop: theme.spacing(3),
        },
        popoverPaper: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            minWidth: theme.spacing(30),
            marginTop: theme.spacing(-3),
        },
    });

export default deviceViewStyle;
