import classNames from "classnames";
import * as React from "react";
import { useEffect, useState } from "react";
import SternumUtils from "../../lib/infra/SternumUtils";
import ReceivedDefinitionsResponse from "../../lib/services/events/ReceivedDefinitionsResponse";
import SternumQuery from "../../lib/state/SternumQuery";
import SternumQueryEditor from "../SternumQueryEditor/SternumQueryEditor";
import SternumImprovedButton from "../SUI/SternumImprovedButton/SternumImprovedButton";
import useEventsQueryStyle from "./EventsQueryStyle";

export interface AppProps {
    loading: boolean;
    error: boolean;
    receivedDefinitionsResponse: ReceivedDefinitionsResponse;
    sternumQuery: SternumQuery;
    onFilterApplied: (sternumQuery: SternumQuery) => void;
    allowDeviceIdFilter?: boolean;
    isEventsLoaded?: boolean;
    disableEditing?: boolean;
}

const EventsQuery = (props: AppProps) => {
    const classes = useEventsQueryStyle();
    const [sternumQuery, setSternumQuery] = useState<SternumQuery>(props.sternumQuery);
    const [sternumQueryChanged, setSternumQueryChanged] = useState<boolean>(false);
    const [isApplyFilterDisabled, setIsApplyFilterDisabled] = useState<boolean>(false);

    // Update on isEventsLoaded change
    useEffect(() => {
        if (props.isEventsLoaded) {
            setIsApplyFilterDisabled(false);
        }
    }, [props.isEventsLoaded]);

    const fieldsToQuery = props.receivedDefinitionsResponse
        ? SternumUtils.getDeviceDefinitionFieldsToQuery(
              false,
              false,
              false,
              false,
              props.receivedDefinitionsResponse.receivedDefinitions,
              props.receivedDefinitionsResponse.receivedArguments,
              props.allowDeviceIdFilter
          )
        : [];

    const onSternumQueryChanged = (updatedQuery: SternumQuery) => {
        setSternumQuery(updatedQuery);
        setSternumQueryChanged(true);
        setIsApplyFilterDisabled(false);
    };

    const handleFilterApplied = () => {
        setIsApplyFilterDisabled(true);
        props.onFilterApplied(sternumQuery);
    };

    return (
        <>
            <div className={classNames(classes.queryContainer)}>
                <SternumQueryEditor
                    showFilterLabels
                    loading={props.loading}
                    error={props.error}
                    fields={fieldsToQuery}
                    sternumQuery={props.sternumQuery}
                    onSternumQueryChanged={(updatedQuery) => onSternumQueryChanged(updatedQuery)}
                    disabled={props.disableEditing}
                />
            </div>

            {/* Apply filter */}
            {!props.disableEditing && sternumQueryChanged && (
                <div className={classNames(classes.marginBottomLarge, classes.flexEnd)}>
                    <SternumImprovedButton
                        content="Apply Filter"
                        fullWidth={false}
                        onClick={() => handleFilterApplied()}
                        isDisabled={isApplyFilterDisabled}
                    />
                </div>
            )}
        </>
    );
};

export default EventsQuery;
