import * as React from "react";
import { Redirect, Route } from "react-router-dom";
import ServiceWire from "../lib/services/ServiceWire";
import { ClientInfoTier } from "../lib/state/ClientInfo.types";

/**
 * Private route that knows to redirect to login page if user is not authenticated.
 */
export default function PrivateRoute({ component: Component, premiumAccessRoute = false, ...rest }) {
    /**
     * Gets the component to route to.
     */
    function getComponent(props) {
        if (ServiceWire.getAuthenticationService().isAuthenticated() === true) {
            const path = props.location.pathname;
            const selectedClient = ServiceWire.getClientsService().getSelectedClient();

            if (selectedClient) {
                if (selectedClient.isTrialTier() && premiumAccessRoute) {
                    return <Redirect to="/" />;
                }

                if (selectedClient.isTrialTier() && !selectedClient.isOnboardingCompleted() && path !== "/user-guide") {
                    return <Redirect to="/user-guide" />;
                }
            }

            if (!!selectedClient || path === "/boards") {
                return <Component {...props} {...rest} />;
            } else {
                return <Redirect to={"/boards"} />;
            }
        } else {
            return (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: { from: props.location },
                    }}
                />
            );
        }
    }

    return <Route {...rest} render={(props) => getComponent(props)} />;
}
