import React, { useEffect, useMemo, useRef } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { GlobalState } from "../../lib/state/GlobalState";
import { connect } from "react-redux";
import { CategoryTimeRange, CategoryView, DashboardAlertsDateRange } from "../../lib/state/DashboardRegularState";
import {
    fetchAlertsStatisticsAction,
    FetchAlertsStatisticsParams,
    toggleDateRangeInAlertsFilterAction,
} from "../../lib/redux/dashboardRegular";
import {
    IssuesStatisticsCategoryData,
    IssuesStatisticsGroupedCategoryData,
} from "../../lib/state/IssuesStatisticsHeatmap";
import {
    fillTimeValuesGaps,
    getLabelFormatBySelectedTimeRange,
    groupIssuesWithinTimeInterval,
    WEEK_INTERVAL_IN_MS,
} from "../../lib/infra/Alerts.utils";
import moment from "moment";
import { toggleAlertCategoryAction } from "../../lib/redux/dashboardRegular/ToggleAlertCategoryAction";
import { difference } from "lodash";

export interface AnomalyOverTimeLineChartProps {
    startTime: number;
    endTime: number;
}

const PALETTE = ["#15AC5A", "#428FBA", "#A856FF", "#1549FF", "#FFCE28", "#35B5F8", "#FF8762", "#13BEB7"];
const SECURITY_CATEGORY_COLOR = "#D8034A";
const CHART_PADDING = 20;

const mapStateToProps = (state: GlobalState, ownProps: AnomalyOverTimeLineChartProps) => {
    return {
        alertsStatistics: state.dashboardRegular.alertsStatistics,
        alertsFilter: state.dashboardRegular.alertsFilter,
        categoryTimeRange: state.dashboardRegular.categoryTimeRange,
        isOnlyUnresolved: state.dashboardRegular.isOnlyUnresolved,
        selectedCategories: state.dashboardRegular.alertsFilter.selectedCategories,
        selectedAlertCategories: state.dashboardRegular.selectedAlertCategories,
        categoryView: state.dashboardRegular.categoryView,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchAlertsStatistics: (params: FetchAlertsStatisticsParams) => dispatch(fetchAlertsStatisticsAction(params)),
        toggleDateRangeInAlertsFilter: (category: string, dateRange: DashboardAlertsDateRange) =>
            dispatch(toggleDateRangeInAlertsFilterAction(category, dateRange)),
        toggleAlertCategory: (category: string) => dispatch(toggleAlertCategoryAction(category)),
    };
};

type AnomalyOverTimeLineChartPropsWithHOC = AnomalyOverTimeLineChartProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

function AnomalyOverTimeLineCharComponent({
    alertsStatistics,
    alertsFilter,
    categoryTimeRange,
    categoryView,
    selectedCategories,
    selectedAlertCategories,
    fetchAlertsStatistics,
    toggleDateRangeInAlertsFilter,
    toggleAlertCategory,
}: AnomalyOverTimeLineChartPropsWithHOC) {
    const rootRef = useRef<HTMLDivElement>(null);
    const chartRef = useRef<am4charts.XYChart | null>(null);
    const noDataLabelRef = useRef<am4core.Label>(null);

    // key: category
    // value: if over bullet in this category (SECURITY_EVENT etc.)
    const overBulletRef = useRef<Record<string, boolean>>({});

    // holds information about series that are actually drawn,
    // so that we don't have to refresh series that are already
    // on the chart, we just update their data
    const allCurrentSeries = useRef<am4charts.LineSeries[]>([]);

    // used in bullet events, as they are created once when series are created
    // we need to somehow know inside them that categoryTimeRange has changed
    const categoryTimeRangeRef = useRef<CategoryTimeRange>(categoryTimeRange);

    const firstLegendToggleAfterRangeChange = useRef<Record<string, boolean>>({});

    const issuesStatistics = useMemo(() => {
        let issuesStatistics: (IssuesStatisticsCategoryData | IssuesStatisticsGroupedCategoryData)[] = [];

        if (alertsStatistics?.category_count) {
            issuesStatistics =
                categoryTimeRange === CategoryTimeRange.LastMonth
                    ? groupIssuesWithinTimeInterval(alertsStatistics.category_count, WEEK_INTERVAL_IN_MS)
                    : fillTimeValuesGaps(alertsStatistics.category_count);
        }

        return issuesStatistics;
    }, [categoryTimeRange, alertsStatistics]);

    // don't use it outside of legend toggle handler
    // use normal issuesStatistics
    const issuesStatisticsRef =
        useRef<(IssuesStatisticsCategoryData | IssuesStatisticsGroupedCategoryData)[]>(issuesStatistics);

    useEffect(() => {
        issuesStatisticsRef.current = issuesStatistics;
    }, [issuesStatistics]);

    useEffect(() => {
        let chart = am4core.create(rootRef.current, am4charts.XYChart);
        chartRef.current = chart;
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "periodStart";
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

        categoryAxis.renderer.grid.template.stroke = am4core.color("#959595");
        categoryAxis.renderer.grid.template.strokeOpacity = 0.08;
        valueAxis.renderer.grid.template.stroke = am4core.color("#959595");
        valueAxis.renderer.grid.template.strokeOpacity = 0.08;
        categoryAxis.renderer.labels.template.fill = am4core.color("#B8BACF");
        valueAxis.renderer.labels.template.fill = am4core.color("#B8BACF");
        categoryAxis.renderer.labels.template.fontSize = 12;
        valueAxis.renderer.labels.template.fontSize = 12;
        categoryAxis.cursorTooltipEnabled = false;
        valueAxis.cursorTooltipEnabled = false;
        categoryAxis.startLocation = 0.4;
        categoryAxis.endLocation = 0.6;
        categoryAxis.renderer.labels.template.adapter.add("text", (text, target) => {
            return target.dataItem.dataContext?.["formattedPeriod"];
        });

        const noDataLabel = chart.plotContainer.createChild(am4core.Label);
        noDataLabel.text = "Select a category";
        noDataLabel.fontSize = 26;
        noDataLabel.fill = am4core.color("#D5DAE0");
        noDataLabel.x = am4core.percent(50);
        noDataLabel.horizontalCenter = "middle";
        noDataLabel.y = am4core.percent(50);
        noDataLabel.verticalCenter = "middle";
        noDataLabel.isMeasured = false;
        noDataLabel.hide();
        noDataLabelRef.current = noDataLabel;

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.maxTooltipDistance = -1;

        const legend = new am4charts.Legend();
        legend.position = "bottom";
        legend.contentAlign = "left";

        legend.useDefaultMarker = true; // add "as am4core.RoundedRectangle" in next line
        let marker = legend.markers.template.children.getIndex(0) as am4core.RoundedRectangle;
        marker.strokeWidth = 1;
        marker.width = 8;
        marker.height = 8;
        marker.dx = 7;
        marker.dy = 7;
        marker.cornerRadius(4, 4, 4, 4);

        chart.legend = legend;
    }, []);

    useEffect(() => {
        overBulletRef.current = {};
        const categoryKeys = Object.keys(selectedCategories);

        if (issuesStatistics) {
            const chart = chartRef.current;
            chart.cursor.snapToSeries = [];
            chart.dateFormatter.dateFormat = "MM-dd";

            const categories = new Set<string>();
            const categoryLabelsMap: Record<string, string> = {};
            const data: any[] = [];

            issuesStatistics.forEach((stats) => {
                categories.add(stats.category);
                categoryLabelsMap[stats.category] = stats.category_label;

                let opacity = 0;

                if (categoryKeys.includes(stats.category)) {
                    selectedCategories[stats.category].forEach((period) => {
                        if (
                            (typeof stats.period === "number" && stats.period === period.from) ||
                            (stats.period[0] === period.from && stats.period[1] === period.to)
                        ) {
                            opacity = 1;
                            return;
                        }
                    });
                }

                const dateFormat = getLabelFormatBySelectedTimeRange(categoryTimeRange, true);

                const period = stats.period;
                let formattedPeriod = "";
                if (typeof period === "number") {
                    formattedPeriod = moment(period).format(dateFormat);
                } else if (Array.isArray(period)) {
                    const startDate = moment(period[0]).format(dateFormat);
                    const endDate = moment(period[1]).format(dateFormat);
                    formattedPeriod = `${startDate} - ${endDate}`;
                }

                data.push({
                    [`${stats.category}_period`]:
                        typeof stats.period === "number"
                            ? new Date(stats.period)
                            : new Date(stats.period[0] + (stats.period[1] - stats.period[0]) / 2),
                    [`${stats.category}_issues_count`]: stats.issues_count,
                    [`${stats.category}_formatted_period`]: stats.periodStart,
                    period: stats.period,
                    periodStart: stats.periodStart,
                    formattedPeriod,
                    category: stats.category,
                    categoryLabel: stats.category_label,
                    devices: stats.devices_count,
                    // show/hide bullet
                    opacity,
                });
            });

            const allCurrentSeriesCategories = allCurrentSeries.current.map((serie) => serie.id);

            categories.forEach((category: string) => {
                if (allCurrentSeriesCategories.includes(category)) {
                    // we already have this set
                    return;
                }

                let series = chartRef.current.series.push(new am4charts.LineSeries());
                series.dataFields.valueY = `${category}_issues_count`;
                // series.dataFields.dateX = `${category}_period`;
                series.dataFields.categoryX = `${category}_formatted_period`;
                series.id = category;
                series.showOnInit = false;
                series.hiddenState.transitionDuration = 0;
                series.defaultState.transitionDuration = 0;

                if (selectedAlertCategories.includes(category)) {
                    series.hidden = true;
                }

                allCurrentSeries.current.push(series);

                let bullet = series.bullets.push(new am4charts.Bullet());
                let bulletBorder = bullet.createChild(am4core.Circle);
                bulletBorder.width = 12;
                bulletBorder.height = 12;
                bulletBorder.fill = am4core.color("#ffffff");
                bulletBorder.strokeWidth = 1.5;
                bulletBorder.opacity = 0;
                // bulletBorder.propertyFields.opacity = "opacity";
                let bulletCenter = bullet.createChild(am4core.Circle);
                bulletCenter.width = 6;
                bulletCenter.height = 6;
                bulletCenter.opacity = 0;
                // bulletCenter.propertyFields.opacity = "opacity";

                bullet.events.on("over", (e) => {
                    overBulletRef.current[series.id] = true;
                    e.target.children.values.forEach((sprite) => {
                        sprite.opacity = 1;
                    });
                });

                bullet.events.on("out", (e) => {
                    const item = series.tooltipDataItem.dataContext as { opacity: number };

                    if (!item) return;

                    overBulletRef.current[series.id] = false;

                    e.target.children.values.forEach((sprite) => {
                        sprite.opacity = item.opacity;
                    });
                });

                bullet.events.on("hit", (e) => {
                    const item = e.target.dataItem.dataContext as IssuesStatisticsCategoryData & { opacity: number };

                    if (item === undefined) {
                        return;
                    }
                    if (categoryTimeRangeRef.current === CategoryTimeRange.LastWeek) {
                        toggleDateRangeInAlertsFilter(item.category, {
                            from: item.period,
                            to: moment.utc(item.period).endOf("day").toDate().getTime(),
                        });
                    }

                    if (categoryTimeRangeRef.current === CategoryTimeRange.LastMonth) {
                        toggleDateRangeInAlertsFilter(item.category, { from: item.period[0], to: item.period[1] });
                    }

                    if (categoryTimeRangeRef.current === CategoryTimeRange.LastYear) {
                        toggleDateRangeInAlertsFilter(item.category, {
                            from: item.period,
                            to: moment.utc(item.period).endOf("month").toDate().getTime(),
                        });
                    }

                    // leaving this here just in case we need to update
                    // manually again
                    // item.opacity = item.opacity > 0 ? 0 : 1;

                    // e.target.children.values.forEach((sprite) => {
                    //     sprite.opacity = item.opacity;
                    // });
                });

                series.tensionX = 0.8;
                series.tensionY = 0.8;
                series.strokeWidth = 1.5;
                series.legendSettings.labelText = categoryLabelsMap[category];
                series.interpolationDuration = 0;

                const rowStyle = "display: flex; font-size: 12px; line-height: 18px;";
                const descriptionCellStyle = "width: 66px; text-align: left; font-weight: 400;";
                const cellStyle = "flex: 1; text-align: left; font-weight: 500;";

                series.tooltip.background.cornerRadius = 6;
                series.tooltip.background.strokeOpacity = 0;
                series.tooltip.background.fillOpacity = 1;
                // removes the shadow
                // in fact removes all filters so make sure
                // to add filters AFTER this is called
                series.tooltip.background.filters.clear();
                bullet.tooltipHTML = `
            <div>
              <div style="${rowStyle}">
                <div style="${descriptionCellStyle}">Category:</div>
                <div style="${cellStyle}">{categoryLabel}</div>
              </div>
              <div style="${rowStyle}">
                <div style="${descriptionCellStyle}">Date:</div>
                <div style="${cellStyle}">{formattedPeriod}</div>
              </div>
              <div style="${rowStyle}">
                <div style="${descriptionCellStyle}">Alerts:</div>
                <div style="${cellStyle}">{${category}_issues_count}</div>
              </div>
              <div style="${rowStyle}">
                <div style="${descriptionCellStyle}">Devices:</div>
                <div style="${cellStyle}">{devices}</div>
              </div>
            </div>
          `;
            });

            const refreshedCurrentSeriesCategories = allCurrentSeries.current.map((serie) => serie.id);
            const categoriesArray: string[] = Array.from(categories);

            if (refreshedCurrentSeriesCategories.length > categoriesArray.length) {
                // remove unused series
                const removedCategories = difference(refreshedCurrentSeriesCategories, categoriesArray);
                allCurrentSeries.current = allCurrentSeries.current.filter((serie) => {
                    if (removedCategories.includes(serie.id)) {
                        if (serie.isHidden) {
                            toggleAlertCategory(serie.id);
                        }

                        chart.series.removeIndex(chart.series.indexOf(serie)).dispose();
                        return false;
                    }

                    return true;
                });
            }

            allCurrentSeries.current.forEach((series, serieIndex) => {
                let serieColor: am4core.Color;

                if (series.id === "SECURITY_EVENT") {
                    serieColor = am4core.color(SECURITY_CATEGORY_COLOR);
                } else {
                    serieColor = am4core.color(PALETTE[serieIndex % PALETTE.length]);
                }

                series.fill = serieColor;
                series.stroke = serieColor;
            });

            // https://www.amcharts.com/docs/v4/tutorials/custom-ordered-legend-items/
            setTimeout(() => {
                const sortedCategories = [...refreshedCurrentSeriesCategories];

                sortedCategories.sort((a, b) => {
                    const aLabel = categoryLabelsMap[a];
                    const bLabel = categoryLabelsMap[b];
                    return aLabel.localeCompare(bLabel);
                });

                sortedCategories.forEach((item, index) => {
                    const legendItem = chart.legend.children.values.find((legendItem) => {
                        return (legendItem.dataItem.dataContext as any).id === item;
                    });

                    if (!legendItem) {
                        return;
                    }

                    chart.legend.children.moveValue(legendItem, index);
                });

                chart.legend.invalidate();
            });

            chartRef.current.data = data;
            chartRef.current.cursor.snapToSeries = allCurrentSeries.current;
        }
    }, [issuesStatistics, categoryTimeRange]);

    useEffect(() => {
        const cb = () => {
            const categoryKeys = Object.keys(selectedCategories);

            // key: [seriesId-period] or [seriesId-period.0-period.1]
            const opacityMap: Record<string, number> = {};

            issuesStatistics.forEach((stats) => {
                if (categoryKeys.includes(stats.category)) {
                    selectedCategories[stats.category].forEach((period) => {
                        if (
                            (typeof stats.period === "number" && stats.period === period.from) ||
                            (stats.period[0] === period.from && stats.period[1] === period.to)
                        ) {
                            const opacityKey = `${stats.category}-${JSON.stringify(stats.period)}`;
                            opacityMap[opacityKey] = 1;
                            return;
                        }
                    });
                }
            });

            // turn bullets on and off by hand
            chartRef.current.bulletsContainer.children.values.forEach((value) => {
                value.dataItem.component.dataItems.values.forEach((bulletItem) => {
                    const bullet = bulletItem.sprites[0] as am4charts.Bullet;
                    bullet.children.values.forEach((bulletChild) => {
                        const oldPeriod = (bulletItem.dataContext as any).period;

                        let clonedPeriod: number | [number, number] = 0;

                        if (typeof oldPeriod === "number") {
                            clonedPeriod = oldPeriod;
                        } else {
                            clonedPeriod = [oldPeriod[0], oldPeriod[1]];
                        }

                        const opacityKey = `${value.dataItem.component.id}-${JSON.stringify(clonedPeriod)}`;
                        bulletChild.opacity = opacityMap[opacityKey] || 0;
                        (bulletItem.dataContext as any).opacity = opacityMap[opacityKey] || 0;
                    });
                });
            });
        };

        // in case we are initializing
        chartRef.current.events.on("dataitemsvalidated", cb);

        // and in case we are not
        cb();

        return () => {
            chartRef.current.events.off("dataitemsvalidated", cb);
        };
    }, [issuesStatistics, selectedCategories]);

    useEffect(() => {
        const valueAxis = chartRef.current.yAxes.getIndex(0);
        const categoryAxis = chartRef.current.xAxes.getIndex(0);
        const legend = chartRef.current.legend;

        if (categoryView === CategoryView.Max) {
            setTimeout(() => {
                categoryAxis.renderer.labels.template.disabled = false;
                valueAxis.renderer.labels.template.disabled = false;
                allCurrentSeries.current.forEach((series) => {
                    series.bullets.getIndex(0).clickable = true;
                });
                legend.show();
                legend.maxHeight = undefined;
                chartRef.current.deepInvalidate();
            });
        } else {
            setTimeout(() => {
                categoryAxis.renderer.labels.template.disabled = true;
                valueAxis.renderer.labels.template.disabled = true;
                allCurrentSeries.current.forEach((series) => {
                    series.bullets.getIndex(0).clickable = false;
                });
                legend.hide();
                legend.maxHeight = 0;
                chartRef.current.deepInvalidate();
            });
        }
    }, [categoryView]);

    useEffect(() => {
        categoryTimeRangeRef.current = categoryTimeRange;

        allCurrentSeries.current.forEach((serie) => {
            if (serie.isHidden) {
                // bug happens only for hidden series,
                // so fill the array only for them
                firstLegendToggleAfterRangeChange.current[serie.id] = true;
            } else {
                firstLegendToggleAfterRangeChange.current[serie.id] = false;
            }
        });
    }, [categoryTimeRange]);

    useEffect(() => {
        // the logic for selectedAlertCategories is reversed,
        // all *unselected* categories in legend are inside of this array
        if (
            selectedAlertCategories.length === allCurrentSeries.current.length ||
            allCurrentSeries.current.length === 0
        ) {
            noDataLabelRef.current.show();
            chartRef.current.cursor.lineX.disabled = true;
            chartRef.current.cursor.lineY.disabled = true;
        } else {
            noDataLabelRef.current.hide();
            chartRef.current.cursor.lineX.disabled = false;
            chartRef.current.cursor.lineY.disabled = false;
        }
    }, [selectedAlertCategories, categoryTimeRange, issuesStatistics]);

    useEffect(() => {
        const legend = chartRef.current.legend;
        const valueAxis = chartRef.current.yAxes.getIndex(0) as am4charts.ValueAxis<am4charts.AxisRenderer>;

        const onToggle = (e) => {
            const series = e.target.dataItem.dataContext as am4charts.LineSeries;

            if (series.id) {
                toggleAlertCategory(series.id);

                if (!firstLegendToggleAfterRangeChange.current[series.id]) {
                    // this is a workaround for first click on legend
                    // if we are clicking the second time we should ignore that code
                    return;
                }

                // this should work only one time
                firstLegendToggleAfterRangeChange.current[series.id] = false;

                setTimeout(() => {
                    let minMax: [number, number] = [Number.MAX_SAFE_INTEGER, Number.MIN_SAFE_INTEGER];
                    issuesStatisticsRef.current.forEach((item) => {
                        if (!selectedAlertCategories.includes(item.category)) {
                            if (minMax[0] > item.issues_count) {
                                minMax[0] = item.issues_count;
                            }
                            if (minMax[1] < item.issues_count) {
                                minMax[1] = item.issues_count;
                            }
                        }
                    });

                    const zoom = () => {
                        valueAxis.zoomToValues(
                            minMax[0] - CHART_PADDING < 0 ? 0 : minMax[0] - CHART_PADDING,
                            minMax[1] + CHART_PADDING,
                            true,
                            true
                        );
                    };

                    setTimeout(zoom);
                });
            }
        };

        legend.itemContainers.template.events.on("toggled", onToggle);

        return () => {
            legend.itemContainers.template.events.off("toggled", onToggle);
        };
    }, [selectedAlertCategories]);

    return (
        <div style={{ flex: 1 }} role="presentation" aria-label="dashboard anomaly over time line chart">
            <div
                ref={rootRef}
                style={{
                    height: categoryView === CategoryView.Max ? 276 : 176,
                    marginTop: 20,
                }}
            />
        </div>
    );
}

export const AnomalyOverTimeLineChart: React.FC<AnomalyOverTimeLineChartProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(AnomalyOverTimeLineCharComponent);
