import { Typography } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { GlobalState } from "../../lib/state/GlobalState";
import changeLogModalStyle from "./ChangeLogModalStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof changeLogModalStyle> {}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {};
};

/**
 * Displays a bar of metrics.
 */
class ChangeLogModal extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            selectedTabIndex: 0,
        };
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        return (
            <div className={classNames(classes.upperContentContainer)}>
                {/* Title */}
                <Typography className={classNames(classes.marginBottomLarge)} variant={"h5"}>
                    Change Log
                </Typography>

                {/* Version 1.3 */}
                <Typography variant="body2" className={classNames(classes.versionTitle)}>
                    Version 1.3 - 10/21/2021
                </Typography>

                <Typography variant="body2" className={classNames(classes.subsectionTitle)}>
                    Added
                </Typography>

                <ul>
                    <li>
                        <Typography variant="body2">Logarithmic scale</Typography>
                    </li>
                    <li>
                        <Typography variant="body2">Group by filtering</Typography>
                    </li>
                    <li>
                        <Typography variant="body2">Export third parties report to CSV/XLSX</Typography>
                    </li>
                </ul>

                {/* Version 1.2 */}
                <Typography variant="body2" className={classNames(classes.versionTitle)}>
                    Version 1.2 - 09/12/2021
                </Typography>

                <Typography variant="body2" className={classNames(classes.subsectionTitle)}>
                    Added
                </Typography>

                <ul>
                    <li>
                        <Typography variant="body2">New device view and fleet view Implementation</Typography>
                    </li>
                    <li>
                        <Typography variant="body2">New filtering mechanism implementation</Typography>
                    </li>
                    <li>
                        <Typography variant="body2">New devices metric</Typography>
                    </li>
                    <li>
                        <Typography variant="body2">US map</Typography>
                    </li>
                    <li>
                        <Typography variant="body2">Data filtering by geo-location</Typography>
                    </li>
                </ul>

                <Typography variant="body2" className={classNames(classes.subsectionTitle)}>
                    Changed
                </Typography>

                <ul>
                    <li>
                        <Typography variant="body2">Improved glances label definition</Typography>
                    </li>
                </ul>

                {/* Version 1.1.0 */}
                <Typography variant="body2" className={classNames(classes.versionTitle)}>
                    Version 1.1.0 - 08/02/2021
                </Typography>

                <Typography variant="body2" className={classNames(classes.subsectionTitle)}>
                    Changed
                </Typography>

                <ul>
                    <li>
                        <Typography variant="body2">Complete UI & UX refactor</Typography>
                    </li>
                </ul>

                {/* Version 1.0.5 */}
                <Typography variant="body2" className={classNames(classes.versionTitle)}>
                    Version 1.0.5 - 03/03/2021
                </Typography>

                <Typography variant="body2" className={classNames(classes.subsectionTitle)}>
                    Added
                </Typography>

                <ul>
                    <li>
                        <Typography variant="body2">Device distribution map</Typography>
                    </li>
                    <li>
                        <Typography variant="body2">Additional filtering abilities</Typography>
                    </li>
                </ul>

                <Typography variant="body2" className={classNames(classes.subsectionTitle)}>
                    Changed
                </Typography>

                <ul>
                    <li>
                        <Typography variant="body2">Enhanced the event table component</Typography>
                    </li>
                    <li>
                        <Typography variant="body2">Enhanced the Active Custom Alerts component</Typography>
                    </li>
                </ul>

                {/* Version 1.0.4 */}
                <Typography variant="body2" className={classNames(classes.versionTitle)}>
                    Version 1.0.4 - 12/29/2020
                </Typography>

                <Typography variant="body2" className={classNames(classes.subsectionTitle)}>
                    Added
                </Typography>

                <ul>
                    <li>
                        <Typography variant="body2">Glances page</Typography>
                    </li>
                    <li>
                        <Typography variant="body2">Additional filtering abilities for overlay graphs</Typography>
                    </li>
                </ul>

                <Typography variant="body2" className={classNames(classes.subsectionTitle)}>
                    Changed
                </Typography>

                <ul>
                    <li>
                        <Typography variant="body2">Enhanced the Active Custom Alerts component</Typography>
                    </li>
                    <li>
                        <Typography variant="body2">Reordered sidebar elements</Typography>
                    </li>
                </ul>

                {/* Version 1.0.3 */}
                <Typography variant="body2" className={classNames(classes.versionTitle)}>
                    Version 1.0.3 - 11/30/2020
                </Typography>

                <Typography variant="body2" className={classNames(classes.subsectionTitle)}>
                    Added
                </Typography>

                <ul>
                    <li>
                        <Typography variant="body2">Fleet View page</Typography>
                    </li>
                    <li>
                        <Typography variant="body2">Overlay graphs support</Typography>
                    </li>
                    <li>
                        <Typography variant="body2">Overlay metrics support</Typography>
                    </li>
                    <li>
                        <Typography variant="body2">Infrastructure scale-up</Typography>
                    </li>
                </ul>

                <Typography variant="body2" className={classNames(classes.subsectionTitle)}>
                    Changed
                </Typography>

                <ul>
                    <li>
                        <Typography variant="body2">Improved user experience in the filters component</Typography>
                    </li>
                </ul>

                {/* Version 1.0.4 */}
                <Typography variant="body2" className={classNames(classes.versionTitle)}>
                    Version 1.0.2 - 10/11/2020
                </Typography>

                <Typography variant="body2" className={classNames(classes.subsectionTitle)}>
                    Added
                </Typography>

                <ul>
                    <li>
                        <Typography variant="body2">Infrastructure scale-up</Typography>
                    </li>
                    <li>
                        <Typography variant="body2">User interface improvements</Typography>
                    </li>
                    <li>
                        <Typography variant="body2">Version control for Device Profiles</Typography>
                    </li>
                    <li>
                        <Typography variant="body2">"Remember Me" login functionality</Typography>
                    </li>
                </ul>

                <Typography variant="body2" className={classNames(classes.subsectionTitle)}>
                    Changed
                </Typography>

                <ul>
                    <li>
                        <Typography variant="body2">Allow to re-send an invitation to an expired user</Typography>
                    </li>
                </ul>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(changeLogModalStyle)(ChangeLogModal));
