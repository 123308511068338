import TimeSelectOption from "../../lib/state/TimeSelectOption";
import TimeSelectOptions from "../../lib/state/TimeSelectOptions";

export default function getDefaultTimeSelection(): TimeSelectOption {
    /**
     * Possible Options:
     * STERNUM_DEFAULT_TIME_RANGE = "m" | "h" | "d" | "w" | "M" | "y" | "c";
     *
     * "m" - minute
     * "h" - hour
     * "d" - day
     * "w" - week
     * "M" - month
     * "y" - year
     * "c" - custom time rage taken from STERNUM_DEFAULT_CUSTOM_TIME_RANGE_START and STERNUM_DEFAULT_CUSTOM_TIME_RANGE_END
     *
     * e.g. - .env file:
     * STERNUM_DEFAULT_TIME_RANGE=c
     * STERNUM_DEFAULT_CUSTOM_TIME_RANGE_START=2023-03-02T11:36:45.867Z
     * STERNUM_DEFAULT_CUSTOM_TIME_RANGE_END=2023-03-18T11:36:45.867Z
     */
    const defaultValue = process.env.STERNUM_DEFAULT_TIME_RANGE || "w";
    const customTimeStart = process.env.STERNUM_DEFAULT_CUSTOM_TIME_RANGE_START;
    const customTimeEnd = process.env.STERNUM_DEFAULT_CUSTOM_TIME_RANGE_END;

    const timeSelectionEntity = TimeSelectOptions.getTimeSelectionByUnit(defaultValue);

    timeSelectionEntity.timeRange = TimeSelectOptions.getTimeRange(defaultValue, 1, {
        startTime: customTimeStart ? new Date(customTimeStart) : new Date(),
        endTime: customTimeEnd ? new Date(customTimeEnd) : new Date(),
    });

    return timeSelectionEntity;
}
