import { Theme } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

/**
 * Holds the styles needed for loading placeholders.
 */
let devicesKeyMetricsBarStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            flexWrap: "wrap" as "wrap",
            minHeight: theme.spacing(18),
            background: "#fff",
            boxShadow: "0px 0px 23px rgba(231, 232, 235, 0.25)",
            paddingRight: theme.spacing(25),
            paddingLeft: theme.spacing(25),
        },
        loadingPlaceholderContainer: {
            marginRight: theme.spacing(12),
        },
    });

export default devicesKeyMetricsBarStyle;
