import { Typography, withStyles } from "@material-ui/core";
import { WithStyles } from "@material-ui/core/styles";
import * as React from "react";
import { connect } from "react-redux";
import ArgumentDefinitionInfo from "../../../lib/state/ArgumentDefinitionInfo";
import { GlobalState } from "../../../lib/state/GlobalState";
import TraceDefinitionInfo from "../../../lib/state/TraceDefinitionInfo";
import NeverSeenArgumentDefinition from "../../../lib/state/triggers/NeverSeenArgumentDefinition";
import TriggeDefinition from "../../../lib/state/triggers/TriggerDefinition";
import SelectComponent from "../../DeviceDefinitionComponents/SelectComponent/SelectComponent";
import neverSeenArgumentTriggerStyle from "./NeverSeenArgumentTriggerStyle";

interface AppState {
    selectedTrace: any;
    selectedArgument: any;
    ignoreValues: any;
}

export interface AppProps extends WithStyles<typeof neverSeenArgumentTriggerStyle> {
    existingDeviceDefinition: any;
    existingNeverSeenArgumentDefinition?: NeverSeenArgumentDefinition;
    updateFieldsStatus: (isAllFieldsSet: boolean, triggerDefinition: TriggeDefinition) => void;
    disabled?: boolean;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {};
};

class NeverSeenArgumentTrigger extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);
        // Initializing the state to default.
        if (this.props.existingNeverSeenArgumentDefinition) {
            this.state = {
                selectedTrace: this.props.existingNeverSeenArgumentDefinition.traceDefinition,
                selectedArgument: this.props.existingNeverSeenArgumentDefinition.argumentDefinition,
                ignoreValues: this.props.existingNeverSeenArgumentDefinition.ignoreValues,
            };
        } else {
            this.state = {
                selectedTrace: "",
                selectedArgument: "",
                ignoreValues: null,
            };
        }
    }

    /**
     * Send data to parent component
     */
    private sendFieldsStatusToParent = () => {
        if (this.validateFields()) {
            let partialTrace;
            let partialArgument;
            if (typeof this.state.selectedTrace === "object" && !this.state.selectedTrace.entityId) {
                partialTrace = {
                    trace_type: this.state.selectedTrace.traceType,
                    trace_event_name: this.state.selectedTrace.traceEventName,
                };
            }
            if (typeof this.state.selectedArgument === "object" && !this.state.selectedArgument.entityId) {
                partialArgument = {
                    argument_type: this.state.selectedArgument.argumentType,
                    argument_event_name: this.state.selectedArgument.argumentEventName,
                };
            }
            const triggerDefinition = new NeverSeenArgumentDefinition(
                this.state.selectedTrace,
                this.state.selectedArgument,
                this.state.ignoreValues,
                partialTrace,
                partialArgument
            );
            this.props.updateFieldsStatus(true, triggerDefinition);
        } else {
            this.props.updateFieldsStatus(false, null);
        }
    };

    /**
     * Load trace definition for existing trigger definition
     */
    private loadTraceForExistingDefinition = (): TraceDefinitionInfo => {
        if (this.props.existingNeverSeenArgumentDefinition) {
            return this.props.existingDeviceDefinition.getTraceDefinitionById(this.state.selectedTrace);
        }
        return this.state.selectedTrace;
    };

    /**
     * Load argument definition for existing trigger definition
     */
    private loadArgumentForExistingDefinition = (): ArgumentDefinitionInfo => {
        if (this.props.existingNeverSeenArgumentDefinition) {
            return this.props.existingDeviceDefinition.getArgumentDefinitionById(this.state.selectedArgument);
        }
        return this.state.selectedTrace;
    };

    /**
     * Check if all trigger fields are set
     */
    private validateFields = (): boolean => {
        return !!this.state.selectedTrace && !!this.state.selectedArgument;
    };

    /*
     * Handle trace change operation
     */
    private onSelectedTraceChanged = (selectedOption: any) => {
        this.setState(
            {
                selectedTrace: selectedOption.value,
            },
            () => this.sendFieldsStatusToParent()
        );
    };

    /*
     * Handle argument change operation
     */
    private onSelectedArgumentChanged = (selectedOption: any) => {
        this.setState(
            {
                selectedArgument: selectedOption.value,
            },
            () => this.sendFieldsStatusToParent()
        );
    };

    /*
     * Build trace list for dropdown display
     */
    private buildTraceSelectList = () => {
        let traceSelectOption = [];
        this.props.existingDeviceDefinition.traceDefinitions.map((traceDefinitionIter, index) => {
            if (traceDefinitionIter.displayInUI || traceDefinitionIter.canBeUsedForAlerts) {
                traceSelectOption.push({
                    label: traceDefinitionIter.displayName,
                    value: traceDefinitionIter,
                });
            }
        });
        return traceSelectOption;
    };

    /*
     * Build argument list for dropdown display
     */
    private buildArgumentSelectList = () => {
        let argumentSelectOption = [];
        this.props.existingDeviceDefinition.argumentDefinitions.map((argumentDefinitionIter, index) => {
            if (argumentDefinitionIter.displayInUI || argumentDefinitionIter.canBeUsedForAlerts) {
                argumentSelectOption.push({
                    label: argumentDefinitionIter.displayName,
                    value: argumentDefinitionIter,
                });
            }
        });
        return argumentSelectOption;
    };

    /*
     * Build selected item
     */

    private buildSelectedItem = (selectedItem) => {
        if (selectedItem) {
            return { label: selectedItem.displayName, value: selectedItem };
        }
        return "";
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Typography variant="subtitle2">New Behavior Alert</Typography>

                {/* Trace definition selection*/}

                <div role="presentation" aria-label="select trace">
                    <SelectComponent
                        name={"traceSelect"}
                        placeHolder={"Select Trace"}
                        ariaLabel="Select Trace"
                        onFieldChange={this.onSelectedTraceChanged}
                        selectedValue={this.buildSelectedItem(this.state.selectedTrace)}
                        selectOptions={this.buildTraceSelectList()}
                        isDisabled={this.props.disabled}
                        menuLocation={"bottom"}
                    />
                </div>

                {/* Argument definition selection*/}
                <div role="presentation" aria-label="select argument">
                    <SelectComponent
                        name={"argumentSelect"}
                        placeHolder={"Select Argument"}
                        ariaLabel="Select Argument"
                        onFieldChange={this.onSelectedArgumentChanged}
                        selectedValue={this.buildSelectedItem(this.state.selectedArgument)}
                        selectOptions={this.buildArgumentSelectList()}
                        isDisabled={this.props.disabled}
                        menuLocation={"bottom"}
                    />
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(neverSeenArgumentTriggerStyle)(NeverSeenArgumentTrigger));
