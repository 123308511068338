import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../../CommonStyle";

const visualisationConfigurationTypeSelectionStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        allTypesContainer: {
            display: "flex",
            alignItems: "center",
        },

        metricLabel: {
            fontSize: 16,
            fontWeight: 500,
            color: "#1B6FDE",
        },

        metricTypeContainer: {
            height: 44,
            padding: 14,
            border: "1px groove #1B6FDE",
            background: "#F6FAFF",
            display: "flex",
            position: "relative",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            transition: "all 0.2s ease",

            "&:first-child": {
                borderTopLeftRadius: 14,
                borderBottomLeftRadius: 14,
            },

            "&:last-child": {
                borderTopRightRadius: 14,
                borderBottomRightRadius: 14,
            },

            "&.mod-selected": {
                background: "#1B6FDE",

                "& > *": {
                    color: "#fff",
                },
            },

            "&:hover:not(&.mod-selected)": {
                background: "#e3e9f1",

                "&.mod-disabled": {
                    background: "#F6FAFF",
                },
            },
            "&.mod-disabled": {
                cursor: "not-allowed",
                opacity: 0.4,
            },
        },

        metricIconText: {
            color: "#1B6FDE",
            fontSize: 16,
            fontWeight: 500,
        },
    });

export default visualisationConfigurationTypeSelectionStyle;
