import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

const eventsTimelineStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        timelineContainer: {
            position: "relative",
            display: "flex",
            alignItems: "center",
            padding: theme.spacing(4),
            paddingBottom: theme.spacing(32),
            margin: "auto 0",
        },
        separator: {
            width: theme.spacing(200),
            height: 2,
            backgroundColor: "#EFEFEF",

            "&.mod-selected": {
                backgroundColor: "#b0b2b5",
            },
        },
        timelineEllipsis: {
            textOverflow: "ellipsis",
            overflow: "hidden",
            width: 200,
            textAlign: "center",
            whiteSpace: "nowrap",
        },
        timelineBulletContainer: {
            position: "relative",
        },
        timelineIndexContainer: {},
        timelineLegendBullet: {
            flex: "1 1 100px",
            width: theme.spacing(4),
            height: theme.spacing(4),
            borderRadius: 4,

            "&.mod-empty": {
                height: 0,
                border: "1px solid #e9ecf1",
            },

            "&.mod-high": {
                backgroundColor: "#E7004C",
                "&.mod-selected": {
                    borderColor: "rgb(209, 0, 39)",
                },
                "&.mod-hover-enabled": {
                    "&:hover": {
                        borderColor: "rgb(209, 0, 39)",
                    },
                },
            },

            "&.mod-medium": {
                backgroundColor: "#FFCF24",
                "&.mod-selected": {
                    borderColor: "rgb(231,180,22)",
                },
                "&.mod-hover-enabled": {
                    "&:hover": {
                        borderColor: "rgb(231,180,22)",
                    },
                },
            },

            "&.mod-low": {
                backgroundColor: "#C8E0FF",
                "&.mod-selected": {
                    borderColor: "rgb(34, 155, 212)",
                },
                "&.mod-hover-enabled": {
                    "&:hover": {
                        borderColor: "rgb(34, 155, 212)",
                    },
                },
            },

            "&.mod-regular": {
                backgroundColor: "#004362",
                "&.mod-selected": {
                    borderColor: "rgb(214,235,245)",
                },
                "&.mod-hover-enabled": {
                    "&:hover": {
                        borderColor: "rgb(214,235,245)",
                    },
                },
            },
        },
        timelineBullet: {
            width: theme.spacing(6),
            height: theme.spacing(6),
            borderRadius: "50%",
            border: "3px solid",
            borderColor: "transparent",

            "&.mod-empty": {
                height: 0,
                border: "1px solid #e9ecf1",
                borderRadius: 0,
            },

            "&.mod-high": {
                backgroundColor: "rgba(231, 0, 76, 1)",
                "&.mod-selected": {
                    borderColor: "rgba(231, 0, 76, 1)",
                    backgroundColor: "rgba(231, 0, 76, 0.1)",
                },
                "&.mod-hover-enabled": {
                    "&:hover": {
                        borderColor: "rgba(231, 0, 76, 1)",
                    },
                },
            },

            "&.mod-medium": {
                backgroundColor: "rgba(255, 207, 36, 1)",
                "&.mod-selected": {
                    borderColor: "rgba(255, 207, 36, 1)",
                    backgroundColor: "rgba(255, 207, 36, 0.1)",
                },
                "&.mod-hover-enabled": {
                    "&:hover": {
                        borderColor: "rgba(255, 207, 36, 1)",
                    },
                },
            },

            "&.mod-low": {
                backgroundColor: "rgba(200, 224, 255, 1)",
                "&.mod-selected": {
                    borderColor: "rgba(200, 224, 255, 1)",
                    backgroundColor: "rgba(200, 224, 255, 0.1)",
                },
                "&.mod-hover-enabled": {
                    "&:hover": {
                        borderColor: "rgba(200, 224, 255, 1)",
                    },
                },
            },

            "&.mod-regular": {
                backgroundColor: "rgba(0, 67, 98, 1)",
                "&.mod-selected": {
                    borderColor: "rgba(0, 67, 98, 1)",
                    backgroundColor: "rgba(0, 67, 98, 0.1)",
                },
                "&.mod-hover-enabled": {
                    "&:hover": {
                        borderColor: "rgba(0, 67, 98, 1)",
                    },
                },
            },
        },
        timelineBulletTooltipLine: {
            position: "absolute",
            width: 2,
            height: theme.spacing(8),
            backgroundColor: "#EFEFEF",

            "&.mod-selected": {
                backgroundColor: "#b0b2b5",
            },

            "&.mod-top": {
                left: theme.spacing(3) - 1,
                top: theme.spacing(1) * -8,
            },

            "&.mod-bottom": {
                left: theme.spacing(3) - 1,
                top: theme.spacing(6),
            },
        },
        timelineBulletLabelContainer: {
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            width: theme.spacing(28),
            height: theme.spacing(8),

            "&.mod-top": {
                alignItems: "flex-end",
                left: theme.spacing(-11),
                top: theme.spacing(-18),
            },

            "&.mod-bottom": {
                alignItems: "flex-start",
                left: theme.spacing(-11),
                top: theme.spacing(16),
            },
        },
        timelineBulletTimestampContainer: {
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            width: theme.spacing(16),
            height: theme.spacing(4),

            "&.mod-top": {
                alignItems: "flex-end",
                left: theme.spacing(-5),
                top: theme.spacing(-6),
            },

            "&.mod-bottom": {
                alignItems: "flex-start",
                left: theme.spacing(-5),
                top: theme.spacing(8),
            },
        },
        timelineBulletLabelContent: {
            fontSize: "1rem",
            textAlign: "center",
            color: "#908C8C",
            width: "100%",

            "&.mod-selected": {
                fontWeight: 600,
            },
        },
        bulletArgumentInfo: {
            fontSize: "1rem",
            color: "#908C8C",

            "&.mod-selected": {
                fontWeight: 600,
            },
        },
        previousPageLink: {
            minWidth: "24px",
            cursor: "pointer",
            marginRight: theme.spacing(2),
            color: "#909090",
            "&:hover": {
                color: "#b0b2b5",
            },
        },
        nextPageLink: {
            minWidth: "24px",
            transform: "rotate(180deg)",
            cursor: "pointer",
            marginLeft: theme.spacing(2),
            color: "#909090",
            "&:hover": {
                color: "#b0b2b5",
            },
        },
        multiLineTimelineBulletLabelContainer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
        },
        popoverContentContainer: {
            padding: theme.spacing(2),
            maxWidth: theme.spacing(68),

            "&.mod-bigger": {
                maxWidth: theme.spacing(108),
            },
        },
        popoverElement: {
            "&.mod-bottom": {
                marginLeft: "-7px",
                marginTop: "5px",
            },
            "&.mod-top": {
                marginLeft: "-7px",
                marginTop: "-5px",
            },
        },
    });

export default eventsTimelineStyle;
