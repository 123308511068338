import React from "react";

import { SternumIconProps } from "./SternumIcon.types";

export const DangerIcon = React.forwardRef<SVGSVGElement, SternumIconProps>(function DangerIcon(
    { width = 18, height = 18, color, ...props },
    ref
) {
    return (
        <svg
            ref={ref}
            width={width}
            height={height}
            viewBox="0 0 21 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M9.66467 16.0037C9.66467 15.4539 10.1127 14.9998 10.6647 14.9998C11.2167 14.9998 11.6647 15.4393 11.6647 15.9891V16.0037C11.6647 16.5535 11.2167 16.9998 10.6647 16.9998C10.1127 16.9998 9.66467 16.5535 9.66467 16.0037Z"
                fill={color || "currentColor"}
            />
            <path
                d="M3.65668 20H17.7472C18.8632 19.9352 19.7213 18.8801 19.6639 17.6415C19.6513 17.3909 19.6026 17.1436 19.517 16.9125L12.4367 3.15872C11.8958 2.07341 10.665 1.68032 9.68712 2.28183C9.35437 2.48486 9.07999 2.78939 8.8961 3.15872L1.81583 16.9125C1.39551 18.0616 1.89367 19.3704 2.92793 19.838C3.13809 19.932 3.35993 19.986 3.58566 20"
                stroke={color || "currentColor"}
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.6551 12.4174V9.0697"
                stroke={color || "currentColor"}
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
});
