import React from "react";

import { SternumIconProps } from "./SternumIcon.types";

export const DocumentText = React.forwardRef<SVGSVGElement, SternumIconProps>(function DocumentText(
    { color = "currentColor", width = 24, height = 24, ...props },
    ref
) {
    return (
        <svg ref={ref} width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.61032 5.61035C4.88897 6.3317 4.48624 7.53203 4.48624 9.52295V14.4771C4.48624 16.468 4.88897 17.6683 5.61032 18.3897C6.33168 19.111 7.532 19.5138 9.52293 19.5138H14.4771C16.468 19.5138 17.6683 19.111 18.3897 18.3897C19.111 17.6683 19.5137 16.468 19.5137 14.4771V10.3486C19.5137 9.93822 19.8464 9.60552 20.2569 9.60552C20.6673 9.60552 21 9.93822 21 10.3486V14.4771C21 16.6146 20.577 18.3042 19.4406 19.4406C18.3041 20.577 16.6146 21 14.4771 21H9.52293C7.38542 21 5.69584 20.577 4.5594 19.4406C3.42295 18.3042 3 16.6146 3 14.4771V9.52295C3 7.38544 3.42295 5.69586 4.5594 4.55942C5.69584 3.42297 7.38542 3.00002 9.52293 3.00002H13.6514C14.0618 3.00002 14.3945 3.33273 14.3945 3.74314C14.3945 4.15355 14.0618 4.48626 13.6514 4.48626H9.52293C7.532 4.48626 6.33168 4.88899 5.61032 5.61035Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.367 3.05659C13.6447 2.94157 13.9643 3.00515 14.1768 3.21768L20.7823 9.82317C20.9949 10.0357 21.0584 10.3553 20.9434 10.633C20.8284 10.9107 20.5574 11.0918 20.2569 11.0918H16.9541C15.6744 11.0918 14.5848 10.8881 13.8484 10.1516C13.1119 9.41519 12.9082 8.32559 12.9082 7.04589V3.74314C12.9082 3.44258 13.0893 3.17161 13.367 3.05659ZM14.3945 5.53719V7.04589C14.3945 8.24325 14.6036 8.80502 14.8993 9.1007C15.195 9.39637 15.7567 9.60552 16.9541 9.60552H18.4628L14.3945 5.53719Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.12844 12.8257C7.12844 12.4153 7.46114 12.0826 7.87155 12.0826H12.8257C13.2361 12.0826 13.5688 12.4153 13.5688 12.8257C13.5688 13.2361 13.2361 13.5688 12.8257 13.5688H7.87155C7.46114 13.5688 7.12844 13.2361 7.12844 12.8257Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.12844 16.1284C7.12844 15.718 7.46114 15.3853 7.87155 15.3853H11.1743C11.5847 15.3853 11.9174 15.718 11.9174 16.1284C11.9174 16.5389 11.5847 16.8716 11.1743 16.8716H7.87155C7.46114 16.8716 7.12844 16.5389 7.12844 16.1284Z"
                fill={color}
            />
        </svg>
    );
});
