import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

import { commonLoginManager } from "../LoginManager.style";

export const useAuthInfoIconStyle = makeStyles((theme: Theme) => ({
    tooltipBackground: {
        backgroundColor: "#222831",
        padding: "6px 12px",
    },

    icon: {
        display: "inline-flex",
        marginTop: 10,
        marginLeft: 11,

        [`@media (max-width: ${commonLoginManager.mediaQueryMaxWidth})`]: {
            margin: "auto",
            marginLeft: 11,
        },
    },

    tooltipTitle: {
        fontSize: 12,
        lineHeight: "18px",
        fontWeight: 400,
        fontFamily: "Inter",
    },
}));
