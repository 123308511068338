import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { sternumTheme } from "../../components/App";
import commonStyle from "../../components/CommonStyle";

const deviceDefinitionCardStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: {
            marginLeft: theme.spacing(3),
            marginTop: theme.spacing(4),
        },

        deviceProfileTitle: {
            fontSize: 18,
            fontWeight: 600,
        },

        deviceProfileCharacteristic: {
            fontSize: 14,
            fontWeight: 500,
            color: "#B8BACF",
        },

        deviceProfileCreator: {
            fontSize: 14,
        },

        deviceProfileOptionsMenu: {
            borderRadius: 20,
            boxShadow: "0px 6px 250px 0px #0000001F",

            "& .MuiMenu-list": {
                padding: theme.spacing(3, 0),

                "& .MuiListItem-button:hover": {
                    background: "#FFF1F5",
                },
            },
        },

        cardHolder: {
            boxShadow: "0px 0px 23px rgba(231, 232, 235, 0.25)",
            background: theme.palette.common.white,
            borderRadius: 14,
            height: theme.spacing(45),
            overflow: "hidden",
            whiteSpace: "nowrap",
            padding: theme.spacing(4),
            "&:hover .boardNameTypography": {
                color: "#636c75",
            },
        },

        actionAreaHeight: {
            height: theme.spacing(27),
        },
        noWarp: {
            overflow: "hidden",
            whiteSpace: "nowrap",
        },

        badge: {
            height: "12px",
            minWidth: "6px",
            fontSize: "0.65rem",
        },
        moreInfo: {
            padding: "5px",
        },
        bold: {
            background: "#DCDCDC",
        },
        parentDisplaySize: {
            fontSize: "0.85rem",
        },
    });

export default deviceDefinitionCardStyle;
