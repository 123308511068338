import React from "react";
import classNames from "classnames";

import { SwitchSelectorItem } from "./SwitchSelectorItem.component";
import { useSwitchSelectorStyle } from "./SwitchSelector.style";
import { SwitchSelectorContextProvider } from "./SwitchSelector.context";

export interface SwitchSelectorProps extends React.ComponentProps<"div"> {
    selectedCase?: unknown;
    onChangeSelectedCase?: (newSelectedCase: unknown) => unknown;
}

export function SwitchSelector({
    className,
    selectedCase,
    onChangeSelectedCase,
    children,
    ...props
}: SwitchSelectorProps) {
    const classes = useSwitchSelectorStyle();

    const handleChange = (newSelectedCase: any) => {
        onChangeSelectedCase?.(newSelectedCase);
    };

    return (
        <SwitchSelectorContextProvider selectedCase={selectedCase} onChangeSelectedCase={handleChange}>
            <div className={classNames(classes.switchSelector, className)} {...props}>
                {children}
            </div>
        </SwitchSelectorContextProvider>
    );
}

SwitchSelector.Item = SwitchSelectorItem;
