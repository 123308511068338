import { Tooltip } from "@material-ui/core";
import classNames from "classnames";
import * as React from "react";
import useSternumEventTypeDisplayStyle from "./SternumEventTypeDisplayStyle";
import { ArchiveBookIcon } from "../SternumIcon";

export interface AppProps {
    label: string;
    size: "small" | "medium" | "large";
    showIndicator: boolean;
    toolTip: string;
    eventInterest: string;
    // when defined shows context on hover
    onContextClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => unknown;
    // when true shows context all the time
    pinContext?: boolean;
    hideShowInContext?: boolean;
    traceId?: string;
}

export default function SternumEventTypeDisplay(props: AppProps) {
    const classes = useSternumEventTypeDisplayStyle();
    const hideShowInContext = props.hideShowInContext || false;

    /**
     *
     * Get the right  indicator css class by interest name
     */
    const getInterestIndicatorClass = (interestName: string) => {
        switch (interestName.toLowerCase()) {
            case "low":
                return classes.lowInterestIndicator;
            case "regular":
                return classes.regularInterestIndicator;
            case "medium":
                return classes.mediumInterestIndicator;
            case "high":
                return classes.highInterestIndicator;
        }
    };

    /**
     *
     * Get the right label css class by interest name
     */
    const getInterestLabelClass = (interestName: string) => {
        switch (interestName.toLowerCase()) {
            case "low":
                return classes.lowInterestLabel;
            case "regular":
                return classes.regularInterestLabel;
            case "medium":
                return classes.mediumInterestLabel;
            case "high":
                return classes.highInterestLabel;
        }
    };

    return (
        <Tooltip title={props.toolTip} classes={{ tooltip: classes.toolTip }}>
            <div className={classes.flexRow}>
                {props.onContextClick && (
                    <div
                        className={classNames(classes.contextIcon, {
                            [classes.contextIconHidden]: hideShowInContext,
                            [classes.contextIconPinned]: !!props.pinContext,
                        })}
                        onClick={props.onContextClick}
                    >
                        <ArchiveBookIcon />
                    </div>
                )}
                {props.showIndicator && (
                    <div
                        className={classNames(
                            classes.statusIndication,
                            "mod-small",
                            getInterestIndicatorClass(props.eventInterest)
                        )}
                    />
                )}
                <div
                    className={classNames(
                        classes.statusIndication,
                        `mod-${props.size}`,
                        getInterestLabelClass(props.eventInterest)
                    )}
                    data-sternum-event-type-display-trace-id={props.traceId || ""}
                >
                    {props.label}
                </div>
            </div>
        </Tooltip>
    );
}
