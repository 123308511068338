import { Action } from "redux";
import ActionType from "../ActionType";

export interface FetchAlertsStatisticsAction extends Action<ActionType> {
    type: ActionType.FetchAlertsStatistics;
    period: "month" | "day_of_month";
    onlyUnresolved?: boolean;
    onlyAnomalyInsights?: boolean;
    startTime?: number;
    endTime?: number;
}

export type FetchAlertsStatisticsParams = Omit<FetchAlertsStatisticsAction, "type">;

export function fetchAlertsStatisticsAction(params: FetchAlertsStatisticsParams): FetchAlertsStatisticsAction {
    return {
        type: ActionType.FetchAlertsStatistics,
        ...params,
    };
}
