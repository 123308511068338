/**
 * Responsible for debounce requests.
 */
class DebounceService {
    /**
     * Request id to its debounce timer.
     * It will be used to clear timers and initiate new ones.
     */
    private static requestIdToTimerMap: { [requestId: string]: any } = {};

    /**
     * Debounce request by request id.
     * action will be the executed action and intervalMs is how long to debounce.
     */
    public static debounce(requestId: string, action: () => void, intervalMs: number) {
        // If request id is not already initialized, we initialize a timer record that is null for it in the map.
        if (!this.requestIdToTimerMap[requestId]) {
            this.requestIdToTimerMap[requestId] = null;
        }

        // If timeout is defined, we need to cancel it!
        if (this.requestIdToTimerMap[requestId]) {
            clearTimeout(this.requestIdToTimerMap[requestId]);
        }

        // We initiate a new timer.
        this.requestIdToTimerMap[requestId] = setTimeout(async () => {
            await action();
        }, intervalMs);
    }
}

export default DebounceService;
