import React, { useEffect } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import { GlobalState } from "../../../../lib/state/GlobalState";
import { CategoryGraphView, CategoryTimeRange } from "../../../../lib/state/DashboardRegularState";
import { fetchAlertsStatisticsAction, FetchAlertsStatisticsParams } from "../../../../lib/redux/dashboardRegular";
import { AnomalyOverTimeLineChart } from "../../../AnomalyOverTimeLineChart";
import SpinnerLoader from "../../../SUI/SternumLoaders/SpinnerLoader";
import { IssueListEmitter } from "../../../IssuesList/IssuesList.emitter";
import { DashboardInteractiveMap } from "../../../DashboardInteractiveMap";
import { AlertsFilter } from "../AlertsFilter";
import { AlertsHeatmap } from "../AlertsHeatmap";
import { useIssuesVisualisationStyle } from "./IssuesVisualisation.style";

export interface IssuesVisualisationProps {
    startTime: number;
    endTime: number;
}

const mapStateToProps = (state: GlobalState, ownProps: IssuesVisualisationProps) => {
    return {
        categoryTimeRange: state.dashboardRegular.categoryTimeRange,
        categoryView: state.dashboardRegular.categoryView,
        isOnlyUnresolved: state.dashboardRegular.isOnlyUnresolved,
        isOnlyAnomalyInsights: state.dashboardRegular.isOnlyAnomalyInsights,
        selectedGraphView: state.dashboardRegular.selectedGraphView,
        statisticsLoading: state.dashboardRegular.alertsStatisticsLoading,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchAlertsStatistics: (params: FetchAlertsStatisticsParams) => dispatch(fetchAlertsStatisticsAction(params)),
    };
};

type IssuesVisualisationPropsWithHOC = IssuesVisualisationProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

function IssuesVisualisationComponent({
    startTime,
    endTime,
    categoryTimeRange,
    isOnlyUnresolved,
    isOnlyAnomalyInsights,
    selectedGraphView,
    fetchAlertsStatistics,
    statisticsLoading,
}: IssuesVisualisationPropsWithHOC) {
    const classes = useIssuesVisualisationStyle();

    useEffect(() => {
        const onRefresh = () => {
            fetchAlertsStatistics({
                period: categoryTimeRange === CategoryTimeRange.LastYear ? "month" : "day_of_month",
                startTime,
                onlyUnresolved: isOnlyUnresolved,
                onlyAnomalyInsights: isOnlyAnomalyInsights,
                endTime,
            });
        };

        // refresh after parameters change
        onRefresh();

        // refresh after outside influence of the issue list operation
        IssueListEmitter.on("refresh", onRefresh);

        return () => {
            IssueListEmitter.off("refresh", onRefresh);
        };
    }, [categoryTimeRange, startTime, endTime, isOnlyUnresolved, isOnlyAnomalyInsights]);

    return (
        <div className={classNames(classes.root)} role="presentation" aria-label="dashboard issues visualisation">
            {statisticsLoading && (
                <div className={classes.loadingOverlay}>
                    <SpinnerLoader width={30} />
                </div>
            )}

            <div className={classNames(statisticsLoading && classes.blur)}>
                {selectedGraphView === CategoryGraphView.Categories && (
                    <>
                        <AlertsFilter />
                        {/*If performance will be not good consider to remove `key` and update content inside*/}
                        <AlertsHeatmap key={`${startTime}-${endTime}`} />
                    </>
                )}
                {selectedGraphView === CategoryGraphView.OverTime && (
                    <>
                        <AlertsFilter />
                        <AnomalyOverTimeLineChart startTime={startTime} endTime={endTime} />
                    </>
                )}
                {selectedGraphView === CategoryGraphView.Map && <DashboardInteractiveMap />}
            </div>
        </div>
    );
}

export const IssuesVisualisation: React.FC<IssuesVisualisationProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(IssuesVisualisationComponent);
