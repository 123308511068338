import { Box, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { GlobalState } from "../../lib/state/GlobalState";
import { useCommonStyle } from "../CommonStyle";
import { useStyles } from "./OpenWRTInstallerCommandStyle";
import ServiceWire from "../../lib/services/ServiceWire";
import { CopyTextComponent } from "../CopyTextComponent";

export interface OpenWRTInstallerCommandProps {
    showUninstallCommand?: boolean;
}

const mapStateToProps = (state: GlobalState, ownProps: any) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

type OpenWRTInstallerCommandPropsWithHOC = OpenWRTInstallerCommandProps & ReturnType<typeof mapDispatchToProps>;

enum OpenWRTArchitecture {
    ARM32 = "ARM 32",
    ARM64 = "ARM 64",
}

const availableArchitectures: OpenWRTArchitecture[] = Object.values(OpenWRTArchitecture);

function OpenWRTInstallerCommandComponent({ showUninstallCommand = false }: OpenWRTInstallerCommandPropsWithHOC) {
    const classes = useStyles();
    const classesCommon = useCommonStyle();
    const [installerCommand, setInstallerCommand] = useState("");
    const [selectedArch, setSelectedArch] = useState<OpenWRTArchitecture>(availableArchitectures[0]);

    useEffect(() => {
        async function fetchInstallerCommand() {
            const response = await ServiceWire.getSternumService().getInstallerCommand(
                selectedArch === OpenWRTArchitecture.ARM32 ? "arm32" : "arm64"
            );
            setInstallerCommand(response.command);
        }

        fetchInstallerCommand();
    }, [selectedArch]);

    return (
        <div>
            <Typography variant="body2" className={classesCommon.commonBold}>
                Select architecture type:
            </Typography>

            <div className={classes.selectArchitecture}>
                {availableArchitectures.map((arch) => (
                    <div
                        key={arch}
                        role="button"
                        className={classNames(classes.architectureOption, selectedArch === arch && "selected")}
                        onClick={() => setSelectedArch(arch)}
                    >
                        <Typography variant="body2">{arch}</Typography>
                    </div>
                ))}
            </div>

            <Box mt={2}>
                <Typography variant="caption" className={classes.commandHelperText}>
                    Execute the following command in the device's Linux shell prompt:
                </Typography>
                <CopyTextComponent
                    text={installerCommand}
                    notificationText="Installation command is copied to the clipboard"
                />
            </Box>

            {showUninstallCommand && (
                <Box mt={6}>
                    <Typography variant="body2" className={classesCommon.commonBold}>
                        Uninstall Sternum Runtime Protection
                    </Typography>
                    <Box mt={1}>
                        <Typography variant="caption" color="textSecondary">
                            To uninstall please execute the following command in the root directory of the installer:
                        </Typography>
                    </Box>
                    <CopyTextComponent
                        text={`./installer.sh -u`}
                        notificationText="Command is copied to the clipboard"
                    />
                </Box>
            )}
        </div>
    );
}

export const OpenWRTInstallerCommand: React.FC<OpenWRTInstallerCommandProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(OpenWRTInstallerCommandComponent);
