import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

const libraryInfoDisplayStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: {
            display: "flex",
            alignItems: "flex-start",
        },
        libraryDetailContainer: {
            display: "flex",
            alignItems: "center",
        },
        libraryDetailName: {
            marginRight: theme.spacing(1),
        },
        infoColumn: {
            marginRight: theme.spacing(8),
        },
    });

export default libraryInfoDisplayStyle;
