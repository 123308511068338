import ArgumentRoleTypeUIConfiguration from "../infra/ArgumentRoleTypeUIConfiguration";
import SternumConfiguration from "../infra/SternumConfiguration";
import TraceEventTypeConfiguration from "../infra/TraceEventTypeConfiguration";
import Utils from "../infra/Utils";
import ArgumentInfo from "./ArgumentInfo";
import DeviceDefinitionVersionInfo from "./DeviceDefinitionVersionInfo";
import DeviceInfo from "./DeviceInfo";
import DeviceProcessInfo from "./DeviceProcessInfo";
import DeviceProcessInstanceInfo from "./DeviceProcessInstanceInfo";
import EntityBase from "./EntityBase";
import EntityType from "./EntityType";
import GeoLocationInfo from "./GeoLocationInfo";
import TableRowData from "./TableRowData";
import TraceDefinitionInfo from "./TraceDefinitionInfo";
import TraceSeverity from "./TraceSeverity";

const PROCESS_KEY_NAME = "SYSTEM_ARG_ROLE_EXECUTING_PROCESS_NAME";

/**
 * Represents a trace in sternum.
 */
class TraceInfo extends EntityBase implements TableRowData {
    /**
     * Constructor.
     */
    constructor(
        public traceId: string,
        public traceIdLong: number,
        public traceEventType: string,
        public traceEventName: string,
        public deviceId: string,
        public deviceProcessId: string,
        public deviceProcessInstanceId: string,
        public created: number,
        public updated: number,
        public ipAddress: string,
        public severity: TraceSeverity,
        public traceArguments: { [key: string]: ArgumentInfo },
        public orderedTraceArguments: ArgumentInfo[],
        public traceDefinition: TraceDefinitionInfo,
        public device?: DeviceInfo,
        public deviceProcess?: DeviceProcessInfo,
        public deviceProcessInstance?: DeviceProcessInstanceInfo,
        public geoLocationInfo?: GeoLocationInfo,
        public description?: string,
        public deviceDefinitionVersion?: DeviceDefinitionVersionInfo
    ) {
        super(traceId, created, updated, EntityType.Trace);
    }

    /**
     * Converts json to trace info object.
     */
    public static fromJsonObject(jsonObject: Object) {
        // Convert trace definition
        let traceDefinition: TraceDefinitionInfo = TraceDefinitionInfo.fromJsonObject(jsonObject["trace_definition"]);

        // Constructing the array and map of arguments.
        let argumentTypeToArgumentInfoMap: { [key: string]: ArgumentInfo } = {};
        let orderedTraceArguments: ArgumentInfo[] = [];

        for (let argument_role_type in jsonObject["arguments"]) {
            if (jsonObject["arguments"].hasOwnProperty(argument_role_type)) {
                let argumentInfo: ArgumentInfo = ArgumentInfo.fromJsonObject(
                    jsonObject["arguments"][argument_role_type]
                );

                // Add to map
                argumentTypeToArgumentInfoMap[argument_role_type] = argumentInfo;
                // Add to array
                orderedTraceArguments.push(argumentInfo);
            }
        }

        // If we are requested to regard order, we sort the arguments by order in configuration.
        let traceConfiguration: TraceEventTypeConfiguration = SternumConfiguration.getTraceEventTypeConfigurationObject(
            traceDefinition.traceEventName
        );

        if (
            traceConfiguration &&
            traceConfiguration.argumentRoleTypesConfigurations &&
            traceConfiguration.shouldRegardOrder
        ) {
            Utils.sortCollection(orderedTraceArguments, (argumentInfo) => {
                let argumentRoleTypeConfiguration: ArgumentRoleTypeUIConfiguration =
                    traceConfiguration.argumentRoleTypesConfigurations[
                        argumentInfo.argumentDefinition.argumentEventName
                    ];

                if (!argumentRoleTypeConfiguration) {
                    return 0;
                } else {
                    return argumentRoleTypeConfiguration.order;
                }
            });
        }
        return new TraceInfo(
            jsonObject["entity_id"],
            jsonObject["entity_id_long"],
            jsonObject["trace_event_type"],
            jsonObject["trace_event_name"],
            jsonObject["device_id"],
            jsonObject["device_process_id"],
            jsonObject["device_process_instance_id"],
            jsonObject["created"],
            jsonObject["updated"],
            jsonObject["ip_address"] ? jsonObject["ip_address"] : "N/A",
            this.extractSeverity(jsonObject["severity"]),
            argumentTypeToArgumentInfoMap,
            orderedTraceArguments,
            traceDefinition,
            DeviceInfo.fromJsonObject(jsonObject["device"]),
            undefined,
            undefined,
            jsonObject["geo_location"] ? GeoLocationInfo.fromJsonObject(jsonObject["geo_location"]) : undefined,
            jsonObject["description"] ? jsonObject["description"] : undefined,
            jsonObject.hasOwnProperty("device_definition_version")
                ? DeviceDefinitionVersionInfo.fromJsonObject(jsonObject["device_definition_version"])
                : undefined
        );
    }

    /**
     * Returns whether given row is any different in details than current trace info.
     * Shallow comparison, in other words.
     * @param other The row to compare with.
     */
    public isDifferentFrom(other: TableRowData): boolean {
        // Row must of of type trace info.
        if (!(other instanceof TraceInfo)) {
            return true;
        }

        let otherTraceInfo = other as TraceInfo;

        // Comparing base trace properties.
        if (
            otherTraceInfo.traceId !== this.traceId ||
            otherTraceInfo.deviceId !== this.deviceId ||
            otherTraceInfo.created !== this.created ||
            otherTraceInfo.ipAddress !== this.ipAddress ||
            otherTraceInfo.severity !== this.severity
        ) {
            return true;
        }

        // Comparing arguments existence.
        if (
            (!otherTraceInfo.traceArguments && this.traceArguments) ||
            (otherTraceInfo.traceArguments && !this.traceArguments)
        ) {
            return true;
        }

        // Comparing the arguments themselves.
        if (otherTraceInfo.traceArguments && this.traceArguments) {
            let otherArgumentKeys = Utils.getMapKeys(otherTraceInfo.traceArguments);
            let thisArgumentKeys = Utils.getMapKeys(this.traceArguments);

            // Comparing arguments length.
            if (otherArgumentKeys.length !== thisArgumentKeys.length) {
                return true;
            }

            // Going through the other arguments.
            for (let i = 0; i < otherArgumentKeys.length; i++) {
                let otherArgumentKey = otherArgumentKeys[i];

                // If this doesn't have other argument key.
                if (!this.traceArguments[otherArgumentKey]) {
                    return true;
                }

                // If they are any different.
                if (
                    otherTraceInfo.traceArguments[otherArgumentKey].isDifferentFrom(
                        this.traceArguments[otherArgumentKey]
                    )
                ) {
                    return true;
                }
            }

            // Going through this arguments.
            for (let i = 0; i < thisArgumentKeys.length; i++) {
                let thisArgumentKey = thisArgumentKeys[i];

                // If other doesn't have this argument key.
                if (!otherTraceInfo.traceArguments[thisArgumentKey]) {
                    return true;
                }

                // If they are any different.
                if (
                    this.traceArguments[thisArgumentKey].isDifferentFrom(otherTraceInfo.traceArguments[thisArgumentKey])
                ) {
                    return true;
                }
            }
        }

        return false;
    }

    /**
     * Gets the value for the given column id.
     */
    public getColumnValue(columnHeaderId: string): any {
        return this[columnHeaderId];
    }

    /**
     * Gets the display name of the device status.
     */
    public getSeverityDisplay(): string {
        return TraceInfo.getSeverityDisplay(this.severity);
    }

    /**
     * Sets the device for the trace.
     */
    public setDevice(device: DeviceInfo): void {
        this.device = device;
    }

    /**
     * Gets the display name of given trace severity.
     */
    public static getSeverityDisplay(traceSeverity: TraceSeverity): string {
        switch (traceSeverity) {
            case TraceSeverity.HIGH:
                return "High";

            case TraceSeverity.MEDIUM:
                return "Medium";

            case TraceSeverity.NORMAL:
                return "Normal";

            default:
                return "Unknown";
        }
    }

    /**
     * Gets the display name of given trace severity.
     */
    public static extractSeverity(severity: string): TraceSeverity {
        switch (severity) {
            case "high":
                return TraceSeverity.HIGH;
            case "medium":
                return TraceSeverity.MEDIUM;
            case "normal":
                return TraceSeverity.NORMAL;
        }
    }

    /**
     * Returns whether the severity of trace is high.
     */
    public isSeverityHigh(): boolean {
        return this.severity === TraceSeverity.HIGH;
    }

    /**
     * Returns whether the severity of trace is medium.
     */
    public isSeverityMedium(): boolean {
        return this.severity === TraceSeverity.MEDIUM;
    }

    /**
     * Returns whether the severity of trace is low.
     */
    public isSeverityNormal(): boolean {
        return this.severity === TraceSeverity.NORMAL;
    }

    /**
     * Gets the display value for the originating code address argument.
     */
    public getOriginatingCodeAddressDisplay() {
        if (this.traceArguments["ARG_ROLE_NAME"]) {
            return this.traceArguments["ARG_ROLE_NAME"].displayValue;
        } else {
            if (this.traceArguments["SYSTEM_ARG_ROLE_ORIGINATING_CODE_ADDRESS"]) {
                return this.traceArguments["SYSTEM_ARG_ROLE_ORIGINATING_CODE_ADDRESS"].displayValue;
            } else {
                return "Unknown";
            }
        }
    }

    public getFunctionName() {
        if (this.traceArguments["ARG_ROLE_FUNCTION_NAME"]) {
            let argumentRoleTypeConfiguration = SternumConfiguration.getArgumentRoleTypeConfigurationObject(
                "ARG_ROLE_FUNCTION_NAME",
                this.traceEventType
            );

            if (argumentRoleTypeConfiguration && argumentRoleTypeConfiguration.extractValue) {
                return argumentRoleTypeConfiguration.extractValue(
                    this.traceArguments["ARG_ROLE_FUNCTION_NAME"].argumentValue
                );
            } else {
                return this.traceArguments["ARG_ROLE_FUNCTION_NAME"].displayValue;
            }
        } else {
            return null;
        }
    }

    /**
     * Gets the display value for the fault address argument.
     */
    public getFaultAddressDisplay() {
        if (this.traceArguments["SYSTEM_ARG_ROLE_FAULT_ADDRESS"]) {
            let argumentRoleTypeConfiguration = SternumConfiguration.getArgumentRoleTypeConfigurationObject(
                "SYSTEM_ARG_ROLE_FAULT_ADDRESS",
                this.traceEventType
            );

            if (argumentRoleTypeConfiguration && argumentRoleTypeConfiguration.extractValue) {
                return argumentRoleTypeConfiguration.extractValue(
                    this.traceArguments["SYSTEM_ARG_ROLE_FAULT_ADDRESS"].argumentValue
                );
            } else {
                return this.traceArguments["SYSTEM_ARG_ROLE_FAULT_ADDRESS"].displayValue;
            }
        } else {
            return "Unknown";
        }
    }

    public getProcessName = (): string => {
        return this.traceArguments[PROCESS_KEY_NAME]?.argumentValue;
    };

    /**
     * Indicate if given trace is an attack attempt
     */

    public isAttackAttempt = (): boolean => {
        return (
            this.traceDefinition &&
            this.traceDefinition.traceEventName === "EXPLOITATION_ALERT" &&
            Boolean(this.traceArguments["EXPLOITATION_TYPE"])
        );
    };

    /**
     * Iterate over trace arguments and create a copy content
     */
    public getArguments() {
        let argumentsContent = this.orderedTraceArguments.map((argumentInfo) => {
            const argumentRoleTypeConfiguration = SternumConfiguration.getArgumentRoleTypeConfigurationObject(
                argumentInfo.argumentDefinition.argumentEventName,
                this.traceDefinition.traceEventName
            );

            if (!argumentRoleTypeConfiguration || argumentRoleTypeConfiguration.displayInArgumentsSummary) {
                let argumentValue = argumentInfo.displayValue;
                if (argumentRoleTypeConfiguration && argumentRoleTypeConfiguration.extractValue) {
                    argumentValue = argumentRoleTypeConfiguration.extractValue(argumentInfo.argumentValue);
                }

                const argumentDisplayName = argumentRoleTypeConfiguration
                    ? argumentRoleTypeConfiguration.getDisplayName(this)
                    : argumentInfo.argumentDefinition.displayName;

                const fullDisplay = `${argumentDisplayName}: ${argumentValue}`;

                return fullDisplay;
            }
        });
        return argumentsContent;
    }
}

export default TraceInfo;
