import React from "react";
import { Typography } from "@material-ui/core";
import { useStyles } from "./StepNumber.style";
import { CheckFilledIcon } from "../../../../SUI/SternumIcon";

interface StepNumberProps {
    orderNumber: number;
    completed: boolean;
}

export function StepNumber({ orderNumber, completed }: StepNumberProps) {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            {completed ? (
                <CheckFilledIcon />
            ) : (
                <div className={classes.numberBox}>
                    <Typography className={classes.number}>{orderNumber}</Typography>
                </div>
            )}
        </div>
    );
}
