import { WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { last } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import HashSet from "../../lib/infra/HashSet";
import SternumConfiguration from "../../lib/infra/SternumConfiguration";
import WebUtils from "../../lib/infra/WebUtils";
import AnalyticsService from "../../lib/services/AnalyticsService";
import DebounceService from "../../lib/services/DebounceService";
import ServiceWire from "../../lib/services/ServiceWire";
import DeviceDefinitionInfo from "../../lib/state/DeviceDefinitionInfo";
import GetSternumDeviceEventsResponse from "../../lib/state/GetSternumDeviceEventsResponse";
import { GlobalState } from "../../lib/state/GlobalState";
import HttpResponse from "../../lib/state/HttpResponse";
import ListFilter from "../../lib/state/ListFilter";
import PollingChangeType from "../../lib/state/PollingChangeType";
import ProcessTracesInfo from "../../lib/state/ProcessTracesInfo";
import SternumDeviceEventInfo from "../../lib/state/SternumDeviceEventInfo";
import SternumDeviceEventsFilter from "../../lib/state/SternumDeviceEventsFilter";
import TableColumnHeaderInfo from "../../lib/state/TableColumnHeaderInfo";
import TableToolbarDisplayState from "../../lib/state/TableToolbarDisplayState";
import ProcessTracesDisplay from "../ProcessTracesDisplay/ProcessTracesDisplay";
import SternumTable from "../SUI/SternumTable/SternumTable";
import SternumDeviceProcessesListStyle from "./SternumDeviceProcessesListStyle";

import moment from "moment";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    // Loading & error state.
    loadingEntities: boolean;
    errorLoadingEntities: boolean;

    // Entities.
    entities: SternumDeviceEventInfo[];
    totalItemCount: number;
    currentPage: number;

    doNotDisplayLoading: boolean;

    // Filtering.
    entitiesFilter: SternumDeviceEventsFilter;
    searchText: string;
    orderByField: string;
    order: "asc" | "desc";
    showInfinityLoader: boolean;
    displayLoadingIcon: boolean;

    expandedRowsSet: HashSet;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof SternumDeviceProcessesListStyle> {
    sideBarOpen: boolean;
    location;
    history;
    hideUpperBorder?: boolean;
    shouldNoWrapDisplayName: boolean;

    deviceDefinitionVersionId?: string;

    entityId: string;
    columnWidthsArray?: number[];

    deviceDefinition: DeviceDefinitionInfo;
    toolbarState?: TableToolbarDisplayState;

    includeColumns?: string[];
    paperClassNames?;
    viewedColumnsSet?: HashSet;

    doNotDisplayLoading?: boolean;
    infiniteScroll?: boolean;

    doNotDisplayExploitationTypeInDisplayName?: boolean;

    amountOfLoadingPlaceholders?: number;

    preloadedEntities: SternumDeviceEventInfo[];
    entitiesFilter: SternumDeviceEventsFilter;
    refreshEntitiesFilter?: boolean;
    displayTypeColumn: boolean;

    onNewVisualizationClicked?: () => void;
    onExistingVisualisationClicked?: (visualisationId: string) => void;

    showAllColumns?: boolean;

    displayXButtonInTraceView: boolean;
    displayBackButtonInTraceView: boolean;
    shouldDisplayLinkToDeviceView?: boolean;
    hideToolbar?: boolean;
    onFiltersChanged?: (sternumDeviceEventsFilter: SternumDeviceEventsFilter) => void;

    emptyTableMessage: string;

    isDeviceView?: boolean;

    indexEventTime?: number;

    isTableExpanded?: boolean;
    onTableExpandToggle?: () => void;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {};
};

/**
 * Holds the entities list in the app.
 */
class SternumDeviceProcessesList extends React.Component<AppProps, AppState> {
    /**
     * Defines the column headers participating in the entities table.
     */
    private readonly columnHeaders: TableColumnHeaderInfo[] = [
        new TableColumnHeaderInfo("processName", "Process", false, true, false, false),
        new TableColumnHeaderInfo("totalTraces", "Total Traces", false, true, false, false),
    ];

    /**
     * Gets the display values for row's columns (where they aren't the default).
     */
    private getRowDisplayValues(row) {
        let entity = row as ProcessTracesInfo;
        const { classes } = this.props;

        let processName = entity.processName;
        const totalTraces = entity.totalTraces;

        if (processName.includes("/")) {
            processName = last(processName.split("/"));
        }

        return {
            created: (
                <div className={classNames(classes.flexVMiddle)}>
                    <Typography variant="body2" className={classNames(classes.marginLeftXs)}>
                        {moment(entity.created).format("MM/DD HH:mm:ss")}
                    </Typography>
                </div>
            ),

            processName: (
                <Typography variant="body2" className={classNames(classes.marginLeftXs)}>
                    {processName}
                </Typography>
            ),

            totalTraces: (
                <Typography variant="body2" className={classNames(classes.marginLeftXs)}>
                    {totalTraces}
                </Typography>
            ),

            expandedContent: (
                <ProcessTracesDisplay
                    bootEvent={entity}
                    entityId={this.props.entityId}
                    processName={entity.processName}
                    entitiesFilter={this.props.entitiesFilter}
                    searchText={this.state.searchText}
                    isInfinityScrollOn
                />
            ),
        };
    }

    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        if (this.props.includeColumns) {
            if (this.props.includeColumns.length) {
                let includeColumnsSet = HashSet.fromValues(this.props.includeColumns);
                this.columnHeaders = this.columnHeaders.filter((columnHeader) =>
                    includeColumnsSet.exists(columnHeader.id)
                );
            } else {
                this.columnHeaders = [];
            }
        }

        if (this.props.showAllColumns) {
            for (let i = 0; i < this.columnHeaders.length; i++) {
                this.columnHeaders[i].isHidden = false;
            }
        }

        // Initializing the state to default.
        this.state = {
            loadingEntities: false,
            errorLoadingEntities: false,

            entities: [],
            currentPage: 1,

            doNotDisplayLoading: this.props.doNotDisplayLoading,

            entitiesFilter: this.props.entitiesFilter,
            searchText: null,
            orderByField: null,
            order: "desc",

            totalItemCount: null,
            showInfinityLoader: false,
            displayLoadingIcon: false,

            expandedRowsSet: new HashSet(),
        };
    }

    /**
     * Occurs once the component is about to receive props.
     */
    UNSAFE_componentWillReceiveProps(nextProps: Readonly<AppProps>, nextContext: any): void {
        let hasChangeInFilters =
            (!nextProps.entitiesFilter && this.props.entitiesFilter) ||
            (nextProps.entitiesFilter && !this.props.entitiesFilter) ||
            (nextProps.entitiesFilter &&
                this.props.entitiesFilter &&
                nextProps.entitiesFilter.isDifferentFrom(this.props.entitiesFilter));

        if (hasChangeInFilters || nextProps.refreshEntitiesFilter) {
            this.handleEntitiesFilterChanged(
                nextProps.entitiesFilter,
                nextProps.doNotDisplayLoading,
                nextProps.indexEventTime
            );
        }
    }

    /**
     * Occurs once the component finished its initialization process.
     */
    async componentDidMount() {
        await this.loadEntities(1);
    }

    /**
     * Occurs once the component is being destroyed.
     */
    componentWillUnmount(): void {
        ServiceWire.getPollingService().unsubscribe("SternumDeviceProcessesList", PollingChangeType.EVENTS_INDEXED);
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        return (
            <SternumTable
                hideUpperBorder={this.props.hideUpperBorder}
                isTableExpanded={this.props.isTableExpanded}
                onTableExpandToggle={this.props.onTableExpandToggle}
                columnWidthsArray={this.props.columnWidthsArray || [90, 10]}
                emptyComponent={
                    <div className={classNames(classes.flexCenter, classes.padding)}>
                        <Typography variant="body2">{this.props.emptyTableMessage}</Typography>
                    </div>
                }
                toolbarState={this.props.toolbarState}
                viewedColumnsSet={this.props.viewedColumnsSet}
                onNewVisualizationClicked={this.props.onNewVisualizationClicked}
                onExistingVisualisationClicked={this.props.onExistingVisualisationClicked}
                onExportToCsvClicked={() => this.onExportToCsvClicked()}
                hideToolbar={this.props.hideToolbar}
                pageSize={SternumConfiguration.getPageSize()}
                amountOfLoadingPlaceholders={this.props.amountOfLoadingPlaceholders || 9}
                totalItemCount={this.state.totalItemCount}
                loadingItems={this.state.loadingEntities}
                errorLoadingItems={this.state.errorLoadingEntities}
                columnHeaders={this.columnHeaders}
                listFilter={this.state.entitiesFilter}
                onRefreshClicked={() => this.onRefreshClicked()}
                orderByField={this.state.orderByField}
                order={this.state.order}
                rows={this.state.entities}
                onFilterChanged={(listFilter) =>
                    this.handleEntitiesFilterChanged(listFilter, this.state.doNotDisplayLoading)
                }
                onSearchTextChanged={(searchText) => this.handleSearchTextChanged(searchText)}
                onPageChanged={(pageNumber) => this.loadEntities(pageNumber)}
                onRowClicked={(row: SternumDeviceEventInfo) => {
                    this.handleRowExpand(row, !this.state.expandedRowsSet.exists(row.entityId));
                }}
                onOrderChanged={(orderByField, order) => this.handleOrderChanged(orderByField, order)}
                getRowValues={(row) => this.getRowDisplayValues(row)}
                onRowExpand={this.handleRowExpand}
                maxTableHeightClass={this.props.paperClassNames}
                isInfinityScrollOn={this.props.infiniteScroll}
                onScrollChanged={(pageNumber) => this.loadEntitiesForScroll(pageNumber)}
                showInfinityLoader={this.state.showInfinityLoader}
                pageNumber={this.state.currentPage}
                displayLoadingIcon={this.state.displayLoadingIcon}
                expandableRows
                expandedRowsSet={this.state.expandedRowsSet}
            />
        );
    }

    /**
     * Occurs when row is expanded/closed
     */
    private handleRowExpand = (row: SternumDeviceEventInfo, isExpanded: boolean) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                // displayLoadingIcon: isExpanded,
                // expand row if its closed / close if expanded
                expandedRowsSet: isExpanded
                    ? HashSet.copyAndAdd(prevState.expandedRowsSet, row.entityId)
                    : HashSet.copyAndRemove(prevState.expandedRowsSet, row.entityId),
            };
        });
    };

    /**
     * Occurs once the filters of the list are changed.
     */
    private handleEntitiesFilterChanged(
        entitiesFilter: ListFilter,
        doNotDisplayLoading: boolean,
        eventIndexTime?: number
    ) {
        const sternumDeviceEventsFilter: SternumDeviceEventsFilter =
            entitiesFilter != null ? (entitiesFilter as SternumDeviceEventsFilter) : null;

        // Don't update if it's new data event and we are not in the first page
        if (
            !!this.props.infiniteScroll &&
            this.state.currentPage > 1 &&
            this.props.indexEventTime &&
            eventIndexTime &&
            eventIndexTime > this.props.indexEventTime
        ) {
            return;
        }

        this.setState(
            {
                doNotDisplayLoading: doNotDisplayLoading,
                entitiesFilter: sternumDeviceEventsFilter,
            },
            async () => {
                await this.loadEntities(1);

                if (this.props.onFiltersChanged) {
                    this.props.onFiltersChanged(sternumDeviceEventsFilter);
                }
            }
        );
    }

    /**
     * Occurs once search text is changed.
     */
    private handleSearchTextChanged(searchText) {
        // Debounce request to search entities.
        DebounceService.debounce(
            "getDeviceSternumDeviceProcesses",
            () => {
                this.setState({ searchText }, () => this.loadEntities(1, false));
            },
            350
        );
    }

    /**
     * Occurs once sort order is changed.
     */
    private handleOrderChanged(orderByField, order) {
        this.setState(
            {
                orderByField: orderByField,
                order: order,
            },
            () => this.loadEntities(1)
        );
    }

    /**
     * Occurs once the user refreshes the list.
     */
    private async onRefreshClicked() {
        await this.loadEntities(1);
    }

    /**
     * Loads entities.
     */
    private async loadEntities(pageNumber, doNotDisplayLoading = true) {
        try {
            let entities = [];
            let totalItemCount = 0;

            // Setting loading to true.
            this.setState({
                loadingEntities: !doNotDisplayLoading || !this.props.doNotDisplayLoading,
                errorLoadingEntities: false,
                showInfinityLoader: false,
                totalItemCount:
                    !doNotDisplayLoading && !this.props.doNotDisplayLoading ? null : this.state.totalItemCount,
            });

            // Fetching entities.
            if (this.props.preloadedEntities) {
                entities = this.props.preloadedEntities;
            } else {
                let getSternumDeviceEventsResponse: GetSternumDeviceEventsResponse = await this.fetchEntities(
                    pageNumber
                );
                entities = getSternumDeviceEventsResponse.sternumDeviceEvents;
                totalItemCount = getSternumDeviceEventsResponse.totalItemCount;
            }
            this.setState({
                loadingEntities: false,
                errorLoadingEntities: false,
                entities: [...entities],
                totalItemCount: totalItemCount,
                currentPage: pageNumber,
            });
        } catch (error) {
            AnalyticsService.error("SternumDeviceProcessesList:loadEntities", error.message);

            this.setState({
                loadingEntities: false,
                errorLoadingEntities: true,
            });
        }
    }

    /**
     * Loads entities with concat operation to existing entities.
     */
    private async loadEntitiesForScroll(pageNumber) {
        try {
            let entities = [];
            let totalItemCount = 0;
            let getSternumDeviceEventsResponse: GetSternumDeviceEventsResponse = null;
            // Setting loading to true.
            this.setState({
                errorLoadingEntities: false,
            });

            // Fetching entities.
            if (pageNumber == 1) {
                // Reload data to current date
                this.state.entitiesFilter.createdTo = moment().toDate().getTime();
                getSternumDeviceEventsResponse = await this.fetchEntities(pageNumber);
                entities = getSternumDeviceEventsResponse.sternumDeviceEvents;
            } else {
                // Load given page
                this.setState({ showInfinityLoader: true });
                getSternumDeviceEventsResponse = await this.fetchEntities(pageNumber);
                entities = this.state.entities.concat(getSternumDeviceEventsResponse.sternumDeviceEvents);
            }

            totalItemCount = getSternumDeviceEventsResponse.totalItemCount;

            this.setState({
                errorLoadingEntities: false,
                entities: [...entities],
                totalItemCount: totalItemCount,
                showInfinityLoader: false,
                currentPage: pageNumber,
            });
        } catch (error) {
            AnalyticsService.error("SternumDeviceProcessesList:loadEntitiesForScroll", error.message);

            this.setState({
                showInfinityLoader: false,
                errorLoadingEntities: true,
            });
        }
    }

    /**
     * Api call for entities.
     */
    private async fetchEntities(pageNumber: number): Promise<GetSternumDeviceEventsResponse> {
        return await ServiceWire.getSternumService().getDeviceSternumDevicProcesses(
            this.props.entityId,
            this.props.entitiesFilter,
            this.state.searchText,
            (pageNumber - 1) * SternumConfiguration.getPageSize(),
            SternumConfiguration.getPageSize()
        );
    }

    /**
     * Occurs when the export to csv button clicked.
     */
    private async onExportToCsvClicked() {
        this.setState({ displayLoadingIcon: true });
        const serverResponse: HttpResponse = await ServiceWire.getSternumService().exportSternumEvents(
            this.props.entityId,
            this.state.entitiesFilter,
            this.state.searchText,
            this.state.order,
            [],
            null,
            null,
            this.props.deviceDefinitionVersionId
        );

        this.setState({ displayLoadingIcon: false });

        // Download the report.
        WebUtils.downloadReport(serverResponse);
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(SternumDeviceProcessesListStyle)(SternumDeviceProcessesList)));
