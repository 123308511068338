import { Action } from "redux";
import ActionType from "../ActionType";
import UsedLibraryInfo from "../../state/UsedLibraryInfo";

/**
 * Occurs once a used library has been successfully fetched from API.
 */
export interface FetchedUsedLibrarySuccessfullyAction extends Action<ActionType> {
    type: ActionType.FetchedUsedLibrarySuccessfully;
    usedLibrary: UsedLibraryInfo;
}

/**
 * Occurs once a used library has been successfully fetched from API.
 */
export function fetchedUsedLibrarySuccessfullyAction(
    usedLibrary: UsedLibraryInfo
): FetchedUsedLibrarySuccessfullyAction {
    return {
        type: ActionType.FetchedUsedLibrarySuccessfully,
        usedLibrary: usedLibrary,
    };
}
