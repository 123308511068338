import { useEffect, useState } from "react";

import VisualisationInfo from "../../../../lib/state/Visualisation/VisualisationInfo";
import ConfigurationService from "../../../../lib/services/ConfigurationService";
import DeviceInfo from "../../../../lib/state/DeviceInfo";
import DeviceDefinitionInfo from "../../../../lib/state/DeviceDefinitionInfo";
import DeviceDefinitionVersionInfo from "../../../../lib/state/DeviceDefinitionVersionInfo";
import ServiceWire from "../../../../lib/services/ServiceWire";
import VisualisationType from "../../../../lib/state/Visualisation/VisualisationType";
import ReceivedDefinitionsResponse from "../../../../lib/services/events/ReceivedDefinitionsResponse";
import { VisualisationDataSourceGroupByCustomFields } from "../../../../lib/state/Visualisation/VisualisationConfigurationGroupBy";

export interface UseGlancesGridDataArgs {
    device: DeviceInfo;
    startDate: Date;
    endDate: Date;
    refreshEntitiesFilter?: boolean;
}

export const useGlancesGridData = ({ device, startDate, endDate, refreshEntitiesFilter }: UseGlancesGridDataArgs) => {
    const [deviceDefinitionVersion, setDeviceDefinitionVersion] = useState<DeviceDefinitionVersionInfo>();
    const [receivedDefinitions, setReceivedDefinitions] = useState<ReceivedDefinitionsResponse>();

    function getReceivedDefinitionTraceByName(name: string) {
        return receivedDefinitions?.receivedDefinitions.find((traceDefinition) => traceDefinition.displayName === name);
    }

    function getReceivedDefinitionArgumentByName(name: string) {
        return receivedDefinitions?.receivedArguments.find(
            (argumentDefinition) => argumentDefinition.displayName === name
        );
    }

    useEffect(() => {
        new Promise(async () => {
            const deviceDefinitionVersion: DeviceDefinitionVersionInfo =
                await ServiceWire.getSternumService().getDeviceDefinitionVersion(device.lastSeenVersionId);

            setDeviceDefinitionVersion(deviceDefinitionVersion);
        }).then();
    }, [device.lastSeenVersionId]);

    useEffect(() => {
        new Promise(async () => {
            const receivedDefinitionsResponse = await ServiceWire.getEventsApiService().getAllReceivedDefinitions(
                device.lastSeenVersionId,
                startDate.getTime(),
                endDate.getTime()
            );

            setReceivedDefinitions(receivedDefinitionsResponse);
        }).then();
    }, [device.lastSeenVersionId, startDate, endDate]);

    const allEventsTrace = ConfigurationService.getAllEventsFilterField();
    const allAlertsTrace = ConfigurationService.getAllAlertsFilterField();
    const attackAttemptTrace = getReceivedDefinitionTraceByName("Attack Attempt");
    const bootTrace = getReceivedDefinitionTraceByName("Boot");
    const uptimeTrace = getReceivedDefinitionTraceByName("Uptime");
    const processListInformationTrace = getReceivedDefinitionTraceByName("Process List Information");
    const storageReportTrace = getReceivedDefinitionTraceByName("Storage Report");

    const deviceArgument = ConfigurationService.getDeviceIdArgumentField();
    const categoryGroupByArgument = VisualisationDataSourceGroupByCustomFields.TRACE_CATEGORY;
    const uptimeArgument = getReceivedDefinitionArgumentByName("Uptime");
    const downtimeArgument = getReceivedDefinitionArgumentByName("Downtime");
    const memoryUsageArgument = getReceivedDefinitionArgumentByName("Memory Usage");
    const cpuUsageArgument = getReceivedDefinitionArgumentByName("CPU Usage");
    const usedPercentageArgument = getReceivedDefinitionArgumentByName("Used Percentage");

    const deviceFilter = {
        argument_definition_id: deviceArgument.id,
        filter_condition: "EQUALS",
        value: device.receivedDeviceId,
        display_name: deviceArgument.displayName,
    };

    if (!receivedDefinitions) {
        return { deviceDefinitionVersion, securityGlances: [], performanceGlances: [], operationalGlances: [] };
    }

    const securityGlances: VisualisationInfo[] = [
        VisualisationInfo.fromJsonObject({
            entity_id: "VISU1",
            entity_type: "VISUALIZATION",
            created: Date.now(),
            updated: Date.now(),
            display_name: "Attacks Over Time",
            configuration: {
                visualisation_type: VisualisationType.TIME_SERIES,
                data_sources: [
                    {
                        data_source_key: "DEFAULT",
                        device_definition_version_id: [device.lastSeenVersionId],
                        sternum_query: {
                            query_type: "AND",
                            filters: [deviceFilter],
                            queries: [],
                        },
                        aggregation_function: "COUNT",
                        aggregation_context: "TRACES",
                        aggregation_fields: null,
                        trace_definition_id: attackAttemptTrace?.identifier,
                        trace_definition_display_name: attackAttemptTrace?.displayName,
                        argument_definition_id: "",
                        argument_definition_display_name: "",
                        percentile: 95,
                        color: "COLOR_A",
                    },
                ],
                group_by: null,
            },
            stacked: false,
            logarithmic_scale: false,
        }),
        VisualisationInfo.fromJsonObject({
            entity_id: "VISU2",
            entity_type: "VISUALIZATION",
            created: Date.now(),
            updated: Date.now(),
            display_name: "Alerts Over Time",
            configuration: {
                visualisation_type: VisualisationType.TIME_SERIES,
                data_sources: [
                    {
                        data_source_key: "DEFAULT",
                        device_definition_version_id: [device.lastSeenVersionId],
                        sternum_query: {
                            query_type: "AND",
                            filters: [deviceFilter],
                            queries: [],
                        },
                        aggregation_function: "COUNT",
                        aggregation_context: "TRACES",
                        aggregation_fields: null,
                        trace_definition_id: allAlertsTrace.id,
                        trace_definition_display_name: allAlertsTrace.displayName,
                        argument_definition_id: "",
                        argument_definition_display_name: "",
                        percentile: 95,
                        color: "COLOR_A",
                    },
                ],
                group_by: null,
            },
            stacked: false,
            logarithmic_scale: false,
        }),
        VisualisationInfo.fromJsonObject({
            entity_id: "VISU3",
            entity_type: "VISUALIZATION",
            created: Date.now(),
            updated: Date.now(),
            display_name: "Events by Category",
            configuration: {
                visualisation_type: VisualisationType.VALUES_GRAPH,
                data_sources: [
                    {
                        data_source_key: "DEFAULT",
                        device_definition_version_id: [device.lastSeenVersionId],
                        sternum_query: {
                            query_type: "AND",
                            filters: [deviceFilter],
                            queries: [],
                        },
                        aggregation_function: "COUNT",
                        aggregation_context: "TRACES",
                        aggregation_fields: null,
                        trace_definition_id: allEventsTrace.id,
                        trace_definition_display_name: allEventsTrace.displayName,
                        argument_definition_id: "",
                        argument_definition_display_name: "",
                        percentile: 95,
                        color: "COLOR_A",
                    },
                ],
                group_by: {
                    field: categoryGroupByArgument,
                    limit: 10,
                    order_by: "TOP",
                    aggregation_function: "COUNT",
                    aggregation_context: "TRACES",
                    device_definition_version_ids: [],
                    argument_definition_id: null,
                    trace_definition_ids: [],
                    enabled: true,
                },
            },
            stacked: false,
            logarithmic_scale: false,
        }),
    ];

    const upDownTimeGlance = VisualisationInfo.fromJsonObject({
        entity_id: "VISU3",
        entity_type: "VISUALIZATION",
        created: Date.now(),
        updated: Date.now(),
        display_name: "Down/Up Time",
        configuration: {
            visualisation_type: VisualisationType.PIE_CHART,
            data_sources: [
                {
                    data_source_key: "DEFAULT",
                    device_definition_version_id: [device.lastSeenVersionId],
                    sternum_query: {
                        query_type: "AND",
                        filters: [deviceFilter],
                        queries: [],
                    },
                    aggregation_function: "SUM",
                    aggregation_context: "TRACES",
                    aggregation_fields: null,
                    trace_definition_id: uptimeTrace?.identifier,
                    trace_definition_display_name: uptimeTrace?.displayName,
                    argument_definition_id: uptimeArgument?.identifier,
                    argument_definition_display_name: uptimeArgument?.displayName,
                    percentile: 95,
                    color: "COLOR_A",
                },
                {
                    data_source_key: "1",
                    device_definition_version_id: [device.lastSeenVersionId],
                    sternum_query: {
                        query_type: "AND",
                        filters: [deviceFilter],
                        queries: [],
                    },
                    aggregation_function: "SUM",
                    aggregation_context: "TRACES",
                    aggregation_fields: null,
                    trace_definition_id: uptimeTrace?.identifier,
                    trace_definition_display_name: uptimeTrace?.displayName,
                    argument_definition_id: downtimeArgument?.identifier,
                    argument_definition_display_name: downtimeArgument?.displayName,
                    percentile: 95,
                    color: "COLOR_B",
                },
            ],
            group_by: null,
        },
        stacked: false,
        logarithmic_scale: false,
    });
    upDownTimeGlance.configuration.dataSources[0].dataSourceLabel = `Up Time`;
    upDownTimeGlance.configuration.dataSources[0].graphLabel = `Up Time`;
    upDownTimeGlance.configuration.dataSources[1].dataSourceLabel = `Down Time`;
    upDownTimeGlance.configuration.dataSources[1].graphLabel = `Down Time`;

    const performanceGlances: VisualisationInfo[] = [
        VisualisationInfo.fromJsonObject({
            entity_id: "VISU1",
            entity_type: "VISUALIZATION",
            created: Date.now(),
            updated: Date.now(),
            display_name: "Memory",
            configuration: {
                visualisation_type: VisualisationType.TIME_SERIES,
                data_sources: [
                    {
                        data_source_key: "DEFAULT",
                        device_definition_version_id: [device.lastSeenVersionId],
                        sternum_query: {
                            query_type: "AND",
                            filters: [deviceFilter],
                            queries: [],
                        },
                        aggregation_function: "MAX",
                        aggregation_context: "TRACES",
                        aggregation_fields: null,
                        trace_definition_id: processListInformationTrace?.identifier,
                        trace_definition_display_name: processListInformationTrace?.displayName,
                        argument_definition_id: memoryUsageArgument?.identifier,
                        argument_definition_display_name: memoryUsageArgument?.displayName,
                        percentile: 95,
                        color: "COLOR_A",
                    },
                ],
                group_by: null,
            },
            stacked: false,
            logarithmic_scale: false,
        }),
        VisualisationInfo.fromJsonObject({
            entity_id: "VISU2",
            entity_type: "VISUALIZATION",
            created: Date.now(),
            updated: Date.now(),
            display_name: "CPU",
            configuration: {
                visualisation_type: VisualisationType.TIME_SERIES,
                data_sources: [
                    {
                        data_source_key: "DEFAULT",
                        device_definition_version_id: [device.lastSeenVersionId],
                        sternum_query: {
                            query_type: "AND",
                            filters: [deviceFilter],
                            queries: [],
                        },
                        aggregation_function: "MAX",
                        aggregation_context: "TRACES",
                        aggregation_fields: null,
                        trace_definition_id: processListInformationTrace?.identifier,
                        trace_definition_display_name: processListInformationTrace?.displayName,
                        argument_definition_id: cpuUsageArgument?.identifier,
                        argument_definition_display_name: cpuUsageArgument?.displayName,
                        percentile: 95,
                        color: "COLOR_A",
                    },
                ],
                group_by: null,
            },
            stacked: false,
            logarithmic_scale: false,
        }),
        VisualisationInfo.fromJsonObject({
            entity_id: "VISU3",
            entity_type: "VISUALIZATION",
            created: Date.now(),
            updated: Date.now(),
            display_name: "Disk Usage",
            configuration: {
                visualisation_type: VisualisationType.TIME_SERIES,
                data_sources: [
                    {
                        data_source_key: "DEFAULT",
                        device_definition_version_id: [device.lastSeenVersionId],
                        sternum_query: {
                            query_type: "AND",
                            filters: [deviceFilter],
                            queries: [],
                        },
                        aggregation_function: "MAX",
                        aggregation_context: "TRACES",
                        aggregation_fields: null,
                        trace_definition_id: storageReportTrace?.identifier,
                        trace_definition_display_name: storageReportTrace?.displayName,
                        argument_definition_id: usedPercentageArgument?.identifier,
                        argument_definition_display_name: usedPercentageArgument?.displayName,
                        percentile: 95,
                        color: "COLOR_A",
                    },
                ],
                group_by: null,
            },
            stacked: false,
            logarithmic_scale: false,
        }),
    ];

    const operationalGlances: VisualisationInfo[] = [
        VisualisationInfo.fromJsonObject({
            entity_id: "VISU1",
            entity_type: "VISUALIZATION",
            created: Date.now(),
            updated: Date.now(),
            display_name: "Reboots over time",
            configuration: {
                visualisation_type: VisualisationType.TIME_SERIES,
                data_sources: [
                    {
                        data_source_key: "DEFAULT",
                        device_definition_version_id: [device.lastSeenVersionId],
                        sternum_query: {
                            query_type: "AND",
                            filters: [deviceFilter],
                            queries: [],
                        },
                        aggregation_function: "COUNT",
                        aggregation_context: "TRACES",
                        aggregation_fields: null,
                        trace_definition_id: bootTrace?.identifier,
                        trace_definition_display_name: bootTrace?.displayName,
                        argument_definition_id: "",
                        argument_definition_display_name: "",
                        percentile: 95,
                        color: "COLOR_A",
                    },
                ],
                group_by: null,
            },
            stacked: false,
            logarithmic_scale: false,
        }),
        VisualisationInfo.fromJsonObject({
            entity_id: "VISU2",
            entity_type: "VISUALIZATION",
            created: Date.now(),
            updated: Date.now(),
            display_name: "Traces over time",
            configuration: {
                visualisation_type: VisualisationType.TIME_SERIES,
                data_sources: [
                    {
                        data_source_key: "DEFAULT",
                        device_definition_version_id: [device.lastSeenVersionId],
                        sternum_query: {
                            query_type: "AND",
                            filters: [deviceFilter],
                            queries: [],
                        },
                        aggregation_function: "COUNT",
                        aggregation_context: "TRACES",
                        aggregation_fields: null,
                        trace_definition_id: allEventsTrace.id,
                        trace_definition_display_name: allEventsTrace.displayName,
                        argument_definition_id: "",
                        argument_definition_display_name: "",
                        percentile: 95,
                        color: "COLOR_A",
                    },
                ],
                group_by: null,
            },
            stacked: false,
            logarithmic_scale: false,
        }),
        upDownTimeGlance,
    ];

    return { deviceDefinitionVersion, securityGlances, performanceGlances, operationalGlances };
};
