import * as React from "react";

import { WithStyles, withStyles } from "@material-ui/core/styles";
import vulnerableDevicesDataTileStyle from "./VulnerableDevicesDataTileStyle";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { GlobalState } from "../../lib/state/GlobalState";
import { CircularProgress } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import ServiceWire from "../../lib/services/ServiceWire";
import { openDevicesListModal } from "../../lib/redux/modals/OpenModalAction";
import SpinnerLoader from "../SUI/SternumLoaders/SpinnerLoader";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    loadingVulnerableDevicesCount?: boolean;
    errorLoadingVulnerableDevicesCount?: boolean;
    vulnerableDevicesCount?: number;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof vulnerableDevicesDataTileStyle> {
    openDevicesListModal?: (key: string, displayXButton: boolean, displayBackButton: boolean) => void;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {
        openDevicesListModal: (key: string, displayXButton: boolean, displayBackButton: boolean) =>
            dispatch(openDevicesListModal(key, displayXButton, displayBackButton)),
    };
};

/**
 * Displays the dashboard of sternum.
 */
class VulnerableDevicesDataTile extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {};
    }

    /**
     * Occurs once the component finished its initialization process.
     */
    async componentDidMount() {
        await this.loadVulnerableDevicesCount();
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;
        if (this.state.loadingVulnerableDevicesCount) {
            return (
                <div className={classNames(classes.flexCenter, classes.flexVMiddle, classes.flexGrow)}>
                    <SpinnerLoader />
                </div>
            );
        } else {
            return (
                <div className={classNames(classes.flexCenter, classes.flexVMiddle, classes.flexGrow)}>
                    {/* Content state */}
                    {!!this.state.vulnerableDevicesCount && (
                        <div className={classNames(classes.vulnerableDevicesNumberContainer)}>
                            <Typography
                                onClick={() => this.props.openDevicesListModal("DevicesListModal", true, false)}
                            >
                                {this.state.vulnerableDevicesCount}
                            </Typography>
                        </div>
                    )}

                    {/* No vulnerable devices state */}
                    {!this.state.vulnerableDevicesCount && (
                        <div className={classNames(classes.flexColumn, classes.flexVMiddle)}>
                            {/* Shield icon */}
                            <Icon
                                className={classNames("fa fa-shield-alt", classes.shieldIcon, classes.marginBottom)}
                                fontSize={"large"}
                                classes={{
                                    fontSizeLarge: classes.shieldIconSize,
                                }}
                            />

                            {/* Text for no vulnerable devices */}
                            <Typography variant="body2" className={classes.marginRight}>
                                All devices are up to date!
                            </Typography>
                        </div>
                    )}
                </div>
            );
        }
    }

    /**
     * Loads the count of vulnerables devices.
     */
    private async loadVulnerableDevicesCount() {
        try {
            // Setting loading to true.
            this.setState({
                loadingVulnerableDevicesCount: true,
                errorLoadingVulnerableDevicesCount: false,
            });

            // Fetching vulnerable devices count.
            let count = await ServiceWire.getSternumService().getVulnerableDevicesCount(
                ServiceWire.getClientsService().getSelectedClientId()
            );

            this.setState({
                loadingVulnerableDevicesCount: false,
                errorLoadingVulnerableDevicesCount: false,
                vulnerableDevicesCount: count,
            });
        } catch (error) {
            this.setState({
                loadingVulnerableDevicesCount: false,
                errorLoadingVulnerableDevicesCount: true,
            });
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(vulnerableDevicesDataTileStyle)(VulnerableDevicesDataTile));
