import ArgumentDefinitionInfo from "./ArgumentDefinitionInfo";
import EntityBase from "./EntityBase";
import EntityType from "./EntityType";
import TableRowData from "./TableRowData";

/**
 * Represents an argument of a track in sternum.
 */
class ArgumentInfo extends EntityBase implements TableRowData {
    /**
     * Constructor.
     */
    constructor(
        public argumentId: string,
        public traceId: string,
        public argumentValue: string,
        public argumentRoleType: string,
        public created: number,
        public updated: number,
        public displayValue: string,
        public argumentDefinition: ArgumentDefinitionInfo
    ) {
        super(argumentId, created, updated, EntityType.TraceArgument);
    }

    /**
     * Constructor.
     */
    public static fromJsonObject(jsonObject: Object) {
        // Check if it's array or string
        let argumentValue = jsonObject["argument_value"];
        argumentValue = Array.isArray(argumentValue) ? argumentValue[0] : argumentValue;
        return new ArgumentInfo(
            jsonObject["argument_id"],
            jsonObject["trace_id"],
            argumentValue,
            jsonObject["argument_role_type"],
            jsonObject["created"],
            jsonObject["updated"],
            jsonObject["display_value"],
            ArgumentDefinitionInfo.fromJsonObject(jsonObject["argument_definition"])
        );
    }

    /**
     * Returns whether given row is any different in details than current trace info.
     * Shallow comparison, in other words.
     * @param other The row to compare with.
     */
    public isDifferentFrom(other: TableRowData): boolean {
        // Row must of of type trace info.
        if (!(other instanceof ArgumentInfo)) {
            return true;
        }

        let otherArgumentInfo = other as ArgumentInfo;

        return otherArgumentInfo.argumentId !== this.argumentId || otherArgumentInfo.traceId !== this.traceId;
    }

    /**
     * Gets the value for the given column id.
     */
    public getColumnValue(columnHeaderId: string): any {
        return this[columnHeaderId];
    }
}

export default ArgumentInfo;
