import React, { useEffect, useRef, useState } from "react";
import { orderBy } from "lodash";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import { AnomaliesResponse } from "../../lib/state/Anomaly";
import { AnomalyOnboarding } from "../AnomalyOnboarding";
import { Step } from "react-joyride";
import { Typography } from "@material-ui/core";
import { demoData } from "./AnomalyChartDemoData";

export interface AnomalyChartProps {
    onAnomaliesFetched?: (response: AnomaliesResponse) => unknown;
    hideLegendAndDescription?: boolean;
    anomaliesResponse?: AnomaliesResponse;
}

am4core.useTheme(am4themes_animated);
am4core.options.autoDispose = true;

const CORNER_RADIUS = 2;
const COLUMN_WIDTH = am4core.percent(80);
// const COLUMN_WIDTH = 20;
const EXPECTED_WIDTH = am4core.percent(100);
const MAX_COLUMN_WIDTH = 20;

const CHART_MIN_HEIGHT = 350;

const SLOPE_DEGREE = 60;

const CELL_START_LOCATION = 0.45;
const CELL_END_LOCATION = 0.55;

const CELL_THIN_START_LOCATION = 0.48;
const CELL_THIN_END_LOCATION = 0.52;

const ANOMALIES_LENGTH_THRESHOLD = 3;

function degToRad(x: number) {
    return (x * Math.PI) / 180;
}

function getTextWidth(text, font) {
    // if given, use cached canvas for better performance
    // else, create new canvas
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = font;
    const metrics = context.measureText(text);

    return metrics.width;
}

/**
 * Returns cell half width based on number of visible
 * items. Cell full width lies in the range 0.0 - 1.0,
 * but usually its around 0.04 - 0.1, and this function
 * returns half of this number
 */
function getCellHalfWidth(visibleItems: number) {
    return 0.01 * visibleItems;
}

export function AnomalyChart({ onAnomaliesFetched, hideLegendAndDescription, anomaliesResponse }: AnomalyChartProps) {
    const rootRef = useRef<HTMLDivElement>(null);
    const chartRef = useRef<am4charts.XYChart | null>(null);

    const [showDemoData, setShowDemoData] = useState(false);

    const [firstStepHeight, setFirstStepHeight] = useState(300);

    const onboardingSteps: Step[] = [
        {
            target: `[data-onboarding="onboarding_anomaly_anomaly_graph_predicted_anomaly"]`,
            title: "Trace Types with Predicted Anomaly",
            content: (
                <Typography variant="body2">
                    Uncommon behavior was found in relation to these trace types. Investigate the data to narrow down
                    possible reasons.
                </Typography>
            ),
            showSkipButton: true,
            disableBeacon: true,
            disableScrolling: true,
            disableScrollParentFix: true,
            placement: "top",
            floaterProps: {
                disableFlip: true,
            },
            // styles: {
            //     tooltipContainer: {
            //         position: "absolute",
            //         top: 100,
            //     },
            // },
            styles: {
                spotlight: {
                    // transform: "scaleY(0.7) translateY(-15%)",
                    maxHeight: firstStepHeight,
                },
            },
        },
        {
            target: `.amcharts-AxisFill-group.amcharts-Authentication-Success`,
            title: "Expected and received trace number",
            content: (
                <Typography variant="body2">
                    Green Plot shows expected range of values including minimal and maximal number of traces received.
                    Orange is how much is received.
                </Typography>
            ),
            showSkipButton: true,
            disableBeacon: true,
            placement: "left",
            spotlightPadding: 50,
            offset: 50,
        },
        {
            target: `[data-onboarding="onboarding_anomaly_anomaly_graph_predicted_anomaly"] .amcharts-AxisFill-group.amcharts-Connect`,
            title: "Unexpected and missing traces",
            content: (
                <Typography variant="body2">
                    See the cases when the traces were not expected but were received...
                </Typography>
            ),
            showSkipButton: true,
            disableBeacon: true,
            placement: "right",
            spotlightPadding: 50,
            offset: 50,
        },
        {
            target: `[data-onboarding="onboarding_anomaly_anomaly_graph_predicted_anomaly"] .amcharts-AxisFill-group.amcharts-Firmware-signature-validation`,
            title: "Unexpected and missing traces",
            content: <Typography variant="body2">...and traces that were expected, but not received.</Typography>,
            showSkipButton: true,
            disableBeacon: true,
            placement: "right",
            spotlightPadding: 50,
            offset: 90,
            styles: {
                spotlight: {
                    transform: "scaleX(1.6)",
                },
            },
        },
        {
            target: `[data-onboarding="onboarding_anomaly_anomaly_graph_predicted_anomaly"] .amcharts-AxisFill-group.amcharts-Certification-extraction`,
            title: "Traces with High Anomaly prediction",
            content: (
                <Typography variant="body2">
                    Some trace types are predicted to have the bigger impact to form the anomaly. This hints at the
                    origin of an issue.
                </Typography>
            ),
            disableBeacon: true,
            placement: "right",
            spotlightPadding: 50,
            offset: 50,
        },
    ];

    useEffect(() => {
        const pattern = new am4core.LinePattern();
        pattern.width = 6;
        pattern.height = 6 * Math.tan(degToRad(SLOPE_DEGREE));
        pattern.stroke = am4core.color("#119970");
        pattern.strokeWidth = 1;
        pattern.strokeOpacity = 0.2;
        pattern.rotation = -SLOPE_DEGREE;
        pattern.backgroundFill = am4core.color("#119970");
        pattern.backgroundOpacity = 0.2;

        const chart = am4core.create(rootRef.current, am4charts.XYChart);
        chart.responsive.enabled = true;
        chart.scrollbarX = new am4core.Scrollbar();
        const slider = chart.scrollbarX;
        slider.width = am4core.percent(40);
        slider.valign = "middle";
        slider.background.fill = am4core.color("#F1F4F8");
        slider.background.fillOpacity = 1;
        slider.minHeight = 5;
        slider.nonScaling = true;
        // slider.start = (_startTime.getTime() - rangeMin) / (rangeMax - rangeMin);
        // slider.end = (_endTime.getTime() - rangeMin) / (rangeMax - rangeMin);
        slider.thumb.background.fill = am4core.color("#1B6FDE");
        slider.thumb.background.states.getKey("hover").properties.fill = am4core.color("#1B6FDE");
        slider.thumb.background.states.getKey("down").properties.fill = am4core.color("#1B6FDE");
        slider.thumb.tooltipY = -5;
        slider.showSystemTooltip = false;
        slider.thumb.showSystemTooltip = false;
        slider.thumb.tooltip.background.filters.clear();
        slider.thumb.tooltip.fontSize = 12;
        slider.thumb.tooltip.background.cornerRadius = 5;

        slider.startGrip.background.fill = am4core.color("#1B6FDE");
        slider.startGrip.background.cornerRadius(10, 10, 10, 10);
        slider.startGrip.background.strokeWidth = 0;
        slider.startGrip.width = 16;
        slider.startGrip.height = 16;
        slider.startGrip.icon.disabled = true;
        slider.startGrip.background.states.getKey("hover").properties.fill = am4core.color("#1B6FDE");
        slider.startGrip.background.states.getKey("down").properties.fill = am4core.color("#1B6FDE");
        slider.startGrip.tooltip.background.filters.clear();
        slider.startGrip.tooltip.dy = -10;
        slider.startGrip.tooltip.fontSize = 12;
        slider.startGrip.tooltip.background.cornerRadius = 10;

        slider.endGrip.background.fill = am4core.color("#1B6FDE");
        slider.endGrip.background.cornerRadius(10, 10, 10, 10);
        slider.endGrip.background.strokeWidth = 0;
        slider.endGrip.width = 16;
        slider.endGrip.height = 16;
        slider.endGrip.icon.disabled = true;
        slider.endGrip.background.states.getKey("hover").properties.fill = am4core.color("#1B6FDE");
        slider.endGrip.background.states.getKey("down").properties.fill = am4core.color("#1B6FDE");
        slider.endGrip.tooltip.background.filters.clear();
        slider.endGrip.tooltip.dy = -10;
        slider.endGrip.tooltip.fontSize = 12;
        slider.endGrip.tooltip.background.cornerRadius = 10;

        const startLeftGripRectangle = slider.startGrip.createChild(am4core.RoundedRectangle);
        startLeftGripRectangle.width = 1;
        startLeftGripRectangle.height = 6;
        startLeftGripRectangle.cornerRadius(40, 40, 40, 40);
        startLeftGripRectangle.fill = am4core.color("#fff");
        startLeftGripRectangle.dx = -2;
        startLeftGripRectangle.dy = -3;

        const startRightGripRectangle = slider.startGrip.createChild(am4core.RoundedRectangle);
        startRightGripRectangle.width = 1;
        startRightGripRectangle.height = 6;
        startRightGripRectangle.cornerRadius(40, 40, 40, 40);
        startRightGripRectangle.fill = am4core.color("#fff");
        startRightGripRectangle.dx = 1;
        startRightGripRectangle.dy = -3;

        const endLeftGripRectangle = slider.endGrip.createChild(am4core.RoundedRectangle);
        endLeftGripRectangle.width = 1;
        endLeftGripRectangle.height = 6;
        endLeftGripRectangle.cornerRadius(40, 40, 40, 40);
        endLeftGripRectangle.fill = am4core.color("#fff");
        endLeftGripRectangle.dx = -2;
        endLeftGripRectangle.dy = -3;

        const endRightGripRectangle = slider.endGrip.createChild(am4core.RoundedRectangle);
        endRightGripRectangle.width = 1;
        endRightGripRectangle.height = 6;
        endRightGripRectangle.cornerRadius(40, 40, 40, 40);
        endRightGripRectangle.fill = am4core.color("#fff");
        endRightGripRectangle.dx = 1;
        endRightGripRectangle.dy = -3;

        chartRef.current = chart;

        chart.zoomOutButton.disabled = true;
        chart.paddingTop = 40;

        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "traceCategory";
        categoryAxis.title.text = "";
        // categoryAxis.renderer.cellStartLocation = 0.4;
        // categoryAxis.renderer.cellEndLocation = 0.6;
        categoryAxis.renderer.labels.template.fill = am4core.color("#BCBED2");
        categoryAxis.renderer.labels.template.opacity = 0;
        categoryAxis.renderer.labels.template.fontSize = 10;
        categoryAxis.renderer.labels.template.maxWidth = 100;
        categoryAxis.renderer.labels.template.wrap = true;
        categoryAxis.renderer.labels.template.textAlign = "middle";
        // categoryAxis.renderer.labels.template.propertyFields.fill = 'exampleLabelFill';
        // categoryAxis.renderer.labels.template.propertyFields.stroke = 'exampleLabelFill';
        // THIS SHOULD WORK AND IS NOT WORKING!
        // AGAIN! AMCHART DOCS ARE !@#$%^&!!!!!!!!!!!!!!!!!
        // categoryAxis.renderer.labels.template.disabled = true;
        categoryAxis.renderer.cellStartLocation = CELL_START_LOCATION;
        categoryAxis.renderer.cellEndLocation = CELL_END_LOCATION;
        categoryAxis.renderer.grid.template.location = 1;
        categoryAxis.minZoomCount = 5;
        categoryAxis.maxZoomCount = 10;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = "Deviation\n(Traces count)";
        valueAxis.title.rotation = 0;
        valueAxis.title.fontSize = 10;
        valueAxis.title.align = "center";
        valueAxis.title.valign = "top";
        valueAxis.layout = "absolute";
        valueAxis.title.dy = -40;
        valueAxis.title.dx = -10;
        // valueAxis.renderer.labels.template.disabled = true;
        valueAxis.renderer.grid.template.disabled = true;
        valueAxis.renderer.labels.template.fill = am4core.color("#BCBED2");
        valueAxis.renderer.labels.template.fontSize = 10;
        valueAxis.renderer.labels.template.maxWidth = 100;
        valueAxis.renderer.labels.template.wrap = true;
        valueAxis.renderer.line.strokeOpacity = 0.15;
        valueAxis.renderer.line.dx = 70;

        categoryAxis.renderer.grid.template.stroke = am4core.color("#F4F4F4");
        categoryAxis.renderer.grid.template.strokeOpacity = 1;
        valueAxis.renderer.grid.template.stroke = am4core.color("#F4F4F4");
        valueAxis.renderer.grid.template.strokeOpacity = 1;

        const rowStyle = "display: flex; font-size: 12px; line-height: 18px;";
        const descriptionCellStyle = "text-align: left; font-weight: 400;";
        const cellStyle = "flex: 1; text-align: left; font-weight: 600;";
        const expectedCellStyle = "color: #1EC985;";
        const receivedCellStyle = "color: #EC984B;";
        const legendTooltipStyle = "";

        // let severeSeries = chart.series.push(new am4charts.ColumnSeries());
        // severeSeries.clustered = false;
        // severeSeries.dataFields.valueY = "severeValue";
        // severeSeries.dataFields.categoryX = "traceCategory";
        // severeSeries.fill = am4core.color("#FF5C5C");
        // severeSeries.stroke = am4core.color("rgba(0, 0, 0, 0)");
        // severeSeries.columns.template.width = COLUMN_WIDTH;
        // severeSeries.columns.template.column.cornerRadius(CORNER_RADIUS, CORNER_RADIUS, CORNER_RADIUS, CORNER_RADIUS);
        // severeSeries.columns.template.tooltipHTML = "{traceCategory}<br/>Expected: <strong>{valueY}</strong>";
        // severeSeries.legendSettings.labelText = "Severe";
        // severeSeries.columns.template.tooltipHTML = `
        //     <div>
        //         <div style="${rowStyle}">
        //             <div style="${descriptionCellStyle}">Category:</div>
        //             <div style="${cellStyle}">{traceCategory}</div>
        //         </div>
        //         <div style="${rowStyle}">
        //             <div style="${descriptionCellStyle}">Severity:</div>
        //             <div style="${cellStyle}">Severe</div>
        //         </div>
        //     </div>
        // `;
        // severeSeries.tooltip.background.cornerRadius = 6;
        // severeSeries.tooltip.background.strokeOpacity = 0;
        // severeSeries.tooltip.background.fillOpacity = 1;
        // severeSeries.tooltip.background.filters.clear();
        // severeSeries.tooltip.getFillFromObject = false;
        // severeSeries.tooltip.background.fill = am4core.color("#1B1B1B");

        let highSeries = chart.series.push(new am4charts.ColumnSeries());
        highSeries.clustered = false;
        highSeries.dataFields.valueY = "highValue";
        highSeries.dataFields.categoryX = "traceCategory";
        highSeries.fill = am4core.color("#FF5C5C");
        highSeries.stroke = am4core.color("rgba(0, 0, 0, 0)");
        highSeries.columns.template.width = COLUMN_WIDTH;
        highSeries.columns.template.column.cornerRadius(CORNER_RADIUS, CORNER_RADIUS, CORNER_RADIUS, CORNER_RADIUS);
        (highSeries as any).legendTooltipContent =
            "<div>Color shows the prediction of high</div><div>impact anomaly. The size of the bar - </div><div>from 0 to many hints at the origin of</div><div>anomaly</div>";
        highSeries.columns.template.tooltipHTML = `
            <div>
                <div style="${rowStyle}">
                    <div style="${descriptionCellStyle}">Trace Type:</div>
                    <div style="${cellStyle}">&nbsp;{traceCategory}</div>
                </div>
                <div style="${rowStyle}">
                    <div style="${descriptionCellStyle}${expectedCellStyle}">Expected range:</div>
                    <div style="${cellStyle}">&nbsp;{tooltipQuartileRange}</div>
                </div>
                <div style="${rowStyle}">
                    <div style="${descriptionCellStyle}${receivedCellStyle}">Received:</div>
                    <div style="${cellStyle}">&nbsp;{actualValue}</div>
                </div>
            </div>
      `;
        highSeries.legendSettings.labelText = "High anomaly";
        highSeries.tooltip.background.cornerRadius = 6;
        highSeries.tooltip.background.strokeOpacity = 0;
        highSeries.tooltip.background.fillOpacity = 1;
        highSeries.tooltip.background.filters.clear();
        highSeries.tooltip.getFillFromObject = false;
        highSeries.tooltip.background.fill = am4core.color("#1B1B1B");

        let normalSeries = chart.series.push(new am4charts.ColumnSeries());
        normalSeries.clustered = false;
        normalSeries.dataFields.valueY = "normalValue";
        normalSeries.dataFields.categoryX = "traceCategory";
        normalSeries.fill = am4core.color("#FFA337");
        normalSeries.stroke = am4core.color("rgba(0, 0, 0, 0)");
        normalSeries.columns.template.width = COLUMN_WIDTH;
        normalSeries.columns.template.column.cornerRadius(CORNER_RADIUS, CORNER_RADIUS, CORNER_RADIUS, CORNER_RADIUS);
        normalSeries.columns.template.tooltipHTML = "{traceCategory}<br/>Actual: <strong>{valueY}</strong>";
        normalSeries.legendSettings.labelText = "Received traces per type";
        (normalSeries as any).legendTooltipContent =
            "<div>Color shows the prediction of anomaly.</div><div>The size of the bar - from 0 to many</div><div>hints at the origin of anomaly</div>";
        normalSeries.columns.template.tooltipHTML = `
            <div>
                <div style="${rowStyle}">
                    <div style="${descriptionCellStyle}">Trace Type:</div>
                    <div style="${cellStyle}">&nbsp;{traceCategory}</div>
                </div>
                <div style="${rowStyle}">
                    <div style="${descriptionCellStyle}${expectedCellStyle}">Expected range:</div>
                    <div style="${cellStyle}">&nbsp;{tooltipQuartileRange}</div>
                </div>
                <div style="${rowStyle}">
                    <div style="${descriptionCellStyle}${receivedCellStyle}">Received:</div>
                    <div style="${cellStyle}">&nbsp;{actualValue}</div>
                </div>
            </div>
        `;
        normalSeries.tooltip.background.cornerRadius = 6;
        normalSeries.tooltip.background.strokeOpacity = 0;
        normalSeries.tooltip.background.fillOpacity = 1;
        normalSeries.tooltip.background.filters.clear();
        normalSeries.tooltip.getFillFromObject = false;
        normalSeries.tooltip.background.fill = am4core.color("#1B1B1B");

        // let rangeSeries = chart.series.push(new am4charts.ColumnSeries());
        // rangeSeries.clustered = false;
        // rangeSeries.dataFields.valueY = "endExpectedValue";
        // rangeSeries.dataFields.openValueY = "startExpectedValue";
        // rangeSeries.dataFields.categoryX = "traceCategory";
        // rangeSeries.fill = pattern;
        // rangeSeries.stroke = am4core.color("rgba(0, 0, 0, 0)");
        // rangeSeries.strokeOpacity = 0;
        // rangeSeries.strokeWidth = 0;
        // rangeSeries.columns.template.width = EXPECTED_WIDTH;
        // rangeSeries.columns.template.column.cornerRadius(CORNER_RADIUS, CORNER_RADIUS, CORNER_RADIUS, CORNER_RADIUS);
        // rangeSeries.columns.template.tooltipHTML = "{traceCategory}<br/>Actual: <strong>{valueY}</strong>";
        // rangeSeries.legendSettings.labelText = "Normal value range";
        // rangeSeries.columns.template.tooltipHTML = `
        //     <div>
        //         <div style="${rowStyle}">
        //             <div style="${descriptionCellStyle}">Category:</div>
        //             <div style="${cellStyle}">{traceCategory}</div>
        //         </div>
        //         <div style="${rowStyle}">
        //             <div style="${cellStyle}">Normal value range</div>
        //         </div>
        //     </div>
        // `;
        // rangeSeries.tooltip.background.cornerRadius = 6;
        // rangeSeries.tooltip.background.strokeOpacity = 0;
        // rangeSeries.tooltip.background.fillOpacity = 1;
        // rangeSeries.tooltip.background.filters.clear();
        // rangeSeries.tooltip.getFillFromObject = false;
        // rangeSeries.tooltip.background.fill = am4core.color("#1B1B1B");
        // rangeSeries.hiddenInLegend = true;

        let candleSeries = chart.series.push(new am4charts.CandlestickSeries());
        candleSeries.dataFields.categoryX = "traceCategory";
        candleSeries.dataFields.valueY = "thirdQuartile";
        candleSeries.dataFields.openValueY = "firstQuartile";
        candleSeries.dataFields.lowValueY = "min";
        candleSeries.dataFields.highValueY = "max";
        candleSeries.riseFromOpenState.properties.fill = pattern;
        candleSeries.riseFromOpenState.properties.strokeWidth = 1;
        candleSeries.riseFromOpenState.properties.stroke = am4core.color("#9DD3C5");
        candleSeries.dropFromOpenState.properties.fill = pattern;
        candleSeries.dropFromOpenState.properties.strokeWidth = 1;
        candleSeries.riseFromOpenState.properties.stroke = am4core.color("#9DD3C5");
        candleSeries.legendSettings.labelText = "Expected value range per type";
        candleSeries.columns.template.width = am4core.percent(100);
        candleSeries.columns.template.strokeWidth = 0;

        (candleSeries as any).legendTooltipContent =
            "<div>Compare common values across devices</div><div>to the anomaly value to understand the</div><div>possible origin of the anomaly.</div>";
        candleSeries.columns.template.tooltipHTML = `
            <div>
                <div style="${rowStyle}">
                    <div style="${descriptionCellStyle}">Trace Type:</div>
                    <div style="${cellStyle}">&nbsp;{traceCategory}</div>
                </div>
                <div style="${rowStyle}">
                    <div style="${descriptionCellStyle}${expectedCellStyle}">Expected range:</div>
                    <div style="${cellStyle}">&nbsp;{tooltipQuartileRange}</div>
                </div>
                <div style="${rowStyle}">
                    <div style="${descriptionCellStyle}${receivedCellStyle}">Received:</div>
                    <div style="${cellStyle}">&nbsp;{actualValue}</div>
                </div>
            </div>
        `;
        candleSeries.tooltip.background.cornerRadius = 6;
        candleSeries.tooltip.background.strokeOpacity = 0;
        candleSeries.tooltip.background.fillOpacity = 1;
        candleSeries.tooltip.background.filters.clear();
        candleSeries.tooltip.getFillFromObject = false;
        candleSeries.tooltip.background.fill = am4core.color("#1B1B1B");

        let lowStepLineSeries = chart.series.push(new am4charts.StepLineSeries());
        lowStepLineSeries.noRisers = true;
        lowStepLineSeries.dataFields.categoryX = "traceCategory";
        lowStepLineSeries.dataFields.valueY = "min";
        lowStepLineSeries.hiddenInLegend = true;
        lowStepLineSeries.startLocation = CELL_START_LOCATION;
        lowStepLineSeries.endLocation = CELL_END_LOCATION;
        lowStepLineSeries.properties.stroke = am4core.color("#119970");

        let highStepLineSeries = chart.series.push(new am4charts.StepLineSeries());
        highStepLineSeries.noRisers = true;
        highStepLineSeries.dataFields.categoryX = "traceCategory";
        highStepLineSeries.dataFields.valueY = "max";
        highStepLineSeries.hiddenInLegend = true;
        highStepLineSeries.startLocation = CELL_START_LOCATION;
        highStepLineSeries.endLocation = CELL_END_LOCATION;
        highStepLineSeries.properties.stroke = am4core.color("#119970");

        let medianStepLineSeries = chart.series.push(new am4charts.StepLineSeries());
        medianStepLineSeries.noRisers = true;
        medianStepLineSeries.dataFields.categoryX = "traceCategory";
        medianStepLineSeries.dataFields.valueY = "median";
        medianStepLineSeries.hiddenInLegend = true;
        medianStepLineSeries.startLocation = CELL_START_LOCATION;
        medianStepLineSeries.endLocation = CELL_END_LOCATION;
        medianStepLineSeries.properties.stroke = am4core.color("#119970");
        medianStepLineSeries.properties.strokeWidth = 2;

        candleSeries.events.on("hidden", () => {
            lowStepLineSeries.hide();
            highStepLineSeries.hide();
            medianStepLineSeries.hide();
        });

        candleSeries.events.on("shown", () => {
            lowStepLineSeries.show();
            highStepLineSeries.show();
            medianStepLineSeries.show();
        });

        // fake series to put labels somewhere
        const categoryLabelSeries = chart.series.push(new am4charts.ColumnSeries());
        categoryLabelSeries.clustered = false;
        categoryLabelSeries.dataFields.valueY = "zero";
        categoryLabelSeries.dataFields.categoryX = "traceCategory";
        categoryLabelSeries.hiddenInLegend = true;
        categoryLabelSeries.fill = am4core.color("rgba(0, 0, 0, 0)");
        categoryLabelSeries.stroke = am4core.color("rgba(0, 0, 0, 0)");

        chart.maskBullets = false;
        const labelBullet = categoryLabelSeries.bullets.push(new am4charts.Bullet());
        labelBullet.locationY = 1;
        labelBullet.dy = 16;

        const axisLabel = labelBullet.children.push(new am4charts.LabelBullet());
        axisLabel.label.text = "{traceCategory}";
        axisLabel.label.fontSize = 10;
        // axisLabel.label.fill = am4core.color("#BCBED2");
        axisLabel.label.propertyFields.fill = "labelColor";

        const dangerIcon = axisLabel.children.push(new am4core.Sprite());
        dangerIcon.path =
            "M16.6744 14.3821L13.6092 9.10036C13.1973 8.38971 12.6274 8 12 8C11.3726 8 10.8027 8.38971 10.3908 9.10036L7.32563 14.3821C6.9377 15.056 6.8946 15.7025 7.2059 16.2114C7.51721 16.7203 8.13024 17 8.93484 17H15.0652C15.8698 17 16.4828 16.7203 16.7941 16.2114C17.1054 15.7025 17.0623 15.0515 16.6744 14.3821ZM11.6408 11.2094C11.6408 11.0214 11.8036 10.8655 12 10.8655C12.1964 10.8655 12.3592 11.0214 12.3592 11.2094V13.5018C12.3592 13.6898 12.1964 13.8456 12 13.8456C11.8036 13.8456 11.6408 13.6898 11.6408 13.5018V11.2094ZM12.34 15.2028C12.3161 15.2211 12.2921 15.2394 12.2682 15.2578C12.2395 15.2761 12.2107 15.2899 12.182 15.299C12.1533 15.3128 12.1245 15.322 12.091 15.3265C12.0623 15.3311 12.0287 15.3357 12 15.3357C11.9713 15.3357 11.9377 15.3311 11.9042 15.3265C11.8755 15.322 11.8467 15.3128 11.818 15.299C11.7893 15.2899 11.7605 15.2761 11.7318 15.2578C11.7079 15.2394 11.6839 15.2211 11.66 15.2028C11.5738 15.1156 11.5211 14.9964 11.5211 14.8772C11.5211 14.758 11.5738 14.6388 11.66 14.5517C11.6839 14.5334 11.7079 14.515 11.7318 14.4967C11.7605 14.4783 11.7893 14.4646 11.818 14.4554C11.8467 14.4417 11.8755 14.4325 11.9042 14.4279C11.9665 14.4142 12.0335 14.4142 12.091 14.4279C12.1245 14.4325 12.1533 14.4417 12.182 14.4554C12.2107 14.4646 12.2395 14.4783 12.2682 14.4967C12.2921 14.515 12.3161 14.5334 12.34 14.5517C12.4262 14.6388 12.4789 14.758 12.4789 14.8772C12.4789 14.9964 12.4262 15.1156 12.34 15.2028Z";
        dangerIcon.width = 24;
        dangerIcon.height = 24;
        dangerIcon.horizontalCenter = "middle";
        dangerIcon.verticalCenter = "middle";
        dangerIcon.propertyFields.fill = "labelColor";
        dangerIcon.stroke = am4core.color("white");
        dangerIcon.strokeWidth = 0.5;
        dangerIcon.propertyFields.disabled = "iconDisabled";

        dangerIcon.adapter.add("dx", (dx, target: am4core.Sprite) => {
            const parent = target.parent as unknown as am4charts.LabelBullet;
            const labelWidth = getTextWidth(parent.label.currentText, "10px Inter");

            return -labelWidth / 2 - 10;
        });

        // const labelBullet = rangeSeries.bullets.push(new am4charts.LabelBullet());
        // labelBullet.dx = 4;
        // labelBullet.label.text = "{expectedValue}";
        // labelBullet.label.truncate = false;
        // labelBullet.locationX = 1;
        // labelBullet.locationY = 0.5;
        // labelBullet.label.fontSize = 10;
        // labelBullet.label.fontWeight = "600";
        // labelBullet.label.fill = am4core.color("#119970");
        // labelBullet.label.horizontalCenter = "left";

        // const lineBullet = rangeSeries.bullets.push(new am4charts.Bullet());
        // lineBullet.fill = am4core.color("#ff0000");
        // lineBullet.locationY = 0.5;
        // lineBullet.dx = 0.5;
        // const line = lineBullet.createChild(am4core.Line);
        // line.horizontalCenter = "middle";
        // line.verticalCenter = "middle";
        // line.stroke = am4core.color("#119970");
        // line.strokeWidth = 2;
        // line.strokeOpacity = 1;
        // line.width = am4core.percent(100);
        // line.height = 0;
        // line.rotation = 0;
        // lineBullet.width = am4core.percent(100);

        const legend = new am4charts.Legend();
        legend.position = "bottom";
        legend.contentAlign = "left";
        legend.itemContainers.template.tooltipHTML = `<div style="${legendTooltipStyle}">{dataContext.legendTooltipContent}</div>`;

        const marker = legend.markers.template.children.getIndex(0);
        const disabledState = marker.states.getKey("active");
        // disabledState.propertyFields.stroke = 'fill';
        disabledState.properties.strokeWidth = 2;
        disabledState.properties.strokeOpacity = 1;

        legend.markers.template.events.on("childadded", function (event) {
            // this is a workaround for not working fill override
            // in markers disabled states
            // https://github.com/amcharts/amcharts4/issues/3180
            var child = event.newValue;
            // active is disabled
            var activeState = child.states.create("active");
            activeState.propertyFields.stroke = "fill";
            activeState.properties.fill = am4core.color("rgba(255, 255, 255, 255)");
            activeState.properties.strokeWidth = 2;
            activeState.properties.strokeOpacity = 1;
            (activeState.sprite.dom.firstChild as SVGRectElement).setAttribute("rx", "4");
            (activeState.sprite.dom.firstChild as SVGRectElement).setAttribute("ry", "4");
            const sprite = activeState.sprite as am4core.RoundedRectangle;
            sprite.properties.fill = am4core.color("rgba(255, 255, 255, 255)");
            sprite.cornerRadiusBottomLeft = 4;
            sprite.cornerRadiusTopLeft = 4;
            sprite.cornerRadiusTopRight = 4;
            sprite.cornerRadiusBottomRight = 4;

            setTimeout(() => {
                const hasColor = "color" in event.newValue.dataItem.properties;
                const hasPattern = event.newValue.dataItem.properties.color instanceof am4core.LinePattern;

                if (hasColor && hasPattern) {
                    activeState.propertyFields.stroke = undefined;
                }
            });
        });

        // legend.markers.getIndex(0).dataItem.adapter.add('duration', (duration, target) => {
        //     return 0;
        // });

        const disabledLabel = legend.labels.template.states.getKey("active");
        disabledLabel.properties.fill = am4core.color("#000000");
        const disabledValueLabel = legend.valueLabels.template.states.getKey("active");
        disabledValueLabel.properties.fill = am4core.color("#000000");

        chart.legend = legend;
    }, [showDemoData]);

    useEffect(() => {
        if (!chartRef.current) {
            return;
        }

        const xyChart = chartRef.current as am4charts.XYChart;

        xyChart.legend.disabled = hideLegendAndDescription;
    }, [hideLegendAndDescription]);

    useEffect(() => {
        if (!anomaliesResponse) {
            return;
        }

        let maxValue = 0;

        const categoryAxis = chartRef.current.xAxes.getIndex(0) as am4charts.CategoryAxis<am4charts.AxisRenderer>;
        categoryAxis.axisRanges.clear();

        const anomalies = showDemoData ? demoData : anomaliesResponse.anomalies;

        if (anomalies.length > 10) {
            chartRef.current.scrollbarX.disabled = false;
        } else {
            chartRef.current.scrollbarX.disabled = true;
        }

        const lowStepLineSeries = chartRef.current.series.getIndex(3) as am4charts.StepLineSeries;
        const highStepLineSeries = chartRef.current.series.getIndex(4) as am4charts.StepLineSeries;
        const mediumStepLineSeries = chartRef.current.series.getIndex(5) as am4charts.StepLineSeries;

        // if (anomalies.length < ANOMALIES_LENGTH_THRESHOLD) {
        //     categoryAxis.renderer.cellStartLocation = CELL_THIN_START_LOCATION;
        //     categoryAxis.renderer.cellEndLocation = CELL_THIN_END_LOCATION;

        //     lowStepLineSeries.startLocation = CELL_THIN_START_LOCATION;
        //     lowStepLineSeries.endLocation = CELL_THIN_END_LOCATION;

        //     highStepLineSeries.startLocation = CELL_THIN_START_LOCATION;
        //     highStepLineSeries.endLocation = CELL_THIN_END_LOCATION;

        //     mediumStepLineSeries.startLocation = CELL_THIN_START_LOCATION;
        //     mediumStepLineSeries.endLocation = CELL_THIN_END_LOCATION;
        // } else {
        //     categoryAxis.renderer.cellStartLocation = CELL_START_LOCATION;
        //     categoryAxis.renderer.cellEndLocation = CELL_END_LOCATION;

        //     lowStepLineSeries.startLocation = CELL_START_LOCATION;
        //     lowStepLineSeries.endLocation = CELL_END_LOCATION;

        //     highStepLineSeries.startLocation = CELL_START_LOCATION;
        //     highStepLineSeries.endLocation = CELL_END_LOCATION;

        //     mediumStepLineSeries.startLocation = CELL_START_LOCATION;
        //     mediumStepLineSeries.endLocation = CELL_END_LOCATION;
        // }

        const halfWidth = getCellHalfWidth(Math.min(10, anomalies.length));
        const cellStartLocation = 0.5 - halfWidth;
        const cellEndLocation = 0.5 + halfWidth;

        categoryAxis.renderer.cellStartLocation = cellStartLocation;
        categoryAxis.renderer.cellEndLocation = cellEndLocation;

        lowStepLineSeries.startLocation = cellStartLocation;
        lowStepLineSeries.endLocation = cellEndLocation;

        highStepLineSeries.startLocation = cellStartLocation;
        highStepLineSeries.endLocation = cellEndLocation;

        mediumStepLineSeries.startLocation = cellStartLocation;
        mediumStepLineSeries.endLocation = cellEndLocation;

        anomalies.forEach((anomaly) => {
            if (anomaly.actualValue > maxValue) {
                maxValue = anomaly.actualValue;
            }

            const categoryRange = categoryAxis.axisRanges.create();
            categoryRange.category = anomaly.traceCategory;
            // categoryRange.locations.category =
            //     anomalies.length < ANOMALIES_LENGTH_THRESHOLD ? CELL_THIN_START_LOCATION : CELL_START_LOCATION;
            // categoryRange.locations.endCategory =
            //     anomalies.length < ANOMALIES_LENGTH_THRESHOLD ? CELL_THIN_END_LOCATION : CELL_END_LOCATION;
            categoryRange.locations.category = cellStartLocation;
            categoryRange.locations.endCategory = cellEndLocation;
            categoryRange.axisFill.fill = am4core.color("#FAFBFD");
            categoryRange.axisFill.fillOpacity = 1;
            categoryRange.axisFill.strokeOpacity = 0;
            categoryRange.axisFill.strokeWidth = 0;
            categoryRange.axisFill.id = anomaly.traceCategory.split(/\s+/).join("-");
        });

        const valueAxis = chartRef.current.yAxes.getIndex(0) as am4charts.ValueAxis<am4charts.AxisRenderer>;
        valueAxis.axisRanges.clear();

        // let range = valueAxis.axisRanges.create();
        // range.value = 0.2;
        // range.endValue = 0.6;
        // // range.axisFill.fill = am4core.color("#119970");
        // range.axisFill.fill = pattern;
        // range.axisFill.fillOpacity = 1;
        // range.axisFill.stroke = am4core.color("#396478");
        // range.axisFill.strokeOpacity = 0;
        // // range.grid.strokeOpacity = 0;
        // range.axisFill.above = true;

        // function createGrid(value: number) {
        //     let range = valueAxis.axisRanges.create();
        //     range.value = value;
        //     range.label.text = "";
        // }

        // if (typeof maxValue === "number") {
        //     // check if number just in case
        //     const step = 0.4;
        //     let currentValue = step;
        //     const minNeededGrid = 1.2;

        //     while (currentValue <= Math.max(minNeededGrid, maxValue)) {
        //         createGrid(currentValue);
        //         currentValue += step;
        //     }
        // }

        chartRef.current.data = orderBy(anomalies, ["shap"], ["desc"]);

        const offReady = chartRef.current.events.once("dataitemsvalidated", () => {
            setTimeout(() => {
                categoryAxis.minZoomCount = Math.min(5, anomalies.length);
                categoryAxis.maxZoomCount = Math.min(10, anomalies.length);

                categoryAxis.zoomToIndexes(0, Math.min(10, anomalies.length));

                // yes, I know a timeout is a bad practice, but practically
                // no event matches what we need, and it is the only way
                // that worked so far ;(
            }, 300);

            offReady.dispose();
        });

        onAnomaliesFetched?.(anomaliesResponse);
    }, [anomaliesResponse, showDemoData]);

    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            for (const entry of entries) {
                const borderBoxSize: ResizeObserverSize = Array.isArray(entry.borderBoxSize)
                    ? entry.borderBoxSize[0]
                    : entry.borderBoxSize;
                setFirstStepHeight(borderBoxSize.blockSize - 80);
            }
        });

        observer.observe(rootRef.current);

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <div
            style={{
                position: "relative",
                flex: 1,
                display: "flex",
                flexDirection: "column",
                minHeight: CHART_MIN_HEIGHT,
            }}
        >
            <div
                ref={rootRef}
                style={{ flex: 1, padding: "28px 40px" }}
                data-onboarding="onboarding_anomaly_anomaly_graph_predicted_anomaly"
            />
            {!hideLegendAndDescription && (
                <div
                    style={{
                        color: "#BCBED2",
                        fontSize: 12,
                        lineHeight: "18px",
                        maxWidth: 550,
                        position: "absolute",
                        bottom: 10,
                        right: 64,
                    }}
                >
                    The values in this graph show <b>the impact</b> of each trace type on the origin of the anomaly.
                    <br />
                    Note:{" "}
                    <i>
                        the absence of some traces can also have a <b>high impact</b> value.
                    </i>
                </div>
            )}
            <AnomalyOnboarding
                storageKey="onboarding_anomaly_anomaly_graph"
                steps={onboardingSteps}
                isDemoDataTagVisible={true}
                onStartOnboarding={() => setShowDemoData(true)}
                onFinishOnboarding={() => setShowDemoData(false)}
            />
        </div>
    );
}
