/**
 * Represents an Event Interest in detailed form.
 */
class EventInterestDetailed {
    /**
     * Constructor.
     */
    constructor(public displayName: string, public id: number, public name: string) {}

    /**
     * Converts json to info object.
     */
    public static fromJsonObject(jsonObject: Object) {
        return new EventInterestDetailed(jsonObject["display_name"], jsonObject["id"], jsonObject["name"]);
    }
}

export default EventInterestDetailed;
