import { getModalsDefaultState, ModalsState } from "./ModalsState";
import { getNotificationsDefaultState, NotificationsState } from "./NotificationsState";

/**
 * Represents the state for UI operations.
 */
export interface UIState {
    modals: ModalsState;
    notifications: NotificationsState;
    currentlyViewedDeviceId: string;
    currentlyViewedTraceId: string;
    currentlyViewedIssueId?: string;
    currentlyViewedLibraryId: string;
    currentlyViewedCveId: string;
    currentlyViewedUsedLibraryId: string;
    currentlyViewedGeneratedEventId: string;
}

/**
 * Gets the default UI state for the app.
 */
export function getDefaultUIState(): UIState {
    return {
        modals: getModalsDefaultState(),
        notifications: getNotificationsDefaultState(),
        currentlyViewedDeviceId: null,
        currentlyViewedTraceId: null,
        currentlyViewedIssueId: null,
        currentlyViewedLibraryId: null,
        currentlyViewedCveId: null,
        currentlyViewedUsedLibraryId: null,
        currentlyViewedGeneratedEventId: null,
    };
}
