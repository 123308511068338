import { Box, Link, Typography } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { History } from "history";

import { GlobalState } from "../../lib/state/GlobalState";
import { GetSupportGuide } from "../GetSupport/GetSupportGuide";
import { SignupContainer } from "../SignupContainer";
import { ArrowLeftIcon } from "../SUI/SternumIcon";
import { useStyles } from "./HowItWorksStyle";

export interface HowItWorksPageProps {}

const mapStateToProps = (state: GlobalState, ownProps: HowItWorksPageProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

type HowItWorksPagePropsWithHOC = HowItWorksPageProps &
    ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps> & { history: History };

function HowItWorksPageComponent({ history }: HowItWorksPagePropsWithHOC) {
    const classes = useStyles();

    return (
        <SignupContainer>
            <div className={classes.root}>
                <div className={classes.content}>
                    <Link
                        className={classNames(classes.backButton)}
                        component="a"
                        variant="body2"
                        onClick={() => history.goBack()}
                    >
                        <ArrowLeftIcon className={classes.backButtonIcon} />
                        <span>Back</span>
                    </Link>

                    <Typography variant="h5" className={classes.mainTitle}>
                        How it works
                    </Typography>

                    <Box py={2}>
                        <GetSupportGuide />
                    </Box>
                </div>
            </div>
        </SignupContainer>
    );
}

export const HowItWorksPage: React.FC<HowItWorksPageProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(HowItWorksPageComponent));
