import { Icon } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import moment from "moment";
import * as React from "react";
import { withRouter } from "react-router-dom";

import SternumConfiguration from "../../lib/infra/SternumConfiguration";
import SternumUtils from "../../lib/infra/SternumUtils";
import WebUtils from "../../lib/infra/WebUtils";
import AnalyticsService from "../../lib/services/AnalyticsService";
import ServiceWire from "../../lib/services/ServiceWire";
import GetSternumDeviceEventsResponse from "../../lib/state/GetSternumDeviceEventsResponse";
import SternumDeviceEventInfo from "../../lib/state/SternumDeviceEventInfo";
import kernelLogsDisplayStyle from "./KernelLogsDisplayStyle";
import SternumDeviceEventsFilter from "../../lib/state/SternumDeviceEventsFilter";
import { getMappedValue } from "../../utils";

import { KernelLogsMessagesType } from "./KernelLogsDisplay.types";

export interface KernelLogsDisplayProps {
    className?: string;
    bootEvent: SternumDeviceEventInfo;
    isInfinityScrollOn?: boolean;
    entitiesFilter: SternumDeviceEventsFilter;
    searchText: string;
    kernelLogsMessagesType?: KernelLogsMessagesType;
}

/**
 * Holds the inner state for our app.
 */
interface AppState {
    entities: SternumDeviceEventInfo[];
    scrollPosition: number;
    pageNumber: number;
    totalItemCount: number;
    errorLoadingEntities: boolean;
    showInfinityLoader: boolean;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends KernelLogsDisplayProps, WithStyles<typeof kernelLogsDisplayStyle> {}

/**
 * Displays arguments when a row is in a list.
 */
class KernelLogsDisplayComponent extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            entities: [],
            scrollPosition: 0,
            pageNumber: 1,
            totalItemCount: 0,
            errorLoadingEntities: false,
            showInfinityLoader: false,
        };
    }

    componentDidMount() {
        this.loadMessages(1);
    }

    componentDidUpdate(prevProps: Readonly<AppProps>, prevState: Readonly<AppState>, snapshot?: any) {
        if (prevProps.searchText !== this.props.searchText) {
            this.loadEntitiesForScroll(1);
        }
    }

    private loadMessages = async (pageNumber: number) => {
        try {
            const response = await this.fetchMessages(this.props.bootEvent, pageNumber);

            this.setState({
                errorLoadingEntities: false,
                showInfinityLoader: false,
                entities: response.sternumDeviceEvents,
                totalItemCount: response.totalItemCount,
            });
        } catch (error) {
            AnalyticsService.error("KernelLogsDisplay:loadBootMessages", error.message);

            this.setState({ errorLoadingEntities: true, showInfinityLoader: false });
        }
    };

    /**
     * Api call for messages.
     */
    private async fetchMessages(
        entity: SternumDeviceEventInfo,
        pageNumber: number
    ): Promise<GetSternumDeviceEventsResponse> {
        // `created_from` value is used in the api to find the boot for which we need to get boot kernel modules,
        // so this value should be always equal to device boot creation time, not custom time that user defined
        const entitiesFilter = this.props.entitiesFilter?.clone();
        if (entitiesFilter) {
            entitiesFilter.createdFrom = entity.created;
        }

        const apiRequest = getMappedValue<KernelLogsMessagesType, () => Promise<GetSternumDeviceEventsResponse>>(
            {
                [KernelLogsMessagesType.Boot]: () =>
                    ServiceWire.getSternumService().getDeviceSternumDeviceKernelLogBootMessages(
                        entity.device.deviceId,
                        entitiesFilter,
                        this.props.searchText,
                        (pageNumber - 1) * SternumConfiguration.getPageSize(),
                        SternumConfiguration.getPageSize()
                    ),
                [KernelLogsMessagesType.All]: () =>
                    ServiceWire.getSternumService().getDeviceSternumDeviceKernelLogAllMessages({
                        entityId: entity.device.deviceId,
                        searchText: this.props.searchText,
                        offset: (pageNumber - 1) * SternumConfiguration.getPageSize(),
                        limit: SternumConfiguration.getPageSize(),
                    }),
            },
            this.props.kernelLogsMessagesType || KernelLogsMessagesType.Boot
        );

        return apiRequest();
    }

    /**
     * Loads entities with concat operation to existing entities.
     */
    private loadEntitiesForScroll = async (pageNumber: number) => {
        try {
            let entities = [];
            let totalItemCount = 0;

            this.setState({
                errorLoadingEntities: false,
                showInfinityLoader: true,
            });

            let getSternumDeviceEventsResponse: GetSternumDeviceEventsResponse = await this.fetchMessages(
                this.props.bootEvent,
                pageNumber
            );

            // Fetching entities.
            if (pageNumber === 1) {
                entities = getSternumDeviceEventsResponse.sternumDeviceEvents;
            } else {
                entities = this.state.entities.concat(getSternumDeviceEventsResponse.sternumDeviceEvents);
            }

            totalItemCount = getSternumDeviceEventsResponse.totalItemCount;

            this.setState({
                errorLoadingEntities: false,
                showInfinityLoader: false,
                totalItemCount,
                entities: [...entities],
                pageNumber,
            });
        } catch (error) {
            AnalyticsService.error("KernelLogDisplay:loadEntitiesForScroll", error.message);

            this.setState({
                showInfinityLoader: false,
                errorLoadingEntities: true,
            });
        }
    };

    private handleScroll = (event: any) => {
        if (!this.props.isInfinityScrollOn) {
            return;
        }

        WebUtils.loadDataOnScroll(
            event,
            "loadKernelMessages",
            (scrollTop) => {
                if (!this.isLastPage()) {
                    this.setState({ scrollPosition: scrollTop }, () => {
                        this.loadEntitiesForScroll(this.state.pageNumber + 1);
                    });
                }
            },
            (scrollTop) => {
                if (scrollTop <= this.state.scrollPosition) {
                    this.setState({ scrollPosition: scrollTop, pageNumber: 1 }, () => {
                        this.loadEntitiesForScroll(1);
                    });
                }
            }
        );
    };

    /**
     * Check if it's the last pagination page
     */
    private isLastPage = () => {
        return this.state.totalItemCount < this.state.pageNumber * SternumConfiguration.getPageSize();
    };

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        // Constructing the display of arguments.
        let kernelLogMessages = [];

        this.state.entities.forEach((entity) => {
            const priorityArgument = entity.traceInfo.traceArguments["SYSTEM_ARG_ROLE_PRIORITY"];
            const timestampArgument = entity.traceInfo.traceArguments["SYSTEM_ARG_ROLE_TIMESTAMP"];
            const messageArgument = entity.traceInfo.traceArguments["ARG_ROLE_MESSAGE"];

            const timestamp = parseInt(timestampArgument?.displayValue, 10) * 1000;
            const formattedTimestamp = moment(timestamp).format("MM/DD HH:mm:ss");

            if (!timestampArgument) {
                return;
            }

            kernelLogMessages.push(
                <div key={entity.entityId} className={classNames(classes.kernelLogMessage, "kernel-log-message-line")}>
                    <span className={classNames(classes.kernelLogPriority)}>
                        {SternumUtils.getKernelLogDisplayName(priorityArgument)}
                    </span>
                    <span className={classNames(classes.kernelLogTime)}>{`[${formattedTimestamp}]`}</span>
                    <Icon className={classNames("fas fa-chevron-right", classes.kernelLogChevron)} />
                    <div className={classNames(classes.kernelLogMessageText)}>{messageArgument?.displayValue}</div>
                </div>
            );
        });

        return (
            <div
                className={classNames(classes.container, this.props.className)}
                onScroll={this.handleScroll}
                role="presentation"
                aria-label="kernel logs"
            >
                {kernelLogMessages}
            </div>
        );
    }
}

export const KernelLogsDisplay: React.FC<KernelLogsDisplayProps> = withRouter(
    withStyles(kernelLogsDisplayStyle)(KernelLogsDisplayComponent)
);
