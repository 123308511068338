import { useCallback, useEffect, useRef, useState } from "react";

import ServiceWire from "../../../../lib/services/ServiceWire";
import ReceivedDefinitionsResponse from "../../../../lib/services/events/ReceivedDefinitionsResponse";

export interface UseReceivedDefinitionsOptions {
    deviceId: string;
    createdFrom: number;
    createdTo: number;
}

export const useReceivedDefinitions = ({ deviceId, createdFrom, createdTo }: UseReceivedDefinitionsOptions) => {
    const [definitions, setDefinitions] = useState<ReceivedDefinitionsResponse>(null);
    const isMountedRef = useIsMounted();
    const [isLoading, setLoading] = useState(false);

    const fetch = useCallback(async () => {
        setLoading(true);

        const response = await ServiceWire.getEventsApiService().getAllReceivedDefinitions(
            deviceId,
            createdFrom,
            createdTo
        );

        if (isMountedRef.current) {
            setDefinitions(response);
            setLoading(false);
        }
    }, [deviceId, createdFrom, createdTo]);

    useEffect(() => {
        fetch().catch(() => {
            if (isMountedRef.current) {
                setLoading(false);
            }
        });
    }, [fetch]);

    return {
        receivedDefinitions: definitions?.receivedDefinitions,
        receivedArguments: definitions?.receivedArguments,
        isLoading,
        refetch: fetch,
    } as const;
};

const useIsMounted = () => {
    const isMountedRef = useRef(true);

    useEffect(
        () => () => {
            isMountedRef.current = false;
        },
        []
    );

    return isMountedRef;
};
