import { makeStyles, Theme } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

export const useSelectTimeRangePopoverStyle = makeStyles((theme: Theme) => ({
    closeButtonIcon: {
        cursor: "pointer",
        color: grey[500],
        "&:hover": {
            color: theme.palette.common.black,
        },
    },

    customDateSelect: {
        width: theme.spacing(110),
        height: theme.spacing(20),
    },

    setButton: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
}));
