import React, { useMemo } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { History } from "history";

import { GlobalState } from "../../../../lib/state/GlobalState";
import { AnomaliesIcon, CrossOctagonIcon, DangerIcon, SternumLogoGradientIcon } from "../../../SUI/SternumIcon";

import { DeviceMetricTile } from "./DeviceMetricTile";

import { LinuxTab } from "../LinuxTabs";
import { useDeviceMetricsStyles } from "./DeviceMetrics.styles";
import { useDeviceMetrics } from "./DeviceMetrics.hook";
import SternumConfiguration from "../../../../lib/infra/SternumConfiguration";

export interface DeviceMetricsProps {
    deviceId: string;
    startDate: Date;
    endDate: Date;
    refreshEntitiesFilter?: boolean;
}

const mapStateToProps = (state: GlobalState, ownProps: DeviceMetricsProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

type DeviceMetricsPropsWithHOC = DeviceMetricsProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
        history: History;
    };

function DeviceMetricsComponent({
    deviceId,
    history,
    startDate: startDateFromProps,
    endDate: endDateFromProps,
    refreshEntitiesFilter,
}: DeviceMetricsPropsWithHOC) {
    const classes = useDeviceMetricsStyles();

    const { startDate, endDate } = useMemo(() => {
        return { startDate: startDateFromProps, endDate: endDateFromProps };
    }, [refreshEntitiesFilter]);

    const { isLoading, deviceMetricsData } = useDeviceMetrics({ deviceId, startDate, endDate });

    if (isLoading) {
        return (
            <div className={classes.container}>
                {/* Maybe it is good to have loading state here? */}
                <div className={classes.loadingContainer}>
                    <SternumLogoGradientIcon width={60} height={60} useAnimation={true} />
                </div>
            </div>
        );
    }

    const searchKey = "tab";
    const urlParams = new URLSearchParams(history.location.search);

    const changeLinuxTab = (linuxTab: LinuxTab) => {
        urlParams.set(searchKey, linuxTab);
        history.push({ search: urlParams.toString() });
    };

    const renderPercentageValue = (value: number) => {
        return value === Infinity ? "\u221e" : value === -Infinity ? "-\u221e" : `${value}%`;
    };

    return (
        <div className={classes.container}>
            {deviceMetricsData?.alerts && (
                <DeviceMetricTile
                    title="Number of Alerts"
                    icon={<DangerIcon />}
                    value={deviceMetricsData.alerts.count}
                    arrowDirection={deviceMetricsData.alerts.arrowDirection}
                    percentageValue={renderPercentageValue(deviceMetricsData.alerts.percentageValue)}
                    onClick={() => changeLinuxTab(LinuxTab.Alerts)}
                />
            )}
            {deviceMetricsData?.anomalies && (
                <DeviceMetricTile
                    title="Number of Anomalies"
                    icon={<AnomaliesIcon />}
                    value={deviceMetricsData.anomalies.count}
                    arrowDirection={deviceMetricsData.anomalies.arrowDirection}
                    percentageValue={renderPercentageValue(deviceMetricsData.anomalies.percentageValue)}
                    onClick={() => changeLinuxTab(LinuxTab.Anomalies)}
                />
            )}
            {SternumConfiguration.getDeploymentEnv() === "development" && deviceMetricsData?.crashes && (
                <DeviceMetricTile
                    title="Number of Crashes"
                    icon={<CrossOctagonIcon />}
                    value={deviceMetricsData.crashes.count}
                    arrowDirection={deviceMetricsData.crashes.arrowDirection}
                    percentageValue={renderPercentageValue(deviceMetricsData.crashes.percentageValue)}
                    onClick={() => changeLinuxTab(LinuxTab.Crashes)}
                />
            )}
        </div>
    );
}

export const DeviceMetrics: React.FC<DeviceMetricsProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DeviceMetricsComponent));
