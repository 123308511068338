import { FormControlLabel, Typography } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { GlobalState } from "../../../lib/state/GlobalState";
import VisualisationType from "../../../lib/state/Visualisation/VisualisationType";
import SternumSwitch from "../../SUI/SternumSwitch/SternumSwitch";
import visualisationConfigurationPresentationTitleStyle from "./VisualisationConfigurationPresentationTitleStyle";

const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

interface AppState {
    isStacked: boolean;
    multiDataSources: boolean;
}

export interface AppProps extends WithStyles<typeof visualisationConfigurationPresentationTitleStyle> {
    visualisationType: VisualisationType;
    onDisplayTypeSelected: (displayType: VisualisationType) => void;
    onStackToggleChange?: (status: boolean) => void;
    onLogarithmicScaleToggleChange?: (status: boolean) => void;
    isStacked?: boolean;
    isLogarithmicScale?: boolean;
    multiDataSources?: boolean;
}

class VisualisationConfigurationPresentationTitle extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);
        // Initializing the state to default.
        this.state = { isStacked: this.props.isStacked, multiDataSources: false };
    }

    /**
     * Occurs once the component is about to receive props.
     */
    UNSAFE_componentWillReceiveProps(nextProps: Readonly<AppProps>, nextContext: any) {
        /* Check how many data sources exists  */
        if (nextProps.multiDataSources != this.state.multiDataSources) {
            if (!nextProps.multiDataSources) {
                this.props.onStackToggleChange(false);
                this.setState({ isStacked: false });
            }
            this.setState({ multiDataSources: nextProps.multiDataSources });
        }

        if (nextProps.isStacked !== this.state.isStacked) {
            this.setState({ isStacked: nextProps.isStacked });
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classNames(classes.sectionTitleContainer)}>
                {this.props.visualisationType === VisualisationType.TIME_SERIES && (
                    <div className={classNames(classes.flexSpaceBetween, classes.fullWidth)}>
                        <Typography variant="body2" className={classNames(classes.sectionTitle)}>
                            Graph
                        </Typography>
                        <div className={classNames(classes.flexVMiddle)}>
                            <FormControlLabel
                                className={classNames(classes.marginRightLarge)}
                                style={{ pointerEvents: "none" }}
                                control={
                                    <SternumSwitch
                                        style={{ pointerEvents: "auto" }}
                                        color="primary"
                                        checked={this.state.isStacked}
                                        onChange={() => {
                                            this.props.onStackToggleChange(!this.state.isStacked);
                                            this.setState({ isStacked: !this.state.isStacked });
                                        }}
                                    />
                                }
                                labelPlacement="end"
                                label={
                                    <Typography
                                        variant="body2"
                                        color={!this.state.isStacked ? "textSecondary" : "primary"}
                                    >
                                        {"Stacked"}
                                    </Typography>
                                }
                            />
                            <FormControlLabel
                                style={{ pointerEvents: "none" }}
                                control={
                                    <SternumSwitch
                                        style={{ pointerEvents: "auto" }}
                                        color="primary"
                                        checked={this.props.isLogarithmicScale}
                                        onChange={() => {
                                            this.props.onLogarithmicScaleToggleChange(!this.props.isLogarithmicScale);
                                        }}
                                    />
                                }
                                labelPlacement="end"
                                label={
                                    <Typography
                                        variant="body2"
                                        color={!this.props.isLogarithmicScale ? "textSecondary" : "primary"}
                                    >
                                        {"Logarithmic scale"}
                                    </Typography>
                                }
                            />
                        </div>
                    </div>
                )}
                {this.props.visualisationType === VisualisationType.METRIC && (
                    <Typography variant="body2" className={classNames(classes.sectionTitle)}>
                        Metric
                    </Typography>
                )}

                {/* Values */}
                {this.props.visualisationType === VisualisationType.VALUES_TABLE && (
                    <React.Fragment>
                        {/* Values */}
                        <Typography variant="body2" className={classNames(classes.sectionTitle)}>
                            Table
                        </Typography>
                    </React.Fragment>
                )}

                {this.props.visualisationType === VisualisationType.PIE_CHART && (
                    <React.Fragment>
                        <Typography variant="body2" className={classNames(classes.sectionTitle)}>
                            Pie Chart
                        </Typography>
                    </React.Fragment>
                )}

                {this.props.visualisationType === VisualisationType.VALUES_GRAPH && (
                    <div className={classNames(classes.flexSpaceBetween, classes.fullWidth)}>
                        <Typography variant="body2" className={classNames(classes.sectionTitle)}>
                            Bar
                        </Typography>
                        <div className={classNames(classes.flexVMiddle)}>
                            <FormControlLabel
                                style={{ pointerEvents: "none" }}
                                control={
                                    <SternumSwitch
                                        style={{ pointerEvents: "auto" }}
                                        color="primary"
                                        checked={this.props.isLogarithmicScale}
                                        onChange={() => {
                                            this.props.onLogarithmicScaleToggleChange(!this.props.isLogarithmicScale);
                                        }}
                                    />
                                }
                                labelPlacement="end"
                                label={
                                    <Typography
                                        variant="body2"
                                        color={!this.props.isLogarithmicScale ? "textSecondary" : "primary"}
                                    >
                                        {"Logarithmic scale"}
                                    </Typography>
                                }
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withStyles(visualisationConfigurationPresentationTitleStyle, { withTheme: true })(
        VisualisationConfigurationPresentationTitle
    )
);
