import React from "react";

import { SternumIconProps } from "./SternumIcon.types";

export const SternumLongLogoOneColorIcon = React.forwardRef<SVGSVGElement, SternumIconProps>(
    function SternumLongLogoOneColorIcon({ className, color, height = 26, width = 155, ...props }, ref) {
        return (
            <svg
                ref={ref}
                className={className}
                width={width}
                height={height}
                viewBox="0 0 572 96"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                aria-label="sternum logo with text - one color"
                {...props}
            >
                <path
                    d="M128.582 69.7025C131.123 72.553 134.238 74.8348 137.724 76.3982C141.209 77.9615 144.985 78.7712 148.805 78.7745C160.296 78.7745 163.858 72.5733 163.858 67.7504C163.858 60.0568 156.045 57.9898 147.656 55.6932C137.43 53.0521 125.939 50.0661 125.939 37.5496C125.939 26.9849 135.361 19.7505 148.001 19.7505C157.768 19.7505 165.352 22.9658 170.752 28.7074L165.926 34.5639C161.1 29.2816 154.436 26.8701 147.427 26.8701C139.728 26.8701 134.212 31.0041 134.212 37.0902C134.212 43.5208 141.566 45.4729 149.725 47.6547C160.181 50.4106 172.131 53.7411 172.131 67.1763C172.131 76.4776 165.811 86.0089 148.461 86.0089C137.315 86.0089 129.156 81.7601 123.871 75.7886L128.582 69.7025Z"
                    fill={color || "currentColor"}
                />
                <path
                    d="M201.777 27.7898H181.554V20.6702H230.159V27.7898H209.821V84.7467H201.892V27.7898H201.777Z"
                    fill={color || "currentColor"}
                />
                <path
                    d="M244.174 20.7854H286.114V27.905H252.103V48.5749H285.425V55.6944H252.103V77.7422H286.114V84.8621H244.174V20.7854Z"
                    fill={color || "currentColor"}
                />
                <path
                    d="M325.531 59.3693H312.777V84.8621H304.848V20.7854H330.587C342.307 20.7854 350.695 28.2495 350.695 40.0774C350.695 51.5604 342.767 57.991 334.034 58.6803L351.385 84.7473H341.963L325.531 59.3693ZM329.553 27.7902H312.777V52.2494H329.553C337.136 52.2494 342.307 47.1969 342.307 39.9625C342.307 32.728 337.136 27.7902 329.553 27.7902Z"
                    fill={color || "currentColor"}
                />
                <path
                    d="M375.859 33.0725V84.7473H367.93V20.7854H376.088L412.973 71.4265V20.7854H420.9V84.8621H413.202L375.859 33.0725Z"
                    fill={color || "currentColor"}
                />
                <path
                    d="M439.399 20.7854H447.33V59.8286C447.33 71.5414 453.648 78.8905 465.483 78.8905C477.321 78.8905 483.639 71.5414 483.639 59.8286V20.7854H491.569V59.8286C491.569 75.7901 482.834 85.8956 465.483 85.8956C448.134 85.8956 439.399 75.6753 439.399 59.8286V20.7854Z"
                    fill={color || "currentColor"}
                />
                <path
                    d="M563.959 31.4648L542.127 84.7473H538.909L517.077 31.4648V84.7473H509.15V20.7854H520.638L540.518 69.5892L560.512 20.7854H572.004V84.8621H564.073V31.4648H563.959Z"
                    fill={color || "currentColor"}
                />
                <path
                    d="M48.0307 96C39.9097 95.9926 31.9234 93.9297 24.8163 90.0037C17.7093 86.0777 11.7132 80.4163 7.38758 73.5478C3.06203 66.6794 0.547935 58.8281 0.0799162 50.726C-0.388104 42.624 1.2052 34.5355 4.71114 27.2153C4.98585 26.6374 5.47891 26.192 6.08198 25.9772C6.68502 25.7623 7.3488 25.7955 7.9274 26.0694C8.50605 26.3432 8.95217 26.8355 9.16783 27.438C9.38349 28.0404 9.35102 28.7038 9.07757 29.2823C6.2389 35.1118 4.78399 41.517 4.82602 48C4.82606 56.5673 7.36961 64.9418 12.1347 72.0635C16.8998 79.1852 23.6722 84.734 31.5944 88.0073C39.5167 91.2805 48.2325 92.1312 56.6391 90.4516C65.0453 88.7716 72.7639 84.6373 78.8175 78.5712C84.8711 72.5051 88.9881 64.7808 90.6465 56.3753C92.3049 47.9701 91.4307 39.2618 88.1341 31.3533C84.8379 23.4448 79.2678 16.6914 72.1288 11.9483C64.9899 7.20517 56.6033 4.68546 48.0307 4.70817C39.3746 4.65644 30.9117 7.26188 23.7855 12.1723C23.2522 12.5226 22.6015 12.6467 21.9765 12.5175C21.3516 12.3883 20.8037 12.0163 20.4532 11.4833C20.1027 10.9504 19.9785 10.3001 20.1078 9.67558C20.2371 9.05106 20.6093 8.50342 21.1426 8.15321C28.3785 3.30194 36.7968 0.50547 45.4991 0.0623174C54.2009 -0.380836 62.8602 1.54597 70.552 5.63701C78.2437 9.72809 84.6796 15.8298 89.1722 23.2909C93.6652 30.7519 96.0462 39.2921 96.0609 48C96.0277 60.72 90.9569 72.9098 81.9565 81.9042C72.9562 90.8987 60.7589 95.9668 48.0307 96Z"
                    fill={color || "currentColor"}
                />
                <path
                    d="M48.0318 85.7803C40.5548 85.7803 33.2457 83.5646 27.0289 79.4133C20.8121 75.2621 15.9666 69.3614 13.1053 62.4582C10.244 55.5547 9.49542 47.9586 10.9541 40.6301C12.4128 33.3013 16.0132 26.5696 21.3002 21.2859C26.5872 16.0023 33.3232 12.4041 40.6563 10.9464C47.9897 9.48865 55.5907 10.2368 62.4986 13.0963C69.4065 15.9558 75.3106 20.7981 79.4645 27.011C83.6183 33.2239 85.8354 40.5282 85.8354 48.0003C85.8362 48.3174 85.7742 48.6317 85.6531 48.9248C85.5324 49.218 85.3546 49.4842 85.1306 49.7087C84.9066 49.9332 84.6401 50.1112 84.3471 50.2326C84.0541 50.3541 83.7397 50.4169 83.4227 50.4169C83.1053 50.4169 82.7909 50.3541 82.4979 50.2326C82.2049 50.1112 81.9388 49.9332 81.7144 49.7087C81.4904 49.4842 81.3129 49.218 81.1919 48.9248C81.0709 48.6317 81.0089 48.3174 81.0096 48.0003C81.0096 41.4822 79.0755 35.1102 75.4516 29.6905C71.8281 24.2707 66.6775 20.0466 60.6516 17.5521C54.6256 15.0577 47.9949 14.405 41.5981 15.6767C35.2009 16.9483 29.3248 20.0872 24.7127 24.6963C20.1007 29.3054 16.9598 35.1778 15.6874 41.5709C14.4149 47.9637 15.068 54.5903 17.564 60.6125C20.06 66.6346 24.2869 71.7817 29.7101 75.4031C35.1333 79.0245 41.5091 80.9575 48.0318 80.9575C53.4421 80.9818 58.7743 79.6618 63.5477 77.116C68.3215 74.5705 72.3872 70.8789 75.3793 66.3736C75.5527 66.1096 75.7763 65.8825 76.038 65.7053C76.2993 65.5277 76.593 65.4032 76.9026 65.3394C77.2119 65.2755 77.5311 65.2729 77.8414 65.3323C78.1518 65.3914 78.4474 65.511 78.7113 65.6846C78.9755 65.8581 79.2028 66.0819 79.3803 66.3429C79.5578 66.604 79.6822 66.8979 79.7464 67.2069C79.8102 67.5163 79.8128 67.835 79.7534 68.1451C79.6944 68.4553 79.5744 68.7507 79.401 69.0147C75.9524 74.1695 71.2863 78.3957 65.8154 81.32C60.3442 84.244 54.2363 85.7759 48.0318 85.7803Z"
                    fill={color || "currentColor"}
                />
                <path
                    d="M48.0288 75.6747C44.3927 75.6898 40.7906 74.9761 37.4354 73.576C34.08 72.1755 31.0399 70.1167 28.4947 67.5217C24.6297 63.647 21.9978 58.7163 20.9303 53.3503C19.8629 47.9842 20.4074 42.4225 22.4956 37.3648C24.5838 32.3074 28.1222 27.9802 32.6655 24.9278C37.2088 21.8753 42.5542 20.2341 48.0288 20.2107C51.6649 20.1955 55.267 20.9092 58.6222 22.3096C61.9775 23.71 65.0176 25.7687 67.5628 28.3638C67.7813 28.5824 67.9551 28.842 68.0735 29.1277C68.192 29.4134 68.2529 29.7196 68.2529 30.0289C68.2529 30.3381 68.192 30.6443 68.0735 30.93C67.9551 31.2157 67.7813 31.4753 67.5628 31.6939C67.344 31.9126 67.0842 32.086 66.7982 32.2044C66.5122 32.3227 66.2059 32.3836 65.8966 32.3836C65.587 32.3836 65.2807 32.3227 64.9947 32.2044C64.7091 32.086 64.4493 31.9126 64.2304 31.6939C62.1066 29.5654 59.5806 27.8804 56.7992 26.737C54.0175 25.5935 51.0364 25.0145 48.0288 25.0337C41.9638 25.0481 36.1467 27.4395 31.827 31.6939C28.6158 34.89 26.4275 38.9676 25.5397 43.4091C24.652 47.8506 25.1049 52.4556 26.841 56.639C28.5771 60.8227 31.5181 64.3965 35.2905 66.9066C39.0628 69.417 43.4967 70.7499 48.0288 70.737C51.4073 70.7458 54.7459 70.007 57.8048 68.5729C60.8638 67.1392 63.5669 65.046 65.7198 62.444C67.8732 59.8421 69.4231 56.7959 70.2582 53.5242C71.0933 50.2524 71.193 46.8367 70.5501 43.5217C70.4892 43.22 70.4885 42.9091 70.5479 42.6067C70.6073 42.3047 70.7258 42.0175 70.8966 41.7612C71.0671 41.5046 71.2867 41.2845 71.5428 41.1132C71.7985 40.9419 72.0857 40.8223 72.3879 40.7621C72.6901 40.7015 73.0012 40.7015 73.3035 40.7613C73.6053 40.8211 73.8928 40.94 74.1493 41.111C74.4054 41.2819 74.6253 41.5016 74.7966 41.7579C74.9674 42.0138 75.0866 42.301 75.1464 42.6031C76.0247 47.0419 75.7922 51.6285 74.4689 55.9559C73.1459 60.2833 70.7734 64.2167 67.5628 67.4069C65.0393 70.0388 62.0059 72.1293 58.6473 73.5509C55.2891 74.9724 51.676 75.695 48.0288 75.6747Z"
                    fill={color || "currentColor"}
                />
                <path
                    d="M48.0273 64.3066C44.8127 64.3199 41.6671 63.3776 38.9901 61.5994C36.3132 59.8212 34.226 57.2875 32.9941 54.3204C31.7622 51.3536 31.4412 48.0874 32.072 44.9375C32.7029 41.7876 34.2569 38.8965 36.5366 36.6318C36.9785 36.1902 37.5778 35.9421 38.2026 35.9421C38.8277 35.9421 39.427 36.1902 39.8688 36.6318C40.3109 37.0732 40.5589 37.6725 40.5589 38.2969C40.5589 38.9212 40.3109 39.5205 39.8688 39.9621C37.9568 41.912 36.8075 44.4822 36.6294 47.2068C36.4513 49.9313 37.256 52.6293 38.8982 54.8115C40.54 56.9936 42.91 58.5152 45.5784 59.1004C48.2469 59.6857 51.0368 59.2958 53.4425 58.0012C55.8479 56.7071 57.7093 54.594 58.689 52.0452C59.6692 49.4968 59.7024 46.6817 58.7835 44.1108C57.8643 41.5395 56.0534 39.3828 53.6794 38.0318C51.3054 36.6808 48.5255 36.2248 45.8441 36.7467C45.2349 36.8672 44.6031 36.7414 44.0868 36.3967C43.5705 36.0521 43.2122 35.5169 43.0904 34.9085C42.9686 34.3001 43.0934 33.6683 43.4373 33.1518C43.7812 32.6352 44.3163 32.2761 44.9249 32.1534C47.1501 31.713 49.4433 31.7434 51.6564 32.2428C53.8695 32.7421 55.953 33.6993 57.7735 35.0526C59.5935 36.4058 61.1099 38.1252 62.2247 40.0998C63.3396 42.0745 64.0278 44.2607 64.2459 46.5174C64.4637 48.7742 64.2057 51.0516 63.489 53.2027C62.7724 55.3539 61.6129 57.3311 60.0847 59.007C58.5569 60.683 56.6944 62.0203 54.6175 62.9331C52.541 63.8458 50.2958 64.3136 48.0273 64.3066Z"
                    fill={color || "currentColor"}
                />
            </svg>
        );
    }
);
