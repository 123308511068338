import React from "react";
import classNames from "classnames";

import { useItemCounterStyle } from "./ItemCounter.style";

export interface ItemCounterProps {
    count: number;
}

export const ItemCounter = React.forwardRef<HTMLDivElement, ItemCounterProps>(({ count, ...props }, ref) => {
    const classes = useItemCounterStyle();

    return (
        <div ref={ref} className={classNames(classes.itemCounter)} {...props}>
            +{count}
        </div>
    );
});

ItemCounter.displayName = "ItemCounter";
