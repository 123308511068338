import { Typography } from "@material-ui/core";
import classNames from "classnames";
import * as React from "react";
import { useEffect, useState } from "react";
import ServiceWire from "../../lib/services/ServiceWire";
import DeviceInteractiveMap, { DeviceDefinitionGeoContainer } from "../DeviceInteractiveMap/DeviceInteractiveMap";
import GraphLoader from "../SUI/SternumLoaders/GraphLoader";
import useMapModalStyles from "./MapModalStyles";

interface AppProps {
    entityId: string;
    createdFrom: number;
    createdTo: number;
    title: string;
    fullScreenDisplay: boolean;
}

const DeviceDefinitionLocationModal = ({ entityId, createdFrom, createdTo, title, fullScreenDisplay }: AppProps) => {
    const {
        loadingPlaceholder,
        container,
        descriptionText,
        marginBottomXLarge,
        listPaper,
        marginBottom,
        marginBottomMedium,
        tableContainer,
        tableInner,
        mapLoader,
        flexVMiddle,
        flexCenter,
    } = useMapModalStyles();

    const [isLoading, setIsLoading] = useState(true);
    const [mapData, setMapData] = useState<DeviceDefinitionGeoContainer>(null);

    useEffect(() => {
        async function fetchMapData() {
            await ServiceWire.getSternumService()
                .getDeviceDefinitionVersionGeolocationData(entityId, createdFrom, createdTo)
                .then((data) => {
                    setMapData(data["total_devices"]);
                    setIsLoading(false);
                });
        }
        fetchMapData();
    }, []);

    return (
        <div className={container}>
            <div className={marginBottomMedium}>
                {/* Title Section */}
                <Typography variant={"h6"}>{title}</Typography>
            </div>
            {isLoading && (
                <div className={classNames(mapLoader, flexVMiddle, flexCenter)}>
                    <GraphLoader />
                </div>
            )}
            {!isLoading && (
                <div className={tableContainer}>
                    <DeviceInteractiveMap
                        id={entityId}
                        maxColor="#1D489B"
                        minColor="#EFF6FF"
                        deviceDefinitionMapView={mapData}
                    />
                </div>
            )}
        </div>
    );
};

export default DeviceDefinitionLocationModal;
