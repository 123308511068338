import React, { useMemo, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import classNames from "classnames";

import { ArrowDownIcon } from "../../../SUI/SternumIcon";
import { useDeviceAlertsStatistics } from "./AlertsSummary.hooks";
import { useStyle } from "./AlertsSummary.styles";
import ServiceWire from "../../../../lib/services/ServiceWire";
import { DeviceAlertsStatistics } from "../../../../lib/state/DeviceAlertsStatistics";
import Utils from "../../../../lib/infra/Utils";
import { BlueCheckmarkIcon } from "../../../SUI/SternumIcon/BlueCheckmark.icon";
import { SternumTooltip } from "../../../SUI/SternumTooltip";

export interface AlertsSummaryProps {
    deviceId: string;
    startDate: Date;
    endDate: Date;
}

function groupAlertTriggersPerCategory(stats: DeviceAlertsStatistics) {
    const map = stats.triggersUsage.reduce<Record<string, DeviceAlertsStatistics["triggersUsage"]>>((acc, entry) => {
        const category = entry.trigger?.triggerCategory || entry.traceDefinition?.traceCategory;

        acc[category] ||= [];
        acc[category].push(entry);

        return acc;
    }, {});

    return Object.entries(map).map(([category, entries]) => ({ category, entries }));
}

export function AlertsSummary({ deviceId, endDate, startDate }: AlertsSummaryProps) {
    const MAX_VISIBLE_TRIGGERS = 3;
    const MAX_VISIBLE_CATEGORIES = 5;

    const classes = useStyle();
    const [isExpanded, setExpanded] = useState(false);
    const [showMoreEnabled, setShowMoreEnabled] = useState(false);

    const { alertsStatistics } = useDeviceAlertsStatistics({
        deviceId,
        createdFrom: startDate.getTime(),
        createdTo: endDate.getTime(),
    });

    const triggersByCategory = useMemo(
        () => (alertsStatistics ? groupAlertTriggersPerCategory(alertsStatistics) : null),
        [alertsStatistics]
    );

    const isShowMoreEnabledButtonVisible =
        isExpanded &&
        (triggersByCategory?.length > MAX_VISIBLE_CATEGORIES ||
            triggersByCategory?.some(({ entries }) => {
                return entries.length > MAX_VISIBLE_TRIGGERS;
            }));
    const maxVisibleTriggers = !isShowMoreEnabledButtonVisible || showMoreEnabled ? undefined : MAX_VISIBLE_TRIGGERS;
    const maxVisibleCategories =
        !isShowMoreEnabledButtonVisible || showMoreEnabled ? undefined : MAX_VISIBLE_CATEGORIES;

    return (
        <Box className={classNames(classes.container, isExpanded && "expanded")}>
            <Box className={classes.header} onClick={() => setExpanded((prevState) => !prevState)}>
                <Typography className={classes.title}>Alerts Summary</Typography>
                <ArrowDownIcon className={classNames(classes.arrowIcon, isExpanded && "expanded")} />
            </Box>

            {isExpanded && (
                <>
                    <Box className={classes.categoriesGrid}>
                        {triggersByCategory.slice(0, maxVisibleCategories).map(({ category, entries }) => {
                            const categoryLabel =
                                ServiceWire.getConfigurationService().getTraceCategoriesMap().get(category)
                                    ?.displayName || category;

                            return (
                                <Box key={category} className={classes.categoryContainer}>
                                    <Typography className={classes.categoryTitle}>{categoryLabel}</Typography>

                                    <Box className={classes.categoryTriggers}>
                                        {entries.slice(0, maxVisibleTriggers).map((entry) => {
                                            const id = entry.trigger?.entityId || entry.traceDefinition?.entityId;
                                            const name =
                                                entry.trigger?.displayName || entry.traceDefinition?.displayName;
                                            const count = entry.alertsCount || entry.tracesCount;

                                            return (
                                                <Box key={id} className={classes.triggerStatsRow}>
                                                    <Box className={classes.triggerStatsRowLeft}>
                                                        <BlueCheckmarkIcon style={{ flexShrink: 0 }} />
                                                        <SternumTooltip useWrapper={false} title={name}>
                                                            <Typography className={classes.triggerName}>
                                                                {name}
                                                            </Typography>
                                                        </SternumTooltip>
                                                    </Box>

                                                    <Typography className={classes.triggerAlertsCount}>
                                                        {Utils.numberToHumanString(count)}
                                                    </Typography>
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>

                    {isShowMoreEnabledButtonVisible && (
                        <Box className={classes.footer}>
                            <Typography
                                className={classes.showMore}
                                onClick={() => setShowMoreEnabled((prevState) => !prevState)}
                            >
                                {showMoreEnabled ? "Show less" : "Show more"}
                            </Typography>
                        </Box>
                    )}
                </>
            )}
        </Box>
    );
}
