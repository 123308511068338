import EntityBase from "./EntityBase";
import EntityType from "./EntityType";
import TableRowData from "./TableRowData";

function toTitleCase(str) {
    return str.replace("_", " ").replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

/**
 * Represents a trace definition in sternum.
 */

class TraceDefinitionInfo extends EntityBase implements TableRowData {
    /**
     * Constructor.
     */
    constructor(
        public traceDefinitionId: string,
        public created: number,
        public updated: number,
        public displayName: string,
        public traceEventName: string,
        public traceCategory: string,
        public traceType: string,
        public transmitFrequency: number,
        public traceInterest: string,
        public displayInUI: boolean,
        public canBeUsedForAlerts: boolean,
        public description: string
    ) {
        super(traceDefinitionId, created, updated, EntityType.TraceDefinition);
    }

    /**
     * Converts json to issue info object.
     */
    public static fromJsonObject(jsonObject: Object) {
        return new TraceDefinitionInfo(
            jsonObject["entity_id"],
            jsonObject["created"],
            jsonObject["updated"],
            jsonObject["display_name"],
            jsonObject["trace_event_name"],
            jsonObject["trace_category"],
            jsonObject["trace_type"],
            jsonObject["transmit_frequency"],
            jsonObject["event_interest"],
            jsonObject["display_in_ui"],
            jsonObject["can_be_used_for_alerts"],
            jsonObject.hasOwnProperty("description") ? jsonObject["description"] : ""
        );
    }

    /**
     * Converts Trace definition object into json object.
     */
    public toJsonObject() {
        return {
            display_name: this.displayName,
            trace_event_name: this.traceEventName,
            trace_category: this.traceCategory,
            transmit_frequency: this.transmitFrequency,
            event_interest: this.traceInterest,
            description: this.description,
        };
    }

    /**
     * Help function to determine if given trace is system trace
     */
    public isSystemTrace(): boolean {
        return this.traceType === "SYSTEM";
    }

    /**
     * Help function to generate trigger and trace connection
     */
    public generateTraceTriggerConnection(): string {
        return JSON.stringify({ trace_type: this.traceType, display_name: this.displayName });
    }

    public getCategoryDisplayName() {
        return toTitleCase(this.traceCategory);
    }
    /**
     * Gets the value for the given column id.
     */
    public getColumnValue(columnHeaderId: string): any {
        return this[columnHeaderId];
    }

    /**
     * Returns whether given row is any different in details than current trace info.
     * Shallow comparison, in other words.
     * @param other The row to compare with.
     */
    public isDifferentFrom(other: TableRowData): boolean {
        // Row must of of type trace info.
        if (!(other instanceof TraceDefinitionInfo)) {
            return true;
        }

        let otherTraceInfo = other as TraceDefinitionInfo;

        // Comparing base trace properties.
        if (
            otherTraceInfo.traceDefinitionId !== this.traceDefinitionId ||
            otherTraceInfo.displayName !== this.displayName ||
            otherTraceInfo.traceEventName !== this.traceEventName ||
            otherTraceInfo.traceCategory !== this.traceCategory ||
            otherTraceInfo.traceType !== this.traceType ||
            otherTraceInfo.traceInterest !== this.traceInterest
        ) {
            return true;
        }

        return false;
    }
}

export default TraceDefinitionInfo;
