import { grey } from "@material-ui/core/colors";
import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { sternumTheme } from "../App";
import commonStyle from "../CommonStyle";

const deviceListFilterPopoverStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: {
            minWidth: theme.spacing(150),
            maxHeight: theme.spacing(100),
            overflowY: "auto",
        },
        titleTypography: {
            display: "flex",
            alignItems: "center",
            width: theme.spacing(18),
        },
        resetAndApplyButtons: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(4),
        },
        formControl: {
            minWidth: theme.spacing(40),
            maxWidth: theme.spacing(80),
        },
        filterByStatusesCheckboxRoot: {
            "&:hover": {
                backgroundColor: `${sternumTheme.lightGrey.main} !important`,
            },
        },
        filterByStatusesCheckboxSelected: {
            backgroundColor: "transparent !important",
            "&:hover": {
                backgroundColor: `${sternumTheme.lightGrey.main} !important`,
            },
        },
        eventsSectionTitleContainer: {
            marginTop: theme.spacing(4),
        },
        eventsSectionTitle: {
            fontSize: "1rem",
            fontWeight: 400,
        },
        advancedLink: {
            cursor: "pointer",
        },
        addEventFilterLink: {
            cursor: "pointer",
        },
        addEventFilterLinkContainer: {
            marginTop: theme.spacing(4),
        },
        deleteEventIcon: {
            cursor: "pointer",
            color: grey[500],
            "&:hover": {
                color: theme.palette.common.black,
            },
        },
        eventFilterContainer: {
            display: "flex",
            alignItems: "flex-end",
        },
        deviceNameSelectionContainer: {
            display: "flex",
            alignItems: "flex-end",
        },
        usedLibrarySelectionContainer: {
            display: "flex",
            alignItems: "flex-end",
        },
    });

export default deviceListFilterPopoverStyle;
