import { makeStyles } from "@material-ui/styles";

export const useItemCounterStyle = makeStyles({
    itemCounter: {
        fontSize: 14,
        fontWeight: 400,
        color: "#221CB6",
        backgroundColor: "#DBE2FE",
        padding: "4px 8px",
        borderRadius: 20,
    },
});
