import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { sternumTheme } from "../App";
import commonStyle from "../CommonStyle";

const newDeviceViewStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),
        loadingContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            overflow: "hidden",
        },
        deviceViewUpperContent: {
            paddingTop: theme.spacing(4),
            paddingRight: theme.spacing(4),
            paddingLeft: theme.spacing(4),
            marginBottom: theme.spacing(4),
        },
        detailsSummaryLine: {
            overflow: "auto",
            display: "flex",
            alignItems: "center",
            marginLeft: theme.spacing(4),
            marginBottom: theme.spacing(4),
            paddingRight: theme.spacing(4),

            "&::-webkit-scrollbar": {
                display: "none",
            },

            "& > *": {
                flexShrink: 0,
            },
        },
        issuesTableWrapper: {
            height: "calc(100% - 37px)",
        },
        detailsSummaryLinuxView: {
            overflow: "auto",
            marginLeft: theme.spacing(4),
            marginBottom: 0,
            paddingRight: theme.spacing(4),

            "&::-webkit-scrollbar": {
                display: "none",
            },

            "& > *": {
                flexShrink: 0,
            },
        },
        titleContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        filterContainer: {
            minHeight: theme.spacing(17),
        },
        title: {
            marginRight: theme.spacing(2),
            fontSize: 20,
            fontWeight: 600,
        },
        boardTagText: {
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: 14,
        },
        formControl: {
            minWidth: theme.spacing(40),
            maxWidth: theme.spacing(80),
        },
        boardTagsHolder: {
            "&:hover .addToBoard": {
                visibility: "visible",
            },
        },
        addToBoard: {
            visibility: "hidden",
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: "0.8125rem",
            cursor: "pointer",
            textDecoration: "none",
            color: theme.palette.common.black,
            "&:hover": {
                textDecoration: "underline",
            },
        },
        categoryLabel: {
            fontSize: 14,
            marginBottom: "-1px",
        },
        securityAlertsTabContent: {
            paddingTop: theme.spacing(2),
        },
        deviceInfoTabContent: {
            padding: theme.spacing(4),
        },
        tracesListTabContent: {
            paddingTop: theme.spacing(2),
        },
        deviceDefinitionLibrariesListTabContent: {
            paddingTop: theme.spacing(2),
        },
        popoverPaper: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            minWidth: theme.spacing(30),
            marginTop: theme.spacing(-3),
        },
        sternumEventsPaper: {
            width: "100%",
            height: "calc(100% - 37px)",
        },
        graphTitle: {
            fontSize: 18,
            fontWeight: 600,
        },
        metricVisualisationsContainer: {
            marginBottom: theme.spacing(4),
            display: "flex",
            alignItems: "flex-start",
        },

        moreInfoText: {
            cursor: "pointer",
            fontSize: 14,
            fontWeight: 400,
            "&:hover": {
                textDecoration: "underline",
            },
        },

        deviceDefinitionInformationTitle: {
            fontSize: 14,
            fontWeight: 600,
        },
        deviceDefinitionInformationContent: {
            fontSize: 14,
        },

        infoCircleIconSize: {
            fontSize: "16px",
        },

        sternumDeviceEventsListContainer: {
            padding: theme.spacing(3),
            background: "#fff",
            borderRadius: 14,
            boxShadow: "0px 0px 23px rgba(231, 232, 235, 0.25)",
            marginLeft: theme.spacing(2),
            flexGrow: 1,
            width: "30%",
            height: "calc(100vh - 450px)",
        },
        sternumDeviceEventsListTitleContainer: {
            padding: theme.spacing(1),
        },

        backButtonIcon: {
            cursor: "pointer",
        },

        eventCountContainer: {
            width: "60%",
            height: "calc(100vh - 450px)",
            padding: theme.spacing(3),
            background: "#fff",
            borderRadius: 14,
            boxShadow: "0px 0px 23px rgba(231, 232, 235, 0.25)",
            marginRight: theme.spacing(2),
            marginLeft: theme.spacing(4),

            "&.mod-half-size": {
                width: "30%",
            },
        },
        eventCountTitleContainer: {
            padding: theme.spacing(1),
        },
        eventCountGraphContainer: {
            height: "calc(100% - 37px)",
        },

        metricsBarContainer: {
            marginTop: theme.spacing(4),
            marginRight: theme.spacing(4),
            marginLeft: theme.spacing(4),
        },

        withoutTopBorderRadius: {
            borderTopLeftRadius: "0 !important",
        },

        eventCountOverTimeContainer: {
            height: 200,
            padding: theme.spacing(3, 3, 0, 3),
            background: "#fff",
            boxShadow: "0px 0px 23px rgba(231, 232, 235, 0.25)",
            borderRadius: 14,
            marginRight: theme.spacing(4),
            marginLeft: theme.spacing(4),
            marginBottom: theme.spacing(4),
        },

        eventCountOverTimeTitleContainer: {
            padding: theme.spacing(1),
        },
        eventCountOverTimeGraphContainer: {
            height: "calc(100% - 37px)",
        },
        deviceSubTitle: {
            fontSize: "14px",
        },

        multipleDevicesGraphContainer: {
            padding: "15px",
            background: "#fff",
            boxShadow: "0px 0px 23px rgba(231, 232, 235, 0.25)",
            borderRadius: 14,
            marginRight: theme.spacing(4),
            marginLeft: theme.spacing(4),
            marginBottom: theme.spacing(4),
        },
        multipleDevicesGraphTitleContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "25px",
        },
        multipleDevicesGraphLeft: {
            display: "flex",
            alignItems: "center",
        },
        multipleDevicesGraphRight: {
            display: "flex",
            alignItems: "center",
            gap: 24,
        },
        hiddenDeviceProfilesContainer: {
            display: "flex",
            flexFlow: "column",
            gap: 14,
        },
        multipleDevicesGraphContent: {
            display: "flex",
        },
        multipleDevicesGraphLoaderContainer: {
            margin: "auto",
        },
        multipleDevicesGraphContentDevices: {
            width: "min-content",
        },
        multipleDevicesGraphContentGraph: {
            flex: "1",
        },

        expandIcon: {
            fontSize: "0.875rem",
            marginRight: theme.spacing(1),
            cursor: "pointer",
        },

        eventsListContainer: {
            padding: theme.spacing(4),
            paddingTop: 0,
            width: "auto",
        },
        eventsListContainerFlexHeight: {
            minHeight: "calc(100vh - 65px - 20px)",
        },

        queryEditorFooter: {
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
        },

        categoryIndicationBox: {
            width: theme.spacing(2),
            height: theme.spacing(2),
            marginRight: theme.spacing(1),
            borderRadius: "3px",
            border: `1px solid ${sternumTheme.lightGrey.main}`,
            flexShrink: 0,
        },
        tracesListPaper: {
            height: "100%",
            maxHeight: "60vh",
            paddingTop: theme.spacing(2),

            "&.borderTop": {
                borderTop: "1px solid #E4E4E4",
            },
        },

        tracesListPaperFlexHeight: {
            maxHeight: "750px",
            paddingTop: theme.spacing(2),

            "&.borderTop": {
                borderTop: "1px solid #E4E4E4",
            },
        },

        previewMetricContent: {
            fontWeight: 200,
        },

        metricContainer: {
            position: "relative",
            width: "220px",
            height: "120px",
            borderRadius: "4px",
            boxShadow: "1px 2px 6px 0 rgba(0,0,0,0.07)",
            border: "1px solid #e9ecf1",
            marginRight: "15px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },

        metricTitle: {
            position: "absolute",
            top: "5px",
            left: "5px",
            fontWeight: 500,
            fontSize: "0.8125rem",
        },

        addMetricContainer: {
            width: "220px",
            height: "120px",
            display: "flex",
            alignItems: "center",
            borderRadius: "4px",
            justifyContent: "center",
        },

        tracesListContainer: {
            height: "100%",
            padding: theme.spacing(3),
            background: "#fff",
            boxShadow: "0px 0px 23px rgba(231, 232, 235, 0.25)",
            borderRadius: 14,
        },

        filtersContainer: {
            display: "flex",
            alignItems: "center",
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(8),
        },
        filterLabel: {
            fontSize: "0.8125rem",
        },
        categorySelectComponent: {
            minWidth: theme.spacing(29),
        },
        interestSelectComponent: {
            minWidth: theme.spacing(23),
        },

        eventsSectionTitleContainer: {},
        eventsSectionTitleContentContainer: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        showHideTableDataBadge: {
            display: "flex",
            alignItems: "center",
            height: 30,
            padding: 6,
            paddingLeft: 10,
            marginLeft: "auto",
            borderRadius: 30,
            background: "#C1DAFC",
            cursor: "pointer",

            "&.open": {
                background: "#1B6FDE",
            },
        },
        showHideTableDataText: {
            marginRight: 8,
            fontSize: 14,
            color: "#1B6FDE",

            "&.open": {
                color: "#fff",
            },
        },
        dropdownIconContainer: {
            width: 18,
            height: 18,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#1B6FDE",
            borderRadius: "50%",

            "&.open": {
                background: "#C1DAFC",
            },
        },
        dropdownIcon: {
            transition: "transform 0.2s ease",
        },
        dropdownIconExpanded: {
            transform: "rotate(-90deg)",
        },
        eventsSectionTitleTypography: {
            fontWeight: 500,
            fontSize: 16,
            color: "#ACB4BD",
            marginLeft: theme.spacing(1),
        },
        filterVerticalDivider: {
            width: 1,
            height: 20,
            background: "#C4C4C4",
            margin: theme.spacing(0, 3),
        },
        filterChip: {
            maxWidth: 200,
        },
        chipsContainer: {
            maxWidth: "calc(100% - 24px - 31px - 53px - 16px - 10px)",
            overflow: "auto",

            "&::-webkit-scrollbar": {
                display: "none",
                // Chrome & Safari
                width: 0,
                height: 0,
            },
            scrollbarWidth: "none", // Firefox
            msOverflowStyle: "none", // IE
        },
        tabsRoot: {
            width: "100%",
            marginRight: theme.spacing(2),
            minHeight: 36,
        },
        tabsWrapper: {
            margin: "0 20px",
        },
        tabsIndicator: {
            backgroundColor: "#1890ff",
        },
        tabLabel: {
            textTransform: "none",
            minWidth: 50,
            fontWeight: 400,
            padding: "0 12px",
            minHeight: 36,
            marginRight: theme.spacing(2),
            "&:hover": {
                color: "#40a9ff",
                opacity: 1,
            },
            "&$selected": {
                color: "#1890ff",
                fontWeight: 500,
            },
            "&:focus": {
                color: "#40a9ff",
            },
        },

        popover: {
            "& .MuiPopover-paper": {
                borderRadius: 20,
                boxShadow: "0px 6px 250px 0px #0000001F",
                padding: "15px 0px",
            },
        },

        exportItemPadding: {
            padding: theme.spacing(1),
        },

        exportPopperIconSize: {
            "font-size": theme.spacing(3),
        },

        loaderContainer: {
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        exportHover: {
            "&:hover": {
                background: "#FFF1F5",
            },
        },
    });

export default newDeviceViewStyle;
