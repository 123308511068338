import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

import { commonHome } from "../Home.style";

import stairsSvgPath from "../../../images/stars.svg";

export const useNavigationBarStyle = makeStyles((theme: Theme) => ({
    mainTitleContainer: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        display: "flex",
        justifyContent: "center",
        height: 82,
        background: commonHome.darkBackground,
        backgroundImage: `url("${stairsSvgPath}")`,
        color: "white",
        zIndex: 100,
    },

    navigationContainer: {
        zIndex: 10,
    },

    bigNavigationButtonContainer: {
        "&&": {
            [`@media (max-width: ${commonHome.mediaQueriesNavigationMaxWidth})`]: {
                display: "none",
            },
        },
    },

    smallNavigationButtonContainer: {
        "&&": {
            [`@media not (max-width: ${commonHome.mediaQueriesNavigationMaxWidth})`]: {
                display: "none",
            },
        },
    },

    buttonContainer: {
        display: "flex",
        alignItems: "stretch",
        gap: 24,
    },

    linkButton: {
        fontSize: 16,
        fontWeight: 500,
    },

    menuIconContainer: {
        display: "none",
        alignItems: "center",
        justifyContent: "center",

        [`@media (max-width: ${commonHome.mediaQueriesNavigationMaxWidth})`]: {
            display: "flex",
        },
    },

    iconButton: {
        cursor: "pointer",
    },

    smallMenuContainer: {
        position: "absolute",
        top: "100%",
        right: 0,
        left: 0,
        display: "none",
        alignItems: "center",
        justifyContent: "center",
        padding: "24px 22px",
        background: commonHome.darkBackground,
        backgroundImage: `url("${stairsSvgPath}")`,
        transform: "translateY(-100%)",
        opacity: 0,
        transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
        zIndex: 5,

        [`@media (max-width: ${commonHome.mediaQueriesNavigationMaxWidth})`]: {
            display: "flex",
        },
    },

    smallMenuContainerOpened: {
        transform: "translateY(0)",
        opacity: 1,
    },
}));
