import ListFilter from "./ListFilter";
import Utils from "../infra/Utils";
import EventFilter from "./EventFilter";

/**
 * Represents a filter of devices.
 */
class EntitiesFilter implements ListFilter {
    /**
     * Constructor.
     */
    constructor() {}

    /**
     * Returns whether filter is defined, or all values are nulls.
     */
    public isActive(): boolean {
        return false;
    }

    /**
     * Returns whether current filter is different than given other filter.
     * @param other The other list filter to check with.
     */
    public isDifferentFrom(other: ListFilter): boolean {
        if (!(other instanceof EntitiesFilter)) {
            return true;
        }

        let otherEntitiesFilter = other as EntitiesFilter;

        return false;
    }
}

export default EntitiesFilter;
