import { Icon, Typography } from "@material-ui/core";
import _ from "lodash";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import moment from "moment";
import * as React from "react";
import { withRouter } from "react-router-dom";

import SternumConfiguration from "../../lib/infra/SternumConfiguration";
import AnalyticsService from "../../lib/services/AnalyticsService";
import ServiceWire from "../../lib/services/ServiceWire";
import GetSternumDeviceEventsResponse from "../../lib/state/GetSternumDeviceEventsResponse";
import SternumDeviceEventInfo from "../../lib/state/SternumDeviceEventInfo";
import ProcessTracesDisplayStyle from "./ProcessTracesDisplayStyle";
import SternumDeviceEventsFilter from "../../lib/state/SternumDeviceEventsFilter";
import SternumTable from "../SUI/SternumTable/SternumTable";
import HashSet from "../../lib/infra/HashSet";
import TableColumnHeaderInfo from "../../lib/state/TableColumnHeaderInfo";
import EntityType from "../../lib/state/EntityType";
import ArgumentsListDisplay from "../ArgumentsListDisplay/ArgumentsListDisplay";
import SternumEventTypeDisplay from "../SUI/SternumEventTypeDisplay/SternumEventTypeDisplay";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    entities: SternumDeviceEventInfo[];
    scrollPosition: number;
    pageNumber: number;
    loadingEntities: boolean;
    totalItemCount: number;
    errorLoadingEntities: boolean;
    showInfinityLoader: boolean;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof ProcessTracesDisplayStyle> {
    bootEvent: SternumDeviceEventInfo;
    entityId: string;
    processName: string;
    isInfinityScrollOn?: boolean;
    entitiesFilter: SternumDeviceEventsFilter;
    searchText: string;
}

/**
 * Displays arguments when a row is in a list.
 */
class ProcessTracesDisplay extends React.Component<AppProps, AppState> {
    /**
     * Defines the column headers participating in the entities table.
     */
    private readonly columnHeaders: TableColumnHeaderInfo[] = [
        new TableColumnHeaderInfo("created", "Received", false, true, false, false),
        new TableColumnHeaderInfo("displayName", "Name", false, true, false, false),
        new TableColumnHeaderInfo("details", "Arguments", false, true, false),
    ];

    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            entities: [],
            scrollPosition: 0,
            pageNumber: 1,
            loadingEntities: false,
            totalItemCount: 0,
            errorLoadingEntities: false,
            showInfinityLoader: false,
        };
    }

    componentDidMount() {
        this.loadEntitiesForScroll(1);
    }

    /**
     * Gets the display values for row's columns (where they aren't the default).
     */
    private getRowDisplayValues(entity: SternumDeviceEventInfo) {
        const { classes } = this.props;

        let eventInterest = entity.traceInfo
            ? entity.traceInfo.traceDefinition.traceInterest
            : entity.sternumGeneratedEventInfo.sternumTrigger.eventInterest;

        const tooltipText = `${eventInterest.substr(0, 1).toUpperCase()}${eventInterest
            .toLowerCase()
            .substr(1)} Interest`;

        let displayName = entity.getDisplayName();

        return {
            type: (
                <SternumEventTypeDisplay
                    showIndicator={true}
                    label={entity.getEventType()}
                    eventInterest={eventInterest}
                    size={"medium"}
                    toolTip={tooltipText}
                />
            ),

            created: (
                <div className={classNames(classes.flexVMiddle)}>
                    <Typography variant="body2" className={classNames(classes.marginLeftXs)}>
                        {moment(entity.created).format("MM/DD HH:mm:ss")}
                    </Typography>
                </div>
            ),

            displayName: (
                <div className={classNames(classes.flexVMiddle)}>
                    <Typography variant="body2" className={classNames(classes.marginLeftXs)}>
                        {displayName}
                    </Typography>
                </div>
            ),

            details: entity.traceInfo ? (
                <ArgumentsListDisplay
                    traceInfo={entity.traceInfo}
                    exclude={["ARG_ROLE_COMMAND", "ARG_ROLE_NAME", "SYSTEM_ARG_ROLE_EXECUTING_PROCESS_NAME"]}
                />
            ) : null,
        };
    }

    /**
     * Api call for process traces.
     */
    private async fetchProcessTraces(
        entity: SternumDeviceEventInfo,
        pageNumber: number
    ): Promise<GetSternumDeviceEventsResponse> {
        return ServiceWire.getSternumService().getDeviceSternumDeviceProcessTraces(
            this.props.entityId,
            this.props.processName,
            this.props.entitiesFilter,
            entity.created,
            this.props.searchText,
            (pageNumber - 1) * SternumConfiguration.getPageSize(),
            SternumConfiguration.getPageSize()
        );
    }

    /**
     * Loads entities with concat operation to existing entities.
     */
    private loadEntitiesForScroll = async (pageNumber: number) => {
        try {
            let entities = [];
            let totalItemCount = 0;

            this.setState({
                errorLoadingEntities: false,
                showInfinityLoader: true,
            });

            let getSternumDeviceEventsResponse: GetSternumDeviceEventsResponse = await this.fetchProcessTraces(
                this.props.bootEvent,
                pageNumber
            );

            // Fetching entities.
            if (pageNumber === 1) {
                entities = getSternumDeviceEventsResponse.sternumDeviceEvents;
            } else {
                entities = this.state.entities.concat(getSternumDeviceEventsResponse.sternumDeviceEvents);
            }

            totalItemCount = getSternumDeviceEventsResponse.totalItemCount;

            this.setState({
                errorLoadingEntities: false,
                showInfinityLoader: false,
                totalItemCount,
                entities: [...entities],
                pageNumber,
            });
        } catch (error) {
            AnalyticsService.error("ProcessTracesDisplay:loadEntitiesForScroll", error.message);

            this.setState({
                showInfinityLoader: false,
                errorLoadingEntities: true,
            });
        }
    };

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        const viewedColumns = ["created", "displayName", "details"];

        return (
            <div className={classNames(classes.container)}>
                <SternumTable
                    emptyComponent={
                        <div className={classNames(classes.flexCenter, classes.padding)}>
                            <Typography variant="body2">Process traces will appear here.</Typography>
                        </div>
                    }
                    viewedColumnsSet={HashSet.fromValues(viewedColumns)}
                    pageSize={SternumConfiguration.getPageSize()}
                    amountOfLoadingPlaceholders={5}
                    totalItemCount={this.state.totalItemCount}
                    loadingItems={this.state.loadingEntities}
                    errorLoadingItems={this.state.errorLoadingEntities}
                    columnHeaders={this.columnHeaders}
                    rows={this.state.entities}
                    getRowValues={(row) => this.getRowDisplayValues(row as SternumDeviceEventInfo)}
                    onScrollChanged={this.loadEntitiesForScroll}
                    pageNumber={this.state.pageNumber}
                    isInfinityScrollOn={this.props.isInfinityScrollOn}
                    nonClickableRows
                    displayLoadingIcon
                    hideUpperBorder
                    hideToolbar
                    wrapColumns
                />
            </div>
        );
    }
}

export default withRouter(withStyles(ProcessTracesDisplayStyle)(ProcessTracesDisplay));
