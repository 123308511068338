import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

const changeLogModalStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        upperContentContainer: {
            paddingTop: theme.spacing(10),
            paddingRight: theme.spacing(5),
            paddingBottom: theme.spacing(5),
            paddingLeft: theme.spacing(5),
        },

        versionTitle: {
            fontSize: "1rem",
            fontWeight: 500,
            textDecoration: "underline",
            marginBottom: theme.spacing(2),
        },
        subsectionTitle: {
            textDecoration: "underline",
        },
    });

export default changeLogModalStyle;
