import React from "react";
import { Tooltip, TooltipProps } from "@material-ui/core";

import { useSternumTooltipStyle } from "./SternumTooltip.style";

interface SternumTooltipProps extends Omit<TooltipProps, "title"> {
    title?: React.ReactNode;
    useWrapper?: boolean;
}

export function SternumTooltip({
    className,
    title,
    useWrapper = true,
    arrow = true,
    children,
    ...props
}: SternumTooltipProps) {
    const classes = useSternumTooltipStyle();

    if (title) {
        return (
            <Tooltip
                title={title}
                classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
                arrow={arrow}
                {...props}
            >
                {/*// @ts-ignore */}
                {useWrapper ? <sternum-tooltip-container>{children}</sternum-tooltip-container> : children}
            </Tooltip>
        );
    }

    // @ts-ignore
    return useWrapper ? <sternum-tooltip-container {...props}>{children}</sternum-tooltip-container> : children;
}
