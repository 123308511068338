import React from "react";
import classNames from "classnames";

import { useMainSectionStyle } from "./MainSection.style";

export interface MainSectionRightActionsProps extends React.ComponentProps<"div"> {}

export function MainSectionRightActions({ className, children, ...props }: MainSectionRightActionsProps) {
    const classes = useMainSectionStyle();

    return (
        <div className={classNames(classes.rightActions, className)} {...props}>
            {children}
        </div>
    );
}
