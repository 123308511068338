import _ from "lodash";
import am4geodata_usaLow from "@amcharts/amcharts4-geodata/usaLow";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import React, { useEffect, useLayoutEffect, useRef } from "react";
import ServiceWire from "../../lib/services/ServiceWire";
import USStateNameToCode from "./USStateNameToCode.json";
import SternumFlag from "../SternumFlag/SternumFlag";
import { renderToString } from "react-dom/server";

export interface InteractiveMapProps {
    id: string;
    onDataLoaded: () => void;
    location: "world" | "US";
}

interface TracesDataApiResponse {
    by_location: {
        total_devices: { [country: string]: number };
    };
}

type DataScope = "world" | "US";

type TraceType = "active_devices";

am4core.useTheme(am4themes_animated);
am4core.addLicense("CH278866808");
am4core.addLicense("MP278866808");
am4core.addLicense("TL278866808");

am4core.useTheme((target) => {
    if (target instanceof am4core.InterfaceColorSet) {
        target.setFor("primaryButtonActive", am4core.color("#0d47a1"));
    }
});

function getKeyWithMaxCountries(data: { [type: string]: { [country: string]: number } }): TraceType {
    let maxRange = 0;
    let maxRangeKey = "";

    Object.keys(data).forEach((key) => {
        if (Object.keys(data[key]).length > maxRange) {
            maxRangeKey = key;
            maxRange = Object.keys(data[key]).length;
        }
    });

    return maxRangeKey as TraceType;
}

function prepareTotalPerCountryData(data: TracesDataApiResponse, location: DataScope) {
    const maxCountriesKey = getKeyWithMaxCountries(data.by_location);
    const totalData = [];

    if (!maxCountriesKey) return totalData;

    Object.keys(data.by_location[maxCountriesKey]).forEach((area) => {
        totalData.push({
            id: location === "world" ? area : `US-${USStateNameToCode[area]}`,
            active_devices: data.by_location.total_devices[area] || 0,
        });
    });

    return totalData;
}

function onReady(chart: am4core.Container) {
    let totalPerCountryData = [];

    let activeDevicesColor = am4core.color("#15AC5A");

    let countryColor = am4core.color("#fff");
    let countryStrokeColor = am4core.color("#D9D9D9");

    let maxColors = {
        active_devices: activeDevicesColor,
    };

    let minColors = {
        active_devices: am4core.color("#87BB9F"),
    };

    let currentCountry = "World";

    let currentPolygon;

    let countryDataTimeout;

    let currentType: TraceType;

    // as we will be modifying raw data, make a copy
    let mapData = [];

    let max = {
        active_devices: 0,
    };

    // main container
    // https://www.amcharts.com/docs/v4/concepts/svg-engine/containers/
    chart.width = am4core.percent(100);
    chart.height = am4core.percent(100);

    const backgroundRectangle = chart.createChild(am4core.RoundedRectangle);
    backgroundRectangle.width = am4core.percent(100);
    backgroundRectangle.height = am4core.percent(100);
    backgroundRectangle.fill = am4core.color("#E0E9F3");
    backgroundRectangle.cornerRadius(14, 14, 14, 14);

    // MAP CHART
    // https://www.amcharts.com/docs/v4/chart-types/map/
    let mapChart = chart.createChild(am4maps.MapChart);
    mapChart.height = am4core.percent(100);
    mapChart.zoomControl = new am4maps.ZoomControl();
    mapChart.zoomControl.align = "right";
    mapChart.zoomControl.marginRight = 15;
    mapChart.zoomControl.valign = "middle";
    mapChart.homeZoomLevel = 1.5;

    mapChart.zoomControl.plusButton.width = 24;
    mapChart.zoomControl.plusButton.height = 24;
    mapChart.zoomControl.plusButton.padding(2, 5, 5, 5);
    mapChart.zoomControl.plusButton.marginBottom = 10;
    mapChart.zoomControl.plusButton.label.fontSize = 18;
    // @ts-ignore
    mapChart.zoomControl.plusButton.label.fontWeight = 600;
    mapChart.zoomControl.plusButton.label.fill = am4core.color("#3E7FAD");
    mapChart.zoomControl.plusButton.background.fill = am4core.color("#fff");
    mapChart.zoomControl.plusButton.background.stroke = am4core.color("#3E7FAD");
    mapChart.zoomControl.plusButton.background.cornerRadius(100, 100, 100, 100);
    mapChart.zoomControl.plusButton.background.states.getKey("hover").properties.fill = am4core.color("#f0f0f0");
    mapChart.zoomControl.plusButton.background.states.getKey("down").properties.fill = am4core.color("#e4e4e4");

    mapChart.zoomControl.minusButton.width = 24;
    mapChart.zoomControl.minusButton.height = 24;
    mapChart.zoomControl.minusButton.padding(2, 5, 5, 5);
    mapChart.zoomControl.minusButton.label.fontSize = 18;
    // @ts-ignore
    mapChart.zoomControl.minusButton.label.fontWeight = 600;
    mapChart.zoomControl.minusButton.label.fill = am4core.color("#3E7FAD");
    mapChart.zoomControl.minusButton.background.stroke = am4core.color("#3E7FAD");
    mapChart.zoomControl.minusButton.background.fill = am4core.color("#fff");
    mapChart.zoomControl.minusButton.background.cornerRadius(100, 100, 100, 100);
    mapChart.zoomControl.minusButton.background.states.getKey("hover").properties.fill = am4core.color("#f0f0f0");
    mapChart.zoomControl.minusButton.background.states.getKey("down").properties.fill = am4core.color("#e4e4e4");

    // by default minus button zooms out by one step, but we modify the behavior so when user clicks on minus, the map would fully zoom-out and show world data
    mapChart.zoomControl.minusButton.events.on("hit", showWorld);
    // clicking on a "sea" will also result a full zoom-out
    mapChart.seriesContainer.background.events.on("hit", showWorld);
    mapChart.seriesContainer.background.events.on("over", resetHover);
    mapChart.seriesContainer.background.fillOpacity = 0;
    mapChart.zoomEasing = am4core.ease.sinOut;

    // https://www.amcharts.com/docs/v4/chart-types/map/#Map_data
    // you can use more accurate world map or map of any other country - a wide selection of maps available at: https://github.com/amcharts/amcharts4-geodata
    mapChart.geodata = am4geodata_worldLow;

    // Set projection
    // https://www.amcharts.com/docs/v4/chart-types/map/#Setting_projection
    // instead of Miller, you can use Mercator or many other projections available: https://www.amcharts.com/demos/map-using-d3-projections/
    mapChart.projection = new am4maps.projections.Miller();
    mapChart.panBehavior = "move";

    // when map is globe, beackground is made visible
    mapChart.backgroundSeries.mapPolygons.template.polygon.fillOpacity = 0.05;
    mapChart.backgroundSeries.mapPolygons.template.polygon.fill = am4core.color("#ffffff");
    mapChart.backgroundSeries.hidden = true;

    // Map polygon series (defines how country areas look and behave)
    let polygonSeries = mapChart.series.push(new am4maps.MapPolygonSeries());
    polygonSeries.dataFields.id = "id";
    polygonSeries.dataFields.value = "active_devices";
    polygonSeries.interpolationDuration = 0;

    polygonSeries.exclude = ["AQ"]; // Antarctica is excluded in non-globe projection
    polygonSeries.useGeodata = true;
    // this helps to place bubbles in the visual middle of the area
    polygonSeries.calculateVisualCenter = true;

    let polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.fill = countryColor;
    polygonTemplate.fillOpacity = 1;
    polygonTemplate.stroke = countryStrokeColor;
    polygonTemplate.strokeOpacity = 0.2;
    polygonTemplate.strokeWidth = 1;
    polygonTemplate.setStateOnChildren = true;
    polygonTemplate.tooltipPosition = "fixed";

    polygonTemplate.tooltip = new am4core.Tooltip();
    polygonTemplate.tooltip.background.filters.clear();
    polygonTemplate.tooltip.background.cornerRadius = 8;
    polygonTemplate.tooltip.getFillFromObject = false;
    polygonTemplate.tooltip.background.fill = am4core.color("#1B1B1B");
    polygonTemplate.tooltip.background.fillOpacity = 1;
    polygonTemplate.tooltip.background.stroke = null;
    polygonTemplate.applyOnClones = true;
    polygonTemplate.hoverOnFocus = true;
    polygonTemplate.nonScalingStroke = true;

    polygonTemplate.events.on("hit", handleCountryHit);
    polygonTemplate.events.on("over", handleCountryOver);
    polygonTemplate.events.on("out", handleCountryOut);

    polygonSeries.heatRules.push({
        target: polygonTemplate,
        property: "fill",
        min: countryColor,
        max: countryColor,
        dataField: "value",
    });

    const polygonHoverState = polygonTemplate.states.create("hover");
    polygonHoverState.properties.stroke = am4core.color("#000");
    polygonHoverState.properties.strokeOpacity = 1;
    polygonHoverState.properties.strokeWidth = 2;

    const polygonActiveState = polygonTemplate.states.create("active");
    polygonActiveState.properties.stroke = am4core.color("#000");
    polygonActiveState.properties.strokeOpacity = 1;
    polygonActiveState.properties.strokeWidth = 2;
    polygonActiveState.properties.zIndex = Number.MAX_VALUE;

    // this is to prevent polygon from displaying broken outline, polygon should be placed in front of all other polygons by Z axis
    polygonSeries.mapPolygons.template.events.on("over", (event) => {
        event.target.zIndex = Number.MAX_VALUE;
        event.target.toFront();
    });

    polygonTemplate.adapter.add("fill", (fill, target) => {
        // @ts-ignore
        if (target.dataItem.dataContext?.[currentType] === 0) {
            return countryColor;
        }

        return fill;
    });

    // END OF MAP

    // buttons & chart container
    let buttonsAndChartContainer = chart.createChild(am4core.Container);
    buttonsAndChartContainer.layout = "vertical";
    buttonsAndChartContainer.height = am4core.percent(10); // make this bigger if you want more space for the chart
    buttonsAndChartContainer.width = am4core.percent(100);
    buttonsAndChartContainer.valign = "bottom";

    // country name and buttons container
    let nameAndButtonsContainer = buttonsAndChartContainer.createChild(am4core.Container);
    nameAndButtonsContainer.width = am4core.percent(100);
    nameAndButtonsContainer.padding(0, 10, 10, 20);
    nameAndButtonsContainer.layout = "horizontal";

    // name of a country and date label
    let countryName = nameAndButtonsContainer.createChild(am4core.Label);
    countryName.fontSize = "16px";
    countryName.dy = 5;
    countryName.fill = am4core.color("#3E7FAD");
    countryName.valign = "middle";

    // buttons container (active/confirmed/recovered/deaths)
    let buttonsContainer = nameAndButtonsContainer.createChild(am4core.Container);
    buttonsContainer.layout = "grid";
    buttonsContainer.width = am4core.percent(100);
    buttonsContainer.x = 10;
    buttonsContainer.contentAlign = "right";

    function changeDataType(name: TraceType) {
        currentType = name;

        // tell series new field name
        polygonSeries.dataFields.value = name;

        polygonSeries.dataItems.each(function (dataItem) {
            dataItem.setValue("value", dataItem.dataContext[currentType]);
            // dataItem.mapPolygon.defaultState.properties.fill = undefined;
        });

        polygonSeries.invalidateRawData();

        // update heat rule's maxValue
        polygonSeries.heatRules.getIndex(0).maxValue = max[currentType];
        polygonSeries.heatRules.getIndex(0).max = maxColors[name];
        polygonSeries.heatRules.getIndex(0).min = minColors[name];
    }

    function selectCountry(mapPolygon) {
        resetHover();
        polygonSeries.hideTooltip();

        // if the same country is clicked show world
        if (currentPolygon == mapPolygon) {
            currentPolygon.isActive = false;
            currentPolygon = undefined;
            showWorld();
            return;
        }
        // save current polygon
        currentPolygon = mapPolygon;
        currentCountry = mapPolygon.dataItem.dataContext.name;

        // make others inactive
        polygonSeries.mapPolygons.each(function (polygon) {
            polygon.isActive = false;
        });

        // clear timeout if there is one
        if (countryDataTimeout) {
            clearTimeout(countryDataTimeout);
        }

        updateCountryName();

        mapPolygon.isActive = true;
        mapChart.zoomToMapObject(mapPolygon, getZoomLevel(mapPolygon));
    }

    function rollOverCountry(mapPolygon) {
        resetHover();
        if (mapPolygon) {
            mapPolygon.isHover = true;
        }
    }

    function rollOutCountry(mapPolygon) {
        resetHover();
    }

    // calculate zoom level (default is too close)
    function getZoomLevel(mapPolygon) {
        let w = mapPolygon.polygon.bbox.width;
        let h = mapPolygon.polygon.bbox.width;
        // change 2 to smaller walue for a more close zoom
        return Math.min(mapChart.seriesWidth / (w * 2), mapChart.seriesHeight / (h * 2));
    }

    // show world data
    function showWorld() {
        currentCountry = "World";
        currentPolygon = undefined;
        resetHover();

        if (countryDataTimeout) {
            clearTimeout(countryDataTimeout);
        }

        // make all inactive
        polygonSeries.mapPolygons.each(function (polygon) {
            polygon.isActive = false;
        });

        updateCountryName();

        mapChart.goHome();
    }

    function updateCountryName() {
        countryName.text = currentCountry;
    }

    function updateMapData(data) {
        // modifying instead of setting new data for a nice animation
        polygonSeries.dataItems.each((dataItem) => {
            // @ts-ignore
            if (!dataItem.dataContext.active_devices || data.length === 0) {
                // @ts-ignore
                dataItem.dataContext.all_traces = 0;
                // @ts-ignore
                dataItem.dataContext.active_devices = 0;
                // @ts-ignore
                dataItem.dataContext.added_devices = 0;
                // @ts-ignore
                dataItem.dataContext.attack_traces = 0;
                // @ts-ignore
                dataItem.dataContext.update_traces = 0;
                // @ts-ignore
                dataItem.dataContext.cves = 0;
            }
        });

        for (var i = 0; i < data.length; i++) {
            let di = data[i];
            polygonSeries.mapPolygons.each((polygon) => {
                // @ts-ignore
                if (polygon.dataItem.id === di.id && polygon.dataItem.dataContext.madeFromGeoData) {
                    // @ts-ignore
                    polygon.dataItem.dataContext.all_traces = di.all_traces;
                    // @ts-ignore
                    polygon.dataItem.dataContext.active_devices = di.active_devices;
                    // @ts-ignore
                    polygon.dataItem.dataContext.added_devices = di.added_devices;
                    // @ts-ignore
                    polygon.dataItem.dataContext.attack_traces = di.attack_traces;
                    // @ts-ignore
                    polygon.dataItem.dataContext.update_traces = di.update_traces;
                    // @ts-ignore
                    polygon.dataItem.dataContext.cves = di.cves;
                }
            });
        }

        polygonSeries.heatRules.getIndex(0).maxValue = max[currentType];
        polygonSeries.invalidateRawData();
    }

    function handleCountryHit(event) {
        selectCountry(event.target);
    }

    function handleCountryOver(event) {
        rollOverCountry(event.target);
    }

    function handleCountryOut(event) {
        rollOutCountry(event.target);
    }

    function resetHover() {
        polygonSeries.mapPolygons.each(function (polygon) {
            polygon.isHover = false;
        });
    }

    // set initial data and names
    updateCountryName();

    polygonTemplate.adapter.add("tooltipHTML", function (text, target) {
        const data = target.tooltipDataItem.dataContext;
        // @ts-ignore
        const value = target.tooltipDataItem.value;
        let locationId = data["id"];
        if (locationId.includes("US")) {
            locationId = "US";
        }

        const countryFlag = <SternumFlag countryCode={locationId} />;
        const toolTip = `
                        <div style="display: flex; align-items: center">
                        ${renderToString(countryFlag)}
                            ${
                                value
                                    ? '<span style=" padding-left: 5px; font-size:14px; font-weight:600; font-family: Inter;"><strong>{name}: {value.formatNumber("#,###")}</strong></span>'
                                    : '<span style=" padding-left: 5px; font-size:14px; font-weight:600; font-family: Inter;"><strong>{name}</strong><span>'
                            }
                        </div>
                        `;
        return toolTip;
    });

    function calculateMaxTraceValues(tracesData: TracesDataApiResponse["by_location"]) {
        max.active_devices = Math.max(...Object.values(tracesData.total_devices));
    }

    function updateData(newData: TracesDataApiResponse, location: DataScope) {
        totalPerCountryData = prepareTotalPerCountryData(newData, location);

        mapData = totalPerCountryData;

        calculateMaxTraceValues(newData.by_location);

        polygonSeries.data = [];
        polygonSeries.addData(_.cloneDeep(mapData));

        changeDataType(currentType || "active_devices");
        updateMapData(mapData);
        updateCountryName();
    }

    function changeMapGeoData(location: "world" | "US") {
        if (location === "world") {
            currentCountry = "World";
            updateCountryName();

            mapChart.geodata = am4geodata_worldLow;
            // @ts-ignore
            mapChart.updateScaleRatio();
            mapChart.homeZoomLevel = 1.5;
            mapChart.goHome();
        } else if (location === "US") {
            currentCountry = "United States";
            updateCountryName();

            mapChart.geodata = am4geodata_usaLow;
            // @ts-ignore
            mapChart.updateScaleRatio();
            setTimeout(() => {
                mapChart.zoomToMapObject(polygonSeries.getPolygonById("US-KS"), 3, true);
            }, 500);
        }
    }

    return { updateData, changeMapGeoData };
}

export default function InteractiveMap(props: InteractiveMapProps) {
    const chartId = `interactive_map_${props.id}`;
    const chart = useRef<am4core.Container>(null);
    const chartController = useRef(null);

    useLayoutEffect(() => {
        let newChart = am4core.create(chartId, am4core.Container);
        chart.current = newChart;

        chartController.current = onReady(newChart);

        return () => {
            newChart.dispose();
        };
    }, []);

    useEffect(() => {
        let isNeeded = true;
        ServiceWire.getSternumService()
            .getTraceActivityGraphData(ServiceWire.getClientsService().getSelectedClientId(), props.location)
            .then((data) => {
                if (isNeeded) {
                    chartController.current?.changeMapGeoData(props.location);
                    chartController.current?.updateData(data, props.location);
                    props.onDataLoaded();
                }
            });

        return () => {
            isNeeded = false;
        };
    }, [props.location]);

    return <div id={chartId} style={{ width: "100%", height: "75vh" }} />;
}
