import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

const modalsStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        deviceViewModalContent: {
            width: theme.spacing(204),
            height: theme.spacing(100),
            padding: 0,
        },
        changePasswordModalContent: {
            width: theme.spacing(76),
            height: theme.spacing(54),
            padding: 0,
        },
        visualizationModal: {
            width: "95%",
            height: "95%",
            marginTop: theme.spacing(4),
            padding: `14px 0 0 0 `,
        },
        traceViewModalContent: {
            width: "95%",
            height: "95%",
            marginTop: theme.spacing(4),
            padding: 0,
            overflow: "hidden",
        },
        deviceInfoModalContent: {
            width: theme.spacing(210),
            height: theme.spacing(100),
            padding: 0,
        },
        sternumGeneratedEventViewModalContent: {
            width: "95%",
            height: "95%",
            marginTop: theme.spacing(4),
            padding: 0,
        },
        libraryViewModalContent: {
            width: theme.spacing(204),
            height: theme.spacing(100),
            padding: 0,
        },
        usedLibraryViewModalContent: {
            width: theme.spacing(210),
            height: theme.spacing(140),
            padding: 0,
        },
        changeLogModalContent: {
            width: theme.spacing(204),
            height: theme.spacing(100),
            padding: 0,
        },
        createDashboardModal: {
            width: theme.spacing(150),
            height: "450px",
            padding: 0,
        },
        cveViewModalContent: {
            width: theme.spacing(204),
            height: theme.spacing(150),
            padding: 0,
        },
        libraryHistoriesModalContent: {
            width: theme.spacing(210),
            maxWidth: "90%",
            height: theme.spacing(140),
            padding: 0,
        },
        outgoingWebhookConfigurationModalContent: {
            width: theme.spacing(92),
            height: theme.spacing(80),
        },
        newAPIKeyModalContent: {
            width: theme.spacing(92),
            height: theme.spacing(55),
        },
        newDeviceDefinitionContent: {
            width: theme.spacing(150),
            height: theme.spacing(110),
            maxHeight: `${theme.spacing(16)}vh`,
        },
        EditDeviceDefinitionContent: {
            width: theme.spacing(150),
            height: theme.spacing(110),
            maxHeight: `${theme.spacing(16)}vh`,
        },
        alertDisplayContent: {
            width: theme.spacing(250),
            maxWidth: "90%",
            height: theme.spacing(110),
            padding: 0,
        },
        mapContent: {
            width: "95%",
            height: "95%",
            marginTop: theme.spacing(4),
        },
        geoLocationHistoryContent: {
            height: theme.spacing(200),
            width: theme.spacing(300),
            maxWidth: "90%",
            maxHeight: `${theme.spacing(16)}vh`,
        },
    });

export default modalsStyle;
