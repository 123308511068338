import { Action } from "redux";
import ActionType from "../ActionType";
import TraceInfo from "../../state/TraceInfo";

/**
 * Occurs once a trace has been successfully fetched from API.
 */
export interface FetchedTraceSuccessfullyAction extends Action<ActionType> {
    type: ActionType.FetchedTraceSuccessfully;
    trace: TraceInfo;
}

/**
 * Occurs once a trace has been successfully fetched from API.
 */
export function fetchedTraceSuccessfullyAction(trace: TraceInfo): FetchedTraceSuccessfullyAction {
    return {
        type: ActionType.FetchedTraceSuccessfully,
        trace: trace,
    };
}
