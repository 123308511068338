import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const useSliderStyle = makeStyles((theme: Theme) => ({
    slider: {
        display: "grid",
        gridTemplateRows: "auto 1fr",
        gridTemplateColumns: "50px auto 50px",
        gridTemplateAreas: `"leftArrow slider rightArrow" "pagination pagination pagination"`,
    },

    variantDefault: {
        "& $slidersContainer": {
            gridColumn: "2 / 3",
        },
    },

    variantMaxContent: {
        "$ $slidersContainer": {
            gridColumn: "1 / 4",
        },
    },

    leftArrowContainer: {
        gridArea: "leftArrow",
        display: "flex",
        zIndex: 10,
        pointerEvents: "none",

        "& > *": {
            pointerEvents: "initial",
        },
    },

    rightArrowContainer: {
        gridArea: "rightArrow",
        display: "flex",
        zIndex: 10,
        pointerEvents: "none",

        "& > *": {
            pointerEvents: "initial",
        },
    },

    slidersContainer: {
        position: "relative",
        gridColumn: "1 / 4",
        gridRow: "1",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
    },

    paginationContainer: {
        gridArea: "pagination",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    sliderHandler: {
        display: "block",
        width: "100%",
        height: "100%",
        whiteSpace: "nowrap",
        transition: "transform 0.5s ease-out",
    },

    arrow: {
        color: "#222831",
        margin: "auto",
        cursor: "pointer",
    },

    arrowDisabled: {
        opacity: "0.4",
        cursor: "inherit",
    },
}));
