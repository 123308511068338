import { makeStyles, Theme } from "@material-ui/core";

export const useAlertsFilterStyle = makeStyles((theme: Theme) => ({
    root: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "15px",
    },

    left: {
        display: "flex",
        alignItems: "center",
        columnGap: "6px",
        flex: 1,
    },

    right: {
        display: "flex",
        alignItems: "center",
    },

    rightBlock: {
        display: "flex",
        alignItems: "center",
        columnGap: "6px",
        padding: "0 10px",
        borderLeft: "1px solid #E6E6E6",
    },

    parameterValue: {
        fontWeight: "bold",
        whiteSpace: "nowrap",
    },

    infoTooltip: {
        background: "#1B1B1B",
        fontSize: "12px",
        marginTop: "5px",
    },

    extraCategoriesCount: {
        display: "inline",
        marginLeft: "6px",
        padding: "2px 4px",
        background: "#E6EAED",
        borderRadius: "4px",
        fontSize: "10px",
        color: "#2B2E30",
    },

    resetFilterButton: {
        height: "fit-content",
        padding: "4px 0 4px 8px",
    },
}));
