import {
    Badge,
    CircularProgress,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
    WithStyles,
    withStyles,
} from "@material-ui/core";
import AssessmentIcon from "@material-ui/icons/Assessment";
import RestorePageOutlinedIcon from "@material-ui/icons/RestorePageOutlined";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DeployToProductionDialog from "../../components/DeviceDefinitionComponents/DeployToProductionDialog/DeployToProductionDialog";
import StatusDisplay from "../../components/StatusDisplay/StatusDisplay";
import {
    ArrowRightIcon,
    ChartOneSeriesIcon,
    CloundDownloadIcon,
    CopyFilesIcon,
    DeleteIcon,
    ExternalLinkIcon,
    RocketIcon,
    SettingsIcon,
    ThreeDotsVerticalIcon,
} from "../../components/SUI/SternumIcon";
import Utils from "../../lib/infra/Utils";
import WebUtils from "../../lib/infra/WebUtils";
import { openDeviceViewModalAction } from "../../lib/redux/modals/OpenModalAction";
import { showNotificationAction } from "../../lib/redux/notifications/ShowNotificationAction";
import ServiceWire from "../../lib/services/ServiceWire";
import DeviceDefinitionVersionInfo from "../../lib/state/DeviceDefinitionVersionInfo";
import { GlobalState } from "../../lib/state/GlobalState";
import { NotificationMessage } from "../../lib/state/NotificationsState";
import ServerEntityType from "../../lib/state/ServerEntityType";
import UserInfo from "../../lib/state/UserInfo";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import deviceDefinitionCardStyle from "./DeviceDefinitionCardStyle";
import moment from "moment";

interface AppState {
    anchorEl: any;
    nestedAnchorEl: any;
    openNestedMenu: boolean;
    openMenu: boolean;
    openProductionConfirmationDialog: boolean;
    openClearHistoryConfirmationDialog: boolean;
    openDeleteDeviceDefinitionDialog: boolean;
    openClearDataDeviceDefinitionDialog: boolean;
    isDisableMode: boolean;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof deviceDefinitionCardStyle> {
    deviceDefinitionVersion: DeviceDefinitionVersionInfo;
    header: string;
    content: any;
    uniqueIdentifier: string;
    userData: UserInfo;
    cardThemeClass?: any;
    actionThemeClass?: any;
    handleEdit: () => void;
    handleDelete: () => void;
    handleDownloadC: () => void;
    handleDownloadJson: () => void;
    downloadJavaFiles: () => void;
    handleReleaseToProduction: (deleteExistingData: boolean) => void;
    handleCreateNewVersion: () => void;
    handleClearHistory: () => void;
    deletingHistoryState?: boolean;
    history;
    canCreateNewVersion: boolean;

    openDeviceViewModal?: (
        key: string,
        deviceDefinitionId: string,
        displayXButton: boolean,
        displayBackButton: boolean
    ) => void;
    showNotification: (message: NotificationMessage) => void;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {
        openDeviceViewModal: (
            key: string,
            deviceDefinitionId: string,
            displayXButton: boolean,
            displayBackButton: boolean
        ) =>
            dispatch(openDeviceViewModalAction(key, true, null, deviceDefinitionId, displayXButton, displayBackButton)),
        showNotification: (message: NotificationMessage) => dispatch(showNotificationAction(message)),
    };
};

class DeviceDefinitionCard extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */

    constructor(props: AppProps) {
        super(props);
        // Initializing the state to default.
        this.state = {
            anchorEl: null,
            openMenu: false,
            nestedAnchorEl: null,
            openNestedMenu: false,
            openProductionConfirmationDialog: false,
            openClearHistoryConfirmationDialog: false,
            openDeleteDeviceDefinitionDialog: false,
            openClearDataDeviceDefinitionDialog: false,
            isDisableMode: !ServiceWire.getAuthorizationService().canEdit(ServerEntityType.DEVICE_DEFINITION),
        };
    }

    /**
     * Copy data to clipboard.
     */
    private copyToClip = (content) => {
        WebUtils.copyContentToClipBoard(content);
    };

    /**
     *  Open or close more menu
     */
    private handleMenuClick = (event) => {
        this.changeMenuState();
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    /**
     * Trigger edit operation and close menu
     */
    private handleEditMenuClick = (event) => {
        this.changeMenuState();
        this.props.handleEdit();
    };

    /**
     * Trigger release to production operation and close dialog
     */
    private handleConfirmationProductionDialog = (confirmationResult: boolean, deleteExistingData: boolean) => {
        this.setState({ openProductionConfirmationDialog: false });
        if (confirmationResult) {
            this.props.handleReleaseToProduction(deleteExistingData);
        }
    };

    /**
     * Open production confirmation dialog
     */
    private openProductionConfirmationDialog = (event) => {
        this.changeMenuState();
        this.setState({ openProductionConfirmationDialog: true });
    };

    /**
     * Open clear history confirmation dialog
     */
    private openClearHistoryConfirmationDialog = (event) => {
        this.changeMenuState();
        this.setState({ openClearHistoryConfirmationDialog: true });
    };

    /**
     * Trigger copy operation and close menu
     */
    private handleCopyClick = (event) => {
        this.copyToClip(this.props.uniqueIdentifier);
        this.props.showNotification("Device Definition ID copied!");
        this.changeMenuState();
    };

    /**
     * Trigger delete operation and close menu
     */
    private handleDeleteClick = (event) => {
        this.changeMenuState();
        this.setState({ openDeleteDeviceDefinitionDialog: true });
    };

    private handleDeleteConfirmation = () => {
        this.setState({ openDeleteDeviceDefinitionDialog: false });
        this.props.handleDelete();
    };

    private handleClearHistoryConfirmation = () => {
        this.setState({ openClearHistoryConfirmationDialog: false });
        this.props.handleClearHistory();
    };

    /**
     * Trigger create new version from
     */
    private handleCreateNewVersion = (event) => {
        this.changeMenuState();
        this.props.handleCreateNewVersion();
    };

    /**
     * Trigger download operation and close menu
     */
    private openDownloadOptionsMenu = (event) => {
        this.setState({
            nestedAnchorEl: event.currentTarget,
            openNestedMenu: true,
        });
    };

    /**
     * Trigger download operation and close menu
     */
    private OnDownloadCFile = () => {
        this.changeMenuState();
        this.props.handleDownloadC();
    };

    /**
     * Trigger download operation and close menu
     */
    private OnDownloadJsonFile = () => {
        this.changeMenuState();
        this.props.handleDownloadJson();
    };

    /**
     * Trigger download operation and close menu
     */
    private OnDownloadJavaFiles = () => {
        this.changeMenuState();
        this.props.downloadJavaFiles();
    };

    /**
     * Trigger change menu state
     */
    private changeMenuState = () => {
        this.setState({
            openMenu: !this.state.openMenu,
            nestedAnchorEl: null,
            openNestedMenu: false,
        });
    };

    /**
     * Close delete device definition confirmation dialog
     */
    private closeDeviceDefinitionConfirmation = () => {
        this.setState({
            openDeleteDeviceDefinitionDialog: false,
        });
    };

    /**
     * Close clear history device definition confirmation dialog
     */
    private closeClearHistoryConfirmation = () => {
        this.setState({
            openClearHistoryConfirmationDialog: false,
        });
    };

    private handleViewMouseDownClicked = (event) => {
        if (event.button === 1) {
            window.open(`device-profile/${this.props.deviceDefinitionVersion.entityId}`, "_blank");
        } else if (event.button === 0) {
            this.props.history.push({
                pathname: `device-profile/${this.props.deviceDefinitionVersion.entityId}`,
            });
        }
    };

    render() {
        const { classes } = this.props;

        const showTracesDownload = this.props.deviceDefinitionVersion.isNewTraceDefinitionsExists() ? true : false;
        return (
            <div
                role="listitem"
                aria-label="device profile item"
                className={classNames(classes.cardHolder, classes.flexColumn, classes.flexSpaceBetween)}
            >
                <div key="Action area" onClick={this.props.handleEdit} className={classNames(classes.cursorPointer)}>
                    <div
                        key="title"
                        className={classNames(
                            classes.marginBottomLarge,
                            classes.flexRow,
                            classes.flexSpaceBetween,
                            classes.flexVMiddle,
                            classes.paddingRightXs
                        )}
                    >
                        <Typography
                            variant="h6"
                            noWrap
                            className={classNames(classes.paddingRightXs, classes.deviceProfileTitle)}
                        >
                            {this.props.header}
                        </Typography>
                        {this.props.deletingHistoryState ? (
                            <div className={classNames(classes.flexVMiddle, classes.flexCenter)}>
                                <CircularProgress />
                            </div>
                        ) : (
                            <StatusDisplay
                                label={Utils.capitalize(this.props.deviceDefinitionVersion.versionStatus.toLowerCase())}
                                centerText={false}
                                padding={true}
                                production={this.props.deviceDefinitionVersion.isProduction()}
                                staging={!this.props.deviceDefinitionVersion.isProduction()}
                                displayColoredBackground={true}
                            />
                        )}
                    </div>

                    <div key="content">
                        {/** Firmware version */}
                        <Typography
                            key={"Firmware version"}
                            variant="subtitle2"
                            className={classes.deviceProfileCharacteristic}
                        >
                            {`Firmware Version: ${this.props.deviceDefinitionVersion.getFirmwareVersion()}`}
                        </Typography>
                        {/** Operation System */}
                        <Typography
                            key={"Operation System"}
                            variant="subtitle2"
                            className={classes.deviceProfileCharacteristic}
                        >
                            {`Operating System: ${this.props.deviceDefinitionVersion.deviceDefinition.deviceOSFamily}`}
                        </Typography>
                        {/** Parent id */}
                        {!this.props.deviceDefinitionVersion.isRootVersion() && (
                            <Typography
                                key={"Parent id"}
                                variant="subtitle2"
                                color="textSecondary"
                                className={classes.deviceProfileCharacteristic}
                            >
                                {`Parent Definition ID: ${this.props.deviceDefinitionVersion.parentRawId}`}
                            </Typography>
                        )}

                        {/** Device definition version id */}
                        <Typography
                            key={"device definition version id"}
                            variant="subtitle2"
                            className={classes.deviceProfileCharacteristic}
                        >
                            {`Device Definition ID: ${this.props.deviceDefinitionVersion.deviceDefinitionVersionRawId}`}
                        </Typography>
                        {/** Device Count*/}
                        <Typography
                            key={"device count"}
                            variant="subtitle2"
                            className={classes.deviceProfileCharacteristic}
                        >
                            {this.props.content}
                        </Typography>
                    </div>
                </div>
                <div
                    key="footer"
                    className={classNames(
                        classes.flexRow,
                        classes.flexSpaceBetween,
                        classes.flexVMiddle,
                        classes.paddingTopXs,
                        classes.paddingRightXs
                    )}
                >
                    <div className={classNames(classes.flexColumn, classes.paddingRightXs, classes.noWarp)}>
                        <Typography variant={"caption"} noWrap className={classes.deviceProfileCreator}>
                            Created by {Utils.capitalize(this.props.deviceDefinitionVersion.getCreatorFullName())} at{" "}
                            {moment(this.props.deviceDefinitionVersion.created).format("MM/DD/YYYY")}
                        </Typography>
                    </div>

                    <div role="presentation" aria-label="device definition card action buttons">
                        {/* View */}
                        <Tooltip title="Explore Profile" classes={{ tooltip: classes.toolTip }}>
                            <IconButton
                                aria-label="explore profile"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={this.handleViewMouseDownClicked}
                                className={classes.moreInfo}
                            >
                                <ChartOneSeriesIcon color="#1D489B" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title={
                                showTracesDownload
                                    ? "Changes were made to the Traces definition. Please download the latest Traces definition."
                                    : ""
                            }
                            classes={{ tooltip: classes.toolTip }}
                        >
                            <Badge
                                badgeContent={showTracesDownload ? 1 : 0}
                                color="primary"
                                classes={{ badge: classes.badge }}
                            >
                                <IconButton
                                    aria-label="options"
                                    aria-controls="long-menu"
                                    aria-haspopup="true"
                                    onClick={this.handleMenuClick}
                                    className={classes.moreInfo}
                                >
                                    <ThreeDotsVerticalIcon color="#1D489B" />
                                </IconButton>
                            </Badge>
                        </Tooltip>
                        <Menu
                            id="device-definition-menu"
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            open={this.state.openMenu}
                            onClose={this.handleMenuClick}
                            PaperProps={{
                                className: classes.deviceProfileOptionsMenu,
                            }}
                        >
                            {/* Empty menu item to fix autoSelect first element */}
                            <MenuItem style={{ display: "none" }} />

                            {/* Open device definition version view */}
                            <MenuItem
                                aria-label="device definition menu item"
                                onClick={this.handleViewMouseDownClicked}
                            >
                                <ListItemIcon>
                                    <AssessmentIcon fontSize="small" color="primary" />
                                </ListItemIcon>
                                <Typography variant="inherit">Explore Profile</Typography>
                            </MenuItem>

                            {/* Open edit dialog */}
                            <MenuItem aria-label="device definition menu item" onClick={this.handleEditMenuClick}>
                                <ListItemIcon>
                                    <SettingsIcon width="20" height="20" color="#000" />
                                </ListItemIcon>
                                <Typography variant="inherit">Settings</Typography>
                            </MenuItem>

                            {/* Set Production ready */}
                            {!this.props.deviceDefinitionVersion.isProduction() && !this.state.isDisableMode && (
                                <MenuItem
                                    aria-label="device definition menu item"
                                    onClick={this.openProductionConfirmationDialog}
                                >
                                    <ListItemIcon>
                                        <RocketIcon color="#000" />
                                    </ListItemIcon>
                                    <Typography variant="inherit">Deploy</Typography>
                                </MenuItem>
                            )}

                            {/* Set Production ready confirmation dialog*/}
                            <DeployToProductionDialog
                                open={this.state.openProductionConfirmationDialog}
                                title={`${this.props.deviceDefinitionVersion.getVersionName()}: ${
                                    this.props.deviceDefinitionVersion.versionFirmware
                                }`}
                                body={
                                    "You are about to deploy the device profile into production state." +
                                    " In production state no changes to the device profile are allowed but creating/editing Sternum Alerts." +
                                    " This action is irreversible. Please perform it only when the firmware is production ready."
                                }
                                handleConfirmationDialog={this.handleConfirmationProductionDialog}
                            />

                            {/* Clear history */}
                            {!this.props.deviceDefinitionVersion.isProduction() && !this.state.isDisableMode && (
                                <MenuItem
                                    aria-label="device definition menu item"
                                    onClick={this.openClearHistoryConfirmationDialog}
                                >
                                    <ListItemIcon>
                                        <RestorePageOutlinedIcon fontSize="small" style={{ color: "black" }} />
                                    </ListItemIcon>
                                    <Typography variant="inherit">Clear Data</Typography>
                                </MenuItem>
                            )}

                            {/* Clear history confirmation dialog*/}
                            <ConfirmationDialog
                                title={`Clear ${this.props.deviceDefinitionVersion.getVersionName()} historical data?`}
                                body={"All data associated to this device profile will be deleted."}
                                open={this.state.openClearHistoryConfirmationDialog}
                                handleApprove={this.handleClearHistoryConfirmation}
                                handleCancel={this.closeClearHistoryConfirmation}
                                overrideActionName={"Clear"}
                            />

                            {/* Download definitions */}
                            <MenuItem
                                aria-label="device definition menu item"
                                onClick={this.openDownloadOptionsMenu}
                                className={showTracesDownload ? classes.bold : "none"}
                            >
                                <ListItemIcon>
                                    <CloundDownloadIcon color="#000" />
                                </ListItemIcon>
                                <Typography variant="inherit">Download Traces Definition</Typography>
                                <ArrowRightIcon color="#000" />
                            </MenuItem>

                            {/* Copy id click */}
                            <MenuItem aria-label="device definition menu item" onClick={this.handleCopyClick}>
                                <ListItemIcon>
                                    <CopyFilesIcon color="#000" />
                                </ListItemIcon>
                                <Typography variant="inherit">Copy Device Definition ID</Typography>
                            </MenuItem>

                            {/* Create new version */}
                            {!this.state.isDisableMode && (
                                <MenuItem
                                    aria-label="device definition menu item"
                                    onClick={this.handleCreateNewVersion}
                                    disabled={!this.props.canCreateNewVersion}
                                >
                                    <ListItemIcon>
                                        <ExternalLinkIcon color="#000" />
                                    </ListItemIcon>
                                    <Typography variant="inherit">New Version</Typography>
                                </MenuItem>
                            )}

                            {/* Delete click */}
                            {!this.props.deviceDefinitionVersion.isProduction() && !this.state.isDisableMode && (
                                <MenuItem aria-label="device definition menu item" onClick={this.handleDeleteClick}>
                                    <ListItemIcon>
                                        <DeleteIcon color="#000" />
                                    </ListItemIcon>
                                    <Typography variant="inherit">Delete</Typography>
                                </MenuItem>
                            )}

                            {/** Delete device definition confirmation dialog */}
                            <ConfirmationDialog
                                title={`Delete ${this.props.deviceDefinitionVersion.getVersionName()} ?`}
                                body={"All data associated to this device profile will be deleted."}
                                open={this.state.openDeleteDeviceDefinitionDialog}
                                handleApprove={this.handleDeleteConfirmation}
                                handleCancel={this.closeDeviceDefinitionConfirmation}
                                overrideActionName={"Delete"}
                            />
                        </Menu>

                        {/* Nested download header menu */}
                        <Menu
                            PaperProps={{
                                className: classes.deviceProfileOptionsMenu,
                            }}
                            id={"user-popup-menu"}
                            anchorEl={this.state.nestedAnchorEl}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: 0,
                                horizontal: "right",
                            }}
                            open={Boolean(this.state.nestedAnchorEl)}
                            onClose={() => {
                                this.setState({ nestedAnchorEl: null, openNestedMenu: false });
                            }}
                        >
                            <MenuItem aria-label="device definition menu item" onClick={this.OnDownloadCFile}>
                                <ListItemIcon>
                                    <CloundDownloadIcon color="#000" />
                                </ListItemIcon>
                                <Typography variant="subtitle2">Header File</Typography>
                            </MenuItem>

                            <MenuItem aria-label="device definition menu item" onClick={this.OnDownloadJsonFile}>
                                <ListItemIcon>
                                    <CloundDownloadIcon color="#000" />
                                </ListItemIcon>
                                <Typography variant="subtitle2">JSON File</Typography>
                            </MenuItem>

                            <MenuItem aria-label="device definition menu item" onClick={this.OnDownloadJavaFiles}>
                                <ListItemIcon>
                                    <CloundDownloadIcon color="#000" />
                                </ListItemIcon>
                                <Typography variant="subtitle2">Java Files</Typography>
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(deviceDefinitionCardStyle)(DeviceDefinitionCard)));
