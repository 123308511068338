import { IconButton, Tooltip, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import classNames from "classnames";
import React from "react";
import WebUtils from "../../lib/infra/WebUtils";
import { showNotificationAction } from "../../lib/redux/notifications/ShowNotificationAction";
import { GlobalState } from "../../lib/state/GlobalState";
import { NotificationMessage } from "../../lib/state/NotificationsState";
import { useCommonStyle } from "../CommonStyle";
import { CopyFilesIcon } from "../SUI/SternumIcon";
import { useStyles } from "./CopyTextComponentStyle";

export interface CopyTextComponentProps {
    text: string;
    notificationText?: string;
    variant?: "filled" | "outlined";
}

const mapStateToProps = (state: GlobalState, ownProps: any) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        showNotification: (message: NotificationMessage) => dispatch(showNotificationAction(message)),
    };
};

type CopyTextComponentPropsWithHOC = CopyTextComponentProps & ReturnType<typeof mapDispatchToProps>;

function CopyText({
    text,
    notificationText = "Copied to the clipboard",
    variant = "outlined",
    showNotification,
}: CopyTextComponentPropsWithHOC) {
    const classes = useStyles();
    const classesCommon = useCommonStyle();

    const handleCopyClick = () => {
        WebUtils.copyContentToClipBoard(text);
        showNotification(notificationText);
    };

    return (
        <div
            className={classNames(
                classes.commandContainer,
                variant,
                classesCommon.flexSpaceBetween,
                classesCommon.flexVMiddle
            )}
        >
            <Tooltip title={<Typography variant="caption">{text}</Typography>}>
                <Typography variant="body2" className={classNames(classes.command, classesCommon.marginRight)}>
                    {text}
                </Typography>
            </Tooltip>

            <div className={classNames(classesCommon.flexVMiddle)}>
                <Tooltip title="Copy">
                    <IconButton className={classes.noPadding} onClick={handleCopyClick}>
                        <CopyFilesIcon color="#8B949E" />
                    </IconButton>
                </Tooltip>
            </div>
        </div>
    );
}

export const CopyTextComponent: React.FC<CopyTextComponentProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(CopyText);
