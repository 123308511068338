import React from "react";
import classNames from "classnames";

import { useButtonStyle } from "./Button.style";

export interface ButtonProps extends React.ComponentProps<"button"> {}

export function Button({ className, children, ...props }: ButtonProps) {
    const classes = useButtonStyle();

    return (
        <button className={classNames(classes.button, className)} {...props}>
            {children}
        </button>
    );
}
