import ActionType from "../ActionType";
import { DevicesReducerActions } from "./DevicesReducerActions";
import { EntitiesState, getDefaultEntitiesState } from "../../state/EntitiesState";
import DeviceInfo from "../../state/DeviceInfo";
import HashSet from "../../infra/HashSet";
import HashMap from "../../infra/HashMap";

/**
 * Reducer for all device-related actions.
 */
const devicesReducer = (
    state: EntitiesState<DeviceInfo> = getDefaultEntitiesState<DeviceInfo>(),
    action: DevicesReducerActions
): EntitiesState<DeviceInfo> => {
    switch (action.type) {
        case ActionType.FetchDevice: {
            return {
                ...state,
                loadingIds: HashSet.copyAndAdd(state.loadingIds, action.deviceId),
            };
        }

        case ActionType.FetchedDeviceSuccessfully: {
            return {
                ...state,
                idToEntityMap: HashMap.copyAndPut(state.idToEntityMap, action.device.deviceId, action.device),
                loadingIds: state.loadingIds.exists(action.device.deviceId)
                    ? HashSet.copyAndRemove(state.loadingIds, action.device.deviceId)
                    : state.loadingIds,
                errorIds: state.errorIds.containsKey(action.device.deviceId)
                    ? HashMap.copyAndRemove(state.errorIds, action.device.deviceId)
                    : state.errorIds,
            };
        }

        case ActionType.ErrorFetchingDevice: {
            return {
                ...state,
                loadingIds: HashSet.copyAndRemove(state.loadingIds, action.deviceId),
                errorIds: HashMap.copyAndPut(state.errorIds, action.deviceId, action.error),
            };
        }

        case ActionType.SetDetectedDeviceCount: {
            return {
                ...state,
                detectedDeviceCount: action.count,
            };
        }

        default:
            return state;
    }
};

export default devicesReducer;
