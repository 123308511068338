import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

import { commonSignupManager } from "../SignupManager.style";

export const useSignupStyle = makeStyles((theme: Theme) => ({
    content: {
        display: "grid",
        gridTemplateAreas: `"descriptionContent signupFormContent" "descriptionContent signupFormContent"`,
        gridTemplateRows: "auto auto",
        gridTemplateColumns: "1fr 1fr",
        columnGap: 40,
        rowGap: 70,
        justifyItems: "center",
        alignItems: "start",
        height: "100%",
        margin: "15px auto 0 auto",

        [`@media (max-width: ${commonSignupManager.mediaQueryMaxWidth})`]: {
            gridTemplateAreas: `"signupFormContent signupFormContent" "descriptionContent descriptionContent"`,
            margin: "50px 16px 60px 16px",
        },
    },

    mainTitle: {
        fontWeight: 700,
        fontSize: "34px",
        lineHeight: "51px",
        margin: "32px 0",

        [`@media (max-width: ${commonSignupManager.mediaQueryMaxWidth})`]: {
            margin: "0 0 14px 0",
            fontSize: "22px",
            lineHeight: "33px",
            textAlign: "center",
        },
    },

    signUpTitle: {
        fontWeight: 600,
        fontSize: "24px",
        textAlign: "center",
        margin: "0 0 16px 0",
    },

    descriptionContent: {
        gridArea: "descriptionContent",
        maxWidth: 540,

        [`@media (max-width: ${commonSignupManager.mediaQueryMaxWidth})`]: {
            width: "100%",
            maxWidth: 480,
        },
    },

    backButton: {
        display: "flex",
        alignItems: "center",
        gap: 4,
        color: "#222831",
        fontWeight: 600,
        fontSize: "0.875rem",
        lineHeight: "1.5",
        textDecoration: "none",

        "&:hover": {
            color: theme.palette.secondary.dark,
            textDecoration: "underline",
        },

        [`@media (max-width: ${commonSignupManager.mediaQueryMaxWidth})`]: {
            display: "none",
        },
    },

    backButtonIcon: {
        color: theme.palette.secondary.dark,
    },

    signupFormContent: {
        gridArea: "signupFormContent",
        width: 480,
        padding: "40px",
        background: "#fff",
        boxShadow: "-12px 14px 34px rgba(83, 93, 120, 0.15)",
        borderRadius: "12px",
        boxSizing: "border-box",

        [`@media (max-width: ${commonSignupManager.mediaQueryMaxWidth})`]: {
            width: "100%",
            maxWidth: 480,
        },

        "@media (max-width: 450px)": {
            padding: "40px 20px",
        },
    },

    trialDescription: {
        fontSize: "14px",
        color: "rgba(35, 20, 52, .7)",
        margin: "0 0 32px 0",
        lineHeight: "22px",
        maxWidth: 500,

        [`@media (max-width: ${commonSignupManager.mediaQueryMaxWidth})`]: {
            maxWidth: "unset",
            textAlign: "center",
        },
    },

    trialDescriptionItem: {
        "&:not(:first-child)": {
            marginTop: 8,
        },
    },

    featuresContainer: {
        display: "flex",
        flexDirection: "column",
        rowGap: "16px",
    },

    featureItem: {
        display: "flex",
        alignItems: "center",
    },

    featureItemIcon: {
        [`@media (max-width: ${commonSignupManager.mediaQueryMaxWidth})`]: {
            width: 16,
            height: 16,
        },
    },

    featureText: {
        flex: 1,
        marginLeft: "16px",
        fontSize: "14px",
        color: "rgba(35, 20, 52, .7)",

        [`@media (max-width: ${commonSignupManager.mediaQueryMaxWidth})`]: {
            marginLeft: "10px",
        },
    },

    termsCheckbox: {
        margin: "5px 0 15px 0",
    },

    modalContent: {
        width: "95%",
        height: "95%",
        marginTop: theme.spacing(4),
        boxSizing: "border-box",
    },

    researchNumber: {
        marginTop: 8,
    },

    companyLogosGroup: {
        display: "flex",
        flexWrap: "wrap",
        gap: 20,
        justifyContent: "space-between",
        marginTop: 40,
        marginBottom: 40,
    },

    companyLogosItem: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
    },

    companyLogosTitle: {
        color: "#43484C",
        fontSize: 12,
        marginBottom: "10px",
    },

    companyLogos: {
        display: "flex",
        gap: 12,
        transformOrigin: "left",
    },

    companyLogo: {},

    companyLogoImage: {
        height: 40,
    },

    backendInvestorImage: {
        height: 40,
    },

    linkButton: {
        color: "#1B6FDE",
        fontSize: 14,
        fontWeight: 500,
    },

    separator: {
        width: 1,
        height: 20,
        background: "#E6EAED",
        margin: "0 16px",
    },
}));
