import AggregatedEventInfo from "./AggregatedEventInfo";
import EntityManager from "../infra/EntityManager";
import SternumTriggerInfo from "./SternumTriggerInfo";

class EventCountsOverTimeAggregationInfo {
    /**
     * Constructor.
     */
    constructor(public dateToEventsMap: Record<string, AggregatedEventInfo[]>) {}

    /**
     * Converts API response to the device aggregated events information.
     */
    public static fromApiResponse(apiResponseJson: Object) {
        // Constructing a map between a bucket identifier (usually a time) to its aggregated events information.
        let dateToAggregatedEventInfoMap: Record<string, AggregatedEventInfo[]> = {};

        // For each aggregated event.
        for (let aggregatedDate in apiResponseJson["aggregated_events"]) {
            if (apiResponseJson["aggregated_events"].hasOwnProperty(aggregatedDate)) {
                // Initializing the converted map.
                dateToAggregatedEventInfoMap[aggregatedDate] = [];

                let apiAggregatedEventsMap = apiResponseJson["aggregated_events"][aggregatedDate];

                for (let aggregatedEvent in apiAggregatedEventsMap) {
                    if (apiAggregatedEventsMap.hasOwnProperty(aggregatedEvent)) {
                        // Constructing the aggregated event info with the event and the count.
                        const aggregatedEventInfoJson = apiAggregatedEventsMap[aggregatedEvent];

                        dateToAggregatedEventInfoMap[aggregatedDate].push(
                            new AggregatedEventInfo(
                                aggregatedEventInfoJson["count"],
                                aggregatedEventInfoJson["event"],
                                aggregatedEventInfoJson["display_name"],
                                null,
                                null,
                                null,
                                aggregatedEventInfoJson["interest_levels"]
                            )
                        );
                    }
                }
            }
        }

        return new EventCountsOverTimeAggregationInfo(dateToAggregatedEventInfoMap);
    }
}

export default EventCountsOverTimeAggregationInfo;
