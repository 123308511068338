import DeviceInfo from "./DeviceInfo";

/**
 * Response of the get devices command.
 */
class GetDevicesResponse {
    /**
     * Constructor.
     */
    constructor(public devices: DeviceInfo[], public totalItemCount: number) {}
}

export default GetDevicesResponse;
