import { makeStyles } from "@material-ui/core";
import commonStyle from "../../../components/CommonStyle";

const useSternumInputFieldStyles = makeStyles((theme) => {
    return {
        ...commonStyle(theme),
        root: {
            "& .MuiOutlinedInput-root": {
                "& fieldset": {
                    borderColor: "#E4E4E4",
                },
            },
            "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                borderColor: "#E4E4E4",
                pointerEvents: "all !important",
                cursor: "not-allowed",
            },
            "& .MuiSelect-select": {
                "&:focus": {
                    borderRadius: "14px",
                },
            },
            "& .MuiFormHelperText-contained": {
                marginLeft: "0px",
            },
            "& .MuiFormHelperText-root.Mui-error": {
                color: "#E7004C",
            },
            "& .MuiFormLabel-root.Mui-error": {
                color: "#E7004C",
            },
            "& .MuiOutlinedInput-root.Mui-error": { "& fieldset": { borderColor: "#E7004C" } },
        },

        borderRadius: {
            borderRadius: "14px",
            borderColor: "#E4E4E4",
            fontFamily: "Inter",
        },
        displayInput: {
            fontSize: "14px",
            fontFamily: "Inter",
            color: "#4F4F4F",
        },
        placeHolderFontSize: {
            fontSize: "14px",
            fontFamily: "Inter",
            color: "#999999",
            background: "white",
        },
        disableColor: {
            backgroundColor: "#ECEFF3",
            borderRadius: "14px",
        },
        backgroundColor: {
            backgroundColor: "#fff",
        },
        disabledBackgroundColor: {
            backgroundColor: "#ECEFF3",
        },
        zeroMargin: {
            marginLeft: "0px",
        },
    };
});

export default useSternumInputFieldStyles;
