import MomentUtils from "@date-io/moment";
import Button from "@material-ui/core/Button";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import classNames from "classnames";
import * as React from "react";
import ListFilter from "../../lib/state/ListFilter";
import TracesFilter from "../../lib/state/TracesFilter";
import tracesListFilterPopoverStyle from "./TracesListFilterPopoverStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    startTime: number;
    endTime: number;
    ipAddress: string;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof tracesListFilterPopoverStyle> {
    tracesFilter: TracesFilter;
    onFilterChanged: (listFilter: ListFilter) => void;
}

/**
 * Popover content for view columns.
 */
class TracesListFilterPopover extends React.Component<AppProps, AppState> {
    /**
     * Defining default state so we can later reset safely.
     */
    private readonly defaultState: AppState = {
        startTime: null,
        endTime: null,
        ipAddress: "",
    };

    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            startTime:
                this.props.tracesFilter && this.props.tracesFilter.startTime ? this.props.tracesFilter.startTime : null,
            endTime:
                this.props.tracesFilter && this.props.tracesFilter.endTime ? this.props.tracesFilter.endTime : null,
            ipAddress:
                this.props.tracesFilter && this.props.tracesFilter.ipAddress ? this.props.tracesFilter.ipAddress : "",
        };
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        return (
            <div>
                {/* Filter popover title */}
                <Typography variant={"caption"} className={classes.titleTypography}>
                    Filter
                </Typography>

                {/* From */}
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DateTimePicker
                        value={this.state.startTime}
                        className={classNames(classes.formControl, classes.fromTimePicker)}
                        label={"From"}
                        clearable
                        onChange={(event) => this.handleStartTimeChanged(event)}
                    />
                </MuiPickersUtilsProvider>

                {/* To */}
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DateTimePicker
                        value={this.state.endTime}
                        className={classNames(classes.formControl)}
                        label={"To"}
                        clearable
                        onChange={(event) => this.handleEndTimeChanged(event)}
                    />
                </MuiPickersUtilsProvider>

                {/* IP Address */}
                <div className={classes.filterRow}>
                    <TextField
                        id="ip-address"
                        label="IP Address"
                        className={classNames(classes.formControl, classes.ipAddressFilter)}
                        value={this.state.ipAddress}
                        onChange={(event) => this.handleFilterByIpChanged(event)}
                    />
                </div>

                {/* Apply & Reset buttons */}
                <div className={classes.resetAndApplyButtons}>
                    {/* Apply */}
                    <Button
                        size={"small"}
                        variant={"contained"}
                        color={"primary"}
                        className={classes.applyButton}
                        onClick={(event) => this.handleFilterApplied()}
                    >
                        Apply
                    </Button>

                    {/* Reset */}
                    <Button size={"small"} variant={"contained"} onClick={(event) => this.handleFilterReset()}>
                        Reset
                    </Button>
                </div>
            </div>
        );
    }

    /**
     * Occurs once the value of ip address is changed.
     */
    private handleFilterByIpChanged(event) {
        this.setState({
            ipAddress: event.target.value,
        });
    }

    /**
     * Occurs once filter is applied.
     */
    private handleFilterApplied() {
        if (this.props.onFilterChanged) {
            this.props.onFilterChanged(
                new TracesFilter(
                    this.state.startTime,
                    this.state.endTime,
                    this.state.ipAddress,
                    null,
                    null,
                    null,
                    null,
                    null
                )
            );
        }
    }

    /**
     * Occurs on reset of filters requested.
     */
    private handleFilterReset() {
        this.setState({
            ...this.defaultState,
        });
    }

    /**
     * Occurs once the value of last seen from is changed.
     */
    private handleStartTimeChanged(newDate) {
        this.setState({
            startTime: new Date(newDate).getTime(),
        });
    }

    /**
     * Occurs once the value of last seen to is changed.
     */
    private handleEndTimeChanged(newDate) {
        this.setState({
            endTime: new Date(newDate).getTime(),
        });
    }
}

export default withStyles(tracesListFilterPopoverStyle)(TracesListFilterPopover);
