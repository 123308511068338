import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const useEmailNotificationStyle = makeStyles((theme: Theme) => {
    const mediaMaxWidth = "550px";

    return {
        content: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            padding: 16,
            margin: "auto",

            [`@media (max-width: ${mediaMaxWidth})`]: {
                margin: "50px 0 0 0",
            },
        },

        innerContainer: {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            padding: "56px",
            background: "#fff",
            boxShadow: "-12px 14px 34px rgba(83, 93, 120, 0.15)",
            borderRadius: "12px",

            [`@media (max-width: ${mediaMaxWidth})`]: {
                padding: "62px 16px 38px 16px",
            },
        },

        logo: {
            "& .text": {
                fill: "#253858",
            },

            [`@media (max-width: ${mediaMaxWidth})`]: {
                height: 28,
                width: 163,
            },
        },

        description: {
            marginTop: "40px",
            fontSize: "22px",
            color: "#091E42",

            [`@media (max-width: ${mediaMaxWidth})`]: {
                fontSize: "14px",
                marginTop: "35px",
            },
        },

        email: {
            marginTop: "24px",
            fontSize: "18px",
            color: "#71829E",
            fontWeight: 500,

            [`@media (max-width: ${mediaMaxWidth})`]: {
                fontSize: "14px",
            },
        },
    };
});
