import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

const devicesPageStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        tableContainer: {
            height: "calc(100vh - 90px)",
            padding: theme.spacing(4),

            "&.mod-sidebar-open": {
                left: theme.spacing(36),
            },
            "&.mod-sidebar-collapsed": {
                left: theme.spacing(11),
            },

            width: "auto",
            overflow: "auto",
            maxHeight: "100%",
        },

        tableInner: {
            height: "100%",
            padding: theme.spacing(3),
            background: "#fff",
            boxShadow: "0px 0px 23px rgba(231, 232, 235, 0.25)",
            borderRadius: 10,
        },
    });

export default devicesPageStyle;
