/**
 * Represents a Sternum GeoLocation
 */
class GeoLocationInfo {
    /**
     * Constructor.
     */
    constructor(
        public continent: string,
        public country: string,
        public city: string,
        public state: string,
        public latitude: number,
        public longitude: number
    ) {}

    /**
     * Converts json to info object.
     */
    public static fromJsonObject(jsonObject: Object) {
        return new GeoLocationInfo(
            jsonObject["continent"],
            jsonObject["country"],
            jsonObject["city"],
            jsonObject["state"],
            jsonObject["latitude"],
            jsonObject["longitude"]
        );
    }
}

export default GeoLocationInfo;
