import React, { ReactNode, useContext, useMemo } from "react";
import AggregatedTraceDefinition from "../../lib/state/AggregatedTraceDefinition";
import { UniqueCountDataContext, UniqueCountMetric } from "./UniqueCountContext";

interface MetricMap {
    [value: string]: UniqueCountMetric;
}

interface DropdownCounterProviderProps {
    children: (metrics: MetricMap) => ReactNode;
}

export function DropdownCounterProvider({ children }: DropdownCounterProviderProps) {
    const availableMetrics = useContext(UniqueCountDataContext);

    const metricMap = useMemo(() => {
        const metrics: MetricMap = {};
        availableMetrics.forEach((metric) => {
            metrics[metric.value] = metric;
        });

        return metrics;
    }, [availableMetrics]);

    return <>{children(metricMap)}</>;
}

/**
 * Enriches the item list with a counter field
 *
 * @param items
 * @param metrics
 * @returns
 */
export function addCounters(items: AggregatedTraceDefinition[] | null | undefined, metrics: MetricMap) {
    if (!items) {
        return items;
    }

    return items.map((item) => {
        const addCounter = (trace: AggregatedTraceDefinition) => {
            if ((trace as any).options) {
                return {
                    ...trace,
                    options: (trace as any).options
                        .map((subitem) => addCounter(subitem))
                        .sort((a, b) => b.counter - a.counter),
                };
            }

            const metric = metrics[trace.value];
            if (!trace.isSpecialField) {
                let itemCounter: any = {};
                if (metric) {
                    itemCounter.counter = metric.counter;
                }

                return {
                    ...trace,
                    ...itemCounter,
                };
            }

            return trace;
        };

        return addCounter(item);
    });
}
