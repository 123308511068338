import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

const usedLibraryViewStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),
        usedLibraryViewUpperContent: {
            paddingTop: theme.spacing(10),
            paddingRight: theme.spacing(3),
            paddingBottom: theme.spacing(3),
            paddingLeft: theme.spacing(3),

            "&.mod-with-back-button": {
                paddingTop: theme.spacing(10),
            },
        },
        tabsContainer: {
            marginTop: theme.spacing(2),
            padding: theme.spacing(0, 4),
        },

        title: {
            marginRight: theme.spacing(2),
        },
        subTitle: {
            color: "#B8BACF",
        },
        securityAlertsTabContent: {
            paddingTop: theme.spacing(2),
        },
        infoTabContent: {
            padding: theme.spacing(4),
        },
        devicesListTabContent: {
            paddingTop: theme.spacing(2),
        },
        cvesListTabContent: {
            paddingTop: theme.spacing(2),
        },
        historiesListTabContent: {
            paddingTop: theme.spacing(2),
        },
        devicesListPaper: {
            "&.mod-regular": {
                maxHeight: theme.spacing(68),
                minHeight: theme.spacing(63) - 1,
            },
            "&.mod-fullscreen": {
                maxHeight: "100vh",
            },
        },
        cvesListPaper: {
            "&.mod-regular": {
                maxHeight: theme.spacing(63),
            },
            "&.mod-fullscreen": {
                maxHeight: "100vh",
            },
        },
        historiesListPaper: {
            "&.mod-regular": {
                maxHeight: theme.spacing(68),
                minHeight: theme.spacing(67) - 1,
            },
            "&.mod-fullscreen": {
                maxHeight: "100vh",
            },
        },

        tableContainer: {
            padding: theme.spacing(4),
            paddingTop: 0,
            "&.mod-sidebar-open": {
                left: theme.spacing(36),
            },
            "&.mod-sidebar-collapsed": {
                left: theme.spacing(11),
            },
            height: "calc(100% - 110px)",
        },

        tableInner: {
            padding: theme.spacing(3),
            background: "#fff",
            boxShadow: "0px 0px 23px rgba(231, 232, 235, 0.25)",
            borderRadius: "14px",
            height: "calc(100% - 30px)",
        },
        tabContent: {
            padding: theme.spacing(0, 4),
            boxShadow: "0px 0px 23px rgba(231, 232, 235, 0.25);",
        },
        innerTabContent: {
            borderBottomLeftRadius: "14px",
            borderBottomRightRadius: "14px",
            borderTopRightRadius: "14px",
            background: "#FFFFFF",
            padding: theme.spacing(3),
        },
        wrapper: {
            height: "100%",
            overflowY: "hidden",
        },
    });

export default usedLibraryViewStyle;
