import { CircularProgress, Step, StepButton, StepLabel, Stepper, withStyles, WithStyles } from "@material-ui/core";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import ServiceWire from "../../lib/services/ServiceWire";
import DeviceDefinitionVersionInfo from "../../lib/state/DeviceDefinitionVersionInfo";
import { GlobalState } from "../../lib/state/GlobalState";
import SternumTriggerTypeDisplayInfo from "../../lib/state/SternumTriggerTypeDisplayInfo";
import TraceCategory from "../../lib/state/TraceCategory";
import ConfirmationDialog from "../../shared_components/ConfirmationDialog/ConfirmationDialog";
import ArgumentDefinitionStep from "../DeviceDefinitionComponents/ArgumentDefinitionStep/ArgumentDefinitionStep";
import DeviceDefinitionForm from "../DeviceDefinitionComponents/DeviceDefinitionForm/DeviceDefinitionForm";
import DeviceDefinitionLibraryStep from "../DeviceDefinitionComponents/LibraryDefinitionStep/DeviceDefinitionLibraryStep";
import TraceDefinitionStep from "../DeviceDefinitionComponents/TraceDefinitionStep/TraceDefinitionStep";
import TriggerDefinitionStep from "../DeviceDefinitionComponents/TriggerDefinitionStep/TriggerDefinitionStep";
import deviceDefinitionEditModalStyle from "./DeviceDefinitionEditModalStyle";

interface AppState {
    activeTab: number;
    tabs: string[];
    loadingData: boolean;
    sternumCategories: TraceCategory[];
    sternumTriggerTypes: SternumTriggerTypeDisplayInfo[];
    deviceDefinitionVersionInfo: DeviceDefinitionVersionInfo;
    isFormInDirtyState: boolean;
    openDirtyStateDialog: boolean;
    nextTab: number;
}

export interface AppProps extends WithStyles<typeof deviceDefinitionEditModalStyle> {
    fullScreenDisplay: boolean;
    theme?;
    deviceDefinitionVersionId: string;
    closeDialog: () => void;
    updateDeviceDefinitionCallbackFunction: () => void;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {};
};

class EditDeviceDefinitionModal extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);
        // Initializing the state to default.
        this.state = {
            activeTab: 0,
            tabs: ["Device Profile", "Traces", "Arguments", "Sternum Alerts", "3ʳᵈ Party"],
            loadingData: true,
            sternumCategories: null,
            sternumTriggerTypes: null,
            deviceDefinitionVersionInfo: null,
            isFormInDirtyState: false,
            openDirtyStateDialog: false,
            nextTab: -1,
        };
    }

    /*
     *  Occurs once the component finished its initialization process.
     */
    async componentDidMount() {
        this.setState({
            loadingData: true,
        });
        await this.loadDeviceDefinitionVersionInnerData();
        await this.loadCategories();
        await this.loadSternumTriggerTypes();
        this.setState({
            loadingData: false,
        });
    }

    /*
     *  Load Sternum trigger types from server.
     */
    private loadSternumTriggerTypes = async () => {
        const serverResponse = await ServiceWire.getSternumService().getSternumTriggerTypes();
        if (serverResponse) {
            this.setState({
                sternumTriggerTypes: serverResponse,
            });
        }
    };

    /*
     * This method is called when a component is being removed from the DOM:
     */
    componentWillUnmount() {
        this.props.updateDeviceDefinitionCallbackFunction();
    }

    /*
     * Load device definition inner data
     */
    private loadDeviceDefinitionVersionInnerData = async () => {
        const deviceDefinitionVersion: DeviceDefinitionVersionInfo =
            await ServiceWire.getSternumService().getFullDeviceDefinitionVersion(this.props.deviceDefinitionVersionId);

        // NOTE: These are all tricks so we wouldn't have to change the way the definition modals work today.
        deviceDefinitionVersion.deviceDefinition.traceDefinitions = deviceDefinitionVersion.traceDefinitions;
        deviceDefinitionVersion.deviceDefinition.argumentDefinitions = deviceDefinitionVersion.argumentDefinitions;
        deviceDefinitionVersion.deviceDefinition.sternumTriggers = deviceDefinitionVersion.sternumTriggers;
        deviceDefinitionVersion.deviceDefinition.deviceDefinitionLibraries =
            deviceDefinitionVersion.deviceDefinitionLibraries;

        if (deviceDefinitionVersion) {
            this.setState({
                deviceDefinitionVersionInfo: deviceDefinitionVersion,
            });
        }
    };

    /*
     *  Load Sternum categories from server.
     */
    private loadCategories = async () => {
        const serverResponse = await ServiceWire.getSternumService().getTraceCategories();
        if (serverResponse) {
            this.setState({
                sternumCategories: serverResponse,
            });
        }
    };

    render() {
        const { classes } = this.props;
        // Loading element
        const loadingDataElement = (
            <div className={classNames(classes.flexVMiddle, classes.flexCenter)}>
                <CircularProgress />
            </div>
        );
        // Show loading element if loading data
        if (this.state.loadingData) {
            return loadingDataElement;
        }

        // Show data
        return (
            <div className={classNames(classes.root)} key="container">
                <Stepper
                    alternativeLabel
                    nonLinear
                    activeStep={this.state.activeTab}
                    classes={{ root: classNames(classes.stepper) }}
                >
                    {this.state.tabs.map((label, index) => {
                        const stepProps: { completed?: boolean } = {};
                        const buttonProps: { optional?: React.ReactNode } = {};
                        return (
                            <Step key={label} {...stepProps}>
                                <StepButton
                                    onClick={() => {
                                        if (this.state.activeTab === 0 && this.state.isFormInDirtyState) {
                                            this.setState({
                                                openDirtyStateDialog: true,
                                                nextTab: index,
                                            });
                                        } else {
                                            this.setState({ activeTab: index });
                                        }
                                    }}
                                    completed={true}
                                    {...buttonProps}
                                >
                                    <StepLabel
                                        StepIconProps={
                                            this.state.activeTab === index
                                                ? { classes: { root: classes.selectedStep } }
                                                : {}
                                        }
                                    >
                                        {label}
                                    </StepLabel>
                                </StepButton>
                            </Step>
                        );
                    })}
                </Stepper>

                <div>{this.getTabContent(this.state.activeTab, "activeStep" + this.state.activeTab)}</div>

                {/** Confirmation dialog for dirty state in form */}
                <ConfirmationDialog
                    title={`Unsaved Changes`}
                    body={"You have unsaved changes that will be lost if you decide to continue."}
                    open={this.state.openDirtyStateDialog}
                    handleApprove={() => {
                        this.setState({
                            activeTab: this.state.nextTab,
                            openDirtyStateDialog: false,
                            isFormInDirtyState: false,
                        });
                    }}
                    handleCancel={() => {
                        this.setState({ openDirtyStateDialog: false });
                    }}
                    overrideActionName={"Continue"}
                />
            </div>
        );
    }

    private getTabContent(tabIndex: number, key: any) {
        switch (tabIndex) {
            case 0:
                return (
                    <DeviceDefinitionForm
                        key={key}
                        deviceDefinition={this.state.deviceDefinitionVersionInfo.deviceDefinition}
                        deviceDefinitionVersion={this.state.deviceDefinitionVersionInfo}
                        forceCRUD={true}
                        isDirtyState={(isDirty: boolean) => {
                            this.setState({
                                isFormInDirtyState: isDirty,
                            });
                        }}
                    />
                );
            case 1:
                return (
                    <TraceDefinitionStep
                        key={key}
                        deviceDefinition={this.state.deviceDefinitionVersionInfo.deviceDefinition}
                        deviceDefinitionVersion={this.state.deviceDefinitionVersionInfo}
                        categoriesList={this.state.sternumCategories}
                        forceCRUD={true}
                    />
                );
            case 2:
                return (
                    <ArgumentDefinitionStep
                        key={key}
                        deviceDefinition={this.state.deviceDefinitionVersionInfo.deviceDefinition}
                        deviceDefinitionVersion={this.state.deviceDefinitionVersionInfo}
                        forceCRUD={true}
                    />
                );

            case 3:
                return (
                    <TriggerDefinitionStep
                        key={key}
                        deviceDefinition={this.state.deviceDefinitionVersionInfo.deviceDefinition}
                        deviceDefinitionVersion={this.state.deviceDefinitionVersionInfo}
                        forceCRUD={true}
                        sternumTriggerTypes={this.state.sternumTriggerTypes}
                    />
                );
            case 4:
                return (
                    <DeviceDefinitionLibraryStep
                        key={key}
                        deviceDefinition={this.state.deviceDefinitionVersionInfo.deviceDefinition}
                        deviceDefinitionVersion={this.state.deviceDefinitionVersionInfo}
                        forceCRUD={true}
                    />
                );
            default:
                return "Unknown stepIndex";
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(deviceDefinitionEditModalStyle, { withTheme: true })(EditDeviceDefinitionModal));
