import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";

const visualisationButtonStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: {
            display: "flex",
            alignItems: "center",
        },

        toolbarItemIconContainer: {
            padding: "2px",
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1),
        },

        iconSize: {
            "font-size": theme.spacing(3),
        },

        toolbarItemTitle: {
            fontSize: "14px",
        },

        inactiveIcon: {
            color: theme.palette.grey["500"],
        },

        createNewVisualisation: {
            padding: theme.spacing(2),
            fontSize: "14px",
            cursor: "pointer",
        },

        visualisationItem: {
            margin: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
            fontSize: "14px",
            cursor: "pointer",
        },

        divider: {
            marginBottom: theme.spacing(2),
        },

        visualisationsContainer: {
            marginBottom: theme.spacing(2),
        },
    });

export default visualisationButtonStyle;
