import { Typography } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { debounce, throttle } from "lodash";
import * as queryString from "querystring";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TimeAgo from "react-timeago";
import HashSet from "../../lib/infra/HashSet";
import SternumConfiguration from "../../lib/infra/SternumConfiguration";
import SternumUtils from "../../lib/infra/SternumUtils";
import Utils from "../../lib/infra/Utils";
import WebUtils from "../../lib/infra/WebUtils";
import { openDeviceViewModalAction } from "../../lib/redux/modals/OpenModalAction";
import ConfigurationService from "../../lib/services/ConfigurationService";
import DebounceService from "../../lib/services/DebounceService";
import PollingSubscriptionInfo from "../../lib/services/PollingSubscriptionInfo";
import ServiceWire from "../../lib/services/ServiceWire";
import { NotificationOperation } from "../../lib/services/WebsocketService";
import { CopyMechanismInfo } from "../../lib/state/CopyMechanismInfo";
import DeviceInfo, { DeviceMode } from "../../lib/state/DeviceInfo";
import DevicesFilter from "../../lib/state/DevicesFilter";
import DeviceStatus from "../../lib/state/DeviceStatus";
import EventFilter from "../../lib/state/EventFilter";
import { GlobalState } from "../../lib/state/GlobalState";
import ListFilter from "../../lib/state/ListFilter";
import PollingChangeType from "../../lib/state/PollingChangeType";
import TableColumnHeaderInfo from "../../lib/state/TableColumnHeaderInfo";
import TableRowData from "../../lib/state/TableRowData";
import TableToolbarDisplayState from "../../lib/state/TableToolbarDisplayState";
import DevicesListFilterPopover from "../DevicesListFilterPopover/DevicesListFilterPopover";
import StatusDisplay from "../StatusDisplay/StatusDisplay";
import SternumFlag from "../SternumFlag/SternumFlag";
import SternumCopyMenu from "../SUI/SternumCopyMenu/SternumCopyMenu";
import SternumLink from "../SUI/SternumLink/SternumLink";
import SternumTable from "../SUI/SternumTable/SternumTable";
import devicesListStyle from "./DevicesListStyle";
import EntityManager from "../../lib/infra/EntityManager";
import TraceInfo from "../../lib/state/TraceInfo";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    // Loading & error state.
    loadingDevices: boolean;
    errorLoadingDevices: boolean;

    // Devices.
    devices: DeviceInfo[];
    totalItemCount: number;

    // Filtering.
    devicesFilter: DevicesFilter;
    searchText: string;
    currentPage: number;
    orderByField: string;
    order: "asc" | "desc";

    // Refreshing.
    newEvents?: number;

    // Right click
    anchorEl: Element;
    openRowMenu: boolean;
    clickEvent: React.MouseEvent<HTMLDivElement>;
    rightClickedInfo: CopyMechanismInfo;
    rightClickedRow: TableRowData;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof devicesListStyle> {
    sideBarOpen: boolean;
    location;
    history;

    includeColumns?: string[];
    displayBackButtonInDeviceView?: boolean;
    displayXButtonInDeviceView?: boolean;
    paperClassNames?;
    viewedColumnsSet?: HashSet;

    applyFiltersOnUrl?: boolean;

    devicesFilter: DevicesFilter;

    openDeviceViewModal?: (key: string, deviceId: string, displayXButton: boolean, displayBackButton: boolean) => void;

    showAllColumns?: boolean;

    openDeviceViewInCurrentPage?: boolean;

    toolBarState?: TableToolbarDisplayState;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {
        openDeviceViewModal: (key: string, deviceId: string, displayXButton: boolean, displayBackButton: boolean) =>
            dispatch(openDeviceViewModalAction(key, false, deviceId, null, displayXButton, displayBackButton)),
    };
};

/**
 * List of items to display in copy menu
 */
const copyItems: CopyMechanismInfo[] = [{ value: "Device Id", label: "Copy Device ID" }];

/**
 * Holds the devices list in the app.
 */
class DevicesList extends React.Component<AppProps, AppState> {
    /**
     * Defines the column headers participating in the devices table.
     */
    private readonly columnHeaders: TableColumnHeaderInfo[] = [
        new TableColumnHeaderInfo("entityId", "Device ID", false, true, false),
        new TableColumnHeaderInfo("name", "Profile Name", false, true, false),
        new TableColumnHeaderInfo("firmwareVersion", "Firmware Version", false, true, false, false),
        new TableColumnHeaderInfo("status", "Device Status", false, true, false),
        new TableColumnHeaderInfo("lastSeen", "Last Seen", false, true, false),
        new TableColumnHeaderInfo("geoLocation", "Country", false, true, false, false),
    ];
    private deviceIdsWithNewTraces: Set<string> = new Set();

    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        if (this.props.includeColumns) {
            if (this.props.includeColumns.length) {
                let includeColumnsSet = HashSet.fromValues(this.props.includeColumns);
                this.columnHeaders = this.columnHeaders.filter((columnHeader) =>
                    includeColumnsSet.exists(columnHeader.id)
                );
            } else {
                this.columnHeaders = [];
            }
        }

        if (this.props.showAllColumns) {
            for (let i = 0; i < this.columnHeaders.length; i++) {
                this.columnHeaders[i].isHidden = false;
            }
        }

        // Initializing the state to default.
        this.state = {
            loadingDevices: false,
            errorLoadingDevices: false,

            devices: [],

            devicesFilter: this.props.devicesFilter,
            searchText: null,
            currentPage: 1,
            orderByField: null,
            order: "asc",

            totalItemCount: null,
            newEvents: 0,

            anchorEl: null,
            openRowMenu: false,
            clickEvent: null,
            rightClickedInfo: { label: "", value: "" },
            rightClickedRow: null,
        };
    }

    /**
     * Occurs once the component is about to receive props.
     */
    UNSAFE_componentWillReceiveProps(nextProps: Readonly<AppProps>, nextContext: any): void {
        if (
            (!nextProps.devicesFilter && this.props.devicesFilter) ||
            (nextProps.devicesFilter && !this.props.devicesFilter) ||
            (nextProps.devicesFilter &&
                this.props.devicesFilter &&
                nextProps.devicesFilter.isDifferentFrom(this.props.devicesFilter))
        ) {
            this.handleDevicesFilterChanged(nextProps.devicesFilter);
        }
    }

    /**
     * Occurs once the component finished its initialization process.
     */
    async componentDidMount() {
        // Get devices filters from url.
        let devicesFiltersFromUrl = this.parseDevicesFilterFromUrl();
        if (devicesFiltersFromUrl) {
            this.setState(
                {
                    devicesFilter: devicesFiltersFromUrl,
                },
                async () => {
                    // Load devices.
                    await this.loadDevices(1);
                }
            );
        } else {
            await this.loadDevices(1);
        }

        if (!ConfigurationService.isNewArchitectureEnabled()) {
            // Registering to polling for issues.
            ServiceWire.getPollingService().subscribe(
                new PollingSubscriptionInfo(
                    "DevicesList",
                    PollingChangeType.ALL_DEVICES,
                    this.handleChangedDevices.bind(this)
                )
            );
            ServiceWire.getPollingService().subscribe(
                new PollingSubscriptionInfo(
                    "DevicesList",
                    PollingChangeType.ALL_TRACES,
                    this.handleChangedTraces.bind(this)
                )
            );
        }

        ServiceWire.getWebsocketService().subscribe(
            "DevicesList",
            NotificationOperation.DEVICE_UPDATED,
            throttle(() => {
                // live reload only if user is on the first page and havent entered any search query
                if (this.state.currentPage === 1 && !this.state.searchText) {
                    this.loadDevices(1, false);
                }
            }, 5000)
        );
    }

    filterDevices(entity: any) {
        if (entity.entity_type !== "DEVICE") {
            return false;
        }

        const device = DeviceInfo.fromJsonObject(entity);
        if (!this.state.devicesFilter || !this.state.devicesFilter.isActive()) {
            return true;
        }

        const filter = this.state.devicesFilter;

        if (filter.lastSeenFrom && filter.lastSeenFrom > device.lastSeen) {
            return false;
        }

        if (filter.lastSeenTo && filter.lastSeenTo < device.lastSeen) {
            return false;
        }

        if (filter.createdFrom && filter.createdFrom > device.created) {
            return false;
        }

        if (filter.createdTo && filter.createdTo < device.created) {
            return false;
        }

        if (
            filter.statuses &&
            filter.statuses.length &&
            !filter.statuses.find((status) => status === device.status.toString())
        ) {
            return false;
        }

        if (
            filter.ipAddresses &&
            filter.ipAddresses.length &&
            !filter.ipAddresses.find((ipAddress) => ipAddress === device.ipAddress)
        ) {
            return false;
        }

        return true;
    }

    handleChangedDevices(fromTimestamp: number, changedData: Object): void {
        if (changedData && !!changedData["entities"]) {
            const countChangedDevices = changedData["entities"].filter(this.filterDevices.bind(this)).length;
            this.setState({
                newEvents: this.state.newEvents + countChangedDevices,
            });
        }
    }

    handleChangedTraces(fromTimestamp: number, changedData: Object): void {
        if (changedData && !!changedData["entities"]) {
            let traces = EntityManager.getSternumEntities(changedData).map((entity) => entity as TraceInfo);
            const previousSize = this.deviceIdsWithNewTraces.size;
            traces.forEach((t) => this.deviceIdsWithNewTraces.add(t.deviceId));
            const eventsDelta = this.deviceIdsWithNewTraces.size - previousSize;
            this.setState({
                newEvents: this.state.newEvents + eventsDelta,
            });
        }
    }

    componentWillUnmount(): void {
        ServiceWire.getPollingService().unsubscribe("DevicesList", PollingChangeType.ALL_DEVICES);
        ServiceWire.getPollingService().unsubscribe("DevicesList", PollingChangeType.ALL_TRACES);
        ServiceWire.getWebsocketService().unsubscribe("DevicesList", NotificationOperation.DEVICE_UPDATED);
    }

    shouldDisplayRefreshButton = () => !ConfigurationService.isNewArchitectureEnabled();

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        return (
            <>
                <SternumTable
                    role="presentation"
                    ariaLabel="devices list table"
                    nonClickableRows
                    maxTableHeightClass={classes.limitHeight}
                    viewedColumnsSet={this.props.viewedColumnsSet}
                    pageSize={SternumConfiguration.getPageSize()}
                    amountOfLoadingPlaceholders={9}
                    totalItemCount={this.state.totalItemCount}
                    loadingItems={this.state.loadingDevices}
                    errorLoadingItems={this.state.errorLoadingDevices}
                    columnHeaders={this.columnHeaders}
                    listFilter={this.state.devicesFilter}
                    toolbarState={
                        this.props.toolBarState
                            ? this.props.toolBarState
                            : new TableToolbarDisplayState(
                                  true,
                                  false,
                                  this.shouldDisplayRefreshButton(),
                                  false,
                                  false,
                                  false,
                                  false,
                                  false,
                                  "Search Device"
                              )
                    }
                    onRefreshClicked={
                        !ConfigurationService.isNewArchitectureEnabled() && (() => this.onRefreshClicked())
                    }
                    newEvents={!ConfigurationService.isNewArchitectureEnabled() && this.state.newEvents}
                    filterComponentProvider={(onFilterChanged) => (
                        <DevicesListFilterPopover
                            devicesFilter={this.state.devicesFilter}
                            onFilterChanged={onFilterChanged}
                        />
                    )}
                    orderByField={this.state.orderByField}
                    order={this.state.order}
                    rows={this.state.devices}
                    onFilterChanged={(listFilter) => this.handleDevicesFilterChanged(listFilter)}
                    onSearchTextChanged={(searchText) => this.handleSearchTextChanged(searchText)}
                    onPageChanged={this.handlePageChange}
                    onOrderChanged={(orderByField, order) => this.handleOrderChanged(orderByField, order)}
                    getRowValues={(row) => this.getRowDisplayValues(row)}
                    onCellRightClick={(cellName: string, clickedRow: TableRowData, event) => {
                        this.onCellCopyClick(cellName, clickedRow, event);
                    }}
                />
                <SternumCopyMenu
                    openMenu={this.state.openRowMenu}
                    fieldsInfo={copyItems}
                    anchorEl={this.state.anchorEl}
                    copyId={this.onCopyId}
                    closeMenu={() => this.setState({ openRowMenu: false })}
                />
            </>
        );
    }

    private handlePageChange = (pageNumber: number) => {
        this.setState({ currentPage: pageNumber });
        this.loadDevices(pageNumber);
    };

    /**
     * Handle copy device id click
     */
    private onCopyId = (cellName: string) => {
        const deviceInfo = this.state.rightClickedRow as DeviceInfo;
        WebUtils.copyContentToClipBoard(deviceInfo.receivedDeviceId);
        this.setState({ openRowMenu: false });
    };

    private onCellCopyClick = (cellName: string, clickedRow: TableRowData, event) => {
        this.setState({
            openRowMenu: true,
            anchorEl: event.target,
            rightClickedInfo: { label: cellName, value: "cellValue" },
            rightClickedRow: clickedRow,
        });
    };

    /**
     * Gets the display values for row's columns (where they aren't the default).
     */
    private getRowDisplayValues(row) {
        const deviceInfo = row as DeviceInfo;

        const countryCode = deviceInfo.countryCode;
        const countryName = countryCode ? SternumUtils.getCountryNameByCode(countryCode) : null;

        const isDeviceInDevMode = deviceInfo.deviceMode === DeviceMode.Development;
        const statusLabel = !isDeviceInDevMode ? deviceInfo.getStatusDisplay() : deviceInfo.getDeviceModeLabel();

        return {
            entityId: !Utils.isNullOrUndefined(deviceInfo.receivedDeviceId) ? (
                <SternumLink to={`/device/${deviceInfo.entityId}`} isExternal={!this.props.openDeviceViewInCurrentPage}>
                    {deviceInfo.receivedDeviceId.toString()}
                </SternumLink>
            ) : (
                "Unknown"
            ),
            name: deviceInfo.lastSeenVersionId ? (
                <SternumLink
                    onlyActiveOnHover
                    to={`fleet-view/${deviceInfo.lastSeenVersionId}`}
                    isExternal={!this.props.openDeviceViewInCurrentPage}
                >
                    {deviceInfo.deviceDefinition.displayName}
                </SternumLink>
            ) : (
                deviceInfo.deviceDefinition.displayName
            ),
            status: (
                <StatusDisplay
                    label={statusLabel}
                    danger={!isDeviceInDevMode && deviceInfo.isAlertingStatus()}
                    success={!isDeviceInDevMode && deviceInfo.isSecured()}
                    devMode={isDeviceInDevMode}
                    medium={
                        !isDeviceInDevMode &&
                        (deviceInfo.status === DeviceStatus.CRITICAL_CVE ||
                            deviceInfo.status === DeviceStatus.OUT_OF_DATE_LIBRARIES ||
                            deviceInfo.status === DeviceStatus.MULTIPLE_ISSUES)
                    }
                    displayColoredBackground={true}
                    centerText={true}
                    padding={true}
                />
            ),
            lastSeen: <TimeAgo date={(row as DeviceInfo).lastSeen} />,
            firmwareVersion: deviceInfo.getFirmwareVersion(),
            geoLocation: (
                <div className={classNames(this.props.classes.flexVMiddle, this.props.classes.flexRow)}>
                    <SternumFlag countryCode={countryCode} />
                    <Typography className={classNames(this.props.classes.paddingLeft)}>
                        {countryName || "N/A"}
                    </Typography>
                </div>
            ),
        };
    }

    /**
     * Redirect to device page
     */
    private onDeviceIdClick(device: DeviceInfo) {
        // this.openDeviceViewModal(device.entityId);
        if (this.props.openDeviceViewInCurrentPage) {
            this.props.history.push({
                pathname: `device/${device.entityId}`,
            });
        } else {
            window.open(`device/${device.entityId}`, "_blank");
        }
    }

    /**
     * Redirect to fleet view page
     */
    private onDeviceDefinitionClick(device: DeviceInfo) {
        // this.openDeviceViewModal(device.entityId);
        if (this.props.openDeviceViewInCurrentPage)
            this.props.history.push({
                pathname: `fleet-view/${device.lastSeenVersionId}`,
            });
    }

    /**
     * Occurs once the filters of the list are changed.
     */
    private handleDevicesFilterChanged(devicesFilter: ListFilter) {
        if (this.props.applyFiltersOnUrl) {
            if (devicesFilter && devicesFilter.isActive()) {
                this.props.history.push({
                    pathname: this.props.location.pathname,
                    search: `?${queryString.stringify({
                        devicesFilter: btoa(JSON.stringify(devicesFilter)),
                    })}`,
                });
            } else {
                this.props.history.push({
                    pathname: this.props.location.pathname,
                    search: `?${queryString.stringify({})}`,
                });
            }
        }

        this.setState(
            {
                devicesFilter: devicesFilter != null ? (devicesFilter as DevicesFilter) : null,
            },
            async () => {
                await this.loadDevices(1);
            }
        );
    }

    /**
     * Occurs once search text is changed.
     */
    private handleSearchTextChanged(searchText) {
        // Debounce request to search devices.

        DebounceService.debounce(
            "getDevices",
            () => {
                this.setState(
                    {
                        searchText: searchText,
                    },
                    () => this.loadDevices(1)
                );
            },
            350
        );
    }

    /**
     * Occurs once sort order is changed.
     */
    private handleOrderChanged(orderByField, order) {
        this.setState(
            {
                orderByField: orderByField,
                order: order,
            },
            () => this.loadDevices(1)
        );
    }

    /**
     * Loads devices.
     */
    private async loadDevices(pageNumber, showLoader = true) {
        try {
            // Setting loading to true.
            this.setState({
                loadingDevices: showLoader,
                errorLoadingDevices: false,
                newEvents: 0,
            });

            const offset = (pageNumber - 1) * SternumConfiguration.getPageSize();

            // Fetching devices.
            let devicesResponse = await ServiceWire.getSternumService().getDevices({
                clientId: ServiceWire.getClientsService().getSelectedClientId(),
                doNotReturnEntities: false,
                returnTotalCount: true,
                devicesFilter: this.state.devicesFilter,
                searchText: this.state.searchText,
                sortByField: this.state.orderByField,
                sortOrder: this.state.order,
                offset,
                limit: SternumConfiguration.getPageSize(),
            });

            // Only get total count in the first batch (offset=0)
            const totalItemCount = offset ? this.state.totalItemCount : devicesResponse.totalItemCount;

            this.setState({
                loadingDevices: false,
                errorLoadingDevices: false,
                devices: devicesResponse.devices,
                totalItemCount,
            });
        } catch (error) {
            this.setState({
                loadingDevices: false,
                errorLoadingDevices: true,
            });
        }
    }

    /**
     * Occurs once the user refreshes the list.
     */
    private async onRefreshClicked() {
        this.deviceIdsWithNewTraces = new Set();
        return this.loadDevices(1);
    }

    /**
     * Parses the devices filter from url if exists.
     */
    private parseDevicesFilterFromUrl(): DevicesFilter {
        let evaluatedParsedUrlQuery = Utils.parseQueryStringParamsObject(queryString.parse(this.props.location.search));

        if (evaluatedParsedUrlQuery.devicesFilter) {
            let parsedDevicesFilterJson = JSON.parse(atob(evaluatedParsedUrlQuery.devicesFilter));

            let convertedEventFilters = [];
            let parsedEventFilters = parsedDevicesFilterJson.eventFilters;
            if (parsedEventFilters && parsedEventFilters.length) {
                convertedEventFilters = parsedEventFilters.map(
                    (parsedEventFilter) =>
                        new EventFilter(
                            parsedEventFilter.eventType,
                            parsedEventFilter.from ? new Date(parsedEventFilter.from) : null,
                            parsedEventFilters.to ? new Date(parsedEventFilters.to) : null
                        )
                );
            }

            return new DevicesFilter(
                parsedDevicesFilterJson.deviceNames,
                parsedDevicesFilterJson.statuses,
                parsedDevicesFilterJson.ipAddresses,
                parsedDevicesFilterJson.lastSeenFrom,
                parsedDevicesFilterJson.lastSeenTo,
                parsedDevicesFilterJson.createdFrom,
                parsedDevicesFilterJson.createdTo,
                convertedEventFilters,
                parsedDevicesFilterJson.libraryId,
                parsedDevicesFilterJson.onlyVulnerableDevices,
                parsedDevicesFilterJson.onlyOutOfDateDevices
            );
        } else {
            return null;
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(devicesListStyle)(DevicesList)));
