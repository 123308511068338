import { makeStyles } from "@material-ui/core";
import commonStyle from "../../../components/CommonStyle";

const useSternumLinkStyles = makeStyles((theme) => {
    return {
        ...commonStyle(theme),

        inactive: {
            color: "inherit",
            textDecoration: "none",
            "&:hover": {
                color: "#0d47a1",
                cursor: "pointer",
                textDecoration: "underline",
            },
        },

        link: {
            color: "#0d47a1",
            cursor: "pointer",
            textDecoration: "none",
            "&:hover": {
                textDecoration: "underline",
            },
        },
    };
});

export default useSternumLinkStyles;
