import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useStyles } from "./ProtectOpenWRTStep.style";
import { StepNumber } from "../StepNumber";
import SternumImprovedButton from "../../SUI/SternumImprovedButton/SternumImprovedButton";
import SternumLink from "../../SUI/SternumLink/SternumLink";
import classNames from "classnames";
import { SignupUserGuideStep } from "../../../lib/state/ClientInfo.types";
import { ExternalLinkIcon } from "../../SUI/SternumIcon";

interface ProtectOpenWRTStepProps {
    active: boolean;
    onNextClick: (step: SignupUserGuideStep) => unknown;
    stepNumber: number;
    completed: boolean;
    className?: string;
}

export function ProtectOpenWRTStep({ active, onNextClick, stepNumber, completed, className }: ProtectOpenWRTStepProps) {
    const classes = useStyles();

    const handleNextClick = () => {
        onNextClick(SignupUserGuideStep.PrepareOpenWRT);
    };

    return (
        <div className={classNames(classes.container, className)}>
            <div className={classes.inner}>
                <div>
                    <StepNumber orderNumber={stepNumber} completed={completed} />
                </div>

                <Box ml={3}>
                    <Typography className={classes.title}>Prepare your OpenWrt</Typography>

                    {active && (
                        <>
                            <Typography className={classes.description}>
                                Sternum Platform Free license allows you to protect the following major OpenWrt
                                versions:
                                <ul>
                                    <li>OpenWrt 21</li>
                                    <li>OpenWrt 19</li>
                                </ul>
                            </Typography>

                            <Typography className={classes.extraDescription}>
                                Visit this{" "}
                                <SternumLink href="https://firmware-selector.openwrt.org" target="_blank">
                                    link <ExternalLinkIcon width={14} height={14} />
                                </SternumLink>{" "}
                                to download a supported version.
                            </Typography>

                            <Box mt={3}>
                                <SternumImprovedButton
                                    buttonType="regular"
                                    content="Next"
                                    fullWidth={false}
                                    onClick={handleNextClick}
                                />
                            </Box>
                        </>
                    )}
                </Box>
            </div>
        </div>
    );
}
