import { Box, Typography } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";

import { GlobalState } from "../../lib/state/GlobalState";
import { useGetSupportStyle } from "./GetSupport.style";
import { GetSupportBanner } from "../GetSupportBanner";
import { GetSupportGuide } from "./GetSupportGuide";

export interface GetSupportProps {
    sideBarOpen?: boolean;
}

const mapStateToProps = (state: GlobalState, ownProps: GetSupportProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

type GetSupportPropsWithHOC = GetSupportProps &
    ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps>;

function GetSupportComponent({}: GetSupportPropsWithHOC) {
    const classes = useGetSupportStyle();

    return (
        <div className={classes.root}>
            <GetSupportBanner />

            <div className={classes.content}>
                <Typography variant="h5" className={classes.mainTitle}>
                    How it works
                </Typography>

                <Box py={6}>
                    <GetSupportGuide />
                </Box>
            </div>

            <GetSupportBanner title="Didn't find what you were looking for?" />
        </div>
    );
}

export const GetSupport: React.FC<GetSupportProps> = connect(mapStateToProps, mapDispatchToProps)(GetSupportComponent);
