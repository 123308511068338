import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import _ from "lodash";
import React, { useLayoutEffect, useRef } from "react";
import { GraphData } from "../../../lib/infra/GraphUtils";
import Utils from "../../../lib/infra/Utils";

export interface SternumTimeSeriesChartProps {
    data?: GraphData;
    displayLegend?: boolean;
    disableZoom?: boolean;
    isStacked?: boolean;
    isLogarithmicScale?: boolean;
    height?: number | string;
    tooltipCallback?: TooltipCallback;
    copyCategoryOnPointClick?: boolean;
}

am4core.useTheme(am4themes_animated);
am4core.options.autoDispose = true;

type TooltipCallback = (value: string, target: am4charts.LineSeries, key: "tooltipHTML") => string;

function addSeries(
    chart: am4charts.XYChart,
    yAxisValues,
    seriesName: string,
    color: am4core.Color,
    isStacked: boolean = false,
    tooltipCallback?: TooltipCallback,
    copyCategoryOnPointClick = false
) {
    /* Create series */
    let series = new am4charts.LineSeries();

    for (let index = 0; index < chart.data.length; index++) {
        series.data.push({
            ...yAxisValues.data[index],
            date: chart.data[index].date,
            extraLabel: yAxisValues.extraLabel,
        });
    }

    series.dataFields.valueY = "value";
    series.dataFields.categoryX = "date";
    series.dataFields.categoryY = "extraLabel";
    series.name = yAxisValues.label;
    series.strokeWidth = 2;
    series.fillOpacity = 0.7;
    series.stacked = isStacked;
    series.stroke = am4core.color(color);

    const fillGradient = new am4core.LinearGradient();
    fillGradient.rotation = 90;
    fillGradient.addColor(series.stroke as am4core.Color, 0.8);
    fillGradient.addColor(series.stroke as am4core.Color, 0.1);
    series.fill = fillGradient;

    series.tooltipHTML = yAxisValues.tooltipContent || "<div style='font-size: 13px;'>{name}: <b>{valueY}</b></div>";
    if (tooltipCallback) {
        series.adapter.add("tooltipHTML", tooltipCallback);
    }

    if (copyCategoryOnPointClick) {
        series.segments.template.interactionsEnabled = true;
        series.segments.template.events.on("hit", (e) => {
            // @ts-ignore
            window.copyTextToClipboard(e.target.dataItem.component.tooltipDataItem.categoryY);
        });
    }

    series.tooltip.label.interactionsEnabled = true;
    series.tooltip.keepTargetHover = true;
    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = series.stroke;
    series.tooltip.background.stroke = null;
    series.tooltip.background.cornerRadius = 6;
    series.tooltip.background.filters.clear();
    series.tooltip.label.padding(6, 11, 6, 11);
    series.tooltip.zIndex = 10;

    chart.series.push(series);
}

function SternumTimeSeriesChart(props: SternumTimeSeriesChartProps) {
    const chartId = `chart_div_${Utils.now()}`;
    const myChart = useRef<am4charts.XYChart>(null);

    useLayoutEffect(() => {
        let chart = am4core.create(chartId, am4charts.XYChart);

        chart.fontFamily = '"Inter", "Roboto", "Helvetica", "Arial", sans-serif';
        chart.fontSize = 12;
        chart.paddingLeft = 0;
        chart.paddingRight = 0;

        /* Add x axis data (dates) */
        chart.data = props.data.labels.map((date) => {
            return { date: date };
        });

        /* Create axes */
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "date";
        categoryAxis.renderer.grid.template.stroke = am4core.color("#959595");
        categoryAxis.renderer.grid.template.strokeOpacity = 0.1;
        categoryAxis.tooltip.background.cornerRadius = 8;
        categoryAxis.tooltip.label.fontSize = 14;
        categoryAxis.tooltip.label.padding(6, 10, 6, 10);
        categoryAxis.renderer.labels.template.fill = am4core.color("#B8BACF");
        categoryAxis.renderer.labels.template.fontSize = 12;
        // @ts-ignore
        categoryAxis.renderer.labels.template.fontWeight = 500;

        /* Create value axis */
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.grid.template.stroke = am4core.color("#959595");
        valueAxis.renderer.grid.template.strokeOpacity = 0.1;
        valueAxis.tooltip.background.cornerRadius = 8;
        valueAxis.tooltip.label.fontSize = 14;
        valueAxis.tooltip.label.padding(6, 10, 6, 10);
        valueAxis.renderer.labels.template.fill = am4core.color("#B8BACF");
        valueAxis.renderer.labels.template.fontSize = 12;
        // @ts-ignore
        valueAxis.renderer.labels.template.fontWeight = 500;

        if (props.isLogarithmicScale) {
            valueAxis.logarithmic = true;
            valueAxis.treatZeroAs = 0.1;
        }

        /* Add series */
        const colorSet = new am4core.ColorSet();
        props.data.datasets.forEach((dataset) => {
            addSeries(
                chart,
                dataset,
                dataset.id,
                dataset.color || colorSet.next(),
                props.isStacked,
                props.tooltipCallback,
                props.copyCategoryOnPointClick
            );
        });

        /* Create a cursor */
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.maxTooltipDistance = 5;

        /* Disable zoom operation */
        if (props.disableZoom) {
            chart.cursor.behavior = "none";
        }

        if (props.displayLegend) {
            /* Add legend */
            chart.legend = new am4charts.Legend();
            chart.legend.position = "bottom";
        }

        myChart.current = chart;

        return () => {
            chart.dispose();
        };
    }, [props.isStacked, props.isLogarithmicScale, props.data]);

    return <div id={chartId} style={{ width: "100%", height: props.height ? props.height : 220 }} />;
}

export default React.memo(SternumTimeSeriesChart, (prevProps, nextProps) => {
    if (!_.isEqual(prevProps.data.labels, nextProps.data.labels)) {
        return false;
    }

    if (!_.isEqual(prevProps.data.datasets, nextProps.data.datasets)) {
        return false;
    }

    if (prevProps.isLogarithmicScale !== nextProps.isLogarithmicScale) {
        return false;
    }

    if (prevProps.isStacked !== nextProps.isStacked) {
        return false;
    }

    return true;
});
