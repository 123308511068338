import CircularProgress from "@material-ui/core/CircularProgress";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SternumConfiguration from "../../lib/infra/SternumConfiguration";
import ServiceWire from "../../lib/services/ServiceWire";
import { GlobalState } from "../../lib/state/GlobalState";
import IssueInfo from "../../lib/state/IssueInfo";
import ServerEntityType from "../../lib/state/ServerEntityType";
import ConfirmationDialog from "../../shared_components/ConfirmationDialog/ConfirmationDialog";
import { CheckmarkIcon } from "../SUI/SternumIcon/SternumIcon";
import issuesListActionsStyle from "./IssuesListActionsStyle";
import { putIssueStatusAction } from "../../lib/redux/issues/PutIssueStatusAction";
import { IssueInfoStateStatus, IssueInfoStatus } from "../../lib/state/IssueInfoType";
import { getMappedValue } from "../../utils";
import { IssuesIsResolved } from "../IssuesList/IssuesIsResolved";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    openConfirmationDialog: boolean;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof issuesListActionsStyle> {
    issue: IssueInfo;
    canResolve?: boolean;

    loadingResolveIssue?: boolean;

    onResolveFinished?: (issueId: string) => void;
    resolveIssueAction?: (issueId: string) => void;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {
        loadingResolveIssue:
            state.issues.issueStateByIssueId.get(ownProps.issue?.issueId)?.issueStatus ===
            IssueInfoStateStatus.Resolving,
    };
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {
        resolveIssueAction: (issueId: string) =>
            dispatch(putIssueStatusAction({ issueId, status: IssueInfoStatus.Resolved })),
    };
};

/**
 * Holds the issues list in the app.
 */
class IssuesListActions extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);
        // Initializing the state to default.
        this.state = {
            openConfirmationDialog: false,
        };
    }

    /**
     * Occurs once the component is about to receive props.
     */
    UNSAFE_componentWillReceiveProps(nextProps: Readonly<AppProps>, nextContext: any): void {
        if (!nextProps.loadingResolveIssue && this.props.loadingResolveIssue) {
            this.props.onResolveFinished(this.props.issue.issueId);
        }
    }

    /**
     * Close confirmation dialog
     */
    private closeConfirmationDialog = () => {
        this.setState({ openConfirmationDialog: false });
    };

    /**
     * Close confirmation dialog
     */
    private openConfirmationDialog = () => {
        this.setState({ openConfirmationDialog: true });
    };

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.flexVMiddle}>
                {this.props.canResolve && ServiceWire.getAuthorizationService().canEdit(ServerEntityType.ISSUE) && (
                    <IssuesIsResolved
                        issue={this.props.issue}
                        loadingResolveIssue={this.props.loadingResolveIssue}
                        onClickResolve={() => this.openConfirmationDialog()}
                    />
                )}

                <ConfirmationDialog
                    open={this.state.openConfirmationDialog}
                    title={`Resolve ${this.props.issue.alertName}?`}
                    body={`Are you sure you want to resolve ${this.props.issue.alertName} on ${
                        this.props.issue.device.deviceDefinition
                            ? this.props.issue.device.deviceDefinition.displayName
                            : "Unknown"
                    }?`}
                    handleCancel={this.closeConfirmationDialog}
                    handleApprove={this.handleResolveClicked}
                    overrideActionName={"Resolve"}
                />
            </div>
        );
    }

    /**
     * Handles clicking on resolve action.
     */
    private handleResolveClicked = () => {
        this.closeConfirmationDialog();
        this.props.resolveIssueAction(this.props.issue.entityId);
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(issuesListActionsStyle)(IssuesListActions)));
