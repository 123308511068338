import { Action } from "redux";
import SternumGeneratedEventInfo from "../../state/SternumGeneratedEventInfo";
import ActionType from "../ActionType";

/**
 * Occurs once a trace has been successfully fetched from API.
 */
export interface FetchedGeneratedEventSuccessfullyAction extends Action<ActionType> {
    type: ActionType.FetchedGeneratedEventSuccessfully;
    generatedEvent: SternumGeneratedEventInfo;
}

/**
 * Occurs once a trace has been successfully fetched from API.
 */
export function fetchedGeneratedEventSuccessfullyAction(
    generatedEvent: SternumGeneratedEventInfo
): FetchedGeneratedEventSuccessfullyAction {
    return {
        type: ActionType.FetchedGeneratedEventSuccessfully,
        generatedEvent: generatedEvent,
    };
}
