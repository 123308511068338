export type ReceivedTraceId = string;

export enum ReceivedTraceStatus {
    Received = "Received",
    NotReceived = "NotReceived",
    Unexpected = "Unexpected",
}

export interface ReceivedTrace {
    orderIndex?: number;
    receivedTraceId: ReceivedTraceId;
    receivedTime?: Date;
    eventType: string;
    traceStatus: ReceivedTraceStatus;
}

export interface ReceivedTraceData {
    totalCount: number;
    receivedTraces: ReceivedTrace[];
    receivedTracesCount: number;
    notReceivedCount: number;
    receivedUnexpectedCount: number;
}
