import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { sternumTheme } from "../../App";
import commonStyle from "../../CommonStyle";

const createDashboardModalStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: {
            display: "flex",
            flexDirection: "column",
            paddingLeft: "30px",
            paddingTop: "30px",
            paddingBottom: "15px",
            paddingRight: "30px",
            height: "100%",
        },

        fullWidth: {
            width: "100%",
        },

        title: {
            fontSize: "24px",
            marginBottom: "9px",
        },

        subTitle: {
            fontSize: "12px",
            color: "#5B636C",
            marginBottom: "30px",
        },

        sectionTitle: {
            fontSize: "13px",
            fontWeight: 500,
            marginBottom: "2px",

            "&.mod-secondary": {
                marginTop: "30px",
            },
        },

        profileSelection: {
            marginBottom: "20px",
        },

        cancelButtonContainer: {
            marginRight: "8px",
        },

        footer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginTop: "20px",
        },

        errorText: {
            color: sternumTheme.danger.main,
            marginRight: "8px",
        },

        loadingContainer: {
            marginRight: "8px",
        },
    });

export default createDashboardModalStyle;
