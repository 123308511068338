import ConfigurationService from "../../../lib/services/ConfigurationService";
import SternumDeviceEventsFilter from "../../../lib/state/SternumDeviceEventsFilter";
import SternumQuery from "../../../lib/state/SternumQuery";
import AggregationFunctionType from "../../../lib/state/Visualisation/AggregationFunctionType";
import VisualisationDataSource from "../../../lib/state/Visualisation/VisualisationDataSource";
import { getColorOrder } from "../../ColorPicker/getColorOrder";
import SternumColorType from "../../ColorPicker/SternumColorType";
import UIDataVisualisationConfiguration from "../entities/UIDataVisualisationConfiguration";
import { getTimeSeriesGraphLabel } from "./getTimeSeriesGraphLabel";

export default function getDefaultDataVisualisationConfiguration(
    dataSourceKey: string,
    latestUsedColor?: SternumColorType,
    sternumQuery?: SternumQuery,
    startTime?: Date,
    endTime?: Date,
    visualisationDataSource?: VisualisationDataSource,
    deviceDefinitionVersionIds?: string[],
    deviceDefinitionVersionNames?: string[]
): UIDataVisualisationConfiguration {
    const allPossibleColors: SternumColorType[] = getColorOrder();
    let colorToUse: SternumColorType = allPossibleColors[0];

    if (latestUsedColor) {
        allPossibleColors.forEach((value, index) => {
            if (value === latestUsedColor) {
                if (index < allPossibleColors.length - 1) {
                    colorToUse = allPossibleColors[index + 1];
                } else {
                    colorToUse = allPossibleColors[0];
                }
            }
        });
    }

    const aggregationFunctionType = visualisationDataSource
        ? visualisationDataSource.aggregationFunction
        : AggregationFunctionType.COUNT;

    const aggregatedTraceDefinitionId = visualisationDataSource
        ? visualisationDataSource.traceDefinitionId
        : ConfigurationService.getAllEventsFilterField().id;
    const aggregatedTraceDefinitionDisplayName = visualisationDataSource
        ? visualisationDataSource.traceDefinitionDisplayName
        : ConfigurationService.getAllEventsFilterField().displayName;

    const aggregatedArgumentDefinitionId = visualisationDataSource
        ? visualisationDataSource.argumentDefinitionId
        : undefined;
    const aggregatedArgumentDefinitionDisplayName = visualisationDataSource
        ? visualisationDataSource.argumentDefinitionDisplayName
        : undefined;

    return {
        dataSourceKey: visualisationDataSource ? visualisationDataSource.dataSourceKey : dataSourceKey,
        graphLabel:
            visualisationDataSource?.graphLabel ||
            getTimeSeriesGraphLabel(
                aggregationFunctionType,
                aggregatedTraceDefinitionDisplayName,
                aggregatedArgumentDefinitionDisplayName,
                deviceDefinitionVersionNames
            ),
        dataSourceLabel:
            visualisationDataSource?.dataSourceLabel ||
            getTimeSeriesGraphLabel(
                aggregationFunctionType,
                aggregatedTraceDefinitionDisplayName,
                aggregatedArgumentDefinitionDisplayName
            ),

        aggregationFunctionType: aggregationFunctionType,

        aggregatedTraceDefinitionId: aggregatedTraceDefinitionId,
        aggregatedTraceDefinitionDisplayName: aggregatedTraceDefinitionDisplayName,

        aggregatedArgumentDefinitionId: aggregatedArgumentDefinitionId,
        aggregatedArgumentDefinitionDisplayName: aggregatedArgumentDefinitionDisplayName,

        percentile: visualisationDataSource ? visualisationDataSource.percentile : 95,
        color: visualisationDataSource ? visualisationDataSource.color : colorToUse,

        deviceDefinitionVersionIds:
            visualisationDataSource?.deviceDefinitionVersionIds || deviceDefinitionVersionIds || null,
        deviceDefinitionVersionNames: deviceDefinitionVersionNames || [],

        sternumQuery: sternumQuery,
        sternumQueryChanged: false,
        appliedSternumQuery: sternumQuery,
        appliedSternumQueryChangeIdentifier: 0,

        entitiesFilter: new SternumDeviceEventsFilter(
            null,
            null,
            startTime ? startTime.getTime() : null,
            endTime ? endTime.getTime() : null,
            null,
            null,
            null,
            null,
            sternumQuery,
            aggregatedTraceDefinitionId ? [aggregatedTraceDefinitionId] : []
        ),
        uniqueColumns: visualisationDataSource?.uniqueColumns,
    };
}
