import { Typography } from "@material-ui/core";
import classNames from "classnames";
import * as React from "react";
import { useEffect } from "react";
import { CrossWithCircleIcon, ErrorInfoIcon, InfoCircleIcon } from "../SternumIcon/SternumIcon";
import useSternumBannerStyles from "./SternumBannerStyles";

interface AppProps {
    messageType: "error" | "system";
    message: string;
    onCloseClick: (event) => void;
}

const SternumBanner = ({ messageType, message, onCloseClick }: AppProps) => {
    const classes = useSternumBannerStyles();

    useEffect(() => {}, []);

    return (
        <div
            className={classNames(
                classes.container,
                messageType == "error" ? classes.backgroundErrorState : classes.backgroundSystemState
            )}
        >
            <div
                className={classNames(
                    classes.flexSpaceBetween,
                    classes.flexVMiddle,
                    classes.fullHeight,
                    classes.bannerPadding
                )}
            >
                <div
                    className={classNames(
                        classes.rectangle,
                        messageType == "error" ? classes.errorRecColor : classes.systemRecColor
                    )}
                />
                <div className={classNames(classes.flexRow, classes.flexVMiddle, classes.fullHeight)}>
                    {/* Information Icon */}
                    <div className={classNames(classes.paddingLeft, classes.paddingRight)}>
                        {messageType == "error" ? (
                            <ErrorInfoIcon color="#E7004C" />
                        ) : (
                            <InfoCircleIcon color="#1B6FDE" width={25} height={35} />
                        )}
                    </div>

                    {/* Content */}
                    <Typography variant="body2" className={classes.commonBold}>
                        {message}
                    </Typography>
                </div>
                {/* X Icon */}
                <div
                    className={classNames(classes.paddingLeftXl, classes.paddingRightXl, classes.cursorPointer)}
                    onClick={onCloseClick}
                >
                    <CrossWithCircleIcon
                        color={messageType == "error" ? "#E7004C" : "#1B6FDE"}
                        width={30}
                        height={30}
                    />
                </div>
            </div>
        </div>
    );
};

export default SternumBanner;
