import { Action } from "redux";
import ActionType from "../ActionType";
import SternumService from "../../services/SternumService";

/**
 * Occurs once fetching a cve has started.
 */
export interface FetchCveAction extends Action<ActionType> {
    type: ActionType.FetchCve;
    cveId: string;
}

/**
 * Occurs once fetching a cve has started.
 */
export function fetchCveAction(cveId: string): FetchCveAction {
    return {
        type: ActionType.FetchCve,
        cveId: cveId,
    };
}
