import { makeStyles, Theme } from "@material-ui/core/styles";
import commonStyle from "../CommonStyle";

const useEventsQueryStyle = makeStyles((theme: Theme) => {
    return {
        ...commonStyle(theme),

        queryContainer: {
            marginBottom: theme.spacing(4),
        },
    };
});

export default useEventsQueryStyle;
