import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@material-ui/core";

import { CrossIcon, DiamondIcon } from "../SUI/SternumIcon";
import useGoProDialogStyle from "./GoProDialogStyle";
import SternumImprovedButton from "../SUI/SternumImprovedButton/SternumImprovedButton";
import { useCommonStyle } from "../CommonStyle";
import { Chip } from "../SettingsPage/Chip";

export interface GoProDialogProps {
    open: boolean;
    onCancelClick: () => void;
}

export function GoProDialog(props: GoProDialogProps) {
    const classes = useGoProDialogStyle();
    const classesCommon = useCommonStyle();

    if (!open) {
        return null;
    }

    return (
        <Dialog
            aria-labelledby="dialog-title"
            open={props.open}
            onClose={props.onCancelClick}
            disableEnforceFocus
            classes={{
                root: classes.root,
                paper: classes.paper,
            }}
        >
            {/* Title */}
            <DialogTitle id="dialog-title" className={classes.titleContainer}>
                <div className={classNames(classes.title)}>
                    <Typography variant={"h6"} className={classNames(classesCommon.extraBold)}>
                        Upgrade Sternum Platform
                    </Typography>
                    <IconButton onClick={props.onCancelClick}>
                        <CrossIcon color="#909090" />
                    </IconButton>
                </div>
            </DialogTitle>

            {/* Body */}
            <DialogContent>
                <Box display="inline-flex">
                    <Chip>
                        <DiamondIcon /> Premium
                    </Chip>
                </Box>

                <Box mt={2}>
                    <p className={classes.paragraph}>
                        Want to upgrade your free license to a fully-featured premium license?
                    </p>
                    <p className={classes.paragraph}>
                        Fill out your details or email <a href="mailto:sales@sternumiot.com">sales@sternumiot.com</a>{" "}
                        and we will get in touch with you.
                    </p>
                </Box>
            </DialogContent>

            <DialogActions className={classNames(classes.actions)}>
                <Link to="/premium" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                    <SternumImprovedButton
                        onClick={props.onCancelClick}
                        content={"Upgrade"}
                        buttonType={"regular"}
                        fullWidth={false}
                    />
                </Link>
            </DialogActions>
        </Dialog>
    );
}
