import React from "react";
import classNames from "classnames";
import { CircularProgress } from "@material-ui/core";

import IssueInfo from "../../../lib/state/IssueInfo";
import { CheckmarkIcon } from "../../SUI/SternumIcon";
import { useIssuesIsResolvedStyle } from "./IssuesIsResolved.style";
import { IssueInfoStatus } from "../../../lib/state/IssueInfoType";
import { SternumTooltip } from "../../SUI/SternumTooltip";

export interface IssuesIsResolvedProps {
    issue?: IssueInfo;
    loadingResolveIssue?: boolean;
    onClickResolve?: () => unknown;
}

export function IssuesIsResolved({ issue, loadingResolveIssue, onClickResolve }: IssuesIsResolvedProps) {
    const classes = useIssuesIsResolvedStyle();
    const isResolved = issue?.status === IssueInfoStatus.Resolved;
    const isDismissed = issue?.status === IssueInfoStatus.Dismissed;
    const isArchived = issue?.status === IssueInfoStatus.Archived;

    if (isResolved) {
        return (
            <div className={classes.issuesIsResolvedContainer}>
                <span>
                    <SternumTooltip title={`by ${issue.updaterUser ? issue.getResolvedBy() : "Unknown"}`}>
                        <span className={classes.issuesIsResolved}>Resolved</span>
                    </SternumTooltip>
                </span>
            </div>
        );
    }

    if (isDismissed) {
        return (
            <div className={classes.issuesIsResolvedContainer}>
                <span>
                    <SternumTooltip title={`by ${issue.updaterUser ? issue.getResolvedBy() : "Unknown"}`}>
                        <span className={classes.issuesIsDismissed}>Dismissed</span>
                    </SternumTooltip>
                </span>
            </div>
        );
    }

    if (isArchived) {
        return (
            <div className={classes.issuesIsResolvedContainer}>
                <span>
                    <SternumTooltip title={`by ${issue.updaterUser ? issue.getResolvedBy() : "Unknown"}`}>
                        <span className={classes.issuesIsDismissed}>Archived</span>
                    </SternumTooltip>
                </span>
            </div>
        );
    }

    return (
        <>
            <div className={classNames(classes.issuesIsResolvedContainer)} onClick={() => onClickResolve()}>
                Resolve
                {!loadingResolveIssue && <CheckmarkIcon />}
                {loadingResolveIssue && <CircularProgress size={15} />}
            </div>
        </>
    );
}
