import { Chip, CircularProgress, IconButton, Input, Typography } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
    openGoProModal,
    openSimulateAttackModal,
    openVisualizationCreationModalAction,
} from "../../../lib/redux/modals/OpenModalAction";
import ReceivedDefinitionsResponse from "../../../lib/services/events/ReceivedDefinitionsResponse";
import ServiceWire from "../../../lib/services/ServiceWire";
import CustomDashboardInfo, { DashboardType } from "../../../lib/state/CustomDashboardInfo";
import DeviceDefinitionVersionInfo from "../../../lib/state/DeviceDefinitionVersionInfo";
import { GlobalState } from "../../../lib/state/GlobalState";
import ServerEntityType from "../../../lib/state/ServerEntityType";
import SternumQuery from "../../../lib/state/SternumQuery";
import TimeSelectOption from "../../../lib/state/TimeSelectOption";
import VisualisationType from "../../../lib/state/Visualisation/VisualisationType";
import getDefaultTimeSelection from "../../NewDeviceView/getDefaultTimeSelection";
import { PremiumBanner } from "../../PremiumBanner";
import { CategoryIcon, ChartBarsIcon, PencilIcon } from "../../SUI/SternumIcon/SternumIcon";
import TimeSelectionComponent from "../../TimeSelectionComponent/TimeSelectionComponent";
import DashboardsBrowser from "../DashboardsBrowser/DashboardsBrowser";
import DashboardVisualisationCard from "../DashboardVisualisationCard/DashboardVisualisationCard";
import { LostCommunicationSvg, AnomalyDetectionSvg } from "../PremiumVisualisationMockCard";
import { PremiumVisualisationMockCard } from "../PremiumVisualisationMockCard/PremiumVisualisationMockCard";
import dashboardViewerStyle from "./DashboardViewerStyle";
import { LoadingDevices } from "../../LoadingDevices/LoadingDevices";
import { DeviceMetrics } from "../../../lib/services/DevicesMetricsPollingService";
import { SimulateAttackInstructionBanner } from "../SimulateAttackInstructionBanner";
import SpinnerLoader from "../../SUI/SternumLoaders/SpinnerLoader";
import { setDetectedDeviceCountAction } from "../../../lib/redux/devices/SetDetectedDeviceCount";
import { PredefinedLinuxVisualisationCard } from "../PredefinedLinuxVisualisationCard";
import VisualisationInfo from "../../../lib/state/Visualisation/VisualisationInfo";
import ConfigurationService from "../../../lib/services/ConfigurationService";
import SpecialField from "../../../lib/state/SpecialField";
import { VisualisationDataSourceGroupByCustomFields } from "../../../lib/state/Visualisation/VisualisationConfigurationGroupBy";

const DEFAULT_HIDDEN_GLANCES = ["Reboots Over Time"];

const mapStateToProps = (state: GlobalState, ownProps: any) => {
    const selectedClient = ServiceWire.getClientsService().getSelectedClient();

    return {
        isFreeUser: selectedClient.isTrialTier(),
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        openGoProModal: (key: string) => dispatch(openGoProModal(key)),
        openSimulateAttackModal: (key: string) => dispatch(openSimulateAttackModal(key)),
        onDeviceCountKnown: (c: number) => dispatch(setDetectedDeviceCountAction(c)),
        openVisualizationCreationModal: (
            key: string,
            visualisationId: string,
            deviceDefinitionVersions: DeviceDefinitionVersionInfo[],
            receivedDefinitionsResponse: Record<string, ReceivedDefinitionsResponse>,
            startTime: Date,
            endTime: Date,
            sternumQuery: SternumQuery,
            displayViewForDeviceDefinition: boolean,
            displayXButton: boolean,
            displayBackButton: boolean,
            lookedUpEntityId: string,
            timeSelectedOption: TimeSelectOption,
            visualisationType?: VisualisationType,
            dashboardId?: string,
            onVisualisationCreated?: (visualisationId: string, displayName: string) => void,
            onVisualisationUpdated?: (visualisationId: string, displayName: string) => void,
            onVisualisationDeleted?: (visualisationId: string) => void,
            visualisation?: VisualisationInfo,
            disableEditing?: boolean
        ) =>
            dispatch(
                openVisualizationCreationModalAction(
                    key,
                    visualisationId,
                    deviceDefinitionVersions,
                    receivedDefinitionsResponse,
                    startTime,
                    endTime,
                    sternumQuery,
                    displayViewForDeviceDefinition,
                    displayXButton,
                    displayBackButton,
                    lookedUpEntityId,
                    timeSelectedOption,
                    undefined,
                    visualisationType,
                    dashboardId,
                    onVisualisationCreated,
                    onVisualisationUpdated,
                    onVisualisationDeleted,
                    visualisation,
                    disableEditing
                )
            ),
    };
};

interface AppState {
    loading: boolean;
    deviceMetricsLoading: {
        waitingForDevices: boolean;
        showConfirmation: boolean;
        wasInitiallyLoaded: boolean;
    } | null;
    error: boolean;
    customDashboardInfo: CustomDashboardInfo;
    timeSelectOption: TimeSelectOption;
    selectingNewVisualisation: boolean;

    startTime: Date;
    endTime: Date;

    displayName: string;
    editingDisplayName: boolean;

    savingDisplayName: boolean;
    errorSavingDisplayName: boolean;
    isDisabled: boolean;

    dashboardId: string;
    expandedDashboardsBrowser?: boolean;
    forceReloadDashboards: boolean;

    // used as a part of dashboard card key
    refreshCounter: number;

    viewMore: boolean;

    receivedDefinitionsResponse: ReceivedDefinitionsResponse | null;
}

export interface AppProps
    extends WithStyles<typeof dashboardViewerStyle>,
        ReturnType<typeof mapDispatchToProps>,
        ReturnType<typeof mapStateToProps> {
    dashboardId: string;
    history;
}

class DashboardViewer extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);

        const timeSelectOption: TimeSelectOption = getDefaultTimeSelection();

        // Initializing the state to default.
        this.state = {
            loading: true,
            deviceMetricsLoading: props.isFreeUser
                ? { waitingForDevices: true, showConfirmation: false, wasInitiallyLoaded: false }
                : null,
            error: false,
            customDashboardInfo: undefined,
            selectingNewVisualisation: false,
            timeSelectOption: timeSelectOption,
            startTime: timeSelectOption.timeRange.start,
            endTime: timeSelectOption.timeRange.end,

            displayName: "",
            editingDisplayName: false,

            savingDisplayName: false,
            errorSavingDisplayName: false,
            dashboardId: this.props.dashboardId,

            expandedDashboardsBrowser: false,

            isDisabled: !ServiceWire.getAuthorizationService().canEdit(ServerEntityType.VISUALIZATION),

            forceReloadDashboards: false,

            refreshCounter: 1,

            viewMore: false,

            receivedDefinitionsResponse: null,
        };
    }

    componentDidMount() {
        if (this.props.isFreeUser) {
            ServiceWire.getDeviceMetricsPollingService().subscribe(
                "DashboardViewer",
                this.handleMetrics.bind(this),
                true
            );
        } else {
            this.loadDashboard().then(() => {
                if (!this.state.error && this.state.customDashboardInfo.isPredefinedLinuxDashboard()) {
                    this.loadReceivedDefinitions();
                }
            });
        }
    }

    componentWillUnmount(): void {
        ServiceWire.getDeviceMetricsPollingService().unsubscribe("DashboardViewer");
    }

    private handleMetrics({ totalDevices }: DeviceMetrics) {
        if (totalDevices > 0) {
            this.props.onDeviceCountKnown(totalDevices);
            ServiceWire.getDeviceMetricsPollingService().unsubscribe("DashboardViewer");
            this.setState((s) => ({
                deviceMetricsLoading: {
                    ...s.deviceMetricsLoading,
                    showConfirmation: s.deviceMetricsLoading.wasInitiallyLoaded,
                    waitingForDevices: false,
                },
            }));

            if (!this.state.deviceMetricsLoading.wasInitiallyLoaded) {
                this.loadDashboard();
            }
        }

        this.setState((s) => ({
            deviceMetricsLoading: {
                ...s.deviceMetricsLoading,
                wasInitiallyLoaded: true,
            },
        }));
    }

    async UNSAFE_componentWillReceiveProps(nextProps: Readonly<AppProps>, nextContext: any) {
        if (nextProps.dashboardId !== this.props.dashboardId) {
            this.setState(
                {
                    dashboardId: nextProps.dashboardId,
                },
                async () => {
                    await this.loadDashboard().then(() => {
                        if (!this.state.error && this.state.customDashboardInfo.isPredefinedLinuxDashboard()) {
                            this.loadReceivedDefinitions();
                        }
                    });
                }
            );
        }
    }

    render() {
        const { classes } = this.props;

        const isPredefinedLinuxDashboard = !!this.state.customDashboardInfo?.isPredefinedLinuxDashboard();

        return (
            <div className={classNames(classes.root)}>
                {this.props.isFreeUser && !this.state.deviceMetricsLoading.waitingForDevices && (
                    <SimulateAttackInstructionBanner
                        open={!this.state.viewMore}
                        openAttackSimulationModal={() => this.props.openSimulateAttackModal("dashboard-instructions")}
                    />
                )}

                {!this.props.isFreeUser && (
                    <div
                        className={classNames(
                            classes.dashboardsBrowserContainer,
                            this.state.expandedDashboardsBrowser && "mod-expanded"
                        )}
                    >
                        <div className={classNames(classes.browseTitleContainer)}>
                            <IconButton
                                aria-label="browse views"
                                className={classes.browseIcon}
                                onClick={() => {
                                    this.setState({
                                        expandedDashboardsBrowser: !this.state.expandedDashboardsBrowser,
                                    });
                                }}
                            >
                                <CategoryIcon color="#C4C6CD" />
                            </IconButton>
                            <Typography
                                aria-label="browse views"
                                className={classNames(classes.browseTitle, classes.disableSelect)}
                                onClick={() => {
                                    this.setState({
                                        expandedDashboardsBrowser: !this.state.expandedDashboardsBrowser,
                                    });
                                }}
                            >
                                Browse Views
                            </Typography>
                        </div>

                        <div style={{ visibility: this.state.expandedDashboardsBrowser ? "visible" : "hidden" }}>
                            <DashboardsBrowser
                                doNotDisplayAddDashboard={true}
                                doNotDisplayDashboardMenu={true}
                                doNotRedirectToDashboardPage={false}
                                minified={true}
                                forceReloadDashboards={this.state.forceReloadDashboards}
                                selectedDashboardId={this.state.dashboardId}
                                onDashboardClicked={(selectedDashboardId) => {
                                    if (selectedDashboardId !== this.state.dashboardId) {
                                        this.setState({
                                            dashboardId: selectedDashboardId,
                                        });
                                    }
                                }}
                                onDashboardUpdated={() => this.loadDashboard()}
                                closeBrowser={() => {
                                    this.setState({
                                        expandedDashboardsBrowser: !this.state.expandedDashboardsBrowser,
                                    });
                                }}
                                onDeletedDashboard={(dashboardId) => {
                                    this.onDeleteDashboard(dashboardId);
                                }}
                            />
                        </div>
                    </div>
                )}

                {this.state.viewMore && <PremiumBanner />}

                {this.state.customDashboardInfo && !this.state.loading && (
                    <div className={classNames(classes.titleContainer)}>
                        <div
                            role="presentation"
                            aria-label="glance display name container"
                            className={classNames(classes.displayNameContainer)}
                        >
                            {!this.state.editingDisplayName && (
                                <Typography className={classNames(classes.dashboardTitle)}>
                                    {this.state.displayName}
                                </Typography>
                            )}

                            {isPredefinedLinuxDashboard && (
                                <Chip className={classes.predefinedDashboardChip} color="primary" label="Predefined" />
                            )}

                            {this.state.editingDisplayName && (
                                <Input
                                    role="presentation"
                                    aria-label="change glance name input"
                                    className={classNames(classes.valueInputBox, classes.selectComponent)}
                                    value={this.state.displayName}
                                    onKeyDown={(event) => {
                                        if (event.key === "Enter") {
                                            this.updateCustomDashboardName();
                                        }
                                    }}
                                    onBlurCapture={() => {
                                        this.updateCustomDashboardName();
                                    }}
                                    onChange={(event) => this.onDisplayNameChanged(event.target.value)}
                                />
                            )}
                            {!isPredefinedLinuxDashboard && !this.state.isDisabled && (
                                <PencilIcon
                                    role="button"
                                    aria-label="edit display name of glance"
                                    color="#8B949E"
                                    onClick={() => this.enterEditDisplayName()}
                                    className={classes.pencil}
                                />
                            )}
                            {this.state.savingDisplayName && (
                                <div className={classNames(classes.savingCircleContainer)}>
                                    <CircularProgress size={15} />
                                </div>
                            )}

                            {ServiceWire.getAuthorizationService().canAdd(ServerEntityType.VISUALIZATION) && (
                                <IconButton
                                    aria-label="create glance"
                                    onClick={this.handleAddGlanceClick}
                                    className={classes.addGlanceButton}
                                >
                                    <span className="plus">+</span>
                                    <ChartBarsIcon color="#fff" />
                                </IconButton>
                            )}
                        </div>

                        {this.state.customDashboardInfo && !this.state.loading && (
                            <TimeSelectionComponent
                                onTimeRangeSelected={(selectedTimeOption: TimeSelectOption) =>
                                    this.onTimeRangeSelected(selectedTimeOption)
                                }
                                timeSelectedOption={this.state.timeSelectOption}
                            />
                        )}
                    </div>
                )}

                {this.props.isFreeUser && !this.state.deviceMetricsLoading.wasInitiallyLoaded && (
                    <div className={classes.spinnerContainer}>
                        <SpinnerLoader />
                    </div>
                )}

                {this.props.isFreeUser && this.state.deviceMetricsLoading.waitingForDevices && (
                    <LoadingDevices confirmMode={false} />
                )}
                {this.props.isFreeUser &&
                    !this.state.deviceMetricsLoading.waitingForDevices &&
                    this.state.deviceMetricsLoading.showConfirmation && (
                        <LoadingDevices
                            confirmMode={true}
                            confirmButtonText="View Device Glances"
                            onConfirm={() => {
                                this.setState((s) => ({
                                    deviceMetricsLoading: { ...s.deviceMetricsLoading, showConfirmation: false },
                                }));
                                this.loadDashboard();
                            }}
                        />
                    )}

                {this.state.error && (
                    <Typography className={classNames(classes.errorText)}>Error loading dashboard...</Typography>
                )}

                {this.renderDashboardGlances()}
            </div>
        );
    }

    private renderDashboardGlances() {
        const { classes } = this.props;

        if (this.state.loading || !this.state.customDashboardInfo) return null;

        let predefinedVisualisations: VisualisationInfo[] = [];
        const isPredefinedLinuxDashboard = !!this.state.customDashboardInfo.isPredefinedLinuxDashboard();

        if (isPredefinedLinuxDashboard && this.state.receivedDefinitionsResponse) {
            if (this.state.customDashboardInfo.type === DashboardType.LinuxPerformance) {
                predefinedVisualisations = this.generateLinuxPerformanceDashboardVisualisations();
            }

            if (this.state.customDashboardInfo.type === DashboardType.LinuxSecurity) {
                predefinedVisualisations = this.generateLinuxSecurityDashboardVisualisations();
            }
        }

        return (
            <div className={classNames(classes.visualisationsContainer)}>
                {this.state.customDashboardInfo.visualisationSummaries
                    .filter((glance) => {
                        if (!this.state.viewMore) {
                            return !DEFAULT_HIDDEN_GLANCES.includes(glance.displayName);
                        }

                        return true;
                    })
                    .map((visualisation, index) => {
                        return (
                            <DashboardVisualisationCard
                                key={`${visualisation.visualisationId}+${this.state.refreshCounter}`}
                                visualisation={visualisation}
                                index={index}
                                dashboardDeviceDefinitionVersions={
                                    this.state.customDashboardInfo.deviceDefinitionVersions
                                }
                                startTime={this.state.startTime}
                                endTime={this.state.endTime}
                                isDisabled={this.state.isDisabled}
                                openVisualisationModal={this.openVisualisationModal}
                                onVisualisationDelete={this.handleVisualisationDeleted}
                            />
                        );
                    })}

                {predefinedVisualisations.map((visualisation) => (
                    <PredefinedLinuxVisualisationCard
                        key={visualisation.entityId}
                        startTime={this.state.startTime}
                        endTime={this.state.endTime}
                        dashboardDeviceDefinitionVersions={this.state.customDashboardInfo.deviceDefinitionVersions}
                        openVisualisationModal={this.openVisualisationModal}
                        visualisation={visualisation}
                    />
                ))}

                {this.state.viewMore && (
                    <>
                        <PremiumVisualisationMockCard
                            title="Missing Authentication - Abnormal Event Pattern"
                            visualisationMock={<AnomalyDetectionSvg />}
                        />
                        <PremiumVisualisationMockCard
                            title="Lost Communication"
                            visualisationMock={<LostCommunicationSvg />}
                        />
                    </>
                )}

                {this.props.isFreeUser && !this.state.viewMore && (
                    <div
                        role="button"
                        tabIndex={0}
                        className={classes.viewMoreContainer}
                        onClick={this.handleViewMoreClick}
                    >
                        <Typography className={classes.viewMoreLabel}>View more glances</Typography>
                    </div>
                )}
            </div>
        );
    }

    private generateLinuxPerformanceDashboardVisualisations(): VisualisationInfo[] {
        const allAlertsTrace = ConfigurationService.getAllAlertsFilterField();
        const bootTrace = this.getReceivedDefinitionTraceByName("Boot");
        const processListInformationTrace = this.getReceivedDefinitionTraceByName("Process List Information");
        const storageReportTrace = this.getReceivedDefinitionTraceByName("Storage Report");
        const crashTrace = this.getReceivedDefinitionTraceByName("Crash");

        const categoryGroupByArgument = VisualisationDataSourceGroupByCustomFields.TRACE_CATEGORY;
        const deviceGroupByArgument = VisualisationDataSourceGroupByCustomFields.DEVICE;
        const countryGroupByArgument = VisualisationDataSourceGroupByCustomFields.COUNTRY;
        const mountedOnArgument = this.getReceivedDefinitionArgumentByName("Mounted On");
        const memoryUsageArgument = this.getReceivedDefinitionArgumentByName("Memory Usage");
        const cpuUsageArgument = this.getReceivedDefinitionArgumentByName("CPU Usage");
        const usedPercentageArgument = this.getReceivedDefinitionArgumentByName("Used Percentage");

        return [
            // VisualisationInfo.fromJsonObject({
            //     entity_id: "VISU1",
            //     entity_type: "VISUALIZATION",
            //     created: Date.now(),
            //     updated: Date.now(),
            //     display_name: "Top Crashes per Geography",
            //     configuration: {
            //         visualisation_type: VisualisationType.VALUES_TABLE,
            //         data_sources: [
            //             {
            //                 data_source_key: "DEFAULT",
            //                 device_definition_version_id: [
            //                     this.state.customDashboardInfo.deviceDefinitionVersions[0].entityId,
            //                 ],
            //                 sternum_query: {
            //                     query_type: "AND",
            //                     filters: [],
            //                     queries: [],
            //                 },
            //                 aggregation_function: "COUNT",
            //                 aggregation_context: "TRACES",
            //                 aggregation_fields: null,
            //                 trace_definition_id: crashTrace?.identifier,
            //                 trace_definition_display_name: crashTrace?.displayName,
            //                 argument_definition_id: "",
            //                 argument_definition_display_name: "",
            //                 percentile: 95,
            //                 color: "COLOR_A",
            //             },
            //         ],
            //         group_by: {
            //             field: countryGroupByArgument,
            //             limit: 10,
            //             order_by: "TOP",
            //             aggregation_function: "COUNT",
            //             aggregation_context: "TRACES",
            //             device_definition_version_ids: [],
            //             argument_definition_id: null,
            //             trace_definition_ids: [],
            //             enabled: true,
            //         },
            //     },
            //     stacked: false,
            //     logarithmic_scale: false,
            // }),
            // VisualisationInfo.fromJsonObject({
            //     entity_id: "VISU2",
            //     entity_type: "VISUALIZATION",
            //     created: Date.now(),
            //     updated: Date.now(),
            //     display_name: "Top 10 Crashing Devices",
            //     configuration: {
            //         visualisation_type: VisualisationType.VALUES_TABLE,
            //         data_sources: [
            //             {
            //                 data_source_key: "DEFAULT",
            //                 device_definition_version_id: [
            //                     this.state.customDashboardInfo.deviceDefinitionVersions[0].entityId,
            //                 ],
            //                 sternum_query: {
            //                     query_type: "AND",
            //                     filters: [],
            //                     queries: [],
            //                 },
            //                 aggregation_function: "COUNT",
            //                 aggregation_context: "TRACES",
            //                 aggregation_fields: null,
            //                 trace_definition_id: crashTrace?.identifier,
            //                 trace_definition_display_name: crashTrace?.displayName,
            //                 argument_definition_id: "",
            //                 argument_definition_display_name: "",
            //                 percentile: 95,
            //                 color: "COLOR_A",
            //             },
            //         ],
            //         group_by: {
            //             field: deviceGroupByArgument,
            //             limit: 10,
            //             order_by: "TOP",
            //             aggregation_function: "COUNT",
            //             aggregation_context: "TRACES",
            //             device_definition_version_ids: [],
            //             argument_definition_id: null,
            //             trace_definition_ids: [],
            //             enabled: true,
            //         },
            //     },
            //     stacked: false,
            //     logarithmic_scale: false,
            // }),
            // VisualisationInfo.fromJsonObject({
            //     entity_id: "VISU3",
            //     entity_type: "VISUALIZATION",
            //     created: Date.now(),
            //     updated: Date.now(),
            //     display_name: "Crashes Over Time",
            //     configuration: {
            //         visualisation_type: VisualisationType.TIME_SERIES,
            //         data_sources: [
            //             {
            //                 data_source_key: "DEFAULT",
            //                 device_definition_version_id: [
            //                     this.state.customDashboardInfo.deviceDefinitionVersions[0].entityId,
            //                 ],
            //                 sternum_query: {
            //                     query_type: "AND",
            //                     filters: [],
            //                     queries: [],
            //                 },
            //                 aggregation_function: "COUNT",
            //                 aggregation_context: "TRACES",
            //                 aggregation_fields: null,
            //                 trace_definition_id: crashTrace?.identifier,
            //                 trace_definition_display_name: crashTrace?.displayName,
            //                 argument_definition_id: "",
            //                 argument_definition_display_name: "",
            //                 percentile: 95,
            //                 color: "COLOR_A",
            //             },
            //         ],
            //         group_by: null,
            //     },
            //     stacked: false,
            //     logarithmic_scale: false,
            // }),
            VisualisationInfo.fromJsonObject({
                entity_id: "VISU4",
                entity_type: "VISUALIZATION",
                created: Date.now(),
                updated: Date.now(),
                display_name: "Alerts by Category",
                configuration: {
                    visualisation_type: VisualisationType.VALUES_GRAPH,
                    data_sources: [
                        {
                            data_source_key: "DEFAULT",
                            device_definition_version_id: [
                                this.state.customDashboardInfo.deviceDefinitionVersions[0].entityId,
                            ],
                            sternum_query: {
                                query_type: "AND",
                                filters: [],
                                queries: [],
                            },
                            aggregation_function: "COUNT",
                            aggregation_context: "TRACES",
                            aggregation_fields: null,
                            trace_definition_id: allAlertsTrace.id,
                            trace_definition_display_name: allAlertsTrace.displayName,
                            argument_definition_id: "",
                            argument_definition_display_name: "",
                            percentile: 95,
                            color: "COLOR_A",
                        },
                    ],
                    group_by: {
                        field: categoryGroupByArgument,
                        limit: 10,
                        order_by: "TOP",
                        aggregation_function: "COUNT",
                        aggregation_context: "TRACES",
                        device_definition_version_ids: [],
                        argument_definition_id: null,
                        trace_definition_ids: [],
                        enabled: true,
                    },
                },
                stacked: false,
                logarithmic_scale: false,
            }),
            VisualisationInfo.fromJsonObject({
                entity_id: "VISU5",
                entity_type: "VISUALIZATION",
                created: Date.now(),
                updated: Date.now(),
                display_name: "Top 10 devices with Alerts",
                configuration: {
                    visualisation_type: VisualisationType.VALUES_TABLE,
                    data_sources: [
                        {
                            data_source_key: "DEFAULT",
                            device_definition_version_id: [
                                this.state.customDashboardInfo.deviceDefinitionVersions[0].entityId,
                            ],
                            sternum_query: {
                                query_type: "AND",
                                filters: [],
                                queries: [],
                            },
                            aggregation_function: "COUNT",
                            aggregation_context: "TRACES",
                            aggregation_fields: null,
                            trace_definition_id: allAlertsTrace.id,
                            trace_definition_display_name: allAlertsTrace.displayName,
                            argument_definition_id: "",
                            argument_definition_display_name: "",
                            percentile: 95,
                            color: "COLOR_A",
                        },
                    ],
                    group_by: {
                        field: deviceGroupByArgument,
                        limit: 10,
                        order_by: "TOP",
                        aggregation_function: "COUNT",
                        aggregation_context: "TRACES",
                        device_definition_version_ids: [],
                        argument_definition_id: null,
                        trace_definition_ids: [],
                        enabled: true,
                    },
                },
                stacked: false,
                logarithmic_scale: false,
            }),
            VisualisationInfo.fromJsonObject({
                entity_id: "VISU6",
                entity_type: "VISUALIZATION",
                created: Date.now(),
                updated: Date.now(),
                display_name: "Top Alerts per Geography",
                configuration: {
                    visualisation_type: VisualisationType.GEOMAP,
                    data_sources: [
                        {
                            data_source_key: "DEFAULT",
                            device_definition_version_id: [
                                this.state.customDashboardInfo.deviceDefinitionVersions[0].entityId,
                            ],
                            sternum_query: {
                                query_type: "AND",
                                filters: [],
                                queries: [],
                            },
                            aggregation_function: "COUNT",
                            aggregation_context: "TRACES",
                            aggregation_fields: null,
                            trace_definition_id: allAlertsTrace.id,
                            trace_definition_display_name: allAlertsTrace.displayName,
                            argument_definition_id: "",
                            argument_definition_display_name: "",
                            percentile: 95,
                            color: "COLOR_A",
                        },
                    ],
                    group_by: null,
                },
                stacked: false,
                logarithmic_scale: false,
            }),
            VisualisationInfo.fromJsonObject({
                entity_id: "VISU7",
                entity_type: "VISUALIZATION",
                created: Date.now(),
                updated: Date.now(),
                display_name: "Excessive Reboots Over Time",
                configuration: {
                    visualisation_type: VisualisationType.TIME_SERIES,
                    data_sources: [
                        {
                            data_source_key: "DEFAULT",
                            device_definition_version_id: [
                                this.state.customDashboardInfo.deviceDefinitionVersions[0].entityId,
                            ],
                            sternum_query: {
                                query_type: "AND",
                                filters: [],
                                queries: [],
                            },
                            aggregation_function: "COUNT",
                            aggregation_context: "TRACES",
                            aggregation_fields: null,
                            trace_definition_id: bootTrace?.identifier,
                            trace_definition_display_name: bootTrace?.displayName,
                            argument_definition_id: "",
                            argument_definition_display_name: "",
                            percentile: 95,
                            color: "COLOR_A",
                        },
                    ],
                    group_by: null,
                },
                stacked: false,
                logarithmic_scale: false,
            }),
            VisualisationInfo.fromJsonObject({
                entity_id: "VISU8",
                entity_type: "VISUALIZATION",
                created: Date.now(),
                updated: Date.now(),
                display_name: "Top 10 Devices with Excessive Reboots",
                configuration: {
                    visualisation_type: VisualisationType.VALUES_TABLE,
                    data_sources: [
                        {
                            data_source_key: "DEFAULT",
                            device_definition_version_id: [
                                this.state.customDashboardInfo.deviceDefinitionVersions[0].entityId,
                            ],
                            sternum_query: {
                                query_type: "AND",
                                filters: [],
                                queries: [],
                            },
                            aggregation_function: "COUNT",
                            aggregation_context: "TRACES",
                            aggregation_fields: null,
                            trace_definition_id: bootTrace?.identifier,
                            trace_definition_display_name: bootTrace?.displayName,
                            argument_definition_id: "",
                            argument_definition_display_name: "",
                            percentile: 95,
                            color: "COLOR_A",
                        },
                    ],
                    group_by: {
                        field: deviceGroupByArgument,
                        limit: 10,
                        order_by: "TOP",
                        aggregation_function: "COUNT",
                        aggregation_context: "TRACES",
                        device_definition_version_ids: [],
                        argument_definition_id: null,
                        trace_definition_ids: [],
                        enabled: true,
                    },
                },
                stacked: false,
                logarithmic_scale: false,
            }),
            VisualisationInfo.fromJsonObject({
                entity_id: "VISU9",
                entity_type: "VISUALIZATION",
                created: Date.now(),
                updated: Date.now(),
                display_name: "Top Reboots per Geography",
                configuration: {
                    visualisation_type: VisualisationType.GEOMAP,
                    data_sources: [
                        {
                            data_source_key: "DEFAULT",
                            device_definition_version_id: [
                                this.state.customDashboardInfo.deviceDefinitionVersions[0].entityId,
                            ],
                            sternum_query: {
                                query_type: "AND",
                                filters: [],
                                queries: [],
                            },
                            aggregation_function: "COUNT",
                            aggregation_context: "TRACES",
                            aggregation_fields: null,
                            trace_definition_id: bootTrace?.identifier,
                            trace_definition_display_name: bootTrace?.displayName,
                            argument_definition_id: "",
                            argument_definition_display_name: "",
                            percentile: 95,
                            color: "COLOR_A",
                        },
                    ],
                    group_by: null,
                },
                stacked: false,
                logarithmic_scale: false,
            }),
            VisualisationInfo.fromJsonObject({
                entity_id: "VISU10",
                entity_type: "VISUALIZATION",
                created: Date.now(),
                updated: Date.now(),
                display_name: "Top 10 Devices CPU Consumption",
                configuration: {
                    visualisation_type: VisualisationType.VALUES_TABLE,
                    data_sources: [
                        {
                            data_source_key: "DEFAULT",
                            device_definition_version_id: [
                                this.state.customDashboardInfo.deviceDefinitionVersions[0].entityId,
                            ],
                            sternum_query: {
                                query_type: "AND",
                                filters: [],
                                queries: [],
                            },
                            aggregation_function: "MAX",
                            aggregation_context: "TRACES",
                            aggregation_fields: null,
                            trace_definition_id: processListInformationTrace?.identifier,
                            trace_definition_display_name: processListInformationTrace?.displayName,
                            argument_definition_id: cpuUsageArgument?.identifier,
                            argument_definition_display_name: cpuUsageArgument?.displayName,
                            percentile: 95,
                            color: "COLOR_A",
                        },
                    ],
                    group_by: {
                        field: deviceGroupByArgument,
                        limit: 10,
                        order_by: "TOP",
                        aggregation_function: "COUNT",
                        aggregation_context: "TRACES",
                        device_definition_version_ids: [],
                        argument_definition_id: null,
                        trace_definition_ids: [],
                        enabled: true,
                    },
                },
                stacked: false,
                logarithmic_scale: false,
            }),
            VisualisationInfo.fromJsonObject({
                entity_id: "VISU11",
                entity_type: "VISUALIZATION",
                created: Date.now(),
                updated: Date.now(),
                display_name: "Top 10 Devices Memory Consumption",
                configuration: {
                    visualisation_type: VisualisationType.VALUES_TABLE,
                    data_sources: [
                        {
                            data_source_key: "DEFAULT",
                            device_definition_version_id: [
                                this.state.customDashboardInfo.deviceDefinitionVersions[0].entityId,
                            ],
                            sternum_query: {
                                query_type: "AND",
                                filters: [],
                                queries: [],
                            },
                            aggregation_function: "MAX",
                            aggregation_context: "TRACES",
                            aggregation_fields: null,
                            trace_definition_id: processListInformationTrace?.identifier,
                            trace_definition_display_name: processListInformationTrace?.displayName,
                            argument_definition_id: memoryUsageArgument?.identifier,
                            argument_definition_display_name: memoryUsageArgument?.displayName,
                            percentile: 95,
                            color: "COLOR_A",
                        },
                    ],
                    group_by: {
                        field: deviceGroupByArgument,
                        limit: 10,
                        order_by: "TOP",
                        aggregation_function: "COUNT",
                        aggregation_context: "TRACES",
                        device_definition_version_ids: [],
                        argument_definition_id: null,
                        trace_definition_ids: [],
                        enabled: true,
                    },
                },
                stacked: false,
                logarithmic_scale: false,
            }),
            VisualisationInfo.fromJsonObject({
                entity_id: "VISU12",
                entity_type: "VISUALIZATION",
                created: Date.now(),
                updated: Date.now(),
                display_name: "Top 10 Devices Disk Consumption",
                configuration: {
                    visualisation_type: VisualisationType.VALUES_TABLE,
                    data_sources: [
                        {
                            data_source_key: "DEFAULT",
                            device_definition_version_id: [
                                this.state.customDashboardInfo.deviceDefinitionVersions[0].entityId,
                            ],
                            sternum_query: {
                                query_type: "AND",
                                filters: [
                                    {
                                        argument_definition_id: mountedOnArgument?.identifier,
                                        filter_condition: "EQUALS",
                                        value: "TOTAL",
                                        display_name: mountedOnArgument?.displayName || "",
                                    },
                                ],
                                queries: [],
                            },
                            aggregation_function: "MAX",
                            aggregation_context: "TRACES",
                            aggregation_fields: null,
                            trace_definition_id: storageReportTrace?.identifier,
                            trace_definition_display_name: storageReportTrace?.displayName,
                            argument_definition_id: usedPercentageArgument?.identifier,
                            argument_definition_display_name: usedPercentageArgument?.displayName,
                            percentile: 95,
                            color: "COLOR_A",
                        },
                    ],
                    group_by: {
                        field: deviceGroupByArgument,
                        limit: 10,
                        order_by: "TOP",
                        aggregation_function: "COUNT",
                        aggregation_context: "TRACES",
                        device_definition_version_ids: [],
                        argument_definition_id: null,
                        trace_definition_ids: [],
                        enabled: true,
                    },
                },
                stacked: false,
                logarithmic_scale: false,
            }),
        ];
    }

    private generateLinuxSecurityDashboardVisualisations(): VisualisationInfo[] {
        const attackAttemptTrace = this.getReceivedDefinitionTraceByName("Attack Attempt");
        const clientConnectedTrace = this.getReceivedDefinitionTraceByName("Client Connected");
        const connectTrace = this.getReceivedDefinitionTraceByName("Connect");
        const allEventsTrace = ConfigurationService.getAllEventsFilterField();
        const allAlertsTrace = ConfigurationService.getAllAlertsFilterField();

        const exploitationTypeArgument = this.getReceivedDefinitionArgumentByName("Exploitation Type");
        const executingProcessNameArgument = this.getReceivedDefinitionArgumentByName("Executing Process Name");
        const remoteIpArgument = this.getReceivedDefinitionArgumentByName("Remote ip");

        return [
            VisualisationInfo.fromJsonObject({
                entity_id: "VISU1",
                entity_type: "VISUALIZATION",
                created: Date.now(),
                updated: Date.now(),
                display_name: "Activity",
                configuration: {
                    visualisation_type: "TIME_SERIES",
                    data_sources: [
                        {
                            data_source_key: "DEFAULT",
                            device_definition_version_id: [
                                this.state.customDashboardInfo.deviceDefinitionVersions[0].entityId,
                            ],
                            sternum_query: {
                                query_type: "AND",
                                filters: [],
                                queries: [],
                            },
                            aggregation_function: "COUNT",
                            aggregation_context: "TRACES",
                            aggregation_fields: null,
                            trace_definition_id: allEventsTrace.id,
                            trace_definition_display_name: allEventsTrace.displayName,
                            argument_definition_id: "",
                            argument_definition_display_name: "",
                            percentile: 95,
                            color: "COLOR_A",
                        },
                    ],
                    group_by: null,
                },
                stacked: false,
                logarithmic_scale: false,
            }),
            VisualisationInfo.fromJsonObject({
                entity_id: "VISU2",
                entity_type: "VISUALIZATION",
                created: Date.now(),
                updated: Date.now(),
                display_name: "Prevented Attacks & Alerts Over Time",
                configuration: {
                    visualisation_type: "TIME_SERIES",
                    data_sources: [
                        {
                            data_source_key: "DEFAULT",
                            device_definition_version_id: [
                                this.state.customDashboardInfo.deviceDefinitionVersions[0].entityId,
                            ],
                            sternum_query: {
                                query_type: "AND",
                                filters: [],
                                queries: [],
                            },
                            aggregation_function: "COUNT",
                            aggregation_context: "TRACES",
                            aggregation_fields: null,
                            trace_definition_id: allAlertsTrace.id,
                            trace_definition_display_name: allAlertsTrace.displayName,
                            argument_definition_id: "",
                            argument_definition_display_name: "",
                            percentile: 95,
                            color: "COLOR_A",
                        },
                        {
                            data_source_key: "1",
                            device_definition_version_id: [
                                this.state.customDashboardInfo.deviceDefinitionVersions[0].entityId,
                            ],
                            sternum_query: {
                                query_type: "AND",
                                filters: [],
                                queries: [],
                            },
                            aggregation_function: "COUNT",
                            aggregation_context: "TRACES",
                            aggregation_fields: null,
                            trace_definition_id: attackAttemptTrace.identifier,
                            trace_definition_display_name: attackAttemptTrace.displayName,
                            argument_definition_id: "",
                            argument_definition_display_name: "",
                            percentile: 95,
                            color: "COLOR_B",
                        },
                    ],
                    group_by: null,
                },
                stacked: false,
                logarithmic_scale: false,
            }),
            VisualisationInfo.fromJsonObject({
                entity_id: "VISU3",
                entity_type: "VISUALIZATION",
                created: Date.now(),
                updated: Date.now(),
                display_name: "Prevented Attack Attempts by Type",
                configuration: {
                    visualisation_type: "VALUES_GRAPH",
                    data_sources: [
                        {
                            data_source_key: "DEFAULT",
                            device_definition_version_id: [
                                this.state.customDashboardInfo.deviceDefinitionVersions[0].entityId,
                            ],
                            sternum_query: {
                                query_type: "AND",
                                filters: [],
                                queries: [],
                            },
                            aggregation_function: "COUNT",
                            aggregation_context: "TRACES",
                            aggregation_fields: null,
                            trace_definition_id: attackAttemptTrace.identifier,
                            trace_definition_display_name: attackAttemptTrace.displayName,
                            argument_definition_id: "",
                            argument_definition_display_name: "",
                            percentile: 95,
                            color: "COLOR_A",
                        },
                    ],
                    group_by: {
                        field: exploitationTypeArgument.identifier,
                        limit: 10,
                        order_by: "TOP",
                        aggregation_function: "COUNT",
                        aggregation_context: "TRACES",
                        device_definition_version_ids: [],
                        argument_definition_id: null,
                        trace_definition_ids: [],
                        enabled: true,
                    },
                },
                stacked: false,
                logarithmic_scale: false,
            }),
            VisualisationInfo.fromJsonObject({
                entity_id: "VISU4",
                entity_type: "VISUALIZATION",
                created: Date.now(),
                updated: Date.now(),
                display_name: "Top Attacked Processes",
                configuration: {
                    visualisation_type: "PIE_CHART",
                    data_sources: [
                        {
                            data_source_key: "DEFAULT",
                            device_definition_version_id: [
                                this.state.customDashboardInfo.deviceDefinitionVersions[0].entityId,
                            ],
                            sternum_query: {
                                query_type: "AND",
                                filters: [],
                                queries: [],
                            },
                            aggregation_function: "COUNT",
                            aggregation_context: "TRACES",
                            aggregation_fields: null,
                            trace_definition_id: attackAttemptTrace.identifier,
                            trace_definition_display_name: attackAttemptTrace.displayName,
                            argument_definition_id: "",
                            argument_definition_display_name: "",
                            percentile: 95,
                            color: "COLOR_A",
                        },
                    ],
                    group_by: {
                        field: executingProcessNameArgument.identifier,
                        limit: 10,
                        order_by: "TOP",
                        aggregation_function: "COUNT",
                        aggregation_context: "TRACES",
                        device_definition_version_ids: [],
                        argument_definition_id: null,
                        trace_definition_ids: [],
                        enabled: true,
                    },
                },
                stacked: false,
                logarithmic_scale: false,
            }),
            VisualisationInfo.fromJsonObject({
                entity_id: "VISU5",
                entity_type: "VISUALIZATION",
                created: Date.now(),
                updated: Date.now(),
                display_name: "Incoming Connections Per Process",
                configuration: {
                    visualisation_type: "VALUES_TABLE",
                    data_sources: [
                        {
                            data_source_key: "DEFAULT",
                            device_definition_version_id: [
                                this.state.customDashboardInfo.deviceDefinitionVersions[0].entityId,
                            ],
                            sternum_query: {
                                query_type: "AND",
                                filters: [
                                    {
                                        argument_definition_id: remoteIpArgument.identifier,
                                        filter_condition: "NOT_EQUALS",
                                        value: "0.0.0.0",
                                        display_name: remoteIpArgument.displayName,
                                    },
                                ],
                                queries: [],
                            },
                            aggregation_function: "COUNT",
                            aggregation_context: "TRACES",
                            aggregation_fields: null,
                            trace_definition_id: clientConnectedTrace.identifier,
                            trace_definition_display_name: clientConnectedTrace.displayName,
                            argument_definition_id: "",
                            argument_definition_display_name: "",
                            percentile: 95,
                            color: "COLOR_A",
                        },
                    ],
                    group_by: {
                        field: executingProcessNameArgument.identifier,
                        limit: 10,
                        order_by: "TOP",
                        aggregation_function: "COUNT",
                        aggregation_context: "TRACES",
                        device_definition_version_ids: [],
                        argument_definition_id: null,
                        trace_definition_ids: [],
                        enabled: true,
                    },
                },
                stacked: false,
                logarithmic_scale: false,
            }),
            VisualisationInfo.fromJsonObject({
                entity_id: "VISU6",
                entity_type: "VISUALIZATION",
                created: Date.now(),
                updated: Date.now(),
                display_name: "Top Connected IP Addresses",
                configuration: {
                    visualisation_type: "VALUES_TABLE",
                    data_sources: [
                        {
                            data_source_key: "DEFAULT",
                            device_definition_version_id: [
                                this.state.customDashboardInfo.deviceDefinitionVersions[0].entityId,
                            ],
                            sternum_query: {
                                query_type: "AND",
                                filters: [],
                                queries: [],
                            },
                            aggregation_function: "COUNT",
                            aggregation_context: "TRACES",
                            aggregation_fields: null,
                            trace_definition_id: clientConnectedTrace.identifier,
                            trace_definition_display_name: clientConnectedTrace.displayName,
                            argument_definition_id: "",
                            argument_definition_display_name: "",
                            percentile: 95,
                            color: "COLOR_A",
                        },
                    ],
                    group_by: {
                        field: remoteIpArgument.identifier,
                        limit: 10,
                        order_by: "TOP",
                        aggregation_function: "COUNT",
                        aggregation_context: "TRACES",
                        device_definition_version_ids: [],
                        argument_definition_id: null,
                        trace_definition_ids: [],
                        enabled: true,
                    },
                },
                stacked: false,
                logarithmic_scale: false,
            }),
            VisualisationInfo.fromJsonObject({
                entity_id: "VISU7",
                entity_type: "VISUALIZATION",
                created: Date.now(),
                updated: Date.now(),
                display_name: "Incoming & Outgoing Network Activity Over Time",
                configuration: {
                    visualisation_type: "TIME_SERIES",
                    data_sources: [
                        {
                            data_source_key: "DEFAULT",
                            device_definition_version_id: [
                                this.state.customDashboardInfo.deviceDefinitionVersions[0].entityId,
                            ],
                            sternum_query: {
                                query_type: "AND",
                                filters: [],
                                queries: [],
                            },
                            aggregation_function: "COUNT",
                            aggregation_context: "TRACES",
                            aggregation_fields: null,
                            trace_definition_id: connectTrace.identifier,
                            trace_definition_display_name: connectTrace.displayName,
                            argument_definition_id: "",
                            argument_definition_display_name: "",
                            percentile: 95,
                            color: "COLOR_A",
                        },
                        {
                            data_source_key: "1",
                            device_definition_version_id: [
                                this.state.customDashboardInfo.deviceDefinitionVersions[0].entityId,
                            ],
                            sternum_query: {
                                query_type: "AND",
                                filters: [
                                    {
                                        argument_definition_id: remoteIpArgument.identifier,
                                        filter_condition: "NOT_EQUALS",
                                        value: "0.0.0.0",
                                        display_name: remoteIpArgument.displayName,
                                    },
                                ],
                                queries: [],
                            },
                            aggregation_function: "COUNT",
                            aggregation_context: "TRACES",
                            aggregation_fields: null,
                            trace_definition_id: clientConnectedTrace.identifier,
                            trace_definition_display_name: clientConnectedTrace.displayName,
                            argument_definition_id: "",
                            argument_definition_display_name: "",
                            percentile: 95,
                            color: "COLOR_B",
                        },
                    ],
                    group_by: null,
                },
                stacked: false,
                logarithmic_scale: true,
            }),
            VisualisationInfo.fromJsonObject({
                entity_id: "VISU8",
                entity_type: "VISUALIZATION",
                created: Date.now(),
                updated: Date.now(),
                display_name: "Dynamic SBOM",
                configuration: {
                    visualisation_type: "VALUES_TABLE",
                    data_sources: [
                        {
                            data_source_key: "DEFAULT",
                            device_definition_version_id: [
                                this.state.customDashboardInfo.deviceDefinitionVersions[0].entityId,
                            ],
                            sternum_query: {
                                query_type: "AND",
                                filters: [],
                                queries: [],
                            },
                            aggregation_function: "COUNT",
                            aggregation_context: "TRACES",
                            aggregation_fields: null,
                            trace_definition_id: allEventsTrace.id,
                            trace_definition_display_name: allEventsTrace.displayName,
                            argument_definition_id: "",
                            argument_definition_display_name: "",
                            percentile: 95,
                            color: "COLOR_A",
                        },
                    ],
                    group_by: {
                        field: executingProcessNameArgument.identifier,
                        limit: 200,
                        order_by: "TOP",
                        aggregation_function: "COUNT",
                        aggregation_context: "TRACES",
                        device_definition_version_ids: [],
                        argument_definition_id: null,
                        trace_definition_ids: [],
                        enabled: true,
                    },
                },
                stacked: false,
                logarithmic_scale: false,
            }),
        ];
    }

    private getReceivedDefinitionTraceByName(name: string) {
        return this.state.receivedDefinitionsResponse?.receivedDefinitions.find(
            (traceDefinition) => traceDefinition.displayName === name
        );
    }

    private getReceivedDefinitionArgumentByName(name: string) {
        return this.state.receivedDefinitionsResponse?.receivedArguments.find(
            (argumentDefinition) => argumentDefinition.displayName === name
        );
    }

    private handleViewMoreClick = () => {
        this.setState({ viewMore: true });
    };

    private handleAddGlanceClick = () => {
        if (this.props.isFreeUser) {
            this.props.openGoProModal("goProModal");
        } else {
            this.openVisualisationModal(undefined, VisualisationType.TIME_SERIES);
        }
    };

    private onTimeRangeSelected(selectedTimeOption: TimeSelectOption) {
        this.setState({
            startTime: selectedTimeOption.timeRange ? selectedTimeOption.timeRange.start : this.state.startTime,
            endTime: selectedTimeOption.timeRange ? selectedTimeOption.timeRange.end : this.state.endTime,
            timeSelectOption: selectedTimeOption,
            refreshCounter: this.state.refreshCounter + 1,
        });
    }

    private enterEditDisplayName() {
        this.setState({
            editingDisplayName: !this.state.editingDisplayName,
        });
    }

    /***
     * Check if deleted dashboard is the active one. If so, go to main page
     */
    private onDeleteDashboard = (dashboardId: string) => {
        if (this.state.dashboardId === dashboardId) {
            this.props.history.push("/glances");
            this.setState({ dashboardId: null });
        }
    };

    private onDisplayNameChanged(updatedDisplayName) {
        this.setState({
            displayName: updatedDisplayName,
        });
    }

    private async updateCustomDashboardName() {
        try {
            this.setState({
                savingDisplayName: true,
                errorSavingDisplayName: false,
            });

            await ServiceWire.getCustomDashboardsApiService().updateCustomDashboard(
                this.state.customDashboardInfo.customDashboardId,
                this.state.displayName,
                this.state.customDashboardInfo.deviceDefinitionVersions.map(
                    (deviceDefinitionVersion) => deviceDefinitionVersion.entityId
                )
            );

            this.setState(
                {
                    savingDisplayName: false,
                    errorSavingDisplayName: false,
                    editingDisplayName: false,
                    forceReloadDashboards: true,
                },
                () => {
                    this.setState({ forceReloadDashboards: false });
                }
            );
        } catch {
            this.setState({
                savingDisplayName: false,
                errorSavingDisplayName: true,
            });
        }
    }

    private handleVisualisationDeleted = (index: number) => {
        const clonedCustomDashboardInfo: CustomDashboardInfo = new CustomDashboardInfo(
            this.state.customDashboardInfo.customDashboardId,
            this.state.customDashboardInfo.displayName,
            this.state.customDashboardInfo.created,
            this.state.customDashboardInfo.updated,
            this.state.customDashboardInfo.deviceId,
            this.state.customDashboardInfo.deviceDefinitionVersions,
            this.state.customDashboardInfo.lookedUpEntityId,
            this.state.customDashboardInfo.deviceDefinitionId,
            [...this.state.customDashboardInfo.visualisationSummaries],
            this.state.customDashboardInfo.type
        );

        clonedCustomDashboardInfo.visualisationSummaries.splice(index, 1);

        this.setState({
            customDashboardInfo: clonedCustomDashboardInfo,
        });
    };

    private openVisualisationModal = (
        visualisationId?: string,
        visualisationType?: VisualisationType,
        lookedUpEntityId?: string,
        visualisation?: VisualisationInfo,
        disableEditing?: boolean
    ) => {
        this.props.openVisualizationCreationModal(
            "VisualizationCreation",
            visualisationId,
            this.state.customDashboardInfo.deviceDefinitionVersions,
            {},
            this.state.startTime,
            this.state.endTime,
            undefined,
            true,
            false,
            false,
            lookedUpEntityId,
            this.state.timeSelectOption,
            visualisationType,
            this.state.dashboardId,
            (visualisationId, displayName) => this.onVisualisationCreated(visualisationId, displayName),
            (visualisationId, displayName) => this.onVisualisationUpdated(visualisationId, displayName),
            undefined,
            visualisation,
            disableEditing
        );

        this.setState({
            selectingNewVisualisation: false,
        });
    };

    private onVisualisationUpdated = (visualisationId: string, displayName: string) => {
        const clonedVisualisations = [...this.state.customDashboardInfo.visualisationSummaries];

        const foundVisualisationIndex = clonedVisualisations.findIndex(
            (visualisation) => visualisation.visualisationId === visualisationId
        );

        if (foundVisualisationIndex > -1) {
            clonedVisualisations[foundVisualisationIndex] = {
                ...clonedVisualisations[foundVisualisationIndex],
                displayName: displayName,
                updated: new Date().getTime(),
            };
        }

        this.setState({
            customDashboardInfo: new CustomDashboardInfo(
                this.state.customDashboardInfo.customDashboardId,
                this.state.customDashboardInfo.displayName,
                this.state.customDashboardInfo.created,
                this.state.customDashboardInfo.updated,
                this.state.customDashboardInfo.deviceId,
                this.state.customDashboardInfo.deviceDefinitionVersions,
                this.state.customDashboardInfo.lookedUpEntityId,
                this.state.customDashboardInfo.deviceDefinitionId,
                clonedVisualisations,
                this.state.customDashboardInfo.type
            ),
        });
    };

    private onVisualisationCreated(visualisationId: string, displayName: string) {
        this.setState({
            customDashboardInfo: new CustomDashboardInfo(
                this.state.customDashboardInfo.customDashboardId,
                this.state.customDashboardInfo.displayName,
                this.state.customDashboardInfo.created,
                this.state.customDashboardInfo.updated,
                this.state.customDashboardInfo.deviceId,
                this.state.customDashboardInfo.deviceDefinitionVersions,
                this.state.customDashboardInfo.lookedUpEntityId,
                this.state.customDashboardInfo.deviceDefinitionId,
                [
                    ...this.state.customDashboardInfo.visualisationSummaries,
                    {
                        visualisationId: visualisationId,
                        displayName: displayName,
                        updated: new Date().getTime(),
                    },
                ],
                this.state.customDashboardInfo.type
            ),
        });
    }

    private async loadReceivedDefinitions() {
        const versionId = this.state.customDashboardInfo.deviceDefinitionVersions[0].entityId;

        try {
            const receivedDefinitionsResponse = await ServiceWire.getEventsApiService().getAllReceivedDefinitions(
                versionId,
                this.state.startTime.getTime(),
                this.state.endTime.getTime()
            );

            this.setState({
                receivedDefinitionsResponse,
            });
        } catch (err) {
            console.error(`Failed to load received definitions: ${err}`);
        }
    }

    private async loadDashboard() {
        try {
            this.setState({
                loading: true,
                error: false,
            });

            const customDashboardInfo: CustomDashboardInfo =
                await ServiceWire.getCustomDashboardsApiService().getCustomDashboard(this.state.dashboardId);

            this.setState({
                error: false,
                customDashboardInfo: customDashboardInfo,
                displayName: customDashboardInfo.displayName,
                loading: false,
            });
        } catch (error) {
            this.setState({
                loading: false,
                error: true,
            });
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(dashboardViewerStyle)(DashboardViewer)));
