import React from "react";

import { SternumIconProps } from "./SternumIcon.types";

export const CopyIcon = React.forwardRef<SVGSVGElement, SternumIconProps>(function CopyIcon(
    { color, height = 17, width = 16, ...props },
    ref
) {
    return (
        <svg
            ref={ref}
            width={width}
            height={height}
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.57538 12.4134C1.40813 11.6609 1.33335 10.6565 1.33335 9.2933C1.33335 7.93012 1.40813 6.9257 1.57538 6.17316C1.73926 5.43577 1.97367 5.02445 2.23703 4.7611C2.50039 4.49774 2.91173 4.26334 3.64914 4.09946C4.40169 3.93221 5.40614 3.85743 6.76935 3.85743C8.13256 3.85743 9.13701 3.93221 9.88956 4.09946C10.627 4.26334 11.0383 4.49774 11.3017 4.7611C11.565 5.02445 11.7994 5.43577 11.9633 6.17316C12.1306 6.9257 12.2054 7.93012 12.2054 9.2933C12.2054 10.6565 12.1306 11.6609 11.9633 12.4134C11.7994 13.1508 11.565 13.5622 11.3017 13.8255C11.0383 14.0889 10.627 14.3233 9.88956 14.4871C9.13701 14.6544 8.13256 14.7292 6.76935 14.7292C5.40614 14.7292 4.40169 14.6544 3.64914 14.4871C2.91173 14.3233 2.50039 14.0889 2.23703 13.8255C1.97367 13.5622 1.73926 13.1508 1.57538 12.4134ZM6.76935 16.0625C1.19479 16.0625 0 14.8677 0 9.2933C0 3.71887 1.19479 2.5241 6.76935 2.5241C12.3439 2.5241 13.5387 3.71887 13.5387 9.2933C13.5387 14.8677 12.3439 16.0625 6.76935 16.0625Z"
                fill={color || "currentColor"}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.5154 10.5195C13.4925 11.064 13.452 11.5578 13.3893 12.0051C15.4642 11.3709 16 9.77566 16 6.21632C16 1.29326 14.7692 0.0625 9.84605 0.0625C6.28638 0.0625 4.69117 0.598385 4.05697 2.67356C4.50426 2.61088 4.99802 2.57029 5.54259 2.54745C5.62742 2.39241 5.71825 2.27506 5.81147 2.18184C6.02958 1.96374 6.37982 1.75874 7.03579 1.61296C7.70689 1.46382 8.60953 1.39583 9.84605 1.39583C12.3172 1.39583 13.3273 1.737 13.8264 2.23607C14.3255 2.73514 14.6667 3.74526 14.6667 6.21632C14.6667 7.4528 14.5987 8.35542 14.4495 9.02651C14.3037 9.68245 14.0987 10.0327 13.8806 10.2508C13.7875 10.3439 13.6702 10.4347 13.5154 10.5195Z"
                fill={color || "currentColor"}
            />
        </svg>
    );
});
