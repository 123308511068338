import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

import { commonHome } from "../Home.style";

export const useCustomersOpinionsViewStyle = makeStyles((theme: Theme) => ({
    customerOpinionsContainer: {
        display: "flex",
        justifyContent: "center",
        background: commonHome.customersOpinionsBackground,
        padding: "115px 0px",
    },

    title: {
        fontSize: 32,
        fontWeight: 600,
        color: "#231434",
        marginBottom: 40,
        textAlign: "center",

        [`@media (max-width: ${commonHome.mediaQueryMaxWidth})`]: {
            fontSize: 22,
            marginBottom: 36,
        },
    },

    sliderArrow: {
        "&&": {
            marginTop: 50,
        },
    },

    customerItem: {
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        maxWidth: 959,
        textAlign: "center",
    },

    image: {
        width: 142,
        height: 142,
        borderRadius: "100%",
    },

    descriptions: {
        fontSize: 20,
        fontWeight: 400,
        color: "#212E44",
        lineHeight: "40px",
        marginTop: 40,

        [`@media (max-width: ${commonHome.mediaQueryMaxWidth})`]: {
            fontSize: 14,
            lineHeight: "23px",
            marginTop: 36,
        },
    },

    nameAndSurname: {
        fontSize: 24,
        fontWeight: 500,
        color: "#231434",
        lineHeight: "40px",
        textDecoration: "CaptionText",
        marginTop: 40,

        [`@media (max-width: ${commonHome.mediaQueryMaxWidth})`]: {
            fontSize: 18,
            lineHeight: "150%",
            marginTop: 36,
        },
    },

    workplace: {
        fontSize: 18,
        fontWeight: 400,
        lineHeight: "150%",
        color: "#231434",
        marginTop: 8,
        marginBottom: 40,

        [`@media (max-width: ${commonHome.mediaQueryMaxWidth})`]: {
            fontSize: 14,
            lineHeight: "160%",
            marginTop: 36,
        },
    },
}));
