import { makeStyles } from "@material-ui/core";

export const useLinuxTopologyDropdownStyles = makeStyles((theme) => ({
    container: {
        height: 45,
        width: 190,
        marginRight: 10,
    },
    selectComponent: {
        width: 190,
    },

    popoverPaper: {
        padding: 24,
        borderRadius: 20,
        minWidth: 190,
        maxHeight: 450,
    },

    dropdown: {
        display: "flex",
        alignItems: "center",
        background: "#FFFFFF",
        boxShadow: "0 0 23px rgba(211, 212, 214, 0.25)",
        borderRadius: 14,
        padding: "0 8px 0 10px",
        height: "100%",
        cursor: "pointer",
    },
    dropdownValueContainer: {
        flex: 1,
        display: "flex",
        alignItems: "center",
        gap: 10,
        overflow: "hidden",
    },
    dropdownValueContent: {
        flex: 1,
        display: "flex",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        color: "#333333",
        fontSize: 16,
    },
    dropdownIconContainer: {
        display: "flex",
        alignItems: "center",
    },
}));
