import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        padding: "20px",
        borderRadius: "14px",
        background: "rgba(243, 243, 255, 0.7)",
    },

    inner: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },

    title: {
        marginBottom: 15,
        fontWeight: 700,
        fontSize: "18px",
    },
}));
