import React from "react";
import classNames from "classnames";

import { useMultipleDevicesSummaryStyle } from "./MultipleDevicesSummary.style";

export interface MultipleDevicesSummaryProps {
    totalRelatedDevices: number;
    uniqueDevicesProfiles: number;
}

export function MultipleDevicesSummary({ totalRelatedDevices, uniqueDevicesProfiles }: MultipleDevicesSummaryProps) {
    const classes = useMultipleDevicesSummaryStyle();

    return (
        <div className={classNames(classes.container)}>
            <div className={classNames(classes.title)}>Devices</div>
            <div className={classNames(classes.summaryItem)}>
                <div className={classNames(classes.summaryItemTitle)}>Total related devices:</div>
                <div className={classNames(classes.summaryItemValue)}>&nbsp;{totalRelatedDevices}</div>
            </div>
            <div className={classNames(classes.summaryItem)}>
                <div className={classNames(classes.summaryItemTitle)}>Unique device profiles:</div>
                <div className={classNames(classes.summaryItemValue)}>&nbsp;{uniqueDevicesProfiles}</div>
            </div>
        </div>
    );
}
