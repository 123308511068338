import moment from "moment";

import {
    convertCategoryTimeRangeToNumeric,
    DAY_INTERVAL_IN_MS,
    generateMockedEmptyStatistics,
} from "../infra/Alerts.utils";
import WebUtils from "../infra/WebUtils";
import FetchError from "./FetchError";
import { IssuesStatisticsHeatmap } from "./IssuesStatisticsHeatmap";
import { Status } from "./GlobalTypes";

export interface DashboardRegularState {
    categoryView: CategoryView;
    isOnlyUnresolved: boolean;
    isOnlyAnomalyInsights: boolean;
    selectedGraphView: CategoryGraphView;
    categoryTimeRange: CategoryTimeRange;
    categoryTimeRangeCustomFrom?: Date;
    categoryTimeRangeCustomTo?: Date;
    alertsFilter: {
        selectedCategories: Record<string, DashboardAlertsDateRange[]>;
    };
    alertsCountFilter: AlertsCountFilter | null;
    alertsHeatmapInteractionsEnabled: boolean;

    alertsStatistics: IssuesStatisticsHeatmap | null;
    alertsStatisticsLoading: boolean;
    alertsStatisticsRequestError: FetchError | null;
    selectedAlertCategories: string[];
    isAlertTableExpanded: boolean;

    onboardingState: DashboardOnboardingState;
}

export interface AlertsCountFilter {
    from: number;
    to: number;
}

export interface DashboardAlertsDateRange {
    from: number;
    to: number;
}

export enum CategoryView {
    Min = "Min",
    Max = "Max",
}

export enum CategoryGraphView {
    Categories = "Categories",
    OverTime = "OverTime",
    Map = "Map",
}

export enum CategoryTimeRange {
    LastWeek = "LastWeek",
    LastMonth = "LastMonth",
    LastYear = "LastYear",
    TimeRange = "TimeRange",
}

export interface DashboardOnboardingState {
    isVisible: boolean;
    statusGet: Status;
    statusSet: Status;
    stepsState?: DashboardOnboardingStepState;
}

export interface DashboardOnboardingStepState {
    isAverageAnomaliesNumbersVisited: boolean;
}

function getDefaultCategoryTimeRange(): {
    categoryTimeRange: CategoryTimeRange;
    categoryTimeRangeCustomFrom?: Date;
    categoryTimeRangeCustomTo?: Date;
} {
    /**
     * Possible Options:
     * STERNUM_DEFAULT_TIME_RANGE = "m" | "h" | "d" | "w" | "M" | "y" | "c";
     *
     * "m" - minute
     * "h" - hour
     * "d" - day
     * "w" - week
     * "M" - month
     * "y" - year
     * "c" - custom time rage taken from STERNUM_DEFAULT_CUSTOM_TIME_RANGE_START and STERNUM_DEFAULT_CUSTOM_TIME_RANGE_END
     *
     * e.g. - .env file:
     * STERNUM_DEFAULT_TIME_RANGE=c
     * STERNUM_DEFAULT_CUSTOM_TIME_RANGE_START=2023-03-02T11:36:45.867Z
     * STERNUM_DEFAULT_CUSTOM_TIME_RANGE_END=2023-03-18T11:36:45.867Z
     */
    const envCategoryTimeRange = process.env.STERNUM_DEFAULT_TIME_RANGE;
    const envCustomTimeStart = process.env.STERNUM_DEFAULT_CUSTOM_TIME_RANGE_START;
    const envCustomTimeEnd = process.env.STERNUM_DEFAULT_CUSTOM_TIME_RANGE_END;

    const result: {
        categoryTimeRange: CategoryTimeRange;
        categoryTimeRangeCustomFrom?: Date;
        categoryTimeRangeCustomTo?: Date;
    } = {
        categoryTimeRange: CategoryTimeRange.LastWeek,
    };

    switch (envCategoryTimeRange) {
        case "m":
            result.categoryTimeRange = CategoryTimeRange.LastWeek;
            break;
        case "h":
            result.categoryTimeRange = CategoryTimeRange.LastWeek;
            break;
        case "d":
            result.categoryTimeRange = CategoryTimeRange.LastWeek;
            break;
        case "w":
            result.categoryTimeRange = CategoryTimeRange.LastWeek;
            break;
        case "M":
            result.categoryTimeRange = CategoryTimeRange.LastMonth;
            break;
        case "y":
            result.categoryTimeRange = CategoryTimeRange.LastYear;
            break;
        case "c":
            result.categoryTimeRange = CategoryTimeRange.TimeRange;
            result.categoryTimeRangeCustomFrom = moment(envCustomTimeStart).toDate();
            result.categoryTimeRangeCustomTo = moment(envCustomTimeEnd).toDate();
            break;
        default:
            result.categoryTimeRange = CategoryTimeRange.LastWeek;
    }

    return result;
}

export const getDefaultDashboardRegularState = (): DashboardRegularState => {
    const { categoryTimeRange, categoryTimeRangeCustomFrom, categoryTimeRangeCustomTo } = getDefaultCategoryTimeRange();
    const defaultDateRange = convertCategoryTimeRangeToNumeric(categoryTimeRange, {
        from: categoryTimeRangeCustomFrom,
        to: categoryTimeRangeCustomTo,
    });
    const onlyAnomalyInsights = WebUtils.getUrlSearchParam("anomaly_insights") === "1";

    return {
        categoryView: CategoryView.Max,
        isOnlyUnresolved: false,
        isOnlyAnomalyInsights: onlyAnomalyInsights,
        selectedGraphView: CategoryGraphView.Categories,
        categoryTimeRange,
        categoryTimeRangeCustomFrom,
        categoryTimeRangeCustomTo,
        alertsFilter: getDefaultDashboardRegularAlertsFilterState(),
        alertsCountFilter: null,
        alertsHeatmapInteractionsEnabled: false,

        alertsStatistics: {
            category_count: generateMockedEmptyStatistics(
                defaultDateRange.from,
                defaultDateRange.to,
                DAY_INTERVAL_IN_MS
            ),
        },
        isAlertTableExpanded: false,
        alertsStatisticsLoading: false,
        alertsStatisticsRequestError: null,
        selectedAlertCategories: [],
        onboardingState: { isVisible: false, statusGet: Status.NotInitialized, statusSet: Status.NotInitialized },
    };
};

export const getDefaultDashboardRegularAlertsFilterState = (): DashboardRegularState["alertsFilter"] => {
    return {
        selectedCategories: {},
    };
};
