/**
 * Represents a configuration for a category in Sternum.
 */
class ArgumentRoleType {
    /**
     * Constructor.
     */
    constructor(
        public displayName: string,
        public argumentRoleType: string,
        public argumentRoleTypeName: string,
        public displayInUI: boolean
    ) {}

    /**
     * Converts json to info object.
     */
    public static fromJsonObject(jsonObject: Object) {
        return new ArgumentRoleType(
            jsonObject["display_name"],
            jsonObject["argument_role_type"],
            jsonObject["argument_role_type_name"],
            jsonObject["display_in_ui"]
        );
    }
}

export default ArgumentRoleType;
