class AggregatedEventInfo {
    /**
     * Constructor.
     */
    constructor(
        public count: number,
        public event: string,
        public displayName?: string,
        public color?: string,
        public traceDefinitionNameToCountMap?: Record<string, number>,
        public sternumTriggerNameToCountMap?: Record<string, number>,
        public interestLevels?: string[]
    ) {}
}

export default AggregatedEventInfo;
