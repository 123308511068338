import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useStyles } from "./MonitorDeviceStep.style";
import { StepNumber } from "../StepNumber";
import SternumImprovedButton from "../../SUI/SternumImprovedButton/SternumImprovedButton";
import classNames from "classnames";
import { SignupUserGuideStep } from "../../../lib/state/ClientInfo.types";

interface MonitorDeviceStepProps {
    active: boolean;
    onNextClick: (step: SignupUserGuideStep) => unknown;
    onBackClick: (step: SignupUserGuideStep) => unknown;
    stepNumber: number;
    completed: boolean;
    className?: string;
}

export function MonitorDeviceStep({
    active,
    onNextClick,
    onBackClick,
    stepNumber,
    completed,
    className,
}: MonitorDeviceStepProps) {
    const classes = useStyles();

    const handleNextClick = () => {
        onNextClick(SignupUserGuideStep.MonitorDevice);
    };

    const handleBackClick = () => {
        onBackClick(SignupUserGuideStep.MonitorDevice);
    };

    return (
        <div className={classNames(classes.container, className)}>
            <div className={classes.inner}>
                <div>
                    <StepNumber orderNumber={stepNumber} completed={completed} />
                </div>

                <Box ml={3}>
                    <Typography className={classes.title}>Monitor Device Security and Insights</Typography>

                    {active && (
                        <>
                            <Typography className={classes.description}>
                                You're almost done. Install the protected OpenWrt firmware on your device, and connect
                                it to the internet to get real-time alerts, visibility & insights on both cyber attacks
                                and your device's behavior.
                            </Typography>

                            <Box display="flex" mt={3}>
                                <SternumImprovedButton
                                    buttonType="regular"
                                    content="Start"
                                    fullWidth={false}
                                    onClick={handleNextClick}
                                />
                                <Box ml={2}>
                                    <SternumImprovedButton
                                        buttonType="text:dark"
                                        content="Back"
                                        fullWidth={false}
                                        onClick={handleBackClick}
                                    />
                                </Box>
                            </Box>
                        </>
                    )}
                </Box>
            </div>
        </div>
    );
}
