import { makeStyles } from "@material-ui/core";

export const useIconContainerStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 38,
        height: 30,
        background: "#EDEDED",
        borderRadius: 6,
        color: "#555B61",
    },
    isSelected: {
        background: "#D6E7FF",
        color: "#1B6FDE",
    },
}));
