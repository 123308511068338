import React from "react";
import classNames from "classnames";

import { useLegendDetailsStyles } from "./LegendDetails.styles";

export interface LegendSeparatorProps extends React.ComponentProps<"div"> {}

export function LegendSeparator({ className, children, ...props }: LegendSeparatorProps) {
    const classes = useLegendDetailsStyles();

    return <div className={classNames(className, classes.separator)} {...props} />;
}
