import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "./CommonStyle";

const appStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),
    });

export default appStyle;
