import { Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { closeModalAction } from "../../../lib/redux/modals/CloseModalAction";
import ServiceWire from "../../../lib/services/ServiceWire";
import CustomDashboardInfo from "../../../lib/state/CustomDashboardInfo";
import EntityBase from "../../../lib/state/EntityBase";
import EntityType from "../../../lib/state/EntityType";
import { GlobalState } from "../../../lib/state/GlobalState";
import ModalKey from "../../../lib/state/ModalKey";
import ModalType from "../../../lib/state/ModalType";
import SternumEntitySelect from "../../SUI/SternumEntitySelect/SternumEntitySelect";
import SternumImprovedButton from "../../SUI/SternumImprovedButton/SternumImprovedButton";
import SternumInputField from "../../SUI/SternumInputField/SternumInputField";
import createDashboardModalStyle from "./CreateDashboardModalStyle";

const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeModal: (modalKey) => dispatch(closeModalAction(modalKey)),
    };
};

interface AppState {
    name: string;
    description: string;
    deviceDefinitionVersionIds: string[];

    creatingDashboard: boolean;
    errorCreatingDashboard: boolean;
}

export interface AppProps extends WithStyles<typeof createDashboardModalStyle> {
    closeModal?: (modalKey: ModalKey) => void;
    onCreateOperation?: (customDashboard: CustomDashboardInfo) => void;
    theme;
    history;
    location;
}

class CreateDashboardModal extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);

        const { classes } = this.props;

        // Initializing the state to default.
        this.state = {
            name: "",
            description: "",
            deviceDefinitionVersionIds: [],
            creatingDashboard: false,
            errorCreatingDashboard: false,
        };
    }

    render() {
        const { classes } = this.props;

        let createDisabled = !this.state.name || this.state.deviceDefinitionVersionIds.length == 0;

        return (
            <div role="presentation" aria-label="create dashboard modal container" className={classNames(classes.root)}>
                <Typography className={classNames(classes.title)}>New View</Typography>

                <Typography className={classNames(classes.subTitle)}>
                    Create a new view to include your favorite Glances. Access anytime, at a glance.
                </Typography>
                <Typography className={classNames(classes.sectionTitle)}>Name</Typography>

                <SternumInputField
                    inputValue={this.state.name}
                    onFieldChange={(event) => this.updateName(event)}
                    noLabel
                />

                {/*<Typography className={classNames(classes.sectionTitle, "mod-secondary")}>Description</Typography>*/}

                {/*<Input value={this.state.description} onChange={event => this.updateDescription(event)} />*/}

                <Typography className={classNames(classes.sectionTitle, "mod-secondary", classes.profileSelection)}>
                    Device Profiles
                </Typography>

                <div role="presentation" aria-label="select device profiles dropdown">
                    <SternumEntitySelect
                        multiple
                        entityType={EntityType.DeviceDefinitionVersion}
                        onEntitySelected={(sternumEntity) =>
                            this.updateDeviceDefinitionVersion(sternumEntity as EntityBase[])
                        }
                    />
                </div>

                <div className={classNames(classes.flexGrow)} />

                <div className={classNames(classes.footer)}>
                    {this.state.errorCreatingDashboard && (
                        <Typography className={classNames(classes.errorText)}>Error creating dashboard...</Typography>
                    )}
                    {this.state.creatingDashboard && (
                        <div className={classNames(classes.loadingContainer)}>
                            <CircularProgress size={15} color="inherit" />
                        </div>
                    )}
                    <div className={classNames(classes.cancelButtonContainer)}>
                        <SternumImprovedButton
                            buttonType="gray"
                            fullWidth={false}
                            onClick={() => this.cancel()}
                            content="Cancel"
                        />
                    </div>
                    <SternumImprovedButton
                        fullWidth={false}
                        onClick={() => this.createDashboard()}
                        isDisabled={createDisabled}
                        content="Create"
                    />
                </div>
            </div>
        );
    }

    private cancel() {
        this.props.closeModal(new ModalKey(ModalType.CreateDashboardModal, "CreateDashboardModal"));
    }

    private updateName(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        this.setState({
            name: event.target.value,
        });
    }

    private updateDeviceDefinitionVersion(sternumEntity: EntityBase[]) {
        this.setState({ deviceDefinitionVersionIds: sternumEntity.map((entity) => entity.entityId) });
    }

    private updateDescription(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        const newDescription = event.target.value;

        if (!newDescription || newDescription.length <= 1000) {
            this.setState({
                description: newDescription,
            });
        }
    }

    private async createDashboard() {
        try {
            this.setState({
                creatingDashboard: true,
                errorCreatingDashboard: false,
            });

            const customDashboard = await ServiceWire.getCustomDashboardsApiService().createCustomDashboard(
                ServiceWire.getClientsService().getSelectedClientId(),
                this.state.name,
                this.state.description,
                undefined,
                this.state.deviceDefinitionVersionIds
            );

            // Set created custom dashboard as default one
            ServiceWire.getClientsService().setCustomDashboard(customDashboard.customDashboardId);

            // Redirect into created dashboard
            const redirectTo = `/glances/${customDashboard.customDashboardId}`;

            this.props.history.push(redirectTo);
            if (this.props.onCreateOperation) {
                this.props.onCreateOperation(customDashboard);
            }

            this.props.closeModal(new ModalKey(ModalType.CreateDashboardModal, "CreateDashboardModal"));
        } catch (error) {
            this.setState({
                creatingDashboard: false,
                errorCreatingDashboard: true,
            });
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(createDashboardModalStyle, { withTheme: true })(CreateDashboardModal)));
