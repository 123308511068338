import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const useTilesStyle = makeStyles((theme: Theme) => ({
    tilesContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, 320px)",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center",
        gridGap: 36,
    },

    tileItem: {
        minHeight: 255,
        background: "rgba(255, 255, 255, 0.05)",
        backdropFilter: "blur(25px)",
        borderRadius: "12px",
        padding: "32px 16px",
        textAlign: "center",
        boxSizing: "border-box",
    },

    tileItemIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 59,
        opacity: 0.7,
    },

    tileTitle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: theme.palette.secondary.dark,
        fontSize: 22,
        fontWeight: 600,
        height: 77,
    },

    tileDescription: {
        color: "white",
        fontSize: 14,
        lineHeight: "24px",
    },
}));
