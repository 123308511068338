import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../../CommonStyle";

const deviceDefinitionLibraryStepStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        root: {
            paddingTop: theme.spacing(5),
            paddingRight: theme.spacing(5),
            paddingBottom: theme.spacing(5),
            paddingLeft: theme.spacing(5),
        },

        librariesList: {
            maxHeight: theme.spacing(50),
            overflowY: "auto",
        },
        displayName: {
            width: theme.spacing(90),
        },

        libraryItem: {
            "&:hover": {
                background: "#F0F0F0",
            },
        },

        smallIconButton: {
            padding: "4px",
        },
    });

export default deviceDefinitionLibraryStepStyle;
