import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const useSignupStyle = makeStyles((theme: Theme) => ({
    content: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    },

    innerContainer: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "50px",
        width: "790px",
        maxWidth: "90%",
    },

    mainTitle: {
        fontWeight: 600,
        fontSize: 26,
    },

    description: {
        marginTop: 12,
        fontSize: 14,
        color: "#1D1D1F",
    },

    descriptionCaption: {
        marginTop: 8,
        fontSize: 14,
        color: "#505059",
    },

    linkButton: {
        color: theme.palette.secondary.dark,
        fontSize: 16,
        fontWeight: 600,
    },

    progressContainer: {
        width: "100%",
        marginTop: 24,
    },

    progressTitle: {
        fontSize: 18,
        fontWeight: 500,
        color: "#1D1D1F",
    },

    progressBar: {
        width: "100%",
        height: "6px",
        marginTop: 8,
        borderRadius: "14px",
        background: "#F4F4F4",
    },

    progressTrack: {
        height: "6px",
        background: "#E7004C",
        borderRadius: "14px",
    },

    stepsContainer: {
        display: "flex",
        flexDirection: "column",
        rowGap: "24px",
        width: "100%",
        padding: "40px",
        marginTop: 24,
        boxSizing: "border-box",
        background: "#fff",
        boxShadow: "-12px 14px 34px rgba(83, 93, 120, 0.15)",
        borderRadius: "12px",
    },

    activeStep: {
        padding: "24px",
        background: "#F7F9FA",
        borderRadius: "12px",
    },

    inactiveStep: {
        padding: "0 24px",
    },
}));
