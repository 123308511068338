import { Action } from "redux";

import ActionType from "../ActionType";

export interface SetIsOnlyUnresolvedAction extends Action<ActionType> {
    type: ActionType.DashboardRegularSetIsOnlyUnresolved;
    isOnlyUnresolved: boolean;
}

export function setIsOnlyUnresolvedAction(isOnlyUnresolved: boolean): SetIsOnlyUnresolvedAction {
    return {
        type: ActionType.DashboardRegularSetIsOnlyUnresolved,
        isOnlyUnresolved,
    };
}
