import { Typography, WithStyles, withStyles } from "@material-ui/core";
import classNames from "classnames";
import * as React from "react";
import StatusDisplay from "../StatusDisplay/StatusDisplay";
import timeSelectionSelectComponent from "./TimeSelectionSelectComponentStyle";

interface AppState {}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof timeSelectionSelectComponent> {
    statusText: string;
    labelText: string;
}

class TimeSelectionSelectComponent extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);
        // Initializing the state to default.
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classNames(classes.flexRow, classes.flexVMiddle)}>
                <StatusDisplay
                    label={this.props.statusText}
                    dateSelection={true}
                    displayColoredBackground={true}
                    padding={true}
                />{" "}
                <Typography className={classNames(classes.dateLabel, classes.paddingLeftXs)}>
                    {" "}
                    {this.props.labelText}
                </Typography>
            </div>
        );
    }
}

export default withStyles(timeSelectionSelectComponent)(TimeSelectionSelectComponent);
