import React from "react";

import { SternumIconProps } from "./SternumIcon.types";

export const CrossOutlinedIcon = React.forwardRef<SVGSVGElement, SternumIconProps>(function CrossOutlinedIcon(
    { color = "#E7004C", height = 95, width = 94, ...props },
    ref
) {
    return (
        <svg ref={ref} width={width} height={height} viewBox="0 0 94 95" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.43555 47.1638C5.43555 24.2767 24.1493 5.56287 47.0365 5.56287C69.9237 5.56287 88.6375 24.2767 88.6375 47.1638C88.6375 70.051 69.9237 88.7648 47.0365 88.7648C24.1493 88.7648 5.43555 70.051 5.43555 47.1638ZM47.0365 11.3677C27.3552 11.3677 11.2403 27.4826 11.2403 47.1638C11.2403 66.8451 27.3552 82.96 47.0365 82.96C66.7178 82.96 82.8327 66.8451 82.8327 47.1638C82.8327 27.4826 66.7178 11.3677 47.0365 11.3677Z"
                fill={color || "currentColor"}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M60.0405 34.1598C61.174 35.2933 61.174 37.131 60.0405 38.2644L38.1371 60.1678C37.0037 61.3013 35.166 61.3013 34.0325 60.1678C32.8991 59.0344 32.8991 57.1967 34.0325 56.0632L55.9359 34.1598C57.0694 33.0264 58.907 33.0264 60.0405 34.1598Z"
                fill={color || "currentColor"}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M34.0325 34.1598C35.166 33.0264 37.0037 33.0264 38.1371 34.1598L60.0405 56.0632C61.174 57.1967 61.174 59.0344 60.0405 60.1678C58.907 61.3013 57.0694 61.3013 55.9359 60.1678L34.0325 38.2644C32.8991 37.131 32.8991 35.2933 34.0325 34.1598Z"
                fill={color || "currentColor"}
            />
        </svg>
    );
});
