import { Theme } from "@material-ui/core/styles";
import { Link, withRouter } from "react-router-dom";
import createStyles from "@material-ui/core/styles/createStyles";
import commonStyle from "../CommonStyle";
import { sternumTheme } from "../App";

const libraryHistoriesListStyle = (theme: Theme) =>
    createStyles({
        ...commonStyle(theme),

        paperClass: {
            overflowY: "auto" as "auto",
        },
        rowHover: {
            "&:hover": {
                backgroundColor: `${sternumTheme.lightGrey.main} !important`,
            },
        },
    });

export default libraryHistoriesListStyle;
