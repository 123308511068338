/**
 * Defines the possible statuses of a used library.
 */
enum UsedLibraryStatus {
    CRITICAL_CVE,
    OUT_OF_DATE_LIBRARIES,
    MULTIPLE_ISSUES,
    UP_TO_DATE,
    NO_INFORMATION,
}

export default UsedLibraryStatus;
