/**
 * The different change types that we can poll for,
 */
enum PollingChangeType {
    /**
     * Change in all devices.
     */
    ALL_DEVICES,

    /**
     * Changes in all traces.
     */
    ALL_TRACES,

    /**
     * Changes in all issues.
     */
    ALL_ISSUES,

    /**
     * Changes in all CVEs.
     */
    ALL_CVES,

    /**
     * Change in attacked devices.
     */
    ATTACKED_DEVICES,

    /**
     * Changed in attack traces (traces that represents an attack on a device).
     */
    ATTACK_TRACES,

    /**
     * Change in metrics.
     */
    METRICS,

    /**
     * New activities created.
     */
    ACTIVITIES,

    /**
     * Events indexed.
     */
    EVENTS_INDEXED,

    /**
     * Changes in device definition.
     */
    ALL_DEVICE_DEFINITIONS,

    /**
     * Changes in device definition version.
     */
    ALL_DEVICE_DEFINITION_VERSIONS,

    /**
     * Changes in Client triggers.
     */
    ALL_TRIGGERS,

    /**
     * Changes in custom dashboard
     */
    ALL_CUSTOM_DASHBOARDS,
}

export default PollingChangeType;
