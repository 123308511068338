import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import Utils from "../../lib/infra/Utils";
import ServiceWire from "../../lib/services/ServiceWire";
import SternumBarChart from "../SUI/SternumAMCharts/SternumBarChart";
import GraphLoader from "../SUI/SternumLoaders/GraphLoader";
import librariesUsageDataTileStyle from "./LibrariesUsageDataTileStyle";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    loadingUsedLibrariesCounts: boolean;
    errorLoadingUsedLibrariesCounts: boolean;
    usedLibrariesCounts: Object;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof librariesUsageDataTileStyle> {}

/**
 * Displays a pie of used libraries.
 */
class LibrariesUsageDataTile extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            loadingUsedLibrariesCounts: false,
            errorLoadingUsedLibrariesCounts: false,
            usedLibrariesCounts: {},
        };
    }

    /**
     * Occurs once the component finished its initialization process.
     */
    async componentDidMount() {
        await this.getUsedLibrariesCount();
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        let pieData = this.getPieData();

        if (this.state.loadingUsedLibrariesCounts) {
            return (
                <div className={classNames(classes.flexCenter, classes.flexVMiddle, classes.flexGrow)}>
                    <GraphLoader />
                </div>
            );
        } else {
            return (
                <div className={classes.root}>
                    <SternumBarChart
                        id="libraries-usage-bar-chart"
                        data={pieData}
                        tooltipText="{categoryX}: <b>{valueY}%</b>"
                        labelText="[bold]{valueX}%[/]"
                    />
                </div>
            );
        }
    }

    /**
     * Gets the pie data object for display of pie.
     */
    private getPieData(): any {
        let labels = [];
        let dataItems = [];

        let usedLibrariesCountPairs = Utils.getMapPairs(this.state.usedLibrariesCounts).sort(
            (a, b) => b["value"] - a["value"]
        );

        let totalCount = 0;
        usedLibrariesCountPairs.forEach((pair) => {
            totalCount = totalCount + (pair["value"] || 0);
        });

        let topUsedLibraries = 0;
        let topUsedLibrariesCountPairs = usedLibrariesCountPairs.slice(0, 10);

        for (let i = 0; i < topUsedLibrariesCountPairs.length; i++) {
            let pair = topUsedLibrariesCountPairs[i];
            labels.push(pair["key"]);
            topUsedLibraries += (pair["value"] / totalCount) * 100;
            dataItems.push(((pair["value"] / totalCount) * 100).toFixed(2));
        }

        // All the other options
        if (usedLibrariesCountPairs.length - topUsedLibrariesCountPairs.length > 0) {
            labels.push("Other");
            dataItems.push((100 - topUsedLibraries).toFixed(2));
        }

        let backgroundColors = Utils.duplicateArray(["#113f67", "#34699a", "#408ab4", "#65c6c4"], dataItems.length);

        return {
            labels: labels,
            datasets: dataItems.map((point, index) => {
                return {
                    data: { value: point },
                    backgroundColor: backgroundColors[index],
                };
            }),
        };
    }

    /**
     * Gets the used libraries count for the dashboard.
     */
    private async getUsedLibrariesCount() {
        try {
            // Setting loading state.
            this.setState({
                loadingUsedLibrariesCounts: true,
                errorLoadingUsedLibrariesCounts: false,
            });

            // Fetching.
            let usedLibrariesCounts = await ServiceWire.getSternumService().getUsedLibrariesCounts(
                ServiceWire.getClientsService().getSelectedClientId()
            );

            // Setting fetched data.
            this.setState({
                loadingUsedLibrariesCounts: false,
                errorLoadingUsedLibrariesCounts: false,
                usedLibrariesCounts: usedLibrariesCounts,
            });
        } catch (error) {
            // Setting error state.
            this.setState({
                loadingUsedLibrariesCounts: false,
                errorLoadingUsedLibrariesCounts: true,
            });
        }
    }
}

export default withStyles(librariesUsageDataTileStyle)(LibrariesUsageDataTile);
