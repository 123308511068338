import ConfigurationService from "../services/ConfigurationService";
import ReceivedDefinitionSummary from "../services/events/ReceivedDefinitionSummary";
import ServiceWire from "../services/ServiceWire";
import AggregatedTraceDefinition from "../state/AggregatedTraceDefinition";
import ArgumentInfo from "../state/ArgumentInfo";
import ConditionType from "../state/ConditionType";
import EventInterest from "../state/EventInterest";
import FieldType from "../state/FieldType";
import InputComponentType from "../state/InputComponentType";
import TraceInfo from "../state/TraceInfo";
import countriesJson from "./countries.json";
import SternumQueryField from "./SternumQueryField";
import SternumQueryInputValueConfiguration from "./SternumQueryInputValueConfiguration";
import Utils from "./Utils";

/**
 * Common utils for dealing with the Sternum entities.
 */
class SternumUtils {
    private static MAX_INPUT_SIZE = 255;

    private static MAX_EMAIL_SIZE = 100;

    /**
     * Gets the fields we can query over for a device definition.
     */
    public static getDeviceDefinitionFieldsToQuery(
        includeAllEventOption?: boolean,
        includeAllTraceOption?: boolean,
        includeSternumTriggers?: boolean,
        includeAllAlertsOption?: boolean,
        receivedDefinitions?: ReceivedDefinitionSummary[],
        receivedArguments?: ReceivedDefinitionSummary[],
        includeDeviceIdFilter?: boolean
    ): SternumQueryField[] {
        // First, we get the initial queried fields.
        const queriedArguments: SternumQueryField[] = this.getQueriedFieldsFromReceivedDefinitions(
            receivedArguments,
            includeSternumTriggers
        );

        // Then, we add the special fields.
        this.getSpecialArguments(
            includeAllEventOption,
            includeAllTraceOption,
            includeSternumTriggers,
            includeAllAlertsOption,
            receivedDefinitions,
            includeDeviceIdFilter
        ).forEach((specialArgument) => queriedArguments.unshift(specialArgument));

        const configurationService = ServiceWire.getConfigurationService();

        // from `sternum_configuration` endpoint we get a list of special fields,
        // so we go through arguments and if the argument is found in this list of special fields,
        // we substitute its id to match special field's id
        queriedArguments.forEach((argument) => {
            if (configurationService.isSpecialFieldByLabel(argument.label)) {
                argument.apiName = configurationService.getSpecialFieldByLabel(argument.label).id;
            }

            this.modifyGeoLocationArgument(argument);
        });

        return queriedArguments;
    }

    public static getCountryNameByCode(code: string): string {
        return countriesJson.find((country) => country.code === code)?.name;
    }

    private static modifyGeoLocationArgument(argument: SternumQueryField) {
        // for better UX country field is changed to dropdown list instead of plain text input
        if (argument.label === "Country") {
            argument.inputTypeConfiguration = new SternumQueryInputValueConfiguration(
                FieldType.STRING,
                InputComponentType.DROPDOWN,
                countriesJson.map((country) => ({ value: country.code, label: country.name }))
            );
        }

        const geoLocationLabels = ["Country", "State", "City", "Latitude", "Longitude", "Continent", "Organization"];

        if (geoLocationLabels.some((label) => argument.label === label)) {
            argument.onlyAllowedConditions = [ConditionType.EQUALS, ConditionType.NOT_EQUALS];
        }
    }

    /**
     * Gets the special arguments we can query over.
     */
    private static getSpecialArguments(
        includeAllEventOption?: boolean,
        includeAllTraceOption?: boolean,
        includeSternumTriggers?: boolean,
        includeAllAlertsOption?: boolean,
        receivedDefinitions?: ReceivedDefinitionSummary[],
        includeDeviceIdFilter?: boolean
    ): SternumQueryField[] {
        // Category
        let categoryArgument = new SternumQueryField(
            ConfigurationService.getCategoryArgumentField().id,
            ConfigurationService.getCategoryArgumentField().id,
            "Category",
            FieldType.STRING,
            new SternumQueryInputValueConfiguration(
                FieldType.STRING,
                InputComponentType.DROPDOWN,
                ServiceWire.getConfigurationService()
                    .getTraceCategories()
                    .map((traceCategory) => {
                        return {
                            value: traceCategory.numericIdentifier.toString(),
                            label: traceCategory.displayName,
                        };
                    })
            ),
            true,
            [ConditionType.EQUALS, ConditionType.NOT_EQUALS]
        );

        // Interest
        let interestArgument = new SternumQueryField(
            ConfigurationService.getInterestArgumentField().id,
            ConfigurationService.getInterestArgumentField().id,
            "Interest",
            FieldType.STRING,
            new SternumQueryInputValueConfiguration(
                FieldType.STRING,
                InputComponentType.DROPDOWN,
                ServiceWire.getConfigurationService().getInterests()
            ),
            true,
            [ConditionType.EQUALS, ConditionType.NOT_EQUALS]
        );

        // Event Type
        let eventTypeArgument = new SternumQueryField(
            ConfigurationService.getEventTypeArgumentField().id,
            ConfigurationService.getEventTypeArgumentField().id,
            "Event Type",
            FieldType.STRING,
            new SternumQueryInputValueConfiguration(
                FieldType.STRING,
                InputComponentType.DROPDOWN,
                SternumUtils.getTraceDefinitionFields(
                    includeAllEventOption,
                    includeAllTraceOption,
                    includeSternumTriggers,
                    includeAllAlertsOption,
                    receivedDefinitions
                )
            ),
            true,
            [ConditionType.EQUALS, ConditionType.NOT_EQUALS]
        );

        const specialArguments = [categoryArgument, interestArgument, eventTypeArgument];

        // Device ID
        if (includeDeviceIdFilter) {
            const deviceId = new SternumQueryField(
                ConfigurationService.getDeviceIdArgumentField().id,
                ConfigurationService.getDeviceIdArgumentField().id,
                "Device ID",
                FieldType.STRING,
                new SternumQueryInputValueConfiguration(FieldType.STRING, InputComponentType.TEXT),
                true,
                [ConditionType.EQUALS]
            );
            specialArguments.unshift(deviceId);
        }

        return specialArguments;
    }

    /**
     * Returns queried fields from given received definitions summary collection.
     */
    public static getQueriedFieldsFromReceivedDefinitions(
        receivedDefinitions: ReceivedDefinitionSummary[],
        includeSternumTriggers?: boolean
    ): SternumQueryField[] {
        if (!receivedDefinitions) {
            return [];
        } else {
            return receivedDefinitions
                .filter(
                    (receivedDefinition) =>
                        includeSternumTriggers || receivedDefinition.entityType !== "STERNUM_TRIGGER"
                )
                .map((receivedDefinition) => {
                    return new SternumQueryField(
                        receivedDefinition.identifier,
                        receivedDefinition.identifier,
                        receivedDefinition.displayName,
                        FieldType.STRING,
                        new SternumQueryInputValueConfiguration(FieldType.STRING, InputComponentType.TEXT),
                        receivedDefinition.entityType === "STERNUM_TRIGGER"
                    );
                });
        }
    }
    /**
     * Returns the trace definitions as fields.
     */
    public static getTraceDefinitionFields(
        includeAllEventsOption?: boolean,
        includeAllTracesOption?: boolean,
        includeSternumTriggers?: boolean,
        includeAllAlertsOption?: boolean,
        receivedDefinitions?: ReceivedDefinitionSummary[],
        includeDevicesOption?: boolean
    ): AggregatedTraceDefinition[] {
        let traceDefinitionsOptions = this.getQueriedFieldsFromReceivedDefinitions(
            receivedDefinitions,
            includeSternumTriggers
        ).map((queriedField) => {
            return {
                value: queriedField.value,
                label: queriedField.label,
                displayName: queriedField.label,
                isSpecialField: false,
                isSternumTrigger: queriedField.isSternumTrigger,
            };
        });

        if (includeDevicesOption) {
            traceDefinitionsOptions.unshift({
                value: ConfigurationService.getDeviceIdArgumentField().id,
                label: "Device",
                displayName: "Device",
                isSpecialField: true,
                isSternumTrigger: false,
            });
        }

        if (includeAllAlertsOption) {
            traceDefinitionsOptions.unshift({
                value: ConfigurationService.getAllAlertsFilterField().id,
                label: "All Alerts",
                displayName: "All Alerts",
                isSpecialField: true,
                isSternumTrigger: false,
            });
        }

        if (includeAllTracesOption) {
            traceDefinitionsOptions.unshift({
                value: ConfigurationService.getAllTracesFilterField().id,
                label: "All Traces",
                displayName: "All Traces",
                isSpecialField: true,
                isSternumTrigger: false,
            });
        }

        if (includeAllEventsOption) {
            traceDefinitionsOptions.unshift({
                value: ConfigurationService.getAllEventsFilterField().id,
                label: "All Events",
                displayName: "All Events",
                isSpecialField: true,
                isSternumTrigger: false,
            });
        }

        return traceDefinitionsOptions;
    }

    /**
     * Gets the different event interests.
     */
    public static getEventInterests(
        shouldIgnoreRegularInterest: boolean,
        shouldIgnoreHighExplanation: boolean
    ): { label: string; value: string; explanation: string }[] {
        let interestList = [];
        for (let eventInterest in EventInterest) {
            if (EventInterest.hasOwnProperty(eventInterest)) {
                if (isNaN(Number(eventInterest))) {
                    if (!shouldIgnoreRegularInterest || eventInterest !== EventInterest[EventInterest.REGULAR]) {
                        const eventInterestDisplay = Utils.capitalize(eventInterest.toLowerCase());

                        let explanation: string = "";

                        if (eventInterest === "HIGH" && !shouldIgnoreHighExplanation) {
                            explanation =
                                "High interest items are considered critical and will appear at the Dashboard page.";
                        }

                        interestList.push({
                            label: eventInterestDisplay,
                            value: eventInterest,
                            explanation: explanation,
                        });
                    }
                }
            }
        }
        return interestList;
    }

    public static getMaxInputFieldSize(): number {
        return SternumUtils.MAX_INPUT_SIZE;
    }

    public static getMaxEmailSize(): number {
        return SternumUtils.MAX_EMAIL_SIZE;
    }

    public static getTraceCategoryById(id: number) {
        return ServiceWire.getConfigurationService()
            .getTraceCategories()
            .find((category) => category.numericIdentifier === id);
    }

    /**
     * Returns kernel log display name based on kernel log priority number
     */
    public static getKernelLogDisplayName(argument: ArgumentInfo) {
        // TODO: not sure if to check that argument is ARG_ROLE_PRIORITY? now this argument is defined manually by user
        const priority = parseInt(argument?.displayValue, 10);

        switch (priority) {
            case 0:
                return "KERN_EMERG";
            case 1:
                return "KERN_ALERT";
            case 2:
                return "KERN_CRIT";
            case 3:
                return "KERN_ERR";
            case 4:
                return "KERN_WARNING";
            case 5:
                return "KERN_NOTICE";
            case 6:
                return "KERN_INFO";
            case 7:
                return "KERN_DEBUG";
            default:
                return "N/A";
        }
    }

    /**
     * Returns event trigger display name based on trigger bitmask
     */
    public static getTriggerDisplayName(trigger: ArgumentInfo) {
        const bitmask = parseInt(trigger.displayValue, 10);

        switch (bitmask) {
            case 1:
                return "CPU Peak Detected";
            case 2:
                return "Memory Peak Detected";
            case 3:
                return "CPU & Memory Peak Detected";
            case 4:
                return "Routine Collection";
            case 5:
                return "CPU Peak Detected, Routine Collection";
            case 6:
                return "Memory Peak Detected, Routine Collection";
            case 7:
                return "CPU & Memory Peak Detected, Routine Collection";
            default:
                return "N/A";
        }
    }

    /**
     * Returns module type display name based on trace event name
     */
    public static getModuleTraceType(trace: TraceInfo) {
        const eventName = trace.traceEventName;

        switch (eventName) {
            case "SYSTEM_TRACE_KERNEL_MODULES":
                return "Initial Collection";
            case "SYSTEM_TRACE_KERNEL_MODULE_ADDED":
                return "Added Modules";
            case "SYSTEM_TRACE_KERNEL_MODULE_REMOVED":
                return "Removed Modules";
            default:
                return "N/A";
        }
    }
}

export default SternumUtils;
