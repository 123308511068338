import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

import { commonSignupManager } from "../SignupManager.style";

export const useCreatePasswordStyle = makeStyles((theme: Theme) => ({
    content: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        margin: "auto",

        [`@media (max-width: ${commonSignupManager.mediaQueryMaxWidth})`]: {
            margin: "50px 16px 16px 16px",
        },
    },

    innerContainer: {
        display: "flex",
        flexDirection: "column",
        width: 480,
        boxSizing: "border-box",
        padding: "40px",
        background: "#fff",
        boxShadow: "-12px 14px 34px rgba(83, 93, 120, 0.15)",
        borderRadius: "12px",

        [`@media (max-width: ${commonSignupManager.mediaQueryMaxWidth})`]: {
            width: "100%",
            maxWidth: 480,
        },

        "@media (max-width: 450px)": {
            padding: "40px 20px",
        },
    },

    title: {
        margin: 0,
        marginBottom: 40,
        fontWeight: 500,
        textAlign: "center",
        fontSize: "24px",
    },

    buttonContainer: {
        display: "flex",
        marginTop: 28,
    },

    loginButton: {
        maxWidth: 143,
        backgroundColor: theme.palette.secondary.main,
    },

    termsCheckbox: {
        margin: "5px 0 15px 0",
    },

    modalContent: {
        width: "95%",
        height: "95%",
        marginTop: theme.spacing(4),
        boxSizing: "border-box",
    },
}));
