import { makeStyles } from "@material-ui/core";

export const useTraceViewAlertStatusStyle = makeStyles((theme) => ({
    traceViewAlertStatusContainer: {
        padding: 14,
        margin: "24px 18px 18px 18px",
        background: "#FFFFFF",
        boxShadow: "0px 0px 23px rgba(231, 232, 235, 0.25)",
        borderRadius: 8,
    },

    traceViewAlertAnomalyStatusContainer: {
        padding: 14,
        margin: "24px 18px 18px 18px",
    },

    titleSection: {
        display: "flex",
        gap: 10,
        alignItems: "center",
        justifyContent: "space-between",
    },

    titleContainer: {},

    titleContainerInline: {},

    actionsContainer: {
        display: "flex",
        alignItems: "center",
        gap: 16,
    },

    redDecoration: {
        color: "#EE3B66",
    },

    title: {
        display: "flex",
        gap: 8,
        alignItems: "center",
        fontWeight: 500,
        fontSize: 14,
        lineHeight: "17px",
        color: "#222831",
    },

    issueInfoStatusContainer: {
        display: "flex",
        gap: "0 34px",
        flexWrap: "wrap",
    },

    statusIcon: {
        color: "#BBC6DB",
    },

    tagsContainer: {
        display: "flex",
        gap: 12,
        alignItems: "center",
        marginTop: 12,
    },

    yellowTag: {
        fontSize: 13,
        lineHeight: "20px",
        color: "#FFB400",
        background: "rgba(255, 180, 0, 0.15)",
        borderRadius: 100,
        padding: "4px 26.5px",
    },

    greenTag: {
        fontSize: 13,
        lineHeight: "20px",
        color: "#3FC47C",
        background: "rgba(63, 196, 124, 0.1)",
        borderRadius: 100,
        padding: "4px 26.5px",
    },

    grayTag: {
        fontSize: 13,
        lineHeight: "20px",
        color: "#676E75",
        background: "#F7F9FA",
        borderRadius: 100,
        padding: "4px 20.5px",
    },

    blueButton: {
        "&&": {
            fontSize: 14,
            color: "#FFF",
            background: "#1B6FDE",

            "&:hover": {
                background: "#2064c2",
            },

            "&:focus-visible": {
                outline: "1px solid #2064c2",
                boxShadow: "0px 4px 30px rgb(58 134 253 / 40%)",
            },

            "&:disabled": {
                opacity: 0.3,

                "&:hover": {
                    background: "#1B6FDE",
                },
            },
        },
    },

    grayButton: {
        "&&": {
            fontSize: 14,
            color: "#595C62",
            background: "#E5E8EC",

            "&:hover": {
                background: "#dbdde1",

                "&:disabled": {
                    background: "#E5E8EC",
                    opacity: 0.3,
                },
            },

            "&:focus-visible": {
                outline: "1px solid #dbdde1",
                boxShadow: "0px 4px 30px rgb(58 134 253 / 40%)",
            },

            "&:disabled": {
                opacity: 0.3,
            },
        },
    },

    resolvingSection: {
        padding: 18,
        marginTop: 16,
        background: "rgba(226, 233, 249, 0.3)",
        borderRadius: 8,
        color: "#202022",
    },

    resolvingTitleContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },

    resolvingTitle: {
        display: "flex",
        alignItems: "center",
        gap: 7,
        fontSize: 16,
        fontWeight: 500,
    },

    cancelResolvingIcon: {
        color: "#909090",
        cursor: "pointer",
    },

    resolvingSubtitle: {
        fontSize: 13,
        fontWeight: 400,
        marginTop: 16,
    },

    resolvingSubtitleStar: {
        color: "#EE3B66",
    },

    resolvingReasonsWithActionButtons: {
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "space-between",
        marginTop: 12,
        gap: 16,
    },

    tooltipContent: {
        maxWidth: 236,
    },

    resolveIssueDialogRoot: {
        width: 400,
        paddingBottom: 13,
    },

    switchAlertAnomalyItem: {
        padding: "8px 16px",
    },
}));
