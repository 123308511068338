import { Paper } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import HashSet from "../../lib/infra/HashSet";
import SternumConfiguration from "../../lib/infra/SternumConfiguration";
import DebounceService from "../../lib/services/DebounceService";
import ServiceWire from "../../lib/services/ServiceWire";
import { GlobalState } from "../../lib/state/GlobalState";
import LibraryHistoriesFilter from "../../lib/state/LibraryHistoriesFilter";
import LibraryHistoryInfo from "../../lib/state/LibraryHistoryInfo";
import ListFilter from "../../lib/state/ListFilter";
import TableColumnHeaderInfo from "../../lib/state/TableColumnHeaderInfo";
import LibraryHistoriesListFilterPopover from "../LibraryHistoriesFilterPopover/LibraryHistoriesListFilterPopover";
import SternumTable from "../SUI/SternumTable/SternumTable";
import libraryHistoriesListStyle from "./LibraryHistoriesListStyle";
import moment from "moment";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    // Loading & error state.
    loadingLibraryHistories: boolean;
    errorLoadingLibraryHistories: boolean;

    // Library histories.
    libraryHistories: LibraryHistoryInfo[];
    totalItemCount: number;

    // Filtering.
    libraryHistoriesFilter: LibraryHistoriesFilter;
    searchText: string;
    orderByField: string;
    order: "asc" | "desc";
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof libraryHistoriesListStyle> {
    libraryHistoriesFilter?: LibraryHistoriesFilter;
    includeColumns: string[];

    viewedColumnsSet?: HashSet;

    hideToolbar?: boolean;
    hidePagination?: boolean;
    hideUpperBorder?: boolean;

    location;
    history;

    paperClassNames?;

    showAllColumns?: boolean;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {};
};

/**
 * Holds the library histories list in the app.
 */
class LibraryHistoriesList extends React.Component<AppProps, AppState> {
    /**
     * Defines the column headers participating in the library histories table.
     */
    private columnHeaders: TableColumnHeaderInfo[] = [
        new TableColumnHeaderInfo("historyDate", "History Date", false, true, false),
        new TableColumnHeaderInfo("libraryId", "Id", false, true, false),
        new TableColumnHeaderInfo("libraryName", "Name", false, true, false),
        new TableColumnHeaderInfo("libraryVersion", "Version", false, true, false),
        new TableColumnHeaderInfo("libraryUpdated", "Library Updated", false, true, false),
    ];

    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        if (this.props.includeColumns) {
            if (this.props.includeColumns.length) {
                let includeColumnsSet = HashSet.fromValues(this.props.includeColumns);
                this.columnHeaders = this.columnHeaders.filter((columnHeader) =>
                    includeColumnsSet.exists(columnHeader.id)
                );
            } else {
                this.columnHeaders = [];
            }
        }

        if (this.props.showAllColumns) {
            for (let i = 0; i < this.columnHeaders.length; i++) {
                this.columnHeaders[i].isHidden = false;
            }
        }

        // Initializing the state to default.
        this.state = {
            loadingLibraryHistories: false,
            errorLoadingLibraryHistories: false,

            libraryHistories: [],

            libraryHistoriesFilter: this.props.libraryHistoriesFilter
                ? this.props.libraryHistoriesFilter
                : new LibraryHistoriesFilter(null),
            searchText: null,
            orderByField: null,
            order: "asc",

            totalItemCount: null,
        };
    }

    /**
     * Occurs once the component finished its initialization process.
     */
    async componentDidMount() {
        await this.loadLibraryHistories(1);
    }

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        return (
            <Paper
                classes={{
                    root: classNames(classes.paperClass, this.props.paperClassNames),
                }}
                elevation={0}
            >
                {/* library histories list */}
                <SternumTable
                    hideUpperBorder={this.props.hideUpperBorder}
                    hideToolbar={this.props.hideToolbar}
                    viewedColumnsSet={this.props.viewedColumnsSet}
                    hidePagination={this.props.hidePagination}
                    onRefreshClicked={() => this.onRefreshClicked()}
                    pageSize={SternumConfiguration.getPageSize()}
                    amountOfLoadingPlaceholders={3}
                    totalItemCount={this.state.totalItemCount}
                    loadingItems={this.state.loadingLibraryHistories}
                    errorLoadingItems={this.state.errorLoadingLibraryHistories}
                    columnHeaders={this.columnHeaders}
                    listFilter={this.state.libraryHistoriesFilter}
                    isFilterActive={this.state.libraryHistoriesFilter && this.state.libraryHistoriesFilter.isActive()}
                    filterComponentProvider={(onFilterChanged) => (
                        <LibraryHistoriesListFilterPopover
                            libraryHistoriesFilter={this.state.libraryHistoriesFilter}
                            onFilterChanged={onFilterChanged}
                        />
                    )}
                    orderByField={this.state.orderByField}
                    order={this.state.order}
                    rows={this.state.libraryHistories}
                    onFilterChanged={(listFilter) => this.handleLibraryHistoriesFilterChanged(listFilter)}
                    onSearchTextChanged={(searchText) => this.handleSearchTextChanged(searchText)}
                    onPageChanged={(pageNumber) => this.loadLibraryHistories(pageNumber)}
                    onOrderChanged={(orderByField, order) => this.handleOrderChanged(orderByField, order)}
                    getRowValues={(row) => this.getRowDisplayValues(row)}
                    getRowClasses={(row) => this.getRowClasses(row)}
                />
            </Paper>
        );
    }

    /**
     * Gets the display values for row's columns (where they aren't the default).
     */
    private getRowDisplayValues(row) {
        let libraryInfo = row as LibraryHistoryInfo;

        return {
            libraryId: libraryInfo.libraryId,
            libraryName: libraryInfo.name,
            libraryVersion: libraryInfo.latestKnownVersion,
            libraryUpdated: moment(libraryInfo.updated).format("MM/DD/YYYY HH:mm"),
            historyDate: moment(libraryInfo.historyDate).format("MM/DD/YYYY HH:mm"),
        };
    }

    /**
     * Occurs once the user refreshes the list.
     */
    private async onRefreshClicked() {
        await this.loadLibraryHistories(1);
    }

    /**
     * Occurs once the filters of the list are changed.
     */
    private handleLibraryHistoriesFilterChanged(libraryHistoriesFilter: ListFilter) {
        this.setState(
            {
                libraryHistoriesFilter:
                    libraryHistoriesFilter != null ? (libraryHistoriesFilter as LibraryHistoriesFilter) : null,
            },
            async () => {
                if (libraryHistoriesFilter && libraryHistoriesFilter.isActive()) {
                    await this.loadLibraryHistories(1);
                }
            }
        );
    }

    /**
     * Occurs once search text is changed.
     */
    private handleSearchTextChanged(searchText) {
        // Debounce request to search library histories.
        DebounceService.debounce(
            "getLibraryHistories",
            () => {
                this.setState(
                    {
                        searchText: searchText,
                    },
                    () => this.loadLibraryHistories(1)
                );
            },
            350
        );
    }

    /**
     * Occurs once sort order is changed.
     */
    private handleOrderChanged(orderByField, order) {
        this.setState(
            {
                orderByField: orderByField,
                order: order,
            },
            () => this.loadLibraryHistories(1)
        );
    }

    /**
     * Loads library histories.
     */
    private async loadLibraryHistories(pageNumber) {
        try {
            // Setting loading to true.
            this.setState({
                loadingLibraryHistories: true,
                errorLoadingLibraryHistories: false,
            });

            // Fetching library histories.
            let libraryHistories = await ServiceWire.getSternumService().getLibraryHistories(
                ServiceWire.getClientsService().getSelectedClientId(),
                this.state.libraryHistoriesFilter,
                this.state.searchText,
                this.state.orderByField,
                this.state.order
            );

            this.setState({
                loadingLibraryHistories: false,
                errorLoadingLibraryHistories: false,
                libraryHistories: libraryHistories,
                totalItemCount: libraryHistories.length,
            });
        } catch (error) {
            this.setState({
                loadingLibraryHistories: false,
                errorLoadingLibraryHistories: true,
            });
        }
    }

    /**
     * Gets the classes defined for row.
     */
    private getRowClasses(row) {
        const { classes } = this.props;

        return {
            hover: classes.rowHover,
        };
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(libraryHistoriesListStyle)(LibraryHistoriesList)));
