import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const useLoginStyle = makeStyles((theme: Theme) => ({
    titleDecorated: {
        background: "linear-gradient(to right,#ee3a66,#c64bec)",
        backgroundClip: "text",
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
    },

    forgotPasswordLink: {
        color: "#43484C",
    },
}));
