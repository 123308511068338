import * as React from "react";

import classNames from "classnames";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import changePasswordModalStyle from "./ChangePasswordModalStyle";
import { GlobalState } from "../../lib/state/GlobalState";
import { connect } from "react-redux";
import { Input, Typography } from "@material-ui/core";
import EntityType from "../../lib/state/EntityType";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import ServiceWire from "../../lib/services/ServiceWire";

/**
 * Holds the inner state for our app.
 */
interface AppState {
    oldPassword: string;
    newPassword: string;
    savingPassword: boolean;
    errorSavingPassword: boolean;
    errorMessage: string;
}

/**
 * Holds any props the App component wants to use.
 */
export interface AppProps extends WithStyles<typeof changePasswordModalStyle> {
    fullScreenDisplay: boolean;
    closeModal: () => void;
    theme?;
}

/**
 * Maps the global state into our props.
 */
const mapStateToProps = (state: GlobalState, ownProps: AppProps) => {
    return {};
};

/**
 * Maps props actions to dispatch actions.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {};
};

/**
 * Displays a bar of metrics.
 */
class ChangePasswordModal extends React.Component<AppProps, AppState> {
    /**
     * Constructor.
     */
    constructor(props: AppProps) {
        super(props);

        // Initializing the state to default.
        this.state = {
            oldPassword: "",
            newPassword: "",
            savingPassword: false,
            errorSavingPassword: false,
            errorMessage: null,
        };
    }

    /**
     * Occurs once the component is mounted.
     * We fetch the library if needed here.
     */
    async componentDidMount() {}

    /**
     * Renders the component.
     */
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                {/* Title */}
                <Typography variant={"h5"} className={classNames(classes.marginBottomXLarge)}>
                    Change Password
                </Typography>

                {/* Old password */}
                <div className={classNames(classes.flexVMiddle, classes.marginBottomMedium)}>
                    {/* Title */}
                    <Typography variant="body2" className={classNames(classes.marginRight, classes.nameColumn)}>
                        Old password:
                    </Typography>

                    {/* Input */}
                    <Input
                        className={classNames(classes.valueInputBox, classes.marginRight)}
                        value={this.state.oldPassword}
                        type={"password"}
                        onChange={(event) => this.onOldPasswordChanged(event)}
                    />
                </div>

                {/* New password */}
                <div className={classNames(classes.flexVMiddle, classes.marginBottomMedium)}>
                    {/* Title */}
                    <Typography variant="body2" className={classNames(classes.marginRight, classes.nameColumn)}>
                        New password:
                    </Typography>

                    {/* Input */}
                    <Input
                        className={classNames(classes.valueInputBox, classes.marginRight)}
                        value={this.state.newPassword}
                        type={"password"}
                        onChange={(event) => this.onNewPasswordChanged(event)}
                    />
                </div>

                {/* Save */}
                <Button
                    size={"small"}
                    variant={"contained"}
                    color={"primary"}
                    className={classNames(classes.marginTop)}
                    disabled={!this.state.oldPassword || !this.state.newPassword}
                    onClick={() => this.savePassword()}
                >
                    <span>Save</span>

                    {/* Loading */}
                    {this.state.savingPassword && (
                        <CircularProgress className={classes.marginLeft} size={10} color="inherit" />
                    )}
                </Button>

                {/* Error message */}
                {this.state.errorSavingPassword && (
                    <Typography color={"error"} variant={"caption"} className={classNames(classes.marginTopXs)}>
                        There was an error trying to update password.
                    </Typography>
                )}
            </div>
        );
    }

    /**
     * Occurs once the old password is changed.
     */
    private onOldPasswordChanged(event) {
        this.setState({
            oldPassword: event.target.value,
        });
    }

    /**
     * Occurs once the new password is changed.
     */
    private onNewPasswordChanged(event) {
        this.setState({
            newPassword: event.target.value,
        });
    }

    /**
     * Saves new password.
     */
    private async savePassword() {
        try {
            this.setState({
                savingPassword: true,
                errorSavingPassword: false,
            });

            let token = await ServiceWire.getSternumService().updateUserPassword(
                ServiceWire.getAuthenticationService().getCachedAuthenticatedUser().entityId,
                this.state.oldPassword,
                this.state.newPassword
            );
            ServiceWire.getAuthenticationService().overrideAuthenticationToken(token);
            this.props.closeModal();
        } catch (error) {
            this.setState({
                errorSavingPassword: true,
                errorMessage: "There was an error tyring to change password.",
            });
        } finally {
            this.setState({
                savingPassword: false,
            });
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(changePasswordModalStyle, { withTheme: true })(ChangePasswordModal));
