import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { History } from "history";

import { GlobalState } from "../../lib/state/GlobalState";
import { CreatePassword } from "./CreatePassword";
import { EmailNotification } from "./EmailNotification";
import { Signup } from "./Signup";
import { NotificationMessage, NotificationVariant } from "../../lib/state/NotificationsState";
import { showNotificationAction } from "../../lib/redux/notifications/ShowNotificationAction";

export enum SignupStage {
    Form = "Form",
    EmailNotification = "EmailNotification",
    CreatePassword = "CreatePassword",
    InvitationSetup = "InvitationSetup",
}

export interface SignupManagerProps {
    signupStage: SignupStage;
}

const mapStateToProps = (state: GlobalState, ownProps: SignupManagerProps) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        showNotification: (message: NotificationMessage, variant?: NotificationVariant) => {
            dispatch(showNotificationAction(message, variant));
        },
    };
};

type SignupManagerPropsWithHOC = SignupManagerProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
        history: History;
    };

function SignupManagerComponent({ signupStage, showNotification, history }: SignupManagerPropsWithHOC) {
    const [stage, setStage] = useState(signupStage || SignupStage.Form);
    const [verificationEmail, setVerificationEmail] = useState("");

    const handleSuccessCreatePassword = () => {
        showNotification("Verification is complete", NotificationVariant.Success);
        history?.push("/login");
    };

    switch (stage) {
        case SignupStage.Form:
            return (
                <Signup
                    onFormSubmitSuccess={({ email }) => {
                        setStage(SignupStage.EmailNotification);
                        setVerificationEmail(email);
                    }}
                />
            );

        case SignupStage.EmailNotification:
            return <EmailNotification verificationEmail={verificationEmail} />;

        case SignupStage.CreatePassword:
            return <CreatePassword onSuccess={handleSuccessCreatePassword} />;

        case SignupStage.InvitationSetup:
            return <CreatePassword isInvitationMode onSuccess={handleSuccessCreatePassword} />;

        default:
            return null;
    }
}

export const SignupManager: React.FC<SignupManagerProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SignupManagerComponent));
