/**
 * Represents a hash set data structure.
 * Note there is a built in javascript set<T> data structure, not using it because not all browsers support it just yet and the implementation cost is not high.
 */
class HashSet {
    /**
     * Inner map that holds our set.
     */
    private mSetMap: { [key: string]: boolean } = {};

    /**
     * Holds the length of the set.
     */
    private mLength: number = 0;

    /**
     * Copies given source hash set into a new hash set.
     * @param source The source hash set to copy into a new one.
     */
    public static copy(source: HashSet): HashSet {
        let copiedHashSet = new HashSet();

        copiedHashSet.addAll(source.getAll());

        return copiedHashSet;
    }

    /**
     * Copies given source hash set into a new hash set, and removes given value from copied hash set.
     * @param source The source hash set to copy into a new one, and then later delete from.
     * @param valueToRemove The value to remove from copied hash set.
     */
    public static copyAndRemove(source: HashSet, valueToRemove: string): HashSet {
        let copiedHashSet = HashSet.copy(source);
        copiedHashSet.remove(valueToRemove);
        return copiedHashSet;
    }

    /**
     * Copies given source hash set into a new hash set, and adds given value to copied hash set.
     * @param source The source hash set to copy into a new one, and then later add to.
     * @param valueToAdd The value to add to the copied hash set.
     */
    public static copyAndAdd(source: HashSet, valueToAdd: string): HashSet {
        let copiedHashSet = HashSet.copy(source);
        copiedHashSet.add(valueToAdd);
        return copiedHashSet;
    }

    /**
     * Creates a hash set from given value.
     * @param value Value to create a hash set from.
     */
    public static fromValue(value: string): HashSet {
        let hashSet = new HashSet();

        hashSet.add(value);

        return hashSet;
    }

    /**
     * Creates a hash set from given values.
     * @param values Values to create a hash set from.
     */
    public static fromValues(values: string[]): HashSet {
        let hashSet = new HashSet();

        hashSet.addAll(values);

        return hashSet;
    }

    /**
     * Adds a new value to the hash set.
     * @param value Value to add.
     */
    public add(value: string): void {
        if (!this.mSetMap[value]) {
            this.mSetMap[value] = true;
            this.mLength++;
        }
    }

    /**
     * Adds all given values to the set.
     * @param values Values to add.
     */
    public addAll(values: string[]): void {
        for (let i = 0; i < values.length; i++) {
            this.add(values[i]);
        }
    }

    /**
     * Removes given value, if it exists. Otherwise, does nothing.
     * @param value Value to remove.
     */
    public remove(value: string): void {
        if (this.mSetMap[value]) {
            delete this.mSetMap[value];
            this.mLength--;
        }
    }

    /**
     * Returns whether given value exists in the set.
     * @param value Value to check for its existence.
     */
    public exists(value: string): boolean {
        return !!this.mSetMap[value];
    }

    /**
     * Gets all the keys in the set.
     */
    public getAll(): string[] {
        let keys = [];

        for (let key in this.mSetMap) {
            if (this.mSetMap.hasOwnProperty(key)) {
                keys.push(key);
            }
        }

        return keys;
    }

    /**
     * Returns whether given other hash set is different than current hash set.
     * @param other The set to compare with.
     */
    public isDifferentFrom(other: HashSet): boolean {
        // First, we get all our keys and if any of our keys do not exist in the other set, we return true.
        let thisKeys = this.getAll();
        for (let i = 0; i < thisKeys.length; i++) {
            if (!other.exists(thisKeys[i])) {
                return true;
            }
        }

        // Otherwise, and only after we've done the first comparison, for performance reasons, we fetch the keys of the other set,
        // and check that all keys in other set exist in our set. If any key in other set does not exist in our set, we return
        // true, as they are different sets.
        let otherKeys = other.getAll();
        for (let i = 0; i < otherKeys.length; i++) {
            if (!this.exists(otherKeys[i])) {
                return true;
            }
        }

        // At last, this and other both have the same keys, and therefore they are not different sets!
        return false;
    }

    /**
     * Returns the length of the set, calculated and maintained through out the use of the set.
     */
    public length(): number {
        return this.mLength;
    }
}

export default HashSet;
