import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

import { commonHome } from "../Home.style";

export const useSecondMainViewStyle = makeStyles((theme: Theme) => ({
    secondViewContainer: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: commonHome.secondViewBackground,
        paddingBottom: 140,

        [`@media (max-width: ${commonHome.mediaQueryMaxWidth})`]: {
            paddingBottom: 70,
        },
    },

    mainContent: {
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
    },

    screenShotPlaceholder: {
        width: "100%",
        height: 0,
    },

    screenShotContainer: {
        background: "rgba(255, 255, 255, 0.2)",
        padding: "30px 24px",
        borderRadius: 36,
        transform: "translateY(-68%) translateY(20px)",

        [`@media (max-width: ${commonHome.mediaQueryMaxWidth})`]: {
            padding: "8px 6px",
            borderRadius: 9,
        },
    },

    screenShot: {
        width: "100%",
    },

    sternumCapabilitiesContainer: {
        position: "relative",
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        marginTop: "min(331px, 29vw)",
        maxWidth: 580,
        width: "100%",
        color: "#231434",
    },

    title: {
        fontSize: 34,
        fontWeight: 700,
        lineHeight: "150%",
        textAlign: "center",

        [`@media (max-width: ${commonHome.mediaQueryMaxWidth})`]: {
            fontSize: 22,
        },
    },

    description: {
        display: "grid",
        gridGap: 8,
        fontSize: 14,
        lineHeight: "150%",
        margin: "30px 0",
        textAlign: "center",
        padding: "0 27px",

        [`@media (max-width: ${commonHome.mediaQueryMaxWidth})`]: {
            margin: "24px 0",
            padding: 0,
        },
    },

    descriptionItem: {
        display: "grid",
        gridAutoFlow: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gridGap: 10,
        textAlign: "left",
    },

    descriptionIcon: {
        marginTop: 3,
    },

    descriptionText: {
        flex: 1,
        color: "rgba(35, 20, 52, 0.7)",
    },

    promoVideo: {
        width: "100%",
        maxWidth: "100%",
        borderRadius: 36,

        [`@media (max-width: ${commonHome.mediaQueryMaxWidth})`]: {
            borderRadius: 9,
        },

        "&::-webkit-media-controls-overlay-play-button": {
            display: "none",
        },
    },

    videoPlayButton: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "80px",
        height: "80px",
        background: "rgba(100, 100, 100)",
        color: "#fff",
        fontSize: "40px",
        cursor: "pointer",
        borderRadius: "50%",

        [`@media (max-width: ${commonHome.mediaQueryMaxWidth})`]: {
            width: "50px",
            height: "50px",
            fontSize: "25px",
        },
    },
}));
